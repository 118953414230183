import React, { Component } from 'react'
import ReactGA from 'react-ga4'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import { analyticsTrackZoomMedia } from '../../utils/analytics/analyticsTrackZoomMedia'
import { analyticsTrackStartMediaPlayback } from '../../utils/analytics/analyticsTrackStartMediaPlayback'
import { fetchControl, updateControl, updateControlSettings } from '../../actions/control'
import { clearPlaybackCommand } from '../../actions/clientPlaybackCommand'
import { fetchQuestionPolls, fetchSetPolls } from '../../actions/polls'
import { resetControl } from '../../utils/liveView/resetControl'
import LiveViewScreenNavigationBar from '../../components/liveView/LiveViewScreenNavigationBar'
import LiveViewEmptyState from '../../components/liveView/LiveViewEmptyState'
import LiveViewToolBar from '../../components/liveView/LiveViewToolBar'
import LiveViewReviewScreenControls from '../../components/liveView/LiveViewReviewScreenControls'
import LiveViewCardAssignmentView from '../../components/liveView/LiveViewCardAssignmentView'
import LiveViewAutoplayModal from '../../components/liveView/LiveViewAutoplayModal'
import LiveViewUniversalMediaUnzoomBtn from '../../components/liveView/LiveViewUniversalMediaUnzoomBtn'
import { playPoll } from '../../utils/playItem'
import LiveViewMain from '../../components/liveView/LiveViewMain'
import LiveViewPerfectScoreCelebration from '../../components/liveView/LiveViewPerfectScoreCelebration'
import { calculateNPLayout } from '../../utils/calculateNPLayout'
import { getPollForControl, getSetPollForControl } from '../../utils/liveView/getPollForControl'
import { buildQuestionsAndPollsList } from '../../utils/buildQuestionsAndPollsList'
import { getLiveViewSettings } from '../../utils/getLiveViewSettings'
import { isSurvey } from '../../utils/isSurvey'
import { calibratedCurrentTime } from '../../utils/calibratedCurrentTime'
import { showAutoplayFailedAlert, hideAutoplayFailedAlert } from '../../actions/autoplayFailedAlert'

// Regular non E-learning NP page
// Shown in standard and hybrid NP modes

const MOUSE_MOVE_CONTROLS_FADEOUT_TIME = 3000// time in ms
const PERFECT_SCORE_DISPLAY_TIME = 6000// time in ms

class LiveViewContainer extends Component {
  constructor(props) {
    super(props)
    this.launchFullscreen = this.launchFullscreen.bind(this)
    this.exitFullscreen = this.exitFullscreen.bind(this)
    this.hideMouseMoveControls = this.hideMouseMoveControls.bind(this)
    this.showMouseMoveControls = this.showMouseMoveControls.bind(this)
    this.playQuestionInSet = this.playQuestionInSet.bind(this)
    this.handlePlayPoll = this.handlePlayPoll.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.recalculateLayout = this.recalculateLayout.bind(this)
    this.showCardAssignmentView = this.showCardAssignmentView.bind(this)
    this.clickToCloseCardAssignmentView = this.clickToCloseCardAssignmentView.bind(this)
    this.handleFullScreenChange = this.handleFullScreenChange.bind(this)
    this.handleMinimiseLiveView = this.handleMinimiseLiveView.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.toggleShowCorrect = this.toggleShowCorrect.bind(this)
    this.toggleShowGraph = this.toggleShowGraph.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleDoubleClick = this.handleDoubleClick.bind(this)
    this.showPerfectScoreCelebration = this.showPerfectScoreCelebration.bind(this)
    this.hidePerfectScoreCelebration = this.hidePerfectScoreCelebration.bind(this)
    this.toggleManualShowIndividualStudentResponses = this.toggleManualShowIndividualStudentResponses.bind(this)
    this.toggleZoomMedia = this.toggleZoomMedia.bind(this)
    this.zoomMedia = this.zoomMedia.bind(this)
    this.unzoomMedia = this.unzoomMedia.bind(this)
    this.updateControlPlayback = this.updateControlPlayback.bind(this)
    this.resetControlPlayback = this.resetControlPlayback.bind(this)
    this.beforeUnload = this.beforeUnload.bind(this)
    this.hideMouseMoveControls = debounce(this.hideMouseMoveControls, MOUSE_MOVE_CONTROLS_FADEOUT_TIME)
    this.state = ({
      windowWidth: 0,
      windowHeight: 0,
      fullScreen: false,
      showMouseMoveControls: false,
      cardAssigmentViewVisible: false,
      resizing: false,
      isEmptyState: null,
      layout: {},
      manualShowIndividualStudentResponses: false,
      studentsAndGuestsCount: 0,
      showPerfectScoreCelebration: false,
      promptMediaZoomed: false,
      choiceMediaZoomed: false,
      activeZoomedChoiceIndex: 0, // 1, 2, 3, 4
      layoutKey: 0,
      lastZoomedItem: null,
      showLiveViewAutoplayModal: false,
    })
    this.questionsAndPollsList = null
    this.timeout = null
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeUnload)
    window.addEventListener('resize', this.updateWindowDimensions)
    window.addEventListener('click', this.handleClick)
    document.addEventListener('webkitfullscreenchange', this.handleFullScreenChange)
    document.addEventListener('fullscreenchange', this.handleFullScreenChange)
    document.addEventListener('mozfullscreenchange', this.handleFullScreenChange)
    document.addEventListener('MSFullscreenChange', this.handleFullScreenChange)
    if (this.props.control && this.props.currentSection && (this.props.control.setPoll || this.props.control.currentPoll)) {
      this.setState({ isEmptyState: false })
      this.recalculateLayout(this.props.control, this.props.currentSection)
      if (this.props.control.setPoll) {
        const setPoll = getSetPollForControl(this.props.control)
        if (setPoll && setPoll.snapshot) {
          this.questionsAndPollsList = buildQuestionsAndPollsList(setPoll)
        }
      } else this.questionsAndPollsList = null
    } else {
      this.setState({ isEmptyState: true, cardAssigmentViewVisible: false })
      this.updateWindowDimensions()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload)
    window.removeEventListener('resize', this.updateWindowDimensions)
    window.removeEventListener('click', this.handleClick)
    document.removeEventListener('webkitfullscreenchange', this.handleFullScreenChange)
    document.removeEventListener('fullscreenchange', this.handleFullScreenChange)
    document.removeEventListener('mozfullscreenchange', this.handleFullScreenChange)
    document.removeEventListener('MSFullscreenChange', this.handleFullScreenChange)
    document.body.style.overflowY = '' // overlay is not supported by firefox, so default to blank (auto style comes from css)
    document.body.style.overflowY = 'overlay'
    this.hideMouseMoveControls.cancel()
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.control && nextProps.currentSection && (nextProps.control.setPoll || nextProps.control.currentPoll)) {
      this.setState({ isEmptyState: false })
      this.recalculateLayout(nextProps.control, nextProps.currentSection)
      if (nextProps.control.setPoll) {
        const setPoll = getSetPollForControl(nextProps.control)
        if (setPoll && setPoll.snapshot) {
          this.questionsAndPollsList = buildQuestionsAndPollsList(setPoll)
        }
      } else this.questionsAndPollsList = null
    } else {
      this.setState({ isEmptyState: true, cardAssigmentViewVisible: false })
    }
    if (!this.props.visible && nextProps.visible) {
      document.body.style.overflowY = 'hidden'
      document.addEventListener('keydown', this.handleKeyDown)
    } else if (this.props.visible && !nextProps.visible) {
      document.body.style.overflowY = '' // overlay is not supported by firefox, so default to blank (auto style comes from css)
      document.body.style.overflowY = 'overlay'
      document.removeEventListener('keydown', this.handleKeyDown)
    }
    if (nextProps.visible && !this.props.visible) {
      if (typeof window.gtag === 'function') {
        ReactGA.set({ page: '/nowplaying' })
        ReactGA.send({ hitType: 'pageview' })
      }
      this.setState((prevState) => ({ layoutKey: prevState.layoutKey + 1 }))// to make media components calc position
    }
    if ((nextProps.control.setPollPoll !== this.props.control.setPollPoll) || (nextProps.control.currentPoll !== this.props.control.currentPoll)) {
      this.setState({ manualShowIndividualStudentResponses: false, lastZoomedItem: null })
      this.props.hideAutoplayFailedAlert()
    }
    if (this.props.perfectScoreIsDisplayed(nextProps) && !this.props.perfectScoreIsDisplayed(this.props)) {
      this.showPerfectScoreCelebration()
    } else if (!this.props.perfectScoreIsDisplayed(nextProps) && this.state.showPerfectScoreCelebration) {
      this.hidePerfectScoreCelebration()
    }
    let promptMediaZoomed = false
    let choiceMediaZoomed = false
    let activeZoomedChoiceIndex = 0
    let lastZoomedItem = null

    if (nextProps.pageVisibilityState === 'visible') { // only zoom if page is visible
      const { zoomedItem } = nextProps.control
      lastZoomedItem = zoomedItem
      if (zoomedItem === 'promptMedia') {
        promptMediaZoomed = true
      } else if (zoomedItem === 'choiceA') {
        choiceMediaZoomed = true
        activeZoomedChoiceIndex = 0
      } else if (zoomedItem === 'choiceB') {
        choiceMediaZoomed = true
        activeZoomedChoiceIndex = 1
      } else if (zoomedItem === 'choiceC') {
        choiceMediaZoomed = true
        activeZoomedChoiceIndex = 2
      } else if (zoomedItem === 'choiceD') {
        choiceMediaZoomed = true
        activeZoomedChoiceIndex = 3
      }
    }
    if (choiceMediaZoomed || promptMediaZoomed) {
      this.setState({ lastZoomedItem })
    }
    if (choiceMediaZoomed) {
      this.setState({
        activeZoomedChoiceIndex,
        choiceMediaZoomed,
        promptMediaZoomed,
        lastZoomedItem,
      })
    } else {
      this.setState({
        choiceMediaZoomed,
        promptMediaZoomed,
      })
    }

    if (this.props.pageVisibilityState === 'hidden' && nextProps.pageVisibilityState === 'visible') {
      this.resetControlPlayback()
    }
    if (!this.props.control.controlled && nextProps.control.controlled) {
      this.resetControlPlayback(nextProps.control)
    }
    if (this.props.control.playback && nextProps.control.playback && (this.props.control.playback.item !== nextProps.control.playback.item || this.props.control.playback.isPlaying !== nextProps.control.playback.isPlaying)) {
      if (nextProps.control.playback.isPlaying) {
        analyticsTrackStartMediaPlayback(nextProps.control)
      }
    }
    if (nextProps.control.zoomedItem && nextProps.control.zoomedItem !== this.props.control.zoomedItem) {
      analyticsTrackZoomMedia(nextProps.control)
    }
  }

  updateWindowDimensions() {
    const layout = calculateNPLayout(window.innerWidth, window.innerHeight, this.state.studentsAndGuestsCount, this.state.fullScreen)
    this.setState((prevState) => ({
      layout,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      resizing: true,
      layoutKey: prevState.layoutKey + 1,
    }))
    setTimeout(() => {
      this.setState({ resizing: false })
    }, 800) // Use this to prevent onMouseMove when going to full screen
  }

  handleKeyDown(e) {
    if (e.keyCode === 27) { // escape key
      e.preventDefault()
      e.stopPropagation()
      if (this.state.promptMediaZoomed || this.state.choiceMediaZoomed) {
        this.unzoomMedia()
      } else {
        this.props.minimiseLiveView()
      }
    }
  }

  beforeUnload() {
    if (this.props.pageVisibilityState === 'visible') {
      this.resetControlPlayback()
    }
  }

  showPerfectScoreCelebration() {
    this.setState({ showPerfectScoreCelebration: true })
    this.timeout = setTimeout(() => {
      this.setState({
        showPerfectScoreCelebration: false,
      })
    }, PERFECT_SCORE_DISPLAY_TIME)
  }

  hidePerfectScoreCelebration() {
    this.setState({ showPerfectScoreCelebration: false })
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }

  toggleManualShowIndividualStudentResponses() {
    this.setState((prevState) => ({ manualShowIndividualStudentResponses: !prevState.manualShowIndividualStudentResponses }))
  }

  handleClick() {
    this.showMouseMoveControls()
  }

  recalculateLayout(control) { // Temp
    const poll = getPollForControl(control)
    let studentCount = 0
    let guestCount = 0
    if (this.props.currentSection) {
      const students = filter(this.props.currentSection.students, { archived: false })
      studentCount = students.length
      if (poll) {
        guestCount = poll.unassignedResponseCount || 0
      }
    }
    const studentsAndGuestsCount = studentCount + guestCount
    if (studentsAndGuestsCount !== this.state.studentsAndGuestsCount) {
      const layout = calculateNPLayout(window.innerWidth, window.innerHeight, studentsAndGuestsCount, this.state.fullScreen)
      this.setState((prevState) => ({
        layout,
        studentsAndGuestsCount,
        layoutKey: prevState.layoutKey + 1,
      }))
    }
  }

  handleFullScreenChange() {
    this.setState(
      (prevState) => ({ fullScreen: !prevState.fullScreen }),
      () => { this.updateWindowDimensions() },
    )
  }

  launchFullscreen() {
    const element = this.refs.ForFullScreen
    // find which method the browser recognises
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }
    this.setState({ showMouseMoveControls: false })
  }

  exitFullscreen() {
    // find which method the browser recognises
    if (document.fullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozExitFullScreen) {
        document.mozExitFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
  }

  handleMinimiseLiveView() {
    if (this.state.fullScreen) {
      this.exitFullscreen()
    }
    this.props.minimiseLiveView()
  }

  toggleShowCorrect() {
    const { control } = this.props
    const nowDate = calibratedCurrentTime()
    const newControl = {
      ...control,
      controlled: nowDate,
      revealAnswer: !control.revealAnswer,
    }
    return this.props.updateControlSettings(newControl).then((response) => response)
  }

  toggleShowGraph() {
    const { control } = this.props
    const nowDate = calibratedCurrentTime()
    const newControl = {
      ...control,
      controlled: nowDate,
      showGraph: !control.showGraph,
    }
    return this.props.updateControlSettings(newControl).then((response) => response)
  }

  toggleZoomMedia(item) {
    const { control } = this.props
    const nowDate = calibratedCurrentTime()
    let newZoomedItem = item
    let { playback } = control

    if (control.zoomedItem === item) {
      newZoomedItem = null
    } else { // reset playback if zooming a different item
      playback = {
        item: null,
        isPlaying: false,
      }
    }
    const newControl = {
      ...control,
      controlled: nowDate,
      zoomedItem: newZoomedItem,
      playback,
    }
    if (newZoomedItem) {
      const zoomedOnWeb = true
      analyticsTrackZoomMedia(newControl, zoomedOnWeb)
    }
    return this.props.updateControlSettings(newControl).then((response) => response)
  }

  zoomMedia(item) {
    const { control } = this.props
    const nowDate = calibratedCurrentTime()
    const newZoomedItem = item
    let { playback } = control
    if (control.zoomedItem && control.zoomedItem !== item) { // reset playback if zooming a different item
      playback = {
        item: null,
        isPlaying: false,
      }
    }
    const newControl = {
      ...control,
      controlled: nowDate,
      zoomedItem: newZoomedItem,
      playback,
    }
    const zoomedOnWeb = true
    analyticsTrackZoomMedia(newControl, zoomedOnWeb)
    return this.props.updateControlSettings(newControl).then((response) => response)
  }

  unzoomMedia() {
    const { control } = this.props
    const nowDate = calibratedCurrentTime()
    const newControl = {
      ...control,
      controlled: nowDate,
      zoomedItem: null,
    }
    return this.props.updateControlSettings(newControl).then((response) => response)
  }

  resetControlPlayback(nextControl) {
    const control = nextControl || this.props.control
    if (control.setPoll || control.currentPoll) {
      const nowDate = calibratedCurrentTime()
      const playback = {
        item: null,
        isPlaying: false,
      }
      const newControl = {
        ...control,
        controlled: nowDate,
        playback,
        zoomedItem: null, // also reset zoomed item
      }
      if ((control.playback && control.playback.item) || control.zoomedItem !== null) {
        return this.props.updateControlSettings(newControl).then((response) => response)
      }
    }
  }

  updateControlPlayback(item, isPlaying) {
    const { control } = this.props
    const nowDate = calibratedCurrentTime()
    const playback = {
      item: item || null,
      isPlaying,
    }
    if (
      !control.playback ||
      control.playback.item !== item ||
      control.playback.isPlaying !== isPlaying
    ) {
      const newControl = {
        ...control,
        controlled: nowDate,
        playback,
      }
      if (isPlaying) {
        const playedFromWeb = true
        analyticsTrackStartMediaPlayback(newControl, playedFromWeb)
      }

      return this.props.updateControlSettings(newControl).then((response) => response)
    }
  }

  playQuestionInSet(questionAndPoll) {
    let { control } = this.props
    const snapshot = questionAndPoll.questionSnapshot
    const nowDate = calibratedCurrentTime()
    let setPollPollId
    if (questionAndPoll.poll) {
      setPollPollId = questionAndPoll.poll.id
    } else {
      setPollPollId = questionAndPoll.questionSnapshot.questionId
    }
    if (snapshot.source) {
      delete snapshot.source
    }
    control = {
      ...control,
      controlled: nowDate,
      scanning: false,
      setPollPoll: setPollPollId,
      snapshot,
      showGraph: false,
      revealAnswer: false,
      zoomedItem: null,
      playback: { item: null, isPlaying: false },
    }
    this.props.updateControl(control)
  }

  hideMouseMoveControls() {
    this.setState({ showMouseMoveControls: false })
  }

  showMouseMoveControls() {
    if (this.state.resizing === false) { // dont show navbar is mouse move is due to launching fullscreen
      this.setState({ showMouseMoveControls: true })
      this.hideMouseMoveControls()
    }
  }

  handlePlayPoll(poll, item) {
    this.props.playPoll(poll, item)
  }

  showCardAssignmentView() {
    this.setState({ cardAssigmentViewVisible: true })
    document.addEventListener('click', this.clickToCloseCardAssignmentView)
  }

  clickToCloseCardAssignmentView() {
    this.setState({ cardAssigmentViewVisible: false })
    document.removeEventListener('click', this.clickToCloseCardAssignmentView)
  }

  handleDoubleClick() {
    if (this.state.fullScreen) {
      this.exitFullscreen()
    } else {
      this.launchFullscreen()
    }
  }

  render() {
    const { control } = this.props
    const {
      isEmptyState, layout, cardAssigmentViewVisible, showLiveViewAutoplayModal,
    } = this.state
    const isScan = (control && control.scanning)

    let currentQuestionIndex = 0

    if (this.questionsAndPollsList) {
      currentQuestionIndex = this.questionsAndPollsList.findIndex((questionAndPoll) => {
        if (questionAndPoll && this.props.control) {
          if (questionAndPoll.questionSnapshot && this.props.control.snapshot) {
            return questionAndPoll.questionSnapshot.questionId === this.props.control.snapshot.questionId
          }
        } return null
      })
    }

    const displayStyle = this.props.visible !== false ?
      {
        visibility: 'visible',
        opacity: '1',

      } :
      {
        visibility: 'hidden',
        opacity: '0',
      }
    let poll
    let setPoll
    if (control && (control.setPoll || control.currentPoll)) {
      poll = getPollForControl(control)
    }
    if (control.setPoll) {
      setPoll = getSetPollForControl(control)
    }

    let isReviewScreen = false
    if (!isScan && poll && poll.hasResponses) {
      isReviewScreen = true
    }
    const liveViewSettings = getLiveViewSettings()

    let questionIsSurvey = false
    if (poll) {
      questionIsSurvey = isSurvey(poll.snapshot)
    }

    let { showGraph } = control
    if (liveViewSettings.alwaysShowGraph) {
      showGraph = true
    }

    let questionStage = 'ask'
    if (isScan) {
      questionStage = 'scan'
    } else if (poll && poll.hasResponses) {
      questionStage = 'review'
    }

    const { choiceMediaZoomed, promptMediaZoomed, activeZoomedChoiceIndex } = this.state

    let playbackItem = null
    let isPlaying = false
    if (control && control.playback) {
      playbackItem = control.playback.item
      isPlaying = control.playback.isPlaying
    }

    return (
      <div style={displayStyle} className='liveViewContainer' onMouseMove={() => { this.showMouseMoveControls() }} ref='ForFullScreen'>
        {!this.state.fullScreen && (
        <LiveViewToolBar
          toolBarHeight={layout.defaultHeightLiveViewToolbar}
          isEmptyState={isEmptyState}
          section={this.props.currentSection}
          sectionDataOwner={this.props.currentSectionDataOwner}
          minimiseLiveView={this.handleMinimiseLiveView}
          stopLiveView={this.props.stopLiveView}
          switchNowPlayingMode={this.props.switchNowPlayingMode}
          nowPlayingMode={this.props.nowPlayingMode}
          onlineStudentsCount={this.props.onlineStudentsCount}
          countDownTimerStartSecondsLength={this.props.countDownTimerStartSecondsLength}
          setCountdownSeconds={this.props.setCountdownSeconds}
          incrementCountdown={this.props.incrementCountdown}
          cancelCountdown={this.props.cancelCountdown}
          handleCountdownEnd={this.props.handleCountdownEnd}
          toggleScanning={this.props.toggleScanning}
          questionStage={questionStage}
          countdownEndsAt={control ? control.countdownEndsAt : null}
          mediaZoomed={promptMediaZoomed || choiceMediaZoomed}
        />
        )}

        {cardAssigmentViewVisible && (
        <LiveViewCardAssignmentView
          section={this.props.currentSection}
          sortOrder={liveViewSettings.studentSortOrder}
        />
        )}

        {showLiveViewAutoplayModal && (
        <LiveViewAutoplayModal
          dismissAutoplayModal={() => { this.setState({ showLiveViewAutoplayModal: false }) }}
        />
        )}

        {isEmptyState === false && (
        <LiveViewUniversalMediaUnzoomBtn
          mouseMove={this.state.showMouseMoveControls}
          slideWidth={layout.slideDimensions.width}
          mediaZoomed={promptMediaZoomed || choiceMediaZoomed}
          unzoomMedia={this.unzoomMedia}
        />
        )}

        {isEmptyState === true &&
        <LiveViewEmptyState />}

        {isEmptyState === false && (
        <LiveViewScreenNavigationBar
          questionsAndPollsList={this.questionsAndPollsList}
          currentQuestionIndex={currentQuestionIndex}
          queue={this.props.queue}
          section={this.props.currentSection}
          fullScreen={this.state.fullScreen}
          launchFullscreen={this.launchFullscreen}
          exitFullscreen={this.exitFullscreen}
          playQuestionInSet={this.playQuestionInSet}
          playPoll={this.handlePlayPoll}
          showCardAssignmentView={this.showCardAssignmentView}
          currentSetPoll={setPoll}
          mediaZoomed={promptMediaZoomed || choiceMediaZoomed}
        />
        )}

        {isEmptyState === false && (
        <LiveViewReviewScreenControls
          alwaysShowGraph={liveViewSettings.alwaysShowGraph}
          layout={layout}
          showGraph={showGraph}
          questionIsSurvey={questionIsSurvey}
          showCorrect={control.revealAnswer}
          toggleShowCorrect={this.toggleShowCorrect}
          toggleShowGraph={this.toggleShowGraph}
          fullScreen={this.state.fullScreen}
        />
        )}

        {isEmptyState === false && (
        <LiveViewMain
          showIndividualStudentResponses={liveViewSettings.showIndividualResponses}
          manualShowIndividualStudentResponses={this.state.manualShowIndividualStudentResponses}
          handleDoubleClick={this.handleDoubleClick}
          liveViewSettings={liveViewSettings}
          layout={layout}
          layoutKey={this.state.layoutKey}
          scaleTest={layout.scanSlideScale}
          isScan={isScan}
          showMouseMoveControls={this.state.showMouseMoveControls}
          isSurvey={questionIsSurvey}
          isReviewScreen={isReviewScreen}
          showGraph={showGraph}
          showCorrect={control.revealAnswer}
          section={this.props.currentSection}
          currentPollId={this.props.currentPollId}
          currentSetPollId={this.props.currentSetPollId}
          currentSetPollPollId={this.props.currentSetPollPollId}
          snapshot={control.snapshot}
          poll={poll}
          nowPlayingMode={this.props.nowPlayingMode}
          onlineStudents={this.props.onlineStudents}
          promptMediaZoomed={promptMediaZoomed}
          choiceMediaZoomed={choiceMediaZoomed}
          activeZoomedChoiceIndex={activeZoomedChoiceIndex}
          toggleZoomMedia={this.toggleZoomMedia}
          zoomMedia={this.zoomMedia}
          unzoomMedia={this.unzoomMedia}
          playbackItem={playbackItem}
          isPlaying={isPlaying}
          visible={this.props.visible}
          pageVisibilityState={this.props.pageVisibilityState}
          updateControlPlayback={this.updateControlPlayback}
          clientPlaybackCommand={this.props.clientPlaybackCommand}
          clearPlaybackCommand={this.props.clearPlaybackCommand}
          lastZoomedItem={this.state.lastZoomedItem}
          showAutoplayFailedAlert={this.props.showAutoplayFailedAlert}
          hideAutoplayFailedAlert={this.props.hideAutoplayFailedAlert}
        />
        )}

        <LiveViewPerfectScoreCelebration
          showPerfectScoreCelebration={this.state.showPerfectScoreCelebration}
          windowWidth={this.state.windowWidth}
          windowHeight={this.state.windowHeight}
          totalDisplayTime={PERFECT_SCORE_DISPLAY_TIME}
          hidePerfectScoreCelebration={this.hidePerfectScoreCelebration}
        />

      </div>

    )
  }
}

function mapStateToProps(state) {
  const { control, dataOwners } = state
  let currentSection = null
  let currentPollId = null
  let currentSetPollId = null
  let currentSetPollPollId = null
  let queue = []
  let currentSectionDataOwner = null

  if (state.control) {
    currentSetPollId = control.setPoll
    currentSetPollPollId = control.setPollPoll
    currentPollId = control.currentPoll
    currentSection = find(state.sections, { id: state.control.section })
    const currentSectionQueueQuestionPolls = filter(state.queueQuestionPolls, { section: state.control.section })
    const currentSectionQueueSetPolls = filter(state.queueSetPolls, { section: state.control.section })
    queue = sortBy(currentSectionQueueQuestionPolls.concat(currentSectionQueueSetPolls), 'planned')
    if (queue[0]) {
      if (queue[0].id === control.currentPoll || queue[0].id === control.setPoll) {
        queue.splice(0, 1) // remove from upnext queue if currently being played
      }
    }
    if (currentSection?.dataOwner) {
      const dataOwner = dataOwners[currentSection.dataOwner.id]
      currentSectionDataOwner = dataOwner
    }
  }
  return {
    control,
    currentSection,
    queue,
    currentPollId,
    currentSetPollId,
    currentSetPollPollId,
    currentSectionDataOwner,
    pageVisibilityState: state.pageVisibilityState,
    clientPlaybackCommand: state.clientPlaybackCommand,
  }
}

export default connect(
  mapStateToProps,
  {
    fetchControl,
    updateControl,
    fetchQuestionPolls,
    fetchSetPolls,
    playPoll,
    resetControl,
    updateControlSettings,
    clearPlaybackCommand,
    showAutoplayFailedAlert,
    hideAutoplayFailedAlert,
  },
)(LiveViewContainer)

import React from 'react'
import EditorIcon from '../../../misc/EditorIcons'

class ChoiceImageReactComponent extends React.Component{  

	constructor(props) {
		super(props)
		this.setImageFill=this.setImageFill.bind(this)
		this.state = { 
			cloudinaryImageHasLoaded:false,
			imageFill: props.imageFill,
		}
	} 

	setImageFill(imageFill){
		this.props.setImageFillType(imageFill)
		this.setState({imageFill:imageFill})
	}

	render(){ 
		const {deliveryUrl,placeholderImage,slideWidth}=this.props
		const {imageFill} = this.state
		const slideScale = slideWidth / 1280
		const inverseScale = 1 / slideScale
		const ceilingInverseScale = 1.75 // prevent and other elements icons getting too big
		const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)
		let imageFillType = 'fill'
		if(!imageFill){
			imageFillType = 'fit'
		}

		return (
			<React.Fragment>
				{placeholderImage && !this.state.cloudinaryImageHasLoaded &&							
					<img
						draggable="false" 
						src={placeholderImage} 
						alt=''
						className={'slide-choice-media--image-image slide-choice-media--image-image--placeholder ' + (imageFillType === 'fit' ? 'slide-choice-media--image-image--fit' : 'slide-choice-media--image-image--fill')}
					/>
				}

				{placeholderImage &&
					<div className={'slide-choice-media--image-placeholderOverlay ' + (deliveryUrl ? 'slide-choice-media--image-placeholderOverlay--hide' :  'slide-choice-media--image-placeholderOverlay--show')} />
				}

				{placeholderImage && !this.state.cloudinaryImageHasLoaded && 
					<div style={{transform: `scale(${UIInverseScale})`}} className='slide-choice-media-topLeftBtnContainer'>
						<div className='slide-choice-media--image-loadingSpinnerContainer'>
							<div className='slide-choice-media--image-loadingSpinner' /> 
						</div>
					</div>
				}

				{deliveryUrl &&	imageFillType &&
					<img 
						onLoad={()=>{this.setState({cloudinaryImageHasLoaded:true})}}
						draggable="false" 
						src={deliveryUrl}
						alt='' 
						className={'slide-choice-media--image-image ' + (imageFillType === 'fit' ? 'slide-choice-media--image-image--fit' : 'slide-choice-media--image-image--fill')}
						onClick={this.props.showEditImageModal}
					/>																
				}

				{deliveryUrl && this.state.cloudinaryImageHasLoaded && 
					<div style={{transform: `scale(${UIInverseScale})`}} className='slide-choice-media-topLeftBtnContainer  slide-choice-media-topLeftBtnContainer--fitBtn'>
						<button onClick={()=>{this.setImageFill(!this.state.imageFill)}} className='slide-choice-media--image-fitImage'>
							{imageFill &&
								<EditorIcon name='imageFit' /> 
							}
							{!imageFill &&
								<EditorIcon name='imageFill' /> 
							}
						</button>
					</div>
				}				


				<div style={{transform: `scale(${UIInverseScale})`}} className={'slide-choice-media-topRightBtnContainer ' + (deliveryUrl ? '' : 'slide-choice-media-topRightBtnContainer--placeholder' )}>
					<button onClick={this.props.deleteImage} className='slide-choice-media--image-removeImage'>
						<EditorIcon name='close' /> 
					</button>
				</div>

				{!placeholderImage && !this.state.cloudinaryImageHasLoaded &&							
					<div className='slide-choice-mediaContainer--image--fallback' /> 
				}
			</React.Fragment>
		)
	}
}

export default ChoiceImageReactComponent

import React from 'react'
import { Link } from 'react-router-dom'
import StaticQuestionSlide from './slides/StaticQuestionSlide'
import QuestionCountBadge from './uiKit/QuestionCountBadge'
import ScorePill from './uiKit/ScorePill'
import Icon from './misc/Icons'
import {processQuestionResponses} from '../utils/processQuestionResponses'

//Used to display a grid of question slides e.g. reports
//takes gridColumns (1,2 or 3) as a prop

const GRID_ITEM_SIDE_PADDING= 5
const GRID_ITEM_LEFT_PADDING= 5
const GRID_ITEM_RIGHT_PADDING= 5
const GRID_ITEM_TOP_PADDING = 8		
const GRID_ITEM_BOTTOM_PADDING = 12

 
class SlideGridItem extends React.Component{
	render(){	
		const {item, showAnsweredOnly,section, gridItemHeight, showQuestionNumber, slideWidth,parentID,gridItemWidth, index} = this.props
		let linkPath
		let processedQuestionResponses={}
		let totalResponses
		if(item.question){ //is poll		
			linkPath=`/setreport/${parentID}/${item.question}`
			if(item.responsesByCard && section && section.students){
				processedQuestionResponses = processQuestionResponses(item,section)
				totalResponses=item.unassignedResponseCount + item.assignedResponseCount
			}
		}else{
			linkPath=`/set/${parentID}/${item.questionId}`
		}

		if(item.question && item.responsesByCard){
			return(
				<Link 	
					style={{
						height:`${gridItemHeight}px`,
						width:`${gridItemWidth}px`,
						paddingLeft:`${GRID_ITEM_LEFT_PADDING}px`,
						paddingRight:`${GRID_ITEM_RIGHT_PADDING}px`,
						paddingTop:`${GRID_ITEM_TOP_PADDING}px`,
						paddingBottom:`${GRID_ITEM_BOTTOM_PADDING}px`,
					}}
					className='slideGrid-gridItem'
					to={linkPath}
				>

					<div className='slideGrid-gridItem-slide'>
						<StaticQuestionSlide            			
							processedResponses={processedQuestionResponses}
							totalResponses={totalResponses}
							question={item.snapshot}  
							displayWidth={slideWidth} 
							reportView 
						/>
					</div>
					<ScorePill score={item.correctPercent} />				

					{this.props.currentQuestion &&
						<div className='slideGrid-gridItem-activeQuestion'>
							Current
						</div> 
					}

					{this.props.upNext &&
						<div className='slideGrid-gridItem-upNext'>
							Up Next
						</div> 
					}
				</Link>
			)}
		else if(!showAnsweredOnly){
			return(
			<div 	
				style={{
					height:`${gridItemHeight}px`,
					width:`${gridItemWidth}px`,
					paddingLeft:`${GRID_ITEM_LEFT_PADDING}px`,
					paddingRight:`${GRID_ITEM_RIGHT_PADDING}px`,
					paddingTop:`${GRID_ITEM_TOP_PADDING}px`,
					paddingBottom:`${GRID_ITEM_BOTTOM_PADDING}px`,
				}}
				className='slideGrid-gridItem'
				onClick={()=>{this.props.showExpandedQuestionSlideModal(item)}}		
			>
				
				<div className='slideGrid-gridItem-expandLabel' >
					<Icon name='enterFS'/>
				</div>
				
				{showQuestionNumber &&
					<QuestionCountBadge questionCount={index + 1} color="gray" /> 
				}

				{this.props.currentQuestion &&
					<div className='slideGrid-gridItem-activeQuestion'>
						Current
					</div> 
				}

				{this.props.upNext &&
					<div className='slideGrid-gridItem-upNext'>
						Up Next
					</div> 
				}
				
				<div className='slideGrid-gridItem-slide' >				
					<StaticQuestionSlide question={item} stage='ask' mode='view' displayWidth={slideWidth} previewCard={true} />
				</div>
			
			</div>		
		)}
		else return null
	}
}

class SlideGrid extends React.Component{

	render(){	

		const {questions,showAnsweredOnly,questionsAndPollsList,section, setID, pollId,availableWidth, gridColumns} = this.props		


		// grid overflow width aligns the slides to the edges, so the grid items overhang the gutters
		const gridOverflowWidth = availableWidth + GRID_ITEM_SIDE_PADDING + GRID_ITEM_SIDE_PADDING
		let gridItemWidth
		gridItemWidth = (gridOverflowWidth / gridColumns) - 1 //subtract one to prevent rounding error
		
		const slideWidth = gridItemWidth - GRID_ITEM_LEFT_PADDING - GRID_ITEM_RIGHT_PADDING - 2
		const slideHeight = slideWidth / 1.6 // 1.6 is aspect ratio of slide
		const gridItemHeight = slideHeight + GRID_ITEM_TOP_PADDING + GRID_ITEM_BOTTOM_PADDING

		let gridItems=questions || [] //for library use questions array, for history use questionsAndPollsList
		if(questionsAndPollsList){
			gridItems = questionsAndPollsList.map(function(questionAndPoll) {
					return questionAndPoll.poll || questionAndPoll.questionSnapshot
			})
		}
		return(		
			<div style={{width: `${gridOverflowWidth}px`, marginLeft: `${-1 * GRID_ITEM_SIDE_PADDING}px` }} className='slideGrid'>																			
				{gridItems.map(function(item,index){		
					return(
						<SlideGridItem
							showAnsweredOnly={showAnsweredOnly}
							gridItemHeight={gridItemHeight}
							slideWidth={slideWidth}							
							section={section}
							parentID={setID || pollId}							
							gridItemWidth={gridItemWidth}		
							item={item}//can be question or setpollpoll
							key={index}
							index={index}
							showQuestionNumber={questionsAndPollsList ? false : true}
							showExpandedQuestionSlideModal={this.props.showExpandedQuestionSlideModal}
							currentQuestion={index === this.props.currentQuestionIndex}
							upNext={index === this.props.currentQuestionIndex + 1}
						/>							
					)
				}.bind(this))}					
			</div>
			
		)
	}
}

export default SlideGrid

import React from 'react'
import { Spring, animated } from 'react-spring/renderprops' 

const LETTERS_ARRAY=['A','B','C','D']

class LiveViewChoiceZoomLetterBlockPanelLetterBlock extends React.Component{
	constructor(){
		super()
		this.handleClick=this.handleClick.bind(this)	 
	}

	handleClick(e){
		e.stopPropagation()
		this.props.zoomChoice()
	}
	
	render(){	
		const { isActive, animationDisabled} = this.props			
		return(			
			<React.Fragment>
				<Spring 
					native          	              
					to={{
					opacity: isActive ? 1 : 0.2,
					transform: isActive ? 'scale(1.25)' : 'scale(1)',
					background: isActive ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
					color: isActive ? 'rgb(0,0,0)' : 'rgb(255,255,255)',	              	
					}}
					immediate={animationDisabled}               
				>
					{props => 
						<animated.div onClick={this.handleClick} className='liveView-choiceZoomLetterBlockPanel-letterBlock' style={{ ...props}}>               			            	
							<Spring 
								native          	              
								to={{				              	
									transform: isActive ? 'scale(1.25)' : 'scale(1)',				              
								}}
								immediate={animationDisabled}           
							>
							{props => 
								<animated.div className='liveView-choiceZoomLetterBlockPanel-letterBlock-label' style={props}>               			            		              			
									{this.props.label}
								</animated.div>
							}
						</Spring>
					</animated.div>
					}
				</Spring>
			</React.Fragment>
		)		
	}	
}

class LiveViewChoiceZoomLetterBlockPanel extends React.Component{
	
	render(){		
		const {choiceMediaZoomed, slideWidth, activeZoomedChoiceIndex, animationDisabled,choices} = this.props 		

		// SLIDE SCALE	
		const slideScale = slideWidth / 1280 
		// SLIDE SCALE	
		const choiceZoomLetterBlockPanelHeight = 120 // at 1280px wide, putting in JS so easier to find for other components
		// height elsewhere is 100 * slideWidth / 1280			
		return(				
			<React.Fragment>
				<Spring          
					native                  
					from={{ transform: `translateY(-${choiceZoomLetterBlockPanelHeight}px) scale(${slideScale})`, opacity: 0 }}
					to={{ 
						opacity: choiceMediaZoomed ? 0.95 : 0,
						transform: choiceMediaZoomed ? `translateY(0) scale(${slideScale})` : `translateY(-${choiceZoomLetterBlockPanelHeight}px) scale(${slideScale})`
					}}
					immediate={animationDisabled}
				>
					{props => 
						<animated.div style={{height:`${choiceZoomLetterBlockPanelHeight}px`, ...props}} className='liveView-choiceZoomLetterBlockPanel' onClick={this.props.unzoomMedia}>
							{choices.map((choice,index)=>{
								
								const choiceLetter=LETTERS_ARRAY[index]
								const itemName=`choice${choiceLetter}`

								return(
									<LiveViewChoiceZoomLetterBlockPanelLetterBlock
										key={index}
										zoomChoice={()=>{this.props.zoomMedia(itemName)}}
										label={choiceLetter}
										isActive={activeZoomedChoiceIndex === index}
										choiceZoomLetterBlockPanelHeight={choiceZoomLetterBlockPanelHeight}
										animationDisabled={animationDisabled}
									/>
								)
							})}

										 
						</animated.div>
					}
				</Spring>		
			</React.Fragment>

		)
	}
}

export default LiveViewChoiceZoomLetterBlockPanel

					 
import React, { Component } from 'react'
import ImageSearchContainer from '../../containers/modals/ImageSearchContainer'
import Icon from '../misc/Icons'
import Button from '../uiKit/Button'
 
class EditRepoImageModal extends Component {
   
	 
 
	render() {
		


		return (
			<div className='repoDetailsImageSearchModal'>    
				<div className='modal-content'>
					<div className='modal-content-header'>
						<div className='modal-content-header-center'>
							<Icon name='newImage'/>
							<div className='modal-content-header-title'>
								Select Image
							</div>
						</div>
						<Button label='Cancel' onClickFunction={this.props.closeImageSearch}/>
					</div>						
					<ImageSearchContainer disableGifs={true}/>    
				</div>
			</div>
		)
	}
}


export default EditRepoImageModal

   
import React from 'react'
import EditorInsertImageModalContainer from '../../../containers/richEditor/EditorInsertImageModalContainer'
import ReactDOM from 'react-dom'
import store from '../../../store.js'
import {stopAllMediaPlayback} from '../../../prosemirror/utils/editorActions/stopAllMediaPlayback'

const modalId='editor-editImageModalContainer'

function closeInsertImageModal(onCloseModal){
	ReactDOM.render(null, document.getElementById(modalId))
	if(onCloseModal){
		onCloseModal()
	}	
} 

export function showInsertImageModal(insertPos,onCloseModal,isQuestion,questionPos,questionNode) {
	stopAllMediaPlayback()
	ReactDOM.render(
		<EditorInsertImageModalContainer 
			store={store}
			insertPos={insertPos}
			isQuestion={isQuestion}
			questionPos={questionPos}
			questionNode={questionNode}
			closeInsertImageModal={()=>{closeInsertImageModal(onCloseModal)}}
	/>, document.getElementById(modalId))
}
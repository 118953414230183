import React from 'react'
 

class PublishingFlowChecklistPage extends React.Component{

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Publishing Flow',{
				stage:'checklist',
			})
		}
	}



	render(){	
		

		return(
			<div className='publishingFlowModalPage publishingFlowModalPage--checklist'>
				<div className='publishingFlowModalPage-header'>
					Release Checklist 
				</div>		
				<div className='publishingFlowModalPage-subheader'>
					Some things to do before you release.
				</div>				
				<div className='publishingFlowModalPage-subheader'>
					Dev: We'll either add some stuff in here before release – like tips on making sure your pack is as good as possible – or we'll remove this stage (but then it's a bit abrupt into Add Subject)
				</div>				

			</div>

			
		)	
	}
				
}




export default PublishingFlowChecklistPage



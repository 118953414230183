import React from 'react';
import Icon from '../misc/Icons'


class KeyboardKey extends React.Component{

	render(){			

		return(
				<div className={'keyboardKey' + (this.props.plus ? ' keyboardKey--plus' : '')} >
					
					{this.props.enter &&
						<span>Enter</span>
					}
					{this.props.enter &&
						<Icon name="enterKey"/>
					}					
					
					{this.props.ctrlcmd && this.props.isMac &&
						<Icon name="key--cmd" />
					}

					{this.props.ctrlcmd && !this.props.isMac &&
						<span>Ctrl</span>
					}
					
					{this.props.shift &&
						<span>Shift</span>
					}

					{this.props.delete && this.props.isMac &&
						<Icon name="key--backSpace"/>
					}

					{this.props.delete && !this.props.isMac &&
						<span>Delete</span>
					}

					{this.props.letter &&
						<span>{this.props.letter}</span>
					}

					{this.props.arrowDown &&
						<Icon name="key--arrowDown" />
					}

					{this.props.arrowUp &&
						<Icon name="key--arrowUp" />
					}

					{this.props.arrowRight &&
						<Icon name="key--arrowRight" />
					}

					{this.props.arrowLeft &&
						<Icon name="key--arrowLeft" />
					}
					
				</div>
			)
	}
				
}






export default KeyboardKey;



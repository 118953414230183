import React from 'react';
import EditorIcon from '../../../misc/EditorIcons'
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import {formatMillisecondDuration} from '../../../../utils/formatMillisecondDuration'
import slideLayoutDefinitions from '../../../../staticData/slideLayoutDefinitions.js'

const { Handle } = Slider;



const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="slide-media--sound-player-seekBar-tooltip"
      overlay={`${value}`}
      visible={false}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};



class StaticSlideChoiceSoundPlayer extends React.Component{  

  constructor(props) {
    super(props)
    this.playSound=this.playSound.bind(this)
    this.pauseSound=this.pauseSound.bind(this)
    this.startTimer=this.startTimer.bind(this)
    this.seekTo=this.seekTo.bind(this)
    this.restartAudio=this.restartAudio.bind(this)
    this.onAudioEnd=this.onAudioEnd.bind(this)
 

    const url=`${props.sound.deliveryUrl}.mp3`


    this.audioElement = new Audio(url)
    this.audioElement.addEventListener('ended',this.onAudioEnd)
    this.timer=null
    let duration=props.sound.end-props.sound.start


    this.state = {
      isPlaying:false,      
      playerCurrentTime:0,
      duration:duration
    }  
}
  
  componentWillUnmount() {
    this.audioElement.pause()
    this.stopTimer()
  }

  onAudioEnd(){
    this.audioElement.currentTime = 0
    this.setState({isPlaying:false,playerCurrentTime:0})
    this.stopTimer()
  }

  
  updatePlayerCurrentTime(){
    const currentTime=this.audioElement.currentTime
    this.setState({playerCurrentTime:currentTime})
  } 

  startTimer(){
    this.timer = setInterval(() => {
      this.updatePlayerCurrentTime()
    }, 50)
  }
  stopTimer(){
    if(this.timer){
      clearInterval(this.timer)
      this.timer=null
   }
  }


  playSound(){
    this.setState({isPlaying:true})
    this.startTimer()
    this.audioElement.play()
  }

  pauseSound(){
    this.stopTimer()
    this.setState({isPlaying:false})
    this.audioElement.pause()
  }

  seekTo(seconds){
    this.audioElement.currentTime=seconds
    if(!this.timer){
      this.setState({playerCurrentTime:seconds})
    }
  }

  restartAudio(){
    this.audioElement.currentTime=0
  }


  

  render(){ 

  	const {slideWidth, questionChoiceCount} = this.props
    const {isPlaying,duration} = this.state
    
    const slideScale = slideWidth / 1280
    const inverseScale = 1 / slideScale
    // prevent and other elements icons getting too big
    const ceilingInverseScale = 1.55 

    const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)


    // const numberOfChoices = 2
    // const numberOfChoices = 3
    const numberOfChoices = questionChoiceCount
  
    const mediaWidth = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${questionChoiceCount}Choices`].width
    // const mediaHeight = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${questionChoiceCount}Choices`].height

    const mediaInternalSidePadding = 5

    const soundPlayerWidth = mediaWidth - mediaInternalSidePadding


    const progress = this.state.playerCurrentTime
    const lengthSeconds = duration // passed through

    
    const scaledSoundPlayerWidth = soundPlayerWidth / UIInverseScale

    const soundClipTitle = this.props.sound.title

    // VISUALIZER TEST
    const visualizerValue = 0 // between 0 and 1
    const visualizerTransformMaxScale = 2
    const visualizerTransformMinScale = 1

    const visualizerTransformScale = 1 + ((visualizerTransformMaxScale - visualizerTransformMinScale) * visualizerValue)

    let displayMillisecondGranularity = true
    let displayHourGranularity=false
    if((this.state.duration) >1){ // if over 1 second 
      displayMillisecondGranularity = false // don't show millisecond granularity
      if(this.state.duration>60*60){
        displayHourGranularity=true
      }
    }

    // matching to video choices
    let heightBottomTimestampContainer = 83
    if(numberOfChoices === 2){
      heightBottomTimestampContainer = 81 
    }


	  return (

	  		<div className={'slide-choice-media slide-choice-media--sound' + (progress > 0 ? ' slide-choice-media--sound--started' : ' slide-choice-media--sound--initial')}>
          {/* PLAY/PAUSE BTN */}
          <div style={{transform:`scale(${UIInverseScale})`}} className='slide-choice-media--sound-center'>            
            {isPlaying &&
              <button onClick={this.pauseSound} className='slide-choice-media--sound-playPauseBtn'>
                <EditorIcon name='playbackPause' /> 
              </button>
            }
            {!isPlaying &&
              <button onClick={this.playSound} className='slide-choice-media--sound-playPauseBtn'>
                <EditorIcon name='playbackPlay' />
                <EditorIcon name='insertSound' /> 
              </button>
            }            
          </div>

          <div style={{transform:`scale(${UIInverseScale})`}} className='slide-choice-media--sound-visualizerContainer'>
            <div style={{transform:`scale(${visualizerTransformScale})`}} className='slide-choice-media--sound-visualizer' />
          </div>

          <div style={{width: `${Math.floor(scaledSoundPlayerWidth)}px`, height:`${heightBottomTimestampContainer / UIInverseScale}px`, transform:`scale(${UIInverseScale})`}} className='slide-choice-media--sound-bottom'>
            <div className='slide-choice-media--sound-bottom-timestampContainer'>
              
              {progress === 0 && !isPlaying &&
                <div style={{width: `${scaledSoundPlayerWidth - 12}px`}} className='slide-choice-media--sound-initialTitleAndTimestamp'>
                  {!this.props.hideSoundTitles && // used for sync classSafe mode
                    <div className='slide-choice-media--sound-initialTitleAndTimestamp-title'>
                      {soundClipTitle}
                    </div>
                  }
                  <div className='slide-choice-media--sound-initialTitleAndTimestamp-timestamp'>                    
                    {formatMillisecondDuration(this.state.duration*1000,displayMillisecondGranularity,displayHourGranularity)}          
                  </div>
                </div>
              }
            
            </div>

            {(isPlaying || progress > 0) &&
            <div className={'slide-choice-media--sound-bottom-seekBarContainer ' + (numberOfChoices ? `slide-choice-media--sound-bottom-seekBarContainer--choiceCount--${numberOfChoices}`:'')}>
                <Slider 
                  onChange={(value)=>{this.seekTo(value)}}
                  value={this.state.playerCurrentTime} 
                  step={0.01}
                  min={0} 
                  max={lengthSeconds} 
                  defaultValue={0} 
                  handle={handle}
                />              
            </div>
            }                     
          </div>

	     	 </div>
	  )
	}
}

export default StaticSlideChoiceSoundPlayer


import includes from 'lodash/includes'
import api from '../api'
import { fetchUser, fetchUserSignInMethods } from './user'
// import {fetchConfig} from './userConfig'
import { fetchConfiguration } from './configuration'
import { fetchFolders, fetchFoldersPreview } from './folders'
import { fetchSets, fetchSetsPreview } from './sets'
import { fetchQuestions, fetchQuestionsPreview } from './questions'
import { fetchSetPolls, fetchQuestionPolls } from './polls'
import { fetchRecentActivities } from './recentActivities'
import { fetchMeta } from './meta'
import { fetchSectionControl } from './sectionControl'
import { fetchControl } from './control'
import { fetchArchivedSections } from './sections'
import { fetchService } from './service'
import { fetchBilling } from './billing'
import { loadRichEditorData } from './loadRichEditorData'
import { fetchGeoInfo } from './geoInfo'
import { fetchRepos, fetchAPublishedRepoByShortId } from './repos'
import { fetchSearchKey } from './searchKey'
import { fetchMobileInfo } from './mobileInfo'
import { fetchReviewsForUser } from './reviews'
import { fetchSubmissions } from './submissions'
import { fetchIntegrations } from './integrations'
import { sectionPresencePusherSetup } from '../utils/pusher/sectionPresencePusherSetup'
import discoverRepoIds from '../staticData/discoverRepoIds'

// Loads app data for all pages except question/set editor (which uses loadRichEditorData)

const { biologyRepoShortIds } = discoverRepoIds
const { mathsRepoShortIds } = discoverRepoIds
const { keyTextsRepoShortIds } = discoverRepoIds

export function loadInitialData(userId) {
  if (includes(window.location.pathname, 'seteditor')) {
    const res = window.location.pathname.match('/seteditor/(.*)/output')
    let setId
    if (res && res.length === 2) {
      [, setId] = res
    } else {
      const res2 = window.location.pathname.match('/seteditor/(.*)')
      if (res2 && res2.length === 2) {
        [, setId] = res2
      }
    }
    const isSet = true
    return loadRichEditorData(setId, userId, isSet)
  } if (includes(window.location.pathname, 'editor')) {
    const res = window.location.pathname.match('/editor/(.*)')
    let questionId
    if (res && res.length === 2) {
      [, questionId] = res
    }
    const isSet = false
    return loadRichEditorData(questionId, userId, isSet)
  }
  return (dispatch) => {
    api.batchedFetch('/sections?archived=false&populated=true')
      .then((response) => {
        dispatch({ type: 'FETCH_All_SECTIONS_SUCCESS', response })
        response.forEach((section) => {
          dispatch(fetchSectionControl(section.id))
          if (section.studentAccess.enabled) {
            sectionPresencePusherSetup(section.id)
          }
        })
      })
      .catch(() => {})

    const lastViewedDiscover = JSON.parse(localStorage.getItem('lastViewedDiscover'))
    // Load data for last used discover subject to reduce loading time when visit discover
    if (lastViewedDiscover && lastViewedDiscover.subject && !includes(window.location.pathname, 'discover')) {
      const { subject } = lastViewedDiscover
      if (subject === 'earlyMath') {
        mathsRepoShortIds.forEach((shortId) => {
          dispatch(fetchAPublishedRepoByShortId(shortId))
            .catch(() => {})
        })
      } else if (subject === 'biology') {
        biologyRepoShortIds.forEach((shortId) => {
          dispatch(fetchAPublishedRepoByShortId(shortId))
            .catch(() => {})
        })
      } else if (subject === 'keyTexts') {
        keyTextsRepoShortIds.forEach((shortId) => {
          dispatch(fetchAPublishedRepoByShortId(shortId))
            .catch(() => {})
        })
      }
    }

    dispatch(fetchSearchKey())
    dispatch(fetchReviewsForUser())
    dispatch(fetchFolders())
    dispatch(fetchRepos())
    dispatch(fetchSets())
    dispatch(fetchSetsPreview())
    dispatch(fetchQuestionsPreview())
    dispatch(fetchFoldersPreview())
    dispatch(fetchQuestions())
    dispatch(fetchService())
    dispatch(fetchIntegrations())
    dispatch(fetchUser(userId))
    dispatch(fetchUserSignInMethods(userId))
    dispatch(fetchRecentActivities())
    dispatch(fetchSetPolls())
    dispatch(fetchQuestionPolls())
    dispatch(fetchMeta())
    dispatch(fetchControl())
    dispatch(fetchArchivedSections())
    dispatch(fetchBilling())
    dispatch(fetchGeoInfo())
    dispatch(fetchSubmissions())
    dispatch(fetchMobileInfo())
    dispatch(fetchConfiguration())
  }
}

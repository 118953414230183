import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getLastLocation } from '../routing/LastLocationProvider'
import find from 'lodash/find'
import includes from 'lodash/includes'
import Icon from '../misc/Icons' 
 
class BackButton extends React.Component{

	render(){	
		const previousLocation=getLastLocation()

		let backLabel
		if(previousLocation){	
			if(previousLocation.pathname === '/reports'){
				backLabel = 'Reports'
			}
			else if(previousLocation.pathname === '/library'){
				backLabel = 'Library'
			}
			else if(includes(previousLocation.pathname,'discover')){
				backLabel = 'Discover'
			}
			else if(includes(previousLocation.pathname,'classes') && !includes(previousLocation.pathname,'reports')){
				// backLabel = 'AP Social Sciences'

				const fullPath=previousLocation.pathname
				const res=fullPath.match('/classes/(.*)')
				if(res && res.length===2){
					const sectionId=res[1]
					const section=find(this.props.sections,{id:sectionId})
					if(section){
						backLabel=section.name
					}}
				// put in section name here
			}
			else if(includes(previousLocation.pathname, 'classes') && includes(previousLocation.pathname,'reports')){
				//backLabel = 'AP Social Sciences History'
				const fullPath=previousLocation.pathname
				const sectionId=fullPath.match('classes/(.*)/')[1]
				const section=find(this.props.sections,{id:sectionId})
				if(section){
					backLabel=`${section.name} Reports`
				}
				// put in section name here
			}
			else if(includes(previousLocation.pathname, 'repos')){
				//backLabel = 'AP Social Sciences History'
				const fullPath=previousLocation.pathname
				const res=fullPath.match('/packs/(.*)')
				if(res && res.length===2){
					const repoId=res[1]
					const repo=find(this.props.repos,{id:repoId})
					if(repo){
						backLabel=`${repo.name}`
					}}
			}


			else{
				backLabel = null
			}
		}
		else return null


		if(backLabel && previousLocation){
			return(
				<div className='backButton' onClick={()=>{this.props.history.goBack()}}>
					<Icon name='open-left-arrow' />
					<span>Back to {backLabel}</span>
				</div>
			)
		}else return null
	}
				
}


export default withRouter(connect(
	(state) => ({
		sections:state.sections,
		repos:state.repos
	}),
	{}
)(BackButton))


export function newSet(parentFolder,repo) {
	localStorage.removeItem('plickers-tab-sync') //clear tab sync to prevent new tab trying to sync old actions
	const setEditorWindow =window.open('/seteditor/newSet','_blank')
	setEditorWindow.parentFolder=parentFolder
	setEditorWindow.repo=repo

	
	//setEditorWindow.origin=origin
}


export function newQuestion(parentFolder,repo) {
	localStorage.removeItem('plickers-tab-sync')
	const questionEditorWindow =window.open('/editor/newquestion','_blank')
	questionEditorWindow.parentFolder=parentFolder
	questionEditorWindow.repo=repo

}


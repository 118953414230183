//<2mins ago
// 	savedTimestampLabel = 'Changes Saved'
// 	savedTimestampState = 'short'		
//>2min <60min	
//savedTimestampLabel = 'Saved 10m ago'
// 	savedTimestampState = 'medium'
//>60min
// 	savedTimestampLabel = 'Edited Today 4:45PM'
// 	//savedTimestampLabel = 'Edited Monday 2:45PM'
// 	//savedTimestampLabel = 'Edited Mon 24 Feb'
// 	//savedTimestampLabel = 'Edited 24 Dec 2017'
// 	savedTimestampState = 'long'


 

import moment from 'moment'


export function formatSaveStatusDate(timestamp,inSharedRepo) {
	const nowDate =moment()
	let savedTimestampLabel
	let savedTimestampState
	const today = moment(nowDate).startOf('day')
	const week = moment(nowDate).startOf('isoWeek')
	const year = moment(nowDate).startOf('isoYear')

	let shortVerb // under 1 day timespans 
	let longVerb // over 1 day timespans

	if(inSharedRepo){
		shortVerb = 'edited'
		longVerb = 'edited'
	}	
	else{
		shortVerb = 'Saved'
		longVerb = 'Edited'
	}
	
				


	if (moment(timestamp).isSame(today, 'd')) {
		var seconds = Math.floor((nowDate - moment(timestamp)) / 1000)
		var minutes = Math.floor(seconds / 60)
		if (minutes < 60) { //is in the last hour
			if (minutes < 2) { //is in the last min				
				savedTimestampLabel = `${shortVerb} just now`
				savedTimestampState = 'short'														
			} 
			else {
				savedTimestampLabel = `${shortVerb} ${minutes} min ago`
				savedTimestampState = 'medium'																
			}
		} else {
			savedTimestampLabel = `${shortVerb} Today ${moment(timestamp).format('h:mm A')}`
			savedTimestampState = 'long'
		}
	}


	else
	if (moment(timestamp).isSame(week, 'w')) {
		savedTimestampLabel = `${longVerb} ${moment(timestamp).format('dddd h:mm A')}`
		savedTimestampState = 'long'
	}
	else
	if (moment(timestamp).isSame(year, 'y')) {
		savedTimestampLabel=`${longVerb} ${moment(timestamp).format('ddd DD MMM')}`
		savedTimestampState = 'long'
	}

	else {
		savedTimestampLabel=`${longVerb} ${moment(timestamp).format('ddd DD MMM YYYY')}`
		savedTimestampState = 'long'
	}
	const formattedSaveStatusDate={savedTimestampLabel:savedTimestampLabel,savedTimestampState:savedTimestampState}

	return formattedSaveStatusDate

}
import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'  
import find from 'lodash/find'
import { connect } from 'react-redux'
import {updateFolder} from '../../actions/folders'
import {showLiveViewModal} from '../../utils/liveView/showLiveViewModal'
import {fetchQuestions,updateQuestionMeta} from '../../actions/questions'
import {fetchSets,updateSetContent,updateSetMeta} from '../../actions/sets'
import {fetchQuestionPolls, fetchSetPolls} from '../../actions/polls'
import {showNotification,hideNotification} from '../../actions/notifications'
import {showRepoSelectionModal,showSectionSelectionModal,showEditFolderModal,showRenameSetModal,showMoveToFolderModal,showConfirmModal,hideModal } from '../../actions/modals'
import {updateSetLock ,deleteLockForSet} from '../../actions/locks'   
import {createPoll} from '../../utils/createPoll' 
import {playItem} from '../../utils/playItem'
import {getLinkForRepo} from '../../utils/getLinkForRepo'
import {createCopyOfItem} from '../../utils/createCopyOfItem'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'
import {copyQuestion} from '../../utils/copyPaste'
import {removeFromQueue} from '../../utils/removeFromQueue'
import {moveToFolder} from '../../utils/moveToFolder'
import {getRepoforId} from '../../utils/getRepoforId'
import PreviewPanel from '../../components/previewPanel/PreviewPanel'
import MultiselectPreviewPanel from '../../components/previewPanel/MultiSelectPreviewPanel'

//side panel in Library TODO combine with RecentPreviewPanelContainer

class PreviewPanelContainer extends Component {

	constructor(props){
		super(props)
		this.navigateToFolder=this.navigateToFolder.bind(this)
		this.handleAddToQueue=this.handleAddToQueue.bind(this)	
		this.playItem=this.playItem.bind(this) 
		this.showRenameFolderModal=this.showRenameFolderModal.bind(this)
		this.showMoveToFolderModal=this.showMoveToFolderModal.bind(this)
		this.handleMoveToFolder=this.handleMoveToFolder.bind(this)
		this.queueItem=this.queueItem.bind(this)
		this.archiveItem=this.archiveItem.bind(this)
		this.archiveFolder=this.archiveFolder.bind(this)
		this.resetState=this.resetState.bind(this)
		this.editSetName=this.editSetName.bind(this)
		this.editFolderName=this.editFolderName.bind(this)
		this.handleArchiveItem=this.handleArchiveItem.bind(this)
		this.navigateToItemDetail=this.navigateToItemDetail.bind(this)
		this.createCopy=this.createCopy.bind(this)
		this.copyQuestionToClipboard=this.copyQuestionToClipboard.bind(this)

	}
 
	editSetName(set,name){
		const nowDate=new Date()
		if(name !== set.name && name!==''){ //only save if name has been edited and is non-empty
			const requestData = {...set,
				'name': name,
				'clientModified': nowDate,
			}
			return this.props.updateSetContent(requestData, set.id)
		}else{
			 return new Promise(() => {})
		}
	}

	editFolderName(folder,name){
		const nowDate=new Date()
		if(name !== folder.name && name!==''){//only save if name has been edited and is non-empty
			const requestData = {...folder,
				'name': name,
				'clientModified': nowDate,
			}
			this.props.updateFolder(requestData, folder.id)
		}
	}

	navigateToFolder(folderId){
		if(this.props.activePanelItem.repo){
			const parentRepo=getRepoforId(this.props.activePanelItem.repo)
			if(folderId){
				this.props.history.push(`${getLinkForRepo(parentRepo)}/${folderId}`)
			}else{
				this.props.history.push(`${getLinkForRepo(parentRepo)}`)
			}
		}
		else{
			if(folderId){
				this.props.history.push(`/library/${folderId}`)
			}else{
				this.props.history.push('/library')
			}
		}
		this.resetState()
	}

	handleAddToQueue(sectionId){
		this.props.createPoll(this.props.activePanelItem,sectionId)
	}

	queueItem(item,section,isBatchRequest,plannedTime){
		this.props.createPoll(item,section.id,isBatchRequest,plannedTime)
	}

	resetState(){
		this.props.resetSelectedCells()
		this.setState({
			filterTerm:'',
		})
	}

	showRenameFolderModal(folder){
		this.props.showEditFolderModal(folder)
	}
	
	showMoveToFolderModal(item){
		this.props.showMoveToFolderModal(item,this.resetState)
	}

	handleMoveToFolder(item,folder,numOfItems){
		let notificationMessage
		this.props.moveToFolder(item,folder.id).then(() => {
			if(numOfItems){
				notificationMessage=`${numOfItems} items to ${folder.name}`
			}else{
				notificationMessage=`${item.name || item.body} to ${folder.name}`
			}
			this.props.showNotification(notificationMessage,'Moved','default')
		})
	}

	playItem(item,section){
		if(section){
			this.props.playItem(item,section.id).then(() => {
				showLiveViewModal()
			})
		}else{
			this.props.showSectionSelectionModal(item)
		}
	}

	handleArchiveItem(item,isMultiSelect){
		if(item.repo){
			const parentRepo=getRepoforId(item.repo)
			if(parentRepo.shared){
				this.props.showConfirmModal(item,this.archiveItem,'archiveRepoItem')
			}else{
				this.archiveItem(item,isMultiSelect)
			}
		}else{
			this.archiveItem(item,isMultiSelect)
		}
	}

	archiveItem(item,isMultiSelect) {
		this.props.hideModal()
		const nowDate = new Date()
		let notificationMessage
		const requestData = {
			...item,
			clientModified: nowDate,
			archived: true
		}
		if(item.ancestors){ //folder
			return this.props.updateFolder(requestData, item.id).then((response) => {
				if(!isMultiSelect){
					notificationMessage=`${item.name}`
					this.props.showNotification(notificationMessage,'Sent to Trash ','destroy')
				}
				return response
			})
		}else if (item.questions) { //set
			return this.props.updateSetMeta(requestData, item.id).then((response) => {
				if(!isMultiSelect){
					notificationMessage=`${item.name}`
					this.props.showNotification(notificationMessage,'Sent to Trash','destroy')
				}
				return response
			})
		} else { //question
			return this.props.updateQuestionMeta(requestData, item.id).then((response) => {
				if(!isMultiSelect){
					notificationMessage=`${item.body}`  
					this.props.showNotification(notificationMessage,'Sent to Trash','destroy')      
				}
				return response
			})
		}
	}

	archiveFolder(folder){
		if(folder){
			const nowDate = new Date()
			let notificationMessage
			const requestData = {
				...folder,
				clientModified: nowDate,
				archived: true
			}		
			this.props.updateFolder(requestData, folder.id).then(() => {
				notificationMessage=`${folder.name}`   
				this.props.showNotification(notificationMessage,'Sent to Trash','destroy')
			})
		}
	}

	createCopy(item){
		this.props.createCopyOfItem(item).then((response) => {
			const notificationMessage=`${item.name ||getQuestionBodyLabel(item) }`   
			this.props.showNotification(notificationMessage,'Created a copy of','create')
			this.props.changeActiveItem(response)
		})
	}

	copyQuestionToClipboard(question){
		copyQuestion(question)
		const notificationMessage='to Clipboard'
		this.props.showNotification(notificationMessage,'Copied','default')
	}

	navigateToItemDetail(item){
		if(item.questions){
			this.props.history.push(`/set/${item.id}`)
		}else if(item.choices){
			this.props.history.push(`/question/${item.id}`)
		}
	}


	render() {
		const {activePanelItem,multiSelectContainsFolders,multiSelectContainsReadOnly,multiSelectItems}=this.props
		const numberOfMultiselect=Object.keys(this.props.multiSelectItems).length
		let panelActiveItem={}
		if(activePanelItem){
			if(!activePanelItem.archived){
				panelActiveItem=find(this.props.questions,{'id':activePanelItem.id})||find(this.props.sets,{'id':activePanelItem.id})|| find(this.props.folders,{'id':activePanelItem.id})||{}
			}else{
				panelActiveItem=find(this.props.archivedQuestions,{'id':activePanelItem.id})||find(this.props.archivedSets,{'id':activePanelItem.id})||{}
			}
		} 

		if(numberOfMultiselect <2){
			return (	 
				<div className='page-rightPanel'>
					<div className='sidePanelContainer'>
						<div className='sidePanel'>
							<PreviewPanel 
								copyQuestionToClipboard={this.copyQuestionToClipboard}
								archiveFolder={this.archiveFolder}
								showRenameFolderModal={this.showRenameFolderModal}
								handleNewSet={this.handleNewSet}
								archiveItem={this.handleArchiveItem}
								createCopy={this.createCopy}
								navigateToItemDetail={this.navigateToItemDetail}
								showMoveToFolderModal={this.showMoveToFolderModal}
								showRenameSetModal={this.props.showRenameSetModal}
								navigateToFolder={this.navigateToFolder}
								isLibrary={this.props.isLibrary}
								activeItem={panelActiveItem} 
								sections={this.props.sections}							
								queueSetPolls={this.props.queueSetPolls}
								queueQuestionPolls={this.props.queueQuestionPolls}
								addToQueue={this.handleAddToQueue}
								removeFromQueue={this.props.removeFromQueue}
								playItem={this.playItem}
								editSetName={this.editSetName}
								updateSetLock={this.props.updateSetLock}
								deleteLockForSet={this.props.deleteLockForSet}
								editFolderName={this.editFolderName}
								showRepoSelectionModal={this.props.showRepoSelectionModal}
							/>	
						</div>
					</div>					
				</div>
			)
		}else return(
			<div className='page-rightPanel'>
				<div className='sidePanelContainer'>
					<div className='sidePanel'>
						<MultiselectPreviewPanel 
							multiSelectItems={multiSelectItems}
							batchMoveToFolder={this.props.batchMoveToFolder}
							combineIntoSet={this.props.combineIntoSet}
							batchArchive={this.props.batchArchive}
							showMoveToFolderModal={this.props.showMoveToFolderModal}
							multiSelectContainsFolders={multiSelectContainsFolders}
							multiSelectContainsReadOnly={multiSelectContainsReadOnly}
							questionsInSetLimit={this.props.questionsInSetLimit}
							folders={this.props.folders}
							showRepoSelectionModal={this.props.showRepoSelectionModal}
						/>						
					</div>
				</div>					
			</div>
		)
	}
}

function mapStateToProps(state,ownProps) {	
	return {		
		sets:state.sets,
		questions:state.questions,
		queueSetPolls:state.queueSetPolls,
		queueQuestionPolls:state.queueQuestionPolls,
		sections:state.sections,
	}
}

export default withRouter(connect(
	mapStateToProps,
	{ 
		showRenameSetModal,
		showNotification,
		hideNotification,
		showMoveToFolderModal,
		updateSetContent,
		updateSetMeta,
		updateQuestionMeta,
		updateFolder,
		fetchQuestions,
		fetchSets,    
		fetchQuestionPolls, 
		fetchSetPolls,
		moveToFolder,
		showConfirmModal,   
		createPoll,
		playItem,
		showSectionSelectionModal,
		showEditFolderModal,
		hideModal,
		removeFromQueue,
		createCopyOfItem,
		showRepoSelectionModal,
		updateSetLock,
		deleteLockForSet
	}
)(PreviewPanelContainer))
import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import TopPageHeader from '../../components/pageHeaders/TopPageHeader'
import Icon from '../../components/misc/Icons'
import StaticQuestionSlide from '../../components/slides/StaticQuestionSlide'
import Measure from 'react-measure'


class SetQuestionDetailContainer extends Component {

	constructor() {
		super()
		this.state={
			centerPanelInnerWidth:0
		}
	}

	render() {
	 const {question}=this.props   
		if(question){
			return( 
				<div className='page page--wideLayout setQuestionDetail'>
					<div className='page-leftPanel' />        	
					<div className='page-centerPanel'>
						<Measure onMeasure={(dimensions) => {this.setState({centerPanelInnerWidth:dimensions.width})}}>
							<div className='page-centerPanel-innerMeasureSpacer'/>
						</Measure>

						<TopPageHeader
							location='setQuestionDetail' 
							height={203}
							leftBadge={<Icon name='chevron-left' />}
							leftBadgeClickFunction={()=>{this.props.history.goBack()}}						
							header={question.body}
							multiLineHeader
							borderBottom
						/>

						{question &&
							<div style={{height: `${this.state.centerPanelInnerWidth / 1.6}px`}} className='setQuestionDetail-slide'>
								<StaticQuestionSlide question={question} stage='ask' mode='view' displayWidth={this.state.centerPanelInnerWidth - 2} previewCard={true} />
							</div>				
						}
						
					</div> 
					<div className='page-rightPanel' />
				</div> 
			)} else return null
	}
}

function mapStateToProps(state,ownProps) {
	var currentSet= _.find(state.sets, {'id':ownProps.match.params.id})
	if(currentSet){
	 var currentQuestion= _.find(currentSet.questions, {'questionId':ownProps.match.params.questionId})
	}
	return {
		set:currentSet,
		question:currentQuestion		
	}
}

export default withRouter(connect(mapStateToProps,
	{}
)(SetQuestionDetailContainer))



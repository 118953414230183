import React from 'react'
import Icon from '../misc/Icons'
import EditorIcon from '../misc/EditorIcons'

class AutoPlaySetupBrowserInstructionStepWrapper extends React.Component{

	render(){			

		return(		
			<div className='autoPlaySetup-selectedBrowser-browserInstructions-step'>
				<div className='autoPlaySetup-selectedBrowser-browserInstructions-step-number'>
					{this.props.stepNumber}
				</div>
				<div className='autoPlaySetup-selectedBrowser-browserInstructions-step-label'>
					{this.props.children}
				</div>
			</div>
			
		)
	}
}



class AutoPlaySetupBrowserInstructions extends React.Component{

	render(){	

		const {browser} = this.props

		return(		
			<div className='autoPlaySetup-selectedBrowser-browserInstructions'>

				{browser === 'chrome' &&
					<React.Fragment>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='1'>
							Click the <emph><EditorIcon name='lock'/> icon</emph> next to plickers.com at the top
						</AutoPlaySetupBrowserInstructionStepWrapper>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='2' >
							Change the <emph><EditorIcon name='playbackVolume'/> Sound </emph>setting to <emph>Allow</emph>.
						</AutoPlaySetupBrowserInstructionStepWrapper>
					</React.Fragment>
				}

				{/*}
				{browser === 'firefox' && autoplayFailed &&
					<React.Fragment>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='1'>
							Click the <emph><EditorIcon name='firefoxAutoplay'/><EditorIcon name='firefoxAutoplay'/> icon</emph> next to plickers.com at the top.
						</AutoPlaySetupBrowserInstructionStepWrapper>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='2' >
							Change the <emph><EditorIcon name='firefoxAutoplay'/> Autoplay</emph> setting from <emph>Block Audio and Video</emph> to <emph>Allow Audio and Video</emph>.
						</AutoPlaySetupBrowserInstructionStepWrapper>						
					</React.Fragment>
				}*/}

				{browser === 'firefox' &&
					<React.Fragment>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='1'>
							Click the <emph><EditorIcon name='firefoxSettings'/><EditorIcon name='firefoxAutoplay'/> icon</emph> next to plickers.com at the top.
						</AutoPlaySetupBrowserInstructionStepWrapper>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='2' >
							Change the <emph>Autoplay</emph> setting to <emph>Allow Audio and Video</emph>.
						</AutoPlaySetupBrowserInstructionStepWrapper>						
					</React.Fragment>
				}

				{browser === 'safari' &&
					<React.Fragment>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='1'>
							Click <emph>Safari</emph> in the macOS menu bar.
						</AutoPlaySetupBrowserInstructionStepWrapper>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='2' >
							Click <emph>Settings for plickers.com</emph> (it may be called "Settings for this website")
						</AutoPlaySetupBrowserInstructionStepWrapper>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='3' >
							Change the <emph>Autoplay</emph> setting to <emph>Allow All Auto-Play</emph>.
						</AutoPlaySetupBrowserInstructionStepWrapper>
					</React.Fragment>
				}
				
				{browser === 'edge' &&
					<React.Fragment>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='1'>
							Click the <emph>three dots <Icon name='three-dots-horizontal'/></emph> in the top right<br/> of the Edge window.
						</AutoPlaySetupBrowserInstructionStepWrapper>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='2' >
							Click <emph>Settings</emph>.
						</AutoPlaySetupBrowserInstructionStepWrapper>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='3' >												
							Click <emph>Cookies and Site permissions</emph>.
						</AutoPlaySetupBrowserInstructionStepWrapper>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='4' >						
							Click <emph>Media autoplay</emph> from the list.
						</AutoPlaySetupBrowserInstructionStepWrapper>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='5' >												
							New to Allow, click <emph>Add</emph>.
						</AutoPlaySetupBrowserInstructionStepWrapper>
						<AutoPlaySetupBrowserInstructionStepWrapper stepNumber='6' >												
							Type <emph>www.plickers.com</emph> and click Add.
						</AutoPlaySetupBrowserInstructionStepWrapper>
					</React.Fragment>
				}
				
				
			</div>
			
		)
	}
}




export default AutoPlaySetupBrowserInstructions

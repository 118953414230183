import React from 'react'
import ModalWrapper from './ModalWrapper'
import ConfirmEditStudentCardContainer from '../../containers/sections/ConfirmEditStudentCardContainer'

 
const ConfirmEditStudentCardModal= props => {

	return (
		 <div className={'confirmEditStudentCard-modal ' + (props.selectedOption.swapStudent ? 'is--swapStudent' : 'is--notSwapStudent')}>
			<ModalWrapper
				{...props}
				modalStyle='dark'
				title={(props.selectedOption.swapStudent ? 'Edit Card Numbers' : 'Edit Card Number')}       
			>
				<ConfirmEditStudentCardContainer {...props} />
				
			</ModalWrapper>

		</div>

	)
}

export default ConfirmEditStudentCardModal


ConfirmEditStudentCardModal.defaultProps = {
	selectedOption: {}
}

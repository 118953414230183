import React, { Component } from 'react'
import range from 'lodash/range'
import ImportQuestionsTopBar from './ImportQuestionsTopBar'
import ImportQuestionsRightPanel from './ImportQuestionsRightPanel'
import ImportQuestionsInputAreaLeftGutter from './ImportQuestionsInputAreaLeftGutter'
import ImportQuestionsInputAreaRightGutter from './ImportQuestionsInputAreaRightGutter'
import ImportQuestionsInputAreaTextArea from './ImportQuestionsInputAreaTextArea'
import ImportQuestionsNotification from './ImportQuestionsNotification'
import ImportQuestionsFreeLimitMessage from './ImportQuestionsFreeLimitMessage'


class ImportQuestionsModal extends Component {
     
 
	render() {		
		const questionNumberArray=range(this.props.maxQuestionCount)

		return ( 
			<div className='importQuestions'>	
												
				<ImportQuestionsNotification 
					notificationTimestamp={this.props.importQuestionsNotificationTimestamp}
					showNotification={this.props.showImportQuestionsNotification}
					notificationType={this.props.importQuestionsNotificationType}	
					clearNotification={this.props.clearImportQuestionsNotification}				
					undoLastChange={this.props.undoLastChange}
					importQuestionsNotificationSearchTerm={this.props.importQuestionsNotificationSearchTerm}
				/>
 
				<ImportQuestionsFreeLimitMessage 
					questionsInSetLimit={this.props.questionsInSetLimit}
					showFreeLimitMessage={this.props.contentOverFreeLimit}
				/>
				
				<ImportQuestionsTopBar
					hideModal={this.props.hideModal} 
					setChoicesPerQuestion={this.props.setChoicesPerQuestion}
					choicesPerQuestion={this.props.choicesPerQuestion}
					canUndo={this.props.canUndo}
					undoLastChange={this.props.undoLastChange}
					openFeedbackModal={this.props.openFeedbackModal}
				/>

				

				<div className='importQuestionsCenterContainer'>

					<div className='importQuestions-inputArea'>
						<ImportQuestionsInputAreaLeftGutter
							browser={this.props.browser}
							setCorrectAnswer={this.props.setCorrectAnswer}
							questionNumberArray={questionNumberArray}
							choicesObject={this.props.choicesObject}
							choicesPerQuestion={this.props.choicesPerQuestion}
							setToSurvey={this.props.setToSurvey}
							setToGraded={this.props.setToGraded}
							activeQuestionIndex={this.props.activeQuestionIndex}
							remainingQuestionsInSetLimit={this.props.remainingQuestionsInSetLimit}
						/>
						<ImportQuestionsInputAreaTextArea 
							getLineNumber={this.props.getLineNumber}
							textAreaOnPaste={this.props.onPaste}
							textAreaValue={this.props.value}
							textAreaOnChange={this.props.onChange}
							choicesPerQuestion={this.props.choicesPerQuestion}
							questionNumberArray={questionNumberArray}
							remainingQuestionsInSetLimit={this.props.remainingQuestionsInSetLimit}
							maxQuestionCount={this.props.maxQuestionCount}
						/>
						<ImportQuestionsInputAreaRightGutter
							questionNumberArray={questionNumberArray}
							choicesPerQuestion={this.props.choicesPerQuestion}
							questionsPreview={this.props.questionsPreview}
						/>
					</div>

					<ImportQuestionsRightPanel 
						questionsInSetLimit={this.props.questionsInSetLimit}
						removeEmbeddedQuestionNumbers={this.props.removeEmbeddedQuestionNumbers}
						removeEmbeddedChoiceLetters={this.props.removeEmbeddedChoiceLetters}
						removeBulletPoints={this.props.removeBulletPoints}
						isKahootFormat={this.props.isKahootFormat}
						removeKahootFormatting={this.props.removeKahootFormatting}						
						importQuestions={this.props.importQuestions}
						questionsPreview={this.props.questionsPreview}
						matchCase={this.props.matchCase}
						deleteLineWithMatch={this.props.deleteLineWithMatch}
						toggleMatchCase={this.props.toggleMatchCase}
						toggleDeleteLineWithMatch={this.props.toggleDeleteLineWithMatch}
						findAndRemove={this.props.findAndRemove}
						choicesPerQuestion={this.props.choicesPerQuestion}
						questionPreviewTimestamp={this.props.questionPreviewTimestamp}
						canAutoclean={this.props.canAutoclean}
						autoclean={this.props.autoclean}
						activeQuestionIndex={this.props.activeQuestionIndex}						
						questionNumbersDetected={this.props.questionNumbersDetected}
						choiceLettersDetected={this.props.choiceLettersDetected}
						bulletPointsDetected={this.props.bulletPointsDetected}
						submitLoading={this.props.submitLoading}
						autocleanOnImport={this.props.autocleanOnImport}
						toggleAutocleanOnImport={this.props.toggleAutocleanOnImport}
						value={this.props.value}
						showHelpVideoModal={this.props.showHelpVideoModal}
					/>				

				</div>

				
			</div>
		)
	}
}


export default ImportQuestionsModal



import React from 'react'

class AuthorPageRepoLinkLoader extends React.Component{
	render(){
		return(
			<div className='authorPageRepoLink authorPageRepoLink--loader'>
				<div className='authorPageRepoLink-repoImageContainer'>
					<div className='authorPageRepoLink-repoImageLoader'/>
				</div>
				<div className='authorPageRepoLink-title'>
					<div className='authorPageRepoLink-title-pageLoaderPlaceholder'/>					
				</div>									
				<div className='authorPageRepoLink-metadata'>					
					<div className='authorPageRepoLink-metadata-pageLoaderPlaceholder'/>
				</div>												
				<div className='authorPageRepoLink-description'>					
					<div className='authorPageRepoLink-description-pageLoaderPlaceholder'/>
				</div>
				
				<div className='authorPageRepoLink-rightArrow' />									
			</div>
		)
	}
}

export default AuthorPageRepoLinkLoader
import richQuestionTemplate from './richQuestionTemplate'

export default function richSetTemplate() {
	const nowDate = new Date()
	//create a set with the default stuff
	const newSet = {
		'folder': null,
		'repo':null,
		'name': 'Untitled Set',
		'description': 'Empty description',
		'questions': [richQuestionTemplate()],
		'clientModified': nowDate,
		'archived': false,
		'userCreated': nowDate,
	}
	return newSet
}

 
import React from 'react'
import ReactDOM from 'react-dom';
import ChoiceSoundReactComponent from './ChoiceSoundReactComponent'
import {showEditSoundModal} from '../../utils/showEditSoundModal'
import {playMediaNode} from '../../../../prosemirror/utils/editorActions/playMediaNode'


class ChoiceSoundNodeView {

  constructor(node, view, getPos) {
    this.getNode=this.getNode.bind(this)
    this.deleteSound=this.deleteSound.bind(this)
    this.showEditSoundModal=this.showEditSoundModal.bind(this)
    this.playSound=this.playSound.bind(this)
    this.node = node
    this.getPos=getPos

    const {deliveryUrl,start,end,title,isPlaying,fullDuration,isClipped,questionChoiceCount,slideWidth} = node.attrs


    this.dom = document.createElement("div")
    this.dom.className="slide-choice-mediaContainer slide-choice-mediaContainer--sound"

   
    ReactDOM.render(
      <ChoiceSoundReactComponent 
        key={deliveryUrl}
        questionChoiceCount={questionChoiceCount}
        deliveryUrl={deliveryUrl} 
        title={title}
        fullDuration={fullDuration}
        start={start}
        end={end}
        isClipped={isClipped}
        showEditSoundModal={this.showEditSoundModal}
        deleteSound={this.deleteSound} 
        isPlaying={isPlaying}
        playSound={this.playSound}
        slideWidth={slideWidth}
        />, this.dom)


  }  

  playSound(){
    const position=this.getPos()
    playMediaNode(position)
  }

  showEditSoundModal(focusTitle){
    const {fileId,title,fullDuration,start,end,attribution} = this.node.attrs
    const nodePos=this.getPos()
    showEditSoundModal(fileId,title,attribution,fullDuration,start,end,this.node,nodePos,focusTitle)
  }


  deleteSound(){
    const node=this.getNode()
    const position=this.getPos()
    window.view.dispatch(window.view.state.tr.delete(position,position+node.nodeSize))
  }

   update(node) {
  if (node.type !== this.node.type) return false
    const {deliveryUrl,title,start,end,fileId,isPlaying,isClipped,questionChoiceCount,fullDuration,slideWidth} = node.attrs
    const oldStart=this.node.attrs.start
    const oldEnd=this.node.attrs.oldEnd
    const oldTitle=this.node.attrs.title
    const oldFileId=this.node.attrs.fileId
    const oldIsPlaying=this.node.attrs.isPlaying
    const oldQuestionChoiceCount=this.node.attrs.questionChoiceCount
    const oldSlideWidth=this.node.attrs.slideWidth

   if(oldStart !==start
    || oldEnd !== end
    || oldFileId!==fileId
    || oldTitle!==title
    || oldIsPlaying !==isPlaying
    || oldQuestionChoiceCount!==questionChoiceCount
    || oldSlideWidth !== slideWidth
    ){
         

    
    ReactDOM.render(
      <ChoiceSoundReactComponent 
        key={deliveryUrl}
        questionChoiceCount={questionChoiceCount}
        deliveryUrl={deliveryUrl} 
        title={title}
        fullDuration={fullDuration}
        start={start}
        end={end}
        isClipped={isClipped}
        showEditSoundModal={this.showEditSoundModal}
        deleteSound={this.deleteSound} 
        isPlaying={isPlaying}
        playSound={this.playSound}
        slideWidth={slideWidth}
        />, this.dom)

  }
    this.node=node
    return true
  }





  getNode(){
    return this.node
  }

  destroy() {
    ReactDOM.unmountComponentAtNode(this.dom)
  }

}

export default ChoiceSoundNodeView
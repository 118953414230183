import api from '../api'
import {calibratedCurrentTime} from '../utils/calibratedCurrentTime'
import * as Sentry from '@sentry/browser'

export function fetchSectionControl(sectionId) {
		if(!sectionId){ //TEMP for debugging
		Sentry.withScope(scope => {
			scope.setExtra('sectionId', sectionId)
			Sentry.captureException('Request section control with no section id')
		})
	}
	return (dispatch) => api.fetch(`/sections/${sectionId}/control/`)
		.then((response) => {
			dispatch({ type: 'FETCH_SECTION_CONTROL_SUCCESS',response,sectionId})
		})
		.catch(err => {})
}

export function updateSectionControl(sectionId,data) {
	const requestData={...data,controlled:calibratedCurrentTime()}
		return (dispatch) =>{ 
			return api.put(`/sections/${sectionId}/control/`,requestData)
				.then((response) => {
					dispatch({ type: 'UPDATE_SECTION_CONTROL_SUCCESS', response,sectionId })
					return response
				})
				.catch(err => {})
	}
}

export function sectionControlReceived(data) {
	return (dispatch) => {
		const sectionId=data.section
		const response=data.message
		dispatch({ type: 'UPDATE_SECTION_CONTROL_SUCCESS', response,sectionId })
	}	
}
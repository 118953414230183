import React from 'react'
import Icon from '../../misc/Icons'
import {formatSaveStatusDate} from '../../../utils/formatSaveStatusDate'
import {getUserNameForId} from '../../../utils/getUserNameForId' 
import {getUserForId} from '../../../utils/getUserForId' 
import {getDisplayNameForUser} from '../../../utils/getDisplayNameForUser' 
import ProfilePic from '../../ProfilePic'
import isEmpty from 'lodash/isEmpty'

//Label that shows various statuses e.g. is saving, unable to save, last saved at X etc

class EditorSaveStatus extends React.Component{  
	render(){
		const {isSaving,unableToSave,item,parentRepo,itemIsLockedByAnotherUser,lock,permissions}=this.props
		let shouldRender=true //dont render untill have all info needed e.g. lastEditedUser
		if(item && item.repo && !parentRepo){
			shouldRender=false
		}
		let saved=false
		if(!isSaving && !unableToSave){
			saved=true
		}
		let isShared = false
		let lastEditedByUserObject
		let lastEditedByUserName

		if(parentRepo && parentRepo.shared){			
			isShared = true
			lastEditedByUserObject = getUserForId(item.lastEditedBy,parentRepo.id)
			lastEditedByUserName= getDisplayNameForUser(lastEditedByUserObject) 
			if(isEmpty(lastEditedByUserObject) && !permissions){
				shouldRender=false
			}			
		}

		let isPublicConsumer = false
		let publicAuthor = false

		if(parentRepo && parentRepo.published){
			if(parentRepo.published && parentRepo.role === 'consumer'){
				isPublicConsumer = true
			}
			if(parentRepo.author){
				publicAuthor = parentRepo.author
			}
		}
	
		const formattedDate=formatSaveStatusDate(item.clientModified,isShared)
		const savedTimestampLabel=formattedDate.savedTimestampLabel
		const savedTimestampState=formattedDate.savedTimestampState

		let editingUserName
		if(itemIsLockedByAnotherUser && lock && parentRepo){
			editingUserName= getUserNameForId(lock.user,parentRepo.id)
		}

		if(item.id && shouldRender){ //check is it is a new set/question
			return(
				<div className={'editor-saveStatus ' + 
						(savedTimestampState ? `timestampState--${savedTimestampState} ` : '') +
						(saved ? ' is--saved ' : '') +
						(isSaving ? ' is--saving ' : '') +
						(unableToSave ? ' is--unableToSave ' : '')
				}>
				
						{/* Default not-shared saved state*/}
						{!isSaving && !unableToSave && !isPublicConsumer && (!isShared || isEmpty(lastEditedByUserObject)) && !itemIsLockedByAnotherUser &&

							<React.Fragment>
								<div className='editor-saveStatus-label'>
									{savedTimestampLabel}								 	
								</div>
								<div className='editor-saveStatus-icon'>
									<Icon name='check-circle' />
								</div>
							</React.Fragment>											
						}

						{/* Default shared saved state*/}
						{isShared && !isSaving && !unableToSave && !itemIsLockedByAnotherUser && !isEmpty(lastEditedByUserObject) &&
							<React.Fragment>
								<ProfilePic size='28' borderRadius='3' user={lastEditedByUserObject} />				
								<div className='editor-saveStatus-label'>
									{lastEditedByUserName} {savedTimestampLabel}
								</div>
							</React.Fragment>
						}

						{/* Public Consumer  */}
						{isPublicConsumer &&
							<a 
								className='editor-saveStatus-publicAuthorUsernameLink'
								target="_blank" 
								rel="noopener noreferrer"
								href={`/${publicAuthor.username}`}								
							>
									<ProfilePic size='28' borderRadius='14' user={publicAuthor} />				
									<div className='editor-saveStatus-publicAuthorUsernameLink-username'>
										{publicAuthor.username}
									</div>
							</a>
						}

						{/* Locked out by someone else */}
						{isShared && !isSaving && !unableToSave && itemIsLockedByAnotherUser &&
							<React.Fragment>															
								<div className='editor-saveStatus-label editor-saveStatus-label--isEditing'>									
									{editingUserName}
									<div className='editor-saveStatus-label-liveEditPulse'>
										<div className='editor-saveStatus-label-liveEditPulse-dot'/>
										<div className='editor-saveStatus-label-liveEditPulse-dot'/>
										<div className='editor-saveStatus-label-liveEditPulse-dot'/>
									</div>									
								</div>
							</React.Fragment>
						}
										
						{unableToSave &&
							<React.Fragment>
								<div className='editor-saveStatus-icon'>
									<Icon name='alert' />
								</div>
								<div className='editor-saveStatus-label'>
									Unable to save
								</div>								
							</React.Fragment>
						}

						{!unableToSave && isSaving &&
							<React.Fragment>
					
								<div className='editor-saveStatus-icon editor-saveStatus-icon--isSavingSpinner'>
									<div className='editor-saveStatus-isSavingSpinner' />
								</div>

								<div className='editor-saveStatus-label'>
									Saving Changes
								</div>

							</React.Fragment>
						}					
					
				</div>
		)	
		}else return null	
	}
}

export default EditorSaveStatus

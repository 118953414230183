export function setChoiceToCorrect(choiceNode,nodePos) {
	const state = window.view.state
	let tr = window.view.state.tr

	state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if (node.attrs.activeslide && node.attrs.activeslide===true){
			state.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
				if (questionNode.type.attrs.correct) {
					if(questionPos !== nodePos){
						tr.setNodeMarkup(questionPos, null, {...questionNode.attrs,correct:false,isSurvey:false,isTouched:true})
					}
				}
				if(questionNode.type.name === "choiceGroup"){
					tr.setNodeMarkup(questionPos, null, {...questionNode.attrs,choicesComplete:true})
				}
			})
		}
	})
	
	tr.setMeta("triggerParseDoc", true) 
	tr.setNodeMarkup(nodePos, null, {...choiceNode.attrs,correct:true,isSurvey:false,isTouched:true})
	window.view.dispatch(tr)
}
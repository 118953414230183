import {setQuestionBodySelectionTransaction} from '../setQuestionBodySelectionTransaction'
import {stopAllMediaPlayback} from './stopAllMediaPlayback'

export function setActiveQuestion(questionId) {
	const editorView=window.view
	let tr = window.view.state.tr	
	let activeQuestionNode
	let activeQuestionPos
	editorView.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if (node.type.name === "question"){
			if(node.attrs.questionid===questionId){
				activeQuestionNode=node
				activeQuestionPos=pos
				tr.setNodeMarkup(pos, null, {...node.attrs,activeslide:true})
			}else{
				tr.setNodeMarkup(pos, null, {...node.attrs,activeslide:false})
			}
		}
	})     
	tr=setQuestionBodySelectionTransaction(tr,activeQuestionNode,activeQuestionPos) //set the cursor position to the end of the active question body
	tr.setMeta("triggerParseDoc", true) 
	window.view.dispatch(tr)
	stopAllMediaPlayback()
}
import {newFolder} from '../actions/folders'

export function createFolder(parentFolder,folderName,repoId) {
	const nowDate = new Date()
	const requestBody={
		'name':folderName,
		'parent':parentFolder,
		'clientModified':nowDate,
		'userCreated':nowDate,
		'archived': false,
		'repo':repoId
	}
	return newFolder(requestBody)
}
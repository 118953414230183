//Update custom date range in state
export function updateCustomDateRange(dateRange) {
	return (dispatch) => dispatch({ type: 'UPDATE_CUSTOM_DATE_RANGE',dateRange})    
}








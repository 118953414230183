export default function (state = {visible:false}, action) {
	switch (action.type) {

	case 'SHOW_AUTOPLAY_SETUP_MODAL':
		return {visible:true}
	case 'HIDE_AUTOPLAY_SETUP_MODAL':
		return {visible:false}
	
	case 'LOGOUT':
		return {visible:false}
	
	default:
		return state
	}
}

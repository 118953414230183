import React from 'react'
import ComplexIcon from '../misc/ComplexIcons'


class UpgradeProFeaturesSymbolRowSymbol extends React.Component{

	render(){	

		return(
			<div className='upgrade--proFeaturesSymbolRow-symbol'>
				<div className='upgrade--proFeaturesSymbolRow-symbol-iconContainer'>
					<ComplexIcon name={`proFeatureSymbol-${this.props.iconName}`} />
				</div>
				<div className='upgrade--proFeaturesSymbolRow-symbol-label'>
					{this.props.label}
				</div>				
			</div>
			
		)	
	}
				
}

class UpgradeProFeaturesSymbolRow extends React.Component{

	render(){	

		return(
			<div className='upgrade--proFeaturesSymbolRow'>
				
				<UpgradeProFeaturesSymbolRowSymbol 
					label='No Set Limits'
					iconName='limit'
				/>				
				<UpgradeProFeaturesSymbolRowSymbol 
					label='Unlimited Media Search'					
					iconName='images'
				/>				
				<UpgradeProFeaturesSymbolRowSymbol 
					label='Shared Packs'
					iconName='sharedPack'
				/>
				<UpgradeProFeaturesSymbolRowSymbol 
					label='Unlimited Scoresheet'
					iconName='scoresheet'
				/>

				<div className='upgrade--proFeaturesSymbolRow-bgContainer'>
					<ComplexIcon name='proFeatureSymbolRowCloud'/>
				</div>	

			</div>
			
		)	
	}
				
}

export default UpgradeProFeaturesSymbolRow



import React, { Component} from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import find from 'lodash/find'
import moment from 'moment'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import {hideLiveViewModal} from '../../utils/liveView/hideLiveViewModal'
import {calibratedCurrentTime} from '../../utils/calibratedCurrentTime'
import {updateControl,updateControlSettings,proposeControl} from '../../actions/control'
import {hideAutoplayFailedAlert} from '../../actions/autoplayFailedAlert'
import {resetControl} from '../../utils/liveView/resetControl'
import {getPollForControl,getSetPollForControl} from '../../utils/liveView/getPollForControl'
import LiveViewContainer from './LiveViewContainer'
import SyncLiveViewContainer from './SyncLiveViewContainer'
import {analyticsTrackStartScan} from '../../utils/analytics/analyticsTrackStartScan'
import {getLiveViewSettings} from '../../utils/getLiveViewSettings'
import {calculatePercentCorrect} from '../../utils/calculatePercentCorrect'


//LiveViewModalContainer is in the app for all authenticated routes. 
//isVisible if window location '/nowplaying' or #now-playing
//returns LiveViewContainer for standard and hybrid NP
//returns SyncLiveViewContainer for E Learning NP

//LiveViewModalContainer has some of the functionality that is shared between standard and E-Learning
//e.g. confetti celebration, minimise live view


function getNowPlayingMode(sectionId,section){
	const sectionNPDefaults = JSON.parse(localStorage.getItem('sectionNPDefaults'))
	let nowPlayingMode='liveView'
	if(sectionNPDefaults && sectionNPDefaults[sectionId]){
		nowPlayingMode=sectionNPDefaults[sectionId]
	}else if(section && section.studentAccess && section.studentAccess.enabled){
		nowPlayingMode='syncLiveView'
	}
	return nowPlayingMode
}

function getCountDownTimerStartSecondsLength(sectionId){
	const sectionCountdowns = JSON.parse(localStorage.getItem('sectionCountdowns'))
	let countDownTimerStartSecondsLength=null
	if(sectionCountdowns && sectionCountdowns[sectionId]){
		countDownTimerStartSecondsLength=sectionCountdowns[sectionId]
	}
	return countDownTimerStartSecondsLength
}

function saveDefaultNPModeLocalStorage(mode,sectionId){ //when you change the mode for a section we save this in local storage so its automatically selected next time you play something for that section
		const sectionNPDefaults = JSON.parse(localStorage.getItem('sectionNPDefaults'))
		let newSectionNPDefaults
		if(sectionNPDefaults){
			newSectionNPDefaults=sectionNPDefaults
			newSectionNPDefaults[sectionId]=mode
			
		}else{
			newSectionNPDefaults={}
			newSectionNPDefaults[sectionId]=mode
		}
		localStorage.setItem('sectionNPDefaults', JSON.stringify(newSectionNPDefaults))
	}

function saveSectionCountdownLocalStorage(seconds,sectionId){//save countdown length in local storage to use as default value next time
		const sectionCountdowns = JSON.parse(localStorage.getItem('sectionCountdowns'))
		let newSectionCountdowns
		if(sectionCountdowns){
			newSectionCountdowns=sectionCountdowns
			newSectionCountdowns[sectionId]=seconds
			
		}else{
			newSectionCountdowns={}
			newSectionCountdowns[sectionId]=seconds
		}
		localStorage.setItem('sectionCountdowns', JSON.stringify(newSectionCountdowns))
	}

//calculates if should show the perfect score celebration (confetti)
//conditions are showGraph and showCorrect are true and the poll scored 100%
function perfectScoreIsDisplayed(props){ 
	const liveViewSettings=getLiveViewSettings()
	if(props.control.revealAnswer &&
		(props.control.showGraph || liveViewSettings.alwaysShowGraph)
		&& !props.control.scanning
	) {
		let poll
		if(props.control && (props.control.setPoll || props.control.currentPoll)){		 		
			 poll=getPollForControl(props.control)
		}
		if(poll && poll.hasResponses){
			const correctPercent=calculatePercentCorrect(poll)
			if(correctPercent===100){
				return true
			}else return false
		}else return false

	}
	else return false
}


class LiveViewModalContainer extends Component {

	constructor(props) {
		super(props)	
		this.stopLiveView=this.stopLiveView.bind(this)	
		this.minimiseLiveView=this.minimiseLiveView.bind(this)
		this.switchNowPlayingMode=this.switchNowPlayingMode.bind(this)
		this.setCountdownSeconds=this.setCountdownSeconds.bind(this)
		this.cancelCountdown=this.cancelCountdown.bind(this)
		this.incrementCountdown=this.incrementCountdown.bind(this)
		this.saveControlChanges=this.saveControlChanges.bind(this)
		this.handleCountdownEnd=this.handleCountdownEnd.bind(this)
		this.toggleScanning=this.toggleScanning.bind(this)
		this.updateControlCountdownEndsAt=this.updateControlCountdownEndsAt.bind(this)
		this.saveControlChanges=this.saveControlChanges.bind(this)
		this.saveControlChanges = debounce(this.saveControlChanges,150,{'leading': false })

		const sectionId=props.control?props.control.section:null
		const nowPlayingMode=getNowPlayingMode(sectionId,props.currentSection)
		const countDownTimerStartSecondsLength=getCountDownTimerStartSecondsLength(sectionId)	

		this.state=({
			nowPlayingMode:nowPlayingMode,
			countDownTimerStartSecondsLength:countDownTimerStartSecondsLength,
			classSafeMode:false
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.control.section !== this.props.control.section){
			const nowPlayingMode=getNowPlayingMode(nextProps.control.section,nextProps.currentSection)
			const countDownTimerStartSecondsLength=getCountDownTimerStartSecondsLength(nextProps.control.section)
			this.setState({classSafeMode:false,nowPlayingMode:nowPlayingMode,countDownTimerStartSecondsLength:countDownTimerStartSecondsLength})
		}
		if(nextProps.control.scanning && !this.props.control.scanning){
			if(this.state.nowPlayingMode !=='liveView' && !nextProps.control.countdownEndsAt && this.state.countDownTimerStartSecondsLength){		
				this.updateControlCountdownEndsAt(nextProps.control)
			}
			analyticsTrackStartScan(this.state.nowPlayingMode,nextProps.control)
			if(window.analytics){
				let testGroup=null
				const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
				if(setLimitTestGroup){
					testGroup=setLimitTestGroup
				}
				window.analytics.track('NP start scan',{
					nowPlayingMode:this.state.nowPlayingMode,
					'test_group_set_limit':testGroup
				})
			}
		}
	}

	saveControlChanges(){
		this.props.updateControl(this.state.control)
	}

	minimiseLiveView(){
		this.props.hideAutoplayFailedAlert()
		if(this.props.history.location.pathname==='/nowplaying/' || this.props.history.location.pathname==='/nowplaying'){
			this.props.history.push('/recent')
		}	
		else hideLiveViewModal()
	}

	stopLiveView(){
		this.props.resetControl()
		if(this.state.fullScreen){
			this.exitFullscreen()
		}
		this.minimiseLiveView()
	}

	switchNowPlayingMode(mode){
		saveDefaultNPModeLocalStorage(mode,this.props.currentSection.id)
		this.setState({nowPlayingMode:mode})
	}
	
	//Countdown Stuff
	updateControlCountdownEndsAt(control){
		const nowDate = calibratedCurrentTime()
		const countdownEndsAt=moment(calibratedCurrentTime()).add(this.state.countDownTimerStartSecondsLength, 's')		
		const newControl={
			...control,
			controlled:nowDate,
			countdownEndsAt:countdownEndsAt
		}
		this.setState({control:newControl})
		this.saveControlChanges()
		this.props.proposeControl(newControl)
	}

	handleCountdownEnd(){
		this.toggleScanning()
	}

	toggleScanning(){ //toggle control.scanning from web for E-Learning
		const nowDate = calibratedCurrentTime()
		let countdownEndsAt=null
		if(this.state.countDownTimerStartSecondsLength && this.state.nowPlayingMode!=='liveView'){
			countdownEndsAt=moment(calibratedCurrentTime()).add(this.state.countDownTimerStartSecondsLength, 's')
		}
		const control=this.props.control
		let newScanning=!this.props.control.scanning
		const newControl={
			...control,
			controlled:nowDate,
			scanning:newScanning,
			revealAnswer:false,
			showGraph:false,
			countdownEndsAt:(newScanning?countdownEndsAt:null)
		}
		if(window.analytics){
			const {onlineStudents,control}=this.props
			const section=this.props.currentSection
			let poll
			if(control && (control.setPoll || control.currentPoll)){		 		
				poll=getPollForControl(control)
			}
			let type='start'
			if(this.props.control.scanning){
				type='stop'
			}
			let hasResponses= poll&&poll.hasResponses
			let onlineStudentsCount=0
			if(onlineStudents){
				onlineStudentsCount=Object.keys(onlineStudents).length	
			}
			let students=[]
			if(section){
				students=section.students
			}
			const filteredStudents=filter(students,{archived:false})
			const totalStudentCount = filteredStudents.length
			let responseCount=0
			if(poll && poll.responsesByCard){
				responseCount = Object.keys(poll.responsesByCard).length	
			}
			let moreResponsesThanOnlineStudents=false
			if(responseCount>onlineStudentsCount){
				moreResponsesThanOnlineStudents=true
			}
			let reOpeningScan=false
			if(type==='start'&& hasResponses){
				reOpeningScan=true
			}
			let hasCountdown=countdownEndsAt?true:false
			let countdownSeconds=null
			if(this.props.countDownTimerStartSecondsLength){
				countdownSeconds=this.props.countDownTimerStartSecondsLength-1
			}
			window.analytics.track('Sync NP toggle scan',{
					type:type,
					hasCountdown:hasCountdown,
					countdownSeconds:countdownSeconds,
					reOpeningScan:reOpeningScan,
					onlineStudentsCount:onlineStudentsCount,
					totalStudentCount:totalStudentCount,
					responseCount:responseCount,
					moreResponsesThanOnlineStudents:moreResponsesThanOnlineStudents				
			})
		}
		this.setState({control:newControl})
		this.saveControlChanges()
		this.props.proposeControl(newControl)
	}

	setCountdownSeconds(seconds){
		const {control}=this.props	
		let previousCountdownSeconds=null
		if(this.state.countDownTimerStartSecondsLength){
			previousCountdownSeconds=this.state.countDownTimerStartSecondsLength-1
		}
		if(control.scanning){
			let newControl
			const newCountdownEndsAt=moment().add(seconds, 's')
			newControl={
				...control,
				controlled:calibratedCurrentTime(),
				countdownEndsAt:newCountdownEndsAt
				}
			this.setState({control:newControl})
			this.saveControlChanges()
		}
		else{
			saveSectionCountdownLocalStorage(seconds,this.props.currentSection.id)
			this.setState({countDownTimerStartSecondsLength:seconds})
		}
		if(window.analytics){
			window.analytics.track('Set Countdown',{
					countdownSeconds:seconds-1,
					isScanning:control.scanning,
					previousCountdownSeconds:previousCountdownSeconds
				 })
		}		
	}

	cancelCountdown(){
		const control=this.props.control
		const nowDate = calibratedCurrentTime()	
		const newControl={
			...control,
			controlled:nowDate,
			countdownEndsAt:null,
		}

		if(window.analytics){
			window.analytics.track('Cancel countdown while scannning',{
					countdownSeconds:this.state.countDownTimerStartSecondsLength?this.state.countDownTimerStartSecondsLength-1:null
				 })
		}		
		return this.props.updateControlSettings(newControl).then((response)=>{
			return response
		})
	}

	incrementCountdown(incrementSeconds){
		const {control}=this.props
		let oldCountdownEndsAt=null
		let oldCountDownTimerStartSecondsLength=this.state.countDownTimerStartSecondsLength
		if(control.scanning){
			let newControl
			let newCountdownEndsAt
			if(control.countdownEndsAt){
				oldCountdownEndsAt=control.countdownEndsAt
				newCountdownEndsAt=moment(control.countdownEndsAt).add(incrementSeconds, 's')
			}else{
				newCountdownEndsAt=moment().add(incrementSeconds, 's')
			}
			if(moment().isBefore(newCountdownEndsAt)){
				newControl={
					...control,
					controlled:calibratedCurrentTime(),
					countdownEndsAt:newCountdownEndsAt
					}
				this.setState({control:newControl})
				this.saveControlChanges()
				this.props.proposeControl(newControl)
			}
		}else{
			this.setState({countDownTimerStartSecondsLength:Math.max(this.state.countDownTimerStartSecondsLength+incrementSeconds,11)})
			saveSectionCountdownLocalStorage(Math.max(this.state.countDownTimerStartSecondsLength+incrementSeconds,0),this.props.currentSection.id)
		}
		if(window.analytics){
			let currentCountdownSecondsRemaining=null
			if(oldCountdownEndsAt){
				const countdownTime=Math.max(moment(oldCountdownEndsAt)-moment(),0)
				currentCountdownSecondsRemaining=Math.floor(countdownTime / 1000)
			}
			let countdownTimerStartLengthBeforeIncrement
			if(!control.scanning){
				countdownTimerStartLengthBeforeIncrement=oldCountDownTimerStartSecondsLength-1
			}
			window.analytics.track('Increment countdown',{
				incrementSeconds:incrementSeconds,
				isScanning:control.scanning,
				scanningCountdownSecondsRemainingBeforeIncrement:currentCountdownSecondsRemaining,
				countdownTimerStartLengthBeforeIncrement:countdownTimerStartLengthBeforeIncrement
			})
		}		
	}

	render() {
		const {location}=this.props.history
		let visible=false
		if(location.pathname==='/nowplaying' ||location.pathname==='/nowplaying/' || location.hash==='#now-playing'){
			visible=true
		}
		const {nowPlayingMode}=this.state
			return [ 
				 <React.Fragment  key='liveView' >	
					{(nowPlayingMode==='liveView' || nowPlayingMode==='liveViewHybrid') &&							  
						<LiveViewContainer 
							key='liveViewContainer'
							visible={visible}
							stopLiveView={this.stopLiveView}
							minimiseLiveView={this.minimiseLiveView}
							switchNowPlayingMode={this.switchNowPlayingMode}
							nowPlayingMode={this.state.nowPlayingMode}
							isHybrid={nowPlayingMode==='liveViewHybrid' }
							onlineStudentsCount={this.props.onlineStudentsCount}
							onlineStudents={this.props.onlineStudents}
							setCountdownSeconds={this.setCountdownSeconds}
							countDownTimerStartSecondsLength={this.state.countDownTimerStartSecondsLength}
							incrementCountdown={this.incrementCountdown}
							cancelCountdown={this.cancelCountdown}
							handleCountdownEnd={this.handleCountdownEnd}
							toggleScanning={this.toggleScanning}
							perfectScoreIsDisplayed={perfectScoreIsDisplayed}

						/> 
					}
				</React.Fragment>
			 ,
			 <React.Fragment  key='syncLiveView' >	
					{nowPlayingMode==='syncLiveView' &&							  
						<SyncLiveViewContainer 
							key='liveViewContainer'
							visible={visible}
							stopLiveView={this.stopLiveView}
							minimiseLiveView={this.minimiseLiveView}
							switchNowPlayingMode={this.switchNowPlayingMode}
							nowPlayingMode={this.state.nowPlayingMode}
							onlineStudentsCount={this.props.onlineStudentsCount}
							setCountdownSeconds={this.setCountdownSeconds}
							countDownTimerStartSecondsLength={this.state.countDownTimerStartSecondsLength}
							incrementCountdown={this.incrementCountdown}
							cancelCountdown={this.cancelCountdown}
							handleCountdownEnd={this.handleCountdownEnd}
							toggleScanning={this.toggleScanning}
							classSafeMode={this.state.classSafeMode}
							toggleClassSafeMode={()=>{this.setState({classSafeMode:!this.state.classSafeMode})}}
							perfectScoreIsDisplayed={perfectScoreIsDisplayed}
						/>
					}
				</React.Fragment>,
			<React.Fragment key='documentTitle'>
			{visible &&
				<DocumentTitle title='Now Playing – Plickers'/>
			}
			</React.Fragment>			
		]
	}
}

function mapStateToProps(state) {
	const control=state.control
	let currentSection=null
	let currentSectionAuthorizations=[]
	let currentSectionPresenceMembers={}
	if(state.control){
		currentSection=find(state.sections, {'id':state.control.section}) 
		currentSectionAuthorizations=state.sectionAuthorizations[state.control.section]
		currentSectionPresenceMembers=state.sectionPresenceMembers[state.control.section]
	}
	let onlineStudents={}
	if(currentSectionPresenceMembers){
			Object.keys(currentSectionPresenceMembers).forEach((sessionId)=>{
		const authorization=find(currentSectionAuthorizations,{session:sessionId})
		if(authorization){
			onlineStudents[authorization.student]=true
		}
	
	})
	}
	const onlineStudentsCount=Object.keys(onlineStudents).length	
	return {
		control:control,
		currentSection:currentSection,
		onlineStudents:onlineStudents,
		onlineStudentsCount:onlineStudentsCount
	}
}

export default withRouter(connect(
	mapStateToProps,
	{ proposeControl,
		updateControl,
		updateControlSettings,
		resetControl,
		getPollForControl,
		getSetPollForControl,
		hideAutoplayFailedAlert}
)(LiveViewModalContainer))
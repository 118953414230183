import _ from 'lodash'

//following logic in 
//https://docs.google.com/document/d/1WPFpFM9pfudLNK3Z6sQmSnCk--8nfvxRpsgKopnyuE8/edit#
//if setObject is passed use that instead of setPoll.snapshot to include any changes to setObject (e.g.resumableSetPolls)

export function buildQuestionsAndPollsList(setPoll,setObject) {
	if(setPoll.snapshot){
		let questionsAndPolls=[]
		let polls=[]
		if(setPoll.polls){
			Object.keys(setPoll.polls).map((pollId) => {
				const poll=setPoll.polls[pollId]
				polls.push(poll)
				return null
			})
		}
		let questions=setPoll.snapshot.questions
		if(setObject){
			questions=setObject.questions 
		}

		//1. loop over snapshot questions
		questions.map((question) => {
			const matchingPoll=_.find(polls,{question:question.questionId})
			if (matchingPoll != null) {
				_.remove(polls,matchingPoll)
				//hasResponses=true --> use matchingPoll.snapshot 
				//else use whole set snapshot
				let snapshot=question
				if(matchingPoll.hasResponses===true){
					snapshot=matchingPoll.snapshot
					questionsAndPolls.push({questionSnapshot:snapshot,poll:matchingPoll})
				}else{
					//Dont include poll if no responses-check this
					questionsAndPolls.push({questionSnapshot:snapshot,poll:null})
				}
			} else {
				questionsAndPolls.push({questionSnapshot:question,poll:null})
			}
			return null
		})
		//2.loop over remaining polls
		//TODO check ordering on this

		polls.map((poll) => {
			if(poll.hasResponses){
				questionsAndPolls.push({questionSnapshot:poll.snapshot,poll:poll}) 
			}
			return null
		})
 
		return questionsAndPolls
	}else return null
}
import React, { Component } from 'react'

class TemplateSelectPanelToggle extends Component {
	render() {
		const {template, label,changeTemplate} = this.props
		const isActive = template===label

		return (
			<button onClick={()=>{changeTemplate(label)}} className={'fontSizeCalculationVisualizer-templateSelectPanel-toggle ' + (isActive ? 'fontSizeCalculationVisualizer-templateSelectPanel-toggle--active' : 'fontSizeCalculationVisualizer-templateSelectPanel-toggle--inactive')}>
				{label}
			</button>
		)  		
	}
}

class SettingSelectPanelToggle extends Component {
	render() {
		const { label,onClick,isActive} = this.props


		return (
			<button onClick={()=>{onClick()}} className={'fontSizeCalculationVisualizer-templateSelectPanel-toggle ' + (isActive ? 'fontSizeCalculationVisualizer-templateSelectPanel-toggle--active' : 'fontSizeCalculationVisualizer-templateSelectPanel-toggle--inactive')}>
				{label}
			</button>
		)  		
	}
}




class TemplateSelectPanelMediaRatioToggle extends Component {
	render() {
		const { mediaRatio, setMediaRatio, label } = this.props
		const isActive = mediaRatio===label

		return (
			<button onClick={()=>{setMediaRatio(label)}} className={'fontSizeCalculationVisualizer-templateSelectPanel-toggle ' + (isActive ? 'fontSizeCalculationVisualizer-templateSelectPanel-toggle--active' : 'fontSizeCalculationVisualizer-templateSelectPanel-toggle--inactive')}>
				{label}
			</button>
		)  		
	}
}



class TemplateSelectPanel extends Component {


	render() {
 		const {question,mediaRatio,setMediaRatio,showGhostElements,showOutcomesGrid,slideWidth,showHeightsTable} = this.props
 		const template = question.template

      return (
          <div className='fontSizeCalculationVisualizer-templateSelectPanel'>
          	<div className='fontSizeCalculationVisualizer-templateSelectPanel-UISetup'>
          		<div className='fontSizeCalculationVisualizer-templateSelectPanel-pageTitle'>
          			Font Size Calculation Visualizer
          		</div>
          		<div className='fontSizeCalculationVisualizer-templateSelectPanel-hSpacer'/>
	          	

	          	<div className='fontSizeCalculationVisualizer-templateSelectPanel-toggleGroup'>
	          		<SettingSelectPanelToggle label='Heights Table' isActive={showHeightsTable} onClick={this.props.toggleShowHeightsTable}/>
	          	</div>

	          	<div className='fontSizeCalculationVisualizer-templateSelectPanel-toggleGroup'>
	          		<SettingSelectPanelToggle label='Outcomes Grid' isActive={showOutcomesGrid} onClick={this.props.toggleShowOutcomesGrid} />
	          	</div>

	          	<div className='fontSizeCalculationVisualizer-templateSelectPanel-toggleGroup'>
	          		<SettingSelectPanelToggle label='Ghost Elements' isActive={showGhostElements}  onClick={this.props.toggleShowGhostElements}/>
	          	</div>

	          	<div className='fontSizeCalculationVisualizer-templateSelectPanel-toggleGroup fontSizeCalculationVisualizer-templateSelectPanel-toggleGroup--slideWidth'>
	          		Slide Width
	          		<SettingSelectPanelToggle label='50%' isActive={slideWidth===640} onClick={()=>{this.props.changeSlideWidth(640)}}/>
	          		<SettingSelectPanelToggle label='75%' isActive={slideWidth===960} onClick={()=>{this.props.changeSlideWidth(960)}}/>
	          		<SettingSelectPanelToggle label='100%'isActive={slideWidth===1280} onClick={()=>{this.props.changeSlideWidth(1280)}}/>	          		          		
	          	</div>

	          </div>

	          <div className='fontSizeCalculationVisualizer-templateSelectPanel-template'>
	          	<div className='fontSizeCalculationVisualizer-templateSelectPanel-toggleGroup fontSizeCalculationVisualizer-templateSelectPanel-toggleGroup--template'>          		
	          		<TemplateSelectPanelToggle label='bodyLeft' template={template} changeTemplate={this.props.changeTemplate}/>
          			<TemplateSelectPanelToggle label='bodyCenter' template={template} changeTemplate={this.props.changeTemplate}/>
          			<TemplateSelectPanelToggle label='bodyLeftMediaRight' template={template} changeTemplate={this.props.changeTemplate} />
          			<TemplateSelectPanelToggle label='bodyRightMediaLeft' template={template} changeTemplate={this.props.changeTemplate}/>
          			<TemplateSelectPanelToggle label='bodyCenterMediaCenter' template={template} changeTemplate={this.props.changeTemplate}/>
        			<TemplateSelectPanelToggle label='bodyCenterChoicesMedia' template={template} changeTemplate={this.props.changeTemplate}/>
        			<TemplateSelectPanelToggle label='bodyLeftMediaRightChoicesMedia' template={template} changeTemplate={this.props.changeTemplate}/>
							</div>	          
							<div className='fontSizeCalculationVisualizer-templateSelectPanel-toggleGroup'>	
								Media
						<TemplateSelectPanelMediaRatioToggle label='MS' mediaRatio={mediaRatio} setMediaRatio={setMediaRatio} />
        				<TemplateSelectPanelMediaRatioToggle label='MM' mediaRatio={mediaRatio} setMediaRatio={setMediaRatio}  />
        				<TemplateSelectPanelMediaRatioToggle label='ML'  mediaRatio={mediaRatio} setMediaRatio={setMediaRatio} />
							</div>
          	</div>
          	


          </div>


    )
   
		
	}
}


export default TemplateSelectPanel


import React from 'react'
import { withRouter} from 'react-router-dom'
import _ from 'lodash'
import Icon from '../misc/Icons'

const LandscapeOrientation = () => (
	<style type="text/css">
		{'@media print{@page {size: landscape}}'}
	</style>
)

const PortraitOrientation = () => (
	<style type="text/css">
		{'@media print{@page {size: portrait}}'}
	</style>
)


class SectionRoster extends React.Component{
	constructor(){
		super()
		this.state={
	      sortBy:'name',	      
	      pageOrientation:'portrait',
	      printColor:'color',
		}
	}


	renderRosterStudentSortName(student){
		return(
			<div key={student.id} className='sectionRosterSheet-studentTable-studentCell sectionRosterSheet-studentTable-studentCell--sortName notranslate'>				
				<div className='sectionRosterSheet-studentTable-studentCell-name'>
					{student.displayName} 
				</div>
				<div className='sectionRosterSheet-studentTable-studentCell-card'>
					{student.card}
				</div>
			</div>
		)
	}

	renderRosterStudentSortNumber(student){
		return(
			<div className='sectionRosterSheet-studentTable-studentCell sectionRosterSheet-studentTable-studentCell--sortNumber notranslate'>
				<div className='sectionRosterSheet-studentTable-studentCell-card'>
					{student.card}
				</div>
				<div className='sectionRosterSheet-studentTable-studentCell-name'>
					{student.displayName} 				
				</div>
			</div>
		)
	}

	isIE(){
 		const ua = navigator.userAgent;
	  /* MSIE used to detect old browsers and Trident used to newer ones*/
  		var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1
  		return is_ie
	}




	render(){


		const {section,user}=this.props

		const isIe=this.isIE()

		const {pageOrientation, printColor, sortBy}=this.state

		if(section){

		let students = _.filter(section.students,{'archived':false})

		let studentsName=_.sortBy(_.filter(section.students,{'archived':false}), [function(o) { 
    		return o.displayName.toString().toLowerCase()
		}]) 

		let studentsNumber=_.sortBy(_.filter(section.students,{'archived':false}), [function(o) { 
    		return o.card
		}]) 
		let userDisplayName=''
		if(user && user.lastName){
			userDisplayName=`${user.title} ${user.lastName}`
		}

  			
			return(
				<div className='sectionRoster'>

					
				
					{this.state.pageOrientation === 'portrait' &&
						<PortraitOrientation/>
					}

					{this.state.pageOrientation === 'landscape' &&
						<LandscapeOrientation/>
					}

					<div className='sectionRoster-topBar'>

						<div className='sectionRoster-topBar-title'>
							Class Roster
						</div>

						<div className='sectionRoster-topBar-btnGroup'>
							<div className={'sectionRoster-topBar-btn ' + (pageOrientation === 'portrait' ? 'sectionRoster-topBar-btn--active' : '')}
								onClick={()=>{this.setState({pageOrientation:'portrait'})}}>
								Portrait
							</div>
							<div className={'sectionRoster-topBar-btn ' + (pageOrientation === 'landscape' ? 'sectionRoster-topBar-btn--active' : '')}
								onClick={()=>{this.setState({pageOrientation:'landscape'})}}>
								Landscape
							</div>							
						</div>

						<div className='sectionRoster-topBar-btnGroup'>
							
							<div className={'sectionRoster-topBar-btn ' + (sortBy === 'name' ? 'sectionRoster-topBar-btn--active' : '')}
								onClick={()=>{this.setState({sortBy:'name'})}}>
								Sort by Name
							</div>
							<div className={'sectionRoster-topBar-btn ' + (sortBy === 'number' ? 'sectionRoster-topBar-btn--active' : '')}
								onClick={()=>{this.setState({sortBy:'number'})}}>
								Sort by Card
							</div>						
							
						</div>

						<div className='sectionRoster-topBar-btnGroup sectionRoster-topBar-btnGroup--color'>
							<div className={'sectionRoster-topBar-btn ' + (printColor === 'color' ? 'sectionRoster-topBar-btn--active' : '')}
								onClick={()=>{this.setState({printColor:'color'})}}>
								Color
							</div>
							<div className={'sectionRoster-topBar-btn ' + (printColor === 'blackandwhite' ? 'sectionRoster-topBar-btn--active' : '')}
								onClick={()=>{this.setState({printColor:'blackandwhite'})}}>
								Black + White
							</div>							
						</div>
						{!isIe &&
							<div className='sectionRoster-topBar-printBtn' onClick={()=>{window.print()}}>
								Print Roster
							</div>
						}

						<div className='sectionRoster-topBar-closeBtn' onClick={()=>{this.props.history.goBack()}}>
							<Icon name='close' />
						</div>
						
					</div>

					<div className={'sectionRosterSheet' + 
						(students.length > 36 ? ' sectionRosterSheet--over36' : ' sectionRosterSheet--under37') +
						(this.state.pageOrientation ? ` sectionRosterSheet--${this.state.pageOrientation}` : '') +
						(this.state.printColor ? ` sectionRosterSheet--${this.state.printColor}` : '') +
						(this.state.sortBy ? ` sectionRosterSheet--sortby${this.state.sortBy}` : '')
					}>
					<div className='sectionRosterSheetContents'>
						
						<div className='sectionRosterSheet-header'>
							{this.state.printColor === 'color' &&
								<div 
									className={'sectionRosterSheet-header-colorBlock' + 
										(section.color ? ` sectionRosterSheet-header-colorBlock--class${section.color}` : ' ') 
									}
								/>
							}

							<div className='sectionRosterSheet-header-top notranslate'>												
								{userDisplayName}
							</div>
							<div className='sectionRosterSheet-header-sectionName notranslate'>
								{section.name}
							</div>

						</div>



							
						{this.state.sortBy === 'name' &&
							
							<div className='sectionRosterSheet-studentTable'>

								{studentsName.map(this.renderRosterStudentSortName)}

							</div>
						
						}

						{this.state.sortBy === 'number' &&						
							
							<div className='sectionRosterSheet-studentTable'>

								{studentsNumber.map(this.renderRosterStudentSortNumber)}

							</div>
						
						}
						
						

						
							{/*

								<div className='sectionRosterSheet-studentTable'>
							
							
							
							
						</div>

							{this.state.sortName &&								
								
							}
							{this.state.sortNumber &&
								students.map(this.renderRosterStudentSortNumber)
							}

							*/}

						
						

					{/* <iframe id='iframe' style={css}></iframe> /*}
					{/* <button onClick={() =>{this.printPage()}}>PRINT</button>
					<h3>{section.name}</h3>
					<button onClick={() =>{this.setState({isRosterView:true})}}>roster view</button>
					<button onClick={() =>{this.setState({isRosterView:false})}}>wall print out </button>

					{this.state.isRosterView &&
						<div id='printableDiv'>
							{students.map(this.renderRosterStudent)}
						</div>
					}

					{!this.state.isRosterView &&
						<div id='printableDiv'>
							{students.map(this.renderWallStudent)}
						</div>
					}
					*/}
					</div>
					</div>
				</div>
			)

		}
		else{
			return null
		}



	}
}



export default withRouter(SectionRoster)

import React from 'react'
import DraggableSlide from './DraggableSlide'
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import range from 'lodash/range'
import { ContextMenu, MenuItem } from 'react-contextmenu'
import SlideListMaxFreeLimitMessage from './SlideListMaxFreeLimitMessage'

//function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result
}

const getListStyle = isDraggingOver => ({
	width: 182
})


class EditorSlideList extends React.Component{  

	constructor(props) {
		super(props)
		this.listIsScrollable=this.listIsScrollable.bind(this)
		this.onDragUpdate=this.onDragUpdate.bind(this)
		this.onDragEnd = this.onDragEnd.bind(this)
		this.onDragStart=this.onDragStart.bind(this)
		this.state = {
			questions:props.questions,
			order:range(props.questions.length),
			dragStarted:false
		}
	}

	componentDidMount() {
		window.addEventListener('keydown', this.props.handleSlideListKeyDown)
	}

	componentWillUnmount() { 
		window.removeEventListener('keydown', this.props.handleSlideListKeyDown)
	}

	UNSAFE_componentWillReceiveProps(nextProps){
		if(nextProps !==this.props){
			if(!this.state.dragStarted && nextProps.questions){
				this.setState({
					questions:nextProps.questions, 
					order:range(nextProps.questions.length)
				})
			}
		}
	}

	onDragStart(){
		this.setState({dragStarted:true})
	}

	onDragUpdate(result){
		if (!result.destination) {
			return
		}else{
			let order=reorder(
			range(this.props.questions.length),
				result.source.index,
				result.destination.index
			)
			this.setState({order:order})
		}
	}	

	onDragEnd(result) {
		this.setState({dragStarted:false})
		if (!result.destination) {
			return
		}
		else{
			if(result.source.index !==result.destination.index){
				let newQuestions=reorder(
					this.props.questions,
					result.source.index,
					result.destination.index
				)
				this.setState({questions:newQuestions,order:range(newQuestions.length)})
				this.props.updateQuestionOrder(result.source.index,result.destination.index)
			}
		}
	}

	listIsScrollable(){
		const scrollableDiv = document.getElementById('slideListScrollableDiv')
		let listIsScrollable=false
		if(scrollableDiv){
			if(scrollableDiv.scrollHeight > scrollableDiv.clientHeight){
				listIsScrollable=true
			}
		}
		return listIsScrollable
	}


	render(){
		const {setActiveQuestion,activeQuestionId,handleSlideListContextMenuClick,maxNumOfQuestionsReached,isViewOnly,itemIsLockedByAnotherUser}=this.props
		const {questions,order}=this.state
		const copiedQuestion = JSON.parse(localStorage.getItem('copiedQuestion'))
		const listIsScrollable=this.listIsScrollable()

		return (
			<div className='editor-slideListOuterContainer'>
				<div className={'editor-slideListContainer ' + (listIsScrollable ? '': 'editor-slideListContainer--noScroll')} id='slideListScrollableDiv'>        
					
				<DragDropContext onDragUpdate={this.onDragUpdate} onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div
								className='editor-slideList'
								{...provided.droppableProps}
								ref={provided.innerRef}
								style={getListStyle(snapshot.isDraggingOver)}
							>
								{questions.map((question,index) => {    
									return(
										<DraggableSlide 
											key={question.questionId}
											isViewOnly={isViewOnly}
											itemIsLockedByAnotherUser={itemIsLockedByAnotherUser}
											active={activeQuestionId===question.questionId}
											setActiveQuestion={setActiveQuestion}
											number={order.indexOf(index) + 1}
											question={question}
											questionIndex={index}
											index={index}
											dragStarted={this.state.dragStarted}
											handleSlideListKeyDown={this.props.handleQuestionCardKeyDown}
										/>
										)
									 })}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>

				<ContextMenu key='contextMenu' id='QUESTION_SLIDE' className='contextMenu' tabIndex='-1' zIndex='100'>
					{!isViewOnly && 
						<React.Fragment>
							<MenuItem onClick={handleSlideListContextMenuClick} data={{action: 'NewQuestion'}} preventClose={true} disabled={maxNumOfQuestionsReached || itemIsLockedByAnotherUser}>         
								New Question          
							</MenuItem>
							<MenuItem divider/>					
							<MenuItem onClick={handleSlideListContextMenuClick} data={{action: 'Cut'}} disabled={itemIsLockedByAnotherUser} preventClose={true}>         
								Cut         
							</MenuItem>
							<MenuItem onClick={handleSlideListContextMenuClick} data={{action: 'Copy'}} preventClose={true}>         
								Copy          
							</MenuItem>
							<MenuItem onClick={handleSlideListContextMenuClick} data={{action: 'Paste'}} preventClose={true} disabled={maxNumOfQuestionsReached || !copiedQuestion || itemIsLockedByAnotherUser}>          
								Paste         
							</MenuItem>
							<MenuItem onClick={handleSlideListContextMenuClick} data={{action: 'Duplicate'}} preventClose={true} disabled={maxNumOfQuestionsReached || itemIsLockedByAnotherUser}>         
								Duplicate         
							</MenuItem>                    								 
							<MenuItem divider/>   
							<MenuItem onClick={handleSlideListContextMenuClick} data={{action: 'MoveUp'}} disabled={itemIsLockedByAnotherUser} preventClose={true}>          
								Move up         
							</MenuItem>
							<MenuItem onClick={handleSlideListContextMenuClick} data={{action: 'MoveDown'}} disabled={itemIsLockedByAnotherUser} preventClose={true}>          
								Move down         
							</MenuItem>
							<MenuItem divider/>
							<MenuItem onClick={handleSlideListContextMenuClick} data={{action: 'Delete'}} disabled={itemIsLockedByAnotherUser} attributes={{className:'is--warning'}} preventClose={true}>         
								Delete          
							</MenuItem>   
						</React.Fragment>
					}
					
					{isViewOnly && 
						<MenuItem onClick={handleSlideListContextMenuClick} data={{action: 'Copy'}} preventClose={true}>         
							Copy          
						</MenuItem>
					}
				</ContextMenu>
			</div>
			{this.props.questionsInSetLimit &&
				<SlideListMaxFreeLimitMessage
					maxNumOfQuestionsReached={maxNumOfQuestionsReached}        
					questionsInSetLimit={this.props.questionsInSetLimit}
				/>
			}        
			</div>
		)
	}
}

export default EditorSlideList

import React from 'react'
import PropTypes from 'prop-types'
import BackButton from '../uiKit/BackButton'

class TopPageHeader extends React.Component {
  componentDidMount() {
    this.context.registerTopHeader(this.el, this.props.height)
  }

  componentWillUnmount() {
    this.context.unregisterTopHeader()
  }

  render() {
    const {
      location,
      repoWithImage,
      repoTextOnly,
      headerRowClickFunction,
      height,
      header,
      folderNavRow,
      headerInfoRowClickFunction,
      multiLineHeader,
      subHeader,
      subHeaderBadge,
      headerInfoRow,
      actionRow,
      leftBadge,
      rightBadge,
      leftBadgeClickFunction,
      backButton,
      sectionHeaderSectionColor,
      borderBottom,
      pageHeaderRowNoTranslate,
    } = this.props

    let headerHeightStyle

    if (!multiLineHeader && location !== 'repo') {
      headerHeightStyle = { height: `${height}px` }
    } else {
      headerHeightStyle = { height: 'auto' }
    }

    return (
      <div ref={(el) => { this.el = el }} style={headerHeightStyle} className={`pageHeaderTop${location ? ` pageHeaderTop--${location} ` : ''}${sectionHeaderSectionColor ? ` pageHeaderTop--classcolor-${sectionHeaderSectionColor} ` : ''}${multiLineHeader ? ' pageHeaderTop--multiLine' : ''}${repoWithImage ? ' pageHeaderTop--repoWithImage' : ''}${repoTextOnly ? ' pageHeaderTop--repoTextOnly' : ''}${this.props.viewOnlyRepoPrivileges ? ' pageHeaderTop--repoViewOnly' : ''}`}>
        <div className='pageHeaderTop-leftBadgeContainer' onClick={leftBadgeClickFunction}>
          <div className='pageHeaderTop-leftBadge'>
            {leftBadge}
          </div>
        </div>
        <div className={`pageHeaderTop-headerRow ${pageHeaderRowNoTranslate ? 'notranslate' : ''}`} onClick={headerRowClickFunction}>
          <div className='pageHeaderTop-headerRow-header'>
            {header}
          </div>

          {rightBadge && (
          <div className='pageHeaderTop-headerRow-rightBadge'>
            {rightBadge}
          </div>
          )}

        </div>

        {subHeader && (
        <div className='pageHeaderTop-subHeaderRow'>
          {subHeaderBadge && (
          <div className='pageHeaderTop-subHeaderRow-badge'>
            {subHeaderBadge}
          </div>
          )}
          <div className='pageHeaderTop-subHeaderRow-subHeader'>
            {subHeader}
          </div>
        </div>
        )}

        {headerInfoRow && (
        <div className='pageHeaderTop-headerInfoRow' onClick={headerInfoRowClickFunction}>
          {headerInfoRow}
        </div>
        )}

        {actionRow && (
        <div className='pageHeaderTop-actionRow'>
          {actionRow}
        </div>
        )}

        {folderNavRow && (
        <div className='pageHeaderTop-folderNavRow'>
          {folderNavRow}
        </div>
        )}

        {(repoWithImage || repoTextOnly) &&
        <div className='pageHeaderTop--repo-actionRowSpacer' />}

        {borderBottom &&
        <div className='pageHeaderTop-borderBottom' />}

        {backButton &&
        <BackButton />}

      </div>
    )
  }
}

TopPageHeader.defaultProps = {
  height: 175,
  // scrollTransitionPoint:185
}

export default TopPageHeader

TopPageHeader.contextTypes = {
  registerTopHeader: PropTypes.func.isRequired,
  unregisterTopHeader: PropTypes.func.isRequired,
}

import React from 'react'
import ModalWrapper from '../ModalWrapper'
import BillingInfoContainer from '../../../containers/modals/account/BillingInfoContainer'

const BillingInfoModal = props => {
	return (
		 <ModalWrapper
			{...props}
			title={'Billing Info'}      
			modalStyle='dark'
			cancelBtn={false}
		>
			<BillingInfoContainer {...props}/>
		</ModalWrapper>
	)
}

export default BillingInfoModal
import React from 'react'
import {setChoiceToCorrect} from '../../../../prosemirror/utils/editorActions/setChoiceToCorrect'
import EditorIcon from '../../../misc/EditorIcons'

class ChoiceLetterBlock extends React.Component{  
	
	constructor() {
		super()
		this.onClick=this.onClick.bind(this)
	}


 
	onClick(e){
		const {getNode,getPos}=this.props 
		const node=getNode() //use this to make sure have updated node
		const nodePos=getPos(node)
		setChoiceToCorrect(node,nodePos)
	}




  render(){
  	const {choiceIndex}=this.props 
  	let choiceLetter='A'
  	if(choiceIndex==='1'){
  		choiceLetter='B'
  	}else if(choiceIndex==='2'){
  		choiceLetter='C'
  	}
  	else if(choiceIndex==='3'){
  		choiceLetter='D'
  	}

	  return (
	     <div className='slide-choice-letterblock' onClick={this.onClick}>	     		     		     	
	     		
	     		{choiceLetter === 'A' &&
	     			<EditorIcon name='choiceLetterBlock-A' />
	     		}
	     		{choiceLetter === 'B' &&
	     			<EditorIcon name='choiceLetterBlock-B' />
	     		}
	     		{choiceLetter === 'C' &&
	     			<EditorIcon name='choiceLetterBlock-C' />
	     		}
	     		{choiceLetter === 'D' &&
	     			<EditorIcon name='choiceLetterBlock-D' />
	     		}

			</div>
	  )
	}
}

export default ChoiceLetterBlock

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {cancelService,switchPlan} from '../../../actions/service'
import {claimCoupon} from '../../../actions/discounts'
import {showNotification} from '../../../actions/notifications'
import DowngradeCouponModal from '../../../components/account/DowngradeCouponModal'
import DowngradeModal from '../../../components/account/DowngradeModal'
import DowngradeFeedbackModal from '../../../components/account/DowngradeFeedbackModal'
import Icon from '../../../components/misc/Icons'
import {getUserSegment} from '../../../utils/getUserSegment'
 
class DowngradePlanContainer extends Component {
  	
	constructor(props){
		super(props)
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)
		this.claimCoupon=this.claimCoupon.bind(this)
		this.rejectCoupon=this.rejectCoupon.bind(this)
		this.state = {
			showCouponModal:true,
			switchingPlans:false,
			showFeedbackModal:false,
			serviceStatus:null,
		} 
	}  

	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden' //prevent scrolling while modal is open
	}

	shouldComponentUpdate(){
		if(this.state.switchingPlans){
			return false
		}else return true
	}

	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}

	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.props.hideModal() //close modal is click outside modal
	}
 
	claimCoupon(){ //downgrade coupon not currently active- may want to reintroduce in the future
		const couponCode=process.env.REACT_APP_COVID_COUPON_CODE
		if(window.analytics){
			window.analytics.track('Claim Covid Downgrade Coupon',{couponName:'COVIDEXTEND'})
		}
		this.props.claimCoupon(couponCode).then((response)=>{
			this.props.hideModal()
			this.props.showNotification('','Added Discount Coupon','default')
		})
	}
	
	rejectCoupon(){ //continue to downgrade
		this.setState({showCouponModal:false})
		if(window.analytics){
			window.analytics.track('Reject Covid Downgrade Coupon', {couponName:'COVIDEXTEND'})
		}
	}



	render() {
		const {service}=this.props
		const userSegment=getUserSegment()
		let canClaimCoupon=false
		if(service){
			const paidUntil = new Date(service.paidUntil)
			const endDate = new Date("2020-11-16")
			if(paidUntil<endDate ){
			  canClaimCoupon=true
			} 
		}

 		const isTrial=service&&service.status==='trial'

		if(!isTrial && this.state.showCouponModal && !this.props.hasClaimedCoupon && canClaimCoupon && userSegment==='activeMonthlyPro' ){
			return(
				<div className='downgradeModalBG marketing' onClick={this.handleBackgroundClick}>
					 <DowngradeCouponModal 
					 	user={this.props.user}
					 	claimCoupon={this.claimCoupon}
					 	showDowngradeModal={this.rejectCoupon}
					 />
					 <div className='downgradeModal-closeBtn' onClick={this.props.hideModal}>
						<Icon name='circle-with-close' />
					</div>
				</div>
			 )
		}else if(this.state.showFeedbackModal){
			return (
				<div className='downgradeModalBG marketing' onClick={this.handleBackgroundClick}>
					<DowngradeFeedbackModal
						hideModal={this.props.hideModal}
						serviceStatus={this.state.serviceStatus}
					/>
					<div className='downgradeModal-closeBtn' onClick={this.props.hideModal}>
			 			<Icon name='circle-with-close' />
					</div>
				</div>
			)
		}else if(service){
			return (
				<div className='downgradeModalBG marketing' onClick={this.handleBackgroundClick}>
					<DowngradeModal 
						cancelService={this.props.cancelService}
						showNotification={this.props.showNotification}
						service={service}
						showFeedbackModal={(serviceStatus)=>{this.setState({showFeedbackModal:true,serviceStatus:serviceStatus})}}
						downgrade={this.handleDowngrade}
						hideModal={this.props.hideModal}
					/>
					<div className='downgradeModal-closeBtn' onClick={this.props.hideModal}>
						<Icon name='circle-with-close' />
					</div>
				</div>
			)
			}else return null
		}
	}

function mapStateToProps(state) {
	const discounts=state.discounts
	let hasClaimedCoupon=false
	discounts.map((discount)=>{
		if(discount.coupon.code===process.env.REACT_APP_COVID_COUPON_CODE){
			hasClaimedCoupon=true
		}
		return null
	})
	return {
		user:state.user,
		service:state.service,
		hasClaimedCoupon:hasClaimedCoupon,
	}
}

export default connect(
	mapStateToProps,
	{ cancelService,showNotification,claimCoupon,switchPlan}
)(DowngradePlanContainer)
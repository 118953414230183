import React from 'react'
import QuestionCountBadge from '../uiKit/QuestionCountBadge' 
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'


class ItemPreviewCell extends React.Component{
	
	render(){
		const {item}=this.props
		let questionCount
		let title
		let isSet=false
		
		if(item.questions){
			isSet=true
		 questionCount=item.questions.length 
		 title=item.name 
		}else{
			title=getQuestionBodyLabel(item)
		}		
		return(
			<div className={'cellRow'}>
				<div className="cellRow-content">
					<div className="cellRow-left">
						{isSet &&
							<QuestionCountBadge color="blueLight" questionCount={questionCount} />
						}
					</div>

					<div className="cellRow-center">						
						<div className="cellRow-name">
							{title}
						</div>							

					</div>								

				</div>				
			</div>
		)
	}
}

export default ItemPreviewCell
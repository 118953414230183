import React from 'react'
import DropdownWithoutPreventScroll from '../uiKit/DropdownWithoutPreventScroll'
import Icon from '../misc/Icons'

//DropDown to switch between NP modes
//'liveView' || 'syncLiveView' || 'liveViewHybrid'

class LiveViewModeSwitchDropdownTooltip extends React.Component{	
	render(){
		return(						
			<div className='liveViewModeSwitchDropdown-tooltipContainer'onClick={this.props.dismissModeSwitchTooltip}>
				<div className='liveViewModeSwitchDropdown-tooltip'>
					<div className='liveViewModeSwitchDropdown-tooltip-label'>
						Switch between Card, E-Learning, and Hybrid views here
					</div>	
					<div className='liveViewModeSwitchDropdown-tooltip-dismiss'>
						Hide
					</div>	
				</div>
				<div className='liveViewModeSwitchDropdown-tooltipBG'>
					<svg xmlns="http://www.w3.org/2000/svg" width="252" height="78" viewBox="0 0 252 78">   
						<path id="border" fillRule="nonzero" d="M63.0665288,0.176051389 C63.1674048,0.245403648 63.2546897,0.332688479 63.3240419,0.433564491 L67.312,6.234 L248,6.23495806 C250.209139,6.23495806 252,8.02581906 252,10.2349581 L252,74.2349581 C252,76.4440971 250.209139,78.2349581 248,78.2349581 L4,78.2349581 C1.790861,78.2349581 0,76.4440971 0,74.2349581 L0,10.2349581 C0,8.02581906 1.790861,6.23495806 4,6.23495806 L57.687,6.234 L61.6759581,0.433564491 C61.9888433,-0.0215412973 62.611423,-0.13683384 63.0665288,0.176051389 Z M66.7859794,7.23399721 L62.5,1.00009331 L58.2129504,7.23399061 L4,7.23495806 C2.40231912,7.23495806 1.09633912,8.48387802 1.00509269,10.0586853 L1,10.2349581 L1,74.2349581 C1,75.8326389 2.24891996,77.1386189 3.82372721,77.2298654 L4,77.2349581 L248,77.2349581 C249.597681,77.2349581 250.903661,75.9860381 250.994907,74.4112308 L251,74.2349581 L251,10.2349581 C251,8.63727717 249.75108,7.33129718 248.176268,7.24005075 L248,7.23495806 L66.7859794,7.23399721 Z"/>
						<path id="bg" fillRule="nonzero" d="M66.7859794,7.23399721 L62.5,1.00009331 L58.2129504,7.23399061 L4,7.23495806 C2.40231912,7.23495806 1.09633912,8.48387802 1.00509269,10.0586853 L1,10.2349581 L1,74.2349581 C1,75.8326389 2.24891996,77.1386189 3.82372721,77.2298654 L4,77.2349581 L248,77.2349581 C249.597681,77.2349581 250.903661,75.9860381 250.994907,74.4112308 L251,74.2349581 L251,10.2349581 C251,8.63727717 249.75108,7.33129718 248.176268,7.24005075 L248,7.23495806 L66.7859794,7.23399721 Z"/>
				</svg>
				</div>							
			</div>				
		)
	}
}

class LiveViewModeSwitchDropdownButton extends React.Component{	

	constructor() {
		super()	
		this.onClick=this.onClick.bind(this)
	}

	onClick(){
		this.props.onClickFunction()
		this.props.dismissModeSwitchTooltip()
	}


	render(){
		return(							
			<button onClick={this.onClick} className={'liveViewModeSwitchDropdown-button '+ (this.props.label ? `liveViewModeSwitchDropdown-button--${this.props.label}`:'')}>
				<div className='liveViewModeSwitchDropdown-button-iconContainer'>
					<Icon name={this.props.iconName} />
				</div>
				<div className='liveViewModeSwitchDropdown-button-label'>
					{this.props.label}
				</div>
				<div className='liveViewModeSwitchDropdown-button-dropdownIcon'>
					<Icon name='chevron-right' />
				</div>
			</button>				
		)
	}
}


class LiveViewModeSwitchDropdownDropdownItem extends React.Component{	

	render(){	 
		const {active}=this.props
		return(								
			<div onClick={this.props.onSelect} className={'liveViewModeSwitchDropdown-dropdown-item ' + (active ? 'liveViewModeSwitchDropdown-dropdown-item--active':'liveViewModeSwitchDropdown-dropdown-item--inactive') + (this.props.disabled ? ' liveViewModeSwitchDropdown-dropdown-item--disabled':' liveViewModeSwitchDropdown-dropdown-item--enabled')}>
				<div className='liveViewModeSwitchDropdown-dropdown-item-leftContainer'>
					<div className='liveViewModeSwitchDropdown-dropdown-item-iconContainer'>
						<Icon name={this.props.iconName} />
					</div>
				</div>
				<div className='liveViewModeSwitchDropdown-dropdown-item-rightContainer'>
					<div className='liveViewModeSwitchDropdown-dropdown-item-title'>
						{this.props.title} {/*{active && <span> (Current)</span>} */}
					</div>						
					<div className='liveViewModeSwitchDropdown-dropdown-item-description'>
						{this.props.description}
					</div>
				</div>
			</div>				
		)
	}
}


class LiveViewModeSwitchDropdown extends React.Component{	

	constructor() {
		super()	
		this.dismissModeSwitchTooltip=this.dismissModeSwitchTooltip.bind(this)

		const dismissedModeSwitchDropdownTooltip = JSON.parse(localStorage.getItem('dismissedModeSwitchDropdownTooltip'))
		let showModeSwitchDropdownTooltip=true
		if(dismissedModeSwitchDropdownTooltip){
			showModeSwitchDropdownTooltip=false
		}
		this.state=({
			showModeSwitchDropdownTooltip:showModeSwitchDropdownTooltip
		})
	}

	dismissModeSwitchTooltip(){
		localStorage.setItem('dismissedModeSwitchDropdownTooltip',true)
		this.setState({showModeSwitchDropdownTooltip:false})
	}


	render(){
		const {nowPlayingMode,switchNowPlayingMode, onlineStudentsCount,section}=this.props	
		let sectionSyncActivated = false
		if(section && section.studentAccess && section.studentAccess.enabled){
			sectionSyncActivated=true
		}
		let dropdownButtonLabel = ''
		let dropdownButtonIcon = ''
		if(nowPlayingMode==='liveView'){
			dropdownButtonLabel='Cards'
			dropdownButtonIcon='npmode--cards'
		}
		if(nowPlayingMode==='syncLiveView'){
			dropdownButtonLabel='E-Learning'
			dropdownButtonIcon='npmode--elearning'
		}
		if(nowPlayingMode==='liveViewHybrid'){
			dropdownButtonLabel='Hybrid'
			dropdownButtonIcon='npmode--hybrid'
		}

		const {showModeSwitchDropdownTooltip}=this.state

		return(			
			<div className='liveViewModeSwitchDropdown'>
		
			{showModeSwitchDropdownTooltip &&
				<LiveViewModeSwitchDropdownTooltip dismissModeSwitchTooltip={this.dismissModeSwitchTooltip}/>
			}

			<DropdownWithoutPreventScroll onDropdownOpen={this.props.onDropdownOpen} onDropdownClose={this.props.onDropdownClose} dropdownButton={<LiveViewModeSwitchDropdownButton dismissModeSwitchTooltip={this.dismissModeSwitchTooltip} label={dropdownButtonLabel} iconName={dropdownButtonIcon} />} >        	   
				
				<LiveViewModeSwitchDropdownDropdownItem
					onSelect={()=>{switchNowPlayingMode('liveView')}}
					active={nowPlayingMode==='liveView'}
					title='Cards Mode'
					iconName='npmode--cards'
					description='Standard view – project in your classroom and scan answers from cards.'
				/>
				<LiveViewModeSwitchDropdownDropdownItem
					onSelect={()=>{switchNowPlayingMode('syncLiveView')}}
					active={nowPlayingMode==='syncLiveView'}
					title='E-Learning Mode'
					iconName='npmode--elearning'							
					disabled={!sectionSyncActivated}
					description='Private teacher view – use if all students are participating via E-Learning.'
				/>
				<LiveViewModeSwitchDropdownDropdownItem
					onSelect={()=>{switchNowPlayingMode('liveViewHybrid')}}
					active={nowPlayingMode==='liveViewHybrid'}
					title='Hybrid Mode'
					disabled={!sectionSyncActivated}
					iconName='npmode--hybrid'							
					description='Project in your classroom and accept answers from cards and E-Learning at the same time.'
				/>

				{sectionSyncActivated &&
					<div className='liveViewModeSwitchDropdown-dropdown-onlineStudentCountContainer'>
						
						{onlineStudentsCount === 0 &&
							<div className='liveViewModeSwitchDropdown-dropdown-onlineStudentCount liveViewModeSwitchDropdown-dropdown-onlineStudentCount--inactive'> 
								No students are online
							</div>
						}

						{onlineStudentsCount === 1 &&
						<div className='liveViewModeSwitchDropdown-dropdown-onlineStudentCount liveViewModeSwitchDropdown-dropdown-onlineStudentCount--active'> 
							1 student online
						</div>
						}

						{onlineStudentsCount > 1 && 
							<div className='liveViewModeSwitchDropdown-dropdown-onlineStudentCount liveViewModeSwitchDropdown-dropdown-onlineStudentCount--active'> 
								{onlineStudentsCount} students online
							</div>
						}

					</div>
				}

			{/* MAY WANT TO ADD IN FUTURE IF E.G. E-LEARNING ACTIVATED */}
				{!sectionSyncActivated &&
					<div className='liveViewModeSwitchDropdown-dropdown-syncActivateTipContainer'>								
						<div className='liveViewModeSwitchDropdown-dropdown-syncActivateTip'>
							Tip: Enable E-Learning for this class to use E-Learning modes
							<span>. Try it out now</span>
						</div>
					</div>
				}
				</DropdownWithoutPreventScroll>
			</div>
		)
	}
}
export default LiveViewModeSwitchDropdown
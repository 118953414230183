import React from 'react'

export default function BingAttributionWithPrivacyLink() {
  return (
    <div className='bingAttributionWithPrivacyLinkContainer'>
      <div className='bingAttributionWithPrivacyLinkContainer-bingLogoContainer'>
        <div>
          powered by
        </div>
        <picture className='bingAttributionWithPrivacyLinkContainer-bingLogoContainer-bingLogo'>
          <img
            className='bingAttributionLogo'
            src='https://assets.plickers.com/react-assets/images/bing_logo.png'
            alt=''
          />
        </picture>
      </div>
      <div className='bingAttributionWithPrivacyLinkContainer-bingPrivacyContainer'>
        <a
          className='thirdPartyAttributionLink'
          href='https://privacy.microsoft.com/en-us/privacystatement'
          target='_blank'
          rel='noopener noreferrer'
        >
          Microsoft Privacy Statement
        </a>
      </div>
    </div>
  )
}

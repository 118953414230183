import React from 'react'

class ReleaseNotesListItemDetailSubItem extends React.Component{

	render(){	
	
		const {subItemLabel} = this.props
		return(
			<div className='releaseNotes-list-item-releaseDetail-group-subitem'>
				<div className='releaseNotes-list-item-releaseDetail-group-subitem-bullet'>
					•
				</div>
				<div className='releaseNotes-list-item-releaseDetail-group-subitem-label'>
				{subItemLabel}
				</div>
			</div>				
		)
	}
				
}

class ReleaseNotesListItemDetailGroup extends React.Component{

	render(){	
	
		const {label, children} = this.props
		return(
			<div className='releaseNotes-list-item-releaseDetail-group'>
				{label &&
					<div className='releaseNotes-list-item-releaseDetail-group-title'>				
						{label}
					</div>
				}
				<div className='releaseNotes-list-item-releaseDetail-group-subitemList'>				
					{children}
				</div>			
			</div>
		)
	}
				
}

class ReleaseNotesListItem extends React.Component{

	render(){	
	
		const {releaseDate, releaseTitle, children} = this.props
		return(
			<div className='releaseNotes-list-item'>
				<div className='releaseNotes-list-item--left'>
					<div className='releaseNotes-list-item-releaseDate'>
						{releaseDate}
					</div>
				</div>
				<div className='releaseNotes-list-item--center'>

				</div>

				<div className='releaseNotes-list-item--right'>
					<div className='releaseNotes-list-item-releaseTitle'>
						{releaseTitle}
					</div>
					<div className='releaseNotes-list-item-releaseDetail'>
						{children}
					</div>
				</div>

			</div>
		)
	}
				
}

class ReleaseNotesList extends React.Component{

	render(){	
	
		return(
			<div className='releaseNotes-list'>
				<ReleaseNotesListItem 
					releaseDate='24 Nov 2019'
					releaseTitle='Public Pack Reviews and more'
			>
					<ReleaseNotesListItemDetailGroup>
						<ReleaseNotesListItemDetailSubItem subItemLabel='You can now leave reviews for public packs you follow!' />						
						<ReleaseNotesListItemDetailSubItem subItemLabel='To leave a review, just click “Provide Feedback” in the bottom right of the pack page.' />						
						<ReleaseNotesListItemDetailSubItem subItemLabel='Reviewing public packs helps us identify the best public packs and creators, so please leave feedback on your favourite packs!' />						
					</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup label='Also new in this release'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='Creator pages can now be shared on Facebook, Twitter, or Pinterest!' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='We’ve made publishing packs easier with improved image options.' />					
						<ReleaseNotesListItemDetailSubItem subItemLabel='Plickers Creators: you can now add a bio to your creator page to introduce yourself.' />					
						<ReleaseNotesListItemDetailSubItem subItemLabel='There’s also some other other small improvements and fixes.' />					
						</ReleaseNotesListItemDetailGroup>					
				</ReleaseNotesListItem>

			
				<ReleaseNotesListItem 
					releaseDate='4 Nov 2019'
					releaseTitle='Introducing the Plickers Creator Program'
				>
					<ReleaseNotesListItemDetailGroup label='Share your content with teachers around the world'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='Join the Plickers Creator Program for free and start publishing today.' />						
						<ReleaseNotesListItemDetailSubItem subItemLabel='When you publish a pack, you’ll receive a unique share URL. Distribute the share URL however you want, including on Twitter or Facebook.' />						
						<ReleaseNotesListItemDetailSubItem subItemLabel='When you join the Creator Program, you’ll also get a Creator profile page where all your public packs will be listed.' />
					</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup label='How to join'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='To get started, click "Publish Pack" in any pack page.' />
						</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup label='Accessing Public Packs'>
						<ReleaseNotesListItemDetailSubItem subItemLabel="When you open a public pack link, you’ll have the option to follow the pack. Followed packs are saved to your sidebar and your mobile app, and they’ll also appear in your search results!" />						
						<ReleaseNotesListItemDetailSubItem subItemLabel="You can play and queue sets and questions you find in public packs just like normal." />
					</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup>
						<ReleaseNotesListItemDetailSubItem subItemLabel="Also, you can now assign a subject and grade-level to any pack." />
						<ReleaseNotesListItemDetailSubItem subItemLabel="Finally, there are also a range of small bug fixes included in this release." />						
					</ReleaseNotesListItemDetailGroup>
				</ReleaseNotesListItem>

				<ReleaseNotesListItem 
					releaseDate='29 Sept 2019'
					releaseTitle='Save time with Import Questions'
				>
					<ReleaseNotesListItemDetailGroup>
						<ReleaseNotesListItemDetailSubItem subItemLabel='If you have questions outside of Plickers – buried in Word Documents, websites, or other quiz apps – you can now import them into your sets in seconds!' />												
					</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup>
						<ReleaseNotesListItemDetailSubItem subItemLabel='You can find Import Questions in the Set Editor.' />						
						<ReleaseNotesListItemDetailSubItem subItemLabel='To get started, copy multiple choice questions in bulk from your source.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Next, paste them in bulk into Import Questions.' />						
						<ReleaseNotesListItemDetailSubItem subItemLabel='Check over how things are looking. You might need to do one or two tweaks.' />						
						<ReleaseNotesListItemDetailSubItem subItemLabel='Finally, click Import – and voila! Your questions are ready to use!' />						
						<ReleaseNotesListItemDetailSubItem subItemLabel='We’d love to hear how it’s working out for you and what we can do to improve it – let us know from the feedback from in Import Questions' />						
					</ReleaseNotesListItemDetailGroup>					
				</ReleaseNotesListItem>

				<ReleaseNotesListItem 
					releaseDate='28 June 2019'
					releaseTitle='Introducing Packs'
				>
					<ReleaseNotesListItemDetailGroup label='For Plickers Pro Teachers: share your sets and questions'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='Invite your teammates, friends and colleagues to collaborate on Packs with you.' />						
						<ReleaseNotesListItemDetailSubItem subItemLabel='Click “Invite” on the right-side panel of your Packs and enter an email address to send an invitation.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Choose if people you invite ‘can edit’ or ‘can view’ your Pack. People who ‘can edit’ the Pack can also invite new members!' />						
					</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup label='For Everyone: A new way to organize your content'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='Packs were designed to help you guys better organize your courses, units and topics.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='They are easy-to-access on the left-side wherever you are.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='We have big plans for Packs, so expect to see more features added to them over time.' />
						</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup label='Create your first Pack'>
						<ReleaseNotesListItemDetailSubItem subItemLabel="Click 'New Pack' in the left-side navigation. To make things snazzy, add an image and a description. There's also 'New Pack' buttons at the top of Recent and Your Library." />
					</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup label='Three ways to move your existing content into Packs'>
						<ReleaseNotesListItemDetailSubItem subItemLabel="1 – Select any set, question or folder in Your Library, and click 'Move to Pack' on the right-side panel." />
						<ReleaseNotesListItemDetailSubItem subItemLabel='2 – Drag-and-drop: drag any item from Your Library and drop onto a Pack on the left-side-navigation or at the top of Your Library. You can move batches of items using Shift to multi-select and drag-and-drop.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel="3 – Right-click on any item in Your Library and select 'Move to Pack' from the menu." />
					</ReleaseNotesListItemDetailGroup>
				</ReleaseNotesListItem>

				<ReleaseNotesListItem 
					releaseDate='16 Mar 2019'
					releaseTitle='Work faster with Drag and Drop'
				>
					<ReleaseNotesListItemDetailGroup label='Add to class queue'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='You can now add items to your class queue quicker than ever with Drag and Drop.' />						
						<ReleaseNotesListItemDetailSubItem subItemLabel='In your Library, click and hold on a set or question. Then, drag the item over to the left-side navigation and “drop” the item onto a class. This will add the item to that class queue.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='You can even add multiple items to the queue at once. Click on an item in your Library, then press Shift and click other files to select a bunch at once. Then drag and drop them all onto a class to add lots of items to a queue at once!' />						
					</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup label='Organize your folders easier'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='In your Library, you can also drag sets and questions and drop them onto a folder to move items into that folder.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='You can even move folders into other folders, and you can also move multiple items at once.' />					
						</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup label='A few other bits'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='There’s also a range of other small fixes and improvements!' />											
					</ReleaseNotesListItemDetailGroup>
				</ReleaseNotesListItem>

				<ReleaseNotesListItem 
					releaseDate='17 Dec 2018'
					releaseTitle='Print Set Handouts'
				>
					<ReleaseNotesListItemDetailGroup>
						<ReleaseNotesListItemDetailSubItem subItemLabel='You can now print out your sets – just click the “Print Handout” button in the Set Editor.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='You can do some jazzy things to your handouts like add space for student working and add a custom instructional message for your students.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Printing sets can be great for your own records, but also as a student worksheet – complete now, scan later!' />
						</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup label='A few other bits'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='When you search your Library, we now highlight the folder it’s in – hopefully it’s a bit easier to find those things buried in your Library!' />					
						<ReleaseNotesListItemDetailSubItem subItemLabel='Your current plan and status – whether you’re a free user or a Pro – should be a little clearer in the Account page.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='A bunch of other small fixes and improvements.' />						
					</ReleaseNotesListItemDetailGroup>
				</ReleaseNotesListItem>

				<ReleaseNotesListItem 
					releaseDate='11 Nov 2018'
					releaseTitle='A few more small improvements'
				>
					<ReleaseNotesListItemDetailGroup>
						<ReleaseNotesListItemDetailSubItem subItemLabel='We’ve improved the copy-paste system for questions! You can now copy between sets, or from a question into a set. You can also copy a question directly from the Library and paste it into the Set Editor.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='The “Create New” button is back in the Question Editor, making it easier to make multiple standalone questions at a time.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='When you export a CSV from Scoresheet, we’re now maintaining the sort order.' />					
						<ReleaseNotesListItemDetailSubItem subItemLabel='There’s more detail in the date-time displayed in Question reports.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Some other tiny fixes and improvements.' />						
					</ReleaseNotesListItemDetailGroup>
				</ReleaseNotesListItem>


				<ReleaseNotesListItem 
					releaseDate='4 Nov 2018'
					releaseTitle='Small improvements and fixes'
				>
					<ReleaseNotesListItemDetailGroup>
						<ReleaseNotesListItemDetailSubItem subItemLabel='You can now hide student responses in Question Reports! Click anywhere on the response graphs or student responses. You can also show student card numbers – look out the dropdown menu!' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='In your Library, you can now access more functionality on a selected item using a new menu button on the right side panel (look for the three dots).' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Fixed a bug in Now Playing where the question could overlap and hide the student list.' />					
						<ReleaseNotesListItemDetailSubItem subItemLabel='Added some helpful links into the account page to make it easier to get new cards and other things.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='A bunch of other tiny fixes and improvements.' />						
					</ReleaseNotesListItemDetailGroup>
				</ReleaseNotesListItem>


				<ReleaseNotesListItem 
					releaseDate='24 Oct 2018'
					releaseTitle='Organize faster with improved multi-select'
				>
					<ReleaseNotesListItemDetailGroup>
						<ReleaseNotesListItemDetailSubItem subItemLabel='We’ve now made it easier to multiple items to a queue at once.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Adding multiple items into a folder is also now a lot faster and easier!' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='To use multi-select, use your shift key in Library. (You can also use ctrl or cmd keys to select a bunch of individual items.)' />
					</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup label='A few other things...'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='We’ve added this here What’s New page to keep you updated on everything new we’ve released.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Added a link to large 1up cards back into the Get Cards page.' />						 
						<ReleaseNotesListItemDetailSubItem subItemLabel='Improved support for narrow-screens in Library and Students.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='We now keep your Library sorted as you like it (by name or by date).' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Fixed a few rare bugs.' />
					</ReleaseNotesListItemDetailGroup>
				</ReleaseNotesListItem>


				<ReleaseNotesListItem 
					releaseDate='21 Oct 2018'
					releaseTitle='Improvements and Bug Fixes'
				>
					<ReleaseNotesListItemDetailGroup label='Some of the fixes and improvements in this release include:'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='In Export CSV from Scoresheet, we’ve split first name and second name into separate columns for easier sorting.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='We’ve prevented certain page elements from translating if you’re using Google Translate (which was causing some issues with the payment form and question choice letters).' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Correct/incorrect choices are now clearer in Set and Question Reports.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Fixed a bug where search results would sometimes go to blank pages.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Fixed a bug where a Guest might be excluded from Scoresheet CSV export.' />
					</ReleaseNotesListItemDetailGroup>					
				</ReleaseNotesListItem>


				<ReleaseNotesListItem 
					releaseDate='14 Oct 2018'
					releaseTitle='Student Reports'
				>
					<ReleaseNotesListItemDetailGroup label='Student Reports are back! You can access them from Scoresheet.'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='Customize your student report contents and layout with live preview.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='You can create student reports for a single student. Click on the student’s name in the Scoresheet student list.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Student reports have an all new design and format to reduce the number of pages to print.' />
					</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup label='Also...'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='If you need to add student responses after the lesson, you can now Continue Playing from Question Report.' />
					</ReleaseNotesListItemDetailGroup>
				</ReleaseNotesListItem>


				<ReleaseNotesListItem 
					releaseDate='30 Sept 2018'
					releaseTitle='Excluding Questions in Scoresheet'
				>
					<ReleaseNotesListItemDetailGroup>
						<ReleaseNotesListItemDetailSubItem subItemLabel='Excluding questions is back! Click on the percentage-score-cell for a question to exclude it from class totals, student totals, and data exports. exported CSV.' />						
					</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup label='Also in this release'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='In Scoresheet, we’ve brought back the option to show correct/incorrect split instead of percentage score for students.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='In Export CSV from Scoresheet, the column order has been updated to closer match the previous Plickers style.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='In Now Playing, we’ve brought back Live response counts for each choice (select Show Responses in Display Options).' />
					</ReleaseNotesListItemDetailGroup>
				</ReleaseNotesListItem>


					<ReleaseNotesListItem 
					releaseDate='22 Sept 2018'
					releaseTitle='Better, more customized, Now Playing'
				>
					<ReleaseNotesListItemDetailGroup label='What’s new in Now Playing'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='Choice text is now up to 50% larger.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='The real-time scanning graph is back (click Show Graph Graph button before or while you are scanning).' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Student name text is now larger.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='The default graph color is now blue, not gray (we admit gray’s a bit dull).' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Options to customize how the student list appears in Now Playing, including how students are sorted and when the student list is displayed.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='New Display Options, including when to show the graph, and color-scheme.' />
					</ReleaseNotesListItemDetailGroup>
					<ReleaseNotesListItemDetailGroup label='Also in this release'>
						<ReleaseNotesListItemDetailSubItem subItemLabel='Added Day selection into Scoresheet.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='Fixed a bug in Internet Explorer where Scoresheets might display incorrectly.' />
						<ReleaseNotesListItemDetailSubItem subItemLabel='You can double-click on questions in Now Playing to enter and exit full-screen quickly.' />
					</ReleaseNotesListItemDetailGroup>
				</ReleaseNotesListItem>


			</div>
		)
	}
				
}

export default ReleaseNotesList





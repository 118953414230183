import React from 'react'
import Icon from '../misc/Icons'
import SectionMarker from '../uiKit/SectionMarker'
import QuestionCountBadge from '../uiKit/QuestionCountBadge'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'
 
class SectionSelection extends React.Component{

	render(){		
		const {item}=this.props

		let itemName
		let numOfQuestions
		let isSet

		if(item.name){
			itemName = item.name
			numOfQuestions=item.questions.length
			isSet = true
		}

		else {
			itemName = getQuestionBodyLabel(item)
		}    

		return(		
			<div className={'selectionModal selectionModal--section ' + (isSet ? ' selectionModal--set' : '')}>
				<div className='selectionModal-header'>        				                               
					<div className='selectionModal-header-title'>
						 Play Now
					</div>
					<div className='selectionModal-header-itemTitle'>
						 {itemName}
					</div>

					{isSet &&
						<QuestionCountBadge color='blueLight' questionCount={numOfQuestions} />
					}            

				</div>
				<div className='selectionModal-divider' />
				<div className='selectionModal-selectionList'>           
					<div className='selectionModal-selectionList-helpLabel'>
						Select a Class
					</div>
					{this.props.sections.map((section,index) => {
						return(
							<div className='selectionModal-selectionList-item selectionModal-selectionList-item--section' key={index} onClick={() =>{this.props.playForSection(section.id)}}>
								<SectionMarker section={section}/>
								<Icon name='play' />
								<SectionMarker section={section} type={'colorOnly'} />
							</div>                                                 
						)
					})}					

				</div>

			</div>    

		)
	}
}



export default SectionSelection

//we save scoresheet settings in local storage and load them next time visit scoresheet
export function getScoresheetSettings() {	
	//Defaults
	let scoresheetSettings={
		showStudentCardNumber:false,
		showStudentLastName:false,
		studentScoreDisplay:'percentage'
		
	}
	const scoresheetSettingsFromLocalStorage = JSON.parse(localStorage.getItem('scoresheetSettings'))

	if(scoresheetSettingsFromLocalStorage ){	
		if(scoresheetSettingsFromLocalStorage.showStudentCardNumber){
			scoresheetSettings.showStudentCardNumber=scoresheetSettingsFromLocalStorage.showStudentCardNumber
		}
		if(scoresheetSettingsFromLocalStorage.showStudentLastName){
			scoresheetSettings.showStudentLastName=scoresheetSettingsFromLocalStorage.showStudentLastName
		}
		if(scoresheetSettingsFromLocalStorage.studentScoreDisplay){
			scoresheetSettings.studentScoreDisplay=scoresheetSettingsFromLocalStorage.studentScoreDisplay
		}	
	}
	return scoresheetSettings
}


import React from 'react'

// This was a footer at one point.  Is now a header (have 2 header rows instead of header and footer) so could rename

class ScoreSheetFooterRow extends React.Component {
  render() {
    const { historyPolls, collapsedView, excludedPolls } = this.props
    return (
      <div className='scoreSheet-table-footerRow'>
        {historyPolls.map((poll, index) => {
          if (poll.setObject) { // is a setPoll
            if (!collapsedView) {
              return (
                <ScoreSheetFooterCellGroup setPoll={poll} excludePoll={this.props.excludePoll} excludedPolls={excludedPolls} key={index} setActiveSetPollPoll={this.props.setActiveSetPollPoll} />
              )
            }
            let totalResponses = 0
            let totalCorrect = 0
            Object.keys(poll.polls).map((key) => {
              const setPollPoll = poll.polls[key]
              if (!excludedPolls.setPollPolls[`${poll.id}-${key}`]) { // dont included excluded polls in calc
                if (setPollPoll.correctPercent > -1 && setPollPoll.aggregatedResponses) {
                  totalResponses += setPollPoll.aggregatedResponses.totalResponses
                  totalCorrect += setPollPoll.aggregatedResponses.totalCorrectResponses
                }
              }
              return null
            })
            let percentageCorrectExcludingExcludedPolls = -1
            if (totalResponses !== 0) {
              percentageCorrectExcludingExcludedPolls = Math.round(100 * (totalCorrect / totalResponses))
            }
            const isExcluded = excludedPolls.setPolls[poll.id]
            const percentageCorrect = poll.correctPercent
            return (
              <ScoreSheetFooterCell poll={poll} excludedPolls={excludedPolls} excludePoll={() => { this.props.excludePoll(poll.id, 'setPolls') }} isExcluded={isExcluded} percentageCorrect={percentageCorrect} percentageCorrectExcludingExcludedPolls={percentageCorrectExcludingExcludedPolls} key={index} collapsed correctPercent={percentageCorrect} setActivePoll={() => { this.props.setActiveSetPollPoll(poll) }} />
            )
          }

          const isExcluded = excludedPolls.questionPolls[poll.id]
          return (
            <ScoreSheetFooterCell collapsedQuestion={collapsedView} excludePoll={() => { this.props.excludePoll(poll.id, 'questionPolls') }} isExcluded={isExcluded} key={index} percentageCorrect={poll.correctPercent} setActivePoll={() => { this.props.setActiveQuestionPoll(poll) }} />
          )
        })}
      </div>
    )
  }
}
export default ScoreSheetFooterRow

class ScoreSheetFooterCell extends React.Component {
  render() {
    const {
      percentageCorrect, poll, excludedPolls, excludePoll, collapsed, collapsedQuestion, isExcluded, percentageCorrectExcludingExcludedPolls,
    } = this.props
    let someSetPollsExcluded = false
    if (collapsed) {
      Object.keys(excludedPolls.setPollPolls).map((key) => {
        if (key.includes(poll.id)) {
          someSetPollsExcluded = true
        }
        return null
      })
    }
    let showPercentageCorrectExcludingExcludedPolls = false
    if (collapsed && !isExcluded && someSetPollsExcluded) {
      showPercentageCorrectExcludingExcludedPolls = true
    }
    return (
      <div className={`scoreSheet-table-footerRow-footerCell ${isExcluded ? ' scoreSheet-table-footerRow-footerCell--isExcluded ' : ' scoreSheet-table-footerRow-footerCell--isNotExcluded '}${collapsed ? ' scoreSheet-table-footerRow-footerCell--collapsed' : ''}${collapsedQuestion ? ' scoreSheet-table-footerRow-footerCell--collapsedQuestion' : ''}`} onClick={excludePoll}>
        {percentageCorrect > -1 && (
        <div className='scoreSheet-table-footerRow-footerCell-label'>
          <span className={`scoreSheet-table-footerRow-footerCell-label-primary ${showPercentageCorrectExcludingExcludedPolls ? 'scoreSheet-table-footerRow-footerCell-label-primary--disabled' : ''}`}>
            {percentageCorrect}
            <span className='scoreSheet-table-footerRow-footerCell-label-percent'>%</span>
          </span>

          {showPercentageCorrectExcludingExcludedPolls && percentageCorrectExcludingExcludedPolls > -1 && (
          <span className='scoreSheet-table-footerRow-footerCell-label-secondary'>
            {percentageCorrectExcludingExcludedPolls}
            <span className='scoreSheet-table-footerRow-footerCell-label-percent'>%</span>
          </span>
          )}

        </div>
        )}

        {percentageCorrect === -1 && (
        <div className='scoreSheet-table-footerRow-footerCell-label scoreSheet-table-footerRow-footerCell-label--survey'>
          Survey
        </div>
        )}

        <div className='scoreSheet-table-footerRow-footerCell-hideIndicator'>
          Hide
        </div>

      </div>
    )
  }
}

class ScoreSheetFooterCellGroup extends React.Component {
  render() {
    const { setPoll, excludedPolls, excludePoll } = this.props
    return (
      <div className='scoreSheet-table-footerRow-footerCellGroup'>
        {setPoll.pollsOrderedByMinCaptured.map((setPollPoll) => {
          const idString = `${setPoll.id}-${setPollPoll.id}`
          const isExcluded = excludedPolls.setPollPolls[idString]
          return (
            <ScoreSheetFooterCell
              excludePoll={() => { excludePoll(idString, 'setPollPolls', setPoll.id) }}
              isExcluded={isExcluded}
              key={idString}
              percentageCorrect={setPollPoll.correctPercent}
              poll={setPollPoll}
            />
          )
        })}
      </div>
    )
  }
}

export default function (state = {}, action) {
	switch (action.type) {
		case 'UPDATE_CUSTOM_DATE_RANGE':
			return action.dateRange
	case 'LOGOUT':
			return {}
		default:
			return state
	}
}

import React from 'react'
import {toggleMark} from 'prosemirror-commands'
import editorSchema from '../../schema/editorSchema'
import EditorIcon from '../../../components/misc/EditorIcons'
import SymbolPicker from '../../../components/richEditor/editorUI/symbolPicker/SymbolPicker';
import {deleteActiveQuestion} from '../../utils/editorActions/deleteActiveQuestion'
import {duplicateActiveQuestion} from '../../utils/editorActions/duplicateActiveQuestion'
import {insertFriendlyFraction} from '../../utils/editorActions/insertFriendlyFraction'
import {openEquationEditorModal} from '../../utils/editorActions/insertEquation'
import {setToSurvey} from '../../utils/editorActions/setToSurvey'
import {setToGraded} from '../../utils/editorActions/setToGraded'
import {updateRecentSymbols} from '../../utils/updateRecentSymbols'
import {showSymbolNotification} from '../../utils/showSymbolNotification'
import {showSubSupNotification} from '../../utils/showSubSupNotification'
import {showFractionShortcutNotification} from '../../utils/showFractionShortcutNotification'
import store from '../../../store'

const CLOSE_DROPDOWN_DELAY=400

class EditorFormatBarIconButton extends React.Component{  
  render(){   
    return (
        <button onClick={this.props.command} className={'editor-formatBar-iconBtn ' + (this.props.active ? ' editor-formatBar-iconBtn--active' : ' editor-formatBar-iconBtn--inactive') + (this.props.iconName ? ` editor-formatBar-iconBtn--type-${this.props.iconName}`: '') + (this.props.extraClass ? ` editor-formatBar-iconBtn--${this.props.extraClass}` : '')}>
          <EditorIcon name={this.props.iconName} />
          {this.props.children &&
            <React.Fragment>
              {this.props.children}
            </React.Fragment>
          }
          {this.props.tooltipLabel &&
          <div className={'editor-formatBar-iconBtn-tooltip ' + (this.props.keyboardShortcut ? 'editor-formatBar-iconBtn-tooltip--hasShortcut' : 'editor-formatBar-iconBtn-tooltip--noShortcut')}>
            <div className='editor-formatBar-iconBtn-tooltip-label'>
              {this.props.tooltipLabel}
            </div>
          
              {this.props.keyboardShortcut &&              
                <div className='notranslate editor-formatBar-iconBtn-tooltip-keyboardShortcuts'>
                  {this.props.keyboardShortcut.map((key, i)=>{
                     return (
                      <React.Fragment key={key}>
                        <div className='editor-formatBar-iconBtn-tooltip-keyboardShortcuts-key'>
                          {key}
                        </div>
                        {i < this.props.keyboardShortcut.length - 1 &&
                          <div className='editor-formatBar-iconBtn-tooltip-keyboardShortcuts-plus'>
                            +
                          </div>
                        }                
                      </React.Fragment>
                    )
                  })}
                </div>
              }
          </div>
          }          
        </button>
    )
  }
}

function questionIsSurvey(editorView){
   let isSurvey=false
  editorView.state.doc.nodesBetween(0,editorView.state.tr.doc.content.size, (node, pos) => {
    if (node.attrs.activeslide && node.attrs.activeslide===true){
      window.view.state.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
        if (questionNode.type.attrs.correct) {
          if(questionNode.attrs.isSurvey){
                      isSurvey=true
          }
        }
      })  
    }
  })
  return isSurvey
}

class EditorFormatBarReactComponent extends React.Component{

  constructor(props) {
    super(props)
    this.onSelectEmojiSymbol=this.onSelectEmojiSymbol.bind(this)
    this.insertFriendlyFraction=this.insertFriendlyFraction.bind(this)
    this.toggleSuperscript=this.toggleSuperscript.bind(this)
    this.toggleSubscript=this.toggleSubscript.bind(this)
    this.toggleAutoAlgebraMode=this.toggleAutoAlgebraMode.bind(this)
    this.getActiveHighlightStatesCount=this.getActiveHighlightStatesCount.bind(this)
    this.toggleHighlight=this.toggleHighlight.bind(this)
    this.removeAllHighlights=this.removeAllHighlights.bind(this)
    this.toggleHighlight=this.toggleHighlight.bind(this)
    this.removeAllHighlights=this.removeAllHighlights.bind(this)
    this.showHighlightDropdown=this.showHighlightDropdown.bind(this)
    this.delayedCloseHighlightDropdown=this.delayedCloseHighlightDropdown.bind(this)

    this.state = {
      showEmojiPicker:false,
      showSymbolPicker:false,
      showInsertSoundDropdown: false,
      autoAlgebraMode:false,
      showHighlightDropdown:false
    }
    this.closeHighlightTimout=null
  }


  showHighlightDropdown(){
    this.setState({showHighlightDropdown:true})
    if(this.closeHighlightTimout){
      clearTimeout(this.closeHighlightTimout)
    }
  }

  delayedCloseHighlightDropdown(){
    this.closeHighlightTimout = setTimeout(function(){ 
      this.setState({showHighlightDropdown:false})
       }.bind(this), CLOSE_DROPDOWN_DELAY);
  }



  insertFriendlyFraction(){
    insertFriendlyFraction()
    showFractionShortcutNotification()
    // this.props.showNotification('Next time, try typing 1/2 and hitting spacebar.','Tip: For numbers-only fractions, insert faster by typing.','default')
  }

	toggleSuperscript(editorView,isActive){
		if(!isActive){
			toggleMark(editorSchema.marks.superscript)(editorView.state, editorView.dispatch, editorView)
		}
		else{
			toggleMark(editorSchema.marks.superscript)(editorView.state, editorView.dispatch, editorView)
			showSubSupNotification('superscript')
		}
	}

	toggleSubscript(editorView,isActive){
		if(!isActive){
			toggleMark(editorSchema.marks.subscript)(editorView.state, editorView.dispatch, editorView)
			}else{
				toggleMark(editorSchema.marks.subscript)(editorView.state, editorView.dispatch, editorView)
				showSubSupNotification('subscript')
			}
		}


  onSelectEmojiSymbol(unicodeCharacter,name,symbol,category,dropdownRecentCategoryCount,searchTerm){
    const view=window.view
    const state= window.view.state
    var node = view.state.schema.text(unicodeCharacter)
    view.dispatch(state.tr.replaceSelectionWith(node))
    this.setState({showEmojiPicker:false,showSymbolPicker:false})
    showSymbolNotification(symbol)
    updateRecentSymbols(symbol.escapedString)
		if(window.analytics){
			window.analytics.track('Insert symbol', {
				fromSymbolsDropdown:true,
				fromSuggestionsPlugin:false,
				symbolName:name,
				symbol:unicodeCharacter,
				dropdownCategory:category,
				dropdownSearchTerm:searchTerm,
				dropdownRecentCategoryCount:dropdownRecentCategoryCount
			})
		}
  }

  toggleAutoAlgebraMode(){
    const active=!this.state.autoAlgebraMode
    this.setState({autoAlgebraMode:active})
    store.dispatch({ type: 'TOGGLE_AUTO_ALGEBRA_MODE', active })
    if(active){
      this.props.showNotification(' Try typing algebra like x, y^2, 4x, or 4a^2.','Auto Algebra Active:','default') 
    }
    else{
      this.props.showNotification(' Tip: You can turn on Auto Algebra quickly with CLTR SHIFT A (On Mac, CMD SHIFT A)','Auto Algebra Off –','default') 
    }
  }

  getActiveHighlightStatesCount(){
    const {menuItems}=this.props
    let activeHighlightStatesCount=0
    for (var i = 1; i < 9; i++) {
     if(menuItems[`highlight${i}`].isActive){
      activeHighlightStatesCount+=1
     }
    }
    return activeHighlightStatesCount
  }

	toggleHighlight(highlightName,isActive,multiSelectHighlight){ //adapted from https://github.com/ProseMirror/prosemirror-commands/blob/master/src/commands.js
		this.setState({showHighlightDropdown:false})
		const state=window.view.state 
		const markType=editorSchema.marks[`${highlightName}`]
		const attrs={}
		const dispatch=window.view.dispatch
		let {$cursor, ranges} = state.selection

		if ($cursor) {
			dispatch(state.tr.addStoredMark(markType.create(attrs)))
		} else {
			let has = false, tr = state.tr
			for (let i = 0; !has && i < ranges.length; i++) {
				let {$from, $to} = ranges[i]
				has = state.doc.rangeHasMark($from.pos, $to.pos, markType)
			}
			for (let i = 0; i < ranges.length; i++) {
				let {$from, $to} = ranges[i]
				let from = $from.pos, to = $to.pos, start = $from.nodeAfter, end = $to.nodeBefore
				let spaceStart = start && start.isText ? /^\s*/.exec(start.text)[0].length : 0
				let spaceEnd = end && end.isText ? /\s*$/.exec(end.text)[0].length : 0
				if (from + spaceStart < to) { from += spaceStart; to -= spaceEnd }
				tr.addMark(from, to, markType.create(attrs))
				if(window.analytics){
					let highlightColor='red'
					if(highlightName==='highlight2'){
						highlightColor='orange'
					}else if(highlightName==='highlight3'){
						highlightColor='green'
					}else if(highlightName==='highlight4'){
						highlightColor='blue'
					}else if(highlightName==='highlight5'){
						highlightColor='purple'
					}else if(highlightName==='highlight6'){
						highlightColor='yellow'
					}else if(highlightName==='highlight7'){
						highlightColor='pink'
					}else if(highlightName==='highlight8'){
						highlightColor='white'
					}

					window.analytics.track('Add highlight', {
						highlight:highlightName,
						highlightColor:highlightColor
					})
				}
			}
			dispatch(tr.scrollIntoView())
		}
	}

	removeAllHighlights(){
		const state=window.view.state 
		let tr=state.tr
		const dispatch=window.view.dispatch
		let {$cursor, ranges} = state.selection
		for (var i = 1; i < 9; i++) {
			const markType=editorSchema.marks[`highlight${i}`]
			if ($cursor) {
				if (markType.isInSet(state.storedMarks || $cursor.marks()))
					tr.removeStoredMark(markType)
				} else {
					let has = false
					for (let i = 0; !has && i < ranges.length; i++) {
						let {$from, $to} = ranges[i]
						has = state.doc.rangeHasMark($from.pos, $to.pos, markType)
					}
				for (let i = 0; i < ranges.length; i++) {
					let {$from, $to} = ranges[i]
					if (has) {
						tr.removeMark($from.pos, $to.pos, markType)
					} 
				}
			}
		}
		dispatch(tr)
		this.setState({showHighlightDropdown:false})
	}

	render(){ 
		const {editorView,menuItems,slideWidth,maxNumOfQuestionsReached,isSetEditor} =this.props
		const {showSymbolPicker,showHighlightDropdown} = this.state
		const isSurvey=questionIsSurvey(editorView)        
		const isMacOS = navigator.platform.toUpperCase().indexOf('MAC')>=0
		let OSKeyModifier = 'Cltr'
		if(isMacOS){
			OSKeyModifier = '\u{2318}'
		}
		const isQuestionEditor = !isSetEditor

		const activeHighlightStatesCount = this.getActiveHighlightStatesCount()
		let multiSelectHighlight=activeHighlightStatesCount>1?true:false


			return(          
				<div className={'editor-formatBar ' + (isQuestionEditor ? 'editor-formatBar--questionEditor' : '')}>
					<div className='editor-formatBar-left'>
					<div className='editor-formatBar-slideListSpacer' />
					<div className='editor-formatBar-format'>
						<EditorFormatBarIconButton 
							active={menuItems.bold.isActive}
							iconName='formatBold'
							command={()=>{menuItems.bold.command(editorView.state, editorView.dispatch, editorView)}}
						/>
						<EditorFormatBarIconButton
							active={menuItems.italic.isActive}
							iconName='formatItalic'
							command={()=>{menuItems.italic.command(editorView.state, editorView.dispatch, editorView)}}
						/>
						<EditorFormatBarIconButton
							active={menuItems.underline.isActive}
							iconName='formatUnderline'
							command={()=>{menuItems.underline.command(editorView.state, editorView.dispatch, editorView)}}
						/>

						<div className='editor-formatBar-format-highlightDropdownContainer' onMouseOver={this.showHighlightDropdown} onMouseLeave={this.delayedCloseHighlightDropdown}>
							<div className='editor-formatBar-format-highlightDropdown-btn' >
								{multiSelectHighlight &&
									<EditorFormatBarIconButton active={true} iconName='formatHighlightMulti' extraClass='highlight--multi' command={this.removeAllHighlights}/> 
								}
								{activeHighlightStatesCount===0 &&
									<EditorFormatBarIconButton iconName='formatHighlight'  /> 
								}
								{!multiSelectHighlight && menuItems.highlight1.isActive &&
									<EditorFormatBarIconButton active={menuItems.highlight1.isActive} iconName='formatHighlight' extraClass='highlight--1' command={()=>{menuItems.highlight1.command(editorView.state, editorView.dispatch, editorView)}} /> 
								}
								{!multiSelectHighlight && menuItems.highlight2.isActive &&
									<EditorFormatBarIconButton active={menuItems.highlight2.isActive} iconName='formatHighlight' extraClass='highlight--2' command={()=>{menuItems.highlight2.command(editorView.state, editorView.dispatch, editorView)}} /> 
								}
								{!multiSelectHighlight && menuItems.highlight3.isActive &&
									<EditorFormatBarIconButton active={menuItems.highlight3.isActive} iconName='formatHighlight' extraClass='highlight--3' command={()=>{menuItems.highlight3.command(editorView.state, editorView.dispatch, editorView)}} /> 
								}
								{!multiSelectHighlight && menuItems.highlight4.isActive &&
									<EditorFormatBarIconButton active={menuItems.highlight4.isActive} iconName='formatHighlight' extraClass='highlight--4' command={()=>{menuItems.highlight4.command(editorView.state, editorView.dispatch, editorView)}} /> 
								}
								{!multiSelectHighlight && menuItems.highlight5.isActive &&
									<EditorFormatBarIconButton active={menuItems.highlight5.isActive} iconName='formatHighlight' extraClass='highlight--5' command={()=>{menuItems.highlight5.command(editorView.state, editorView.dispatch, editorView)}} />                   
								}
								{!multiSelectHighlight && menuItems.highlight6.isActive &&
									<EditorFormatBarIconButton active={menuItems.highlight6.isActive} iconName='formatHighlight' extraClass='highlight--6' command={()=>{menuItems.highlight6.command(editorView.state, editorView.dispatch, editorView)}} />                   
								}
								{!multiSelectHighlight && menuItems.highlight7.isActive &&
									<EditorFormatBarIconButton active={menuItems.highlight7.isActive} iconName='formatHighlight' extraClass='highlight--7' command={()=>{menuItems.highlight7.command(editorView.state, editorView.dispatch, editorView)}} />                   
								}
								{!multiSelectHighlight && menuItems.highlight8.isActive &&
									<EditorFormatBarIconButton active={menuItems.highlight8.isActive} iconName='formatHighlight' extraClass='highlight--8' command={()=>{menuItems.highlight8.command(editorView.state, editorView.dispatch, editorView)}} />                   
								}
							</div>
							<div className={'editor-formatBar-format-highlightDropdown-dropdownContainer ' + (showHighlightDropdown ? ' editor-formatBar-format-highlightDropdown-dropdownContainer--show' : ' editor-formatBar-format-highlightDropdown-dropdownContainer--hide')}>
								<div className='editor-formatBar-format-highlightDropdown-dropdown'>                      
									<EditorFormatBarIconButton iconName='formatHighlight' extraClass='highlight--null'  command={this.removeAllHighlights} />                         
									<EditorFormatBarIconButton active={false} iconName='formatHighlight' extraClass='highlight--1' command={()=>{this.toggleHighlight('highlight1',menuItems.highlight1.isActive,multiSelectHighlight)}} /> 
									<EditorFormatBarIconButton active={false} iconName='formatHighlight' extraClass='highlight--2' command={()=>{this.toggleHighlight('highlight2',menuItems.highlight2.isActive,multiSelectHighlight)}} /> 
									<EditorFormatBarIconButton active={false} iconName='formatHighlight' extraClass='highlight--3' command={()=>{this.toggleHighlight('highlight3',menuItems.highlight3.isActive,multiSelectHighlight)}} /> 
									<EditorFormatBarIconButton active={false} iconName='formatHighlight' extraClass='highlight--4' command={()=>{this.toggleHighlight('highlight4',menuItems.highlight4.isActive,multiSelectHighlight)}} /> 
									<EditorFormatBarIconButton active={false} iconName='formatHighlight' extraClass='highlight--5' command={()=>{this.toggleHighlight('highlight5',menuItems.highlight5.isActive,multiSelectHighlight)}} />                   
									<EditorFormatBarIconButton active={false} iconName='formatHighlight' extraClass='highlight--6' command={()=>{this.toggleHighlight('highlight6',menuItems.highlight6.isActive,multiSelectHighlight)}} />                   
									<EditorFormatBarIconButton active={false} iconName='formatHighlight' extraClass='highlight--7' command={()=>{this.toggleHighlight('highlight7',menuItems.highlight7.isActive,multiSelectHighlight)}} />                   
									<EditorFormatBarIconButton active={false} iconName='formatHighlight' extraClass='highlight--8' command={()=>{this.toggleHighlight('highlight8',menuItems.highlight8.isActive,multiSelectHighlight)}} />                   
								</div>                    
							</div>            
						</div>         
					</div>
				</div>
				<div className='editor-formatBar-center'>
					<div className='editor-formatBar-center-isSurveyToggle'>
						<button onClick={setToGraded} className={'editor-formatBar-center-isSurveyToggleBtn ' + (!isSurvey ? 'editor-formatBar-center-isSurveyToggleBtn--active' : ' editor-formatBar-center-isSurveyToggleBtn--inactive') }>
							Graded                  
							<div className='editor-formatBar-center-isSurveyToggleBtn-tooltip'>
								<b>Use Graded if the question has a correct answer,</b> for example formative assessments or tests.
							</div>                  
						</button>
						<button onClick={setToSurvey} className={'editor-formatBar-center-isSurveyToggleBtn ' + (isSurvey ? 'editor-formatBar-center-isSurveyToggleBtn--active' : ' editor-formatBar-center-isSurveyToggleBtn--inactive') }>
							Survey
							<div className='editor-formatBar-center-isSurveyToggleBtn-tooltip'>
								<b>Use Survey if there is no right or wrong answer,</b> &nbsp;&nbsp; for example student feedback or discussion topics.
							</div>
						</button>             
					</div>
				</div>

				<div className={'editor-formatBar-right ' + (showSymbolPicker ? ' editor-formatBar-right--symbolPickerOpen ':'')}>
					<div className='editor-formatBar-right-slideAligned'>
						<div className='editor-formatBar-right-slideAligned-spacer'/>
						<div className='editor-formatBar-mathsScience'>
							<React.Fragment>
								<EditorFormatBarIconButton 
									active={menuItems.superscript.isActive}
									iconName='formatMathSuperscript'
									tooltipLabel='Superscript'
									keyboardShortcut={[`${OSKeyModifier}`, '.']}                        
									command={()=>{this.toggleSuperscript(editorView,menuItems.superscript.isActive)}}
								/>
								<EditorFormatBarIconButton 
									active={menuItems.subscript.isActive}
									iconName='formatMathSubscript'
									tooltipLabel='Subscript'
									keyboardShortcut={[`${OSKeyModifier}`, ',']}                        
									command={()=>{this.toggleSubscript(editorView,menuItems.subscript.isActive)}}
								/>			
												
								<EditorFormatBarIconButton
									iconName='formatMathFriendlyFraction'
									tooltipLabel='Insert Fast Fraction'
									keyboardShortcut={[`//`]}
									command={this.insertFriendlyFraction}
								/> 

							{/*}              
							<EditorFormatBarIconButton
							active={this.state.autoAlgebraMode}
							iconName='formatMathAlgebra'
							tooltipLabel={this.state.autoAlgebraMode ? 'Turn off Auto Algebra': 'Turn on Auto Algebra'}
							keyboardShortcut={[`${OSKeyModifier}`, 'Shift', 'A']}
							command={this.toggleAutoAlgebraMode}
							/>
							*/}

							{/* LATER MOVE INTO OWN CONTAINER */}
								<EditorFormatBarIconButton 
									iconName='formatMathEquation'
									tooltipLabel='Insert Equation'
									keyboardShortcut={[`$$`]}
									command={()=>{openEquationEditorModal()}}
								/>
								
								<EditorFormatBarIconButton 
									iconName='formatMathAdvancedEquation'
									tooltipLabel='Insert Advanced Equation'
									keyboardShortcut={[`$/`]}
									command={()=>{openEquationEditorModal(false,true,true)}}
								/>								


								</React.Fragment>     
							</div>

							<div className='editor-formatBar-symbolsEmoji'>
								<EditorFormatBarIconButton 
									active={showSymbolPicker}
									iconName='formatSymbols'
									tooltipLabel='Insert Symbol'
									keyboardShortcut={[`/`]}
									command={()=>{this.setState({showSymbolPicker:!this.state.showSymbolPicker})}}
								/>
							
								{showSymbolPicker && 
									<div className='editor-formatBar-symbolsEmoji-tempEmojiContainer' onClick={(e)=>{e.stopPropagation()}}>                  
										<SymbolPicker 
											onSelectSymbol={this.onSelectEmojiSymbol} 
											closePicker={()=>{this.setState({showSymbolPicker:false})}}
										/>
									</div>
							}
							{/*
							</EditorFormatBarIconButton>
							*/}
						</div>
					</div>
              
          
					<div style={{minWidth: `calc((100vw - ${slideWidth}px)/2)`}} className='editor-formatBar-rightEdge'>                
						{isSetEditor &&
							<div className='editor-formatBar-rightEdge-contents'>                  
								<button className='editor-formatBar-rightEdgeBtn editor-formatBar-rightEdgeBtn--duplicate' disabled={maxNumOfQuestionsReached} onClick={()=>{duplicateActiveQuestion(this.props.onAddQuestionToSet)}}>
									Duplicate
								</button>
								<button className='editor-formatBar-rightEdgeBtn editor-formatBar-rightEdgeBtn--delete' onClick={()=>{deleteActiveQuestion(this.props.onDeleteActiveQuestion)}}>
									Delete
								</button>                
							</div>                
						}
					</div>           
				</div>        
			</div>          
    )   
  } 
}

export default EditorFormatBarReactComponent

 
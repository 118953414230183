import React from 'react'
import QuestionBodyGhostComponentsArray from './QuestionBodyGhostComponentsArray'
import ChoiceGhostComponentsArray from './ChoiceGhostComponentsArray'
import slideLayoutDefinitions from '../../staticData/slideLayoutDefinitions'

const BODY_FONT_SIZES = slideLayoutDefinitions.availableBodyFontSizes
const CHOICE_FONT_SIZES = slideLayoutDefinitions.availableChoiceFontSizes
const AVAILABLE_CONTENT_HEIGHT = slideLayoutDefinitions.totalAvailableContentHeight
const MEDIA_CHOICE_HEIGHT = slideLayoutDefinitions.mediaChoiceFullContainerHeight
const BODY_SOUND_PLAYER_HEIGHT = slideLayoutDefinitions.bodySoundPlayerHeight
const BODY_CENTER_MEDIA_CENTER_MARGIN_BODY_MEDIA =
  slideLayoutDefinitions.bodyCenterMediaCenterMarginBetweenBodyAndMedia

// We have experimented with allowing different media ratio parameters for media center template.
// Currently only use MM and MSound
// Could add ability for user to change the ratio
// To play with media ratios use the FS visualizer at /dev/fs
const mediaRatioParameters = {
  MS: {
    magicMaxMinimumMediaHeight: 400,
    magicRate: 0.7,
  },
  MM: {
    magicMaxMinimumMediaHeight: 400,
    magicRate: 0.25,
  },
  ML: {
    magicMaxMinimumMediaHeight: 400,
    magicRate: 0.1,
  },
  MWide: {
    magicMaxMinimumMediaHeight: 200,
    magicRate: 0,
  },
  MXWide: {
    magicMaxMinimumMediaHeight: 100,
    magicRate: 0,
  },
  MSound: {
    magicMaxMinimumMediaHeight: BODY_SOUND_PLAYER_HEIGHT +
      BODY_CENTER_MEDIA_CENTER_MARGIN_BODY_MEDIA,
    magicRate: 0,
  },
}

class FontSizeCalculatorComponent extends React.Component {
  constructor(props) {
    super(props)
    this.calcFontSizeCombo = this.calcFontSizeCombo.bind(this)
    this.updateBodyHeights = this.updateBodyHeights.bind(this)
    this.updateChoiceHeights = this.updateChoiceHeights.bind(this)
    this.calculateAvailableHeightForBodyAndChoices =
      this.calculateAvailableHeightForBodyAndChoices.bind(this)

    this.state = {
      bodyHeights: {},
      choiceHeights: {},
      questionMediaDynamicHeight: 0,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mediaRatio !== this.props.mediaRatio) {
      this.calcFontSizeCombo()
    }
  }

  // taken from template definitions except for body center media center which is calculated
  calculateAvailableHeightForBodyAndChoices() {
    let height = AVAILABLE_CONTENT_HEIGHT
    const { question } = this.props
    if (question.layout === 'bodyCenterMediaCenter') {
      // if image is wider than this, than treated slightly different from font-size perspective,
      // has lower minimum height
      const imageWideAspectCutoff = 3.3
      // if image is wider than this, than treated slightly different from font-size perspective,
      // has lower minimum height
      const imageXWideAspectCutoff = 15
      let wideImage = false
      let xWideImage = false
      if (question.media && question.media.type === 'image') {
        const imageWidth = question.media.deliveryDimensions.width
        const imageHeight = question.media.deliveryDimensions.height
        const imageAspect = imageWidth / imageHeight
        if (imageAspect > imageWideAspectCutoff) {
          wideImage = true
        }
        if (imageAspect > imageXWideAspectCutoff) {
          xWideImage = true
        }
      }

      // default media ratio params are normal
      let mediaRatioParamsRatio = this.props.mediaRatio || 'MM'
      if (wideImage) {
        mediaRatioParamsRatio = 'MWide'
      }
      if (xWideImage) {
        mediaRatioParamsRatio = 'MXWide'
      }
      if (question.media.type === 'sound') {
        mediaRatioParamsRatio = 'MSound'
      }

      const { bodyHeights, choiceHeights } = this.state
      const mediaRatioParams = mediaRatioParameters[`${mediaRatioParamsRatio}`]
      const { magicMaxMinimumMediaHeight } = mediaRatioParams
      // as increases, media grows smaller quicker and font-sizes stay bigger
      const { magicRate } = mediaRatioParams
      const magicMediaHeight = magicMaxMinimumMediaHeight -
        (magicRate * (bodyHeights.fontSize_36.height + choiceHeights.font_30.total))
      height = AVAILABLE_CONTENT_HEIGHT - magicMediaHeight
    }
    return height
  }

  // isValidFontSize is used to prevent long equations being wider than the field
  // if the width of ghost element is wider than allowed
  // then isValidFontSize=false and must try a smaller FS
  // this only effects math nodes
  calcFontSizeCombo() {
    const { bodyHeights, choiceHeights } = this.state
    const template = this.props.question.layout
    // find allowed combo with largest choice font size
    let perfectChoiceFontSize = CHOICE_FONT_SIZES[0]
    let perfectBodyFontSize = BODY_FONT_SIZES[0]
    const availableHeightForBodyAndChoices = this.calculateAvailableHeightForBodyAndChoices()
    const questionHasMediaChoices = (template === 'bodyCenterChoicesMedia' || template === 'bodyLeftMediaRightChoicesMedia')
    BODY_FONT_SIZES.forEach((fontSize) => {
      const bodyHeight = bodyHeights[`fontSize_${fontSize}`].height
      const isValidBodyFontSize = bodyHeights[`fontSize_${fontSize}`].isValidFontSize
      if (isValidBodyFontSize) {
        CHOICE_FONT_SIZES.forEach((choiceFontSize) => {
          // if choice font is bigger than body's, return
          if (choiceFontSize > fontSize) return

          const { isValidFontSize } = choiceHeights[`font_${choiceFontSize}`]
          if (!isValidFontSize) return

          const choiceHeightsForFontSize = choiceHeights[`font_${choiceFontSize}`]
          let choiceHeight = 0
          if (choiceHeightsForFontSize) {
            choiceHeight = choiceHeights[`font_${choiceFontSize}`].total
          }
          let combinedHeight = choiceHeight + bodyHeight
          if (questionHasMediaChoices) {
            combinedHeight = bodyHeight + MEDIA_CHOICE_HEIGHT
          }
          // if combo too tall, return
          if (combinedHeight > availableHeightForBodyAndChoices) return

          // update perfect font sizes
          if (
            choiceFontSize > perfectChoiceFontSize ||
            (choiceFontSize === perfectChoiceFontSize && fontSize > perfectBodyFontSize)
          ) {
            perfectChoiceFontSize = choiceFontSize
            perfectBodyFontSize = fontSize
          }
        })
      }
    })
    this.props.updatePerfectCombo({
      bodyFontSize: perfectBodyFontSize,
      choiceFontSize: perfectChoiceFontSize,
    })
    const questionMediaDynamicHeight = AVAILABLE_CONTENT_HEIGHT -
      (bodyHeights[`fontSize_${perfectBodyFontSize}`].height + choiceHeights[`font_${perfectChoiceFontSize}`].total) -
      BODY_CENTER_MEDIA_CENTER_MARGIN_BODY_MEDIA
    this.setState({ questionMediaDynamicHeight })
    this.props.updateQuestionMediaDynamicHeight(questionMediaDynamicHeight)
  }

  updateBodyHeights(bodyHeights) {
    if (this.props.updateBodyHeights) { // for visualizer
      this.props.updateBodyHeights(bodyHeights)
    }
    this.setState({ bodyHeights }, () => {
      this.calcFontSizeCombo()
    })
  }

  updateChoiceHeights(choiceHeights) {
    if (this.props.updateChoiceHeights) {
      this.props.updateChoiceHeights(choiceHeights)
    }
    this.setState({ choiceHeights }, () => {
      this.calcFontSizeCombo()
    })
  }

  render() {
    const { question } = this.props
    return (
      <div className={`fontSizeCalculator-ghostArrays${this.props.devShowGhostElements ? ' fontSizeCalculator-ghostArrays--devDisplay' : ' fontSizeCalculator-ghostArrays--hidden'}`}>
        <div className="fontSizeCalculator-ghostArrays-bodyArrayContainer">
          <QuestionBodyGhostComponentsArray
            template={question.layout}
            question={question}
            updateBodyHeights={this.updateBodyHeights}
            bodyHeights={this.state.bodyHeights}
            questionMediaDynamicHeight={this.state.questionMediaDynamicHeight}
          />
        </div>
        <div className="fontSizeCalculator-ghostArrays-choiceArrayContainer">
          <ChoiceGhostComponentsArray
            template={question.layout}
            question={question}
            updateChoiceHeights={this.updateChoiceHeights}
            questionMediaDynamicHeight={this.state.questionMediaDynamicHeight}
          />
        </div>
      </div>
    )
  }
}

export default FontSizeCalculatorComponent

import React from 'react'
import ModalWrapper from '../ModalWrapper'
import ChangePasswordContainer from '../../../containers/modals/account/ChangePasswordContainer'

const BillingInfoModal = props => {

	return (

    <ModalWrapper
      {...props}
      title={'Change Password'}
      iconName=''
      modalStyle='dark'
    >
      <ChangePasswordContainer {...props}/>

    </ModalWrapper>

 

)
}

export default BillingInfoModal
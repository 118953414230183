import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_REPOS_SUCCESS':
		let newState = [...state] || []
		action.response.forEach(repo => {
			const repoId = repo.id
			let indexOfRepo = newState.findIndex(repo => {
				return repo.id === repoId
			})
	
			if(indexOfRepo === -1){
				indexOfRepo=newState.length
			}
			newState= [
				...newState.slice(0,indexOfRepo),
				repo,
				...newState.slice(indexOfRepo + 1) 
			]	
		})
		return newState



	case 'FETCH_DISCOVER_LATEST_REPOS_SUCCESS':
		let newStateWithLatest = [...state] || []
		action.repos.forEach(repo => {
			const repoId = repo.id
			let indexOfRepo = newStateWithLatest.findIndex(repo => {
				return repo.id === repoId
			})
	
			if(indexOfRepo === -1){

				indexOfRepo=newStateWithLatest.length		
				let modifiedRepo={...repo}
				modifiedRepo.author=repo.authorObject||{}
				modifiedRepo.following=false
				modifiedRepo.role='consumer'
				modifiedRepo.subject=repo.subjectObject||{}
				// const subjectObject=find(subjects,)

				newStateWithLatest= [
					...newStateWithLatest.slice(0,indexOfRepo),
						modifiedRepo,
					...newStateWithLatest.slice(indexOfRepo + 1) 
				]	
		}
	})


		return newStateWithLatest




	case 'REPO_ADDED':
	case 'CREATE_REPO_SUCCESS':
		const addedRepoId = action.repo.id
		var indexOfRepo = findIndex(state,repo => {
			return repo.id === addedRepoId
		})
		if(indexOfRepo === -1){
			indexOfRepo=state.length
		}

		return [
			...state.slice(0,indexOfRepo),
			action.repo,
			...state.slice(indexOfRepo + 1)
		]


	case 'REPO_REMOVED':
	case 'DELETE_REPO_SUCCESS':

		const removedRepoId = action.repoId
		const indexOfRepoToRemove = findIndex(state,repo => {
			return repo.id === removedRepoId
		})
		if(indexOfRepoToRemove !==-1){
			return [
				...state.slice(0, indexOfRepoToRemove),
				...state.slice(indexOfRepoToRemove + 1)
			]

		}
		else return state

	case 'UPDATE_REPO_SUCCESS':
		const updatedRepoId = action.response.id
		var indexOfRepoToUpdate = findIndex(state,repo => {
			return repo.id === updatedRepoId
		})
		if(indexOfRepoToUpdate === -1){
			indexOfRepoToUpdate=state.length
		}

		return [
			...state.slice(0,indexOfRepoToUpdate),
			action.response,
			...state.slice(indexOfRepoToUpdate + 1)
		]
		

		
	case 'FETCH_A_REPO_SUCCESS':
		const fetchedRepoId = action.response.id
		var indexOfFetchedRepo = findIndex(state,repo => {
			return repo.id === fetchedRepoId
		})
		if(indexOfFetchedRepo === -1){
			indexOfFetchedRepo=state.length
		}

		return [
			...state.slice(0,indexOfFetchedRepo),
			action.response,
			...state.slice(indexOfFetchedRepo + 1)
		]
	

	// case 'DELETE_REPO_SUCCESS':
	// 	const repoId = action.repoId
	// 	//find index of the question we deleted
	// 	const indexOfRepoToDelete = findIndex(state,repo => {
	// 		return repo.id === repoId
	// 	})
	// 	return [
	// 		// from the start to the one we want to delete
	// 		...state.slice(0, indexOfRepoToDelete),
	// 		// after the deleted one, to the end
	// 		...state.slice(indexOfRepoToDelete + 1)
	// 	]

	case 'LOGOUT':
		return null
	
	default:
		return state
	}
}

import editorSchema from '../../schema/editorSchema'
import {TextSelection} from "prosemirror-state"
import {analyticsInsertFriendlyFraction} from '../../../utils/analytics/analyticsInsertFriendlyFraction'

 
function getAnchorNode($from) {
	let anchorNode
	for (let i = $from.depth; i > 0; i--) {
		let node = $from.node(i)
		if (node.type.name === "friendlyFraction") {
			anchorNode = node
			break
		}
	}
	return anchorNode
}

export function insertFriendlyFraction() {
	const state= window.view.state
	const dispatch=window.view.dispatch
	let {empty,$from, $to} = state.selection
	const anchorNode=getAnchorNode($from)  //check you are not already inside a friendly fraction
	if(anchorNode){// console.log('ALREADY IN FRACTION')
	}
	else{
		let tr=state.tr
		const type = editorSchema.nodes.friendlyFraction
		let numerator
		let content
		if(empty){
			numerator=editorSchema.nodes.friendlyFractionNumerator.create(null)
		}else{
			content=tr.doc.slice($from.pos,$to.pos)
			numerator=editorSchema.nodes.friendlyFractionNumerator.create(null,content.content)
		}
		const denominator=editorSchema.nodes.friendlyFractionDenominator.create(null)
		const node=type.create(null,[numerator,denominator])
		tr.setMeta("triggerParseDoc", true) 
		tr.replaceSelectionWith(node)
		let selection
		if(empty){
			selection = new TextSelection(tr.doc.resolve($from.pos+2))
		}else{
			selection = new TextSelection(tr.doc.resolve($from.pos+2+content.size+2))//put cursor in denominator 
		}
		tr.setSelection(selection) //position cursor after inserted stuff		
		const startPositionParentOffset=tr.doc.resolve($from.pos).parentOffset
		if(startPositionParentOffset===0){ //if start is at the begining of parent node insert a space before fraction so user can put cursor infront of fraction
			tr.insertText('\u00a0',$from.pos,$from.pos)
		}
		tr.insertText('\u00a0',$from.pos+node.nodeSize+1,$from.pos+node.nodeSize+1) 
		
		const UISource="button"
		let numeratorText
		if(!empty){
			numeratorText=numerator.textContent
		}
		analyticsInsertFriendlyFraction(UISource,numeratorText)
		dispatch(tr)
	}
}    

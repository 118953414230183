import React from 'react'
// import Icon from '../../../misc/Icons'
// import Button from '../../../uiKit/Button'
// import SectionHomeAddToQueue from './SectionHomeAddToQueue'

class SectionHomeEmptyQueue extends React.Component{

	render(){
				

		return(			
			<div className="sectionHome-emptyQueue">
				<div className="sectionHome-emptyQueue-title">
				No items queued for <span className='notranslate'>{this.props.sectionName}</span>
				</div>
				<div className="sectionHome-emptyQueue-subtitle">
					Add items to your class to play questions faster in your lessons.
				</div>				
			</div> 
						
		)
	}


}


export default SectionHomeEmptyQueue





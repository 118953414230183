import React from 'react'
import { Field } from 'redux-form'
import Icon from '../misc/Icons'

const SectionColorOptions = [
	{fieldValue:'blue'},
	{fieldValue:'purple'},
	{fieldValue:'red'},
	{fieldValue:'orange'},
	{fieldValue:'green'},
	{fieldValue:'sky'},
	{fieldValue:'pink'},
	{fieldValue:'teal'},
	{fieldValue:'grey'},
]


class FormFieldClassColor extends React.Component{

	render(){		

		const colorFields = SectionColorOptions

		return(
			<div className='formField is--classColor'>
				<div className='formField-label'>
					Class Color
				</div>

				<div className='formField-classColorBtn--row'>
					{colorFields.map((colorField, index) => {	
						return(							
							<label className={colorField.fieldValue ? ` is--color-${colorField.fieldValue}`: ''} key={index}>
								<Field 
									name='color'
									type='radio'
									value={colorField.fieldValue}
									className={colorField.fieldValue}
									id={colorField.fieldValue}
									component='input'	
								/>								
								<Icon name='check' />									
							</label> 							
						)})}
				</div>									
			</div>
		)
	}				
}

export default FormFieldClassColor



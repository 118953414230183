import React from 'react'
import SectionMarker from '../uiKit/SectionMarker'
import Icon from '../misc/Icons'
import ScorePill from '../uiKit/ScorePill'
import Button from '../uiKit/Button'
import {processQuestionResponses} from '../../utils/processQuestionResponses'
import {formatDateLong} from '../../utils/formatDateLong'
import ScoreSheetSetReviewModal from './ScoreSheetSetReviewModal'
import StaticQuestionSlide from '../slides/StaticQuestionSlide'

//modal showing question slide and response graphs.  Opened by clicking on question in scoresheet header

class ScoreSheetReviewModal extends React.Component{
	constructor(props) {
			super(props)
			this.handleKeyDown = this.handleKeyDown.bind(this)
			this.previousArrowOnClick = this.previousArrowOnClick.bind(this)
			this.nextArrowOnClick = this.nextArrowOnClick.bind(this)
			this.goToReport = this.goToReport.bind(this)
			this.handleBackgroundClick = this.handleBackgroundClick.bind(this)
	}

	UNSAFE_componentWillMount(){
		document.addEventListener('keydown', this.handleKeyDown)
	}

	componentWillUnmount(){
		document.removeEventListener('keydown', this.handleKeyDown)
	}

	handleKeyDown(e){
		if(e.keyCode===37){ //left arrow key
			this.props.changeReviewPoll('previous')
		}else if(e.keyCode===39){ //right arrow key
			this.props.changeReviewPoll('next')
		}else if(e.keyCode === 27){//escape key
			this.props.closeReviewModal()
		}
	}

	previousArrowOnClick(e){
		e.stopPropagation() 
		this.props.changeReviewPoll('previous')
	}

	nextArrowOnClick(e){
		e.stopPropagation() 
		this.props.changeReviewPoll('next')
	}

	goToReport(e){
		e.stopPropagation()
		let reportPath
		if(this.props.setPoll){
			reportPath=`/setreport/${this.props.setPoll.id}/${this.props.poll.question}`
		}else{
			reportPath=`/questionreport/${this.props.poll.id}`
		}
		window.open(reportPath,'_blank')
	}

	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.props.closeReviewModal() //close modal is click outside modal
	}

	render(){
		const {section,poll,setPoll} = this.props
		let processedQuestionResponses={}
		if(poll){ 
			processedQuestionResponses = processQuestionResponses(poll,section)
			let totalResponseCount=poll.unassignedResponseCount+poll.assignedResponseCount
			let playedString=''
			if(poll.minCaptured){
				playedString=`Played ${formatDateLong(poll.minCaptured)} `
			}
		
			return(
				<div className='scoreSheet-reviewModalContainer' >	
					
					{!this.props.collapsedView &&
						<div onClick={this.previousArrowOnClick} className='scoreSheet-reviewModalContainer-navButton scoreSheet-reviewModalContainer-navButton--previous' >
							<Icon name='chevron-left' />
						</div>
					}

					<div className='scoreSheet-reviewModal'>
					
						<div className='scoreSheet-reviewModal-header'>							
							<SectionMarker section={section} />
							{playedString}

						
							<Button label='Go to Report' onClickFunction={this.goToReport}/>

						</div>

						<div className='scoreSheet-reviewModal-questionSlideContainer'>
						
							<ScorePill score={poll.correctPercent} />

							<StaticQuestionSlide 
								question={poll.snapshot}
								displayWidth={816} 
								processedResponses={processedQuestionResponses}
								totalResponses={totalResponseCount}
								reportView
								mediaIsPlayable
							/>
						</div>

					</div>	

					{!this.props.collapsedView &&
						<div onClick={this.nextArrowOnClick} className='scoreSheet-reviewModalContainer-navButton scoreSheet-reviewModalContainer-navButton--previous'>
							<Icon name='chevron-right' />
						</div>
					}

					<div className='scoreSheet-reviewModalContainer-bg'  onClick={this.handleBackgroundClick} />

					{/* <div className='scoreSheet-reviewModalContainer-bg' onClick={this.props.closeReviewModal} /> */}
				</div>
			)}

		else if(setPoll){
			return(
				<div className='scoreSheet-setReviewModalContainer'>					
					<ScoreSheetSetReviewModal setPoll={setPoll} section={section}/>			
					<div className='scoreSheet-setReviewModalContainer-bg' onClick={this.handleBackgroundClick}/>
				</div>

			)
		}
	}
}


export default ScoreSheetReviewModal

import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../misc/Icons'
import {getRepoforId} from '../../utils/getRepoforId'
import {getLinkForRepo} from '../../utils/getLinkForRepo'

class SearchResultsFolderCard extends React.Component{

	render(){	

		const {folder} = this.props
		let linkPath=`/library/${folder.id}`
		let repoName = null
		let inSharedRepo = false
		let inPublicConsumerRepo = false
		let parentRepo 
		if(folder.repo){
			parentRepo = getRepoforId(folder.repo)
			if(parentRepo){
				repoName=parentRepo.name
				inSharedRepo=parentRepo.shared
				inPublicConsumerRepo=parentRepo.role==='consumer'
				linkPath=`${getLinkForRepo(parentRepo)}/${folder.id}`
			}else{
				linkPath=`/packs/${folder.repo}/${folder.id}`
			}		
		}

		return(
			<Link to={linkPath}>
				<div className='searchResultsCard searchResultsCard--folder'>					
					<Icon name={!folder.readOnly ? 'folder' : 'folder-isbe'}/>
					<div className='searchResultsCard-title'>
						{folder.name}
						{inSharedRepo && <Icon name='shared'/>}
						{inPublicConsumerRepo && <Icon name='globe-shared'/>}						
						{repoName &&							
							<Link to={getLinkForRepo(parentRepo)} className='searchResultsCard--folder-repo'>
								{repoName}
							</Link>
						}
					</div>
					<div className='searchResultsCard-rightArrow'>
						<Icon name='chevron-right' />
					</div>
				</div>
			</Link>
		)
	}
}

export default SearchResultsFolderCard
import { Component } from 'react'
import { withRouter,matchPath } from 'react-router-dom'

//used for contextual Back Buttons throughout the app
//e.g. in set detail page button could be "Back To Library", "Back To Recent" or no back button (if arrived from search)

let lastLocation = null 

class LastLocationProvider extends Component {
 
	UNSAFE_componentWillReceiveProps(nextProps) {
		if(lastLocation === 'search'){ //if get to e.g. setDetail page through search we don't show a back button
			lastLocation = null
		}else{
			if(this.props.location){
				if(matchPath(this.props.location.pathname, { path:'/setreport/:pollId/:pollPollId',isExact:true})){
					return
				}
			}
			if(nextProps.location){
				if(matchPath(nextProps.location.pathname, { path:'/setreport/:pollId/:pollPollId',isExact:true})){
					return 
				}
			}
			if (this.props.location === nextProps.location) {
				return
			}
		
			lastLocation = {
				...this.props.location,
			}
			if (this.props.location.pathname === nextProps.location.pathname) {
				return
			}
			lastLocation = {
				...this.props.location,
			}
		}
	}
	render() {
		return this.props.children
	}
}

export function getLastLocation(){
	return lastLocation
}
export function setLastLocation(manualLastLocation){
	lastLocation =  manualLastLocation
	return lastLocation
}
export default withRouter(LastLocationProvider)
//old creator fund stuff

export function generateFrontCURLRequest(submission,email) {
	const toEmail=submission.repo.author.email
	let fromEmail="joe.baylis@plickers.com"
	if(submission.assignee==='Nolan'){
		fromEmail="nolan@plickers.com"
	}
	const data={"to":[`${toEmail}`],
	"subject":`Plickers Creator Fund: Update on your Pack ${submission.repo.name}`,
	"body": `${email}`,
	"author_id":`alt:email:${fromEmail}`
	}

	const dataString=JSON.stringify(data)
	const request=	`curl --location --request POST 'https://api2.frontapp.com/channels/cha_3oyhb/drafts' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOlsic2hhcmVkOioiLCJwcml2YXRlOioiLCJwcm92aXNpb25pbmciXSwiaWF0IjoxNTgzNDM5ODYyLCJpc3MiOiJmcm9udCIsInN1YiI6IjM4NjVlMmEyNmI0MTYxMGM1OGI4IiwianRpIjoiODMzM2U0Y2NlYzMzMDY4MSJ9.AR6t2CGABjV9LKuNSoC8jq3AgP4vG_ePypDVJHpd9DU' --header 'Content-Type: application/json' --data-raw '${dataString}'`
	return request
}


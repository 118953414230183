import api from '../api'
import {fetchDiscounts} from './discounts'
import {fetchBilling,fetchInvoices,fetchUpcomingInvoice} from './billing'
import {dismissBackToSchoolModal} from './backToSchoolModal'
import store from '../store'

function setProductInLocalStorage(product) {
	localStorage.setItem('service.product', JSON.stringify(product))
	return null
} 

export function fetchService() {
	return (dispatch) => api.fetch('/service')
		.then((response) => {
			dispatch({ type: 'FETCH_SERVICE_SUCCESS',response})
			setProductInLocalStorage(response.product)
		})
		.catch(err => {})
} 

export function upgradeService(data) {
	const state = store.getState()
	const service=state.service
	////if upgrading a cancelled membership to a different plan (monthly/yearly) must reactivate and then switch plan
	if(service.status==='canceled' && data.plan!==service.plan){ 
		return (dispatch) => api.put('/service/upgrade',data)
		.then((response) => {
			return dispatch(switchPlan(data.plan,data.coupon))
		})
	}else{
		return (dispatch) => api.put('/service/upgrade',data)
			.then((response) => {
				dispatch(dismissBackToSchoolModal())
				dispatch({ type: 'UPDATE_SERVICE_SUCCESS',response})
				setProductInLocalStorage(response.product)
				dispatch(fetchDiscounts())
				dispatch(fetchBilling())
				dispatch(fetchInvoices())
				dispatch(fetchUpcomingInvoice())
				return response
			})
	}
}

export function switchPlan(plan,coupon) { //Switch between monthly/yearly plans
	let requestData={plan:plan}
	if(coupon){
		requestData.coupon=coupon
	}
	return (dispatch) => api.put('/service/switch-plan',requestData)
		.then((response) => {
			dispatch(dismissBackToSchoolModal())
			dispatch({ type: 'SWITCH_PLAN_SUCCESS',response})
			dispatch(fetchDiscounts())
			dispatch(fetchBilling())
			dispatch(fetchInvoices())
			dispatch(fetchUpcomingInvoice())
			setProductInLocalStorage(response.product)
			return response
		})
}

export function cancelService() {
	return (dispatch) => api.put('/service/cancel')
		.then((response) => {
			dispatch(dismissBackToSchoolModal())
			dispatch({ type: 'UPDATE_SERVICE_SUCCESS',response})
			return response
		})
		.catch((error) => {})
}


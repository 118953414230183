export default function (state = null, action) {
	switch (action.type) {

	case 'CLOCK_SYNC_SUCCESS':
		return action.clockOffset

	case 'LOGOUT':
		return null
	
	default:
		return state
	}
}

const initialState = {
	signinMethods: {}
}


export default function (state=initialState, action) {
	
	switch (action.type) {
	
	case 'FETCH_USER_SIGNIN_METHODS_SUCCESS':
		return action.response

	case 'ADD_GOOGLE_SIGNIN_SUCCESS':
		return {...state, google:true}

	case 'ADD_APPLE_SIGNIN_SUCCESS':
		return {...state, apple:true}	

	case 'ADD_PASSWORD_SUCCESS':
		return {...state, password:true}

	case 'REMOVE_EXTERNAL_SIGNIN_SUCCESS':
		return {...state, google:false,apple:false}

	case 'LOGOUT':
		return initialState

	default:
		return state
			
	}
}



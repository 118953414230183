import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import {updateMeta} from '../../actions/meta'
import Icon from '../../components/misc/Icons'
import ReleaseNotesList from '../../components/releaseNotes/ReleaseNotesList'

class ReleaseNotesContentContainer extends Component {

	constructor(){
		super()				
		this.dismissModal=this.dismissModal.bind(this)
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)
	}
	componentDidMount(){
		//update whats new last seen 
		const nowDate=new Date()
		this.props.updateMeta({whatsNewLastSeen:nowDate})


		if(window.analytics){
			window.analytics.page('Release Notes')
		}
	}
 
	dismissModal(){
		this.props.history.push(window.location.pathname)
	}

	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.dismissModal() //close modal is click outside modal
	}
	
	render() {
		return (
			<div className='releaseNotesContainer' onClick={this.handleBackgroundClick}>				

				<div className='releaseNotes marketing'>
					<div className='releaseNotes-closeBtn' onClick={this.dismissModal}>
						<Icon name='circle-with-close' />
					</div>

					<div className='releaseNotesScrollContainer'>
						<div className='releaseNotes-header'>
							what's new on <span className='releaseNotes-header-blue'> plickers</span>
						</div>					
						<div className='releaseNotes-header-bg'>
							<svg width="766" height="117" viewBox="0 0 766 117">
								<path fill="#f1f7ff" fillRule="evenodd" d="M15.5044714,20.6842545 C29.086437,-1.03149103 235.979193,3 410.985276,3 C654.099757,3 728.51585,-12.2195027 754.63142,31.4852208 C764.098116,47.3278507 772.686043,81.4538301 754.63142,103.471107 C736.576797,125.488383 514.429428,111.736139 440.962143,113 C293.49732,115.536844 19.5053204,119.763459 6.69454778,103.471107 C-5.85078344,87.516335 1.92250582,42.4 15.5044714,20.6842545 Z"/>
							</svg>
						</div>

						<ReleaseNotesList />

						</div>

				</div>
			</div>					
				
		)
	}
}


const ConnectedReleaseNotesContainer=withRouter(connect(
	(state) => ({	}),
	{updateMeta }
)(ReleaseNotesContentContainer))


class ReleaseNotesContainer extends Component {
	render() {
		if(window.location.hash==='#updates' ){
			return <ConnectedReleaseNotesContainer/>	
		}
		else return null}
}	

export default withRouter(connect(
	(state) => ({
	
	}),
	{ }
)(ReleaseNotesContainer))	






import React, {Component} from 'react'
import Button from '../../components/uiKit/Button'
import Icon from '../../components/misc/Icons'
import {getPublicUrlForRepo} from '../../utils/getPublicUrlForRepo'


  
class ReviewPublishedReviewStar extends Component{

	render(){	
		
		const {onMouseOver,onMouseLeave,btnRating,rating,mouseOverRating} = this.props
		const isActive=((rating && rating>(btnRating-1)) || (!rating && mouseOverRating && mouseOverRating>(btnRating-1)))
		return(		
			<button
				className={'reviewPublishedRepoModal-starBtn ' + (isActive ? 'reviewPublishedRepoModal-starBtn--active' :  'reviewPublishedRepoModal-starBtn--inactive')}							
				onClick={()=>{this.props.setRating(btnRating)}}
				onMouseOver={()=>{onMouseOver(btnRating)}}
				onMouseLeave={onMouseLeave}
			>				
				<Icon name='star' />
			</button>					

			
		)
	}
}

  
  

class ReviewPublishedRepoModal extends Component{

	constructor(props) {
		super(props)
		this.createReview=this.createReview.bind(this)
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)

		
		this.state = {
			rating: null,
			mouseOverRating:null,
			feedbackText:'',
			submitLoading:false
		}
	}


	componentDidMount() {
		if(window.analytics){
			window.analytics.page('Review Public Pack Modal')
		}
	}



	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden' //prevent scrolling while modal is open
	}

 
	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}


	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.props.closeModal() //close modal is click outside modal
	}


 

	createReview(){
		this.setState({submitLoading:true})
		const {user}=this.props
		let userHasUsername = false
		if(user.username){
			userHasUsername=true
		}
		const requestData={
			repo:this.props.repo.id,
			rating:this.state.rating,
			comment: this.state.feedbackText,
			anonymous: !userHasUsername,
		}
		if(window.analytics){
			const {rating,feedbackText}=this.state
			window.analytics.track('Public Pack Review', {
				  rating:rating,
				  feedbackText:feedbackText,
				  repoId:this.props.repo.id,
				  repoUrl:getPublicUrlForRepo(this.props.repo),
				  anonymous:!userHasUsername,
				  repoName:this.props.repo.name
			})
		}



		this.props.createReview(requestData).then(()=>{
			this.props.closeModal()
			this.props.showNotification('','Public Feedback Submitted','default')

		})
	}




	render(){	
		
		const {rating,mouseOverRating} = this.state
		const {repo,user}=this.props
		let userHasUsername = false
		if(user.username){
			userHasUsername=true
		}

		let reviewTextAreaPlaceholder = 'Please leave any additional comments about this pack, including anything that other educators would find helpful. Your comment will be anonymous.'
		if (userHasUsername){
			reviewTextAreaPlaceholder = 'Please leave any additional comments about this pack, including anything that other educators would find helpful.'
		}
		const buttonRatings=[1,2,3,4,5]

		return(					
     	 <div className='reviewPublishedRepoModalContainer' onMouseDown={this.handleBackgroundClick}>				
				<div className={'reviewPublishedRepoModal' + (rating ? ' reviewPublishedRepoModal--hasRating' : ' reviewPublishedRepoModal--noRating')}>

					<div className='reviewPublishedRepoModal-closeBtn' onClick={this.props.closeModal} >
						<Icon name='circle-with-close'  />
					</div>
					{rating &&
						<div className='reviewPublishedRepoModal-title'>
							Public Feedback
						</div>
					}
					<div className='reviewPublishedRepoModal-subtitle'>
						How you would rate your experience using<br/>{repo.name}?
					</div>
					<div className='reviewPublishedRepoModal-anonymous'>																		
						Please take this opportunity to leave a review about your experience with this pack. Tell us how it met your needs and what other teachers might like to know about it.						
						{!userHasUsername && <span> Your review will be anonymous.</span>}
					</div>					
				<div className='reviewPublishedRepoModal-starBtnRowContainer'>
					<div className='reviewPublishedRepoModal-starBtnRow'>
					
					{buttonRatings.map((btnRating)=>{
						return <ReviewPublishedReviewStar
						key={btnRating}
						btnRating={btnRating}
						rating={rating}
						mouseOverRating={mouseOverRating}
						setRating={(btnRating)=> this.setState({rating: btnRating})}
						onMouseOver={(btnRating)=> this.setState({mouseOverRating: btnRating})}
						onMouseLeave={()=> this.setState({mouseOverRating: null})}
					/>
    				})}

			 		


					</div>
				</div>
				
					{rating && 
						<textarea 
							className='reviewPublishedRepoModal-message' 
							placeholder={reviewTextAreaPlaceholder}					 
							maxLength={300}
							value={this.state.feedbackText}
							onChange={(e)=>{this.setState({feedbackText:e.target.value})}}
						/>
					}
					{rating && 
						<Button 
							name='submitfeedback'
							size='xLarge'				
							label='Submit Public Feedback'
							color='blue'
							onClickFunction={this.createReview}
							disabled={!this.state.rating}
							submitLoading={this.state.submitLoading}
						/>
					}


				</div>
			</div>				


			
		)
	}
}


 
					



export default ReviewPublishedRepoModal

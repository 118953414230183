import katex from 'katex'

export function katexRender({
  latex,
  dom,
  throwOnError = false,
  errorCallback,
}) {
  try {
    katex.render(latex, dom, {
      throwOnError,
      output: 'html',
    })
  } catch (e) {
    // error includes assertion error which supposed to be a parse error
    // see https://github.com/KaTeX/KaTeX/issues/3760
    renderKatexError(latex, dom)
    if (errorCallback) { errorCallback() }
  }
}

function renderKatexError(invalidLatex, dom) {
  // manually apply the same handling for parse error
  const span = document.createElement('span')
  span.textContent = invalidLatex
  span.className = 'katex-error'
  span.style = 'color:#cc0000'
  dom.appendChild(span)
}

function htmlDecode(input) { // handle inequality signs
  let escapedInput = input.replace(/</g, '&lt')
  escapedInput = escapedInput.replace(/>/g, '&gt')
  const doc = new DOMParser().parseFromString(escapedInput, 'text/html')
  return doc.documentElement.textContent
}

export function katexRenderToString(latex) {
  const parsedLatex = htmlDecode(latex)
  try {
    const html = katex.renderToString(parsedLatex, {
      throwOnError: false,
      output: 'html',
    })
    return html
  } catch (e) {
    // catch assertion error that gets thrown and crashes the app
    // see https://github.com/KaTeX/KaTeX/issues/3760
    // manually apply the same handling for parse error
    return `<span className='latex-error' style='color:#cc0000'>${parsedLatex}<span>`
  }
}

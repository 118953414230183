import {InputRule} from 'prosemirror-inputrules'
import schema from '../../schema/editorSchema'
import {TextSelection} from "prosemirror-state"
import {analyticsInsertFriendlyFraction} from '../../../utils/analytics/analyticsInsertFriendlyFraction'


function getAnchorNode($from) {
	let anchorNode
	for (let i = $from.depth; i > 0; i--) {
		let node = $from.node(i)
		if (node.type.name === "friendlyFraction") {
			anchorNode = node
		break
		}
	}
	return anchorNode
}


//insert fraction if type //
export function insertFractionShortcut(){
	return new InputRule(/([^\s]*)\/\/$/g, (state, match, start, end) => {
		const nodePos=state.tr.doc.resolve(start)
		const anchorNode=getAnchorNode(nodePos)//check you are not already inside a friendly fraction
		if(anchorNode){
			return false
		}else{   
			const numeratorSlice=state.doc.slice(start,start+match[1].length)
			const type = schema.nodes.friendlyFraction  
			const numerator=schema.nodes.friendlyFractionNumerator.create(null,numeratorSlice.content)
			const denominator=schema.nodes.friendlyFractionDenominator.create()
			const node=type.create(null,[numerator,denominator])
			let tr = state.tr
			tr.replaceWith(end-1,end,schema.text('\u00a0')) //add blank space after fraction.  Don't add if in algebra mode
			tr.replaceWith(start,end-1,node)

			const startPositionParentOffset=tr.doc.resolve(start).parentOffset

			if(startPositionParentOffset===0){//only add a space if is the begining of text node
				tr.insertText('\u00a0',start)
			}
			//put cursor on top if no numerator, else put on bottom
			let selection
			if(match[1].length===0){ //put cursor at top
				let offset=2
				if(startPositionParentOffset===0){
					offset+=1
				}
				selection = new TextSelection(tr.doc.resolve(start+offset))
				tr.setSelection(selection) 
			}
			else{
				selection = new TextSelection(tr.doc.resolve(start+2+ match[1].length+2))
				tr.setSelection(selection) 
			}
			const UISource="shortcut"
			let numeratorText
			if(match[1].length>0){
				numeratorText=match[1]
			}
   			analyticsInsertFriendlyFraction(UISource,numeratorText)
			return tr
		}
	})
}

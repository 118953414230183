import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import find from 'lodash/find'
import RichStaticSlide from '../../components/richStaticSlide/RichStaticSlide'
import StaticSlideTestPanel from '../../components/StaticSlideTestPanel'


//This is the static slide testing page at /static/setId

 
class RichStaticSlideTestContainer extends Component {

	constructor(props){
		super()
		// this.handleKeyPress=this.handleKeyPress.bind(this)
		// this.sizeChanged=this.sizeChanged.bind(this)
		// this.onSelectImage=this.onSelectImage.bind(this)
		this.changeActiveQuestion=this.changeActiveQuestion.bind(this)
		this.updateWindowDimensions=this.updateWindowDimensions.bind(this)

		this.state=({
			mediaZoomed: false,
			choiceMediaZoomed: false,
			activeZoomedChoiceIndex: 0, //0, 1, 2, 4
			displayType:'carousel',
			activeQuestionIndex:0,
			//activeQuestionIndex:0,
			listSlideWidth: 640,
			windowWidth:window.innerWidth,
			windowHeight:window.innerHeight,
			staticSlideMediaIsPlayable: true,
			staticSlideMediaIsZoomable: false,
			staticSlideShowCorrect: false,
			staticSlideReportView: false,
			staticSlideIsReviewScreen: false,
			staticSlideAlwaysShowLetterBlock: false,
			staticSlideIsAskScreen: false,
			staticSlideIsScanScreen: false,
			staticSlideShowResponseCounts: false,   
			showGraph: false,
			choiceAResponseCount: 5,
			choiceBResponseCount: 25,
			choiceCResponseCount: 3,
			choiceDResponseCount: 8,
			// image:null,
			// searchTerm:props.searchTerm
		})
	}



	componentDidMount() {
		window.addEventListener('resize', this.updateWindowDimensions)    
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions)
	}


	updateWindowDimensions() {  
		this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight})
	}


	changeActiveQuestion(direction){
		const {activeQuestionIndex}=this.state
		let newActiveQuestionIndex
		if(direction==='next'){
			newActiveQuestionIndex=Math.min(activeQuestionIndex+1,this.props.set.questions.length-1)
		}
		else if(direction==='previous'){
			newActiveQuestionIndex=Math.max(activeQuestionIndex-1,0)
		}
		this.setState({activeQuestionIndex:newActiveQuestionIndex})
	}


	render() {
		const {set} = this.props
		const {mediaZoomed, displayType,listSlideWidth, staticSlideMediaIsPlayable, staticSlideMediaIsZoomable, choiceMediaZoomed, activeZoomedChoiceIndex, staticSlideAlwaysShowLetterBlock, staticSlideIsReviewScreen, staticSlideReportView, staticSlideShowCorrect, staticSlideIsAskScreen, staticSlideIsScanScreen, staticSlideShowResponseCounts, staticSlideShowGraph, choiceAResponseCount, choiceBResponseCount, choiceCResponseCount, choiceDResponseCount} = this.state

		// const displayType = 'list'
		// const displayType = 'carousel'

		// const colorScheme = 'dark'
		const colorScheme = 'light'

		const carouselPaddingTop = 30
		const carouselPaddingLeft = 30
		const carouselPaddingRight = 30
		const carouselPaddingBottom = 30

		const testPanelWidth = 300

		// INITIAL CALCS

		// const screenWidth = 1280 // measure
		// const screenHeight = 670  // measure
		const {windowWidth,windowHeight}=this.state




		const slideContainerWidth = windowWidth - testPanelWidth - carouselPaddingLeft - carouselPaddingRight
		const slideContainerHeight = windowHeight - carouselPaddingTop - carouselPaddingBottom

		// MAXIMIZE SLIDE TO FIT IN SLIDE CONTAINER
		const slideContainerAspect = slideContainerWidth / slideContainerHeight
		const slideAspect = 1280 / 800 // always this

		let slideWidth
		let slideHeight // only calculated to be clear

		if(slideAspect >= slideContainerAspect){ // container squarer than slide
			slideWidth = slideContainerWidth
		}

		if(slideAspect < slideContainerAspect){ // container wider than slide
			slideHeight = slideContainerHeight
			slideWidth = slideHeight * slideAspect      
		}

		const slideScale = slideWidth / 1280

		// const slideXToContainer = (slideContainerWidth - slideWidth) / 2
		// const slideYToContainer = (slideContainerHeight - (slideWidth / slideAspect)) / 2    

		const slideXToContainer = 0
		const slideYToContainer = 0

		const slideXToScreen = slideXToContainer + carouselPaddingLeft
		const slideYToScreen = slideYToContainer + carouselPaddingTop

		// MEDIA SCALING     
		// atm only for video in right template
		const mediaPreScaledYToSlide = 60 //from template definitions 
		const mediaYToSlide = mediaPreScaledYToSlide * slideScale

		const mediaContainerWidth = 580 // from slide definitions

		const mediaPreScaledXToSlide = 1280 - mediaContainerWidth - 60

		const mediaXToSlide = mediaPreScaledXToSlide * slideScale

		const mediaXToScreen = mediaXToSlide + slideXToScreen
		const mediaYToScreen = mediaYToSlide + slideYToScreen    

		// Should be in slide dimensions I guess
		const intrinsicVideoWidth = 640
		const intrinsicVideoHeight = 360

		const videoAspect = intrinsicVideoWidth / intrinsicVideoHeight

		const mediaPreScaledWidth = mediaContainerWidth
		const mediaPreScaledHeight = mediaContainerWidth / videoAspect

		const mediaScaledWidth = mediaPreScaledWidth * slideScale
		const mediaScaledHeight = mediaPreScaledHeight * slideScale



		//


		if(set){
			return (
				<div className={'staticSlideTestPage ' + (colorScheme ? `staticSlideTestPage--${colorScheme}`:'')}>

					{displayType === 'list' &&
						<div className='staticSlideTest-slideListContainer'>               
								{set.questions.map((question)=>{
									return(
										<div style={{width: `${listSlideWidth}px`, height: `${listSlideWidth / 1.6}px`}} className='staticSlideTest-slideListContainer-slideContainer' key={question.questionId}>
											<RichStaticSlide
												key={question.questionId}
												question={question}
												slideWidth={listSlideWidth}
												bodyFontSize={60}
												choiceFontSize={30}
												mediaIsPlayable={staticSlideMediaIsPlayable}
												mediaIsZoomable={staticSlideMediaIsZoomable}
											/>
										</div>
									)
									
								})}             
							
						</div>
					}

					{displayType === 'carousel' &&
						<div style={{top:`${carouselPaddingTop}px`, left:`${carouselPaddingLeft}px`, right: `${carouselPaddingLeft + testPanelWidth}px`, bottom: `${carouselPaddingBottom}px`}} className='staticSlideTest-slideCarouselContainer'>            
							{set.questions.map((question,i)=>{
								if(i===this.state.activeQuestionIndex){
									 return(
										<div style={{left:`${slideXToContainer}px`, top:`${slideYToContainer}px`, width: `${slideWidth}px`, height: `${slideWidth / 1.6}px`}} className='staticSlideTest-slideCarouselContainer-slideContainer' key={question.questionId}>
											<RichStaticSlide
												key={`${question.questionId}_${i}`}
												question={{...question, xLayout:null}}
												slideWidth={slideWidth}
												bodyFontSize={60}
												choiceFontSize={30}
												zoomMedia={()=>{this.setState({mediaZoomed: !mediaZoomed})}}
												mediaIsPlayable={staticSlideMediaIsPlayable}
												mediaIsZoomable={staticSlideMediaIsZoomable}
												mediaZoomed={mediaZoomed}
												choiceMediaZoomed={choiceMediaZoomed}
												activeZoomedChoiceIndex={activeZoomedChoiceIndex}
												showCorrect={staticSlideShowCorrect}
												reportView={staticSlideReportView}
												isAskScreen={staticSlideIsAskScreen}
												isScanScreen={staticSlideIsScanScreen}
												isReviewScreen={staticSlideIsReviewScreen}
												alwaysShowLetterBlock={staticSlideAlwaysShowLetterBlock}
												showResponseCounts={staticSlideShowResponseCounts}
												showGraph={staticSlideShowGraph}
												choiceAResponseCount={choiceAResponseCount}
												choiceBResponseCount={choiceBResponseCount}
												choiceCResponseCount={choiceCResponseCount}
												choiceDResponseCount={choiceDResponseCount}
												totalResponseCount={choiceAResponseCount + choiceBResponseCount + choiceCResponseCount + choiceDResponseCount}
											/>
										</div>
									)
								}else return null
								 
									
								})}        
						</div>
					}

					{displayType === 'carousel' && set.questions[this.state.activeQuestionIndex].template !== "bodyCenterChoicesMedia" &&
						<button onClick={()=>{this.setState({mediaZoomed: !mediaZoomed})}} className='staticSlideTest-zoomToggleBtn'>
							{mediaZoomed ? 'Minimize' : 'Zoom'}            
						</button>
					}          

					{displayType === 'carousel' && set.questions[this.state.activeQuestionIndex].template === "bodyCenterChoicesMedia" &&
						<div className='staticSlideTest-zoomToggleBtnContainer'>
							<button onClick={()=>{this.setState({choiceMediaZoomed: !choiceMediaZoomed})}} className='staticSlideTest-zoomToggleChoiceBtn staticSlideTest-zoomToggleChoiceBtn--choicesZoom'>
								{choiceMediaZoomed ? 'Choices Minimize' : 'Choices Zoom'}                                        
							</button>
							<button onClick={()=>{this.setState({activeZoomedChoiceIndex: 0})}} className={'staticSlideTest-zoomToggleChoiceBtn ' + (activeZoomedChoiceIndex === 0 ? 'staticSlideTest-zoomToggleChoiceBtn--active' : 'staticSlideTest-zoomToggleChoiceBtn--inactive')}>
								Active A [0]                                     
							</button>
							<button onClick={()=>{this.setState({activeZoomedChoiceIndex: 1})}} className={'staticSlideTest-zoomToggleChoiceBtn ' + (activeZoomedChoiceIndex === 1 ? 'staticSlideTest-zoomToggleChoiceBtn--active' : 'staticSlideTest-zoomToggleChoiceBtn--inactive')}>
								Active B [1]                                     
							</button>
							<button onClick={()=>{this.setState({activeZoomedChoiceIndex: 2})}} className={'staticSlideTest-zoomToggleChoiceBtn ' + (activeZoomedChoiceIndex === 2 ? 'staticSlideTest-zoomToggleChoiceBtn--active' : 'staticSlideTest-zoomToggleChoiceBtn--inactive')}>
								Active C [2]                                     
							</button>
							<button onClick={()=>{this.setState({activeZoomedChoiceIndex: 3})}} className={'staticSlideTest-zoomToggleChoiceBtn ' + (activeZoomedChoiceIndex === 3 ? 'staticSlideTest-zoomToggleChoiceBtn--active' : 'staticSlideTest-zoomToggleChoiceBtn--inactive')}>
								Active D [3]                                     
							</button>
						</div>
					}        					

					<div style={{width: `${testPanelWidth}px`}} className='staticSlideTest-panelContainer'>
						<StaticSlideTestPanel 
							colorScheme={colorScheme}
							windowHeight={windowHeight}
							windowWidth={windowWidth}
							slideContainerWidth = {slideContainerWidth}
							slideContainerHeight = {slideContainerHeight}
							slideWidth={slideWidth}
							slideXToContainer={slideXToContainer}
							slideYToContainer={slideYToContainer}
							slideXToScreen={slideXToScreen}
							slideYToScreen={slideYToScreen}
							displayType={displayType}
							setDisplayType={(displayType)=>{this.setState({displayType:displayType})}}
							listSlideWidth={listSlideWidth}
							setListSlideWidth={(listSlideWidth)=>{this.setState({listSlideWidth:listSlideWidth})}}
							changeActiveQuestion={(direction)=>{this.changeActiveQuestion(direction)}}
							activeQuestionIndex={this.state.activeQuestionIndex}
							set={this.props.set}
							staticSlideMediaIsPlayable={staticSlideMediaIsPlayable}
							setStaticSlideMediaIsPlayable={(isPlayable)=>{this.setState({staticSlideMediaIsPlayable:isPlayable})}}
							staticSlideMediaIsZoomable={staticSlideMediaIsZoomable}
							setStaticSlideMediaIsZoomable={(isZoomable)=>{this.setState({staticSlideMediaIsZoomable:isZoomable})}}
							setStaticSlideShowCorrect={(showCorrect)=>{this.setState({staticSlideShowCorrect:showCorrect})}}              
							staticSlideShowCorrect={staticSlideShowCorrect}
							setStaticSlideReportView={(isReportView)=>{this.setState({staticSlideReportView:isReportView})}}
							staticSlideReportView={staticSlideReportView}
							setStaticSlideIsReviewScreen={(isReviewScreen)=>{this.setState({staticSlideIsReviewScreen:isReviewScreen})}}
							staticSlideIsReviewScreen={staticSlideIsReviewScreen}
							setStaticSlideAlwaysShowLetterBlock={(alwaysShowLetterBlock)=>{this.setState({staticSlideAlwaysShowLetterBlock:alwaysShowLetterBlock})}}
							staticSlideAlwaysShowLetterBlock={staticSlideAlwaysShowLetterBlock}              
							staticSlideIsAskScreen={staticSlideAlwaysShowLetterBlock}
							setStaticSlideIsAskScreen={(isAskScreen)=>{this.setState({staticSlideIsAskScreen:isAskScreen})}}
							staticSlideIsScanScreen={staticSlideIsScanScreen}
							setStaticSlideIsScanScreen={(isScanScreen)=>{this.setState({staticSlideIsScanScreen:isScanScreen})}}
							staticSlideShowResponseCounts={staticSlideShowResponseCounts}
							setStaticSlideShowResponseCounts={(showResponseCounts)=>{this.setState({staticSlideShowResponseCounts:showResponseCounts})}}
							choiceAResponseCount={choiceAResponseCount}
							setStaticSlideChoiceAResponseCount={(choiceAResponseCount)=>{this.setState({staticSlideChoiceAResponseCount:choiceAResponseCount})}}
							choiceBResponseCount={choiceBResponseCount}
							setStaticSlideChoiceBResponseCount={(choiceBResponseCount)=>{this.setState({staticSlideChoiceBResponseCount:choiceBResponseCount})}}
							choiceCResponseCount={choiceCResponseCount}
							setStaticSlideChoiceCResponseCount={(choiceCResponseCount)=>{this.setState({staticSlideChoiceCResponseCount:choiceCResponseCount})}}              
							choiceDResponseCount={choiceDResponseCount}
							setStaticSlideChoiceDResponseCount={(choiceDResponseCount)=>{this.setState({staticSlideChoiceDResponseCount:choiceDResponseCount})}}
						/>            
					</div>
				
				
				</div>

		)
		}else return null
		
	}
}


 
 
function mapStateToProps(state,ownProps) {
	const set = find(state.sets, {'id':ownProps.match.params.id})
	return {
		set:set,
		}
}

export default withRouter(connect(
	mapStateToProps,
	{ 
		
	}
)(RichStaticSlideTestContainer))


import React from 'react'
import { Field, reduxForm } from 'redux-form'
import Icon from '../../misc/Icons'
import { ensureRecaptcha } from '../recaptchaUtil'

let captcha

/// ////// Validations//////////////

const requiredEmail = (value) => (value ? undefined : 'Please enter an email address')

const validEmailAddress = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9.-]/i.test(value) ?
  "This doesn't look like a valid email address." : undefined)

class SignUpFormInputField extends React.Component {
  constructor() {
    super()
    this.state = {
      showPassword: false,
    }
  }

  render() {
    const {
      input, styleName, autocomplete, placeholder, type, meta: { touched, error },
    } = this.props

    return (
      <div
        className={`signup-form-field${
          styleName ? ` signup-form-field--${styleName} ` : ''
        }${input.value !== '' ? ' signup-form-field--nonEmpty ' : ' signup-form-field--empty'
        }${touched && error ? ' signup-form-field--hasError ' : ''}`}
      >

        {styleName !== 'password' && (
        /* eslint-disable react/jsx-props-no-spreading */
        <input
          className='signup-form-field-input'
          {...input}
          placeholder={placeholder}
          type={type}
          autoComplete={autocomplete}
        />
        /* eslint-enable react/jsx-props-no-spreading */
        )}

        {styleName === 'password' && (
        /* eslint-disable react/jsx-props-no-spreading */
        <React.Fragment>
          <input
            className='signup-form-field-input'
            {...input}
            autoComplete={autocomplete}
            placeholder={placeholder}
            type={this.state.showPassword ? 'text' : 'password'}
          />

          <div
            className='signup-form-field--password-showPassword'
            onClick={() => {
              this.setState((prevState) => ({ showPassword: !prevState.showPassword }))
            }}
          >
            {this.state.showPassword &&
            <span>Hide</span>}
            {!this.state.showPassword &&
            <span>Show</span>}
          </div>
        </React.Fragment>
        /* eslint-enable react/jsx-props-no-spreading */
        )}

        {touched && error && (
        <div className='signup-form-field-errorIcon'>
          <Icon name='alert' />
        </div>
        )}

        {touched && error && (
        <div className='signup-form-field-errorText'>
          {error}
        </div>
        )}

      </div>
    )
  }
}

class ForgotPasswordForm extends React.Component {
  constructor() {
    super()
    this.actuallySubmit = this.actuallySubmit.bind(this)
    this.preSubmit = this.preSubmit.bind(this)
  }

  componentDidMount() {
    const { handleSubmit, onRecaptchaError } = this.props
    ensureRecaptcha(
      () => {
        captcha = window.grecaptcha.render(this.recaptchaDiv, {
          sitekey: process.env.REACT_APP_PLICKERS_RECAPTCHA_SITE_KEY,
          tabIndex: 100,
          size: 'invisible',
          callback: handleSubmit(this.actuallySubmit),
        })
      },
      onRecaptchaError,
    )
  }

  actuallySubmit(formProps) {
    const requestData = {
      ...formProps,
      recaptchaResponse: window.grecaptcha.getResponse(captcha),
    }
    this.props.onSubmit(requestData)
    window.grecaptcha.reset()
  }

  preSubmit() {
    this.props.onPreSubmit()
    if (!window.grecaptcha) {
      return
    }
    window.grecaptcha.execute(captcha)
  }

  render() {
    const { handleSubmit, submitLoading } = this.props

    return (
      <form
        className={`signup-form ${
          this.props.anyTouched ? ' signup-form--anyTouched ' : ''
        }${this.props.valid ? ' signup-form--valid ' : ' signup-form--invalid'}`}
        onSubmit={handleSubmit(this.preSubmit)}
      >

        <Field
          name='email'
          type='email'
          styleName='email'
          component={SignUpFormInputField}
          validate={[requiredEmail, validEmailAddress]}
          autocomplete='email'
          placeholder='Your Email Address'
          autoFocus
        />

        <button className='signup-form-submitBtn' type='submit'>

          {!submitLoading && (
          <React.Fragment>
            Send
          </React.Fragment>
          )}

          {submitLoading && (
          <div className='signup-form-submitBtn-loadingSpinnerContainer'>
            <div className='signup-form-submitBtn-loadingSpinner' />
          </div>
          )}

        </button>

        {/* eslint-disable no-return-assign */}
        <div ref={(div) => this.recaptchaDiv = div} />
        {/* eslint-enable no-return-assign */}
      </form>
    )
  }
}

export default reduxForm({
  form: 'forgotPasswordForm',
})(ForgotPasswordForm)

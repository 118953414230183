import React from 'react'
import Icon from '../../../misc/Icons'
import Gallery from '../../../imageGallery/Gallery'
import BingAttributionWithPrivacyLink from '../../../uiKit/attribution/BingAttributionWithPrivacyLink'
import BingAttribution from '../../../uiKit/attribution/BingAttribution'

class EditorInsertImageModal extends React.Component {
  constructor() {
    super()
    this.onInputChange = this.onInputChange.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.state = ({
      searchInput: '',
      image: null, // selected image
    })
  }

  componentDidMount() {
    if (window.analytics) {
      window.analytics.page('Insert Image Modal')
    }
  }

  onInputChange(e) {
    this.setState({ searchInput: e.target.value })
    if (e.target.value === '') { // remove search results if backspace to clear out search term
      this.props.clearSearch()
    }
  }

  onKeyDown(e) {
    if (e.keyCode === 13) {
      if (this.state.searchInput) {
        this.props.handleNewImageSearch(this.state.searchInput)
      }
    }
    if (e.keyCode === 27) { // escape key
      this.props.closeInsertImageModal()
    }
  }

  clearSearch() {
    this.setState({ searchInput: '' })
    this.props.clearSearch()
    const inputField = document.getElementById('image-search-input-field')
    if (inputField) {
      inputField.focus()
    }
  }

  render() {
    const {
      images,
      searchTerm,
      noResults,
      isSearching,
    } = this.props
    const { searchInput } = this.state
    let searchInputIsEmpty = true
    if (searchInput.length > 0) {
      searchInputIsEmpty = false
    }
    const hasImages = images.length > 0
    let showSearchHint = false // show search hint if input is non empty and different from last search term
    if (!searchInputIsEmpty && searchInput !== searchTerm) {
      showSearchHint = true
    }
    const showClearButton = (searchInput === searchTerm) && (noResults || hasImages) && isSearching === false
    const showCancelButton = searchInputIsEmpty
    return (
      <div className='editor-insertImageModal editor-insertImageModal--image'>
        <div className='editor-insertImageModal-searchBar'>
          <input
            className={`editor-insertImageModal-searchBar-input ${showSearchHint ? ' editor-insertImageModal-searchBar-input--showSearchHint ' : ' editor-insertImageModal-searchBar-input--hideSearchHint '}${isSearching ? ' editor-insertImageModal-searchBar-input--isSearching ' : ''}${showClearButton ? ' editor-insertImageModal-searchBar-input--showClearButton ' : ''}`}
            id='image-search-input-field'
            placeholder='Search Internet for Images or Paste Image'
            value={searchInput}
            autoFocus
            onChange={(e) => { this.onInputChange(e) }}
            onKeyDown={this.onKeyDown}
            onPaste={this.props.handleImageSearchBarPaste}
            autoComplete='off'
          />

          <div className='editor-insertImageModal-searchBar-searchBarUI'>
            <div className='editor-insertImageModal-searchBar-searchBarUI-searchIconContainer'>
              <Icon name='magnifying-glass' />
            </div>
            {showClearButton && (
            <button onClick={this.clearSearch} className='editor-insertImageModal-searchBar-searchBarUI-clearBtn'>
              Clear
            </button>
            )}
            {showCancelButton && (
            <button onClick={this.props.closeInsertImageModal} className='editor-insertImageModal-searchBar-searchBarUI-cancelBtn'>
              Cancel
            </button>
            )}
            {isSearching === false && (
            <button className='editor-insertImageModal-searchBar-searchBarUI-hint' onClick={() => { this.props.handleNewImageSearch(this.state.searchInput) }}>
              <div className='editor-insertImageModal-searchBar-searchBarUI-hint-searchBtn'>
                Search
              </div>
              <div className='editor-insertImageModal-searchBar-searchBarUI-hint-secondaryLabel'>
                Press
              </div>
              <div className='editor-insertImageModal-searchBar-searchBarUI-hint-key'>
                Enter
                {' '}
                <Icon name='enterKey' />
              </div>
              <div className='editor-insertImageModal-searchBar-searchBarUI-hint-secondaryLabel'>
                to Search
              </div>
            </button>
            )}
            {isSearching === true && (
            <div className='editor-insertImageModal-searchBar-searchBarUI-searchingHint'>
              <div className='editor-insertImageModal-searchBar-searchBarUI-searchingHint-spinnerContainer'>
                <div className='editor-insertImageModal-searchBar-searchBarUI-searchingHint-spinner' />
              </div>
            </div>
            )}
          </div>
        </div>

        <div className={`editor-insertImageModal-results editor-insertImageModal-results--image ${hasImages ? 'editor-insertImageModal-results--hasImages' : 'editor-insertImageModal-results--noImages'}${isSearching ? ' editor-insertImageModal-results--isSearching' : ''}`}>
          {noResults === true && (
          <div className='editor-insertImageModal-results--noResults'>
            No results found
          </div>
          )}
          {noResults === false && (
          <div className={`editor-insertImageModal-results-imageGrid${this.state.image ? ' editor-insertImageModal-results-imageGrid--selectedImage' : ''}`}>
            <Gallery
              images={images}
              enableLightbox={false}
              enableImageSelection
              onSelectImage={this.props.onSelectImage}
              onClickThumbnail={this.props.onSelectImage}
            />
          </div>
          )}

          {images.length > 0 && isSearching === false && images.length < parseInt(this.props.searchResultCount, 10) && (
          <div>
            <div className='editor-insertImageModal-results-loadMoreContainer'>
              <button className='editor-insertImageModal-results-loadMoreBtn' onClick={() => { this.props.loadMore() }}>
                Load more results
              </button>
            </div>
          </div>
          )}

          {isSearching && images.length > 0 && (
          <div>
            <div className='editor-insertImageModal-results-loadMoreContainer'>
              <button className='editor-insertImageModal-results-loadMoreBtn editor-insertImageModal-results-loadMoreBtn--loading'>
                Loading
              </button>
            </div>
          </div>
          )}

        </div>

        {!hasImages && (
        <a className={`editor-insertImageModal-copyPasteTooltip${isSearching ? ' editor-insertImageModal-copyPasteTooltip--searching ' : ' editor-insertImageModal-copyPasteTooltip--notSearching '}`} target='_blank' rel='noopener noreferrer' href='https://help.plickers.com/hc/en-us/articles/4405430184219'>
          Learn how to copy and paste images into Plickers
        </a>
        )}

        <div className={`editor-insertImageModal-footer${isSearching ? ' editor-insertImageModal-footer--searching ' : ' editor-insertImageModal-footer--notSearching '}${hasImages ? ' editor-insertImageModal-footer--images' : ' editor-insertImageModal-footer--noImages'}`}>
          <div className='editor-insertImageModal-footer-bingAttributionContainer'>
            <BingAttributionWithPrivacyLink />
          </div>
          <div className='editor-insertImageModal-footer-upload'>
            <input
              id='file'
              type='file'
              value=''
              name='uploadImage'
              accept='.png,.jpg,.jpeg,.bmp,.tif,.tiff,.webp,.gif'
              className='editor-insertImageModal-footer-hiddenUploadInput'
              onChange={(e) => { this.props.handleSelectFile(e) }}
            />
            <label htmlFor='file' className='editor-insertImageModal-footer-uploadBtn'>
              <div className='editor-insertImageModal-footer-uploadBtn-primary'>
                Upload Image from Computer...
              </div>
              <div className='editor-insertImageModal-footer-uploadBtn-secondary'>
                PNG, JPEG, GIF, BMP
              </div>
            </label>
          </div>
        </div>
        <div className={`editor-insertImageModal-searchResult-footer-bingAttributionContainer${hasImages ? '' : '--noImages'}`}>
          <BingAttribution />
        </div>
      </div>
    )
  }
}

export default EditorInsertImageModal

import symbols from '../../staticData/symbols'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import store from '../../store'

function getUnicodeForId(id){
	const state = store.getState()
	const symbolsList=state.symbolsList
	const symbol=find(symbolsList,{id:id})
	let unicode
	if(symbol){
		unicode=symbol.symbol
	}
	return unicode
}

export function getFormattedRecentSymbols(count){
	const state = store.getState()
	const recentSymbols=state.recentSymbols
	//sort by last used at
	const sortedSymbols=sortBy(recentSymbols,'lastUsedAt').reverse()
	let formattedRecentSymbols=[]

	sortedSymbols.forEach((symbol)=>{
		const string=getUnicodeForId(symbol.symbol)
		const recentSymbol=find(symbols,{escapedString:string})
		if(recentSymbol){
			formattedRecentSymbols.push(recentSymbol)
		}
	})
	return formattedRecentSymbols
}
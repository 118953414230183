const WAVEFORM_BAR_COLOR = 'rgb:282636'
const WAVEFORM_BACKGROUND_COLOR= 'transparent'
const WAVEFORM_WIDTH = '700'
const WAVEFORM_HEIGHT = '200'


export function composeSoundWaveformUrl(cloudinaryID,start,end,isClipped) {  
	let cloudinaryDeliveryURL=`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/video/upload/`
	if(isClipped){
		cloudinaryDeliveryURL+=`/so_${start},eo_${end},`
	}
	cloudinaryDeliveryURL+=`h_${WAVEFORM_HEIGHT},w_${WAVEFORM_WIDTH},fl_waveform,co_${WAVEFORM_BAR_COLOR},b_${WAVEFORM_BACKGROUND_COLOR}/`
	cloudinaryDeliveryURL+=`${cloudinaryID}`
	cloudinaryDeliveryURL+=`.png` // for Safari support

	return cloudinaryDeliveryURL
}
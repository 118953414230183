import React from 'react'
import ScorePill from '../../uiKit/ScorePill'
import StaticQuestionSlide from '../../slides/StaticQuestionSlide'
import {processQuestionResponses} from '../../../utils/processQuestionResponses'

class SyncLiveViewPlayerProgressBarItem extends React.Component{

	render(){		
		const {index, isCurrentQuestion, currentSection, classSafeMode, poll,question,playQuestionInSet} = this.props
		let processedQuestionResponses={}
		let totalResponses
		if(poll){
			if(poll.responsesByCard && currentSection && currentSection.students){
				processedQuestionResponses = processQuestionResponses(poll,currentSection)
				totalResponses=poll.unassignedResponseCount + poll.assignedResponseCount
			}
		}
		return(				
			<div 
				onClick={playQuestionInSet}
				key={index}
				className={'syncLiveView-player-main-slideContainer-progressBar-item ' + 
					(isCurrentQuestion ? ' syncLiveView-player-main-slideContainer-progressBar-item--activeQuestion ' : ' syncLiveView-player-main-slideContainer-progressBar-item--notActiveQuestion ') +
					(poll ? ' syncLiveView-player-main-slideContainer-progressBar-item--hasPoll ' : '')
				}
			>
			 <div className='syncLiveView-player-main-slideContainer-progressBar-item-pillContainer'>			
				{poll && !classSafeMode &&
					<ScorePill score={poll.correctPercent} />
				}
				<div className={'syncLiveView-player-main-slideContainer-progressBar-item-pill ' + ((classSafeMode && poll) ? ' syncLiveView-player-main-slideContainer-progressBar-item-pill--classSafePoll':'')}/>			 
			 </div>				 
			 
			 <div className='syncLiveView-player-main-slideContainer-progressBar-item-slideContainer'>
				{!poll &&
					<StaticQuestionSlide 
						question={question}
						stage='ask'
						mode='view'
						displayWidth={256}
						previewCard={true} 
					/>
				}
				{poll &&
					<React.Fragment>				 						 						
						<StaticQuestionSlide            			
							processedResponses={processedQuestionResponses}
							totalResponses={totalResponses}
							question={question}  
							displayWidth={256} 
							reportView={!classSafeMode}
						/>
					</React.Fragment>
				}
			 </div>				
			</div>											
		)
	}
}


class SyncLiveViewPlayerProgressBar extends React.Component{

	render(){		
		const {questionsAndPollsList, currentQuestionIndex,classSafeMode,playQuestionInSet} = this.props
		return(				
			<div className={'syncLiveView-player-main-slideContainer-progressBar ' + (this.props.questionStage ==='scan' ? 'syncLiveView-player-main-slideContainer-progressBar--isScanning' : '')}>				
				{questionsAndPollsList.map((questionAndPoll, index) => {										
					const poll=questionAndPoll.poll		
					const question=questionAndPoll.questionSnapshot  		
					return(
						<SyncLiveViewPlayerProgressBarItem
							key={index}
							index={index}
							isCurrentQuestion={index === currentQuestionIndex}
							poll={poll}
							question={question}
							classSafeMode={classSafeMode}
							currentSection={this.props.currentSection}
							playQuestionInSet={()=>{playQuestionInSet(questionAndPoll)}}							
						/>											
					)}
				)}								
			</div>
		)
	}
}
 
export default SyncLiveViewPlayerProgressBar
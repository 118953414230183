import React from 'react'
import {formatVideoDuration} from '../../../../utils/timestampFormatters/formatVideoDuration'
 
class CanvasVideoControlBar extends React.Component{  
	
	render(){
		const {isPlaying,playerCurrentTime,startTime,endTime,isClipped, overlayMode}=this.props
		let currentTimestamp
		if(isClipped){	
			currentTimestamp = formatVideoDuration(Math.max(playerCurrentTime-startTime,0))
		}else{
			currentTimestamp = formatVideoDuration(playerCurrentTime)
		}
		const durationTimestamp = formatVideoDuration(endTime - startTime)
		
  	return (
	  	<div className='slide-media-choice--video-controlBar'>	  		
  			<div className={'slide-media-choice--video-controlBar-seekBar ' + (overlayMode ? 'slide-media-choice--video-controlBar-seekBar--overlayMode': '')}>
  						
  			</div>
	  			  		
  			<div className={'slide-media-choice--video-controlBar-timestamp ' + (overlayMode ? 'slide-media-choice--video-controlBar-timestamp--overlayMode': '')}>
  				{isPlaying && 	  				
  					<div className='slide-media-choice--video-controlBar-playingTimestamps'>			  				
		  				{currentTimestamp}			  				
	  				</div>
	  			}
	  			{!isPlaying &&
  					<div onClick={this.props.showEditVideoModal} className='slide-media-choice--video-controlBar-initialTitleAndTimestamp'>
  						<div className='slide-media-choice--video-controlBar-initialTitleAndTimestamp-title'>
  							{this.props.videoTitle}
  						</div>
  						<div className='slide-media-choice--video-controlBar-initialTitleAndTimestamp-timestamp'>
  							{durationTimestamp}
  						</div>		  					
	  				</div>
	  			}
  			</div>

	  	</div>
  	)
	}
}

export default CanvasVideoControlBar

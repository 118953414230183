import Pusher from 'pusher-js'
import store from '../../store'
import { analyticsTrackSkipMediaCommand } from '../analytics/analyticsTrackSkipMediaCommand'

export function userPresencePusherSetup(session) { // For media skip commands from mobile
  const { token, user: userId } = session
  // Pusher.logToConsole = true
  const pusher = new Pusher(process.env.REACT_APP_PLICKERS_PUSHER_TOKEN, {
    encrypted: true,
    authEndpoint: `${process.env.REACT_APP_PLICKERS_API_ENDPOINT}/pusher/auth`,
    auth: {
      headers: {
        'x-auth-token': token,
      },
    },
  })
  let userPresenceChannel = pusher.subscribe(`presence-${userId}`)

  userPresenceChannel.bind('pusher:subscription_error', (status) => {
    if (window.analytics) {
      window.analytics.track('pusher:user_presence_subscription_error', {
        status,
        userId,
      })
    }
    if (status === 408 || status === 503) {
      userPresenceChannel = pusher.subscribe(`presence-${userId}`)
    }
  })
  userPresenceChannel.bind('client-playback-command', handleClientPlaybackCommand)
}

function handleClientPlaybackCommand(data) {
  const command = data
  store.dispatch({ type: 'CLIENT_PLAYBACK_COMMAND_RECIEVED', command })
  const state = store.getState()
  analyticsTrackSkipMediaCommand(state.control, command)
}

import React from 'react'
import Icon from '../misc/Icons'

class DropdownSelectItem extends React.Component{	

	handleOnClick(e){
		e.stopPropagation()
		this.props.onSelect()
	}

	render(){	
		const {label, isActive, multiline, multilineHeight, sublabel} = this.props
		
		return(
			<div className={'dropdownMenu-item is--selectItem' + (isActive ? ' is--active ' : ' isNot--active') + (multiline ? ' dropdownMenu-item--multiline' : '') + (multilineHeight ? ` dropdownMenu-item--multilineHeight--${multilineHeight}` : '')} onClick={this.handleOnClick.bind(this)}>
				
				{label}

				{multiline && sublabel &&
					<div className='dropdownMenu-item--multiline-sublabel'>
						{sublabel}
					</div>
				}

				{isActive &&
					<Icon name="check" />
				}
			</div>
		)
	}
				
}

export default DropdownSelectItem

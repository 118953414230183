import React from 'react'

class PageNotFoundLabel extends React.Component{ 
 
	render(){
 		
		return( 

			<div className='pageNotFoundLabel'>
				Page Not Found 
			</div>
		)
	}
}


export default PageNotFoundLabel

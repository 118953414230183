export default function (state = {}, action) {
	switch (action.type) {
		
		case 'PUSHER_PRESENCE_SUBSCRIBE_SUCCESS':				
			const sectionId=action.sectionId
			const members=action.members
			return {
				...state,
				[`${sectionId}`]:members 
			}

		case 'PUSHER_PRESENCE_REMOVED':				
			let sectionMembers=state[`${action.sectionId}`]
			delete sectionMembers[action.sessionId]
			return {...state,
				[`${action.sessionId}`]:sectionMembers 
			}

		case 'PUSHER_PRESENCE_ADDED':				
			let sectionPresenceMembers=state[`${action.sectionId}`]
			sectionPresenceMembers[action.sessionId]=null
			return {...state,
				[`${action.sectionId}`]:sectionPresenceMembers 
			}

		case 'LOGOUT':
			return {}

		default:
			return state
	}
}

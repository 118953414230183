import React, { Component} from 'react'
import { connect } from 'react-redux'
import { newStudentBulk } from '../../actions/students'
import { showNotification } from '../../actions/notifications'
import sortBy from 'lodash/sortBy'
import each from 'lodash/each'
import filter from 'lodash/filter'
import {parseName} from '../../utils/parseName'
import Button from '../../components/uiKit/Button'

//Modal to bulk add students to section
 
class StudentRow extends React.Component{
	render(){
		const {isTitleRow,firstName,lastName } = this.props
		return(
			<div className={'bulkStudents--add-previewStudent' + (isTitleRow ? ' is--titleRow' : '')}>
				<div className='bulkStudents--add-previewStudent-firstName notranslate'>
					{firstName}
				</div>
				<div className='bulkStudents--add-previewStudent-lastName notranslate'>
					{lastName}
				</div>
			</div>
		)
	}
}

class ReviewCardsStudentCard extends React.Component{
	render(){
		const { cardNumber,firstName,lastName } = this.props
		return(
			<div className='bulkStudents--reviewCards-StudentCard'>
				<div className='bulkStudents--reviewCards-StudentCard-cardNumber notranslate'>
					{cardNumber}
				</div>
				<div className='bulkStudents--reviewCards-StudentCard-firstName notranslate'>
					{firstName}
				</div>
				<div className='bulkStudents--reviewCards-StudentCard-lastName notranslate'>
					{lastName}
				</div>
			</div>
		)
	}
}




class BulkAddStudentsContainer extends Component {

	constructor(){
		super()
		this.handleSaveButton=this.handleSaveButton.bind(this)
		this.handleNewStudent=this.handleNewStudent.bind(this)
		this.textAreaOnChange=this.textAreaOnChange.bind(this)
		this.assaignCardsToStudents=this.assaignCardsToStudents.bind(this)
		this.sortStudents=this.sortStudents.bind(this)


		this.state = {
			addStudentView:true,
			studentsList:[],
			rawStudentsList:'',
			sortedStudentList:[],
			sortType:'none',
			studentsWithCards:[],
			maxNumStudentsExceeded:false,
			submitLoading:false
		}
	}

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Bulk Add Students Modal')
		}
	}

	textAreaOnChange(e){
		const rawStudentsList=e.target.value
		let studentsArray = this.parseAll(rawStudentsList)
		let formattedStudents=[]
		let maxNumStudentsExceeded=false

		each(studentsArray, function(student,index) {
			if (student !== '') {
				const formattedName=parseName(student)
				formattedStudents.push({enteredName:student,firstName:formattedName.firstName,lastName:formattedName.lastName})
			}
		})
		if(formattedStudents.length >this.props.availableCards.length){
			maxNumStudentsExceeded=true
		}
		this.setState({studentsList:formattedStudents,rawStudentsList:rawStudentsList,maxNumStudentsExceeded:maxNumStudentsExceeded})
		this.assaignCardsToStudents(formattedStudents)
	}


	assaignCardsToStudents(studentList,sortType){

		sortType=sortType || this.state.sortType 
		let sortedStudentList
		if(sortType==='firstName'){
			sortedStudentList=sortBy(studentList, [function(student) {
				return student.firstName.toString().toLowerCase()
			}])
		}else
			if(sortType==='lastName'){
				sortedStudentList=sortBy(studentList, [function(student) {
					return student.lastName.toString().toLowerCase()
				}])
			}
		else{
				sortedStudentList=studentList
			}

		let studentsWithCards=[]

		each(sortedStudentList, function(student,index) {
			studentsWithCards.push({card:this.props.availableCards[index],enteredName:student.enteredName,firstName:student.firstName,lastName:student.lastName})
		}.bind(this))
		this.setState({studentsWithCards:studentsWithCards})
	}



	sortStudents(sortType){
		if(sortType==='firstName'){
			this.setState({sortType:'firstName'})
			this.assaignCardsToStudents(this.state.studentsList,'firstName')
		}else if(sortType==='lastName'){
			this.setState({sortType:'lastName'})
			this.assaignCardsToStudents(this.state.studentsList,'lastName')
		}else if(sortType==='none'){
			this.setState({sortType:'none'})
			this.assaignCardsToStudents(this.state.studentsList,'none')		
		}
	}

	handleNewStudent(student){  
		const nowDate= new Date()
		const requestData={
			...student,
			section:this.props.section.id,
			clientModified:nowDate,
			userCreated:nowDate,
			archived:false,
		}

		return this.props.newStudentBulk(requestData)
			.then((response)=>{
				return response
			})
	}

	handleSaveButton(){
		this.setState({submitLoading:true})
		const studentsArray = this.state.studentsWithCards
		if (studentsArray.length === 0) {
			this.props.hideModal()
			return
		}

		var promises = []
		each(studentsArray, function(student,index) {
			if (student !== '') {
				promises.push(this.handleNewStudent(student))
			}
		}.bind(this))

		Promise.all(promises).then(function (students) {
			this.props.hideModal()
			this.setState({submitLoading:false})
			if(studentsArray.length === 1){
				this.props.showNotification('one student to the class','Added','create')	
			}
			else{
				this.props.showNotification(`${studentsArray.length} students to the class`,'Added','create')		
			}			
			
		}.bind(this))
	}

	parseAll (studentsList) {
		if (studentsList) {
			return filter(studentsList.split('\n'), function(student) {
				return student.trim() !== ''
			})
		} else {
			return []
		}
	}

	render() {
 		const {addStudentView,studentsList,rawStudentsList,studentsWithCards,maxNumStudentsExceeded} = this.state
		return (
			<div className={'bulkStudents' + (rawStudentsList === '' ? ' bulkStudents--empty' : '')}>
				
				{addStudentView &&
				<div className='bulkStudents--add'>
					<div className='bulkStudents-main'>
						<div className='bulkStudents--add-left'>							
							<div className='bulkStudents--add-title'>
								Enter Names
							</div>
							<div className='bulkStudents--add-explainText'>
								Type in student names into the box below. You can also copy-paste rows and columns from a spreadsheet. Make sure each student is on a new line.
							</div>            
							<textarea 
								value={rawStudentsList}
								onChange={this.textAreaOnChange}
								placeholder='First Name Last Name                                                                   Last Name, First Name' ref='studentsText'/>                      
						</div>
						<div className='bulkStudents--add-right'>

							<div className='bulkStudents--add-title'>
									Student Roster Preview
							</div>

							<StudentRow firstName={'First Name'} lastName={'Last Name'} isTitleRow={true}/>
							
							<div className='bulkStudents--add-previewBox'>

								{studentsList.map((student, index) => { 
									return(
										 <StudentRow
										 key={index}
										 firstName={student.firstName}
										 lastName={student.lastName}
										 isTitleRow={false} 
										/>            
								 )})}

							</div>

						</div>
					</div>
					<div className='bulkStudents-bottom'>
						<Button 
							disabled={maxNumStudentsExceeded} 
							label='Next'
							size='large'
							onClickFunction={() =>{this.setState({addStudentView:false})}}
							color={studentsWithCards.length === 0 || maxNumStudentsExceeded ? 'gray' : 'blue'}
							canSubmit={studentsWithCards.length === 0  || maxNumStudentsExceeded ? false : true} 
						/>

						{maxNumStudentsExceeded &&
							<div className='bulkStudents-bottom-error'>
								You can only add up to a maximum of 63 students to each class.
							</div>
						}

					</div>
				</div>
				}

				{!addStudentView &&

					<div className='bulkStudents--reviewCards'>
						<div className='bulkStudents-main'>
							<div className='bulkStudents--reviewCards-top'>
								<Button label='As Entered' color={this.state.sortType==='none' ? 'blue' : 'white'} onClickFunction={()=>{this.sortStudents('none')}}/>
								<Button label='Sort by First Name' color={this.state.sortType==='firstName' ? 'blue' : 'white'} onClickFunction={()=>{this.sortStudents('firstName')}}/>
								<Button label='Sort by Last Name' color={this.state.sortType==='lastName' ? 'blue' : 'white'} onClickFunction={()=>{this.sortStudents('lastName')}}/>
							</div>

							<div className='bulkStudents--reviewCards-middle'>
								{studentsWithCards.map((student, index) => { 
									return(
										<ReviewCardsStudentCard key={index} cardNumber={student.card} firstName={student.firstName} lastName={student.lastName}/>
									)
								})}
							</div>
						</div>
						<div className='bulkStudents-bottom'>
							<Button label='Back' onClickFunction={() =>{this.setState({addStudentView:true})}} />
							<Button 
								submitLoading={this.state.submitLoading}
								label='Done'
								size='large'
								color='blue'
								canSubmit={!this.state.submitLoading ? true : false}
								onClickFunction={() =>{this.handleSaveButton()}} 
							/>
						</div>
					</div>
				}
			</div>
		)
	}
}

export default connect(
	(state,ownProps) => ({}),
	{newStudentBulk,showNotification}
)(BulkAddStudentsContainer)
import React from 'react'
import { withRouter} from 'react-router-dom'
import { connectAutoComplete } from 'react-instantsearch-dom'
import Autosuggest from 'react-autosuggest'
import QuestionCountBadge from '../uiKit/QuestionCountBadge'
import Icon from '../misc/Icons'
import qs from 'qs'
import AlgoliaSearchWrapperContainer from '../../containers/search/AlgoliaSearchWrapperContainer'
import {Configure,Index} from 'react-instantsearch-dom'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'
import {getRepoforId} from '../../utils/getRepoforId'
import {isCurrentUser} from '../../utils/isCurrentUser'
import { setLastLocation } from '../routing/LastLocationProvider'
import debounce from 'lodash/debounce'
import {getLinkForRepo} from '../../utils/getLinkForRepo'

 
const MAX_SUGGESTIONS=4

								
const AutoComplete = connectAutoComplete(
	({ hits, refine,currentRefinement,renderSuggestion,getSuggestionValue,renderInputComponent,inputProps,renderSuggestionsContainer,onSuggestionSelected,value,onSuggestionHighlighted }) => (
		 <Autosuggest
			suggestions={hits.concat({hits:[{'showMore':currentRefinement}],index:''})}
			multiSection={true}
			onSuggestionsFetchRequested={({ value }) => refine(value)}
			onSuggestionsClearRequested={() => refine('')}
			getSuggestionValue={getSuggestionValue}
			renderInputComponent={renderInputComponent}
			onSuggestionSelected={onSuggestionSelected}
			renderSuggestionsContainer={renderSuggestionsContainer}
			renderSuggestion={renderSuggestion}
			inputProps={inputProps}
			renderSectionTitle={section => section.index}
			getSectionSuggestions={section => section.hits}
			focusInputOnSuggestionClick={false}
			onSuggestionHighlighted={onSuggestionHighlighted}			
		/>
	)
)

function renderSuggestionsContainer({ containerProps , children, query }) {
	if(children){
		return (
			<div {... containerProps} className='searchDropdown'>
				{children}
			</div>
		)
	}
}

const renderSuggestion = suggestion => {
	let inSharedRepo = false	
	let inPublicConsumerRepo = false
	let inPublicAuthorRepo = false
	if(suggestion.repo){
		const containingRepo = getRepoforId(suggestion.repo)
		if(containingRepo){
			inSharedRepo=containingRepo.shared 
			inPublicConsumerRepo=containingRepo.role==='consumer'
			inPublicAuthorRepo=containingRepo.role==='author'
		}
	}
	if(suggestion.createdBy){
		if(suggestion.published){
			if(isCurrentUser(suggestion.author)){
				inPublicAuthorRepo=true
			}else{
				inPublicConsumerRepo=true
			}
		}
	}

	return(
		<div className={'searchDropdownItem' + (suggestion.createdBy ? ' searchDropdownItem--repo ' : '') + (suggestion.showMore ? ' is--showMore' : '')}>			

			{suggestion.createdBy && //repo
				<React.Fragment>					
					<div className='searchDropdownItem-title searchDropdownItem-title--repo'> 
						{suggestion.name} {}
					</div>
					{suggestion.shared && <Icon name='shared' /> }
					{inPublicConsumerRepo && <Icon name='globe-shared'/>} 
					{inPublicAuthorRepo && <Icon name='globe-shared-author'/>}

				</React.Fragment>
			}


			{suggestion.ancestors && //folder
				<React.Fragment>
					<Icon name={!suggestion.readOnly ? 'folder' : 'folder-isbe'}/>
					<div className='searchDropdownItem-title searchDropdownItem-title--folder'>
						{suggestion.name}

						{inSharedRepo && <Icon name='shared'/>}
						{inPublicConsumerRepo && <Icon name='globe-shared'/>}

					</div>

				</React.Fragment>
			}

			{suggestion.questions && //set
				<React.Fragment>
					<QuestionCountBadge color='blueLight' questionCount={suggestion.questions.length} />
					<div className='searchDropdownItem-title searchDropdownItem-title--set'>      
						{suggestion.name}
												
						{inSharedRepo && <Icon name='shared'/>}
						{inPublicConsumerRepo && <Icon name='globe-shared'/>}
						
					</div>
				</React.Fragment>
			}

			{suggestion.choices && //question
				<div className='searchDropdownItem-title searchDropdownItem-title--question'>
					{getQuestionBodyLabel(suggestion)}

					{inSharedRepo && <Icon name='shared'/>}
					{inPublicConsumerRepo && <Icon name='globe-shared'/>}
					 
				</div>
			}
			

			{suggestion.showMore &&
				<div className='searchDropdownItem-title'>
					See all results for {suggestion.showMore}
				</div>          
			}

		</div>
	)}

const renderInputComponent = inputProps => (
	<input {...inputProps} className='topNav-search' id='topNavSearchBar' />
)



class AlgoliaSearchBar extends React.Component {
 
	constructor() {
		super()
		this.onSuggestionsClearRequested=this.onSuggestionsClearRequested.bind(this)
		this.onSuggestionSelected=this.onSuggestionSelected.bind(this)
		this.onSuggestionHighlighted=this.onSuggestionHighlighted.bind(this)
		this.onBlur=this.onBlur.bind(this)
		this.onChange=this.onChange.bind(this)
		this.onKeyDown=this.onKeyDown.bind(this)
		this.getSuggestionValue=this.getSuggestionValue.bind(this)
		this.onSuggestionHighlighted = debounce(this.onSuggestionHighlighted,100)
		this.state = {
			value: '',
			highlightedSuggestion:null,
		}
	}

	onKeyDown(e){
		if(!this.state.highlightedSuggestion){
			if(e.keyCode === 13){
				this.props.history.push(`/searchresults/?${qs.stringify({query:this.state.value})}`)
				this.setState({value:''})
			}
		}
	}

	getSuggestionValue(){
		return this.state.value
	}

	onChange(event, { newValue, method }){
		this.setState({
			value: newValue
		})
	}

	onBlur(){
		this.setState({value:''})
	}

	// Autosuggest will call this function every time you need to clear suggestions.
	onSuggestionsClearRequested (){
		this.setState({})
	}

	onSuggestionHighlighted({suggestion}){
		if(!(suggestion && suggestion.showMore) && suggestion!==this.state.suggestion){
			this.setState({highlightedSuggestion:suggestion})
		}
	}

	onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
		this.setState({value: ''})
		let itemDetailPath
		const itemId=suggestion.id||suggestion._id
		
		if(suggestion.createdBy){
			itemDetailPath=getLinkForRepo(suggestion)
		}else if(suggestion.questions){
			itemDetailPath=`/set/${itemId}`
		}else if(suggestion.showMore){
			itemDetailPath=`/searchresults/?${qs.stringify({query:suggestion.showMore})}`
		}else if(suggestion.ancestors){
			itemDetailPath=`/library/${itemId}`
			if(suggestion.repo){
				const parentRepo = getRepoforId(suggestion.repo)
				itemDetailPath=`${getLinkForRepo(parentRepo)}/${itemId}`
			}
			
		}else {
			itemDetailPath=`/question/${itemId}`
		}
		setLastLocation('search') //to prevent back button linking to the wrong place
		this.props.history.push(`${itemDetailPath}`)
	}

	render() {
		const { value } = this.state
		const inputProps = {
			placeholder: 'Search',
			value,
			onChange: this.onChange,
			onBlur:this.onBlur,
			onKeyDown:this.onKeyDown
		}

		return (
			<AlgoliaSearchWrapperContainer isTopNav={true}>
				<Configure hitsPerPage={MAX_SUGGESTIONS}/>
				<AutoComplete 
					renderSuggestion={renderSuggestion}
					getSuggestionValue={this.getSuggestionValue}
					renderInputComponent={renderInputComponent}
					inputProps={inputProps}
					renderSuggestionsContainer={renderSuggestionsContainer}
					onSuggestionSelected={this.onSuggestionSelected}
					value={this.state.value}
					onSuggestionHighlighted={this.onSuggestionHighlighted}
				/>
				<Index indexName='folders'/>			
				<Index indexName='sets_questions'/>
			</AlgoliaSearchWrapperContainer>
		)
	}
}



export default withRouter(AlgoliaSearchBar)


import React from 'react'

class StaticSlideChoiceImage extends React.Component{

	constructor(props){
		super(props)
		this.onLoad=this.onLoad.bind(this)
	}

	onLoad(){
		if(this.props.choiceMediaPromiseResolve){ //for slide service
			console.log(`choice ${this.props.choiceLetter} image onLoad`)
			this.props.choiceMediaPromiseResolve()
		}
	}

	render(){	
		const {image} = this.props
		return(			
			<React.Fragment>					
				<img
					onLoad={this.onLoad}
					src={image.deliveryUrl}
					className={'slide-choice-media--image-image ' + (image.imageFill ? ' slide-choice-media--image-image--fill' : 'slide-choice-media--image-image--fit')}
					alt=''
				/>										
			</React.Fragment>			
		)		
	}	
}

export default StaticSlideChoiceImage
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ModalWrapper from '../ModalWrapper'
import Button from '../../uiKit/Button'
import VerifyPasswordToSigninForm from '../../forms/VerifyPasswordToSigninForm'
import { externalLogin } from '../../../actions/session'
import getErrorMessageForRequest from '../../../utils/getErrorMessageForRequest'

const VerifyPasswordToSigninModal = (props) => {
  const {
    hideModal, email, requestData, history, externalLogin,
  } = props
  const signinMethodString = requestData.type === 'apple' ?
    'Sign in with Apple' :
    'Sign in with Google'
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  async function signin(password) {
    try {
      setSubmitLoading(true)
      setErrorMessage('')
      await externalLogin({ ...requestData, password }, true)
      hideModal()
    } catch (e) {
      setSubmitLoading(false)
      const errorMessage = getErrorMessageForRequest({
        requestName: 'externalLogin',
        response: e.response,
      })
      setErrorMessage(errorMessage)
    }
  }

  function goToForgotPassword() {
    hideModal()
    history.push('forgot-password')
  }

  return (
    <div className='verifyPasswordToSignin-modalContainer marketing'>
      <ModalWrapper
        hideModal={hideModal}
        closeBtn={false}
        cancelBtn={false}
        showModalHeader={false}
        modalStyle='dark'
      >
        <div className='verifyPasswordToSignin-modalContent'>
          <div className='verifyPasswordToSignin-modalContent-title'>
            Welcome back!
          </div>
          <div className='verifyPasswordToSignin-modalContent-description'>
            <div>
              {`Your account (${email}) requires a password to sign in.`}
            </div>
          </div>
          <div className='verifyPasswordToSignin-modalContent-main'>
            <VerifyPasswordToSigninForm
              email={email}
              signinMethodString={signinMethodString}
              submitLoading={submitLoading}
              onSubmit={(password) => signin(password)}
              goToForgotPassword={() => goToForgotPassword()}
            />
            {errorMessage && (
              <div className='verifyPasswordToSignin-modalContent-main-error'>
                {errorMessage}
              </div>
            )}
            <Button
              size='large'
              color='gray'
              label='Cancel'
              onClickFunction={() => hideModal()}
            />
          </div>
        </div>
      </ModalWrapper>
    </div>
  )
}

export default withRouter(connect(
  (/* state */) => ({}),
  { externalLogin },
)(VerifyPasswordToSigninModal))

import React from 'react'
import Measure from 'react-measure'

// PM
import {
  EditorState, TextSelection, AllSelection, Selection,
} from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { DOMSerializer } from 'prosemirror-model'
import { undo, redo, history } from 'prosemirror-history'
import { toggleMark, baseKeymap } from 'prosemirror-commands'
import { keymap } from 'prosemirror-keymap'
// import applyDevTools from "prosemirror-dev-tools"
import { convertSetToPMNode, convertIndependentQuestionToPMNode } from '../../prosemirror/utils/convertSetToPMNode'
import { stopAllMediaPlayback } from '../../prosemirror/utils/editorActions/stopAllMediaPlayback'

// Schema
import editorSchema from '../../prosemirror/schema/editorSchema'

// Plugins
import { formatBarPlugin } from '../../prosemirror/plugins/formatBar/formatBarPlugin'
import { focusPlugin } from '../../prosemirror/plugins/focusPlugin'
import { selectionPlugin } from '../../prosemirror/plugins/selectionPlugin'
import { mathSelectionPlugin } from '../../prosemirror/plugins/mathSelectionPlugin'
import { placeholderPlugin } from '../../prosemirror/plugins/placeholderPlugin'
import { focusedInputPlugin } from '../../prosemirror/plugins/focusedInputPlugin'
import { symbolsSuggestionPlugin } from '../../prosemirror/plugins/suggestions/symbolsSuggestionPlugin'
import inputRulesPlugin from '../../prosemirror/plugins/inputRules/inputRulesPlugin'
import fractionPlugin from '../../prosemirror/plugins/fractionPlugin'
import { updateSlideWidth } from '../../prosemirror/utils/updateSlideWidth'
import { selectAllTextInActiveNode } from '../../prosemirror/utils/selectAllTextInActiveNode'
import { characterLimitPlugin } from '../../prosemirror/plugins/characterLimitPlugin'
import { deleteEmptyFriendlyFractionPlugin } from '../../prosemirror/plugins/deleteEmptyFriendlyFractionPlugin'

// Editor UI
import EditorTopBar from './editorUI/EditorTopBar'
import EditorCanvasInsertBar from './editorUI/EditorCanvasInsertBar'
import EditorCanvasEditMediaBar from './editorUI/EditorCanvasEditMediaBar'
import EditorCanvasBottomBar from './editorUI/EditorCanvasBottomBar'
import EditorSlideList from './editorUI/slideList/EditorSlideList'
import EditorBottomRightPanel from './editorUI/EditorBottomRightPanel'
import PrintSetModal from './richSetPrinting/modal/PrintSetModal'
import RichStaticSlide from '../richStaticSlide/RichStaticSlide'

// Node views
import QuestionNodeView from './nodeViews/QuestionNodeView'
import QuestionBodyNodeView from './nodeViews/QuestionBodyNodeView'
import QuestionVideoNodeView from './nodeViews/video/QuestionVideoNodeView'
import QuestionImageNodeView from './nodeViews/image/QuestionImageNodeView'
import QuestionSoundNodeView from './nodeViews/sound/QuestionSoundNodeView'
import QuestionAnimatedGIFNodeView from './nodeViews/gif/QuestionAnimatedGIFNodeView'
import ChoiceGroupNodeView from './nodeViews/ChoiceGroupNodeView'
import ChoiceNodeView from './nodeViews/ChoiceNodeView'
import ChoiceMediaEmptyStateNodeView from './nodeViews/ChoiceMediaEmptyStateNodeView'
import ChoiceImageNodeView from './nodeViews/image/ChoiceImageNodeView'
import ChoiceVideoNodeView from './nodeViews/video/ChoiceVideoNodeView'
import ChoiceSoundNodeView from './nodeViews/sound/ChoiceSoundNodeView'
import ChoiceAnimatedGIFNodeView from './nodeViews/gif/ChoiceAnimatedGIFNodeView'
import KatexMathNodeView from './nodeViews/KatexMathNodeView'
import FriendlyFractionNodeView from './nodeViews/friendlyFractions/FriendlyFractionNodeView'
import FriendlyFractionNumeratorNodeView from './nodeViews/friendlyFractions/FriendlyFractionNumeratorNodeView'
import FriendlyFractionDenominatorNodeView from './nodeViews/friendlyFractions/FriendlyFractionDenominatorNodeView'

const UNTOUCHED_CHOICE_BODY = '\u2063' // Invisible unicode character for detecting if choice has been touched
const MAX_FILE_SIZE = 20000000

function getAnchorNode($from) {
  let anchorNode
  for (let i = $from.depth; i > 0; i--) {
    const node = $from.node(i)
    if (node.type.name === 'questionBody' || node.type.name === 'choiceBody') {
      anchorNode = node
      break
    }
  }
  return anchorNode
}

function arrowHandler(dir) { // https://prosemirror.net/examples/codemirror/
  return (state, dispatch, view) => {
    if (state.selection.empty && view.endOfTextblock(dir)) {
      const side = dir === 'left' || dir === 'up' ? -1 : 1; const
        { $head } = state.selection
      const nextPos = Selection.near(state.doc.resolve(side > 0 ? $head.after() : $head.before()), side)
      const currentAnchorNode = getAnchorNode($head)
      const nextAnchorNode = getAnchorNode(nextPos.$head)
      if (currentAnchorNode && nextAnchorNode && currentAnchorNode !== nextAnchorNode) { // prevent arrowing accross nodes
        return true
      }
    }
    return false
  }
}

const arrowHandlers = keymap({
  ArrowLeft: arrowHandler('left'),
  ArrowRight: arrowHandler('right'),
})

function handleDragAndDropEvent(e) { // Disable node drag and drop
  e.preventDefault()
  return true
}

class RichEditorPage extends React.Component {
  constructor(props) {
    super(props)
    this.sizeChanged = this.sizeChanged.bind(this)
    this.handlePaste = this.handlePaste.bind(this)
    this.transformPastedHTML = this.transformPastedHTML.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.loadEditor = this.loadEditor.bind(this)
    this.showPrintModal = this.showPrintModal.bind(this)
    this.state = {
      showPrintModal: false,
      slideWidth: '',
    }
  }

  componentDidMount() {
    this.loadEditor()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemIsLockedByAnotherUser && !this.props.itemIsLockedByAnotherUser) {
      this.loadEditor() // if locked state changes need to reload the editor
    }
  }

  showPrintModal() {
    stopAllMediaPlayback()
    this.setState({ showPrintModal: true })
  }

  loadEditor() {
    const base = DOMSerializer.fromSchema(editorSchema)
    let doc
    if (this.props.isSetEditor) { // use saved Set object to create doc
      const questionOrderArray = []
      this.props.currentSet.questions.forEach((question) => {
        questionOrderArray.push(question.questionId)
      })
      doc = convertSetToPMNode(this.props.currentSet, questionOrderArray)
    } else { // independnt question
      doc = convertIndependentQuestionToPMNode(this.props.currentQuestion)
    }
    const clipboardSerializer = new DOMSerializer(({
      ...base.nodes, // for copy/paste math nodes https://prosemirror.net/docs/ref/#model.DOMSerializer
      math(node) {
        const { latex, isAdvancedInput } = node.attrs
        const domAttrs = {}
        if (latex) {
          domAttrs['data-latex'] = latex
        }
        domAttrs['data-isadvancedinput'] = isAdvancedInput
        return ['math', domAttrs]
      },
    }), base.marks)

    window.view = new EditorView(document.querySelector('#editor'), {
      state: EditorState.create({
        doc,
        plugins: [
          formatBarPlugin(this.props.setActiveQuestion, this.props.showNotification, this.props.onAddQuestionToSet, this.props.onDeleteActiveQuestion, this.props.questionsInSetLimit, this.props.isSetEditor),
          focusPlugin(),
          placeholderPlugin(),
          focusedInputPlugin(),
          inputRulesPlugin,
          symbolsSuggestionPlugin(),
          fractionPlugin, // has fraction input rule, triggered by tab key
          characterLimitPlugin(),
          mathSelectionPlugin(),
          deleteEmptyFriendlyFractionPlugin(),
          history(),
          keymap(baseKeymap),
          keymap({
            'Mod-z': undo,
            'Mod-y': redo,
            'Mod-b': toggleMark(editorSchema.marks.strong),
            'Mod-i': toggleMark(editorSchema.marks.em),
            'Mod-u': toggleMark(editorSchema.marks.underline),
            'Mod-.': toggleMark(editorSchema.marks.superscript),
            'Mod-,': toggleMark(editorSchema.marks.subscript),
            'Shift-Mod-h': toggleMark(editorSchema.marks.highlight4),
          }),
          selectionPlugin(),
          arrowHandlers,
        ],
      }),
      handleDOMEvents: { // we disable all of these events
        drop(view, event) { handleDragAndDropEvent(event) },
        dragstart(view, event) { handleDragAndDropEvent(event) },
        dragend(view, event) { handleDragAndDropEvent(event) },
        dragover(view, event) { handleDragAndDropEvent(event) },
      },
      handleKeyDown: this.handleKeyDown,
      clipboardSerializer,
      handlePaste: this.handlePaste,
      transformPastedHTML: this.transformPastedHTML, // need this to remove wrapping html to prevent pasting questionbody div when copy/paste question body
      attributes: { class: 'editor-canvas-slide' },
      dispatchTransaction: (transaction) => {
        if (transaction.curSelection instanceof AllSelection) {
          selectAllTextInActiveNode()
        } else {
          const { state, transactions } = window.view.state.applyTransaction(transaction)
          window.view.updateState(state)
          if (transaction.meta.history$) {
            this.props.notThrottledDocUpdate(state.doc)
          } else if (transactions.some((tr) => (tr.docChanged && tr.meta.triggerParseDoc !== true && tr.meta.addToHistory === false))) { // add to history false to prevent unnecessary saves e.g.changing slide width should not trigger save
            this.props.onDocUpdate(state.doc, false)
          } else if (transactions.some((tr) => (tr.docChanged && tr.meta.triggerParseDoc !== true))) { // add to history false to prevent unnecessary saves
            this.props.onDocUpdate(state.doc)
          } else if (transactions.some((tr) => (tr.docChanged && tr.meta.triggerParseDoc === true))) { // add to history false to prevent unnecessary saves
            this.props.notThrottledDocUpdate(state.doc)
          }
          this.forceUpdate()
        }
      },
      nodeViews: { // custom node views
        question(node, view, getPos) { return new QuestionNodeView(node, view, getPos) },
        questionBody(node, view, getPos) { return new QuestionBodyNodeView(node, view, getPos) },
        questionImage(node, view, getPos) { return new QuestionImageNodeView(node, view, getPos) },
        questionVideo(node, view, getPos) { return new QuestionVideoNodeView(node, view, getPos) },
        questionSound(node, view, getPos) { return new QuestionSoundNodeView(node, view, getPos) },
        questionAnimatedGIF(node, view, getPos) { return new QuestionAnimatedGIFNodeView(node, view, getPos) },
        choiceAnimatedGIF(node, view, getPos) { return new ChoiceAnimatedGIFNodeView(node, view, getPos) },
        choiceGroup(node, view, getPos) { return new ChoiceGroupNodeView(node, view, getPos) },
        choice(node, view, getPos) { return new ChoiceNodeView(node, view, getPos) },
        choiceMediaEmptyState(node, view, getPos) { return new ChoiceMediaEmptyStateNodeView(node, view, getPos) },
        choiceImage(node, view, getPos) { return new ChoiceImageNodeView(node, view, getPos) },
        choiceVideo(node, view, getPos) { return new ChoiceVideoNodeView(node, view, getPos) },
        choiceSound(node, view, getPos) { return new ChoiceSoundNodeView(node, view, getPos) },
        math(node, view, getPos) { return new KatexMathNodeView(node, view, getPos) },
        friendlyFraction(node, view, getPos) { return new FriendlyFractionNodeView(node, view, getPos) },
        friendlyFractionNumerator(node, view, getPos) { return new FriendlyFractionNumeratorNodeView(node, view, getPos) },
        friendlyFractionDenominator(node, view, getPos) { return new FriendlyFractionDenominatorNodeView(node, view, getPos) },
      },
    })
    if (this.props.isSetEditor) {
      if (this.props.currentSet.id) {
        this.props.focusActiveQuestionInSlideList()
      } else {
        this.setInitialCursorPositionOnLoad()
      }
    }
    setTimeout(() => {
      updateSlideWidth(this.state.slideWidth)
    }, 50)
    // Uncomment to use ProseMirror DevTools https://github.com/d4rkr00t/prosemirror-dev-tools
    // applyDevTools(window.view)
  }

  setInitialCursorPositionOnLoad() { // when page loads put cursor to the end of question body of first question
    let activeQuestionNode
    let activeQuestionPos
    const { tr } = window.view.state
    window.view.state.doc.nodesBetween(0, tr.doc.content.size, (node, pos) => {
      if (node.type.name === 'question') {
        if (node.attrs.activeslide === true) {
          activeQuestionNode = node
          activeQuestionPos = pos
        }
      }
    })
    tr.doc.nodesBetween(activeQuestionPos, activeQuestionPos + activeQuestionNode.nodeSize, (node, pos) => {
      if (node.type.name === 'questionBody') {
        const selection = new TextSelection(tr.doc.resolve(pos + node.nodeSize - 2))
        tr.setSelection(selection) // position cursor at end of question body
      }
    })
    tr.setMeta('addToHistory', false) // do not trigger autosave or add undo step
    window.view.dispatch(tr)
    window.view.focus()
  }

  handleKeyDown(view, event) {
    if (event.keyCode === 13) { // enter
      const { selection } = view.state
      if (selection.node && selection.node.type.name === 'math') { // if press enter key when math node is selected then we open equation editor
        return true
      } return false
    } if (event.keyCode === 38) { // up arrow
      const { selection } = view.state
      const { $from } = selection
      let isNumerator = false
      for (let i = $from.depth; i > 0; i--) {
        const node = $from.node(i)
        if (node.type.name === 'friendlyFractionNumerator') { // cancel up arrow event if in fraction numerator
          isNumerator = true
          break
        }
      }
      if (isNumerator) {
        return true
      } return false
    } if (event.keyCode === 40) { // up arrow
      const { selection } = view.state
      const { $from } = selection
      let isDenominator = false
      for (let i = $from.depth; i > 0; i--) {
        const node = $from.node(i)
        if (node.type.name === 'friendlyFractionDenominator') { // cancel down arrow event if in fraction denominatotr
          isDenominator = true
          break
        }
      }
      if (isDenominator) {
        return true
      } return false
    }

    return false
  }

  transformPastedHTML(string) { // make sure don't paste wrapping questionBody html tags
    const newHTML = string.replace(/<\/?questionbody[^>]*(>|$)/g, '')
    const HTML = newHTML.replace(/<\/?p[^>]*(>|$)/g, '')
    return HTML
  }

  addQuestionImageFromPaste(file, imageSrc) {
    if (file && file.size > MAX_FILE_SIZE) {
      this.props.showNotification('Max image upload is 20mb', 'Cannot upload image', 'destroy')
    } else {
      const src = imageSrc || null
      const thumbnail = null
      let insertPos
      let questionPos
      let questionNode
      let hasMedia = false
      let hasMediaInsertPos
      window.view.state.doc.nodesBetween(0, window.view.state.doc.content.size, (node, pos) => {
        if (node.attrs.activeslide && node.attrs.activeslide === true) {
          questionPos = pos
          questionNode = node
          window.view.state.doc.nodesBetween(pos, pos + node.nodeSize, (questionNode, questionPos) => {
            if (questionNode.type.name === 'questionBody') {
              insertPos = {
                start: questionPos + questionNode.nodeSize,
                end: questionPos + questionNode.nodeSize,
              }
            }
            if (questionNode.type.name === 'questionImage' || questionNode.type.name === 'questionAnimatedGIF' || questionNode.type.name === 'questionSound' || questionNode.type.name === 'questionVideo') {
              hasMedia = true
              hasMediaInsertPos = {
                start: questionPos,
                end: questionPos + questionNode.nodeSize,
              }
            }
          })
        }
      })
      const title = ''
      const attribution = ''
      const isCopyPaste = true
      const isSearchBarPaste = false
      const isImageSearch = false
      if (hasMedia) {
        this.props.addQuestionImage(src, thumbnail, title, attribution, hasMediaInsertPos, questionPos, questionNode, file, isImageSearch, isCopyPaste, isSearchBarPaste)
      } else {
        this.props.addQuestionImage(src, thumbnail, title, attribution, insertPos, questionPos, questionNode, file, isImageSearch, isCopyPaste, isSearchBarPaste)
      }
    }
  }

  handlePaste(view, event) {
    const { clipboardData } = event
    const { files } = clipboardData
    if (!files || !files.length) {
      const html = event.clipboardData.getData('text/html')
      if (html) {
        const regExp = '<img[^>]* src="([^"]*)"[^>]*>'
        const match = html.match(regExp)
        // check if has an image in it
        if (match && match[1]) { // handle google docs images
          const src = match[1]
          const { state } = view
          const sel = state.selection
          const $from = state.doc.resolve(sel.anchor)
          const anchorNode = getAnchorNode($from)
          if (anchorNode && anchorNode.type.name === 'questionBody') {
            this.addQuestionImageFromPaste(null, src)
            return true
          }
        }
      }
    }
    if (files[0] && files[0].size !== 0) { // when copy paste from Mac PowerPoint clipboard data has an image file with size 0 (we want to ignore this)
      const text = event.clipboardData.getData('text')
      if (!text) { // for copy paste from windows PP (clipboard data has non zero image file) check if there if there is text before uploading
        const { state } = view
        const sel = state.selection
        const $from = state.doc.resolve(sel.anchor)
        const anchorNode = getAnchorNode($from)
        if (anchorNode && anchorNode.type.name === 'questionBody') {
          this.addQuestionImageFromPaste(files[0])
          return true
        }
      }
    }
    return false
  }

  /* eslint-disable react/no-unused-state */
  sizeChanged(dimensions) { // update slide width when screen dimensions change
    const canvasGhostWidth = dimensions.dimensions.width
    const canvasGhostHeight = dimensions.dimensions.height
    this.setState({ canvasGhostWidth, canvasGhostHeight })
    const canvasGhostLeftSpacerWidth = 195 // slide panel width
    let canvasGhostRightSpacerWidth
    if (this.props.showOutput) {
      canvasGhostRightSpacerWidth = 526 // minimum for code output panel
    } else {
      canvasGhostRightSpacerWidth = 195 // minimum for insert panel
    }
    const canvasGhostSlideContainerWidth = canvasGhostWidth - canvasGhostLeftSpacerWidth - canvasGhostRightSpacerWidth
    const canvasGhostSlideContainerHeight = canvasGhostHeight
    const slideAspect = 1280 / 800
    let slideWidth
    if (canvasGhostSlideContainerWidth && canvasGhostHeight) {
      if ((canvasGhostSlideContainerWidth / slideAspect) >= canvasGhostSlideContainerHeight) { // if taller than wide, slide width is ghostSlideContainer width
        slideWidth = canvasGhostSlideContainerHeight * slideAspect
      }
      if ((canvasGhostSlideContainerWidth / slideAspect) < canvasGhostSlideContainerHeight) { // if wider than tall, slide based off of height
        slideWidth = canvasGhostSlideContainerWidth
      }
    }
    const slideHeight = slideWidth / slideAspect
    this.setState({ // think we can probably remove this stuff as slide width is now an attribute on the node
      slideWidth,
      slideHeight,
      canvasGhostSlideContainerWidth,
      canvasGhostLeftSpacerWidth,
      canvasGhostRightSpacerWidth,
    })
    updateSlideWidth(slideWidth)
  }
  /* eslint-enable react/no-unused-state */

  render() {
    const {
      activeQuestion, showAdvancedEquationButton, isViewOnly, itemIsLockedByAnotherUser, isPublicConsumer, isPublicAuthor, inSharedRepo,
    } = this.props
    const { slideWidth, slideHeight } = this.state
    const slideScale = slideWidth / 1280
    const showDevOutputPanel = this.props.showOutput
    let canvasLeftSpacerWidth = `calc((100vw - ${slideWidth}px) / 2)` // positions spacer on left
    let canvasRightSpacerWidth = `calc((100vw - ${slideWidth}px) / 2)`
    if (showDevOutputPanel) {
      canvasLeftSpacerWidth = '195px' // needs work, based off width of slide list
      canvasRightSpacerWidth = `calc(100vw - ${slideWidth}px - 195px)`
    }
    const activeQuestionMedia = activeQuestion.media
    let showChoiceAddMediaButton = true
    activeQuestion.choices.forEach((choice) => {
      if (choice.body && choice.body !== UNTOUCHED_CHOICE_BODY) {
        showChoiceAddMediaButton = false
      }
    })

    const { isSetEditor } = this.props
    const isQuestionEditor = !isSetEditor

    return (
      <div className={`editor ${isViewOnly ? ' editor--viewOnly ' : ''}${itemIsLockedByAnotherUser ? ' editor--itemIsLockedByAnotherUser ' : ''}`} id='app-editor'>

        <EditorTopBar
          reloadEditor={this.props.reloadEditor}
          makeACopySet={this.props.makeACopySet}
          makeACopyQuestion={this.props.makeACopyQuestion}
          copyQuestionToClipboard={this.props.copyQuestionToClipboard}
          set={this.props.currentSet}
          question={this.props.currentQuestion}
          updateSetTitle={this.props.updateSetTitle}
          showRepoSelectionModal={this.props.showRepoSelectionModal}
          onAddQuestionToSet={this.props.onAddQuestionToSet}
          showPrintModal={this.showPrintModal}
          showImportQuestionsModal={this.props.showImportQuestionsModal}
          isSetEditor={isSetEditor}
          isQuestionEditor={isQuestionEditor}
          maxNumOfQuestionsReached={this.props.maxNumOfQuestionsReached}
          isViewOnly={isViewOnly}
          itemIsLockedByAnotherUser={itemIsLockedByAnotherUser}
          inSharedRepo={inSharedRepo}
          isPublicConsumer={isPublicConsumer}
          isPublicAuthor={isPublicAuthor}
          showArchiveSetModal={this.props.showArchiveSetModal}
          showArchiveQuestionModal={this.props.showArchiveQuestionModal}
          showDuplicateAndEditModal={this.props.showDuplicateAndEditModal}
          convertQuestionIntoSet={this.props.convertQuestionIntoSet}
          newQuestionEditorTab={this.props.newQuestionEditorTab}
          disableFastFractionInputRule={this.props.disableFastFractionInputRule}
          toggleDisableFastFractionInputRule={this.props.toggleDisableFastFractionInputRule}
        />

        <div id='menuBar' className={`editor-formatBarContainer ${this.props.maxNumOfQuestionsReached ? ' editor-formatBarContainer--maxQuestionLimitReached ' : ''}${showAdvancedEquationButton ? ' editor-formatBarContainer--showAdvancedEquationButton ' : ''}`} />
        {' '}
        {/* FormatBar rendered into this */}

        {isSetEditor && (
        <EditorSlideList
          questions={this.props.currentSet.questions}
          setActiveQuestion={this.props.setActiveQuestion}
          activeQuestionId={this.props.activeQuestionId}
          updateQuestionOrder={this.props.updateQuestionOrder}
          handleSlideListContextMenuClick={this.props.handleSlideListContextMenuClick}
          handleSlideListKeyDown={this.props.handleSlideListKeyDown}
          maxNumOfQuestionsReached={this.props.maxNumOfQuestionsReached}
          isViewOnly={isViewOnly}
          itemIsLockedByAnotherUser={itemIsLockedByAnotherUser}
          questionsInSetLimit={this.props.questionsInSetLimit}
        />
        )}

        <div className={`editor-canvas ${showChoiceAddMediaButton ? 'editor-canvas--showChoiceAddMediaBtn ' : ''}${isQuestionEditor ? 'editor-canvas--questionEditor ' : ''}`} style={{ fontSize: `${1}px` }}>

          <div style={{ width: `${canvasLeftSpacerWidth}` }} className='editor-canvas-left' />

          {!isViewOnly && !itemIsLockedByAnotherUser && (
          <div style={{ width: `${slideWidth}px` }} className='editor-canvas-slideContainer'>
            {' '}
            {/* Slide goes into this */}
            <div style={{ transform: `scale(${slideScale})` }} className='editor-canvas-slideInnerContainer' id='editor' />
          </div>
          )}

          {(isViewOnly || itemIsLockedByAnotherUser) && (
          <div style={{ width: `${slideWidth}px` }} className='editor-canvas-slideContainer'>
            <div className='editor-canvas-slideInnerContainer'>
              <RichStaticSlide
                key={activeQuestion.questionId}
                question={activeQuestion}
                slideWidth={slideWidth}
                mediaIsPlayable
                showCorrect
              />
            </div>
          </div>
          )}

          <div style={{ width: `${canvasRightSpacerWidth}` }} className='editor-canvas-right'>

            {activeQuestionMedia && (
            <div style={{ height: `${slideHeight}px` }} className='editor-canvas-right-editMediaBarContainer'>
              <EditorCanvasEditMediaBar
                key={activeQuestion.questionId}
                media={activeQuestion.media}
                fileId={activeQuestion.media ? activeQuestion.media.fileId : null}
                mediaType={activeQuestion.media.type}
                showNotification={this.props.showNotification}
                isViewOnly={isViewOnly}
                itemIsLockedByAnotherUser={itemIsLockedByAnotherUser}
              />
            </div>
            )}

            {!activeQuestionMedia && !isViewOnly && !itemIsLockedByAnotherUser && (
            <div style={{ height: `${slideHeight}px` }} className='editor-canvas-right-insertBarContainer'>
              <EditorCanvasInsertBar showNotification={this.props.showNotification} />
            </div>
            )}

          </div>

          {!isViewOnly && !itemIsLockedByAnotherUser && (
          <EditorCanvasBottomBar
            activeQuestion={activeQuestion}
            toggleShowSidePanel={() => { this.setState((prevState) => ({ showSidePanel: !prevState.showSidePanel })) }}
            slideWidth={slideWidth}
            slideHeight={slideHeight}
            question={this.props.activeQuestion}
            saveChanges={this.props.saveChanges}
            isSaving={this.props.isSaving}
          />
          )}

        </div>

        <EditorBottomRightPanel
          isSaving={this.props.isSaving}
          item={this.props.currentSet || this.props.currentQuestion}
          unableToSave={this.props.unableToSave}
          parentRepo={this.props.parentRepo}
          currentUserPermission={this.props.currentUserPermission}
          itemIsLockedByAnotherUser={this.props.itemIsLockedByAnotherUser}
          lock={this.props.lock}
          permissions={this.props.permissions}
        />

        {this.state.showPrintModal &&
        <PrintSetModal set={this.props.currentSet} closePrintSetModal={() => { this.setState({ showPrintModal: false }) }} />}

        <div id='editor-editImageModalContainer' />
        <div id='editor-insertSoundModalContainer' />
        <div id='editor-editSoundModalContainer' />
        <div id='editor-editYouTubeModalContainer' />
        <div id='editor-insertYouTubeMiniModalContainer' />
        <div id='equationEditorModal' />

        <div style={{ display: 'none' }}>
          <div id='content' />
        </div>

        {/* GHOST CANVAS RESIZER */}

        <Measure onMeasure={(dimensions) => { this.sizeChanged({ dimensions }) }}>
          <div className='editor-canvasGhost' />
        </Measure>
      </div>
    )
  }
}

export default RichEditorPage

import React from 'react'
import findIndex from 'lodash/findIndex'
import ScoreSheetTopBar from './ScoreSheetTopBar'
import ScoreSheetSidePanel from './ScoreSheetSidePanel'
import ScoreSheetStudent from './ScoreSheetStudent'
import ScoreSheetGuest from './ScoreSheetGuest'
import ScoreSheetStudentHeader from './ScoreSheetStudentHeader'
import ScoreSheetReviewModal from './ScoreSheetReviewModal'
import ScoreSheetHeaderRow from './ScoreSheetHeaderRow'
import ScoreSheetFooterRow from './ScoreSheetFooterRow'
import ScoreSheetResponseRows from './ScoreSheetResponseRows'
import ScorePill from '../uiKit/ScorePill'
import KeyboardKey from '../uiKit/KeyboardKey'
import { sortStudentList } from '../../utils/sortStudentList'
import { sortGuestsList } from '../../utils/sortGuestsList'
import { getScoresheetSettings } from '../../utils/getScoresheetSettings'
import { saveSettingToLocalStorage } from '../../utils/saveSettingToLocalStorage'
import ReportPackModal from './reportPack/modal/ReportPackModal'
import { calculateDataForReportPack } from '../../utils/scoresheet/calculateDataForReportPack'

class ScoreSheet extends React.Component {
  constructor() {
    super()
    this.setActiveQuestionPoll = this.setActiveQuestionPoll.bind(this)
    this.setActiveSetPollPoll = this.setActiveSetPollPoll.bind(this)
    this.changeReviewPoll = this.changeReviewPoll.bind(this)
    this.closeReviewModal = this.closeReviewModal.bind(this)
    this.onScrollCenter = this.onScrollCenter.bind(this)
    this.onScrollStudentsList = this.onScrollStudentsList.bind(this)
    this.changeSorting = this.changeSorting.bind(this)
    this.setScoresheetSetting = this.setScoresheetSetting.bind(this)
    this.calculateCsvData = this.calculateCsvData.bind(this)
    this.isSyncingStudentScroll = false
    this.isSyncingCenterScroll = false

    const scoresheetSettings = getScoresheetSettings() // settings e.g. display student last name that are saved in local storage

    this.state = {
      activeQuestionPoll: null,
      activeSetPoll: null,
      activeSetPollPoll: null,
      collapsedView: false, // in collapsed view only one column per setPoll (setPollPolls are not shown)
      scoresheetSettings,
      sortedBy: 'displayName',
      sortOrder: 'ascending',
      showReportPackModal: false, // ReportPackModal is the reports Printing Modal (was named before repo/packs existed, may want to rename)
      packType: null, // currently only student available (could remove this but need to test first)
      individualReportPackStudent: null, // for individual student report pack (instead of whole class)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.activeSectionId !== this.props.activeSectionId) { // reset scroll when change active section
      if (this.studentsDiv && this.centerDiv && this.tableDiv) {
        this.studentsDiv.scrollTo(0, 0)
        this.centerDiv.scrollTo(0, 0)
        this.tableDiv.scrollTo(0, 0)
      }
    }
  }

  changeSorting(sortBy) {
    let newSortOrder = 'ascending'
    if (sortBy === this.state.sortedBy && this.state.sortOrder === 'ascending') {
      newSortOrder = 'descending'
    }
    this.setState({ sortedBy: sortBy, sortOrder: newSortOrder })
  }

  setActiveQuestionPoll(questionPoll) {
    this.setState({ activeQuestionPoll: questionPoll, activeSetPoll: null, activeSetPollPoll: null })
    this.props.toggleShowReviewModal()
  }

  setActiveSetPollPoll(setPoll, setPollPoll) {
    const activeSetPollPoll = setPollPoll
    this.props.toggleShowReviewModal()
    this.setState({ activeQuestionPoll: null, activeSetPoll: setPoll, activeSetPollPoll })
  }

  changeReviewPoll(direction) { // TODO clean this up
    const { historyPolls } = this.props
    const { activeQuestionPoll, activeSetPoll, activeSetPollPoll } = this.state
    const activeReviewPoll = activeQuestionPoll || activeSetPoll
    let newActivePollPollIndex
    let indexOfPollPoll
    let countOfPollPolls
    const indexOfActivePoll = findIndex(historyPolls, (poll) => {
      if (activeReviewPoll) {
        return poll.id === activeReviewPoll.id
      } return null
    })

    let newActiveIndex = indexOfActivePoll
    if (activeSetPollPoll) {
      countOfPollPolls = Object.keys(activeSetPoll.polls).length
      indexOfPollPoll = findIndex(Object.keys(activeSetPoll.polls), (pollId) => pollId === activeSetPollPoll.id)
    }
    if (direction === 'next') {
      if (activeQuestionPoll || (indexOfPollPoll === (countOfPollPolls - 1) && indexOfActivePoll !== historyPolls.length - 1)) {
        newActiveIndex = Math.min(indexOfActivePoll + 1, historyPolls.length - 1)
      } else {
        newActivePollPollIndex = Math.min(indexOfPollPoll + 1, countOfPollPolls - 1)
      }
    } else if (direction === 'previous') {
      if (activeQuestionPoll || (indexOfPollPoll === 0)) {
        newActiveIndex = Math.max(indexOfActivePoll - 1, 0)
      } else {
        newActivePollPollIndex = indexOfPollPoll - 1
      }
    }

    const newReviewPoll = historyPolls[newActiveIndex]
    if (newReviewPoll.polls) { // if new review poll is a setPoll, set active poll poll to the LAST setPollPoll
      let setPollPoll
      if (newActivePollPollIndex) {
        setPollPoll = newReviewPoll.polls[Object.keys(newReviewPoll.polls)[newActivePollPollIndex]]
      } else if (direction === 'previous' && newActiveIndex !== indexOfActivePoll && (activeQuestionPoll || indexOfPollPoll === 0)) {
        setPollPoll = newReviewPoll.polls[Object.keys(newReviewPoll.polls)[Object.keys(newReviewPoll.polls).length - 1]]
      } else {
        setPollPoll = newReviewPoll.polls[Object.keys(newReviewPoll.polls)[0]]
      }
      this.setState({
        activeQuestionPoll: null,
        activeSetPoll: newReviewPoll,
        activeSetPollPoll: setPollPoll,

      })
    } else {
      this.setState({
        activeQuestionPoll: newReviewPoll,
        activeSetPoll: null,
        activeSetPollPoll: null,

      })
    }
  }

  setScoresheetSetting(setting, value) {
    saveSettingToLocalStorage('scoresheetSettings', setting, value)
    const { scoresheetSettings } = this.state
    scoresheetSettings[setting] = value
    this.setState({ scoresheetSettings })
  }

  toggleShowStudentLastName() {
    if (this.state.showStudentLastName && (this.state.sortedBy === 'firstName' || this.state.sortedBy === 'lastName')) {
      this.setState({ sortedBy: 'displayName', sortOrder: 'ascending', showStudentLastName: false })
    } else this.setState((prevState) => ({ showStudentLastName: !prevState.showStudentLastName }))
  }

  closeReviewModal() {
    this.props.toggleShowReviewModal()
    this.setState({
      activeQuestionPoll: null,
      activeSetPoll: null,
      activeSetPollPoll: null,
    })
  }

  onScrollCenter() { // stuff to keep scroll areas in sync
    if (!this.isSyncingCenterScroll) {
      this.isSyncingStudentScroll = true
      this.studentsDiv.scrollTo(0, this.centerDiv.scrollTop)
    }
    this.isSyncingCenterScroll = false
  }

  onScrollStudentsList() { // stuff to keep scroll areas in sync
    if (!this.isSyncingStudentScroll) {
      this.isSyncingCenterScroll = true
      this.centerDiv.scrollTo(0, this.studentsDiv.scrollTop)
    }
    this.isSyncingStudentScroll = false
  }

  calculateCsvData() {
    return this.props.calculateCsvData(this.state.sortedBy, this.state.sortOrder)
  }

  render() {
    const {
      currentSection, historyPolls, studentsWithTotals, guestsWithTotals,
    } = this.props
    const {
      activeQuestionPoll, activeSetPollPoll, collapsedView, activeSetPoll, sortedBy, sortOrder,
    } = this.state
    let currentSectionId
    if (currentSection) {
      currentSectionId = currentSection.id
    }
    let students = []
    if (currentSection) {
      students = sortStudentList(currentSection.students, studentsWithTotals, sortedBy, sortOrder)
    }
    let guests = []
    if (this.props.guestsWithTotals) {
      const guestsIncTotal = Object.keys(this.props.guestsWithTotals)
      guests = guestsIncTotal.slice(0, guestsIncTotal.length - 1) // temp to remove total key
      guests = sortGuestsList(guests, guestsWithTotals, sortedBy, sortOrder)
    }

    let averageScore = -1

    if (studentsWithTotals) {
      let totalResponses = studentsWithTotals.total.allStudentsResponseCount
      let totalCorrect = studentsWithTotals.total.allStudentsCorrectCount
      if (guestsWithTotals.total) {
        totalResponses += guestsWithTotals.total.allGuestsResponseCount
        totalCorrect += guestsWithTotals.total.allGuestsCorrectCount
      }
      if (totalResponses !== 0) {
        averageScore = 100 * (totalCorrect / totalResponses)
      }
    }

    const activeReviewPoll = activeQuestionPoll || activeSetPollPoll

    /// / Calculate table width (IE Fix)
    const defaultColumnWidth = 90
    const collapsedQuestionColumnWidth = 90
    const collapsedSetColumnWidth = 140

    let dataTableWidth = this.props.columnCount * defaultColumnWidth
    if (collapsedView) {
      dataTableWidth = (this.props.setCount * collapsedSetColumnWidth) + (this.props.independentQuestionCount * collapsedQuestionColumnWidth)
    }

    const dataTableStyle = { width: `${dataTableWidth}px`, minWidth: `${dataTableWidth}px` }

    ///
    const { scoresheetSettings } = this.state
    const { showStudentCardNumber, showStudentLastName, studentScoreDisplay } = scoresheetSettings
    return (
      <div className='scoreSheet scoreSheet--compact'>
        <ScoreSheetTopBar
          openStudySetModal={this.props.openStudySetModal}
          calculateCsvData={this.calculateCsvData}
          getCsvFilename={this.props.getCsvFilename}
          collapsedView={collapsedView}
          toggleCollapsedView={() => { this.setState((prevState) => ({ collapsedView: !prevState.collapsedView })) }}
          changeDateGranularity={this.props.changeDateGranularity}
          granularity={this.props.granularity}
          changeSelectedDate={this.props.changeSelectedDate}
          dateRangeLabel={this.props.dateRangeLabel}
          showScoreSheetCustomRangeModal={this.props.showScoreSheetCustomRangeModal}
          dateRangeCanGoForward={this.props.dateRangeCanGoForward}
          customDateRange={this.props.customDateRange}
          showStudentLastName={showStudentLastName}
          showStudentCardNumber={showStudentCardNumber}
          setScoresheetSetting={this.setScoresheetSetting}
          hasPolls={this.props.allHistoryPolls.length > 0}
          noSectionSelected={!currentSection}
          disable90Day={this.props.disable90Day}
          disableCustom={this.props.disableCustom}
          studentScoreDisplay={studentScoreDisplay}
          changeStudentScoreDisplay={this.handleChangeStudentScoreDisplay}
          clearExcludedPolls={this.props.clearExcludedPolls}
          currentSectionId={currentSectionId}
          currentSection={currentSection}
          showReportPackModal={(packType) => { this.setState({ showReportPackModal: true, packType }) }}
        />
        {this.props.excludedPolls && (
        <ScoreSheetSidePanel
          customDateRange={this.props.customDateRange}
          sections={this.props.sections}
          changeActiveSection={this.props.changeActiveSection}
          activeSectionId={this.props.activeSectionId}
          dateRange={this.props.dateRange}
          currentSectionId={currentSectionId}
          granularity={this.props.granularity}
          changeSelectedDate={this.props.changeSelectedDate}
        />
        )}

        <div className='scoreSheet-centerContainer'>
          {!currentSection && ( // show empty state
          <div className='scoreSheet-centerContainer-empty'>
            <div className='scoreSheet-centerContainer-empty-h1'>
              Welcome to Scoresheet!
            </div>
            <div className='scoreSheet-centerContainer-empty-h2'>
              Select a class on the left to get started.
            </div>
            <div className='scoreSheet-centerContainer-empty-keyboardShortcuts'>
              <div className='scoreSheet-centerContainer-empty-keyboardShortcuts-label'>
                Keyboard Shortcuts
              </div>

              <div className='scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut'>
                <div className='scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-right'>
                  <div className='scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-desc'>
                    Go forward in time
                  </div>
                  <div className='scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-shortcut scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-shortcut--right'>
                    <KeyboardKey shift />
                    <KeyboardKey arrowUp />
                  </div>
                </div>
              </div>
              <div className='scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut'>
                <div className='scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-right'>
                  <div className='scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-desc'>
                    Go back in time
                  </div>
                  <div className='scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-shortcut scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-shortcut--left'>
                    <KeyboardKey shift />
                    <KeyboardKey arrowUp />
                  </div>
                </div>
              </div>
              <div className='scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut'>
                <div className='scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-right'>
                  <div className='scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-desc'>
                    Switch Classes
                  </div>
                  <div className='scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-shortcut scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-shortcut--updown'>
                    <KeyboardKey shift />
                    <KeyboardKey arrowUp />
                    <KeyboardKey arrowDown />
                  </div>
                </div>
              </div>
            </div>
          </div>
          )}

          {currentSection && (
          <div className={`scoreSheet-students${
            !showStudentLastName && !showStudentCardNumber ? ' scoreSheet-students--default' : ''
          }${showStudentLastName ? ' scoreSheet-students--showLastName' : ''
          }${showStudentCardNumber ? ' scoreSheet-students--showCardNumber' : ''}`}
          >
            <ScoreSheetStudentHeader
              changeSorting={this.changeSorting}
              showStudentLastName={showStudentLastName}
              showStudentCardNumber={showStudentCardNumber}
              sortOrder={this.state.sortOrder}
              sortedBy={this.state.sortedBy}
            />

            <div className='scoreSheet-students-students' onScroll={this.onScrollStudentsList} ref={(el) => { this.studentsDiv = el }}>
              <StudentList
                guests={guests}
                students={students}
                studentsWithTotals={studentsWithTotals}
                guestsWithTotals={guestsWithTotals}
                showStudentLastName={showStudentLastName}
                showStudentCardNumber={showStudentCardNumber}
                studentScoreDisplay={studentScoreDisplay}
                showReportPackModal={(student) => { this.setState({ showReportPackModal: true, individualReportPackStudent: student, packType: 'student' }) }}
              />
            </div>
            <div className='scoreSheet-students-footer'>
              {historyPolls.length > 0 && (
              <div className='scoreSheet-students-footer-label'>
                Class Average
              </div>
              )}
              {historyPolls.length > 0 && averageScore > -1 &&
              <ScorePill typeDot score={averageScore} />}
            </div>
          </div>
          )}

          {currentSection && (
          <div className='scoreSheet-table' ref={(el) => { this.tableDiv = el }}>
            <ScoreSheetHeaderRow excludePoll={this.props.excludePoll} excludedPolls={this.props.excludedPolls[currentSectionId]} collapsedView={collapsedView} style={dataTableStyle} historyPolls={historyPolls} setActiveQuestionPoll={this.setActiveQuestionPoll} setActiveSetPollPoll={this.setActiveSetPollPoll} />
            <div className='scoreSheet-table-responseRows' style={dataTableStyle} onScroll={this.onScrollCenter} ref={(el) => { this.centerDiv = el }}>
              <ScoreSheetResponseRows excludedPolls={this.props.excludedPolls[currentSectionId]} collapsedView={collapsedView} historyPolls={historyPolls} students={students} guests={guests} />
            </div>
            <ScoreSheetFooterRow excludedPolls={this.props.excludedPolls[currentSectionId]} excludePoll={this.props.excludePoll} collapsedView={collapsedView} style={dataTableStyle} historyPolls={historyPolls} setActiveQuestionPoll={this.setActiveQuestionPoll} setActiveSetPollPoll={this.setActiveSetPollPoll} />
            {historyPolls.length === 0 && (
            <div className='scoreSheet-table-noData'>
              No student results for your class and date selection.
            </div>
            )}
          </div>
          )}
        </div>

        {this.state.showReportPackModal && (
        <ReportPackModal
          packType={this.state.packType}
          students={students}
          studentsWithTotals={studentsWithTotals}
          section={currentSection}
          historyPolls={calculateDataForReportPack(historyPolls.slice(0), this.props.excludedPolls[currentSectionId])}
          dateRange={this.props.dateRange}
          guests={guests}
          individualReportPackStudent={this.state.individualReportPackStudent}
          closeReportPackModal={() => { this.setState({ showReportPackModal: false, packType: null, individualReportPackStudent: null }) }}
        />
        )}

        {(activeReviewPoll || activeSetPoll) && (
        <ScoreSheetReviewModal
          setPoll={activeSetPoll}
          section={currentSection}
          poll={activeReviewPoll}
          collapsedView={collapsedView}
          closeReviewModal={this.closeReviewModal}
          changeReviewPoll={this.changeReviewPoll}
        />
        )}
      </div>
    )
  }
}

export default ScoreSheet

class StudentList extends React.Component {
  render() {
    const {
      studentScoreDisplay, students, guests, showStudentCardNumber, showStudentLastName, showReportPackModal,
    } = this.props
    return (
      <React.Fragment>
        {students.map((student, index) => {
          let studentTotals = {}
          if (this.props.studentsWithTotals) {
            studentTotals = this.props.studentsWithTotals[student.id]
          }
          return (
            <ScoreSheetStudent
              key={index}
              student={student}
              studentTotals={studentTotals}
              showStudentCardNumber={showStudentCardNumber}
              showStudentLastName={showStudentLastName}
              studentScoreDisplay={studentScoreDisplay}
              showReportPackModal={showReportPackModal}
            />
          )
        })}
        {guests.map((guest, index) => {
          let guestTotals = {}
          if (this.props.guestsWithTotals) {
            guestTotals = this.props.guestsWithTotals[guest]
          }
          return (
            <ScoreSheetGuest
              key={index}
              guest={guest}
              guestTotals={guestTotals}
              showStudentCardNumber={showStudentCardNumber}
              showStudentLastName={showStudentLastName}
              studentScoreDisplay={studentScoreDisplay}
            />
          )
        })}
      </React.Fragment>
    )
  }
}

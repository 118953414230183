
export default function calculateEditImageCanvasImagePositioning(imageDimensions, rotateAngle,flipHorizontal,flipVertical){
	const {editorCanvasImageWidth,editorCanvasImageHeight,canvasImageXOffset,canvasImageYOffset,imageContainerWidth,imageContainerHeight}=imageDimensions
	let imageTransformRotateX = '0deg'
  	let imageTransformRotateY = '0deg'
  	let imageTransformRotateZ = `${rotateAngle}deg`

  	let imageTranslateX = 0
  	let imageTranslateY = 0

  	const isRotated = rotateAngle === 90 || rotateAngle === 180 || rotateAngle === 270

  	// NO FLIP

  	if(!flipHorizontal && !flipVertical){
	  	if(rotateAngle === 90){
	  		imageTranslateX = `${editorCanvasImageHeight}px`
	  	}
	  	if(rotateAngle === 180){
	  		imageTranslateX = `${editorCanvasImageWidth}px`
	  		imageTranslateY = `${editorCanvasImageHeight}px`
	  	}
	  	if(rotateAngle === 270){  		
	  		imageTranslateY = `${editorCanvasImageWidth}px`
	  	}
  	}


  	// FLIP HORIZONTAL
  	if(flipHorizontal){
  		imageTransformRotateY = '-180deg'
  	}

  	if(flipHorizontal && !flipVertical && !isRotated){
  		imageTranslateX = `${editorCanvasImageWidth}px`
  	}

  	if(flipHorizontal && !flipVertical && isRotated){

  		if(rotateAngle === 90){
  			imageTranslateX = 0
	  	}
	  	if(rotateAngle === 180){	  		
	  		imageTranslateY = `${editorCanvasImageHeight}px`
	  	}
	  	if(rotateAngle === 270){  		
	  		imageTranslateY = `${editorCanvasImageWidth}px`
	  		imageTranslateX = `${editorCanvasImageHeight}px`
	  	}

  	}

  	// FLIP VERTICAL

  	if(flipVertical){
  		imageTransformRotateX = '-180deg'
  	}

  	if(flipVertical && !flipHorizontal && !isRotated){
  		imageTranslateY = `${editorCanvasImageHeight}px`
  	}

  	if(flipVertical && !flipHorizontal && isRotated){
  		if(rotateAngle === 90){
  			imageTranslateY = `${editorCanvasImageWidth}px`
	  		imageTranslateX = `${editorCanvasImageHeight}px`
	  	}
	  	if(rotateAngle === 180){	  		
	  		imageTranslateX = `${editorCanvasImageWidth}px`	  		
	  	}
	  	if(rotateAngle === 270){  		
	  		imageTranslateY = 0
	  		imageTranslateX = 0
	  	}

  	}


  	// BOTH FLIP

  	if(flipVertical && flipHorizontal && !isRotated){
  		imageTranslateX = `${editorCanvasImageWidth}px`
  		imageTranslateY = `${editorCanvasImageHeight}px`
  	}

  	if(flipVertical && flipHorizontal && isRotated){
  		if(rotateAngle === 90){
  			imageTranslateY = `${editorCanvasImageWidth}px`
	  		//imageTranslateX = `${editorCanvasImageHeight}px`
	  	}
	  	if(rotateAngle === 180){	  		
	  		//imageTranslateX = `${editorCanvasImageWidth}px`	  		
	  	}
	  	if(rotateAngle === 270){  			  		
	  		imageTranslateX = `${editorCanvasImageHeight}px`
	  	}

  	}

  	


  	const imageTransform = `translateX(${imageTranslateX}) translateY(${imageTranslateY}) rotateX(${imageTransformRotateX}) rotateY(${imageTransformRotateY}) rotateZ(${imageTransformRotateZ})`		

 
	  // CENTERING IMAGE IN CONTAINER
  	const imageContainerPositioning = {
  		top: `${canvasImageYOffset}px`,  		
  		left: `${canvasImageXOffset}px`,
  		width: `${imageContainerWidth}px`,
  		height: `${imageContainerHeight}px`,
  	}

  	const imagePositioning = {
				width: `${editorCanvasImageWidth}px`,
				height: `${editorCanvasImageHeight}px`,
				transform: `${imageTransform}`			
  	}  	  	
  	const imagePositioningStyling={
  		imageContainerPositioning:imageContainerPositioning,
  		imagePositioning:imagePositioning
  	}
  	return imagePositioningStyling
}	
  	
   
import React from 'react';
import ModalWrapper from './ModalWrapper';
import NewSectionContainer from '../../containers/sections/NewSectionContainer'

const NewSectionModal = props => {


  return (
    <div className="newSection-modal">
      <ModalWrapper
        {...props}
        title="Add Classes" 
        modalStyle="dark"
        showModalHeader={false}
      >
        <NewSectionContainer/>

      </ModalWrapper>

    </div>

  );
};

export default NewSectionModal;
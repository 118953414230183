import React from 'react'


class LiveViewReviewScreenControls extends React.Component{

	constructor(){
		super()
		this.handleToggleShowCorrect=this.handleToggleShowCorrect.bind(this)
		this.handleToggleShowGraph=this.handleToggleShowGraph.bind(this)
		this.state={
			showCorrectButtonDisabled:false,
			showGraphButtonDisabled:false,
		}
	}	

	handleToggleShowCorrect(){
		if(window.analytics){
			window.analytics.track('Now Playing Toggle Show Correct')
		}
		this.setState({showCorrectButtonDisabled:true})
		return this.props.toggleShowCorrect().then(()=>{
			this.setState({showCorrectButtonDisabled:false})
		})
	}

	handleToggleShowGraph(){
		if(window.analytics){
			window.analytics.track('Now Playing Toggle Show Graph')
		}
		this.setState({showGraphButtonDisabled:true})
		return this.props.toggleShowGraph().then(()=>{
			this.setState({showGraphButtonDisabled:false})
		})
	}


	render(){
		const {layout,showCorrect,showGraph,fullScreen,questionIsSurvey,alwaysShowGraph}=this.props
		return(
			<div className={'liveView-reviewControls ' + 				
				(questionIsSurvey ? 'liveView-reviewControls--isSurvey' : 'liveView-reviewControls--isGraded') +
				(fullScreen ? ' liveView-reviewControls--fullScreen ' : ' liveView-reviewControls--notFullScreen ')
			}
				style={{
						top: `${layout.yPosSlide + (layout.internalSafePadding * 0.25)}px`,
						right:`${layout.internalSafePadding * 0.9}px`,												
				}}
			>	
			 		
			 {!alwaysShowGraph && 
				<button 
					className={'liveView-reviewControls-btn liveView-reviewControls-btn--showGraph ' + (showGraph ? 'liveView-reviewControls-btn--showGraph--active' : 'liveView-reviewControls-btn--showGraph--inactive')}
					onClick={this.handleToggleShowGraph}
					disabled={this.state.showGraphButtonDisabled}
					style={{
						width: `${layout.reviewControlWidth}px`,
						height: `${layout.reviewControlHeight}px`,
						fontSize: `${layout.reviewControlFontSize}px`,
						borderRadius: `${layout.reviewControlBorderRadius}px`,
						marginLeft: `${layout.reviewControlMarginLeft}px`,
					}}
				>
					<div className='liveView-reviewControls-btn-label'>
						{showGraph ? <span>Hide Graph</span> : <span>Show Graph</span>}
					</div>
					
				</button>
			 }

				<button 
					className={'liveView-reviewControls-btn liveView-reviewControls-btn--showCorrect ' + (showCorrect ? 'liveView-reviewControls-btn--showCorrect--active' : 'liveView-reviewControls-btn--showCorrect--inactive')}
					onClick={this.handleToggleShowCorrect}
					disabled={this.state.showCorrectButtonDisabled}
					style={{
						width: `${layout.reviewControlShowCorrectWidth}px`,
						height: `${layout.reviewControlHeight}px`,
						fontSize: `${layout.reviewControlFontSize}px`,
						borderRadius: `${layout.reviewControlBorderRadius}px`,
						marginLeft: `${layout.reviewControlMarginLeft}px`,
					}}
				>
					<div className='liveView-reviewControls-btn-label'>
						{showCorrect ? <span>Hide Answer</span> : <span>Reveal Answer</span>}
					</div>
											
				</button>	
				
			</div>
		)
	}
}

export default LiveViewReviewScreenControls

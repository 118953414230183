import React from 'react'
import ReactGA from 'react-ga4'
import { Route } from 'react-router-dom'

// Redirect to unauthenticated app
const RedirectUnauthenticated = ({
  path,
  location,
  isAuthenticated,
  willAuthenticate,
  component: Component,
}) => {
  if (!isAuthenticated && !willAuthenticate) {
    window.location = `${process.env.REACT_APP_UNAUTH_PREVIEW_URL}/${location.pathname}`
  } else if (isAuthenticated) {
    return (
      <Route
        exact
        path={path}
        render={(props) => {
          if (typeof window.gtag === 'function') {
            ReactGA.set({ page: location.pathname + location.search })
            ReactGA.send({ hitType: 'pageview' })
          }
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          return <Component {...props} />
        }}
      />

    )
  } else return null
}

export default RedirectUnauthenticated

//https://github.com/react-dnd/react-dnd
const SectionTarget = {
	canDrop(props, monitor) {
		const multiselectItems=monitor.getItem().multiselectItems
		let multiSelectContainsFolders=false
		if(multiselectItems){
			Object.keys(multiselectItems).map((key) => {
				if(multiselectItems[key].ancestors){
					multiSelectContainsFolders=true
				}
				return null
			})
			if(multiSelectContainsFolders){
				return false 
			}else return true
		}else return true

	},
	drop(props, monitor) {
		const droppedItem=monitor.getItem().cellItem
		const multiselectItems=monitor.getItem().multiselectItems
		let promises=[]
		if(multiselectItems){
			Object.keys(multiselectItems).map((key)=>{
				const item=multiselectItems[key]
				promises.push(props.createPoll(item,props.section.id))
				return null

			})
		}else{
			promises.push(props.createPoll(droppedItem,props.section.id))
		}
		Promise.all(promises).then(function () {
			let itemCount=1
			if(multiselectItems){
				itemCount=Object.keys(multiselectItems).length
			}
			let notificationMessage=''
			if(itemCount>1){
				 notificationMessage=`${itemCount} items for ${props.section.name}`
			}else{
				notificationMessage=`${droppedItem.name || droppedItem.body} for ${props.section.name}`
			}
			props.showNotification(notificationMessage,'Queued','default')
		})
	}
}

export default SectionTarget
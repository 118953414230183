import React, { Component} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {updateSetContent,createNewSet} from '../../actions/sets'
import ImportQuestionsModal from '../../components/importQuestions/ImportQuestionsModal'
import ImportQuestionsOnboardingModal from '../../components/importQuestions/ImportQuestionsOnboardingModal'
import ImportQuestionsFeedbackModal from '../../components/importQuestions/ImportQuestionsFeedbackModal'
import range from 'lodash/range'
import includes from 'lodash/includes'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import richSetTemplate from '../../utils/editors/richSetTemplate'
import {updateSetLock} from '../../actions/locks'
import HelpVideoModal from '../../components/HelpVideoModal'
import {showNotification} from '../../actions/notifications'
import {detectBrowser} from '../../utils/detectBrowser'
import {calculateMeasurements} from './calculateMeasurements'
 
const browser=detectBrowser()

const QUESTION_BODY_MAX_LENGTH=250
const QUESTION_CHOICE_MAX_LENGTH=200
const MAX_QUESTION_COUNT=75
const LOCK_THROTTLE_TIME=1000*10
const regexMatchQuestionNumbers = new RegExp('^((question )?[0-9]+[\\).](?=\\s*?\\S+)|(question )?[0-9]+ [\\).](?=\\s*?\\S+)|(question) [0-9]+ (of) [0-9]+(?=\\s*?\\S+)|(question) [0-9]+(?=\\s*?\\S+)|^((Question )?[0-9]*[\\).]|(Question )?[0-9]* [\\).] |(question) [0-9]* (of) [0-9]*|(question) [0-9]*|\\([0-9]*\\)))','gmi')
const regexMatchChoiceLabels = new RegExp('^(\\([abcd]\\)(?=\\s*?\\S+)|[abcd] [\\).](?=\\s*?\\S+)|[abcd][\\).](?=\\s*?\\S+)|[abcd](?=\\s+\\S+)|\\( [abcd](?=\\s*?\\S+)\\))','gmi')

//to detect choices dont include a
const regexDetectChoiceLabels = new RegExp('^(\\([0-9]\\)|\\([abcd]\\)|[abcd] [\\).]|[abcd][\\).]|[bcd] |\\( [abcd] \\))','gmi')
const regexMatchBlankLines = new RegExp('^\\s*[\\r\\n]?','gm')
const regexMatchWhitespace = new RegExp('^[ \\t]+|[ \\t]+$','gm')
const regexMatchBulletPoints = new RegExp('^[•*·\\- – — ?]','gm')
// const regexMatchKahootLabels = new RegExp(/([0-9]* sec)|([0-9]* - quiz)/,'gmi') //20 sec and 2-Quiz
// const regexMatchKahootCorrectnessLabels = new RegExp(/(this is a wrong answer)|(this is a correct answer)/,'gmi')
 
const QUESTION_TEMPLATE={
	body: '',
	bodyHtml:'',
	bodySource:'',
	measurements:{
		bodyFS:64,
		choiceFS:38
	},
	layout:'bodyLeft',
	media:null,
	choices:[]
}

function isBlankQuestion(question){
	let isBlank=true
	if(question.body !=='' && question.body !=='Click here to edit question'){
		isBlank=false
	}else if(question.image !==''){
		isBlank=false
	}else{
		question.choices.map((choice)=>{
			if(choice.body !== '' && choice.body !=='\u2063'){
				isBlank=false
			}
			return null
		})
	}
	return isBlank
}


class ImportQuestionsContainer extends Component {
   
	constructor(){
		super()
		this.onChange=this.onChange.bind(this)
		this.setCorrectAnswer=this.setCorrectAnswer.bind(this)
		this.removeEmbeddedQuestionNumbers=this.removeEmbeddedQuestionNumbers.bind(this)
		this.removeEmbeddedChoiceLetters=this.removeEmbeddedChoiceLetters.bind(this)
		this.removeBulletPoints=this.removeBulletPoints.bind(this)
		this.onPaste=this.onPaste.bind(this)
		this.detectKahootFormatting=this.detectKahootFormatting.bind(this)
		this.removeKahootFormatting=this.removeKahootFormatting.bind(this)
		this.importQuestions=this.importQuestions.bind(this)
		this.parseTextIntoQuestions=this.parseTextIntoQuestions.bind(this)
		this.hideModal=this.hideModal.bind(this)
		this.openHelpVideo=this.openHelpVideo.bind(this)	
		this.parseQuestionsFromText=this.parseQuestionsFromText.bind(this)
		this.updateTextValue=this.updateTextValue.bind(this)
		this.findAndRemove=this.findAndRemove.bind(this)
		this.setChoicesPerQuestion=this.setChoicesPerQuestion.bind(this)
		this.setToSurvey=this.setToSurvey.bind(this)
		this.setToGraded=this.setToGraded.bind(this)
		this.autoclean=this.autoclean.bind(this)
		this.getActiveQuestion=this.getActiveQuestion.bind(this)
		this.getLineNumber=this.getLineNumber.bind(this)
		this.savePreviousState=this.savePreviousState.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.parseTextIntoQuestionsClean=this.parseTextIntoQuestionsClean.bind(this)
		this.toggleDoNotShowOnboardingAgain=this.toggleDoNotShowOnboardingAgain.bind(this)
		this.toggleAutocleanOnImport=this.toggleAutocleanOnImport.bind(this)
		this.clearNotification=this.clearNotification.bind(this)
		this.showImportQuestionsNotification=this.showImportQuestionsNotification.bind(this)
		this.undoLastChange=this.undoLastChange.bind(this)
		//this.calculateMeasurements=this.calculateMeasurements.bind(this)

		this.lockItem = throttle(this.lockItem,LOCK_THROTTLE_TIME,{'edge': 'both' })
		this.debouncedSavePreviousState=this.debouncedSavePreviousState.bind(this)
		this.debouncedSavePreviousState = debounce(this.debouncedSavePreviousState,500,{'leading': true,'trailing':false })


		let choicesObject={}
		const questionIndexArray=range(MAX_QUESTION_COUNT)
		questionIndexArray.map((questionIndex)=>{
			choicesObject[questionIndex]={
				'A':true,
				'B':false,
				'C':false,
				'D':false,
				'isSurvey':false
			}
			return null
		})

		let showOnboarding=true
		let doNotShowAgain=false
		const localStorageOnboardingFlag = JSON.parse(localStorage.getItem('doNotShowImportQuestionsOnboarding'))
		if(localStorageOnboardingFlag){
			showOnboarding=false
			doNotShowAgain=true
		}

		let autocleanOnImport=true
		const localStorageAutocleanFlag = JSON.parse(localStorage.getItem('autocleanOnImport'))
		if(localStorageAutocleanFlag || localStorageAutocleanFlag===false){
			autocleanOnImport=localStorageAutocleanFlag
		}

		this.state=({
			value: '',
			previousValue:'',
			choicesObject:choicesObject,
			questionsPreview:null,
			questionPreviewTimestamp:null,
			matchCase:false,
			deleteLineWithMatch:true,
			choicesPerQuestion:4,
			canAutoclean:false,
			questionNumbersDetected:false,
			choiceLettersDetected:false,
			bulletPointsDetected:false,
			activeQuestionIndex:0,
			previousStateArray:[],
			showOnboarding:showOnboarding,
			doNotShowAgain:doNotShowAgain,
			submitLoading:false,
			autocleanOnImport:autocleanOnImport,
			showHelpVideoModal:false,
			showFeedbackModal:false,
			contentOverFreeLimit:false,
			showImportQuestionsNotification:false,
			importQuestionsNotificationType:null,
			importQuestionsNotificationTimestamp:null,
			importQuestionsNotificationSearchTerm:null
		})
	}

	componentDidMount() {
		this.lockItem()//for sets in repos
		document.addEventListener('keydown', this.handleKeyDown)
		localStorage.setItem('doNotShowImportQuestionsNewFlag',true )
		if(window.analytics){
			window.analytics.page('Import Questions Modal')
		}
	}

	componentWillUnmount() { 
		document.removeEventListener('keydown', this.handleKeyDown)
	}

	lockItem(){
		if(this.props.currentSet && this.props.currentSet.repo){
			this.props.updateSetLock(this.props.currentSet.id)
		}
	}

	handleKeyDown(e){
		e.stopPropagation()
		if((e.metaKey|| e.ctrlKey) && e.keyCode === 90) {
			if(this.state.previousStateArray.length>0){
				this.undoLastChange()
			}
		}
	}

	toggleDoNotShowOnboardingAgain(){
		if(this.state.doNotShowAgain){
			localStorage.setItem('doNotShowImportQuestionsOnboarding',false )
			this.setState({doNotShowAgain:false})
		}else{
			localStorage.setItem('doNotShowImportQuestionsOnboarding',true )
			this.setState({doNotShowAgain:true})
		}
	}

	toggleAutocleanOnImport(){
		if(this.state.autocleanOnImport){
			localStorage.setItem('autocleanOnImport',false )
			this.setState({autocleanOnImport:false},() => this.parseQuestionsFromText(this.state.value))
		}else{
			localStorage.setItem('autocleanOnImport',true )
			this.setState({autocleanOnImport:true},() => this.parseQuestionsFromText(this.state.value))
		}
	}

	getLineNumber(event) {
		let el
		if(event){
			el = event.target
		}else{
			 el=document.getElementById('importQuestionsTextArea')
		}
		const cursorPosStart = el.selectionStart
		const v = el.value
		const textBefore = v.substring(0, cursorPosStart)
		const lines =textBefore.split(/\r\n|\n|\r/)
		const lineNumber=lines.length
		this.getActiveQuestion(lineNumber)
	}

	getActiveQuestion(activeLineNumber){
		const {choicesPerQuestion} = this.state
		const questionIndex=Math.floor((activeLineNumber-1)/(choicesPerQuestion+1))
		if(browser !=='msie'){ //fix for edge and ie
			if(questionIndex<this.props.remainingQuestionsInSetLimit){
				this.setState({activeQuestionIndex:questionIndex})
			}
		}
	}

	setChoicesPerQuestion(choiceCount){
		let choicesObject={}
		let choiceCorrectness={
			'A':true,
			'B':false,
			'isSurvey':false
		}
		if(choiceCount>2){
			choiceCorrectness['C']=false
		}
		if(choiceCount>3){
			choiceCorrectness['D']=false
		}
		const questionIndexArray=range(MAX_QUESTION_COUNT)
		questionIndexArray.map((questionIndex)=>{
			choicesObject[questionIndex]=choiceCorrectness
			return null
		})
		this.setState({choicesObject:choicesObject,choicesPerQuestion:choiceCount},()=>{
			this.parseQuestionsFromText(this.state.value)
			this.getLineNumber()
  		})
	}

	setCorrectAnswer(questionIndex,correctChoice){
		let newChoicesObject=Object.assign({},this.state.choicesObject)
		newChoicesObject[questionIndex]={
			'A':correctChoice==='A',
			'B':correctChoice==='B',
			'C':correctChoice==='C',
			'D':correctChoice==='D',
		}
		this.setState({choicesObject:newChoicesObject})
	}

	setToSurvey(questionIndex){
		const {choicesPerQuestion}=this.state
		let newChoicesObject=Object.assign({},this.state.choicesObject)
		let choiceCorrectness={
			'A':false,
			'B':false,
			'isSurvey':true
		}
		if(choicesPerQuestion>2){
			choiceCorrectness['C']=false
		}
		if(choicesPerQuestion>3){
			choiceCorrectness['D']=false
		}		
		newChoicesObject[questionIndex]=choiceCorrectness
		this.setState({choicesObject:newChoicesObject})
	}

	setToGraded(questionIndex){
		const {choicesPerQuestion}=this.state
		let newChoicesObject=Object.assign({},this.state.choicesObject)
		let choiceCorrectness={
			'A':true,
			'B':false,
			'isSurvey':false
		}
		if(choicesPerQuestion>2){
			choiceCorrectness['C']=false
		}
		if(choicesPerQuestion>3){
			choiceCorrectness['D']=false
		}
		newChoicesObject[questionIndex]=choiceCorrectness
		this.setState({choicesObject:newChoicesObject})
	}

	debouncedSavePreviousState(value){ //for typing
		let {previousStateArray}=this.state
		const previousValue=value||''
		previousStateArray.push(previousValue)
		previousStateArray=previousStateArray.slice(Math.max((previousStateArray.length-5),0)) //limit to 5 previous states
		this.setState({previousStateArray:previousStateArray})
	}

	savePreviousState(value){ //for button actions
		let {previousStateArray}=this.state
		const previousValue=value||''
		previousStateArray.push(previousValue)
		previousStateArray=previousStateArray.slice(Math.max((previousStateArray.length-5),0)) //limit to 5 previous states
		this.setState({previousStateArray:previousStateArray})
	}

	undoLastChange(){
		if(window.analytics){
			window.analytics.track('Import Questions Action',{
									type:'undo'
			})	
		}
		let {previousStateArray} = this.state
		const previousState=previousStateArray[previousStateArray.length-1]
		previousStateArray.splice(-1,1)
		this.setState({previousStateArray:previousStateArray})
		this.clearNotification()
		this.updateTextValue(previousState)
		this.detectAutoclean(previousState)
	}

	onChange(e){
		const currentValue=this.state.value
		const newValue = e.target.value
		this.updateTextValue(newValue)
		this.debouncedSavePreviousState(currentValue)
		this.lockItem()
	}

	updateTextValue(value){
		this.setState({value:value})
		this.clearNotification()
		this.parseQuestionsFromText(value)
	}

	parseQuestionsFromText(text){
		const parsedQuestions=this.parseTextIntoQuestions(text)
		const nowDate=new Date()
		this.setState({questionsPreview:parsedQuestions,questionPreviewTimestamp:nowDate})
	}

	escapeRegExp(string) {
		return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
	}

	findAndRemove(string){
		if(window.analytics){
			window.analytics.track('Import Questions Action',{
									type:'find and remove'
			})	
		}
		const currentValue=this.state.value
		const escapedString=this.escapeRegExp(string)
		let newText
		let regexOptions='gi'
		let notificationType
		if(this.state.matchCase){
			regexOptions='g'
		}
		if(this.state.deleteLineWithMatch){
			var regexMatchLine = new RegExp(`.*${escapedString}.*\r?\n?`,regexOptions)
			if(regexMatchLine.test(currentValue)){
				notificationType='findAndRemove'
			}else{
				
				notificationType='noMatchFindAndRemove'
			}
			newText = currentValue.replace(regexMatchLine,  '')
			
		}
		else{
			var regex = new RegExp(escapedString,regexOptions)
			if(regex.test(currentValue)){
				notificationType='findAndRemove'
			}else{
				notificationType='noMatchFindAndRemove'
			}
			newText = currentValue.replace(regex, '')
		}	
		this.savePreviousState(currentValue)
		this.updateTextValue(newText)
		this.detectAutoclean(newText)
		this.showImportQuestionsNotification(notificationType,string)
	}

	removeBulletPoints(){
		if(window.analytics){
			window.analytics.track('Import Questions Action',{
									type:'Manual clean-bullet points'
			})	
		}
		const currentValue=this.state.value
		let newText=currentValue.replace(regexMatchWhitespace, '')
		newText = newText.replace(regexMatchBulletPoints, '')
		newText=newText.replace(regexMatchWhitespace, '')
		this.savePreviousState(currentValue)
		this.updateTextValue(newText)
		this.detectAutoclean(newText)
		this.showImportQuestionsNotification('cleanBulletPoints')
	}

	removeEmbeddedQuestionNumbers(){
		if(window.analytics){
			window.analytics.track('Import Questions Action',{
									type:'Manual clean-question numbers'
			})	
		}
		const currentValue=this.state.value
		let newText = currentValue.replace(regexMatchWhitespace, '')
		newText = newText.replace(regexMatchQuestionNumbers,'')
		this.savePreviousState(currentValue)
		this.updateTextValue(newText)
		this.detectAutoclean(newText)
		this.showImportQuestionsNotification('cleanQuestionNumbers')
	}


	removeEmbeddedChoiceLetters(){
		if(window.analytics){
			window.analytics.track('Import Questions Action',{
									type:'Manual clean-choice letters'
			})	
		}
		const currentValue=this.state.value
		let newText = currentValue.replace(regexMatchWhitespace, '')
		newText = newText.replace(regexMatchChoiceLabels,'')
		newText = newText.replace(regexMatchWhitespace, '')
		this.savePreviousState(currentValue)
		this.updateTextValue(newText)
		this.detectAutoclean(newText)
		this.showImportQuestionsNotification('cleanChoiceLetters')
	}

	onPaste(event) {
		if(window.analytics){
			window.analytics.track('Import Questions Action',{type:'paste'})	
		}
		this.clearNotification()
    event.preventDefault()//prevent pasting
    this.savePreviousState(this.state.value)
		let newText
		if (window.clipboardData && window.clipboardData.getData) { // IE
			newText = window.clipboardData.getData('Text')
		} else if (event.clipboardData && event.clipboardData.getData) {
			newText = event.clipboardData.getData('text/plain')
		}
		newText = newText.replace(regexMatchWhitespace, '') 
		newText = newText.replace(regexMatchBlankLines,'')
		if(window.analytics){
			const lines =newText.split(/\r\n|\n|\r/)
			const lineCount=lines.length
			window.analytics.track('Import Questions Paste',{
				pastedContent:newText,
				lineCount:lineCount
			})	
		}
		// merged modified pasted text with the existing text
		const el = event.target
		const cursorPosStart = el.selectionStart
		const cursorPosEnd = el.selectionEnd
		const v = el.value
		const textBefore = v.substring(0, cursorPosStart)
		const textAfter  = v.substring(cursorPosEnd, v.length)
		let mergedText = textBefore + newText + textAfter
		mergedText=mergedText.trim()
		el.value=mergedText
		this.updateTextValue(mergedText)
		this.detectAutoclean(mergedText)
		const hasKahootFormatting=this.detectKahootFormatting(mergedText)
		if(hasKahootFormatting){
			this.savePreviousState(mergedText)
			const cleanedText=this.removeKahootFormatting(mergedText)
			this.updateTextValue(cleanedText)
			this.detectAutoclean(cleanedText)
			this.showImportQuestionsNotification('kahoot')
		}
		this.setState({activeQuestionIndex:0})
		el.selectionStart=0
		el.selectionEnd=0
	}

	showImportQuestionsNotification(notificationType,searchTerm){
		const timestamp=new Date()
		this.setState({showImportQuestionsNotification:true,importQuestionsNotificationType:notificationType,importQuestionsNotificationTimestamp:timestamp,importQuestionsNotificationSearchTerm:searchTerm})
	}

	clearNotification(){
		this.setState({showImportQuestionsNotification:false,importQuestionsNotificationType:null,importQuestionsNotificationTimestamp:null,importQuestionsNotificationSearchTerm:null})
	}

	detectAutoclean(text){
		const questionNumbersDetected = (regexMatchQuestionNumbers).test(text)
		const choiceLettersDetected= (regexDetectChoiceLabels).test(text)
		const bulletPointsDetected= (regexMatchBulletPoints).test(text)
		let canAutoclean=false
		if(questionNumbersDetected || choiceLettersDetected || bulletPointsDetected){
			canAutoclean=true
		}
		this.setState({canAutoclean:canAutoclean,
					activeQuestionIndex:0,
					bulletPointsDetected:bulletPointsDetected,
					choiceLettersDetected:choiceLettersDetected,
					questionNumbersDetected:questionNumbersDetected
				})	
	}

	autoclean(text){
		let newText = text.replace(regexMatchWhitespace, '')
		newText = newText.replace(regexMatchQuestionNumbers,'')
		newText = newText.replace(regexMatchChoiceLabels,'')	
		newText = newText.replace(regexMatchBulletPoints, '')
		newText=newText.replace(regexMatchWhitespace, '')
		return newText
	}

	detectKahootFormatting(value){
		let hasKahootFormatting=includes(value,'This is a correct answer')
		const regexMatchKahootPollQuestionLabel = new RegExp('([0-9]* - poll)','gmi')
		if(!hasKahootFormatting){
			hasKahootFormatting=regexMatchKahootPollQuestionLabel.test(value)
		}
		return hasKahootFormatting
	}


	removeKahootFormatting(text){
		//TODO clean all this up
		if(window.analytics){
			window.analytics.track('Import Questions Action',{
									type:'Kahoot autoclean'
			})	
		}
		let {choicesObject}=this.state
		const {choicesPerQuestion}=this.state

		//remove labels may accidentaly copy
		const regexMatchKahootExtraLabels = new RegExp('(hide answers)|(show answers)|(questions \\([0-9]*\\))','gmi') //20 sec and 2-Quiz
		const regexMatchKahootSecLabels = new RegExp('([0-9]* sec)','gmi') //20 sec and 2-Quiz
		const regexMatchKahootQuestionLabels = new RegExp('([0-9]* - quiz)|([0-9]* - true or false)','gmi')
		const regexMatchKahootSlideQuestionLabel = new RegExp('([0-9]* - slide)','gmi')
		const regexMatchKahootPollQuestionLabel = new RegExp('([0-9]* - poll)','i')
		const regexMatchKahootPuzzleQuestionLabel = new RegExp('([0-9]* - Puzzle)','i')
		
		let newText = text.replace(regexMatchKahootSecLabels, '')
		newText = newText.replace(regexMatchKahootExtraLabels, '')
		newText=newText.replace(regexMatchWhitespace, '') 
		newText=newText.replace(regexMatchBlankLines, '') 
		newText=newText.trim()
	
		let lines =newText.split(/\r\n|\n|\r/)
		let linesClean=[]
		let trueLineCount=0
		let questionCount=1
		let questionHasCorrectChoice=false //only allow one correct choice per question
		const lineCountPerQuestion = choicesPerQuestion+1
		let isSlideQuestion=false
		let isPuzzleQuestion=false

		lines.map((line,i)=>{
			const lineIsKahootQuestionLabel=regexMatchKahootQuestionLabels.test(line)
			const lineIsKahootPollLabel=regexMatchKahootPollQuestionLabel.test(line)
			if((regexMatchKahootSlideQuestionLabel).test(line)){
				isSlideQuestion=true
			}
			if((regexMatchKahootPuzzleQuestionLabel).test(line)){
				isPuzzleQuestion=true
			}
			else if(lineIsKahootQuestionLabel ||lineIsKahootPollLabel){
				isSlideQuestion=false
				isPuzzleQuestion=false
				if(i!==0){
					if(trueLineCount !==0){ //if not the first line then move onto next question
						const missingLines=(questionCount*lineCountPerQuestion)-trueLineCount
						if(missingLines !==0){
							const missingLineArray=range(missingLines)
							missingLineArray.map(()=>{
								linesClean.push(' ')
								trueLineCount+=1
								return null
							})
						}
						questionCount+=1
						questionHasCorrectChoice=false
					}
				}
				if(lineIsKahootPollLabel){
					choicesObject[questionCount-1]={
						'A':false,
						'B':false,
						'C':false,
						'D':false,
						'isSurvey':true
					}
				}
			}
			else if(line.match('This is a wrong answer')){
			}
			else if(line.match('This is a correct answer')){
				if(!questionHasCorrectChoice){
					const questionLine= (trueLineCount -((questionCount-1)*lineCountPerQuestion))
					let correctChoice
					if(questionLine===2){
						correctChoice='A'
					}else if(questionLine===3){
						correctChoice='B'
					}else if(questionLine===4){
						correctChoice='C'
					}else if (questionLine===5){
						correctChoice='D'
					}
					choicesObject[questionCount-1]={
						'A':correctChoice==='A',
						'B':correctChoice==='B',
						'C':correctChoice==='C',
						'D':correctChoice==='D',
					}
					questionHasCorrectChoice=true
				}
			}
			else{	
				if(!isSlideQuestion && !isPuzzleQuestion){
					trueLineCount+=1
					linesClean.push(line)	
					if((trueLineCount-(lineCountPerQuestion*(questionCount-1)))===(lineCountPerQuestion+1)){
						questionCount+=1
						questionHasCorrectChoice=false
					}
				}
			}
			return null
		})
		this.setState({choicesObject:choicesObject,activeQuestionIndex:0})
		const cleanText=linesClean.join('\r\n')
		return cleanText
	}

  bodyStringToHtml(string){
    let html=''
    let paragraphs = string.split(/\r\n|\r|\n/gi)
    paragraphs.forEach((paragraph)=>{
      html+=`<p>${paragraph}</p>`
    })
    return html
  }
  
  bodyStringToBodySource(string){
    let source=[]
    let paragraphs = string.split(/\r\n|\r|\n/gi)
    paragraphs.forEach((paragraph)=>{
      let sourceObj={
        "type":"paragraph"
        }
        if(paragraph && paragraph.length!==0){ //no empty text nodes
          sourceObj.content=[{type:'text',text:paragraph}]
        }
      source.push(sourceObj)
    })   
    return source
  }

	parseTextIntoQuestions(text){
		let textToParse=text
		if(this.state.autocleanOnImport){
			textToParse=this.autoclean(text)
		}
		const lines =textToParse.split(/\r\n|\n|\r/)
		let questions=[]
		const {choicesObject,choicesPerQuestion} = this.state
		const questionTemplate={
			'body': '',
			'bodyHtml':'',
			'bodySource':'',
			'bodyOverMaxCharacters':false,
			'bodyCharacterCount':0,
			'xLayout':null,
			'template':'bodyLeft',
			'layout':'bodyLeft',
			'image':'',
			'choices':[]
		}

		let currentQuestion=Object.assign({},questionTemplate)
		let currentQuestionIndex=0
		let choices=[]
		const lineCountPerQuestion = choicesPerQuestion+1
		
		lines.map((line,i)=>{
			if(currentQuestionIndex<MAX_QUESTION_COUNT){
			if(i % lineCountPerQuestion === 0){
				//currentQuestion.body=line
				currentQuestion.bodyCharacterCount=line.length
				if(line.length>QUESTION_BODY_MAX_LENGTH){
					currentQuestion.body=line.substring(0, QUESTION_BODY_MAX_LENGTH)
					currentQuestion.bodyOverMaxCharacters=true
				}else{
					currentQuestion.body=line
				}
				currentQuestion.bodyHtml=`<p>${currentQuestion.body}</p>`
				//currentQuestion.body=line
			}else{
				const choiceCorrectness=choicesObject[currentQuestionIndex]
				let correct=false
				const questionLine = i % lineCountPerQuestion
				if (!(questionLine>2 && (line ==='\u2063'))){
					if(questionLine===1){
						correct=choiceCorrectness['A']
					}else if(questionLine===2){
						correct=choiceCorrectness['B']
					}else if(questionLine===3){
						correct=choiceCorrectness['C']
					}else if (questionLine===4){
						correct=choiceCorrectness['D']
					}
					const lineLength=line.length
					const choiceOverMaxCharacters=(line.length)>QUESTION_CHOICE_MAX_LENGTH
					const choice={
						'body': line.substring(0, QUESTION_CHOICE_MAX_LENGTH),
						'bodyHtml':line.substring(0, QUESTION_CHOICE_MAX_LENGTH),
						'correct': correct,
						'choiceOverMaxCharacters':choiceOverMaxCharacters,
						'characterCount':lineLength
					}
					choices.push(choice)
				}
			}
			if((i%lineCountPerQuestion ===choicesPerQuestion) || i===(lines.length-1)){
				if(i===(lines.length-1)){
					if(choices.length<choicesPerQuestion){
						let newChoices=choices.slice(0)
						const choiceCorrectness=choicesObject[currentQuestionIndex]
						for (var t = 0; t < (choicesPerQuestion-choices.length); t++) {
      				const choiceIndex=choices.length+t
      				let correct=false
      				if(choiceIndex===0){
								correct=choiceCorrectness['A']
      				}else if(choiceIndex===1){
								correct=choiceCorrectness['B']
      				}else if(choiceIndex===2){
								correct=choiceCorrectness['C']
      				}else if(choiceIndex===3){
								correct=choiceCorrectness['D']
      				}		
      				const emptyChoice={
								body:'',
								bodyHtml:'',
								correct: correct,
								choiceOverMaxCharacters:false,
								characterCount:0
							} 
								newChoices.push(emptyChoice)
    					} 
						choices=newChoices
					}	
				}
				currentQuestion.choices=choices
				const randId='00000000'.replace(/0/g,function(){return (~~(Math.random()*16)).toString(16)})
				currentQuestion.questionId=randId
				questions.push(currentQuestion)
				currentQuestion=Object.assign({},questionTemplate)
				choices=[]
				currentQuestionIndex+=1
			}
		}
		return null
		})
		if(questions.length>this.props.remainingQuestionsInSetLimit){
			this.setState({contentOverFreeLimit:true})
		}else{
			this.setState({contentOverFreeLimit:false})
		}
		return questions.slice(0,this.props.remainingQuestionsInSetLimit)
	}

	parseTextIntoQuestionsClean(text){ //applies choice count and character limits
		const {choicesObject,choicesPerQuestion} = this.state
		const lineCountPerQuestion = choicesPerQuestion+1
		let textToParse=text
		if(this.state.autocleanOnImport){
			textToParse=this.autoclean(text)
		}
		const lines =textToParse.split(/\r\n|\n|\r/)
		let questions=[]
		//let newQuestions=[]
		let promises=[]
		let currentQuestion=Object.assign({},QUESTION_TEMPLATE)
		let currentQuestionIndex=0
		let choices=[]
		
		lines.forEach((line,i)=>{
			if(currentQuestionIndex<MAX_QUESTION_COUNT){
			if(i % lineCountPerQuestion === 0){
				if(line===''){
					currentQuestion.body='Click here to edit question'
				}else{
					currentQuestion.body=line.substring(0, QUESTION_BODY_MAX_LENGTH)
				}
				currentQuestion.bodyHtml=`<p>${currentQuestion.body}</p>`
				currentQuestion.bodySource=this.bodyStringToBodySource(currentQuestion.body)

			}else{
				const choiceCorrectness=choicesObject[currentQuestionIndex]
				let correct=false
				const questionLine = i % lineCountPerQuestion
				if (!(questionLine>2 && (line ==='\u2063'))){
					if(questionLine===1){
						correct=choiceCorrectness['A']
					}else if(questionLine===2){
						correct=choiceCorrectness['B']
					}else if(questionLine===3){
						correct=choiceCorrectness['C']
					}else if (questionLine===4){
						correct=choiceCorrectness['D']
					}

				let choice={
					'body': line.substring(0, QUESTION_CHOICE_MAX_LENGTH),
					'correct': correct,
				}
				choice.bodyHtml=choice.body
				let choiceBodySource=[]
			       if(choice.body&&choice.body.length!==0){
			        choiceBodySource=[{
			          type:'text',
			          text:choice.body
			        }]
			       }    
				choice.bodySource=choiceBodySource
				choice.media=null
				choices.push(choice)
			}
			}
			if((i%lineCountPerQuestion ===choicesPerQuestion) || i===(lines.length-1)){
				if(i===(lines.length-1)){
					if(choices.length<choicesPerQuestion){
						let newChoices=choices.slice(0)
						const choiceCorrectness=choicesObject[currentQuestionIndex]
						for (var t = 0; t < (choicesPerQuestion-choices.length); t++) {
      				const choiceIndex=choices.length+t
      				let correct=false
      				if(choiceIndex===0){
								correct=choiceCorrectness['A']
      				}else if(choiceIndex===1){
								correct=choiceCorrectness['B']
      				}else if(choiceIndex===2){
								correct=choiceCorrectness['C']
      				}else if(choiceIndex===3){
								correct=choiceCorrectness['D']
      				}		
							const emptyChoice={
								body:'',
								correct: correct,
								bodyHtml:'',
								bodySource:[],
								media:null
							} 
							newChoices.push(emptyChoice)
    				} 
						choices=newChoices
					}	
				}
				currentQuestion.choices=choices
				const randId='00000000'.replace(/0/g,function(){return (~~(Math.random()*16)).toString(16)})
				currentQuestion.questionId=randId
				questions.push(currentQuestion)
				currentQuestion=Object.assign({},QUESTION_TEMPLATE)
				choices=[]
				currentQuestionIndex+=1
			}
		}			
		})

		questions.forEach((question)=>{
			promises.push(calculateMeasurements(question,this.fontSizeDiv))
		})
		if(window.analytics){
			const autocleanOnImport= this.state.autocleanOnImport
			const questionsInTextAreaCount=questions.length
			const importedQuestions=questions.slice(0,this.props.remainingQuestionsInSetLimit)
			const questionsImportedCount=importedQuestions.length
			let testGroup=null
			const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
			if(setLimitTestGroup){
				testGroup=setLimitTestGroup
			}
			window.analytics.track('Import Questions Import',{
								autocleanOnImport:autocleanOnImport,
								questionsImportedCount:questionsImportedCount,
								questionsInTextAreaCount:questionsInTextAreaCount,
								choicesPerQuestion:choicesPerQuestion,
								textAreaContent:text,
								importedQuestions:importedQuestions,
								'test_group_set_limit':testGroup
			})	
		}
		return Promise.all(promises) 			
	  			.then((questions) => {
	  				return questions.slice(0,this.props.remainingQuestionsInSetLimit)
	  			})	
	} 

	importQuestions(){
		this.setState({submitLoading:true})
		this.parseTextIntoQuestionsClean(this.state.value).then((parsedQuestions)=>{
		const nowDate=new Date()
		if(window.analytics){
			window.analytics.track('Import Questions Action',{
									type:'import'
								})	
		}
	
		if(this.props.setId ==='newSet'){
			let parentFolder=null
			let repo=null
			if(typeof window.parentFolder === 'string'){//Prevent invalid folder value
				parentFolder=window.parentFolder
			}
			if(typeof window.repo === 'string'){//Prevent invalid folder value
				repo=window.repo
			}

			let set=richSetTemplate()
			
			const requestData = {
				...set,
				folder:parentFolder,
				repo:repo,
				questions:parsedQuestions,
				clientModified: nowDate,
				userCreated:nowDate,
				lastEditedAt:nowDate	
 			}

			return this.props.createNewSet(requestData).then((response) => {
				this.props.history.push(`./${response.id}`)
				this.props.hideModal()
				this.props.reloadEditor()
				if(response.repo){
					this.props.updateSetLock(response.id)
					this.setState({userLockedSet:true})
					this.setState({submitLoading:false})
				}
			})
				.catch(error => {
					this.setState({unableToSave:true})
					this.setState({submitLoading:false})
				})
		}
		else{
			let setQuestions=this.props.currentSet.questions
			let replaceCurrentQuestions=false
			if(setQuestions.length===1){
				if(isBlankQuestion(setQuestions[0])){
					replaceCurrentQuestions=true
				}
			}
			if(replaceCurrentQuestions){
				setQuestions=parsedQuestions
			}else{
				setQuestions=setQuestions.concat(parsedQuestions)
			}

		
			let newSet=Object.assign({},this.props.currentSet)
			newSet.questions=setQuestions

			newSet.clientModified=nowDate
			return this.props.updateSetContent(newSet,newSet.id).then((response)=>{
				if(response==='failed'){
					this.setState({unableToSave:true})
					this.setState({submitLoading:false})
				}else{
					this.setState({submitLoading:false})
					this.props.reloadEditor()
					this.props.hideModal()

				}	
			})
		}
	})
	}

	hideModal(){
		if(window.analytics){
			window.analytics.track('Import Questions Action',{
						type:'cancel'
			})	
			const {value}=this.state
			const hasContent=value!==''
			window.analytics.track('Cancel Import Questions',{
							hasContent:hasContent,
							content:value
						})	
		}
		this.props.hideModal()
	}

	openHelpVideo(){
		if(window.analytics){
			window.analytics.track('Import Questions Action',{
								type:'open help video'
			})	
		}
		this.setState({showHelpVideoModal:true})
	}



	render() {
		const {remainingQuestionsInSetLimit}=this.props

		return (
			<div className='featureModalContainer'>
				{this.state.showOnboarding &&
					<ImportQuestionsOnboardingModal 
						closeOnboarding={()=>{this.setState({showOnboarding:false})}}
						toggleDoNotShowOnboardingAgain={this.toggleDoNotShowOnboardingAgain}
						doNotShowAgain={this.state.doNotShowAgain}
					/>
				}
				{this.state.showFeedbackModal &&
					<ImportQuestionsFeedbackModal 
						closeModal={()=>{this.setState({showFeedbackModal:false})}}
						showNotification={this.props.showNotification}
						service={this.props.service}
					
					/>
				}

				{this.state.showHelpVideoModal  &&
					<HelpVideoModal videoUrl="https://www.youtube-nocookie.com/embed/0iLZ7fSYu0s" closeHelpVideoModal={()=>{this.setState({showHelpVideoModal:false})}}/>
				}
				<div ref={c => {this.fontSizeDiv = c}} />

				<ImportQuestionsModal 
					questionsInSetLimit={this.props.questionsInSetLimit}
					browser={browser}
					remainingQuestionsInSetLimit={remainingQuestionsInSetLimit}
					openFeedbackModal={()=>{this.setState({showFeedbackModal:true})}}
					onPaste={this.onPaste}
					undoLastChange={this.undoLastChange} 
					setCorrectAnswer={this.setCorrectAnswer} 
					choicesObject={this.state.choicesObject} 
					maxQuestionCount={MAX_QUESTION_COUNT} 
					hideModal={this.hideModal} 
					value={this.state.value} 
					onChange={this.onChange} 
					removeEmbeddedQuestionNumbers={this.removeEmbeddedQuestionNumbers} 
					removeEmbeddedChoiceLetters={this.removeEmbeddedChoiceLetters}
					removeBulletPoints={this.removeBulletPoints}
					isKahootFormat={this.state.isKahootFormat}
					removeKahootFormatting={this.removeKahootFormatting}
					importQuestions={this.importQuestions}
					questionsPreview={this.state.questionsPreview}
					matchCase={this.state.matchCase}
					toggleMatchCase={()=>{this.setState({matchCase:!this.state.matchCase})}}
					deleteLineWithMatch={this.state.deleteLineWithMatch}
					toggleDeleteLineWithMatch={()=>{this.setState({deleteLineWithMatch:!this.state.deleteLineWithMatch})}}
					findAndRemove={this.findAndRemove}
					setChoicesPerQuestion={this.setChoicesPerQuestion}
					choicesPerQuestion={this.state.choicesPerQuestion}
					questionPreviewTimestamp={this.state.questionPreviewTimestamp}
					setToSurvey={this.setToSurvey}
					setToGraded={this.setToGraded}
					canAutoclean={this.state.canAutoclean}
					autoclean={this.autoclean}
					getLineNumber={this.getLineNumber}
					activeQuestionIndex={this.state.activeQuestionIndex}
					canUndo={this.state.previousStateArray.length>0}
					questionNumbersDetected={this.state.questionNumbersDetected}
					choiceLettersDetected={this.state.choiceLettersDetected}
					bulletPointsDetected={this.state.bulletPointsDetected}
					submitLoading={this.state.submitLoading}
					autocleanOnImport={this.state.autocleanOnImport}
					toggleAutocleanOnImport={this.toggleAutocleanOnImport}
					showHelpVideoModal={this.openHelpVideo}
					contentOverFreeLimit={this.state.contentOverFreeLimit}
					showImportQuestionsNotification={this.state.showImportQuestionsNotification}
					importQuestionsNotificationType={this.state.importQuestionsNotificationType}
					clearImportQuestionsNotification={this.clearNotification}
					importQuestionsNotificationTimestamp={this.state.importQuestionsNotificationTimestamp}
					importQuestionsNotificationSearchTerm={this.state.importQuestionsNotificationSearchTerm}
				/>				
			</div>
		)
	}
} 


function mapStateToProps(state,ownProps) {
	const currentSet=ownProps.currentSet
	const questionsInSetLimit=state.planRestrictions.questionsInSetLimit
	let remainingQuestionsInSetLimit=MAX_QUESTION_COUNT
	if(questionsInSetLimit){
		if(currentSet){
			remainingQuestionsInSetLimit=questionsInSetLimit-currentSet.questions.length
			if(currentSet.questions.length===1){
				if(isBlankQuestion(currentSet.questions[0])){
					remainingQuestionsInSetLimit+=1
				}
			}
		}else{
			remainingQuestionsInSetLimit=questionsInSetLimit
		}
	}

	return {
		questionsInSetLimit:questionsInSetLimit,
		remainingQuestionsInSetLimit:remainingQuestionsInSetLimit,
		service:state.service
	}
}

export default withRouter(connect(
	mapStateToProps,
	{updateSetContent,createNewSet,updateSetLock,showNotification}
)(ImportQuestionsContainer))
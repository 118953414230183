import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset } from 'redux-form'
import filter from 'lodash/filter'
import DocumentTitle from 'react-document-title'
import { uploadProfileImageToCloudinary } from '../../actions/cloudinary'
import { addPassword, updateUser } from '../../actions/user'
import { fetchInvoices, fetchUpcomingInvoice, fetchBilling } from '../../actions/billing'
import { fetchDiscounts } from '../../actions/discounts'
import { cancelService } from '../../actions/service'
import { logout } from '../../actions/session'
import { showNotification, hideNotification } from '../../actions/notifications'
import {
  showPaypalEmailModal,
  showAddLicenseKeyModal,
  showSwitchPlanModal,
  showUpdateGoogleModal,
  showChangePasswordModal,
  showUpdateEmailModal,
  showDowngradePlanModal,
  showBillingInfoModal,
  showExternalSigninAuthModal,
  showAddPasswordModal,
  showUpdateNameModal,
  showUpdateUsernameModal,
  showUpdatePaymentInfoModal,
  showDeleteAccountModal,
  showDataOwnerInfoModal,
} from '../../actions/modals'
import AccountPage from '../../components/account/AccountPage'
import getUserDataOwners from '../../utils/getUserDataOwners'

const MAX_FILE_SIZE = 20000000 // for profile picture

class AccountContainer extends Component {
  constructor() {
    super()
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.handleRemoveProfileImage = this.handleRemoveProfileImage.bind(this)
    this.state = {
      uploadingImage: false,
    }
  }

  UNSAFE_componentWillMount() {
    this.props.fetchBilling()
    this.props.fetchInvoices()
    this.props.fetchUpcomingInvoice()
    this.props.fetchDiscounts()
  }

  componentDidMount() {
    if (window.analytics) {
      window.analytics.page('Account')
    }
  }

  componentWillUnmount() {
    this.props.hideNotification()
  }

  handleFileUpload(e) {
    const fileList = e.target.files
    const file = fileList[0]
    if (file && (file.size > MAX_FILE_SIZE)) {
      this.props.showNotification('Max image upload is 20mb', 'Cannot upload image', 'destroy')
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (event) => {
        this.setState({ uploadingImage: true })
        this.props.uploadProfileImageToCloudinary(event.target.result).then((response) => {
          const nowDate = new Date()
          const { user } = this.props
          const { version } = response
          const requestData = {
            ...user,
            image: `v${version}`,
            clientModified: nowDate,
          }
          this.props.updateUser(requestData, user.id).then(() => {
            this.setState({ uploadingImage: false })
            this.props.showNotification('Profile Picture', 'Added', 'create')
          })
        })
      }
    }
  }

  handleRemoveProfileImage() {
    const nowDate = new Date()
    const { user } = this.props
    const requestData = {
      ...user,
      image: '',
      clientModified: nowDate,
    }
    return this.props.updateUser(requestData, user.id).then(() => {
      this.props.showNotification('Profile Picture', 'Removed', 'destroy')
    })
  }

  render() {
    let isDiscountPeriod = false
    const discountPeriodStartDate = process.env.REACT_APP_DEFAULT_COUPON_START_DATE
    const discountPeriodEndDate = process.env.REACT_APP_DEFAULT_COUPON_END_DATE
    const currentDate = new Date()
    const startDate = new Date(discountPeriodStartDate)
    const endDate = new Date(discountPeriodEndDate)

    if (startDate < currentDate && endDate > currentDate) {
      isDiscountPeriod = true
    }

    return (
      <DocumentTitle title='Account - Plickers'>
        <div className='page page--defaultLayout notranslate'>

          <div className='page-leftPanel' />
          <div className='page-centerPanel'>
            {this.props.service && (
            <AccountPage
              showAddLicenseKeyModal={this.props.showAddLicenseKeyModal}
              isDiscountPeriod={isDiscountPeriod}
              service={this.props.service}
              user={this.props.user}
              updateUserName={this.updateUserName}
              updateUserEmail={this.updateUserEmail}
              updateUserPassword={this.updateUserPassword}
              signinMethods={this.props.signinMethods}
              setBanner={this.setBanner}
              logout={this.props.logout}
              showExternalSigninAuthModal={this.props.showExternalSigninAuthModal}
              showAddPasswordModal={this.props.showAddPasswordModal}
              showUpdateNameModal={this.props.showUpdateNameModal}
              billingInfo={this.props.billing}
              showUpdatePaymentInfoModal={this.props.showUpdatePaymentInfoModal}
              showBillingInfoModal={this.props.showBillingInfoModal}
              showDowngradePlanModal={this.props.showDowngradePlanModal}
              showUpdateEmailModal={this.props.showUpdateEmailModal}
              showChangePasswordModal={this.props.showChangePasswordModal}
              showUpdateGoogleModal={this.props.showUpdateGoogleModal}
              upcomingInvoice={this.props.upcomingInvoice}
              handleFileUpload={this.handleFileUpload}
              handleRemoveProfileImage={this.handleRemoveProfileImage}
              uploadingImage={this.state.uploadingImage}
              hasClaimedSummerCoupon={this.props.hasClaimedSummerCoupon}
              showSwitchPlanModal={this.props.showSwitchPlanModal}
              historySetPolls={this.props.historySetPolls}
              historyQuestionPolls={this.props.historyQuestionPolls}
              meta={this.props.meta}
              showUpdateUsernameModal={this.props.showUpdateUsernameModal}
              publishedRepoCount={this.props.publishedRepoCount}
              totalFollowerCount={this.props.totalFollowerCount}
              showPaypalEmailModal={this.props.showPaypalEmailModal}
              showDeleteAccountModal={this.props.showDeleteAccountModal}
              showDataOwnerInfoModal={this.props.showDataOwnerInfoModal}
              userDataOwners={this.props.userDataOwners}
            />
            )}

          </div>
          <div className='page-rightPanel' />
        </div>
      </DocumentTitle>
    )
  }
}

function bindActionsToDispatch(dispatch) {
  return {

    ...bindActionCreators(
      {
        logout,
        reset,
        showAddPasswordModal,
        addPassword,
        showExternalSigninAuthModal,
        showNotification,
        hideNotification,
        showUpdateNameModal,
        fetchBilling,
        showUpdatePaymentInfoModal,
        showBillingInfoModal,
        cancelService,
        showDowngradePlanModal,
        showUpdateEmailModal,
        showChangePasswordModal,
        showDeleteAccountModal,
        showUpdateGoogleModal,
        fetchInvoices,
        fetchUpcomingInvoice,
        uploadProfileImageToCloudinary,
        updateUser,
        fetchDiscounts,
        showSwitchPlanModal,
        showAddLicenseKeyModal,
        showUpdateUsernameModal,
        showPaypalEmailModal,
        showDataOwnerInfoModal,
      },
      dispatch,
    ),
    resetForm: (formName) => dispatch(reset(formName)),
  }
}

function mapStateToProps(state) {
  const { discounts, user, dataOwners } = state
  let hasClaimedSummerCoupon = false
  discounts.map((discount) => {
    if (discount.coupon.code === 'SUMMER100') {
      hasClaimedSummerCoupon = true
    }
    return null
  })

  let publishedRepoCount = 0
  let totalFollowerCount = 0
  const authorRepos = filter(state.repos, (repo) => {
    if (repo.role === 'author') {
      return repo
    }
  })
  publishedRepoCount = authorRepos.length
  authorRepos.map((repo) => {
    const repoFollowers = state.reposFollowerCount[repo.id]
    if (repoFollowers) {
      totalFollowerCount += repoFollowers
    }

    return null
  })

  return {
    sections: state.sections,
    user,
    signinMethods: state.signinMethods,
    service: state.service,
    billing: state.billing,
    upcomingInvoice: state.upcomingInvoice,
    discounts,
    hasClaimedSummerCoupon,
    historySetPolls: state.historySetPolls,
    historyQuestionPolls: state.historyQuestionPolls,
    meta: state.meta,
    userDataOwners: getUserDataOwners(user, dataOwners),
    publishedRepoCount,
    totalFollowerCount,
  }
}

export default connect(mapStateToProps, bindActionsToDispatch)(AccountContainer)

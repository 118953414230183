import React from 'react'
import EditorIcon from '../../../misc/EditorIcons'
import {deleteQuestionImage} from '../../../../prosemirror/utils/editorActions/deleteQuestionImage'
import slideLayoutDefinitions from '../../../../staticData/slideLayoutDefinitions.js'

class QuestionImageReactComponent extends React.Component{  

	constructor(props) {
		super(props)
		this.setImageFillType=this.setImageFillType.bind(this)
		this.onPlaceholderImageLoad=this.onPlaceholderImageLoad.bind(this)

		let imageFillType='fit'
		if(props.imageFill){
			imageFillType='fill'
		}

		this.state = { 
			imageFillType:imageFillType,   
			cloudinaryImageHasLoaded:false,
			placeholderWidth:0,
			placeholderHeight:0
		}
	}

	setImageFillType(type){
		this.props.setImageFillType(type)
		this.setState({imageFillType:type})
	}

	onPlaceholderImageLoad(){
		const img = document.getElementById(`placeholder-img-${this.props.nodeId}`)
		if(img){
			this.setState({placeholderWidth:img.naturalWidth,placeholderHeight:img.naturalHeight})
		}
	}

 
	render(){ 
		const {deliveryDimensions,placeholderImage,deliveryUrl,template,questionMediaDynamicHeight,slideWidth}=this.props 
		const {cloudinaryImageHasLoaded,imageFillType,placeholderWidth,placeholderHeight}=this.state
		const displayImageWidth=deliveryDimensions.w
		const displayImageHeight=deliveryDimensions.h
		const slideScale = slideWidth / 1280
		const inverseScale = 1 / slideScale     // prevent and other elements icons getting too big
		const ceilingInverseScale = 1.75 
		const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)

		let imageContainerWidth = 0
		let imageContainerHeight = 0 
		if(slideLayoutDefinitions.bodyMediaContainerDimensions[`${template}`]){
			imageContainerWidth=slideLayoutDefinitions.bodyMediaContainerDimensions[`${template}`].width
		}
		if(slideLayoutDefinitions.bodyMediaContainerDimensions[`${template}`]){
			imageContainerHeight = slideLayoutDefinitions.bodyMediaContainerDimensions[`${template}`].height
		}
		

		if(template === 'bodyCenterMediaCenter'){
			imageContainerHeight = questionMediaDynamicHeight // pass in
		}

		const intrinsicImageAspect = displayImageWidth / displayImageHeight
		const containerAspect = imageContainerWidth / imageContainerHeight
		const placeholderAspect = placeholderWidth / placeholderHeight
		let fitImageWidth
		let fitImageHeight 
		let fitPlaceholderImageWidth
		let fitPlaceholderImageHeight 

		if(intrinsicImageAspect > containerAspect){//landscape
			fitImageWidth = imageContainerWidth
			fitImageHeight = imageContainerWidth / intrinsicImageAspect
		}

		if(placeholderAspect > containerAspect){//landscape
			fitPlaceholderImageWidth = imageContainerWidth
			fitPlaceholderImageHeight = imageContainerWidth / placeholderAspect
		}

		if(intrinsicImageAspect < containerAspect){//portrait
			fitImageHeight = imageContainerHeight
			fitImageWidth = imageContainerHeight * intrinsicImageAspect
		}

		if(placeholderAspect < containerAspect){//portrait
			fitPlaceholderImageHeight = imageContainerHeight
			fitPlaceholderImageWidth = imageContainerHeight * placeholderAspect
		}

		let fillImageWidth
		let fillImageHeight
		let isPortrait

		if(intrinsicImageAspect > containerAspect){//landscape
			fillImageHeight = imageContainerHeight
			fillImageWidth = imageContainerHeight * intrinsicImageAspect
		}

		if(intrinsicImageAspect < containerAspect){ //portrait
			fillImageWidth = imageContainerWidth
			fillImageHeight = imageContainerWidth / intrinsicImageAspect
			isPortrait = true
		}

		if(placeholderAspect < containerAspect){ //portrait placeholder
			isPortrait = true
		}

		let imageWidth
		let imageHeight
		if(imageFillType === 'fit'){
			imageWidth = fitImageWidth
			imageHeight = fitImageHeight
		}else if(imageFillType === 'fill'){
			imageWidth = fillImageWidth
			imageHeight = fillImageHeight
		}

		//
		// POSITIONING FOR BUTTONS ONTO IMAGE
		//

		let editImageBottomY // used for putting at bottom of image
		let editImageXOffset = 0 // used for centering in narrow images
		let removeImageBtnRightXOffset = 0
		let topLeftBtnLeftXOffset = 0

		if(imageFillType === 'fit'){
			editImageBottomY = imageContainerHeight - fitImageHeight
		}
		if(imageFillType === 'fit' && isPortrait && (template === 'bodyLeftMediaRight' || template === 'bodyLeftMediaRightChoicesMedia')){            
			editImageXOffset = imageContainerWidth - fitImageWidth      
		}
		if(imageFillType === 'fit' && isPortrait && (template === 'bodyLeftMediaRight' || template === 'bodyLeftMediaRightChoicesMedia') && placeholderWidth > 0){
			topLeftBtnLeftXOffset = imageContainerWidth - fitPlaceholderImageWidth
		}
		if(imageFillType === 'fit' && isPortrait && template === 'bodyRightMediaLeft'){      
			editImageXOffset = -1 * (imageContainerWidth - fitImageWidth)                  
		}
		if(imageFillType === 'fit' && isPortrait && template === 'bodyRightMediaLeft' && placeholderWidth === 0){            
			removeImageBtnRightXOffset = imageContainerWidth - fitImageWidth
		}
		if(imageFillType === 'fit' && isPortrait && template === 'bodyRightMediaLeft' && placeholderWidth > 0){
			removeImageBtnRightXOffset = imageContainerWidth - fitPlaceholderImageWidth           
		}      
		if(imageFillType === 'fit' && isPortrait && template === 'bodyCenterMediaCenter' && placeholderWidth === 0){        
			removeImageBtnRightXOffset = 0.5 * (imageContainerWidth - fitImageWidth)
		}
		if(imageFillType === 'fit' && isPortrait && template === 'bodyCenterMediaCenter' && placeholderWidth > 0){        
			removeImageBtnRightXOffset = 0.5 * (imageContainerWidth - fitPlaceholderImageWidth)            
			topLeftBtnLeftXOffset = 0.5 * (imageContainerWidth - fitPlaceholderImageWidth)  
		}
		if(imageFillType === 'fill'){
			editImageBottomY = 0      
		}

		let coverWidth
		let coverHeight   
		const placeholderCoverWidth = fitPlaceholderImageWidth
		const placeholderCoverHeight = fitPlaceholderImageHeight

		// before: needs refactoring for portrait
		// latest: seems to work okay!
		if(imageFillType === 'fit'){
			coverWidth = fitImageWidth
			coverHeight = fitImageHeight
		}else if(imageFillType === 'fill'){
		coverWidth = imageContainerWidth
		coverHeight = imageContainerHeight
		}

		// POSITIONING WITHIN CONTAINER

		let imageLayoutStyle
		if(template === 'bodyLeftMediaRight' || template === 'bodyLeftMediaRightChoicesMedia'){
			imageLayoutStyle = {top: '0px', right: '0px'}
		//	placeholderPositionStyle = {objectPosition: 'top right'}
		}else if(template === 'bodyRightMediaLeft'){
			imageLayoutStyle = {top: '0px', left: '0px'}
			//placeholderPositionStyle = {objectPosition: 'top left'}
		}else if(template === 'bodyCenterMediaCenter'){
			imageLayoutStyle = {top: '0px', margin: 'auto', left: '0px', right:'0px'}
			//placeholderPositionStyle = {objectPosition: 'center center'}
		}

		const imageUploadCompleted = this.props.fileId // this is where media bar becomes active
		return (
			<React.Fragment>                      
           
				{/* PLACEHOLDER IMAGE */}          
				{placeholderImage && !cloudinaryImageHasLoaded &&
					<div style={{width: `${placeholderCoverWidth}px`, height: `${placeholderCoverHeight}px`, ...imageLayoutStyle}}  className='slide-media--imageCover slide-media--imageCover--fit slide-media--imageCover--placeholder'>
						<div style={{width: `${fitPlaceholderImageWidth}px`, height: `${fitPlaceholderImageHeight}px`}} className='slide-media--imageContainer'>             
							<img alt='' onLoad={this.onPlaceholderImageLoad}  draggable="false" id={`placeholder-img-${this.props.nodeId}`} src={placeholderImage} className='slide-media--image' />                                  
						</div>            
					</div>
				}

				{/* REAL IMAGE */}        
				<div onClick={this.props.showEditImageModal}  style={{width: `${coverWidth}px`, height: `${coverHeight}px`, ...imageLayoutStyle}}  className={'slide-media--imageCover ' + (imageFillType ? `slide-media--imageCover--${imageFillType}` :'')}>            
					<div style={{width: `${imageWidth}px`, height: `${imageHeight}px`}} className='slide-media--imageContainer'>             
						<img alt='' onLoad={()=>{this.setState({cloudinaryImageHasLoaded:true})}} draggable="false" src={deliveryUrl} className='slide-media--image' />                                  
					</div>            
				</div>  
          
          
				{/* LOADING SPINNER */}          
				{placeholderImage && !imageUploadCompleted &&
					<div style={{transform: `scale(${UIInverseScale})`, left: `${topLeftBtnLeftXOffset}px`}} className='slide-mediaContainer--image-topLeftContainer'>                                
						<div className='slide-mediaContainer--image-topLeftContainer-spinnerContainer'>
							<div className='slide-mediaContainer--image-topLeftContainer-spinner'/>              
						</div>
					</div>           
				}

				{/* REMOVE IMAGE */}          
				<div style={{transform: `scale(${UIInverseScale})`, right: `${removeImageBtnRightXOffset}px`}} className={'slide-mediaContainer--image-topRightBtnContainer ' + ((placeholderImage && !imageUploadCompleted) ? 'slide-mediaContainer--image-topRightBtnContainer--placeholder' : '')}>
					<button onClick={deleteQuestionImage} className='slide-mediaContainer--image-topRightBtn slide-mediaContainer--image-topRightBtn--removeImage'>
						<EditorIcon name='close' /> 
					</button>
				</div>
                  
          
				{/* FILL IMAGE BUTTON */}       
				{imageUploadCompleted && 
					<div style={{transform: `scale(${UIInverseScale})`, bottom: `${editImageBottomY}px`, left: `${editImageXOffset}px`}} className='slide-mediaContainer--image-editImageBtnContainer'>
						<button onClick={()=>{this.setImageFillType(imageFillType === 'fit' ? 'fill' : 'fit')}} className={'slide-mediaContainer--image-fillTypeBtn slide-mediaContainer--image-fillTypeBtn--fit ' + (imageFillType === 'fill' ? 'slide-mediaContainer--image-fillTypeBtn--active' : 'slide-mediaContainer--image-fillTypeBtn--inactive')}>
							<EditorIcon name='imageFill' />
							Fill Image
						</button>           
					</div>
				}
			</React.Fragment>   
		)
	}
}

export default QuestionImageReactComponent

import React from 'react';
import Icon from '../../../misc/Icons'
import Gallery from '../../../imageGallery/Gallery'

//Regex for accepted GIPHY url formats- there might be more we want to add

const regExp= /^.*((giphy.com\/gifs\/)(.*-))(.*)/ //https://giphy.com/gifs/pbsnature-Oi1692DKlPdNk1uETR https://regex101.com/r/8W3ast/1/
const regExp2=/^.*((giphy.com\/media\/)(.*))(\/giphy\.gif)/ //https://media.giphy.com/media/Oi1692DKlPdNk1uETR/giphy.gif https://regex101.com/r/RJgxuJ/1
const regExp3=/^.*((media.giphy.com\/media\/)(.*))(\/source\.mp4)/ //https://media.giphy.com/media/cbcCA7khn7QJVRESxE/source.mp4 https://regex101.com/r/3E9DWW/1
const regExp4=/^.*((media.giphy.com\/media\/)(.*))(\/giphy\.mp4)/ //https://media.giphy.com/media/cbcCA7khn7QJVRESxE/giphy.mp4 https://regex101.com/r/N6oI1l/1
const regExp5=/^.*((giphy.com\/clips\/)(.*-))(.*)/  //htttps://giphy.com/clips/originals-coffee-morning-good-3eOz7ZDjZMHan2iWLe


export function extractIdFromGiphyUrl(url){
	let match = url.match(regExp)
	let returnValue=false
	if(match && match[4]){
		returnValue=match[4]
	}else{
		match = url.match(regExp2)
		if(match && match[3]){
			returnValue=match[3]
		}else{
			match = url.match(regExp3)
			if(match && match[3]){
				returnValue=match[3]
			}else{
				match = url.match(regExp4)
				if(match && match[3]){
					returnValue=match[3]
				}else{
					match = url.match(regExp5)
					if(match && match[4]){
						returnValue=match[4]
					}
				}  
			}  		    
		}  
	}
	return returnValue
}


//TODO could maybe look at sharing more logic with insert image modal
class EditorInsertGIFModal extends React.Component{  

	constructor(props){
		super()
		this.onInputChange=this.onInputChange.bind(this)
		this.onKeyDown=this.onKeyDown.bind(this)
		this.clearSearch=this.clearSearch.bind(this)
		this.state=({
			searchInput:'',
			image: null // selected gif 
		})
	}

	onInputChange(e){
		this.setState({ searchInput: e.target.value })
		const giphyId=extractIdFromGiphyUrl(e.target.value)
		if(giphyId){
			this.props.insertGifFromId(giphyId)
		}
		if(e.target.value===''){//remove search results if backspace to clear out search term
			this.props.clearSearch()
		}
	}

	onKeyDown(e){
		if (e.keyCode === 13) {
			if(this.state.searchInput){
				this.props.handleNewImageSearch(this.state.searchInput)
			}
		}
		if(e.keyCode === 27) { //escape key
			this.props.closeInsertImageModal()
		}
	}

	clearSearch(e){
		this.setState({searchInput:''})
		this.props.clearSearch()
		const inputField = document.getElementById('gif-search-input-field')
		if(inputField){
			inputField.focus()
		}		
	}

	render(){  	
		const {images,searchTerm,noResults,isSearching} = this.props
		const {searchInput} = this.state
		let searchInputIsEmpty = true
		if(searchInput.length > 0){
			searchInputIsEmpty = false
		}
		const hasImages = images.length > 0
		let showSearchHint=false //show search hint if input is non empty and different from last search term
		if(!searchInputIsEmpty && searchInput!==searchTerm){
			showSearchHint=true
		}
		const showClearButton = (searchInput === searchTerm) && (noResults || hasImages) && isSearching === false

		const showCancelButton = searchInputIsEmpty

		const poweredByGIPHYWebPURL = "https://res.cloudinary.com/plickers-dev/image/upload/resources/giphy_logo.webp"
		const poweredByGIPHYGIFURL = "https://res.cloudinary.com/plickers-dev/image/upload/resources/giphy_logo.gif"
		
		return (	 
				<div className='editor-insertImageModal editor-insertImageModal--gif'>		
					<div className='editor-insertImageModal-searchBar'>
						<input 
							className={'editor-insertImageModal-searchBar-input ' + (showSearchHint ? 'editor-insertImageModal-searchBar-input--showSearchHint' : 'editor-insertImageModal-searchBar-input--hideSearchHint' ) + (isSearching ? ' editor-insertImageModal-searchBar-input--isSearching ' : ' ' ) + (showClearButton ? ' editor-insertImageModal-searchBar-input--showClearButton ' : '' )} 
							placeholder='Search GIPHY or Paste GIPHY Link'
							id='gif-search-input-field'
							value={searchInput}
							autoFocus
							onChange={(e)=>{this.onInputChange(e)} } 
							onKeyDown={this.onKeyDown} 
							autoComplete="off"
						/>	
						<div className='editor-insertImageModal-searchBar-searchBarUI'>
							<div className='editor-insertImageModal-searchBar-searchBarUI-searchIconContainer'>
								<Icon name='magnifying-glass' />
							</div>							
							{showClearButton &&
								<button onClick={this.clearSearch} className='editor-insertImageModal-searchBar-searchBarUI-clearBtn'>
									Clear
								</button>
							}					
							{showCancelButton &&
								<button onClick={this.props.closeInsertImageModal} className='editor-insertImageModal-searchBar-searchBarUI-cancelBtn'>
									Cancel
								</button>
							}													
							{isSearching === false &&
								<button className='editor-insertImageModal-searchBar-searchBarUI-hint' onClick={() =>{this.props.handleNewImageSearch(this.state.searchInput)}}>
									<div className='editor-insertImageModal-searchBar-searchBarUI-hint-searchBtn'>
										Search
									</div>
									<div className='editor-insertImageModal-searchBar-searchBarUI-hint-secondaryLabel'>									
										Press
									</div>
									<div className='editor-insertImageModal-searchBar-searchBarUI-hint-key'>
										Enter <Icon name='enterKey' />
									</div>
									<div className='editor-insertImageModal-searchBar-searchBarUI-hint-secondaryLabel'>									
									 to Search
									</div>									
								</button>
							}
							{isSearching === true &&
								<div className='editor-insertImageModal-searchBar-searchBarUI-searchingHint'>									
									<div className='editor-insertImageModal-searchBar-searchBarUI-searchingHint-spinnerContainer'>
										<div className='editor-insertImageModal-searchBar-searchBarUI-searchingHint-spinner' />
									</div>
								</div>
							}
						</div>
					</div>
		
					<div className={'editor-insertImageModal-results ' + (hasImages ? 'editor-insertImageModal-results--hasImages' : 'editor-insertImageModal-results--noImages')  + (isSearching ? ' editor-insertImageModal-results--isSearching' : '')}>
						{noResults===true &&
							<div className='editor-insertImageModal-results--noResults'>							
								No Results for '{searchTerm}'
							</div>	
						}
						{noResults===false &&
							<div className={'editor-insertImageModal-results-imageGrid' + (this.state.image ? ' editor-insertImageModal-results-imageGrid--selectedImage' : '')}>							 
								<Gallery
								images={images}
								isGif={true}
								enableLightbox={false}
								enableImageSelection={true}
								onSelectImage={this.props.onSelectImage}
								onClickThumbnail={this.props.onSelectImage}
								/>
							</div>
						}

						{images.length>0 && isSearching ===false && images.length < parseInt(this.props.searchResultCount,10) &&				 							 		
							<div>
								<div className='editor-insertImageModal-results-loadMoreContainer'>
									<button className='editor-insertImageModal-results-loadMoreBtn' onClick={() =>{this.props.loadMore()}}>
										Load more results
									</button>
								</div>				 	
							</div>
						}

						{isSearching && images.length>0 &&					
							<div>
								<div className='editor-insertImageModal-results-loadMoreContainer'>					 		
									<button className='editor-insertImageModal-results-loadMoreBtn editor-insertImageModal-results-loadMoreBtn--loading'>
										Loading
									</button>
								</div>						
							</div>
						}

					</div>	
				
				<div className={'editor-insertImageModal-footer' + (isSearching ? ' editor-insertImageModal-footer--searching ' : ' editor-insertImageModal-footer--notSearching ') + (hasImages ? ' editor-insertImageModal-footer--images' : ' editor-insertImageModal-footer--noImages')}>					
					<div className='editor-insertImageModal-footer-giphyLogoContainerWrapper'>			
						<a className='editor-insertImageModal-footer-giphyLogoContainer' href="https://www.giphy.com" target='_blank' rel='noopener noreferrer'>									
							<picture className='editor-insertImageModal-footer-giphyLogo'> 
								<source type="image/webp" srcSet={poweredByGIPHYWebPURL} />
								<img src={poweredByGIPHYGIFURL} alt=''/>           
							</picture>									
						</a>					
					</div>
					<div className='editor-insertImageModal-footer-upload'>
						<input 
							id='file'
							type='file'
							value={''} 
							name='uploadGIF'
							accept={'.gif, .webp'}
							className='editor-insertImageModal-footer-hiddenUploadInput' 
							onChange={(e) =>{this.props.handleSelectFile(e)}}
						/>							     
						<label htmlFor='file' className='editor-insertImageModal-footer-uploadBtn'>
							<div className='editor-insertImageModal-footer-uploadBtn-primary'>
								Upload Animated GIF from Computer...
							</div>
							<div className='editor-insertImageModal-footer-uploadBtn-secondary'>
								Maximum file size: 20mb
							</div>
						</label>
					</div>					
				</div>
			</div>
		)
	}
}

export default EditorInsertGIFModal

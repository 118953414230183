import {updateSectionControl} from '../actions/sectionControl'

export function clearSectionControl(sectionId) {
	const nowDate = new Date()
	const newSectionControlObject={
		controlled:nowDate,
		resumableSetPoll:null
	}
	return updateSectionControl(sectionId,newSectionControlObject)
}

export default function (state = null, action) {
 
	switch (action.type) {
		case 'UPDATE_PREVIEW_ITEM':
			return action.itemId

	case 'LOGOUT':
			return null
		default:
			return state
	}
}

import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'
import findIndex from 'lodash/findIndex'
 
//Higher Order Component with selectable cell/row behaviour
//(select, unselect, active panel item, multiselect, arrow keys to step through table, shift select behavior etc)
//used to wrap Library and Recent pages  

const withSelectableCells = (BaseComponent) => {

	class HOC extends Component {
		constructor(props) {
			super(props)
			this.addMultiSelectItem=this.addMultiSelectItem.bind(this)
			this.removeMultiSelectItem=this.removeMultiSelectItem.bind(this)
			this.resetState=this.resetState.bind(this)
			this.handleSelectRow=this.handleSelectRow.bind(this)
			this.keyPressNavigation=this.keyPressNavigation.bind(this)
			this.changeActiveItem=this.changeActiveItem.bind(this)
			this.state={
				activePanelItem: {},
				multiSelectEnabled:false,
				multiSelectItems:{},
				pivotForShiftSelect:null,
				lastSelectedItem:null
			}
		}

		resetState(){
			this.setState({
				activePanelItem: {},
				multiSelectEnabled: false,
				multiSelectItems: {},
				pivotForShiftSelect:null,
				lastSelectedItem:null,
			})
		}

		addMultiSelectItem(selectedItem){
			this.setState({multiSelectEnabled:true,
				multiSelectItems:{...this.state.multiSelectItems,[selectedItem.id]:selectedItem}})		
		}

		removeMultiSelectItem(selectedItem) {
			let newMultiselectItems=Object.assign({},this.state.multiSelectItems)
			delete newMultiselectItems[selectedItem.id]
			this.setState({
				multiSelectItems: newMultiselectItems
			})
			if(this.state.activePanelItem.id===selectedItem.id){					
				if(Object.keys(newMultiselectItems).length!==0){
					this.setState({activePanelItem:newMultiselectItems[Object.keys(newMultiselectItems)[0]]})	
				}else{
					this.setState({activePanelItem:{}})	
				}
			}
			if(this.state.pivotForShiftSelect.id===selectedItem.id){
				this.setState({pivotForShiftSelect:{}})
			}
		}

		changeActiveItem(newActiveItem){	
			if(newActiveItem){
				let multiSelectItems={}
				if(!isEmpty(newActiveItem)){
					multiSelectItems={[newActiveItem.id]:newActiveItem}
				}
				this.setState({	
					multiSelectEnabled:false,
					multiSelectItems:multiSelectItems,
					activePanelItem: newActiveItem,
					pivotForShiftSelect:newActiveItem,
					lastSelectedItem:newActiveItem
				})	
			}
		}

		handleSelectRow(selectedItem,e,combinedData){ //when click on cell
			e.stopPropagation()
			const {activePanelItem,pivotForShiftSelect,lastSelectedItem}=this.state
			if(e.shiftKey){
				if(pivotForShiftSelect){
					const pivotForShiftSelectIndex= findIndex(combinedData, function (item) {
						return item.id === pivotForShiftSelect.id
					})
					const clickedRowIndex=findIndex(combinedData, function (item) {
						return item.id === selectedItem.id
					})
					const previouslyClickedRowIndex=findIndex(combinedData, function (item) {
						return item.id === lastSelectedItem.id
					})

					let itemsToSelect
					
					if(pivotForShiftSelectIndex<clickedRowIndex){
						itemsToSelect=combinedData.slice(pivotForShiftSelectIndex+1,clickedRowIndex+1)
					}else{
						itemsToSelect=combinedData.slice(clickedRowIndex,pivotForShiftSelectIndex)
					}

					let itemsToUnselect

					if(pivotForShiftSelectIndex<previouslyClickedRowIndex){
						itemsToUnselect=combinedData.slice(pivotForShiftSelectIndex+1,previouslyClickedRowIndex+1)
					}else{
						itemsToUnselect=combinedData.slice(previouslyClickedRowIndex,pivotForShiftSelectIndex)
					}

					let newMultiSelectItems=Object.assign({},this.state.multiSelectItems)

					itemsToUnselect.map((item) => {
						delete newMultiSelectItems[item.id]
						if(item.id===activePanelItem.id){
							this.setState({activePanelItem:{}})
						}
						return null
					})
				
					itemsToSelect.map((item) => {
						newMultiSelectItems[item.id]=item
						return null
					})

					this.setState({
						multiSelectEnabled:true,
						multiSelectItems:newMultiSelectItems,
						lastSelectedItem:selectedItem
					})
				}else if(!pivotForShiftSelect){
					this.changeActiveItem(selectedItem)
					this.setState({pivotForShiftSelect:selectedItem,lastSelectedItem:selectedItem})
				}
			}else if(e.metaKey || e.ctrlKey){ //command key
				const {multiSelectItems} =this.state
				const alreadySelected=multiSelectItems[selectedItem.id] //check if the item is already selected in the multiselect array
				
				if(Object.keys(activePanelItem).length===0 || !activePanelItem){
					this.changeActiveItem(selectedItem)
					this.setState({pivotForShiftSelect:selectedItem,lastSelectedItem:selectedItem})
				}else{
					if(alreadySelected){		
						this.removeMultiSelectItem(selectedItem)
					}else{
						this.addMultiSelectItem(selectedItem)	
						this.setState({
							pivotForShiftSelect:selectedItem,
							lastSelectedItem:selectedItem
						})
					}
				}
			}else if(!e.metaKey && !e.ctrlKey){
				if(selectedItem.id !== activePanelItem.id){
					this.changeActiveItem(selectedItem)
					this.setState({pivotForShiftSelect:selectedItem,lastSelectedItem:selectedItem})
				}else{
					this.resetState() //if the selected row is already active then deselect
				}
			}
		}


		keyPressNavigation(e,allData){
			const {activePanelItem,lastSelectedItem,pivotForShiftSelect}=this.state
			const activeIndex= findIndex(allData, function (item) {
				return item.id === lastSelectedItem.id
			})

			let newSelectedIndex
			if (e.keyCode === 40) { 
				newSelectedIndex=Math.min(activeIndex+1,allData.length-1)				
			}
			else if (e.keyCode === 38) { 
				newSelectedIndex=Math.max(activeIndex-1,0)
			}
			const newSelected=allData[newSelectedIndex]	   
			if(!e.shiftKey){
				this.changeActiveItem(newSelected)
			}
			else if(e.shiftKey){
				const shiftPivotIndex= findIndex(allData, function (item) {
					return item.id === pivotForShiftSelect.id
				})
				// const {multiSelectItems} =this.state
				let newMultiSelectItems=Object.assign({},this.state.multiSelectItems)
				const alreadySelected=newMultiSelectItems[newSelected.id] //check if the item is already selected in the multiselect array
				if(alreadySelected){
					if((e.keyCode===40 && shiftPivotIndex>=newSelectedIndex)||(e.keyCode===38 && shiftPivotIndex<=newSelectedIndex)){
						delete newMultiSelectItems[lastSelectedItem.id]
						this.setState({multiSelectItems:newMultiSelectItems})
						if(lastSelectedItem.id===activePanelItem.id){
							this.setState({activePanelItem:{}})
						}
					}

					this.setState({lastSelectedItem:newSelected})
				}
				else if(!alreadySelected){
					this.addMultiSelectItem(newSelected)	
					this.setState({lastSelectedItem:newSelected})	
				}
			}
		}

		render() {
			const {multiSelectEnabled,multiSelectItems,activePanelItem}=this.state
			let multiSelectContainsFolders=false
			let multiSelectContainsReadOnly=false
			Object.keys(multiSelectItems).map((key) => {
				if(multiSelectItems[key].ancestors){
					multiSelectContainsFolders=true
				}
				if(multiSelectItems[key].readOnly){
					multiSelectContainsReadOnly=true
				}
				return null
			})
			return (
				<BaseComponent
					multiSelectEnabled={multiSelectEnabled}
					multiSelectItems={multiSelectItems}
					activePanelItem={activePanelItem}
					handleSelectRow={this.handleSelectRow}
					resetSelectedCells={this.resetState}
					changeActiveItem={this.changeActiveItem}
					keyPressNavigation={this.keyPressNavigation}
					multiSelectContainsFolders={multiSelectContainsFolders}
					multiSelectContainsReadOnly={multiSelectContainsReadOnly}
					{...this.props}
				/>
			)
		}
	}

	return HOC
}
export default withSelectableCells
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import { fetchOrder,applyOrder } from '../actions/order'
import { fetchService } from '../actions/service'
import Button from '../components/uiKit/Button'
import Icon from '../components/misc/Icons'
import {showNotification} from '../actions/notifications'

//This modal shows when user visits https://www.plickers.com/claim?key=licenseKey
//Modal shows details of order e.g. name of school on group order and gives the user the option to claim a seat 

const urlSearchParams = location => qs.parse(location.search.slice(1))


class ApplyOrderModalContainer extends Component {

	constructor(props){
		super(props)
		this.closeModal=this.closeModal.bind(this)
		this.applyOrder=this.applyOrder.bind(this)
		let licenseKey
		const searchParams=urlSearchParams(props.location)
		if(searchParams){
			licenseKey=searchParams.key
		}
		this.state = {
			licenseKey: licenseKey,
			submitLoading:false,
			failedToFetchOrder:false,
			failedToApplyOrder:false,
			errorMessage:null
		}
	}

	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden'
		this.props.fetchService().then(()=>{
			this.props.fetchOrder(this.state.licenseKey).then((order)=>{
				const {service}=this.props
				if(service && service.pass && service.pass.order===order.id){
					this.closeModal()
				}
			})
			.catch((error) => {
			if(window.analytics){
					window.analytics.track('Error claim membership', {
							method:'Order link',
							error:'Failed to fetch order'
				})}
			this.setState({failedToFetchOrder:true})
		 })
		})
	}

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Claim Membership Link Modal')
		}
	}

	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}

	applyOrder(){
		if(window.analytics){
			window.analytics.track('Click claim membership', {
				method:'Order link'
			})
		}
		this.setState({submitLoading:true})
		if(this.props.order){
			const orderId=this.props.order.id 
			this.props.applyOrder(orderId,this.state.licenseKey).then(()=>{
				 if(window.analytics){
					window.analytics.track('Successfully Claimed Membership', {
							method:'Order link',
				})}
				this.closeModal()
				this.props.showNotification('Plickers Pro has been activated on your account','Successfully joined order –','default')
			})
			.catch((error) => {
				if(window.analytics){
					const status=error.response.status
					window.analytics.track('Error claim membership', {
							method:'Order link',
							error:'Failed to apply order',
							status:status
				})}
				let errorMessage="Error Processing Order: please contact help@plickers.com or your system adminstrator"
				if(error.response.status===409){
					errorMessage="Error Processing Order: the order is already applied to your account "
				}
				else if(error.response.status===422){
					errorMessage="Error Processing Order: the group order is full. Please contact your administrator for additional seats."
				}
				this.setState({submitLoading:false,failedToApplyOrder:true,errorMessage:errorMessage})
			 })
		}
	}

	closeModal(){
		this.props.history.push('/library')
	}

	render() {
		const {order}=this.props
		const {failedToFetchOrder,failedToApplyOrder} = this.state
		let orderOrganization=''
		if(order){
			orderOrganization=order.purchaser.organizationName
		}

		return (
			<div className='applyOrderModalContainer'>
				<div className='applyOrderModal'>					

					{!order && !failedToFetchOrder &&
						<React.Fragment>
							<div className='applyOrderModal--loading-messageContainer'>
								<div className='applyOrderModal--loading-message'>
									Loading Order Details
								</div>
							</div>
						</React.Fragment>
					}

					{failedToFetchOrder &&
						<React.Fragment>
							<div className='applyOrderModal-closeBtn' onClick={this.closeModal}>
								<Icon name='circle-with-close' />
							</div>
							<div className='applyOrderModal--error-h1'>
								Order Details not found
							</div>
							<div className='applyOrderModal--error-h2'>
								Please contact <b>help@plickers.com</b> or your system administrator.
							</div>

							<Button 
								label='Done'
								name='errordone'
								color='blue'
								size='xLarge' 
								onClickFunction={this.closeModal}								
							/>

						</React.Fragment>
					}

				{!failedToFetchOrder && order &&
					<React.Fragment>
						
						<div className='applyOrderModal-closeBtn' onClick={this.closeModal}>
							<Icon name='circle-with-close' />
						</div>

						<div className='applyOrderModal-h1'>
							Take advantage of free Plickers Pro,<br/>paid for by {orderOrganization}
						</div>
						<div className='applyOrderModal-benefitsHeader'>
							What's included with Plickers Pro
						</div>

						<div className='applyOrderModal-benefitsContainer'>
							<div className='applyOrderModal-benefit'>							
								<div className='applyOrderModal-benefit-header'>							
									Unlimited Set Editor
								</div>
								<div className='applyOrderModal-benefit-desc'>							
									Create sets without question limits, and use Instant Images to easily add visuals to your questions.
								</div>
							</div>
							<div className='applyOrderModal-benefit'>							
								<div className='applyOrderModal-benefit-header'>							
									Shared Packs
								</div>
								<div className='applyOrderModal-benefit-desc'>							
									Share sets with your colleagues and build curriculum content together. Invite people as co-editors or viewers.
								</div>
							</div>
							<div className='applyOrderModal-benefit'>							
								<div className='applyOrderModal-benefit-header'>							
									Unlimited Scoresheet
								</div>
								<div className='applyOrderModal-benefit-desc'>							
									Use 90-day and custom date-ranges and get the long-view on class and student performance.
								</div>
							</div>
							<div className='applyOrderModal-benefitsCloudBG'>							
								<svg width="765" height="157" viewBox="0 0 765 157">
									<path d="M15.1380325,20.2494031 C28.7199981,-1.46634244 235.612755,2.56514859 410.618837,2.56514859 C653.733319,2.56514859 728.149411,-12.6543541 754.264981,31.0503694 C763.731677,46.8929993 772.319604,122.018979 754.264981,144.036255 C736.210358,166.053532 514.062989,152.301287 440.595704,153.565149 C293.130881,156.101993 19.1388815,160.328607 6.32810893,144.036255 C-6.21722229,128.081484 1.55606698,41.9651486 15.1380325,20.2494031 Z"/>
								</svg>							
							</div>
						</div>

						<Button 
							label={`Get Plickers Pro with ${orderOrganization}`}
							name='applyorder'
							color='blue'
							size='xLarge' 
							onClickFunction={this.applyOrder}
							submitLoading={this.state.submitLoading}						
						/>

						{!failedToApplyOrder &&						
							<div className='applyOrderModal-smallPrint'>
								If you join the order, we’ll display your basic profile information to your order administrator.
							</div>
						}
						{failedToApplyOrder &&						
							<div className='applyOrderModal-smallPrint applyOrderModal-smallPrint--error'>
								{this.state.errorMessage}
							</div>
						}



						<div onClick={this.closeModal} className='applyOrderModal-noThanks'>						
							No thanks, I don't want to join this order
						</div>
					</React.Fragment>
				}
				</div>
			</div>
		)
	}
}

export default withRouter(connect(
	(state) => ({
		order:state.order,
		service:state.service
	
	}),
	{ fetchOrder,applyOrder,showNotification,fetchService}
)(ApplyOrderModalContainer))
import schema from '../../schema/editorSchema'  
import {setChoiceIndiciesTransaction} from '../setChoiceIndiciesTransaction'

export function addQuestionChoice() {
	const state=window.view.state
	let isSurvey=false
	let activeQuestionNode
	state.doc.nodesBetween(0,state.doc.content.size, (node, pos) => {
		if(node.attrs.activeslide && node.attrs.activeslide===true){
			activeQuestionNode=node
			state.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
				if(questionNode.type.attrs.correct){ 
					if(questionNode.attrs.isSurvey){
						isSurvey=true
					}
				}
			})
		}
	})

	let type = schema.nodes.choice
	let emptyStateType=schema.nodes.choiceMediaEmptyState
	const emptyStateNode=emptyStateType.createAndFill({slideWidth:activeQuestionNode.attrs.slideWidth})
	const node=type.createAndFill({
			isTouched:true,
			correct:false,
			isSurvey:isSurvey,
			template:activeQuestionNode.attrs.template},emptyStateNode)
	let insertPos
	state.doc.nodesBetween(0,state.doc.content.size, (node, pos) => {
		if(node.attrs.activeslide){
			insertPos=pos+node.nodeSize-2
		}  
	})
	let tr=state.tr
	tr.insert(insertPos,node)
	tr=setChoiceIndiciesTransaction(tr)
	tr.setMeta("triggerParseDoc", true) 
	window.view.dispatch(tr)
}
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import qs from 'qs'
import RichStaticSlide from '../components/richStaticSlide/RichStaticSlide'
import { calculateImageDeliveryValues } from '../prosemirror/utils/image/calculateImageDeliveryValues'

// Takes pre rich text question as input (paste into input field)
// Outputs new rich text format question (press "calculate fields button"and migration result
// is logged in console)
// Migration service was used during transition to rich text. May retire this page in the future

const DEFAULT_SLIDE_WIDTH = 1280

function getNewQuestionTemplate(oldTemplate, hasImage) {
  let newTemplate
  if (oldTemplate === 'standard') {
    if (hasImage) {
      newTemplate = 'bodyLeftMediaRight'
    } else {
      newTemplate = 'bodyLeft'
    }
  } else if (oldTemplate === 'center') {
    if (hasImage) {
      newTemplate = 'bodyCenterMediaCenter'
    } else {
      newTemplate = 'bodyCenter'
    }
  } else if (oldTemplate === 'right') {
    newTemplate = 'bodyRightMediaLeft'
  }
  return newTemplate
}

const defaultRotateParams = {
  angle: 0,
  flipH: false,
  filpV: false,
}

const defaultColorAdjustParams = {
  brightness: 0,
  saturation: 0,
  contrast: 0,
}

function getNewMediaObject(cloudinaryID, originalImageDimensions, isAnimatedGif) {
  const mediaObject = {}
  if (!isAnimatedGif) {
    const imageDeliveryValues = calculateImageDeliveryValues(
      cloudinaryID,
      originalImageDimensions,
      null,
      defaultRotateParams,
      defaultColorAdjustParams,
      [],
      [],
    )
    const { deliveryUrl } = imageDeliveryValues
    mediaObject.type = 'image'
    mediaObject.fileId = cloudinaryID
    mediaObject.deliveryUrl = deliveryUrl
    mediaObject.deliveryDimensions = originalImageDimensions
    mediaObject.originalDimensions = originalImageDimensions
    mediaObject.imageFill = false
    mediaObject.crop = null
    mediaObject.rotate = defaultRotateParams
    mediaObject.markup = { markers: [], covers: [] }
    mediaObject.colorAdjust = defaultColorAdjustParams
  } else {
    mediaObject.type = 'animatedGif'
    mediaObject.fileId = cloudinaryID
    mediaObject.host = 'cloudinary'
    mediaObject.stillUrl = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload/pg_1/${cloudinaryID}`
    mediaObject.dimensions = originalImageDimensions
    mediaObject.imageFill = false
  }

  return mediaObject
}

const questionObject = { // for testing
  _id: '555b29db8827130300cd982b',
  modified: '2016-08-07T23:05:29.411Z',
  body: 'If you unfolded your brain, it would cover an or a ......',
  image: '44941b10743aa0c2fec4709094abba34',
  template: 'standard',
  choices: [
    {
      _id: '555b29db8827130300cd982f',
      correct: true,
      body: 'ironing board',
    },
    {
      _id: '555b29db8827130300cd982e',
      correct: false,
      body: 'regular dinner table',
    },
    {
      _id: '555b29db8827130300cd982d',
      correct: false,
      body: 'breadboard',
    },
    {
      _id: '555b29db8827130300cd982c',
      correct: false,
      body: 'the soles of your feet and palms of your hands',
    },
  ],
  isAnimatedGif: false,
}

function bodyStringToHtml(string) {
  let html = ''
  const paragraphs = string.split(/\r\n|\r|\n/gi)
  paragraphs.forEach((paragraph) => {
    html += `<p>${paragraph}</p>`
  })
  return html
}

function bodyStringToBodySource(string) {
  const source = []
  const paragraphs = string.split(/\r\n|\r|\n/gi)
  paragraphs.forEach((paragraph) => {
    const sourceObj = {
      type: 'paragraph',
    }
    if (paragraph && paragraph.length !== 0) { // no empty text nodes
      sourceObj.content = [{ type: 'text', text: paragraph }]
    }
    source.push(sourceObj)
  })
  return source
}

class MigrationServiceContainer extends Component {
  constructor(props) {
    super(props)
    this.calculateFields = this.calculateFields.bind(this)
    this.onCalculateFontSizes = this.onCalculateFontSizes.bind(this)
    this.onCalculateQuestionMediaDynamicHeight = this.onCalculateQuestionMediaDynamicHeight.bind(this)
    this.reset = this.reset.bind(this)
    this.updateMigrationResult = this.updateMigrationResult.bind(this)
    this.state = {
      showSlide: false,
      questionString: JSON.stringify(questionObject),
      questionObject: null,
      slideWidth: DEFAULT_SLIDE_WIDTH,
      key: 0,
    }
    this.bodyFontSize = false
    this.questionMediaDynamicHeight = false
    this.hasImage = false
    this.migrationResult = {}
    this.migrationPromises = []

    // To ensure any segment analytics calls are associated with the slide service user
    // and to prevent segment calls to be anonymous, perform an identify call with
    // the slide service user id when caller provides correct user ID
    // We are reusing the slide service user id for the migration service
    // (no need for separate user).
    if (window.analytics) {
      const session = localStorage.getItem('ls.session')
      const { location } = props
      const providedUserId = qs.parse(location.search, { ignoreQueryPrefix: true }).userId
      if (!session && providedUserId === process.env.REACT_APP_SLIDE_SERVICE_USER_ID) {
        window.analytics.identify(process.env.REACT_APP_SLIDE_SERVICE_USER_ID, {
          email: 'slideService@plickers.com',
          id: process.env.REACT_APP_SLIDE_SERVICE_USER_ID,
        })
      }
    }
  }

  calculateFields() {
    this.migrationResult = {}
    this.bodyFontSize = false
    this.questionMediaDynamicHeight = false
    this.hasImage = false
    const { questionString } = this.state
    if (questionString) {
      const questionObject = JSON.parse(questionString)
      const newFormatObject = { ...questionObject }
      newFormatObject.bodyHtml = bodyStringToHtml(questionObject.body)
      const questionBodySource = bodyStringToBodySource(questionObject.body)
      newFormatObject.bodySource = questionBodySource
      newFormatObject.choices.forEach((choice, i) => {
        newFormatObject.choices[i].bodyHtml = choice.body
        let choiceBodySource = []
        if (choice.body && choice.body.length !== 0) {
          choiceBodySource = [{
            type: 'text',
            text: choice.body,
          }]
        }
        newFormatObject.choices[i].bodySource = choiceBodySource
      })

      // template
      const oldTemplate = questionObject.template || 'standard'
      const hasImage = !!questionObject.image
      const newTemplate = getNewQuestionTemplate(oldTemplate, hasImage)
      console.log(`Old Template: ${oldTemplate}`)
      console.log(`New Template: ${newTemplate}`)

      this.updateMigrationResult('layout', newTemplate)
      newFormatObject.layout = newTemplate

      const newKey = this.state.key + 1
      // images
      if (questionObject.image) {
        this.hasImage = true
        const cloudinaryBucketName = process.env.REACT_APP_CLOUDINARY_CLOUDNAME
        const fullSizeImageUrl = `https://res.cloudinary.com/${cloudinaryBucketName}/image/upload/${questionObject.image}`
        // const fullSizeStaticImageUrl=`https://res.cloudinary.com/${cloudinaryBucketName}/image/upload/pg_2/${questionObject.image}`

        const img = new Image()

        img.onload = function () {
          const originalDimensions = {
            w: img.naturalWidth,
            h: img.naturalHeight,
          }
          console.log(`Image Height: ${img.naturalHeight}`)
          console.log(`Image Width: ${img.naturalWidth}`)
          const imageMediaObject = getNewMediaObject(
            questionObject.image,
            originalDimensions,
            questionObject.isAnimatedGif,
          )
          newFormatObject.media = imageMediaObject
          console.log(`Image media object: ${JSON.stringify(imageMediaObject)}`)
          this.updateMigrationResult('media', imageMediaObject)
          this.setState({ showSlide: true, questionObject: newFormatObject, key: newKey })
        }.bind(this)

        img.onerror = function () {
          // console.log('image load ERROR-------')
          console.log('image load failed try png format')
          // might be a TIf/TIFF try loading .png
          const pngUrl = `https://res.cloudinary.com/${cloudinaryBucketName}/image/upload/${questionObject.image}.png`
          const pngImg = new Image()

          pngImg.onload = function () {
            console.log('loaded png image')
            const originalDimensions = {
              w: pngImg.naturalWidth,
              h: pngImg.naturalHeight,
            }
            console.log(`Image Height: ${pngImg.naturalHeight}`)
            console.log(`Image Width: ${pngImg.naturalWidth}`)
            const imageMediaObject = getNewMediaObject(questionObject.image, originalDimensions, questionObject.isAnimatedGif)
            newFormatObject.media = imageMediaObject
            console.log(`Image media object: ${JSON.stringify(imageMediaObject)}`)
            this.updateMigrationResult('media', imageMediaObject)
            this.setState({ showSlide: true, questionObject: newFormatObject, key: newKey })
          }.bind(this)
          pngImg.onerror = function () {
            console.log('image load ERROR-------')
          }
          pngImg.src = pngUrl
        }.bind(this)
        img.src = fullSizeImageUrl
      } else {
        console.log("Question doesn't have an image")
        this.setState({ showSlide: true, questionObject: newFormatObject, key: newKey })
      }
    }
  }

  migrationFinished(migrationResult) {
    let finished = false
    if (Object.prototype.hasOwnProperty.call(migrationResult, 'bodyFontSize') &&
      Object.prototype.hasOwnProperty.call(migrationResult, 'choiceFontSize') &&
      Object.prototype.hasOwnProperty.call(migrationResult, 'questionMediaDynamicHeight') &&
      Object.prototype.hasOwnProperty.call(migrationResult, 'layout')
    ) {
      if (this.hasImage) {
        if (Object.prototype.hasOwnProperty.call(migrationResult, 'media')
        ) {
          finished = true
        } else {
          finished = false
        }
      } else {
        finished = true
      }
    }
    return finished
  }

  updateMigrationResult(field, value) {
    this.migrationResult[field] = value
    if (this.migrationFinished(this.migrationResult)) {
      console.log('migration finished')
      const newQuestionObject = this.state.questionObject
      const measurements = {
        bodyFS: this.migrationResult.bodyFontSize,
        choiceFS: this.migrationResult.choiceFontSize,
      }
      if (this.migrationResult.layout === 'bodyCenterMediaCenter') {
        measurements.questionMediaDynamicHeight = this.migrationResult.questionMediaDynamicHeight
      }
      this.migrationResult.measurements = measurements
      this.migrationResult.bodyHtml = newQuestionObject.bodyHtml
      this.migrationResult.bodySource = newQuestionObject.bodySource
      this.migrationResult.choices = newQuestionObject.choices
      console.log(`Migration Result: ${JSON.stringify(this.migrationResult)}`)
    }
  }

  onCalculateFontSizes(perfectCombo) {
    if (!this.bodyFontSize) {
      console.log(`bodyFontSize: ${perfectCombo.bodyFontSize}`)
      console.log(`choiceFontSize: ${perfectCombo.choiceFontSize}`)
      this.bodyFontSize = true
      this.updateMigrationResult('bodyFontSize', perfectCombo.bodyFontSize)
      this.updateMigrationResult('choiceFontSize', perfectCombo.choiceFontSize)
    }
  }

  onCalculateQuestionMediaDynamicHeight(questionMediaDynamicHeight) {
    if (!this.questionMediaDynamicHeight) {
      this.questionMediaDynamicHeight = true
      console.log(`questionMediaDynamicHeight: ${questionMediaDynamicHeight}`)
      this.updateMigrationResult('questionMediaDynamicHeight', questionMediaDynamicHeight)
    }
  }

  reset() {
    this.bodyFontSize = false
    this.questionMediaDynamicHeight = false
    this.setState({
      showSlide: false,
      questionString: '',
      questionObject: null,
      slideWidth: DEFAULT_SLIDE_WIDTH,
    })
  }

  fieldCalculationFinished() {
    console.log('field calculation finished')
  }

  render() {
    const {
      questionString,
      showSlide,
      key,
      questionObject,
      slideWidth,
    } = this.state
    const hideSlide = false
    return (

      <div className='slideServicesPageContainer'>
        <div className='slideServices-form'>
          Question:
          {' '}
          <input id='question' value={questionString} onChange={(e) => { this.setState({ questionString: e.target.value }) }} />
          <button id='generateSlideBtn' onClick={this.calculateFields}>calculate fields</button>
          <button id='resetBtn' onClick={this.reset}>reset</button>
        </div>

        <div className='slideServicesPageContainer-languageSet'>
          <div className='slideServicesPageContainer-languageSet-korean slideServicesPageContainer-languageSet--body'>
            Korean: 제5항에 의하여 법률이 확정된 후 또는 제4항에 의한 확정법률이 정부에 이송된 후 5일 이내에 대통령이 공포하지 아니할 때에는 국회의장이 이를 공포한다. 이 헌법공포 당시의 국회의원의 임기는 제1항에 의한 국회의 최초의 집회일 전일까지로 한다. 모든 국민은 근로의 권리를 가진다. 국회는 국무총리 또는 국무위원의 해임을 대통령에게 건의할 수 있다.
          </div>
          <div className='slideServicesPageContainer-languageSet-japanese'>
            Japanese: 恋ノイロ図銃誌み士一はばゆ年長よ売56旨勲7割セ年無申びひイン収79平ヌ不録安エテ示努ネエム量芸ホナリ早査ア市9雑ぴせてえ監町京径べぞ。締メヘツ区雑がーフ間方こぶト著試年ノウ大終制治べご則伝スゅ委分じやの権保ミセヒ携団クエセ活持オヒヘ江問ヒ標属マス方米地ずま試細ッ通素母実壌でづそぎ。
          </div>
          <div className='slideServicesPageContainer-languageSet-chinese'>
            Simplified Chinese: 責本欲著適全政間泉出登技。負授高面気点間込材神記更集社囲旨様芸化毎。港売結阜導登味花再変写濃置露購少効月。藤写率泣盤度銀内選未初境泰振帳日就拒傷。所暮択図所阪健属講選測郎変化無成豊米虫。方月能早載勝論研六新代成健年権線。創評表決店陸高戦必作治聞物間名飛入返質上。合能不彰暮提議沢道加浜道帆止中清。
          </div>
          <div className='slideServicesPageContainer-languageSet-chinese'>
            Traditional Chinese: 的然的日在，部詩消又！使出的車政年委行寫成那南知德？明懷城在子們……分人達面理，將利報香界書那，位地治，導簡大共市金……中有最境式舞直都他，響不春面就道天腦體因他可麼種年想們中還景感：計狀直要蘭們後找身人朋易不離聲；竟同裡健白新有還絕人樹這是為業委來不來過；覺保話時的看月？中年有話時；細間氣大、題地爭決，南己到吸無說但德可己的，本從讀例半出一海成平
          </div>
          <div className='slideServicesPageContainer-languageSet-chinese'>
            Hong Kong Chinese(?): 前深水埗區議員劉家衡的議員辦事處於去年，因張貼寫有「藍絲與狗不得內進」的告示，引發有人上門示威。其中劉的妻子「搣時潘」潘書韻疑與上門示威者爆發衝突，並向示威者潑漂白水，與劉的助理李譯喬分別被控襲擊致造成身體傷害及刑事損壞共7罪。案件今在西九龍法院提堂，押後至8月25日再訊。潘今未有現身法庭，法庭發出拘捕令。李則獲准以5000港元保釋，期間不得離港及須交出所有...         踏入美國企業業績期，本周多間藍籌包括高盛、摩根大通、美國銀行等會公佈業績，市場觀望成績，美股反覆，杜指昨晚開市下跌，但迅即回升，一度見35014點高位；納指則跌10點報14691點；標指升7點報4377點。
          </div>
        </div>

        {showSlide && !hideSlide && (
        <div className='slideServices-slideContainer'>
          <RichStaticSlide
            key={key}
            slideGenerationFinished={this.slideGenerationFinished}
            question={{ ...questionObject, xLayout: null }}
            slideWidth={slideWidth}
            mediaIsPlayable={false}
            mediaIsZoomable={false}
            onCalculateFontSizes={this.onCalculateFontSizes}
            onCalculateQuestionMediaDynamicHeight={this.onCalculateQuestionMediaDynamicHeight}
          />
        </div>
        )}

      </div>

    )
  }
}

export default withRouter(connect(
  () => ({}),
  { },
)(MigrationServiceContainer))

import request from 'superagent' // used for postWithProgress cloudinary request for uploading sound files
import store from './store'

import { clearUserAndSendToLogin } from './actions/logoutInternal'
import { getHttpStatusText } from './utils/httpStatusTexts'

const API = process.env.REACT_APP_PLICKERS_API_ENDPOINT
const APICloudinary = process.env.REACT_APP_CLOUDINARY_API_ENDPOINT
const APIImageSearch = 'https://api.bing.microsoft.com/v7.0/images/search'
const APIGifSearch = 'https://api.giphy.com/v1/gifs/search'
const APIGifFetch = 'https://api.giphy.com/v1/gifs'

const API_VERSION = '5.0.0'
const WEB_APP_VERSION = '58.62' // update this before deploy to prod
const DEFAULT_BATCH_SIZE = 100

function headers() {
  const session = JSON.parse(localStorage.getItem('ls.session'))
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-version': API_VERSION,
    'x-auth-token': session?.token || '',
    'Cache-Control': 'max-age=0, private, must-revalidate',
    Pragma: 'no-cache',
    'x-client-info': `{"platform":"Web","appVersion":"${WEB_APP_VERSION}"}`,
  }
}

function studentHeaders(token) { // for e-learning scan helper
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-version': API_VERSION,
    'x-auth-token': token,
    'Cache-Control': 'max-age=0, private, must-revalidate',
    Pragma: 'no-cache',
  }
}
function patchHeaders(token) { // for e-learning scan helper
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-auth-token': token,
    'X-HTTP-Method-Override': 'PATCH',
  }
}

function parseResponse(response) {
  if (response.status !== 204) { // 204 = No Content
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json().then((json) => {
        if (!response.ok) {
          return Promise.reject(json)
        }
        return json
      })
    }
    return response.text().then((text) => text)
  }
}

function parseBatchedResponses(responses) {
  const parsedResponses = []
  const promises = []
  responses.map((response) => {
    promises.push(parseResponse(response).then((json) => {
      json.map((item) => {
        parsedResponses.push(item)
        return null
      })
      return null
    }))
    return null
  })
  return Promise.all(promises).then(() => parsedResponses)
}

async function checkStatus(response) {
  if (response.ok) {
    return response
  }
  if (response && response.status) {
    const error = new Error(getHttpStatusText(response.status))
    error.response = response
    error.response.textString = await response.text()
    throw error
  } else {
    const error = new Error('Invalid fetch response')
    throw error
  }
}

function queryString(params) {
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')
  return `${query.length ? '?' : ''}${query}`
}

function fetchRetryPlickersApi(relativeUrl, options) {
  const delay = 100 // delay in ms before retry request
  const limit = 2 // max number request repeats
  return new Promise(((resolve, reject) => {
    const wrappedFetch = function wrappedFetch(limit) {
      fetch(`${API}${relativeUrl}`, {
        ...options,
        credentials: 'include',
      })
        .then((response) => {
          if (response.status !== 503) { // only retry on 503 errors
            // if the request is not for creating a session and
            // the response is 401, log user out
            const path = relativeUrl.split('?')[0]
            const isCreateSession = options.method === 'POST' &&
              (path === '/sessions' || path === '/sessions/external-signin')
            if (!isCreateSession && response.status === 401) {
              clearUserAndSendToLogin(store.dispatch)
            }
            resolve(response)
          } else if (limit > 0) {
            retry(limit)
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          if (limit > 0) {
            retry(limit)
          } else {
            reject(error)
          }
        })
    }
    function retry(n) {
      if (process.env.REACT_APP_ENV !== 'production') {
        console.log(`************Retry request: attempt ${limit - n + 1} *******************`)
      }
      setTimeout(() => {
        wrappedFetch(n - 1)
      }, delay)
    }
    wrappedFetch(limit)
  }))
}

export default {
  batchedFetch(relativeUrl, batchCount) {
    let totalCount
    let offset = 0
    const batchSize = batchCount || DEFAULT_BATCH_SIZE
    const fetchResponses = []
    return fetchRetryPlickersApi(`${relativeUrl}&&limit=${batchSize}&&offset=${offset}`, {
      method: 'GET',
      headers: headers(),
    })
      .then((response) => {
        totalCount = response.headers.get('x-total-count')
        fetchResponses.push(response)
        offset += batchSize
        const fetchPromises = []
        const batchCount = Math.ceil(totalCount / batchSize)
        for (let batchI = 2; batchI <= batchCount; ++batchI) {
          const fetchBatchPromise = fetchRetryPlickersApi(`${relativeUrl}&&limit=${batchSize}&&offset=${offset}`, {
            method: 'GET',
            headers: headers(),
          })
          fetchPromises.push(fetchBatchPromise)
          offset += batchSize
        }

        return Promise.all(fetchPromises)
          .then((responses) => {
            Promise.all(responses.map((response) => fetchResponses.push(response)))
            return fetchResponses
          })
      })
      .then(parseBatchedResponses)
  },
  // only load some of results, used for e.g. admin pages dev
  limitedBatchedFetch(relativeUrl, batchSize, maxFetchCount) {
    let totalCount
    let offset = 0
    const fetchResponses = []
    return fetchRetryPlickersApi(`${relativeUrl}&&limit=${batchSize}&&offset=${offset}`, {
      method: 'GET',
      headers: headers(),
    })
      .then((response) => {
        totalCount = Math.min(response.headers.get('x-total-count'), maxFetchCount)
        fetchResponses.push(response)
        offset += batchSize
        const fetchPromises = []
        const batchCount = Math.ceil(totalCount / batchSize)
        for (let batchI = 2; batchI <= batchCount; ++batchI) {
          const fetchBatchPromise = fetchRetryPlickersApi(`${relativeUrl}&&limit=${batchSize}&&offset=${offset}`, {
            method: 'GET',
            headers: headers(),
          })
          fetchPromises.push(fetchBatchPromise)
          offset += batchSize
        }

        return Promise.all(fetchPromises)
          .then((responses) => {
            Promise.all(responses.map((response) => fetchResponses.push(response)))
            return fetchResponses
          })
      })
      .then(parseBatchedResponses)
  },

  fetch(relativeUrl, params = {}) {
    return fetchRetryPlickersApi(`${relativeUrl}${queryString(params)}`, {
      method: 'GET',
      headers: headers(),
    })
      .then(checkStatus)
      .then(parseResponse)
  },
  post(relativeUrl, data) {
    const body = JSON.stringify(data)
    return fetchRetryPlickersApi(relativeUrl, {
      method: 'POST',
      headers: headers(),
      body,
    })
      .then(checkStatus)
      .then(parseResponse)
  },
  postCloudinary(form) { // for image uploads
    return fetch(`${APICloudinary}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
      },
      body: form,
    })
      .then(checkStatus)
      .then(parseResponse)
  },

  // https://github.com/cloudinary/cloudinary-react/blob/master/samples/photo_album/src/components/PhotosUploader.js
  /* eslint-disable camelcase */
  postCloudinaryWithProgress(
    file,
    onProgress,
    api_key,
    timestamp,
    signature,
    public_id,
    upload_preset,
  ) {
    return request.post(`${APICloudinary}`)
      .set({ Accept: 'application/json, text/plain, */*' })
      .field('file', file)
      .field('api_key', api_key)
      .field('timestamp', timestamp)
      .field('signature', signature)
      .field('public_id', public_id)
      .field('upload_preset', upload_preset)
      .on('progress', (progress) => { onProgress(progress) })
      .then((response) => response.body)
  },
  /* eslint-enable camelcase */

  fetchImageSearchResults(searchTerm, count, offset) { // Bing image search
    const searchParams = new URLSearchParams({
      q: encodeURIComponent(searchTerm),
      count,
      offset,
      safeSearch: 'Strict',
    })

    return fetch(
      `${APIImageSearch}?${searchParams.toString()}`,
      {
        method: 'GET',
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_BING_IMAGE_SEARCH_SUBSCRIPTION_KEY,
        },
      },
    )
      .then(checkStatus)
      .then(parseResponse)
  },
  fetchGifSearchResults(searchTerm, limit, offset) { // GIPHY search
    return fetch(`${APIGifSearch}?q=${searchTerm}&api_key=${process.env.REACT_APP_GIPHY_API_KEY}&limit=${limit}&offset=${offset}&rating=g `, {
      method: 'GET',
      headers: {
      },
    })
      .then(checkStatus)
      .then(parseResponse)
  },
  fetchGifById(gifId) {
    return fetch(`${APIGifFetch}/${gifId}?api_key=${process.env.REACT_APP_GIPHY_API_KEY}`, {
      method: 'GET',
      headers: {
      },
    })
      .then(checkStatus)
      .then(parseResponse)
  },
  put(relativeUrl, data) {
    const body = JSON.stringify(data)
    return fetchRetryPlickersApi(relativeUrl, {
      // return fetch(`${API}`, {
      method: 'PUT',
      headers: headers(),
      body,
    })
      .then(checkStatus)
      .then(parseResponse)
  },
  studentPut(relativeUrl, data, token) { // E-Learning scan helper
    const body = JSON.stringify(data)
    return fetchRetryPlickersApi(relativeUrl, {
      // return fetch(`${API}`, {
      method: 'PUT',
      headers: studentHeaders(token),
      body,
    })
      .then(checkStatus)
      .then(parseResponse)
  },
  studentPatch(relativeUrl, data, token) { // E-Learning scan helper
    const body = JSON.stringify(data)
    return fetchRetryPlickersApi(relativeUrl, {
      // return fetch(`${API}`, {
      method: 'POST',
      headers: patchHeaders(token),
      body,
    })
      .then(checkStatus)
      .then(parseResponse)
  },
  delete(relativeUrl, data) {
    const body = JSON.stringify(data)
    return fetchRetryPlickersApi(relativeUrl, {
      method: 'DELETE',
      headers: headers(),
      body,
    })
      .then(checkStatus)
  },
  synchronousDelete(url) { // sync request used for deleting lock when close set editor tab
    const session = JSON.parse(localStorage.getItem('ls.session'))
    let token = ''
    if (session) {
      token = session.token
    }
    const xhr = new XMLHttpRequest()
    xhr.open('DELETE', `${API}${url}`, false)
    xhr.setRequestHeader('x-api-version', API_VERSION)
    xhr.setRequestHeader('x-auth-token', token)
    xhr.send(null)
  },
  fetchWithTimeout(url, params = {}, timeoutMs = 1000) {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), timeoutMs)
    return fetch(`${API}${url}${queryString(params)}`, {
      method: 'GET',
      headers: headers(),
      signal: controller.signal,
    })
      .then((response) => {
        clearTimeout(timeoutId)
        return response
      })
      .then(checkStatus)
      .then(parseResponse)
  },
}

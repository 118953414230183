import React from 'react'

const TOOLTIP_IMAGE_BASE_URL = 'https://assets.plickers.com/react-assets/images/seteditor-tooltips/'
const FOX_IMAGE_URL = `${TOOLTIP_IMAGE_BASE_URL}0800eaf61043a374fb9ad7bb55afbaef.png`
const TURTLE_IMAGE_URL = `${TOOLTIP_IMAGE_BASE_URL}48610e66f1426bedd789fdbd40ae7454.png`
const FLAMINGO_IMAGE_URL = `${TOOLTIP_IMAGE_BASE_URL}28a57449adeeb8596bc73ae88d6721c5.png`
const LION_IMAGE_URL = `${TOOLTIP_IMAGE_BASE_URL}1dd3fba774fcdc7c78a56764eae012d2.png`
const FROG_IMAGE_URL = `${TOOLTIP_IMAGE_BASE_URL}e3fe3efb3a58ea5a557996c698c07b4a.png`
const PIG_IMAGE_URL = `${TOOLTIP_IMAGE_BASE_URL}98d28dc0a445e0674de37fd4dcd21d84.png`
const WHALE_IMAGE_URL = `${TOOLTIP_IMAGE_BASE_URL}f44463b94259471630a6932fdb941872.png`
const CHICK_IMAGE_URL = `${TOOLTIP_IMAGE_BASE_URL}dcb761ede1e72f73a2706fbc414c2d24.png`

class EditorCanvasBottomBarTemplateTooltip extends React.Component {
  render() {
    const { templateTooltipTemplate } = this.props
    return (
      <React.Fragment>
        {templateTooltipTemplate === 'bodyLeft' && (
        <div className='editor-canvas-bottomBar-templateBtn-tooltip editor-canvas-bottomBar-templateBtn-tooltip--bodyLeft'>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-title'>
            Default
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-subtitle'>
            Easy-to-read question and choices.
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-choices'>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
          </div>
        </div>
        )}
        {templateTooltipTemplate === 'bodyLeftMediaRight' && (
        <div className='editor-canvas-bottomBar-templateBtn-tooltip editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRight'>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-title'>
            Default
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-subtitle'>
            Easy-to-read question and choices.
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-media'>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-media-imageContainer'>
              <img src={FOX_IMAGE_URL} alt='' />
            </div>
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-choices'>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
          </div>
        </div>
        )}
        {templateTooltipTemplate === 'bodyCenter' && (
        <div className='editor-canvas-bottomBar-templateBtn-tooltip editor-canvas-bottomBar-templateBtn-tooltip--bodyCenter'>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-title'>
            Center
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-subtitle'>
            Great for large text and for videos, sounds, and wide images.
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-choices'>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
          </div>
        </div>
        )}
        {templateTooltipTemplate === 'bodyCenterMediaCenter' && (
        <div className='editor-canvas-bottomBar-templateBtn-tooltip editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterMediaCenter'>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-title'>
            Center
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-media'>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-media-imageContainer'>
              <img src={TURTLE_IMAGE_URL} alt='' />
            </div>
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-choices'>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
          </div>
        </div>
        )}
        {templateTooltipTemplate === 'bodyRightMediaLeft' && (
        <div className='editor-canvas-bottomBar-templateBtn-tooltip editor-canvas-bottomBar-templateBtn-tooltip--bodyRightMediaLeft'>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-title'>
            Right
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-subtitle'>
            Mix it up or make your media the focus.
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-media'>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-media-imageContainer'>
              <img src={FLAMINGO_IMAGE_URL} alt='' />
            </div>
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-choices'>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice' />
          </div>
        </div>
        )}
        {templateTooltipTemplate === 'bodyCenterChoicesMedia' && (
        <div className='editor-canvas-bottomBar-templateBtn-tooltip editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterChoicesMedia'>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-title'>
            Media Choices
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-subtitle'>
            Instead of text, make your choices images, GIFs, sounds, or videos!
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-choices'>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice'>
              <img src={LION_IMAGE_URL} alt='' />
            </div>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice'>
              <img src={FROG_IMAGE_URL} alt='' />
            </div>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice'>
              <img src={PIG_IMAGE_URL} alt='' />
            </div>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice'>
              <img src={WHALE_IMAGE_URL} alt='' />
            </div>
          </div>
        </div>
        )}
        {templateTooltipTemplate === 'bodyLeftMediaRightChoicesMedia' && (
        <div className='editor-canvas-bottomBar-templateBtn-tooltip editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRightChoicesMedia'>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-title'>
            Media Choices
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-media'>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-media-imageContainer'>
              <img src={CHICK_IMAGE_URL} alt='' />
            </div>
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-subtitle'>
            Instead of text, make your choices images, GIFs, sounds, or videos!
          </div>
          <div className='editor-canvas-bottomBar-templateBtn-tooltip-choices'>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice'>
              <img src={LION_IMAGE_URL} alt='' />
            </div>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice'>
              <img src={FROG_IMAGE_URL} alt='' />
            </div>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice'>
              <img src={PIG_IMAGE_URL} alt='' />
            </div>
            <div className='editor-canvas-bottomBar-templateBtn-tooltip-choice'>
              <img src={WHALE_IMAGE_URL} alt='' />
            </div>
          </div>
        </div>
        )}
      </React.Fragment>
    )
  }
}

export default EditorCanvasBottomBarTemplateTooltip

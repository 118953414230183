import React, { Component } from 'react'
import Button from '../../../components/uiKit/Button'

class EditSubmissionStatusModal extends Component {
  	
	render() {
		const {submission}=this.props
		return ( 
			<div className='explorerEditRepoNotesModal '>
				<Button 
					label='Cancel'					
					name='explorerreponotescancel'					
					onClickFunction={this.props.hideModal}
				/>				
				<div className='explorerEditRepoNotesModal-title'>
					current status {submission.status}
				</div>
				
				<br/>
				Set status to
	
					<br/>
				<button onClick={()=>{this.props.updateSubmissionStatus(submission.id,'in-review')}}>in-review</button>
				<br/>
				<button onClick={()=>{this.props.updateSubmissionStatus(submission.id,'hidden')}}>hidden</button>
					<br/>
				<button onClick={()=>{this.props.updateSubmissionStatus(submission.id,'approved')}}>approved</button>
				<br/>
				<button onClick={()=>{this.props.updateSubmissionStatus(submission.id,'canceled')}}>cancelled</button>




				<Button 
					label='Done'
					size='xLarge'
					name='explorerreponotessubmit'
					inputType='submit'
					disabled={this.props.submitLoading}
					submitLoading={this.props.submitLoading} 					
					color={'blue'}
					onClickFunction={this.props.saveNotes}
				/>
			</div>
		)
	}
}

export default EditSubmissionStatusModal
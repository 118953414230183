import React from 'react'
import { DropTarget } from 'react-dnd'
import ItemTypes from '../DnDKit/ItemTypes'
import LibraryRepoGridItem from './LibraryRepoGridItem'
import includes from 'lodash/includes'

const repoGridTarget = {}

function collect(connect, monitor,DropTargetMonitor) {
	const item = monitor.getItem()
	let isDragging=false
	if(item && item.cellItem ){
		isDragging=true
	}	 
	return {
		isDragging:isDragging
	}
}



class LibraryRepoGrid extends React.Component{

	render(){		
		const {isDragging,repos,filterTerm}=this.props
		const repoGridItemHeight = 32 // unfortunate JS fix for height as flexbox (column can't wrap) and css columns have issues (flicker on webkit)
		let repoGridHeight
		let filteredRepos=[]
		repos.map((repo)=>{
			if(includes(repo.name.toLowerCase(),filterTerm.toLowerCase())){
				filteredRepos.push(repo)
			}
			return null
		})

		if(filteredRepos){
			repoGridHeight = Math.ceil(filteredRepos.length / 2) * repoGridItemHeight
		}

		return (		
			<div style={{height: `${repoGridHeight}px`}} className={'library-repoGrid ' + (isDragging ? 'library-repoGrid--isDnDDragging' : '')}>
				{filteredRepos.map((repo)=>{
					return(
						<LibraryRepoGridItem key={repo.id} repo={repo} {...this.props}/>
					)
				})}
				<div className='library-repoGrid-lastLineBlocker' />
			</div>
		)
	}
}




export default DropTarget(ItemTypes.ITEMCELL, repoGridTarget, collect)(LibraryRepoGrid)


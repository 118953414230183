import React from 'react'
import Slider, { Handle } from 'rc-slider'
import 'rc-slider/assets/index.css';



class EditorEditImageModalColorAdjustSlider extends React.Component{  


  render(){  
  	const {value}=this.props
 
	  return (	 
	  	<div className='editor-editImageModal-leftBar-adjustColorPanel-slider'> 		
	  		<div className='editor-editImageModal-leftBar-adjustColorPanel-slider-title'>
	  			{this.props.name}
	  		</div>
	  		<div className='editor-editImageModal-leftBar-adjustColorPanel-slider-value'>
	  			{value}
	  		</div>
	  		<div className='editor-editImageModal-leftBar-adjustColorPanel-slider-sliderContainer'> 		
	  			<div className='editor-editImageModal-leftBar-adjustColorPanel-slider-sliderContainer-centerPoint'/> 		
			  	<Slider 
					onChange={(value)=>{this.props.updateSliderValue(value)}}
					value={value} 
					step={1}
					min={this.props.minValue} 
					max={this.props.maxValue} 
					marks={{0:'0'}}
					defaultValue={this.props.defaultValue} 
	  				handle={ (handleProps) => {
			        return (
			            <Handle { ...handleProps }>
		                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17">  
										   <path fill="#0076FF" id="edit-image-modal-slider-handle-outer" fillRule="nonzero" d="M11,0 L4,0 C1.790861,0 0,1.790861 0,4 L0,9.62012038 C0,10.8374915 0.554384249,11.9886061 1.50620254,12.7475753 L5.66413439,16.0630635 C6.7644536,16.9404457 8.32675782,16.9350493 9.42098968,16.0500868 L13.5153429,12.7387695 C14.4543634,11.9793347 15,10.8363009 15,9.62861683 L15,4 C15,1.790861 13.209139,0 11,0 Z"/>
										   <path fill="#FF2323" id="edit-image-modal-slider-handle-inner" fillRule="nonzero" d="M11,2 C12.1045695,2 13,2.8954305 13,4 L13,9.62861683 C13,10.2324588 12.7271817,10.8039758 12.2576715,11.1836932 L8.16331821,14.4950105 C7.79857426,14.789998 7.27780619,14.7917968 6.91103312,14.499336 L2.75310127,11.1838478 C2.27719212,10.8043632 2,10.2288059 2,9.62012038 L2,4 C2,2.8954305 2.8954305,2 4,2 L11,2 Z"/>
										</svg>
			            </Handle>
			        )
			    }}
	  			/> 
  			</div>
		   </div>
		)
	}
}

export default EditorEditImageModalColorAdjustSlider

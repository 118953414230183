import React from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {generateFrontCURLRequest} from '../../../utils/generateFrontCURLRequest'

class PackReviewDetailPanelEmailPreviewGuidelines extends React.Component{ 

	render(){
		



		return( 
			<React.Fragment>

				{this.props.guideline1Checked &&
					<p>
						<b>Guideline 1: Focused to a topic</b><br/>
						Typically, packs should cover a topic area within a single subject: an entire course is too large in scope, and a single lesson of content is too small. However, provided they are comprehensive, course-review packs will also be considered
					</p>
				}

				{this.props.guideline2Checked &&
					<p>
						<b>Guideline 2: Substantial</b><br/>
						For the given topic area, packs are substantial and complete and contain multiple lessons worth of content. Packs should typically have between 50 and 200 questions.
					</p>
				}

				{this.props.guideline3Checked &&
					<p>
						<b>Guideline 3: Useful to teachers everywhere</b><br/>
						Packs should be easy to understand and use, with a clear pack title and accurate subject and grade-level information. Packs should not reference or require external sources unless they are widely accessible.
					</p>
				}

				{this.props.guideline4Checked &&
					<p>
						<b>Guideline 4: Well-Organized</b><br/>
						Pack contents are well-organized into clearly defined and well-labelled sets for each subtopic. Except in rare cases, folders and independent questions should be avoided.
					</p>
				}

				{this.props.guideline5Checked &&
					<p>
						<b>Guideline 5: High-quality questions</b><br/>
						Questions are clear, rigorous, accurate, consistent, and engaging, with visual aids where helpful.
					</p>
				}

				{this.props.guideline6Checked &&
					<p>
						<b>Guideline 6: Original and meets Content Policy</b><br/>
						Packs contents should be original and meet the Plickers Content Policy, including no objectionable content or copyright infringement.
					</p>
				}				


			</React.Fragment>
		)
	}
}



class PackReviewDetailPanelEmailPreview extends React.Component{ 


	constructor() {
		super()	


		this.state = { 
		curl:"",
		copied:false
	    }
  	}


  	componentDidUpdate(prevProps) {
  		if(prevProps !==this.props){
  			let emailHTML
			const div=document.getElementById('emailContent')
			if(div){
				emailHTML=div.innerHTML
			}
		const curl=generateFrontCURLRequest(this.props.submission,emailHTML)
		this.setState({curl:curl,copied:false})

  		}
	
  	}


	render(){
 
		const {submission,selectedEmailTemplate,guideline1Checked,guideline2Checked,guideline3Checked,guideline4Checked,guideline5Checked,guideline6Checked} = this.props
		const {repo}=submission				

		let countCheckedGuidelines = 0

		if(guideline1Checked){
			countCheckedGuidelines += 1
		}
		if(guideline2Checked){
			countCheckedGuidelines += 1
		}
		if(guideline3Checked){
			countCheckedGuidelines += 1
		}
		if(guideline4Checked){
			countCheckedGuidelines += 1
		}
		if(guideline5Checked){
			countCheckedGuidelines += 1
		}
		if(guideline6Checked){
			countCheckedGuidelines += 1
		}		

		let pluralizedGuideline = 'guideline'
		let pluralizedGuidelineThis = 'this'

		if(countCheckedGuidelines > 1){
			pluralizedGuideline = 'guidelines'
			pluralizedGuidelineThis = 'these'
		}

		let packGuidelinesURL = 'https://www.plickerscreatorfund.com/#guidelines'
		//const curl=generateFrontCURLRequest(submission)

		





		return( 
			<div className='packReview-detailPanel-actionArea-emailTemplater-preview' id="12">
				<div className='packReview-detailPanel-actionArea-emailTemplater-preview-title'>
					Email Preview
				</div>
				{/*
				<div className='packReview-detailPanel-actionArea-emailTemplater-preview-section packReview-detailPanel-actionArea-emailTemplater-preview-section--to'>
					<div className='packReview-detailPanel-actionArea-emailTemplater-preview-section-label'>
						To
					</div>
					<div className='packReview-detailPanel-actionArea-emailTemplater-preview-section-contents'>
						{repo.author.email}
					</div>
				</div>
				*/}	
				<div className='packReview-detailPanel-actionArea-emailTemplater-preview-section packReview-detailPanel-actionArea-emailTemplater-preview-section--subject'>					
					<div className='packReview-detailPanel-actionArea-emailTemplater-preview-section-contents'>
						Plickers Creator Fund: Update on your Pack "{repo.name}"
					</div>					
				</div>
				<div className='packReview-detailPanel-actionArea-emailTemplater-preview-section packReview-detailPanel-actionArea-emailTemplater-preview-section--body'>					
					<div className='packReview-detailPanel-actionArea-emailTemplater-preview-section-contents' id={"emailContent"}>
							{/* Top Section – All the same */}
							<p>Hi {repo.author.firstName},</p>
							<p>Thank you for submitting <a href={`https://${this.props.plickersRepoURL}`} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}}>{repo.name}</a> for early release approval on Plickers.</p>

							

							{selectedEmailTemplate === 'minorChanges' &&
								<React.Fragment>
									<p>We’ve reviewed your pack against the <a href={packGuidelinesURL} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}}>Early Release Guidelines</a>, and we think it’s a fantastic start and close to meeting the guidelines.</p>
									<p>However, there are some changes we’re looking for to get this pack into our early release collection.</p>
									<b>Requested changes:</b>
									<ul>
										<li>[INSERT CUSTOM STUFF HERE]</li>
									</ul>									
									<p>We are only accepting a small percentage of submitted packs for approval and the $100 cash award, and we think you’re close to getting there. If you’re happy to make the changes required, please reply back once you’ve made them and we’ll review the pack again!</p>
								</React.Fragment>
							}

							{selectedEmailTemplate === 'majorChanges' &&
								<React.Fragment>
									<p>We’ve reviewed your pack against the <a href={packGuidelinesURL} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}}>Early Release Guidelines</a>.</p>
									<p>At the moment, your pack doesn’t meet the following {pluralizedGuideline}:</p>
									<PackReviewDetailPanelEmailPreviewGuidelines
										guideline1Checked={guideline1Checked}
										guideline2Checked={guideline2Checked}
										guideline3Checked={guideline3Checked}
										guideline4Checked={guideline4Checked}
										guideline5Checked={guideline5Checked}
										guideline6Checked={guideline6Checked}
									/>
									<b>Initial requested changes:</b>
									<ul>
										<li>[INSERT CUSTOM STUFF HERE]</li>
									</ul>
									<p>We appreciate it will take time to make the changes requested.</p>
									<p>If you’re willing to make these changes, please reply back once you’ve made them and we’ll review the pack in more detail.</p>
									<p>Let me know if you have any questions!</p>
								</React.Fragment>
							}

							{selectedEmailTemplate === 'cancelWithCause' &&
								<React.Fragment>
									{countCheckedGuidelines === 0 &&
										<p>We’ve reviewed your pack against the <a href={packGuidelinesURL} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}}>Early Release Guidelines</a>.</p>
									}
									{countCheckedGuidelines > 0 &&
										<React.Fragment>
											<p>We’ve reviewed your pack against the <a href={packGuidelinesURL} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}}>Early Release Guidelines</a>, and unfortunately your pack doesn’t meet {pluralizedGuidelineThis} {pluralizedGuideline}:</p>
											<PackReviewDetailPanelEmailPreviewGuidelines
												guideline1Checked={guideline1Checked}
												guideline2Checked={guideline2Checked}
												guideline3Checked={guideline3Checked}
												guideline4Checked={guideline4Checked}
												guideline5Checked={guideline5Checked}
												guideline6Checked={guideline6Checked}
											/>
										</React.Fragment>
									}
									<p>[INSERT CUSTOM STUFF]</p>
									<p>At the moment, we can’t see a pathway for this pack being approved for early release. Please feel free to review the guidelines and resubmit your pack when you feel it meets them.</p>
									<p>Thank you again for taking the time to submit your pack, and let me know if you have any questions!</p>
								</React.Fragment>
							}

							{selectedEmailTemplate === 'cancelWithoutCause' &&
								<React.Fragment>
									<p>We’ve reviewed your pack, and at the moment, it does not meet some of the key <a href={packGuidelinesURL} target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}}>Early Release Guidelines</a>.</p>
									<p>Please feel free to review the guidelines and resubmit your pack when you feel it meets them.</p>
									<p>Let me know if you have any questions!</p>
								</React.Fragment>
							}






							
							{/* End Section – All the same */}
							<p>

								{this.props.activePackReviewAccount}
							</p>							
							{/* */}

					</div>	


						<CopyToClipboard text={this.state.curl} onCopy={()=>{this.setState({copied:true})}}>
							<button 
								className={'packReview-detailPanel-actionArea-btn packReview-detailPanel-actionArea-btn--frontButton ' + (this.state.copied ? 'packReview-detailPanel-actionArea-btn--disabled':'')}
								disabled={this.state.copied}
							>								
								{this.state.copied &&
									<React.Fragment>Copied to Clipboard</React.Fragment>
								}
								{!this.state.copied &&
									<React.Fragment>
										<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Lab_flask" x="0px" y="0px" viewBox="0 0 20 20" enable-background="new 0 0 20 20"><path fillRule="evenodd" clipRule="evenodd" d="M16.432,15C14.387,9.893,12,8.547,12,6V3h0.5C12.776,3,13,2.776,13,2.5v-1  C13,1.224,12.776,1,12.5,1h-5C7.224,1,7,1.224,7,1.5v1C7,2.776,7.224,3,7.5,3H8v3c0,2.547-2.387,3.893-4.432,9  C2.917,16.625,1.245,19,10,19S17.083,16.625,16.432,15z M14.815,16.751C14.113,16.961,12.716,17.2,10,17.2s-4.113-0.239-4.815-0.449  c-0.249-0.074-0.346-0.363-0.258-0.628c0.22-0.67,0.635-1.828,1.411-3.121c1.896-3.159,3.863,0.497,5.5,0.497  c1.637,0,1.188-1.561,1.824-0.497c0.774,1.294,1.191,2.451,1.411,3.121C15.161,16.388,15.064,16.676,14.815,16.751z"></path></svg>
										<b>Copy cURL</b>&nbsp;to Clipboard
									</React.Fragment>
								}
							</button>							
						</CopyToClipboard>									
													
				</div>

			</div>				
		)
	}
}


export default PackReviewDetailPanelEmailPreview

export function setChoiceIndiciesTransaction(tr){
	let choiceIndex=0
	let questionChoiceCounts={}
	let questionIndex
	let choiceCount=0

	tr.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if (node.type.name==='choiceGroup') { 		
			if(!questionIndex && questionIndex!==0){
				questionIndex=0
			}else{
				questionChoiceCounts[questionIndex]=choiceCount
				questionIndex+=1
			}
			choiceCount=0
		}
		else if (node.type.name==='choice') {
			choiceCount+=1
		}
	})
	questionChoiceCounts[questionIndex]=choiceCount
	questionIndex=null

	tr.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if (node.type.name==='choiceGroup') { 
			if(!questionIndex && questionIndex!==0){
				questionIndex=0
			}else{
				questionIndex+=1
			}
			choiceIndex=0
		}
		else if (node.type.name==='choiceVideo'||node.type.name==='choiceImage' || node.type.name==='choiceSound'|| node.type.name==='choiceAnimatedGIF') {
			const choiceCount=questionChoiceCounts[questionIndex]
			tr.setNodeMarkup(pos, null, {...node.attrs,questionChoiceCount:choiceCount})
			//tr.step(new SetAttrsStep(questionChildPos,{...questionChildNode.attrs,questionMediaDynamicHeight:questionMediaDynamicHeight}))
		}
		else if (node.type.name==='choice') {
			const choiceCount=questionChoiceCounts[questionIndex]
			tr.setNodeMarkup(pos, null, {...node.attrs,choiceIndex:choiceIndex,questionChoiceCount:choiceCount})
			choiceIndex+=1
		}
		
	})

	return tr
}

import {getActiveQuestion} from '../getActiveQuestion'
import {SetAttrsStep} from '../SetAttrsStep'

export function updateQuestionMediaDynamicHeight(questionMediaDynamicHeight){
	let tr = window.view.state.tr
	const activeQuestion=getActiveQuestion()
	const {questionPos,questionNode}=activeQuestion
	tr.step(new SetAttrsStep(questionPos,{...questionNode.attrs,questionMediaDynamicHeight:questionMediaDynamicHeight}))
	window.view.state.doc.nodesBetween(questionPos,questionPos+questionNode.nodeSize, (questionChildNode, questionChildPos) => {
		if(questionChildNode.type.name==='questionImage' ||questionChildNode.type.name==='questionAnimatedGIF' || questionChildNode.type.name==='questionVideo' || questionChildNode.type.name==='questionSound' ) {
			tr.step(new SetAttrsStep(questionChildPos,{...questionChildNode.attrs,questionMediaDynamicHeight:questionMediaDynamicHeight}))
		}
	})  
	tr.setMeta('addToHistory', false)//can't undo setting media dynamic height
	window.view.dispatch(tr)
}

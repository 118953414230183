import React from 'react';
import EditorIcon from '../../../misc/EditorIcons'
import {formatMillisecondDuration} from '../../../../utils/formatMillisecondDuration'
import slideLayoutDefinitions from '../../../../staticData/slideLayoutDefinitions.js'

class StaticSlideChoiceSoundStatic extends React.Component{  

  componentDidMount(){
    if(this.props.choiceMediaPromiseResolve){ //slide service
      console.log(`choice ${this.props.choiceLetter} sound did mount`)
      this.props.choiceMediaPromiseResolve()
    }
  }

  render(){ 

    const {title,sound, questionChoiceCount} = this.props

    let duration=sound.end-sound.start

    let displayMillisecondGranularity = true
    let displayHourGranularity=false
    if((duration) >1){ // if over 1 second 
      displayMillisecondGranularity = false // don't show millisecond granularity
      if(duration>60*60){
        displayHourGranularity=true
      }
    }

    const mediaWidth = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${questionChoiceCount}Choices`].width
    //const mediaHeight = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${questionChoiceCount}Choices`].height

    const mediaInternalSidePadding = 5

    const soundPlayerWidth = mediaWidth - mediaInternalSidePadding

    // matching to video choices
    let heightBottomTimestampContainer = 83
    if(questionChoiceCount === 2){
      heightBottomTimestampContainer = 81 
    }


    return (

        <div className='slide-choice-media slide-choice-media--sound slide-choice-media--sound--static'>
        
          {/* SOUND ICON  */}
          <div className='slide-choice-media--sound-center'>                                    
            <button disabled={true} className='slide-choice-media--sound-playPauseBtn'>                
              <EditorIcon name='insertSound' /> 
            </button>      
          </div>

          
          <div style={{width: `${soundPlayerWidth}px`, height:`${heightBottomTimestampContainer}px`}}  className='slide-choice-media--sound-bottom'>
            <div className='slide-choice-media--sound-bottom-timestampContainer'>
              <div style={{width: `${soundPlayerWidth - 12}px`}} className='slide-choice-media--sound-initialTitleAndTimestamp'>
                {!this.props.hideSoundTitles &&
                <div className='slide-choice-media--sound-initialTitleAndTimestamp-title'>
                  {title}
                </div>
                }
                <div className='slide-choice-media--sound-initialTitleAndTimestamp-timestamp'>                    
                  {formatMillisecondDuration(duration*1000,displayMillisecondGranularity,displayHourGranularity)}          
                </div>
              </div>
            </div>            
          </div>

         </div>
    )
  }
}

export default StaticSlideChoiceSoundStatic


import {moveToFolder} from '../../utils/moveToFolder'
import {getRepoforId} from '../../utils/getRepoforId'
//https://github.com/react-dnd/react-dnd
const FolderTarget = {
	canDrop(props, monitor) {
		const draggedItem=monitor.getItem().cellItem
		const multiselectItems=props.multiselectItems
		const folderId=props.folder.id
		const containingRepo = getRepoforId(draggedItem.repo)
		if(containingRepo && ( containingRepo.role ==='viewer' || containingRepo.role ==='consumer')){
			return false
		}else if(props.folder.readOnly){
			return false
		}else {
			if(multiselectItems && Object.keys(multiselectItems).length > 1){
				let multiselectContainsSelf = false
				let multiSelectContainsReadonly = false
				Object.keys(multiselectItems).map((key)=>{
					const item= multiselectItems[key]
					if(item.readOnly){
						multiSelectContainsReadonly = true
					}
					if(item.id === folderId){
						multiselectContainsSelf = true
					}
					return null
				})
				if(multiselectContainsSelf || multiSelectContainsReadonly){
					return false
				}else return true

			}else {
				if(props.folder.readOnly){
					return false
				}else if(draggedItem.id ===props.folder.id){
					return false
				}else return true
			}
		}
	},
	drop(props, monitor) {
		const droppedItem=monitor.getItem().cellItem
		const multiselectItems=props.multiselectItems
		let promises=[]
		if(multiselectItems){
			Object.keys(multiselectItems).map((key)=>{
				const item=multiselectItems[key]
				promises.push(moveToFolder(item,props.folder.id))
				return null
			})

			Promise.all(promises).then(function () {
				const itemCount=Object.keys(multiselectItems).length
				let notificationMessage=''
				if(itemCount===1){
					 notificationMessage=`${droppedItem.name || droppedItem.body} to ${props.folder.name}`
				}else{
					notificationMessage=`${itemCount} items to ${props.folder.name}`
				}

				props.showNotification(notificationMessage,'Moved','default')
				props.resetState()
			})
			return
		} 
		else {
			moveToFolder(droppedItem,props.folder.id).then(()=>{
				const notificationMessage=`${droppedItem.name || droppedItem.body} to ${props.folder.name}`
				props.showNotification(notificationMessage,'Moved','default')
				props.resetState()
			})

		}
	}
}

export default FolderTarget


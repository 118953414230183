import React from 'react'

class SyncLiveViewPlayerControlBarSubmitLoading extends React.Component{
	render(){					
		return(				
			<div className='syncLiveView-player-main-controlBar-revealAnswer-submitLoadingContainer'>
				<div className='syncLiveView-player-main-controlBar-revealAnswer-submitLoading'>   
					<div className='syncLiveView-player-main-controlBar-revealAnswer-submitLoading-progressBar'>              
						<div className='syncLiveView-player-main-controlBar-revealAnswer-submitLoading-progressBar-bar'/>              
					</div>	               	                              
					<div className='syncLiveView-player-main-controlBar-revealAnswer-submitLoading-bg'/>              
				</div>
			</div>	
		)
	}
}
export default SyncLiveViewPlayerControlBarSubmitLoading

import React from 'react'
import Icon from '../misc/Icons'
import Button from '../uiKit/Button'

class ArchivedFolderCell extends React.Component{
	render(){
		const {folder,isActive,contextMenuIsOpen,folderIsFocused,handleSingleClick,navigateToFolder}=this.props
		return(			
			<div
				className={'cellRow is--archived' + (isActive ? ' is--active':'')+ (contextMenuIsOpen ? ' has--menu':'')} 
				onClick={() =>{handleSingleClick(folder)}}
				onDoubleClick={() =>{
					if(!folderIsFocused){
						navigateToFolder(folder.id,folder.name)}}}
			>	
				<div className="cellRow-content">

					<div className="cellRow-left">
						<Icon name="folder"/>
					</div>

					<div className="cellRow-name">
						{folder.name}
					</div>
					
					<Button size="small" label="Restore" onClickFunction={()=>{this.props.restoreFolder(folder)}}/>		
				</div>
			</div>
			
		)
	}
}
export default ArchivedFolderCell
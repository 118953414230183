import React, { Component } from 'react'
import gradeLevels from '../../staticData/gradeLevels.js'
import languages from '../../staticData/languages.js' 
import PublishingFlowSelectControl from './publishingFlow/PublishingFlowSelectControl'
import Select from 'react-select'
import 'react-select/dist/react-select.css'

class EditRepoModalEditGradeLevel extends Component {
  

	render() {

		const{selectedGradeLevels}=this.props

		return ( 
			<React.Fragment>			

				<div className='repoDetailsModal-gradeLevel-gradeLevelCheckboxContainer'>					

					{gradeLevels.map((grade)=>{
						const checked= selectedGradeLevels[grade.grade]
						return (							
							<PublishingFlowSelectControl 
								key={grade.grade}
								onSelect={()=>{this.props.selectGradeLevel(grade.grade)}} 
								checked={checked} 
								checkbox 
								label={grade.longLabel} 
								showDescription 
							/>								
						)
					})}
									
				</div>

				<div className='repoDetailsModal-gradeLevel-extraOptionsContainer'>
					<PublishingFlowSelectControl 
						onSelect={this.props.selectHigherEducation} 
						checked={this.props.selectedHigherEducation}  
						checkbox 
						label='Higher Education' 
						description="College-level, Technical College, Vocational" 
						showDescription 
					/>
					
					<PublishingFlowSelectControl 
						onSelect={this.props.selectStaff} 
						checked={this.props.selectedStaff} 
						checkbox label='Staff' 
						description="Training or Professional Development" 
						showDescription 
					/>
				</div>




				<div className='repoDetailsModal-gradeLevel-otherContainer'>
					<PublishingFlowSelectControl  
						onSelect={this.props.selectOtherGradeLevel} 
						checked={this.props.selectedOtherGradeLevel} 
						checkbox 
						label='Other' 
						inputValue={this.props.gradeOtherString} 
						onChangeInput={(e)=>{this.props.setGradeOtherString(e.target.value)}}
						optionalOtherInputBox 
						optionalOtherInputBoxPlaceholder="Who is the intended audience for your pack?"
					/>
				</div>				

				<div className='repoDetailsModal-gradeLevel-language'>
					<div className='repoDetailsModal-gradeLevel-language-title'>
					Language
					</div>
					<Select											
						value={this.props.language} 
						onChange={(option) => this.props.selectLanguage(option?option.value:null)}
						//onBlur={() => this.props.input.onBlur(this.props.input.value)}
						options={languages}
						searchable={true}
						clearable={true}
						placeholder="Select Language"
						menuIsOpen={true}
						blurInputOnSelect={false} //set by default, but to be sure
   						closeMenuOnSelect={false} //prevents menu close after select, which would also result in input blur

					/>					
				</div>

				
						

			</React.Fragment>
		)
	}
}


export default EditRepoModalEditGradeLevel




import React from 'react'
import { Spring } from 'react-spring/renderprops' 

const windowZoomItemInternalPadding = 20 // PASS IN FROM SLIDE DEFINITIONS

function calculateItemLayout(nowPlayingLayout,deliveryImageWidth,deliveryImageHeight){
	// VIEWPORT DIMENSIONS
	const viewportWidth = nowPlayingLayout.viewportWidth
	const viewportHeight = nowPlayingLayout.viewportHeight
	const viewportAspect = viewportWidth / viewportHeight
	// START ZOOM CONTAINER DIMENSIONS
	// Find position of original on-slide imageContainer
	const imageFillContainer = document.getElementById("fillImageZoomable")
	let rect
	if(imageFillContainer){
		rect = imageFillContainer.getBoundingClientRect()
	}   
	let containerStartZoomWidth = 0
	let containerStartZoomHeight = 0
	let containerStartZoomX = 0
	let containerStartZoomY = 0
	if(rect){
		containerStartZoomWidth = rect.width
		containerStartZoomHeight = rect.height
		containerStartZoomX = rect.x
		containerStartZoomY = rect.y
	}
	const containerAspect = containerStartZoomWidth / containerStartZoomHeight
	// UNDLERYING IMAGE SIZING
	const imageAspect = deliveryImageWidth / deliveryImageHeight
	let imageWidth 
	let imageHeight
	// this is opposite logic to fit image/video we're filling instead of fitting
	if(containerAspect >= imageAspect){ // container wider than image
		imageWidth = containerStartZoomWidth
		imageHeight = containerStartZoomWidth / imageAspect
	}
	else{ // container narrower than item (containerAspect < imageAspect)  
		imageHeight = containerStartZoomHeight
		imageWidth = imageHeight * imageAspect
	}
	// END ZOOM CONTAINER DIMENSIONS    
	// find maximum available width and height
	const containerEndZoomMaxWidth = viewportWidth - windowZoomItemInternalPadding - windowZoomItemInternalPadding
	const containerEndZoomMaxHeight = viewportHeight - windowZoomItemInternalPadding - windowZoomItemInternalPadding
	// find actual fitting item width and height
	let containerEndZoomWidth 
	let containerEndZoomHeight

	// this time we do it to fit to the maximum image size available
	if(viewportAspect >= imageAspect){ // screen wider than item
		containerEndZoomHeight = containerEndZoomMaxHeight
		containerEndZoomWidth = containerEndZoomHeight * imageAspect
	}
	if(viewportAspect < imageAspect){ // screen narrower than item
		containerEndZoomWidth = containerEndZoomMaxWidth
		containerEndZoomHeight = containerEndZoomWidth / imageAspect
	}

	// find end-state X and Y, this is to viewport
	const containerEndZoomX = (viewportWidth - containerEndZoomWidth) / 2
	const containerEndZoomY = (viewportHeight - containerEndZoomHeight) / 2
	// ZOOM START --> ZOOM END  
	// SCALE FACTOR
	const containerEndZoomScaleX = containerEndZoomWidth / containerStartZoomWidth
	const containerEndZoomScaleY = containerEndZoomHeight / containerStartZoomHeight
	// TRANSFORM ORIGIN (this effectively translates position of slide)
	const transformOriginX = (containerStartZoomX - containerEndZoomX) / (containerEndZoomWidth - containerStartZoomWidth)
	const transformOriginY = (containerStartZoomY - containerEndZoomY) / (containerEndZoomHeight - containerStartZoomHeight)
	// STYLING IS FIXED
	// zooming also repositions slide through transform origin location

	const containerStyle = {
		width: `${containerStartZoomWidth}px`, 
		height: `${containerStartZoomHeight}px`,
		left: `${containerStartZoomX}px`,
		top: `${containerStartZoomY}px`,              
		transformOrigin: `${transformOriginX * 100}% ${transformOriginY * 100}%`,
	}

	return {
		containerStyle:containerStyle,
		containerEndZoomScaleX:containerEndZoomScaleX,
		containerEndZoomScaleY:containerEndZoomScaleY,
		imageWidth:imageWidth,
		imageHeight:imageHeight
	}   
}  





class LiveViewPromptFillImage extends React.Component{

	constructor(props){
		super()
		this.calculateLayout=this.calculateLayout.bind(this)
		this.state={
			containerStyle:{},
			containerEndZoomScaleX:0,
			containerEndZoomScaleY:0,
			imageWidth:0,
			imageHeight:0	
		}		
	}

	componentDidMount(){
	 this.calculateLayout()
	}

	componentDidUpdate(prevProps) {
		if ((this.props.layoutKey !== prevProps.layoutKey) || (this.props.showStudentList!==prevProps.showStudentList)) {
			this.calculateLayout()
		 }    
	}

	calculateLayout(){    
		const itemLayout=calculateItemLayout(this.props.nowPlayingLayout,this.props.deliveryImageWidth, this.props.deliveryImageHeight)
		this.setState({
			containerStyle:itemLayout.containerStyle,      
			containerEndZoomScaleX:itemLayout.containerEndZoomScaleX,
			containerEndZoomScaleY:itemLayout.containerEndZoomScaleY,
			imageWidth:itemLayout.imageWidth,
			imageHeight:itemLayout.imageHeight})
	}

	render(){		
		const {promptMediaZoomed, animationDisabled, isLastZoomedItem} = this.props 
		const {containerStyle,containerEndZoomScaleX,containerEndZoomScaleY,imageWidth,imageHeight} = this.state

		const zoomEndGlobalScale = Math.min(containerEndZoomScaleX, containerEndZoomScaleY)

		// Z-INDEX MANAGEMENT
		const moveItemBelowDarkBG = !isLastZoomedItem
		
		
		return(			
			
			<React.Fragment>
				<Spring
					//onRest={this.zoomAnimationFinished}
					immediate={animationDisabled}          
					from={{ scaleX: 1, scaleY: 1, scaleGlobal: 1 }}
					to={{ 
						scaleX: (promptMediaZoomed && containerEndZoomScaleX) ? containerEndZoomScaleX : 1,
						scaleY: (promptMediaZoomed && containerEndZoomScaleY) ? containerEndZoomScaleY : 1,
						scaleGlobal: (promptMediaZoomed && zoomEndGlobalScale) ? zoomEndGlobalScale : 1,          	
					}}          
					
				>
					{props => 
						<div onClick={this.props.toggleZoomMedia} style={{transform: `scaleX(${props.scaleX}) scaleY(${props.scaleY})`, borderRadius: promptMediaZoomed ? '4px' : '15px', ...containerStyle}} className={'liveView-mediaItem liveView-mediaItem--fillImage ' + (isLastZoomedItem ? ' liveView-mediaItem--isLastZoomedItem ' : '') + (moveItemBelowDarkBG ? ' liveView-mediaItem--moveItemBelowDarkBG ' : '')}>
							
							<div 
								style={{
									width: `${imageWidth}px`,
									height: `${imageHeight}px`,		            			
									transform: `translateX(-50%) translateY(-50%) scaleX(${(1 / props.scaleX) * props.scaleGlobal} ) scaleY(${(1 / props.scaleY) * props.scaleGlobal})`
								}}
								className='liveView-mediaItem--fillImage-imageFillCover'
							>            				            	
										
								<img src={this.props.imageDeliveryURL} className='liveView-mediaItem--fillImage-image' alt=''/>			            	
															
							</div>      

						</div>
					}
				</Spring>		
			</React.Fragment>
		)
	}
}

export default LiveViewPromptFillImage
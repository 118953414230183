import React from 'react'


class StaticSlideChoiceAnimatedGIF extends React.Component{

	constructor(props){
		super(props)
		this.onLoad=this.onLoad.bind(this)
	}

	onLoad(){
		if(this.props.choiceMediaPromiseResolve){
			console.log(`choice ${this.props.choiceLetter} GIF still onLoad`)
			this.props.choiceMediaPromiseResolve()
		}
	}

	render(){	
		const {gif,fullyStatic} = this.props
		return(			
			<React.Fragment>
				{!fullyStatic && gif.gifUrl &&
					<picture className='slide-choice-media--gif-gif'> 
            <source type="image/webp" srcSet={gif.webpUrl} />
            <img src={gif.gifUrl} alt=''/>	            
          </picture>       
        }
        {fullyStatic && gif.stillUrl &&
					<picture className='slide-choice-media--gif-gif'>             
            <img onLoad={this.onLoad} src={gif.stillUrl} alt=''/>            
          </picture>        
        }        
			</React.Fragment>			
		)		
	}	
}

export default StaticSlideChoiceAnimatedGIF

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateUserPayoutDestination } from '../../actions/user'
import Button from '../../components/uiKit/Button'
import {showNotification} from '../../actions/notifications'
import { Field, reduxForm} from 'redux-form'
import FormFieldText from '../../components/uiKit/FormFieldText'

//During creator fund experiments users were able to add their paypal email to recieve payments 

const required = value => value ? undefined : 'Required field'
const email = value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9.-]/i.test(value) ?
		"Are you sure that's an email?" : undefined

const emailsMatch = (value, allValues) => 
	value !== allValues.email ? "Emails don't match" : undefined


class EditPaypalEmailForm extends React.Component{
	render(){
		return(			
			<form onSubmit={this.props.handleSubmit} autoComplete='off'>				
				 <Field 
						name='email'
						type='text'
						component={FormFieldText}
						placeholder='PayPal account email'				          	
						validate={[required, email]}
						autoComplete='false'
						autoFocus={true}
				 />

				 <Field 
					name='emailsMatch'
					type='text'      		
					component={FormFieldText}
					placeholder='Confirm PayPal account email'
					validate={[required, emailsMatch]}
				/>

				<div className='addEditPayPalModal-fees'>
					{!this.props.currentPaypalEmail &&	
						<div className='addEditPayPalModal-fees-para'>
							If you don't have a PayPal account, you can sign up at <a href='https://www.paypal.com' target='_blank' rel='noopener noreferrer'>www.paypal.com</a>
						</div>
					}
					{this.props.currentPaypalEmail &&	
						<div className='addEditPayPalModal-fees-para'>
							You can open a new PayPal account at <a href='https://www.paypal.com' target='_blank' rel='noopener noreferrer'>www.paypal.com</a>
						</div>
					}
					<div className='addEditPayPalModal-fees-para'>
						All payouts incur a fee of up to 2% of the payout amount, which will be deducted from your payout. This is what PayPal charges Plickers to process payments.


					</div>
					<div className='addEditPayPalModal-fees-para'>
						Learn more about the Creator Fund at <a href='https://www.plickerscreatorfund.com' target='_blank' rel='noopener noreferrer'>www.plickerscreatorfund.com</a>
					</div>													
					<div className='addEditPayPalModal-fees-para'>
						If you have any questions, please contact help@plickers.com
					</div>													
				</div>				

				<div className='addEditPayPalModal-actionRow'>	
					<Button 
						size='xLarge'
						color={this.props.dirty && this.props.valid ? 'blue' : 'gray'}
						submitLoading={this.props.submitLoading}
						label={this.props.currentPaypalEmail ? 'Update PayPal Account Email' : 'Add PayPal Account Email'}
						canSubmit={this.props.dirty && this.props.valid && !this.props.submitLoading ? true : false}
						type='submit'
						name='submitpaypal'
					/>
					<Button 
						size='xLarge'
						color='white'        			
						label='Cancel' 
						onClickFunction={this.props.hideModal}              			
					/>
				 </div>
			</form>		
		)
	}
}

const mapStateToProps = (state,ownProps) => {
	return {
		initialValues: {
			email:ownProps.email,
			emailsMatch:ownProps.email
		}
	}
}



EditPaypalEmailForm = connect(mapStateToProps)(reduxForm({
	form: 'EditPaypalEmailForm',
	enableReinitialize: true,
	autoComplete:'off'
})(EditPaypalEmailForm))




class EditPaypalEmailContainer extends Component {


	constructor(props){
		super(props)
		this.updatePaypalEmail=this.updatePaypalEmail.bind(this)
		this.removePaypalEmail=this.removePaypalEmail.bind(this)
		this.state = {
			submitLoading:false,
		}
	}

	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden'
	}

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Paypal Email Modal')
		}
	}

 
	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}

	updatePaypalEmail(formData){
		this.setState({submitLoading:true})
		const payoutDestination=formData.email
		const requestData={value:payoutDestination}
		const oldPayoutDestination=this.props.user.payoutDestination
		this.props.updateUserPayoutDestination(requestData,this.props.user.id).then((response)=>{
			if(oldPayoutDestination){
				this.props.showNotification('updated PayPal account email','Successfully','default')
				 if(window.analytics){
					window.analytics.track('Successfully Updated Paypal Email', {
					})}
			}else{
				this.props.showNotification("added PayPal account email" ,'Successfully','create')
				 if(window.analytics){
					window.analytics.track('Successfully Added Paypal Email', {
					})}
			}
			this.props.hideModal()
		})
	}

	removePaypalEmail(){
		this.setState({submitLoading:true})
		const requestData={value:''}
		this.props.updateUserPayoutDestination(requestData,this.props.user.id).then((response)=>{	
			this.props.showNotification('PayPal account email','Removed','destroy')
			 if(window.analytics){
				window.analytics.track('Removed Paypal Email', {
				})}
			
			this.props.hideModal()
		})		
	}
	
	render() {
		const {submitLoading} = this.state
		const {user}=this.props
		let currentPaypalEmail=user.payoutDestination

		return (
			<div className='addEditPayPalModal'>
				<div className='addEditPayPalModal-header'>
					{currentPaypalEmail?'Update PayPal Account Email':'Add PayPal Account Email'}
				</div>
				
				{!currentPaypalEmail &&
					<div className='addEditPayPalModal-sub'>						
						Add your PayPal account email so we can process any Creator Fund cash awards you've received.															
					</div>
				}

				{currentPaypalEmail &&
					<div className='addEditPayPalModal-sub'>
						Changing your PayPal account email will affect all future payouts. <div className='addEditPayPalModal-sub-removeEmail' onClick={this.removePaypalEmail}>Remove your PayPal account email.</div>
					</div>
				}

				<EditPaypalEmailForm
					onSubmit={this.updatePaypalEmail}
					hideModal={this.props.hideModal}
					submitLoading={submitLoading}
					email={currentPaypalEmail}					
					currentPaypalEmail={currentPaypalEmail}
				/>			
			</div>
		)
	}
}


export default withRouter(connect(
	(state) => ({
		user:state.user	
	}),
	{ updateUserPayoutDestination,showNotification}
)(EditPaypalEmailContainer))
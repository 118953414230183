import React from 'react'
import {ContextMenuTrigger } from 'react-contextmenu'
import { showMenu } from 'react-contextmenu/modules/actions'
import {formatDate} from '../../utils/formatDate'
import {formatDateConsumerView} from '../../utils/formatDateConsumerView'
import {isCurrentUser} from '../../utils/isCurrentUser'
import {getUserNameForId} from '../../utils/getUserNameForId'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'
import {getRepoforId} from '../../utils/getRepoforId'
import {openItemInEditor} from '../../utils/openItemInEditor'
import Button from '../uiKit/Button'
import QuestionCountBadge from '../uiKit/QuestionCountBadge' 
import Icon from '../misc/Icons'


class Cell extends React.Component{

	constructor(){
		super()
		this.handlePlayButtonClick=this.handlePlayButtonClick.bind(this)
		this.handleMouseDown=this.handleMouseDown.bind(this)
		this.handleMouseUp=this.handleMouseUp.bind(this)
		this.state={
			mousedDownFired:false
		}
	}

	handlePlayButtonClick(e,item){
		e.stopPropagation()
		this.props.playItem(item)
	}

	handleMouseDown(item,e){
		if(!this.props.isActive && !this.props.isMultiSelectItem){
			this.props.handleSingleClick(item,e)
			this.setState({mousedDownFired:true})
		}else{
			e.stopPropagation()
		}	
	}

	handleMouseUp(item,e){
		if(!this.state.mousedDownFired && e.button!==2){
			this.props.handleSingleClick(item,e)		
		}
		this.setState({mousedDownFired:false})
	}

	handleOpenContextMenu(e,item,isSet){
		const x=e.target.getBoundingClientRect().left
		const y=e.target.getBoundingClientRect().bottom
		showMenu({
			position: {x, y},
			target: e.target,
			id: 'ITEMS',
			data:{itemId:item.id,item:item,itemType:(isSet ? 'set': 'question')}
		})
	}
			
	handleDoubleClick(itemId,isReadOnly,isSet){
		if(!isReadOnly){
			openItemInEditor(itemId,isSet)
		}
	}		

	collect(props) {
		return { itemId: props.itemId, item:props.item,itemType:(props.isSet ? 'set': 'question')}
	}

	render(){
		const {item,cellInRecent,isActive,contextMenuIsOpen,activityItem,isSet,isMultiSelectItem,lastEditedBy,isDragging,lock}=this.props
		let questionCount
		let title
		
		if(isSet){
		 questionCount=item.questions.length 
		 title=item.name 
		}else{
			title=getQuestionBodyLabel(item)
		}

		let formattedActivityType
		if (activityItem){
			if(activityItem.activityType === 'create'){
				formattedActivityType = 'created'				
			}	
			else if(activityItem.activityType === 'queue'){
				formattedActivityType = 'queued'
			}
			else{
				formattedActivityType = `${activityItem.activityType}ed`
			}
		}
 
		let lockingUserName = ''
		let isLocked=false

		let isLockedByYou = false
		if(lock){
			isLocked = true
			const lockingUser = lock.user
			isLockedByYou=isCurrentUser(lockingUser)
			lockingUserName = getUserNameForId(lockingUser,lock.repo)
		}

		let itemInSharedRepo = false
		let isPublicConsumerItem = false

		if(item.repo){
			const containingRepo = getRepoforId(item.repo)
			if(containingRepo){
				itemInSharedRepo=containingRepo.shared
				if(containingRepo.role==='consumer'){
					isPublicConsumerItem = true
				}
			}
		}

		const isLockedByAnotherUser = isLocked && !isLockedByYou

		let formattedClientModified
		if(isPublicConsumerItem){
			formattedClientModified=formatDateConsumerView(item.clientModified)
		}else{
			formattedClientModified=formatDate(item.clientModified)
		}

		return(
			<ContextMenuTrigger 
				id={isMultiSelectItem? 'MULTISELECT' : 'ITEMS'} 
				itemId={item.id}
				item={item}
				isSet={isSet}
				collect={this.collect}
				disable={isDragging}
			>

				<div 
					className={'cellRow' + 
					(isActive ? ' is--active' : '') +
					(isMultiSelectItem ? ' is--multiSelect' : '') + 
					(contextMenuIsOpen ? ' has--menu' : '')+
					(isDragging ? ' is--DnDdragging' : '')} 
					key={item.id} 
					id={item.id}
					onMouseDown={(e) =>{this.handleMouseDown(item,e)}}	
					onMouseUp={(e) =>{this.handleMouseUp(item,e)}}	
					onDoubleClick={() =>{this.handleDoubleClick(item.id,item.readOnly,isSet)}}
				>
			
					<div className="cellRow-content">
						
						<div className="cellRow-left">
							{isSet &&
								<QuestionCountBadge color="blueLight" questionCount={questionCount} />
							}
							<div onClick={(e) =>{this.handlePlayButtonClick(e,item)}}>
								<Button name="cellPlay" iconName="play"/>
							</div>
						</div>

						<div className="cellRow-center">
											
							<div className="cellRow-name">
								{title}
							</div>							

							{itemInSharedRepo && cellInRecent &&
								<div className="cellRow-sharedIcon">
									<Icon name='shared' />
								</div>
							}

							{isPublicConsumerItem && cellInRecent &&
								<div className="cellRow-sharedIcon">
									<Icon name='globe-shared' />
								</div>
							}							

							{/* Don't think we need this actually
							{isPublicAuthorItem && cellInRecent &&
								<div className="cellRow-sharedIcon">
									<Icon name='globe-shared-author' />
								</div>
							}	
							*/}						

																					
							{activityItem && 
								<div className="cellRow-date">
									{formattedActivityType} {formatDate(activityItem.activityTime)}
								</div>
							}

							{!activityItem && !lastEditedBy &&
								<div className="cellRow-date">
									{formattedClientModified}
								</div>
							}

							{lastEditedBy && !isLockedByAnotherUser &&
								<div className="cellRow-date cellRow-date--repo">
									<b>{lastEditedBy}</b>{formatDate(item.clientModified)}
								</div>
							}							

							{lastEditedBy && isLockedByAnotherUser &&
								<div className="cellRow-date cellRow-date--repo">									
									<b>{lockingUserName}</b>									
									
									<div data-tooltip='Currently editing' className='cellRow-date--repo--live tooltip tooltip--label'>
										<div className='cellRow-date--repo--live-dot' />
										<div className='cellRow-date--repo--live-dot' />
										<div className='cellRow-date--repo--live-dot' />
									</div>

								</div>
							}

						</div>								

						<div className="cellRow-right" onClick={()=>{this.props.navigateToItemDetail(item)}}>
							<Button name="detail" size="small" iconName="chevron-right" />
						</div>

					</div>
		
					
				</div>
			</ContextMenuTrigger>
		)
	}
}

export default Cell
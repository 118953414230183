import React from 'react'

class PageIcon extends React.Component {
  render() {
    switch (this.props.name) {
      case 'recentLarge':
        return (
          <svg className='icon recent recentLarge' width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'>
            <path fill='#167BF0' fillRule='nonzero' d='M57.2975878,39.6788462 C56.1528743,38.4788462 50.615175,15.0990385 48.0867741,12.6557692 C47.3108472,11.9086538 46.2015371,11.4326923 45.0391218,11.4326923 L14.9579378,11.4326923 C13.7955225,11.4326923 12.6862125,11.9086538 11.9102855,12.6557692 C9.37893439,15.0990385 3.84418535,38.4759615 2.6994718,39.6788462 C1.26857986,41.1817308 0.566410203,42.2807692 0.858489176,43.8586538 C1.15056815,45.4365385 2.23332556,51.5144231 2.4988519,52.7259615 C2.75847765,53.9346154 4.51685208,55.4230769 6.1572148,55.4230769 L53.8398448,55.4230769 C55.4802076,55.4230769 57.238582,53.9346154 57.501158,52.7259615 C57.7637341,51.5144231 58.8494418,45.4365385 59.1415208,43.8586538 C59.4335997,42.2807692 58.7314301,41.1788462 57.2975878,39.6788462 Z M41.8410046,41.0086538 C41.543025,41.0086538 41.2715981,41.1586538 41.1388349,41.3923077 L38.7490979,46.7692308 L21.2479618,46.7692308 L18.861175,41.3923077 C18.7284118,41.1586538 18.4569849,41.0086538 18.1590054,41.0086538 L7.79167694,41.0086538 L14.0423078,17.2019231 L45.9461535,17.2019231 L52.208333,41.0086538 L41.8410046,41.0086538 Z' />
            <polygon fill='#0A7CFF' points='47.346 35.336 47.877 37.853 12.123 37.853 12.654 35.336 47.346 35.336' />
            <polygon fill='#0A7CFF' points='46.283 30.301 46.814 32.818 13.186 32.818 13.717 30.301' />
            <polygon fill='#0A7CFF' points='45.22 25.266 45.751 27.783 14.249 27.783 14.78 25.266' />
            <polygon fill='#0A7CFF' points='44.157 20.231 44.689 22.748 15.311 22.748 15.843 20.231' />
          </svg>
        )

      case 'libraryLarge':
        return (
          <svg className='icon library libraryLarge' width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'>
            <defs>
              <linearGradient id='libraryGradient' x1='50%' x2='50%' y1='0%' y2='100%'>
                <stop offset='0%' stopColor='#0A7CFF' />
                <stop offset='100%' stopColor='#167BF0' />
              </linearGradient>
            </defs>
            <path fill='#167BF0' fillRule='nonzero' d='M55.1037404,20.2470536 C54.7665736,18.950625 53.1141524,17.8928571 51.4283183,17.8928571 L30.7305281,17.8928571 C29.0477315,17.8928571 26.6906013,16.9558929 25.4998861,15.8097321 L23.6895129,14.0772321 C22.4987976,12.9310714 20.144705,12 18.4619084,12 L8.57471924,12 C6.88888514,12 5.35796552,13.3170536 5.17267565,14.92875 L4.28571429,23.7857143 L55.7142857,23.7857143 L55.1037404,20.2470536 Z' />
            <path fill='url(#libraryGradient)' id='library-front' d='M58.2593081,27 L1.74069188,27 C0.713844946,27 -0.0908187363,27.8877273 0.00826298576,28.9173701 L2.77954873,58.1127273 C2.89064035,59.3024026 3.88746253,60.2142857 5.07944567,60.2142857 L54.9205543,60.2142857 C56.1125375,60.2142857 57.1063572,59.3024026 57.2204513,58.1127273 L59.991737,28.9173701 C60.0908187,27.8877273 59.2861551,27 58.2593081,27 Z' />
          </svg>
        )

      case 'historyLarge':
        return (
          <svg className='icon history historyLarge' width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'>
            <defs>
              <linearGradient id='historyGradient' x1='50%' x2='50%' y1='0%' y2='100%'>
                <stop offset='0%' stopColor='#0A7CFF' />
                <stop offset='100%' stopColor='#167BF0' />
              </linearGradient>
            </defs>
            <path fill='url(#historyGradient)' fillRule='nonzero' d='M50.1428571,0 C51.9993571,0 53.5,1.49333333 53.5,3.33333333 L53.5,56.6666667 C53.5,58.5066667 51.9993571,60 50.1428571,60 L9.85714286,60 C8.00064286,60 6.5,58.5066667 6.5,56.6666667 L6.5,3.33333333 C6.5,1.49 8.00064286,0 9.85714286,0 L50.1428571,0 Z M43,11 L17,11 L17,18.8627108 L43,18.8627108 L43,11 Z M43,41.3275988 L17,41.3275988 L17,49.1903096 L43,49.1903096 L43,41.3275988 Z M43,26.7254216 L17,26.7254216 L17,34.5881324 L43,34.5881324 L43,26.7254216 Z' />
          </svg>

        )

      case 'trashLarge':
        return (
          <svg className='icon trash trash--large' width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'>
            <defs>
              <linearGradient id='trashGradient' x1='50%' x2='50%' y1='0%' y2='100%'>
                <stop offset='0%' stopColor='#0A7CFF' />
                <stop offset='100%' stopColor='#167BF0' />
              </linearGradient>
            </defs>
            <path fill='url(#trashGradient)' d='M12.8571429,54.2222222 C12.8571429,57.4144444 15.4142857,60 18.5714286,60 L41.4285714,60 C44.5857143,60 47.1428571,57.4144444 47.1428571,54.2222222 L47.1428571,20.5555556 L47.1428571,20.5555556 C47.1428571,20.0032708 46.6951419,19.5555556 46.1428571,19.5555556 L13.8571429,19.5555556 L13.8571429,19.5555556 C13.3048581,19.5555556 12.8571429,20.0032708 12.8571429,20.5555556 L12.8571429,54.2222222 Z' />
            <path fill='#167BF0' d='M49,10.8888889 L40.4174536,10.8888889 L40.4174536,10.8888889 C40.1503222,10.8888889 39.8942951,10.7820094 39.706451,10.5920783 L37.4364061,8.29681062 L37.4364061,8.29681062 C37.2485621,8.10687944 36.992535,8 36.7254035,8 L23.2745965,8 L23.2745965,8 C23.007465,8 22.7514379,8.10687944 22.5635939,8.29681062 L20.293549,10.5920783 L20.293549,10.5920783 C20.1057049,10.7820094 19.8496778,10.8888889 19.5825464,10.8888889 L11,10.8888889 L11,10.8888889 C10.4477153,10.8888889 10,11.3366041 10,11.8888889 L10,15.6666667 L10,15.6666667 C10,16.2189514 10.4477153,16.6666667 11,16.6666667 L49,16.6666667 L49,16.6666667 C49.5522847,16.6666667 50,16.2189514 50,15.6666667 L50,11.8888889 L50,11.8888889 C50,11.3366041 49.5522847,10.8888889 49,10.8888889 Z' />
          </svg>

        )

      case 'historySolidBGLarge':
        return (
          <svg className='icon history historySolidBG historySolidBGLarge' width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'>
            <path d='M50.1428571,0 C51.9993571,0 53.5,1.49333333 53.5,3.33333333 L53.5,56.6666667 C53.5,58.5066667 51.9993571,60 50.1428571,60 L9.85714286,60 C8.00064286,60 6.5,58.5066667 6.5,56.6666667 L6.5,3.33333333 C6.5,1.49 8.00064286,0 9.85714286,0 L50.1428571,0 Z M43,11 L17,11 L17,18.8627108 L43,18.8627108 L43,11 Z M43,41.3275988 L17,41.3275988 L17,49.1903096 L43,49.1903096 L43,41.3275988 Z M43,26.7254216 L17,26.7254216 L17,34.5881324 L43,34.5881324 L43,26.7254216 Z' />
          </svg>
        )

      case 'studentLarge':
        return (
          <svg className='icon student studentLarge' width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'>
            <path d='M9.90887614,36.7726278 C11.3008587,42.4591687 13.0708366,44.9468412 18.9747628,47.552542 C24.881689,50.1552164 27.6866539,53 30.002625,53 C32.3155961,53 34.9465632,50.5123275 40.8504894,47.9036004 C46.7574155,45.2978996 45.1794353,44.5049919 46.5714179,38.818451 L30.002625,46.9472689 L9.90887614,36.7726278 Z M58.5352683,21.9373838 L33.5605805,7.82846757 C31.6046049,6.72384414 28.400645,6.72384414 26.4446694,7.82846757 L1.46698166,21.9373838 C-0.488993888,23.0420073 -0.488993888,24.8517739 1.46698166,25.9563973 L26.4416695,40.0653136 C28.397645,41.169937 31.601605,41.169937 33.5575805,40.0653136 L49.7033787,30.9438478 L32.1955976,26.806806 C31.520606,26.9732561 30.7796153,27.0670735 30.002625,27.0670735 C26.8466644,27.0670735 24.2906964,25.5357325 24.2906964,23.644254 C24.2906964,21.7467228 26.8496644,20.2153818 30.002625,20.2153818 C32.4505944,20.2153818 34.5325683,21.1444761 35.3425582,22.4367342 L53.8883264,28.5772299 L58.5352683,25.9533709 C60.4882439,24.8517739 60.4882439,23.0420073 58.5352683,21.9373838 Z M50.9033637,48.6722972 C50.7083661,49.837448 54.7523156,51.7531374 55.1363108,48.3484761 C56.8732891,32.99875 53.8883264,28.5832826 53.8883264,28.5832826 L49.7033787,30.9468741 C49.7033787,30.9438478 53.2523343,34.3515354 50.9033637,48.6722972 Z' />
          </svg>
        )

      case 'settingsLarge':
        return (
          <svg className='icon settings settingsLarge' width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'>
            <path d='M54.2185454,29.9982144 C54.2185454,26.2520088 56.5254136,23.3021844 59.992858,21.2737337 C59.3643614,19.1881436 58.5394596,17.1846914 57.5217236,15.3026606 C53.6329008,16.3204571 50.4868468,14.7991191 47.8407332,12.1492768 C45.1946197,9.50657699 44.3840019,6.36033569 45.4017379,2.46771026 C43.5198191,1.4499137 41.5164861,0.617820368 39.4310201,3.96484623e-15 C37.4026902,3.46407952 33.7352696,5.76751384 29.996429,5.76751384 C26.2540174,5.76751384 22.5901678,3.46407952 20.5582669,0 C18.4692299,0.617820368 16.4730389,1.4499137 14.5911201,2.46771026 C15.6088561,6.36033569 14.8018093,9.50657699 12.1485537,12.1492768 C9.50601119,14.7991191 6.35995715,16.3204571 2.46756339,15.3026606 C1.4498274,17.1846914 0.621354601,19.1881436 7.92922051e-16,21.2737337 C3.46387335,23.3021844 5.76717057,26.2520088 5.76717057,29.9982144 C5.76717057,33.7372775 3.46387335,37.4049164 0,39.4369383 C0.624925604,41.5225284 1.4498274,43.5224094 2.46756339,45.4080114 C6.35995715,44.3902149 9.50601119,45.1973097 12.1485537,47.8435807 C14.7946673,50.493423 15.6088561,53.6396643 14.5911201,57.5251473 C16.4730389,58.5429439 18.4728009,59.3750372 20.5618379,60 C22.5901678,56.5252068 26.2575884,54.2253437 30,54.2253437 C33.7388406,54.2253437 37.4062612,56.528778 39.4381621,60 C41.5236281,59.371466 43.5233901,58.5429439 45.4088799,57.5251473 C44.3911439,53.6396643 45.1981907,50.493423 47.8478753,47.8435807 C50.4939888,45.2008809 53.6400429,43.6795429 57.5288656,44.690197 C58.5466016,42.8081662 59.3750744,40.8118564 60,38.7191239 C56.5254136,36.687102 54.2185454,33.7372775 54.2185454,29.9982144 Z M29.996429,43.0402952 C22.790144,43.0402952 16.9515534,37.2013571 16.9515534,29.9982144 C16.9515534,22.7915005 22.793715,16.9489911 29.996429,16.9489911 C37.202714,16.9489911 43.0377336,22.7950717 43.0377336,29.9982144 C43.0377336,37.2049283 37.202714,43.0402952 29.996429,43.0402952 Z' />
          </svg>
        )

      default:
        return null
    }
  }
}

export default PageIcon

import React from 'react'
import ModalWrapper from '../ModalWrapper'
import EditPaypalEmailContainer from '../../../containers/account/EditPaypalEmailContainer'

const PaypalEmailModal = props => {

	return (

  <ModalWrapper
    {...props}
    title={'add edit paypal'}
    iconName=''
    closeBtn={false}
    showModalHeader={false}
    modalStyle='dark'
  >
    <EditPaypalEmailContainer {...props}/>

  </ModalWrapper>



  )
}

export default PaypalEmailModal
import React from 'react'
import moment from 'moment'
import {calibratedCurrentTime} from '../../../utils/calibratedCurrentTime'

class SyncLiveViewToolbarScanCountdownPresetDropdownBtn extends React.Component{
	render(){							
		return(								               		
			<button onClick={this.props.onClick} className={'syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn ' + (this.props.isActive ? 'syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn--active':'')}>  				    			  				
				<div className='syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn-label'>
					{this.props.label}
				</div>
				<div className='syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn-inner'/>
			</button>	        		        					 
		)
	}
}

class SyncLiveViewToolbarScanCountdownPresetDropdown extends React.Component{
	render(){		
		const {showCountdownPresetDropdown,setCountdownSeconds,countDownTimerStartSecondsLength} = this.props
			return(				
			  <div className={'syncLiveView-toolbar-center-countDownTimer-presetDropdownContainer ' + (showCountdownPresetDropdown ? 'syncLiveView-toolbar-center-countDownTimer-presetDropdownContainer--show':'syncLiveView-toolbar-center-countDownTimer-presetDropdownContainer--hide')}>
	    		<div className='syncLiveView-toolbar-center-countDownTimer-presetDropdown'>
	    			
	    			<SyncLiveViewToolbarScanCountdownPresetDropdownBtn 
	    				isActive={countDownTimerStartSecondsLength===6}
	    				onClick={()=>{setCountdownSeconds(6)}}
	    				label='5 seconds'
	    			/>
	    			<SyncLiveViewToolbarScanCountdownPresetDropdownBtn 
	    				isActive={countDownTimerStartSecondsLength===11}
	    				onClick={()=>{setCountdownSeconds(11)}}
	    				label='10 seconds'
	    			/>
	    			<SyncLiveViewToolbarScanCountdownPresetDropdownBtn 
	    				isActive={countDownTimerStartSecondsLength===16}
	    				onClick={()=>{setCountdownSeconds(16)}}
	    				label='15 seconds'
	    			/>
	    			<SyncLiveViewToolbarScanCountdownPresetDropdownBtn 
	    				isActive={countDownTimerStartSecondsLength===21}
	    				onClick={()=>{setCountdownSeconds(21)}}
	    				label='20 seconds'
	    			/>
	    			<SyncLiveViewToolbarScanCountdownPresetDropdownBtn 
	    				isActive={countDownTimerStartSecondsLength===31}
	    				onClick={()=>{setCountdownSeconds(31)}}
	    				label='30 seconds'
	    			/>
	    			<SyncLiveViewToolbarScanCountdownPresetDropdownBtn 
	    				isActive={countDownTimerStartSecondsLength===46}
	    				onClick={()=>{setCountdownSeconds(46)}}
	    				label='45 seconds'
	    			/>
	    			<SyncLiveViewToolbarScanCountdownPresetDropdownBtn 
	    				isActive={countDownTimerStartSecondsLength===61}
	    				onClick={()=>{setCountdownSeconds(61)}}
	    				label='1 minute'
	    			/>
	    			<SyncLiveViewToolbarScanCountdownPresetDropdownBtn 
	    				isActive={countDownTimerStartSecondsLength===91}
	    				onClick={()=>{setCountdownSeconds(91)}}
	    				label='1 min 30s'
	    			/>
	    			<SyncLiveViewToolbarScanCountdownPresetDropdownBtn 
	    				isActive={countDownTimerStartSecondsLength===121}
	    				onClick={()=>{setCountdownSeconds(121)}}
	    				label='2 minutes'
	    			/>
	    			<SyncLiveViewToolbarScanCountdownPresetDropdownBtn 
	    				isActive={countDownTimerStartSecondsLength===301}
	    				onClick={()=>{setCountdownSeconds(301)}}
	    				label='5 minutes'
	    			/>

	      		<button className='syncLiveView-toolbar-center-countDownTimer-presetDropdown-disableTimerBtn' 	onClick={()=>{setCountdownSeconds(null)}}>
	      			Cancel Timer
	      		</button>

	    		</div>
				</div>
			)
	}
}


class SyncLiveViewToolbarScanCountdown extends React.Component{

	constructor(){
		super()
		this.startCountdown=this.startCountdown.bind(this)
		this.stopCountdown=this.stopCountdown.bind(this)
		this.setCountdownSeconds=this.setCountdownSeconds.bind(this)
		this.cancelCountdown=this.cancelCountdown.bind(this)
		this.countdown=null

			this.state={    	 
				countdownTime: 0,
				showCountdownPresetDropdown: false,
				didSelectCountdownTime:false,
				recentlyCanceled:false
			}
		}

	componentDidMount(){
		if(this.props.countdownEndsAt){
			this.startCountdown()
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.countdownEndsAt && !moment(nextProps.countdownEndsAt).isSame(moment(this.props.countdownEndsAt))){
			this.stopCountdown()
			this.startCountdown(nextProps.countdownEndsAt)
		}else if(!nextProps.countdownEndsAt){
			this.stopCountdown()
		}else if(this.props.countdownEndsAt){
			const countdownTime=Math.max(moment(this.props.countdownEndsAt)-moment(calibratedCurrentTime()),0)
			this.setState({
				countdownTime: countdownTime,
			})
		}
	}

	componentWillUnmount(){
		this.stopCountdown()
	}

	cancelCountdown(){
		this.setState({recentlyCanceled:true})
		this.props.cancelCountdown()
	}

	setCountdownSeconds(seconds){
		this.props.setCountdownSeconds(seconds)
		this.setState({isSetTimerScanningSubmitLoading:true,didSelectCountdownTime:true},()=>{
		setTimeout(function() {
			this.setState({didSelectCountdownTime:false})
		}.bind(this), 100)
		setTimeout(function() {
			this.setState({isSetTimerScanningSubmitLoading:false})
		}.bind(this), 1000)
		})
	}

	startCountdown(countdownEnds){
		let countdownEndsAt=countdownEnds||this.props.countdownEndsAt
		const nowDate=moment(calibratedCurrentTime())
		const countdownTime=Math.max(moment(countdownEndsAt)-nowDate,0)
		this.setState({countdownTime: countdownTime})
		this.countdown = setInterval(() => {
			if(this.state.countdownTime>10){
				const nowDate=moment(calibratedCurrentTime())
				this.setState({countdownTime:Math.max(moment(countdownEndsAt)-nowDate,0)})
			}
			else{
				this.stopCountdown()
				this.props.handleCountdownEnd()
			}
		}, 10)		
	}

	stopCountdown(){
		clearInterval(this.countdown)
	}

	render(){	
		const { countdownTime, showCountdownPresetDropdown,didSelectCountdownTime,recentlyCanceled } = this.state
		const {isScanning,countDownTimerStartSecondsLength,countdownEndsAt} = this.props
		let seconds = ("0" + (Math.floor(countdownTime / 1000) % 60)).slice(-2);
		let minutes = ("0" + (Math.floor(countdownTime / 60000) % 60)).slice(-2);
		//let hours = ("0" + Math.floor(countdownTime / 3600000)).slice(-2);	
		let countDownTimerStartSecondsDisplay =countDownTimerStartSecondsLength-1
		let countdownTimerLabel
		let countdownLightDisplay = false
		if(!countDownTimerStartSecondsLength && !isScanning){
			countdownTimerLabel = 'Add Timer'
			countdownLightDisplay = true
		}else if(!countdownEndsAt && isScanning){
			countdownTimerLabel = 'Start Timer'
		}else if(countDownTimerStartSecondsDisplay > 0 && countDownTimerStartSecondsDisplay < 60){
			countdownTimerLabel = `${countDownTimerStartSecondsDisplay} seconds`
		}else if(countDownTimerStartSecondsDisplay === 60){
			countdownTimerLabel = '1 minute'
		}else if(countDownTimerStartSecondsDisplay !== 60 && countDownTimerStartSecondsDisplay % 60 === 0){
			countdownTimerLabel = `${countDownTimerStartSecondsDisplay / 60} minutes`
		}else if(countDownTimerStartSecondsDisplay > 60 && countDownTimerStartSecondsDisplay % 60 !== 0){
			countdownTimerLabel = `${Math.floor(countDownTimerStartSecondsDisplay / 60)} min ${countDownTimerStartSecondsDisplay % 60}s`
		}

		const isScanningNotSet = !countdownEndsAt && isScanning			
		const {isSetTimerScanningSubmitLoading} = this.state
		const isSetTimerScanningSubmitLoadingWhileScanning = isSetTimerScanningSubmitLoading && isScanning

		return(				
			<div className='syncLiveView-toolbar-center-countDownTimer' onMouseLeave={()=>{this.setState({recentlyCanceled:false})}}>

				<div className='syncLiveView-toolbar-center-countDownTimer-leftContainer' >
					{(countdownTime>10000 || (!countdownEndsAt && countDownTimerStartSecondsLength>11)) && !recentlyCanceled &&
        		<button className='syncLiveView-toolbar-center-countDownTimer-minusTenBtn' onClick={()=>{this.props.incrementCountdown(-10)}}>
        			-10s
        		</button>
      		}
      	</div>
      	<div  className={'syncLiveView-toolbar-center-countDownTimer-centerContainer ' + (didSelectCountdownTime ? 'syncLiveView-toolbar-center-countDownTimer-centerContainer--selectedPreset':'')}>

      		{(!isScanning || !countdownEndsAt) && !recentlyCanceled && !isSetTimerScanningSubmitLoadingWhileScanning &&
        		<button onClick={()=>(this.setState({showCountdownPresetDropdown: !showCountdownPresetDropdown}))} className={'syncLiveView-toolbar-center-countDownTimer-setTimerBtn ' + (countdownLightDisplay ? 'syncLiveView-toolbar-center-countDownTimer-setTimerBtn--unset' : '') + (isScanningNotSet ? ' syncLiveView-toolbar-center-countDownTimer-setTimerBtn--scanningNotSet':'') + (showCountdownPresetDropdown ? ' syncLiveView-toolbar-center-countDownTimer-setTimerBtn--presetDropdownOpen':'')}>
        			{countDownTimerStartSecondsLength && !isScanning &&
	        			<div className='syncLiveView-toolbar-center-countDownTimer-setTimerBtn-iconContainer'>
									<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" >
						        <circle id="A" fill="#E7F1FD" cx="15.96" cy="16.52" r="12.6"></circle>
						        <path d="M17.60164,14.03836 C18.5857,15.02242 18.5857,16.61758 17.60164,17.60164 C16.61758,18.5857 15.02242,18.5857 14.03836,17.60164 C13.35292,16.9162 10.20292,12.03496 8.35828,9.14074 C8.03068,8.62792 8.62792,8.03068 9.14074,8.35828 C12.03496,10.20292 16.9162,13.35166 17.60164,14.03836 Z" id="B" fill="#167CF0" fillRule="nonzero"></path>
						        <path d="M15.82,2.13695728e-12 C24.5569781,2.13695728e-12 31.64,7.08302189 31.64,15.82 C31.64,24.5569781 24.5569781,31.64 15.82,31.64 C7.08302189,31.64 -1.2414958e-11,24.5569781 -1.2414958e-11,15.82 C-1.2414958e-11,12.0486069 1.32964583,8.47829631 3.71147233,5.64941474 C4.40866845,4.82135894 5.64512945,4.7152762 6.47318526,5.41247233 C7.30124106,6.10966845 7.4073238,7.34612945 6.71012767,8.17418526 C4.91754865,10.303221 3.92,12.9817978 3.92,15.82 C3.92,22.3920219 9.24797811,27.72 15.82,27.72 C22.3920219,27.72 27.72,22.3920219 27.72,15.82 C27.72,9.24797811 22.3920219,3.92 15.82,3.92 C14.7375219,3.92 13.86,3.04247811 13.86,1.96 C13.86,0.87752189 14.7375219,2.13695728e-12 15.82,2.13695728e-12 Z" id="C" fill="#167CF0" fillRule="nonzero"></path>  
									</svg>
	        			</div>
        			}
        			{countdownTimerLabel}
        		</button>
      		}

      		<SyncLiveViewToolbarScanCountdownPresetDropdown
      			setCountdownSeconds={this.setCountdownSeconds}
      			showCountdownPresetDropdown={showCountdownPresetDropdown}
      			countDownTimerStartSecondsLength={this.props.countDownTimerStartSecondsLength}
      		/>

      		{isScanning && (countdownEndsAt || isSetTimerScanningSubmitLoadingWhileScanning) && 
        		<div className='syncLiveView-toolbar-center-countDownTimer-timer'  >
        			{!countdownEndsAt &&
        				<React.Fragment>
        					<span className='syncLiveView-toolbar-center-countDownTimer-timer-submitLoading-minutes'>
										00
									</span>		
									<span className='syncLiveView-toolbar-center-countDownTimer-timer-submitLoading-dot'>
									:				
									</span>
									<span className='syncLiveView-toolbar-center-countDownTimer-timer-submitLoading-seconds'>
										20
									</span>				
								</React.Fragment>	
        			}
        			
        			{countdownEndsAt &&
        				<React.Fragment>
		        			<button className='syncLiveView-toolbar-center-countDownTimer-timer-cancel' onClick={this.cancelCountdown}>
		        				Cancel
		        			</button>
									<span className={'syncLiveView-toolbar-center-countDownTimer-timer-minutes ' + (minutes > 0 ? ' syncLiveView-toolbar-center-countDownTimer-timer-minutes--nonZeroMinutes ':' syncLiveView-toolbar-center-countDownTimer-timer-minutes--zeroMinutes ')}>
										{minutes}:
									</span>						
									<span className='syncLiveView-toolbar-center-countDownTimer-timer-seconds'>
										{seconds}
									</span>
								</React.Fragment>
							}
							
        		</div>
      		}
      	</div>
      	<div className='syncLiveView-toolbar-center-countDownTimer-rightContainer'>      
      	 	{!recentlyCanceled && !isSetTimerScanningSubmitLoadingWhileScanning &&
        		<button className='syncLiveView-toolbar-center-countDownTimer-addTenBtn'  onClick={()=>{this.props.incrementCountdown(10)}}>
      				+10s
      			</button>
    			}
      	</div>                 
			</div>
		)
	}
}

export default SyncLiveViewToolbarScanCountdown 
import {createNewSet} from '../actions/sets'
import {createNewQuestion} from '../actions/questions'
import {getQuestionBodyLabel} from './getQuestionBodyLabel'


const SET_TITLE_MAX_CHARACTER_LENGTH=60
 
export function createCopyOfItem(item) {
	const nowDate = new Date()

	if(item.choices){
		const requestBody = {
			...item,
			'body':`${getQuestionBodyLabel(item)}`,
			'userCreated': nowDate,
			'clientModified':nowDate
		}
		return createNewQuestion(requestBody)
	}
	else if(item.questions){
		
		const newSetName=`${item.name} Copy`

		const requestBody = {
			...item,
			'name':newSetName.substring(0, SET_TITLE_MAX_CHARACTER_LENGTH),
			'userCreated': nowDate,
			'clientModified':nowDate
		}
		return createNewSet(requestBody)

	}

}



  
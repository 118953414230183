import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import {updateUser,testUsername} from '../../actions/user'
import {updateRepo} from '../../actions/repos'
import Button from '../../components/uiKit/Button'
import PublishingFlowHeader from '../../components/repo/publishingFlow/PublishingFlowHeader'
import PublishingFlowChecklistPage from '../../components/repo/publishingFlow/PublishingFlowChecklistPage'
import PublishingFlowSubjectPage from '../../components/repo/publishingFlow/PublishingFlowSubjectPage'
import PublishingFlowGradeLevelPage from '../../components/repo/publishingFlow/PublishingFlowGradeLevelPage'
import PublishingFlowDescriptionPage from '../../components/repo/publishingFlow/PublishingFlowDescriptionPage'
import PublishingFlowImagePage from '../../components/repo/publishingFlow/PublishingFlowImagePage'
import PublishingFlowReviewPage from '../../components/repo/publishingFlow/PublishingFlowReviewPage'
import PublishingFlowSuccessPage from '../../components/repo/publishingFlow/PublishingFlowSuccessPage'
import {imageSearch} from '../../actions/imageSearch'
import gradeLevels from '../../staticData/gradeLevels.js'
import languages from '../../staticData/languages.js'
import JoinCreatorProgramContainer from '../JoinCreatorProgramContainer'
import find from 'lodash/find'
import filter from 'lodash/filter'
import {uploadRepoImageToCloudinary} from '../../actions/cloudinary'

   
class PublishRepoModalWrapper extends React.Component{

	constructor(){
		super()
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)   
	}


	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden' //prevent scrolling while modal is open
	}

 
	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}

	handleBackgroundClick(e){
		if(this.props.publishingFlowStage==='checklist'){
			if (e.target === e.currentTarget) this.props.hideModal() //close modal is click outside modal
		}
	}

 
	render(){	

		return(
			<div className='publishingFlowContainer'  onClick={this.handleBackgroundClick}>
				{this.props.children}
			</div>
		)		
	}	
}


class PublishRepoFlowContainer extends Component {
  
	constructor(props){
		super(props)
		this.nextButtonClick=this.nextButtonClick.bind(this)
		this.selectGradeLevel=this.selectGradeLevel.bind(this)
		this.selectOtherGradeLevel=this.selectOtherGradeLevel.bind(this)
		this.selectStaff=this.selectStaff.bind(this)
		this.selectHigherEducation=this.selectHigherEducation.bind(this)
		this.validateGradeLevels=this.validateGradeLevels.bind(this)
		this.nextBtnIsDisabled=this.nextBtnIsDisabled.bind(this)
		this.goToPreviousPublishingFlowStage=this.goToPreviousPublishingFlowStage.bind(this)
		this.onChangeRepoDescription=this.onChangeRepoDescription.bind(this)
		this.onChangeRepoName=this.onChangeRepoName.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.startPublishingFlow=this.startPublishingFlow.bind(this)

		let selectedGradeLevels={}
		gradeLevels.map((gradeLevel)=>{
			selectedGradeLevels[gradeLevel.grade]=false
			return null
		})

		let selectedOtherGradeLevel=false
		let selectedStaff=false
		let selectedHigherEducation=false

		if(props.repo){
			if(props.repo.grade && props.repo.grade.length !==0){
				const currentGrade=props.repo.grade

				gradeLevels.map((gradeLevel)=>{
					if(currentGrade.includes(gradeLevel.grade)){
						selectedGradeLevels[gradeLevel.grade]=true	
					}else{
						selectedGradeLevels[gradeLevel.grade]=false
					}
					return null
				})
				if(currentGrade.includes('higher')){
					selectedHigherEducation=true
				}
				if(currentGrade.includes('staff')){
					selectedStaff=true
				}if(currentGrade.includes('other')){
					selectedOtherGradeLevel=true
				}

			}
		}



		const browserLanguage = window.navigator.userLanguage || window.navigator.language
		let packLanguage='en'
		if(props.repo.language){
			packLanguage=props.repo.language
		}else{
			if(browserLanguage){
			const shortenedBrowserLanguage=browserLanguage.substring(0,2)
			if(find(languages, {value: shortenedBrowserLanguage})) {
				packLanguage=shortenedBrowserLanguage
			}
		}
		}




		const publishingFlowStage=this.getPublishingFlowStage(window.location.hash)

		let selectedSubjectId
		let selectedSubjectParentId
		const subjectOtherString=props.repo.subjectOther
		const gradeOtherString=props.repo.gradeOther
		if(props.repo && props.repo.subject){
			selectedSubjectId=props.repo.subject.id
			if(props.repo.subject.parent){
				selectedSubjectParentId=props.repo.subject.parent.id
			}
		}
 

		this.state={
			publishingFlowStage:publishingFlowStage,
			selectedSubjectId:selectedSubjectId,
			selectedSubjectParentId:selectedSubjectParentId,
			subjectOtherString:subjectOtherString||'',
			selectedGradeLevels:selectedGradeLevels,
			selectedOtherGradeLevel:selectedOtherGradeLevel,
			selectedStaff:selectedStaff,
			selectedHigherEducation:selectedHigherEducation,
			error:null,
			language:packLanguage,
			repoDescription:props.repo.description,
			repoImage:props.repo.image,
			repoName:props.repo.name,
			confirmCheckboxChecked:false,
			uploadingImage:false,
			submitLoading:false,
			repoIsInReview:false,
			nextBtnSubmitLoading:false,
			gradeOtherString:gradeOtherString			
		}	   
	}


	UNSAFE_componentWillMount() {
		document.addEventListener('keydown', this.handleKeyDown)
	}

	componentDidMount() {
		localStorage.setItem('doNotShowPublishNewFlag',true )
	}



	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.location.hash !== this.props.location.hash){
			const nextPublishingFlowStage=this.getPublishingFlowStage(nextProps.location.hash)
			this.setState({publishingFlowStage:nextPublishingFlowStage})
		}
		if(!this.props.repo.id && nextProps.repo.id){
			this.setState({
				repoDescription:nextProps.repo.description,
				repoImage:nextProps.repo.image,
				repoName:nextProps.repo.name,
				language:nextProps.repo.packLanguage
			})
		}
	}



	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown)
	}



	handleKeyDown(e){
		if (e.keyCode === 13) {
			e.preventDefault()
			if(!this.nextBtnIsDisabled() && this.state.publishingFlowStage!=='review' && this.state.publishingFlowStage!=='image'){
				this.nextButtonClick()
			}				
		}
	}


	getPublishingFlowStage(hash){
		switch (hash) {

		case '#becomeACreator':
			return 'joinCreatorProgram'

		case '#publish':
			return 'checklist'
	
		case '#publish-subject':
			return 'subject'

		case '#publish-gradeLevel':
			return 'gradeLevel'
	
		case '#publish-description':
			return 'description'

		case '#publish-image':
			return 'image'

		case '#publish-review':
			return 'review'


		case '#publish-success':
			return 'success'
	
		default:
			return 'checklist'
		}
	}

	goToPreviousPublishingFlowStage(){
		this.props.history.goBack()
	}

 


	selectGradeLevel(grade){
		let newGradeArray=Object.assign({},this.state.selectedGradeLevels)
		newGradeArray[grade]=!newGradeArray[grade]
		this.setState({selectedGradeLevels:newGradeArray,selectedOtherGradeLevel:false,selectedStaff:false,error:null})
	}

	selectOtherGradeLevel(){
		let selectedGradeLevels={}
		gradeLevels.map((gradeLevel)=>{
			selectedGradeLevels[gradeLevel.grade]=false
			return null
		})
		this.setState({selectedGradeLevels:selectedGradeLevels,selectedOtherGradeLevel:!this.state.selectedOtherGradeLevel,selectedStaff:false,selectedHigherEducation:false,error:null})
	}

	selectStaff(){
		let selectedGradeLevels={}
		gradeLevels.map((gradeLevel)=>{
			selectedGradeLevels[gradeLevel.grade]=false
			return null
		})
		this.setState({selectedGradeLevels:selectedGradeLevels,selectedOtherGradeLevel:false,selectedStaff:!this.state.selectedStaff,selectedHigherEducation:false,error:null})
	}


	selectHigherEducation(){
		this.setState({selectedOtherGradeLevel:false,selectedStaff:false,selectedHigherEducation:!this.state.selectedHigherEducation,error:null})
	}



	validateGradeLevels(){
		const {selectedGradeLevels,selectedHigherEducation}=this.state

		let isValidGradeRange=true
		if(!this.state.selectedOtherGradeLevel && !this.state.selectedStaff){
			let arrayOfSelectedGradeIncidies=[]
			let isSequential=true

			Object.keys(selectedGradeLevels).map((level,index)=>{
				if(selectedGradeLevels[level]===true){
					arrayOfSelectedGradeIncidies.push(index)
				}
				return null
			})

			const selectedGradesCount=arrayOfSelectedGradeIncidies.length
			const minSelectedGrade=arrayOfSelectedGradeIncidies[0]
			const maxSelectedGrade=arrayOfSelectedGradeIncidies[selectedGradesCount-1]

			if(selectedHigherEducation && selectedGradesCount===0){
				isSequential=true
			}else{
				if(selectedHigherEducation && selectedGradesCount!==0){
					if(maxSelectedGrade !== (Object.keys(selectedGradeLevels).length-1)){
						isSequential=false
					}
				}

				if(selectedGradesCount !==1){
					if((maxSelectedGrade - minSelectedGrade)+1 !==selectedGradesCount){
						isSequential=false
					}
				}
			}	
			isValidGradeRange=isSequential
		}
		return isValidGradeRange
	}







	nextButtonClick(){
		const {publishingFlowStage,selectedSubjectId,subjectOtherString,language,repoIsInReview}=this.state
		const nowDate=new Date()
		const {repo}=this.props

		let updatedRepo=repo
		if(publishingFlowStage==='checklist'){
			if(!this.state.selectedSubjectId){
				window.location.hash='publish-subject'
			}else if(!this.props.repo.grade || this.props.repo.grade.length===0 || !repo.language){
				window.location.hash='publish-gradeLevel'
			}else if(!this.props.repo.description ){
				window.location.hash='publish-description'
			}else if(!this.props.repo.image ){
				window.location.hash='publish-image'
			}else {
				window.location.hash='publish-review'
			}
			
		}

		else if(publishingFlowStage==='subject'){
			this.setState({nextBtnSubmitLoading:true})
			updatedRepo={
				...repo,
				'clientModified': nowDate,
				subject:selectedSubjectId,
				subjectOther:subjectOtherString
			}
			this.props.updateRepo(updatedRepo).then(()=>{
				this.setState({nextBtnSubmitLoading:false})
				if(repoIsInReview){
					window.location.hash='publish-review'
				}else{
					 if(!this.props.repo.grade || this.props.repo.grade.length===0){
						window.location.hash='publish-gradeLevel'
					}else if(!this.props.repo.description ){
						window.location.hash='publish-description'
					}else if(!this.props.repo.image ){
						window.location.hash='publish-image'
					}else {
						window.location.hash='publish-review'
					}
				}

			})

		}

		else if(publishingFlowStage==='gradeLevel'){
			this.setState({nextBtnSubmitLoading:true})
			const isValidGradeRange=this.validateGradeLevels()
			
			if(isValidGradeRange){
				let gradeArray = []			
				if(this.state.selectedOtherGradeLevel){
					gradeArray=['other']
				}
				else if(this.state.selectedStaff){
					gradeArray=['staff']
				}
				else{
					const {selectedGradeLevels}=this.state
					Object.keys(selectedGradeLevels).map((level,index)=>{
						if(selectedGradeLevels[level]===true){
							gradeArray.push(level)
						}
					return null
					})
					if(this.state.selectedHigherEducation){
						gradeArray.push('higher')
					}

				}
				updatedRepo={
					...repo,
					'clientModified': nowDate,
					grade:gradeArray,
					gradeOther:this.state.gradeOtherString,
					language:language
				}


				this.props.updateRepo(updatedRepo).then(()=>{
					this.setState({nextBtnSubmitLoading:false})
					if(repoIsInReview){
						window.location.hash='publish-review'
					}else{
						if(!this.props.repo.description ){
							window.location.hash='publish-description'
						}else if(!this.props.repo.image ){
							window.location.hash='publish-image'
						}else {
							window.location.hash='publish-review'
						}
					}
				})



			}
			else{
				this.setState({nextBtnSubmitLoading:false})
				this.setState({error:'Grade-level needs to be a continuous range without gaps. Please check the grade range and try again.'})

			}
		}

		else if(publishingFlowStage==='description'){
			if(this.state.repoDescription){
				this.setState({nextBtnSubmitLoading:true})
				updatedRepo={
					...repo,
					'clientModified': nowDate,
					description:this.state.repoDescription	
				}
				this.props.updateRepo(updatedRepo).then(()=>{
					this.setState({nextBtnSubmitLoading:false})
					if(!this.props.repo.image ){
						window.location.hash='publish-image'
					}else {
						window.location.hash='publish-review'
					}
				})
			}else{
				if(!this.props.repo.image ){
					window.location.hash='publish-image'
				}else {
					window.location.hash='publish-review'
				}
			}
		
		}

		else if(publishingFlowStage==='image'){
			this.setState({nextBtnSubmitLoading:true})
			updatedRepo={
				...repo,
				'clientModified': nowDate,
				image:this.state.repoImage	
			}
			this.props.updateRepo(updatedRepo).then(()=>{
				this.setState({uploadingImage:false,repoIsInReview:true,nextBtnSubmitLoading:false})
				window.location.hash='publish-review'

			})


		}

		else if(publishingFlowStage==='review'){
			if((this.state.repoName !==this.props.repo.name) || (this.state.repoDescription !==this.props.repo.description) ){
				this.setState({nextBtnSubmitLoading:true})
				updatedRepo={
					...repo,
					'clientModified': nowDate,
					name:this.state.repoName,
					description:this.state.repoDescription	
				}
				this.props.updateRepo(updatedRepo).then(()=>{
					this.setState({nextBtnSubmitLoading:false})
				})
			}
			else {
				this.setState({nextBtnSubmitLoading:true})
				this.props.publishRepo()
			}
		}
		else if(publishingFlowStage==='success'){
				this.props.finishPublishingFlow()
		}

	}

	nextBtnIsDisabled(){
		const {publishingFlowStage,selectedSubjectId}=this.state
		let buttonDisabled=false

		if(publishingFlowStage==='subject' && !selectedSubjectId){
			buttonDisabled=true
		}
		else if(publishingFlowStage==='gradeLevel'){
			const {selectedGradeLevels,selectedHigherEducation,selectedOtherGradeLevel,selectedStaff}=this.state
			if(!selectedHigherEducation && !selectedOtherGradeLevel && !selectedStaff){
				let gradeArray=[]
				Object.keys(selectedGradeLevels).map((level,index)=>{
					if(selectedGradeLevels[level]===true){
						gradeArray.push(level)
					}
					return null
				})
				if(gradeArray.length===0){
					buttonDisabled=true
				}
			}
		}

		else if(publishingFlowStage==='image' && !this.state.repoImage){
			buttonDisabled=true
		}else if(publishingFlowStage==='review' && !this.state.confirmCheckboxChecked){
			if((this.state.repoName ===this.props.repo.name) && (this.state.repoDescription ===this.props.repo.description)){
				buttonDisabled=true
			}
			
			
		}

		return buttonDisabled
	}


	onChangeRepoDescription(value){
		const stringWithoutLineBreaks=value.replace(/\r?\n|\r/g, '')
		this.setState({repoDescription:stringWithoutLineBreaks})
	}

	onChangeRepoName(value){
		const stringWithoutLineBreaks=value.replace(/\r?\n|\r/g, '')
		this.setState({repoName:stringWithoutLineBreaks})
	}

	startPublishingFlow(){
		if(!this.state.selectedSubjectId){
				window.location.hash='publish-subject'
			}else if(!this.props.repo.grade || this.props.repo.grade.length===0 || !this.props.repo.language){
				window.location.hash='publish-gradeLevel'
			}else if(!this.props.repo.description ){
				window.location.hash='publish-description'
			}else if(!this.props.repo.image ){
				window.location.hash='publish-image'
			}else {
				window.location.hash='publish-review'
			}
	}



	render() {
		
		const {publishingFlowStage} = this.state

		const nextBtnDisabled = this.nextBtnIsDisabled()

		const nextBtnSubmitLoading = this.state.nextBtnSubmitLoading || this.state.uploadingImage

		let  nextBtnLabel = 'Next'

		if(this.state.repoIsInReview && publishingFlowStage !== 'review' && publishingFlowStage !== 'checklist'){
			nextBtnLabel = 'Done'
		}

		else if(publishingFlowStage === 'checklist'){
			nextBtnLabel = 'Add Pack Info'
		}
		
		else if(publishingFlowStage === 'description'){
			if(!this.state.repoDescription){
				nextBtnLabel = 'Skip'
			}else{
				nextBtnLabel = 'Next'
			}			
		
		}
		else if(publishingFlowStage === 'review'){
			if((this.state.repoName !==this.props.repo.name) || (this.state.repoDescription !==this.props.repo.description) ){
				nextBtnLabel = 'Save'
			}else{
				nextBtnLabel = 'Publish'	
			}		
		}
		else if(publishingFlowStage === 'success'){
				nextBtnLabel = 'Done'	
	
		}

		
		if(this.state.publishingFlowStage==='joinCreatorProgram'){
			return <JoinCreatorProgramContainer 
					hideModal={this.props.closePublishRepoModal}
					goToPublishingFlow={this.startPublishingFlow}	
				/>
		}

		else return(
			<PublishRepoModalWrapper hideModal={this.props.closePublishRepoModal} publishingFlowStage={this.state.publishingFlowStage}>
				<div className={'publishingFlowModal' + (publishingFlowStage ? ` publishingFlowModal--${publishingFlowStage}`:'')}>

					<PublishingFlowHeader 
						publishingFlowStage={publishingFlowStage}
						closePublishRepoModal={this.props.closePublishRepoModal}
					/>	
									
					
										
					{publishingFlowStage==='checklist' &&
						<PublishingFlowChecklistPage />
					}					

					{publishingFlowStage==='subject' &&
						<PublishingFlowSubjectPage 
							selectedSubjectId={this.state.selectedSubjectId}
							setSelectedSubjectId={(subjectId)=>{this.setState({selectedSubjectId:subjectId})}}
							selectedSubjectParentId={this.state.selectedSubjectParentId}
							setSelectedSubjectParentId={(parentId)=>{this.setState({selectedSubjectParentId:parentId})}}
							subjectOtherString={this.state.subjectOtherString}
							setSubjectOtherString={(string)=>{this.setState({subjectOtherString:string})}}
						/>
					}

					{publishingFlowStage==='gradeLevel' &&
						<PublishingFlowGradeLevelPage 
							selectedGradeLevels={this.state.selectedGradeLevels}
							selectedOtherGradeLevel={this.state.selectedOtherGradeLevel}
							selectedStaff={this.state.selectedStaff}
							selectedHigherEducation={this.state.selectedHigherEducation}
							selectGradeLevel={this.selectGradeLevel}
							selectOtherGradeLevel={this.selectOtherGradeLevel}
							selectStaff={this.selectStaff}
							selectHigherEducation={this.selectHigherEducation}
							gradeLevels={gradeLevels}
							language={this.state.language}
							selectLanguage={(language)=>{this.setState({language:language})}}
							gradeOtherString={this.state.gradeOtherString}
							setGradeOtherString={(grade)=>{this.setState({gradeOtherString:grade})}}

						/>
					}

					{publishingFlowStage==='description' &&
						<PublishingFlowDescriptionPage
							repoDescription={this.state.repoDescription}
							onChangeRepoDescription={this.onChangeRepoDescription}
						 />
					}
 
					{publishingFlowStage==='image' &&
						<PublishingFlowImagePage
							repo={this.props.repo}
							imageSearch={this.props.imageSearch}
							goToNextPage={this.nextButtonClick} 
							uploadRepoImageToCloudinary={this.props.uploadRepoImageToCloudinary}
							setRepoImage={(imageString)=>{this.setState({repoImage:imageString})}}
							beginImageUpload={()=>{this.setState({uploadingImage:true})}}
							endImageUpload={()=>{this.setState({uploadingImage:false})}}
							uploadingImage={this.state.uploadingImage}
							showFileTooBigError={()=>{this.setState({error:'Cannot upload image. Maximum image size is 20mb.'})}}
							hideFileTooBigError={()=>{this.setState({error:null})}}
							repoItems={this.props.repoItems}
							//selectedSubjectId={this.state.selectedSubjectId}
						 />
						
						
					}

					{publishingFlowStage==='review' &&
						<PublishingFlowReviewPage 
							language={this.state.language}
							repo={this.props.repo}
							repoName={this.state.repoName}
							onChangeNameInput={this.onChangeRepoName}
							repoDescription={this.state.repoDescription}
							onChangeRepoDescription={this.onChangeRepoDescription}
							confirmCheckboxChecked={this.state.confirmCheckboxChecked}
							toggleConfirmCheckbox={()=>{this.setState({confirmCheckboxChecked:!this.state.confirmCheckboxChecked})}}

						/>
					}


					{publishingFlowStage==='success' &&
						<PublishingFlowSuccessPage 
							repo={this.props.repo}												
						/>
					}

					

					




					<div className='publishingFlowModal-footer'>

						{this.state.error &&
							<div className='publishingFlowModal-footer-errorContainer'>
								<div className='publishingFlowModal-footer-error'>						
									{this.state.error}
								</div>
							</div>
						}
						
						{!this.state.repoIsInReview && publishingFlowStage!=='checklist' &&publishingFlowStage!=='success' &&
							<Button
								size='large'
								name='back'
								iconOnly
								iconName='chevron-left'			
								tooltipLabel='Go Back'
								tooltipLabelTypeLabel	
								onClickFunction={this.goToPreviousPublishingFlowStage}			
							/>
						}

						<button 
							onClick={this.nextButtonClick} 
							className={'btn btn--blue btn--large publishingFlowModal-footer-nextBtn ' + (nextBtnSubmitLoading ? ' publishingFlowModal-footer-nextBtn--submitLoading ' :'') + ((nextBtnDisabled && !nextBtnSubmitLoading) ? 'publishingFlowModal-footer-nextBtn--disabled':'')}
						>
							
							{!nextBtnSubmitLoading &&		
								<React.Fragment>						
									{nextBtnLabel}
								</React.Fragment>
							}

							{nextBtnSubmitLoading &&
								<div className='publishingFlowModal-footer-nextBtn-loadingSpinnerContainer'>
									<div className='publishingFlowModal-footer-nextBtn-loadingSpinner' />
								</div>
							}
																							
						</button>

						{!nextBtnDisabled && !nextBtnSubmitLoading && publishingFlowStage !== 'review' && publishingFlowStage !== 'image' &&
							<div className='publishingFlowModal-footer-pressEnterHint'>
								Press <b>ENTER</b>
							</div>
						}

					</div>
					</div>

				
				</PublishRepoModalWrapper>
			
			)
		
	}

}




function mapStateToProps(state,ownProps) {
	let repoItems=[]
	if(ownProps.repo){
		const repoId=ownProps.repo.id
		
		const sets=filter(state.sets, {'repo':repoId})
		const questions=filter(state.questions, {'repo':repoId})
		repoItems=sets.concat(questions)
	}
	return {
		user:state.user,
		repoItems:repoItems

	  }
}
 


export default withRouter(connect(
	mapStateToProps,
	{ updateUser,testUsername,imageSearch,updateRepo,uploadRepoImageToCloudinary }
)(PublishRepoFlowContainer))









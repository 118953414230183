import {Plugin, PluginKey} from 'prosemirror-state'

const key = new PluginKey('focus')

//Triggers an update on focus/blur via dispatchTransaction so other plugins are triggered
//https://discuss.prosemirror.net/t/trigger-plugin-update-when-view-gets-focus/2048

export function focusPlugin(){
  return new Plugin({
  key,
  state: {
    init() {
      return false;
    },
    apply(transaction, prevFocused) {
      let focused = transaction.getMeta(key);
      if (typeof focused === 'boolean') {
        return focused;
      }
      return prevFocused;
    }
  },
  props: {
    handleDOMEvents: {
      blur: view => {
        let tr=view.state.tr
        tr.setMeta("addToHistory", false)    
        tr.setMeta(key, false)
        view.dispatch(tr);
        return false;
      },
      focus: view => {
         let tr=view.state.tr
        tr.setMeta("addToHistory", false)    
        tr.setMeta(key, true)
        view.dispatch(tr);
        //view.dispatch(view.state.tr.setMeta(key, true));
        return false;
      }
    }
  }
})
}


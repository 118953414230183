import React, { Component } from 'react'
import { connect } from 'react-redux'
import { InstantSearch, Configure } from 'react-instantsearch-dom' 
import algoliasearch from 'algoliasearch/lite'

//Wrapper used for sets/questions/repos search
//used in topNav search bar, add to queue modal in section home page, and search results page

class AlgoliaSearchWrapperContainer extends Component {

	constructor(props) {
		super(props)
		this.searchClient=null
		if(props.algoliaSearchKey){
			this.searchClient=algoliasearch(
				process.env.REACT_APP_PLICKERS_ALGOLIA_APP_ID,
				props.algoliaSearchKey
			)
		}
	} 

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.algoliaSearchKey && !this.props.algoliaSearchKey && !this.searchClient ){
			this.searchClient=algoliasearch(
				process.env.REACT_APP_PLICKERS_ALGOLIA_APP_ID,
				nextProps.algoliaSearchKey
			)
		}
	}

	render() {
		if(this.props.algoliaSearchKey){	
			return(
				<InstantSearch
					searchClient={this.searchClient}
					indexName={this.props.isSectionHome?'sets_questions':'repos'}
					searchState={this.props.searchState}
					createURL={this.props.createURL}
					onSearchStateChange={this.props.onSearchStateChange}
				>
				<Configure filters="archived!=1 AND parentArchived!=1" />
					{this.props.children}
				</InstantSearch>
			)
		}else if(this.props.isTopNav){
			return <input disabled={true} className='topNav-search'/>
		} else return null
	}
}

function mapStateToProps(state) {
	const searchKey=state.searchKey
	let contentsSearchKey
	if(searchKey){
		contentsSearchKey=searchKey.contents
	}
	return {
		 algoliaSearchKey:contentsSearchKey
	}
}

export default connect(
	mapStateToProps,
	{ }
)(AlgoliaSearchWrapperContainer)
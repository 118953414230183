import React from 'react'
import EditorIcon from '../../misc/EditorIcons'
import { showInsertImageModal } from '../utils/showInsertImageModal'
import { showInsertGIFModal } from '../utils/showInsertGIFModal'
import { showInsertVideoModal } from '../utils/showInsertVideoModal'
import { showInsertSoundModal } from '../utils/showInsertSoundModal'

class EditorCanvasInsertBarButton extends React.Component {
  render() {
    const { onClick } = this.props
    return (
      <button className='editor-canvas-insertBar-btn' onClick={onClick}>
        <EditorIcon name={this.props.iconName} />
        <div className='editor-canvas-insertBar-btn-tooltipLabelContainer'>
          <div className='editor-canvas-insertBar-btn-tooltipLabel'>
            {this.props.tooltipLabel}
          </div>
        </div>
      </button>
    )
  }
}

function getInsertParams() {
  let insertPos
  let questionPos
  let questionNode
  window.view.state.doc.nodesBetween(0, window.view.state.doc.content.size, (node, pos) => {
    if (node.attrs.activeslide && node.attrs.activeslide === true) {
      questionPos = pos
      questionNode = node
      window.view.state.doc.nodesBetween(pos, pos + node.nodeSize, (questionNode, questionPos) => {
        if (questionNode.type.name === 'questionBody') {
          insertPos = {
            start: questionPos + questionNode.nodeSize,
            end: questionPos + questionNode.nodeSize,
          }
        }
      })
    }
  })
  return {
    insertPos,
    questionNode,
    questionPos,
  }
}

class EditorCanvasInsertBar extends React.Component {
  constructor() {
    super()
    this.showInsertVideoModal = this.showInsertVideoModal.bind(this)
    this.showInsertImageModal = this.showInsertImageModal.bind(this)
    this.showInsertSoundModal = this.showInsertSoundModal.bind(this)
  }

  showInsertVideoModal() {
    const insertParams = getInsertParams()
    const { insertPos, questionPos, questionNode } = insertParams
    const searchState = null
    showInsertVideoModal(searchState, insertPos, true, questionPos, questionNode)
  }

  showInsertSoundModal(openOnSoundLibrary) {
    const insertParams = getInsertParams()
    const { insertPos, questionPos, questionNode } = insertParams
    const isQuestion = true
    showInsertSoundModal(insertPos, isQuestion, questionPos, questionNode, openOnSoundLibrary)
  }

  showInsertImageModal() {
    const insertParams = getInsertParams()
    const { insertPos, questionPos, questionNode } = insertParams
    const onCloseModal = null
    showInsertImageModal(insertPos, onCloseModal, true, questionPos, questionNode)
  }

  showInsertGIFModal() {
    const insertParams = getInsertParams()
    const { insertPos, questionPos, questionNode } = insertParams
    const isQuestion = true
    showInsertGIFModal(insertPos, isQuestion, questionPos, questionNode)
  }

  render() {
    return (
      <div className='editor-canvas-insertBar'>
        <EditorCanvasInsertBarButton iconName='insertImage' tooltipLabel='Image' onClick={this.showInsertImageModal} />
        <EditorCanvasInsertBarButton iconName='insertVideo' tooltipLabel='Video' onClick={this.showInsertVideoModal} />
        <EditorCanvasInsertBarButton onClick={() => { this.showInsertSoundModal(false) }} iconName='insertSound' tooltipLabel='Sound Clip' />
        <EditorCanvasInsertBarButton onClick={() => { this.showInsertSoundModal(true) }} iconName='insertSoundLibrary' tooltipLabel='Sound Library' />
        <EditorCanvasInsertBarButton iconName='insertGIF' tooltipLabel='GIF' onClick={this.showInsertGIFModal} />
      </div>
    )
  }
}

export default EditorCanvasInsertBar

module.exports = [
	'sound/63f4654d994c1d28fee2a23f627c87ec',
	'sound/012c1e6c0ec7b4ba548fcd40009b8c03',
	'sound/9547f096e6647f4f33aa61029f3fca00',
	'sound/b7f9c1508c0ee70fa00e7b2538afa71a',
	'sound/7a5340817f12b82519ba5ef5af2bb510',
	'sound/8616a8380402cb90243cf67eb943bc9c',
	'sound/13965e1dc8ed29610776b57de89f9f23',
	'sound/2f01c834b4b230e25ab23808fc623a8d',
	'sound/ea5b9228d27a6c7da97214e43c729e49'
]
import React from 'react'
import {injectStripe} from 'react-stripe-elements'
import CardSection from './CardSection'

 
const MONTHLY_PLAN_ID=process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID
const YEARLY_PLAN_ID=process.env.REACT_APP_STRIPE_YEARLY_PLAN_ID


class CheckoutForm extends React.Component {

	constructor(props){
		super(props)
		this.handleSubmit=this.handleSubmit.bind(this)
		this.state=({ 	
 			addressLine1:'',
 			addressZip:'',
 			fullName:'',
 			submitLoading:false,
 			showNewCard: false,
 			activeCard:'existingCard'
 		})     
	} 
 
	handleSubmit(ev){
		this.setState({submitLoading:true})
		ev.preventDefault()		// We don't want to let default form submission happen here, which would refresh the page.
		const {billingInfo}=this.props
		let plan=YEARLY_PLAN_ID
		if(this.props.plan==='month'){
			plan=MONTHLY_PLAN_ID
		}
		if(billingInfo && this.state.activeCard==='existingCard'){
			
			this.props.upgradePlan(plan).then((response)=>{
				
				if(response==='error'){
					this.setState({submitLoading:false})
				}
			})
		}else{
			this.props.stripe.createToken({'name':this.state.fullName,'address_line1':this.state.addressLine1,'address_zip':this.state.addressZip,'email':this.props.email}).then(({token}) => {	
				if(token){
					this.props.upgradePlan(plan,token.id).then((response)=>{
						if(response==='error'){
							this.setState({submitLoading:false})
						}
					})
				}else{this.setState({submitLoading:false})}

			})
		}
	}
 
	render() {
		const {submitLoading, showNewCard, activeCard} =this.state 
		const {upgradeError,trialUsed,billingInfo}=this.props
		const hasSavedCard = billingInfo? true:false
		let currentCardBrand
		let currentCardLast4
		if(billingInfo){
			currentCardBrand=billingInfo.brand
			currentCardLast4=billingInfo.last4
		}

		return (			
			<form translate="no" className={'notranslate upgrade--payment-paymentForm' + (submitLoading ? ' upgrade--payment-paymentForm--submitLoading' : '')} onSubmit={this.handleSubmit}>
				 	       			   
				{hasSavedCard &&
				<div onClick={()=>this.setState({activeCard:'existingCard'})} className={'upgrade--payment-paymentForm-savedCard upgrade--payment-paymentForm-savedCard--currentCard' + (activeCard === 'existingCard' ? ' upgrade--payment-paymentForm-savedCard--active' : '')}>
					<div className='upgrade--payment-paymentForm-savedCard-radio'>
						<div className='upgrade--payment-paymentForm-savedCard-radio-inner'/>
					</div>
					<div className='upgrade--payment-paymentForm-savedCard-right'>
							Use Current Card
						<div className='upgrade--payment-paymentForm-savedCard--currentCard-cardNumber'>
							{currentCardBrand} •••• •••• •••• {currentCardLast4}
						</div>	
					</div>				
				</div>
				}
				{hasSavedCard &&
				<div onClick={()=>this.setState({showNewCard:true, activeCard:'newCard'})} className={'upgrade--payment-paymentForm-savedCard upgrade--payment-paymentForm-savedCard--newCard' + (activeCard === 'newCard' ? ' upgrade--payment-paymentForm-savedCard--active' : '')}>
					<div className='upgrade--payment-paymentForm-savedCard-radio'>
						<div className='upgrade--payment-paymentForm-savedCard-radio-inner'/>
					</div>
					<div className='upgrade--payment-paymentForm-savedCard-right'>
						Use New Card
					</div>
				</div>
				}

				{hasSavedCard && !showNewCard &&
					<div className='upgrade--payment-paymentForm-savedCard-newCardSpacer' />
				}

				{(!hasSavedCard || (hasSavedCard && showNewCard)) &&

				<div className='upgrade--payment-paymentForm-newCardContainer'>

	        <input 
	        	className='upgrade--payment-paymentForm-field'
	        	onChange={(e)=>{this.setState({fullName:e.target.value})}}
	        	name="name"
	        	type="text"
	        	placeholder="Cardholder Name"
	        	required={activeCard==='newCard'}
	        	value={this.state.name}
	        	maxLength={50}
	        	disabled={submitLoading}
	        />        		
	              
	        <input
	        	className='upgrade--payment-paymentForm-field upgrade--payment-paymentForm-field--email'
	         	name="email"
	         	type="email"
	         	value={this.props.email}
	          readOnly       
	          disabled
	        />
			        			    
			    <div className='upgrade--payment-paymentForm-addressContainer'>    

						<input
							value={this.state.addressLine1}
							onChange={(e)=>{this.setState({addressLine1:e.target.value})}}
							name="address_line1"
							id='address_line1'
							className='upgrade--payment-paymentForm-field upgrade--payment-paymentForm-field--addressLine'
							type="text"
							placeholder='Street Address'
							required={activeCard==='newCard'}
							maxLength={150}
							disabled={submitLoading}
						/>

						<input
							value={this.state.addressZip}
							onChange={(e)=>{this.setState({addressZip:e.target.value})}}
							name="address-zip"
							maxLength={11}
							className='upgrade--payment-paymentForm-field upgrade--payment-paymentForm-field--postcode'    
							type="text"
							placeholder='ZIP'
							required={activeCard==='newCard'}
							disabled={submitLoading}
						/>		       
      
		   		</div>		    

					<CardSection />

				</div>

				}

				<button className='upgrade--payment-paymentForm-submit'>
					{!submitLoading &&
						<React.Fragment>
							{!trialUsed &&							
								<React.Fragment>
									Start 14-day Free Trial

									{this.props.plan === 'month' &&
										<div className='upgrade--payment-paymentForm-submit-sub'>
										then pay {this.props.monthlyPricePerMonth} / month
										</div>
									}

									{this.props.plan ==='year' &&
										<div className='upgrade--payment-paymentForm-submit-sub'>
											then pay {this.props.yearlyPricePerYear}
										</div>
									}
								</React.Fragment>
							}
							{trialUsed &&							
								<React.Fragment>
									Upgrade to Pro Now

									{this.props.plan === 'month' &&
										<div className='upgrade--payment-paymentForm-submit-sub'>
											Pay {this.props.monthlyPricePerMonth} / month
										</div>
									}

									{this.props.plan ==='year' &&
										<div className='upgrade--payment-paymentForm-submit-sub'>
											Pay {this.props.yearlyPricePerYear}
										</div>
									}
								</React.Fragment>
							}

						</React.Fragment>
					}

					{submitLoading &&
						<div className='upgrade--payment-paymentForm-submitSpinnerContainer'>
							<div className='upgrade--payment-paymentForm-submitSpinner'/>
						</div>
					}

				</button>

				{upgradeError && !submitLoading &&
					<div className='upgrade--payment-paymentForm-error'>
						{upgradeError}
					</div>
				}
			</form>

		)
	}
}

export default injectStripe(CheckoutForm)
import React from 'react'
import StudentReportPackStudent from '../studentReportPack/StudentReportPackStudent'
import Button from '../../../uiKit/Button'

const studentsInClassPreview=5

class PrintPreview extends React.Component{

	constructor(props) {
		super(props)
		this.changePreviewStudent=this.changePreviewStudent.bind(this)

		this.state = {
			showPreview:false,
			selectedPreviewStudent:null,
			showAllStudents:false,
		}
	} 


	componentDidMount(){
		//get heights of ghosts
		//then show preview
		setTimeout(function() { 
			this.setState({showPreview:true})
		}.bind(this),	1)
	}


	

	changePreviewStudent(student){
		this.setState({selectedPreviewStudent:student})
	}



	render(){ 
		const {
			students,
			guests,
			reportPackSettings,
			showStudentScores,
			studentsWithTotals,
			totalPollCount,
			showResponses,		
			reportPollsByStudent,
			ghostHeights,
			paperSize
		}=this.props		

		const columnCount = reportPackSettings.columnCount
		const {selectedPreviewStudent,isSafari, showAllStudents}=this.state


		

		return(			
			<div className='featureModal-previewContainer'>
				<div className='featureModal-preview'>	

					{this.state.showPreview &&
							
							<div className={'printPack printPack--rendered ' + (paperSize === 'letter' ? ' printPack--letter ': ' printPack--a4 ') + (columnCount === 2 ? 'printPack--twoColumn ' : '') + (columnCount === 1 ? 'printPack--singleColumn ' : '') + (isSafari ? ' printPack--isSafari ': '')}>

							{students &&students.map((student,index) => {
								let shouldRender=false
								if(showAllStudents){
									shouldRender=true
								}
								else if(!selectedPreviewStudent && index<studentsInClassPreview){
									shouldRender=true
								}
								if(selectedPreviewStudent && selectedPreviewStudent.id===student.id){
									shouldRender=true
								}

								if(shouldRender){
								const studentTotals=studentsWithTotals[student.id]
								return(						 	 	
									<StudentReportPackStudent
									paperSize={paperSize}
										ghostHeights={ghostHeights}
										reportPackSettings={this.props.reportPackSettings}
										highlightCorrect={this.props.highlightCorrect}
										showQuestionGrades={this.props.showQuestionGrades} 
										personalizedContentLabel={this.props.personalizedContentLabel}
										totalPollCount={totalPollCount} 
										studentTotals={studentTotals} 
										showStudentScores={showStudentScores} 
										student={student} 
										dateRangeLabel={this.props.dateRangeLabel}
										//historyPolls={historyPolls} 
										studentPolls={reportPollsByStudent[student.id]}
										key={student.id}
										showResponses={showResponses}
									/>			
			        			)}else return null
							})} 
							{guests &&guests.map((guest,index) => {
								let shouldRender=false
								if(showAllStudents){
									shouldRender=true
								}
								else if(!selectedPreviewStudent && index<studentsInClassPreview){
									shouldRender=true
								}
								if(selectedPreviewStudent && selectedPreviewStudent===guest){
									shouldRender=true
								}
								if(shouldRender){
								// if(guest ===selectedPreviewStudent){
								const studentTotals=studentsWithTotals[guest]
								return(						 	 	
									<StudentReportPackStudent
											paperSize={paperSize}
										showQuestionGrades={this.props.showQuestionGrades} 
										ghostHeights={ghostHeights}
										reportPackSettings={this.props.reportPackSettings}
										highlightCorrect={this.props.highlightCorrect} 
										personalizedContentLabel={this.props.personalizedContentLabel}
										totalPollCount={totalPollCount} 
										studentTotals={studentTotals} 
										showStudentScores={showStudentScores} 
										guest={guest}
										isGuest={true}
										dateRangeLabel={this.props.dateRangeLabel}
										//historyPolls={historyPolls} 
										studentPolls={reportPollsByStudent[guest]}
										key={guest}
										showResponses={showResponses}
									/>			
			        			)}else return null
							})} 

							{!selectedPreviewStudent && !showAllStudents && !this.props.individualReportPackStudent &&
								<div className='featureModal--studentReport-preview-showAllStudentsContainer'>
									<Button label='Show all students' onClickFunction={()=>{this.setState({showAllStudents:true})}} />
								</div>
							}		

						</div>			
					
					}
				</div>
			</div>

		)
	}
}


export default PrintPreview

import React from 'react'
import SectionHomeLive from './SectionHomeLive'
import SectionHomeResumableSetPoll from './SectionHomeResumableSetPoll'
import {getSetForId} from '../../../../utils/getSetForId'



//In-Progress is either Live set/question or a resumable set poll


class SectionHomeInProgress extends React.Component{

	
	render(){
		const {livePoll,resumableSetPoll,control,section}=this.props
		
		if(livePoll){
			return(
				<SectionHomeLive
					livePoll={livePoll}
					control={control}
				/>
			)
		}
		else{
			const setObject=getSetForId(resumableSetPoll.setObject)	
			 if(resumableSetPoll){
			 return(
					<SectionHomeResumableSetPoll
						key={resumableSetPoll.id}
						resumableSetPoll={resumableSetPoll}
						setObject={setObject}
						markDone={this.props.markDone}
						section={section}
					/>

				)
			}else return null
		}
	}
}


export default SectionHomeInProgress

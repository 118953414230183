import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
// TODO: fix cycle reference
/* eslint-disable import/no-cycle */
import rootReducer from './reducers/index'
/* eslint-enable import/no-cycle */
import { storageMiddleware } from './tabSyncMiddleware'

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(thunk, storageMiddleware()),
)

export default store

import React from 'react'
import EditorIcon from '../../../../misc/EditorIcons'
import {formatRecordingDuration} from './utils/formatRecordingDuration'

const CONSTRAINTS = { audio: true }


class EditorSoundRecordingPage extends React.Component{  

	constructor() {
		super()
		this.startRecording=this.startRecording.bind(this)
		this.stopRecording=this.stopRecording.bind(this)
		this.onSuccess=this.onSuccess.bind(this)
		this.onDataAvailable=this.onDataAvailable.bind(this)
		this.onStopRecording=this.onStopRecording.bind(this)
		this.startTimer=this.startTimer.bind(this)
		this.stopTimer=this.stopTimer.bind(this)
		this.visualize=this.visualize.bind(this)
		this.handleKeyUp=this.handleKeyUp.bind(this)
		this.onError=this.onError.bind(this)
		
		this.state = {
			recordingDuration:0,
			audioURL:null,
			recordingStartTime:null,
			userHadBlockedMicrophoneAccess:false
		}
		this.mediaRecorder = null
		this.chunks=[]
		this.blob=null
		this.timer=null
	}

	componentDidMount(){
		document.addEventListener('keyup', this.handleKeyUp)
		this.startRecording()
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.handleKeyUp)
		 if(window && window.stream){
			window.stream.getTracks() // get all tracks from the MediaStream
				.forEach( track => track.stop() ); // stop each of them
		}
	}

	handleKeyUp(e){
		if(e.keyCode === 32){
			this.stopRecording()
		}
	}

	startRecording(){
		this.chunks=[]
		this.blob=null
		this.setState({audioURL:null})
		navigator.mediaDevices.getUserMedia(CONSTRAINTS).then(this.onSuccess, this.onError)
	}

	onError(){//Handle permissions error
		this.props.closeModal()
	}

	stopRecording(){
		this.mediaRecorder.stop()
	}

	onSuccess(stream){
		this.setState({recordingDuration:0})
		if(!this.mediaRecorder){
			this.mediaRecorder = new MediaRecorder(stream)
			this.mediaRecorder.audioChannels = 1
			this.mediaRecorder.ondataavailable = this.onDataAvailable
			this.mediaRecorder.onstop = this.onStopRecording
			this.mediaRecorder.onstart = this.startTimer
		}
		window.stream=stream
		this.mediaRecorder.start();
		this.visualize(stream)
	}

	visualize(stream) {
		if(!this.audioCtx) {
			var AudioContext = window.AudioContext // Default
		|| window.webkitAudioContext // Safari and old versions of Chrome https://stackoverflow.com/questions/29373563/audiocontext-on-safari
		|| false; 
		if(AudioContext){
			this.audioCtx = new AudioContext()
			}
		}
		if(this.audioCtx){
			const WIDTH = this.canvas.width
			const HEIGHT = this.canvas.height    
			const source = this.audioCtx.createMediaStreamSource(stream)
			const analyser = this.audioCtx.createAnalyser()
			analyser.fftSize = 2048
			//const bufferLength = analyser.frequencyBinCount
		 // const dataArray = new Uint8Array(bufferLength)
			source.connect(analyser)
			const canvasCtx = this.canvas.getContext("2d")
			analyser.fftSize = 64
			var bufferLengthAlt = analyser.frequencyBinCount
			var dataArrayAlt = new Uint8Array(bufferLengthAlt)
			canvasCtx.clearRect(0, 0, WIDTH, HEIGHT)
			drawAlt()
				function drawAlt() {    
					requestAnimationFrame(drawAlt);        
					analyser.getByteFrequencyData(dataArrayAlt);
					const centerX = WIDTH / 2;
					const centerY = (HEIGHT / 2); // not vertically centered           

					canvasCtx.fillStyle = 'rgb(253, 248, 249)';
					canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

					// let circleRadius
				 // var barHeight
					//var renderedRadiusFloored
				 // var renderedRadiusOther
					var frequencyArraySum = 0
					const minRange = 3
					const maxRange = 16
					for( var i = minRange; i < maxRange; i++ ){
						frequencyArraySum += parseInt( dataArrayAlt[i], 10 ); //don't forget to add the base
					}
					var frequencyArrayAverage = frequencyArraySum/dataArrayAlt.length;
					//const medianIndex = 24 // middle of 32 kind of        
					// circleRadius = frequencyArrayAverage
					canvasCtx.beginPath();
					canvasCtx.arc(centerX, centerY, ((frequencyArrayAverage + 70)), 0, 2 * Math.PI, false);
					canvasCtx.fillStyle = 'rgb(254,236,237)';
					canvasCtx.fill();      
			} 
		}
	}
 
	startTimer(){
		if(!this.state.recordingStartTime){
			this.setState({recordingStartTime:Date.now()})
		}
		this.timer = setInterval(() => {
			let recordingDuration=0
			if(this.state.recordingStartTime){
				recordingDuration=Date.now() - this.state.recordingStartTime
			}
			this.setState({recordingDuration:recordingDuration})
		}, 10)
	}

	stopTimer(){
		const recordingDuration=Date.now() - this.state.recordingStartTime
		this.setState({recordingDuration:recordingDuration,recordingStartTime:null})
		clearInterval(this.timer)
		this.timer=null
	}
	
	onDataAvailable(e){
		this.chunks.push(e.data)
	}

	onStopRecording(e) {
		this.stopTimer()
		const blob = new Blob(this.chunks, { 'type' : 'audio/mp4' });
		this.chunks = [];
		const audioURL = window.URL.createObjectURL(blob);
		this.blob=blob
		this.props.onFinishRecording(audioURL,this.state.recordingDuration,blob)
	}

	render(){  
		const {recordingType} = this.props
		const {userHadBlockedMicrophoneAccess}=this.state
		return (	  	
			<React.Fragment>
				{!userHadBlockedMicrophoneAccess &&
					<div className='editor-insertSoundModal editor-insertSoundModal--recording'>			
						<div onClick={this.stopRecording} className='editor-insertSoundModal--recording-recordingContainer'>																										 
								{recordingType === 'spacebarRecording' &&
									<button className='editor-insertSoundModal--recording-endRecordBtn'>
										<EditorIcon name='microphone' />
									</button>
								}
								{recordingType === 'clickRecording' &&
									<button className='editor-insertSoundModal--recording-endRecordBtn'>			  					
										<div className='editor-insertSoundModal--recording-endRecordBtn-stopIcon'/>
									</button>
								}																					
								<canvas ref={c => (this.canvas = c)} className="editor-insertSoundMiniModal-recordBtn-visualizer" height="315px" width="400px"></canvas>				  									
							</div>

							<div className='editor-insertSoundModal--recording-footer'>
								<div className='editor-insertSoundModal--recording-completedTime'>
									{formatRecordingDuration(this.state.recordingDuration)}								
								</div>
							</div>
						</div>  
					}
				{userHadBlockedMicrophoneAccess && //old testing message, user should now be blocked from reaching this page
					<div className='editor-insertSoundModal editor-insertSoundModal--recording'>
						<div>ACCESS BLOCKED </div>
					</div>
				}
			</React.Fragment>  			     	
		)
	}
}

export default EditorSoundRecordingPage

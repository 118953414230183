 
export function getPersonalizedContentLabel(showCorrect,showIncorrect,showMissing,showSurveys) {
	let label=''
	if(showCorrect && showIncorrect && showMissing && showSurveys){
		label='Showing all questions'
	}else if(showCorrect && showIncorrect && showMissing && !showSurveys){
		label='Showing all non survey questions'
	}else if(showCorrect && showIncorrect && !showMissing && showSurveys){
		label='Showing all questions with responses'
	}else if(showCorrect && !showIncorrect && showMissing && showSurveys){
		label='Showing all questions excluding incorrect'
	}else if(!showCorrect && showIncorrect && showMissing && showSurveys){
		label='Showing all questions excluding correct'
	}else if(!showCorrect && !showIncorrect && !showMissing && showSurveys){
		label='Showing only surveys'
	}else if(!showCorrect && !showIncorrect && showMissing && !showSurveys){
		label='Showing all non survey Questions without responses'
	}else if(!showCorrect && showIncorrect && !showMissing && !showSurveys){
		label='Showing all questions answered incorrectly'
	}else if(showCorrect && !showIncorrect && !showMissing && !showSurveys){
		label='Showing all questions answered correctly'
	}else if(showCorrect && showIncorrect && !showMissing && !showSurveys){
		label='Showing all non survey questions with responses'
	}else if(!showCorrect && !showIncorrect && showMissing && showSurveys){
		label='Showing all missing and survey questions'
	}else if(!showCorrect && showIncorrect && showMissing && !showSurveys){
		label='Showing all non survey questions answered incorrectly or missing '
	}else if(showCorrect && !showIncorrect && !showMissing && showSurveys){
		label='Showing surveys and correct '
	}

	return label
}


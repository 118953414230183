import * as Sentry from '@sentry/browser'
import api from '../api'
import { fetchQuestionPolls, fetchSetPolls } from './polls'
// eslint-disable-next-line import/no-cycle
import { sectionPresencePusherSetup } from '../utils/pusher/sectionPresencePusherSetup'
import { fetchIntegrations } from './integrations'

export function fetchSections() {
  return (dispatch) => api.batchedFetch('/sections?archived=false&populated=true')
    .then((response) => {
      dispatch({ type: 'FETCH_All_SECTIONS_SUCCESS', response })
      return response
    })
    .catch((/* err */) => {})
}

export function fetchArchivedSections() {
  return (dispatch) => api.batchedFetch('/sections?archived=true&populated=true')
    .then((response) => {
      dispatch({ type: 'FETCH_ARCHIVED_SECTIONS_SUCCESS', response })
    })
    .catch((/* err */) => {})
}

export function fetchSection(sectionId) {
  return (dispatch) => api.fetch(`/sections/${sectionId}?populated=true`)
    .then((response) => {
      dispatch({ type: 'FETCH_SECTION_SUCCESS', response })
    })
    .catch((/* err */) => {})
}

export function newSection(data) {
  return (dispatch) => api.post('/sections/', data)
    .then((response) => {
      dispatch({ type: 'CREATE_SECTION_SUCCESS', response })
      dispatch({ type: 'HIDE_MODAL' })
      return response
    })
    .catch((err) => {
      Sentry.withScope((scope) => {
        scope.setExtra('request', data)
        Sentry.captureException(new Error(err))
      })
    })
}

export function deleteSection(sectionId) {
  return (dispatch) => api.delete(`/sections/${sectionId}`)
    .then((/* response */) => {
      dispatch({ type: 'DELETE_SECTION_SUCCESS', sectionId })
    })
    .catch((err) => {
      Sentry.withScope((scope) => {
        scope.setExtra('request', sectionId)
        Sentry.captureException(new Error(err))
      })
    })
}

export function updateSection(data, sectionId) {
  if (!sectionId) { // TEMP for debugging
    Sentry.withScope((scope) => {
      scope.setExtra('sectionId', sectionId)
      Sentry.captureException('update section control without sectionId')
    })
  }
  return (dispatch) => api.put(`/sections/${sectionId}`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_SECTION_SUCCESS', response })
      return response
    })
    .catch((err) => {
      Sentry.withScope((scope) => {
        scope.setExtra('request', data)
        Sentry.captureException(new Error(err))
      })
    })
}

export function archiveSection(data, sectionId) {
  return (dispatch) => api.put(`/sections/${sectionId}`, data)
    .then((response) => {
      dispatch(fetchSections())
      dispatch(fetchArchivedSections())
      dispatch({ type: 'ARCHIVE_SECTION_SUCCESS', response })
      return response
    })
    .catch((/* err */) => {})
}

export function unarchiveSection(data, sectionId) {
  return (dispatch) => api.put(`/sections/${sectionId}`, data)
    .then((response) => {
      // refetch data after unarchive section
      dispatch({ type: 'UNARCHIVE_SECTION_SUCCESS', response })
      dispatch(fetchSection(response.id))
      dispatch({ type: 'CLEAR_QUESTION_POLLS_REFRESHED_TIMESTAMP' })
      dispatch({ type: 'CLEAR_SET_POLLS_REFRESHED_TIMESTAMP' })
      dispatch(fetchSections())
      dispatch(fetchArchivedSections())
      dispatch(fetchQuestionPolls())
      dispatch(fetchSetPolls())
      return response
    })
}

// Section preferences e.g.setupPromptDismissed
export function updateSectionPreferences(data, sectionId) {
  return (dispatch) => api.put(`/sections/${sectionId}/preferences`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_SECTION_SUCCESS', response })
    })
    .catch((err) => {
      Sentry.withScope((scope) => {
        scope.setExtra('request', data)
        Sentry.captureException(new Error(err))
      })
    })
}

export function assignSectionToDataOwner(sectionId, dataOwnerId) {
  return (dispatch) => api.put(`/sections/${sectionId}/dataOwner/associate`, {
    dataOwner: dataOwnerId,
  })
    .then((response) => {
      dispatch({ type: 'UPDATE_SECTION_SUCCESS', response })
    })
}

/// ///// Google Classroom ///////////

export function newSectionsFromIntegration(data) {
  return (dispatch) => api.put('/sections/from-integration', data)
    .then((response) => {
      dispatch(fetchSections())
      dispatch(fetchIntegrations())
      return response
    })
    .catch((/* err */) => {})
}

export function syncSectionWithIntegration(sectionId) {
  return (dispatch) => api.put(`/sections/${sectionId}/integration/sync`)
    .then((response) => {
      dispatch({ type: 'FETCH_SECTION_SUCCESS', response })
      if (window.analytics) {
        window.analytics.track('Sync Google Classroom Students', {
          syncFailed: false,
        })
      }
      return response
    })
    .catch((err) => {
      if (window.analytics) {
        let status
        if (err.response && err.response.status) {
          status = err.response.status
        }
        window.analytics.track('Sync Google Classroom Students', {
          syncFailed: true,
          status,
        })
      }
      throw err
    })
}

// Delink class from Google Classroom
export function removeIntegrationFromSection(sectionId) {
  return (dispatch) => api.put(`/sections/${sectionId}/integration/remove?populated=true`)
    .then((response) => {
      if (window.analytics) {
        window.analytics.track('Remove integration from section', { integration: 'Google Classroom' })
      }
      dispatch({ type: 'FETCH_SECTION_SUCCESS', response })
    })
    .catch((/* err */) => {})
}

/// /////E-Learning//////////

// enable E-Learning for section
export function toggleStudentAccessToSection(sectionId, enable, userId, hasStudentsUnder13, hasStudentsInUS, consentProvided) {
  const requestData = { enabled: enable }
  return (dispatch) => api.put(`/sections/${sectionId}/toggle-student-access`, requestData)
    .then((response) => {
      const studentsPopulated = true
      dispatch({ type: 'UPDATE_SECTION_SUCCESS', response, studentsPopulated })
      if (enable) {
        sectionPresencePusherSetup(sectionId)
      }
      if (window.analytics) {
        if (enable) {
          window.analytics.track('enable section student access', {
            sectionStudentCount: response.students.length,
            sectionId,
            userId,
            hasStudentsUnder13,
            hasStudentsInUS,
            consentProvided,
          })
        } else {
          window.analytics.track('disable section student access', {
            sectionStudentCount: response.students.length,
          })
        }
      }
    })
    .catch((/* err */) => {})
}

export function fetchSectionAuthorizations(sectionId) {
  return (dispatch) => api.fetch(`/sections/${sectionId}/authorizations?limit=500`)
    .then((response) => {
      dispatch({ type: 'FETCH_SECTION_AUTHORIZATIONS_SUCCESS', sectionId, response })
    })
    .catch((/* err */) => {})
}

export function sectionEmailStudentStudentCodes(sectionId, data) {
  return (/* dispatch */) => api.post(`/sections/${sectionId}/integration/email-links`, data)
    .then((/* response */) => {})
}

import React from 'react' 

//Old repo submission stuff from creator fund
//May want to take this out at some point

class RepoDefaultPreviewPanelSubmitStatus extends React.Component{
	render(){ 		
		const {repoSubmitStatus} = this.props
		let repoSubmitStatusLabel = ''
		let repoSubmitStatusDescription = ''

		if(repoSubmitStatus === 'submitted'){
			repoSubmitStatusLabel = 'Submitted'
			repoSubmitStatusDescription = 'Thank you for submitting your pack for early release. We’ll be in touch via email if any changes are required.'
		}else if(repoSubmitStatus === 'in-review'){
			repoSubmitStatusLabel = 'In Review'
			repoSubmitStatusDescription = "We’ll be working with you to  get your pack approved for early release. We’ll email you with more information."
		}else if(repoSubmitStatus === 'approved'){
			repoSubmitStatusLabel = 'Approved'
			repoSubmitStatusDescription = ''
		}
		return (
			<div className={'repoDefaultPreviewPanel-earlyReleaseApprovalStatus ' + (repoSubmitStatus ? `repoDefaultPreviewPanel-earlyReleaseApprovalStatus--${repoSubmitStatus}`:'')}>
				<div className='repoDefaultPreviewPanel-earlyReleaseApprovalStatus-title'>
					Early Release Status
				</div>
				
				<div className='repoDefaultPreviewPanel-earlyReleaseApprovalStatus-statusLabel'>
					{/*
					{repoSubmitStatus === 'submitted' &&
						<div className='repoDefaultPreviewPanel-earlyReleaseApprovalStatus-statusLabel-iconContainer'>
							<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">       
								<path d="M31.1187944,3.19130573 C30.5205792,3.40172442 0.997619388,13.8061462 0.41505524,14.0113479 C-0.0788200384,14.1852477 -0.188376878,14.611302 0.397665265,14.8443277 C1.09500325,15.1243063 6.99889962,17.4893428 6.99889962,17.4893428 L6.99889962,17.4893428 L10.9116439,19.0561795 C10.9116439,19.0561795 29.7588983,5.21723777 30.012792,5.02942604 C30.2701636,4.84161432 30.5657932,5.1946308 30.3797204,5.39635451 C30.1936477,5.59981721 16.6920714,20.2004399 16.6920714,20.2004399 L16.6920714,20.2039179 L15.9060446,21.0786336 L16.9477041,21.6385908 L16.9477041,21.6385908 C16.9477041,21.6385908 25.0340423,25.9930404 25.6113894,26.3025819 C26.1174377,26.5738656 26.7730397,26.3495349 26.9191155,25.7217568 C27.0912762,24.9809439 31.8665633,4.39990896 31.9726421,3.94081363 C32.1100229,3.3443375 31.7152705,2.98088704 31.1187944,3.19130573 Z M10.8768639,28.4554608 C10.8768639,28.8832542 11.1185846,29.003245 11.4524721,28.7006594 C11.8889605,28.302429 16.4086149,24.247087 16.4086149,24.247087 L10.8768639,21.3881751 L10.8768639,28.4554608 Z"></path>    
							</svg>
						</div>
					}		
					*/}			
					<div className='repoDefaultPreviewPanel-earlyReleaseApprovalStatus-statusLabel-label'>
						{repoSubmitStatusLabel}
					</div>
				</div>
				<div className='repoDefaultPreviewPanel-earlyReleaseApprovalStatus-statusDescription'>
					{repoSubmitStatusDescription}
				</div>
			</div>
		)
	}
}

export default RepoDefaultPreviewPanelSubmitStatus
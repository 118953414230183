//		Same Year Tuesday 24 September 12:05PM 
//		Tuesday 24 September 12:05PM 2017

import moment from 'moment'


export function formatDateLong(timestamp) {

	const nowDate =moment()
	const year = moment(nowDate).startOf('isoYear')
	let formatedTimestamp

	if (moment(timestamp).isSame(year, 'y')) {
		formatedTimestamp = moment(timestamp).format('dddd DD MMMM h:mm A')
	}else{
		formatedTimestamp = moment(timestamp).format('dddd DD MMMM YYYY h:mm A')
	}

	return formatedTimestamp

}
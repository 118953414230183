import {DOMSerializer} from "prosemirror-model"
import editorSchema from '../schema/editorSchema'

const EMPTY_BODY_TEXT='Click here to edit question'
const EMPTY_BODY_HTML= '<p>Click here to edit question</p>' 
const EMPTY_BODY_SOURCE=[
    {
      "type": "paragraph",
      "content": [
        {
          "type": "text",
          "text": "Click here to edit question"
        }
      ]
    } 
  ]
 
const UNTOUCHED_CHOICE_BODY='\u2063'
const FALLBACK_IMAGE='resources/fallback_image'
const dprValue = 2.0 // can't seem to get "auto" to work atm
const FALLBACK_DELIVERY_URL=`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload/c_limit,dpr_${dprValue},h_900,w_900/f_auto,q_auto/${FALLBACK_IMAGE}`
const FALLBACK_GIF='resources/fallback_gif'
const FALLBACK_STILL_URL=`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload/pg_1/${FALLBACK_GIF}`
const FALLBACK_WEBP_URL=`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload/${FALLBACK_GIF}.webp`
const FALLBACK_GIF_URL=`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload/${FALLBACK_GIF}.gif`
      
function convertNodeToHTML(node){
	let fragment = DOMSerializer.fromSchema(editorSchema).serializeFragment(node)
	let tmp = document.createElement("div")
	tmp.appendChild(fragment)
	let html=tmp.innerHTML
	return html
}

function videoNodeToMediaObject(node){
	let media={
		type:'video',
		addedAt:node.attrs.addedAt,
		youtubeId:node.attrs.youtubeId,
		title:node.attrs.title,
		channelTitle:node.attrs.channelTitle,
		thumbnail:node.attrs.thumbnail,
		fullDuration:node.attrs.fullDuration,
		isClipped:node.attrs.isClipped,
		start:node.attrs.start,
		end:node.attrs.end
	}
	return media
}

function imageNodeToMediaObject(node){
	let media={
		type:'image',
		addedAt:node.attrs.addedAt,
		fileId:node.attrs.fileId || FALLBACK_IMAGE,
		title:node.attrs.title,
		attribution:node.attrs.attribution,
		deliveryUrl:node.attrs.deliveryUrl || FALLBACK_DELIVERY_URL,
		originalDimensions:node.attrs.originalDimensions,
		deliveryDimensions:node.attrs.deliveryDimensions,
		rotate:node.attrs.rotate,
		markup:node.attrs.markup,
		colorAdjust:node.attrs.colorAdjust,
		imageFill:node.attrs.imageFill,
		crop:node.attrs.crop
	}
	if(!node.attrs.fileId && node.attrs.placeholderDimensions){
		media.originalDimensions=node.attrs.placeholderDimensions
		media.deliveryDimensions=node.attrs.placeholderDimensions
	}
	return media
}

function gifNodeToMediaObject(node){
	let media={
		type:'animatedGif',
		addedAt:node.attrs.addedAt,
		dimensions:node.attrs.dimensions,
		fileId:node.attrs.fileId || FALLBACK_GIF,
		title:node.attrs.title,
		attribution:node.attrs.attribution,
		host:node.attrs.host,
		stillUrl:node.attrs.stillUrl || FALLBACK_STILL_URL,
		giphySlug:node.attrs.giphySlug,
		giphyUser:node.attrs.giphyUser,
		imageFill:node.attrs.imageFill,
		webpUrl:node.attrs.webpUrl || FALLBACK_WEBP_URL,
		gifUrl:node.attrs.gifUrl || FALLBACK_GIF_URL
	}
	if(!node.attrs.fileId && node.attrs.placeholderDimensions){
		media.dimensions=node.attrs.placeholderDimensions
	}
	return media
}

function soundNodeToMediaObject(node){
	let media={
		type:'sound',
		fileId:node.attrs.fileId,
		deliveryUrl:node.attrs.deliveryUrl,
		title:node.attrs.title,
		fullDuration:node.attrs.fullDuration,
		isClipped:node.attrs.isClipped,
		start:node.attrs.start,
		end:node.attrs.end,
		source:node.attrs.source,
		attribution:node.attrs.attribution,
		addedAt:node.attrs.addedAt
	}
	return media
}

function parseNodeToPlainText(bodyNode){
	let bodyText=''
	bodyNode.forEach((node,offset,index)=>{
		if(node.type.name !=='math'){
			if(node.type.name ==='friendlyFraction'){             
				node.forEach((fractionNode,offset,index)=>{
					if(fractionNode.type.name !=='friendlyFractionDenominator'){
						bodyText+=fractionNode.textContent                   
					}else{//fraction --> "nominator/denominator"
						bodyText+='/'
						bodyText+=fractionNode.textContent
					}
				})
			}else{
				if(node.marks && node.marks.length>0){
					const subscriptType= editorSchema.marks.subscript
					const superscriptType= editorSchema.marks.superscript
					if(subscriptType.isInSet(node.marks)){
						bodyText+='_'
					}else if(superscriptType.isInSet(node.marks)){
						bodyText+='^'
					}
				}
			bodyText+=node.textContent
		}
	}else{ //is math node
		bodyText+=`$$${node.attrs.latex}$$`
	}
	})
	return bodyText
}

export function convertPMNodeToQuestionObject(node,isIndependentQuestion){
	let question={
		media:null,
		body:'',
		bodySource:'',
		bodyHtml:'',
		choices:[],
		measurements:{}
	}

	let questionBody=''
	let questionHtml=''
	let questionBodyContent=''
	let mediaObject=null

	question.layout=node.attrs.template
	if(!isIndependentQuestion){
		question.questionId=node.attrs.questionid
	}
	question.measurements.bodyFS=node.attrs.bodyFontSize
	question.measurements.choiceFS=node.attrs.choiceFontSize
	if(node.attrs.template==='bodyCenterMediaCenter' ){
		question.measurements.questionMediaDynamicHeight=node.attrs.questionMediaDynamicHeight 
	}
  
	node.forEach((questionNode,offset,index)=>{
		if(questionNode.type.name ==='questionBody'){
			questionNode.forEach((questionBodyParagraphNode,offset,index)=>{
				if(index!==0){
					questionBody+=' '
				}
				questionBody+=parseNodeToPlainText(questionBodyParagraphNode)	
			})
			questionHtml=convertNodeToHTML(questionNode) 
			questionBodyContent=questionNode.content
		}              
		else if(questionNode.type.name ==='choiceGroup'){
			questionNode.forEach((choiceNode,offset,index)=>{
			let choiceHtml
			let choiceBody=''
			let choiceContent
			let choice={
				body:'',
				bodyHtml:'',
				bodySource:[],
				correct:choiceNode.attrs.correct,
				media:null           
			}
			let mediaObject=null
			
			choiceNode.forEach((choiceChildNode,offset,index)=>{     
				if(choiceChildNode.type.name ==='choiceBody'){
					choiceHtml=convertNodeToHTML(choiceChildNode)
					choiceContent=choiceChildNode.content
					choiceBody=parseNodeToPlainText(choiceChildNode)              
				}
				else if(choiceChildNode.type.name ==='choiceVideo'){
					mediaObject=videoNodeToMediaObject(choiceChildNode)
				}
				else if(choiceChildNode.type.name ==='choiceImage'){
					mediaObject=imageNodeToMediaObject(choiceChildNode)
				}
				else if(choiceChildNode.type.name ==='choiceSound'){
					mediaObject=soundNodeToMediaObject(choiceChildNode)
				}
				else if(choiceChildNode.type.name ==='choiceAnimatedGIF'){
					mediaObject=gifNodeToMediaObject(choiceChildNode)
				}
		})
         
		choice.body=choiceBody
		choice.bodyHtml=choiceHtml
		choice.media=mediaObject
		//only save media or body depending on template 
		if(question.layout==='bodyLeftMediaRightChoicesMedia' ||question.layout==='bodyCenterChoicesMedia' ){
			choice.body=''
			choice.bodyHtml=''
			choice.bodySource=[]
		}else{
			choice.media=null
			if(choice.body){
				choice.bodySource=choiceContent
			}else if(choiceNode.attrs.isTouched===false){
				choice.body=UNTOUCHED_CHOICE_BODY
			}
		}
		question.choices.push(choice)
		})
	}
		else if(questionNode.type.name ==='questionImage'){
			mediaObject=imageNodeToMediaObject(questionNode)
		}
		else if(questionNode.type.name ==='questionVideo'){
			mediaObject=videoNodeToMediaObject(questionNode) 
		}
		else if(questionNode.type.name ==='questionSound'){
			mediaObject=soundNodeToMediaObject(questionNode)  
		}
		else if(questionNode.type.name ==='questionAnimatedGIF'){
			mediaObject=gifNodeToMediaObject(questionNode)  
		}
  })

	question.body=questionBody
	question.bodyHtml=questionHtml
	question.bodySource=questionBodyContent
	question.media=mediaObject
  
  const bodyWithoutWhitespace=questionBody.replace(/\s/g, '') //remove whitespace to prevent questionBody that is only whitespace    
	if(bodyWithoutWhitespace ==='' && !mediaObject){//handle empty questionbody
		question.body=EMPTY_BODY_TEXT
		question.bodyHtml=EMPTY_BODY_HTML
		question.bodySource=EMPTY_BODY_SOURCE
	}
  return question
}
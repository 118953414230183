import React from 'react'
import {getQuestionBodyLabel} from '../../../../utils/getQuestionBodyLabel'
import findIndex from 'lodash/findIndex'

class SectionHomeLive extends React.Component{

	
	render(){

		const {livePoll,control} = this.props
		let isSet	
		let title=''
		let currentQuestionIndex
		let numOfQuestions
				
		if(livePoll && livePoll.snapshot){
			if(livePoll.setObject){
				isSet=true
				title = livePoll.snapshot.name	
				if(control){
					currentQuestionIndex=findIndex(livePoll.snapshot.questions,question => {
						return question.questionId === control.snapshot.questionId
					})
					numOfQuestions=livePoll.snapshot.questions.length
				}
			}
			else{
				isSet=false
				title=getQuestionBodyLabel(livePoll.snapshot)
			}

			return(
				<div className='sectionHome-live'>									
					
					<div className='sectionHome-live-label'>
						Live
					</div>
				
					<div className='sectionHome-live-title'>
						{title}
					</div>
										
					<div className='sectionHome-live-subtitle'>
						Playing Now 
					</div>					

					{isSet && livePoll.snapshot &&
					<div className='sectionHome-live-counter'>
						<span className='sectionHome-live-counter-count'>{currentQuestionIndex +1}</span>
						<span className='sectionHome-live-counter-slash'> / </span>
						<span className='sectionHome-live-counter-total'>{numOfQuestions}</span>						 
					</div>
					}
				
					{isSet  && livePoll.snapshot &&					
						<div className='sectionHome-live-progressBar'>
							{livePoll.snapshot.questions.map((question,index) => {												
								return(
									<div
										key={index} 
										className={'sectionHome-live-progressBar-item ' + 
										(index === currentQuestionIndex ? 'sectionHome-live-progressBar-item--active' : '') +
										(index < currentQuestionIndex ? 'sectionHome-live-progressBar-item--before' : '' ) + 
										(index > currentQuestionIndex ? 'sectionHome-live-progressBar-item--after' : '' )} />
								)
							})}
							
						</div>
					}

					<div className='sectionHome-live-divider' />
				</div>
			)
		}else return null	
	}}


export default SectionHomeLive

import React from 'react'
import slideLayoutDefinitions from '../../../../staticData/slideLayoutDefinitions.js'

class StaticSlideSoundZoomablePlayer extends React.Component{  
	render(){ 
		const {slideTemplate,UIInverseScale} = this.props
		let mediaWidth
		let widePlayer = false
		let soundContainerHeight = slideLayoutDefinitions.bodySoundPlayerHeight
		if((slideTemplate === 'bodyLeftMediaRight') || (slideTemplate === 'bodyRightMediaLeft') || (slideTemplate === 'bodyLeftMediaRightChoicesMedia')){
			mediaWidth = 580 // should come from template definitions or somewhere      
		}
		if(slideTemplate === 'bodyCenterMediaCenter'){
			mediaWidth = 1160 // should come from template definitions or somewhere      
			widePlayer = true
		}    
		const soundPlayerWidth = mediaWidth
		const scaledSoundPlayeContainerHeight = soundContainerHeight / UIInverseScale
		const scaledSoundPlayerWidth = soundPlayerWidth / UIInverseScale
		return (
			<React.Fragment>
				<div  id="soundPlayerZoomable" style={{transform: `scale(${UIInverseScale})`, width: `${scaledSoundPlayerWidth}px`, height: scaledSoundPlayeContainerHeight ? `${scaledSoundPlayeContainerHeight}px` : null}} className={'slide-media--sound slide-media--sound--zoomablePlayer ' + (widePlayer ? ' slide-media--sound--widePlayer' : '')}>            
				</div>
			</React.Fragment>
		)
	}
}
export default StaticSlideSoundZoomablePlayer


import React from 'react'
import { Redirect } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

const RedirectInvalidUrl = ({
	path,
	isAuthenticated,
	willAuthenticate,
	location,
}: Props) =>

{	
	Sentry.withScope(scope => {
		scope.setExtra('path', location.pathname)
		Sentry.captureMessage('Redirect invalid url')
	})

	if (isAuthenticated){
		return( 
			<Redirect to={{ pathname: '/library' }} />
		)
	} 
	else if(!isAuthenticated && !willAuthenticate){
		return( 
			<Redirect to={{ pathname: '/login' }} />
		)
	}
	else return null
}


export default RedirectInvalidUrl
import React from 'react'
import { withRouter} from 'react-router-dom'
import subjects from '../../staticData/subjects.js'
import filter from 'lodash/filter'
import algoliasearch from 'algoliasearch/lite' 
import DiscoverELAKeyTextsPage from './DiscoverELAKeyTextsPage'
import DiscoverBiologyPage from './DiscoverBiologyPage'
import DiscoverEarlyMathPage from './DiscoverEarlyMathPage'

//DEV
const algoliaFiltersDev={
	earlyMath:'language:en AND _tags:repo_qualified AND grade:K OR grade:1st OR grade:2nd OR grade:3rd OR grade:4th OR grade:5th AND subject:5db0c7ecae38d80013090b83',
	biology:'language:en AND _tags:repo_qualified AND grade:9th OR grade:10th OR grade:11th OR grade:12th AND subject:5db0c80eae38d80013090b84',
}


//PROD
const algoliaFiltersProd={
	earlyMath:'language:en AND _tags:repo_qualified AND grade:K OR grade:1st OR grade:2nd OR grade:3rd OR grade:4th OR grade:5th AND subject:5dbcc502f2cbe10011b2b999',
	biology:'language:en AND _tags:repo_qualified AND grade:9th OR grade:10th OR grade:11th OR grade:12th AND subject:5dbcc52520bed70016832c2e',
}

class DiscoverPage extends React.Component{

	constructor(props){
		super(props)
		this.filterRepos=this.filterRepos.bind(this)
		this.getLatestRepos=this.getLatestRepos.bind(this)
		let latestRepos=[]
		if(props.discoverLatestRepos && props.discoverLatestRepos[props.subject]){
			latestRepos=props.discoverLatestRepos[props.subject]
		}
		this.state = { 
			latestRepos:latestRepos,
			popularRepos:[]	,
			highlyRatedRepos:[]
		}
	}


	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Discover Subject Page',{
				type:this.props.subject
			})
		}
		if(this.props.searchKey){
			this.getLatestRepos(this.props.searchKey)
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if(!this.props.searchKey && props.searchKey) {
			this.getLatestRepos(props.searchKey)
		}
	}

	getLatestRepos(searchKey){
		const {latestRepos}=this.state
		if(!latestRepos || latestRepos.length===0){
			const searchClient = algoliasearch(
			process.env.REACT_APP_PLICKERS_ALGOLIA_APP_ID,
			searchKey
		)
		const index = searchClient.initIndex('repos_latest');
		const env= process.env.REACT_APP_ENV
		index.search('',{
			hitsPerPage:12, // number of latest packs
			filters:env==='development'?algoliaFiltersDev[this.props.subject]:algoliaFiltersProd[this.props.subject]

		}).then(({ hits }) => {
			this.setState({latestRepos:hits})
			const subject=this.props.subject
			const repos=hits
			this.props.onFetchLatestRepos(subject,repos)	
			repos.forEach((repo)=>{
				this.props.loadRepoContent(repo.id)
			})
		})
		}

		const searchClient = algoliasearch(
			process.env.REACT_APP_PLICKERS_ALGOLIA_APP_ID,
			searchKey
		)
		const indexPopular=searchClient.initIndex('repos_response_count')
		const env= process.env.REACT_APP_ENV
		indexPopular.search('',{
			hitsPerPage:12, // number of latest packs
			filters:env==='development'?algoliaFiltersDev[this.props.subject]:algoliaFiltersProd[this.props.subject]
			}).then(({ hits }) => {
				this.setState({popularRepos:hits})			 
			})
			const indexRated=searchClient.initIndex('repos_rating')
			let filters=env==='development'?algoliaFiltersDev[this.props.subject]:algoliaFiltersProd[this.props.subject]
			filters+=' AND reviewCount>2'
			indexRated.search('',{
				hitsPerPage:12, // number of latest packs
				filters:filters

			}).then(({ hits }) => {
				this.setState({highlyRatedRepos:hits})
		})
	}

	filterRepos(repos){
		const unfilteredRepos=repos
		let filteredRepos=unfilteredRepos
		const selectedSubject=this.props.selectedSubSubject
		if(repos && selectedSubject){
			const subjectId=subjects[selectedSubject].subjectId
			filteredRepos= filter(filteredRepos, function(repo) { 
				if(repo.subject.id===subjectId){
					return repo
				}
			})
		}		
		return filteredRepos
	}

	render(){
		const {repos,subject}=this.props
		const filteredRepos=this.filterRepos(repos)
		if(subject==='biology'){
			return(
				<DiscoverBiologyPage 
					fetchAPublishedRepoByShortId={this.props.fetchAPublishedRepoByShortId}
					showNotification={this.props.showNotification}
					repos={filteredRepos} 
					history={this.props.history}
					latestRepos={this.state.latestRepos}
					popularRepos={this.state.popularRepos}
					highlyRatedRepos={this.state.highlyRatedRepos}
					loadRepoContent={this.props.loadRepoContent}
				/>	
			)
		}else if(subject==='keyTexts'){
			return(
				<DiscoverELAKeyTextsPage 
					fetchAPublishedRepoByShortId={this.props.fetchAPublishedRepoByShortId}
					showNotification={this.props.showNotification}
					repos={filteredRepos}
					history={this.props.history}
				/>
			)
		}else if(subject==='earlyMath'){
			return(
				<DiscoverEarlyMathPage 
					fetchAPublishedRepoByShortId={this.props.fetchAPublishedRepoByShortId}
					showNotification={this.props.showNotification}
					repos={filteredRepos}
					history={this.props.history}
					latestRepos={this.state.latestRepos}
					popularRepos={this.state.popularRepos}
					highlyRatedRepos={this.state.highlyRatedRepos}
					loadRepoContent={this.props.loadRepoContent}
				/>
			)
		}else return(
			<div className="page page--wideLayout discover">
				<div className="page-leftPanel" />
				<div className='page-centerPanel'>					
					<div className='discover-repoListContainer'>
						<div className='discover-repoList-header'>
						</div>
					<div className='discover-repoList-list'>
					</div>
				</div> 					
			</div>
				<div className="page-rightPanel" />
			</div>
		)
	}
}

export default withRouter(DiscoverPage)
export default function (state=null, action) {

	switch (action.type) {
		
	case 'FETCH_SUBMISSIONS_SUCCESS':
		return action.response

	case 'CREATE_SUBMISSION_SUCCESS':
		const submission=action.response
		return {...state,submission}

	case 'LOGOUT':
		return null
		
	default:
		return state
	
	}

}



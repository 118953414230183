import React from 'react'
import EditSectionForm from '../forms/EditSectionForm'
import Button from '../uiKit/Button'
import TopPageHeader from '../pageHeaders/TopPageHeader'
import PageIcon from '../misc/PageIcons'
import Icon from '../misc/Icons'

class SectionSetup extends React.Component{


	constructor(){
		super()
		this.handleUpdateSection=this.handleUpdateSection.bind(this)
		this.state={
			buttonDisabled:false,
		}
	}

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Class',{subpage:'Settings',sectionId:this.props.currentSection.id,title:this.props.currentSection.name})
		}
	}

	handleUpdateSection(data){
		if(data.name){
			this.setState({buttonDisabled:true})
			const nowDate = new Date()
			const requestData={
				...this.props.currentSection,
				...data, 
				'clientModified':nowDate,
				}
				this.props.updateSection(requestData,this.props.currentSection.id).then(() => {
					this.setState({buttonDisabled:false})
				})
		}
	}


	render(){
		return[
			<div className='page-centerPanel sectionSettings' key='centerPanel'>

				<TopPageHeader
					location='sectionSettings'
					height={193}
					leftBadge={<Icon name='chevron-left' />}
					leftBadgeClickFunction={this.props.navigateToSectionHome}						
					header={this.props.currentSection.name}
					subHeader='Settings'
					subHeaderBadge={<PageIcon name='settingsLarge' />}
					sectionHeaderSectionColor={this.props.currentSection.color}						
				/>

				
				<Button name='Archive' label='Archive Class' onClickFunction={this.props.archiveSection} />
				
				<EditSectionForm onSubmit={this.handleUpdateSection} section={this.props.section} buttonDisabled={this.state.buttonDisabled}/>
				

			</div>,
			<div className='page-rightPanel' key='sidePanel'/>
		]
	}
}




export default SectionSetup



import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import { findDataOwnerByUniqueName } from '../actions/dataOwner'
import { addDataOwner } from '../actions/user'
import { showNotification } from '../actions/notifications'

class AddDataOwnerContainer extends Component {
  constructor(props) {
    super(props)
    this.fetchAndAddDataOwner = this.fetchAndAddDataOwner.bind(this)
    const { dataOwnerUniqueName } = props.match.params
    this.fetchAndAddDataOwner(dataOwnerUniqueName)
  }

  async fetchAndAddDataOwner(uniqueName) {
    const {
      findDataOwnerByUniqueName,
      addDataOwner,
      showNotification,
      history,
    } = this.props
    let message = ''
    try {
      const dataOwner = await findDataOwnerByUniqueName(uniqueName)
      if (!dataOwner) {
        message = 'Organization not found. Please make sure you have the correct url.'
        throw new Error()
      }
      await addDataOwner(dataOwner.id)
      showNotification(
        'added',
        dataOwner.name,
      )
    } catch (e) {
      showNotification(
        message || 'Something went wrong. Please try again, if the problem persists contact support.',
        '',
        'delete',
      )
    }
    history.push('/account')
  }

  render() {
    const docTitle = 'Plickers - org connect'
    return (
      <DocumentTitle title={docTitle}>
        <div className='addDataOwnerPage'>
          <span className='addDataOwnerPage-loading'>
            Loading organization...
          </span>
        </div>
      </DocumentTitle>
    )
  }
}

export default withRouter(connect(
  () => {},
  { findDataOwnerByUniqueName, addDataOwner, showNotification },
)(AddDataOwnerContainer))

import {createNewQuestion} from '../../actions/questions'
import {createNewSet} from '../../actions/sets'

export function makeACopyQuestion(currentQuestion) {
	const nowDate = new Date()
	let copyOfQuestion={...currentQuestion}
	copyOfQuestion.clientModified=nowDate
	copyOfQuestion.userCreated=nowDate
	return createNewQuestion(copyOfQuestion)
}



export function makeACopySet(currentSet) {
	const nowDate = new Date()
	let copyOfSet=currentSet
	const newName=`Copy of ${currentSet.name}`
	copyOfSet.name=newName
	copyOfSet.clientModified=nowDate
	copyOfSet.userCreated=nowDate
	return createNewSet(copyOfSet)
}




import React from 'react'
import { ContextMenu, MenuItem} from 'react-contextmenu'
 
class SectionHomeUpNextContextMenu extends React.Component{
	render(){
		const {handleUpNextContextMenuClick}=this.props
		return(
			<ContextMenu id='UP_NEXT' className='contextMenu' onShow={this.props.onShowUpNextContextMenu} onHide={this.props.onHideUpNextContextMenu}>  			
				<MenuItem  onClick={handleUpNextContextMenuClick} data={{action: 'Play'}}>			    
					Play Now	    
				</MenuItem>
				<MenuItem onClick={handleUpNextContextMenuClick} data={{action: 'Open'}}>			    
					{this.props.isViewOnlyRepo ? 'Edit' : 'View'}
				</MenuItem>
				<MenuItem divider/>
				<MenuItem attributes={{className:'is--warning'}}  onClick={handleUpNextContextMenuClick} data={{action: 'RemoveFromQueue'}}>			    
					Remove From Queue	    
				</MenuItem>
			</ContextMenu>
		)
	}
}


export default SectionHomeUpNextContextMenu



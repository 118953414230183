import React from 'react'


class DropdownSubMenu extends React.Component{


	render(){	
		const {children, label} = this.props

		return(
			<div className="dropdownSelect--container">
				{label &&
				<div className="dropdownSelect-header">
					{label}
				</div>
				}
				{children}					
			</div>
		)
	}
				
}

DropdownSubMenu.defaultProps = {
	position: 'right',
}

export default DropdownSubMenu

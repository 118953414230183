import {Plugin} from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

//style math nodes to look like selected text if node is contained in doc selection
export function mathSelectionPlugin() {
	return new Plugin({
		props: {
			decorations: ({ doc, plugins, selection }) => {
				let {empty,$from, $to} = selection
				let decorations=[]
				if(!empty){
					doc.descendants((node, pos) => {
						if (node.type.name==='math') {
							if((pos>$from.pos || pos===$from.pos) && (pos+node.nodeSize<$to.pos || pos+node.nodeSize===$to.pos )){
								const decoration = Decoration.node(pos, pos + node.nodeSize, {
									class: 'ProseMirror-selectednode'
								})
								decorations.push(decoration)
							}
						}
					})
				}
			return DecorationSet.create(doc, decorations)
		}
	}
	})
}

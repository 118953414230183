import React from 'react'
import Icon from '../misc/Icons'

class LiveViewAutoplayFailedAlert extends React.Component{	

	constructor(props){
		super()
		this.dismissAlert=this.dismissAlert.bind(this)
		this.clickLink=this.clickLink.bind(this)
	}

	dismissAlert(){
		this.props.hideAutoplayFailedAlert()
		if(window.analytics){
			window.analytics.track('AutoPlay alert dismiss without click link')
		}
	}

	clickLink(){
		this.props.showAutoplaySetupModal()
		if(window.analytics){
			window.analytics.track('AutoPlay alert click link')
		}
	}

	render(){			
		return(
			<React.Fragment>
				<div className='liveView-autoPlayFailedAlert-top'>
					
					<div className='liveView-autoPlayFailedAlert-top-hand'>
						<img alt='' src='https://res.cloudinary.com/plickers-static/image/upload/v1632150760/richtextmarketing/wavehand.png' />
					</div>
					
					<button className='liveView-autoPlayFailedAlert-top-dismiss' onClick={this.dismissAlert}>
						<div className='liveView-autoPlayFailedAlert-top-dismiss-inner'>
							<Icon name='close' />
						</div>
					</button>
				</div>
				<div className='liveView-autoPlayFailedAlert-h1'>
					Your browser prevented the mobile app from playing media.
				</div>		
				{/*}		        
				<div className='liveView-autoPlayFailedAlert-h2'>					
				</div>
				*/}
				<button className='liveView-autoPlayFailedAlert-openModal' onClick={this.clickLink}>
					Enable Remote Control
				</button>
												
			</React.Fragment>
				
		)
	}				
}

export default LiveViewAutoplayFailedAlert
//See comments in calculateStudentTotals.js

export function calculateGuestTotals(polls,section,excludedPolls) {
	let allGuestsResponseCount=0
	let allGuestsCorrectCount=0
	let guestsWithTotals={}
	
	polls.map((poll)=>{ //map over all polls
		if(poll.setObject){ //is a setPoll 
			// if(!excludedPolls[section.id].setPolls[poll.id]){
			const setPollPolls=poll.polls	
			Object.keys(setPollPolls).map((key)=>{ //map over each setPollPoll
				const setPollPoll=setPollPolls[key]
				const isExcluded=excludedPolls[section.id].setPollPolls[`${poll.id}-${setPollPoll.id}`]
				// if(!excludedPolls[section.id].setPollPolls[`${poll.id}-${setPollPoll.id}`]){ 
				if(setPollPoll.responsesByGuest){
					Object.keys(setPollPoll.responsesByGuest).map((key) => { //map over guest responses
						if(!isExcluded){
							const guestResponse=setPollPoll.responsesByGuest[key]
							if(guestResponse){
								if(guestsWithTotals[key]){
									if(!guestResponse.isSurvey && guestResponse.isValid ){
										guestsWithTotals[key].totalResponses +=1
										allGuestsResponseCount+=1
											
										if(guestResponse.isCorrect){
											guestsWithTotals[key].totalCorrect +=1
											allGuestsCorrectCount +=1
										}
									}
								}
								else{
									if(!guestResponse.isSurvey && guestResponse.isValid ){
										guestsWithTotals[key]={
											totalResponses:1,
											totalCorrect:0
										}
										allGuestsResponseCount+=1
										if(guestResponse.isCorrect){
											guestsWithTotals[key].totalCorrect +=1
											allGuestsCorrectCount +=1
										}
									}
									else{
										guestsWithTotals[key]={
											totalResponses:0,
											totalCorrect:0
										}
									}
								}
							}	
						}
						else { //if is an excluded poll make sure guest still appears in guests with totals
							if(!guestsWithTotals[key]){
								guestsWithTotals[key]={
									totalResponses:0,
									totalCorrect:0
								}
							}
						}
						return null
					})
				}//responses by guest
				return null })//map of poll polls 
		}else{ //is a questionPoll
			const isExcluded=excludedPolls[section.id].questionPolls[poll.id]
			Object.keys(poll.responsesByGuest).map((key) => { //map over guest responses
				if(!isExcluded){
					const guestResponse=poll.responsesByGuest[key]
					if(guestResponse && guestResponse.isValid){
						
						if(!guestResponse.isSurvey){
							if(guestsWithTotals[key]){
							
							guestsWithTotals[key].totalResponses +=1
							allGuestsResponseCount+=1

							if(guestResponse.isCorrect){
								guestsWithTotals[key].totalCorrect +=1
								allGuestsCorrectCount +=1
							}
						}else{
							guestsWithTotals[key]={
								totalResponses:1,
								totalCorrect:0
							}
							allGuestsResponseCount+=1
							if(guestResponse.isCorrect){
								guestsWithTotals[key].totalCorrect +=1
								allGuestsCorrectCount +=1
							}
						}
					}else{ //isSurvey
						if(!guestsWithTotals[key]){
							guestsWithTotals[key]={
								totalResponses:0,
								totalCorrect:0
							}
						}
					}
				}
			}else{//if is excluded
					if(!guestsWithTotals[key]){
						guestsWithTotals[key]={
							totalResponses:0,
							totalCorrect:0
						}
					}
				}
				return null
			})
		}
		return null //mapping over all polls
	})

	guestsWithTotals.total={
		allGuestsResponseCount:allGuestsResponseCount,
		allGuestsCorrectCount:allGuestsCorrectCount
	}
	return guestsWithTotals
}

import api from '../api'

//for dev
// export function adminFetchRepos() {
//   	return (dispatch) => api.limitedBatchedFetch('/admin/repos?',50,50)
// 	    .then((response) => {
// 		      dispatch({ type: 'FETCH_ADMIN_REPOS_SUCCESS',response})
// 	    })
// 	     .catch(err => {	     	
// 	     })
// }

// to load all public repos (for explorer)
export function adminFetchRepos() {
	return (dispatch) => api.batchedFetch('/admin/repos?language=en',100)
		.then((response) => {
			dispatch({ type: 'FETCH_ADMIN_REPOS_SUCCESS',response})
		})
		.catch(err => {})
}

//Old creator fund stuff
export function adminFetchAwards() {
	return (dispatch) => api.batchedFetch('/admin/awards?populateRepo=true',100)
		.then((response) => {
			dispatch({ type: 'FETCH_ADMIN_AWARDS_SUCCESS',response})
		})
		.catch(err => {})
}

export function adminActivateAward(awardId) {
	return (dispatch) => api.put(`/admin/awards/${awardId}/activate`)
		.then((response) => {
			dispatch({ type: 'UPDATE_AWARD_SUCCESS',response})
		})
		.catch(err => {})
}

function adminFetchAwardsForUser(userId) {
	return (dispatch) => api.batchedFetch(`/admin/awards?populateRepo=true&&user=${userId}`,100)
		.then((userAwards) => {
			userAwards.forEach((response)=>{
				dispatch({ type: 'UPDATE_AWARD_SUCCESS',response})
			})
		})
		.catch(err => {})
}

export function adminPayActivatedAwards(userId) {
	return (dispatch) => api.put(`/admin/users/${userId}/pay-activated-awards`)
		.then((response) => {
			dispatch(adminFetchAwardsForUser(userId))
		})
		.catch(err => {})
}

export function adminCreateAward(data) {
	return (dispatch) => api.post(`/admin/awards/`,data)
		.then((response) => {})
		.catch(err => {})
}

export function adminUpdateRepoMetadata(repoId,metadata) {
	return (dispatch) => api.put(`/admin/repos/${repoId}/explorerMetadata`,metadata)
		.then((response) => {
			dispatch({ type: 'UPDATE_REPO_METADATA_SUCCESS',repoId,response})
		})
		.catch(err => {})
}

export function adminFetchCreators() {
	return (dispatch) => api.batchedFetch('/admin/users?creators=true',100)
		.then((response) => {
			dispatch({ type: 'FETCH_ADMIN_CREATORS_SUCCESS',response})
		})
		.catch(err => {})
}

export function adminAddCreatorFebPayoutAmount(userId,februaryPayoutAmount) {
	return (dispatch) => api.put(`/admin/users/${userId}/explorerMetadata`,{februaryPayoutAmount:februaryPayoutAmount})
		.then((response) => {
			dispatch({ type: 'ADMIN_UPDATE_CREATOR_METADATA_SUCCESS', response,userId })
			return response
		})
		.catch((error) => {
			return error
		})
}

export function adminUpdateCreatorMetadata(userId,metadata) {
	return (dispatch) => api.put(`/admin/users/${userId}/explorerMetadata`,metadata)
		.then((response) => { 
			dispatch({ type: 'ADMIN_UPDATE_CREATOR_METADATA_SUCCESS', response,userId })
			return response
		})
		.catch((error) => {
			return error
		})
}

export function adminFetchSubmissions() {
	return (dispatch) => api.batchedFetch('/admin/submissions?populateRepo=true',100)
		.then((response) => {
			dispatch({ type: 'FETCH_ADMIN_SUBMISSIONS_SUCCESS',response})
		})
		.catch(err => {})
}

export function adminUpdateSubmission(submissionId,newStatus) {
	return (dispatch) => api.put(`/admin/submissions/${submissionId}`,{status:newStatus})
		.then((response) => {
			dispatch({ type: 'ADMIN_UPDATE_SUBMISSION_SUCCESS', response })
		})
		.catch((error) => {
			return error
		})
}
 

export function adminAssignAndMoveToReview(submissionId,assignee) {
	return (dispatch) => api.put(`/admin/submissions/${submissionId}`,{assignee:assignee,status:'in-review'})
		.then((response) => {
			dispatch({ type: 'ADMIN_UPDATE_SUBMISSION_SUCCESS', response })
		})
		.catch((error) => {
			return error
		})
}

export function adminLogEmailEvent(submissionId) {
	return (dispatch) => api.post(`/admin/submissions/${submissionId}/events`,{event:'email-sent'})
		.then((response) => {
			dispatch({ type: 'ADMIN_UPDATE_SUBMISSION_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}
import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_ADMIN_REPOS_SUCCESS':	
		return action.response

	case 'UPDATE_REPO_METADATA_SUCCESS':	
		const updatedRepoId = action.repoId
		var indexOfRepoToUpdate = findIndex(state,(repo => {
			return repo.id === updatedRepoId
		}))
		if(indexOfRepoToUpdate !== -1 ){
			let repo=state[indexOfRepoToUpdate]
			repo.explorerMetadata=action.response

			return [
					...state.slice(0,indexOfRepoToUpdate),
					repo,
					...state.slice(indexOfRepoToUpdate + 1)
				]	
		}
		else return state
	

	case 'LOGOUT':
		return null
	
	default:
		return state
	}
}

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_REVIEWS_SUCCESS':
		return action.response


	case 'CREATED_REVIEW_SUCCESS':
		return [...state,action.response]
	
	case 'LOGOUT':
		return []
	
	default:
		return state
	}
}

import React from 'react'
import { NavLink } from 'react-router-dom'
import Icon from './../misc/Icons'
import { DropTarget } from 'react-dnd'
import ItemTypes from '../DnDKit/ItemTypes'
import RepoTarget from '../DnDKit/RepoTarget'
import {getLinkForRepo} from '../../utils/getLinkForRepo'

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop()
	}
}


class LibraryRepoGridItem extends React.Component{

	render(){
		const {repo,connectDropTarget,isOver,canDrop} = this.props
		const pending = !repo.published && !repo.joined
		const isPublicConsumer = repo.role==='consumer'
		const isPublicAuthor = repo.role==='author'
		const link=getLinkForRepo(repo)


		return(
			<NavLink className={'library-repoGrid-repo ' + (isOver ? ' library-repoGrid-repo--isDnDOver ' : '') + (canDrop ? ' library-repoGrid-repo--canDrop ' : ' library-repoGrid-repo--cannotDrop ')} to={link} key={repo.id}>
				{connectDropTarget(
					<div className='library-repoGrid-repo--contents'>
						<div className={'library-repoGrid-repo-label ' + (pending ? 'library-repoGrid-repo-label--pending' : '')}>
							{repo.name}						
						</div>
						{repo.shared && repo.joined && 
							<div className='library-repoGrid-repo-icon library-repoGrid-repo-icon--shared'>						
								<Icon name='shared' />																
							</div>
						}

						{isPublicConsumer &&									
							<div className='library-repoGrid-repo-icon library-repoGrid-repo-icon--publicConsumer'>						
								<Icon name='globe-shared' />																
							</div>
						}

						{isPublicAuthor &&									
							<div className='library-repoGrid-repo-icon library-repoGrid-repo-icon--publicAuthor'>						
								<Icon name='globe-shared-author' />																
							</div>
						}

						{pending && 
							<div className='library-repoGrid-repo-icon library-repoGrid-repo-icon--pending'>						
								<div className='library-repoGrid-repo-icon--pending-circle' />
							</div>
						}


						<div className='library-repoGrid-repo-dropIcon'>
							<Icon name='open-left-arrow' />						
						</div>
					</div>
				)}


				<div className='library-repoGrid-repo-chevronContainer'>
					<Icon name='chevron-right'/>
				</div>	
								
			</NavLink>
		)
	}
}

export default DropTarget([ItemTypes.ITEMCELL,ItemTypes.FOLDERCELL], RepoTarget, collect)(LibraryRepoGridItem)

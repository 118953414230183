import React from 'react'

export default function BingAttribution() {
  return (
    <div className='bingAttributionContainer'>
      <div>
        powered by
      </div>
      <picture className='bingAttributionContainer-bingLogo'>
        <img
          className='bingAttributionLogo'
          src='https://assets.plickers.com/react-assets/images/bing_logo.png'
          alt=''
        />
      </picture>
    </div>
  )
}

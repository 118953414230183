import React from 'react'
import {updateQuestionTemplate} from '../../../../prosemirror/utils/editorActions/updateQuestionTemplate'
//import EditorIcon from '../../../misc/EditorIcons';

class AddMediaChoiceButton extends React.Component{  
	
	constructor() {
		super()
		this.onClick=this.onClick.bind(this)
	}
 
	onClick(e){
		const {template}=this.props
		let newTemplate='bodyCenterChoicesMedia'
	  if(template==='bodyLeftMediaRight' ||
	  	template==='bodyRightMediaLeft' ||
	  	template==='bodyCenterMediaCenter'){
	  	newTemplate='bodyLeftMediaRightChoicesMedia'
	  }
		updateQuestionTemplate(newTemplate)
	}


	render(){
		const {template}=this.props
		let shortLabel=false
		if(template!=='bodyLeft'){
			shortLabel=true
		}

		// const shortLabel = false
		// short label if anything other than left no media

	  return (
	  	<div className={'slide-choice-addChoiceMediaBtn ' + (shortLabel ? 'slide-choice-addChoiceMediaBtn--shortLabel' : 'slide-choice-addChoiceMediaBtn--defaultLabel')} onClick={this.onClick} >
	  		{shortLabel ? 'Media' : 'Add Media Choices'}
	  	</div>
	  )
	}
}

export default AddMediaChoiceButton


function getInitialState(){
	const backToSchoolModalDismissed = JSON.parse(localStorage.getItem('backToSchoolModalDismissed'))
	const backToSchoolModalPermenantlyDismissed = JSON.parse(localStorage.getItem('backToSchoolModalPermenantlyDismissed'))

	let dismissed=false
	let permenantlyDismissed=false
	if(backToSchoolModalDismissed && backToSchoolModalDismissed===true){
		dismissed=true
	}
	if(backToSchoolModalPermenantlyDismissed && backToSchoolModalPermenantlyDismissed===true){
		permenantlyDismissed=true
	}

	return {
		dismissed:dismissed,
		openedFromContextBadge:false,
		permenantlyDismissed:permenantlyDismissed
	}



}



export default function (state = getInitialState(), action) {
	switch (action.type) {


	case 'DISMISS_BACK_TO_SCHOOL_MODAL':
		localStorage.setItem('backToSchoolModalDismissed',true)
		return {...state,
			dismissed:true,
		}


	case 'PERMENANTLY_DISMISS_BACK_TO_SCHOOL_MODAL':
		localStorage.setItem('backToSchoolModalPermenantlyDismissed',true)
		let newState=Object.assign({},state)
		newState.permenantlyDismissed=true
		return newState


	case 'SHOW_BACK_TO_SCHOOL_MODAL':
		localStorage.setItem('backToSchoolModalDismissed',false)
		let openedFromContextBadge=false
		if(action.openedFromContextBadge){
			openedFromContextBadge=true
		}
		return {
			dismissed:false,
			permenantlyDismissed:false,
			openedFromContextBadge:openedFromContextBadge
		}



	// case 'LOGOUT':
	// 	localStorage.setItem('backToSchoolModalDismissed',false)
	// 	return {
	// 		dismissed:false,
	// 		permenantlyDismissed:false,
	// 		openedFromContextBadge:false
	// 	}
	
	default:
		return state
	}
}


import React from 'react'
import Icon from '../../misc/Icons'

class SyncLiveViewToolbarViewToggle extends React.Component{

	constructor(){
		super()
		this.toggleClassSafeMode=this.toggleClassSafeMode.bind(this)
		this.state={    	   	
			recentlyToggled:false
		}
	}


	toggleClassSafeMode(){
		this.props.toggleClassSafeMode()
		this.setState({recentlyToggled:true})
	
	}



	render(){		
			
			const { classSafeMode} = this.props
			const {recentlyToggled}=this.state

			return(				
				<button onMouseLeave={()=>{this.setState({recentlyToggled:false})}} onClick={this.toggleClassSafeMode} className='syncLiveView-toolbar-viewToggle'>

					{classSafeMode && !recentlyToggled &&
						<React.Fragment>
							<div className='syncLiveView-toolbar-viewToggle-iconContainer syncLiveView-toolbar-viewToggle-iconContainer--active'>
								<Icon name='syncScreenshare' />
							</div>							
						</React.Fragment>
					}

	
					{classSafeMode && recentlyToggled &&
						<React.Fragment>
							<div className='syncLiveView-toolbar-viewToggle-iconContainer syncLiveView-toolbar-viewToggle-iconContainer--active'>
								<Icon name='syncScreenshare' />
							</div>
						</React.Fragment>
					}


					{!classSafeMode && !recentlyToggled &&
						<React.Fragment>
							<div className='syncLiveView-toolbar-viewToggle-iconContainer syncLiveView-toolbar-viewToggle-iconContainer--inactive'>
								<Icon name='syncScreenshare' />
							</div>
						</React.Fragment>
					}

					{!classSafeMode && recentlyToggled &&
						<React.Fragment>
							<div className='syncLiveView-toolbar-viewToggle-iconContainer syncLiveView-toolbar-viewToggle-iconContainer--inactive'>
								<Icon name='syncScreenshare' />
							</div>
						</React.Fragment>
					}

					{!recentlyToggled &&
						<div className='syncLiveView-toolbar-viewToggle-keyboardTooltip'>
							Screenshare Mode (Space Bar)
						</div>					
					}
							
				</button>
				
			)

	}
}

export default SyncLiveViewToolbarViewToggle 
import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm} from 'redux-form'
import FormFieldText from '../../../components/uiKit/FormFieldText'
import Button from '../../../components/uiKit/Button'
  

function isBlank(str) {
    return (!str || /^\s*$/.test(str))
}

///////// Validations//////////////

const requiredFirstName = value => isBlank(value) ? 'Please enter your first name':undefined
const requiredLastName = value => isBlank(value) ? 'Please enter your last name':undefined 

////////////////////////////////////

class EditUserNameForm extends React.Component{

	render(){
		return(			
				<form onSubmit={this.props.handleSubmit}>				    				    			          
					<Field 
        		name='title'
        		type='text'
        		label='Title'
		        component={FormFieldText}
		        placeholder=''
		        autoFocus={true}
		        error

				  />

					<Field 
        		name='firstName'
        		type='text'
        		label='First Name'
		        component={FormFieldText}
		        placeholder=''
		        validate={requiredFirstName}
		       			        
				  />

				  <Field 
        		name='lastName'
        		type='text'
        		label='Last Name'
		        component={FormFieldText}
		        placeholder=''
		        validate={requiredLastName}	
		        		        			        
				  />
				 
		 			<div className='accountForm-actionRow'>						
	    			<Button 
	    				size='xLarge'
	    				submitLoading={this.props.submitLoading}
	    				label='Update Name'
	    				color={this.props.dirty && this.props.valid ? 'blue' : 'gray'}
	    				canSubmit={this.props.dirty && this.props.valid && !this.props.submitLoading ? true : false}
	    				type='submit' 
	    			/>	        			
	    		</div>
	    		
	    	</form>     		 
		)
	}
}



const mapStateToProps = (state,ownProps) => {
	return {
	    initialValues: {
	      firstName:ownProps.firstName,
	      lastName:ownProps.lastName,
	      title:ownProps.title
	    }
	  }
}



EditUserNameForm = reduxForm({
	form: 'editUserNameForm',
	enableReinitialize: true
})(EditUserNameForm)

export default connect(mapStateToProps)(EditUserNameForm)




import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import {updateMeta} from '../actions/meta'
import RepoReleaseModal from '../components/repoReleaseModal/RepoReleaseModal'

//Modal shown when we released repos
//still show this modal to old users who have not used since before repo release because they need to accept changes to privacy policy 
 
class RepoReleaseModalContainer extends Component {

	constructor(){
		super()				
		this.acceptPrivacyPolicy=this.acceptPrivacyPolicy.bind(this)	
		this.state = {
			buttonDisabled: false,	
		}
	} 
	acceptPrivacyPolicy(){ 
		if(window.analytics){
			window.analytics.track('Accept Privacy Policy')
		}
		this.setState({buttonDisabled:true})
		this.props.updateMeta({sharingOptedIn:true})
	}

	render() {
		const {meta}=this.props
		if(meta && !meta.sharingOptedIn && !(meta.isbeModalSeen!==true && meta.isbeMatchedAt && !meta.isbeOptedInAt)){
			return <RepoReleaseModal 
				buttonDisabled={this.state.buttonDisabled} 
				acceptPrivacyPolicy={this.acceptPrivacyPolicy}
				service={this.props.service}/>
		}else return null
	}
}

export default withRouter(connect(
	(state) => ({
		meta:state.meta,
		service:state.service
	}),
	{ updateMeta}
)(RepoReleaseModalContainer))	

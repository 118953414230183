import React, { Component } from 'react'

class RepoReleaseModalVisualPageHeader extends Component {

	render() {


		return ( 
			<div className='repoReleaseModal-visual-pageHeader' >
				<div className='repoReleaseModal-visual-pageHeader-imageContainer'>
					<img alt='' src={`https://s3.amazonaws.com/assets.plickers.com/react-assets/images/packrelease/PackHeaderImage${this.props.imageURL}.png`} />
				</div>
				<div className='repoReleaseModal-visual-pageHeader-right'>
					<div className='repoReleaseModal-visual-pageHeader-right-title'>
						{this.props.title}
					</div>

					<div className='repoReleaseModal-visual-pageHeader-right-desc'>
						{this.props.description}
					</div>					

				</div>

			</div>	
		)
	}
}


export default RepoReleaseModalVisualPageHeader




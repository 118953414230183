import React from 'react'

class ReportPackModalHeader extends React.Component{
	render(){	
		const {section,individualReportPackStudent,dateRangeLabel,studentCount}=this.props
		let header1=''
		let header2=''
		if( !individualReportPackStudent){
			header1='Print Student Reports'
			header2=`${section.name} (${studentCount} students)`
		}else if( individualReportPackStudent){
			header1='Print Student Report'
			header2=`${individualReportPackStudent.firstName} ${individualReportPackStudent.lastName}`
		}
		return(			
			<div className='featureModal-header'> 
				<div className='featureModal-header-h1'> 				
					{header1}
				</div>
				<div className='featureModal-header-h2 notranslate'> 				
					{header2}
				</div>
				<div className='featureModal-header-h3'> 				
					{dateRangeLabel}
				</div>				
			</div>
		)
	}
}
export default ReportPackModalHeader



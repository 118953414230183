import React from 'react'
import Button from '../uiKit/Button'

class DiscoverSubjectPageFooter extends React.Component{


	constructor(){
		super() 	
		this.submitTopicRequest=this.submitTopicRequest.bind(this)
		this.onKeyDown=this.onKeyDown.bind(this)
		this.onKeyDownSubject=this.onKeyDownSubject.bind(this)
		this.submitSubjectRequest=this.submitSubjectRequest.bind(this)
		this.submitGeneralFeedback=this.submitGeneralFeedback.bind(this)
		this.onKeyDownGeneralFeedback=this.onKeyDownGeneralFeedback.bind(this)
 		this.state=({ 	
 			feedbackText:'',
 			subjectRequestText:'',
 			generalFeedbackText:'',
 			submitLoading:false	,
 			submitLoadingFeedback:false	
 		})
 	}


 	onKeyDown(e){
 		if(e.keyCode===13){
 			if(this.state.feedbackText){
 				this.submitTopicRequest()
 		}}
 	}

 	onKeyDownSubject(e){
 		if(e.keyCode===13){
 			if(this.state.subjectRequestText){
 				this.submitSubjectRequest()
 			}
 		}	
 	}

 	onKeyDownGeneralFeedback(e){
 		if(e.keyCode===13){
 			if(this.state.generalFeedbackText){
 				this.submitGeneralFeedback()
 			}
 		}	
 	}


 	submitGeneralFeedback(){
		const {generalFeedbackText}=this.state
		this.setState({submitLoadingFeedback:true})
		if(window.analytics){
					window.analytics.track('Discover Feedback', {
					  	feedback:generalFeedbackText
					})
				}
		
		setTimeout(function() {
     		this.setState({submitLoadingFeedback:false,generalFeedbackText:''})	
     		this.props.showNotification('Thank you for taking the time to send your comments.','Feedback sent!','default')	
    	}.bind(this), 500)	

	}

	submitTopicRequest(){
		const {feedbackText}=this.state
		this.setState({submitLoading:true})
		if(window.analytics){
					window.analytics.track('Discover Topic Request', {
					  	discoverMarket:this.props.discoverMarket,
					  	topic:feedbackText
					})
				}
		
		setTimeout(function() {
     		this.setState({submitLoading:false,feedbackText:''})	
     		this.props.showNotification('We’ll be in touch if we have more information.','Request submitted!','default')	
    	}.bind(this), 500)	

	}


	submitSubjectRequest(){
		const {subjectRequestText}=this.state
		this.setState({submitLoading:true})
		if(window.analytics){
					window.analytics.track('Discover Subject Request', {
					  	subject:subjectRequestText
					})
				}
		
		setTimeout(function() {
     		this.setState({submitLoading:false,subjectRequestText:''})	
     		this.props.showNotification('We’ll be in touch if we have more information.','Request submitted!','default')	
    	}.bind(this), 500)	

	}

	render(){

		const {discoverMarket} = this.props
		const {feedbackText,subjectRequestText,generalFeedbackText}=this.state
		
		let requestTopicHeaderLabel
		let requestTopicDescriptionLabel
		let requestButtonLabel
		let requestInputPlaceholder
		let requestionTopicInputDescriptionLabel

		if(discoverMarket === 'earlyMath' || 'highSchoolBiology'){
			requestTopicHeaderLabel = 'Looking for a topic?'
			requestTopicDescriptionLabel = "Help us prioritize by requesting topics you'll be teaching this school year."
			requestButtonLabel = 'Request Topic'
			requestionTopicInputDescriptionLabel = 'We may contact you in the future if your requested topic becomes available, or for further information.'
		}

		if(discoverMarket === 'ELAKeyTexts'){
			requestTopicHeaderLabel = 'Looking for a text?'
			requestTopicDescriptionLabel = "Help us prioritize by requesting texts, novels, and plays you'll be teaching this school year."
			requestButtonLabel = 'Request Text'
			requestionTopicInputDescriptionLabel = 'We may contact you in the future if your requested text becomes available, or for further information.'
		}

		if(discoverMarket === 'earlyMath'){
			requestInputPlaceholder = 'Interpreting Data'
		}
		if(discoverMarket === 'highSchoolBiology'){
			requestInputPlaceholder = 'Group Behavior'
		}
		if(discoverMarket === 'ELAKeyTexts'){
			requestInputPlaceholder = 'Of Mice and Men'
		}

		return(		
			<div className='discover-market-footer'>
				{!this.props.hideBorder &&
					<div className='discover-market-footer-divider'/>
				}

				{this.props.showRequestTopic &&
					<div className='discover-market-footer-block discover-market-footer-block--requestTopic'>
						<div className='discover-market-footer-block-header'>
							{requestTopicHeaderLabel}
						</div>
						<div className='discover-market-footer-block-description'>
							{requestTopicDescriptionLabel}
						</div>

						<div className='discover-market-footer-block--requestTopic-inputContainer'>
							<input 
								className='discover-market-footer-block--requestTopic-input'
								placeholder={`e.g. ${requestInputPlaceholder}`}
								value={feedbackText}
								onChange={(e)=>{this.setState({feedbackText:e.target.value})}}
								onKeyDown={this.onKeyDown}
							/>
							<Button 
								color={feedbackText.length > 0 ? 'blue':'gray'}
								label={requestButtonLabel}
								onClickFunction={this.submitTopicRequest}
								submitLoading={this.state.submitLoading}
							/>
						</div>
						<div className='discover-market-footer-block--requestTopic-inputDescription'>
							{requestionTopicInputDescriptionLabel}											
						</div>

					</div>
				}

				{this.props.showRequestSubject &&
					<div className='discover-market-footer-block discover-market-footer-block--requestSubject'>
						<div className='discover-market-footer-block-header'>
							Looking for your subject?
						</div>
						<div className='discover-market-footer-block-description'>
							If we haven’t included your subject in the list above, help us prioritize by adding yours below.
						</div>

						<div className='discover-market-footer-block--requestSubject-inputContainer'>
							<input 
								className='discover-market-footer-block--requestSubject-input'
								placeholder='Your Subject'
								value={subjectRequestText}
								onChange={(e)=>{this.setState({subjectRequestText:e.target.value})}}
								onKeyDown={this.onKeyDownSubject}
							/>
							<Button 
								color={subjectRequestText.length > 0 ? 'blue':'gray'}
								label='Add Subject'
								onClickFunction={this.submitSubjectRequest}
								submitLoading={this.state.submitLoading}
							/>
						</div>
						<div className='discover-market-footer-block--requestTopic-inputDescription'>
							We may contact you in the future about this subject for further information!							
						</div>

					</div>
				}

				{this.props.showGeneralFeedback &&
					<div className='discover-market-footer-block discover-market-footer-block--generalFeedback'>
						<div className='discover-market-footer-block-header'>
							General Feedback
						</div>
						<div className='discover-market-footer-block-description'>						
							Help us improve Discover by leaving your feedback. 
						</div>
						<div className='discover-market-footer-block--generalFeedback-textAreaContainer'>
							<textarea 
								className='discover-market-footer-block--generalFeedback-textArea'
								placeholder='Any comments you have on how we can improve Discover'
								value={generalFeedbackText}
								onChange={(e)=>{this.setState({generalFeedbackText:e.target.value})}}
								onKeyDown={this.onKeyDownGeneralFeedback}
							/>
						</div>
						<Button 
								color={generalFeedbackText.length>0 ? 'blue':'gray'}
								label='Send Feedback'						
								onClickFunction={this.submitGeneralFeedback}
								submitLoading={this.state.submitLoadingFeedback}
						/>
						<div className='discover-market-footer-block--generalFeedback-textAreaDescription'>
							We might get in touch in the future about your feedback!
						</div>
												
					</div>
				}


				<div className='discover-market-footer-block discover-market-footer-block--contributeContent'>
					<div className='discover-market-footer-block-header'>
						Contribute your content
					</div>
					<div className='discover-market-footer-block-description'>
						<div className='discover-market-footer-block-description-p'>
							Publish your best packs and help teachers and students around the world.
						</div>
						<div className='discover-market-footer-block-description-p'>
							Top creators can earn cash awards from the Plickers Creator Fund.
						</div>
						<div className='discover-market-footer-block-description-p'>
							To get started, click <emph>Publish Pack</emph> on the right side of your pack page.							
						</div>
						<div className='discover-market-footer-block-description-p'>							
							<a target='_blank' rel='noopener noreferrer' href='https://help.plickers.com/hc/en-us/articles/360044728754-Plickers-Creator-Program'>
								Learn more about publishing your content
							</a>
						</div>

					</div>								
				</div>

				{this.props.showFAQ &&
					<div className='discover-market-footer-block'>
						<div className='discover-market-footer-block-header discover-market-footer-block--faq'>
							FAQ
						</div>
						<div className='discover-market-footer-block-FAQ-item'>
							<div className='discover-market-footer-block-FAQ-item-title'>
								Can I edit the sets in Discover?
							</div>
							<div className='discover-market-footer-block-FAQ-item-description'>
								Yes! When you open a set in Discover, you’ll see an option in the top-left of the Set Editor to <emph>Make a Copy and Edit</emph>. Edits you make will only affect your copy of the set.
							</div>
						</div>
						<div className='discover-market-footer-block-FAQ-item'>
							<div className='discover-market-footer-block-FAQ-item-title'>
								Can I “save” packs to my Library?
							</div>
							<div className='discover-market-footer-block-FAQ-item-description'>								
								Yes! Inside of every Discover pack you’ll see the option to <emph>Follow Pack</emph>. When you “follow” a pack, it is saved to your account so you can access it easily in the future, including from the mobile app.
							</div>
						</div>					
						<div className='discover-market-footer-block-FAQ-item'>
							<div className='discover-market-footer-block-FAQ-item-title'>
								Can I search Discover?
							</div>
							<div className='discover-market-footer-block-FAQ-item-description'>
								When you “follow” packs, they will be saved to your account and included in your search results when you use the search bar at the top. We will be adding improved search later this school year.
							</div>
						</div>																	
					</div>
				}


			</div>
		)
	}
}


export default DiscoverSubjectPageFooter


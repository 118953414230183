//today
//yesterday
// X days ago
// Last Month
// Two Months ago
// Dunno...
	
import moment from 'moment'

export function formatExplorerPublishedDate(timestamp) {
	const nowDate =moment()
	const today = moment(nowDate).startOf('day')
	const yest = moment().startOf('day').subtract(1,'day')
	const thisMonth = moment(nowDate).startOf('month')
	const lastMonth = moment().startOf('month').subtract(1,'month')
	let formatedTimestamp
	if (moment(timestamp).isSame(today, 'd')) {
		const seconds = Math.floor((nowDate - moment(timestamp)) / 1000)
		const minutes = Math.floor(seconds / 60)
		if (minutes < 60) { //is in the last hour
			if (minutes < 1) { //is in the last min
				formatedTimestamp = 'Just Now'
			} else {
				formatedTimestamp = `${minutes} min ago`
			}
		} else {
			formatedTimestamp = `Today ${moment(timestamp).format('h:mm A')}`
		}
	}else if (moment(timestamp).isSame(yest, 'd')) {
		formatedTimestamp = `Yesterday`
	}
	else if (moment(timestamp).isSame(thisMonth, 'month')) {
			const seconds = Math.floor((nowDate - moment(timestamp)) / 1000)
			const minutes = Math.floor(seconds / 60)
			const hours=Math.floor(minutes / 60)
			const days=Math.floor(hours / 60)
			formatedTimestamp = `${days} days ago`
	}
	else if(moment(timestamp).isSame(lastMonth, 'month')){
		formatedTimestamp = `Last month`
	}
	else {
		const seconds = Math.floor((nowDate - moment(timestamp)) / 1000)
		const minutes = Math.floor(seconds / 60)
		const hours=Math.floor(minutes / 60)
		const days=Math.floor(hours / 24)
		const months=Math.floor(days/30)
		formatedTimestamp = `${months} months ago`
	}

	return formatedTimestamp

}
import React from 'react'
import EditorEditImageModal from '../editorUI/modals/imageEditing/components/EditorEditImageModal'
import ReactDOM from 'react-dom'
import {stopAllMediaPlayback} from '../../../prosemirror/utils/editorActions/stopAllMediaPlayback'
import store from '../../../store'

const modalId='editor-editImageModalContainer'

function closeEditImageModal(){
	ReactDOM.render(null, document.getElementById(modalId))    
}

export function showEditImageModal(fileId,imageNode,nodePos) {
	stopAllMediaPlayback()
	store.dispatch({ type: 'HIDE_NOTIFICATION'})
		ReactDOM.render(
			<EditorEditImageModal 
				fileId={fileId}
				imageNode={imageNode}
				nodePos={nodePos}
				closeEditImageModal={()=>{closeEditImageModal()}}
		/>, document.getElementById(modalId))
}
import React from 'react'
import QuestionCountBadge from '../uiKit/QuestionCountBadge'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'
import Icon from '../misc/Icons'

class DuplicateAndEditLocationSelection extends React.Component{
 
	render(){		
		const {item,submitLoading,selectedRepoId,librarySelected,repos}=this.props

		let itemName
		let numOfQuestions
		let isSet
		if(item){
			if(item.ancestors){
				itemName = item.name
			}

			else if(item.name){
				itemName = item.name
				numOfQuestions=item.questions.length
				isSet = true
			}

			else {
				itemName = getQuestionBodyLabel(item)
			}  
		}


		return(		
			<div className={'selectionModal selectionModal--repo' + (isSet ? ' selectionModal--set' : '') + (submitLoading ? ' selectionModal--submitLoading' : '' )}>
				<div className='selectionModal-header'>        				                               
					<div className='selectionModal-header-title'>
						 Make a Copy and Edit
					</div>
					{item &&
						<React.Fragment>
							<div className='selectionModal-header-itemTitle'>
						 	{itemName}
							</div>
							{isSet &&
							<QuestionCountBadge color='blueLight' questionCount={numOfQuestions} />
							}    
						</React.Fragment>        
					}
				</div>

				<div className='selectionModal-divider' />
				<div className='selectionModal-selectionList'>
					<div className='selectionModal-selectionList-helpLabel'>
						Select where to save the copy
					</div>
					
					<div className={'selectionModal-selectionList-item selectionModal-selectionList-item--library '  + (librarySelected ? 'selectionModal-selectionList-item--library--submitLoading ' : '')} onClick={() =>{this.props.selectLocation(null,true)}}>
						Your Library
						<div className='selectionModal-selectionList-item--library-arrow'>
							<Icon name='open-left-arrow' />
						</div>

						<div className='selectionModal-selectionList-item--library--submitLoading-loadingSpinnerContainer'>
							<div className='selectionModal-selectionList-item--library--submitLoading-loadingSpinner' />
						</div>
					</div>         



							{repos && repos.map((repo,index) => {
								const submitLoadingRow = repo.id===selectedRepoId
								return(
									<div className={'selectionModal-selectionList-item selectionModal-selectionList-item--repo ' + (submitLoadingRow ? 'selectionModal-selectionList-item--repo--submitLoading ' : '')} key={index} onClick={() =>{this.props.selectLocation(repo.id,false)}}>
										{repo.name}
										<div className='selectionModal-selectionList-item--repo-arrow'>
											<Icon name='open-left-arrow' />
										</div>

										<div className='selectionModal-selectionList-item--repo--submitLoading-loadingSpinnerContainer'>
											<div className='selectionModal-selectionList-item--repo--submitLoading-loadingSpinner' />
										</div>
									</div>                                                 
								)
							})}



				</div>

			</div>    

		)
	}
}



export default DuplicateAndEditLocationSelection

function handleMouseDown(e){
	if (e.target === e.currentTarget){
		e.preventDefault()
		e.stopPropagation()
	} 
}

class QuestionNodeView {

	constructor(node, view) {
		this.node = node
		const {template,activeslide} = node.attrs
		this.dom = this.contentDOM = document.createElement("div")
		this.dom.classList.add("slide")
		this.dom.classList.add("notranslate")
		this.dom.classList.add("slide--editor")
		this.dom.classList.add(`slide--template--${template}`)

		if(!activeslide){
			this.dom.classList.add(`slide--editor--hidden`)
		}
		if(activeslide){
			this.dom.contentEditable=true
		}else{
			this.dom.contentEditable=false
		}
		this.dom.addEventListener('mousedown',handleMouseDown)
	}


 update(node) {
	if (node.type !== this.node.type) return false
	const {template,activeslide} = node.attrs
	this.dom.classList=''
	this.dom.classList.add("slide")
	this.dom.classList.add("notranslate")
	this.dom.classList.add("slide--editor")
	this.dom.classList.add(`slide--template--${template}`)

	if(!activeslide){
		this.dom.classList.add(`slide--editor--hidden`)
	}
	if(activeslide){
		this.dom.contentEditable=true
	}else{
		this.dom.contentEditable=false
	}
	return true
	}

	destroy() {
		this.dom.removeEventListener('mousedown',handleMouseDown)
	}

}

export default QuestionNodeView
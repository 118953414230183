import React from 'react'
import { withRouter } from 'react-router-dom'
import ExplorerTopBar from './ExplorerTopBar'
import ExplorerCrossFilterRepo from './ExplorerCrossFilterRepo'
import ExplorerCrossFilterDisplayControl from './ExplorerCrossFilterDisplayControl'
import ExplorerCrossFilterMarketPanel from './ExplorerCrossFilterMarketPanel'
import ExplorerCrossFilterSortPanelControl from './ExplorerCrossFilterSortPanelControl'

class ExplorerCrossFilterPage extends React.Component {
  render() {
    const { sortedBy, sortOrder, changeSortOrder } = this.props

    return (
      <div className="page explorer">
        <ExplorerTopBar />
        <div className="explorerCrossFilter">
          <div className="explorerCrossFilter--left">
            <ExplorerCrossFilterMarketPanel
              selectSubject={this.props.selectSubject}
              selectedSubjects={this.props.selectedSubjects}
              selectAllSubjects={this.props.selectAllSubjects}
              clearSelectedSubjects={this.props.clearSelectedSubjects}
              selectGrade={this.props.selectGrade}
              selectedGrades={this.props.selectedGrades}
              selectAllGrades={this.props.selectAllGrades}
              clearSelectedGrades={this.props.clearSelectedGrades}
              englishLanguageFilter={this.props.englishLanguageFilter}
              toggleEnglishLanguageFilter={this.props.toggleEnglishLanguageFilter}
              selectInternalRating={this.props.selectInternalRating}
              selectedInternalRatings={this.props.selectedInternalRatings}
              selectAllInternalRatings={this.props.selectAllInternalRatings}
              clearSelectedInternalRatings={this.props.clearSelectedInternalRatings}
              selectMonth={this.props.selectMonth}
              selectedMonths={this.props.selectedMonths}
            />
          </div>

          <div className="explorerCrossFilter--center">
            <div className="explorerCrossFilter-table">
              {this.props.loadingRepos && (
              <div className="explorerCrossFilter-table-header explorerCrossFilter-table-header--loading">
                Loading packs...
              </div>
              )}
              {!this.props.loadingRepos && (
              <div className="explorerCrossFilter-table-header">
                {this.props.repos.length}
                {' '}
                results
              </div>
              )}

              {this.props.repos.map((repo) => (
                <ExplorerCrossFilterRepo
                  month={this.props.month}
                  year={this.props.year}
                  updateRepoRating={this.props.updateRepoRating}
                  key={repo.id}
                  repoName={repo.name}
                  repo={repo}
                  showAdminEditRepoNotesModal={this.props.showAdminEditRepoNotesModal}
                />
              ))}
            </div>
          </div>
          <div className="explorerCrossFilter--right">

            <ExplorerCrossFilterDisplayControl
              name="sort"
              title="Sort"
            >
              <ExplorerCrossFilterSortPanelControl label="Newest first" onSelect={() => { changeSortOrder('publishedAt', 'descending') }} isActive={sortedBy === 'publishedAt' && sortOrder === 'descending'} />
              <ExplorerCrossFilterSortPanelControl label="Oldest first" onSelect={() => { changeSortOrder('publishedAt', 'ascending') }} isActive={sortedBy === 'publishedAt' && sortOrder === 'ascending'} />
              <ExplorerCrossFilterSortPanelControl label="Internal Rating 5 to 0" onSelect={() => { changeSortOrder('rating', 'descending') }} isActive={sortedBy === 'rating' && sortOrder === 'descending'} />
              <ExplorerCrossFilterSortPanelControl label="Internal Rating 0 to 5" onSelect={() => { changeSortOrder('rating', 'ascending') }} isActive={sortedBy === 'rating' && sortOrder === 'ascending'} />
              <ExplorerCrossFilterSortPanelControl label="External Rating 5 to 0" onSelect={() => { changeSortOrder('externalRating', 'descending') }} isActive={sortedBy === 'externalRating' && sortOrder === 'descending'} />
              <ExplorerCrossFilterSortPanelControl label="External Rating 0 to 5" onSelect={() => { changeSortOrder('externalRating', 'ascending') }} isActive={sortedBy === 'externalRating' && sortOrder === 'ascending'} />
              <ExplorerCrossFilterSortPanelControl label="Most this-month responses" onSelect={() => { changeSortOrder('monthResponses', 'descending') }} isActive={sortedBy === 'monthResponses' && sortOrder === 'descending'} />
              <ExplorerCrossFilterSortPanelControl label="Most total responses" onSelect={() => { changeSortOrder('totalResponses', 'descending') }} isActive={sortedBy === 'totalResponses' && sortOrder === 'descending'} />
              <ExplorerCrossFilterSortPanelControl label="A to Z" onSelect={() => { changeSortOrder('repoName', 'ascending') }} isActive={sortedBy === 'repoName' && sortOrder === 'ascending'} />
              <ExplorerCrossFilterSortPanelControl label="Z to A" onSelect={() => { changeSortOrder('repoName', 'descending') }} isActive={sortedBy === 'repoName' && sortOrder === 'descending'} />
              <ExplorerCrossFilterSortPanelControl label="Creator A to Z" onSelect={() => { changeSortOrder('author', 'ascending') }} isActive={sortedBy === 'author' && sortOrder === 'ascending'} />
              <ExplorerCrossFilterSortPanelControl label="Creator Z to A" onSelect={() => { changeSortOrder('author', 'descending') }} isActive={sortedBy === 'author' && sortOrder === 'descending'} />

            </ExplorerCrossFilterDisplayControl>
            <div className="explorerCrossFilter-packsToRateCounter">
              <b>{this.props.reposToBeRated.length}</b>
              {' '}
              EN Unrated Packs
              <br />
              <b>{this.props.reposRated5.length}</b>
              {' '}
              Rated 5
              {' '}
              <br />
              <b>{this.props.reposRated4.length}</b>
              {' '}
              Rated 4
              <br />
              <b>{this.props.reposRated3.length}</b>
              {' '}
              Rated 3
              <br />

            </div>

          </div>

        </div>

      </div>

    )
  }
}

export default withRouter(ExplorerCrossFilterPage)

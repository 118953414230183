function getAttrs(dom: HTMLElement) {
	const isAdvancedInput=dom.getAttribute('data-isadvancedinput')==="true"?true:false
  return {
    latex: dom.getAttribute('data-latex') || null,
    isAdvancedInput: isAdvancedInput
    // isAdvancedInput: dom.getAttribute('data-isAdvancedInput') || null,
  }
}

const MathNodeSpec={
	group: "inline", 
	inline: true, 
	draggable:false,
	attrs: {
		latex: {default: ''},
		isAdvancedInput:{default:false}//true for custom latex input, false for MathQuill input
	},
	parseDOM: [
		{tag: 'math[data-latex]', getAttrs},
	],
	toDOM(node) {
		return ['math', {'data-latex':node.attrs.latex}]
	}
}


export default MathNodeSpec


export default function (state=null, action) {

	switch (action.type) {

	case 'FETCH_QUESTIONS_SUCCESS':
	//TODO should we use response header date
	//what about batched requests....
		const fetchedDateTime = new Date()
		// const fetchedDateTimeIsoString = fetchedDateTime.toISOString()

		return fetchedDateTime.toISOString()


	case 'REFRESH_QUESTIONS_SUCCESS':
		const refreshedDateTime = new Date()
		// const refreshedDateTimeisoString = refreshedDateTime.toISOString()
		return refreshedDateTime.toISOString()	

	case 'LOGOUT':
		return null

	default:
		return state

	}
}
import React from 'react'
import { NavLink } from 'react-router-dom'
import { DropTarget } from 'react-dnd'
import ItemTypes from '../DnDKit/ItemTypes'
import SectionTarget from '../DnDKit/SectionTarget'
import Icon from '../misc/Icons'

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }
}

class SideNavSectionItem extends React.Component {
  render() {
    const {
      connectDropTarget, isOver, canDrop, section,
    } = this.props

    return (

      <NavLink
        to={`/classes/${section.id}`}
        className='sideNav-itemContainer'
      >
        {connectDropTarget(
          <div className={`sideNav-item is--class notranslate ${(isOver && canDrop) ? ' is--dndOver ' : ' isNot--dndOver '}${section.color ? `classColor--${section.color}` : 'classColor--none'}`}>
            <div className='sideNav-item-icon'>
              <div className='sideNav-item-icon-circle' />
              <Icon name='plus' />
            </div>
            <div className='sideNav-item-label'>
              {section.name}
            </div>
            {section.dataOwner && (
              <div className='sideNav-item-state'>
                <Icon name='organization' />
              </div>
            )}
          </div>,
        )}
      </NavLink>
    )
  }
}

export default DropTarget(ItemTypes.ITEMCELL, SectionTarget, collect)(SideNavSectionItem)

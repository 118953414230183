export function getSlideWidthFromQuestionNode() {
	let slideWidth
	if(window.view && window.view.state){
		var tr = window.view.state.tr
		window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(node.attrs.slideWidth || node.attrs.slideWidth===''){
				slideWidth=node.attrs.slideWidth
			}
		})
	}
	return slideWidth
}

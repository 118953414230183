import React from 'react'
import ReactDOM from 'react-dom';
import ChoiceAnimatedGIFReactComponent from './ChoiceAnimatedGIFReactComponent'
import {showInsertGIFModal} from '../../utils/showInsertGIFModal'


function handleMouseDown(e){
    e.stopPropagation()
  if(e.target === e.currentTarget){
    e.preventDefault() 
    e.stopPropagation()
  } 
}


class ChoiceAnimatedGIFNodeView {

  constructor(node, view, getPos) {
    this.getNode=this.getNode.bind(this)
    this.node = node
    this.getPos=getPos
    this.deleteGIF=this.deleteGIF.bind(this)
    this.showReplaceGIFModal=this.showReplaceGIFModal.bind(this)
    this.node = node
    this.getPos=getPos

    this.dom = document.createElement("div")
    this.dom.className="slide-choice-mediaContainer slide-choice-mediaContainer--gif"

   
    const {
      template,
      dimensions,
      stillUrl,
      webpUrl,
      gifUrl,
      giphyUser,
      placeholder,
      questionChoiceCount,
      host,
      fileId,
      slideWidth
    } = node.attrs
   
    this.testKey=0


    ReactDOM.render(
      <ChoiceAnimatedGIFReactComponent 
        stillUrl={stillUrl}
        webpUrl={webpUrl}
        gifUrl={gifUrl}
        template={template}
        dimensions={dimensions}
        deleteGIF={this.deleteGIF}
        placeholder={placeholder}
        host={host}
        giphyUser={giphyUser}
        showReplaceGIFModal={this.showReplaceGIFModal}
        fileId={fileId}
        questionChoiceCount={questionChoiceCount}
        slideWidth={slideWidth}

      />, this.dom)
    }



 update(node) {
  if (node.type !== this.node.type) return false
    const {
      template,
      dimensions,
      stillUrl,
      webpUrl,
      gifUrl,
      giphyUser,
      placeholder,
      questionChoiceCount,
      host,
      fileId,
      slideWidth
    } = node.attrs
  const oldTemplate=this.node.attrs.template
  const oldFileId=this.node.attrs.fileId
  const oldQuestionChoiceCount=this.node.attrs.questionChoiceCount
  const oldSlideWidth=this.node.attrs.slideWidth


      
   if(
    template !==oldTemplate || 
    fileId !==oldFileId || 
    questionChoiceCount !==oldQuestionChoiceCount ||
    slideWidth !== oldSlideWidth
    ){
    ReactDOM.render(
      <ChoiceAnimatedGIFReactComponent 
        stillUrl={stillUrl}
        webpUrl={webpUrl}
        gifUrl={gifUrl}
        template={template}
        dimensions={dimensions}
        deleteGIF={this.deleteGIF}
        placeholder={placeholder}
        host={host}
        giphyUser={giphyUser}
        showReplaceGIFModal={this.showReplaceGIFModal}
        fileId={fileId}
        questionChoiceCount={questionChoiceCount}
        slideWidth={slideWidth}

      />, this.dom)
    }
    this.node=node
    return true


    }
        
        




  getNode(){
    return this.node
  }

  showReplaceGIFModal(){
    const node=this.getNode()
    const nodePos=this.getPos(node)
    let insertPos={
      start:nodePos,
      end:nodePos+node.nodeSize
    }
    const isQuestion=false
    showInsertGIFModal(insertPos,isQuestion)
  }

  
  deleteGIF(){
    const node=this.getNode()
    const position=this.getPos()
    window.view.dispatch(window.view.state.tr.delete(position,position+node.nodeSize))
  }

   destroy() {
    this.dom.removeEventListener('mousedown',handleMouseDown)
    //if (this.innerView) this.close()
  }

  stopEvent(event) {
    //return this.innerView && this.innerView.dom.contains(event.target)
  }

}

export default ChoiceAnimatedGIFNodeView
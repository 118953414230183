import React from 'react'
import SetHeaderContent from './SetHeaderContent'


class SetHeader extends React.Component{

	render(){ 
		return(							
			<div className='printPackSetHeader'>
				<SetHeaderContent {...this.props} />
			</div>		
		)
	}
}
export default SetHeader
import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import {fetchArchivedFolders,deleteFolder,updateFolder } from '../actions/folders'
import {fetchArchivedQuestions,deleteQuestion,updateQuestionMeta} from '../actions/questions'
import {fetchArchivedSets,deleteSet,updateSetMeta} from '../actions/sets'
import {showNotification} from '../actions/notifications'
import {showConfirmModal,hideModal } from '../actions/modals'
import Trash from '../components/Trash'
import TrashPreviewPanel from '../components/previewPanel/PreviewPanel'
import Button from '../components/uiKit/Button'
import FilterBar from '../components/uiKit/FilterBar'
import {getQuestionBodyLabel} from '../utils/getQuestionBodyLabel'
import TopPageHeader from '../components/pageHeaders/TopPageHeader'
import StickyPageHeader from '../components/pageHeaders/StickyPageHeader'
import PageIcon from '../components/misc/PageIcons'
import Icon from '../components/misc/Icons'

//Library items trash (repos have their own trash)

class TrashContainer extends Component {

	constructor(props:any){
		super(props)
		this.handleDeleteQuestion=this.handleDeleteQuestion.bind(this)
		this.confirmDeleteQuestion=this.confirmDeleteQuestion.bind(this)
		this.confirmDeleteSet=this.confirmDeleteSet.bind(this)
		this.handleDeleteSet=this.handleDeleteSet.bind(this)
		this.handleDeleteFolder=this.handleDeleteFolder.bind(this)
		this.restoreItem=this.restoreItem.bind(this)
		this.restoreFolder=this.restoreFolder.bind(this)
		this.emptyTrash=this.emptyTrash.bind(this)
		this.confirmEmptyTrash=this.confirmEmptyTrash.bind(this)
		this.keyPressNavigation=this.keyPressNavigation.bind(this)
		this.confirmDeleteFolder=this.confirmDeleteFolder.bind(this)
		this.handleSelectRow=this.handleSelectRow.bind(this)
		this.handleFilterChange=this.handleFilterChange.bind(this)
		this.state={
			activePanelItem: {},
			filterTerm:''
		}
	}

	UNSAFE_componentWillMount() {
		this.props.fetchArchivedFolders()
		this.props.fetchArchivedQuestions()
		this.props.fetchArchivedSets()
	}

	handleFilterChange(e){
		this.setState({filterTerm:e.target.value})
	}

	handleDeleteQuestion(question){
		this.props.deleteQuestion(question.id).then(() => {
			this.props.hideModal()
			const notificationMessage=`${getQuestionBodyLabel(question)}`
			this.props.showNotification(notificationMessage,'Permanently Deleted','destroy')		
		})
	}

	confirmDeleteQuestion(question){
		this.props.showConfirmModal(question,this.handleDeleteQuestion,'delete')
	}

	handleDeleteSet(set){
		this.props.deleteSet(set.id).then(() => {
			this.props.hideModal()
			const notificationMessage=`${set.name}`
			this.props.showNotification(notificationMessage,'Permanently Deleted','destroy')
			this.setState({activePanelItem:{}})
		})
	}

	confirmDeleteSet(set){
		this.props.showConfirmModal(set,this.handleDeleteSet,'delete')
	}

	handleDeleteFolder(folder){
		this.props.deleteFolder(folder.id).then(() => {
			this.props.hideModal()
			const notificationMessage=`${folder.name}`
			this.props.showNotification(notificationMessage,'Permanently Deleted','destroy')
			this.setState({activePanelItem:{}})
		})
	}

	confirmDeleteFolder(folder){
		this.props.showConfirmModal(folder,this.handleDeleteFolder,'delete')
	}

	restoreItem(item) { //unarchive
		const nowDate = new Date()
		let notificationMessage
		const requestData = {
			...item,
			clientModified: nowDate,
			archived: false
		}
		if(item.questions){
			this.props.updateSetMeta(requestData, item.id).then(() => {
				notificationMessage=`${item.name} to Your Library`
				this.props.showNotification(notificationMessage,'Restored','create')
			})
		}else{
			this.props.updateQuestionMeta(requestData, item.id).then(() => {
				notificationMessage=`${item.body} to Your Library`
				this.props.showNotification(notificationMessage,'Restored','create')
			})
		}
		this.setState({activePanelItem:{}})
	}

	restoreFolder(folder) {
		const nowDate = new Date()
		let notificationMessage
		const requestData = {
			...folder,
			clientModified: nowDate,
			archived: false
		}
		
		this.props.updateFolder(requestData, folder.id).then(() => {
			notificationMessage=`${folder.name} to Your Library`
			this.props.showNotification(notificationMessage,'Restored','create')
		})
		this.setState({activePanelItem:{}})
	}
	
	confirmEmptyTrash(){
		this.props.showConfirmModal(null,this.emptyTrash,'emptyTrash')
	}

	emptyTrash() {
		const items=this.props.archivedItems
		const archivedFolders=this.props.archivedFolders
		archivedFolders.map((item, index) => {
			return this.props.deleteFolder(item.id).then((response) => {
			})
		}) 
		items.map((item, index) => {
			if(item.questions){
				return this.props.deleteSet(item.id).then((response) => {
				})
			}else{
				return this.props.deleteQuestion(item.id).then((response) => {})
			}
		})
		this.setState({activePanelItem:{}})
		this.props.hideModal()
	}
  
	handleSelectRow(selectedItem){
		this.setState({activePanelItem:selectedItem})
	}

	keyPressNavigation(e){
		const {activePanelItem}=this.state
		const {archivedItems}=this.props
		if(activePanelItem.id){
			if (e.keyCode === 40 || e.keyCode === 38 ){
				const activeIndex= findIndex(archivedItems, function (item) {
					return item.id === activePanelItem.id
				})
				e.preventDefault()// prevent scrolling container
				let newSelectedIndex
				//check if keypress was an up or down press
				if (e.keyCode === 40) { 
					newSelectedIndex=Math.min(activeIndex+1,archivedItems.length-1)				
				}
				else if (e.keyCode === 38) { 
			    newSelectedIndex=Math.max(activeIndex-1,0)
				 }
				const newSelected=archivedItems[newSelectedIndex]	   
			  this.setState({activePanelItem:newSelected})
		  }
	  }
	}

	render() {
		let panelActiveItem={}
		if(this.state.activePanelItem){
			panelActiveItem=find(this.props.archivedItems,{'id':this.state.activePanelItem.id})||find(this.props.archivedFolders,{'id':this.state.activePanelItem.id})||{}
			
		}
		return (
   	 <DocumentTitle title='Trash - Plickers'>
    	 <div className='page page--sidePanelLayout' >
					<div className='page-leftPanel'/>
					<div className='page-centerPanel library'>
    						
						<TopPageHeader 
							location='trash'
							height='175'
							leftBadge={
									<React.Fragment>
										<Icon name='chevron-left' />
										<PageIcon name='trashLarge' />
									</React.Fragment>}
							leftBadgeClickFunction={()=>{this.props.history.push('./library')}}
							header='Trash'							
							actionRow={
								<React.Fragment>
									<FilterBar
										value={this.state.filterTerm}
										onChange={this.handleFilterChange} 
										id='filterInput'
										clearBtnOnClick={() =>{this.clearFilter()}}
									/>
									<Button label='Empty Trash' onClickFunction={this.confirmEmptyTrash} />
								</React.Fragment>}
						/>

						<StickyPageHeader 
							location='trash' 
							leftBadge={
									<React.Fragment>
										<Icon name='chevron-left' />
										<PageIcon name='trashLarge' />
									</React.Fragment>
								}
							leftBadgeClickFunction={()=>{this.props.history.push('./library')}}
							header='Trash'						
						/>


						<Trash
							selectRow={this.handleSelectRow}						
							activeItem={this.state.activePanelItem}   
							keyPressNavigation={this.keyPressNavigation}
							deleteFolder={this.confirmDeleteFolder}
							filterTerm={this.state.filterTerm}   
							restoreItem={this.restoreItem}
							archivedItems={this.props.archivedItems}    
							archivedFolders={this.props.archivedFolders}
							restoreFolder={this.restoreFolder}
						/>
						
					</div>
					<div className='page-rightPanel'>
						<div className='sidePanelContainer'>
							<div className='sidePanel'> 
								<TrashPreviewPanel     
									restoreItem={this.restoreItem}
									deleteQuestion={this.confirmDeleteQuestion}
									deleteSet={this.confirmDeleteSet}   
									isLibrary={true}
									activeItem={panelActiveItem} 
									sections={this.props.sections}
									isTrash={true}
									restoreFolder={this.restoreFolder}
									deleteFolder={this.confirmDeleteFolder}
								/>
							</div>
						</div>
					</div>
					
				</div>
			</DocumentTitle>
		)
	}
}


function mapStateToProps(state,ownProps) {
	const archivedQuestions=filter(state.archivedQuestions,{'repo':null})
	const archivedSets=filter(state.archivedSets,{'repo':null})
	const archivedItems=archivedSets.concat(archivedQuestions)
	return {
		archivedFolders:sortBy(filter(state.archivedFolders, {'parent':null}),[function(o) { 
			return o.name.toString().toLowerCase()
		}]),
		archivedQuestions:archivedQuestions,
		archivedSets:archivedSets,
		archivedItems:archivedItems
	  }
	}

export default withRouter(connect(
	mapStateToProps,
	{ 
		fetchArchivedFolders,
		showNotification,
		deleteFolder,
		fetchArchivedQuestions,
		deleteQuestion,
		fetchArchivedSets,    
		deleteSet,
		showConfirmModal,   
		hideModal,
		updateSetMeta,
		updateQuestionMeta,
		updateFolder
	}
)(TrashContainer))

import {Node} from "prosemirror-model"
import filter from 'lodash/filter'
import editorSchema from '../schema/editorSchema'
const EMPTY_BODY_TEXT='Click here to edit question'
const UNTOUCHED_CHOICE_BODY='\u2063'

//Takes a Plickers set/question object and turns it into PM doc
//For each setQuestion/question we need to make
//Question node
//Question body node
//Question media node (optional)
//Choice group node
//For Each choice
	//Choice Node
	//Choice Body Node
	//ChoiceMediaNode or ChoiceMediaEmptyState Node 


export function convertSetToPMNode(set,questionOrderArray){ //takes a set object and returns PM Doc, used for loading editor from set obj
	let questionNodesArray=[]
	set.questions.forEach((question,i)=>{
		const questionNode=convertQuestionToPMNode(question,i,questionOrderArray)
		questionNodesArray.push(questionNode)
	})
	const doc=editorSchema.nodes.doc.createAndFill({},questionNodesArray)
	return doc
}

export function convertStandaloneQuestionToPMNode(question,questionId,slideWidth){ //Turn a question obj into a node for adding a question to set (see addQuestionToSet.js)
	let questionAttrs={
		template: question.layout,
		questionid: questionId|| question.questionId,
		bodyFontSize: question.measurements.bodyFS,
		choiceFontSize: question.measurements.choiceFS,
		questionMediaDynamicHeight: question.measurements.questionMediaDynamicHeight,
		slideWidth:slideWidth||''
	}
	const questionBodyNode=convertQuestionToQuestionBodyPMNode(question)
	let questionMediaNode
	if(question.media){
		questionMediaNode=convertQuestionToQuestionMediaPMNode(question,slideWidth)
	}
	let questionContentArray=[]
	questionContentArray.push(questionBodyNode)
	if(questionMediaNode){
		questionContentArray.push(questionMediaNode)
	}
	const choiceGroupNode=convertQuestionToChoiceGroupPMNode(question,slideWidth)
	questionContentArray.push(choiceGroupNode)
	const questionNode=editorSchema.nodes.question.createAndFill(questionAttrs,questionContentArray)
	return questionNode
}

export function convertIndependentQuestionToPMNode(question){ //Turns independent question into PM doc, used for loading question editor from question obj
	let questionAttrs={
		template: question.layout,
		activeslide:true,
		questionid: question.questionId,
		bodyFontSize: question.measurements.bodyFS,
		choiceFontSize: question.measurements.choiceFS,
		questionMediaDynamicHeight: question.measurements.questionMediaDynamicHeight,
	}
	const questionBodyNode=convertQuestionToQuestionBodyPMNode(question)
	let questionMediaNode
	if(question.media){
		questionMediaNode=convertQuestionToQuestionMediaPMNode(question)
	}
	let questionContentArray=[]
	questionContentArray.push(questionBodyNode)
	if(questionMediaNode){
		questionContentArray.push(questionMediaNode)
	}
	const choiceGroupNode=convertQuestionToChoiceGroupPMNode(question)
	questionContentArray.push(choiceGroupNode)
	const questionNode=editorSchema.nodes.question.createAndFill(questionAttrs,questionContentArray)
	const doc=editorSchema.nodes.doc.createAndFill({},[questionNode])
	return doc
}

function convertQuestionToPMNode(question,i,questionOrderArray){ //convert setQuestion into question PM node
	let questionAttrs={
		template: question.layout,
		activeslide: i===0?true:false,
		questionid: question.questionId,
		bodyFontSize: question.measurements.bodyFS,
		choiceFontSize: question.measurements.choiceFS,
		questionMediaDynamicHeight: question.measurements.questionMediaDynamicHeight,
		questionOrderArray:questionOrderArray
	}
	const questionBodyNode=convertQuestionToQuestionBodyPMNode(question)
	let questionMediaNode
	if(question.media){
		questionMediaNode=convertQuestionToQuestionMediaPMNode(question)
	}
	let questionContentArray=[]
	questionContentArray.push(questionBodyNode)
	if(questionMediaNode){
		questionContentArray.push(questionMediaNode)
	}
	const choiceGroupNode=convertQuestionToChoiceGroupPMNode(question)
	questionContentArray.push(choiceGroupNode)
	const questionNode=editorSchema.nodes.question.createAndFill(questionAttrs,questionContentArray)
	return questionNode
}

function convertQuestionToChoiceGroupPMNode(question,slideWidth){ //Makes a ChoiceGroup node from question obj
	let choiceNodesArray=[]
	const fontSize = question.measurements.choiceFS
	const template = question.layout
	const questionChoiceCount = question.choices.length
	let isSurvey = true
	if(filter(question.choices, { 'correct': true }).length !== 0) {
		isSurvey=false
	}
	let hasUntouchedChoices=false
	question.choices.forEach((choice,i)=>{
		if(choice.body===UNTOUCHED_CHOICE_BODY){
			hasUntouchedChoices=true
		}
		const node= convertChoiceToPMNode(choice,i,isSurvey,questionChoiceCount,fontSize,template,slideWidth)
		choiceNodesArray.push(node)
	})
	const choiceGroupNode=editorSchema.nodes.choiceGroup.createAndFill({choicesComplete:!hasUntouchedChoices},choiceNodesArray)
	return choiceGroupNode
}

function convertChoiceToPMNode(choice,choiceIndex,isSurvey,questionChoiceCount,fontSize,template,slideWidth){
	let choiceAttrs={
		correct:choice.correct,
		isSurvey:isSurvey,
		choiceIndex:choiceIndex,
		questionChoiceCount:questionChoiceCount,
		fontSize:fontSize,
		template:template
	}
	let isTouched=true
	if(choice.body===UNTOUCHED_CHOICE_BODY){
		isTouched=false
	}
	choiceAttrs.isTouched=isTouched
	let choiceNode
	let choiceMediaNode
	if(choice.media){
		choiceMediaNode=convertChoiceToMediaPMNode(choice,questionChoiceCount,slideWidth)
	}
	let choiceContentArray=[]
	const choiceBodyNode=convertChoiceToChoiceBodyPMNode(choice)
	choiceContentArray.push(choiceBodyNode)
	if(choiceMediaNode){
		choiceContentArray.push(choiceMediaNode)
	}
	choiceNode=editorSchema.nodes.choice.createAndFill(choiceAttrs,choiceContentArray)
	return choiceNode
}

function convertChoiceToChoiceBodyPMNode(choice){
	let content=choice.bodySource
	let choiceBodyNode
	if(!content || choice.body===UNTOUCHED_CHOICE_BODY){   
	 choiceBodyNode=editorSchema.nodes.choiceBody.createAndFill({})
	}else{
		const nodeJSON={
			type: 'choiceBody',
			content: content,
		}
		choiceBodyNode = Node.fromJSON(editorSchema,nodeJSON)
	 }
	return choiceBodyNode
}

function convertQuestionToQuestionBodyPMNode(question){
	let content=question.bodySource
	let questionBodyNode
	if(!content || question.body===EMPTY_BODY_TEXT){
		if(!question.body || question.body===EMPTY_BODY_TEXT){
			content=editorSchema.nodes.paragraph.createAndFill()
		}else{
			content=editorSchema.nodes.paragraph.createAndFill({},editorSchema.text(question.body))
		}
	 
	 questionBodyNode=editorSchema.nodes.questionBody.create({
		fontSize:question.measurements.bodyFS},
		content)
	}
	 else{
			const nodeJSON={
				type: "questionBody",
				content: content,
				attrs: {
					fontSize: question.measurements.bodyFS
				}
			}
		questionBodyNode = Node.fromJSON(editorSchema,nodeJSON)
	 }
	return questionBodyNode
}

//////////Media node stuff- TODO clean up/combine choice and prompt media

function convertQuestionToQuestionMediaPMNode(question,slideWidth){ //Takes a plickers question obj and returns a prompt media PM node
	let questionMediaNode=null
	const media = question.media
	if(media.type==='image'){
		const image = media
		let imageAttrs={
			fileId:image.fileId,
			addedAt:image.addedAt||'',
			deliveryUrl:image.deliveryUrl,
			title:image.title,
			attribution:image.attribution,
			questionMediaDynamicHeight:question.measurements.questionMediaDynamicHeight,
			template:question.layout,
			rotate:image.rotate,
			originalDimensions:image.originalDimensions,
			deliveryDimensions:image.deliveryDimensions,
			crop:image.crop,
			markup:image.markup,
			colorAdjust:image.colorAdjust,
			imageFill:image.imageFill,
			slideWidth:slideWidth||''
		}
		questionMediaNode=editorSchema.nodes.questionImage.createAndFill(imageAttrs)
	}else if(media.type==='video'){
		const video = media
		let videoAttrs={
			questionMediaDynamicHeight:question.measurements.questionMediaDynamicHeight,
			template:question.layout,
			addedAt:video.addedAt||'',
			youtubeId:video.youtubeId,
			fullDuration:video.fullDuration,
			title:video.title,
			channelTitle:video.channelTitle,
			thumbnail:video.thumbnail,
			isClipped:video.isClipped,
			start:video.start,
			end:video.end,
			slideWidth:slideWidth||''
		}
		questionMediaNode=editorSchema.nodes.questionVideo.createAndFill(videoAttrs)
	}else if(media.type==='sound'){
		const sound = media
		let soundAttrs={
			questionMediaDynamicHeight:question.measurements.questionMediaDynamicHeight,
			template:question.layout,
			fileId:sound.fileId,
			deliveryUrl:sound.deliveryUrl,    
			title:sound.title,
			fullDuration:sound.fullDuration,
			isClipped:sound.isClipped,
			start:sound.start,
			end:sound.end,
			source:sound.source,
			attribution:sound.attribution,
			addedAt:sound.addedAt||'',
			slideWidth:slideWidth||''
		}
		questionMediaNode=editorSchema.nodes.questionSound.createAndFill(soundAttrs)
	}else if(media.type==='animatedGif'){
		const gif = media
		let gifAttrs={
			fileId:gif.fileId,
			dimensions:gif.dimensions,
			title:gif.title,
			attribution:gif.attribution,
			host:gif.host,
			stillUrl:gif.stillUrl,
			webpUrl:gif.webpUrl,
			gifUrl:gif.gifUrl,
			giphySlug:gif.giphySlug,
			giphyUser:gif.giphyUser,
			template:question.layout,
			imageFill:gif.imageFill,  
			addedAt:gif.addedAt||'',
			slideWidth:slideWidth||''
		}
		questionMediaNode=editorSchema.nodes.questionAnimatedGIF.createAndFill(gifAttrs)
	}
	return questionMediaNode
}

function convertChoiceToMediaPMNode(choice,questionChoiceCount,slideWidth){
	let choiceMediaNode=null
	const media = choice.media
	if(media.type==='image'){
		const image = media
		let imageAttrs={
			questionChoiceCount:questionChoiceCount,
			fileId:image.fileId,
			addedAt:image.addedAt||'',
			title:image.title,
			attribution:image.attribution,
			deliveryUrl:image.deliveryUrl,
			rotate:image.rotate,
			originalDimensions:image.originalDimensions,
			deliveryDimensions:image.deliveryDimensions,
			crop:image.crop,
			markup:image.markup,
			colorAdjust:image.colorAdjust,
			imageFill:image.imageFill,
			slideWidth:slideWidth||''
		}
		choiceMediaNode=editorSchema.nodes.choiceImage.createAndFill(imageAttrs)
	}else if(media.type==='video'){
		const video = media
		let videoAttrs={
			questionChoiceCount:questionChoiceCount,
			addedAt:video.addedAt||'',
			youtubeId:video.youtubeId,
			fullDuration:video.fullDuration,
			title:video.title,
			channelTitle:video.channelTitle,
			thumbnail:video.thumbnail,
			isClipped:video.isClipped,
			start:video.start,
			end:video.end,
			slideWidth:slideWidth||''
		}
		choiceMediaNode=editorSchema.nodes.choiceVideo.createAndFill(videoAttrs)
	}else if(media.type==='sound'){
		const sound = media
		let soundAttrs={
			questionChoiceCount:questionChoiceCount,
			fileId:sound.fileId,
			deliveryUrl:sound.deliveryUrl,    
			title:sound.title,
			fullDuration:sound.fullDuration,
			isClipped:sound.isClipped,
			start:sound.start,
			end:sound.end,
			source:sound.source,
			attribution:sound.attribution,
			addedAt:sound.addedAt||'',
			slideWidth:slideWidth||''
		}
		choiceMediaNode=editorSchema.nodes.choiceSound.createAndFill(soundAttrs)
	}else if(media.type==='animatedGif'){
		const gif = media
		let gifAttrs={
			questionChoiceCount:questionChoiceCount,
			fileId:gif.fileId,
			dimensions:gif.dimensions,
			title:gif.title,
			attribution:gif.attribution,
			host:gif.host,
			stillUrl:gif.stillUrl,
			webpUrl:gif.webpUrl,
			gifUrl:gif.gifUrl,
			giphySlug:gif.giphySlug,
			giphyUser:gif.giphyUser,
			imageFill:gif.imageFill,  
			addedAt:gif.addedAt,
			slideWidth:slideWidth||''
		}
		choiceMediaNode=editorSchema.nodes.choiceAnimatedGIF.createAndFill(gifAttrs)
	}
	return choiceMediaNode
}


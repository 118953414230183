import PropTypes from 'prop-types'
import React, { Component } from 'react'


class Image extends Component {
	tileViewportStyle () {        
		return {
			width: this.props.item.vwidth,
			height: this.props.height,
			overflow: 'hidden'
		}
	}

	thumbnailStyle () {
		return {
			cursor: 'pointer',
			width: this.props.item.scaletwidth,
			height: this.props.height,
			marginLeft: this.props.item.marginLeft,
			marginTop: 0
		}
	}

	render () {
		const {item}=this.props
		const giphyOriginalGifURL = item.giphyGalleryGifURL
		const giphyOriginalWebPURL = item.giphyGalleryWebPUrl
		return (
			<div className={'tile' + (this.props.item.isSelected ? ' is--selected' : ' ')}
				key={'tile-'+this.props.index}            
				style={{
					margin: this.props.margin,
					WebkitUserSelect: 'none',
					position: 'relative',
					float: 'left',
					background: `#${this.props.item.accentColor || 'eee'}`,
					padding: '0px'}}>              

				<div className='tile-viewport'
					style={
						this.tileViewportStyle()
					}
					key={'tile-viewport-'+this.props.index}
					onClick={this.props.onClick ?
						(e) => this.props.onClick(this.props.index, e) : null}
					onDoubleClick={this.props.onDoubleClick ?
						(e) => this.props.onDoubleClick(this.props.index, e) : null}
				>					
					{!this.props.isGif &&
						<img
							alt=''
							key={'img-'+this.props.index}
							src={this.props.item.thumbnail} title={this.props.item.caption}
							style={this.thumbnailStyle()}
						/>
					}	
					{this.props.isGif &&						
						<picture>
							<source type="image/webp" srcSet={giphyOriginalWebPURL} />
							<img style={this.thumbnailStyle()} src={giphyOriginalGifURL} alt=''/>          
						</picture>                  
					}
				</div>
			</div>
		)
	}
}

Image.propTypes = {
	item: PropTypes.object,
	index: PropTypes.number,
	margin: PropTypes.number,
	height: PropTypes.number,
	isSelectable: PropTypes.bool,
	onClick: PropTypes.func,
	onSelectImage: PropTypes.func,
	tileViewportStyle: PropTypes.func
}

Image.defaultProps = {
	isSelectable: true
}

export default Image
import React from 'react'
import Icon from '../components/misc/Icons'
import { connect } from 'react-redux'
import {updatePreviewItem} from '../actions/previewItem'
import StaticQuestionSlide from '../components/slides/StaticQuestionSlide'
import find from 'lodash/find'

//set/question preview modal opened by pressing space bar when have item selected in library/recent

class ItemReviewModalSetCounterItem extends React.Component{
	render(){
		const {index,isCurrentQuestion,question,setPreviewQuestionIndex}=this.props
		return(
			<div 
				key={index}
				onClick={()=>{setPreviewQuestionIndex(index)}}
				className={'itemPreviewModal-setCounter-item ' + 
					(isCurrentQuestion ? ' itemPreviewModal-setCounter-item--activeQuestion ' : ' itemPreviewModal-setCounter-item--notActiveQuestion ')						
				}
			>
				<div className='itemPreviewModal-setCounter-item-pillContainer'>
					<div className='itemPreviewModal-setCounter-item-pill'/>
				</div>

				<div className='itemPreviewModal-setCounter-item-slideContainer'>
					<StaticQuestionSlide 
						question={question}
						stage='ask'
						mode='view'
						displayWidth={320}
						previewCard={true} 				 			
					/>
				</div>
			</div>
		)
	}
}


class ItemReviewModalSetCounter extends React.Component{

	render(){
		const {set,previewQuestionIndex}=this.props
		return(
			<div className='itemPreviewModal-setCounter'>
				{set.questions.map((question, index) => {											
						return(
							<ItemReviewModalSetCounterItem
								key={index}
								index={index}
								isCurrentQuestion={index === previewQuestionIndex}							
								question={question}	
								setPreviewQuestionIndex={this.props.setPreviewQuestionIndex}												
							/>											
						)}
					)
				}		
			</div>
		)
	}
}


class ItemReviewModal extends React.Component{
	constructor(props) {
		super(props)
		this.handleKeyDown = this.handleKeyDown.bind(this)
	}

	UNSAFE_componentWillMount(){
		document.body.style.overflowY='hidden' //prevent scrolling while modal is open
		document.addEventListener('keydown', this.handleKeyDown)
	}

	handleKeyDown(e){
		if(e.keyCode === 32){//space key	
			e.preventDefault()
			this.props.updatePreviewItem(null)
		}
	}

	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
		document.removeEventListener('keydown', this.handleKeyDown)
	}

	render(){
		const {set,previewQuestionIndex,question}=this.props
			return(
				<div className={'itemPreviewModal ' + (question ? 'itemPreviewModal--question':'itemPreviewModal--set')}>
					{set &&											
						<button className={'itemPreviewModal-navigateBtn itemPreviewModal-navigateBtn--previous ' + (previewQuestionIndex === 0 ? 'itemPreviewModal-navigateBtn--unavailable' : '')} onClick={this.props.previousArrowOnClick} onMouseDown={(e)=>{e.preventDefault()}}>
							<div className='itemPreviewModal-navigateBtn-iconContainer'>
								<Icon name='chevron-left' />
							</div>
						</button>	
					}			

					{set &&											
						<button className={'itemPreviewModal-navigateBtn itemPreviewModal-navigateBtn--next'+ ((previewQuestionIndex + 1) === set.questions.length ? 'itemPreviewModal-navigateBtn--unavailable' : '')}  onClick={this.props.nextArrowOnClick} onMouseDown={(e)=>{e.preventDefault()}}>
							<div className='itemPreviewModal-navigateBtn-iconContainer'>
								<Icon name='chevron-right' />
							</div>
						</button>							
					}
																	
					{set && 						
						<div style={{width: `${this.props.slideWidth}px`}} className='itemPreviewModal-topBar'>
							<div className='itemPreviewModal-topBar-counter'>
								
							</div>
							<div className='itemPreviewModal-topBar-setTitle'>
								{set.name}
							</div>
							<div className='itemPreviewModal-topBar-counter'>
								{previewQuestionIndex + 1} / {set.questions.length}							
							</div>
						</div>					
					}


					<div style={{width: `${this.props.slideWidth}px`, height:`${this.props.slideWidth / 1.6}px`}} className='itemPreviewModal-slideContainer'>
						
						<StaticQuestionSlide 
							question={question || set.questions[previewQuestionIndex]}
							displayWidth={this.props.slideWidth} 
							stage='ask'
							mode='view'				 			
							previewCard={true} 
							showCorrect				 			
							mediaIsPlayable
							key={previewQuestionIndex}
						/>
						
					</div>

					{set && 
						<div className='itemPreviewModal-setCounterContainer'>
							<ItemReviewModalSetCounter 
								setPreviewQuestionIndex={this.props.setPreviewQuestionIndex}
								set={set}
								previewQuestionIndex={previewQuestionIndex}
							/>
						</div>
					}
			</div>
		)
	}
}

class ItemPreviewModalContainer extends React.Component{

	constructor(props) {
		super(props)
		this.handleKeyDown = this.handleKeyDown.bind(this)
		this.previousArrowOnClick = this.previousArrowOnClick.bind(this)
		this.nextArrowOnClick = this.nextArrowOnClick.bind(this)
		this.handleBackgroundClick = this.handleBackgroundClick.bind(this)
		this.state = {
			previewQuestionIndex: 0,
		}
	}

	UNSAFE_componentWillMount(){
		document.addEventListener('keydown', this.handleKeyDown)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.previewItem!==this.props.previewItem){
			this.setState({previewQuestionIndex:0})
		}
	}

	componentWillUnmount(){
		document.removeEventListener('keydown', this.handleKeyDown)
	}


	handleKeyDown(e){ //this interferes with other arrow key handlers in app so need to test more before adding
		// if(e.keyCode===37){ //left arrow key
		// 	this.previousArrowOnClick(e)
		// }else if(e.keyCode===39){ //right arrow key
		// 	this.nextArrowOnClick(e)
		// }else if(e.keyCode === 27){//escape key
		// 	this.props.updatePreviewItem(null)
		// }
		
	}

	previousArrowOnClick(e){
		if(this.props.set){
			e.stopPropagation() 
			const currentIndex=this.state.previewQuestionIndex
			const newIndex=Math.max(currentIndex-1,0)
			this.setState({previewQuestionIndex:newIndex})
		}
	}


	nextArrowOnClick(e){
		if(this.props.set){
			e.stopPropagation() 
			const currentIndex=this.state.previewQuestionIndex
			const newIndex=Math.min(currentIndex+1,this.props.set.questions.length-1)
			this.setState({previewQuestionIndex:newIndex})
		}
	}

	

	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.props.updatePreviewItem(null) //close modal is click outside modal
	}


	render(){
		const {set,question} = this.props
		const screenWidth=window.innerWidth
		const screenHeight=window.innerHeight
		const sideButtonWidth = 80 // side buttons for going forward and backward
		const minimumSideBuffer = 80 // space between edge of modal and screen
		const minimumVerticalBuffer = 12 // space between edge of modal and screen
		const modalVerticalPadding = 38 // space above and below slide for title and counter
		const availableWidth = Math.min(1280,(screenWidth - sideButtonWidth - sideButtonWidth - minimumSideBuffer - minimumSideBuffer))
		const availableHeight = Math.min(800,(screenHeight - minimumVerticalBuffer - minimumVerticalBuffer - modalVerticalPadding - modalVerticalPadding))
		let slideWidth 
		if((availableHeight * 1.6) > availableWidth){
			// taller than wide
			slideWidth = availableWidth 
		}else{
			// wider than tall
			slideWidth = availableHeight * 1.6
		}
		if(set){
			return(
				<div className='itemPreviewModalContainer'>					
										
					<ItemReviewModal 
						updatePreviewItem={this.props.updatePreviewItem}
						previousArrowOnClick={this.previousArrowOnClick}
						nextArrowOnClick={this.nextArrowOnClick}
						set={set}
						previewQuestionIndex={this.state.previewQuestionIndex}
						slideWidth={slideWidth}
						handleBackgroundClick={this.handleBackgroundClick}
						setPreviewQuestionIndex={(index)=>{this.setState({previewQuestionIndex:index})}}
					/>																

					<div className='itemPreviewModalContainer-bg' onClick={this.handleBackgroundClick} onMouseDown={(e)=>{e.preventDefault()}}/>

				</div>

			)
			}else if(question){
				return(
					<div className='itemPreviewModalContainer'>					
											
						<ItemReviewModal 
							updatePreviewItem={this.props.updatePreviewItem}
							question={question}	
							slideWidth={slideWidth}		
							handleBackgroundClick={this.handleBackgroundClick}				
						/>																

						<div className='itemPreviewModalContainer-bg' onClick={this.handleBackgroundClick} onMouseDown={(e)=>{e.preventDefault()}}/>

					</div>

				)
			}else return null
	}
}

function mapStateToProps(state) {
	const previewItem=state.previewItem
	let set
	let question
	if(previewItem){
		set=find(state.sets,{id:previewItem})
		question=find(state.questions,{id:previewItem})
	}
	return {
		previewItem:previewItem,
		set:set,
		question:question
	}
}

export default connect(
	mapStateToProps,
	{updatePreviewItem }
)(ItemPreviewModalContainer)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Measure from 'react-measure'
import _ from 'lodash'
import { showRepoSelectionModal} from '../../actions/modals'
import {createPoll} from '../../utils/createPoll'
import {removeFromQueue} from '../../utils/removeFromQueue'
import TopPageHeader from '../../components/pageHeaders/TopPageHeader'
import Button from '../../components/uiKit/Button'
import AddToQueueDropdown from '../../components/uiKit/AddToQueueDropdown'
import PollsForItemList from '../../components/uiKit/PollsForItemList'
import {formatDate} from '../../utils/formatDate'
import {formatDateConsumerView} from '../../utils/formatDateConsumerView'
import {showSectionSelectionModal} from '../../actions/modals'
import StaticQuestionSlide from '../../components/slides/StaticQuestionSlide'
import { Link } from 'react-router-dom'
import {openItemInEditor} from '../../utils/openItemInEditor'
import ItemDetailHistoryList from '../../components/itemDetail/ItemDetailHistoryList'
import Icon from '../../components/misc/Icons'
import DocumentTitle from 'react-document-title' 
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'
import {getUserForId} from '../../utils/getUserForId'
import {getDisplayNameForUser} from '../../utils/getDisplayNameForUser' 
import {showNotification} from '../../actions/notifications'
import {addCopyToLibrary} from '../../utils/addCopyToLibrary'
import {getLinkForRepo} from '../../utils/getLinkForRepo'


class QuestionDetailContainer extends Component {

	constructor() {
		super()
		this.addToQueue=this.addToQueue.bind(this)
		this.playQuestion=this.playQuestion.bind(this)
		this.addCopyToLibrary=this.addCopyToLibrary.bind(this)
		this.state={
			centerPanelInnerWidth:0
		}
	}
	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Item Detail',{itemType:'Question'})
		}
	}

	addCopyToLibrary(){
		return this.props.addCopyToLibrary(this.props.question,'Item Detail').then(()=>{
			this.props.showNotification('to Your Library','Added a Copy','create')
		})
	}

	addToQueue(sectionId){
		this.props.createPoll(this.props.question,sectionId)
	}

	playQuestion(){
		this.props.showSectionSelectionModal(this.props.question)
	}


	render() {
		const {question,questions,parentRepo}=this.props   
		let hasParentFolder
		let parentFolderName=''
		let documentTitle='Detail'
		if(question){
			hasParentFolder = question.folder
			parentFolderName = this.props.parentFolder.name
			documentTitle=`${getQuestionBodyLabel(question)} - Plickers`
		}
		const inRepo = (question && question.repo)
		let repoName = ''
		let inSharedRepo = false
		let isViewOnly = question && question.readOnly
		let lastEditedBy
		let isPublicConsumer = false

		if(parentRepo){
			repoName = parentRepo.name
			inSharedRepo=parentRepo.shared
			if(parentRepo.role ==='viewer' || parentRepo.role ==='consumer'){
				isViewOnly=true
			}
			if(inSharedRepo){
				const lastEditedUser = getUserForId(question.lastEditedBy,question.repo)
				lastEditedBy=getDisplayNameForUser(lastEditedUser)
			}
			if(parentRepo.role === 'consumer'){
				isPublicConsumer = true
			}
		}
		return(
		<DocumentTitle title={documentTitle}> 
       	 <div className='page page--wideLayout questionDetail'>
        	<div className='page-leftPanel' />        	
    			<div className='page-centerPanel'>
    				<Measure onMeasure={(dimensions) => {this.setState({centerPanelInnerWidth:dimensions.width})}}>
    					<div className='page-centerPanel-innerMeasureSpacer'/>
    				</Measure>

					{question &&
						<TopPageHeader
							location='questionDetail'
							height={249}

							header={
								<React.Fragment>
									{getQuestionBodyLabel(question)}
									{inSharedRepo && <Icon name='shared'/>}
								</React.Fragment>
								}
							multiLineHeader
							headerInfoRow={
								<React.Fragment>
									{hasParentFolder && !inRepo &&
										<Link 
											className='pageHeaderTop-headerInfoRow-folderLink'
											to={`/library/${question.folder}`}
										>
											<Icon name='folder' />
											{parentFolderName}				
										</Link>
									}

									{inRepo &&
										<Link 
											className='pageHeaderTop-headerInfoRow-repoLink'
											to={getLinkForRepo(parentRepo)}
										>									
											{repoName}
										</Link>
									}

									{inRepo && hasParentFolder &&
										<React.Fragment>
											<Icon name='chevron-right'/>

											<Link 
												className='pageHeaderTop-headerInfoRow-repoFolderLink'
												to={`${getLinkForRepo(parentRepo)}/${question.folder}`}
											>									
												{parentFolderName}
											</Link>
											
										</React.Fragment>
									}					

									{!isPublicConsumer &&
										<React.Fragment>						
											{lastEditedBy?`${lastEditedBy} edited ` :'Edited'} {formatDate(question.clientModified)}								 
										</React.Fragment> 
									}

									{isPublicConsumer &&
										<React.Fragment>
											Edited {formatDateConsumerView(question.clientModified)}								 
										</React.Fragment> 
									}		

									
									
								</React.Fragment>
							}

							actionRow={
								<React.Fragment>
									<Button iconName='play' label='Play Now' onClickFunction={this.playQuestion}/>
									<Button iconName={!isViewOnly ? 'compose' : null} label={!isViewOnly ? 'Edit Question' : 'View Question'} onClickFunction={()=>{openItemInEditor(question.id,false)}} />
									{!isViewOnly && <Button iconName='open-left-arrow' label='Move to Pack' name='moverepo' onClickFunction={()=>{this.props.showRepoSelectionModal(question,null,true)}}/> }
									{isViewOnly && <Button label='Add Copy to Library' onClickFunction={this.addCopyToLibrary} /> }
									<AddToQueueDropdown item={question} dropdownButton={<Button iconName='plus' label='Add to Queue' />}/>
									<div className='pageHeaderTop-actionRow-spacer' />
									<PollsForItemList item={question}/>
								</React.Fragment>
							}
							borderBottom
							backButton							
						/>
					}

					{question &&
						<div style={{height: `${this.state.centerPanelInnerWidth / 1.6}px`, overflow:'hidden'}} className='questionDetail-slide'>
							<StaticQuestionSlide question={question} stage='ask' mode='view' displayWidth={this.state.centerPanelInnerWidth - 2} previewCard={true} />
						</div>

						// note nothing we need in question detail page
					}
					{!question && questions && 
						<div className='questionDetail'>
							<h3>Something went wrong. Please try again, if the problem persists contact support.</h3>
						</div>
					}

				</div>
				<div className='page-rightPanel'>
					{this.props.historyPolls.length > 0 && 
						<div className='sidePanelContainer'>
							<div className='sidePanel'>
								<ItemDetailHistoryList historyPolls={this.props.historyPolls} sections={this.props.sections}/>
							</div>
						</div>
					}
				</div>
			</div> 
			</DocumentTitle>
		)
	}
}

function mapStateToProps(state,ownProps) {
	const currentQuestion= _.find(state.questions, {'id':ownProps.match.params.id})
	const historyPolls=_.sortBy(_.filter(_.filter(state.historyQuestionPolls,{'archived':false}),{'question':ownProps.match.params.id}), 'maxCaptured').reverse()
	const polls=_.filter(state.queueQuestionPolls,{'question':ownProps.match.params.id})
	let parentFolder
	let parentRepo
	if(currentQuestion){
		parentFolder= _.find(state.folders, {'id':currentQuestion.folder})
		parentRepo= _.find(state.repos, {'id':currentQuestion.repo})	
	}

	return {
		questions:state.questions,
		question:currentQuestion,
		parentFolder:parentFolder || {},
		sections:_.filter(state.sections,{'archived':false}),
		historyPolls:historyPolls,
		parentRepo:parentRepo,
		polls:polls   
	}
}


export default connect(
	mapStateToProps,
	{ createPoll,
		removeFromQueue,
		showSectionSelectionModal,
		showRepoSelectionModal,
		showNotification,
		addCopyToLibrary
	}
)(QuestionDetailContainer)



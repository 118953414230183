module.exports = [
	{value:'as', label:'Assamese'},
	{value:'id', label:'Bahasa Indonesia'},
	{value:'ms', label:'Bahasa Melayu'},
	{value:'ca', label:'Català'},
	{value:'cs', label:'Čeština'},
	{value:'da', label:'Dansk'},
	{value:'de', label:'Deutsch'},
	{value:'et', label:'Eesti'},
	{value:'en', label:'English'},
	{value:'es', label:'Español'},
	{value:'eu', label:'Euskara'},
	{value:'fr', label:'Français'},
	{value:'ga', label:'Gaeilge'},
	{value:'gl', label:'Galego'},
	{value:'hr', label:'Hrvatski'},
	{value:'it', label:'Italiano'},
	{value:'lv', label:'Latviešu valoda'},
	{value:'lt', label:'Lietuvių kalba'},
	{value:'hu', label:'Magyar'},
	{value:'nl', label:'Nederlands'},
	{value:'nb', label:'Norsk Bokmål'},
	{value:'uz', label:'Oʻzbek'},
	{value:'pl', label:'Polski'},
	{value:'pt', label:'Português'},
	{value:'ro', label:'Română'},
	{value:'sq', label:'Shqip'},
	{value:'sk', label:'Slovenčin'},
	{value:'sl', label:'Slovenščina'},
	{value:'fi', label:'Suomi'},
	{value:'sv', label:'Svenska'},
	{value:'vi', label:'Tiếng Việt'},
	{value:'tr', label:'Türkçe'},
	{value:'tl', label:'Wikang Tagalog'},
	{value:'ar', label:'العربية'},
	{value:'bg', label:'български език'},
	{value:'km', label:'ខ្មែរ'},
	{value:'zh', label:'汉语'},
	{value:'ka', label:'ქართული'},
	{value:'el', label:'ελληνικά'},
	{value:'gu', label:'ગુજરાતી'},
	{value:'he', label:'עברית'},
	{value:'hi', label:'हिन्दी'},
	{value:'ja', label:'日本語'},
	{value:'kk', label:'қазақ тілі'},
	{value:'ko', label:'한국어'},
	{value:'lo', label:'ພາສາລາວ'},
	{value:'mr', label:'मराठी'},
	{value:'mn', label:'Монгол хэл'},
	{value:'ru', label:'русский'},
	{value:'sr', label:'српски језик'},
	{value:'th', label:'ไทย'},
	{value:'uk', label:'Українська'}
	]



import React from 'react'
import { ContextMenu, MenuItem ,SubMenu} from 'react-contextmenu'
import SectionMarker from '../uiKit/SectionMarker'
import Icon from '../misc/Icons'
import sortBy from 'lodash/sortBy' 
 
const SUBMENU_HOVER_DELAY=0

class MultiSelectContextMenu extends React.Component{
	render(){
		const {
			handleMultiSelectContextMenuClick,
			multiSelectItems,
			folders,
			multiSelectContainsFolders,
			sections,
			multiSelectContainsReadOnly,
			isViewOnlyRepo}=this.props

		let numberOfQuestionsSelected=0
		Object.keys(multiSelectItems).map((key) => {
			const item=multiSelectItems[key]
			if(item.questions){
				numberOfQuestionsSelected+=item.questions.length
			}else{
				numberOfQuestionsSelected+=1
			}
			return null
		})
		let disableCombineIntoSet=false
		if(multiSelectContainsFolders ||(this.props.questionsInSetLimit && numberOfQuestionsSelected > this.props.questionsInSetLimit)){
			disableCombineIntoSet=true
		}

		let sortedFolders=folders.slice(0)
		sortedFolders=sortBy(sortedFolders,[function(o) { 
			return o.name.toString().toLowerCase()
		}])

		const isViewOnly = multiSelectContainsReadOnly || isViewOnlyRepo


		return(
			<ContextMenu id='MULTISELECT' className='contextMenu'>
				
				{!isViewOnly &&
					<React.Fragment>
						<MenuItem disabled={disableCombineIntoSet||isViewOnly} onClick={handleMultiSelectContextMenuClick} data={{action: 'CombineIntoSet'}} >			    
							New Set from Selection
						</MenuItem>

					{!multiSelectContainsFolders && sections.length < 8 &&
						<MenuItem divider/>
					}

					</React.Fragment>
				}
				
				


				{sections.length < 8 && !multiSelectContainsFolders &&
					<SubMenu title={'Add all to Queue'} attributes={{className:'contextSubMenu'}} hoverDelay={SUBMENU_HOVER_DELAY}>
						{sections.map((section,index) =>      											
							<MenuItem key={`queue_${index}`} onClick={handleMultiSelectContextMenuClick} data={{ action: 'Queue', section:section }}>
		     				<SectionMarker section={section} />					
								<Icon name="plus" />
								<SectionMarker section={section} type={'colorOnly'} />
							</MenuItem>
						)}
					</SubMenu>
				}


			{/*-------------- */}

				{!isViewOnly &&
					<React.Fragment>

						<MenuItem divider/>

						<MenuItem disabled={isViewOnly} data={{ action: 'MoveToRepo'}} onClick={handleMultiSelectContextMenuClick} >
								Move all to Pack...
						</MenuItem>	

						<MenuItem divider/>			

						{sortedFolders.length < 8 &&
							<SubMenu title='Move all to Folder' disabled={isViewOnly} attributes={{className:'is--subMenuHeader'}} className='contextMenu-subMenu--folder' hoverDelay={SUBMENU_HOVER_DELAY}>
								{sortedFolders.map((folder,index) =>{
									let isDisabled=false
										if(folder.readOnly || multiSelectItems[folder.id]){
											isDisabled=true
										}									
										return(
											<MenuItem disabled={isDisabled} key={`move_${index}`} onClick={handleMultiSelectContextMenuClick} data={{ action: 'MoveToFolder', folder:folder }}>{folder.name}</MenuItem>          
										)									
								}
								)}
								<MenuItem onClick={handleMultiSelectContextMenuClick} data={{ action: 'Move'}}>Select Folder...</MenuItem>
							</SubMenu>
						}
						
						{sortedFolders.length >= 8 &&						
							<MenuItem disabled={isViewOnly} data={{ action: 'Move'}} onClick={handleMultiSelectContextMenuClick} >
								Move all to Folder...
							</MenuItem>					
						}					

						<MenuItem disabled={isViewOnly} attributes={{className:'is--warning'}} onClick={handleMultiSelectContextMenuClick} data={{action: 'Archive'}} >			    
							Send all to Trash
						</MenuItem>

					</React.Fragment>
				}
				
				

			</ContextMenu>
		)
	}
}

export default MultiSelectContextMenu


import React, { Component } from 'react'
import { connect } from 'react-redux'
import ExplorerEditRepoNotesModal from '../../components/explorer/ExplorerEditRepoNotesModal' 
import {adminUpdateRepoMetadata} from '../../actions/admin'
import moment from 'moment'

  
class AdminEditRepoNotesContainer extends Component {
  
	constructor(props){
		super(props)

		this.handleNoteChange=this.handleNoteChange.bind(this)
		this.saveNotes=this.saveNotes.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.appendWithNameAndDate=this.appendWithNameAndDate.bind(this)

		let repoNotes=''
		if(props.repo.explorerMetadata){
			repoNotes=props.repo.explorerMetadata.notes	||''	
		}

		this.state={
			repoNotes:repoNotes,
			submitLoading:false
		}        

	}        

	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyDown)
	}
	
	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown)
	}

	handleKeyDown(e){
		if(e.keyCode === 27){//escape key
			this.props.hideModal()
		}
		if(e.keyCode === 13 && e.shiftKey){
			e.preventDefault()
			this.saveNotes()
		}
		if(e.shiftKey && e.keyCode === 88 && (e.metaKey || e.ctrlKey)){
			this.appendWithNameAndDate()
		}
	}

	appendWithNameAndDate(){
		const textArea=document.getElementById('repoNotesTextArea')
		
		//comment signature [{user.firstname} - {day month}]
		const firstName=this.props.user.firstName
		const date=moment().format('DD MMM')
		const noteSignature=`[${firstName}-${date}] `

		let notes=`${noteSignature}\r\n\r\n`+ this.state.repoNotes

		this.setState({repoNotes:notes},
			()=>{
				textArea.focus()
			    textArea.selectionEnd=noteSignature.length

			})
	}




	handleNoteChange(e){
		this.setState({
			repoNotes:e.target.value,
		})
	}

	
	saveNotes(){
		this.setState({submitLoading:true})
		this.props.adminUpdateRepoMetadata(this.props.repo.id,{notes:this.state.repoNotes}).then(()=>{
			this.props.hideModal()
		})
	}

 

	render() {
		const {repo}=this.props

		return(
			<div className='explorerEditRepoNotesModalContainer'>
				<ExplorerEditRepoNotesModal
					repo={repo}
					repoNotes={this.state.repoNotes}
					handleNoteChange={this.handleNoteChange}
					saveNotes={this.saveNotes}
					submitLoading={this.state.submitLoading}
					hideModal={this.props.hideModal}
				/>
				<div className='explorerEditRepoNotesModalContainer-background' onClick={this.props.hideModal} />
			</div>
		)		
	}
}


export default connect(
	(state) => ({
		user:state.user}),
	{adminUpdateRepoMetadata
		}
)(AdminEditRepoNotesContainer)



import api from '../api'
import {updateSectionControl} from './sectionControl'
import {calibratedCurrentTime} from '../utils/calibratedCurrentTime'

export const controlReceived = (controlObject) => ({ type: 'CONTROL_RECEIVED',controlObject})
//In some situations we update state with "proposed control" when request is sent rather wait for API response to improve UI responsivness
export const proposeControl = (controlObject) => ({ type: 'PROPOSE_CONTROL',controlObject})

export function fetchControl() {
	return (dispatch) => api.fetch('/control')
		.then((response) => {
			dispatch({ type: 'FETCH_CONTROL_SUCCESS', response})
		})
		.catch((error) => {
			return error
		})
}
   
export function updateControl(data) {
	const requestData={...data}
	return (dispatch) =>{ 
		dispatch({ type: 'UPDATE_CONTROL_REQUEST', requestData })
		api.put('/control/',requestData)
			.then((response) => {
				dispatch({ type: 'UPDATE_CONTROL_SUCCESS', response })
				//after update control check if need to update section control
				let sectionControl
				if(response.section){
					const nowDate = new Date()
					sectionControl={
						resumableSetPoll:null,
						controlled:nowDate
					}
					if(response.setPoll){
						sectionControl={
							resumableSetPoll:response.setPoll,
							controlled:nowDate
						}
					}
					return dispatch(updateSectionControl(response.section,sectionControl))
				}
			})
			.catch((error) => {
				return error
			})
	}
}


export function updateControlSettings(data) { //used for showGraph/showCorrect
	const requestData={...data,controlled:calibratedCurrentTime()}
	return (dispatch) =>{ 
  		dispatch({ type: 'UPDATE_CONTROL_REQUEST', requestData })
  		return api.put('/control/',requestData)
	    .then((response) => {
		    dispatch({ type: 'UPDATE_CONTROL_SUCCESS', response })
		    return response
	    })
	    .catch((error) => {
			return error
		})
	}
}




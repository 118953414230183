import React from 'react'
import find from 'lodash/find'
import { Image } from 'cloudinary-react'
import Gallery from '../../imageGallery/Gallery'
import Button from '../../uiKit/Button'
import subjects from '../../../staticData/subjects'
import BingAttributionWithPrivacyLink from '../../uiKit/attribution/BingAttributionWithPrivacyLink'
import BingAttribution from '../../uiKit/attribution/BingAttribution'

const MAX_FILE_SIZE = 20000000

class PublishingFlowImagePage extends React.Component {
  constructor(props) {
    super()
    this.searchForImages = this.searchForImages.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.onSelectImage = this.onSelectImage.bind(this)
    this.handleLoadMore = this.handleLoadMore.bind(this)
    this.selectSuggestedImage = this.selectSuggestedImage.bind(this)
    this.uploadRepoImageToCloudinary = this.uploadRepoImageToCloudinary.bind(this)
    this.onChangeSearchTerm = this.onChangeSearchTerm.bind(this)

    const { repoItems } = props
    let imageSuggestions = []
    if (repoItems.length !== 0) {
      imageSuggestions = this.getImageSuggestions(repoItems)
    }

    this.state = ({
      images: [],
      searchTerm: '',
      isSearching: false,
      submitLoading: false,
      noResults: false,
      count: 50,
      offset: 0,
      searchResultCount: null,
      imageSuggestions,
    })
  }

  componentDidMount() {
    if (window.analytics) {
      window.analytics.page('Publishing Flow', {
        stage: 'image',
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.repoItems !== nextProps.repoItems) {
      const imageSuggestions = this.getImageSuggestions(nextProps.repoItems)
      this.setState({ imageSuggestions })
    }
  }

  getImageSuggestions(repoItems) {
    const imageSuggestions = []
    repoItems.map((item) => {
      if (item.questions) {
        item.questions.map((question) => {
          if (question.image) {
            imageSuggestions.push(question.image)
          }
          return null
        })
      } else if (item.image) {
        imageSuggestions.push(item.image)
      }
      return null
    })
    return imageSuggestions.slice(0, 50)
  }

  handleLoadMore() {
    const { searchTerm, count, offset } = this.state
    this.setState({ isSearching: true })
    this.props.imageSearch(searchTerm, count, offset).then((response) => {
      const { images } = this.state
      response.value.forEach((value) => {
        images.push(this.parseImageObject(value))
      })
      this.setState({
        isSearching: false, images, offset: response.nextOffset, searchResultCount: response.totalEstimatedMatches,
      })
    })
  }

  parseImageObject(value) {
    return {
      src: value.contentUrl,
      thumbnail: value.thumbnailUrl,
      thumbnailWidth: value.thumbnail.width,
      thumbnailHeight: value.thumbnail.height,
      caption: '',
      accentColor: value.accentColor,
    }
  }

  handleKeyUp(e) {
    if (e.keyCode === 13) { // enter key
      if (this.state.searchTerm) {
        this.searchForImages()
      }
    }
  }

  uploadRepoImageToCloudinary(imgSrc, source) {
    this.props.beginImageUpload()

    if (window.analytics) {
      window.analytics.track('Publishing flow add image', {
        source, // upload, search or suggestion from pack
      })
    }

    this.props.uploadRepoImageToCloudinary(imgSrc, this.props.repo.id).then((response) => {
      const { version } = response
      this.props.setRepoImage(`v${version}`)
      this.props.goToNextPage()
    })
      .catch(() => {
        this.props.endImageUpload()
        if (window.analytics) {
          window.analytics.track('Publishing flow upload to cloudinary failed', {
            imgSrc,
            source,
          })
        }
      })
  }

  onSelectImage(index) {
    const img = this.state.images[index]
    this.uploadRepoImageToCloudinary(img.src, 'search')
  }

  selectSuggestedImage(publicId) {
    const imageSrc = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload/${publicId}.jpg`
    this.uploadRepoImageToCloudinary(imageSrc, 'suggestion from pack')
  }

  handleFileUpload(e) {
    this.props.hideFileTooBigError()
    const fileList = e.target.files
    const file = fileList[0]
    if (file.size > MAX_FILE_SIZE) {
      this.props.showFileTooBigError()
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (event) => {
        this.uploadRepoImageToCloudinary(event.target.result, 'upload from computer')
      }
    }
  }

  searchForImages() {
    const { count } = this.state
    this.props.hideFileTooBigError()
    const { searchTerm } = this.state
    this.setState({
      isSearching: true, noResults: false, images: [], submitLoading: true,
    })

    this.props.imageSearch(searchTerm, count, 0).then((response) => {
      this.setState({ isSearching: false })
      if (response.value.length === 0) {
        this.setState({ noResults: true })
      }
      const images = []
      response.value.forEach((value) => {
        images.push(this.parseImageObject(value))
      })
      this.setState({
        images, offset: response.nextOffset, submitLoading: false, searchResultCount: response.totalEstimatedMatches,
      })
    })
      .catch(() => {
        this.setState({ submitLoading: false })
      })
  }

  onChangeSearchTerm(e) {
    this.setState({ searchTerm: e.target.value, noResults: false })
    if (!e.target.value) {
      this.setState({ images: [] })
    }
  }

  render() {
    const { images, isSearching, noResults } = this.state
    const { uploadingImage, repo } = this.props

    let subjectSpecificImageSearchPlaceholder = 'rainforest, Shakespeare, theatre'

    if (repo.subject && repo.subject.id) {
      const subject = find(subjects, { subjectId: repo.subject.id })
      if (subject && subject.imageSearchPlaceholder) {
        subjectSpecificImageSearchPlaceholder = subject.imageSearchPlaceholder
      }
    }

    return (
      <div className={`publishingFlowModalPage publishingFlowModalPage--image ${uploadingImage ? 'publishingFlowModalPage--image--uploading' : ''}`}>
        <div className='publishingFlowModalPage-header'>
          Add Pack Image
        </div>
        <div className='publishingFlowModalPage-subheader publishingFlowModalPage--image-subheader'>
          Search internet for an image or&nbsp;
          <div className='publishingFlowModalPage-subheader-uploadBtnContainer'>
            <input
              id='file'
              type='file'
              value=''
              name='uploadImage'
              accept='.png,.jpg,.jpeg,.tif,.tiff'
              className='publishingFlowModalPage-subheader-uploadBtnHidden'
              onChange={(e) => { this.handleFileUpload(e) }}
            />
            <label htmlFor='file' className='publishingFlowModalPage--image-subheader-uploadBtn'>upload an image from your computer</label>
          </div>
          .
        </div>

        <div className='publishingFlowModalPage--image-imageInterfaceContainer'>
          <div className={`publishingFlowModalPage--image-imageSearchBox${this.state.searchTerm ? ' publishingFlowModalPage--image-imageSearchBox--hasSearchTerm' : ' publishingFlowModalPage--image-imageSearchBox--noSearchTerm'}`}>
            <input
              className='publishingFlowModalPage--image-imageSearchBox-input'
              required
              type='text'
              placeholder={`Type what image you are looking for (e.g ${subjectSpecificImageSearchPlaceholder})`}
              value={this.state.searchTerm}
              onChange={this.onChangeSearchTerm}
              onKeyUp={this.handleKeyUp}
              autoFocus
              disabled={uploadingImage}
            />

            {this.state.images.length === 0 && !this.state.noResults && (
            <div className={`publishingFlowModalPage--image-imageSearchBox-hint ${this.state.searchTerm ? 'publishingFlowModalPage--image-imageSearchBox-hint--hasSearchTerm' : ''}`}>
              {!this.state.submitLoading && (
              <span>
                Press
                <b>Enter</b>
                {' '}
                to Search
              </span>
              )}
              {this.state.submitLoading &&
              <span>Searching...</span>}
            </div>
            )}

            {this.state.searchTerm && (
            <button
              className={`publishingFlowModalPage--image-imageSearchBox-searchBtn ${this.state.submitLoading ? 'publishingFlowModalPage--image-imageSearchBox-searchBtn--submitLoading' : ''}`}
              onClick={this.searchForImages}
              disabled={this.state.submitLoading || uploadingImage}
            >
              {!this.state.submitLoading &&
              <span>Search</span>}
              {this.state.submitLoading && (
              <div className='publishingFlowModalPage--image-imageSearchBox-searchBtn-loadingSpinnerContainer'>
                <div className='publishingFlowModalPage--image-imageSearchBox-searchBtn-loadingSpinner' />
              </div>
              )}

            </button>
            )}

          </div>
          {(images.length > 0 || (this.state.noResults && this.state.searchTerm)) && (
          <div className='publishingFlowModalPage--image-imageGrid'>
            {images.length > 0 && (
            <Gallery
              images={this.state.images}
              onClickThumbnail={this.onSelectImage}
            />
            )}

            {images.length > 0 && isSearching === false && images.length < parseInt(this.state.searchResultCount, 10) && (
            <div>
              <div className='publishingFlowModalPage--image-imageGrid-loadMoreBtnContainer'>
                <Button label='Show more results' size='large' onClickFunction={() => { this.handleLoadMore() }} />
              </div>
            </div>
            )}

            {this.state.noResults && this.state.searchTerm && (
            <div className='publishingFlowModalPage--image-imageGrid-noResults'>
              No image results for '
              {this.state.searchTerm}
              '
            </div>
            )}

          </div>
          )}

          {images.length === 0 && !this.state.noResults && !this.state.searchTerm && this.state.imageSuggestions.length > 0 && (
          <div className='publishingFlowModalPage--image-fromPackImageGrid'>

            <div className='publishingFlowModalPage--image-fromPackImageGrid-header '>
              Or select an image from one of your questions in this pack
            </div>

            <div className='publishingFlowModalPage--image-fromPackImageGrid-grid'>

              {this.state.imageSuggestions.map((publicId) => (
                <div key={publicId} onClick={() => { this.selectSuggestedImage(publicId) }}>
                  <Image
                    cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
                    publicId={publicId}
                    responsive
                    dpr='auto'
                    width='900'
                    height='900'
                    crop='limit'
                    role='presentation'
                    id='formImage'
                    fetchFormat='auto'
                    quality='auto'
                  />

                </div>
              ))}
            </div>
          </div>
          )}

        </div>

        {images.length === 0 && !noResults && (
          <div className='publishingFlowModalPage--image-bingAttributionContainer'>
            <BingAttributionWithPrivacyLink />
          </div>
        )}

        {images.length > 0 && (
          <div className='publishingFlowModalPage--image-bingAttributionContainer--result'>
            <BingAttribution />
          </div>
        )}
      </div>

    )
  }
}

export default PublishingFlowImagePage

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import DocumentTitle from 'react-document-title' 
import { connect } from 'react-redux'
import {deleteSetPoll,updateSetPoll} from '../../actions/polls'
import {showConfirmModal,hideModal} from '../../actions/modals'
import {showNotification} from '../../actions/notifications'
import SetPollDetail from '../../components/pollDetail/SetPollDetail'
import {calculateStudentTotals} from '../../utils/calculateStudentTotal'
import _ from 'lodash'
import {buildQuestionsAndPollsList} from '../../utils/buildQuestionsAndPollsList'
import {showExpandedQuestionSlideModal} from '../../actions/modals'
 
class SetPollDetailContainer extends Component {

	constructor() {
		super()
		this.deleteSetPoll=this.deleteSetPoll.bind(this)
		this.archiveSetPoll=this.archiveSetPoll.bind(this)
		this.confirmDeleteSetPoll=this.confirmDeleteSetPoll.bind(this)   
	} 
 	
 	componentDidMount(){
 		if(window.analytics){
			let testGroup=null
			const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
			if(setLimitTestGroup){
				testGroup=setLimitTestGroup
			}
			let setQuestionCount
			let setPollPollCount
			if(this.props.poll){
				setQuestionCount=this.props.poll.snapshot.questions.length
				setPollPollCount=Object.keys(this.props.poll.polls).length
			}

			window.analytics.page('Report',{
				pollType:'Set Poll',
				'test_group_set_limit':testGroup,
				setQuestionCount:setQuestionCount,
				setPollPollCount:setPollPollCount
			})
		}
	}

	deleteSetPoll(poll){
		this.props.deleteSetPoll(poll.id).then(() => {
			this.props.hideModal()
			this.props.history.goBack()
			const notificationMessage=`${poll.snapshot.name}`
			this.props.showNotification(notificationMessage,'Deleted','destroy')
		})
	}

	confirmDeleteSetPoll(poll){
		this.props.showConfirmModal(poll,this.deleteSetPoll,'delete')
	}

	archiveSetPoll(poll){
		const nowDate=new Date()
		const requestData={
			...poll,
			clientModified:nowDate,
			archived:!poll.archived
		}
		this.props.updateSetPoll(poll.id,requestData).then((response) => {
			if(response==='failed'){
				const notificationMessage=''
				const actionWord=poll.archived? 'unarchive':'archive'
				this.props.showNotification(notificationMessage,`Failed to ${actionWord} report`,'destroy')
			}else{	
				this.props.history.goBack()
				const notificationMessage=`${poll.snapshot.name}`
				const actionWord=poll.archived? 'Unarchived':'Archived'
				this.props.showNotification(notificationMessage,actionWord,'default')
			}
		})
	}

	isPollResumable(poll){
		const totalPolls=Object.keys(poll.polls).length
		let pollsWithResponses=0
		Object.keys(poll.polls).map(function(key,index){
			if(poll.polls[key].hasResponses){
				pollsWithResponses+=1
			}
			return null
		})
		const isResumable= pollsWithResponses===totalPolls? false:true
		return isResumable
	}

	render() {
  	const {section,poll,set}=this.props 
  	const parentSectionId=this.props.match.params.sectionId

  	let studentsWithTotals
  	if(section && poll){
  		studentsWithTotals=calculateStudentTotals(section.students,poll)
  	}
  	let documentTitle='Plickers'
  	let questionsAndPollsList
  	if(poll && poll.snapshot){
  		documentTitle=`${poll.snapshot.name} Report - Plickers`
  		questionsAndPollsList=buildQuestionsAndPollsList(poll)
  	}
		return( 
			<DocumentTitle title={documentTitle}>
				<div className='page page--wideLayout setReport'>
					{questionsAndPollsList && section &&
						<SetPollDetail
							parentSectionId={parentSectionId}
							questionsAndPollsList={questionsAndPollsList}
							poll={poll}
							showExpandedQuestionSlideModal={this.props.showExpandedQuestionSlideModal}
							section={section}
							set={set}
							deletePoll={this.confirmDeleteSetPoll}   
							archivePoll={this.archiveSetPoll}
							libraryItem={this.props.set}
							students={studentsWithTotals}
						/>
					}
				</div> 
			</DocumentTitle>
		)
	}
}

function mapStateToProps(state,ownProps) {
	const currentPoll= _.find(state.historySetPolls, {'id':ownProps.match.params.pollId})
	let currentSection=null
	let currentSet
	if(currentPoll){
		currentSection=_.find(state.sections, {'id':currentPoll.section})
		currentSet=_.find(state.sets, {'id':currentPoll.setObject})
	}
	return {
		poll:currentPoll,
		set:currentSet,
		section:currentSection,
	}
}

export default withRouter(connect(
	mapStateToProps,
	{ deleteSetPoll,
		updateSetPoll,
		showConfirmModal,
		hideModal,
		showNotification,
		showExpandedQuestionSlideModal
	}
)(SetPollDetailContainer))
//Date rangel label shown in scoresheet topbar

// Granularity = Days
// Today
// Yesterday
// Then move to period-style formatting (eg Monday 22 June)

// Granularity = Weeks
// This Week
// Then move to period formatting

// Granularity = Months
// Always full month = eg June, Septembber
// If not this year, add year eg June 2017

// Granularity = Period Formatting
// {Full day of week} {date of month} {full month} {year if not this year}
// Monday 18 June - Sunday 25 June
// If previous year, then add year
// eg Monday 31 December 2017 –  Friday 29 January 



import moment from 'moment'

export function getScoreSheetDateRangeLabel(granularity,dateRange) {
	const year = moment().startOf('isoYear')
	let isSameYear=false
	if(moment(dateRange.startDate).isSame(year, 'y')){
		isSameYear=true
	}

	let label

	if(granularity==='day'){
		const today = moment().startOf('day')
		const yest = moment().startOf('day').subtract(1,'day')
		if(moment(dateRange.startDate).isSame(today, 'd')){
			label='Today'
		}
		else if(moment(dateRange.startDate).isSame(yest, 'd')){
			label='Yesterday'
		}else{
			if(isSameYear){
				label=moment(dateRange.startDate).format('ddd DD MMMM')	
			}else{
				label=moment(dateRange.startDate).format( 'ddd DD MMMM YYYY')
			}		

		}
	}else if(granularity==='week'){
		const week = moment().startOf('isoWeek')
		if(moment(dateRange.startDate).isSame(week, 'isoWeek')){
			label='This Week'
		}
		else{
			if(isSameYear){
				label=`${moment(dateRange.startDate).format('ddd DD MMMM')} - ${moment(dateRange.endDate).format('ddd DD MMMM')}`
			}else{
				label=`${moment(dateRange.startDate).format('ddd DD MMMM YYYY')} - ${moment(dateRange.endDate).format('ddd DD MMMM YYYY')}`
			}		

		}
	}else if(granularity==='14d' ||granularity==='90d' ){ //used to have 14d granularity.  Not currently active
		const today = moment().startOf('day')
		const endDateIsToday=moment(dateRange.endDate).isSame(today, 'd')

		if(isSameYear){
			if(endDateIsToday){
				label=`${moment(dateRange.startDate).format('ddd DD MMMM')} - Today`
			}else{
				label=`${moment(dateRange.startDate).format('ddd DD MMMM')} - ${moment(dateRange.endDate).format('ddd DD MMMM')}`
			}
		}else{
			if(endDateIsToday){
				label=`${moment(dateRange.startDate).format('ddd DD MMMM YYYY')} - Today`
			}else{
				label=`${moment(dateRange.startDate).format('ddd DD MMMM YYYY')} - ${moment(dateRange.endDate).format('ddd DD MMMM YYYY')}`
			}
		}		
	}else if(granularity==='month'){

		if(moment(dateRange.startDate).isSame(year, 'y')){
			label=moment(dateRange.startDate).format('MMMM')	
		}else{
			label=moment(dateRange.startDate).format('MMMM YYYY')
		}
	}else if(granularity==='custom'){
		if(isSameYear){
			if(moment(dateRange.startDate).isSame(dateRange.endDate, 'd')){  //custom range of single day
				label=`${moment(dateRange.startDate).format('ddd DD MMMM')}`

			} else label=`${moment(dateRange.startDate).format('ddd DD MMMM')} - ${moment(dateRange.endDate).format('ddd DD MMMM')}`
			
			}else{
				if(moment(dateRange.startDate).isSame(dateRange.endDate, 'd')){  
					label=`${moment(dateRange.startDate).format('ddd DD MMMM YYYY')}`
				}else label=`${moment(dateRange.startDate).format('ddd DD MMMM YYYY')} - ${moment(dateRange.endDate).format('ddd DD MMMM YYYY')}`
			}	
	}

	return label
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditUserUsernameForm from '../../../components/forms/account/EditUserUsernameForm'
import {updateUser,testUsername} from '../../../actions/user'
import {fetchRepos} from '../../../actions/repos'
import {showNotification} from '../../../actions/notifications'
import { withRouter} from 'react-router-dom'
import {fetchUserByUsername} from '../../../actions/user'
import {refetchUserByUsername} from '../../../actions/user'


 
const isAccountPage = (location) => { 	
	if(
		(location.pathname.indexOf('/account')>-1) 
	) return true
	else return false

}
 
class UpdateUsernameContainer extends Component {
  
	constructor(props){
		super(props)
		this.updateUsername=this.updateUsername.bind(this)

		this.state = {
			submitLoading:false,
			errorMessage: null
		}
		
	}

	componentDidMount(){
		if(window.analytics){
			let openedFrom='authorPage'
			if(window.location.pathname.indexOf('/account')>-1){
				openedFrom='accountPage'
			}

			window.analytics.page('Edit Username Modal',{
				openedFrom:openedFrom,
			})
		}
	}



	updateUsername(formData) {
		this.setState({submitLoading:true,errorMessage:null})
		const oldUsername=this.props.user.username

		this.props.testUsername(formData.username).then((response)=>{
			this.setState({submitLoading:false,errorMessage:'Unfortunately that username is already taken. Please try a different one.'})
		})
			.catch((error) => {
			
				const nowDate = new Date()
				const requestData = { 
					...this.props.user,
					'username': formData.username,
					'clientModified': nowDate,
				}

				this.props.updateUser(requestData,this.props.user.id).then((response)=>{
			 		const notificationMessage=`to ${formData.username}`
			 		this.props.showNotification(notificationMessage,'Updated username','default')
			 		if(window.analytics){
				 		window.analytics.page('Updated Username',{
							oldUsername:oldUsername,
							newUsername:formData.username
						})
				 	}
			 		//refesh author repos (so updated author info )
			 		this.props.fetchRepos()
			 		if(isAccountPage(this.props.history.location)){
			 			this.props.hideModal()
			 		}else{
			 			this.props.refetchUserByUsername(response.username).then(()=>{
			 				this.props.history.push(`${response.username}`)
			 				this.props.hideModal()
			 			})
			 			


			 		}


					
				})
					.catch((error) => {
						this.setState({submitLoading:false,errorMessage:'Unfortunately that username is already taken. Please try a different one.'})
					})
			})
		
	} 

	



	render() {
		const {user}=this.props
		const {errorMessage,submitLoading}=this.state

		return (

			<div className='updateUserNameModal'>

				<div className='updateUserNameModal-header'>
					Edit Creator Username
				</div>
				
				<div className='updateUserNameModal-sub'>			
					Your username is displayed with your published packs and is used for your creator profile URL.
				</div>
				
		
				<EditUserUsernameForm
					username={user.username}
					onSubmit={this.updateUsername}
					hideModal={this.props.hideModal}
					submitLoading={submitLoading}
				/>

				{/* for unavaible or blacklisted usernames after submit */}				
				<div className={'updateUserNameModal-errorSpace ' + (errorMessage ? 'updateUserNameModal-errorSpace--hasError' : '')} >
					{errorMessage}
				</div>
				
				<div className='updateUserNameModal-smallPrint'>
					If you update your username, any public pack URLs you shared previously will still work. However, your creator profile URL will change and your old creator profile URL will no longer work.
				</div>

			</div>
		)
	}
}


export default withRouter(connect(
	(state) => ({
		user:state.user,

	}),
	{ updateUser,showNotification,testUsername,fetchRepos,fetchUserByUsername,refetchUserByUsername}
)(UpdateUsernameContainer))



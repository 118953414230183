import React from 'react'
import ReactGA from 'react-ga4'
import Icon from '../misc/Icons'
import UpgradeProFeaturesSymbolRow from './UpgradeProFeaturesSymbolRow'

class UpgradeSuccessPage extends React.Component {
  componentDidMount() {
    if (window.analytics) {
      let testGroup = null
      const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
      if (setLimitTestGroup) {
        testGroup = setLimitTestGroup
      }

      window.analytics.page('Upgrade', {
        stage: 'Success',
        // eslint-disable-next-line camelcase
        test_group_set_limit: testGroup,
      })
    }
    if (typeof window.gtag === 'function') {
      ReactGA.set({ page: '/upgrade-success-page' })
      ReactGA.send({ hitType: 'pageview' })
    }
  }

  render() {
    const { trialUsed } = this.props

    const trialPurchase = !trialUsed

    return (
      <div className='upgrade upgrade--success marketing'>
        <div className='upgrade-closeBtn' onClick={this.props.hideModal}>
          <Icon name='circle-with-close' />
        </div>

        <div className='upgrade--success-header'>
          <span role='img' aria-label='Celebration'>🎉</span>
          {' '}
&nbsp; Welcome to
          {' '}
          <span className='upgrade--success-header--plickersPro-plickers'>plickers</span>
          <span className='upgrade--success-header--plickersPro-pro'>pro</span>
          {' '}
&nbsp; 🎉
        </div>

        {trialPurchase && (
        <div className='upgrade--success-sub'>
          You are now on a 14-day free trial. Here's everything that's included:
        </div>
        )}

        {!trialPurchase && (
        <div className='upgrade--success-sub'>
          Here's everything that's included:
        </div>
        )}

        <UpgradeProFeaturesSymbolRow />

        <div className='upgrade-success-btnContainer'>
          <button className='upgrade--success-doneBtn' onClick={this.props.hideModal}>
            Go to your upgraded Plickers
          </button>
        </div>

        {trialPurchase && (
        <div className='upgrade--success-cancelSub'>
          You can cancel your trial at any time by going to "Account", then "Cancel Trial".
        </div>
        )}

      </div>

    )
  }
}

export default UpgradeSuccessPage

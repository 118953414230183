import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import qs from 'qs'
import RichStaticSlide from '../components/richStaticSlide/RichStaticSlide'

const DEFAULT_SLIDE_WIDTH = 1280

// takes string from input and turns it into a slide
// console logs "slide generation finished" when slide has rendered and all media has loaded
// if slideGenerationFinished function is passed to slide then slide creates promises for all media items that are
// resolved once media has loaded (e.g. image.onLoad)
// once all media load promises (if any) are resolved slideGenerationFinished is called

const testQuestionObject = {
  body: 'This has animated gif!!!',
  bodySource: [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'This has animated gif!!!',
        },
      ],
    },
  ],
  image: '',
  template: 'standard',
  xLayout: null,
  questionId: '6af1b4ff',
  choices: [
    {
      body: '',
      bodySource: [],
      correct: true,
      bodyHtml: '',
      media: {
        type: 'video',
        youtubeId: 'opyPkipNNhE',
        title: 'Best Monkey Moments | BBC Earth',
        channelTitle: 'BBC Earth',
        thumbnail: {
          h: 720,
          w: 1280,
          src: 'https://i.ytimg.com/vi/opyPkipNNhE/maxresdefault.jpg',
        },
        fullDuration: 1017,
        isClipped: false,
        start: 0,
        end: 1017,
      },
    },
    {
      body: '',
      bodySource: [],
      correct: false,
      bodyHtml: '',
      media: {
        type: 'sound',
        fileId: '150ac6fe1e47f4ee8709de3a4244ec07',
        deliveryUrl: 'https://res.cloudinary.com/plickers-dev/video/upload/150ac6fe1e47f4ee8709de3a4244ec07',
        title: 'Bernardine - La côte d’Opale et son parc régional [A2]',
        fullDuration: 88.42449,
        isClipped: false,
        start: 0,
        end: 88.42449,
      },
    },
    {
      body: '',
      bodySource: [],
      correct: false,
      bodyHtml: '',
      media: {
        type: 'image',
        fileId: '7ecbf11714fe223ed2ad3ca0001ab377',
        title: 'Proboscis-monkey-male – Chris Hill Wildlife Photography',
        attribution: 'https://chrishillphotoblog.com/wp-content/uploads/2017/01/Proboscis-monkey-male.jpg',
        deliveryUrl: 'https://res.cloudinary.com/plickers-dev/image/upload/c_limit,dpr_2,h_900,w_900/f_auto,q_auto/7ecbf11714fe223ed2ad3ca0001ab377',
        originalDimensions: {
          w: 1200,
          h: 800,
        },
        deliveryDimensions: {
          w: 1200,
          h: 800,
        },
        rotate: {
          angle: 0,
          flipH: false,
          flipV: false,
        },
        markup: {
          covers: [],
          markers: [],
        },
        colorAdjust: {
          brightness: 0,
          saturation: 0,
          contrast: 0,
        },
        imageFill: true,
        crop: null,
      },
    },
    {
      body: '',
      bodySource: [],
      correct: false,
      bodyHtml: '',
      media: {
        type: 'animatedGif',
        dimensions: {
          w: 600,
          h: 450,
        },
        fileId: 'LPgFwCQg4HQBvPihcn',
        title: '50Th Anniversary Hello GIF by Sesame Street',
        attribution: 'https://giphy.com/gifs/sesamestreet-sesame-street-50th-anniversary-LPgFwCQg4HQBvPihcn',
        host: 'giphy',
        stillUrl: 'https://media4.giphy.com/media/LPgFwCQg4HQBvPihcn/giphy_s.gif?cid=deaac6a9mj5oqyywppz0vb12ni4b1g622tvr33s3yjty1rm6&rid=giphy_s.gif&ct=g',
        webpUrl: 'https://media4.giphy.com/media/LPgFwCQg4HQBvPihcn/giphy.webp?cid=deaac6a9mj5oqyywppz0vb12ni4b1g622tvr33s3yjty1rm6&rid=giphy.webp&ct=g',
        gifUrl: 'https://media4.giphy.com/media/LPgFwCQg4HQBvPihcn/giphy.gif?cid=deaac6a9mj5oqyywppz0vb12ni4b1g622tvr33s3yjty1rm6&rid=giphy.gif&ct=g',
        giphySlug: 'sesamestreet-sesame-street-50th-anniversary-LPgFwCQg4HQBvPihcn',
        giphyUser: {
          username: 'sesamestreet',
          profileUrl: 'https://giphy.com/sesamestreet/',
          avatarUrl: 'https://media2.giphy.com/avatars/sesamestreet/gHHZmwAIT6MH.png',
        },
        imageFill: false,
      },
    },
  ],
  media: {
    type: 'animatedGif',
    dimensions: {
      w: 480,
      h: 480,
    },
    fileId: 'tmQrpA8zpG4a16SSxm',
    title: 'Pbs Nature Monday GIF by Nature on PBS',
    attribution: 'https://giphy.com/gifs/pbsnature-tmQrpA8zpG4a16SSxm',
    host: 'giphy',
    stillUrl: 'https://media4.giphy.com/media/tmQrpA8zpG4a16SSxm/giphy_s.gif?cid=deaac6a95nw20vp1pbyvpztv263t24ix3fb7yu3cdpprij3z&rid=giphy_s.gif&ct=g',
    webpUrl: 'https://media4.giphy.com/media/tmQrpA8zpG4a16SSxm/giphy.webp?cid=deaac6a95nw20vp1pbyvpztv263t24ix3fb7yu3cdpprij3z&rid=giphy.webp&ct=g',
    gifUrl: 'https://media4.giphy.com/media/tmQrpA8zpG4a16SSxm/giphy.gif?cid=deaac6a95nw20vp1pbyvpztv263t24ix3fb7yu3cdpprij3z&rid=giphy.gif&ct=g',
    giphySlug: 'pbsnature-tmQrpA8zpG4a16SSxm',
    giphyUser: {
      username: 'pbsnature',
      profileUrl: 'https://giphy.com/pbsnature/',
      avatarUrl: 'https://media1.giphy.com/avatars/pbsnature/y4o7pzONphjg.gif',
    },
    imageFill: false,
  },
  bodyHtml: '<p>This has animated gif!!!</p>',
  measurements: {
    bodyFS: 64,
    choiceFS: 42,
  },
  layout: 'bodyLeftMediaRightChoicesMedia',
}

class SlideServiceContainer extends Component {
  constructor(props) {
    super(props)
    this.generateSlide = this.generateSlide.bind(this)
    this.reset = this.reset.bind(this)
    this.state = {
      showSlide: false,
      questionString: JSON.stringify(testQuestionObject),
      questionObject: null,
      slideWidth: DEFAULT_SLIDE_WIDTH,
    }
    // To ensure any segment analytics calls are associated with the slide service user
    // and to prevent segment calls to be anonymous, perform an identify call with
    // the slide service user id when caller provides correct user ID
    if (window.analytics) {
      const session = localStorage.getItem('ls.session')
      const { location } = props
      const providedUserId = qs.parse(location.search, { ignoreQueryPrefix: true }).userId
      if (!session && providedUserId === process.env.REACT_APP_SLIDE_SERVICE_USER_ID) {
        window.analytics.identify(process.env.REACT_APP_SLIDE_SERVICE_USER_ID, {
          email: 'slideService@plickers.com',
          id: process.env.REACT_APP_SLIDE_SERVICE_USER_ID,
        })
      }
    }
  }

  generateSlide() {
    const { questionString } = this.state
    const questionObject = JSON.parse(questionString)
    this.setState({ showSlide: true, questionObject })
  }

  reset() {
    this.setState({
      showSlide: false,
      questionString: '',
      questionObject: null,
      slideWidth: DEFAULT_SLIDE_WIDTH,
    })
  }

  slideGenerationFinished() {
    console.log('slide generation finished')
  }

  render() {
    const hideSlide = false
    const {
      questionString,
      showSlide,
      questionObject,
      slideWidth,
    } = this.state
    return (
      <div className='slideServicesPageContainer'>
        <div className='slideGenerator-form'>
          Question:
          {' '}
          <input id='question' value={questionString} onChange={(e) => { this.setState({ questionString: e.target.value }) }} />
          <br />
          <br />
          <button id='generateSlideBtn' onClick={this.generateSlide}>generate slide</button>
        </div>

        <div className='slideGenerator-resetBtnContainer'>
          <button id='resetBtn' onClick={this.reset}>reset</button>
        </div>

        {showSlide && !hideSlide && (
        <div className='slideServices-slideContainer'>
          <RichStaticSlide
            slideGenerationFinished={this.slideGenerationFinished}
            question={questionObject}
            slideWidth={slideWidth}
            mediaIsPlayable={false}
            mediaIsZoomable={false}
          />
        </div>
        )}
      </div>
    )
  }
}

export default withRouter(connect(
  () => ({}),
  { },
)(SlideServiceContainer))

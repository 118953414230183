import React from 'react'
import { withRouter,NavLink } from 'react-router-dom'
import Icon from '../misc/Icons'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import SideNavSectionItem from './DnDSideNavSection'
import { DropTarget } from 'react-dnd'
import { findDOMNode } from 'react-dom'
import ItemTypes from '../DnDKit/ItemTypes'
import DnDSideNavRepo from './DnDSideNavRepo'
import DnDSideNavLibrary from './DnDSideNavLibrary'

//SideNav is a drop target so we can scroll it when dragging

var scrollBar

function collect(connect, monitor,DropTargetMonitor) {
	 const item = monitor.getItem()
	 let isDragging=false
	 let isDraggingFolder=false
	 if(item && item.cellItem && !item.cellItem.ancestors){ //is not a folder
		isDragging=true
	 }
	 if(item && item.cellItem && item.cellItem.ancestors){
		isDraggingFolder=true
	 }	 
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
		isDragging:isDragging,
		isDraggingFolder:isDraggingFolder
		}
}

const sideNavTarget = {
	hover(props, monitor,component) {
		const cancelAnimationFrame = window.cancelAnimationFrame || (timeout => clearTimeout(timeout))
		const requestAnimationFrame = window.requestAnimationFrame || (func => setTimeout(func, 1000 / 60))
		// If already scrolling, stop the previous scroll loop
		if (this.lastScroll) {
			cancelAnimationFrame(this.lastScroll)
			this.lastScroll = null
			clearTimeout(this.removeTimeout)
		}

		const bufferHeight = 50
		const bufferHeightTop= 100 + bufferHeight
		const dragYOffset = monitor.getClientOffset().y
		const { top: containerTop, bottom: containerBottom } = findDOMNode(component).getBoundingClientRect()
		const windowHeight = window.innerHeight
		let scrollDirection = 0
		let scrollMagnitude = 0
		const fromTop = dragYOffset - bufferHeightTop - Math.max(containerTop, 0)
		if (fromTop <= 0) {
			// Move up
			scrollDirection = -1
			scrollMagnitude = Math.ceil(Math.sqrt(-1 * fromTop))
		} else {
			const fromBottom = dragYOffset + bufferHeight - Math.min(containerBottom, windowHeight)
			if (fromBottom >= 0) {
			// Move down
				scrollDirection = 1
				scrollMagnitude = Math.ceil(Math.sqrt(fromBottom))
			} else {
			// If neither near the top nor bottom, skip calling the scrolling function
				return
			}
		}

		// Indefinitely scrolls the window down at a constant rate
		const doScroll = () => {
			scrollBar._container.scrollBy(0, scrollDirection * scrollMagnitude)
				this.lastScroll = requestAnimationFrame(doScroll)
		}

		// Stop the scroll loop after a period of inactivity
		this.removeTimeout = setTimeout(() => {
			cancelAnimationFrame(this.lastScroll)
			this.lastScroll = null
		}, 100)

		// Start the scroll loop
		this.lastScroll = requestAnimationFrame(doScroll)
		},

	 drop() {	 
		if (this.lastScroll) {
			const cancelAnimationFrame = window.cancelAnimationFrame || (timeout => clearTimeout(timeout))
			cancelAnimationFrame(this.lastScroll)
			this.lastScroll = null
			clearTimeout(this.removeTimeout)
		}
	}
}

const SideNavItem = ({ name, iconName, url }) => {
	const nameClass = name.toLowerCase()
	return(             
		<NavLink
			to={url}
			className='sideNav-itemContainer'
		>
			<div className={'sideNav-item is--' + (nameClass)}>
				<div className='sideNav-item-icon'>
					<Icon name={iconName} />
				</div>
				<div className='sideNav-item-label'>
					{name}
				</div>
			</div>        
		</NavLink>
	) 
}


class SideNav extends React.Component{

	constructor(){
		super()
		this.addNewSection=this.addNewSection.bind(this)  
	}

	addNewSection(){
		this.props.showNewSectionModal()
	} 

	handleNewSet(){
		window.open('/seteditor/newSet','_blank')
	}

	handleNewQuestion(){
		window.open('/editor/newquestion','_blank')
	}
	
	render(){
		const {connectDropTarget,isDragging,isDraggingFolder}=this.props

		const browserLanguage = window.navigator.userLanguage || window.navigator.language
		let browserLanguageIsEnglish=false
		if(browserLanguage){
			const shortenedBrowserLanguage=browserLanguage.substring(0,2)
			if(shortenedBrowserLanguage==='en'){
				browserLanguageIsEnglish=true
			}
		}
		
		const showDiscoverLink = browserLanguageIsEnglish //only show discover link to english language browsers

		let discoverLink='/discover'
		const lastViewedDiscover = JSON.parse(localStorage.getItem('lastViewedDiscover'))
		if(lastViewedDiscover && this.props.location.pathname.indexOf('/discover/')===-1){
			const {subject,subSubject,page}=lastViewedDiscover
			if(subject){
				discoverLink+=`/${subject}`
			}
			if(subSubject){
				discoverLink+=`/${subSubject}`
			}
			if(page){
				discoverLink+=`/${page}`
			}
		}

		return (    
			<div className={'sideNav' + (isDragging ? ' sideNav--isDraggingSetOrQuestion' : '') + (isDraggingFolder ? ' sideNav--isDraggingFolder' : '')}>
				<PerfectScrollbar 
					ref = {(ref) => { scrollBar = ref }} 
					options={{
								handlers:['click-rail', 'drag-thumb', 'wheel', 'touch'],
								suppressScrollX: true,
								wheelPropagation: false
					}}          
				>
					<div className='sideNav-item sideNav-item--newSet' onClick={this.handleNewSet}>
						<div className='sideNav-item-icon'>
							<Icon name='compose' />
						</div>
						<div className='sideNav-item-label'>
							New Set
						</div>

					</div>
					
					{showDiscoverLink &&      
						<NavLink
							to={discoverLink}
							className='sideNav-itemContainer'
						>
							<div className='sideNav-item is--discover'>
								<div className='sideNav-item-icon'>
									<Icon name='discoverActive' />
									<Icon name='discover' />
								</div>
								<div className='sideNav-item-label'>
									Discover
								</div>
							</div>        
						</NavLink>
					}

					{/* Keep classic Recent -> Library Layout if no discover access */}
					{!showDiscoverLink &&           
						<SideNavItem name='Recent' iconName='recent' url='/recent'/>        
					}
										
					<DnDSideNavLibrary {...this.props}/>

					{/* Show new Discover -> Library -> Recent layout if has discover access */}
					{showDiscoverLink &&            
						<SideNavItem  name='Recent' iconName='recent' url='/recent'/>       
					}                 
					
					<SideNavItem  name='Reports' iconName='history-inverted' url='/reports'/>

				
					<div className='sideNav-item is--scoresheet' onClick={()=>{window.open('/scoresheet','_blank')}}>
						<div className='sideNav-item-icon'>
							<Icon name='scoresheet' />
						</div>
						<div className='sideNav-item-label'>
							Scoresheet...
						</div>
					</div>

					
					<div className='sideNav-classDivider'>
						
						
						<div className='sideNav-item is--classes' onClick={this.props.showManageSectionsModal}>
							Your Classes
							{!isDragging && 
								<div className='sideNav-classDivider-label'>Edit</div>                
							}
						</div>            
						
					</div>
					{this.props.sections &&
						<React.Fragment>
							{connectDropTarget(
								<div className='sideNav-classGroup'>
								
							{this.props.sections.map((section)=>{
								return <SideNavSectionItem section={section} createPoll={this.props.createPoll} showNotification={this.props.showNotification} key={section.id} />
							})}
																
						</div>  )}
						</React.Fragment>
					}
				
					<div className={'sideNav-item is--newClass ' + (this.props.sections.length > 3 ? ' ' : 'is--newClass--highlight' )} onClick={() => this.props.showNewSectionModal()}>
						<div className='sideNav-item-icon'>
							<Icon name='circle-with-plus' />
						</div>
						<div className='sideNav-item-label'>
							New Class
						</div>
					</div>



					<div className='sideNav-classDivider'>
						
						{this.props.repos && this.props.repos.length > 0 &&
							<div className='sideNav-item is--repos' onClick={this.props.newRepo}>
								Your Packs                
							</div>
						}
						
					</div>
					
					{this.props.repos &&
						<div className='sideNav-classGroup'>
								
							{this.props.repos.map((repo)=>{
								return <DnDSideNavRepo {...this.props} repo={repo} key={repo.id} showEditRepoModal={this.props.showEditRepoModal} showNotification={this.props.showNotification} moveToRepo={this.props.moveToRepo}/>
							})}
																
						</div>        
					}

					<div className={'sideNav-item is--newRepo ' + ((this.props.repos && this.props.repos.length === 0) ? 'is--newRepoNoRepo' : '')} onClick={this.props.newRepo}>           
						<div className='sideNav-item-label'>
							New Pack
						</div>            
					</div>

					{this.props.repos && this.props.repos.length === 0 &&
						<div className='sideNav-itemExplanation'>
							Packs are a simple and fast way to organize and share your content.             
						</div>
					}						
				</PerfectScrollbar> 
			</div>
		)
	}
}

export default withRouter(DropTarget(ItemTypes.ITEMCELL, sideNavTarget, collect)(SideNav))
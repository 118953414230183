//for both print set and print report if user adjusts print settings we save them in local storage (at the moment just paperSize)

export function getPrintSettings() {	
	let printSettings={
		paperSize:'letter' //default
	}
	const settingsFromLocalStorage = JSON.parse(localStorage.getItem('printSettings'))
	if(settingsFromLocalStorage ){	
		if(settingsFromLocalStorage.paperSize || !settingsFromLocalStorage.paperSize){
			printSettings.paperSize=settingsFromLocalStorage.paperSize
		}
	}
	return printSettings
}

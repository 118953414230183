import React from 'react'
import ModalWrapper from './ModalWrapper'
import DuplicateAndEditModalContainer from '../../containers/modals/DuplicateAndEditModalContainer'

const DuplicateAndEditModal = props => {

	return (
	    <div className="selectionModal-modal selectionModal-modal--repo">
			    <ModalWrapper
		        {...props}
		        title={'Duplicate and Edit'}		       
		        modalStyle='dark'
		        showModalHeader={false}
			    >
	        	
	        	<DuplicateAndEditModalContainer {...props} />    
 
	        </ModalWrapper>
	    </div>
	  )
	}

export default DuplicateAndEditModal
import React, { Component } from 'react'


class RepoReleaseModalBottom extends Component {

	render() {


		return ( 
			<React.Fragment>
										
					<button disabled={this.props.buttonDisabled} className='repoReleaseModal-bottom-acceptBtn' onClick={this.props.acceptPrivacyPolicy}>
						Get Started
					</button>

					<div className='repoReleaseModal-bottom-privacyPolicyText'>
						We've updated our <a className='repoReleaseModal-bottom-privacyPolicyText-link' href="https://help.plickers.com/hc/en-us/articles/360009090833-Plickers-Privacy-Policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a className='repoReleaseModal-bottom-privacyPolicyText-link' href="https://help.plickers.com/hc/en-us/articles/360009090913-Terms-of-Service" target="_blank" rel="noopener noreferrer">Terms of Service</a> to support sharing. 
					</div>
					
					<div className='repoReleaseModal-bottom-privacyPolicyText'>
						By clicking “Get Started” above, you agree to these changes.
					</div>					

					
			</React.Fragment>
		)
	}
}


export default RepoReleaseModalBottom




import React, { useState } from 'react'
import { connect } from 'react-redux'
import ChangePasswordForm from '../../../components/forms/account/ChangePasswordForm'
import { updateUserPassword } from '../../../actions/user'
import { showNotification } from '../../../actions/notifications'
import handleAccountRequest from '../../account/utils/handleAccountRequest'

function ChangePasswordContainer(props) {
  const {
    user, showNotification, updateUserPassword, hideModal,
  } = props
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [logOut, setLogOut] = useState(false)

  function onSubmit(formData) {
    const requestData = {
      currentPassword: formData.currentPassword,
      newPassword: formData.newPassword,
    }

    handleAccountRequest({
      setSubmitLoading,
      setErrorMessage,
      makeRequest: () => updateUserPassword(requestData, user.id, logOut),
      hideModal,
      showSuccessNotification: () => showNotification('your password', 'Updated', 'default'),
      requestName: 'updatePassword',
    })
  }

  return (
    <React.Fragment>

      <ChangePasswordForm
        submitLoading={submitLoading}
        onSubmit={(formData) => onSubmit(formData)}
        logOut={logOut}
        toggleLogout={() => setLogOut(!logOut)}
      />

      {errorMessage && (
        <div className='accountForm-error'>
          {errorMessage}
        </div>
      )}

    </React.Fragment>
  )
}

export default connect(
  (state) => ({
    user: state.user,

  }),
  { updateUserPassword, showNotification },
)(ChangePasswordContainer)

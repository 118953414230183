import React from 'react'


class PublishingFlowDescriptionPage extends React.Component{
	
	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Publishing Flow',{
				stage:'description',
			})
		}
	}



	render(){	
		
		const {repoDescription} = this.props
		const maxDescriptionLength = 300

		return(
			<div className='publishingFlowModalPage publishingFlowModalPage--description'>
				<div className='publishingFlowModalPage-header'>
					Add Pack Description <span className='publishingFlowModalPage-header-light'>(optional)</span>
				</div>
				<div className='publishingFlowModalPage-subheader'>
					Help teachers understand how your pack can be used in their classroom.
				</div>				
				<textarea 	
					autoFocus									
					value={repoDescription}
					maxLength={maxDescriptionLength}
					placeholder="Enter a snazzy description"					
					onChange={(e)=>{this.props.onChangeRepoDescription(e.target.value)}}
				/>
				{repoDescription &&
					<div className={'publishingFlowModalPage--description-characterCount ' + ((maxDescriptionLength === repoDescription.length) ? ' publishingFlowModalPage--description-characterCount--max' : '') + ((repoDescription.length > 200) ? ' publishingFlowModalPage--description-characterCount--show' : '')}>
						{repoDescription.length} / {maxDescriptionLength}
					</div>
				}

			</div>

			
		)	
	}
				
}




export default PublishingFlowDescriptionPage



import store from '../../store'
import moment from 'moment'

export function analyticsUpdateNowPlayingSetting(setting,value) {
	const state = store.getState()
	if(window.analytics){
		const user=state.user
		let userDaysOld
		if(user){
			const userCreated=user.userCreated
			const nowDate =moment()
			const seconds = Math.floor((nowDate - moment(userCreated)) / 1000)
			const minutes = Math.floor(seconds / 60)
			const hours = Math.floor(minutes / 60)
			userDaysOld=Math.floor(hours / 24)
		}

		window.analytics.track('Update Now Playing Setting', {
			setting:setting,
			value:value,
			userDaysOld:userDaysOld
		})




	

	}
	
}



import React from 'react'
import EditorEditVideoModal from '../editorUI/modals/EditorEditVideoModal'
import ReactDOM from 'react-dom'
import {stopAllMediaPlayback} from '../../../prosemirror/utils/editorActions/stopAllMediaPlayback'
import store from '../../../store'

const modalId='editor-editYouTubeModalContainer'

function closeEditVideoModal(){
	ReactDOM.render(null, document.getElementById(modalId))
}


export function showEditVideoModal(isInsertFlow,searchState,autoplay,video,insertPos,isQuestion,questionPos,questionNode,videoNode,videoNodePos) {
	stopAllMediaPlayback()
	store.dispatch({ type: 'HIDE_NOTIFICATION'})
	ReactDOM.render(
		<EditorEditVideoModal 
			searchState={searchState}
			autoplay={autoplay}
			videoNode={videoNode}
			videoNodePos={videoNodePos}
			isInsertFlow={isInsertFlow}
			video={video}
			insertPos={insertPos}
			isQuestion={isQuestion}
			questionPos={questionPos}
			questionNode={questionNode}
			closeEditVideoModal={closeEditVideoModal}
		/>, document.getElementById(modalId))
}
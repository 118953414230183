import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm} from 'redux-form'
import Icon from '../../components/misc/Icons'
import FormFieldText from '../../components/uiKit/FormFieldText'
import FormFieldSelectColor from '../../components/uiKit/FormFieldSelectColor'



const SectionColorOptions = [	
	{label:'1', 		value:'blue'},
	{label:'2', 		value:'purple'},
	{label:'3', 		value:'red'},
	{label:'4', 		value:'orange'},
	{label:'5',			value:'green'},
	{label:'6', 		value:'sky'},
	{label:'7', 		value:'pink'},
	{label:'8', 		value:'teal'},
	{label:'9', 		value:'grey'},
]


class NewSectionForm extends React.Component{
	
	constructor() {
		super()
		this.state={
			nameFocused:false,
			inputLength:0,
		}
	}

	render(){
		const { handleSubmit,autoFocus,tabIndex } = this.props
		const { nameFocused,inputLength } = this.state
		
		return(
			<div className={'newSection-form-section' + 
					(nameFocused ? ' newSection-form-section--nameFocused' : '') +
					(inputLength > 0 ? ' newSection-form-section--nameNonEmpty' : ' newSection-form-section--nameEmpty')}
					>
				<form onSubmit={handleSubmit}>										
						<Field
          		name='color'
          		label=' '
          		component={FormFieldSelectColor}
          		options={SectionColorOptions}
          		placeholderOptionLabel=' '	
          		tabIndex={-1}		          	
			      />

			      <div className='newSection-form-section-icons'>
					  	<Icon name='check' />
					  	<Icon name='three-dots-horizontal' />
					  </div>

	        	<Field 
	        		onFocus={()=>{this.setState({'nameFocused' : true})}}
	        		onBlur={()=>{this.setState({'nameFocused' : false})}}
	        		onChange={(e)=>{this.setState({'inputLength' : e.target.value.length})}}
	        		name='name'
	        		autoFocus={autoFocus}			        		
	        		type='text'	
			        component={FormFieldText}
			        placeholder='Enter Class Name'		          	
			        tabIndex={tabIndex}
			        fieldLengthLimit={40}
					  />
			        
					  
	     	</form>	     		
     	</div>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		form:ownProps.formName,
		initialValues: {
			color:ownProps.defaultColor
		}
	}
}


NewSectionForm = reduxForm({
	enableReinitialize: false
})(NewSectionForm)

export default connect(mapStateToProps)(NewSectionForm)






import React from 'react'
import ExplorerCrossFilterMarketPanelSelectControl from './ExplorerCrossFilterMarketPanelSelectControl'
import subjects from '../../staticData/subjects.js'
import gradeLevels from '../../staticData/gradeLevels.js'
import find from 'lodash/find'



class ExplorerCrossFilterMarketPanelSubjectSelectControl extends React.Component{ 
	render(){
		const {subject,selectSubject,selectedSubjects}=this.props
		const subjectDefinition=subjects[subject]
		const {subjectId}=subjectDefinition
		const label=this.props.label || subjectDefinition.label

		return( 						
				<ExplorerCrossFilterMarketPanelSelectControl
					label={label}
					checkbox
					checked={selectedSubjects[subjectId]}
					onSelect={()=>{selectSubject(subjectId)}}
				/>							
		)
	}
}


class ExplorerCrossFilterMarketPanelGradeSelectControl extends React.Component{ 
	render(){
		const {grade,selectGrade,selectedGrades}=this.props
		let label
		if(this.props.label){
			label=this.props.label
		}else{
			const gradeDefinition=find(gradeLevels,{grade:grade})
			label=gradeDefinition.longLabel
		}


		return( 						
				<ExplorerCrossFilterMarketPanelSelectControl
					label={label}
					checkbox
					checked={selectedGrades[grade]}
					onSelect={()=>{selectGrade(grade)}}
				/>							
		)
	}
}




class ExplorerCrossFilterMarketPanel extends React.Component{ 
 


	render(){
		const {selectSubject,selectedSubjects,selectGrade,selectedGrades,selectedInternalRatings,selectInternalRating}=this.props
 



		return( 
			<div className='explorerCrossFilter-marketPanel' >
				<div className='explorerCrossFilter-marketPanel-filter explorerCrossFilter-marketPanel-filter--rating' >
					<div className='explorerCrossFilter-marketPanel-filter-header'>
						<div className='explorerCrossFilter-marketPanel-filter-header-title'>
							Internal Rating
						</div>		
						<button className='explorerCrossFilter-marketPanel-filter-header-btn' onClick={this.props.selectAllInternalRatings}>
							Select All
						</button>
						<button className='explorerCrossFilter-marketPanel-filter-header-btn' onClick={this.props.clearSelectedInternalRatings}>
							Clear
						</button>				
					</div>
					<div className='explorerCrossFilter-marketPanel-filter-controlArray'>						
						{/*
						<ExplorerCrossFilterMarketPanelSelectControl
							label='6 - Exceeds guidelines, ready for release'
							checkbox
							checked={selectedInternalRatings[6]?true:false}
							onSelect={()=>{selectInternalRating(6)}}
						/>
						*/}
						
						<ExplorerCrossFilterMarketPanelSelectControl
							label='5 - Amazing Pack'
							checkbox
							checked={selectedInternalRatings[5]?true:false}
							onSelect={()=>{selectInternalRating(5)}}
						/>

						<ExplorerCrossFilterMarketPanelSelectControl
							label='4 - Great Pack'
							checkbox
							checked={selectedInternalRatings[4]?true:false}
							onSelect={()=>{selectInternalRating(4)}}
						/>

						<ExplorerCrossFilterMarketPanelSelectControl
							label='3 - Good pack'
							checkbox
							checked={selectedInternalRatings[3]?true:false}
							onSelect={()=>{selectInternalRating(3)}}
						/>

						<ExplorerCrossFilterMarketPanelSelectControl
							label='2 - Bad pack'
							checkbox
							checked={selectedInternalRatings[2]?true:false}
							onSelect={()=>{selectInternalRating(2)}}
						/>

						<ExplorerCrossFilterMarketPanelSelectControl
							label='1 - Very Bad'
							checkbox
							checked={selectedInternalRatings[1]?true:false}
							onSelect={()=>{selectInternalRating(1)}}
						/>

						<ExplorerCrossFilterMarketPanelSelectControl
							label='0 - Ignore'
							checkbox
							checked={selectedInternalRatings[0]?true:false}
							onSelect={()=>{selectInternalRating(0)}}
						/>

						<ExplorerCrossFilterMarketPanelSelectControl
							label='No Rating'
							checkbox
							checked={selectedInternalRatings.noRating?true:false}
							onSelect={()=>{selectInternalRating('noRating')}}
						/>
						
						
					</div>											
				</div>

				<div className='explorerCrossFilter-marketPanel-filter explorerCrossFilter-marketPanel-filter--publishedMonth' >
					<div className='explorerCrossFilter-marketPanel-filter-header'>
						<div className='explorerCrossFilter-marketPanel-filter-header-title'>
							Published Month
						</div>												
						<button className='explorerCrossFilter-marketPanel-filter-header-btn'>
							Select All
						</button>
						<button className='explorerCrossFilter-marketPanel-filter-header-btn'>
							Clear
						</button>						
					</div>
					<div className='explorerCrossFilter-marketPanel-filter-controlArray'>
						<ExplorerCrossFilterMarketPanelSelectControl
							label='Nov 2019'
							checkbox
							checked={this.props.selectedMonths['Nov 2019']}
							onSelect={()=>{this.props.selectMonth('Nov 2019')}}
						/>
						<ExplorerCrossFilterMarketPanelSelectControl
							label='Dec 2019'
							checkbox
							checked={this.props.selectedMonths['Dec 2019']}
							onSelect={()=>{this.props.selectMonth('Dec 2019')}}
						/>
						<ExplorerCrossFilterMarketPanelSelectControl
							label='Jan 2020'
							checkbox
							checked={this.props.selectedMonths['Jan 2020']}
							onSelect={()=>{this.props.selectMonth('Jan 2020')}}
						/>
						<ExplorerCrossFilterMarketPanelSelectControl
							label='Feb 2020'
							checkbox
							checked={this.props.selectedMonths['Feb 2020']}
							onSelect={()=>{this.props.selectMonth('Feb 2020')}}
						/>
						<ExplorerCrossFilterMarketPanelSelectControl
							label='March 2020'
							checkbox
							checked={this.props.selectedMonths['Mar 2020']}
							onSelect={()=>{this.props.selectMonth('Mar 2020')}}
						/>	
						<ExplorerCrossFilterMarketPanelSelectControl
							label='April 2020'
							checkbox
							checked={this.props.selectedMonths['Apr 2020']}
							onSelect={()=>{this.props.selectMonth('Apr 2020')}}
						/>	
					</div>
				</div>



				<div className='explorerCrossFilter-marketPanel-filter explorerCrossFilter-marketPanel-filter--subject' >
					<div className='explorerCrossFilter-marketPanel-filter-header'>
						<div className='explorerCrossFilter-marketPanel-filter-header-title'>
							Subject
						</div>
						<button className='explorerCrossFilter-marketPanel-filter-header-btn' onClick={this.props.selectAllSubjects}>
							Select All
						</button>
						<button className='explorerCrossFilter-marketPanel-filter-header-btn' onClick={this.props.clearSelectedSubjects}>
							Clear
						</button>
					</div>
					<div className='explorerCrossFilter-marketPanel-filter-controlArray'>												
						<ExplorerCrossFilterMarketPanelSubjectSelectControl
							subject='computerScience'
							selectSubject={selectSubject}
							selectedSubjects={selectedSubjects}
						/>	
						<ExplorerCrossFilterMarketPanelSubjectSelectControl
							subject='computerSkills'
							selectSubject={selectSubject}
							selectedSubjects={selectedSubjects}
						/>							
						<ExplorerCrossFilterMarketPanelSubjectSelectControl
							subject='creativeArts'
							selectSubject={selectSubject}
							selectedSubjects={selectedSubjects}
						/>

						
					</div>
					<div className='explorerCrossFilter-marketPanel-filter-controlArray explorerCrossFilter-marketPanel-filter-controlArray--subSubject'>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='art'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>	
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='creativeArtsOther'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>											
						</div>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='drama'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>												
						</div>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='music'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>													
						</div>					
					</div>
					<div className='explorerCrossFilter-marketPanel-filter-controlArray'>
						<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='foreignLanguages'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>			

					</div>
					<div className='explorerCrossFilter-marketPanel-filter-controlArray explorerCrossFilter-marketPanel-filter-controlArray--subSubject'>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='arabic'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>				
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='chinese'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>	
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='english'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='french'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>	
							
							
						</div>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='german'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>			
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='italian'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='japanese'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='latin'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>					
						</div>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='signLanguage'
								label="Sign Lang"
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>	
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='spanish'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='foreignLanguagesOther'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>				
							
													
						</div>					
					</div>
					<div className='explorerCrossFilter-marketPanel-filter-controlArray'>
						<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='languageArts'
								label='Lit/Lang Arts'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>

							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='math'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>	
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='physicalEducation'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='science'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>	

						
					</div>
					<div className='explorerCrossFilter-marketPanel-filter-controlArray explorerCrossFilter-marketPanel-filter-controlArray--subSubject'>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='biology'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>	

							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='scienceOther'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>		

																		
						</div>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='chemistry'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>												
						</div>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='physics'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>													
						</div>					
					</div>
					<div className='explorerCrossFilter-marketPanel-filter-controlArray'>
						
						<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='socialSciences'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>

					</div>
					<div className='explorerCrossFilter-marketPanel-filter-controlArray explorerCrossFilter-marketPanel-filter-controlArray--subSubject'>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='economics'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>			
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='history'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>											
						</div>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='geography'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>	
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='psychology'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>						
																			
						</div>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='politics'
								label='Govt/Politics'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>
							<ExplorerCrossFilterMarketPanelSubjectSelectControl
								subject='socialSciencesOther'
								selectSubject={selectSubject}
								selectedSubjects={selectedSubjects}
							/>	

																						
						</div>					
					</div>
					<div className='explorerCrossFilter-marketPanel-filter-controlArray'>
						<ExplorerCrossFilterMarketPanelSubjectSelectControl
							subject='other'
							selectSubject={selectSubject}
							selectedSubjects={selectedSubjects}
						/>	
					</div>
				</div>

				<div className='explorerCrossFilter-marketPanel-filter explorerCrossFilter-marketPanel-filter--gradeLevel' >
					<div className='explorerCrossFilter-marketPanel-filter-header'>
						<div className='explorerCrossFilter-marketPanel-filter-header-title'>
							Grade Level
						</div>
						<button className='explorerCrossFilter-marketPanel-filter-header-btn' onClick={this.props.selectAllGrades}>
							Select All
						</button>
						<button className='explorerCrossFilter-marketPanel-filter-header-btn' onClick={this.props.clearSelectedGrades}>
							Clear
						</button>
					</div>
					<div className='explorerCrossFilter-marketPanel-filter-controlArray explorerCrossFilter-marketPanel-filter-controlArray--threeColumns'>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							



							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='pre-k'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>	
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='k'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>	
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='1st'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>	
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='2nd'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>						
								
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='3rd'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>						
													
										
							
						</div>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
									
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='4th'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>						
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='5th'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>							
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='6th'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>	
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='7th'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>							
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='8th'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>										
						</div>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='9th'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>	
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='10th'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>	
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='11th'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>	
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='12th'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>							
						</div>
					</div>
					
					<div className='explorerCrossFilter-marketPanel-filter-controlArray explorerCrossFilter-marketPanel-filter-controlArray--threeColumns explorerCrossFilter-marketPanel-filter-controlArray--extraGrades'>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='higher'
								label='Higher Ed'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>

																		
						</div>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='staff'
								label='Staff'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>												
						</div>
						<div className='explorerCrossFilter-marketPanel-filter-controlArray-col'>
							<ExplorerCrossFilterMarketPanelGradeSelectControl
								grade='other'
								label='Other'
								selectGrade={selectGrade}
								selectedGrades={selectedGrades}
							/>													
						</div>
					</div>
				</div>


				<div className='explorerCrossFilter-marketPanel-filter explorerCrossFilter-marketPanel-filter--language' >
					<div className='explorerCrossFilter-marketPanel-filter-header'>
						<div className='explorerCrossFilter-marketPanel-filter-header-title'>
							Language
						</div>
						{/*
						<button className='explorerCrossFilter-marketPanel-filter-header-btn'>
							Select All
						</button>
						<button className='explorerCrossFilter-marketPanel-filter-header-btn'>
							Clear
						</button>
						*/}
					</div>
					<div className='explorerCrossFilter-marketPanel-filter-controlArray'>
						<ExplorerCrossFilterMarketPanelSelectControl
							label='English'
							checkbox
							checked={this.props.englishLanguageFilter}
							onSelect={this.props.toggleEnglishLanguageFilter}
						/>						
					</div>
				</div>

				

			</div>
		)
	}
}


export default ExplorerCrossFilterMarketPanel



import React from 'react' 
import { withRouter} from 'react-router-dom'
import Button from '../uiKit/Button'
import Icon from '../misc/Icons'
import Dropdown from '../uiKit/Dropdown'
import DropdownItem from '../uiKit/DropdownItem'
import AddToQueueDropdown from '../uiKit/AddToQueueDropdown'
import sortBy from 'lodash/sortBy' 
import {getRepoforId} from '../../utils/getRepoforId'


class MultiSelectSidePanelMoveToFolderFolder extends React.Component{
	render(){
		const {folderName, onClickFunction,disabled}=this.props	
		return(
			<div onClick={onClickFunction} className={'multiSelectPreviewPanel-moveToFolder-scrollList-folder' + (disabled ? ' multiSelectPreviewPanel-moveToFolder-scrollList-folder--disabled': '')}>
				<Icon name='folder' />
				{folderName}
			</div>
		)
	}
}
  
 
class MultiSelectPreviewPanel extends React.Component{


	render(){

		const {folders,multiSelectItems,multiSelectContainsFolders,multiSelectContainsReadOnly}=this.props
		
		const numberSelected = Object.keys(multiSelectItems).length

		let containingRepoID
		let numberOfQuestionsSelected=0
		Object.keys(multiSelectItems).map((key) => {
			const item=multiSelectItems[key]
			containingRepoID=item.repo
			if(item.questions){
				numberOfQuestionsSelected+=item.questions.length
			}else{
				numberOfQuestionsSelected+=1
			}
			return null
		})
		let disableCombineIntoSet=false
		if(this.props.questionsInSetLimit && numberOfQuestionsSelected > this.props.questionsInSetLimit){
			disableCombineIntoSet=true
		}
		let sortedFolders=folders.slice(0)
		sortedFolders=sortBy(sortedFolders,[function(o) { 
			return o.name.toString().toLowerCase()
		}])

		let isViewOnly = multiSelectContainsReadOnly
		if(containingRepoID){
			const repo = getRepoforId(containingRepoID)
			if(repo){
				if(repo.role === 'viewer' || repo.role === 'consumer'){
					isViewOnly = true 
				}
			}

		}







		return(
			<div className='multiSelectPreviewPanelContainer'>
				<div className='multiSelectPreviewPanel'>
					<div className='multiSelectPreviewPanel-header'>
						<div className='multiSelectPreviewPanel-header-title'>
							{numberSelected} items selected
						</div>
						<div className='multiSelectPreviewPanel-header-questionCount'>
							{!multiSelectContainsFolders &&
								<React.Fragment>
									<span className='multiSelectPreviewPanel-header-questionCount-count'>{numberOfQuestionsSelected} questions</span>
									<span className='multiSelectPreviewPanel-header-questionCount-label'>in selection</span>
								</React.Fragment>	
							}														
						</div>
					</div>


					{!isViewOnly &&
					<div className='multiSelectPreviewPanel-buttonRow'>						
						<Button 
							name='movealltopack'
							label='Move all to Pack'
							onClickFunction={() =>{this.props.showRepoSelectionModal(null,multiSelectItems)}}
							// color='blue'
							iconName='open-left-arrow'							
						/>

						<Dropdown>							

							<DropdownItem label='New Set from Selection' disabled={disableCombineIntoSet || multiSelectContainsFolders} onSelect={() =>{this.props.combineIntoSet(multiSelectItems)}} />
							
							<DropdownItem  label='Send all to Trash' onSelect={() =>{this.props.batchArchive(multiSelectItems)}} />
							
						</Dropdown>								
					</div>
				}


					<div className='multiSelectPreviewPanel-divider' />



					{!isViewOnly &&
					<div className='multiSelectPreviewPanel-moveToFolder'>
						<div className='multiSelectPreviewPanel-moveToFolder-header'>
							Move all to Folder
						</div>
						<div className='multiSelectPreviewPanel-moveToFolder-scrollList'>						
							{sortedFolders.map((folder,index) =>{
								let isDisabled=false
								if(folder.readOnly || multiSelectItems[folder.id]){
									isDisabled=true
								}
								return(
									<MultiSelectSidePanelMoveToFolderFolder disabled={isDisabled} key={index} onClickFunction={()=>{this.props.batchMoveToFolder(folder)}} folderName={folder.name} />
								)
							})}
							
						</div>
						<div className='multiSelectPreviewPanel-moveToFolder-selectFolderBtn'  onClick={() =>{this.props.showMoveToFolderModal(multiSelectItems)}}>
							Select Folder...
						</div>
					</div>
					}
					<div className='multiSelectPreviewPanel-addToQueueMenuContainer'>
						<div className='multiSelectPreviewPanel-addToQueueMenu'>
							<AddToQueueDropdown  multiSelectItems={multiSelectItems} dropdownButton={<Button disabled={multiSelectContainsFolders} iconName='plus' label='Add all to Queue' />} multiselect inverted />
							{/* item={activeItem} */}

						</div>
					</div>


				</div>
								

			</div>

			
		)
	}

}

       

export default withRouter(MultiSelectPreviewPanel)

import moment from 'moment'
import store from '../store'

export function calibratedCurrentTime() {
  return calibratedCurrentTimeMoment().toISOString()
}

export function calibratedCurrentTimeMoment() {
  const state = store.getState()
  return offsetTimestampByMs(moment(), state.clockSyncOffset)
}

export function offsetTimestampByMs(timestamp, offsetMs) {
  return moment(timestamp).add((offsetMs || 0), 'milliseconds')
}

import React from 'react';
import EditorIcon from '../../../../misc/EditorIcons'
import Icon from '../../../../misc/Icons'
import Slider, { SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import {formatSoundLibraryDuration} from './utils/formatSoundLibraryDuration'


const { Handle } = Slider

const handle = props => {
	const { value, dragging, index, ...restProps } = props;
	return (
		<SliderTooltip
			prefixCls="slide-media--sound-player-seekBar-tooltip"
			overlay={`${value}`}
			visible={false}
			placement="top"
			key={index}
		>
			<Handle value={value} {...restProps} />
		</SliderTooltip>
	);
};



class EditorSoundLibrarySoundClipRow extends React.Component{  
	
	constructor(props) {
		super(props)
		this.playSound=this.playSound.bind(this)
		this.pauseSound=this.pauseSound.bind(this)
		this.startTimer=this.startTimer.bind(this)
		this.seekTo=this.seekTo.bind(this)
		this.stopTimer=this.stopTimer.bind(this)   
		this.stopSound=this.stopSound.bind(this)
		this.onSoundRowClick=this.onSoundRowClick.bind(this)
		this.onAudioEnd=this.onAudioEnd.bind(this)
		this.adioElement=null
		this.timer=null
		const duration = props.end-props.start    
		this.state = {
			isPlaying:false,
			isLoading:false,
			duration:duration,
			playerCurrentTime:0,      
		}
	}

	componentWillUnmount() {
		this.stopTimer()
		if(this.audioElement){
			this.audioElement.pause()
			this.audioElement.removeEventListener('ended',this.onAudioEnd)
		}
	}

	 UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.playback.playbackRow===nextProps.rowIndex){
			 if(nextProps.playback.isPlaying){
				if(!this.state.isPlaying){
					this.playSound()
				}
			}else{
				if(this.state.isPlaying){
					this.pauseSound()
				}
			}
		}else{
			if(this.state.isPlaying){
				this.stopSound()
			}
		}
	}

	onAudioEnd(){
		this.audioElement.currentTime = 0
		this.setState({isPlaying:false,playerCurrentTime:0})
		this.stopTimer()
		this.props.pauseItem()
	}

	updatePlayerCurrentTime(){
		const currentTime=this.audioElement.currentTime
		this.setState({playerCurrentTime:currentTime})
	}

	startTimer(){
		this.timer = setInterval(() => {
			this.updatePlayerCurrentTime()
		}, 10)
	}
	 
	stopTimer(){
		if(this.timer){
			clearInterval(this.timer)
			this.timer=null
		}
	}

	playSound(){
		if(!this.audioElement){
			this.setState({isLoading:true})
			this.audioElement = new Audio(this.props.deliveryUrl)
			this.audioElement.addEventListener('ended',this.onAudioEnd)
			this.audioElement.addEventListener('loadeddata', () => {
				this.setState({isLoading:false})
			})
		}
		this.setState({isPlaying:true})
		this.startTimer()
		this.audioElement.play()
	}

	pauseSound(){
		this.setState({isPlaying:false})
		this.audioElement.pause()
		this.stopTimer()
	}

	stopSound(){
		this.setState({isPlaying:false})
		this.audioElement.pause()
		this.stopTimer()
	}

	seekTo(seconds){
		this.audioElement.currentTime=seconds
		if(!this.timer){
			this.setState({playerCurrentTime:seconds})
		}
	}

	onSoundRowClick(e){
		if(!this.props.active){
			this.props.setActiveRow()
		}else if(e.target === e.currentTarget){
			this.props.setActiveRow()
		}
	}
 
	render(){  
		const {isPlaying, isLoading} = this.state
		const {active, categoryType} = this.props
		const progress = this.state.playerCurrentTime
		const lengthSeconds = this.state.duration
		const soundClipTitle = this.props.soundClipName
		return (          		
			<div onClick={this.onSoundRowClick} onDoubleClick={this.props.insertSoundFromSoundLibrary} className={'editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow notranslate ' + (active ? 'editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active' : 'editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--inactive')}>				
				<div className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtnContainer'>
					{isPlaying && !isLoading &&
							<button onClick={this.props.pauseItem} className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn--playing'>
								<div className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal'>
									<EditorIcon name='playbackPause' /> 
								</div>
							</button>
					}
					{!isPlaying &&
						<button onClick={this.props.playItem} className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn--notPlaying'>
							<div className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal'>
								<EditorIcon name='playbackPlay' />                
							</div>
						</button>
					}
					{isPlaying && isLoading &&
						<button onClick={this.props.pauseItem} className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn--playing'>
							<div className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal'>
								<div className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal-spinnerContainer'>
									<div className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal-spinnerContainer-spinner'/>
								</div>
							</div>
						</button>
					}
				</div>				
				<div onClick={this.onSoundRowClick} className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-name'>
					{soundClipTitle}
				</div>
				{progress > 0 &&
					<div className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-seekBarContainer' >          
						<Slider 
							onChange={(value)=>{this.seekTo(value)}}
							value={this.state.playerCurrentTime} 
							step={0.01}
							min={0} 
							max={lengthSeconds} 
							defaultValue={0} 
							handle={handle}
						/>                        
					</div>
				}
				{!progress && this.props.category && categoryType === 'mixed' &&
					<div className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-categoryLabelContainer' >                    
						<div className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-categoryLabel'>
							{this.props.category}
						</div>          
					</div>
				}
				<div onClick={this.onSoundRowClick} className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-duration'>
					{formatSoundLibraryDuration(this.state.duration)}
				</div>
				{(categoryType === 'public' || categoryType === 'mixed') &&
					<div className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-plays'>
						{this.props.plays}
					</div>
				}
				<div className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-insertBtnContainer'>
					<button onClick={this.props.editSoundFromSoundLibrary} className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-insertBtn'>
						<Icon name='chevron-right' />
					</button>
				</div>
				<div className='editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-borderBottom'/>
			</div>						
		)
	}
}

export default EditorSoundLibrarySoundClipRow

import React from 'react'
import Select from 'react-select'
import 'react-select/dist/react-select.css'



class FormFieldSelectColor extends React.Component{

	colorOption(option){		
		return(
			<div className={'selectColor-menuOption ' + (option.value ? `classColor--${option.value}` : 'classColor--none')} />					
		)
	}

	colorValue(option){		
		return(
			<div className={'selectColor-valueOption ' + (option.value ? `classColor--${option.value}` : 'classColor--none')} />					
		)
	}

	render(){	
		const {input } = this.props


		return(
			<div className={'formField is--select' + (input.name ? ` is--${input.name}` : '') }>
				<div className='formField-select--container'>
				  <Select
						    {...this.props}
						    value={this.props.input.value}
						    onChange={(option) => this.props.input.onChange(option.value)}
						    onBlur={() => this.props.input.onBlur(this.props.input.value)}
						    options={this.props.options}	    
						    optionRenderer={this.colorOption}
						   	valueRenderer={this.colorValue}
						    searchable={false}
						    clearable={false}
						    placeholder=' '					    
						  />
				</div>
	    </div>
		)
	}
				
}

export default FormFieldSelectColor



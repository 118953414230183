import React, { Component } from 'react'
import InputField from '../../components/uiKit/InputField'
import Button from '../../components/uiKit/Button'
import { withRouter} from 'react-router-dom'  
import Icon from '../../components/misc/Icons'
import {Image} from 'cloudinary-react'
import EditRepoModalEditGradeLevel from './EditRepoModalEditGradeLevel'
import EditRepoModalEditSubject from './EditRepoModalEditSubject'

const maxNameLength = 80  
const maxDescriptionLength = 300 

 
class EditRepoModal extends Component {
  
	constructor(props){
		super(props)
		this.onPaste=this.onPaste.bind(this)
		let showExpanded=false
		if(props.repo){
			if(props.repo.subject || props.repo.language || (props.repo.grade &&props.repo.grade.length!==0)){
				showExpanded=true
			}
		}

	 
	 	this.state={
			showExpanded: showExpanded,
			expandedTab: 'basicInfo'
		}	   
	}


	componentDidMount(){
		if(!this.props.repo){
			var input = document.getElementById('repoNameInput')
			input.focus()
			input.select()
		}

		if(window.analytics){
			if(this.props.repo){
				window.analytics.page('Edit Repo Modal',{repoId:this.props.repo.id})
			}else{
				window.analytics.page('New Repo Modal')
			}
			
		}

	}


		
	handleRepoDescriptionKeyDown(e){
		if(e.which === 13) { //prevent line breaks
			e.preventDefault()
		}
	}

	onPaste(event) {
	    event.preventDefault()//prevent pasting
    	var newText = (event.originalEvent || event).clipboardData.getData('text/plain')
    	newText = newText.replace(/\r?\n/g, ' ') //remove line breaks

      	// merged the modified pasted text with the existing text
	    var el = event.target
	    var cursorPosStart = el.selectionStart
	    var cursorPosEnd = el.selectionEnd
	    var v = el.value
	    var textBefore = v.substring(0, cursorPosStart)
	    var textAfter  = v.substring(cursorPosEnd, v.length)
	    let mergedText = textBefore + newText + textAfter
	    el.value=mergedText
	    if(mergedText.length >= maxDescriptionLength) {
			mergedText=mergedText.substring(0, maxDescriptionLength)
		}

	    this.props.updateRepoDescription(mergedText)


	}


	render() {
		const {repo,repoName,repoImage,repoDescription,imageToUploadOnCreate,submitButtonDisabled}=this.props
		const uploadingImage = this.props.uploadingToCloudinary

		const {showExpanded, expandedTab} = this.state // expanded view shows state


		return ( 
			<div className={'repoDetailsModal ' + (showExpanded ? 'repoDetailsModal--expanded' : '')}>
				<div className='repoDetailsModal-closeBtn' onClick={this.props.hideModal}>					
 					<Icon name='circle-with-close' /> 
				</div>

				{!showExpanded &&
					<Button
						name='addmetadata'									
						label='Add Subject and Grade-Level' 
						onClickFunction={()=>{this.setState({showExpanded: true})}}			
					/>
				}		

				<div className='repoDetailsModal-title'>
					{!repo &&
						<span>Create New Pack</span>
					}
					{repo &&
						<span>Edit Pack</span>
					}
				</div>

				{showExpanded &&
					<div className='repoDetailsModal--expanded-tabsContainer'>
						<div
							onClick={()=>{this.setState({expandedTab: 'basicInfo'})}}			
							className={'repoDetailsModal--expanded-tab ' + (expandedTab === 'basicInfo' ? 'repoDetailsModal--expanded-tab--active':'repoDetailsModal--expanded-tab--inactive')}
						>
							Basic Info
						</div>
						<div 
							onClick={()=>{this.setState({expandedTab: 'subject'})}}
							className={'repoDetailsModal--expanded-tab ' + (expandedTab === 'subject' ? 'repoDetailsModal--expanded-tab--active':'repoDetailsModal--expanded-tab--inactive')}
						>
							Subject
						</div>
						<div 
							onClick={()=>{this.setState({expandedTab: 'gradeLevel'})}}
							className={'repoDetailsModal--expanded-tab ' + (expandedTab === 'gradeLevel' ? 'repoDetailsModal--expanded-tab--active':'repoDetailsModal--expanded-tab--inactive')}
						>
							Grade Level
						</div>
					</div>
				}
				

				{(!showExpanded || (showExpanded && expandedTab === 'basicInfo')) &&

					<React.Fragment>

						{/* REPO NAME */}
						<div className='repoDetailsModal-input repoDetailsModal-input--name'>
							<div className='repoDetailsModal-input-label'>
								Name {repo && repo.published && repoName.length === 0 && <span className='repoDetailsModal-input-label-required'>&nbsp;(required)</span>}
								
								<div className={'repoDetailsModal-input-label-characterCount ' + ((maxNameLength === repoName.length) ? ' repoDetailsModal-input-label-characterCount--max' : '') + ((repoName.length > 60) ? ' repoDetailsModal-input-label-characterCount--show' : '')}>
									{repoName.length} / {maxNameLength}
								</div>				

							</div>
							<InputField
								autoFocus={true} 
								onFocus={function(e) {
				    				var val = e.target.value
				    				e.target.value = ''
				    				e.target.value = val
				 	 			}}		      
								value={repoName}
								placeholder='Enter Pack Name'
								maxLength={maxNameLength}
								onChangeFunction={this.props.handleNameChange}
								id={'repoNameInput'}

							/>
						</div>

						<div className='repoDetailsModal--middle'>

							{/* REPO IMAGE */}
							<div className='repoDetailsModal-image' >
								<div className='repoDetailsModal-input-label'>
									Image  {repo && repo.published && !repoImage &&	!uploadingImage  && !imageToUploadOnCreate && <span className='repoDetailsModal-input-label-required'>&nbsp;(required)</span>}
								</div>


								{!repoImage &&	!uploadingImage  && !imageToUploadOnCreate &&
										<div className='repoDetailsModal-imageContainer repoDetailsModal-imageContainer--noImage' onClick={this.props.showEditImageModal}>
											<div className='repoDetailsModal-imageContainer-addImage'>
												<Icon name='plus' />
											</div>			
										</div>
								}

								{repoImage && !uploadingImage &&
										<div className='repoDetailsModal-imageContainer repoDetailsModal-imageContainer--hasImage'>
											<div onClick={this.props.deleteImage} className='repoDetailsModal-imageContainer-removeImage'>
												<Icon name='close' />
											</div>

											<Image
												cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
												publicId={`${repoImage}/repo/${repo.id}`} 
												className='repoDetailsModal-imageContainer-image'
												width='450'
												height='450'
												crop='fill'
												fetchFormat='auto'
			    							quality='auto'
											/>

											<div className='repoDetailsModal-imageContainer-image-border'/>
										</div>
									}

									{!repoImage && !uploadingImage && imageToUploadOnCreate &&
										<div className='repoDetailsModal-imageContainer repoDetailsModal-imageContainer--noImage'>
											<div onClick={this.props.deletePendingImage} className='repoDetailsModal-imageContainer-removeImage'>
												<Icon name='close' />
											</div>

											<img src={imageToUploadOnCreate} alt=''
												className='repoDetailsModal-imageContainer-image'
											/>

											<div className='repoDetailsModal-imageContainer-image-border'/>
										</div>
									}



									{uploadingImage && !repoImage &&
										<div className='repoDetailsModal-imageContainer repoDetailsModal-imageContainer--uploadingImage'>
											<div className='repoDetailsModal-imageContainer--uploading-loadingSpinnerContainer'>
												<div className='repoDetailsModal-imageContainer--uploading-loadingSpinner' />
											</div>									
										</div>
									}						
								
							</div>


							{/* REPO DESCRIPTION */}
							<div className='repoDetailsModal-input repoDetailsModal-input--description'>
								<div className='repoDetailsModal-input-label'>
									Description {repo && repo.published && repoDescription.length === 0 && <span className='repoDetailsModal-input-label-optional'>&nbsp;(optional)</span>}

									<div className={'repoDetailsModal-input-label-characterCount ' + ((maxDescriptionLength === repoDescription.length) ? ' repoDetailsModal-input-label-characterCount--max' : '') + ((repoDescription.length > 200) ? ' repoDetailsModal-input-label-characterCount--show' : '')}>
										{repoDescription.length} / {maxDescriptionLength}
									</div>				

								</div>
								<textarea
									onFocus={function(e) {
					    				var val = e.target.value
					    				e.target.value = ''
					    				e.target.value = val
					 	 			}}		      
									value={repoDescription}
									onPaste={this.onPaste}
									className={"" + ((repoDescription.length === 0) ? " is--empty" : " is--nonEmpty")}			      
									placeholder='Give your pack a snazzy description'
									onChange={this.props.handleDescriptionChange}
									maxLength={maxDescriptionLength}
									onKeyDown={this.handleRepoDescriptionKeyDown}
								/>
							</div>

						</div>
					</React.Fragment>
				}

				{showExpanded && expandedTab === 'subject' && 
					<EditRepoModalEditSubject
						selectedSubjectId={this.props.selectedSubjectId}
						setSelectedSubjectId={this.props.setSelectedSubjectId}
						selectedSubjectParentId={this.props.selectedSubjectParentId}
						setSelectedSubjectParentId={this.props.setSelectedSubjectParentId}
						subjectOtherString={this.props.subjectOtherString}
						setSubjectOtherString={this.props.setSubjectOtherString}
					/>
				}

				{showExpanded && expandedTab === 'gradeLevel' && 
					<EditRepoModalEditGradeLevel
						selectGradeLevel={this.props.selectGradeLevel}
						selectOtherGradeLevel={this.props.selectOtherGradeLevel}
						selectStaff={this.props.selectStaff}
						selectHigherEducation={this.props.selectHigherEducation}
						validateGradeLevels={this.props.validateGradeLevels}
						selectedGradeLevels={this.props.selectedGradeLevels}
						selectedOtherGradeLevel={this.props.selectedOtherGradeLevel}
						selectedStaff={this.props.selectedStaff}
						selectedHigherEducation={this.props.selectedHigherEducation}
						language={this.props.language}
						selectLanguage={this.props.selectLanguage}
						setGradeOtherString={this.props.setGradeOtherString}
						gradeOtherString={this.props.gradeOtherString}



					/>
				}




				{/* ERROR SPACE FOR GRADE LEVEL RANGE REQUIREMENT */}				
				{this.props.error &&
					<div className='repoDetailsModal-errorMessageContainer'>															
						<div className='repoDetailsModal-errorMessage'>
							{this.props.error}
						</div>
					</div>
				}

			
				
				<Button 
					label={repo ? 'Done' : 'Create Pack'}
					size='xLarge'
					name='repoDetailSubmit'
					inputType='submit'
					disabled={this.props.submitLoading}
					submitLoading={this.props.submitLoading} 
					color={submitButtonDisabled ? 'gray' : 'blue'}
					onClickFunction={this.props.clickDoneButton}
				/>

				

			</div>
		)
	}
}


export default withRouter(EditRepoModal)




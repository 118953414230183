import React, { Component } from 'react'
import { connect } from 'react-redux' 
import RepoContainer from './RepoContainer'
import RepoLoadingPage from '../../components/repo/RepoLoadingPage'
import find from 'lodash/find'
 
 
class RepoStandardViewContainer extends Component {

	render() {
		if(this.props.currentRepo){
			return <RepoContainer repoId={this.props.match.params.id}/>		
		}else return <RepoLoadingPage pageNotFound={this.props.repos && this.props.repos.length !==0}/>
	}
}




function mapStateToProps(state,ownProps) {
	const currentRepo=find(state.repos, {'id':ownProps.match.params.id})
	return {
		currentRepo:currentRepo,
		repos:state.repos
	  }
}
 


export default connect(
	mapStateToProps,
	{ })(RepoStandardViewContainer)

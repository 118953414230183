import React from 'react'
import { Spring, animated } from 'react-spring/renderprops' 
import EditorIcon from '../../misc/EditorIcons'
import Slider, { SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'

const { Handle } = Slider

const handle = props => {
	const { value, dragging, index, ...restProps } = props;
	return (
		<SliderTooltip
			prefixCls="slide-media--sound-player-seekBar-tooltip"
			overlay={`${value}`}
			visible={false}
			placement="top"
			key={index}
		>
			<Handle value={value} {...restProps} />
		</SliderTooltip>
	)
}


function calculateItemLayout(nowPlayingLayout,choiceIndex){ //TODO clean this up
	// SCALE INVARIANCE
	// this is about keeping buttons the same size at any screen size
	
	const slideWidth = nowPlayingLayout.slideDimensions.width
	const slideScale = slideWidth / 1280

	// Center play button
	const centerPlayButtonScaleEffect =  1.5 // we scale up the buttons by a fixed amount, should be solved with unique CSS maybe
	const centerPlayButtonScale = centerPlayButtonScaleEffect * slideScale

	// Seekbar at bottom
	const seekBarContainerScaleEffect = 1.5
	const seekBarContainerScale = seekBarContainerScaleEffect * slideScale

	// VIEWPORT DIMENSIONS
	const viewportWidth = nowPlayingLayout.viewportWidth
	const viewportHeight = nowPlayingLayout.viewportHeight    

	// applied to bottom left and right
	const windowZoomItemInternalPadding = 20 // PASS IN FROM SLIDE DEFINITIONS

	// height of choice letterblock at 1280 (120px), plus a bit of padding as we're only applying internal padding to bottom
	const unscaledHeightChoiceLettersPanel = 120
	const windowZoomChoiceLettersTopPadding = (nowPlayingLayout.slideDimensions.width / 1280 * unscaledHeightChoiceLettersPanel) 

	//
	// START ZOOM ITEM DIMENSIONS

	// Find position of original on-slide video container
	const zoomableSoundPlayer = document.getElementById(`choice${choiceIndex}SoundPlayerZoomable`)

	let rect
	if(zoomableSoundPlayer){
		rect = zoomableSoundPlayer.getBoundingClientRect()
	}
 
	let itemStartZoomWidth = 0
	let itemStartZoomHeight = 0
	let itemStartZoomX = 0
	let itemStartZoomY = 0

	if(rect){
		itemStartZoomWidth = rect.width
		itemStartZoomHeight = rect.height
		itemStartZoomX = rect.x
		itemStartZoomY = rect.y
	}

	const itemAspect = itemStartZoomWidth / itemStartZoomHeight

	const scaledSeekBarContainerWidth = itemStartZoomWidth / seekBarContainerScale
	const scaledSeekBarContainerHeight = (itemStartZoomHeight * 0.3) / seekBarContainerScale
 
	//
	// END ZOOM ITEM DIMENSIONS    

		// find maximum available width and height
		const itemEndZoomMaxWidth = viewportWidth - windowZoomItemInternalPadding - windowZoomItemInternalPadding
		const itemEndZoomMaxHeight = viewportHeight - windowZoomItemInternalPadding - windowZoomChoiceLettersTopPadding

		const itemEndZoomMaxAvailableAspect = itemEndZoomMaxWidth / itemEndZoomMaxHeight

		// find actual fitting item width and height
		let itemEndZoomWidth 
		let itemEndZoomHeight

		if(itemEndZoomMaxAvailableAspect >= itemAspect){ // screen wider than item
			itemEndZoomHeight = itemEndZoomMaxHeight
			itemEndZoomWidth = itemEndZoomHeight * itemAspect
		}
		if(itemEndZoomMaxAvailableAspect < itemAspect){ // screen narrower than item
			itemEndZoomWidth = itemEndZoomMaxWidth
			itemEndZoomHeight = itemEndZoomWidth / itemAspect
		}

		// find end-state X and Y, this is to viewport
		// basically center, for Y we also need to offset with the top padding
		const itemEndZoomX = (viewportWidth - itemEndZoomWidth) / 2
		const itemEndZoomY = Math.max(((viewportHeight - itemEndZoomHeight) / 2), windowZoomChoiceLettersTopPadding)


	//
	// ZOOM START --> ZOOM END
	
	// SCALE FACTOR
	const itemEndZoomScale = itemEndZoomWidth / itemStartZoomWidth

	// TRANSFORM ORIGIN (this effectively translates position of slide)
	const transformOriginX = (itemStartZoomX - itemEndZoomX) / (itemEndZoomWidth - itemStartZoomWidth)
	const transformOriginY = (itemStartZoomY - itemEndZoomY) / (itemEndZoomHeight - itemStartZoomHeight)
	
	
	//
	// STYLING IS FIXED
	// zooming also repositions slide through transform origin location

	const itemStyle = {
		width: `${itemStartZoomWidth}px`, 
		height: `${itemStartZoomHeight}px`,
		left: `${itemStartZoomX}px`,
		top: `${itemStartZoomY}px`,             
		transformOrigin: `${transformOriginX * 100}% ${transformOriginY * 100}%`,
	}

	return{
		itemStyle:itemStyle,
		itemEndZoomScale:itemEndZoomScale,
		centerPlayButtonScale:centerPlayButtonScale,
		scaledSeekBarContainerWidth:scaledSeekBarContainerWidth,
		scaledSeekBarContainerHeight:scaledSeekBarContainerHeight,
		seekBarContainerScale:seekBarContainerScale
	}
}

class LiveViewChoiceSoundPlayer extends React.Component{

	constructor(props){
		super(props)    
		this.playSound=this.playSound.bind(this)
		this.pauseSound=this.pauseSound.bind(this)
		this.startTimer=this.startTimer.bind(this)
		this.seekTo=this.seekTo.bind(this)
		this.restartAudio=this.restartAudio.bind(this)
		this.updateControlPlayback=this.updateControlPlayback.bind(this)
		this.stopTimer=this.stopTimer.bind(this)
		this.skipPlayerCurrentTime=this.skipPlayerCurrentTime.bind(this)
		this.zoomAnimationFinished=this.zoomAnimationFinished.bind(this)    
		this.calculateLayout=this.calculateLayout.bind(this)

		const url=`${props.sound.deliveryUrl}.mp3`

		this.audioElement = new Audio(url)
		this.audioElement.addEventListener("ended", function(){
			this.audioElement.currentTime = 0
			this.setState({isPlaying:false,playerCurrentTime:0})
			this.updateControlPlayback(false)
			this.stopTimer()
		}.bind(this))
		this.timer=null

		this.state={
			zoomActivePhase:false,
			carouselIsOpen:false,
			playerCurrentTime:0,
			duration:props.sound.end-props.sound.start,
			itemStyle:{},
			itemEndZoomScale:0,
			centerPlayButtonScale:1,
			scaledSeekBarContainerWidth:0,
			scaledSeekBarContainerHeight:0,
			seekBarContainerScale:1
		}		
	}

	componentDidMount(){
		this.calculateLayout()
	}

	componentDidUpdate(prevProps) {
		if ((this.props.layoutKey !== prevProps.layoutKey) || (this.props.showStudentList !== prevProps.showStudentList)) {
			this.calculateLayout()
		 }    
	}

	calculateLayout(){   
		const itemLayout=calculateItemLayout(this.props.nowPlayingLayout,this.props.choiceIndex)
		this.setState({
			itemStyle:itemLayout.itemStyle,
			itemEndZoomScale:itemLayout.itemEndZoomScale,
			centerPlayButtonScale:itemLayout.centerPlayButtonScale,
			scaledSeekBarContainerWidth:itemLayout.scaledSeekBarContainerWidth,
			scaledSeekBarContainerHeight:itemLayout.scaledSeekBarContainerHeight,
			seekBarContainerScale:itemLayout.seekBarContainerScale
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.choiceMediaZoomed !== this.props.choiceMediaZoomed){
			if(nextProps.choiceMediaZoomed){
				this.setState({zoomActivePhase:true})
			}	   
		}
		if(nextProps.playbackItem===nextProps.itemName && this.props.clientPlaybackCommand===null && nextProps.clientPlaybackCommand){
			this.props.clearPlaybackCommand()
			if(nextProps.clientPlaybackCommand.command==='skip forward'){
				this.skipPlayerCurrentTime('forwards',nextProps.clientPlaybackCommand.length/1000)
			}
			else{
				this.skipPlayerCurrentTime('backwards',nextProps.clientPlaybackCommand.length/1000)
			}
		}
		if(nextProps.playbackItem===nextProps.itemName && this.props.pageVisibilityState==='visible' && nextProps.nowPlayingModalVisible===true){
			if(nextProps.isPlaying){
				if(!this.state.isPlaying){
					this.playSound(true)
				}
			}else{
				if(this.state.isPlaying){
					this.pauseSound(true)
				}
			}
		}
		if(nextProps.playbackItem !== nextProps.itemName){
			if(this.state.isPlaying){
				this.pauseSound(true)
			}
		}
		if(this.props.pageVisibilityState==='visible' && nextProps.pageVisibilityState==='hidden'){
			if(this.state.isPlaying){
				this.pauseSound()
			}
		}    
	}

	skipPlayerCurrentTime(skipDirection,skipAmount){
		const currentTime=this.audioElement.currentTime
		let newCurrentTime=currentTime
		if(skipDirection==='forwards'){
			newCurrentTime=Math.min(newCurrentTime+skipAmount,this.props.sound.end)
		}else{
			newCurrentTime=Math.max(newCurrentTime-skipAmount,0)
		}
		this.seekTo(newCurrentTime)
	}

	updateControlPlayback(isPlaying){
		this.props.updateControlPlayback(this.props.itemName,isPlaying)
	}

	componentWillUnmount() {
		this.audioElement.pause()
		this.stopTimer()
	}
 
	stopTimer(){
		clearInterval(this.timer)
		this.timer=null
	}

	updatePlayerCurrentTime(){
		const currentTime=this.audioElement.currentTime
		this.setState({playerCurrentTime:currentTime})
	}

	startTimer(){
		this.timer = setInterval(() => {
			this.updatePlayerCurrentTime()
		}, 100)
	}

	playSound(commandFromControl){
		this.props.hideAutoplayFailedAlert()
		if(commandFromControl!==true){
			this.updateControlPlayback(true)
		}else{
			this.setState({isPlaying:true})
			this.startTimer()
			this.audioElement.play().catch(error => {
					this.props.showAutoplayFailedAlert()
					this.setState({isPlaying:false})
					this.stopTimer()
					this.updateControlPlayback(false)
					 if(window.analytics){
						window.analytics.track('Show autoplay failed alert',{
							mediaType:'sound',
							playbackItem:this.props.itemName
						})
					}
			})
		}
	}

	pauseSound(commandFromControl){
		if(commandFromControl!==true){
			this.updateControlPlayback(false)
		}else{
			this.stopTimer()
			this.setState({isPlaying:false})
			this.audioElement.pause()
		}
	}

	seekTo(seconds){
		this.audioElement.currentTime=seconds
		if(!this.timer){
			this.setState({playerCurrentTime:seconds})
		}
	}

	restartAudio(){
		this.audioElement.currentTime=0
	}

	zoomAnimationFinished(){
		if(!this.props.choiceMediaZoomed){
			this.setState({zoomActivePhase:false})
			this.setState({carouselIsOpen:false})
		}
		if(this.props.choiceMediaZoomed){
			this.setState({carouselIsOpen:true})
		}
	} 

	render(){		

		const {nowPlayingLayout, animationDisabled,choiceIndex, sound, choiceCount, choiceMediaZoomed,activeZoomedChoiceIndex, isLastZoomedItem} = this.props 
		const {carouselIsOpen} = this.state
		const {isPlaying} = this.state
		const {itemStyle,itemEndZoomScale,centerPlayButtonScale,scaledSeekBarContainerWidth,scaledSeekBarContainerHeight,seekBarContainerScale}=this.state

		// PLAYBACK STUFF  
		const progress = this.state.playerCurrentTime
		const lengthSeconds = sound.end - sound.start // passed through
	 // VIEWPORT DIMENSIONS
		const viewportWidth = nowPlayingLayout.viewportWidth
		//
		// CAROUSEL OFFSET

		const pageWidth = viewportWidth
		const indexDifferent = choiceIndex - activeZoomedChoiceIndex
		const isActiveZoomedChoice = choiceIndex === activeZoomedChoiceIndex

		const carouselOffset = pageWidth * indexDifferent  

		let zoomMediaScaledTranslateXCarousel = carouselOffset / itemEndZoomScale
		
		let setDurationTo0 = false

		if(!carouselIsOpen && !isActiveZoomedChoice){
			setDurationTo0 = true
		}

		if(carouselIsOpen && !choiceMediaZoomed && !isActiveZoomedChoice){
			setDurationTo0 = true
		}
		
		const setDelay = !carouselIsOpen && !isActiveZoomedChoice

		// Z-INDEX MANAGEMENT  
		const moveItemBelowDarkBG = (!choiceMediaZoomed && !isLastZoomedItem) || (!carouselIsOpen && !isLastZoomedItem)

		// FADE OUT IF OTHER CHOICE PLAYING
		const otherChoicePlaying = this.props.choicesPlaying && !this.props.isPlayingChoice && !choiceMediaZoomed
		
		return(			
			
			<React.Fragment>
				<Spring
					onRest={this.zoomAnimationFinished}
					native           
					config={{
						duration: setDurationTo0 ? 0 : undefined,
						delay: setDelay ? 350 : undefined
					}}
					from={{ transform: 'scale(1) translateX(0px)' }}
					to={{ transform: (choiceMediaZoomed && itemEndZoomScale) ? `scale(${itemEndZoomScale}) translateX(${zoomMediaScaledTranslateXCarousel})` : 'scale(1) translateX(0px)'}}          
					immediate={animationDisabled}
				>
					{props => 
						<animated.div onClick={this.props.toggleZoomMedia} key={choiceIndex} style={{...itemStyle, ...props}}  className={'liveView-mediaItem liveView-mediaItem--choiceSoundPlayer ' + (isLastZoomedItem ? ' liveView-mediaItem--isLastZoomedItem ' : '') + (moveItemBelowDarkBG ? ' liveView-mediaItem--moveItemBelowDarkBG ' : '') + (otherChoicePlaying ? ' liveView-mediaItem--choiceSoundPlayer--otherChoicesPlaying ' : '')}>

							<div style={{transform:`scale(${centerPlayButtonScale})`}} className='slide-choice-media--sound-center'>            
								{isPlaying &&
									<button onClick={this.pauseSound} className='slide-choice-media--sound-playPauseBtn slide-choice-media--sound-playPauseBtn--playing'>
										<EditorIcon name='playbackPause' /> 
									</button>
								}
								{!isPlaying &&
									<button onClick={this.playSound} className='slide-choice-media--sound-playPauseBtn slide-choice-media--sound-playPauseBtn--paused'>
										<EditorIcon name='playbackPlay' />
										<EditorIcon name='insertSound' /> 
									</button>
								}            
							</div>              

							<div style={{width: `${scaledSeekBarContainerWidth}px`, height:`${scaledSeekBarContainerHeight}px`, transform:`scale(${seekBarContainerScale})`}} className='slide-choice-media--sound-bottom'>
								{(isPlaying || progress > 0) &&
									<div className={'slide-choice-media--sound-bottom-seekBarContainer ' + (choiceCount ? `slide-choice-media--sound-bottom-seekBarContainer--choiceCount--${choiceCount}`:'')}>
										<Slider 
											onChange={(value)=>{this.seekTo(value)}}
											value={this.state.playerCurrentTime} 
											step={0.01}
											min={0} 
											max={lengthSeconds} 
											defaultValue={0} 
											handle={handle}
										/>              
									</div>
								}                        
							</div>							
																 
						</animated.div>
					}
				</Spring>		
			</React.Fragment>

		)
	}
}


export default LiveViewChoiceSoundPlayer


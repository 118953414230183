import React from 'react'
 


class StaticSlideTestPanelButton extends React.Component{
	render(){
		const {label, active} = this.props
		return(
			<button onClick={this.props.onClickFunction} className={'staticSlideTestPanel-btn ' + (active ? ' staticSlideTestPanel-btn--active ':' staticSlideTestPanel-btn--inactive ') + (this.props.activeGray ? ' staticSlideTestPanel-btn--activeGray':'')}>
				{label}
			</button>
		)
	}
}
					

class StaticSlideTestPanelDataRow extends React.Component{
	render(){
		const {label, value, notPx} = this.props
		return(
			<div className='staticSlideTestPanel-section-row staticSlideTestPanel-section-row--value'>
				<div className='staticSlideTestPanel-section-row-label'>
					{label}
				</div>
				{!notPx &&
					<div className='staticSlideTestPanel-section-row-value'>
						{value}px
					</div> 
				}           
				{notPx &&
					<div className='staticSlideTestPanel-section-row-value'>
						{value}
					</div> 
				}           
			</div>          
		)
	}
}
					

class StaticSlideTestPanel extends React.Component{

	constructor(props){
		super()
		// this.handleKeyPress=this.handleKeyPress.bind(this)
		// this.sizeChanged=this.sizeChanged.bind(this)
		// this.onSelectImage=this.onSelectImage.bind(this)

		this.state=({
			// images:props.images,
			// index:null,
			// image:null,
			// searchTerm:props.searchTerm
		})
	}


	render(){

		const {windowWidth, windowHeight, setListSlideWidth, listSlideWidth,displayType} = this.props    
		
		return(
			<div className={'staticSlideTestPanel ' + (this.props.colorScheme ? `staticSlideTestPanel--${this.props.colorScheme}`:'')}>
				{/* TAKE OUT FOR VIDEO QA
				<div className='staticSlideTestPanel-section staticSlideTestPanel-section--btnRow'>
					<StaticSlideTestPanelButton
						label='Carousel'
						active={displayType === 'carousel'}
						onClickFunction={()=>{setDisplayType('carousel')}}
					/>
					<StaticSlideTestPanelButton
						label='List'            
						active={displayType === 'list'}
						onClickFunction={()=>{setDisplayType('list')}}
					/>          
				</div> 
				*/}

				{displayType === 'list' && 
					<div className='staticSlideTestPanel-section staticSlideTestPanel-section--btnRow staticSlideTestPanel-section--btnRow--manyBtn'>            
						<StaticSlideTestPanelButton
							label='320'
							active={listSlideWidth === 320}
							onClickFunction={()=>{setListSlideWidth(320)}}
						/>          
						<StaticSlideTestPanelButton
							label='640'      
							active={listSlideWidth === 640}
							onClickFunction={()=>{setListSlideWidth(640)}}
						/>
						<StaticSlideTestPanelButton
							label='960'                    
							active={listSlideWidth === 960}   
							onClickFunction={()=>{setListSlideWidth(960)}}
						/>
						<StaticSlideTestPanelButton
							label='1280'                   
							active={listSlideWidth === 1280}    
							onClickFunction={()=>{setListSlideWidth(1280)}}
						/>
						<StaticSlideTestPanelButton
							label='1920'          
							active={listSlideWidth === 1920}             
							onClickFunction={()=>{setListSlideWidth(1920)}}
						/>
					</div> 
				}
				
				
				{displayType === 'carousel' && 
					<div className='staticSlideTestPanel-section staticSlideTestPanel-section--btnRow'>            
						<StaticSlideTestPanelButton
							label='Previous'
							active={this.props.activeQuestionIndex>0}
							onClickFunction={()=>{this.props.changeActiveQuestion('previous')}}
						/>          
						<StaticSlideTestPanelButton
							label='Next'              
							active={this.props.activeQuestionIndex<this.props.set.questions.length-1}
							onClickFunction={()=>{this.props.changeActiveQuestion('next')}}
							// onClickFunction= Go to previous question
						/>
					</div> 
				}

					{/*

				<div className='staticSlideTestPanel-section'>
					<div className='staticSlideTestPanel-section-title'>
						Reporting
					</div>
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							Show Correct
						</div>
						<StaticSlideTestPanelButton
							label='True'             
							active={this.props.staticSlideShowCorrect}    
							onClickFunction={()=>{this.props.setStaticSlideShowCorrect(true)}}              
						/>
						<StaticSlideTestPanelButton
							label='False'             
							active={!this.props.staticSlideShowCorrect} 
							activeGray
							onClickFunction={()=>{this.props.setStaticSlideShowCorrect(false)}}              
						/>                      
					</div>
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							Report View
						</div>
						<StaticSlideTestPanelButton
							label='True'             
							active={this.props.staticSlideReportView} 
							onClickFunction={()=>{this.props.setStaticSlideReportView(true)}}              
						/>
						<StaticSlideTestPanelButton
							label='False'              
							active={!this.props.staticSlideReportView} 
							activeGray            
							onClickFunction={()=>{this.props.setStaticSlideReportView(false)}}    
						/>                      
					</div>
					
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							isAskScreen
						</div>
						<StaticSlideTestPanelButton
							label='True'              
							active={this.props.staticSlideIsAskScreen} 
							onClickFunction={()=>{this.props.setStaticSlideIsAskScreen(true)}}     
						/>
						<StaticSlideTestPanelButton
							label='False'              
							active={!this.props.staticSlideIsAskScreen}
							activeGray               
							onClickFunction={()=>{this.props.setStaticSlideIsAskScreen(false)}}     
						/>                      
					</div>
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							isScanScreen
						</div>
						<StaticSlideTestPanelButton
							label='True'              
							active={this.props.staticSlideIsScanScreen} 
							onClickFunction={()=>{this.props.setStaticSlideIsScanScreen(true)}}     
						/>
						<StaticSlideTestPanelButton
							label='False'              
							active={!this.props.staticSlideIsScanScreen}
							activeGray               
							onClickFunction={()=>{this.props.setStaticSlideIsScanScreen(false)}}     
						/>                      
					</div>
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							isReviewScreen
						</div>
						<StaticSlideTestPanelButton
							label='True'              
							active={this.props.staticSlideIsReviewScreen} 
							onClickFunction={()=>{this.props.setStaticSlideIsReviewScreen(true)}}     
						/>
						<StaticSlideTestPanelButton
							label='False'              
							active={!this.props.staticSlideIsReviewScreen}
							activeGray               
							onClickFunction={()=>{this.props.setStaticSlideIsReviewScreen(false)}}     
						/>                      
					</div>
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							showResponseCounts
						</div>
						<StaticSlideTestPanelButton
							label='True'              
							active={this.props.staticSlideShowResponseCounts} 
							onClickFunction={()=>{this.props.setStaticSlideShowResponseCounts(true)}}     
						/>
						<StaticSlideTestPanelButton
							label='False'              
							active={!this.props.staticSlideShowResponseCounts}
							activeGray               
							onClickFunction={()=>{this.props.setStaticSlideShowResponseCounts(false)}}     
						/>                      
					</div>
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							Always Show Letterblock
						</div>
						<StaticSlideTestPanelButton
							label='True'           
							active={this.props.staticSlideAlwaysShowLetterBlock}
							onClickFunction={()=>{this.props.setStaticSlideAlwaysShowLetterBlock(true)}}                   
						/>
						<StaticSlideTestPanelButton
							label='False'              
							active={!this.props.staticSlideAlwaysShowLetterBlock}
							activeGray
							onClickFunction={()=>{this.props.setStaticSlideAlwaysShowLetterBlock(false)}}     
						/>                      
					</div>
														
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							Show Graph
						</div>
						<StaticSlideTestPanelButton
							label='True'           
							active={this.props.staticSlideShowGraph}
							onClickFunction={()=>{this.props.setStaticSlideShowGraph(true)}}                   
						/>
						<StaticSlideTestPanelButton
							label='False'              
							active={!this.props.staticSlideShowGraph}
							activeGray
							onClickFunction={()=>{this.props.setStaticSlideShowGraph(false)}}     
						/>                      
					</div>
					

					
					

					<br/>
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							Choice A Responses
						</div>
						<input 
							className='staticSlideTestPanel-section-row-input' 
							value={this.props.choiceAResponseCount}
							onChange={()=>{this.props.setStaticSlideChoiceAResponseCount(this.props.choiceAResponseCount)}}
						/>              
					</div>
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							Choice B Responses
						</div>
						<input 
							className='staticSlideTestPanel-section-row-input' 
							value={this.props.choiceBResponseCount}              
						/>              
					</div>
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							Choice C Responses
						</div>
						<input 
							className='staticSlideTestPanel-section-row-input' 
							value={this.props.choiceCResponseCount}              
						/>              
					</div>
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							Choice D Responses
						</div>
						<input 
							className='staticSlideTestPanel-section-row-input' 
							value={this.props.choiceDResponseCount}
							
						/>              
					</div>

				</div>

					*/}

			 
				{displayType !== 'carousel' && 
					<div className='staticSlideTestPanel-section'>
						<div className='staticSlideTestPanel-section-title'>
							Layout
						</div>
						<StaticSlideTestPanelDataRow            
							label='Window Width'
							value={windowWidth}            
						/>
						<StaticSlideTestPanelDataRow
							label='Window Height'
							value={windowHeight}            
						/>
						<br/>
						<StaticSlideTestPanelDataRow
							label='Slide Container Width'
							value={this.props.slideContainerWidth}            
						/>
						<StaticSlideTestPanelDataRow
							label='Slide Container Height'
							value={this.props.slideContainerHeight}            
						/>
						<br/>            
						<StaticSlideTestPanelDataRow
							label='Slide Width'
							value={this.props.slideWidth}            
						/>
						<StaticSlideTestPanelDataRow
							label='Slide Height'
							value={this.props.slideWidth / 1.6}            
						/>
						<StaticSlideTestPanelDataRow
							label='Slide Scale'
							value={this.props.slideWidth / 1280}
							notPx
						/>
						<br/>
						<StaticSlideTestPanelDataRow
							label='Slide X (to container)'
							value={this.props.slideXToContainer}            
						/>
						<StaticSlideTestPanelDataRow
							label='Slide Y (to container)'
							value={this.props.slideYToContainer}            
						/>
						<StaticSlideTestPanelDataRow
							label='Slide X (to screen)'
							value={this.props.slideXToScreen}            
						/>
						<StaticSlideTestPanelDataRow
							label='Slide Y (to screen)'
							value={this.props.slideYToScreen}            
						/>            
						
					</div>
				}



				<div className='staticSlideTestPanel-section'>
					<div className='staticSlideTestPanel-section-title'>
						Interactivity
					</div>
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							Media Play
						</div>
						<StaticSlideTestPanelButton
							label='Playable'
							active={this.props.staticSlideMediaIsPlayable}
							onClickFunction={()=>{this.props.setStaticSlideMediaIsPlayable(true)}}
						/>
						<StaticSlideTestPanelButton
							label='Static'              
							active={!this.props.staticSlideMediaIsPlayable}
							onClickFunction={()=>{this.props.setStaticSlideMediaIsPlayable(false)}}

						/>                      
					</div>
					{/* COMMENTING OUT bEFORE OF NP STUFF 
					<div className='staticSlideTestPanel-section-row'>
						<div className='staticSlideTestPanel-section-row-label'>
							Media Zoom
						</div>
						<StaticSlideTestPanelButton
							label='Zoomable'
							active={this.props.staticSlideMediaIsZoomable}
							onClickFunction={()=>{this.props.setStaticSlideMediaIsZoomable(true)}}
						/>
						<StaticSlideTestPanelButton
							label='Static'      
							active={!this.props.staticSlideMediaIsZoomable}
							onClickFunction={()=>{this.props.setStaticSlideMediaIsZoomable(false)}}
						/>     
					</div>
					*/}
				</div>

				
				
				
				{/*}
				<div className='staticSlideTestPanel-section staticSlideTestPanel-section--btnRow'>
					<StaticSlideTestPanelButton
						label='Light'      
						active      
						// onClickFunction= Set colorscheme to light
					/>          
					<StaticSlideTestPanelButton
						label='Dark'
						// onClickFunction= Set colorscheme to dark
					/>          
				</div>
				*/}

				<div className='staticSlideTestPanel-section-spacer' />

			</div>
		)
	}
}
					



export default StaticSlideTestPanel

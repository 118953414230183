module.exports = [
	{grade:'pre-k',
		label:'Pre-K',
		ageLabel:'Under 5',
		secondRowLabel:'',
		longLabel:'Prek'},
	{grade:'k',
		label:'K',
		ageLabel:'Age 5/6',
		secondRowLabel:'',
		longLabel:'Kindergarten'},
	{grade:'1st',
		label:'1st',
		ageLabel:'Age 6/7',
		secondRowLabel:'Grade',
		longLabel:'1st Grade'},
	{grade:'2nd',
		label:'2nd',
		ageLabel:'Age 7/8',
		secondRowLabel:'Grade',
		longLabel:'2nd Grade'},
	{grade:'3rd',
		label:'3rd',
		ageLabel:'Age 8/9',
		secondRowLabel:'Grade',
		longLabel:'3rd Grade'},
	{grade:'4th',
		label:'4th',
		ageLabel:'Age 9/10',
		secondRowLabel:'Grade',
		longLabel:'4th Grade'},
	{grade:'5th',
		label:'5th',
		ageLabel:'Age 10/11',
		secondRowLabel:'Grade',
		longLabel:'5th Grade'},
	{grade:'6th',
		label:'6th',
		ageLabel:'Age 11/12',
		secondRowLabel:'Grade',
		longLabel:'6th Grade'},
	{grade:'7th',
		label:'7th',
		ageLabel:'Age 12/13',
		secondRowLabel:'Grade',
		longLabel:'7th Grade'},
	{grade:'8th',
		label:'8th',
		ageLabel:'Age 13/14',
		secondRowLabel:'Grade',
		longLabel:'8th Grade'},
	{grade:'9th',
		label:'9th',
		ageLabel:'Age 14/15',
		secondRowLabel:'Grade',
		longLabel:'9th Grade'},
	{grade:'10th',
		label:'10th',
		ageLabel:'Age 15/16',
		secondRowLabel:'Grade',
		longLabel:'10th Grade'},
	{grade:'11th',
		label:'11th',
		secondRowLabel:'Grade',
		ageLabel:'Age 16/17',
		longLabel:'11th Grade'},
	{grade:'12th',
		label:'12th',
		secondRowLabel:'Grade',
		ageLabel:'Age 17/18',
		longLabel:'12th Grade'}]



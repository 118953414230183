import React, { Component } from "react";

class DraggableLetterMarker extends Component {

	render() {
		const {markerSize}=this.props    
		return (
			<div style={{width: `${markerSize}px`, height: `${markerSize}px`}} className={'editor-editImageModal-draggableMarker ' + (this.props.name ? `editor-editImageModal-draggableMarker--${this.props.name}`:'') + (this.props.initial ? ' editor-editImageModal-draggableMarker--initial':'')}>     			
				<span>{this.props.name}</span>        
				{/* FOR DEV
				{this.props.xPosition && this.props.yPosition &&
					<div className='editor-editImageModal-draggableMarker-position'>
						<div className='editor-editImageModal-draggableMarker-position-pos'>
							X: {this.props.xPosition}
						</div>
						<div className='editor-editImageModal-draggableMarker-position-pos'>
							Y: {this.props.yPosition}
						</div>
							<div className='editor-editImageModal-draggableMarker-position-pos'>
							image:{this.props.inImageContainerSafeArea?'true':'false'}
						</div>
						<div className='editor-editImageModal-draggableMarker-position-pos'>
							crop:{this.props.inCropSafeArea?'true':'false'}
						</div>             
					</div>
				}
				*/}
			</div>
		)
	}
}

class CoverCornerHandle extends Component {
	render() {
		return (
			<div className="editor-editImageModal-markerCanvas-cover-cornerHandleContainer">
				<div className="editor-editImageModal-markerCanvas-cover-cornerHandle" />        
			</div>
		)
	}
}

class CropboxCornerHandle extends Component {
	render() {
		return (
			 <div
				style={{        
					height: "100%",
					width: "100%",        
				}}
				className={"editor-editImageModal-canvas-cropBox-cornerHandle"}        
			/>
		)
	}
}

class CropboxLeftRightEdgeHandle extends Component {
	render() {
		return (
			<div
				style={{                                      
					width: "100%",
					height: "100%",        
				}}
				className="editor-editImageModal-canvas-cropBox-edgeContainer"
			>
				<div className="editor-editImageModal-canvas-cropBox-edge editor-editImageModal-canvas-cropBox-edge--leftRight"/>
			</div>
		)
	}
}

class CropboxTopBottomEdgeHandle extends Component {
	render() {
		return (
			<div
				style={{        
					height: "100%",
					width: "100%",         
				}}
				className="editor-editImageModal-canvas-cropBox-edgeContainer"
			>
				<div className="editor-editImageModal-canvas-cropBox-edge editor-editImageModal-canvas-cropBox-edge--topBottom"/>

			</div>
		)
	}
}

export {
	DraggableLetterMarker,
	CoverCornerHandle,
	CropboxCornerHandle,
	CropboxLeftRightEdgeHandle,
	CropboxTopBottomEdgeHandle
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {getLinkForRepo} from '../../utils/getLinkForRepo'
import Icon from '../misc/Icons'
import {Image} from 'cloudinary-react'
import {formatRepoGradesToString} from '../../utils/formatRepoGradesToString'
import {formatSubjectToString} from '../../utils/formatSubjectToString'

class AuthorPageRepoLink extends Component {

	render() {	
		const {repo} = this.props
		let subject=''
		if(repo.subject){
			subject=formatSubjectToString(repo.subject)
		}
		let gradeString=''
		if(repo.grade){
			gradeString=formatRepoGradesToString(repo.grade)
		}
		
		let repoMetadata = null
		if(repo.subject && repo.grade){
			repoMetadata = `${subject} · ${gradeString}`
		}
		if(repo.subject && !repo.grade){
			repoMetadata = `${subject}`
		}
		if(!repo.subject && repo.grade){
			repoMetadata = `${gradeString}`
		}

		return (
			<Link to={getLinkForRepo(repo)}>
				<div className='authorPageRepoLink'>
					<div className='authorPageRepoLink-repoImageContainer'>
						<Image
							key={repo.id}						
							cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
							publicId={`${repo.image}/repo/${repo.id}`} 
							className='pageHeaderTop-leftBadge-repoImage'											
							width='450'
							height='450'
							crop='fill'
							fetchFormat='auto'
			    		quality='auto'
						/>
					</div>
					<div className='authorPageRepoLink-title'>
						{repo.name}
					</div>

					{repoMetadata &&
						<div className='authorPageRepoLink-metadata'>					
							{repoMetadata}
						</div>
					}

					{repo.description && 
					<div className='authorPageRepoLink-description'>					
						{repo.description}
					</div>
					}

					<div className='authorPageRepoLink-rightArrow'>
						<Icon name='chevron-right' />
					</div>

				</div>
			</Link>
		)
	}
}

export default AuthorPageRepoLink

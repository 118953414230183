import update from 'immutability-helper'

export default function (state=null, action) {

	switch (action.type) {
		
	case 'FETCH_META_SUCCESS':
		return action.response

	case 'UPDATE_META_SUCCESS':
		return action.response

	case 'UPDATE_ONBOARDING_STATUS':
		let newMeta=update(state, 
			{onboardingStatus:{$set:action.onboardingStatus}})
		return newMeta
				
	case 'LOGOUT':
		return null
		
	default:
		return state
	
	}

}



import React, {Component} from 'react'
import { connect } from 'react-redux'

//used e.g. import questions help video
class HelpVideoModal extends Component{
	render(){	
		const {videoUrl}=this.props
		return(	
			<div className='helpVideoModalContainer' >	
				<div className='helpVideoModal' >								
					<iframe class="helpVideoModal-video" 
						src={videoUrl}						
						cc_load_policy="1"
						cc_lang_pref="en" // required to show captions by default
						frameBorder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						rel="0" // only show related videos from same channel
						allowFullScreen
						title='helpVideoModal'
					>
					</iframe>									
				</div>
				<div className='helpVideoModalContainer--BG' onClick={() =>{this.props.closeHelpVideoModal()}} />
			</div>
		)
	}
}

export default connect(
	(state) => ({}),
	{ }
)(HelpVideoModal)
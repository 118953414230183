import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import moment from 'moment'
import _ from 'lodash'

export default function (state=[], action) {
	switch (action.type) {

	case 'FETCH_QUEUE_QUESTION_POLLS_SUCCESS':
		const filteredResponse=filter(action.response, {'hasResponses':false})
		return filteredResponse

	case 'UPDATED_QUESTION_POLL_WITH_PUSHER':
		if(action.pollObject.hasResponses===false){
			const updatedPollIndex= state.findIndex(setPoll => {
				return setPoll.id === action.pollObject.id
			})		
				return [
					...state.slice(0,updatedPollIndex),
					action.pollObject,
					...state.slice(updatedPollIndex + 1)
				]
		}
		else if(action.pollObject.hasResponses===true){
			const updatedPollIndex= state.findIndex(setPoll => {
				return setPoll.id === action.pollObject.id
			})
			if(updatedPollIndex !==-1){
				return [
				...state.slice(0,updatedPollIndex),
				...state.slice(updatedPollIndex + 1)
				]
			}	else return state
			
		}else return state

	case  'FETCH_SINGLE_QUESTION_POLL_SUCCESS':
		if(action.response.hasResponses===false){
			const questionPollIndex= state.findIndex(questionPoll => {
				return questionPoll.id === action.response.id
			})	

		if(questionPollIndex !==-1){
				return [
					...state.slice(0,questionPollIndex),
					action.response,
					...state.slice(questionPollIndex + 1)
				]
			}else{
				return [
					...state,
					action.response
				]
			}
		}
		else return state

	case 'FETCH_POLLS_FOR_A_QUESTION_SUCCESS':
		const filteredPolls=_.filter(action.response, {'hasResponses':false})
		return filteredPolls

	case 'REFRESH_QUESTION_POLLS_SUCCESS':
		const filtered=filter(action.response,{'hasResponses':false})
		let polls=state.slice()

		forEach(filtered, function(modifiedPoll) { 
			
			let indexOfPoll = polls.findIndex(poll => {
				return poll.id === modifiedPoll.id
			})
			if(indexOfPoll !== -1){ 
				polls =[
					...polls.slice(0,indexOfPoll),
					...polls.slice(indexOfPoll + 1)
				]
			}
			polls=[modifiedPoll,...polls]
			
		})
		return polls


	case 'CREATE_QUESTION_POLL_REQUEST'://add a temp poll on request sent
		return [...state,action.data]
    
	case 'CREATE_QUESTION_POLL_SUCCESS'://replace temp poll with the response (has id etc)
		const timeStamp = moment(action.response.planned)
		const isotime=timeStamp.toISOString()
		const indexOfPoll = state.findIndex(questionPoll => {
			return moment(questionPoll.planned).toISOString() === isotime
		})
		if(indexOfPoll !==-1){ //if there was a temporary poll remove it and replace with real poll
			return [
				...state.slice(0,indexOfPoll),
				action.response,
				...state.slice(indexOfPoll + 1)
			]
		}else return [...state,action.response] //if no temp poll just append new poll
 

	case 'CREATE_QUESTION_POLL_FAILURE':		//if request fails remove the temp poll
		const timeStampFailed = moment(action.data.planned)
		const isotimeFailed=timeStampFailed.toISOString()
		const indexOfPollFailed = state.findIndex(questionPoll => {
			return moment(questionPoll.planned).toISOString() === isotimeFailed
		})

		return [
			...state.slice(0,indexOfPollFailed),
			...state.slice(indexOfPollFailed + 1)
		]


	case 'UPDATE_QUESTION_POLL_REQUEST':
		const pollId = action.pollId
		const indexOfUpdateRequestQuestionPoll = state.findIndex(questionPoll => {
			return questionPoll.id === pollId
		})

		//if canceled=true we want to delete the poll from state.  if canceled =false update the poll.
		if(action.data.canceled===true || action.data.hasResponses){
			return [
				...state.slice(0,indexOfUpdateRequestQuestionPoll),
				...state.slice(indexOfUpdateRequestQuestionPoll + 1)
			]}
		else{
			return [
				...state.slice(0,indexOfUpdateRequestQuestionPoll),
				action.data,
				...state.slice(indexOfUpdateRequestQuestionPoll + 1)
			]
		}


	case 'UPDATE_QUESTION_POLL_SUCCESS':
		return state
	
	case 'LOGOUT':
		return []

	default:
		return state

	}
}
import React from 'react'

//When choice is focused show hints with keyboard shortcuts
class ChoiceShortcutHints extends React.Component{  
  render(){   	
  	const {questionCount,slideWidth}=this.props
  	const slideScale = slideWidth / 1280
  	const inverseScale = 1 / slideScale
	  return (
	  	<React.Fragment>
	  	{questionCount === 1 &&
		  	<div style={{transform: `scale(${inverseScale})`}} className='slide-choice-content-shortcutHints'>		  		
		  		{/* GRADED INCOMPLETE */}
		  		<span className='slide-choice-content-shortcutHint slide-choice-content-shortcutHint--incompleteFirst'>
		  			<strong>Shift + Enter</strong> to mark correct. <strong>Tab</strong> to go to next choice. {/* You can shuffle order later. */}
		  		</span>		  		
		  		
		  		<span className='slide-choice-content-shortcutHint slide-choice-content-shortcutHint--incompleteMiddle'>
		  			<strong>Shift + Enter</strong> to mark correct. <strong>Tab</strong> to go to next choice.
		  		</span>
		  				  			  	
		  		<span className='slide-choice-content-shortcutHint slide-choice-content-shortcutHint--incompleteLast'>
		  			{/* <strong>Shift + Enter</strong> to mark correct or A will be auto-marked correct. */}
		  			<strong>Shift + Enter</strong> to mark correct. <strong>TAB</strong> to go to Shuffle Choices.
		  		</span>
		  		{/* GRADED COMPLETE */}
					
		  		{/* Incorrect */}
		  		<span className='slide-choice-content-shortcutHint slide-choice-content-shortcutHint--completeIncorrect'>
		  			<strong>Shift + Enter</strong> to mark this correct instead.
		  		</span>

		  		<span className='slide-choice-content-shortcutHint slide-choice-content-shortcutHint--completeCorrectNotLast'>
		  			<strong>Tab</strong> to go to next choice.
		  		</span>

		  		<span className='slide-choice-content-shortcutHint slide-choice-content-shortcutHint--completeCorrectLast'>
		  			<strong>Tab</strong> to go to Shuffle choices button.
		  		</span>
		  		

			  	{/* SURVEY */}
		  		<span className='slide-choice-content-shortcutHint slide-choice-content-shortcutHint--survey'>		  			
		  			<strong>TAB</strong> to go to next choice.
		  		</span>

		  		<span className='slide-choice-content-shortcutHint slide-choice-content-shortcutHint--surveyLast'>		  			
		  			<strong>TAB</strong> to go to Shuffle choices button.
		  		</span>


		  		{/*}
					<span className='slide-choice-content-shortcutHint--enterLast'>Press <strong>SHIFT + ENTER</strong> to mark correct or Choice A will be auto marked correct.</span>
					<span className='slide-choice-content-shortcutHint--shuffleLater'>You can shuffle order later.</span>
					<span className='slide-choice-content-shortcutHint--enterSwitch'> Press <strong>SHIFT + ENTER</strong> to mark this correct instead.</span>									
					<span className='slide-choice-content-shortcutHint--tab'>Press <strong>TAB</strong> to go to next choice</span>
					<span className='slide-choice-content-shortcutHint--tabLast'>Press <strong>TAB</strong> to go to Shuffle Choices button</span>		  		
					*/}
		  	</div>
	  	}	
	  	</React.Fragment>	    
	  )
	}
}

export default ChoiceShortcutHints
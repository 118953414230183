import React from 'react'
import PropTypes from 'prop-types'
import StaticQuestionSlide from '../../components/slides/StaticQuestionSlide'
import Icon from '../../components/misc/Icons'
import QuestionCountBadge from '../../components/uiKit/QuestionCountBadge'

const navigationInterval=150 //the interval at which preview slide changes if press and hold navigation arrow


class QuestionSlideCarousel extends React.Component{

	shouldComponentUpdate(nextProps,nextState){
		if(this.props.questionPreviewTimestamp !== nextProps.questionPreviewTimestamp){ //Temp for import questions preview
			return true
		}
		if(nextProps.slideWidth!==this.props.slideWidth){
			return true
		}
		if(nextState.previewQuestionIndex!==this.state.previewQuestionIndex){
			return true
		}
		else if((this.props.set === nextProps.set) || (this.props.question === nextProps.question)){
			return false
		}else return true
	}

	constructor(){
		super()
		this.onSliderChange=this.onSliderChange.bind(this)
		this.navigateLeft=this.navigateLeft.bind(this)
		this.navigateRight=this.navigateRight.bind(this)
		this.onMouseUpLeft=this.onMouseUpLeft.bind(this)
		this.onMouseDownLeft=this.onMouseDownLeft.bind(this)
		this.repeatLeft=this.repeatLeft.bind(this)
		this.onMouseUpRight=this.onMouseUpRight.bind(this)
		this.onMouseDownRight=this.onMouseDownRight.bind(this)
		this.repeatRight=this.repeatRight.bind(this)
		this.navigateLeftProcess = undefined
		this.navigateRightProcess = undefined
		this.state={
			previewQuestionIndex:0,
		}		
	}

	componentWillReceiveProps(nextProps){
		if( (typeof nextProps.activeQuestionIndex!=='undefined') && nextProps.activeQuestionIndex !== this.state.previewQuestionIndex){
			this.setState({previewQuestionIndex:nextProps.activeQuestionIndex})
		}
	}	
 
	onSliderChange(value){
		this.setState({previewQuestionIndex:Math.max(value,0)})
	}

	//for navigating through questions in set
	navigateLeft(){ 
		this.setState({previewQuestionIndex:Math.max(this.state.previewQuestionIndex-1,0)})
	}

	repeatLeft() {
		this.navigateLeft()
		this.navigateLeftProcess = setTimeout(this.repeatLeft,navigationInterval)
	}

	onMouseDownLeft() {
		this.repeatLeft()
	}

	onMouseUpLeft() {
		clearTimeout(this.navigateLeftProcess)
	}

	navigateRight(){
		const numOfQuestions=this.props.set.questions.length
		this.setState({previewQuestionIndex:Math.min(this.state.previewQuestionIndex+1,numOfQuestions-1)})
	}

	repeatRight() {
		this.navigateRight()
		this.navigateRightProcess = setTimeout(this.repeatRight,navigationInterval)
	}

	onMouseDownRight() {
		this.repeatRight()
	}

	onMouseUpRight() {
		clearTimeout(this.navigateRightProcess)
	}

	render(){	
		const {question,set,slideWidth,showSetNavigator,isTrash,hideTotalQuestionCount}=this.props
		const {previewQuestionIndex}=this.state

		let numOfQuestions
		let activeQuestion

		if(set){
			numOfQuestions=set.questions.length
			activeQuestion=set.questions[previewQuestionIndex]
		}else if(question){
			numOfQuestions=1
			activeQuestion=question
		}

		let carouselHeight

		const setNavigatorHeight = 20

		if(showSetNavigator){
			carouselHeight = (slideWidth / 1.6) + 2 + setNavigatorHeight    	
		}
		else{
			carouselHeight = (slideWidth / 1.6) + 2
		}

		let slideCarouselStyle={
			width: `${slideWidth}px`,
			height: `${carouselHeight}px`,
		}

		let hidePlayButton = false

		if(isTrash || this.props.preventPlay){
			hidePlayButton = true
		}

		if(set || question){

			return(
				<div style={slideCarouselStyle} className={'slideCarousel ' + (set && showSetNavigator ? 'slideCarousel--hasSetNavigator' : '') }>			
					<div style={{height: `${slideWidth / 1.6 + 2}px`}} className='slideCarousel-slide' >
						{set && (hideTotalQuestionCount || previewQuestionIndex > 0) &&
							<QuestionCountBadge color="gray" questionCount={previewQuestionIndex + 1} />
						}													
						{set && !hideTotalQuestionCount &&
							<QuestionCountBadge questionCount={numOfQuestions} />
						}													
						<StaticQuestionSlide question={activeQuestion} stage='ask' mode='view' displayWidth={slideWidth - 2} previewCard={true} />						
					</div>

					<div style={{height: `${slideWidth / 1.6 + 2}px`}} className='slideCarousel-slideControls' >

						{showSetNavigator && numOfQuestions >1 &&
							<div className='slideCarousel-navBtn slideCarousel-navBtn--previous' onMouseDown={this.onMouseDownLeft} onMouseUp={this.onMouseUpLeft}>	
								<Icon name='chevron-left' />								
							</div>
						}


						{!hidePlayButton &&
							<div className='slideCarousel-playBtnContainer' onClick={(e) => {e.preventDefault()}} >
								<div className='slideCarousel-playBtn' onClick={() => {this.props.play()}}>
									<Icon name='play' />
								</div>
							</div>
						}

						{hidePlayButton &&
							<div className='slideCarousel-playBtnContainer' />
						}




						{showSetNavigator && numOfQuestions >1 &&   
							<div className='slideCarousel-navBtn slideCarousel-navBtn--next' onMouseDown={this.onMouseDownRight} onMouseUp={this.onMouseUpRight}>								
								<Icon name='chevron-right' />							
							</div>
						}				
						
					</div>

					{set && showSetNavigator &&
						<div className='slideCarousel-setNavigator'>
						
						{set.questions.map((question, index) => {						
						
							return(
								<div 
									className={'slideCarousel-setNavigator-itemContainer ' + 
										(index === previewQuestionIndex ? 'slideCarousel-setNavigator-itemContainer--active' : 'slideCarousel-setNavigator-itemContainer--inactive')
									}
									style={{ width: `${(100 / numOfQuestions)}%` }}
									index={index} 
									key={index}
									onClick={()=>this.setState({previewQuestionIndex: index})}
								>

									<div className='slideCarousel-setNavigator-item' />

								</div>											 
							)
						})}
						</div>
					}
							
				</div>
			)
		}else return null
	}
				
}

QuestionSlideCarousel.propTypes = {
	set: PropTypes.object,
	question: PropTypes.object,
}

QuestionSlideCarousel.defaultProps = {
	showSetNavigator: false,
	isTrash:false
}


export default QuestionSlideCarousel



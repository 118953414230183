import {newSetPoll,newQuestionPoll} from '../actions/polls'
import {isSet} from './isSet'
import moment from 'moment'


export function createPoll(item,sectionId,isBatchRequest,plannedTime) {
	const nowDate = moment().toISOString()
	const planned=plannedTime||nowDate
	//check if item is a set or question
	if(item.id){
		if(isSet(item)){
				 const requestBody=
			    {'section':sectionId,
			      'setObject':item.id,
			      'snapshot':null,
			      'snapshotRefreshed':nowDate,
			      'polls':{},
			      'planned':planned,
			      'canceled':false,
			      'archived':false,
			      'clientModified':nowDate,
			      'userCreated':nowDate,
			    }
			return  newSetPoll(requestBody,isBatchRequest)
		}	
		else{
			const questionRequestBody={
				'section':sectionId,
				'question':item.id,
				'snapshot':null,
				'cleared':null,
				'planned':planned,
				'canceled':false,
				'archived':false,
				'clientModified':nowDate,
				'userCreated':nowDate,
			}
			return newQuestionPoll(questionRequestBody,isBatchRequest)
		}
	}else return null
}



import React from 'react';
import EditorIcon from '../../misc/EditorIcons';
import editorSchema from '../../../prosemirror/schema/editorSchema'
import {addQuestionChoice} from '../../../prosemirror/utils/editorActions/addQuestionChoice'
import {updateQuestionTemplate} from '../../../prosemirror/utils/editorActions/updateQuestionTemplate'
import {shuffleChoices} from '../../../prosemirror/utils/editorActions/shuffleChoices'
import {setToTrueFalse} from '../../../prosemirror/utils/editorActions/setToTrueFalse'
import EditorCanvasBottomBarTemplateTooltip from './EditorCanvasBottomBarTemplateTooltip'

class EditorCanvasBottomBar extends React.Component{  

	constructor(){
		super()		
		this.state={    	   	
			templateTooltipTemplate: null,
		}
	}

  render(){
  	const {slideHeight, slideWidth,question,activeQuestion} = this.props  	
  	const choiceCount = question.choices.length
  	let showNewChoice = false
  	if(choiceCount < 4){
  		showNewChoice = true
  	}
  	const hasQuestionMedia=question.media?true:false
  	const questionTemplate=question.layout
  	const templateDefinition=questionTemplate
  	let shuffleChoicesLabel = 'Shuffle Choices'
  	let trueFalseLabel = 'Make True/False'
  	if(showNewChoice){
  		shuffleChoicesLabel = 'Shuffle'  		
  		trueFalseLabel = 'T/F'
  	}
		let hasEditedChoices=false
		activeQuestion.choices.forEach((choice)=>{
			if(choice.body !== '\u2063'){
				hasEditedChoices=true
			}
		})
		const showTrueFalseBtn=!hasEditedChoices
	  return (
  		<div style={{width: `${slideWidth}px`, top:`${slideHeight}px`}} className='editor-canvas-bottomBar'>
  			<div className={'editor-canvas-bottomBar-left ' + (showNewChoice ? 'editor-canvas-bottomBar-left--showNewChoiceBtn' : 'editor-canvas-bottomBar-left--shuffleBtnOnly')}>
  				{!showTrueFalseBtn &&
	  				<button 
	  					id='shuffleQuestionButton'
	  					tabIndex={0}
	  					className='editor-canvas-bottomBar-choiceBtn editor-canvas-bottomBar-choiceBtn--shuffle' 
	  					onClick={()=>{shuffleChoices(editorSchema,window.view.state, window.view.dispatch)}}
	  				>
	  					<EditorIcon name='bottomBarShuffleChoices' />
	  					<span className='editor-canvas-bottomBar-choiceBtn-label'>
	  						{shuffleChoicesLabel}
	  					</span>		  							  					
	  				</button>
  				}
  				{!showTrueFalseBtn &&
  					<div className='editor-canvas-bottomBar-choiceBtn-shuffleFocusTooltip'>
	  					Press <strong>Enter</strong> to shuffle.
	  				</div>
	  			}

  				{showTrueFalseBtn &&
	  				<button 
	  					id='trueFalseButton'
	  					tabIndex={0}
	  					className='editor-canvas-bottomBar-choiceBtn editor-canvas-bottomBar-choiceBtn--trueFalse' 
	  					onClick={setToTrueFalse}
	  				>		  					
	  					<span className='editor-canvas-bottomBar-choiceBtn-label'>
	  						{trueFalseLabel}
	  					</span>
	  					
	  				</button>
  				}


  				{showNewChoice &&
	  				<button className='editor-canvas-bottomBar-choiceBtn editor-canvas-bottomBar-choiceBtn--addChoice' onClick={()=>{addQuestionChoice()}}>	  					
	  					<EditorIcon name='bottomBarAddChoice' />
	  					<span className='editor-canvas-bottomBar-choiceBtn-label'>
	  						Add Choice
	  					</span>
	  				</button>
  				}

  				{/*
  				<button disabled={isSaving} className='editor-canvas-bottomBar-resetButtonTemp' onClick={()=>{this.props.saveChanges()}}>	  					
  					{isSaving?'saving...':'Save'}
  				</button>
  				*/}

  				{/*}

  				<button className='editor-canvas-bottomBar-sidePanelButtonTemp' onClick={this.props.toggleShowSidePanel}>	  					
  					SP
  				</button>
  			*/}
  				

  			</div>
  			
  			<div className='editor-canvas-bottomBar-center'>
  				{!hasQuestionMedia && 
	  				<div className='editor-canvas-bottomBar-templateBtnGroup'>
	  					<button className={'editor-canvas-bottomBar-templateBtn ' + (templateDefinition === 'bodyLeft' ?  'editor-canvas-bottomBar-templateBtn--active' : 'editor-canvas-bottomBar-templateBtn--inactive')} onClick={()=>{updateQuestionTemplate('bodyLeft')}} onMouseEnter={()=>{this.setState({templateTooltipTemplate: 'bodyLeft'})}}>
	  						<EditorIcon name='templateLeft'/>		  						
	  						<div className='editor-canvas-bottomBar-templateBtn-label'>
	  							Default
	  						</div>
	  						<EditorCanvasBottomBarTemplateTooltip templateTooltipTemplate='bodyLeft' />
	  					</button>
	  					<button className={'editor-canvas-bottomBar-templateBtn ' + (templateDefinition === 'bodyCenter' ?  'editor-canvas-bottomBar-templateBtn--active' : 'editor-canvas-bottomBar-templateBtn--inactive')} onClick={()=>{updateQuestionTemplate('bodyCenter')}} onMouseEnter={()=>{this.setState({templateTooltipTemplate: 'bodyCenter'})}}>
	  						<EditorIcon name='templateCenter'/>
	  						<div className='editor-canvas-bottomBar-templateBtn-label'>
	  							Center
	  						</div>
	  						<EditorCanvasBottomBarTemplateTooltip templateTooltipTemplate='bodyCenter' />
	  					</button>		  					
	  					<button className={'editor-canvas-bottomBar-templateBtn ' + (templateDefinition === 'bodyCenterChoicesMedia' ?  'editor-canvas-bottomBar-templateBtn--active' : 'editor-canvas-bottomBar-templateBtn--inactive')} onClick={()=>{updateQuestionTemplate('bodyCenterChoicesMedia')}} onMouseEnter={()=>{this.setState({templateTooltipTemplate: 'bodyCenterChoicesMedia'})}}>
	  						<EditorIcon name='templateChoicesMedia'/>		  					
	  						<div className='editor-canvas-bottomBar-templateBtn-label'>
	  							Media Choices
	  						</div>
	  						<EditorCanvasBottomBarTemplateTooltip templateTooltipTemplate='bodyCenterChoicesMedia' />
	  					</button>
	  				</div>	  	
  				}		
  				{hasQuestionMedia && 
	  				<div className='editor-canvas-bottomBar-templateBtnGroup editor-canvas-bottomBar-templateBtnGroup--media'>
	  					<button className={'editor-canvas-bottomBar-templateBtn ' + (templateDefinition === 'bodyLeftMediaRight' ?  'editor-canvas-bottomBar-templateBtn--active' : 'editor-canvas-bottomBar-templateBtn--inactive')} onClick={()=>{updateQuestionTemplate('bodyLeftMediaRight')}}>
	  						<EditorIcon name='templateMediaRight'/>
	  						<div className='editor-canvas-bottomBar-templateBtn-label'>
	  							Default
	  						</div>
	  						<EditorCanvasBottomBarTemplateTooltip templateTooltipTemplate='bodyLeftMediaRight' />
	  					</button>
	  					<button className={'editor-canvas-bottomBar-templateBtn ' + (templateDefinition === 'bodyCenterMediaCenter' ?  'editor-canvas-bottomBar-templateBtn--active' : 'editor-canvas-bottomBar-templateBtn--inactive')} onClick={()=>{updateQuestionTemplate('bodyCenterMediaCenter')}}>
	  						<EditorIcon name='templateMediaCenter'/>
	  						<div className='editor-canvas-bottomBar-templateBtn-label'>
	  							Center
	  						</div>
	  						<EditorCanvasBottomBarTemplateTooltip templateTooltipTemplate='bodyCenterMediaCenter' />
	  					</button>
	  					<button className={'editor-canvas-bottomBar-templateBtn ' + (templateDefinition === 'bodyRightMediaLeft' ?  'editor-canvas-bottomBar-templateBtn--active' : 'editor-canvas-bottomBar-templateBtn--inactive')} onClick={()=>{updateQuestionTemplate('bodyRightMediaLeft')}}>
	  						<EditorIcon name='templateMediaLeft'/>
	  						<div className='editor-canvas-bottomBar-templateBtn-label'>
	  							Right
	  						</div>
	  						<EditorCanvasBottomBarTemplateTooltip templateTooltipTemplate='bodyRightMediaLeft' />
	  					</button>
	  					<button className={'editor-canvas-bottomBar-templateBtn ' + (templateDefinition === 'bodyLeftMediaRightChoicesMedia' ?  'editor-canvas-bottomBar-templateBtn--active' : 'editor-canvas-bottomBar-templateBtn--inactive')} onClick={()=>{updateQuestionTemplate('bodyLeftMediaRightChoicesMedia')}}>		  						
	  						<EditorIcon name='templateMediaChoicesMedia'/>
	  						<div className='editor-canvas-bottomBar-templateBtn-label'>
	  							Media Choices
	  						</div>
	  						<EditorCanvasBottomBarTemplateTooltip templateTooltipTemplate='bodyLeftMediaRightChoicesMedia' />
	  					</button>
	  				</div>	  	
  				}		
  			</div>
  			<div className='editor-canvas-bottomBar-rightSpacer'>

  			</div>
     	</div>
	  )
	}
}

export default EditorCanvasBottomBar

import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Icon from '../components/misc/Icons'
import { upsertIntegration, fetchIntegrationSections } from '../actions/integrations'
import requestGoogleAuthCode from '../utils/googleIdentity/requestGoogleAuthCode'

function ImportFromGoogleClassroomButton(props) {
  const {
    onAuthSuccess,
    upsertIntegration,
    fetchIntegrationSections,
    onLoadGCCourses,
  } = props
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  function authenticateWithGoogle() {
    setSubmitLoading(true)
    setErrorMessage('')
    requestGoogleAuthCode({
      scopeGroup: 'googleClassroomBase',
      onSuccess: (codeResponse) => {
        onAuthSuccess()
        // upsert integration
        const requestBody = {
          type: 'googleClassroom',
          authCode: codeResponse.code,
        }
        upsertIntegration(requestBody)
          .then((response) => {
            fetchIntegrationSections(response.id)
              .then((sections) => {
                onLoadGCCourses(sections, response.email, response)
              })
          })
      },
      onFailure: (errorType) => {
        setSubmitLoading(false)
        if (errorType === 'noCode') return
        const errorMessage = errorType === 'insufficientScopes' ?
          'Insufficient access granted' :
          `Something went wrong (${errorType})`
        setErrorMessage(errorMessage)
      },
    })
  }

  return (
    <React.Fragment>
      <button className='newSection-importGoogleBtn' onClick={authenticateWithGoogle}>
        <div className='newSection-importGoogleBtn-iconContainer'>
          <Icon name='googleClassroomLogo' />
        </div>
        <div className='newSection-importGoogleBtn-labelContainer'>
          {!submitLoading && (
            <div className='newSection-importGoogleBtn-label'>
              Import from Google Classroom...
            </div>
          )}

          {submitLoading && (
            <div className='button-loadingSpinner' />
          )}
        </div>
      </button>
      {errorMessage && (
        <div className='newSection-importGoogleErrorMessage'>
          {errorMessage}
        </div>
      )}
    </React.Fragment>
  )
}

export default withRouter(connect(
  () => ({ }),
  { upsertIntegration, fetchIntegrationSections },
)(ImportFromGoogleClassroomButton))

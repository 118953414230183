import React from 'react'
import ModalWrapper from './ModalWrapper'
import MoveToFolderContainer from '../../containers/modals/MoveToFolderContainer'

const MoveToFolderModal = props => {


	return (
		<div className="moveToFolder--modal">
			<ModalWrapper
				{...props}
				title="Move"
				modalStyle="dark"
			>
				<MoveToFolderContainer {...props}/>

			</ModalWrapper>

		</div>

	)
}

export default MoveToFolderModal
import React from 'react'
import RichStaticSlide from '../../../richStaticSlide/RichStaticSlide'
import { Draggable } from "react-beautiful-dnd"
import { ContextMenuTrigger } from 'react-contextmenu'

function getItemStyle(isDragging, draggableStyle,dragStarted,itemIsSelected){
	let height='100px'
	let opacity='1'
	if(dragStarted && !isDragging && itemIsSelected){
		height='0px'
		opacity='0'
	}
	const { transform } = draggableStyle
	let activeTransform = {}
	if (transform) {
		activeTransform = {
			transform: `translate(0, ${transform.substring(
			transform.indexOf(',') + 1,
			transform.indexOf(')')
			)})`
		}
	}
 let style={// some basic styles to make the items look a bit nicer
	userSelect: "none",
	// padding: grid * 2,
	//margin: `0 0 ${grid}px 0`,
	// change background colour if dragging
	height:height,
	opacity: opacity,
	// styles we need to apply on draggables
	...draggableStyle,
	...activeTransform,
	cursor: isDragging ? "grabbing" : "pointer",
 }
 return style
}


class DraggableSlide extends React.Component{  

	collect(props) {
		return { question:props.question,questionIndex:props.index}
	}

	render(){
		const {question,index,active,dragStarted,isViewOnly,itemIsLockedByAnotherUser}=this.props
		return (
			<ContextMenuTrigger 
				id='QUESTION_SLIDE'
				index={index}
				key={question.questionId}
				// disable={this.state.isPressed} 
				holdToDisplay={-1}
				collect={this.collect}
				question={question}        
			>
			 <Draggable key={question.questionId} draggableId={question.questionId} index={index} isDragDisabled={isViewOnly||itemIsLockedByAnotherUser}>
				{(provided, snapshot) => {
					return(
						<div
							id={`draggableSlide_${question.questionId}`}
							className={'editor-slideList-slideItem ' + (this.props.active ? 'editor-slideList-slideItem--active' : 'editor-slideList-slideItem--inactive')}
							onMouseDown={()=>{this.props.setActiveQuestion(question.questionId)}}
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							style={getItemStyle(
								snapshot.isDragging,
								provided.draggableProps.style,
								dragStarted,
								active
							)}
						>
							<div className='editor-slideList-slideItem-number'>
								{this.props.number}
							</div>
							<div className='editor-slideList-slideItem-slideContainer'>
								{this.props.question &&
									<RichStaticSlide
										question={this.props.question}
										slideWidth={142}
										bodyFontSize={60}
										choiceFontSize={30}
									/>
								}
								{!this.props.question &&	
									<div className='editor-slideList-slideItem-slide'/>
								}
							</div>
						</div>
					)
				}}
			</Draggable> 
		</ContextMenuTrigger>
		)
	}
}

 export default DraggableSlide
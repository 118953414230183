import React from 'react'
import { Spring, animated, interpolate} from 'react-spring/renderprops'

class SyncLiveViewPlayerStudentCell extends React.Component{

	render(){		
		const {isOnline, isAsk, isCorrect, hasResponse, isIncorrect, isScan, isReview, student, isSurvey, responseLetter,hoveredChoice} = this.props			
		let choiceHoverNotResponded = (hoveredChoice && hoveredChoice!==responseLetter)		
		let choiceHoverResponded = (hoveredChoice && hoveredChoice===responseLetter)		
		let presenceStatus = 'offline'

		if(isOnline){
			presenceStatus = 'online'
		}
			
		return(								
			<div className={'syncLiveView-player-students-studentCell '
				+ (isAsk ? ' syncLiveView-player-students-studentCell--isAsk ' : ' syncLiveView-player-students-studentCell--isScanOrReview ')
				+ (isScan ? ' syncLiveView-player-students-studentCell--isScan ' : '  ')
				+ (isReview ? ' syncLiveView-player-students-studentCell--isReview ' : '  ')
				+ (isCorrect ? ' syncLiveView-player-students-studentCell--isCorrect ' : '')
				+ (isSurvey ? ' syncLiveView-player-students-studentCell--isSurvey ' : ' syncLiveView-player-students-studentCell--isGraded ')
				+ (isIncorrect ? ' syncLiveView-player-students-studentCell--isIncorrect ' : '')
				+ (hasResponse ? ' syncLiveView-player-students-studentCell--hasResponse ' : '  ')					
				+ (!hasResponse ? ' syncLiveView-player-students-studentCell--noResponse ' : '  ')
				+ (presenceStatus ? ` syncLiveView-player-students-studentCell--${presenceStatus} ` : '')
				+ (choiceHoverNotResponded ? ' syncLiveView-player-students-studentCell--choiceHoverNotResponded ' : '')
				+ (choiceHoverResponded ? ' syncLiveView-player-students-studentCell--choiceHoverResponded ' : '')					
			}>

				{/* RESPONSE MARKER */}
				{!isAsk && presenceStatus === 'online' &&
					<div className='syncLiveView-player-students-studentCell-responseMarker'>
						<div className='syncLiveView-player-students-studentCell-responseMarker-inner'/>							
					</div>
				}

				{/* OFFLINE MARKER */}
				{presenceStatus === 'offline' &&
					<div className='syncLiveView-player-students-studentCell-offlineMarkerContainer'>
						<div className='syncLiveView-player-students-studentCell-offlineMarker'/>							
					</div>
				}

				{/* ONLINE MARKER – Signal kind of thing */}					
				<div className={'syncLiveView-player-students-studentCell-onlineMarkerContainer ' + (presenceStatus === 'online' ? 'syncLiveView-player-students-studentCell-onlineMarkerContainer--show' : ' syncLiveView-player-students-studentCell-onlineMarkerContainer--hide')} >
					<div className='syncLiveView-player-students-studentCell-onlineMarker'/>
					<div className='syncLiveView-player-students-studentCell-onlineMarker'/>
					<div className='syncLiveView-player-students-studentCell-onlineMarker'/>
				</div>							


				{isAsk &&
					<Spring native from={{x : '18px'}} to={{ x : presenceStatus === 'online' ? '0px' : '18px'}}>
						{({x}) =>        
							<animated.div style={{ transform: interpolate([x],(x) => `translate3d(${x},0,0)`)}} className='syncLiveView-player-students-studentCell-name notranslate'>
								{student.displayName}
							</animated.div>
						}
					</Spring>
				}

				{!isAsk &&				    
					<div className='syncLiveView-player-students-studentCell-name'>
						{student.displayName}
					</div>	            
				}



				{isSurvey && hasResponse &&
					<div className='syncLiveView-player-students-studentCell-responseLetter syncLiveView-player-students-studentCell-responseLetter--surveyResponse' >
						{this.props.responseLetter}							
					</div>
				}	

				{isIncorrect && hasResponse && !isSurvey &&
					<div className='syncLiveView-player-students-studentCell-responseLetter syncLiveView-player-students-studentCell-responseLetter--incorrectResponse' >
						{this.props.responseLetter}							
					</div>
				}

				{isCorrect && hasResponse && !isSurvey &&
					<div className='syncLiveView-player-students-studentCell-responseLetter syncLiveView-player-students-studentCell-responseLetter--correctResponse' >
						{this.props.responseLetter}							
					</div>
				}
					
			</div>				
		)
	}
}

export default SyncLiveViewPlayerStudentCell
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {updateStudent} from '../../actions/students'
import {showNotification} from '../../actions/notifications'
import Button from '../../components/uiKit/Button'
import Icon from '../../components/misc/Icons'

class ConfirmEditStudentCardContainer extends Component {
  
	constructor(){
		super()
		this.editStudentCard=this.editStudentCard.bind(this)  
		this.state = {
			submitLoading:false
		}
	}

	editCardNumberDiagram(studentName,oldCardNumber,newCardNumber,isSwapStudent){  
		return(
	      <div className={'confirmEditStudentCard-diagram' + (isSwapStudent ? ' is--swapStudent' : '')}>
	        <div className='confirmEditStudentCard-diagram-name'>
	          {studentName}
	        </div>
	        <div className='confirmEditStudentCard-diagram-main'>
	          <div className='confirmEditStudentCard-diagram-cardNo is--old'>
	            {oldCardNumber}
	          </div>
	          <Icon name='long-triangle-right'/>
	          <div className='confirmEditStudentCard-diagram-cardNo is--new'>
	            {newCardNumber}
	          </div>
	        </div>
	        <div className='confirmEditStudentCard-diagram-explainText'>
	          {studentName} will change from Card {oldCardNumber} to Card {newCardNumber}
	        </div>
	      </div>          
  	  )
	}



	editStudentCard() {
		this.setState({submitLoading:true})
		const {student,selectedOption} = this.props
		const nowDate = new Date()

		if (!selectedOption.swapStudent) {
			const data = {
				...student,
				clientModified:nowDate,
				card: this.props.selectedOption.value
			}
			this.props.updateStudent(student.id, data, student.section).then((response) => {
				this.props.hideModal()
				const notificationMessage=`for ${student.firstName} ${student.lastName}`
				this.props.showNotification(notificationMessage,'Updated Card Number', 'default')       
			})
		} 
		else {
			const swapStudent = selectedOption.swapStudent
			//set student card to -1 (cant have 2 students with the same card)
			const card=-1
			const data = {
				...student,
				clientModified:nowDate,
				card:card
			}
			
			this.props.updateStudent(student.id, data, student.section).then(() => {
				//then update the card of the student that is being swapped with      
				const dataSwap = {
					...swapStudent,
					card: student.card,
					clientModified:nowDate,
				}
				this.props.updateStudent(swapStudent.id, dataSwap, swapStudent.section).then((response) => {
					//then update the origional students card
					const dataNew = {
						...student,
						card: selectedOption.value,
						clientModified:nowDate,
					}
					this.props.updateStudent(student.id, dataNew, student.section).then(() => {
						this.props.hideModal()
						const notificationMessage=`for ${student.firstName} and ${swapStudent.firstName}`
						this.props.showNotification(notificationMessage,'Updated Card Numbers','default')
					})
				})
			})
		}
	}




	render() {
		const {selectedOption,student}=this.props
		return (
			<div className='confirmEditStudentCard'>

				<div className='confirmEditStudentCard-diagram--container'>     
					{this.editCardNumberDiagram(student.firstName, student.card, selectedOption.value)}
				</div>

				{selectedOption.swapStudent &&
					<div className='confirmEditStudentCard-diagram--container'>
						{this.editCardNumberDiagram(selectedOption.swapStudent.firstName, selectedOption.value, student.card, true)}
					</div>          
				}

				<div className='confirmEditStudentCard-buttonContainer'>
					{selectedOption.swapStudent &&
						<Button
							submitLoading={this.state.submitLoading}
							size='xLarge' 
							color='blue'
							label='Confirm New Card Numbers'
							canSubmit={!this.state.submitLoading ? true : false}
							onClickFunction={this.editStudentCard}
						/>
					}
					{!selectedOption.swapStudent &&
						<Button
							submitLoading={this.state.submitLoading}
							size='xLarge'
							color='blue'
							label='Confirm New Card Number'
							canSubmit={!this.state.submitLoading ? true : false}
							onClickFunction={this.editStudentCard}
						/>
					}
				</div>
			</div>
		)
	}
}


export default connect(
  (state) => ({}),
  {updateStudent,showNotification}
)(ConfirmEditStudentCardContainer)


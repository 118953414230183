import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import {confirmEmail} from '../../actions/user'
import qs from 'qs'
import Button from '../../components/uiKit/Button'

class ConfirmEmailContainer extends Component {

	constructor(props) {
		super(props)
		this.urlToConfirmEmailAttributes = this.urlToConfirmEmailAttributes.bind(this)
		this.state = {
			confirmEmailAttributes: this.urlToConfirmEmailAttributes(props.location),
			errorMessage:false,
			emailConfirmed:false
		}
	}

	UNSAFE_componentWillMount() {
		this.props.confirmEmail(this.state.confirmEmailAttributes.user,this.state.confirmEmailAttributes.token)
		.then((response) => {
			this.setState({emailConfirmed:true})
		})
		.catch(error => {
			this.setState({errorMessage:true})
		})
	}

	urlToConfirmEmailAttributes(location){
		const identityAttributes=qs.parse(location.search.slice(1))
		return identityAttributes
	}

	render() {
		const docTitle='Plickers - Confirm Email'
		return(
			<DocumentTitle title={docTitle}>
				<div className='confirmEmailPage'>					
					{this.state.errorMessage &&
						<React.Fragment>
							Email confirmation unsuccessful. If you need to confirm your email, please contact support at<span className='confirmEmailPage-supportEmail'>&nbsp;help@plickers.com</span>
						</React.Fragment>
					}
					{this.state.emailConfirmed &&
						<React.Fragment>
							<span role='img' aria-label="Celebration">🎉</span>&nbsp;Email confirmed!&nbsp;<span role='img' aria-label="Celebration">🎉</span>
							<Button size='large' color='blue' label='Go to your Library' onClickFunction={()=>{this.props.history.push('/library')}}/>
						</React.Fragment>
					}
					{!this.state.emailConfirmed && !this.state.errorMessage &&
					 <span className='confirmEmailPage-confirming'>
						Confirming your email
					 </span>
					}
				</div>
			</DocumentTitle>
		)
	}
		
}

export default withRouter(connect(
	(state) => ({

	}),
	{confirmEmail
		 }
)(ConfirmEmailContainer))

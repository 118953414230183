import React  from 'react'
import { withRouter } from 'react-router-dom'
import Icon from '../misc/Icons'


class SectionMarker extends React.Component{

	render(){	
		const {section, type, label, className} = this.props

		return(
			<div className={'sectionMarker notranslate ' + (section.color ? ` has--classcolor-${section.color}` : ' no--color') + (className ? ` ${className}` : '') + (type ? ` is--${type}` : '')}>
				{type === 'circle' &&
					<Icon name='circle'	/>					
				}
				{type !== 'colorOnly' &&
					<span>{section.name}</span>
				}

				{type === 'colorOnly' && label &&
					<span>{label}</span>
				}
				{type === 'underline' &&
					<div className='sectionMarker-underline'/>
				}					
			</div>
		)
	}
				
}

SectionMarker.defaultProps = {
	type:'circle',
}

export default withRouter(SectionMarker)



const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'FETCH_DATA_OWNER_SUCCESS': {
      const { response } = action
      return {
        ...state,
        [response.id]: response,
      }
    }
    case 'LOGOUT':
      return {}

    default:
      return state
  }
}

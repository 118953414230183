import {isSurvey} from './isSurvey'

export function calculatePercentCorrect(poll) {
	let percentCorrect
	if(isSurvey(poll)){
		percentCorrect=-1
	}else{
		let totalResponses=0
		let totalCorrect=0
		Object.keys(poll.responsesByCard).map((key)=>{
			const response=poll.responsesByCard[key]
			totalResponses+=1
			if (response.answer === 'A') {
				if (poll.snapshot.choices[0].correct) {
					totalCorrect += 1
				}
			}else if (response.answer === 'B') {
				if (poll.snapshot.choices[1].correct) {
					totalCorrect += 1
				}
			} else if (response.answer === 'C') {
				if (poll.snapshot.choices[2] && poll.snapshot.choices[2].correct) {
					totalCorrect += 1
				}
			} else if (response.answer === 'D') {
				if (poll.snapshot.choices[3] && poll.snapshot.choices[3].correct) {
					totalCorrect += 1
				}
			} 		
			return null
		})
		percentCorrect=Math.round(100*(totalCorrect/totalResponses))
	}
	return percentCorrect		
}


import {InputRule} from 'prosemirror-inputrules'
import editorSchema from '../../schema/editorSchema'
import formulas from './formulaDefinitions.js'

//formula input rules turn e.g. h2o into formatted formula with capital letters and subscript numbers

//https://stackoverflow.com/questions/61993331/how-to-replace-currently-selected-content-in-prosemirror
function buildRegex(formulaString){
  const escapedString=formulaString.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  var regex = new RegExp(escapedString + '\\s+$', 'i')
  return regex
}

function buildInputRule(formulaString){
	return new InputRule(buildRegex(formulaString), (state, match, start, end) => {
		if(window.analytics){
			window.analytics.track('Formula input rule',{
				formulaString:formulaString,
			})
		}
		let tr = state.tr
		const text=`${formulaString}`
		let subscriptPositions=[]
		const str=formulaString
		for (var i = 0; i < str.length; i++) {
			if(parseInt(str.charAt(i)) || parseInt(str.charAt(i))===0 ){
				subscriptPositions.push(i)
			}
		}
		let containsSubscript=false //only apply input rule if does not already have subscript
		const markType=editorSchema.marks.subscript
		state.doc.nodesBetween(start, end, (node, position) => {
			if (!node.isText || start === end) return;
			if(markType.isInSet(node.marks)){
				containsSubscript=true 
			}
			// calculate the section to replace
			const startPosition = Math.max(position, start)
			const endPosition = Math.min(position + node.nodeSize, end)
			// grab the content
			const substringFrom = startPosition-start
			const substringTo =endPosition-start
			const subString = text.substring(substringFrom, substringTo)
			const textNode = state.schema.text(subString, node.marks)
			// replace
			tr.replaceWith(startPosition, endPosition, textNode);
				if(endPosition===end){
					//insert a blank space at the end of formula and give it node marks
					tr.insert(end,editorSchema.text('\u00a0',node.marks))
				}
			});

			subscriptPositions.forEach((position)=>{
				tr.addMark(start+position, start+position+1, editorSchema.marks.subscript.create())
			})  
			
			tr.removeStoredMark(editorSchema.marks.subscript)
			if(!containsSubscript){
				return tr
			}else{
				return false
			}
	})
}


function buildFormulaInputRules(){
	let inputRules=[]
	//sort formulas by length so try and match longest string to shortest (otherwise will match O2 before H2O2)
	formulas.sort(function(a,b){
		return b.length - a.length
	})

	formulas.forEach((formulaString)=>{
		const rule= buildInputRule(formulaString)
		inputRules.push(rule)
	})
	return  inputRules
}

const formulaInputRules=buildFormulaInputRules()

export default formulaInputRules
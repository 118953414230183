import React from 'react' 
import Button from '../uiKit/Button'
import Icon from '../misc/Icons'
import ProfilePic from '../ProfilePic'
import {getUserNameForId} from '../../utils/getUserNameForId'
import {getPublicUrlForRepo} from '../../utils/getPublicUrlForRepo'
import RepoSocialMediaShareRow from './RepoSocialMediaShareRow'
import RepoDefaultPreviewPanelSubmitStatus from './RepoDefaultPreviewPanelSubmitStatus'
 
class RepoDefaultPreviewPanelMemberListItem extends React.Component{
	render(){
		const {permission, repoId}=this.props
		const user=permission.user || {}		
		const isPending=!permission.joined
		return(
			<div className={'repoDefaultPreviewPanel-memberList-item' + (isPending ? ' repoDefaultPreviewPanel-memberList-item--pending' : '')}>							
				<ProfilePic size='30' borderRadius='3' user={permission.user || null} />						
				<div className='repoDefaultPreviewPanel-memberList-item-labelPrimary'>
					<React.Fragment>
						{getUserNameForId(user.id,repoId,true)}
					</React.Fragment>
				</div>
			</div>
		)
	}
}
   
class RepoDefaultPreviewPanel extends React.Component{
	constructor(){
		super()
		this.handleDoubleClick=this.handleDoubleClick.bind(this)
	}
 
	showUpgrade(){
		window.location.hash='upgrade'
	}

	handleDoubleClick(){
		if(this.props.currentUserPermission.role !== 'viewer'){
			this.props.showInvitationModal()
		}
	}

	render(){
		const {userFollowsNoRepos,currentUserPermission,isPublicConsumer,isFollowing,isPublicAuthor,permissionsJoined,service,repoId,meta,repo,userHasReviewedRepo,repoSubmission,submissionsLoaded}=this.props
		let product
		if(service){
			product = service.product
		}
		let trialUsed=true
		if(meta){
			trialUsed=meta.trialUsed
		}

		//let hasRatedRepo = false // only show review if haven't reviewed. for prototyping what review button would look like 

		const publicShareURL = getPublicUrlForRepo(repo)	 	




		let showNewPublishFlag=true
		const localStorageDoNotShowPublishNewFlag = JSON.parse(localStorage.getItem('doNotShowPublishNewFlag'))
		if(localStorageDoNotShowPublishNewFlag){
			showNewPublishFlag=false
		}

		// language is english
		// grade level is not exclusively higher education or staff or other
		// subject is not other
		let repoCanBeSubmitted = false


	 // disable submissions for now
		// if((!this.props.repoSubmission||this.props.repoSubmission.status==='canceled' )&& repo.published && isPublicAuthor){
		// 	const repopoMeetsSubmissionCriteria = repoMeetsPackReviewSubmissionCriteria(repo)
		// 	if(repopoMeetsSubmissionCriteria){
		// 		repoCanBeSubmitted = true
		// 	}
		// }
	

		let repoSubmitted = false
		let repoSubmitStatus
		if(repoSubmission && repoSubmission.status!=='canceled'){
			repoSubmitStatus=repoSubmission.status
			if(repoSubmitStatus==='hidden'){
				repoSubmitStatus='in-review'
			}
		}
		if(repoSubmitStatus){
			repoSubmitted = true
		}		

		return(
			<div className='repoDefaultPreviewPanelContainer'>
				<div className={'repoDefaultPreviewPanel ' + (currentUserPermission && currentUserPermission.role === 'viewer' ? 'repoDefaultPreviewPanel--viewer' : '') + (product && product === 'free' ? ' repoDefaultPreviewPanel--free' : ' repoDefaultPreviewPanel--pro')}>									
					<div className='repoDefaultPreviewPanel-header'>
						{repo.published && isPublicConsumer && !isFollowing && !this.props.followButtonSubmitLoading &&
							<React.Fragment>							
								<Button 
									size='large'
									color='blue'
									name='follow'
									label='Follow Pack'
									onClickFunction={this.props.followRepo}									
								/>
								{!userFollowsNoRepos &&								
									<div className='repoDefaultPreviewPanel-message repoDefaultPreviewPanel-message--follow'>
										Save this pack for easy-access.
									</div>
								}
								{userFollowsNoRepos &&						
									<div className='repoDefaultPreviewPanel-message repoDefaultPreviewPanel-message--follow'>
										Packs you follow will be accessible from your sidebar and the mobile app, and we’ll include them in your search results.
									</div>
								}
							</React.Fragment>
						}
						{submissionsLoaded && repoCanBeSubmitted &&
							<React.Fragment>
								<Button 
									size='large'
									color='blue'
									name='submitearlyreleaseapproval'
									label='Submit for Early Release'
									onClickFunction={this.props.showSubmitForPackReviewModal}
								/>
								<div className='repoDefaultPreviewPanel-message repoDefaultPreviewPanel-message--submitEarlyReleaseApproval'>
									<div className='repoDefaultPreviewPanel-message--submitEarlyReleaseApproval-para'>	
										Packs approved for early release are eligible for a $100 cash award from the Plickers Creator Fund.
									</div>									
									<a className='repoDefaultPreviewPanel-message--submitEarlyReleaseApproval-link' href='https://www.plickerscreatorfund.com' target='_blank' rel='noopener noreferrer'>									
										Read more at plickerscreatorfund.com 
									</a>									
								</div>								
							</React.Fragment>
						}
						{repoSubmitted &&		
							<RepoDefaultPreviewPanelSubmitStatus
								repoSubmitStatus={repoSubmitStatus}
							/>											
						}
						{!repo.shared && !repo.published && repo.role &&
							<React.Fragment>							
								<Button
									size='large' 
									iconName='globe-shared-author'
									color='white'
									name='publish'
									label='Publish Pack'
									onClickFunction={this.props.openPublishRepoModal} 
								/>
								<div className='repoDefaultPreviewPanel-message repoDefaultPreviewPanel-message--publish'>
									Share your pack with teachers around the world.
								</div>								
							</React.Fragment>
						}
						{!repo.published && currentUserPermission && currentUserPermission.role !== 'viewer' &&										
							<Button
								size='large'
								iconName='sharedLayered'
								color={product === 'free' ? 'white' : 'blue'}
								disabled={product === 'free'} label='Invite' 
								onClickFunction={this.props.showInvitationModal}
							/>
						}
						{!repo.published && product === 'free' && 
							<div className='repoDefaultPreviewPanel-message repoDefaultPreviewPanel-message--invite' onClick={this.showUpgrade}>
								{!trialUsed && 
									<React.Fragment>
										Try Plickers Pro free for 14 days and invite people to your pack.
									</React.Fragment>
								}
								{trialUsed && 
									<React.Fragment>
										Upgrade to Plickers Pro and invite people to your pack.
									</React.Fragment>
								}							
							</div>
						}
						{!repo.published && permissionsJoined && permissionsJoined.length === 0 && product === 'plus' &&
							<div className='repoDefaultPreviewPanel-message repoDefaultPreviewPanel-message--invite'>
								Invite people to add and edit this pack's content together.
							</div>
						}						
					</div>


					{permissionsJoined && permissionsJoined.length > 0 && currentUserPermission && !currentUserPermission.disabled &&
						<div className='repoDefaultPreviewPanel-memberList' onDoubleClick={this.handleDoubleClick}>
							{currentUserPermission &&
								<RepoDefaultPreviewPanelMemberListItem repoId={repoId} currentUser permission={currentUserPermission} />
							}
							{permissionsJoined && permissionsJoined.map((permission,i)=>{
			 					return <RepoDefaultPreviewPanelMemberListItem key={i} permission={permission} repoId={repoId} />
			 				})}
						</div>
					}

					{repo.published && isPublicAuthor &&
						<div className={'repoDefaultPreviewPanel-authorBlock ' + ((repoCanBeSubmitted || repoSubmitted) ? 'repoDefaultPreviewPanel-authorBlock--footerLayout' : 'repoDefaultPreviewPanel-authorBlock--headerLayout')}>
							<div className='repoDefaultPreviewPanel-authorBlock-sectionHeader'>
								Publish Status
							</div>
							<div className='repoDefaultPreviewPanel-authorBlock-statusLive'>
								Live
							</div>
							<div className='repoDefaultPreviewPanel-authorBlock-sectionHeader'>
								Followers
							</div>
							<div className='repoDefaultPreviewPanel-authorBlock-sectionNumber'>
								{this.props.repoFollowerCount}
							</div>							

							<div className='repoDefaultPreviewPanel-authorBlock-sectionHeader'>
								Share your Pack
							</div>
							<div className='repoDefaultPreviewPanel-authorBlock-shareBlock'>								
								<div className='repoDefaultPreviewPanel-authorBlock-shareBlock-URL'>
									{publicShareURL}												
								</div>								
								<RepoSocialMediaShareRow
									repo={repo}
									publicShareURL={`https://${publicShareURL}`}
								/>								
								<div className='repoDefaultPreviewPanel-authorFooter-shareBlock-license'>									
									Creative Commons CC BY License
								</div>
							</div>
						</div>
					}

					{repo.published && isPublicConsumer &&
						<div className='repoDefaultPreviewPanel-consumerFooter'>
							<div className='repoDefaultPreviewPanel-consumerFooter-shareBlock'>								
								<div className='repoDefaultPreviewPanel-consumerFooter-shareBlock-header'>
									Share Pack Link
								</div>
								<div className='repoDefaultPreviewPanel-consumerFooter-shareBlock-URL'>
									{publicShareURL}												
								</div>															
								<RepoSocialMediaShareRow 
									repo={repo}
									publicShareURL={`https://${publicShareURL}`}
								/>								
								{isFollowing && !userHasReviewedRepo &&
									<button className='btn btn--mid repoDefaultPreviewPanel-reviewBtn' onClick={this.props.showReviewRepoModal}>										
										<Icon name='thumbsup' /> Provide Feedback 
									</button>
								}
								<div className='repoDefaultPreviewPanel-consumerFooter-shareBlock-license'>									
									Creative Commons CC BY License
								</div>
							</div>							
						</div>
					}
				</div>
			</div>		
		)
	}
}

export default RepoDefaultPreviewPanel

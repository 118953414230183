export function getReportSettings() {	
	//Defaults
	let reportSettings={
		defaultShowStudentResponses:true,
		showStudentCardNumbers:false,
		
	}

	const reportSettingsFromLocalStorage = JSON.parse(localStorage.getItem('reportSettings'))

	if(reportSettingsFromLocalStorage ){
		
		if(reportSettingsFromLocalStorage.defaultShowStudentResponses || !reportSettingsFromLocalStorage.defaultShowStudentResponses ){
			reportSettings.defaultShowStudentResponses=reportSettingsFromLocalStorage.defaultShowStudentResponses
		}
		if(reportSettingsFromLocalStorage.showStudentCardNumbers){
			reportSettings.showStudentCardNumbers=reportSettingsFromLocalStorage.showStudentCardNumbers
		}

		
	}

	return reportSettings

}


import React from 'react'
import ScorePill from '../uiKit/ScorePill'

class ScoreSheetGuest extends React.Component{

	render(){ 

		const {studentScoreDisplay,guest,guestTotals,showStudentCardNumber, showStudentLastName} = this.props
		let score='-'
		if(guestTotals.totalResponses){
			score=100 * guestTotals.totalCorrect/guestTotals.totalResponses
		}

		return(
			<div className={'scoreSheetStudent scoreSheetStudent--guest ' + (score === '-' ? ' scoreSheetStudent--isAbsent' : '')}>
				{showStudentCardNumber && 
					<div className='scoreSheetStudent-col is--cardNumber' />											
				}
				{!showStudentLastName &&
					<div className='scoreSheetStudent-col is--name'>
						{guest}
					</div>
				}
				{showStudentLastName &&
					<div className='scoreSheetStudent-col is--firstName'>
						{guest}
					</div>
				}
				{showStudentLastName &&
					<div className='scoreSheetStudent-col is--lastName' />
				}

	
				{studentScoreDisplay ==='percentage' &&
					<div className="scoreSheetStudent-col is--score is--score--percentage">
						<ScorePill typeDot score={score} />						
					</div>
				}

				{studentScoreDisplay ==='number' &&
					<div className="scoreSheetStudent-col is--score is--score--count">
						{guestTotals.totalCorrect}<span className='scoreSheetStudent-col-studentScoreCountSlash'>/</span>{guestTotals.totalResponses}				
					</div>
				}
				<ScorePill score={score} colorOnly={true} />

			</div>
		)
	}
}


export default ScoreSheetGuest

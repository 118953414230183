import React from 'react'
import ModalWrapper from './ModalWrapper'
import ScoreSheetCustomRangeModalContainer from '../../containers/modals/ScoreSheetCustomRangeModalContainer'

const ScoreSheetCustomRangeModal = props => {
	return (
		<div className='scoreSheet-customRangeModalContainer'>
			<ModalWrapper
				{...props}
				showModalHeader={false}			
				modalStyle="dark"
			>
				<ScoreSheetCustomRangeModalContainer {...props}/>
			</ModalWrapper>
		</div>
	)
}

export default ScoreSheetCustomRangeModal
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import TopNav from '../../components/navigation/TopNav'
import { updateMeta } from '../../actions/meta'

// const whatsNewContentLastUpdated=new Date('2019-03-17T22:07:50+0000')
// const whatsNewNotificationTimeout= 7 * 24 * 60 * 60 * 1000 //7 days

const navBarIsVisible = (location) => {
  if (// don't show nav bar for the following routes
    !(location.pathname.indexOf('/seteditor/') > -1) &&
    !(location.pathname.indexOf('/editor/') > -1) &&
    !(location.pathname.indexOf('/classRoster/') > -1) &&
    !(location.pathname.indexOf('/integration/') > -1) &&
    !(location.pathname.indexOf('/scoresheet') > -1) &&
    !(location.pathname.indexOf('/dnd') > -1) &&
    !(location.pathname.indexOf('/scan-helper') > -1) &&
    !(location.pathname.indexOf('/import-questions-helper') > -1) &&
    !(location.pathname.indexOf('/packreview') > -1) &&
    !(location.pathname.indexOf('/creatorslist') > -1) &&
    !(location.pathname.indexOf('/rich') > -1) &&
    !(location.pathname.indexOf('/static') > -1) &&
    !(location.pathname.indexOf('/dev/fs') > -1) &&
    !(location.pathname.indexOf('/dev/spring') > -1) &&
    !(location.pathname.indexOf('/dev/zoomtest') > -1) &&
    !(location.pathname.indexOf('/slideservice') > -1) &&
    !(location.pathname.indexOf('/migration-service') > -1) &&
    !(location.pathname.indexOf('/setup-autoplay') > -1) &&
    !(location.pathname.indexOf('/reset-password') > -1) &&
    !(location.pathname.indexOf('/delete-account') > -1) &&
    !(location.pathname.indexOf('/explorer') > -1)
  ) return true
  return false
}

class TopNavContainer extends Component {
  // Not currently using whats new notification.
  // When uncommented, this will display a notification
  // if whats new last seen is more than whatsNewNotificationTimeout time ago
  // shouldDisplayWhatsNewNotification(meta) {
  //   if (
  //     meta &&
  //     meta.onboardingStatus &&
  //     meta.onboardingStatus.assignedResponseScanned
  //   ) { // dont show notification if also showing onboarding wizard
  //     if (meta.whatsNewLastSeen) {
  //       const lastSeenDate = new Date(meta.whatsNewLastSeen)
  //       const nowDate = new Date()
  //       if (
  //         (lastSeenDate - whatsNewContentLastUpdated) < 0 &&
  //         (nowDate - whatsNewContentLastUpdated) < whatsNewNotificationTimeout
  //       ) {
  //         return true
  //       } return false
  //     } return true
  //   } return false
  // }

  render() {
    // const showNotification = this.shouldDisplayWhatsNewNotification(this.props.meta)
    if (navBarIsVisible(this.props.location)) {
      return (
        <TopNav
          user={this.props.user}
          navigateToAccount={() => { this.props.history.push('/account') }}
          // showWhatsNewNotification={showNotification}
          resetDiscoverOptIn={this.resetDiscoverOptIn}
        />
      )
    } return null
  }
}

function mapStateToProps(state) {
  const { meta } = state
  let whatsNewLastSeen = null
  if (meta) {
    whatsNewLastSeen = meta.whatsNewLastSeen
  }
  return {
    user: state.user,
    whatsNewLastSeen,
    meta: state.meta,
  }
}

export default withRouter(connect(
  mapStateToProps,
  { updateMeta },
)(TopNavContainer))

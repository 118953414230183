import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import find from 'lodash/find'
import SectionRoster from '../../components/section/SectionRoster'
import DocumentTitle from 'react-document-title'

class SectionRosterContainer extends Component {

	render() {
		return (        
			<DocumentTitle title={this.props.currentSection ? `${this.props.currentSection.name} Roster - Plickers` : 'Class Roster - Plickers'}>
				<SectionRoster section={this.props.currentSection} user={this.props.user}/>  
			</DocumentTitle>
		)
	}
}

export default withRouter(connect(
	(state,ownProps) => ({
		currentSection: find(state.sections, {'id':ownProps.match.params.id}),
		user:state.user
	}),
	{ }
)(SectionRosterContainer))

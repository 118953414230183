import {paginateDataForPreview} from './paginateDataForPreview'

export function calculateIndividualStudentPageCount(studentWithPolls,ghostHeights,columnsPerPage) {
	let totalPageCount=0	
	const reportPolls=studentWithPolls.polls
	const paginatedPolls=paginateDataForPreview(reportPolls,ghostHeights,columnsPerPage)
	totalPageCount=Object.keys(paginatedPolls).length
	return totalPageCount
}


import React from 'react'
import { Spring } from 'react-spring/renderprops' 

function calculateItemLayout(nowPlayingLayout,deliveryImageWidth, deliveryImageHeight, choiceIndex){
			// VIEWPORT DIMENSIONS
		const viewportWidth = nowPlayingLayout.viewportWidth
		const viewportHeight = nowPlayingLayout.viewportHeight    
		const viewportAspect = viewportWidth / viewportHeight

		// applied to bottom left and right
		const windowZoomItemInternalPadding = 20 // PASS IN FROM SLIDE DEFINITIONS

		// height of choice letterblock at 1280 (120px), plus a bit of padding as we're only applying internal padding to bottom
		const unscaledHeightChoiceLettersPanel = 120
		const windowZoomChoiceLettersTopPadding = (nowPlayingLayout.slideDimensions.width / 1280 * unscaledHeightChoiceLettersPanel) 
		

		//
		// START ZOOM CONTAINER DIMENSIONS

		// Find position of original on-slide video container
		const choiceImageFillContainer = document.getElementById(`choice${choiceIndex}FillImageZoomable`)

		let rect
		if(choiceImageFillContainer){
			rect = choiceImageFillContainer.getBoundingClientRect()
		}
	 
		let containerStartZoomWidth = 0
		let containerStartZoomHeight = 0
		let containerStartZoomX = 0
		let containerStartZoomY = 0

		if(rect){
			containerStartZoomWidth = rect.width
			containerStartZoomHeight = rect.height
			containerStartZoomX = rect.x
			containerStartZoomY = rect.y
		}

		const containerAspect = containerStartZoomWidth / containerStartZoomHeight
	 
		//
		// UNDLERYING IMAGE SIZING
		const imageAspect = deliveryImageWidth / deliveryImageHeight

		let imageWidth 
		let imageHeight

		// this is opposite logic to fit image/video we're filling instead of fitting
		if(containerAspect >= imageAspect){ // container wider than image
			imageWidth = containerStartZoomWidth
			imageHeight = containerStartZoomWidth / imageAspect
		}
		if(containerAspect < imageAspect){ // container narrower than item     
			imageHeight = containerStartZoomHeight
			imageWidth = imageHeight * imageAspect
		}

		//
		// END ZOOM CONTAINER DIMENSIONS    

			// find maximum available width and height
			const containerEndZoomMaxWidth = viewportWidth - windowZoomItemInternalPadding - windowZoomItemInternalPadding
			const containerEndZoomMaxHeight = viewportHeight - windowZoomItemInternalPadding - windowZoomChoiceLettersTopPadding

			// find actual fitting item width and height
			let containerEndZoomWidth 
			let containerEndZoomHeight

			// this time we do it to fit to the maximum image size available
			if(viewportAspect >= imageAspect){ // screen wider than item
				containerEndZoomHeight = containerEndZoomMaxHeight
				containerEndZoomWidth = containerEndZoomHeight * imageAspect
			}
			if(viewportAspect < imageAspect){ // screen narrower than item
				containerEndZoomWidth = containerEndZoomMaxWidth
				containerEndZoomHeight = containerEndZoomWidth / imageAspect
			}

			// find end-state X and Y, this is to viewport
			// basically center, for Y we also need to offset with the top padding
			const containerEndZoomX = (viewportWidth - containerEndZoomWidth) / 2     
			const containerEndZoomY = Math.max(((viewportHeight - containerEndZoomHeight) / 2), windowZoomChoiceLettersTopPadding)


		//
		// ZOOM START --> ZOOM END
		
		// SCALE FACTOR
		const containerEndZoomScaleX = containerEndZoomWidth / containerStartZoomWidth
		const containerEndZoomScaleY = containerEndZoomHeight / containerStartZoomHeight

		// TRANSFORM ORIGIN (this effectively translates position of slide)
		const transformOriginX = (containerStartZoomX - containerEndZoomX) / (containerEndZoomWidth - containerStartZoomWidth)
		const transformOriginY = (containerStartZoomY - containerEndZoomY) / (containerEndZoomHeight - containerStartZoomHeight)
		
		
		//
		// STYLING IS FIXED
		// zooming also repositions slide through transform origin location

		const containerStyle = {
			width: `${containerStartZoomWidth}px`, 
			height: `${containerStartZoomHeight}px`,
			left: `${containerStartZoomX}px`,
			top: `${containerStartZoomY}px`,              
			transformOrigin: `${transformOriginX * 100}% ${transformOriginY * 100}%`,
		}   

		const zoomEndGlobalScale = Math.min(containerEndZoomScaleX, containerEndZoomScaleY)   

		return{
			containerStyle:containerStyle,
			zoomEndGlobalScale:zoomEndGlobalScale,
			containerEndZoomScaleX:containerEndZoomScaleX,
			containerEndZoomScaleY:containerEndZoomScaleY,
			imageWidth:imageWidth,
			imageHeight:imageHeight
		}

 } 


class LiveViewChoiceFillImage extends React.Component{

	constructor(props){
		super()
		this.zoomAnimationFinished=this.zoomAnimationFinished.bind(this) 
		this.calculateLayout=this.calculateLayout.bind(this)   

		this.state={
			zoomActivePhase:false, //TODO think we can take this out
			carouselIsOpen:false,
			containerStyle:{},
			zoomEndGlobalScale:0,
			containerEndZoomScaleX:0,
			containerEndZoomScaleY:0,
			imageWidth:0,
			imageHeight:0
		}		
	}

	componentDidMount(){
		this.calculateLayout()
	}

	componentDidUpdate(prevProps) {
		if ((this.props.layoutKey !== prevProps.layoutKey) || (this.props.showStudentList!==prevProps.showStudentList)) {
			this.calculateLayout()
		 }    
	}

	calculateLayout(){    
		const itemLayout=calculateItemLayout(this.props.nowPlayingLayout,this.props.deliveryImageWidth, this.props.deliveryImageHeight, this.props.choiceIndex,this.props.choiceCount)
		this.setState({
			containerStyle:itemLayout.containerStyle,
			zoomEndGlobalScale:itemLayout.zoomEndGlobalScale,
			containerEndZoomScaleX:itemLayout.containerEndZoomScaleX,
			containerEndZoomScaleY:itemLayout.containerEndZoomScaleY,
			imageWidth:itemLayout.imageWidth,
			imageHeight:itemLayout.imageHeight
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.choiceMediaZoomed !== this.props.choiceMediaZoomed){
			if(nextProps.choiceMediaZoomed){
				this.setState({zoomActivePhase:true})
			}	   
		}
	}

	zoomAnimationFinished(){
		if(!this.props.choiceMediaZoomed){
			this.setState({zoomActivePhase:false})
			this.setState({carouselIsOpen:false})
		}
		if(this.props.choiceMediaZoomed){
			this.setState({carouselIsOpen:true})
		}
	}

	render(){		

		const {nowPlayingLayout, choiceMediaZoomed, animationDisabled,choiceIndex,activeZoomedChoiceIndex,isLastZoomedItem} = this.props 
		const {carouselIsOpen} = this.state
		const {containerStyle,zoomEndGlobalScale,containerEndZoomScaleX,containerEndZoomScaleY,imageWidth,imageHeight} = this.state
					// VIEWPORT DIMENSIONS
		const viewportWidth = nowPlayingLayout.viewportWidth

		//
		// CAROUSEL OFFSET

		const pageWidth = viewportWidth
		const indexDifferent = choiceIndex - activeZoomedChoiceIndex
		const isActiveZoomedChoice = choiceIndex === activeZoomedChoiceIndex

		const carouselOffset = pageWidth * indexDifferent  

		let zoomMediaScaledTranslateXCarousel = carouselOffset / zoomEndGlobalScale
		
		let setDurationTo0 = false

		if(!carouselIsOpen && !isActiveZoomedChoice){
			setDurationTo0 = true
		}

		if(carouselIsOpen && !choiceMediaZoomed && !isActiveZoomedChoice){
			setDurationTo0 = true
		}
		
		const setDelay = !carouselIsOpen && !isActiveZoomedChoice
		
		// Z-INDEX MANAGEMENT  
		const moveItemBelowDarkBG = (!choiceMediaZoomed && !isLastZoomedItem) || (!carouselIsOpen && !isLastZoomedItem)

		return(			
			<React.Fragment>
				<Spring
					onRest={this.zoomAnimationFinished}
					immediate={animationDisabled}          
					config={{
						duration: setDurationTo0 ? 0 : undefined,
						delay: setDelay ? 350 : undefined                    
					}}
					from={{ scaleX: 1, scaleY: 1, scaleGlobal: 1, translateX: 0 }}
					to={{ 
						scaleX: (choiceMediaZoomed && containerEndZoomScaleX) ? containerEndZoomScaleX : 1,
						scaleY: (choiceMediaZoomed && containerEndZoomScaleY) ? containerEndZoomScaleY : 1,
						scaleGlobal: (choiceMediaZoomed && zoomEndGlobalScale) ? zoomEndGlobalScale : 1,
						translateX: (choiceMediaZoomed && zoomMediaScaledTranslateXCarousel) ? zoomMediaScaledTranslateXCarousel : 0,
					}}                    
				>
					{props => 
						<div onClick={this.props.toggleZoomMedia} style={{transform: `scaleX(${props.scaleX}) scaleY(${props.scaleY}) translateX(${props.translateX}px)`, borderRadius: choiceMediaZoomed ? '4px' : '8px', ...containerStyle}} className={'liveView-mediaItem liveView-mediaItem--choiceFillImage '  + (isLastZoomedItem ? ' liveView-mediaItem--isLastZoomedItem ' : '') + (moveItemBelowDarkBG ? ' liveView-mediaItem--moveItemBelowDarkBG ' : '')}>
							
							<div 
								style={{
									width: `${imageWidth}px`,
									height: `${imageHeight}px`,	            			
									transform: `translateX(-50%) translateY(-50%) scaleX(${(1 / props.scaleX) * props.scaleGlobal} ) scaleY(${(1 / props.scaleY) * props.scaleGlobal})`
								}}
								className='liveView-mediaItem--choiceFillImage-imageFillCover'
							>            				            	
					
								<img src={this.props.imageDeliveryURL} className='liveView-mediaItem--fillImage-image' alt=''/>			            	
															
							</div>      

						</div>
					}
				</Spring>		
			</React.Fragment>
		)
	}
}

export default LiveViewChoiceFillImage
import store from '../store'

 
//Definitions
//free
//activeMonthlyPro
//activeYearlyProNo2019Coupon
//activeYearlyPro2019Coupon
//churnedPro
//thirdPartyPro
  
function discountsIncludesCouponCode(discounts,couponCode){
	let includesCouponCode=false
	discounts.map((discount)=>{
		if(!discount.discardedAt && discount.coupon && discount.coupon.code===couponCode){
			includesCouponCode=true
		}
		return null
	})
	return includesCouponCode

}

 

export function getUserSegment() {	
	const state = store.getState()
	const service=state.service
	const meta = state.meta
	const discounts = state.discounts


	const userHas2019Coupon=discountsIncludesCouponCode(discounts,'YEARLY20')
	let userSegmemt
	if(service && meta){
		if(service.product === 'free' && meta.trialUsed !== true){
			userSegmemt='free'
		}
		else if(service.product === 'plus' && service.plan ===  process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID && service.status !=='canceled'){
			userSegmemt='activeMonthlyPro'
		}
		else if(service.product === 'plus' && service.plan ===  process.env.REACT_APP_STRIPE_YEARLY_PLAN_ID && service.status !=='canceled' && !userHas2019Coupon){
			userSegmemt='activeYearlyProNo2019Coupon'
		}
		else if(service.product === 'plus' && service.plan ===  process.env.REACT_APP_STRIPE_YEARLY_PLAN_ID && service.status !=='canceled' && userHas2019Coupon){
			userSegmemt='activeYearlyPro2019Coupon'
		}
		else if((service.product === 'free' && meta.trialUsed === true) || service.status ==='canceled' ){
			userSegmemt='churnedPro'
		}else if(service.providedBy){
			userSegmemt='thirdPartyPro'
		}
	}

	return userSegmemt
}


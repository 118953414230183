import React from 'react'
import ReactGA from 'react-ga4'
import Icon from '../misc/Icons'
import UpgradeDiscountSash from './UpgradeDiscountSash'
import UpgradeProFeaturesSymbolRow from './UpgradeProFeaturesSymbolRow'
import UpgradeLandingInstantImagesDemo from './UpgradeLandingInstantImagesDemo'
import planPricingDetails from './planPricingDetails'
import couponDescriptions from '../../staticData/couponDescriptions'
import ComplexIcon from '../misc/ComplexIcons'

class UpgradeLandingFeatureSection extends React.Component {
  render() {
    return (
      <div className={`upgrade--landing-featureSection ${this.props.classNameAppend ? `upgrade--landing-featureSection--${this.props.classNameAppend}` : ''}`}>
        <div className='upgrade--landing-featureSection-featureName'>
          {this.props.featureName}
        </div>
        <div className='upgrade--landing-featureSection-featureHeader'>
          {this.props.featureHeader}
        </div>
        <div className='upgrade--landing-featureSection-featureSub'>
          {this.props.featureSub}
        </div>

        <div className='upgrade--landing-featureSection-visual'>
          {this.props.children}
        </div>

        {this.props.cloudSVGname && (
        <div className='upgrade--landing-featureSection-cloudBG'>
          <ComplexIcon name={this.props.cloudSVGname} />
        </div>
        )}

      </div>

    )
  }
}

class UpgradeLandingCompareTableRow extends React.Component {
  render() {
    return (
      <div className={`upgrade--landing-compareTable-row ${this.props.inactive ? 'upgrade--landing-compareTable-row--inactive' : 'upgrade--landing-compareTable-row--active'}`}>
        <div className='upgrade--landing-compareTable-row-checkContainer'>
          <Icon name='check' />
        </div>
        <div className='upgrade--landing-compareTable-row-featureLabel'>
          {this.props.featureLabel}
        </div>
      </div>

    )
  }
}

class UpgradeLandingPage extends React.Component {
  componentDidMount() {
    if (window.analytics) {
      let testGroup = null
      const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
      if (setLimitTestGroup) {
        testGroup = setLimitTestGroup
      }

      window.analytics.page('Upgrade', {
        stage: 'Landing',
        // eslint-disable-next-line camelcase
        test_group_set_limit: testGroup,
      })
    }
    if (typeof window.gtag === 'function') {
      ReactGA.set({ page: '/upgrade-landing-page' })
      ReactGA.send({ hitType: 'pageview' })
    }
  }

  render() {
    const { trialUsed, isDiscountPeriod } = this.props
    const { percentageSavingStandardYearly, standardMonthlyPricePerMonth } = planPricingDetails
    let yearlyDiscount = false
    if (isDiscountPeriod) {
      const couponDetails = couponDescriptions[process.env.REACT_APP_DEFAULT_COUPON_CODE]
      yearlyDiscount = couponDetails.yearlyDiscount
    }

    const couponDescription = couponDescriptions[process.env.REACT_APP_DEFAULT_COUPON_CODE]

    return (
      <div className='upgrade upgrade--landing marketing'>
        <div className='upgrade-closeBtn' onClick={this.props.hideModal}>
          <Icon name='circle-with-close' />
        </div>

        {isDiscountPeriod &&
        <UpgradeDiscountSash />}

        <div className='upgrade--landing-header'>
          Do more with
          {' '}
          <span className='upgrade--landing-header--plickersPro-plickers'>plickers&nbsp;</span>
          <span className='upgrade--landing-header--plickersPro-pro'>pro</span>
        </div>

        <div className='upgrade--landing-sub'>

          {yearlyDiscount && (
          <React.Fragment>
            {!trialUsed && (
            <div onClick={this.props.nextPage} className='upgrade--landing-sub-trial'>
              Try it free for 14 days.
              <div className='upgrade--landing-sub-trial-underline' />
            </div>
            )}
            <div className='upgrade--landing-sub-price'>
              &nbsp; From
              {' '}
              {couponDescription.discountYearlyPricePerMonth}
              {' '}
              per month with an annual plan.
            </div>
            <div className='upgrade--landing-sub-normally' />
          </React.Fragment>
          )}

          {!yearlyDiscount && (
          <React.Fragment>
            {standardMonthlyPricePerMonth}
            {' '}
            per month. Save
            {' '}
            {percentageSavingStandardYearly}
            {' '}
            when you pay annually.
          </React.Fragment>
          )}

        </div>

        <UpgradeProFeaturesSymbolRow />

        <button className='upgrade--landing-btn' onClick={this.props.nextPage}>
          {!trialUsed &&
          <React.Fragment>Start 14-day Free Trial</React.Fragment>}
          {!!trialUsed &&
          <React.Fragment>Upgrade to Pro</React.Fragment>}
        </button>

        <div className='upgrade--landing-btnSub'>
          No commitments. Cancel anytime with one click.
        </div>

        <UpgradeLandingFeatureSection
          classNameAppend='sharedPacks'
          featureName='Shared Packs'
          featureHeader='Build content with your colleagues and friends.'
          featureSub='Add teammates as editors or viewers and collaborate on lesson content together.'
        />

        <UpgradeLandingFeatureSection
          classNameAppend='instantImages'
          featureName='Unlimited Media Search'
          featureHeader='Add media to your questions in a flash.'
          featureSub={(
            <React.Fragment>
              Search YouTube. Add images from the internet.
              {' '}
              <b>Try typing into the search bar below</b>
            </React.Fragment>
)}
          cloudSVGname='landingFeatureCloudInstantImages'
        >
          <React.Fragment>

            <div className='upgrade--landing-featureSection--instantImages-dottedLineContainer'>
              <ComplexIcon name='landingFeatureInstantImagesDottedLine' />
            </div>

            <UpgradeLandingInstantImagesDemo />

          </React.Fragment>
        </UpgradeLandingFeatureSection>

        {/*
          <UpgradeLandingFeatureSection
            classNameAppend='layoutTemplates'
            featureName='Layout Templates'
            featureHeader='Choose the best layout for every question.'
            featureSub='Select from three templates to make your questions more fun and easier to understand.'
            cloudSVGname='landingFeatureCloudLayoutTemplates'
          >

            <UpgradeLandingLayoutTemplatesCarousel />
          </UpgradeLandingFeatureSection>
        */}

        <UpgradeLandingFeatureSection
          classNameAppend='unlimitedScoresheet'
          featureName='Unlimited Scoresheet'
          featureHeader='Look long-term with unlimited Scoresheet.'
          featureSub='Custom select any date range and track student results over months and terms.'
          cloudSVGname='landingFeatureCloudUnlimitedScoresheet'
        >

          <div className='upgrade--landing-featureSection-unlimitedScoresheet-demoContainer'>
            <div className='upgrade--landing-featureSection-unlimitedScoresheet-demoBG'>
              <div className='upgrade--landing-featureSection-unlimitedScoresheet-demoBG-screen'>
                <img
                  alt=''
                  className='upgrade--landing-featureSection-unlimitedScoresheet-demoBG-screen-image'
                  src='https://assets.plickers.com/react-assets/unlimited-scoresheet-preview/Unlimited-Scoresheet_960w.png'
                  srcSet='https://assets.plickers.com/react-assets/unlimited-scoresheet-preview/Unlimited-Scoresheet_960w.png 1x, https://assets.plickers.com/react-assets/unlimited-scoresheet-preview/Unlimited-Scoresheet_1920w.png 2x'
                />
              </div>
            </div>
          </div>

        </UpgradeLandingFeatureSection>

        <div className='upgrade--landing-compareTableContainer'>

          <div className='upgrade--landing-compareTable upgrade--landing-compareTable--free'>
            <div className='upgrade--landing-compareTable-header'>
              plickers
              {' '}
              <span className='upgrade--landing-compareTable-header-append'>free</span>
            </div>
            <div className='upgrade--landing-compareTable-price'>
              $0.00
              {' '}
              <span className='upgrade--landing-compareTable-month'>/ month</span>
            </div>

            <div className='upgrade--landing-compareTable-divider' />

            <UpgradeLandingCompareTableRow
              featureLabel='Unlimited Classes'
            />
            <UpgradeLandingCompareTableRow
              featureLabel='Unlimited Reports'
            />
            <UpgradeLandingCompareTableRow
              featureLabel='Create Sets with Unlimited Questions'
              inactive
            />
            <UpgradeLandingCompareTableRow
              featureLabel='Unlimited Media Search'
              inactive
            />
            <UpgradeLandingCompareTableRow
              featureLabel='Shared Packs'
              inactive
            />
            <UpgradeLandingCompareTableRow
              featureLabel='Unlimited Scoresheet'
              inactive
            />
            <UpgradeLandingCompareTableRow
              featureLabel='Priority Support'
              inactive
            />

            <button onClick={this.props.hideModal} className='upgrade--landing-btn--small upgrade--landing-btn--small--white'>
              Stay on Free Plan
            </button>

          </div>

          <div className='upgrade--landing-compareTable upgrade--landing-compareTable--pro'>
            <div className='upgrade--landing-compareTable-header'>
              plickers
              {' '}
              <span className='upgrade--landing-compareTable-header-append'>pro</span>
            </div>
            <div className='upgrade--landing-compareTable-price'>
              {/* BTS 2018
              {monthlyDiscount &&
                <React.Fragment>
                  {discountMonthlyPricePerMonth} <span className='upgrade--landing-compareTable-normally'>{standardMonthlyPricePerMonth}</span> <span className='upgrade--landing-compareTable-month'>/ month</span>
                  <div className='upgrade--landing-compareTable-price-strikeThrough'/>
                </React.Fragment>
              }
              */}
              {!isDiscountPeriod && (
              <React.Fragment>
                {standardMonthlyPricePerMonth}
              </React.Fragment>
              )}
              {isDiscountPeriod && (
              <React.Fragment>
                <span className='upgrade--landing-compareTable-from'>from</span>
                {' '}
                {couponDescription.discountYearlyPricePerMonth}
                {' '}
                <span className='upgrade--landing-compareTable-month'>/ month</span>
              </React.Fragment>
              )}
            </div>

            <div className='upgrade--landing-compareTable-divider'>
              {isDiscountPeriod && (
              <React.Fragment>
                {couponDescription.paymentHeaderMessage}
              </React.Fragment>
              )}

              {!isDiscountPeriod && (
              <React.Fragment>
                Save
                {' '}
                {percentageSavingStandardYearly}
                {' '}
                when you pay annually
              </React.Fragment>
              )}
            </div>

            <UpgradeLandingCompareTableRow
              featureLabel='Unlimited Classes'
            />
            <UpgradeLandingCompareTableRow
              featureLabel='Unlimited Reports'
            />
            <UpgradeLandingCompareTableRow
              featureLabel='Create Sets with Unlimited Questions'
            />
            <UpgradeLandingCompareTableRow
              featureLabel='Unlimited Media Search'
            />
            <UpgradeLandingCompareTableRow
              featureLabel='Shared Packs'
            />
            <UpgradeLandingCompareTableRow
              featureLabel='Unlimited Scoresheet'
            />
            <UpgradeLandingCompareTableRow
              featureLabel='Priority Support'
            />

            <button onClick={this.props.nextPage} className='upgrade--landing-btn--small upgrade--landing-btn--small--green'>
              {!trialUsed && (
              <React.Fragment>
                Start Free Plickers Pro Trial
              </React.Fragment>
              )}
              {!!trialUsed && (
              <React.Fragment>
                Upgrade to Pro
              </React.Fragment>
              )}

            </button>
          </div>

          <div className='upgrade--landing-compareTableContainer-bg'>
            <ComplexIcon name='landingCompareTableCloud' />
          </div>

        </div>

        <div className='upgrade--landing-bulkOrder'>

          <div className='upgrade--landing-bulkOrder-text'>
            Looking to upgrade your department, school, or district?
          </div>

          <button className='upgrade--landing-btn--small upgrade--landing-btn--small--blue' onClick={() => { window.open(process.env.REACT_APP_PLICKERS_FOR_SCHOOLS_URL, '_blank') }}>
            See group order pricing
          </button>

        </div>

      </div>

    )
  }
}

export default UpgradeLandingPage

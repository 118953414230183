import {InputRule} from 'prosemirror-inputrules'
import schema from '../../schema/editorSchema'

//replace x_y with xsub(y)

export function subscriptInputRule(){
	return new InputRule(/([\S]+|\s|^)_([\S]+)\s$/, (state, match, start, end) => {
		let tr = state.tr
		if((/_+/).test(match[1])===false && (/_+/).test(match[2])===false){ //don't trigger for '__'
			let marksForSpaceCharacter=[]//apply any marks to the space after text
			state.doc.nodesBetween(start, end, (node, position) => {
				if (!node.isText || start === end) return
				marksForSpaceCharacter=node.marks   
			})
			return tr
			.insert(end,schema.text(`\u00a0`,marksForSpaceCharacter))
			.delete(start+match[1].length,start+match[1].length+1)
			.addMark(start+match[1].length, end-1, schema.marks.subscript.create())
			.removeStoredMark(schema.marks.subscript)
		}else{ 
			return false
		}
	})
}

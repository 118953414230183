import React, { Component } from 'react'
import Icon from '../misc/Icons'
import {FacebookShareButton,TwitterShareButton,PinterestShareButton} from 'react-share';


class AuthorSocialMediaShareRow extends Component {   	

	render() {
		const {author} = this.props
		const authorShareURL = `${process.env.REACT_APP_URL}/${author.username}`
		let imageURL 
		if(author.image){
			imageURL = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload/c_fill,h_128,w_128/${author.image}/profile/${author.id}`
		}else{//use plickers logo if doesn't have image
			imageURL=`https://assets.plickers.com/brand-assets/PlickersLogo640.png`	
		}
		
		return (
			<div className='repoSocialMediaShareRow'>				
				<FacebookShareButton
					url={authorShareURL}
					hashtag={"#plickers"}
				>
					<div className='repoSocialMediaShareRow-item repoSocialMediaShareRow-item--facebook'>
						<Icon name='social-media-facebook-share' />	
						Share
					</div>
				</FacebookShareButton>

				<TwitterShareButton
					url={authorShareURL}				
					title={`${author.username} - Plickers`}
					hashtags={["plickers"]}
				>
					<div className='repoSocialMediaShareRow-item repoSocialMediaShareRow-item--twitter'>
						<Icon name='social-media-twitter-tweet' />
						Tweet
					</div>
				</TwitterShareButton>
				

				<PinterestShareButton
					url={String(authorShareURL)}
					media={String(imageURL)}
					description={author.description ? author.description : null}
				>
					<div className='repoSocialMediaShareRow-item repoSocialMediaShareRow-item--pinterest'>
						<Icon name='social-media-pinterest-save' />
						Save
					</div>
				</PinterestShareButton>			
			</div>
		)
	}
}

export default AuthorSocialMediaShareRow
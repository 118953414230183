import React from 'react'
import Button from '../uiKit/Button'
 
class CreatorProgramSuccessBlock extends React.Component{


	render(){	
		
		
		return(
			
				<div className={'joinCreatorProgramModal--success-block ' + (this.props.blockNumber ? `joinCreatorProgramModal--success-block--${this.props.blockNumber}` : '')}>					
					<div className='joinCreatorProgramModal--success-block-title'>
						{this.props.title}
					</div>
					<div className='joinCreatorProgramModal--success-block-label'>
						{this.props.label}
					</div>
					{this.props.label2 &&
						<div className='joinCreatorProgramModal--success-block-label joinCreatorProgramModal--success-block-label--two'>
						{this.props.label2}
						</div>
					}
					{this.props.label3 &&
						<div className='joinCreatorProgramModal--success-block-label joinCreatorProgramModal--success-block-label--two'>
						{this.props.label3}
						</div>
					}
					
				</div>

			
		)	
	}
				
}

class CreatorProgrammeSuccessPage extends React.Component{


	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Join Creator Program',{
				stage:'success',
			})
		}
	}



	render(){	
		
		return(
			<React.Fragment>			
				<div className='joinCreatorProgramModal-header'>
					You're in!
				</div>


				<CreatorProgramSuccessBlock
					blockNumber={1}
					title='Welcome to the program - you are now ready to publish!'
					label="Click 'Publish Pack' in the pack page at any time and follow the steps"
				/>

				<CreatorProgramSuccessBlock
					blockNumber={2}
					title='Your Creator profile page is now ready'
					label={<React.Fragment> Your unique Creator URL is live at <a href={`${process.env.REACT_APP_FULL_URL}/${this.props.username}`} target='_blank' rel='noopener noreferrer'>{process.env.REACT_APP_SIMPLE_URL}/{this.props.username}</a></React.Fragment>}
					label2="All of your public packs will be accessible from this page."
					label3="Share your Creator profile URL with your colleagues or Twitter followers!"
				/>

				<CreatorProgramSuccessBlock
					blockNumber={3}
					title='Feedback and Support'
					label="If you have any questions or feedback about publishing or the Creator program, contact us at help@plickers.com"
				/>
				
		
				<div className='joinCreatorProgramModal-submitArea'>
					
					<Button
						size='large'
						color='blue'
						label='Publish your first pack now'
						name='submit'
						onClickFunction={this.props.goToNextPage}						
					/>


					<Button
						size='large'
						color='white'
						label="I'm not quite ready to publish yet!"
						name='cancel'
						onClickFunction={this.props.hideModal}						
					/>


				</div>

								

			</React.Fragment>


			
		)	
	}
				
}




export default CreatorProgrammeSuccessPage



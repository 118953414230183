import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {fetchQuestionPolls, fetchSetPolls} from '../../actions/polls'
import find from 'lodash/find'
import Button from '../../components/uiKit/Button'
import StaticQuestionSlide from '../../components/slides/StaticQuestionSlide'



class LiveViewAlertContainer extends Component {

	constructor() {
		super()
		this.navigateToLiveView = this.navigateToLiveView.bind(this)     
	}

	UNSAFE_componentWillMount() {
		this.props.fetchQuestionPolls()
		this.props.fetchSetPolls()
	}


	navigateToLiveView(){
		this.context.router.transitionTo('/liveview')
		this.props.hideModal()
	}

	render() {
		const {currentSection,control,currentQuestion,currentSet}=this.props
		let setQuestion=null
		if(currentSet){
			setQuestion=find(currentSet.questions, {'questionId':control.setQuestion})
		}

		return (
      <div className='liveViewAlert'>   

        {currentSection &&
          <div className='liveViewAlert-sectionName'>
            {currentSection.name} 
          </div> 
        }

        {currentSection &&
          <div className={'liveViewAlert-live' + (currentSection.color ? ` has--class${currentSection.color}` : ' no--color')}>            
            Live
          </div>
        }

        {currentQuestion &&
          <div className='liveViewAlert-questionPreview' onClick={() =>{this.navigateToLiveView()}}>
            <StaticQuestionSlide question={currentQuestion} stage='ask' mode='view' displayWidth={220} previewCard={true} />
          </div>
        }

        {setQuestion &&
          <div className='liveViewAlert-questionPreview' onClick={() =>{this.navigateToLiveView()}}>
            <StaticQuestionSlide question={setQuestion} stage='ask' mode='view' displayWidth={220} previewCard={true} />      
          </div>
        }

        <div className='liveViewAlert-actionRow'>
          <Button label='Go to Now Playing' size='large' color='blue' onClickFunction={() =>{this.navigateToLiveView()}} />
          <Button label='Ignore' size='small' />
        </div>

      </div>
      )   
	}
}



function mapStateToProps(state) {
	let control=state.control
	let currentSection=null
	let currentPoll= null
	if(state.control){
		currentSection=find(state.sections, {'id':state.control.section})
		currentPoll= find(state.queueQuestionPolls, {'id':control.currentPoll}) || find(state.historyQuestionPolls, {'id':control.currentPoll}) 
	}
	if(currentPoll){
		var currentQuestion=find(state.questions, {'id':currentPoll.question})
	} 
	let currentSetPoll= null
	if(state.control){
		currentSetPoll= find(state.queueSetPolls, {'id':state.control.setPoll}) || find(state.historySetPolls, {'id':state.control.setPoll}) 
	}
	let currentSet=null
	if(currentSetPoll){
	 currentSet= find(state.sets, {'id':currentSetPoll.setObject})
	}

	return {
		control:control,
		currentPoll:currentPoll,
		currentQuestion:currentQuestion,
		currentSetPoll: currentSetPoll,
		currentSet: currentSet,
		currentSection:currentSection,
		questions:state.questions,
		sections:state.sections
	}
}


export default connect(
   mapStateToProps,
  {fetchQuestionPolls, fetchSetPolls }
)(LiveViewAlertContainer)


LiveViewAlertContainer.contextTypes= {
	router: PropTypes.object
}



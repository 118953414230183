import {moveToLibrary} from '../../utils/moveToLibrary'
import {getRepoforId} from '../../utils/getRepoforId'
import {showConfirmModal} from '../../actions/modals'
import store from '../../store'

//https://github.com/react-dnd/react-dnd
function onDrop(props,droppedItem,multiselectItems) {	
	let promises=[]
	if(multiselectItems){
		Object.keys(multiselectItems).map((key)=>{
			const item=multiselectItems[key]
			promises.push(moveToLibrary(item))
			return null
		})
	}else{
		promises.push(moveToLibrary(droppedItem))
	}

	Promise.all(promises).then(function () {
		let itemCount=1
		if(multiselectItems){
			itemCount=Object.keys(multiselectItems).length
		}
		let notificationMessage=''
		if(itemCount>1){
			 notificationMessage=`${itemCount} items to Your Library`
		}else{
			notificationMessage=`${droppedItem.name || droppedItem.body} to Your Library`
		}
		props.showNotification(notificationMessage,'Moved','default')
		props.hideModal()
	})
}


const LibraryTarget = {
	canDrop(props, monitor) {
		const draggedItem=monitor.getItem().cellItem
		const containingRepo = getRepoforId(draggedItem.repo)
		if(containingRepo && (containingRepo.role ==='viewer' || containingRepo.role ==='consumer')){
			return false
		}else if(draggedItem.folder || draggedItem.repo){
			return true
		}else return false

		
	},
	drop(props, monitor) {
		const droppedItem=monitor.getItem().cellItem
		const multiselectItems=monitor.getItem().multiselectItems
		const inSharedRepo=monitor.getItem().inSharedRepo
		const inPublishedRepo=monitor.getItem().inPublishedRepo
		if(inSharedRepo){
			store.dispatch(showConfirmModal(multiselectItems,onDrop.bind(this,props,droppedItem,multiselectItems),'moveFromSharedRepo'))
		}else if(inPublishedRepo){
			store.dispatch(showConfirmModal(multiselectItems,onDrop.bind(this,props,droppedItem,multiselectItems),'moveFromPublishedRepo'))
		}else{
			onDrop(props,droppedItem,multiselectItems)
		}
	}
}

export default LibraryTarget


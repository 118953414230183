export function getPublicUrlForRepo(repo) {
	if(repo.published){
		let route = process.env.REACT_APP_SIMPLE_URL
		let username='username'
		if(repo.author && repo.author.username){
			username=repo.author.username	 
		}
		
		const formattedUsername=username.replace(/ /g,'-')
		route += `/${formattedUsername}/`
		const repoName=repo.name 
		const formattedRepoName=repoName.replace(/[º#&=:*·\\/?;|{}"<>%~[\]` ]/g,'-')
		route += `${formattedRepoName}-${repo.shortId}`
		return route
	}else {
	
		return null

	}
} 

   
import React, {Component} from 'react'
import { CSSTransitionGroup } from 'react-transition-group' 
import moment from 'moment'

 
const NOTIFICATION_TIMEOUT= 7500



class ImportQuestionsNotificationContent extends Component{


	componentDidMount() {
		this.setTimer()
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (moment(nextProps.notificationTimestamp) !== moment(this.props.notificationTimestamp)) {
	 		//reset the timer
			this.setTimer()
		}
	}
	componentWillUnmount(){
		if(this._timer !== null){
			clearTimeout(this._timer)
		}
	}


	setTimer() {
		if(this._timer !== null){
			clearTimeout(this._timer)}

		// hide after `delay` milliseconds
		this._timer = setTimeout(function(){
			this.props.clearNotification()
			this._timer = null
		}.bind(this), NOTIFICATION_TIMEOUT)
	}







	render(){	
		
		const {notificationType,importQuestionsNotificationSearchTerm} = this.props
		let hasUndo=true
		let notificationMessage=''
		let undoMessage=''
		if(notificationType==='kahoot'){
			notificationMessage='It looks you pasted some Kahoot questions in, so we cleaned them up for you.'
			undoMessage='No thanks, go back what I originally pasted'
		}
		else if(notificationType==='findAndRemove'){
			notificationMessage=`Found and removed '${importQuestionsNotificationSearchTerm.replace(/ /g, '\u00a0')}'`
			undoMessage='Undo'
		}
		else if(notificationType==='cleanBulletPoints'){
			notificationMessage='Cleaned Bullet Points'
			undoMessage='Undo'
		}
		else if(notificationType==='cleanQuestionNumbers'){
			notificationMessage='Cleaned Question Numbers'
			undoMessage='Undo'
		}
		else if(notificationType==='cleanChoiceLetters'){
			notificationMessage='Cleaned Choice Letters'
			undoMessage='Undo'
		}
		else if(notificationType==='noMatchFindAndRemove'){
			notificationMessage=`No matches found for '${importQuestionsNotificationSearchTerm.replace(/ /g, '\u00a0')}'`
			hasUndo=false
		}





		return(					
        		<div className={'importQuestionsNotification importQuestionsNotification--show' + (notificationType==='kahoot' ? ' importQuestionsNotification--kahoot' :'')}>						
						{notificationMessage}	
						{hasUndo &&			
						<div className='importQuestionsNotification-button' onClick={this.props.undoLastChange}>
							{undoMessage}
						</div>
						}
				</div>
		)
	}
}


 
					





class ImportQuestionsNotification extends Component{


	render(){	
		
		const {showNotification,notificationTimestamp} = this.props
		
		return(					
			
			<CSSTransitionGroup
				transitionName='notificationTransition'
				transitionEnterTimeout={350}
				transitionLeaveTimeout={250}
			>
				{showNotification &&
					<ImportQuestionsNotificationContent
						key={notificationTimestamp}
						{...this.props}
					/>
				}
			</CSSTransitionGroup>        
		)
	}
}


 
					



export default ImportQuestionsNotification

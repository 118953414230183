import schema from '../../schema/editorSchema'  
//make question have 2 choices, the first is correct and has choiceBody "True", the second has choiceBody "False"
export function setToTrueFalse() {
	let tr=window.view.state.tr
	let choices=[]
	tr.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if(node.attrs.activeslide && node.attrs.activeslide===true){
			tr.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
				if (questionNode.type.attrs.correct){ 
					choices.push(questionNode)
				}
			})
		}
	})

	let newChoiceNodes=[]
	choices.forEach((choice,index)=>{
		if(index===0){
			const type = schema.nodes.choice
			const choiceBodyNode=schema.nodes.choiceBody.createAndFill({},schema.text('True'))
			const mediaEmptyState=schema.nodes.choiceMediaEmptyState.createAndFill({})
			const node=type.create({...choice.attrs,choiceIndex:index,correct:true,isSurvey:false,isTouched:true,questionChoiceCount:2},[choiceBodyNode,mediaEmptyState])
			newChoiceNodes.push(node)
		}
		if(index===1){
			const type = schema.nodes.choice
			const mediaEmptyState=schema.nodes.choiceMediaEmptyState.createAndFill({})
			const choiceBodyNode=schema.nodes.choiceBody.createAndFill({},schema.text('False'))
			const node=type.create({...choice.attrs,choiceIndex:index,correct:false,isSurvey:false,isTouched:true,questionChoiceCount:2},[choiceBodyNode,mediaEmptyState])
			newChoiceNodes.push(node)
		}
	})

	tr.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if(node.attrs.activeslide && node.attrs.activeslide===true){
			tr.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
				if(questionNode.type.name==='choiceGroup'){
				let choiceGroupType = schema.nodes.choiceGroup
				const newChoiceGroupNode=choiceGroupType.create({...questionNode.attrs,choicesComplete:true},newChoiceNodes)
				tr.replaceWith(questionPos, questionPos+questionNode.nodeSize,newChoiceGroupNode)
				}	 
			})
		}
	})
	tr.setMeta("triggerParseDoc", true) 
	window.view.dispatch(tr)
}

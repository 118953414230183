import React from 'react'

class AccountSubsection extends React.Component{

	render(){	
		const {children, label, name} = this.props

		return(
				
			<div className={'account-subsection' + (label && !name ? ` account-subsection--${label}` : '') + (name ? ` account-subsection--${name}` : '')}>
				{label &&
					<div className='account-subsection-header'>					
						{label}														
					</div>
				}

				{children && 
					<div className="account-subsection-content">
						{children}
					</div>
				}

			</div>
		)
	}
}

export default AccountSubsection


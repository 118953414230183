import React from 'react'
import { withRouter} from 'react-router-dom'
import includes from 'lodash/includes'
import find from 'lodash/find'
import Icon from './../misc/Icons'
import DnDCell from '../cells/DnDCell'
import ItemPreviewCell from '../cells/ItemPreviewCell'
import DnDFolderCell from '../cells/DnDFolderCell'
import FolderPreviewCell from '../cells/FolderPreviewCell'
import StickyDividerWrapper from '../uiKit/StickyDividerWrapper'
import SetAndQuestionContextMenu from '../contextMenus/SetAndQuestionContextMenu'
import FolderContextMenu from '../contextMenus/FolderContextMenu'
import MultiSelectContextMenu from '../contextMenus/MultiSelectContextMenu'
import {getUserNameForId} from '../../utils/getUserNameForId'
import RepoInlineRatingPanel from './RepoInlineRatingPanel' 
 
class RepoPage extends React.Component{ 
 
	constructor(){
		super()
		this.renderRepoItem=this.renderRepoItem.bind(this)
		this.isItemActive=this.isItemActive.bind(this)
		this.lockForItem=this.lockForItem.bind(this)
		this.isItemMultiSelect=this.isItemMultiSelect.bind(this)
	}


	componentDidUpdate(prevProps) {
   	 // only scroll into view if the active item changed last render
   	 let activeItemId=null
   	 if(this.props.activeItem){
   	 	activeItemId=this.props.activeItem.id
   	 }
   	 let prevActiveItemId=null
   	 if(prevProps.activeItem){
   	 	prevActiveItemId=prevProps.activeItem.id
   	 }
   	 if (this.props.activeItem && (activeItemId !== prevActiveItemId) ) {
   	  this.ensureActiveItemVisible(this.props.activeItem.id)
    	}
  	}

	ensureActiveItemVisible(itemId) {
		const cellNode = document.getElementById(itemId)
		if(cellNode ){
			const cellRect = cellNode.getBoundingClientRect()
			const offset=130 //for header
			//check if need to scroll it 
			if(!(cellRect.top >= offset && cellRect.bottom <= window.innerHeight)){
				cellNode.scrollIntoView({block: 'end', behavior: 'instant'})
			}
		}
	}

	isItemActive(itemId){ 
		let isActive=false
		const {activeItem}=this.props
		if(activeItem){		
			isActive=(itemId === activeItem.id)
		}
		return isActive
	}


	lockForItem(itemId){
		const {locks}=this.props
		const lock = find(locks, function(lock) {
    		return lock.item === itemId
		})

		if(lock){
			return lock
		} else return null
	}

  
 
	renderRepoItem(item,disablePage){
		const {isSharedRepo} = this.props
		let editedByName
		if(isSharedRepo){
			editedByName=getUserNameForId(item.lastEditedBy,this.props.repo.id)
		} 
		if(!disablePage){
			if(item.choices){
				return  (
					<DnDCell
						lastEditedBy={editedByName}
						key={`question_${item.id}`}
						isSet={false}
						item={item}
						isActive={this.isItemActive(item.id)}	
						handleSingleClick={this.props.selectRow}
						handleOpenItem={this.props.handleOpenItem}
						playItem={this.props.playItem}
						lock={this.lockForItem(item.id)}
						isMultiSelectItem={this.isItemMultiSelect(item.id)}
						navigateToItemDetail={this.props.navigateToItemDetail}
						showNotification={this.props.showNotification}
						multiselectItems={this.props.multiSelectItems}
						inSharedRepo={this.props.isSharedRepo}
						inPublishedRepo={this.props.isPublishedRepo}
					/> 
				)
			}		
			else if(item.questions){
				return (
					<DnDCell
						key={item.id}
						lastEditedBy={editedByName}
						isSet={true}
						item={item}
						lock={this.lockForItem(item.id)}
						isMultiSelectItem={this.isItemMultiSelect(item.id)}
						isActive={this.isItemActive(item.id)}	
						handleSingleClick={this.props.selectRow}
						handleOpenItem={this.props.handleOpenItem}
						navigateToItemDetail={this.props.navigateToItemDetail}
						showNotification={this.props.showNotification}
						playItem={this.props.playItem}
						multiselectItems={this.props.multiSelectItems}
						inSharedRepo={this.props.isSharedRepo}
						inPublishedRepo={this.props.isPublishedRepo}
					/>
				)
			}
		}
		else return <ItemPreviewCell item={item} key={item.id}/>

	}
 
	renderFolder(folder,disablePage){
		if(!disablePage){
			return (
				<DnDFolderCell 
					key={folder.id}
					folder={folder}	
					isActive={this.isItemActive(folder.id)}			
					handleSingleClick={this.props.selectRow}
					navigateToFolder={this.props.navigateToFolder}
					showNotification={this.props.showNotification}
					resetState={this.props.resetState}
					isMultiSelectItem={this.isItemMultiSelect(folder.id)}
					multiselectItems={this.props.multiSelectItems}
					inSharedRepo={this.props.isSharedRepo}
					inPublishedRepo={this.props.isPublishedRepo}

				/>
			)	
		}else return(
			<FolderPreviewCell 
				key={folder.id}
				folder={folder}			
			/>
		)
	}



 


	isItemMultiSelect(itemId){
		let isMultiSelect=false
		const {multiSelectEnabled,multiSelectItems}=this.props
		if(multiSelectEnabled ===true && Object.keys(multiSelectItems).length>1){
			isMultiSelect=includes(Object.keys(multiSelectItems), itemId)			
		}
		return isMultiSelect
	}



	render(){
		const {published,userHasReviewedRepo,repo,repoData,folders,joined,sortBy,parentFolder,sortOrder,isViewOnlyRepo,repoDisabled}=this.props

		let disablePage=false
		if((!joined && !published) || repoDisabled){
			disablePage=true
		}
	
 
		return( 

			<div className={'repo-content' + (parentFolder ? ' repo-content--repoFolder' : ' repo-content--repoBase')} onClick={(e)=>{e.stopPropagation()}} >			

				{repoData && disablePage && 
					<div className='repo-content-pendingInvitePageCover' />
				}

				<div className='repo-table--container' onKeyPress={this.handleUp}>
					{!userHasReviewedRepo && repo.published && repo.role!=='author' &&
						<RepoInlineRatingPanel 
							key={repo.id}
							repo={repo}
							createReview={this.props.createReview}
							showNotification={this.props.showNotification}
							user={this.props.user}
						/>
				}

				{!disablePage &&
					<StickyDividerWrapper className='repo'>	
						<div className={'sortableColumnHeaders' + (sortBy === 'name' ? ' is--sortedByName' : ' is--sortedByDate') + (sortOrder === 'ascending' ? ' is--sortedAscending' : ' is--sortedDescending')}>
							<div className='sortableColumnHeaders-name' onClick={() =>{this.props.sortRepo('byName')}}>
								Name
								<Icon name='chevron-small-up'/>
								<Icon name='chevron-small-down'/>
							</div>
							<div className='sortableColumnHeaders-modified' onClick={() =>{this.props.sortRepo('byDate')}}>
								Modified
								<Icon name='chevron-small-up'/>
								<Icon name='chevron-small-down'/>
							</div>
						</div>

					</StickyDividerWrapper>
				}

					<div className={'repo-table ' + (disablePage ? 'repo-table--shopWindow' : '')} tabIndex='-1' onKeyDown={this.props.keyPressNavigation} id='focusableLibraryTable'>		
						{folders.map((folder)=>this.renderFolder(folder,disablePage))}	
						{repoData.map((item)=>this.renderRepoItem(item,disablePage))}	

						{repoData.length === 0 && folders.length === 0 && !parentFolder && joined &&			
							<div className='repo-table-emptyState'>
								Your new Pack is all ready to go!								 
							</div>
						}
						{repoData.length === 0 && folders.length === 0 &&	parentFolder &&			
							<div className='repo-table-emptyState'>
								Empty Folder
							</div>
						}

					</div>
				</div>
				{!this.props.activeItem.ancestors && Object.keys(this.props.multiSelectItems).length < 2 &&
					<SetAndQuestionContextMenu isViewOnlyRepo={isViewOnlyRepo} key={this.props.activeItem.id} {...this.props}/>
				}
				
				{this.props.activeItem.ancestors && Object.keys(this.props.multiSelectItems).length < 2 &&
					<FolderContextMenu  isViewOnlyRepo={isViewOnlyRepo}  key={this.props.activeItem.id} {...this.props} />
				}

				{Object.keys(this.props.multiSelectItems).length >1 &&
					<MultiSelectContextMenu 
						isRepo={true} 
						isViewOnlyRepo={isViewOnlyRepo}  
						key={`${this.props.activeItem.id}_${Object.keys(this.props.multiSelectItems).length}`} 
						{...this.props} />
				}


				


			</div>
		)
	}
}


export default withRouter(RepoPage)



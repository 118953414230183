import React, { Component } from 'react'
import { connect } from 'react-redux'
import {updateSetContent } from '../../actions/sets'
import {showNotification } from '../../actions/notifications'
import InputField from '../../components/uiKit/InputField'
import Button from '../../components/uiKit/Button'
import {updateSetLock ,deleteLockForSet} from '../../actions/locks'
import throttle from 'lodash/throttle'
 
//modal for renaming set in Library 
const LOCK_THROTTLE_TIME=1000*10   
 
class EditSetContainer extends Component {
  
	constructor(props){
		super(props)
		this.updateSet=this.updateSet.bind(this)
		this.handleNameChange=this.handleNameChange.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.lockItem=this.lockItem.bind(this)

		this.lockItem = throttle(this.lockItem,LOCK_THROTTLE_TIME,{'edge': 'both' })

		let setName=''
		if(props.modalSet){
			setName=props.modalSet.name
		}

		this.state={
			setName:setName,
			buttonDisabled:false,
			userLockedSet:false
		}        
	}

	componentDidMount(){
		this.lockItem()
	}

	componentWillUnmount() {		
		if(this.state.userLockedSet){
			this.props.deleteLockForSet(this.props.modalSet.id)
			this.lockItem.cancel()
		}
	}	

	lockItem(){
		if(this.props.modalSet && this.props.modalSet.repo){
			this.setState({userLockedSet:true})
			this.props.updateSetLock(this.props.modalSet.id)
		}
	}


	updateSet(){
		this.setState({buttonDisabled:true})
		const nowDate = new Date()
		const newName=this.state.setName
		const oldName=this.props.modalSet.name
		if(newName !== oldName){
			const requestData = {...this.props.modalSet,
				'name': newName,
				'clientModified': nowDate,
			}
			this.props.updateSetContent(requestData, this.props.modalSet.id).then(() => {
				this.props.hideModal()
				const notificationMessage=`${oldName} to ${newName}`
				this.props.showNotification(notificationMessage,'Renamed','default')
			})
		}else{
			this.props.hideModal()
		}
	}

	handleNameChange(e){	
		this.lockItem()
		this.setState({
			setName:e.target.value,
		})
	}

	handleKeyDown(e){
		if (e.keyCode === 13) { 
			this.updateSet() 
		}
	}


	render() {
		let buttonDisabled=false
		if(this.state.buttonDisabled || !this.state.setName ){
			buttonDisabled=true
		}
		if(this.props.modalSet && this.state.setName === this.props.modalSet.name ){
			buttonDisabled=true
		}
		return (
			<div className='library-editFolderModal'>          
				<InputField 
					autoFocus={true} 
					onFocus={function(e) {
	    				var val = e.target.value
	    				e.target.value = ''
	    				e.target.value = val
	 	 			}}	
					value={this.state.setName}
					placeholder='Enter Set Name'
					onChangeFunction={this.handleNameChange}
					onKeyDown={this.handleKeyDown}
				/>

				<Button 
					label={'Save'}
					size='large'
					inputType='submit'
					color={buttonDisabled ? 'gray' : 'blue'}
					onClickFunction={this.updateSet}
				/>
			</div>
		)
	}
}


export default connect(
	(state) => ({
		modalSet:state.modals.set
	}),
	{updateSetContent,showNotification,updateSetLock ,deleteLockForSet }
)(EditSetContainer)



// Errors
export const showWhitelistErrorModal = (source) => ({ type: 'SHOW_WHITELIST_ERROR_MODAL', source })

// Sections

export const showNewSectionModal = () => ({ type: 'SHOW_NEW_SECTION_MODAL' })

export const showSectionSelectionModal = (item) => ({ type: 'SHOW_SECTION_SELECTION_MODAL', item })

export const showEditStudentModal = (student, section) => ({ type: 'SHOW_EDIT_STUDENT_MODAL', student, section })

export const showAssignSectionToDataOwnerModal = (section, userDataOwners) => ({ type: 'SHOW_ASSIGN_SECTION_TO_DATA_OWNER_MODAL', section, userDataOwners })

export const showDataOwnerInfoModal = (dataOwner) => ({ type: 'SHOW_DATA_OWNER_INFO_MODAL', dataOwner })

export const showBulkAddStudentsModal = (section, availableCards) => ({ type: 'SHOW_BULK_ADD_STUDENTS_MODAL', section, availableCards })

export const showConfirmEditStudentCardModal = (selectedOption, student) => ({ type: 'CONFIRM_EDIT_STUDENT_CARD_MODAL', selectedOption, student })

export const showManageSectionsModal = () => ({ type: 'SHOW_MANAGE_SECTIONS_MODAL' })

// Set/Question Editor

export const showImageSearchModal = () => ({ type: 'SHOW_IMAGE_SEARCH_MODAL' })

export const saveImageSearchModalImage = (image, thumbnail) => ({ type: 'SAVE_IMAGE', image, thumbnail })

export const finishImageUpload = () => ({ type: 'FINISH_IMAGE_UPLOAD' })

export const showImportQuestionsModal = (setId) => ({ type: 'SHOW_IMPORT_QUESTIONS_MODAL', setId })

export const showDuplicateAndEditModal = (item) => ({ type: 'SHOW_DUPLICATE_AND_EDIT_MODAL', item })

// Library

export const showEditFolderModal = (folder, parentFolder, newFolderSucess, repoId) => ({
  type: 'SHOW_EDIT_FOLDER_MODAL', folder, parentFolder, newFolderSucess, repoId,
})

export const showMoveToFolderModal = (items, clearSelected) => ({ type: 'SHOW_MOVE_TO_FOLDER_MODAL', items, clearSelected })

export const showRenameSetModal = (set) => ({ type: 'SHOW_RENAME_SET_MODAL', set })

// Repo

export const showNewRepoModal = () => ({ type: 'SHOW_NEW_REPO_MODAL' })

export const showEditRepoModal = (repo) => ({ type: 'SHOW_EDIT_REPO_MODAL', repo })

export const showRepoSelectionModal = (item, multiSelectItems, navigateToRepo) => ({
  type: 'SHOW_REPO_SELECTION_MODAL', item, multiSelectItems, navigateToRepo,
})

// Admin explorer
export const showAdminEditRepoNotesModal = (repo) => ({ type: 'SHOW_ADMIN_EDIT_REPO_NOTES_MODAL', repo })

export const showAdminEditSubmissionStatusModal = (submission) => ({ type: 'SHOW_ADMIN_EDIT_SUBMISSION_STATUS_MODAL', submission })

// Account

export const showAddLicenseKeyModal = () => ({ type: 'SHOW_ADD_LICENSE_KEY_MODAL' })

export const showPaypalEmailModal = () => ({ type: 'SHOW_PAYPAL_EMAIL_MODAL' }) // Creator fund stuff

export const showUpdateNameModal = () => ({ type: 'SHOW_UPDATE_NAME_MODAL' })

export const showUpdateUsernameModal = () => ({ type: 'SHOW_UPDATE_USERNAME_MODAL' })

export const showUpdateEmailModal = () => ({ type: 'SHOW_UPDATE_EMAIL_MODAL' })

export const showChangePasswordModal = () => ({ type: 'SHOW_CHANGE_PASSWORD_MODAL' })

export const showDeleteAccountModal = () => ({ type: 'SHOW_DELETE_ACCOUNT_MODAL' })

export const showUpdateGoogleModal = () => ({ type: 'SHOW_UPDATE_GOOGLE_MODAL' })

export const showUpdatePaymentInfoModal = () => ({ type: 'SHOW_UPDATE_PAYMENT_INFO_MODAL' })

export const showBillingInfoModal = () => ({ type: 'SHOW_BILLING_INFO_MODAL' })

export const showDowngradePlanModal = () => ({ type: 'SHOW_DOWNGRADE_PLAN_MODAL' })

export const showSwitchPlanModal = () => ({ type: 'SHOW_SWITCH_PLAN_MODAL' })

export const showExternalSigninAuthModal = (externalAccountType, operationType) => ({ type: 'SHOW_EXTERNAL_SIGNIN_AUTH_MODAL', externalAccountType, operationType })

export const showAddPasswordModal = () => ({ type: 'SHOW_ADD_PASSWORD_MODAL' })

export const showUpgradeModal = () => ({ type: 'SHOW_UPGRADE_MODAL' })

export const showEditAuthorDescriptionModal = () => ({ type: 'SHOW_EDIT_AUTHOR_DESCRIPTION_MODAL' })

// Login, Signup
export const showVerifyPasswordToSigninModal = ({ requestData, email }) => ({
  type: 'SHOW_VERIFY_PASSWORD_TO_SIGNIN_MODAL',
  requestData,
  email,
})

// Misc

export const showConfirmModal = (confirmItem, accept, actionType) => ({
  type: 'SHOW_CONFIRM_MODAL', confirmItem, accept, actionType,
})

export const showLiveViewAlertModal = () => ({ type: 'SHOW_LIVE_VIEW_ALERT_MODAL' })

export const showAddToQueueFromLibraryModal = (sectionId) => ({ type: 'SHOW_ADD_TO_QUEUE_FROM_LIBRARY_MODAL', sectionId })

export const hideModal = () => ({ type: 'HIDE_MODAL' })

export const showExpandedReviewSlideModal = (poll, processedResponses) => ({ type: 'SHOW_EXPANDED_REVIEW_SLIDE_MODAL', poll, processedResponses })

export const showExpandedQuestionSlideModal = (question) => ({ type: 'SHOW_EXPANDED_QUESTION_SLIDE_MODAL', question })

export const showScoreSheetCustomRangeModal = (currentSection) => ({ type: 'SHOW_SCORESHEET_CUSTOM_RANGE_MODAL', currentSection })

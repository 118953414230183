import React from 'react'
import DropdownMenu from './DropdownMenu'
import Button from './Button'



class Dropdown extends React.Component{	

	constructor(){
		super()
		this.handleClickOutside=this.handleClickOutside.bind(this)
		this.handleContextMenuOpen=this.handleContextMenuOpen.bind(this)
		this.handleToggleDropdown=this.handleToggleDropdown.bind(this)
		this.disableScroll=this.disableScroll.bind(this)
		this.enableScroll=this.enableScroll.bind(this)
		this.state={
			isOpen:false,
		}
	}

	componentWillUnmount() {		
		if(this.state.isOpen){
			this.enableScroll()
		}
	}

	disableScroll(){	
		document.addEventListener('click', this.handleClickOutside)
		document.addEventListener('contextmenu', this.handleContextMenuOpen)
		const scrollBars = document.getElementsByClassName('repoInvitationModal-membersList')		
		if(scrollBars.length !== 0){
			for (var i = 0; i < scrollBars.length; i++) {
				scrollBars[i].style.overflowY='hidden'  		
			}
		}else{
			document.body.style.overflowY = 'hidden'
		}

	}

	enableScroll(){		
		document.removeEventListener('click', this.handleClickOutside)
		document.removeEventListener('contextmenu', this.handleContextMenuOpen)

		const scrollBars = document.getElementsByClassName('repoInvitationModal-membersList')
		if(scrollBars.length !== 0){
			for (var i = 0; i < scrollBars.length; i++) {
				 scrollBars[i].style.overflowY=''
				 scrollBars[i].style.overflowY='overlay'
			}
		}else{
			document.body.style.overflowY='' 
			document.body.style.overflowY = 'overlay'
		}
	}

	handleClickOutside(e){
		if(this.state.isOpen){
			this.enableScroll()
			this.setState({isOpen:false})
		}
	}

	handleContextMenuOpen(e){ //shut dropdown if context menu is opened
		this.setState({isOpen:false})
		this.enableScroll()
	}

	handleToggleDropdown(e){
		if(!this.props.disabled){
			if(this.state.isOpen){
				this.setState({isOpen:false})
				this.enableScroll()
			}else{
				this.disableScroll()
				this.setState({isOpen:true})
			}
		}
	}
 
	render(){	
		const {children, position, inverted, dropdownButton} = this.props

		const button= dropdownButton ? 			
			React.cloneElement(dropdownButton,{onClickFunction:this.handleToggleDropdown , isOpen:this.state.isOpen })		
			:
			<Button  name='menu' iconName='three-dots-horizontal' iconSize='large' onClickFunction={(e) => this.handleToggleDropdown(e)}/>

		return(
			<div className='dropdown' ref={node => this.node = node}>
				{button}
				<DropdownMenu position={position} dropdownYPosTop={this.props.dropdownYPosTop} dropdownYPosBottom={this.props.dropdownYPosBottom} inverted={inverted} isOpen={this.state.isOpen}>
					{children}
				</DropdownMenu> 
				<div className='dropdown-screen' />
			</div>
		)
	}			
}


Dropdown.defaultProps = {
	disabled:false
}

export default Dropdown

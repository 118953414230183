import React, {Component} from 'react'
import { connect } from 'react-redux'
import CreatorProgramIntroPage from '../components/creatorProgram/CreatorProgramIntroPage'
import CreatorProgramUsernamePage from '../components/creatorProgram/CreatorProgramUsernamePage'
import CreatorProgramSuccessPage from '../components/creatorProgram/CreatorProgramSuccessPage'
import CreatorProgramModalVisual from '../components/creatorProgram/CreatorProgramModalVisual'
import CreatorProgramGuidelinesPage from '../components/creatorProgram/CreatorProgramGuidelinesPage'
import {updateUser,testUsername} from '../actions/user'
import Icon from '../components/misc/Icons'

//Before a user can publish a repo they have to sign up to the creator programme 
class JoinCreatorProgramContainer extends Component {

	constructor(props){
		super(props)
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)  
		this.state={
			stage:'intro',
		}	   
	}

	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden' //prevent scrolling while modal is open
	}

 
	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}

	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.props.hideModal() //close modal is click outside modal
	}

	render() {
		const {stage}=this.state
		return (
				<div className='joinCreatorProgramModalContainer' onMouseDown={this.handleBackgroundClick}>
					<div className={'joinCreatorProgramModal ' + (stage ? `joinCreatorProgramModal--${stage}` : '')}>
						<div className='joinCreatorProgramModal-pageContainer'>							
							{stage ==='intro' &&
								<CreatorProgramIntroPage 
									goToNextPage={()=>{this.setState({stage:'guidelines'})}}		  						
									hideModal={this.props.hideModal}
								/>
							}
							{stage ==='guidelines' &&
								<CreatorProgramGuidelinesPage 
									goToNextPage={()=>{this.setState({stage:'username'})}}		  						
									hideModal={this.props.hideModal}
								/>
							}
							{stage ==='username' &&
								<CreatorProgramUsernamePage
									user={this.props.user}
									updateUser={this.props.updateUser}
									testUsername={this.props.testUsername}
									goToNextPage={()=>{this.setState({stage:'success'})}}		  						
									hideModal={this.props.hideModal}
								/>
							}
							{stage ==='success' &&
								<CreatorProgramSuccessPage 
									goToNextPage={this.props.goToPublishingFlow}		  						
									username={this.props.user.username}
									hideModal={this.props.hideModal}			  						
								/>
							}		  					
						</div>
						<CreatorProgramModalVisual
							key={'visual'}
							stage={stage}
						/>
						<div className='joinCreatorProgramModal-closeBtn' onClick={this.props.hideModal}>
							<Icon name='circle-with-close' />
						</div>					
					</div>       
				</div>
		)
	}
}


export default connect(
	(state,ownProps) => ({
		user:state.user
	}),
	{  updateUser,testUsername
	})(JoinCreatorProgramContainer)


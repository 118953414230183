import React from 'react'
import find from 'lodash/find'
import Icon from '../misc/Icons'

function isSubscribedToMarket(market,subscribedMarkets){
	if(find(subscribedMarkets,{name:market})){
		return true
	}else return false
}



class DiscoverExcludedMarketsMarket extends React.PureComponent{

	render(){

		const {marketString,subscribedMarkets,subscribeToExcludedMarket,unsubscribeToExcludedMarket,meta} = this.props 
		const isSubscribed=isSubscribedToMarket(marketString,subscribedMarkets)

		return(
			<div onClick={()=>{isSubscribed?unsubscribeToExcludedMarket(marketString):subscribeToExcludedMarket(marketString)}} className={'discoverHome-upcoming-subject-market ' + (isSubscribed ? ' discoverHome-upcoming-subject-market--subscribed' : ' discoverHome-upcoming-subject-market--notSubscribed')}>				
				<div className='discoverHome-upcoming-subject-market-text'>
					{this.props.marketTitle}
				</div>
				{meta && !isSubscribed && 
					<div className='discoverHome-upcoming-subject-market-icon'>
						<Icon name='discoverNotificationBell' />						
					</div>
				}

				{meta && !isSubscribed && 
					<button className='discoverHome-upcoming-subject-market-subscribeBtn discoverHome-upcoming-subject-market-subscribeBtn--notSubscribed' >						
						Notify Me
						
					</button>
				}
				{isSubscribed && 
					<button className='discoverHome-upcoming-subject-market-subscribeBtn discoverHome-upcoming-subject-market-subscribeBtn--subscribed'>						
						Subscribed
					</button>
				}
			</div>		
		)
	}
}


class DiscoverExcludedMarketsSubjectWrapper extends React.Component{

	render(){		

		return(
			<div className='discoverHome-upcoming-subject'>
				<div className='discoverHome-upcoming-subject-title'>
					{this.props.subjectTitle}
				</div>
				<div className='discoverHome-upcoming-subject-marketsContainer'>
					{this.props.children}
				</div>

			</div>
		)
	}
}





class DiscoverExcludedMarkets extends React.Component{

	render(){
		const {subscribedMarkets,subscribeToExcludedMarket,unsubscribeToExcludedMarket,meta}=this.props

		
		return(
			<div className='discoverHome-upcoming'>
				<div className='discoverHome-upcoming-title'>
					Subscribe to updates for your subject
				</div>
				<div className='discoverHome-upcoming-subtitle'>
					Get notified when new subjects are available, and help us get there quicker by publishing your best content.
				</div>
				

				<div className='discoverHome-upcoming-subjectsContainer'>

					<DiscoverExcludedMarketsSubjectWrapper subjectTitle='Creative Arts'>
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Art' 
							marketString='art'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket}/>
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Drama' 
							marketString='drama' 
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Music' 
							marketString={'music'} 
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket}/>
					</DiscoverExcludedMarketsSubjectWrapper>

					

					<DiscoverExcludedMarketsSubjectWrapper subjectTitle='Foreign Languages'>
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='English/EFL/ESL'
							marketString='englishEFL'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='French'
							marketString='french'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Spanish'
							marketString='spanish'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
					</DiscoverExcludedMarketsSubjectWrapper>

					<DiscoverExcludedMarketsSubjectWrapper subjectTitle='Math'>
						<DiscoverExcludedMarketsMarket
							meta={meta} 
							marketTitle='Middle School Math'
							marketString='middleMath'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket
							meta={meta}
							 marketTitle='High School Math'
							 marketString='highschoolMath'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Advanced/AP Calculus'
							marketString='advancedCalculus'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket
							meta={meta} 
							marketTitle='Advanced/AP Statistics'
							marketString='advancedStatistics'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
					</DiscoverExcludedMarketsSubjectWrapper>

					<DiscoverExcludedMarketsSubjectWrapper subjectTitle='Misc Subjects'>
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Computer Science'
							marketString='computerScience'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Computer Skills'
							marketString='computrerSkills'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='ELA: Skills (Grade 6+)'
							marketString='ELASkills'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Health and Wellbeing'
							marketString='healthAndWellbeing'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Physical Education' 
							marketString='physicalEducation'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket}/>
					</DiscoverExcludedMarketsSubjectWrapper>

					<DiscoverExcludedMarketsSubjectWrapper subjectTitle='PreK - Grade 5'>
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Early ELA'
							marketString='earlyELA'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket
							meta={meta}
							 marketTitle='Early Science'
							 marketString='earlyScience'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Early Social Sciences'
							marketString='earlySocialSciences'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
					</DiscoverExcludedMarketsSubjectWrapper>

					<DiscoverExcludedMarketsSubjectWrapper subjectTitle='Science'>
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='High School Chemistry'
							marketString='highSchoolChemistry'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='High School Earth Sci'
							marketString='highSchoolEarthScience'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='High School Physics'
							marketString='highSchoolPhysics'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Advanced/AP Biology'
							marketString='advancedBiology'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Advanced/AP Chemistry'
							marketString='advancedChemistry'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Advanced/AP Earth Sci'
							marketString='advancedEarthScience'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Advanced/AP Physics'
							marketString='advancedPhysics'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
					</DiscoverExcludedMarketsSubjectWrapper>

				
					<DiscoverExcludedMarketsSubjectWrapper subjectTitle='Social Sciences'>
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Economics'
							marketString='economics'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Geography'
							marketString='geography'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='Govt. and Politics'
							marketString='politics'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
						<DiscoverExcludedMarketsMarket 
							meta={meta}
							marketTitle='History'
							marketString='history'
							subscribedMarkets={subscribedMarkets}
							subscribeToExcludedMarket={subscribeToExcludedMarket}
							unsubscribeToExcludedMarket={unsubscribeToExcludedMarket} />
					</DiscoverExcludedMarketsSubjectWrapper>

					

				</div>

			</div>
		)
	}
}


export default DiscoverExcludedMarkets


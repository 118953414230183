import React from 'react'
import { Link } from 'react-router-dom'
import {getLinkForRepo} from '../../utils/getLinkForRepo'
import Icon from '../misc/Icons'
import DiscoverRepoRatingStars from './DiscoverRepoRatingStars'
import {Image} from 'cloudinary-react'

class DiscoverFeaturedRepoLink extends React.Component{
	constructor(){
		super()
		this.onClickLink=this.onClickLink.bind(this)
	}


	onClickLink(){
		if(window.analytics){
			const {repo,rating,market}=this.props
			window.analytics.track('Click Discover Repo Link',{
				name:repo.name,
				rating:rating,
				author:repo.author.username,
				collection:'featured',
				market:market
			})}
	}


	render(){
		const {repo,rating}=this.props		

		return(
			<Link 
				to={getLinkForRepo(repo)}
				onClick={this.onClickLink}
				className='discover-featuredRepoLink'
			>				
				<div className='discover-featuredRepoLink-left'>
					<div className='discover-featuredRepoLink-left-repoImageContainer'>
						<div className='discover-featuredRepoLink-left-repoImage-imgBorder'/>
						<Image
							key={repo.id}
							cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
							publicId={`${repo.image}/repo/${repo.id}`} 
							className='discover-featuredRepoLink-left-repoImage'											
							width='450'
							height='450'
							crop='fill'
						/>
					</div>

				</div>
				<div className='discover-featuredRepoLink-center'>
					<div className='discover-featuredRepoLink-center-title'>
						{repo.name}
					</div>
					<div className='discover-featuredRepoLink-center-creator'>
						{repo.author.username}
					</div>								
					{rating && 
						<div className='discover-featuredRepoLink-center-ratingContainer'>
							<DiscoverRepoRatingStars rating={rating} showRatingLabel />
				
						</div>		
					}			
				</div>
				<div className='discover-featuredRepoLink-right'>
					<Icon name='chevron-right' />
				</div>
				
			</Link>
		)
	}
}


export default DiscoverFeaturedRepoLink


import React from 'react'
import {deleteChoice} from '../../../../prosemirror/utils/editorActions/deleteChoice'
import EditorIcon from '../../../misc/EditorIcons'


function handleMouseDown(e){
    e.preventDefault()
    e.stopPropagation()
}

class DeleteChoiceButton extends React.Component{  
	
	constructor() {
		super()
		this.onClick=this.onClick.bind(this)
	}
 
	onClick(e){
		const {getNode,getPos}=this.props 
		const node=getNode() //use this to make sure have updated node
		const nodePos=getPos(node)
		deleteChoice(window.view.state,nodePos,node)
		window.view.dom.blur()
	}


	render(){

	  return (
	  	<div className={'slide-choice-deleteBtn'} onMouseDown={handleMouseDown} onClick={this.onClick} >
	  		<EditorIcon name='closeSmall' />
	  	</div>
	  )
	}
}

export default DeleteChoiceButton

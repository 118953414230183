import { katexRenderToString } from './katexHelper'
// create markup from HTML
// (on object we save HTML and we then need to parse it a bit before rendering in slide )

export default function createMarkup(input) {
  let newHTML = ''
  if (input) {
    newHTML = input.replace(/<math data-latex="((.|\n)*?)"><\/math>/g, (outer, inner) => { // find all math nodes and turn them into katex HTML
      const katexHTML = katexRenderToString(inner)
      return `<span class="katexOuterContainer">
        <span class="katexInnerContainer">
          <span class="katexInnerContainer-content">
            ${katexHTML}
          </span>
        </span>
      </span>`
    })
  }

  return { __html: newHTML }
}

import React, { Component} from 'react'
import { connect } from 'react-redux'
import { updateStudent } from '../../actions/students'
import {hideModal} from '../../actions/modals'
import {showNotification} from '../../actions/notifications'
import InputField from '../../components/uiKit/InputField'
import Button from '../../components/uiKit/Button'



class EditStudentContainer extends Component {

	constructor(props){
		super(props)
		this.handleUpdateStudent=this.handleUpdateStudent.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)

		this.state={
			firstName:props.student.firstName,
			lastName:props.student.lastName,
			buttonDisabled:false,
			submitLoading:false
		}       
	}

	handleKeyDown(e){
		if (e.keyCode === 13) { 
			this.handleUpdateStudent()
		}
	}


	handleUpdateStudent(data) {
		this.setState({submitLoading:true})
		const {student}=this.props
		const nowDate = new Date()
		const requestBody = {
			...student,
			'firstName': this.state.firstName,
			'lastName': this.state.lastName,
			'clientModified': nowDate
		}

		this.props.updateStudent(student.id, requestBody, this.props.section.id)
			.then((response) => {
				this.props.hideModal()
				if(response==='failed'){
					const notificationMessage=''
					this.props.showNotification(notificationMessage,'Failed to update name','destroy')
				}
				else{
					const notificationMessage=`for ${this.state.firstName} ${this.state.lastName}`
					this.props.showNotification(notificationMessage,'Updated Name','default')
				}
			})

			// .catch((error) => {
			// 	this.props.hideModal()
			// 	const notificationMessage=`for ${this.state.firstName} ${this.state.lastName}`
			// 	this.props.showNotification(notificationMessage,'Failed to update name','destroy')
		 // })

		
	}

	render() {

		let buttonDisabled=false
		if(this.state.buttonDisabled || this.state.firstName==='' ){
			buttonDisabled=true
		}
		if(this.props.student&& (this.state.firstName === this.props.student.firstName && this.state.lastName===this.props.student.lastName) ){
			buttonDisabled=true
		}



		return (        
			<div className='editStudent'>   
				<div className="editStudent-form">
					<InputField
						autoFocus={true} 
						onFocus={function(e) {
	    				var val = e.target.value
	    				e.target.value = ''
	    				e.target.value = val
	 	 			}}		      
						value={this.state.firstName}
						placeholder="First Name"
						onChangeFunction={(e)=>{this.setState({firstName:e.target.value})}}
						onKeyDown={this.handleKeyDown}
					/>

					<InputField		      
						value={this.state.lastName}
						placeholder="Last Name"
						onChangeFunction={(e)=>{this.setState({lastName:e.target.value})}}
						onKeyDown={this.handleKeyDown}
					/>

					<Button 
						submitLoading={this.state.submitLoading}
						label='Update Name'
						size='xLarge'
						inputType='submit'
						canSubmit={this.props.dirty || !this.state.submitLoading ? true : false}
						color={buttonDisabled ? 'gray' : 'blue'}
						onClickFunction={this.handleUpdateStudent}
					/> 
     		</div>
			</div>      
		)
	}
}

export default connect(
	(state) => ({}),
	{ updateStudent,hideModal,showNotification}
)(EditStudentContainer)
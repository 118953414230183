export default function (state = {}, action) {
	switch (action.type) {

	case 'FETCH_SECTION_CONTROL_SUCCESS':				
		const sectionId=action.sectionId
		const response=action.response
			 return {...state,
			 [`${sectionId}`]:response
			 }
	
	case 'UPDATE_SECTION_CONTROL_SUCCESS':
		return {
			...state,
			[`${action.sectionId}`]:action.response
		}

	case 'LOGOUT':
		return {}
	
	default:
		return state
	
	}
}

import React from 'react'
import PropTypes from 'prop-types'

class QuestionCountBadge extends React.Component{

	render(){	
		const {questionCount, size, color} = this.props

		var QuestionCountBadgeColorClass = ''
		var QuestionCountBadgeSizeClass = ''

		if(color === 'white'){			
			QuestionCountBadgeColorClass = 'questionCountBadge--white '
		}
		if(color === 'gray'){			
			QuestionCountBadgeColorClass = 'questionCountBadge--gray '
		}
		if(color === 'blue'){			
			QuestionCountBadgeColorClass = 'questionCountBadge--blue '
		}

		if(color === 'blueLight'){			
			QuestionCountBadgeColorClass = 'questionCountBadge--blueLight '
		}

		if(color === 'blueDark'){			
			QuestionCountBadgeColorClass = 'questionCountBadge--blueDark '
		}
		

		if(size === 'small'){			
			QuestionCountBadgeSizeClass = 'questionCountBadge--small '
		}
		if(size === 'medium'){			
			QuestionCountBadgeSizeClass = 'questionCountBadge--mid '
		}
		if(size === 'large'){			
			QuestionCountBadgeSizeClass = 'questionCountBadge--large '
		}
		




		return(
			<div className={'questionCountBadge notranslate ' + (QuestionCountBadgeSizeClass) + (QuestionCountBadgeColorClass)}>
				{questionCount}
			</div>
		)
	}
				
}


QuestionCountBadge.defaultProps = {
	size: 'medium',	
	color: 'blue',
}

QuestionCountBadge.propTypes = {	
	size: PropTypes.string,
	color: PropTypes.string,
}


export default QuestionCountBadge



import React from 'react'
import SectionHomeStudents from './SectionHomeStudents'
import SectionHomeHistory from './SectionHomeHistory'
import SectionHomeELearningBanner from './SectionHomeELearningBanner'
import SectionHomeUpNext from './sectionHomeQueue/SectionHomeUpNext'
import SectionHomeUpcoming from './sectionHomeQueue/SectionHomeUpcoming'
import SectionHomeEmptyQueue from './sectionHomeQueue/SectionHomeEmptyQueue'
import SectionHomeAddToClassInline from './sectionHomeQueue/SectionHomeAddToClassInline'
import TopPageHeader from '../../pageHeaders/TopPageHeader'
import SectionHomeHeaderLinkTray from '../../pageHeaders/SectionHomeHeaderLinkTray'
import SectionHomeInProgress from './sectionHomeQueue/SectionHomeInProgress'
import SectionHomeEmptyStudents from './SectionHomeEmptyStudents'
import SectionHomeQueueContextMenu from './sectionHomeQueue/SectionHomeQueueContextMenu'
import SectionHomeUpNextContextMenu from './sectionHomeQueue/SectionHomeUpNextContextMenu'
import Icon from '../../misc/Icons'

class SectionHomePage extends React.Component {
  constructor(props) {
    super(props)
    this.dismissSetUpPrompt = this.dismissSetUpPrompt.bind(this)
  }

  componentDidMount() {
    if (window.analytics) {
      let testGroup = null
      const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
      if (setLimitTestGroup) {
        testGroup = setLimitTestGroup
      }
      window.analytics.page('Class', {
        // eslint-disable-next-line camelcase
        test_group_set_limit: testGroup, subpage: 'Home', sectionId: this.props.currentSection.id, title: this.props.currentSection.name,
      })
    }
  }

  dismissSetUpPrompt() {
    this.props.dismissSetUpPrompt()
    if (window.analytics) {
      window.analytics.page('Class', { subpage: 'Home', sectionId: this.props.currentSection.id, title: this.props.currentSection.name })
    }
  }

  render() {
    const {
      currentSection,
      recentHistory,
      livePoll,
      resumableSetPoll,
      upNextPoll,
      mainQueue,
      sectionIsIntegrationRostered,
      currentSectionDataOwner,
      userDataOwners,
      showAssignSectionToDataOwnerModal,
      showDataOwnerInfoModal,
    } = this.props

    const topPageHeaderHeight = userDataOwners.length ? 138 : 126

    let historyLength
    let studentsLength
    let queueIsEmpty = false
    if (mainQueue.length === 0 && !upNextPoll) {
      queueIsEmpty = true
    }
    if (recentHistory) {
      historyLength = recentHistory.length
    }
    if (currentSection) {
      if (currentSection.students) {
        studentsLength = currentSection.students.length
      }
    }

    const sectionStudentDeviceAccessEnabled = (currentSection && currentSection.studentAccess && currentSection.studentAccess.enabled)

    let showELearningBanner = false
    if (!sectionStudentDeviceAccessEnabled && currentSection && currentSection.preferences && !currentSection.preferences.studentSynchronousBannerTurnedOff) {
      showELearningBanner = true
    }

    return [
      <div className='page-centerPanel' key='centerPanel'>
        <TopPageHeader
          location='sectionHome'
          height={topPageHeaderHeight}
          header={currentSection.name}
          headerInfoRow={!!userDataOwners.length && (
            <React.Fragment>
              <div
                className='pageHeader--sectionHome-headerInfoRow-dataOwnerInfoContainer'
                onClick={currentSection.dataOwner ? () => showDataOwnerInfoModal(currentSectionDataOwner) : showAssignSectionToDataOwnerModal}
              >
                <Icon name='organization' />
                {currentSectionDataOwner && (
                <div className='pageHeader--sectionHome-headerInfoRow-text notranslate'>
                  {currentSectionDataOwner.shortName}
                </div>
                )}
                {!currentSectionDataOwner && (
                <div className='pageHeader--sectionHome-headerInfoRow-text pageHeader--sectionHome-headerInfoRow-text--notAssigned'>
                  Assign to organization
                </div>
                )}
              </div>
            </React.Fragment>
          )}
          pageHeaderRowNoTranslate
          rightBadge={<SectionHomeHeaderLinkTray disableHistory={historyLength === 0} section={currentSection} />}
          borderBottom
        />

        {showELearningBanner && (
        <SectionHomeELearningBanner
          showHelpVideoModal={this.props.openHelpVideo}
          section={currentSection}
          turnOffSyncBanner={this.props.turnOffSyncBanner}
          showActivateElearningModal={this.props.showActivateElearningModal}
          meta={this.props.meta}
        />
        )}

        {(livePoll || resumableSetPoll) && (
        <SectionHomeInProgress
          livePoll={livePoll}
          resumableSetPoll={resumableSetPoll}
          markDone={this.props.markDone}
          control={this.props.control}
          section={currentSection}
          sets={this.props.sets}
        />
        )}

        {upNextPoll && (
        <SectionHomeUpNext
          sectionHasLivePoll={!!livePoll}
          upNextPoll={upNextPoll}
          sets={this.props.sets}
          questions={this.props.questions}
          playPoll={this.props.playPoll}
          moveToUpNext={this.props.moveToUpNext}
          removeFromQueue={this.props.removeFromQueue}
          upNextContextMenuOpen={this.props.upNextContextMenuOpen}
        />
        )}

        {queueIsEmpty && (
        <SectionHomeEmptyQueue
          sectionName={currentSection.name}
        />
        )}

        {mainQueue.length > 0 && (
        <SectionHomeUpcoming
          reorderQueue={this.props.reorderQueue}
          queuePolls={mainQueue}
          upNextContextMenuOpen={this.props.upNextContextMenuOpen}
          sets={this.props.sets}
          questions={this.props.questions}
          repos={this.props.repos}
          playPoll={this.props.playPoll}
          moveToUpNext={this.props.moveToUpNext}
          removeFromQueue={this.props.removeFromQueue}
          currentSection={currentSection}
          contextMenuPoll={this.props.contextMenuPoll}
        />
        )}

        <SectionHomeAddToClassInline
          addToQueue={this.props.addToQueue}
          queueIsEmpty={queueIsEmpty}
          recentLibrary={this.props.recentLibrary}
          showAddToQueueFromLibraryModal={this.props.showAddToQueueFromLibraryModal}
        />

        {historyLength !== 0 && (
        <SectionHomeHistory
          resumableSetPoll={resumableSetPoll}
          currentSection={currentSection}
          recentHistory={this.props.recentHistory}
        />
        )}

        {(studentsLength !== 0 || sectionIsIntegrationRostered) && (
        <SectionHomeStudents
          showELearningBanner={showELearningBanner}
          toggleHideELearningSetup={this.props.toggleHideELearningSetup}
          showNotification={this.props.showNotification}
          currentSection={currentSection}
          sectionStudentDeviceAccessEnabled={sectionStudentDeviceAccessEnabled}
          sectionIsIntegrationRostered={sectionIsIntegrationRostered}
          showDistributeStudentCodesGCModal={this.props.showDistributeStudentCodesGCModal}
          eLearningSetupHidden={this.props.eLearningSetupHidden}
        />
        )}

        {studentsLength === 0 && !sectionIsIntegrationRostered && (
        <SectionHomeEmptyStudents
          showBulkAddStudentsModal={this.props.showBulkAddStudentsModal}
        />
        )}

        <SectionHomeQueueContextMenu
          onShowContextMenu={this.props.onShowContextMenu}
          onHideContextMenu={this.props.onHideContextMenu}
          handleContextMenuClick={this.props.handleContextMenuClick}
          contextMenuPollIndex={this.props.contextMenuPollIndex}
          queueLength={mainQueue.length}
        />

        <SectionHomeUpNextContextMenu
          onShowUpNextContextMenu={this.props.onShowUpNextContextMenu}
          onHideUpNextContextMenu={this.props.onHideUpNextContextMenu}
          handleUpNextContextMenuClick={this.props.handleUpNextContextMenuClick}
        />

      </div>,

      <div className='page-rightPanel' key='rightPanel' />,
    ]
  }
}

export default SectionHomePage

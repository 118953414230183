import React from 'react'
import Gallery from './imageGallery/Gallery'
import Icon from './misc/Icons'
import Button from './uiKit/Button'
import BingAttributionWithPrivacyLink from './uiKit/attribution/BingAttributionWithPrivacyLink'
import BingAttribution from './uiKit/attribution/BingAttribution'

// For repo image search modal
// TODO get rid of old GIF stuff
class ImageSearch extends React.Component {
  constructor(props) {
    super()
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.onSelectImage = this.onSelectImage.bind(this)
    this.state = ({
      images: props.images,
      image: null,
      searchTerm: props.searchTerm,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ images: nextProps.images })
  }

  handleChange(e) {
    this.setState({ searchTerm: e.target.value })
  }

  handleKeyPress(e) {
    const { searchTerm } = this.state
    if (e.keyCode === 13 && searchTerm) {
      this.props.imageSearch(searchTerm)
    }
  }

  onSelectImage(index, image) {
    this.setState({ image })
    this.setState((prevState) => {
      const images = prevState.images.slice()
      let setImageToNull = false
      images.forEach((image, i) => {
        if (i === index) {
          if (image.isSelected === true) {
            image.isSelected = false
            setImageToNull = true
          } else {
            image.isSelected = true
          }
        } else {
          image.isSelected = false
        }
      })
      const newState = { images }
      if (setImageToNull) {
        newState.image = null
      }
      return newState
    })
  }

  render() {
    const {
      isSearching, isGif, noResults, searchTerm, images, errorMessage, disableGifs,
    } = this.props
    return (
      <div className='repoImageSearch u-unselectable'>

        <div className='repoImageSearch-search'>

          <input autoFocus required type='text' value={this.state.searchTerm} placeholder={!isGif ? 'Search Internet for Images' : 'Search GIPHY for GIFs'} onChange={(e) => { this.handleChange(e) }} onKeyDown={this.handleKeyPress} />
          <div className='repoImageSearch-search-inputUI'>
            <div className='repoImageSearch-search-searchBtn' onClick={() => { this.props.imageSearch(this.state.input) }}>
              <Icon name='magnifying-glass' />
            </div>
            {images.length === 0 && isSearching === false && noResults === false && (
            <div className='repoImageSearch-search-hint'>
              Press
              {' '}
              <div className='repoImageSearch-search-hint-keyboard'>
                Enter
                <Icon name='enterKey' />
              </div>
              {' '}
              to Search
            </div>
            )}
            {images.length === 0 && isSearching === true && (
            <div className='repoImageSearch-search-hint'>
              Looking for
              {' '}
              {this.state.searchTerm}
              s ...
            </div>
            )}
          </div>

        </div>

        <div className={`repoImageSearch-body ${images.length === 0 ? 'is--empty' : ''}`}>

          {!isGif && images.length === 0 && (
          <div className='repoImageSearch-body-emptyBG'>
            <div className='repoImageSearch-body-emptyBG-bingAttributionContainer'>
              <BingAttributionWithPrivacyLink />
            </div>
          </div>
          )}

          {isGif && images.length === 0 && (
          <div className='repoImageSearch-body-emptyBG repoImageSearch-body-emptyBG--GIF'>
            <img alt='' src='https://assets.plickers.com/react-assets/images/Poweredby_640px-White_HorizText.png' />
          </div>
          )}

          {noResults === true && (
          <div className='repoImageSearch-body-noResults'>
            No Results for '
            {searchTerm}
            '
          </div>
          )}

          {errorMessage && (
          <div className='repoImageSearch-body-noResults'>
            {errorMessage}
          </div>
          )}

          {noResults === false && images.length > 0 && (
          <div className={`repoImageSearch-imageGrid${this.state.image ? ' has--selectedImage' : ''}`}>
            <Gallery
              images={images}
              onClickThumbnail={this.props.onImageSelected}
            />
          </div>
          )}

          {isSearching && images.length > 0 && (
          <div>
            <div className='repoImageSearch-imageGrid-loadMore'>
              <Button label='Loading More' size='medium' />
            </div>
          </div>
          )}

          {images.length > 0 && isSearching === false && images.length < parseInt(this.props.searchResultCount, 10) && (
          <div>
            <div className='repoImageSearch-imageGrid-loadMore'>
              <Button label='Show more results' size='medium' onClickFunction={() => { this.props.loadMore() }} />
            </div>
          </div>
          )}

          {isGif && images.length > 0 && isSearching === false && (
          <div>
            <div className='repoImageSearch-imageGrid-loadMore'>
              <Button label='Show more Gifs' size='medium' onClickFunction={() => { this.props.loadMoreGifs() }} />
            </div>
          </div>
          )}

        </div>

        <div className={`repoImageSearch-footer${images.length > 0 ? ' has--images' : ' has--noImages'}`}>

          <div className='repoImageSearch-footer-upload'>
            <input
              id='file'
              type='file'
              value=''
              name='uploadImage'
              accept={`.png,.jpg,.jpeg,.tif,.tiff ${disableGifs ? '' : ',.gif'}`}
              className='repoImageSearch-footer-uploadBtn-hiddenInput'
              onChange={(e) => {
                this.props.handleFileUpload(e)
              }}
            />
            <label htmlFor='file' className='repoImageSearch-footer-uploadBtn'>Upload Image from Computer...</label>
          </div>

          <div className='repoImageSearch-footer-bingAttributionContainer'>
            <BingAttribution />
          </div>

        </div>
      </div>
    )
  }
}

export default ImageSearch

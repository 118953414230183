export function formatMillisecondDuration(duration,displayMilliseconds,displayHours){

	let centiseconds = ("0" + (Math.floor(duration / 10) % 100)).slice(-2)
	let seconds = ("0" + (Math.floor(duration / 1000) % 60)).slice(-2)
	let minutes = ("0" + (Math.floor(duration / 60000) % 60)).slice(-2)
	let hours = ("0" + Math.floor(duration / 3600000)).slice(-2)

	if(displayMilliseconds){
		return `${minutes}:${seconds}.${centiseconds}`
	}else{
		if(!displayHours){
			return `${minutes}:${seconds}`
		}else{
			return `${hours}:${minutes}:${seconds}`
		}
		
	}
}
	
import React from 'react'

class ExplorerCrossFilterDisplayControl extends React.Component{ 
 


	render(){

 
		return( 
			<div className={'explorerCrossFilter-displayControl ' + (this.props.name ? `explorerCrossFilter-displayControl--${this.props.name}` : '')} >
				<div className='explorerCrossFilter-displayControl-title'>
					{this.props.title}
				</div>
				<div className='explorerCrossFilter-displayControl-contents'>
					{this.props.children}
				</div>			
			</div>
		)
	}
}


export default ExplorerCrossFilterDisplayControl



import React from 'react'
 
class CreatorListCreatorCell extends React.Component{ 
	
	onClickViewPack(e,repoLink){	
		e.stopPropagation()
		window.open(`https://${repoLink}`,'_blank')	
	}

	render(){
		const {creator,selectedCreator} = this.props
		const creatorItemActive = selectedCreator && creator.id===selectedCreator.id
		return( 
			<div className={'creatorsList-creatorCell ' + (creatorItemActive ? 'creatorsList-creatorCell--active' : 'creatorsList-creatorCell--inactive')} onClick={()=>{this.props.setSelectedCreator(creator)}} >					
					<div className='creatorsList-creatorCell-topLine'>
						<div className='creatorsList-creatorCell-topLine-repoName'>						
							{creator.firstName} {creator.lastName}
						</div>
										
						<div className='creatorsList-creatorCell-topLine-date'>
							
						</div>						
					</div>
					
					<div className='creatorsList-creatorCell-author'>
						{creator.username}
					</div>					

					<div className='creatorsList-creatorCell-metadata'>
						{creator.email}
					</div>
			<div className='creatorsList-creatorCell-bottomBorder' />			
		</div>
		)
	}
}

export default CreatorListCreatorCell

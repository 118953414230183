import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import sortBy from 'lodash/sortBy'
import range from 'lodash/range'
import indexOf from 'lodash/indexOf'
import filter from 'lodash/filter'
import find from 'lodash/find'
import remove from 'lodash/remove'
import moment from 'moment'
import {
  fetchSection,
  removeIntegrationFromSection,
  syncSectionWithIntegration,
  deleteSection,
  updateSection,
  archiveSection,
  updateSectionPreferences,
  toggleStudentAccessToSection,
  assignSectionToDataOwner,
} from '../../actions/sections'
import { upsertIntegration } from '../../actions/integrations'
import { showLiveViewModal } from '../../utils/liveView/showLiveViewModal'
import { updateSectionControl, fetchSectionControl } from '../../actions/sectionControl'
import {
  showAddToQueueFromLibraryModal,
  showAssignSectionToDataOwnerModal,
  showBulkAddStudentsModal,
  showConfirmEditStudentCardModal,
  showConfirmModal,
  hideModal,
  showEditStudentModal,
  showDataOwnerInfoModal,
} from '../../actions/modals'
import { newStudent, deleteStudent, updateStudent } from '../../actions/students'
import { showNotification, hideNotification } from '../../actions/notifications'
import {
  fetchSingleSetPoll,
  newQuestionPoll,
  updateQuestionPoll,
  newSetPoll,
  updateSetPoll,
  fetchQuestionPolls,
  fetchSetPolls,
} from '../../actions/polls'
import { playPoll } from '../../utils/playItem'
import { createPoll } from '../../utils/createPoll'
import { parseName } from '../../utils/parseName'
import SectionStudents from '../../components/section/sectionStudents/SectionStudentsPage'
import SectionSetup from '../../components/section/SectionSetup'
import SectionHome from '../../components/section/sectionHome/SectionHomePage'
import SectionHomeCompleteSetupPrompt from '../../components/section/sectionHome/SectionHomeCompleteSetupPrompt'
import HistoryContainer from '../HistoryContainer'
import { openItemInEditor } from '../../utils/openItemInEditor'
import { clearSectionControl } from '../../utils/clearSectionControl'
import HelpVideoModal from '../../components/HelpVideoModal'
import ActivateElearningModalContainer from './ActivateElearningModalContainer'
import DistributeStudentCodesGCModalContainer from './DistributeStudentCodesGCModalContainer'
import { getSetForId } from '../../utils/getSetForId'
import { getQuestionForId } from '../../utils/getQuestionForId'
import requestGoogleAuthCode from '../../utils/googleIdentity/requestGoogleAuthCode'
import getUserDataOwners from '../../utils/getUserDataOwners'

const numOfItemsInRecentHistory = 8
const numOfItemsInRecentlyModifiedLibrary = 6

function reinsert(arr, from, to) {
  const _arr = arr.slice(0)
  const val = _arr[from]
  _arr.splice(from, 1)
  _arr.splice(to, 0, val)
  return _arr
}

class SectionContainer extends Component {
  constructor(props) {
    super(props)
    this.handleRemoveFromQueue = this.handleRemoveFromQueue.bind(this)
    this.handleDeleteStudent = this.handleDeleteStudent.bind(this)
    this.handleNewStudent = this.handleNewStudent.bind(this)
    this.getAvailableCardsForSection = this.getAvailableCardsForSection.bind(this)
    this.getCardsWithStudents = this.getCardsWithStudents.bind(this)
    this.getSetupStep = this.getSetupStep.bind(this)
    this.handleReorderQueue = this.handleReorderQueue.bind(this)
    this.handleAddToQueue = this.handleAddToQueue.bind(this)
    this.handlePlayPoll = this.handlePlayPoll.bind(this)
    this.handleUpdateStudentCard = this.handleUpdateStudentCard.bind(this)
    this.handleDeleteSection = this.handleDeleteSection.bind(this)
    this.handleArchiveStudent = this.handleArchiveStudent.bind(this)
    this.handleClearSectionControl = this.handleClearSectionControl.bind(this)
    this.showArchivedStudents = this.showArchivedStudents.bind(this)
    this.showActiveStudents = this.showActiveStudents.bind(this)
    this.confirmDeleteStudent = this.confirmDeleteStudent.bind(this)
    this.confirmArchiveSection = this.confirmArchiveSection.bind(this)
    this.handleArchiveSection = this.handleArchiveSection.bind(this)
    this.handleMoveToUpNext = this.handleMoveToUpNext.bind(this)
    this.handleAssignSectionToDataOwnerModal = this.handleAssignSectionToDataOwnerModal.bind(this)
    this.handleShowBulkAddStudentsModal = this.handleShowBulkAddStudentsModal.bind(this)
    this.handleShowAddToQueueFromLibraryModal = this.handleShowAddToQueueFromLibraryModal.bind(this)
    this.handleNavigateToSectionHome = this.handleNavigateToSectionHome.bind(this)
    this.handleContextMenuClick = this.handleContextMenuClick.bind(this)
    this.movePollUpQueue = this.movePollUpQueue.bind(this)
    this.movePollDownQueue = this.movePollDownQueue.bind(this)
    this.onHideContextMenu = this.onHideContextMenu.bind(this)
    this.onShowContextMenu = this.onShowContextMenu.bind(this)
    this.onHideUpNextContextMenu = this.onHideUpNextContextMenu.bind(this)
    this.onShowUpNextContextMenu = this.onShowUpNextContextMenu.bind(this)
    this.handleUpNextContextMenuClick = this.handleUpNextContextMenuClick.bind(this)
    this.skipCurrentSetupStep = this.skipCurrentSetupStep.bind(this)
    this.markDone = this.markDone.bind(this)
    this.openHelpVideo = this.openHelpVideo.bind(this)
    this.turnOffSyncBanner = this.turnOffSyncBanner.bind(this)
    this.syncSectionWithIntegration = this.syncSectionWithIntegration.bind(this)
    this.removeIntegrationFromSection = this.removeIntegrationFromSection.bind(this)
    this.confirmRemoveIntegrationFromSection = this.confirmRemoveIntegrationFromSection.bind(this)
    this.shouldShowConsentModal = this.shouldShowConsentModal.bind(this)
    this.dismissConsentModal = this.dismissConsentModal.bind(this)
    this.onSendCodesSuccess = this.onSendCodesSuccess.bind(this)
    this.reauthenticateIntegration = this.reauthenticateIntegration.bind(this)
    this.toggleHideELearningSetup = this.toggleHideELearningSetup.bind(this)

    const sectionELearningSetupSettings = JSON.parse(localStorage.getItem('sectionELearningSetupSettings'))

    let eLearningSetupHidden = false
    if (sectionELearningSetupSettings && sectionELearningSetupSettings[props.currentSection.id] && sectionELearningSetupSettings[props.currentSection.id].setupHidden) {
      eLearningSetupHidden = true
    }

    this.state = {
      eLearningSetupHidden,
      availableCards: [],
      showArchived: false,
      contextMenuPoll: {},
      contextMenuPollIndex: null,
      upNextContextMenuOpen: false,
      showHelpVideoModal: false,
      showActivateElearningModal: false,
      showDistributeCodesGCModal: false,
      isElearningActivationFlow: false,
      skipDataOwnerSetup: false,
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params.id) {
      this.props.fetchSection(this.props.match.params.id)
      this.props.fetchQuestionPolls()
      this.props.fetchSetPolls()
      this.props.fetchSectionControl(this.props.match.params.id)
      this.getAvailableCardsForSection(this.props)
      this.getCardsWithStudents(this.props)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id && nextProps.match.params.id !== this.props.match.params.id) {
      this.props.fetchSectionControl(nextProps.match.params.id)
      this.props.fetchSection(this.props.match.params.id)
      this.props.fetchQuestionPolls()
      this.props.fetchSetPolls()
      this.props.hideNotification()
    }
    if (nextProps.livePoll !== this.props.livePoll && this.props.match.params.id) {
      this.props.fetchSectionControl(this.props.match.params.id)
    }

    if (nextProps.match.params.sectionNav !== this.props.match.params.sectionNav) {
      this.props.hideNotification()
    }
    if (nextProps.currentSection && nextProps.currentSection !== this.props.currentSection) {
      const sectionELearningSetupSettings = JSON.parse(localStorage.getItem('sectionELearningSetupSettings'))
      let eLearningSetupHidden = false
      if (sectionELearningSetupSettings && sectionELearningSetupSettings[nextProps.currentSection.id] && sectionELearningSetupSettings[nextProps.currentSection.id].setupHidden) {
        eLearningSetupHidden = true
      }
      this.setState({
        eLearningSetupHidden,
        skipDataOwnerSetup: false,
      })
    }
    this.getAvailableCardsForSection(nextProps)
    this.getCardsWithStudents(nextProps)
  }

  componentWillUnmount() {
    this.props.hideNotification()
  }

  toggleHideELearningSetup() {
    const { eLearningSetupHidden } = this.state
    const sectionELearningSetupSettings = JSON.parse(localStorage.getItem('sectionELearningSetupSettings'))
    let newSectionELearningSetupSettings
    const sectionId = this.props.currentSection.id
    if (sectionELearningSetupSettings) {
      newSectionELearningSetupSettings = sectionELearningSetupSettings
      newSectionELearningSetupSettings[sectionId] = {}
      newSectionELearningSetupSettings[sectionId].setupHidden = !eLearningSetupHidden
    } else {
      newSectionELearningSetupSettings = {}
      newSectionELearningSetupSettings[sectionId] = {}
      newSectionELearningSetupSettings[sectionId].setupHidden = true
    }
    localStorage.setItem('sectionELearningSetupSettings', JSON.stringify(newSectionELearningSetupSettings))
    this.setState({ eLearningSetupHidden: !eLearningSetupHidden })
  }

  turnOffSyncBanner() { // E-Learning banner
    const nowDate = new Date()
    const requestData = {
      studentSynchronousBannerTurnedOff: true,
      controlled: nowDate,
    }
    this.props.updateSectionPreferences(requestData, this.props.currentSection.id)
  }

  openHelpVideo() {
    if (window.analytics) {
      window.analytics.track('Open sync beta help video')
    }
    this.setState({ showHelpVideoModal: true })
  }

  scrollToStudentSection() {
    const studentDiv = document.getElementById('sectionStudents')
    if (studentDiv) {
      studentDiv.scrollIntoView({ block: 'start', behavior: 'instant' })
    }
  }

  handleDeleteStudent(student) {
    this.props.deleteStudent(student.id, this.props.currentSection.id).then(() => {
      this.props.hideModal()
      const notificationMessage = `${student.firstName} ${student.lastName}`
      this.props.showNotification(notificationMessage, 'Deleted', 'destroy')
    })
  }

  confirmDeleteStudent(student) {
    this.props.showConfirmModal(student, this.handleDeleteStudent, 'delete')
  }

  handleDeleteSection() {
    this.props.deleteSection(this.props.currentSection.id, this.context.router)
  }

  handleArchiveSection() {
    const { currentSection } = this.props
    const nowDate = new Date()
    const requestData = {
      ...currentSection,
      archived: true,
      clientModified: nowDate,
    }

    this.props.archiveSection(requestData, currentSection.id).then(() => {
      this.props.hideModal()
      this.props.history.push('/')
      const notificationMessage = `${currentSection.name}`
      this.props.showNotification(notificationMessage, 'Archived', 'default')
    })
  }

  confirmArchiveSection() {
    this.props.showConfirmModal(this.props.currentSection, this.handleArchiveSection, 'archive')
  }

  handleClearSectionControl() {
    const nowDate = new Date()
    const requestBody = {
      resumableSetPoll: null,
      controlled: nowDate,
    }
    this.props.updateSectionControl(this.props.currentSection.id, requestBody)
  }

  handleNewStudent(name) {
    const parsedName = parseName(name)
    const nowDate = new Date()
    const requestData = {
      enteredName: name,
      section: this.props.currentSection.id,
      clientModified: nowDate,
      userCreated: nowDate,
      firstName: parsedName.firstName,
      lastName: parsedName.lastName,
      archived: false,
      card: this.state.availableCards[0],
    }
    this.props.newStudent(requestData).then(() => {
      this.setState((prevState) => ({ availableCards: [...prevState.availableCards.slice(1)] }))
      const notificationMessage = `${parsedName.firstName} ${parsedName.lastName}`
      this.props.showNotification(notificationMessage, 'Added', 'default')
    })
  }

  handleAddToQueue(suggestion) {
    if (this.props.currentSection && this.props.currentSection.id) {
      this.props.createPoll(suggestion, this.props.currentSection.id)
    }
  }

  handleRemoveFromQueue(poll) {
    const nowDate = new Date()
    const requestBody = {
      ...poll,
      canceled: true,
      clientModified: nowDate,
    }
    if (poll.setObject) {
      this.props.updateSetPoll(poll.id, requestBody)
    } else {
      this.props.updateQuestionPoll(poll.id, requestBody)
    }
  }

  handleMoveToUpNext(poll) {
    // if there is no livePoll move to begining of queue (planned time of current top poll - buffer)
    // if there is a livePoll move to inbetween livePoll and upNext Poll
    const nowDate = new Date()
    const bufferDays = 1
    let newUpNextPlanned = null
    const { livePoll, upNextPoll } = this.props
    const oldUpNextPlanned = moment(upNextPoll.planned)
    if (livePoll) {
      const livePollPlanned = moment(livePoll.planned)
      const difference = livePollPlanned.diff(oldUpNextPlanned)
      newUpNextPlanned = livePollPlanned.subtract(difference / 2)
    } else {
      newUpNextPlanned = moment(oldUpNextPlanned).subtract(bufferDays, 'd')
    }
    const requestBody = {
      ...poll,
      clientModified: nowDate,
      planned: newUpNextPlanned.toISOString(),
    }
    if (poll.setObject) {
      return this.props.updateSetPoll(poll.id, requestBody)
    }
    return this.props.updateQuestionPoll(poll.id, requestBody)
  }

  handleReorderQueue(oldIndex, newIndex, newArray) {
    let newPlanned = null
    const { upNextPoll } = this.props
    const nowDate = new Date()
    // if the new index is at the end of the queue, planned timestamp is set to the current datetime
    if (newIndex === (newArray.length - 1)) {
      newPlanned = moment()
    } else {
      let pollBeforePlanned
      if (newIndex === 0) { // is the new index is at the begining of the queue planned timestamp is set to the average of upNext planned time and next poll in queue
        pollBeforePlanned = moment(upNextPoll.planned)
      } else { // otherwise find the items either side of the new position and average their planned times
        pollBeforePlanned = moment(newArray[newIndex - 1].planned)
      }
      const pollAfterPlanned = moment(newArray[newIndex + 1].planned)
      newPlanned = moment(pollBeforePlanned).subtract(moment(pollBeforePlanned).diff(moment(pollAfterPlanned)) / 2)
    }
    const poll = newArray[newIndex]
    const requestBody = {
      ...poll,
      clientModified: nowDate,
      planned: newPlanned.toISOString(),
    }
    if (poll.setObject) {
      return this.props.updateSetPoll(poll.id, requestBody).then(() => {})
    }
    return this.props.updateQuestionPoll(poll.id, requestBody).then(() => {
    })
  }

  movePollUpQueue(oldIndex) { // move an item one place up the queue
    const newIndex = Math.max(0, oldIndex - 1)

    if (newIndex !== oldIndex) {
      const newArray = reinsert(this.props.mainQueue, oldIndex, newIndex)
      this.handleReorderQueue(oldIndex, newIndex, newArray)
    }
  }

  movePollDownQueue(oldIndex) { // move an item one place down the queue
    const newIndex = Math.min(this.props.mainQueue.length - 1, oldIndex + 1)
    if (newIndex !== oldIndex) {
      const newArray = reinsert(this.props.mainQueue, oldIndex, newIndex)
      this.handleReorderQueue(oldIndex, newIndex, newArray)
    }
  }

  handlePlayPoll(poll, item) {
    this.props.playPoll(poll, item)
    showLiveViewModal()
  }

  getAvailableCardsForSection(props) {
    const availableCards = range(1, 64)
    if (props.currentSection) {
      if (props.currentSection.students) {
        for (let i = props.currentSection.students.length - 1; i >= 0; i--) {
          const indexToRemove = indexOf(availableCards, props.currentSection.students[i].card)
          // Only when student card is vaild, splice it from available cards array
          if (indexToRemove >= 0) {
            availableCards.splice(indexToRemove, 1)
          }
        }
      }
    }
    this.setState({ availableCards })
  }

  getCardsWithStudents(props) { // [{card:cardNumber,student:student},....]
    let cardsWithStudents = []
    for (let i = 0; i < 63; i++) {
      const cardNumber = i + 1
      cardsWithStudents.push({ card: cardNumber, student: null })
    }
    if (props.currentSection) {
      if (props.currentSection.students) {
        for (let index = props.currentSection.students.length - 1; index >= 0; index--) {
          const indexofCard = props.currentSection.students[index].card - 1
          // Only when student card is vaild, splice it from available cards array
          if (indexofCard >= 0) {
            const cardNumber = indexofCard + 1
            cardsWithStudents = [
              ...cardsWithStudents.slice(0, indexofCard),
              { card: cardNumber, student: props.currentSection.students[index] },
              ...cardsWithStudents.slice(indexofCard + 1)]
          }
        }
        this.setState({ cardsWithStudents })
      }
    }
  }

  getSetupStep() {
    const { currentSection, userDataOwners } = this.props
    const { skipDataOwnerSetup } = this.state
    if (skipDataOwnerSetup) {
      return 'students'
    }

    const showShowDataOwnerPrompt = !currentSection.dataOwner &&
      userDataOwners.length > 0

    return showShowDataOwnerPrompt ?
      'dataOwner' :
      'students'
  }

  handleUpdateStudentCard(student, newCard) {
    const nowDate = new Date()
    const requestBody = {
      firstName: student.firstName,
      lastName: student.lastName,
      card: newCard,
      archived: student.archived,
      clientModified: nowDate,
    }
    this.props.updateStudent(student.id, requestBody, this.props.currentSection.id)
  }

  handleArchiveStudent(student) { // toggle archive/unarchive student
    const nowDate = new Date()
    let card = -1
    if (student.archived) {
      card = this.state.availableCards[0]
    }
    const requestBody = {
      ...student,
      card,
      archived: !student.archived,
      clientModified: nowDate,
    }
    this.props.updateStudent(student.id, requestBody, this.props.currentSection.id).then(() => {
      let notificationActionWord
      let notificationType
      const notificationMessage = `${student.firstName} ${student.lastName}`
      if (student.archived) {
        notificationActionWord = 'Restored'
        notificationType = 'create'
      } else {
        notificationActionWord = 'Archived'
        notificationType = 'destroy'
      }
      this.props.showNotification(notificationMessage, notificationActionWord, notificationType)
    })
  }

  showArchivedStudents() {
    this.setState({ showArchived: true })
  }

  showActiveStudents() {
    this.setState({ showArchived: false })
  }

  handleAssignSectionToDataOwnerModal() {
    const {
      showAssignSectionToDataOwnerModal,
      currentSection,
      userDataOwners,
    } = this.props
    showAssignSectionToDataOwnerModal(currentSection, userDataOwners)
  }

  handleShowBulkAddStudentsModal() {
    this.props.showBulkAddStudentsModal(this.props.currentSection, this.state.availableCards)
  }

  handleShowAddToQueueFromLibraryModal() {
    this.props.showAddToQueueFromLibraryModal(this.props.currentSection.id)
  }

  handleNavigateToSectionHome() {
    this.props.history.push(`/classes/${this.props.match.params.id}`)
  }

  onShowContextMenu(e) {
    this.setState({ contextMenuPoll: e.detail.data.queuePoll, contextMenuPollIndex: e.detail.data.index })
    document.body.style.overflowY = 'hidden'
  }

  onHideContextMenu() {
    this.setState({ contextMenuPoll: {}, contextMenuPollIndex: null })
    document.body.style.overflowY = '' // overlay is not supported by firefox, so default to blank (auto style comes from css)
    document.body.style.overflowY = 'overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
  }

  onShowUpNextContextMenu() {
    this.setState({ upNextContextMenuOpen: true })
    document.body.style.overflowY = 'hidden'
  }

  onHideUpNextContextMenu() {
    this.setState({ upNextContextMenuOpen: false })
    document.body.style.overflowY = '' // overlay is not supported by firefox, so default to blank (auto style comes from css)
    document.body.style.overflowY = 'overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
  }

  handleContextMenuClick(e, data) {
    e.stopPropagation()
    if (data.action === 'RemoveFromQueue') {
      this.handleRemoveFromQueue(data.queuePoll)
    } else if (data.action === 'Play') {
      this.handlePlayPoll(data.queuePoll, data.item)
    } else if (data.action === 'Open') {
      openItemInEditor(data.item.id, data.isSet)
    } else if (data.action === 'UpNext') {
      this.handleMoveToUpNext(data.queuePoll)
    } else if (data.action === 'MoveUp') {
      this.movePollUpQueue(data.index)
    } else if (data.action === 'MoveDown') {
      this.movePollDownQueue(data.index)
    }
  }

  handleUpNextContextMenuClick(e, data) {
    e.stopPropagation()
    if (data.action === 'RemoveFromQueue') {
      this.handleRemoveFromQueue(data.upNextPoll)
    } else if (data.action === 'Play') {
      this.handlePlayPoll(data.upNextPoll, data.item)
    } else if (data.action === 'Open') {
      openItemInEditor(data.item.id, data.isSet)
    } else if (data.action === 'MoveDown') {
      this.movePollDownQueue(data.index)
    }
  }

  skipCurrentSetupStep() {
    const { currentSection, updateSectionPreferences } = this.props
    const currentStep = this.getSetupStep()
    if (currentStep === 'dataOwner') {
      this.setState({ skipDataOwnerSetup: true })
    } else if (currentStep === 'students') {
      const nowDate = new Date()
      const requestData = {
        setUpPromptDismissed: true,
        controlled: nowDate,
      }
      updateSectionPreferences(requestData, currentSection.id)
    }
  }

  markDone() {
    const { resumableSetPoll } = this.props
    if (!resumableSetPoll.hasResponses) { // if poll doesnt have responses, cancel it to prevent it returning to the queue
      this.handleRemoveFromQueue(resumableSetPoll)
    }
    this.props.clearSectionControl(this.props.currentSection.id).then(() => {
      let notificationMessage = ''
      if (resumableSetPoll.snapshot) {
        notificationMessage = `${resumableSetPoll.snapshot.name}`
      }
      this.props.showNotification(notificationMessage, 'Marked Done', 'default')
    })
  }

  reauthenticateIntegration() { // if sync Google Classroom fails reauthorize section
    const emailHint = this.props.sectionIntegrationRosterEmail
    requestGoogleAuthCode({
      scopeGroup: 'googleClassroomBase',
      hint: emailHint,
      onSuccess: (codeResponse) => {
        const requestBody = {
          type: 'googleClassroom',
          authCode: codeResponse.code,
        }
        this.props.upsertIntegration(requestBody)
          .then((integration) => {
            const selectedEmail = integration.email
            if (selectedEmail === emailHint) {
              this.syncSectionWithIntegration(true)
            } else {
              this.props.showNotification(`Please select the linked Google account (${emailHint})`, 'Failed to sync.', 'destroy')
            }
          })
          .catch(() => {
            this.props.showNotification('Something went wrong', '', 'destroy')
          })
      },
      onFailure: (errorType) => {
        if (errorType === 'noCode') return
        const errorMessage = errorType === 'insufficientScopes' ?
          'Insufficient access on Google Classroom' :
          `Something went wrong (${errorType})`
        this.props.showNotification(errorMessage, 'Failed to sync. ', 'destroy')
      },
    })
  }

  syncSectionWithIntegration(throwIfError) {
    return this.props.syncSectionWithIntegration(this.props.currentSection.id)
      .then(() => {
        this.props.showNotification('', 'Successfully Synced your Class Roster with Google Classroom', 'default')
      })
      .catch((e) => {
        if (throwIfError) throw e
        this.reauthenticateIntegration()
      })
  }

  removeIntegrationFromSection() {
    this.props.removeIntegrationFromSection(this.props.currentSection.id).then(() => {
      this.props.hideModal()
      this.props.showNotification('', 'Class Delinked from Google Classroom', 'default')
    })
  }

  confirmRemoveIntegrationFromSection() {
    this.props.showConfirmModal(this.props.currentSection, this.removeIntegrationFromSection, 'delinkSection')
  }

  onSendCodesSuccess() {
    const sectionELearningSetupSettings = JSON.parse(localStorage.getItem('sectionELearningSetupSettings'))
    let newSectionELearningSetupSettings = {}
    const sectionId = this.props.currentSection.id
    if (sectionELearningSetupSettings) {
      newSectionELearningSetupSettings = sectionELearningSetupSettings
    }
    newSectionELearningSetupSettings[sectionId] = {}
    newSectionELearningSetupSettings[sectionId].setupHidden = true
    localStorage.setItem('sectionELearningSetupSettings', JSON.stringify(newSectionELearningSetupSettings))
    this.setState({ eLearningSetupHidden: true, isElearningActivationFlow: false })
  }

  shouldShowConsentModal() {
    const { location } = this.props.history
    if (this.state.showActivateElearningModal) {
      return true
    } if (location.hash === '#consent') {
      return true
    }
    return false
  }

  dismissConsentModal() {
    this.setState({ showActivateElearningModal: false })
    window.location.hash = ''
  }

  render() {
    // sectionNav is null, /reports, /students or /settings
    const subsection = this.props.match.params.sectionNav || 'home'
    const {
      currentSection,
      currentSectionDataOwner,
      userDataOwners,
      sectionIsIntegrationRostered,
      sectionIntegrationRosterEmail,
      showDataOwnerInfoModal,
    } = this.props

    let documentTitle
    if (this.props.currentSection) {
      if (subsection === 'home') {
        documentTitle = `${this.props.currentSection.name || 'Class'} - Plickers`
      } else if (subsection === 'reports') {
        documentTitle = `${this.props.currentSection.name || 'Class'} Reports - Plickers`
      } else if (subsection === 'students') {
        documentTitle = `${this.props.currentSection.name || 'Class'} Students - Plickers`
      } else if (subsection === 'settings') {
        documentTitle = `${this.props.currentSection.name || 'Class'} Settings - Plickers`
      }
    }

    // figure out if should show "Finish setup" prompt
    let setUpComplete = true
    if (subsection === 'home') {
      let skippedStudentSetup = false
      if (currentSection.preferences) {
        skippedStudentSetup = currentSection.preferences.setUpPromptDismissed
      }
      if (currentSection) {
        if (sectionIsIntegrationRostered) {
          setUpComplete = true
        } else if (currentSection.students) {
          if (currentSection.students.length === 0 && !skippedStudentSetup) {
            setUpComplete = false
          }
        }
      }
    }
    return (
      <DocumentTitle title={documentTitle}>
        <div className={`page ${subsection === 'home' ? 'page--wideLayout' : ''}
        ${subsection === 'reports' ? 'page--defaultLayout' : ''}
        ${subsection === 'students' ? 'page--sidePanelLayout' : ''}
        ${subsection === 'settings' ? 'page--defaultLayout' : ''}`}
        >

          <div className='page-leftPanel' />

          {subsection === 'home' && (
          <React.Fragment>
            {setUpComplete && (
            <SectionHome
              toggleHideELearningSetup={this.toggleHideELearningSetup}
              eLearningSetupHidden={this.state.eLearningSetupHidden}
              key={currentSection.id}
              removeFromQueue={this.handleRemoveFromQueue}
              reorderQueue={this.handleReorderQueue}
              currentSection={this.props.currentSection}
              moveToUpNext={this.handleMoveToUpNext}
              sets={this.props.sets}
              questions={this.props.questions}
              recentHistory={this.props.recentHistory}
              playPoll={this.handlePlayPoll}
              showAddToQueueFromLibraryModal={this.handleShowAddToQueueFromLibraryModal}
              sections={this.props.sections}
              sectionControl={this.props.sectionControl}
              clearSectionControl={this.handleClearSectionControl}
              showAssignSectionToDataOwnerModal={this.handleAssignSectionToDataOwnerModal}
              userDataOwners={userDataOwners}
              showDataOwnerInfoModal={showDataOwnerInfoModal}
              currentSectionDataOwner={currentSectionDataOwner}
              showBulkAddStudentsModal={this.handleShowBulkAddStudentsModal}
              addToQueue={this.handleAddToQueue}
              livePoll={this.props.livePoll}
              liveSetQuestion={this.props.liveSetQuestion}
              upNextPoll={this.props.upNextPoll}
              resumableSetPoll={this.props.resumableSetPoll}
              mainQueue={this.props.mainQueue}
              recentLibrary={this.props.recentLibrary}
              control={this.props.control}
              handleContextMenuClick={this.handleContextMenuClick}
              onShowContextMenu={this.onShowContextMenu}
              onHideContextMenu={this.onHideContextMenu}
              contextMenuPoll={this.state.contextMenuPoll}
              contextMenuPollIndex={this.state.contextMenuPollIndex}
              handleUpNextContextMenuClick={this.handleUpNextContextMenuClick}
              onShowUpNextContextMenu={this.onShowUpNextContextMenu}
              onHideUpNextContextMenu={this.onHideUpNextContextMenu}
              upNextContextMenuOpen={this.state.upNextContextMenuOpen}
              markDone={this.markDone}
              repos={this.props.repos}
              showActivateElearningModal={() => { this.setState({ showActivateElearningModal: true }) }}
              showDistributeStudentCodesGCModal={() => { this.setState({ showDistributeCodesGCModal: true }) }}
              openHelpVideo={this.openHelpVideo}
              turnOffSyncBanner={this.turnOffSyncBanner}
              sectionIsIntegrationRostered={sectionIsIntegrationRostered}
              showNotification={this.props.showNotification}
              meta={this.props.meta}
            />
            )}
            {!setUpComplete && (
            <div className='page-centerPanel'>
              <SectionHomeCompleteSetupPrompt
                currentSection={currentSection}
                step={this.getSetupStep()}
                skipThisStep={this.skipCurrentSetupStep}
                showAssignSectionToDataOwnerModal={this.handleAssignSectionToDataOwnerModal}
                showBulkAddStudentsModal={this.handleShowBulkAddStudentsModal}
              />
            </div>
            )}
          </React.Fragment>
          )}

          {subsection === 'reports' && this.props.currentSection && (
          <HistoryContainer
            navigateToSectionHome={this.handleNavigateToSectionHome}
            sectionId={this.props.currentSection.id}
            currentSection={this.props.currentSection}
            handlePageScroll={this.props.handlePageScroll}
          />
          )}

          {subsection === 'students' && this.props.currentSection && (
          <SectionStudents
            showNotification={this.props.showNotification}
            syncSectionWithIntegration={this.syncSectionWithIntegration}
            removeIntegrationFromSection={this.confirmRemoveIntegrationFromSection}
            navigateToSectionHome={this.handleNavigateToSectionHome}
            showArchived={this.state.showArchived}
            showArchivedStudents={this.showArchivedStudents}
            showActiveStudents={this.showActiveStudents}
            students={filter(this.props.currentSection.students, { archived: false })}
            archivedStudents={filter(this.props.currentSection.students, { archived: true })}
            addNewStudent={this.handleNewStudent}
            deleteStudent={this.confirmDeleteStudent}
            availableCards={this.state.availableCards}
            updateStudentCard={this.handleUpdateStudentCard}
            archiveStudent={this.handleArchiveStudent}
            showEditStudentModal={this.props.showEditStudentModal}
            currentSection={this.props.currentSection}
            showBulkAddStudentsModal={this.handleShowBulkAddStudentsModal}
            sections={this.props.sections}
            cardsWithStudents={this.state.cardsWithStudents}
            showConfirmEditStudentCardModal={this.props.showConfirmEditStudentCardModal}
            sectionIsIntegrationRostered={sectionIsIntegrationRostered}
            sectionIntegrationRosterEmail={sectionIntegrationRosterEmail}
          />
          )}
          {subsection === 'settings' && (
          <SectionSetup
            navigateToSectionHome={this.handleNavigateToSectionHome}
            archiveSection={this.confirmArchiveSection}
            section={this.props.currentSection}
            updateSection={this.props.updateSection}
            currentSection={this.props.currentSection}
            sections={this.props.sections}
          />
          )}

          {this.state.showHelpVideoModal &&
          <HelpVideoModal videoUrl='https://www.youtube-nocookie.com/embed/ywXuUVGVamc' closeHelpVideoModal={() => { this.setState({ showHelpVideoModal: false }) }} />}
          {this.shouldShowConsentModal() && (
          <ActivateElearningModalContainer
            dismissModal={this.dismissConsentModal}
            scrollToStudentSection={this.scrollToStudentSection}
            section={this.props.currentSection}
            openDistributeCodesGCModal={() => { this.setState({ showDistributeCodesGCModal: true, isElearningActivationFlow: true }) }}
            sectionIsIntegrationRostered={this.props.sectionIsIntegrationRostered}
          />
          )}
          {this.state.showDistributeCodesGCModal && (
          <DistributeStudentCodesGCModalContainer
            isElearningActivationFlow={this.state.isElearningActivationFlow}
            dismissModal={() => { this.setState({ showDistributeCodesGCModal: false, isElearningActivationFlow: false }) }}
            scrollToStudentSection={this.scrollToStudentSection}
            section={this.props.currentSection}
            onSendCodesSuccess={this.onSendCodesSuccess}
            sectionIntegrationRosterEmail={this.props.sectionIntegrationRosterEmail}
          />
          )}

        </div>
      </DocumentTitle>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const currentSectionQueueQuestionPolls = filter(state.queueQuestionPolls, { section: ownProps.match.params.id })
  const currentSectionQueueSetPolls = filter(state.queueSetPolls, { section: ownProps.match.params.id })
  const { control, user, dataOwners } = state
  let livePoll = null
  let currentSectionDataOwner = null
  const fullQueue = sortBy(currentSectionQueueQuestionPolls.concat(currentSectionQueueSetPolls), 'planned')
  const mainQueue = fullQueue.slice() // this is the queue without any live or upNext polls
  if (control) {
    if (control.section === ownProps.match.params.id) {
      if (control.setPoll) {
        livePoll = find(currentSectionQueueSetPolls, { id: control.setPoll }) || find(state.historySetPolls, { id: control.setPoll })
      } else if (control.currentPoll) {
        livePoll = find(currentSectionQueueQuestionPolls, { id: control.currentPoll }) || find(state.historyQuestionPolls, { id: control.currentPoll })
      }
    }
  }
  if (livePoll) {
    remove(mainQueue, {
      id: livePoll.id,
    })
  }
  const sectionControl = state.sectionControls[ownProps.match.params.id]
  let resumableSetPoll = null
  if (sectionControl) {
    resumableSetPoll = find(state.historySetPolls, { id: sectionControl.resumableSetPoll }) || find(currentSectionQueueSetPolls, { id: sectionControl.resumableSetPoll })
    if (resumableSetPoll) {
      remove(mainQueue, {
        id: resumableSetPoll.id,
      })
    }
  }

  const upNextPoll = mainQueue[0]
  if (upNextPoll) {
    remove(mainQueue, {
      id: upNextPoll.id,
    })
  }

  const currentSectionHistoryQuestionPolls = filter(state.historyQuestionPolls, { section: ownProps.match.params.id })
  const currentSectionHistorySetPolls = filter(state.historySetPolls, { section: ownProps.match.params.id })
  const history = currentSectionHistoryQuestionPolls.concat(currentSectionHistorySetPolls)
  const recentHistory = sortBy(history, 'minCaptured').reverse().slice(0, numOfItemsInRecentHistory)
  const currentSection = find(state.sections, { id: ownProps.match.params.id }) || {}
  const recentActivityItems = sortBy(state.recentActivities, 'activityTime').reverse().slice(0, numOfItemsInRecentlyModifiedLibrary)

  const recentLibrary = [] // for inline add item to class queue
  recentActivityItems.forEach((activityItem) => {
    const { itemType } = activityItem
    let item
    if (itemType === 'set') {
      item = getSetForId(activityItem.item)
    } else {
      item = getQuestionForId(activityItem.item)
    }
    if (item) {
      recentLibrary.push(item)
    }
  })

  let sectionIsIntegrationRostered = false // is Google Classroom
  let sectionIntegrationRosterEmail
  if (currentSection && currentSection.integration && currentSection.integration.active) {
    sectionIsIntegrationRostered = true
    const integration = find(state.integrations, { id: currentSection.integration.id })
    if (integration) {
      sectionIntegrationRosterEmail = integration.email
    }
  }

  if (currentSection.dataOwner) {
    const dataOwner = dataOwners[currentSection.dataOwner.id]
    currentSectionDataOwner = dataOwner
  }

  return {
    sectionIsIntegrationRostered,
    sectionIntegrationRosterEmail,
    livePoll,
    control,
    upNextPoll,
    mainQueue,
    sections: state.sections,
    currentSection,
    currentSectionQueueQuestionPolls,
    currentSectionQueueSetPolls,
    currentSectionDataOwner,
    sectionControl,
    resumableSetPoll,
    fullQueue,
    recentHistory,
    questions: state.questions,
    sets: state.sets,
    recentActivities: sortBy(state.recentActivities, 'activityTime').reverse().slice(0, 10),
    recentLibrary,
    repos: state.repos,
    meta: state.meta,
    userDataOwners: getUserDataOwners(user, dataOwners),
    dataOwners: state.dataOwners,
  }
}

export default withRouter(connect(
  mapStateToProps,
  {
    fetchSingleSetPoll,
    fetchSection,
    updateSection,
    updateSectionPreferences,
    fetchSectionControl,
    deleteSection,
    newStudent,
    deleteStudent,
    updateStudent,
    updateQuestionPoll,
    updateSetPoll,
    newQuestionPoll,
    newSetPoll,
    playPoll,
    createPoll,
    fetchQuestionPolls,
    fetchSetPolls,
    showAddToQueueFromLibraryModal,
    showEditStudentModal,
    showAssignSectionToDataOwnerModal,
    showDataOwnerInfoModal,
    showBulkAddStudentsModal,
    updateSectionControl,
    showConfirmEditStudentCardModal,
    showConfirmModal,
    hideModal,
    showNotification,
    hideNotification,
    clearSectionControl,
    archiveSection,
    toggleStudentAccessToSection,
    syncSectionWithIntegration,
    removeIntegrationFromSection,
    upsertIntegration,
    assignSectionToDataOwner,
  },
)(SectionContainer))

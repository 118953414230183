export function getQuestionBodyLabel(question) {

	if(question.body!==''){
		return question.body
	}

	else {
		return 'Media Only Question'
	}
	

}
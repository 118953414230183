import React from 'react'
import ModalWrapper from './ModalWrapper'
import EditFolderContainer from '../../containers/modals/EditFolderContainer'

const EditFolderModal = props => {

	let title
	if(props.folder){
		title='Rename Folder'
	}
	else{
		title='New Folder'
	}

	return (
    <div className='library-editFolderModal--container'>
      <ModalWrapper
        {...props}
        title={title}
        iconName='folder'
        modalStyle='light'
      >
        <EditFolderContainer {...props}/>

      </ModalWrapper>

    </div>

  )
}

export default EditFolderModal
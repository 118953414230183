import React from 'react'
import { updateUserConfig } from '../../../../../actions/userConfig'
import FeatureModalSelectControl from '../../../../featureModals/FeatureModalSelectControl'
import store from '../../../../../store'
import { katexRender } from '../../../../../utils/katexHelper'

// Mathquill (used in primary equation editor) supports a subset of LaTex
// the advanced equation editor uses KaTex (better LaTeX coverage)

class AdvancedEquationEditor extends React.Component {
  constructor() {
    super()
    this.handleTextAreaOnChange = this.handleTextAreaOnChange.bind(this)
    this.renderKatexPreview = this.renderKatexPreview.bind(this)
    this.handleInsertEquationButtonClick = this.handleInsertEquationButtonClick.bind(this)
    this.handleUpdateLatexButtonClick = this.handleUpdateLatexButtonClick.bind(this)
    this.handlePaste = this.handlePaste.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.toggleShowAdvancedEquationButton = this.toggleShowAdvancedEquationButton.bind(this)
    const state = store.getState()
    const { userConfig } = state
    let showAdvancedEquationButton = false
    if (userConfig && userConfig.displayAdvancedEquationButtonInEditor) {
      showAdvancedEquationButton = true
    }
    this.state = {
      showAdvancedEquationButton,
    }
  }

  componentDidMount() {
    this.renderKatexPreview(this.props.latex)
    this.textArea.focus()
    this.textArea.setSelectionRange(this.textArea.value.length, this.textArea.value.length)
    if (window.analytics) {
      window.analytics.page('Advanced equation editor modal', {
        isInsert: !!this.props.isNewNode,
        openedWithShortcut: !!this.props.openedWithShortcut,
        openedFromToolbar: !!this.props.openedFromToolbar,
      })
    }
  }

  toggleShowAdvancedEquationButton() {
    let newShowAdvancedEquationButton
    this.setState((prevState) => {
      newShowAdvancedEquationButton = !prevState.showAdvancedEquationButton
      return { showAdvancedEquationButton: newShowAdvancedEquationButton }
    })
    const state = store.getState()
    if (state.user) {
      const userId = state.user.id
      const requestData = { displayAdvancedEquationButtonInEditor: newShowAdvancedEquationButton }
      store.dispatch(updateUserConfig(requestData, userId))
    }
    if (window.analytics) {
      window.analytics.track('Toggle Show Advanced Equation Shortcut Button', {
        toggleOn: newShowAdvancedEquationButton,
      })
    }
  }

  handlePaste(event) {
    event.preventDefault()// prevent pasting
    const text = (event.originalEvent || event).clipboardData.getData('text/plain')
    const newText = text.replace(/\$/g, '')
    const el = event.target
    const cursorPosStart = el.selectionStart
    const cursorPosEnd = el.selectionEnd
    const v = el.value
    const textBefore = v.substring(0, cursorPosStart)
    const textAfter = v.substring(cursorPosEnd, v.length)
    const mergedText = textBefore + newText + textAfter
    this.props.onChangeLatex(mergedText)
    this.renderKatexPreview(mergedText)
  }

  handleOnClick() { // keep textarea focused
    this.textArea.focus()
  }

  renderKatexPreview(latex) {
    this.props.setHasKatexError(false)
    katexRender({
      latex,
      dom: this.previewDiv,
      throwOnError: true, // run errorCallback on all errors
      errorCallback: () => this.props.setHasKatexError(true),
    })
  }

  handleTextAreaOnChange(e) {
    const newLatex = e.target.value
    this.props.onChangeLatex(newLatex)
    this.renderKatexPreview(newLatex)
  }

  handleInsertEquationButtonClick(e) {
    e.preventDefault()
    e.stopPropagation()
    // prevent saving when there's latex error
    if (this.props.hasKatexError) return

    const isAdvancedInput = true
    this.props.insertMathNode(this.props.latex, this.props.openedWithShortcut, isAdvancedInput)
  }

  handleUpdateLatexButtonClick(e) {
    e.preventDefault()
    e.stopPropagation()
    // prevent saving when there's latex error
    if (this.props.hasKatexError) return

    if (window.analytics) {
      window.analytics.track('Edit Equation', {
        oldLatex: this.props.initialLatex,
        newLatex: this.props.latex,
        isAdvancedInput: true,
      })
    }
    this.props.updateLatexValue(this.props.latex)
  }

  render() {
    const { isNewNode, openedWithShortcut } = this.props
    const { latex } = this.props
    const isEmpty = !latex
    const openedFromToolbar = isNewNode && !openedWithShortcut
    let isUpdateChanges = false
    if (!isNewNode && this.props.latex !== this.props.initialLatex) {
      isUpdateChanges = true
    }

    return (
      <div className='equationModalContainer' onClick={this.handleOnClick}>
        {isNewNode && openedFromToolbar && (
        <div className='equationModal-topBtnRow-fastTip'>
          Tip: Insert advanced equations faster by typing
          {' '}
          <span>$/</span>
          {' '}
          in the editor
        </div>
        )}
        <div className='equationModal equationModal--advanced notranslate'>
          <div className='equationModal-topBtnRow'>
            {isNewNode && (
            <button className='equationModal-topBtnRow-btn' onClick={this.props.showMathQuillEditor}>
              Standard Equation
            </button>
            )}
            <a className='equationModal-topBtnRow-link' target='_blank' rel='noopener noreferrer' href='https://katex.org/docs/supported.html'>
              Supported LaTeX Functions
            </a>
            <a className='equationModal-topBtnRow-link' target='_blank' rel='noopener noreferrer' href='https://mhchem.github.io/MathJax-mhchem/'>
              Chem Extension Functions
            </a>
            <a className='equationModal-topBtnRow-link' target='_blank' rel='noopener noreferrer' href='https://help.plickers.com/hc/en-us/articles/4405865875099'>
              Help
            </a>

            <button onClick={this.props.cancel} className='equationModal-topBtnRow-btn equationModal-topBtnRow-btn--cancel'>
              Cancel
            </button>
          </div>
          <div className='equationEditor-latexTextAreaContainer'>
            <textarea
              ref={(ref) => { this.textArea = ref }}
              onPaste={this.handlePaste}
              value={latex}
              onChange={this.handleTextAreaOnChange}
              className='equationEditor-latexTextArea'
            />
            {isEmpty && (
            <div className='equationEditor-latexTextAreaPlaceholder'>
              Type LaTeX
              <br />
              <br />
              {'x = \\begin{cases}'}
              <br />
              {'a &\\text{if } b \\\\'}
              <br />
              {'c &\\text{if } d '}
              <br />
              {'\\end{cases}'}
              <br />
            </div>
            )}
          </div>
          <div className='equationEditor-latexPreviewArea'>
            <div className='equationEditor-latexPreviewArea-label'>
              Preview
            </div>
            <div className='equationEditor-latexPreviewArea-preview' ref={(c) => { this.previewDiv = c }}>
              {/* PREVIEW LATEX */}
            </div>
          </div>
          <div className='equationEditor-advancedPrimaryBtnContainer'>
            {isNewNode && (
            <button disabled={isEmpty} className={`equationEditor-controls-primaryBtn equationEditor-controls-primaryBtn--advanced ${this.props.hasKatexError ? 'btn--disabled' : ''}`} onClick={this.handleInsertEquationButtonClick}>
              <div className='equationEditor-controls-primaryBtn-title'>
                Insert Advanced Equation
              </div>
              <div className='equationEditor-controls-primaryBtn-keyboardHint'>
                Shift + Enter
              </div>
            </button>
            )}
            {!isNewNode && (
            <button className={`equationEditor-controls-primaryBtn equationEditor-controls-primaryBtn--advanced ${this.props.hasKatexError ? 'btn--disabled' : ''}`} onClick={this.handleUpdateLatexButtonClick}>
              <div className='equationEditor-controls-primaryBtn-title'>
                {isUpdateChanges ? 'Save Changes' : 'Done'}
              </div>
              <div className='equationEditor-controls-primaryBtn-keyboardHint'>
                Shift + Enter
              </div>
            </button>
            )}
          </div>
          <div className='equationEditor-addShortcutCheckboxContainer'>
            <FeatureModalSelectControl
              checkbox
              checked={this.state.showAdvancedEquationButton}
              onClick={this.toggleShowAdvancedEquationButton}
              label='Show Advanced Equation button in Editor Toolbar'
            />
          </div>
        </div>
        {((isNewNode && isEmpty) || (!isNewNode && !isUpdateChanges)) &&
        <div onClick={this.props.cancel} className='equationModalContainerBG' />}
      </div>
    )
  }
}

export default AdvancedEquationEditor

export default function (state = {}, action) {
	switch (action.type) {
		
		case 'FETCH_SECTION_AUTHORIZATIONS_SUCCESS':				
			const sectionId=action.sectionId
				 return {...state,
				 [`${sectionId}`]:action.response 
				 }

		case 'LOGOUT':
			return {}
		
		default:
			return state
	
	}
}

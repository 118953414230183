// CheckoutForm.js
import React from 'react'
import {injectStripe} from 'react-stripe-elements'
import {CardElement} from 'react-stripe-elements'
import Button from '../../../components/uiKit/Button'
 
class UpdatePaymentInfoForm extends React.Component {

	constructor(props){
		super(props)
		this.handleSubmit=this.handleSubmit.bind(this)
		this.handleOnChange=this.handleOnChange.bind(this)
		this.state=({ 	
 			addressLine1:'',
 			addressZip:'',
 			fullName:'',
 			cardDetailsComplete:false

 		})
      
	}
 
	handleSubmit(ev){
		this.props.beginSubmit()
		// We don't want to let default form submission happen here, which would refresh the page.
		ev.preventDefault()
		this.props.stripe.createToken({'name':this.state.fullName,'address_line1':this.state.addressLine1,'address_zip':this.state.addressZip,'email':this.props.email}).then(({token}) => {
			if(token){
				this.props.updateBillingInformation(token.id)
			}

		})


	}

	handleOnChange(e){
		if(e.complete){
			this.setState({cardDetailsComplete:true})

		}else {
			this.setState({cardDetailsComplete:false})
		}
	}

	render() {
		let canSubmit = false
		const {addressLine1,addressZip,fullName,cardDetailsComplete}=this.state
		if(!this.props.submitLoading && addressLine1!=='' &&  addressZip!=='' && fullName!=='' && cardDetailsComplete){
			canSubmit=true
		}



		return (
			<form onSubmit={this.handleSubmit}>
			 	 	       			 
			 	<div className='formField is--text'>			 		
			        <input 
			        	className='formFieldText-input'
			        	name="name"
			        	type="text"
			        	placeholder="Cardholder Name"
			        	required
			        	onChange={(e)=>{this.setState({fullName:e.target.value})}}
			        	value={this.state.fullName}
			        />        		
		      </div>

		        <input
		        	className='accountForm-updatePaymentModal-hiddenEmailInput'
		         	name="email"
		         	type="email"
		         	value={this.props.email}
		          	readOnly 
		         	 disabled
		        />

			    <div className='accountForm-updatePaymentModal-addressRowContainer'>    

			    	<div className='formField is--text is--updatePaymentModal-streetAddress'>
							<input
								value={this.state.addressLine1}
								onChange={(e)=>{this.setState({addressLine1:e.target.value})}}
								name="address_line1"
								id='address_line1'
								className='formFieldText-input '
								type="text"
								placeholder='Street Address'
								required
								maxLength={150}
							/>
						</div>

						<div className='formField is--text is--updatePaymentModal-postalCode'>
							<input
								value={this.state.addressZip}
								onChange={(e)=>{this.setState({addressZip:e.target.value})}}
								name="address-zip"
								className='formFieldText-input'    
								type="text"
								placeholder='ZIP'
								required
								maxLength={11}
							/>		     
						</div>  
      
		   </div>


				    
		        <CardElement 
		        onChange={this.handleOnChange}
		        hidePostalCode={true}
						  style={
						    {base: 
						    	{				      
							      color: 'rgb(33,	33,	51)',				      
							      fontWeight: 500,
			        			fontFamily: 'PlickersLato',
			        			fontSize: '16px',


							      '::placeholder': {
							        color: 'rgb(204, 204, 218)',
							  		},				  		
							  		':hover': {
							        borderColor: 'rgb(195,195,205)',
							  		},
									}	
								}
						  }
		        />
		      
		      <div className='updatePaymentInfo-actionRow'>	
        		<Button 
        			size='xLarge'
        			color='blue'
        			submitLoading={this.props.submitLoading}
        			label='Update Payment Method'
        			canSubmit={canSubmit}
        			disabled={!canSubmit}
        			type='submit'
        		/>
        		<Button 
        			size='xLarge'
        			color='white'        			
        			label='Cancel' 
        			onClickFunction={this.props.hideModal}              			
        		/>
	        </div>
			
			</form>
		)
	}
}

export default injectStripe(UpdatePaymentInfoForm)
export function getActiveQuestionSlideWidth(){
	let slideWidth=''
		window.view.state.doc.forEach((node,offset,index)=>{
		if(node.type.name === "question" && node.attrs.activeslide===true){
			slideWidth=node.attrs.slideWidth
		}	
	})
	return slideWidth
}






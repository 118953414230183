import React from 'react'
import { NavLink } from 'react-router-dom'
import { DropTarget } from 'react-dnd'
import ItemTypes from '../DnDKit/ItemTypes'
import LibraryTarget from '../DnDKit/LibraryTarget'
import Icon from '../misc/Icons'




function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop()
	}
}




class DnDSideNavLibrary extends React.Component{

	render(){
		const {isOver,connectDropTarget,canDrop} = this.props

		return(				
			<NavLink
     			to={'/library'}
     			className='sideNav-itemContainer'
   			>
   			{connectDropTarget(
					<div className={'sideNav-item is--library' + (canDrop ? ' sideNav-item--library--canDrop ' : ' sideNav-item--library--cannotDrop ') + (isOver ? ' sideNav-item--library--isDnDOver ' : '')}>
						<div className='sideNav-item-icon'>
							<Icon name='library' />
						</div>
						<div className='sideNav-item-label'>
							Your Library
						</div>
						<div className='sideNav-item-dropIcon'>			
							 <Icon name='open-left-arrow' />
						</div>
					</div>				

				)}
   			</NavLink>

		)
	}
}
export default DropTarget([ItemTypes.ITEMCELL,ItemTypes.FOLDERCELL], LibraryTarget, collect)(DnDSideNavLibrary)






import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
 
const lettersArray=['A','B','C','D']

// Add calculated fields to poll object for easier calcs in e.g. scoresheet
// and to make it easy to find out if e.g. a student answered a poll correctly
// setPoll add aggregatedResponsesByStudent
// aggregatedResponsesByStudent={...
// 	studentId:{
// 		totalResponses:2,
//		totalCorrect:0,
//		totalSurveyResponses:0
// 	}
// }
//setPollPoll add responsesbyStudent
// responsesByStudent={...
// 	studentId:{
// 		card:1,
// 		answer:"A",
// 		captured:"2021-08-06T12:06:15.141Z",
// 		isCorrect:false,
// 		isSurvey:false,
// 		isValid:true
// 	}
// }

function calculateResponsesByStudents(setPoll){
	let pollPolls={}
	let aggregatedResponsesByStudent={}
	let aggregatedResponsesByGuest={}

	Object.keys(setPoll.polls).map((key)=>{
		const poll=setPoll.polls[key]
		let isSurvey=(poll.correctPercent===-1)
		let correctLetters=[]
		let validResponseLetters=[]//e.g. choice D is not valid if there are only 3 choices
		if(poll.snapshot){
			poll.snapshot.choices.map((choice,index) => {
				const validResponseLetter=lettersArray[index]
				validResponseLetters.push(validResponseLetter)
				if(choice.correct===true){
					const correctLetter=lettersArray[index]
					correctLetters.push(correctLetter)
				}
				return null
			})
		}else{ //handling for missing snapshots- this can probably be removed now
			const setSnapshot=setPoll.snapshot
			const matchingQuestion=find(setSnapshot.questions,{questionId:poll.id})
			if(matchingQuestion){
				poll.snapshot=matchingQuestion
			}
		}
		let responsesByStudent={}
		let responsesByGuest={}
		let allResponseCount=0
  	let allCorrectCount=0		
		
		if(poll.responsesByCard){ //ignore pollPolls with no responses
			Object.keys(poll.responsesByCard).map((cardNumber)=>{
				//for each response check if it is correct and valid then create a studentResponseObject
				const response=poll.responsesByCard[cardNumber]
				const correctResponse=includes(correctLetters,response.answer)
				const isValid=includes(validResponseLetters,response.answer)
				const byStudentResponseObject={
					card:cardNumber,
					answer:response.answer,
					captured:response.captured,
					isCorrect:correctResponse,
					isSurvey:isSurvey,
					isValid:isValid
				}
				if(!isSurvey && isValid){
					allResponseCount+=1 
				}
				if(response.student){
					responsesByStudent[`${response.student}`]=byStudentResponseObject				
					if(!isSurvey && isValid){
						if(aggregatedResponsesByStudent[`${response.student}`]){
							aggregatedResponsesByStudent[`${response.student}`].totalResponses +=1
						}else{
							aggregatedResponsesByStudent[`${response.student}`]={
								totalResponses:1,
								totalCorrect:0,
								totalSurveyResponses:0
							}	
						}
					}else if(isSurvey){
						if(aggregatedResponsesByStudent[`${response.student}`]){
							aggregatedResponsesByStudent[`${response.student}`].totalSurveyResponses +=1
						}else{
							aggregatedResponsesByStudent[`${response.student}`]={
								totalResponses:0,
								totalCorrect:0,
								totalSurveyResponses:1
							}	
						}
					}
					if(correctResponse){
						allCorrectCount+=1
						aggregatedResponsesByStudent[`${response.student}`].totalCorrect +=1
					}
				}else{ //is guest response--> add to responsesByGuest object //TODO can combine this with student logic above
					responsesByGuest[`Guest ${cardNumber}`]=byStudentResponseObject					
					if(!isSurvey && isValid){
						if(aggregatedResponsesByGuest[`Guest ${cardNumber}`]){
							aggregatedResponsesByGuest[`Guest ${cardNumber}`].totalResponses +=1
						}else{
							aggregatedResponsesByGuest[`Guest ${cardNumber}`]={
								totalResponses:1,
								totalCorrect:0,
								totalSurveyResponses:0
							}
						}
					}else if(isSurvey){
						if(aggregatedResponsesByGuest[`Guest ${cardNumber}`]){
							aggregatedResponsesByGuest[`Guest ${cardNumber}`].totalSurveyResponses +=1
						}else{
							aggregatedResponsesByGuest[`Guest ${cardNumber}`]={
								totalResponses:0,
								totalCorrect:0,
								totalSurveyResponses:1
							}
						}
					}				
					if(correctResponse){
						allCorrectCount+=1
						aggregatedResponsesByGuest[`Guest ${cardNumber}`].totalCorrect +=1				
					}
					return null
				}return null})
		}
		const modifiedPoll={...poll, responsesByStudent:responsesByStudent,responsesByGuest:responsesByGuest,aggregatedResponses:{totalResponses:allResponseCount,totalCorrectResponses:allCorrectCount}}
		pollPolls[`${key}`]=modifiedPoll
		return null
	})
	//update setPoll object with the modified setPollPolls and additional aggregated response objects
	return {...setPoll,polls:pollPolls,aggregatedResponsesByStudent:aggregatedResponsesByStudent,aggregatedResponsesByGuest:aggregatedResponsesByGuest}
}	


export default function (state=null, action) {
	switch (action.type) {
	
		case 'FETCH_HISTORY_SET_POLLS_SUCCESS':
			let historySetPolls=[]
			action.response.map((setPoll) => {
				const modifiedPoll=calculateResponsesByStudents(setPoll)
				historySetPolls.push(modifiedPoll)
				return null
			})	
			return historySetPolls

		case 'FETCH_SINGLE_SET_POLL_SUCCESS'://Fetch Single Poll
			if(action.response.hasResponses===true){
				if(state){
					const setPollIndex= findIndex(state,setPoll => {
						return (setPoll && setPoll.id === action.response.id)
					})	
					const modifiedSetPoll=calculateResponsesByStudents(action.response)
					if(setPollIndex !==-1){
						return [
							...state.slice(0,setPollIndex),
							modifiedSetPoll,
							...state.slice(setPollIndex + 1)
						]
					}else{
						return [
							...state,
							modifiedSetPoll
						]
					}
				}else{
					const modifiedSetPoll=calculateResponsesByStudents(action.response)
					let newState=[]
					return [
						...newState,
						modifiedSetPoll
					]}}
				else return state

			case 'UPDATED_SET_POLL_WITH_PUSHER':
				if(action.updatedPoll.hasResponses===false){ 
					const updatedPollIndex= findIndex(state,setPoll => {
						return setPoll.id === action.updatedPoll.id
					})		
					if(updatedPollIndex !==-1){
						return [
							...state.slice(0,updatedPollIndex),
							...state.slice(updatedPollIndex + 1)
						]
					}else return state		
				}else{ //remove from state
					const updatedPollIndex= findIndex(state,setPoll => {
					return setPoll.id === action.updatedPoll.id
				})
				const modifiedUpdatedSetPoll=calculateResponsesByStudents(action.updatedPoll)
				if(state){
					if(updatedPollIndex !==-1){
						return [
							...state.slice(0,updatedPollIndex),
							modifiedUpdatedSetPoll,
							...state.slice(updatedPollIndex + 1)
						]
					}	else return [...state,modifiedUpdatedSetPoll]	
				}else return modifiedUpdatedSetPoll
			}

		case 'ARCHIVE_SECTION_SUCCESS':
			const archivedSectionId=action.response.id
			let filteredPolls=state.slice()
				filteredPolls=filter(filteredPolls, function(poll) {
  		  	return poll.section !==archivedSectionId
				})
			return filteredPolls

		case 'REFRESH_SET_POLLS_SUCCESS':
			const filtered=filter(action.response,{'hasResponses':true})		
			let polls=state.slice()
			forEach(filtered, function(modifiedPoll) { 			
				const modifiedPollWithCalculatedFields=calculateResponsesByStudents(modifiedPoll)			
				let indexOfPoll = findIndex(polls,poll => {
					return poll.id === modifiedPoll.id
				})
				if(indexOfPoll !== -1){ 
					polls =[
						...polls.slice(0,indexOfPoll),
						...polls.slice(indexOfPoll + 1)
					]
				}
				polls=[modifiedPollWithCalculatedFields,...polls]
			})
			return polls

		case 'UPDATE_SET_POLL_REQUEST':
			if(action.data.hasResponses){
				const updatedPollId = action.pollId
				const indexOfSetPoll = findIndex(state,setPoll => {
					return setPoll.id === updatedPollId
				})
				const modifiedUpdatedPoll=calculateResponsesByStudents(action.data)	
				if(indexOfSetPoll!==-1){
					return [
						...state.slice(0,indexOfSetPoll),
						modifiedUpdatedPoll,
						...state.slice(indexOfSetPoll + 1)
					]
				}else return state}
			else return state

		case 'DELETE_SET_POLL_SUCCESS':
			const pollId = action.pollId
			const indexOfPollToDelete = findIndex(state,poll => {
				return poll.id === pollId
			})
			if(indexOfPollToDelete !== -1){
				return [
					...state.slice(0,indexOfPollToDelete),
					...state.slice(indexOfPollToDelete + 1)
				]
			}else return state

		case 'LOGOUT':
			return null
		
		default:
			return state
	}
}

function getInitialState(){
	const onboardingWizardDismissed = JSON.parse(localStorage.getItem('onboardingWizardDismissed'))
	if(onboardingWizardDismissed && onboardingWizardDismissed===true){
		return true
	}else return false


}



export default function (state = getInitialState(), action) {
	switch (action.type) {

	
	case 'DISMISS_ONBOARDING_WIZARD':
		localStorage.setItem('onboardingWizardDismissed',true)
		return true


	case 'SHOW_ONBOARDING_WIZARD':
		localStorage.setItem('onboardingWizardDismissed',false)
		return false



	case 'LOGOUT':
		localStorage.setItem('onboardingWizardDismissed',false)
		return false
	
	default:
		return state
	}
}


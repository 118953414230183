import { chain } from 'lodash'
// Simple statistics computation utility

// sort array ascending
const orderAscending = (values) => values.sort((a, b) => a - b)

// compute sum
const sum = (values) => values.reduce((sum, value) => sum + value, 0)

// compute mean
const mean = (values) => sum(values) / values.length

// compute standard deviation
const standardDeviation = (values) => {
  const meanValue = mean(values)
  const squaredDeviations = values.map((value) => (value - meanValue) ** 2)
  return Math.sqrt(sum(squaredDeviations) / (values.length - 1))
}

// compute percentile value for given percentile (array, integer)
// this function expects values to be sorted ascending
// function is implemented to return same results as google sheet PERCENTILE function
// Tested with percentiles 0, 25, 50, 75, 90, 99, 100 :
// data: [0, 1] => [0, 0.25, 0.5, 0.75, 0.9, 0.99, 1]
// data: [0, 1, 2] => [0, 0.5, 1, 1.5, 1.8, 1.98, 2]
// data: [0, 1, ... , 10] => [0, 2.5, 5, 7.5, 9, 9.9, 10]
// data: [1, 2, 8, 9] => [1, 1.75, 5, 8.25, 8.7, 8.97, 9]
// data: [1, 2, 3, 5, 6, 10] => [1, 2.25, 4, 5.75, 8, 9.8, 10]
// data: [1, 1, 2, 2, 5, 5, 5, 6, 10, 10] => [1, 2, 5, 5.75, 10, 10, 10]
const percentile = (valuesAscending, percentile) => {
  const position = (valuesAscending.length - 1) * (percentile / 100)
  const lower = Math.floor(position)
  const upper = lower + 1
  const weight = position % 1
  if (upper >= valuesAscending.length) return valuesAscending[lower]
  // return lower index value if upper is out of array range
  // or "linear extrapolation" of value between lower and upper in other cases
  return (upper >= valuesAscending.length ? valuesAscending[lower] :
    (valuesAscending[lower] * (1 - weight)) + (valuesAscending[upper] * weight))
}

// compute list of percentiles from values and prefixes the key by given string
// this function expects values to be sorted ascending and percentiles to be an array
// for values [0, 1 ,2], percentiles [50, 75] and prefix 'p' returns:
// { p50: 1, p75: 1.5 }
const percentilesPrefixedKeys = (valuesAscending, percentiles, keyPrefix) => chain(percentiles)
  .keyBy((it) => `${keyPrefix}${it}`)
  .mapValues((it) => percentile(valuesAscending, it))
  .value()

// compute list of percentiles from values
// this function expects values to be sorted ascending and percentiles to be an array
// for values [0, 1 ,2] and percentiles [50, 75] returns:
// { p50: 1, p75: 1.5 }
const percentiles = (valuesAscending, percentiles) => percentilesPrefixedKeys(valuesAscending, percentiles, 'p')

export {
  orderAscending,
  sum,
  mean,
  standardDeviation,
  percentile,
  percentiles,
  percentilesPrefixedKeys,
}

import React from 'react'
import Icon from '../misc/Icons'

class FilterBar extends React.Component{

	render(){	
		const {value} = this.props
		let isEmpty = true
		if (value!==''){
			isEmpty = false
		} 
		let isSticky = false
		return(
			<div className={'filterBar' + (isEmpty ? ' is--empty' : ' is--nonEmpty') + (isSticky ? ' is--sticky' : '')}>
				<input				      
					type="text"
					placeholder="Filter"
					value={value}
					onChange={this.props.onChange}
					onBlur={this.props.onBlur}
					id={this.props.id}
					/>
					<div className="filterBar-extras">
						<Icon name="magnifying-glass" />
						<div className="filterBar-clear" onClick={this.props.clearBtnOnClick}>
							<Icon name="close" />
						</div>
					</div>		
			</div>
		)
	}		
}

export default FilterBar
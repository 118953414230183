import React, { Component } from 'react'

class RepoReleaseModalVisualRightPanelUserBubble extends Component {

	render() {

		return ( 
			<div className='repoReleaseModal-visual-rightPanel-userBubble'>
				<div className='repoReleaseModal-visual-rightPanel-userBubble-avatar'>
					<img alt='' src={`https://s3.amazonaws.com/assets.plickers.com/react-assets/images/packrelease/SampleProfilePic${this.props.imageURL}.png`} /> 
				</div>
				<div style={{width: `${this.props.width}px`}} className='repoReleaseModal-visual-rightPanel-userBubble-name' />
			</div>
		)
	}
}

class RepoReleaseModalVisualRightPanel extends Component {

	render() {


		return ( 
			<div className='repoReleaseModal-visual-rightPanel' >
				{this.props.product === 'pro' &&
					<React.Fragment>
						<RepoReleaseModalVisualRightPanelUserBubble imageURL='Zoe' width='64' />
						<RepoReleaseModalVisualRightPanelUserBubble imageURL='Konnie' width='72' />
						<RepoReleaseModalVisualRightPanelUserBubble imageURL='Lizo' width='44' />
						<RepoReleaseModalVisualRightPanelUserBubble imageURL='Angellica' width='48' />
						<RepoReleaseModalVisualRightPanelUserBubble imageURL='Gethin' width='56' />
					</React.Fragment>
				}
			</div>	
		)
	}
}


export default RepoReleaseModalVisualRightPanel




import React, { Component } from 'react'
import modifySampleQuestion from './modifySampleQuestion'
import TemplateSelectPanel from './TemplateSelectPanel'
import FieldLengthSelectPanel from './FieldLengthSelectPanel'
import RichStaticSlide from '../richStaticSlide/RichStaticSlide'
import FontSizeCalculatorComponent from '../fontSizeCalculator/FontSizeCalculatorComponent'
import sampleQuestionMedia from './sampleQuestionMedia'
import slideLayoutDefinitions from '../../staticData/slideLayoutDefinitions'

const BODY_FONT_SIZES = slideLayoutDefinitions.availableBodyFontSizes
const CHOICE_FONT_SIZES = slideLayoutDefinitions.availableChoiceFontSizes

class FontSizeCalculationVisualizerPage extends Component {
  constructor() {
    super()
    this.toggleShowChoice = this.toggleShowChoice.bind(this)
    this.updateSampleQuestionFieldLength = this.updateSampleQuestionFieldLength.bind(this)
    this.setMediaRatio = this.setMediaRatio.bind(this)
    this.changeTemplate = this.changeTemplate.bind(this)
    this.state = {
      bodyHeights: {},
      questionMediaDynamicHeight: 50,
      choiceHeights: {},
      bodyFontSize: 0,
      choiceFontSize: 0,
      perfectCombo: {},
      mediaRatio: 'MM',
      sampleQuestion: modifySampleQuestion(150, 15, 15, 15, 15),
      questionBodyCharacterCount: 150,
      choiceACharacterCount: 15,
      choiceBCharacterCount: 15,
      choiceCCharacterCount: 15,
      choiceDCharacterCount: 15,
      showChoiceC: true,
      showChoiceD: true,
      showGhostElements: true,
      showOutcomesGrid: true,
      showHeightsTable: true,
      slideWidth: 640,
    }
  }

  setMediaRatio(ratio) {
    this.setState({ mediaRatio: ratio })
  }

  updateSampleQuestionFieldLength(field, length) {
    let {
      questionBodyCharacterCount,
      choiceACharacterCount,
      choiceBCharacterCount,
      choiceCCharacterCount,
      choiceDCharacterCount,
    } = this.state
    if (field === 'questionBody') {
      this.setState({ questionBodyCharacterCount: length })
      questionBodyCharacterCount = length
    } else if (field === 'choiceA') {
      this.setState({ choiceACharacterCount: length })
      choiceACharacterCount = length
    } else if (field === 'choiceB') {
      this.setState({ choiceBCharacterCount: length })
      choiceBCharacterCount = length
    } else if (field === 'choiceC') {
      this.setState({ choiceCCharacterCount: length })
      choiceCCharacterCount = length
    } else if (field === 'choiceD') {
      this.setState({ choiceDCharacterCount: length })
      choiceDCharacterCount = length
    }
    const { template } = this.state.sampleQuestion
    const { media } = this.state.sampleQuestion
    const choiceMedia = this.state.sampleQuestion.choices[0].media

    const question = modifySampleQuestion(
      questionBodyCharacterCount,
      choiceACharacterCount,
      choiceBCharacterCount,
      choiceCCharacterCount,
      choiceDCharacterCount,
    )
    question.template = template
    question.media = media
    question.choices.forEach((choice, i) => {
      question.choices[i].media = choiceMedia
    })
    this.setState({ sampleQuestion: question })
  }

  toggleShowChoice(choice) {
    if (choice === 'choiceC') {
      this.setState((prevState) => ({ showChoiceC: !prevState.showChoiceC }))
    } else if (choice === 'choiceD') {
      this.setState((prevState) => ({ showChoiceD: !prevState.showChoiceD }))
    }
  }

  changeTemplate(template) {
    this.setState((prevState) => {
      const question = { ...prevState.sampleQuestion }
      question.template = template
      if (template === 'bodyLeft') {
        question.choices.forEach((choice, i) => {
          question.choices[i].media = null
        })
        question.media = null
      } else if (template === 'bodyCenter') {
        question.choices.forEach((choice, i) => {
          question.choices[i].media = null
        })
        question.media = null
      } else if (template === 'bodyLeftMediaRight') {
        question.choices.forEach((choice, i) => {
          question.choices[i].media = null
        })
        question.media = sampleQuestionMedia
      } else if (template === 'bodyRightMediaLeft') {
        question.choices.forEach((choice, i) => {
          question.choices[i].media = null
        })
        question.media = sampleQuestionMedia
      } else if (template === 'bodyCenterMediaCenter') {
        question.choices.forEach((choice, i) => {
          question.choices[i].media = null
        })
        question.media = sampleQuestionMedia
      } else if (template === 'bodyCenterChoicesMedia') {
        question.choices.forEach((choice, i) => {
          question.choices[i].media = sampleQuestionMedia
        })
        question.media = null
      } else if (template === 'bodyLeftMediaRightChoicesMedia') {
        question.choices.forEach((choice, i) => {
          question.choices[i].media = sampleQuestionMedia
        })
        question.media = sampleQuestionMedia
      }

      return { sampleQuestion: question }
    })
  }

  render() {
    const {
      showChoiceC, showChoiceD, sampleQuestion, bodyHeights, choiceHeights, perfectCombo,
    } = this.state
    const slideQuestion = { ...sampleQuestion }
    const choices = []
    slideQuestion.choices.forEach((choice, i) => {
      if (i === 0 || i === 1 || (i === 2 && showChoiceC) || (i === 3 && showChoiceD)) {
        choices.push(choice)
      }
    })
    slideQuestion.choices = choices
    let maxCombinedHeight = 655
    if (slideQuestion.template === 'bodyCenterMediaCenter') {
      maxCombinedHeight = 655 - parseInt(this.state.questionMediaDynamicHeight, 10)
    }
    if (slideQuestion.template === 'bodyCenterChoicesMedia' || slideQuestion.template === 'bodyLeftMediaRightChoicesMedia') {
      maxCombinedHeight = 655 - 342
    }

    const { choiceFontSize, bodyFontSize, questionMediaDynamicHeight } = this.state
    const measurements = {
      bodyFS: bodyFontSize,
      choiceFS: choiceFontSize,
      questionMediaDynamicHeight,
    }

    return (
      <div className="fontSizeCalculationVisualizerContainer">
        <div className="fontSizeCalculationVisualizer-slideContainer">
          <RichStaticSlide
            question={{ ...slideQuestion, measurements, layout: slideQuestion.template }}
            slideWidth={this.state.slideWidth}
          />
          <FontSizeCalculatorComponent
            question={{ ...slideQuestion, layout: slideQuestion.template }}
            updateBodyHeights={(bodyHeights) => { this.setState({ bodyHeights }) }}
            updateChoiceHeights={(choiceHeights) => { this.setState({ choiceHeights }) }}
            updatePerfectCombo={(perfectCombo) => {
              this.setState({
                perfectCombo,
                bodyFontSize: perfectCombo.bodyFontSize,
                choiceFontSize: perfectCombo.choiceFontSize,
              })
            }}
            updateQuestionMediaDynamicHeight={(questionMediaDynamicHeight) => {
              this.setState({ questionMediaDynamicHeight })
            }}
            mediaRatio={this.state.mediaRatio}
            devShowGhostElements={this.state.showGhostElements}
          />
        </div>

        <TemplateSelectPanel
          question={sampleQuestion}
          changeTemplate={this.changeTemplate}
          setMediaRatio={this.setMediaRatio}
          mediaRatio={this.state.mediaRatio}
          showGhostElements={this.state.showGhostElements}
          toggleShowGhostElements={() => {
            this.setState((prevState) => ({ showGhostElements: !prevState.showGhostElements }))
          }}
          showOutcomesGrid={this.state.showOutcomesGrid}
          toggleShowOutcomesGrid={() => {
            this.setState((prevState) => ({ showOutcomesGrid: !prevState.showOutcomesGrid }))
          }}
          toggleShowHeightsTable={() => {
            this.setState((prevState) => ({ showHeightsTable: !prevState.showHeightsTable }))
          }}
          changeSlideWidth={(slideWidth) => { this.setState({ slideWidth }) }}
          slideWidth={this.state.slideWidth}
          showHeightsTable={this.state.showHeightsTable}
        />

        <FieldLengthSelectPanel
          sampleQuestion={this.state.sampleQuestion}
          questionBodyCharacterCount={this.state.questionBodyCharacterCount}
          choiceACharacterCount={this.state.choiceACharacterCount}
          choiceBCharacterCount={this.state.choiceBCharacterCount}
          choiceCCharacterCount={this.state.choiceCCharacterCount}
          choiceDCharacterCount={this.state.choiceDCharacterCount}
          showChoiceC={this.state.showChoiceC}
          showChoiceD={this.state.showChoiceD}
          updateSampleQuestionFieldLength={this.updateSampleQuestionFieldLength}
          toggleShowChoice={this.toggleShowChoice}
        />

        {this.state.showHeightsTable && (
        <div className="fontSizeCalculationVisualizer-calculatedHeightsTable">
          <div className="fontSizeCalculationVisualizer-calculatedHeightsTable-column">
            <div className="fontSizeCalculationVisualizer-calculatedHeightsTable-row">
              Body
            </div>
            {BODY_FONT_SIZES.map((fontSize) => (
              <div key={`1-${fontSize}`} className="fontSizeCalculationVisualizer-calculatedHeightsTable-row">
                {fontSize}
                px
              </div>
            ))}
          </div>
          <div className="fontSizeCalculationVisualizer-calculatedHeightsTable-column">
            <div className="fontSizeCalculationVisualizer-calculatedHeightsTable-row">
              Height
            </div>
            {Object.keys(bodyHeights).map((key) => {
              const height = bodyHeights[key]
              return (
                <div
                  key={`2-${key}`}
                  className={`fontSizeCalculationVisualizer-calculatedHeightsTable-row ${height.isValidFontSize ? '' : 'fontSizeCalculationVisualizer-calculatedHeightsTable-row--invalid'}`}
                >
                  {height.height}
                  px
                </div>
              )
            })}
          </div>
          <div className="fontSizeCalculationVisualizer-calculatedHeightsTable-column">
            <div className="fontSizeCalculationVisualizer-calculatedHeightsTable-row">
              Scroll w
            </div>
            {Object.keys(bodyHeights).map((key) => {
              const height = bodyHeights[key]
              return (
                <div
                  key={`3-${key}`}
                  className={`fontSizeCalculationVisualizer-calculatedHeightsTable-row ${height.isValidFontSize ? '' : 'fontSizeCalculationVisualizer-calculatedHeightsTable-row--invalid'}`}
                >
                  {height.scrollWidth}
                  px
                </div>
              )
            })}
          </div>
          <div className="fontSizeCalculationVisualizer-calculatedHeightsTable-column">
            <div className="fontSizeCalculationVisualizer-calculatedHeightsTable-row">
              Client w
            </div>
            {Object.keys(bodyHeights).map((key) => {
              const height = bodyHeights[key]
              return (
                <div
                  key={`4-${key}`}
                  className={`fontSizeCalculationVisualizer-calculatedHeightsTable-row ${height.isValidFontSize ? '' : 'fontSizeCalculationVisualizer-calculatedHeightsTable-row--invalid'}`}
                >
                  {height.clientWidth}
                  px
                </div>
              )
            })}
          </div>
          <div className="fontSizeCalculationVisualizer-calculatedHeightsTable-column">
            <div className="fontSizeCalculationVisualizer-calculatedHeightsTable-row">
              Choices
            </div>
            {CHOICE_FONT_SIZES.map((fontSize) => (
              <div key={`5-${fontSize}`} className="fontSizeCalculationVisualizer-calculatedHeightsTable-row">
                {fontSize}
                px
              </div>
            ))}
          </div>
          <div className="fontSizeCalculationVisualizer-calculatedHeightsTable-column">
            <div className="fontSizeCalculationVisualizer-calculatedHeightsTable-row">
              Height
            </div>
            {Object.keys(choiceHeights).map((key) => {
              const height = choiceHeights[key]
              return (
                <div
                  key={`6-${key}`}
                  className={`fontSizeCalculationVisualizer-calculatedHeightsTable-row  ${height.isValidFontSize ? '' : 'fontSizeCalculationVisualizer-calculatedHeightsTable-row--invalid'}`}
                >
                  {height.total}
                  px
                </div>
              )
            })}
          </div>
        </div>
        )}
        {this.state.showOutcomesGrid &&
        <div className="fontSizeCalculationVisualizer-outcomesGrid" />}
        {this.state.showOutcomesGrid && (
        <div className="fontSizeCalculationVisualizer-outcomesGrid">

          <div className="fontSizeCalculationVisualizer-outcomesGrid-column">
            <div className="fontSizeCalculationVisualizer-outcomesGrid-row" />
            {CHOICE_FONT_SIZES.map((fontSize) => (
              <div key={`5-${fontSize}`} className="fontSizeCalculationVisualizer-outcomesGrid-row">
                {fontSize}
                px
              </div>
            ))}

          </div>

          {BODY_FONT_SIZES.map((fontSize) => {
            let bodyHeight = bodyHeights[`fontSize_${fontSize}`]
            if (bodyHeight) { // hack update for new measurement format with height and isValid
              bodyHeight = bodyHeight.height
            }
            return (
              <div key={`6-${fontSize}`} className="fontSizeCalculationVisualizer-outcomesGrid-column">
                <div className="fontSizeCalculationVisualizer-outcomesGrid-row">
                  {fontSize}
                  px
                </div>

                {CHOICE_FONT_SIZES.map((choiceFontSize) => {
                  let bodySmallerThanChoice = false
                  if (choiceFontSize >= fontSize) {
                    bodySmallerThanChoice = true
                  }
                  let styleThing = 'fontSizeCalculationVisualizer-outcomesGrid-row'

                  const choiceHeightsForFontSize = choiceHeights[`font_${choiceFontSize}`]
                  let choiceHeight = 0
                  if (choiceHeightsForFontSize) {
                    choiceHeight = choiceHeights[`font_${choiceFontSize}`].total
                  }
                  const combinedHeight = choiceHeight + bodyHeight
                  let comboTooBig = false
                  if (combinedHeight > maxCombinedHeight) {
                    comboTooBig = true
                  }
                  let isPerfectCombo = false
                  if (
                    perfectCombo.bodyFontSize === fontSize &&
                    perfectCombo.choiceFontSize === choiceFontSize
                  ) {
                    isPerfectCombo = true
                  }

                  if (isPerfectCombo) {
                    styleThing = 'fontSizeCalculationVisualizer-outcomesGrid-row--perfectCombo'
                  } else if (bodySmallerThanChoice) {
                    styleThing = 'fontSizeCalculationVisualizer-outcomesGrid-row--choiceBiggerThanBody'
                  } else if (comboTooBig) {
                    styleThing = 'fontSizeCalculationVisualizer-outcomesGrid-row--comboTooBig'
                  }

                  return (
                    <div key={`7-${choiceFontSize}`} className={`fontSizeCalculationVisualizer-outcomesGrid-row ${styleThing}`}>
                      {`${combinedHeight}`}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        )}
        <div className="fontSizeCalculationVisualizer-generatedElements" />
      </div>
    )
  }
}

export default FontSizeCalculationVisualizerPage

import React from 'react'
import Icon from '../../../misc/Icons'

class SectionHomeDnDArchivedItemCard extends React.PureComponent{

 
	render(){
		const {queuePoll}=this.props
		const{index,y,originalPosOfLastPressed,scale}=this.props
		const fixedCardHeight = 147     
		const styleheight = {
			height: fixedCardHeight
		}
          
		const styledrag = {                   
			transform: `translate3d(0px, ${y}px, 0) scale(${scale})`, 
			zIndex: index === originalPosOfLastPressed ? 99 : index,
			'position':'absolute', 
		} 



		return(
			<div key={index} className={'sectionHome-upcoming-upcomingCard ' + (this.props.cardIsPressed ? ' sectionHome-upcoming-upcomingCard--isPressed':'')}  style={{...styledrag, ...styleheight}} id={`DnD_Card_${queuePoll.id}`}>				
										
					
					
				<div className="sectionHome-upcoming-upcomingCard-removeBtn" onClick={()=>{this.props.removeFromQueue(queuePoll)}}>
						<Icon name="close-small"/>
					</div>				

				<div className='sectionHome-upcoming-upcomingCard-background' />
					
			</div>

		)
	
	}
}


export default SectionHomeDnDArchivedItemCard



import React, { Component} from 'react'
import ModalWrapper from './ModalWrapper'
import StaticQuestionSlide from '../slides/StaticQuestionSlide'


class ExpandedQuestionSlideModal extends Component {
	
	

	render() {	
		const {question}=this.props
		return (
			<div className='expandedReviewSlideModal'>
				<ModalWrapper
					{...this.props}
					//title='Expanded review slide'
					modalStyle='light'
					cancelBtn={false}
				>
	
					<StaticQuestionSlide 
						question={question} 
						stage='ask'
						mode='view'
						displayWidth={960}
						previewCard={true}
						mediaIsPlayable
					/>

				</ModalWrapper>
			</div>
		)
	}


	
}

export default ExpandedQuestionSlideModal
import React from 'react' 
import TopPageHeader from '../../components/pageHeaders/TopPageHeader'
import PageNotFoundLabel from '../../components/misc/PageNotFoundLabel'
import AuthorPageRepoLinkLoader from './AuthorPageRepoLinkLoader'

class AuthorLoadingPage extends React.Component {
	render() {
		return (			
			<div className='page page--wideLayout' >
				<div className='page-leftPanel'/>
				<div className='page-centerPanel authorPage authorPageLoader'>
					{!this.props.pageNotFound &&
						<TopPageHeader
							location='authorPage'																	
							header={
								<React.Fragment>
									<div className='pageHeaderTop--author-headerRow-header-loadingPlaceholder' />
								</React.Fragment>
							}
							headerInfoRow={
									<React.Fragment>
										<div className='pageHeaderTop--author-headerRow-header-pageLoaderPlaceholder' />
									</React.Fragment>
								}
							actionRow={
								<React.Fragment> 																															
									<span className='pageHeaderTop--authorPage-actionRow-URL-pageLoaderPlaceholder' />
								</React.Fragment>									
							}
							rightBadge={
								<div className='pageHeaderTop--authorPage-profilePicContainer'>								
									<div className='pageHeaderTop--authorPage-profilePic-pageLoader'/>
								</div>
							}
						/>						
					}
					{!this.props.pageNotFound &&
						<div className='authorPage-share authorPage-share--loading'>							
							<div className='authorPage-share-URL'/>
							<div className='authorPage-share-socialMediaContainer'>		
								<div className='authorPage-share-socialMediaBlob'/>
								<div className='authorPage-share-socialMediaBlob'/>
								<div className='authorPage-share-socialMediaBlob'/>						 		
							</div>						
						</div>
					}
					{!this.props.pageNotFound && 
						<div className='authorPage-repoList authorPage-repoList--loading'>																
							<AuthorPageRepoLinkLoader />
							<AuthorPageRepoLinkLoader />
							<AuthorPageRepoLinkLoader />
						</div>
					}

					{this.props.pageNotFound && 
						<PageNotFoundLabel />
					}
				</div>														
				<div className='page-rightPanel'/>
			</div>			
		)
	}
}

export default AuthorLoadingPage

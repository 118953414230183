import moment from 'moment'
import { calibratedCurrentTimeMoment } from '../calibratedCurrentTime'
import {
  orderAscending,
  mean,
  standardDeviation,
  percentile,
  percentiles,
} from './simpleStats'

// Time based metrics computation utility
// options:
//  segmentEventName -> name of Segment event (mandatory if Segment enabled)
//  additionalSegmentAttributes -> object appended to the Segment track call
//  metricsType -> name of the metrics inserted to the Segment track call (attribute type)
//                   mandatory if Segment enabled
//  periodicFlushInterval -> set to non zero integer, metrics will be reported periodically
//                   in periodicFlushInterval ms, call to flush will cause immediate report,
//                   collected data will be flushed, timer cleared and the TimeMetrics will
//                   wait for next recordValue/recordTimeSince call to restart the periodic
//                   flush interval
//                   default: 0 (disabled)
//  maxSampleSize -> when reached the metrics will be computed and sent to Segment,
//                   the internal value storage will be cleared and new values will be recorded
//                   until next maxSampleSize number of values is reached or flush() is called
//                   set to 0 to disable, default: 10000
// segmentMinSampleSize -> minimal count of values needed to send report to Segment
//                   default: 1
// percentilesToCompute -> array of percentiles (as integers) to compute and report
//                   empty array disabled percentile computation
//                   default: [50, 75, 90, 95, 99]
// disableSegment -> don't send reports to Segment, default: false (Segment enabled)
// How to use:
// 1. instantiate and set options
// 2. call recordValue() to record time point in ms
//    or call recordTimeSince() to record time difference
//         from timestamp supplied as parameter
// 3. call flush() to indicate end of data collection session and send metrics to Segment
//    if not disabled
// in case there might be some values comming after the flush() is called, use ensureOpened() and
// ensureClosed() methods to indicate when the TimeMetrics should start or stop accepting
// new values, by defaults TimeMetrics accepts data when initialized until first call of
// ensureClosed() method

class TimeMetrics {
  constructor(options = {}) {
    this.values = []

    if (!options.disableSegment && (!options.segmentEventName || !options.metricsType)) {
      throw new Error('segmentEventName or metricsType must be set when segment reporting not disabled')
    }

    this.options = {
      disableSegment: false,
      additionalSegmentAttributes: {},
      periodicFlushInterval: 0,
      maxSampleSize: 10000,
      percentilesToCompute: [50, 75, 90, 95, 99],
      metricsType: '', // in case segment disabled avoid undefined value in report
      segmentMinSampleSize: 1,
      ...options,
    }

    this.periodicFlusher = null
    this.isAcceptingValues = true
  }

  reset() {
    this.values = []
  }

  stopPeriodicFlusher() {
    clearInterval(this.periodicFlusher)
    this.periodicFlusher = null
  }

  ensureOpened() {
    this.isAcceptingValues = true
  }

  ensureClosed() {
    this.isAcceptingValues = false
  }

  recordTimeSince(timestamp) {
    if (!timestamp) {
      return
    }
    this.recordValue(calibratedCurrentTimeMoment().diff(moment(timestamp)))
  }

  recordValue(value) {
    if (!value || !this.isAcceptingValues) {
      return
    }

    if (!this.periodicFlusher && this.options.periodicFlushInterval > 0) {
      this.periodicFlusher = setInterval(() => {
        this.doFlush('periodic')
      }, this.options.periodicFlushInterval)
    }

    this.values.push(value)

    if (this.isCapped()) {
      this.doFlush('capped')
    }
  }

  // computes metrics report and returns it, if not disabled by global option
  // sends the report to the Segment
  flush() {
    if (this.options.periodicFlushInterval > 0) {
      // premature flush on periodic flusher
      this.stopPeriodicFlusher()
    }
    this.doFlush('toggle')
  }

  // this is private method, do not call, use flush instead
  doFlush(flushEventName) {
    if (this.periodicFlusher && this.values.length <= 0) {
      // if there are no values and periodic flush is enabled
      // stop the flusher (will be restarted on next recordValue call)
      this.stopPeriodicFlusher()
      return
    }

    const metricsReport = this.getAndSendMetricsReport(flushEventName)
    this.reset()

    // uncomment line below to report metrics to console
    // console.log(metricsReport)
    return metricsReport
  }

  isCapped() {
    return (this.options.maxSampleSize > 0 && this.values.length >= this.options.maxSampleSize)
  }

  // returns metrics report and optionally sends the report to Segment
  getAndSendMetricsReport(flushEventName) {
    const metrics = this.computeMetrics()
    const metricsReport = {
      metricsType: this.options.metricsType,
      flushEvent: flushEventName,
      flushTime: this.options.periodicFlushInterval,
      flushCount: this.options.maxSampleSize,
      ...metrics,
      ...this.options.additionalSegmentAttributes,
    }

    if (metrics.count >= this.options.segmentMinSampleSize && !this.options.disableSegment) {
      // don't report empty metrics
      if (window.analytics) {
        window.analytics.track(this.options.segmentEventName, metricsReport)
      }
    }
    return metricsReport
  }

  computeMetrics() {
    function getDataBuckets(values) {
      const buckets = {
        absBucket0to100: 0,
        absBucket100to200: 0,
        absBucket200to500: 0,
        absBucket500to1000: 0,
        absBucketAbove1000: 0,
      }
      for (let index = 0; index < values.length; index++) {
        const value = Math.abs(values[index])
        buckets.absBucket0to100 += value <= 100 ? 1 : 0
        buckets.absBucket100to200 += (value > 100 && value <= 200) ? 1 : 0
        buckets.absBucket200to500 += (value > 200 && value <= 500) ? 1 : 0
        buckets.absBucket500to1000 += (value > 500 && value <= 1000) ? 1 : 0
        buckets.absBucketAbove1000 += value > 1000 ? 1 : 0
      }
      return buckets
    }

    if (this.values.length <= 0) {
      // if there are no values don't compute anything
      return { count: 0 }
    }
    const sortedValues = orderAscending(this.values)
    const computedPercentiles = this.options.percentilesToCompute === [] ?
      {} : percentiles(sortedValues, this.options.percentilesToCompute)

    return {
      ...computedPercentiles,
      ...getDataBuckets(this.values),
      stdDev: standardDeviation(sortedValues),
      mean: mean(sortedValues),
      median: percentile(sortedValues, 50),
      min: sortedValues[0],
      max: sortedValues[sortedValues.length - 1],
      count: this.values.length,
    }
  }
}

export default TimeMetrics

import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { fetchInvoices } from '../../../actions/billing'
import Button from '../../../components/uiKit/Button'
import Icon from '../../../components/misc/Icons'

// const MONTHLY_PLAN_ID='plan_DOTy1L61pDm9ed'
// const YEARLY_PLAN_ID='plan_DOTzIhVAqtI3wR'

// 7/19/18-8/18/18
function formatUnixTimestampFormat1(timestamp) {
  return moment.unix(timestamp).format('MM/DD/YYYY')
}

// August 19, 2018
function formatUnixTimestampFormat2(timestamp) {
  return moment.unix(timestamp).format('MMMM DD, YYYY')
}

// August 19, 2018
function formatTimestamp(timestamp) {
  return moment(timestamp).format('MMMM DD, YYYY')
}

class InvoiceRow extends Component {
  render() {
    const { invoice } = this.props
    let cardInfoString
    let paymentAmount
    let isPaid
    if (invoice && invoice.charge) {
      cardInfoString = `${invoice.charge.source.brand} •••• •••• •••• ${invoice.charge.source.last4}`
      paymentAmount = (invoice.charge.amount / 100).toFixed(2) // charge is in cents
    }
    if (invoice && invoice.paid) {
      isPaid = true
    }

    return (
      <React.Fragment>
        {paymentAmount && isPaid && (
          <div className="billingInfo-invoiceRow billingInfo-invoiceRow--dataRow">
            <div className="billingInfo-invoiceRow-col billingInfo-invoiceRow-col--date">
              {formatUnixTimestampFormat2(invoice.date)}
            </div>
            <div className="billingInfo-invoiceRow-col billingInfo-invoiceRow-col--description">
              Plickers Pro
            </div>
            <div className="billingInfo-invoiceRow-col billingInfo-invoiceRow-col--servicePeriod">
              {formatUnixTimestampFormat1(invoice.lines.data[0].period.start)}
              {' '}
              -
              {formatUnixTimestampFormat1(invoice.lines.data[0].period.end)}
            </div>
            <div className="billingInfo-invoiceRow-col billingInfo-invoiceRow-col--paymentMethod">
              {cardInfoString}
            </div>
            <div className="billingInfo-invoiceRow-col billingInfo-invoiceRow-col--invoice">
              <a target="_blank" rel="noopener noreferrer" href={invoice.hosted_invoice_url}>
                <Icon name="open-link" />
              </a>
            </div>
            <div className="billingInfo-invoiceRow-col billingInfo-invoiceRow-col--total">

              $
              {paymentAmount}
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

class BillingInfoContainer extends Component {
  UNSAFE_componentWillMount() {
    this.props.fetchInvoices()
  }

  render() {
    const { service, invoices, upcomingInvoice } = this.props
    let planText = ''
    let paidUntil = ''
    const currentSubscriptionPrice = upcomingInvoice ? `$${(upcomingInvoice.amount_due / 100).toFixed(2)}` : null

    if (service) {
      if (service.plan === process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID) {
        planText = `${currentSubscriptionPrice} / month`
      } else if (service.plan === process.env.REACT_APP_STRIPE_YEARLY_PLAN_ID) {
        planText = `${currentSubscriptionPrice} / year`
      }
      paidUntil = service.paidUntil
    }

    return (
      <div className="billingInfo-content">

        {service.status !== 'canceled' && service.product !== 'free' && (
        <div className="billingInfo-top">
          <div className="billingInfo-top-item">
            <div className="billingInfo-top-item-label">
              Your Plan
            </div>
            <div className="billingInfo-top-item-value">
              {planText}
            </div>
          </div>

          <div className="billingInfo-top-item">
            <div className="billingInfo-top-item-label">
              Next Billing Date
            </div>
            <div className="billingInfo-top-item-value">
              {formatTimestamp(paidUntil)}
            </div>
          </div>
        </div>
        )}

        {invoices && (
        <div className="billingInfo-invoiceTable">
          <div className="billingInfo-invoiceRow billingInfo-invoiceRow--headerRow">
            <div className="billingInfo-invoiceRow-col billingInfo-invoiceRow-col--date">
              Date
            </div>
            <div className="billingInfo-invoiceRow-col billingInfo-invoiceRow-col--description">
              Description
            </div>
            <div className="billingInfo-invoiceRow-col billingInfo-invoiceRow-col--servicePeriod">
              Service Period
            </div>
            <div className="billingInfo-invoiceRow-col billingInfo-invoiceRow-col--paymentMethod">
              Payment Method
            </div>
            <div className="billingInfo-invoiceRow-col billingInfo-invoiceRow-col--invoice">
              Invoice
            </div>
            <div className="billingInfo-invoiceRow-col billingInfo-invoiceRow-col--total">
              Total
            </div>
          </div>

          {invoices.map((invoice, index) => (<InvoiceRow key={index} invoice={invoice} />))}

        </div>
        )}

        <div className="billingInfo-doneBtnContainer">
          <Button size="xLarge" color="blue" label="Done" onClickFunction={this.props.hideModal} />
        </div>

      </div>
    )
  }
}

export default connect(
  (state) => ({
    invoices: state.invoices,
    service: state.service,
    upcomingInvoice: state.upcomingInvoice,

  }),
  { fetchInvoices },
)(BillingInfoContainer)

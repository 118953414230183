import * as Sentry from '@sentry/browser'
import {
  BroadcastChannel,
  createLeaderElection,
} from 'broadcast-channel'
import Cookies from 'js-cookie'
import { nanoid } from 'nanoid'
import api from '../api'

const CLIENT_IDENTITY_TOKEN_LENGTH = 32

// define clientIdentityToken cookie options
const apiUrl = new URL(process.env.REACT_APP_PLICKERS_API_ENDPOINT)
const cookieDomain = apiUrl.hostname === 'localhost' ?
  'localhost' :
  apiUrl.hostname.split('.').slice(-2).join('.') // the last two parts of the hostname (e.g. plickers.com) to allow access from subdomains
const CLIENT_IDENTITY_COOKIE_OPTIONS = {
  expires: 365 * 3, // 3 years in days (though browsers might have their own limits - e.g. Chrome 400 days, Safari 7 days w/o user activity, etc.)
  path: '/',
  domain: cookieDomain,
  secure: apiUrl.protocol === 'https:',
  sameSite: 'Lax',
}

let channel

export function triggerEnsureClientIdentity() {
  return (dispatch, getState) => {
    // get token from cookie
    const token = Cookies.get('clientIdentityToken')
    if (token) {
      // update the expiry of the cookie by setting it again (with new Expires)
      Cookies.set('clientIdentityToken', token, CLIENT_IDENTITY_COOKIE_OPTIONS)
      dispatch({
        type: 'ENSURE_CLIENT_IDENTITY_SUCCESS',
        response: { token },
      })
    } else {
      setUpGenerateClientIdentityTabSync(dispatch, getState)
    }
  }
}

function setUpGenerateClientIdentityTabSync(dispatch, getState) {
  channel = new BroadcastChannel('generate-clientIdentity-token-sync-service')
  // listen for createdClientIdentityTokenAction message and dispatch
  channel.onmessage = (ensureClientIdentityAction) => {
    dispatch(ensureClientIdentityAction)
    // no longer need to listen for messages
    channel.close()
  }
  // create upon tab leadership
  generateClientIdentityTokenAndTrustUponLeaderElection(channel, dispatch, getState)
}

async function generateClientIdentityTokenAndTrustUponLeaderElection(channel, dispatch, getState) {
  // wait until this tab is the leader
  const election = createLeaderElection(channel)
  await election.awaitLeadership()

  // no op if clientIdentity is already created
  const { clientIdentity } = getState()
  if (clientIdentity) return

  const generatedToken = generateClientIdentityToken(
    dispatch,
    true,
  )

  // trust if possible
  const { session } = getState()
  let isTrusted = false
  if (session.sessionInfo?.user) {
    isTrusted = await trustClientIdentityIfPossible(
      dispatch,
      generatedToken,
      session.sessionInfo,
    )
  }

  channel.postMessage(getEnsureClientIdentityAction({
    token: generatedToken,
    isTrusted,
  }))
}

export function generateClientIdentityToken(dispatch, skipBroadcast) {
  const token = nanoid(CLIENT_IDENTITY_TOKEN_LENGTH)
  Cookies.set('clientIdentityToken', token, CLIENT_IDENTITY_COOKIE_OPTIONS)
  const clientIdentityObj = { token }
  dispatch(getEnsureClientIdentityAction(clientIdentityObj))
  if (!skipBroadcast) {
    channel.postMessage(getEnsureClientIdentityAction(clientIdentityObj))
  }
  return token
}

function getEnsureClientIdentityAction(clientIdentityObj) {
  return {
    type: 'ENSURE_CLIENT_IDENTITY_SUCCESS',
    response: clientIdentityObj,
  }
}

export function trustClientIdentity(session) {
  return async (dispatch) => {
    await trustClientIdentityIfPossible(dispatch, session)
  }
}

async function trustClientIdentityIfPossible(dispatch, session) {
  const { trustClient } = session
  let isTrusted = false
  const shouldTrust = !trustClient?.isDeclined &&
    !trustClient?.isConsumed &&
    (trustClient?.expiresAt === null || new Date(trustClient?.expiresAt) > new Date())
  if (shouldTrust) {
    try {
      await api.put('/clientIdentities/trust-self')
      dispatch({
        type: 'TRUST_CLIENT_IDENTITY_SUCCESS',
      })
      isTrusted = true
    } catch (e) {
      Sentry.captureException(e)
    }
  }
  return isTrusted
}

import React, { useState } from 'react'
import { connect } from 'react-redux'
import EditUserEmailForm from '../../../components/forms/account/EditUserEmailForm'
import { updateUserEmail } from '../../../actions/user'
import { showNotification } from '../../../actions/notifications'
import handleAccountRequest from '../../account/utils/handleAccountRequest'

function UpdateEmailContainer(props) {
  const {
    user, updateUserEmail, showNotification, hideModal,
  } = props
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [recaptchaFailed, setRecaptchaFailed] = useState(false)

  function onPreSubmit() {
    setSubmitLoading(true)
  }

  function onSubmit(formData) {
    const requestData = {
      email: formData.email,
      password: formData.currentPassword,
      recaptchaResponse: formData.recaptchaResponse,
    }
    handleAccountRequest({
      setSubmitLoading,
      setErrorMessage,
      makeRequest: () => updateUserEmail(requestData, user.id),
      hideModal,
      showSuccessNotification: () => showNotification(`to ${formData.email}`, 'Updated Email', 'default'),
      requestName: 'updateEmail',
    })
  }

  return (
    <React.Fragment>

      <EditUserEmailForm
        email={user.email}
        onPreSubmit={() => onPreSubmit()}
        onSubmit={(formData) => onSubmit(formData)}
        submitLoading={submitLoading}
        onRecaptchaError={() => setRecaptchaFailed(true)}
      />

      {recaptchaFailed && (
        <div className='accountForm-error accountForm-error--recaptcha'>
          <div>
            This form requires reCAPTCHA but it failed to load.
          </div>
          <div>
            Ensure google.com/recaptcha/api.js is not blocked by your browser or network.
          </div>
        </div>
      )}

      {errorMessage && (
        <div className='accountForm-error'>
          {errorMessage}
        </div>
      )}

    </React.Fragment>
  )
}

export default connect(
  (state) => ({
    user: state.user,

  }),
  { updateUserEmail, showNotification },
)(UpdateEmailContainer)

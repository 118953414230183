//Layout values for default slide size of 1280px
//These layout parameters are relative to viewport only NOT num of students etc

const layoutValues1280={
	internalSafePadding:15,

	studentListLeftMargin:2,
	studentListTopMargin:10,
	studentListFontSize:19,
	studentListLetterBlockMargin: 2,
	
	studentListItemHeight:36,
	studentListItemWidth:134,
	studentListItemPillMargin:2,
	studentListPillBorderRadius:2,

	reviewControlFontSize:15,
	reviewControlWidth: 100,
	reviewControlShowCorrectWidth: 120,
	reviewControlIndividualStudentWidth: 140,
	reviewControlHeight: 30,
	reviewControlBorderRadius: 4,
	reviewControlMarginLeft: 2,
}


const standardViewportWidth = 1280
const slideAspectRatio = 1280 / 800 // default slide dimensions


//scanListCount=number of items to be displayed in scan list= numOfStudents+numOfGuestResponses

export function calculateNPLayout(viewportWidth,viewportHeight,scanListCount,isFullScreen) {

	let defaultHeightLiveViewToolBar=48
	let topSafePadding=defaultHeightLiveViewToolBar
	if(isFullScreen){
		topSafePadding = 0 //
	}


	//1. Scale layoutValues1280 values to viewport dimensions
	let layoutValues={}
	Object.keys(layoutValues1280).map((key) => {
		const scaledValue=calculateViewportRelativeSize(layoutValues1280[key])
		layoutValues[key]=scaledValue
		return null
	})

	
	function calculateViewportRelativeSize(size){ 	 	// converts 1280px sizes into viewport-relative size
		const viewportRelativeRatio = viewportWidth / standardViewportWidth 		// find ratio
		const viewportRelativeSize = size * viewportRelativeRatio 		// multiply input size by ratio to find relative size
		return viewportRelativeSize
	}

	//2.Calculate Available Content Dimensions 
	//scanning=false--> subtract height of toolbar
	//scanning=true--> subtract height of toolbar AND enforces safe area around whole screen (for projector)

	const availableContentDimensions={
		width:viewportWidth,
		height:viewportHeight-topSafePadding
	}

	const availableContentDimensionsScan={
		width:viewportWidth-2*layoutValues.internalSafePadding,
		height:viewportHeight-2*layoutValues.internalSafePadding,
	}


	const availableHeightStudentList = availableContentDimensionsScan.height - layoutValues.reviewControlHeight - layoutValues.studentListTopMargin

	//3.Calculate student list width
	const studentItemRowsPerColumn = Math.floor(availableHeightStudentList / layoutValues.studentListItemHeight)
	const studentListColumns = Math.ceil(scanListCount / studentItemRowsPerColumn)
	const studentListWidth = studentListColumns * layoutValues.studentListItemWidth + layoutValues.studentListLeftMargin
		
	//4. Calculate slide dimensions
	//scanning=false--> max slide that will fit into available content dimensions
	//scanning=true--> max slide that will fit into available content dimension - student list width


	const slideDimensions=calculateSlideDimensions(availableContentDimensions.width,availableContentDimensions.height)
	const slideDimensionsScan=calculateSlideDimensions(availableContentDimensionsScan.width-studentListWidth,availableContentDimensionsScan.height)

	function calculateSlideDimensions(availableSlideWidth, availableSlideHeight){
		if(availableSlideHeight * slideAspectRatio > availableSlideWidth){
			return{
				width:availableSlideWidth,
				height:availableSlideWidth / slideAspectRatio
			}	
		}
		else return{
			width:availableSlideHeight*slideAspectRatio,
			height:availableSlideHeight	
		}	
	}


	
	// Calculate Y Position of Slide
	const yPosSlide = topSafePadding
	const yPosSlideScan = topSafePadding

	// Calculate X Position of Slide
	const xPosSlide = (viewportWidth - slideDimensions.width) / 2
	const xPosSlideScan = Math.min(
			((viewportWidth - slideDimensionsScan.width) / 2), 
			(viewportWidth - slideDimensionsScan.width - studentListWidth - layoutValues.internalSafePadding)
		)
	

	// Calculate values for Transitions
	const scanSlideScale = slideDimensionsScan.width / slideDimensions.width

	const yPosScanTranslate = yPosSlideScan - yPosSlide
	const xPosScanTranslate = xPosSlideScan - xPosSlide

	const studentListxPosScanTranslate = studentListWidth + layoutValues.internalSafePadding



	const layout={
		
		'viewportWidth':viewportWidth,
		'viewportHeight':viewportHeight,

		'defaultHeightLiveViewToolbar':defaultHeightLiveViewToolBar,
		'topSafePadding':topSafePadding,	
		'internalSafePadding':layoutValues.internalSafePadding,
		
		'heightScanProgressBar':layoutValues.heightScanProgressBar,
		'heightScanProgressBarBar':layoutValues.heightScanProgressBarBar,
		'progressBarFontSize':layoutValues.progressBarFontSize,
		'verticalPaddingProgressBar':layoutValues.verticalPaddingProgressBar,

		'studentListLeftMargin':layoutValues.studentListLeftMargin,
		'studentListTopMargin':layoutValues.studentListTopMargin,
		'studentListFontSize':layoutValues.studentListFontSize,
		'studentListGuestLabelFontSize':layoutValues.studentListGuestLabelFontSize,
		'studentListItemHeight':layoutValues.studentListItemHeight,
		'studentListItemWidth':layoutValues.studentListItemWidth,
		'studentListItemPillMargin':layoutValues.studentListItemPillMargin,
		'studentListPillBorderRadius':layoutValues.studentListPillBorderRadius,
		'studentListLetterBlockMargin':layoutValues.studentListLetterBlockMargin,

		'reviewControlFontSize':layoutValues.reviewControlFontSize,
		'reviewControlWidth':layoutValues.reviewControlWidth,
		'reviewControlHeight':layoutValues.reviewControlHeight,
		'reviewControlIndividualStudentWidth':layoutValues.reviewControlIndividualStudentWidth,
		'reviewControlMarginLeft':layoutValues.reviewControlMarginLeft,
		'reviewControlBorderRadius':layoutValues.reviewControlBorderRadius,
		'reviewControlShowCorrectWidth':layoutValues.reviewControlShowCorrectWidth,
		
		'studentListWidth':studentListWidth,

		'xPosSlide':xPosSlide,
		'yPosSlide':yPosSlide,

		'slideDimensions':slideDimensions,
		'slideDimensionsScan':slideDimensionsScan,

		'xPosSlideScan':xPosSlideScan,
		'yPosSlideScan':yPosSlideScan,

		'scanSlideScale':scanSlideScale,
		'xPosScanTranslate':xPosScanTranslate,
		'yPosScanTranslate':yPosScanTranslate,

		'studentListxPosScanTranslate':studentListxPosScanTranslate,
		
	}

	return layout
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditUserNameForm from '../../../components/forms/account/EditUserNameForm'
import {updateUser} from '../../../actions/user'
import {showNotification} from '../../../actions/notifications'

 
class UpdateNameContainer extends Component {
  
	constructor(props){
		super(props)
		this.updateUserName=this.updateUserName.bind(this)

		this.state = {
			submitLoading:false,
			errorMessage:null
		
		}
		
	}
 

	updateUserName(formData) {
		this.setState({submitLoading:true,errorMessage:null})
		const nowDate = new Date()
		const requestData = { 
			...this.props.user,
			'firstName': formData.firstName,
			'lastName': formData.lastName,
			'title':formData.title,
			'clientModified': nowDate,
		}
		this.props.updateUser(requestData, this.props.user.id).then((response) => {
		 	if(!response.message){
		 		const notificationMessage=`to ${formData.firstName} ${formData.lastName}`
		 		this.props.showNotification(notificationMessage,'Updated name','default')
			this.props.hideModal()
		 	}else{

		 		//const errorMessage=`Error: ${response.message}`
		 		//this.setState({successBanner:errorMessage,bannerType:'failure'})
		 		this.setState({submitLoading:false,errorMessage:'Unable to save changes, please try again'})
		 	}
		 })
	} 

	render() {
		const {user}=this.props
		const {errorMessage,submitLoading}=this.state

		return (

			<React.Fragment>

				<EditUserNameForm
					firstName={user.firstName}
					lastName={user.lastName}
					title={user.title}
					onSubmit={this.updateUserName}
					hideModal={this.props.hideModal}
					submitLoading={submitLoading}
				/>

				{errorMessage &&		
					<div className='accountForm-error'>
						{errorMessage}					
					</div>
				}

			</React.Fragment>
		)
	}
}


export default connect(
	(state) => ({
		user:state.user,

	}),
	{ updateUser,showNotification}
)(UpdateNameContainer)



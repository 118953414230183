import React from 'react'
import {formatDiscoverLatestTimestamp} from '../../utils/formatDiscoverLatestTimestamp'
import {calculatePlayCount} from '../../utils/calculatePlayCount'
import TopPageHeader from '../pageHeaders/TopPageHeader'
import StickyPageHeader from '../pageHeaders/StickyPageHeader'
import DiscoverFeaturedRepoLink from './DiscoverFeaturedRepoLink'
import DiscoverMiniRepoLink from './DiscoverMiniRepoLink'
import Icon from '../misc/Icons'
import DiscoverSubjectPageFooter from './DiscoverSubjectPageFooter'
import discoverRepoIds from '../../staticData/discoverRepoIds.js'

//TODO combine with early math subject page

const biologyRepoShortIds=discoverRepoIds.biologyRepoShortIds

class DiscoverBiologyPage extends React.Component{

	UNSAFE_componentWillMount() {
		if(this.props.repos.length !== biologyRepoShortIds.length){
			biologyRepoShortIds.forEach((shortId)=>{
				this.props.fetchAPublishedRepoByShortId(shortId)
				.catch((error)=>{})
			})
		}
	}

	render(){
		const {repos}=this.props
		const discoverNavRow=
			<React.Fragment>								
				<div className='pageHeaderTop-folderNavRow-folderPathItem is--library' onClick={()=>{this.props.history.push('/discover')}}>
					Discover
				</div>
				<div className='pageHeaderTop-folderNavRow-folderPathItem is--folder is--currentFolder'>
	        <Icon name='chevron-right'/>			       
	         Biology
			  </div>
			</React.Fragment>


		return(
			<div className="page page--extraWideLayout discover-market">
				<div className="page-leftPanel" />
				<div className='page-centerPanel'>
					<TopPageHeader
						header='High School Biology'
						location='discoverMarket'
						headerInfoRow='Biology · Grades 9+'
						leftBadge={
							<React.Fragment>
								<Icon name='chevron-left' />										
							</React.Fragment>
						}
						leftBadgeClickFunction={()=>{this.props.history.push('/discover')}}
						folderNavRow={discoverNavRow}
					/>

					<StickyPageHeader 												
						header='High School Biology'
					/>			

					<div className='discover-market-featuredReposContainer'>
							<div className='discover-market-featuredRepos-header'>
								Featured Packs
							</div>
							<div className='discover-market-featuredRepos-list'>

								{repos.map((repo)=>{
									return(
										<DiscoverFeaturedRepoLink
											key={repo.id}
											repo={repo}
											rating={repo.rating}	
											market={'highSchoolBiology'}										
										/>																					
									)					

								})}
							</div>							
						</div> 	

						<div className='discover-market-subsectionReposContainer'>
							<div className='discover-market-subsectionRepos-divider'/>
							<div className='discover-market-subsectionRepos-header'>
								Latest Packs 
								{process.env.REACT_APP_ENV!== 'production' &&
									<button className='discover-market-subsectionRepos-header-seeMoreBtn' onClick={()=>{this.props.history.push('/discover/biology/latest')}}>
										See All
										<Icon name='chevron-right' />
									</button>
								}
							</div>
							<div className='discover-market-subsectionRepos-list discover-market-subsectionRepos-list--12grid'>

								{this.props.latestRepos.map((repo)=>{
									return(
										<DiscoverMiniRepoLink
											market={'highSchoolBiology'}	
											loadRepoContent={this.props.loadRepoContent}
											key={repo.id}
											repo={repo}
											timestamp={formatDiscoverLatestTimestamp(repo.publishedAt)}
										/>																					
									)					

								})}
							</div>							
						</div> 							
					
						
							<div className='discover-market-subsectionReposContainer'>
								<div className='discover-market-subsectionRepos-divider'/>
								<div className='discover-market-subsectionRepos-header'>
									Popular Packs 
									{process.env.REACT_APP_ENV!== 'production' &&
									<button className='discover-market-subsectionRepos-header-seeMoreBtn' onClick={()=>{this.props.history.push('/discover/biology/popular')}}>
										See All
										<Icon name='chevron-right' />
									</button>
								}
								</div>
								<div className='discover-market-subsectionRepos-list discover-market-subsectionRepos-list--12grid'>

									{this.props.popularRepos.map((repo)=>{
										return(
											<DiscoverMiniRepoLink
												list='popular'
												market={'biology'}
												loadRepoContent={this.props.loadRepoContent}
												key={repo.id}
												repo={repo}
												playCount={calculatePlayCount(repo.responseCount)}
												hidePlayCount
												//timestamp={formatDiscoverLatestTimestamp(repo.publishedAt)}
											/>																					
										)					

									})}
								</div>							
							</div> 	
					
					{process.env.REACT_APP_ENV!== 'production' &&
							<div className='discover-market-subsectionReposContainer'>
								<div className='discover-market-subsectionRepos-divider'/>
								<div className='discover-market-subsectionRepos-header'>
									Highly Rated Packs
									<button className='discover-market-subsectionRepos-header-seeMoreBtn' onClick={()=>{this.props.history.push('/discover/biology/rated')}}>
										See All
										<Icon name='chevron-right' />
									</button>									
								</div>
								<div className='discover-market-subsectionRepos-list discover-market-subsectionRepos-list--12grid'>

									{this.props.highlyRatedRepos.map((repo)=>{
										return(
											<DiscoverMiniRepoLink
												list='popular'
												market={'biology'}
												loadRepoContent={this.props.loadRepoContent}
												key={repo.id}
												repo={repo}
												rating={repo.rating}
											/>																					
										)					
									})}
								</div>							
							</div> 	
					}

						<DiscoverSubjectPageFooter 
							showNotification={this.props.showNotification}
							discoverMarket='highSchoolBiology'
							showRequestTopic
							showFAQ
							showGeneralFeedback
						/>
			</div>
			<div className="page-rightPanel" />
			</div>
		)
	}
}

export default DiscoverBiologyPage
// COLOR ADJUST
// BRIGHTNESS max out at brightness -50 on cloudinary
const cssFilterValues={
	brightness:{
		min:0.4,
		center:1,
		max:2.0
	},
	color:{
		min:0,
		center:1,
		max:1.8
	},
	contrast:{
		min:0.5,
		center:1,
		max:1.6
	}
}    

function getTranslatedValue(type,value){
	let translatedValue=cssFilterValues[type].center
	if(value < 0){
      translatedValue = 1 - (value / -100) * (cssFilterValues[type].center - cssFilterValues[type].min)     
	}else if(value > 0){
      translatedValue = 1 + (value / 100 * (cssFilterValues[type].max - cssFilterValues[type].center))      
	}
	return translatedValue
}



export default function getTranslatedColorAdjustParams(brightnessValue,colorValue,contrastValue){    
   const brightnessTranslatedValue=getTranslatedValue('brightness', brightnessValue)
   const colorTranslatedValue=getTranslatedValue('color', colorValue)
   const contrastTranslatedValue=getTranslatedValue('contrast', contrastValue)

	const imageColorAdjust = {
		filter: `brightness(${brightnessTranslatedValue}) saturate(${colorTranslatedValue}) contrast(${contrastTranslatedValue})`,            
	}

    return imageColorAdjust
}




// export default function getTranslatedColorAdjustParams(brightnessValue,colorValue,contrastValue){
//     // this bit is all the same so maybe can be generalized

//     let brightnessTranslatedValue = CENTER_BRIGHTNESS_CSS_FILTER_VALUE
//     let colorTranslatedValue = centerColorCSSFilterValue
//     let contrastTranslatedValue = centerContrastCSSFilterValue

//     if(brightnessValue < 0){         
//       brightnessTranslatedValue = 1 - (brightnessValue / -100) * (CENTER_BRIGHTNESS_CSS_FILTER_VALUE - MIN_BRIGHTNESS_CSS_FILTER_VALUE)            
//     }

//     if(brightnessValue > 0){
//       brightnessTranslatedValue = 1 + (brightnessValue / 100 * (MAX_BRIGHTNESS_CSS_FILTER_VALUE - CENTER_BRIGHTNESS_CSS_FILTER_VALUE))      
//     }

//     if(colorValue < 0){         
//       colorTranslatedValue = 1 - (colorValue / -100) * (centerColorCSSFilterValue - minColorCSSFilterValue)            
//     }

//     if(colorValue > 0){
//       colorTranslatedValue = 1 + (colorValue / 100 * (maxColorCSSFilterValue - centerColorCSSFilterValue))      
//     }

//     if(contrastValue < 0){         
//       contrastTranslatedValue = 1 - (contrastValue / -100) * (centerContrastCSSFilterValue - minContrastCSSFilterValue)            
//     }

//     if(contrastValue > 0){
//       contrastTranslatedValue = 1 + (contrastValue / 100 * (maxContrastCSSFilterValue - centerContrastCSSFilterValue))      
//     }

    
//     const imageColorAdjust = {
//       filter: `brightness(${brightnessTranslatedValue}) saturate(${colorTranslatedValue}) contrast(${contrastTranslatedValue})`,            
//     }
//     return imageColorAdjust


// }




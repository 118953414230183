import {showNotification} from '../../actions/notifications'
import store from '../../store'
import filter from 'lodash/filter'
import moment from 'moment'


const MAX_NOTIFICATION_COUNT=3 
const NOTIFICATION_TIME_PERIOD=60*24*30//resets every 30 days


export function showFractionShortcutNotification(symbol){
	const fractionShortcutNotificationHistory = JSON.parse(localStorage.getItem('fractionShortcutNotificationHistory')) || []
	const now=moment()
	let dateFilteredNotifications=filter(fractionShortcutNotificationHistory,function (n) { 	
		const timeAgo=now.diff(n.timestamp,'minutes')
		return timeAgo < NOTIFICATION_TIME_PERIOD
	})
	const notificationCount=filter(dateFilteredNotifications).length
	if(notificationCount<MAX_NOTIFICATION_COUNT){
		store.dispatch(showNotification('Next time, try typing 1/2 and hitting spacebar.','Tip: For numbers-only fractions, insert faster by typing.','default'))
		dateFilteredNotifications.push({timestamp:moment()})
		localStorage.setItem('fractionShortcutNotificationHistory', JSON.stringify(dateFilteredNotifications))
	}
}




import api from '../api'
import {fetchARepo} from './repos'
import {fetchFoldersForRepo} from './folders'
import {fetchSetsForRepo} from './sets'
import {fetchQuestionsForRepo} from './questions'
import {repoPusherSetup} from '../utils/pusher/repoPusherSetup'



export function permissionUpdatedPusherReceived(permission) {
	return (dispatch) => {
		dispatch(fetchPermissions(permission.repo))
		dispatch(fetchARepo(permission.repo))		
	}
}

export function fetchPermissions(repoId) {
	return (dispatch) => api.fetch(`/repos/${repoId}/permissions`)
		.then((response) => {
			dispatch({ type: 'FETCH_PERMISSIONS_SUCCESS',response,repoId})
			return response
		})
		.catch(err => {})
}

export function inviteByEmail(repoId,email,permissionType) {
	const requestBody={
		originalEmail: email,
		role: permissionType
	}
	return (dispatch) => api.post(`/repos/${repoId}/permissions`,requestBody)
		.then((permission) => {
			dispatch({ type: 'CREATE_PERMISSION_SUCCESS',permission,repoId})
			if(window.analytics){
				window.analytics.track('Invite to Repo', {
					repoId:repoId,
					permissionRole:permissionType,
					isContactList:false
				})}
			return permission
		})
		.catch(err => {
			let response={
				error:err,
				email:email
			}
			return response
		})
}

export function inviteByUser(repoId,userId,permissionType) {
	const requestBody={
		user: userId,
		role: permissionType
	}
	return (dispatch) => api.post(`/repos/${repoId}/permissions`,requestBody)
		.then((permission) => {
			dispatch({ type: 'CREATE_PERMISSION_SUCCESS',permission,repoId})
			if(window.analytics){
				window.analytics.track('Invite to Repo', {
					repoId:repoId,
					permissionRole:permissionType,
					inContactList:true
				})}
			return permission
			})
		.catch(err => {
			let response={
				error:err,
				user:userId
			}
		return response
	})
}


export function updatePermission(data,repoId) {
	return (dispatch) => api.put(`/repos/${repoId}/permissions`,data)
		.then((response) => {
			const permission=response
			dispatch({ type: 'UPDATE_PERMISSION_SUCCESS', permission, repoId})
			return response
		})
		.catch(err => {})
}

export function joinRepo(repoId) {
	const nowDate=new Date()
	const requestBody={joinedAt: nowDate}
	return (dispatch) => api.put(`/repos/${repoId}/permissions/join`,requestBody)
		.then((response) => {
			dispatch(fetchARepo(repoId))
			dispatch(fetchPermissions(repoId))
			dispatch(fetchFoldersForRepo(repoId))
			dispatch(fetchQuestionsForRepo(repoId))
			dispatch(fetchSetsForRepo(repoId))
			repoPusherSetup(repoId) //subscribe to pusher for repo
			return response
		})
		.catch(err => {
			throw err
		})
}

export function leaveRepo(repoId,userId) {
	const requestBody={user: userId}
	return (dispatch) => api.delete(`/repos/${repoId}/permissions`,requestBody)
		.then((response) => {
			dispatch({ type: 'DELETE_REPO_SUCCESS', repoId})
		})
	.catch(err => {})
}
		
export function deletePermission(repoId,requestData) {
	return (dispatch) => api.delete(`/repos/${repoId}/permissions`,requestData)
		.then((response) => {
			dispatch({ type: 'DELETE_PERMISSION_SUCCESS', repoId,requestData})
		})
		.catch(err => {})
}

export function rejectInvitation(repoId,userId) {	
	const requestBody={user: userId}
	return (dispatch) => api.delete(`/repos/${repoId}/permissions`,requestBody)
		.then((response) => {
			dispatch({ type: 'DELETE_REPO_SUCCESS', repoId})		   
		})
		.catch(err => {})
}

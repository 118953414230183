import React from 'react'
import { Spring } from  'react-spring/renderprops'


class DropdownMenu extends React.Component{


	render(){	
		const {children, position, inverted, isOpen, dropdownYPosTop, dropdownYPosBottom} = this.props
		

		const translateYDistance = 10
		let translateYDirection = 'down'
		if(inverted){
			translateYDirection = 'up'
		}

		let translateY

		if(translateYDirection === 'down'){
			translateY = translateYDistance * -1
		}
		if(translateYDirection === 'up'){
			translateY = translateYDistance * 1
		}

		let fixedPosition
		if(dropdownYPosTop || dropdownYPosBottom){
			fixedPosition = true
		}

		return(
		  	
			<Spring
				to={{
          opacity: isOpen ? 1 : 0,
          yTranslate: isOpen ? 0 : translateY,
        }}
        config={{ tension: 600, friction: 40 }}
      	>
        {props => 
        		<div 
        			style={{ transform: `translateY(${props.yTranslate}px)`, opacity:`${props.opacity}`, top: (dropdownYPosTop ? `${dropdownYPosTop}px` : null), bottom: (dropdownYPosBottom ? `${dropdownYPosBottom}px` : null)}}
							className={'dropdownMenu ' + (isOpen ? 'is--open ' : 'is--closed ') + (position ? `position--${position}` : '') + (inverted ? ' direction--up' : ' direction--down') + (fixedPosition ? ' dropdownMenu--fixedPosition' : '')}
						>
							{children}
					</div>
      }					
			
			</Spring>
			
		)
	}
				
}

DropdownMenu.defaultProps = {
	position: 'left',
}

export default DropdownMenu

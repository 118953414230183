import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hideNotification } from '../actions/notifications'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import moment from 'moment'
import {fetchQuestionPolls, fetchSetPolls} from '../actions/polls'
import HistoryPage from '../components/history/HistoryPage'
import SectionHistoryPage from '../components/section/SectionHistoryPage'
import DocumentTitle from 'react-document-title'
 
//Same component for section history and general history 
//If has prop sectionId if is section history
 
class HistoryContainer extends Component {
 
	constructor(){
		super()	
		this.changeFilterTerm=this.changeFilterTerm.bind(this)		
		this.toggleArchived=this.toggleArchived.bind(this)
		this.showMore=this.showMore.bind(this)
		this.state = {
			filterTerm:'',
			showArchived:false,
			pageSize:30,
			currentlyShowing:30
		}
	}

	UNSAFE_componentWillMount() {
		this.props.fetchQuestionPolls()
		this.props.fetchSetPolls()
	}

	componentDidMount(){
		if(!this.props.sectionId){
			if(window.analytics){
				window.analytics.page('Reports')
			}
		}
	}

	componentWillUnmount() {
		this.props.hideNotification()
	}

	
	changeFilterTerm(term){
		this.setState({filterTerm:term})
	}

	toggleArchived(){
		this.setState({showArchived:!this.state.showArchived})
	}

	filterPolls(polls,filterTerm){
		let filteredPolls=[]
		polls.map((poll) => {
			if(poll.snapshot){
				if(poll.snapshot.name){
					if(includes(poll.snapshot.name.toLowerCase(),filterTerm.toLowerCase())){
						filteredPolls.push(poll)
					}}
					else if (poll.snapshot.body){
						if(includes(poll.snapshot.body.toLowerCase(),filterTerm.toLowerCase())) {
							filteredPolls.push(poll)
						}
					}	
			}
			return null
		})
		return filteredPolls
	}

	showMore(){
		const newCurrentlyShowing=this.state.currentlyShowing+this.state.pageSize
		this.setState({currentlyShowing:newCurrentlyShowing})	
	}

	render() {	
		const {filterTerm,showArchived}=this.state
 
		if(this.props.sectionId){
			let polls=this.props.orderedSectionPolls
			let dateGroupIds=this.props.firstItemInDateGroupIds
			if(showArchived){
				polls=this.props.archivedSectionPolls
				dateGroupIds=this.props.archivedFirstItemInDateGroupIds
			}
			let filteredPolls=polls
			if(filterTerm !==''){
				filteredPolls=this.filterPolls(polls,this.state.filterTerm)
			}
			const pagedPolls=filteredPolls.slice(0,this.state.currentlyShowing)
			return (
				<DocumentTitle title={!showArchived ? `${this.props.currentSection.name || 'Class'} Reports - Plickers` : `${this.props.currentSection.name || 'Class'} Archived Reports - Plickers` }>
					<SectionHistoryPage
						navigateToSectionHome={this.props.navigateToSectionHome}
						toggleArchived={this.toggleArchived}
						showArchived={this.state.showArchived}
						firstItemInDateGroupIds={dateGroupIds}
						filterTerm={this.state.filterTerm}
						changeFilterTerm={this.changeFilterTerm}
						orderedPolls={pagedPolls}
						sections={this.props.sections}
						filterPolls={this.filterPolls}
						currentSection={this.props.currentSection}
						totalPolls={filteredPolls.length}
						showMore={this.showMore}
					/>
				</DocumentTitle>
			)
		}	else {
			let polls=this.props.orderedPolls
			let dateGroupIds=this.props.firstItemInDateGroupIds
			if(showArchived){
				polls=this.props.archivedPolls
				dateGroupIds=this.props.archivedFirstItemInDateGroupIds
			}
			let filteredPolls=polls
			if(filterTerm !==''){
				filteredPolls=this.filterPolls(polls,this.state.filterTerm)
			}
			const pagedPolls=filteredPolls.slice(0,this.state.currentlyShowing)
			return(
				<DocumentTitle title={!showArchived ? 'Reports - Plickers' : 'Archived Reports - Plickers'}>
					<HistoryPage
						toggleArchived={this.toggleArchived}
						showArchived={this.state.showArchived}
						filterTerm={this.state.filterTerm}
						changeFilterTerm={this.changeFilterTerm}
						orderedPolls={pagedPolls}
						totalPolls={filteredPolls.length}
						firstItemInDateGroupIds={dateGroupIds}
						sections={this.props.sections}
						filterPolls={this.filterPolls}
						showMore={this.showMore}
					/>
				</DocumentTitle>
			)	 }
		
	}
}
 

function calculateFirstItemInDateGroupIds(polls) {
	const groupedPolls = groupBy(polls, function(poll) {
		const nowDate = new Date()
		const today = moment(nowDate).startOf('day')
		const week = moment(nowDate).startOf('isoWeek')
		if (moment(poll.minCaptured).isSame(today, 'd')) {
			return moment(poll.minCaptured).startOf('day')
		} else
		if (moment(poll.minCaptured).isSame(week, 'isoWeek')) {
			return moment(poll.minCaptured).startOf('isoWeek')
		} else {
			return moment(poll.minCaptured).startOf('month')
		}
	})

	let firstItemInDateGroupIds=[] //id of first item in each date group (used to insert headers in table)

	Object.keys(groupedPolls).map(function(key) {
		const firstInGroupId=groupedPolls[key][0].id
		firstItemInDateGroupIds.push(firstInGroupId)
		return null	
	})
	return firstItemInDateGroupIds
}


function mapStateToProps(state,ownProps) {
	//const sectionPolls=sortBy(filter(state.historyQuestionPolls,{'section':ownProps.sectionId}).concat(filter(state.historySetPolls,{'section':ownProps.sectionId})), 'minCaptured').reverse()
	let polls=[]
	let archivedPolls=[]
	let sectionPolls=[]
	let archivedSectionPolls=[]
	let firstItemInDateGroupIds
	let archivedFirstItemInDateGroupIds
	let currentSection
	if(state.historyQuestionPolls && state.historySetPolls){
		if(ownProps.sectionId){
			const allSectionPolls=sortBy(filter(state.historyQuestionPolls,{'section':ownProps.sectionId}).concat(filter(state.historySetPolls,{'section':ownProps.sectionId})), 'minCaptured').reverse()
			sectionPolls=filter(allSectionPolls,{'archived':false})
			archivedSectionPolls=filter(allSectionPolls,{'archived':true})
			firstItemInDateGroupIds=calculateFirstItemInDateGroupIds(sectionPolls)
			archivedFirstItemInDateGroupIds=calculateFirstItemInDateGroupIds(archivedSectionPolls)
			currentSection=find(state.sections, {'id':ownProps.sectionId})
		}
		else{
			const allPolls=sortBy(state.historyQuestionPolls.concat(state.historySetPolls), 'minCaptured').reverse()
			polls=filter(allPolls,{'archived':false})
			archivedPolls=filter(allPolls,{'archived':true})
			firstItemInDateGroupIds=calculateFirstItemInDateGroupIds(polls)
			archivedFirstItemInDateGroupIds=calculateFirstItemInDateGroupIds(archivedPolls)
		}
	}
	
	
	return {
		sections:state.sections,
		orderedPolls:polls,
		archivedPolls:archivedPolls,
		orderedSectionPolls:sectionPolls,
		archivedSectionPolls:archivedSectionPolls,
		firstItemInDateGroupIds:firstItemInDateGroupIds,
		archivedFirstItemInDateGroupIds:archivedFirstItemInDateGroupIds,
		currentSection:currentSection || {}
	}
}

export default connect(
	mapStateToProps,
	{ 
		fetchQuestionPolls, 
		fetchSetPolls,
		hideNotification
	}	
)(HistoryContainer)

import store from '../store'
import find from 'lodash/find'
import {fetchASet} from '../actions/sets'

 
export function getSetForId(setId) {	
	if(setId){
		const state = store.getState()
		const set= find(state.sets, {'id':setId})
		
		if(set){
			return set
		}else {	store.dispatch(fetchASet(setId))}
	}else return null

}

  
//returns the active question node and its position

export function getActiveQuestion(){
	const state=window.view.state
	let questionPos
	let questionNode
	state.doc.nodesBetween(0,state.doc.content.size, (node, pos) => {
		if(node.attrs.activeslide && node.attrs.activeslide===true){
		 questionPos=pos
		 questionNode=node
		}
	})
	return {
		questionPos:questionPos,
		questionNode:questionNode
	}
}

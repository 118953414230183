import React from 'react'
import createMarkup from '../../utils/createMarkup'
import slideLayoutDefinitions from '../../staticData/slideLayoutDefinitions'
import isTooWide from './isTooWide'

const BODY_FONT_SIZES = slideLayoutDefinitions.availableBodyFontSizes

class QuestionBodyGhostComponentsArray extends React.Component {
  constructor() {
    super()
    this.measureBodyHeights = this.measureBodyHeights.bind(this)
  }

  componentDidMount() {
    this.measureBodyHeights()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.question.bodyHtml !== this.props.question.bodyHtml ||
      prevProps.template !== this.props.template ||
      prevProps.questionMediaDynamicHeight !== this.props.questionMediaDynamicHeight
    ) {
      this.measureBodyHeights()
    }
  }

  measureBodyHeights() {
    const bodyHeights = {}
    let bodyFontSize
    BODY_FONT_SIZES.forEach((fontSize) => {
      const el = this[`body_${fontSize}`]
      let height
      if (el) {
        height = el.clientHeight
      }
      bodyHeights[`fontSize_${fontSize}`] = {}
      bodyHeights[`fontSize_${fontSize}`].height = height
      // exclude FS if element is too wide (for long single line equations which don't wrap)
      bodyHeights[`fontSize_${fontSize}`].isValidFontSize = !isTooWide(el)
      bodyHeights[`fontSize_${fontSize}`].scrollWidth = el.scrollWidth
      bodyHeights[`fontSize_${fontSize}`].clientWidth = el.clientWidth

      if (height < 300 && (!bodyFontSize || bodyFontSize < fontSize)) {
        bodyFontSize = fontSize
      }
    })
    this.props.updateBodyHeights(bodyHeights)
  }

  render() {
    return (
      <div
        className={`fontSizeBodyArray ${this.props.template ? `fontSizeBodyArray--template--${this.props.template}` : ''}`}
      >
        {BODY_FONT_SIZES.map((fontSize) => (
          <div
            dangerouslySetInnerHTML={createMarkup(this.props.question.bodyHtml)}
            key={`body_${fontSize}`}
            ref={(c) => { this[`body_${fontSize}`] = c }}
            style={{ fontSize: `${fontSize}px` }}
            className="slide-body"
          />
        ))}
      </div>
    )
  }
}

export default QuestionBodyGhostComponentsArray

import React from 'react'
import ScorePill from '../uiKit/ScorePill'
import Icon from '../misc/Icons'

//Cell in student list column
class ScoreSheetStudent extends React.Component{
	render(){ 
		const {studentScoreDisplay,showReportPackModal,student,studentTotals, showStudentCardNumber, showStudentLastName} = this.props
		let score='-'
		if(studentTotals.totalResponses){
			score=100 * studentTotals.totalCorrect/studentTotals.totalResponses
		}
		return(
			<div className={'scoreSheetStudent notranslate ' + (score === '-' ? ' scoreSheetStudent--isAbsent' : '')} onClick={()=>{showReportPackModal(student)}}>
				{showStudentCardNumber && student.card > -1 &&
					<div className='scoreSheetStudent-col is--cardNumber'>
						{student.card}			
					</div>
				}
				{showStudentCardNumber && student.card === -1 &&
					<div className='scoreSheetStudent-col is--cardNumber'>
						–
					</div>
				}
				{!showStudentLastName &&
					<div className='scoreSheetStudent-col is--name'>
						{student.displayName}					
					</div>
				}
				{showStudentLastName &&
					<div className='scoreSheetStudent-col is--firstName'>
						{student.firstName}
					</div>
				}
				{showStudentLastName &&
					<div className='scoreSheetStudent-col is--lastName'>
						{student.lastName}	
					</div>
				}
				<div className='scoreSheetStudent-reportPackIcon'>
					<Icon name='portrait' />
				</div>
				{studentScoreDisplay ==='percentage' &&
					<div className="scoreSheetStudent-col is--score is--score--percentage">
						<ScorePill typeDot score={score} />						
					</div>
				}

				{studentScoreDisplay ==='number' &&
					<div className="scoreSheetStudent-col is--score is--score--count">
						{studentTotals.totalCorrect}<span className='scoreSheetStudent-col-studentScoreCountSlash'>/</span>{studentTotals.totalResponses}				
					</div>
				}
				<ScorePill score={score} colorOnly={true} />
			</div>
		)
	}
}


export default ScoreSheetStudent

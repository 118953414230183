const ChoiceNodeSpec = {
  attrs: {
    correct: {default: false},
    isSurvey: {default: true},
    choiceIndex: {default: 0},
    questionChoiceCount: {default: 4},
    fontSize:{default:42},
    template: {default: null},
    isTouched:{default:false}
  },
  selectable:false,
  draggable:false,
  isolating: true,
  content: "choiceBody (choiceMediaEmptyState | choiceImage | choiceVideo | choiceSound | choiceAnimatedGIF){1}",
  toDOM: () => ["choice"],
  parseDOM: [{
    tag: 'choice', getAttrs(dom) {
      return {
        correct: dom.getAttribute('data-correct') === 'true',
         isSurvey: dom.getAttribute('data-isSurvey') === 'true',
         choiceImage: dom.getAttribute('data-choiceImage'),
         fontSize: dom.getAttribute('data-fontSize')
      }
    },
  }],
}

export default ChoiceNodeSpec
import React from 'react'
import Icon from '../../misc/Icons'

const SET_TITLE_PLACEHOLDER='Untitled Set'
const SET_TITLE_MAX_CHARACTER_LENGTH=60

class EditorTopBarSetTitleInput extends React.Component{  
	
	onKeyDown(e){
		if(e.which === 13) {//blur input when press enter
			document.getElementById('updateSetTitleField').blur()
		}
	}

	render(){	
		const {inSharedRepo, isViewOnly, isPublicConsumer, isPublicAuthor, itemIsLockedByAnotherUser} = this.props
		const containingPackName = null // maybe add this later!
		return (
			<div className={'editor-topBar-setTitleInputContainer ' + ((isViewOnly || itemIsLockedByAnotherUser) ? 'editor-topBar-setTitleInputContainer--viewOnly' : '')}>
				<input 
					value={this.props.name===SET_TITLE_PLACEHOLDER?'':this.props.name}
					placeholder={SET_TITLE_PLACEHOLDER}
					onChange={(e)=>{this.props.updateSetTitle(e.target.value)}}
					tabIndex='-1' 
					className='editor-topBar-setTitleInput-input'
					onKeyDown={this.onKeyDown} // blur on enter						
					id='updateSetTitleField'
					autoComplete="off"
					autoCorrect="off"
					autoCapitalize="off"
					spellCheck="false"
					maxLength={SET_TITLE_MAX_CHARACTER_LENGTH}
					disabled={isViewOnly || itemIsLockedByAnotherUser} // view only/locked by another user
				/>

				<div className={'editor-topBar-setTitleInput-static ' + (containingPackName ? 'editor-topBar-setTitleInput-static--containingPackName' : 'editor-topBar-setTitleInput-static--notContainingPackName')}>
					<div className='editor-topBar-setTitleInput-static-title'>
						<div className='editor-topBar-setTitleInput-static-title-title'>
							<div className='editor-topBar-setTitleInput-static-title-title-title'>{this.props.name}</div>
							<div className='editor-topBar-setTitleInput-static-title-title-sharedIconContainer'>
								{inSharedRepo &&
									<Icon name='shared' />
								}									
								{isPublicConsumer &&
									<Icon name='globe-shared' />
								}
								{isPublicAuthor &&
									<Icon name='globe-shared-author' />
								}
							</div>
						</div>
					</div>
					
					{containingPackName && 
						<div className='editor-topBar-setTitleInput-static-containingPackName'>
							{containingPackName}
						</div>
					}
				</div>
			</div>
		) 
	}
}

export default EditorTopBarSetTitleInput

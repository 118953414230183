import React, { Component } from 'react'

class RepoReleaseModalVisualTopBarBtn extends Component {

	render() {


		return ( 
			<div style={{width: `${this.props.width}px`}} className='repoReleaseModal-visual-topBar-btn' />					
		)
	}
}

class RepoReleaseModalVisualTopBar extends Component {

	render() {


		return ( 
			<div className='repoReleaseModal-visual-topBar' >
				<div className='repoReleaseModal-visual-topBar-section'>
					<RepoReleaseModalVisualTopBarBtn width='90'/>
					<RepoReleaseModalVisualTopBarBtn width='286'/>
				</div>
				<div className='repoReleaseModal-visual-topBar-section'>
					<RepoReleaseModalVisualTopBarBtn width='60'/>
					<RepoReleaseModalVisualTopBarBtn width='65'/>
					<RepoReleaseModalVisualTopBarBtn width='45'/>					
				</div>
				
			</div>	
		)
	}
}


export default RepoReleaseModalVisualTopBar




import React from 'react'
import ReactDOM from 'react-dom';
import ChoiceImageReactComponent from './ChoiceImageReactComponent'
import {showEditImageModal} from '../../utils/showEditImageModal'
import {updateImageNodeWithSavedCloudinaryValues} from '../../../../prosemirror/utils/editorActions/addImage' 
import {setImageAttributes} from '../../../../prosemirror/utils/editorActions/setImageAttributes'


class ChoiceImageNodeView {

  constructor(node, view, getPos) {
    this.getNode=this.getNode.bind(this)
    this.deleteImage=this.deleteImage.bind(this)
    this.showEditImageModal=this.showEditImageModal.bind(this)
    this.setImageFillType=this.setImageFillType.bind(this)
    this.node = node
    this.getPos=getPos

    const {deliveryUrl,questionChoiceCount,placeholderImage,fileId,imageFill,slideWidth} = node.attrs
    
    this.dom = document.createElement("div")
    this.dom.className="slide-choice-mediaContainer slide-choice-mediaContainer--image"


      if(!fileId){
        setTimeout(function() { //Start the timer
          updateImageNodeWithSavedCloudinaryValues(node.attrs.nodeId)
        },500)
      }

    ReactDOM.render(
      <ChoiceImageReactComponent 
        key={placeholderImage||deliveryUrl}
        placeholderImage={placeholderImage}
        questionChoiceCount={questionChoiceCount}
        showEditImageModal={this.showEditImageModal}
        deliveryUrl={deliveryUrl} 
        deleteImage={this.deleteImage}
        setImageFillType={this.setImageFillType}
        imageFill={imageFill}
        slideWidth={slideWidth}
         />,         
        this.dom)


  } 

  setImageFillType(imageFill){
    const node=this.getNode()
    const nodePos=this.getPos(node)
    const attributes={...node.attrs,imageFill:imageFill}
    setImageAttributes(node,nodePos,attributes)
  }

  showEditImageModal(){
    const {fileId} = this.node.attrs
    showEditImageModal(fileId,this.node,this.getPos())
  }



  deleteImage(){
    const node=this.getNode()
    const position=this.getPos()
    window.view.dispatch(window.view.state.tr.delete(position,position+node.nodeSize))
  }


  getNode(){
    return this.node
  }


  update(node) {
  if (node.type !== this.node.type) return false
   const {
      fileId,
      deliveryUrl,
      placeholderImage,
      questionChoiceCount,
      imageFill,
      slideWidth
    } = node.attrs

    const oldDeliveryUrl=this.node.attrs.deliveryUrl
    const oldPlaceholderImage=this.node.attrs.placeholderImage
    const oldQuestionChoiceCount=this.node.attrs.questionChoiceCount
    const oldSlideWidth=this.node.attrs.slideWidth
      
   if( 
    oldDeliveryUrl !==deliveryUrl ||
    placeholderImage !== oldPlaceholderImage||
    oldQuestionChoiceCount !== questionChoiceCount ||
    oldSlideWidth !== slideWidth
    ){

    if(!fileId){
      updateImageNodeWithSavedCloudinaryValues(node.attrs.nodeId)
    }


    ReactDOM.render(
      <ChoiceImageReactComponent 
        imageFill={imageFill}
        key={placeholderImage||deliveryUrl}
        placeholderImage={placeholderImage}
        questionChoiceCount={questionChoiceCount}
        showEditImageModal={this.showEditImageModal}
        deliveryUrl={deliveryUrl} 
        deleteImage={this.deleteImage}
        setImageFillType={this.setImageFillType}
        slideWidth={slideWidth}
         />,
        
        this.dom)
      }
        


    this.node=node
    return true
  }





  destroy() {
    ReactDOM.unmountComponentAtNode(this.dom)
  }

  stopEvent(event) {
    //return this.innerView && this.innerView.dom.contains(event.target)
  }

}

export default ChoiceImageNodeView
import without from 'lodash/without'
import includes from 'lodash/includes'
import map from 'lodash/map'



export function parseName(fullName) {
	const fullNameConformed = conformWhitespace(fullName)
	if(includes(fullNameConformed, ',')) {
		return splitCommaSeparatedName(fullName, fullNameConformed)
	} else {
		return splitSpaceSeparatedName(fullName, fullNameConformed)
	}
}

function conformWhitespace(fullName) {
	return fullName.replace(/\s+/, ' ').trim()
}


function splitCommaSeparatedName(fullName, fullNameConformed) {
	let name = {firstName: '', lastName: ''}
	let commaParts = without(fullNameConformed.split(','), '', ' ')
	commaParts = trimAll(commaParts)
	if (commaParts.length === 1) {
		name = splitSpaceSeparatedName(fullName, commaParts[0])
	} else if (commaParts.length > 1) {
		name = arrangeMultipleCommaParts(fullName, commaParts)
	}
	return ensureFirstNameExists(fullNameConformed, name)
}

function arrangeMultipleCommaParts(fullName, commaParts) {
	var name = {firstName: '', lastName: ''}
	name.firstName = commaParts.splice(1).join(', ')
	name.lastName = commaParts[0]
	var idRegex = /[0-9]{3,}/
	if(idRegex.exec(name.firstName)) {
		var firstNameParts = name.firstName.split(' ')
		var lastNameParts = name.lastName.split(' ')
		if(firstNameParts.length > 1) {
			for(var i = 1 ; i < firstNameParts.length; i++){
				if(idRegex.exec(firstNameParts[i])) {
				// Move the part to lastName
					lastNameParts.push(firstNameParts.splice(i, 1))
				}
			}
		}
		name.firstName = firstNameParts.join(' ')
		name.lastName = lastNameParts.join(' ')
	}
	return name
}

function splitSpaceSeparatedName(fullName, fullNameConformed) {
	var name = {firstName: '', lastName: ''}
	var spaceParts = fullNameConformed.split(/\s/)
	if (spaceParts.length === 1) {
		name.firstName = spaceParts[0]
	} else if (spaceParts.length > 1) {
		if (isNumber(spaceParts[0])) {
			spaceParts = joinFirstTwo(spaceParts)
		}
		while(spaceParts.length > 2 && isInitial(spaceParts[1])){
			spaceParts = joinFirstTwo(spaceParts)
		}
		name.firstName = spaceParts[0]
		if (spaceParts.length > 1) {
			name.lastName = spaceParts.splice(1).join(' ')
		}
	}
	return ensureFirstNameExists(fullNameConformed, name)
}

function ensureFirstNameExists(fullNameConformed, name) {
	if (!name.firstName) {
		if (name.lastName) {
			name.firstName = name.lastName
			name.lastName = ''
		} else if (fullNameConformed) {
			name.firstName = fullNameConformed
		} else {
			name = 'empty'
		}
	}
	return name
}

function joinFirstTwo(parts) {
	return [parts.splice(0, 2).join(' ')].concat(parts)
}

function trimAll(parts) {
	return map(parts, function (part) {return part.trim()})
}


function isNumber(word) {
	return !isNaN(parseFloat(word)) && isFinite(word)
}
function removeIgnoredChars(word) {
//ignore periods
	return word.replace('.','')
}


function isInitial(word) {
	word = removeIgnoredChars(word)
	return (word.length === 1)
}
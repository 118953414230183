export function updateVideoClipTimes(videoNode,nodePos,startTime,endTime) {
	var tr = window.view.state.tr
	let isClipped=false
	if(startTime!==0 || endTime-startTime!==videoNode.attrs.fullDuration){
		isClipped=true
	}
	tr.setNodeMarkup(nodePos, null, {...videoNode.attrs,start:startTime,end:endTime,isClipped:isClipped})
	window.view.dispatch(tr)
	const videoAttrs=videoNode.attrs
	if(window.analytics){
		window.analytics.track('Edit Video', {
			youtubeId:videoAttrs.youtubeId,
			title:videoAttrs.title,
			channelTitle:videoAttrs.channelTitle,
			thumbnail:videoAttrs.thumbnail.src,
			fullDuration:videoAttrs.fullDuration,
			start:startTime,
			end:endTime,
			isClipped:isClipped,
			clipDuration:endTime-startTime
		})
	}
}


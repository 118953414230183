// “Grade 5”
// “Grades 4 - 7”
// “Higher Education”
// “Staff”
// “Other”
// “PreK, Kindergarten, Grade 1-2”
// “Kindergarten, Grades 1-3”
// “Grades 10-12, Higher Education”




export function formatRepoGradesToString(gradeArray) {
	let gradeString=''
	if(!gradeArray){
		gradeString=''
	}
	else if(gradeArray.length===0){
		gradeString=''
	}
	else{
		let isStaff=false
		let isOther=false
		let isHigher=false
		let isKindergarten=false
		let isPreK=false
		let numberGrades=[]
		gradeArray.map((grade)=>{
			switch (grade) {
			
			case 'other':
				isOther=true 
				break

			case 'staff':
				isStaff=true 
				break

			case 'higher':
				isHigher=true 
				break

			case 'pre-k':
				isPreK=true 
				break

			case 'k':
				isKindergarten=true 
				break

			default:
				const gradeNumber=grade.substring(0, grade.length-2)
				numberGrades.push(gradeNumber)

			}
			return null
		})

		if(isStaff){
			gradeString='Staff'
		}else if(isOther){
			gradeString='Grade-Level: Other'
		}else if(isPreK){
			gradeString+='PreK'
		}
		if(isKindergarten){
			if(gradeString!==''){
				gradeString+=', '
			}
			gradeString+='Kindergarten'

		}

		if(numberGrades.length===1){
			const gradeNumberString = `Grade ${numberGrades[0]}`
			if(gradeString !== ''){
				gradeString+=', '
			}
			gradeString+=gradeNumberString
		}else if(numberGrades.length>1){
			const gradeNumberString=`Grades ${numberGrades[0]}-${numberGrades[numberGrades.length-1]}`
			if(gradeString !== ''){
				gradeString+=', '
			}
			gradeString+=gradeNumberString
		}
		if(isHigher){
			if(gradeString !== ''){
				gradeString+=', '
			}
			gradeString+='Higher Education'
		}

	}

	return gradeString

}



import React, { Component } from 'react'
import ComplexIcon from '../misc/ComplexIcons'
import RepoReleaseModalVisualTopBar from './RepoReleaseModalVisualTopBar'
import RepoReleaseModalVisualSideNav from './RepoReleaseModalVisualSideNav'
import RepoReleaseModalVisualPageHeader from './RepoReleaseModalVisualPageHeader'
import RepoReleaseModalVisualPageTable from './RepoReleaseModalVisualPageTable'
import RepoReleaseModalVisualRightPanel from './RepoReleaseModalVisualRightPanel'
import { Transition, animated, config } from  'react-spring/renderprops'


const images=[
	 'https://s3.amazonaws.com/assets.plickers.com/react-assets/images/packrelease/PackHeaderImageRainforest.png',
	 'https://s3.amazonaws.com/assets.plickers.com/react-assets/images/packrelease/PackHeaderImageGraph.png',
	 'https://s3.amazonaws.com/assets.plickers.com/react-assets/images/packrelease/PackHeaderImageGatsby.png',	
	 'https://s3.amazonaws.com/assets.plickers.com/react-assets/images/packrelease/PackHeaderImagePlagnet.png',	
	 'https://s3.amazonaws.com/assets.plickers.com/react-assets/images/packrelease/PackHeaderImageCoco.png',	 
]

 

const pageHeaders = [
	style => (
		<animated.div style={{ ...style }}>
			<RepoReleaseModalVisualPageHeader
				title='Rainforests'
				imageURL='Rainforest'
				description='Aligned to Common Core standards. Geography and climate questions, reading sources quiz, graphing math exercises. Also introduction to trophic levels. 6 - 10 lessons.'
			/>
		</animated.div>
	),
	style => (
		<animated.div style={{ ...style }}>
			<RepoReleaseModalVisualPageHeader
				title='Symbolic Differentiation with Graphs and Tables'
				imageURL='Graph'
				description='Quizzes for warm-up, exit ticket, or homework check. Covers LO2.1A, LO2.1B, and LO2.1C.'
			/>
		</animated.div>
	),
	style => (
		<animated.div style={{ ...style }}>
			<RepoReleaseModalVisualPageHeader 
				title='The Great Gatsby'
				imageURL='Gatsby'
				description='Three-weeks of lessons content, including close-reading exercises, characters, Jazz age, and night-reading quizzes. Includes 50 question end-of-unit exam. For Grades 9 - 10.'
			/>
		</animated.div>
	),	
	style => (
		<animated.div style={{ ...style }}>
			<RepoReleaseModalVisualPageHeader
				title='Self-Assessment Phys Ed Tools'
				imageURL='Plagnet'
				description="Great for Grades 4 - 6. Covers topics such as Cooperation, Responsibility and Effort in physical education. Works great with plagnets! Inspired by Jason Leach's courses."
			/>
		</animated.div>
	),	
	style => (
		<animated.div style={{ ...style }}>
			<RepoReleaseModalVisualPageHeader
				title='Coco - Movie and Culture Unit'
				imageURL='Coco'
				description='Great for all year groups at the end of term. 3 - 4 lessons. Explores Mexican culture and el Dia de los Muertos.'
			/>
		</animated.div>
	),
]

const pageTables = [
	style => (
		<animated.div style={{ ...style }}>
			<RepoReleaseModalVisualPageTable/>    		
		</animated.div>
	),
	style => (
		<animated.div style={{ ...style }}>
			<RepoReleaseModalVisualPageTable/>
		</animated.div>
	),
	style => (
		<animated.div style={{ ...style }}>
			<RepoReleaseModalVisualPageTable/>
		</animated.div>
	),
	style => (
		<animated.div style={{ ...style }}>
			<RepoReleaseModalVisualPageTable/>
		</animated.div>
	),
	style => (
		<animated.div style={{ ...style }}>
			<RepoReleaseModalVisualPageTable/>
		</animated.div>
	),
]



class RepoReleaseModalVisual extends Component {

	constructor() {
		super()
		this.changePage = this.changePage.bind(this)
		this.changePageOnClick = this.changePageOnClick.bind(this)
	

		this.state = {
			index: 0,

		}
	} 

	componentWillUnmount(){
		clearInterval(this.interval)
	}

	componentDidMount() {
		this.interval = setInterval(() => this.changePage(), 6000)
		images.forEach((url) => {
			const img = new Image()
			img.src = url
		})
	}

	changePageOnClick(){
		clearInterval(this.interval)
		this.changePage()
		this.interval = setInterval(() => this.changePage(), 6000)
	}



	changePage(){
		this.setState(state => ({
			index: state.index === 4 ? 0 : state.index + 1,
		}))
	}

	render() {


		return ( 
			
			<React.Fragment>
				<div className='repoReleaseModal-visual--device'>
					<div className='repoReleaseModal-visual--screen'>
						
						<RepoReleaseModalVisualTopBar/>

					
							<RepoReleaseModalVisualSideNav/>

							<div className='repoReleaseModal-visual-page' onClick={this.changePageOnClick}>
								<Transition
									native
									reset
									unique
									config={config.molasses}
									items={this.state.index}
									from={{ opacity: 0, transform: 'translate3d(50px,0,0)' }}
									enter={{ opacity: 1, transform: 'translate3d(0px,0,0)' }}
									leave={{ opacity: 0, transform: 'translate3d(-99999px,0,0)' }}>
									{index => pageHeaders[index]}
								</Transition>

								<Transition
									native
									reset
									unique			
									config={config.molasses}
									items={this.state.index}
									from={{ opacity: 0, transform: 'translate3d(0px,25px,0)' }}
									enter={{ opacity: 1, transform: 'translate3d(0px,0px,0)' }}
									leave={{ opacity: 0, transform: 'translate3d(-99999px,0px,0)' }}>
									{index => pageTables[index]}
								</Transition>								
							</div>


							<RepoReleaseModalVisualRightPanel
								product={this.props.product}
							/>

							
						
					</div>
				</div>
				
				<div className='repoRelease-visual-cloudBGContainer'>
					<ComplexIcon name='repoReleaseModalCloud' />					
				</div>

			</React.Fragment>
		)
	}
}


export default RepoReleaseModalVisual




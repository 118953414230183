import React from 'react'
import isEqual from 'lodash/isEqual'
import createMarkup from '../../utils/createMarkup'
import slideLayoutDefinitions from '../../staticData/slideLayoutDefinitions'
import isTooWide from './isTooWide'

const CHOICE_FONT_SIZES = slideLayoutDefinitions.availableChoiceFontSizes

class ChoiceGhostComponentsArray extends React.Component {
  constructor() {
    super()
    this.measureChoiceHeights = this.measureChoiceHeights.bind(this)
  }

  componentDidMount() {
    this.measureChoiceHeights()
  }

  componentDidUpdate(prevProps) {
    if (
      isEqual(prevProps.question.choices, this.props.question.choices) &&
      prevProps.template === this.props.template &&
      prevProps.questionMediaDynamicHeight === this.props.questionMediaDynamicHeight
    ) {
      // do nothing
    } else {
      this.measureChoiceHeights()
    }
  }

  measureChoiceHeights() {
    const choiceHeights = {}
    const { question, template } = this.props
    let choiceAHeight = 0
    let choiceBHeight = 0
    let choiceCHeight = 0
    let choiceDHeight = 0
    CHOICE_FONT_SIZES.forEach((fontSize) => {
      const choiceHeightsForFontSize = {}
      let totalHeight = 0
      // exclude FS if element is too wide (for long single line equations which don't wrap)
      let isValidFontSize = true
      question.choices.forEach((choice, i) => {
        const el = this[`choice_${i}_${fontSize}px`]
        const textEl = this[`choice_${i}_text_${fontSize}px`]
        if (isTooWide(textEl)) { isValidFontSize = false }
        let height
        if (el) {
          height = el.clientHeight
        }
        choiceHeightsForFontSize[`choice_${i}`] = height
        totalHeight += height
        if (i === 0) {
          choiceAHeight = height
        } else if (i === 1) {
          choiceBHeight = height
        } else if (i === 2) {
          choiceCHeight = height
        } else if (i === 3) {
          choiceDHeight = height
        }
      })
      if (template === 'bodyCenter' || template === 'bodyCenterMediaCenter') {
        totalHeight = 0
        const row1Height = Math.max(choiceAHeight, choiceBHeight)
        const row2Height = Math.max(choiceCHeight, choiceDHeight)
        totalHeight = row1Height + row2Height
      }
      let marginBuffer = 0
      if (template === 'bodyCenter' || template === 'bodyCenterMediaCenter') {
        if (question.choices.length > 2) {
          marginBuffer = 20
        }
      } else {
        marginBuffer = (question.choices.length - 1) * 20
      }
      totalHeight += marginBuffer
      choiceHeightsForFontSize.total = totalHeight
      choiceHeightsForFontSize.isValidFontSize = isValidFontSize
      choiceHeights[`font_${fontSize}`] = choiceHeightsForFontSize
    })
    this.props.updateChoiceHeights(choiceHeights)
  }

  render() {
    const { question } = this.props
    return (
      <div className={`fontSizeChoicesArray ${this.props.template ? `fontSizeChoicesArray--template--${this.props.template}` : ''}`}>
        {CHOICE_FONT_SIZES.map((fontSize) => (
          <div key={fontSize} className="slide-choices">
            {question.choices.map((choice, i) => (
              <div
                key={`choice_${i}_${fontSize}px`}
                ref={(c) => { this[`choice_${i}_${fontSize}px`] = c }}
                className="fontSizeChoicesArray-choice slide-choice"
                style={{ fontSize: `${fontSize}px` }}
              >
                <div className="fontSizeChoicesArray-choice-letterblock slide-choice-letterblock">
                  A
                </div>
                <div className="slide-choice-text" ref={(c) => { this[`choice_${i}_text_${fontSize}px`] = c }}>
                  <div className="slide-choice-content" dangerouslySetInnerHTML={createMarkup(choice.bodyHtml)} />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    )
  }
}

export default ChoiceGhostComponentsArray

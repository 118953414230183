import React from 'react'
import SetHandoutHeader from './SetHandoutHeader'
import Question from '../../../printKit/Question'
import PageHead from '../printElements/PageHead'
import {paginateQuestionsForPreview} from '../../../../utils/editors/paginateQuestionsForPreview'
import {isSurvey} from '../../../../utils/isSurvey'


 

class SetHandout extends React.Component{

	render(){ 
		const {
			set,
			printSetSettings,
			ghostHeights,
			browser,
			paperSize,
			instructionalText,
			
			//includeInstructionalText
		}=this.props


		const PrintCSSShowPortalOnly = () => (
			<style type="text/css">
				{'@media print{	#root, .App {display: none !important}}'}
			</style>
		)


		const PrintCSSLetterPageSize = () => (
			<style type="text/css">
				{'@media print{@page {size: 8.5in 11in; size: portrait}}'}
			</style>
		)

		const PrintCSSA4PageSize = () => (
			<style type="text/css">
				{'@media print{@page {size: 210mm 297mm}}'}
			</style>
		)


		const {
			columnCount,
		
			showTeachersName,
			showDate,
			includeStudentWorkingSpace,
			showImages,
			highlightCorrect,
			labelSurveys
		}=printSetSettings



		

		 const paginatedData=paginateQuestionsForPreview(set,ghostHeights,columnCount)
		 let questionCounter=0
	
	return(
		<div className={'printPack printPack--setHandout printPack--rendered ' + (browser === 'safari' ? ' printPack--safari ': ' ') + (paperSize === 'letter' ? ' printPack--letter ': ' printPack--a4 ') + (columnCount === 2 ? 'printPack--twoColumn ' : '') + (columnCount === 1 ? 'printPack--singleColumn ' : '')}>
		
				{columnCount===1 &&				
					<React.Fragment>
						{Object.keys(paginatedData).map((key)=>{
							const pageData=paginatedData[key]['column-1']
							return(
								<div key={key} className={'printPack-page' + (key==='0' ? ' printPack-page--firstPage' : '') } >
									{paperSize === 'a4' &&
										<PrintCSSA4PageSize />
									}
									{paperSize === 'letter' &&
										<PrintCSSLetterPageSize />
									}
						
									<PrintCSSShowPortalOnly />
						

									{key==='0' &&
										<SetHandoutHeader setName={set.name} instructionalText={instructionalText} showTeachersName={showTeachersName} showDate={showDate}/> 
									}					

									{key!=='0' &&
										<PageHead currentPage={parseInt(key, 10) + 1} totalPages={Object.keys(paginatedData).length} set={set}  /> 
									}
									<div className='printPack-page-pageColumn'>
										{pageData.map((question,index) => {	
											questionCounter+=1					
											return( 
												<Question
													key={question.questionId}
													question={question}
													showImages={showImages}
													includeStudentWorkingSpace={includeStudentWorkingSpace}
													highlightCorrect={highlightCorrect}
													labelSurveys={labelSurveys}
													isSurvey={isSurvey(question)}
													showQuestionNumbers={true}
													questionCounter={questionCounter}
												/>)					
										})}
									</div>
								</div>
							)
						})}
					</React.Fragment>
				}

			{columnCount===2 &&			
				<React.Fragment>
					{Object.keys(paginatedData).map((key)=>{
						const pageData1=paginatedData[key]['column-1']
						const pageData2=paginatedData[key]['column-2']
						return(
							<div key={key} className={'printPack-page'	 + (key==='0' ? ' printPack-page--firstPage' : '') } >													
								{paperSize === 'a4' &&
									<PrintCSSA4PageSize />
								}

								{paperSize === 'letter' &&
									<PrintCSSLetterPageSize />
								}				
			
								<PrintCSSShowPortalOnly />

								{key==='0' &&
									<SetHandoutHeader setName={set.name} instructionalText={instructionalText} showTeachersName={showTeachersName} showDate={showDate}/> 
								}

								{key!=='0' &&
									<PageHead currentPage={parseInt(key, 10) + 1} totalPages={Object.keys(paginatedData).length} set={set} /> 
								}

								<div className='printPack-page-pageColumn printPack-page-pageColumn--left'>

									{pageData1.map((question,index) => {	
										questionCounter+=1						
										return( 
											<Question
												key={question.questionId}
												question={question}
												showImages={showImages}
												includeStudentWorkingSpace={includeStudentWorkingSpace}
												highlightCorrect={highlightCorrect}
												isSurvey={isSurvey(question)}
												showQuestionNumbers={true}
												questionCounter={questionCounter}
												labelSurveys={labelSurveys}
											/>)		
									})}
								</div>

								<div className='printPack-page-pageColumn printPack-page-pageColumn--right'>
									{pageData2 && pageData2.map((question,index) => {	
										questionCounter+=1						
										return( 
											<Question
												key={question.questionId}
												question={question}
												showImages={showImages}
												includeStudentWorkingSpace={includeStudentWorkingSpace}
												highlightCorrect={highlightCorrect}
												isSurvey={isSurvey(question)}
												showQuestionNumbers={true}
												questionCounter={questionCounter}
												labelSurveys={labelSurveys}
											/>)		
									})}
								</div>
							</div>
						)})}
				</React.Fragment>
			}
			</div>
	)}
}


export default SetHandout


import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'  
import update from 'immutability-helper' 
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import each from 'lodash/each'
import filter from 'lodash/filter'
import debounce from 'lodash/debounce'
import { hideMenu } from 'react-contextmenu/modules/actions'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import {fetchFolders,updateFolder} from '../actions/folders'
import {showLiveViewModal} from '../utils/liveView/showLiveViewModal'
import {fetchQuestions,updateQuestionMeta} from '../actions/questions'
import {fetchSets,updateSetContent,updateSetMeta} from '../actions/sets'
import {fetchQuestionPolls, fetchSetPolls} from '../actions/polls'
import {updatePreviewItem} from '../actions/previewItem'
import {showNotification,hideNotification} from '../actions/notifications'
import {showNewRepoModal,showRepoSelectionModal, showSectionSelectionModal,showEditFolderModal,showRenameSetModal,showMoveToFolderModal,showConfirmModal,hideModal } from '../actions/modals'
import LibraryPage from '../components/library/LibraryPage'
import {createPoll} from '../utils/createPoll' 
import {newSet,newQuestion} from '../utils/newItem'
import {playItem} from '../utils/playItem'
import {combineIntoSet} from '../utils/combineIntoSet'
import {openItemInEditor} from '../utils/openItemInEditor'
import {createCopyOfItem} from '../utils/createCopyOfItem'
import {getQuestionBodyLabel} from '../utils/getQuestionBodyLabel'
import {copyQuestion} from '../utils/copyPaste'
import {moveToFolder} from '../utils/moveToFolder'
import {getLibrarySettings} from '../utils/getLibrarySettings'
import {sortLibraryData} from '../utils/sortLibraryData'
import {saveSettingToLocalStorage} from '../utils/saveSettingToLocalStorage'
import Button from '../components/uiKit/Button'
import FilterBar from '../components/uiKit/FilterBar'
import TopPageHeader from '../components/pageHeaders/TopPageHeader'
import StickyPageHeader from '../components/pageHeaders/StickyPageHeader'
import PageIcon from '../components/misc/PageIcons'
import Icon from '../components/misc/Icons'
import Dropdown from '../components/uiKit/Dropdown'
import DropdownItem from '../components/uiKit/DropdownItem'
import DropdownDivider from '../components/uiKit/DropdownDivider'
import moment from 'moment'
import withSelectableCells from '../hoc/withSelectableCells'
import PreviewPanelContainer from './previewPanel/PreviewPanelContainer'
import {moveToRepo} from '../utils/moveToRepo'
import ApplyOrderModalContainer from './ApplyOrderModalContainer'

const CONTEXT_MENU_CLOSE_DELAY = 300


class FolderNameInput extends Component {
	constructor(props){
		super(props)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.state=({
 			folderName:props.folder.name
 		})
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
 		if(nextProps.folder){
 			if(nextProps.folder.id !==this.props.folder.id){
 				this.setState({folderName:nextProps.folder.name})
 			}
 		}else{
 			this.setState({folderName:null})
 		}
 	}

 	handleKeyDown(e){
 		if (e.keyCode === 13) { 
 			e.preventDefault()
			this.props.editFolderName(this.props.folder,this.state.folderName)
			document.getElementById('pageHeaderFolderNameInput').blur()
		}
 	}

	render(){	
		const {readOnlyFolder,folder}=this.props
		const {folderName}=this.state

		return(		
			<input 
				disabled={readOnlyFolder}
				maxLength={100}
				className='pageHeader-folderTitle'
				id='pageHeaderFolderNameInput'
				placeholder='Enter Folder Name'
				value={this.state.folderName || ''}
				onChange={(e)=>{this.setState({folderName:e.target.value})}}
				onBlur={()=>{this.props.editFolderName(folder,folderName)}} 
				onKeyDown={this.handleKeyDown}
			/>
		)
	}
}

class LibraryContainer extends Component {

	constructor(props){
		super(props)
		this.navigateToFolder=this.navigateToFolder.bind(this)
		this.changeLibrarySortOrder=this.changeLibrarySortOrder.bind(this)	
		this.handleNewFolder=this.handleNewFolder.bind(this)
		this.playItem=this.playItem.bind(this)
		this.confirmBatchArchive=this.confirmBatchArchive.bind(this)
		this.batchArchive=this.batchArchive.bind(this)
		this.handleFilterChange=this.handleFilterChange.bind(this)
		this.clearFilter=this.clearFilter.bind(this)    
		this.handleNewQuestion=this.handleNewQuestion.bind(this)
		this.showRenameFolderModal=this.showRenameFolderModal.bind(this)
		this.showMoveToFolderModal=this.showMoveToFolderModal.bind(this)
		this.handleMoveToFolder=this.handleMoveToFolder.bind(this)
		this.queueItem=this.queueItem.bind(this)
		this.handleNewSet=this.handleNewSet.bind(this)
		this.archiveItem=this.archiveItem.bind(this)
		this.archiveFolder=this.archiveFolder.bind(this)
		this.combineIntoSet=this.combineIntoSet.bind(this)
		this.keyPressNavigation=this.keyPressNavigation.bind(this)
		this.resetState=this.resetState.bind(this)
		this.handleSelectRow=this.handleSelectRow.bind(this)
		this.delayedMenuClose=this.delayedMenuClose.bind(this)
		this.onShowContextMenu=this.onShowContextMenu.bind(this)
		this.onHideContextMenu=this.onHideContextMenu.bind(this)
		this.editSetName=this.editSetName.bind(this)
		this.editFolderName=this.editFolderName.bind(this)
		this.handleContextMenuClick=this.handleContextMenuClick.bind(this)
		this.handleFolderContextMenuClick=this.handleFolderContextMenuClick.bind(this)
		this.handleMultiSelectContextMenuClick=this.handleMultiSelectContextMenuClick.bind(this)
		this.handleDocumentKeyDown=this.handleDocumentKeyDown.bind(this)
		this.centerPanelOnClick=this.centerPanelOnClick.bind(this)
		this.navigateToItemDetail=this.navigateToItemDetail.bind(this)
		this.batchAddToQueue=this.batchAddToQueue.bind(this)
		this.batchMoveToFolder=this.batchMoveToFolder.bind(this)
		this.renderPathItem=this.renderPathItem.bind(this)
		this.createCopy=this.createCopy.bind(this)
		this.copyQuestionToClipboard=this.copyQuestionToClipboard.bind(this)
		this.onShowMultiselectContextMenu=this.onShowMultiselectContextMenu.bind(this)
		this.onHideMultiselectContextMenu=this.onHideMultiselectContextMenu.bind(this)
		
		this.delayedMenuClose = debounce(this.delayedMenuClose,CONTEXT_MENU_CLOSE_DELAY)
		this.appElement=document.getElementById('App')

		const librarySettings=getLibrarySettings()
		let showApplyOrderModal=false

		if(this.props.history.location.pathname.indexOf('/claim')>-1){	
			showApplyOrderModal=true
		}
		
		this.state={
			libraryData:props.libraryData,
			folders:props.folders,
			sortedBy:librarySettings.sortType,
			sortOrder:librarySettings.sortOrder,
			parent:null,//parent folder
			folderName:'',
			filterTerm:'',
			contextMenuActiveItem:null,
			contextMenuObject:null,
			showApplyOrderModal:showApplyOrderModal		
		}
	}
 

	UNSAFE_componentWillReceiveProps(nextProps) {
		if((this.props.libraryData !==nextProps.libraryData) || (this.props.folders !== nextProps.folders)){
			this.setState({libraryData:nextProps.libraryData,folders:nextProps.folders})
		}
		if(nextProps.parentFolder !== this.props.parentFolder){
			this.setState({filterTerm:''})
		} 
		if(nextProps.parentFolder){			
			this.setState({parent:nextProps.parentFolder.id,folderName:nextProps.parentFolder.name})			
		}else{
			this.setState({parent:null,folderName:''})
		}
		if(this.state.showApplyOrderModal && !(nextProps.history.location.pathname.indexOf('/claim')>-1 )){
			this.setState({showApplyOrderModal:false})
		}
	}

	UNSAFE_componentWillMount() {
		this.props.fetchFolders()
		this.props.fetchQuestions()
		this.props.fetchSets()
		this.props.fetchQuestionPolls()
		this.props.fetchSetPolls()
		document.addEventListener('keydown', this.handleDocumentKeyDown)
	}

	componentDidMount(){
		if(window.analytics){
			let testGroup=null
			const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
			if(setLimitTestGroup){
				testGroup=setLimitTestGroup
			}
			window.analytics.page('Library',{'test_group_set_limit':testGroup})
		}
	}

	componentWillUnmount() {
		this.props.hideNotification()
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' 
		document.removeEventListener('keydown', this.handleDocumentKeyDown)
	}

	handleDocumentKeyDown(e){
		if(!this.props.activePanelItem.id && isEmpty(this.props.multiSelectItems)){			
			if(e.keyCode ===40){//if press down arrow when no items selected then set first item to the active item 
				const topNavSearchBar=document.getElementById('topNavSearchBar')
				if((topNavSearchBar===document.activeElement)===false){ //check the topNav search bar is not focused 
					e.preventDefault()
					const libraryTable=document.getElementById('focusableLibraryTable')
					if(libraryTable){
						libraryTable.focus()
					}
					const {libraryData,folders}=this.state
					const allData=folders.concat(libraryData)
					const newActiveItem=allData[0]
					this.props.changeActiveItem(newActiveItem)
				}
			}
		}
	}

	onShowMultiselectContextMenu(e){
		this.setState({contextMenuActiveItem:null,contextMenuObject:null})
		document.body.style.overflowY = 'hidden'
	}

	onHideMultiselectContextMenu(e){
		this.setState({contextMenuActiveItem:null,contextMenuObject:null})
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' 

	}

	onShowContextMenu(e){
		this.setState({contextMenuActiveItem:e.detail.data.itemId,contextMenuObject:e.detail.data.item})
		document.body.style.overflowY = 'hidden'
	}

	onHideContextMenu(e){
		this.setState({contextMenuActiveItem:null,contextMenuObject:null})
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' 

	}

	delayedMenuClose(){
		hideMenu()
	}

	editSetName(set,name){
		const nowDate=new Date()
		if(name !== set.name && name!==''){ //only save if name has been edited and is non-empty
			const requestData = {...set,
				'name': name,
				'clientModified': nowDate,
			}
			this.props.updateSetContent(requestData, set.id).then((response) => {
				const setIndex = findIndex(this.state.libraryData, function (item) {
					return item.id === response.id
				})

				const updatedLibraryData= update(this.state.libraryData, 
					{[setIndex]: {$set: response}})
				this.setState({libraryData:updatedLibraryData})

			})
		}
	}

	editFolderName(folder,name){
		const nowDate=new Date()
		if(name !== folder.name && name!==''){//only save if name has been edited and is non-empty
			const requestData = {...folder,
				'name': name,
				'clientModified': nowDate,
			}
			this.props.updateFolder(requestData, folder.id)
		}
	}

	handleFilterChange(e){
		this.setState({filterTerm:e.target.value})
	}

	clearFilter(){
		this.setState({filterTerm:''})    
	}

	navigateToFolder(folderId){
		if(folderId){
			this.props.history.push(`/library/${folderId}`)
		}else{
			this.props.history.push('/library')
		}
		this.resetState()
	}

	queueItem(item,section,isBatchRequest,plannedTime){
		this.props.createPoll(item,section.id,isBatchRequest,plannedTime)
	}

	resetState(){
		this.props.resetSelectedCells()
		this.setState({
			filterTerm:'',
		})
	}

	handleNewFolder(){
		this.props.showEditFolderModal(null,this.state.parent,this.props.changeActiveItem)
	}

	showRenameFolderModal(folder){
		this.props.showEditFolderModal(folder)
	}
 
	showMoveToFolderModal(item){
		this.props.showMoveToFolderModal(item,this.resetState)
	}

	handleMoveToFolder(item,folder,numOfItems){
		let notificationMessage
		moveToFolder(item,folder.id).then(() => {
			if(numOfItems){
				notificationMessage=`${numOfItems} items to ${folder.name}`
			}else{
				notificationMessage=`${item.name || item.body} to ${folder.name}`
			}
			this.props.showNotification(notificationMessage,'Moved','default')
		})
	}

	handleNewQuestion(parentFolder){
		const parent=parentFolder || this.props.match.params.folderId || null
		newQuestion(parent)
	}

	handleNewSet(parentFolder){
		const parent=parentFolder||this.props.match.params.folderId || null
		newSet(parent)
	}

	
	playItem(item,section){
		if(section){
			this.props.playItem(item,section.id).then(() => {
				showLiveViewModal()
			})
		}else{
			this.props.showSectionSelectionModal(item)
		}
	}
	
	archiveItem(item,isMultiSelect) {
		const nowDate = new Date()
		let notificationMessage
		const requestData = {
			...item,
			clientModified: nowDate,
			archived: true
		}
		if(item.ancestors){ //Folder
			return this.props.updateFolder(requestData, item.id).then((response) => {
				if(!isMultiSelect){
					notificationMessage=`${item.name}`
					this.props.showNotification(notificationMessage,'Sent to Trash ','destroy')
				}
				return response
			})
		}else if (item.questions) {//Set
			return this.props.updateSetMeta(requestData, item.id).then((response) => {
				if(!isMultiSelect){
					notificationMessage=`${item.name}`
					this.props.showNotification(notificationMessage,'Sent to Trash','destroy')
				}
				return response
			})
		} else {//Independent questions
			return this.props.updateQuestionMeta(requestData, item.id).then((response) => {
				if(!isMultiSelect){
					notificationMessage=`${item.body}`  
					this.props.showNotification(notificationMessage,'Sent to Trash','destroy')      
				}
				return response
			})
		}
	}

	archiveFolder(folder){
		if(folder){
			const nowDate = new Date()
			let notificationMessage
			const requestData = {
				...folder,
				clientModified: nowDate,
				archived: true
			}		
			this.props.updateFolder(requestData, folder.id).then(() => {
				notificationMessage=`${folder.name}`   
				this.props.showNotification(notificationMessage,'Sent to Trash','destroy')
			})
		}
	}

	combineIntoSet(){
		const {multiSelectItems}=this.props
		const {parent}=this.state
		const repo = null
		this.props.combineIntoSet(multiSelectItems,parent,repo).then((response) => {
			const length = Object.keys(multiSelectItems).length
			const notificationMessage=`New Set from ${length} items`   
			this.props.showNotification(notificationMessage,'Created','create')
			this.props.changeActiveItem(response)
		})
	}

	createCopy(item){
		this.props.createCopyOfItem(item).then((response) => {
			const notificationMessage=`${item.name ||getQuestionBodyLabel(item) }`   
			this.props.showNotification(notificationMessage,'Created a copy of','create')
			this.props.changeActiveItem(response)
		})
	}

	confirmBatchArchive(items){
		setTimeout(function() { //To ensure opens after dropdown closed (to prevent screen scrolling while modal open) 
			this.props.showConfirmModal(items,this.batchArchive,'batchArchive')	
		}.bind(this),	1)
			
	}

	batchArchive() {
		const {multiSelectItems}=this.props
		let promises = []
		each(Object.keys(multiSelectItems), function(key) {
			const item=multiSelectItems[key]
			promises.push(this.archiveItem(item,true))			
		
		}.bind(this))

		Promise.all(promises).then(function () {
			this.props.hideModal()
			this.props.showNotification(`${Object.keys(multiSelectItems).length} items to Trash `,'Sent','destroy')		
			this.resetState()
		}.bind(this))
	}

	batchMoveToFolder(folder){
		const {multiSelectItems}=this.props
		const length = Object.keys(multiSelectItems).length
		Object.keys(multiSelectItems).map((key) => {
			const item=multiSelectItems[key]
			this.handleMoveToFolder(item,folder,length)
			return null
		})
		this.resetState()
	}

	batchAddToQueue(section){
		const {multiSelectItems}=this.props
		let items = []
		Object.keys(multiSelectItems).map((key)=>{
			const item=multiSelectItems[key]
			items.push(item)
			return null
		})

		const librarySettings=getLibrarySettings()
		items=sortLibraryData(librarySettings.sortType,librarySettings.sortOrder,items)
		let plannedTime=moment()
		items.map((item)=>{
			this.queueItem(item,section,true,plannedTime)
			plannedTime=plannedTime.add(1,'millisecond')
			return null
		})
		this.props.showNotification(`${Object.keys(multiSelectItems).length} items to ${section.name} queue`,'Added','default')
	}

	centerPanelOnClick(){
		this.props.changeActiveItem({}) //deselect active cell by clicking outside the main table
	}

	keyPressNavigation(e){
		//prevent keypress navigation if context menu is open of command key pressed
		if(!this.state.contextMenuActiveItem===null || e.metaKey || e.ctrlKey){
			e.preventDefault()//prevent container scrolling
		}
		else{
			if(e.keyCode===32 && !this.props.previewItem ){
				e.preventDefault()
				if(!this.props.activePanelItem.ancestors){
					this.props.updatePreviewItem(this.props.activePanelItem.id)
				}
				e.stopPropagation()
			}else if (e.keyCode === 40 || e.keyCode === 38 ){
				e.preventDefault()
				if(!this.props.previewItem){
					const allData=this.state.folders.concat(this.props.libraryData)||[]
					this.props.keyPressNavigation(e,allData)
				}
			}else if ( e.keyCode === 13) { //if enter pressed on selected row open editor in new tab	
			 	const {activePanelItem}=this.props
				if(activePanelItem.ancestors){
					this.navigateToFolder(activePanelItem.id)	    	
				}else if(!activePanelItem.readOnly){
					if(activePanelItem.body){
						openItemInEditor(activePanelItem.id,false)
					}else if(activePanelItem.questions){
						openItemInEditor(activePanelItem.id,true)
					}
				}
			}
		}
	}

	changeLibrarySortOrder(sortType) {
		//Can sort by name or date.  If data is already sorted on that field then reverse the order
		let sorted = []
		let sortedFolders=this.props.folders.slice()
		let newSortOrder
		let newSortedBy

		if (sortType === 'byDate') {
			newSortedBy='date'
			if (this.state.sortedBy !== 'date') {
				sorted = sortBy(this.state.libraryData, 'clientModified').reverse()
				newSortOrder='ascending'
			
			}else{
				sorted = this.state.libraryData.reverse()
				if (this.state.sortOrder === 'descending') {
					newSortOrder='ascending'
				} else {
					newSortOrder='descending'
				}
			}
		}else if (sortType === 'byName') {
			newSortedBy='name'
			if (this.state.sortedBy !== 'name') {
				sorted = sortBy(this.state.libraryData, [function(o) {
				//for questions use question body, for sets use the set name
					if (o.body) {
						return o.body.toString().toLowerCase()
					} else {
						if (o.name) {
							return o.name.toString().toLowerCase()
						}
					}
				}])
				newSortOrder='ascending'
				sortedFolders=this.props.folders
			} else {
				sorted = this.state.libraryData.reverse()
				if (this.state.sortOrder === 'descending') {
					newSortOrder='ascending'
				} else {
					newSortOrder='descending'
					sortedFolders.reverse()
				}
			}
		}

		this.setState({libraryData: sorted,folders:sortedFolders,sortedBy:newSortedBy,sortOrder:newSortOrder})
		saveSettingToLocalStorage('librarySettings','sortType',newSortedBy)
		saveSettingToLocalStorage('librarySettings','sortOrder',newSortOrder)
	}

	copyQuestionToClipboard(question){
		copyQuestion(question)
		const notificationMessage='to Clipboard'
		this.props.showNotification(notificationMessage,'Copied','default')
	}

	handleContextMenuClick(e, data){
		e.stopPropagation() //prevent click on context menu deselecting cells etc
		if (data.action === 'Open') {
			if (data.itemType === 'set') {
				openItemInEditor(data.itemId,true)
			}
			if (data.itemType === 'question') {
				openItemInEditor(data.itemId,false)
			}
		}else if (data.action === 'Rename') {
			this.props.showRenameSetModal(data.item)
		}else if (data.action === 'Play') {
			this.playItem(data.item,data.section)
		}else if (data.action === 'Queue') {
			this.queueItem(data.item,data.section)
		}else if (data.action === 'Move') {
			this.showMoveToFolderModal([data.item])
		}else if (data.action === 'MoveToRepo') {
			this.props.showRepoSelectionModal(data.item)
		}else if (data.action === 'MoveToFolder') {
			this.handleMoveToFolder(data.item,data.folder)
		}else if (data.action === 'Archive') {
			this.archiveItem(data.item)
		}else if (data.action === 'Copy') {
			this.createCopy(data.item)
		}else if (data.action === 'CopyToClipboard') {
			this.copyQuestionToClipboard(data.item)
		}else if (data.action === 'ItemDetail') {
			if(data.item.questions){
				this.props.history.push(`/set/${data.item.id}`)
			}else if(data.item.choices){
				this.props.history.push(`/question/${data.item.id}`)
			}
		 }
		this.delayedMenuClose()
	}

	navigateToItemDetail(item){//shared with recent-could move to HOC
		if(item.questions){
			this.props.history.push(`/set/${item.id}`)
		}else if(item.choices){
			this.props.history.push(`/question/${item.id}`)
		}
	}

	handleFolderContextMenuClick(e, data){	
		const item=data.item
		e.stopPropagation() //prevent click on context menu deselecting cells etc
		if (data.action === 'Open') {
			this.navigateToFolder(item.id, item.name)
		}else if (data.action === 'Move') {
			this.showMoveToFolderModal({[item.id]:item})
		}else if (data.action === 'MoveToRepo') {
			this.props.showRepoSelectionModal(item)
		}else if (data.action === 'Archive') {
			this.archiveFolder(item)
		}else if (data.action === 'Rename') {
			this.showRenameFolderModal(item)
		}else if (data.action === 'NewQuestionInFolder') {
			this.handleNewQuestion(item.id)
		}else if (data.action === 'NewSetInFolder') {
			this.handleNewSet(item.id)
		}
		this.delayedMenuClose()
	}

	handleMultiSelectContextMenuClick(e, data){	
		e.stopPropagation() //prevent click on context menu deselecting cells etc
		const {multiSelectItems}=this.props
		if(Object.keys(multiSelectItems).length !==0){
			if (data.action === 'Archive') {
				this.confirmBatchArchive(multiSelectItems)
			}else if (data.action === 'MoveToFolder') {
				this.batchMoveToFolder(data.folder)
			}else if (data.action === 'Move') {
				this.showMoveToFolderModal(multiSelectItems)
			}else if (data.action === 'MoveToRepo') {
				this.props.showRepoSelectionModal(null,multiSelectItems)
			}else if (data.action === 'CombineIntoSet') {
				this.combineIntoSet()
			}else if (data.action === 'Queue') {
				this.batchAddToQueue(data.section)
			}
			this.delayedMenuClose()
		}
	}

	renderPathItem(pathItem,index){
		const folder=find(this.props.allFolders,{'id':pathItem})	
		if(folder){
			return(
				<div className='pageHeaderTop-folderNavRow-folderPathItem is--folder' onClick={() =>{this.navigateToFolder(pathItem)}} key={index}>
					<Icon name='chevron-right'/>
					<Icon name='folder'/>					       
					{folder.name}
				</div>
			)
		}else{
			return null
		}
	}

	handleSelectRow(selectedItem,e){
		const {libraryData}=this.state
		const combinedData=this.props.folders.concat(libraryData)
		this.props.handleSelectRow(selectedItem,e,combinedData)
	}


	render() {
		const {activePanelItem,multiSelectContainsFolders,multiSelectContainsReadOnly}=this.props
		const {parentFolder}=this.props
		let readOnly=false
		let folderPath=[]
		let folderNavRow
		if(parentFolder){
			readOnly=parentFolder.readOnly
			folderPath=parentFolder.ancestors.slice().reverse()
			folderNavRow=
				<React.Fragment>								
					<div className='pageHeaderTop-folderNavRow-folderPathItem is--library' onClick={()=>{this.navigateToFolder()}}>
						Your Library 
					</div>
					{folderPath.map((pathItem,index) => ( this.renderPathItem(pathItem,index)))}						

						<div className='pageHeaderTop-folderNavRow-folderPathItem is--folder is--currentFolder'>
			        <Icon name='chevron-right'/>
			        <Icon name={!readOnly ? 'folder' : 'folder-isbe' }/>					        
			          {this.state.folderName}					        
					  </div>	
				</React.Fragment>
			}


		let LibraryPageHeaderActions = 				
			<React.Fragment>
				{!parentFolder && <Button label='New Pack' onClickFunction={this.props.showNewRepoModal}/> }
				{!readOnly && <Button iconName='compose' label={parentFolder ? 'New Set in Folder' : 'New Set'} onClickFunction={()=>{this.handleNewSet()}} /> }
				{!readOnly &&	<Button iconName='folder-outline' tooltipLabel='New Folder' tooltipPosition='label' onClickFunction={this.handleNewFolder}/> }
					
				<Dropdown>   									
					<DropdownItem disabled={readOnly} label={parentFolder ? 'New Question in Folder' : 'New Question'} onSelect={()=>{this.handleNewQuestion()}}/>
					{parentFolder && <DropdownItem label='Move Folder to Pack...'  onSelect={()=>{this.props.showRepoSelectionModal(parentFolder,null,true)}}/> }
					<DropdownDivider />		
					<DropdownItem label='View Trash' onSelect={()=>{this.props.history.push('/trash')}} />									
				</Dropdown>					
			</React.Fragment>
		

		return (
			<DocumentTitle title={!parentFolder ? 'Your Library - Plickers' : `${this.props.parentFolder.name} - Plickers`}>
				<div className='page page--sidePanelLayout' >
				
					<div className='page-leftPanel'/>
					<div className='page-centerPanel library' onClick={this.centerPanelOnClick} >
						{!parentFolder &&
							<TopPageHeader
								location='library' 								
								leftBadge={<PageIcon name='libraryLarge' />}
								header='Your Library'
								actionRow={
									<React.Fragment>
										<FilterBar 
											value={this.state.filterTerm}
											onChange={this.handleFilterChange} 
											id='filterInput'
											clearBtnOnClick={() =>{this.clearFilter()}}               
										/>
										{LibraryPageHeaderActions}
									</React.Fragment>}
							/>
						}
						
						{!parentFolder &&
								<StickyPageHeader 
									location='library' 
									leftBadge={<PageIcon name='libraryLarge' />}
									header='Your Library'						
									actionGroup={LibraryPageHeaderActions}
								/>							
						}
 

						{parentFolder && 
							
							<TopPageHeader
								location='libraryFolder' 								
								leftBadge={
									<React.Fragment>
										<Icon name='chevron-left' />
										<PageIcon name='libraryLarge' />
									</React.Fragment>}
								leftBadgeClickFunction={()=>{
									if(folderPath.length===0){
										this.navigateToFolder()	
									}else{
										this.navigateToFolder(folderPath[folderPath.length-1])
										}  }}
								header={ <FolderNameInput
													path={folderPath}
													readOnlyFolder={readOnly}
													allFolders={this.props.allFolders}
													navigateToFolder={this.navigateToFolder}
													editFolderName={this.editFolderName}
													folder={this.props.parentFolder}  
												/>}						
								folderNavRow={folderNavRow}
								actionRow={
									<React.Fragment>
										<FilterBar 
											value={this.state.filterTerm}
											onChange={this.handleFilterChange} 
											id='filterInput'
											clearBtnOnClick={() =>{this.clearFilter()}}
										/>
										{LibraryPageHeaderActions}
									</React.Fragment>}
							/>

						}

						{parentFolder && 
							<StickyPageHeader 
								location='libraryFolder' 
								leftBadge={
									<React.Fragment>
										<Icon name='chevron-left' />
										<PageIcon name='libraryLarge' />
									</React.Fragment>
								}
								leftBadgeClickFunction={()=>{
									if(folderPath.length===0){
										this.navigateToFolder()	
									}else{
										this.navigateToFolder(folderPath[folderPath.length-1])
									}  }}
								header={this.props.parentFolder.name}
								actionGroup={LibraryPageHeaderActions}
							/>

						}

						<LibraryPage
							showNotification={this.props.showNotification}
							moveToFolder={this.props.moveToFolder}
							resetLibaryState={this.resetState}
							activeItem={this.props.activePanelItem}   
							contextMenuActiveItem={this.state.contextMenuActiveItem}
							contextMenuObject={this.state.contextMenuObject}
							multiSelectItems={this.props.multiSelectItems}
							multiSelectEnabled={this.props.multiSelectEnabled}
							libraryData={this.state.libraryData}
							folders={this.state.folders}
							parentFolder={this.props.match.params.folderId || null}
							sections={this.props.sections}
							filterTerm={this.state.filterTerm} 
							sortBy={this.state.sortedBy}
							sortOrder={this.state.sortOrder}
							sortLibrary={this.changeLibrarySortOrder}
							selectRow={this.handleSelectRow}
							keyPressNavigation={this.keyPressNavigation}
							navigateToFolder={this.navigateToFolder}  
							onShowContextMenu={this.onShowContextMenu}
							onHideContextMenu={this.onHideContextMenu}
							handleContextMenuClick={this.handleContextMenuClick}
							handleFolderContextMenuClick={this.handleFolderContextMenuClick}
							handleMultiSelectContextMenuClick={this.handleMultiSelectContextMenuClick}
							playItem={this.playItem}
							multiSelectContainsFolders={multiSelectContainsFolders}
							navigateToItemDetail={this.navigateToItemDetail}
							multiSelectContainsReadOnly={multiSelectContainsReadOnly}
							allSets={this.props.allSets}
							allQuestions={this.props.allQuestions}
							questionsInSetLimit={this.props.questionsInSetLimit} 
							repos={this.props.repos}
							moveToRepo={this.props.moveToRepo}
							resetLibraryState={this.resetState}
						/>	
 					</div>
					<PreviewPanelContainer 
						activePanelItem={activePanelItem}
						resetSelectedCells={this.props.resetSelectedCells}
						multiSelectItems={this.props.multiSelectItems}
						multiSelectContainsFolders={multiSelectContainsFolders}
		 				multiSelectContainsReadOnly={multiSelectContainsReadOnly}
		 				folders={this.state.folders}
						batchMoveToFolder={this.batchMoveToFolder}
						combineIntoSet={this.combineIntoSet}
						batchArchive={this.confirmBatchArchive}
						showMoveToFolderModal={this.showMoveToFolderModal}
						questionsInSetLimit={this.props.questionsInSetLimit}
					/> 
					 
					{this.state.showApplyOrderModal && 
						<ApplyOrderModalContainer/>
					}

				</div>			
			</DocumentTitle>	
		)
	}
}


function sortFolders(sortType,sortOrder,folders) {
	let sorted = folders.slice(0)
	sorted=sortBy(sorted,[function(o) { 
		return o.name.toString().toLowerCase()
	}])
	if (sortType === 'name' && sortOrder!=='ascending') {
		sorted=sorted.reverse()

	}
	return sorted
}


function mapStateToProps(state,ownProps) {
	const parentId=ownProps.match.params.folderId || null
	const questions=filter(state.questions, {'folder':parentId,'repo':null})
	const sets=filter(state.sets, {'folder':parentId,'repo':null})
	let libraryData=[]
	const librarySettings=getLibrarySettings()

	if(state.sets && state.questions){
		libraryData=sortLibraryData(librarySettings.sortType,librarySettings.sortOrder,sets.concat(questions))
	}
	const folders=filter((filter(state.folders, {'parent':parentId})),{'archived':false,'repo':null})
	let sortedFolders=[]
	if(folders){
		sortedFolders=sortFolders(librarySettings.sortType,librarySettings.sortOrder,folders)
	}
	let questionsInSetLimit
	if(state.planRestrictions){
		questionsInSetLimit=state.planRestrictions.questionsInSetLimit
	}

	return {
		previewItem:state.previewItem,
		folders:sortedFolders,
		allFolders:state.folders,
		parentFolder:find(state.folders,{'id':parentId})||null,
		questions:filter(state.questions, {'folder':parentId}),  
		sets:filter(state.sets, {'folder':parentId}),
		allSets:state.sets,
		allQuestions:state.questions,
		queueSetPolls:state.queueSetPolls,
		queueQuestionPolls:state.queueQuestionPolls,
		sections:state.sections,
		meta:state.meta,
		libraryData:libraryData,
		questionsInSetLimit:questionsInSetLimit,
		repos:sortBy(filter(state.repos, function(repo) { 
    		if(!repo.published || (repo.published &&(repo.role==='author' || repo.following))){
    			return repo
    		}
 			}),
		[repo => repo.name.toLowerCase()]),

	}
}



export default withSelectableCells(withRouter(connect(
	mapStateToProps,
	{ fetchFolders,
		showRenameSetModal,
		showNotification,
		hideNotification,
		showMoveToFolderModal,
		updateSetContent,
		updateSetMeta,
		updateQuestionMeta,
		updateFolder,
		fetchQuestions,
		fetchSets,    
		fetchQuestionPolls, 
		fetchSetPolls,
		showConfirmModal,   
		createPoll,
		playItem,
		showSectionSelectionModal,
		showEditFolderModal,
		combineIntoSet,
		hideModal,
		createCopyOfItem,
		moveToRepo,
		showNewRepoModal,
		showRepoSelectionModal,
		updatePreviewItem	
	}
)(LibraryContainer)))


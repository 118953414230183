import React from 'react'
import DropdownMenu from './DropdownMenu'
import Button from './Button'



class Dropdown extends React.Component{	


	constructor(){
		super()
		this.handleClickOutside=this.handleClickOutside.bind(this)
		this.handleContextMenuOpen=this.handleContextMenuOpen.bind(this)
		this.handleToggleDropdown=this.handleToggleDropdown.bind(this)		
		this.state={
			isOpen:false,
		}
	}


	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside)
		document.removeEventListener('contextmenu', this.handleContextMenuOpen)
	}



	handleClickOutside(e){
		if(this.state.isOpen){
			this.setState({isOpen:false})
			document.removeEventListener('click', this.handleClickOutside)
			if(this.props.onDropdownClose){
				this.props.onDropdownClose()
			}
		}
	}

	handleContextMenuOpen(e){ //shut dropdown if context menu is opened
		this.setState({isOpen:false})
		document.removeEventListener('click', this.handleClickOutside)
		if(this.props.onDropdownClose){
			this.props.onDropdownClose()
		}
	}



	


	handleToggleDropdown(e){
		if(!this.props.disabled){

			if(this.state.isOpen){
				this.setState({isOpen:false})
				document.removeEventListener('click', this.handleClickOutside)
				document.removeEventListener('contextmenu', this.handleContextMenuOpen)
				if(this.props.onDropdownClose){
					this.props.onDropdownClose()
				}
			}else{
				this.setState({isOpen:true})
				document.addEventListener('click', this.handleClickOutside)
				document.addEventListener('contextmenu', this.handleContextMenuOpen)
				if(this.props.onDropdownOpen){
					this.props.onDropdownOpen()
				}
			
			}
		}
	}
 
	render(){	
		const {children, position, inverted, dropdownButton} = this.props

		const button= dropdownButton ? 			
			React.cloneElement(dropdownButton,{onClickFunction:this.handleToggleDropdown , isOpen:this.state.isOpen })		
	 		:
	  	<Button  name='menu' iconName='three-dots-horizontal' iconSize='large' onClickFunction={(e) => this.handleToggleDropdown(e)}/>



		return(
			<div className='dropdown' id='dropdownDiv'>
				{button}
				<DropdownMenu position={position} inverted={inverted} isOpen={this.state.isOpen} >
					{children}
				</DropdownMenu> 
				<div className='dropdown-screen' />
			</div>
		)
	}			
}


Dropdown.defaultProps = {
	disabled:false
}



export default Dropdown

import React from 'react'
import _,{range} from 'lodash'
import {Motion, spring} from 'react-motion'
import SectionHomeDnDQueueCard from './SectionHomeDnDQueueCard'
import SectionHomeDnDArchivedItemCard from './SectionHomeDnDArchivedItemCard'
import {getSetForId} from '../../../../utils/getSetForId'
import {getQuestionForId} from '../../../../utils/getQuestionForId'

const moveToUpNextBuffer=150//the amount a card must be dragged above the upcoming list for it to be move to upNext

const QUEUE_ITEM_HEIGHT = 147

const springConfig = {stiffness: 300, damping: 30}
const DND_DELAY = 50//time to hold before becomes draggable

function reinsert(arr, from, to) {
	const _arr = arr.slice(0)
	const val = _arr[from]
	_arr.splice(from, 1)
	_arr.splice(to, 0, val)
	return _arr
}

function clamp(n, min, max) {
	return Math.max(Math.min(n, max), min)
} 


class SectionHomeUpcomingDnDList extends React.Component{

	constructor(props) {
		super(props)
		this.state = {
			topDeltaY: 0,
			mouseY: 0,
			isPressed: false,
			originalPosOfLastPressed: 0,
			order: range(props.queueData.length),
			queueData:props.queueData
		}
		this.handleMouseDown=this.handleMouseDown.bind(this)
		this.handleMouseMove=this.handleMouseMove.bind(this)
		this.handleMouseUp=this.handleMouseUp.bind(this)
		this.delayedPress=this.delayedPress.bind(this)
		this.movePollUpQueue=this.movePollUpQueue.bind(this)
		this.movePollDownQueue=this.movePollDownQueue.bind(this)

		this.delayedPress = _.debounce(this.delayedPress,DND_DELAY,{'leading': false })
	}



	UNSAFE_componentWillReceiveProps(nextProps) {
	//prevent cards jumping when drag and drop to reorder
		if(!this.props.isSaving && !nextProps.isSaving && this.props.isSaving === nextProps.isSaving){
			this.setState({order:range(nextProps.queueData.length),queueData:nextProps.queueData})
		}
	}

	componentDidMount() {
		window.addEventListener('mousemove', this.handleMouseMove)
		window.addEventListener('mouseup', this.handleMouseUp)
	}


	componentWillUnmount() {
		window.removeEventListener('mousemove', this.handleMouseMove)
		window.removeEventListener('mouseup', this.handleMouseUp)
	}

 

	handleMouseDown(pos, pressY, {pageY}){
		this.setState({
			topDeltaY: pageY - pressY,
			mouseY: pressY,
			// isPressed: true,
			originalPosOfLastPressed: pos,
			currentRow:pos,
		})
		this.delayedPress()
	}

	delayedPress(){
		this.setState({isPressed:true})
	}



	handleMouseMove({pageY}){
		const {isPressed, topDeltaY, order, originalPosOfLastPressed} = this.state
		if(isPressed) {
			const mouseY = pageY - topDeltaY
			const currentRow = clamp(Math.round(mouseY / QUEUE_ITEM_HEIGHT), 0, this.props.queueData.length - 1)
			let newOrder = order
			if (currentRow !== order.indexOf(originalPosOfLastPressed)){
				newOrder = reinsert(order, order.indexOf(originalPosOfLastPressed), currentRow)
			}
			this.setState({mouseY: mouseY, order: newOrder, currentRow:currentRow})
		}
	}

	handleMouseUp(){
		if(this.state.isPressed){
			this.setState({isPressed: false, topDeltaY: 0})
			if(this.state.mouseY < (-1*moveToUpNextBuffer)){
				this.props.moveToUpNext(this.state.queueData[this.state.originalPosOfLastPressed])
			}
			else{
				const oldIndex= this.state.originalPosOfLastPressed
				const newIndex=this.state.currentRow
				if(oldIndex !== newIndex){
					const newArray=reinsert(this.state.queueData, oldIndex, newIndex)
					this.props.reorderQueue(oldIndex,newIndex,newArray).then((response) => {
						//this.setState({queueData:response,order:range(response.length)})
					})
				}}
		}else{

			this.delayedPress.cancel()
		}
	}

	movePollUpQueue(oldIndex){ //move an item one place up the queue
		const newIndex=Math.max(0,oldIndex-1)

		if(newIndex !== oldIndex){
			const newArray=reinsert(this.state.queueData, oldIndex, newIndex)
			this.props.reorderQueue(oldIndex,newIndex,newArray).then((response) => {
				this.setState({queueData:response,order:range(response.length)})
			})
		}
	}

	movePollDownQueue(oldIndex){ //move an item one place down the queue
		const newIndex=Math.min(this.state.queueData.length-1,oldIndex+1)
		if(newIndex !== oldIndex){
			const newArray=reinsert(this.state.queueData, oldIndex, newIndex)
			this.props.reorderQueue(oldIndex,newIndex,newArray).then((response) => {
				//this.setState({queueData:response,order:range(response.length)})
			})
		}
	}


	render(){
		const {mouseY, isPressed, originalPosOfLastPressed, order,queueData} = this.state
				
		const itemListHeight = (queueData.length) * QUEUE_ITEM_HEIGHT

		const cardIsAboveUpNext=(mouseY<(-1*moveToUpNextBuffer))?true:false

		return(
		 	<div style={{height: `${itemListHeight}px`}} 
		 		className={'sectionHome-upcoming-itemList' + 
		 			(isPressed ? ' sectionHome-upcoming-itemList--isPressed' : '') + 
		 			((isPressed && cardIsAboveUpNext) ? ' sectionHome-upcoming-itemList--cardIsAboveUpNext' : '') + 
		 			(this.props.contextMenuPoll.id ? ' sectionHome-upcoming-itemList--contextMenuOpen' : '') + 
		 			(this.props.upNextContextMenuOpen ? ' sectionHome-upcoming-itemList--upNextContextMenuOpen' : '')
		 		}>
				
				<div className='sectionHome-upcoming-itemList-upNextBlocker' />

				{range(queueData.length).map(i => {

					const style = originalPosOfLastPressed === i && isPressed
						? {
							scale: spring(1.05, springConfig),							
							y: mouseY
						}
						: {
							scale: spring(1, springConfig),							
							y: spring(order.indexOf(i) * QUEUE_ITEM_HEIGHT, springConfig)
						}

					let isSet
					let queueItem
				
					if(queueData[i].setObject){
						isSet=true
						queueItem=getSetForId(queueData[i].setObject)
						
					}else {
						isSet=false
						queueItem= getQuestionForId(queueData[i].question)
					
					}

					if(queueItem && queueData[i].id ){
						return (
							<Motion style={style}  key={queueData[i].id}>
								{({scale,y}) =>
									<SectionHomeDnDQueueCard
										key={queueData[i].id} 
										index={i}
										y={y}
										originalPosOfLastPressed={originalPosOfLastPressed}
										isSet={isSet}
										playPoll={this.props.playPoll}
										isPressed={isPressed}
										item={queueItem}
										repos={this.props.repos}
										queuePoll={queueData[i]}
										contextMenuOpen={queueData[i].id===this.props.contextMenuPoll.id}
										scale={scale}
										handleMouseDown={this.handleMouseDown}
										removeFromQueue={this.props.removeFromQueue}
										movePollUpQueue={this.movePollUpQueue}
										movePollDownQueue={this.movePollDownQueue}
										moveToUpNext={this.props.moveToUpNext}
										cardIsPressed={originalPosOfLastPressed === i && isPressed}
				 	      	 />
								}
							</Motion>
						)
					}else if(this.props.sets && this.props.questions) {return( //if sets and questions are loaded
						<Motion style={style}  key={queueData[i].id || i}>
							{({scale,y}) =>
								<SectionHomeDnDArchivedItemCard
									key={queueData[i].id} 
									y={y}
									removeFromQueue={this.props.removeFromQueue}
									index={i}
									queuePoll={queueData[i]}
									scale={scale}/>
							}
						</Motion>
					)} else return null
				
				}
	
				)
				}
				 	
				 </div>
		)
	}
}



export default SectionHomeUpcomingDnDList
import api from '../api'
import {showWhitelistErrorModal} from './modals'

//http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#cloudinary

export function uploadNewImageToCloudinary(file,thumbnail) {
	return (dispatch) => api.fetch('/cloudinary?type=image')
		.then((response) => {
			return dispatch(uploadToCloudinary(file,response.api_key,response.timestamp,response.signature,response.public_id,response.upload_preset))
				.then((response) => {
					return response
				})
				.catch((error) => {//upload image thumbnail if available (thumbnail comes from image search result)
					return dispatch(uploadToCloudinary(thumbnail,response.api_key,response.timestamp,response.signature,response.public_id))
						.then((response) => {
							return response
						})
						.catch((error) => {//TODO better error handling	
							if(error.response && (error.response.status===400 ||error.response.status===401 ||error.response.status===404) ){
								throw error
							}else{
								dispatch(showWhitelistErrorModal('cloudinary'))
								throw error
							}
						})
				})
		})
}

export function uploadProfileImageToCloudinary(image) {
	return (dispatch) => api.fetch('/cloudinary?type=profile')
		.then((response) => {
			return dispatch(uploadToCloudinary(image,response.api_key,response.timestamp,response.signature,response.public_id,response.upload_preset))
				.then((response) => {
					return response
				})
				.catch((error) => {})
		})
}

export function uploadRepoImageToCloudinary(image,repoId) {
	return (dispatch) => api.fetch(`/cloudinary?type=repo&id=${repoId}`)
		.then((response) => {
			return dispatch(uploadToCloudinary(image,response.api_key,response.timestamp,response.signature,response.public_id,response.upload_preset))
				.then((response) => {
					return response
				})
				.catch((error) => {})
	})
}


// export function uploadSoundToCloudinary(file) {
// 	return (dispatch) => api.fetch('/cloudinary/')
// 		.then((response) => {
// 			return dispatch(uploadToCloudinary(file,response.api_key,response.timestamp,response.signature,response.public_id))
// 				.then((response) => {
// 					return response
// 				})
// 			.catch((error) => {})
// 	})
// }


export function uploadSoundToCloudinaryWithProgress(file,onProgress) { //used for showing progress bar during sound upload
	return (dispatch) => api.fetch('/cloudinary?type=sound')
		.then((response) => {
			return dispatch(uploadToCloudinaryWithProgress(file,onProgress,response.api_key,response.timestamp,response.signature,response.public_id,response.upload_preset))
				.then((uploadResponse) => {
					return uploadResponse
				})
		})
}


export function uploadToCloudinaryWithProgress(file,onProgress,api_key,timestamp,signature,public_id,upload_preset) {
	return (dispatch) => api.postCloudinaryWithProgress(file,onProgress,api_key,timestamp,signature,public_id,upload_preset)
		.then((uploadResponse) => {
			return uploadResponse
		})
}

export function uploadToCloudinary(file,api_key,timestamp,signature,public_id,upload_preset) {
	let form = new FormData()
	form.append('file', file)
	form.append('api_key', api_key)
	form.append('timestamp', timestamp)
	form.append('signature',signature)
	form.append('public_id', public_id)
	if(upload_preset){
		form.append('upload_preset', upload_preset)
	}
	return (dispatch) => api.postCloudinary(form)
		.then((response) => {
	  	return response
	  }) 
}
import store from '../../store'
import find from 'lodash/find'
import {formatSubjectToString} from '../formatSubjectToString'


export function copyItemFromRepoEvent(item,initiatedFrom) {
	const state = store.getState()
	if(window.analytics){
		let itemType=''
		let itemName=''
		if(item.questions){
			itemType='set'
			itemName=item.name
		}else{
			itemType='question'
			itemName=item.body
		}
		const repoId=item.repo
		const repo= find(state.repos, {'id':repoId})||{}

		const formattedSubject=formatSubjectToString(repo.subject)
		if(repo.published){
			const userIsAuthor=repo.role==='author'?true:false
			window.analytics.track('Copy item out of public repo',{
				itemType:itemType,
				itemId:item.id,
				itemName:itemName,
				repoId:repoId,
				repoName:repo.name,
				repoSubject:formattedSubject,
				repoLanguage:repo.language,
				repoGradeArray:repo.grade,
				repoAuthorUsername:repo.author.username,
				reopDescription:repo.description,
				initiatedFrom:initiatedFrom,
				userIsAuthor:userIsAuthor
			})
		}else{
			window.analytics.track('Copy item out of view only private shared',{
				itemType:itemType,
				itemId:item.id,
				itemName:itemName,
				repoId:repoId,
				repoName:repo.name,
				repoSubject:formattedSubject,
				repoLanguage:repo.language,
				repoGradeArray:repo.grade,
				reopDescription:repo.description,
				initiatedFrom:initiatedFrom

			})

		}



	

	}
	
}



import React from 'react'
import ReactDOM from 'react-dom';
import QuestionImageReactComponent from './QuestionImageReactComponent'
import {showEditImageModal} from '../../utils/showEditImageModal'
import {setImageAttributes} from '../../../../prosemirror/utils/editorActions/setImageAttributes'
import {updateImageNodeWithSavedCloudinaryValues} from '../../../../prosemirror/utils/editorActions/addImage'



function handleMouseDown(e){
  e.stopPropagation()
  if(e.target === e.currentTarget){
    e.preventDefault()
    e.stopPropagation()
  } 
}


class QuestionImageNodeView {

  constructor(node, view, getPos) {
    this.getNode=this.getNode.bind(this)
    this.showEditImageModal=this.showEditImageModal.bind(this)
    this.updateQuestionImageCloudinaryUrl=this.updateQuestionImageCloudinaryUrl.bind(this)
    this.setImageFillType=this.setImageFillType.bind(this)
    this.node = node
    this.getPos=getPos
    this.dom = document.createElement("div")
    this.dom.className="slide-mediaContainer slide-mediaContainer--image"
    this.dom.addEventListener('mousedown',handleMouseDown)
   
    const {
      fileId,
      deliveryUrl,
      deliveryDimensions,
      originalDimensions,
      imageFill,
      crop,
      rotate,
      markup,
      colorAdjust,
      template,
      placeholderImage,
      questionMediaDynamicHeight,
      nodeId,
      slideWidth
    } = node.attrs

    if(!fileId){ //check if image has been uploaded to cloudinary (undo/redo while in placeholder state)
      setTimeout(function() { //Start the timer
        updateImageNodeWithSavedCloudinaryValues(node.attrs.nodeId)
        },  500)
    }

    ReactDOM.render(
      <QuestionImageReactComponent 
        key={placeholderImage||deliveryUrl}
        fileId={fileId}
        deliveryUrl={deliveryUrl}
        deliveryDimensions={deliveryDimensions}
        originalDimensions={originalDimensions}
        imageFill={imageFill}
        crop={crop}
        rotate={rotate}
        markup={markup}
        colorAdjust={colorAdjust}
        template={template}
        placeholderImage={placeholderImage}
        questionMediaDynamicHeight={questionMediaDynamicHeight}
        showEditImageModal={this.showEditImageModal}
        updateQuestionImageCloudinaryUrl={this.updateQuestionImageCloudinaryUrl}
        setImageFillType={this.setImageFillType}
        nodeId={nodeId}
        slideWidth={slideWidth}
  
      />, this.dom)
    }


  getNode(){
    return this.node
  }

  setImageFillType(fillType){
    let imageFill=false
    if(fillType==='fill'){
      imageFill=true
    }
    const node=this.getNode()
    const nodePos=this.getPos(node)
    const attributes={...node.attrs,imageFill:imageFill}
    setImageAttributes(node,nodePos,attributes)

  }

  showEditImageModal(){
    const {fileId} = this.node.attrs
    showEditImageModal(fileId,this.node,this.getPos())
  }

  updateQuestionImageCloudinaryUrl(cloudinaryUrl){
    const node=this.getNode()
    const nodePos=this.getPos(node)
    var tr = window.view.state.tr
    tr.setNodeMarkup(nodePos, null, {...node.attrs,cloudinaryUrl:cloudinaryUrl})
    window.view.dispatch(tr)

  }




 update(node) {
  if (node.type !== this.node.type) return false
   const {
      fileId,
      deliveryUrl,
      markup,
      colorAdjust,
      template,
      rotate,
      originalDimensions,
      questionMediaDynamicHeight,
      crop,
      imageFill,
      deliveryDimensions,
      placeholderImage,
      nodeId,
      slideWidth
    } = node.attrs
    const oldTemplate=this.node.attrs.template
    const oldQuestionMediaDynamicHeight=this.node.attrs.questionMediaDynamicHeight
    const oldRotate=this.node.attrs.rotate
    const oldCrop=this.node.attrs.crop
    const oldMarkup=this.node.attrs.markup
    const oldDeliveryUrl=this.node.attrs.deliveryUrl
    const oldPlaceholderImage=this.node.attrs.placeholderImage
    const oldSlideWidth=this.node.attrs.slideWidth
      
   if(
    template !==oldTemplate || 
    questionMediaDynamicHeight!==oldQuestionMediaDynamicHeight || 
    rotate !==oldRotate ||
    oldCrop !== crop ||
    oldMarkup !== markup ||
    oldDeliveryUrl !==deliveryUrl ||
    placeholderImage !== oldPlaceholderImage ||
    slideWidth !== oldSlideWidth
    ){

    if(!fileId){
      updateImageNodeWithSavedCloudinaryValues(node.attrs.nodeId)
    }


    ReactDOM.render(
      <QuestionImageReactComponent 
        placeholderImage={placeholderImage}
        key={placeholderImage||deliveryUrl}
        fileId={fileId}
        deliveryUrl={deliveryUrl}
        colorAdjust={colorAdjust}
        markup={markup}
        rotate={rotate}
        originalDimensions={originalDimensions}
        crop={crop}
        template={template}
        questionMediaDynamicHeight={questionMediaDynamicHeight}
        showEditImageModal={this.showEditImageModal}
        setImageFillType={this.setImageFillType}
        imageFill={imageFill}
        updateQuestionImageCloudinaryUrl={this.updateQuestionImageCloudinaryUrl}
        deliveryDimensions={deliveryDimensions}
        nodeId={nodeId}
        slideWidth={slideWidth}

         />, this.dom)
        }
        
    this.node=node
    return true
  }


  destroy() {
    this.dom.removeEventListener('mousedown',handleMouseDown)
    //if (this.innerView) this.close()
  }

  stopEvent(event) {
    //return this.innerView && this.innerView.dom.contains(event.target)
  }


}

export default QuestionImageNodeView
import React from 'react'
import Icon from '../misc/Icons'

class HelpVideoThumbnail extends React.Component{
	render(){	
		return(
			<div className='helpVideoThumbnail'>
				<div className='helpVideoThumbnail-plickersLogo'>
					<Icon name='logo' />
				</div>
				<div className='helpVideoThumbnail-title'>
					{this.props.title}
				</div>
		  </div>
		)				
	}
				
}

export default HelpVideoThumbnail
import {InputRule} from 'prosemirror-inputrules'

//replace -- with unicode minus symbol 
//TODO combine with arrow input rule
export function minusSignInputRule(){
	return new InputRule(/--\s/g, (state, match, start, end) => {
		let tr = state.tr
		let hasMultipleNodes=false
		state.doc.nodesBetween(start, end, (node, position) => {
		if (!node.isText || start === end) return
		const startPosition = Math.max(position, start)
		const endPosition = Math.min(position + node.nodeSize, end)
		if(startPosition===start && endPosition===end){ //don't apply input rule if matched text is accross multiple nodes
			const textNode = state.schema.text(`\u2212\u00a0`, node.marks)//minus symbol with space after
			tr.replaceWith(start, end, textNode)
		}else{
			hasMultipleNodes=true
		}
	})
	if(hasMultipleNodes){
		return false
	}else{
		return tr
	}
	})
}



import React from 'react'
import ModalWrapper from './ModalWrapper'
import EditStudentContainer from '../../containers/sections/EditStudentContainer'

const EditStudentModal= props => {
	return (
		<div className='editStudent-modal'>
			<ModalWrapper
				{...props}
				title='Edit Student Name'
				modalStyle='dark'        
			>
				<EditStudentContainer {...props} />
			</ModalWrapper>
		</div>

	)
}

export default EditStudentModal
import React from 'react'
import SectionHomeUpcomingDnDList from './SectionHomeUpcomingDnDList'


class SectionHomeUpcoming extends React.Component{

	constructor(props) {
		super(props)	
		this.moveToUpNext=this.moveToUpNext.bind(this)	
		this.removeFromQueue=this.removeFromQueue.bind(this)	
		this.state = {
			activeQueuePollIndex: 0, 
		}
	} 
 

	removeFromQueue(poll){
		const newActiveQueuePollIndex=Math.min(this.state.activeQueuePollIndex,this.props.queuePolls.length-2)
		this.setState({activeQueuePollIndex:newActiveQueuePollIndex})
		this.props.removeFromQueue(poll)
	}


	moveToUpNext(poll){
		this.props.moveToUpNext(poll).then(() => {
			this.setState({activeQueuePollIndex:0})
		})
	}

	render(){ 
	
		return(
			<div className='sectionHome-upcoming sectionHome--block' >
				<div className="sectionHome--block-divider" />				
				<div className="sectionHome--block-header">
					<div className="sectionHome--block-header-title">
						Upcoming
					</div>	
				</div> 
			
				<SectionHomeUpcomingDnDList
					queueData={this.props.queuePolls} 
					reorderQueue={this.props.reorderQueue}
					sets={this.props.sets}
					questions={this.props.questions}
					repos={this.props.repos}
					removeFromQueue={this.props.removeFromQueue}
					playPoll={this.props.playPoll}
					moveToUpNext={this.props.moveToUpNext}
					contextMenuPoll={this.props.contextMenuPoll}
					upNextContextMenuOpen={this.props.upNextContextMenuOpen}
				/>
			
			</div>
		)
	}
}


export default SectionHomeUpcoming
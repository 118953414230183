import {TextSelection} from "prosemirror-state"
import {getActiveQuestion} from './getActiveQuestion'
import {setQuestionBodySelectionTransaction} from './setQuestionBodySelectionTransaction'

//tab key cycles through body, choices, shuffle button and new question button

function focusShuffleTrueFalseButton(){ //focus true/false or shuffle depending which is shown
	const shuffleButton = document.getElementById('shuffleQuestionButton')
	if(shuffleButton){
		shuffleButton.focus()
	}else{
		const trueFalseButton = document.getElementById('trueFalseButton')
		if(trueFalseButton){
			trueFalseButton.focus()
		}
	}
}

export function handleEditorTabKeypress(focusActiveQuestionInSlideList){
	let focused=false
	if(window.view){
		focused=window.view.hasFocus()
	}
	if(focused){
		const view = window.view
		const state = view.state 
		const selection = state.selection 
		const {anchor}=selection
		let tr=state.tr
		let inFraction=false //if cursor is in fraction tab to move between nominator and denominator
		let fractionNode
		let fractionPos
		let inFractionNumerator=false
		let inFractionDenominator=false
		let bodyIsSelected = false
		let choiceANode
		let choiceAPos
		let choiceAIsSelected = false
		let choiceBNode
		let choiceBPos
		let choiceBIsSelected = false
		let choiceCNode
		let choiceCPos
		let choiceCIsSelected = false
		let choiceDNode
		let choiceDPos
		let choiceDIsSelected = false

		state.doc.nodesBetween(0,state.doc.content.size, (parentNode, parentPos) => {
			if(parentNode.attrs.activeslide && parentNode.attrs.activeslide===true){
			 parentNode.descendants((node, pos) => {
			 	const hasAnchor = anchor >= pos+parentPos && anchor <= (pos+parentPos + node.nodeSize)
	      if(node.type.name==='friendlyFraction'){	      	
	      	if(hasAnchor){
	      		inFraction=true
	      		fractionNode=node
	      		fractionPos=pos	      		
	      	}	
	      }
	      if(node.type.name==='friendlyFractionNumerator'){	      	
	      	if(hasAnchor){
	      		inFractionNumerator=true
	      	}	
	      }
	      if(node.type.name==='friendlyFractionDenominator'){	      	
	      	if(hasAnchor){
	      		inFractionDenominator=true
	      	}	
	      }
	      if(node.type.name==='questionBody'){
	      	if(hasAnchor){
	      		bodyIsSelected=true
	      	}
	      }
	      if(node.type.name==='choice'){
	      	if(node.attrs.choiceIndex===0){
	      		choiceANode=node
	      		choiceAPos=pos+parentPos
	      		if(hasAnchor){
	      			choiceAIsSelected=true
	      		}
	      	}
	      	if(node.attrs.choiceIndex===1){
	      		choiceBNode=node
	      		choiceBPos=pos+parentPos
	      		if(hasAnchor){
	      			choiceBIsSelected=true
	      		}
	      	}
	      	if(node.attrs.choiceIndex===2){
	      		choiceCNode=node
	      		choiceCPos=pos+parentPos
	      		if(hasAnchor){
	      			choiceCIsSelected=true
	      		}
	      	}
	      	if(node.attrs.choiceIndex===3){
	      		choiceDNode=node
	      		choiceDPos=pos+parentPos
	      		if(hasAnchor){
	      			choiceDIsSelected=true
	      		}
	      	}
	      }
			 })
			}
		})

		if(inFraction){
			//do fraction stuff
			let textSelectionPosition
			let fractionDenominatorNode
			let fractionDenominatorPos

			state.doc.nodesBetween(0,state.doc.content.size, (parentNode, parentPos) => {
				if(parentNode===fractionNode){
					parentNode.descendants((node, pos) => {
						if(node.type.name==='friendlyFractionDenominator'){	      	
							fractionDenominatorNode=node
							fractionDenominatorPos=pos+parentPos	    		
						}	
					})
				}			
			})

			if(inFractionNumerator){
				textSelectionPosition=fractionDenominatorPos+fractionDenominatorNode.nodeSize //to end of denominator
			}else if(inFractionDenominator){ //move cursor to after the fraction
				textSelectionPosition=fractionPos+fractionNode.nodeSize+2
			}
			if(textSelectionPosition){
				tr.setSelection(TextSelection.create(state.doc,textSelectionPosition))
				window.view.dispatch(tr)
			}
		}else{ //not in fraction, cycle through nodes
			let textSelectionPosition

			if(bodyIsSelected){//move from body to first choice
				textSelectionPosition=choiceAPos+choiceANode.nodeSize-2
			}else if(choiceAIsSelected){
				textSelectionPosition=choiceBPos+choiceBNode.nodeSize-2
			}else if(choiceBIsSelected){
				if(choiceCNode){
					textSelectionPosition=choiceCPos+choiceCNode.nodeSize-2
				}else{
					focusShuffleTrueFalseButton()
				}
			} else if(choiceCIsSelected){
				if(choiceDNode){
					textSelectionPosition=choiceDPos+choiceDNode.nodeSize-2
				}else{
					focusShuffleTrueFalseButton()
				}
			}else if(choiceDIsSelected){
				focusShuffleTrueFalseButton()
			}

			if(textSelectionPosition){
				tr.setSelection(TextSelection.create(state.doc,textSelectionPosition))
				window.view.dispatch(tr)
			}
		}
	}
	else{ //editor not focused
		const suffledButtonFocused=document.getElementById('shuffleQuestionButton')===document.activeElement
		const trueFalseButtonFocused=document.getElementById('trueFalseButton')===document.activeElement
		if(suffledButtonFocused || trueFalseButtonFocused){
			const newQuestionButton=document.getElementById('addQuestionButton')
			if(newQuestionButton){
				newQuestionButton.focus()
			}else{
				const activeQ=getActiveQuestion()
				const transaction=setQuestionBodySelectionTransaction(window.view.state.tr,activeQ.questionNode,activeQ.questionPos)
				window.view.dispatch(transaction)
				window.view.focus()	
			}
		}else{
			const newQuestionButtonFocused=document.getElementById('addQuestionButton')===document.activeElement
			if(newQuestionButtonFocused){
				focusActiveQuestionInSlideList()
			}else{
		 	 const activeElementId=document.activeElement.id
		 	 let slideListSlideFocused=false
			  if(activeElementId && activeElementId.includes('draggableSlide')){
			    slideListSlideFocused=true
			  }
			  if(slideListSlideFocused){			  	
			  	const activeQ=getActiveQuestion()
			  	const transaction=setQuestionBodySelectionTransaction(window.view.state.tr,activeQ.questionNode,activeQ.questionPos)
			  	window.view.dispatch(transaction)
			  	window.view.focus()
			  }
			}
		}
	}
}

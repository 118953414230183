import React from 'react'
import EditorIcon from '../../../misc/EditorIcons'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import {formatVideoDuration} from '../../../../utils/timestampFormatters/formatVideoDuration'

class QuestionVideoControlBar extends React.Component{  
	
	render(){
		const {duration,isPlaying,isMuted,playerCurrentTime,clipStartTime,mediaZoomed,zoomable}=this.props
		const startTime=clipStartTime||0
		let currentTimestamp
		if(this.props.clipStartTime){	
			currentTimestamp = formatVideoDuration(Math.max(playerCurrentTime-clipStartTime,0))
		}else{
			currentTimestamp = formatVideoDuration(playerCurrentTime)
		}

		const fullTimestamp=formatVideoDuration(duration)
		const lengthSeconds = duration
	
	  return (
	  	<div className={'slide-media--video-controlBar ' + (this.props.liveViewChoiceStartZoom ? 'slide-media--video-controlBar--liveViewChoiceStartZoom' : '')}>	  		
  			<div className='slide-media--video-controlBar-seekBar'>
  				<Slider 
  					onBeforeChange={this.props.onBeforeChange}
  					onAfterChange={this.props.onAfterChange}
  					onChange={(value)=>{this.props.seekTo(value+clipStartTime)}}
  					value={playerCurrentTime-startTime} 
  					min={0} 
  					max={lengthSeconds} 
  					step={0.01}
  					defaultValue={0} 
  				/> 
  			</div>
	  		
	  		<div className='slide-media--video-controlBar-buttonContainer'>
	  			{isPlaying &&
		  			<button onClick={this.props.pauseVideo} className='slide-media--video-controlBar-btn slide-media--video-controlBar-btn--playPause'>
		  				<EditorIcon name='playbackPause' /> 
		  			</button>
		  		}
	  			{!isPlaying &&
		  			<button onClick={this.props.playVideo} className='slide-media--video-controlBar-btn slide-media--video-controlBar-btn--playPause'>
		  				<EditorIcon name='playbackPlay' /> 
		  			</button>
	  			}
	  			<div className='slide-media--video-controlBar-timestamp'>
	  				<div className='slide-media--video-controlBar-timestamp-current'>
	  					{currentTimestamp}
	  				</div>
	  				<div className='slide-media--video-controlBar-timestamp-total'>
	  					{fullTimestamp}
	  				</div>
	  			</div>

	  			{/* GOES BACK TO 0 AND PLAYS */}
	  			<button onClick={this.props.restartVideo} className={'slide-media--video-controlBar-btn slide-media--video-controlBar-btn--restart'}>	  			 			
	  				<EditorIcon name='playbackRestart' />
	  			</button>

	  			{/* MUTE TOGGLE */}
	  			{!this.props.hideMuteButton &&
		  			<button onClick={this.props.toggleMuteVideo} className={'slide-media--video-controlBar-btn slide-media--video-controlBar-btn--volume' + (isMuted ? ' slide-media--video-controlBar-btn--inactive':'')}>
		  				{!isMuted &&
		  					<EditorIcon name='playbackVolume' />
		  				}
		  				{isMuted &&
		  					<EditorIcon name='playbackMute' />
		  				}
		  			</button>
	  			}

	  			{/* SHOW/HIDE CC TOGGLE 
	  			<button className={'slide-media--video-controlBar-btn slide-media--video-controlBar-btn--CC' + (!CCActive ? ' slide-media--video-controlBar-btn--inactive':'')}>
	  				<EditorIcon name='playbackCC' />
	  			</button>
	  			*/}

	  			{/* ZOOMABLE TOGGLE */}
	  			{zoomable && !mediaZoomed &&
		  			<button onClick={this.props.zoomMedia} className='slide-media--video-controlBar-btn slide-media--video-controlBar-btn--zoom'>
		  				<EditorIcon name='expandVideo' />		  				
		  			</button>
	  			}
	  			{zoomable && mediaZoomed &&
		  			<button onClick={this.props.unzoomMedia} className='slide-media--video-controlBar-btn slide-media--video-controlBar-btn--zoom'>		  				
		  				<EditorIcon name='unZoomMedia' />		  				
		  			</button>
	  			}

	  		</div>
	  	</div>
	  )
	}
}

export default QuestionVideoControlBar

import findIndex from 'lodash/findIndex'

export default function (state = {}, action) {
	switch (action.type) {

	case 'FETCH_PERMISSIONS_SUCCESS':				
		const repoId=action.repoId
		const response=action.response
			 return {...state,
			 [`${repoId}`]:response 
			 }
 

	case 'PERMISSION_ADDED_PUSHER':	
	case 'CREATE_PERMISSION_SUCCESS':		
		const addedPermission=action.permission
		const repo=addedPermission.repo
		let repoPermissions=state[repo]
		var indexOfAddedPermission = findIndex(repoPermissions,permission => {
			if(addedPermission.user && permission.user){
				return permission.user.id === addedPermission.user.id
			}
			else{
				return permission.originalEmail === addedPermission.originalEmail
			}
		})
		if(indexOfAddedPermission === -1){
			indexOfAddedPermission=repoPermissions.length
		}
		let updatedRepoPermissions=[
			...repoPermissions.slice(0,indexOfAddedPermission),
			addedPermission,
			...repoPermissions.slice(indexOfAddedPermission + 1)
		]
		return {...state,
			 [`${repo}`]:updatedRepoPermissions

			 }		

	case 'UPDATE_PERMISSION_SUCCESS':	
	case 'PERMISSION_UPDATED_PUSHER':		
		let permissionList=state[`${action.repoId}`]
		var indexOfUpdatedPermission = findIndex(permissionList,permission => {
			if(action.permission.user && permission.user){
				return permission.user.id === action.permission.user.id
			}
			else if(permission.originalEmail && action.permission.originalEmail){
				return permission.originalEmail === action.permission.originalEmail
			}
			else if(permission.originalEmail && action.permission.user){
				return permission.originalEmail === action.permission.user.email
			}

		})
		
		if(indexOfUpdatedPermission === -1){
			indexOfUpdatedPermission=permissionList.length
		}

		let updatedPermissionList=[
			...permissionList.slice(0,indexOfUpdatedPermission),
			action.permission,
			...permissionList.slice(indexOfUpdatedPermission + 1)
		]
		return {...state,
			 [`${action.repoId}`]:updatedPermissionList
			 }			 
		
	case 'DELETE_PERMISSION_SUCCESS':				
	case 'PERMISSION_REMOVED_PUSHER':	
		let permissions=state[`${action.repoId}`]
		let userId
		let originalEmail
		if(action.requestData){
			userId=action.requestData.user
			originalEmail=action.requestData.originalEmail
		}else{
			userId=action.pusher.userId
			originalEmail=action.pusher.originalEmail
		}

		var indexOfDeletedPermission = findIndex(permissions,permission => {
			if(permission.user){
				return permission.user.id === userId
			}
			else{
				return permission.originalEmail === originalEmail
			}
		})

		if(indexOfDeletedPermission !==-1){
			let newPermissions=[
				...permissions.slice(0,indexOfDeletedPermission),
				...permissions.slice(indexOfDeletedPermission + 1)
			]
			return {...state,
				 [`${action.repoId}`]:newPermissions
				 }		
		}else return state


	case 'LOGOUT':
		return {}
	
	default:
		return state
	
	}
}

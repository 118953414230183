export function detectBrowser() {
	let browser
	var ua = navigator.userAgent.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i)
	if (navigator.userAgent.match(/Edge/i) || navigator.userAgent.match(/Edg/i) || navigator.userAgent.match(/Trident.*rv[ :]*11\./i)) {
		browser = 'edge'
	}
	else {
		browser = ua[1].toLowerCase()
	}
	return browser
}



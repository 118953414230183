import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view"
import symbols from '../../../staticData/symbols.js'
import {getNewState} from './utils/getNewState'
import {getSuggestions} from './utils/getSuggestions'
import {getSuggestionsHTML} from './utils/getSuggestionsHTML'
import {getMatch} from './utils/getMatch'
import {getFormattedRecentSymbols} from '../../utils/getFormattedRecentSymbols'
import schema from '../../schema/editorSchema'
import {updateRecentSymbols} from '../../utils/updateRecentSymbols'
import {getSlideWidthFromQuestionNode} from '../../utils/getSlideWidthFromQuestionNode'


//https://github.com/joelewis/prosemirror-mentions

const triggerRegex = new RegExp("[\\\\/]([\\w-\\+]*)$") //trigger with \ and /
const suggestionTextClass="prosemirror-suggestion"
const activeClass='suggestion-item--active'

export function symbolsSuggestionPlugin() {
	let el = document.createElement("div")//dropdown element
	const showList = function(view, state, suggestions) {
		const slideWidth=getSlideWidthFromQuestionNode()
		el.innerHTML = getSuggestionsHTML(suggestions,hideList)
		el.addEventListener("mousedown", function(e) { //prevent clicking defocusing selection item
			e.preventDefault()
			e.stopPropagation()
		})

		el.querySelectorAll(".suggestion-item").forEach(function(itemNode, index) {
			itemNode.addEventListener("click", function() {
				select(view, state)
				view.focus()
			})
			 itemNode.addEventListener("mousemove", function() {
				if(!state.mouseHasMoved){
					 state.mouseHasMoved=true
					 setIndex(index, state)
				} 
			})
			itemNode.addEventListener("mouseover", function() {
				if(state.mouseHasMoved){
				 setIndex(index, state)
				}
			})
			itemNode.addEventListener("mouseout", function() {
				setIndex(index, state)
			})
			itemNode.addEventListener("mousedown", function(e) {
				//e.preventDefault()
			})
		})
	 
 
		addClassAtIndex(state.index, activeClass)// highlight first element by default - like Facebook.

		var node = view.domAtPos(view.state.selection.$from.pos)
		var paraDOM = node.node
		var textDOM = paraDOM.querySelector("." + suggestionTextClass)
		var offset = textDOM.getBoundingClientRect()

		document.getElementById("app-editor").appendChild(el)

		el.style.position = "fixed";
		el.style.left = offset.left + "px";

		// VERTICAL OFFSET
		// ABOVE OR BELOW

		let dropdownPosition

		if(offset.top > (window.innerHeight / 2)){
			dropdownPosition = "above"
		}
		else{
			dropdownPosition = "below"
		}

		const slideScale = slideWidth / 1280

		// from 900 to 600, decrease a larger amount

		if(dropdownPosition === "above"){
			var bottom = window.innerHeight - offset.top + 4 // buffer get to the top, then add a bit of buffer      
			el.style.bottom = bottom + "px";
		}

		if(dropdownPosition === "below") {       
			var top = (textDOM.offsetHeight * slideScale) + offset.top + 2 // get height of text line then buffer      
			el.style.top = top + "px";
		}

		el.style.display = "block"

	}

	const hideList = function() {
		el.style.display = "none"
		el.style.bottom = null
		el.style.top = null
	}

	const removeClassAtIndex = function(index, className) {
		const itemList = el.querySelector(".suggestion-item-list").childNodes
		const prevItem = itemList[index]
		if(prevItem){
			prevItem.classList.remove(className)
		}
	};

	const addClassAtIndex = function(index, className) {
		const itemList = el.querySelector(".suggestion-item-list").childNodes
		const prevItem = itemList[index]
		prevItem.classList.add(className)
	};

	const setIndex = function(index, state) {
		removeClassAtIndex(state.index, activeClass)
		state.index = index
		addClassAtIndex(state.index,activeClass)
	};

	const goNext = function(view, state) {
		removeClassAtIndex(state.index, activeClass)
		state.index++
		state.index = state.index === state.suggestions.length ? 0 : state.index
		addClassAtIndex(state.index, activeClass)
	}

	const goPrev = function(view, state) {
		removeClassAtIndex(state.index, activeClass)
		state.index--
		state.index =
			state.index === -1 ? state.suggestions.length - 1 : state.index
		addClassAtIndex(state.index, activeClass)
	}

	const select = function(view, state) {
		const item = state.suggestions[state.index]
		let text=item.string
		updateRecentSymbols(item.escapedString)
		if(window.analytics){
			window.analytics.track('Insert symbol', {
				fromSymbolsDropdown:false,
				fromSuggestionsPlugin:true,
				symbolName:item.name,
				symbol:item.string
			})
		}

		if(item.spaceAfter){
			text=`${item.string}\u00a0`
		}
		const node = view.state.schema.text(text)
		let tr = view.state.tr
		tr.replaceWith(state.range.from, state.range.to, node)
		
		let marksToAdd=[]
		window.view.state.doc.nodesBetween(state.range.from, state.range.to, (node, position) => {
				if (!node.isText){
					return;
				} 
				node.marks.forEach((mark)=>{
					const obj={
						name:mark.type.name,
					}
					 marksToAdd.push(obj)
				})
			})

		
		marksToAdd.forEach((mark,i)=>{
			tr.addMark(state.range.from,state.range.to,schema.marks[mark.name].create())
		})
		view.dispatch(tr)



	}

	return new Plugin({
		key: new PluginKey("symbolSuggestions"),
		state: {
			init() {
				return getNewState();
			},
			apply(tr, state) {
				// compute state.active for current transaction and return
				var newState = getNewState()
				var selection = tr.selection
				if (selection.from !== selection.to) {
					return newState
				}
				const $position = selection.$from;
				const match = getMatch($position,triggerRegex)
				if(!state.active && match){
					const recentSymbols=getFormattedRecentSymbols()
					newState.recentSymbols = recentSymbols
				}else{
					 newState.recentSymbols = state.recentSymbols
				}
				if (match) {// if match found update state       
					newState.active = true;
					newState.range = match.range;
					newState.text = match.queryText;

				}
				return newState
			}
		},
		props: {
			handleKeyDown(view, e) {
				var state = this.getState(view.state)
				if (!state.active || !state.suggestions.length) { // don't handle if no suggestions or not in active mode
					return false
				}
				if (e.keyCode === 40) {
					goNext(view, state)
					return true
				} else if (e.keyCode === 38) {
					goPrev(view, state)
					return true
				} else if ( e.keyCode === 13) {
					select(view, state)
					return true
				} else if (e.keyCode === 27) {
					hideList()
					this.state = getNewState()
					return true
				} else {
					return false
				}
			},     
			decorations(editorState) {//to decorate the currently active suggestion text in ui (and used for positioning dropdown)
				const { active, range } = this.getState(editorState)
				if (!active) return null
				return DecorationSet.create(editorState.doc, [
					Decoration.inline(range.from, range.to, {
						nodeName: "span",
						class: suggestionTextClass
					})
				])
			}
		},
		view() {// To track down state mutations and update dropdown
			return {
				update: view => {
					const state = this.key.getState(view.state)
					let focused=false
					if(window.view){
						focused=window.view.hasFocus()
				 }
					if (!state.active || !focused){
						hideList()
					}else{
					//  text,suggestions,isDropdown,done
						getSuggestions(state.text, symbols,state.recentSymbols,false, function(suggestions) {
							var node = view.domAtPos(view.state.selection.$from.pos)
							var paraDOM = node.node
							var textDOM = paraDOM.querySelector("." + suggestionTextClass)
							var offset = textDOM.getBoundingClientRect()

							let orderedSuggestions=suggestions
							let index=0

							// We need to reverse the order when above
							// We also need to remove number of suggestions for small screens sizes
							 
							let heightSuggestions = 266
							let heightTopEditorBars = 74
							
							if(offset.top > (window.innerHeight / 2)){ //if above 
								if(window.innerHeight < 620 || offset.top < (heightSuggestions + heightTopEditorBars + 10)){
									orderedSuggestions=suggestions.reverse().slice(0,6) // reverse and slice
									index=orderedSuggestions.length-1                  
								} 
								else{ // reverse
									orderedSuggestions=suggestions.reverse()
									index=suggestions.length-1
								}
							}
							else{
								if(window.innerHeight < 620){ // below
									orderedSuggestions=suggestions.slice(0,6) // slice if small screen
									index=orderedSuggestions.length-1                 
								}
							}

							 state.suggestions = orderedSuggestions
							 state.index=index


								if(suggestions.length){
									 showList(view, state, orderedSuggestions)
								 }else{
									hideList()
								 }
							})
					}
				}
			};
		}
	});
}
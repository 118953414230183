import React from 'react'

class CreatorProgrammeModalVisual extends React.Component{

	constructor(props){
		super(props)
		this.launchRocket=this.launchRocket.bind(this)
		this.generateStars=this.generateStars.bind(this)
		
		this.state={
			flying: null,
			start: null,
			counting: null,
			countdown: 3,
			space: {},
		}	   
	} 



	componentDidMount(){
		this.generateStars()
	}

	componentWillReceiveProps(nextProps){
		if(this.props.stage !=='success' && nextProps.stage==='success'){
			this.launchRocket()
		}
	}

	launchRocket(){
		this.setState({flying:true})
	}



	generateStars(){
        const field = 2
        const distanceLayer = 3
        const galaxy = document.getElementsByClassName('galaxy')[0]
      	let space={}

        for (let i=0; i<distanceLayer; i++){
        	space[`field${i}`]={} 
         
          for (let j=0; j<field; j++){
          	space[`field${i}`][`distance-layer${j}`]=[]
          
            for (let k=0; k<10; k++){
              const star = {
              //  "left": galaxy.clientWidth * Math.random(),
               // "top": galaxy.clientHeight / 2 * Math.random()
               	"left": galaxy.clientWidth * Math.random(),
                "top": galaxy.clientHeight / 2 * Math.random()
              };
              space[`field${i}`][`distance-layer${j}`].push(star)
            }
          }
        }
      this.setState({space:space})
    }



	render(){	
		
		const {flying,space} = this.state

		return(
			<div className='joinCreatorProgramModal-visualContainer'>
								
				  <div className={'rocket-wrap' + (flying ? ' fly':'')}>
				    <div className="galaxy">
				    	
				    	{Object.keys(space).map((key)=>{
				    		const field=space[key]
				    		return(
					    		<div className={`field ${key}`} key={key}>
					    			{Object.keys(field).map((i)=>{		
					    				const distanceLayer=field[i]	    				
					    				return(
					       				 <div className={`distance-layer ${i}`} key={i}>
					       				 		{distanceLayer.map((star,index)=>{
					       				 			
					       				 			return(
					         						 <div key={index} className="star" style={{top: `${star.top}px`, left: `${star.left}px`}} />
					        						)})}
					   
					        			</div>
					        		)
					        	})}
					      </div>
					      )
				  		})}

		
				    	
				      <div className="moon">
					      <div className="crater" />
					      <div className="crater-2" />
					      <div className="crater-3" />
				      </div>
				      <div className="earth" />
				      <div className="mars" />
				    </div>

				    

				    <div className="rocket">
				      <div className="exhaust-flame" />
				      <div className="rocket-body">
				        <div className="body" />
				        <div className="fin fin-left" />
				        <div className="fin fin-right" />				       
				        <div className="window" />
				      </div>
				    </div>

				    <ul className="exhaust-fumes">
				      <li></li>
				      <li></li>
				      <li></li>
				      <li></li>
				      <li></li>
				      <li></li>
				      <li></li>
				      <li></li>
				      <li></li>
				    </ul>
				  </div>

			</div>
			
		)	
	}
				
}




export default CreatorProgrammeModalVisual



import $script from 'scriptjs'

export function ensureRecaptcha(callback, onError) {
  $script.ready(
    'recaptcha',
    () => {
      // script is ready or load failed
      if (window.grecaptcha?.ready) {
        window.grecaptcha.ready(callback)
      } else {
        onError && onError()
      }
    },
    () => {
      // haven't initiated script load, load it now
      $script(
        'https://www.google.com/recaptcha/api.js',
        'recaptcha',
      )
    },
  )
}

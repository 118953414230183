export function getLibrarySettings() {	

	//Defaults
	let librarySettings={
		sortType:'name',
		sortOrder:'ascending',
	}

	const librarySettingsFromLocalStorage = JSON.parse(localStorage.getItem('librarySettings'))

	if(librarySettingsFromLocalStorage ){
		if(librarySettingsFromLocalStorage.sortType){
			librarySettings.sortType=librarySettingsFromLocalStorage.sortType
		}
		if(librarySettingsFromLocalStorage.sortOrder){
			librarySettings.sortOrder=librarySettingsFromLocalStorage.sortOrder
		
		}

	}

	return librarySettings

}

 
import React from 'react'
import AccountSubsection from './AccountSubsection'
import AccountButton from './AccountButton'
import moment from 'moment'

//August 19, 2018
function formatUnixTimestamp(timestamp){
	return moment.unix(timestamp).format('MMMM DD, YYYY')
}
//August 19, 2018
function formatTimestamp(timestamp){
	return moment(timestamp).format('MMMM DD, YYYY')
}


class AccountServiceStatusPaymentInfo extends React.Component{
 
	render(){	
		const {billingInfo,showUpdatePaymentInfoModal}=this.props
		const billingInfoBrand = billingInfo? billingInfo.brand:null
		const billingInfoLast4 =billingInfo? billingInfo.last4:null
		if(billingInfo){
			return(
				<React.Fragment>
					{`${billingInfoBrand} •••• •••• •••• ${billingInfoLast4}`}
					<div className='account-serviceStatus-sub'>
						{this.props.currentSubscriptionPrice}/{this.props.currentSubscriptionBillingPeriod}
					</div>							
					<AccountButton label='Update Payment Method' onClickFunction={showUpdatePaymentInfoModal} />							
				</React.Fragment>
			)
		}else return null
	}
				
}




class AccountServiceStatus extends React.Component{

	showUpgradeModal(){
		window.location.hash='upgrade'
	}

	render(){	
		const { showDowngradePlanModal,accountServiceStatus,service,upcomingInvoice,billingInfo} = this.props

		// ACTIVE 
		const nextPaymentDate = upcomingInvoice ?formatUnixTimestamp(upcomingInvoice.date):null
		const currentSubscriptionPrice = upcomingInvoice ?`$${(upcomingInvoice.amount_due /100).toFixed(2)}`:null
		const currentSubscriptionBillingPeriod = upcomingInvoice ? upcomingInvoice.lines.data[0].plan.id===process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID ? 'month':'year' :null
 

		// IN TRIAL
		const trialEndDate = formatTimestamp(service.paidUntil)

		// CANCELLED
		const downgradeDate = formatTimestamp(service.paidUntil)

		// ACTIVE OR CANCELLED
		const hasBillingHistory = true

		// PASS
		const passProvidedBy = service.providedBy

		return(
				
			<React.Fragment>

				{accountServiceStatus === 'free' &&
					<AccountSubsection label='Plan' >
						Plickers Free
						<div className='account-subsection-actionRow'>
							<AccountButton label='Upgrade to Pro' onClickFunction={this.showUpgradeModal} />
							<AccountButton label='Add License Key' onClickFunction={this.props.showAddLicenseKeyModal} />
							{billingInfo &&
								<AccountButton label='View Billing History' onClickFunction={this.props.showBillingInfoModal} />
							}
						</div>
					</AccountSubsection>
				}
				
				{accountServiceStatus === 'active' &&
					<div className='account-subsectionContainer--twoColumn'>
						<AccountSubsection twoColumn label='Plan' >
							Plickers Pro 
							<div className='account-serviceStatus-sub'>
								Next payment: {nextPaymentDate}
							</div>
							{hasBillingHistory && 
								<AccountButton label='View Billing History' onClickFunction={this.props.showBillingInfoModal} />
							}
							<AccountButton label='Add License Key' onClickFunction={this.props.showAddLicenseKeyModal} />
							<AccountButton label='Downgrade to Free Plan' onClickFunction={showDowngradePlanModal} />													
						</AccountSubsection>
						
						<AccountSubsection twoColumn label='Payment Info'>
							<AccountServiceStatusPaymentInfo
								billingInfo={billingInfo}
								currentSubscriptionPrice={currentSubscriptionPrice}
								currentSubscriptionBillingPeriod={currentSubscriptionBillingPeriod}
								showUpdatePaymentInfoModal={this.props.showUpdatePaymentInfoModal}
							/>			
							<AccountButton label='Change Billing Period' onClickFunction={this.props.showSwitchPlanModal} />			
						</AccountSubsection>
					</div>
				}

				{accountServiceStatus === 'canceled' &&
					<AccountSubsection label='Plan' >
						Plickers Pro <div className='account-serviceStatus-pill account-serviceStatus-pill--cancelled'>Cancelled</div>
						<div className='account-serviceStatus-sub'>
							You will lose access to Plickers Pro on {downgradeDate}
						</div>
						<div className='account-subsection-actionRow'>
							{hasBillingHistory && 
								<AccountButton label='View Billing History' onClickFunction={this.props.showBillingInfoModal} />
							}
							<AccountButton label='Add License Key' onClickFunction={this.props.showAddLicenseKeyModal} />
						</div>
					</AccountSubsection>
				}

				{accountServiceStatus === 'trial' &&
					<div className='account-subsectionContainer--twoColumn'>
						<AccountSubsection label='Plan' >
							Plickers Pro <div className='account-serviceStatus-pill account-serviceStatus-pill--trial'>Trial</div>
							<div className='account-serviceStatus-sub'>
								Trial ends: {trialEndDate}
							</div>
							<AccountButton label='Add License Key' onClickFunction={this.props.showAddLicenseKeyModal} />
							<AccountButton label='Cancel Trial' onClickFunction={showDowngradePlanModal} />							
						</AccountSubsection>
						<AccountSubsection twoColumn label='Payment Info'>
							<AccountServiceStatusPaymentInfo
								billingInfo={billingInfo}
								currentSubscriptionPrice={currentSubscriptionPrice}
								currentSubscriptionBillingPeriod={currentSubscriptionBillingPeriod}
								showUpdatePaymentInfoModal={this.props.showUpdatePaymentInfoModal}
							/>
							<AccountButton label='Change Billing Period' onClickFunction={this.props.showSwitchPlanModal} />											
						</AccountSubsection>
					</div>
				}

				{accountServiceStatus === 'pass' &&
					<AccountSubsection label='Plan' >
						Plickers Pro
						<div className='account-serviceStatus-sub'>
							Your subscription is provided by <span className='account-serviceStatus--pass-providedBy'>{passProvidedBy}</span>.
						</div>
						<AccountButton label='Add License Key' subtle onClickFunction={this.props.showAddLicenseKeyModal} />
					</AccountSubsection>
				}


			</React.Fragment>
		)
	}
				
}


export default AccountServiceStatus


import React, { Component} from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import {showLiveViewNotification,hideLiveViewNotification} from '../../actions/liveViewNotification'
import {showLiveViewModal} from '../../utils/liveView/showLiveViewModal'
import LiveViewNotification from '../../components/liveView/LiveViewNotification'
import find from 'lodash/find'

//if user starts playing something from mobile (control.mobileAccepted) we show a notification on web
//notification has a countdown, after the countdown finishes auto navigates to NP

const isPageWithNotification = (location) => { 
	if(//don't show notification if user is on one of these routes
		location.pathname !=='/nowplaying' &&
		location.pathname!=='/nowplaying/' &&
		location.hash!=='#now-playing' &&
		location.pathname !=='/scan-helper' 
	)return true
	else return false
}

class LiveViewNotificationContainer extends Component {
	constructor() {
		super()		
		this.showLiveViewModal=this.showLiveViewModal.bind(this)
		this.startTimer = this.startTimer.bind(this)
		this.countDown = this.countDown.bind(this)
		this.hideLiveViewNotification = this.hideLiveViewNotification.bind(this)
		this.timer = 0
		this.state = { 
			activeCountdownSeconds: 5
		}
	}
 
	UNSAFE_componentWillReceiveProps(nextProps) {
		if(this.props.control.controlled && this.props.control.controlled !== nextProps.control.controlled){
			if((!this.props.control.setPoll && !this.props.control.currentPoll) && (nextProps.control.setPoll || nextProps.control.currentPoll)){
				if(isPageWithNotification(this.props.history.location)){
					if(nextProps.control.mobileAccepted){
						this.props.showLiveViewNotification()
						this.startTimer()
					}
				}
			}
			if(this.props.hasLiveViewNotification && !nextProps.control.setPoll && !nextProps.control.currentPoll){
				this.hideLiveViewNotification()
			} 
		}
	}

	shouldComponentUpdate(nextProps,nextState){
		if(nextProps.control!==this.props.control|| nextProps.hasLiveViewNotification!==this.props.hasLiveViewNotification|| nextState !==this.state){
			return true
		}else return false
	}

	startTimer() {
		if (this.timer === 0) {
			this.timer = setInterval(this.countDown, 1000)
		}
	}

	countDown() {
		let activeCountdownSeconds = this.state.activeCountdownSeconds - 1
		this.setState({
			activeCountdownSeconds: activeCountdownSeconds,
		})
		if (activeCountdownSeconds === 0) { 
			clearInterval(this.timer)
			this.timer=0
			this.props.hideLiveViewNotification()
			this.setState({activeCountdownSeconds:5})
			showLiveViewModal()
		}
	}

	showLiveViewModal(){
		this.props.hideLiveViewNotification()
		showLiveViewModal()
	}

	hideLiveViewNotification(){
		this.props.hideLiveViewNotification()
		clearInterval(this.timer)
		this.timer=0
		this.setState({activeCountdownSeconds:5})
	}

	
	render() {
		const {control,section,hasLiveViewNotification}=this.props
		if(hasLiveViewNotification){
			return (        												  
				<LiveViewNotification 
					hideLiveViewNotification={this.hideLiveViewNotification}
					showLiveViewModal={this.showLiveViewModal}
					activeCountdownSeconds={this.state.activeCountdownSeconds}
					control={control}
					section={section}
				/>   				
			)
		}else return null
	}
}


function mapStateToProps(state) {
	const control=state.control
	let section=null
	if(state.control){
		section=find(state.sections, {'id':state.control.section})
	}
	return {
		 control:control,
		 section:section,
		 hasLiveViewNotification:state.hasLiveViewNotification,
	}
}

export default withRouter(connect(
	mapStateToProps,
	{ showLiveViewNotification,hideLiveViewNotification}
)(LiveViewNotificationContainer))
import React from 'react'
import slideLayoutDefinitions from '../../../../staticData/slideLayoutDefinitions.js'

const SERVED_IMAGE_MAX_DIMENSION = 900

class StaticSlideAnimatedGIF extends React.Component{
	
	constructor(props){
		super(props)
		this.onLoad=this.onLoad.bind(this)
	}

	onLoad(){
		if(this.props.questionMediaPromiseResolve){
			console.log('question GIF still onLoad')
			this.props.questionMediaPromiseResolve()
		}
	}

	render(){
		const {gif, slideTemplate, gifFillType,questionMediaDynamicHeight, fullyStatic} = this.props
		const displayImageWidth = gif.dimensions.w
		const displayImageHeight = gif.dimensions.h
		let imageContainerWidth = slideLayoutDefinitions.bodyMediaContainerDimensions[`${slideTemplate}`].width
		let imageContainerHeight = slideLayoutDefinitions.bodyMediaContainerDimensions[`${slideTemplate}`].height

		if(slideTemplate === 'bodyCenterMediaCenter'){
			imageContainerHeight = questionMediaDynamicHeight // pass in
		}
		
		const displayImageAspect = displayImageWidth / displayImageHeight
		const containerAspect = imageContainerWidth / imageContainerHeight

		let fitImageWidth
		let fitImageHeight 
		let servedImageWidth
		let servedImageHeight

		let maxServedImageDimensionLength = SERVED_IMAGE_MAX_DIMENSION
		if(displayImageAspect > containerAspect){//landscape
			fitImageWidth = imageContainerWidth
			fitImageHeight = imageContainerWidth / displayImageAspect
		}
		if(displayImageAspect < containerAspect){//portrait
			fitImageHeight = imageContainerHeight
			fitImageWidth = imageContainerHeight * displayImageAspect
		}
		if(displayImageAspect > 1){
			servedImageWidth = maxServedImageDimensionLength
			servedImageHeight = servedImageWidth / displayImageAspect
		}
		if(displayImageAspect < 1){//portrait
			servedImageHeight = maxServedImageDimensionLength
			servedImageWidth = servedImageHeight * displayImageAspect
		}

		let fillImageWidth
		let fillImageHeight
		if(displayImageAspect > containerAspect){//landscape
			fillImageHeight = imageContainerHeight
			fillImageWidth = imageContainerHeight * displayImageAspect
		}
		if(displayImageAspect < containerAspect){ //portrait
			fillImageWidth = imageContainerWidth
			fillImageHeight = imageContainerWidth / displayImageAspect
		}

		let imageWidth
		let imageHeight 

		if(gifFillType === 'fit'){
			imageWidth = fitImageWidth
			imageHeight = fitImageHeight
		}
		if(gifFillType === 'fill'){
			imageWidth = fillImageWidth
			imageHeight = fillImageHeight
		}

		//
		// 

		let coverWidth
		let coverHeight   

		if(gifFillType === 'fit'){
			coverWidth = fitImageWidth
			coverHeight = fitImageHeight
		}

		if(gifFillType === 'fill'){
			coverWidth = imageContainerWidth
			coverHeight = imageContainerHeight
		}
	 
		// POSITIONING WITHIN CONTAINER

		let imageLayoutStyle

		if(slideTemplate === 'bodyLeftMediaRight'){
			imageLayoutStyle = {top: '0px', right: '0px'}
		}
		if(slideTemplate === 'bodyRightMediaLeft'){
			imageLayoutStyle = {top: '0px', left: '0px'}
		}
		if(slideTemplate === 'bodyCenterMediaCenter'){
			imageLayoutStyle = {top: '0px', margin: 'auto', left: '0px', right:'0px'}
		}

		const {stillUrl,webpUrl,gifUrl} = this.props.gif		
		return(											
			<div style={{width: `${coverWidth}px`, height: `${coverHeight}px`, ...imageLayoutStyle}}  className={'slide-media--gifCover ' + (gifFillType ? `slide-media--gifCover--${gifFillType}` :'')}>            
				<div style={{width: `${imageWidth}px`, height: `${imageHeight}px`}} className='slide-media--gifContainer '>                                         
					{!fullyStatic &&
						<picture className='slide-media--gif'> 
							<source type="image/webp" srcSet={webpUrl} />
							<img src={gifUrl} alt=''/>           
						</picture>
					}
					{fullyStatic &&
						<picture className='slide-media--gif'>                   
							<img onLoad={this.onLoad} src={stillUrl} alt=''/>            
						</picture>                  
					}
				</div>            
			</div>                  					
		)		
	}	
}

export default StaticSlideAnimatedGIF
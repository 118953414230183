import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import find from 'lodash/find'
import { connect } from 'react-redux' 
import RepoTrashContainer from './RepoTrashContainer'
import {fetchAPublishedRepoByShortId} from '../../actions/repos'


//signature is repodescription-shortId
function repoShortIdFromSignature(signature){
	const signatureComponentsArray=signature.split(/-/)
	const shortId=signatureComponentsArray[signatureComponentsArray.length-1]
	return shortId

}


 
 
class PublishedRepoTrashContainer extends Component {
 
	UNSAFE_componentWillMount() {
		if(!this.props.repo){
			this.props.fetchAPublishedRepoByShortId(this.props.repoShortId)
		}
	}

	


	render() {
		if(this.props.repoId){
			return <RepoTrashContainer repoId={this.props.repoId}/>
		}else return null

	}
}




function mapStateToProps(state,ownProps) {
	
	const repoSignature=ownProps.match.params.repoSignature
	const repoShortId=repoShortIdFromSignature(repoSignature)


	const currentRepo=find(state.repos, {'shortId':parseInt(repoShortId,10)})
	let repoId
	if(currentRepo){
		repoId=currentRepo.id
	}
	return {
	    repo:currentRepo,
	    repoId:repoId,
	    repoShortId:repoShortId
	  }
}
 


export default withRouter(connect(
	mapStateToProps,
	{fetchAPublishedRepoByShortId })(PublishedRepoTrashContainer))

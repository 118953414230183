export function analyticsTrackStartScan(nowPlayingMode,control) {
	if(window.analytics){
		let testGroup=null
		const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
		if(setLimitTestGroup){
			testGroup=setLimitTestGroup
		}

		const snapshot=control.snapshot
		if(snapshot){
			let hasPromptMedia=false
			let promptMediaType=null
			if(snapshot.media){
				hasPromptMedia=true
				promptMediaType=snapshot.media.type
			}
			let isMediaChoices=false
			let choiceMediaType=null
			if(snapshot.layout ==='bodyLeftMediaRightChoicesMedia' || snapshot.layout==='bodyCenterChoicesMedia'){
				isMediaChoices=true
				snapshot.choices.forEach((choice)=>{
					if(choice.media){
						if(choiceMediaType===null){
							choiceMediaType=choice.media.type
						}else{
							if(choiceMediaType!==choice.media.type){
								choiceMediaType='mixed'
							}
						}
					}
				})

			}
			let itemType='question'
			if(control.setPoll){
				itemType='set'
			}

			window.analytics.track('NP start scan',{
				nowPlayingMode:nowPlayingMode,
				hasPromptMedia:hasPromptMedia,
				promptMediaType:promptMediaType,
				isMediaChoices:isMediaChoices,
				choiceMediaType:choiceMediaType,
				itemType:itemType,
				windowDimensions:`${window.innerWidth} x ${window.innerHeight}`,
				'test_group_set_limit':testGroup})
			}

		}

		
	}






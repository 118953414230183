import React from 'react'
import Icon from '../misc/Icons'
import StaticQuestionSlide from '../slides/StaticQuestionSlide'

class FloatingNPControl extends React.Component{	

	render(){	

		const {section,showLiveViewModal,stopLiveView,control}=this.props
		
		return(
			<div className='floatingNPControl'>
				<div className='floatingNPControl-header'>
					{section && 
						<React.Fragment>
							<div className='floatingNPControl-header-expandLabel'>							
								Open Now Playing
							</div>
							<button onClick={stopLiveView} className='floatingNPControl-header-closeButton'>							
								<Icon name="close" />
							</button>
						</React.Fragment>
					}

				</div>				        

		 		<div className='floatingNPControl-questionPreview' onClick={showLiveViewModal}>		       	
		        {control.snapshot &&
		          <StaticQuestionSlide question={control.snapshot} stage='ask' mode='view' displayWidth={366} />       
		        }	     
				</div>
			</div>
		)
	}				
}

export default FloatingNPControl
import React, { Component} from 'react'
import ModalWrapper from './ModalWrapper'
import StaticQuestionSlide from '../slides/StaticQuestionSlide'


class ExpandedReviewSlideModal extends Component {
	
	

	render() {	
		const {poll,processedResponses}=this.props
		return (
			<div className='expandedReviewSlideModal'>
				<ModalWrapper
					{...this.props}
					//title='Expanded review slide'
					modalStyle='light'
					cancelBtn={false}
				>
	
					<StaticQuestionSlide 
						question={poll.snapshot} 						
						reportView
						totalResponses={poll.unassignedResponseCount+poll.assignedResponseCount}
						displayWidth={960}						
						processedResponses={processedResponses}					
						mediaIsPlayable
					/>

				</ModalWrapper>
			</div>
		)
	}


	
}

export default ExpandedReviewSlideModal
import React from 'react'
import filter from 'lodash/filter'
import Measure from 'react-measure'
import SyncLiveViewToolbar from './SyncLiveViewToolbar'
import SyncLiveViewPlayerSummary from './SyncLiveViewPlayerSummary'
import SyncLiveViewPlayerControlBar from './SyncLiveViewPlayerControlBar'
import SyncLiveViewPlayerStudents from './SyncLiveViewPlayerStudents'
import SyncLiveViewSetReport from './SyncLiveViewSetReport'
import StaticQuestionSlide from '../../slides/StaticQuestionSlide'
import { processQuestionResponses } from '../../../utils/processQuestionResponses'
import SyncLiveViewPlayerProgressBar from './SyncLiveViewPlayerProgressBar'

// E-Learning Page
class SyncLiveViewPage extends React.Component {
  constructor() {
    super()
    this.sizeChanged = this.sizeChanged.bind(this)
    this.state = {

    }
  }

  sizeChanged(dimensions) {
    const { height } = dimensions.dimensions
    const { width } = dimensions.dimensions
    let slideWidth
    if (width && height) {
      if (width / 1.6 > height) {
        slideWidth = height * 1.6
      } else {
        slideWidth = width
      }
    }
    this.setState({ slideWidth })
    this.setState({ slideContainerWidth: width })
  }

  render() {
    const {
      control, currentPollId, currentSetPollId, currentSetPollPollId, questionStage, showGraph, revealAnswer, poll, currentSection, isSurvey, isSet, classSafeMode,
    } = this.props
    let processedResponses
    if (poll && this.props.currentSection && poll.hasResponses) {
      processedResponses = processQuestionResponses(poll, currentSection)
    }
    const isReviewOrScan = (questionStage === 'review' || questionStage === 'scan')
    let students = []
    if (currentSection) {
      students = filter(currentSection.students, { archived: false })
    }

    const totalStudentCount = students.length

    let onlineStudentCount = 0
    if (this.props.onlineStudents) {
      onlineStudentCount = Object.keys(this.props.onlineStudents).length
    }
    const defaultControlBarWidth = 700
    let narrowControlBarView

    if (this.state.slideContainerWidth < defaultControlBarWidth) {
      narrowControlBarView = true
    }

    const { snapshot } = this.props

    const countdownEndsAt = control ? control.countdownEndsAt : null

    let key = ''
    if (currentPollId) {
      key = currentPollId
    } else if (currentSetPollId && currentSetPollPollId) {
      key = `${currentSetPollId}_${currentSetPollPollId}`
    }
    return (
      <div className={`syncLiveView ${classSafeMode ? 'syncLiveView--classSafeMode' : 'syncLiveView--defaultMode'}${questionStage ? ` syncLiveView--${questionStage} ` : ''}${(totalStudentCount > 47) ? ' syncLiveView--over47Students ' : ''}`}>

        <SyncLiveViewToolbar
          isSet={isSet}
          classSafeMode={this.props.classSafeMode}
          toggleClassSafeMode={this.props.toggleClassSafeMode}
          setPoll={this.props.setPoll}
          currentQuestionIndex={this.props.currentQuestionIndex}
          questionsAndPollsList={this.props.questionsAndPollsList}
          slideWidth={this.state.slideWidth}
          minimiseLiveView={this.props.minimiseLiveView}
          stopLiveView={this.props.stopLiveView}
          questionStage={questionStage}
          poll={poll}
          isSurvey={isSurvey}
          currentSection={this.props.currentSection}
          countdownEndsAt={countdownEndsAt}
          handleCountdownEnd={this.props.handleCountdownEnd}
          setCountdownSeconds={this.props.setCountdownSeconds}
          countDownTimerStartSecondsLength={this.props.countDownTimerStartSecondsLength}
          incrementCountdown={this.props.incrementCountdown}
          cancelCountdown={this.props.cancelCountdown}
          switchNowPlayingMode={this.props.switchNowPlayingMode}
          nowPlayingMode={this.props.nowPlayingMode}
          onlineStudentsCount={this.props.onlineStudentsCount}
        />

        <div className='syncLiveView-player'>
          {!classSafeMode && isSet && (
          <SyncLiveViewPlayerSummary
            currentSection={this.props.currentSection}
            setPoll={this.props.setPoll}
            currentQuestionIndex={this.props.currentQuestionIndex}
            questionsAndPollsList={this.props.questionsAndPollsList}
            questionStage={questionStage}
            sectionDataOwner={this.props.currentSectionDataOwner}
          />
          )}

          <div className='syncLiveView-player-main'>

            <Measure onMeasure={(dimensions) => { this.sizeChanged({ dimensions }) }}>
              <div className='syncLiveView-player-main-slideContainer'>
                <div className='syncLiveView-player-main-slide' style={{ width: `${this.state.slideWidth}px`, height: `calc(${this.state.slideWidth / 1.6}px)` }}>
                  {this.props.snapshot && (
                  <StaticQuestionSlide
                    key={key}
                    // question={{...snapshot,xLayout:null}}
                    mediaIsPlayable
                    question={snapshot}
                    displayWidth={this.state.slideWidth}
                    isLiveView
                    isAskScreen={questionStage === 'ask'}
                    isScanScreen={questionStage === 'scan'}
                    isReviewScreen={questionStage === 'review'}
                    reportView={classSafeMode ? false : (questionStage === 'scan' || questionStage === 'review')}
                    isSurvey={isSurvey}
                    showGraph={classSafeMode ? showGraph : (questionStage === 'scan' || showGraph)}
                    showCorrect={classSafeMode ? revealAnswer : revealAnswer}
                    showResponseCounts={classSafeMode ? false : (questionStage === 'scan' || questionStage === 'review')}
                    processedResponses={isReviewOrScan ? processedResponses : null}
                    totalResponses={(isReviewOrScan && poll) ? (poll.assignedResponseCount + poll.unassignedResponseCount) : 0}
                    expectedResponses={(isReviewOrScan && poll) ? (students.length + poll.unassignedResponseCount) : 0}
                    colorScheme={classSafeMode ? 'scheme1' : null}
                    onMouseEnterChoice={this.props.onMouseEnterChoice}
                    onMouseEnterChoicesGroup={this.props.onMouseEnterChoicesGroup}
                    onMouseLeaveChoicesGroup={this.props.onMouseLeaveChoicesGroup}
                    choicesGroupHovered={this.props.choicesGroupHovered}
                    hideSoundTitles={classSafeMode}
                  />
                  )}
                </div>

                <div style={{ top: `calc(${this.state.slideWidth / 1.6}px + 5px)`, width: `${this.state.slideWidth}px` }} className='syncLiveView-player-main-slideContainer-progressBarContainer'>
                  <SyncLiveViewPlayerProgressBar
                    questionsAndPollsList={this.props.questionsAndPollsList}
                    currentQuestionIndex={this.props.currentQuestionIndex}
                    playQuestionInSet={this.props.playQuestionInSet}
                    currentSection={this.props.currentSection}
                    questionStage={this.props.questionStage}
                    classSafeMode={classSafeMode}
                  />
                </div>

              </div>

            </Measure>

            <SyncLiveViewPlayerControlBar
              isSet={isSet}
              narrowControlBarView={narrowControlBarView}
              slideWidth={this.state.slideWidth}
              questionStage={this.props.questionStage}
              currentSection={this.props.currentSection}
              poll={this.props.poll}
              toggleScanning={this.props.toggleScanning}
              toggleShowCorrect={this.props.toggleShowCorrect}
              toggleShowGraph={this.props.toggleShowGraph}
              showGraph={this.props.showGraph}
              isSurvey={isSurvey}
              revealAnswer={this.props.revealAnswer}
              currentQuestionIndex={this.props.currentQuestionIndex}
              questionsAndPollsList={this.props.questionsAndPollsList}
              playQuestionInSet={this.props.playQuestionInSet}
              classSafeMode={this.props.classSafeMode}
              showCorrectSubmitLoading={this.props.showCorrectSubmitLoading}
              showGraphSubmitLoading={this.props.showGraphSubmitLoading}
              onlineStudentCount={onlineStudentCount}
            />

          </div>
          {this.props.currentSection && !classSafeMode && (
          <SyncLiveViewPlayerStudents
            isSurvey={isSurvey}
            poll={this.props.poll}
            currentSection={this.props.currentSection}
            questionStage={this.props.questionStage}
            question={this.props.snapshot}
            hoveredChoice={this.props.hoveredChoice}
            choicesGroupHovered={this.props.choicesGroupHovered}
            onlineStudents={this.props.onlineStudents}
          />
          )}
        </div>

        {isSet && (
        <SyncLiveViewSetReport
          classSafeMode={classSafeMode}
          currentSection={this.props.currentSection}
          setPoll={this.props.setPoll}
          currentQuestionIndex={this.props.currentQuestionIndex}
          questionsAndPollsList={this.props.questionsAndPollsList}
          students={students}
          onlineStudents={this.props.onlineStudents}
        />
        )}
      </div>
    )
  }
}

export default SyncLiveViewPage

import React from 'react'
import ModalWrapper from '../ModalWrapper'
import UpdateNameContainer from '../../../containers/modals/account/UpdateNameContainer'

const UpdateNameModal = props => {

	return (

      <ModalWrapper
        {...props}
        title={'Update Name'}
        iconName=''
        modalStyle='dark'
      >
        <UpdateNameContainer {...props}/>

      </ModalWrapper>

   

  )
}

export default UpdateNameModal
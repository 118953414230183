import React from 'react'
import debounce from 'lodash/debounce'
import {
  InstantSearch,
  Configure,
  connectSearchBox,
  connectHits,
  Index,
  connectStateResults,
  connectStats,
} from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import sortBy from 'lodash/sortBy'
import store from '../../../../../store'
import EditorSoundLibraryResultSortHeaders from './EditorSoundLibraryResultSortHeaders'
import EditorSoundLibrarySoundClipRow from './EditorSoundLibrarySoundClipRow'
import Icon from '../../../../misc/Icons'

const CATEGORY_COUNTS_LOADING_STATE = ''

const categories = ['yourSounds', 'EFL', 'french', 'music', 'science', 'spanish', 'everything']

// tagFilters
function getAlgoliaFilters(userId, repos) {
  const yourSounds = [`user_${userId}`]
  repos.forEach((repo) => {
    const filter = `repo_${repo.id}`
    yourSounds.push(filter)
  })
  const env = process.env.REACT_APP_ENV
  if (env === 'development') {
    return {
      yourSounds: [yourSounds],
      EFL: ['subject_5db0c858ae38d80013090b88'],
      french: ['subject_5db0c85d6f74320011379af3'],
      music: ['subject_5db0c8b4ae38d80013090b8e'],
      science: ['subject_5db0c8016f74320011379aef'],
      spanish: ['subject_5db0c87aae38d80013090b8b'],
      everything: [
        [
          `user_${userId}`,
          'subject_5db0c858ae38d80013090b88',
          'subject_5db0c85d6f74320011379af3',
          'subject_5db0c8b4ae38d80013090b8e',
          'subject_5db0c8016f74320011379aef',
          'subject_5db0c87aae38d80013090b8b',
        ],
      ],
      discover: [
        [
          'subject_5db0c858ae38d80013090b88',
          'subject_5db0c85d6f74320011379af3',
          'subject_5db0c8b4ae38d80013090b8e',
          'subject_5db0c8016f74320011379aef',
          'subject_5db0c87aae38d80013090b8b',
        ],
      ],
    }
  }
  return {
    yourSounds: [yourSounds],
    EFL: ['subject_5dbcc56120bed70016832d1a'],
    french: ['subject_5dbcc56620bed70016832d21'],
    music: ['subject_5dbcc5ba2f2e12001100ccb4'],
    science: ['subject_5dbcc5182f2e12001100caee'],
    spanish: ['subject_5dbcc57feea50700165ac943'],
    everything: [
      [
        `user_${userId}`,
        'subject_5dbcc56120bed70016832d1a',
        'subject_5dbcc56620bed70016832d21',
        'subject_5dbcc5ba2f2e12001100ccb4',
        'subject_5dbcc5182f2e12001100caee',
        'subject_5dbcc57feea50700165ac943',
      ],
    ],
    discover: [
      [
        'subject_5dbcc56120bed70016832d1a',
        'subject_5dbcc56620bed70016832d21',
        'subject_5dbcc5ba2f2e12001100ccb4',
        'subject_5dbcc5182f2e12001100caee',
        'subject_5dbcc57feea50700165ac943',
      ],
    ],
  }
}

export function getSortedHitsData(sortType, sortOrder, hits) {
  let sorted = hits
  if (sortType === 'duration') {
    sorted = sortBy(sorted, [(hit) => hit.end - hit.start])
  } else if (sortType === 'name') {
    sorted = sortBy(sorted, [(hit) => hit.title.toString().toLowerCase()])
  }
  if (sortOrder !== 'ascending') {
    sorted = sorted.reverse()
  }
  return sorted
}

const SearchGroupLoadingIndicator = connectStateResults(
  ({ isSearchStalled }) => (isSearchStalled ?
    <div className="editor-insertSoundModal--soundLibrary-main-resultsList-searchGroupLoading editor-insertSoundModal--soundLibrary-main-resultsList-searchGroupLoading--loading" /> : <div className="editor-insertSoundModal--soundLibrary-main-resultsList-searchGroupLoading editor-insertSoundModal--soundLibrary-main-resultsList-searchGroupLoading--notLoading" />),
)

const SearchBar = connectSearchBox(({
  currentRefinement,
  refine,
  handleOnInputChange,
  isSearchStalled,
}) => {
  let isEmpty = true
  if (currentRefinement !== '') {
    isEmpty = false
  }
  const showSearchLoading = isSearchStalled && !isEmpty
  return (
    <div className={`editor-insertSoundModal--soundLibrary-main-titleBar-searchBar ${isEmpty ? ' editor-insertSoundModal--soundLibrary-main-titleBar-searchBar--empty' : ' editor-insertSoundModal--soundLibrary-main-titleBar-searchBar--notEmpty'}`}>
      <input
        id="soundLibrarySearchInput"
        placeholder="Search Sound Library"
        autoFocus
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        className={`editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-input ${isEmpty ? 'editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-input--empty' : 'editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-input--nonEmpty'}`}
        value={currentRefinement}
        onChange={(e) => {
          handleOnInputChange(e.target.value, refine)
        }}
      />
      <div className="editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-iconContainer">
        {!showSearchLoading &&
        <Icon name="magnifying-glass" />}
        {showSearchLoading && (
        <div className="editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-iconContainer-loadingSpinnerContainer">
          <div className="editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-iconContainer-loadingSpinner" />
        </div>
        )}
      </div>
      {!isEmpty && (
      <button className="editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-clearBtnContainer" onClick={() => { handleOnInputChange('', refine) }}>
        <div className="editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-clearBtn">
          Cancel
          {/* <Icon name='cross' /> */}
        </div>
      </button>
      )}
    </div>
  )
})

const SearchResults = connectHits(({
  category,
  insertSoundFromSoundLibrary,
  hasSearchTerm,
  hits,
  sortOrder,
  sortType,
  activeRowIndex,
  setActiveRow,
  playback,
  playItem,
  pauseItem,
  editSoundFromSoundLibrary,
  isLoading,
}) => {
  // note:
  // some sound clips that get returned from Algolia are empty (bug).
  // filter out all the empty ones here
  const filteredHits = hits.filter((hit) => hit.title)
  const sortedHits = hasSearchTerm ?
    filteredHits :
    getSortedHitsData(sortType, sortOrder, filteredHits)
  return (
    <React.Fragment>
      <SearchGroupLoadingIndicator />
      <div className={`editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup ${(hasSearchTerm && sortedHits.length === 0) ? ' editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup--noResults' : ''}`}>
        {hasSearchTerm && !isLoading && (
        <div className="editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup-groupTitle">
          {category}
          {sortedHits.length === 0 && (
          <span className="editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup-groupTitle-noResults">
            (No Results)
          </span>
          )}
          {sortedHits.length > 0 && (
          <span className="editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup-groupTitle-noResults">
            (
            {sortedHits.length}
            {' '}
            Results)
          </span>
          )}
        </div>
        )}
        {sortedHits.length !== 0 && sortedHits.map((item, index) => (
          <EditorSoundLibrarySoundClipRow
            active={`${category}_${index}` === activeRowIndex}
            /* eslint-disable react/no-array-index-key */
            key={`${item.deliveryUrl}_${index}`}
            /* eslint-enable react/no-array-index-key */
            soundClipName={item.title}
            duration={item.fullDuration}
            start={item.start}
            end={item.end}
            deliveryUrl={item.deliveryUrl}
            setActiveRow={() => { setActiveRow(`${category}_${index}`, item) }}
            playback={playback}
            playItem={() => { playItem(`${category}_${index}`) }}
            pauseItem={() => { pauseItem(`${category}_${index}`) }}
            rowIndex={`${category}_${index}`}
            editSoundFromSoundLibrary={() => { editSoundFromSoundLibrary(item) }}
            insertSoundFromSoundLibrary={() => { insertSoundFromSoundLibrary(item) }}
          />
        ))}
      </div>
    </React.Fragment>
  )
})

// nbHits could be inaccurate if there are empty hits that needs to be filtered out from displaying
// we can't tell how many of them are in total since we only get a chunk
const Stats = connectStats(({ nbHits }) => <React.Fragment>{nbHits}</React.Fragment>)

class EditorSoundLibraryCategoryNavCategory extends React.Component {
  render() {
    return (
      <button onClick={this.props.selectCategory} className={`editor-insertSoundModal--soundLibrary-categoryNav--category ${this.props.active ? 'editor-insertSoundModal--soundLibrary-categoryNav--category--active' : 'editor-insertSoundModal--soundLibrary-categoryNav--category--inactive'}`}>
        <div className="editor-insertSoundModal--soundLibrary-categoryNav--category-name">
          {this.props.categoryName}
        </div>
        {this.props.categoryName !== 'Search Results' && (
        <div className="editor-insertSoundModal--soundLibrary-categoryNav--category-resultsCount">
          {!this.props.resultsCount &&
          <Stats />}
          {this.props.resultsCount && (
          <React.Fragment>
            {this.props.resultsCount}
          </React.Fragment>
          )}
        </div>
        )}
      </button>
    )
  }
}

const DEBOUNCE_TIME = 1000

class EditorSoundLibraryPage extends React.Component {
  constructor() {
    super()
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.setSortType = this.setSortType.bind(this)
    this.checkForAlgoliaSearchKey = this.checkForAlgoliaSearchKey.bind(this)
    this.checkForFilterParams = this.checkForFilterParams.bind(this)
    this.handleOnInputChange = this.handleOnInputChange.bind(this)
    this.selectCategory = this.selectCategory.bind(this)
    this.getTotalResultsCounts = this.getTotalResultsCounts.bind(this)
    this.setActiveRow = this.setActiveRow.bind(this)

    this.sendSoundLibrarySearchTermAnalytics = debounce(
      this.sendSoundLibrarySearchTermAnalytics,
      DEBOUNCE_TIME,
      { leading: false },
    )

    const searchKey = this.getSearchKey()
    if (!searchKey) {
      this.timer = setInterval(() => {
        this.checkForAlgoliaSearchKey()
      }, 300)
    }

    let algoliaTagFilters = null
    const state = store.getState()
    const { user } = state
    const { reposFetched } = state
    if (user && reposFetched) {
      const { repos } = state
      algoliaTagFilters = getAlgoliaFilters(user.id, repos)
    } else {
      this.filterTimer = setInterval(() => {
        this.checkForFilterParams()
      }, 300)
    }

    const counts = {}
    categories.forEach((category) => {
      counts[category] = CATEGORY_COUNTS_LOADING_STATE
    })

    this.searchClient = null

    this.state = {
      searchTerm: '',
      category: 'yourSounds',
      sortType: 'name',
      sortOrder: 'ascending',
      activeRowIndex: null,
      activeSound: null,
      activeSoundBrowseSoundLibraryCategory: null,
      activeSoundSearchSoundLibraryCategory: null,
      searchKey,
      soundCounts: counts,
      algoliaTagFilters,
      playback: { // sound currently being played in sound library
        playbackRow: null,
        isPlaying: false,
      },
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
    if (window.analytics) {
      window.analytics.page('Sound Library')
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
    if (this.timer) {
      clearInterval(this.timer)
    }
    if (this.filterTimer) {
      clearInterval(this.filterTimer)
    }
  }

  selectCategory(category) {
    if (category !== this.state.category) {
      this.setState({
        category,
        activeRowIndex: null,
        activeSound: null,
        playback: {
          playbackRow: null,
          isPlaying: false,
        },
      })
    }
  }

  getTotalResultsCounts() { // find the  totals for each category
    const index = this.searchClient.initIndex('sound_clips')
    categories.forEach((category) => {
      index.search('', {
        tagFilters: this.state.algoliaTagFilters[category],
      }).then(({ nbHits }) => {
        this.setState((prevState) => {
          const counts = { ...prevState.soundCounts }
          counts[category] = `${nbHits}`
          return { soundCounts: counts }
        })
      })
    })
  }

  getSearchKey() {
    const state = store.getState()
    const { searchKey } = state
    let soundSearchKey
    if (searchKey) {
      soundSearchKey = searchKey.sound
    }
    return soundSearchKey
  }

  checkForAlgoliaSearchKey() {
    const searchKey = this.getSearchKey()
    if (searchKey) {
      this.setState({ searchKey })
      clearInterval(this.timer)
    }
  }

  checkForFilterParams() {
    const state = store.getState()
    const { user } = state
    const { reposFetched } = state
    if (user && user.id && reposFetched) {
      const { repos } = state
      const algoliaTagFilters = getAlgoliaFilters(user.id, repos)
      this.setState({ algoliaTagFilters })
      clearInterval(this.filterTimer)
    }
  }

  handleKeyDown(e) {
    const {
      activeRowIndex,
      playback,
    } = this.state
    if (e.keyCode === 27) { // escape key
      this.props.closeInsertSoundModal()
    } else if (e.keyCode === 32) { // spacebar
      const searchInputFocused = document.getElementById('soundLibrarySearchInput') === document.activeElement
      if (!searchInputFocused) { // don't do space bar if focused on search bar
        e.preventDefault()
        if (activeRowIndex) {
          const isPlaying = playback && playback.playbackRow === activeRowIndex ?
            !playback.isPlaying :
            true
          this.setState({
            playback: {
              playbackRow: activeRowIndex,
              isPlaying,
            },
          })
        }
      }
    }
  }

  setSortType(type) {
    const { sortType, sortOrder } = this.state
    let newSortOrder = 'ascending'
    if (type === sortType) {
      if (sortOrder === 'ascending') {
        newSortOrder = 'descending'
      } else {
        newSortOrder = 'ascending'
      }
    }
    this.setState({
      sortType: type,
      sortOrder: newSortOrder,
      activeRowIndex: null,
      activeSound: null,
      playback: {
        playbackRow: null,
        isPlaying: false,
      },
    })
  }

  sendSoundLibrarySearchTermAnalytics(searchTerm) {
    if (window.analytics) {
      window.analytics.track('Sound library search', {
        searchTerm,
      })
    }
  }

  handleOnInputChange(newValue, refine) {
    this.sendSoundLibrarySearchTermAnalytics(newValue)
    refine(newValue)
    this.setState({
      searchTerm: newValue,
      activeRowIndex: null,
      playback: {
        playbackRow: null,
        isPlaying: false,
      },
    })
  }

  setActiveRow(index, sound, browseSoundLibraryCategory, searchSoundLibraryCategory) {
    if (this.state.activeRowIndex !== index) {
      this.setState({
        activeRowIndex: index,
        activeSound: sound,
        activeSoundBrowseSoundLibraryCategory: browseSoundLibraryCategory,
        activeSoundSearchSoundLibraryCategory: searchSoundLibraryCategory,
      })
    } else {
      this.setState({
        activeRowIndex: null,
        activeSound: null,
        activeSoundBrowseSoundLibraryCategory: browseSoundLibraryCategory,
        activeSoundSearchSoundLibraryCategory: searchSoundLibraryCategory,
      })
    }
  }

  render() {
    const {
      category, soundCounts, algoliaTagFilters, searchKey,
    } = this.state

    let categoryTagFilters
    if (algoliaTagFilters) {
      categoryTagFilters = algoliaTagFilters[category]
    }

    const hasSearchTerm = !!this.state.searchTerm
    if (hasSearchTerm && algoliaTagFilters) {
      categoryTagFilters = algoliaTagFilters.everything
    }

    // Category name for title
    let selectedCategoryName = 'Search Results'
    let isDiscover = false

    if (category === 'yourSounds' && !hasSearchTerm) {
      selectedCategoryName = 'Your Sounds'
    }
    // I'm not sure if we need nicely formatted names for categoryies
    if (category !== 'yourSounds' && !hasSearchTerm) {
      selectedCategoryName = category
      isDiscover = true
    }

    const resultsListCategoryType = 'personal' // your sounds
    // const resultsListCategoryType = 'public' // discover content
    // const resultsListCategoryType = 'mixed' // "everything"

    if (searchKey && !this.searchClient && this.state.algoliaTagFilters) {
      this.searchClient = algoliasearch(
        process.env.REACT_APP_PLICKERS_ALGOLIA_SOUND_CLIPS_APP_ID,
        searchKey,
      )
      this.getTotalResultsCounts()
    }

    const showInsertButton = (this.state.activeRowIndex || this.state.activeRowIndex === 0)
    return (
      <React.Fragment>
        <div className="editor-insertSoundModal editor-insertSoundModal--soundLibrary">
          {this.searchClient && (
          <InstantSearch
            searchClient={this.searchClient}
            indexName="sound_clips"
            stalledSearchDelay={10}
          >
            <div className="editor-insertSoundModal--soundLibrary-categoryNav">
              {hasSearchTerm &&
              <EditorSoundLibraryCategoryNavCategory active categoryName="Search Results" />}
              {!hasSearchTerm && (
              <React.Fragment>
                <EditorSoundLibraryCategoryNavCategory selectCategory={() => { this.selectCategory('yourSounds') }} active={category === 'yourSounds'} categoryName="Your Sounds" resultsCount={soundCounts.yourSounds} />
                <div className="editor-insertSoundModal--soundLibrary-categoryNav-discoverLabel">
                  Discover
                </div>
                <EditorSoundLibraryCategoryNavCategory selectCategory={() => { this.selectCategory('EFL') }} active={category === 'EFL'} categoryName="EFL" resultsCount={soundCounts.EFL} />
                <EditorSoundLibraryCategoryNavCategory selectCategory={() => { this.selectCategory('french') }} active={category === 'french'} categoryName="French" resultsCount={soundCounts.french} />
                <EditorSoundLibraryCategoryNavCategory selectCategory={() => { this.selectCategory('music') }} active={category === 'music'} categoryName="Music" resultsCount={soundCounts.music} />
                <EditorSoundLibraryCategoryNavCategory selectCategory={() => { this.selectCategory('science') }} active={category === 'science'} categoryName="Science" resultsCount={soundCounts.science} />
                <EditorSoundLibraryCategoryNavCategory selectCategory={() => { this.selectCategory('spanish') }} active={category === 'spanish'} categoryName="Spanish" resultsCount={soundCounts.spanish} />
              </React.Fragment>
              )}
              {/* ADD LATER
                <button className='editor-insertSoundModal--soundLibrary-categoryNav-addSubject'>
                  Add Subject
                </button>
              */}

              <div className="editor-insertSoundModal--soundLibrary-categoryNav-vSpacer" />

              <a target="_blank" className="editor-insertSoundModal--soundLibrary-categoryNav-helpBtn" rel="noopener noreferrer" href="https://help.plickers.com/hc/en-us/articles/4405741980059">
                Sound Library Help
              </a>

              <div className="editor-insertSoundModal--soundLibrary-categoryNav-submitBtnContainer">
                {showInsertButton && (
                <button onClick={() => { this.props.insertSoundFromSoundLibrary(this.state.activeSound, this.state.activeSoundBrowseSoundLibraryCategory, this.state.activeSoundSearchSoundLibraryCategory) }} className="editor-insertSoundModal--soundLibrary-categoryNav-insertBtn">
                  <div className="editor-insertSoundModal--soundLibrary-categoryNav-insertBtn-primary">
                    Insert Sound Clip
                  </div>
                  <div className="editor-insertSoundModal--soundLibrary-categoryNav-insertBtn-secondary">
                    Enter
                  </div>
                </button>
                )}
                <button className="editor-insertSoundModal--soundLibrary-categoryNav-cancelBtn" onClick={this.props.closeModal}>
                  Cancel
                </button>
              </div>
            </div>
            <div className="editor-insertSoundModal--soundLibrary-main">
              <div className={`editor-insertSoundModal--soundLibrary-main-titleBar ${hasSearchTerm ? 'editor-insertSoundModal--soundLibrary-main-titleBar--search' : 'editor-insertSoundModal--soundLibrary-main-titleBar--browse'}`}>
                {!hasSearchTerm && (
                <React.Fragment>
                  <div className="editor-insertSoundModal--soundLibrary-main-titleBar-categoryNameContainer">
                    <div className="editor-insertSoundModal--soundLibrary-main-titleBar-categoryName">
                      {isDiscover && (
                      <div className="editor-insertSoundModal--soundLibrary-main-titleBar-categoryName-discover">
                        Discover
                      </div>
                      )}
                      <div className="editor-insertSoundModal--soundLibrary-main-titleBar-categoryName-categoryName">
                        {selectedCategoryName}
                      </div>
                    </div>
                    {isDiscover && selectedCategoryName && (
                    <div className="editor-insertSoundModal--soundLibrary-main-titleBar-categoryLabel">
                      Browse and add sounds from published
                      {' '}
                      <span>{selectedCategoryName}</span>
                      {' '}
                      packs
                    </div>
                    )}
                  </div>
                </React.Fragment>
                )}
                <div className={`editor-insertSoundModal--soundLibrary-main-titleBar-searchBarContainer ${hasSearchTerm ? 'editor-insertSoundModal--soundLibrary-main-titleBar-searchBarContainer--active' : 'editor-insertSoundModal--soundLibrary-main-titleBar-searchBarContainer--inactive'}`}>
                  <SearchBar handleOnInputChange={this.handleOnInputChange} />
                </div>
              </div>
              <EditorSoundLibraryResultSortHeaders
                resultsListCategoryType={resultsListCategoryType}
                sortType={this.state.sortType}
                sortOrder={this.state.sortOrder}
                setSortType={this.setSortType}
                hasSearchTerm={hasSearchTerm}
              />

              {!hasSearchTerm && algoliaTagFilters && (
              <div className="editor-insertSoundModal--soundLibrary-main-resultsList editor-insertSoundModal--soundLibrary-main-resultsList--browse">
                <Index indexName="sound_clips" indexId="browse">
                  <Configure
                    hitsPerPage={500}
                    tagFilters={categoryTagFilters}
                  />
                  <SearchResults
                    category="Browse"
                    hasSearchTerm={hasSearchTerm}
                    sortType={this.state.sortType}
                    sortOrder={this.state.sortOrder}
                    activeRowIndex={this.state.activeRowIndex}
                    setActiveRow={(index, sound) => {
                      this.setActiveRow(index, sound, this.state.category, null)
                    }}
                    playback={this.state.playback}
                    playItem={(index) => {
                      this.setState({ playback: { playbackRow: index, isPlaying: true } })
                    }}
                    pauseItem={(index) => {
                      this.setState({ playback: { playbackRow: index, isPlaying: false } })
                    }}
                    editSoundFromSoundLibrary={(sound) => {
                      this.props.editSoundFromSoundLibrary(sound)
                    }}
                    insertSoundFromSoundLibrary={(sound) => {
                      this.props.insertSoundFromSoundLibrary(sound, this.state.category, null)
                    }}
                  />
                </Index>
              </div>
              )}
              {hasSearchTerm && algoliaTagFilters && (
              <div className="editor-insertSoundModal--soundLibrary-main-resultsList editor-insertSoundModal--soundLibrary-main-resultsList--search">
                <Index indexName="sound_clips" indexId="yourSounds">
                  <Configure
                    hitsPerPage={500}
                    tagFilters={algoliaTagFilters.yourSounds}
                  />
                  <SearchResults
                    category="Your Sounds"
                    hasSearchTerm={hasSearchTerm}
                    sortType={this.state.sortType}
                    sortOrder={this.state.sortOrder}
                    activeRowIndex={this.state.activeRowIndex}
                    setActiveRow={(index, sound) => { this.setActiveRow(index, sound, null, 'yourSounds') }}
                    playback={this.state.playback}
                    playItem={(index) => {
                      this.setState({ playback: { playbackRow: index, isPlaying: true } })
                    }}
                    pauseItem={(index) => {
                      this.setState({ playback: { playbackRow: index, isPlaying: false } })
                    }}
                    editSoundFromSoundLibrary={(sound) => {
                      this.props.editSoundFromSoundLibrary(sound)
                    }}
                    insertSoundFromSoundLibrary={(sound) => {
                      this.props.insertSoundFromSoundLibrary(sound, null, 'yourSounds')
                    }}
                  />
                </Index>

                <Index indexName="sound_clips" indexId="discover">
                  <Configure
                    hitsPerPage={500}
                    tagFilters={algoliaTagFilters.discover}
                  />
                  <SearchResults
                    category="Discover"
                    hasSearchTerm={hasSearchTerm}
                    sortType={this.state.sortType}
                    sortOrder={this.state.sortOrder}
                    activeRowIndex={this.state.activeRowIndex}
                    setActiveRow={(index, sound) => { this.setActiveRow(index, sound, null, 'discover') }}
                    playback={this.state.playback}
                    playItem={(index) => {
                      this.setState({ playback: { playbackRow: index, isPlaying: true } })
                    }}
                    pauseItem={(index) => {
                      this.setState({ playback: { playbackRow: index, isPlaying: false } })
                    }}
                    editSoundFromSoundLibrary={(sound) => {
                      this.props.editSoundFromSoundLibrary(sound)
                    }}
                    insertSoundFromSoundLibrary={(sound) => {
                      this.props.insertSoundFromSoundLibrary(sound, null, 'discover')
                    }}
                  />
                </Index>
              </div>
              )}
            </div>
          </InstantSearch>
          )}
        </div>
        <div onClick={this.props.closeModal} className="editor-insertSoundModal--soundLibraryBG" />
      </React.Fragment>
    )
  }
}

export default EditorSoundLibraryPage

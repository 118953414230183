import React from 'react'
import EditorIcon from '../misc/EditorIcons'
import {formatVideoDuration} from '../../utils/timestampFormatters/formatVideoDuration'
import createMarkup from '../../utils/createMarkup'

class QuestionChoice extends React.Component{

	render(){ 
		const {letter,choice,isCorrect,highlightCorrect,choiceHasResponse,showResponses,showImages} = this.props
		let showCheckLetterBlock = false	
		if(isCorrect && highlightCorrect){
			showCheckLetterBlock = true
		}
		if(isCorrect && choiceHasResponse && showResponses){
			showCheckLetterBlock = true
		}
		const showLetterLetterBlock = !showCheckLetterBlock
		let startVideoClipTimestamp
		let endVideoClipTimestamp	
		if(choice.media && choice.media.type==='video' && choice.media.isClipped){
			startVideoClipTimestamp = formatVideoDuration(choice.media.start)
			endVideoClipTimestamp = formatVideoDuration(choice.media.end)
		}

		return(			
			<div className={'printPackQuestion-choice' + (highlightCorrect ? ' printPackQuestion-choice--isHighlightCorrect ' : '') + (isCorrect ? ' printPackQuestion-choice--isCorrect ' : ' printPackQuestion-choice--isIncorrect ') }>
								
				<div className='printPackQuestion-choice-responseContainer'>				
					{choiceHasResponse && showResponses &&
						<div className='printPackQuestion-choice-responseContainer-response'>
							<svg width='4' height='4' viewBox='0 0 4 4'>
								<circle cx="2" cy="2" r="2" />
							</svg>
						</div>
					}
				</div>
			
				{showLetterLetterBlock &&
					<div className='printPackQuestion-choice-letterBlock printPackQuestion-choice-letterBlock--default'>
						{letter}
					</div>		
				}

				{showCheckLetterBlock &&
					<div className='printPackQuestion-choice-letterBlock printPackQuestion-choice-letterBlock--correctHighlight'>
						<svg width="14" height="14" viewBox="0 0 14 14">
    					<path fill="#31C99D" d="M12.25,0 C13.311481,0 14,0.688519022 14,1.75 L14,12.25 C14,13.311481 13.311481,14 12.25,14 L1.75,14 C0.688519022,14 0,13.311481 0,12.25 L0,1.75 C0,0.688519022 0.688519022,0 1.75,0 L12.25,0 Z"/>
    					<path fill="#FFF" fillRule="nonzero" d="M5.64806016,8.32065948 L9.8174555,3.42233728 C10.0508869,3.14809555 10.4603383,3.12370859 10.7195359,3.37058529 L11.1987462,3.8270165 C11.4499025,4.06623413 11.4679468,4.4670957 11.2418358,4.73273715 L6.26666646,10.5777052 C6.03323506,10.8519469 5.62378365,10.8763339 5.36458605,10.6294572 L2.60117984,7.99740876 C2.35097724,7.75909947 2.33236779,7.35556112 2.55536108,7.09358237 L2.93713865,6.64505941 C3.16996815,6.3715248 3.57883505,6.34979443 3.83772341,6.5963766 L5.64806016,8.32065948 Z"/>  
						</svg>

					</div>
				}

				<div className='printPackQuestion-choice-body'>
					{!choice.media &&
						<div dangerouslySetInnerHTML={createMarkup(choice.bodyHtml)} className='printPackQuestion-choice-body-body' />											
					}

					{choice.media && choice.media.type==='video' && 
						<div className='printPackQuestion-choice-body-video'>
							<EditorIcon name='insertVideo' />								
								youtube.com/watch?v={choice.media.youtubeId}

							{choice.media.isClipped &&
								<div className='printPackQuestion-choice-body-video-clip'>({startVideoClipTimestamp} - {endVideoClipTimestamp})</div>
							}
						</div>						
					}
					{choice.media && choice.media.type==='sound' && 
						<div className='printPackQuestion-choice-body-sound'>
							<EditorIcon name='insertSound' />								
								{choice.media.deliveryUrl}.mp3
						</div>						
					}

				</div>

				{choice.media && choice.media.type==='image' && showImages &&
					<div className='printPackQuestion-choice-imageContainer'>							
						<div className='printPackQuestion-choice-ghostImageSpacer'/>
						<img alt='' src={choice.media.deliveryUrl}/>		
					</div>
				}

				{choice.media && choice.media.type==='animatedGif' && showImages &&
					<div className='printPackQuestion-choice-imageContainer'>							
						<div className='printPackQuestion-choice-ghostImageSpacer'/>
						<img alt='' src={choice.media.stillUrl} />				
					</div>
				}

			</div>
		)
	}
}


class QuestionFlag extends React.Component{
	render(){ 
		const {questionGrade} = this.props
		return(			
			<div className={'printPackQuestion-flag' + (questionGrade ? ` printPackQuestion-flag--${questionGrade}`:'')}>					
				<div className='printPackQuestion-flag-label'>
					{questionGrade}
				</div>
				<svg width="80" height="16" viewBox="0 0 80 16">
  				<path d="M1,-7.05824288e-15 L78.381966,-7.77156117e-15 C78.9342508,-7.87301424e-15 79.381966,0.44771525 79.381966,1 C79.381966,1.1552451 79.3458209,1.30835816 79.2763932,1.4472136 L76.2236068,7.5527864 C76.0828431,7.8343139 76.0828431,8.1656861 76.2236068,8.4472136 L79.2763932,14.5527864 C79.5233825,15.0467649 79.3231581,15.6474379 78.8291796,15.8944272 C78.6903242,15.9638549 78.5372111,16 78.381966,16 L1,16 C0.44771525,16 -4.6889057e-13,15.5522847 -4.68958206e-13,15 L-4.68958206e-13,1 C-4.69025841e-13,0.44771525 0.44771525,-7.00397429e-15 1,-7.10542736e-15 Z"/>
				</svg>
			</div>
		)
	}
}




class QuestionContent extends React.Component{

	render(){ 
		const {question,labelSurveys,questionCounter,response,highlightCorrect,showResponses,isSurvey,showResponseCapturedDate,showQuestionNumbers,showQuestionGrades,showImages,includeStudentWorkingSpace}=this.props
		const choices=question.choices		
		const choicesLettersArray=['A','B','C','D']			
		const showAnimatedGIFs = showImages // maybe separate toggle in the future
		const showVideos = true // I think separate toggle, maybe question-level I guess
		const showSounds = true // I think separate toggle, maybe question-level I guess		
		let startVideoClipTimestamp
		let endVideoClipTimestamp	
		if(question.media && question.media.type==='video' && question.media.isClipped){
			startVideoClipTimestamp = formatVideoDuration(question.media.start)
			endVideoClipTimestamp = formatVideoDuration(question.media.end)
		}

		return(			
			<React.Fragment>			
				{((labelSurveys && labelSurveys!==false && isSurvey) || highlightCorrect ||showQuestionGrades) &&
					<div className='printPackQuestion-flags'>
						{showQuestionGrades && !response && 
							<QuestionFlag questionGrade = 'missed' />
						}

						{isSurvey &&
							<QuestionFlag questionGrade = 'survey' />
						}
						
						{showQuestionGrades && response && !isSurvey && response.isCorrect &&
							<QuestionFlag questionGrade = 'correct' />
						}
						{showQuestionGrades && response && !isSurvey && !response.isCorrect &&
							<QuestionFlag questionGrade = 'incorrect' />
						}						
					</div>
				}

				<div className='printPackQuestion-mainContentContainer notranslate'>
					<div className='printPackQuestion-body'>						
						<div dangerouslySetInnerHTML={createMarkup(question.bodyHtml)} className='printPackQuestion-body-body' />
						
						{question.media && question.media.type==='video' && showVideos &&				
							<div className='printPackQuestion-body-video'>
								<EditorIcon name='insertVideo' />								
									youtube.com/watch?v={question.media.youtubeId}

								{question.media.isClipped &&
									<div className='printPackQuestion-body-video-clip'>({startVideoClipTimestamp} - {endVideoClipTimestamp})</div>
								}
							</div>
						}

						{question.media && question.media.type==='sound' && showSounds &&				
							<div className='printPackQuestion-body-sound'>
								<EditorIcon name='insertSound' />								
								{question.media.deliveryUrl}.mp3
							</div>
						}

						{showQuestionNumbers &&
							<div className='printPackQuestion-body-number'>
								{questionCounter}
							</div>
						}
					</div>
					
					{question.media && question.media.type==='image' && showImages &&
						<div className='printPackQuestion-imageContainer'>							
							<div className='printPackQuestion-ghostImageSpacer'/>
							<img alt='' src={question.media.deliveryUrl}/>	
						</div>
					}

					{question.media && question.media.type==='animatedGif' && showAnimatedGIFs &&
						<div className='printPackQuestion-imageContainer'>							
							<div className='printPackQuestion-ghostImageSpacer'/>
							<img alt='' src={question.media.stillUrl} />				
						</div>
					}

					<div className='printPackQuestion-choices'>
						{choices.map((choice,index)=>{
							const choiceLetter=choicesLettersArray[index]
							let choiceHasResponse = false
							if(response && (response.answer===choiceLetter)){
								choiceHasResponse=true
							}
							
							return(
								<QuestionChoice 
									key={index}
									letter={choiceLetter}
									choice={choice}									
									isCorrect={choice.correct}
									highlightCorrect={highlightCorrect}
									showResponses={showResponses}
									choiceHasResponse={choiceHasResponse}
									showImages={showImages}
								/>
							)

						})}
						{/*
							{response && showResponses &&
							<div className='printPackQuestion-choices-studentResponse'>
								Answered {response.answer} ({response.isSurvey? 'survey':response.isCorrect?'correct':'incorrect'})
							</div>
						}
						*/}

						

						{response && showResponseCapturedDate &&
							<div className='printPackQuestion-choices-studentResponse'>
								{response.captured}
							</div>
						}

					</div>

					{includeStudentWorkingSpace &&
						<div className='printPackQuestion-workingSpace'>
							<div className='printPackQuestion-workingSpace-row' />
							<div className='printPackQuestion-workingSpace-row' />
							<div className='printPackQuestion-workingSpace-row' />
							<div className='printPackQuestion-workingSpace-row' />							
						</div>
					}
					
					<div className='printPackQuestion-divider'/>
				</div>				
				
			</React.Fragment>
		)
	}
}


export default QuestionContent

import React from 'react' 
import HelpVideoThumbnail from '../uiKit/HelpVideoThumbnail'
 
class RepoEmptyStateHelpVideo extends React.Component{


	render(){ 
		
		return(
			
			<div className='repo-emptyStateHelpVideoContainer'>
				<div className='repo-emptyStateHelpVideo' onClick={this.props.showHelpVideoModal}>

					<HelpVideoThumbnail title='How to move content from Your Library into Packs' />
					<div className='repo-emptyStateHelpVideo-label'>
						How to move content into Packs
					</div>
					<div className='repo-emptyStateHelpVideo-sub'>
						Watch Video (1:45)
					</div>
					
				</div>
				<div className='repo-emptyStateHelpVideoBG'>
					<svg width="240" height="160" viewBox="0 0 240 160">
						<path d="M3.71201466,6.25021111 C3.71201466,-2.2736339 13.7216023,0.626211183 43.5439639,0.626211183 C68.0934011,0.626211183 127.879709,-0.782763978 130.748036,0.626211183 C133.269043,1.86457638 239.180073,-4.28261598 238.157032,7.44910981 C237.059969,20.0296979 236.05904,56.9507123 236.010107,57.005999 C230.115296,63.666209 244.987732,137 236.010107,153.023526 C229.590074,164.482187 20.4880429,157.947133 11.1443465,154 C-8.88007659,145.540921 4.73987491,29.9756813 2.08485714,23.0573391 C-0.570160636,16.1389968 3.71201466,14.7740561 3.71201466,6.25021111 Z"/>
					</svg>
				</div>
			</div>

			

			
		)
	}

}

       

export default RepoEmptyStateHelpVideo

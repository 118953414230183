import {Plugin} from 'prosemirror-state'
import {TextSelection} from "prosemirror-state"

//Plugin to prevent selection across nodes (e.g. can't select question body and choice)
//(we also prevent select all from selecting outside the active node--this is found in RichEditorPage dispatchTransaction)

function isTextSelectionAcrossNodes($from, $to) {
	let fromBoundaryNode
	let toBoundaryNode
	for (let i = $from.depth; i > 0; i--) {
		let node = $from.node(i)
		if (node.type.name === "questionBody" || node.type.name === "choiceBody") {
			fromBoundaryNode = node
			break
		}
	}
	for (let i = $to.depth; i > 0; i--) {
		let node = $to.node(i)
		if (node.type.name === "questionBody" || node.type.name === "choiceBody") {
			toBoundaryNode = node
			break
		}
	}
	return fromBoundaryNode !== toBoundaryNode 
}


function normalizeSelection(state) {
	let sel = state.selection, doc = state.doc
	const $from=state.doc.resolve(sel.anchor)
	const $to=state.doc.resolve(sel.head)
	let tr=state.tr
	const isAcrossNodes=isTextSelectionAcrossNodes($from, $to)
	if(!isAcrossNodes){//if selection is all within single node do nothing
		return tr
	}else{//cut selection off at the end of node 
		let depth
		for (let i = $from.depth; i > 0; i--) {
			let node = $from.node(i)
			if (node.type.name === "questionBody" || node.type.name === "choiceBody") {
				depth=i
				break
			}
		}
		let endOfNode
		if($from.pos<$to.pos){
			endOfNode= $from.after(depth)
		}else{
			endOfNode= $from.before(depth)
		}
		tr.setSelection(TextSelection.create(doc,sel.anchor,endOfNode))
		return tr
	}
}

export function selectionPlugin(){
	return new Plugin({
		appendTransaction(_, oldState, state) {
			return normalizeSelection(state)
		},
	})
}
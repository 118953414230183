import {getNewQuestionTemplateDeleteMedia} from '../getNewQuestionTemplate'

export function deleteQuestionVideo(){	  
	var tr = window.view.state.tr
	const state=window.view.state
	state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {  
		if(node.attrs.activeslide && node.attrs.activeslide===true){
			const template=node.attrs.template
			const newTemplate=getNewQuestionTemplateDeleteMedia(template)
			tr.setNodeMarkup(pos, null, {...node.attrs,template:newTemplate})
			state.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
				if(questionNode.type.name === "choiceImage"||questionNode.type.name === "choiceVideo"||questionNode.type.name === "choiceSound"||questionNode.type.name === "choice"){
					tr.setNodeMarkup(questionPos, null, {...questionNode.attrs,template:newTemplate})          
				}
			})
		state.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
			if(questionNode.type.name==='questionVideo'){
				tr.delete(questionPos,questionPos+questionNode.nodeSize)
			} 
		})
	}
	})
	tr.setMeta("triggerParseDoc", true) 
	window.view.dispatch(tr)
}
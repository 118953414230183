import React from 'react'
import ReactGA from 'react-ga4'
import { Elements } from 'react-stripe-elements'
import InjectedCheckoutForm from './CheckoutForm'
import Icon from '../misc/Icons'
import planPricingDetails from './planPricingDetails'
import UpgradeDiscountSash from './UpgradeDiscountSash'
import couponDescriptions from '../../staticData/couponDescriptions'

class PaymentSelectionBtn extends React.Component {
  render() {
    const {
      type, isActive, onClickFunction, children,
    } = this.props

    return (

      <button
        className={`upgrade--payment-planBtn ${
          isActive ? 'upgrade--payment-planBtn--active' : 'upgrade--payment-planBtn--inactive'
        }${type ? ` upgrade--payment-planBtn--${type}` : ''}`}
        onClick={onClickFunction}
      >
        <div className='upgrade--payment-planBtn-checkContainer'>
          {isActive && <Icon name='check' /> }
        </div>
        {children}
      </button>
    )
  }
}

class UpgradePaymentPage extends React.Component {
  constructor() {
    super()

    this.state = {
      selectedPlan: 'year',
    }
  }

  componentDidMount() {
    if (window.analytics) {
      let testGroup = null
      const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
      if (setLimitTestGroup) {
        testGroup = setLimitTestGroup
      }

      window.analytics.page('Upgrade', {
        stage: 'Payment',
        // eslint-disable-next-line camelcase
        test_group_set_limit: testGroup,
      })
    }
    if (typeof window.gtag === 'function') {
      ReactGA.set({ page: '/upgrade-payment-page' })
      ReactGA.send({ hitType: 'pageview' })
    }
  }

  render() {
    const { trialUsed, isDiscountPeriod, billingInfo } = this.props

    let header = 'Upgrade to Pro Today'
    if (!trialUsed) {
      header = 'Start your 14-day trial'
    }

    const subHeader = 'No commitments. Cancel anytime with a click.'
    const {
      percentageSavingStandardYearly, standardMonthlyPricePerMonth, standardYearlyPricePerYear, standardYearlyPricePerMonth,
    } = planPricingDetails
    let discountMonthlyPricePerMonth
    let discountYearlyPricePerMonth
    let discountYearlyPricePerYear
    let monthlyDiscount = false
    let yearlyDiscount = false
    if (isDiscountPeriod) {
      const couponDetails = couponDescriptions[process.env.REACT_APP_DEFAULT_COUPON_CODE]
      discountMonthlyPricePerMonth = couponDetails.discountMonthlyPricePerMonth
      discountYearlyPricePerMonth = couponDetails.discountYearlyPricePerMonth
      discountYearlyPricePerYear = couponDetails.discountYearlyPricePerYear
      monthlyDiscount = couponDetails.monthlyDiscount
      yearlyDiscount = couponDetails.yearlyDiscount
    }

    return (
      <div className='upgrade upgrade--payment marketing'>
        <div className='upgrade--payment-content'>
          <div className='upgrade-closeBtn' onClick={this.props.hideModal}>
            <Icon name='circle-with-close' />
          </div>
          {isDiscountPeriod &&
          <UpgradeDiscountSash />}

          <div className='upgrade--payment-logoContainer'>
            <Icon name='logo-pro' />
          </div>

          <div className='upgrade--payment-header'>
            {header}
          </div>

          <div className='upgrade--payment-sub'>
            {subHeader}
          </div>

          <div className='upgrade--payment-sectionStep'>
            Step 1 of 2
          </div>
          <div className='upgrade--payment-sectionHeader'>
            Select your Plan
          </div>

          <div className='upgrade--payment-sectionHeader-sub'>
            {isDiscountPeriod && (
            <React.Fragment>
              {/*
                {couponDescriptions[process.env.REACT_APP_DEFAULT_COUPON_CODE].paymentHeaderMessage}
                */}
              Lifetime Discount: save 20% forever (available until October 1st)
            </React.Fragment>
            )}
          </div>

          <div className='upgrade--payment-planSelection'>
            <PaymentSelectionBtn
              type='month'
              isActive={this.state.selectedPlan === 'month'}
              onClickFunction={() => { this.setState({ selectedPlan: 'month' }) }}
            >
              <div className='upgrade--payment-planBtn-left'>
                <div className='upgrade--payment-planBtn-left-title'>
                  Pay Monthly
                </div>
                <div className='upgrade--payment-planBtn-left-selected'>
                  {this.state.selectedPlan === 'month' &&
                  <span>Selected Plan</span>}
                </div>
              </div>

              <div className='upgrade--payment-planBtn-right'>
                {monthlyDiscount && (
                <React.Fragment>
                  <div className='upgrade--payment-planBtn-right-price'>
                    {discountMonthlyPricePerMonth}
                    {' '}
                    <span className='upgrade--payment-planBtn-right-price-sub'>/ month</span>
                  </div>
                  <div className='upgrade--payment-planBtn-right-diffPrice'>
                    Normally
                    {' '}
                    {standardMonthlyPricePerMonth}
                    /month
                  </div>
                </React.Fragment>
                )}
                {!monthlyDiscount && (
                <React.Fragment>
                  <div className='upgrade--payment-planBtn-right-price'>
                    {standardMonthlyPricePerMonth}
                    {' '}
                    <span className='upgrade--payment-planBtn-right-price-sub'>/ month</span>
                  </div>
                </React.Fragment>
                )}

              </div>

            </PaymentSelectionBtn>

            <PaymentSelectionBtn
              type='year'
              isActive={this.state.selectedPlan === 'year'}
              onClickFunction={() => { this.setState({ selectedPlan: 'year' }) }}
            >

              <div className='upgrade--payment-planBtn-left'>
                <div className='upgrade--payment-planBtn-left-title'>
                  Pay Yearly
                </div>

                <div className='upgrade--payment-planBtn-left-selected'>
                  {this.state.selectedPlan === 'year' &&
                  <span>Selected Plan</span>}
                </div>

              </div>

              <div className='upgrade--payment-planBtn-right'>
                {yearlyDiscount && (
                <React.Fragment>
                  <div className='upgrade--payment-planBtn-right-price'>
                    {discountYearlyPricePerMonth}
                    {' '}
                    <span className='upgrade--payment-planBtn-right-price-sub'>/ month</span>
                  </div>
                  <div className='upgrade--payment-planBtn-right-diffPrice'>
                    {discountYearlyPricePerYear}
                    /year. Normally
                    {standardYearlyPricePerYear}
                    /year.
                  </div>
                </React.Fragment>
                )}
                {!yearlyDiscount && (
                <React.Fragment>
                  <div className='upgrade--payment-planBtn-right-price'>
                    {standardYearlyPricePerMonth}
                    {' '}
                    <span className='upgrade--payment-planBtn-right-price-sub'>/ month</span>
                  </div>
                  <div className='upgrade--payment-planBtn-right-diffPrice'>
                    {standardYearlyPricePerYear}
                    /year (save
                    {percentageSavingStandardYearly}
                    )
                  </div>
                </React.Fragment>
                )}

              </div>

            </PaymentSelectionBtn>

          </div>

          <div className='upgrade--payment-sectionStep'>
            Step 2 of 2
          </div>
          <div className='upgrade--payment-sectionHeader'>
            Setup your Payment
          </div>

          <div className='upgrade--payment-paymentFormContainer'>

            {!trialUsed && (
            <div className='upgrade--payment-paymentFormContainer-smallPrint'>
              You may cancel at any time in your free trial and will not be charged.  To cancel, go online to your Account and click on "Cancel Trial.”
              <br />
              <br />
              Plickers will automatically continue your Plickers Pro membership at the end of your free trial and charge the membership fee to your payment method on a monthly or yearly basis (based on your plan selection) until you cancel. There are no refunds or credits for partial payment periods.
              <br />
              <br />
              {isDiscountPeriod && (
              <React.Fragment>
                {/* Discounted rate continues through 12 months from the start of your Plickers Pro membership. */}
                20% discount will be applied to your Plickers Pro membership indefinitely.
              </React.Fragment>
              )}
            </div>
            )}
            {trialUsed && (
            <div className='upgrade--payment-paymentFormContainer-smallPrint'>
              Plickers will automatically continue your Plickers Pro membership and charge the membership fee to your payment method on a monthly or yearly basis (based on your plan selection) until you cancel.
              <br />
              <br />
              To downgrade to Plickers Free, go online to your Account and click on "Downgrade".  There are no refunds or credits for partial payment periods.
              <br />
              <br />
              {isDiscountPeriod && (
              <React.Fragment>
                {/* Discounted rate continues through 12 months from the start of your Plickers Pro membership. */}
                20% discount will be applied to your Plickers Pro membership indefinitely.
              </React.Fragment>
              )}
            </div>
            )}

            <Elements
              fonts={[
                { cssSrc: 'StripeFontDefinitions.css' },
              ]}
            >
              <InjectedCheckoutForm
                isDiscountPeriod={isDiscountPeriod}
                email={this.props.email || ''}
                fullName={this.props.fullName}
                plan={this.state.selectedPlan}
                trialUsed={trialUsed}
                upgradePlan={this.props.upgradePlan}
                monthlyPricePerMonth={monthlyDiscount ? discountMonthlyPricePerMonth : standardMonthlyPricePerMonth}
                yearlyPricePerYear={yearlyDiscount ? discountYearlyPricePerYear : standardYearlyPricePerYear}
                upgradeError={this.props.upgradeError}
                billingInfo={billingInfo}
              />
            </Elements>

          </div>
        </div>

      </div>

    )
  }
}

export default UpgradePaymentPage

import store from '../../store'
import find from 'lodash/find'
import {formatSubjectToString} from '../formatSubjectToString'
import {formatGradesForAnalytics} from './formatGradesForAnalytics'



export function sendPublishRepoEvent(repoId) {
	const state = store.getState()
	const repo= find(state.repos, {'id':repoId})
	const service= state.service

	if(window.analytics && repo){
		
		
		const repoName=repo.name
		const repoDescription=repo.description
		let isPro=false
		if(service.product==='plus'){
			isPro=true
		}
		const formattedSubject=formatSubjectToString(repo.subject)

		let analyticsInfo=formatGradesForAnalytics(repo.grade)

		window.analytics.track('Published Repo',{...analyticsInfo,
			repoId:repoId,
			'name':repoName,
			subject:formattedSubject,
			language:repo.language,
			gradeArray:repo.grade,
			authorUsername:repo.author.username,
			description:repoDescription,
			authorIsPro:isPro

		})

	}
	
}








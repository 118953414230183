import _ from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { removeExternalSignin } from '../../actions/user'
import { hideModal } from '../../actions/modals'
import { showNotification } from '../../actions/notifications'
import ReauthenticationModal from '../../components/reauthenticationModal/ReauthenticationModal'
import FeatureModalSelectControl from '../../components/featureModals/FeatureModalSelectControl'
import Button from '../../components/uiKit/Button'
import handleAccountRequest from './utils/handleAccountRequest'

function RemoveExternalSigninContainer(props) {
  const {
    hideModal,
    showNotification,
    removeExternalSignin,
    user,
    externalAccountType,
  } = props
  const [shouldLogOut, setShouldLogOut] = useState(false)
  const [showReauthenticationModal, setShowReauthenticationModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const externalTypeString = _.startCase(externalAccountType)

  function onAuthenticate(authentication) {
    const requestData = {
      type: externalAccountType,
      password: authentication.password,
    }
    handleAccountRequest({
      setSubmitLoading,
      setErrorMessage,
      makeRequest: () => removeExternalSignin(user.id, requestData, shouldLogOut),
      hideModal,
      showSuccessNotification: () => showNotification(`Sign in with ${externalTypeString}`, 'Removed', 'default'),
      requestName: externalAccountType === 'google' ? 'removeGoogleSignin' : 'removeAppleSignin',
    })
  }

  return (
    <div className='accountAuthentication is--remove'>
      <div className='accountAuthentication-explanationText'>
        {`Please confirm that you would like to remove Sign in with ${externalTypeString}`}
      </div>
      <div className='accountAuthentication-signOutEverywhere'>
        <FeatureModalSelectControl
          checkbox
          checked={shouldLogOut}
          onClick={() => (setShouldLogOut(!shouldLogOut))}
          label='Require all devices to sign in again'
        />
      </div>
      <div className='accountAuthentication-actionRow'>
        <Button
          size='large'
          label='Confirm'
          color='blue'
          type='submit'
          onClickFunction={() => setShowReauthenticationModal(true)}
          submitLoading={submitLoading}
        />
      </div>

      {errorMessage && (
      <div className='accountAuthentication-error'>
        {errorMessage}
      </div>
      )}

      {showReauthenticationModal && (
        <ReauthenticationModal
          title={`Remove Sign in with ${externalTypeString}`}
          signinMethodsToDisplay={{ password: true }}
          onAuthenticate={(authentication) => onAuthenticate(authentication)}
          hideModal={() => setShowReauthenticationModal(false)}
        />
      )}
    </div>
  )
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  {
    removeExternalSignin,
    hideModal,
    showNotification,
  },
)(RemoveExternalSigninContainer)

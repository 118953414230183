import filter from 'lodash/filter'
import find from 'lodash/find'
import remove from 'lodash/remove'


export function processQuestionResponses(poll,section) {
	let responsesByCardArray=[]
	if(poll.responsesByCard){
		Object.keys(poll.responsesByCard).map((key) => {
			const response=poll.responsesByCard[key]
			const modifiedResponse={...response,card:key}
			responsesByCardArray.push(modifiedResponse)
			return null
		})
	}

	const responsesAnswerA=filter(responsesByCardArray, {'answer':'A'})
	const responsesAnswerB=filter(responsesByCardArray, {'answer':'B'})
	const responsesAnswerC=filter(responsesByCardArray, {'answer':'C'})
	const responsesAnswerD=filter(responsesByCardArray, {'answer':'D'})
			
	let studentListforChoiceA = []
	let studentListforChoiceB = []
	let studentListforChoiceC = []
	let studentListforChoiceD = []
	let studentListInvalidChoice = []
	let missingStudentsList=filter(section.students,{archived:false}).slice(0)

	for (let i = 0 ; i < responsesAnswerA.length ; i++) {
	  	const response = responsesAnswerA[i]
	  	const studentId = response.student
	  	const student = find(section.students, {'id':studentId})
	  	remove(missingStudentsList,{'id':studentId})
	  	if(student){
	  		studentListforChoiceA.push({displayName:student.displayName,card:student.card})
	  	} else{
	  		studentListforChoiceA.push({displayName:'Guest',card:response.card})
	  	}
	  }

	for (let i = 0; i < responsesAnswerB.length ;i++) {
	  	const response = responsesAnswerB[i]
	  	const studentId = response.student
	  	const student = find(section.students, {'id':studentId})
	  	remove(missingStudentsList,{'id':studentId})
	  	if(student){
	  		studentListforChoiceB.push({displayName:student.displayName,card:student.card})
	  	} else{
	  		studentListforChoiceB.push({displayName:'Guest',card:response.card})
	  	}
	  }

	 var hasChoiceC=false
	 var hasChoiceD=false

	 if(poll.snapshot){
	 	if(poll.snapshot.choices[2]){
	 		hasChoiceC=true
	 	}
	 	if(poll.snapshot.choices[3]){
	 		hasChoiceD=true
	 	}
	 }

	for (let i = 0; i < responsesAnswerC.length ; i++) {
	  	const response = responsesAnswerC[i]
	  	const studentId = response.student
	  	const student = find(section.students, {'id':studentId})
	  	remove(missingStudentsList,{'id':studentId})
	  	let studentName

	  	if(student){
	  		studentName=student.displayName
	  	} else{
	  		studentName=`Card ${response.card} (Guest)`
	  	}
	  	if(hasChoiceC){
	  		if(student){
	  			studentListforChoiceC.push({displayName:student.displayName,card:student.card})
	  		}else{
	  			studentListforChoiceC.push({displayName:'Guest',card:response.card})
	  		}
	  	}else{
	  		//studentListInvalidChoice.push(studentName)
	  		studentListInvalidChoice.push({displayName:studentName,card:response.card})
	  	}

	  }


	for (let i = 0 ; i < responsesAnswerD.length ; i++) {
		const response = responsesAnswerD[i]
		const studentId = response.student
		const student = find(section.students, {'id':studentId})
		remove(missingStudentsList,{'id':studentId})
		let studentName

	  	if(student){
	  		studentName=student.displayName
	  	} else{
	  		studentName=`Card ${response.card} (Guest)`
	  	}

		if(hasChoiceD){
	  		if(student){
	  			studentListforChoiceD.push({displayName:student.displayName,card:student.card})
	  		}else{
	  			studentListforChoiceD.push({displayName:'Guest',card:response.card})
	  		}
	  	}else{
	  		//studentListInvalidChoice.push(studentName)
	  		studentListInvalidChoice.push({displayName:studentName,card:response.card})

	  		
	  	}
	}

	let missingStudentsNames=[]

	missingStudentsList.map((student)=>{
		missingStudentsNames.push({displayName:student.displayName,card:student.card})
		return null
	})


	
	const processedResponces={
		'responsesAnswerA':studentListforChoiceA,
		'responsesAnswerB':studentListforChoiceB,
		'responsesAnswerC':studentListforChoiceC,
		'responsesAnswerD':studentListforChoiceD,
		'invalidResponses':studentListInvalidChoice,
		'missingStudentsList':missingStudentsNames

	}
  
	return processedResponces

}

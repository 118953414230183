import api from '../api'

// Google classroom stuff
// http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#integration
export function fetchIntegrations() {
  return (dispatch) => api.fetch('/integrations')
    .then((response) => {
      dispatch({ type: 'FETCH_INTEGRATIONS_SUCCESS', response })
      return response
    })
    .catch(() => {})
}

export function fetchIntegrationSections(integrationId) {
  return () => api.fetch(`/integrations/${integrationId}/sections`)
    .then((response) => response)
    .catch((error) => {
      if (window.analytics) {
        const errorCode = error.response.status
        window.analytics.track('Fetch integration sections error', { error, errorCode })
      }
    })
}

export function upsertIntegration(data) {
  return () => api.put('/integrations', data)
    .then((integration) => integration)
}

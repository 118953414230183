import React from 'react'
import { Redirect } from 'react-router-dom'
//App root redirects to Library if user is authenticated or Login page if they are not
const MatchRootAuthenticated = ({
	path,
	isAuthenticated,
	willAuthenticate,
	location,
	component: Component,
}: Props) =>

{
	if (isAuthenticated){
		return( 
			<Redirect to={{ pathname: '/library' }} />
		)
	} 
	else if(!isAuthenticated && !willAuthenticate){
		return( 
			<Redirect to={{ pathname: '/login' }} />
		)
	}
	else return null
}

export default MatchRootAuthenticated
class FriendlyFractionDenominatorNodeView {

	constructor(node) {
		this.node = node
		this.dom = this.contentDOM = document.createElement("span")
		this.dom.classList.add("friendlyFraction-denominator")
		const textContent= this.node.textContent
		let empty=true
		if(textContent && textContent.length!==0){
			empty=false
		} 
		if(empty){
			this.dom.classList.add("friendlyFraction-denominator--empty")
		}
	}

	update(node) {
		if (node.type !== this.node.type) return false
		this.dom.classList=''
		this.dom.classList.add("friendlyFraction-denominator")
		const textContent= node.textContent
		let empty=true
		if(textContent && textContent.length!==0){
			empty=false
		} 
		if(empty){
			this.dom.classList.add("friendlyFraction-denominator--empty")
		}
		this.node=node
		return true
	}

}

export default FriendlyFractionDenominatorNodeView


module.exports = {
	'9TrIeif9':{
		percentage:25,
		yearlyDiscount:true,
		monthlyDiscount:true,
		until:'October 15th',
		paymentHeaderMessage:'25% off when you upgrade by October 15th.',
		discountMonthlyPricePerMonth:'$6.74',
		discountYearlyPricePerMonth:'$4.49',
		discountYearlyPricePerYear:'$53.91',

	},
	'vveGCvpu':{
		percentage:25,
		yearlyDiscount:true,
		monthlyDiscount:true,
		until:'October 15th',
		paymentHeaderMessage:'25% off when you upgrade by October 15th.',
		discountMonthlyPricePerMonth:'$6.74',
		discountYearlyPricePerMonth:'$4.49',
		discountYearlyPricePerYear:'$53.91',

	},
	'YEARLY20':{
		percentage:20,
		yearlyDiscount:true,
		monthlyDiscount:false,
		until:'October 3rd',
		paymentHeaderMessage:'Save up to 45% until October 3rd',
		discountMonthlyPricePerMonth:null,
		discountYearlyPricePerMonth:'$4.79',
		discountYearlyPricePerYear:'$57.50',

	},
	'20FOREVER':{
		percentage:20,
		yearlyDiscount:true,
		monthlyDiscount:true,
		until:'October 1st',
		paymentHeaderMessage:'Save 20% until October 1st',
		discountMonthlyPricePerMonth:'$7.19',
		discountYearlyPricePerMonth:'$4.79',
		discountYearlyPricePerYear:'$57.50',

	}

}


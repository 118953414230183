const QuestionAnimatedGIFNodeSpec = {
  inline: false,
  attrs: {
    nodeId:{default:''},
    addedAt:{default:''},
    fileId:{default:''},
    title:{default:''},
    host:{default:''},
    stillUrl:{default:''},
    webpUrl:{default:''},
    gifUrl:{default:''},
    giphySlug:{default:null},
    giphyUser:{default:null},
    dimensions:{default:null},
    imageFill:{default:false},
    attribution:{default:''},
    placeholder:{default:''},
    placeholderDimensions:{default:{h:'1',w:'1'}},
    questionMediaDynamicHeight:{default: null},
    template: {default: null},
    slideWidth:{default:''}
    
    
  },
  selectable:false,
  draggable:false,
  group: 'block',
  parseDOM: [{tag: 'questionAnimatedGIF'}],
 toDOM(node) {
    return ['questionAnimatedGIF']},
}
export default QuestionAnimatedGIFNodeSpec
  
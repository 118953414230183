import React, { Component } from 'react'
import Button from '../uiKit/Button'

//when user downgrades we ask for feedback
//feedback is sent to segment

class DowngradeFeedbackModalFeedbackOption extends React.Component{
	render(){		
		const {isSelected,label} = this.props
		return(
			<div className={'downgradeModal--feedback-option ' + (isSelected ? 'downgradeModal--feedback-option--selected' : 'downgradeModal--feedback-option--unselected')} onClick={this.props.toggleIsSelected} >
				{label}
			</div>
		)
	}
}

const feedbackOptions=[
	'The free features are good enough for me!',
	"I'm teaching different classes this year where Plickers isn't as useful",
	"I've had problems using the mobile apps or web app",
	"I don't use Plickers enough to justify the cost",
	"I've started using other tools for formative assessment",
	'Plickers is too expensive',
	"I'm only downgrading temporarily, I'll be back!"
]

class DowngradeFeedbackModal extends Component {
  	
	constructor(){
		super() 	
		this.toggleIsSelected=this.toggleIsSelected.bind(this)
		this.submitFeedback=this.submitFeedback.bind(this)
		let feedbackObject={}
		feedbackOptions.map((option,index)=>{
			feedbackObject[index]={
				'selected':false
			}
			return null
		})
 		this.state=({ 	
 			feedbackObject:feedbackObject,
 			feedbackText:'',
 			submitLoading:false		
 		})
 	}

 	submitFeedback(){
		this.setState({submitLoading:true})
		setTimeout(function() { //short timer so it feels like button is doing something rather than modal just shutting
			if(window.analytics){
				const {feedbackObject,feedbackText}=this.state
				const {serviceStatus}=this.props	
				if(feedbackText){
					window.analytics.track('Downgrade Feedback', {
					  	userInput:true,
					  	feedbackText:feedbackText,
					  	subscriptionStatus:serviceStatus
					})
				}
				feedbackOptions.map((feedbackItem,i)=>{
					if(feedbackObject[i].selected){
						window.analytics.track('Downgrade Feedback', {
					  	userInput:false,
					  	feedbackText:feedbackItem,
					  	subscriptionStatus:serviceStatus
					})
					}
					return null
				})
			}
			this.props.hideModal()
		}.bind(this),	400)
 	}

	toggleIsSelected(optionIndex){
		let newFeedbackObject=Object.assign({},this.state.feedbackObject)
		let newIsSelected=!this.state.feedbackObject[optionIndex].selected
		newFeedbackObject[optionIndex]={
			'selected':newIsSelected
		}
		this.setState({feedbackObject:newFeedbackObject})
	}

	render() {
		return (
			<div className='downgradeModal downgradeModal--feedback'>
				<div className='downgradeModal--feedback-h1'>
					Your subscription has been cancelled.
				</div>
							
				<div className='downgradeModal--feedback-h2'>
					It'd be super-helpful for us to understand why you've left us.
				</div>										

				<div className='downgradeModal--feedback-optionsContainer'>
					{feedbackOptions.map((option,i)=>{
						return <DowngradeFeedbackModalFeedbackOption
						label={option}	
						isSelected={this.state.feedbackObject[i].selected}
						toggleIsSelected={()=>{this.toggleIsSelected(i)}}
						key={i}	
						/>
					})}		
					
					<div className='downgradeModal--feedback-option downgradeModal--feedback-option--unselected'>
						<textarea 
							className='downgradeModal--feedback-option-textArea' 
							placeholder='Other'
							resize="off"
							value={this.state.feedbackText}
							onChange={(e)=>{this.setState({feedbackText:e.target.value})}}
						/>						
					</div>

				</div>
				<Button onClickFunction={this.submitFeedback} label='Done' size='xLarge' color='blue'submitLoading={this.state.submitLoading}/>
			</div>
		)
	}
}

export default DowngradeFeedbackModal
import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import FormFieldText from '../../uiKit/FormFieldText'
import Button from '../../uiKit/Button'

const regexHasConsecutiveUnderscores = new RegExp('[_]{2,}')
const regexHasEndingUnderscore = new RegExp('.*_$')
const regexValidCharacters = new RegExp('^[a-zA-Z]?([a-zA-Z0-9]|(_))+[a-zA-Z0-9]?$')
/// ////// Validations//////////////

// const requiredUsername = value => value ? undefined : 'Please enter a username'

// const noSpaces=value => value && value.replace(/\s/g, '')
// const lowerCase=value => value && value.toLowerCase()

const normalizeUsername = (value) => {
  if (value) {
    const noSpaces = value.replace(/\s/g, '')
    return noSpaces.toLowerCase()
  } return ''
}

/// /////////////////////////////////

class EditUserUsernameForm extends React.Component {
  render() {
    const {
      currentValue,
      dirty,
      pristine,
      submitLoading,
      handleSubmit,
      hideModal,
    } = this.props

    const minUsernameLength = 4
    const maxUsernameLength = 15

    let tooLongError = false
    let tooShortError = false
    const isEmptyError = !currentValue

    if (currentValue && currentValue.length > maxUsernameLength) {
      tooLongError = true
    }

    if (currentValue && currentValue.length < minUsernameLength) {
      tooShortError = true
    }

    let characterCount
    if (currentValue) {
      characterCount = currentValue.length
    } else {
      characterCount = 0
    }

    const doubleUnderscoreError = regexHasConsecutiveUnderscores.test(currentValue)
    const endingUnderscoreError = regexHasEndingUnderscore.test(currentValue)
    const containsSpecialCharacterError = !(regexValidCharacters.test(currentValue) || currentValue === '')

    const isReadyToSubmit = !!(
      !tooShortError &&
      !tooLongError &&
      !isEmptyError &&
      !doubleUnderscoreError &&
      !endingUnderscoreError &&
      !containsSpecialCharacterError &&
      dirty &&
      !submitLoading
    )

    return (
      <form onSubmit={handleSubmit} autoComplete="off">

        <Field
          normalize={normalizeUsername}
          name="username"
          type="text"
          size="large"
          component={FormFieldText}
          placeholder="Username"
          autoComplete="false"
          maxLength={30}
        />

        <div
          className={
            `updateUserNameModal-characterCounter${tooLongError ? ' updateUserNameModal-characterCounter--aboveMax' : ''}`
          }
        >
          {characterCount}
          {' '}
          /
          {' '}
          {maxUsernameLength}
        </div>

        <div className="updateUserNameModal-notes">

          {!containsSpecialCharacterError && (
            <div className="updateUserNameModal-notes-note">
              Letters, numbers and underscores allowed.
            </div>
          )}
          {containsSpecialCharacterError && (
            <div className="updateUserNameModal-notes-note updateUserNameModal-notes-note--bold">
              Only letters, numbers and underscores allowed.
            </div>
          )}

          {doubleUnderscoreError && (
            <div className="updateUserNameModal-notes-note updateUserNameModal-notes-note--bold">
              Consecutive underscores are not allowed.
            </div>
          )}

          {endingUnderscoreError && (
            <div className="updateUserNameModal-notes-note updateUserNameModal-notes-note--bold">
              Ending in underscore is not allowed.
            </div>
          )}

          {
            !pristine &&
            !isEmptyError &&
            !doubleUnderscoreError &&
            !endingUnderscoreError &&
            !containsSpecialCharacterError &&
            !tooShortError &&
            !tooLongError &&
            (
              <div className="updateUserNameModal-notes-note">
                Your new profile URL will be
                {' '}
                {window.location.host}
                /
                {currentValue}
              </div>
            )
          }

        </div>

        <div className="updateUserNameModal-actionRow">
          <Button
            size="xLarge"
            submitLoading={submitLoading}
            label="Edit Username"
            name="update"
            disabled={!isReadyToSubmit}
            color={isReadyToSubmit ? 'blue' : 'gray'}
            canSubmit={isReadyToSubmit}
            type="submit"
          />
          <Button
            size="xLarge"
            color="white"
            label="Cancel"
            onClickFunction={hideModal}
          />
        </div>

      </form>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let currentValue
  if (state.form && state.form.editUserNameForm) {
    currentValue = state.form.editUserNameForm.values.username
  }

  return {
    initialValues: {
      username: ownProps.username,
    },
    currentValue,
  }
}

const EditUserUsernameFormToExport = reduxForm({
  form: 'editUserNameForm',
  enableReinitialize: true,
})(EditUserUsernameForm)

export default connect(mapStateToProps)(EditUserUsernameFormToExport)

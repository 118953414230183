import React from 'react'
import loadable from '@loadable/component'
import PlickersCardSVG from './PlickersCardSVG'

const CartoonScreenSVG = loadable(() => import(/*webpackChunkName: 'cartoonscreenSVG'*/ './CartoonScreenSVG'))


class CartoonDevice extends React.Component{


	
 	render() { 	
   	
   	switch (this.props.device) {
     
     case 'iPhone':
     return (
	    <div className='cartoonDevice cartoonDevice--iPhone'>

	    	<div className='cartoonDevice--iPhone-topSensors'>
	    		<svg className="cartoonDevice--iPhone-topSensors-svg" width="427" height="66" viewBox="0 0 427 66" xmlns="http://www.w3.org/2000/svg">  	    				    		
					  <g fillRule="evenodd">
					    <rect width="74" height="7" x="176" y="54" fill="#F1F1F1" rx="3.5"/>
					    <circle cx="213" cy="29" r="5" fill="#F1F1F1"/>
					    <circle cx="145.5" cy="57.5" r="7.5" fill="#F1F1F1"/>
					  </g>
					</svg>
	    	</div>
	    	
	    	<div className='cartoonDevice-screen'>
	    		<CartoonScreenSVG screen={this.props.screen} />
	    	</div>

	    	<div className='cartoonDevice-body' />

	    	{this.props.showContactShadow &&
    			<div className='cartoonDevice-contactShadow' />	    			    		
    		}
	    	
	    </div>
		 )


		case 'laptop':
     return (
	    <div className='cartoonDevice cartoonDevice--laptop'>
	    
	    	<div className='cartoonDevice-screen'>	    		
	    		<CartoonScreenSVG screen={this.props.screen} />
	    	</div>

	    	<div className='cartoonDevice--laptop-base'>	    		
	    	</div>
	    	
	    </div>
		 )

   	default:
    return null;
 		}
 	}
}



export default CartoonDevice



import React, {Component} from 'react'
import Button from '../../components/uiKit/Button'
import {getPublicUrlForRepo} from '../../utils/getPublicUrlForRepo'
import FeatureModalSelectControl from '../../components/featureModals/FeatureModalSelectControl'

class SubmitForPackReviewModalGuideline extends Component{

	render(){	


		return(					     	 
			<div className='submitEarlyReleaseApprovalModal-guideline'>	
				<div className='submitEarlyReleaseApprovalModal-guideline-number'>
					{this.props.guidelineNumber}
				</div>
				<div className='submitEarlyReleaseApprovalModal-guideline-title'>
					{this.props.guidelineTitle}
				</div>
				<div className='submitEarlyReleaseApprovalModal-guideline-description'>
					{this.props.guidelineDescription}
				</div>							
			</div>				
		)
	}
}


 
					


class SubmitForPackReviewModal extends Component{

	constructor(props) {
		super(props)
		this.submitForReview=this.submitForReview.bind(this)
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)

		
		this.state = {
			submitLoading:false,
			checkbox1Checked:false,
			checkbox2Checked:false,
			checkbox3Checked:false,
			checkbox4Checked:false,
			checkbox5Checked:false,
			checkbox6Checked:false
		}
	}


	componentDidMount() {
		if(window.analytics){
			window.analytics.page('Submit For Pack Review Modal')
		}
	}


	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden' //prevent scrolling while modal is open
	}

 
	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}


	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.props.closeModal() //close modal is click outside modal
	}


	submitForReview(){
		this.setState({submitLoading:true})

		setTimeout(function() { 				
			this.props.submitRepoForReview(this.props.repo.id).then(()=>{
				if(window.analytics){
					
					window.analytics.track('Submitted Repo For Review',{
						repoName:this.props.repo.name,
						repoSubject:this.props.repo.subject.name,
						author:this.props.repo.author.username,
						repoLink:getPublicUrlForRepo(this.props.repo)
				})
			}
			this.props.closeModal()
			this.props.showNotification(`${this.props.repo.name} for Early Release`,'Submitted','create')
		})
		}.bind(this),500)
	}

 

	render(){	
		const {checkbox1Checked,checkbox2Checked,checkbox3Checked,checkbox4Checked,checkbox5Checked,checkbox6Checked}=this.state
		const submitDisabled = !(checkbox1Checked && checkbox2Checked && checkbox3Checked && checkbox4Checked && checkbox5Checked&& checkbox6Checked)


		return(					
     	 <div className='submitEarlyReleaseApprovalModalContainer' onMouseDown={this.handleBackgroundClick}>				
				<div className='submitEarlyReleaseApprovalModal'>	
					<div className='submitEarlyReleaseApprovalModal-subtitle'>
						Early Release Approval
					</div>
					<div className='submitEarlyReleaseApprovalModal-title'>
						Early Release Pack Guidelines
					</div>
					<div className='submitEarlyReleaseApprovalModal-description'>
						Confirm your pack meets each of the following guidelines before submitting:
					</div> 

					<div className='submitEarlyReleaseApprovalModal-guidelinesContainer'>
						<div className='submitEarlyReleaseApprovalModal-guidelineContainer'>
							<SubmitForPackReviewModalGuideline
								guidelineNumber='1'
								guidelineTitle='Focused to a topic'
								guidelineDescription='Typically, packs should cover a topic area within a single subject: an entire course is too large in scope, and a single lesson of content is too small. However, provided they are comprehensive, course-review packs will also be considered.'
							/>
							<FeatureModalSelectControl
								checkbox
								checked={checkbox1Checked}
								onClick={()=>{this.setState({checkbox1Checked:!checkbox1Checked})}}
								label="My pack meets this guideline" 
							/>
							<div className='submitEarlyReleaseApprovalModal-guidelineContainer-icon'>
								<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">    
			            <path d="M29.4915126,17.9156102 L4.63676576,31.7238029 C3.57869321,32.3116209 2.24443523,31.9304044 1.65661715,30.8723318 C1.47573889,30.546751 1.38081927,30.1804442 1.38081927,29.807993 L1.38081927,2.19160771 C1.38081927,0.981216192 2.36203546,5.32907052e-14 3.57242697,5.32907052e-14 C3.9448781,5.32907052e-14 4.3111849,0.0949196272 4.63676576,0.275797883 L29.4915126,14.0839905 C30.5495851,14.6718086 30.9308017,16.0060666 30.3429836,17.0641392 C30.144229,17.4218973 29.8492707,17.7168556 29.4915126,17.9156102 Z"></path>    
			          </svg>
							</div>
						</div>
						<div className='submitEarlyReleaseApprovalModal-guidelineContainer'>							
							<SubmitForPackReviewModalGuideline
								guidelineNumber='2'
								guidelineTitle='Substantial'
								guidelineDescription='For the given topic area, packs are substantial and complete and contain multiple lessons worth of content. Packs should typically have between 50 and 200 questions.'
							/>
							<FeatureModalSelectControl
								checkbox
								checked={checkbox2Checked}
								onClick={()=>{this.setState({checkbox2Checked:!checkbox2Checked})}}
								label="My pack meets this guideline" 
							/>
							<div className='submitEarlyReleaseApprovalModal-guidelineContainer-icon'>
								<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">    
			            <path d="M29.4915126,17.9156102 L4.63676576,31.7238029 C3.57869321,32.3116209 2.24443523,31.9304044 1.65661715,30.8723318 C1.47573889,30.546751 1.38081927,30.1804442 1.38081927,29.807993 L1.38081927,2.19160771 C1.38081927,0.981216192 2.36203546,5.32907052e-14 3.57242697,5.32907052e-14 C3.9448781,5.32907052e-14 4.3111849,0.0949196272 4.63676576,0.275797883 L29.4915126,14.0839905 C30.5495851,14.6718086 30.9308017,16.0060666 30.3429836,17.0641392 C30.144229,17.4218973 29.8492707,17.7168556 29.4915126,17.9156102 Z"></path>    
			          </svg>
							</div>
						</div>
						<div className='submitEarlyReleaseApprovalModal-guidelineContainer'>
							<SubmitForPackReviewModalGuideline
								guidelineNumber='3'
								guidelineTitle='Useful to teachers everywhere'
								guidelineDescription='Packs should be easy to understand and use, with a clear pack title and accurate subject and grade-level information. Packs should not reference or require external sources unless they are widely accessible.'
							/>
							<FeatureModalSelectControl
								checkbox
								checked={checkbox3Checked}
								onClick={()=>{this.setState({checkbox3Checked:!checkbox3Checked})}}
								label="My pack meets this guideline" 
							/>
							<div className='submitEarlyReleaseApprovalModal-guidelineContainer-icon'>
								<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">    
			            <path d="M29.4915126,17.9156102 L4.63676576,31.7238029 C3.57869321,32.3116209 2.24443523,31.9304044 1.65661715,30.8723318 C1.47573889,30.546751 1.38081927,30.1804442 1.38081927,29.807993 L1.38081927,2.19160771 C1.38081927,0.981216192 2.36203546,5.32907052e-14 3.57242697,5.32907052e-14 C3.9448781,5.32907052e-14 4.3111849,0.0949196272 4.63676576,0.275797883 L29.4915126,14.0839905 C30.5495851,14.6718086 30.9308017,16.0060666 30.3429836,17.0641392 C30.144229,17.4218973 29.8492707,17.7168556 29.4915126,17.9156102 Z"></path>    
			          </svg>
							</div>
						</div>
						<div className='submitEarlyReleaseApprovalModal-guidelineContainer'>
							<SubmitForPackReviewModalGuideline
								guidelineNumber='4'
								guidelineTitle='Well-Organized'
								guidelineDescription='Pack contents are well-organized into clearly defined and well-labelled sets for each subtopic. Except in rare cases, folders and independent questions should be avoided.'
							/>						
							<FeatureModalSelectControl
								checkbox
								checked={checkbox4Checked}
								onClick={()=>{this.setState({checkbox4Checked:!checkbox4Checked})}}
								label="My pack meets this guideline" 
							/>
							<div className='submitEarlyReleaseApprovalModal-guidelineContainer-icon'>
								<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">    
			            <path d="M29.4915126,17.9156102 L4.63676576,31.7238029 C3.57869321,32.3116209 2.24443523,31.9304044 1.65661715,30.8723318 C1.47573889,30.546751 1.38081927,30.1804442 1.38081927,29.807993 L1.38081927,2.19160771 C1.38081927,0.981216192 2.36203546,5.32907052e-14 3.57242697,5.32907052e-14 C3.9448781,5.32907052e-14 4.3111849,0.0949196272 4.63676576,0.275797883 L29.4915126,14.0839905 C30.5495851,14.6718086 30.9308017,16.0060666 30.3429836,17.0641392 C30.144229,17.4218973 29.8492707,17.7168556 29.4915126,17.9156102 Z"></path>    
			          </svg>
							</div>
						</div>
						<div className='submitEarlyReleaseApprovalModal-guidelineContainer'>
							<SubmitForPackReviewModalGuideline
								guidelineNumber='5'
								guidelineTitle='High-quality questions'
								guidelineDescription='Questions are clear, rigorous, accurate, consistent, and engaging, with visual aids where helpful.'
							/>
							<FeatureModalSelectControl
								checkbox
								checked={checkbox5Checked}
								onClick={()=>{this.setState({checkbox5Checked:!checkbox5Checked})}}
								label="My pack meets this guideline" 
							/>
							<div className='submitEarlyReleaseApprovalModal-guidelineContainer-icon'>
								<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">    
			            <path d="M29.4915126,17.9156102 L4.63676576,31.7238029 C3.57869321,32.3116209 2.24443523,31.9304044 1.65661715,30.8723318 C1.47573889,30.546751 1.38081927,30.1804442 1.38081927,29.807993 L1.38081927,2.19160771 C1.38081927,0.981216192 2.36203546,5.32907052e-14 3.57242697,5.32907052e-14 C3.9448781,5.32907052e-14 4.3111849,0.0949196272 4.63676576,0.275797883 L29.4915126,14.0839905 C30.5495851,14.6718086 30.9308017,16.0060666 30.3429836,17.0641392 C30.144229,17.4218973 29.8492707,17.7168556 29.4915126,17.9156102 Z"></path>    
			          </svg>
							</div>
						</div>
						<div className='submitEarlyReleaseApprovalModal-guidelineContainer'>
							<SubmitForPackReviewModalGuideline
								guidelineNumber='6'
								guidelineTitle='Original and meets Content Policy'
								guidelineDescription='Packs contents should be original and meet the Plickers Content Policy, including no objectionable content or copyright infringement.'
							/>
							<FeatureModalSelectControl
								checkbox
								checked={checkbox6Checked}
								onClick={()=>{this.setState({checkbox6Checked:!checkbox6Checked})}}
								label="My pack meets this guideline" 
							/>
							<div className='submitEarlyReleaseApprovalModal-guidelineContainer-icon'>
								<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">    
			            <path d="M29.4915126,17.9156102 L4.63676576,31.7238029 C3.57869321,32.3116209 2.24443523,31.9304044 1.65661715,30.8723318 C1.47573889,30.546751 1.38081927,30.1804442 1.38081927,29.807993 L1.38081927,2.19160771 C1.38081927,0.981216192 2.36203546,5.32907052e-14 3.57242697,5.32907052e-14 C3.9448781,5.32907052e-14 4.3111849,0.0949196272 4.63676576,0.275797883 L29.4915126,14.0839905 C30.5495851,14.6718086 30.9308017,16.0060666 30.3429836,17.0641392 C30.144229,17.4218973 29.8492707,17.7168556 29.4915126,17.9156102 Z"></path>    
			          </svg>
							</div>
						</div>
						
						
										
									

					</div>
				
				
					<div className='submitEarlyReleaseApprovalModal-btnContainer'>						

     				<Button 
     					size='xLarge'
     					submitLoading={this.state.submitLoading}
     					onClickFunction={this.submitForReview}
     					label='Submit Pack for Early Release Approval'
     					color={submitDisabled?'gray':'blue'}
     					disabled={submitDisabled}
     					name='submitearlyreleaseapprovalmodalsubmit'
     				/>     				

     			</div>

     			<div className='submitEarlyReleaseApprovalModal-cancel' onClick={this.handleBackgroundClick}>
     				Cancel
     			</div>
  
			</div>	
		</div>				
		)
	}
}


 
					



export default SubmitForPackReviewModal

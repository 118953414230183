export default function (state=null, action) {
	switch (action.type) {		
		case 'FETCH_USER_CONFIG_SUCCESS':
			return action.response
		case 'UPDATE_USER_CONFIG_SUCCESS':
			return action.response
		case 'LOGOUT':
			return null			
		default:
			return state
	}
}



module.exports ={
		cover5:{
			name:'cover5',
			x:777,
			y:352,
			width:40,
			height:40,
			inImageContainerSafeArea:false,
			//inCropSafeArea:false
		},
		cover4:{
			name:'cover4',
			x:777,
			y:352,
			width:40,
			height:40,
			inImageContainerSafeArea:false,
			//inCropSafeArea:false
		},
		cover3:{
			name:'cover3',
			x:777,
			y:352,
			width:40,
			height:40,
			inImageContainerSafeArea:false,
			//inCropSafeArea:false
		},
		cover2:{
			name:'cover2',
			x:777,
			y:352,
			width:40,
			height:40,
			inImageContainerSafeArea:false,
			//inCropSafeArea:false
		},
		cover1:{
			name:'cover1',
			x:777,
			y:352,
			width:40,
			height:40,
			inImageContainerSafeArea:false,
			//inCropSafeArea:false
		},
	}
import React from 'react'
import ModalWrapper from './ModalWrapper'
import Button from '../uiKit/Button'


const WhitelistErrorModal = props => {

	return (
	    <div className='cloudinaryError-modalContainer'>
	      <ModalWrapper
	        {...props}
	        title='Network Error'
	        modalStyle='light'
	      >
	      <div className='cloudinaryError-modalContent'>
		      {props.whitelistErrorSource==='cloudinary' &&
			    	<div className='cloudinaryError-modalContent-header'>
			    		Failed to upload media because your network internet is blocking access to Plickers' online media storage service.
			    	</div>
		    	}	

		    	{props.whitelistErrorSource==='imageSearch' &&
			    	<div className='cloudinaryError-modalContent-header'>
			    		Failed to search for images because your network internet is blocking access to Plickers' image search.
			    	</div>
			    }

		   	 	{(props.whitelistErrorSource==='imageSearch' || props.whitelistErrorSource==='cloudinary') &&

		    		<div>
		    			The good news is, your IT administrator should be able to fix this. <br/>
		    			If you send them the following message it should help them: <br/>
			    		<div className='cloudinaryError-modalContent-messageContents'>
				    		Plickers requires the following domains to be whitelisted:<br/><br/>
				    		<b>*.cloudinary.com</b> – used for media storage and hosting<br/>
				    		<b>*.microsoft.com</b> – used for image search via Bing<br/>
				    		<b>*.giphy.com</b> – used for GIPHY search and hosting<br/>
				    		<b>*.googleapis.com</b> – used for YouTube search<br/>
				    		<b>*.youtube.com</b> – used for YouTube video delivery<br/><br/>
				    		Some of these domains are currently inaccessible, restricting Plickers' functionality.<br/>
			    		</div>

			    		If you have any questions, don't hesitate to contact support help@plickers.com
			    	</div>
			    }

			   	{props.whitelistErrorSource==='gifSearch' &&
			    	<div>
			    	"Failed to complete gif search. Please make sure giphy.com is whitelisted in your network."
			    	</div>
			    }

			    <Button size='xLarge' color='blue' label='Done' /> 
		    </div>

	      </ModalWrapper>

	    </div>

  )
}

export default WhitelistErrorModal
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { connectSearchBox,connectHits,Configure } from 'react-instantsearch-dom'
import {createPoll} from '../../utils/createPoll'
import AlgoliaSearchWrapperContainer from '../search/AlgoliaSearchWrapperContainer'
import { showNotification } from '../../actions/notifications'
import SectionHomeAddToClassCell from '../../components/section/sectionHome/sectionHomeQueue/SectionHomeAddToClassCell'
	
//modal where user can search content and add to the queue
//opened from section home page
//if section has empty queue we show some recent items that they may want to add and a search button which opens this modal

const SearchBar = connectSearchBox(({ onFocus, onBlur, currentRefinement, refine }) => {
	let isEmpty=true
	if(currentRefinement !== ''){
		 isEmpty=false
	}
	return (
		<div className={'addToClassSearch' + (isEmpty ? ' is--empty' : ' is--nonEmpty')}>				
			<input 
					type="text"
					autoFocus={true} 
					required
					placeholder="Search"
					value={currentRefinement}
					onChange={e => {
						refine(e.target.value)
					}}      	
				/>           
		</div>
	)
})

const SearchResults = connectHits(({ hits,addToQueue,showRecentActivity }) => {
	return (
		<div className='addToClassSearch-resultsList'>
			{hits.map((item, index) => 
				<SectionHomeAddToClassCell item={item} addToQueue={addToQueue} key={index}/>
		 )}
		</div>
	)	
})


class SectionHomeAddToClassSearchModalContainer extends Component {
	
	constructor() {
		super()
		this.handleAddToQueue=this.handleAddToQueue.bind(this)
	}
	
	handleAddToQueue(suggestion){
		this.props.createPoll(suggestion,this.props.sectionId).then(() => {
			this.props.hideModal()
			const notificationMessage=`${suggestion.name||suggestion.body} to Class`
			this.props.showNotification(notificationMessage,'Added','default')
		})
	}
 
	render() {
		return (
			<div className='addFromLibrary'>          
				<AlgoliaSearchWrapperContainer isSectionHome={true}>		
					<Configure hitsPerPage={200}/>
					<SearchBar />			 		
					<SearchResults addToQueue={this.handleAddToQueue}/>		 	    
				</AlgoliaSearchWrapperContainer>   

			</div>
		)
	}
}

export default connect(
	(state) => ({
		sectionId:state.modals.sectionId,
}),
{ createPoll,showNotification}
)(SectionHomeAddToClassSearchModalContainer)
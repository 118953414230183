import React, { Component } from 'react'
import Button from '../uiKit/Button'
import planPricingDetails from '../upgrade/planPricingDetails.js'
import couponDescriptions from '../../staticData/couponDescriptions.js'
import moment from 'moment'

//Modal to switch between monthly and yearly billing

function formatTimestamp(timestamp){ //TODO move to utils
	return moment(timestamp).format('MMMM DD, YYYY')
}

class SwitchPlanModalToggleBox extends Component {  	
	render() {
		const {selectedToggle, period, current, headline,subline,mainPrice,equivalentPrice}=this.props
		let currentPeriod
		let equivalentPeriod
		if(period === 'monthly'){
			currentPeriod = 'mo'
			equivalentPeriod ='yr'
		}
		if(period === 'yearly'){
			currentPeriod = 'yr'
			equivalentPeriod ='mo'
		}
		return (
			<div onClick={this.props.onClickFunction} className={'switchPlanModalToggleBox' + (period ? ` switchPlanModalToggleBox--${period}` : '') + (selectedToggle ? ' switchPlanModalToggleBox--selectedToggle' : ' switchPlanModalToggleBox--unselectedToggle') + (current ? ' switchPlanModalToggleBox--current' : ' switchPlanModalToggleBox--notCurrent')}>   	
				<div className='switchPlanModalToggleBox-radio'>
					<div className='switchPlanModalToggleBox-radio-inner'/>
				</div>

				<div className='switchPlanModalToggleBox-left'>   	
					<div className='switchPlanModalToggleBox-left-headline'>
						{headline}
					</div>
					<div className='switchPlanModalToggleBox-left-subline'>
						{subline}
					</div>
				</div>

				<div className='switchPlanModalToggleBox-right'>   	
					<div className='switchPlanModalToggleBox-right-mainPrice'>   	
						{mainPrice}<span className='switchPlanModalToggleBox-right-mainPrice-period'>/{currentPeriod}</span>
					</div>
					<div className='switchPlanModalToggleBox-right-equivalentPrice'>   	
						{equivalentPrice}<span className='switchPlanModalToggleBox-right-equivalentPrice-period'>/{equivalentPeriod}</span>
					</div>
				</div>
			</div>
		)
	}
}

class SwitchPlanModal extends Component {
  	
	constructor(props){
		super(props)	
		this.getChangeSummaryText=this.getChangeSummaryText.bind(this)	
		let selectedPlanToggle='monthly'
		if(props.currentPlan==='yearly'){
			selectedPlanToggle='yearly'
		}
		this.state = {
			errorMessage:null,
			selectedPlanToggle:selectedPlanToggle,
		}
	} 


	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Switch Plan Modal',{currentPeriod:this.props.currentPlan})
		}
	}


	
	getChangeSummaryText(isDiscountPeriod,standardYearlyPrice,discountedYearlyPrice){//e.g.Starting on July 28, 2019, we’ll bill you once a year for Plickers Pro. Your new yearly total will be $71.88, saving over 33% versus your existing monthly billing. Charges will continue until you cancel. '
		const {currentPlan,service,upcomingInvoice}=this.props
		const {selectedPlanToggle}=this.state
		const currentSubscriptionPrice = upcomingInvoice ?`$${(upcomingInvoice.amount_due /100).toFixed(2)}`:null
		const paidUntil=formatTimestamp(service.paidUntil)
		let summary
		let onTrial = false
		if(service && service.status==='trial'){
			onTrial=true
		}
		if(currentPlan===selectedPlanToggle){
			if(currentPlan==='monthly'){
				summary=`Your Plickers Pro plan ${onTrial?'will start billing':'will renew'} on ${paidUntil}. Your current monthly total is ${currentSubscriptionPrice}.`
			}else{
				summary=`Your Plickers Pro plan ${onTrial?'will start billing':'will renew'} on ${paidUntil}. Your current annual total is ${currentSubscriptionPrice} (${upcomingInvoice ?`$${((upcomingInvoice.amount_due /100)/12).toFixed(2)}`:null} per month equivalent).`
			}
		}else if(currentPlan==='monthly' && selectedPlanToggle==='yearly'){
			summary=`Starting on ${paidUntil}, we’ll bill you once a year for Plickers Pro.` 
			if(currentSubscriptionPrice==='$8.99'){
				summary +=` Your new yearly total will be ${isDiscountPeriod?discountedYearlyPrice:standardYearlyPrice}, saving over ${isDiscountPeriod?'45%':`${planPricingDetails.percentageSavingStandardYearly}`} versus your existing monthly billing. Charges will continue until you cancel.`
			}else{
				summary +=` Your new yearly total will be ${isDiscountPeriod?discountedYearlyPrice:standardYearlyPrice}. Charges will continue until you cancel.`
			}
		}else if(currentPlan==='yearly' && selectedPlanToggle==='monthly'){
			summary=`Starting on ${paidUntil}, we’ll bill you $8.99 once a month for Plickers Pro. Charges will continue until you cancel. `
		}
		return summary
	}

	render() {
		const {isDiscountPeriod,service,currentPlan,upcomingInvoice,submitLoading}=this.props
		const {selectedPlanToggle}=this.state
		const currentSubscriptionPrice = upcomingInvoice ?`$${(upcomingInvoice.amount_due /100).toFixed(2)}`:null
		const {standardMonthlyPricePerMonth,standardMonthlyPricePerYear,standardYearlyPricePerYear,standardYearlyPricePerMonth} = planPricingDetails

		let monthlyMainPrice
		let monthlyEquivalentPrice
		let yearlyMainPrice
		let yearlyEquivalentPrice
		let yearlyDiscountSubline

		const couponDetails=couponDescriptions[process.env.REACT_APP_DEFAULT_COUPON_CODE]

		if(currentPlan==='monthly' && upcomingInvoice){
			monthlyMainPrice=currentSubscriptionPrice
			monthlyEquivalentPrice=`$${(12*upcomingInvoice.amount_due /100).toFixed(2)}`
			if(isDiscountPeriod){
				yearlyEquivalentPrice=couponDetails.discountYearlyPricePerMonth
				yearlyMainPrice=couponDetails.discountYearlyPricePerYear
			}else{
				yearlyMainPrice=standardYearlyPricePerYear
				yearlyEquivalentPrice=standardYearlyPricePerMonth
				

			}
			if(currentSubscriptionPrice==='$8.99'){
				yearlyDiscountSubline=isDiscountPeriod?'Save over 45%':'Save over 33%'
			}

		}else if(currentPlan==='yearly' && upcomingInvoice){
		
			monthlyMainPrice=standardMonthlyPricePerMonth
			monthlyEquivalentPrice=standardMonthlyPricePerYear

			yearlyEquivalentPrice=`$${((upcomingInvoice.amount_due /100)/12).toFixed(2)}`
			yearlyMainPrice=currentSubscriptionPrice
			yearlyDiscountSubline=isDiscountPeriod?'Save over 45%':'Save over 33%'

		}

		const changeSummary=this.getChangeSummaryText(isDiscountPeriod,standardYearlyPricePerYear,couponDetails.discountYearlyPricePerYear,)

		return (
			<div className='switchPlanModal'>   	
				
				<div className='switchPlanModal-header'>   	
					Switch Billing Period
				</div>

				<SwitchPlanModalToggleBox
					onClickFunction={()=>{this.setState({selectedPlanToggle: 'monthly'})}}
					current={currentPlan === 'monthly'}
					selectedToggle={selectedPlanToggle === 'monthly'}
					period='monthly'
					headline='Billed Monthly'
					subline={currentPlan === 'monthly'?'Current':''} // If not current, I think we leave this out for monthly
					mainPrice={monthlyMainPrice}					
					equivalentPrice={monthlyEquivalentPrice}
				/>

				<SwitchPlanModalToggleBox
					onClickFunction={()=>{this.setState({selectedPlanToggle: 'yearly'})}}
					current={currentPlan === 'yearly'}
					selectedToggle={selectedPlanToggle === 'yearly'}					
					period='yearly'
					headline='Billed Annually'
					subline={currentPlan === 'yearly'?'Current':yearlyDiscountSubline} // I think we show only if in discount period, but also if both are standard prices
					mainPrice={yearlyMainPrice}
					equivalentPrice={yearlyEquivalentPrice}
				/>

				<div className='switchPlanModal-changeSummary'>
					<div className='switchPlanModal-changeSummary-header'>
						Change Summary
					</div>					
						<div className='switchPlanModal-changeSummary-text'>
						{changeSummary}
						</div>					
				</div>
											

				{service &&
					<div className='switchPlanModal-actionRow'>	
	        		<Button 
	        			size='xLarge'
	        			color='blue'
	        			submitLoading={submitLoading}
	        			name='switchbilling'
	        			label='Yes, switch Billing Period'
	        			onClickFunction={this.props.switchPlan}
	        			disabled={currentPlan === selectedPlanToggle}        			
	        		/>
	        		<Button 
	        			size='xLarge'
	        			color='white'
	        			label='Cancel'
	        			onClickFunction={this.props.hideModal}
	        		/>
		      </div>
	    	}

			</div>

		)
	}
}

export default SwitchPlanModal
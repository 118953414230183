module.exports ={   
	"type":"image",
   "fileId":"635d2ef1cd0caea44a9f1a4bed4a069b",
   "deliveryUrl":"https://res.cloudinary.com/plickers-dev/image/upload/c_limit,dpr_2,h_900,w_900/f_auto,q_auto/635d2ef1cd0caea44a9f1a4bed4a069b",
   "originalDimensions":{
      "w":1600,
      "h":1067
   },
   "deliveryDimensions":{
      "w":1600,
      "h":1067
   },
   "rotate":{
      "angle":0,
      "flipH":false,
      "flipV":false
   },
   "markup":{
      "covers":[
         
      ],
      "markers":[
         
      ]
   },
   "colorAdjust":{
      "brightness":0,
      "saturation":0,
      "contrast":0
   },
   "imageFill":false
}

import React from 'react'
import EditorIcon from '../../misc/EditorIcons'

class ChoiceMediaEmptyStateReactComponent extends React.Component{  

	constructor(props) {
		super(props)  
		this.state = {
			slideWidth:props.slideWidth  
		}
	}

	render(){ 
		const slideWidth = this.state.slideWidth
		const slideScale = slideWidth / 1280
		const inverseScale = 1 / slideScale
		const bottomRowHeight = 48
		
		// prevent and other elements icons getting too big
		const ceilingInverseScale = 1.75 

		const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)
		const scaledBottomRowHeight = bottomRowHeight * UIInverseScale
		

		return (
			<React.Fragment>
				{/* THIS BUTTON SHOULD DELETE THE CHOICE */}
				<button onClick={this.props.deleteChoice} style={{transform: `scale(${UIInverseScale})`}} className='slide-choice-media--blank-deleteChoice'>
					<EditorIcon name='close' />
				</button>
			
				<div className='slide-choice-media--blankInnerContainer'>	  			

		
					
					{/* INSERT IMAGE BUTTON */}  			
					<button onClick={this.props.showInsertImageModal} className='slide-choice-media--blank-insertImageBtn'>
						<div style={{transform: `scale(${UIInverseScale})`}} className='slide-choice-media--blank-insertImageBtn-iconContainer'>
							<EditorIcon name='insertImageRounded' />		  					
						</div>		  					
					</button>  			

					<div style={{height: `${scaledBottomRowHeight}px`}} className='slide-choice-media--blank-bottomBtnRow'>	  			
						
						
						{/* INSERT VIDEO BUTTON */}
						<button onClick={this.props.showInsertVideoModal} className='slide-choice-media--blank-bottomBtn slide-choice-media--blank-bottomBtn--insertVideo'>
							<div style={{transform: `scale(${UIInverseScale})`}} className='slide-choice-media--blank-bottomBtn-iconContainer'>
								<EditorIcon name='insertVideo' />	
							</div>
						</button>
										
						{/*  INSERT SOUND */}			
						<button onClick={this.props.showInsertSoundModal} className='slide-choice-media--blank-bottomBtn slide-choice-media--blank-bottomBtn--insertSound'>
							<div style={{transform: `scale(${UIInverseScale})`}} className='slide-choice-media--blank-bottomBtn-iconContainer'>
								<EditorIcon name='insertSound' />
							</div>		  							  				
						</button>	  				

						{/* INSERT GIF BUTTON */}
						<button onClick={this.props.showInsertGIFModal} className='slide-choice-media--blank-bottomBtn slide-choice-media--blank-bottomBtn--insertGIF'>
							<div style={{transform: `scale(${UIInverseScale})`}} className='slide-choice-media--blank-bottomBtn-iconContainer'>
								<EditorIcon name='insertGIF' />	
							</div>
						</button>


					</div>
				</div>
				
			</React.Fragment>
		)
	}
}


export default ChoiceMediaEmptyStateReactComponent


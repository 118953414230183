import React, { Component } from 'react'
import Button from '../../components/uiKit/Button'
import Icon from '../../components/misc/Icons'

const maxDescriptionLength = 500 

class EditAuthorDescriptionModal extends Component {
  
	render() {
		const submitButtonDisabled=(this.props.authorDescription===this.props.user.description)
		const {authorDescription} = this.props

		return ( 
			<div className='editAuthorDescriptionModal'>
				<div className='editAuthorDescriptionModal-closeBtn' onClick={this.props.hideModal}>					
 					<Icon name='circle-with-close' /> 
				</div>
	
				<div className='editAuthorDescriptionModal-title'>				
					Creator Bio				
				</div>				

				<div className='editAuthorDescriptionModal-subtitle'>				
					Tell teachers a little about yourself, such as your school, what subject you teach, or your teaching style.
				</div>

				
				<div className={'editAuthorDescriptionModal-characterCount ' + ((maxDescriptionLength === authorDescription.length) ? ' editAuthorDescriptionModal-characterCount--max' : '') + ((authorDescription.length > 400) ? ' editAuthorDescriptionModal-characterCount--show' : '')}>
						{authorDescription.length} / {maxDescriptionLength}
				</div>									

				<div className='editAuthorDescriptionModal-input editAuthorDescriptionModal-input--description'>
								
					<textarea	      
						value={this.props.authorDescription}
						className={((authorDescription.length === 0) ? ' is--empty' : ' is--nonEmpty')}			      
						placeholder='Tell us about yourself!'
						onChange={this.props.descriptionOnChange}
						maxLength={maxDescriptionLength}									
					/>
				</div>
				
				<Button 
					label={'Done'}
					size='xLarge'
					name='editAuthorDescriptionSubmit'
					inputType='submit'
					disabled={submitButtonDisabled || this.props.submitLoading}
					submitLoading={this.props.submitLoading} 
					color={submitButtonDisabled ? 'gray' : 'blue'}
					onClickFunction={this.props.saveChanges}
				/>
			</div>
		)
	}
}

export default EditAuthorDescriptionModal
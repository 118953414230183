import React from 'react';
import Icon from '../misc/Icons'

class GridColumnToggle extends React.Component{

	render(){	
		const {activeGridColumn} = this.props
		
		
		return(
			<div className='gridColumnToggle tooltip tooltip--label' data-tooltip={'Toggle Grid Display'}>
				{activeGridColumn === 1 &&
	      <div className='gridColumnToggle-toggle' onClick={() => {this.props.setNumberColumns(3)}}>
					<Icon name='grid-column-one' />
				</div>
				}
				
				{activeGridColumn === 2 &&
		      <div className='gridColumnToggle-toggle' onClick={() => {this.props.setNumberColumns(1)}}>
		      	<Icon name='grid-column-two' />
		      </div>
	    	}

	    	{activeGridColumn === 3 &&	      
				<div className='gridColumnToggle-toggle' onClick={() => {this.props.setNumberColumns(2)}}>
	      	<Icon name='grid-column-three' />
	      </div>
	    	}

		  </div>
		)				
	}
				
}






export default GridColumnToggle;



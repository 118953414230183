import React from 'react'
import ModalWrapper from './ModalWrapper'
import EditSetContainer from '../../containers/modals/EditSetContainer'

const EditSetModal = props => {

	return (
    <div className='library-editFolderModal--container'>
      <ModalWrapper
        {...props}
        title={'Rename Set'}
        iconName=''
        modalStyle='light' 
      >
        <EditSetContainer {...props}/>

      </ModalWrapper>

    </div>

  )
}

export default EditSetModal
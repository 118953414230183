import React, { Component} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AutoplaySetupModal from '../../components/autoplaySetup/AutoplaySetupModal'
import {hideAutoplaySetupModal} from '../../actions/autoplaySetupModal'

class AutoplaySetupModalContainer extends Component {
	
	constructor(){
		super()
		this.closeModal=this.closeModal.bind(this)

	}

	closeModal(){
		this.props.hideAutoplaySetupModal()
		window.location.hash=''
	}

	render() {		
		if(this.props.location.hash==='#setup-autoplay' || this.props.showAutoplayModal){
			return (
				<div className='autoPlaySetupContainer autoPlaySetupContainer--modal'>
					<AutoplaySetupModal
						isModal={true}
						closeModal={this.closeModal}
					/>
					<div onClick={this.closeModal} className='autoPlaySetupContainer-bgClick' />
				</div>
			)
		}else return null
	}
}

export default withRouter(connect(
	(state) => ({
		showAutoplayModal:state.autoplaySetupModal.visible,
	}),
	{ hideAutoplaySetupModal}
)(AutoplaySetupModalContainer))
import {InputRule} from 'prosemirror-inputrules'
import schema from '../../schema/editorSchema'

//replace x^y with xsup(y)
export function superscriptInputRule(){
	return new InputRule(/([\S]+|\s|^)\^([\S]+)\s$/, (state, match, start, end) => {
		const tr = state.tr
		if((/\^+/).test(match[1])===false && (/\^+/).test(match[2])===false){ //don't trigger for '^^^'
			let marksForSpaceCharacter=[] //apply any marks to the space after text
			state.doc.nodesBetween(start, end, (node, position) => {
				if (!node.isText || start === end) return
					marksForSpaceCharacter=node.marks   
				})
			return tr
			.insert(end,schema.text(`\u00a0`,marksForSpaceCharacter))
			.delete(start+match[1].length,start+match[1].length+1)
			.addMark(start+match[1].length, end-1, schema.marks.superscript.create())
			.removeStoredMark(schema.marks.superscript)
		}
		else return false
	})
}
import React from 'react' 
import moment from 'moment'
import { Link } from 'react-router-dom'
import ScorePill from '../../uiKit/ScorePill'
import SetScoresArray from '../../uiKit/SetScoresArray'
import Icon from '../../misc/Icons'
import {buildQuestionsAndPollsList} from '../../../utils/buildQuestionsAndPollsList'


class SectionHomeHistoryCard extends React.PureComponent{

	render(){
		const {title, timestamp, score, id, isSet,setPoll,resumableSetPoll}=this.props
		let historyCardLink
		let questionsAndPollsList
		let isResumable=false
		if(resumableSetPoll){
			if(resumableSetPoll.id===id){			
				isResumable=true
			}
		}
		if(isSet){
			historyCardLink = `/setreport/${id}`
			questionsAndPollsList=buildQuestionsAndPollsList(setPoll)

		}else{
			historyCardLink = `/questionreport/${id}`
		}

		return(			
			<Link to={`${historyCardLink}`} className={'sectionHome-history-historyCardContainer' + (isSet ? ' is--set' : ' is--question')}>	
				<div className='sectionHome-history-historyCard'>									
					<div className='sectionHome-history-historyCard-timestamp'>
						{isResumable &&
							<React.Fragment>In Progress</React.Fragment>
						}
						{!isResumable &&
							<React.Fragment>Played {timestamp}</React.Fragment>
						}
					</div>

					<div className='sectionHome-history-historyCard-title'>
						{title}						
					</div>

					{isSet && questionsAndPollsList &&
						<SetScoresArray questionsAndPollsList={questionsAndPollsList} />
					}
					
					<div className='sectionHome-history-historyCard-scorePillContainer'>					
						<ScorePill score={score} isSet={isSet} />
					</div>

					<div className='sectionHome-history-historyCard-navBtn'>					
						<Icon name='chevron-right' />
					</div>

				</div>
			
			</Link>
		)
	}
}





class SectionHomeHistory extends React.Component{
	
	render(){
		const {currentSection,recentHistory,resumableSetPoll} = this.props
		const sectionHomeHistoryCardHeight = 100		// unfortunate JS fix for height as flexbox (column can't wrap) and css columns have issues (flicker on webkit)
		let sectionHomeHistoryCardsHeight
		if(recentHistory.length !==0){
			sectionHomeHistoryCardsHeight = Math.ceil(recentHistory.length / 2) * sectionHomeHistoryCardHeight
		}
		if(recentHistory.length !==0){
			return(
				<div className='sectionHome-history sectionHome--block'>
					<div className="sectionHome--block-divider" />
					<div className="sectionHome--block-header">
						<div className="sectionHome--block-header-title">
							Latest Reports
						</div>
						
						<Link to={`/classes/${currentSection.id}/reports`}>
							<div className="sectionHome--block-header-link">
								See all Class Reports
								{/* <Icon name="chevron-right"/> */}
							</div>
						</Link>

					</div>

					<div style={{height: `${sectionHomeHistoryCardsHeight}px`}} className='sectionHome-history-historyCards'>
						{recentHistory.map((historyPoll, index) => {
							const isSet= historyPoll.polls? true:false
			 				const percentageCorrect=historyPoll.correctPercent
							if(historyPoll.snapshot){
								if(isSet){
									return(							
										<SectionHomeHistoryCard 
											resumableSetPoll={resumableSetPoll}						
											key={index}
											sectionId={currentSection.id}
											title={historyPoll.snapshot.name}
											timestamp={moment(historyPoll.minCaptured).format('dddd MMM D')}
											score={percentageCorrect}
											isSet={true}
											setPoll={historyPoll}							
											id={historyPoll.id}
										/>
									)
								}
								else{
									return(
										<SectionHomeHistoryCard
											resumableSetPoll={resumableSetPoll}	
											key={index}
											title={historyPoll.snapshot.body}
											timestamp={moment(historyPoll.minCaptured).format('dddd MMM D')}
											score={percentageCorrect}
											isSet={false}	
											polls={null}						
											id={historyPoll.id}
										/>
									)
								}
								}else {
									return null
								}
							})}
						</div>
						<div className="sectionHome-history-historyCards-bottomBlocker" />
				</div>									
			)
		}
		else return null
	}
}


export default SectionHomeHistory





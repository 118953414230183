//returns response counts by student for polls in date selection (not including excluded polls)
//studentsWithTotals[studentId]=
					//{
					// 	totalResponses:number of responses,
					// 	totalCorrect:number of correct responses,
					// 	totalSurveys:number of survey responses
					// }
//as well as aggregated counts for all students
export function calculateStudentTotals(polls,section,excludedPolls) {
	if(section){
		const students=section.students
		let studentsWithTotals={}
		students.map((student) => {
			studentsWithTotals[student.id]={
				totalResponses:0,
				totalCorrect:0,
				totalSurveys:0
			}
			return null
		})
		let allStudentsResponseCount=0
		let allStudentsCorrectCount=0
		//TODO simplify this
		polls.map((poll)=>{		
			if(poll.polls){ //is setPoll
				if(!excludedPolls[section.id].setPolls[poll.id]){ //ignore excluded setPolls
					const setPollPolls=poll.polls||{}
					Object.keys(setPollPolls).map((key)=>{
						const setPollPoll=setPollPolls[key]
						if(!excludedPolls[section.id].setPollPolls[`${poll.id}-${setPollPoll.id}`]){ //ignore excluded setPollPolls
							//Do the calculation 
							if(setPollPoll.responsesByStudent){
								Object.keys(setPollPoll.responsesByStudent).map((key) => {
									const studentResponse=setPollPoll.responsesByStudent[key]
									if(studentResponse && !studentResponse.isSurvey && studentResponse.isValid){
										allStudentsResponseCount+=1
										
										if(studentsWithTotals[key]){
											studentsWithTotals[key].totalResponses +=1
										}else{
											studentsWithTotals[key]={
												totalResponses:1,
												totalCorrect:0,
												totalSurveys:0
											}									
										}
										if(studentResponse.isCorrect){
											studentsWithTotals[key].totalCorrect +=1
											allStudentsCorrectCount +=1
										}
									}else if(studentResponse.isSurvey){
										if(studentsWithTotals[key]){
											studentsWithTotals[key].totalSurveys +=1
										}else{
											studentsWithTotals[key]={
												totalResponses:0,
												totalCorrect:0,
												totalSurveys:1
											}									
										}
									}
									return null
								})
							}
						} 
						return null	
					})
				}
			}	else{ //is questionPoll 
				if(!excludedPolls[section.id].questionPolls[poll.id]){
					if(poll.responsesByStudent){
						Object.keys(poll.responsesByStudent).map((key) => {
							const studentResponse=poll.responsesByStudent[key]
							if(studentResponse && !studentResponse.isSurvey && studentResponse.isValid){
								allStudentsResponseCount+=1
								if(studentsWithTotals[key]){
									studentsWithTotals[key].totalResponses +=1
								}else{
									studentsWithTotals[key]={
										totalResponses:1,
										totalCorrect:0,
										totalSurveys:0
									}
								}					
								if(studentResponse.isCorrect){
									studentsWithTotals[key].totalCorrect +=1
									allStudentsCorrectCount +=1
								}
							}else if(studentResponse.isSurvey){
								if(studentsWithTotals[key]){
									studentsWithTotals[key].totalSurveys +=1
								}else{
									studentsWithTotals[key]={
										totalResponses:0,
										totalCorrect:0,
										totalSurveys:1
									}
								}		
							}
							return null
						})
					}
				}
			}return null
		})
		studentsWithTotals.total={
			allStudentsResponseCount:allStudentsResponseCount,
			allStudentsCorrectCount:allStudentsCorrectCount
		}
		return studentsWithTotals
	}else return null
}






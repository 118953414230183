import React from 'react'
import { withRouter} from 'react-router-dom'
import FilterBar from '../uiKit/FilterBar'
import Dropdown from '../uiKit/Dropdown'
import DropdownItem from '../uiKit/DropdownItem'
import HistoryList from './HistoryList'
import TopPageHeader from '../pageHeaders/TopPageHeader'
import StickyPageHeader from '../pageHeaders/StickyPageHeader'
import PageIcon from '../misc/PageIcons' 


class HistoryPage extends React.Component{

	constructor(){
		super()
		this.handleFilterChange=this.handleFilterChange.bind(this)	
		this.clearFilter=this.clearFilter.bind(this)	
	}

	handleFilterChange(e){
		this.props.changeFilterTerm(e.target.value)
	}
 
	clearFilter(){
		const inputField = document.getElementById('filterInput')
		inputField.value=''
		inputField.blur()
		this.props.changeFilterTerm('')	
	}

	render(){	
		const {showArchived}=this.props
		return(
			<div className="page page--defaultLayout">
				<div className="page-leftPanel" />
				<div className='page-centerPanel'>
					<TopPageHeader
						location={showArchived ?'archivedReports':'history' }
						leftBadge={<PageIcon name='historyLarge' />}
						header={showArchived? 'Archived Reports':'Reports'}
						actionRow={
							<React.Fragment>
								<FilterBar 
									value={this.props.filterTerm}
									onChange={this.handleFilterChange} 
									id='filterInput'
									clearBtnOnClick={() =>{this.clearFilter()}}
								/>		
			
								<Dropdown>        									
									<DropdownItem label={showArchived ?'View Reports':'View Archived Reports' } onSelect={()=>{this.props.toggleArchived()}} />
								</Dropdown>	

							</React.Fragment>}
					/>
					
					<StickyPageHeader 
						location={showArchived ?'archivedReports':'history' }
						leftBadge={<PageIcon name='historyLarge' />}
						header={showArchived? 'Archived Reports':'Reports'}					
					/>									
					
					<HistoryList
						key={showArchived?'archived':'unarchived'}
						orderedPolls={this.props.orderedPolls}
						firstItemInDateGroupIds={this.props.firstItemInDateGroupIds}
						sections={this.props.sections}
						filterTerm={this.props.filterTerm}
						isSectionHistory={false}
						showMore={this.props.showMore}
						totalPolls={this.props.totalPolls}
					/>

				</div>
				<div className="page-rightPanel" />
			</div>
		)
	}
}

export default withRouter(HistoryPage)
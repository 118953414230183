import Pusher from 'pusher-js'
import store from '../../store'
import {fetchSectionAuthorizations} from '../../actions/sections'  
import find from 'lodash/find'

//Used for E-Learning
//http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#section-channel
//TODO could add sentry logging

export function sectionPresencePusherSetup(sectionId) {
	const session = JSON.parse(localStorage.getItem('ls.session'))
	let token=''
	if(session){
		token=session.token
	} 
	//Pusher.logToConsole = true 
	var pusher = new Pusher(process.env.REACT_APP_PLICKERS_PUSHER_TOKEN, {
		encrypted: true,
		authEndpoint: `${process.env.REACT_APP_PLICKERS_API_ENDPOINT }/pusher/auth-section`,
		 auth: {
   				headers: {
   				   'x-auth-token': token
   				 }
 			 }
	})

	var context = { sectionId: sectionId }
	var presenceChannel = pusher.subscribe(`presence-${sectionId}`)
	presenceChannel.bind('pusher:subscription_error', status => {
		if(window.analytics){
			window.analytics.track('pusher:subscription_error',{
				status:status,
				sectionId:sectionId
			})
			}
    })

		pusher.connection.bind('error', function(error) {
			if(window.analytics){
				window.analytics.track('pusher:connection_error',{
					error:error,
					sectionId:sectionId
				})
			}
		})

	presenceChannel.bind('pusher:subscription_succeeded', handlePresenceSubscriptionSucceeded,context)	
	presenceChannel.bind('pusher:member_removed', handlePresenceMemberRemoved,context)	
	presenceChannel.bind('pusher:member_added', handlePresenceMemberAdded,context)	
}
 


function handlePresenceSubscriptionSucceeded(data){
	const sectionId=this.sectionId
	const members=data.members
	store.dispatch(fetchSectionAuthorizations(sectionId))
	store.dispatch({ type: 'PUSHER_PRESENCE_SUBSCRIBE_SUCCESS',sectionId,members})
}



function handlePresenceMemberRemoved(data){
	const sectionId=this.sectionId
	const sessionId=data.id
	store.dispatch({ type: 'PUSHER_PRESENCE_REMOVED',sectionId,sessionId})
}

function handlePresenceMemberAdded(data){
	const sectionId=this.sectionId
	const sessionId=data.id
	store.dispatch({ type: 'PUSHER_PRESENCE_ADDED',sectionId,sessionId})
	//check if member is in authorizations else refetch authorizations 
	const state = store.getState()
	const sectionAuthorizations=state.sectionAuthorizations[sectionId]
	const sessionAuthorization=find(sectionAuthorizations,{session:sessionId})
	if(sessionAuthorization){
		//console.log('existing authorization')

	}else{
		store.dispatch(fetchSectionAuthorizations(sectionId))
	}
}

import api from '../api'
import {fetchService} from './service'
import {fetchBilling,fetchUpcomingInvoice} from './billing'

//http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#discount
export function fetchDiscounts() {
	return (dispatch) => api.fetch('/discounts')
		.then((response) => {
			dispatch({ type: 'FETCH_DISCOUNTS_SUCCESS', response })
		})
		.catch((error) => {
			return error
		})
}

export function claimCoupon(couponCode) {	
  	return (dispatch) => api.post('/discounts',{couponCode:couponCode})
	    .then((response) => {
	    	dispatch(fetchService())
	    	dispatch(fetchBilling())
	    	dispatch(fetchUpcomingInvoice())
	    	dispatch(fetchDiscounts())
		      return response
	    })
	    .catch((error) => {
			return error
		})
}


import tumblePodcastIds from '../../staticData/tumblePodcastIds.js'
import includes from 'lodash/includes'

export function analyticsTrackStartMediaPlayback(control,playedOnWeb) {
	if(window.analytics){		
		const snapshot=control.snapshot
		const playbackItem=control.playback.item
		if(snapshot){
			let mediaType
			let fileId
			let isTumblePodcast=false
			if(playbackItem==='promptMedia' && snapshot.media){
				mediaType=snapshot.media.type
				fileId=snapshot.media.fileId
			}
			else if(playbackItem==='choiceA' && snapshot.choices[0] && snapshot.choices[0].media){
				mediaType=snapshot.choices[0].media.type
				fileId=snapshot.choices[0].media.fileId
			}else if(playbackItem==='choiceB' && snapshot.choices[1] && snapshot.choices[1].media){
				mediaType=snapshot.choices[1].media.type
				fileId=snapshot.choices[1].media.fileId
			}else if(playbackItem==='choiceC' && snapshot.choices[2] && snapshot.choices[2].media){
				mediaType=snapshot.choices[2].media.type
				fileId=snapshot.choices[2].media.fileId
			}else if(playbackItem==='choiceD' && snapshot.choices[3] && snapshot.choices[3].media){
				mediaType=snapshot.choices[3].media.type
				fileId=snapshot.choices[3].media.fileId
			}
			if(mediaType==='sound'){
				isTumblePodcast=includes(tumblePodcastIds,fileId) 
			}

			if(playedOnWeb){
				window.analytics.track('Web play NP media',{
					playbackItem:playbackItem,
					mediaType:mediaType,
					fileId:fileId,
					isTumblePodcast:isTumblePodcast
				})
			}
			else{
				window.analytics.track('Play NP media',{
					playbackItem:playbackItem,
					mediaType:mediaType,
					fileId:fileId,
					isTumblePodcast:isTumblePodcast
				})
			}
			
		}
	}
}








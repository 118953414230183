import React, { Component } from 'react'
import RichTextMarketingContent from './RichTextMarketingContent'

class RichTextMarketingModal extends Component {
  constructor() {
    super()
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this)
  }

  UNSAFE_componentWillMount() {
    document.body.style.overflowY = 'hidden' // prevent scrolling while modal is open
    if (window.analytics) {
      window.analytics.page('Rich text what\'s new modal', { isInitialLoad: this.props.initialLoad })
    }
  }

  componentWillUnmount() {
    document.body.style.overflowY = '' // overlay is not supported by firefox, so default to blank (auto style comes from css)
    document.body.style.overflowY = 'overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
  }

  handleBackgroundClick(e) {
    if (e.target === e.currentTarget) this.props.dismissModal() // close modal is click outside modal
  }

  render() {
    return (
      <RichTextMarketingContent
        dismissModal={this.props.dismissModal}
      />
    )
  }
}

export default RichTextMarketingModal

const MARKER_WIDTH = 80
const SERVED_IMAGE_MAX_DIMENSION = 900
const dprValue = 2.0 // can't seem to get "auto" to work atm

const markerIds = {
  marker1ID: 'resources:marker_1',
  marker2ID: 'resources:marker_2',
  marker3ID: 'resources:marker_3',
  marker4ID: 'resources:marker_4',
  marker5ID: 'resources:marker_5',
  markercoverID: 'resources:white_cover',
}

function calculateDeliveryDimensions(originalDimensions, rotateAngle, crop) {
  const deliveryDimensions = {
    h: originalDimensions.h,
    w: originalDimensions.w,
  }
  const hasRotated = rotateAngle === 90 || rotateAngle === 270
  if (hasRotated && !crop) {
    deliveryDimensions.h = originalDimensions.w
    deliveryDimensions.w = originalDimensions.h
  } else if (crop) {
    deliveryDimensions.h = crop.h
    deliveryDimensions.w = crop.w
  }
  deliveryDimensions.servedImageScale = SERVED_IMAGE_MAX_DIMENSION / Math.max(deliveryDimensions.h, deliveryDimensions.w)
  return deliveryDimensions
}

export function calculateImageDeliveryValues(fileId, originalDimensions, crop, rotate, colorAdjust, covers, markers) {
  const { angle, flipH, flipV } = rotate
  const displayDimensions = calculateDeliveryDimensions(originalDimensions, angle, crop)
  const displayWidth = displayDimensions.w
  const displayHeight = displayDimensions.h

  const markerTransforms = []
  covers.forEach((cover) => {
    // calculate crop area overlap
    let overlapHeight = cover.h
    let overlapWidth = cover.w
    const coverXTL = cover.x - 0.5 * cover.w
    const coverYTL = cover.y - 0.5 * cover.h
    let overlapX = coverXTL
    let overlapY = coverYTL

    if (crop) {
      if (coverXTL > crop.x) {
        overlapWidth = Math.max(Math.min(parseInt(crop.x, 10) + parseInt(crop.w, 10) - parseInt(coverXTL, 10), cover.w), 0)
      }
      if (coverXTL <= crop.x) {
        overlapX = crop.x
        overlapWidth = Math.max(Math.min(cover.w - (crop.x - coverXTL), crop.w), 0)
      }
      if (coverYTL > crop.y) {
        overlapHeight = Math.max(Math.min(parseInt(crop.y, 10) + parseInt(crop.h, 10) - parseInt(coverYTL, 10), cover.h), 0)
      }
      if (coverYTL <= crop.y) {
        overlapY = crop.y
        overlapHeight = Math.max(Math.min(cover.h - (crop.y - coverYTL), crop.h), 0)
      }
    }

    // Need to put into coordinate system around centerpoint of image
    let cover00Xposition = cover.x - (displayWidth / 2)
    let cover00Yposition = cover.y - (displayHeight / 2)

    if (crop) {
      cover00Xposition = overlapX - (displayWidth / 2) + (0.5 * overlapWidth) - crop.x
      cover00Yposition = overlapY - (displayHeight / 2) + (0.5 * overlapHeight) - crop.y
    }

    let serveCover = true // hide cover if completely off image
    if (overlapWidth === 0 || overlapHeight === 0) {
      serveCover = false
    }

    let coverServeWidth = Math.round(cover.w)
    let coverServeHeight = Math.round(cover.h)
    if (crop) {
      coverServeWidth = Math.round(overlapWidth)
      coverServeHeight = Math.round(overlapHeight)
    }
    if (serveCover) {
      const transform = {
        overlay: markerIds.markercoverID,
        width: coverServeWidth,
        height: coverServeHeight,
        x: Math.round(cover00Xposition),
        y: Math.round(cover00Yposition),
      }
      markerTransforms.push(transform)
    }
  })
  // center around 0,0
  // scale it
  markers.forEach((marker) => {
    let marker00Xposition = marker.x - (displayWidth / 2)
    let marker00Yposition = marker.y - (displayHeight / 2)
    if (crop) {
      marker00Xposition = marker.x - (displayWidth / 2) - crop.x
      marker00Yposition = marker.y - (displayHeight / 2) - crop.y
    }

    // determine if marker is completely inside crop area, otherwise shouldn't appear in cloudinary
    const serveMarker = marker.inCropSafe
    if (serveMarker) {
      const markerServeSize = Math.round(MARKER_WIDTH / displayDimensions.servedImageScale)
      const transform = {
        overlay: markerIds[`marker${marker.type}ID`],
        width: markerServeSize,
        height: markerServeSize,
        x: Math.round(marker00Xposition),
        y: Math.round(marker00Yposition),
      }
      markerTransforms.push(transform)
    }
  })

  const { brightness, saturation, contrast } = colorAdjust
  let brightnessCloudinaryValue = brightness
  if (brightness < 0) {
    brightnessCloudinaryValue = 0.5 * brightness
  }

  let deliveryUrl = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload/`
  if (process.env.REACT_APP_ENV === 'production') {
    deliveryUrl = 'https://media.plickers.com/image/upload/'
  }

  if (angle) {
    deliveryUrl += `a_${angle}/`
  }
  if (flipV) {
    deliveryUrl += 'a_vflip/'
  }
  if (flipH) {
    deliveryUrl += 'a_hflip/'
  }
  if (crop && crop.w && crop.h) {
    deliveryUrl += `c_crop,h_${Math.round(crop.h)},w_${Math.round(crop.w)},x_${Math.round(crop.x)},y_${Math.round(crop.y)}/`
  }
  if (contrast) {
    deliveryUrl += `e_contrast:${contrast}/`
  }

  if (saturation) {
    deliveryUrl += `e_saturation:${saturation}/`
  }

  if (brightnessCloudinaryValue) {
    deliveryUrl += `e_brightness_hsb:${brightnessCloudinaryValue}/`
  }

  markerTransforms.forEach((transform) => {
    deliveryUrl += `h_${transform.height},l_${transform.overlay},w_${transform.width},x_${transform.x},y_${transform.y}/`
  })

  deliveryUrl += `c_limit,dpr_${dprValue},h_900,w_900/f_auto,q_auto/${fileId}`

  return {
    w: displayWidth,
    h: displayHeight,
    deliveryUrl,
  }
}

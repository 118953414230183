import React from 'react'
import ModalWrapper from './ModalWrapper'
import BulkAddStudentsContainer from '../../containers/sections/BulkAddStudentsContainer'

const BulkAddStudentsModal= props => {
	let title=`Add Students`
	if(props.section){
		title=`Add Students to ${props.section.name}`
	}

	return (
		<div className="bulkStudents-modal">
			<ModalWrapper
				{...props}
				title={title}
				modalStyle="dark"        
			>

				<BulkAddStudentsContainer {...props} />

			</ModalWrapper>

		</div>

	)
}

export default BulkAddStudentsModal
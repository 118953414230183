import React from 'react'
import { Link } from 'react-router-dom'
import {getLinkForRepo} from '../../utils/getLinkForRepo'
import Icon from '../misc/Icons'
import DiscoverRepoRatingStars from './DiscoverRepoRatingStars'
import TopPageHeader from '../pageHeaders/TopPageHeader'
import StickyPageHeader from '../pageHeaders/StickyPageHeader'
import {Image} from 'cloudinary-react'
import find from 'lodash/find'
import DiscoverSubjectPageFooter from './DiscoverSubjectPageFooter'
import discoverRepoIds from '../../staticData/discoverRepoIds.js'
	
const keyTextsRepoShortIds=discoverRepoIds.keyTextsRepoShortIds
	
function findRepoByShortId(repos,shortId){
	const repo = find(repos,{shortId:shortId})
	return repo
}

class DiscoverELAKeyTextsTopFeaturedLink extends React.Component{

	constructor(){
		super()
		this.onClickLink=this.onClickLink.bind(this)
	}

	onClickLink(){
		if(window.analytics){
			const {repo}=this.props
			const rating=repo.rating
			window.analytics.track('Click Discover Repo Link',{
				name:repo.name,
				rating:rating,
				author:repo.author.username,
				collection:'featured',
				market:'keyTexts'
			}
		)}
	}


	render(){
		const {repo}=this.props		
		const rating=repo.rating
		//const showPlayCount = process.env.REACT_APP_ENV!== 'production'
		const showPlayCount = false

		return(
			<Link 
				onClick={this.onClickLink}
				to={getLinkForRepo(repo)}
				className='discover-market--ELA-romeoJuliet-repoLink'
			>				
				<div className='discover-market--ELA-romeoJuliet-repoLink-left'>
					<div className='discover-market--ELA-romeoJuliet-repoLink-repoImageContainer'>
						<Image
							key={repo.id}
							cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
							publicId={`${repo.image}/repo/${repo.id}`} 
							className='discover-market--ELA-romeoJuliet-repoLink-repoImage'
							width='450'
							height='450'
							crop='fill'
						/>
					</div>

				</div>
				<div className='discover-market--ELA-romeoJuliet-repoLink-center'>					
					<div className='discover-market--ELA-romeoJuliet-repoLink-center-creator'>						
						{repo.author.username}
					</div>					
					{rating && 
						<div className='discover-market--ELA-romeoJuliet-repoLink-center-ratingContainer'>
							<DiscoverRepoRatingStars rating={rating} showRatingLabel />				
						</div>		
					}				
					{showPlayCount && 
						<div className='discover-market--ELA-romeoJuliet-repoLink-center-playsContainer'>
							55 plays
						</div>		
					}								
					<div className='discover-market--ELA-romeoJuliet-repoLink-center-description'>						
						{repo.description}
					</div>										
				</div>
				<div className='discover-market--ELA-romeoJuliet-repoLink-right'>
					<Icon name='chevron-right' />
				</div>
			</Link>
		)
	}
}


class DiscoverELAKeyTextsAllTextsLink extends React.Component{

	constructor(){
		super()
		this.onClickLink=this.onClickLink.bind(this)
	}


	onClickLink(){
		if(window.analytics){
			const {repo}=this.props
			const rating=repo.rating
			window.analytics.track('Click Discover Repo Link',{
				name:repo.name,
				rating:rating,
				author:repo.author.username,
				collection:'featured',
				market:'keyTexts'
			})}
	}

	render(){
		const {repo}=this.props		
		const rating=repo.rating
		//const showPlayCount = process.env.REACT_APP_ENV!== 'production'
		const showPlayCount = false

		return(
			<Link 
				onClick={this.onClickLink}
				to={getLinkForRepo(repo)}
				className='discover-market--ELA-allTexts-repoLink'
			>				
				<div className='discover-market--ELA-allTexts-repoLink-left'>
					<div className='discover-market--ELA-allTexts-repoLink-bookImageContainer'>
						<img className='discover-market--ELA-allTexts-repoLink-bookImage' srcSet={this.props.imageURL} alt=''/>
					</div>

				</div>
				<div className='discover-market--ELA-allTexts-repoLink-center'>					
					<div className='discover-market--ELA-allTexts-repoLink-center-textTitle'>						
						{this.props.textTitle}
					</div>
					<div className='discover-market--ELA-allTexts-repoLink-center-textAuthor'>						
						by {this.props.textAuthor}
					</div>
					<div className='discover-market--ELA-allTexts-repoLink-center-creator'>						
						{repo.author.username}
					</div>					
					{rating && 
						<div className='discover-market--ELA-allTexts-repoLink-center-ratingContainer'>
							<DiscoverRepoRatingStars rating={rating} showRatingLabel />				
						</div>		
					}				
					{showPlayCount && 
						<div className='discover-market--ELA-allTexts-repoLink-center-playCount'>
							{Math.round(repo.responseCount/20)} plays	
						</div>		
					}								
					<div className='discover-market--ELA-allTexts-repoLink-center-description'>						
						{repo.description}
					</div>										
				</div>
				
				<div className='discover-market--ELA-allTexts-repoLink-right'>
					
					<Icon name='chevron-right' />
					
				</div>
				
			</Link>
		)
	}
}





class DiscoverELAKeyTextsPage extends React.Component{

	UNSAFE_componentWillMount() {
		if(this.props.repos.length !== keyTextsRepoShortIds.length){
			keyTextsRepoShortIds.forEach((shortId)=>{
				this.props.fetchAPublishedRepoByShortId(shortId)
				.catch((error)=>{})
			})
		}
	}

	render(){
		const {repos}=this.props	
		const discoverNavRow=
				<React.Fragment>								
					<div className='pageHeaderTop-folderNavRow-folderPathItem is--library' onClick={()=>{this.props.history.push('/discover')}}>
						Discover
					</div>
					<div className='pageHeaderTop-folderNavRow-folderPathItem is--folder is--currentFolder'>
						<Icon name='chevron-right'/>			       
						 ELA: Key Texts			        
					</div>
				</React.Fragment>

		return(
			<div className="page page--extraWideLayout discover-market">
				<div className="page-leftPanel" />
				<div className='page-centerPanel'>
					<TopPageHeader
						header='ELA: Key Texts'
						location='discoverMarket'
						headerInfoRow='Exploring key novels and plays in English Literature · Grades 6+'
						leftBadge={
							<React.Fragment>
								<Icon name='chevron-left' />										
							</React.Fragment>
						}
						leftBadgeClickFunction={()=>{this.props.history.push('/discover')}}
						folderNavRow={discoverNavRow}
					/>

					<StickyPageHeader 												
						header='ELA Key Texts'
					/>									
					
						<div className='discover-market--ELA-romeoJulietContainer'>
							<div className='discover-market--ELA-romeoJulietLeftContainer'>
								<div className='discover-market--ELA-romeoJuliet-header'>
									Romeo and Juliet
								</div>
								<div className='discover-market--ELA-romeoJuliet-description'>
									<div className='discover-market--ELA-romeoJuliet-description-p'>
										Two packs covering Shakespeare's classic, each containing over 300 original questions.
									</div>
									<div className='discover-market--ELA-romeoJuliet-description-p'>
										Both packs include comprehension questions, discussion questions, and historical context.
									</div>
									<div className='discover-market--ELA-romeoJuliet-description-p'>
										Ready to use in your next class.
									</div>
								</div>
							</div>
							<div className='discover-market--ELA-romeoJulietRightContainer'>								
								
								<div className='discover-market--ELA-romeoJuliet-pack'>
								{repos && findRepoByShortId(repos,4119)&&
									<DiscoverELAKeyTextsTopFeaturedLink repo={findRepoByShortId(repos,4119)}/>
								}
								{repos && findRepoByShortId(repos,3887)&&
									<DiscoverELAKeyTextsTopFeaturedLink repo={findRepoByShortId(repos,3887)}/>
								}
								</div>								
							</div>
							

						</div> 	
						
						<div className='discover-market--ELA-allTextsContainer'>
							<div className='discover-market--ELA-allTexts-divider'/>
							<div className='discover-market--ELA-allTexts-header'>
								All Texts
							</div>

							<div className='discover-market-allTexts-bookshelfRow'>
								{repos && findRepoByShortId(repos,3740)&&
									<DiscoverELAKeyTextsAllTextsLink
										textTitle='A Raisin in the Sun'
										textAuthor='Lorraine Hansberry'
										imageURL="https://res.cloudinary.com/plickers-static/image/upload/v1600286891/discoverELABookCovers/raisin.png"
										repo={findRepoByShortId(repos,3740)}
									/>
								}								
								
								{repos && findRepoByShortId(repos,4610)&&
									<DiscoverELAKeyTextsAllTextsLink
										textTitle='Chains'
										textAuthor='Laurie Halse Anderson'
										imageURL="https://res.cloudinary.com/plickers-static/image/upload/v1609941495/discoverELABookCovers/chains.png"
										repo={findRepoByShortId(repos,4610)}
									/>
								}
							
								<div className='discover-market-allTexts-bookshelfRow-shelf'/>
							</div>

								<div className='discover-market-allTexts-bookshelfRow'>
								{repos && findRepoByShortId(repos,3713)&&
									<DiscoverELAKeyTextsAllTextsLink
										textTitle='Hamlet'
										textAuthor='William Shakespeare'
										imageURL="https://res.cloudinary.com/plickers-static/image/upload/v1600286892/discoverELABookCovers/hamlet.png"
										repo={findRepoByShortId(repos,3713)}
									/>
								}
								{repos && findRepoByShortId(repos,6158)&&
									<DiscoverELAKeyTextsAllTextsLink
										textTitle='Lord of the Flies'
										textAuthor='William Golding'
										imageURL="https://res.cloudinary.com/plickers-static/image/upload/v1609941496/discoverELABookCovers/lordoftheflies.png"
										repo={findRepoByShortId(repos,6158)}
									/>
								}
								<div className='discover-market-allTexts-bookshelfRow-shelf'/>
							</div>

							<div className='discover-market-allTexts-bookshelfRow'>
								{repos && findRepoByShortId(repos,3779)&&
									<DiscoverELAKeyTextsAllTextsLink
										textTitle='Macbeth'
										imageURL="https://res.cloudinary.com/plickers-static/image/upload/v1600286891/discoverELABookCovers/macbeth.png"
										textAuthor='William Shakespeare'
										repo={findRepoByShortId(repos,3779)}
									/>
								}								
								{repos && findRepoByShortId(repos,841)&&
									<DiscoverELAKeyTextsAllTextsLink
										textTitle='The Crucible'
										textAuthor='Arthur Miller'
										imageURL="https://res.cloudinary.com/plickers-static/image/upload/v1600286890/discoverELABookCovers/crucible.png"
										repo={findRepoByShortId(repos,841)}
									/>
								}
								<div className='discover-market-allTexts-bookshelfRow-shelf'/>
							</div>

								<div className='discover-market-allTexts-bookshelfRow'>
									{repos && findRepoByShortId(repos,3076)&&
										<DiscoverELAKeyTextsAllTextsLink
											textTitle='The Tragedy of Julius Caesar'
											imageURL="https://res.cloudinary.com/plickers-static/image/upload/v1600286891/discoverELABookCovers/julius_caesar.png"
											textAuthor='William Shakespeare'
											repo={findRepoByShortId(repos,3076)}
										/>
									}
								{repos && findRepoByShortId(repos,3414)&&
									<DiscoverELAKeyTextsAllTextsLink
										textTitle='To Kill a Mockingbird'
										textAuthor='Harper Lee'
										imageURL="https://res.cloudinary.com/plickers-static/image/upload/v1600286890/discoverELABookCovers/mockingbird.png"
										repo={findRepoByShortId(repos,3414)}
									/>
								}
								<div className='discover-market-allTexts-bookshelfRow-shelf'/>
							</div>
								
						</div> 	

						<DiscoverSubjectPageFooter 
							showNotification={this.props.showNotification}
							discoverMarket='ELAKeyTexts'
							showRequestTopic
							showFAQ
							showGeneralFeedback
							hideBorder
						/>
						
			</div>
			<div className="page-rightPanel" />
			</div>
		)
	}
}


export default DiscoverELAKeyTextsPage


import React from 'react'
import Slider, { SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import {formatMillisecondDuration} from '../../../../utils/formatMillisecondDuration'
import {deleteQuestionSound} from '../../../../prosemirror/utils/editorActions/deleteQuestionSound'
import EditorIcon from '../../../misc/EditorIcons'
import {composeSoundWaveformUrl} from '../../../../prosemirror/utils/composeSoundWaveformUrl'
import slideLayoutDefinitions from '../../../../staticData/slideLayoutDefinitions.js'

const { Handle } = Slider

const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="slide-media--sound-player-seekBar-tooltip"
      overlay={`${value}`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};


class QuestionSoundReactComponent extends React.Component{  

  constructor(props) {
    super(props)
    this.playSound=this.playSound.bind(this)
    this.pauseSound=this.pauseSound.bind(this)
    this.startTimer=this.startTimer.bind(this)
    this.seekTo=this.seekTo.bind(this)
    this.restartAudio=this.restartAudio.bind(this)
    this.stopTimer=this.stopTimer.bind(this)
    this.playbackSkip=this.playbackSkip.bind(this)
    this.onAudioEnd=this.onAudioEnd.bind(this)
    
    this.audioElement = new Audio(`${props.deliveryUrl}.mp3`)
    this.audioElement.addEventListener('ended',this.onAudioEnd)

    this.timer=null
    let duration=props.end-props.start

    this.state = {
      isPlaying:false,
      duration:duration,
      playerCurrentTime:0,      
      showWaveform: true,
    }  
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.isPlaying && !nextProps.isPlaying){
      this.pauseSound()
    }
  }

  componentWillUnmount() {
    this.pauseSound()
    this.stopTimer()
    this.audioElement.removeEventListener('ended',this.onAudioEnd)
  }

  onAudioEnd(){
    this.audioElement.currentTime = 0
    this.setState({isPlaying:false,playerCurrentTime:0})
    this.stopTimer()
  }

  updatePlayerCurrentTime(){
    const currentTime=this.audioElement.currentTime
    this.setState({playerCurrentTime:currentTime})
  }

  startTimer(){
    this.timer = setInterval(() => {
      this.updatePlayerCurrentTime()
    }, 10)
  }

  
  stopTimer(){
    clearInterval(this.timer)
    this.timer=null
  }


  playSound(){
    this.props.playSound()
    this.setState({isPlaying:true})
    this.startTimer()
    this.audioElement.play()
  }

  pauseSound(){
    this.setState({isPlaying:false})
    this.audioElement.pause()
    this.stopTimer()
  }

  seekTo(seconds){
    this.audioElement.currentTime=seconds
    if(!this.timer){
      this.setState({playerCurrentTime:seconds})
    }
  }

  playbackSkip(skipDirection){ //skip forwards/back 15 seconds
    const currentTime=this.audioElement.currentTime
    let newTime
    if(skipDirection==='forward'){
      newTime=Math.min(currentTime+15,this.state.duration)
    }
    else if(skipDirection==='back'){
      newTime=Math.max(currentTime-15,0)
    }
    this.seekTo(newTime)
  }

  restartAudio(){
    this.audioElement.currentTime=0
  }

  render(){ 
    const {slideWidth}=this.props
    const {isPlaying, showWaveform} = this.state
    const slideScale = slideWidth / 1280
    const inverseScale = 1 / slideScale

    const ceilingInverseScale = 1.55 

    const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)

    const slideTemplate = this.props.template

    let mediaWidth

    let widePlayer = false

    const soundContainerHeight = slideLayoutDefinitions.bodySoundPlayerHeight
    
    // TO DO: Bring in from slide definitions
    if((slideTemplate === 'bodyLeftMediaRight') || (slideTemplate === 'bodyRightMediaLeft') || (slideTemplate === 'bodyLeftMediaRightChoicesMedia')){
      mediaWidth = 580 // should come from template definitions or somewhere      
    }
    if(slideTemplate === 'bodyCenterMediaCenter'){
      mediaWidth = 1160 // should come from template definitions or somewhere      
      widePlayer = true    
    }    

    const soundPlayerWidth = mediaWidth

    const scaledSoundPlayerWidth = soundPlayerWidth / UIInverseScale
    let scaledSoundPlayeContainerHeight 

    if(soundContainerHeight){
      scaledSoundPlayeContainerHeight = soundContainerHeight / UIInverseScale
    }
    const lengthSeconds = this.state.duration
    const soundClipTitle = this.props.title
    let showSeekJumpButtons = false
    if(this.state.duration > 20){
      showSeekJumpButtons = true
    }
    const waveFormImageURL = composeSoundWaveformUrl(this.props.fileId,this.props.start,this.props.end,this.props.isClipped)
    let displayMillisecondGranularity = true
    let displayHourGranularity=false
    if((this.state.duration) >1){ // if over 1 second 
      displayMillisecondGranularity = false // don't show millisecond granularity
      if(this.state.duration>60*60){
        displayHourGranularity=true
      }
    }

	  return (

	  		<React.Fragment>


          <div style={{transform: `scale(${UIInverseScale})`, width: `${scaledSoundPlayerWidth}px`, height: scaledSoundPlayeContainerHeight ? `${scaledSoundPlayeContainerHeight}px` : null}} className={'slide-media--sound ' + (widePlayer ? ' slide-media--sound--widePlayer' : '')}>
        		
            <div className='slide-media--sound-topLeft'>
        			<button onClick={()=>{this.props.showEditSoundModal(false)}} className='slide-media--sound-topBtn slide-media--sound-topBtn--edit'>
                {widePlayer ? 'Edit Sound' : 'Edit'}
              </button>                                	
            </div>	            

            <div className='slide-media--sound-topRight'>
              {/* COMMENT OUT WAVEFORM FOR NOW }
              <button onClick={()=>{this.setState({showWaveform: !showWaveform})}} className={'slide-media--sound-topBtn slide-media--sound-topBtn--waveform ' + (showWaveform ? ' slide-media--sound-topBtn--waveform--active' : ' slide-media--sound-topBtn--waveform--inactive')}>
                <EditorIcon name='waveform' /> {widePlayer ? 'Waveform' : ''}
              </button>
              */}
              <button onClick={deleteQuestionSound} className='slide-media--sound-topBtn slide-media--sound-topBtn--close'>
                <EditorIcon name='close' />
              </button>
            </div>
         		          		          	
            <div className='slide-media-sound--infoAndTimestampContainer'>              
              
              {!isPlaying &&
              <div className='slide-media-sound--infoAndTimestamp'>
                <div onClick={()=>{this.props.showEditSoundModal(true)}} className='slide-media-sound--infoAndTimestamp-title'>
                  {soundClipTitle}
                </div>
                <div className='slide-media-sound--infoAndTimestamp-timestamp'>
                  {formatMillisecondDuration(this.state.duration*1000,displayMillisecondGranularity,displayHourGranularity)}
                </div>
              </div>         
              }

              {isPlaying &&
              <div className='slide-media-sound--infoAndTimestamp'>
                <div className='slide-media-sound--infoAndTimestamp-playingTimestamp'>
                  {formatMillisecondDuration(this.state.playerCurrentTime*1000,displayMillisecondGranularity,displayHourGranularity)}
                </div>                              

              </div>         
              }   
            </div>

          	<div className='slide-media--sound-seekBarContainer'>
          		          		            		
	          			<Slider 
				  				  onChange={(value)=>{this.seekTo(value)}}
				  					value={this.state.playerCurrentTime} 
				  					step={0.01}
                    min={0} 
				  					max={lengthSeconds} 
				  					defaultValue={0} 
				  					handle={handle}
				  				/>  				
				  			
                <div className='slide-media--sound-seekBarContainer-waveformImageContainer'>                                  
                  {showWaveform &&
                    <img src={waveFormImageURL} alt=''/>                                  
                  }
                </div>
          		
            </div>      		

          			{/* go back to start and play
          			<button onClick={this.restartAudio} className='slide-media--sound-secondaryBtn slide-media--sound-secondaryBtn--restart'>
					  			<EditorIcon name='playbackRestart' /> 
					  		</button>

					  		{/* open edit sound minimodal
					  		<button onClick={this.props.showEditSoundModal} className='slide-media--sound-secondaryBtn slide-media--sound-secondaryBtn--editSound'>
					  			<EditorIcon name='editVideo' /> 
					  		</button>
                 */}
          		

              <div className='slide-media--sound-controls'>
                {showSeekJumpButtons &&
                  <button onClick={()=>{this.playbackSkip('back')}} className='slide-media--sound-controls-seekJump'>
                    <EditorIcon name='playbackSeekBack15' />
                  </button>
                }
                <div className='slide-media--sound-controls-centerContainer'>
                  {isPlaying &&
                    <button onClick={this.pauseSound} className='slide-media--sound-controls-playPauseBtn'>
                      <EditorIcon name='playbackPause' /> 
                    </button>
                  }
                  {!isPlaying &&
                    <button onClick={this.playSound} className='slide-media--sound-controls-playPauseBtn'>
                      <EditorIcon name='playbackPlay' /> 
                    </button>
                  }
                </div>
                {showSeekJumpButtons &&
                  <button onClick={()=>{this.playbackSkip('forward')}} className='slide-media--sound-controls-seekJump'>
                    <EditorIcon name='playbackSeekForward15' />
                  </button>
                }
              </div>
          </div>
	     </React.Fragment>
	  )
	}
}
export default QuestionSoundReactComponent


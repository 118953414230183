import Pusher from 'pusher-js'
import {lockSetPusherReceived,lockRemovedPusherReceived} from '../../actions/locks'  
import store from '../../store'
import {fetchASet} from '../../actions/sets'  
import {fetchARepo} from '../../actions/repos'  
import pako from 'pako'//for inflating compressed data

//Pusher is used to push updates to shared repos
//http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#repo-channel
export function repoPusherSetup(repoId) {
	const session = JSON.parse(localStorage.getItem('ls.session'))
	let token=''
	if(session){
		token=session.token
	} 

	var pusher = new Pusher(process.env.REACT_APP_PLICKERS_PUSHER_TOKEN, {
		encrypted: true,
		authEndpoint: `${process.env.REACT_APP_PLICKERS_API_ENDPOINT }/pusher/auth`,
		auth: {
			headers: {
				'x-auth-token': token
			}
		}
	})

	// Pusher.logToConsole = true 
	var context = { repoId: repoId }
	var privateChannel = pusher.subscribe(`private-${repoId}`)
	privateChannel.bind('lock set', handleLockSetPusherReceived)
	privateChannel.bind('lock removed', handleLockRemovedPusherReceived)
	privateChannel.bind('item updated', handleItemUpdatedPusherReceived)
	privateChannel.bind('item updated-comp', handleCompressedItemUpdatedPusherReceived)
	privateChannel.bind('item added', handleItemAddedPusherReceived)
	privateChannel.bind('item added-comp', handleCompressedItemAddedPusherReceived)
	privateChannel.bind('item removed', handleItemRemovedPusherReceived)
	privateChannel.bind('permission added', handlePermissionAddedPusherReceived)
	privateChannel.bind('permission updated', handlePermissionUpdatedPusherReceived)
	privateChannel.bind('permission removed', handlePermissionRemovedPusherReceived,context)
	
}
 

function handlePermissionAddedPusherReceived(data){
	const permission=data.permission
	store.dispatch({ type: 'PERMISSION_ADDED_PUSHER',permission})
}

function handlePermissionUpdatedPusherReceived(data){
	const permission=data.permission
	const repoId=permission.repo
	store.dispatch(fetchARepo(repoId))
	store.dispatch({ type: 'PERMISSION_UPDATED_PUSHER',repoId,permission})
}
function handlePermissionRemovedPusherReceived(data){
	const repoId=this.repoId
	const pusher=data
	store.dispatch(fetchARepo(repoId))
	store.dispatch({ type: 'PERMISSION_REMOVED_PUSHER',repoId,pusher})
}


function handleLockSetPusherReceived(data){
	store.dispatch(lockSetPusherReceived(data.lock))
}

function handleLockRemovedPusherReceived(data){
	store.dispatch(lockRemovedPusherReceived(data.lock))
}

function handleItemUpdatedPusherReceived(data){
	if(data.type === 'set'){
		const response = data.doc
		if(response){//check if update was made by current user
			store.dispatch({ type: 'UPDATE_SET_FROM_PUSHER',response})
		}
		else if(data.docId){
			store.dispatch(fetchASet(data.docId))
		}
	}
	else if(data.type === 'question'){
		const response = data.doc
		store.dispatch({ type: 'UPDATE_QUESTION_FROM_PUSHER',response})
	}
	else if(data.type === 'folder'){
		const response = data.doc
		store.dispatch({ type: 'UPDATE_FOLDER_FROM_PUSHER',response})
	}

}

function handleCompressedItemUpdatedPusherReceived(data){
	var byteArray = new Uint8Array(data.data)
	const itemString = pako.inflate(byteArray,{to: 'string'})
	const itemJSON= JSON.parse(itemString)
	if(itemJSON.type === 'set'){
		const response = itemJSON.doc
		if(response){//check if update was made by current user
			store.dispatch({ type: 'UPDATE_SET_FROM_PUSHER',response})
		}
	}
	
}

function handleItemAddedPusherReceived(data){
	if(data.type === 'set'){
		const set = data.doc
		if(set){//check if update was made by current user
			store.dispatch({ type: 'SET_ADDED_PUSHER',set})
		}
		else if(data.docId) {
			store.dispatch(fetchASet(data.docId))
		}
	}

	else if(data.type === 'question'){
		const question = data.doc
		store.dispatch({ type: 'QUESTION_ADDED_PUSHER',question})
	}

	else if(data.type === 'folder'){
		const folder = data.doc
		store.dispatch({ type: 'FOLDER_ADDED_PUSHER',folder})
	}

}

function handleCompressedItemAddedPusherReceived(data){
	var byteArray = new Uint8Array(data.data)
	const itemString = pako.inflate(byteArray,{to: 'string'})
	const itemJSON= JSON.parse(itemString)
	if(itemJSON.type === 'set'){
		const set = itemJSON.doc
		if(set){//check if update was made by current user
			store.dispatch({ type: 'SET_ADDED_PUSHER',set})
		}
	}
}

function handleItemRemovedPusherReceived(data){
	if(data.type === 'set'){
		const setId = data.docId
		const repoId = data.repo
		store.dispatch({ type: 'SET_REMOVED_PUSHER',setId,repoId})
	}

	else if(data.type === 'question'){
		const questionId = data.docId
		const repoId = data.repo
		store.dispatch({ type: 'QUESTION_REMOVED_PUSHER',questionId,repoId})
	}

	else if(data.type === 'folder'){
		const folderId = data.docId
		const repoId = data.repo
		store.dispatch({ type: 'FOLDER_REMOVED_PUSHER',folderId,repoId})
	}

}


import {upsertRecentSymbols} from '../../actions/recentSymbols'
import find from 'lodash/find'
import store from '../../store'

function getIdForUnicode(escapedString){
	const state = store.getState()
	const symbolsList=state.symbolsList
	const symbol=find(symbolsList,{symbol:escapedString})
	let id
	if(symbol){
		id=symbol.id
	}
	return id
}

export function updateRecentSymbols(escapedString){
	const id=getIdForUnicode(escapedString)
	if(id){
		return store.dispatch(upsertRecentSymbols(id)).then((response)=>{
		}) 
	}
}
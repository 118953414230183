import React from 'react'

class DropdownDivider extends React.Component{	
	render(){
		const {label, atDropdownTop} = this.props
		if(label){
			return(
				<div className={'dropdownDividerHeader' + (atDropdownTop ? ' dropdownDividerHeader--dropdownTop' : '')}>
					{label}
				</div>
			)
		}else{
			return(
				<div className="dropdownDivider"/>
			)
		}	
	}		
}

export default DropdownDivider
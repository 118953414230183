import React from 'react'
import PropTypes from 'prop-types'


const stickyHeaderHeight = 75

class StickyPageHeader extends React.Component{

	componentDidMount() {
		this.context.registerStickyHeader(this.el,stickyHeaderHeight)
	}

	componentWillUnmount() {
		this.context.unregisterStickyHeader()
	}




	render(){	

		const {header,leftBadge, leftBadgeClickFunction, sectionHeaderSectionColor, subHeader, location, actionGroup} = this.props
		
		return(
			<div ref={(el) => { this.el = el }} style={{height: `${stickyHeaderHeight}px`}} className={'pageHeaderSticky' + (location ? ` pageHeaderSticky--${location} ` : '') + (sectionHeaderSectionColor ? ` pageHeaderSticky--classcolor-${sectionHeaderSectionColor} ` : '')}>
				
				<div className='pageHeaderSticky-leftBadgeContainer'
					onClick={leftBadgeClickFunction}
				>
					<div className='pageHeaderSticky-leftBadge'>
					{leftBadge}
					</div>
				</div>
				
				<div className={'pageHeaderSticky-header ' + (this.props.pageHeaderNoTranslate ? 'notranslate' : '')}>							
					{header}
				</div>


				{subHeader &&
				<div className='pageHeaderSticky-subHeader'> 
					{subHeader} 
				</div>
				}

				<div className='pageHeaderSticky-spacer' />

				<div className='pageHeaderSticky-actionGroup'>
					{actionGroup}
				</div>

			</div>
		)	
	}
				
}




export default StickyPageHeader

StickyPageHeader.contextTypes= {
	registerStickyHeader: PropTypes.func.isRequired,
	unregisterStickyHeader: PropTypes.func.isRequired
}


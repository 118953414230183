import React from 'react'

class SymbolPickerSearchBar extends React.Component{  
	render(){  
	  return (
  		<div className='editor-emojiSymbolPicker-search' onClick={()=>{this.textInput.focus()}}>
  			<div className='editor-emojiSymbolPicker-search-inputContainer'>
				<input	
					ref={(input) => { this.textInput = input }} 
					//autoFocus		//if autofocus it takes focus away from the editor which is annoying	      
					type="text"
					placeholder="Search Symbols"
					className='editor-emojiSymbolPicker-search-input'
					maxLength='50'
					value={this.props.searchTerm}
					onChange={(e)=>{this.props.onChange(e.target.value)}}
				/>
			    <div className="editor-emojiSymbolPicker-search-inputIcon">
			    	<svg className="editor-emojiSymbolPicker-search-inputIcon-icon" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path fillRule="nonzero" d="M31.2814741,27.0733785 L23.6276903,19.4188599 C24.7821377,17.5249793 25.4464513,15.2968844 25.4464513,12.916874 C25.4464513,5.99560122 19.4494008,5.48258976e-07 12.5287924,5.48258976e-07 C5.60818398,-0.00202499251 0,5.60872294 0,12.5299957 C0,19.449243 5.99705048,25.4468692 12.9156335,25.4468692 C15.2184523,25.4468692 17.3713955,24.8209771 19.2245874,23.7373128 L26.9188781,31.4363933 C27.6723069,32.1878689 28.8935907,32.1878689 29.6449942,31.4363933 L31.5548958,29.5263083 C32.3062993,28.7748327 32.0328775,27.8248541 31.2814741,27.0733785 Z M3.85625941,12.5299957 C3.85625941,7.73756629 7.73884832,3.85460463 12.5287924,3.85460463 C17.3207618,3.85460463 21.5901919,8.12241903 21.5901919,12.916874 C21.5901919,17.7072779 17.7055776,21.5922651 12.9156335,21.5922651 C8.12366413,21.5902396 3.85625941,17.3203997 3.85625941,12.5299957 Z"></path></svg>
		    	</div>
			  </div>
  		</div>
  	)
	}
}
export default SymbolPickerSearchBar
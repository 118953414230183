import React from 'react'
import ReactDOM from 'react-dom'
import PrintSetModalHeader from './PrintSetModalHeader'
import PrintPreview from './PrintPreview'
import PrintSetModalConfigControls from './PrintSetModalConfigControls'
import GhostSet from '../ghostSet/GhostSet'
import Icon from '../../../misc/Icons'
import {getPrintSetSettings} from '../../../../utils/editors/getPrintSetSettings'
import {getPrintSettings} from '../../../../utils/printing/getPrintSettings'
import {saveSettingToLocalStorage} from '../../../../utils/saveSettingToLocalStorage'
import SetHandout from '../setHandout/SetHandout'
import {detectBrowser} from '../../../../utils/detectBrowser'


class PrintPortal extends React.Component {
	constructor() {
		super()
		this.onAfterPrint=this.onAfterPrint.bind(this)
	}

	componentDidMount(){
		this.props.handlePrintPortalMounted()
		window.print()
		window.onafterprint = this.onAfterPrint()
	}

	onAfterPrint(){
		this.props.closePrintSetModal()
		if(window.analytics){
			window.analytics.track('Print Set', {
				  		setId:this.props.setId
			})}
	}

	render() {
		return (
			<React.Fragment>
				{ReactDOM.createPortal(
	      			this.props.children,
	      			document.body
	    		)}
			</React.Fragment>
		)
	}
}


class PrintSetModal extends React.Component{

	constructor() {
		super()
		this.setPrintSetSetting=this.setPrintSetSetting.bind(this)
		this.toggleSetting=this.toggleSetting.bind(this)
		this.handlePrintReport=this.handlePrintReport.bind(this)
		this.saveGhostHeights=this.saveGhostHeights.bind(this)
		this.setPaperSize=this.setPaperSize.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.handlePrintPortalMounted=this.handlePrintPortalMounted.bind(this)
		this.handleInstructionalTextAreaOnChange=this.handleInstructionalTextAreaOnChange.bind(this)
		

		const printSetSettings=getPrintSetSettings()
		const printSettings=getPrintSettings()
		const browser=detectBrowser()

		this.state = {
			previewLoading:true,
			showPreview:false,
			printSetSettings:printSetSettings,
			paperSize:printSettings.paperSize,
			renderPrintPortal:false,
			ghostHeights:null,
			submitLoading:false,
			browser:browser,
			printPageCount:null,
			instructionalText:'',
			//includeInstructionalText:false
		}
	}  

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Print Set Modal')
		}
		setTimeout(function() { 
			this.setState({showPreview:true})
		}.bind(this),	1)
		
		document.addEventListener('keydown', this.handleKeyDown)

	}
	
	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown)
	}

	handlePrintPortalMounted(){
		this.setState({submitLoading:false})
	}

	handleKeyDown(e){
		e.stopPropagation()
		if(e.keyCode === 27){//escape key
			this.props.closePrintSetModal()
		}else if(e.keyCode === 13){//escape key
			this.handlePrintReport()
		}
	}

	saveGhostHeights(heights){
		this.setState({ghostHeights:heights,showPreview:true})
	}


	handlePrintReport(){
		this.setState({submitLoading:true})	

		setTimeout(function() { 
			this.setState({renderPrintPortal:true})
		}.bind(this),	1)
	
	}

	setPrintSetSetting(setting,value){		
		let printSetSettings=this.state.printSetSettings
		printSetSettings[setting]=value
		this.setState({printSetSettings:printSetSettings})
		saveSettingToLocalStorage('printSetSettings',setting,value)
	}

	setPaperSize(value){		
		this.setState({paperSize:value})
		saveSettingToLocalStorage('printSettings','paperSize',value)
	}
	toggleSetting(setting){
		this.setState(function(prevState){
		      return {[setting]:!prevState[setting]
		   }}, () => {})
	}
	

	handleInstructionalTextAreaOnChange(e){
		let value =e.target.value
		this.setState({instructionalText:value})
	}


	render(){
		const {closePrintSetModal,set}=this.props
		const {printSetSettings,showPreview,renderPrintPortal,ghostHeights,paperSize}=this.state
		const personalizedContentLabel='hello'		
		const {submitLoading} = this.state


 		

		return(
			<div className='featureModalContainer'>
				<div className={'featureModal featureModal--printSet ' + (submitLoading ? ' featureModal--printSet--hide' : '')}> 
					<div className='featureModal-closeBtn' onClick={closePrintSetModal}>
						<Icon name='circle-with-close' />
					</div>

					<div className='featureModal-left'>
						<PrintSetModalHeader 
							set={set}
						/>

						<PrintSetModalConfigControls 
							setPrintSetSetting={this.setPrintSetSetting} 
							printSetSettings={printSetSettings}
							browser={this.state.browser}
							setPaperSize={this.setPaperSize}
							paperSize={paperSize}
							instructionalText={this.state.instructionalText}
							handleInstructionalTextAreaOnChange={this.handleInstructionalTextAreaOnChange}

						/>		
					</div>
					

					<GhostSet 
						set={set}
						printSetSettings={printSetSettings}
						saveGhostHeights={this.saveGhostHeights}
						showImages={printSetSettings.showImages}
						includeStudentWorkingSpace={printSetSettings.includeStudentWorkingSpace}
						paperSize={paperSize}
						columnCount={printSetSettings.columnCount}
						labelSurveys={printSetSettings.labelSurveys}
					/>	


 

					{showPreview===true &&
						<PrintPreview 
							set={set}
							printSetSettings={printSetSettings}
							paperSize={paperSize}
							ghostHeights={ghostHeights}
							personalizedContentLabel={personalizedContentLabel}
							includeStudentWorkingSpace={printSetSettings.includeStudentWorkingSpace}
							instructionalText={this.state.instructionalText}
							labelSurveys={printSetSettings.labelSurveys}
						/>			
					}
					
					{renderPrintPortal===true &&
						<PrintPortal setId={set.id} closePrintSetModal={closePrintSetModal} handlePrintPortalMounted={this.handlePrintPortalMounted}>
							<SetHandout 
								browser={this.state.browser}
								ghostHeights={ghostHeights}
								set={set}
								paperSize={paperSize}
								printSetSettings={printSetSettings}
								includeStudentWorkingSpace={printSetSettings.includeStudentWorkingSpace}
								labelSurveys={printSetSettings.labelSurveys}
								instructionalText={this.state.instructionalText}
								//includeInstructionalText={this.state.includeInstructionalText}


							/>							
						</PrintPortal>
					}
				

					<button className='featureModal-primaryButton' onClick={this.handlePrintReport}>

						
							{!submitLoading ?'Print':''}

						{submitLoading &&	
							<div className='featureModal-primaryButton-loadingSpinnerContainer'>
								<div className='featureModal-primaryButton-loadingSpinner' />
							</div>
						}

					</button>
				</div>
				<div className='featureModalOverlay' onClick={closePrintSetModal} />
			</div>
		)
	}
}
 

export default PrintSetModal

	

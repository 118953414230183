import api from '../api'

//http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#invite
export function inviteToPlickers(email) {
	return (dispatch) => api.post('/invite',{email:email})
		.then((response) => {
			if(window.analytics){
				window.analytics.track('Invite to Plickers')}
				return response
			})
		.catch((error) => {
			return error
		})
}



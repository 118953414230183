import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'  
import {showNotification } from '../../actions/notifications'
import {updateRepo } from '../../actions/repos'
import {finishImageUpload } from '../../actions/modals'
import { newRepo } from '../../actions/repos'
import EditRepoModal from '../../components/repo/EditRepoModal' 
import EditRepoImageModal from '../../components/repo/EditRepoImageModal'
import {uploadRepoImageToCloudinary} from '../../actions/cloudinary'
import gradeLevels from '../../staticData/gradeLevels.js'

  
class EditRepoContainer extends Component {
  
	constructor(props){
		super(props)
		this.showEditImageModal=this.showEditImageModal.bind(this)
		this.uploadImage=this.uploadImage.bind(this)
		this.handleNameChange=this.handleNameChange.bind(this)
		this.handleDescriptionChange=this.handleDescriptionChange.bind(this)
		this.deleteImage=this.deleteImage.bind(this)
		this.createRepo=this.createRepo.bind(this)
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)
		this.repoHasEdits=this.repoHasEdits.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.updateRepoDescription=this.updateRepoDescription.bind(this)
		this.saveRepoUpdates=this.saveRepoUpdates.bind(this)
		this.selectGradeLevel=this.selectGradeLevel.bind(this)
		this.selectOtherGradeLevel=this.selectOtherGradeLevel.bind(this)
		this.selectStaff=this.selectStaff.bind(this)
		this.selectHigherEducation=this.selectHigherEducation.bind(this)
		this.validateGradeLevels=this.validateGradeLevels.bind(this)
		this.clickDoneButton=this.clickDoneButton.bind(this)
		this.formatSelectedGrades=this.formatSelectedGrades.bind(this)
		this.submitButtonDisabled=this.submitButtonDisabled.bind(this)

		let repoName='New Pack'
		let repoDescription=''
		let repoImage = ''
		let repoSubject=null
		let repoSubjectParent=null
		let subjectOtherString=''
		let language=null
		let selectedOtherGradeLevel=false
		let selectedStaff=false
		let selectedHigherEducation=false
		let gradeOtherString=''

		let selectedGradeLevels={}
		gradeLevels.map((gradeLevel)=>{
			selectedGradeLevels[gradeLevel.grade]=false
			return null
		})


		if(props.repo){
			repoName=props.repo.name
			repoDescription=props.repo.description
			language=props.repo.language
			repoImage=props.repo.image
			if(props.repo.subject){
				repoSubject=props.repo.subject.id
				if(props.repo.subject.parent){
					repoSubjectParent=props.repo.subject.parent.id
				}
				

			}
			subjectOtherString=props.repo.subjectOther
			gradeOtherString=props.repo.gradeOther
			if(props.repo.grade && props.repo.grade.length !==0){
				const currentGrade=props.repo.grade

				gradeLevels.map((gradeLevel)=>{
					if(currentGrade.includes(gradeLevel.grade)){
						selectedGradeLevels[gradeLevel.grade]=true	
					}else{
						selectedGradeLevels[gradeLevel.grade]=false
					}
				return null
				})
				if(currentGrade.includes('higher')){
					selectedHigherEducation=true
				}
				if(currentGrade.includes('staff')){
					selectedStaff=true
				}if(currentGrade.includes('other')){
					selectedOtherGradeLevel=true
				}

			}
		}


		


		this.state={
			showImageSearchModal:false,
			repoName:repoName,
			repoDescription:repoDescription,
			repoImage:repoImage,
			uploadingToCloudinary:false,
			imageToUploadOnCreate:null,
			submitLoading:false,
			selectedSubjectId:repoSubject,
			selectedSubjectParentId:repoSubjectParent,
			subjectOtherString:subjectOtherString,
			selectedGradeLevels:selectedGradeLevels,
			selectedOtherGradeLevel:selectedOtherGradeLevel,
			selectedStaff:selectedStaff,
			selectedHigherEducation:selectedHigherEducation,
			editedGradeLevels:false,
			gradeOtherString:gradeOtherString,
			error:null,
			language:language
		}        

	}        


	UNSAFE_componentWillReceiveProps(nextProps) {
		if(this.props.uploadingImage !== nextProps.uploadingImage && nextProps.uploadingImage) {
			if(this.props.repo){
				this.uploadImage(nextProps.uploadingImage,nextProps.thumbnail)
			}else{
				this.setState({imageToUploadOnCreate:nextProps.uploadingImage,showImageSearchModal:false})
			}
		}
		
	}



	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown)
  		
	}
	
	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)

	}

	selectGradeLevel(grade){

		let newGradeArray=Object.assign({},this.state.selectedGradeLevels)
		newGradeArray[grade]=!newGradeArray[grade]
		this.setState({editedGradeLevels:true,selectedGradeLevels:newGradeArray,selectedOtherGradeLevel:false,selectedStaff:false,error:null})

	}
	selectOtherGradeLevel(){
		let selectedGradeLevels={}
		gradeLevels.map((gradeLevel)=>{
			selectedGradeLevels[gradeLevel.grade]=false
			return null
		})

		this.setState({editedGradeLevels:true,selectedGradeLevels:selectedGradeLevels,selectedOtherGradeLevel:!this.state.selectedOtherGradeLevel,selectedStaff:false,selectedHigherEducation:false,error:null})

	}
	selectStaff(){
		let selectedGradeLevels={}
		gradeLevels.map((gradeLevel)=>{
			selectedGradeLevels[gradeLevel.grade]=false
			return null
		})

		this.setState({editedGradeLevels:true,selectedGradeLevels:selectedGradeLevels,selectedOtherGradeLevel:false,selectedStaff:!this.state.selectedStaff,selectedHigherEducation:false,error:null})

	}
 

	selectHigherEducation(){
		this.setState({editedGradeLevels:true,selectedOtherGradeLevel:false,selectedStaff:false,selectedHigherEducation:!this.state.selectedHigherEducation,error:null})

	}



	validateGradeLevels(){ 
		const {selectedGradeLevels,selectedHigherEducation}=this.state

		let isValidGradeRange=true

		if(!this.state.selectedOtherGradeLevel && !this.state.selectedStaff){

			let arrayOfSelectedGradeIncidies=[]
			let isSequential=true

			Object.keys(selectedGradeLevels).map((level,index)=>{
				if(selectedGradeLevels[level]===true){
					arrayOfSelectedGradeIncidies.push(index)
				}
				return null
			})

			const selectedGradesCount=arrayOfSelectedGradeIncidies.length



			const minSelectedGrade=arrayOfSelectedGradeIncidies[0]
			const maxSelectedGrade=arrayOfSelectedGradeIncidies[selectedGradesCount-1]

			if(selectedGradesCount===0){
				isSequential=true
			}else{

				if(selectedHigherEducation && selectedGradesCount!==0){
					if(maxSelectedGrade !== (Object.keys(selectedGradeLevels).length-1)){
						isSequential=false
					}
				}

				if(selectedGradesCount !==1){

					if((maxSelectedGrade - minSelectedGrade)+1 !==selectedGradesCount){
						isSequential=false
					}
				}
			}
	
			isValidGradeRange=isSequential
		}
		return isValidGradeRange


	}







 	repoHasEdits(){
		const {repoName,repoDescription,repoImage,uploadingToCloudinary,selectedSubjectId,editedGradeLevels} = this.state
		const {repo} = this.props
		let repoSubjectId
		if(repo.subject && repo.subject.id){
			repoSubjectId=repo.subject.id
		}


		if(uploadingToCloudinary){
			return true
		}
		else if(repo){
			const {name,description,image} = repo
			if(repoName !== name){
				return true
			}else if(repoDescription !== description){
				return true
			}else if (repoImage !== image){
				return true
			}else if(repoSubjectId !== selectedSubjectId){
				return true
			}else if(editedGradeLevels){
				return true
			}else if(this.state.language !==repo.language){
				return true
			}
			else return false
		}else return true

	}




	deleteImage(){
		this.setState({repoImage:''})
	}


	createRepo(){
		this.setState({submitLoading:true})
		const grade=this.formatSelectedGrades()
		const language=this.state.language || ''
		this.props.newRepo({name:this.state.repoName,description:this.state.repoDescription,image:'',subject:this.state.selectedSubjectId,	'subjectOther':this.state.subjectOtherString,'grade':grade,'language':language,gradeOther:this.state.gradeOtherString}).then((response) => {
			const {imageToUploadOnCreate} = this.state
			
			if(imageToUploadOnCreate){		
				this.props.uploadRepoImageToCloudinary(imageToUploadOnCreate,response.id).then((cloudinaryResponse) => {
					const version=cloudinaryResponse.version
					const imageString = `v${version}`
					this.props.finishImageUpload()
					
					const requestData = {...response,
						'image':imageString	
					}
					this.props.updateRepo(requestData).then(() => {
						this.props.hideModal()
						this.props.history.push(`/packs/${response.id}`) 
					})

				})
				 .catch((error) => {
				 })	
			}
			else{
				this.props.hideModal()
				this.props.history.push(`/packs/${response.id}`) 
			}
		})	

	}


	saveRepoUpdates(){
		this.setState({submitLoading:true})
		const nowDate = new Date()
		const grade=this.formatSelectedGrades()
		const language=this.state.language||''
		const requestData = {...this.props.repo,
			'name': this.state.repoName,
			'description':this.state.repoDescription,
			'image':this.state.repoImage,
			'clientModified': nowDate,
			'subject':this.state.selectedSubjectId,
			'subjectOther':this.state.subjectOtherString,
			'grade':grade,
			'language':language,
			'gradeOther':this.state.gradeOtherString
		}
		this.props.updateRepo(requestData).then(() => {
			this.props.hideModal()
			const notificationMessage=`${this.state.repoName}`
			this.props.showNotification(notificationMessage,'Updated','default')
		})
	}
		




	uploadImage(img,thumbnail) {
		this.setState({showImageSearchModal:false})
		this.setState({uploadingToCloudinary:true})
	
		this.props.uploadRepoImageToCloudinary(img,this.props.repo.id).then((response) => {
			const version=response.version
			this.setState({repoImage:`v${version}`})
			this.props.finishImageUpload()
			this.setState({uploadingToCloudinary:false})
			return `v${version}`

		})
		 .catch((error) => {
		 	this.setState({uploadingToCloudinary:false})
		 })
	}


	handleNameChange(e){
		this.setState({
			repoName:e.target.value,
		})
	}

	handleDescriptionChange(e){
		this.setState({
			repoDescription:e.target.value,
		})
	}

	updateRepoDescription(newDescription){ //for with paste
		this.setState({
			repoDescription:newDescription,
		})
	}


	showEditImageModal(){
		this.setState({showImageSearchModal:true})
	}

	formatSelectedGrades(){
		let gradeArray = []			
		if(this.state.selectedOtherGradeLevel){
		gradeArray=['other']
					}
		else if(this.state.selectedStaff){
			gradeArray=['staff']
		}
		else{
			const {selectedGradeLevels}=this.state
			Object.keys(selectedGradeLevels).map((level,index)=>{
				if(selectedGradeLevels[level]===true){
					gradeArray.push(level)
				}
			return null
			})
			if(this.state.selectedHigherEducation){
				gradeArray.push('higher')
			}
		}
		return gradeArray
	}



	handleKeyDown(e){
		if(e.keyCode === 27){//escape key
			if(this.state.showImageSearchModal){
				this.setState({showImageSearchModal:false})
			}	
			else {
				const {repo} = this.props
				if(!repo){
					this.props.hideModal()
				}else{
					const repoHasEdits= this.repoHasEdits()
					if(!repoHasEdits){
						this.props.hideModal()
					}
				}
			}
		}
		if(e.keyCode === 13){//escape key
			if(!this.state.showImageSearchModal){
				if(this.state.repoName){
					const {repo} = this.props
					if(!repo){
						this.createRepo()
			
					}
					else{
						this.saveRepoUpdates()
					}
				}
			}
		}
		}			

	handleBackgroundClick(){
		if(this.state.showImageSearchModal){
			this.setState({showImageSearchModal:false})
		}	
		else {
			const {repo} = this.props
			if(!repo){
				this.props.hideModal()
			}else{
				const repoHasEdits= this.repoHasEdits()
				if(!repoHasEdits){
					this.props.hideModal()
				}
			}
		}
	}

	clickDoneButton(){
		let isValidGradeRange=true
		if(this.state.editedGradeLevels){
			//validate grade levels
			isValidGradeRange=this.validateGradeLevels()

		}if(isValidGradeRange){
			if(this.props.repo){
				this.saveRepoUpdates()
			}else{
				this.createRepo()
			}

		}else{
			this.setState({error:'Grade-level needs to be a continuous range without gaps. Please check the grade range and try again.'})
		}

	}



	submitButtonDisabled(){
		let isDisabled=false
		const {repo}=this.props
		const {repoImage,selectedSubjectId,language,selectedGradeLevels,selectedHigherEducation,selectedOtherGradeLevel,selectedStaff}=this.state
		
		if(this.state.repoName === '' || this.state.uploadingToCloudinary){
			isDisabled = true
		}
		if(repo && repo.published){
			if(!repoImage || !selectedSubjectId || !language){
				isDisabled=true
			}
			if(!selectedHigherEducation && !selectedOtherGradeLevel && !selectedStaff){
				let gradeArray=[]
				Object.keys(selectedGradeLevels).map((level,index)=>{
					if(selectedGradeLevels[level]===true){
						gradeArray.push(level)
					}
					return null
				})
				if(gradeArray.length===0){
					isDisabled=true
				}
			}
		}
		return isDisabled
	}



	render() {
		const {repo}=this.props
		const {showImageSearchModal}=this.state
		let submitButtonDisabled = this.submitButtonDisabled()
		
	

		return(

			<React.Fragment>

				{!showImageSearchModal && 
					<div className='repoDetailsModalContainer'>
						<EditRepoModal
							repo={repo}
							newRepo={this.props.newRepo}
							hideModal={this.props.hideModal}
							showEditImageModal={this.showEditImageModal}
							repoName={this.state.repoName}
							repoDescription={this.state.repoDescription}
							repoImage={this.state.repoImage}
							handleNameChange={this.handleNameChange}
							handleDescriptionChange={this.handleDescriptionChange}
							deleteImage={this.deleteImage}
							uploadingToCloudinary={this.state.uploadingToCloudinary}
							imageToUploadOnCreate={this.state.imageToUploadOnCreate}
							deletePendingImage={()=>{this.setState({imageToUploadOnCreate:null})}}
							showNotification={this.props.showNotification}
							submitButtonDisabled={submitButtonDisabled}
							updateRepoDescription={this.updateRepoDescription}
							submitLoading={this.state.submitLoading}
							selectedSubjectId={this.state.selectedSubjectId}
							setSelectedSubjectId={(subjectId)=>{this.setState({selectedSubjectId:subjectId})}}
							selectedSubjectParentId={this.state.selectedSubjectParentId}
							setSelectedSubjectParentId={(parentId)=>{this.setState({selectedSubjectParentId:parentId})}}
							subjectOtherString={this.state.subjectOtherString}
							setSubjectOtherString={(string)=>{this.setState({subjectOtherString:string})}}
							setGradeOtherString={(string)=>{this.setState({gradeOtherString:string})}}
							gradeOtherString={this.state.gradeOtherString}
							selectGradeLevel={this.selectGradeLevel}
							selectOtherGradeLevel={this.selectOtherGradeLevel}
							selectStaff={this.selectStaff}
							selectHigherEducation={this.selectHigherEducation}
							validateGradeLevels={this.validateGradeLevels}
							selectedGradeLevels={this.state.selectedGradeLevels}
							selectedOtherGradeLevel={this.state.selectedOtherGradeLevel}
							selectedStaff={this.state.selectedStaff}
							selectedHigherEducation={this.state.selectedHigherEducation}
							clickDoneButton={this.clickDoneButton}
							error={this.state.error}
							language={this.state.language}
							selectLanguage={(language)=>{this.setState({language:language})}}


						/>
						<div className='repoDetailsModalBackground' onClick={this.handleBackgroundClick} />
					</div>
				}
				
				{showImageSearchModal && 
					<div className='repoDetailsModalContainer'>
						<EditRepoImageModal
							closeImageSearch={()=>{this.setState({showImageSearchModal:false})}}
						/>
						<div className='repoDetailsModalBackground' onClick={this.handleBackgroundClick} />
					</div>
				}

				

			

			</React.Fragment>

		)		
	}
}


export default withRouter(connect(
	(state) => ({uploadingImage:state.modals.image}),
	{newRepo, 
		showNotification,
		updateRepo,
		uploadRepoImageToCloudinary,
		finishImageUpload,
		}
)(EditRepoContainer))



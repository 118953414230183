import filter from 'lodash/filter'
import {isSurvey} from '../isSurvey'

export function calculateSetPollCorrectPercent(setPoll){ //returns -1 if all setPollPolls are survey questions
	let totalResponses=0
	let totalCorrect=0
	let setPollHasNonSurveyQuestions=false

	if(setPoll.polls){
		const setPollPolls=setPoll.polls
		Object.keys(setPollPolls).forEach((key)=>{
			const poll=setPollPolls[key]
			if(poll.snapshot && !isSurvey(poll.snapshot)){
				setPollHasNonSurveyQuestions=true
				let responsesByCardArray=[]
				if(poll.responsesByCard){
					Object.keys(poll.responsesByCard).forEach((key) => {
						const response=poll.responsesByCard[key]
						const modifiedResponse={...response,card:key}
						responsesByCardArray.push(modifiedResponse)
					})
				}
				totalResponses+=responsesByCardArray.length

				const responsesAnswerA=filter(responsesByCardArray, {'answer':'A'})
				const responsesAnswerB=filter(responsesByCardArray, {'answer':'B'})
				const responsesAnswerC=filter(responsesByCardArray, {'answer':'C'})
				const responsesAnswerD=filter(responsesByCardArray, {'answer':'D'})

				if(poll.snapshot.choices[0].correct){
					totalCorrect+=responsesAnswerA.length
				}
				if(poll.snapshot.choices[1].correct){
					totalCorrect+=responsesAnswerB.length
				}
				if(poll.snapshot.choices[2] && poll.snapshot.choices[2].correct){
					totalCorrect+=responsesAnswerC.length
				}
				if(poll.snapshot.choices[3] && poll.snapshot.choices[3].correct){
					totalCorrect+=responsesAnswerD.length
				}
			}
		})
	}

	let correctPercentage=0
	if(totalResponses !== 0){
		correctPercentage= 100* totalCorrect/totalResponses
	}
	if(!setPollHasNonSurveyQuestions){
		correctPercentage=-1
	}
	return correctPercentage
}
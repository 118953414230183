export default function (state = null, action) {
	switch (action.type) {

	case 'CLIENT_PLAYBACK_COMMAND_RECIEVED':
		return action.command


	case 'CLEAR_PLAYBACK_COMMAND':
		return null

	// case 'UPDATE_BILLING_SUCCESS':
	// 	return action.response

	case 'LOGOUT':
		return null
	
	default:
		return state
	}
}

const QuestionVideoNodeSpec = {
  inline: false,
  attrs: {
    youtubeId:{default: null},
    addedAt:{default:''},
    fullDuration:{default:null},
    title:{default:null},
    channelTitle:{default:null},
    thumbnail:{default:{
      w:null,
      h:null,
      src:null
    }},
    isClipped:{default:false},
    start:{defult:null},
    end:{defualt:null},
    isPlaying:{default:false},
    questionMediaDynamicHeight:{default: null},
    template:{default:null},
    slideWidth:{default:''},
  },
  group: 'block',
  parseDOM: [{tag: 'questionVideo', getAttrs(dom) {
      return {}
    },}],
    toDOM(node) {
    return ['questionVideo',{}]},
}
export default QuestionVideoNodeSpec
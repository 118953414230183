export function updateSlideWidth(width) {
	if(window.view && window.view.state){
		let tr = window.view.state.tr
		window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(node.attrs.slideWidth || node.attrs.slideWidth===''){
				tr.setNodeMarkup(pos, null, {...node.attrs,slideWidth:width})
			}
		})
		tr.setMeta('addToHistory', false)  
		return window.view.dispatch(tr)
	}
}
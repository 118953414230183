import api from '../api'
import * as Sentry from '@sentry/browser'
import {fetchQuestionsNoMinTime} from './questions'
import {fetchSetsNoMinTime} from './sets'

export function fetchFolders() {
	return (dispatch) => {
		api.batchedFetch('/folders?archived=false&parentArchived=false')
		.then((response) => {
			dispatch({ type: 'FETCH_FOLDERS_SUCCESS',response})
		})
		.catch(err => {})
		api.batchedFetch('/folders?allRepos=true&archived=false&parentArchived=false')
		.then((response) => {
			dispatch({ type: 'FETCH_FOLDERS_SUCCESS',response})
		})
		.catch(err => {})
	}
}

//For displaying pack preview to user who has invitation pending or disabled access 
//http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#query-folders-as-preview
export function fetchFoldersPreview() {
	return (dispatch) => api.fetch('/folders/preview')
		.then((response) => {
			dispatch({ type: 'FETCH_FOLDERS_PREVIEW_SUCCESS', response})
		})
	 .catch(err => {})
}

export function fetchFoldersForRepo(repoId) {
	return (dispatch) => api.batchedFetch(`/folders?archived=false&parentArchived=false&repo=${repoId}`)
		.then((response) => {
			dispatch({ type: 'FETCH_REPO_FOLDERS_SUCCESS',response})
		})
		.catch(err => {})
}

export function fetchArchivedFolders() {
	return (dispatch) => {
		api.batchedFetch('/folders?archived=true&&parentArchived=false')
    .then((response) => {
	      dispatch({ type: 'FETCH_ARCHIVED_FOLDERS_SUCCESS',response})
    })
     .catch(err => {})
     api.batchedFetch('/folders?allRepos=true&archived=true&&parentArchived=false')
    .then((response) => {
	      dispatch({ type: 'FETCH_ARCHIVED_FOLDERS_SUCCESS',response})
    })
     .catch(err => {})
 }
}


export function fetchArchivedFoldersForRepo(repoId) {
	return (dispatch) => api.batchedFetch(`/folders?archived=true&&parentArchived=false&repo=${repoId}`)
    .then((response) => {
	      dispatch({ type: 'FETCH_ARCHIVED_FOLDERS_SUCCESS',response})
    })
    .catch(err => {})
}


export function newFolder(data) {
	return (dispatch) => api.post('/folders/',data)
    .then((folder) => {
	      dispatch({ type: 'CREATE_FOLDER_SUCCESS', folder,syncAction:true })
	      return folder
    })
    .catch(err => {
       Sentry.withScope(scope => {
			scope.setExtra('request', data)
			Sentry.captureException(new Error(err))
		})
	})
}

export function updateFolder(data,folderId,hasMovedRepo) {
	return (dispatch) => api.put(`/folders/${folderId}`,data)
    .then((response) => {
	      dispatch({ type: 'UPDATE_FOLDER_SUCCESS', response, syncAction:true})
	      if(hasMovedRepo){
	      	dispatch(fetchQuestionsNoMinTime())
	      	dispatch(fetchSetsNoMinTime())
	      	dispatch(fetchFolders())	
	      }
			return response
    })
    .catch(err => {})
}


export function deleteFolder(folderId) {
	return (dispatch) => api.delete(`/folders/${folderId}`)
    .then((response) => {
	      dispatch({ type: 'DELETE_FOLDER_SUCCESS', folderId,syncAction:true})
    })
     .catch(err => {})
}


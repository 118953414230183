import React, {Component} from 'react'
import Icon from '../../components/misc/Icons'
import Dropdown from '../../components/uiKit/Dropdown'
import DropdownSelect from '../../components/uiKit/DropdownSelect'
import DropdownSelectItem from '../../components/uiKit/DropdownSelectItem'

 



class ImportQuestionsInputAreaLeftGutterQuestion extends Component{


	render(){	
		const {browser,isOutsideLimit,questionIndex,choicesObject,setCorrectAnswer,choicesPerQuestion,activeQuestionIndex}=this.props
		const questionChoices=choicesObject[questionIndex]
	 

		const isSurvey=questionChoices.isSurvey
		let isActiveCursor=false
		if(browser!=='msie' && questionIndex === activeQuestionIndex){
			isActiveCursor=true
		}
	

		

		return(		
			<div className={'importQuestionsInputAreaLeftGutter-question' + 
				(isOutsideLimit ? ' importQuestionsInputAreaLeftGutter-question--outsideLimit':'') +
				(isActiveCursor ? ' importQuestionsInputAreaLeftGutter-question--isActiveCursor':'')
			}>				
				<div className='importQuestionsInputAreaLeftGutter-question-number'>
					{questionIndex+1}				
				</div>

				{isActiveCursor &&
					<div className='importQuestionsInputAreaLeftGutter-question-activeRightBorder'/>
				}
				<div className='importQuestionsInputAreaLeftGutter-question-dropdownContainer'>
					<Dropdown>						
						<DropdownSelect>
							<DropdownSelectItem isActive={!isSurvey} label='Graded'onSelect={()=>{this.props.setToGraded(questionIndex)}}/>																	
							<DropdownSelectItem isActive={isSurvey} label='Survey' onSelect={()=>{this.props.setToSurvey(questionIndex)}}/>							
						</DropdownSelect>
					</Dropdown>
				</div>
				<div className='importQuestionsInputAreaLeftGutter-question-markerArrow'>
					<Icon name='play' /> 
				</div>
				<ImportQuestionsInputAreaLeftGutterQuestionChoice setCorrectAnswer={setCorrectAnswer} choiceLetter={'A'} questionIndex={questionIndex} isSurvey={isSurvey} isCorrect={questionChoices['A']}  key={`${questionIndex}_A`}/>
				<ImportQuestionsInputAreaLeftGutterQuestionChoice setCorrectAnswer={setCorrectAnswer} choiceLetter={'B'} questionIndex={questionIndex} isSurvey={isSurvey}  isCorrect={questionChoices['B']}  key={`${questionIndex}_B`}/>
				{choicesPerQuestion>2 &&
					<ImportQuestionsInputAreaLeftGutterQuestionChoice setCorrectAnswer={setCorrectAnswer} choiceLetter={'C'} questionIndex={questionIndex} isSurvey={isSurvey}  isCorrect={questionChoices['C']} key={`${questionIndex}_C`}/>
				}
				{choicesPerQuestion>3 &&
					<ImportQuestionsInputAreaLeftGutterQuestionChoice setCorrectAnswer={setCorrectAnswer} choiceLetter={'D'} questionIndex={questionIndex} isSurvey={isSurvey}  isCorrect={questionChoices['D']} key={`${questionIndex}_D`}/>
				}				
			</div>
				
			
		)
	}
}


class ImportQuestionsInputAreaLeftGutterQuestionChoice extends Component{


	render(){	

		return(		
			<div className={'importQuestionsInputAreaLeftGutter-question-choice' + ((this.props.isCorrect && !this.props.isSurvey) ? ' importQuestionsInputAreaLeftGutter-question-choice--isCorrect' : '') + ((!this.props.isCorrect && !this.props.isSurvey) ? ' importQuestionsInputAreaLeftGutter-question-choice--isNotCorrect':'') + (this.props.isSurvey ?  ' importQuestionsInputAreaLeftGutter-question-choice--isSurvey':'')} onClick={()=>{this.props.setCorrectAnswer(this.props.questionIndex,this.props.choiceLetter)}}>
				{this.props.choiceLetter}
			</div>
		)
	}
}


class ImportQuestionsInputAreaLeftGutter extends Component{


	render(){	
		
		const {remainingQuestionsInSetLimit,questionNumberArray,choicesObject,choicesPerQuestion,setToSurvey,setToGraded,activeQuestionIndex}=this.props

		return(		
			<div className='importQuestions-inputArea-leftGutter'>
				{questionNumberArray.map((questionIndex)=>{
					return(
						<ImportQuestionsInputAreaLeftGutterQuestion 
							browser={this.props.browser}
							activeQuestionIndex={activeQuestionIndex} 
							setToSurvey={setToSurvey} 
							setToGraded={setToGraded} 
							choicesPerQuestion={choicesPerQuestion} 
							setCorrectAnswer={this.props.setCorrectAnswer} 
							choicesObject={choicesObject} 
							questionIndex={questionIndex} 
							key={questionIndex}
							isOutsideLimit={questionIndex+1>remainingQuestionsInSetLimit}

						/>
					)
				})}										
			</div>		
		)
	}
}

					



export default ImportQuestionsInputAreaLeftGutter

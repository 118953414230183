//turn unicode into regular letters for pasting into mathquil

export function cleanPastedEquationCharacter(character){ 	
	let cleanCharacter=character
	// Lowercase math
	if(character==='𝑎'){
		cleanCharacter='a'
	}
	else if(character==='𝑏'){
		cleanCharacter='b'		
	}
	else if(character==='𝑐'){
		cleanCharacter='c'
	}
	else if(character==='𝑑'){
		cleanCharacter='d'
	}
	else if(character==='𝑒'){
		cleanCharacter='e'
	}
	else if(character==='𝑓'){
		cleanCharacter='f'
	}
	else if(character==='𝑔'){
		cleanCharacter='g'
	}
	else if(character==='ℎ'){
		cleanCharacter='h'
	}
	else if(character==='𝑖'){
		cleanCharacter='i'
	}
	else if(character==='𝑗'){
		cleanCharacter='j'
	}
	else if(character==='𝑘'){
		cleanCharacter='k'
	}
	else if(character==='𝑙'){
		cleanCharacter='l'
	}
	else if(character==='𝑚'){
		cleanCharacter='m'
	}
	else if(character==='𝑛'){
		cleanCharacter='n'
	}
	else if(character==='𝑜'){
		cleanCharacter='o'
	}
	else if(character==='𝑝'){
		cleanCharacter='p'
	}
	else if(character==='𝑞'){
		cleanCharacter='q'
	}
	else if(character==='𝑟'){
		cleanCharacter='r'
	}
	else if(character==='𝑠'){
		cleanCharacter='s'
	}
	else if(character==='𝑡'){
		cleanCharacter='t'
	}
	else if(character==='𝑢'){
		cleanCharacter='u'
	}
	else if(character==='𝑣'){
		cleanCharacter='v'
	}
	else if(character==='𝑤'){
		cleanCharacter='w'
	}
	else if(character==='𝑥'){
		cleanCharacter='x'
	}
	else if(character==='𝑦'){
		cleanCharacter='y'
	}
	else if(character==='𝑧'){
		cleanCharacter='z'
	}

	// Uppercase Math
	else if(character==='𝐴'){
		cleanCharacter='A'
	}
	else if(character==='𝐵'){
		cleanCharacter='B'
	}
	else if(character==='𝐶'){
		cleanCharacter='C'
	}
	else if(character==='𝐷'){
		cleanCharacter='D'
	}
	else if(character==='𝐸'){
		cleanCharacter='E'
	}
	else if(character==='𝐹'){
		cleanCharacter='F'
	}
	else if(character==='𝐺'){
		cleanCharacter='G'
	}
	else if(character==='𝐻'){
		cleanCharacter='H'
	}
	else if(character==='𝐼'){
		cleanCharacter='I'
	}
	else if(character==='𝐽'){
		cleanCharacter='J'
	}
	else if(character==='𝐾'){
		cleanCharacter='K'
	}
	else if(character==='𝐿'){
		cleanCharacter='L'
	}
	else if(character==='𝑀'){
		cleanCharacter='M'
	}
	else if(character==='𝑁'){
		cleanCharacter='N'
	}
	else if(character==='𝑂'){
		cleanCharacter='O'
	}
	else if(character==='𝑃'){
		cleanCharacter='P'
	}
	else if(character==='𝑄'){
		cleanCharacter='Q'
	}
	else if(character==='𝑅'){
		cleanCharacter='R'
	}
	else if(character==='𝑆'){
		cleanCharacter='S'
	}
	else if(character==='𝑇'){
		cleanCharacter='T'
	}
	else if(character==='𝑈'){
		cleanCharacter='U'
	}
	else if(character==='𝑉'){
		cleanCharacter='V'
	}
	else if(character==='𝑊'){
		cleanCharacter='W'
	}
	else if(character==='𝑋'){
		cleanCharacter='X'
	}
	else if(character==='𝑌'){
		cleanCharacter='Y'
	}
	else if(character==='𝑍'){
		cleanCharacter='Z'
	}
	// Symbols seem to be working okay, might be a bug surface in the future though


	




	return cleanCharacter
	
}




import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../misc/Icons'
import {isCurrentUser} from '../../utils/isCurrentUser'
import {getLinkForRepo} from '../../utils/getLinkForRepo'

class SearchResultsRepoCard extends React.Component{

	render(){	

		const {repo} = this.props
		const linkPath=getLinkForRepo(repo)
		let inPublicConsumerRepo = false
		let inPublicAuthorRepo = false
		if(repo.published){
			if(isCurrentUser(repo.author)){
				inPublicAuthorRepo=true
			}else{
				inPublicConsumerRepo=true
			}
		}
		return(
			<Link to={linkPath}>
				<div className='searchResultsCard searchResultsCard--repo'>									
					<div className='searchResultsCard-title'>
						{repo.name}
						{repo.shared && <Icon name='shared' />}
						{inPublicConsumerRepo && <Icon name='globe-shared'/>}
						{inPublicAuthorRepo &&  <Icon name='globe-shared-author'/>}														
					</div>
					<div className='searchResultsCard-rightArrow'>
						<Icon name='chevron-right' />
					</div>
				</div>
			</Link>
		)
	}
}

export default SearchResultsRepoCard

import React from 'react'
import ReactDOM from 'react-dom'
import { TextSelection } from 'prosemirror-state'
import EquationEditorModal from '../editorUI/modals/equationEditor/EquationEditorModal'
import { katexRender } from '../../../utils/katexHelper'

class KatexMathNodeView {
  constructor(node, view, getPos) {
    this.node = node
    this.outerView = view
    this.getPos = getPos

    const { latex } = node.attrs
    const content = latex

    this.dom = document.createElement('span')
    this.dom.className = 'katexOuterContainer'

    this.mathViewBodySpan = document.createElement('span')
    this.mathViewBodySpan.className = 'katexInnerContainer'

    // img spaces for cursor positioning https://github.com/chanzuckerberg/czi-prosemirror  czi-math-view-body-img
    const imgSpacer = document.createElement('img')
    imgSpacer.setAttribute('src', 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAABGdBTUEAALGPC/xhBQAAAAtJREFUCB1jYAACAAAFAAGNu5vzAAAAAElFTkSuQmCC')
    imgSpacer.className = 'katexInnerContainer-imageSpacer'
    this.mathViewBodySpan.appendChild(imgSpacer)

    const mathViewBodyContentSpan = document.createElement('span')
    mathViewBodyContentSpan.className = 'katexInnerContainer-content'
    this.mathViewBodySpan.appendChild(mathViewBodyContentSpan)
    katexRender({
      latex: content,
      dom: mathViewBodyContentSpan,
    })

    this.dom.appendChild(this.mathViewBodySpan)
    this.selected = false
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.innerView = null
    this.openModal = this.openModal.bind(this)
    this.updateLatexValue = this.updateLatexValue.bind(this)
    this.valueChanged = this.valueChanged.bind(this)
    this.dom.addEventListener('click', this.openModal)
    window.addEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(e) {
    if (e.keyCode === 13 && this.selected) {
      e.preventDefault()
      e.stopPropagation()
      this.openModal()
    }
  }

  selectNode() {
    this.selected = true
    this.dom.classList.add('ProseMirror-selectednode')
    this.mathViewBodySpan.classList.add('selected')
  }

  deselectNode() {
    this.selected = false
    this.dom.classList.remove('ProseMirror-selectednode')
    this.mathViewBodySpan.classList.remove('selected')
  }

  closeModal() {
    ReactDOM.render(null, document.getElementById('equationEditorModal'))
  }

  openModal() {
    const { latex, isAdvancedInput } = this.node.attrs
    const latexValue = latex
    const editorMode = isAdvancedInput ? 'advanced' : 'mathquill'
    ReactDOM.render(
      <EquationEditorModal
        mode={editorMode}
        updateLatexValue={this.updateLatexValue}
        cancel={this.closeModal}
        initialLatex={latexValue}
      />,
      document.getElementById('equationEditorModal'),
    )
  }

  updateLatexValue(value) {
    this.valueChanged(value)
    this.closeModal()
    this.deselectNode()
  }

  valueChanged(value) {
    const { tr } = this.outerView.state
    tr.setNodeMarkup(this.getPos(), null, { ...this.node.attrs, latex: value })
    const selection = new TextSelection(tr.doc.resolve(this.getPos() + this.node.nodeSize))
    tr.setSelection(selection) // position cursor after inserted stuff
    this.outerView.dispatch(tr)
    window.view.focus()
  }

  destroy() {
    this.dom.removeEventListener('click', this.openModal)
    window.removeEventListener('keydown', this.handleKeyDown)
    if (this.innerView) this.close()
  }

  stopEvent(event) {
    const { type } = event
    if (type === 'dragstart' || type === 'dragover' || type === 'drangend' || type === 'drop') { // prevent drag and drop events
      return true
    }
    return this.innerView && this.innerView.dom.contains(event.target)
  }

  ignoreMutation() { return true }
}

export default KatexMathNodeView

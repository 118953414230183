import React from 'react'
import { Spring, animated } from 'react-spring/renderprops'
import canAutoPlay from 'can-autoplay'
import YTReady from '../../../youtubeReady'
import { generateRandomId } from '../../../utils/generateRandomId'
import QuestionVideoControlBar from '../../richStaticSlide/media/video/QuestionVideoControlBar'

// YTPlayer states
// -1 – unstarted
// 0 – ended
// 1 – playing
// 2 – paused
// 3 – buffering
// 5 – video cued

// const windowZoomItemInternalPadding = 20 // PASS IN FROM SLIDE DEFINITIONS
const WINDOW_ZOOM_ITEM_INTERNAL_PADDING = 20

function calculateItemLayout(nowPlayingLayout) {
  // VIEWPORT DIMENSIONS
  const { viewportWidth } = nowPlayingLayout
  const { viewportHeight } = nowPlayingLayout
  const viewportAspect = viewportWidth / viewportHeight
  //
  // START ZOOM ITEM DIMENSIONS
  const globalVideoScaler = 0.75 // we scale down YT video so doesn't look silly when zoomed
  const inverseGlobalVideoScaler = 1 / globalVideoScaler
  // Find position of original on-slide video container
  const questionVideoZoomElement = document.getElementById('videoPlayerZoomable')
  let rect
  if (questionVideoZoomElement) {
    rect = questionVideoZoomElement.getBoundingClientRect()
  }
  let videoStartZoomWidth = 0
  let videoStartZoomHeight = 0
  let videoStartZoomX = 0
  let videoStartZoomY = 0

  if (rect) {
    videoStartZoomWidth = rect.width
    videoStartZoomHeight = rect.height
    videoStartZoomX = rect.x
    videoStartZoomY = rect.y
  }
  const videoAspect = videoStartZoomWidth / videoStartZoomHeight
  const scaledVideoStartZoomWidth = videoStartZoomWidth * inverseGlobalVideoScaler
  const scaledVideoStartZoomHeight = videoStartZoomHeight * inverseGlobalVideoScaler

  //
  // END ZOOM ITEM DIMENSIONS
  // find maximum available width and height
  const videoEndZoomMaxWidth = viewportWidth - WINDOW_ZOOM_ITEM_INTERNAL_PADDING - WINDOW_ZOOM_ITEM_INTERNAL_PADDING
  const videoEndZoomMaxHeight = viewportHeight - WINDOW_ZOOM_ITEM_INTERNAL_PADDING - WINDOW_ZOOM_ITEM_INTERNAL_PADDING
  // find actual fitting item width and height
  let videoEndZoomWidth
  let videoEndZoomHeight
  if (viewportAspect >= videoAspect) { // screen wider than item
    videoEndZoomHeight = videoEndZoomMaxHeight
    videoEndZoomWidth = videoEndZoomHeight * videoAspect
  }
  if (viewportAspect < videoAspect) { // screen narrower than item
    videoEndZoomWidth = videoEndZoomMaxWidth
    videoEndZoomHeight = videoEndZoomWidth / videoAspect
  }

  // // scaled using global scalar
  // const scaledVideoEndZoomWidth = videoEndZoomWidth * inverseGlobalVideoScaler
  // const scaledVideoEndZoomHeight = videoEndZoomHeight * inverseGlobalVideoScaler

  // find end-state X and Y, this is to viewport
  const itemEndZoomX = (viewportWidth - videoEndZoomWidth) / 2
  const itemEndZoomY = (viewportHeight - videoEndZoomHeight) / 2
  //
  // ZOOM START --> ZOOM END

  // SCALE FACTOR
  const itemEndZoomScale = videoEndZoomWidth / videoStartZoomWidth

  // TRANSFORM ORIGIN (this effectively translates position of slide)
  const transformOriginX = (videoStartZoomX - itemEndZoomX) / (videoEndZoomWidth - videoStartZoomWidth)
  const transformOriginY = (videoStartZoomY - itemEndZoomY) / (videoEndZoomHeight - videoStartZoomHeight)

  //
  // CONTROL BAR POSITIONS
  // very similar to choice video player
  // START ZOOM CONTROL BAR (i.e. on choice)

  const slideWidth = nowPlayingLayout.slideDimensions.width

  const slideScale = slideWidth / 1280 // controls should be the same size on any size screen
  const startZoomControlBarScaler = 1.15 // hardcoded value to make controls a nice size
  const scaleStartControlBar = startZoomControlBarScaler * slideScale // controls can be slightly larger than normal

  const initialControlBarHeight = 35
  const bottomPaddingOfVideoWithoutControlBar = 20

  const startZoomControlBarContainer = {
    transform: `scale(${scaleStartControlBar})`,
    width: `${videoStartZoomWidth / scaleStartControlBar}px`,
    height: `${initialControlBarHeight}px`,
    bottom: `-${initialControlBarHeight * scaleStartControlBar + 2}px`, // 2 is padding
  }

  // END ZOOM CONTROL BAR (i.e. on choice)

  const endZoomControlBarScaler = 1.6 // hardcoded value to make controls a nice size
  const scaleEndControlBar = endZoomControlBarScaler * slideScale // controls can be slightly larger than normal

  const endZoomControlBarContainer = {
    transform: `scale(${scaleEndControlBar})`,
    width: `${viewportWidth / scaleEndControlBar}px`,
    height: `${initialControlBarHeight}px`,
    bottom: `${viewportHeight * 0.04}px`, // hardcoded, should come off the floor a bit, should be same as choice
    left: '0px',
  }
  //
  // STYLING IS FIXED
  // zooming also repositions slide through transform origin location

  const itemStyle = {
    width: `${videoStartZoomWidth}px`,
    height: `${videoStartZoomHeight}px`,
    left: `${videoStartZoomX}px`,
    top: `${videoStartZoomY - (initialControlBarHeight - bottomPaddingOfVideoWithoutControlBar)}px`,
    transformOrigin: `${transformOriginX * 100}% ${transformOriginY * 100}%`,
  }

  const videoStyle = {
    width: `${scaledVideoStartZoomWidth}px`,
    height: `${scaledVideoStartZoomHeight}px`,
    transform: `scale(${globalVideoScaler})`,
  }

  return {
    itemStyle,
    videoStyle,
    itemEndZoomScale,
    startZoomControlBarContainer,
    endZoomControlBarContainer,
  }
}

class LiveViewPromptVideoPlayer extends React.Component {
  constructor() {
    super()
    this.loadVideo = this.loadVideo.bind(this)
    this.playVideo = this.playVideo.bind(this)
    this.stopVideo = this.stopVideo.bind(this)
    this.pauseVideo = this.pauseVideo.bind(this)
    this.onPlayerReady = this.onPlayerReady.bind(this)
    this.onStateChange = this.onStateChange.bind(this)
    this.toggleMuteVideo = this.toggleMuteVideo.bind(this)
    this.updatePlayerCurrentTime = this.updatePlayerCurrentTime.bind(this)
    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.seekTo = this.seekTo.bind(this)
    this.onClipFinished = this.onClipFinished.bind(this)
    this.onVideoEnd = this.onVideoEnd.bind(this)
    this.restartVideo = this.restartVideo.bind(this)
    this.updateControlPlayback = this.updateControlPlayback.bind(this)
    this.skipPlayerCurrentTime = this.skipPlayerCurrentTime.bind(this)
    this.calculateLayout = this.calculateLayout.bind(this)

    this.playerId = generateRandomId()

    this.state = {
      playerReady: false,
      isPlaying: false,
      isMuted: false,
      playerCurrentTime: 0,
      // isDraggingSeekBar: false,
      isBuffering: false,
      itemStyle: {},
      videoStyle: {},
      itemEndZoomScale: 0,
      startZoomControlBarContainer: {},
      endZoomControlBarContainer: {},
      // showEndZoomControlBar: false,
    }
    this.timer = null
  }

  componentDidMount() {
    this.calculateLayout()
    const youtubeReady = YTReady
    youtubeReady.then((YT) => {
      this.loadVideo(YT)
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.playbackItem === 'promptMedia' && nextProps.pageVisibilityState === 'visible' && nextProps.nowPlayingModalVisible === true && this.props.clientPlaybackCommand === null && nextProps.clientPlaybackCommand) {
      this.props.clearPlaybackCommand()
      if (nextProps.clientPlaybackCommand.command === 'skip forward') {
        this.skipPlayerCurrentTime('forwards', nextProps.clientPlaybackCommand.length / 1000)
      } else {
        this.skipPlayerCurrentTime('backwards', nextProps.clientPlaybackCommand.length / 1000)
      }
    }

    if (nextProps.playbackItem === 'promptMedia') {
      if (nextProps.isPlaying && this.props.pageVisibilityState === 'visible' && nextProps.nowPlayingModalVisible === true) {
        if (!this.state.isPlaying) {
          this.playVideo()
        }
      } else if (this.state.isPlaying) {
        this.pauseVideo()
      }
    }
    if (nextProps.playbackItem !== 'promptMedia') {
      if (this.state.isPlaying) {
        this.stopVideo()
      }
    }
    if (this.props.pageVisibilityState === 'visible' && nextProps.pageVisibilityState === 'hidden') {
      if (this.state.isPlaying) {
        this.pauseVideo()
      }
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.layoutKey !== prevProps.layoutKey) || (this.props.showStudentList !== prevProps.showStudentList)) {
      this.calculateLayout()
    }
  }

  componentWillUnmount() {
    if (this.state.playerReady) {
      this.stopTimer()
      this.stopVideo()
    }
  }

  calculateLayout() {
    const itemLayout = calculateItemLayout(this.props.nowPlayingLayout)
    this.setState({
      itemStyle: itemLayout.itemStyle,
      videoStyle: itemLayout.videoStyle,
      itemEndZoomScale: itemLayout.itemEndZoomScale,
      startZoomControlBarContainer: itemLayout.startZoomControlBarContainer,
      endZoomControlBarContainer: itemLayout.endZoomControlBarContainer,
    })
  }

  skipPlayerCurrentTime(skipDirection, skipAmount) {
    if (this.state.playerReady) {
      const currentTime = this.player.getCurrentTime()
      let newCurrentTime = currentTime
      if (skipDirection === 'forwards') {
        newCurrentTime = Math.min(newCurrentTime + skipAmount, this.props.end)
      } else {
        newCurrentTime = Math.max(newCurrentTime - skipAmount, this.props.start)
      }
      this.seekTo(newCurrentTime)
    }
  }

  loadVideo(YT) {
    const { video } = this.props
    /* eslint-disable camelcase */
    this.player = new YT.Player(`youtube-player-${this.playerId}`, {
      videoId: video.youtubeId,
      playerVars: {
        controls: 0, // disable controls
        // 'cc_load_policy': 1, // don't know what this does
        loop: 0, // loop video
        autoplay: 0, // autoplay
        fs: 0, // show full screen option
        disablekb: 1, // disable keyboard shortcuts
        rel: 0, // either hides related (depreciated) or only shows from same chanel
        iv_load_policy: 3, // don't show video annotations by default
        hl: 'en', // interface language
        cc_lang_pref: 'en',
        // don't know what these do, from EdPuzzle
        playsInline: 1,
        showinfo: 0, // undocumented, should hide title but I guess depreciated
        wmode: 'opaque', // undocumented
        start: (this.props.start),
        // 'end':(this.props.end+1)
      },
      host: 'http://www.youtube-nocookie.com',
      height: '100%',
      width: '100%',
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onStateChange,
      },
    })
    /* eslint-enable camelcase */
  }

  onPlayerReady() {
    const muted = this.player.isMuted()
    this.setState({ playerReady: true, isMuted: muted })
  }

  onStateChange(event) {
    switch (event.data) {
      case -1:// unstarted
        this.setState({ isPlaying: false })
        this.stopTimer()
        this.setState({ isBuffering: false })
        break
      case 0:// ended
        this.setState({ isPlaying: false })
        this.stopTimer()
        this.onVideoEnd()
        this.setState({ isBuffering: false })
        break
      case 1:// playing
        this.setState({ isPlaying: true })
        this.startTimer()
        this.updateControlPlayback(true)
        this.setState({ isBuffering: false })
        this.props.hideAutoplayFailedAlert()
        break
      case 2:// paused
        this.stopTimer()
        this.setState({ isPlaying: false })
        this.updateControlPlayback(false)
        this.setState({ isBuffering: false })
        break
      case 3:// BUFFERING
        this.stopTimer()
        this.setState({ isBuffering: true })
        break
      default:
        break
    }
  }

  updateControlPlayback(isPlaying) {
    this.props.updateControlPlayback('promptMedia', isPlaying)
  }

  stopVideo() {
    this.player.stopVideo()
  }

  playVideo() {
    setTimeout(() => {
      const playerState = this.player.getPlayerState()
      let ytAutoplay
      if (playerState === 1 || playerState === 3) {
        ytAutoplay = true
      } else {
        ytAutoplay = false
      }
      let canAutoplayCheck
      canAutoPlay.video().then(({ result }) => {
        if (result === true) {
          canAutoplayCheck = true
        } else {
          canAutoplayCheck = false
        }
        if (ytAutoplay === false && canAutoplayCheck === false) {
          this.props.showAutoplayFailedAlert()
          this.updateControlPlayback(false)
          if (window.analytics) {
            window.analytics.track('Show autoplay failed alert', {
              mediaType: 'video',
              playbackItem: 'promptMedia',
            })
          }
        } else {
          this.props.hideAutoplayFailedAlert()
        }
      })
    }, 1000)
    if (this.state.playerReady) {
      const playerState = this.player.getPlayerState()
      if (playerState === 0) {
        this.seekTo(this.props.start)
        this.setState({ playerCurrentTime: this.props.start })
      }
      this.setState({ isPlaying: true })
      this.player.playVideo()
    }
  }

  pauseVideo() {
    if (this.state.playerReady) {
      this.setState({ isPlaying: false })
      this.player.pauseVideo()
    }
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.updatePlayerCurrentTime()
    }, 50)
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  updatePlayerCurrentTime() {
    if (!this.state.isBuffering && this.state.playerReady) {
      const currentTime = this.player.getCurrentTime()
      if (currentTime > this.state.playerCurrentTime) {
        if (currentTime < this.props.end) {
          this.setState({ playerCurrentTime: currentTime })
        } else {
          this.onClipFinished()
        }
      }
    }
  }

  onClipFinished() {
    this.player.pauseVideo()
    this.seekTo(this.props.start)
    this.setState({ isPlaying: false, playerCurrentTime: this.props.start })
  }

  onVideoEnd() {
    this.player.pauseVideo()
    this.seekTo(this.props.start)
    this.setState({ playerCurrentTime: this.props.start })
  }

  seekTo(seconds) {
    if (this.state.playerReady) {
      this.setState({ playerCurrentTime: seconds })
      this.player.seekTo(seconds, true)
    }
  }

  restartVideo() {
    if (this.state.playerReady) {
      this.setState({ isBuffering: true, playerCurrentTime: this.props.start })
      this.player.seekTo(this.props.start)
    }
  }

  toggleMuteVideo() {
    const { player } = this
    if (player.isMuted()) {
      player.unMute()
    } else {
      player.mute()
    }
    this.setState({ isMuted: !player.isMuted() })
  }

  render() {
    const { promptMediaZoomed, animationDisabled, isLastZoomedItem } = this.props
    const {
      itemStyle, videoStyle, itemEndZoomScale, startZoomControlBarContainer, endZoomControlBarContainer,
    } = this.state
    const showEndZoomControlBar = !this.state.isPlaying // maybe later fade out after paused for a while

    // Z-INDEX MANAGEMENT
    const moveItemBelowDarkBG = !isLastZoomedItem

    return (

      <React.Fragment>
        <Spring
          native
          from={{ transform: 'scale(1)' }}
          to={{ transform: (promptMediaZoomed && itemEndZoomScale) ? `scale(${itemEndZoomScale})` : 'scale(1)' }}
          immediate={animationDisabled}
        >
          {(props) => (
            <animated.div style={{ ...itemStyle, ...props }} className={`liveView-mediaItem liveView-mediaItem--promptVideoPlayer${isLastZoomedItem ? ' liveView-mediaItem--isLastZoomedItem ' : ''}${moveItemBelowDarkBG ? ' liveView-mediaItem--moveItemBelowDarkBG ' : ''}`}>
              <div
                className='liveView-mediaItem--promptVideoPlayer-videoContainer'
                style={{
                  position: 'absolute', top: '0px', left: '0px', ...videoStyle,
                }}
              >
                <div
                  style={{
                    position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%',
                  }}
                  id={`youtube-player-${this.playerId}`}
                />
              </div>

              {!promptMediaZoomed && (
              <div style={startZoomControlBarContainer} className={`liveView-mediaItem--promptVideoPlayer-startZoomControlBarContainer ${this.props.showMouseMoveControls ? 'liveView-mediaItem--promptVideoPlayer-startZoomControlBarContainer--showMouseMoveControls' : ''}`}>
                {this.state.playerReady && (
                <QuestionVideoControlBar
                  playVideo={() => { this.player.playVideo() }}
                  pauseVideo={() => { this.player.pauseVideo() }}
                  YTPlayer={this.player}
                  clipStartTime={this.props.start}
                  clipEndTime={this.props.end}
                  duration={this.props.end - this.props.start}
                  isPlaying={this.state.isPlaying}
                  isMuted={this.state.isMuted}
                  toggleMuteVideo={this.toggleMuteVideo}
                  playerCurrentTime={this.state.playerCurrentTime}
                  seekTo={this.seekTo}
                  zoomable
                  zoomMedia={this.props.zoomMedia}
                  mediaZoomed={promptMediaZoomed}
                  restartVideo={this.restartVideo}
                  hideMuteButton
                />
                )}
              </div>
              )}
            </animated.div>
          )}
        </Spring>

        {promptMediaZoomed && (
        <div style={endZoomControlBarContainer} className={`liveView-mediaItem--promptVideoPlayer-endZoomControlBarContainer ${showEndZoomControlBar ? ' liveView-mediaItem--promptVideoPlayer-endZoomControlBarContainer--show' : ' liveView-mediaItem--promptVideoPlayer-endZoomControlBarContainer--hide'}`}>
          {this.state.playerReady && (
          <QuestionVideoControlBar
            playVideo={() => { this.player.playVideo() }}
            pauseVideo={() => { this.player.pauseVideo() }}
            YTPlayer={this.player}
            clipStartTime={this.props.start}
            clipEndTime={this.props.end}
            duration={this.props.end - this.props.start}
            isPlaying={this.state.isPlaying}
            isMuted={this.state.isMuted}
            toggleMuteVideo={this.toggleMuteVideo}
            playerCurrentTime={this.state.playerCurrentTime}
            seekTo={this.seekTo}
            zoomable
            zoomMedia={this.props.zoomMedia}
            mediaZoomed={promptMediaZoomed}
            restartVideo={this.restartVideo}
            hideMuteButton
            unzoomMedia={this.props.unzoomMedia}
          />
          )}
        </div>
        )}
      </React.Fragment>
    )
  }
}

export default LiveViewPromptVideoPlayer

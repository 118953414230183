import findIndex from 'lodash/findIndex'

export default function (state=[], action) {
	switch (action.type) {
    
	case 'FETCH_ARCHIVED_SETS_SUCCESS':
		let newState = [...state] || []
		action.response.forEach(set => {
			const setId = set.id
			let indexOfSet = newState.findIndex(set => {
				return set.id === setId
			})
	
			if(indexOfSet === -1){
				indexOfSet=newState.length
			}
			newState= [
				...newState.slice(0,indexOfSet),
				set,
				...newState.slice(indexOfSet + 1)
			]	
		})
		return newState

 



	case 'DELETE_SET_SUCCESS':
	case 'SET_REMOVED_PUSHER':
		const setId = action.setId
		const indexOfSetToDelete = state.findIndex(set => {
			return set.id === setId
		})
		if(indexOfSetToDelete !==-1){	
			return [
				...state.slice(0,indexOfSetToDelete),
				...state.slice(indexOfSetToDelete + 1)
			]
		}else return state


	case 'UPDATE_SET_SUCCESS':
	case 'UPDATE_SET_FROM_PUSHER':

		const updatedSetId = action.response.id
		var indexOfSetToUpdate = findIndex(state,(set => {
			return set.id === updatedSetId
		}))
		if(indexOfSetToUpdate !== -1 ){
			if(action.response.archived===true){
				return [
					...state.slice(0,indexOfSetToUpdate),
					action.response,
					...state.slice(indexOfSetToUpdate + 1)
				]
			}else {
				return [
					...state.slice(0,indexOfSetToUpdate),
					...state.slice(indexOfSetToUpdate + 1)
				]
			}}
		else {
			if(action.response.archived===true){
				return [...state,action.response]
			}else return state
		}






	case 'LOGOUT':
		return []

	default:
	    return state
	}
}


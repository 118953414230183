
//
// TO DO: EXTRACT THESE OUT
//

// This is the size of the canvas in the Edit Image Modal UI
// This is currently hardcoded, it may become an input if decide to make the edit image modal responsive to the users screen dimensions
// const IMAGE_MODAL_CANVAS_WIDTH = 750
// const IMAGE_MODAL_CANVAS_HEIGHT = 529
const SERVED_IMAGE_MAX_DIMENSION = 900


// Throughout the app, we always serve images from cloudinary
// We never serve the image in it's original size/dimensions, always using c_limit, w_900, l_900
// This 900 number is not expected to change anytime soon
// const servedImageMaxDimension = 900


//
//
 
// This function takes
      // the intrinsic image dimensions (saved in question image object)
      // the image editor UI dimensions

    // and finds
      // the size of the image in the editor canvas
      // the offset required to place the image in the center of the editor canvas

export default function calculateEditorCanvasImageDimensions(rotateAngle,originalImageDimensions,canvasWidth,canvasHeight){	
  	// let IMAGE_MODAL_CANVAS_WIDTH = canvasWidth
    // let IMAGE_MODAL_CANVAS_HEIGHT = canvasHeight
    const originalImageHeight=originalImageDimensions.h
    const originalImageWidth=originalImageDimensions.w


    // Find aspect ratio of UI-Canvas and image
    // The image will be scaled to maximum-fit the available canvas area
    // There will either be space left and right of image, or above and below the image
    // So by finding the aspect ratios we can work out whether the max on width or height
    const imageModalCanvasAspect = canvasWidth  / canvasHeight

    // Intrinsic image dimensions are saved to image object
    // We don't save the served image dimensions (i.e. the scaled to 900, c_limit)
  	const intrinsicImageAspect = originalImageWidth / originalImageHeight


  	let imageAspect = intrinsicImageAspect
  	//let imageWidth = originalImageWidth
  	//let imageHeight = originalImageHeight

    // If 90 or 270 rotated, then image dimensions will be swapped
    // e.g. height will become width, width will become height, and aspect will be inverted
  	if(rotateAngle === 90 || rotateAngle === 270){
  		imageAspect = 1 / intrinsicImageAspect
  		//imageWidth = originalImageHeight
  		//imageHeight = originalImageWidth      
  	}


    //
    // Next we find the served image dimensions from cloudinary
    // We don't actually use the intrinsic/original image for display in editor UI, always max-side 900 size
    // We calculate the cloudinary-served size now
  	// let servedImageWidth
  	// let servedImageHeight

  	// if(imageWidth >= imageHeight){
  	// 	servedImageWidth = SERVED_IMAGE_MAX_DIMENSION
  	// 	servedImageHeight = SERVED_IMAGE_MAX_DIMENSION / imageAspect
  	// }
  	// if(imageWidth < imageHeight){  		
  	// 	servedImageHeight = SERVED_IMAGE_MAX_DIMENSION
  	// 	servedImageWidth = SERVED_IMAGE_MAX_DIMENSION * imageAspect
  	// }

    //
    //
    // Next we find the size of the image in editor UI
    // this is based on comparing the aspect ratio of the canvas and aspect ratio of the image


  	let editorCanvasImageWidth
  	let editorCanvasImageHeight
  	// correct scaled image size for image modal

    if(imageAspect >= imageModalCanvasAspect){
      //landscape
      editorCanvasImageWidth = canvasWidth
      editorCanvasImageHeight = canvasWidth  / imageAspect
    }

    if(imageAspect < imageModalCanvasAspect){
      //portrait
      editorCanvasImageHeight = canvasHeight
      editorCanvasImageWidth = canvasHeight * imageAspect
    }

    let imageContainerHeight = editorCanvasImageHeight
    let imageContainerWidth = editorCanvasImageWidth
    
    //not 100% sure why we do this again
    if(rotateAngle === 90 || rotateAngle === 270){
  		const calculatedWidth = editorCanvasImageHeight
  		const calculatedHeight = editorCanvasImageWidth
  		editorCanvasImageHeight = calculatedHeight
  		editorCanvasImageWidth = calculatedWidth

      imageContainerHeight = editorCanvasImageWidth
      imageContainerWidth = editorCanvasImageHeight
  	}


    //
    // Calculate offset required to center the image in canvas editor
    // the offset will be used directly to position the image, as well as in marker calculations

  	let editorCanvasImageXOffset
  	let editorCanvasImageYOffset

  	editorCanvasImageXOffset = (canvasWidth  - editorCanvasImageWidth) / 2
  	editorCanvasImageYOffset = (canvasHeight  - editorCanvasImageHeight) / 2

    if(rotateAngle === 90 || rotateAngle === 270){
      editorCanvasImageXOffset = (canvasWidth  - imageContainerWidth) / 2      
      editorCanvasImageYOffset = (canvasHeight - imageContainerHeight) / 2      
    }

    let imageContainerXOffset = (canvasWidth - imageContainerWidth) / 2 
    let imageContainerYOffset = (canvasHeight - imageContainerHeight) / 2


    // ORIGINAL IMAGE SCALE
	  // This is scale from original intrinsic size to editor-size
    // could be height and height, doesn't matter
	  const imageModalImageScale = editorCanvasImageWidth / originalImageWidth


    // SERVE SCALE
    // Try and avoid this until we understand it
	  const servedImageScale = SERVED_IMAGE_MAX_DIMENSION / Math.max(editorCanvasImageWidth,editorCanvasImageHeight)

	  return {
	  	servedImageScale:servedImageScale,
	  	imageScale:imageModalImageScale,
	  	editorCanvasImageWidth:editorCanvasImageWidth,
	  	editorCanvasImageHeight:editorCanvasImageHeight,
	  	canvasImageXOffset:editorCanvasImageXOffset,
	  	canvasImageYOffset:editorCanvasImageYOffset,
      imageContainerHeight: imageContainerHeight,
      imageContainerWidth: imageContainerWidth,
      imageContainerXOffset: imageContainerXOffset,
      imageContainerYOffset: imageContainerYOffset,
	  }


}




import React from 'react'
import moment from 'moment'
import includes from 'lodash/includes'
import RecentContextMenu from './contextMenus/RecentContextMenu'
import {openItemInEditor} from '../../utils/openItemInEditor'
import DnDCell from '../cells/DnDCell'
import {getSetForId} from '../../utils/getSetForId'
import {getQuestionForId} from '../../utils/getQuestionForId'
	 
class RecentListHeaderRow extends React.PureComponent{
	render(){
		return(
			<div  className='recentDateGroup-dateHeader'>
				{this.props.headerText}
			</div>
		)
	}
}

class RecentListEmptyRow extends React.PureComponent{
	render(){
		return null
	}
}

class RecentPage extends React.PureComponent{

	constructor(){
		super()
		this.renderRow=this.renderRow.bind(this)
		this.renderRecentDataArray=this.renderRecentDataArray.bind(this)
	}

	calculateListSectionTitle(date){ //title for date group headers (section referes to section of table not section as in class) 
		const today = moment().startOf('day')
		const week = moment().startOf('isoWeek')
		const year=moment().startOf('yeah')
		let dateTitle
		if (moment(date).isSame(today,'d')){
			dateTitle='Today'
		}else if (moment(date).isSame(week,'isoWeek')){
			dateTitle='This Week'
		}else if (moment(date).isSame(year,'year')){
			dateTitle=moment(date).format('MMMM')
		}else{
			dateTitle=moment(date).format('MMMM YYYY')
		}
		return dateTitle
	}

	//user firstItemInDateGroupIds to date insert headers in table
	renderRow(activityItem) { //this could be moved to a React component
			const isActive = (activityItem.item === this.props.activeItem.id)
			const contextMenuIsOpen=(activityItem.item===this.props.contextMenuActiveItem)
			const itemType = activityItem.itemType
			const isSectionHeader=includes(this.props.firstItemInDateGroupIds, activityItem.item)	
			const {filterTerm}=this.props
			let containsFilterTerm=false
			let itemDetail   
				if(itemType === 'set') {
						itemDetail = getSetForId(activityItem.item)
						if(itemDetail){
							containsFilterTerm=includes(itemDetail.name.toLowerCase(),filterTerm.toLowerCase())
						}		
				}
				if(itemType === 'question') {
						itemDetail = getQuestionForId(activityItem.item)
						 if(itemDetail){
							containsFilterTerm=includes(itemDetail.body.toLowerCase(),filterTerm.toLowerCase())
						}
				}
			if(isSectionHeader && filterTerm ===''){ //only show headers when data is not filtered
				const headerText=this.calculateListSectionTitle(activityItem.activityTime)		
				if(itemDetail && containsFilterTerm) {	
					return[ 
						<RecentListHeaderRow headerText={headerText} key={`${activityItem.item}_header`}/>,
							<DnDCell
								cellInRecent={true}
								key={itemDetail.id}
								isSet={itemType==='set'}						
								item={itemDetail}
								isActive={isActive}
								contextMenuIsOpen={contextMenuIsOpen}
								handleSingleClick={this.props.changeActiveItem}
								handleOpenItem={()=>{openItemInEditor(activityItem,true)}}
								activityItem={activityItem}	
								playItem={this.props.playItem}	
								navigateToItemDetail={()=>{this.props.navigateToItemDetail(itemDetail)}}			
								/> 
							]
						}else return [
							<RecentListHeaderRow headerText={headerText} key={`${activityItem.item}_header`}/>,
							<RecentListEmptyRow key={`${activityItem.item}_empty`} itemType={activityItem.itemType} item={activityItem.item}/>
						]
					}else if(itemDetail && containsFilterTerm){
						return(
							<DnDCell
								cellInRecent={true}
								key={itemDetail.id}
								isSet={itemType==='set'}						
								item={itemDetail}
								isActive={isActive}
								contextMenuIsOpen={contextMenuIsOpen}
								handleSingleClick={this.props.changeActiveItem}
								handleOpenItem={()=>{openItemInEditor(activityItem,true)}}
								activityItem={activityItem}	
								playItem={this.props.playItem}	
								navigateToItemDetail={()=>{this.props.navigateToItemDetail(itemDetail)}}			
								/> 
						)
					}else if(!itemDetail && filterTerm==='') {
						return (	<RecentListEmptyRow key={`${activityItem.item}_empty`} itemType={activityItem.itemType} item={activityItem.item}/>
					)
				}else return null
			}

	renderRecentDataArray(key, ref){
		const {dateGroupedRecentActivity}=this.props
		const dateTitle=Object.keys(dateGroupedRecentActivity)[key]
		const dateGroup=dateGroupedRecentActivity[dateTitle]
		if(dateGroup.length !==0){
			return(
				<div className='recentDateGroup'key={dateTitle}>
					<div className='recentDateGroup-dateHeader'>
						{dateTitle}
					</div>				
					{dateGroup.map((activityItem, key) => {	
						return(
							this.renderRow(activityItem,key)
						)})}
				</div>
			)
		}else{
			return null
		}
	}
 
	render(){
		const {sortedRecentActivityItems} = this.props
		return(
			<div className='recent-content'>	
				<div className='recent-table' tabIndex='-1' onKeyDown={this.props.keyPressNavigation}>		
					{sortedRecentActivityItems.map((activityItem)=>
						this.renderRow(activityItem)
					)}
				</div>
				<RecentContextMenu  key={this.props.activeItem.id} {...this.props} />
			</div>
		)
	}
}


export default RecentPage
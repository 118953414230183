import React from 'react'
import { withRouter,Link} from 'react-router-dom'
import Measure from 'react-measure'
import {formatDate} from '../../utils/formatDate'
import {formatDateConsumerView} from '../../utils/formatDateConsumerView'
import QuestionCountBadge from '../uiKit/QuestionCountBadge'
import Button from '../uiKit/Button'
import GridColumnToggle from '../uiKit/GridColumnToggle'
import AddToQueueDropdown from '../uiKit/AddToQueueDropdown'
import TopPageHeader from '../pageHeaders/TopPageHeader'
import StickyPageHeader from '../pageHeaders/StickyPageHeader'
import SlideGrid from '../SlideGrid' 
import Icon from '../misc/Icons'
import PollsForItemList from '../uiKit/PollsForItemList'
import ItemDetailHistoryList from './ItemDetailHistoryList'
import {openItemInEditor} from '../../utils/openItemInEditor'
import {getUserForId} from '../../utils/getUserForId'
import {getDisplayNameForUser} from '../../utils/getDisplayNameForUser'
import {getLinkForRepo} from '../../utils/getLinkForRepo'

 
 
class SetDetail extends React.Component{

	constructor() {
		super()
		this.handleToggleTableView=this.handleToggleTableView.bind(this)
		this.navigateToSetQuestion=this.navigateToSetQuestion.bind(this)
		this.state={
			centerPanelInnerWidth:0,
			gridColumns:3
		}
	}

	handleToggleTableView(isTable){
	  this.setState({isTableView:isTable})
	}

	navigateToSetQuestion(setQuestion){
		this.props.history.push(`/set/${this.props.set.id}/${setQuestion.questionId}`)
	}


	render(){
		const {set,parentRepo,addCopyToLibrary}=this.props
		const questions = set.questions
		
		const hasParentFolder = set.folder
		const parentFolderName = this.props.parentFolder.name

		const inRepo = set.repo
		let repoName = ''
		let inSharedRepo = false
		let isViewOnly = set.readOnly
		let lastEditedBy
		let isPublicConsumer = false

		if(parentRepo){
			repoName = parentRepo.name
			inSharedRepo=parentRepo.shared
			if(inSharedRepo){
				const lastEditedUser = getUserForId(set.lastEditedBy,set.repo)
				lastEditedBy=getDisplayNameForUser(lastEditedUser)
			}
			if(parentRepo.role === 'viewer' ||parentRepo.role === 'consumer'){
				isViewOnly = true
			}
			if(parentRepo.role === 'consumer'){
				isPublicConsumer = true
			}
		}

	


		return[
			<div className='page-leftPanel' key='leftPanel' />,
			<div className='page-centerPanel setDetail' key='centerPanel'>
				<Measure onMeasure={(dimensions) => {this.setState({centerPanelInnerWidth:dimensions.width})}}>
					<div className='page-centerPanel-innerMeasureSpacer'/>
				</Measure>

				<TopPageHeader
					location='setDetail' 
					height={218}						
					rightBadge={<QuestionCountBadge questionCount={set.questions.length} />}
					header={
						<React.Fragment>
							{set.name}
							{inSharedRepo && <Icon name='shared'/>}
						</React.Fragment>
						}

					headerInfoRow={
						<React.Fragment>
						

							{hasParentFolder && !inRepo &&
								<Link 
									className='pageHeaderTop-headerInfoRow-folderLink'
									to={`/library/${set.folder}`}
								>
									<Icon name='folder' />
									{parentFolderName}				
								</Link>
							}

							{inRepo &&
								<Link 
									className='pageHeaderTop-headerInfoRow-repoLink'
									to={getLinkForRepo(parentRepo)}
								>									
									{repoName}
								</Link>
							}

							{inRepo && hasParentFolder &&
								<React.Fragment>
									<Icon name='chevron-right'/>

									<Link 
										className='pageHeaderTop-headerInfoRow-repoFolderLink'
										to={`${getLinkForRepo(parentRepo)}/${set.folder}`}
									>									
										{parentFolderName}
									</Link>

								</React.Fragment>
							}
							
						
							{!isPublicConsumer &&
								<div className='pageHeaderTop-headerInfoRow-timestamp'>						
									{lastEditedBy?`${lastEditedBy} edited ` :'Edited'} {formatDate(set.clientModified)}								 
								</div> 
							}

							{isPublicConsumer &&
								<div className='pageHeaderTop-headerInfoRow-timestamp'>						
									Edited {formatDateConsumerView(set.clientModified)}								 
								</div> 
							}
							

						</React.Fragment>
					}
					actionRow={
						<React.Fragment>
							<Button iconName='play' label='Play Now' onClickFunction={this.props.playSet} />
				      <Button iconName={!isViewOnly ? 'compose' : null} label={!isViewOnly ? 'Edit Set' : 'View Set'} onClickFunction={()=>{openItemInEditor(set.id,true)}}  />
				      {!isViewOnly && <Button iconName='open-left-arrow' label='Move to Pack' name='moverepo' onClickFunction={()=>{this.props.showRepoSelectionModal(set,null,true)}}/> }
				      {isViewOnly && <Button label='Add Copy to Library' onClickFunction={addCopyToLibrary}/> }
				      <AddToQueueDropdown item={set} dropdownButton={<Button iconName='plus' label='Add to Queue'/>} />
				      <div className='pageHeaderTop-actionRow-spacer' />
				      <PollsForItemList item={set}/>				      
				      <GridColumnToggle
				      	activeGridColumn={this.state.gridColumns}
				      	setNumberColumns={(numberOfColumns) => {this.setState({gridColumns: numberOfColumns})}}
				       />
				      
						</React.Fragment>
					}
					borderBottom
					backButton					
				/>					
						
				<StickyPageHeader 
					location='setDetail' 
					leftBadge={<QuestionCountBadge questionCount={set.questions.length} />}
					header={set.name}
					actionGroup={
				    <React.Fragment>
							<Button iconName='play' label='Play Now' onClickFunction={this.props.playSet} />
			     	 	<Button iconName={!isViewOnly ? 'compose' : null} label={!isViewOnly ? 'Edit Set' : 'View Set'} onClickFunction={()=>{openItemInEditor(set.id,true)}}  />
						</React.Fragment>
					}
				/>
				
				
				<div className='setDetail-slideGrid'>
					<SlideGrid 
						questions={questions}
						gridColumns={this.state.gridColumns}
						availableWidth={this.state.centerPanelInnerWidth}
						setID={set.id}
						showExpandedQuestionSlideModal={this.props.showExpandedQuestionSlideModal}
					/>
				</div>
			</div>,

			<div className='page-rightPanel' key='rightPanel'>
				{this.props.historyPolls.length > 0 && 
					<div className='sidePanelContainer'>
						<div className='sidePanel'>
							<ItemDetailHistoryList historyPolls={this.props.historyPolls} isSet sections={this.props.sections}/>
						</div>
					</div>
				}
			</div>,
		]
	}
}


export default withRouter(SetDetail)


import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {formatDate} from '../../utils/formatDate'
import StaticQuestionSlide from '../slides/StaticQuestionSlide'
import {processQuestionResponses} from '../../utils/processQuestionResponses'
import SectionMarker from '../uiKit/SectionMarker'
import ScorePill from '../uiKit/ScorePill'
import Icon from '../misc/Icons'
import SetScoresArray from '../uiKit/SetScoresArray'
import {buildQuestionsAndPollsList} from '../../utils/buildQuestionsAndPollsList'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'

class HistoryCard extends React.PureComponent{

	render(){
		const {poll,section,isSet,isSectionHistory} = this.props		
		const time = formatDate(poll.minCaptured)
		const setScore=poll.correctPercent
		let snapshot=null
		let title=''
		let pollDetailPath=''

		let processedQuestionResponses
		let totalResponses
		let questionsAndPollsList
		let previewQuestionHasPoll //for sets the 1st ordered item may not have a poll

		if(isSet){
			questionsAndPollsList=buildQuestionsAndPollsList(poll)
			const previewQuestionAndPoll=questionsAndPollsList[0]
		 	if(previewQuestionAndPoll.poll){
		 		snapshot=previewQuestionAndPoll.poll.snapshot
		 		previewQuestionHasPoll=true
		 	}else{
		 		snapshot=previewQuestionAndPoll.questionSnapshot
		 		previewQuestionHasPoll=false
		 	}
		 	title=poll.snapshot.name
			pollDetailPath=`/setreport/${poll.id}`
			if(previewQuestionHasPoll===true){
				processedQuestionResponses = processQuestionResponses(previewQuestionAndPoll.poll,section)
				totalResponses=previewQuestionAndPoll.poll.unassignedResponseCount + previewQuestionAndPoll.poll.assignedResponseCount
			}
		}
		else{
			previewQuestionHasPoll=true
			snapshot=poll.snapshot
			title=getQuestionBodyLabel(poll.snapshot)
			pollDetailPath=`/questionreport/${poll.id}`
			processedQuestionResponses = processQuestionResponses(poll,section)
			totalResponses= poll.unassignedResponseCount + poll.assignedResponseCount
		}

		return(				
			<div className='historyCardContainer'>							
				<Link
					className={'historyCard' + (isSectionHistory ? ' historyCard--sectionHistory' : '')}
					to={pollDetailPath}
				>							
					<div className='historyCard-slidePreview'>						
						<ScorePill score={setScore} isSet={isSet}/>
						{previewQuestionHasPoll &&
			           	<div className='historyCard-slidePreview-slide'>
			           		<StaticQuestionSlide 
			           			question={snapshot}
			           			displayWidth={188} 
			           			processedResponses={processedQuestionResponses}
			           			totalResponses={totalResponses}
											reportView
			           		/>
			           	</div> 
			           }
			           {!previewQuestionHasPoll &&
			           	<div className='historyCard-slidePreview-slide'>
			           		<StaticQuestionSlide 
			           			question={snapshot}
			           			displayWidth={188} 		
			           		/>
			           	</div> 
			           }          
					</div>									
					<div className='historyCard-title'>
						{title}
					</div>										
					<div className='historyCard-subtitle'>
						<SectionMarker section={section}/>
						<span className='historyCard-subtitle-time'>{time}</span>							
					</div>
					{isSet &&
						<SetScoresArray questionsAndPollsList={questionsAndPollsList} polls={poll.polls} />
					}
					<div className='historyCard-rightArrow'>
						<Icon name='chevron-right' />
					</div>			
				</Link>							
			</div>
		)
	}
}

HistoryCard.propTypes = {
	poll: PropTypes.object.isRequired,
	section: PropTypes.object.isRequired,
	isSet:PropTypes.bool.isRequired,
}

export default withRouter(HistoryCard)





export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_RECENT_SYMBOLS_SUCCESS':
		return action.response

	case 'UPSERT_RECENT_SYMBOLS_SUCCESS':
		const upsertedSymbol=action.response
		let indexOfSymbol = state.findIndex(symbol => {
			return symbol.id === upsertedSymbol.id
		})
		if(indexOfSymbol === -1){
			indexOfSymbol=state.length
		}
		return [
			...state.slice(0,indexOfSymbol),
			upsertedSymbol,
			...state.slice(indexOfSymbol + 1)
		]

	case 'LOGOUT':
		return null
	
	default:
		return state
	}
}
import store from '../../store'
import find from 'lodash/find'
import {getQuestionPollForControl,fetchSetPollForControl} from '../../actions/polls'



export function getPollForControl(control) {	//returns either independent poll or setPollPoll
	const state = store.getState()
	let poll=null
	if(control && control.currentPoll){
		poll= find(state.historyQuestionPolls, {'id':control.currentPoll}) || find(state.queueQuestionPolls, {'id':control.currentPoll})
		if(!poll){ //if poll is not in state then fetch it
			return store.dispatch(getQuestionPollForControl(control.currentPoll))
		}	
	}
	else if(control && control.setPoll && control.setPollPoll){
		const setPoll= find(state.historySetPolls, {'id':control.setPoll}) || find(state.queueSetPolls, {'id':control.setPoll})
		if(setPoll){
			poll=find(setPoll.polls, {'id':control.setPollPoll})
		}
	}
	return poll
}

export function getSetPollForControl(control) {
	const state = store.getState()
	let poll=null
	 if(control && control.setPoll){
		poll= find(state.historySetPolls, {'id':control.setPoll}) || find(state.queueSetPolls, {'id':control.setPoll})	
		if(!poll || !poll.snapshot){ //if poll is not in state then fetch it
			return store.dispatch(fetchSetPollForControl(control.setPoll))
		}
	}
	return poll
}

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import { GoogleLogin } from '@react-oauth/google'
import { signup, login, externalLogin } from '../../actions/session'
import SignupForm from '../../components/forms/SignupForm'
import CartoonDevice from '../../components/misc/CartoonDevice'
import Icon from '../../components/misc/Icons'
import getErrorMessageForRequest from '../../utils/getErrorMessageForRequest'
import SignInWithAppleButton from '../../components/misc/SignInWithAppleButton'
import { showVerifyPasswordToSigninModal } from '../../actions/modals'

class SignupContainer extends Component {
  constructor() {
    super()
    this.handleSignup = this.handleSignup.bind(this)
    this.goToLogin = this.goToLogin.bind(this)
    this.handleLoginKeyDown = this.handleLoginKeyDown.bind(this)
    this.onPreSubmit = this.onPreSubmit.bind(this)
    this.googleLogin = this.googleLogin.bind(this)
    this.appleCallback = this.appleCallback.bind(this)
    this.state = {
      errorMessage: null,
      submitLoading: false,
      recaptchaFailed: false,
    }
  }

  async handleExternalLogin(requestData) {
    const {
      externalLogin,
      showVerifyPasswordToSigninModal,
    } = this.props

    try {
      await externalLogin(requestData)
    } catch (error) {
      this.setState({ submitLoading: false })
      const { status } = error.response
      if (status === 412) {
        // get email from the header
        const email = error.response.headers.get('x-matched-email')
        showVerifyPasswordToSigninModal({ requestData, email })
      } else {
        const errorMessage = getErrorMessageForRequest({
          requestName: 'externalLogin',
          response: error.response,
        })
        this.setState({ errorMessage })
      }
    }
  }

  appleCallback(data) {
    if (data.authorization) {
      this.setState({ submitLoading: true })
      const token = data.authorization.id_token
      const requestData = {
        type: 'apple',
        idToken: token,
        firstName: '-',
        lastName: '-',
      }
      if (data.user) {
        requestData.firstName = data.user.name.firstName
        requestData.lastName = data.user.name.lastName
      }
      this.handleExternalLogin(requestData)
    }
  }

  onPreSubmit() {
    this.setState({ submitLoading: true })
  }

  handleSignup(data, recaptchaResponseForSessionCreation) {
    // handle recaptcha assessment failure
    if (!data.recaptchaResponse || !recaptchaResponseForSessionCreation) {
      this.setState({
        errorMessage: 'Captcha verification failed. Please try again.',
        submitLoading: false,
      })
      return
    }
    const { signup, login } = this.props
    this.setState({ errorMessage: null })
    signup(this.appendData(data))
      .then(() => {
        // Once signed up log user in
        const loginData = {
          email: data.email,
          password: data.password,
          recaptchaResponse: recaptchaResponseForSessionCreation,
        }
        return login(loginData)
      })
      .catch((error) => {
        this.setState({ submitLoading: false })
        const errorMessage = getErrorMessageForRequest({
          requestName: 'signup',
          response: error.response,
          data: { email: data.email },
        })
        this.setState({ errorMessage })
      })
  }

  googleLogin(credentialResponse) {
    this.setState({ submitLoading: true })
    const requestData = {
      type: 'google',
      idToken: credentialResponse.credential,
    }
    this.handleExternalLogin(requestData)
  }

  goToLogin() {
    this.props.history.push('login')
  }

  handleLoginKeyDown(e) {
    if (e.keyCode === 13) this.goToLogin()
  }

  appendData = (data) => {
    const nowDate = new Date()
    return {
      ...data,
      clientModified: nowDate,
      userCreated: nowDate,
      title: '',
    }
  }

  render() {
    const { errorMessage, recaptchaFailed } = this.state
    return (
      <DocumentTitle title='Create Account - Plickers'>
        <div className='signup marketing notranslate'>
          <div className='signup-centerBlock'>
            <div className='signup-centerBlock--top'>
              <div className='signup-centerBlock-logoSpacer-top' />
              <div className='signup-centerBlock-logo'>
                <Icon name='logo' />
              </div>
              <div className='signup-centerBlock-logoSpacer-bottom' />
            </div>
            <div className='signup-centerBlock--middle'>
              <div className='signup-centerBlock-title'>
                Sign up for free
              </div>
              <div className='signup-centerBlock-spacer' />
              <div className='signup-thirdPartyBtnContainer'>
                <button className='signup-signInWithGoogleBtn'>
                  <GoogleLogin
                    width='300px'
                    logo_alignment='center'
                    text='signup_with'
                    onSuccess={this.googleLogin}
                  />
                </button>
                <SignInWithAppleButton
                  callback={this.appleCallback}
                  redirectPath='/signup'
                  text='signup_with'
                />
              </div>

              <div className='signup-centerBlock-divider'>
                <div className='signup-centerBlock-divider-line' />
                <div className='signup-centerBlock-divider-label'>
                  or use email and password
                </div>
                <div className='signup-centerBlock-divider-line' />

              </div>

              <div className={`signup-centerBlock-form ${recaptchaFailed && 'signup-centerBlock-form--disabled'}`}>
                <SignupForm
                  onPreSubmit={this.onPreSubmit}
                  onSubmit={this.handleSignup}
                  submitLoading={this.state.submitLoading}
                  onRecaptchaError={() => this.setState({ recaptchaFailed: true })}
                />
              </div>

            </div>

            <div className='signup-centerBlock--bottom'>
              {recaptchaFailed && (
              <div className='signup-centerBlock-error signup-centerBlock-error--recaptcha'>
                <div>
                  Email and password signup requires reCAPTCHA but it failed to load.
                </div>
                <div>
                  Ensure google.com/recaptcha/api.js is not blocked by your browser or network.
                </div>
              </div>
              )}
              {errorMessage && (
              <div className='signup-centerBlock-error'>
                {errorMessage}
              </div>
              )}

              <div
                className='signup-centerBlock-goToLoginBtn'
                role='button'
                tabIndex={0}
                onClick={this.goToLogin}
                onKeyDown={this.handleLoginKeyDown}
              >
                If you already have an account, click here to sign in
              </div>
            </div>

          </div>

          <div className='signup-deviceArray'>
            <div className='signup-deviceArray-topLeft'>
              <CartoonDevice device='laptop' screen='laptopSetEditor' />
            </div>
            <div className='signup-deviceArray-topRight'>
              <CartoonDevice device='iPhone' screen='iPhoneNowPlayingReview' />
            </div>
            <div className='signup-deviceArray-bottomRight'>
              <CartoonDevice device='laptop' screen='laptopSetEditor' />
            </div>
            <div className='signup-deviceArray-bottomLeft'>
              <CartoonDevice device='iPhone' screen='iPhoneNowPlayingReview' />
            </div>
          </div>

        </div>
      </DocumentTitle>
    )
  }
}

export default withRouter(connect(
  (/* state */) => ({ }),
  {
    signup, login, externalLogin, showVerifyPasswordToSigninModal,
  },
)(SignupContainer))

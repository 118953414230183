//Grouping			Card Timestamp	Example Today is Thursday	
//Sub 60 seconds	Just now		
//Sub 1 hour		35 min ago	Week Starts on Monday	
//Today				Today 4:54PM		
//This Week			Tuesday 2:44PM		
//July				Mon 10 July		
//May	 			Wed 15 May		
//December 2016	   	Thur 16 Dec 2016		



import moment from 'moment'


export function formatDate(timestamp) {

	const nowDate =moment()
	const today = moment(nowDate).startOf('day')
	const week = moment(nowDate).startOf('isoWeek')
	const year = moment(nowDate).startOf('isoYear')
 
	let formatedTimestamp

	if (moment(timestamp).isSame(today, 'd')) {
		var seconds = Math.floor((nowDate - moment(timestamp)) / 1000)
		var minutes = Math.floor(seconds / 60)
		if (minutes < 60) { //is in the last hour
			if (minutes < 1) { //is in the last min
				formatedTimestamp = 'Just Now'
			} else {
				formatedTimestamp = `${minutes} min ago`
			}
		} else {
			formatedTimestamp = `Today ${moment(timestamp).format('h:mm A')}`
		}
	}


	else
	if (moment(timestamp).isSame(week, 'w')) {
		formatedTimestamp = moment(timestamp).format('dddd h:mm A')
	}
	else
	if (moment(timestamp).isSame(year, 'y')) {
		formatedTimestamp=moment(timestamp).format('ddd DD MMM')
	}

	else {
		formatedTimestamp=moment(timestamp).format('DD MMM YYYY')
	}

	return formatedTimestamp

}
import React from 'react'
import PublishingFlowSubjectSelectControl from './PublishingFlowSubjectSelectControl'
import subjects from '../../../staticData/subjects.js'


class PublishingFlowSubjectPage extends React.Component{


	constructor(props){
		super(props)
		this.selectSubject=this.selectSubject.bind(this)
		this.onChangeOtherInput=this.onChangeOtherInput.bind(this)

	}

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Publishing Flow',{
				stage:'subject',
			})
		}
	}



	onChangeOtherInput(e){
		this.props.setSubjectOtherString(e.target.value)

	}


	selectSubject(subject){
		const subjectId=subjects[subject].subjectId
		const parentId=subjects[subject].parentId
		const {selectedSubjectId}=this.props
		let newSelectedSubjectId
		let newSelectedSubjectParentId

		if(subjectId===selectedSubjectId){
			newSelectedSubjectId=null
			if(parentId){
				newSelectedSubjectId=parentId
			}		
		}else{
			newSelectedSubjectId=subjectId	
			newSelectedSubjectParentId=parentId
		}

		this.props.setSelectedSubjectId(newSelectedSubjectId)
		this.props.setSelectedSubjectParentId(newSelectedSubjectParentId)
		this.props.setSubjectOtherString('')
	}



	render(){	
	
		const {selectedSubjectId,selectedSubjectParentId,subjectOtherString}=this.props 



		return(

			<div className='publishingFlowModalPage publishingFlowModalPage--subject'>
				
				<div className='publishingFlowModalPage-header'>
					Add Subject
				</div>

				<div className='publishingFlowModalPage--subject-subjectListContainer'>
					<div className='publishingFlowModalPage--subject-subjectListColumn'>						

						<PublishingFlowSubjectSelectControl
							subject='computerScience' 
							selectedSubjectId={selectedSubjectId}
							selectedSubjectParentId={selectedSubjectParentId}
							selectSubject={this.selectSubject}
						/>

						<PublishingFlowSubjectSelectControl
							subject='computerSkills' 
							selectedSubjectId={selectedSubjectId}
							selectedSubjectParentId={selectedSubjectParentId}
							selectSubject={this.selectSubject}
						/>

						<PublishingFlowSubjectSelectControl
							subject='creativeArts' 
							selectedSubjectId={selectedSubjectId}
							selectedSubjectParentId={selectedSubjectParentId}
							selectSubject={this.selectSubject}
						/>

						{(selectedSubjectId === subjects.creativeArts.subjectId || selectedSubjectParentId===subjects.creativeArts.subjectId)&&
							<div className='publishingFlowModalPage--subjectListSubSubjectGroup'>									
								<PublishingFlowSubjectSelectControl
									subject='art' 
									selectedSubjectId={selectedSubjectId}
									selectedSubjectParentId={selectedSubjectParentId}
									selectSubject={this.selectSubject}
								/>

								<PublishingFlowSubjectSelectControl
									subject='drama' 
									selectedSubjectId={selectedSubjectId}
									selectedSubjectParentId={selectedSubjectParentId}
									selectSubject={this.selectSubject}
								/>
								<PublishingFlowSubjectSelectControl
									subject='music' 
									selectedSubjectId={selectedSubjectId}
									selectedSubjectParentId={selectedSubjectParentId}
									selectSubject={this.selectSubject}
								/>

								<PublishingFlowSubjectSelectControl
									subject='creativeArtsOther' 
									selectedSubjectId={selectedSubjectId}
									selectedSubjectParentId={selectedSubjectParentId}
									selectSubject={this.selectSubject}
									hasOptionalOtherInputBox={true}
									optionalOtherInputBoxPlaceholder="Creative Arts Subject" 
									subjectOtherString={subjectOtherString} 
									onChangeOtherInput={this.onChangeOtherInput}
								/>

							</div>
						}

						<PublishingFlowSubjectSelectControl
							subject='foreignLanguages' 
							selectedSubjectId={selectedSubjectId}
							selectedSubjectParentId={selectedSubjectParentId}
							selectSubject={this.selectSubject}
						/>
						
						{(selectedSubjectId === subjects.foreignLanguages.subjectId || selectedSubjectParentId===subjects.foreignLanguages.subjectId)&&
							<div className='publishingFlowModalPage--subjectListSubSubjectGroup publishingFlowModalPage--subjectListSubSubjectGroup--twoColumn'>
								<div className='publishingFlowModalPage--subjectListSubSubjectGroup-column'>
									<PublishingFlowSubjectSelectControl
										subject='arabic' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>
									<PublishingFlowSubjectSelectControl
										subject='chinese' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>

									<PublishingFlowSubjectSelectControl
										subject='english' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>

									<PublishingFlowSubjectSelectControl
										subject='french' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>

									<PublishingFlowSubjectSelectControl
										subject='german' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>

									<PublishingFlowSubjectSelectControl
										subject='italian' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>

								</div>
								
								<div className='publishingFlowModalPage--subjectListSubSubjectGroup-column publishingFlowModalPage--subjectListSubSubjectGroup-column--wide'>
									<PublishingFlowSubjectSelectControl
										subject='japanese' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>
									<PublishingFlowSubjectSelectControl
										subject='latin' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>	
									<PublishingFlowSubjectSelectControl
										subject='signLanguage' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>
									<PublishingFlowSubjectSelectControl
										subject='spanish' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>
									<PublishingFlowSubjectSelectControl
										subject='foreignLanguagesOther' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
										hasOptionalOtherInputBox={true}
										optionalOtherInputBoxPlaceholder="Language" 
										subjectOtherString={subjectOtherString} 
										onChangeOtherInput={this.onChangeOtherInput}
									/>
									
								</div>			
							</div>
						}
						<PublishingFlowSubjectSelectControl
							subject='languageArts' 
							selectedSubjectId={selectedSubjectId}
							selectedSubjectParentId={selectedSubjectParentId}
							selectSubject={this.selectSubject}
						/>

						<PublishingFlowSubjectSelectControl
							subject='math' 
							selectedSubjectId={selectedSubjectId}
							selectedSubjectParentId={selectedSubjectParentId}
							selectSubject={this.selectSubject}
						/>

						
						

						<div className='publishingFlowModalPage--subject-subjectListColumn-allSubjectSpacer'/>						

							<PublishingFlowSubjectSelectControl
								subject='allSubjects' 
								selectedSubjectId={selectedSubjectId}
								selectedSubjectParentId={selectedSubjectParentId}
								selectSubject={this.selectSubject}
							/>

						
												
					</div>
					<div className='publishingFlowModalPage--subject-subjectListColumn'>
						
						<PublishingFlowSubjectSelectControl
							subject='physicalEducation' 
							selectedSubjectId={selectedSubjectId}
							selectedSubjectParentId={selectedSubjectParentId}
							selectSubject={this.selectSubject}
						/>

						<PublishingFlowSubjectSelectControl
							subject='science' 
							selectedSubjectId={selectedSubjectId}
							selectedSubjectParentId={selectedSubjectParentId}
							selectSubject={this.selectSubject}
						/>
		
						{(selectedSubjectId === subjects.science.subjectId  || selectedSubjectParentId===subjects.science.subjectId )&&
							
							<div className='publishingFlowModalPage--subjectListSubSubjectGroup'>
								<PublishingFlowSubjectSelectControl
									subject='biology' 
									selectedSubjectId={selectedSubjectId}
									selectedSubjectParentId={selectedSubjectParentId}
									selectSubject={this.selectSubject}
								/>	
								<PublishingFlowSubjectSelectControl
									subject='chemistry' 
									selectedSubjectId={selectedSubjectId}
									selectedSubjectParentId={selectedSubjectParentId}
									selectSubject={this.selectSubject}
								/>	
								<PublishingFlowSubjectSelectControl
									subject='earthScience' 
									selectedSubjectId={selectedSubjectId}
									selectedSubjectParentId={selectedSubjectParentId}
									selectSubject={this.selectSubject}
								/>	
								<PublishingFlowSubjectSelectControl
									subject='physics' 
									selectedSubjectId={selectedSubjectId}
									selectedSubjectParentId={selectedSubjectParentId}
									selectSubject={this.selectSubject}
								/>	


								<PublishingFlowSubjectSelectControl
									subject='scienceOther' 
									selectedSubjectId={selectedSubjectId}
									selectedSubjectParentId={selectedSubjectParentId}
									selectSubject={this.selectSubject}
									hasOptionalOtherInputBox={true}
									optionalOtherInputBoxPlaceholder="Science Subject" 
									subjectOtherString={subjectOtherString} 
									onChangeOtherInput={this.onChangeOtherInput}
								/>
							</div>
						}
						
						<PublishingFlowSubjectSelectControl
							subject='socialSciences' 
							selectedSubjectId={selectedSubjectId}
							selectedSubjectParentId={selectedSubjectParentId}
							selectSubject={this.selectSubject}
						/>	

						{(selectedSubjectId === subjects.socialSciences.subjectId || selectedSubjectParentId===subjects.socialSciences.subjectId )&&
								<div className='publishingFlowModalPage--subjectListSubSubjectGroup'>
									<PublishingFlowSubjectSelectControl
										subject='economics' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>	

									<PublishingFlowSubjectSelectControl
										subject='geography' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>	

									<PublishingFlowSubjectSelectControl
										subject='politics' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>

									<PublishingFlowSubjectSelectControl
										subject='history' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>	

									<PublishingFlowSubjectSelectControl
										subject='psychology' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
									/>	

									<PublishingFlowSubjectSelectControl
										subject='socialSciencesOther' 
										selectedSubjectId={selectedSubjectId}
										selectedSubjectParentId={selectedSubjectParentId}
										selectSubject={this.selectSubject}
										hasOptionalOtherInputBox={true}
										optionalOtherInputBoxPlaceholder="Social Science Subject" 
										subjectOtherString={subjectOtherString} 
										onChangeOtherInput={this.onChangeOtherInput}
									/>
								</div>
						}						
													
							<PublishingFlowSubjectSelectControl
								subject='other' 
								selectedSubjectId={selectedSubjectId}
								selectedSubjectParentId={selectedSubjectParentId}
								selectSubject={this.selectSubject}
								hasOptionalOtherInputBox={true}
								optionalOtherInputBoxPlaceholder="Subject" 
								subjectOtherString={subjectOtherString} 
								onChangeOtherInput={this.onChangeOtherInput}
							/>
					
					</div>
				</div>
				

			</div>
			
		)	
	}
				
}




export default PublishingFlowSubjectPage



import React from 'react'
import PropTypes from 'prop-types'
import {ContextMenuTrigger } from 'react-contextmenu'
import Icon from '../misc/Icons'
import Button from '../uiKit/Button'

class FolderCell extends React.Component{
	constructor(){
		super()
		this.handleMouseDown=this.handleMouseDown.bind(this)
		this.handleMouseUp=this.handleMouseUp.bind(this)
		this.state={
			 mousedDownFired:false
		}
	}

	collect(props) {
    	return { item:props.item,itemId:props.item.id}
	}

	handleMouseDown(item,e){
		if(!this.props.isActive && !this.props.isMultiSelectItem){
		
			this.props.handleSingleClick(item,e)
			this.setState({mousedDownFired:true})
		}else{
		
			e.stopPropagation()
		}	
	}

	handleMouseUp(item,e){
		if(!this.state.mousedDownFired && e.button!==2){
			
			this.props.handleSingleClick(item,e)		
		}else{
			e.stopPropagation()
		}	
		this.setState({mousedDownFired:false})
	}

	render(){
		const {folder,isActive,contextMenuIsOpen,isOver,canDrop,folderIsFocused,navigateToFolder,isMultiSelectItem,isDragging}=this.props
		const readOnly=folder.readOnly
		return(
			<ContextMenuTrigger 
				id={isMultiSelectItem? 'MULTISELECT':'FOLDER' }
				itemId={folder.id}
				item={folder}
				folderName={folder.name}
				itemType='folder'
				collect={this.collect}
				disable={isDragging}
			>	
				<div
					className={'cellRow ' + 
						(isActive ? ' is--active' : '') +
						(isMultiSelectItem ? ' is--multiSelect' : '') + 
						(contextMenuIsOpen ? ' has--menu':'') +
						(readOnly ? ' is--readOnly':'') + 
						(isDragging ? ' is--DnDdragging' : '') +
						(isOver ? ' is--DnDover ':'') +
						(isOver && !canDrop ? ' is--DnDcantDrop ':'')
					}
					// onClick={(e) =>{handleSingleClick(folder,e)}}
					onMouseDown={(e) =>{this.handleMouseDown(folder,e)}}	
					onMouseUp={(e) =>{this.handleMouseUp(folder,e)}}	
					id={folder.id}
					onDoubleClick={() =>{
						if(!folderIsFocused){
							navigateToFolder(folder.id,folder.name)}}}
				>	

					<div className="cellRow-content">

						<div className="cellRow-left">
							<Icon name={!readOnly ? 'folder' : 'folder-isbe'}/>
						</div>

						<div className="cellRow-name">
							{folder.name}
						</div>

						<div className="cellRow-date" />

						<div className="cellRow-right" onClick={() =>{navigateToFolder(folder.id,folder.name)}} >
							<Button  name="more" size="small" iconName="chevron-right" />							
						</div>

					</div>

				</div>
			</ContextMenuTrigger>

		)
	}
}



FolderCell.propTypes = {
	folder: PropTypes.object.isRequired,
	isActive: PropTypes.bool.isRequired,
	handleSingleClick: PropTypes.func.isRequired,
	navigateToFolder: PropTypes.func.isRequired,

}

export default FolderCell



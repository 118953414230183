import React from 'react'
import ModalWrapper from '../ModalWrapper'
import DeleteAccountModalContainer from '../../../containers/modals/account/DeleteAccountModalContainer'

const DeleteAccountModal = (props) => {
  const { hideModal } = props
  return (
    <ModalWrapper
      title='Delete Account'
      iconName=''
      modalStyle='dark'
      hideModal={hideModal}
    >
      <DeleteAccountModalContainer />
    </ModalWrapper>
  )
}

export default DeleteAccountModal

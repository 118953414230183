import React from 'react'
import EditorIcon from '../../misc/EditorIcons'
import {undo, redo} from "prosemirror-history"

class EditorTopBarUndoRedoButtons extends React.Component{  

	render(){
		const {editorView}=this.props
		let undoIsAllowed = false
		let redoIsAllowed = false
		if(editorView){
			undoIsAllowed= undo(editorView.state, null, editorView)
			redoIsAllowed=redo(editorView.state, null, editorView)
		}
		return (	  		 
			<div id="editorMenuTopBar" className='editor-topBar-btnGroup editor-topBar-btnGroup--undoRedo'>
				<button onClick={()=>{undo(editorView.state, editorView.dispatch, editorView)}} className={'editor-topBar-btn editor-topBar-btn--undo '+ (undoIsAllowed?'':'editor-topBar-btn--disabled')} disabled={!undoIsAllowed}> 
				<EditorIcon name='topBarUndo' />              
					<div className='editor-topBar-tooltip'>            
						Undo            
					</div>
				</button>
				<button onClick={()=>{redo(editorView.state, editorView.dispatch, editorView)}} className={'editor-topBar-btn editor-topBar-btn--redo '+ (redoIsAllowed?'':'editor-topBar-btn--disabled')} disabled={!redoIsAllowed}>
					<EditorIcon name='topBarRedo' />              
						<div className='editor-topBar-tooltip'>            
							Redo                      
						</div>
				</button>         	          
			</div>
		)
	}
}

export default EditorTopBarUndoRedoButtons
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import {adminPayActivatedAwards,adminCreateAward,adminActivateAward,adminFetchCreators,adminAddCreatorFebPayoutAmount,adminUpdateCreatorMetadata,adminFetchAwards} from '../../actions/admin'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import sortBy from 'lodash/sortBy'
import CreatorsListPage from '../../components/explorer/creatorsList/CreatorsListPage'



class CreatorsListContainer extends Component {
	
	constructor() {
		super()	
		this.keyPressNavigation=this.keyPressNavigation.bind(this)
		this.handleDocumentKeyDown=this.handleDocumentKeyDown.bind(this)
		this.setSelectedCreator=this.setSelectedCreator.bind(this)
		this.changeFilter=this.changeFilter.bind(this)
		this.adminAddCreatorFebPayoutAmount=this.adminAddCreatorFebPayoutAmount.bind(this)
		this.adminSetCreatorMetadataToEmailSent=this.adminSetCreatorMetadataToEmailSent.bind(this)
		this.activateAward=this.activateAward.bind(this)
		this.adminSetCreatorToCustomEmail=this.adminSetCreatorToCustomEmail.bind(this)
		this.state = { 
			filter:'outstandingToPay',
			selectedCreator:null
		 }
	}
 
	UNSAFE_componentWillMount() {
		this.props.adminFetchCreators()
		this.props.adminFetchAwards()
		document.addEventListener('keydown', this.handleDocumentKeyDown)
	}
	 
	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleDocumentKeyDown)
	}


	changeFilter(newFilter){
		this.setState({filter:newFilter,selectedCreator:null})
		const table=document.getElementById('focusablePackReviewTable')
		table.scrollTop = 0
	}

	handleDocumentKeyDown(e){
		const {selectedCreator}=this.state
		if(!selectedCreator){			
			if(e.keyCode ===40){//if press down arrow when no items selected then set first item to the active item 
				e.preventDefault()
				const table=document.getElementById('focusablePackReviewTable')
				if(table){
					table.focus()
				}
				const {filter}=this.state
				let allData=this.props.creators					
				if(filter==='inbox'){
					allData=this.props.inbox
				}else if(filter==='creatorsWithAwards'){
					allData=this.props.creatorsWithAwards
				}else if(filter==='febEmailSent'){
					allData=this.props.febEmailSent
				}
				else if(filter==='outstandingNeedPaypal'){
					allData=this.props.outstandingNeedPaypal
				}
				else if(filter==='outstandingToPay'){
					allData=this.props.outstandingToPay
				}
				else if(filter==='fullyPaid'){
					allData=this.props.fullyPaid
				}
				const newActiveItem=allData[0]
				this.setState({selectedCreator:newActiveItem})
			}
		}
	}

	keyPressNavigation(e){
			const {selectedCreator,filter}=this.state
			let allData=this.props.creators
			
			if(filter==='inbox'){
				allData=this.props.inbox
			}else if(filter==='creatorsWithAwards'){
				allData=this.props.creatorsWithAwards
			}else if(filter==='febEmailSent'){
				allData=this.props.febEmailSent
			}else if(filter==='outstandingNeedPaypal'){
						allData=this.props.outstandingNeedPaypal
					}
					else if(filter==='outstandingToPay'){
						allData=this.props.outstandingToPay
					}
					else if(filter==='fullyPaid'){
						allData=this.props.fullyPaid
					}
		
			const newActiveItem=allData[0]
			this.setState({selectedCreator:newActiveItem})


			if(selectedCreator){
			const activeIndex= findIndex(allData, function (item) {
				return item.id === selectedCreator.id
			})
			let newSelectedIndex
			if (e.keyCode === 40) { 
				newSelectedIndex=Math.min(activeIndex+1,allData.length-1)
				const newSelected=allData[newSelectedIndex]	   
				this.setState({selectedCreator:newSelected})				
			}
			else if (e.keyCode === 38) { 
				newSelectedIndex=Math.max(activeIndex-1,0)
				const newSelected=allData[newSelectedIndex]	   
				this.setState({selectedCreator:newSelected})
			}

		}
			
	}

	setSelectedCreator(creator){
		if(this.state.selectedCreator && this.state.selectedCreator.id===creator.id){
			this.setState({selectedCreator:null})
		}else{
			this.setState({selectedCreator:creator})
		}
	}

	adminAddCreatorFebPayoutAmount(creatorID,payoutAmount){
		const integerPayout=parseInt(Number(payoutAmount)*100)	
		this.props.adminAddCreatorFebPayoutAmount(creatorID,integerPayout).then((response)=>{
			const {selectedCreator}=this.state
			let explorerMetadata={}
			if(selectedCreator.explorerMetadata){
				explorerMetadata=selectedCreator.explorerMetadata
			}
			const newExplorerMetadata={...explorerMetadata,februaryPayoutAmount:integerPayout
			}
			const updatedCreator=Object.assign({},selectedCreator)
			updatedCreator.explorerMetadata=newExplorerMetadata
			this.setState({selectedCreator:updatedCreator})
		})
	}

	
	adminSetCreatorMetadataToEmailSent(){
		const creator=this.state.selectedCreator
		let metadata=creator.explorerMetadata||{misc:{}}
		let febCreatorFundEmailSent=false 
		if(creator && creator.explorerMetadata && creator.explorerMetadata.misc && creator.explorerMetadata.misc.febCreatorFundEmailSent){
			febCreatorFundEmailSent=true
		}

		metadata.misc.febCreatorFundEmailSent=!febCreatorFundEmailSent

		this.props.adminUpdateCreatorMetadata(creator.id,metadata).then((response)=>{
			if(this.state.filter==='inbox'){
				const updatedCreator=Object.assign({},creator)
				updatedCreator.explorerMetadata=response
				this.setState({selectedCreator:{}})
			}else{
				const updatedCreator=Object.assign({},creator)
				updatedCreator.explorerMetadata=response
				this.setState({selectedCreator:updatedCreator})
			}
			
		})
	}

	adminSetCreatorToCustomEmail(){
		const creator=this.state.selectedCreator
		let metadata=creator.explorerMetadata||{misc:{}}
		let customEmail=false 
		if(creator && creator.explorerMetadata && creator.explorerMetadata.misc && creator.explorerMetadata.misc.customEmail){
			customEmail=true
		}

		metadata.misc.customEmail=!customEmail

		this.props.adminUpdateCreatorMetadata(creator.id,metadata).then((response)=>{
			if(this.state.filter==='inbox'){
				const updatedCreator=Object.assign({},creator)
				updatedCreator.explorerMetadata=response
				this.setState({selectedCreator:updatedCreator})
			}else{
				const updatedCreator=Object.assign({},creator)
				updatedCreator.explorerMetadata=response
				this.setState({selectedCreator:updatedCreator})
			}
			
		})
	}


	activateAward(awardId){
		this.props.adminActivateAward(awardId)
	}

	render() {
			return(
				<DocumentTitle title={'Plickers - Creators List'}>
					
				<React.Fragment>
					{this.props.creators.length>0 && 
						<CreatorsListPage 
							customEmail={this.props.customEmail}
							allCreators={this.props.allCreators}
							creatorsWithAwards={this.props.creatorsWithAwards}
							inbox={this.props.inbox}
							creators={this.props.creators} 
							selectedCreator={this.state.selectedCreator}
							setSelectedCreator={this.setSelectedCreator}
							keyPressNavigation={this.keyPressNavigation}
							adminAddCreatorFebPayoutAmount={this.adminAddCreatorFebPayoutAmount}
							adminSetCreatorMetadataToEmailSent={this.adminSetCreatorMetadataToEmailSent}
							awards={this.props.awards}
							activateAward={this.activateAward}
							setFilter={this.changeFilter}
							filterTerm={this.state.filter}
							adminSetCreatorToCustomEmail={this.adminSetCreatorToCustomEmail}
							adminPayActivatedAwards={this.props.adminPayActivatedAwards}
							febEmailSent={this.props.febEmailSent}
							outstandingToPay={this.props.outstandingToPay}
							outstandingNeedPaypal={this.props.outstandingNeedPaypal}
							fullyPaid={this.props.fullyPaid}
						/>
					}
					{this.props.creators.length===0 &&
					<div className='explorerCrossFilter-table-header explorerCrossFilter-table-header--loading'>
						Loading Creator list...
					</div>
					}
				</React.Fragment>
			</DocumentTitle>
		)		
	}
}

function mapStateToProps(state,ownProps) {
	let creatorsWithAwards=[]
	if(state.adminCreators && state.adminAwards){
		state.adminCreators.forEach((creator)=>{			
			const creatorAwards=filter(state.adminAwards, function(award) { return award.user === creator.id})
			if(creatorAwards && creatorAwards.length !==0){
				creatorsWithAwards.push(creator)
			}
		})
	}

	//inbox is creators with awards who have not been emailed
	let inbox=[]
	let customEmail=[]
	let outstandingToPay=[] //have outstanding activated awards and have paypal email
	let outstandingNeedPaypal=[]
	let fullyPaid=[]
	let febEmailSent=[]


	creatorsWithAwards.forEach((creator)=>{
		let isCustomEmail=false 
		if(creator.explorerMetadata && creator.explorerMetadata.misc && creator.explorerMetadata.misc.customEmail){
			isCustomEmail=true
		}


		let febCreatorFundEmailSent=false 
		if(creator.explorerMetadata && creator.explorerMetadata.misc && creator.explorerMetadata.misc.febCreatorFundEmailSent){
			febCreatorFundEmailSent=true
		}
		if(isCustomEmail && !febCreatorFundEmailSent){
			customEmail.push(creator)
		}else if(!febCreatorFundEmailSent){
				inbox.push(creator)
		}if(febCreatorFundEmailSent){
			febEmailSent.push(creator)
		}
		

		const creatorAwards=filter(state.adminAwards, function(award) { return award.user === creator.id})
			let creatorFullyPaid=true
			let payoutAmount=0
			if(creatorAwards && creatorAwards.length !==0){
				creatorAwards.forEach((award)=>{

					if(award.status==='activated' || award.status==='proposed'){

						creatorFullyPaid=false
					}else if(award.status==='payout pending' ||award.status==='paid' ){
						payoutAmount+=award.amount
					}
				})
				if(creatorFullyPaid && payoutAmount !==0){
						fullyPaid.push(creator)
					}else{
						if(creator.payoutDestination){
							outstandingToPay.push(creator)
						}else{
							outstandingNeedPaypal.push(creator)
						}
					}
			}
	})
	return {
		outstandingToPay:sortBy(outstandingToPay,[function(o) { 
					return o.username.toString().toLowerCase()
		}]),
		outstandingNeedPaypal:sortBy(outstandingNeedPaypal,[function(o) { 
					return o.username.toString().toLowerCase()
		}]),
	fullyPaid:sortBy(fullyPaid,[function(o) { 
					return o.username.toString().toLowerCase()
		}]),
		customEmail:sortBy(customEmail,[function(o) { 
					return o.username.toString().toLowerCase()
		}]),
		febEmailSent:sortBy(febEmailSent,[function(o) { 
					return o.username.toString().toLowerCase()
		}]),
		creators:sortBy(state.adminCreators,[function(o) { 
					return o.username.toString().toLowerCase()
		}]),
		allCreators:sortBy(state.adminCreators,[function(o) { 
					return o.username.toString().toLowerCase()
		}]),
		creatorsWithAwards:sortBy(creatorsWithAwards,[function(o) { 
					return o.username.toString().toLowerCase()
		}]),
		inbox:sortBy(inbox,[function(o) { 
					return o.username.toString().toLowerCase()
		}]),
		awards:state.adminAwards

	
	}
}

export default withRouter(connect(
mapStateToProps,
	{	adminPayActivatedAwards,
		adminCreateAward,
		adminFetchCreators,
		adminAddCreatorFebPayoutAmount,
		adminUpdateCreatorMetadata,
		adminFetchAwards,
		adminActivateAward

		 }
)(CreatorsListContainer))

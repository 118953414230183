import React from 'react'




class StickyDividerWrapper extends React.Component{

	render(){	
		const {className} = this.props
	
		return(
			<div className={'stickyDivider' + (className ? ` in--${className}` : '')}>
				{this.props.children}
			</div>
		)		
	}	
}

StickyDividerWrapper.defaultProps = {
	caps: false,
	isFixed: false	
}

export default StickyDividerWrapper



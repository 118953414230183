import _ from 'lodash'
import moment from 'moment'

export default function (state=[], action) {
	switch (action.type) {

	case 'FETCH_QUEUE_SET_POLLS_SUCCESS': //Fetch all
		return action.response

	case 'UPDATED_SET_POLL_WITH_PUSHER':
		if(action.updatedPoll.hasResponses===false){
			const updatedPollIndex= state.findIndex(setPoll => {
				return setPoll.id === action.updatedPoll.id
			})		
				return [
					...state.slice(0,updatedPollIndex),
					action.updatedPoll,
					...state.slice(updatedPollIndex + 1)
				]
		}else if(action.updatedPoll.hasResponses===true){
			const updatedPollIndex= state.findIndex(setPoll => {
				return setPoll.id === action.updatedPoll.id
			})
			if(updatedPollIndex !==-1){
				return [
				...state.slice(0,updatedPollIndex),
				...state.slice(updatedPollIndex + 1)
				]
			}	else return state
			
		}else return state


	case 'FETCH_SINGLE_SET_POLL_SUCCESS'://Fetch Single Poll
		if(action.response.hasResponses===false){
			const setPollIndex= state.findIndex(setPoll => {
				return setPoll.id === action.response.id
			})	
			if(setPollIndex !==-1){
				return [
					...state.slice(0,setPollIndex),
					action.response,
					...state.slice(setPollIndex + 1)
				]
			}else{
				return [
					...state,
					action.response
				]
			}
		}else if(action.response.hasResponses===true){
			const setPollIndex= state.findIndex(setPoll => {
				return setPoll.id === action.response.id
			})	
			if(setPollIndex !==-1){
				return [
					...state.slice(0,setPollIndex),
					...state.slice(setPollIndex + 1)
				]
			}else{
				return state
			}
		}
		else return state


	case 'FETCH_POLLS_FOR_A_SET_SUCCESS':
		const filteredPolls=_.filter(action.response, {'hasResponses':false})
		return filteredPolls

	case 'REFRESH_SET_POLLS_SUCCESS':
		const filtered=_.filter(action.response,{'hasResponses':false})		
		let polls=state.slice()
		_.forEach(filtered, function(modifiedPoll) { 			
			let indexOfPoll = polls.findIndex(poll => {
				return poll.id === modifiedPoll.id
			})
			if(indexOfPoll !== -1){ 
				polls =[
					...polls.slice(0,indexOfPoll),
					...polls.slice(indexOfPoll + 1)
				]
			}
			polls=[modifiedPoll,...polls]
			
		})

		return polls


	case 'CREATE_SET_POLL_REQUEST'://add a temp poll on request sent)
		return [...state,action.data] 
		
	case 'CREATE_SET_POLL_SUCCESS'://replase temp poll with the response (has id etc)
		const timeStamp = moment(action.response.planned)
		const isotime=timeStamp.toISOString()
		const indexOfPoll = state.findIndex(setPoll => {
			return moment(setPoll.planned).toISOString() === isotime
		})
		if(indexOfPoll !==-1){ //if there was a temporary poll remove it and replace with real poll
			return [
				...state.slice(0,indexOfPoll),
				action.response,
				...state.slice(indexOfPoll + 1)
			]
		}else return [...state,action.response] //if no temp poll just append new poll


	case 'CREATE_SET_POLL_FAILURE':	//if request fails remove the temp poll
		const timeStampFailed = moment(action.data.planned)
		const isotimeFailed=timeStampFailed.toISOString()
		const indexOfPollFailed = state.findIndex(setPoll => {
			return moment(setPoll.planned).toISOString() === isotimeFailed
		})

		return [
			...state.slice(0,indexOfPollFailed),
			...state.slice(indexOfPollFailed + 1)
		]

	case 'UPDATE_SET_POLL_REQUEST':
		const pollId = action.pollId
		let indexOfPollForUpdateRequest=0
		if(state){
			indexOfPollForUpdateRequest = state.findIndex(setPoll => {
				return setPoll.id === pollId
			})
		}
		//if canceled=true we want to delete the poll from state.  if canceled=false update the poll.
		if(indexOfPollForUpdateRequest!==-1){
			if(action.data.canceled===true && !action.data.hasResponses){
				return [
					...state.slice(0,indexOfPollForUpdateRequest),
					...state.slice(indexOfPollForUpdateRequest + 1)
				]
			}
			else{
				return [
					...state.slice(0,indexOfPollForUpdateRequest),
					action.data,
					...state.slice(indexOfPollForUpdateRequest + 1)
				]
			}
		}else return state

	case 'UPDATE_SET_POLL_SUCCESS':
		return state

	case 'LOGOUT':
		return []

	default:
		return state

	}
}
import React from 'react'
import ModalWrapper from '../ModalWrapper'
import AddLicenseKeyContainer from '../../../containers/account/AddLicenseKeyContainer'

const AddLicenseKeyModal = props => {

	return (

  <ModalWrapper
    {...props}
    title={'Add License Key'}
    iconName=''
    closeBtn={false}
    showModalHeader={false}
    modalStyle='dark'
  >
    <AddLicenseKeyContainer {...props}/>

  </ModalWrapper>



  )
}

export default AddLicenseKeyModal
import findIndex from 'lodash/findIndex' 

const LOCK_TIMEOUT=1*60*1000

export default function (state = {}, action) {
	switch (action.type) {
	
	case 'FETCH_LOCKS_SUCCESS':				
		const repoId=action.repoId
		const response=action.response

		
		return {...state,
			 [`${repoId}`]:response
			 }

	case 'CHECK_FOR_EXPIRED_LOCKS':	
		const nowDate = new Date()
		let newState = {}

		Object.keys(state).map((key)=>{
			const repoLocks= state[key]
			let newRepoLocks=[]
			repoLocks.map((lock)=>{

				if((nowDate-new Date(lock.lockedAt)) < LOCK_TIMEOUT){
					newRepoLocks.push(lock)
				}
				newState[key]=newRepoLocks
				return null})

			return null})


		return newState


	case 'UPDATE_LOCKS_FROM_PUSHER':	
		const updatedLock = action.lock
		const repo = updatedLock.repo

		const repoLocks = state[repo]
		let newRepoLocks = [updatedLock]
		
		if(repoLocks){
			var indexOfLockToUpdate = repoLocks.findIndex( lock => {
				return lock.item === updatedLock.item
			})
			if(indexOfLockToUpdate === -1){
				indexOfLockToUpdate=repoLocks.length || 0
			}
			newRepoLocks = [
				...repoLocks.slice(0,indexOfLockToUpdate),
				updatedLock,
				...repoLocks.slice(indexOfLockToUpdate + 1)
			]
		}

		return {...state,
			 [`${repo}`]:newRepoLocks
			 }

	
	case 'REMOVE_LOCK_PUSHER':	
		const lockId = action.lock.id
		const lockRepoId=action.lock.repo
		if(state && state[lockRepoId]){
			const indexOfLockToDelete = findIndex(state[lockRepoId],lock => {
				return lock.id === lockId
			})
			return {...state,
				 		[`${lockRepoId}`]:[
					...state[lockRepoId].slice(0, indexOfLockToDelete),
					...state[lockRepoId].slice(indexOfLockToDelete + 1)
				]
			}
		} else return state
		

	case 'LOGOUT':
		return {}
	
	default:
		return state
	
	}
}

import React, { Component} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {deleteQuestionPoll,updateQuestionPoll} from '../../actions/polls'
import {showConfirmModal,showExpandedReviewSlideModal ,hideModal} from '../../actions/modals'
import {showNotification} from '../../actions/notifications'
import {processQuestionResponses} from '../../utils/processQuestionResponses'
import QuestionPollDetail from '../../components/pollDetail/QuestionPollDetail'
import DocumentTitle from 'react-document-title' 
import find from 'lodash/find'


class QuestionPollDetailContainer extends Component {

	constructor() {
		super()
		this.deleteQuestionPoll=this.deleteQuestionPoll.bind(this)
		this.archiveQuestionPoll=this.archiveQuestionPoll.bind(this)
		this.confirmDeleteQuestionPoll=this.confirmDeleteQuestionPoll.bind(this)
	}

	componentDidMount(){
		if(window.analytics){
			let testGroup=null
			const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
			if(setLimitTestGroup){
				testGroup=setLimitTestGroup
			}
			window.analytics.page('Report',{pollType:'Question Poll','test_group_set_limit':testGroup})
		}
	}

	deleteQuestionPoll(poll){
		this.props.deleteQuestionPoll(poll.id).then(() => {
			this.props.hideModal()
			this.props.history.goBack()
			const notificationMessage=`${poll.snapshot.body}`
			this.props.showNotification(notificationMessage,'Deleted','destroy')
		})
	}

	confirmDeleteQuestionPoll(poll){
		this.props.showConfirmModal(poll,this.deleteQuestionPoll,'delete')
	}

	archiveQuestionPoll(poll){
		const nowDate=new Date()
		const requestData={
			...poll,
			clientModified:nowDate,
			archived:!poll.archived
		}

		this.props.updateQuestionPoll(poll.id,requestData).then((response) => {
			if(response==='failed'){
				const notificationMessage=''
				const actionWord=poll.archived? 'unarchive':'archive'
				this.props.showNotification(notificationMessage,`Failed to ${actionWord} report`,'destroy')
			}else{	
				this.props.history.goBack()
				const notificationMessage=`${poll.snapshot.body}`
				const actionWord=poll.archived? 'Unarchived':'Archived'
				this.props.showNotification(notificationMessage,actionWord,'default')
			}
		})
	}


	render() {
		const {section,poll,question}=this.props   
		let processedQuestionResponses
		let questionDetailPath
		if(poll && section){ 
			processedQuestionResponses = processQuestionResponses(poll,section)
			questionDetailPath=`/question/${poll.question}`
		}
		let documentTitle='Report'
		if(poll && poll.snapshot){
			documentTitle=`${poll.snapshot.body} Report - Plickers`    		 
		}
		return( 
			<DocumentTitle title={documentTitle}>
				<div className='page questionReport page--defaultLayout'>
					{poll && section &&
						<QuestionPollDetail
							poll={poll}
							question={question}
							section={section}
							processedQuestionResponses={processedQuestionResponses}
							questionDetailPath={questionDetailPath}
							deletePoll={this.confirmDeleteQuestionPoll}
							archivePoll={this.archiveQuestionPoll}
							showExpandedReviewSlideModal={this.props.showExpandedReviewSlideModal}
						/>
					}
				</div> 
			</DocumentTitle>
		)
	}
}

function mapStateToProps(state,ownProps) { 
	const currentPoll= find(state.historyQuestionPolls, {'id':ownProps.match.params.pollId})
	let currentSection
	let libraryQuestion
	if(currentPoll){
		currentSection=find(state.sections, {'id':currentPoll.section})
		libraryQuestion=find(state.questions, {'id':currentPoll.question})
	}
	return {   
		poll:currentPoll,
		section:currentSection,
		question:libraryQuestion
	}
}


export default withRouter(connect(
	mapStateToProps,
	{ 
		deleteQuestionPoll,
		updateQuestionPoll,
		showConfirmModal,
		hideModal,
		showNotification,
		showExpandedReviewSlideModal
	}
)(QuestionPollDetailContainer))




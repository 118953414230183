import React from 'react'

class AccountButton extends React.Component{

	render(){	
		const {onClickFunction, label, name, children} = this.props
		return(
			<button className={'btn btn--mid account-accountBtn' +				
				(name ? ` account-accountBtn--${name}` : '') + (this.props.subtle ? ' account-accountBtn--subtle' : '' )}
				onClick={onClickFunction}
			>			
				{label}
				{children}
			</button>
		)
	}		
}


export default AccountButton
import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_ADMIN_SUBMISSIONS_SUCCESS':	
		return action.response

	case 'ADMIN_UPDATE_SUBMISSION_SUCCESS':	
		const updatedSubmissionId = action.response.id
		var indexOfSubmissionToUpdate = findIndex(state,(submission => {
			return submission.id === updatedSubmissionId
		}))
		if(indexOfSubmissionToUpdate !== -1 ){ 
			let repo=state[indexOfSubmissionToUpdate].repo
		
			return [
					...state.slice(0,indexOfSubmissionToUpdate),
					{...action.response,repo:repo},
					...state.slice(indexOfSubmissionToUpdate + 1)
				]	
		}
		else return state



	case 'LOGOUT':
		return null
	
	default:
		return state
	}
}

import React from 'react'
import slideLayoutDefinitions from '../../../../staticData/slideLayoutDefinitions.js'

const SERVED_IMAGE_MAX_DIMENSION = 900

class StaticSlideImage extends React.Component{
	
	constructor(props){
		super(props)
		this.onLoad=this.onLoad.bind(this)
	}

	onLoad(){
		if(this.props.questionMediaPromiseResolve){ //for slide service
			console.log('question image onLoad')
			this.props.questionMediaPromiseResolve()
		}
	}

	render(){
		const {image, slideTemplate, imageFillType,questionMediaDynamicHeight} = this.props

		const displayImageWidth = image.deliveryDimensions.w
		const displayImageHeight = image.deliveryDimensions.h

		let imageContainerWidth = 0 
		if(slideLayoutDefinitions.bodyMediaContainerDimensions[`${slideTemplate}`]){
			imageContainerWidth=slideLayoutDefinitions.bodyMediaContainerDimensions[`${slideTemplate}`].width
		}
		let imageContainerHeight = 0 
		if(slideLayoutDefinitions.bodyMediaContainerDimensions[`${slideTemplate}`]){
			imageContainerHeight=slideLayoutDefinitions.bodyMediaContainerDimensions[`${slideTemplate}`].height
		}
		if(slideTemplate === 'bodyCenterMediaCenter'){
			imageContainerHeight = questionMediaDynamicHeight // pass in
		}
		
		const displayImageAspect = displayImageWidth / displayImageHeight
		const containerAspect = imageContainerWidth / imageContainerHeight

		let fitImageWidth
		let fitImageHeight 
		let servedImageWidth
		let servedImageHeight

		let maxServedImageDimensionLength = SERVED_IMAGE_MAX_DIMENSION

		
		if(displayImageAspect > containerAspect){//landscape
			fitImageWidth = imageContainerWidth
			fitImageHeight = imageContainerWidth / displayImageAspect
		}
		if(displayImageAspect < containerAspect){//portrait
			fitImageHeight = imageContainerHeight
			fitImageWidth = imageContainerHeight * displayImageAspect
		}
		if(displayImageAspect > 1){
			servedImageWidth = maxServedImageDimensionLength
			servedImageHeight = servedImageWidth / displayImageAspect
		}
		if(displayImageAspect < 1){//portrait
			servedImageHeight = maxServedImageDimensionLength
			servedImageWidth = servedImageHeight * displayImageAspect
		}

		let fillImageWidth
		let fillImageHeight
	

		if(displayImageAspect > containerAspect){//landscape
			fillImageHeight = imageContainerHeight
			fillImageWidth = imageContainerHeight * displayImageAspect
		}

		if(displayImageAspect < containerAspect){ //portrait
			fillImageWidth = imageContainerWidth
			fillImageHeight = imageContainerWidth / displayImageAspect
		
		}

		let imageWidth
		let imageHeight 

		if(imageFillType === 'fit'){
			imageWidth = fitImageWidth
			imageHeight = fitImageHeight
		}
		if(imageFillType === 'fill'){
			imageWidth = fillImageWidth
			imageHeight = fillImageHeight
		}

		//
		// 

		let coverWidth
		let coverHeight   

		if(imageFillType === 'fit'){
			coverWidth = fitImageWidth
			coverHeight = fitImageHeight
		}

		if(imageFillType === 'fill'){
			coverWidth = imageContainerWidth
			coverHeight = imageContainerHeight
		}
	 
		// POSITIONING WITHIN CONTAINER

		let imageLayoutStyle

		if(slideTemplate === 'bodyLeftMediaRight'){
			imageLayoutStyle = {top: '0px', right: '0px'}
		}
		if(slideTemplate === 'bodyRightMediaLeft'){
			imageLayoutStyle = {top: '0px', left: '0px'}
		}
		if(slideTemplate === 'bodyCenterMediaCenter'){
			imageLayoutStyle = {top: '0px', margin: 'auto', left: '0px', right:'0px'}
		}

		return(			
			<React.Fragment>					
				<div style={{width: `${coverWidth}px`, height: `${coverHeight}px`, ...imageLayoutStyle}}  className={'slide-media--imageCover ' + (imageFillType ? `slide-media--imageCover--${imageFillType}` :'')}>            
					<div style={{width: `${imageWidth}px`, height: `${imageHeight}px`}} className='slide-media--imageContainer '>															
						<img
							onLoad={this.onLoad}
							src={image.deliveryUrl}
							className='slide-media--image'   
							alt=''            
						/>																									
					</div>            
				</div>					
			</React.Fragment>			
		)		
	}	
}

export default StaticSlideImage
import React from 'react'
import { connect } from 'react-redux'
import {imageSearch} from '../../actions/imageSearch'
import Gallery from '../imageGallery/Gallery'
import loadable from '@loadable/component'

const CartoonScreenSVG = loadable(() => import(/*webpackChunkName: 'cartoonscreenSVG'*/ '../misc/CartoonScreenSVG'))


const DEFAULT_SEARCH_TERM='Humpback whale'


class UpgradeLandingInstantImagesDemo extends React.Component{

	constructor(props){
		super()
		this.searchForImages=this.searchForImages.bind(this)
		this.handleKeyUp=this.handleKeyUp.bind(this)


		this.state=({
			images:[],
			searchTerm:DEFAULT_SEARCH_TERM,
			isSearching:false,
			submitLoading:false,
			errorMessage:null

		})
	}

	UNSAFE_componentWillMount(){
		this.searchForImages()	
	}



	parseImageObject(value){
		return {
			src: value.contentUrl,
			thumbnail: value.thumbnailUrl,
			thumbnailWidth: value.thumbnail.width,
			thumbnailHeight: value.thumbnail.height, 
			caption:'',
			accentColor:value.accentColor
		}
	}

	handleKeyUp(e){
		if (e.keyCode === 13) { //enter key
			this.searchForImages()
  		}
	}

	searchForImages(){
		const numberOfImagesToFetch=10
		const offset=0
		const {searchTerm}=this.state
		this.setState({isSearching:true, noResults:false,images:[],submitLoading:true})
		this.props.imageSearch(searchTerm,numberOfImagesToFetch,offset).then((response) => {
  		this.setState({isSearching:false})
  		if(response.value.length===0){
    		this.setState({noResults:true})
			}
			let images=[]
			response.value.forEach(value => {
				images.push(this.parseImageObject(value))
			})
			this.setState({images:images,submitLoading:false})
		})
		.catch((error) => {
			this.setState({errorMessage:'Sorry we are having issues handling the high demand for our new image search feature! We are working to fix this as soon as possible.'})
			this.setState({submitLoading:false})
		 	
		 })
	}



	render(){	
		
		return(
			<div className='upgrade--landing-featureSection-instantImages-demoContainer'>
				
				<div className='upgrade--landing-featureSection-instantImages-demo'>
					<div className='upgrade--landing-featureSection-instantImages-demo-search'>
						<input 
							className='upgrade--landing-featureSection-instantImages-demo-search-input'
							required
							type='text'
							placeholder='Search Internet for Images'
							value={this.state.searchTerm}
							onChange={(e)=>{this.setState({searchTerm:e.target.value})} }
							onKeyUp={this.handleKeyUp}
							onFocus={()=>{this.setState({searchTerm:'',images:[]})}}
							// autoFocus
						/>
						
						<button 
							className='upgrade--landing-featureSection-instantImages-demo-search-btn'
							onClick={this.searchForImages}
							disabled={this.state.submitLoading}
						>
							{!this.state.submitLoading &&
								<span>Search</span>
							}

							{this.state.submitLoading &&
								<div className='upgrade--landing-featureSection-instantImages-demo-search-btn-loadingSpinnerContainer'>
									<div className='upgrade--landing-featureSection-instantImages-demo-search-btn-loadingSpinner' />
								</div>
							}
							
						</button>
					
					


					</div>
					<div className='upgrade--landing-featureSection-instantImages-demo-resultsContainer'>
						 <Gallery 
							 	images={this.state.images}
						/>	
						{this.state.errorMessage&&
						<div className='imageSearch-body-noResults'>							
							{this.state.errorMessage}
						</div>	
					}

					</div>

				</div>

				<div className='upgrade--landing-featureSection-instantImages-demoBG'>
					<div className='upgrade--landing-featureSection-instantImages-demoBG-screen'>
						<CartoonScreenSVG screen='laptopSetEditor' />
					</div>
				</div>

			</div>
			
		)	
	}
				
}

export default connect(
	(state) => ({

	} ),
	{ imageSearch
		 })(UpgradeLandingInstantImagesDemo)

import api from '../api'


 
export function fetchMeta() {
	return (dispatch) => api.fetch('/meta')
		.then((response) => {
			dispatch({ type: 'FETCH_META_SUCCESS', response })
			if(response.testGroup && response.testGroup.setLimit){
				localStorage.setItem('meta.setGroup', JSON.stringify(response.testGroup.setLimit))
			}
				//if newFeatureFirstSignin is undefined set it to current date (maybe should use session info? -doesnt make too much difference)
			if(!response.newFeatureFirstSignin){
				dispatch(updateNewFeatureFirstSignin())
			}
		})
		.catch((error) => {
			return error
		})
}


function updateNewFeatureFirstSignin() {
	const nowDate=new Date()
	const data={newFeatureFirstSignin:nowDate}
	return (dispatch) => api.put('/meta',data)
		.then((response) => {
			dispatch({ type: 'UPDATE_META_SUCCESS', response })
		})
		.catch((error) => {
			return error
		})
}


export function updateMeta(data) {
	return (dispatch) => api.put('/meta',data)
		.then((response) => {
			dispatch({ type: 'UPDATE_META_SUCCESS', response })
		})
		.catch((error) => {
			return error
		})
}


export function onboardingStatusUpdateRecieved(onboardingStatus) {
	return (dispatch) =>{
		dispatch({ type: 'UPDATE_ONBOARDING_STATUS', onboardingStatus })
	} 	
}







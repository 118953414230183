import React, {Component} from 'react'
import HelpVideoThumbnail from '../../components/uiKit/HelpVideoThumbnail'

class ImportQuestionsRightPanelHelpFAQ extends Component{

	constructor(props){
		super(props)
		this.state={
			showDescription: false,
		}
	}

	render(){	
		return(

			<div className='importQuestions-rightPanel-subSection--help-faqItem'>						
				<div onClick={()=>{this.setState({showDescription: !this.state.showDescription})}} className='importQuestions-rightPanel-subSection--help-faqItem-title'>
					{this.props.title}
				</div>								
				<ul className='importQuestions-rightPanel-subSection--help-faqitem-list'>
					{this.props.description &&
					<li className='importQuestions-rightPanel-subSection--help-faqItem-description'>
						{this.props.description} 
					</li>				
					}
					{this.props.description2 &&
					<li className='importQuestions-rightPanel-subSection--help-faqItem-description importQuestions-rightPanel-subSection--help-faqItem-description--2'>
						{this.props.description2}
					</li>				
					}
					{this.props.description3 &&
					<li className='importQuestions-rightPanel-subSection--help-faqItem-description importQuestions-rightPanel-subSection--help-faqItem-description--3'>
						{this.props.description3}
					</li>				
					}
					{this.props.description4 &&
					<li className='importQuestions-rightPanel-subSection--help-faqItem-description importQuestions-rightPanel-subSection--help-faqItem-description--4'>
						{this.props.description4}
					</li>			
					}
				</ul>
			
			</div>
		)
	}
}

				

class ImportQuestionsRightPanelHelp extends Component{


	render(){	
		const {questionsInSetLimit}=this.props
		let setLimitLabel= '5'

		if(questionsInSetLimit === 12){
			setLimitLabel = '12'
		}

		return(		
			<React.Fragment>
				
				<div className='importQuestions-rightPanel-subSection--help-gettingStartedVideoContainer' onClick={this.props.showHelpVideoModal}>
					<div className='importQuestions-rightPanel-subSection--help-gettingStartedVideo-text'>
						<div className='importQuestions-rightPanel-subSection--help-gettingStartedVideo-text-label'>
							Getting Started Video
						</div>
						<div className='importQuestions-rightPanel-subSection--help-gettingStartedVideo-text-sub'>
							Watch Video (4:50)
						</div>					
					</div>		

					<div className='importQuestions-rightPanel-subSection--help-gettingStartedVideo' >
						<HelpVideoThumbnail title='Getting Started with Import Questions' />
					</div>
								
				</div>				

				<div className='importQuestions-rightPanel-subSection--help-faqQuestions'>						
					<ImportQuestionsRightPanelHelpFAQ
						title='What is Import Questions?'
						description="Use Import Questions to save time by copy and pasting in questions you have outside of Plickers."
						description2="To start, copy a bunch of multiple-choice question text from your source."
						description3="Then, paste the text into Import Questions. Check over and fix any issues, then Import into Set Editor"						
					/>								
					<ImportQuestionsRightPanelHelpFAQ
						title="What type of sources can I use?"
						description="Websites, Word Documents and other quiz web apps like Kahoot work well."
						description2="But you can also import from things like PDFs and Spreadsheets – basically, anywhere you can copy text from!"
					/>
					<ImportQuestionsRightPanelHelpFAQ
						title="Import questions doesn't work well with my source"
						description="Send us a message using the Import Questions Feedback form with a link to your source and we’ll see if we can support it!"
						description2="Unfortunately, not all sources will work well with Import Questions – some PDFs and websites can format text weirdly so it makes them difficult to use."
					/>
					<ImportQuestionsRightPanelHelpFAQ
						title='How do I set the correct answer? What about survey questions?'
						description="Click on a Choice Letter on the left-side to make that choice correct. You can make a question a survey question by clicking on the Question Number on the left side."
						description2="You might find it easier to do these things after you’ve imported the questions into Set Editor."
					/>
					<ImportQuestionsRightPanelHelpFAQ
						title="What does 'Find and Remove Text' do?"
						description="Sometimes when you copy and paste in questions, there will be recurring text like 'Question 1 of 5' or 'Answer'."
						description2="Using Find and Remove Text, you can clean out all these bits at once. If you accidentally removed more than you wanted, you can always use the Undo button."
					/>
					<ImportQuestionsRightPanelHelpFAQ
						title="What does 'Clean Pasted Text' do?"
						description="Often when you paste questions in other parts come along: things like question numbers, choice letters (like A, B, C, D), and bullet points."
						description2="You probably don’t want these in your final Plickers questions, so we can clean them out for you. We do this automatically by default, but you can also manually clean these elements yourself."
					/>
					<ImportQuestionsRightPanelHelpFAQ
						title="When I copy and paste in text, sometimes the questions don’t line up correctly"
						description="Sometimes questions will be mostly four-choice questions, with a few true and false questions thrown in."
						description2="When you check through the questions, just press Enter in the main text area to add line breaks and line things up."
					/>
					<ImportQuestionsRightPanelHelpFAQ
						title='When I copy and paste in text, sometimes the choices aren’t on separate lines'
						description="Unfortunately some sources format choices a bit weirdly. To clean these up, click into the main text area and press Enter to line them back up."
					/>
					<ImportQuestionsRightPanelHelpFAQ
						title="How can I import a bunch of true and false questions?"
						description="For true/false questions, selecting '2 choices' should fix things up for you."
					/>					
					<ImportQuestionsRightPanelHelpFAQ
						title='Can I import images with questions?'
						description='Import questions only works with plain old text. But after you import the questions, you can always add images to a question from the Set Editor.'
					/>
					<ImportQuestionsRightPanelHelpFAQ
						title='Can I import from a Powerpoint presentation?'
						description="Try going to the 'Outline View' in Powerpoint and copying the text form there. Depending on how the presentation is formatted, it might import nicely."
					/>
					<ImportQuestionsRightPanelHelpFAQ
						title='Is there a limit on the number of questions I can import?'
						description={`Free users are limited to ${setLimitLabel} questions in a Set, but can use Import Questions for unlimited sets. Pro users can import up to 75 questions at a time.`}
					/>
					<ImportQuestionsRightPanelHelpFAQ
						title='If you have any questions, send us an email: help@plickers.com'						
					/>
					
				</div>


			</React.Fragment>
		)
	}
}

					



export default ImportQuestionsRightPanelHelp

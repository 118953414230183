import React, { Component } from 'react'
import { connect } from 'react-redux'
import find from 'lodash/find'
import filter from 'lodash/filter'
import PropTypes from 'prop-types'
import Icon from '../misc/Icons'
import {playPoll} from '../../utils/playPoll'

class PollsForItemList extends Component {

	constructor(){
		super()
		this.renderSection=this.renderSection.bind(this)
	}

	renderSection(poll,index){
		const sectionDetail = find(this.props.sections, {'id':poll.section})||0
		if(sectionDetail){
			return( 
				<div onClick={()=>{this.playPoll(poll)}} key={index} className={'pollsForItemList-section ' + (sectionDetail.color ? ` pollsForItemList-section--classColor--${sectionDetail.color}` : ' pollsForItemList-section--classColor--none')}>					
					<div className='pollsForItemList-section-name notranslate'>{sectionDetail.name}</div>
					<div className='pollsForItemList-section-playContainer'>						
						<Icon name='play' />
					</div>
				</div>
			)
		}   
		else return null
	}

	playPoll(poll){
		if(poll.id){ //don't try and play if poll is still being created (show section blob immediately on add to queue from toolbar)
			playPoll(poll)
		}
	}

	render() {	
		const {sections,queuePolls} = this.props
		if(sections && queuePolls){
			return( 
				<React.Fragment>
				 {queuePolls.map((poll,index) => (this.renderSection(poll,index)))}
				</React.Fragment>
			)} else return null
	}
}


function mapStateToProps(state,ownProps) {
	let queuePolls=[]
	if(ownProps.item){
		if(ownProps.item.questions){
		 queuePolls=filter(state.queueSetPolls,{'setObject':ownProps.item.id})
		}
		else {
			queuePolls=filter(state.queueQuestionPolls,{'question':ownProps.item.id})
		}
	}
	return {
		sections:state.sections,
		queuePolls:queuePolls		
	}
}


export default connect((state) => (
	mapStateToProps
),
{ }
)(PollsForItemList)

PollsForItemList.propTypes = {	
	item:PropTypes.object

}

//For some subjects we display a different name to subject.name

export function formatSubjectToString(subject) {
	let subjectString=''
	if(subject){
		if(subject.name !== 'Other'){
			subjectString=subject.name
			if(subjectString==='American Sign Language'){
				subjectString='Sign Language'
			}
		}else{
			if(subject.id==='5db34494f172040011e3a2d5' || subject.id==='5dbcc5c019140e0012104f14'){
				subjectString='Other Creative Art'
			}
			else if(subject.id==='5db344a8eea85d001695404b' || subject.id==='5dbcc584eea50700165ac946'){
				subjectString='Other Foreign Language'
			}
			else if(subject.id==='5db344bceea85d001695404c'|| subject.id==='5dbcc534eea50700165ac842'){
				subjectString='Other Science'
			}
			else if(subject.id==='5db344cbf172040011e3a2d7'|| subject.id==='5dbcc5f8a5f33b0011f9eb1d'){
				subjectString='Other Social Science'
			}
			else{
				subjectString='Subject: Other'
			}
		}
	} 
	return subjectString
}



import {SetAttrsStep} from '../SetAttrsStep'

export function playMediaNode(nodePos) {
	const editorView=window.view
	let tr = window.view.state.tr
	editorView.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {    
		if(node.attrs.isPlaying||node.attrs.isPlaying===false){
			if(pos!==nodePos){
				tr.step(new SetAttrsStep(pos, {...node.attrs,isPlaying:false}))     
			}else{
				tr.step(new SetAttrsStep(pos, {...node.attrs,isPlaying:true}))
			}
		}})    
	tr.setMeta('addToHistory', false) 
	window.view.dispatch(tr)
}
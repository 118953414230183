const ChoiceBodyNodeSpec = {
  content: "inline*",
  isolating: true,
  selectable:false,
  draggable:false,
  toDOM: () => ["div",{class:'slide-choice-text'}, 0],
}
export default ChoiceBodyNodeSpec

 

// isolating: ?⁠bool
// When enabled (default is false), 
//the sides of nodes of this type count as boundaries that 
//regular editing operations, like backspacing or lifting, won't cross. 
//An example of a node that should probably have this enabled is a table cell.
import React from 'react'
 
class PlickersCardSVG extends React.Component{

 	render() { 	
   	
   	switch (this.props.cardExample) {
     
     case '1':
	     return (
		    <svg className="plickersCardSVG"  width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">  	    	    		
				 	<path d="M158,200 L82,200 C80.8954305,200 80,199.104569 80,198 L80,162 C80,160.895431 80.8954305,160 82,160 L118,160 C119.104569,160 120,159.104569 120,158 L120,122 C120,120.895431 120.895431,120 122,120 L158,120 C159.104569,120 160,119.104569 160,118 L160,82 C160,80.8954305 160.895431,80 162,80 L198,80 C199.104569,80 200,80.8954305 200,82 L200,158 C200,159.104569 199.104569,160 198,160 L162,160 C160.895431,160 160,160.895431 160,162 L160,198 C160,199.104569 159.104569,200 158,200 Z M161.991908,4.4408921e-16 L198.008092,4.4408921e-16 C199.108192,2.42004055e-16 200,0.891807532 200,1.99190787 L200,38.0080921 C200,39.1081925 199.108192,40 198.008092,40 L161.991908,40 C160.891808,40 160,39.1081925 160,38.0080921 L160,1.99190787 C160,0.891807532 160.891808,2.02085154e-16 161.991908,4.4408921e-16 Z M1.99190787,4.4408921e-16 L38.0080921,4.4408921e-16 C39.1081925,2.42004055e-16 40,0.891807532 40,1.99190787 L40,38.0080921 C40,39.1081925 39.1081925,40 38.0080921,40 L1.99190787,40 C0.891807532,40 1.34723436e-16,39.1081925 0,38.0080921 L8.8817842e-16,1.99190787 C7.53454983e-16,0.891807532 0.891807532,2.02085154e-16 1.99190787,4.4408921e-16 Z M41.9919079,40 L158.008092,40 C159.108192,40 160,40.8918075 160,41.9919079 L160,78.0080921 C160,79.1081925 159.108192,80 158.008092,80 L41.9919079,80 C40.8918075,80 40,79.1081925 40,78.0080921 L40,41.9919079 C40,40.8918075 40.8918075,40 41.9919079,40 Z M1.99190787,120 L38.0080921,120 C39.1081925,120 40,120.891808 40,121.991908 L40,198.008092 C40,199.108192 39.1081925,200 38.0080921,200 L1.99190787,200 C0.891807532,200 1.34723436e-16,199.108192 0,198.008092 L4.4408921e-16,121.991908 C3.09365774e-16,120.891808 0.891807532,120 1.99190787,120 Z"/>
				</svg>
		 	)

		case '2':
     return (
	    <svg className="plickersCardSVG"  width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">  	    	    		
				<path d="M42,0 L118,0 C119.104569,0 120,0.8954305 120,2 L120,38 C120,39.1045695 119.104569,40 118,40 L82,40 C80.8954305,40 80,40.8954305 80,42 L80,78 C80,79.1045695 79.1045695,80 78,80 L42,80 C40.8954305,80 40,80.8954305 40,82 L40,118 C40,119.104569 39.1045695,120 38,120 L2,120 C0.8954305,120 0,119.104569 0,118 L0,42 C0,40.8954305 0.8954305,40 2,40 L38,40 C39.1045695,40 40,39.1045695 40,38 L40,2 C40,0.8954305 40.8954305,0 42,0 Z M38.0080921,200 L1.99190787,200 C0.891807532,200 0,199.108192 0,198.008092 L0,161.991908 C0,160.891808 0.891807532,160 1.99190787,160 L38.0080921,160 C39.1081925,160 40,160.891808 40,161.991908 L40,198.008092 C40,199.108192 39.1081925,200 38.0080921,200 Z M198.008092,200 L161.991908,200 C160.891808,200 160,199.108192 160,198.008092 L160,161.991908 C160,160.891808 160.891808,160 161.991908,160 L198.008092,160 C199.108192,160 200,160.891808 200,161.991908 L200,198.008092 C200,199.108192 199.108192,200 198.008092,200 Z M158.008092,160 L41.9919079,160 C40.8918075,160 40,159.108192 40,158.008092 L40,121.991908 C40,120.891808 40.8918075,120 41.9919079,120 L158.008092,120 C159.108192,120 160,120.891808 160,121.991908 L160,158.008092 C160,159.108192 159.108192,160 158.008092,160 Z M198.008092,80 L161.991908,80 C160.891808,80 160,79.1081925 160,78.0080921 L160,1.99190787 C160,0.891807532 160.891808,1.13686838e-13 161.991908,1.13686838e-13 L198.008092,1.13686838e-13 C199.108192,1.13686838e-13 200,0.891807532 200,1.99190787 L200,78.0080921 C200,79.1081925 199.108192,80 198.008092,80 Z"/>
			</svg>		
		)

   	default:
    return null;
 		}
 	}
}




export default PlickersCardSVG
import React from 'react'
import SetHeader from '../printElements/SetHeader'
import Question from '../../../printKit/Question'
import PageHead from '../printElements/PageHead'
import StudentReportPackStudentHeader from './StudentReportPackStudentHeader'
import { paginateDataForPreview } from '../../../../utils/scoresheet/paginateDataForPreview'

class printPackStudent extends React.Component {
  constructor(props) {
    super(props)
    this.renderPreviewObject = this.renderPreviewObject.bind(this)
  }

  renderPreviewObject(previewObject, responseTable, responseIdentifier, reportPackSettings, highlightCorrect, showResponses, index) {
    if (previewObject.type === 'setFooter') {
      return (
        <div className='reportPackSetFooter' key={`${index}-${previewObject.poll.id}`} />
      )
    }

    const { poll, questionCounter, parentPoll } = previewObject
    const {
      showQuestionNumbers,
      showImages,
      showResponseCapturedDate,
    } = reportPackSettings
    const { showQuestionGrades } = this.props

    if (poll) {
      const response = poll[responseTable][responseIdentifier]
      return (
        <Question
          showQuestionGrades={showQuestionGrades}
          showQuestionNumbers={showQuestionNumbers}
          showImages={showImages}
          showResponseCapturedDate={showResponseCapturedDate}
          highlightCorrect={highlightCorrect}
          key={parentPoll ? `${parentPoll}-${poll.id}` : `${poll.id}`}
          questionCounter={questionCounter}
          question={poll.snapshot}
          response={response}
          showResponses={showResponses}
          isSurvey={poll.correctPercent === -1}
        />
      )
    } return null
  }

  render() {
    const {
      student,
      studentPolls,
      guest,
      isGuest,
      showStudentScores,
      studentTotals,
      totalPollCount,
      highlightCorrect,
      showResponses,
      reportPackSettings,
      ghostHeights,
      paperSize,
    } = this.props

    let aggregatedResponseTable
    let responseTable
    let responseIdentifier
    let name
    if (!isGuest) {
      responseTable = 'responsesByStudent'
      aggregatedResponseTable = 'aggregatedResponsesByStudent'
      responseIdentifier = student.id
      name = `${student.firstName} ${student.lastName}`
    }
    if (isGuest) {
      aggregatedResponseTable = 'aggregatedResponsesByGuest'
      responseIdentifier = guest
      name = guest
      responseTable = 'responsesByGuest'
    }

    const PrintCSSShowPortalOnly = () => (
      <style type='text/css'>
        {'@media print{ #root, .App {display: none !important}}'}
      </style>
    )

    const PrintCSSLetterPageSize = () => (
      <style type='text/css'>
        {'@media print{@page {size: 8.5in 11in; size: portrait}}'}
      </style>
    )

    const PrintCSSA4PageSize = () => (
      <style type='text/css'>
        {'@media print{@page {size: 210mm 297mm}}'}
      </style>
    )

    const {
      columnCount,
      // paperSize,
    } = reportPackSettings

    const paginatedData = paginateDataForPreview(studentPolls.polls, ghostHeights, columnCount)

    if (columnCount === 1) {
      return (
        <React.Fragment>
          {Object.keys(paginatedData).map((key) => {
            const pageData = paginatedData[key]['column-1']
            return (
              <div key={key} className={`printPack-page${key === '0' ? ' printPack-page--firstPage' : ''}`}>
                {paperSize === 'a4' &&
                <PrintCSSA4PageSize />}

                {paperSize === 'letter' &&
                <PrintCSSLetterPageSize />}

                <PrintCSSShowPortalOnly />

                {key === '0' &&
                <StudentReportPackStudentHeader personalizedContentLabel={this.props.personalizedContentLabel} totalPollCount={totalPollCount} studentName={name} showStudentScores={showStudentScores} dateRangeLabel={this.props.dateRangeLabel} studentTotals={studentTotals} />}

                {key !== '0' &&
                <PageHead currentPage={parseInt(key, 10) + 1} totalPages={Object.keys(paginatedData).length} studentName={name} dateRangeLabel={this.props.dateRangeLabel} />}

                <div className='printPack-page-pageColumn'>

                  {pageData.map((previewObject) => {
                    if (previewObject.type === 'setHeader') {
                      const { poll, setCounter } = previewObject
                      let score = '-'
                      if (showStudentScores) {
                        if (poll[aggregatedResponseTable][responseIdentifier]) {
                          const totalAggregatedResponses = poll[aggregatedResponseTable][responseIdentifier].totalResponses
                          if (totalAggregatedResponses !== 0) {
                            score = 100 * (poll[aggregatedResponseTable][responseIdentifier].totalCorrect / totalAggregatedResponses)
                          } else if (totalAggregatedResponses === 0 && poll[aggregatedResponseTable][responseIdentifier].totalSurveyResponses > 0) {
                            score = -1
                          }
                        }
                      }
                      return (
                        <SetHeader
                          setName={poll.snapshot.name}
                          score={score}
                          showQuestionNumbers={reportPackSettings.showQuestionNumbers}
                          key={poll.id}
                          setCounter={setCounter}
                          showStudentScores={showStudentScores}
                        />
                      )
                    }

                    return this.renderPreviewObject(previewObject, responseTable, responseIdentifier, reportPackSettings, highlightCorrect, showResponses)
                  })}
                </div>
              </div>
            )
          })}
        </React.Fragment>
      )
    }
    if (columnCount === 2) {
      return (
        <React.Fragment>
          {Object.keys(paginatedData).map((key) => {
            const pageData1 = paginatedData[key]['column-1']
            const pageData2 = paginatedData[key]['column-2']
            return (
              <div key={key} className={`printPack-page${key === '0' ? ' printPack-page--firstPage' : ''}`}>

                {paperSize === 'a4' &&
                <PrintCSSA4PageSize />}

                {paperSize === 'letter' &&
                <PrintCSSLetterPageSize />}

                <PrintCSSShowPortalOnly />

                {key === '0' &&
                <StudentReportPackStudentHeader personalizedContentLabel={this.props.personalizedContentLabel} totalPollCount={totalPollCount} studentName={name} showStudentScores={showStudentScores} dateRangeLabel={this.props.dateRangeLabel} studentTotals={studentTotals} />}

                {key !== '0' &&
                <PageHead currentPage={parseInt(key, 10) + 1} totalPages={Object.keys(paginatedData).length} studentName={name} dateRangeLabel={this.props.dateRangeLabel} />}

                <div className='printPack-page-pageColumn printPack-page-pageColumn--left'>
                  {pageData1.map((previewObject) => {
                    if (previewObject.type === 'setHeader') {
                      const { poll, setCounter } = previewObject
                      let score = '-'
                      if (showStudentScores) {
                        if (poll[aggregatedResponseTable][responseIdentifier]) {
                          const totalAggregatedResponses = poll[aggregatedResponseTable][responseIdentifier].totalResponses
                          if (totalAggregatedResponses !== 0) {
                            score = 100 * (poll[aggregatedResponseTable][responseIdentifier].totalCorrect / totalAggregatedResponses)
                          } else if (totalAggregatedResponses === 0 && poll[aggregatedResponseTable][responseIdentifier].totalSurveyResponses > 0) {
                            score = -1
                          }
                        }
                      }
                      return (
                        <SetHeader
                          setName={poll.snapshot.name}
                          score={score}
                          key={poll.id}
                          setCounter={setCounter}
                          showQuestionNumbers={reportPackSettings.showQuestionNumbers}
                          showStudentScores={showStudentScores}
                        />
                      )
                    }

                    return this.renderPreviewObject(previewObject, responseTable, responseIdentifier, reportPackSettings, highlightCorrect, showResponses)
                  })}
                </div>

                <div className='printPack-page-pageColumn printPack-page-pageColumn--right'>
                  {pageData2 && pageData2.map((previewObject) => {
                    if (previewObject.type === 'setHeader') {
                      const { poll, setCounter } = previewObject
                      let score = '-'
                      if (showStudentScores) {
                        if (poll[aggregatedResponseTable][responseIdentifier]) {
                          const totalAggregatedResponses = poll[aggregatedResponseTable][responseIdentifier].totalResponses
                          if (totalAggregatedResponses !== 0) {
                            score = 100 * (poll[aggregatedResponseTable][responseIdentifier].totalCorrect / totalAggregatedResponses)
                          } else if (totalAggregatedResponses === 0 && poll[aggregatedResponseTable][responseIdentifier].totalSurveyResponses > 0) {
                            score = -1
                          }
                        }
                      }
                      return (
                        <SetHeader
                          setName={poll.snapshot.name}
                          score={score}
                          key={poll.id}
                          setCounter={setCounter}
                          showStudentScores={showStudentScores}
                          showQuestionNumbers={reportPackSettings.showQuestionNumbers}
                        />
                      )
                    }

                    return this.renderPreviewObject(previewObject, responseTable, responseIdentifier, reportPackSettings, highlightCorrect, showResponses)
                  })}
                </div>
              </div>
            )
          })}
        </React.Fragment>
      )
    } return null
  }
}

export default printPackStudent

import React from 'react'
import moment from 'moment'
import find from 'lodash/find'
import includes from 'lodash/includes'
import {isSet} from '../../utils/isSet'
import HistoryCard from './HistoryCard'
import Button from '../uiKit/Button'

class HistoryListHeaderRow extends React.PureComponent{
	render(){
		return(
			<div  className='historyCardGroup-dateHeader'>
				{this.props.headerText}
			</div>
		)
	}
}

class HistoryList extends React.Component{

	constructor(){
		super()
		this.itemRenderer = this.itemRenderer.bind(this)
		this.showMore = this.showMore.bind(this)
		this.state = {
			submitLoading:false,
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.orderedPolls.length !== prevProps.orderedPolls.length) {
			this.setState({submitLoading:false})
		}
	}

	itemSizeGetter(index){
		const height = 140
		return height
	}

	calculateListSectionTitle(date){
		const today = moment().startOf('day')
		const week = moment().startOf('isoWeek')
		const year=moment().startOf('yeah')
		let dateTitle
		if (moment(date).isSame(today,'d')){
			dateTitle='Today'
		}else if (moment(date).isSame(week,'isoWeek')){
			dateTitle='This Week'
		}else if (moment(date).isSame(year,'year')){
			dateTitle=moment(date).format('MMMM')
		}else{
			dateTitle=moment(date).format('MMMM YYYY')
		}
		return dateTitle
	}

	showMore(){
		this.setState({submitLoading:true})
		this.props.showMore()
	}

	itemRenderer(poll,index){ //TODO could clean this up into a component
		const section=find(this.props.sections, {'id':poll.section})
		const isSectionHeader=includes(this.props.firstItemInDateGroupIds, poll.id)			
		if(isSectionHeader && this.props.filterTerm===''){ //only show headers when data is not filtered
			const headerText=this.calculateListSectionTitle(poll.minCaptured)
			if(poll.snapshot && section){		
				return[
					<HistoryListHeaderRow headerText={headerText} key={`${poll.id}_header`}/>,
					<HistoryCard 
						poll={poll}
						isSet={isSet(poll)}
						key={poll.id}
						section={section}	
						isSectionHistory={this.props.isSectionHistory}
						filterTerm={this.props.filterTerm}							
					/>	
				]}
				else return (
					<HistoryListHeaderRow headerText={headerText} key={`${poll.id}_header`}/>
				)
		}else if(poll.snapshot && section){		
			return(
				<HistoryCard 
					poll={poll}
					isSet={isSet(poll)}
					key={poll.id}
					section={section}	
					isSectionHistory={this.props.isSectionHistory}
					filterTerm={this.props.filterTerm}							
				/>	
			)
		} else return ( //TODO replace this with a loading state 
			<div style={{height:'140px', width:'100%', background: 'rgba(255,0,0,0.25', outline: '1px solid rgb(255,0,0)'}} key={index}>
			Missing Snapshot or section <br/>
			poll.id:{poll.id}
			</div>
		)
	}

	render(){
		const {orderedPolls,totalPolls}=this.props
		return(
			<div className='historyList'>
				{orderedPolls.map((poll,index)=>this.itemRenderer(poll,index))}
				{orderedPolls.length < totalPolls &&
				<div className='historyList-loadMoreBtnContainer' >
					<Button disabled={this.state.submitLoading} label='Show more' onClickFunction={this.showMore} size='large' />
				</div>
				}
			</div>
		)
	}
}

export default HistoryList
import React from 'react'
import { Spring, animated } from 'react-spring/renderprops' 

const windowZoomItemInternalPadding = 20 // PASS IN FROM SLIDE DEFINITIONS

function calculateItemLayout(layout){
	let itemStyle={}
	const fitImageZoomElement = document.getElementById('fitAnimatedGIFZoomable')
	let rect
  if(fitImageZoomElement){
    rect = fitImageZoomElement.getBoundingClientRect()
  }
  let itemStartZoomWidth = 0
  let itemStartZoomHeight = 0
  let itemStartZoomX = 0
  let itemStartZoomY = 0

  if(rect){
    itemStartZoomWidth = rect.width
    itemStartZoomHeight = rect.height
    itemStartZoomX = rect.x
    itemStartZoomY = rect.y
  }
	// VIEWPORT DIMENSIONS
	const viewportWidth = layout.viewportWidth
	const viewportHeight = layout.viewportHeight
	const viewportAspect = viewportWidth / viewportHeight
  // // START ZOOM ITEM DIMENSIONS
	// Find position of original on-slide video container
	const itemAspect = itemStartZoomWidth / itemStartZoomHeight
	// END ZOOM ITEM DIMENSIONS    
  // find maximum available width and height
  const itemEndZoomMaxWidth = viewportWidth - windowZoomItemInternalPadding - windowZoomItemInternalPadding
 	const itemEndZoomMaxHeight = viewportHeight - windowZoomItemInternalPadding - windowZoomItemInternalPadding

 	// find actual fitting item width and height
 	let itemEndZoomWidth 
  let itemEndZoomHeight

  if(viewportAspect >= itemAspect){ // screen wider than item
    itemEndZoomHeight = itemEndZoomMaxHeight
    itemEndZoomWidth = itemEndZoomHeight * itemAspect
  }
  if(viewportAspect < itemAspect){ // screen narrower than item
    itemEndZoomWidth = itemEndZoomMaxWidth
    itemEndZoomHeight = itemEndZoomWidth / itemAspect
  }
  // find end-state X and Y, this is to viewport
  const itemEndZoomX = (viewportWidth - itemEndZoomWidth) / 2
	const itemEndZoomY = (viewportHeight - itemEndZoomHeight) / 2
  // ZOOM START --> ZOOM END
  // SCALE FACTOR
  const itemEndZoomScale = itemEndZoomWidth / itemStartZoomWidth
  // TRANSFORM ORIGIN (this effectively translates position of slide)
  const transformOriginX = (itemStartZoomX - itemEndZoomX) / (itemEndZoomWidth - itemStartZoomWidth)
  const transformOriginY = (itemStartZoomY - itemEndZoomY) / (itemEndZoomHeight - itemStartZoomHeight)
  
  // STYLING IS FIXED
  // zooming also repositions slide through transform origin location

	itemStyle = {
		width: `${itemStartZoomWidth}px`, 
		height: `${itemStartZoomHeight}px`,
		left: `${itemStartZoomX}px`,
		top: `${itemStartZoomY}px`,							
		transformOrigin: `${transformOriginX * 100}% ${transformOriginY * 100}%`,
	}	
	return {
		style:itemStyle,
		endZoomScale:itemEndZoomScale
		}
	}







class LiveViewPromptAnimatedGIF extends React.Component{

	constructor(props){
		super()
		this.calculateLayout=this.calculateLayout.bind(this)		
		this.state={
			itemStyle:{},
			itemEndZoomScale:0
	   }
	}

	componentDidMount(){
		this.calculateLayout()
	}

	componentDidUpdate(prevProps) {
	  if ((this.props.layoutKey !== prevProps.layoutKey) || (this.props.showStudentList!==prevProps.showStudentList)) {
	    this.calculateLayout()
	   }
	}

	calculateLayout(){		
		const itemLayout=calculateItemLayout(this.props.nowPlayingLayout)
		this.setState({itemStyle:itemLayout.style,itemEndZoomScale:itemLayout.endZoomScale})
	}


	render(){		

		const {promptMediaZoomed, animationDisabled, isLastZoomedItem} = this.props 		
		const {itemStyle,itemEndZoomScale}=this.state

		const moveItemBelowDarkBG = !isLastZoomedItem

		return(			
			<React.Fragment>
				<Spring
          //onRest={this.zoomAnimationFinished}
          native                  
          from={{ transform: 'scale(1)' }}
          to={{ transform: (promptMediaZoomed && itemEndZoomScale) ? `scale(${itemEndZoomScale})` : 'scale(1)'}}          
          immediate={animationDisabled}
        >
          {props => 
            <animated.div onClick={this.props.toggleZoomMedia} style={{...itemStyle, ...props}}  className={'liveView-mediaItem liveView-mediaItem--animatedGIF ' + (isLastZoomedItem ? ' liveView-mediaItem--isLastZoomedItem ' : '') + (moveItemBelowDarkBG ? ' liveView-mediaItem--moveItemBelowDarkBG ' : '')}>              

              <picture className='liveView-mediaItem--fitAnimatedGIF-GIF'> 
			            <source draggable="false" type="image/webp" srcSet={this.props.GIF.webpUrl} />            
			            <img draggable="false" src={this.props.GIF.gifUrl} alt=''/>            
			          </picture>
              	                 
            </animated.div>
          }
        </Spring>		
			</React.Fragment>

		)
	}
}


export default LiveViewPromptAnimatedGIF


export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_SYMBOLS_LIST_SUCCESS':
		return action.response

	case 'LOGOUT':
		return null
	
	default:
		return state
	}
}
module.exports ={
		marker1:{
			name:'1',
			x:777,
			y:145,
			inImageContainerSafeArea:false,
			inCropSafe:false
		},
		marker2:{
			name:'2',
			x:777,
			y:185,
			inImageContainerSafeArea:false,
			inCropSafe:false
		},
		marker3:{
			name:'3',
			x:777,
			y:225,
			inImageContainerSafeArea:false,
			inCropSafe:false
		},
		marker4:{
			name:'4',
			x:777,
			y:265,
			inImageContainerSafeArea:false,
			inCropSafe:false
		},
		marker5:{
			name:'5',
			x:777,
			y:305,
			inImageContainerSafeArea:false,
			inCropSafe:false
		}
	}
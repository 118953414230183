import React from 'react'
import ReactDOM from 'react-dom'
import QuestionAnimatedGIFReactComponent from './QuestionAnimatedGIFReactComponent'
import {showInsertGIFModal} from '../../utils/showInsertGIFModal'
import {generateRandQuestionId} from '../../../../utils/generateRandQuestionId'
import {updateGIFNodeWithSavedCloudinaryValues} from '../../../../prosemirror/utils/editorActions/addAnimatedGIF'

function handleMouseDown(e){
	e.stopPropagation()
	if(e.target === e.currentTarget){
		e.preventDefault() 
		e.stopPropagation()
	} 
}

class QuestionAnimatedGIFNodeView {

	constructor(node, view, getPos) {
		this.getNode=this.getNode.bind(this)
		this.setImageFillType=this.setImageFillType.bind(this)
		this.showReplaceGIFModal=this.showReplaceGIFModal.bind(this)
		this.node = node
		this.getPos=getPos
		this.dom = document.createElement("div")
		this.dom.className="slide-mediaContainer slide-mediaContainer--gif"
		this.dom.addEventListener('mousedown',handleMouseDown)

		const {
			fileId,
			host,
			stillUrl,
			webpUrl,
			gifUrl,
			template,
			dimensions,
			questionMediaDynamicHeight,
			imageFill,
			placeholder,
			giphyUser,
			nodeId,
			slideWidth} = node.attrs
   
		if(!fileId){ //check if image has been uploaded to cloudinary (undo/redo while in placeholder state)
			setTimeout(function() { //Start the timer
			updateGIFNodeWithSavedCloudinaryValues(node.attrs.nodeId)
			},  500)
		}

		this.randId=generateRandQuestionId()

		ReactDOM.render(
			<QuestionAnimatedGIFReactComponent 
				key={fileId}
				stillUrl={stillUrl}
				nodeId={nodeId}
				gifUrl={gifUrl}
				webpUrl={webpUrl}
				template={template}
				questionMediaDynamicHeight={questionMediaDynamicHeight}
				dimensions={dimensions}
				setImageFillType={this.setImageFillType}
				imageFill={imageFill}
				host={host}
				placeholder={placeholder}
				giphyUser={giphyUser}
				showReplaceGIFModal={this.showReplaceGIFModal}
				fileId={fileId}
				slideWidth={slideWidth}
			/>, this.dom)
		}


	getNode(){
		return this.node
	}

	setImageFillType(fillType){
		let imageFill=false
		if(fillType==='fill'){
			imageFill=true
		}
		const node=this.getNode()
		const nodePos=this.getPos(node)
		const attributes={...node.attrs,imageFill:imageFill}
		var tr = window.view.state.tr
		tr.setMeta("triggerParseDoc", true) 
		tr.setNodeMarkup(nodePos, null, {...attributes})
		window.view.dispatch(tr)
	}

	showReplaceGIFModal(){
		let questionPos=this.getPos()
		let questionNode=this.getNode()
		let insertPos= {
			start:questionPos,
			end:questionPos+questionNode.nodeSize
		}
		const isQuestion=true
		showInsertGIFModal(insertPos,isQuestion,questionPos,questionNode)
	}

	update(node) {
		if (node.type !== this.node.type) return false
		const {
			fileId,
			stillUrl,
			gifUrl,
			webpUrl,
			template,
			questionMediaDynamicHeight,
			dimensions,
			imageFill,
			giphyUser,
			host,
			placeholder,
			nodeId,
			slideWidth
		} = node.attrs
		const oldTemplate=this.node.attrs.template
		const oldQuestionMediaDynamicHeight=this.node.attrs.questionMediaDynamicHeight
		const oldPlaceholder=this.node.attrs.placeholder
		const oldGifUrl=this.node.attrs.girUrl
		const oldImageFill=this.node.attrs.imageFill
		const oldFileId=this.node.attrs.fileId
		const oldSlideWidth=this.node.attrs.slideWidth
		if(
			fileId !== oldFileId ||
			template !==oldTemplate || 
			questionMediaDynamicHeight!==oldQuestionMediaDynamicHeight || 
			placeholder !== oldPlaceholder ||
			gifUrl !== oldGifUrl ||
			imageFill !== oldImageFill ||
			slideWidth !== oldSlideWidth
		){
			if(!fileId){
			updateGIFNodeWithSavedCloudinaryValues(node.attrs.nodeId)
		}


		ReactDOM.render(
			<QuestionAnimatedGIFReactComponent 
				key={fileId}
				stillUrl={stillUrl}
				nodeId={nodeId}
				gifUrl={gifUrl}
				webpUrl={webpUrl}
				template={template}
				questionMediaDynamicHeight={questionMediaDynamicHeight}
				dimensions={dimensions}
				setImageFillType={this.setImageFillType}
				imageFill={imageFill}
				host={host}
				placeholder={placeholder}
				giphyUser={giphyUser}
				showReplaceGIFModal={this.showReplaceGIFModal}
				fileId={fileId}
				slideWidth={slideWidth}
			/>, this.dom)
		}
		this.node=node
		return true
	}

	destroy() {
		this.dom.removeEventListener('mousedown',handleMouseDown)
	}

}

export default QuestionAnimatedGIFNodeView
import React from 'react'
import AppleLogin from 'react-apple-login'
import Icon from './Icons'

function SignInWithAppleBtnRender(props) {
  const { appleLogin, text } = props
  let buttonText = 'Sign in with Apple'
  if (text === 'signup_with') {
    buttonText = 'Sign up with Apple'
  } else if (text === 'continue_with') {
    buttonText = 'Continue with Apple'
  }

  return (
    <button
      onClick={appleLogin}
      className='signin-signInWithAppleBtn'
    >
      <div className='signin-signInWithAppleBtn-centerContent'>
        <div className='signin-signInWithAppleBtn-centerContent-icon'>
          <Icon name='appleSignInLogo' />
        </div>
        <div className='signin-signInWithAppleBtn-centerContent-label'>
          {buttonText}
        </div>
      </div>
    </button>
  )
}

function SignInWithAppleButton(props) {
  const { callback, redirectPath } = props
  return (
    <AppleLogin
      clientId={process.env.REACT_APP_SIGN_IN_WITH_APPLE_CLIENT_ID}
      redirectURI={`${window.location.origin}${redirectPath}`}
      usePopup
      callback={(data) => callback(data)}
      scope='email name'
      responseType='query'
      render={(props) => (<SignInWithAppleBtnRender appleLogin={props.onClick} />)}
    />
  )
}

export default SignInWithAppleButton

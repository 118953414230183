import React from 'react'
import RichStaticSlide from '../richStaticSlide/RichStaticSlide'

//Component from pre Rich Text (kept to easily switch between RichStaticSlide an old static slide component)
//Can probably remove this component/replace with richStaticSlide component

class QuestionSlide extends React.Component{
	render(){
		const {question,displayWidth} = this.props			
			if(question && question.bodyHtml){
			return(
				<RichStaticSlide
				{...this.props}
					question={question}
					slideWidth={displayWidth}
					xPosSlide={this.props.xPosSlide} // used for zooming positioning, taken in from Now Playing
					yPosSlide={this.props.yPosSlide} // used for zooming positioning, taken in from Now Playing
					mediaIsPlayable={this.props.mediaIsPlayable} // defaults to false
					mediaIsZoomable={this.props.mediaIsZoomable} // defaults to false
					clientPlaybackCommand={this.props.clientPlaybackCommand}
					zoomMedia={this.props.zoomMedia}
					mediaZoomed={this.props.mediaZoomed}
					choiceMediaZoomed={this.props.choiceMediaZoomed}
					activeZoomedChoiceIndex={this.props.activeZoomedChoiceIndex}
					showCorrect={this.props.showCorrect} // Used for review screen show correct. Also for teacher facing situations, show correct answer, e.g. show previewModal.
					viewportWidth={this.props.viewportWidth} // Used for media zooming positioning
					viewportHeight={this.props.viewportHeight} // Used for media zooming positioning
					// REPORTING
					processedResponses={this.props.processedResponses}
					expectedResponses={this.props.expectedResponses}
					totalResponses={this.props.totalResponses}
					// maybe refactor into new view-type state?
					reportView={this.props.reportView}
					nowPlayingView={this.props.nowPlayingView}
					hideSoundTitles={this.props.hideSoundTitles}
				/>
			)
		}else return null
	}
}

export default QuestionSlide
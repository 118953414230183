import React from 'react'
import { ContextMenu, MenuItem ,SubMenu} from 'react-contextmenu'
import SectionMarker from '../uiKit/SectionMarker'
import Icon from '../misc/Icons'
import {isItemLocked} from '../../utils/isItemLocked'

const SUBMENU_HOVER_DELAY=0
	
 

class SetAndQuestionContextMenu extends React.Component{
	render(){
		const {
			folders,
			sections,
			onShowContextMenu,
			onHideContextMenu,
			handleContextMenuClick,
			contextMenuObject,
			isViewOnlyRepo}=this.props
		let isSet=false
		let isViewOnly=false
		let itemLocked=false
		if(contextMenuObject){
			isViewOnly=contextMenuObject.readOnly || isViewOnlyRepo
			if(contextMenuObject.questions){
				isSet=true
			}
			if(contextMenuObject.repo){
				itemLocked= isItemLocked(contextMenuObject.id,contextMenuObject.repo)
			}
		}
		return(
			<ContextMenu id='ITEMS' className='contextMenu' onShow={onShowContextMenu} onHide={onHideContextMenu}>  
				
				{!isViewOnly &&
					<React.Fragment>
						<MenuItem disabled={isViewOnly} onClick={handleContextMenuClick} data={{action: 'Open'}}>			    
							Edit	    
						</MenuItem>
								
						{isSet &&
							<MenuItem disabled={itemLocked} onClick={handleContextMenuClick} data={{action: 'Rename'}}>			    
								Rename...
							</MenuItem>
						}
				
						<MenuItem divider/>
					</React.Fragment>
				}

				{sections.length < 8 &&
					<SubMenu title='Play Now' hoverDelay={SUBMENU_HOVER_DELAY}>
						{sections.map((section,index) =>
							<MenuItem  key={`play_${index}`} onClick={handleContextMenuClick} data={{ action: 'Play', section:section }}>
								<SectionMarker section={section} />
								<Icon name='play' />
								<SectionMarker section={section} type={'colorOnly'} />
							</MenuItem>
						)}	  			
					</SubMenu>
				}
			 
				{sections.length >= 8 &&
					<MenuItem hoverDelay={SUBMENU_HOVER_DELAY} onClick={handleContextMenuClick} data={{ action: 'Play' }}>
						Play Now
					</MenuItem>
				}

				{sections.length < 8 &&
					<SubMenu title='Add to Queue' hoverDelay={SUBMENU_HOVER_DELAY}>
						{sections.map((section,index) =>      				
							<MenuItem  key={`queue_${index}`} onClick={handleContextMenuClick} data={{ action: 'Queue', section:section }}>
								<SectionMarker section={section} />					
								<Icon name='plus' />
								<SectionMarker section={section} type={'colorOnly'} />
							</MenuItem>
						)}
					</SubMenu>
				}
				
				{!isViewOnly && 
					<React.Fragment>
				
						<MenuItem divider />
					
						<MenuItem disabled={isViewOnly} attributes={{className:`${isViewOnly ?'is--disabled':''}`}} hoverDelay={SUBMENU_HOVER_DELAY} preventClose={true} data={{ action: 'MoveToRepo'}} onClick={handleContextMenuClick} >
								Move to Pack...
						</MenuItem>		

						<MenuItem divider />

						{folders.length!==0 && folders.length < 8 &&
							<SubMenu title='Move to Folder' disabled={isViewOnly} attributes={{className:'is--subMenuHeader'}} className='contextMenu-subMenu--folder' hoverDelay={SUBMENU_HOVER_DELAY}>
								{folders.map((folder,index) =>{
									if(!folder.readOnly){
										return(
											<MenuItem key={`move_${index}`} onClick={handleContextMenuClick} data={{ action: 'MoveToFolder', folder:folder }}>{folder.name}</MenuItem>          
										)
									}
									else return null
								}
								)}
								<MenuItem onClick={handleContextMenuClick} data={{ action: 'Move'}}>Select Folder...</MenuItem>
							</SubMenu>
						}

						{(folders.length >= 8 || folders.length ===0) &&						
							<MenuItem disabled={isViewOnly} attributes={{className:`${isViewOnly ?'is--disabled':''}`}} hoverDelay={SUBMENU_HOVER_DELAY} preventClose={true} data={{ action: 'Move'}} onClick={handleContextMenuClick} >
								Move to Folder...
							</MenuItem>					
						}
				
				</React.Fragment>

				}
				
				{isViewOnly &&
					<MenuItem divider />
				}
			

				{isViewOnly &&
					<MenuItem onClick={handleContextMenuClick} data={{action: 'CopyToLibrary'}}>			    
						Add Copy to Library
					</MenuItem>
				}

				<MenuItem  onClick={handleContextMenuClick} data={{action: 'ItemDetail'}}>			    
					Detail View		    
				</MenuItem>

				{!isViewOnly &&
					<MenuItem onClick={handleContextMenuClick} data={{action: 'Copy'}}>			    
						Duplicate
					</MenuItem>
				}
				
				{!isSet && !isViewOnly &&
					<MenuItem onClick={handleContextMenuClick} data={{action: 'CopyToClipboard'}}>			    
						Copy to Clipboard    
					</MenuItem>
				}

				{!isViewOnly && 
					<MenuItem divider />   
				}

				{!isViewOnly &&
					<MenuItem attributes={{className: 'is--warning'}}  onClick={handleContextMenuClick} data={{action: 'Archive'}}>			    
						Send to Trash
					</MenuItem>
				}

			</ContextMenu>
		)
	}
}


export default SetAndQuestionContextMenu
import React from 'react'
import { withRouter } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import { ContextMenu, MenuItem } from 'react-contextmenu'
import StickyDividerWrapper from '../../uiKit/StickyDividerWrapper'
import Icon from '../../misc/Icons'
import StudentCell from './StudentCell'
import ArchivedStudentCell from './ArchivedStudentCell'
import Button from '../../uiKit/Button'
import CardAssignmentSelectMenu from './CardAssignmentSelectMenu'
import Dropdown from '../../uiKit/Dropdown'
import DropdownItem from '../../uiKit/DropdownItem'
import TopPageHeader from '../../pageHeaders/TopPageHeader'
import StickyPageHeader from '../../pageHeaders/StickyPageHeader'
import PageIcon from '../../misc/PageIcons'

class SectionStudents extends React.Component {
  constructor(props) {
    super(props)
    this.addNewStudent = this.addNewStudent.bind(this)
    this.handleInputFieldChange = this.handleInputFieldChange.bind(this)
    this.inputFieldkeyUp = this.inputFieldkeyUp.bind(this)
    this.setActiveStudent = this.setActiveStudent.bind(this)
    this.archiveStudent = this.archiveStudent.bind(this)
    this.editStudentName = this.editStudentName.bind(this)
    this.keyPressNavigation = this.keyPressNavigation.bind(this)
    this.showActiveStudents = this.showActiveStudents.bind(this)
    this.showArchivedStudents = this.showArchivedStudents.bind(this)
    this.deleteStudent = this.deleteStudent.bind(this)
    this.handleContextMenuClick = this.handleContextMenuClick.bind(this)
    this.onShowContextMenu = this.onShowContextMenu.bind(this)
    this.onHideContextMenu = this.onHideContextMenu.bind(this)

    this.state = {
      addStudentInputValue: '',
      students: [],
      sortedBy: 'firstName',
      sortOrder: 'descending',
      activeStudent: null,
      contextMenuActiveStudent: null,
    }
  }

  componentDidMount() {
    if (window.analytics) {
      window.analytics.page('Class', { subpage: 'Students', sectionId: this.props.currentSection.id, title: this.props.currentSection.name })
    }
    this.handleSortStudents(this.state.sortedBy, this.state.sortOrder, this.props.students)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.students !== this.props.students) {
      this.handleSortStudents(this.state.sortedBy, this.state.sortOrder, nextProps.students)
    }
  }

  handleInputFieldChange(e) { // quick add student input field
    this.setState({ addStudentInputValue: e.target.value })
  }

  inputFieldkeyUp(e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      this.addNewStudent()
    }
  }

  handleContextMenuClick(e, data) {
    if (data.action === 'Edit') {
      this.editStudentName(data.student)
    } else if (data.action === 'Archive') {
      this.props.archiveStudent(data.student)
      if (data.student.id === this.state.activeStudent) {
        this.setState({ activeStudent: null })
      }
    } else if (data.action === 'Delete') {
      this.deleteStudent(data.student)
    }
  }

  addNewStudent() {
    this.props.addNewStudent(this.state.addStudentInputValue)
    this.setState({ addStudentInputValue: '' })
  }

  handleSortStudents(sortType, order, students) {
    students = students || this.props.students
    let sortedStudents
    let newSortOrder
    if (sortType !== 'card') {
      sortedStudents = sortBy(students, [(student) => student[`${sortType}`].toLowerCase()])
    } else {
      sortedStudents = sortBy(students, sortType)
    }
    if (!order) {
      if (sortType === this.state.sortedBy) {
        if (this.state.sortOrder === 'ascending') {
          newSortOrder = 'descending'
        } else if (this.state.sortOrder === 'descending') {
          newSortOrder = 'ascending'
        }
      } else { newSortOrder = 'descending' }
    } else { newSortOrder = order }

    if (newSortOrder === 'ascending') {
      sortedStudents = sortedStudents.reverse()
    }
    this.setState({ students: sortedStudents, sortOrder: newSortOrder, sortedBy: sortType })
  }

  setActiveStudent(student) { // for selecting row in student table
    if (student.id === this.state.activeStudent) {
      this.setState({ activeStudent: null })
    } else {
      this.setState({ activeStudent: student.id })
      this.ensureActiveStudentVisible(student.id)
    }
  }

  ensureActiveStudentVisible(itemId) { // if new active row is not on screen scroll into view
    const cellNode = document.getElementById(itemId)
    if (cellNode) {
      const cellRect = cellNode.getBoundingClientRect()
      const offset = 130 // for header
      // check if need to scroll it
      if (!(cellRect.top >= offset && cellRect.bottom <= window.innerHeight)) {
        cellNode.scrollIntoView({ block: 'end', behavior: 'instant' })
      }
    }
  }

  archiveStudent() {
    const student = find(this.props.students, { id: this.state.activeStudent }) || find(this.props.archivedStudents, { id: this.state.activeStudent })
    this.setState({ activeStudent: null })
    this.props.archiveStudent(student)
  }

  deleteStudent(student) {
    this.setState({ activeStudent: null })
    this.props.deleteStudent(student)
  }

  editStudentName(student) {
    if (!this.props.sectionIsIntegrationRostered) {
      this.props.showEditStudentModal(student, this.props.currentSection)
    }
  }

  keyPressNavigation(e) { // arrow keys to arrow through student table
    const { activeStudent, students } = this.state
    if (activeStudent) {
      if (e.keyCode === 40) { // down arrow
        e.preventDefault()// prevent scrolling container
        const indexOfActive = findIndex(students, (student) => student.id === activeStudent)
        const indexOfNewActive = Math.min(indexOfActive + 1, students.length - 1)
        const newActiveStudent = students[indexOfNewActive]
        if (indexOfNewActive !== indexOfActive) {
          this.setActiveStudent(newActiveStudent)
        }
      } else if (e.keyCode === 38) { // up arrow
        e.preventDefault()
        const indexOfActive = findIndex(students, (student) => student.id === activeStudent)
        const indexOfNewActive = Math.max(indexOfActive - 1, 0)
        const newActiveStudent = students[indexOfNewActive]
        if (indexOfNewActive !== indexOfActive) {
          this.setActiveStudent(newActiveStudent)
        }
      }
    }
  }

  showArchivedStudents() {
    this.setState({ activeStudent: null })
    this.props.showArchivedStudents()
  }

  showActiveStudents() {
    this.setState({ activeStudent: null })
    this.props.showActiveStudents()
  }

  onShowContextMenu(e) {
    this.setState({ contextMenuActiveStudent: e.detail.data.student.id })
    document.body.style.overflowY = 'hidden'
  }

  onHideContextMenu() {
    this.setState({ contextMenuActiveStudent: null })
    document.body.style.overflowY = '' // overlay is not supported by firefox, so default to blank (auto style comes from css)
    document.body.style.overflowY = 'overlay'
  }

  render() {
    const {
      showArchived, archivedStudents, availableCards, sectionIsIntegrationRostered, sectionIntegrationRosterEmail,
    } = this.props
    const {
      activeStudent, sortBy, sortOrder, addStudentInputValue,
    } = this.state
    let disableAddNewStudent = false
    if (availableCards.length === 0) {
      disableAddNewStudent = true
    }

    const activeStudentDetail = find(this.props.students, { id: activeStudent }) || find(this.props.archivedStudents, { id: activeStudent })

    return [
      <div className='page-centerPanel sectionStudents' key='centerPanel'>

        {!showArchived && (
        <TopPageHeader
          location='sectionStudents'
          height={217}
          leftBadge={<Icon name='chevron-left' />}
          leftBadgeClickFunction={this.props.navigateToSectionHome}
          header={this.props.currentSection.name}
          subHeader='Students'
          subHeaderBadge={<PageIcon name='studentLarge' />}
          sectionHeaderSectionColor={this.props.currentSection.color}
          actionRow={(
            <React.Fragment>
              {!sectionIsIntegrationRostered && (
              <React.Fragment>
                <div className={`addStudentBar${addStudentInputValue === '' ? ' is--empty' : ' is--nonEmpty'}`}>
                  <input
                    disabled={disableAddNewStudent}
                    type='text'
                    placeholder='Quick Add Student'
                    value={addStudentInputValue}
                    onChange={this.handleInputFieldChange}
                    onKeyUp={this.inputFieldkeyUp}
                  />

                  <div className='addStudentBar-extras'>
                    <Icon name='plus' />

                    <div className='addStudentBar-addBtn' onClick={this.addNewStudent}>
                      Add
                    </div>
                  </div>
                </div>

                <Button label='Add Students' disabled={disableAddNewStudent} onClickFunction={this.props.showBulkAddStudentsModal} />
              </React.Fragment>
              )}
              {sectionIsIntegrationRostered && (
              <React.Fragment>
                <div className='pageHeaderTop-actionRow-googleClassroomInfo'>
                  <div className='pageHeaderTop-actionRow-googleClassroomInfo-logo'>
                    <Icon name='googleClassroomLogo' />
                  </div>
                  <div className='pageHeaderTop-actionRow-googleClassroomInfo-left'>
                    <div className='pageHeaderTop-actionRow-googleClassroomInfo-left-primary'>
                      Google Classroom
                    </div>
                    <div className='pageHeaderTop-actionRow-googleClassroomInfo-accountEmail'>
                      {sectionIntegrationRosterEmail}
                    </div>
                  </div>

                </div>
                <div className='pageHeaderTop-actionRow-spacer' />
                <Button label='Sync Students' tooltipLabel='Update Plickers Roster from Google Classroom' tooltipLabelTypeLabel onClickFunction={() => { this.props.syncSectionWithIntegration() }} />
              </React.Fragment>
              )}

              <Button label='Print Class Roster' onClickFunction={() => { this.props.history.push(`/classRoster/${this.props.currentSection.id}`) }} />
              <Dropdown>
                <DropdownItem label='View Archived Students' onSelect={this.showArchivedStudents} />
                {sectionIsIntegrationRostered &&
                <DropdownItem label='Delink Google Classroom' onSelect={() => { this.props.removeIntegrationFromSection(this.props.currentSection.id) }} />}
              </Dropdown>
            </React.Fragment>
          )}
        />
        )}

        {!showArchived && (
        <StickyPageHeader
          location='sectionStudents'
          leftBadge={(
            <React.Fragment>
              <Icon name='chevron-left' />
              <PageIcon name='studentLarge' />
            </React.Fragment>
          )}
          leftBadgeClickFunction={this.props.navigateToSectionHome}
          header={this.props.currentSection.name}
          subHeader='Students'
          sectionHeaderSectionColor={this.props.currentSection.color}
        />
        )}

        {showArchived && (
        <TopPageHeader
          location='sectionArchivedStudents'
          height={203}
          leftBadge={<Icon name='chevron-left' />}
          leftBadgeClickFunction={this.showActiveStudents}
          header={this.props.currentSection.name}
          subHeader='Archived Students'
          subHeaderBadge={<PageIcon name='studentLarge' />}
          sectionHeaderSectionColor={this.props.currentSection.color}
        />
        )}

        {showArchived && (
        <StickyPageHeader
          location='sectionArchivedStudents'
          leftBadge={(
            <React.Fragment>
              <Icon name='chevron-left' />
              <PageIcon name='studentLarge' />
            </React.Fragment>
          )}
          leftBadgeClickFunction={() => { this.showActiveStudents() }}
          header={this.props.currentSection.name}
          subHeader='Archived Students'
          sectionHeaderSectionColor={this.props.currentSection.color}
        />
        )}

        {!showArchived && (
        <div className='students-table' tabIndex='99' onKeyDown={this.keyPressNavigation}>
          <StickyDividerWrapper className='sectionStudents'>
            <div className={`sortableColumnHeaders${sortBy ? ` is--sortedBy${sortBy} ` : ''}${sortOrder === 'ascending' ? ' is--sortedAscending' : ' is--sortedDescending'}`}>
              <div className='sortableColumnHeaders-firstName' onClick={() => { this.handleSortStudents('firstName') }}>
                First name
                <Icon name='chevron-small-up' />
                <Icon name='chevron-small-down' />
              </div>
              <div className='sortableColumnHeaders-lastName' onClick={() => { this.handleSortStudents('lastName') }}>
                Last name
                <Icon name='chevron-small-up' />
                <Icon name='chevron-small-down' />
              </div>
              <div className='sortableColumnHeaders-cardNo' onClick={() => { this.handleSortStudents('card') }}>
                Card No
                <Icon name='chevron-small-up' />
                <Icon name='chevron-small-down' />
              </div>
            </div>
          </StickyDividerWrapper>

          {this.state.students.map((student, index) => {
            const contextMenuIsOpen = (student.id === this.state.contextMenuActiveStudent)
            return (
              <StudentCell
                contextMenuIsOpen={contextMenuIsOpen}
                key={index}
                setActiveStudent={this.setActiveStudent}
                student={student}
                index={student.id}
                activeStudent={this.state.activeStudent}
                editStudentName={this.editStudentName}
              />
            )
          })}
        </div>
        )}

        {showArchived && (
        <div className='students-table' tabIndex='99' onKeyDown={this.keyPressNavigation}>
          <StickyDividerWrapper className='sectionStudents'>
            <div className='sortableColumnHeaders'>
              <div className='sortableColumnHeaders-firstName'>
                First name
              </div>
              <div className='sortableColumnHeaders-lastName'>
                Last name
              </div>
            </div>
          </StickyDividerWrapper>

          {archivedStudents.map((student, index) => (
            <ArchivedStudentCell
              disableAddNewStudent={disableAddNewStudent}
              archiveStudent={this.props.archiveStudent}
              deleteStudent={this.props.deleteStudent}
              key={index}
              setActiveStudent={this.setActiveStudent}
              student={student}
              index={student.id}
              activeStudent={this.state.activeStudent}
              sectionIsIntegrationRostered={sectionIsIntegrationRostered}
            />
          ))}
        </div>
        )}
      </div>,
      <div className='page-rightPanel' key='rightPanel'>

        {activeStudentDetail && (
        <div className='sidePanelContainer'>
          <div className='sidePanel'>
            <div className='studentSidePanel'>
              <div className='studentSidePanel-header'>
                <span className='studentSidePanel-header-firstName'>{activeStudentDetail.firstName}</span>
                {' '}
                <span className='studentSidePanel-header-lastName'>{activeStudentDetail.lastName}</span>
                {!activeStudentDetail.archived && (
                <div className='studentSidePanel-header-cardNo'>
                  {activeStudentDetail.card}
                </div>
                )}
                {!activeStudentDetail.archived &&
                <CardAssignmentSelectMenu cardsWithStudents={this.props.cardsWithStudents} activeStudent={activeStudentDetail} showConfirmEditStudentCardModal={this.props.showConfirmEditStudentCardModal} />}
              </div>

              {!activeStudentDetail.archived && !sectionIsIntegrationRostered && (
              <div>
                <Button color='blue' label='Edit Student Name' onClickFunction={() => { this.editStudentName(activeStudentDetail) }} />
                <Button label='Archive Student' onClickFunction={this.archiveStudent} />
                <Button label='Delete Student' onClickFunction={() => { this.deleteStudent(activeStudentDetail) }} />
              </div>
              )}
              {activeStudentDetail.archived && (
              <div>
                <Button disabled={disableAddNewStudent || sectionIsIntegrationRostered} label='Unarchive Student' onClickFunction={this.archiveStudent} />
                <Button label='Delete Student' onClickFunction={() => { this.deleteStudent(activeStudentDetail) }} />
              </div>
              )}
            </div>
          </div>
        </div>
        )}

        <ContextMenu id='STUDENT' className='contextMenu' onShow={this.onShowContextMenu} onHide={this.onHideContextMenu}>
          <MenuItem disabled={sectionIsIntegrationRostered} onClick={this.handleContextMenuClick} data={{ action: 'Edit' }}>
            Edit Student Name
          </MenuItem>

          <MenuItem disabled={sectionIsIntegrationRostered} onClick={this.handleContextMenuClick} data={{ action: 'Archive' }}>
            Archive Student
          </MenuItem>

          <MenuItem disabled={sectionIsIntegrationRostered} onClick={this.handleContextMenuClick} data={{ action: 'Delete' }}>
            Delete Student
          </MenuItem>

        </ContextMenu>

      </div>,
    ]
  }
}

export default withRouter(SectionStudents)


export default function (state = false, action) {
	switch (action.type) {

	case 'FETCH_REPOS_SUCCESS':

		return true

	case 'LOGOUT':
		return false
	
	default:
		return state
	}
}

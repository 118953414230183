import store from '../store'
import find from 'lodash/find'
import {fetchARepo} from '../actions/repos'

 
export function getRepoforId(repoId) {	
	if(repoId){
		const state = store.getState()
		const repo= find(state.repos, {'id':repoId})
		
		if(repo){
			return repo
		}else {	store.dispatch(fetchARepo(repoId))}
	}else return null

}

 
import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import EditorIcon from '../../../../../misc/EditorIcons'
import { Rnd } from "react-rnd"
import {DraggableLetterMarker,CropboxCornerHandle,CoverCornerHandle,CropboxLeftRightEdgeHandle,CropboxTopBottomEdgeHandle} from './EditorEditImageModalHandles'
import {setImageAttributes} from '../../../../../../prosemirror/utils/editorActions/setImageAttributes'
import calculateEditorCanvasImageDimensions from '../utils/calculateEditorCanvasImageDimensions'
import initialDefaultImageMarkers from '../defaultImageMarkers.js'
import initialDefaultImageCovers from '../defaultImageCovers.js'
import calculateEditImageCanvasImagePositioning from '../utils/calculateEditImageCanvasImagePositioning'
import EditorEditImageModalColorAdjustSlider from './EditorEditImageModalColorAdjustSlider'
import getTranslatedColorAdjustParams from '../utils/getTranslatedColorAdjustParams'
import {rotateMarkers,rotateCovers} from '../utils/rotateMarkersAndCovers'
import calculateInitialCropBoxDimensions from '../utils/calculateInitialCropBoxDimensions'
import calculateCoverCropAreaOverlap from '../utils/calculateCoverCropAreaOverlap'
import throttle from 'lodash/throttle'
import isEqual from 'lodash/isEqual'

const MARKER_SIZE = 40// width and height, in the editor UI only
const CROP_SNAP_MARGIN=10

// UNDO HISTORY
//const anyEdits = false // if any edits to original image, e.g. crop,flip,markers,rotate,etc.
const undoAvailable = false
const redoAvailable = false



class EditorEditImageModal extends React.Component{  

	constructor(props) {
		super(props)
		this.rotateImage = this.rotateImage.bind(this)
    this.flipImage = this.flipImage.bind(this)
    this.resetCropBox=this.resetCropBox.bind(this)
		this.saveChanges = this.saveChanges.bind(this)
		this.resetMarkersAndCovers = this.resetMarkersAndCovers.bind(this)
		this.onMarkerDragStop = this.onMarkerDragStop.bind(this)
		this.onMarkerDrag = this.onMarkerDrag.bind(this)
		this.isPositionInImageContainerSafeArea=this.isPositionInImageContainerSafeArea.bind(this)
		this.isPositionInCropSafeArea=this.isPositionInCropSafeArea.bind(this)
		this.resetMarkerPostion=this.resetMarkerPostion.bind(this)
		this.onCoverKeyDown=this.onCoverKeyDown.bind(this)
		this.onMarkerKeyDown=this.onMarkerKeyDown.bind(this)
		this.resetCover=this.resetCover.bind(this)
		this.onCoverDrag=this.onCoverDrag.bind(this)
    this.recalculateCoverCropAreaOverlaps=this.recalculateCoverCropAreaOverlaps.bind(this)
		this.onCoverResizeStop=this.onCoverResizeStop.bind(this)
    this.updateColorAdjustParams=this.updateColorAdjustParams.bind(this)
    this.updateMarkersCropSafeAreaParam=this.updateMarkersCropSafeAreaParam.bind(this)
    this.resetToOrigionalImage=this.resetToOrigionalImage.bind(this)
    this.imageHasEdits=this.imageHasEdits.bind(this)
    this.imageHasCrop=this.imageHasCrop.bind(this)
    this.onClickMarker=this.onClickMarker.bind(this)
    this.onClickCover=this.onClickCover.bind(this)
    this.onKeyDown=this.onKeyDown.bind(this)
    this.imageHasUnsavedChanges=this.imageHasUnsavedChanges.bind(this)
    this.handleBackgroundClick=this.handleBackgroundClick.bind(this)
    this.isCoverInImageContainerSafeArea=this.isCoverInImageContainerSafeArea.bind(this)

    this.updateMarkersCropSafeAreaParam = throttle(this.updateMarkersCropSafeAreaParam,50)
    this.recalculateCoverCropAreaOverlaps = throttle(this.recalculateCoverCropAreaOverlaps,50)
    this.onMouseMove=this.onMouseMove.bind(this)
    this.imageHasMarkup=this.imageHasMarkup.bind(this)



    const modalMinWidth = 950
    const modalMaxWidth = 1200

    let modalWidth = Math.max(Math.min(window.innerWidth - 130, modalMaxWidth),modalMinWidth)

    const modalMinHeight = 550
    const modalMaxHeight = 720

    let modalHeight = Math.max(Math.min(window.innerHeight - 100, modalMaxHeight),modalMinHeight)

    const modalLeftBarWidth = 280
    const canvasLeftPadding = 30
    const canvasRightPadding = 90
    const canvasTopPadding = 30
    const canvasBottomPadding = 30

    const canvasWidth = modalWidth - modalLeftBarWidth - canvasLeftPadding - canvasRightPadding
    const canvasHeight = modalHeight - canvasTopPadding - canvasBottomPadding




		const {crop,rotate,originalDimensions,colorAdjust}=props.imageNode.attrs
    const imageDimensions = calculateEditorCanvasImageDimensions(rotate.angle,originalDimensions,canvasWidth,canvasHeight)

    const initialCropBoxDimensions=calculateInitialCropBoxDimensions(crop,imageDimensions)

    this.state = {
      rotateAngle:rotate.angle,
      flipHorizontal:rotate.flipH,
      flipVertical:rotate.flipV,
      initialCropBoxDimensions:initialCropBoxDimensions,
      initialCropBoxWidth:initialCropBoxDimensions.cropBoxWidth,
      cropBoxWidth: initialCropBoxDimensions.cropBoxWidth,
      initialCropBoxHeight:initialCropBoxDimensions.cropBoxHeight,
      cropBoxHeight: initialCropBoxDimensions.cropBoxHeight,     
      cropBoxX: initialCropBoxDimensions.cropBoxX,
      initialCropBoxX:initialCropBoxDimensions.cropBoxX,
      initialCropBoxY:initialCropBoxDimensions.cropBoxY,
      cropBoxY: initialCropBoxDimensions.cropBoxY,
      imageDimensions:imageDimensions,
      initialImageMarkers:{},
      imageMarkers:{},
      initialImageCovers:{},
      imageCovers:{},
      submitLoading:false,
      showAdjustColorControls: (colorAdjust.saturation || colorAdjust.contrast || colorAdjust.brightness) ? true : false,
      saturationValue:colorAdjust.saturation,
      contrastValue:colorAdjust.contrast,
      brightnessValue:colorAdjust.brightness,
      resizingCover:null,
      rightRegionHover:false,
      modalWidth: modalWidth,
      modalHeight: modalHeight,
      modalLeftBarWidth: modalLeftBarWidth,
      canvasWidth: canvasWidth,
      canvasHeight: canvasHeight,   
      originalImageLoaded: false,   
    }
    
  } 

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown)
    const {imageDimensions,initialCropBoxDimensions}=this.state
    const {markers,covers}=this.props.imageNode.attrs.markup


    // unfortunately was easier if brittle to hardcode initial responsive positions rather than e.g. measuring
    const markupPanelHeight = 335
    const markupPanelTopPadding = 24
    const markupPanelLeftPadding = 24
    const markerMargin = 8
    const extraMarginTopCover = 8 

    let imageMarkers= cloneDeep(initialDefaultImageMarkers)
    Object.keys(imageMarkers).forEach((markerName,i)=>{
      imageMarkers[markerName].x = this.state.canvasWidth + markupPanelLeftPadding
      imageMarkers[markerName].y = ((this.state.canvasHeight - markupPanelHeight)/2) + markupPanelTopPadding + ((MARKER_SIZE + markerMargin) * i)
    })
    
    this.defaultImageMarkers=cloneDeep(imageMarkers)
    let imageCovers=cloneDeep(initialDefaultImageCovers)

    Object.keys(imageCovers).forEach((coverName)=>{
      imageCovers[coverName].x = this.state.canvasWidth + markupPanelLeftPadding
      imageCovers[coverName].y = ((this.state.canvasHeight - markupPanelHeight)/2) + markupPanelTopPadding + ((MARKER_SIZE + markerMargin) * 5) + extraMarginTopCover
      
    })
    this.defaultImageCovers=cloneDeep(imageCovers)

    markers.forEach((marker)=>{
      imageMarkers[`marker${marker.type}`].x=((marker.x * imageDimensions.imageScale) + imageDimensions.imageContainerXOffset - (0.5 * MARKER_SIZE)) 
      imageMarkers[`marker${marker.type}`].y=((marker.y * imageDimensions.imageScale) + imageDimensions.imageContainerYOffset - (0.5 * MARKER_SIZE)) 
      imageMarkers[`marker${marker.type}`].inImageContainerSafeArea=true
      imageMarkers[`marker${marker.type}`].inCropSafe=this.isPositionInCropSafeArea(imageMarkers[`marker${marker.type}`].x,imageMarkers[`marker${marker.type}`].y,initialCropBoxDimensions.cropBoxX,initialCropBoxDimensions.cropBoxY,initialCropBoxDimensions.cropBoxWidth,initialCropBoxDimensions.cropBoxHeight,imageDimensions)    
    })
    covers.forEach((cover)=>{
      imageCovers[`${cover.type}`].x=((cover.x * imageDimensions.imageScale) + imageDimensions.imageContainerXOffset - (0.5 * cover.w * imageDimensions.imageScale))       
      imageCovers[`${cover.type}`].y=((cover.y * imageDimensions.imageScale) + imageDimensions.imageContainerYOffset - (0.5 * cover.h * imageDimensions.imageScale))        
      imageCovers[`${cover.type}`].width=cover.w * imageDimensions.imageScale     
      imageCovers[`${cover.type}`].height=cover.h * imageDimensions.imageScale
      imageCovers[`${cover.type}`].inImageContainerSafeArea=true
      imageCovers[`${cover.type}`].cropAreaOverlap=calculateCoverCropAreaOverlap(imageCovers[`${cover.type}`],initialCropBoxDimensions.cropBoxX,initialCropBoxDimensions.cropBoxY,initialCropBoxDimensions.cropBoxWidth,initialCropBoxDimensions.cropBoxHeight)
    })

    this.setState({
      initialImageMarkers:cloneDeep(imageMarkers),
      imageMarkers:imageMarkers,
      initialImageCovers:cloneDeep(imageCovers),
      imageCovers:imageCovers,
    })
    if(window.analytics){
      window.analytics.page('Edit Image Modal')
    }
  }


  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  onKeyDown(e){
    e.stopPropagation()//to prevent triggering slide list
    if (e.keyCode === 13) {
      e.preventDefault()
      this.saveChanges()
    }
    if (e.keyCode === 27) { //escape key
      this.props.closeEditImageModal()
    }
  }

  handleBackgroundClick(){
    const imageHasUnsavedChanges=this.imageHasUnsavedChanges()
    if(!imageHasUnsavedChanges){
      this.props.closeEditImageModal()
    }
  }

  imageHasUnsavedChanges(){
    const {rotateAngle,flipHorizontal,flipVertical,cropBoxWidth,
      cropBoxHeight,imageMarkers,imageCovers,
      saturationValue,brightnessValue,contrastValue,initialImageMarkers,initialImageCovers,initialCropBoxHeight,initialCropBoxWidth,
      cropBoxX,initialCropBoxX,cropBoxY,initialCropBoxY
    }=this.state

    const {rotate,colorAdjust}=this.props.imageNode.attrs

    let imageHasUnsavedChanges=false
    if(
      rotateAngle !== rotate.angle ||
      flipHorizontal !== rotate.flipH ||
      flipVertical !== rotate.flipV ||
      Math.round(cropBoxWidth) !== Math.round(initialCropBoxWidth) ||
      cropBoxY !== initialCropBoxY ||
      cropBoxX !== initialCropBoxX ||
      Math.round(cropBoxHeight) !== Math.round(initialCropBoxHeight) ||
      saturationValue !== colorAdjust.saturation ||
      brightnessValue !== colorAdjust.brightness ||
      contrastValue !== colorAdjust.contrast ||
      JSON.stringify(imageMarkers) !== JSON.stringify(initialImageMarkers) ||
      JSON.stringify(imageCovers) !== JSON.stringify(initialImageCovers)
      ){

      imageHasUnsavedChanges=true
    }
    return imageHasUnsavedChanges
  }


  imageHasEdits(){
    const {rotateAngle,flipHorizontal,flipVertical,cropBoxWidth,
      cropBoxHeight,imageDimensions,imageMarkers,imageCovers,
      saturationValue,brightnessValue,contrastValue}=this.state
    let imageHasEdits=false
    if(
      rotateAngle !== 0 ||
      flipHorizontal === true ||
      flipVertical === true ||
      Math.round(cropBoxWidth) !== Math.round(imageDimensions.imageContainerWidth) ||
      Math.round(cropBoxHeight) !== Math.round(imageDimensions.imageContainerHeight) ||
      saturationValue !== 0 ||
      brightnessValue !== 0 ||
      contrastValue !== 0 ||
      JSON.stringify(imageMarkers) !== JSON.stringify(this.defaultImageMarkers) ||
      JSON.stringify(imageCovers) !== JSON.stringify(this.defaultImageCovers)
      ){
      imageHasEdits=true
    }
    return imageHasEdits
  }

  imageHasMarkup(){
    const {imageMarkers,imageCovers}=this.state
    let imageHasMarkup=false
    if(JSON.stringify(imageMarkers) !== JSON.stringify(this.defaultImageMarkers) ||
      JSON.stringify(imageCovers) !== JSON.stringify(this.defaultImageCovers)){
      imageHasMarkup=true
    }
    return imageHasMarkup
  }

  imageHasCrop(){
    const {cropBoxWidth,cropBoxHeight,imageDimensions}=this.state
    let imageHasCrop=false
    if(      
     Math.round(cropBoxWidth) !== Math.round(imageDimensions.imageContainerWidth) ||
     Math.round(cropBoxHeight) !== Math.round(imageDimensions.imageContainerHeight)
    ){
      imageHasCrop=true
    }
    return imageHasCrop
  }

  resetToOrigionalImage(){
    this.resetMarkersAndCovers()
    const imageDimensions = calculateEditorCanvasImageDimensions(0,this.props.imageNode.attrs.originalDimensions, this.state.canvasWidth,this.state.canvasHeight)
    this.setState({
      rotateAngle:0,
      flipHorizontal:false,
      flipVertical:false,
      saturationValue:0,
      brightnessValue:0,
      contrastValue:0,
      imageDimensions:imageDimensions
    })
    this.resetCropBox(imageDimensions)
  }

  resetMarkersAndCovers(){
  	let imageMarkers= cloneDeep(this.defaultImageMarkers)
		let imageCovers=cloneDeep(this.defaultImageCovers)
		this.setState({imageMarkers:imageMarkers,imageCovers:imageCovers})
  }

  resetMarkerPostion(key){
    let newMarkers={...this.state.imageMarkers}
    newMarkers[key]=cloneDeep(this.defaultImageMarkers[key])
    this.setState({imageMarkers:newMarkers})
  }

  onClickMarker(key){
    const marker=this.state.imageMarkers[key]
    const defaultMarker=this.defaultImageMarkers[key]
    if(marker.x===defaultMarker.x && marker.y===defaultMarker.y){
      const {imageDimensions}=this.state
      let newMarkers={...this.state.imageMarkers}
      let markerNumber=parseInt(key.substring(6,7),10)
      let incrementWidth=imageDimensions.imageContainerWidth/12
      let incrementHeight=imageDimensions.imageContainerHeight/12

      const newMarkerX=imageDimensions.canvasImageXOffset+(markerNumber*incrementWidth)
      const newMarkerY=imageDimensions.canvasImageYOffset+(markerNumber*incrementHeight)
      const {cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight}=this.state

      newMarkers[key].x=newMarkerX
      newMarkers[key].y=newMarkerY
      newMarkers[key].inImageContainerSafeArea=this.isPositionInImageContainerSafeArea(newMarkerX,newMarkerY,MARKER_SIZE,MARKER_SIZE)
      newMarkers[key].inCropSafe=this.isPositionInCropSafeArea(newMarkerX,newMarkerY,cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight)
      this.setState({imageMarkers:newMarkers})
    }
  }

  onClickCover(key){
    const cover=this.state.imageCovers[key]
    const defaultCover=this.defaultImageCovers[key]
    if(cover.x === defaultCover.x && cover.y===defaultCover.y){
    //if(!cover.inImageContainerSafeArea){
      const {imageDimensions}=this.state
      let newCovers={...this.state.imageCovers}
      let coverNumber=parseInt(key.substring(5,6),10)
      let incrementWidth=imageDimensions.imageContainerWidth/12
      let incrementHeight=imageDimensions.imageContainerHeight/12
      
      newCovers[key].x=imageDimensions.canvasImageXOffset+((coverNumber+5)*incrementWidth)
      newCovers[key].y=imageDimensions.canvasImageYOffset+((coverNumber+5)*incrementHeight)
      newCovers[key].inImageContainerSafeArea=true
      const {cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight}=this.state
      newCovers[key].cropAreaOverlap=calculateCoverCropAreaOverlap(newCovers[key],cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight)

      this.setState({imageCovers:newCovers})
    }
  }

  resetCover(key){
    let newCovers={...this.state.imageCovers}
    newCovers[key]=cloneDeep(this.defaultImageCovers[key])
    this.setState({imageCovers:newCovers})
  }

  resetCropBox(imageDimensions){
    this.setState({// reset the cropbox to full image
      cropBoxWidth: imageDimensions.imageContainerWidth,
      cropBoxHeight: imageDimensions.imageContainerHeight,
      cropBoxX: imageDimensions.imageContainerXOffset,
      cropBoxY: imageDimensions.imageContainerYOffset
    },()=>{
      this.updateMarkersCropSafeAreaParam()
      this.recalculateCoverCropAreaOverlaps(imageDimensions.imageContainerXOffset,imageDimensions.imageContainerYOffset,imageDimensions.imageContainerWidth,imageDimensions.imageContainerHeight)
    })
  }

  flipImage(flipType){ 
  	if(flipType === 'horizontal'){
  		this.setState({flipHorizontal:!this.state.flipHorizontal})
  	}
  	if(flipType === 'vertical'){
  	 this.setState({flipVertical:!this.state.flipVertical})
  	}
    this.resetCropBox(this.state.imageDimensions)

  }

  rotateImage(){
  	const oldAngle=this.state.rotateAngle
  	const newAngle=(oldAngle+90)%360
  	const imageDimensions = calculateEditorCanvasImageDimensions(newAngle,this.props.imageNode.attrs.originalDimensions,this.state.canvasWidth, this.state.canvasHeight)
    const oldScale = this.state.imageDimensions.imageScale
    const newScale = imageDimensions.imageScale 
    let newMarkers = rotateMarkers(this.state.imageMarkers,oldScale,newScale,this.state.canvasWidth,this.state.canvasHeight)
    let newCovers = rotateCovers(this.state.imageCovers,oldScale,newScale,this.state.canvasWidth,this.state.canvasHeight) 	
		this.setState({
			imageMarkers:newMarkers,
			imageCovers:newCovers,
      imageDimensions:imageDimensions,
      rotateAngle:newAngle
		})
    this.resetCropBox(imageDimensions)
  }


  sendEditImageAnalytics(imageAttrs){
    if(window.analytics){
      const hasMarkers=imageAttrs.markup.markers.length!==0
      const hasCovers=imageAttrs.markup.covers.length!==0
      const hasRotation=imageAttrs.rotate.angle !== '0'
      //TODO check this once rotate on API fixed
      const hasFlip=imageAttrs.rotate.flipH ||imageAttrs.rotate.flipV
      const hasColorAdjust=imageAttrs.colorAdjust.brightness||imageAttrs.colorAdjust.saturation||imageAttrs.colorAdjust.contrast

        window.analytics.track('Edit Image', {
          deliveryUrl:imageAttrs.deliveryUrl,
          fileId:imageAttrs.fileId,
          isCropped:imageAttrs.crop?true:false,
          hasMarkers:hasMarkers,
          hasCovers:hasCovers,
          hasRotation:hasRotation,
          hasFlip:hasFlip,
          hasColorAdjust:hasColorAdjust
       })
      }
     
  }

  

  saveChanges(){
    const imageHasUnsavedChanges=this.imageHasUnsavedChanges()
    if(imageHasUnsavedChanges){
    	this.setState({submitLoading:true})
      const {rotateAngle,flipHorizontal,flipVertical,cropBoxX,cropBoxY}=this.state
      const {imageDimensions}=this.state
      const {imageScale,imageContainerXOffset,imageContainerYOffset}=imageDimensions
      let crop=null
      //1.calculate crop dimensions if cropped to less than whole image
      const imageHasCrop=this.imageHasCrop()
       if(imageHasCrop){
        // Step 1: Add offset
        const unscaledCropImageX = cropBoxX - imageContainerXOffset
        const unscaledCropImageY = cropBoxY - imageContainerYOffset
        // Step 2: Scale to original image dimensions for saved
        const cropWidth = this.state.cropBoxWidth / imageScale
        const cropHeight = this.state.cropBoxHeight / imageScale
        const cropX = unscaledCropImageX / imageScale
        const cropY = unscaledCropImageY / imageScale
        crop={
          x:cropX,
          y:cropY,
          w:cropWidth,
          h:cropHeight,
        }
      }
      let scaledMarkers=[]
      Object.keys(this.state.imageMarkers).forEach((key)=>{
        const marker=this.state.imageMarkers[key]   
        const defaultMarker=this.defaultImageMarkers[key]
        if(marker.x<defaultMarker.x){ //check if is on the , do properly
          let scaledMarker={
          type:marker.name,
          x:(marker.x - imageContainerXOffset + (0.5 * MARKER_SIZE)) / imageScale,
          y:(marker.y - imageContainerYOffset + (0.5 * MARKER_SIZE)) / imageScale,
          inCropSafe:marker.inCropSafe
        }
        scaledMarkers.push(scaledMarker)
        }     
      })

      let scaledCovers=[]
      Object.keys(this.state.imageCovers).forEach((key)=>{
        const cover=this.state.imageCovers[key]  
        const defaultCover=this.defaultImageCovers[key]
        if(cover.x<defaultCover.x){ //check if is on the canvas
          const height=cover.cropAreaOverlap.height 
          const width=cover.cropAreaOverlap.width 
          const x=cover.x +cover.cropAreaOverlap.x
          const y=cover.y +cover.cropAreaOverlap.y

          let scaledCover={
            type:cover.name,
            x:(x - imageContainerXOffset + (0.5 * width)) / imageScale,
            y:(y - imageContainerYOffset + (0.5 * height)) / imageScale,
            h:height / imageScale,
            w:width / imageScale
        }
        scaledCovers.push(scaledCover)
        }
      })
      let attrs=this.props.imageNode.attrs
      let newAttrs={...attrs,
        crop:crop,
        rotate:{
          angle:rotateAngle,
          flipH:flipHorizontal,
          flipV:flipVertical},
        markup:{
          markers:scaledMarkers,
          covers:scaledCovers},
        colorAdjust:{
          brightness:this.state.brightnessValue,
          saturation:this.state.saturationValue,
          contrast:this.state.contrastValue
        }
      }
        if(isEqual(attrs) === isEqual(newAttrs)){
          setImageAttributes(this.props.imageNode,this.props.nodePos,newAttrs)
        }
        setTimeout(function() {  
          this.props.closeEditImageModal()
        }.bind(this), 1000) 

        this.sendEditImageAnalytics(newAttrs)


      }
      else{
        this.props.closeEditImageModal()
      }
  }

  updateColorAdjustParams(value,param){
  	let snappedValue=value
  	if(Math.abs(value)<4){
  		snappedValue=0
  	}
  	this.setState({[`${param}Value`]:snappedValue})
  }	

  onCoverKeyDown(e,key){
  	if (e.keyCode === 46 || e.keyCode === 8) {
			this.resetCover(key)
		}
  }

 	onMarkerKeyDown(e,key){
  	if (e.keyCode === 46 || e.keyCode === 8) {
      this.resetMarkerPostion(key)
		}
  }



  onMarkerDrag(e,d,key){
    const {cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight,imageDimensions}=this.state
    let imageMarkers={...this.state.imageMarkers}
    imageMarkers[key].x=d.x
    imageMarkers[key].y=d.y
    imageMarkers[key].inImageContainerSafeArea=this.isPositionInImageContainerSafeArea(d.x,d.y,MARKER_SIZE,MARKER_SIZE)
    imageMarkers[key].inCropSafe=this.isPositionInCropSafeArea(d.x,d.y,cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight,imageDimensions)
    this.setState({imageMarkers:imageMarkers}) 
  }

  updateMarkersCropSafeAreaParam(){ //after resize or move cropbox recalc saveAreaParams
    const {cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight,imageDimensions}=this.state
    let imageMarkers={...this.state.imageMarkers}
    Object.keys(imageMarkers).forEach((key)=>{
      const marker=imageMarkers[key]
      if(marker.inImageContainerSafeArea){
        imageMarkers[key].inCropSafe=this.isPositionInCropSafeArea(marker.x,marker.y,cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight,imageDimensions)
      }
    })
    this.setState({imageMarkers:imageMarkers}) 
  }



  recalculateCoverCropAreaOverlaps(cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight){
    let imageCovers={...this.state.imageCovers}
    Object.keys(imageCovers).forEach((key)=>{
      const cover=imageCovers[key]
      if(cover.inImageContainerSafeArea){
        imageCovers[key].cropAreaOverlap=calculateCoverCropAreaOverlap(cover,cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight)
      }
    })
    this.setState({imageCovers:imageCovers}) 
  }

  onCoverDrag(e,d,key){
    const {cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight}=this.state
    let imageCovers={...this.state.imageCovers}
    imageCovers[key].x=d.x
    imageCovers[key].y=d.y
    imageCovers[key].inImageContainerSafeArea=this.isCoverInImageContainerSafeArea(d.x,d.y,imageCovers[key].width,imageCovers[key].height)
    imageCovers[key].cropAreaOverlap=calculateCoverCropAreaOverlap(imageCovers[key],cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight)
    this.setState({imageCovers:imageCovers}) 
  }

  onMarkerDragStop(e,key){
    let imageMarkers={...this.state.imageMarkers}
    const marker=imageMarkers[key]
    if(!marker.inImageContainerSafeArea){
      const defaultMarker=this.defaultImageMarkers[key]
     if(marker.x !== defaultMarker.x || marker.y !==defaultMarker.y){
      setTimeout(function(){ 
        this.resetMarkerPostion(key) }.bind(this), 10) //short timeout position is reset after onclick called
    }
    }
  }

  onCoverDragStop(e, d, key){
    let imageCovers={...this.state.imageCovers}
    const cover=imageCovers[key]
    if(!cover.inImageContainerSafeArea){
      const defaultCover=this.defaultImageCovers[key]
      if(cover.x !== defaultCover.x || cover.y !==defaultCover.y){
         setTimeout(function(){ 
        this.resetCover(key) }.bind(this), 10) //short timeout position is reset after onclick called
        }
      }
    const {cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight}=this.state
    imageCovers[key].cropAreaOverlap=calculateCoverCropAreaOverlap(imageCovers[key],cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight)
  }


  isPositionInImageContainerSafeArea(x,y,markupWidth,markupHeight){
    const {imageContainerHeight,imageContainerWidth,imageContainerXOffset,imageContainerYOffset}=this.state.imageDimensions
    let xPositionIsSafe=false

    if((x>=imageContainerXOffset)&&((imageContainerXOffset+imageContainerWidth)>=(x+markupWidth))){
      xPositionIsSafe=true
    }
    let yPositionIsSafe=false
    if((y >= imageContainerYOffset) && ((imageContainerYOffset+imageContainerHeight)>=(y+markupHeight))){
      yPositionIsSafe=true
    }
    return (xPositionIsSafe && yPositionIsSafe)
  }

  isCoverInImageContainerSafeArea(x,y,markupWidth,markupHeight){
    const {imageContainerHeight,imageContainerWidth,imageContainerXOffset,imageContainerYOffset}=this.state.imageDimensions
    let xPositionIsSafe=false
    //check if any part of the over is in the safe area
    if((x+markupWidth>=imageContainerXOffset)&&(imageContainerXOffset+imageContainerWidth)>=(x)){
      xPositionIsSafe=true
    }
    let yPositionIsSafe=false
    if((y+markupHeight >= imageContainerYOffset) && ((imageContainerYOffset+imageContainerHeight)>=(y))){
      yPositionIsSafe=true
    }

    return (xPositionIsSafe && yPositionIsSafe)
  }

  isPositionInCropSafeArea(x,y,cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight){
    let xPositionIsSafe=false
    if(x>(cropBoxX) && (x+MARKER_SIZE)<(cropBoxX+parseInt(cropBoxWidth,10))){
      xPositionIsSafe=true
    }
    let yPositionIsSafe=false
    if(y>(cropBoxY) && (y+MARKER_SIZE)<(cropBoxY+parseInt(cropBoxHeight,10))){
      yPositionIsSafe=true
    }
    return (xPositionIsSafe && yPositionIsSafe)
  }


  onCoverResizeStop(key,e, direction, ref, delta, position){
		let imageCovers={...this.state.imageCovers}
		imageCovers[key].height=ref.offsetHeight
		imageCovers[key].width=ref.offsetWidth
		imageCovers[key].x=position.x
		imageCovers[key].y=position.y
    const {cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight}=this.state
		imageCovers[key].cropAreaOverlap=calculateCoverCropAreaOverlap(imageCovers[key],cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight)
		imageCovers[key].inImageContainerSafeArea=this.isCoverInImageContainerSafeArea(position.x,position.y,imageCovers[key].width,imageCovers[key].height)

   if(!imageCovers[key].inImageContainerSafeArea){
      setTimeout(function(){ 
        this.resetCover(key) }.bind(this), 10) //short timeout position is reset after onclick called
      }
      else{
        this.setState({
          imageCovers:imageCovers
        })
      }
    this.setState({resizingCover:null})		
  }


  onMouseMove(e){
    const posX = e.pageX
    const windowWidth=window.innerWidth
    const modalWidth=this.state.modalWidth
    let rightRegionHover=false
    const cutOff=(modalWidth - 85)+(windowWidth-modalWidth)/2
    if(posX>cutOff){
     rightRegionHover=true
    }
    if(rightRegionHover !==this.state.rightRegionHover){
      this.setState({rightRegionHover:rightRegionHover})
    }
  }


  render(){  

    const {fileId}=this.props
  	const {brightnessValue, saturationValue, contrastValue, flipHorizontal, flipVertical, aspectType,submitLoading,showAdjustColorControls,imageDimensions,rotateAngle, modalWidth, modalHeight, modalLeftBarWidth, originalImageLoaded} = this.state	
  	const {imageScale,imageContainerXOffset,imageContainerYOffset}=imageDimensions
  	const imagePositioningStyling=calculateEditImageCanvasImagePositioning(imageDimensions,rotateAngle,flipHorizontal,flipVertical)
  	const {imageContainerPositioning,imagePositioning} = imagePositioningStyling
    const imageColorAdjust = getTranslatedColorAdjustParams(brightnessValue,saturationValue,contrastValue)



  	// MARKUP PANEL DETAILS – HOVER X-Y IN RIGHT REGION
  	const rightRegionHover = this.state.rightRegionHover// used to e.g. show the tooltip
  	const imageHasNoMarkup = !this.imageHasMarkup() // no markers have been moved yet  	
  	const imageHasCrop=this.imageHasCrop()
    const imageHasEdits=this.imageHasEdits()
    const imageHasUnsavedChanges=this.imageHasUnsavedChanges()

    const originalImageURL = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload/c_limit,dpr_2,h_900,w_900/f_auto,q_auto/${fileId}`    


    return (	 
	  	<div className='editor-editImageModalContainer' onMouseMove={this.onMouseMove}> 		
		  	<div style={{width: `${modalWidth}px`, height: `${modalHeight}px`}} className='editor-editImageModal' >        
		  		<button className={'editor-editImageModal-resetImageBtn ' + (imageHasEdits ? 'editor-editImageModal-resetImageBtn--show' : 'editor-editImageModal-resetImageBtn--hide')} onClick={this.resetToOrigionalImage}>
		  			Reset 
		  		</button>        

		  		<div style={{width: `${modalLeftBarWidth}px`}} className='editor-editImageModal-leftBar'>
		  			<div className='editor-editImageModal-leftBar-title'>		  				
		  				<EditorIcon name='editImage'/>		  							  				
		  				Edit Image		  				
		  			</div>
		  		
			  		<div className='editor-editImageModal-leftBar-transformPanel'>
			  			<button onClick={()=>{this.flipImage("horizontal")}} className={'editor-editImageModal-iconBtn editor-editImageModal-iconBtn--flipHorizontal ' + (flipHorizontal ? 'editor-editImageModal-iconBtn--active' : 'editor-editImageModal-iconBtn--inactive')}>
			  				<EditorIcon name='imageFlipHorizontal'/>
			  			</button>
			  			<button onClick={()=>{this.flipImage("vertical")}} className={'editor-editImageModal-iconBtn editor-editImageModal-iconBtn--flipVertical ' + (flipVertical ? 'editor-editImageModal-iconBtn--active' : 'editor-editImageModal-iconBtn--inactive')}>
			  				<EditorIcon name='imageFlipVertical'/>
			  			</button>
			  			<button onClick={this.rotateImage} className='editor-editImageModal-iconBtn editor-editImageModal-iconBtn--rotateRight'>
			  				<EditorIcon name='imageRotateRight'/>
			  			</button>
			  		</div>
			  		<div className='editor-editImageModal-leftBar-divider'/>

			  		<div className='editor-editImageModal-leftBar-adjustColorPanel'>
			  			<button onClick={()=>{this.setState({showAdjustColorControls: !showAdjustColorControls})}} className={'editor-editImageModal-adjustColorBtn ' + (showAdjustColorControls ? 'editor-editImageModal-adjustColorBtn--active' : 'editor-editImageModal-adjustColorBtn--inactive')}>			  							  				
			  				Adjust Color
			  			</button>
			  			{showAdjustColorControls &&			  				
			  				<div className='editor-editImageModal-leftBar-adjustColorPanel-slidersContainer'>
			  					<EditorEditImageModalColorAdjustSlider
			  						name='Brightness'
			  						minValue={-100}
			  						maxValue={100}
			  						defaultValue={0}
			  						value={this.state.brightnessValue}
			  						updateSliderValue={(value)=>{this.updateColorAdjustParams(value,'brightness')}}
			  					/>
			  					
			  					<EditorEditImageModalColorAdjustSlider
			  						name='Color Saturation'
			  						minValue={-100}
			  						maxValue={100}
			  						defaultValue={0}
			  						value={this.state.saturationValue}
			  						updateSliderValue={(value)=>{this.updateColorAdjustParams(value,'saturation')}}
			  					/>

			  					<EditorEditImageModalColorAdjustSlider
			  						name='Contrast'
			  						minValue={-100}
			  						maxValue={100}
			  						defaultValue={0}
			  						value={this.state.contrastValue}
			  						updateSliderValue={(value)=>{this.updateColorAdjustParams(value,'contrast')}}
			  					/>
			  					
			  				</div>				  								  			
			  			}
			  		</div>			  		
			  		
			  		

			  		<div className='editor-editImageModal-leftBar-verticalSpacer'/>

			  		<div className='editor-editImageModal-leftBar-undoPanel'>
			  			{(undoAvailable || redoAvailable) &&
			  				<React.Fragment>
					  			<button disabled={!undoAvailable} className='editor-editImageModal-undoRedo editor-editImageModal-undoRedo--undo'>
					  				<EditorIcon name='imageUndo'/> Undo 
					  			</button>
					  			<button disabled={!redoAvailable} className='editor-editImageModal-undoRedo editor-editImageModal-undoRedo--redo'>					  								  				
					  				Redo <EditorIcon name='imageRedo'/> 
					  			</button>
				  			</React.Fragment>
			  			}
			  		</div>

            <a target='_blank' className='editor-editImageModal-leftBar-helpBtn' rel='noopener noreferrer' href='https://help.plickers.com/hc/en-us/articles/4405436877595'>Help: Image Editing and Markup</a>

			  		<div className='editor-editImageModal-leftBar-divider'/>

			  		<div className='editor-editImageModal-leftBar-submitBtnContainer'>
			  			<button onClick={this.saveChanges} className='editor-editImageModal-doneBtn'>
			  				{!submitLoading &&
			  					<React.Fragment>
					  				<div className='editor-editImageModal-doneBtn-primary'>
					  					Done
					  				</div>
					  				<div className='editor-editImageModal-doneBtn-secondary'>
					  					Enter
					  				</div>
					  			</React.Fragment>
			  				}

			  				{submitLoading &&
				  				<div className='editor-editImageModal-doneBtn-submitLoadingContainer'>
				  					<div className='editor-editImageModal-doneBtn-submitLoadingSpinner' />				  								  					
				  				</div>				  				
			  				}
			  			</button>
              {
			  			<button onClick={this.props.closeEditImageModal} className={'editor-editImageModal-cancelBtn' + (imageHasUnsavedChanges ? ' editor-editImageModal-cancelBtn--show' : ' editor-editImageModal-cancelBtn--hide')}>
			  				Cancel
			  			</button>
            }
			  		</div>
		  		</div>

		  		<div className='editor-editImageModal-canvasContainer'>	
			  		<div className='editor-editImageModal-canvas'>
			  			{Object.keys(this.state.imageMarkers).map((key,i)=>{
			  				const marker=this.state.imageMarkers[key]
                const markerFaded = !marker.inCropSafe && marker.inImageContainerSafeArea
			  				return(
									<Rnd
                    key={i}
										className={'editor-editImageModal-markerCanvas-markerContainerContainer ' + (markerFaded ? 'editor-editImageModal-markerCanvas-markerContainerContainer--markerFaded':'')}
										enableResizing={false}
										default={{						    
											width: MARKER_SIZE, // do not change
											height: MARKER_SIZE, // do not change
										}}
										position={{ x: this.state.imageMarkers[key].x, y: this.state.imageMarkers[key].y }}
										onDrag={(e, d) => { this.onMarkerDrag(e,d,key)}}
										onDragStop={(e) => { this.onMarkerDragStop(e,key)}}
									>
                    <div
                      onClick={()=>{this.onClickMarker(key)}}
                      onKeyDown={(e)=>{this.onMarkerKeyDown(e,key)}} 
                      tabIndex='1'                      
                      className="editor-editImageModal-markerCanvas-markerContainer"                      
                    >
										  <DraggableLetterMarker 
											 name={marker.name} 
                       markerSize={MARKER_SIZE}
											 inImageContainerSafeArea={marker.inImageContainerSafeArea}
											 inCropSafeArea={marker.inCropSafe}
											 xPosition={Math.round((this.state.imageMarkers[key].x - imageContainerXOffset + (0.5 * MARKER_SIZE))/ imageScale)} 
											 yPosition={Math.round((this.state.imageMarkers[key].y - imageContainerYOffset + (0.5 * MARKER_SIZE)) / imageScale)}
                      />
                      </div>
									</Rnd>

			  					)
			  			})}
			  		
							
			  			{Object.keys(this.state.imageCovers).map((key,i)=>{
			  				const cover=this.state.imageCovers[key]
			  				const isResizing=this.state.resizingCover === key
			  				const cropAreaOverlap=cover.cropAreaOverlap || {}                
			  				return(
									<Rnd
                    key={`cover_${i}`}
										className={'editor-editImageModal-markerCanvas-coverContainer ' + (isResizing ? 'editor-editImageModal-canvas-coverContainer--resizing' : '' )}  											  
										position={{ x: this.state.imageCovers[key].x, y:this.state.imageCovers[key].y}}
										size={{ width: this.state.imageCovers[key].width,  height: this.state.imageCovers[key].height }}
										onResizeStart={()=>{this.setState({resizingCover:key})}}
										onDragStop={(e, d) => { this.onCoverDragStop(e,d,key)}}
										//bounds={cover.inImageContainerSafeArea?'.editor-editImageModal-canvas-intrinsicImageContainer-coverSafeArea':'window'} 
										enableResizing={cover.inImageContainerSafeArea}
										onDrag={(e, d) => { this.onCoverDrag(e,d,key)}}
										default={{						    
											width: this.state.imageCovers[key].width, 
											height: this.state.imageCovers[key].height,
										}}
                    maxWidth={this.state.imageDimensions.editorCanvasImageWidth}
                    maxHeight={this.state.imageDimensions.editorCanvasImageHeight}
                    minWidth={20}
                    minHeight={20}
                    onResize={(e, direction, ref, delta, position) => {this.onCoverResizeStop(key,e, direction, ref, delta, position)}}
										onResizeStop={(e, direction, ref, delta, position) => {this.onCoverResizeStop(key,e, direction, ref, delta, position)}}
								  	resizeHandleComponent={{
                      topLeft: <CoverCornerHandle />,			        		
                      topRight: <CoverCornerHandle />,
                      bottomLeft: <CoverCornerHandle />,
                      bottomRight: <CoverCornerHandle />,			        		
									 }}
									>
  									<div 
                      onClick={()=>{this.onClickCover(key)}}
  										onKeyDown={(e)=>{this.onCoverKeyDown(e,key)}}
  										tabIndex='1'
  										className='editor-editImageModal-markerCanvas-cover'
                    >
    									<div 
                        style={{position: "absolute", "top": `${cropAreaOverlap.y||0}px`, "left": `${cropAreaOverlap.x||0}px`, width: `${cropAreaOverlap.width||0}px`, height: `${cropAreaOverlap.height||0}px`}}
                        className='editor-editImageModal-markerCanvas-cover-fullBG'
                      />
  									</div>

										{/* FOR DEV
										<div className='editor-editImageModal-markerCanvas-coverExample-position'>																						
											<div className='editor-editImageModal-markerCanvas-coverExample-position-pos'>
												COVER X:{cover.x} Y:{cover.y} W:{cover.width} H:{cover.height}
											</div>	
                      <div className='editor-editImageModal-markerCanvas-coverExample-position-pos'>
                        CROP X: {Math.round(this.state.cropBoxX)} Y:{Math.round(this.state.cropBoxY)} W:{this.state.cropBoxWidth} H:{this.state.cropBoxHeight}
                      </div>  											
											<div className='editor-editImageModal-markerCanvas-coverExample-position-pos'>
												OVERLAP: X:{cover.cropAreaOverlap?cover.cropAreaOverlap.x:''} / Y:{cover.cropAreaOverlap?cover.cropAreaOverlap.y:''} W:{cover.cropAreaOverlap?Math.round(cover.cropAreaOverlap.width):''} H:{cover.cropAreaOverlap?Math.round(cover.cropAreaOverlap.height):''} 
											</div>												
										</div>
                  */}

									</Rnd>
			  					)
			  			})}
			  		

			  		 <Rnd			        
				        className={'editor-editImageModal-canvas-cropBox ' + (imageHasCrop ? 'editor-editImageModal-canvas-cropBox--hasCrop' : 'editor-editImageModal-canvas-cropBox--noCrop')}
				        size={{ width: this.state.cropBoxWidth, height: this.state.cropBoxHeight }}
				        position={{ x: this.state.cropBoxX, y: this.state.cropBoxY }}
				        onDragStop={(e, d) => {
				          this.setState({ cropBoxX: d.x, cropBoxY: d.y })
                  this.updateMarkersCropSafeAreaParam()
                  this.recalculateCoverCropAreaOverlaps(d.x,d.y,this.state.cropBoxWidth,this.state.cropBoxHeight)
				        }}
                onDrag={(e,d) => {
                  this.setState({ cropBoxX: d.x, cropBoxY: d.y })
                  this.updateMarkersCropSafeAreaParam()
                  this.recalculateCoverCropAreaOverlaps(d.x,d.y,this.state.cropBoxWidth,this.state.cropBoxHeight)                
                }}
                onResize={(e, direction, ref, delta, position) => {
                  this.setState({
                    cropBoxWidth: ref.offsetWidth,
                    cropBoxHeight: ref.offsetHeight,
                    cropBoxX: position.x, 
                    cropBoxY: position.y
                  })
                  this.updateMarkersCropSafeAreaParam()
                  this.recalculateCoverCropAreaOverlaps(position.x,position.y,ref.style.width.replace("px", ""),ref.style.height.replace("px", ""))
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    let snappedWidth = ref.offsetWidth
                    let snappedHeight = ref.offsetHeight
                    let positionX=position.x
                    let positionY=position.y
                    const {imageDimensions}=this.state
                    if((imageDimensions.imageContainerWidth-snappedWidth) < CROP_SNAP_MARGIN){
                      snappedWidth = imageDimensions.imageContainerWidth
                      positionX=imageDimensions.imageContainerXOffset

                    }
                    if((imageDimensions.imageContainerHeight - snappedHeight ) < CROP_SNAP_MARGIN){
                      snappedHeight = imageDimensions.imageContainerHeight
                      positionY=imageDimensions.imageContainerYOffset
                    }


                  this.setState({
                    cropBoxWidth: snappedWidth,
                    cropBoxHeight: snappedHeight,
                    cropBoxX: positionX, 
                    cropBoxY: positionY
                  })
                  this.updateMarkersCropSafeAreaParam()
                  this.recalculateCoverCropAreaOverlaps(position.x,position.y,ref.style.width.replace("px", ""),ref.style.height.replace("px", ""))
                }}
				        disableDragging={false}
				        minWidth={60}
				        minHeight={60}
				        bounds='.editor-editImageModal-canvas-intrinsicImageContainer' // may want to do this better in future
				        lockAspectRatio={aspectType === 'fill' ? 580/680 : false}

				        resizeHandleComponent={{ 
				        		left: <CropboxLeftRightEdgeHandle />,
				        		right: <CropboxLeftRightEdgeHandle />,
				        		topLeft: <CropboxCornerHandle />,
				        		top: <CropboxTopBottomEdgeHandle />,
				        		topRight: <CropboxCornerHandle />,
				        		bottom: <CropboxTopBottomEdgeHandle />,
				        		bottomLeft: <CropboxCornerHandle />,
				        		bottomRight: <CropboxCornerHandle />,			        		
				        }}
				      >
				        <div className='editor-editImageModal-canvas-cropBox-thirds editor-editImageModal-canvas-cropBox-thirds--topCenter'/>
				        <div className='editor-editImageModal-canvas-cropBox-thirds editor-editImageModal-canvas-cropBox-thirds--bottomCenter'/>
				        <div className='editor-editImageModal-canvas-cropBox-thirds editor-editImageModal-canvas-cropBox-thirds--leftCenter'/>
				        <div className='editor-editImageModal-canvas-cropBox-thirds editor-editImageModal-canvas-cropBox-thirds--rightCenter'/>
				      </Rnd>

							
			  			<div style={imageContainerPositioning} className={'editor-editImageModal-canvas-intrinsicImageContainer ' + (originalImageLoaded ? 'editor-editImageModal-canvas-intrinsicImageContainer--imageLoaded' : 'editor-editImageModal-canvas-intrinsicImageContainer--imageNotLoaded')}>			  			  				
                <img 
                  className='editor-editImageModal-canvas-intrinsicImage'
                  style={{...imagePositioning, ...imageColorAdjust}}
                  src={originalImageURL} 
                  onLoad={()=>(this.setState({originalImageLoaded: true}))}
                  alt={''}
                />

                <div className='editor-editImageModal-canvas-intrinsicImageContainer-coverSafeArea' />

			  			</div>			  			
			  		</div>
			  	</div>
		  		
		  		<div className='editor-editImageModal-rightPanelContainer'>

		  				<div className={'editor-editImageModal-markupPanelTooltip ' + ((imageHasNoMarkup && rightRegionHover) ? 'editor-editImageModal-markupPanelTooltip--show': 'editor-editImageModal-markupPanelTooltip--hide')}>
		  					Drag onto <br/> your image
		  				</div>

		  				
		  			  		  			  		
		  				<div className='editor-editImageModal-markupPanel'>
		  					<div className='editor-editImageModal-markupPanel-itemContainer' id='testthis'>
		  						<DraggableLetterMarker markerSize={MARKER_SIZE} name='1' />
		  					</div>
		  					<div className='editor-editImageModal-markupPanel-itemContainer'>
		  						<DraggableLetterMarker markerSize={MARKER_SIZE} name='2' />
		  					</div>
		  					<div className='editor-editImageModal-markupPanel-itemContainer'>
		  						<DraggableLetterMarker markerSize={MARKER_SIZE} name='3' />
		  					</div>		  					
		  					<div className='editor-editImageModal-markupPanel-itemContainer'>
		  						<DraggableLetterMarker markerSize={MARKER_SIZE} name='4' />
		  					</div>
		  					<div className='editor-editImageModal-markupPanel-itemContainer'>
		  						<DraggableLetterMarker markerSize={MARKER_SIZE} name='5' />
		  					</div>		  		
		  					<div className='editor-editImageModal-markupPanel-itemContainer'>
		  						<div className='editor-editImageModal-markupPanel-boxSpacer'/>
		  					</div>
		  				</div>	  				
		  			
		  		</div>	

		  		<div className={'editor-editImageModal-rightPanelContainer editor-editImageModal-rightPanelContainer--initial ' + ((imageHasNoMarkup && !rightRegionHover) ? 'editor-editImageModal-rightPanelContainer--initial--show' : 'editor-editImageModal-rightPanelContainer--initial--hide')}>
		  						  			  		
		  				<div className='editor-editImageModal-markupPanel'>
		  					<div className='editor-editImageModal-markupPanel-itemContainer'>
		  						<DraggableLetterMarker markerSize={MARKER_SIZE} name='1' initial />
		  					</div>
		  					<div className='editor-editImageModal-markupPanel-itemContainer'>
		  						<DraggableLetterMarker markerSize={MARKER_SIZE} name='2' initial />
		  					</div>
		  					<div className='editor-editImageModal-markupPanel-itemContainer'>
		  						<DraggableLetterMarker markerSize={MARKER_SIZE} name='3' initial />
		  					</div>		  					
		  					<div className='editor-editImageModal-markupPanel-itemContainer'>
		  						<DraggableLetterMarker markerSize={MARKER_SIZE} name='4' initial />
		  					</div>
		  					<div className='editor-editImageModal-markupPanel-itemContainer'>
		  						<DraggableLetterMarker markerSize={MARKER_SIZE} name='5' initial />
		  					</div>		  		
		  					<div className='editor-editImageModal-markupPanel-itemContainer'>
		  						<div className='editor-editImageModal-markupPanel-boxSpacer'>
		  							<div className='editor-editImageModal-markupPanel-boxSpacer-handle'/>
		  							<div className='editor-editImageModal-markupPanel-boxSpacer-handle'/>
		  							<div className='editor-editImageModal-markupPanel-boxSpacer-handle'/>
		  							<div className='editor-editImageModal-markupPanel-boxSpacer-handle'/>
		  						</div>
		  					</div>

		  				</div>	  				
		  			
		  		</div>	

		  		
		     </div>
         <div className='editor-editImageModalContainerBG' onClick={this.handleBackgroundClick} />
		   </div>
		)
	}
}

export default EditorEditImageModal

import React from 'react'
import { NavLink } from 'react-router-dom'
import { DropTarget } from 'react-dnd'
import ItemTypes from '../DnDKit/ItemTypes'
import RepoTarget from '../DnDKit/RepoTarget'
import Icon from '../misc/Icons'
import {getLinkForRepo} from '../../utils/getLinkForRepo'




function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop()
	}
}




class DnDSideNavRepo extends React.Component{




	render(){
		const {repo,isOver,canDrop,connectDropTarget} = this.props
		const isShared = repo.shared
		const isPending = !repo.published && !repo.joined
		const isPublicAuthor = repo.role ==='author'
		const isPublicConsumer = repo.role ==='consumer'


		return(				
			<NavLink
				id={`DnDSideNavRepo_${repo.id}`}
     			to={getLinkForRepo(repo)}
     			className='sideNav-itemContainer'
   			>
   				{connectDropTarget(
					<div onDoubleClick={()=>{if(repo.role !=='viewer' &&repo.role !=='consumer' && repo.joined && !repo.disabled){this.props.showEditRepoModal(repo)}}} className={'sideNav-item is--repo ' + (isOver ? ' sideNav-item--repo--isDnDOver ' : '') + (canDrop ? ' sideNav-item--repo--canDrop ' : ' sideNav-item--repo--cannotDrop ') + (isPending ? ' sideNav-item--repo--isPending ' : '') + (repo.role === 'viewer' ? ' sideNav-item--repo--isViewOnly ' : '')} >				

						
						<div className='sideNav-item-label'>
							{repo.name}
						</div>
						
						{isShared && !isPending &&
							<div className='sideNav-item-state'>					
								 <Icon name='shared' />							 
							</div>
						}

						{isPublicConsumer &&
							<div className='sideNav-item-state'>					
								 <Icon name='globe-shared' />							 
							</div>
						}

						{isPublicAuthor &&
							<div className='sideNav-item-state'>					
								 <Icon name='globe-shared-author' />							 
							</div>
						}
						
						{isPending && 
							<div className='sideNav-item-pendingRepo'>
							 	<div className='sideNav-item-pendingRepo-circle' />
							</div>
						}

						

						
						<div className='sideNav-item-dropIcon'>			
							 <Icon name='open-left-arrow' />
						</div>
					
							
						
										
					</div>
				)}
			</NavLink>
		)
	}
}
export default DropTarget([ItemTypes.ITEMCELL,ItemTypes.FOLDERCELL], RepoTarget, collect)(DnDSideNavRepo)






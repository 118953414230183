import findIndex from 'lodash/findIndex'

const grades=[
	'pre-k',
	'k',
	'1st',
	 '2nd',
	'3rd',
	'4th',
	 '5th',
	'6th',
	'7th',
	 '8th',
	'9th',
	'10th',
	'11th',
	'12th',
	'higher',
	'staff',
	'other'
]


export function formatGradesForAnalytics(repoGrades) {
	let repoGradesObject={}

	grades.map((grade)=>{
		let gradeSelected=false

		let indexOfGrade = findIndex(repoGrades,repoGrade => {
			return repoGrade === grade
		})
		if(indexOfGrade !== -1){
			gradeSelected=true
		}
		let gradeLabel
		if(grade==='pre-k'){
			gradeLabel='preK'
		}else if(grade==='k'){
			gradeLabel='kindergarten'
		}
		else if(grade==='higher'){
			gradeLabel='higherEducation'
		}
		else if(grade==='staff'){
			gradeLabel='staffGrade'
		}
		else if(grade==='other'){
			gradeLabel='otherGrade'
		}
		else{
			const gradeNumber=grade.substring(0, grade.length-2)
			gradeLabel=`grade${gradeNumber}`
		}

		repoGradesObject[gradeLabel]=gradeSelected
		return null
	})


	return repoGradesObject

}



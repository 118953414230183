export default function calculateCoverCropAreaOverlap(cover,cropBoxX,cropBoxY,cropBoxWidth,cropBoxHeight){
    const coverX= cover.x
    const coverY= cover.y
    const coverHeight= cover.height
    const coverWidth= cover.width

  	let overlapHeight = coverHeight
  	let overlapWidth = coverWidth
  	let overlapX = 0
  	let overlapY = 0
  	if(coverX > cropBoxX){
      overlapX = 0  		
      overlapWidth = Math.max(Math.min(parseInt(cropBoxX, 10) + parseInt(cropBoxWidth, 10) - parseInt(coverX, 10),coverWidth),0)      
  	}
    if(coverX <= cropBoxX){
  		overlapX = cropBoxX - coverX
  		overlapWidth = Math.max(Math.min(coverWidth- (cropBoxX - coverX), cropBoxWidth),0)
  	}
  	if(coverY > cropBoxY){
      overlapY = 0
  		overlapHeight = Math.max(Math.min(parseInt(cropBoxY, 10) + parseInt(cropBoxHeight, 10) - parseInt(coverY, 10),coverHeight),0)      
  	}
    if(coverY <= cropBoxY){
  		overlapY = cropBoxY - coverY
  		overlapHeight= Math.max(Math.min(coverHeight - (cropBoxY - coverY), cropBoxHeight),0)
  	}
  	return{
  		x:overlapX,
  		y:overlapY,
  		height:overlapHeight,
  		width:overlapWidth
  	}
  }
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import {Configure,Index} from 'react-instantsearch-dom'
import DocumentTitle from 'react-document-title'
import {connectSearchBox,connectInfiniteHits,connectStateResults} from 'react-instantsearch-dom'
import {showSectionSelectionModal } from '../../actions/modals'
import AlgoliaSearchWrapperContainer from './AlgoliaSearchWrapperContainer'
import Button from '../../components/uiKit/Button'
import TopPageHeader from '../../components/pageHeaders/TopPageHeader'
import StickyPageHeader from '../../components/pageHeaders/StickyPageHeader'
import SearchResultsCard from '../../components/search/SearchResultsCard'
import SearchResultsFolderCard from '../../components/search/SearchResultsFolderCard'
import SearchResultsRepoCard from '../../components/search/SearchResultsRepoCard'
import qs from 'qs'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'
 
//Page at /searchResults?query=searchTerm
//Get to this page by typing in search bar in top nav and clicking "see all results for X"

const updateAfter = 700

const createURL = state => `?${qs.stringify({query:state.query})}`


const searchStateToUrl = (props, searchState) =>
	searchState ? `${props.location.pathname}${createURL(searchState)}` : ''

const urlToSearchState = location => qs.parse(location.search.slice(1))


const CustomSearchBox = ({ currentRefinement, refine }) => (	
	<input
		type="text"
		placeholder='Search'
		value={currentRefinement}
		onChange={e => refine(e.target.value)}
		autoComplete="off"
		className="topBar-search"
		id="algoliaInputField"
		onKeyDown={(e)=>{if (e.keyCode === 13) {
			document.getElementById('algoliaInputField').blur()
		}}}
	/>	
)





function CustomHits({ hits, refine, hasMore,play }) {
	return (
		<main id="hits">			
			{hits.map(hit => <Hit item={hit} key={hit.objectID} play={play}/>)}			
			{hasMore &&
				<div className='searchResults-list-showMoreContainer'>
					<Button 
						label='Show more results'
						size='large' 
						onClickFunction={refine}					
					/>
				</div>
			}
		</main>
	)
} 

const Hit = withRouter(({ item,history,play }) => {
	if(item.questions){ //set
		return(		         
			<SearchResultsCard
				set
				item={item}				
				title={item.name}
				play={()=>{play(item)}}
			/>			
		)}
	else if(item.choices){ //question
		return(
			<SearchResultsCard
				question
				item={item}
				title={getQuestionBodyLabel(item)}
				play={()=>{play(item)}}
			/>
		)}
	else if(item.ancestors){ //folder
		return(
			<SearchResultsFolderCard
				folder={item}/>
		)}
	else if(item.createdBy){ //repo
		return(
			<SearchResultsRepoCard
				repo={item}/>
		)}
	else return null
}) 



const CustomResults = connectStateResults(({ searchState, searchResults ,play}) => {
	if (searchState.query && searchResults ) {
		if(searchResults.nbHits !==0){
			return (
				<div className='searchResults-list'>  
					<ConnectedHits play={play} />
				</div>
			)}else return null
	}else return null
})



const FolderHeader = connectStateResults(({ searchState,allSearchResults }) => {
	if(allSearchResults && searchState.query){
		if(allSearchResults.folders){
		if(allSearchResults.folders.nbHits >0){
			return(
				<div className='searchResults-subHeader searchResults-subHeader--folder'>
					Folders
				</div>
				)
		}else return null
	}else return null
	}else return null

	})


const RepoHeader = connectStateResults(({ searchState,allSearchResults }) => {
	if(allSearchResults && searchState.query){
		if(allSearchResults['repos']){
		if(allSearchResults['repos'].nbHits >0){
			return(
				<div className='searchResults-subHeader searchResults-subHeader--repo'>
					Packs
				</div>
				)
		}else return null
	}else return null
	}else return null

	})

const ItemsHeader = connectStateResults(({ searchState,allSearchResults }) => {
	if(allSearchResults && searchState.query){
		if(allSearchResults['sets_questions']){
		if(allSearchResults['sets_questions'].nbHits >0){
			return(
				<div className='searchResults-subHeader searchResults-subHeader--repo'>
					Items
				</div>
				)
		}else return null
	}else return null
	}else return null

	})




const ConnectedHeaders = connectStateResults(({ searchState,allSearchResults }) => {
	let totalNumberSearchResults=0
	if(allSearchResults){
		if(allSearchResults.folders){
			totalNumberSearchResults += allSearchResults.folders.nbHits
		} if(allSearchResults['sets_questions']){
			totalNumberSearchResults += allSearchResults['sets_questions'].nbHits
		}
		if(allSearchResults['repos']){
			totalNumberSearchResults += allSearchResults['repos'].nbHits
		}
	}

	let searchResultsText
	if(allSearchResults && searchState.query){
		if(totalNumberSearchResults!==0){
			if(totalNumberSearchResults===1){
				searchResultsText=`${totalNumberSearchResults} Search Result`
			}else{
				searchResultsText=`${totalNumberSearchResults} Search Results`
			}
		}else{
			searchResultsText='No Results in Library'
		}
	}
	return [
		<TopPageHeader
			key='topHeader'
			location='searchResults' 							
			header={<ConnectedSearchBox />}
			headerInfoRow={searchResultsText}								
		/>,
		<StickyPageHeader 
			key='stickyHeader'
			location='searchResults'
			header={searchState.query}
			actionGroup={searchResultsText}	
		/>
	]
})






const ConnectedSearchBox = connectSearchBox(CustomSearchBox)
const ConnectedHits = connectInfiniteHits(CustomHits)

class SearchResultsContainer extends Component {
	constructor(props) {
    	super(props)
    	this.onSearchStateChange = this.onSearchStateChange.bind(this)
   	 	this.state = {
      	searchState: urlToSearchState(props.location),
    	}
  	}
  	componentDidMount(){
  		if(window.analytics){
			window.analytics.page('Search Results')
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.location !== this.props.location) {
			this.setState({ searchState: urlToSearchState(props.location) })
		}
	}

	onSearchStateChange(searchState){
		clearTimeout(this.debouncedSetState)
		this.debouncedSetState = setTimeout(() => {
			window.history.pushState(
				searchState,
				null,
				searchStateToUrl(this.props,searchState)
			)
		}, updateAfter)
		this.setState({ searchState })
	};



	render() {

  	return (
  		<DocumentTitle title='Search Results - Plickers'>
  			<div className='page searchResults page--wideLayout'>
  				<div className='page-leftPanel'/>
					<div className='page-centerPanel'>
					
						<AlgoliaSearchWrapperContainer  
							searchState={this.state.searchState}
							onSearchStateChange={this.onSearchStateChange}
							createURL={createURL}>  

      				<Configure hitsPerPage={100} />
  						<ConnectedHeaders/>		 
      					<RepoHeader /> 
      					<CustomResults />


  						<Index indexName='folders'>  
 					 		<FolderHeader />
      						<CustomResults />     					
   						 </Index>  		

   						<Index indexName='sets_questions'>  
   							<ItemsHeader />
        					<CustomResults play={this.props.showSectionSelectionModal} />    
        				</Index>

      		
    				</AlgoliaSearchWrapperContainer>
    		</div>
    		<div className='page-rightPanel'/>
    	</div>
    	</DocumentTitle>
  	)
	}
}

 

export default withRouter(connect(
	(state,ownProps) => ({
		permissions:state.permissions //pass permissions as props to ensure view updates once permissions load

	}),
	{ showSectionSelectionModal, 
	})(SearchResultsContainer))


import React from 'react'

class FeatureModalSelectControlGroup extends React.Component{

	render(){ 
		return(						
			<div className='featureModal-selectControlGroup'>
				{this.props.title &&
					<div className='featureModal-selectControlGroup-title'>
						{this.props.title}
					</div>
				}				
				{this.props.children}
			</div>			
		)
	}
}

export default FeatureModalSelectControlGroup
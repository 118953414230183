import {showNotification} from '../../actions/notifications'
import store from '../../store'
import filter from 'lodash/filter'
import moment from 'moment'

//When user inserts sub or sup using button in toolbar we show a notification telling them how to insert using a shortcut

const MAX_NOTIFICATION_COUNT=5 //per type
const NOTIFICATION_TIME_PERIOD=60*24*7//resets every 7 days


export function showSubSupNotification(type){
	const subSupNotificationHistory = JSON.parse(localStorage.getItem('subSupNotificationHistory')) || []
	const now=moment()
	
	let dateFilteredNotifications=filter(subSupNotificationHistory,function (n) { 
		const timeAgo=now.diff(n.timestamp,'minutes')
		return timeAgo < NOTIFICATION_TIME_PERIOD
	})

	const notificationCount=filter(dateFilteredNotifications,{type:type}).length

		if(notificationCount<MAX_NOTIFICATION_COUNT){
			if(type==='superscript'){
				store.dispatch(showNotification('Try writing 5^2 and hitting spacebar.','Tip: You can also type exponents. ','default'))
			}else{
				store.dispatch(showNotification('Try writing T_1 and hitting spacebar.','Tip: You can also type subscript.','default'))
		}
		dateFilteredNotifications.push({type:type,timestamp:moment()})
		localStorage.setItem('subSupNotificationHistory', JSON.stringify(dateFilteredNotifications))
	}
}
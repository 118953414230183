import React from 'react'
import Icon from '../misc/Icons'
import range from 'lodash/range'

function roundHalf(num) {
    return Math.round(num*2)/2;
}

class DiscoverRepoRatingStars extends React.PureComponent{

	render(){
		const {rating}=this.props
		const roundedRating=Math.round(rating * 10) / 10
		const numOfStars=roundHalf(roundedRating)
		const numberOfFullstars=Math.floor(numOfStars)
		const numberOfHalfStars=(numOfStars-numberOfFullstars)===0?0:1

		return(
		<div className='discover-repoRatingStars'>
			<div className='discover-repoRatingStars-starsContainer'>
				<div className='discover-repoRatingStars-stars discover-repoRatingStars-stars--active'>
					
					{/* here we show appropriate number of stars or half stars based on rating */}
					{range(numberOfFullstars).map((i)=>{
						return(
							<Icon name='star' key={i} />
							)
					})}
	
					
					{numberOfHalfStars===1 &&
						<Icon name='halfstar' />
					}					
				</div>
				<div className='discover-repoRatingStars-stars discover-repoRatingStars-stars--bg'>
					<Icon name='star' />
					<Icon name='star' />
					<Icon name='star' />
					<Icon name='star' />
					<Icon name='star' />
				</div>
			</div>
			{this.props.showRatingLabel &&
				<div className='discover-repoRatingStars-label'>
					{roundedRating}
				</div>
			}
			
		</div>
		)
	}
}


export default DiscoverRepoRatingStars


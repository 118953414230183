import {updateSetPoll,updateQuestionPoll} from '../actions/polls'
import {isSet} from './isSet'

export function removeFromQueue(poll) {
	const nowDate = new Date()
	if(isSet(poll)===true){
		const requestBody=
			{...poll,
				'canceled':true,
				'clientModified':nowDate,
			}
		return updateSetPoll(poll.id,requestBody)
	}else
	{
		const requestBodyQuestion=
				{...poll,
					'canceled':true,
					'clientModified':nowDate
				}

		return updateQuestionPoll(poll.id,requestBodyQuestion)
	}
}
 
import React from 'react'
import {getPublicUrlForRepo} from '../../../utils/getPublicUrlForRepo'
import {formatRepoGradesToString} from '../../../utils/formatRepoGradesToString'
import {formatTimeSinceSubmission} from '../../../utils/formatTimeSinceSubmission'

 
class PackReviewRepoSubmission extends React.Component{ 

	onClickViewPack(e,repoLink){	
		e.stopPropagation()
		window.open(`https://${repoLink}`,'_blank')	
	}


	render(){

		const {submission,setSelectedSubission,selectedSubmission} = this.props


		const {repo}=submission
		const repoLink=getPublicUrlForRepo(repo)

		//const submissionItemActive = true
		const submissionItemActive = selectedSubmission && submission.id===selectedSubmission.id

		const repoSubject = repo.subject.name
		const repoGradeLevel = formatRepoGradesToString(repo.grade)
		
		const timeSinceSubmission = formatTimeSinceSubmission(submission.created)

			
		const submissionAssignee = submission.assignee
		
		
		return( 
			<div className={'packReview-submissionItem ' + (submissionItemActive ? 'packReview-submissionItem--active' : 'packReview-submissionItem--inactive')} onClick={()=>{setSelectedSubission(submission)}} onDoubleClick={(e)=>{this.onClickViewPack(e,repoLink)}} >
					<div className='packReview-submissionItem-submissionID'>
						{submission.shortId}
					</div>
					<div className='packReview-submissionItem-topLine'>
						<div className='packReview-submissionItem-topLine-repoName'>
						{repo.name}
						</div>
						{submission.status !== 'submitted' &&
							<div className={'packReview-submissionItem-topLine-status ' + (submission.status ? `packReview-submissionItem-topLine-status--${submission.status}` : '')}>
							{submission.status}
							</div>
						}					
						<div className='packReview-submissionItem-topLine-date'>
							{timeSinceSubmission}
						</div>						
					</div>
					
					{repo.author && 
					<div className='packReview-submissionItem-author'>
						{repo.author.firstName} {repo.author.lastName} ({repo.author.username})
					</div>					
				}
					<div className='packReview-submissionItem-metadata'>
						{repoSubject} · {repoGradeLevel}
					</div>

					{submissionAssignee && 
						<div className='packReview-submissionItem-assignee'>
							{submissionAssignee}
						</div>
					}
					


			<div className='packReview-submissionItem-bottomBorder' />			

		</div>
				

		)
	}
}


export default PackReviewRepoSubmission



import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {archiveSection,unarchiveSection,deleteSection,fetchArchivedSections} from '../../actions/sections'
import {fetchQuestionPolls,fetchSetPolls} from '../../actions/polls'
import Button from '../../components/uiKit/Button'
import SectionMarker from '../../components/uiKit/SectionMarker'

//Modal to archive/unarchive sections
class ActiveSectionRow extends React.Component{
	constructor(){
		super()	
		this.archiveSection=this.archiveSection.bind(this)
		this.state={
			submitLoading:false,
		}
	}

	archiveSection(){
		this.setState({submitLoading:true})
		this.props.archiveSection(this.props.section)
	}

	render(){
		const {section} = this.props
		const {submitLoading} =this.state

		return(
			<div className={'manageSections-sectionList-sectionRow is--activeSection' + (submitLoading ? ' manageSections-sectionList-sectionRow--submitLoading' : '')}>
				<SectionMarker section={section} />         
				<Button label='Archive' onClickFunction={this.archiveSection}/>
			</div>
		)
	}
}
 


class ArchivedSectionRow extends React.Component{

	constructor(){
		super()
		this.unarchiveSection=this.unarchiveSection.bind(this)
		this.deleteSection=this.deleteSection.bind(this)
		this.state={
			expandDelete:false,
			submitLoading:false,
		}
	}

	unarchiveSection(){
		this.setState({submitLoading:true})
		this.props.unarchiveSection(this.props.section)
	}

	deleteSection(){
		this.setState({submitLoading:true})
		this.props.deleteSection(this.props.section)
	}

	render(){
		const {section } = this.props
		const {expandDelete,submitLoading} = this.state

		return(
			<div className={'manageSections-sectionList-sectionRow is--archivedSection' + (submitLoading ? ' manageSections-sectionList-sectionRow--submitLoading' : '')}>
				<SectionMarker section={section} /> 
				
				{!expandDelete &&
					<React.Fragment>						
						<Button label='Make Active'  onClickFunction={this.unarchiveSection} />
						<Button label='Delete' disabled={section.identity?true:false} onClickFunction={()=>{this.setState({'expandDelete':true})}} />
					</React.Fragment>
				}
				{expandDelete &&
					<React.Fragment>
						<span className='manageSections-sectionList-sectionRow-confirmDeleteLabel'> Are you sure?</span>
						<Button  submitLoading={submitLoading} name='confirmDelete' label='Yes, Delete' color='red' onClickFunction={this.deleteSection} />
						<Button label='Cancel' onClickFunction={()=>{this.setState({'expandDelete':false})}} />
					</React.Fragment>
				}
				
			</div>
		)
	}
}


class ManageSectionsContainer extends Component {

	constructor(){
		super()
		this.handleArchiveSection=this.handleArchiveSection.bind(this)
		this.handleUnarchiveSection=this.handleUnarchiveSection.bind(this)
		this.deleteSection=this.deleteSection.bind(this)
		this.toggleManageSectionsTab=this.toggleManageSectionsTab.bind(this)

		this.state={
			showActiveSections:true,
		}
	}

	UNSAFE_componentWillMount() {
		this.props.fetchArchivedSections()
	}

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Manage Classes Modal',{tab:'Active'})
		}
	}

	analyticsPage(tab){
		if(window.analytics){
			window.analytics.page('Manage Classes Modal',{tab:`${tab}`})
		}
	}

	handleArchiveSection (section){
		const nowDate= new Date()
		const requestData={
			...section,
			archived:true,
			clientModified:nowDate
		}
		this.props.archiveSection(requestData,section.id).then((response) => {		
			const pathnameUrls=this.props.location.pathname.match('classes/(.*)/')
			if(pathnameUrls){
				const urlSectionId=pathnameUrls[1]
			 if(urlSectionId ===section.id){ //if currently on section that you have archived, navigate to recent
			 	this.props.history.push('/')
			 }				
			}
			
		})			
	}
	
	handleUnarchiveSection (section){
		const nowDate= new Date()
		const requestData={
			...section,
			archived:false,
			clientModified:nowDate
		}
		this.props.unarchiveSection(requestData,section.id)
	}

	deleteSection (section){
		this.props.deleteSection(section.id)
	}

	toggleManageSectionsTab(tab){
		if(tab==='Active'){
			this.setState({showActiveSections:true})
			this.analyticsPage('Active')
		}else{
			this.setState({showActiveSections:false})
			this.analyticsPage('Archived')
		}
	}

	render() {
		const {activeSections,archivedSections}=this.props
		const {showActiveSections}=this.state

		return (
			<div className='manageSections'>
				<div className='manageSections-sectionTypeView-toggles'>
					<div className={'manageSections-sectionTypeView-toggles-toggle is--activeSection' + (showActiveSections ? ' is--active' : ' is--inactive')} onClick={()=>{this.toggleManageSectionsTab('Active')}}>
						Active Classes<div className='manageSections-sectionTypeView-toggles-toggle-count'>{activeSections.length}</div>
					</div>
					<div className={'manageSections-sectionTypeView-toggles-toggle is--archiveSection' + (showActiveSections ? ' is--inactive' : ' is--active')} onClick={()=>{this.toggleManageSectionsTab('Archived')}}>
							Archived Classes<div className='manageSections-sectionTypeView-toggles-toggle-count'>{archivedSections.length}</div>
					</div>            
				</div>

				{showActiveSections &&
						<div className='manageSections-explainText'>
							Archive classes from your previous school year. <br/>
							You can unarchive any class at any time.        
						</div>
				}

				{!showActiveSections &&
					<div className='manageSections-explainText'>        
						Permanently deleting a class will destroy all the student data in that class.<br/>
						<b>Deleting a class cannot be undone.</b>
					</div>
				}

				{showActiveSections &&
					<div className='manageSections-sectionList'>
						{activeSections.map((section,index)=>
							<ActiveSectionRow key={section.id} section={section} archiveSection={this.handleArchiveSection}/>
						)}
					</div>
				}

				{!showActiveSections &&
					<div className='manageSections-sectionList'>
						{archivedSections.map((section,index)=>
							<ArchivedSectionRow key={section.id} section={section} unarchiveSection={this.handleUnarchiveSection} deleteSection={this.deleteSection}/>
						)}
					</div>
				}

				<div className='manageSections-btnContainer'>
					<Button size='xLarge' color='blue' label='Done' onClickFunction={this.props.hideModal} />
				</div>

			</div>
		)
	}
}


export default withRouter(connect(
	(state) => ({
		activeSections:state.sections,
		archivedSections:state.archivedSections
	}),
	{archiveSection,unarchiveSection,deleteSection,fetchArchivedSections,fetchQuestionPolls,fetchSetPolls}
)(ManageSectionsContainer))



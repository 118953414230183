import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux'
import { GoogleLogin } from '@react-oauth/google'
import SignInWithAppleButton from '../../components/misc/SignInWithAppleButton'
import { login, externalLogin } from '../../actions/session'
import LoginForm from '../../components/forms/LoginForm'
import Icon from '../../components/misc/Icons'
import getErrorMessageForRequest from '../../utils/getErrorMessageForRequest'
import { showVerifyPasswordToSigninModal } from '../../actions/modals'

class Login extends Component {
  constructor(props) {
    super(props)
    this.goToSignup = this.goToSignup.bind(this)
    this.handleExternalLogin = this.handleExternalLogin.bind(this)
    this.googleLogin = this.googleLogin.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
    this.onPreSubmit = this.onPreSubmit.bind(this)
    this.goToForgotPassword = this.goToForgotPassword.bind(this)
    this.appleCallback = this.appleCallback.bind(this)
    this.handleForgotPasswordKeyDown = this.handleForgotPasswordKeyDown.bind(this)
    this.handleSignupKeyDown = this.handleSignupKeyDown.bind(this)
    this.state = {
      errorMessage: null,
      isNoAccountError: false,
      submitLoading: false,
      recaptchaFailed: false,
    }
  }

  onPreSubmit() {
    this.setState({ submitLoading: true })
  }

  handleLogin(data) {
    // handle recaptcha assessment failure
    if (!data.recaptchaResponse) {
      this.setState({
        errorMessage: 'Captcha verification failed. Please try again.',
        submitLoading: false,
      })
      return
    }

    this.setState({
      errorMessage: null,
      isNoAccountError: false,
    })
    this.props.login(data)
      .then(() => {
        if (window.analytics) {
          window.analytics.track('Login Success', {})
        }
      })
      .catch((error) => {
        this.setState({ submitLoading: false })
        const errorMessage = getErrorMessageForRequest({
          requestName: 'login',
          response: error.response,
          data: { email: data.email },
        })
        this.setState({ errorMessage })
        if (error.response.status === 422) {
          this.setState({ isNoAccountError: true })
        }
        if (window.analytics) {
          let status
          if (error.response) {
            status = error.response.status
          }
          window.analytics.track('Login Failed', {
            error,
            response: error.response,
            status,
            email: data.email,
          })
        }
      })
  }

  handleExternalLogin(requestData) {
    const {
      externalLogin,
      showVerifyPasswordToSigninModal,
    } = this.props

    externalLogin(requestData, true)
      .catch((error) => {
        this.setState({ submitLoading: false })
        const { status } = error.response
        if (status === 412) {
          // get email from the header
          const email = error.response.headers.get('x-matched-email')
          showVerifyPasswordToSigninModal({ requestData, email })
        } else {
          const errorMessage = getErrorMessageForRequest({
            requestName: 'externalLogin',
            response: error.response,
          })
          this.setState({ errorMessage })
          if (status === 404) {
            this.setState({ isNoAccountError: true })
          }
        }
      })
  }

  appleCallback(data) {
    if (data.authorization) {
      this.setState({ submitLoading: true })
      const token = data.authorization.id_token
      const requestData = {
        type: 'apple',
        idToken: token,
        firstName: '-',
        lastName: '-',
      }
      if (data.user) {
        requestData.firstName = data.user.name.firstName
        requestData.lastName = data.user.name.lastName
      }
      this.handleExternalLogin(requestData)
    }
  }

  googleLogin(credentialResponse) {
    this.setState({ submitLoading: true })
    const requestData = {
      type: 'google',
      idToken: credentialResponse.credential,
    }
    this.handleExternalLogin(requestData)
  }

  goToSignup() {
    this.props.history.push('signup')
  }

  goToForgotPassword() {
    this.props.history.push('forgot-password')
  }

  handleForgotPasswordKeyDown(e) {
    if (e.keyCode === 13) this.goToForgotPassword()
  }

  handleSignupKeyDown(e) {
    if (e.keyCode === 13) this.goToSignup()
  }

  render() {
    const {
      errorMessage,
      isNoAccountError,
      recaptchaFailed,
    } = this.state
    return (
      <DocumentTitle title='Sign in - Plickers'>
        <div className='signin marketing notranslate'>

          <div className='signin-centerBlock'>
            <div className='signin-centerBlock--top' />
            <div className='signin-centerBlock--middle'>
              <div className='signin-centerBlock-logo'>
                <Icon name='logo-full' />
              </div>

              <div className='signin-centerBlock-spacer' />
              <div className='signin-thirdPartyBtnContainer'>
                <button className='signin-signInWithGoogleBtn'>
                  <GoogleLogin
                    width='300px'
                    logo_alignment='center'
                    onSuccess={this.googleLogin}
                  />
                </button>
                <SignInWithAppleButton
                  callback={this.appleCallback}
                  redirectPath='/login'
                />
              </div>

              <div className='signin-centerBlock-divider'>
                <div className='signin-centerBlock-divider-line' />
                <div className='signin-centerBlock-divider-label'>
                  or sign in with email and password
                </div>
                <div className='signin-centerBlock-divider-line' />
              </div>

              <div className={`signin-centerBlock-form ${recaptchaFailed && 'signin-centerBlock-form--disabled'}`}>
                <LoginForm
                  onPreSubmit={this.onPreSubmit}
                  onSubmit={this.handleLogin}
                  submitLoading={this.state.submitLoading}
                  goToForgotPassword={this.goToForgotPassword}
                  handleForgotPasswordKeyDown={this.handleForgotPasswordKeyDown}
                  onRecaptchaError={() => this.setState({ recaptchaFailed: true })}
                />
              </div>
              <div />
            </div>

            <div className='signin-centerBlock--bottom'>
              {recaptchaFailed && (
              <div className='signin-centerBlock-error--recaptcha'>
                <div className='signin-centerBlock-error'>
                  Email and password signin requires reCAPTCHA but it failed to load.
                </div>
                <div className='signin-centerBlock-error'>
                  Ensure google.com/recaptcha/api.js is not blocked by your browser or network.
                </div>
              </div>
              )}
              {errorMessage && (
              <div className='signin-centerBlock-error'>
                {errorMessage}
              </div>
              )}

              <div
                className={`signin-centerBlock-goToCreateAccount${isNoAccountError ? ' signin-centerBlock-goToCreateAccount--bold' : ''}`}
                role='button'
                tabIndex={0}
                onClick={this.goToSignup}
                onKeyDown={this.handleSignupKeyDown}
              >
                Don’t have an account?
                {' '}
                <span className='signin-centerBlock-goToCreateAccount-signUp'>
                  Sign up for free
                </span>
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    )
  }
}

export default withRouter(connect(
  (/* state */) => ({}),
  { login, externalLogin, showVerifyPasswordToSigninModal },
)(Login))

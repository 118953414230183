import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import FormFieldText from '../../uiKit/FormFieldText'
import Button from '../../uiKit/Button'
import FeatureModalSelectControl from '../../featureModals/FeatureModalSelectControl'
import { getNewPasswordErrorMessage } from '../../../utils/passwordValidator'

const required = (value) => (value ? undefined : 'Sorry but we are going to need you to tell us that')

class ChangePasswordForm extends React.Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>

        <Field
          name='currentPassword'
          type='password'
          label='Your existing password'
          component={FormFieldText}
          placeholder=''
          validate={[required]}
          autoFocus
        />

        <Field
          name='newPassword'
          type='password'
          label='New Password'
          subLabel='Must be at least 8 characters'
          component={FormFieldText}
          placeholder=''
          validate={[getNewPasswordErrorMessage]}
        />

        <div className='accountForm-checkbox'>
          <FeatureModalSelectControl
            checkbox
            checked={this.props.logOut}
            onClick={this.props.toggleLogout}
            label='Require all devices to sign in again with new password.'
          />
        </div>

        <div className='accountForm-actionRow'>
          <Button
            size='large'
            submitLoading={this.props.submitLoading}
            label='Update Password'
            color={this.props.dirty && this.props.valid ? 'blue' : 'gray'}
            canSubmit={!!(this.props.dirty && this.props.valid && !this.props.submitLoading)}
            type='submit'
          />
        </div>
      </form>
    )
  }
}

export default connect()(reduxForm({
  form: 'changePasswordForm',
  // onSubmitSuccess: afterSubmit
})(ChangePasswordForm))

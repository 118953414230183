import React, {Component} from 'react'
import { connect } from 'react-redux'
import {hideNotification} from '../actions/notifications'
import Notification from '../components/misc/Notification'
import { CSSTransitionGroup } from 'react-transition-group'

class NotificationContainer extends Component {
	render() {
		const {notification}=this.props
		return (
  
			<CSSTransitionGroup
				transitionName='notificationTransition'
				transitionEnterTimeout={350}
				transitionLeaveTimeout={250}
			>
				{notification &&
					<Notification
						key={notification.timestamp}
						hideNotification={this.props.hideNotification}
						message={notification.message}
						actionWord={notification.actionWord}
						actionType={notification.actionType}
						timestamp={notification.timestamp}
						button={notification.button}
					/>
				}
			</CSSTransitionGroup>        
		)
	}
}


export default connect(
	(state,ownProps) => ({
		notification: state.notification,
	}),
	{ hideNotification, 
	})(NotificationContainer)


import React from 'react'
import ItemTypes from '../DnDKit/ItemTypes'
import { DragSource } from 'react-dnd'
import Cell from './Cell'


const cellSource = {
	beginDrag(props) {
		return {cellItem:props.item,multiselectItems:props.multiselectItems,inSharedRepo:props.inSharedRepo,inPublishedRepo:props.inPublishedRepo}
	},
	canDrag(props){
		 return true
	},
	isDragging(props) {
		return props.isMultiSelectItem || props.isActive
	},
}

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		isDragging: monitor.isDragging()
	}
}


class DnDCell extends React.Component{
	
	render() {
		const { connectDragSource,multiselectItems } = this.props
		let isMultiselect=false
		if(multiselectItems && Object.keys(multiselectItems).length>1){
			isMultiselect=true
		}
		return connectDragSource(
			<div>
				<Cell {...this.props}/>
				<div className='cellRow-dragPreviewWrapper'>
					{this.props.connectDragPreview(

						<div className='cellRow-dragPreviewContainer'>
						
							
							{/* Question */}
							{!isMultiselect && this.props.item.body && 
								<div className='cellRow-dragPreview cellRow-dragPreview--question'>
									{this.props.item.body}
								</div>
							}

							{/* Set */}
							{!isMultiselect && !this.props.item.body && 
								<div className='cellRow-dragPreview cellRow-dragPreview--set'>
									{this.props.item.name}									
								</div>
							}

							{/* MultiSelect */}
							{isMultiselect &&
								<div className='cellRow-dragPreview cellRow-dragPreview--multi'>
									{Object.keys(multiselectItems).length} items
								</div>
							}								

						</div>,{offsetX:0,offsetY:0})}
				</div>
			</div>,
		)
	}
}

export default DragSource(ItemTypes.ITEMCELL, cellSource, collect)(DnDCell)


import React from 'react'
import subjects from '../../../staticData/subjects.js'
import PublishingFlowSelectControl from './PublishingFlowSelectControl'

class PublishingFlowSubjectSelectControl extends React.Component{


	render(){	
	
		const {subject,selectedSubjectId,selectedSubjectParentId,hasOptionalOtherInputBox,optionalOtherInputBoxPlaceholder,subjectOtherString,onChangeOtherInput}=this.props 
		const subjectId=subjects[subject].subjectId
		const parentId=subjects[subject].parentId
		let showDescription=true
		if(selectedSubjectId && selectedSubjectId===subjectId){
			showDescription=false
		}
		if(selectedSubjectParentId && selectedSubjectParentId===subjectId){
			showDescription=false
		}
		let checked=false
		if(selectedSubjectId=== subjectId ){
			checked=true
		}
		



		return(
			<PublishingFlowSelectControl 
				radio
				label={subjects[subject].label}
				subjectId={subjectId} 
				parentSubject={parentId} 
				checked={checked} 
				onSelect={()=>{this.props.selectSubject(subject)}}
				description={subjects[subject].description}
				showDescription={showDescription}
				optionalOtherInputBox={hasOptionalOtherInputBox}
				optionalOtherInputBoxPlaceholder={optionalOtherInputBoxPlaceholder}
				inputValue={subjectOtherString} 
				onChangeInput={onChangeOtherInput}
			/>

		)
	}
 
}




export default PublishingFlowSubjectSelectControl


import cloneDeep from 'lodash/cloneDeep'

const MARKER_SIZE = 40// width and height

function getRotatedMarkerPosition(marker,oldScale,newScale,canvasWidth,canvasHeight){
	const IMAGE_MODAL_CANVAS_WIDTH = canvasWidth
	const IMAGE_MODAL_CANVAS_HEIGHT = canvasHeight
  
	const imageCenterX = IMAGE_MODAL_CANVAS_WIDTH / 2
	const imageCenterY = IMAGE_MODAL_CANVAS_HEIGHT / 2
	const oldMarkerX = marker.x + (0.5 * MARKER_SIZE)
	const oldMarkerY = marker.y + (0.5 * MARKER_SIZE)
	const oldMarkerCenterXOffset = imageCenterX - oldMarkerX 
	const oldMarkerCenterYOffset = imageCenterY - oldMarkerY 		
	const scaleEffect = newScale / oldScale
	const newMarkerCenterXOffset = oldMarkerCenterYOffset * scaleEffect
	const newMarkerCenterYOffset = -1 * oldMarkerCenterXOffset * scaleEffect
	const newMarkerX = imageCenterX + newMarkerCenterXOffset - (0.5 * MARKER_SIZE)			
	const newMarkerY = imageCenterY + newMarkerCenterYOffset - (0.5 * MARKER_SIZE)
	return {
		x:newMarkerX,
		y:newMarkerY
	}				
}

export function rotateMarkers(oldMarkers,oldScale,newScale,canvasWidth,canvasHeight){
	let newMarkers=cloneDeep(oldMarkers)
	Object.keys(oldMarkers).forEach((key)=>{
  	const marker=oldMarkers[key]
  	if(marker.inImageContainerSafeArea){
      const newMarkerPosition = getRotatedMarkerPosition(marker,oldScale,newScale,canvasWidth,canvasHeight)
			newMarkers[`marker${marker.name}`].x=newMarkerPosition.x
			newMarkers[`marker${marker.name}`].y=newMarkerPosition.y
			newMarkers[`marker${marker.name}`].inImageContainerSafeArea=true
			newMarkers[`marker${marker.name}`].inCropSafeArea=true
  		}  		
  	})
	return newMarkers
}

export function rotateCovers(oldCovers,oldScale,newScale,canvasWidth,canvasHeight){
	const IMAGE_MODAL_CANVAS_WIDTH = canvasWidth
	const IMAGE_MODAL_CANVAS_HEIGHT = canvasHeight
  
	let newCovers=cloneDeep(oldCovers)
	Object.keys(oldCovers).forEach((key)=>{
		const cover=oldCovers[key]
		if(cover.inImageContainerSafeArea){
			const imageCenterX = IMAGE_MODAL_CANVAS_WIDTH / 2
			const imageCenterY = IMAGE_MODAL_CANVAS_HEIGHT / 2
			const oldCoverX = cover.x + (0.5 * cover.width)
			const oldCoverY = cover.y + (0.5 * cover.height)
			const oldCoverCenterXOffset = imageCenterX - oldCoverX 
			const oldCoverCenterYOffset = imageCenterY - oldCoverY 	
	  		
			const scaleEffect = newScale / oldScale
			const newCoverCenterXOffset = oldCoverCenterYOffset * scaleEffect
			const newCoverCenterYOffset = -1 * oldCoverCenterXOffset * scaleEffect
			const newCoverX = imageCenterX + newCoverCenterXOffset - (0.5 * cover.height * scaleEffect)			
			const newCoverY = imageCenterY + newCoverCenterYOffset - (0.5 * cover.width * scaleEffect)		
			const newCoverWidth = cover.height*scaleEffect
			const newCoverHeight = cover.width * scaleEffect
			newCovers[`${cover.name}`].x=newCoverX
			newCovers[`${cover.name}`].y=newCoverY
			newCovers[`${cover.name}`].width=newCoverWidth
			newCovers[`${cover.name}`].height=newCoverHeight
			newCovers[`${cover.name}`].cropAreaOverlap = { //crop area resets so cover is fully in safe area
				x:0,
				y:0,
				height:newCoverHeight,
				width:newCoverWidth
			}

		}
	})
	return newCovers
}

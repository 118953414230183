import api from '../api'
import {fetchRecentActivities,updateRecentActivities} from './recentActivities'
import {fetchQuestionPolls} from './polls'
import store from '../store'
import moment from 'moment'

 
const MINIMUM_TIME_BETWEEN_REFRESHES=1000*30 //30seconds

   
export function fetchAQuestion(questionId) {
	const state = store.getState()
	if(state.requestedQuestions[questionId]){
		return({ type: 'WAIT_TO_REFRESH'})
	}else{
		return (dispatch) =>{
			dispatch({ type: 'FETCH_QUESTION_REQUEST',questionId})
			api.fetch(`/questions/${questionId}`)
				.then((response) => {
					dispatch({ type: 'FETCH_A_QUESTION_SUCCESS', response})
					return response
				})
				.catch(err => {})
		}
	}
}

export function fetchQuestions() {
	const state = store.getState()
		if(state.questionsRefreshedTimestamp){
			const nowDate =moment()
			const timeSinceLastRefresh=nowDate - moment(state.questionsRefreshedTimestamp)
			if(timeSinceLastRefresh > MINIMUM_TIME_BETWEEN_REFRESHES){
				return (dispatch) => {
					api.batchedFetch(`/questions?archived=false&parentArchived=false&sort=modified&modifiedAfter=${state.questionsRefreshedTimestamp}`)
						.then((response) => {
							dispatch({ type: 'REFRESH_QUESTIONS_SUCCESS', response})
						})
						.catch(err => {})
					api.batchedFetch(`/questions?allRepos=true&archived=false&parentArchived=false&sort=modified&modifiedAfter=${state.questionsRefreshedTimestamp}`)
						.then((response) => {
							dispatch({ type: 'REFRESH_QUESTIONS_SUCCESS', response})
						})
						.catch(err => {})
				}
			}else{
				return({ type: 'WAIT_TO_REFRESH'})
			}
		}else return (dispatch) => { //no questionsRefreshedTimestamp (first request)
			api.batchedFetch('/questions?archived=false&parentArchived=false&sort=modified')
				.then((response) => {
					dispatch({ type: 'FETCH_QUESTIONS_SUCCESS', response})
				})
				.catch(err => {})
			api.batchedFetch('/questions?allRepos=true&archived=false&parentArchived=false&sort=modified')
				.then((response) => {
					dispatch({ type: 'FETCH_QUESTIONS_SUCCESS', response})
				})
				.catch(err => {})
		}
}

//For displaying pack preview to user who has invitation pending or disabled access 
export function fetchQuestionsPreview() {
	return (dispatch) => api.fetch('/questions/preview')
		.then((response) => {
			dispatch({ type: 'FETCH_QUESTIONS_PREVIEW_SUCCESS', response})
		})
	.catch(err => {})
}

export function fetchQuestionsNoMinTime() { //force fetch regardless of questionsRefreshedTimestamp
	//TODO clean this up
	const state = store.getState()
	if(state.questionsRefreshedTimestamp){
		return (dispatch) =>{
			api.batchedFetch(`/questions?archived=false&parentArchived=false&sort=modified&modifiedAfter=${state.questionsRefreshedTimestamp}`)
				.then((response) => {
					dispatch({ type: 'REFRESH_QUESTIONS_SUCCESS', response})
				})
				.catch(err => {})
			
			api.batchedFetch(`/questions?allRepos=true&archived=false&parentArchived=false&sort=modified&modifiedAfter=${state.questionsRefreshedTimestamp}`)
				.then((response) => {
					dispatch({ type: 'REFRESH_QUESTIONS_SUCCESS', response})
				})
			.catch(err => {})
		}
	}else return (dispatch) => {
		api.batchedFetch('/questions?archived=false&parentArchived=false&sort=modified')
			.then((response) => {
				dispatch({ type: 'FETCH_QUESTIONS_SUCCESS', response})
			})
			.catch(err => {})
	
		api.batchedFetch('/questions?allRepos=true&archived=false&parentArchived=false&sort=modified')
			.then((response) => {
				dispatch({ type: 'FETCH_QUESTIONS_SUCCESS', response})
			})
		.catch(err => {})
	}
}

export function fetchQuestionsForRepo(repoId) {
	return (dispatch) => api.batchedFetch(`/questions?archived=false&sort=modified&parentArchived=false&repo=${repoId}`)
		.then((response) => {
			dispatch({ type: 'FETCH_REPO_QUESTIONS_SUCCESS', response})
		})
		.catch(err => {})
}


export function refetchQuestions() { //TODO combine with fetchQuestionsNoMinTime
	return (dispatch) => {
		api.batchedFetch('/questions?archived=false&parentArchived=false&sort=modified')
			.then((response) => {
				dispatch({ type: 'FETCH_QUESTIONS_SUCCESS', response})
			})
			.catch(err => {})
		
		api.batchedFetch('/questions?allRepos=true&archived=false&parentArchived=false&sort=modified')
			.then((response) => {
				dispatch({ type: 'FETCH_QUESTIONS_SUCCESS', response})
			})
			.catch(err => {})
	}
}

export function fetchArchivedQuestions() {
	return (dispatch) => {
		api.batchedFetch('/questions?archived=true&parentArchived=false&sort=modified')
			.then((response) => {
				dispatch({ type: 'FETCH_ARCHIVED_QUESTIONS_SUCCESS', response})
			})
			.catch(err => {})
	
		api.batchedFetch('/questions?allRepos=true&archived=true&parentArchived=false&sort=modified')
			.then((response) => {
				dispatch({ type: 'FETCH_ARCHIVED_QUESTIONS_SUCCESS', response})
			})
			.catch(err => {})
	}
}

export function fetchArchivedQuestionsForRepo(repoId) {
	return (dispatch) => api.batchedFetch(`/questions?archived=true&parentArchived=false&sort=modified&repo=${repoId}`)
		.then((response) => {
			dispatch({ type: 'FETCH_ARCHIVED_QUESTIONS_SUCCESS', response})
		})
	.catch(err => {})
}

export function createNewQuestion(data) {
	if(typeof data.folder !== 'string'){//Prevent invalid folder value
		data.folder=null
	}
	data.lastEditedAt=data.clientModified
	return (dispatch) => api.post('/questions/',data)
		.then((question) => {
			dispatch({ type: 'CREATE_QUESTION_SUCCESS', question, syncAction:true})
			dispatch(updateRecentActivities(question.id,'question','create'))
			return question
	})
}

export function deleteQuestion(questionId) {
	return (dispatch) => api.delete(`/questions/${questionId}`)
		.then((response) => {
			dispatch({ type: 'DELETE_QUESTION_SUCCESS', questionId, syncAction:true })
			dispatch(fetchQuestionPolls())
			dispatch(fetchRecentActivities())
			return response
		})
		.catch(err => {
			return 'failed'
		})
}

export function updateQuestionContent(data,questionId) {
	data.lastEditedAt = data.clientModified	
	return (dispatch) => api.put(`/questions/${questionId}/content`,data)
		.then((response) => {
			dispatch({ type: 'UPDATE_QUESTION_SUCCESS', response,syncAction:true })
			if(!data.archived){
				dispatch(updateRecentActivities(response.id,'question','edit'))
			}
		return response
	})
	.catch(err => {
		return 'failed'
	})
}
 
export function updateQuestionMeta(data,questionId) {
	if(typeof data.folder !== 'string'){//Prevent invalid folder value
		data.folder=null
	}
	return (dispatch) => api.put(`/questions/${questionId}/meta`,data)
		.then((response) => {
			dispatch({ type: 'UPDATE_QUESTION_SUCCESS', response,syncAction:true })
			if(!data.archived){
				dispatch(updateRecentActivities(response.id,'question','edit'))
			}
		return response
	})
	.catch(err => {
		return 'failed'
	})
}
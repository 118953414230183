import find from 'lodash/find'


export function calculateStudentTotals(students,poll){
	let studentsWithTotals=[]
	students.map((student)=>{
		studentsWithTotals.push(calculateStudentTotal(student,poll))
		return null
	})
	return studentsWithTotals
}


function calculateStudentTotal(student,poll) {

	let totalAnswers=0
	let totalCorrect=0
	let totalSurveys=0

	if(poll.polls){
		Object.keys(poll.polls).map(function(key){
			const setPollPoll=poll.polls[key]
			const studentResponse=find(setPollPoll.responsesByCard, {'student':student.id})
			
			if (studentResponse) {
				if(setPollPoll.correctPercent !== -1){
					totalAnswers+=1
					if (studentResponse.answer === 'A') {
						if (setPollPoll.snapshot.choices[0].correct) {
							totalCorrect += 1
						}
					} 
					else if (studentResponse.answer === 'B') {
						if (setPollPoll.snapshot.choices[1].correct) {
							totalCorrect += 1
						}
					} 
					else if (studentResponse.answer === 'C') {
						if (setPollPoll.snapshot.choices[2]) {
							if (setPollPoll.snapshot.choices[2].correct) {
								totalCorrect += 1
							}
						}
					} 
					else if (studentResponse.answer === 'D') {
						if (setPollPoll.snapshot.choices[3]) {
							if (setPollPoll.snapshot.choices[3].correct) {
								totalCorrect += 1
							}
						}
					}
				}
				else{
					totalSurveys+=1			
				} 
			}
		return null
		})
}

	return {
		...student,
		totalAnswers:totalAnswers,
		totalCorrect:totalCorrect,
		totalSurveys:totalSurveys
	}
			
}


import React from 'react'
import SectionMarker from '../../uiKit/SectionMarker'
import ScorePill from '../../uiKit/ScorePill'
import Icon from '../../misc/Icons'

class SyncLiveViewPlayerSummary extends React.Component {
  render() {
    const { currentSection, setPoll, sectionDataOwner } = this.props
    let setTitle = ''
    let liveSetScore = ''
    if (setPoll && setPoll.snapshot) {
      setTitle = setPoll.snapshot.name
      if (setPoll.hasResponses) {
        liveSetScore = setPoll.correctPercent
      }
    }
    const activeSection = currentSection || {}
    return (
      <div className={`syncLiveView-player-summary${this.props.questionStage === 'scan' ? ' syncLiveView-player-summary--isScanning ' : ''}`}>
        <div className='syncLiveView-player-summary-section'>
          <SectionMarker section={activeSection} type='colorOnly' label='Live' />
          <div className='syncLiveView-player-summary-section-name notranslate'>
            {activeSection.name}
            {sectionDataOwner && (
              <div className='liveView-sectionDataOwnerLabel syncLiveView-player-summary-dataOwner'>
                <Icon name='organization' />
                <div className='liveView-sectionDataOwnerLabel-name syncLiveView-player-summary-dataOwner-name notranslate'>
                  {sectionDataOwner.shortName}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='syncLiveView-player-summary-setSummary'>
          <div className='syncLiveView-player-summary-setSummary-title'>
            Summary
          </div>
          <div className='syncLiveView-player-summary-setSummary-topLine'>
            <div className='syncLiveView-player-summary-setSummary-topLine-setTitle'>
              {setTitle}
            </div>

            {setPoll && setPoll.hasResponses && (liveSetScore !== -1) && (
            <div className='syncLiveView-player-summary-setSummary-topLine-setScore'>
              <ScorePill size='small' score={liveSetScore} />
            </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default SyncLiveViewPlayerSummary

import React from 'react'
import ReactSlider from 'react-slider'
import EditorIcon from '../../../../misc/EditorIcons'
import Icon from '../../../../misc/Icons'
import { formatMillisecondDuration } from '../../../../../utils/formatMillisecondDuration'
import { composeSoundWaveformUrl } from '../../../../../prosemirror/utils/composeSoundWaveformUrl'
import { composeSoundDeliveryUrl } from '../../../../../prosemirror/utils/composeSoundDeliveryUrl'
import { setNodeAttributes } from '../../../../../prosemirror/utils/editorActions/setNodeAttributes'

const DEFAULT_SOUND_FILE_NAME = 'Untitled Sound'

class EditorEditSoundModal extends React.Component {
  constructor(props) {
    super(props)
    this.playSound = this.playSound.bind(this)
    this.pauseSound = this.pauseSound.bind(this)
    this.startTimer = this.startTimer.bind(this)
    this.seekTo = this.seekTo.bind(this)
    this.restartAudio = this.restartAudio.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.handleRangeSliderChange = this.handleRangeSliderChange.bind(this)
    this.insertSound = this.insertSound.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.playbackSkip = this.playbackSkip.bind(this)
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this)
    this.soundHasUnsavedChanges = this.soundHasUnsavedChanges.bind(this)
    this.togglePlayClipModeActive = this.togglePlayClipModeActive.bind(this)
    this.restartClip = this.restartClip.bind(this)
    this.alignClipBoundToPlayerCurrentTime = this.alignClipBoundToPlayerCurrentTime.bind(this)
    this.onAudioEnd = this.onAudioEnd.bind(this)
    this.toggleShowAttribution = this.toggleShowAttribution.bind(this)

    // const url=`https://res.cloudinary.com/plickers-dev/video/upload/${props.fileId}.mp3`

    let url = 'https://res.cloudinary.com/plickers-dev/video/upload/'
    if (process.env.REACT_APP_ENV === 'production') {
      url = 'https://media.plickers.com/video/upload/'
    }
    url += `${props.fileId}.mp3`

    this.audioElement = new Audio(url)
    this.audioElement.addEventListener('ended', this.onAudioEnd)

    this.timer = null
    const durationMilliseconds = props.fullDuration * 1000

    const rangeValues = [props.start * 1000, props.end * 1000]
    const playerCurrentTimeMilliseconds = props.start * 1000
    this.audioElement.currentTime = props.start

    this.state = {
      isPlaying: false,
      durationMilliseconds,
      playerCurrentTimeMilliseconds,
      rangeValues,
      soundTitle: props.soundTitle === DEFAULT_SOUND_FILE_NAME ? '' : props.soundTitle,
      showAttributionInput: false,
      attributionString: props.attribution,
      playClipModeActive: false,
      isDraggingTrimBar: false,
      isDraggingSeekBar: false,
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown)
    if (this.props.focusTitle) {
      document.getElementById('soundTitleInputField').select()
    }
    if (window.analytics) {
      window.analytics.page('Edit Sound Modal', {
        isInsertFlow: this.props.isInsertFlow,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)
    this.audioElement.pause()
    this.stopTimer()
  }

  onAudioEnd() {
    this.audioElement.currentTime = 0
    this.setState({ isPlaying: false, playerCurrentTimeMilliseconds: 0 })
    this.stopTimer()
  }

  toggleShowAttribution() {
    if (this.state.showAttributionInput) {
      this.setState({ showAttributionInput: false })
    } else {
      this.setState({ showAttributionInput: true }, () => {
        const attributionElement = document.getElementById('soundAttributionInputField')
        if (attributionElement) {
          attributionElement.focus()
          attributionElement.select()
        }
      })
    }
  }

  onKeyDown(e) {
    e.stopPropagation()
    const inputElement = document.getElementById('soundTitleInputField')
    const isFocused = (document.activeElement === inputElement)
    const attributionElement = document.getElementById('soundAttributionInputField')
    const attributionIsFocused = (document.activeElement === attributionElement)

    if (e.keyCode === 13) {
      if (isFocused) {
        inputElement.blur()
      } else if (attributionIsFocused) {
        attributionElement.blur()
      } else {
        e.preventDefault()
        this.saveChanges()
      }
    }

    if (!isFocused && !attributionIsFocused) { // if input if focused don't play/pause
      if (e.keyCode === 32) { // spacebar
        if (e.shiftKey) {
          this.togglePlayClipModeActive()
        } else if (this.state.isPlaying) {
          this.pauseSound()
        } else {
          this.playSound()
        }
      } else if (e.keyCode === 37 && !e.shiftKey) { // left arrow
        this.playbackSkip('back', this.playbackSkipTime)
      } else if (e.keyCode === 39 && !e.shiftKey) { // right arrow
        this.playbackSkip('forward', this.playbackSkipTime)
      }

      if (e.keyCode === 32 && e.shiftKey) {
        this.togglePlayClipModeActive()
      }
      if (e.keyCode === 37 && e.shiftKey) { // left arrow
        this.playbackSkip('back', 0.1)
      }
      if (e.keyCode === 39 && e.shiftKey) { // right arrow
        this.playbackSkip('forward', 0.1)
      }
    }

    if (e.keyCode === 27) { // escape key
      if (isFocused) {
        inputElement.blur()
      } else if (!this.props.isInsertFlow && !this.soundHasUnsavedChanges()) {
        this.props.closeInsertSoundModal()
      }
    }
  }

  updatePlayerCurrentTime() {
    const { currentTime } = this.audioElement
    if (this.state.playClipModeActive) {
      if (currentTime < this.state.rangeValues[1] / 1000) {
        this.setState({ playerCurrentTimeMilliseconds: currentTime * 1000 })
      } else {
        this.pauseSound()
        this.setState((prevState) => ({
          playClipModeActive: false,
          playerCurrentTimeMilliseconds: prevState.rangeValues[1],
        }))
      }
    } else {
      this.setState({ playerCurrentTimeMilliseconds: currentTime * 1000 })
    }
  }

  togglePlayClipModeActive() {
    if (!this.state.playClipModeActive) {
      if (this.state.playerCurrentTimeMilliseconds !== this.state.rangeValues[0]) {
        this.seekTo(this.state.rangeValues[0])
      }
      this.setState({ playClipModeActive: true })
      if (!this.state.isPlaying) {
        this.playSound()
      }
    } else {
      this.seekTo(this.state.rangeValues[0])
      this.setState({ playClipModeActive: true })
      if (!this.state.isPlaying) {
        this.playSound()
      }
    }
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.updatePlayerCurrentTime()
    }, 10)
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  playSound() {
    this.setState({ isPlaying: true })
    this.startTimer()
    this.audioElement.play()
  }

  pauseSound() {
    this.setState({ isPlaying: false })
    this.audioElement.pause()
    this.stopTimer()
  }

  restartClip() {
    this.seekTo(this.state.rangeValues[0])
  }

  seekTo(milliseconds, isRangeSliderSeek) {
    this.audioElement.currentTime = milliseconds / 1000
    this.setState({ playerCurrentTimeMilliseconds: milliseconds })
    if (this.state.playClipModeActive) {
      if (milliseconds < this.state.rangeValues[0] || milliseconds > this.state.rangeValues[1]) {
        // toggle mode off if seek outside of clip
        if (isRangeSliderSeek !== true) { // if seeking by changing range then dont toggle out of play clip mode
          this.setState({ playClipModeActive: false })
        }
      }
    }
  }

  playbackSkip(skipDirection, skipLength) { // skip forwards/back 15 seconds
    const { currentTime } = this.audioElement
    let newTime
    const { playClipModeActive } = this.state
    if (skipDirection === 'forward') {
      if (playClipModeActive) {
        newTime = Math.min(currentTime + skipLength, this.state.rangeValues[1] / 1000)
      } else {
        newTime = Math.min(currentTime + skipLength, this.state.durationMilliseconds / 1000)
      }
    } else if (skipDirection === 'back') {
      if (playClipModeActive) {
        newTime = Math.max(currentTime - skipLength, this.state.rangeValues[0] / 1000)
      } else {
        newTime = Math.max(currentTime - skipLength, 0)
      }
    }

    this.seekTo(newTime * 1000)
  }

  alignClipBoundToPlayerCurrentTime(clipBound) {
    // const currentTime=this.audioElement.currentTime
    const currentTime = this.state.playerCurrentTimeMilliseconds
    let newValues
    if (clipBound === 'clipStart') {
      newValues = [currentTime, this.state.rangeValues[1]]
    } else {
      newValues = [this.state.rangeValues[0], currentTime]
    }
    this.setState({ rangeValues: newValues })
  }

  restartAudio() {
    this.audioElement.currentTime = 0
    this.setState({ playerCurrentTimeMilliseconds: 0 })
  }

  handleRangeSliderChange(sliderValues) { // range slider!
    const oldValues = this.state.rangeValues
    let startValueChanged = false
    if (sliderValues[0] !== oldValues[0]) {
      startValueChanged = true
    }
    let newSliderValue
    if (startValueChanged) {
      newSliderValue = sliderValues[0]
      const isRangeSliderSeek = true
      this.seekTo(newSliderValue, isRangeSliderSeek)
    }
    this.setState({ rangeValues: sliderValues })
  }

  insertSound() {
    let title = this.state.soundTitle
    if (!title || title.match(/^ *$/) !== null) {
      title = DEFAULT_SOUND_FILE_NAME
    }
    this.props.insertSound(this.props.isUpload, this.props.fileId, title, this.state.durationMilliseconds / 1000, this.state.rangeValues[0] / 1000, this.state.rangeValues[1] / 1000)
  }

  soundHasUnsavedChanges() {
    let hasChanges = false
    const initialRangeValues = [this.props.start * 1000, this.props.end * 1000]
    if (initialRangeValues[0] !== this.state.rangeValues[0] || initialRangeValues[1] !== this.state.rangeValues[1]) {
      hasChanges = true
    }
    if (this.state.soundTitle !== this.props.soundTitle) {
      if (!(this.state.soundTitle === '' && this.props.soundTitle === DEFAULT_SOUND_FILE_NAME)) {
        hasChanges = true
      }
    }
    return hasChanges
  }

  handleBackgroundClick() {
    const hasChanges = this.soundHasUnsavedChanges()
    if (!hasChanges) {
      this.props.closeModal()
    }
  }

  sendEditSoundAnalytics(soundAttrs, oldAttrs) {
    if (window.analytics) {
      let editedClip = false
      if (soundAttrs.start !== oldAttrs.start || soundAttrs.end !== oldAttrs.end) {
        editedClip = true
      }
      let editedTitle = false
      if (soundAttrs.title !== oldAttrs.title) {
        editedTitle = true
      }
      let editedAttribution = false
      if (soundAttrs.attribution !== oldAttrs.attribution) {
        editedAttribution = true
      }
      if (editedAttribution || editedTitle || editedClip) {
        window.analytics.track('Edit Sound', {
          title: soundAttrs.title,
          deliveryUrl: soundAttrs.deliveryUrl,
          isClipped: soundAttrs.isClipped,
          source: soundAttrs.source,
          fullDuration: soundAttrs.fullDuration,
          clipDuration: soundAttrs.end - soundAttrs.start,
          editedClip,
          editedTitle,
          editedAttribution,
          fileId: soundAttrs.fileId,
        })
      }
    }
  }

  saveChanges() {
    if (this.props.isInsertFlow) {
      this.insertSound()
    } else {
      const { rangeValues } = this.state
      const start = rangeValues[0] / 1000
      const end = rangeValues[1] / 1000
      let isClipped = false
      if (end - start !== this.state.durationMilliseconds / 1000) {
        isClipped = true
      }

      const { attrs } = this.props.node
      let title = this.state.soundTitle
      if (!title || title.match(/^ *$/) !== null) {
        title = DEFAULT_SOUND_FILE_NAME
      }
      const deliveryUrl = composeSoundDeliveryUrl(attrs.fileId, start, end, isClipped)
      const newAttrs = {
        ...attrs, title, deliveryUrl, isClipped, start, end, attribution: this.state.attributionString,
      }
      setNodeAttributes(this.props.node, this.props.nodePos, newAttrs)
      this.sendEditSoundAnalytics(newAttrs, attrs)
      this.props.closeModal()
    }
  }

  render() {
    const { fileId, isInsertFlow } = this.props
    const { durationMilliseconds } = this.state
    const {
      isPlaying, playClipModeActive, rangeValues, playerCurrentTimeMilliseconds, showAttributionInput, isDraggingSeekBar, isDraggingTrimBar,
    } = this.state
    const waveFormImageURL = composeSoundWaveformUrl(fileId, null, null, false)

    const isQuickRecordingInsertFlow = false
    const showCurrentTimestamp = true

    let primaryBtnLabel = 'Done'
    if (isInsertFlow) {
      primaryBtnLabel = 'Insert Sound Clip'
      if (rangeValues[0] !== 0 || rangeValues[1] !== durationMilliseconds) {
        primaryBtnLabel = 'Trim and Insert Sound Clip'
      }
    }

    const clipStartLeftPercentage = rangeValues[0] / durationMilliseconds
    const clipWidthPercentage = (rangeValues[1] - rangeValues[0]) / durationMilliseconds
    const noClip = Math.round(rangeValues[1] - rangeValues[0]) === Math.round(durationMilliseconds)

    let previewBarState = 'hidden'

    if (!noClip) {
      previewBarState = 'readyToPlayClip' // at rest
    }

    if (playClipModeActive && isPlaying) {
      previewBarState = 'playingClip' // show playback duration
    }

    if (playClipModeActive && !isPlaying) {
      previewBarState = 'pausedClip' // similar to at reset
    }

    if (isDraggingTrimBar) {
      previewBarState = 'draggingTrimBarHandles' // show duration
    }
    if (noClip) {
      previewBarState = 'hidden'
    }

    // const playClipProgressPercentage = 0.3
    const playClipProgressPercentage = (playerCurrentTimeMilliseconds - rangeValues[0]) / (rangeValues[1] - rangeValues[0])

    // DISPLAY MILLISECOND GRANULARITY
    let displayMillisecondGranularity = true
    let displayHourGranularity = false
    let playbackSkipTime = 5
    // let showRewindMilliseconds = 500

    if ((durationMilliseconds / 1000) > 60) { // if over 1 minute
      displayMillisecondGranularity = false // don't show millisecond granularity
      playbackSkipTime = 15 // skip 15 seconds instead of 5
      // showRewindMilliseconds = 4000 // show rewind button after 4 seconds
      if (durationMilliseconds > 3600000) {
        displayHourGranularity = true
      }
    }

    const hideForwardButton = (durationMilliseconds / 1000) < 6 // hide if less than 6 seconds

    this.playbackSkipTime = playbackSkipTime
    //
    // CLIP ALIGN BUTTONS
    //

    const clipStart = rangeValues[0]
    const clipEnd = rangeValues[1]

    let showAlignButtons = false

    if (!isPlaying && playerCurrentTimeMilliseconds > 0) {
      showAlignButtons = true
    }

    let showStartClipAlign = true
    let showEndClipAlign = true

    if (clipStart === playerCurrentTimeMilliseconds) {
      showStartClipAlign = false
      showEndClipAlign = false
    }

    if (clipEnd === playerCurrentTimeMilliseconds) {
      showEndClipAlign = false
      showStartClipAlign = false
    }

    if (playerCurrentTimeMilliseconds > clipEnd) {
      showStartClipAlign = false
    }
    if (playerCurrentTimeMilliseconds < clipStart) {
      showEndClipAlign = false
    }

    let startClipAlignDirection = 'right'
    // let startClipAlignDirection = 'left'

    if (clipStart > playerCurrentTimeMilliseconds) {
      startClipAlignDirection = 'left'
    }

    let endClipAlignDirection = 'left'
    // let endClipAlignDirection = 'right'

    if (playerCurrentTimeMilliseconds > clipEnd) {
      endClipAlignDirection = 'right'
    }

    // bring in stuff from video modal

    const formattedClipDuration = formatMillisecondDuration(rangeValues[1] - rangeValues[0], displayMillisecondGranularity, displayHourGranularity)
    const formattedPlayClipProgressTimestamp = formatMillisecondDuration(Math.max((playerCurrentTimeMilliseconds - rangeValues[0]), 0), displayMillisecondGranularity, displayHourGranularity) // use Math.max to prevent flash of negative number

    return (
      <div className="editor-editSoundModalContainer">

        <div className={`editor-editSoundModal${isDraggingTrimBar ? ' editor-editSoundModal--draggingTrimBar ' : ''}${isDraggingSeekBar ? ' editor-editSoundModal--draggingSeekBar ' : ''}${isInsertFlow ? ' editor-editSoundModalContainer--insertFlow' : ' editor-editSoundModalContainer--standaloneMode'}`}>

          <div className="editor-editSoundModal-header">
            <div className="editor-editSoundModal-header-leftContainer">

              {isInsertFlow && !isQuickRecordingInsertFlow && !this.props.soundIsFromSoundLibrary && (
              <button onClick={this.props.goBack} className="editor-editSoundModal-header-backBtn">
                <Icon name="open-left-arrow" />
                {' '}
                Insert Sound
              </button>
              )}
              {this.props.soundIsFromSoundLibrary && (
              <button onClick={this.props.goBackToSoundLibrary} className="editor-editSoundModal-header-backBtn">
                <Icon name="open-left-arrow" />
                {' '}
                Sound Library
              </button>
              )}

            </div>

            <div className="editor-editSoundModal-header-titleContainer">
              <input
                id="soundTitleInputField"
                autoFocus={this.props.focusTitle}
                className="editor-editSoundModal-header-title"
                value={this.state.soundTitle}
                placeholder={DEFAULT_SOUND_FILE_NAME}
                onChange={(e) => { this.setState({ soundTitle: e.target.value }) }}
                autoComplete="off"
              />
              <div className="editor-editSoundModal-header-title-tooltip">
                The title of the sound clip is always hidden from students
              </div>
            </div>

            <div className="editor-editSoundModal-header-rightContainer">
              <button onClick={this.props.closeModal} className="editor-editSoundModal-header-cancelBtn">
                Cancel
              </button>
            </div>

          </div>

          <div className={`editor-editSoundModal-timestampContainer ${showCurrentTimestamp ? 'editor-editSoundModal-timestampContainer--withCurrentTimestamp' : ''}`}>
            {showCurrentTimestamp && (
            <div className="editor-editSoundModal-currentTimestamp">
              {formatMillisecondDuration(this.state.playerCurrentTimeMilliseconds, displayMillisecondGranularity, displayHourGranularity)}
            </div>
            )}
            <div className="editor-editSoundModal-fullTimestamp">
              {formatMillisecondDuration(this.state.durationMilliseconds, displayMillisecondGranularity, displayHourGranularity)}
            </div>
          </div>

          <div className="editor-editSoundModal-playbackBarsContainer">

            <div className="editor-editSoundModal-seekBarContainer">
              {/* }
                <Slider
                  min={0}
                  step={0.01}
                  onChange={(value)=>{this.seekTo(value)}}
                  max={lengthSeconds}
                  value={this.state.playerCurrentTime}
                  defaultValue={0}
                  handle={handle}
                />

                <div className='editor-editSoundModal-seekBarContainer-waveformImageContainer'>
                  <img src={waveFormImageURL} />
                </div>
                */}

              <ReactSlider
                className="editor-editSoundModal-seekbar"
                thumbClassName="editor-editSoundModal-seekbar-thumb"
                trackClassName="editor-editSoundModal-seekbar-track"
                min={0}
                onBeforeChange={() => { this.setState({ isDraggingSeekBar: true }) }}
                onAfterChange={() => { this.setState({ isDraggingSeekBar: false }) }}
                value={this.state.playerCurrentTimeMilliseconds}
                onChange={(value) => { this.seekTo(value) }}
                max={this.state.durationMilliseconds}
                renderThumb={(props) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <div {...props}>
                    {isDraggingSeekBar && (
                    <div draggable={false} className="editor-editSoundModal-seekbar-thumb-tooltip">
                      {formatMillisecondDuration(this.state.playerCurrentTimeMilliseconds, displayMillisecondGranularity, displayHourGranularity)}
                    </div>
                    )}
                    <div draggable={false} className="editor-editSoundModal-seekbar-thumb-circle" />
                    <div className="editor-editSoundModal-seekbar-thumb-line" />
                  </div>
                )}
              />

            </div>

            <div className="editor-editSoundModal-trimBarContainer">

              <ReactSlider
                className="editor-editSoundModal-trimBar"
                thumbClassName="editor-editSoundModal-trimBar-thumbContainer"
                trackClassName="editor-editSoundModal-trimBar-track"
                onChange={this.handleRangeSliderChange}
                onBeforeChange={() => { this.setState({ isDraggingTrimBar: true }) }}
                onAfterChange={() => { this.setState({ isDraggingTrimBar: false }) }}
                  // step={1000}
                max={this.state.durationMilliseconds}
                min={0}
                defaultValue={this.state.rangeValues}
                value={this.state.rangeValues}
                ariaLabel={['Lower thumb', 'Upper thumb']}
                ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                renderThumb={
                    (props) => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <div {...props}>
                        <div className="editor-editSoundModal-trimBar-thumb">
                          <div className="editor-editSoundModal-trimBar-thumb-line" />
                          {/* ALIGN START */}
                          {showStartClipAlign && (
                          <button onClick={() => { this.alignClipBoundToPlayerCurrentTime('clipStart') }} className={`editor-editSoundModal-trimBar-thumb-align editor-editSoundModal-trimBar-thumb-align--start ${showAlignButtons ? 'editor-editSoundModal-trimBar-thumb-align--show' : 'editor-editSoundModal-trimBar-thumb-align--hide'}`}>
                            {startClipAlignDirection === 'right' && <EditorIcon name="openArrowRight" /> }
                            {startClipAlignDirection === 'left' && <EditorIcon name="openArrowLeft" /> }
                          </button>
                          )}

                          {/* ALIGN END */}
                          {showEndClipAlign && (
                          <button onClick={() => { this.alignClipBoundToPlayerCurrentTime('clipEnd') }} className={`editor-editSoundModal-trimBar-thumb-align editor-editSoundModal-trimBar-thumb-align--end ${showAlignButtons ? 'editor-editSoundModal-trimBar-thumb-align--show' : 'editor-editSoundModal-trimBar-thumb-align--hide'}`}>
                            {endClipAlignDirection === 'right' && <EditorIcon name="openArrowRight" /> }
                            {endClipAlignDirection === 'left' && <EditorIcon name="openArrowLeft" /> }
                          </button>
                          )}

                        </div>
                      </div>
                    )
}
                pearling={false}
                minDistance={100}
              />

              <div className="editor-editSoundModal-trimBarContainer-bgTrack" />
            </div>

            <div className="editor-editSoundModal-previewBarContainer">

              {/* AT REST AND READY TO PLAY */}
              {previewBarState === 'readyToPlayClip' && (
              <div style={{ left: `${clipStartLeftPercentage * 100}%`, width: `${clipWidthPercentage * 100}%` }} className="editor-editSoundModal-previewBar editor-editSoundModal-previewBar--readyToPlayClip">
                <div className="editor-editSoundModal-previewBar-line" />
                <button onClick={this.togglePlayClipModeActive} className="editor-editSoundModal-previewBar-btn">
                  <EditorIcon name="playbackPlayClip" />
                  {' '}
                  Play Clip
                </button>
              </div>
              )}

              {/* DRAGGING TRIM BAR HANDLES - SHOW CLIP DURAITON */}
              {previewBarState === 'draggingTrimBarHandles' && (
              <div style={{ left: `${clipStartLeftPercentage * 100}%`, width: `${clipWidthPercentage * 100}%` }} className="editor-editSoundModal-previewBar editor-editSoundModal-previewBar--draggingTrimBarHandles">
                {playClipModeActive &&
                <div style={{ width: `${playClipProgressPercentage * 100}%` }} className="editor-editSoundModal-previewBar-playClipProgressBar" />}
                <div className={`editor-editSoundModal-previewBar-line ${playClipModeActive ? 'editor-editSoundModal-previewBar-line--playingClip' : ''}`} />
                <div className={`editor-editSoundModal-previewBar-clipDuration ${playClipModeActive ? 'editor-editSoundModal-previewBar-clipDuration--playingClip' : ''}`}>
                  {' '}
                  {/* TOTAL CLIP DURATION */}
                  {playClipModeActive && (
                  <React.Fragment>
                    <span className="editor-editSoundModal-previewBar-clipDuration--playingClip--clipProgress">
                      {formattedPlayClipProgressTimestamp}
                      {' '}
                      /
                    </span>
                    <span className="editor-editSoundModal-previewBar-clipDuration--playingClip--duration">
                      {formattedClipDuration}
                    </span>
                  </React.Fragment>
                  )}
                  {!playClipModeActive && (
                  <span>
                    {formattedClipDuration}
                  </span>
                  )}
                </div>
              </div>
              )}

              {/* PLAYING CLIP - SHOW PROGRESS */}
              {previewBarState === 'playingClip' && (
              <div style={{ left: `${clipStartLeftPercentage * 100}%`, width: `${clipWidthPercentage * 100}%` }} className="editor-editSoundModal-previewBar editor-editSoundModal-previewBar--playingClip">
                <div style={{ width: `${playClipProgressPercentage * 100}%` }} className="editor-editSoundModal-previewBar-playClipProgressBar" />
                <div className="editor-editSoundModal-previewBar-line" />
                <button onClick={this.restartClip} className={`editor-editSoundModal-previewBar-clipRestartBtn ${displayMillisecondGranularity ? ' editor-editSoundModal-previewBar-clipRestartBtn--millisecond' : ''}`}>
                  <EditorIcon name="playbackRewind" />
                </button>
                <button onClick={this.pauseSound} className="editor-editSoundModal-previewBar-clipProgress">
                  <div className="editor-editSoundModal-previewBar-clipProgress-icon">
                    <EditorIcon name="playbackPause" />
                  </div>
                  <div className="editor-editSoundModal-previewBar-clipProgress-label">
                    {formattedPlayClipProgressTimestamp}
                  </div>
                </button>
              </div>
              )}

              {/* PLAYING CLIP - SHOW PROGRESS */}
              {previewBarState === 'pausedClip' && (
              <div style={{ left: `${clipStartLeftPercentage * 100}%`, width: `${clipWidthPercentage * 100}%` }} className="editor-editSoundModal-previewBar editor-editSoundModal-previewBar--resumeClip">
                <div style={{ width: `${playClipProgressPercentage * 100}%` }} className="editor-editSoundModal-previewBar-playClipProgressBar" />
                <div className="editor-editSoundModal-previewBar-line" />
                {/* }
                    <button onClick={this.restartClip} className='editor-editSoundModal-previewBar-clipRestartBtn'>
                      <EditorIcon name='playbackRewind' />
                    </button>
                    */}
                <button onClick={this.playSound} className="editor-editSoundModal-previewBar-btn">
                  Resume Clip
                </button>

              </div>
              )}

            </div>

            <div className="editor-editSoundModal-waveformBG">
              <img alt="" className="editor-editSoundModal-waveFormBG-waveform" src={waveFormImageURL} />
            </div>

            <button onClick={this.restartAudio} className="editor-editSoundModal-seekToStartBtn" />
            {' '}
            {/* SEEK TO START BUTTON */}

          </div>

          <div className="editor-editSoundModal-playbackControls">
            <button onClick={() => { this.playbackSkip('back', playbackSkipTime) }} className="editor-editSoundModal-playbackControls-seekBtn editor-editSoundModal-playbackControls-seekBtn--back">
              {playbackSkipTime === 15 &&
              <EditorIcon name="playbackSeekBack15" />}
              {playbackSkipTime === 5 &&
              <EditorIcon name="playbackSeekBack5" />}

              <div className="editor-editSoundModal-playbackControls-tooltip">
                <Icon name="open-left-arrow" />
              </div>
            </button>

            <div className="editor-editSoundModal-playbackControls-playPauseBtnContainer">
              {isPlaying && (
              <button onClick={this.pauseSound} className="editor-editSoundModal-playbackControls-playPauseBtn">
                <EditorIcon name="playbackPause" />
              </button>
              )}

              {!isPlaying && (
              <button onClick={this.playSound} className="editor-editSoundModal-playbackControls-playPauseBtn">
                <EditorIcon name="playbackPlay" />
              </button>
              )}
              <div className="editor-editSoundModal-playbackControls-tooltip">
                Spacebar
              </div>
            </div>

            <button onClick={() => { this.playbackSkip('forward', playbackSkipTime) }} disabled={hideForwardButton} className="editor-editSoundModal-playbackControls-seekBtn editor-editSoundModal-playbackControls-seekBtn--forward">
              {playbackSkipTime === 15 &&
              <EditorIcon name="playbackSeekForward15" />}
              {playbackSkipTime === 5 &&
              <EditorIcon name="playbackSeekForward5" />}
              <div className="editor-editSoundModal-playbackControls-tooltip">
                <Icon name="open-right-arrow" />
              </div>
            </button>
          </div>

          <div className="editor-editSoundModal-footer">

            <div className="editor-editSoundModal-footer-attribution">
              {!showAttributionInput && (
              <button onClick={() => { this.toggleShowAttribution() }} className="editor-editSoundModal-footer-attribution-btn">
                Add Attribution
              </button>
              )}

              {showAttributionInput && (
              <div className="editor-editSoundModal-footer-attribution-textAreaContainer">
                <div onClick={() => { this.toggleShowAttribution() }} className="editor-editSoundModal-footer-attribution-textArea-title">
                  Attribution
                </div>
                <textarea
                  id="soundAttributionInputField"
                  className="editor-editSoundModal-footer-attribution-textArea"
                  placeholder="Add attribution details"
                  resize="off"
                  value={this.state.attributionString}
                  onChange={(e) => { this.setState({ attributionString: e.target.value }) }}
                  maxLength={400}
                />
              </div>
              )}
            </div>

            <button onClick={this.saveChanges} className="editor-editSoundModal-doneBtn">
              <div className="editor-editSoundModal-doneBtn-primary">
                {primaryBtnLabel}
              </div>

              <div className="editor-editSoundModal-doneBtn-sub">
                <div className="editor-editSoundModal-doneBtn-sub-key">
                  Enter
                  <Icon name="enterKey" />
                </div>
              </div>

            </button>

            <a target="_blank" className="editor-editSoundModal-footer-helpBtn" rel="noopener noreferrer" href="https://help.plickers.com/hc/en-us/articles/4405698039067">Help: Sound Editing</a>

          </div>

        </div>

        {/* ADD: CLICK TO CLOSE – DEPENDS ON DIFFERENT FACTORS */}
        {isInsertFlow &&
        <div className="editor-editSoundModalContainerBG" />}

        {!isInsertFlow &&
        <div className="editor-editSoundModalContainerBG" onClick={this.handleBackgroundClick} />}

      </div>
    )
  }
}

export default EditorEditSoundModal

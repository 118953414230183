import React from 'react'
import ModalWrapper from '../ModalWrapper'
import UpdateUsernameContainer from '../../../containers/modals/account/UpdateUsernameContainer'

const UpdateUsernameModal = props => {

	return (

      <ModalWrapper
        {...props}
        title={'Update Username'}
        iconName=''
        closeBtn={false}
        showModalHeader={false}
        modalStyle='dark'
      >
        <UpdateUsernameContainer {...props}/>

      </ModalWrapper>

   

  )
}

export default UpdateUsernameModal
//define free/pro limits
//disableTemplates and disableImageSearch are old pro restrictions no longer used
module.exports = {
	free:{
		questionsInSetLimit:5,
		//disableTemplates:false,
		//disableImageSearch:true,
		scoreSheetDisable90Day:true,
		scoreSheetDisableCustom:true	
	},
	free_test_a:{ //free user set limit A/B test
		questionsInSetLimit:5,
		//disableTemplates:false,
		//disableImageSearch:true,
		scoreSheetDisable90Day:true,
		scoreSheetDisableCustom:true	
	},
	free_test_b:{
		questionsInSetLimit:12,
		//disableTemplates:false,
		//disableImageSearch:true,
		scoreSheetDisable90Day:true,
		scoreSheetDisableCustom:true	
	},
	plus:{
		//questionsInSetLimit:null, //used to be unlimited, now 150
		questionsInSetLimit:150,
		//disableTemplates:false,
		//disableImageSearch:false,
		scoreSheetDisable90Day:false,
		scoreSheetDisableCustom:false	
	},

}


export function getActiveQuestionChoiceCount(){
	const editorView=window.view
	let currentChoiceCount=0 //think of better way of doing this
	if(editorView){
			editorView.state.doc.forEach((node,offset,index)=>{
		if(node.type.name === "question" && node.attrs.activeslide===true){
			node.forEach((questionNode,offset,index)=>{
				if(questionNode.type.name ==='choiceGroup'){
					questionNode.forEach((choiceNode,offset,index)=>{
						currentChoiceCount+=1
					})
				}
			})
		}	
	})
	}
	return currentChoiceCount
}







export function formatSoundLibraryDuration(duration){ //duration in seconds
	let seconds = ("0" + (Math.floor(duration) % 60)).slice(-2)
	let minutes = ("0" + (Math.floor(duration / 60) % 60)).slice(-2)
	let hours = ("0" + Math.floor(duration / 3600)).slice(-2)
	if(duration<1){
		return `00:01` //minimum time 1 second as dont show ms granularity
	}else{
		if(duration<60*60){
			return `${minutes}:${seconds}`
		}else{
			return `${hours}:${minutes}:${seconds}`
		}
	}
}

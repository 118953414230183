import React, { Component } from 'react'
import { Transition, animated, config } from  'react-spring/renderprops'

class RepoReleaseModalHeader extends Component {

	render() {

		const {product, delayH1, delayH2, delayTB1, delayTB2, delayTB3} = this.props

		const showH1 = true
		const showH2 = true
		const showTextBlock1 = true
		const showTextBlock2 = true
		const showTextBlock3 = true		

		

		return ( 
			<div className='repoReleaseModal-header' >			
					
					<Transition
						native
						items={showH1}
						delay={delayH1}
						config={config.molasses}
						from={{opacity: 0, transform: `translateY(${70}px)`}}
						enter={{opacity: 1, transform: `translateY(${0}px)`}}
						leave={{opacity: 0, transform: `translateY(${70}px)`}}
					>
						{showH1 =>
								showH1 && (
									props =>

										<animated.div style={props} className='repoReleaseModal-header-h1'>
											{product === 'free' &&							
												<React.Fragment>
												Also introducing Packs
												</React.Fragment>
											}
											{product === 'pro' &&
												<React.Fragment>
												Also introducing Packs
												</React.Fragment>
											}
										</animated.div>
									)
							}

						</Transition>

						<Transition
							native
							items={showH2}
							delay={delayH2}
							config={{tension: 360, friction: 180}}
							from={{opacity: 0}}
							enter={{opacity: 1}}
							leave={{opacity: 0}}
						>
							{showH2 =>
									showH2 && (
										props =>

											<animated.div style={props} className='repoReleaseModal-header-h2'>
												{product === 'free' &&							
													<React.Fragment>
														A simple new way to organize your sets into topics or courses.
													</React.Fragment>
												}
												{product === 'pro' &&
													<React.Fragment>
														It's finally here. Share questions and plan courses together.
													</React.Fragment>
												}
											</animated.div>
										)
								}
						
							</Transition>



						<div className='repoReleaseModal-header-textBlocks'>

						<Transition
							native
							items={showTextBlock1}
							delay={delayTB1}
							config={config.molasses}
							from={{opacity: 0, transform: `translateY(${20}px)`}}
							enter={{opacity: 1, transform: `translateY(${0}px)`}}
							leave={{opacity: 0, transform: `translateY(${20}px)`}}
						>
							{showTextBlock1 =>
									showTextBlock1 && (
										props =>

											<animated.div style={props} className='repoReleaseModal-header-textBlock'>
												{product === 'free' &&							
													<React.Fragment>
														Easy to access from the side bar, <b>Packs are a great way to group</b> your folders, sets and questions.
													</React.Fragment>
												}
												{product === 'pro' &&
													<React.Fragment>
														Easy to access from the side bar, <b>Packs are a great way to organize</b> all of your folders, sets and questions.
													</React.Fragment>
												}
											</animated.div>
										)
								}
						
							</Transition>

							<Transition
							native
							items={showTextBlock2}
							delay={delayTB2}
							config={config.molasses}
							from={{opacity: 0, transform: `translateY(${20}px)`}}
							enter={{opacity: 1, transform: `translateY(${0}px)`}}
							leave={{opacity: 0, transform: `translateY(${20}px)`}}
						>
							{showTextBlock2 =>
									showTextBlock2 && (
										props =>

											<animated.div style={props} className='repoReleaseModal-header-textBlock'>
												{product === 'free' &&							
													<React.Fragment>
														Plickers Pro teachers can share Packs with teammates and friends. Start a free trial and try it out today.
													</React.Fragment>
												}
												{product === 'pro' &&
													<React.Fragment>
														<b>It’s really easy to get started.</b> Make new content inside, or drag-and-drop your existing Library stuff in seconds.
													</React.Fragment>
												}
											</animated.div>
										)
								}
						
							</Transition>

							<Transition
							native
							items={showTextBlock3}
							delay={delayTB3}
							config={config.molasses}
							from={{opacity: 0, transform: `translateY(${20}px)`}}
							enter={{opacity: 1, transform: `translateY(${0}px)`}}
							leave={{opacity: 0, transform: `translateY(${20}px)`}}
						>
							{showTextBlock3 =>
									showTextBlock3 && (
										props =>

											<animated.div style={props} className='repoReleaseModal-header-textBlock'>
												{product === 'free' &&							
													<React.Fragment>
														Also released today, <b>Layout Templates are now free for all Plickers teachers.</b> Try them out in the Set Editor today.
													</React.Fragment>
												}
												{product === 'pro' &&
													<React.Fragment>
														<b>Best of all, invite teammates or friends to your Packs</b> as editors or viewers and collaborate on lesson planning today.
													</React.Fragment>
												}
											</animated.div>
										)
								}
						
							</Transition>

					</div>

			</div>
		)
	}
}


export default RepoReleaseModalHeader




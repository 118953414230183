const QuestionBodyNodeSpec = {
   attrs: {
    fontSize:{default:64}
  },
  group: 'block',
  selectable:false,
  draggable:false,
  isolating: true,
  content: "block{1,10}",
  toDOM(node) {
    return ['questionBody', 0]},
    //return ['questionBody', 0]},
   parseDOM: [{
    tag: 'questionBody'}]


    // , getAttrs(dom) {
    //   return {
    //      fontSize: dom.getAttribute('data-fontSize')
    //   }}}]
}
export default QuestionBodyNodeSpec
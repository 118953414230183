// CardSection.js
import React from 'react'
import {CardElement} from 'react-stripe-elements'

class CardSection extends React.Component {
  render() {
    return (      
      <CardElement
			  hidePostalCode={true}			  
			  style={
			    {base: 
			    	{				      
				      color: '#0A0A18',		      
				      fontWeight: 500,
        			fontFamily: 'Effra, sans-serif',
				      fontSize: '18px',
				      lineHeight: '1.5',

				      '::placeholder': {
				        color: '#A3A2B2',
				  		},

				  		':hover::placeholder': {				        
				        color: '#BAB9C6',				        
				  		},

				  		':focus': {
				        borderColor: '#4099FF',
				  		},
				  		':focus::placeholder': {
				        color: '#D1D1D9',
				  		},
						}	
					}
			  }
			/> 
    )
  }
}

export default CardSection
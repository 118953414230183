import store from '../store'
import find from 'lodash/find'

export function isItemLocked(itemId,repoId) {	
	const state = store.getState()
	const locks= state.locks[repoId]

	const lock = find(locks, function(lock) {
    		return lock.item === itemId
	})
	const currentUser=state.user

	if(lock && currentUser && currentUser.id!==lock.user){
		return true
	} else return false
}
   
import React from 'react'
import QuestionCountBadge from '../uiKit/QuestionCountBadge'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'
import Icon from '../misc/Icons'
import {getRepoforId} from '../../utils/getRepoforId'

class RepoSelection extends React.Component{
 

	constructor(){
		super()
		this.handleSelectRepo=this.handleSelectRepo.bind(this)
	}

	handleSelectRepo(e,repo,disabled){
		e.preventDefault()
		if(disabled===false){
			this.props.moveToRepo(repo)
		}
	}
 



	render(){		
		const {item,multiSelectItems,submitLoading,selectedRepoId,repos}=this.props

		let itemName
		let numOfQuestions
		let isSet
		let currentRepoId
		let multiSelectItemCount
		if(item){
			if(item.ancestors){
				itemName = item.name
			}

			else if(item.name){
				itemName = item.name
				numOfQuestions=item.questions.length
				isSet = true
			}

			else {
				itemName = getQuestionBodyLabel(item)
			}  

			currentRepoId = item.repo  
		}else if(multiSelectItems){
			multiSelectItemCount=Object.keys(multiSelectItems).length
			currentRepoId=multiSelectItems[Object.keys(multiSelectItems)[0]].repo

		} 

		let sharedItem = false
		let publishedItem = false
		const repo=getRepoforId(currentRepoId)
		if(repo){
			sharedItem=repo.shared
			publishedItem=repo.published
		}
 

		return(		
			<div className={'selectionModal selectionModal--repo' + (isSet ? ' selectionModal--set' : '') + (submitLoading ? ' selectionModal--submitLoading' : '' )}>
				<div className='selectionModal-header'>        				                               
					<div className='selectionModal-header-title'>
						 Move To Pack
					</div>
					{item &&
						<React.Fragment>
					<div className='selectionModal-header-itemTitle'>
						 {itemName}
					</div>

					{isSet &&
						<QuestionCountBadge color='blueLight' questionCount={numOfQuestions} />
					}    
					</React.Fragment>        
				}
				{multiSelectItems &&
					<React.Fragment>
						<div className='selectionModal-header-itemTitle'>
							 {multiSelectItemCount} Items
						</div>
	 
						</React.Fragment>        
				}
				{sharedItem && !multiSelectItems &&
					<div className='selectionModal-header-sharedItemWarning'>
						If you move this item, members of the current pack may lose access to it.
					</div>
				}
				{sharedItem && multiSelectItems &&
					<div className='selectionModal-header-sharedItemWarning selectionModal-header-sharedItemWarning--multi'>
						If you move these items, members of the current pack may lose access to them.
					</div>
				}
				{publishedItem && !multiSelectItems &&
					<div className='selectionModal-header-sharedItemWarning selectionModal-header-sharedItemWarning--public'>
						If you move this item, it will no longer be publicly accessible from the current pack.
					</div>
				}
				{publishedItem && multiSelectItems &&
					<div className='selectionModal-header-sharedItemWarning selectionModal-header-sharedItemWarning--multi selectionModal-header-sharedItemWarning--publicMulti'>
						If you move these items, they will no longer be publicly accessible from the current pack.
					</div>
				}

				</div>
				<div className='selectionModal-divider' />
				<div className='selectionModal-selectionList'>
					{repos && repos.length === 0 &&					
						<div className='selectionModal-selectionList-emptyState'>
							You don't have any packs. <br/><br/> To create a Pack, click <b>New Pack</b> on the left-side navigation.						
						</div>
					}

					{repos && repos.length > 0 &&
						<React.Fragment>
							<div className='selectionModal-selectionList-helpLabel'>
								Select a Pack
							</div>
							{repos && repos.map((repo,index) => {
								const isCurrentRepo = repo.id===currentRepoId
								const submitLoadingRow = repo.id===selectedRepoId
								let disabled=false
								if(repo.role === 'viewer' ||repo.role === 'consumer' || repo.joined===false || isCurrentRepo || repo.disabled){
									disabled=true
								}
								return(
									<div className={'selectionModal-selectionList-item selectionModal-selectionList-item--repo ' + (disabled ? 'selectionModal-selectionList-item--repo--unselectable ' : '')  + (isCurrentRepo ? ' selectionModal-selectionList-item--repo--currentRepo' : '') + (submitLoadingRow ? 'selectionModal-selectionList-item--repo--submitLoading ' : '')} key={index} onClick={(e) =>{this.handleSelectRepo(e,repo,disabled)}}>
											{repo.name}
											<div className='selectionModal-selectionList-item--repo-arrow'>
												<Icon name='open-left-arrow' />
											</div>

											<div className='selectionModal-selectionList-item--repo--submitLoading-loadingSpinnerContainer'>
												<div className='selectionModal-selectionList-item--repo--submitLoading-loadingSpinner' />
											</div>
									</div>                                                 
								)
							})}

							</React.Fragment>
					}

				</div>

			</div>    

		)
	}
}



export default RepoSelection

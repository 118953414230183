//in discover we calculate approx play count from response count

function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function calculatePlayCount(responseCount){
	let playCount='0'
	if(responseCount){
		playCount=4*Math.round(responseCount/20)
	}
	if(playCount>1000){
		playCount=`${round(playCount/1000,1)}k`
	}
	return playCount
}




import React, { Component } from 'react'



class ImportQuestionsHelperSourceRow extends Component {


	render() {
		
		const {sourceName, sourceURL, sourceNotes} = this.props

		return (			
			<a className='importQuestionsHelperSourceRow' href={sourceURL} target='_blank' rel='noopener noreferrer'>					
				<div className='importQuestionsHelperSourceRow-nameContainer'>
					<div className='importQuestionsHelperSourceRow-name'>
						{sourceName}
					</div>
				</div>
				<div className='importQuestionsHelperSourceRow-typeContainer'>

					{this.props.sourceWeb &&
						<div className='importQuestionsHelperSourceRow-type importQuestionsHelperSourceRow-type--web'>
							Web
						</div>
					}
					{this.props.sourceWord &&
						<div className='importQuestionsHelperSourceRow-type importQuestionsHelperSourceRow-type--word'>
							Word Doc
						</div>
					}
					{this.props.sourcePDF &&
						<div className='importQuestionsHelperSourceRow-type importQuestionsHelperSourceRow-type--pdf'>
							PDF
						</div>
					}

					{this.props.sourceKahoot &&
						<div className='importQuestionsHelperSourceRow-type importQuestionsHelperSourceRow-type--kahoot'>
							Kahoot
						</div>
					}

				</div>				

				<div className='importQuestionsHelperSourceRow-notesContainer'>
					{sourceNotes &&
						<div className='importQuestionsHelperSourceRow-notes'>
							{sourceNotes}
						</div>
					}
				</div>
											
			</a>
		)
	}		
}





class ImportQuestionsHelperContainer extends Component {



	render() {
		


		return (
			<div className='importQuestionsHelperContainer'>
				<div className='importQuestionsHelper'>
					<div className='importQuestionsHelper-pageTitle'>
						Import Questions Helper
					</div>
					<div className='importQuestionsHelper-pageDescription'>							
							Below you'll find a range of sources used to test Import Questions (click to open link).
						<br/>
							We've included them here for the internal feedback release to help you guys quickly test things out.
						<br/>
							Please do though try out any new sources you can find – Websites, PDFs, Word Documents, anything you can think of!

					</div>


					<div className='importQuestionsHelper-section'>
						<div className='importQuestionsHelper-section-title'>
							<div className='importQuestionsHelper-section-title-blob importQuestionsHelper-section-title-blob--excellent' />
							<div className='importQuestionsHelper-section-title-label'>
								Just Copy and Paste
							</div>
						</div>

						<ImportQuestionsHelperSourceRow
							sourceName='Romeo and Juliet'
							sourceURL='http://www.olypen.com/pnkdurr/eng9/romeotest.htm'							
							sourceWeb						
							sourceNotes=''
						/>
						
						<ImportQuestionsHelperSourceRow
							sourceName='Science Review'
							sourceURL='https://create.kahoot.it/details/science-review-earth-space/d721e538-6142-44f9-a4ed-93507d0396ab'
							sourceKahoot
							sourceNotes='You need to click show answer in Kahoot. After that it should be all good'
						/>
						
						<ImportQuestionsHelperSourceRow
							sourceName='World War 2'
							sourceURL='https://www.dropbox.com/s/cnbndglj44m64n6/test_WWII.doc?dl=0'
							sourceWord	
							sourceNotes=''												
						/>
					
						<ImportQuestionsHelperSourceRow
							sourceName='Structure and Bonding'
							sourceURL='http://www.knockhardy.org.uk/sci_htm_files/mcbond.HTM'
							sourceWeb						
							sourceNotes=''
						/>

						<ImportQuestionsHelperSourceRow
							sourceName="Whats new at Kahoot"
							sourceURL='https://create.kahoot.it/details/what-s-new-at-kahoot-for-back-to-school/ac401413-7183-45e2-b1ea-66e90dcfa8cf'
							sourceKahoot
							sourceNotes='Complex Kahoot quiz with slides and polls'
						/>
						

					</div>

					<div className='importQuestionsHelper-section'>
						<div className='importQuestionsHelper-section-title'>
							<div className='importQuestionsHelper-section-title-blob importQuestionsHelper-section-title-blob--excellent' />
							<div className='importQuestionsHelper-section-title-label'>
								Fast and fairly straightforward but may need some tweaks or use some Find and Remove
							</div>
						</div>

						<ImportQuestionsHelperSourceRow
							sourceName='Slavery'
							sourceURL='https://www.english-online.at/history/slavery/slavery-multiple-choice.htm'
							sourceWeb				
							sourceNotes='Three choice example!'
						/>

						<ImportQuestionsHelperSourceRow
							sourceName='Romeo and Juliet 2'
							sourceURL='https://www.enotes.com/topics/romeo-and-juliet/quizzes/romeo-juliet-act-1-scene-4-352'
							sourceWeb				
							sourceNotes='Need to use Find and Remove. Some two choice questions as well.'
						/>

						<ImportQuestionsHelperSourceRow
							sourceName='Mean Median and Mode'
							sourceURL='https://reviewgamezone.com/mc/candidate/test/?test_id=6707&title=Mean%20Median%20Mode%20Range'
							sourceWeb			
							sourceNotes='Sneaky three choice question in the middle'								
						/>

						<ImportQuestionsHelperSourceRow
							sourceName='Seasons on Earth'
							sourceURL='https://www.mcqlearn.com/science/g7/seasons-on-earth-mcqs.php'
							sourceWeb
							sourceNotes='Find and replace works well and is v. useful here'							
						/>					

						<ImportQuestionsHelperSourceRow
							sourceName='GCSE PE'
							sourceURL='https://pegeekscorner.blogspot.com/2016/01/gcse-physical-education-multiple-choice.html'
							sourceWeb
							sourceNotes="Lots of question === lots of saved time! Question 41 has the question 42 question-body 'Sport England' contained within it, so you need to press enter to break that out. Not much can be done about that!"							
						/>				

						<ImportQuestionsHelperSourceRow
							sourceName='Tundra'
							sourceURL='https://reviewgamezone.com/mc/candidate/test/?test_id=11335&title=Tundra'
							sourceWeb
							sourceNotes="Using find remove w/line for question number works great. Two choice example as well."							
						/>


						<ImportQuestionsHelperSourceRow
							sourceName='Pyramids and Energy'
							sourceURL='https://spolem.co.uk/quizzes/a2-level-interactive-quizzes/pyramids-and-energy-multiple-choice'
							sourceWeb							
							sourceNotes="Need to check, there is a sneaky line break, but also images are pasting as file name" 
						/>


						<ImportQuestionsHelperSourceRow
							sourceName='Ecological Processes'
							sourceURL='http://wps.pearsoned.co.uk/ema_uk_he_holden_physgeo_3/230/58969/15096278.cw/content/index.html'
							sourceWeb			
							vLightWork
							sourceNotes="Over character-length question. Alert works well to find and fix."
						/>


						<ImportQuestionsHelperSourceRow
							sourceName='B2 English Test'
							sourceURL='https://www.test-english.com/use-of-english/b2/b2-english-test-2-multiple-choice-questions/'
							sourceWeb			
							sourceNotes="Some question numbers baked into question body. Need to take those out."					
						/>				

						<ImportQuestionsHelperSourceRow
							sourceName='Forces Multiple Choice Questions'
							sourceURL='https://www.mcqlearn.com/grade6/science/forces-multiple-choice-questions-answers.php'
							sourceWeb							
						/>


						<ImportQuestionsHelperSourceRow
							sourceName='Landslide!'
							sourceURL='https://www.funtrivia.com/playquiz/quiz1959941671538.html'
							sourceWeb
							sourceNotes="Some different numbers-of-choices but pretty fast to correct"							
						/>			

						<ImportQuestionsHelperSourceRow
							sourceName='Child and Omerod'
							sourceURL='https://global.oup.com/uk/orc/law/criminal/child_ormerod/resources/mcqs/ch01/'
							sourceWeb													
						/>

						<ImportQuestionsHelperSourceRow
							sourceName='Stimulus Based Multiple Choice'
							sourceURL='https://www.dropbox.com/s/6uqla1f5y13yafe/Period%201%20and%20Period%202%20-%20Stimulus%20Based%20Multiple%20Choice.docx?dl=0'
							sourceWord			
							sourceNotes='Question type is interesting - source text and follow on questions. So copy and paste in chunks. But works well!'										
						/>
						


					</div>


					<div className='importQuestionsHelper-section'>
						<div className='importQuestionsHelper-section-title'>
							<div className='importQuestionsHelper-section-title-blob importQuestionsHelper-section-title-blob--lightWork' />
							<div className='importQuestionsHelper-section-title-label'>
								Takes 2 mins or less, might be a bit tricky
							</div>
						</div>	

						<ImportQuestionsHelperSourceRow
							sourceName='Basic Photography Quiz'
							sourceURL='https://www.proprofs.com/quiz-school/story.php?title=basic-photography-quiz'
							sourceWeb
							sourceNotes='Conceivably could improve this, by removing blank line after question numers of letters? Not sure...'
						/>



						<ImportQuestionsHelperSourceRow
							sourceName='Photosynthesis'
							sourceURL='https://www.saps.org.uk/secondary/teaching-resources/1223-photosynthesis-quiz-test-your-knowledge'
							sourceWeb
							sourceNotes="Good test for people to understand how to use product. Find and remove, some fixes as well. One too-long question-body that needs to be cut out."
							midWork
						/>

						<ImportQuestionsHelperSourceRow
							sourceName='Tropical WX Quiz'
							sourceURL='http://theweatherprediction.com/quizzes/tropical/'
							sourceWeb			
							sourceNotes="Different choice lengths, mostly four-choice but 1 five-choice and some two-choice."
						/>			

						<ImportQuestionsHelperSourceRow
							sourceName='Array and Array operations'
							sourceURL='https://www.sanfoundry.com/data-structure-questions-answers-array-array-operations/'
							sourceWeb
							sourceNotes="Code snippets in para need a bit of work but can't be helped."
						/>

						<ImportQuestionsHelperSourceRow
							sourceName='Homeostasis question bank'
							sourceURL='https://www.dropbox.com/s/4a3151yctfvs1so/HighSchoolBiologyQuestionBankHomeostasisandFeedback.doc?dl=0'
							sourceWord
							sourceNotes="Generally works well. 2x2 choices matrix someplaces but easyish to spot and quick to fix."
						/>					

						<ImportQuestionsHelperSourceRow
							sourceName='From Sinners in the Hands of an Angry God'
							sourceURL='https://www.dropbox.com/s/cnu7uh6zuj469s2/ExamViewQuestionBankSinnersintheHandsofanAngryGod.pdf?dl=0'
							sourcePDF
							sourceNotes="Some annoying weird line breaks but can breeze through it pretty quickly."
							lightWork
						/>				

						<ImportQuestionsHelperSourceRow
							sourceName='Mice and Men'
							sourceURL='https://www.gradesaver.com/of-mice-and-men/study-guide/quiz1'
							sourceWeb
							sourceNotes="Question numbers stand on own line and annoying to remove. But because numbers standing alone could be choices/questions we can't autoclean them."
						/>

						<ImportQuestionsHelperSourceRow
							sourceName='Nuclear Power Plant'
							sourceURL='https://www.jobstron.com/nuclear-power-plants-test-1'
							sourceWeb
							sourceNotes="No line breaks between choices means some manual work. But can get through pretty quickly."							
						/>

						<ImportQuestionsHelperSourceRow
							sourceName='Phys Ed Content Knowledge'
							sourceURL='http://www.uwosh.edu/hperclub/pdf/0091.pdf'
							sourcePDF
							sourceNotes="Weird line breaks in PDF, but manageable to go through and manually fix though. Autoclean works nicely."						
						/>



											

					
					</div>


					<div className='importQuestionsHelper-section'>
						<div className='importQuestionsHelper-section-title'>
							<div className='importQuestionsHelper-section-title-blob importQuestionsHelper-section-title-blob--unsupported' />
							<div className='importQuestionsHelper-section-title-label'>
								Take quite a bit of work – but we don't think we can do much to speed things up
							</div>
						</div>



						<ImportQuestionsHelperSourceRow
							sourceName='Question Bank Economics'
							sourceURL='https://www.dropbox.com/s/g7vcobpkb91nz2q/ElementarySocialStudiesQuestionBankEconomics.doc?dl=0'
							sourceWord
							sourceNotes="Lots of 2 x 2 choices meaning a lot of enter presses. This isn't so bad or difficult to do. Also some line breaks for explanations."							
						/>


						<ImportQuestionsHelperSourceRow
							sourceName='Insurance Multiple Choice Questions'
							sourceURL='https://www.brainscape.com/flashcards/insurance-multiple-choice-questions-1489144/packs/2798784'
							sourceWeb			
							midWork
							sourceNotes="Adds answer choice at the end without 'Answer' so can't remove that. Also lots of long questions. Also free standing question numbers. Takes a few mins to get this in."
						/>


						<ImportQuestionsHelperSourceRow
							sourceName='Super Testers'
							sourceURL='https://www.dropbox.com/s/xc9zbjiypcu2g0a/ThirdGradeMathTestPrepWeeklyMultipleChoiceTests.pdf?dl=0'
							sourcePDF
							sourceNotes="Annoying line breaks. Also some tricky maths stuff, but works as best as can be expected... Takes time to slowly add questions in."							
						/>


						<ImportQuestionsHelperSourceRow
							sourceName='Staar Reading Test Prep'
							sourceURL='https://www.dropbox.com/s/2zymtsmnqjxuamb/1%20-%20STAAR%20Reading%20Prep%20Practice%20Test%20Set%201.pdf?dl=0'
							sourcePDF
							sourceNotes="Some funky text from PDF again, but works as best as can be hoped. Free-standing numbers like these can't be easily autocleaned."
							midWork
						/>


						<ImportQuestionsHelperSourceRow
							sourceName='FreeBee - Determining Setting'
							sourceURL='https://www.dropbox.com/s/v7rihwt2oxmcbfh/FREEBIEDeterminingSettinginLiteratureGradeReadingAssessment.pdf?dl=0'
							sourcePDF
							sourceNotes="Some weird characters used for bullet points for choices means they don't get removed with autoclean. Classic PDF line break issues."
							lightWork
						/>


						<ImportQuestionsHelperSourceRow
							sourceName='The Biosphere'
							sourceURL='https://www.cwcboe.org/cms/lib/NJ01001185/Centricity/Domain/143/The%20Biosphere/Practice%20test/practice%20test%20answers.pdf'
							sourcePDF
							sourceNotes="Lots of questions and quite a bit of weird line breaks so would take a few mins to clean up."							
						/>


						<ImportQuestionsHelperSourceRow
							sourceName='Introduction to Financial Systems'
							sourceURL='http://wps.pearsoned.co.uk/ema_uk_he_howells_econmoney_4/98/25261/6466840.cw/content/index.html'
							sourceWeb
							sourceNotes="5 choices. Without answer key this makes it time consuming to select correct answer and remove alternate - not sure how difficult for teacher to find these."
							heavyWork
						/>

					
					</div>


					
				</div>			
			</div>
		)
	}		
}




export default ImportQuestionsHelperContainer









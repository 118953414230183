export function analyticsTrackZoomMedia(control,zoomedOnWeb) {
	if(window.analytics){
		
		const snapshot=control.snapshot
		const zoomedItem=control.zoomedItem
		if(snapshot){
			let mediaType
			if(zoomedItem==='promptMedia' && snapshot.media){
				mediaType=snapshot.media.type
			}
			else if(zoomedItem==='choiceA' && snapshot.choices[0] && snapshot.choices[0].media){
				mediaType=snapshot.choices[0].media.type
			}	else if(zoomedItem==='choiceB' && snapshot.choices[1] && snapshot.choices[1].media){
				mediaType=snapshot.choices[1].media.type
			}else if(zoomedItem==='choiceC' && snapshot.choices[2] && snapshot.choices[2].media){
				mediaType=snapshot.choices[2].media.type
			}else if(zoomedItem==='choiceD' && snapshot.choices[3] && snapshot.choices[3].media){
				mediaType=snapshot.choices[3].media.type
			}
			if(zoomedOnWeb){
				window.analytics.track('Web NP zoom media',{
					zoomedItem:zoomedItem,
					mediaType:mediaType
				})
			}
			else{
				window.analytics.track('NP zoom media',{
					zoomedItem:zoomedItem,
					mediaType:mediaType
				})
			}
			
		}
	}
}








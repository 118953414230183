export default function (state=null, action) {
	switch (action.type) {
	
		case 'FETCH_QUESTION_POLLS_REQUEST':
			const fetchedDateTime = new Date()
			return fetchedDateTime.toISOString()

		case 'REFRESH_QUESTION_POLLS_REQUEST':
			const refreshedDateTime = new Date()
			return refreshedDateTime.toISOString()	

		case 'CLEAR_QUESTION_POLLS_REFRESHED_TIMESTAMP':
			return null

		case 'LOGOUT':
			return null

		default:
			return state
	}
}
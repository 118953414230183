import api from '../api'

export function findDataOwnerByUniqueName(uniqueName) {
  return async (dispatch) => {
    const dataOwners = await api.fetch(`/dataOwners?uniqueName=${uniqueName}`)
    const dataOwner = dataOwners.length ? dataOwners[0] : null
    if (dataOwner) {
      dispatch({ type: 'FETCH_DATA_OWNER_SUCCESS', response: dataOwner })
    }
    return dataOwner
  }
}

export function ensureDataOwners(user) {
  return async (dispatch, getState) => {
    const { dataOwners } = getState()
    if (!user.dataOwners) return
    user.dataOwners.forEach((dataOwnerObj) => {
      const { id } = dataOwnerObj
      if (!dataOwners[id]) {
        dispatch(fetchDataOwner(id))
      }
    })
  }
}

function fetchDataOwner(id) {
  return async (dispatch) => {
    try {
      const dataOwner = await api.fetch(`/dataOwners/${id}`)
      dispatch({ type: 'FETCH_DATA_OWNER_SUCCESS', response: dataOwner })
      return dataOwner
    } catch (e) {
      // no op
    }
  }
}

import store from '../store'
import find from 'lodash/find'

//used for Algolia search results which have folder ID but not name
export function getFolderName(folderId) {	
	const state = store.getState()
	const folder= find(state.folders, {'id':folderId})
	if(folder){
		return folder.name
	}else return null
}


import React, {Component} from 'react'
import Button from '../../components/uiKit/Button'
import Icon from '../../components/misc/Icons'
import {getPublicUrlForRepo} from '../../utils/getPublicUrlForRepo'

const BUTTON_RATINGS=[1,2,3,4,5]

class ReviewPublishedReviewStar extends Component{
	render(){		
		const {onMouseOver,onMouseLeave,btnRating,rating,mouseOverRating} = this.props
		const isActive=((rating && rating>(btnRating-1)) || (!rating && mouseOverRating && mouseOverRating>(btnRating-1)))
		return(		
			<button
				className={'selectionModal--preRateAndReview-starBtn ' + (isActive ? 'selectionModal--preRateAndReview-starBtn--active' :  'selectionModal--preRateAndReview-starBtn--inactive')}							
				onClick={()=>{this.props.setRating(btnRating)}}
				onMouseOver={()=>{onMouseOver(btnRating)}}
				onMouseLeave={onMouseLeave}
			>				
				<Icon name='star' />
			</button>					
		)
	}
}


class DuplicateAndEditFlowReview extends Component{

	constructor(props) {
		super(props)
		this.createReview=this.createReview.bind(this)
		this.state = {
			rating: null,
			mouseOverRating:null,
			feedbackText:'',
			submitLoading:false,
			showCommentBox: false
		}
	}

	componentDidMount() {
		if(window.analytics){
			window.analytics.page('Review Public Pack Modal')
		}
	}

	createReview(){
		this.setState({submitLoading:true})
		const {user}=this.props
		let userHasUsername = false
		if(user.username){
			userHasUsername=true
		}
		const requestData={
			repo:this.props.repo.id,
			rating:this.state.rating,
			comment: this.state.feedbackText,
			anonymous: !userHasUsername,
		}
		if(window.analytics){
			const {rating,feedbackText}=this.state
			window.analytics.track('Public Pack Review', {
				  rating:rating,
				  feedbackText:feedbackText,
				  repoId:this.props.repo.id,
				  repoUrl:getPublicUrlForRepo(this.props.repo),
				  anonymous:!userHasUsername,
				  repoName:this.props.repo.name
			})
		}
		this.props.createReview(requestData).then(()=>{
			this.props.onCreateReview()
			this.props.showNotification('','Public Feedback Submitted','default')
		})
	}

	render(){	
		const {rating,mouseOverRating} = this.state
		const {repo}=this.props
		return(					
     	 <React.Fragment>				
					<div className='selectionModal--preRateAndReview-packTitle'>
						{repo.name}
					</div>					
					<div className='selectionModal--preRateAndReview-headline'>
						If you find this content helpful, please <br/> <emph>rate and review</emph> it before you make a copy						
					</div>										

					<div className={'selectionModal--preRateAndReview-starBtnRowContainer ' + (rating ? 'selectionModal--preRateAndReview-starBtnRowContainer--rating' : 'selectionModal--preRateAndReview-starBtnRowContainer--noRating')}>
						<div className='selectionModal--preRateAndReview-starBtnRow'>								
							{BUTTON_RATINGS.map((btnRating)=>{
									return <ReviewPublishedReviewStar
									key={btnRating}
									btnRating={btnRating}
									rating={rating}
									mouseOverRating={mouseOverRating}
									setRating={(btnRating)=> this.setState({rating: btnRating})}
									onMouseOver={(btnRating)=> this.setState({mouseOverRating: btnRating})}
									onMouseLeave={()=> this.setState({mouseOverRating: null})}
								/>
	    				})}				 		
						</div>
					</div>					
					<Button 
						name='duplicateAndEditSubmitFeedback'
						size='xLarge'				
						label='Submit Rating'
						color='blue'
						onClickFunction={this.createReview}
						disabled={!rating}
						submitLoading={this.state.submitLoading}
					/>										
					<button
						className='selectionModal--preRateAndReview-skipReviewBtn'
						onClick={this.props.skipReviewStage}>
						I don’t want to provide any feedback on this content now
					</button>				

			</React.Fragment>	
		)
	}
}


export default DuplicateAndEditFlowReview

import {Plugin} from  'prosemirror-state'
import FormatBarView from './FormatBarView'

export function formatBarPlugin(setActiveQuestion,showNotification,onAddQuestionToSet,onDeleteActiveQuestion,questionsInSetLimit,isSetEditor) {
  return new Plugin({
    view(editorView) {
      let formatBarView = new FormatBarView(editorView,setActiveQuestion,showNotification,onAddQuestionToSet,onDeleteActiveQuestion,questionsInSetLimit,isSetEditor)
      return formatBarView
    }
  })
}

import React from 'react' 
import Icon from '../misc/Icons'
import FilterBar from '../uiKit/FilterBar'
import HistoryList from '../history/HistoryList'
import Dropdown from '../uiKit/Dropdown'
import DropdownItem from '../uiKit/DropdownItem'
import PageIcon from '../misc/PageIcons'
import TopPageHeader from '../pageHeaders/TopPageHeader'
import StickyPageHeader from '../pageHeaders/StickyPageHeader'



class SectionHistoryPage extends React.Component{

	constructor(){
		super()
		this.handleFilterChange=this.handleFilterChange.bind(this)	
		this.clearFilter=this.clearFilter.bind(this)	
	}
	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Class',{subpage:'Reports',sectionId:this.props.currentSection.id,title:this.props.currentSection.name})
		}
	}

	handleFilterChange(e){
		this.props.changeFilterTerm(e.target.value)
	}

	clearFilter(){
		const inputField = document.getElementById('filterInput')
		inputField.value=''
		inputField.blur()
		this.props.changeFilterTerm('')	
	}

	render(){		
		return[
			<div className='page-centerPanel' key='centerPanel'>

	    		{!this.props.showArchived &&
						<TopPageHeader
							location='sectionHistory'
							height={217}
							leftBadge={<Icon name='chevron-left' />}
							leftBadgeClickFunction={this.props.navigateToSectionHome}						
							header={this.props.currentSection.name}
							subHeader='Reports'
							subHeaderBadge={<PageIcon name='historySolidBGLarge' />}
							sectionHeaderSectionColor={this.props.currentSection.color}
							actionRow={
								<React.Fragment>
									<FilterBar 
										value={this.props.filterTerm}
										onChange={this.handleFilterChange} 
										id='filterInput'
										clearBtnOnClick={() =>{this.clearFilter()}}
									/>						
									<Dropdown>        									
										<DropdownItem label='View Archived Reports' onSelect={()=>{this.props.toggleArchived()}} />							
									</Dropdown>	
								</React.Fragment>}
						/>
					}

				{!this.props.showArchived &&
					<StickyPageHeader 
						location='sectionHistory' 
						leftBadge={
								<React.Fragment>
									<Icon name='chevron-left' />
									<PageIcon name='historySolidBGLarge' />
								</React.Fragment>
							}
						leftBadgeClickFunction={this.props.navigateToSectionHome}						
						header={this.props.currentSection.name}
						subHeader='Reports'
						sectionHeaderSectionColor={this.props.currentSection.color}
					/>		
				}
			
				{this.props.showArchived &&
					<TopPageHeader
						location='sectionArchivedReports'
						height={217}
						leftBadge={<Icon name='chevron-left' />}
						leftBadgeClickFunction={()=>{this.props.toggleArchived()}}
						header={this.props.currentSection.name}
						subHeader='Archived Reports'
						subHeaderBadge={<PageIcon name='historySolidBGLarge' />}
						sectionHeaderSectionColor={this.props.currentSection.color}
						actionRow={
							<React.Fragment>
								<FilterBar 
									value={this.props.filterTerm}
									onChange={this.handleFilterChange} 
									id='filterInput'
									clearBtnOnClick={() =>{this.clearFilter()}}
								/>						
								<Dropdown>        									
									<DropdownItem label='View History Reports' onSelect={()=>{this.props.toggleArchived()}} />							
								</Dropdown>	
							</React.Fragment>}
					/>	
				}

				{this.props.showArchived &&
					<StickyPageHeader 
						location='sectionHistory' 
						leftBadge={
								<React.Fragment>
									<Icon name='chevron-left' />
									<PageIcon name='historySolidBGLarge' />
								</React.Fragment>
							}
						leftBadgeClickFunction={()=>{this.props.toggleArchived()}}
						header={this.props.currentSection.name}
						subHeader='Archived Reports'
						sectionHeaderSectionColor={this.props.currentSection.color}
					/>		
				}
									
				<HistoryList
					orderedPolls={this.props.orderedPolls}
					firstItemInDateGroupIds={this.props.firstItemInDateGroupIds}
					sections={this.props.sections}
					filterTerm={this.props.filterTerm}
					isSectionHistory={true}
					threshold={10000}
					pageSize={100}
					totalPolls={this.props.totalPolls}
					showMore={this.props.showMore}

				/>
				
			</div>,
			<div className="page-rightPanel" key='rightPanel' />		
		]
	}
}



export default SectionHistoryPage
import moment from 'moment'
 

export function getReportPackDateRangeLabel(dateRange) {
	let isSameYear=false
	let isSameDay=false
	if(moment(dateRange.startDate).isSame(moment(dateRange.endDate), 'd')){
		isSameDay=true
	}else if(moment(dateRange.startDate).isSame(dateRange.endDate, 'y')){
		isSameYear=true
	}
	let endDate=dateRange.endDate
	if(moment().diff(endDate, 'days')<0){ //if end date is in the future (part way through granularity), use today
		endDate=moment()
	}
	let label
	if(isSameDay){
		label=`${moment(endDate).format('DD MMMM YYYY')}`
	}else if(isSameYear){
		label=`${moment(dateRange.startDate).format('DD MMMM')} - ${moment(endDate).format('DD MMMM YYYY')}`
	}else{
		label=`${moment(dateRange.startDate).format('DD MMMM YYYY')} - ${moment(endDate).format('DD MMMM YYYY')}`
	}

	return label
}
import store from '../store'
import find from 'lodash/find'
import {fetchAQuestion} from '../actions/questions'

 
export function getQuestionForId(questionId) {	
	if(questionId){
		const state = store.getState()
		const question= find(state.questions, {'id':questionId})
		
		if(question){
			return question
		}else {	store.dispatch(fetchAQuestion(questionId))}
	}else return null

}

  
import ReactDOM from 'react-dom'
import React from 'react'
import FontSizeCalculatorComponent from '../../components/fontSizeCalculator/FontSizeCalculatorComponent'

export function calculateMeasurements(question,fontSizeDiv){
 return new Promise((resolve, reject) =>
 {
 	const el=document.createElement("DIV")
 	el.setAttribute("id", question.questionId)
 	document.body.appendChild(el)
	let measurements={
		'bodyFS':0,
		'choiceFS':0
	}

	function updateQuestionFontSizes(perfectCombo,question){	
		const el= document.getElementById(question.questionId)
		if(el){
			measurements.bodyFS=perfectCombo.bodyFontSize
			measurements.choiceFS=perfectCombo.choiceFontSize
			let modifiedQuestion={...question}
			modifiedQuestion.measurements=measurements	
			document.body.removeChild(el)
			resolve(modifiedQuestion)
		}
	}

 	ReactDOM.render(
  	<FontSizeCalculatorComponent
	    question={question}
	    updatePerfectCombo={(perfectCombo)=>{updateQuestionFontSizes(perfectCombo,question)}}
	    updateQuestionMediaDynamicHeight={()=>{return null}}
	    mediaRatio={'MM'}
     />, 
      el)
 })

}
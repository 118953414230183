import filter from 'lodash/filter'

export function calculateDataForReportPack(polls, excludedPolls) {
  const filteredPolls = filter(polls, (poll) => {
    if (poll.setObject) {
      if (!excludedPolls.setPolls[poll.id]) {
        return poll
      }
    } else if (!excludedPolls.questionPolls[poll.id]) {
      return poll
    }
  })

  filteredPolls.forEach((poll) => {
    if (poll.setObject) {
      const filteredAndOrderedPolls = []
      poll.pollsOrderedByMinCaptured.forEach((setPollPoll) => {
        if (!excludedPolls.setPollPolls[`${poll.id}-${setPollPoll.id}`]) {
          filteredAndOrderedPolls.push(setPollPoll)
        }
      })
      poll.filteredAndOrderedPolls = filteredAndOrderedPolls
    }
  })

  return filteredPolls
}

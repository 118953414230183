import React from 'react'
import ModalWrapper from './ModalWrapper'
import RepoSelectionContainer from '../../containers/modals/RepoSelectionContainer'

const RepoSelectionModal = props => {

	return (
	    <div className="selectionModal-modal selectionModal-modal--repo">
			    <ModalWrapper
		        {...props}
		        title={'Select Pack'}		       
		        modalStyle='dark'
		        showModalHeader={false}
			    >
	        	
	        	<RepoSelectionContainer {...props} />    
 
	        </ModalWrapper>
	    </div>
	  )
	}

export default RepoSelectionModal
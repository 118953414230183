import React, { Component } from 'react'
import { connect } from 'react-redux'
import filter from 'lodash/filter'
import { sectionEmailStudentStudentCodes, syncSectionWithIntegration } from '../../actions/sections'
import { upsertIntegration } from '../../actions/integrations'
import Button from '../../components/uiKit/Button'
import SectionMarker from '../../components/uiKit/SectionMarker'
import FeatureModalSelectControl from '../../components/featureModals/FeatureModalSelectControl'
import { showNotification } from '../../actions/notifications'
import requestGoogleAuthCode from '../../utils/googleIdentity/requestGoogleAuthCode'

const CLOSE_MODAL_DELAY = 1000

class DistributeStudentCodesGCModal extends Component {
  constructor() {
    super()
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this)
    this.shareStudentCodes = this.shareStudentCodes.bind(this)
    this.handleClickCancel = this.handleClickCancel.bind(this)
    this.state = ({
      submitLoading: false,
      errorMessage: '',
      announcementMessage: '',
      ccUser: false,
    })
  }

  componentDidMount() {
    if (window.analytics) {
      window.analytics.page('GC share student codes modal')
    }
  }

  UNSAFE_componentWillMount() {
    document.body.style.overflowY = 'hidden' // prevent scrolling while modal is open
  }

  componentWillUnmount() {
    document.body.style.overflowY = '' // overlay is not supported by firefox, so default to blank (auto style comes from css)
    document.body.style.overflowY = 'overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
  }

  handleBackgroundClick(e) {
    if (!this.state.submitLoading && e.target === e.currentTarget) {
      if (window.analytics) {
        window.analytics.track('GC share student codes modal action', {
          action: 'dismiss',
          isElearningActivationFlow: this.props.isElearningActivationFlow,
        })
      }
      this.props.dismissModal() // close modal is click outside modal
    }
  }

  handleClickCancel(e) {
    e.stopPropagation()
    if (window.analytics) {
      window.analytics.track('GC share student codes modal action', {
        action: 'cancel',
        isElearningActivationFlow: this.props.isElearningActivationFlow,
      })
    }
    this.props.dismissModal()
  }

  addEmailScope() {
    const emailHint = this.props.sectionIntegrationRosterEmail
    requestGoogleAuthCode({
      scopeGroup: 'googleClassroomWithRosterEmail',
      hint: emailHint,
      onSuccess: (codeResponse) => {
        const requestBody = {
          type: 'googleClassroom',
          authCode: codeResponse.code,
        }
        this.props.upsertIntegration(requestBody)
          .then((integration) => {
            const selectedEmail = integration.email
            if (selectedEmail === emailHint) {
              this.shareStudentCodes(true)
            } else {
              this.setState({
                submitLoading: false,
                errorMessage: `Failed to send. Please select the linked Google account (${emailHint})`,
              })
            }
          })
          .catch(() => {
            this.setState({
              submitLoading: false,
              errorMessage: 'Something went wrong. Failed to send.',
            })
          })
      },
      onFailure: (errorType) => {
        this.setState({ submitLoading: false })
        if (errorType === 'noCode') return
        const errorMessage = errorType === 'insufficientScopes' ?
          'Insufficient access granted on Google Classroom' :
          `Something went wrong with Google authentication (${errorType})`
        this.setState({ errorMessage })
      },
    })
  }

  shareStudentCodes(throwIfError) {
    this.setState({ submitLoading: true, errorMessage: '' })
    const requestData = {
      message: this.state.announcementMessage,
      ccUser: this.state.ccUser,

    }
    if (window.analytics) {
      window.analytics.track('GC share student codes modal action', {
        action: 'send',
        isElearningActivationFlow: this.props.isElearningActivationFlow,
      })
    }
    // first sync section
    return this.props.syncSectionWithIntegration(this.props.section.id)
      .then(() => this.props.sectionEmailStudentStudentCodes(this.props.section.id, requestData))
      .then(() => {
        setTimeout(() => {
          this.props.dismissModal()
          this.props.showNotification('Your students should receive them immediately.', 'E-Learning links emailed!', 'create')
          this.props.onSendCodesSuccess()
          if (window.analytics) {
            window.analytics.track('GC share student codes success', {
              studentCount: this.props.section.students.length,
              customMessage: !!this.state.announcementMessage,
              customMessageText: this.state.announcementMessage,
              isElearningActivationFlow: this.props.isElearningActivationFlow,
              ccUser: this.state.ccUser,
            })
          }
        }, CLOSE_MODAL_DELAY)
      })
      .catch((e) => {
        if (throwIfError) throw e
        this.addEmailScope()
      })
  }

  render() {
    const readyToSubmit = true
    const studentCount = filter(this.props.section.students, { archived: false }).length
    let studentCountLabel
    if (studentCount === 1) {
      studentCountLabel = 'One Student'
    } else {
      studentCountLabel = `${studentCount} Students`
    }
    return (
      <div className='distributeStudentCodesGCModalContainer' onClick={this.handleBackgroundClick}>
        <div className='distributeStudentCodesGCModal'>
          <div className='distributeStudentCodesGCModal-sectionLabel'>
            <SectionMarker section={this.props.section} />
          </div>
          <div className='distributeStudentCodesGCModal-header'>
            Email Setup Links to Students with Google Classroom
          </div>
          <div className='distributeStudentCodesGCModal-description'>
            <div className='distributeStudentCodesGCModal-description-p'>
              No need to share a link every time you play. Plickers uses a unique E-Learning registration link for each student that registers their device (like a Chromebook or iPad) to Plickers.
            </div>
            <div className='distributeStudentCodesGCModal-description-p'>
              After clicking their registration link, your students can visit
              {' '}
              <emph>my.plickers.com</emph>
              {' '}
              on their device to answer live – no accounts, passwords, or daily links.
            </div>
            <div className='distributeStudentCodesGCModal-description-p'>
              <emph>Send your students a one-time email to their Google Classroom email address from Plickers containing their link.</emph>
              {' '}
              Plickers does not store or save student email addresses, and we will sync your Google Classroom roster before sending emails
            </div>

          </div>
          <div className='distributeStudentCodesGCModal-customMessage'>
            <div className='distributeStudentCodesGCModal-customMessage-title'>
              Add Message
            </div>
            <textarea
              className='distributeStudentCodesGCModal-customMessage-textArea'
              value={this.state.announcementMessage}
              onChange={(e) => { this.setState({ announcementMessage: e.target.value }) }}
              maxLength={350}
              placeholder='Add a custom message to the student link emails. Tip: Your message will appear below their link and instructions.'
            />

          </div>

          <div className='distributeStudentCodesGCModal-CCcheckboxContainer'>
            <FeatureModalSelectControl
              checkbox
              checked={this.state.ccUser}
              onClick={() => { this.setState((prevState) => ({ ccUser: !prevState.ccUser })) }}
              label={`Add yourself as CC (${this.props.sectionIntegrationRosterEmail})`}
              subLabel={studentCount > 9 ? `Note: you will receive ${studentCount} emails` : ''}
            />
          </div>

          <div className='distributeStudentCodesGCModal-buttonContainer'>
            <Button disabled={!readyToSubmit} name='distributeStudentCodesSubmitBtn' submitLoading={this.state.submitLoading} label={`Send Links to ${studentCountLabel}`} size='xLarge' color={readyToSubmit ? 'blue' : 'gray'} onClickFunction={() => this.shareStudentCodes()} />

            <div onClick={this.handleClickCancel} className='distributeStudentCodesGCModal-buttonContainer-cancelBtn'>
              Not Now
            </div>

            {this.state.errorMessage && (
              <div className='distributeStudentCodesGCModal-buttonContainer-errorMessage'>
                {this.state.errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

class DistributeStudentCodesGCModalContainer extends Component {
  render() {
    return (
      <DistributeStudentCodesGCModal
        section={this.props.section}
        dismissModal={this.props.dismissModal}
        user={this.props.user}
        showNotification={this.props.showNotification}
        onSendCodesSuccess={this.props.onSendCodesSuccess}
        sectionEmailStudentStudentCodes={this.props.sectionEmailStudentStudentCodes}
        upsertIntegration={this.props.upsertIntegration}
        sectionIntegrationRosterEmail={this.props.sectionIntegrationRosterEmail}
        syncSectionWithIntegration={this.props.syncSectionWithIntegration}
        isElearningActivationFlow={this.props.isElearningActivationFlow}
      />
    )
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  {
    showNotification,
    sectionEmailStudentStudentCodes,
    upsertIntegration,
    syncSectionWithIntegration,
  },
)(DistributeStudentCodesGCModalContainer)

import React, { Component } from 'react'
import ReactGA from 'react-ga4'
import { connect } from 'react-redux'
import moment from 'moment'
import find from 'lodash/find'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import { updateControl, updateControlSettings, proposeControl } from '../../actions/control'
import { playPoll } from '../../utils/playItem'
import { getPollForControl, getSetPollForControl } from '../../utils/liveView/getPollForControl'
import { buildQuestionsAndPollsList } from '../../utils/buildQuestionsAndPollsList'
import { isSurvey } from '../../utils/isSurvey'
import SyncLiveViewPage from '../../components/liveView/sync/SyncLiveViewPage'
import LiveViewPerfectScoreCelebration from '../../components/liveView/LiveViewPerfectScoreCelebration'
import { calibratedCurrentTime } from '../../utils/calibratedCurrentTime'

// E-Learning NP page

const PERFECT_SCORE_DISPLAY_TIME = 6000// time in ms
const showCorrectSubmitLoadingTime = 2000
const showGraphSubmitLoadingTime = 500

class SyncLiveViewContainer extends Component {
  constructor(props) {
    super(props)
    this.handlePlayPoll = this.handlePlayPoll.bind(this)
    this.toggleShowCorrect = this.toggleShowCorrect.bind(this)
    this.toggleShowGraph = this.toggleShowGraph.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.saveControlChanges = this.saveControlChanges.bind(this)
    this.playNextQuestion = this.playNextQuestion.bind(this)
    this.playPreviousQuestion = this.playPreviousQuestion.bind(this)
    this.onMouseEnterChoice = this.onMouseEnterChoice.bind(this)
    this.onMouseEnterChoicesGroup = this.onMouseEnterChoicesGroup.bind(this)
    this.onMouseLeaveChoicesGroup = this.onMouseLeaveChoicesGroup.bind(this)
    this.showPerfectScoreCelebration = this.showPerfectScoreCelebration.bind(this)
    this.hidePerfectScoreCelebration = this.hidePerfectScoreCelebration.bind(this)
    this.handleToggleShowCorrect = this.handleToggleShowCorrect.bind(this)
    this.handleToggleShowGraph = this.handleToggleShowGraph.bind(this)
    this.playQuestionInSet = this.playQuestionInSet.bind(this)
    this.saveControlChanges = debounce(this.saveControlChanges, 150, { leading: false })

    this.state = ({
      hoveredChoice: null,
      choicesGroupHovered: false,
      showPerfectScoreCelebration: false,
      showCorrectSubmitLoading: false,
      showGraphSubmitLoading: false,
    })
    this.questionsAndPollsList = null
    this.timeout = null
    this.perfectScoreTimeout = null
    this.showCorrectTimeout = null
    this.showGraphTimeout = null
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
    if (this.props.visible) {
      document.body.style.overflowY = 'hidden'
      if (window.analytics) {
        window.analytics.page('Sync Now Playing')
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.control && nextProps.currentSection && (nextProps.control.setPoll || nextProps.control.currentPoll)) {
      if (nextProps.control.setPoll) {
        const setPoll = getSetPollForControl(nextProps.control)
        if (setPoll && setPoll.snapshot) {
          this.questionsAndPollsList = buildQuestionsAndPollsList(setPoll)
        }
      } else this.questionsAndPollsList = null
    }
    if (!this.props.visible && nextProps.visible) {
      document.body.style.overflowY = 'hidden'
      window.addEventListener('keydown', this.handleKeyDown)
    } else if (this.props.visible && !nextProps.visible) {
      document.body.style.overflowY = '' // overlay is not supported by firefox, so default to blank (auto style comes from css)
      document.body.style.overflowY = 'overlay'
      window.removeEventListener('keydown', this.handleKeyDown)
    }
    if (nextProps.visible && !this.props.visible) {
      if (typeof window.gtag === 'function') {
        ReactGA.set({ page: '/nowplaying' })
        ReactGA.send({ hitType: 'pageview' })
      }
      if (window.analytics) {
        window.analytics.page('Sync Now Playing')
      }
    }
    if (this.props.perfectScoreIsDisplayed(nextProps) && !this.props.perfectScoreIsDisplayed(this.props)) {
      this.showPerfectScoreCelebration()
    } else if (!this.props.perfectScoreIsDisplayed(nextProps) && this.state.showPerfectScoreCelebration) {
      this.hidePerfectScoreCelebration()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  // we show an progress bar/animation for toggle show correct and graph so it feels to the teacher like its doing something
  handleToggleShowCorrect() {
    const { control } = this.props
    if (control && control.revealAnswer) {
      return this.toggleShowCorrect().then(() => {
      })
    }
    if (this.showCorrectTimeout) {
      this.setState({ showCorrectSubmitLoading: false })
      clearTimeout(this.showCorrectTimeout)
      this.showCorrectTimeout = null
    } else {
      this.setState({ showCorrectSubmitLoading: true })
      this.showCorrectTimeout = setTimeout(() => this.toggleShowCorrect().then(() => {
        this.setState({ showCorrectSubmitLoading: false })
        this.showCorrectTimeout = null
      }), showCorrectSubmitLoadingTime)
    }
  }

  handleToggleShowGraph() {
    const { control } = this.props
    if (control && control.showGraph) {
      return this.toggleShowGraph().then(() => {
      })
    }
    if (this.showGraphTimeout) {
      this.setState({ showGraphSubmitLoading: false })
      clearTimeout(this.showGraphTimeout)
      this.showGraphTimeout = null
    } else {
      this.setState({ showGraphSubmitLoading: true })
      this.showGraphTimeout = setTimeout(() => this.toggleShowGraph().then(() => {
        this.setState({ showGraphSubmitLoading: false })
        this.showGraphTimeout = null
      }), showGraphSubmitLoadingTime)
    }
  }

  toggleShowCorrect() {
    const { control } = this.props
    const nowDate = calibratedCurrentTime()
    const newControl = {
      ...control,
      controlled: nowDate,
      revealAnswer: !control.revealAnswer,
    }
    if (window.analytics) {
      let type = 'show'
      if (control.revealAnswer) {
        type = 'hide'
      }
      window.analytics.track('Sync NP toggle reveal answer', {
        type,
        graphShown: control.showGraph,

      })
    }
    return this.props.updateControlSettings(newControl).then((response) => response)
  }

  toggleShowGraph() {
    const { control } = this.props
    const nowDate = calibratedCurrentTime()
    const newControl = {
      ...control,
      controlled: nowDate,
      showGraph: !control.showGraph,
    }
    if (window.analytics) {
      let type = 'show'
      if (control.showGraph) {
        type = 'hide'
      }
      window.analytics.track('Sync NP toggle show graph', {
        type,
        answerRevealed: control.revealAnswer,
        isSurvey: isSurvey(control.snapshot),
      })
    }
    return this.props.updateControlSettings(newControl).then((response) => response)
  }

  showPerfectScoreCelebration() {
    this.setState({ showPerfectScoreCelebration: true })
    this.perfectScoreTimeout = setTimeout(() => {
      this.setState({ showPerfectScoreCelebration: false })
    }, PERFECT_SCORE_DISPLAY_TIME)
  }

  hidePerfectScoreCelebration() {
    this.setState({ showPerfectScoreCelebration: false })
    if (this.perfectScoreTimeout) {
      clearTimeout(this.perfectScoreTimeout)
      this.perfectScoreTimeout = null
    }
  }

  handleKeyDown(e) {
    if (this.props.visible) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault()
        const { control } = this.props
        let poll
        if (control && (control.setPoll || control.currentPoll)) {
          poll = getPollForControl(control)
        }
        let isReviewScreen = false
        if (!control.scanning && poll && poll.hasResponses) {
          isReviewScreen = true
        }
        if (isReviewScreen) {
          this.handleToggleShowCorrect()
        } else if (!control.showGraph && !control.revealAnswer) {
          this.props.toggleScanning()
        }
      } else if (e.keyCode === 13 && e.shiftKey) {
        e.preventDefault()
        this.handleToggleShowGraph()
      } else if (e.keyCode === 39 && e.shiftKey) {
        this.playNextQuestion()
      } else if (e.keyCode === 37 && e.shiftKey) {
        this.playPreviousQuestion()
      } else if (e.keyCode === 27) { // escape key
        this.props.minimiseLiveView()
      }
    }
  }

  playNextQuestion() {
    let currentQuestionIndex = 0
    if (this.questionsAndPollsList) {
      currentQuestionIndex = this.questionsAndPollsList.findIndex((questionAndPoll) => {
        if (questionAndPoll && this.props.control) {
          if (questionAndPoll.questionSnapshot && this.props.control.snapshot) {
            return questionAndPoll.questionSnapshot.questionId === this.props.control.snapshot.questionId
          }
        } return null
      })
    }
    if (this.questionsAndPollsList && currentQuestionIndex !== this.questionsAndPollsList.length - 1) {
      this.playQuestionInSet(this.questionsAndPollsList[currentQuestionIndex + 1])
    }
  }

  playPreviousQuestion() {
    let currentQuestionIndex = 0
    if (this.questionsAndPollsList) {
      currentQuestionIndex = this.questionsAndPollsList.findIndex((questionAndPoll) => {
        if (questionAndPoll && this.props.control) {
          if (questionAndPoll.questionSnapshot && this.props.control.snapshot) {
            return questionAndPoll.questionSnapshot.questionId === this.props.control.snapshot.questionId
          }
        } return null
      })
    }
    if (this.questionsAndPollsList && currentQuestionIndex !== 0) {
      this.playQuestionInSet(this.questionsAndPollsList[currentQuestionIndex - 1])
    }
  }

  toggleScanning() {
    const nowDate = calibratedCurrentTime()
    let countdownEndsAt = null
    if (this.state.countDownTimerStartSecondsLength) {
      countdownEndsAt = moment(calibratedCurrentTime()).add(this.state.countDownTimerStartSecondsLength, 's')
    }
    let newControl = this.props.control
    const newScanning = !this.props.control.scanning
    newControl = {
      ...newControl,
      controlled: nowDate,
      scanning: newScanning,
      revealAnswer: false,
      showGraph: false,
      countdownEndsAt: (newScanning ? countdownEndsAt : null),
    }
    if (window.analytics) {
      const { onlineStudents, control } = this.props
      const section = this.props.currentSection
      let poll
      if (control && (control.setPoll || control.currentPoll)) {
        poll = getPollForControl(control)
      }
      let type = 'start'
      if (this.props.control.scanning) {
        type = 'stop'
      }
      const hasResponses = poll && poll.hasResponses
      let onlineStudentsCount = 0
      if (onlineStudents) {
        onlineStudentsCount = Object.keys(onlineStudents).length
      }
      // const onlineStudentsCount=Object.keys(onlineStudents).length
      let students = []
      if (section) {
        students = section.students
      }
      const filteredStudents = filter(students, { archived: false })
      const totalStudentCount = filteredStudents.length

      let responseCount = 0
      if (poll && poll.responsesByCard) {
        responseCount = Object.keys(poll.responsesByCard).length
      }

      let moreResponsesThanOnlineStudents = false
      if (responseCount > onlineStudentsCount) {
        moreResponsesThanOnlineStudents = true
      }
      let reOpeningScan = false
      if (type === 'start' && hasResponses) {
        reOpeningScan = true
      }

      const hasCountdown = !!countdownEndsAt
      let countdownSeconds = null
      if (this.state.countDownTimerStartSecondsLength) {
        countdownSeconds = this.state.countDownTimerStartSecondsLength - 1
      }
      const { currentSectionAuthorizations, currentSectionPresenceMembers } = this.props
      window.analytics.track('Sync NP toggle scan', {
        type,
        hasCountdown,
        countdownSeconds,
        reOpeningScan,
        onlineStudentsCount,
        totalStudentCount,
        responseCount,
        moreResponsesThanOnlineStudents,
        currentSectionAuthorizations,
        currentSectionPresenceMembers,
      })
    }
    this.setState({ control: newControl })
    this.saveControlChanges()
    this.props.proposeControl(newControl)
  }

  saveControlChanges() {
    this.props.updateControl(this.state.control)
  }

  playQuestionInSet(questionAndPoll) {
    let { control } = this.props
    if (control && !control.scanning) {
      const snapshot = questionAndPoll.questionSnapshot
      const nowDate = calibratedCurrentTime()
      let setPollPollId
      if (questionAndPoll.poll) {
        setPollPollId = questionAndPoll.poll.id
      } else {
        setPollPollId = questionAndPoll.questionSnapshot.questionId
      }
      control = {
        ...control,
        controlled: nowDate,
        scanning: false,
        setPollPoll: setPollPollId,
        snapshot,
        showGraph: false,
        revealAnswer: false,
      }
      this.setState({ control })
      this.saveControlChanges()
      this.props.proposeControl(control)
    }
  }

  handlePlayPoll(poll, item) {
    this.props.playPoll(poll, item)
  }

  onMouseEnterChoice(choiceLetter) { // when mouse over choice highlight students that responded with that choice
    this.setState({ hoveredChoice: choiceLetter })
  }

  onMouseEnterChoicesGroup() {
    this.timeout = setTimeout(() => {
      this.setState({ choicesGroupHovered: true })
    }, 400)
  }

  onMouseLeaveChoicesGroup() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.setState({ choicesGroupHovered: false, hoveredChoice: null })
  }

  render() {
    const { control } = this.props
    const isScan = (control && control.scanning)
    let isSet = false
    let currentQuestionIndex = 0
    if (this.questionsAndPollsList) {
      currentQuestionIndex = this.questionsAndPollsList.findIndex((questionAndPoll) => {
        if (questionAndPoll && this.props.control) {
          if (questionAndPoll.questionSnapshot && this.props.control.snapshot) {
            return questionAndPoll.questionSnapshot.questionId === this.props.control.snapshot.questionId
          }
        } return null
      })
    }

    const displayStyle = this.props.visible !== false ?
      {
        visibility: 'visible',
        opacity: '1',

      } :
      {
        visibility: 'hidden',
        opacity: '0',
      }

    let poll
    let setPoll
    if (control && (control.setPoll || control.currentPoll)) {
      poll = getPollForControl(control)
    }
    if (control.setPoll) {
      setPoll = getSetPollForControl(control)
      isSet = true
    }

    let questionIsSurvey = false
    if (control && control.snapshot) {
      questionIsSurvey = isSurvey(control.snapshot)
    }

    let questionStage = 'ask'
    if (isScan) {
      questionStage = 'scan'
    } else if (poll && poll.hasResponses) {
      questionStage = 'review'
    }

    return (
      <div style={displayStyle} id='syncViewScrollableDiv' className='syncLiveViewContainer'>
        <SyncLiveViewPage
          currentSection={this.props.currentSection}
          setPoll={setPoll}
          currentPollId={this.props.currentPollId}
          currentSetPollId={this.props.currentSetPollId}
          currentSetPollPollId={this.props.currentSetPollPollId}
          currentSectionDataOwner={this.props.currentSectionDataOwner}
          poll={poll}
          currentQuestionIndex={currentQuestionIndex}
          questionsAndPollsList={this.questionsAndPollsList || []}
          questionStage={questionStage}
          toggleScanning={this.props.toggleScanning}
          toggleShowCorrect={this.handleToggleShowCorrect}
          toggleShowGraph={this.handleToggleShowGraph}
          control={control}
          showGraph={control && control.showGraph}
          revealAnswer={control && control.revealAnswer}
          playQuestionInSet={this.playQuestionInSet}
          snapshot={control.snapshot}
          isSurvey={questionIsSurvey}
          minimiseLiveView={this.props.minimiseLiveView}
          stopLiveView={this.props.stopLiveView}
          isSet={isSet}
          hoveredChoice={this.state.hoveredChoice}
          choicesGroupHovered={this.state.choicesGroupHovered}
          onMouseEnterChoice={this.onMouseEnterChoice}
          onMouseEnterChoicesGroup={this.onMouseEnterChoicesGroup}
          onMouseLeaveChoicesGroup={this.onMouseLeaveChoicesGroup}
          onlineStudents={this.props.onlineStudents}
          showCorrectSubmitLoading={this.state.showCorrectSubmitLoading}
          showGraphSubmitLoading={this.state.showGraphSubmitLoading}
          handleCountdownEnd={this.props.handleCountdownEnd}
          setCountdownSeconds={this.props.setCountdownSeconds}
          cancelCountdown={this.props.cancelCountdown}
          countDownTimerStartSecondsLength={this.props.countDownTimerStartSecondsLength}
          incrementCountdown={this.props.incrementCountdown}
          switchNowPlayingMode={this.props.switchNowPlayingMode}
          nowPlayingMode={this.props.nowPlayingMode}
          onlineStudentsCount={this.props.onlineStudentsCount}
          toggleClassSafeMode={this.props.toggleClassSafeMode}
          classSafeMode={this.props.classSafeMode}
        />

        <LiveViewPerfectScoreCelebration
          showPerfectScoreCelebration={this.state.showPerfectScoreCelebration}
          windowWidth={window.innerWidth}
          windowHeight={window.innerHeight}
          totalDisplayTime={PERFECT_SCORE_DISPLAY_TIME}
          hidePerfectScoreCelebration={this.hidePerfectScoreCelebration}
        />

      </div>
    )
  }
}

function mapStateToProps(state) {
  let { control } = state
  const { dataOwners, proposedControl } = state
  if (proposedControl && new Date(proposedControl.controlled) > new Date(control.controlled)) {
    control = proposedControl
  }
  let currentSection = null
  let currentPollId = null
  let currentSetPollId = null
  let currentSetPollPollId = null
  let currentSectionAuthorizations = []
  let currentSectionPresenceMembers = {}
  let currentSectionDataOwner = null

  let queue = []
  if (state.control) {
    currentSetPollId = control.setPoll
    currentSetPollPollId = control.setPollPoll
    currentPollId = control.currentPoll
    currentSection = find(state.sections, { id: state.control.section })
    const currentSectionQueueQuestionPolls = filter(state.queueQuestionPolls, { section: state.control.section })
    const currentSectionQueueSetPolls = filter(state.queueSetPolls, { section: state.control.section })
    queue = sortBy(currentSectionQueueQuestionPolls.concat(currentSectionQueueSetPolls), 'planned')

    if (queue[0]) {
      if (queue[0].id === control.currentPoll || queue[0].id === control.setPoll) {
        queue.splice(0, 1) // remove from upnext queue if currently being played
      }
    }

    currentSectionAuthorizations = state.sectionAuthorizations[state.control.section]
    currentSectionPresenceMembers = state.sectionPresenceMembers[state.control.section]

    if (currentSection?.dataOwner) {
      const dataOwner = dataOwners[currentSection.dataOwner.id]
      currentSectionDataOwner = dataOwner
    }
  }

  const onlineStudents = {}
  if (currentSectionPresenceMembers) {
    Object.keys(currentSectionPresenceMembers).forEach((sessionId) => {
      const authorization = find(currentSectionAuthorizations, { session: sessionId })
      if (authorization) {
        onlineStudents[authorization.student] = true
      }
    })
  }
  return {
    control,
    queue,
    currentSection,
    currentPollId,
    currentSetPollId,
    currentSetPollPollId,
    onlineStudents,
    currentSectionAuthorizations,
    currentSectionPresenceMembers,
    currentSectionDataOwner,
  }
}

export default connect(
  mapStateToProps,
  {
    updateControl,
    playPoll,
    updateControlSettings,
    proposeControl,
  },
)(SyncLiveViewContainer)

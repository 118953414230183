import {updateQuestionMeta} from '../actions/questions'
import {updateSetMeta} from '../actions/sets'
import {updateFolder} from '../actions/folders'
import store from '../store'


export function moveToFolder(itemToMove,newFolder) {
	const nowDate = new Date()
	if(itemToMove.questions){
		const updatedSet={
			...itemToMove,
			clientModified:nowDate,
			folder:newFolder,
		}
		return store.dispatch(updateSetMeta(updatedSet,itemToMove.id))	

	}
	else if(itemToMove.ancestors){
		const updatedFolder={
			...itemToMove,	
			clientModified:nowDate,
			parent:newFolder,
		}
		return store.dispatch(updateFolder(updatedFolder,itemToMove.id))	
	}

	else{
		const updatedQuestion={
			...itemToMove,	
			clientModified:nowDate,
			folder:newFolder,
		}
		return store.dispatch(updateQuestionMeta(updatedQuestion,itemToMove.id))	
	}
}

export function moveToFolderInLibrary(itemToMove,newFolder) {
	const nowDate = new Date()
	if(itemToMove.questions){
		const updatedSet={
			...itemToMove,
			clientModified:nowDate,
			folder:newFolder,
			repo:null
		}
		return store.dispatch(updateSetMeta(updatedSet,itemToMove.id))	

	}
	else if(itemToMove.ancestors){
		const updatedFolder={
			...itemToMove,	
			clientModified:nowDate,
			parent:newFolder,
			repo:null
		}
		return store.dispatch(updateFolder(updatedFolder,itemToMove.id))	
	}

	else{
		const updatedQuestion={
			...itemToMove,	
			clientModified:nowDate,
			folder:newFolder,
			repo:null
		}
		return store.dispatch(updateQuestionMeta(updatedQuestion,itemToMove.id))	
	}
}




import React, {Component} from 'react'
import Button from '../../components/uiKit/Button'

class ImportQuestionsTopbar extends Component{
	render(){			
		const {setChoicesPerQuestion,choicesPerQuestion}=this.props
		return(		
			<div className='importQuestions-topBar'>				
				<div className='importQuestions-topBar-left'>
					<div className='importQuestions-topBar-left-pageTitle'>
						Import Questions
					</div>
				</div>

				<div className='importQuestions-topBar-right'>														
					
					<Button name='feedback' label='Send Feedback...' onClickFunction={this.props.openFeedbackModal} />				
					<Button name='undo' disabled={!this.props.canUndo}  iconName='undo' label='Undo' onClickFunction={this.props.undoLastChange} />				

					<div data-tooltip='Select the number of choices per question' className='importQuestions-topBar-right-choiceCountToggle tooltip tooltip--label'>														
						<Button name='twochoice' label='2 choices' color={choicesPerQuestion===2 ? 'blue' : 'white'} onClickFunction={()=>{setChoicesPerQuestion(2)}} />
						<Button name='threechoice' label='3 choices' color={choicesPerQuestion===3 ? 'blue' : 'white'} onClickFunction={()=>{setChoicesPerQuestion(3)}} />
						<Button name='fourchoice' label='4 choices' color={choicesPerQuestion===4 ? 'blue' : 'white'} onClickFunction={()=>{setChoicesPerQuestion(4)}} />
					</div>

				

					<Button name='cancel' label='Cancel' onClickFunction={this.props.hideModal} />

				</div>				
			</div>
				
			
		)
	}
}

export default ImportQuestionsTopbar

//settingsObject
//liveViewSettings or scoresheetSettings

export function saveSettingToLocalStorage(settingsObject,setting,value){

	const settingsObjectFromLocalStorage = JSON.parse(localStorage.getItem(settingsObject))
	let newSettings
	if(settingsObjectFromLocalStorage){
		newSettings={...settingsObjectFromLocalStorage,[setting]:value}
	}else{
		newSettings={[setting]:value}
	}
	
	localStorage.setItem(settingsObject, JSON.stringify(newSettings))

}
	
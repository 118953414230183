import React from 'react'

class StaticSlideChoiceZoomableAnimatedGIF extends React.Component{
	
	render(){ 
		const {choiceIndex, mediaChoiceWidth} = this.props              
		const imageContainerWidth = mediaChoiceWidth - 6 // bring in from definitions with some 
		const imageContainerHeight = 284 // refactor template definitions      
		return(             
			<div id={`choice${choiceIndex}AnimatedGIFZoomable`} style={{width: `${imageContainerWidth}px`, height: `${imageContainerHeight}px`}}  className='slide-choice-media--animatedGIF-animatedGIFZoomable'>          
			</div>
		)   
	} 
}

export default StaticSlideChoiceZoomableAnimatedGIF

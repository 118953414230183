const ChoiceVideoNodeSpec ={
  inline: false,
  attrs: {
    youtubeId:{default: null},
    addedAt:{default:''},
    fullDuration:{default:null},
    title:{default:null},
    channelTitle:{default:null},
    thumbnail:{default:{
      w:null,
      h:null,
      src:null
    }},
    isClipped:{default:false},
    start:{defult:null},
    end:{defualt:null},
    isPlaying:{default:false},
    questionChoiceCount: {default: 4},
    slideWidth:{default:''}
  },
  selectable:false,
  draggable:false,
  isolating: true,
  group: 'block',
  parseDOM: [{tag: 'choiceVideo'}],
    toDOM(node) {
    return ['choiceVideo']},
};
export default ChoiceVideoNodeSpec

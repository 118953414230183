import React from 'react'
import QuestionContent from './QuestionContent'

class Question extends React.Component{

	render(){ 
		return(			
			<div className={'printPackQuestion notranslate ' + (this.props.isSurvey ? ' printPackQuestion--isSurvey ' : ' printPackQuestion--isGraded ' ) + (this.props.includeStudentWorkingSpace ? ' printPackQuestion--includeWorkingSpace' : '')}>				
				<QuestionContent {...this.props} />				
			</div>
		)
	}
}

export default Question

import React from 'react' 
import DropdownWithoutPreventScroll from '../uiKit/DropdownWithoutPreventScroll'
import Button from '../uiKit/Button'
import DropdownSelect from '../uiKit/DropdownSelect'
import DropdownDivider from '../uiKit/DropdownDivider'
import DropdownSelectItem from '../uiKit/DropdownSelectItem'
import {analyticsUpdateNowPlayingSetting} from '../../utils/analytics/analyticsUpdateNowPlayingSetting'

class LiveViewDropdown extends React.Component{	

	updateLiveViewSetting(settingName,value){
		const liveViewSettings = JSON.parse(localStorage.getItem('liveViewSettings'))
		let newliveViewSettings=liveViewSettings||{}
		newliveViewSettings[settingName]=value
		localStorage.setItem('liveViewSettings', JSON.stringify(newliveViewSettings))
		analyticsUpdateNowPlayingSetting(settingName,value)
	}


	render(){
		let studentSortOrder='name'
		let studentListVisible='scan'
		let alwaysShowLetterblock=false
		let showStudentCardNumbers=false
		let colorScheme='scheme1'
		let alwaysShowGraph=false
		let showIndividualResponses='never'
		let showResponseCounts=false
		let disableZoomAnimations=false

		const liveViewSettings = JSON.parse(localStorage.getItem('liveViewSettings'))
	
		if(liveViewSettings ){ //TODO clean this up!
			if(liveViewSettings.studentSortOrder){
				studentSortOrder=liveViewSettings.studentSortOrder
			}
			if(liveViewSettings.studentListVisible){
				studentListVisible=liveViewSettings.studentListVisible
			}
			if(liveViewSettings.alwaysShowLetterblock){
				alwaysShowLetterblock=liveViewSettings.alwaysShowLetterblock
			}
			if(liveViewSettings.showStudentCardNumbers){
				showStudentCardNumbers=liveViewSettings.showStudentCardNumbers
			}
			if(liveViewSettings.colorScheme){
				colorScheme=liveViewSettings.colorScheme
			}
			if(liveViewSettings.alwaysShowGraph){
				alwaysShowGraph=liveViewSettings.alwaysShowGraph
			}
			if(liveViewSettings.showIndividualResponses){
				showIndividualResponses=liveViewSettings.showIndividualResponses
			}
			if(liveViewSettings.showResponseCounts){
				showResponseCounts=liveViewSettings.showResponseCounts
			}
			if(liveViewSettings.disableZoomAnimations){
				disableZoomAnimations=liveViewSettings.disableZoomAnimations
			}
		}

		return(

			<React.Fragment>
			<DropdownWithoutPreventScroll onDropdownOpen={()=>{this.props.onDropdownOpen('studentList')}} onDropdownClose={this.props.onDropdownClose} dropdownButton={<Button iconName='student-list-settings' label='Student List' size='large' />} >        	   
				
				<DropdownSelect label='Show Student List'>
					<DropdownSelectItem isActive={studentListVisible==='never'} label='Never Display' onSelect={()=>{this.updateLiveViewSetting('studentListVisible','never')}}/>
					<DropdownSelectItem isActive={studentListVisible==='scan'} label='Display while Scanning' onSelect={()=>{this.updateLiveViewSetting('studentListVisible','scan')}}/>
					<DropdownSelectItem isActive={studentListVisible==='always'} label='Always Display' onSelect={()=>{this.updateLiveViewSetting('studentListVisible','always')}}/>
				</DropdownSelect>

				<DropdownDivider /> 									

				<DropdownSelect label='Sort Student List by'>
					<DropdownSelectItem isActive={studentSortOrder==='name'} label='Name' onSelect={()=>{this.updateLiveViewSetting('studentSortOrder','name')}}/>
					<DropdownSelectItem isActive={studentSortOrder==='card'} label='Card Number' onSelect={()=>{this.updateLiveViewSetting('studentSortOrder','card')}}/>
				</DropdownSelect>
															
				<DropdownDivider /> 

				<DropdownSelect label='Student Card Numbers'>					
					<DropdownSelectItem isActive={!showStudentCardNumbers} label='Hide' onSelect={()=>{this.updateLiveViewSetting('showStudentCardNumbers',false)}}/>
					<DropdownSelectItem isActive={showStudentCardNumbers}label='Show' onSelect={()=>{this.updateLiveViewSetting('showStudentCardNumbers',true)}}/>										
				</DropdownSelect>

				<DropdownDivider />

				<DropdownSelect label='Individual Responses'>
					<DropdownSelectItem isActive={showIndividualResponses==='never'}label='Hide' onSelect={()=>{this.updateLiveViewSetting('showIndividualResponses','never')}}/>
					{/*<DropdownSelectItem isActive={showIndividualResponses==='manual'} label='Manually (Show Button)' onSelect={()=>{this.setShowIndividualResponses('manual')}}/> */}
					<DropdownSelectItem isActive={showIndividualResponses==='always'}label='Show' onSelect={()=>{this.updateLiveViewSetting('showIndividualResponses','always')}}/>										
				</DropdownSelect>
					
			</DropdownWithoutPreventScroll>

			<DropdownWithoutPreventScroll onDropdownOpen={()=>{this.props.onDropdownOpen('displayOptions')}} onDropdownClose={this.props.onDropdownClose} dropdownButton={<Button iconName='cog' label='Display Options' size='large' />} >        	   
				
				<DropdownSelect label='Media Zoom Animations'>
					<DropdownSelectItem isActive={!disableZoomAnimations} label='Default' onSelect={()=>{this.updateLiveViewSetting('disableZoomAnimations',false)}}/>
					<DropdownSelectItem isActive={disableZoomAnimations}label='Off' onSelect={()=>{this.updateLiveViewSetting('disableZoomAnimations',true)}}/>
				</DropdownSelect>	

				<DropdownDivider />

				<DropdownSelect label='Show Graph'>
					<DropdownSelectItem isActive={!alwaysShowGraph} label='Manually (Show Button)' onSelect={()=>{this.updateLiveViewSetting('alwaysShowGraph',false)}}/>
					<DropdownSelectItem isActive={alwaysShowGraph}label='Always' onSelect={()=>{this.updateLiveViewSetting('alwaysShowGraph',true)}}/>
				</DropdownSelect>

				<DropdownDivider /> 
				
				<DropdownSelect label='Incorrect Choice Color'>	
					<DropdownSelectItem isActive={colorScheme==='scheme1'} label='Gray' onSelect={()=>{this.updateLiveViewSetting('colorScheme','scheme1')}}/>
					<DropdownSelectItem isActive={colorScheme==='scheme2'} label='Red' onSelect={()=>{this.updateLiveViewSetting('colorScheme','scheme2')}}/>
				</DropdownSelect>		
				
				<DropdownDivider /> 

				<DropdownSelect label='Answer Display'>
					<DropdownSelectItem isActive={!alwaysShowLetterblock} label='Show Check and Cross' onSelect={()=>{this.updateLiveViewSetting('alwaysShowLetterblock',false)}}/>
					<DropdownSelectItem isActive={alwaysShowLetterblock}label='Show Choice Letter' onSelect={()=>{this.updateLiveViewSetting('alwaysShowLetterblock',true)}}/>
				</DropdownSelect>	

				<DropdownDivider /> 

				<DropdownSelect label='Show Response Counts'>
					<DropdownSelectItem isActive={showResponseCounts} label='Show' onSelect={()=>{this.updateLiveViewSetting('showResponseCounts',true)}}/>
					<DropdownSelectItem isActive={!showResponseCounts}label='Hide' onSelect={()=>{this.updateLiveViewSetting('showResponseCounts',false)}}/>
				</DropdownSelect>					


			</DropdownWithoutPreventScroll>

			</React.Fragment>			

		)
	}
}

export default LiveViewDropdown

import sortBy from 'lodash/sortBy'

export function sortLibraryData(sortType,sortOrder,libraryData) {
	let sorted = libraryData
	if (sortType === 'date') {
		if (sortOrder==='ascending') {
			sorted = sortBy(sorted, 'clientModified').reverse()
		}else{
			sorted = sortBy(sorted, 'clientModified')			
		}			
	}
	else if (sortType === 'name') {
		if (sortOrder==='ascending') {
			sorted = sortBy(sorted, [function(o) {
			//for questions use question body, for sets use the set name
				if (o.body) {
					return o.body.toString().toLowerCase()
				} else {
					if (o.name) {
						return o.name.toString().toLowerCase()
					}
				}
			}])
		}
		else{
			sorted = sortBy(sorted, [function(o) {
			//for questions use question body, for sets use the set name
				if (o.body) {
					return o.body.toString().toLowerCase()
				} else {
					if (o.name) {
						return o.name.toString().toLowerCase()
					}
				}
			}]).reverse()
		}
	}
	return sorted
}

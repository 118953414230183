import React, { Component } from 'react'
import { connect } from 'react-redux'
import {adminUpdateRepoMetadata,adminUpdateSubmission} from '../../actions/admin'
import EditSubmissionStatusModal from '../../components/explorer/packreview/EditSubmissionStatusModal'
	
class AdminEditSubmissionStatusContainer extends Component {
	constructor(){
		super()
		this.updateSubmissionStatus=this.updateSubmissionStatus.bind(this)
	}

	updateSubmissionStatus(submissionId,newStatus){
		this.props.adminUpdateSubmission(submissionId,newStatus).then(()=>{
			this.props.hideModal()
		})
	}


	render() {
		return(
			<div className='explorerEditRepoNotesModalContainer'>			
				<EditSubmissionStatusModal
					hideModal={this.props.hideModal}
					submission={this.props.submission}
					updateSubmissionStatus={this.updateSubmissionStatus}
				/>
				<div className='explorerEditRepoNotesModalContainer-background' onClick={this.props.hideModal} />
			</div>
		)		
	}
}

export default connect(
	(state) => ({
		user:state.user}),
	{adminUpdateRepoMetadata,adminUpdateSubmission
		}
)(AdminEditSubmissionStatusContainer)
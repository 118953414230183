import {calculateImageDeliveryValues} from '../image/calculateImageDeliveryValues'
	
export function setImageAttributes(imageNode,nodePos,attributes) {
	let tr = window.view.state.tr  
	const imageDeliveryValues=calculateImageDeliveryValues(attributes.fileId,attributes.originalDimensions,attributes.crop,attributes.rotate,attributes.colorAdjust,attributes.markup.covers,attributes.markup.markers)
	const deliveryUrl=imageDeliveryValues.deliveryUrl
	const  deliveryDimensions={
		w:imageDeliveryValues.w,
		h:imageDeliveryValues.h,
	}
	tr.setMeta("triggerParseDoc", true) 
	tr.setNodeMarkup(nodePos, null, {...attributes,deliveryUrl:deliveryUrl,deliveryDimensions:deliveryDimensions,placeholderImage:null})
	window.view.dispatch(tr)
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { upgradeService } from '../../actions/service'
import UpgradePaymentPage from '../../components/upgrade/UpgradePaymentPage'
import UpgradeSuccessPage from '../../components/upgrade/UpgradeSuccessPage'
import { getPaymentErrorMessage } from '../../utils/getPaymentErrorMessage'
import { fetchUpcomingInvoice, fetchBilling } from '../../actions/billing'

class UpgradePaymentContainer extends Component {
  constructor(props) {
    super(props)
    this.handleUpgrade = this.handleUpgrade.bind(this)
    const idempotencyKey = uuidv4()
    this.state = {
      upgradeSuccessful: false,
      upgradeError: null,
      idempotencyKey,
    }
  }

  handleUpgrade(plan, token) {
    // const {isDiscountPeriod}=this.props
    this.setState({ upgradeError: null })
    // let coupon=''
    // if(isDiscountPeriod){
    //   coupon=process.env.REACT_APP_DEFAULT_COUPON_CODE
    // }
    const { idempotencyKey } = this.state

    return this.props.upgradeService({
      plan, source: token, coupon: undefined, idempotencyKey,
    }).then(() => {
      this.setState({ upgradeSuccessful: true, upgradeError: false })
      this.props.fetchBilling()
      this.props.fetchUpcomingInvoice()
    })
      .catch((error) => {
        const newIdempotencyKey = uuidv4()
        this.setState({ idempotencyKey: newIdempotencyKey })
        let errorMessage = 'Please try again. If the problem persists, contact support.'
        if (error.response.status === 402) {
          const { response } = error
          errorMessage = getPaymentErrorMessage(response.textString)
          this.setState({ upgradeError: errorMessage })
          return 'error'
        }
        this.setState({ upgradeError: errorMessage })
        return 'error'
      })
  }

  render() {
    const { upgradeSuccessful } = this.state
    const { isDiscountPeriod } = this.props
    let email
    let fullName = ''
    if (this.props.user) {
      email = this.props.user.email
      if (this.props.user.firstName) {
        fullName = `${this.props.user.firstName} ${this.props.user.lastName}`
      }
    }
    if (!upgradeSuccessful) {
      return (
        <UpgradePaymentPage
          trialUsed={this.props.trialUsed}
          upgradePlan={this.handleUpgrade}
          email={email}
          fullName={fullName}
          hideModal={this.props.hideModal}
          upgradeError={this.state.upgradeError}
          isDiscountPeriod={isDiscountPeriod}
          billingInfo={this.props.billing}
        />

      )
    } return (
      <UpgradeSuccessPage
        trialUsed={this.props.trialUsed}
        hideModal={this.props.hideModal}
      />

    )
  }
}

export default connect(
  (state) => ({
    user: state.user,
    service: state.service,
    billing: state.billing,
  }),
  { upgradeService, fetchUpcomingInvoice, fetchBilling },
)(UpgradePaymentContainer)

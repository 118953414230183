import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import {  newSection,newSectionsFromIntegration,fetchArchivedSections } from '../../actions/sections' 
import {hideModal} from '../../actions/modals'
import NewSectionForm from '../../components/forms/NewSectionForm'
import Button from '../../components/uiKit/Button'
import Icon from '../../components/misc/Icons'
import ImportFromGoogleClassroomButton from '../ImportFromGoogleClassroomButton'
import FeatureModalSelectControl from '../../components/featureModals/FeatureModalSelectControl'

//Modal to create up to 8 sections at a time
//has a button to import from CG

const COLOR_OPTIONS = [
	'blue',
	'purple',
	'red',
	'orange',
	'green',
	'sky',
	'pink',
	'teal',
	'grey',
]

class NewSectionContainer extends Component {

	constructor(){
		super()
		this.handleNewSection=this.handleNewSection.bind(this)
		this.createNewSections=this.createNewSections.bind(this)
		this.onLoadGCCourses=this.onLoadGCCourses.bind(this)
		this.toggleCourseCheckbox=this.toggleCourseCheckbox.bind(this)
		this.deselectAllCourseCheckbox=this.deselectAllCourseCheckbox.bind(this)
		this.createGCSections=this.createGCSections.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		 this.state=({
			newSectionFormIds:[1,2,3,4,5,6,7,8],
			submitDisabled:false,
			submitLoading:false,
			showGCSectionList:false,
			courses:[],
			selectedCourses:{},
			GCEmail:null,
			integration:null,
			isLoadingGCClasses:false
		})
	}

	UNSAFE_componentWillMount() {
		document.addEventListener('keydown', this.handleKeyDown)
	}

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Add Class Modal')
		}
		this.props.fetchArchivedSections()
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown)
	}

	handleKeyDown(e){
		if(this.state.showGCSectionList){
			if (e.keyCode === 13) {
				const {selectedCourses}=this.state
				let selectedCoursesCount=0
				if(selectedCourses){
					Object.keys(selectedCourses).forEach((courseId)=>{
						if(selectedCourses[courseId]===true){
							selectedCoursesCount+=1
						}
					})	
					const canGCSubmit = selectedCoursesCount>0?true:false 
					if(canGCSubmit){
						this.createGCSections()
					}	
				}
			}
		}
	}

	createNewSections(){
		const forms=this.state.newSectionFormIds  
		let promises = []
		let sections=[]
		forms.forEach((sectionId, index) => { 
			const form=this.props.forms[`NewSection${sectionId}`]
			if(form.values.name  && form.values.name !== ''){
				promises.push(this.handleNewSection(form.values).then((response)=>{
				sections.push(response)}))
				this.setState({submitDisabled:true})//prevent dublicate form submissions
				this.setState({submitLoading:true})
			}
			return null
		})
		Promise.all(promises).then(function () {
			const firstSection=sections[0]
			if(sections[0]){
				this.props.history.push(`/classes/${firstSection.id}`) //after creating, navigate to the first section
			}
		}.bind(this))
	}

	handleNewSection(data){
		return this.props.newSection(this.appendData(data))
	}

	appendData(data){	//Add additional fields required for api to the data passed from the redux form
		const nowDate = new Date()
		return {
			...data, 
			'subject':'',
			'year':'',
			'archived':false,
			'clientModified':nowDate,
			'userCreated':nowDate
		}
	}

	onLoadGCCourses(courses,email,integration){
		let selectedCourses={}
		if(courses){
			courses.forEach((course)=>{
				let uncheckable=false
				this.props.sections.forEach((section)=>{
					if(section.integration && section.integration.active && section.integration.externalSectionId===course.id){
						uncheckable=true
							}
					})

				this.props.archivedSections.forEach((section)=>{
					if(section.integration && section.integration.active && section.integration.externalSectionId===course.id){
						uncheckable=true
					}
				})

				if(uncheckable){
					selectedCourses[course.id]=false
				}
				else selectedCourses[course.id]=true
			})
		}

		this.setState({isLoadingGCClasses:false,showGCSectionList:true,courses:courses,GCEmail:email,selectedCourses:selectedCourses,integration:integration})
		if(window.analytics){
			const GCCoursesCount=courses.length
			window.analytics.page('Import Google Classroom - Select Classes',{googleClassroomCoursesCount:GCCoursesCount})
		}
	}


	toggleCourseCheckbox(courseId){
		let newSelectedCourses={}
		const {selectedCourses,courses}=this.state
		courses.forEach((course)=>{
			if(course.id===courseId){
				newSelectedCourses[course.id]=!selectedCourses[course.id]
			}else{
				newSelectedCourses[course.id]=selectedCourses[course.id]
			}
		})
		this.setState({selectedCourses:newSelectedCourses})
	}

	deselectAllCourseCheckbox(){
		let newSelectedCourses={}
		const {courses}=this.state
		courses.forEach((course)=>{
			newSelectedCourses[course.id]=false
		})
		this.setState({selectedCourses:newSelectedCourses})
	}


	createGCSections(){
		this.setState({submitLoading:true})
		let externalIds=[]
		const {selectedCourses}=this.state
		Object.keys(selectedCourses).forEach((courseId)=>{
				if(selectedCourses[courseId]===true){
					externalIds.push(courseId)
				}
			})
		const integrationId=this.state.integration.id
		const requestBody={
			integration:integrationId,
			externalIds: externalIds
		}
		this.props.newSectionsFromIntegration(requestBody).then(()=>{
			this.props.hideModal()
		})
		if(window.analytics){
			const importSectionCount=externalIds.length
			window.analytics.track('Click Import from Google Classroom - Import',{
				importSectionCount:importSectionCount

			})
		}
	}

	render() {
		let canSubmit=false
		let classesToCreateCount=0
		const forms=this.state.newSectionFormIds  
		forms.map((sectionId, index) => { 
			const form=this.props.forms[`NewSection${sectionId}`]
			if(form){
				if(form.values.name  && form.values.name !== ''){
					canSubmit=true
					classesToCreateCount+=1
				}}
			return null
		})
		if(this.state.submitDisabled){
			canSubmit=false
		}

		let buttonText='Create Class'
		if(classesToCreateCount>1){
			buttonText='Create Classes'
		}

		const {selectedCourses}=this.state
		let selectedCoursesCount=0
		if(selectedCourses){
			Object.keys(selectedCourses).forEach((courseId)=>{
				if(selectedCourses[courseId]===true){
					selectedCoursesCount+=1
				}
			})
			if(this.state.showGCSectionList){
				buttonText='Import Class'
				if(selectedCoursesCount>1){
					buttonText='Import Classes'
				}
			}
			
		}
		const canGCSubmit = selectedCoursesCount>0?true:false // need at least one selected GC course I guess for button to be blue
		const {isLoadingGCClasses} = this.state
		return (        
			<div className='newSection'>
				<div onClick={this.props.hideModal} className='newSection-closeBtn'>
					<Icon name='circle-with-close' />
				</div>

			{!this.state.showGCSectionList && !isLoadingGCClasses &&
				<React.Fragment>
				<div className='newSection-header'>	
					<div className='newSection-header-primary'>	
						New Classes
					</div>
					<div className='newSection-header-secondary'>	
						<div className='newSection-header-secondary-explainText'>	
							We suggest naming your class something not too long, like “AP Chemistry” or “Period 2 Biology”.
						</div>
						<div className='newSection-header-secondary-importGoogleBtnContainer'>	
							<ImportFromGoogleClassroomButton onAuthSuccess={()=>{this.setState({isLoadingGCClasses:true})}} onLoadGCCourses={this.onLoadGCCourses}/>							
						</div>
					</div>				
					<div className='newSection-header-divider'/>	
				</div>				
				<div className='newSection-forms--container'>
					{this.state.newSectionFormIds.map((sectionId, index) => { 
						return(
						 <NewSectionForm 
						 autoFocus={index===0 ? true : false}
						 defaultColor={`${COLOR_OPTIONS[index]}`}
						 key={sectionId}
						 formName={`NewSection${sectionId}`} 
						 newSectionId={sectionId}
						 onSubmit={this.createNewSections}
						 tabIndex={index}   /> 
						)
					})
					}
				</div>

				<button className={'btn btn--xLarge newSection-submitBtn ' + (canSubmit ? ' btn--blue ':' btn--gray')} disabled={!canSubmit} type="submit" onClick={this.createNewSections}>
						
					{!this.state.submitLoading &&	
						<React.Fragment>
							<div className='newSection-submitBtn-primary'>
								{buttonText}
							</div>
							{canSubmit &&	
								<div className='newSection-submitBtn-secondary'>
									Press ENTER
								</div>
							}
						</React.Fragment>
					}

					{this.state.submitLoading &&
						<div className='newSection-submitBtn-loadingSpinnerContainer'>
							<div className='newSection-submitBtn-loadingSpinner' />
						</div>
					}

				</button>
				
					
				</React.Fragment>
			}

			{isLoadingGCClasses &&
				
					<div className='newSection-loadingFromGCContainer'>
						<div className='newSection-loadingFromGC'>
							<div className='newSection-loadingFromGC-loadingSpinnerContainer'>
								<div className='newSection-loadingFromGC-loadingSpinner' />
							</div>							
						</div>
					</div>
			}

			{this.state.showGCSectionList && !isLoadingGCClasses &&

				<React.Fragment>
					<div className='newSection-header newSection-header--GCSelect'>	
						<div className='newSection-header-primary'>	
							Select Classes to Import
						</div>
						<div className='newSection-header-secondary'>	
							<div className='newSection-header-secondary-iconContainer'>
								<Icon name='googleClassroomLogo' />
							</div>
							<div className='newSection-header-secondary-explainText'>	
								Google Classroom <span>({this.state.GCEmail})</span>								
							</div>							
							<Button name='GCImportDeselect' ghost label='Deselect All' onClickFunction={this.deselectAllCourseCheckbox}/>							
						</div>				
						<div className='newSection-header-divider'/>	
					</div>	

					<div className='newSection-GCCourseListContainer'>
						<div className='newSection-GCCourseList'>
						
							{this.state.courses && this.state.courses.length>0 &&
								<React.Fragment>
								 {this.state.courses.map((course)=>{
									const checked=selectedCourses[course.id]
									let uncheckable=false
									this.props.sections.forEach((section)=>{
										if(section.integration && section.integration.active && section.integration.externalSectionId===course.id){
											uncheckable=true
										}
									})
									this.props.archivedSections.forEach((section)=>{
										if(section.integration && section.integration.active && section.integration.externalSectionId===course.id){
											uncheckable=true
										}
									})

									return(
											<FeatureModalSelectControl
												key={course.id}
												checkbox
												checked={checked}		
												uncheckable={uncheckable} // if already imported					
												subLabel={uncheckable ? `Already imported` : false}
												onClick={()=>{this.toggleCourseCheckbox(course.id)}}
												label={course.name}
											/>

										)

								})}	
								
							</React.Fragment>
						}							 
							 
						
						{this.state.courses && this.state.courses.length === 0 &&
						 <div className='newSection-GCCourseList-emptyState'>
						 	No active classes found on this Google Classroom account.
						 </div>
						}
					</div>
				</div>

				<button onClick={this.createGCSections} className={'btn btn--xLarge newSection-submitBtn ' + (canGCSubmit ? ' btn--blue ':' btn--gray')} disabled={!canGCSubmit} type="submit" >
						
					{!this.state.submitLoading &&	
						<React.Fragment>
							<div className='newSection-submitBtn-primary'>
								{buttonText}
							</div>
							{canGCSubmit &&	
								<div className='newSection-submitBtn-secondary'>
									Press ENTER
								</div>
							}
						</React.Fragment>
					}

					{this.state.submitLoading &&
						<div className='newSection-submitBtn-loadingSpinnerContainer'>
							<div className='newSection-submitBtn-loadingSpinner' />
						</div>
					}

				</button>
				</React.Fragment>

			}

			</div>
		)
	}
}

export default withRouter(connect(
	(state) => ({
		forms:state.form,
		sections:state.sections,
		archivedSections:state.archivedSections
	}),
	{ newSection,hideModal,newSectionsFromIntegration,fetchArchivedSections}
)(NewSectionContainer))
import React from 'react'

class StaticSlideChoiceZoomableVideoPlayer extends React.Component{
	
	render(){ 
		const {choiceIndex, mediaChoiceWidth, videoWidth, videoHeight} = this.props              
		const videoContainerWidth = mediaChoiceWidth - 6 // bring in from definitions with some 
		const videoContainerHeight = 284 // refactor template definitions 
		const containerAspect = videoContainerWidth / videoContainerHeight
		// FIND SIZE OF IMAGE TO FIT CREATE PLACEHOLDER DIV
		const videoAspect = videoWidth / videoHeight
		let fitVideoWidth
		let fitVideoHeight     
		
		if(videoAspect > containerAspect){ // image is more landscape than media choice container
			fitVideoWidth = videoContainerWidth
			fitVideoHeight = fitVideoWidth / videoAspect
		}

		if(videoAspect < containerAspect){ // image is more portrait than media choice container
			fitVideoHeight = videoContainerHeight
			fitVideoWidth = fitVideoHeight * videoAspect
		}

		// center the image (could be done otherways)
		const marginTop = (videoContainerHeight - fitVideoHeight) / 2
		const marginLeft = (videoContainerWidth - fitVideoWidth) / 2
		return(             
			<div id={`choice${choiceIndex}VideoZoomable`} style={{width: `${videoContainerWidth}px`, height: `${videoContainerHeight}px`}}  className='slide-choice-media--videoChoiceZoomable'>
				<div  style={{marginTop: `${marginTop}px`, marginLeft: `${marginLeft}px`, width: `${fitVideoWidth}px`, height: `${fitVideoHeight}px`}} className='slide-choice-media--videoChoiceZoomable-placeholderBG' />          
			</div>
		)   
	} 
}

 
export default StaticSlideChoiceZoomableVideoPlayer

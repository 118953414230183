class ChoiceGroupNodeView {

	constructor(node, view, getPos) {
		this.getNode=this.getNode.bind(this)
		this.node = node
		this.getPos=getPos
		this.dom = this.contentDOM = document.createElement("div")
		this.dom.classList.add("slide-choices")
		if(node.attrs.choicesComplete){
			this.dom.classList.add("slide-choices--complete")
		}else{
			this.dom.classList.add("slide-choices--incomplete")
		}
	}

	getNode(){
		return this.node
	}

	update(node) {
		if (node.type !== this.node.type) return false
		const {choicesComplete} = node.attrs
		if(choicesComplete !== this.node.attrs.choicesComplete){
			this.dom.classList=''
			this.dom.classList.add("slide-choices")
			if(choicesComplete){
				this.dom.classList.add("slide-choices--complete")
			}else{
				this.dom.classList.add("slide-choices--incomplete")
			}
		}
		this.node=node
		return true
	}

	destroy() {
	//if (this.innerView) this.close()
	}

	stopEvent(event) {
	//return this.innerView && this.innerView.dom.contains(event.target)
	}

}

export default ChoiceGroupNodeView
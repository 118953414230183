const QuestionSoundNodeSpec ={
  inline: false,
   attrs: {
    isPlaying:{default:false},
    fileId:{default:''},
    deliveryUrl:{default:''},
    title:{default:''},
    fullDuration:{default:null},
    isClipped:{default:false},
    start:{default:0},
    end:{default:''},
    source:{default:false}, //upload/mic
    addedAt:{default:false},
    attribution:{default:''},
    questionMediaDynamicHeight:{default: null},
    template: {default: null},
    slideWidth:{default:''}
  },
  group: 'block',
  parseDOM: [{tag: 'questionSound'}],
  toDOM(node) {
    return ['questionSound']
  }
}

export default QuestionSoundNodeSpec
 
import {SetAttrsStep} from '../SetAttrsStep'

//used when open modal or change active question to stop media playing

export function stopAllMediaPlayback() {
	const editorView=window.view
	let tr = window.view.state.tr
	editorView.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if(node.attrs.isPlaying||node.attrs.isPlaying===false){
			tr.step(new SetAttrsStep(pos, {...node.attrs,isPlaying:false}))
		}
	})    
	tr.setMeta('addToHistory', false) 
	window.view.dispatch(tr)
}
import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../misc/Icons'
import QuestionSlideCarousel from '../uiKit/QuestionSlideCarousel'
import {formatDate} from '../../utils/formatDate'
import {getFolderName} from '../../utils/getFolderName'
import {getRepoforId} from '../../utils/getRepoforId'
import {getUserForId} from '../../utils/getUserForId'
import {getDisplayNameForUser} from '../../utils/getDisplayNameForUser'
import {getLinkForRepo} from '../../utils/getLinkForRepo'
 
class SearchResultsCard extends React.Component{

	render(){	

		const {title, item, question, set,play} = this.props
		let linkPath=''
		if(set){
			const itemId=item.id||item._id
			linkPath=`/set/${itemId}`
		}else if(question){
			const itemId=item.id||item._id
			linkPath=`/question/${itemId}`
		}
		let folderName = null
		let repoName = null
		let inSharedRepo = false
		let inPublicConsumerRepo = false
		let inPublicAuthorRepo = false
		let lastEditedBy
		let parentRepo
		if(item.folder){
			folderName=getFolderName(item.folder)
		}
		if(item.repo){
			parentRepo=getRepoforId(item.repo)
			if(parentRepo){
				repoName=parentRepo.name
				inSharedRepo=parentRepo.shared
				inPublicConsumerRepo=parentRepo.role==='consumer'
				inPublicAuthorRepo=parentRepo.role==='author'
				if(inSharedRepo){
					const lastEditedUser = getUserForId(item.lastEditedBy,item.repo)
					lastEditedBy=getDisplayNameForUser(lastEditedUser)
				}
			}
		}

		const hideSlide = true // during Algolia Migration 
 
		return(
			<Link to={linkPath}>
				<div className={'searchResultsCard ' + (hideSlide ? 'searchResultsCard--hideSlide' : '')}>				

					{!hideSlide &&
						<div className='searchResultsCard-questionPreview'>
							{set && <QuestionSlideCarousel set={item} slideWidth={200} play={play}/> }
							{question && <QuestionSlideCarousel question={item} slideWidth={200} play={play} /> }					
						</div>
					}					

					<div className='searchResultsCard-title'>
						{title} {inSharedRepo && <Icon name='shared'/>}
						{inPublicConsumerRepo && <Icon name='globe-shared'/>}
						{!set && !question && inPublicAuthorRepo && <Icon name='globe-shared-author'/>}
					</div>							

					{(folderName || repoName) &&
						<div className='searchResultsCard-details'>

							{repoName &&
								<Link to={getLinkForRepo(parentRepo)} className='searchResultsCard-details-repo'>
									{repoName}
								</Link>
							}

							{repoName && folderName &&
								<Icon name='chevron-right'/>
							}

							{folderName && !repoName &&
								<Link to={`/library/${item.folder}`} className='searchResultsCard-details-folder'>
									{folderName}
								</Link>
							}	

							{folderName && repoName &&
								<Link to={`${getLinkForRepo(parentRepo)}/${item.folder}`} className='searchResultsCard-details-folder'>
									{folderName}
								</Link>
							}							
						</div>
					}

					<div className='searchResultsCard-timestamp'>
						{lastEditedBy?`${lastEditedBy} edited ` :'Edited'} {formatDate(item.clientModified)}
					</div>
					

					<div className='searchResultsCard-rightArrow'>
						<Icon name='chevron-right' />
					</div>

				</div>
			</Link>
		)
	}
}




export default SearchResultsCard

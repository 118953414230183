import api from '../api'
import * as Sentry from '@sentry/browser'

export function fetchRecentActivities(params) {
	return (dispatch) => api.fetch('/recentActivities?itemTrashed=false&limit=500')
		.then((response) => {
			dispatch({ type: 'FETCH_RECENT_ACTIVITIES_SUCCESS', response })
		})
		.catch(err => {})
}



export function updateRecentActivities(itemId,itemType,activityType) {
	if(!itemId){ //TEMP for debugging
		Sentry.withScope(scope => {
			scope.setExtra('itemId', itemId)
			scope.setExtra('itemType', itemType)
			Sentry.captureException('request update recent activities with no itemId')
		})
	}

	const nowDate = new Date()
	const requestBody={
		'item':itemId,
		'itemType':itemType,
		'activityTime':nowDate,
		'activityType':activityType}
	return (dispatch) => api.put(`/recentActivities/${itemId}`,requestBody)
		.then((response) => {
			dispatch({ type: 'UPDATE_RECENT_ACTIVITIES_SUCCESS', response,itemId ,syncAction:true})
		})
		.catch(err => {})
}

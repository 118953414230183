import TimeMetrics from '../metrics/timeMetrics'

const MAX_RESPONSE_MESSAGE_COUNT = 10000
const CONTROL_MESSAGE_STATS_FLUSH_PERIOD = 5 * 60 * 1000

const controlMetrics = new TimeMetrics({
  segmentEventName: 'Message Metrics',
  metricsType: 'control',
  periodicFlushInterval: CONTROL_MESSAGE_STATS_FLUSH_PERIOD,
})

const responseMetrics = new TimeMetrics({
  segmentEventName: 'Message Metrics',
  metricsType: 'scan',
  maxSampleSize: MAX_RESPONSE_MESSAGE_COUNT,
})

export async function onControlMessage(message) {
  // record control message time
  controlMetrics.recordTimeSince(message.controlled)

  if (message.scanning) {
    // set responseMetrics to accept data
    responseMetrics.ensureOpened()
  } else {
    // end data session - stop accepting data
    responseMetrics.ensureClosed()
    // send metrics
    responseMetrics.flush()
  }
}

export async function onResponseMessage(message) {
  const messageContent = message.poll || message.setPollPoll
  responseMetrics.recordTimeSince(messageContent.maxCaptured)
}

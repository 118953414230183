import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import UpgradeLandingPage from '../../components/upgrade/UpgradeLandingPage'
import UpgradePaymentContainer from './UpgradePaymentContainer'
 

class UpgradeModalWrapper extends React.Component{
	constructor(){
		super()
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)   
	}

	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden' //prevent scrolling while modal is open
	}

	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}

	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.props.hideModal() //close modal is click outside modal
	}

	render(){	
		return(
			<div className='upgradeContainer'  onClick={this.handleBackgroundClick}>
				{this.props.children}
			</div>
		)		
	}	
}


class UpgradeContainer extends Component {
  
	constructor(props){
		super(props)
		this.dismissUpgradeModal=this.dismissUpgradeModal.bind(this)		   
	}

	dismissUpgradeModal(){
		this.props.history.push(window.location.pathname)
	}


	render() {
		const location=window.location
		let trialUsed=true
		if(this.props.meta){
			trialUsed=this.props.meta.trialUsed
		}
		let isDiscountPeriod=false
		const discountPeriodStartDate = process.env.REACT_APP_DEFAULT_COUPON_START_DATE
		const discountPeriodEndDate = process.env.REACT_APP_DEFAULT_COUPON_END_DATE
		const currentDate = new Date()
		const startDate = new Date(discountPeriodStartDate)
		const endDate = new Date(discountPeriodEndDate)

		if(startDate<currentDate && endDate > currentDate){
		   isDiscountPeriod=true
		} 
 
		if(location.hash==='#upgrade' ){
			return (
				<UpgradeModalWrapper hideModal={this.dismissUpgradeModal} key='upgrade'>			
					<UpgradeLandingPage 
						trialUsed={trialUsed}
						nextPage={()=>{location.hash='upgrade-payment'}}
						hideModal={this.dismissUpgradeModal}
						isDiscountPeriod={isDiscountPeriod}
					/>		
				</UpgradeModalWrapper>
			)}
		else if(location.hash==='#upgrade-payment' ){
			return(
				<UpgradeModalWrapper key='upgrade-payment' hideModal={this.dismissUpgradeModal}>
					<UpgradePaymentContainer isDiscountPeriod={isDiscountPeriod} trialUsed={trialUsed}	hideModal={this.dismissUpgradeModal}/>
				</UpgradeModalWrapper>
			)
		}		
		else return null
	}
}

export default withRouter(connect(
	(state) => ({
		user:state.user,
		meta:state.meta
	}),
	{ }
)(UpgradeContainer))
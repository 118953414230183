import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggleStudentAccessToSection } from '../../actions/sections'
import Button from '../../components/uiKit/Button'
import FeatureModalSelectControl from '../../components/featureModals/FeatureModalSelectControl'
import { showNotification } from '../../actions/notifications'

class ActivateElearningModal extends Component {
	constructor(){
		super()				
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)
		this.activateElearning=this.activateElearning.bind(this)
		this.toggleCoppaCheckbox=this.toggleCoppaCheckbox.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.state=({
			submitLoading:false,
			coppaStudentsUnder13:null,
			coppaStudentsUSBased:null,
			coppaAcceptedConsent:false		
		})
	}

 	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden' //prevent scrolling while modal is open
	}

 	componentDidMount(){
		window.addEventListener('keydown', this.handleKeyDown)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}


	handleKeyDown(e){
		if(e.keyCode === 27){//escape key
			this.props.dismissModal()
		}
	}

	handleBackgroundClick(e){
		if (!this.state.submitLoading && e.target === e.currentTarget) this.props.dismissModal() //close modal is click outside modal
	}
	
	toggleCoppaCheckbox(field,value){
		let coppaStudentsUnder13=this.state.coppaStudentsUnder13 
		let coppaStudentsUSBased=this.state.coppaStudentsUSBased 
		let coppaAcceptedConsent=this.state.coppaAcceptedConsent 
		if(field==='coppaStudentsUnder13'){
			if(coppaStudentsUnder13===value){
				coppaStudentsUnder13=null
				coppaStudentsUSBased=null
				coppaAcceptedConsent=false
			}else{
				coppaStudentsUnder13=value
				coppaStudentsUSBased=null
				coppaAcceptedConsent=false
			}	
		}else if(field==='coppaStudentsUSBased'){
			if(coppaStudentsUSBased===value){
				coppaStudentsUSBased=null
				coppaAcceptedConsent=false
			}else{
				coppaStudentsUSBased=value
				coppaAcceptedConsent=false
			}	
		}else if(field==='coppaAcceptedConsent'){
			if(coppaAcceptedConsent===value){
				coppaAcceptedConsent=false
			}else{
				coppaAcceptedConsent=value
			}	
		}	
		this.setState({coppaStudentsUnder13:coppaStudentsUnder13,coppaStudentsUSBased:coppaStudentsUSBased,coppaAcceptedConsent:coppaAcceptedConsent})
	}

	activateElearning(){
		this.setState({submitLoading:true})
		setTimeout(function() {
			this.props.toggleStudentAccessToSection(this.props.section.id,true,this.props.user.id,this.state.coppaStudentsUnder13,this.state.coppaStudentsUSBased,this.state.coppaAcceptedConsent).then(()=>{
			this.setState({toggleStudentAccessSubmitLoading:false})
			document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
			document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
			this.props.scrollToStudentSection()
			this.props.dismissModal()	
			if(this.props.sectionIsIntegrationRostered){
				this.props.openDistributeCodesGCModal()
			}
		})}.bind(this), 1000)	
	}

	render() {
		const {coppaStudentsUnder13,coppaStudentsUSBased,coppaAcceptedConsent} = this.state
		let readyToActivate=false
		if(coppaAcceptedConsent){
			readyToActivate = true
		}else if(coppaStudentsUnder13===false){
			readyToActivate = true
		}else if(coppaStudentsUSBased===false){
			readyToActivate = true
		}
	
		return (
			<div className='sectionHome-activateELearningModalContainer' onClick={this.handleBackgroundClick}>				
				<div className='sectionHome-activateELearningModal'>
					<div className='sectionHome-activateELearningModal-iconContainer'>
						<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
							<path d="M29.4865084,4.14577778 C21.3039521,5.33333333 19.6812735,3.25866667 16.0014286,0 C12.3215837,3.25866667 10.6989051,5.33333333 2.51634877,4.14577778 C-1.8444891,25.92 16.0014286,32 16.0014286,32 C16.0014286,32 33.8473462,25.92 29.4865084,4.14577778 Z" fillRule="nonzero"></path>    
						</svg>
					</div>
					<div className='sectionHome-activateELearningModal-header'>
						Consent for Student Access
					</div>
					<div className='sectionHome-activateELearningModal-description'>
						Depending on local laws, we may need consent before students can access Plickers on their devices.
					</div>
					<div className='sectionHome-activateELearningModal-description'>
						For information on how we handle data from students,<br/>please see <a href="https://help.plickers.com/hc/en-us/articles/360009090833-Privacy-Policy#Information-Collected-About-Students" target='_blank' rel='noopener noreferrer'>Section 11 of our Privacy Policy</a>.
					</div>
														
					<div className='sectionHome-activateELearningModal-divider'/>

					<div className='sectionHome-activateELearningModal-checkbox'>
						<div className='sectionHome-activateELearningModal-checkbox-header'>
							Does this class have any students under 13?
						</div>
						<FeatureModalSelectControl
							checkbox
							checked={coppaStudentsUnder13===true}
							onClick={()=>{this.toggleCoppaCheckbox('coppaStudentsUnder13',true)}}
							label='Yes'
						/>
						<FeatureModalSelectControl
							checkbox
							checked={coppaStudentsUnder13===false}
							onClick={()=>{this.toggleCoppaCheckbox('coppaStudentsUnder13',false)}}
							label='No'
						/>							
					</div>

					{coppaStudentsUnder13 &&
						<div className='sectionHome-activateELearningModal-checkbox'>
							<div className='sectionHome-activateELearningModal-checkbox-header'>
								Are your students in the United States?
							</div>
							<FeatureModalSelectControl
								checkbox
								checked={coppaStudentsUSBased===true}
								onClick={()=>{this.toggleCoppaCheckbox('coppaStudentsUSBased',true)}}
								label='Yes'
							/>
							<FeatureModalSelectControl
								checkbox
								checked={coppaStudentsUSBased===false}
								onClick={()=>{this.toggleCoppaCheckbox('coppaStudentsUSBased',false)}}
								label='No'
							/>							
						</div>
					}

					{coppaStudentsUnder13 && coppaStudentsUSBased &&
						<div className='sectionHome-activateELearningModal-checkbox sectionHome-activateELearningModal-checkbox--consent'>
							<div className='sectionHome-activateELearningModal-checkbox-header'>
								Based on your answers, we need <a href="https://help.plickers.com/hc/en-us/articles/360049891033-COPPA" target='_blank' rel='noopener noreferrer'>parental consent</a> to proceed. 
							</div>
							<FeatureModalSelectControl
								checkbox
								checked={coppaAcceptedConsent===true}
								onClick={()=>{this.toggleCoppaCheckbox('coppaAcceptedConsent',true)}}
								label='I consent on behalf of parents to enable student access for this class.'
							/>									
						</div>
					}
					<div className='sectionHome-activateELearningModal-buttonContainer'>
						<Button disabled={!readyToActivate} name='activateclassmodalbtn' submitLoading={this.state.submitLoading} label="Activate Class for E-Learning" size='xLarge' color={readyToActivate ? 'blue' : 'gray'} onClickFunction={this.activateElearning}/>								
						<div onClick={this.handleBackgroundClick} className='sectionHome-activateELearningModal-buttonContainer-cancelBtn'>
							Cancel
						</div>
					</div>
				</div>
			</div>								
		)
	}
}


class ActivateElearningModalContainer extends Component {
	render() {
		return (
			<ActivateElearningModal 
				section={this.props.section}
				scrollToStudentSection={this.props.scrollToStudentSection}
				toggleStudentAccessToSection={this.props.toggleStudentAccessToSection}
				dismissModal={this.props.dismissModal}
				user={this.props.user}
				openDistributeCodesGCModal={this.props.openDistributeCodesGCModal}
				sectionIsIntegrationRostered={this.props.sectionIsIntegrationRostered}
			/>
		)
	}
}

export default connect(
	(state) => ({
		user:state.user
	}),
	{toggleStudentAccessToSection,showNotification}
)(ActivateElearningModalContainer)




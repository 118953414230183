import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import debounce from 'lodash/debounce'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find' 
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import { hideMenu } from 'react-contextmenu/modules/actions'
import { connect } from 'react-redux'
import { fetchRecentActivities } from '../actions/recentActivities'
import {updateSetContent,updateSetMeta} from '../actions/sets'
import {updateQuestionMeta } from '../actions/questions'
import {showRenameSetModal,showNewRepoModal,showRepoSelectionModal} from '../actions/modals'
import {showNotification,hideNotification} from '../actions/notifications'
import {fetchQuestionPolls, fetchSetPolls} from '../actions/polls'
import RecentPage from '../components/recent/RecentPage'
import moment from 'moment'
import {playItem} from '../utils/playItem'
import {newSet} from '../utils/newItem'
import {createPoll} from '../utils/createPoll'
import {showSectionSelectionModal } from '../actions/modals'
import {openItemInEditor} from '../utils/openItemInEditor'
import FilterBar from '../components/uiKit/FilterBar'
import Button from '../components/uiKit/Button'
import TopPageHeader from '../components/pageHeaders/TopPageHeader'
import StickyPageHeader from '../components/pageHeaders/StickyPageHeader'
import PageIcon from '../components/misc/PageIcons'
import {showLiveViewModal} from '../utils/liveView/showLiveViewModal'
import {copyQuestion} from '../utils/copyPaste'
import RecentPreviewPanelContainer from './previewPanel/RecentPreviewPanelContainer'
import {addCopyToLibrary} from '../utils/addCopyToLibrary' 
import {updatePreviewItem} from '../actions/previewItem'

const CONTEXT_MENU_CLOSE_DELAY = 1000 

class RecentContainer extends Component {

	constructor(){
		super()
		this.changeActiveItem=this.changeActiveItem.bind(this)
		this.handlePlayItem=this.handlePlayItem.bind(this)
		this.handleFilterChange=this.handleFilterChange.bind(this)
		this.clearFilter=this.clearFilter.bind(this)
		this.queueItem=this.queueItem.bind(this)
		this.handleContextMenuClick=this.handleContextMenuClick.bind(this)
		this.archiveItem=this.archiveItem.bind(this)
		this.keyPressNavigation=this.keyPressNavigation.bind(this)
		this.delayedMenuClose=this.delayedMenuClose.bind(this)
		this.onShowContextMenu=this.onShowContextMenu.bind(this)
		this.onHideContextMenu=this.onHideContextMenu.bind(this)
		this.editSetName=this.editSetName.bind(this)
		this.navigateToItemDetail=this.navigateToItemDetail.bind(this)
		this.copyQuestionToClipboard=this.copyQuestionToClipboard.bind(this)
		this.addCopyToLibrary=this.addCopyToLibrary.bind(this)
		this.delayedMenuClose = debounce(this.delayedMenuClose,CONTEXT_MENU_CLOSE_DELAY)
		this.state={
			activePanelItem: {},
			filterTerm:'',
			contextMenuActiveItem:null,
			contextMenuObject:null,
		}  
	}

	UNSAFE_componentWillMount() {
		this.props.fetchRecentActivities()
		this.props.fetchQuestionPolls()
		this.props.fetchSetPolls()
	}

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Recent')
		}
	}

	componentWillUnmount() {
		this.props.hideNotification()
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' 
	}

	addCopyToLibrary(item){
		return this.props.addCopyToLibrary(item,'Context Menu').then(()=>{
			this.props.showNotification('to Your Library','Added a Copy','create')
		})
	}

	copyQuestionToClipboard(question){
		copyQuestion(question)
		const notificationMessage='to Clipboard'
		this.props.showNotification(notificationMessage,'Copied','default')
	}

	onShowContextMenu(e){
		this.setState({contextMenuActiveItem:e.detail.data.itemId,contextMenuObject:e.detail.data.item})				
		document.body.style.overflowY = 'hidden'
	}

	onHideContextMenu(e){
		this.setState({contextMenuActiveItem:null,contextMenuObject:null})
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' 
	}

	changeActiveItem(newActiveItem){
		let activeItem
		if(newActiveItem.id===this.state.activePanelItem.id){
			activeItem={}
		}else{
			activeItem=newActiveItem
		}
		this.setState({activePanelItem: activeItem})
		this.ensureActiveItemVisible(activeItem.id)
	}

	ensureActiveItemVisible(itemId) {
		const cellNode = document.getElementById(itemId)
		if(cellNode ){
			const cellRect = cellNode.getBoundingClientRect()
			const offset=130 //for header
			//check if need to scroll it 
			if(!(cellRect.top >= offset && cellRect.bottom <= window.innerHeight)){
				cellNode.scrollIntoView({block: 'end', behavior: 'instant'})
			}
		}
	}

	navigateToItemDetail(item){ //shared with library-could move to HOC
		if(item.questions){
			this.props.history.push(`/set/${item.id}`)
		}else {
			this.props.history.push(`/question/${item.id}`)
		}
	}

	delayedMenuClose(){	
		hideMenu()
	}

	handleFilterChange(e){
		this.setState({filterTerm:e.target.value})
	}

	clearFilter(){
		const inputField = document.getElementById('filterInput')
		inputField.value=''
		inputField.blur()
		this.setState({filterTerm:'',filteredData:null})    
	}

	queueItem(item,section){
		this.props.createPoll(item,section.id)
	}

	handlePlayItem(item,section){
		if(section){
			this.props.playItem(item,section.id).then(() => {
				showLiveViewModal()
			})
		}else{
			this.props.showSectionSelectionModal(item)
		}
	}     

	archiveItem(item) {
		const nowDate = new Date()
		let notificationMessage
		const requestData = {
			...item,
			clientModified: nowDate,
			archived: true
		}
		if(item.questions){
			this.props.updateSetMeta(requestData, item.id).then(() => {
				notificationMessage=`${item.name}`
				this.props.showNotification(notificationMessage,'Sent to Trash','destroy')
			})
		} else {
			this.props.updateQuestionMeta(requestData, item.id).then(() => {
				notificationMessage=`${item.body}`
				this.props.showNotification(notificationMessage,'Sent to Trash','destroy')
			})
		}
	} 

	editSetName(set,name){
		const nowDate=new Date()
		if(name !== set.name && name!==''){ //only save if name has been edited and is non-empty
			const requestData = {...set,
				'name': name,
				'clientModified': nowDate,
			}
			this.props.updateSetContent(requestData, set.id)
		}
	}

	handleContextMenuClick(e, data){
		if (data.action === 'Archive') {
			this.archiveItem(data.item)
		}
		if (data.action === 'Open') {
			if (data.itemType === 'set') {
				openItemInEditor(data.itemId,true)
			}
			if (data.itemType === 'question') {
				openItemInEditor(data.itemId,false)
			}
		}else if (data.action === 'MoveToRepo') {
			this.props.showRepoSelectionModal(data.item)
		}else if (data.action === 'Rename') {
			this.props.showRenameSetModal(data.item)
		}else if (data.action === 'Play') {
			this.handlePlayItem(data.item,data.section)
		}else if (data.action === 'Queue') {
			this.queueItem(data.item,data.section)
		}else if (data.action === 'CopyToClipboard') {
			this.copyQuestionToClipboard(data.item)
		}else if (data.action === 'CopyToLibrary') {
			this.addCopyToLibrary(data.item)
		}else if (data.action === 'ItemDetail') {
			if(data.itemType==='set'){
				this.props.history.push(`/set/${data.item.id}`)
			}else {
				this.props.history.push(`/question/${data.item.id}`)
			}
		}
		this.delayedMenuClose()
	}
 
	keyPressNavigation(e){
		//prevent keypress navigation if context menu is open
		if(this.state.contextMenuActiveItem===null){
			const {activePanelItem}=this.state
			const {sortedRecentActivityItems}=this.props
			if(activePanelItem.id){
				if(e.keyCode===32 && !this.props.previewItem ){
					e.preventDefault()
					this.props.updatePreviewItem(activePanelItem.id)
					e.stopPropagation()
				}else if (e.keyCode === 40 || e.keyCode === 38 ){
					if(!this.props.previewItem){
						const activeIndex= findIndex(sortedRecentActivityItems, function (item) {
							return item.item === activePanelItem.id
						})
						e.preventDefault()// prevent scrolling container
						let newSelectedIndex
						//check if keypress was an up or down press
						if (e.keyCode === 40) { 
							newSelectedIndex=Math.min(activeIndex+1,sortedRecentActivityItems.length-1)				
						}else if (e.keyCode === 38) { 
							newSelectedIndex=Math.max(activeIndex-1,0)
						}
						const newSelectedActivityItem=sortedRecentActivityItems[newSelectedIndex]
						let itemDetail
							if(newSelectedActivityItem.itemType === 'set') {
							 itemDetail = find(this.props.sets, {'id': newSelectedActivityItem.item})
							}else if(newSelectedActivityItem.itemType === 'question') {
							itemDetail = find(this.props.questions, {'id': newSelectedActivityItem.item})
						}	 
						if(itemDetail && itemDetail.id!==activePanelItem.id){  
						 this.changeActiveItem(itemDetail)
						}
					}		
				}else if (e.keyCode === 13) { //if enter pressed on selected row open editor in new tab	
				if(!activePanelItem.readOnly){
					if(activePanelItem.body){
							openItemInEditor(activePanelItem.id,false)
						}else if(activePanelItem.questions){
							openItemInEditor(activePanelItem.id,true)
						}
					}
				}
			}
		}
	}

 
	render() {
	
		return (
			 <DocumentTitle title='Recent - Plickers'>
				<div className='page page--sidePanelLayout'>
					<div className='page-leftPanel' />
					<div className='page-centerPanel recent'>          
						<TopPageHeader
							location='recent' 
							leftBadge={<PageIcon name='recentLarge' />}
							header='Recent'
							actionRow={
								<React.Fragment>
									<FilterBar 
										value={this.state.filterTerm}
										onChange={this.handleFilterChange} 
										id='filterInput'
										clearBtnOnClick={() =>{this.clearFilter()}}
									/>
									<Button label='New Pack' onClickFunction={this.props.showNewRepoModal}/>
									<Button iconName='compose' label='New Set' onClickFunction={newSet}/>									
								</React.Fragment>
							}
						/>
						<StickyPageHeader 
							location='recent' 
							leftBadge={<PageIcon name='recentLarge' />}
							header='Recent'
							actionGroup={
								<React.Fragment>
									<Button label='New Pack' onClickFunction={this.props.showNewRepoModal}/>
									<Button iconName='compose' onClickFunction={newSet} label='New Set'/> 
								</React.Fragment>
							}
						/>	
						<RecentPage 
							sections={this.props.sections} //for context menu
							sortedRecentActivityItems={this.props.sortedRecentActivityItems}
							firstItemInDateGroupIds={this.props.firstItemInDateGroupIds}
							changeActiveItem={this.changeActiveItem}
							activeItem={this.state.activePanelItem}
							questions={this.props.questions}
							sets={this.props.sets}
							newSet={this.handleNewSet}
							filterTerm={this.state.filterTerm}
							handleContextMenuClick={this.handleContextMenuClick}
							keyPressNavigation={this.keyPressNavigation}
							onShowContextMenu={this.onShowContextMenu}
							onHideContextMenu={this.onHideContextMenu}
							contextMenuActiveItem={this.state.contextMenuActiveItem}
							playItem={this.handlePlayItem}
							navigateToItemDetail={this.navigateToItemDetail}
							contextMenuObject={this.state.contextMenuObject}
						/>
					</div>
					<RecentPreviewPanelContainer 
						isRecent={true}
						activePanelItem={this.state.activePanelItem}
					/>
			</div>
		</DocumentTitle>
		)
	}
}

function calculateFirstItemInDateGroupIds(activityItems) {
	const groupedItems = groupBy(activityItems, function(item) {
		const nowDate = new Date()
		const today = moment(nowDate).startOf('day')
		const week = moment(nowDate).startOf('isoWeek')
		if (moment(item.activityTime).isSame(today, 'd')) {
			return moment(item.activityTime).startOf('day')
		} else
		if (moment(item.activityTime).isSame(week, 'isoWeek')) {
			return moment(item.activityTime).startOf('isoWeek')
		} else {
			return moment(item.activityTime).startOf('month')
		}
	})
	let firstItemInDateGroupIds=[] //id of first item in each date group (used to insert headers in table)
	Object.keys(groupedItems).map(function(key) {
		const firstInGroupId=groupedItems[key][0].item
		firstItemInDateGroupIds.push(firstInGroupId)
		return null	
	})
	return firstItemInDateGroupIds
}


function mapStateToProps(state,ownProps) {
	let firstItemInDateGroupIds
	let activityItems=[]
	if(state.sets && state.questions){ //wait for sets/questions to load	
		activityItems=sortBy(state.recentActivities, 'activityTime').reverse()
		firstItemInDateGroupIds=calculateFirstItemInDateGroupIds(activityItems)
	}
	return {
		previewItem:state.previewItem,
		sections:state.sections,
		questions:state.questions,
		sets:state.sets,
		sortedRecentActivityItems:activityItems,
		firstItemInDateGroupIds:firstItemInDateGroupIds,
	}}

export default withRouter(connect(
	mapStateToProps,
	{ 
		fetchRecentActivities,
		showNotification,
		hideNotification,
		updateSetContent,
		updateSetMeta,
		updateQuestionMeta,
		playItem,
		createPoll,
		fetchQuestionPolls, 
		fetchSetPolls,
		showRenameSetModal,
		showSectionSelectionModal,
		showNewRepoModal,
		showRepoSelectionModal,
		addCopyToLibrary,
		updatePreviewItem
	}
)(RecentContainer))


import forEach from 'lodash/forEach'
import findIndex from 'lodash/findIndex'

export default function (state=[], action) {

	switch (action.type) { 

	case 'FETCH_QUESTIONS_SUCCESS':
	case 'FETCH_REPO_QUESTIONS_SUCCESS':
		let newState = [...state]
		action.response.forEach(question => {
			const questionId = question.id
			let indexOfQuestion = newState.findIndex(question => {
				return question.id === questionId
			})
	
			if(indexOfQuestion === -1){
				indexOfQuestion=newState.length
			}
			newState= [ 
				...newState.slice(0,indexOfQuestion),
				question,
				...newState.slice(indexOfQuestion + 1)
			]	
		})
		return newState


	case 'FETCH_A_QUESTION_SUCCESS':
		const questionId = action.response.id
		if(state){
			var indexOfQuestionFetched = state.findIndex(question => {
				return question.id === questionId
			})
			if(indexOfQuestionFetched === -1){
				indexOfQuestionFetched=state.length
			}

			return [
				...state.slice(0,indexOfQuestionFetched),
				action.response,
				...state.slice(indexOfQuestionFetched + 1)
			]
		}
		else 
			return [action.response]


	case 'REFRESH_QUESTIONS_SUCCESS':
		let questions=state.slice()
		forEach(action.response, function(modifiedQuestion) { //modified question is either new question or has been edited since last data refresh
			
			let indexOfQuestion = findIndex(questions,(question => {
				return question.id === modifiedQuestion.id
			}))
			if(indexOfQuestion !== -1){ //if question if already in question array (is not new since last data refresh) remove old object
				questions =[
					...questions.slice(0,indexOfQuestion),
					...questions.slice(indexOfQuestion + 1)
				]
			}
			questions=[modifiedQuestion,...questions]
			
		})

		return questions	
	
	
	case 'CREATE_QUESTION_SUCCESS':
	case 'QUESTION_ADDED_PUSHER':
		if(state){
			const addedQuestionId = action.question.id
			var indexOfQuestion = findIndex(state,question => {
				return question.id === addedQuestionId
			})
			if(indexOfQuestion === -1){
				indexOfQuestion=state.length
			}
	
			return [
				...state.slice(0,indexOfQuestion),
				action.question,
				...state.slice(indexOfQuestion + 1)
			]

		}
		else{
			const newQuestion=action.question
			return [newQuestion]
		}



	case 'UPDATE_QUESTION_SUCCESS':
	case 'UPDATE_QUESTION_FROM_PUSHER':
		const updatedQuestionId = action.response.id
		var indexOfQuestionToUpdate = findIndex(state,(question => {
			return question.id === updatedQuestionId
		}))
		if(indexOfQuestionToUpdate !== -1 ){
			if(action.response.archived===false){
				return [
					...state.slice(0,indexOfQuestionToUpdate),
					action.response,
					...state.slice(indexOfQuestionToUpdate + 1)
				]
			}else {
				return [
					...state.slice(0,indexOfQuestionToUpdate),
					...state.slice(indexOfQuestionToUpdate + 1)
				]
			}}
		else {
			if(action.response.archived===false){
				return [...state,action.response]
			}else return state
		}

	case 'DELETE_QUESTION_SUCCESS':
		const deletedQuestionId = action.questionId
		const indexOfQuestionToDelete = state.findIndex(question => {
			return question.id === deletedQuestionId
		})
		if(indexOfQuestionToDelete !==-1){	
			return [
				...state.slice(0,indexOfQuestionToDelete),
				...state.slice(indexOfQuestionToDelete + 1)
			]
		}else return state


	case 'QUESTION_REMOVED_PUSHER':
		const removedQuestionId = action.questionId
		const removedFromRepoId=action.repoId
		const indexOfQuestionToRemove = state.findIndex(question => {
			return (question.id === removedQuestionId && question.repo===removedFromRepoId)
		})

		if(indexOfQuestionToRemove !==-1){	
			return [
				...state.slice(0,indexOfQuestionToRemove),
				...state.slice(indexOfQuestionToRemove + 1)
			]
		}else return state







	case 'LOGOUT':
		return null

	default:
		return state

	}
}
import React from 'react'
import Icon from '../misc/Icons'
 
class FeatureModalSelectControl extends React.Component{

	onKeyDown(e){
		if(e.which === 13) {
			e.preventDefault()
			e.stopPropagation()
			document.getElementById('selectControlTextInput').blur()
		}
	} 

	render(){
		const {checked,onClick,label,textInput,checkbox,radio,subLabel,subControl,uncheckable,textInputValue,textInputOnChange}=this.props
		return(
		<React.Fragment>						
			<div onClick={onClick} className={'featureModal-selectControl ' + (checked ? ' featureModal-selectControl--active ':' featureModal-selectControl--inactive ') + (subControl ? ' featureModal-selectControl--subControl ':'') + (uncheckable ? ' featureModal-selectControl--uncheckable ':' ')}>
				
				{checkbox &&
					<div className='featureModal-selectControl-checkbox'>
						{checked &&
							<Icon name='checkbox-check' />
						}
					</div>						
				}

				{radio &&
					<div  className='featureModal-selectControl-radio'>
						{checked &&
							<div className='featureModal-selectControl-radio-activeCircle' />
						}
					</div>						
				}

				<div className='featureModal-selectControl-label'>
					{label}
					
					{subLabel &&
						<div className='featureModal-selectControl-label-sub'>
							{subLabel}
						</div>
					}
				</div>

			</div>			
				
				{textInput &&
					<input						
						className='featureModal-selectControl-textInput'
						value={textInputValue}
						onChange={textInputOnChange}											
						maxLength={70}
						placeholder='Complete by Thursday'
						autoComplete="off"
						autoCorrect="off"
						autoCapitalize="off" 
						onKeyDown={this.onKeyDown}
						id='selectControlTextInput'
					/>
				}
			</React.Fragment>
		)
	}
}

export default FeatureModalSelectControl

//Defaults
// studentSortOrder='name'
//studentListAlwaysVisible=false
//alwaysShowControls=false


export function getLiveViewSettings() {	

	//Defaults
	let liveViewSettings={
		studentSortOrder:'name',
		studentListVisible:'scan',
		alwaysShowControls:true,
		alwaysShowLetterblock:false,
		showStudentCardNumbers:false,
		colorScheme:'scheme1',
		alwaysShowGraph:false,
		showIndividualResponses:'never',
		showResponseCounts:false,
		disableZoomAnimations:false
	}

	const liveViewSettingsFromLocalStorage = JSON.parse(localStorage.getItem('liveViewSettings'))

	if(liveViewSettingsFromLocalStorage ){
		if(liveViewSettingsFromLocalStorage.studentSortOrder){
			liveViewSettings.studentSortOrder=liveViewSettingsFromLocalStorage.studentSortOrder
		}
		if(liveViewSettingsFromLocalStorage.studentListVisible){
			liveViewSettings.studentListVisible=liveViewSettingsFromLocalStorage.studentListVisible
		}
		if(liveViewSettingsFromLocalStorage.alwaysShowControls || !liveViewSettingsFromLocalStorage.alwaysShowControls){
			liveViewSettings.alwaysShowControls=liveViewSettingsFromLocalStorage.alwaysShowControls
		}
		if(liveViewSettingsFromLocalStorage.alwaysShowLetterblock){
			liveViewSettings.alwaysShowLetterblock=liveViewSettingsFromLocalStorage.alwaysShowLetterblock
		}
		if(liveViewSettingsFromLocalStorage.showStudentCardNumbers){
			liveViewSettings.showStudentCardNumbers=liveViewSettingsFromLocalStorage.showStudentCardNumbers
		}
		if(liveViewSettingsFromLocalStorage.colorScheme){
			liveViewSettings.colorScheme=liveViewSettingsFromLocalStorage.colorScheme
		}
		if(liveViewSettingsFromLocalStorage.alwaysShowGraph){
			liveViewSettings.alwaysShowGraph=liveViewSettingsFromLocalStorage.alwaysShowGraph
		}
		if(liveViewSettingsFromLocalStorage.showIndividualResponses){
			liveViewSettings.showIndividualResponses=liveViewSettingsFromLocalStorage.showIndividualResponses
		}
		if(liveViewSettingsFromLocalStorage.showResponseCounts){
			liveViewSettings.showResponseCounts=liveViewSettingsFromLocalStorage.showResponseCounts
		}
		if(liveViewSettingsFromLocalStorage.disableZoomAnimations || !liveViewSettingsFromLocalStorage.disableZoomAnimations){
			liveViewSettings.disableZoomAnimations=liveViewSettingsFromLocalStorage.disableZoomAnimations
		}


	}

	return liveViewSettings

}


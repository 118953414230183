import React from 'react'
import SectionMarker from '../uiKit/SectionMarker'
import ScoreSheetSidePanelCalendar from './ScoreSheetSidePanelCalendar'

class ScoreSheetSidePanel extends React.Component{
	render(){
		return(
			<div className='scoreSheet-sidePanel'>					
				<ScoreSheetSectionSelection {...this.props} />	
				{!this.props.customDateRange &&	this.props.activeSectionId &&							
					<ScoreSheetSidePanelCalendar key={this.props.dateRange.startDate} {...this.props} />
				}
			</div>
		)
	}
}

export default ScoreSheetSidePanel


class ScoreSheetSectionSelection extends React.Component{

	constructor(){
		super()
		this.renderSectionSelectionButton = this.renderSectionSelectionButton.bind(this)
	}

	renderSectionSelectionButton(section){  	
		const {activeSectionId} = this.props
		return(    		
			<div key={section.id} 
			className={'scoreSheet-sidePanel-sectionSelection-section' + 
			(activeSectionId === section.id ? ' scoreSheet-sidePanel-sectionSelection-section--active' : ' scoreSheet-sidePanel-sectionSelection-section--inactive')}
			onClick={()=>{this.props.changeActiveSection(section.id)}}
			>
				<SectionMarker section={section}/>
				<SectionMarker section={section} type={'colorOnly'} />					
			</div>
		)
	}   

	render(){
		const {sections}=this.props
		return(
			<div className='scoreSheet-sidePanel-sectionSelection'>
				<div className='scoreSheet-sidePanel-sectionSelection-header'>
					Your Classes
				</div>
				{sections.map(this.renderSectionSelectionButton)}
			</div>
		)
	}
}
import React from 'react'

class DataOwnerInfoContainer extends React.Component {
  render() {
    const { dataOwner } = this.props
    const rows = [
      { label: 'Name', fieldName: 'name' },
      { label: 'Short name', fieldName: 'shortName' },
      { label: 'Location', fieldName: 'locationName' },
    ]
    return (
      <div className='dataOwnerInfoContainer'>
        {rows.map((row) => (
          <div className='dataOwnerInfoContainer-row' key={row.fieldName}>
            <div className='dataOwnerInfoContainer-row-label'>
              {row.label}
            </div>
            <div className='dataOwnerInfoContainer-row-value'>
              {dataOwner[row.fieldName]}
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default DataOwnerInfoContainer

import {createNewSet} from '../actions/sets'

 
export function combineIntoSet(items, parent,repo) {
	let questionArray = []
	Object.keys(items).map((key) => {
		const item=	items[key]
		if(item.choices){ //question
			const randId='00000000'.replace(/0/g,function(){return (~~(Math.random()*16)).toString(16)})
			let newQuestion=item
			newQuestion.questionId=randId
			questionArray.push(newQuestion)
		}
		else if(item.questions){ //set
			item.questions.map((setQuestion, index) => {
				const randId='00000000'.replace(/0/g,function(){return (~~(Math.random()*16)).toString(16)})
				let newQuestion = setQuestion
				newQuestion.questionId=randId
				questionArray.push(newQuestion)
				return null
			})
		}			
		return null
	})	
	
	const nowDate = new Date()
	const requestBody = {
		'folder': parent,
		'name': 'New Set from Selection',
		'description': 'Empty description',
		'questions': questionArray,
		'clientModified': nowDate,
		'archived': false,
		'userCreated': nowDate,
		'lastEditedAt':nowDate,
		'repo': repo

	}
	return createNewSet(requestBody)
}








import React from 'react'
import ModalWrapper from '../ModalWrapper'
import UpdateEmailContainer from '../../../containers/modals/account/UpdateEmailContainer'

const UpdateEmailModal = props => {

	return (

      <ModalWrapper
        {...props}
        title={'Update Email'}
        iconName=''
        modalStyle='dark'
      >
        <UpdateEmailContainer {...props}/>

      </ModalWrapper>

   

  )
}

export default UpdateEmailModal
 import discoverRepoIds from '../staticData/discoverRepoIds.js'
 import includes from 'lodash/includes'

const scienceRepoShortIds=discoverRepoIds.scienceRepoShortIds
const biologyRepoShortIds=discoverRepoIds.biologyRepoShortIds
const mathsRepoShortIds=discoverRepoIds.mathsRepoShortIds


export function isRepoInDiscoverBeta(repo) {	
	let repoInDiscoverBeta=false
	if(repo && repo.shortId){
		const shortId=`${repo.shortId}`	
		if(includes(scienceRepoShortIds,shortId)||includes(biologyRepoShortIds,shortId)||includes(mathsRepoShortIds,shortId)){
			repoInDiscoverBeta=true
		}
	}
	return repoInDiscoverBeta	
}

import React from 'react'
import EditorIcon from '../../../../misc/EditorIcons'

class EditorInsertSoundInitialPage extends React.Component {
  constructor() {
    super()
    this.handleKeyDown = this.handleKeyDown.bind(this)

    this.state = {
      userHasDeniedBrowserPermissions: false,
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
    if (navigator && navigator.permissions) {
      navigator.permissions.query({ name: 'microphone' }).then((result) => {
        if (result.state === 'denied') {
          this.setState({ userHasDeniedBrowserPermissions: true })
        }
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(e) {
    if (e.keyCode === 32) {
      this.props.startRecording('spacebarRecording')
    }
    if (e.keyCode === 27) { // escape key
      this.props.closeInsertSoundModal()
    }
  }

  render() {
    const { userHasDeniedBrowserPermissions } = this.state

    return (
      <React.Fragment>
        <div className="editor-insertSoundModal editor-insertSoundModal--initial">

          <a target="_blank" className="editor-insertSoundModal-helpBtn" rel="noopener noreferrer" href="https://help.plickers.com/hc/en-us/articles/4405580289307">Help: Recording and Using Sound Clips</a>

          <button onClick={this.props.closeInsertSoundModal} className="editor-insertSoundModal-cancelBtn">
            Cancel
          </button>

          {!userHasDeniedBrowserPermissions && (
          <div onClick={() => { this.props.startRecording('clickRecording') }} className="editor-insertSoundModal--initial-recordingContainer editor-insertSoundModal--initial-recordingContainer--ready">
            <button className="editor-insertSoundModal--initial-recordBtn">
              <EditorIcon name="microphone" />
            </button>
            <div className="editor-insertSoundModal--initial-recording--tooltips">
              <div className="editor-insertSoundModal--initial-recording--tooltip-spacebarRecord">
                Click or hold
                {' '}
                <span className="editor-insertSoundModal--initial-recording--tooltip-spacebarRecord-spacebar">Spacebar</span>
                {' '}
                to record audio
              </div>
            </div>
          </div>
          )}

          {userHasDeniedBrowserPermissions && (
          <div className="editor-insertSoundModal--initial-recordingContainer editor-insertSoundModal--initial-recordingContainer--denied">

            <EditorIcon name="microphoneMute" />

            <a target="_blank" className="editor-insertSoundModal--initial-recording--denied-h1" rel="noopener noreferrer" href="https://help.plickers.com/hc/en-us/articles/4405974784411">
              To record sound clips, Plickers needs permission to access your microphone.
              <br />
              Please go to your browser settings to grant Plickers access.
            </a>

            <a target="_blank" className="editor-insertSoundModal--initial-recording--denied-h2" rel="noopener noreferrer" href="https://help.plickers.com/hc/en-us/articles/4405974784411">
              Learn more
            </a>

          </div>
          )}

          <div className="editor-insertSoundModal--initial-footerContainer">

            {
              /* eslint-disable max-len */
              /* FOR NOW, GO TO STAGE SELECT FROM DEV GALLERY
                <button
                  onClick={()=>{this.setState({showDevSoundGallery:true})}}
                  className='editor-insertSoundModal--initial-uploadBtn'
                >
                  <div className='editor-insertSoundModal--initial-uploadBtn-primary'>
                    Upload Audio File...
                  </div>
                  <div className='editor-insertSoundModal--initial-uploadBtn-secondary'>
                    AAC, AIFF, MP3, MP4, WAV
                  </div>
                </button>

                {showDevSoundGallery &&
                    <div className='editor-insertSoundModal--initial-devSoundGalleryContainer'>
                      <DevSoundGallery onSelectSound={(sound,fileName,duration)=>{this.props.onUploadSoundFile(sound,fileName,duration)}} />
                      <div onClick={()=>{this.setState({showDevSoundGallery:false})}} className='editor-insertSoundModal--initial-devSoundGalleryContainerBG'/>
                    </div>
                }
              */
              /* eslint-enable max-len */
            }

            <div className="editor-insertSoundModal--initial-uploadBtn">
              <input
                id="file"
                type="file"
                value=""
                name="uploadImage"
                /* eslint-disable max-len */
                // accept="audio/aac, audio/aiff, audio/m4a, audio/mp4, audio/ogg, audio/wav, audio/mp3, audio/webm, audio/x-m4a"
                // accept="audio/mp3, audio/wav, audio/aac, audio/aiff"
                /* eslint-enable max-len */
                accept="audio/*"
                className="editor-insertSoundModal--initial-uploadBtn-hiddenUploadInput"
                onChange={(e) => { this.props.handleSelectFile(e) }}
              />
              <label htmlFor="file" className="editor-insertSoundModal--initial-uploadBtn--label">
                <div className="editor-insertSoundModal--initial-uploadBtn-primary">
                  Upload Audio File...
                </div>
                {/* }
                  <div className='editor-insertSoundModal--initial-uploadBtn-secondary'>
                    AAC, AIFF, MP3, MP4, WAV
                  </div>
                  */}
              </label>
            </div>

            <button onClick={this.props.showSoundLibrary} className="editor-insertSoundModal--initial-addFromYouTubeBtn">
              <div className="editor-insertSoundModal--initial-addFromYouTubeBtn-primary">
                <span className="editor-insertSoundModal--initial-addFromYouTubeBtn-primary-icon">
                  <EditorIcon name="insertSoundLibrary" />
                </span>
                Sound Library
              </div>
            </button>

          </div>

        </div>

      </React.Fragment>

    )
  }
}

export default EditorInsertSoundInitialPage

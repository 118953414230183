import React from 'react'
import ReactDOM from 'react-dom'
import QuestionSoundReactComponent from './QuestionSoundReactComponent' 
import {showEditSoundModal} from '../../utils/showEditSoundModal'
import {playMediaNode} from '../../../../prosemirror/utils/editorActions/playMediaNode'

function handleMouseDown(e){
	e.preventDefault()
	if (e.target === e.currentTarget){
		e.stopPropagation()
	} 
}


class QuestionSoundNodeView {

	constructor(node, view, getPos) {
		this.getNode=this.getNode.bind(this)
		this.showEditSoundModal=this.showEditSoundModal.bind(this)
		this.node = node
		this.getPos=getPos
		this.playSound=this.playSound.bind(this)

		const {fileId,deliveryUrl,start,end,title,template,mediaHeight,isPlaying,isClipped,slideWidth} = node.attrs

		this.dom = document.createElement("div")
		this.dom.className="slide-mediaContainer slide-mediaContainer--sound"    
		this.dom.addEventListener('mousedown',handleMouseDown)

		ReactDOM.render(
			<QuestionSoundReactComponent 
				key={deliveryUrl}
				isClipped={isClipped}
				fileId={fileId}
				deliveryUrl={deliveryUrl}
				start={start}
				end={end}
				title={title}
				template={template}
				mediaHeight={mediaHeight}
				showEditSoundModal={this.showEditSoundModal}
				isPlaying={isPlaying}
				playSound={this.playSound}
				slideWidth={slideWidth}
			/>, this.dom)
	} 

	playSound(){
		const position=this.getPos()
		playMediaNode(position)
	}

	showEditSoundModal(focusTitle){
		const {fileId,title,fullDuration,start,end,attribution} = this.node.attrs
		const nodePos=this.getPos()
		showEditSoundModal(fileId,title,attribution,fullDuration,start,end,this.node,nodePos,focusTitle)
	}

	getNode(){
		return this.node
	}
	
	update(node) {
	if (node.type !== this.node.type) return false
	const {deliveryUrl,title,start,end,template,mediaHeight,fileId,isPlaying,isClipped,slideWidth} = node.attrs
	const oldTemplate=this.node.attrs.template
	const oldMediaHeight=this.node.attrs.mediaHeight
	const oldStart=this.node.attrs.start
	const oldEnd=this.node.attrs.oldEnd
	const oldTitle=this.node.attrs.title
	const oldFileId=this.node.attrs.fileId
	const oldIsPlaying=this.node.attrs.isPlaying
	const oldSlideWidth=this.node.attrs.slideWidth
	 if(template !==oldTemplate 
		|| mediaHeight!==oldMediaHeight
		|| oldStart!==start
		|| oldEnd !== end
		|| oldFileId!==fileId
		|| oldTitle!==title
		|| oldIsPlaying !==isPlaying
		|| oldSlideWidth !== slideWidth
		){							
		ReactDOM.render(
			<QuestionSoundReactComponent 
				key={deliveryUrl}
				fileId={fileId}
				isClipped={isClipped}
				deliveryUrl={deliveryUrl}
				start={start}
				end={end}
				setSoundClipTimes={this.setSoundClipTimes}
				template={template}
				mediaHeight={mediaHeight}
				showEditSoundModal={this.showEditSoundModal}
				isPlaying={isPlaying}
				playSound={this.playSound}
				title={title}
				slideWidth={slideWidth}
			/>, this.dom)

	}
		this.node=node
		return true
	}


	destroy() {
		this.dom.removeEventListener('mousedown',handleMouseDown)
		ReactDOM.unmountComponentAtNode(this.dom)
	}

}

export default QuestionSoundNodeView
import api from '../api'

export function fetchSymbolsList() {
	return (dispatch) => api.batchedFetch('/symbols?')
		.then((response) => {
			dispatch({ type: 'FETCH_SYMBOLS_LIST_SUCCESS', response})
		})
		.catch((error) => {
			return error
		})
}

export function fetchRecentSymbols() {
	return (dispatch) => api.fetch('/recentSymbols')
		.then((response) => {
			dispatch({ type: 'FETCH_RECENT_SYMBOLS_SUCCESS', response})
		})
		.catch((error) => {
			return error
		})
}

export function upsertRecentSymbols(symbolId) {
	const nowDate = new Date()
	const requestBody={
		lastUsedAt: nowDate
	}
	return (dispatch) => api.put(`/recentSymbols/${symbolId}`,requestBody)
		.then((response) => {
			dispatch({ type: 'UPSERT_RECENT_SYMBOLS_SUCCESS',response })
		})
		.catch((error) => {
			return error
		})
}
import moment from 'moment'

function isOutsideRange(momentObj, firstDataDate) {
  // the moment object passed here is a little after 12am or 12pm (local)
  // if simply compared (i.e. momentObj.isAfter(moment())),
  // it causes a bug of today's date being unavailable in am hours
  // so they need to be compare just the dates
  const date = momentObj.startOf('day')
  const today = moment().startOf('day')
  const isAfterToday = date.isAfter(today)
  return firstDataDate ?
    // cannot select futures dates or dates before earliest minCaptured
    (isAfterToday || date.isBefore(moment(firstDataDate).startOf('day'))) :
    // cannot select future dates
    isAfterToday
}

export default isOutsideRange

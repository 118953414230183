import React from 'react'
import Select from 'react-select'
import 'react-select/dist/react-select.css'

class FormFieldSelect extends React.Component{

	render(){	
		const {disabled, input, label, subLabel, placeholderOptionLabel} = this.props

		let inputLabel = input.name
		if(label){
			inputLabel = label
		}

		return(
			<div className={'formField is--select' + (input.name ? ` is--${input.name}` : '') }>					

				{inputLabel &&
				<div className='formField-label'>
					{inputLabel}
				</div>
				}

				{subLabel &&
				<div className='formField-subLabel'>
					{subLabel}
				</div>
				}

				<div className='formField-select--container'>

					<Select
						disabled={disabled}
						{...this.props}
						value={this.props.input.value}
						onChange={(option) => this.props.input.onChange(option.value)}
						onBlur={() => this.props.input.onBlur(this.props.input.value)}
						options={this.props.options}
						searchable={false}
						clearable={false}
						placeholder={placeholderOptionLabel}
					/>

				</div>
			</div>
		)
	}
				
}

FormFieldSelect.defaultProps = {
	disabled:false
}

export default FormFieldSelect



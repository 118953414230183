import React from 'react'
import {ContextMenuTrigger } from 'react-contextmenu'


class StudentCell extends React.Component{

	collect(props) {
		return { student: props.student}
	}


	render(){	
		
		const {student,activeStudent,contextMenuIsOpen} = this.props
		let isActive=false
		if(student && activeStudent){
			if(student.id===activeStudent){
			 	isActive=true
			}}
	
		return(
			<ContextMenuTrigger 
          		id={'STUDENT'} 
          		student={student}
          		collect={this.collect}
        	>
			<div id={student.id} className={'studentCell notranslate ' + (isActive ? ' is--active' : '' )+ (contextMenuIsOpen ? ' has--menu' : '')} onClick={() =>{this.props.setActiveStudent(student)}} onDoubleClick={() =>{this.props.editStudentName(student)}} >
				<div className="studentCell-content">
					<div className="studentCell-left" />
					<div className="studentCell-firstName">
						{student.firstName}
					</div>
					<div className="studentCell-lastName">
						{student.lastName}
					</div>
					<div className="studentCell-cardNo">
						<div className="studentCell-cardNo-numberBlock">
							{student.card}
						</div>
					</div>
					<div className="studentCell-right" />
				</div>
			</div>
			</ContextMenuTrigger>
		)	
	}	
}

export default StudentCell



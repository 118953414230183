import React, { Component } from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

class SampleDataPanelControlBox extends React.Component {
  render() {
    const {
      label, maxCharacters, characterCount, updateSampleQuestionFieldLength, isMedia,
    } = this.props
    return (
      <div className="fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox">

        <div className="fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-title">
          {label}
        </div>

        {(this.props.label === 'choiceC' || this.props.label === 'choiceD') && (
        <button
          className="fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-choiceHideBtn"
          onClick={() => { this.props.toggleShowChoice(this.props.label) }}
        >
          Toggle
        </button>
        )}

        <div className="fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-value">
          <div className="fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-value-value">
            {characterCount}
          </div>
          <div className="fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-value-label">
            {isMedia ? 'pixels' : 'characters'}
          </div>
        </div>

        <div className="fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-sliderContainer">
          <Slider
            onChange={(value) => { updateSampleQuestionFieldLength(label, value) }}
            min={0}
            max={maxCharacters}
            value={characterCount}
          />
        </div>
      </div>
    )
  }
}

class FieldLengthSelectPanel extends Component {
  render() {
    const {
      questionBodyCharacterCount,
      choiceACharacterCount,
      choiceBCharacterCount,
      choiceCCharacterCount,
      choiceDCharacterCount,
      mediaHeight,
      updateMediaHeight,
      updateSampleQuestionFieldLength,
      toggleShowChoice,
    } = this.props
    return (
      <div className="fontSizeCalculationVisualizer-fieldLengthSelectPanel">
        <SampleDataPanelControlBox label="questionBody" characterCount={questionBodyCharacterCount} maxCharacters={400} updateSampleQuestionFieldLength={updateSampleQuestionFieldLength} />
        <SampleDataPanelControlBox label="choiceA" characterCount={choiceACharacterCount} maxCharacters={220} updateSampleQuestionFieldLength={updateSampleQuestionFieldLength} />
        <SampleDataPanelControlBox label="choiceB" characterCount={choiceBCharacterCount} maxCharacters={220} updateSampleQuestionFieldLength={updateSampleQuestionFieldLength} />
        <SampleDataPanelControlBox label="choiceC" characterCount={choiceCCharacterCount} maxCharacters={220} updateSampleQuestionFieldLength={updateSampleQuestionFieldLength} toggleShowChoice={toggleShowChoice} />
        <SampleDataPanelControlBox label="choiceD" characterCount={choiceDCharacterCount} maxCharacters={220} updateSampleQuestionFieldLength={updateSampleQuestionFieldLength} toggleShowChoice={toggleShowChoice} />

        <SampleDataPanelControlBox isMedia label="mediaHeight" characterCount={mediaHeight} maxCharacters={400} updateSampleQuestionFieldLength={updateMediaHeight} />

      </div>
    )
  }
}

export default FieldLengthSelectPanel

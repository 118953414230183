import React from 'react'
import { ContextMenu, MenuItem} from 'react-contextmenu'


 
 
class SectionHomeQueueContextMenu extends React.Component{
	render(){
		const {handleContextMenuClick,contextMenuPollIndex,queueLength}=this.props

		


		return(
			<ContextMenu id='SECTION_QUEUE' className='contextMenu' onShow={this.props.onShowContextMenu} onHide={this.props.onHideContextMenu}>  
				
				<MenuItem  onClick={handleContextMenuClick} data={{action: 'Play'}}>			    
					Play Now	    
				</MenuItem>

				<MenuItem onClick={handleContextMenuClick} data={{action: 'Open'}}>			    
					{this.props.isViewOnlyRepo ? 'Edit' : 'View'}
				</MenuItem>

				 <MenuItem divider/>
				
				<MenuItem onClick={handleContextMenuClick} data={{action: 'UpNext'}}>			    
					Move Up Next
				</MenuItem>

				<MenuItem divider/>

				<MenuItem disabled={contextMenuPollIndex===0} onClick={handleContextMenuClick} data={{action: 'MoveUp'}}>			    
					Move up
				</MenuItem>
		
				
				<MenuItem disabled={contextMenuPollIndex===queueLength-1} onClick={handleContextMenuClick} data={{action: 'MoveDown'}}>			    
					Move down
				</MenuItem>

			  <MenuItem divider/>
			
				<MenuItem  attributes={{className:'is--warning'}} onClick={handleContextMenuClick} data={{action: 'RemoveFromQueue'}}>			    
					Remove
				</MenuItem>



			</ContextMenu>
		)
	}
}


export default SectionHomeQueueContextMenu



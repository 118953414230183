import api from '../api'
import { reset } from 'redux-form'


export function newStudent(data) {
  	return (dispatch) => {
  		dispatch({ type: 'CREATE_STUDENT_REQUEST', data}) //update state with placeholder student so UI doesn't lag
  		dispatch(reset('newStudentForm'))
  		return api.post('/students/',data)
	    .then((response) => {
		      dispatch({ type: 'CREATE_STUDENT_SUCCESS', response})
				return response
	    })
	   .catch(err => {
				return 'failed'
			})
	}
}

 
//for bulk upload we dont need the placeholder student object on request sent
export function newStudentBulk(data) {
  	return (dispatch) => api.post('/students/',data)
	    .then((response) => {
		      dispatch({ type: 'CREATE_STUDENT_SUCCESS', response,bulkAction:true})
			return response
	    })
	     .catch(err => {
			return 'failed'
		})
}

export function deleteStudent(studentId,sectionId) {
  	return (dispatch) => {
 	 dispatch({ type: 'DELETE_STUDENT_REQUEST', studentId,sectionId})
  		return api.delete(`/students/${studentId}`)
	    .then((response) => {
		      dispatch({ type: 'DELETE_STUDENT_SUCCESS', response,studentId,sectionId})
	    })
	}
}

export function updateStudent(studentId,data,sectionId) {
  	return (dispatch) => api.put(`/students/${studentId}`,data)
	    .then((response) => {
		      dispatch({ type: 'UPDATE_STUDENT_SUCCESS', response,studentId,sectionId})
		       return response
	    })
	    .catch(err => {
			return 'failed'
		})
}
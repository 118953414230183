import React from 'react'
// import couponDescriptions from '../../couponDescriptions.js'


class UpgradeDiscountSash extends React.Component{

	render(){	
		// const couponDescription=couponDescriptions[process.env.REACT_APP_DEFAULT_COUPON_CODE]

		return(
			
			<div className='upgrade--introDiscountSashContainer'>
				<div className='upgrade--introDiscountSash'>
					<div className='upgrade--introDiscountSash-title'>
							Save 20%
						</div>
						<div className='upgrade--introDiscountSash-sub'>
							lifetime discount on all plans
						</div>
					{/* 
						<div className='upgrade--introDiscountSash-title'>
							{couponDescription.percentage}% off
						</div>
						<div className='upgrade--introDiscountSash-sub'>
							annual plans until {couponDescription.until}							
						</div>
					*/}
				</div>
			</div>

		)	
	}
				
}

export default UpgradeDiscountSash



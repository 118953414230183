import React, {Component} from 'react'
import { connect } from 'react-redux'
import Dropdown from './uiKit/Dropdown'
import DropdownItem from './uiKit/DropdownItem'
import DropdownDivider from './uiKit/DropdownDivider'
import { withRouter, matchPath } from 'react-router-dom'
import CoreHelpDropdownItems from './CoreHelpDropdownItems'
import {dismissOnboardingWizard,showOnboardingWizard} from '../actions/onboardingWizard'
import Icon from './misc/Icons'

class HelpDropdownButton extends Component{
	render(){
		const {onClickFunction}=this.props
		return(
			<button className='btn btn--mid btn--white btn--dropdown helpDropdown btn--overflowEllipses' onClick={onClickFunction}>
			 Help
			 <Icon name='chevron-small-down' />
			</button>
		)
	}
}


class HelpDropdown extends Component{
	constructor(){
		super()	
		this.getCurrentPage=this.getCurrentPage.bind(this) 
	}

	getCurrentPage(){ //we show different links in help dropdown depending on where the user is in the app
		const {pathname}=this.props.location
		let currentPage	
		if(pathname.indexOf('/library')===0){
			 currentPage='library'
		}else if(pathname.indexOf('/recent')===0){
			currentPage='recent'
		}else if(matchPath(pathname, { path:'/classes/:id',isExact:true}) && !matchPath(pathname, { path:'/classes/:id/:sectionNav',isExact:true}) ){
			currentPage='classHome'
		}else if(pathname.indexOf('/setreport')===0){
			currentPage='setreport'
		}else if(pathname.indexOf('/questionreport')===0){
			currentPage='questionreport'
		}else if(pathname.indexOf('/account')===0){
			currentPage='account'
		}else if(pathname.indexOf('/packs')===0){
			currentPage='packs'
		}
		return currentPage

	}



	render(){	
		const currentPage = this.getCurrentPage()
		const {service,meta}=this.props
		return(		
			<Dropdown dropdownButton={<HelpDropdownButton />} >							
							
				{currentPage === 'library' &&
					<DropdownItem label='Library and Recent Help' onSelect={()=>{window.open('https://help.plickers.com/hc/en-us/articles/360008948934-Library-Recent','_blank')}} />
				}

				{currentPage === 'recent' &&
					<DropdownItem label='Library and Recent Help' onSelect={()=>{window.open('https://help.plickers.com/hc/en-us/articles/360008948934-Library-Recent','_blank')}} />
				}
				
				{currentPage === 'classHome' &&
					<DropdownItem label='Class Homepage Help' onSelect={()=>{window.open('https://help.plickers.com/hc/en-us/articles/360008949014-Your-Class-Homepage','_blank')}} />
				}				

				{currentPage === 'setreport' &&
					<DropdownItem label='Set Report Help' onSelect={()=>{window.open('https://help.plickers.com/hc/en-us/articles/360008949114-Set-Reports','_blank')}} />
				}

				{currentPage === 'questionreport' &&
					<DropdownItem label='Question Report Help' onSelect={()=>{window.open('https://help.plickers.com/hc/en-us/articles/360008949114-Set-Reports','_blank')}} />
				}

				{currentPage === 'account' &&
					<DropdownItem label='Account Help' onSelect={()=>{window.open('https://help.plickers.com/hc/en-us/articles/360009089753-Update-Account-Info','_blank')}} />
				}
				{currentPage === 'packs' &&
					<DropdownItem label='Packs Help' onSelect={()=>{window.open('https://help.plickers.com/hc/en-us/articles/360025736913','_blank')}} />
				}

				{currentPage &&
					<DropdownDivider />		
				}

				<CoreHelpDropdownItems
					service={service}
					meta={meta}		
					dismissOnboardingWizard={this.props.dismissOnboardingWizard}
					showOnboardingWizard={this.props.showOnboardingWizard}	
					onboardingWizardDismissed={this.props.onboardingWizardDismissed}		
				/>


			</Dropdown>
				
		)
	}
}



export default withRouter(connect(
	(state) => ({
		service:state.service,
		meta:state.meta,
		onboardingWizardDismissed:state.onboardingWizardDismissed
	
	}),
	{ dismissOnboardingWizard,showOnboardingWizard }
)(HelpDropdown))

import api from '../api'
import {fetchRecentActivities,updateRecentActivities} from './recentActivities'
import {fetchSetPolls} from './polls'
import store from '../store'
import moment from 'moment'
import * as Sentry from '@sentry/browser'

const MINIMUM_TIME_BETWEEN_REFRESHES=1000*60 //60seconds

export function fetchSets() {
	const state = store.getState()
	if(state.setsRefreshedTimestamp){// if have previously fetched sets check the timestamp of last fetch. Only fetch if was more than MINIMUM_TIME_BETWEEN_REFRESHES ago
		const nowDate =moment()
		const timeSinceLastRefresh=nowDate - moment(state.setsRefreshedTimestamp)
		if(timeSinceLastRefresh > MINIMUM_TIME_BETWEEN_REFRESHES){
			return (dispatch) => {
				api.batchedFetch(`/sets?archived=false&parentArchived=false&&sort=modified&modifiedAfter=${state.setsRefreshedTimestamp}`)
	   		 .then((response) => {
		    	  dispatch({ type: 'REFRESH_SETS_SUCCESS', response})
	    	})
	     .catch(err => {})
	     	api.batchedFetch(`/sets?allRepos=true&archived=false&parentArchived=false&&sort=modified&modifiedAfter=${state.setsRefreshedTimestamp}`)
	   		 .then((response) => {
		    	  dispatch({ type: 'REFRESH_SETS_SUCCESS', response})
	    	})
	     .catch(err => {})
	 	}
		}else{
		 return({ type: 'WAIT_TO_REFRESH'})
		}
	}else return (dispatch) => {
  		api.batchedFetch('/sets?archived=false&sort=modified&parentArchived=false')
	    .then((response) => {
		      dispatch({ type: 'FETCH_SETS_SUCCESS', response})
	    })
	 .catch(err => {})
	   		api.batchedFetch('/sets?allRepos=true&archived=false&sort=modified&parentArchived=false')
	    .then((response) => {
		      dispatch({ type: 'FETCH_SETS_SUCCESS', response})
	    })
	 .catch(err => {})
	}
}

export function fetchSetsNoMinTime() { //TODO clean this up
	const state = store.getState()
	if(state.setsRefreshedTimestamp){
		return (dispatch) => {
			api.batchedFetch(`/sets?archived=false&parentArchived=false&&sort=modified&modifiedAfter=${state.setsRefreshedTimestamp}`)
	   		 .then((response) => {
		    	  dispatch({ type: 'REFRESH_SETS_SUCCESS', response})
	    	})
	     .catch(err => {})
	     	api.batchedFetch(`/sets?allRepos=true&archived=false&parentArchived=false&&sort=modified&modifiedAfter=${state.setsRefreshedTimestamp}`)
	   		 .then((response) => {
		    	  dispatch({ type: 'REFRESH_SETS_SUCCESS', response})
	    	})
	     .catch(err => {})
	 }
	}else return (dispatch) => {
		api.batchedFetch('/sets?archived=false&sort=modified&parentArchived=false')
	    .then((response) => {
		      dispatch({ type: 'FETCH_SETS_SUCCESS', response})
	    })
	 .catch(err => {})
	 		api.batchedFetch('/sets?allRepos=true&archived=false&sort=modified&parentArchived=false')
	    .then((response) => {
		      dispatch({ type: 'FETCH_SETS_SUCCESS', response})
	    })
	 .catch(err => {})
	}
}

export function fetchSetsPreview() {
	return (dispatch) => api.fetch('/sets/preview')
		.then((response) => {
			dispatch({ type: 'FETCH_SETS_PREVIEW_SUCCESS', response})
		})
		.catch(err => {})
}

export function fetchSetsForRepo(repoId) {
	return (dispatch) => api.batchedFetch(`/sets?archived=false&sort=modified&parentArchived=false&repo=${repoId}`)
		.then((response) => {
			dispatch({ type: 'FETCH_REPO_SETS_SUCCESS', response})
		})
		.catch(err => {})
}

export function refetchSets() { //for after connecting to ISBE when modified query wont work
	return (dispatch) =>{
	 api.batchedFetch('/sets?&archived=false&sort=modified&parentArchived=false')
	    .then((response) => {
		      dispatch({ type: 'FETCH_SETS_SUCCESS', response})
	    })
	 .catch(err => {})
	 	 api.batchedFetch('/sets?allRepos=true&archived=false&sort=modified&parentArchived=false')
	    .then((response) => {
		      dispatch({ type: 'FETCH_SETS_SUCCESS', response})
	    })
	 .catch(err => {})
	}
}

export function fetchArchivedSets() {
  	return (dispatch) => {
  		api.batchedFetch('/sets?archived=true&parentArchived=false&sort=modified')
	    .then((response) => {
		      dispatch({ type: 'FETCH_ARCHIVED_SETS_SUCCESS', response})
	    })
	     .catch(err => {})

	     api.batchedFetch('/sets?allRepos=true&archived=true&parentArchived=false&sort=modified')
	    .then((response) => {
		      dispatch({ type: 'FETCH_ARCHIVED_SETS_SUCCESS', response})
	    })
	     .catch(err => {})
	 }
}

export function fetchArchivedSetsForRepo(repoId) {
  	return (dispatch) => api.batchedFetch(`/sets?archived=true&parentArchived=false&sort=modified&repo=${repoId}`)
	    .then((response) => {
		      dispatch({ type: 'FETCH_ARCHIVED_SETS_SUCCESS', response})
	    })
	     .catch(err => {})
}

   
export function fetchASet(setId) {
	const state = store.getState()
	if(state.requestedSets[setId]){
		return({ type: 'WAIT_TO_REFRESH'})
	}else{
		return (dispatch) =>{
			dispatch({ type: 'FETCH_SET_REQUEST',setId})
			api.fetch(`/sets/${setId}`)
				.then((response) => {
					dispatch({ type: 'FETCH_A_SET_SUCCESS', response})
					return response
				})
				.catch(err => {})
		}
	}
}

export function createNewSet(data) {
	if(typeof data.folder !== 'string'){ //Prevent invalid folder value
		data.folder=null
	}
	data.lastEditedAt=data.clientModified
	return (dispatch) => api.post('/sets/',data)
		.then((set) => {
			dispatch({ type: 'CREATE_SET_SUCCESS', set ,syncAction:true})
			dispatch(updateRecentActivities(set.id,'set','create'))
			return set
		})
	}


export function deleteSet(setId) {
	return (dispatch) => api.delete(`/sets/${setId}`)
		.then((response) => {
			dispatch({ type: 'DELETE_SET_SUCCESS', setId,syncAction:true})
			dispatch(fetchSetPolls())
			dispatch(fetchRecentActivities())
			return response
		})
		.catch(err => {
			return 'failed'
		})
}


export function updateSetContent(data,setId) {//update set name or questions
	data.lastEditedAt = data.clientModified
  	return (dispatch) => api.put(`/sets/${setId}/content`,data)
	    .then((response) => {
		      dispatch({ type: 'UPDATE_SET_SUCCESS', response,syncAction:true})
		      if(!data.archived && setId){
		      	dispatch(updateRecentActivities(setId,'set','edit'))
		  		}
		      return response
	    })
	    .catch(err => {
        return 'failed'
		})
}


export function updateSetMeta(data,setId) { //update folder,repo or archived
	if(typeof data.folder !== 'string'){//Prevent invalid folder value
		data.folder=null
	}
  	return (dispatch) => api.put(`/sets/${setId}/meta`,data)
	    .then((response) => {
		      dispatch({ type: 'UPDATE_SET_SUCCESS', response,syncAction:true})
		      if(!data.archived){
		      	dispatch(updateRecentActivities(setId,'set','edit'))
		  		}
		      return response
	    })
	    .catch(err => {
         	 
		})
}




import React from 'react'
import { Link } from 'react-router-dom'
import {getLinkForRepo} from '../../utils/getLinkForRepo'
import Icon from '../misc/Icons'
import DiscoverRepoRatingStars from './DiscoverRepoRatingStars'
import {Image} from 'cloudinary-react'

class DiscoverMiniRepoLink extends React.Component{

	constructor(){
		super()
		this.onClickLink=this.onClickLink.bind(this)
	}


	onClickLink(){
		if(window.analytics){
			const {repo,rating,market}=this.props
			window.analytics.track('Click Discover Repo Link',{
				name:repo.name,
				rating:rating,
				author:repo.authorObject.username,
				collection:'latest',
				market:market
			})}
	}


	render(){
		const {repo,loadRepoContent}=this.props		

		return(
			<Link 
				onClick={this.onClickLink}
				to={getLinkForRepo(repo)}
				className='discover-miniRepoLink'
				onMouseEnter={()=>{loadRepoContent(repo.id)}}
			>				
				<div className='discover-miniRepoLink-left'>
					<div className='discover-miniRepoLink-left-repoImageContainer'>
						<div className='discover-miniRepoLink-left-repoImage-imgBorder'/>
						<Image
							key={repo.id}
							cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
							publicId={`${repo.image}/repo/${repo.id}`} 
							className='discover-miniRepoLink-left-repoImage'											
							width='450'
							height='450'
							crop='fill'
						/>
					</div>

				</div>
				<div className='discover-miniRepoLink-center'>
					<div className='discover-miniRepoLink-center-title'>
						{repo.name}
					</div>
					<div className='discover-miniRepoLink-center-creator'>						
						{repo.authorObject?repo.authorObject.username:repo.author.username}
					</div>
					<div className='discover-miniRepoLink-center-infoContainer'>
						{this.props.rating && 						
								<DiscoverRepoRatingStars rating={this.props.rating} showRatingLabel />											
						}			
						{this.props.timestamp && 						
							<span>{this.props.timestamp}</span>
						}			
						{this.props.playCount && !this.props.hidePlayCount &&						
							<span><Icon name='play'/> {this.props.playCount}</span>
						}			
					</div>
				</div>
				<div className='discover-miniRepoLink-right'>
					<Icon name='chevron-right' />
				</div>
				
			</Link>
		)
	}
}


export default DiscoverMiniRepoLink


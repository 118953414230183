import React from 'react'
import { Link } from 'react-router-dom'
import {getLinkForRepo} from '../../utils/getLinkForRepo'
import Icon from '../misc/Icons'
import DiscoverRepoRatingStars from './DiscoverRepoRatingStars'
import {Image} from 'cloudinary-react'

class DiscoverSeeAllRepoLink extends React.Component{

	constructor(){
		super()
		this.onClickLink=this.onClickLink.bind(this)
	}


	onClickLink(){
		if(window.analytics){
			const {repo,rating,market}=this.props
			window.analytics.track('Click Discover Repo Link',{
				name:repo.name,
				rating:rating,
				author:repo.authorObject.username,
				collection:'latest',
				market:market
			})}
	}


	render(){
		const {repo,loadRepoContent,seeAllPage}=this.props		

		const ratingCount = repo.reviewCount
		
		let ratingCountLabel=''
		if(ratingCount === 1){
			ratingCountLabel = '1 review'
		}
		if(ratingCount > 1){
			ratingCountLabel = `${ratingCount} reviews`
		}

		return(
			<Link 
				onClick={this.onClickLink}
				to={getLinkForRepo(repo)}
				className='discover-seeAllRepoLink'
				onMouseEnter={()=>{loadRepoContent(repo.id)}}
			>				
				<div className='discover-seeAllRepoLink-left'>
					<div className='discover-seeAllRepoLink-left-repoImageContainer'>
						<div className='discover-seeAllRepoLink-left-repoImage-imgBorder'/>
						<Image
							key={repo.id}
							cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
							publicId={`${repo.image}/repo/${repo.id}`} 
							className='discover-seeAllRepoLink-left-repoImage'											
							width='450'
							height='450'
							crop='fill'
						/>
					</div>

				</div>
				<div className='discover-seeAllRepoLink-center'>
					<div className='discover-seeAllRepoLink-center-title'>
						{repo.name}
					</div>
					<div className='discover-seeAllRepoLink-center-creator'>						
						{repo.authorObject?repo.authorObject.username:repo.author.username}
					</div>
					<div className='discover-seeAllRepoLink-center-infoContainer'>
						<div className='discover-seeAllRepoLink-center-info-primary'>
						{this.props.rating && seeAllPage === 'rated' &&
							<React.Fragment>
								<DiscoverRepoRatingStars rating={this.props.rating} showRatingLabel /><span className='discover-seeAllRepoLink-center-info--ratingCount'>({ratingCountLabel})</span>
							</React.Fragment>
						}			
						{seeAllPage === 'popular' &&
							<React.Fragment>
								<Icon name='play'/> {this.props.playCount} plays
							</React.Fragment>
						}			
						{seeAllPage === 'latest' &&
							<React.Fragment>
								<div>{this.props.timestamp}</div>
							</React.Fragment>
						}			
						</div>
						<div className='discover-seeAllRepoLink-center-info-secondary'>								
						{ seeAllPage !== 'popular' && seeAllPage !== 'latest' &&					
							<div><Icon name='play'/> {this.props.playCount} plays</div>
						}
						{seeAllPage !== 'rated' &&			
							<React.Fragment>
							{this.props.rating && 
								<div className='discover-seeAllRepoLink-center-info-secondary--rating'>
									<DiscoverRepoRatingStars rating={this.props.rating} showRatingLabel /><span className='discover-seeAllRepoLink-center-info--ratingCount'>({ratingCountLabel})</span>
								</div>
							}
							{!this.props.rating && 
								<div className='discover-seeAllRepoLink-center-info-secondary--rating'>
									No reviews yet
								</div>
							}
							</React.Fragment>
						}
						</div>
						<div className='discover-seeAllRepoLink-center-info-tertiary'>
						{this.props.timestamp && seeAllPage !== 'latest' &&		
							<div>{this.props.timestamp}</div>
						}
						{seeAllPage === 'latest' &&				
							<div><Icon name='play'/> {this.props.playCount} plays</div>
						}
						</div>
					</div>
				</div>
				<div className='discover-seeAllRepoLink-right'>
					<Icon name='chevron-right' />
				</div>
				
			</Link>
		)
	}
}


export default DiscoverSeeAllRepoLink


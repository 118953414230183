import React from 'react'
import ReactDOM from 'react-dom'
import ChoiceVideoReactComponent from './ChoiceVideoReactComponent' 
import {showEditVideoModal} from '../../utils/showEditVideoModal'
import {playMediaNode} from '../../../../prosemirror/utils/editorActions/playMediaNode'

function randId(){
	return '00000000'.replace(/0/g,function(){return (~~(Math.random()*16)).toString(16)})
}
 
 
class ChoiceVideoNodeView {

	constructor(node, view, getPos) {
		this.getNode=this.getNode.bind(this)
		this.showEditVideoModal=this.showEditVideoModal.bind(this)
		this.node = node
		this.getPos=getPos
		this.deleteVideo=this.deleteVideo.bind(this)
		this.playVideo=this.playVideo.bind(this)

		const {youtubeId,title,start,end,fullDuration,questionChoiceCount,isPlaying,thumbnail,isClipped,slideWidth} = node.attrs
		this.dom = document.createElement("div")
		this.dom.className="slide-choice-mediaContainer slide-choice-mediaContainer--video"
		this.id=randId()
		
		ReactDOM.render(
			<ChoiceVideoReactComponent 
				videoTitle={title}
				questionChoiceCount={questionChoiceCount}
				showEditVideoModal={this.showEditVideoModal}
				videoDuration={fullDuration} 
				start={start}
				end={end}
				nodeId={this.id} 
				key={this.id} 
				youtubeId={youtubeId} 
				isPlaying={isPlaying}
				playVideo={this.playVideo}
				thumbnail={thumbnail}
				isClipped={isClipped}
				deleteVideo={this.deleteVideo}
				slideWidth={slideWidth} />, this.dom)
	} 

	playVideo(){
		const position=this.getPos()
		playMediaNode(position)
	}

	showEditVideoModal(){
		const node=this.node
		let video={
			type:'video',
			youtubeId:node.attrs.youtubeId,
			fullDuration:node.attrs.fullDuration,
			title:node.attrs.title,
			channelTitle:node.attrs.channelTitle,
			start:node.attrs.start,
			end:node.attrs.end
		}
		const isInsertFlow = false
		const isQuestion = false
		const autoplay = true
		const searchState = null
		showEditVideoModal(isInsertFlow,searchState, autoplay,video,null,isQuestion,null,null,this.node,this.getPos())
	}

 update(node) {
	if (node.type !== this.node.type) return false
		const {youtubeId,start,end,questionChoiceCount,isPlaying,fullDuration,title,thumbnail,isClipped,slideWidth} = node.attrs
		const oldStart=this.node.attrs.start
		const oldEnd=this.node.attrs.end
		const oldQuestionChoiceCount=this.node.attrs.questionChoiceCount
		const oldIsPlaying=this.node.attrs.isPlaying
		const oldSlideWidth=this.node.attrs.slideWidth
	 if(
		start !==oldStart || 
		end !==oldEnd || 
		questionChoiceCount !==oldQuestionChoiceCount || 
		isPlaying !== oldIsPlaying ||
		slideWidth !== oldSlideWidth
		){
		ReactDOM.render(
			<ChoiceVideoReactComponent 
				videoTitle={title}
				questionChoiceCount={questionChoiceCount}
				showEditVideoModal={this.showEditVideoModal}
				videoDuration={fullDuration} 
				start={start}
				end={end}
				nodeId={this.id} 
				key={this.id} 
				youtubeId={youtubeId} 
				isPlaying={isPlaying}
				playVideo={this.playVideo}
				thumbnail={thumbnail}
				isClipped={isClipped}
				deleteVideo={this.deleteVideo}
				slideWidth={slideWidth}
				 />, this.dom)
		}
		this.node=node
		return true
	}
				
			
	deleteVideo(){
		const node=this.getNode()
		const position=this.getPos()
		window.view.dispatch(window.view.state.tr.delete(position,position+node.nodeSize))
	}
 

	getNode(){
		return this.node
	}

	 destroy() {
		 ReactDOM.unmountComponentAtNode(this.dom)
	}


}

export default ChoiceVideoNodeView
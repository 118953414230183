import React from 'react'
import Icon from '../../../../misc/Icons'


class EditorSoundLibraryResultSortHeaders extends React.Component{  

	render(){  
		const {resultsListCategoryType, sortOrder, sortType} = this.props
		
		return (          
			<div className={'editor-insertSoundModal--soundLibrary-main-resultsSortHeaders ' + (this.props.hasSearchTerm ? 'editor-insertSoundModal--soundLibrary-main-resultsSortHeaders--hide' : ' editor-insertSoundModal--soundLibrary-main-resultsSortHeaders--show')}>
				<div className='editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-playPauseBtnContainer'/>                
					<button onClick={()=>{this.props.setSortType('name')}} className='editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header--name'>
						<div className='editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header-label'>
							Title 
						</div>
						{sortType === 'name' &&
							<div className='editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header-iconContainer'>
								{sortOrder === 'ascending' &&
									<Icon name='chevron-left' />
								}
								{sortOrder === 'descending' &&
									<Icon name='chevron-right' />
								}
							</div>
						}
					</button>

					<button onClick={()=>{this.props.setSortType('duration')}} className='editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header--duration'>          
						<div className='editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header-label'>
							Duration
						</div>
						{sortType === 'duration' &&
							<div className='editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header-iconContainer'>
								{sortOrder === 'ascending' &&
										<Icon name='chevron-left' />
								}
								{sortOrder === 'descending' &&
									<Icon name='chevron-right' />
								}
							</div>
						}
					</button>

					{(resultsListCategoryType === 'public' || resultsListCategoryType === 'mixed') &&
						<button className='editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header--plays'>
							<div className='editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header-label'>
								Plays
							</div>
							{sortType === 'plays' &&
								<div className='editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header-iconContainer'>
										{sortOrder === 'ascending' &&
											<Icon name='chevron-left' />
										}
										{sortOrder === 'descending' &&
											<Icon name='chevron-right' />
										}
								</div>
							}
						</button>
					}
				<div className='editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-insertBtnContainer'/>
			</div>
		)
	}
}

export default EditorSoundLibraryResultSortHeaders

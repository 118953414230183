import api from '../api'

export function fetchMobileInfo() {
	return (dispatch) => api.fetch('/mobile-info')
		.then((response) => {
			dispatch({ type: 'FETCH_MOBILE_INFO_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}





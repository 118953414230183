import {paginateDataForPreview} from './paginateDataForPreview'

export function calculateWholeClassPageCount(reportPollsByStudent,ghostHeights,columnsPerPage,includeArchivedStudents,includeGuests) {
	let totalPageCount=0	

	Object.keys(reportPollsByStudent).map((key)=>{
		const studentWithPolls=reportPollsByStudent[key]
		let includeInCount=true
		if(!includeArchivedStudents && studentWithPolls.isArchived){
			includeInCount=false
		}else if(!includeGuests && studentWithPolls.isGuest){
			includeInCount=true
		}
		if(includeInCount){
			const reportPolls=studentWithPolls.polls
			const paginatedPolls=paginateDataForPreview(reportPolls,ghostHeights,columnsPerPage)
			totalPageCount+=Object.keys(paginatedPolls).length
		}
		return null
	})

return totalPageCount
}


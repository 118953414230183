import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import findIndex from 'lodash/findIndex'

const lettersArray=['A','B','C','D']

//calculateResponsesByStudents- see historySetPolls.js
function calculateResponsesByStudents(poll){
	let isSurvey=(poll.correctPercent===-1)
	let correctLetters=[]
	let validResponseLetters=[]
	poll.snapshot.choices.map((choice,index) => {
		const validResponseLetter=lettersArray[index]
		validResponseLetters.push(validResponseLetter)
		if(choice.correct===true){
			const correctLetter=lettersArray[index]
			correctLetters.push(correctLetter)
		}
		return null
	})

	let responsesByStudent={}
	let responsesByGuest={}
	if(poll.responsesByCard){ //clear out pollPolls with no responses
		Object.keys(poll.responsesByCard).map((cardNumber)=>{
			const response=poll.responsesByCard[cardNumber]
			const byStudentResponseObject={
				card:cardNumber,
				answer:response.answer,
				captured:response.captured,
				isCorrect:includes(correctLetters,response.answer),
				isValid:includes(validResponseLetters,response.answer),
				isSurvey:isSurvey
			}
			if(response.student){
				responsesByStudent[`${response.student}`]=byStudentResponseObject
			}else{
				responsesByGuest[`Guest ${cardNumber}`]=byStudentResponseObject
			}
			return null
		})
	}
	const modifiedPoll={...poll, responsesByStudent:responsesByStudent,responsesByGuest:responsesByGuest}
	return modifiedPoll

}	


export default function (state=null, action) {
	switch (action.type) {
		
		case 'FETCH_HISTORY_QUESTION_POLLS_SUCCESS':		
			let historyQuestionPolls=[]	
			action.response.map((poll) => {
				const modifiedPoll=calculateResponsesByStudents(poll)
				historyQuestionPolls.push(modifiedPoll)
				return null
			})	
			return historyQuestionPolls
		
		case 'FETCH_SINGLE_QUESTION_POLL_SUCCESS':
			if(action.response.hasResponses===true){
				if(state){
					const questionPollIndex= findIndex(state,poll => {
						return (poll && poll.id === action.response.id)
					})	
					const modifiedQuestionPoll=calculateResponsesByStudents(action.response)
					if(questionPollIndex !==-1){
						return [
							...state.slice(0,questionPollIndex),
							modifiedQuestionPoll,
							...state.slice(questionPollIndex + 1)
						]
					}else{
						return [
							...state,
							modifiedQuestionPoll
						]
					}
				}
				else{
					const modifiedQuestionPoll=calculateResponsesByStudents(action.response)
					let newState=[]
					return [
						...newState,
						modifiedQuestionPoll
					]}}
				else return state

		case 'REFRESH_QUESTION_POLLS_SUCCESS':
			const filtered=filter(action.response,{'hasResponses':true})		
			let polls=state.slice()
			forEach(filtered, function(modifiedPoll) { 
				const modifiedPollWithCalculatedFields=calculateResponsesByStudents(modifiedPoll)
				let indexOfPoll = findIndex(polls,poll => {
					return poll.id === modifiedPoll.id
				})

				if(indexOfPoll !== -1){ 
					polls =[
						...polls.slice(0,indexOfPoll),
						...polls.slice(indexOfPoll + 1)
					]
				}
				polls=[modifiedPollWithCalculatedFields,...polls]				
			})
			return polls

		case 'UPDATED_QUESTION_POLL_WITH_PUSHER':
			if(action.pollObject.hasResponses===false){
				const updatedPollIndex= findIndex(state,questionPoll => {
					return questionPoll.id === action.pollObject.id
				})		
				if(updatedPollIndex !==-1){
					return [
						...state.slice(0,updatedPollIndex),
						...state.slice(updatedPollIndex + 1)
					]
				}else return state		
			}else{
				const updatedPollIndex= findIndex(state,questionPoll => {
					return questionPoll.id === action.pollObject.id
				})
				const modifiedUpdatedPoll=calculateResponsesByStudents(action.pollObject)
				if(updatedPollIndex !==-1){
					return [
					...state.slice(0,updatedPollIndex),
					modifiedUpdatedPoll,
					...state.slice(updatedPollIndex + 1)
					]
				}	else return [...state,modifiedUpdatedPoll]		
			}

		case 'ARCHIVE_SECTION_SUCCESS':
			const archivedSectionId=action.response.id
			if(state){
				let filteredPolls=state.slice()
				filteredPolls=filter(filteredPolls, function(poll) {
		  		  return poll.section !==archivedSectionId
				})
				return filteredPolls
			}else return state


		case 'UPDATE_QUESTION_POLL_REQUEST':
			if(action.data.hasResponses===true){
				const updatedPollId = action.pollId
				const indexOfUpdateRequestQuestionPoll = findIndex(state,questionPoll => {
					return questionPoll.id === updatedPollId
				})
				if(indexOfUpdateRequestQuestionPoll!==-1){
					
					return [
						...state.slice(0,indexOfUpdateRequestQuestionPoll),
						action.data,
						...state.slice(indexOfUpdateRequestQuestionPoll + 1)
					]
				}
				else return state}
			else return state

		case 'DELETE_QUESTION_POLL_SUCCESS':
			const pollId = action.pollId
			const indexOfPollToDelete = findIndex(state,poll => {
				return poll.id === pollId
			})
			return [
				...state.slice(0,indexOfPollToDelete),
				...state.slice(indexOfPollToDelete + 1)
			]

		case 'LOGOUT':
			return null
				
		default:
			return state

	}
}
const QuestionImageNodeSpec = {
  inline: false,
  attrs: {
    fileId:{default:''},//cloudinaryId
    addedAt:{default:''},
    title:{default:''},
    attribution:{default:''},
    deliveryUrl:{default:''},
    deliveryDimensions:{default:null},
    originalDimensions:{default:null},
    imageFill:{default:false},
    crop:{default:null},
    rotate:{default:{
      angle:0,
      flipH:false,
      flipV:false
    }},
    markup:{default:{covers:[],markers:[]}},
    colorAdjust:{
      default:{
        brightness:0,
        saturation:0,
        contrast:0
      }
    },
    nodeId:{default:''}, //for replacing placeholder with cloudinary image
    placeholderImage:{default:''},
    placeholderDimensions:{default:{h:'1',w:'1'}},
    questionMediaDynamicHeight:{default: null},
    template: {default: null},
    slideWidth:{default:''}
  },
  selectable:false,
  draggable:false,
  group: 'block',
  parseDOM: [{tag: 'questionImage'}],
 toDOM(node) {
    return ['questionImage']},
}
export default QuestionImageNodeSpec
 
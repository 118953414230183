export default function (state = null, action) {
	switch (action.type) {
		case 'FETCH_GEO_INFO_SUCCESS':
			return action.response
		case 'LOGOUT':
			return null
		default:
			return state
	}
}

import React from 'react' 
import { withRouter,Link} from 'react-router-dom'
import throttle from 'lodash/throttle'
import moment from 'moment'
import Icon from '../misc/Icons'
import Button from '../uiKit/Button'
import Dropdown from  '../uiKit/Dropdown'
import DropdownDivider from '../uiKit/DropdownDivider'
import DropdownItem from '../uiKit/DropdownItem'
import QuestionSlideCarousel from '../uiKit/QuestionSlideCarousel'
import {openItemInEditor} from '../../utils/openItemInEditor'
import Measure from 'react-measure'
import AddToQueueDropdown from '../uiKit/AddToQueueDropdown'
import PollsForItemList from '../uiKit/PollsForItemList'
import {getRepoforId} from '../../utils/getRepoforId'
import {getUserNameForId} from '../../utils/getUserNameForId'
import {isItemLocked} from '../../utils/isItemLocked'
import {getLinkForRepo} from '../../utils/getLinkForRepo'

  
const LOCK_THROTTLE_TIME=1000*10   

class PreviewPanel extends React.Component{

	constructor(props){
		super(props)
 		this.onSetNameChange=this.onSetNameChange.bind(this)
 		this.onBlur=this.onBlur.bind(this)
 		this.lockItem=this.lockItem.bind(this)
 		this.lockItem = throttle(this.lockItem,LOCK_THROTTLE_TIME,{'edge': 'both' })
 		let activeItemName=null
 		if(props.activeItem){
 			activeItemName=props.activeItem.name
 		}
 		this.state=({
 			activeItemName:activeItemName,//item shown in side panel
 			previewPanelContainerWidth:null,//measure width of panel spacer div to make slide width responsive
 		})
 		this.contextMenuTrigger=null
 		this.folderContextMenuTrigger=null
 	}

 	UNSAFE_componentWillReceiveProps(nextProps) {
 		if(nextProps.activeItem && ((nextProps.activeItem.id !== this.props.activeItem.id) || (nextProps.activeItem.clientModified !==this.props.activeItem.clientModified))){
 			this.setState({activeItemName:nextProps.activeItem.name})
 		}	
	}
 
	lockItem(){
		if(this.props.activeItem && this.props.activeItem.repo){
			this.setState({userLockedSet:true})
			this.props.updateSetLock(this.props.activeItem.id)
		}
	}

	onSetNameChange(e){
		this.setState({activeItemName:e.target.value})
		this.lockItem()
	}

	onBlur(){
		this.props.editSetName(this.props.activeItem,this.state.activeItemName).then(()=>{
			if(this.state.userLockedSet){
				this.props.deleteLockForSet(this.props.activeItem.id)
				this.lockItem.cancel()
			}
		})
	}

	sizeChanged(dimensions){
		this.setState({width:dimensions.dimensions.width})
	}

	render(){
		const {isTrash,restoreItem,activeItem,isLibrary,isRecent,isRepoTrash}=this.props
		const {previewPanelContainerWidth}=this.state
		let isFolder=false
		let isQuestion=false
		let isSet=false
		if(activeItem.questions){
		 isSet=true
		}else if(activeItem.ancestors){
			isFolder=true
		}else{
			isQuestion=true
		}
		let isViewOnly=activeItem.readOnly

		 

		// this stuff is previewpanels displayed when selecting items inside of repos
		const containingRepoID = activeItem.repo 
		let containingRepoName = null
		let inSharedRepo = false
		let itemHasLock = false
		let isPublicConsumer = false
		let isPublicAuthor = false
		let containingRepo

		if(containingRepoID){
			containingRepo = getRepoforId(containingRepoID)
			if(containingRepo){
				itemHasLock=isItemLocked(activeItem.id,containingRepo.id) 
				inSharedRepo = containingRepo.shared
				containingRepoName=containingRepo.name
				if(containingRepo.role === 'viewer'||containingRepo.role==='consumer'){
					isViewOnly = true 
				}
				if(containingRepo.role==='consumer'){
					isPublicConsumer = true
				}
				if(containingRepo.role==='author'){
					isPublicAuthor = true
				}
			}

		}

		const lastEditedByUserName = getUserNameForId(activeItem.lastEditedBy,containingRepoID)// in repo to get user last edited
		
		
		return(
			<div className={(this.props.isLibrary ? 'previewPanelcontainer--library' : 'previewPanelcontainer--recent')}>				
				<Measure onMeasure={(dimensions) => {this.setState({previewPanelContainerWidth:dimensions.width})}}>
					<div className='previewPanelcontainer-innerSpacer'/>
				</Measure>

				{previewPanelContainerWidth && Object.keys(activeItem).length !== 0 && 
					<div className={'previewPanel ' + (isSet ? 'previewPanel--set' : 'previewPanel--question') + (isFolder ? ' previewPanel--folder' : '') }>	

						<div className='previewPanel-header'>							

							{isQuestion === true &&
								<div className='previewPanel-questionPreview'>									
									<QuestionSlideCarousel isTrash={isTrash} key={activeItem.id} question={activeItem} slideWidth={previewPanelContainerWidth} play={() =>{this.props.playItem(activeItem)}}  />									
								</div>
							}
 
							{isSet === true &&
							<div className={'previewPanel-header-title' + ((inSharedRepo || isPublicAuthor || isPublicConsumer) && isRecent ? ' previewPanel-header-title--showSharedIcon' : '')}>
								<textarea 
									disabled={isViewOnly || isTrash ||itemHasLock}
									className='previewPanel-header-title-input'
									id='previewPanelSetNameInput'
									value={this.state.activeItemName}
									onChange={this.onSetNameChange}
									onBlur={this.onBlur} 
									onKeyDown={(e)=>{if (e.keyCode === 13) { 
										e.preventDefault()
										document.getElementById('previewPanelSetNameInput').blur()
									}}}
									
									maxLength={60}
								/>
								<div className="previewPanel-header-title-input-hint">
									<span className='text-hint--enter'>Press <strong>ENTER</strong> to update</span>
								</div>

								<div className='previewPanel-header-title-spacer'>
									{this.state.activeItemName} 
									{inSharedRepo && isRecent && <Icon name='shared' /> }
									{!inSharedRepo && isViewOnly && !isPublicConsumer && <Icon name='lock' /> }
									{isPublicConsumer && isRecent && <Icon name='globe-shared' /> }
									{isPublicAuthor && isRecent && <Icon name='globe-shared-author' /> }
								</div>

							</div>


							}
 
							{isFolder === true && 
							<div className='previewPanel-header-title'>
								
								<Icon name='folder' />

								<textarea 
									disabled={isViewOnly || isTrash}
									className='previewPanel-header-title-input'
									id='previewPanelFolderNameInput'
									value={this.state.activeItemName}
									onChange={(e)=>{this.setState({activeItemName:e.target.value})}}
									onBlur={()=>{this.props.editFolderName(activeItem,this.state.activeItemName)}} 
									onKeyDown={(e)=>{if (e.keyCode === 13) { 
										e.preventDefault()
										document.getElementById('previewPanelFolderNameInput').blur()
									}}}
									maxLength={100}
								/>
								<div className="previewPanel-header-title-input-hint">
									<span className='text-hint--enter'>Press <strong>ENTER</strong> to update</span>
								</div>
								<div className='previewPanel-header-title-spacer'>
									{this.state.activeItemName}
								</div>
							</div>
							}
							
							{isRecent && containingRepoName &&							
								<Link to={getLinkForRepo(containingRepo)} className='previewPanel-header-containingRepoLink'>
									{containingRepoName}
								</Link>					
							}							

							{!containingRepoName && !isViewOnly && !isTrash && 
								<div className='previewPanel-header-movetoRepoBtnContainer ' onClick={()=>{this.props.showRepoSelectionModal(activeItem)}}>
									<div className='previewPanel-header-movetoRepoBtn'>
										Move to Pack <Icon name='open-left-arrow' />
									</div>
								</div>
							}
						

							{!isTrash && !isFolder &&
								<span className='previewPanel-header-modifiedLabel'>
									Modified
									{inSharedRepo && lastEditedByUserName &&
										<React.Fragment>
											&nbsp;by
										</React.Fragment>
									}
								</span>
							}

							{!isFolder &&
								<span className='previewPanel-header-modifiedDatetime'>
									{inSharedRepo && 
										<React.Fragment>
											{lastEditedByUserName}&nbsp;
										</React.Fragment>
									}
									{!isPublicConsumer &&
										<React.Fragment>
											{moment(activeItem.clientModified).format('D/M/YYYY h:mm A')}
										</React.Fragment>
									}
									{isPublicConsumer &&
										<React.Fragment>
											{moment(activeItem.clientModified).format('D/M/YYYY')}
										</React.Fragment>
									}
								</span>
							}							

													
						</div> 

						{!isFolder && !isTrash &&
							<div className={'previewPanel-buttonRow' + (isViewOnly ? ' previewPanel-buttonRow--viewOnly' : '')}>
								<Button label='Play Now' color='blue' iconName='play' onClickFunction={() =>{this.props.playItem(activeItem)}} overflowEllipses />
								{!isViewOnly && <Button name='Edit' label='Edit'  onClickFunction={() =>{openItemInEditor(activeItem.id,isSet)}} /> }
								{!isViewOnly &&								
									<Dropdown>
										{isSet === true &&
											<React.Fragment>
												<DropdownItem label='Rename Set...' onSelect={()=>{this.props.showRenameSetModal(activeItem)}} />
												<DropdownDivider />									
											</React.Fragment>
										}
										{isLibrary &&
											<DropdownItem  label='Move to Folder...' onSelect={()=>{this.props.showMoveToFolderModal({[activeItem.id]:activeItem})}} />
										}

										<DropdownItem label='Move to Pack...' onSelect={()=>{this.props.showRepoSelectionModal(activeItem)}}/>
										
										<DropdownItem label='Detail View' onSelect={()=>{this.props.navigateToItemDetail(activeItem)}} />
										{isLibrary &&
											<DropdownItem label='Make a Copy' onSelect={()=>{this.props.createCopy(activeItem)}} />
										}
										{!isSet &&
											<DropdownItem  label='Copy to Clipboard' onSelect={()=>{this.props.copyQuestionToClipboard(activeItem)}} />
										}
										<DropdownDivider />										
										<DropdownItem warning label='Send to Trash' onSelect={()=>{this.props.archiveItem(activeItem)}} />										
									</Dropdown>
								}
								
								{isViewOnly &&								
									<Button name="detail" size="small" iconName="chevron-right" onClickFunction={()=>{this.props.navigateToItemDetail(activeItem)}}/>
								}
								
							</div>
						}

						{!isFolder && isTrash &&
						<div className='previewPanel-buttonRow'>
							<Button label='Restore' color='blue' onClickFunction={() =>{restoreItem(activeItem)}} />
							<Button label={isRepoTrash? 'Permanently Delete':'Delete'}  onClickFunction={() =>{isSet? this.props.deleteSet(activeItem):this.props.deleteQuestion(activeItem)}} />				
						</div>
						}

						{isFolder && !isTrash &&
						<div className='previewPanel-buttonRow'>
							<Button label='Open Folder' onClickFunction={() =>{this.props.navigateToFolder(activeItem.id)}} />
							{!isViewOnly &&
								<Dropdown>									
									<DropdownItem  label='New Set in Folder...' onSelect={()=>{this.props.handleNewSet(activeItem.id)}} />
									<DropdownDivider />		
									<DropdownItem  label='Rename Folder' onSelect={()=>{this.props.showRenameFolderModal(activeItem)}} />
									<DropdownItem  label='Move to Folder...' onSelect={()=>{this.props.showMoveToFolderModal({[activeItem.id]:activeItem})}} />
									<DropdownItem  label='Move to Pack...' onSelect={()=>{this.props.showRepoSelectionModal(activeItem)}} />
									<DropdownDivider />									
									<DropdownItem warning label='Send to Trash' onSelect={()=>{this.props.archiveFolder(activeItem)}} />									
								</Dropdown>
							}
						</div>
						}
						{isFolder && isTrash &&
						<div className='previewPanel-buttonRow'>
							<Button label='Restore' color='blue' size='small' onClickFunction={() =>{this.props.restoreFolder(activeItem)}} />
							<Button label={isRepoTrash ? 'Permanently Delete':'Delete'}   size='small' onClickFunction={() =>{this.props.deleteFolder(activeItem)}} />				
						</div>
						}

						{isSet === true &&
							<QuestionSlideCarousel isTrash={isTrash} key={activeItem.id} set={activeItem} slideWidth={previewPanelContainerWidth} play={() =>{this.props.playItem(activeItem)}} showSetNavigator />
						}

						
						{!isFolder && !isTrash &&					
							<div className='previewPanel-pollsForItemListContainer'>
								<PollsForItemList item={activeItem}/>		
							</div>
						} 						

						{!isFolder && !isTrash &&					
							<div className='previewPanel-addToQueueMenuContainer'>
								<div className='previewPanel-addToQueueMenu'>
									<AddToQueueDropdown dropdownButton={<Button iconName='plus' label='Add to Queue' />} inverted item={activeItem}/>
								</div>
							</div>
						} 						
									
					</div>
				}
			</div>
		)
	}

}

      
export default withRouter(PreviewPanel)

import React from 'react'
import ModalWrapper from '../ModalWrapper'
import AddPasswordContainer from '../../../containers/modals/account/AddPasswordContainer'

const AddPasswordModal = (props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <div className='addPassword-modal'>
    <ModalWrapper
      {...props}
      title='Add Password Sign-in'
      modalStyle='dark'
      closeBtn={false}
    >
      <AddPasswordContainer {...props} />
    </ModalWrapper>
  </div>
  /* eslint-enable react/jsx-props-no-spreading */
)

export default AddPasswordModal

import React from 'react'
import { withRouter,Link} from 'react-router-dom'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import ScorePill from '../../../uiKit/ScorePill'
import Button from '../../../uiKit/Button'
import Icon from '../../../misc/Icons'
import {Motion, spring} from 'react-motion'
import StaticQuestionSlide from '../../../slides/StaticQuestionSlide'
import {buildQuestionsAndPollsList} from '../../../../utils/buildQuestionsAndPollsList'
import {formatDate} from '../../../../utils/formatDate'
import {getLinkForRepo} from '../../../../utils/getLinkForRepo'
import {processQuestionResponses} from '../../../../utils/processQuestionResponses'
import {calculateStudentTotals} from '../../../../utils/calculateStudentTotal'
import {openItemInEditor} from '../../../../utils/openItemInEditor'
import {resumePlayingSetPoll} from '../../../../utils/resumePlayingSetPoll'
import {getNextQuestionAndPoll} from '../../../../utils/resumePlayingSetPoll'
import {getRepoforId} from '../../../../utils/getRepoforId'


const springConfig = {stiffness: 170, damping: 26}

class SectionHomeResumableSetPoll extends React.Component{

	constructor(props){
		super(props)
		let indexOfNext=0

		const nextQuestionAndPoll=getNextQuestionAndPoll(props.resumableSetPoll,props.setObject)
		if(nextQuestionAndPoll){
			indexOfNext=nextQuestionAndPoll.indexOfNext
		}

		this.state={
     	  currentCarouselIndex:indexOfNext,
     	  showStudentScores:false,
		}
	}

	

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.resumableSetPoll.id !==this.props.resumableSetPoll.id){
			this.setState({
				currentCarouselIndex:0,
     	 		showStudentScores:false,
     	  		studentSortOrder:'name'
     	})
		}


	}



	renderStudentScorePill(student) {
		let score
		if(student.totalAnswers !==0){
			 score = (student.totalCorrect/student.totalAnswers)*100
		}
		else{
			score ='-'
		}
		return( 
			<div className='sectionHome-inProgress-studentScores-studentCard' key={student.id} > 	      
				<div className='sectionHome-inProgress-studentScores-studentCard-name'>{student.displayName}</div>	      	
				<ScorePill score={score} size='small' />	      
			</div>
		)
	}

 
	render(){
	
		const {resumableSetPoll,section,setObject} = this.props
		const {currentCarouselIndex,showStudentScores} = this.state

		const questionsAndPollsList=buildQuestionsAndPollsList(resumableSetPoll,setObject)
		
		const hasResponses=resumableSetPoll.hasResponses		
		const score = resumableSetPoll.correctPercent
		const isAllSurvey = resumableSetPoll.hasResponses && resumableSetPoll.correctPercent===-1
		const formattedPlayedTimestamp = formatDate(resumableSetPoll.snapshotRefreshed)
		const carouselItems = questionsAndPollsList||[]

 		const maxCarouselIndex = Math.max(carouselItems.length - 2,0)
 		// take off one for indexed to zero, take off another as space for 2 cards horizontally


 	
 		const nextQuestionAndPoll=getNextQuestionAndPoll(resumableSetPoll,setObject)
 		
 	


 		const carouselSlideWidth = 328
 		const carouselSlideHeight = carouselSlideWidth / 1.6 // aspect ratio
 		const carouselSlideMargin = 20

		const carouselItemWidth = carouselSlideWidth + carouselSlideMargin
		const carouselItemHeight = carouselSlideHeight + (carouselSlideMargin / 2)
		const carouselInitialOffset = 90

		let carouselXOffset
		carouselXOffset = (currentCarouselIndex * -1 * carouselItemWidth) + carouselInitialOffset

		const carouselStyle = {
		   xTranslate: spring(carouselXOffset, springConfig),		       			       	
		 }

		const carouselWidth = carouselItems.length * carouselItemWidth

		const students=calculateStudentTotals(filter(section.students,{'archived':false}),resumableSetPoll)

	 	const sortedStudents=sortBy(filter(students,{'archived':false}), [function(o) { 
    		return o.displayName.toString().toLowerCase()
		}]) 


	 	let containingRepo = null
		let containingRepoName = null
		let containingRepoID = null
		let isViewOnlyRepo = false
		let inSharedRepo = false
		
		if(setObject){
			containingRepoID = setObject.repo
			if(containingRepoID){
				containingRepo = getRepoforId(setObject.repo)
				if(containingRepo){
					inSharedRepo = containingRepo.shared
					containingRepoName=containingRepo.name
					if(containingRepo.role ==='viewer' || containingRepo.role ==='consumer'){
						isViewOnlyRepo = true
					}
				}
			}
		}



		return(
			<div className='sectionHome-inProgress'>									
				
				<div className='sectionHome-inProgress-label'>
					In Progress
				</div>
			
				<div className='sectionHome-inProgress-topSection'>
					<div className='sectionHome-inProgress-titleBar'>
						{resumableSetPoll.snapshot &&
						<div className='sectionHome-inProgress-titleBar-title'>
							{resumableSetPoll.snapshot.name} {inSharedRepo && <Icon name='shared'/>}
						</div>
						}


						{hasResponses && !isAllSurvey && (score || score===0) &&
							<div className='sectionHome-inProgress-titleBar-scorePill'>
								<ScorePill typeDot score={score} />
							</div>
						}

						{!hasResponses &&
							<div className="sectionHome-inProgress-titleBar-removeBtn" onClick={this.props.markDone} >
								<Icon name="close-small"/>
							</div>
						}
					</div>

					<div className='sectionHome-inProgress-subtitle'>
							{containingRepoName &&
								<Link to={getLinkForRepo(containingRepo)} className='sectionHome-inProgress-subtitle-containingRepo'>
									{containingRepoName}
								</Link>
							}
						<div className='sectionHome-inProgress-subtitle-timestamp'>
							Played {formattedPlayedTimestamp}
						</div>
					</div>
					
				</div>
				

				<div className='sectionHome-inProgress-actionRow'>
					<Button label='Play Now' overflowEllipses onClickFunction={()=>{resumePlayingSetPoll(resumableSetPoll)}} />
					{hasResponses &&
						<Button label='Mark Done' overflowEllipses onClickFunction={this.props.markDone} />
					}
					
				</div>
				
				<div style={{height: `${carouselItemHeight}px`}} className='sectionHome-inProgress-carouselContainer'>
					<div className='sectionHome-inProgress-carouselControls'>						 

						{currentCarouselIndex > 0 &&
							<div className='sectionHome-inProgress-carouselControls-previous' 
								onClick={()=>(this.setState({currentCarouselIndex: (currentCarouselIndex - 1)}))} 
							/>
						}

						{currentCarouselIndex < maxCarouselIndex &&
							<div style={{left: `${carouselItemWidth + carouselInitialOffset - (carouselSlideMargin / 2)}px`}} className='sectionHome-inProgress-carouselControls-next' 
								onClick={()=>(this.setState({currentCarouselIndex: (currentCarouselIndex + 1)}))}
							/>
						}						 

					</div>

					
					
					

					<Motion style={carouselStyle}>
						{({xTranslate}) =>								
							<div style={{transform: `translateX(${xTranslate}px)`, width:`${carouselWidth}px`}} className='sectionHome-inProgress-carousel'>
					
								{carouselItems.map((questionAndPoll,index)=>{		

									let isReviewScreen=false
									let score
									let processedQuestionResponses
									let isNext=false

									if(questionAndPoll.poll){
										isReviewScreen=true
										score=questionAndPoll.poll.correctPercent 
										processedQuestionResponses=processQuestionResponses(questionAndPoll.poll,this.props.section)
										if(nextQuestionAndPoll.poll && questionAndPoll.poll.id===nextQuestionAndPoll.poll.id){
											isNext=true
										}
									}else{
										if(nextQuestionAndPoll.questionSnapshot && questionAndPoll.questionSnapshot.questionId===nextQuestionAndPoll.questionSnapshot.questionId){
											isNext=true
										}
									}


									return(
										<div key={index} style={{width: `${carouselSlideWidth}px`, height: `${carouselSlideHeight}px`, marginRight:`${carouselSlideMargin}px`}} className='sectionHome-inProgress-carousel-carouselItem'>

											{isReviewScreen &&
												<ScorePill score={score}/>											
											}

											{isNext &&
												<div className='sectionHome-inProgress-carousel-carouselItem-nextMarker'>
													Next
												</div>	
											}

											{isReviewScreen &&
												<StaticQuestionSlide 
													question={questionAndPoll.questionSnapshot} 
												 	displayWidth={328}												 	
												 	processedResponses={processedQuestionResponses}
												 	totalResponses={questionAndPoll.poll.unassignedResponseCount + questionAndPoll.poll.assignedResponseCount}
													reportView													
												/>
											}  

											{!isReviewScreen &&
												<StaticQuestionSlide 
													question={questionAndPoll.questionSnapshot} 												 	
													displayWidth={328} 
													previewCard={true} 
												/>
											}
											
										</div>
									)})}
							</div>
						}
					</Motion>
					
				</div>

				<div className='sectionHome-inProgress-bottomSection'>
					{hasResponses && !isAllSurvey &&
						<div className={'sectionHome-inProgress-bottomSection-action sectionHome-inProgress-bottomSection-action--students' + (showStudentScores ? ' sectionHome-inProgress-bottomSection-action--students--hide' : ' sectionHome-inProgress-bottomSection-action--students--show' )} onClick={()=>{this.setState({showStudentScores:!showStudentScores})}}>
							{showStudentScores ? 'Hide':'Show'} Student Scores
						</div>
					}
					{setObject &&
						<div className='sectionHome-inProgress-bottomSection-action sectionHome-inProgress-bottomSection-action--editSet' onClick={()=>{openItemInEditor(resumableSetPoll.setObject,true)}}> 
							{!isViewOnlyRepo ? 'Edit Set' : 'View Set'}
						</div>
					}

					{hasResponses &&
						<div className='sectionHome-inProgress-bottomSection-action' onClick={()=>{this.props.history.push(`/setreport/${resumableSetPoll.id}`)}} >
							Go to Set Report
						</div>
					}
								
				</div>


				{showStudentScores &&
					<div className='sectionHome-inProgress-studentScoreBlock'>

						<div className='sectionHome-inProgress-studentScoresContainer'>
							<div className='sectionHome-inProgress-studentScores-blockLine'/>
							<div className='sectionHome-inProgress-studentScores'>
								{sortedStudents.map(this.renderStudentScorePill)}
							</div>				
						</div>
					</div>

				}


				<div className='sectionHome-inProgress-divider' />

			</div>
		)
	}
}


export default withRouter(SectionHomeResumableSetPoll)
							
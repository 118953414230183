import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux'
import { forgotPassword } from '../../actions/user'
import ForgotPasswordForm from '../../components/forms/account/ForgotPasswordForm'
import getErrorMessageForRequest from '../../utils/getErrorMessageForRequest'

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props)
    this.onPreSubmit = this.onPreSubmit.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      errorMessage: null,
      emailSent: false,
      submitLoading: false,
      recaptchaFailed: false,
    }
  }

  onPreSubmit() {
    this.setState({ submitLoading: true, emailSent: false })
  }

  handleSubmit(data) {
    const { forgotPassword } = this.props
    this.setState({ errorMessage: null })

    return forgotPassword(data.email, data.recaptchaResponse)
      .then(() => {
        this.setState({ submitLoading: false, emailSent: true })
      })
      .catch((error) => {
        this.setState({ submitLoading: false })
        const errorMessage = getErrorMessageForRequest({
          requestName: 'forgotPassword',
          response: error.response,
          data: { email: data.email },
        })
        this.setState({ errorMessage })
      })
  }

  render() {
    const { errorMessage, recaptchaFailed } = this.state
    return (
      <DocumentTitle title='Forgot Password - Plickers'>
        <div className='forgotPassword marketing'>
          <div className='forgotPassword-backtoPlickersLogin' onClick={() => { this.props.history.push('login') }}>
            Back to Plickers.com
          </div>

          <div className='forgotPassword-centerBlock'>
            <div className='forgotPassword-centerBlock--top' />
            <div className='forgotPassword-centerBlock--middle'>

              <div className='forgotPassword-centerBlock-title'>
                Forgot Password
              </div>

              {!this.state.emailSent && (
              <React.Fragment>

                <div className='forgotPassword-centerBlock-subtitle'>
                  We’ll send you an email with further instructions to reset your password.
                </div>

                <div className={`forgotPassword-centerBlock-form ${recaptchaFailed && 'forgotPassword-centerBlock-form--disabled'}`}>
                  <ForgotPasswordForm
                    onPreSubmit={this.onPreSubmit}
                    onSubmit={this.handleSubmit}
                    loginError={this.state.loginError}
                    submitLoading={this.state.submitLoading}
                    onRecaptchaError={() => this.setState({ recaptchaFailed: true })}
                  />
                </div>

                {recaptchaFailed && (
                <div className='forgotPassword-centerBlock-error--recaptcha'>
                  <div className='forgotPassword-centerBlock-error'>
                    This form requires reCAPTCHA but it failed to load.
                  </div>
                  <div className='forgotPassword-centerBlock-error'>
                    Ensure google.com/recaptcha/api.js is not blocked by your browser or network.
                  </div>
                </div>
                )}

                {errorMessage && (
                <div className='forgotPassword-centerBlock-error'>
                  {errorMessage}
                </div>
                )}

              </React.Fragment>
              )}

              {this.state.emailSent && (
              <div className='forgotPassword-centerBlock-sentEmailMessage'>
                Email sent! Check your inbox for
                reset password instructions.
              </div>
              )}

            </div>

            <div className='forgotPassword-centerBlock--bottom' />

          </div>

        </div>
      </DocumentTitle>
    )
  }
}

export default withRouter(connect(
  (/* state */) => ({ }),
  { forgotPassword },
)(ForgotPasswordContainer))

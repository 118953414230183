import React from 'react'
import Button from '../../uiKit/Button'
import {getPublicUrlForRepo} from '../../../utils/getPublicUrlForRepo'
import filter from 'lodash/filter'

function dollarAmountFormatter(amount){
	return `$${(amount /100).toFixed(2)}`
}

class CreatorListDetailPanelAward extends React.Component{ 

	render(){
		const {award} = this.props		

		let repo=award.repo
		let repoName=''
		let repoURL=''
		if(repo){
			repoName = award.repo.name
			repoURL = getPublicUrlForRepo(award.repo)
		}


		const amount = `$${(award.amount /100).toFixed(2)}`

		let occasionName = ''
		if(award.occasionName==='CreatorFund_2020-03'){
			occasionName = 'Creator Fund March 2020'
		}else if(award.occasionName==='CreatorFund_2020-02'){
			occasionName = 'Creator Fund February 2020'
		}

		return( 			
				<div className='creatorsList-detailPanel-award'>											
						<a className='creatorsList-detailPanel-award-title' href={`https://${repoURL}`} target='_blank' rel='noopener noreferrer'>
							{repoName}
						</a>
						<div className='creatorsList-detailPanel-award-amount'>
							{amount}
						</div>		
						<div className='creatorsList-detailPanel-award-occasionReason'>
							{occasionName} ({award.reason})
						</div>
						{this.props.status === 'pending' &&	
							<Button 
								label='Activate Award'
								color='blue'
								size='small'
								name='activateawardbutton'
								onClickFunction={()=>{this.props.activateAward(award.id)}}
							/>							
						}				

				</div>			
		)
	}
}


class CreatorListDetailPanelHeader extends React.Component{ 

	render(){

		const {creator,paypalEmail} = this.props

		const plickersAdminConsoleURL = `https://plickers-web.herokuapp.com/admin/users/${creator.id}`
		let isPro=false
		if(creator.service && creator.service.product==='plus'){
			isPro=true
		}

		const plickersCreatorURL = `https://www.plickers.com/${creator.username}`

		return( 			
				<div className='creatorsList-detailPanel-header'>
					<div className='creatorsList-detailPanel-header--left'>
						<div className='creatorsList-detailPanel-header-creatorName'>
							{creator.firstName} {creator.lastName} 
							
							{paypalEmail &&
								<div className='creatorsList-detailPanel-header-payPalBadge creatorsList-detailPanel-header-payPalBadge--active'>
									PayPal
								</div>
							}
							{!paypalEmail &&
								<div className='creatorsList-detailPanel-header-payPalBadge creatorsList-detailPanel-header-payPalBadge--inactive'>
									No PayPal
								</div>
							}
							{!isPro &&
								<div className='creatorsList-detailPanel-header-proBadge creatorsList-detailPanel-header-proBadge--inactive'>
									Free user
								</div>
							}
							{isPro &&
								<div className='creatorsList-detailPanel-header-proBadge creatorsList-detailPanel-header-proBadge--active'>
									Pro user
								</div>
							}
						</div>
						<div className='creatorsList-detailPanel-header-submissionID'>
							
						</div>

						
						<div className='creatorsList-detailPanel-header-author'>
							{creator.username}
						</div>
						<div className='creatorsList-detailPanel-header-metadata'>
							{creator.email}
						</div>	
						{paypalEmail &&
							<div className='creatorsList-detailPanel-header-metadata'>
								PayPal Email: {paypalEmail}
							</div>				
						}		
						<a className='creatorsList-detailPanel-header-url creatorsList-detailPanel-header-url--plickersURL' href={`${plickersAdminConsoleURL}`} target='_blank' rel='noopener noreferrer'>
							<div className='creatorsList-detailPanel-header-url-icon'>
								<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">    
									<path d="M11.718,25.3854749 L10.098,26.9954749 C8.696,28.3854749 6.412,28.3854749 5.008,26.9954749 C4.336,26.3274749 3.966,25.4374749 3.966,24.4914749 C3.966,23.5454749 4.338,22.6594749 5.008,21.9874749 L10.968,16.0774749 C12.202,14.8514749 14.526,13.0474749 16.22,14.7274749 C16.998,15.4994749 18.252,15.4954749 19.026,14.7174749 C19.796,13.9394749 19.792,12.6834749 19.014,11.9134749 C16.138,9.05747492 11.882,9.58547492 8.176,13.2634749 L2.216,19.1754749 C0.786,20.5934749 0,22.4834749 0,24.4914749 C0,26.5034749 0.788,28.3894749 2.216,29.8074749 C3.688,31.2674749 5.62,31.9994749 7.554,31.9994749 C9.488,31.9994749 11.422,31.2694749 12.892,29.8074749 L14.514,28.1974749 C15.292,27.4274749 15.296,26.1734749 14.524,25.3974749 C13.75,24.6214749 12.496,24.6174749 11.718,25.3854749 Z M29.782,2.41747492 C26.688,-0.65052508 22.364,-0.81652508 19.504,2.02347492 L17.486,4.02747492 C16.708,4.79947492 16.702,6.05347492 17.474,6.82947492 C18.246,7.60747492 19.5,7.61147492 20.278,6.83947492 L22.298,4.83747492 C23.778,3.36547492 25.72,3.97547492 26.99,5.23147492 C27.662,5.90147492 28.034,6.78947492 28.034,7.73547492 C28.034,8.68147492 27.662,9.56947492 26.99,10.2374749 L20.63,16.5454749 C17.722,19.4274749 16.358,18.0774749 15.776,17.4994749 C14.998,16.7274749 13.744,16.7334749 12.974,17.5094749 C12.202,18.2874749 12.206,19.5434749 12.984,20.3114749 C14.32,21.6354749 15.844,22.2914749 17.44,22.2914749 C19.394,22.2914749 21.46,21.3074749 23.426,19.3574749 L29.786,13.0514749 C31.21,11.6314749 32,9.74347492 32,7.73547492 C32,5.72547492 31.21,3.83747492 29.782,2.41747492 Z"></path>
								</svg>
							</div>							
							{plickersAdminConsoleURL}							
						</a>
						<a className='creatorsList-detailPanel-header-url creatorsList-detailPanel-header-url--plickersURL' href={`${plickersCreatorURL}`} target='_blank' rel='noopener noreferrer'>
							<div className='creatorsList-detailPanel-header-url-icon'>
								<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">    
									<path d="M11.718,25.3854749 L10.098,26.9954749 C8.696,28.3854749 6.412,28.3854749 5.008,26.9954749 C4.336,26.3274749 3.966,25.4374749 3.966,24.4914749 C3.966,23.5454749 4.338,22.6594749 5.008,21.9874749 L10.968,16.0774749 C12.202,14.8514749 14.526,13.0474749 16.22,14.7274749 C16.998,15.4994749 18.252,15.4954749 19.026,14.7174749 C19.796,13.9394749 19.792,12.6834749 19.014,11.9134749 C16.138,9.05747492 11.882,9.58547492 8.176,13.2634749 L2.216,19.1754749 C0.786,20.5934749 0,22.4834749 0,24.4914749 C0,26.5034749 0.788,28.3894749 2.216,29.8074749 C3.688,31.2674749 5.62,31.9994749 7.554,31.9994749 C9.488,31.9994749 11.422,31.2694749 12.892,29.8074749 L14.514,28.1974749 C15.292,27.4274749 15.296,26.1734749 14.524,25.3974749 C13.75,24.6214749 12.496,24.6174749 11.718,25.3854749 Z M29.782,2.41747492 C26.688,-0.65052508 22.364,-0.81652508 19.504,2.02347492 L17.486,4.02747492 C16.708,4.79947492 16.702,6.05347492 17.474,6.82947492 C18.246,7.60747492 19.5,7.61147492 20.278,6.83947492 L22.298,4.83747492 C23.778,3.36547492 25.72,3.97547492 26.99,5.23147492 C27.662,5.90147492 28.034,6.78947492 28.034,7.73547492 C28.034,8.68147492 27.662,9.56947492 26.99,10.2374749 L20.63,16.5454749 C17.722,19.4274749 16.358,18.0774749 15.776,17.4994749 C14.998,16.7274749 13.744,16.7334749 12.974,17.5094749 C12.202,18.2874749 12.206,19.5434749 12.984,20.3114749 C14.32,21.6354749 15.844,22.2914749 17.44,22.2914749 C19.394,22.2914749 21.46,21.3074749 23.426,19.3574749 L29.786,13.0514749 C31.21,11.6314749 32,9.74347492 32,7.73547492 C32,5.72547492 31.21,3.83747492 29.782,2.41747492 Z"></path>
								</svg>
							</div>							
							{plickersCreatorURL}
						</a>																					
						
					</div>
					<div className='creatorsList-detailPanel-header--right'>
					

					</div>

				</div>			
		)
	}
}





class CreatorsListDetailPanel extends React.Component{ 



	constructor() {
		super()	
		this.resetState=this.resetState.bind(this)
		this.updatePayoutAmount=this.updatePayoutAmount.bind(this)
		this.state = { 
			payoutAmount:'',
			giveProForFree:false
	
	    }
  	}

  	UNSAFE_componentWillReceiveProps(nextProps) {
 		if(nextProps.creator.id !==this.props.creator.id){
 			this.resetState()
 		}
 	}

  	resetState(){
  		this.setState({
			giveProForFree:false

	    })
  	}

  	updatePayoutAmount(){
  		this.props.adminAddCreatorFebPayoutAmount(this.props.creator.id,this.state.payoutAmount)
  		this.setState({payoutAmount:''})
  	}


	render(){

		const {creator,creatorAwards} = this.props
		
		//let februaryAwardAmount=0
		//let hasQualityAward=false
		//let hasUsageAward=false
		// if(creatorAwards){
		// 	creatorAwards.forEach((award)=>{
		// 		februaryAwardAmount+=award.amount
		// 		if(award.reason==='usage'){
		// 	//		hasUsageAward=true
		// 		}else if (award.reason==='quality'){
		// 		//	hasQualityAward=true
		// 		}
		// 	})
		// }



		//const formattedAwardAmount= februaryAwardAmount ?`$${(februaryAwardAmount /100).toFixed(2)}`:null

		//let febCreatorFundEmailSent=false 
		//let customEmail=false
		//if(creator && creator.explorerMetadata && creator.explorerMetadata.misc && creator.explorerMetadata.misc.febCreatorFundEmailSent){
		//	febCreatorFundEmailSent=true
	//	}
		//if(creator && creator.explorerMetadata && creator.explorerMetadata.misc && creator.explorerMetadata.misc.customEmail){
	//		customEmail=true
	//	}


		let proposedAwards=[]
		let activatedAwards=[]
		let paymentPendingAwards=[]
		let paidAwards=[]
		if(creatorAwards){
			proposedAwards=filter(creatorAwards,{status:'proposed'})
			activatedAwards=filter(creatorAwards,{status:'activated'})
			paymentPendingAwards=filter(creatorAwards,{status:'payout pending'})
			paidAwards=filter(creatorAwards,{status:'paid'})
		}

		let outstandingPaymentsTotal=0
		let pendingPaymentsTotal=0
		let paidPaymentsTotal=0
		creatorAwards.forEach((award)=>{
			if(award.status==='activated'){
				outstandingPaymentsTotal+=award.amount
			}
			else if(award.status==='payout pending'){
				pendingPaymentsTotal+=award.amount
			}
			else if(award.status==='paid'){
				paidPaymentsTotal+=award.amount
			}

		})









		return( 
			<div className='creatorsList-detailPanel'>
				
				<CreatorListDetailPanelHeader
					creator={creator}
					paypalEmail={creator.payoutDestination}
				
				/>
				
				<div className='creatorsList-detailPanel-actionArea'>
				
					
					<div className='creatorsList-detailPanel-actionArea-section creatorsList-detailPanel-actionArea-section--awards'>
						<div className='creatorsList-detailPanel-actionArea-section-header'>
							Awards
						</div>
						
						<div className='creatorsList-detailPanel-actionArea-section--awards-section'>
							<div className='creatorsList-detailPanel-actionArea-section--awards-section-header'>
								proposed
							</div>
							<div className='creatorsList-detailPanel-actionArea-section--awards-section-awards'>
								{proposedAwards && proposedAwards.map((award)=>{
									return <CreatorListDetailPanelAward key={award.id} status='pending' award={award} activateAward={this.props.activateAward}/>
							})}
								
							</div>
						</div>

						<div className='creatorsList-detailPanel-actionArea-section--awards-section'>
							<div className='creatorsList-detailPanel-actionArea-section--awards-section-header'>
								{creator.payoutDestination ? 'Claimed' : 'Activated'}
							</div>
							<div className='creatorsList-detailPanel-actionArea-section--awards-section-awards'>	

							{activatedAwards && activatedAwards.map((award)=>{
									return <CreatorListDetailPanelAward key={award.id} status={creator.payoutDestination ? 'Claimed' : 'Activated'} award={award} activateAward={this.props.activateAward}/>
							})}
							
							</div>
						</div>

						
						<div className='creatorsList-detailPanel-actionArea-section--awards-section'>
							<div className='creatorsList-detailPanel-actionArea-section--awards-section-header'>
								Payout pending
							</div>
							<div className='creatorsList-detailPanel-actionArea-section--awards-section-awards'>
								
								{paymentPendingAwards && paymentPendingAwards.map((award)=>{
									return <CreatorListDetailPanelAward key={award.id} status={creator.payoutDestination ? 'Claimed' : 'Activated'} award={award} activateAward={this.props.activateAward}/>
								})}
								
										
							</div>
						</div>


						<div className='creatorsList-detailPanel-actionArea-section--awards-section'>
							<div className='creatorsList-detailPanel-actionArea-section--awards-section-header'>
								Paid
							</div>
							<div className='creatorsList-detailPanel-actionArea-section--awards-section-awards'>
								
								
								{paidAwards && paidAwards.map((award)=>{
									return <CreatorListDetailPanelAward key={award.id} status={creator.payoutDestination ? 'Claimed' : 'Activated'} award={award} activateAward={this.props.activateAward}/>
								})}
										
							</div>
						</div>
					

					</div>
					
				
					<div className='creatorsList-detailPanel-actionArea-section creatorsList-detailPanel-actionArea-section--payouts'>
						<div className='creatorsList-detailPanel-actionArea-section-header'>
							Payouts
						</div>

						<div className='creatorsList-detailPanel-actionArea-section--payouts-columnContainer'>
							<div className='creatorsList-detailPanel-actionArea-section--payouts-col creatorsList-detailPanel-actionArea-section--payouts-col--outstanding'>
								<div className='creatorsList-detailPanel-actionArea-section--payouts-col-header'>
									Outstanding
								</div>
								<div className='creatorsList-detailPanel-actionArea-section--payouts-col-value'>
									{dollarAmountFormatter(outstandingPaymentsTotal)}
								</div>	
								{outstandingPaymentsTotal !==0 && creator.payoutDestination &&
									<Button 
										label={`Payout ${dollarAmountFormatter(outstandingPaymentsTotal)} Now`}
										color='blue'
										onClickFunction={()=>{this.props.adminPayActivatedAwards(creator.id)}}
									/>

								}							
								
							</div>

							<div className='creatorsList-detailPanel-actionArea-section--payouts-col'>
								<div className='creatorsList-detailPanel-actionArea-section--payouts-col-header'>
									Pending
								</div>
								<div className='creatorsList-detailPanel-actionArea-section--payouts-col-value'>
									{dollarAmountFormatter(pendingPaymentsTotal)}
								</div>
							</div>

							<div className='creatorsList-detailPanel-actionArea-section--payouts-col'>
								<div className='creatorsList-detailPanel-actionArea-section--payouts-col-header'>
									Paid
								</div>
								<div className='creatorsList-detailPanel-actionArea-section--payouts-col-value'>
									{dollarAmountFormatter(paidPaymentsTotal)}
								</div>
							</div>

						</div>
					</div>
			
	

					
					{/*
					<div className='creatorsList-detailPanel-actionArea-section'>
						<div className='creatorsList-detailPanel-actionArea-section-header'>
							Rising Phoenix Email
						</div>

						<div className='creatorsList-detailPanel-actionArea-emailTemplater'>

							<div className='creatorsList-detailPanel-actionArea-emailTemplater-controls'>
								<div className='creatorsList-detailPanel-actionArea-emailTemplater-controls-februaryPayout'>
									<div className='creatorsList-detailPanel-actionArea-emailTemplater-controls-februaryPayout-header'>
										February Payout Amount: <b>{formattedAwardAmount}</b>
									</div>									
									<div onClick={this.props.adminSetCreatorToCustomEmail} className='creatorsList-detailPanel-actionArea-emailTemplater-controls-februaryPayout-header'>
										Custom Email Status: <b>{customEmail?'Custom Email':'Auto Email'}</b>
									</div>
									<div className='creatorsList-detailPanel-actionArea-emailTemplater-controls-februaryPayout-header'>
										Email Sent Status: <b>{febCreatorFundEmailSent?'Sent':'Not Sent'}</b>
									</div>

									<br/>
									<br/>
									<br/>

									<Button
										label={febCreatorFundEmailSent?'Mark Email Unsent':'Mark Email Sent'}
										color={febCreatorFundEmailSent?'white':'blue'}
										onClickFunction={this.props.adminSetCreatorMetadataToEmailSent}									
									/>
								
									<br/>
								{creator.service && creator.service.product !=='plus' && 
									
									<FeatureModalSelectControl
										checkbox
										checked={this.state.giveProForFree}
										onClick={()=>{this.setState({giveProForFree:!this.state.giveProForFree})}}
										label="Give Free Pro"
									/>


									}
									
									
								</div>
							</div>




						

					


					
					
								<div className='creatorsList-detailPanel-actionArea-emailTemplater-previewContainer'>									
								
										<CreatorsListDetailPanelEmailPreview 
											creator={creator}
											formattedAwardAmount={formattedAwardAmount}
											hasQualityAward={hasQualityAward}
											hasUsageAward={hasUsageAward}
											creatorAwards={creatorAwards}
											giveProForFree={this.state.giveProForFree}
										/>																					
								</div>

									
								</div>
								
							</div>
							
						*/}	
						</div>	
						
							


				</div>
								

				
		)
	}
}


export default CreatorsListDetailPanel



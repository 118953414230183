import find from 'lodash/find'
import api from '../api'
import { fetchUser } from './user'
import { fetchUserConfig } from './userConfig'
import {
  fetchPollsForSet,
  fetchPollsForQuestion,
  fetchSetPolls,
  fetchQuestionPolls,
} from './polls'
import { fetchControl } from './control'
import { fetchSections } from './sections'
import { fetchService } from './service'
import { fetchMeta } from './meta'
import { fetchGeoInfo } from './geoInfo'
import { fetchARepoAndPermissions, fetchRepos } from './repos'
import { fetchLocksForRepo } from './locks'
import store from '../store'
import { fetchBilling } from './billing'
import { fetchReviewsForUser } from './reviews'
import { fetchSubmissions } from './submissions'
import { fetchSearchKey } from './searchKey'
import { fetchRecentSymbols, fetchSymbolsList } from './recentSymbols'

function loadCommonData(dispatch, itemId, userId, isSet, isNewItem) {
  dispatch(fetchUser(userId))
  dispatch(fetchUserConfig(userId))
  dispatch(fetchSymbolsList())
  dispatch(fetchRecentSymbols())
  dispatch(fetchRepos())
  dispatch(fetchSearchKey())
  dispatch(fetchService())
  dispatch(fetchBilling())
  dispatch(fetchReviewsForUser())
  dispatch(fetchControl())
  if (isSet && !isNewItem) {
    dispatch(fetchPollsForSet(itemId))
  } else if (!isSet && !isNewItem) {
    dispatch(fetchPollsForQuestion(itemId))
  }

  dispatch(fetchSetPolls())// fix for editor NP thing
  dispatch(fetchQuestionPolls())
  dispatch(fetchSections())
  dispatch(fetchMeta())
  dispatch(fetchGeoInfo())
  dispatch(fetchSubmissions())
  dispatch(fetchControl())
}

export function loadRichEditorData(itemId, userId, isSet) {
  if (isSet) {
    if (itemId !== 'newSet' && itemId !== 'newSet/') {
      return (dispatch) => {
        api.fetch(`/sets/${itemId}`)
          .then((response) => {
            dispatch({ type: 'FETCH_A_SET_SUCCESS', response })
            if (response.repo) {
              const promises = []
              promises.push(dispatch(fetchARepoAndPermissions(response.repo)))
              promises.push(dispatch(fetchLocksForRepo(response.repo)))

              Promise.all(promises).then(() => {
                const state = store.getState()
                const parentRepo = find(state.repos, { id: response.repo })
                if (parentRepo && parentRepo.shared) {
                  let lockState = 'unlocked'
                  const repoLocks = state.locks[response.repo]
                  const lock = find(repoLocks, (lock) => lock.item === itemId)

                  if (lock && state.user) {
                    if (lock.user !== state.user.id) {
                      lockState = 'lockedByAnotherUser'
                    }
                  }
                  window.analytics.track('Open Shared Item', {
                    itemType: 'set',
                    lockState,
                  })
                }
              })
            }
            const isNewItem = false
            loadCommonData(dispatch, itemId, userId, isSet, isNewItem)
          })
          .catch(() => {})
      }
    }
    return (dispatch) => {
      api.fetch('/control')
        .then((response) => {
          dispatch({ type: 'FETCH_CONTROL_SUCCESS', response })
          const isNewItem = true
          loadCommonData(dispatch, itemId, userId, isSet, isNewItem)
        })
        .catch(() => {})
    }
  }

  if (itemId !== 'newquestion' && itemId !== 'newquestion/') {
    return (dispatch) => {
      api.fetch(`/questions/${itemId}`)
        .then((response) => {
          dispatch({ type: 'FETCH_A_QUESTION_SUCCESS', response })
          if (response.repo) {
            const promises = []
            promises.push(dispatch(fetchARepoAndPermissions(response.repo)))
            promises.push(dispatch(fetchLocksForRepo(response.repo)))
            Promise.all(promises).then(() => {
              const state = store.getState()
              const parentRepo = find(state.repos, { id: response.repo })
              if (parentRepo && parentRepo.shared) {
                let lockState = 'unlocked'
                const repoLocks = state.locks[response.repo]
                const lock = find(repoLocks, (lock) => lock.item === itemId)
                if (lock && state.user) {
                  if (lock.user !== state.user.id) {
                    lockState = 'lockedByAnotherUser'
                  }
                }
                window.analytics.track('Open Shared Item', {
                  itemType: 'question',
                  lockState,
                })
              }
            })
          }
          const isNewItem = false
          loadCommonData(dispatch, itemId, userId, isSet, isNewItem)
        })
        .catch(() => {})
    }
  }
  return (dispatch) => {
    api.fetch('/control')
      .then((response) => {
        dispatch({ type: 'FETCH_CONTROL_SUCCESS', response })
        const isNewItem = true
        loadCommonData(dispatch, itemId, userId, isSet, isNewItem)
      })
      .catch(() => {})
  }
}

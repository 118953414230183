import React from 'react'


class SetHeaderContent extends React.Component{

	render(){ 
		const {score,setCounter,showQuestionNumbers,showStudentScores}=this.props

		return(				
			<React.Fragment>
				<div className='printPackSetHeader-counter notranslate'>
					{showQuestionNumbers &&
						<React.Fragment>
							{setCounter}
						</React.Fragment>
					}
				</div>
				
				<div className='printPackSetHeader-name notranslate'>
					{this.props.setName}
				</div>
				
				{showStudentScores && score !=='-' && score !== -1 &&
					<div className='printPackSetHeader-score'>						
						{Math.round(score)}%
					</div>		
				}
			</React.Fragment>
		)
	}
}
export default SetHeaderContent
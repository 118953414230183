//when image is sucessfully uploaded we save the nodeId and cloudinary response 
//this is used in setEditor, if a user makes edits while the image is in placeholder state it could be possible
//for them to undo changes to a state where image returns to placeholder- to prevent this the image node
//checks for saved cloudinary responses for its nodeId 

export default function (state = {},action) {
	switch (action.type) {

	case 'CLOUDINARY_UPLOAD_SUCCESS':
		state[action.nodeId]=action.cloudinaryResponse
		return state
	
	case 'LOGOUT':
		return {}
	
	default:
		return state
	}
}

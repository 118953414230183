import React from 'react'
import {getSuggestions} from '../../../../prosemirror/plugins/suggestions/utils/getSuggestions'
import categoriesOrdered from '../../../../staticData/symbolCategoriesOrdered.js'
import symbols from '../../../../staticData/symbols.js'
import {getFormattedRecentSymbols} from '../../../../prosemirror/utils/getFormattedRecentSymbols'
import SymbolPickerList from './SymbolPickerList'
import SymbolPickerSearchBar from './SymbolPickerSearchBar'
 
class SymbolPicker extends React.Component{  
	
	constructor() {
		super()
		this.onMouseEnter=this.onMouseEnter.bind(this)
		this.onMouseLeave=this.onMouseLeave.bind(this)
		this.handleClickOutside=this.handleClickOutside.bind(this)
		this.onChangeSearchTerm=this.onChangeSearchTerm.bind(this)
		const recentSymbols=getFormattedRecentSymbols().slice(0,42)
		this.state = {
			recentSymbols:recentSymbols,
			hoveredSymbol:null, //can take this out- was used to display symbol info e.g. name on hover like in slack symbol picker
			searchTerm:'',
			searchResults:[]
		}
	}

	componentDidMount(){
		document.addEventListener('click', this.handleClickOutside)
	}

	componentWillUnmount(){
		document.removeEventListener('click', this.handleClickOutside)
	}

	handleClickOutside(e){
		if(!document.getElementById('symbolPicker').contains(e.target)){
			this.props.closePicker()
		}
	}

	onMouseEnter(symbol){
		this.setState({hoveredSymbol:symbol})
	}

	onMouseLeave(symbol){
		this.setState({hoveredSymbol:null})
	}

	onChangeSearchTerm(searchTerm){
		this.setState({searchTerm:searchTerm})
		getSuggestions(searchTerm, symbols,this.state.recentSymbols, true, function(suggestions) {
			this.setState({searchResults:suggestions})                 
		}.bind(this))
	}

	render(){
		const pickerType='symbol'
		const categories=Object.keys(categoriesOrdered)
		return (
			<div id="symbolPicker" className={'editor-emojiSymbolPicker notranslate ' + (pickerType ? `editor-emojiSymbolPicker--${pickerType}` : '')}>

				<SymbolPickerSearchBar
					searchTerm={this.state.searchTerm}
					onChange={this.onChangeSearchTerm}
				 />

				<SymbolPickerList 
					recentSymbols={this.state.recentSymbols}
					searchTerm={this.state.searchTerm}
					searchResults={this.state.searchResults}
					data={symbols} 
					categories={categories} 
					categoriesOrdered={categoriesOrdered} 
					onSelectSymbol={this.props.onSelectSymbol} 
					onMouseEnter={this.onMouseEnter} 
					onMouseLeave={this.onMouseLeave}
				/>
					
			</div>
		) 
	}
}

export default SymbolPicker
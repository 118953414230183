import React from 'react'
import Button from '../../uiKit/Button'
import Icon from '../../misc/Icons'

class SectionHomeELearningBanner extends React.Component{

	constructor(){
		super()
		this.handleClickNotNow=this.handleClickNotNow.bind(this)
		this.handleClickDismiss=this.handleClickDismiss.bind(this)
		this.showActivateElearningModal=this.showActivateElearningModal.bind(this)
	}

	handleClickNotNow(){
		this.props.turnOffSyncBanner()
		if(window.analytics){
 			window.analytics.track('Section home page e learning banner action',{action:"notNow"})
 		}
	}

	handleClickDismiss(){
		this.props.turnOffSyncBanner()
		if(window.analytics){
 			window.analytics.track('Section home page e learning banner action',{action:"dismiss"})
 		}
	}

	handleClickFindOutMore(){
		window.location.hash='e-learning-activation'
		if(window.analytics){
 			window.analytics.track('Section home page e learning banner action',{action:"secondaryCTA"})
 		}
	}

	handleClickGetStarted(){
		window.location.hash='e-learning-activation'
		if(window.analytics){
 			window.analytics.track('Section home page e learning banner action',{action:"primaryCTA"})
 		}
	}

	showActivateElearningModal(){
		this.props.showActivateElearningModal()
		if(window.analytics){
 			window.analytics.track('Section home page e learning banner action',{action:"primaryCTA"})
 		}
	}

	render(){
		const {section,toggleStudentAccessSubmitLoading,meta}=this.props		
		let userHasSubmittedResponse = false // user has not completed elearning activation before
		if(meta && meta.onboardingStatus && meta.onboardingStatus.assignedResponseSubmitted){
			userHasSubmittedResponse = true
		}
		return(							
			<div className={'sectionHome--block ' + (toggleStudentAccessSubmitLoading ? ' sectionHome-eLearningBannerContainer--submitLoading' : '')}>						
				<div className={'sectionHome-eLearningBanner ' + ((section && section.color) ? `sectionHome-eLearningBanner--classColor-${section.color}`: '')}>					

					<div className='sectionHome-eLearningBanner-title'>
						Allow students to answer from home
					</div>

					<div className='sectionHome-eLearningBanner-subtitle'>
						Use for hybrid teaching or 100% remote.
					</div>

					{userHasSubmittedResponse &&						
						<React.Fragment>
							<Button label='Turn on E-Learning for this class' name='elearningbannersubmit' onClickFunction={this.showActivateElearningModal}/>						
							<div className='sectionHome-eLearningBanner-secondLevelButtons'>
								<Button label='Find out more' name='elearningbannerdismiss' onClickFunction={this.handleClickFindOutMore}/>	
								<Button label='Not Now' name='elearningbannerdismiss' onClickFunction={this.handleClickNotNow}/>	
							</div>
						</React.Fragment>
					}

					{!userHasSubmittedResponse &&						
						<React.Fragment>
							<Button label='Get Started with E-Learning' name='elearningbannersubmit' onClickFunction={this.handleClickGetStarted}/>	
							<div className='sectionHome-eLearningBanner-secondLevelButtons'>										
								<Button label='Find out more' name='elearningbannerdismiss' onClickFunction={this.handleClickFindOutMore}/>
								<Button label='Not Now' name='elearningbannerdismiss' onClickFunction={this.handleClickNotNow}/>	
							</div>
						</React.Fragment>
					}

					<div className='sectionHome-eLearningBanner-illustrationContainer' >
						<img className='sectionHome-eLearningBanner-illustration-image' src="https://s3.amazonaws.com/assets.plickers.com/react-assets/images/elearning/SectionHomeELearningBannerImage.png" alt="Plickers E-Learning Graphic" />
						<div className='sectionHome-eLearningBanner-illustration-backgroundContainer'>
							<svg xmlns="http://www.w3.org/2000/svg" width="252" height="154" viewBox="0 0 252 154">  
								<path id="background" fillRule="nonzero" d="M252,126.435986 C252,126.366228 252,126.30083 252,126.231073 C252,56.6433218 195.587751,0.231072664 126,0.231072664 C56.4122491,0.231072664 0,56.6258824 0,126.213633 C0,126.283391 0,126.348789 0,126.418547 L252,126.435986 Z"/>
								<path id="shadow" fillRule="nonzero" d="M199.345952,147.855986 C197.50609,148.69308 160.944291,146.112042 160.97917,147.315363 C161.245121,152.943945 148.335571,149.761246 144.651488,151.043045 C132.465675,155.272111 117.982215,151.915017 108.281522,151.479031 C93.2748789,150.785813 104.793633,145.689135 95.1234602,145.253149 C84.4069204,144.769204 70.7082353,145.253149 63.1220761,145.231349 C60.1442907,145.231349 48.2244291,149.647889 48.2244291,147.062491 C48.2244291,143.221453 60.7895502,133.660277 67.4078201,133.660277 C105.456332,133.660277 207.708166,134.937716 214.029965,134.937716 C219.893979,134.937716 200.265882,147.437439 199.345952,147.855986 Z"/>
							</svg>
						</div>
					</div>

					<div onClick={this.handleClickDismiss} className='sectionHome-eLearningBanner-closeBtn'>
						<Icon name='circle-with-close' />
					</div>
				</div>
			</div>							
		)
	}
}


export default SectionHomeELearningBanner
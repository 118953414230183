// for printing report packs

const studentHeaderHeight = 85

export function paginateDataForPreview(polls, ghostHeights, columnsPerPage) {
  const availablePageHeight = ghostHeights.spacer
  const availablePageHeightFirstPage = availablePageHeight - studentHeaderHeight

  const pollsByPage = {}
  let pageIndex = 0
  let currentPage = {}
  let currentColumnData = []
  let currentColumn = 1
  let currentColumnHeight = 0
  let currentMaxHeight = availablePageHeightFirstPage

  if (polls) {
    polls.forEach((poll, index) => {
      if (poll.setObject) { // is a setPoll
        const setCounter = index + 1
        poll.filteredAndOrderedPolls.forEach((setPollPoll, setPollPollIndex) => {
          if (setPollPollIndex === 0) {
            const headerObject = {
              type: 'setHeader',
              poll,
              setCounter,
            }
            const pollObject = {
              type: 'setPollPoll',
              poll: setPollPoll,
              parentPoll: poll.id,
              questionCounter: `${setCounter}.${setPollPollIndex + 1}`,
            }
            const ghostHeight = ghostHeights[`${poll.id}-${setPollPoll.id}`]
            const setHeaderHeight = ghostHeights[poll.id]
            const combinedHeight = setHeaderHeight + ghostHeight

            if ((currentColumnHeight + combinedHeight) < currentMaxHeight) {
              currentColumnData.push(headerObject)
              currentColumnData.push(pollObject)
              if (setPollPollIndex === (poll.filteredAndOrderedPolls.length - 1)) {
                const footerObject = {
                  type: 'setFooter',
                  poll,
                }
                currentColumnData.push(footerObject)
              }
              currentColumnHeight += combinedHeight
            } else {
              currentPage[`column-${currentColumn}`] = currentColumnData

              if (currentColumn < columnsPerPage) {
                currentColumn += 1
                currentColumnData = []
                currentColumnData.push(headerObject)
                currentColumnData.push(pollObject)
                if (setPollPollIndex === (poll.filteredAndOrderedPolls.length - 1)) {
                  const footerObject = {
                    type: 'setFooter',
                    poll,
                  }
                  currentColumnData.push(footerObject)
                }
                currentColumnHeight = combinedHeight
              } else {
                currentColumn = 1
                currentColumnData = []
                currentColumnData.push(headerObject)
                currentColumnData.push(pollObject)
                if (setPollPollIndex === (poll.filteredAndOrderedPolls.length - 1)) {
                  const footerObject = {
                    type: 'setFooter',
                    poll,
                  }
                  currentColumnData.push(footerObject)
                }
                currentColumnHeight = combinedHeight
                pollsByPage[pageIndex] = currentPage
                currentPage = {}
                pageIndex += 1
                currentMaxHeight = availablePageHeight
              }
            }
          } else { // not setPollPollIndex 0
            const ghostHeight = ghostHeights[`${poll.id}-${setPollPoll.id}`]
            const previewObject = {
              type: 'setPollPoll',
              poll: setPollPoll,
              parentPoll: poll.id,
              questionCounter: `${setCounter}.${setPollPollIndex + 1}`,
            }

            const newColumnHeight = currentColumnHeight + ghostHeight
            if (newColumnHeight < currentMaxHeight) {
              currentColumnData.push(previewObject)
              if (setPollPollIndex === (poll.filteredAndOrderedPolls.length - 1)) {
                const footerObject = {
                  type: 'setFooter',
                  poll,
                }
                currentColumnData.push(footerObject)
              }
              currentColumnHeight += ghostHeight
            } else {
              currentPage[`column-${currentColumn}`] = currentColumnData
              if (currentColumn < columnsPerPage) {
                currentColumn += 1
                currentColumnData = []
                currentColumnData.push(previewObject)
                if (setPollPollIndex === (poll.filteredAndOrderedPolls.length - 1)) {
                  const footerObject = {
                    type: 'setFooter',
                    poll,
                  }
                  currentColumnData.push(footerObject)
                }
                currentColumnHeight = ghostHeight
              } else {
                currentColumn = 1
                currentColumnData = []
                currentColumnData.push(previewObject)
                if (setPollPollIndex === (poll.filteredAndOrderedPolls.length - 1)) {
                  const footerObject = {
                    type: 'setFooter',
                    poll,
                  }
                  currentColumnData.push(footerObject)
                }
                currentColumnHeight = ghostHeight
                pollsByPage[pageIndex] = currentPage
                currentPage = {}
                pageIndex += 1
                currentMaxHeight = availablePageHeight
              }
            }
          }
        })
      } else {
        const ghostHeight = ghostHeights[poll.id]
        const previewObject = {
          type: 'questionPoll',
          poll,
          questionCounter: `${index + 1}`,
        }
        const newColumnHeight = currentColumnHeight + ghostHeight
        if (newColumnHeight < currentMaxHeight) {
          currentColumnData.push(previewObject)
          currentColumnHeight += ghostHeight
        } else {
          currentPage[`column-${currentColumn}`] = currentColumnData
          if (currentColumn < columnsPerPage) {
            currentColumn += 1
            currentColumnData = []
            currentColumnData.push(previewObject)
            currentColumnHeight = ghostHeight
          } else {
            currentColumn = 1
            currentColumnData = []
            currentColumnData.push(previewObject)
            currentColumnHeight = ghostHeight
            pollsByPage[pageIndex] = currentPage
            currentPage = {}
            pageIndex += 1
            currentMaxHeight = availablePageHeight
          }
        }
      }

      if (index === polls.length - 1) {
        currentPage[`column-${currentColumn}`] = currentColumnData
        pollsByPage[pageIndex] = currentPage
      }
    })
  }
  return pollsByPage
}

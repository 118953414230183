import React  from 'react'
import {withRouter, Link } from 'react-router-dom'
import ChoiceDetailBlock from '../uiKit/ChoiceDetailBlock'
import {formatDateLong} from '../../utils/formatDateLong'
import Icon from '../misc/Icons'
import ScorePill from '../uiKit/ScorePill'
import Button from '../uiKit/Button'
import Dropdown from '../uiKit/Dropdown'
import DropdownSelect from '../uiKit/DropdownSelect'
import DropdownDivider from '../uiKit/DropdownDivider'
import DropdownSelectItem from '../uiKit/DropdownSelectItem'
import SectionMarker from '../uiKit/SectionMarker'
import TopPageHeader from '../pageHeaders/TopPageHeader'
import StickyPageHeader from '../pageHeaders/StickyPageHeader'
import StaticQuestionSlide from '../slides/StaticQuestionSlide'
import Measure from 'react-measure'
import { getLastLocation } from '../routing/LastLocationProvider'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'
import {resumePlayingQuestionPoll} from '../../utils/resumePlayingQuestionPoll'
import {getReportSettings} from '../../utils/getReportSettings'
import {saveSettingToLocalStorage} from '../../utils/saveSettingToLocalStorage'


class QuestionPollDetailDropdownMenu extends React.Component{	

	render(){			
		const {showStudentCardNumbers, defaultShowStudentResponses,setReportSetting} = this.props
	
		return(				
			<Dropdown>
			
				<DropdownSelect label='Student Responses'>
					<DropdownSelectItem isActive={defaultShowStudentResponses} label='Default Show' onSelect={()=>{setReportSetting('defaultShowStudentResponses',true)}}/>	
					<DropdownSelectItem isActive={!defaultShowStudentResponses} label='Default Hide'  onSelect={()=>{setReportSetting('defaultShowStudentResponses',false)}}/>	
									
							
				</DropdownSelect>				

				<DropdownDivider /> 
						
				<DropdownSelect label='Student Card Numbers'>
					<DropdownSelectItem isActive={!showStudentCardNumbers} label='Hide'  onSelect={()=>{setReportSetting('showStudentCardNumbers',false)}} />
					<DropdownSelectItem isActive={showStudentCardNumbers} label='Show'  onSelect={()=>{setReportSetting('showStudentCardNumbers',true)}} />						
				</DropdownSelect>		

			</Dropdown>
		)
	
	}
				
}


class QuestionPollDetail extends React.Component{

	constructor(){
		super() 
		this.goToSetPollDetail = this.goToSetPollDetail.bind(this)	
		this.setReportSetting = this.setReportSetting.bind(this)	

		const reportSettings=getReportSettings()	
 		this.state=({ 	
 			questionReportSidePanelWidth:0,
 			reportSettings:reportSettings,
 			showStudentResponses: reportSettings.defaultShowStudentResponses
 		})
 	}

 	goToSetPollDetail(){
 		const previousLocation=getLastLocation()
 		if(previousLocation ){			
			this.props.history.goBack()
		}
 		else this.props.history.push(`/setreport/${this.props.setPollId}`)

 	}


	setReportSetting(setting,value){
		saveSettingToLocalStorage('reportSettings',setting,value)
		let reportSettings=this.state.reportSettings
		reportSettings[setting]=value
		this.setState({reportSettings:reportSettings})
		if(setting==='defaultShowStudentResponses'){
			this.setState({showStudentResponses:value})
		}
	}
	




	render(){

		const {poll,question, section,processedQuestionResponses,isInSet}=this.props
		const {responsesAnswerA,responsesAnswerB,responsesAnswerC,responsesAnswerD,missingStudentsList,invalidResponses}=processedQuestionResponses
		const {questionReportSidePanelWidth, reportSettings,showStudentResponses} = this.state
		const { defaultShowStudentResponses, showStudentCardNumbers}=reportSettings
		let totalResponseCount=poll.unassignedResponseCount+poll.assignedResponseCount
		let questionScore = poll.correctPercent
		let isSurvey = poll.correctPercent ===-1? true:false	
		const questionDetailPath=`/question/${poll.snapshot.id}`		

		let revealInLibraryDisabled=true
		if(question){
			revealInLibraryDisabled=false
		}

		return[			
			<div className='page-leftPanel' key='leftPanel'/>,
			<div className='page-centerPanel' key='centerPanel'>
				{!isInSet &&
					<TopPageHeader
						location='questionReport'	
						height={209}
						maxHeight={249}
						rightBadge={isSurvey ? null : <ScorePill typeDot score={questionScore} />}
						header={getQuestionBodyLabel(poll.snapshot)}
						pageHeaderNoTranslate
						headerInfoRow={							
							<Link to={`/classes/${section.id}`}>
								<SectionMarker section={section} />
							</Link>														
						}

						multiLineHeader
						actionRow={<React.Fragment>{formatDateLong(poll.minCaptured)}<div className='pageHeaderTop-actionRow-spacer'/> <QuestionPollDetailDropdownMenu  defaultShowStudentResponses={defaultShowStudentResponses} showStudentCardNumbers={showStudentCardNumbers} setReportSetting={this.setReportSetting}/></React.Fragment>}
						borderBottom
						backButton
					/>
				}

				{!isInSet &&
					<StickyPageHeader 
						location='setQuestionReport'			
						header={getQuestionBodyLabel(poll.snapshot)}
						pageHeaderNoTranslate
						actionGroup={isSurvey ? null : <ScorePill typeDot score={questionScore} />}
					/>		
				}									


				{isInSet &&
					<TopPageHeader
						location='setQuestionReport' 
						height={209}
						maxHeight={249}
						leftBadge={<Icon name='chevron-left' />}
						leftBadgeClickFunction={this.goToSetPollDetail}
						rightBadge={isSurvey ? null : <ScorePill typeDot score={questionScore} />}
						header={getQuestionBodyLabel(poll.snapshot)}
						multiLineHeader
						pageHeaderRowNoTranslate
						headerInfoRow={<Link 
							to={`/classes/${section.id}`}
						><SectionMarker section={section} />
						</Link>}
						actionRow={<React.Fragment>{formatDateLong(poll.minCaptured)}<div className='pageHeaderTop-actionRow-spacer'/> <QuestionPollDetailDropdownMenu defaultShowStudentResponses={defaultShowStudentResponses} showStudentCardNumbers={showStudentCardNumbers} setReportSetting={this.setReportSetting}/></React.Fragment>}
						borderBottom
					/>
				}
 
				{isInSet &&
					<StickyPageHeader 
						location='setQuestionReport' 						
						header={getQuestionBodyLabel(poll.snapshot)}
						leftBadge={
								<React.Fragment>
									<Icon name='chevron-left' />									
								</React.Fragment>
							}
						leftBadgeClickFunction={() => {this.props.history.goBack()}}						
						actionGroup={isSurvey ? null : <ScorePill typeDot score={questionScore} />}
						pageHeaderNoTranslate
					/>		
				}
 
 				{poll.snapshot.choices &&				

					<div className='questionReport-choiceDetail' onClick={()=>{this.setState({showStudentResponses: !showStudentResponses})}} >						

						{poll.snapshot.choices[0] &&
							<ChoiceDetailBlock
								index={0}								
								body={poll.snapshot.choices[0].body}
								isCorrect={poll.snapshot.choices[0].correct}
								responseCount={responsesAnswerA.length}
								totalResponses={totalResponseCount}
								studentsforChoice={responsesAnswerA}
								isSurvey={isSurvey}
								showStudentResponses={showStudentResponses}
								showStudentCardNumbers={showStudentCardNumbers}
							/>								
						}

						{poll.snapshot.choices[1] &&
							<ChoiceDetailBlock
								index={1}								
								body={poll.snapshot.choices[1].body}
								isCorrect={poll.snapshot.choices[1].correct}
								responseCount={responsesAnswerB.length}
								totalResponses={totalResponseCount}
								studentsforChoice={responsesAnswerB}			
								isSurvey={isSurvey}
								showStudentResponses={showStudentResponses}
								showStudentCardNumbers={showStudentCardNumbers}
							/>
						}

						{poll.snapshot.choices[2] &&
							<ChoiceDetailBlock
								index={2}								
								body={poll.snapshot.choices[2].body}
								isCorrect={poll.snapshot.choices[2].correct}
								responseCount={responsesAnswerC.length}
								totalResponses={totalResponseCount}
								studentsforChoice={responsesAnswerC}								
								isSurvey={isSurvey}
								showStudentResponses={showStudentResponses}
								showStudentCardNumbers={showStudentCardNumbers}
							/>
						}

						{poll.snapshot.choices[3] &&
							<ChoiceDetailBlock
								index={3}								
								body={poll.snapshot.choices[3].body}
								isCorrect={poll.snapshot.choices[3].correct}
								responseCount={responsesAnswerD.length}
								totalResponses={totalResponseCount}
								studentsforChoice={responsesAnswerD}
								isSurvey={isSurvey}
								showStudentResponses={showStudentResponses}
								showStudentCardNumbers={showStudentCardNumbers}
							/>
						}

						<div className='questionReport-choiceDetail-divider' />

						{invalidResponses.length > 0 &&
							<ChoiceDetailBlock								
								body={'Invalid Responses'}
								invalid
								responseCount={invalidResponses.length}
								studentsforChoice={invalidResponses}
								showStudentResponses={showStudentResponses}
								showStudentCardNumbers={showStudentCardNumbers}
							/>
						}

						{missingStudentsList.length >0 &&
							<ChoiceDetailBlock
								body={'Missing'}
								missing
								responseCount={missingStudentsList.length}
								studentsforChoice={missingStudentsList}
								showStudentResponses={showStudentResponses}
								showStudentCardNumbers={showStudentCardNumbers}
							/>
						}						
									
				</div>
				}
			</div>,
			<div className='page-rightPanel' key='rightPanel'>
				<div className='sidePanelContainer'>
					<div className='sidePanel'>
						<Measure onMeasure={(dimensions) => {this.setState({questionReportSidePanelWidth:dimensions.width})}}>
							<div className='questionReport-sidePanel-innerSpacer'/>
						</Measure>
						{questionReportSidePanelWidth &&
							<div className='questionReport-slidePreview' 
								style={{width: `${questionReportSidePanelWidth}px`, height:`${questionReportSidePanelWidth / 1.6}px`}}
								onClick={()=>{this.props.showExpandedReviewSlideModal(poll,processedQuestionResponses)}}
							>

							<div className='questionReport-slidePreview-expandLabel'>							
							  <Icon name='enterFS'/>
							</div>
							<StaticQuestionSlide 
								question={poll.snapshot} 								
								displayWidth={questionReportSidePanelWidth}								
								processedResponses={processedQuestionResponses}
								reportView
								totalResponses={poll.unassignedResponseCount+poll.assignedResponseCount}
							/>
						</div>
 						}
													
						{!isInSet &&
							<React.Fragment>
								
								{!poll.archived &&	
									<Button 
										label='Continue Playing'
										color='blue'		
										onClickFunction={() =>{resumePlayingQuestionPoll(poll)}}
									/>
								}
								{!poll.archived &&	
									<Button 
										disabled={revealInLibraryDisabled}
										name='reveal-library'
										label='Go to Question Detail'
										onClickFunction={() =>{this.props.history.push(`${questionDetailPath}`)}}
									/>		
								}
								<Button 
									name='archive-report'
									label={poll.archived ? 'Unarchive Report' : 'Archive Report'}
									onClickFunction={()=>{this.props.archivePoll(poll)}} 
								/>

								<Button
									name='delete-report'
									label='Delete Report'
									onClickFunction={()=>{this.props.deletePoll(poll)}} 
								/>
							</React.Fragment>
						}
											

					</div>
				</div>
			</div>,
		]
	}
}


export default withRouter(QuestionPollDetail)

		


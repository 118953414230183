import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import FormFieldText from '../../uiKit/FormFieldText'
import Button from '../../uiKit/Button'
import { ensureRecaptcha } from '../recaptchaUtil'

let captchaId

const required = (value) => (value ? undefined : 'Sorry but we are going to need you to tell us that')
const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9.-]/i.test(value) ?
  'Are you sure thats an email?' : undefined)

class EditUserEmailForm extends React.Component {
  constructor() {
    super()
    this.actuallySubmit = this.actuallySubmit.bind(this)
    this.preSubmit = this.preSubmit.bind(this)
    this.reloadCaptcha = this.reloadCaptcha.bind(this)
    this.recaptchaDiv = null
  }

  componentDidMount() {
    this.reloadCaptcha()
  }

  actuallySubmit(formProps) {
    const requestData = {
      ...formProps,
      recaptchaResponse: window.grecaptcha.getResponse(captchaId),
    }
    this.props.onSubmit(requestData)
    window.grecaptcha.reset(captchaId)
  }

  reloadCaptcha(callback) {
    const { handleSubmit, onRecaptchaError } = this.props
    ensureRecaptcha(
      () => {
        captchaId = window.grecaptcha.render(this.recaptchaDiv, {
          sitekey: process.env.REACT_APP_PLICKERS_RECAPTCHA_SITE_KEY,
          tabIndex: 100,
          size: 'invisible',
          callback: handleSubmit(this.actuallySubmit),
        })
        if (callback) callback()
      },
      onRecaptchaError,
    )
  }

  preSubmit() {
    this.props.onPreSubmit()
    if (!window.grecaptcha || captchaId === undefined) {
      this.reloadCaptcha(this.preSubmit)
    } else {
      window.grecaptcha.execute(captchaId)
    }
  }

  render() {
    const {
      handleSubmit,
      submitLoading,
      dirty,
      valid,
    } = this.props
    const isContentOk = dirty && valid

    return (
      <form onSubmit={handleSubmit(this.preSubmit)}>

        <Field
          name='email'
          type='text'
          label='New Email'
          component={FormFieldText}
          placeholder='email@example.com'
          validate={[required, email]}
          autoComplete='false'
          autoFocus
        />

        <Field
          name='currentPassword'
          type='password'
          label='Password'
          component={FormFieldText}
          placeholder='Your current password'
          validate={[required]}
          autoComplete='false'
        />

        <div className='accountForm-actionRow'>
          <Button
            size='xLarge'
            submitLoading={submitLoading}
            label='Update Email'
            color={isContentOk ? 'blue' : 'gray'}
            canSubmit={isContentOk && !submitLoading}
            type='submit'
          />
        </div>

        {/* eslint-disable no-return-assign */}
        <div ref={(div) => this.recaptchaDiv = div} />
        {/* eslint-enable no-return-assign */}
      </form>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    email: ownProps.email,
  },
})

export default connect(mapStateToProps)(reduxForm({
  form: 'editUserEmailForm',
  enableReinitialize: true,
  autoComplete: 'off',
})(EditUserEmailForm))

import React from 'react'
import EditorInsertGIFModalContainer from '../../../containers/richEditor/EditorInsertGIFModalContainer'
import ReactDOM from 'react-dom'
import store from '../../../store.js'
import {stopAllMediaPlayback} from '../../../prosemirror/utils/editorActions/stopAllMediaPlayback'

const modalId='editor-editImageModalContainer'

function closeInsertGIFModal(){
	ReactDOM.render(null, document.getElementById(modalId))
} 

export function showInsertGIFModal(insertPos,isQuestion,questionPos,questionNode) {
	stopAllMediaPlayback()
	store.dispatch({ type: 'HIDE_NOTIFICATION'})
	ReactDOM.render(
		<EditorInsertGIFModalContainer 
		store={store}
		insertPos={insertPos}
		isQuestion={isQuestion}
		questionPos={questionPos}
		questionNode={questionNode}
		closeInsertImageModal={()=>{closeInsertGIFModal()}}
		/>, document.getElementById(modalId))
}
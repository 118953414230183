export function composeSoundDeliveryUrl(cloudinaryID, start, end, isClipped) {
  let cloudinaryDeliveryURL = 'https://res.cloudinary.com/plickers-dev/video/upload/'
  if (process.env.REACT_APP_ENV === 'production') {
    cloudinaryDeliveryURL = 'https://media.plickers.com/video/upload/'
  }
  if (isClipped) {
    cloudinaryDeliveryURL += `so_${start},eo_${end}/`
  }
  cloudinaryDeliveryURL += `${cloudinaryID}`
  return cloudinaryDeliveryURL
}

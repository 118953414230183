import React from 'react'

class FormFieldText extends React.Component{

	render(){	
		const {disabled,input, placeholder, autoFocus, type, size, label, subLabel, autoComplete, fieldLengthLimit, meta: { touched, error }} = this.props		
		return(
			<div className={'formField is--text' + (input.value ? ' is--nonEmpty' : '') + (touched && error ? ' has--error' : '') + (input.name ? ` is--${input.name}` : '') + (size === 'large' ? ' is--large' : '')}>

				{label &&
				<div className='formField-label'>
					{label}
				</div>
				}

				{subLabel &&
				<div className='formField-subLabel'>
					{subLabel}
				</div>
				}

				<input	      
					{...input}
					disabled={disabled}
					type={type}
					className='formFieldText-input'
					placeholder={placeholder}				      
					maxLength={fieldLengthLimit}
					autoComplete={autoComplete}
					autoFocus={autoFocus}
				/>

				{touched && error &&
				<div className='formField-error'>
					{error}
				</div>
				}

			</div>
		)
	}			
}


FormFieldText.defaultProps = {
	touched: false,
	fieldLengthLimit: 524288,
	autoComplete: 'off',
	disabled:false
}

export default FormFieldText
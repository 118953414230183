import _ from 'lodash'
import React from 'react'
import ModalWrapper from '../ModalWrapper'
import AddExternalSigninContainer from '../../../containers/account/AddExternalSigninContainer'
import RemoveExternalSigninContainer from '../../../containers/account/RemoveExternalSigninContainer'

const ExternalSigninAuthModal = (props) => {
  const { hideModal, externalAccountType, operationType } = props
  return (
    <div className='accountAuthentication-modal'>
      <ModalWrapper
        title={`${_.startCase(operationType)} Sign in with ${_.startCase(externalAccountType)}`}
        modalStyle='dark'
        hideModal={hideModal}
        closeBtn={false}
      >
        {operationType === 'add' && (
          <AddExternalSigninContainer
            externalAccountType={externalAccountType}
          />
        )}
        {operationType === 'remove' && (
          <RemoveExternalSigninContainer
            externalAccountType={externalAccountType}
          />
        )}
      </ModalWrapper>
    </div>
  )
}

export default ExternalSigninAuthModal

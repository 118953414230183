const initialState = null

export default function (state = initialState, action) {
	switch (action.type) {
	
	case 'SHOW_NOTIFICATION':
		const timestamp=new Date()
		return {
			...state,
			message:action.message,
			actionWord:action.actionWord,
			actionType:action.actionType,
			button:action.button,
			timestamp:timestamp
		}

	case 'HIDE_NOTIFICATION':
		return null

	case 'LOGOUT':
		return initialState
	
	default:
		return state
	}
}

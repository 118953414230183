import {SetAttrsStep} from '../SetAttrsStep'

export function updateQuestionTemplate(newTemplate) {  
  var tr = window.view.state.tr
  window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
    if(node.attrs.activeslide && node.attrs.activeslide===true){
      tr.setNodeMarkup(pos, null, {...node.attrs,template:newTemplate})
      
      window.view.state.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
        if (questionNode.type.name === "questionImage"||
          questionNode.type.name === "questionAnimatedGIF"||
          questionNode.type.name === "questionVideo"||
          questionNode.type.name === "questionSound"||
          questionNode.type.name === "choiceImage"||
          questionNode.type.name === "choiceAnimatedGIF"||
          questionNode.type.name === "choice"){
        	//tr.setNodeMarkup(questionPos, null, {...questionNode.attrs,template:newTemplate})          
          tr.step(new SetAttrsStep(questionPos,{...questionNode.attrs,template:newTemplate}))
        }
      })
    }
  })
  tr.setMeta("triggerParseDoc", true) 
	return  window.view.dispatch(tr)
}




import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './containers/AppContainer'
import store from './store'
import './styles/scss/App.scss'
import 'react-dates/lib/css/_datepicker.css'
import { createOnStorage } from './tabSyncMiddleware'

const onStorage = createOnStorage(store)
// listen to local storage events for new actions
window.addEventListener('storage', onStorage)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

import api from '../api'
import {showWhitelistErrorModal} from './modals'

 
export function imageSearch(searchTerm,count,offset) {//Bing image search
  	return (dispatch) => api.fetchImageSearchResults(searchTerm,count,offset)
	    .then((response) => {
	    	return response
	    })
	    .catch((error) => {//TODO better error handling
	    	if(error.response && error.response.status ===403){ //403 can occur when exceed queries quota.
	    	}else{
					dispatch(showWhitelistErrorModal('imageSearch'))
				}
			throw error
		})
}


export function gifSearch(searchTerm,limit,offset) {
	return (dispatch) => api.fetchGifSearchResults(searchTerm,limit,offset)
		.then((response) => {
			return response
		})
		.catch((error) => {
			throw error
		})
}

//Fetch all gif details from GIPHY API (when select GIF from search results we fetch all details before inserting)
export function fetchGifById(gifId) {
	return (dispatch) => api.fetchGifById(gifId)
		.then((response) => {
			return response
		})
		.catch((error) => {
			throw error
		})
}
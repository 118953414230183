import React from 'react'
import Button from '../uiKit/Button'
import QuestionCountBadge from '../uiKit/QuestionCountBadge'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'


class ArchivedCell extends React.Component{

	render(){
		const {item,isActive,isSet,handleSingleClick}=this.props
		let title
		let questionCount
		if(isSet){
			questionCount=item.questions.length 
			title=item.name
		}else{
			title=getQuestionBodyLabel(item)
		}

		return(
			<div className={'cellRow is--archived ' + (isActive ? ' is--active':'')} 
				key={item.id} 
				onClick={() =>{handleSingleClick(item)}}				
			>
				<div className="cellRow-content">
					<div className="cellRow-left">
						{isSet &&
							<QuestionCountBadge color="blueLight" questionCount={questionCount} />
						}	
					</div>

					<div className="cellRow-name">
						{title}
					</div>

					<Button size="small" label="Restore" onClickFunction={()=>{this.props.restoreItem(item)}}/>

				</div>
			</div>
		)
	}
}

export default ArchivedCell
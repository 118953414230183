import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import RepoTrashContainer from './RepoTrashContainer'

 
class PrivateRepoTrashContainer extends Component {
 	render() {
		return <RepoTrashContainer repoId={this.props.match.params.id}/>
	}
}





export default withRouter(PrivateRepoTrashContainer)

import api from '../api'
 
export function fetchUserConfig(userId) {
	return (dispatch) => api.fetch(`/users/${userId}/config`)
		.then((response) => {
			dispatch({ type: 'FETCH_USER_CONFIG_SUCCESS', response })
		})
		.catch((error) => {
			return error
		})
}

export function updateUserConfig(data,userId) {
	return (dispatch) => api.put(`/users/${userId}/config`,data)
		.then((response) => {
			dispatch({ type: 'UPDATE_USER_CONFIG_SUCCESS', response })
		})
		.catch((error) => {
			return error
		})
}








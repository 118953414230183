import React from 'react'
import Icon from '../misc/Icons'

class FolderPreviewCell extends React.Component{
	
	render(){
		const {folder}=this.props
		return(
			<div className={'cellRow '}>	
				<div className="cellRow-content">
					<div className="cellRow-left">
						<Icon name={'folder'}/>
					</div>
					<div className="cellRow-name">
						{folder.name}
					</div>
					<div className="cellRow-date" />
				</div>
			</div>
		)
	}
}

export default FolderPreviewCell
import {showNotification} from '../../actions/notifications'
import store from '../../store'
import filter from 'lodash/filter'
import moment from 'moment'


const MAX_NOTIFICATION_COUNT=3 //per symbol
const NOTIFICATION_TIME_PERIOD=60*24*30//resets every 30 days


export function showSymbolNotification(symbol){
	const symbolNotificationHistory = JSON.parse(localStorage.getItem('symbolNotificationHistory')) || []
	let dateFilteredNotifications=filter(symbolNotificationHistory,function (n) { 
		const now=moment()
		const timeAgo=now.diff(n.timestamp,'minutes')
		return timeAgo < NOTIFICATION_TIME_PERIOD })

	const notificationCount=filter(dateFilteredNotifications,{name:symbol.name}).length

	if(notificationCount<MAX_NOTIFICATION_COUNT){
		store.dispatch(showNotification('to insert this symbol quickly.',`Type /${symbol.name} `,'default'))
		dateFilteredNotifications.push({name:symbol.name,timestamp:moment()})
		localStorage.setItem('symbolNotificationHistory', JSON.stringify(dateFilteredNotifications))
	}
}




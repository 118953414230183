
const marks ={
	strong: {
		toDOM() { return ["b"] },
		parseDOM: [{tag: "b"}]
	},
	em: {
		toDOM() { return ["em"] },
		parseDOM: [{tag: "em"}]
	}
}

const subscript = {
	excludes: 'superscript',
	parseDOM: [
		{ tag: 'sub' },
		{ style: 'vertical-align=sub' }
	],
	toDOM: () => ['sub']
}

const superscript = {
	excludes: 'subscript',
	parseDOM: [
		{ tag: 'sup' },
		{ style: 'vertical-align=super' }
	],
	toDOM: () => ['sup']
}

const highlight1 = {
 excludes: 'highlight2 highlight3 highlight4 highlight5 highlight6 highlight7 highlight8',
 parseDOM: [{
		tag: 'span.highlight.highlight--1'}],
	toDOM: () => ['span', {class: 'highlight highlight--1'}]
}

const highlight2 = {
 excludes: 'highlight1 highlight3 highlight4 highlight5 highlight6 highlight7 highlight8',
 parseDOM: [{
		tag: 'span.highlight.highlight--2'}],
	toDOM: () => ['span', {class: 'highlight highlight--2'}]
}

const highlight3 = {
	excludes: 'highlight1 highlight2 highlight4 highlight5 highlight6 highlight7 highlight8',
	parseDOM: [{
		tag: 'span.highlight.highlight--3'}],
	toDOM: () => ['span', {class: 'highlight highlight--3'}]
}

const highlight4 = {
	excludes: 'highlight1 highlight2 highlight3 highlight5 highlight6 highlight7 highlight8',
	parseDOM: [{
		tag: 'span.highlight.highlight--4'}],
	toDOM: () => ['span', {class: 'highlight highlight--4'}]
}

const highlight5 = {
excludes: 'highlight1 highlight2 highlight3 highlight4 highlight6 highlight7 highlight8',
parseDOM: [{
		tag: 'span.highlight.highlight--5'}],
	toDOM: () => ['span', {class: 'highlight highlight--5'}]
}

const highlight6 = {
excludes: 'highlight1 highlight2 highlight3 highlight4 highlight5 highlight7 highlight8',
parseDOM: [{
		tag: 'span.highlight.highlight--6'}],
	toDOM: () => ['span', {class: 'highlight highlight--6'}]
}

const highlight7 = {
excludes: 'highlight1 highlight2 highlight3 highlight4 highlight5 highlight6 highlight8',
parseDOM: [{
		tag: 'span.highlight.highlight--7'}],
	toDOM: () => ['span', {class: 'highlight highlight--7'}]
}

const highlight8 = {
excludes: 'highlight1 highlight2 highlight3 highlight4 highlight5 highlight6 highlight7',
parseDOM: [{
		tag: 'span.highlight.highlight--8'}],
	toDOM: () => ['span', {class: 'highlight highlight--8'}]
}

const underline = {
	parseDOM: [
		{ tag: 'u' },
	],
	toDOM: () => ['u']
}

const EditorMarks={...marks,subscript,superscript,highlight1,highlight2,highlight3,highlight4,highlight5,highlight6,highlight7,highlight8,underline}

export default EditorMarks
import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import {fetchUserByUsername} from '../../actions/user'
import {refetchUserByUsername} from '../../actions/user'
import {fetchReposForAuthor} from '../../actions/repos'
import TopPageHeader from '../../components/pageHeaders/TopPageHeader'
import StickyPageHeader from '../../components/pageHeaders/StickyPageHeader'
import ProfilePic from '../../components/ProfilePic'
import Button from '../../components/uiKit/Button'
import AuthorPageRepoLink from '../../components/authorPage/AuthorPageRepoLink'
import {showUpdateUsernameModal,showEditAuthorDescriptionModal} from '../../actions/modals'
import {showNotification} from '../../actions/notifications'
import {uploadProfileImageToCloudinary} from '../../actions/cloudinary'
import {updateUser} from '../../actions/user'
import filter from 'lodash/filter'
import AuthorLoadingPage from '../../components/authorPage/AuthorLoadingPage'
import AuthorPageRepoLinkLoader from '../../components/authorPage/AuthorPageRepoLinkLoader'
import AuthorSocialMediaShareRow from '../../components/authorPage/AuthorSocialMediaShareRow'


//Page at /username that displays all published repos for an author
const MAX_FILE_SIZE=20000000  
   
class AuthorPageContainer extends Component {
	constructor(){
		super()
		this.handleFileUpload=this.handleFileUpload.bind(this)
		this.state={
			uploadingImage:false,
			pageEventSent:false,
			failedToFetch:false
		}
	}
	
	UNSAFE_componentWillMount() {
		if(!this.props.userProfile){
			if(this.props.currentUser && this.props.currentUser.username !==this.props.match.params.username){
				this.props.fetchUserByUsername(this.props.match.params.username).then((response)=>{
					if(response){
						this.props.fetchReposForAuthor(response.id)
					}else{
						this.setState({failedToFetch:true})
					}
				})
			}
		}
	}

	allDataIsLoaded(props){
		if(props.userProfile && props.repos){
			return true
		}else{
			return false
		}
	}

	componentDidMount(){
		if(!this.state.pageEventSent){
			if(this.allDataIsLoaded(this.props)){
				if(window.analytics){
					window.analytics.page('Author Page',{
						userIsAuthor:this.props.authorIsCurrentUser,
						authorUsername:this.props.userProfile.username,
						authorRepoCount:this.props.repos.length
					})
				}
				this.setState({pageEventSent:true})
			}	
		}
	}


	UNSAFE_componentWillReceiveProps(nextProps) {
		if(!this.state.pageEventSent){
			if(this.allDataIsLoaded(nextProps)){
				if(window.analytics){
					window.analytics.page('Author Page',{
						userIsAuthor:nextProps.authorIsCurrentUser,
						authorUsername:nextProps.userProfile.username,
						authorRepoCount:nextProps.repos.length
					})
				}
				this.setState({pageEventSent:true})
			}
		}
	}

 	handleFileUpload(e){ //for uploading profile picture
		const fileList = e.target.files
		const file = fileList[0]
		if(file && (file.size > MAX_FILE_SIZE)){
			this.props.showNotification('Max image upload is 20mb','Cannot upload image','destroy')
		}else{
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = (event) => {
				this.setState({uploadingImage:true})
				this.props.uploadProfileImageToCloudinary(event.target.result).then((response) => {
					const nowDate=new Date()
					const {currentUser}=this.props
					const version=response.version
					const requestData = {...currentUser,
						'image':`v${version}`,
						'clientModified': nowDate,
					}

					this.props.updateUser(requestData,currentUser.id).then(()=>{				
						this.props.refetchUserByUsername(this.props.match.params.username).then(()=>{
				 				this.setState({uploadingImage:false})
								this.props.showNotification('Profile Picture','Updated','create')
				 			})
					})
				})
			}
		}
	}

	render() {
		const {userProfile,repos}=this.props
		let userIsAuthor=this.props.authorIsCurrentUser
		const {uploadingImage} = this.state
		if(userProfile){	
			const userBio = userProfile.description
			return (
   		 <DocumentTitle title={`${userProfile && userProfile.username ? `${userProfile.username} - Plickers`: 'Plickers'}`}> 	 	
   	 		<React.Fragment>   	 		
    	 		<div className='page page--wideLayout' >
						<div className='page-leftPanel'/>
						<div className={'page-centerPanel authorPage' + (userIsAuthor ? ' authorPage--isAuthor' : ' authorPage--isNotAuthor')}>
							{userProfile &&
								<React.Fragment>
									<TopPageHeader
										location='authorPage'																	
										multiLineHeader
										header={userProfile.username}
										headerInfoRow={
												<React.Fragment>
													<div className='pageHeaderTop--author-headerInfoRow-contents-name'>
														{userProfile.firstName} {userProfile.lastName}											
													</div>
													{userBio && 
													<div className='pageHeaderTop--author-headerInfoRow-contents-bio'>
														{userBio}
													</div>
													}
												</React.Fragment>
											}
										actionRow={
											<React.Fragment> 
												{userIsAuthor &&
													<React.Fragment>																																	
														<input 
															id='file'
															type='file'
															onChange={this.handleFileUpload}
															value={''}
															style={{position:'fixed', opacity: '0', height: '0px', width: '0px', right: '100%', bottom:'100%'}}
															name='uploadProfilePicAuthorPage'	
															accept=".png,.jpg,.jpeg,.tif,.tiff"
														/>	
														<label htmlFor='file'>
															<div className='btn btn--mid btn--white edit-profile-pic'>																											
																{userProfile.image ? 'Edit Profile Pic' : 'Add Profile Pic'} 																															
															</div>
														</label>		
														<Button label={userBio ? 'Edit Creator Bio':'Add Creator Bio'} name='creatorBio' onClickFunction={this.props.showEditAuthorDescriptionModal} />
														<Button label='Edit Username' name='usernameEdit' onClickFunction={this.props.showUpdateUsernameModal} />
													</React.Fragment>
												}																																				
											</React.Fragment>									
										}
										rightBadge={
											<div className='pageHeaderTop--authorPage-profilePicContainer'>								
												{uploadingImage &&											
													<div className='pageHeaderTop--authorPage-profilePic-profilePicUploading-loadingSpinnerContainer'>
														<div className='pageHeaderTop--authorPage-profilePic-profilePicUploading-loadingSpinner' />
													</div>											
												}
												{!uploadingImage &&
													<ProfilePic user={userProfile} size={100} borderRadius={50} />
												}

											</div>
										}
								/>
						
						<StickyPageHeader 
							location='authorPage'
							header={userProfile.username}
						/>							

					</React.Fragment>	
				}	
					
			<div className='authorPage-share'>							
				<div className='authorPage-share-URL'>
					{`${window.location.host}/${userProfile.username}`}
				</div>
				<div className='authorPage-share-socialMediaContainer'>							
			 		<AuthorSocialMediaShareRow author={userProfile} />
				</div>						
			</div>
					<div className={'authorPage-repoList' + (!repos ? ' authorPage-repoList--loading' : '')}>
							
							{repos && repos.map((repo)=>{
								return(									
									<AuthorPageRepoLink 
										key={repo.id}
										repo={repo}
									/>
								)
							})}

							{repos && repos.length === 0 &&
								<div className='authorPage-repoList-empty'>
									{userIsAuthor &&
										<React.Fragment>
										You haven't published any packs yet
										</React.Fragment>
									}
									{!userIsAuthor &&
										<React.Fragment>
										{userProfile.firstName} hasn't published any packs yet										
										</React.Fragment>
									}
									
								</div>
							}

							{!repos && 
								<React.Fragment>
									<AuthorPageRepoLinkLoader />
									<AuthorPageRepoLinkLoader />
									<AuthorPageRepoLinkLoader />
								</React.Fragment>
							}


					</div>



					</div>
													
					<div className='page-rightPanel'/>
				</div>
				</React.Fragment>
			</DocumentTitle>
		)
	}	
	else return <AuthorLoadingPage pageNotFound={this.state.failedToFetch} />
	}
}

 

function mapStateToProps(state,ownProps) {
	let userProfile
	let repos=null
	let authorIsCurrentUser=false
	if(state.user && state.user.username===ownProps.match.params.username){
		userProfile=state.user
		authorIsCurrentUser=true
		repos=filter(state.repos, function(repo) { 
		if(repo.published && repo.role==='author'){
			return repo
			}})
		}else{
			userProfile=state.userProfiles[ownProps.match.params.username]
			if(userProfile){
				repos=state.authorRepos[userProfile.id] 
			}
		}
	return {
		authorIsCurrentUser:authorIsCurrentUser,
		currentUser:state.user,
		userProfile:userProfile,
		repos:repos
	}
}



export default withRouter(connect(
	mapStateToProps,
	{ 
		fetchUserByUsername,
		fetchReposForAuthor,
		showUpdateUsernameModal,
		showNotification,
		uploadProfileImageToCloudinary,
		updateUser,
		refetchUserByUsername,
		showEditAuthorDescriptionModal
	}
)(AuthorPageContainer))

export default function (state = [], action) {
	switch (action.type) {

		case 'FETCH_ARCHIVED_SECTIONS_SUCCESS':
			return action.response

		case 'DELETE_SECTION_SUCCESS':
			const sectionId = action.sectionId
			const indexOfSectionToDelete = state.findIndex(section => {
				return section.id === sectionId
			})
			return [
				...state.slice(0,indexOfSectionToDelete),
				...state.slice(indexOfSectionToDelete + 1)
			]

		case 'LOGOUT':
			return []
			
		default:
			return state
	}
}

export function setChoiceIsTouched(updatedChoiceNode,pos) {
	let tr = window.view.state.tr
	tr.setNodeMarkup(pos, null, {...updatedChoiceNode.attrs,isTouched:true})
	let groupHasIncompleteChoice=false
	let groupNode
	let groupNodePos
	window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {  
		if(node.attrs.activeslide && node.attrs.activeslide===true){
			window.view.state.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
				if(questionNode.type.name === "choiceGroup"){
					groupNode=questionNode
					groupNodePos=questionPos
					window.view.state.doc.nodesBetween(questionPos,questionPos+questionNode.nodeSize, (choiceNode, choicePos) => {
						if(choiceNode.type.name==='choice'){
							if(choiceNode !== updatedChoiceNode && choiceNode.attrs.isTouched===false){
								groupHasIncompleteChoice=true
							}
						}
					})
				}    
			})
		}
	})

	if(!groupHasIncompleteChoice){
		tr.setNodeMarkup(groupNodePos, null, {...groupNode.attrs,choicesComplete:true})
	}
	tr.setMeta("addToHistory", false)//can't undo setting choice touched to true
	window.view.dispatch(tr)
}
import React from 'react'
import Icon from '../misc/Icons'
 
class ExplorerCrossFilterMarketPanelSelectControl extends React.Component{ 
	render(){	
		const {checkbox,checked} = this.props
		return(
			<div onClick={this.props.onSelect} className={'explorerCrossFilterMarketPanelSelectControl ' + (checked ? 'explorerCrossFilterMarketPanelSelectControl--checked' : 'explorerCrossFilterMarketPanelSelectControl--unchecked')}>

				{checkbox &&
					<div className='explorerCrossFilterMarketPanelSelectControl-checkbox'>
						{checked &&
							<Icon name='checkbox-check' />
						}
					</div>
				}

				{/*
				{radio &&
					<div className='publishingFlowSelectControl-radio'>
						{checked &&
							<div className='publishingFlowSelectControl-radio-inner'/>
						}
					</div>
				}
				*/}

				
				<div className='explorerCrossFilterMarketPanelSelectControl-label'>
					{this.props.label}					
				</div>
							

			</div>
			
		)	
	}
				
}

export default ExplorerCrossFilterMarketPanelSelectControl


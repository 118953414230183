import React, { Component } from 'react'
import RepoReleaseModalHeader from './RepoReleaseModalHeader'
import RepoReleaseModalVisual from './RepoReleaseModalVisual'
import RepoReleaseModalBottom from './RepoReleaseModalBottom'
import { Transition, animated, config } from  'react-spring/renderprops'


class RepoReleaseModal extends Component {
	
	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden' //prevent scrolling while modal is open
	}

 
	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}
  

	render() {
		const {service}=this.props

		const showVisual = true
		const showButton = true
		const showLeftDots = true
		const showRightDots = true

		const delayH1 = '500'
		const delayH2 = '1550'
		const delayVisual = '2100'

		const delayTB1 = '3000'
		const delayTB2 = '3400'
		const delayTB3 = '3800'
		
		const delayLeftDots = '4600'
		const delayRightDots = '4800'

		//const delayButton = '6500'
		let product = 'free'
		if(service && service.product==='plus'){
			product = 'pro' 
		}


		return ( 
			<div className='repoReleaseModalContainer' >				

				<div className='repoReleaseModal marketing'>
					
					<RepoReleaseModalHeader
						product={product}
						delayH1 = {delayH1}
						delayH2 = {delayH2}					
						delayTB1 = {delayTB1}
						delayTB2 = {delayTB2}
						delayTB3 = {delayTB3}
					/>

			

					
									
					<Transition
						native
						items={showLeftDots}
						delay={delayLeftDots}
						config={{tension: 360, friction: 180}}
						from={{opacity: 0}}
						enter={{opacity: 1}}
						leave={{opacity: 0}}
					>
						
						{showVisual =>
							showVisual && (
								props =>									
									<animated.div style={props} className='repoReleaseModal-leftDots'>
										<svg width="57" height="214" viewBox="0 0 57 214">
										  <g fill="none" fillRule="evenodd">
										    <path stroke="#167BF0" strokeDasharray="4 4" strokeLinecap="square" d="M3.36370044,1 C26.7643986,16.0692572 38.4647477,36.9058909 38.4647477,63.509901 C38.4647477,103.415916 20.1023128,90.6529227 3.36370044,124.99505 C-5.23883991,142.644633 9.63992661,170.31295 48,208"/>
										    <circle cx="51.695" cy="209" r="5" fill="#167BF0"/>
										  </g>
										</svg>									
									</animated.div>
							)
						}

					</Transition>

					{product === 'pro' &&
						<Transition
							native
							items={showRightDots}
							delay={delayRightDots}
							config={{tension: 360, friction: 180}}
							from={{opacity: 0}}
							enter={{opacity: 1}}
							leave={{opacity: 0}}
						>
							
							{showVisual =>
								showVisual && (
									props =>									
										<animated.div style={props} className='repoReleaseModal-rightDots'>
											<svg width="39" height="111" viewBox="0 0 39 111">
											  <g fill="none" fillRule="evenodd" transform="translate(0 -2)">
											    <path stroke="#167BF0" strokeDasharray="4 4" strokeLinecap="square" d="M11.5909126,103.098811 C0.981270405,110.563451 53.9875085,96.5317906 33.3563139,52.3876359 C13.7363331,10.4071554 6.00790986,-6.02479474 10.1710441,3.0917855"/>
											    <circle cx="5.171" cy="108" r="5" fill="#167BF0"/>
											  </g>
											</svg>									
										</animated.div>
								)
							}

						</Transition>
					}

					<Transition
						native
						items={showVisual}
						delay={delayVisual}
						config={config.molasses}
						from={{opacity: 0, transform: `translateY(${70}px)`}}
						enter={{opacity: 1, transform: `translateY(${0}px)`}}
						leave={{opacity: 0, transform: `translateY(${70}px)`}}
					>
						
						{showVisual =>
							showVisual && (
								props =>
									<animated.div style={props} className='repoReleaseModal-visual' >
										<RepoReleaseModalVisual product={product} />
									</animated.div>
							)
						}

					</Transition>


					<Transition
						native
						items={showButton}
						delay='4700'
						config={config.molasses}
						from={{opacity: 0, transform: `translateY(${70}px)`}}
						enter={{opacity: 1, transform: `translateY(${0}px)`}}
						leave={{opacity: 0, transform: `translateY(${70}px)`}}
					>
						
						{showButton =>
							showButton && (
								props =>
									<animated.div style={props} className='repoReleaseModal-bottom'>
										<RepoReleaseModalBottom 
											buttonDisabled={this.props.buttonDisabled}
											acceptPrivacyPolicy={this.props.acceptPrivacyPolicy}
										/>
									</animated.div>
							)
						}

					</Transition>
				</div>
			</div>					
		)
	}
}


export default RepoReleaseModal




import React from 'react'
import { withRouter} from 'react-router-dom'
import CreatorsListCreatorCell from './CreatorsListCreatorCell'
import CreatorsListDetailPanel from './CreatorsListDetailPanel'
import filter from 'lodash/filter'
  
class CreatorsListSideNavFilter extends React.Component{ 

	render(){

		const {isActive, label, count, name} = this.props
		return( 
			<button 
				onClick={this.props.onClickFunction}
				className={'creatorsList-sideNav-filter ' 
					+ (isActive ? 'creatorsList-sideNav-filter--active' : 'creatorsList-sideNav-filter--inactive')
					+ (label ? ` creatorsList-sideNav-filter--${name}` : '')
				}
			>				
				<div className='creatorsList-sideNav-filter-label'>
					{label}
				</div>
				<div className='creatorsList-sideNav-filter-count'>					
					{count}
				</div>
			</button>
				

		)
	}
}



class CreatorsListPage extends React.Component{ 


	render(){

		const {allCreators,selectedCreator,filterTerm,creatorsWithAwards,inbox,customEmail,febEmailSent,outstandingToPay,outstandingNeedPaypal,fullyPaid}=this.props

		let creatorAwards=[]
		if(selectedCreator && this.props.awards){
			creatorAwards=filter(this.props.awards, function(award) { return award.user === selectedCreator.id})
		}
		let creators=allCreators
		if(filterTerm==='creatorsWithAwards'){
			creators=creatorsWithAwards
		}else if(filterTerm==='inbox'){
			creators=inbox
		}else if(filterTerm==='customEmail'){
			creators=customEmail
		}
		else if(filterTerm==='febEmailSent'){
			creators=febEmailSent
		}
		else if(filterTerm==='outstandingToPay'){
			creators=outstandingToPay
		}
		else if(filterTerm==='outstandingNeedPaypal'){
			creators=outstandingNeedPaypal
		}
		else if(filterTerm==='fullyPaid'){
			creators=fullyPaid
		}


		return( 
			<div className='page creatorsList' >
				<div className='creatorsList-sideNav'>										
					
					{/*
					<CreatorsListSideNavFilter
						label='To Do: Auto Email'
						count={inbox.length}
						isActive={filterTerm==='inbox'}
						onClickFunction={()=>this.props.setFilter('inbox')}
						name='inbox'
					/>
					<CreatorsListSideNavFilter
						label='To Do: Custom Email'
						count={customEmail.length}
						isActive={filterTerm==='customEmail'}
						onClickFunction={()=>this.props.setFilter('customEmail')}
						name='customEmail'
					/>
				*/}

										

					<CreatorsListSideNavFilter
						label='Outstanding Payments'
						count={outstandingToPay.length}
						isActive={filterTerm==='outstandingToPay'}
						onClickFunction={()=>this.props.setFilter('outstandingToPay')}
						name='outstandingToPay'
					/>
					<CreatorsListSideNavFilter
						label='Need Paypal'
						count={outstandingNeedPaypal.length}
						isActive={filterTerm==='outstandingNeedPaypal'}
						onClickFunction={()=>this.props.setFilter('outstandingNeedPaypal')}
						name='outstandingNeedPaypal'
					/>																		
					
					<CreatorsListSideNavFilter
						label='Fully Paid'
						count={fullyPaid.length}
						isActive={filterTerm==='fullyPaid'}
						onClickFunction={()=>this.props.setFilter('fullyPaid')}
						name='fullyPaid'
					/>

					<div className='creatorsList-sideNav-spacer'/>		

					<CreatorsListSideNavFilter
						label='All Creators'
						count={this.props.allCreators.length}
						isActive={filterTerm==='allCreators'}
						onClickFunction={()=>this.props.setFilter('allCreators')}
						name='allcreators'
					/>

					<CreatorsListSideNavFilter
						label='Creators w/Awards'
						count={creatorsWithAwards.length}
						isActive={filterTerm==='creatorsWithAwards'}
						onClickFunction={()=>this.props.setFilter('creatorsWithAwards')}
						name='creatorsWithAwards'
					/>

					<div className='creatorsList-sideNav-spacer'/>			

					<CreatorsListSideNavFilter
						label='RP Email Sent'
						count={febEmailSent.length}
						isActive={filterTerm==='febEmailSent'}
						onClickFunction={()=>this.props.setFilter('febEmailSent')}
						name='febEmailSent'
					/>

					

					
			
				</div>
				
				<div className='creatorsList-centerPanel' onKeyDown={this.props.keyPressNavigation} tabIndex='-1' id={'focusablePackReviewTable'}>
					
					
					{creators.map((creator)=>{
						return(
							<CreatorsListCreatorCell 
								creator={creator}
								setSelectedCreator={this.props.setSelectedCreator}
								selectedCreator={this.props.selectedCreator}
								key={creator.id} 
							/>
							)


					})}					
				</div>

				<div className='packReview-detailPanel'>
					{this.props.selectedCreator && 
						<CreatorsListDetailPanel	
							creator={this.props.selectedCreator}						
							adminSetCreatorMetadataToEmailSent={this.props.adminSetCreatorMetadataToEmailSent}
							creatorAwards={creatorAwards}
							activateAward={this.props.activateAward}
							adminSetCreatorToCustomEmail={this.props.adminSetCreatorToCustomEmail}
							adminPayActivatedAwards={this.props.adminPayActivatedAwards}

						/>
				}

				</div>

		</div>
				

		)
	}
}


export default withRouter(CreatorsListPage)
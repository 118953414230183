import {updateQuestionOrderTransaction} from '../updateQuestionOrderTransaction'


export function deleteActiveQuestion(onDeleteActiveQuestion){
	const state=window.view.state
	let questionPos
	let questionNode
	let questionCount=0
	let oldOrderArray
	state.doc.nodesBetween(0,state.doc.content.size, (node, pos) => {
    	if (node.type.name === "question"){
    		questionCount+=1
    		if(!oldOrderArray){
    			oldOrderArray=node.attrs.questionOrderArray
    		}
    	}	
	    if(node.attrs.activeslide && node.attrs.activeslide===true){
	     questionPos=pos
	     questionNode=node
	     // activeQuestionIndex=questionCount-1
	    }
  })
	if(questionCount>1){
		let tr = window.view.state.tr
		tr.delete(questionPos,questionPos+questionNode.nodeSize) //delete node
		tr.setMeta("triggerParseDoc", true) 	
		const deletedQuestionId=questionNode.attrs.questionid		
		let newArray=[]
    let deletedQuestionIndex	    
		oldOrderArray.forEach((questionId,i)=>{
			if(questionId!==deletedQuestionId){
				newArray.push(questionId)
			}else{
				deletedQuestionIndex=i
			}
		})
		let newActiveQuestionId
		if(deletedQuestionIndex<newArray.length-1){
			newActiveQuestionId=newArray[deletedQuestionIndex]
		}else{
			newActiveQuestionId=newArray[newArray.length-1]
		}
		tr=updateQuestionOrderTransaction(tr,newArray)
		tr.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if (node.type.name === "question"){
				if(node.attrs.questionid===newActiveQuestionId){
					tr.setNodeMarkup(pos, null, {...node.attrs,activeslide:true})
			}
				else{
					tr.setNodeMarkup(pos, null, {...node.attrs,activeslide:false})
				}
		}})     
		window.view.dispatch(tr)
		onDeleteActiveQuestion(newActiveQuestionId)  
	}
}

import api from '../api'
import {fetchAPublishedRepo} from './repos'

export function createReview(data) {
	return (dispatch) => api.post('/reviews/',data)
		.then((response) => {
			dispatch({ type: 'CREATED_REVIEW_SUCCESS', response})
			dispatch(fetchAPublishedRepo(data.repo))
			return response
		})
		.catch(err => {})
}

export function fetchReviewsForUser() {
	return (dispatch) => api.fetch('/reviews')
		.then((response) => {
			dispatch({ type: 'FETCH_REVIEWS_SUCCESS', response})
		})
		.catch(err => {})
}
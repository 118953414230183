import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import find from 'lodash/find'
import { connect } from 'react-redux' 
import RepoContainer from './RepoContainer'
import {fetchAPublishedRepoByShortId} from '../../actions/repos'
import {getLinkForRepo} from '../../utils/getLinkForRepo'
import RepoLoadingPage from '../../components/repo/RepoLoadingPage'


//signature is repodescription-shortId
function repoShortIdFromSignature(signature){
	const signatureComponentsArray=signature.split(/-/)
	const shortId=signatureComponentsArray[signatureComponentsArray.length-1]
	return shortId

}


 
 
class RepoConsumerViewContainer extends Component {

	constructor(){
		super()
		this.state={
			failedToFetch:false,
		}
	} 

	UNSAFE_componentWillMount() {
		if(!this.props.repo){
			this.props.fetchAPublishedRepoByShortId(this.props.repoShortId)
				.catch((error)=>{
					this.setState({failedToFetch:true})
				})
		}
	}

	componentWillReceiveProps(nextProps){
		if(this.props.repo && nextProps.repo && nextProps.repo !==this.props.repo){
			if(getLinkForRepo(nextProps.repo) !== getLinkForRepo(this.props.repo)){
				this.props.history.push(`${getLinkForRepo(nextProps.repo)}`)
			}
		}

		if(nextProps.repoShortId === this.props.repoShortId && this.props.repo && !nextProps.repo){
			this.props.history.push('/library') //unpublished!
		}

	}


	render() {
		if(this.props.repo){
			return <RepoContainer repoId={this.props.repoId}/>		
		}else return <RepoLoadingPage isPublicConsumer pageNotFound={this.state.failedToFetch} />
	

	}
}




function mapStateToProps(state,ownProps) {
	
	const repoSignature=ownProps.match.params.repoSignature
	const repoShortId=repoShortIdFromSignature(repoSignature)


	const currentRepo=find(state.repos, {'shortId':parseInt(repoShortId,10)})
	let repoId
	if(currentRepo){
		repoId=currentRepo.id
	}
	return {
	    repo:currentRepo,
	    repoId:repoId,
	    repoShortId:repoShortId
	  }
}
 


export default withRouter(connect(
	mapStateToProps,
	{fetchAPublishedRepoByShortId })(RepoConsumerViewContainer))

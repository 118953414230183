export function getLinkForRepo(repo) {
	if(repo){
		if(repo.published){
		let username='-'
		if(repo.author && repo.author.username){
			username=repo.author.username	
		}
		const formattedUsername=username.replace(/ /g,'-')
		let route = `/${formattedUsername}/`
		const repoName=repo.name 
		const formattedRepoName=repoName.replace(/[º#&=:*·\\/?;|{}"<>%~[\]` ]/g,'-')
		route += `${formattedRepoName}-${repo.shortId}`
		return route
	}else {
		const route=`/packs/${repo.id}`
		return route

	}
	}else return null
	
}  
import api from '../api'

//used to show user the link to Amazon cards in their country
export function fetchGeoInfo() {
	return (dispatch) => api.fetch('/geo-info')
		.then((response) => {
			dispatch({ type: 'FETCH_GEO_INFO_SUCCESS', response})
		})
		.catch((error) => {
			return error
		}
	)
}
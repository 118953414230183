export default function calculateInitialCropBoxDimensions(crop,imageDimensions){
	let cropBoxWidth = imageDimensions.imageContainerWidth
	let cropBoxHeight= imageDimensions.imageContainerHeight
	let cropBoxX=imageDimensions.imageContainerXOffset
	let cropBoxY=imageDimensions.imageContainerYOffset
	
	if(crop){	
		const imageScale = imageDimensions.imageScale	
		cropBoxWidth = Math.round(crop.w * imageScale)
		cropBoxHeight = Math.round(crop.h * imageScale)	
		cropBoxX = crop.x * imageScale + imageDimensions.imageContainerXOffset
		cropBoxY = crop.y * imageScale + imageDimensions.imageContainerYOffset			
	}

	return {
		cropBoxX:cropBoxX,
		cropBoxY:cropBoxY,
		cropBoxWidth:cropBoxWidth,
		cropBoxHeight:cropBoxHeight
	}

}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { fetchOrder, applyOrder } from '../../actions/order'
import Button from '../../components/uiKit/Button'
import { showNotification } from '../../actions/notifications'
import FormFieldText from '../../components/uiKit/FormFieldText'

class AddLicenseKeyForm extends React.Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <Field
          name="licenseKey"
          label={false}
          size="large"
          component={FormFieldText}
          placeholder="License Key"
          autoFocus
          autoComplete="false"
        />

        <div className="addLicenseKeyModal-actionRow">
          <Button
            size="xLarge"
            color={this.props.dirty && this.props.valid ? 'blue' : 'gray'}
            submitLoading={this.props.submitLoading}
            label="Add License Key"
            canSubmit={!!(this.props.dirty && this.props.valid && !this.props.submitLoading)}
            type="submit"
            name="submitLicense"
          />
          <Button
            size="xLarge"
            color="white"
            label="Cancel"
            onClickFunction={this.props.hideModal}
          />
        </div>
      </form>
    )
  }
}

const AddLicenseKeyReduxForm = reduxForm({
  form: 'AadLicenseKeyForm',
  enableReinitialize: true,
  autoComplete: 'off',
})(AddLicenseKeyForm)

class AddLicenseKeyContainer extends Component {
  constructor(props) {
    super(props)
    this.applyLicenseKey = this.applyLicenseKey.bind(this)

    this.state = {
      submitLoading: false,
      failedToFetchOrder: false,
      failedToApplyOrder: false,
      errorMessage: null,
    }
  }

  UNSAFE_componentWillMount() {
    document.body.style.overflowY = 'hidden'
  }

  componentDidMount() {
    if (window.analytics) {
      window.analytics.page('Add License Key Modal')
    }
  }

  componentWillUnmount() {
    document.body.style.overflowY = '' // overlay is not supported by firefox, so default to blank (auto style comes from css)
    document.body.style.overflowY = 'overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
  }

  applyLicenseKey(formData) {
    this.setState({ submitLoading: true })
    if (window.analytics) {
      window.analytics.track('Click claim membership', {
        method: 'Add license key',
      })
    }

    this.props.fetchOrder(formData.licenseKey).then((response) => {
      const orderId = response.id
      this.props.applyOrder(orderId, formData.licenseKey).then(() => {
        this.props.hideModal()
        this.props.showNotification('Plickers Pro has been activated on your account', 'Successfully joined order –', 'default')
        if (window.analytics) {
          window.analytics.track('Successfully Claimed Membership', {
            method: 'Add license key',

          })
        }
      })
        .catch((error) => {
          if (window.analytics) {
            const { status } = error.response

            window.analytics.track('Error claim membership', {
              method: 'Add license key',
              error: 'Failed to apply order',
              status,
            })
          }

          let errorMessage = 'Error Processing Order: please contact help@plickers.com or your system adminstrator'
          if (error.response.status === 409) {
            errorMessage = 'Error Processing Order: the order is already applied to your account '
          } else if (error.response.status === 422) {
            errorMessage = 'Error Processing Order: the group order is full. Please contact your administrator for additional seats.'
          }

          this.setState({ submitLoading: false, failedToApplyOrder: true, errorMessage })
        })
    })
      .catch(() => {
        if (window.analytics) {
          window.analytics.track('Error claim membership', {
            method: 'Add license key',
            error: 'Failed to fetch order',
          })
        }

        this.setState({ failedToFetchOrder: true, submitLoading: false })
      })
  }

  render() {
    const { failedToFetchOrder, failedToApplyOrder, submitLoading } = this.state
    return (
      <div className="addLicenseKeyModal">
        <div className="addLicenseKeyModal-header">
          Add License Key
        </div>

        <div className="addLicenseKeyModal-sub">
          Enter the 10-digit license key provided by your administrator.
        </div>

        <AddLicenseKeyReduxForm
          onSubmit={this.applyLicenseKey}
          hideModal={this.props.hideModal}
          submitLoading={submitLoading}
        />

        <a className="addLicenseKeyModal-link" target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_PLICKERS_FOR_SCHOOLS_URL}>
          Learn more about getting Plickers Pro for your department, school, or district
        </a>

        <div className={`addLicenseKeyModal-errorSpace ${(failedToFetchOrder || failedToApplyOrder) ? 'addLicenseKeyModal-errorSpace--hasError' : ''}`}>
          {failedToFetchOrder && (
          <span>
            License key is invalid. Check and try again. If you're still having trouble contact help@plickers.com or your system adminstrator
          </span>
          )}
          {failedToApplyOrder && (
          <span>
            {this.state.errorMessage}
          </span>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(connect(
  (state) => ({
    order: state.order,

  }),
  { fetchOrder, applyOrder, showNotification },
)(AddLicenseKeyContainer))

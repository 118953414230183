export default function (state=[], action) {
	switch (action.type) {

	case 'FETCH_RECENT_ACTIVITIES_SUCCESS':
		return action.response


	case 'UPDATE_SET_SUCCESS' :
	case 'UPDATE_QUESTION_SUCCESS' :

		if(action.response.archived===true){ //if archived a question,  remove the associated recent activity
			const indexOfRecentActivityToRemove = state.findIndex(recentActivity => {
				return recentActivity.item=== action.response.id
			})
			if(indexOfRecentActivityToRemove!==-1){
			return [
				...state.slice(0,indexOfRecentActivityToRemove),
				...state.slice(indexOfRecentActivityToRemove + 1)
			]
		}else return state
		}	else return state




	case 'UPDATE_RECENT_ACTIVITIES_SUCCESS':

 
		const updatedItemId = action.response.item
		 const indexOfItemToUpdate = state.findIndex(activityItem => {
			return activityItem.item=== updatedItemId
		})
		 if(indexOfItemToUpdate===-1){

			 return [action.response,...state]
		 }else{
		 	
			return [action.response,
				...state.slice(0,indexOfItemToUpdate),
				...state.slice(indexOfItemToUpdate + 1)
			]}
			




	case 'LOGOUT':
		return []

	default:
		return state

	}
}
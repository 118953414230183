import React from 'react';
import EditorIcon from '../../../misc/EditorIcons'
import slideLayoutDefinitions from '../../../../staticData/slideLayoutDefinitions.js'
import ChoiceVideoControlBar from './ChoiceVideoControlBar'

class ChoiceVideoReactComponent extends React.Component{  

  render(){ 

    const {thumbnail, videoTitle, videoDuration, start, end, isClipped, questionChoiceCount,slideWidth} = this.props

    // const slideWidth = this.state.slideWidth 
    const slideScale = slideWidth / 1280
    const inverseScale = 1 / slideScale

    const ceilingInverseScale = 1.55 

    const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)
    
    const mediaWidth = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${questionChoiceCount}Choices`].width
    const mediaHeight = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${questionChoiceCount}Choices`].height

    const mediaInternalSidePadding = 5 // pass in from slide definitions?

    const thumbnailContainerWidth = mediaWidth - mediaInternalSidePadding
    
    const thumbnailURL = thumbnail.src
    
    // const thumbnailWidth = thumbnail.w
    // const thumbnailHeight = thumbnail.h    
    const thumbnailWidth = 1280 // better to zoom-fit 4:3 weird aspect ratio than customize layout
    const thumbnailHeight = 720 // better to zoom-fit 4:3 weird aspect ratio than customize layout

    const thumbnailContainerHeight = mediaHeight // should come from template definitions or somewhere
      
    const thumbnailAspect = thumbnailWidth / thumbnailHeight
    const containerAspect = thumbnailContainerWidth / thumbnailContainerHeight

    let fitThumbnailWidth
    let fitThumbnailHeight
    // let isPortrait
      
    if(thumbnailAspect >= containerAspect){
      //landscape     
      fitThumbnailWidth = thumbnailContainerWidth
      fitThumbnailHeight = thumbnailContainerWidth / thumbnailAspect
    }

    if(thumbnailAspect < containerAspect){
      //portrait
      fitThumbnailHeight = thumbnailContainerHeight
      fitThumbnailWidth = thumbnailContainerHeight * thumbnailAspect     
      // isPortrait = true
    }

    //const duration = video.end - video.start
    
    // easier to just set custom top positions
    let topThumbnailOffset = 48
    let heightControlBar = mediaHeight - fitThumbnailHeight - topThumbnailOffset 
    let showDarkTopControls = false

    if(questionChoiceCount === 3){
      topThumbnailOffset = 0
      heightControlBar = mediaHeight - fitThumbnailHeight - topThumbnailOffset
      showDarkTopControls = true
      // showDarkTopControls = false
    }
    if(questionChoiceCount === 2){
      topThumbnailOffset = 0
      heightControlBar = 50
      showDarkTopControls = true
    }    



	  return (

	  		<div className='slide-choice-media slide-choice-media--video'>                      

          <div onClick={this.props.showEditVideoModal} className='slide-choice-media--video--static-overlay'>
            <div style={{width: `${fitThumbnailWidth}px`, height:`${fitThumbnailHeight}px`, top: `${topThumbnailOffset}px`}} className='slide-choice-media--video--static-overlay-internalViewSpacer'>            
              <div className='slide-choice-media--video--static-overlay-playIconContainer'>
                <EditorIcon name='playbackPlay' />
              </div>
            </div>
          </div>

          <div style={{width: `${fitThumbnailWidth}px`, height:`${fitThumbnailHeight}px`, top: `${topThumbnailOffset}px`}} className='slide-choice-media--video--static-thumbnailContainer'>
            <img
              onLoad={this.onLoad}
              src={thumbnailURL}
              className='slide-choice-media--video--static-thumbnail'   
              alt=''            
            />
          </div>
          
          <div style={{transform: `scale(${UIInverseScale})`, height:`${heightControlBar / UIInverseScale}px`, width: `${thumbnailContainerWidth / UIInverseScale}px`}} className='slide-media-choice--video-controlBarContainer'>                            
            <ChoiceVideoControlBar 
              startTime={start} // refactor
              endTime={end} // refactor
              isClipped={isClipped}
              duration={videoDuration}
              isPlaying={false}                                                    
              showInfo={true} // for nowPlaying, false
              overlayMode={questionChoiceCount === 2}
              videoTitle={videoTitle}                  
            />                
          </div>                                    

          {/* REMOVE BTN */}
          <div style={{transform:`scale(${UIInverseScale})`}} className='slide-choice-media--video-topRight'>            
            <button onClick={this.props.deleteVideo} className={'slide-choice-media-smallBtn slide-choice-media--video-close' + (showDarkTopControls ? ' slide-choice-media-smallBtn--dark' : '')}>
              <EditorIcon name='close' />
            </button>                        
          </div>

          
      
        </div>
        

	     	 
	  )
	}
}


export default ChoiceVideoReactComponent




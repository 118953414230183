import React, { Component } from 'react'
import { connect } from 'react-redux'
import {createPoll} from '../../utils/createPoll'
import { showNotification } from '../../actions/notifications'
import {removeFromQueue} from '../../utils/removeFromQueue'
import {sortLibraryData} from '../../utils/sortLibraryData'
import {getLibrarySettings} from '../../utils/getLibrarySettings'
import filter from 'lodash/filter'
import find from 'lodash/find'
import PropTypes from 'prop-types'
import Dropdown from './Dropdown'
import DropdownItem from './DropdownItem'
import DropdownDivider from './DropdownDivider'
import moment from 'moment'


 

class AddToQueueDropdown extends Component {

	constructor(){
		super()
		this.addToQueue=this.addToQueue.bind(this)
		this.removeFromQueue=this.removeFromQueue.bind(this)
		this.batchAddToQueue=this.batchAddToQueue.bind(this)
	}

	addToQueue(section){
		if(!this.props.multiSelectItems){
			this.props.createPoll(this.props.item,section.id)
		}else{
			this.batchAddToQueue(section)
		}
	
	}

	removeFromQueue(poll){
		this.props.removeFromQueue(poll)
	}

	batchAddToQueue(section){
		const {multiSelectItems}=this.props
		let items = []
		Object.keys(multiSelectItems).map((key)=>{
			const item=multiSelectItems[key]
			items.push(item)
			return null
		})

		const librarySettings=getLibrarySettings()
		items=sortLibraryData(librarySettings.sortType,librarySettings.sortOrder,items)
		let plannedTime=moment()
		items.map((item)=>{
			this.props.createPoll(item,section.id,true,plannedTime)
			plannedTime=plannedTime.add(1,'millisecond')
			return null
		})
		this.props.showNotification(`${Object.keys(multiSelectItems).length} items to ${section.name} queue`,'Added','default')
	

	}





 
	render() {
		
		const {sections,dropdownButton,inverted, queuePolls} = this.props
		if(sections && queuePolls){
			return( 
				<Dropdown position='center' inverted={inverted} dropdownButton={dropdownButton}>	       	
					<DropdownDivider label='Add to' atDropdownTop />
					{sections.map((section,i) =>         				
						<DropdownItem itemType={'AddToClass'} section={section} key={i} onSelect={()=>{this.addToQueue(section)}}/>	     
					)}
					{queuePolls.length >0 &&
					<DropdownDivider label='Currently in' />
					}
					{queuePolls.map((poll,i) =>   {  
						const section=find(sections, {'id':poll.section})
						if(section){
							return(    					        				
								<DropdownItem itemType={'RemoveFromClass'} section={section} key={i} onSelect={()=>{this.props.removeFromQueue(poll)}}/>	 
							)}
						else return null
					})}
				</Dropdown>		
			)} else return null
	}
}


function mapStateToProps(state,ownProps) {
	let queuePolls=[]
	if(ownProps.item){
		if(ownProps.item.questions){
		 queuePolls=filter(state.queueSetPolls,{'setObject':ownProps.item.id})
		}
		else {
			queuePolls=filter(state.queueQuestionPolls,{'question':ownProps.item.id})
		}
	}
	return {
		sections:state.sections,
		queuePolls:queuePolls		
	}
}


export default connect((state) => (
	mapStateToProps
),
{ createPoll,removeFromQueue,showNotification}
)(AddToQueueDropdown)

AddToQueueDropdown.propTypes = {	
	dropDownButton: PropTypes.node,
	item:PropTypes.object

}

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_DISCOUNTS_SUCCESS':
		return action.response
	
	case 'LOGOUT':
		return []
	
	default:
		return state
	}
}

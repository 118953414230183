import React from 'react'
import { Spring, animated } from 'react-spring/renderprops' 
import {formatDurationTimestamp} from '../../../utils/formatDurationTimestamp'
import EditorIcon from '../../misc/EditorIcons'
import Slider, { SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import {composeSoundWaveformUrl} from '../../../prosemirror/utils/composeSoundWaveformUrl'

const { Handle } = Slider

const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="slide-media--sound-player-seekBar-tooltip"
      overlay={`${value}`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  )
} 

const windowZoomItemInternalPadding = 20 // PASS IN FROM SLIDE DEFINITIONS

function calculateItemLayout(nowPlayingLayout,template){
      // VIEWPORT DIMENSIONS
    const viewportWidth = nowPlayingLayout.viewportWidth
    const viewportHeight = nowPlayingLayout.viewportHeight
    const viewportAspect = viewportWidth / viewportHeight
    const slideWidth = nowPlayingLayout.slideDimensions.width
    const slideScale = slideWidth / 1280
    const inverseScale = 1 / slideScale
    //
    // START ZOOM ITEM DIMENSIONS

    // Find position of original on-slide sound container
    const soundZoomElement = document.getElementById("soundPlayerZoomable")

    let rect
    if(soundZoomElement){
      rect = soundZoomElement.getBoundingClientRect()
    }
   
    let itemStartZoomWidth = 0
    let itemStartZoomHeight = 0
    let itemStartZoomX = 0
    let itemStartZoomY = 0

    if(rect){
      itemStartZoomWidth = rect.width
      itemStartZoomHeight = rect.height
      itemStartZoomX = rect.x
      itemStartZoomY = rect.y
    }

    const itemAspect = itemStartZoomWidth / itemStartZoomHeight
   
    //
    // END ZOOM ITEM DIMENSIONS    

      // find maximum available width and height
      const itemEndZoomMaxWidth = viewportWidth - windowZoomItemInternalPadding - windowZoomItemInternalPadding
      const itemEndZoomMaxHeight = viewportHeight - windowZoomItemInternalPadding - windowZoomItemInternalPadding

      // find actual fitting item width and height
      let itemEndZoomWidth 
      let itemEndZoomHeight

      if(viewportAspect >= itemAspect){ // screen wider than item
        itemEndZoomHeight = itemEndZoomMaxHeight
        itemEndZoomWidth = itemEndZoomHeight * itemAspect
      }
      if(viewportAspect < itemAspect){ // screen narrower than item
        itemEndZoomWidth = itemEndZoomMaxWidth
        itemEndZoomHeight = itemEndZoomWidth / itemAspect
      }

      // find end-state X and Y, this is to viewport
      const itemEndZoomX = (viewportWidth - itemEndZoomWidth) / 2
      const itemEndZoomY = (viewportHeight - itemEndZoomHeight) / 2


    //
    // ZOOM START --> ZOOM END
    
    // SCALE FACTOR
    const itemEndZoomScale = itemEndZoomWidth / itemStartZoomWidth

    // TRANSFORM ORIGIN (this effectively translates position of slide)
    const transformOriginX = (itemStartZoomX - itemEndZoomX) / (itemEndZoomWidth - itemStartZoomWidth)
    const transformOriginY = (itemStartZoomY - itemEndZoomY) / (itemEndZoomHeight - itemStartZoomHeight)
    
    
    //
    // STYLING IS FIXED
    // zooming also repositions slide through transform origin location

    const itemStyle = {
      width: `${itemStartZoomWidth}px`, 
      height: `${itemStartZoomHeight}px`,
      left: `${itemStartZoomX}px`,
      top: `${itemStartZoomY}px`,             
      transformOrigin: `${transformOriginX * 100}% ${transformOriginY * 100}%`,
    }

    const playerStyle = {
      width: `${itemStartZoomWidth * inverseScale}px`, 
      height: `${itemStartZoomHeight * inverseScale}px`,
      transform: `scale(${slideScale})`,  
    }


    //
    // Except for wide player, we do a inverse scale on top and bottom so buttons don't get too big
    let playScaleSectionInverseScale = 1.75 / itemEndZoomScale
    let widePlayer = false

    if(template === 'bodyCenterMediaCenter'){
      playScaleSectionInverseScale = 1
      widePlayer = true 
    }
    return{
      itemStyle:itemStyle,
      playerStyle:playerStyle,
      itemEndZoomScale:itemEndZoomScale,
      playScaleSectionInverseScale:playScaleSectionInverseScale,
      widePlayer:widePlayer
    }
} 





class LiveViewPromptSoundPlayer extends React.Component{

  
  constructor(props) {
    super(props)
    this.playSound=this.playSound.bind(this)
    this.pauseSound=this.pauseSound.bind(this)
    this.startTimer=this.startTimer.bind(this)
    this.seekTo=this.seekTo.bind(this)
    this.restartAudio=this.restartAudio.bind(this)
    this.playbackSkip=this.playbackSkip.bind(this)
    this.stopTimer=this.stopTimer.bind(this)
    this.updateControlPlayback=this.updateControlPlayback.bind(this)
    this.skipPlayerCurrentTime=this.skipPlayerCurrentTime.bind(this)
    this.calculateLayout=this.calculateLayout.bind(this)

    const url = `${props.sound.deliveryUrl}.mp3`

    this.audioElement = new Audio(url)

    this.audioElement.addEventListener("ended", function(){
      this.audioElement.currentTime = 0
      this.setState({isPlaying:false,playerCurrentTime:0})
      this.updateControlPlayback(false)
      this.stopTimer()
    }.bind(this))

    this.timer=null


    let waveformImageURL
    if(props.showWaveform){
      waveformImageURL=composeSoundWaveformUrl(props.sound.fileId,props.sound.start,props.sound.isClipped)
    }
    this.state = {
      isPlaying:false,
      playerCurrentTime:0,      
      slideWidth:'',
      duration:props.sound.end-props.sound.start,
      waveformImageURL:waveformImageURL,
      itemStyle:{},
      playerStyle:{},
      itemEndZoomScale:0,
      playScaleSectionInverseScale:0,
      widePlayer:false
    
    }  
}
  componentDidMount(){
    this.calculateLayout()
  }

  componentDidUpdate(prevProps) {
    if ((this.props.layoutKey !== prevProps.layoutKey) || (this.props.showStudentList!==prevProps.showStudentList)) {
      this.calculateLayout()
     }    
  }

  calculateLayout(){    
    const itemLayout=calculateItemLayout(this.props.nowPlayingLayout,this.props.template)
    this.setState({
      widePlayer:itemLayout.widePlayer,
      itemStyle:itemLayout.itemStyle,
      playerStyle:itemLayout.playerStyle,
      itemEndZoomScale:itemLayout.itemEndZoomScale,
      playScaleSectionInverseScale:itemLayout.playScaleSectionInverseScale})
  }

 UNSAFE_componentWillReceiveProps(nextProps) {
     if(nextProps.playbackItem==='promptMedia' && this.props.clientPlaybackCommand===null && nextProps.clientPlaybackCommand){
      this.props.clearPlaybackCommand()
      if(nextProps.clientPlaybackCommand.command==='skip forward'){
        this.skipPlayerCurrentTime('forwards',nextProps.clientPlaybackCommand.length/1000)
      }
      else{
        this.skipPlayerCurrentTime('backwards',nextProps.clientPlaybackCommand.length/1000)
      }
    }

    if(nextProps.playbackItem==='promptMedia'){
      if(nextProps.isPlaying && this.props.pageVisibilityState==='visible' && nextProps.nowPlayingModalVisible===true){
        if(!this.state.isPlaying){
          this.playSound(true)
        }
      }else{
        if(this.state.isPlaying){
          this.pauseSound(true)
        }
      }
    }
    if(nextProps.playbackItem !=='promptMedia'){
      if(this.state.isPlaying){
        this.pauseSound(true)
      }
    }
    if(this.props.pageVisibilityState==='visible' && nextProps.pageVisibilityState==='hidden'){
      if(this.state.isPlaying){
        this.pauseSound()
      }
    }
  }

  skipPlayerCurrentTime(skipDirection,skipAmount){
    const currentTime=this.audioElement.currentTime
    let newCurrentTime=currentTime
    if(skipDirection==='forwards'){
      newCurrentTime=Math.min(newCurrentTime+skipAmount,this.props.sound.end)
    }else{
      newCurrentTime=Math.max(newCurrentTime-skipAmount,0)
    }
    this.seekTo(newCurrentTime)
  }

  updateControlPlayback(isPlaying){
    this.props.updateControlPlayback('promptMedia',isPlaying)
  }

  componentWillUnmount() {
    this.audioElement.pause()
    this.stopTimer()

  }
 
  updatePlayerCurrentTime(){
    const currentTime=this.audioElement.currentTime
    this.setState({playerCurrentTime:currentTime})
  }

  startTimer(){
    this.timer = setInterval(() => {
      this.updatePlayerCurrentTime()
    }, 100)
  }

  stopTimer(){
    clearInterval(this.timer)
    this.timer=null
  }

  playSound(commandFromControl){
    this.props.hideAutoplayFailedAlert()
    if(commandFromControl!==true){
      this.updateControlPlayback(true)
    }else{
      this.setState({isPlaying:true})
      this.startTimer()
      this.audioElement.play().catch(error => {
          this.props.showAutoplayFailedAlert()
          this.setState({isPlaying:false})
          this.stopTimer()
          this.updateControlPlayback(false)
          if(window.analytics){
            window.analytics.track('Show autoplay failed alert',{
              mediaType:'sound',
              playbackItem:'promptMedia'
            })
          }
      })
      // var promise = this.audioElement.play()
      // promise.catch(error => {
      //     // Auto-play was prevented
      //     // Show a UI element to let the user manually start playback
      // }).then(() => {
      //     // Auto-play started
      // });
    }
  }

  pauseSound(commandFromControl){
    if(commandFromControl!==true){
      this.updateControlPlayback(false)
    }else{
      this.stopTimer()
      this.setState({isPlaying:false})
      this.audioElement.pause()
    }
  }

  seekTo(seconds){
    this.audioElement.currentTime=seconds
    if(!this.timer){
      this.setState({playerCurrentTime:seconds})
    }
  }

  playbackSkip(skipDirection){ //skip forwards/back 15 seconds
    const currentTime=this.audioElement.currentTime
    let newTime
    if(skipDirection==='forward'){
      newTime=Math.min(currentTime+15,this.state.duration)
    }
    else if(skipDirection==='back'){
      newTime=Math.max(currentTime-15,0)
    }
    this.seekTo(newTime)
  }

  restartAudio(){
    this.audioElement.currentTime=0
  }


  render(){   

    const { promptMediaZoomed, animationDisabled, isLastZoomedItem} = this.props 
    const {isPlaying,duration,waveformImageURL,itemStyle,playerStyle,playScaleSectionInverseScale,itemEndZoomScale,widePlayer} = this.state
    // PLAYBACK STUFF
    const progress = this.state.playerCurrentTime
    const lengthSeconds = duration // passed through

    let showSeekJumpButtons = false

    if(lengthSeconds > 20){
      showSeekJumpButtons = true
    }

    // Z-INDEX MANAGEMENT
    const moveItemBelowDarkBG = !isLastZoomedItem
      
    return(     
      
      <React.Fragment>
        <Spring
          //onRest={this.zoomAnimationFinished}
          native                  
          from={{ transform: 'scale(1)' }}
          to={{ transform: (promptMediaZoomed && itemEndZoomScale) ? `scale(${itemEndZoomScale})` : 'scale(1)'}}          
          immediate={animationDisabled}
        >
          {props => 
            <animated.div style={{...itemStyle, ...props}}  className={'liveView-mediaItem liveView-mediaItem--promptSoundPlayer ' + (isLastZoomedItem ? ' liveView-mediaItem--isLastZoomedItem ' : '') + (moveItemBelowDarkBG ? ' liveView-mediaItem--moveItemBelowDarkBG ' : '')}>

              <div style={playerStyle} className={'liveView-mediaItem--promptSoundPlayer-player ' + (isPlaying ? 'liveView-mediaItem--promptSoundPlayer-player--playing' : 'liveView-mediaItem--promptSoundPlayer-player--paused') + (widePlayer ? ' liveView-mediaItem--promptSoundPlayer-player--widePlayer ' : '')}>
                <Spring
                  //onRest={this.zoomAnimationFinished}
                  native                  
                  from={{ transform: 'scale(1)' }}
                  to={{ transform: (promptMediaZoomed && itemEndZoomScale) ? `scale(${playScaleSectionInverseScale})` : 'scale(1)'}}          
                  immediate={animationDisabled}
                >
                  {timestampProps => 
                    <animated.div style={{borderRadius: promptMediaZoomed ? '2px' : '9px', ...timestampProps}} className='liveView-mediaItem--promptSoundPlayer-player-top'>
                      {progress > 0 && isPlaying &&
                        <div className='liveView-mediaItem--promptSoundPlayer-player-top-timestamp liveView-mediaItem--promptSoundPlayer-player-top-timestamp--currentTimePlaying'>
                          {formatDurationTimestamp(this.state.playerCurrentTime)}
                        </div>
                      }
                      {progress > 0 && !isPlaying &&
                        <div className='liveView-mediaItem--promptSoundPlayer-player-top-timestamp liveView-mediaItem--promptSoundPlayer-player-top-timestamp--currentTime'>
                          {formatDurationTimestamp(this.state.playerCurrentTime)}
                        </div>
                      }
                      {!isPlaying &&
                        <div className='liveView-mediaItem--promptSoundPlayer-player-top-timestamp liveView-mediaItem--promptSoundPlayer-player-top-timestamp--duration'>
                          {formatDurationTimestamp(duration)}
                        </div>
                      }
                  </animated.div>
                  }
                </Spring>

                                                
                <div className='liveView-mediaItem--promptSoundPlayer-player-seekBarContainer'>
                  <Slider 
                    onChange={(value)=>{this.seekTo(value)}}
                    value={this.state.playerCurrentTime} 
                    step={0.01}
                    min={0} 
                    max={lengthSeconds} 
                    defaultValue={0} 
                    handle={handle}
                  />

                  <div className='liveView-mediaItem--promptSoundPlayer-player-seekBarContainer-waveformBG'>
                    <img src={waveformImageURL} alt=''/>
                  </div>

                </div>

                <Spring
                  //onRest={this.zoomAnimationFinished}
                  native                  
                  from={{ transform: 'scale(1)' }}
                  to={{ transform: (promptMediaZoomed && itemEndZoomScale) ? `scale(${playScaleSectionInverseScale})` : 'scale(1)'}}          
                  immediate={animationDisabled}
                >
                  {innerProps => 
                    <animated.div style={{...innerProps}} className='liveView-mediaItem--promptSoundPlayer-player-controls'>
                       {showSeekJumpButtons &&
                      <button onClick={()=>{this.playbackSkip('back')}} className='liveView-mediaItem--promptSoundPlayer-player-controls-seekJump'>
                        <EditorIcon name='playbackSeekBack15' />
                      </button>
                    }
                    
                    {isPlaying &&
                      <button onClick={this.pauseSound} className='liveView-mediaItem--promptSoundPlayer-player-controls-playPauseBtn liveView-mediaItem--promptSoundPlayer-player-controls-playPauseBtn--playing'>
                        <EditorIcon name='playbackPause' /> 
                      </button>
                    }
                    {!isPlaying &&
                      <button onClick={this.playSound} className='liveView-mediaItem--promptSoundPlayer-player-controls-playPauseBtn liveView-mediaItem--promptSoundPlayer-player-controls-playPauseBtn--paused'>
                        <EditorIcon name='playbackPlay' /> 
                      </button>
                    }
                    
                    {showSeekJumpButtons &&
                      <button onClick={()=>{this.playbackSkip('forward')}} className='liveView-mediaItem--promptSoundPlayer-player-controls-seekJump'>
                        <EditorIcon name='playbackSeekForward15' />
                      </button>
                    }
                  </animated.div>
                  }
                </Spring>

            
              </div>
              
                                 
            </animated.div>
          }
        </Spring>   
      </React.Fragment>

    )
  }
}


export default LiveViewPromptSoundPlayer
import React from 'react'
import ModalWrapper from './ModalWrapper'
import AssignSectionToDataOwnerContainer from '../../containers/sections/AssignSectionToDataOwnerContainer'

/* eslint-disable react/jsx-props-no-spreading */
const AssignSectionToDataOwnerModal = (props) => (
  <div className='assignToDataOwner-modal'>
    <ModalWrapper
      {...props}
      title='Assign to your organization'
      modalStyle='dark'
    >
      <AssignSectionToDataOwnerContainer {...props} />

    </ModalWrapper>

  </div>
)
/* eslint-enable react/jsx-props-no-spreading */

export default AssignSectionToDataOwnerModal

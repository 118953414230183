import React from 'react'
import ModalWrapper from './ModalWrapper'
import DataOwnerInfoContainer from '../../containers/sections/DataOwnerInfoContainer'

/* eslint-disable react/jsx-props-no-spreading */
const DataOwnerInfoModal = (props) => {
  const { dataOwner } = props
  const { shortName } = dataOwner
  return (
    <div className='dataOwnerInfo-modal'>
      <ModalWrapper
        {...props}
        title={shortName}
        cancelBtn={false}
        closeBtn={false}
        showModalHeader={false}
        modalStyle='dark'
      >
        <DataOwnerInfoContainer {...props} />
      </ModalWrapper>

    </div>
  )
}
/* eslint-enable react/jsx-props-no-spreading */

export default DataOwnerInfoModal

import React, { Component } from 'react'
import Button from '../../components/uiKit/Button'
import FeatureModalSelectControl from '../../components/featureModals/FeatureModalSelectControl' 




class ImportQuestionsOnboardingModalStep extends Component {

   
	
	render() {
		
		return(

			<div className='importQuestionsOnboardingModal-step'>
				<div className='importQuestionsOnboardingModal-step-number'>
					Step {this.props.stepNumber}
				</div>
				<div className='importQuestionsOnboardingModal-step-name'>
					{this.props.stepName}
				</div>

				{this.props.stepLabel &&
					<div className='importQuestionsOnboardingModal-step-label'>
						{this.props.stepLabel}
					</div>
				}


				

			</div>

			)
	
	}
}




class ImportQuestionsOnboardingModal extends Component {

	constructor() {
		super()
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)
	}

   	componentDidMount() {
		if(window.analytics){
			window.analytics.page('Import Questions Onboarding')
		}
	}
	

	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.props.closeOnboarding() //close modal is click outside modal
	}


	render() {
		const {doNotShowAgain}=this.props

		return(
			<div className='importQuestionsOnboardingModalContainer' onClick={this.handleBackgroundClick} >				
				<div className='importQuestionsOnboardingModal'>
									
					<div className='importQuestionsOnboardingModal-left'>
						<div className='importQuestionsOnboardingModal-videoContainer'>

								<iframe 
									class="importQuestionsOnboardingModal-video" 
									src="https://www.youtube-nocookie.com/embed/0iLZ7fSYu0s?autoplay=1&amp;mute=1&amp;loop=1&amp;"
									cc_load_policy="1"
									cc_lang_pref="en" // required to show captions by default
									frameBorder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									rel="0" // only show related videos from same channel
									allowFullScreen									
									title='helpVideoModal'
								>	

								</iframe>
						

						</div>
					</div>

					<div className='importQuestionsOnboardingModal-right'>						
						
						<ImportQuestionsOnboardingModalStep
							stepNumber={1}
							stepName='Find some multiple-choice questions to import'
							stepLabel='You can import from all sorts of places, including websites, Word Documents, PDFs and quiz web-apps.'
						/>
						<ImportQuestionsOnboardingModalStep
							stepNumber={2}
							stepName='Bulk Copy and Paste in'							
						/>
						<ImportQuestionsOnboardingModalStep
							stepNumber={3}
							stepName='Spot-check and tweak'
							stepLabel="Look over the Import Preview to see if everything's ship-shape. Sometimes you need to make a few adjustments – like finding and removing bits of text or lining up question numbers."
						/>
						<ImportQuestionsOnboardingModalStep
							stepNumber={4}
							stepName='Import'
							stepLabel="When you import, we automatically clean the text you've pasted in – removing things like Question Numbers, Bullet Points, and Choice Letters."
						/>

						<div className='importQuestionsOnboardingModal-buttonRow'>
							<FeatureModalSelectControl checked={doNotShowAgain} onClick={this.props.toggleDoNotShowOnboardingAgain} checkbox label='Do not show this Getting Started screen again' />
					
							<Button 
								label='Get Started'
								color='blue'
								size='xLarge'
								onClickFunction={this.props.closeOnboarding}
							/>

						</div>

					</div>




					

				</div>
			</div>

			)
	
	}
}



export default ImportQuestionsOnboardingModal


import store from '../store'

export function isCurrentUser(userId) {	
	const state = store.getState()
	const currentUser=state.user
	if(currentUser && userId===currentUser.id){
		return true
	} else return false 
}


import React from 'react'

class InputField extends React.Component {
  render() {
    const {
      value,
      onChangeFunction,
      onBlurFunction,
      id,
      placeholder,
      maxLength,
      autoFocus,
      onFocus,
      onKeyDown,
      type,
      autoComplete,
    } = this.props
    let isEmpty = true
    if (value !== '') {
      isEmpty = false
    }

    return (
      <input
        autoFocus={autoFocus}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        type={type || 'text'}
        className={`inputField${isEmpty ? ' is--empty' : ' is--nonEmpty'}`}
        placeholder={placeholder}
        autoComplete={autoComplete || null}
        value={value}
        maxLength={maxLength}
        onChange={onChangeFunction}
        onBlur={onBlurFunction || null}
        id={id || null}
      />
    )
  }
}

export default InputField

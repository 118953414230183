import schema from '../../schema/editorSchema'
import { getNewQuestionTemplateAddMedia } from '../getNewQuestionTemplate'
import { generateRandQuestionId } from '../../../utils/generateRandQuestionId'
import { uploadNewImageToCloudinary } from '../../../actions/cloudinary'
import { showNotification } from '../../../actions/notifications'
import { getActiveQuestionChoiceCount } from '../getActiveQuestionChoiceCount'
import { getActiveQuestionSlideWidth } from '../getActiveQuestionSlideWidth'
import { SetAttrsStep } from '../SetAttrsStep'
import store from '../../../store'
// TODO clean up and maybe combine prompt/choice functions

function sendInsertGIFAnalytics(node, gifAttrs) {
  if (window.analytics) {
    let isPromptMedia = false
    if (node.type.name === 'questionAnimatedGIF') {
      isPromptMedia = true
    }
    window.analytics.track('Insert GIF', {
      isPromptMedia,
      host: gifAttrs.host,
      title: gifAttrs.title,
      gifUrl: gifAttrs.gifUrl,
    })
  }
}

export function addQuestionAnimatedGIF(gifyID, giphyOriginalStill, giphyOriginalGifURL, giphyOriginalWebPURL, giphySlug, giphyUser, attribution, title, width, height, insertPos, questionPos, questionNode) {
  return () => {
    const { tr } = window.view.state
    const newTemplate = getNewQuestionTemplateAddMedia(questionNode.attrs.template)
    tr.setNodeMarkup(questionPos, null, { ...questionNode.attrs, template: newTemplate })
    window.view.state.doc.nodesBetween(questionPos, questionPos + questionNode.nodeSize, (node, pos) => {
      if (node.type.name === 'choiceImage' ||
        node.type.name === 'choiceVideo' ||
        node.type.name === 'choiceSound' ||
        node.type.name === 'choiceAnimatedGIF' ||
        node.type.name === 'choice') {
        tr.setNodeMarkup(pos, null, { ...node.attrs, template: newTemplate })
      }
    })

    let giphyUserObj = null
    if (giphyUser) {
      giphyUserObj = {
        username: giphyUser.username,
        profileUrl: giphyUser.profile_url,
        avatarUrl: giphyUser.avatar_url,
      }
    }
    const nowDate = new Date()

    const type = schema.nodes.questionAnimatedGIF
    const gifAttrs = {
      fileId: gifyID,
      addedAt: nowDate,
      host: 'giphy',
      attribution,
      title,
      stillUrl: giphyOriginalStill,
      gifUrl: giphyOriginalGifURL,
      webpUrl: giphyOriginalWebPURL,
      giphySlug,
      giphyUser: giphyUserObj,
      template: newTemplate,
      dimensions: {
        w: width,
        h: height,
      },
    }

    gifAttrs.questionMediaDynamicHeight = questionNode.attrs.questionMediaDynamicHeight
    gifAttrs.slideWidth = questionNode.attrs.slideWidth
    const gifNode = type.create(gifAttrs)
    sendInsertGIFAnalytics(gifNode, gifAttrs)
    tr.replaceWith(insertPos.start, insertPos.end, gifNode)
    tr.setMeta('triggerParseDoc', true)
    window.view.dispatch(tr)
  }
}

export function uploadQuestionAnimatedGIF(insertPos, questionPos, questionNode, file) {
  return (dispatch) => {
    const { tr } = window.view.state
    const newTemplate = getNewQuestionTemplateAddMedia(questionNode.attrs.template)
    tr.setNodeMarkup(questionPos, null, { ...questionNode.attrs, template: newTemplate })

    window.view.state.doc.nodesBetween(questionPos, questionPos + questionNode.nodeSize, (node, pos) => {
      if (node.type.name === 'choiceImage' ||
        node.type.name === 'choiceVideo' ||
        node.type.name === 'choiceSound' ||
        node.type.name === 'choice') {
        tr.setNodeMarkup(pos, null, { ...node.attrs, template: newTemplate })
      }
    })
    const nodeId = generateRandQuestionId()
    const nowDate = new Date()
    const type = schema.nodes.questionAnimatedGIF

    const gifAttrs = {
      nodeId,
      addedAt: nowDate,
      // placeholder:'placeholder',
      fileId: '',
      host: 'cloudinary',
      stillUrl: '',
      gifUrl: '',
      webpUrl: '',
      template: newTemplate,
      dimensions: {
        w: '',
        h: '',
      },
    }

    gifAttrs.questionMediaDynamicHeight = questionNode.attrs.questionMediaDynamicHeight
    gifAttrs.slideWidth = getActiveQuestionSlideWidth()
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      const img = new Image()
      img.src = event.target.result
      img.onload = function () {
        const placeholderDimensions = {
          w: img.naturalWidth,
          h: img.naturalHeight,
        }
        gifAttrs.placeholder = event.target.result
        gifAttrs.placeholderDimensions = placeholderDimensions
        let fileName = file.name
        const extensions = ['.gif']
        extensions.forEach((extension) => {
          fileName = fileName.replace(extension, '')
        })
        gifAttrs.title = fileName
        const gifNode = type.create(gifAttrs)
        tr.replaceWith(insertPos.start, insertPos.end, gifNode)
        tr.setMeta('triggerParseDoc', true)
        window.view.dispatch(tr)
        return dispatch(beginImageUpload(event.target.result)).then((response) => {
          updateGIFNodeWithCloudinaryValues(response, nodeId)
        })
      }
    }
  }
}

export function uploadChoiceAnimatedGIF(insertPos, file) {
  return (dispatch) => {
    const { tr } = window.view.state
    const nodeId = generateRandQuestionId()
    const type = schema.nodes.choiceAnimatedGIF
    const nowDate = new Date()
    const gifAttrs = {
      questionChoiceCount: getActiveQuestionChoiceCount(),
      slideWidth: getActiveQuestionSlideWidth(),
      addedAt: nowDate,
      nodeId,
      host: 'cloudinary',
      fileId: '',
      deliveryCloudinaryUrl: '',
      dimensions: {
        w: '',
        h: '',
      },
    }
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      const img = new Image()
      img.src = event.target.result
      img.onload = function () {
        const placeholderDimensions = {
          w: img.naturalWidth,
          h: img.naturalHeight,
        }
        gifAttrs.placeholderDimensions = placeholderDimensions
        gifAttrs.placeholder = event.target.result
        const gifNode = type.create(gifAttrs)
        tr.replaceWith(insertPos.start, insertPos.end, gifNode)
        tr.setMeta('triggerParseDoc', true)
        window.view.dispatch(tr)
        return dispatch(beginImageUpload(event.target.result)).then((response) => {
          updateGIFNodeWithCloudinaryValues(response, nodeId)
        })
      }
    }
  }
}

export function updateGIFNodeWithSavedCloudinaryValues(nodeId) {
  const state = store.getState()
  if (state.imageUploads[nodeId]) {
    updateGIFNodeWithCloudinaryValues(state.imageUploads[nodeId], nodeId)
  }
}

function checkIfFileIsAnimated(cloudinaryResponse) { // animated GIF check
  let isAnimated = false
  if (cloudinaryResponse.pages && cloudinaryResponse.pages > 1) {
    isAnimated = true
  }
  return isAnimated
}

function updateGIFNodeWithCloudinaryValues(cloudinaryResponse, nodeId) {
  const { tr } = window.view.state
  let imageNode
  let imageNodePos
  window.view.state.doc.nodesBetween(0, window.view.state.doc.content.size, (node, pos) => {
    if (node.attrs.nodeId && node.attrs.nodeId === nodeId) {
      imageNodePos = pos
      imageNode = node
    }
  })

  if (imageNode) {
    const imageAttrs = { ...imageNode.attrs }
    const cloudinaryID = cloudinaryResponse.public_id
    const intrinsicHeight = cloudinaryResponse.height
    const intrinsicWidth = cloudinaryResponse.width
    // check if it is a static file and show notification
    const isAnimated = checkIfFileIsAnimated(cloudinaryResponse)
    if (!isAnimated) {
      store.dispatch(showNotification("If you'd like to insert it as an image instead, go to Insert Image.", 'Selected GIF file does not contain animation.', 'default'))
    }

    const dimensions = {
      w: intrinsicWidth,
      h: intrinsicHeight,
    }
    imageAttrs.dimensions = dimensions
    imageAttrs.fileId = cloudinaryID
    const cloudinaryBucketName = process.env.REACT_APP_CLOUDINARY_CLOUDNAME
    let urlStem = `https://res.cloudinary.com/${cloudinaryBucketName}/image/upload/`
    if (process.env.REACT_APP_ENV === 'production') {
      urlStem = 'https://media.plickers.com/image/upload/'
    }
    imageAttrs.stillUrl = `${urlStem}/pg_1/${cloudinaryID}`
    imageAttrs.webpUrl = `${urlStem}/${cloudinaryID}.webp`
    imageAttrs.gifUrl = `${urlStem}/${cloudinaryID}.gif`
    tr.step(new SetAttrsStep(imageNodePos, imageAttrs))
    tr.setMeta('addToHistory', false)
    tr.setMeta('triggerParseDoc', true)
    sendInsertGIFAnalytics(imageNode, imageAttrs)
    window.view.dispatch(tr)
  }
  store.dispatch({ type: 'CLOUDINARY_UPLOAD_SUCCESS', nodeId, cloudinaryResponse })
}

function beginImageUpload(src, thumbnail) {
  return (dispatch) => dispatch(uploadNewImageToCloudinary(src, thumbnail)).then((response) => response)
}

export function addChoiceAnimatedGIF(gifyID, giphyOriginalStill, giphyOriginalGifURL, giphyOriginalWebPURL, giphySlug, giphyUser, attribution, title, width, height, insertPos) {
  return () => {
    const { tr } = window.view.state
    const type = schema.nodes.choiceAnimatedGIF
    let giphyUserObj = null
    if (giphyUser) {
      giphyUserObj = {
        username: giphyUser.username,
        profileUrl: giphyUser.profile_url,
        avatarUrl: giphyUser.avatar_url,
      }
    }
    const nowDate = new Date()

    const gifAttrs = {
      fileId: gifyID,
      addedAt: nowDate,
      host: 'giphy',
      questionChoiceCount: getActiveQuestionChoiceCount(),
      slideWidth: getActiveQuestionSlideWidth(),
      stillUrl: giphyOriginalStill,
      gifUrl: giphyOriginalGifURL,
      webpUrl: giphyOriginalWebPURL,
      giphySlug,
      giphyUser: giphyUserObj,
      title,
      attribution,
      dimensions: {
        w: width,
        h: height,
      },
    }

    const gifNode = type.create(gifAttrs)
    sendInsertGIFAnalytics(gifNode, gifAttrs)
    tr.replaceWith(insertPos.start, insertPos.end, gifNode)
    tr.setMeta('triggerParseDoc', true)
    window.view.dispatch(tr)
  }
}

import api from '../api'
import {fetchService} from './service'


export function fetchOrder(licenseKey) {
	return (dispatch) => api.fetch(`/orders?key=${licenseKey}`)
		.then((response) => {
			dispatch({ type: 'FETCH_ORDER_SUCCESS', response })
			return response
		})
}

//Claim seat to upgrade account to pro
export function applyOrder(orderId,licenseKey) {
	return (dispatch) => api.put(`/orders/${orderId}/apply?key=${licenseKey}`)
		.then((response) => {
			dispatch(fetchService())
			return response
		})
}



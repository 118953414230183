import React, {Component} from 'react'


class ImportQuestionsInputAreaTextArea extends Component{

	render(){	
		const {questionNumberArray,choicesPerQuestion, remainingQuestionsInSetLimit}=this.props

		const heightRow = 22
		const paddingTopBottom = 50
		const textAreaHeight = (this.props.maxQuestionCount * (choicesPerQuestion + 1) * heightRow) + paddingTopBottom

		return(		
			<div className='importQuestions-inputArea-textAreaContainer'>
				<textarea 
					id='importQuestionsTextArea'
					onClick={this.props.getLineNumber}
					onKeyUp={this.props.getLineNumber}
				 	className='importQuestions-inputArea-textArea'
					onPaste={this.props.textAreaOnPaste}
					value={this.props.textAreaValue}
					onChange={this.props.textAreaOnChange}
					autoFocus
					placeholder='To get started, Copy and Paste in bulk into here!'
					wrap="off"
					style={{height: `${textAreaHeight}px` }}
				/>

			{/* PROVIDES LINES BETWEEN EACH QUESTION */}
				<div className='importQuestions-inputArea-textAreaContainer-zebraBGContainer'>
					{questionNumberArray.map((i)=>	
						<div key={i} className={'importQuestions-inputArea-textAreaContainer-zebraBG ' + (choicesPerQuestion ? `importQuestions-inputArea-textAreaContainer-zebraBG--choiceCount${choicesPerQuestion}`:'') + ((i+1 > remainingQuestionsInSetLimit) ? ' importQuestions-inputArea-textAreaContainer-zebraBG--overLimit' : '')} />
					)}
				</div>

			{/* PROVIDES COVER OVER QUESTIONS OVER LIMIT */}
				<div className='importQuestions-inputArea-textAreaContainer-coverContainer'>
					{questionNumberArray.map((i)=>	
						<div key={i} className={'importQuestions-inputArea-textAreaContainer-coverBG ' + (choicesPerQuestion ? `importQuestions-inputArea-textAreaContainer-coverBG--choiceCount${choicesPerQuestion}`:'') + ((i+1 > remainingQuestionsInSetLimit) ? ' importQuestions-inputArea-textAreaContainer-coverBG--overLimit' : '')} />
					)}
				</div>

			</div>
		)
	}
}

					



export default ImportQuestionsInputAreaTextArea

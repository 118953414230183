import React from 'react'
import { Spring, animated } from 'react-spring/renderprops' 

class NPZoomDarkBG extends React.Component{
	
	render(){		
		const {mediaZoomed, animationDisabled} = this.props 		
		return(				
			<React.Fragment>
				<Spring          
          native                  
          from={{ opacity: 0 }}
          to={{ opacity: mediaZoomed ? 0.95 : 0 }}
          immediate={animationDisabled}
        >
          {props => 
            <animated.div onClick={this.props.unzoomMedia} style={{...props}} className={'liveView-darkZoomBackground ' + (mediaZoomed ? 'liveView-darkZoomBackground--mediaZoomed' : 'liveView-darkZoomBackground--noMediaZoomed')} />
          }
        </Spring>		
			</React.Fragment>
		)
	}
}

export default NPZoomDarkBG
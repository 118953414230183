let initialState={
	autoAlgebra:false
}
// toggleAutoAlgebraMode = (active) => ({ type: 'TOGGLE_AUTO_ALGEBRA_MODE',active})

export default function (state = initialState, action) {
	switch (action.type) {

	case 'TOGGLE_AUTO_ALGEBRA_MODE':
		return {...state,autoAlgebra:action.active}
	default:
		return state
	}
}

export function getNewQuestionTemplateAddMedia(oldTemplate){
	let newTemplate=oldTemplate
	if(oldTemplate==='bodyLeft'){
		newTemplate='bodyLeftMediaRight'
	}else if(oldTemplate==='bodyCenter'){
		newTemplate='bodyCenterMediaCenter'
	}else if(oldTemplate==='bodyCenterChoicesMedia'){
		newTemplate='bodyLeftMediaRightChoicesMedia'
	}
	return newTemplate
}

export function getNewQuestionTemplateDeleteMedia(oldTemplate){
	let newTemplate=oldTemplate

	if(oldTemplate==='bodyLeftMediaRight'){
		newTemplate='bodyLeft'
	}else if(oldTemplate==='bodyRightMediaLeft'){
		newTemplate='bodyLeft'
	}else if(oldTemplate==='bodyCenterMediaCenter'){
		newTemplate='bodyCenter'
	}else if(oldTemplate==='bodyLeftMediaRightChoicesMedia'){
		newTemplate='bodyCenterChoicesMedia'
	}
	return newTemplate
}

export function buildReportPollsByStudent(historyPolls, students, guests, showCorrect, showIncorrect, showMissing, showSurveys) {
  const reportPollsByStudent = {}
  function shouldIncludePoll(response, poll) {
    if (!showSurveys && poll.correctPercent === -1) {
      return false
    } if (!response) {
      if (showMissing) {
        return true
      }
      return false
    } if (response.isCorrect === true) {
      if (showCorrect === true) {
        return true
      }
      return false
    } if (response.isCorrect === false) {
      if (showIncorrect === true) {
        return true
      }
      return false
    } return false
  }

  function getPollsForPerson({ student, guest }) {
    const personalPolls = []
    let personalPollCount = 0
    const identifier = student ? student.id : guest

    historyPolls.forEach((poll) => {
      if (poll.setObject) {
        // work out which of pollPolls to include (then get rid of set if has no poll polls)
        const personalPollPolls = []
        poll.filteredAndOrderedPolls.forEach((setPollPoll) => {
          const response = student ?
            setPollPoll.responsesByStudent[identifier] :
            setPollPoll.responsesByGuest[identifier]
          const includePoll = shouldIncludePoll(response, setPollPoll)
          if (includePoll) {
            personalPollPolls.push(setPollPoll)
            personalPollCount += 1
          }
        })
        if (personalPollPolls.length !== 0) {
          const studentSetPoll = { ...poll }
          studentSetPoll.filteredAndOrderedPolls = [...personalPollPolls]
          personalPolls.push(studentSetPoll)
        }
      } else {
        const response = student ?
          poll.responsesByStudent[identifier] :
          poll.responsesByGuest[identifier]
        const includePoll = shouldIncludePoll(response, poll)
        if (includePoll) {
          personalPolls.push(poll)
          personalPollCount += 1
        }
      }
      return null
    })

    reportPollsByStudent[identifier] = {
      polls: personalPolls,
      questionCount: personalPollCount,
      isArchived: student && student.archived,
      isGuest: !student,
    }
  }

  students.forEach((student) => getPollsForPerson({ student }))
  guests.forEach((guest) => getPollsForPerson({ guest }))
  return reportPollsByStudent
}

import React from 'react'
import ItemTypes from '../DnDKit/ItemTypes'
import { DropTarget,DragSource } from 'react-dnd'
import FolderCell from './FolderCell'
import FolderTarget from '../DnDKit/FolderTarget'
import Icon from '../misc/Icons'

const cellSource = {
	beginDrag(props) {
		return {cellItem:props.folder,multiselectItems:props.multiselectItems,inSharedRepo:props.inSharedRepo,inPublishedRepo:props.inPublishedRepo}

	},
	canDrag(props){
		if(props.folder.readOnly){
			return false
		}else return true
	},
	isDragging(props) {
		return props.isMultiSelectItem  || props.isActive
	},
}

function collectDrag(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		 connectDragPreview: connect.dragPreview(),
		isDragging: monitor.isDragging()
	}
}


function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop()
	}
}

class DnDFolderCell extends React.Component{

	renderOverlay(color) {
		return (
			<div style={{
				position: 'absolute',
				top: 0,
				left: 0,
				height: '100%',
				width: '100%',
				zIndex: 1,
				opacity: 0.5,
				backgroundColor: color,
			}} />
		)
	}

	render(){
		const {connectDropTarget,connectDragSource,multiselectItems,folder}=this.props
	
		
		let isMultiselect=false
		if(multiselectItems && Object.keys(multiselectItems).length>1){
			isMultiselect=true
		}

		return connectDragSource(connectDropTarget(
			<div>
				<FolderCell {...this.props} />			
				<div className='cellRow-dragPreviewWrapper'>
					{this.props.connectDragPreview(

							<div className='cellRow-dragPreviewContainer'>
																				
								{/* Folder */}
								{!isMultiselect &&
									<div className='cellRow-dragPreview cellRow-dragPreview--folder'>
										<Icon name='folder' />
										{folder.name}
									</div>
								}

								{/* MultiSelect */}
								{isMultiselect &&
									<div className='cellRow-dragPreview cellRow-dragPreview--multi'>
										{Object.keys(multiselectItems).length} items
									</div>
								}								

							</div>,{offsetX:0,offsetY:0})}
					</div>


		</div>
	))
}
}

export default DragSource(ItemTypes.FOLDERCELL, cellSource, collectDrag)(DropTarget([ItemTypes.ITEMCELL,ItemTypes.FOLDERCELL], FolderTarget, collect)(DnDFolderCell))



import React from 'react'

//Shows if nothing currently live in NP

class LiveEmptyState extends React.PureComponent{	
	render(){
		return(		
			<div className='blankLiveScreen' >
				<div className='blankLiveScreen-title'>
					Now Playing Ready
				</div>				
				
				<div className='blankLiveScreen-help'>
					<div className='blankLiveScreen-h3'>
						Having trouble connecting?
					</div>

					<div className='blankLiveScreen-h4'>
						<span className='blankLiveScreen-h4-number'>1.&nbsp;</span>Update your mobile app to the latest version.
					</div>
					<div className='blankLiveScreen-h4'>
						<span className='blankLiveScreen-h4-number'>2.&nbsp;</span>Try refreshing the page.
					</div>				

					<div className='blankLiveScreen-h4'>
						<span className='blankLiveScreen-h4-number'>3.&nbsp;</span>Check out our <a href='https://help.plickers.com/hc/en-us/articles/360008949074-Troubleshooting' target='_blank' rel='noopener noreferrer'>troubleshooting page.</a>
					</div>
				</div>					
			</div>				
		)
	}
}

export default LiveEmptyState
import React from 'react'
import { NavLink } from 'react-router-dom'
import Button from '../uiKit/Button'
import Dropdown from '../uiKit/Dropdown'
import DropdownSelect from '../uiKit/DropdownSelect'
import DropdownDivider from '../uiKit/DropdownDivider'
import DropdownItem from '../uiKit/DropdownItem'
import DropdownSelectItem from '../uiKit/DropdownSelectItem'
import {CSVLink} from 'react-csv'


class ScoreSheetTopBar extends React.Component{

	constructor(){
		super()
		this.onClickCsv=this.onClickCsv.bind(this)
		this.state={
			csvData:[],
			csvFilename:''		
		}
	}

	onClickCsv(){
		const csvData=this.props.calculateCsvData()
		const csvFilename=this.props.getCsvFilename()
		let btn = this.refs.csv
		this.setState({csvData:csvData,csvFilename:csvFilename},	 function() {btn.link.click() })
	}

	render(){
		const {clearExcludedPolls,currentSection,collapsedView,setScoresheetSetting, noSectionSelected,studentScoreDisplay} = this.props
		let granularityNavigateLabel = this.props.granularity
		if(this.props.granularity === 'week'){
			granularityNavigateLabel = 'Week'
		}
		if(this.props.granularity === 'month'){
			granularityNavigateLabel = 'Month'
		}
		if(this.props.granularity === '90d'){
			granularityNavigateLabel = '90-day period'
		}

		let unhidePollsLabel='Unhide all for current Class'
		if(currentSection){
			unhidePollsLabel=`Unhide all for ${currentSection.name}`
		}

		return(
			<div className={'scoreSheet-topBar' + (noSectionSelected ? ' scoreSheet-topBar--noSectionSelected' : '')}>
				<div className='scoreSheet-topBar-left'>
					<NavLink to={'/library'} >	
						<Button name='Home' iconName='home'/>
					</NavLink>
					<Button label='Today' onClickFunction={()=>{this.props.changeSelectedDate()}} />

					<Button 
						disabled={this.props.customDateRange}
						name='previous'
						iconName='chevron-left'
						tooltipLabel={`Previous ${granularityNavigateLabel}`}
						tooltipPosition='label'
						onClickFunction={()=>{this.props.changeSelectedDate('back')}}
					/>
					<Button
						disabled={!this.props.dateRangeCanGoForward || this.props.customDateRange}
						name='next'
						iconName='chevron-right'
						tooltipLabel={`Next ${granularityNavigateLabel}`}
						tooltipPosition='label'
						onClickFunction={()=>{this.props.changeSelectedDate('forward')}} 
					/>
				</div>
				<div className='scoreSheet-topBar-center'>
					<div className='scoreSheet-topBar-center-label'>
						{this.props.dateRangeLabel}
					</div>
				</div>

				<div className='scoreSheet-topBar-right'>
					<div 
						data-tooltip={this.props.disable90Day ? 'All date-range options are available with Plickers Pro' : null }
						className={'scoreSheet-topBar-granularityToggle' + 
						(this.props.disable90Day ? ' tooltip tooltip--label':'')}
					>
						<ScoreSheetTopBarGranularityToggle 
							changeDateGranularity={this.props.changeDateGranularity} 
							granularity={this.props.granularity}
							disable90Day={this.props.disable90Day}
						/>
						<Button disabled={!this.props.hasPolls || this.props.disableCustom} isActive={this.props.customDateRange} label='Custom' onClickFunction={()=>{this.props.showScoreSheetCustomRangeModal()}} />
					</div>

					
					<Button name='studentpack' label='Student Reports' disabled={!this.props.hasPolls} onClickFunction={()=>{this.props.showReportPackModal('student')}} overflowEllipses />
					
					


					<Button disabled={!this.props.hasPolls} tooltipLabel='Collapse Sets' tooltipLabelTypeLabel color={collapsedView ? 'blue' : 'white'} iconName='collapse-sets' name='collapsesets' onClickFunction={this.props.toggleCollapsedView} />
					<Button disabled={!this.props.hasPolls} tooltipLabel='Export Data to CSV' tooltipLabelTypeLabel name='exportcsv' iconName='export-csv' onClickFunction={this.onClickCsv} />
					<CSVLink ref="csv" filename={this.state.csvFilename} target='_self' data={this.state.csvData} style={{display:'none'}}/>
					

					<Dropdown>        	  
						{/* DISPLAY DENSITY TO DO THIS LATER
						<DropdownSelect label='Display Density'>
							<DropdownSelectItem isActive={this.props.displayDensity==='compact'}  label='Compact' onSelect={()=>{this.props.changeDisplayDensity('compact')}}/>
							<DropdownSelectItem isActive={this.props.displayDensity==='default'} label='Default'onSelect={()=>{this.props.changeDisplayDensity('default')}}/>	        			
							<DropdownSelectItem isActive={this.props.displayDensity==='comfortable'} label='Comfortable'onSelect={()=>{this.props.changeDisplayDensity('comfortable')}}/>
						</DropdownSelect>
						<DropdownDivider />
						*/}
						
						<DropdownItem label='Scoresheet Help' onSelect={()=>{window.open('https://help.plickers.com/hc/en-us/articles/360008946014-Scoresheet','_blank')}} />						
						<DropdownDivider />	
						<DropdownSelect label='Student List Options'>
							<DropdownSelectItem onSelect={()=>{setScoresheetSetting('showStudentCardNumber',!this.props.showStudentCardNumber)}} isActive={this.props.showStudentCardNumber} label='Display Card Numbers'/>
							<DropdownSelectItem onSelect={()=>{setScoresheetSetting('showStudentLastName',!this.props.showStudentLastName)}} isActive={this.props.showStudentLastName} label='Display Last Names'/>
						</DropdownSelect>
						<DropdownDivider />
						<DropdownSelect label='Student Score Display'>
							<DropdownSelectItem onSelect={()=>{setScoresheetSetting('studentScoreDisplay','percentage')}} isActive={studentScoreDisplay==='percentage'} label='Percentage'/>
							<DropdownSelectItem onSelect={()=>{setScoresheetSetting('studentScoreDisplay','number')}} isActive={studentScoreDisplay==='number'} label='Correct / Total Split'/>
							{/* <DropdownSelectItem onSelect={()=>{changeStudentScoreDisplay('both')}} isActive={studentScoreDisplay==='both'} label='Both'/>*/}
						</DropdownSelect>						
						<DropdownDivider />											
						<DropdownSelectItem onSelect={()=>{clearExcludedPolls(this.props.currentSectionId)}}  label={unhidePollsLabel}/>
						<DropdownSelectItem onSelect={()=>{clearExcludedPolls()}}label='Unhide all'/>
					</Dropdown>

				</div>
			</div>
		)
	}
}


export default ScoreSheetTopBar



class ScoreSheetTopBarGranularityToggle extends React.Component{

	constructor(){
		super()
		this.renderGranularityToggleItem=this.renderGranularityToggleItem.bind(this)
	}

	renderGranularityToggleItem(title,granularity,disabled){ //could make this a component
		const activeGranularity= this.props.granularity
		return(    		
			<Button 
				key={title} 
				isActive={activeGranularity === granularity ? true : false}	    	
				onClickFunction={()=>{this.props.changeDateGranularity(granularity)}}
				label={title}
				disabled={disabled}
				overflowEllipses
			/>	    
		)
	}   
	render(){		
		return(
			<React.Fragment>
				{this.renderGranularityToggleItem('Day','day',false)}
				{this.renderGranularityToggleItem('Week','week',false)}
				{this.renderGranularityToggleItem('Month','month',false)}				
				{this.renderGranularityToggleItem('90-Day','90d',this.props.disable90Day)}						
			</React.Fragment>
		)
	}
}
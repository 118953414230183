import React from 'react'
import Button from '../uiKit/Button'

// class CreatorProgramIntroPageBulletItem extends React.Component{
// 	render(){	
// 		return(
			
// 				<div className='joinCreatorProgramModal--intro-bulletItem'>					
// 					{this.props.title &&
// 						<div className='joinCreatorProgramModal--intro-bulletItem-title'>
// 							{this.props.title}
// 						</div>
// 					}
// 					{this.props.label &&
// 						<div className='joinCreatorProgramModal--intro-bulletItem-label'>
// 							{this.props.label}
// 						</div>
// 					}

// 					{this.props.label2 &&
// 						<div className='joinCreatorProgramModal--intro-bulletItem-label joinCreatorProgramModal--intro-bulletItem-label--two'>
// 							{this.props.label2}
// 						</div>
// 					}

// 					{this.props.label3 &&
// 						<div className='joinCreatorProgramModal--intro-bulletItem-label joinCreatorProgramModal--intro-bulletItem-label--two'>
// 							{this.props.label3}
// 						</div>
// 					}
					
// 				</div>

			
// 		)	
// 	}
				
// }

class CreatorProgramIntroPage extends React.Component{

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Join Creator Program',{
				stage:'intro',
			})
		}
	}



	render(){	
		
		
		return(
			<React.Fragment>
				<div className='joinCreatorProgramModal--intro-topLabel'>
					Introducing the Plickers Creator Program
				</div>
				<div className='joinCreatorProgramModal-header'>
					Share your content with teachers <br/> around the world.
				</div>				
				<div className='joinCreatorProgramModal--intro-howItWorks'>
					How it works
				</div>

				<div className='joinCreatorProgramModal--intro-bulletItem-title'>					
					Join the Plickers Creator Program for free
				</div>
				<div className='joinCreatorProgramModal--intro-bulletItem-label'>		
					Become a Creator and start publishing today.
				</div>

				<div className='joinCreatorProgramModal--intro-bulletItem-title'>					
					When you publish a pack, you’ll receive a unique share URL
				</div>
				<div className='joinCreatorProgramModal--intro-bulletItem-label'>		
					Distribute the share URL however you want, including on Twitter or Facebook.
				</div>

				<div className='joinCreatorProgramModal--intro-bulletItem-title'>					
					You’ll get a Creator profile page where all your public packs will be listed
				</div>						

				<div className='joinCreatorProgramModal--intro-bulletItem-title'>					
					Public packs are shared under a Creative Commons license (CC BY)
				</div>
				<div className='joinCreatorProgramModal--intro-bulletItem-label'>					
					This allows teachers to copy and remix content for their classrooms and share it forward. (However, no one can edit your public packs but you.)
				</div>
							

		
				<div className='joinCreatorProgramModal-submitArea'>
					
					<Button
						size='large'
						color='blue'
						label="I want to become a Creator"
						name='submit'
						onClickFunction={this.props.goToNextPage}						
					/>
					<Button
						size='large'
						color='white'
						label="Maybe later"
						name='cancel'
						onClickFunction={this.props.hideModal}						
					/>

				</div>
				

			</React.Fragment>

			
		)	
	}
				
}




export default CreatorProgramIntroPage



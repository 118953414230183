import React from 'react'
import ScorePill from './ScorePill'

class SetScoresArray extends React.Component{

	render(){	
		const {questionsAndPollsList, activeQuestionIndex} = this.props	

		return(

			<div className={'setScoresArray' + (questionsAndPollsList.length > 40 ? ' setScoresArray--over40' : '')}>				
				{questionsAndPollsList.map((questionAndPoll, index) => {	
					const poll=questionAndPoll.poll
					const css = {
       		 			transitionDelay: `calc(4ms * ${index})`,
  		  			}
  		  			if(poll){
						return(						
							<div key={index} className={'setScoresArray-setScore ' + (index === activeQuestionIndex ? ' setScoresArray-setScore--activeQuestion ' : '')} >
								<div style={css} className='setScoresArray-setScore-scorePill--container'>
									<ScorePill score={poll.correctPercent} />
								</div>
							</div>
						)}
					else{
						return(
							<div key={index} className={'setScoresArray-setScore setScoresArray-setScore--isUnasked ' + (index === activeQuestionIndex ? ' setScoresArray-setScore--activeQuestion ' : '')}>
								<div style={css} className='setScoresArray-setScore-scorePill--container setScoresArray-setScore-scorePill--container---absent'>
									<ScorePill isAbsent />
								</div>
							</div>
						)}
				})}											
			</div>					
		)
	}
				
}



SetScoresArray.defaultProps = {
	questionsAndPollsList: [],
}

export default SetScoresArray


import {setChoiceIndiciesTransaction} from '../setChoiceIndiciesTransaction'
 
export function deleteChoice(state, pos, choiceNodeToDelete){
	let tr=state.tr
	tr.setMeta("triggerParseDoc", true) 
	tr.delete(pos,pos+choiceNodeToDelete.nodeSize)
	tr=setChoiceIndiciesTransaction(tr)
	if(choiceNodeToDelete.attrs.correct){ //if delete the correct choice then set to survey
		tr.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(node.attrs.activeslide && node.attrs.activeslide===true){
				tr.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
					if(questionNode.type.attrs.correct) {
						tr.setNodeMarkup(questionPos, null, {...questionNode.attrs,correct:false,isSurvey:true})
					}
				})
			} 
		})
	}

	//if deleted node isTouched=false then check if all remaining choices in choice group are touched
	if(choiceNodeToDelete.attrs.isTouched===false){
		let groupHasIncompleteChoice=false
		let groupNode
		let groupNodePos 
		window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {  
			if(node.attrs.activeslide && node.attrs.activeslide===true){
				window.view.state.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
					if(questionNode.type.name === "choiceGroup"){
						groupNode=questionNode
						groupNodePos=questionPos
						window.view.state.doc.nodesBetween(questionPos,questionPos+questionNode.nodeSize, (choiceNode, choicePos) => {
							if(choiceNode.type.name==='choice'){
								if(choiceNode !== choiceNodeToDelete && choiceNode.attrs.isTouched===false){
									groupHasIncompleteChoice=true
								}
							}
						})
					}    
				})
			}
		})
		if(!groupHasIncompleteChoice){
			tr.setNodeMarkup(groupNodePos, null, {...groupNode.attrs,choicesComplete:true})
		}
	}

	window.view.dispatch(tr)
}
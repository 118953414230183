export default function (state = null, action) {
  switch (action.type) {
    case 'ENSURE_CLIENT_IDENTITY_SUCCESS':
      return action.response
    case 'TRUST_CLIENT_IDENTITY_SUCCESS':
      return {
        ...state,
        isTrusted: true,
      }
    case 'LOGOUT':
      return {
        ...state,
        isTrusted: null,
      }
    default:
      return state
  }
}

import React from 'react'
import Button from '../uiKit/Button'
import Icon from '../misc/Icons'


//This has all the generic modal properties that will be common to all the modal components

class ModalWrapper extends React.Component{

	constructor(){
		super()
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)    
	}

	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden' //prevent scrolling while modal is open
	}

 
	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}


	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.props.hideModal() //close modal is click outside modal
	}


	render(){   
		const {modalStyle, showModalHeader, cancelBtn, closeBtn, title} = this.props
		if(title){
			var titleClass = title.replace(/\s+/g, '-').toLowerCase()
	 }

		return (
			<div className={'modal-backdrop ' + (modalStyle ? `is--${modalStyle} ` : '') + (titleClass ? ` ${titleClass} ` : '')} onMouseDown={this.handleBackgroundClick} >
				{closeBtn &&
					<Icon name='close'/>
				}
		
				<div className='modal-content'>
				
					{showModalHeader && 

					<div className='modal-content-header'>          
						<div className='modal-content-header-center'>
							{this.props.iconName && 
							<Icon name={this.props.iconName}/>
							}
							<div className='modal-content-header-title'>
								{this.props.title}
							</div>
						</div>
						
						{cancelBtn &&
						<Button label='Cancel' labelColor='light' onClickFunction={this.props.hideModal} />
						}
						
					</div>

					}

					{this.props.children}
				</div>           
			</div>
		)
	}
}

ModalWrapper.defaultProps = {
	closeBtn: true,
	cancelBtn: true,
	showModalHeader: true,
}

export default ModalWrapper
import React from 'react'
import EditorIcon from '../../../misc/EditorIcons'
import { formatVideoDuration } from '../../../../utils/timestampFormatters/formatVideoDuration'

class StaticSlideVideoStatic extends React.Component {
  constructor() {
    super()
    this.onLoad = this.onLoad.bind(this)
  }

  onLoad() {
    if (this.props.questionMediaPromiseResolve) { // slide service
      console.log('question video thumbnail onLoad')
      this.props.questionMediaPromiseResolve()
    }
  }

  render() {
    const { video, slideTemplate, questionMediaDynamicHeight } = this.props
    let thumbnailContainerWidth
    let thumbnailContainerHeight

    if ((slideTemplate === 'bodyLeftMediaRight') || (slideTemplate === 'bodyRightMediaLeft')) {
      thumbnailContainerWidth = 580 // should come from template definitions or somewhere
      thumbnailContainerHeight = 680 // should come from template definitions or somewhere
    }
    if (slideTemplate === 'bodyLeftMediaRightChoicesMedia') {
      thumbnailContainerWidth = 580 // should come from template definitions or somewhere
      thumbnailContainerHeight = 311 // should come from template definitions or somewhere
    }
    if (slideTemplate === 'bodyCenterMediaCenter') {
      thumbnailContainerWidth = 1160 // should come from template definitions or somewhere
      thumbnailContainerHeight = questionMediaDynamicHeight // pass in
    }

    const thumbnailWidth = 640 // fits better for now as many thumbnails are incorrectly sized
    const thumbnailHeight = 360 // fits better for now as many thumbnails are incorrectly sized

    const thumbnailAspect = thumbnailWidth / thumbnailHeight
    const containerAspect = thumbnailContainerWidth / thumbnailContainerHeight

    let fitThumbnailWidth
    let fitThumbnailHeight
    let isPortrait

    if (thumbnailAspect >= containerAspect) { // landscape
      fitThumbnailWidth = thumbnailContainerWidth
      fitThumbnailHeight = thumbnailContainerWidth / thumbnailAspect
    }
    if (thumbnailAspect < containerAspect) { // portrait
      fitThumbnailHeight = thumbnailContainerHeight
      fitThumbnailWidth = thumbnailContainerHeight * thumbnailAspect
      isPortrait = true
    }

    // //
    // // POSITIONING FOR BUTTONS ONTO IMAGE
    // //

    let topRightThumbnailRightXOffset = 0
    let durationOffset = 0
    let trimIndicatorWidth = thumbnailContainerWidth * 0.75

    if (isPortrait && slideTemplate === 'bodyRightMediaLeft') {
      topRightThumbnailRightXOffset = thumbnailContainerWidth - fitThumbnailWidth
      durationOffset = topRightThumbnailRightXOffset
    }

    if (slideTemplate === 'bodyCenterMediaCenter') {
      topRightThumbnailRightXOffset = (thumbnailContainerWidth - fitThumbnailWidth) / 2
      durationOffset = topRightThumbnailRightXOffset
      trimIndicatorWidth = fitThumbnailWidth * 0.75
    }

    if (slideTemplate === 'bodyLeftMediaRightChoicesMedia') {
      topRightThumbnailRightXOffset = thumbnailContainerWidth - fitThumbnailWidth
      trimIndicatorWidth = fitThumbnailWidth * 0.75
      // durationOffset = 0
    }

    // POSITIONING WITHIN CONTAINER

    let thumbnailLayoutStyle

    if (slideTemplate === 'bodyLeftMediaRight') {
      thumbnailLayoutStyle = { top: '0px', right: '0px' }
    }
    if (slideTemplate === 'bodyRightMediaLeft') {
      thumbnailLayoutStyle = { top: '0px', left: '0px' }
    }
    if (slideTemplate === 'bodyCenterMediaCenter') {
      thumbnailLayoutStyle = {
        top: '0px', margin: 'auto', left: '0px', right: '0px',
      }
    }

    const clipStartTime = video.start
    const clipEndTime = video.end
    const trimStartSeconds = clipStartTime
    const trimEndSeconds = clipEndTime
    // const trimmedDuration = trimEndSeconds - trimStartSeconds
    const { isClipped } = video
    const durationSeconds = video.fullDuration
    const endTrimFromRightSeconds = durationSeconds - trimEndSeconds
    const leftTrimPercentage = trimStartSeconds / durationSeconds
    const rightTrimPercentage = endTrimFromRightSeconds / durationSeconds
    const displayDurationSeconds = video.end - video.start
    const formattedDisplayDuration = formatVideoDuration(displayDurationSeconds)
    const videoTitle = video.title

    return (
      <React.Fragment>
        <div style={{ width: `${fitThumbnailWidth}px`, height: `${fitThumbnailHeight}px`, ...thumbnailLayoutStyle }} className='slide-media--video--static-thumbnailContainer'>
          <img
            onLoad={this.onLoad}
            alt=''
            src={video.thumbnail.src}
            className='slide-media--video--static-thumbnail'
          />
        </div>

        {/* TITLE */}
        {videoTitle && (
        <div style={{ width: `${fitThumbnailWidth}px`, left: `${topRightThumbnailRightXOffset}px` }} className='slide-mediaContainer--video--static-topLeftContainer'>
          <div className='slide-mediaContainer--video--static-videoTitle'>
            <div className='slide-mediaContainer--video--static-videoTitle-iconContainer'>
              <EditorIcon name='twoPartVideoIcon' />
            </div>
            <div className='slide-mediaContainer--video--static-videoTitle-title'>
              {videoTitle}
            </div>
          </div>
        </div>
        )}

        {/* DURATION */}
        {formattedDisplayDuration && (
        <div style={{ top: `${fitThumbnailHeight}px`, right: `${durationOffset}px` }} className='slide-mediaContainer--video--static-bottomRightContainer'>
          <div className='slide-mediaContainer--video--static-duration'>
            <div className='slide-mediaContainer--video--static-duration-duration'>
              {formattedDisplayDuration}
            </div>
          </div>
        </div>
        )}

        {/* TRIM INDICATOR */}
        {isClipped && (
        <div style={{ top: `${fitThumbnailHeight}px`, left: `${topRightThumbnailRightXOffset}px`, width: `${trimIndicatorWidth}px` }} className='slide-mediaContainer--video--static-trimIndicatorContainer'>
          <div className='slide-mediaContainer--video--static-trimIndicator'>
            <div style={{ left: `${leftTrimPercentage * 100}%`, right: `${rightTrimPercentage * 100}%` }} className='slide-mediaContainer--video--static-trimIndicator-trim' />
          </div>
        </div>
        )}
      </React.Fragment>
    )
  }
}

export default StaticSlideVideoStatic


export default function (state={}, action) {

	switch (action.type) {

	case 'FETCH_REPO_REQUEST':
		state[action.repoId]=true
		return state


	case 'LOGOUT':
		return {}

	default:
		return state

	}
}
import moment from 'moment'
import {
  orderAscending,
  standardDeviation,
  percentile,
  mean,
  percentilesPrefixedKeys,
} from '../metrics/simpleStats'
import { offsetTimestampByMs } from '../calibratedCurrentTime'

const currentTimeUrl = process.env.REACT_APP_CURRENT_TIME_URL

function getUnixTimeMs(momentTimestamp) {
  return momentTimestamp.format('x')
}

export function reportToSegment(reportData) {
  if (window.analytics) {
    window.analytics.track('Sync Clock', reportData)
  }
}

export function buildClockSyncReport({
  clockSyncId,
  receivedTime,
  roundTripMs,
  existingOffsetMs,
  measuredOffsetMs, // nullable
  version,
  extraParams,
  succeeded,
}) {
  const uncalibratedTime = receivedTime
  const existingCalibratedTime = offsetTimestampByMs(uncalibratedTime, existingOffsetMs)
  const base = {
    uncalibratedTime,
    uncalibratedTimeUnix: getUnixTimeMs(uncalibratedTime),
    existingCalibratedTime,
    existingCalibratedTimeUnix: getUnixTimeMs(existingCalibratedTime),
    roundTripMs,
    existingOffsetMs,
    version,
    clockSyncId,
  }
  const results = {
    measuredOffsetMs,
    offsetInMilliseconds: measuredOffsetMs, // legacy
    succeeded,
  }
  if (measuredOffsetMs !== null) {
    const calibratedTime = offsetTimestampByMs(receivedTime, measuredOffsetMs)
    results.calibratedTime = calibratedTime
    results.calibratedTimeUnix = getUnixTimeMs(calibratedTime)
  }
  return {
    ...base,
    ...results,
    ...extraParams,
  }
}

function whilst(condition, callback) {
  return new Promise(((resolve) => {
    function recurse() {
      if (condition()) {
        callback().then(() => recurse())
      } else {
        resolve()
      }
    }
    recurse()
  }))
}

export function wait(delay) {
  return new Promise(((resolve) => {
    setTimeout(resolve, delay)
  }))
}

function fetchCurrentTimeWithTimeout(timeoutMs = 1000) {
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), timeoutMs)
  return fetch(currentTimeUrl, {
    method: 'GET',
    signal: controller.signal,
  })
    .then((response) => {
      clearTimeout(timeoutId)
      return response.headers.get('current-time')
    })
    .catch(() => null)
}

function getCurrentTimeServerOffset(responseTimeoutMs) {
  const startTimestamp = moment()
  return fetchCurrentTimeWithTimeout(responseTimeoutMs)
    .then((result) => {
      const endTimestamp = moment()
      const roundTripMs = endTimestamp.diff(startTimestamp)
      const serverTimestamp = getUnixTimeMs(moment(result))
      const offset = serverTimestamp - getUnixTimeMs(endTimestamp) + (roundTripMs / 2)
      return {
        offset,
        roundTripMs,
      }
    })
    .catch(() => null)
}

export async function generateClockSyncReport(options) {
  const allResults = []

  function getOffset() {
    return getCurrentTimeServerOffset(options.responseTimeoutMs).then((it) => allResults.push(it))
  }

  function waitAndGetOffset() {
    return wait(options.delayBetweenRequestsMs).then(getOffset)
  }

  function waitAndStart() {
    return wait(options.waitBeforeFirstMs).then(getOffset)
  }

  function notDone() {
    return allResults.length < options.repeatCount
  }

  return new Promise((resolve) => {
    waitAndStart()
      .then(() => whilst(notDone, waitAndGetOffset))
      .then(() => {
        const results = allResults.filter((it) => it)
        const roundTripsOrdered = orderAscending(results.map((it) => it.roundTripMs))
        const offsetsOrdered = orderAscending(results.map((it) => it.offset))
        const roundTripLimitMs = percentile(roundTripsOrdered, 50) +
          standardDeviation(roundTripsOrdered)
        const timelyResults = results.filter((it) => it.roundTripMs < roundTripLimitMs)
        const offsets = timelyResults.map((it) => it.offset)
        const clockOffsetMs = offsets.length > 0 ? mean(offsets) : null
        const roundTrips = timelyResults.map((it) => it.roundTripMs)
        const meanRoundTripMs = roundTrips.length > 0 ? mean(roundTrips) : null

        const succeeded = clockOffsetMs !== null && !Number.isNaN(clockOffsetMs)

        const timeNow = moment()
        const extraParams = {
          repeatCount: options.repeatCount,
          waitBeforeFirstMs: options.waitBeforeFirstMs,
          delayBetweenRequestsMs: options.delayBetweenRequestsMs,
          timelyResultsCount: timelyResults.length,
          validResultsCount: results.length,
          roundTripLimitMs,
          ...percentilesPrefixedKeys(roundTripsOrdered, options.percentilesToCompute, 'roundTripMsP'),
          ...percentilesPrefixedKeys(offsetsOrdered, options.percentilesToCompute, 'offsetMsP'),
        }
        const reportParams = {
          receivedTime: timeNow,
          roundTripMs: meanRoundTripMs,
          measuredOffsetMs: clockOffsetMs,
          extraParams,
          succeeded,
        }

        resolve(reportParams)
      })
  })
}

import React from 'react'
import Icon from '../../misc/Icons'
import Select from 'react-select'
import 'react-select/dist/react-select.css'

class CardAssignmentSelectMenu extends React.Component{

	constructor(){
		super()
		this.onChange=this.onChange.bind(this)
	}

	selectMenuArrow(mouseDown,isOpen){	
		return(
			<div className='cardAssignmentSelectMenu-arrow'>
				<Icon name='triangle-down' />			
			</div>					
		)
	}

	studentCardOption(option){	
		return(
			<div className='cardAssignmentSelectMenu-option'>
				<div className='cardAssignmentSelectMenu-option-cardNo'>
					{option.label}
				</div>
				<div className='cardAssignmentSelectMenu-option-explainText'>
					{option.rightLabel}
				</div>
			</div>					
		)
	}

	selectMenuValue(){	
		return(
			<div className='cardAssignmentSelectMenu-value'>
				<div className='cardAssignmentSelectMenu-value-closed'>
				 Edit Student Card Number
				</div>
				<div className='cardAssignmentSelectMenu-value-open'>
				 Select a Card...
				</div>
			</div>					
		)
	}

	onChange(option){
		this.props.showConfirmEditStudentCardModal(option,this.props.activeStudent)
	}

	render(){			
		let options = []
		this.props.cardsWithStudents.map((cardWithStudent,index)=>{
			let option
			if(cardWithStudent.student ===this.props.activeStudent){
				option={value:cardWithStudent.card,label:`Card ${cardWithStudent.card}`, rightLabel:'Current Card',swapStudent:null}
			}else if(cardWithStudent.student){
				option={value:cardWithStudent.card,label:`Card ${cardWithStudent.card}`, rightLabel:`Swap with ${cardWithStudent.student.firstName}`,swapStudent:cardWithStudent.student}
			}else{
				option={value:cardWithStudent.card,label:`Card ${cardWithStudent.card}`, rightLabel:'',swapStudent:null}
			}
			options.push(option)
			return null
		}
		)
		return(
			<div className={'cardAssignmentSelectMenu'}>
			  <Select				   	
			    onChange={this.onChange}				    
			    options={options}				    
			    optionRenderer={this.studentCardOption}				 				    
			    valueRenderer={this.selectMenuValue}
			    arrowRenderer={this.selectMenuArrow}
			    searchable={false}				    
			    placeholder='Edit Student Card Number'
				 />
			</div>
		)
	}				
}

export default CardAssignmentSelectMenu



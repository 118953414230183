import React from 'react'
import Icon from '../misc/Icons'

const sizeClassMaps = {
  small: 'btn--small',
  medium: 'btn--mid',
  midLarge: 'btn--midLarge',
  large: 'btn--large',
  xLarge: 'btn--xLarge',
}

const colorClassMaps = {
  white: 'btn--white',
  blue: 'btn--blue',
  dark: 'btn--dark',
  gray: 'btn--gray',
  red: 'btn--red',
  green: 'btn--green',
}

const iconColorClassMaps = {
  green: 'icon--green',
  gray: 'icon--gray',
}

const iconSizeClassMaps = {
  large: 'icon--large',
}

const labelColorClassMaps = {
  light: 'label--light',
  black: 'label--black',
}

class Button extends React.PureComponent {
  buttonStyleClasses(props) {
    const { tooltipPosition } = this.props
    let btnClasses = 'btn'

    btnClasses += ` ${sizeClassMaps[props.size]}`
    btnClasses += ` ${colorClassMaps[props.color]}`

    if (props.iconColor) {
      btnClasses += ` ${iconColorClassMaps[props.iconColor]}`
    }

    if (props.sidePanelBtn) {
      btnClasses += ' btn--sidePanel'
    }

    if (props.googleNoTranslate) {
      btnClasses += ' notranslate'
    }

    if (props.overflowEllipses) {
      btnClasses += ' btn--overflowEllipses'
    }

    if (props.ghost) {
      btnClasses += ' btn--ghost'
    }

    if (props.iconSize) {
      btnClasses += ` ${iconSizeClassMaps[props.iconSize]}`
    }

    if (props.labelColor) {
      btnClasses += ` ${labelColorClassMaps[props.labelColor]}`
    }

    if (!props.label) {
      btnClasses += ' btn--iconOnly'
    }

    if (!props.name && props.label) {
      const labelClass = props.label.replace(/\s+/g, '-').toLowerCase()
      btnClasses += ` ${labelClass}`
    }

    if (props.name) {
      const nameClass = props.name.replace(/\s+/g, '-').toLowerCase()
      btnClasses += ` ${nameClass}`
    }

    if (props.tooltipLabel) {
      btnClasses += ' tooltip'
    }

    if (props.tooltipLabelTypeLabel) {
      btnClasses += ' tooltip--label'
    }

    if (props.tooltipPosition) {
      btnClasses += ` tooltip--${tooltipPosition}`
    }

    if (props.type === 'submit') {
      btnClasses += ' is--submitBtn'
    }

    if (props.canSubmit === true) {
      btnClasses += ' can--submit'
    }

    if (props.canSubmit === false) {
      btnClasses += ' canNot--submit'
    }

    if (props.isActive === true) {
      btnClasses += ' is--active'
    }

    if (props.submitLoading === true) {
      btnClasses += ' submit--loading'
    }

    return btnClasses
  }

  render() {
    let btnClasses = this.buttonStyleClasses(this.props)
    const {
      disabled, id, tooltipLabel, label, iconName, type, submitLoading,
    } = this.props

    if (disabled) {
      btnClasses += ' btn--disabled'
    }

    return (
      <button
        type={type}
        disabled={disabled}
        onKeyDown={this.props.onKeyDown}
        data-tooltip={tooltipLabel}
        id={id}
        className={btnClasses}
        onClick={this.props.onClickFunction}
        onDoubleClick={this.props.onDoubleClickFunction}
      >

        {!submitLoading && iconName &&
        <Icon name={iconName} />}

        {!submitLoading ? label : ''}

        {submitLoading && (
        <div className='button-loadingSpinnerContainer'>
          <div className='button-loadingSpinner' />
        </div>
        )}

      </button>
    )
  }
}

Button.defaultProps = {
  size: 'medium',
  color: 'white',
  disabled: false,
  type: 'button',
}

export default Button

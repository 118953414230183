import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import Icon from '../misc/Icons'

// const SECTION_SCROLL_TO_OFFSETS = {
//   maths: 740,
//   video: 1631,
//   sound: 2506,
//   mediaChoices: 3307,
//   editImages: 4084,
//   shortcuts: 4896,
// }

class RichTextMarketingVideoTab extends Component {
  render() {
    const {
      sectionStartTime, sectionEndTime, videoCurrentTime, seekTo, videoId,
    } = this.props
    const active = videoCurrentTime > sectionStartTime && videoCurrentTime < sectionEndTime
    let progress = 0
    if (active) {
      progress = (videoCurrentTime - sectionStartTime) / (sectionEndTime - sectionStartTime)
    }

    return (
      <div onClick={() => { seekTo(videoId, sectionStartTime) }} className={`richTextMarketing-videoTab ${active ? 'richTextMarketing-videoTab--active' : 'richTextMarketing-videoTab--inactive'}`}>

        <div className='richTextMarketing-videoTab-activeLabel'>
          {this.props.label}
        </div>

        <div className='richTextMarketing-videoTab-inactiveLabel'>
          {this.props.label}
        </div>

        {active && progress && (
        <div className='richTextMarketing-videoTab-progressBar'>
          <div style={{ width: `${progress * 100}%` }} className='richTextMarketing-videoTab-progressBar-bar' />
          <div className='richTextMarketing-videoTab-progressBar-track' />
        </div>
        )}

      </div>
    )
  }
}

class RichTextMarketingContent extends Component {
  constructor(props) {
    super(props)
    this.updateVideoCurrentTime = this.updateVideoCurrentTime.bind(this)
    this.seekTo = this.seekTo.bind(this)
    this.handleOnScroll = this.handleOnScroll.bind(this)
    this.handleOnScroll = debounce(this.handleOnScroll, 10)
    // this.scrollToSection = this.scrollToSection.bind(this)

    this.timer = null

    this.state = {
      video1CurrentTime: 0,
      video2CurrentTime: 0,
      video3CurrentTime: 0,
      video4CurrentTime: 0,
      // activeSection: null,
    }
  }

  componentDidMount() {
    this.startTimer()
    this.video1.playbackRate = 1.3
    this.video2.playbackRate = 1
    this.video4.playbackRate = 1.5
    this.video3.playbackRate = 1.3
    // document.addEventListener('scroll', this.handleOnScroll)
    if (window.innerHeight > 700 && this.video1) {
      this.video1.play()
    }
  }

  componentWillUnmount() {
    this.stopTimer()
    // document.removeEventListener('scroll', this.handleOnScroll)
  }

  // scrollToSection(section) {
  //   const scrollAmount = SECTION_SCROLL_TO_OFFSETS[section]
  //   window.scrollTo(0, scrollAmount)
  //   setTimeout(() => {
  //     this.setState({ activeSection: section })
  //   }, 50)
  // }

  handleOnScroll() {
    const el = document.getElementById('scrollable_container')
    if (el) {
      const offset = el.scrollTop + window.innerHeight
      if (offset > 727 && this.video1) {
        this.video1.play()
      }
      if (offset > 1545 && this.video2) {
        this.video2.play()
      }
      if (offset > 2313 && this.video3) {
        this.video3.play()
      }
      if (offset > 3685 && this.video4) {
        this.video4.play()
      }
      if (offset > 4370 && this.video5) {
        this.video5.play()
      }
    }
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.updateVideoCurrentTime()
    }, 50)
  }

  stopTimer() {
    clearInterval(this.timer)
    this.timer = null
  }

  updateVideoCurrentTime() {
    if (this.video1) {
      const { currentTime } = this.video1
      this.setState({ video1CurrentTime: currentTime })
    }
    if (this.video2) {
      const { currentTime } = this.video2
      this.setState({ video2CurrentTime: currentTime })
    }
    if (this.video3) {
      const { currentTime } = this.video3
      this.setState({ video3CurrentTime: currentTime })
    }
    if (this.video4) {
      const { currentTime } = this.video4
      this.setState({ video4CurrentTime: currentTime })
    }
  }

  seekTo(videoId, seconds) {
    if (videoId === 'video1') {
      this.video1.currentTime = seconds
    }
    if (videoId === 'video2') {
      this.video2.currentTime = seconds
    }
    if (videoId === 'video3') {
      this.video3.currentTime = seconds
    }
    if (videoId === 'video4') {
      this.video4.currentTime = seconds
    }
    if (videoId === 'video5') {
      this.video5.currentTime = seconds
    }
  }

  render() {
    const modalMode = true

    const largeSlide = 'https://res.cloudinary.com/plickers-static/image/upload/w_1280,f_auto,q_100/'
    const smallSlide = 'https://res.cloudinary.com/plickers-static/image/upload/w_1280,f_auto,q_100/'

    const tundraSlide = 'v1632315673/richtextmarketing/slides/tundra.png'
    const rainfallSlide = 'v1632315670/richtextmarketing/slides/rainfall.png'
    const loroSlide = 'v1632315669/richtextmarketing/slides/loro.png'
    const simultaneousSlide = 'v1632315667/richtextmarketing/slides/simulataneous.png'
    const qweqSlide = 'v1632315665/richtextmarketing/slides/qweq.png'
    const romeoSlide = 'v1632315664/richtextmarketing/slides/romeo.png'
    const temperatureSlide = 'v1632315664/richtextmarketing/slides/temperature.png'
    const octogonSlide = 'v1632315662/richtextmarketing/slides/octogon.png'
    const exponentsSlide = 'v1632315661/richtextmarketing/slides/exponents.png'

    const megaequationSlide = 'v1632315655/richtextmarketing/slides/megaequation.png'

    // const unitedNationsSlide = "v1632315659/richtextmarketing/slides/united_nations.png"
    const spaceXSlide = 'v1632315659/richtextmarketing/slides/spacex.png'

    const trumpetSlide = 'v1632315656/richtextmarketing/slides/trumpet.png'

    const equilibriumSlide = 'v1632315654/richtextmarketing/slides/equilibirum.png'
    const mlkSlide = 'v1632315660/richtextmarketing/slides/mlk.png'
    // const whaleSlide = "v1632315658/richtextmarketing/slides/whales.png"
    const sarahAnimalSlide = 'v1632315658/richtextmarketing/slides/sarahananimal.png'

    return (

      <div className='richTextMarketingModalContainer' onScroll={this.handleOnScroll} id='scrollable_container'>
        <div className={`richTextMarketing ${modalMode ? 'richTextMarketing--modalMode' : 'richTextMarketing--pageMode'}`}>

          {modalMode && (
          <div onClick={this.props.dismissModal} className='richTextMarketing-closeBtn'>
            <Icon name='close' />
          </div>
          )}

          {/* HEADER */}
          <div className='richTextMarketing-header '>
            <div className='richTextMarketing-header-slideArray'>
              <div className='richTextMarketing-header-slideArray-small'>
                <div className='richTextMarketing-header-slideArray-small-slide'>
                  <img src={`${smallSlide}${tundraSlide}`} alt='' />
                </div>
                <div className='richTextMarketing-header-slideArray-small-slide'>
                  <img src={`${smallSlide}${rainfallSlide}`} alt='' />
                </div>
                <div className='richTextMarketing-header-slideArray-small-slide'>
                  <img src={`${smallSlide}${simultaneousSlide}`} alt='' />
                </div>
                <div className='richTextMarketing-header-slideArray-small-slide'>
                  <img src={`${smallSlide}${octogonSlide}`} alt='' />
                </div>
                <div className='richTextMarketing-header-slideArray-small-slide'>
                  <img src={`${smallSlide}${spaceXSlide}`} alt='' />
                </div>
                <div className='richTextMarketing-header-slideArray-small-slide'>
                  <img src={`${smallSlide}${romeoSlide}`} alt='' />
                </div>
                <div className='richTextMarketing-header-slideArray-small-slide'>
                  <img src={`${smallSlide}${temperatureSlide}`} alt='' />
                </div>
                <div className='richTextMarketing-header-slideArray-small-slide'>
                  <img src={`${smallSlide}${exponentsSlide}`} alt='' />
                </div>
                <div className='richTextMarketing-header-slideArray-small-slide'>
                  <img src={`${smallSlide}${loroSlide}`} alt='' />
                </div>
              </div>
              <div className='richTextMarketing-header-slideArray-large'>
                <div className='richTextMarketing-header-slideArray-large-slide'>
                  <img src={`${largeSlide}${equilibriumSlide}`} alt='' />
                </div>
                <div className='richTextMarketing-header-slideArray-large-slide'>
                  <img src={`${largeSlide}${megaequationSlide}`} alt='' />
                </div>
                <div className='richTextMarketing-header-slideArray-large-slide'>
                  <img src={`${largeSlide}${sarahAnimalSlide}`} alt='' />
                </div>
                <div className='richTextMarketing-header-slideArray-large-slide'>
                  <img src={`${largeSlide}${mlkSlide}`} alt='' />
                </div>
                <div className='richTextMarketing-header-slideArray-large-slide'>
                  <img src={`${largeSlide}${trumpetSlide}`} alt='' />
                </div>
                <div className='richTextMarketing-header-slideArray-large-slide'>
                  <img src={`${largeSlide}${qweqSlide}`} alt='' />
                </div>
              </div>
            </div>
          </div>

          {/* }
            <RichTextMarketingStickyNav activeSection={this.state.activeSection} scrollToSection={this.scrollToSection}/>
            */}

          {/* MATHS SECTION */}
          <div className='richTextMarketing-sectionContainer richTextMarketing-sectionContainer--math'>
            <div className='richTextMarketing-section richTextMarketing-section--maths'>
              <div className='richTextMarketing-section-label'>
                Math and Science notation
              </div>

              <div className='richTextMarketing-section-h1'>
                Full K-12 Math and Science support.
              </div>

              <div className='richTextMarketing-section-videoTabArray'>
                <RichTextMarketingVideoTab label='Equations' sectionStartTime={0} sectionEndTime={11} videoCurrentTime={this.state.video1CurrentTime} videoId='video1' seekTo={this.seekTo} />
                <RichTextMarketingVideoTab label='Exponents' sectionStartTime={11} sectionEndTime={29} videoCurrentTime={this.state.video1CurrentTime} videoId='video1' seekTo={this.seekTo} />
                <RichTextMarketingVideoTab label='Subscript' sectionStartTime={29} sectionEndTime={40} videoCurrentTime={this.state.video1CurrentTime} videoId='video1' seekTo={this.seekTo} />
                <RichTextMarketingVideoTab label='Symbols' sectionStartTime={40} sectionEndTime={53} videoCurrentTime={this.state.video1CurrentTime} videoId='video1' seekTo={this.seekTo} />
                <RichTextMarketingVideoTab label='Chemical Compounds' sectionStartTime={53} sectionEndTime={80} videoCurrentTime={this.state.video1CurrentTime} videoId='video1' seekTo={this.seekTo} />
              </div>

              <div className='richTextMarketing-section-videoDevice'>
                <div className='richTextMarketing-section-videoDevice-videoContainer'>

                  <video ref={(ref) => { this.video1 = ref }} loop muted preload='auto' className='richTextMarketing-section-videoDevice-video'>
                    <source src='https://assets.plickers.com/react-assets/richtextmarketing/RichTextMarketingScienceAndMath.mp4' type='video/mp4' />
                  </video>

                </div>
              </div>
            </div>
          </div>

          {/* MULTIMEDIA SECTION */}
          <div className='richTextMarketing-sectionContainer richTextMarketing-sectionContainer--videos'>
            <br />
            <br />
            <br />
            <div className='richTextMarketing-section richTextMarketing-section--maths'>
              <div className='richTextMarketing-section-label'>
                YouTube Videos
              </div>
              <div className='richTextMarketing-section-h1'>
                Bring questions to life with YouTube and GIPHY.
              </div>

              <div className='richTextMarketing-section-videoTabArray'>
                <RichTextMarketingVideoTab label='Insert YouTube Videos' sectionStartTime={0} sectionEndTime={14} videoCurrentTime={this.state.video2CurrentTime} videoId='video2' seekTo={this.seekTo} />
                <RichTextMarketingVideoTab label='Edit Videos' sectionStartTime={14} sectionEndTime={25} videoCurrentTime={this.state.video2CurrentTime} videoId='video2' seekTo={this.seekTo} />
                <RichTextMarketingVideoTab label='Insert GIPHY' sectionStartTime={25} sectionEndTime={42} videoCurrentTime={this.state.video2CurrentTime} videoId='video2' seekTo={this.seekTo} />
              </div>

              <div className='richTextMarketing-section-videoDevice richTextMarketing-section-videoDevice--video'>
                <div className='richTextMarketing-section-videoDevice-videoContainer'>

                  <video ref={(ref) => { this.video2 = ref }} loop muted preload='auto' className='richTextMarketing-section-videoDevice-video'>
                    <source src='https://assets.plickers.com/react-assets/richtextmarketing/RichTextMarketingVideoAndGIF.mp4' type='video/mp4' />
                  </video>

                </div>
              </div>
            </div>
          </div>

          {/* MULTIMEDIA SECTION */}
          <div className='richTextMarketing-sectionContainer'>
            <br />
            <br />
            <br />
            <div className='richTextMarketing-section richTextMarketing-section--maths'>
              <div className='richTextMarketing-section-label'>
                Sound Clips
              </div>
              <div className='richTextMarketing-section-h1'>
                Language and music teachers, this one's for you.
              </div>

              <div className='richTextMarketing-section-videoTabArray'>
                <RichTextMarketingVideoTab label='Record and Upload Audio' sectionStartTime={0} sectionEndTime={30} videoCurrentTime={this.state.video3CurrentTime} videoId='video3' seekTo={this.seekTo} />
                <RichTextMarketingVideoTab label='Edit Audio' sectionStartTime={30} sectionEndTime={40} videoCurrentTime={this.state.video3CurrentTime} videoId='video3' seekTo={this.seekTo} />
                <RichTextMarketingVideoTab label='Sound Library' sectionStartTime={40} sectionEndTime={58} videoCurrentTime={this.state.video3CurrentTime} videoId='video3' seekTo={this.seekTo} />
              </div>

              <div className='richTextMarketing-section-videoDevice'>
                <div className='richTextMarketing-section-videoDevice-videoContainer'>

                  <video ref={(ref) => { this.video3 = ref }} loop muted preload='auto' className='richTextMarketing-section-videoDevice-video'>
                    {/* <source src="https://res.cloudinary.com/plickers-static/video/upload/w_962/q_auto:best/v1599909667/elearningvideos/ELearningLearnMoreStudentBGVideo.B.mp4" type="video/mp4" /> */}

                    <source src='https://assets.plickers.com/react-assets/richtextmarketing/RichTextMarketingSounds.mp4' type='video/mp4' />
                  </video>

                </div>
              </div>
            </div>
          </div>

          <div className='richTextMarketing-sectionContainer richTextMarketing-sectionContainer--mediaChoices'>
            <br />
            <div className='richTextMarketing-section richTextMarketing-section--mediaChoices'>
              <div className='richTextMarketing-section-label'>
                Media Choices
              </div>
              <div className='richTextMarketing-section-h1'>
                Use anything as answer choices.
                <br />
                Images. Sounds. Videos. GIFs. Equations.
              </div>
              <div className='richTextMarketing-mediaChoice-slide richTextMarketing-mediaChoice-slide--1'>
                <img src={`${largeSlide}v1632315656/richtextmarketing/slides/trumpet.png`} alt='' />
              </div>
              {/* }
                <div className='richTextMarketing-mediaChoice-slide richTextMarketing-mediaChoice-slide--2'>
                  <img src="https://res.cloudinary.com/plickers-static/image/upload/v1632315662/richtextmarketing/slides/octogon.png" />
                </div>
                */}
              <div className='richTextMarketing-mediaChoice-slide richTextMarketing-mediaChoice-slide--3'>
                <img src={`${largeSlide}v1632233756/richtextmarketing/mediachoicesSounds.png`} alt='' />
              </div>
              <div className='richTextMarketing-mediaChoice-slide richTextMarketing-mediaChoice-slide--4'>
                <img src={`${largeSlide}v1632233227/richtextmarketing/mediachoicesImages.png`} alt='' />
              </div>
            </div>
          </div>

          {/* REMOTE CONTROL SECTION
            <div className='richTextMarketing-sectionContainer'>
              <br/>
              <br/>
              <br/>
              <div className='richTextMarketing-section richTextMarketing-section--remoteControl'>
                <div className='richTextMarketing-section-label'>
                  Remote Control
                </div>
                <div className='richTextMarketing-section-h1'>
                  And in class, control everything with a tap.
                </div>

                <div className='richTextMarketing-section-videoTabArray'>
                  <RichTextMarketingVideoTab label="Zoom" sectionStartTime={0} sectionEndTime={54} videoCurrentTime={this.state.video3CurrentTime} videoId={'video3'} seekTo={this.seekTo}/>
                  <RichTextMarketingVideoTab label="Playback" sectionStartTime={54} sectionEndTime={81} videoCurrentTime={this.state.video3CurrentTime} videoId={'video3'} seekTo={this.seekTo}/>
                </div>

                <div className='richTextMarketing-section-remoteControlVideoContainer'>

                  <div className='richTextMarketing-section-videoDevice-videoContainer'>
                    <video ref = {(ref) => { this.video3 = ref }} loop autoPlay muted preload="auto" poster="https://res.cloudinary.com/plickers-static/image/upload/w_1280,f_auto,q_100/v1632218191/richtextmarketing/examplemathposter.png" class="richTextMarketing-section-videoDevice-video">
                      <source src="https://res.cloudinary.com/plickers-static/video/upload/w_1280/q_auto:best/v1632218102/richtextmarketing/ExampleMathVideo.mp4" type="video/mp4" />
                    </video>
                  </div>

                </div>
              </div>
            </div>
            */}

          {/* IMAGES SECTION */}
          <div className='richTextMarketing-sectionContainer richTextMarketing-sectionContainer--images'>
            <div className='richTextMarketing-section richTextMarketing-section--images'>
              <div className='richTextMarketing-section-label'>
                Edit Images and Markup
              </div>
              <div className='richTextMarketing-section-h1'>
                Get images classroom ready, fast.
              </div>

              <div className='richTextMarketing-section-videoTabArray'>
                <RichTextMarketingVideoTab label='Crop and Edit' sectionStartTime={0} sectionEndTime={43} videoCurrentTime={this.state.video4CurrentTime} videoId='video4' seekTo={this.seekTo} />
                <RichTextMarketingVideoTab label='Markup Images' sectionStartTime={43} sectionEndTime={108} videoCurrentTime={this.state.video4CurrentTime} videoId='video4' seekTo={this.seekTo} />
              </div>

              <div className='richTextMarketing-section-imageVideoContainer'>
                <video ref={(ref) => { this.video4 = ref }} loop muted preload='auto' poster='https://res.cloudinary.com/plickers-static/image/upload/w_1280,f_auto,q_100/v1632305811/richtextmarketing/CropVideoPoster.png' className='richTextMarketing-section-videoDevice-video'>
                  <source src='https://assets.plickers.com/react-assets/richtextmarketing/RichTextMarketingImageEditing.mp4' type='video/mp4' />
                </video>
              </div>
            </div>
          </div>

          <div className='richTextMarketing-listContainer'>
            <div className='richTextMarketing-section richTextMarketing-section--list'>
              <div className='richTextMarketing-section-label'>
                and lots more
              </div>

              <div className='richTextMarketing-section-listColumnContainer'>
                <div className='richTextMarketing-section-listColumn'>
                  <ul>
                    <li>Import questions.</li>
                    <li>Paste images from your clipboard.</li>
                    <li>Format text with bold, italics, and highlighting.</li>
                    <li>Automatic fractions formatting.</li>

                  </ul>
                </div>
                <div className='richTextMarketing-section-listColumn'>
                  <ul>
                    <li>Graded and survey questions.</li>
                    <li>Advanced equations with custom LaTeX syntax.</li>
                    <li>
                      Insert symbols instantly with the
                      {' '}
                      <span>/</span>
                      {' '}
                      key.
                    </li>
                    <li>Print Set handouts.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className='richTextMarketing-bottomAreaContainer'>
            <div className='richTextMarketing-bottomArea'>
              <div className='richTextMarketing-bottomArea-text'>
                {'To learn more about using all the features above, head over to our Help Center page linked '}
                <a href='https://help.plickers.com/hc/en-us/articles/360008948754-The-Editor' target='_blank' rel='noreferrer'>
                  here
                </a>
                .
              </div>
              <button onClick={this.props.dismissModal} className='richTextMarketing-bottomArea-doneBtn'>
                Done
              </button>
            </div>
          </div>
          {/* }
          <div className='richTextMarketing-header-slideArray richTextMarketing-header-slideArray--2'>
                <div className='richTextMarketing-header-slideArray-small'>
                  <div className='richTextMarketing-header-slideArray-small-slide'>
                    <img src={`${smallSlide}${tundraSlide}`} />
                  </div>
                  <div className='richTextMarketing-header-slideArray-small-slide'>
                    <img src={`${smallSlide}${rainfallSlide}`} />
                  </div>
                  <div className='richTextMarketing-header-slideArray-small-slide'>
                    <img src={`${smallSlide}${simultaneousSlide}`} />
                  </div>
                  <div className='richTextMarketing-header-slideArray-small-slide'>
                    <img src={`${smallSlide}${octogonSlide}`} />
                  </div>
                  <div className='richTextMarketing-header-slideArray-small-slide'>
                    <img src={`${smallSlide}${spaceXSlide}`} />
                  </div>
                  <div className='richTextMarketing-header-slideArray-small-slide'>
                    <img src={`${smallSlide}${romeoSlide}`} />
                  </div>
                  <div className='richTextMarketing-header-slideArray-small-slide'>
                    <img src={`${smallSlide}${temperatureSlide}`} />
                  </div>
                  <div className='richTextMarketing-header-slideArray-small-slide'>
                    <img src={`${smallSlide}${exponentsSlide}`} />
                  </div>
                  <div className='richTextMarketing-header-slideArray-small-slide'>
                    <img src={`${smallSlide}${loroSlide}`} />
                  </div>
                </div>
                <div className='richTextMarketing-header-slideArray-large'>
                  <div className='richTextMarketing-header-slideArray-large-slide'>
                    <img src={`${largeSlide}${equilibriumSlide}`} />
                  </div>
                  <div className='richTextMarketing-header-slideArray-large-slide'>
                    <img src={`${largeSlide}${megaequationSlide}`} />
                  </div>
                  <div className='richTextMarketing-header-slideArray-large-slide'>
                    <img src={`${largeSlide}${sarahAnimalSlide}`} />
                  </div>
                  <div className='richTextMarketing-header-slideArray-large-slide'>
                    <img src={`${largeSlide}${mlkSlide}`} />
                  </div>
                  <div className='richTextMarketing-header-slideArray-large-slide'>
                    <img src={`${largeSlide}${trumpetSlide}`} />
                  </div>
                  <div className='richTextMarketing-header-slideArray-large-slide'>
                    <img src={`${largeSlide}${qweqSlide}`} />
                  </div>
                </div>

              </div>
            */}
        </div>
        <div onClick={this.props.dismissModal} className='richTextMarketing-bgClick' />
      </div>
    )
  }
}

export default RichTextMarketingContent

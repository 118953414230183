const ChoiceAnimatedGIFNodeSpec = {
  inline: false,
  attrs: {
    nodeId:{default:''},
    addedAt:{default:''},
    questionChoiceCount: {default:4},
    fileId:{default:''},
    title:{default:''},
    host:{default:''},
    stillUrl:{default:''},
    webpUrl:{default:''},
    gifUrl:{default:''},
    giphySlug:{default:null},
    giphyUser:{default:null},
    dimensions:{default:null},
    imageFill:{default:false},
    attribution:{default:''},
    placeholder:{default:''},
    placeholderDimensions:{default:{h:'1',w:'1'}},
    template: {default: null},
    slideWidth:{default:''}
  },
  selectable:false,
  draggable:false,
  group: 'block',
  parseDOM: [{tag: 'choiceAnimatedGIF'}], 
 toDOM(node) {
    return ['choiceAnimatedGIF']},
}
export default ChoiceAnimatedGIFNodeSpec
 
import api from '../api'

//https://docs.google.com/document/d/1gRlNTH9DLnb7qLSnBNCzVZdGusWV11lXPGuFkcTWMQM/edit#heading=h.uzf9e4y6wybz

export function fetchConfiguration() {
	return (dispatch) => api.fetch('/configuration')
		.then((response) => {
			dispatch({ type: 'FETCH_CONFIGURATION_SUCCESS', response })
		})
		.catch((error) => {
			return error
		})
}





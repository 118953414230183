import React from 'react'
import { connect } from 'react-redux'
import FeatureModalSelectControl from '../../components/featureModals/FeatureModalSelectControl'
import Button from '../../components/uiKit/Button'
import getErrorMessageForRequest from '../../utils/getErrorMessageForRequest'
import { assignSectionToDataOwner } from '../../actions/sections'
import { showNotification } from '../../actions/notifications'
import Icon from '../../components/misc/Icons'

class DataOwnerSelection extends React.Component {
  render() {
    const {
      userDataOwners,
      selectedDataOwner,
      selectDataOwner,
    } = this.props

    return (
      <div className='dataOwnerSelection'>
        <div className='dataOwnerSelection-header'>
          Select an organization
        </div>
        {userDataOwners.map((dataOwner) => (
          <div
            className='dataOwnerSelection-dataOwnerContainer'
            onClick={() => selectDataOwner(dataOwner)}
            key={dataOwner.id}
          >
            <FeatureModalSelectControl
              checked={selectedDataOwner.id === dataOwner.id}
              radio
            />
            <div
              className={`dataOwnerSelection-dataOwnerContainer-card dataOwnerSelection-dataOwnerContainer-card${selectedDataOwner.id === dataOwner.id ? '--active' : '--inactive'}`}
            >
              <div className='dataOwnerSelection-dataOwnerContainer-card-name'>
                {dataOwner.name}
              </div>
              <div className='dataOwnerSelection-dataOwnerContainer-card-location'>
                {dataOwner.locationName}
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

class AssignSectionToDataOwnerContainer extends React.Component {
  constructor(props) {
    super()
    this.assignToDataOwner = this.assignToDataOwner.bind(this)
    this.state = {
      selectedDataOwner: props.userDataOwners[0],
      confirmChecked: false,
      submitLoading: false,
      errorMessage: '',
    }
  }

  async assignToDataOwner() {
    const {
      section,
      assignSectionToDataOwner,
      showNotification,
      hideModal,
    } = this.props
    const { selectedDataOwner } = this.state
    try {
      this.setState({ submitLoading: true, errorMessage: '' })
      await assignSectionToDataOwner(section.id, selectedDataOwner.id)
      this.setState({ submitLoading: false })
      showNotification(
        `${section.name} to ${selectedDataOwner.name}`,
        'Assigned',
      )
      hideModal()
    } catch (e) {
      this.setState({ submitLoading: false })
      const errorMessage = getErrorMessageForRequest({
        requestName: 'associateDataOwner',
        response: e.response,
      })
      this.setState({ errorMessage })
    }
  }

  render() {
    const { section, userDataOwners } = this.props
    const { selectedDataOwner } = this.state
    const { confirmChecked, submitLoading, errorMessage } = this.state
    return (
      <div className='assignSectionToDataOwnerContainer'>
        <React.Fragment>
          <div className='assignSectionToDataOwnerContainer-sectionLabel'>
            <div className={`assignSectionToDataOwnerContainer-sectionLabel-circle assignSectionToDataOwnerContainer-sectionLabel-circle--classColor--${section.color}`}>
              <Icon name='circle' />
            </div>
            <div className='assignSectionToDataOwnerContainer-sectionLabel-name'>
              {section.name}
            </div>
          </div>
          <div className='assignSectionToDataOwnerContainer-dataOwnerSelectionContainer'>
            <DataOwnerSelection
              userDataOwners={userDataOwners}
              selectedDataOwner={selectedDataOwner}
              selectDataOwner={(selectedDataOwner) => this.setState({ selectedDataOwner })}
            />
          </div>
          <div className='assignSectionToDataOwnerContainer-description'>
            <div>Assigning this class to an organization facilitates compliance with data protection obligations by giving the selected organization some control over the data in the class – for example, the organization may delete the class in the event that your relationship with the organization ends.</div>
            <div>We will notify you when the organization takes any action using this access.</div>
            <div>This assignment is permanent and can&apos;t be undone.</div>
          </div>
        </React.Fragment>
        <div className='assignSectionToDataOwnerContainer-action'>
          <FeatureModalSelectControl
            checkbox
            checked={confirmChecked}
            onClick={() => this.setState((prevState) => ({ confirmChecked: !prevState.confirmChecked }))}
            label='I understand the above and agree to permanently assign this class to the selected organization'
          />
          <Button
            size='large'
            label='Assign'
            color='blue'
            onClickFunction={this.assignToDataOwner}
            disabled={!confirmChecked}
            submitLoading={submitLoading}
          />
          {errorMessage && (
            <div className='assignSectionToDataOwnerContainer-action-errorMessage'>
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  () => ({}),
  {
    assignSectionToDataOwner,
    showNotification,
  },
)(AssignSectionToDataOwnerContainer)

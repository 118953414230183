import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {hideAutoplayFailedAlert} from '../../actions/autoplayFailedAlert'
import {showAutoplaySetupModal} from '../../actions/autoplaySetupModal'
import LiveViewAutoplayFailedAlert from '../../components/liveView/LiveViewAutoplayFailedAlert'
import { Spring } from  'react-spring/renderprops'

const isLiveviewPage = (location) => { 
	if(
	(location.pathname.indexOf('/liveview')>-1)  ||
		location.hash==='#now-playing'
	)return true
	else return false
}


class LiveViewAutoplayFailedAlertContainer extends Component {
	constructor() {
		super()
		this.showAutoplaySetupModal=this.showAutoplaySetupModal.bind(this)
	}

	showAutoplaySetupModal(){
		this.props.showAutoplaySetupModal()
		this.props.hideAutoplayFailedAlert()
	}

	render() {
		const {showAutoplayFailedAlert} = this.props
		const {location}=this.props.history	
		if(isLiveviewPage(location)){	
			return( 
				<Spring
					to={{
						opacity: showAutoplayFailedAlert ? 1 : 0,
						yTranslate: showAutoplayFailedAlert ? 0 : 15,
					}}
					config={{ tension: 180, friction: 12 }}
				>
				{props => 
					<div 
						style={{ transform: `translateY(${props.yTranslate}px)`, opacity:`${props.opacity}`}}
						className={'liveView-autoPlayFailedAlert ' + (showAutoplayFailedAlert ? 'liveView-autoPlayFailedAlert--visible ' : 'liveView-autoPlayFailedAlert--hidden ')}
					>
						<LiveViewAutoplayFailedAlert 
							showAutoplaySetupModal={this.showAutoplaySetupModal}
						hideAutoplayFailedAlert={this.props.hideAutoplayFailedAlert}
						/>
					</div>
				}					
			</Spring>				
		)}else return null
	}
}


function mapStateToProps(state) {
	return {
		showAutoplayFailedAlert:state.autoplayFailedAlert.visible,
	}
}

export default withRouter(connect(
	 mapStateToProps,
	{hideAutoplayFailedAlert,showAutoplaySetupModal }
)(LiveViewAutoplayFailedAlertContainer))




import moment from 'moment'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import forEach from 'lodash/forEach'
import findIndex from 'lodash/findIndex'

// Student display names
// If no students share the same first name then displayName=firstName (Sarah)
// Else displayName=firstName + lastName initial (Sarah W)
// If 2 students have same first name and same lastName initial then display name is firstName + last Name (Sarah Williams)

// Calculate display name for each student and add to studentObject before saving in redux so can access using student.displayName throughout app
function calculateStudentDisplayNames(students) {
  if (students[0] && students[0].firstName) {
    // group students by firstName
    const groupedStudents = groupBy(students, (student) => student.firstName.toString().toLowerCase())
    // if there are more than one student with the same first name, group again by first letter of last name
    forEach(groupedStudents, (value, key) => {
      if (groupedStudents[key].length !== 1) {
        groupedStudents[key] = groupBy(groupedStudents[key], (student) => {
          if (student.lastName) {
            return student.lastName.toString().toLowerCase().slice(0, 1)
          }
          return ''
        })
      }
    })
    const studentsIncDisplayName = []
    Object.keys(groupedStudents).map((key) => {
      const studentsWithSameFirstName = groupedStudents[key]
      if (studentsWithSameFirstName.length === 1) { // if only one student with that first name use firstName as displayName
        const student = { ...studentsWithSameFirstName[0], displayName: studentsWithSameFirstName[0].firstName }
        studentsIncDisplayName.push(student)
      } else {
        Object.keys(studentsWithSameFirstName).map((key) => { // If more than one student with that firstName check how many have the same initial
          const studentsWithSameFirstInitial = studentsWithSameFirstName[key]
          if (studentsWithSameFirstInitial.length === 1) { // if only one student with the same first name +initial use firstName + initial as display name
            const student = { ...studentsWithSameFirstInitial[0], displayName: `${studentsWithSameFirstInitial[0].firstName} ${studentsWithSameFirstInitial[0].lastName.slice(0, 1)}` }
            studentsIncDisplayName.push(student)
          } else {
            Object.keys(studentsWithSameFirstInitial).map((key) => { // if more than one student with the same initial, use firstName + lastName as display name
              const student = { ...studentsWithSameFirstInitial[key], displayName: `${studentsWithSameFirstInitial[key].firstName} ${studentsWithSameFirstInitial[key].lastName}` }
              studentsIncDisplayName.push(student)
              return null
            })
          } return null
        })
      }
      return null
    })
    return studentsIncDisplayName
  } return students
}

// TODO handle archived section action
export default function (state = [], action) {
  switch (action.type) {
    case 'FETCH_All_SECTIONS_SUCCESS': {
      const sortedSections = sortBy(action.response, [function (section) {
        return section.name.toString().toLowerCase()
      }])
      const sections = []
      sortedSections.map((section) => {
        const modifiedSection = { ...section, students: calculateStudentDisplayNames(section.students) }
        sections.push(modifiedSection)
        return null
      })
      return sections
    }
    case 'FETCH_SECTION_SUCCESS': {
      const fetchedSectionId = action.response.id
      let indexOfSectionFetched = findIndex(state, (section) => section.id === fetchedSectionId)
      if (indexOfSectionFetched === -1) {
        indexOfSectionFetched = state.length
      }
      const { students } = action.response
      const updatedArray = [
        ...state.slice(0, indexOfSectionFetched),
        { ...action.response, students: calculateStudentDisplayNames(students) },
        ...state.slice(indexOfSectionFetched + 1),
      ]
      const sorted = sortBy(updatedArray, [function (section) {
        return section.name.toString().toLowerCase()
      }])
      return sorted
    }
    case 'CREATE_SECTION_SUCCESS': {
      const newSectionsArray = [...state, action.response]
      const sortedNewSections = sortBy(newSectionsArray, [function (section) {
        return section.name.toString().toLowerCase()
      }])
      return sortedNewSections
    }
    case 'UPDATE_SECTION_SUCCESS': {
      const updatedSectionId = action.response.id
      let indexOfSectionToUpdate = findIndex(state, (section) => section.id === updatedSectionId)
      if (indexOfSectionToUpdate === -1) {
        indexOfSectionToUpdate = state.length
      }
      let sectionStudents = action.response.students
      if (!action.studentsPopulated && state[indexOfSectionToUpdate]) {
        sectionStudents = state[indexOfSectionToUpdate].students
      }
      const updatedSectionsArray = [
        ...state.slice(0, indexOfSectionToUpdate),
        { ...action.response, students: calculateStudentDisplayNames(sectionStudents) },
        ...state.slice(indexOfSectionToUpdate + 1),
      ]
      const sortedUpdatedSections = sortBy(updatedSectionsArray, [function (section) {
        return section.name.toString().toLowerCase()
      }])
      return sortedUpdatedSections
    }
    /// ///////STUDENTS
    case 'CREATE_STUDENT_REQUEST': {
      const updatedSectionRequest = action.data.section
      const indexRequest = findIndex(state, (section) => section.id === updatedSectionRequest)
      const newStudentsArray = [...state[indexRequest].students, action.data]
      return [
        ...state.slice(0, indexRequest),
        { ...state[indexRequest], students: calculateStudentDisplayNames(newStudentsArray) },
        ...state.slice(indexRequest + 1),
      ]
    }
    case 'CREATE_STUDENT_SUCCESS': {
      const updatedSectionIdNew = action.response.section
      const index = findIndex(state, (section) => section.id === updatedSectionIdNew)
      const updatedSection = state[index]
      if (updatedSection) {
        const studentCreatedTime = moment(action.response.userCreated).toISOString()
        const studentCreatedIndex = findIndex(updatedSection.students, (student) => moment(student.userCreated).toISOString() === studentCreatedTime)
        let newArrayStudents = []
        if (studentCreatedIndex !== -1 && action.bulkAction !== true) {
          newArrayStudents = [...updatedSection.students.slice(0, studentCreatedIndex),
            action.response,
            ...updatedSection.students.slice(studentCreatedIndex + 1)]
        } else {
          newArrayStudents = [...updatedSection.students,
            action.response]
        }
        const sectionWithNewStudent = {
          ...state[index],
          students: calculateStudentDisplayNames(newArrayStudents),
        }

        return [
          ...state.slice(0, index),
          sectionWithNewStudent,
          ...state.slice(index + 1),
        ]
      } return state
    }
    case 'DELETE_STUDENT_REQUEST': {
      const studentSectionId = action.sectionId
      const sectionIndex = findIndex(state, (section) => section.id === studentSectionId)
      const sectionToupdate = state[sectionIndex]
      const { studentId } = action
      const indexStudent = findIndex(sectionToupdate.students, (student) => student.id === studentId)
      const updatedStudentArray = [...sectionToupdate.students.slice(0, indexStudent),
        ...sectionToupdate.students.slice(indexStudent + 1)]
      const updatedStudentSection = { ...state[sectionIndex], students: calculateStudentDisplayNames(updatedStudentArray) }
      return [
        ...state.slice(0, sectionIndex),
        updatedStudentSection,
        ...state.slice(sectionIndex + 1),
      ]
    }
    case 'UPDATE_STUDENT_REQUEST': {
      const sectionToUpdateIdRequest = action.sectionId
      const sectionToUpdateIndexRequest = findIndex(state, (section) => section.id === sectionToUpdateIdRequest)
      const sectionToUpdateRequest = state[sectionToUpdateIndexRequest]
      const studentToUpdateIdRequest = action.studentId
      const studentToUpdateIndexRequest = findIndex(sectionToUpdateRequest.students, (student) => student.id === studentToUpdateIdRequest)
      const updatedArrayStudentsRequest = [...sectionToUpdateRequest.students.slice(0, studentToUpdateIndexRequest),
        { ...action.data, id: action.studentId },
        ...sectionToUpdateRequest.students.slice(studentToUpdateIndexRequest + 1)]

      const sectionWithUpdatedStudentRequest = { ...state[sectionToUpdateIndexRequest], students: updatedArrayStudentsRequest }
      return [
        ...state.slice(0, sectionToUpdateIndexRequest),
        sectionWithUpdatedStudentRequest,
        ...state.slice(sectionToUpdateIndexRequest + 1),
      ]
    }
    case 'UPDATE_STUDENT_SUCCESS': {
      const sectionToUpdateId = action.sectionId
      const sectionToUpdateIndex = findIndex(state, (section) => section.id === sectionToUpdateId)
      const sectionToUpdate = state[sectionToUpdateIndex]
      const studentToUpdateId = action.studentId
      const studentToUpdateIndex = findIndex(sectionToUpdate.students, (student) => student.id === studentToUpdateId)
      const updatedArrayStudents = [...sectionToUpdate.students.slice(0, studentToUpdateIndex),
        action.response,
        ...sectionToUpdate.students.slice(studentToUpdateIndex + 1)]

      const sectionWithUpdatedStudent = { ...state[sectionToUpdateIndex], students: calculateStudentDisplayNames(updatedArrayStudents) }

      return [
        ...state.slice(0, sectionToUpdateIndex),
        sectionWithUpdatedStudent,
        ...state.slice(sectionToUpdateIndex + 1),
      ]
    }
    case 'LOGOUT':
      return []

    default:
      return state
  }
}

import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm} from 'redux-form'
import FormFieldText from '../../components/uiKit/FormFieldText'
import FormFieldSelect from '../../components/uiKit/FormFieldSelect'
import Button from '../../components/uiKit/Button'
import FormFieldClassColor from '../../components/section/FormFieldClassColor'


// const required = value => value ? undefined : 'Sorry but we are going to need you to tell us that'

const SectionYearOptions = [
	{value:'Pre-School', 		label:'Pre-School'},
	{value:'Kindergarten', 	label:'Kindergarten'},
	{value:'1st Grade', 		label:'1st Grade'},
	{value:'2nd Grade', 		label:'2nd Grade'},
	{value:'3rd Grade',		label:'3rd Grade'},
	{value:'4th Grade', 		label:'4th Grade'},
	{value:'5th Grade', 		label:'5th Grade'},
	{value:'6th Grade', 		label:'6th Grade'},
	{value:'7th Grade', 		label:'7th Grade'},
	{value:'8th Grade', 		label:'8th Grade'},
	{value:'9th Grade', 		label:'9th Grade'},
	{value:'10th Grade', 		label:'10th Grade'},
	{value:'11th Grade', 		label:'11th Grade'},
	{value:'12th Grade', 		label:'12th Grade'},
]

const SectionSubjectOptions = [
	{value:'general education', 			label:'General education'},
	{value:'science', 					label:'Science'},
	{value:'math', 						label:'Math'},
	{value:'literature/Language Arts', 	label:'Literature/Language Arts'},
	{value:'history/Social Studies', 		label:'History/Social Studies'},
	{value:'world Languages', 			label:'World Languages'},
	{value:'computers', 					label:'Computers'},
	{value:'creative Arts', 				label:'Creative Arts'},
	{value:'music', 						label:'Music'},
	{value:'health and PE', 				label:'Health and PE'},
	{value:'special Education', 			label:'Special Education'},
	{value:'career and Technical Education', label:'Career and Technical Education'},
	{value:'other', 						label:'Other'},
]

//TODO validation on section name
class EditSectionForm extends React.Component{
	
	render(){
		const { handleSubmit } = this.props		
		return(
			<div className='sectionSettings-form'>		
				<form onSubmit={handleSubmit}>				    
					
					<Field 
						name='name'
						type='text'	
						component={FormFieldText}
						placeholder='e.g. Period 2 Biology'				          	
						fieldLengthLimit={40}
					/>

					<FormFieldClassColor />

					<Field
						name='subject' 
						component={FormFieldSelect}
						options={SectionSubjectOptions}
						placeholderOptionLabel='Select a Subject'
					/>

					<Field
						name='year' 
						component={FormFieldSelect}
						options={SectionYearOptions}
						placeholderOptionLabel='Select a Year'
					/>

					<div className='sectionSettings-actionRow'>
						<Button size='large' label='Save Changes' color={!this.props.buttonDisabled && this.props.dirty && this.props.valid ? 'blue' : 'gray'} canSubmit={!this.props.buttonDisabled && this.props.dirty && this.props.valid ? true : false} type='submit'>Save</Button>
						<Button size='large' label='Cancel' canSubmit={this.props.dirty && this.props.valid ? true : false} onClickFunction={() =>{this.props.reset(this.props.editSectionForm)}} />
					</div>
				</form>	     		
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	if (ownProps.section) {
		return {
			initialValues: {
				name: ownProps.section.name,
				year: ownProps.section.year,
				subject: ownProps.section.subject,
				color: ownProps.section.color
			}
		}
	}else {
		return {
			initialValues: {
				name: '',
				year: '',
				subject: '',
				color: ''
			}
		}
	}
}

EditSectionForm = reduxForm({
	form: 'editSectionForm',
	enableReinitialize: true
})(EditSectionForm)

export default connect(mapStateToProps)(EditSectionForm)






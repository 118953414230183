import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateMeta } from '../../actions/meta'
import RichTextMarketingModal from '../../components/richTextMarketing/RichTextMarketingModal'

class RichTextMarketingModalContainer extends Component {
  constructor() {
    super()
    this.shouldShowModal = this.shouldShowModal.bind(this)
    this.dismissModal = this.dismissModal.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(e) {
    if (e.keyCode === 27) { // escape key
      this.dismissModal()
    }
  }

  shouldShowModal() {
    const { location } = this.props.history
    if (location.hash === '#whats-possible') {
      return true
    }
    return false
  }

  dismissModal() {
    window.location.hash = ''
    if (this.props.meta && this.props.meta.richTextModalDismissed) {
      // this.props.updateMeta({richTextModalDismissed:false})//dev
    } else {
      this.props.updateMeta({ richTextModalDismissed: true })
    }
  }

  render() {
    const { meta } = this.props
    if (this.shouldShowModal()) {
      return (
        <RichTextMarketingModal
          dismissModal={this.dismissModal}
          initialLoad={meta && !meta.richTextModalDismissed}
        />
      )
    }
    return null
  }
}

function mapStateToProps(state) {
  return {
    meta: state.meta,
  }
}

export default withRouter(connect(mapStateToProps,
  { updateMeta })(RichTextMarketingModalContainer))

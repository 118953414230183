import React from 'react'
import EditorSaveStatus from './EditorSaveStatus'

class EditorBottomRightPanel extends React.Component {
  render() {
    return (
      <div className='editor-bottomRightPanel'>
        <EditorSaveStatus
          isSaving={this.props.isSaving}
          unableToSave={this.props.unableToSave}
          item={this.props.item}
          parentRepo={this.props.parentRepo}
          itemIsLockedByAnotherUser={this.props.itemIsLockedByAnotherUser}
          lock={this.props.lock}
          permissions={this.props.permissions}
        />
        <button onClick={() => { window.location.hash = 'whats-possible' }} className='editor-bottomRightPanel-whatsPossibleBtn notranslate'>What's Possible</button>
      </div>
    )
  }
}
export default EditorBottomRightPanel

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { showLiveViewModal } from '../../utils/liveView/showLiveViewModal'
import Icon from '../misc/Icons'
import TopNavAlgoliaSearchBar from '../search/TopNavAlgoliaSearchBar'
import Button from '../uiKit/Button'
import { getUserDisplayName } from '../../utils/userDisplayName'
import HelpDropdown from '../HelpDropdown'
import ContextBadge from '../ContextBadge'

class TopNav extends Component {
  render() {
    return (
      <div className="topNav">

        <div className="topNav-left">
          <Link to="/recent">
            <div className="topNav-plickersLogo">
              <Icon name="logo" />
            </div>
          </Link>
          <Button label="Now Playing" onClickFunction={() => { showLiveViewModal() }} />
        </div>

        <div className={`topNav-center${this.props.nowPlaying ? ' nowPlaying--active' : ''}${this.props.nowPlayingSection ? ` has--class${this.props.nowPlayingSection.color}` : ''}`}>
          <TopNavAlgoliaSearchBar />
        </div>

        <div className="topNav-right">
          <ContextBadge />
          <Button
            name="Account"
            iconName="gear"
            label={getUserDisplayName(this.props.user)}
            googleNoTranslate
            onClickFunction={this.props.navigateToAccount}
          />
          <HelpDropdown />
        </div>
      </div>
    )
  }
}

export default TopNav

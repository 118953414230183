import React from 'react'
//import SetHeaderContent from '../printElements/SetHeaderContent'
import QuestionContent from '../../../printKit/QuestionContent'
import {isSurvey} from '../../../../utils/isSurvey'

class GhostSet extends React.Component{

	constructor(props) {
		super(props)
		this.measureGhosts=this.measureGhosts.bind(this)
		this.state = {
			heights:{},
		}
	} 


	componentDidMount(){
		this.measureGhosts()
	}



	componentDidUpdate(prevProps) {
		if(
			(this.props.columnCount!== prevProps.columnCount)
			||(this.props.showImages!== prevProps.showImages)
			||(this.props.paperSize!== prevProps.paperSize)||
			(this.props.includeStudentWorkingSpace!== prevProps.includeStudentWorkingSpace)){
			setTimeout(function() { //Start the timer
				this.measureGhosts()
			}.bind(this),	1)
		}
	}



	measureGhosts(){
		const {set}=this.props
		let heights={}

		set.questions.map((question,index) => {
			const el =this[`ghost${question.questionId}`]
			if(el){
				heights[question.questionId]=el.clientHeight						
			}
			return null
		})
		
		const spacerEl=this.spacer
		if(spacerEl){
			heights.spacer = spacerEl.clientHeight
		}

		this.setState({heights:heights})
		this.props.saveGhostHeights(heights)


	}







	render(){ 
		const {
			set,
			columnCount,
			showImages,
			paperSize,
			includeStudentWorkingSpace
		}=this.props


	

		return(
			<React.Fragment>
				<div className={'printPack printPack--ghostSpacer ' + (paperSize === 'letter' ? ' printPack--letter ': ' printPack--a4 ')}>
					<div className='printPack-page'>
						<div ref={c => {this.spacer = c}} className='printPack-page-spacer'/>
					</div>
				</div>
				<div className={'printPack printPack--ghost ' + (paperSize === 'letter' ? ' printPack--letter ': ' printPack--a4 ') + (columnCount === 2 ? 'printPack--twoColumn ' : '') + (columnCount === 1 ? 'printPack--singleColumn ' : '')}>
					<div className='printPack-page'> 
						<div className='printPack-page-pageColumn'>
													
							{set.questions.map((question,index) => {
					 		return(
					 			<div key={question.questionId} ref={c => {this[`ghost${question.questionId}`] = c}}  className={'printPackQuestion printPackQuestion--isGraded '+ (includeStudentWorkingSpace ? ' printPackQuestion--includeWorkingSpace' : '') }>		
						 		<QuestionContent
						 			showQuestionNumbers={true}
						 			showImages={showImages}
									questionCounter={index+1}
									question={question}	
									isSurvey={isSurvey(question)}	
									includeStudentWorkingSpace={includeStudentWorkingSpace}
									//includeStudentWorkingSpace={true}
								 
								/>
								</div>
						
							)	
					 	 	
					 	})}				 	 	
					
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}


export default GhostSet


export default function (state={}, action) {

	switch (action.type) {

	case 'FETCH_REPO_FOLLOWER_COUNT_SUCCESS':
		state[action.repoId]=action.followerCount
		return state


	case 'LOGOUT':
		return {}

	default:
		return state

	}
}
import React from 'react'
import EditorEditSoundModal from '../editorUI/modals/sound/EditorEditSoundModal'
import ReactDOM from 'react-dom'
import {stopAllMediaPlayback} from '../../../prosemirror/utils/editorActions/stopAllMediaPlayback'
import store from '../../../store'

const modalId='editor-editSoundModalContainer'

function closeInsertSoundModal(onCloseModal){
	ReactDOM.render(null, document.getElementById(modalId))
}


export function showEditSoundModal(fileId,title,attribution,fullDuration,start,end,node,nodePos,focusTitle) {
	stopAllMediaPlayback()
	store.dispatch({ type: 'HIDE_NOTIFICATION'})
	ReactDOM.render(
		<EditorEditSoundModal 
			focusTitle={focusTitle}
			isInsertFlow={false}
			fileId={fileId}
			soundTitle={title}
			attribution={attribution}
			fullDuration={fullDuration}
			start={start}
			end={end}
			closeModal={closeInsertSoundModal}
			closeInsertSoundModal={closeInsertSoundModal}
			node={node}
			nodePos={nodePos}
		/>, document.getElementById(modalId))
}
import schema from '../../schema/editorSchema'
import {getNewQuestionTemplateAddMedia} from '../getNewQuestionTemplate'
import {composeSoundDeliveryUrl} from '../composeSoundDeliveryUrl'
import {getActiveQuestionChoiceCount} from '../getActiveQuestionChoiceCount'
import {getActiveQuestionSlideWidth} from '../getActiveQuestionSlideWidth'
import tumblePodcastIds from '../../../staticData/tumblePodcastIds.js'
import includes from 'lodash/includes'


function sendInsertSoundAnalytics(soundAttrs,isQuestion,fromSoundLibrary,browseSoundLibraryCategory,searchSoundLibraryCategory){
	if(window.analytics){
		const isTumblePodcast=includes(tumblePodcastIds,soundAttrs.fileId) 
		window.analytics.track('Insert Sound', {
			isPromptMedia:isQuestion,
			title:soundAttrs.title,
			deliveryUrl:soundAttrs.deliveryUrl,
			isClipped:soundAttrs.isClipped,
			source:soundAttrs.source,
			fromSoundLibrary:fromSoundLibrary?true:false,
			fullDuration:soundAttrs.fullDuration,
			clipDuration:soundAttrs.end-soundAttrs.start,
			fileId:soundAttrs.fileId,
			browseSoundLibraryCategory:browseSoundLibraryCategory,
			searchSoundLibraryCategory:searchSoundLibraryCategory,
			isTumblePodcast:isTumblePodcast
	 })
	}
}


export function insertSound(isUpload,cloudinaryID,soundTitle,soundDuration,clipStartTime,clipEndTime,insertPos,isQuestion,questionPos,questionNode,fromSoundLibrary,browseSoundLibraryCategory,searchSoundLibraryCategory) {
	let type 
	var tr = window.view.state.tr
	let newTemplate
	if(isQuestion){ 
		newTemplate=getNewQuestionTemplateAddMedia(questionNode.attrs.template)//change template
		tr.setNodeMarkup(questionPos, null, {...questionNode.attrs,template:newTemplate})
		type = schema.nodes.questionSound 
	}else{
		type = schema.nodes.choiceSound
	}
	const start=clipStartTime||0
	const end=clipEndTime||soundDuration
	let isClipped=false
	if(end-start !==soundDuration){
		isClipped=true
	}
	const deliveryCloudinaryUrl=composeSoundDeliveryUrl(cloudinaryID,start,end,isClipped)
	const nowDate = new Date()
	const source=isUpload?'upload':'mic'

	let soundAttrs={
		fileId:cloudinaryID,
		deliveryUrl:deliveryCloudinaryUrl,
		template:newTemplate,
		start:start,
		end:end,
		title:soundTitle,
		fullDuration:soundDuration,
		attribution:'',
		isClipped:isClipped,
		source:source,
		addedAt:nowDate
	}
	sendInsertSoundAnalytics(soundAttrs,isQuestion,fromSoundLibrary,browseSoundLibraryCategory,searchSoundLibraryCategory)
 
	if(isQuestion){
		soundAttrs.questionMediaDynamicHeight=questionNode.attrs.questionMediaDynamicHeight
		soundAttrs.slideWidth=getActiveQuestionSlideWidth()
		window.view.state.doc.nodesBetween(questionPos,questionPos+questionNode.nodeSize, (node, pos) => {
			if (node.type.name === "choiceImage"||node.type.name === "choiceVideo"||node.type.name === "choiceSound"||node.type.name === "choice"){
				tr.setNodeMarkup(pos, null, {...node.attrs,template:newTemplate})          
			}
		})
	}else{
		soundAttrs.questionChoiceCount=getActiveQuestionChoiceCount()
		soundAttrs.slideWidth=getActiveQuestionSlideWidth()
	}

	const soundNode=type.create(soundAttrs)
	tr.replaceWith(insertPos.start,insertPos.end,soundNode)
	tr.setMeta("triggerParseDoc", true) 
	window.view.dispatch(tr)
	return
}
		
import React from 'react'
import ModalWrapper from './ModalWrapper'
import SectionSelectionContainer from '../../containers/modals/SectionSelectionContainer'

const SectionSelectionModal = props => {

	return (
    <div className="selectionModal-modal selectionModal-modal--section">
		    <ModalWrapper
	        {...props}
	        title={'Play Now'}		       
	        modalStyle='dark'
	        showModalHeader={false}
		    >
        	
        	<SectionSelectionContainer {...props} />    

        </ModalWrapper>
    </div>
  )
}

export default SectionSelectionModal
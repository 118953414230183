import React from 'react'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import { Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Icon from '../../misc/Icons'
import HelpVideoThumbnail from '../../uiKit/HelpVideoThumbnail'

class SectionHomeStudentsELearningSetupHelp extends React.Component {
  render() {
    const { sectionIsIntegrationRostered } = this.props
    return (
      <div className='sectionHome-students-eLearningSetupHelp'>
        <div className='sectionHome-students-eLearningSetupHelp-generalContainer'>
          <div className='sectionHome-students-eLearningSetupHelp-general'>
            <div className='sectionHome-students-eLearningSetupHelp-general-header'>
              E-Learning Setup
            </div>
            {!sectionIsIntegrationRostered && (
            <div className='sectionHome-students-eLearningSetupHelp-general-description'>
              <div className='sectionHome-students-eLearningSetupHelp-general-description-p'>
                No need to share a link every time you play. Plickers provides a registration link for each student that registers their device (like a Chromebook) to Plickers.
              </div>
              <div className='sectionHome-students-eLearningSetupHelp-general-description-p'>
                After clicking the registration link, your students always visit
                {' '}
                <em>my.plickers.com</em>
                {' '}
                on their device to answer live – no accounts, passwords, or daily links.
              </div>
              <a className='sectionHome-students-eLearningSetupHelp-general-helpLink' target='_blank' rel='noopener noreferrer' href='https://help.plickers.com/hc/en-us/categories/1260801512989-Remote-Learning'>
                Plickers E-Learning Help Guide
              </a>
            </div>
            )}
            {sectionIsIntegrationRostered && (
            <div className='sectionHome-students-eLearningSetupHelp-general-description'>
              <div className='sectionHome-students-eLearningSetupHelp-general-description-p'>
                No need to share a link every time you play. Plickers provides a registration link for each student that registers their device (like a Chromebook) to Plickers.
              </div>
              <div className='sectionHome-students-eLearningSetupHelp-general-description-p'>
                After clicking the registration link, your students always visit
                {' '}
                <em>my.plickers.com</em>
                {' '}
                on their device to answer live – no accounts, passwords, or daily links.
              </div>
              <div className='sectionHome-students-eLearningSetupHelp-general-description-p'>
                <em>Using your Google Classroom integration, Plickers can send each student a one-time email with their link </em>
                {' '}
                – you will be able to set a custom message to accompany the link.
              </div>
              <a className='sectionHome-students-eLearningSetupHelp-general-helpLink' target='_blank' rel='noopener noreferrer' href='https://help.plickers.com/hc/en-us/categories/1260801512989-Remote-Learning'>
                Plickers E-Learning Help Guide
              </a>
              <br />
              <br />
              <br />
              <button onClick={this.props.showDistributeStudentCodesGCModal} className='sectionHome-students-eLearningSetupHelp-primaryBtn'>
                Email links via Google Classroom...
                {' '}
                <Icon name='chevron-right' />
              </button>

            </div>
            )}
          </div>
          <div className='sectionHome-students-eLearningSetupHelp-videoContainer' onClick={() => { window.location.hash = 'e-learning-help' }}>

            <React.Fragment>
              <div className='sectionHome-students-eLearningSetupHelp-video'>
                <HelpVideoThumbnail title='Getting Started with Plickers E-Learning' />
                <div className='sectionHome-students-eLearningSetupHelp-video-label'>
                  Getting Started with E-Learning
                </div>
                <div className='sectionHome-students-eLearningSetupHelp-video-sub'>
                  Watch Video (4:35)
                </div>
              </div>
              <div className='sectionHome-students-eLearningSetupHelp-video-videoBG'>
                <svg width='240' height='160' viewBox='0 0 240 160'>
                  <path d='M3.71201466,6.25021111 C3.71201466,-2.2736339 13.7216023,0.626211183 43.5439639,0.626211183 C68.0934011,0.626211183 127.879709,-0.782763978 130.748036,0.626211183 C133.269043,1.86457638 239.180073,-4.28261598 238.157032,7.44910981 C237.059969,20.0296979 236.05904,56.9507123 236.010107,57.005999 C230.115296,63.666209 244.987732,137 236.010107,153.023526 C229.590074,164.482187 20.4880429,157.947133 11.1443465,154 C-8.88007659,145.540921 4.73987491,29.9756813 2.08485714,23.0573391 C-0.570160636,16.1389968 3.71201466,14.7740561 3.71201466,6.25021111 Z' />
                </svg>
              </div>
            </React.Fragment>

          </div>

        </div>

        {!sectionIsIntegrationRostered && (
        <div className='sectionHome-students-eLearningSetupHelp-shareLinksContainer'>
          <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock'>
            <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock-top'>
              <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock-top-option'>
                Option 1 (Fastest)
              </div>
              <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock-top-header'>
                Bulk copy all student links to clipboard
              </div>
            </div>
            <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock-bottom'>
              <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock-bottom-description'>
                <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock-bottom-description-p'>
                  Paste the links into a class email and share all the links with your class.
                </div>
                <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock-bottom-description-p'>
                  Students can find their name and click the appropriate link.
                </div>
              </div>
              <CopyToClipboard text={this.props.bulkCopyText} onCopy={() => { this.props.showNotification('Paste into a class email and share with your class.', 'E-Learning links copied to clipboard.', 'default') }} options={{ format: 'text/plain' }}>
                <button className='sectionHome-students-eLearningSetupHelp-primaryBtn'>
                  Bulk Copy all Student Links
                  {' '}
                  <Icon name='chevron-right' />
                </button>
              </CopyToClipboard>
            </div>
          </div>

          <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock'>
            <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock-top'>
              <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock-top-option'>
                Option 2
              </div>
              <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock-top-header'>
                Individually send each student their link
              </div>
            </div>
            <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock-bottom'>
              <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock-bottom-description'>
                <div className='sectionHome-students-eLearningSetupHelp-shareLinksBlock-bottom-description-p'>
                  If you think students may use other students links, you can share each link individually with each student.
                </div>
              </div>
            </div>
          </div>
        </div>
        )}

      </div>
    )
  }
}

class SectionHomeStudentsCard extends React.Component {
  constructor() {
    super()
    this.state = {
      tokenCopied: false,
    }
  }

  render() {
    const {
      firstName, lastName, sectionStudentDeviceAccessEnabled, studentDeviceToken,
    } = this.props
    const shortStudentDeviceURL = `${process.env.REACT_APP_SHORT_STUDENT_URL}/${studentDeviceToken}`
    const fullStudentDeviceURL = `${firstName} ${lastName}: ${process.env.REACT_APP_FULL_STUDENT_URL}/${studentDeviceToken}`

    if (!sectionStudentDeviceAccessEnabled) {
      return (
        <div className='sectionHome-students-studentCard notranslate'>
          {firstName}
          {' '}
          <span className='sectionHome-students-studentCard-lastName'>{lastName}</span>
        </div>
      )
    }
    return (
      <CopyToClipboard text={fullStudentDeviceURL} onCopy={() => this.setState({ tokenCopied: true })} options={{ format: 'text/plain' }}>
        <div onMouseLeave={() => { this.setState({ tokenCopied: false }) }} className='sectionHome-students-studentCard sectionHome-students-studentCard--device notranslate'>
          <div className='sectionHome-students-studentCard--device-primary'>
            {firstName}
            {' '}
            <span className='sectionHome-students-studentCard-lastName'>{lastName}</span>
          </div>

          <div className='sectionHome-students-studentCard--device-token'>
            <div className='sectionHome-students-studentCard--device-token-token'>
              {shortStudentDeviceURL}
            </div>
            {!this.state.tokenCopied && (
            <div className='sectionHome-students-studentCard--device-token-hover'>
              Copy Student Link
            </div>
            )}
            {this.state.tokenCopied && (
            <div className='sectionHome-students-studentCard--device-token-copied'>
              Copied to Clipboard
            </div>
            )}
          </div>
        </div>
      </CopyToClipboard>
    )
  }
}

class SectionHomeStudents extends React.Component {
  render() {
    const {
      currentSection, sectionStudentDeviceAccessEnabled, sectionIsIntegrationRostered, showELearningBanner,
    } = this.props
    const activeStudents = filter(currentSection.students, { archived: false })
    const students = sortBy(activeStudents, [(student) => student.displayName.toLowerCase()])

    let bulkCopyText = ''
    students.forEach((student) => {
      const studentString = `${student.firstName} ${student.lastName}: ${process.env.REACT_APP_FULL_STUDENT_URL}/${student.code}\n`
      bulkCopyText += studentString
    })

    const hideELearningSetupHelp = this.props.eLearningSetupHidden

    return (
      <div className='sectionHome-students sectionHome--block'>
        <div className='sectionHome-students-scrollRef' id='sectionStudents' />
        <div className='sectionHome--block-divider' />
        <div className='sectionHome--block-header'>
          {!sectionIsIntegrationRostered && (
          <div className='sectionHome--block-header-title'>
            Students
            {' '}
            <span className='sectionHome--block-header-title-count'>{activeStudents.length}</span>
          </div>
          )}

          {sectionIsIntegrationRostered && (
          <div className='sectionHome--block-header-title sectionHome--block-header-title--googleClassroomStudents'>
            <div className='sectionHome--block-header-title-integration-logoContainer'>
              <Icon name='googleClassroomLogo' />
            </div>
            Students
            {' '}
            <span className='sectionHome--block-header-title-count'>{activeStudents.length}</span>
            <div className='sectionHome--block-header-title-integration'>

              <div className='sectionHome--block-header-title-integration-label'>
                Provided by Google Classroom
              </div>
            </div>
          </div>
          )}

          {!hideELearningSetupHelp && sectionStudentDeviceAccessEnabled && (
          <div className='sectionHome--block-header-link' onClick={this.props.toggleHideELearningSetup}>
            Hide E-Learning Setup
          </div>
          )}

          {hideELearningSetupHelp && sectionStudentDeviceAccessEnabled && (
          <div className='sectionHome--block-header-link' onClick={this.props.toggleHideELearningSetup}>
            E-Learning Setup
          </div>
          )}

          {!sectionStudentDeviceAccessEnabled && !showELearningBanner && (
          <div className='sectionHome--block-header-link' onClick={() => { window.location.hash = 'e-learning-activation' }}>
            Try E-Learning
          </div>
          )}

          <Link to={`/classRoster/${this.props.currentSection.id}`}>
            <div className='sectionHome--block-header-link'>
              Print Roster
            </div>
          </Link>

          <Link to={`/classes/${currentSection.id}/students`}>
            <div className='sectionHome--block-header-link'>
              Edit Students
            </div>
          </Link>
        </div>

        {sectionStudentDeviceAccessEnabled && !hideELearningSetupHelp && (
        <SectionHomeStudentsELearningSetupHelp
          sectionIsIntegrationRostered={sectionIsIntegrationRostered}
          bulkCopyText={bulkCopyText}
          showNotification={this.props.showNotification}
          showDistributeStudentCodesGCModal={this.props.showDistributeStudentCodesGCModal}
        />
        )}

        <div className='sectionHome-students-studentCards--container'>
          {students.map((student, index) => (
            <SectionHomeStudentsCard key={index} studentDeviceToken={student.code} firstName={student.firstName} lastName={student.lastName} sectionStudentDeviceAccessEnabled={sectionStudentDeviceAccessEnabled} />
          ))}
        </div>

      </div>
    )
  }
}

export default SectionHomeStudents

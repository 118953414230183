import React from 'react'
import {formatDiscoverLatestTimestamp} from '../../utils/formatDiscoverLatestTimestamp'
import {calculatePlayCount} from '../../utils/calculatePlayCount'
import TopPageHeader from '../pageHeaders/TopPageHeader'
import StickyPageHeader from '../pageHeaders/StickyPageHeader'
import DiscoverFeaturedRepoLink from './DiscoverFeaturedRepoLink'
import DiscoverMiniRepoLink from './DiscoverMiniRepoLink'
import Icon from '../misc/Icons'
import DiscoverSubjectPageFooter from './DiscoverSubjectPageFooter'
import discoverRepoIds from '../../staticData/discoverRepoIds.js'

const mathsRepoShortIds=discoverRepoIds.mathsRepoShortIds
	
class DiscoverEarlyMathPage extends React.Component{

	UNSAFE_componentWillMount() {
		if(this.props.repos.length !== mathsRepoShortIds.length){
			mathsRepoShortIds.forEach((shortId)=>{
				this.props.fetchAPublishedRepoByShortId(shortId)
				.catch((error)=>{})
			})
		}
	}

	render(){
		const {repos}=this.props
		const discoverNavRow=
				<React.Fragment>								
					<div className='pageHeaderTop-folderNavRow-folderPathItem is--library' onClick={()=>{this.props.history.push('/discover')}}>
						Discover
					</div>
					<div className='pageHeaderTop-folderNavRow-folderPathItem is--folder is--currentFolder'>
		        <Icon name='chevron-right'/>			       
		         Early Math			        
				  </div>
				</React.Fragment>


		return(
			<div className="page page--extraWideLayout discover-market">
				<div className="page-leftPanel" />
				<div className='page-centerPanel'>
					<TopPageHeader
						header='Early Math'
						location='discoverMarket'
						headerInfoRow='2,500+ math questions for young learners · PreK - Grade 5'
						leftBadge={
							<React.Fragment>
								<Icon name='chevron-left' />										
							</React.Fragment>
						}
						leftBadgeClickFunction={()=>{this.props.history.push('/discover')}}
						folderNavRow={discoverNavRow}
					/>

					<StickyPageHeader 												
						header='Early Math'
					/>									
					
						<div className='discover-market-featuredReposContainer'>
							<div className='discover-market-featuredRepos-header'>
								Featured Packs
							</div>
							<div className='discover-market-featuredRepos-list'>

								{repos.map((repo)=>{
									return(
										<DiscoverFeaturedRepoLink
											key={repo.id}
											repo={repo}
											rating={repo.rating}
											market={'earlyMath'}
										/>																					
									)					

								})}
							</div>							
						</div> 	
						
						<div className='discover-market-subsectionReposContainer'>
							<div className='discover-market-subsectionRepos-divider'/>
							<div className='discover-market-subsectionRepos-header'>
								Latest Packs 
									<button className='discover-market-subsectionRepos-header-seeMoreBtn' onClick={()=>{this.props.history.push('/discover/earlyMath/latest')}}>
										See All
										<Icon name='chevron-right' />
									</button>

							</div>
							<div className='discover-market-subsectionRepos-list discover-market-subsectionRepos-list--12grid'>

								{this.props.latestRepos.map((repo)=>{
									return(
										<DiscoverMiniRepoLink
											market={'earlyMath'}
											loadRepoContent={this.props.loadRepoContent}
											key={repo.id}
											repo={repo}
											timestamp={formatDiscoverLatestTimestamp(repo.publishedAt)}
										/>																					
									)					

								})}
							</div>							
						</div> 	
							<div className='discover-market-subsectionReposContainer'>
								<div className='discover-market-subsectionRepos-divider'/>
								
								<div className='discover-market-subsectionRepos-header'>
									Most Popular Packs 
									{process.env.REACT_APP_ENV!== 'production' &&
									<button className='discover-market-subsectionRepos-header-seeMoreBtn' onClick={()=>{this.props.history.push('/discover/earlyMath/popular')}}>
										See All
										<Icon name='chevron-right' />
									</button>
								}

								</div>
							
								<div className='discover-market-subsectionRepos-list discover-market-subsectionRepos-list--12grid'>
									{this.props.popularRepos.map((repo)=>{
										return(
											<DiscoverMiniRepoLink
												list='popular'
												market={'earlyMath'}
												loadRepoContent={this.props.loadRepoContent}
												key={repo.id}
												repo={repo}
												playCount={calculatePlayCount(repo.responseCount)}
											/>																					
										)					
									})}
								</div>							
							</div> 	
					
					
							<div className='discover-market-subsectionReposContainer'>
								<div className='discover-market-subsectionRepos-divider'/>
								<div className='discover-market-subsectionRepos-header'>
									Highest Rated Packs 
									{process.env.REACT_APP_ENV!== 'production' &&
									<button className='discover-market-subsectionRepos-header-seeMoreBtn' onClick={()=>{this.props.history.push('/discover/earlyMath/rated')}}>
										See All
										<Icon name='chevron-right' />
									</button>
								}
								</div>
								<div className='discover-market-subsectionRepos-list discover-market-subsectionRepos-list--9grid'>

									{this.props.highlyRatedRepos.map((repo,i)=>{
										if(i<9){
											return(
												<DiscoverMiniRepoLink
													list='popular'
													market={'earlyMath'}
													loadRepoContent={this.props.loadRepoContent}
													key={repo.id}
													repo={repo}
													rating={repo.rating}
												/>																					
											)		
										}else return null
									})}
								</div>							
							</div> 	
					
						{/*
						<div className='discover-market-subsectionReposContainer'>
							<div className='discover-market-subsectionRepos-divider'/>
							<div className='discover-market-subsectionRepos-header'>
								Highest Rated Packs
							</div>
							<div className='discover-market-subsectionRepos-list'>

								{filteredRepos.map((repo)=>{
									return(
										<DiscoverMiniRepoLink
											repo={repo}
											rating={4.9}
										/>																					
									)					

								})}
							</div>							
						</div> 	
					*/}


						{/*}
							<button onClick={()=>{this.props.history.push(`${this.props.history.location.pathname}/latest`)}}>See All Latest</button>							
							*/}

						<DiscoverSubjectPageFooter 
							showNotification={this.props.showNotification}
							discoverMarket='earlyMath'
							showRequestTopic
							showFAQ
							showGeneralFeedback
						/>
				</div>
			<div className="page-rightPanel" />
			</div>
		)
	}
}


export default DiscoverEarlyMathPage



import moment from 'moment'

// 25 min up to 59 min
// 1 hr up to 96 hours
// then date like 14/03/20

export function formatTimeSinceSubmission(timestamp) {
	const nowDate =moment()
	let formatedTimestamp
	var seconds = Math.floor((nowDate - moment(timestamp)) / 1000)
	var minutes = Math.floor(seconds / 60)
	var hours = Math.floor(minutes / 60)

	if (hours < 1) { //is in the last hour
			if (minutes < 1) { //is in the last min
				formatedTimestamp = 'Just Now'
			} else {
				formatedTimestamp = `${minutes} min`
			}
	}else if(hours<96){
		formatedTimestamp = `${hours} hours`
	}else{
		formatedTimestamp=`${moment(timestamp).format('MM/DD/YYYY')}`
	}
	return formatedTimestamp
}
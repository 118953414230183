import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import {adminFetchSubmissions,adminAssignAndMoveToReview,adminLogEmailEvent,adminUpdateSubmission} from '../../actions/admin'
import {showAdminEditSubmissionStatusModal,hideModal,showConfirmModal} from '../../actions/modals'
import {showNotification} from '../../actions/notifications'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import PackReviewPage from '../../components/explorer/packreview/PackReviewPage'




class PackReviewContainer extends Component {


	constructor() {
		super()	
		this.keyPressNavigation=this.keyPressNavigation.bind(this)
		this.handleDocumentKeyDown=this.handleDocumentKeyDown.bind(this)
		this.assignAndMoveToReview=this.assignAndMoveToReview.bind(this)
		this.logEmailEvent=this.logEmailEvent.bind(this)
		this.emailAndKeepInReview=this.emailAndKeepInReview.bind(this)
		this.emailAndMarkCanceled=this.emailAndMarkCanceled.bind(this)
		this.logEmailAndCancel=this.logEmailAndCancel.bind(this)
		this.logEmailAndApprove=this.logEmailAndApprove.bind(this)
		this.emailAndMarkApproved=this.emailAndMarkApproved.bind(this)
		this.showMarkCanceledConfirmModal=this.showMarkCanceledConfirmModal.bind(this)
		this.cancelSubmission=this.cancelSubmission.bind(this)
		this.approveSubmission=this.approveSubmission.bind(this)
		this.showApproveSubmissionConfirmModal=this.showApproveSubmissionConfirmModal.bind(this)
		this.setSelectedSubission=this.setSelectedSubission.bind(this)
		this.changeFilter=this.changeFilter.bind(this)

		this.state = { 
			filter:'inbox',
			selectedSubmission:null
	    }
  	}

 
 
	UNSAFE_componentWillMount() {
		this.props.adminFetchSubmissions()
		document.addEventListener('keydown', this.handleDocumentKeyDown)
	}
	


	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleDocumentKeyDown)
	}


	changeFilter(newFilter){
		this.setState({filter:newFilter,selectedSubmission:null})
		const table=document.getElementById('focusablePackReviewTable')
		table.scrollTop = 0
	}






	handleDocumentKeyDown(e){
		const {selectedSubmission}=this.state
		if(!selectedSubmission){			
			if(e.keyCode ===40){//if press down arrow when no items selected then set first item to the active item 
					e.preventDefault()
					const table=document.getElementById('focusablePackReviewTable')
					if(table){
						table.focus()
					}
					const {filter}=this.state
					let allData=this.props.adminSubmissions
					if(filter==='active'){
						allData=this.props.activeSubmissions
					}else if(filter==='inbox'){
						allData=this.props.inbox
					}else if(filter==='in-review'){
						allData=this.props.inReviewSubmissions
					}
					else if(filter==='approved'){
						allData=this.props.approvedSubmissions
					}
					else if(filter==='cancelled'){
						allData=this.props.cancelledSubmissions
					}
					const newActiveItem=allData[0]
					this.setState({selectedSubmission:newActiveItem})

			}
		}
	}





	keyPressNavigation(e){
			const {selectedSubmission,filter}=this.state
			let allData=this.props.adminSubmissions
		if(filter==='active'){
			allData=this.props.activeSubmissions
		}else if(filter==='inbox'){
			allData=this.props.inbox
		}else if(filter==='in-review'){
			allData=this.props.inReviewSubmissions
		}
		else if(filter==='approved'){
			allData=this.props.approvedSubmissions
		}
		else if(filter==='cancelled'){
			allData=this.props.cancelledSubmissions
		}
			if(selectedSubmission){
			const activeIndex= findIndex(allData, function (item) {
				return item.id === selectedSubmission.id
			})

			let newSelectedIndex
			if (e.keyCode === 40) { 
				newSelectedIndex=Math.min(activeIndex+1,allData.length-1)
				const newSelected=allData[newSelectedIndex]	   
				this.setState({selectedSubmission:newSelected})				
			}
			else if (e.keyCode === 38) { 
				newSelectedIndex=Math.max(activeIndex-1,0)
				const newSelected=allData[newSelectedIndex]	   
				this.setState({selectedSubmission:newSelected})
			}

		}
			
	}


	logEmailEvent(){
		this.props.adminLogEmailEvent(this.state.selectedSubmission.id).then((response)=>{
			this.setState({selectedSubmission:{...response,repo:this.state.selectedSubmission.repo}})
			this.props.hideModal()
		})

	}

	logEmailAndCancel(){
		this.props.adminLogEmailEvent(this.state.selectedSubmission.id).then((response)=>{
			this.props.adminUpdateSubmission(this.state.selectedSubmission.id,'canceled').then((response)=>{
				this.setState({selectedSubmission:null})
				this.props.hideModal()
				this.props.showNotification('','Set To Canceled','default')
			})
		})

	}


	cancelSubmission(){
		this.props.adminUpdateSubmission(this.state.selectedSubmission.id,'canceled').then((response)=>{
				this.setState({selectedSubmission:null})
				this.props.hideModal()
				this.props.showNotification('','Set To Canceled','default')
			})
	}

	approveSubmission(){
		this.props.adminUpdateSubmission(this.state.selectedSubmission.id,'approved').then((response)=>{
				this.setState({selectedSubmission:{...response,repo:this.state.selectedSubmission.repo},filter:'approved'})
				this.props.hideModal()
				this.props.showNotification('','Approved Submission','default')
			})
	}

	logEmailAndApprove(){
		this.props.adminLogEmailEvent(this.state.selectedSubmission.id).then((response)=>{
			this.props.adminUpdateSubmission(this.state.selectedSubmission.id,'approved').then((response)=>{
				this.setState({selectedSubmission:{...response,repo:this.state.selectedSubmission.repo},filter:'approved'})
				this.props.hideModal()
				this.props.showNotification('','Set To Approved','default')
			})
		})

	}




	assignAndMoveToReview(assignedToNolan,assignedToJasper){
		let assignee='Joe'
		if(assignedToNolan){
			assignee='Nolan'
		}
		this.props.adminAssignAndMoveToReview(this.state.selectedSubmission.id,assignee).then(()=>{
			const newSelectedSubmission=find(this.props.adminSubmissions,{id:this.state.selectedSubmission.id})
			this.setState({selectedSubmission:newSelectedSubmission})
		})

	}


	emailAndKeepInReview(){
		this.props.showConfirmModal(this.state.selectedSubmission,this.logEmailEvent,'logEmailAndKeepInReview')

	}


	emailAndMarkCanceled(){
		this.props.showConfirmModal(this.state.selectedSubmission,this.logEmailAndCancel,'logEmailAndCancel')

	}
	
	emailAndMarkApproved(){
		this.props.showConfirmModal(this.state.selectedSubmission,this.logEmailAndApprove,'logEmailAndApprove')
	}	

	showMarkCanceledConfirmModal(){
		this.props.showConfirmModal(this.state.selectedSubmission,this.cancelSubmission,'cancelSubmission')

	}
	
	showApproveSubmissionConfirmModal(){
		this.props.showConfirmModal(this.state.selectedSubmission,this.approveSubmission,'approveSubmission')

	}

	setSelectedSubission(submission){
		if(this.state.selectedSubmission && this.state.selectedSubmission.id===submission.id){
			this.setState({selectedSubmission:null})
		}else{
			this.setState({selectedSubmission:submission})
		}
}





	render() {
			return(
				<DocumentTitle title={'Plickers - Pack Review'}>
					
				<React.Fragment>

					{this.props.adminSubmissions.length>0 &&
							<PackReviewPage 
							submissions={this.props.adminSubmissions} 
							allSubmissions={this.props.adminSubmissions}
							showAdminEditSubmissionStatusModal={this.props.showAdminEditSubmissionStatusModal}
							inbox={this.props.inbox}
							activeSubmissions={this.props.activeSubmissions}
							setFilter={this.changeFilter}
							filterTerm={this.state.filter}
							inReviewSubmissions={this.props.inReviewSubmissions}
							approvedSubmissions={this.props.approvedSubmissions}
							cancelledSubmissions={this.props.cancelledSubmissions}
							currentUser={this.props.currentUser}
							setSelectedSubission={this.setSelectedSubission}
							selectedSubmission={this.state.selectedSubmission}
							keyPressNavigation={this.keyPressNavigation}
							assignAndMoveToReview={this.assignAndMoveToReview}
							adminLogEmailEvent={this.emailAndKeepInReview}
							emailAndMarkCanceled={this.emailAndMarkCanceled}
							emailAndMarkApproved={this.emailAndMarkApproved}
							showMarkCanceledConfirmModal={this.showMarkCanceledConfirmModal}
							showApproveSubmissionConfirmModal={this.showApproveSubmissionConfirmModal}

							/>

					}



				

					{this.props.adminSubmissions.length===0 &&
						<div className='explorerCrossFilter-table-header explorerCrossFilter-table-header--loading'>
							Loading pack review...
						</div>
					}
					</React.Fragment>
				</DocumentTitle>
			)		
	}
}

function mapStateToProps(state,ownProps) {
	let inbox=[]
	let activeSubmissions=[]
	let inReviewSubmissions=[]
	let approvedSubmissions=[]
	let cancelledSubmissions=[]
	if(state.adminSubmissions){
		//inbox=filter(state.adminSubmissions,{status:'submitted'})

		let asignnee='Joe'
		if(state.user.email==='nolan@plickers.com'){
			asignnee='Nolan'
		}
		inbox=filter(state.adminSubmissions,function(o) {
    		if (o.status === "submitted" || 
    			(o.status==='in-review' && o.assignee===asignnee && filter(o.events,{event:'email-sent'}).length===0))
		 return o
		})



		activeSubmissions=filter(state.adminSubmissions,function(o) {
    			if (o.status !== "canceled") return o
		})
		inReviewSubmissions=filter(state.adminSubmissions,{status:'in-review'})
		approvedSubmissions=filter(state.adminSubmissions,{status:'approved'})
		cancelledSubmissions=filter(state.adminSubmissions,{status:'canceled'})

	}




	return {
		adminSubmissions:state.adminSubmissions,
		inbox:inbox,
		activeSubmissions:activeSubmissions,
		inReviewSubmissions:inReviewSubmissions,
		approvedSubmissions:approvedSubmissions,
		cancelledSubmissions:cancelledSubmissions,
		currentUser:state.user
	
	}
}



export default withRouter(connect(
mapStateToProps,
	{adminFetchSubmissions,showAdminEditSubmissionStatusModal,adminAssignAndMoveToReview,
		adminLogEmailEvent,showConfirmModal,hideModal,adminUpdateSubmission,showNotification

	   }
)(PackReviewContainer))

const ChoiceGroupNodeSpec = {
  group: 'block',
  content: "choice{2,4}",
  isolating: true,
  selectable:false,
  draggable:false,
  attrs: {
    choicesComplete: {default: false}, //for all choices touched styling (until all choices are touched letter blocks are gray)
  },
  toDOM(node) {
    return ['choiceGroup', 0]},
  parseDOM: [{tag: 'choiceGroup'}],
}
export default ChoiceGroupNodeSpec

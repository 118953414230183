import React from 'react'



class LiveViewAutoplayModal extends React.Component{
	render(){
		return(
			<div className='liveView-toolModal--container' onClick={this.props.dismissAutoplayModal}>
				<div className='liveView-toolModal--container-paddingBox' />				
				<div className='liveView-toolModal toolModal--cardList'>
					<div className='toolModal--cardList-title'>
						Autoplay Error!
					</div>					
				</div>
			<div className='liveView-toolModal--container-paddingBox'>					
			</div>				
		</div>
		)	
	}
}
export default LiveViewAutoplayModal

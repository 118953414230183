import React from 'react'
import { withRouter} from 'react-router-dom'
import {formatDiscoverLatestTimestamp} from '../../utils/formatDiscoverLatestTimestamp'
import {calculatePlayCount} from '../../utils/calculatePlayCount'
import TopPageHeader from '../pageHeaders/TopPageHeader'
import DiscoverSeeAllRepoLink from './DiscoverSeeAllRepoLink'
import Icon from '../misc/Icons'
import algoliasearch from 'algoliasearch/lite'

//DEV
const algoliaFiltersDev={
	earlyMath:'language:en AND _tags:repo_qualified AND grade:K OR grade:1st OR grade:2nd OR grade:3rd OR grade:4th OR grade:5th AND subject:5db0c7ecae38d80013090b83',
	biology:'language:en AND _tags:repo_qualified AND grade:9th OR grade:10th OR grade:11th OR grade:12th AND subject:5db0c80eae38d80013090b84',
}

//PROD
const algoliaFiltersProd={
	earlyMath:'language:en AND _tags:repo_qualified AND grade:K OR grade:1st OR grade:2nd OR grade:3rd OR grade:4th OR grade:5th AND subject:5dbcc502f2cbe10011b2b999',
	biology:'language:en AND _tags:repo_qualified AND grade:9th OR grade:10th OR grade:11th OR grade:12th AND subject:5dbcc52520bed70016832c2e',
}

class DiscoverSeeAllPage extends React.Component{

	constructor(props){
		super(props)
		this.getRepos=this.getRepos.bind(this)
		this.state = { 
			repos:[]
		}
	}

	componentDidMount(){
		if(this.props.searchKey){
			this.getRepos(this.props.searchKey)
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!this.props.searchKey && props.searchKey) {
			this.getRepos(props.searchKey)
		}
	}

	getRepos(searchKey){ //get repos from algolia
		const searchClient = algoliasearch(
			process.env.REACT_APP_PLICKERS_ALGOLIA_APP_ID,
			searchKey
		)
		let index
		if(this.props.page==='latest'){
			index = searchClient.initIndex('repos_latest')
		}else if(this.props.page==='popular'){
			index = searchClient.initIndex('repos_response_count')
		}else if(this.props.page==='rated'){
			index=searchClient.initIndex('repos_rating')
		}
		if(index){
			const env= process.env.REACT_APP_ENV
			let filters=env==='development'?algoliaFiltersDev[this.props.subject]:algoliaFiltersProd[this.props.subject]
			if(this.props.page==='rated'){
				filters+=' AND reviewCount>2'
			}
			index.search('',{
				hitsPerPage:50, // number of latest packs
				filters:filters
			}).then(({ hits }) => {
				this.setState({repos:hits})
			})
		}
	}

	render(){
		const {subject,page}=this.props
		let formattedSubject
		if(subject === 'earlyMath'){
			formattedSubject = 'Early Math'
		}
		if(subject === 'biology'){
			formattedSubject = 'High School Biology'
		}

		let formattedPage
		if(page === 'latest'){
			formattedPage = 'Latest Packs'
		}
		if(page === 'popular'){
			formattedPage = 'Most Popular Packs'
		}
		if(page === 'rated'){
			formattedPage = 'Highest Rated Packs'
		}

	
		return(
			<div className="page page--wideLayout discoverSeeAllPage">
				<div className="page-leftPanel" />
				<div className='page-centerPanel'>					
					<TopPageHeader
						location='discoverSeeAll'
						leftBadge={<Icon name='chevron-left' />}
						leftBadgeClickFunction={()=>{this.props.history.push(`/discover/${subject}`)}}
						header={formattedPage}
						subHeader={formattedSubject}
					/>
					
					<div className='discoverSeeAllPage-repoList'>																						
						{this.state.repos.map((repo)=>{
							return(
								<DiscoverSeeAllRepoLink									
									key={repo.id}
									repo={repo}
									rating={repo.rating}
									seeAllPage={page}
									playCount={calculatePlayCount(repo.responseCount)}
									loadRepoContent={this.props.loadRepoContent}
									timestamp={formatDiscoverLatestTimestamp(repo.publishedAt)}
								/>																					
							)					
						})}
					</div> 					
				</div>
				<div className="page-rightPanel" />
			</div>
		)
	}
}

export default withRouter(DiscoverSeeAllPage)
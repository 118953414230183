import React from 'react'
import Icon from '../../misc/Icons'
import ScorePill from '../../uiKit/ScorePill'
import SectionMarker from '../../uiKit/SectionMarker'
import SyncLiveViewToolbarScanStopwatch from './SyncLiveViewToolbarScanStopwatch'
import SyncLiveViewToolbarScanCountdown from './SyncLiveViewToolbarScanCountdown'
import SyncLiveViewToolbarViewToggle from './SyncLiveViewToolbarViewToggle'
import LiveViewModeSwitchDropdown from '../LiveViewModeSwitchDropdown'

class SyncLiveViewToolbar extends React.Component{

	render(){			
		const {currentSection, questionStage, isSurvey,countdownEndsAt} = this.props
		const currentQuestionCount = this.props.currentQuestionIndex+1			
		const totalSetQuestionCount = this.props.questionsAndPollsList.length
		let centerWidth
		if(!this.props.classSafeMode){
			centerWidth = `${this.props.slideWidth}px`
		}
		if(this.props.classSafeMode){
			centerWidth = 'auto'
		}
		return(				
			<div className='syncLiveView-toolbar'>
				<div className='syncLiveView-toolbar-left'/>
				{currentSection &&
					<div className='syncLiveView-toolbar-narrowScreenSummary'>		
						<SectionMarker section={currentSection} type='colorOnly' label='Live' />
						<div className='syncLiveView-toolbar-narrowScreenSummary-sectionName'>
							{currentSection.name}
						</div>									
					</div>
				}
		
				<div className='syncLiveView-toolbar-centerContainer'>
					<div style={{width: centerWidth}} className='syncLiveView-toolbar-center'>										
					{questionStage === 'scan' && 
						<div className='syncLiveView-toolbar-center-scanStopWatchContainer'>
							{!countdownEndsAt && 
								<SyncLiveViewToolbarScanStopwatch/> 
							}                	                   	
						</div>
					}
					<div className='syncLiveView-toolbar-center-countDownTimerContainer'>                 	
						<SyncLiveViewToolbarScanCountdown 
							poll={this.props.poll}
							countdownEndsAt={this.props.countdownEndsAt}
							cancelCountdown={this.props.cancelCountdown}
							incrementCountdown={this.props.incrementCountdown}
							isScanning={questionStage === 'scan'}
							handleCountdownEnd={this.props.handleCountdownEnd}
							setCountdownSeconds={this.props.setCountdownSeconds}
							countDownTimerStartSecondsLength={this.props.countDownTimerStartSecondsLength}
						/>                	
					</div>

					{this.props.isSet && !this.props.classSafeMode &&														
						<div className='syncLiveView-toolbar-center-setCounter'>
							<div className='syncLiveView-toolbar-center-setCounter-currentQuestion'>
								{currentQuestionCount}
							</div>
							<div className='syncLiveView-toolbar-center-setCounter-totalQuestionCount'>
								&nbsp;/&nbsp;{totalSetQuestionCount}
							</div>
						</div>																										
					}

					<div className='syncLiveView-toolbar-center-scorePillContainer'>
						{!this.props.classSafeMode &&	!isSurvey && this.props.questionStage !== 'ask' && this.props.poll &&
							<ScorePill score={this.props.poll.correctPercent} />
						}											
						{!this.props.classSafeMode && isSurvey &&
							<ScorePill score={-1} />
						}									
					</div>

				</div>
						
			</div>

			<div className='syncLiveView-toolbar-right'>

				<SyncLiveViewToolbarViewToggle
					classSafeMode={this.props.classSafeMode}
					toggleClassSafeMode={this.props.toggleClassSafeMode}
				/>

				<LiveViewModeSwitchDropdown 
					section={this.props.currentSection}
					switchNowPlayingMode={this.props.switchNowPlayingMode}
					nowPlayingMode={this.props.nowPlayingMode}	
					onlineStudentsCount={this.props.onlineStudentsCount}	
				/>

				<button data-tooltip='Minimize' className='syncLiveView-toolbar-btn syncLiveView-toolbar-btn--minimize tooltip tooltip--label' onClick={()=>{this.props.minimiseLiveView()}}>
					<Icon name='minimize' />
				</button>

				<button data-tooltip='Close' className='syncLiveView-toolbar-btn syncLiveView-toolbar-btn--close tooltip tooltip--label' onClick={()=>{this.props.stopLiveView()}}>
					<Icon name='close' />
				</button>
				</div>
			</div>
		)
	}
}

export default SyncLiveViewToolbar

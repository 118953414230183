import React, { Component } from 'react'
import Button from '../uiKit/Button'
import Icon from '../misc/Icons'

//we don't currently have any active downgrade coupons
//the last downgrade coupon was COVIDEXTEND
class DowngradeCouponModal extends Component {
	constructor(){
		super()
		this.claimCoupon=this.claimCoupon.bind(this)
		this.state={
			submitLoading:false,  
		}
	}
	 
	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Covid Downgrade Coupon Modal',{couponName:'COVIDEXTEND'})
		}
	}

	claimCoupon(){
		this.setState({submitLoading:true})
		this.props.claimCoupon()
	}

	render() {
		const {submitLoading} = this.state
		return (
			<div className='downgradeModal downgradeModal--coupon'>   
					<div className='downgradeModal downgradeModal--coupon-inner'>  						
												
						<div className='downgradeModal--coupon-limitedOfferSash'>
							School Closures Offer						
							<svg width="220" height="24" viewBox="0 0 220 24">
								<path d="M2.34786499,0 L217.944529,0 C219.114074,1.93250835e-14 220.062177,0.948102883 220.062177,2.11764706 C220.062177,2.49520824 219.961233,2.86590384 219.769802,3.19133674 L215.219817,10.9263103 C214.829985,11.5890261 214.829985,12.4109739 215.219817,13.0736897 L219.769802,20.8086633 C220.362784,21.8167334 220.026289,23.114643 219.018219,23.7076254 C218.692786,23.8990565 218.322091,24 217.944529,24 L2.23732167,24 C1.0677775,24 0.119674613,23.0518971 0.119674613,21.8823529 C0.119674613,21.5138253 0.215848226,21.1516774 0.398689135,20.8317058 L5.0286337,12.7293028 C5.41031166,12.0613664 5.39973605,11.2389762 5.00100834,10.5810755 L0.536857817,3.21522716 C-0.0693189361,2.21503552 0.250093242,0.91281664 1.25028489,0.306639886 C1.58125192,0.106053807 1.96085859,9.59270341e-16 2.34786499,0 Z"/>
							</svg>							
						</div>

						<div className='downgradeModal-h1'>
							Keep Plickers Pro for free through November 15th
						</div>
						<div className='downgradeModal-h3'>
							Thanks so much for being a Plickers Pro teacher! With this coupon, you’ll keep Pro, including your access to unlimited Scoresheet and more.
						</div>

						<div className='downgradeModal-h3'>
							You’ll also have first-access to any e-learning products we develop during school closures. 
						</div>

						<div className='downgradeModal--coupon-btnContainer'>
							<button className='btn downgradeModal-primaryButton' disabled={submitLoading} onClick={this.claimCoupon} >
								 {!submitLoading &&
									<React.Fragment>
										<div className='downgradeModal-primaryButton-headline'>											
											Yes, give me Plickers Pro for free through Nov 15th
										</div>
										<div className='downgradeModal-primaryButton-subline'>
										 	You will keep your Plickers Pro access, including unlimited sets and Scoresheet.
										</div>
										<div className='downgradeModal-primaryButton-subline'>
											You can cancel anytime. We won’t bill you until November 16th, 2020.
										</div>								 
									</React.Fragment>
								}

								{submitLoading &&
									<div className='downgradeModal-primaryButton-submitLoading'>							 								 	
									 	<div className='downgradeModal-primaryButton-submitLoading-spinnerContainer'>
									 		<div className='downgradeModal-primaryButton-submitLoading-spinner' />
									 	</div>
									</div>
								}
							</button>
							<Button name='downgradeModalSecondary' color='white' size='large' label='No thanks, continue to Downgrade' onClickFunction={this.props.showDowngradeModal}/>
						</div>
					</div>

			
				<div className='downgradeModal--coupon-holes downgradeModal--coupon-holes--left'>
					<Icon name='voucherSide'/>
				</div>
				<div className='downgradeModal--coupon-holes downgradeModal--coupon-holes--right'>
					<Icon name='voucherSide'/>
				</div>
			</div>

		)
	}
}


export default DowngradeCouponModal
import React, {Component} from 'react'
import Button from '../../components/uiKit/Button'
import Icon from '../../components/misc/Icons'
import QuestionSlideCarousel from '../uiKit/QuestionSlideCarousel'
import ImportQuestionsRightPanelHelp from './ImportQuestionsRightPanelHelp'

class ImportQuestionsFindAndRemoveCheckbox extends Component{


	render(){	
		return(					
			<div onClick={this.props.onToggle} className={'importQuestions-rightPanel-subSection--findAndRemove-checkboxContainer ' + (this.props.checked ? 'importQuestions-rightPanel-subSection--findAndRemove-checkboxContainer--active' : 'importQuestions-rightPanel-subSection--findAndRemove-checkboxContainer--inactive')}>
				<div className='importQuestions-rightPanel-subSection--findAndRemove-checkbox'>
					{this.props.checked &&
						<Icon name='checkbox-check' />
					}
				</div>
				<div className='importQuestions-rightPanel-subSection--findAndRemove-label'>
					{this.props.label}
				</div>
				
			</div>
			
		)
	} 
}


class ImportQuestionsRightPanelSubSection extends Component{

	render(){	
		return(					
			<div className={'importQuestions-rightPanel-subSection ' + (this.props.subSectionName ? `importQuestions-rightPanel-subSection--${this.props.subSectionName}` : '')}>
				<div className='importQuestions-rightPanel-subSection-title'>
					{this.props.subSectionLabel}
				</div>
				{this.props.children}
			</div>
			
		)
	}
}



class ImportQuestionsRightPanel extends Component{

	constructor(props){
		super()
		this.state = {
			findAndRemoveString:''
		}
		
	}  






	render(){	
		const {autocleanOnImport,questionsPreview,matchCase,deleteLineWithMatch,questionNumbersDetected,choiceLettersDetected,bulletPointsDetected}=this.props

		const testSet={questions:questionsPreview}

		let autoCleanDetected = false

		if(questionNumbersDetected || choiceLettersDetected || bulletPointsDetected){
			autoCleanDetected = true
		}



		return(		
			<div className='importQuestions-rightPanel'>
			
				<ImportQuestionsRightPanelSubSection
					subSectionName='findAndRemove'
					subSectionLabel='Find and Remove Text'
				>

					<div className='importQuestions-rightPanel-subSection--findAndRemove-inputRow'>
						<input
							className='importQuestions-rightPanel-subSection--findAndRemove-textInput'
							value={this.state.findAndRemoveString}
							maxLength={50}
							onChange={(e)=>{this.setState({findAndRemoveString:e.target.value})}}
							onKeyUp={(e)=>{if (e.keyCode === 13 && this.state.findAndRemoveString!=='') { 
									this.props.findAndRemove(this.state.findAndRemoveString)
							}}}

						/>
						<Button name='findreplace' disabled={this.state.findAndRemoveString===''} label='Find and Remove' onClickFunction={()=>{this.props.findAndRemove(this.state.findAndRemoveString)}}/>
					</div>
						
					<div className='importQuestions-rightPanel-subSection--findAndRemove-checkboxesRow'>
						
						<ImportQuestionsFindAndRemoveCheckbox
							checked={deleteLineWithMatch}								
							label='Delete lines containing match'
							onToggle={this.props.toggleDeleteLineWithMatch}
						/>

						<ImportQuestionsFindAndRemoveCheckbox
							checked={matchCase}								
							label='Match Case'
							onToggle={this.props.toggleMatchCase}
						/>
						
					</div>
				</ImportQuestionsRightPanelSubSection>

				<ImportQuestionsRightPanelSubSection
					subSectionName='cleanPastedText'
					subSectionLabel='Clean Pasted Text'
				>

					<div className='importQuestions-rightPanel-subSection--cleanPastedText-subtitle'>
						Remove parts of pasted text that you don’t need.
					</div>

					<div className='importQuestions-rightPanel-subSection--cleanPastedText-buttonRow'>

						<Button label='Question Numbers' disabled={!questionNumbersDetected} onClickFunction={this.props.removeEmbeddedQuestionNumbers}/>
						<Button label='Choice Letters' disabled={!choiceLettersDetected}  onClickFunction={this.props.removeEmbeddedChoiceLetters}/>
						<Button label='Bullet Points'  disabled={!bulletPointsDetected}  onClickFunction={this.props.removeBulletPoints}/>
					
					</div>

					<ImportQuestionsFindAndRemoveCheckbox							
						checked={autocleanOnImport}
						label='Automatically clean questions on import'
						onToggle={this.props.toggleAutocleanOnImport}
					/>

					
				</ImportQuestionsRightPanelSubSection>

				<ImportQuestionsRightPanelSubSection
					subSectionName='importPreview'
					subSectionLabel='Import Preview'
				>
					{!questionsPreview &&
						<div className='importQuestions-rightPanel-subSection--importPreview-emptySpacer' />
					}

					{questionsPreview && questionsPreview.length !==0 &&
						<QuestionSlideCarousel 
							activeQuestionIndex={this.props.activeQuestionIndex}
							questionPreviewTimestamp={this.props.questionPreviewTimestamp} 
							preventPlay
							hideTotalQuestionCount
							set={testSet}  
							slideWidth={384} 
							showSetNavigator 
						/>
					}

				</ImportQuestionsRightPanelSubSection>

				

			
				<ImportQuestionsRightPanelSubSection
					subSectionName='help'
					subSectionLabel='Help'
				>

					<ImportQuestionsRightPanelHelp 
						questionsInSetLimit={this.props.questionsInSetLimit}
						showHelpVideoModal={this.props.showHelpVideoModal}
					/>					



				</ImportQuestionsRightPanelSubSection>

				<div className='importQuestions-rightPanel-buttonRow'>						
					<Button
						name='import'
						submitLoading={this.props.submitLoading}
						size='xLarge'
						disabled={this.props.value===''}
						color='blue'
						label={(autocleanOnImport && autoCleanDetected) ? 'AutoClean and Import Questions' : 'Import Questions'}
						onClickFunction={this.props.importQuestions}
					/>
				</div>	
			</div>
		)
	}
}



					



export default ImportQuestionsRightPanel

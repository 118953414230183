import React from 'react'
import find from 'lodash/find'
import Icon from '../misc/Icons'
import { Link } from 'react-router-dom'
import SectionMarker from '../uiKit/SectionMarker'
import ScorePill from '../uiKit/ScorePill'
import {formatDate} from '../../utils/formatDate'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'

class ItemDetailHistoryListItem extends React.Component{

	render(){	

		const {poll,pollSection} = this.props
		return(
			<Link
				to={`/${poll.setObject ? 'setreport':'questionreport'}/${poll.id}`}				
			>
				<div className='ItemDetailHistoryList-item'>
					<div className='ItemDetailHistoryList-item-topRow'>
						<SectionMarker section={pollSection} />
						<ScorePill score={poll.correctPercent} isSet={this.props.isSet} />
					</div>

					<div className='ItemDetailHistoryList-item-timestamp'>
						{formatDate(poll.minCaptured)}
					</div>
					<div className='ItemDetailHistoryList-item-rightArrow'>
						<Icon name='chevron-right' />
					</div>															
				</div>	
			</Link>
		)
	}
}


class ItemDetailHistoryList extends React.Component{

	render(){	
		const {historyPolls,sections}=this.props
		
		return(
			<div className='ItemDetailHistoryList'>			
				<div className='ItemDetailHistoryList-header'>
					Reports
				</div>
				<div className='ItemDetailHistoryList-list'>
					<PerfectScrollbar
				    option={{
				    		handlers:['click-rail', 'drag-thumb', 'wheel', 'touch'],
				    		suppressScrollX: true
				    	}}		    	
				   >
					 {historyPolls.map((poll,index) => {
					 	const pollSection = find(sections, {'id':poll.section})
					 	if(pollSection){
					 		return(
					 			<ItemDetailHistoryListItem 
					 			poll={poll} 
					 			isSet={this.props.isSet} 
					 			pollSection={pollSection} 
					 			key={index} />
					 			)}else return null
					 	})}
					 </PerfectScrollbar>
				</div>
			</div>
		)
	}
				
}


export default ItemDetailHistoryList



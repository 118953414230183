import React, { Component } from 'react'
import { connect } from 'react-redux'
import {switchPlan} from '../../../actions/service'
import {claimCoupon} from '../../../actions/discounts'
import {showNotification} from '../../../actions/notifications'
import SwitchPlanModal from '../../../components/account/SwitchPlanModal'



class SwitchPlanContainer extends Component {
  	
	constructor(props){
		super(props)
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)
		this.switchPlan=this.switchPlan.bind(this)
		
		

		this.state = {
			showCouponModal:true,
			switchingPlans:false,
			submitLoading:false
		}
		 
	}  

	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden' //prevent scrolling while modal is open
	}

 

	shouldComponentUpdate(){
		if(this.state.switchingPlans){
			return false
		}else return true
	}



	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}


	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.props.hideModal() //close modal is click outside modal
	}

	switchPlan(isDiscountPeriod){
		this.setState({submitLoading:true})
		const {service}=this.props
		let newPlan
		if(service ){
			if(service.plan ===  process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID){
				if(window.analytics){
					window.analytics.track('Switch Billing Period',{
						type:'monthlyToYearly',
					})
				}
				newPlan=process.env.REACT_APP_STRIPE_YEARLY_PLAN_ID
			}else{
				newPlan= process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID
				if(window.analytics){
					window.analytics.track('Switch Billing Period',{
						type:'yearlyToMonthly',
					})
				}

			}
		}
		if(isDiscountPeriod && newPlan===process.env.REACT_APP_STRIPE_YEARLY_PLAN_ID){
			this.setState({switchingPlans:true})
			const couponCode=process.env.REACT_APP_DEFAULT_COUPON_CODE
			this.props.switchPlan(newPlan).then(()=>{
				this.props.claimCoupon(couponCode).then(()=>{
					this.setState({switchingPlans:false})
					this.props.hideModal()
					this.props.showNotification('','Changed Billing Period','default')
			 	})

			})


		}

		else{
			this.props.switchPlan(newPlan).then(()=>{
				this.props.hideModal()
				this.props.showNotification('','Changed Billing Period','default')

			})
		}

	}



	render() {
		const {service}=this.props
		let currentPlan =''
		if(service ){
			if(service.plan ===  process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID){
				currentPlan='monthly'
			}else{
				currentPlan='yearly'
			}
		}

		let isDiscountPeriod=false
		const discountPeriodStartDate = process.env.REACT_APP_DEFAULT_COUPON_START_DATE
		const discountPeriodEndDate = process.env.REACT_APP_DEFAULT_COUPON_END_DATE
		const currentDate = new Date()
		const startDate = new Date(discountPeriodStartDate)
		const endDate = new Date(discountPeriodEndDate)

		if(startDate<currentDate && endDate > currentDate){
		   isDiscountPeriod=true
		} 






		return(
			<div className='switchPlanModalContainer' onClick={this.handleBackgroundClick}>
				<SwitchPlanModal 
					hideModal={this.props.hideModal}
					currentPlan={currentPlan}
					switchPlan={()=>{this.switchPlan(isDiscountPeriod)}}
					service={service}
					isDiscountPeriod={isDiscountPeriod}
					upcomingInvoice={this.props.upcomingInvoice}
					submitLoading={this.state.submitLoading}
				/>	
			</div>
		)
	}
}



function mapStateToProps(state) {
	

	return {
		user:state.user,
		service:state.service,
		upcomingInvoice:state.upcomingInvoice,

	}
}


export default connect(
	mapStateToProps,
	{ switchPlan,showNotification,claimCoupon}
)(SwitchPlanContainer)



import React from 'react'
import FeatureModalSelectControl from '../../../featureModals/FeatureModalSelectControl'
import FeatureModalSelectControlGroup from '../../../featureModals/FeatureModalSelectControlGroup'
 


class ReportPackModalConfigControls extends React.Component{

	constructor() {
		super()		
		this.state = {
			selectedOptionTab: 'packSetup',
			personalizedContentControl: false
		}
	} 

	render(){ 
		const {browser,reportPackSettings,setReportPackSetting,setPaperSize,paperSize}=this.props
		const {selectedOptionTab}=this.state
		const {
			columnCount,
			showQuestionNumbers,
			showImages,
			includeArchivedStudents,
			includeGuests,
			highlightCorrect,
			showMissing,
			showSurveys
		}=reportPackSettings

		let showPaperSizeControl=true
		if(browser==='firefox'){
			showPaperSizeControl=false
		}
		return(			
			<div className='featureModal-configControls'>
				<div className='featureModal-configControls-optionTabs'>
					<div 
						onClick={()=>{this.setState({selectedOptionTab: 'packSetup'})}}
						className={'featureModal-configControls-optionTabs-optionTab ' + (selectedOptionTab === 'packSetup' ? 'featureModal-configControls-optionTabs-optionTab--active' : 'featureModal-configControls-optionTabs-optionTab--inactive' )}>
						Setup
					</div>
					<div 
						onClick={()=>{this.setState({selectedOptionTab: 'options'})}}
						className={'featureModal-configControls-optionTabs-optionTab ' + (selectedOptionTab === 'options' ? 'featureModal-configControls-optionTabs-optionTab--active' : 'featureModal-configControls-optionTabs-optionTab--inactive' )}>
						Options
					</div>				
				</div>

				{selectedOptionTab === 'packSetup' &&
					<div className='featureModal-configControls-controlSet featureModal-configControls-controlSet--packSetup'>
							<React.Fragment>
								<FeatureModalSelectControlGroup>
									<FeatureModalSelectControl checked={highlightCorrect} onClick={()=>{setReportPackSetting('highlightCorrect',!highlightCorrect)}} checkbox label='Indicate correct answers' subLabel='Show correct answer on every question.'/>
								</FeatureModalSelectControlGroup>
								<FeatureModalSelectControlGroup>
									<FeatureModalSelectControl checked={showMissing} onClick={()=>{setReportPackSetting('showMissing',!showMissing)}}  checkbox label='Include unanswered questions' />								
								</FeatureModalSelectControlGroup>	
								<FeatureModalSelectControlGroup>
									<FeatureModalSelectControl checked={showSurveys} onClick={()=>{setReportPackSetting('showSurveys',!showSurveys)}} checkbox label='Include Surveys' />
								</FeatureModalSelectControlGroup>								
								<FeatureModalSelectControlGroup>
									<FeatureModalSelectControl checked={!showImages} onClick={()=>{setReportPackSetting('showImages',!showImages)}} checkbox label='Hide all images and GIFs' />
								</FeatureModalSelectControlGroup>
							</React.Fragment>
					</div>
				}
				{selectedOptionTab === 'options' &&
					<div className='featureModal-configControls-controlSet featureModal-configControls-controlSet--options'>					 									
						<FeatureModalSelectControlGroup title='Layout'>
							<FeatureModalSelectControl checked={columnCount===1} onClick={()=>{setReportPackSetting('columnCount',1)}} radio label='One-column layout' />
							<FeatureModalSelectControl checked={columnCount===2} onClick={()=>{setReportPackSetting('columnCount',2)}} radio label='Two-column layout' />
						</FeatureModalSelectControlGroup>

						{showPaperSizeControl &&
						<FeatureModalSelectControlGroup title='Paper Size'>
							<FeatureModalSelectControl checked={paperSize==='letter'} onClick={()=>{setPaperSize('letter')}} radio label='Letter' />
							<FeatureModalSelectControl checked={paperSize==='a4'} onClick={()=>{setPaperSize('a4')}}  radio label='A4' />
						</FeatureModalSelectControlGroup>
						}

						<FeatureModalSelectControlGroup>
							<FeatureModalSelectControl checked={showQuestionNumbers} onClick={()=>{setReportPackSetting('showQuestionNumbers',!showQuestionNumbers)}}  checkbox label='Add question numbers' />							
							{/*<FeatureModalSelectControl checked={showResponseCapturedDate} onClick={()=>{setReportPackSetting('showResponseCapturedDate',!showResponseCapturedDate)}}  checkbox label='Add Dates to Questions' />*/}
						</FeatureModalSelectControlGroup>

						<FeatureModalSelectControlGroup>
							<FeatureModalSelectControl checked={includeArchivedStudents} onClick={()=>{setReportPackSetting('includeArchivedStudents',!includeArchivedStudents)}}  checkbox label='Include archived students' />
							<FeatureModalSelectControl checked={includeGuests} onClick={()=>{setReportPackSetting('includeGuests',!includeGuests)}}  checkbox label='Include Guests' />
						</FeatureModalSelectControlGroup>						
					</div>
				}
			</div>
		)
	}
}

export default ReportPackModalConfigControls

// {selectedPackType === 'class' &&					 	
// 					 		<React.Fragment>

							
// 							 		<FeatureModalSelectControlGroup>
// 										<FeatureModalSelectControl checked={true} radio label='All Questions' />
// 										<FeatureModalSelectControl checked={false} radio label='Targeted Questions' />
// 										{/* SLIDER GOES HERE 
// 									</FeatureModalSelectControlGroup>

// 							 		<FeatureModalSelectControlGroup>
// 										<FeatureModalSelectControl checked={false} checkbox label='Include Answer Sheet' />
// 									</FeatureModalSelectControlGroup>
								
// 							</React.Fragment>
// 					 	}

import React from 'react'
import ReactDOM from 'react-dom'
import ChoiceMediaEmptyStateReactComponent from './ChoiceMediaEmptyStateReactComponent'
import {showInsertImageModal} from '../utils/showInsertImageModal'
import {showInsertVideoModal} from '../utils/showInsertVideoModal'
import {showInsertSoundModal} from '../utils/showInsertSoundModal'
import {showInsertGIFModal} from '../utils/showInsertGIFModal'
import {deleteChoice} from '../../../prosemirror/utils/editorActions/deleteChoice'


class ChoiceMediaEmptyStateNodeView {

	constructor(node, view, getPos) {
		this.getNode=this.getNode.bind(this)
		this.showInsertVideoModal=this.showInsertVideoModal.bind(this)
		this.showInsertImageModal=this.showInsertImageModal.bind(this)
		this.showInsertSoundModal=this.showInsertSoundModal.bind(this)
		this.showInsertGIFModal=this.showInsertGIFModal.bind(this)
		this.deleteChoice=this.deleteChoice.bind(this)
		this.node = node
		this.getPos=getPos
		this.dom = document.createElement("div")
		this.dom.className='slide-choice-mediaContainer slide-choice-mediaContainer--blank'
	 
		let slideWidth=node.attrs.slideWidth
		if(!slideWidth && window.view){ //back up for when node doesn't have slide width
			window.view.state.doc.nodesBetween(0,window.view.state.doc.content.size, (node, pos) => {
				if(node.attrs.slideWidth){
					slideWidth=node.attrs.slideWidth
					return false
				}
			})
		}
		ReactDOM.render(
			<ChoiceMediaEmptyStateReactComponent 
				deleteChoice={this.deleteChoice}
				showInsertGIFModal={this.showInsertGIFModal}
				showInsertSoundModal={this.showInsertSoundModal} 
				showInsertImageModal={this.showInsertImageModal} 
				showInsertVideoModal={this.showInsertVideoModal}
				slideWidth={slideWidth}
				 />, 
			this.dom)
	} 

	showInsertImageModal(){
		const node=this.getNode()
		const nodePos=this.getPos(node)
		let insertPos={
			start:nodePos,
			end:nodePos+node.nodeSize
		}
		const isQuestion=false
		showInsertImageModal(insertPos,isQuestion)
	}
	
	showInsertGIFModal(){
		const node=this.getNode()
		const nodePos=this.getPos(node)
		let insertPos={
			start:nodePos,
			end:nodePos+node.nodeSize
		}
		const isQuestion=false
		showInsertGIFModal(insertPos,isQuestion)
	}

	showInsertVideoModal(){
		const node=this.getNode()
		const nodePos=this.getPos(node)
		let insertPos={
		 start:nodePos,
			end:nodePos+node.nodeSize
	 }
	 const isQuestion=false
	 const searchState = null
	 showInsertVideoModal(searchState,insertPos,isQuestion)
	}
 
	showInsertSoundModal(){
		const node=this.getNode()
		const nodePos=this.getPos(node)
		let insertPos={
		 start:nodePos,
		 end:nodePos+node.nodeSize
	 }
	 const isQuestion=false
	 showInsertSoundModal(insertPos,isQuestion)

	}


	deleteChoice(){
		const position=this.getPos()
		const state=window.view.state
		const resolvedPos= state.doc.resolve(position)
		const choiceNode=resolvedPos.node(3)
	// //     start(depth: ?⁠number) → number
	// // The (absolute) position at the start of the node at the given level.
	// // end(depth: ?⁠number) → number
	// // The (absolute) position at the end of the node at the given level.
		const choiceNodeStart=resolvedPos.before(3)
		deleteChoice(state,choiceNodeStart,choiceNode)
	}


	getNode(){
		return this.node
	}

	destroy() {
		ReactDOM.unmountComponentAtNode(this.dom)
	}

}

export default ChoiceMediaEmptyStateNodeView
import api from '../api'
import {updateRecentActivities} from './recentActivities'
import store from '../store'
import debounce from 'lodash/debounce'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import find from 'lodash/find'
import {calculateSetPollCorrectPercent} from '../utils/liveView/calculateSetPollCorrectPercent'
import {calculatePollCorrectPercent} from '../utils/liveView/calculatePollCorrectPercent'

const MINIMUM_TIME_BETWEEN_REFRESHES=1000*30 //30seconds between fetch polls


export function fetchQuestionPollforPusher(pollId,dispatch) {//fetch polls in control that don't exist locally (played from mobile from item not poll)
	return api.fetch(`/polls/${pollId}`)
		.then((response) => {
			store.dispatch({ type: 'FETCH_SINGLE_QUESTION_POLL_SUCCESS', response })
			return response
		})
	.catch((error) => {})
}



const debouncedFetchSingleQuestionPoll=debounce(fetchQuestionPollforPusher,1500)


export function refetchQuestionPolls() {
	return (dispatch) => api.batchedFetch('/polls?canceled=false&sort=modified&correctPercent=true&parentArchived=false')
		.then((response) => {
			dispatch({ type: 'FETCH_QUESTION_POLLS_SUCCESS', response })
		})
		.catch((error) => {
			return error
		})
}

function fetchQueueQuestionPolls(questionPollsLastRefreshedTime){
	let queryString='/polls?hasResponses=false&canceled=false&sort=modified&parentArchived=false'
	if(questionPollsLastRefreshedTime){
		queryString+=`&modifiedAfter=${questionPollsLastRefreshedTime}`
	}
	return (dispatch) => api.batchedFetch(queryString)
		.then((response) => {
			if(questionPollsLastRefreshedTime){
				dispatch({ type: 'REFRESH_QUESTION_POLLS_SUCCESS', response})
			}else{
				dispatch({ type: 'FETCH_QUEUE_QUESTION_POLLS_SUCCESS', response})
			}
		})
		.catch((error) => {
			return error
		})
}

function fetchHistoryQuestionPolls(questionPollsLastRefreshedTime){
	let queryString='/polls?hasResponses=true&canceled=false&sort=modified&correctPercent=true&parentArchived=false'
	if(questionPollsLastRefreshedTime){
		queryString+=`&modifiedAfter=${questionPollsLastRefreshedTime}`
	}
	return (dispatch) => api.batchedFetch(queryString)
		.then((response) => {
			if(questionPollsLastRefreshedTime){
				dispatch({ type: 'REFRESH_QUESTION_POLLS_SUCCESS', response})
			}else{
				dispatch({ type: 'FETCH_HISTORY_QUESTION_POLLS_SUCCESS', response})
			}
		})
		.catch((error) => {
			return error
		})
}

export function fetchQuestionPolls() {
	const state = store.getState()
	if(state.questionPollsRefreshedTimestamp){
		const nowDate =moment()
		const timeSinceLastRefresh=nowDate - moment(state.questionPollsRefreshedTimestamp)
		if(timeSinceLastRefresh > MINIMUM_TIME_BETWEEN_REFRESHES){			
			store.dispatch({ type: 'REFRESH_QUESTION_POLLS_REQUEST'})
			return (dispatch) => {
				dispatch(fetchQueueQuestionPolls(state.questionPollsRefreshedTimestamp))
				dispatch(fetchHistoryQuestionPolls(state.questionPollsRefreshedTimestamp))
			}
		}else{
			return({ type: 'WAIT_TO_REFRESH'})
		}
	}else {
		store.dispatch({ type: 'FETCH_QUESTION_POLLS_REQUEST'})
		return (dispatch) => {
			dispatch(fetchQueueQuestionPolls())
			dispatch(fetchHistoryQuestionPolls())
		}
	}
}

function fetchQueueSetPolls(setPollsLastRefreshedTime){
	let queryString='/setpolls?hasResponses=false&canceled=false&sort=modified&parentArchived=false'
	if(setPollsLastRefreshedTime){
		queryString+=`&modifiedAfter=${setPollsLastRefreshedTime}`
	}
	return (dispatch) => api.batchedFetch(queryString)
	    .then((response) => {
	    	if(setPollsLastRefreshedTime){
		 	  dispatch({ type: 'REFRESH_SET_POLLS_SUCCESS', response})
			}else{
				 
				dispatch({ type: 'FETCH_QUEUE_SET_POLLS_SUCCESS', response})
			}
	    })
	    .catch((error) => {
			return error
	})
}


function fetchHistorySetPolls(setPollsLastRefreshedTime){
	let queryString='/setpolls?hasResponses=true&canceled=false&sort=modified&correctPercent=true&parentArchived=false'
	if(setPollsLastRefreshedTime){
		queryString+=`&modifiedAfter=${setPollsLastRefreshedTime}`
	}
	return (dispatch) => api.batchedFetch(queryString)
		.then((response) => {
			if(setPollsLastRefreshedTime){
				dispatch({ type: 'REFRESH_SET_POLLS_SUCCESS', response})
			}else{				 
				dispatch({ type: 'FETCH_HISTORY_SET_POLLS_SUCCESS', response})
			}
		})
		.catch((error) => {
			return error
		})
}


export function fetchSetPolls() {
	const state = store.getState()
	if(state.setPollsRefreshedTimestamp){
		const nowDate =moment()
		const timeSinceLastRefresh=nowDate - moment(state.setPollsRefreshedTimestamp)
		if(timeSinceLastRefresh > MINIMUM_TIME_BETWEEN_REFRESHES){
			store.dispatch({ type: 'REFRESH_SET_POLLS_REQUEST'})
			return (dispatch) => {
				dispatch(fetchQueueSetPolls(state.setPollsRefreshedTimestamp))
				dispatch(fetchHistorySetPolls(state.setPollsRefreshedTimestamp))
			}
		}else{
			return({ type: 'WAIT_TO_REFRESH'})
		}
	}else{
		store.dispatch({ type: 'FETCH_SET_POLLS_REQUEST'})
		return (dispatch) => {
			dispatch(fetchQueueSetPolls())
			dispatch(fetchHistorySetPolls())
		}
	}
}

export function fetchSingleSetPoll(pollId) {
	return (dispatch) => api.fetch(`/setpolls/${pollId}?correctPercent=true`)
	.then((response) => {
		dispatch({ type: 'FETCH_SINGLE_SET_POLL_SUCCESS', response })
		return response
	})
	.catch((error) => {
		return error
	})
}

export function fetchSetPollforPusher(pollId,dispatch) {
	return api.fetch(`/setpolls/${pollId}?correctPercent=true`)
		.then((response) => {
			store.dispatch({ type: 'FETCH_SINGLE_SET_POLL_SUCCESS', response })
			return response
		})
		.catch((error) => {})
}

const debouncedFetchSingleSetPoll=debounce(fetchSetPollforPusher,1000,{leading: true})

export function refetchSetPolls() {
	return (dispatch) => api.batchedFetch('/setpolls?canceled=false&sort=modified&correctPercent=true&parentArchived=false')
		.then((response) => {
			dispatch({ type: 'FETCH_SET_POLLS_SUCCESS', response })
		})
		.catch((error) => {
			return error
		})
}

export function fetchPollsForSet(setId) {
	return (dispatch) => api.fetch(`/setpolls?setObject=${setId}&hasResponses=false&limit=100&canceled=false&sort=modified&parentArchived=false`)
	.then((response) => {
		dispatch({ type: 'FETCH_POLLS_FOR_A_SET_SUCCESS', response })
	})
	.catch((error) => {
		return error
	})
}

export function fetchPollsForQuestion(questionId) {
	return (dispatch) => api.fetch(`/polls?question=${questionId}&hasResponses=false&limit=100&canceled=false&sort=modified&parentArchived=false`)
	.then((response) => {
		dispatch({ type: 'FETCH_POLLS_FOR_A_QUESTION_SUCCESS', response })
	})
	.catch((error) => {
		return error
	})
}

// //debounce to prevent issues when creating multiple polls for an item in quick sucession
// var debouncedUpdateRecentActivities=_.debounce(updateRecentActivities,500,{'leading': false })

export function newQuestionPoll(data,isBatchRequest) {	
	return (dispatch) => {
		if(!isBatchRequest){
			dispatch({ type: 'CREATE_QUESTION_POLL_REQUEST', data })	
		}
		return api.post('/polls/',data)
			.then((response) => {
				dispatch({ type: 'CREATE_QUESTION_POLL_SUCCESS', response,syncAction:true })		      
				dispatch(updateRecentActivities(response.question,'question','queue'))
				return response
			})
			.catch(err => {
				dispatch({ type: 'CREATE_QUESTION_POLL_FAILURE', data })
				Sentry.withScope(scope => {
					scope.setExtra('request', data)
					Sentry.captureException(new Error(err))
				})
			})
		}
	}

export function newSetPoll(data,isBatchRequest) {	
	return (dispatch) => {
	if(!isBatchRequest){
		dispatch({ type: 'CREATE_SET_POLL_REQUEST', data })	
	}
	return api.post('/setpolls/',data)
		.then((response) => {
			dispatch({ type: 'CREATE_SET_POLL_SUCCESS', response,syncAction:true })
			dispatch(updateRecentActivities(response.setObject,'set','queue'))
			return response
		})
		.catch(err => {
			dispatch({ type: 'CREATE_SET_POLL_FAILURE', data })
			Sentry.withScope(scope => {
				scope.setExtra('request', data)
				Sentry.captureException(new Error(err))
			})
		})
	}
}


export function deleteQuestionPoll(pollId) {
	return (dispatch) => api.delete(`/polls/${pollId}`)
	.then((response) => {
		dispatch({ type: 'DELETE_QUESTION_POLL_SUCCESS', pollId,syncAction:true})
		return response
	})
	.catch((error) => {
		return error
	})
}

export function deleteSetPoll(pollId){
	return (dispatch) => api.delete(`/setpolls/${pollId}`)
		.then((response) => {
			dispatch({ type: 'DELETE_SET_POLL_SUCCESS', pollId,syncAction:true})
			return response
		})
		.catch(err => {})
}

//update 
export function updateQuestionPoll(pollId,data) {	
	if(!pollId){ //TEMP for debugging
		Sentry.withScope(scope => {
			scope.setExtra('pollId', pollId)
			Sentry.captureException('request update question poll with no pollId')
		})
	}
	return (dispatch) => {
		dispatch({ type: 'UPDATE_QUESTION_POLL_REQUEST', pollId,data,syncAction:true})	
		return api.put(`/polls/${pollId}`,data)
			.then((response) => {
				if(data.canceled && !response.canceled){
					Sentry.withScope(scope => {
						scope.setExtra('request', data)
						Sentry.captureException('cancel question poll failed')
					})
				}
				dispatch({ type: 'UPDATE_QUESTION_POLL_SUCCESS', response,pollId,syncAction:true })
		 		return response
		})
		.catch(err => {
			dispatch(refetchQuestionPolls())
			return 'failed'
		})
	}
}

export function updateSetPoll(pollId,data) {
	return (dispatch) => {
		dispatch({ type: 'UPDATE_SET_POLL_REQUEST', pollId,data,syncAction:true  })	
		return api.put(`/setpolls/${pollId}`,data)
			.then((response) => {
				dispatch({ type: 'UPDATE_SET_POLL_SUCCESS', response,pollId,syncAction:true })
				return response
			})
		.catch(err => {
			dispatch(refetchSetPolls())
			return 'failed'
		})
	}
}

function updateSetPollWithPusherPoll(setPollId,setPollPoll,setPollModified){
	const state = store.getState()
	const setPoll=find(state.historySetPolls,{id:setPollId})||find(state.queueSetPolls,{id:setPollId})
	if(setPoll){
		let setPollPolls=setPoll.polls||{}
		//if(setPollPolls[setPollPoll.id] && setPollPolls[setPollPoll.id].maxCaptured===setPollPoll.maxCaptured){
		if(setPoll.modified>setPollModified){
			return null
		}else{	
			let pollWithCorrectPercent=setPollPoll
			pollWithCorrectPercent.correctPercent=calculatePollCorrectPercent(setPollPoll)
			setPollPolls[setPollPoll.id]=pollWithCorrectPercent
			let updatedSetPoll=Object.assign({},setPoll)
			updatedSetPoll.polls=setPollPolls
			if(!updatedSetPoll.hasResponses && setPollPoll.hasResponses){
				updatedSetPoll.hasResponses=true
			}
			//calculate correct percentage
			const correctPercent=calculateSetPollCorrectPercent(updatedSetPoll)
			updatedSetPoll.correctPercent=correctPercent
			updatedSetPoll.modified=setPollModified
			if(!setPoll.minCaptured){
				updatedSetPoll.minCaptured=setPollPoll.minCaptured
			}
			return updatedSetPoll
		}
	}else return null
}


export function pusherPollReceived(data) {//TODO save pusher poll in state if poll doesn't already exist in state
	return (dispatch) => {
		if(data.setPollId){
			const updatedPoll=updateSetPollWithPusherPoll(data.setPollId,data.setPollPoll,data.setPollModified)
			if(updatedPoll){
				dispatch({ type: 'UPDATED_SET_POLL_WITH_PUSHER',updatedPoll})
			}
		}else if(data.poll){
			let pollObject=data.poll
			const state = store.getState()
			const questionPoll=find(state.historyQuestionPolls,{id:pollObject.id})||find(state.queueQuestionPolls,{id:pollObject.id})
			if(questionPoll && questionPoll.modified<pollObject.modified){
				pollObject.correctPercent=calculatePollCorrectPercent(pollObject)
				dispatch({ type: 'UPDATED_QUESTION_POLL_WITH_PUSHER',pollObject})
			}
		}
	}
}

export function getQuestionPollForControl(pollId) {
	return (dispatch) => {
		debouncedFetchSingleQuestionPoll(pollId,dispatch)	
		return null				
	}
}

export function fetchSetPollForControl(pollId) {
	return (dispatch) => {
		debouncedFetchSingleSetPoll(pollId,dispatch)
		return null	
	}
}

import React, { Component } from 'react'
import { Spring } from  'react-spring/renderprops'


function sendNotificationActionAnalytics(actionType){
	if(window.analytics){
		window.analytics.track('Mobile update notification action',{
			type:actionType
		})
	}
}


class MobileUpdateNotification extends Component {

	// componentDidUpdate(prevProps) {
	// 	if (this.props.showNotification && !prevProps.showNotification) {
	// 		if(window.analytics){
	// 			window.analytics.track('Show update mobile notification',{
	// 				level:this.props.notification.level,
	// 				platform:this.props.notification.platform,
	// 				beforeAppVersion:this.props.notification.beforeAppVersion,
	// 				message:this.props.notification.message,
	// 				usersCurrentAppVersion:this.props.mobileInfo.appVersion
	// 			})
	// 		}										
	// 	}
	// }

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.showNotification && !this.props.showNotification && nextProps.notification) {
			if(window.analytics && this.props.mobileInfo){
				window.analytics.track('Show update mobile notification',{
					level:nextProps.notification.level,
					platform:nextProps.notification.platform,
					beforeAppVersion:nextProps.notification.beforeAppVersion,
					message:nextProps.notification.message,
					usersCurrentAppVersion:this.props.mobileInfo.appVersion
				})
			}										
		}
	}


	render() {
		const {notification, showNotification}=this.props

		let notificationLevel = 'warning'
		if(notification && notification.level){
			notificationLevel = notification.level
		}

		let notificationMessage = ''
		if(notification && notification.message){
			notificationMessage = notification.message
		}

		// notificationLevel = 'warning'
		// // notificationLevel = 'error'

		// //const notificationMessage = 'Support for your mobile version will end on January 1st.'
		// const notificationMessage = 'Your mobile app version is unsupported.'
	

		return (

			<Spring
				from={{opacity: 0, yTranslate: 20}}
				to={{
          opacity: showNotification ? 1 : 0,
          yTranslate: showNotification ? 0 : 20,
        }}
        config={{ tension: 500, friction: 35 }}
      	>
        {props => 
        		<div 
        			style={{ transform: `translateY(${props.yTranslate}px)`, opacity:`${props.opacity}` }}
							className={'mobileUpdateNotification ' + (notificationLevel ? `mobileUpdateNotification--level--${notificationLevel}`: '') + (showNotification ? '' : ' mobileUpdateNotification--hide')}
						>
												
						<div className='mobileUpdateNotification-emojiContainer'>
							<div className='mobileUpdateNotification-emoji'>
								{notificationLevel === 'warning' &&
									<img alt='Wave Hand' src='https://res.cloudinary.com/plickers-static/image/upload/v1632150760/richtextmarketing/wavehand.png' />
								}
								{notificationLevel === 'error' &&
									<img alt='Alert' src='https://res.cloudinary.com/plickers-static/image/upload/v1639145536/richtextmarketing/alert.png' />
								}								
							
							</div>
						</div>
						<div className='mobileUpdateNotification-header'>
							Please update your mobile app.
						</div>
						<div className='mobileUpdateNotification-message'>
							{notificationMessage}
						</div>
						<div className='mobileUpdateNotification-buttonRow'>
							<button className='mobileUpdateNotification-buttonRow-dismissBtn' onClick={this.props.dismissNotification}>
								Okay
							</button>							
							<a onClick={()=>{sendNotificationActionAnalytics('learn more')}} className='mobileUpdateNotification-buttonRow-helpLink' target='_blank' rel='noopener noreferrer' href='https://help.plickers.com/hc/en-us/articles/4412547947163'>							
								Tell me more
							</a>							
						</div>
					</div>	
								
      }					
			
			</Spring>


			
		)
	}

}





export default MobileUpdateNotification




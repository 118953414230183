import {InputRule} from 'prosemirror-inputrules'
import {openEquationEditorModal} from '../../utils/editorActions/insertEquation' 

// type $$ to open equation editor (also deletes $$)
export function openEquationEditorInputRule(){
	return new InputRule(/\$\$/g, (state, match, start, end) => {
		const tr = state.tr
		const openedWithShortcut=true
		openEquationEditorModal(openedWithShortcut)
		return tr
			.delete(start,start+1)
	})
}

// type $/ to open equation editor (also deletes $/)
export function openAdvancedEquationEditorInputRule(){
	return new InputRule(/\$\//g, (state, match, start, end) => {
		const tr = state.tr
		const openedWithShortcut=true
		const isAdvanced=true
		openEquationEditorModal(openedWithShortcut,isAdvanced)
		return tr
			.delete(start,start+1)
	})
}

export function showLiveViewModal() {
	window.location.hash='now-playing'
	if(window.analytics){
		let testGroup=null
		const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
		if(setLimitTestGroup){
			testGroup=setLimitTestGroup
		}
		window.analytics.page('Now Playing',{'test_group_set_limit':testGroup})
	}
}
import React from 'react';
import ReactDOM from 'react-dom'
import {toggleMark} from "prosemirror-commands"
import editorSchema from '../../schema/editorSchema'
import EditorFormatBarReactComponent from './EditorFormatBarReactComponent'
import {getSlideWidthFromQuestionNode} from '../../utils/getSlideWidthFromQuestionNode'

let menuItems = {
	bold:{command: toggleMark(editorSchema.marks.strong),mark:'strong',isActive:false,isAllowed:true},
	italic:{command: toggleMark(editorSchema.marks.em),mark:'em',isActive:false,isAllowed:true},
	highlight1:{command: toggleMark(editorSchema.marks.highlight1),mark:'highlight1',isActive:false,isAllowed:true},
	highlight2:{command: toggleMark(editorSchema.marks.highlight2),mark:'highlight2',isActive:false,isAllowed:true},
	highlight3:{command: toggleMark(editorSchema.marks.highlight3),mark:'highlight3',isActive:false,isAllowed:true},
	highlight4:{command: toggleMark(editorSchema.marks.highlight4),mark:'highlight4',isActive:false,isAllowed:true},
	highlight5:{command: toggleMark(editorSchema.marks.highlight5),mark:'highlight5',isActive:false,isAllowed:true},
	highlight6:{command: toggleMark(editorSchema.marks.highlight6),mark:'highlight6',isActive:false,isAllowed:true},
	highlight7:{command: toggleMark(editorSchema.marks.highlight7),mark:'highlight7',isActive:false,isAllowed:true},
	highlight8:{command: toggleMark(editorSchema.marks.highlight8),mark:'highlight8',isActive:false,isAllowed:true},
	// code:{command: toggleMark(editorSchema.marks.code),mark:'code',isActive:false,isAllowed:true},
	underline:{command: toggleMark(editorSchema.marks.underline),mark:'underline',isActive:false,isAllowed:true},
	superscript:{command: toggleMark(editorSchema.marks.superscript),mark:'superscript',isActive:false,isAllowed:true},
	subscript:{command: toggleMark(editorSchema.marks.subscript),mark:'subscript',isActive:false,isAllowed:true},
 // undo:{command: undo,isAllowed:false},
 // redo:{command: redo,isAllowed:false},
	//blockquote:{command: wrapIn(editorSchema.nodes.blockquote),nodeType:'blockquote',isActive:false,isAllowed:true}
}

function markActive(state, type) {
	let {from, $from, to, empty} = state.selection
	if (empty) return type.isInSet(state.storedMarks || $from.marks())
	else return state.doc.rangeHasMark(from, to, type)
}

function getQuestionCount(editorView){
	let questionCount=0
	editorView.state.doc.nodesBetween(0, editorView.state.doc.content.size, (node, pos) => {  
		if(node.type.name==='question'){     
			questionCount+=1 
		}
	})   
	return questionCount
}

class FormatBarView {

	constructor(editorView,setActiveQuestion,showNotification,onAddQuestionToSet,onDeleteActiveQuestion,questionsInSetLimit,isSetEditor) {
		this.items = menuItems
		this.editorView = editorView
		this.questionsInSetLimit=questionsInSetLimit
		this.setActiveQuestion=setActiveQuestion
		this.showNotification=showNotification
		this.onAddQuestionToSet=onAddQuestionToSet
		this.isSetEditor=isSetEditor
		this.onDeleteActiveQuestion=onDeleteActiveQuestion
		this.dom=document.getElementById("menuBar")

		const questionCount=getQuestionCount(editorView)

		let maxNumOfQuestionsReached=false
		if(questionsInSetLimit && questionCount>= questionsInSetLimit ){
			maxNumOfQuestionsReached=true
		} 

		ReactDOM.render(
			<EditorFormatBarReactComponent 
				editorView={editorView} 
				setActiveQuestion={this.setActiveQuestion}
				showNotification={this.showNotification}
				onAddQuestionToSet={this.onAddQuestionToSet}
				onDeleteActiveQuestion={this.onDeleteActiveQuestion}
				menuItems={menuItems} 
				slideWidth={getSlideWidthFromQuestionNode()}
				maxNumOfQuestionsReached={maxNumOfQuestionsReached}
				isSetEditor={isSetEditor}

			/>, this.dom)

		this.update()

		this.dom.addEventListener("mousedown", e => {
			e.preventDefault()
			editorView.focus()
		})
	}

	update() {
		const schema=this.editorView.state.schema
		let newItems=Object.assign({},this.items)

		Object.keys(this.items).forEach((key)=>{
			const menuItem=this.items[key]
			const command=menuItem.command
			let isActive=false
			let isAllowed=false

			if(menuItem.mark && markActive(this.editorView.state, schema.marks[menuItem.mark]) ){
				isActive=true
			}
			isAllowed = command(this.editorView.state, null, this.editorView)

			newItems[key].isActive=isActive
			newItems[key].isAllowed=isAllowed
			menuItems=newItems
		})

		const questionCount=getQuestionCount(this.editorView)

		let maxNumOfQuestionsReached=false
		if(this.questionsInSetLimit && questionCount>= this.questionsInSetLimit ){
			maxNumOfQuestionsReached=true
		} 

		ReactDOM.render(
			<EditorFormatBarReactComponent 
				editorView={this.editorView} 
				setActiveQuestion={this.setActiveQuestion}
				showNotification={this.showNotification}
				onAddQuestionToSet={this.onAddQuestionToSet}
				onDeleteActiveQuestion={this.onDeleteActiveQuestion}
				isSetEditor={this.isSetEditor}
				menuItems={menuItems} 
				slideWidth={getSlideWidthFromQuestionNode()}
				maxNumOfQuestionsReached={maxNumOfQuestionsReached}
			/>, this.dom)

	}

	destroy() { 
		ReactDOM.unmountComponentAtNode(this.dom)
		this.dom.remove() 
	}
}

export default FormatBarView
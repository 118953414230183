import editorSchema from '../../schema/editorSchema'
import {generateRandQuestionId} from '../../../utils/generateRandQuestionId'
import {getActiveQuestion} from '../getActiveQuestion'
import {updateQuestionOrderTransaction} from '../updateQuestionOrderTransaction'
import {setQuestionBodySelectionTransaction} from '../setQuestionBodySelectionTransaction'
import {stopAllMediaPlayback} from './stopAllMediaPlayback'

export function duplicateActiveQuestion(onAddQuestionToSet){
	stopAllMediaPlayback()
	let tr = window.view.state.tr
	const activeQuestion=getActiveQuestion()
	const {questionPos,questionNode}=activeQuestion
  const type = editorSchema.nodes.question
  const newQuestionId=generateRandQuestionId()
	const newNode=type.createAndFill({...questionNode.attrs,activeslide:false,questionid:newQuestionId},questionNode.content)
	tr.insert(questionPos+questionNode.nodeSize,newNode)
	tr.setNodeMarkup(questionPos+questionNode.nodeSize, null, {...newNode.attrs,activeslide:false})
 	tr.setMeta("triggerParseDoc", true) 
 	const oldOrderArray=questionNode.attrs.questionOrderArray
 	const activeQuestionId=questionNode.attrs.questionid
	let newArray=[]
	oldOrderArray.forEach((questionId)=>{
		newArray.push(questionId)
		if(questionId===activeQuestionId){
			newArray.push(newQuestionId)
		}
	})

	tr=updateQuestionOrderTransaction(tr,newArray)

	let newActiveNode
  let newActivePos
  
  tr.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
  if (node.type.name === "question"){
    if(node.attrs.questionid===newQuestionId){
    	newActiveNode=node
      newActivePos=pos
      tr.setNodeMarkup(pos, null, {...node.attrs,activeslide:true})
    }
    else{
      tr.setNodeMarkup(pos, null, {...node.attrs,activeslide:false})
    }
  }})     

  tr=setQuestionBodySelectionTransaction(tr,newActiveNode,newActivePos)
  window.view.dispatch(tr)
	onAddQuestionToSet(newQuestionId)
}
import React from 'react'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
 
//Modal that opens over NP showing student names with their card numbers 

class LiveViewCardAssignmentView extends React.Component{
	renderStudent(student) {
		const name = student.displayName
		return( 
			<div className='toolModal--cardList-studentCardGrid-studentCard--container' key={student.id} > 
				<div className='toolModal--cardList-studentCardGrid-studentCard'>
					<div className='toolModal--cardList-studentCardGrid-studentCard-name notranslate'>
						{name}
					</div>
					<div className='toolModal--cardList-studentCardGrid-studentCard-cardNumber notranslate'>
						{student.card}
					</div>
				</div>
			</div>
		)
	}

	render(){
		const { section,sortOrder}=this.props
		let students=[]
		if(section){
			if(sortOrder==='name'){
				students=sortBy(filter(section.students,{'archived':false}), [function(student) {    	
					return student.displayName.toString().toLowerCase()
				}])}
			else{
				students=sortBy(filter(section.students,{'archived':false}), [function(student) { 
					return student.card
				}])}
		}
		return(
			<div className='liveView-toolModal--container'>
				<div className='liveView-toolModal--container-paddingBox' />				
				<div className='liveView-toolModal toolModal--cardList'>
					<div className='toolModal--cardList-title'>
						Student Cards
					</div>
					<div className={'toolModal--cardList-studentCardGrid' + (students.length > 36 ? ' classSizeIs--over36' : '')}>
						{students.map(this.renderStudent)}
					</div>						
				</div>
				<div className='liveView-toolModal--container-paddingBox'>
					Back to Now Playing
				</div>
			</div>
		)
	}
}

export default LiveViewCardAssignmentView
import React, { Component } from 'react'
import { connect } from 'react-redux' 
import { withRouter} from 'react-router-dom'
import find from 'lodash/find'  
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy' 
import isEmpty from 'lodash/isEmpty' 
import moment from 'moment'
import each from 'lodash/each'
import DocumentTitle from 'react-document-title'  
import {uploadRepoImageToCloudinary} from '../../actions/cloudinary'
import { hideMenu } from 'react-contextmenu/modules/actions'
import {updateRepo,deleteRepo,publishRepo,followRepo,unfollowRepo,unpublishRepo} from '../../actions/repos'
import {inviteToPlickers} from '../../actions/invite'
import {submitRepoForReview} from '../../actions/submissions'
import {createReview} from '../../actions/reviews'
import {updateFolder,fetchFolders} from '../../actions/folders'
import {updateQuestionMeta,fetchQuestions} from '../../actions/questions'
import {updateSetMeta,fetchSets} from '../../actions/sets'
import {showSectionSelectionModal, showRepoSelectionModal,showEditRepoModal,showMoveToFolderModal,showConfirmModal,showEditFolderModal,hideModal,finishImageUpload,showRenameSetModal } from '../../actions/modals'
import {showNotification} from '../../actions/notifications'
import {fetchPermissions,joinRepo,leaveRepo,rejectInvitation,updatePermission,deletePermission} from '../../actions/permissions'
import {fetchLocksForRepo} from '../../actions/locks'
import {saveSettingToLocalStorage} from '../../utils/saveSettingToLocalStorage'
import Icon from '../../components/misc/Icons'
import RepoDefaultPreviewPanel from '../../components/repo/RepoDefaultPreviewPanel'
import RepoEmptyStateHelpVideo from '../../components/repo/RepoEmptyStateHelpVideo'
import HelpVideoModal from '../../components/HelpVideoModal'
import RepoInvitationModal from '../../components/repo/RepoInvitationModal'
import {inviteByEmail,inviteByUser} from '../../actions/permissions'
import {updatePreviewItem} from '../../actions/previewItem'
import RepoInvitationBanner from '../../components/repo/RepoInvitationBanner'
import RepoPage from '../../components/repo/RepoPage'
import RepoPageHeaders from '../../components/repo/RepoPageHeaders'	
import withSelectableCells from '../../hoc/withSelectableCells'
import PreviewPanelContainer from '../previewPanel/PreviewPanelContainer'
import {openItemInEditor} from '../../utils/openItemInEditor'
import {getDisplayNameForUser} from '../../utils/getDisplayNameForUser'
import {isCurrentUser} from '../../utils/isCurrentUser'
import {repoPusherSetup} from '../../utils/pusher/repoPusherSetup'
import {showLiveViewModal} from '../../utils/liveView/showLiveViewModal'
import {playItem} from '../../utils/playItem'
import {createPoll} from '../../utils/createPoll' 
import {moveToFolder} from '../../utils/moveToFolder'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'
import {createCopyOfItem} from '../../utils/createCopyOfItem'
import {copyQuestion} from '../../utils/copyPaste'
import {newSet} from '../../utils/newItem'
import {combineIntoSet} from '../../utils/combineIntoSet'
import {getLibrarySettings} from '../../utils/getLibrarySettings' 
import {sortLibraryData} from '../../utils/sortLibraryData'
import {addCopyToLibrary} from '../../utils/addCopyToLibrary'
import {sendRepoPageEvent} from '../../utils/analytics/sendRepoPageEvent'
import {sendPublishRepoEvent} from '../../utils/analytics/sendPublishRepoEvent'
import {sendFollowRepoEvent} from '../../utils/analytics/sendFollowRepoEvent'
import {getLinkForRepo} from '../../utils/getLinkForRepo'
import PublishRepoFlowContainer from './PublishRepoFlowContainer'
import ReviewPublishedRepoModal from '../../components/repo/ReviewPublishedRepoModal'
import SubmitForPackReviewModal from '../../components/repo/SubmitForPackReviewModal'
import {isRepoInDiscoverBeta} from '../../utils/isRepoInDiscoverBeta'

//For privately shared repos we user permissions
//Permission roles viewer/editor/admin http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#permission

//For published packs user has role author or consumer and we show slightly different views e.g.consumer can follow or review a pack
class RepoContainer extends Component {
 
	constructor(props){
		super(props)
		this.editRepoName=this.editRepoName.bind(this)
		this.editRepoDescription=this.editRepoDescription.bind(this)
		this.confirmDeleteRepo=this.confirmDeleteRepo.bind(this)
		this.confirmLeaveRepo=this.confirmLeaveRepo.bind(this)
		this.handleDeleteRepo=this.handleDeleteRepo.bind(this)
		this.handleLeaveRepo=this.handleLeaveRepo.bind(this)
		this.inviteByEmail=this.inviteByEmail.bind(this)
		this.inviteByUser=this.inviteByUser.bind(this)
		this.rejectInvitation=this.rejectInvitation.bind(this)
		this.handleFileUpload=this.handleFileUpload.bind(this)
		this.handleRemoveImage=this.handleRemoveImage.bind(this)
		this.handleNewFolder=this.handleNewFolder.bind(this)
		this.navigateToFolder=this.navigateToFolder.bind(this)
		this.renderPathItem=this.renderPathItem.bind(this)
		this.getNameForUserId=this.getNameForUserId.bind(this)
		this.editFolderName=this.editFolderName.bind(this)
		this.handleSelectRow=this.handleSelectRow.bind(this)
		this.keyPressNavigation=this.keyPressNavigation.bind(this)
		this.navigateToItemDetail=this.navigateToItemDetail.bind(this)
		this.navigateToTrash=this.navigateToTrash.bind(this)
		this.handleFolderContextMenuClick=this.handleFolderContextMenuClick.bind(this)
		this.handleMultiSelectContextMenuClick=this.handleMultiSelectContextMenuClick.bind(this)
		this.handleContextMenuClick=this.handleContextMenuClick.bind(this)
		this.playItem=this.playItem.bind(this)
		this.queueItem=this.queueItem.bind(this)
		this.delayedMenuClose=this.delayedMenuClose.bind(this)
		this.showMoveToFolderModal=this.showMoveToFolderModal.bind(this)
		this.handleMoveToFolder=this.handleMoveToFolder.bind(this)
		this.createCopy=this.createCopy.bind(this)
		this.copyQuestionToClipboard=this.copyQuestionToClipboard.bind(this)
		this.onShowContextMenu=this.onShowContextMenu.bind(this)
		this.onHideContextMenu=this.onHideContextMenu.bind(this)
		this.archiveItem=this.archiveItem.bind(this)
		this.handleNewSet=this.handleNewSet.bind(this)
		this.archiveFolder=this.archiveFolder.bind(this)
		this.combineIntoSet=this.combineIntoSet.bind(this)
		this.batchAddToQueue=this.batchAddToQueue.bind(this)
		this.batchMoveToFolder=this.batchMoveToFolder.bind(this)
		this.batchArchive=this.batchArchive.bind(this)
		this.confirmBatchArchive=this.confirmBatchArchive.bind(this)
		this.centerPanelOnClick=this.centerPanelOnClick.bind(this)
		this.changeRepoSortOrder=this.changeRepoSortOrder.bind(this)
		this.archiveRepoItem=this.archiveRepoItem.bind(this)
		this.archiveRepoFolder=this.archiveRepoFolder.bind(this)
		this.batchArchiveRepoItems=this.batchArchiveRepoItems.bind(this)
		this.addCopyToLibrary=this.addCopyToLibrary.bind(this)
		this.inviteToPlickers=this.inviteToPlickers.bind(this)
		this.publishRepo=this.publishRepo.bind(this)
		this.followRepo=this.followRepo.bind(this)
		this.showPublishModal=this.showPublishModal.bind(this)
		this.dismissPublishModal=this.dismissPublishModal.bind(this)
		this.openPublishModal=this.openPublishModal.bind(this)
		this.unpublishRepo=this.unpublishRepo.bind(this)
		this.unfollowRepo=this.unfollowRepo.bind(this)
		this.handleUnpublishRepo=this.handleUnpublishRepo.bind(this)
		this.finishPublishingFlow=this.finishPublishingFlow.bind(this)


		const librarySettings=getLibrarySettings()

		this.state={
			repoData:props.repoData,
			folders:props.folders,
			sortedBy:librarySettings.sortType,
			sortOrder:librarySettings.sortOrder,
			showInvitationModal:false,
			uploadingToCloudinary:false,
			contextMenuActiveItem:null,
			contextMenuObject:null,
			showHelpVideoModal:false,
			showPublishRepoModal:true,
			followButtonSubmitLoading:false,
			showReviewModal:false,
			showSubmitForPackReviewModal:false
		}
	}


	UNSAFE_componentWillMount() {
		this.props.fetchFolders()
		this.props.fetchQuestions()
		this.props.fetchSets()
		this.props.fetchPermissions(this.props.repoId)
		if(this.props.repo && this.props.repo.joined && !this.props.repoDisabled){
			this.props.fetchLocksForRepo(this.props.repoId)
			repoPusherSetup(this.props.repoId)
		}
	}
	
	componentDidMount(){
		sendRepoPageEvent(this.props.repoId)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(!this.props.repo.id && nextProps.repo.id && nextProps.repo.joined && !nextProps.repoDisabled){
			this.props.fetchLocksForRepo(this.props.repoId)
			repoPusherSetup(this.props.repoId)
		}
		if(nextProps.repoId !== this.props.repoId){
			sendRepoPageEvent(nextProps.repoId)			
			this.props.fetchPermissions(nextProps.repoId)
			this.props.resetSelectedCells()
			if(nextProps.repo.joined && !nextProps.repoDisabled){
				this.props.fetchLocksForRepo(nextProps.repoId)
				repoPusherSetup(nextProps.repoId)
			}
		}
		if((this.props.repoData !==nextProps.repoData) || (this.props.folders !== nextProps.folders)){
			this.setState({repoData:nextProps.repoData,folders:nextProps.folders})
		}		
		if(this.props.currentUserPermission && this.props.currentUserPermission.role!=='viewer' &&nextProps.currentUserPermission && nextProps.currentUserPermission.role==='viewer'){
			this.setState({showInvitationModal:false}) //close invitation modal if get turned into a viewer
		}
		if(nextProps.repoId === this.props.repoId && this.props.currentUserPermission && !nextProps.currentUserPermission){
			this.props.history.push('/library') //if permission is deleted for current user for repo they are viewing then redirect to library
		}
	}

	inviteToPlickers(email){
		return this.props.inviteToPlickers(email)
	}

	addCopyToLibrary(item){
		return this.props.addCopyToLibrary(item,'Context Menu').then(()=>{
			this.props.showNotification('to Your Library','Added a Copy','create')
		})
	}
 
	changeRepoSortOrder(sortType) {//Can sort by name or date.  If data is already sorted on that field then reverse the order
		let sorted = []
		let sortedFolders=this.props.folders.slice()
		let newSortOrder
		let newSortedBy
		if(sortType === 'byDate'){
			newSortedBy='date'
			if(this.state.sortedBy!=='date'){
				sorted = sortBy(this.state.repoData, 'clientModified').reverse()
				newSortOrder='ascending'
			}else {
				sorted = this.state.repoData.reverse()
				if(this.state.sortOrder==='descending'){
					newSortOrder='ascending'
				}else {
					newSortOrder='descending'
				}
			}
		}else if (sortType === 'byName') {
			newSortedBy='name'
			if (this.state.sortedBy !== 'name') {
				sorted = sortBy(this.state.repoData, [function(o) {
				//for questions use question body, for sets use the set name
					if (o.body) {
						return o.body.toString().toLowerCase()
					} else {
						if (o.name) {
							return o.name.toString().toLowerCase()
						}
					}
				}])
				newSortOrder='ascending'
				sortedFolders=this.props.folders
			} else {
				sorted = this.state.repoData.reverse()

				if (this.state.sortOrder === 'descending') {
					newSortOrder='ascending'
				} else {
					newSortOrder='descending'
					sortedFolders.reverse()
				}
			}
		}
		this.setState({repoData: sorted,folders:sortedFolders,sortedBy:newSortedBy,sortOrder:newSortOrder})
		saveSettingToLocalStorage('librarySettings','sortType',newSortedBy)
		saveSettingToLocalStorage('librarySettings','sortOrder',newSortOrder)
	}

	///////////////Repo 

	confirmDeleteRepo(){
		const {repo}=this.props
		this.props.showConfirmModal(repo,this.handleDeleteRepo,'delete')
	}

	handleDeleteRepo(){
		const {repo}=this.props
		this.props.deleteRepo(repo.id).then(() => {
			this.props.hideModal()
			this.props.history.push('/library')
			const notificationMessage=`${repo.name}`
			this.props.showNotification(notificationMessage,'Deleted','destroy')
		})
	}

	editRepoName(newName){
		const nowDate=new Date()
		const {repo}=this.props
		if(newName !== repo.name && newName!==''){//only save if name has been edited and is non-empty
			const requestData = {...repo,
				'name': newName,
				'clientModified': nowDate,
			}
			this.props.updateRepo(requestData)
		}
	}

	editRepoDescription(newDescription){
		const nowDate=new Date()
		const {repo}=this.props
		if(newDescription !== repo.description && newDescription!==''){//only save if name has been edited and is non-empty
			const requestData = {...repo,
				'description': newDescription,
				'clientModified': nowDate,
			}
			this.props.updateRepo(requestData)
		}
	}

	handleRemoveImage(){
		const nowDate=new Date()
		const {repo}=this.props
		const requestData = {...repo,
			'image':'',
			'clientModified': nowDate,
		}
		this.props.updateRepo(requestData)
	}

	uploadImage(img,thumbnail) {
		this.props.hideModal()
		this.setState({uploadingToCloudinary:true})
		this.props.uploadRepoImageToCloudinary(img,this.props.repo.id).then((response) => {
			const nowDate=new Date()
			const {repo}=this.props
			const version=response.version
			const requestData = {...repo,
				'image':`v${version}`,
				'clientModified': nowDate,
			}
			this.props.updateRepo(requestData)
			this.props.finishImageUpload()
			this.setState({uploadingToCloudinary:false})

		})
		.catch((error) => {
			this.setState({uploadingToCloudinary:false})
		})
	}


 	handleFileUpload(e){
		const fileList = e.target.files
		const file = fileList[0]
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = (event) => {
			this.props.uploadNewImageToCloudinary(event.target.result).then((response) => {
				const nowDate=new Date()
				const {repo}=this.props
				const image=response.public_id
				const requestData = {...repo,
					'image':image,
					'clientModified': nowDate,
				}
				this.props.updateRepo(requestData)
			})
		}
	}

	////////invitations/permissions

	inviteByUser(userId,permissionRole){
		return this.props.inviteByUser(this.props.repo.id,userId,permissionRole)
	}
	inviteByEmail(email,permissionRole){
		return this.props.inviteByEmail(this.props.repo.id,email,permissionRole)
	}

	rejectInvitation(){
		this.props.rejectInvitation(this.props.repo.id,this.props.currentUser.id).then(()=>{
			this.props.history.push('/library')
		})
	}

	confirmLeaveRepo(){
		const {repo}=this.props
		this.props.showConfirmModal(repo,this.handleLeaveRepo,'leave')
	}

	handleLeaveRepo(){
		const {repo,currentUser}=this.props
		this.props.leaveRepo(repo.id,currentUser.id).then(() => {
			this.props.hideModal()
			this.props.history.push('/library')
			const notificationMessage=`${repo.name}`
			this.props.showNotification(notificationMessage,'Left','destroy')
			
		})
	}

	//Folders
	batchMoveToFolder(folder){
		const {multiSelectItems}=this.props
		const length = Object.keys(multiSelectItems).length
		Object.keys(multiSelectItems).map((key) => {
			const item=multiSelectItems[key]
			this.handleMoveToFolder(item,folder,length)
			return null
		})
		this.props.resetSelectedCells()
	}

	archiveRepoFolder(folder){
		const {repo}=this.props		
		if(repo.shared){
			this.props.showConfirmModal(folder,this.archiveFolder,'archiveRepoItem')
		}else{
			this.archiveFolder(folder) 
		}
	}

	archiveFolder(folder){
		this.props.hideModal()
		if(folder){
			const nowDate = new Date()
			let notificationMessage
			const requestData = {
				...folder,
				clientModified: nowDate,
				archived: true
			}		
			this.props.updateFolder(requestData, folder.id).then(() => {
				notificationMessage=`${folder.name}`   
				this.props.showNotification(notificationMessage,'Deleted','destroy')
			})
		}
	}

	handleNewFolder(){
		this.props.showEditFolderModal(null,this.props.parentFolderId,this.props.changeActiveItem,this.props.repo.id)
	}

	handleMoveToFolder(item,folder,numOfItems){
		let notificationMessage
		moveToFolder(item,folder.id).then(() => {
			if(numOfItems){
				notificationMessage=`${numOfItems} items to ${folder.name}`
			}else{
				notificationMessage=`${item.name || item.body} to ${folder.name}`
			}
			this.props.showNotification(notificationMessage,'Moved','default')
		})
	}

	showMoveToFolderModal(item){ 
		this.props.showMoveToFolderModal(item,this.props.resetSelectedCells)
	}

	navigateToFolder(folderId){	
		if(folderId){
			this.props.history.push(`${getLinkForRepo(this.props.repo)}/${folderId}`)
			this.props.resetSelectedCells()
		}else{
			this.props.history.push(`${getLinkForRepo(this.props.repo)}`)
			this.props.resetSelectedCells()	
		}
	}

	editFolderName(folder,name){
		const nowDate=new Date()
		if(name !== folder.name && name!==''){//only save if name has been edited and is non-empty
			const requestData = {...folder,
				'name': name,
				'clientModified': nowDate,
			}
			this.props.updateFolder(requestData, folder.id)
		}
	}

	renderPathItem(pathItem,index){
		const folder=find(this.props.allFolders,{'id':pathItem})	
		if(folder){
			return(
				<div className='pageHeaderTop-folderNavRow-folderPathItem is--folder' onClick={() =>{this.navigateToFolder(pathItem)}} key={index}>
					<Icon name='chevron-right'/>
					<Icon name='folder'/>					       
					{folder.name}
				</div>
			)
		}else{
			return null
		}
	}

	///// Sets/questions

	batchAddToQueue(section){
		const {multiSelectItems}=this.props
		let items = []
		Object.keys(multiSelectItems).map((key)=>{
			const item=multiSelectItems[key]
			items.push(item)
			return null
		})
		let plannedTime=moment()
		items.map((item)=>{
			this.queueItem(item,section,true,plannedTime)
			plannedTime=plannedTime.add(1,'millisecond')
			return null
		})
		this.props.showNotification(`${Object.keys(multiSelectItems).length} items to ${section.name} queue`,'Added','default')
	}

	combineIntoSet(){
		const {multiSelectItems}=this.props
		const repo = this.props.repo.id
		const parent=this.props.parentFolderId
		this.props.combineIntoSet(multiSelectItems,parent,repo).then((response) => {
			const length = Object.keys(multiSelectItems).length
			const notificationMessage=`New Set from ${length} items`   
			this.props.showNotification(notificationMessage,'Created','create')
			this.props.changeActiveItem(response)
		})
	}

	// copyAllToLibrary(){ //temp for user testing
	// 	const {multiSelectItems}=this.props
	// 	Object.keys(multiSelectItems).forEach((key)=>{
	// 		const item=multiSelectItems[key]
	// 		this.props.addCopyToLibrary(item,'Context Menu',false).then(()=>{
	// 		this.props.showNotification('to Your Library','Added a Copy','create')
	// 	})
	// 	})	
	// }

	navigateToItemDetail(item){
		if(item.questions){
			this.props.history.push(`/set/${item.id}`)
		}else if(item.choices){
			this.props.history.push(`/question/${item.id}`)
		}
	}

	handleNewSet(parentFolder){
		const parent=parentFolder||this.props.match.params.folderId || null
		newSet(parent,this.props.repo.id)
	}


	////Context menus

	delayedMenuClose(){
		hideMenu()
	}

	onShowMultiselectContextMenu(e){
		this.setState({contextMenuActiveItem:null,contextMenuObject:null})
		document.body.style.overflowY = 'hidden'
	}

	onHideMultiselectContextMenu(e){
		this.setState({contextMenuActiveItem:null,contextMenuObject:null})
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' 

	}

	onShowContextMenu(e){
		this.setState({contextMenuActiveItem:e.detail.data.itemId,contextMenuObject:e.detail.data.item})
		document.body.style.overflowY = 'hidden'
	}

	onHideContextMenu(e){
		this.setState({contextMenuActiveItem:null,contextMenuObject:null})
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' 

	}

	handleContextMenuClick(e, data){
		e.stopPropagation() //prevent click on context menu deselecting cells etc
		if (data.action === 'Open') {
			if(data.itemType === 'set') {
				openItemInEditor(data.itemId,true)
			}else if(data.itemType === 'question') {
				openItemInEditor(data.itemId,false)
			}
		}else if(data.action === 'Rename'){
			this.props.showRenameSetModal(data.item)
		}else if(data.action === 'Play'){
			this.playItem(data.item,data.section)
		}else if (data.action === 'Queue') {
			this.queueItem(data.item,data.section)
		}else if (data.action === 'Move') {
			this.showMoveToFolderModal([data.item])
		}else if(data.action === 'MoveToRepo') {
			this.props.showRepoSelectionModal(data.item)
		}else if (data.action === 'MoveToFolder') {
			this.handleMoveToFolder(data.item,data.folder)
		}else if (data.action === 'Archive') {
			this.archiveRepoItem(data.item)
		}else if(data.action === 'Copy') {
			this.createCopy(data.item)
		}else if (data.action === 'CopyToLibrary') {
			this.addCopyToLibrary(data.item)
		}else if(data.action === 'CopyToClipboard') {
			this.copyQuestionToClipboard(data.item)	
		}else if(data.action === 'ItemDetail') {
			if(data.item.questions){
				this.props.history.push(`/set/${data.item.id}`)
			}else if(data.item.choices){
				this.props.history.push(`/question/${data.item.id}`)
			}
		}
		this.delayedMenuClose()
	}

	handleFolderContextMenuClick(e, data){	
		const item=data.item
		e.stopPropagation() //prevent click on context menu deselecting cells etc
		if(data.action === 'Open'){
			this.navigateToFolder(item.id, item.name)
		}else if(data.action === 'Move'){
			this.showMoveToFolderModal({[item.id]:item})
		}else if(data.action === 'MoveToRepo'){
			this.props.showRepoSelectionModal(item)
		}else if(data.action === 'Archive'){
			this.archiveRepoFolder(item)
		}else if(data.action === 'Rename'){
			this.props.showEditFolderModal(item)
		}else if(data.action === 'NewQuestionInFolder'){
			this.handleNewQuestion(item.id)
		}else if(data.action === 'NewSetInFolder'){
			this.handleNewSet(item.id)
		}
		this.delayedMenuClose()
	}

	handleMultiSelectContextMenuClick(e, data){	
		e.stopPropagation() //prevent click on context menu deselecting cells etc
		const {multiSelectItems}=this.props
		if(Object.keys(multiSelectItems).length !==0){
			if(data.action === 'Archive'){
				this.batchArchiveRepoItems()
			}else if(data.action === 'MoveToFolder'){
				this.batchMoveToFolder(data.folder)
			}else if(data.action === 'Move'){
				this.showMoveToFolderModal(multiSelectItems)
			}else if(data.action === 'MoveToRepo'){
				this.props.showRepoSelectionModal(null,multiSelectItems)
			}else if (data.action === 'CombineIntoSet'){
				this.combineIntoSet()
			}else if(data.action === 'Queue'){
				this.batchAddToQueue(data.section)
			}
			this.delayedMenuClose()
		}
	}

	////Misc

	confirmBatchArchive(items){
		setTimeout(function() { //To ensure opens after dropdown closed (to prevent screen scrolling while modal open) 
			this.props.showConfirmModal(items,this.batchArchive,'batchArchive')		
		}.bind(this),	1)			
	}

	archiveRepoItem(item,isMultiSelect){
		const {repo}=this.props		
		if(repo.shared){
			this.props.showConfirmModal(item,this.archiveItem,'archiveRepoItem')
		}else{
			this.archiveItem(item,isMultiSelect) 
		}
	}

	batchArchiveRepoItems(){
		this.props.showConfirmModal(this.props.multiSelectItems,this.batchArchive,'batchArchive')
	}

	batchArchive() {
		const {multiSelectItems}=this.props
		let promises = []
		each(Object.keys(multiSelectItems), function(key) {
			const item=multiSelectItems[key]
			promises.push(this.archiveItem(item,true))			
		}.bind(this))

		Promise.all(promises).then(function () {
			this.props.hideModal()
			this.props.showNotification(`${Object.keys(multiSelectItems).length} items`,'Deleted','destroy')		
			this.props.resetSelectedCells()
		}.bind(this))
	}

	archiveItem(item,isMultiSelect) {
		this.props.hideModal()
		const nowDate = new Date()
		let notificationMessage
		const requestData = {
			...item,
			clientModified: nowDate,
			archived: true
		}
		if(item.ancestors){
			return this.props.updateFolder(requestData, item.id).then((response) => {
				if(!isMultiSelect){
					notificationMessage=`${item.name}`
					this.props.showNotification(notificationMessage,'Deleted','destroy')
				}
				return response
			})
		}else if(item.questions){
			return this.props.updateSetMeta(requestData, item.id).then((response) => {
				if(!isMultiSelect){
					notificationMessage=`${item.name}`
					this.props.showNotification(notificationMessage,'Deleted','destroy')
				}
				return response
			})
		}else {
			return this.props.updateQuestionMeta(requestData, item.id).then((response) => {
				if(!isMultiSelect){
					notificationMessage=`${item.body}`  
					this.props.showNotification(notificationMessage,'Deleted','destroy')      
				}
				return response
			})
		}
	}

	copyQuestionToClipboard(question){
		copyQuestion(question)
		const notificationMessage='to Clipboard'
		this.props.showNotification(notificationMessage,'Copied','default')
	}

	createCopy(item){
		this.props.createCopyOfItem(item).then((response) => {
			const notificationMessage=`${item.name ||getQuestionBodyLabel(item) }`   
			this.props.showNotification(notificationMessage,'Created a copy of','create')
			this.props.changeActiveItem(response)
		})
	}

	queueItem(item,section,isBatchRequest,plannedTime){
		this.props.createPoll(item,section.id,isBatchRequest,plannedTime)
	}

	playItem(item,section){
		if(section){
			this.props.playItem(item,section.id).then(() => {
				showLiveViewModal()
			})
		}else{
			this.props.showSectionSelectionModal(item)
		}
	}

	navigateToTrash(){	
		this.props.history.push(`${getLinkForRepo(this.props.repo)}/trash`)
		this.props.resetSelectedCells()
	} 

	getNameForUserId(userId){
		if(isCurrentUser(userId)){
			return 'You'
		}else{
			const {permissions} = this.props	
			if(permissions){	
				const userPermission =permissions.find(permission => {
					return permission.user.id === userId})
				if(userPermission){
					return getDisplayNameForUser(userPermission.user)
				}else{
					return ''
				}	
			}else return ''
		}
	}
	

	handleSelectRow(selectedItem,e){
		const combinedData=this.props.folders.concat(this.state.repoData)
		this.props.handleSelectRow(selectedItem,e,combinedData)
	}

	keyPressNavigation(e){
		//prevent keypress navigation if context menu is open of command key pressed
		if(!this.state.contextMenuActiveItem===null || e.metaKey || e.ctrlKey){
			e.preventDefault()//prevent container scrolling
		}
		if(e.keyCode===32 && !this.props.previewItem ){
			e.preventDefault()
			if(!this.props.activePanelItem.ancestors){
				this.props.updatePreviewItem(this.props.activePanelItem.id)
			}
			e.stopPropagation()
		}else{
			if (e.keyCode === 40 || e.keyCode === 38 ){
				e.preventDefault()
				if(!this.props.previewItem){
					const allData=this.props.folders.concat(this.state.repoData)||[]
					this.props.keyPressNavigation(e,allData)
				}
			}else if (e.keyCode === 13) { //if enter pressed on selected row open editor in new tab	
			 	const {activePanelItem}=this.props
				if(activePanelItem.ancestors){
					this.navigateToFolder(activePanelItem.id)	    	
				}else if(!activePanelItem.readOnly){
					if(activePanelItem.body){
						openItemInEditor(activePanelItem.id,false)
					}else if(activePanelItem.questions){
						openItemInEditor(activePanelItem.id,true)
					}
				}
			}
		}
	}

	finishPublishingFlow(){
		this.setState({showPublishRepoModal:false})
		window.location.hash=''
	}

	publishRepo(){
		return this.props.publishRepo(this.props.repo.id).then((response) => {
			const publicRepoLink=getLinkForRepo(response)
			this.props.history.push(`${publicRepoLink}`)
			sendPublishRepoEvent(response.id)
			this.setState({showPublishRepoModal:false})
			window.location.hash=''
			this.props.showNotification(this.props.repo.name,'Published ','default')			
		})
		 .catch((error) => {
		 	this.props.showNotification('to publish','Failed ','destroy')
			this.setState({showPublishRepoModal:false})
		 })
	}


	unpublishRepo(){
		const {repo}=this.props
		this.props.showConfirmModal(repo,this.handleUnpublishRepo,'unPublishRepo')
	}

	handleUnpublishRepo(){
		const followerCount=this.props.repoFollowerCount
		return this.props.unpublishRepo(this.props.repo.id).then((response) => {
			const publicRepoLink=getLinkForRepo(response)
			this.props.history.push(`${publicRepoLink}`)
			this.props.hideModal()
			if(window.analytics){
				 window.analytics.track('Unpublish Repo',{
					repoId:this.props.repo.id,
					repoFollowerCount:followerCount
				})
			}		
		})
		 .catch((error) => {
		 	this.props.showNotification('to unpublish','Failed ','destroy')
		 })
	}

	followRepo(repo){
		this.setState({followButtonSubmitLoading:true})
		this.props.followRepo(repo).then(()=>{
			this.setState({followButtonSubmitLoading:false})
			this.props.showNotification(`${repo.name}`,'Following ','default')
			const cellNode = document.getElementById(`DnDSideNavRepo_${repo.id}`)
			if(cellNode ){
				cellNode.scrollIntoView({block: 'end', behavior: 'instant'})
			}
			sendFollowRepoEvent(repo.id)
		})
		.catch((error) => {
		 	this.props.showNotification(`${repo.name}`,'Unable to follow ','destroy')
		 })
	}

	unfollowRepo(repo){
		this.props.unfollowRepo(repo).then(()=>{
			this.props.showNotification(`${repo.name}`,'Unfollowed ','destroy')
			if(window.analytics){
				 window.analytics.track('Unfollow Repo',{
						repoId:repo.id,
				})
			}
		})
	}

	centerPanelOnClick(){
		this.props.changeActiveItem({}) //deselect active cell by clicking outside the main table
	}

	showPublishModal(){
		const location=window.location
		if(this.props.repo.published && !location.hash==='#publish-success'){
			return false
		}else{
			if(location.hash==='#publish' 
				|| location.hash==='#publish-subject'
				|| location.hash==='#publish-gradeLevel'
				|| location.hash==='#publish-description'
				|| location.hash==='#publish-image'
				|| location.hash==='#publish-review'
				|| location.hash==='#becomeACreator'
				|| location.hash==='#publish-success'
				 ){
				return true
			}else return false
		}
	}

	dismissPublishModal(){
		this.props.history.push(window.location.pathname)
	}

	openPublishModal(){
		if(this.props.currentUser && this.props.currentUser.username){
			if(!this.props.repo.subject){
				window.location.hash='publish-subject'
			}else if(!this.props.repo.grade || this.props.repo.grade.length===0 || !this.props.repo.language){
				window.location.hash='publish-gradeLevel'
			}else if(!this.props.repo.description ){
				window.location.hash='publish-description'
			}else if(!this.props.repo.image ){
				window.location.hash='publish-image'
			}else {
				window.location.hash='publish-review'
			}
		}else{
			window.location.hash='becomeACreator'
		}
	}


	render() {
		const {repo,repoDisabled,permissions,parentFolder,multiSelectContainsFolders,multiSelectContainsReadOnly}=this.props
		const {activePanelItem}=this.props		
		const isViewer = repo.role==='viewer'
		const isPending =  !repo.published && !repo.joined
		const isPublicConsumer = repo.role==='consumer'
		const isPublicAuthor = repo.role==='author'
		const isFollowing = repo.following 
		let readOnlyPrivileges

		if(isViewer || isPending || repoDisabled || isPublicConsumer){
			readOnlyPrivileges = true
		} 

		const uploadingImage = this.state.uploadingToCloudinary
		const showPublishModal=this.showPublishModal()
 
		return (
			<DocumentTitle title={`${repo.name? `${repo.name} - Plickers`:'Plickers'}`}>
				<div className='page page--sidePanelLayout'>
					<div className='page-leftPanel'/>
					<div className={'page-centerPanel repo ' + (isPublicConsumer ? 'repo--publicConsumer':'')} onClick={this.centerPanelOnClick}>
						{(!repo.published &&((repo.id && !repo.joined) || repoDisabled)) &&    																
							<RepoInvitationBanner repo={repo} repoDisabled={repoDisabled} service={this.props.service} permissions={permissions} joinRepo={()=>{return this.props.joinRepo(repo.id)}} rejectInvitation={this.rejectInvitation} meta={this.props.meta}/>    					
						}
						{this.state.repoData.length === 0 && this.state.folders.length === 0 && !parentFolder && repo.joined && !readOnlyPrivileges &&						
							<RepoEmptyStateHelpVideo showHelpVideoModal={()=>{this.setState({showHelpVideoModal:true})}}/>    				
						}
						{this.state.showHelpVideoModal && this.props.repo && this.props.permissions &&
							<HelpVideoModal videoUrl="https://www.youtube-nocookie.com/embed/pN49YK_tr2I" closeHelpVideoModal={()=>{this.setState({showHelpVideoModal:false})}}/>
						}
						
						{showPublishModal &&
							<PublishRepoFlowContainer 
								repo={repo}
								closePublishRepoModal={this.dismissPublishModal} 
								publishRepo={this.publishRepo}
								finishPublishingFlow={this.finishPublishingFlow}
							/>
						}

						<RepoPageHeaders
							parentFolder={parentFolder}
							repoDisabled={repoDisabled}
							uploadingImage={uploadingImage}
							readOnlyPrivileges={readOnlyPrivileges}
							isViewer={isViewer}
							isPending={isPending}
							repo={repo}
							parentFolderId={this.props.parentFolderId}
							handleNewFolder={this.handleNewFolder}
							confirmLeaveRepo={this.confirmLeaveRepo}
							confirmDeleteRepo={this.confirmDeleteRepo}
							handleRemoveImage={this.handleRemoveImage}
							editRepoName={this.editRepoName}
							editRepoDescription={this.editRepoDescription}
							allFolders={this.props.allFolders}
							permissions={this.props.permissions}
							handleFileUpload={this.handleFileUpload}
							editFolderName={this.editFolderName}
							navigateToFolder={this.navigateToFolder}
							navigateToTrash={this.navigateToTrash}
							showEditRepoModal={this.props.showEditRepoModal}
							permissionsJoined={this.props.permissionsJoined}
							isPublicAuthor={isPublicAuthor}
							isPublicConsumer={isPublicConsumer}
							isFollowing={isFollowing}
							currentUser={this.props.currentUser} // temp for profile pic for public repo
							unfollowRepo={()=>{this.unfollowRepo(repo)}}
							unpublishRepo={this.unpublishRepo}
							unpublishDisabled={this.props.repoSubmission && this.props.repoSubmission.open && this.props.repoSubmission.status!=='approved'}						
						/>
 					
						<RepoPage 
							repoDisabled={repoDisabled}
							playItem={this.playItem}
							activeItem={this.props.activePanelItem}   
							keyPressNavigation={this.keyPressNavigation}
							multiSelectItems={this.props.multiSelectItems}
							multiSelectEnabled={this.props.multiSelectEnabled}
							repoData={this.state.repoData}
							folders={this.state.folders}
							navigateToFolder={this.navigateToFolder}
							parentFolder={parentFolder} 
							isSharedRepo={repo.shared}
							isPublishedRepo={repo.published}
							isViewOnlyRepo={(repo.role==='viewer'||repo.role==='consumer')?true:false}
							getNameForUserId={this.getNameForUserId}
							joined={repo.joined}
							published={repo.published}
							selectRow={this.handleSelectRow}
							navigateToItemDetail={this.navigateToItemDetail}
							locks={this.props.locks}
							moveToRepo={this.props.moveToRepo}
							showNotification={this.props.showNotification}
							resetState={this.props.resetSelectedCells}
							sections={this.props.sections}
							handleContextMenuClick={this.handleContextMenuClick}
							handleFolderContextMenuClick={this.handleFolderContextMenuClick}
							contextMenuActiveItem={this.state.contextMenuActiveItem}
							contextMenuObject={this.state.contextMenuObject}
							onShowContextMenu={this.onShowContextMenu}
							onHideContextMenu={this.onHideContextMenu}
							handleMultiSelectContextMenuClick={this.handleMultiSelectContextMenuClick}
							multiSelectContainsFolders={multiSelectContainsFolders}
							multiSelectContainsReadOnly={multiSelectContainsReadOnly}
							sortBy={this.state.sortedBy}
							sortOrder={this.state.sortOrder}
							sortRepo={this.changeRepoSortOrder}
							repo={this.props.repo}
							showHelpVideoModal={()=>{this.setState({showHelpVideoModal:true})}}
							createReview={this.props.createReview}
							user={this.props.currentUser}
							userHasReviewedRepo={this.props.userHasReviewedRepo}
							userInDiscoverBeta={this.props.userInDiscoverBeta}
							repoInDiscoverBeta={this.props.repoInDiscoverBeta}
							questionsInSetLimit={this.props.questionsInSetLimit}
						/>
					</div>
 
					{!isEmpty(activePanelItem) && 
						<PreviewPanelContainer 
							activePanelItem={activePanelItem}
							resetSelectedCells={this.props.resetSelectedCells}
							multiSelectItems={this.props.multiSelectItems}
							multiSelectContainsFolders={multiSelectContainsFolders}
			 				multiSelectContainsReadOnly={multiSelectContainsReadOnly}
			 				folders={this.props.folders}
							batchMoveToFolder={this.batchMoveToFolder}
							combineIntoSet={this.combineIntoSet}
							batchArchive={this.confirmBatchArchive}
							showMoveToFolderModal={this.showMoveToFolderModal}
							questionsInSetLimit={this.props.questionsInSetLimit}
						/> 
					}

					{isEmpty(activePanelItem) && 
						<div className="page-rightPanel">
							<div className='sidePanelContainer'>
								<div className='sidePanel'>							
									{isEmpty(activePanelItem) && !isPending &&
										<RepoDefaultPreviewPanel 
											service={this.props.service}
											meta={this.props.meta}
											repoId={repo.id}
											repo={repo}
											isPublicAuthor={isPublicAuthor}
											isPublicConsumer={isPublicConsumer}
											isFollowing={isFollowing}
											currentUserPermission={this.props.currentUserPermission}
											permissionsJoined={this.props.permissionsJoined}
											permissionsPending={this.props.permissionsPending}
											showInvitationModal={()=>{this.setState({showInvitationModal:true})}}
											openPublishRepoModal={this.openPublishModal}
											followRepo={()=>{this.followRepo(repo)}}
											repoFollowerCount={this.props.repoFollowerCount}
											followButtonSubmitLoading={this.state.followButtonSubmitLoading}
											userFollowsNoRepos={this.props.userFollowsNoRepos}
											showReviewRepoModal={()=>{this.setState({showReviewModal:true})}}
											userHasReviewedRepo={this.props.userHasReviewedRepo}
											showSubmitForPackReviewModal={()=>{this.setState({showSubmitForPackReviewModal:true})}}
											repoSubmission={this.props.repoSubmission}
											submissionsLoaded={this.props.allSubmissions?true:false}
										/>
									}

								</div>
							</div>									
						</div>
					}

					{this.state.showInvitationModal && this.props.repo && this.props.permissions &&
						<RepoInvitationModal
							permissions={this.props.permissions} 
							repo={this.props.repo}
							closeInvitationModal={()=>{this.setState({showInvitationModal:false})}}
							inviteByEmail={this.inviteByEmail}
							inviteByUser={this.inviteByUser}
							currentUser={this.props.currentUser}
							showNotification={this.props.showNotification}
							updatePermission={this.props.updatePermission}
							deletePermission={this.props.deletePermission}
							currentUserPermission={this.props.currentUserPermission}
							permissionsJoined={this.props.permissionsJoined}
							permissionsPending={this.props.permissionsPending}
							searchKey={this.props.searchKey}	
							inviteToPlickers={this.inviteToPlickers}						
						/>
					}

					{this.state.showReviewModal &&
						<ReviewPublishedRepoModal
							repoInDiscoverBeta={this.props.repoInDiscoverBeta}
							userInDiscoverBeta={this.props.userInDiscoverBeta}
							closeModal={()=>{this.setState({showReviewModal:false})}}
							repo={this.props.repo}
							createReview={this.props.createReview}
							showNotification={this.props.showNotification}
							user={this.props.currentUser}
						/>
					}

					{this.state.showSubmitForPackReviewModal &&
						<SubmitForPackReviewModal
							closeModal={()=>{this.setState({showSubmitForPackReviewModal:false})}}
							repo={this.props.repo}
							submitRepoForReview={this.props.submitRepoForReview}
							showNotification={this.props.showNotification}
						/>
					}
				</div>
			</DocumentTitle>
		)
	}
}


function sortFolders(sortType,sortOrder,folders) {
	let sorted = folders.slice(0)
	sorted=sortBy(sorted,[function(o) { 
		return o.name.toString().toLowerCase()
	}])
	if (sortType === 'name' && sortOrder!=='ascending') {
		sorted=sorted.reverse()
	}
	return sorted
}

function mapStateToProps(state,ownProps) {
	const repoId=ownProps.repoId
	const parentFolderId=ownProps.match.params.folderId || null

	const currentRepo=find(state.repos, {'id':repoId})||{}
	let repoFollowerCount
	if(currentRepo && currentRepo.role==='author'){
		repoFollowerCount=state.reposFollowerCount[repoId]
	}
	const permissions=state.permissions[ownProps.repoId]
	const currentUser=state.user

	let currentUserPermission
	let permissionsJoined
	let permissionsPending
	let repoDisabled = false
	if(currentRepo){
		repoDisabled=currentRepo.disabled
	}
	if(permissions && currentUser.id){
		const indexOfCurrentUserPermission = permissions.findIndex(permission => {
			if(permission.user){
				return permission.user.id === currentUser.id
			}else return  permission.originalEmail === currentUser.email
		})
		currentUserPermission=permissions[indexOfCurrentUserPermission]
		const permissionsWithoutCurrentUser=[
			...permissions.slice(0, indexOfCurrentUserPermission),
			...permissions.slice(indexOfCurrentUserPermission + 1)
		]
		 permissionsJoined=sortBy(filter(permissionsWithoutCurrentUser,{joined:true}),[permission => permission.user.firstName.toLowerCase()])
		 permissionsPending=sortBy(filter(permissionsWithoutCurrentUser,{joined:false}),[permission => permission.created])
	}
	const librarySettings=getLibrarySettings()
	let sets
	let questions
	let folders
	if((currentRepo.joined && !repoDisabled)||currentRepo.published){
		questions=filter(state.questions, {'repo':repoId,folder:parentFolderId})
		sets=filter(state.sets, {'repo':repoId,folder:parentFolderId})
		folders=filter(state.folders, {'repo':repoId,parent:parentFolderId,archived:false})
	}else{
		questions=filter(state.questionsPreview, {'repo':repoId,folder:null})
		sets=filter(state.setsPreview, {'repo':repoId,folder:null})
		folders=filter(state.foldersPreview, {'repo':repoId,parent:null,archived:false})
	}
	let repoData=[]
	if(state.sets && state.questions){
		repoData=sortLibraryData(librarySettings.sortType,librarySettings.sortOrder,sets.concat(questions))
	}
	let sortedFolders=[]
	if(folders){
		sortedFolders=sortFolders(librarySettings.sortType,librarySettings.sortOrder,folders)
	}
	let questionsInSetLimit
	if(state.planRestrictions){
		questionsInSetLimit=state.planRestrictions.questionsInSetLimit
	}
	const searchKey=state.searchKey //for repo invitations
	let userSearchKey
	if(searchKey){
		userSearchKey=searchKey.user
	}

	let userFollowsNoRepos=true
	const followedRepos=filter(state.repos,{following:true})
	if(followedRepos.length>0){
		userFollowsNoRepos=false
	}
	const {meta}=state
	const userInDiscoverBeta = meta && meta.discoveryBetaEnabledMarkets && meta.discoveryBetaEnabledMarkets.length>0 
	let repoInDiscoverBeta=false
	if(isRepoInDiscoverBeta(currentRepo)){
		repoInDiscoverBeta=true
	}
	let userHasReviewedRepo=false
	const review=find(state.reviews,{'repo':currentRepo.id})
	if(review){
		userHasReviewedRepo=true
	}
	let repoSubmission=null
	if(state.submissions){
		const repoSubmissions=filter(state.submissions,{repo:repoId})
		repoSubmission=filter(repoSubmissions,{status:'approved'})[0] ||filter(repoSubmissions,{open:true})[0]
	}
	return {
		previewItem:state.previewItem,
		userInDiscoverBeta:userInDiscoverBeta,
		repoInDiscoverBeta:repoInDiscoverBeta,
		allSubmissions:state.submissions,
		repoSubmission:repoSubmission,
		userFollowsNoRepos:userFollowsNoRepos,
		userHasReviewedRepo:userHasReviewedRepo,
		service:state.service,
		repo:currentRepo,
		repoDisabled:repoDisabled,
		locks:state.locks[ownProps.repoId],
		parentFolderId:parentFolderId,
		parentFolder:find(state.folders,{'id':parentFolderId})||null,
		repoData:repoData,
		folders:sortedFolders,
		permissions:permissions,
		currentUser:currentUser,
		uploadingImage:state.modals.image,
		currentUserPermission:currentUserPermission,
		permissionsJoined:permissionsJoined,
		permissionsPending:permissionsPending,
		allFolders:state.folders,
		searchKey:userSearchKey,
		questionsInSetLimit:questionsInSetLimit,
		sections:state.sections,
		meta:state.meta,
		repoFollowerCount:repoFollowerCount
	}
}
 
export default withSelectableCells(withRouter(connect(
	mapStateToProps,
	{ 
		inviteByEmail,
		updateRepo,
		deleteRepo,
		publishRepo,
		showConfirmModal,
		hideModal,
		showNotification,
		fetchPermissions,
		joinRepo,
		rejectInvitation,
		leaveRepo,
		updatePermission,
		deletePermission,
		uploadRepoImageToCloudinary,
		finishImageUpload,
		showEditFolderModal,
		updateFolder,
		fetchLocksForRepo,
		inviteByUser,
		playItem,
		createPoll,
		showMoveToFolderModal,
		createCopyOfItem,
		updateQuestionMeta,
		updateSetMeta,
		combineIntoSet,
		showSectionSelectionModal,
		showEditRepoModal,
		showRepoSelectionModal,
		showRenameSetModal,
		fetchQuestions,
		fetchSets,
		fetchFolders,
		addCopyToLibrary,
		inviteToPlickers,
		followRepo,
		unfollowRepo,
		unpublishRepo,
		createReview,
		submitRepoForReview,
		updatePreviewItem
	}
)(RepoContainer)))

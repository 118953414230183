
import findIndex from 'lodash/findIndex'
export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_ADMIN_CREATORS_SUCCESS':	
		return action.response

	case 'ADMIN_UPDATE_CREATOR_METADATA_SUCCESS':	

		const updatedCreatorId = action.userId
		var indexOfCreatorToUpdate = findIndex(state,(creator => {
			return creator.id === updatedCreatorId
		}))
		if(indexOfCreatorToUpdate !== -1 ){ 
			let updatedCreator=Object.assign({},state[indexOfCreatorToUpdate])	
			updatedCreator.explorerMetadata=action.response

			return [
					...state.slice(0,indexOfCreatorToUpdate),
						updatedCreator,
					...state.slice(indexOfCreatorToUpdate + 1)
				]	
		}
		else return state


 


	case 'LOGOUT':
		return null
	
	default:
		return state
	}
}

import React from 'react'
import ReactSlider from 'react-slider'
import EditorIcon from '../../../misc/EditorIcons'
import Icon from '../../../misc/Icons'
import YTReady from '../../../../youtubeReady'
import { addVideo } from '../../../../prosemirror/utils/editorActions/addVideo'
import { updateVideoClipTimes } from '../../../../prosemirror/utils/editorActions/updateVideoClipTimes'
import { formatVideoDuration } from '../../../../utils/timestampFormatters/formatVideoDuration'
import { formatVideoDurationWithMilliseconds } from '../../../../utils/timestampFormatters/formatVideoDurationWithMilliseconds'
import { generateRandomId } from '../../../../utils/generateRandomId'
// eslint-disable-next-line import/no-cycle
import { showInsertVideoModal } from '../../utils/showInsertVideoModal'

const INFO_BAR_WIDTH = 280
const CONTROLS_HEIGHT = 132 // seekbar and play etc.
const HORIZONTAL_PADDING_TO_MODAL = 50// padding either side of modal
const VERTICAL_PADDING_TO_MODAL = 20 // padding either side of modal
const VIDEO_WIDTH = 640
const VIDEO_HEIGHT = 360
const VIDEO_ASPECT = VIDEO_WIDTH / VIDEO_HEIGHT
const MAX_MODAL_WIDTH = 1400
const MAX_MODAL_HEIGHT = 740
const SEEK_BAR_SIDE_MARGIN = 140 // either side of seekbar, for play buton and timestamps etc.

function calculateModalDimensions(windowWidth, windowHeight) {
  const availableWindowWidth = windowWidth - (2 * HORIZONTAL_PADDING_TO_MODAL)
  const availableWindowHeight = windowHeight - (2 * VERTICAL_PADDING_TO_MODAL)
  const availableVideoWidth = availableWindowWidth - INFO_BAR_WIDTH
  const availableVideoHeight = availableWindowHeight - CONTROLS_HEIGHT
  const availableVideoAspect = availableVideoWidth / availableVideoHeight

  let displayVideoWidth
  let displayVideoHeight

  if (VIDEO_ASPECT >= availableVideoAspect) { // video wider than available space
    displayVideoWidth = availableVideoWidth
    displayVideoHeight = displayVideoWidth / VIDEO_ASPECT
  }
  if (VIDEO_ASPECT < availableVideoAspect) { // video taller than available space
    displayVideoHeight = availableVideoHeight
    displayVideoWidth = displayVideoHeight * VIDEO_ASPECT
  }
  const modalWidth = Math.min(displayVideoWidth + INFO_BAR_WIDTH, MAX_MODAL_WIDTH)
  const modalHeight = Math.min(displayVideoHeight + CONTROLS_HEIGHT, MAX_MODAL_HEIGHT)
  return {
    modalWidth,
    modalHeight,
    displayVideoWidth,
    seekBarWidth: displayVideoWidth - SEEK_BAR_SIDE_MARGIN,
  }
}

class EditorEditVideoModal extends React.Component {
  constructor(props) {
    super(props)
    this.loadVideo = this.loadVideo.bind(this)
    this.playVideo = this.playVideo.bind(this)
    this.onSliderChange = this.onSliderChange.bind(this)
    this.handleRangeSliderChange = this.handleRangeSliderChange.bind(this)
    this.onStateChange = this.onStateChange.bind(this)
    this.pauseVideo = this.pauseVideo.bind(this)
    this.startTimer = this.startTimer.bind(this)
    this.updatePlayerCurrentTime = this.updatePlayerCurrentTime.bind(this)
    this.seekTo = this.seekTo.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
    this.insertVideo = this.insertVideo.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.nudgePlayerCurrentTime = this.nudgePlayerCurrentTime.bind(this)
    this.alignClipBoundToPlayerCurrentTime = this.alignClipBoundToPlayerCurrentTime.bind(this)
    this.togglePlayClipModeActive = this.togglePlayClipModeActive.bind(this)
    this.handleBackgroundMouseDown = this.handleBackgroundMouseDown.bind(this)
    this.handleBackgroundMouseUp = this.handleBackgroundMouseUp.bind(this)
    this.videoHasUnsavedChanges = this.videoHasUnsavedChanges.bind(this)
    this.restartClip = this.restartClip.bind(this)
    this.onYTPlayerReady = this.onYTPlayerReady.bind(this)
    this.toggleDescriptionShowMore = this.toggleDescriptionShowMore.bind(this)
    this.backToSearchModal = this.backToSearchModal.bind(this)

    const rangeStart = props.video.start
    const rangeEnd = props.video.end
    let isPlaying = false
    let playClipModeActive = false
    if (this.props.autoplay && this.props.videoNode && this.props.videoNode.attrs.isClipped) {
      playClipModeActive = true
      isPlaying = true
    }

    this.state = {
      rangeValues: [rangeStart, rangeEnd],
      sliderValue: props.video.start,
      isPlaying,
      playerCurrentTime: props.video.start,
      descriptionShowMore: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      playClipModeActive,
      isDraggingTrimBar: false,
      ytPlayerReady: false,
      isDraggingSeekBar: false,
      playerState: null,
    }

    this.id = generateRandomId()
    this.timer = null
    this.player = null
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('keydown', this.onKeyDown)
    if (window.analytics) {
      window.analytics.page('Edit Video Modal', {
        isInsertFlow: this.props.isInsertFlow,
      })
    }
  }

  componentDidMount() {
    const youtubeReady = YTReady
    youtubeReady.then((YT) => {
      this.loadVideo(YT)
    })
  }

  componentWillUnmount() {
    this.stopTimer()
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    document.removeEventListener('keydown', this.onKeyDown)
  }

  toggleDescriptionShowMore() {
    this.setState((prevState) => ({ descriptionShowMore: !prevState.descriptionShowMore }))
    const objDiv = document.getElementById('descriptionScrollableDiv')
    if (objDiv) {
      objDiv.scrollTop = 0
    }
  }

  loadVideo(YT) {
    const { video } = this.props
    const videoId = video.youtubeId
    const autoplay = this.props.autoplay ? 1 : 0
    /* eslint-disable camelcase */
    this.player = new YT.Player(`youtube-editor-player-${this.id}`, {
      videoId,
      playerVars: {
        controls: 0, // disable controls
        // 'cc_load_policy': 1, // don't know what this does
        loop: 0, // loop video
        autoplay, // autoplay
        fs: 0, // show full screen option
        disablekb: 1, // disable keyboard shortcuts
        rel: 0, // either hides related (depreciated) or only shows from same chanel
        iv_load_policy: 3, // don't show video annotations by default
        hl: 'en', // interface language
        cc_lang_pref: 'en',
        // don't know what these do, from EdPuzzle
        playsInline: 1,
        showinfo: 0, // undocumented, should hide title but I guess depreciated
        wmode: 'opaque', // undocumented
        start: (this.props.video.start),
      },
      host: 'http://www.youtube-nocookie.com',
      events: {
        onReady: this.onYTPlayerReady,
        onStateChange: this.onStateChange,
      },
    })
    /* eslint-enable camelcase */
  }

  onYTPlayerReady() {
    this.setState({ ytPlayerReady: true })
    const playerState = this.player.getPlayerState()
    this.handlePlayerStateChange(playerState)
  }

  onStateChange(event) {
    this.handlePlayerStateChange(event.data)
  }

  handlePlayerStateChange(playerState) {
    this.setState({ playerState })
    switch (playerState) {
      case -1:// unstarted
        this.stopTimer()
        this.setState({ isBuffering: true })
        break
      case 0:// ended
        this.setState({
          isPlaying: false, isBuffering: false, playClipModeActive: false,
        })
        this.stopTimer()
        break
      case 1:// playing
        this.setState({ isPlaying: true, isBuffering: false })
        this.startTimer()
        break
      case 2:// paused
        this.stopTimer()
        this.setState({ isPlaying: false, isBuffering: false })
        break
      case 3:// BUFFERING
        this.stopTimer()
        this.setState({ isBuffering: true })
        break
      default:
        break
    }
  }

  playVideo() {
    this.setState({ isPlaying: true })
    this.player.playVideo()
  }

  pauseVideo() {
    this.setState({ isPlaying: false })
    this.player.pauseVideo()
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.updatePlayerCurrentTime()
    }, 100)
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  onSliderChange(sliderValue) {
    this.setState({ sliderValue, playerCurrentTime: sliderValue, isBuffering: true })
    this.seekTo(sliderValue)
  }

  restartClip() {
    this.seekTo(this.state.rangeValues[0])
    this.setState((prevState) => ({
      sliderValue: prevState.rangeValues[0],
      playerCurrentTime: prevState.rangeValues[0],
    }))
  }

  handleRangeSliderChange(sliderValues) { // clip range slider
    const oldValues = this.state.rangeValues
    let startValueChanged = false
    if (sliderValues[0] !== oldValues[0]) {
      startValueChanged = true
    }
    let newSliderValue
    if (startValueChanged) {
      newSliderValue = sliderValues[0]
      this.onSliderChange(newSliderValue)
    }
    this.setState({ rangeValues: sliderValues })
  }

  seekTo(seconds) {
    // if is first seek will play video so set isPlaying to true
    const playerState = this.player.getPlayerState()
    if (playerState === -1 || playerState === 5) {
      this.setState({ isPlaying: true }) // don't wait for video to actually play
    }
    this.player.seekTo(seconds, true)
    if (this.state.playClipModeActive) { // check if seek to outside of clip and if so turn off play clip mode
      if (seconds < this.state.rangeValues[0] || seconds > this.state.rangeValues[1]) {
        this.setState({ playClipModeActive: false })
      }
    }
  }

  updatePlayerCurrentTime() {
    const currentTime = this.player.getCurrentTime()
    if (!this.state.isBuffering) {
      if (this.state.playClipModeActive) {
        if (currentTime < this.state.rangeValues[1]) {
          this.setState({ playerCurrentTime: currentTime, sliderValue: currentTime })
        } else {
          this.pauseVideo()
          this.setState({ playClipModeActive: false })
        }
      } else {
        this.setState({ playerCurrentTime: currentTime, sliderValue: currentTime })
      }
    }
  }

  togglePlayClipModeActive() {
    if (!this.state.playClipModeActive) {
      if (this.state.playerCurrentTime !== this.state.rangeValues[0]) {
        this.setState((prevState) => ({
          playerCurrentTime: prevState.rangeValues[0],
          sliderValue: prevState.rangeValues[0],
        }))
        this.seekTo(this.state.rangeValues[0])
      }
      this.setState({ playClipModeActive: true })
      if (!this.state.isPlaying) {
        this.playVideo()
      }
    }
  }

  nudgePlayerCurrentTime(nudgeDirection, nudgeAmount) {
    const currentTime = this.state.sliderValue
    let newCurrentTime = currentTime
    if (nudgeDirection === 'forwards') {
      newCurrentTime = Math.min(newCurrentTime + nudgeAmount, this.props.video.fullDuration)
    } else {
      newCurrentTime = Math.max(newCurrentTime - nudgeAmount, 0)
    }
    this.seekTo(newCurrentTime)
    this.setState({ sliderValue: newCurrentTime, playerCurrentTime: newCurrentTime })
  }

  alignClipBoundToPlayerCurrentTime(clipBound) {
    this.setState((prevState) => {
      const currentTime = prevState.sliderValue
      let newValues
      if (clipBound === 'clipStart') {
        newValues = [currentTime, prevState.rangeValues[1]]
      } else {
        newValues = [prevState.rangeValues[0], currentTime]
      }
      return { rangeValues: newValues, playerCurrentTime: currentTime }
    })
  }

  onKeyDown(e) {
    e.stopPropagation()
    if (this.state.ytPlayerReady) {
      if (e.keyCode === 13) {
        e.preventDefault()
        if (this.props.isInsertFlow) {
          this.insertVideo()
        } else {
          this.saveChanges()
        }
      }
      if (e.keyCode === 32 && !e.shiftKey) { // spacebar
        e.preventDefault()
        e.stopPropagation()
        if (this.state.isPlaying) {
          this.pauseVideo()
        } else {
          this.playVideo()
        }
      }
      if (e.keyCode === 32 && e.shiftKey) {
        this.togglePlayClipModeActive()
      }
      if (e.keyCode === 37 && !e.shiftKey) { // left arrow
        this.nudgePlayerCurrentTime('back', 10)
      }
      if (e.keyCode === 37 && e.shiftKey) { // left arrow
        this.nudgePlayerCurrentTime('back', 0.1)
      }
      if (e.keyCode === 39 && !e.shiftKey) { // right arrow
        this.nudgePlayerCurrentTime('forwards', 10)
      }
      if (e.keyCode === 39 && e.shiftKey) { // right arrow
        this.nudgePlayerCurrentTime('forwards', 0.1)
      }
      if (e.keyCode === 27) { // escape key
        if (!this.props.isInsertFlow) {
          this.props.closeEditVideoModal()
        }
      }
    }
  }

  handleBackgroundMouseDown(e) { // TODO check what this is about
    if (e.target === e.currentTarget) {
      this.setState({ mouseDownOutsideModal: true })
    } else {
      this.setState({ mouseDownOutsideModal: false })
    }
  }

  handleBackgroundMouseUp(e) { // TODO check what this is about
    if (e.target === e.currentTarget) {
      if (this.state.mouseDownOutsideModal) {
        if (!this.props.isInsertFlow) {
          const videoHasUnsavedChanges = this.videoHasUnsavedChanges()
          if (!videoHasUnsavedChanges) {
            this.props.closeEditVideoModal() // only close modal if click started outside modal(not drag)
          }
        }
      }
    }
  }

  videoHasUnsavedChanges() {
    let hasChanges = false
    const rangeStart = this.props.video.start
    const rangeEnd = this.props.video.end
    if (this.state.rangeValues[0] !== rangeStart || this.state.rangeValues[1] !== rangeEnd) {
      hasChanges = true
    }
    return hasChanges
  }

  saveChanges() {
    updateVideoClipTimes(this.props.videoNode, this.props.videoNodePos, this.state.rangeValues[0], this.state.rangeValues[1])
    this.timeout = setTimeout(() => {
      this.props.closeEditVideoModal()
    }, 400)
  }

  insertVideo() {
    const {
      video, insertPos, url, isQuestion, questionPos, questionNode,
    } = this.props
    const clipStartTime = this.state.rangeValues[0]
    const clipEndTime = this.state.rangeValues[1]
    addVideo(video, clipStartTime, clipEndTime, insertPos, url, isQuestion, questionPos, questionNode)
    this.props.closeEditVideoModal()
  }

  backToSearchModal() {
    const {
      insertPos, isQuestion, questionPos, questionNode, searchState,
    } = this.props
    this.props.closeEditVideoModal()
    showInsertVideoModal(searchState, insertPos, isQuestion, questionPos, questionNode)
  }

  render() {
    const { video, isInsertFlow } = this.props
    const {
      descriptionShowMore, playClipModeActive, playerCurrentTime, isPlaying, sliderValue, ytPlayerReady, isDraggingSeekBar,
    } = this.state

    //
    // VIDEO METADATA
    const videoTitle = video.title
    const videoAuthor = video.channelTitle
    const videoDescription = video.description || ''
    let displayShowMoreDescriptionToggle = true
    if (!video.description) {
      displayShowMoreDescriptionToggle = false
    }

    const prettifiedVideoURL = `youtube.com/watch?v=${video.youtubeId}`
    const videoURL = `https://${prettifiedVideoURL}`

    //
    // CORE LAYOUT AND POSITIONING OF ELEMENTS
    // TO DO: remove duplication with CSS
    const { windowWidth } = this.state
    const { windowHeight } = this.state

    const modalDimensions = calculateModalDimensions(windowWidth, windowHeight)
    const { modalWidth, modalHeight } = modalDimensions

    //
    // TIMESTAMP AND CLIP CALCULATIONS

    const currentTimestamp = formatVideoDurationWithMilliseconds(sliderValue * 1000)
    const fullTimestamp = formatVideoDuration(video.fullDuration)
    const lengthSeconds = video.fullDuration

    const clipStart = this.state.rangeValues[0]
    const clipEnd = this.state.rangeValues[1]
    const clipDuration = clipEnd - clipStart

    const videoClipped = clipDuration !== lengthSeconds

    const clipStartLeftPercentage = clipStart / lengthSeconds
    const clipEndLeftPercentage = clipEnd / lengthSeconds
    const clipWidthPercentage = clipEndLeftPercentage - clipStartLeftPercentage

    const formattedClipDuration = formatVideoDuration(clipDuration)

    let timeThroughClip = null
    let playClipProgressPercentage = null
    let formattedPlayClipProgressTimestamp = null

    if (playClipModeActive) {
      timeThroughClip = Math.max(playerCurrentTime - clipStart, 0)
      playClipProgressPercentage = (timeThroughClip / clipDuration) * 100
      formattedPlayClipProgressTimestamp = formatVideoDuration(timeThroughClip)
    }

    //
    // CLIP ALIGNMENT BUTTONS
    let showAlignButtons = false

    if (!isPlaying && playerCurrentTime > 0) {
      showAlignButtons = true
    }
    let showStartClipAlign = true
    let showEndClipAlign = true

    if (clipStart === playerCurrentTime) {
      showStartClipAlign = false
      showEndClipAlign = false
    }
    if (clipEnd === playerCurrentTime) {
      showEndClipAlign = false
      showStartClipAlign = false
    }
    if (playerCurrentTime > clipEnd) {
      showStartClipAlign = false
    }
    if (playerCurrentTime < clipStart) {
      showEndClipAlign = false
    }

    let startClipAlignDirection = 'right'
    if (clipStart > playerCurrentTime) {
      startClipAlignDirection = 'left'
    }

    let endClipAlignDirection = 'left'
    if (playerCurrentTime > clipEnd) {
      endClipAlignDirection = 'right'
    }

    //
    // PREVIEW BAR STATE
    let previewBarState = 'hidden'
    const { isDraggingTrimBar } = this.state

    if (videoClipped) {
      previewBarState = 'readyToPlayClip' // at rest
    }

    if (playClipModeActive && isPlaying) {
      previewBarState = 'playingClip' // show playback duration
    }

    if (playClipModeActive && !isPlaying) {
      previewBarState = 'pausedClip' // similar to at reset
    }

    if (isDraggingTrimBar) {
      previewBarState = 'draggingTrimBarHandles' // show duration
    }

    // MISC
    const videoHasUnsavedChanges = this.videoHasUnsavedChanges()

    return (
      <div className={`editor-editVideoModalContainer ${(isDraggingSeekBar || isDraggingTrimBar) ? ' editor-editVideoModalContainer--dragging' : ''}`} onMouseDown={this.handleBackgroundMouseDown} onMouseUp={this.handleBackgroundMouseUp}>
        <div style={{ width: `${modalWidth}px`, height: `${modalHeight}px` }} className={`editor-editVideoModal ${ytPlayerReady ? '' : 'editor-editVideoModal--waitingForPlayerLoad'}`}>
          <div className='editor-editVideoModal-info'>
            {isInsertFlow && (
            <button onClick={this.backToSearchModal} className='editor-editVideoModal-info-backToSearchBtn'>
              <div className='editor-editVideoModal-info-backToSearchBtn-inner'>
                <Icon name='open-left-arrow' />
                {this.props.searchState && this.props.searchState.isUrlInput && (
                <span>
                  Back to Insert Video
                </span>
                )}
                {this.props.searchState && !this.props.searchState.isUrlInput && (
                <span>
                  Back to Search Results
                </span>
                )}
              </div>
            </button>
            )}
            <div className='editor-editVideoModal-info-top notranslate'>
              <div className='editor-editVideoModal-info-top-title'>
                {videoTitle}
              </div>
              <div className='editor-editVideoModal-info-top-author'>
                {videoAuthor}
              </div>
              <a className='editor-editVideoModal-info-top-url' href={`${videoURL}`} target='_blank' rel='noopener noreferrer'>
                {prettifiedVideoURL}
              </a>
              {!displayShowMoreDescriptionToggle && (
              <div className='editor-editVideoModal-info-top-descriptionContainer'>
                <div className='editor-editVideoModal-info-top-description'>
                  {videoDescription}
                </div>
              </div>
              )}
              {displayShowMoreDescriptionToggle && (
              <div className='editor-editVideoModal-info-top-descriptionContainer'>
                <div onClick={this.toggleDescriptionShowMore} id='descriptionScrollableDiv' className={`editor-editVideoModal-info-top-description ${descriptionShowMore ? ' editor-editVideoModal-info-top-description--showingMore' : ' editor-editVideoModal-info-top-description--showingLess'}`}>
                  {videoDescription}
                </div>
                {!descriptionShowMore && (
                <button onClick={this.toggleDescriptionShowMore} className='editor-editVideoModal-info-top-descriptionShowMore'>
                  Show more
                </button>
                )}
                {descriptionShowMore && (
                <button onClick={this.toggleDescriptionShowMore} className='editor-editVideoModal-info-top-descriptionShowMore'>
                  Show less
                </button>
                )}
              </div>
              )}
            </div>

            <a target='_blank' className='editor-editVideoModal-info-helpBtn' rel='noopener noreferrer' href='https://help.plickers.com/hc/en-us/articles/4405451964059'>Help: Video Editing</a>

            <div className='editor-editVideoModal-info-submitBtnContainer'>
              {isInsertFlow && (
              <button onClick={this.insertVideo} className='editor-editVideoModal-doneBtn'>
                <div className='editor-editVideoModal-doneBtn-primary'>
                  Insert Video
                </div>
                <div className='editor-editVideoModal-doneBtn-secondary'>
                  Enter
                </div>
              </button>
              )}
              {!isInsertFlow && (
              <button onClick={this.saveChanges} className='editor-editVideoModal-doneBtn'>
                <div className='editor-editVideoModal-doneBtn-primary'>
                  Done
                </div>
                <div className='editor-editVideoModal-doneBtn-secondary'>
                  Enter
                </div>
              </button>
              )}
              <button onClick={() => { this.props.closeEditVideoModal() }} className={`editor-editVideoModal-cancelBtn ${(videoHasUnsavedChanges || isInsertFlow) ? 'editor-editVideoModal-cancelBtn--show' : 'editor-editVideoModal-cancelBtn--hide'}`}>
                Cancel
              </button>
            </div>
          </div>
          <div className='editor-editVideoModal-preview'>
            <div className={`editor-editVideoModal-preview-videoContainer ${isDraggingSeekBar ? ' editor-editVideoModal-preview-videoContainer--draggingSeekBar ' : ''}${isDraggingTrimBar ? ' editor-editVideoModal-preview-videoContainer--draggingTrimBar ' : ''}`}>
              <div className='editor-editVideoModal-preview-video' id={`youtube-editor-player-${this.id}`} />
            </div>
            <div className={`editor-editVideoModal-preview-controls ${ytPlayerReady ? 'editor-editVideoModal-preview-controls--active' : 'editor-editVideoModal-preview-controls--inactive'}${isDraggingSeekBar ? ' editor-editVideoModal-preview-controls--draggingSeekbar ' : ''}${isDraggingTrimBar ? ' editor-editVideoModal-preview-controls--draggingTrimbar ' : ''}`}>
              <div className='editor-editVideoModal-preview-controls-timestamp-current'>
                {currentTimestamp}
              </div>
              <div className='editor-editVideoModal-preview-controls-timestamp-total'>
                {fullTimestamp}
              </div>
              <div className='editor-editVideoModal-preview-controls-seekBarContainer'>
                <ReactSlider
                  onBeforeChange={() => { this.setState({ isDraggingSeekBar: true }) }}
                  onAfterChange={() => { this.setState({ isDraggingSeekBar: false }) }}
                  className='editor-editVideoModal-preview-controls-seekBar'
                  thumbClassName='editor-editVideoModal-preview-controls-seekBar-thumb'
                  trackClassName='editor-editVideoModal-preview-controls-seekBar-track'
                  min={0}
                  value={this.state.sliderValue}
                  onChange={this.onSliderChange}
                  max={lengthSeconds}
                  renderThumb={(props) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <div {...props}>
                      {isDraggingSeekBar && (
                      <div draggable={false} className='editor-editVideoModal-preview-controls-seekBar-thumb-tooltip'>
                        {formatVideoDuration(this.state.sliderValue)}
                      </div>
                      )}
                      <div draggable={false} className='editor-editVideoModal-preview-controls-seekBar-thumb-circle' />
                      <div draggable={false} className='editor-editVideoModal-preview-controls-seekBar-thumb-circle--loading' />
                      <div draggable={false} className='editor-editVideoModal-preview-controls-seekBar-thumb-line' />
                      <div draggable={false} className='editor-editVideoModal-preview-controls-seekBar-thumb-line--loading' />
                    </div>
                  )}
                />
                {/* HOVER POINTER
                <div className='editor-questionVideoEditModal-preview-controls-seekBarContainer-hoverPointer' />
               */}
              </div>
              <div className='editor-editVideoModal-preview-controls-leftContainer'>
                {isPlaying && ( // stop flash of play button while seeking
                <button className='editor-editVideoModal-preview-controls-playPauseBtn editor-editVideoModal-preview-controls-playPauseBtn--playing' onClick={this.pauseVideo}>
                  <EditorIcon name='playbackPause' />
                  <div className='editor-editVideoModal-preview-controls-playPauseBtn-tooltip'>
                    <div className='editor-editVideoModal-preview-controls-playPauseBtn-tooltip-keyboard'>
                      Spacebar
                    </div>
                  </div>
                </button>
                )}
                {!isPlaying && (
                <button className='editor-editVideoModal-preview-controls-playPauseBtn editor-editVideoModal-preview-controls-playPauseBtn--paused' onClick={this.playVideo}>
                  <EditorIcon name='playbackPlay' />
                  <div className='editor-editVideoModal-preview-controls-playPauseBtn-tooltip'>
                    <div className='editor-editVideoModal-preview-controls-playPauseBtn-tooltip-keyboard'>
                      Spacebar
                    </div>
                  </div>
                </button>
                )}
              </div>

              <div className='editor-editVideoModal-preview-controls-rightContainer'>
                <div className={`editor-editVideoModal-preview-controls-nudgeBtnContainer ${(isPlaying || playerCurrentTime === 0 || this.state.playerState !== 2) ? 'editor-editVideoModal-preview-controls-nudgeBtnContainer--playing' : 'editor-editVideoModal-preview-controls-nudgeBtnContainer--paused'}`}>
                  <button disabled={isPlaying || playerCurrentTime === 0 || this.state.playerState !== 2} onClick={() => { this.nudgePlayerCurrentTime('back', 0.1) }} className='editor-editVideoModal-preview-controls-nudgeBtn'>
                    <EditorIcon name='nudgeFrameVideoLeft' />
                    <div className='editor-editVideoModal-preview-controls-nudgeBtn-tooltip'>
                      Nudge Back
                    </div>
                  </button>
                  <button disabled={isPlaying || playerCurrentTime === 0 || this.state.playerState !== 2} onClick={() => { this.nudgePlayerCurrentTime('forwards', 0.1) }} className='editor-editVideoModal-preview-controls-nudgeBtn'>
                    <EditorIcon name='nudgeFrameVideoRight' />
                    <div className='editor-editVideoModal-preview-controls-nudgeBtn-tooltip'>
                      Nudge Forward
                    </div>
                  </button>
                </div>
              </div>

              <div className='editor-editVideoModal-preview-controls-trimBarContainer'>
                <ReactSlider
                  className='editor-editVideoModal-preview-controls-trimBar'
                  thumbClassName='editor-editVideoModal-preview-controls-trimBar-thumbContainer'
                  trackClassName='editor-editVideoModal-preview-controls-trimBar-track'
                  min={0}
                  onChange={this.handleRangeSliderChange}
                  onBeforeChange={() => { this.setState({ isDraggingTrimBar: true }) }}
                  onAfterChange={() => { this.setState({ isDraggingTrimBar: false }) }}
                  max={lengthSeconds}
                  defaultValue={this.state.rangeValues}
                  value={this.state.rangeValues}
                  renderThumb={
                      (props) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <div {...props}>
                          <div className='editor-editVideoModal-preview-controls-trimBar-thumb'>
                            <div className='editor-editVideoModal-preview-controls-trimBar-thumb-line' />
                            {/* ALIGN START */}
                            {showStartClipAlign && (
                            <button onClick={() => { this.alignClipBoundToPlayerCurrentTime('clipStart') }} className={`editor-editVideoModal-preview-controls-trimBar-thumb-align editor-editVideoModal-preview-controls-trimBar-thumb-align--start ${showAlignButtons ? 'editor-editVideoModal-preview-controls-trimBar-thumb-align--show' : 'editor-editVideoModal-preview-controls-trimBar-thumb-align--hide'}`}>
                              {startClipAlignDirection === 'right' && <EditorIcon name='openArrowRight' /> }
                              {startClipAlignDirection === 'left' && <EditorIcon name='openArrowLeft' /> }
                            </button>
                            )}

                            {/* ALIGN END */}
                            {showEndClipAlign && (
                            <button onClick={() => { this.alignClipBoundToPlayerCurrentTime('clipEnd') }} className={`editor-editVideoModal-preview-controls-trimBar-thumb-align editor-editVideoModal-preview-controls-trimBar-thumb-align--end ${showAlignButtons ? 'editor-editVideoModal-preview-controls-trimBar-thumb-align--show' : 'editor-editVideoModal-preview-controls-trimBar-thumb-align--hide'}`}>
                              {endClipAlignDirection === 'right' && <EditorIcon name='openArrowRight' /> }
                              {endClipAlignDirection === 'left' && <EditorIcon name='openArrowLeft' /> }
                            </button>
                            )}

                          </div>
                          <div className='editor-editVideoModal-preview-controls-trimBar-thumb editor-editVideoModal-preview-controls-trimBar-thumb--loading'>
                            <div className='editor-editVideoModal-preview-controls-trimBar-thumb-line' />
                          </div>
                        </div>
                      )
}
                  pearling={false}
                  minDistance={1}
                />

                <div className='editor-editVideoModal-preview-controls-trimBarContainer-bgTrack' />
              </div>

              <div className='editor-editVideoModal-preview-controls-previewBarContainer'>
                {/* AT REST AND READY TO PLAY */}
                {previewBarState === 'readyToPlayClip' && (
                <div style={{ left: `${clipStartLeftPercentage * 100}%`, width: `${clipWidthPercentage * 100}%` }} className='editor-editVideoModal-preview-controls-previewBar editor-editVideoModal-preview-controls-previewBar--readyToPlayClip'>
                  <div className='editor-editVideoModal-preview-controls-previewBar-line' />
                  <button onClick={this.togglePlayClipModeActive} className='editor-editVideoModal-preview-controls-previewBar-btn'>
                    <EditorIcon name='playbackPlayClip' />
                    {' '}
                    Play Clip
                  </button>
                </div>
                )}

                {/* PLAYING CLIP - SHOW PROGRESS  */}
                {previewBarState === 'playingClip' && (
                <div style={{ left: `${clipStartLeftPercentage * 100}%`, width: `${clipWidthPercentage * 100}%` }} className='editor-editVideoModal-preview-controls-previewBar editor-editVideoModal-preview-controls-previewBar--playingClip'>
                  <div style={{ width: `${playClipProgressPercentage}%` }} className='editor-editVideoModal-preview-controls-previewBar-playClipProgressBar' />
                  <div className='editor-editVideoModal-preview-controls-previewBar-line' />
                  <button onClick={this.restartClip} className='editor-editVideoModal-preview-controls-previewBar-clipRestartBtn'>
                    <EditorIcon name='playbackRewind' />
                  </button>
                  <button onClick={this.pauseVideo} className='editor-editVideoModal-preview-controls-previewBar-clipProgress'>
                    <div className='editor-editVideoModal-preview-controls-previewBar-clipProgress-icon'>
                      <EditorIcon name='playbackPause' />
                    </div>
                    <div className='editor-editVideoModal-preview-controls-previewBar-clipProgress-label'>
                      {formattedPlayClipProgressTimestamp}
                    </div>
                  </button>
                </div>
                )}

                {/* PLAYING CLIP - SHOW PROGRESS */}
                {previewBarState === 'pausedClip' && (
                <div style={{ left: `${clipStartLeftPercentage * 100}%`, width: `${clipWidthPercentage * 100}%` }} className='editor-editVideoModal-preview-controls-previewBar editor-editVideoModal-preview-controls-previewBar--resumeClip'>
                  <div style={{ width: `${playClipProgressPercentage}%` }} className='editor-editVideoModal-preview-controls-previewBar-playClipProgressBar' />
                  <div className='editor-editVideoModal-preview-controls-previewBar-line' />

                  <button onClick={this.playVideo} className='editor-editVideoModal-preview-controls-previewBar-btn'>
                    Resume Clip
                  </button>

                </div>
                )}

                {/* DRAGGING TRIM BAR HANDLES - SHOW CLIP DURATION */}
                {previewBarState === 'draggingTrimBarHandles' && (
                <div style={{ left: `${clipStartLeftPercentage * 100}%`, width: `${clipWidthPercentage * 100}%` }} className='editor-editVideoModal-preview-controls-previewBar editor-editVideoModal-preview-controls-previewBar--draggingTrimBarHandles'>
                  {playClipModeActive &&
                  <div style={{ width: `${playClipProgressPercentage}%` }} className='editor-editVideoModal-preview-controls-previewBar-playClipProgressBar' />}
                  <div className={`editor-editVideoModal-preview-controls-previewBar-line ${playClipModeActive ? 'editor-editVideoModal-preview-controls-previewBar-line--playingClip' : ''}`} />
                  <div className={`editor-editVideoModal-preview-controls-previewBar-clipDuration ${playClipModeActive ? 'editor-editVideoModal-preview-controls-previewBar-clipDuration--playingClip' : ''}`}>
                    {' '}
                    {/* TOTAL CLIP DURATION */}
                    {playClipModeActive && (
                    <React.Fragment>
                      <span className='editor-editVideoModal-preview-controls-previewBar-clipDuration--playingClip--clipProgress'>
                        {formattedPlayClipProgressTimestamp}
                        {' '}
                        /
                      </span>
                      <span className='editor-editVideoModal-preview-controls-previewBar-clipDuration--playingClip--duration'>
                        {formattedClipDuration}
                      </span>
                    </React.Fragment>
                    )}
                    {!playClipModeActive && (
                    <span>
                      {formattedClipDuration}
                    </span>
                    )}
                  </div>
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EditorEditVideoModal

import React from 'react'
import Autosuggest from 'react-autosuggest'
import PermissionRow from './PermissionRow'
import Dropdown from '../uiKit/Dropdown'
import DropdownSelect from '../uiKit/DropdownSelect'
import DropdownSelectItem from '../uiKit/DropdownSelectItem'
import DropdownDivider from '../uiKit/DropdownDivider'
import DropdownItem from '../uiKit/DropdownItem'
import Button from '../uiKit/Button'
import each from 'lodash/each'
import includes from 'lodash/includes'
import findIndex from 'lodash/findIndex'
import { InstantSearch } from 'react-instantsearch-dom'
import { connectAutoComplete,connectStateResults } from 'react-instantsearch-dom'
import {Configure} from 'react-instantsearch-dom'
import ProfilePic from '../ProfilePic' 
import permissionRoleDescriptions from '../../staticData/permissionRoleDescriptions.js'    
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import algoliasearch from 'algoliasearch/lite'

//Contact list in repo invitation modal uses Algolia

const AutoComplete = connectAutoComplete(connectStateResults(
	({ searchResults,filterSuggestions,hits, refine,currentRefinement,renderSuggestion,getSuggestionValue,renderInputComponent,inputProps,renderSuggestionsContainer,onSuggestionSelected,value,onSuggestionHighlighted }) => {
		let query = ''
		if(searchResults){
			query=searchResults.query
		} 
	  return(
			<Autosuggest
				suggestions={filterSuggestions(hits,query)}
				onSuggestionsFetchRequested={({ value }) => refine(value)}
				onSuggestionsClearRequested={() => refine('')}
				getSuggestionValue={getSuggestionValue}
				renderInputComponent={renderInputComponent}
				onSuggestionSelected={onSuggestionSelected} 
				renderSuggestionsContainer={renderSuggestionsContainer}
				renderSuggestion={renderSuggestion}
				inputProps={inputProps}
				highlightFirstSuggestion={true}
				onSuggestionHighlighted={onSuggestionHighlighted}
			/>
	  )}	
))


const renderInputComponent = inputProps => (
	<input id='contactListSearchBar' autoFocus {...inputProps} className='repoInvitationModal-inviteInput-input'  />
)

function renderSuggestionsContainer({ containerProps , children, query }) {
	if(children){
		return (
			<div {... containerProps} className='repoInvitationModal-inviteInput-suggestionDropdown'>
				{children}
			</div>
		)
	}
}

function renderSuggestion(suggestion) {
	return (
		<div className={'repoInvitationModal-inviteInput-suggestion ' + (suggestion.isInvited ? 'repoInvitationModal-inviteInput-suggestion--alreadyJoined':'')}>
			<div className='repoInvitationModal-inviteInput-suggestion-profilePicContainer'>
				<ProfilePic size='32' borderRadius='3' user={suggestion} />				
			</div>
			<div className='repoInvitationModal-inviteInput-suggestion-name'>
				{suggestion.firstName} {suggestion.lastName} {suggestion.isInvited ? 'Joined':''}
				
			</div>			
			<div className='repoInvitationModal-inviteInput-suggestion-hint'>
				Press <strong> ENTER </strong> to invite
			</div>
		</div>
	)
}


class RepoInvitationModal extends React.Component{
	constructor() {
		super()
		this.onChange=this.onChange.bind(this)
		this.onKeyDown=this.onKeyDown.bind(this)
		this.onSuggestionHighlighted=this.onSuggestionHighlighted.bind(this)
		this.onSuggestionSelected=this.onSuggestionSelected.bind(this)
		this.changePermissionroleNewMember=this.changePermissionroleNewMember.bind(this)
		this.getSuggestionValue=this.getSuggestionValue.bind(this)
		this.inlineValidateEmail=this.inlineValidateEmail.bind(this)
		this.permissionIsCurrentUser=this.permissionIsCurrentUser.bind(this)
		this.updatePermission=this.updatePermission.bind(this)
		this.savePermissionChanges=this.savePermissionChanges.bind(this)
		this.deletePermission=this.deletePermission.bind(this)
		this.saveChanges=this.saveChanges.bind(this)
		this.addInvite=this.addInvite.bind(this)
		this.sendInvite=this.sendInvite.bind(this)
		this.filterSuggestions=this.filterSuggestions.bind(this)
		this.isDuplicateEmail=this.isDuplicateEmail.bind(this)
		this.handleDocumentKeyDown=this.handleDocumentKeyDown.bind(this)
		this.ensurePermissionRowIsVisible=this.ensurePermissionRowIsVisible.bind(this)
		this.updateWindowDimensions=this.updateWindowDimensions.bind(this)
		this.inviteToPlickers=this.inviteToPlickers.bind(this)
		this.handleOnScroll=this.handleOnScroll.bind(this)
		this.handleOnScroll = debounce(this.handleOnScroll,100,{'leading': false })	
		this.state = {
			value: '',
			highlightedSuggestion:null,
			permissionRoleNewMember:'editor',
			nonMemberEmail:null,
			invalidEmailAlert:false,
			emailIsMemberAlert:false,
			emailIsPendingInviteAlert:false,
			permissionsToUpdate:[],
			permissionsToDelete:[],
			failedInviteEmail:null,
			submitLoading:false,
			submitInviting:false,
			windowHeight:window.innerHeight,
			scrollTop:0,
			invitationEmailSentAlert:false
		}  
	}


	UNSAFE_componentWillMount() {
		window.addEventListener('resize', this.updateWindowDimensions)
		document.addEventListener('keydown', this.handleDocumentKeyDown)
		document.body.style.overflowY='hidden' //prevent scrolling while modal is open
	}


	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions)
		document.removeEventListener('keydown', this.handleDocumentKeyDown)
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}

	componentDidMount(){
		if(window.analytics && this.props.repo){
			window.analytics.page('Invite Modal',{repoId:this.props.repo.id})
		}
	}


	updateWindowDimensions() {  
		this.setState({windowHeight:window.innerHeight})
	}


	handleDocumentKeyDown(e){
		if(!this.state.highlightedSuggestion && e.keyCode === 13){
			const contactListSearchBar=document.getElementById('contactListSearchBar')	
			if((contactListSearchBar===document.activeElement)===false){ 
				if(this.state.value==='' && (this.state.permissionsToUpdate.length!==0 || this.state.permissionsToDelete.length!==0 )){
					this.saveChanges()
				}
			}
		}
	}

	getSuggestionValue() {
		return this.state.value
	}

	filterSuggestions(suggestions,query){
		if(query === ''){
			return []
		}else{
			const isValidEmail= this.inlineValidateEmail(query)
			const {permissionsJoined,permissionsPending,currentUserPermission}=this.props
			let filteredSuggestions = []
			if(isValidEmail){ //if query is a valid email only show suggestions contain substring
				filteredSuggestions=filter(suggestions,suggestion => {
		    			return suggestion.email.includes(query)
		  		})
			}else{
				suggestions.map((suggestion)=>{
					const permissionIndex= findIndex(permissionsJoined,permission => {
		    					return permission.user.id === suggestion.id
		  			})
					if(permissionIndex === -1){
						const pendingPermissionIndex= findIndex(permissionsPending,permission => {
		    					if(permission.user){
		    						return permission.user.id === suggestion.id
		    					}else{
		    						return permission.originalEmail === suggestion.email
		    					}
		  				})
						if(pendingPermissionIndex === -1){
							if(currentUserPermission.user.id !== suggestion.id){
								filteredSuggestions.push(suggestion)
							}
						}
					}
					return null
				})
			}
			return filteredSuggestions
		}
	}

	permissionIsCurrentUser(permission){
		if(permission.user && this.props.currentUser && (permission.user.id===this.props.currentUser.id)){
			return true
		}
		return false
	}

	isDuplicateEmail(email){
		const {permissionsJoined,permissionsPending,currentUserPermission}=this.props
		const permissionIndex= findIndex(permissionsJoined,permission => {
    		return permission.user.email === email
  		})
		
		if(permissionIndex !== -1){
			this.setState({emailIsMemberAlert:true})
			return true
		}else{
			const pendingPermissionIndex= findIndex(permissionsPending,permission => {
    				if(permission.user){
    					return permission.user.email === email
    				}else{
    				return permission.originalEmail === email
    				}
  				})
			if(pendingPermissionIndex !== -1){
				this.setState({emailIsPendingInviteAlert:true})
				return true
			}else if(currentUserPermission.user.email === email){
				this.setState({emailIsMemberAlert:true})
				return true
			}else {
				return false
			}
		}
	}
	
	validateEmail(email){
		if(this.isDuplicateEmail(email)){
			return false
		}	
		return true		
	}

	inlineValidateEmail(email){
		if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9.-]/i.test(email)){	
			return false
		}else return true
		
	}

	changePermissionroleNewMember(role){
		this.setState({permissionRoleNewMember:role})
	}

	updatePermission(permission,newRole){
		const newPermission={...permission,role:newRole}
		if(permission.joined){
			let newPermissionsToUpdateArray=this.state.permissionsToUpdate
			newPermissionsToUpdateArray.push(newPermission)
			this.setState({permissionsToUpdate:newPermissionsToUpdateArray})
		}else{
			const {originalEmail,role,user}=newPermission
			let requestData
			if(user){
				requestData={
  					user:user.id,
 					role:role
				}
			}else{
				requestData={
  					originalEmail:originalEmail,
 			 		role:role
				}	
			}
			this.props.updatePermission(requestData,this.props.repo.id).then(()=>{
				// this.props.showNotification('permission','Updated','create')
			})
		}
	}

	deletePermission(permission){
		if(permission.joined){
			let newPermissionsToDeleteArray=this.state.permissionsToDelete
			newPermissionsToDeleteArray.push(permission)
			this.setState({permissionsToDelete:newPermissionsToDeleteArray})
		}else{
			const {role}=permission
			let requestData
			if(permission.user){
				requestData={
  					user:permission.user.id,
 					role:role
				}	
			}else{
				requestData={
  				originalEmail:permission.originalEmail,
 				role:role
				}	
			}
			
			this.props.deletePermission(this.props.repo.id,requestData).then(()=>{
				//this.props.showNotification('permission','Deleted','create')
			})
		}
	}


	onChange(event, { newValue, method }){
		if(!this.state.submitInviting){
			this.setState({
				value: newValue,
				invalidEmailAlert:false,
				emailIsMemberAlert:false,
				emailIsPendingInviteAlert:false,
			})
		}
	}
	
	onSuggestionHighlighted({suggestion}){
		this.setState({highlightedSuggestion:suggestion})
	}

	onSuggestionSelected(event, { suggestion}) {
		if(!suggestion.isInvited){
			this.sendInvite({originalEmail:suggestion.email,role:this.state.permissionRoleNewMember,userId:suggestion.id,user:suggestion})
		}
	}

	savePermissionChanges(){
		const {permissionsToUpdate}=this.state
		var promises = []
		each(permissionsToUpdate, function(permission) {
			const {originalEmail,role,user}=permission
			let requestData
			if(user){
				requestData={
  					user:user.id,
 					role:role
				}
			}else{
				requestData={
  					originalEmail:originalEmail,
 			 		role:role
				}	
			}		
			promises.push(this.props.updatePermission(requestData,this.props.repo.id))
		}.bind(this))

		return Promise.all(promises).then(function (responses) {
			//this.props.closeInvitationModal()
			return null
		})
	}

	saveChanges(){
		const {permissionsToDelete}=this.state
		this.setState({submitLoading:true})
		var promises = []
		each(permissionsToDelete, function(permission) {
			const {role}=permission
			let requestData
			if(permission.user){
				requestData={
  				user:permission.user.id,
 				role:role
				}	
			}else{
				requestData={
  				originalEmail:permission.originalEmail,
 				role:role
				}	
			}
			promises.push(this.props.deletePermission(this.props.repo.id,requestData))
		}.bind(this))

		Promise.all(promises).then(function (responses) {
			return this.savePermissionChanges().then(()=>{
				this.props.closeInvitationModal()
				this.props.showNotification(`member changes to ${this.props.repo.name}`,'Saved','create')
			})
		}.bind(this))
	}


	addInvite(){
		const permissionToCreate={originalEmail:this.state.value,role:this.state.permissionRoleNewMember}
		this.sendInvite(permissionToCreate)
	}

	sendInvite(permissionToCreate){
		this.setState({failedInviteEmail:null,submitInviting:true,invitationEmailSentAlert:false})
		var promise 
		if(permissionToCreate.userId){
			promise=Promise.resolve(this.props.inviteByUser(permissionToCreate.userId,permissionToCreate.role))
		}else{
			promise=Promise.resolve(this.props.inviteByEmail(permissionToCreate.originalEmail,permissionToCreate.role))
		}

		promise.then(function (response) {			
			if(response.error && response.error.response.status===422){
				this.setState({failedInviteEmail:response.email})
			}else{
				this.ensurePermissionRowIsVisible(response)
			}	
			this.setState({value:'',submitInviting:false})
			return null
		}.bind(this))
	} 

	onKeyDown(e){
		if(!this.state.highlightedSuggestion){
			if(e.keyCode === 13 && !this.inlineValidateEmail(this.state.value)  && this.state.value!==''){
				this.setState({invalidEmailAlert:true})
			}else if(e.keyCode === 13 && this.state.value !=='' && this.inlineValidateEmail(this.state.value) && this.validateEmail(this.state.value)){
				this.sendInvite({originalEmail:this.state.value,role:this.state.permissionRoleNewMember})
			}else if(e.keyCode === 13 && this.state.value==='' && (this.state.permissionsToUpdate.length!==0 || this.state.permissionsToDelete.length!==0 )){
				this.saveChanges()
			}else if(e.keyCode === 13 && this.state.value==='' && this.state.permissionsToUpdate.length ===0 && this.state.permissionsToDelete.length===0 ){
				this.props.closeInvitationModal()
			}
		}
	}

	ensurePermissionRowIsVisible(permission) {
		var objDiv = document.getElementById('scrollable_list')
		if(objDiv){
			objDiv.scrollTop = objDiv.scrollHeight
		}
		
	}

	inviteToPlickers(email){
		this.props.inviteToPlickers(email).then(()=>{
			 this.setState({failedInviteEmail:null,invitationEmailSentAlert:true})

		})
	}

	handleOnScroll(){
		const el=document.getElementById('scrollable_list')
		if(el){
			this.setState({scrollTop:el.scrollTop})
		}
	}

	render(){	
		const { value,permissionsToUpdate,permissionsToDelete,failedInviteEmail,submitInviting,emailIsMemberAlert,emailIsPendingInviteAlert,windowHeight,scrollTop,invitationEmailSentAlert} = this.state
		const {repo,currentUserPermission, permissionsJoined,permissionsPending}=this.props

		const isValidEmail = this.inlineValidateEmail(value)

		let primaryButtonLabel
		let showChangesToSaveAlert = false
		let preventBackgroundClick = false

		
		if(permissionsToUpdate.length!==0 || permissionsToDelete.length!==0){
			primaryButtonLabel = 'Save Changes'
			showChangesToSaveAlert = true
			preventBackgroundClick = true
		}
		
		
		const inputProps = {
			placeholder: 'Enter name or email address',
			value,
			onChange: this.onChange,
			onKeyDown:this.onKeyDown
		}

		const searchClient = algoliasearch(
			process.env.REACT_APP_PLICKERS_ALGOLIA_APP_ID,
			this.props.searchKey
		)

		return (
			<div className='repoInvitationModalContainer' >	
				<div className='repoInvitationModal' >
					<div className='repoInvitationModal-header'>
						<div className='repoInvitationModal-header-label'>
							Invite
						</div>
						<div className='repoInvitationModal-header-repoName'>
							{repo.name}
						</div>

						<div className='repoInvitationModal-header-changePermissionNewMemberContainer'>								
							<Dropdown>
								<DropdownSelect label='Default invitation role'>
									<DropdownSelectItem label='Invited members can edit' sublabel={permissionRoleDescriptions.editor} multiline multilineHeight='3' isActive={this.state.permissionRoleNewMember==='editor'} onSelect={()=>{this.changePermissionroleNewMember('editor')}} />
									<DropdownSelectItem label='Invited members can view' sublabel={permissionRoleDescriptions.viewer} multiline multilineHeight='2' isActive={this.state.permissionRoleNewMember==='viewer'} onSelect={()=>{this.changePermissionroleNewMember('viewer')}}/>							
								</DropdownSelect>
								<DropdownDivider/>
								<DropdownItem label='Pack Invitation Help' onSelect={()=>{window.open('https://help.plickers.com/hc/en-us/articles/360025595294','_blank')}} />
							</Dropdown>			
						</div>
	
					
						<div className='repoInvitationModal-header-inviteInput'>
							{this.props.searchKey &&
							<InstantSearch
								searchClient={searchClient}
								indexName={'users'}
							>
								<Configure hitsPerPage={6}/>
								<AutoComplete 
									filterSuggestions={this.filterSuggestions}
									renderSuggestion={renderSuggestion}
									getSuggestionValue={this.getSuggestionValue}
									renderInputComponent={renderInputComponent}
									inputProps={inputProps}
									renderSuggestionsContainer={renderSuggestionsContainer}
									onSuggestionSelected={this.onSuggestionSelected}
									value={this.state.value}
									onSuggestionHighlighted={this.onSuggestionHighlighted}
								/>
							</InstantSearch>
							}


	
							<div className={'repoInvitationModal-header-inviteInput-hint ' + ((isValidEmail && !emailIsMemberAlert && !emailIsPendingInviteAlert) ? 'repoInvitationModal-header-inviteInput-hint--show' : 'repoInvitationModal-header-inviteInput-hint--hide')}>
								Press <strong>ENTER</strong> to invite
							</div>

							{submitInviting &&
								<div className='repoInvitationModal-header-inviteInput-submitInvitingContainer'>
									<div className='repoInvitationModal-header-inviteInput-submitInviting' />
								</div>
							}					
												
						</div>
								
					</div>	 
					<div className='repoInvitationModal-membersList' id='scrollable_list' onScroll={this.handleOnScroll}>
				
						{currentUserPermission && 
							<PermissionRow 
								key={`currentUser_${windowHeight}_${scrollTop}_${currentUserPermission.role}`}
								repo={repo}
								isCurrentUser={true} 
								currentUserPermissionRole={currentUserPermission.role}
								permission={currentUserPermission}
								updatePermission={this.updatePermission}
								deletePermission={this.deletePermission}
							/>
						}



						{permissionsJoined && permissionsJoined.map((permission,i)=>{ 
							if(!includes(permissionsToDelete,permission)){
								return(
									<PermissionRow 
										key={`joined_${i}_${windowHeight}_${scrollTop}_${permission.role}`}
										repo={repo}
										isCurrentUser={false} 
										currentUserPermissionRole={currentUserPermission.role}
										permission={permission}
										updatePermission={this.updatePermission}
										deletePermission={this.deletePermission}
										invertedDropdown={true}
									/>
								)}else return null
						})}

						{permissionsPending && permissionsPending.length !== 0 &&
							<div className='repoInvitationModal-membersList-pendingDivider'>
								Invited
							</div>
						}

						{permissionsPending && permissionsPending.map((permission,i)=>{ 
							if(!includes(permissionsToDelete,permission)){
								return(
									<PermissionRow 
										key={`joined_${i}_${permissionsToDelete.length}_${windowHeight}_${scrollTop}_${permission.role}`}
										repo={repo}
										currentUserPermissionRole={currentUserPermission.role}
										isCurrentUser={false} 
										permission={permission}
										updatePermission={this.updatePermission}
										deletePermission={this.deletePermission}
										invertedDropdown={true}
									/>
								)}else return null
						})}										
					
					</div>	

					<div className='repoInvitationModal-footer'>	

						{(permissionsToUpdate.length!==0 || permissionsToDelete.length!==0 )&&
							<React.Fragment>
								<Button name='primaryButton' disabled={this.state.submitLoading} submitLoading={this.state.submitLoading} size='xLarge' label={primaryButtonLabel} color='blue' onClickFunction={this.saveChanges} />
								<div className='repoInvitationModal-footer-cancelBtn' onClick={this.props.closeInvitationModal}>
									Cancel
								</div>
							</React.Fragment>
						}

						{ permissionsToUpdate.length===0 && permissionsToDelete.length===0 &&
							<React.Fragment>
								<Button name='primaryButton' size='xLarge' label='Done' color='blue' onClickFunction={this.props.closeInvitationModal} />			
							</React.Fragment>
						}

						{(showChangesToSaveAlert || failedInviteEmail || this.state.invalidEmailAlert || emailIsPendingInviteAlert || emailIsMemberAlert || invitationEmailSentAlert) &&
							<div className='repoInvitationModal-footer-alerts'>								

								{failedInviteEmail &&
									<div className='repoInvitationModal-footer-alerts-alert repoInvitationModal-footer-alerts-alert--inviteToPlickers'>
										{failedInviteEmail} doesn't have a Plickers account.&nbsp;
										<span className='repoInvitationModal-footer-alerts-alert--inviteToPlickers--sendInvite' onClick={()=>{this.inviteToPlickers(failedInviteEmail)}}>Send invitation to join Plickers</span>
									</div>
								}

								{invitationEmailSentAlert &&
									<div className='repoInvitationModal-footer-alerts-alert repoInvitationModal-footer-alerts-alert--inviteToPlickers'>
										Invitation email sent.&nbsp;
										<span className='repoInvitationModal-footer-alerts-alert--inviteToPlickers--sendInvite' onClick={()=>{this.setState({invitationEmailSentAlert:false})}}>Dismiss</span>
									</div>
								}

								{showChangesToSaveAlert &&
									<div className='repoInvitationModal-footer-alerts-alert repoInvitationModal-footer-alerts-alert--changesToSave'>
										You have made changes that you need to save
									</div>
								}

								{this.state.invalidEmailAlert && this.state.value !=='' &&
									<div className='repoInvitationModal-footer-alerts-alert repoInvitationModal-footer-alerts-alert--changesToSave'>
										Ooops – that doesn't look like a valid email
									</div>
								}

								{emailIsPendingInviteAlert &&
									<div className='repoInvitationModal-footer-alerts-alert repoInvitationModal-footer-alerts-alert--changesToSave'>
										Looks like that person is already invited!
									</div>
								}

								{emailIsMemberAlert &&
									<div className='repoInvitationModal-footer-alerts-alert repoInvitationModal-footer-alerts-alert--changesToSave'>
										Looks like that person is already a member!
									</div>
								}

							</div>
						}
					</div>
				</div>
				
				<div className='repoInvitationModalContainer--BG' onClick={() =>{
					if(!preventBackgroundClick){
						this.props.closeInvitationModal()
					}
				}} />

			</div>
		)
	
	}
}

export default RepoInvitationModal
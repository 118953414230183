import React from 'react'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'

class StudentCard extends React.Component{

	render(){
	    return( 
	      <div className="choiceDetailBlock-studentCard notranslate" key={this.props.index} > 	      
	      	
	      	{(this.props.showStudentCardNumbers || this.props.student.displayName==='Guest') &&
		      	<div className='choiceDetailBlock-studentCard-cardNumber'>
		      		{this.props.student.card===-1?'–':this.props.student.card}
		      	</div>
	      	}
	      	{this.props.student.displayName}
	      </div>
	    )
	}
}

class ChoiceDetailBlock extends React.Component{


	render(){	
		const {body, index, invalid, missing, isCorrect, isSurvey, studentsforChoice, responseCount, totalResponses, showStudentResponses, showStudentCardNumbers} = this.props
		const responseLetters=['A','B','C','D']
		let percentage

		if(totalResponses && totalResponses > 0){
			percentage = responseCount / totalResponses * 100
		}
		else{
			percentage = 0
		}

		var responseBar = {
     	 width: `${percentage}%`,
		}

		let students=filter(studentsforChoice,function(student) { 
			return student.displayName !=='Guest'
		})


		let guests=filter(studentsforChoice,function(student) { 
			return student.displayName ==='Guest'
		})
		

		const sortedStudents=sortBy(students, function(student) { 
  			return student.displayName.toLowerCase()
		})
		
		
		let sortedResponses=sortedStudents.concat(guests)



		return(
			<div className={'choiceDetailBlock notranslate' + (!missing && !invalid ? ' choiceDetailBlock--standardChoice' : ' choiceDetailBlock--specialChoice') + (isCorrect ? ' is--correct ' : ' is--incorrect ') + (isSurvey ? ' is--survey ' : ' ') + (totalResponses ? ' is--report ' : ' is--detail ') + (percentage === 0 ? ' is--zeroPercent ' : '')}>
				{!missing && !invalid &&
					<div className="choiceDetailBlock-letter notranslate">
						{responseLetters[`${index}`]}						
					</div>
				}

				<div className="choiceDetailBlock-responseCount">						
					{responseCount}		
				</div>

				<div className="choiceDetailBlock-content">
					
					<div className="choiceDetailBlock-title notranslate">
						{body}
					</div>

					{totalResponses && !missing && !invalid &&
						<React.Fragment>
							<div className="choiceDetailBlock-track" />
							<div style={responseBar} className={'choiceDetailBlock-bar ' + (percentage === 100 ? ' is--hundredPercent ' : '') + (percentage === 0 ? ' is--zeroPercent ' : '')} />
						</React.Fragment>
					}
					
					{sortedResponses && showStudentResponses &&
						<div className="choiceDetailBlock-studentList">								
							{sortedResponses.map((student,index) => (								
									<StudentCard
										key={index}
										student={student}
										showStudentCardNumbers={showStudentCardNumbers}
									/>
								))}							
						</div>
					}

				</div>
			</div>
		)
	}
				
}





export default ChoiceDetailBlock



import React, { Component } from 'react'

function getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}


class RepoReleaseModalVisualPageTableCell extends Component {


	constructor(){
		super()		

		this.state = {
			centerBlobWidth: getRandomInt(92,132),
			rightBlobWidth:getRandomInt(62,72)
			
		}
	}


	render() {


		return ( 
			<div className='repoReleaseModal-visual-pageTable-cell' >
				<div className='repoReleaseModal-visual-pageTable-cell-leftBlob' />
				<div className='repoReleaseModal-visual-pageTable-cell-center'>	
					<div style={{width: `${this.state.centerBlobWidth}px`}}  className='repoReleaseModal-visual-pageTable-cell-center-centerBlob' />	
				</div>
				<div className='repoReleaseModal-visual-pageTable-cell-right'>	
					<div style={{width: `${this.state.rightBlobWidth}px`}} className='repoReleaseModal-visual-pageTable-cell-right-rightBlob' /> 	
				</div>

			</div>	
		)
	}
}


class RepoReleaseModalVisualPageTable extends Component {


	

	render() {


		return ( 
			<div className='repoReleaseModal-visual-pageTable' >
				<RepoReleaseModalVisualPageTableCell />
				<RepoReleaseModalVisualPageTableCell />
				<RepoReleaseModalVisualPageTableCell />
				<RepoReleaseModalVisualPageTableCell />
				<RepoReleaseModalVisualPageTableCell />
				<RepoReleaseModalVisualPageTableCell />
				<RepoReleaseModalVisualPageTableCell />
				<RepoReleaseModalVisualPageTableCell />
				<RepoReleaseModalVisualPageTableCell />
			</div>	
		)
	}
}


export default RepoReleaseModalVisualPageTable




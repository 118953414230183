import findIndex from 'lodash/findIndex'
 

export default function (state=[], action) {

	switch(action.type) {
		
	case 'FETCH_FOLDERS_SUCCESS':
	case 'FETCH_REPO_FOLDERS_SUCCESS':
		let newState = [...state]
		action.response.forEach(folder => {
			const folderId = folder.id
			let indexOfFolder = newState.findIndex(folder => {
				return folder.id === folderId
			})
	
			if(indexOfFolder === -1){
				indexOfFolder=newState.length
			}
			newState= [
				...newState.slice(0,indexOfFolder),
				folder,
				...newState.slice(indexOfFolder + 1)
			]	
		})
		return newState


	case 'CREATE_FOLDER_SUCCESS':
	case 'FOLDER_ADDED_PUSHER':
		if(state){
			const addedFolderId = action.folder.id
			var indexOfFolder = findIndex(state,folder => {
				return folder.id === addedFolderId
			})
			if(indexOfFolder === -1){
				indexOfFolder=state.length
			}
	
			return [
				...state.slice(0,indexOfFolder),
				action.folder,
				...state.slice(indexOfFolder + 1)
			]

		}
		else{
			const newFolder=action.folder
			return [newFolder]
		}



	case 'UPDATE_FOLDER_SUCCESS':
	case 'UPDATE_FOLDER_FROM_PUSHER':
		const updatedFolderId = action.response.id
		var indexOfFolderToUpdate = findIndex(state,(folder => {
			return folder.id === updatedFolderId
		}))
		if(indexOfFolderToUpdate !== -1 ){
			if(action.response.archived===false){
				return [
					...state.slice(0,indexOfFolderToUpdate),
					action.response,
					...state.slice(indexOfFolderToUpdate + 1)
				]
			}else {
				return [
					...state.slice(0,indexOfFolderToUpdate),
					...state.slice(indexOfFolderToUpdate + 1)
				]
			}}
		else {
			if(action.response.archived===false){
				return [...state,action.response]
			}else return state
		}



	case 'DELETE_FOLDER_SUCCESS':
		const deletedFolderId = action.folderId
		const indexOfFolderToDelete = state.findIndex(folder => {
			return folder.id === deletedFolderId
		})
		if(indexOfFolderToDelete !==-1){	
			return [
				...state.slice(0,indexOfFolderToDelete),
				...state.slice(indexOfFolderToDelete + 1)
			]
		}else return state


	case 'FOLDER_REMOVED_PUSHER':
		const removedFolderId = action.folderId
		const removedFromRepoId=action.repoId
		const indexOfFolderToRemove = state.findIndex(folder => {
			return (folder.id === removedFolderId && folder.repo===removedFromRepoId)
		})
		if(indexOfFolderToRemove !==-1){	
			return [
				...state.slice(0,indexOfFolderToRemove),
				...state.slice(indexOfFolderToRemove + 1)
			]
		}else return state




	case 'LOGOUT':
		return []

	default:
		return state

	}
}


import sampleQuestion from './sampleQuestion.js'

export default function modifySampleQuestion(questionBodyLength,choiceACharacterCount,choiceBCharacterCount,choiceCCharacterCount,choiceDCharacterCount){
  let question={...sampleQuestion}
  const bodyText = question.body.slice(0,questionBodyLength)
  question.body = bodyText
  question.bodyHtml = bodyText
  let choices=[]

  question.choices.forEach((choice,i)=>{
   let choiceText=choice.body
    if(i===0){
      choiceText=question.choices[0].body.slice(0,choiceACharacterCount)
    }
    else if(i===1){
       choiceText=question.choices[1].body.slice(0,choiceBCharacterCount)
    }
    else if(i===2){
       choiceText=question.choices[2].body.slice(0,choiceCCharacterCount)
    }
     else if(i===3){
       choiceText=question.choices[3].body.slice(0,choiceDCharacterCount)
    }
    let newChoice={
      correct: false,
      body:choiceText,
      bodyHtml:choiceText
    }  
    choices.push(newChoice)
  })
  question.choices=choices
  return question
}



export default function (state = {}, action) {
	switch (action.type) {

	case 'FETCH_CONTROL_SUCCESS':
		return action.response
	case 'CONTROL_RECEIVED':
		if(action.controlObject.controlled>state.controlled || action.controlObject.mobileAccepted){
			return action.controlObject
		}else{
			return state
		}
	case 'UPDATE_CONTROL_REQUEST':
		return action.requestData

	case 'UPDATE_CONTROL_SUCCESS':
		return action.response
	
	case 'LOGOUT':
		return {}
	
	default:
		return state
	}
}

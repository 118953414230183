export default function (state={}, action) {

	switch (action.type) {

	case 'FETCH_DISCOVER_LATEST_REPOS_SUCCESS':
		state[action.subject]=action.repos
		return state


	case 'LOGOUT':
		return {}

	default:
		return state

	}
}
import React from 'react'

class StudentReportPackStudentHeader extends React.Component {
  render() {
    const {
      personalizedContentLabel, studentName, studentTotals, totalPollCount, dateRangeLabel,
    } = this.props
    let totalCorrect = 0
    let totalResponses = 0
    let totalSurveys = 0
    let totalMissed = 0
    let totalIncorrect = 0
    if (studentTotals) {
      totalCorrect = studentTotals.totalCorrect
      totalResponses = studentTotals.totalResponses
      totalSurveys = studentTotals.totalSurveys
      totalMissed = totalPollCount - (totalResponses + totalSurveys)
      totalIncorrect = totalResponses - totalCorrect
    }

    let score = 0
    if (totalResponses !== 0) {
      score = Math.round((totalCorrect / totalResponses) * 100)
    }

    let showStudentTotal = true

    if (totalCorrect + totalIncorrect === 0) {
      showStudentTotal = false
    }

    let scoreRange // for styling the SVG

    if (score >= 85) {
      scoreRange = 'o85'
    } else if (score >= 70) {
      scoreRange = 'o70'
    } else if (score >= 60) {
      scoreRange = 'o60'
    } else if (score >= 0) {
      scoreRange = 'u60'
    }

    return (
      <div className="printPack--studentReport-studentReportHeader">
        <div className="printPack--studentReport-studentReportHeader-left">
          <div className="printPack--studentReport-studentReportHeader-left-studentName notranslate">
            {studentName}
          </div>
          <div className="printPack--studentReport-studentReportHeader-left-dateRangeLabel">
            {dateRangeLabel}
          </div>

          <div className="printPack--studentReport-studentReportHeader-left-personalizedContentLabel">
            {personalizedContentLabel}
          </div>

        </div>

        {showStudentTotal && (
        <div className="printPack--studentReport-studentReportHeader-right">

          <div className={`printPack--studentReport-studentReportHeader-right-score ${scoreRange ? ` printPack--studentReport-studentReportHeader-right-score--score-${scoreRange} ` : ''}`}>

            <div className="printPack--studentReport-studentReportHeader-right-score-scoreCircleSVG">
              <svg width="40" height="40" viewBox="0 0 40 40">
                <circle cx="24" cy="24" r="6" />
              </svg>
            </div>

            <div className="printPack--studentReport-studentReportHeader-right-score-score">
              {score}
            </div>

            <div className="printPack--studentReport-studentReportHeader-right-score-percent">
              %
            </div>

          </div>

          <div className="printPack--studentReport-studentReportHeader-right-scoreBreakdown">
            {totalResponses !== 0 && (
            <span>
              {totalCorrect}
              {' '}
              correct ·
              {totalIncorrect}
              {' '}
              incorrect ·
              {totalMissed}
              {' '}
              missed
            </span>
            )}

          </div>
        </div>
        )}
      </div>

    )
  }
}

export default StudentReportPackStudentHeader

import React from 'react'
import Icon from '../../../misc/Icons'
import QuestionSlideCarousel from '../../../uiKit/QuestionSlideCarousel'
import Button from '../../../uiKit/Button'
import { Link } from 'react-router-dom'
import {formatDate} from '../../../../utils/formatDate'
import {formatDateConsumerView} from '../../../../utils/formatDateConsumerView'
import {ContextMenuTrigger } from 'react-contextmenu'
import {getQuestionBodyLabel} from '../../../../utils/getQuestionBodyLabel'
import {openItemInEditor} from '../../../../utils/openItemInEditor'
import {getRepoforId} from '../../../../utils/getRepoforId'
import {getUserForId} from '../../../../utils/getUserForId'
import {getDisplayNameForUser} from '../../../../utils/getDisplayNameForUser'
import {getLinkForRepo} from '../../../../utils/getLinkForRepo'

const SLIDE_WIDTH=200 

class SectionHomeDnDQueueCard extends React.PureComponent{


	collect(props) {
		return { queuePoll: props.queuePoll,index:props.index, isSet:props.isSet,item:props.item}
	}

	render(){
		const {queuePoll, item, isSet,contextMenuOpen}=this.props

		const{index,y,originalPosOfLastPressed,scale,handleMouseDown}=this.props
		const fixedCardHeight = 147     
		const styleheight = {
			height: fixedCardHeight
		}
          
		const styledrag = {                   
			transform: `translate3d(0px, ${y}px, 0) scale(${scale})`, 
			zIndex: index === originalPosOfLastPressed ? 99 : index,
			'position':'absolute', 
		} 

                    

		const itemTitle=isSet ? item.name:getQuestionBodyLabel(item)

		let containingRepo = null
		let containingRepoName = null
		let isViewOnlyRepo = false
		let inSharedRepo = false
		let lastEditedBy
		let isPublicConsumer = false 

		const containingRepoID = item.repo
		if(containingRepoID){
			containingRepo = getRepoforId(item.repo)
			isViewOnlyRepo = true

			if(containingRepo){
				inSharedRepo = containingRepo.shared
				if(inSharedRepo){
					const lastEditedUser = getUserForId(item.lastEditedBy,item.repo)
					lastEditedBy=getDisplayNameForUser(lastEditedUser)
				}

				containingRepoName=containingRepo.name
				if(containingRepo.role === 'consumer'){
					isPublicConsumer = true
				}
				if(containingRepo.role !=='viewer' && containingRepo.role !=='consumer'){
					isViewOnlyRepo = false
				}
			}
		}

	
	

		return(
			<ContextMenuTrigger 
          		id='SECTION_QUEUE'
          		queuePoll={queuePoll}
          		isSet={isSet}
          		item={item}
          		index={index}
          		holdToDisplay={1000}
          		collect={this.collect}
          		disabled={true}
          		isViewOnlyRepo={isViewOnlyRepo}
        	>

				<div key={index} className={'sectionHome-upcoming-upcomingCard' + (contextMenuOpen ? ' sectionHome-upcoming-upcomingCard--contextOpen' : '') + (this.props.cardIsPressed ? ' sectionHome-upcoming-upcomingCard--isPressed':'')}  style={{...styledrag, ...styleheight}} id={`DnD_Card_${queuePoll.id}`}>				

					{isSet &&
						<QuestionSlideCarousel set={item} showSetNavigator slideWidth={SLIDE_WIDTH} play={()=>{this.props.playPoll(queuePoll,item)}}/>			
					}

					{!isSet &&
						<QuestionSlideCarousel question={item} slideWidth={SLIDE_WIDTH} play={()=>{this.props.playPoll(queuePoll,item)}} />
					}				
						
					<div className='sectionHome-upcoming-upcomingCard-rightContentContainer'>
						<div className='sectionHome-upcoming-upcomingCard-title'>
							{itemTitle} {inSharedRepo && <Icon name='shared'/>} 
						</div>

						<div className='sectionHome-upcoming-upcomingCard-subline'>
							
							{containingRepoName &&
								<Link to={getLinkForRepo(containingRepo)} className='sectionHome-upcoming-upcomingCard-subline-containingRepo'>
									{isPublicConsumer && <Icon name='globe-shared'/>} {containingRepoName}
								</Link>
							}
							{!isPublicConsumer &&
								<div className='sectionHome-upcoming-upcomingCard-subline-timestamp'>							
									{lastEditedBy?`${lastEditedBy} edited` :'Edited'} {formatDate(item.clientModified)}
								</div>
							}							
							{isPublicConsumer &&
								<div className='sectionHome-upcoming-upcomingCard-subline-timestamp'>							
									Edited {formatDateConsumerView(item.clientModified)}
								</div>
							}
						</div>

						<div className='sectionHome-upcoming-upcomingCard-actionRow'>
							<Button label='Play Now' disabled={queuePoll.id?false:true} onClickFunction={()=>{this.props.playPoll(queuePoll,item)}} />							
							<Button label={(!isViewOnlyRepo ? 'Edit ' : 'View ') + (isSet ? 'Set' : 'Question')} onClickFunction={()=>{openItemInEditor(item.id,isSet)}} />							
						</div>
					
					</div> 

					<div className="sectionHome-upcoming-upcomingCard-dragHandleContainer" onMouseDown={handleMouseDown.bind(null, index,y)}>
						<div className="sectionHome-upcoming-upcomingCard-dragHandle">
							<div className="sectionHome-upcoming-upcomingCard-dragHandle-dotGrid">
								<div className="sectionHome-upcoming-upcomingCard-dragHandle-dot"/>
								<div className="sectionHome-upcoming-upcomingCard-dragHandle-dot"/>
								<div className="sectionHome-upcoming-upcomingCard-dragHandle-dot"/>
								<div className="sectionHome-upcoming-upcomingCard-dragHandle-dot"/>
								<div className="sectionHome-upcoming-upcomingCard-dragHandle-dot"/>
								<div className="sectionHome-upcoming-upcomingCard-dragHandle-dot"/>
								<div className="sectionHome-upcoming-upcomingCard-dragHandle-dot"/>
								<div className="sectionHome-upcoming-upcomingCard-dragHandle-dot"/>
								<div className="sectionHome-upcoming-upcomingCard-dragHandle-dot"/>
								<div className="sectionHome-upcoming-upcomingCard-dragHandle-dot"/>						
							</div>
						</div>
						<div className="sectionHome-upcoming-upcomingCard-dragHandle-tooltip">
							<span className='sectionHome-upcoming-upcomingCard-dragHandle-tooltip--labelDefault'>
								Click and hold to pick up and reorder
							</span>						
							<span className='sectionHome-upcoming-upcomingCard-dragHandle-tooltip--labelMoveUpNext'>
								Drop here to Move to Up Next
							</span>
						</div>
					</div>

					<div className="sectionHome-upcoming-upcomingCard-removeBtn" onClick={()=>{this.props.removeFromQueue(queuePoll)}}>
						<Icon name="close-small"/>
					</div>				

					<div className='sectionHome-upcoming-upcomingCard-background' />
					
				</div>
				
			</ContextMenuTrigger>	


		)
	
	}
}


export default SectionHomeDnDQueueCard



import React from 'react'

class SyncLiveViewToolbarScanStopwatch extends React.Component{
	
	constructor(){
		super()		
		this.startTimer=this.startTimer.bind(this)
		this.stopTimer=this.stopTimer.bind(this)
		this.resetTimer=this.resetTimer.bind(this)
		this.state={    	 
			timerOn: false,
			timerStart: 0,
			timerTime: 0
		}
	}

	componentDidMount(){
		this.startTimer()
	}

	componentWillUnmount(){
		this.stopTimer()
	}
	
	startTimer(){
		this.setState({
			timerOn: true,
			timerTime: this.state.timerTime,
			timerStart: Date.now() - this.state.timerTime
		});
		
		this.timer = setInterval(() => {
			this.setState({
				timerTime: Date.now() - this.state.timerStart
			});
		}, 10);
	}

	stopTimer(){
		this.setState({ timerOn: false });
		clearInterval(this.timer);
	}

	resetTimer(){
		this.setState({
			timerStart: 0,
			timerTime: 0
		})
	}

	render(){				
		const { timerTime } = this.state
		let centiseconds = ("0" + (Math.floor(timerTime / 10) % 100)).slice(-2)
		let seconds = ("0" + (Math.floor(timerTime / 1000) % 60)).slice(-2)
		let minutes = ("0" + (Math.floor(timerTime / 60000) % 60)).slice(-2)
		return(				
			<div onClick={this.startTimer} className='syncLiveView-toolbar-center-scanStopWatch'>				
				<span className={'syncLiveView-toolbar-center-scanStopWatch-minutes ' + (minutes > 0 ? ' syncLiveView-toolbar-center-scanStopWatch-minutes--nonZeroMinutes ':' syncLiveView-toolbar-center-scanStopWatch-minutes--zeroMinutes ')}>
					{minutes}:
				</span>			
				<span className='syncLiveView-toolbar-center-scanStopWatch-seconds'>
					{seconds}
				</span>			
				<span className='syncLiveView-toolbar-center-scanStopWatch-centiseconds'>
				.{centiseconds}
				</span>
			</div>
		)
	}
}

export default SyncLiveViewToolbarScanStopwatch 
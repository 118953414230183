import {Plugin, PluginKey} from 'prosemirror-state'

//Delete friendly fraction if press delete key in empty numerator (and denominator is empty)
const key = new PluginKey('friendlyFractions')

function getAnchorNode($from) {
	let anchorNode
	for (let i = $from.depth; i > 0; i--) {
		let node = $from.node(i)
		if (node.type.name === "friendlyFractionNumerator") {
			anchorNode = node
			break
		}
	}
	return anchorNode
}

function getParentFractionNode($from) {
	let fractionNode
	for (let i = $from.depth; i > 0; i--) {
		let node = $from.node(i)
		if (node.type.name === "friendlyFraction") {
			fractionNode = node
		break
		}
	}
	return fractionNode
}

function getNodePos(anchorNode){
	const state=window.view.state
	let nodePos
	state.doc.nodesBetween(0,state.doc.content.size, (node, pos) => {
		if(anchorNode===node){
			nodePos=pos
		}
	})
	return nodePos
}

export function deleteEmptyFriendlyFractionPlugin(){
	return new Plugin({
		key,
		props: {
			handleDOMEvents: {
				keydown (view, event) {
					if(event.keyCode===8){//delete key
						let state = view.state
						let sel = state.selection
						const $from=state.doc.resolve(sel.anchor)
						const anchorNode=getAnchorNode($from)
						if(anchorNode){
							if(anchorNode.textContent && anchorNode.textContent.length>0){
								// console.log('do nothing')
							}else{
								const parentFractionNode=getParentFractionNode($from)
								if(parentFractionNode.textContent && parentFractionNode.textContent.length>0){
								// console.log('do nothing')
								}else{
									event.preventDefault()
									const pos=getNodePos(parentFractionNode)
									let tr=state.tr
									tr.delete(pos,pos+parentFractionNode.nodeSize)    
									window.view.dispatch(tr)
								}
							}
						}
					}
				}
			}
		}
	})
}


function randId(){
  return '00000000'.replace(/0/g,function(){return (~~(Math.random()*16)).toString(16)})
}


const QuestionNodeSpec = {
   attrs: {
    template: {default: 'bodyLeft'},
    activeslide:{default:false},
    questionid:{default:randId()},
    bodyFontSize:{default:64},
    choiceFontSize:{default:42},
    questionMediaDynamicHeight:{default:null},
    questionOrderArray:{default:[]},
    slideWidth:{default:''},
  },
  isolating: true,
  selectable:false,
  content: "questionBody (questionImage | questionVideo | questionSound | questionAnimatedGIF){0,1} choiceGroup",
  toDOM(node) {
    return ['question', 0]},
  parseDOM: [{
    tag: 'question'}]
}
export default QuestionNodeSpec
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import RepoSelection from '../../components/modals/RepoSelection'
import {moveToRepo} from '../../utils/moveToRepo'
import {updateQuestionLock,updateSetLock} from '../../actions/locks'
import {showNotification} from '../../actions/notifications'
import each from 'lodash/each'
import {fetchQuestionsNoMinTime} from '../../actions/questions'
import {fetchSetsNoMinTime} from '../../actions/sets'
import {fetchFolders} from '../../actions/folders'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'

//Modal shown when user selects to move item to a repo
//modal shows a list of user's repos (that they have edit access to)
//on select a repo the item is moved to that repo

class RepoSelectionContainer extends Component {

	constructor(){
		super()
		this.moveToRepo=this.moveToRepo.bind(this)
		this.state={
			submitLoading: false,
			selectedRepoId:null
		}
	}

	moveToRepo(repo){
		this.setState({submitLoading:true,selectedRepoId:repo.id})
		const {itemToMove,multiSelectItems}=this.props
		if(itemToMove){
			this.props.moveToRepo(itemToMove,repo.id,null).then(()=>{
				this.props.hideModal()
				const notificationMessage=`${itemToMove.name || itemToMove.body} to ${repo.name}`
				this.props.showNotification(notificationMessage,'Moved','default')
				this.props.fetchSetsNoMinTime()
				this.props.fetchQuestionsNoMinTime()
				this.props.fetchFolders()
				if(this.props.navigateToRepo===true){
					this.props.history.push(`/packs/${repo.id}`)
				}else if(this.props.navigateToRepo){ //messy hack to reload editor after move item to repo-fix this!
					this.props.navigateToRepo()
					if(itemToMove.questions){
						this.props.updateSetLock(itemToMove.id)
					}else{
						this.props.updateQuestionLock(itemToMove.id)
					}	
				}
			})
		}else if(multiSelectItems){
			let promises = []
			each(Object.keys(multiSelectItems), function(key) {
				const item=multiSelectItems[key]
				promises.push(this.props.moveToRepo(item,repo.id,null))					
			}.bind(this))
			Promise.all(promises).then(function () {
				this.props.hideModal()
				this.props.showNotification(`${Object.keys(multiSelectItems).length} items to ${repo.name}`,'Moved','default')		
				this.props.fetchQuestionsNoMinTime()
				this.props.fetchSetsNoMinTime()
				this.props.fetchFolders()
				if(this.props.navigateToRepo){
					this.props.history.push(`/packs/${repo.id}`)
				}
			}.bind(this))
		}
	} 

	render() {
		return (    
			<RepoSelection 
				submitLoading={this.state.submitLoading}
				selectedRepoId={this.state.selectedRepoId}
				repos={this.props.repos} 
				item={this.props.itemToMove} 
				multiSelectItems={this.props.multiSelectItems} 
				hideModal={this.props.hideModal} 
				moveToRepo={this.moveToRepo}/>
		)
	}
}


export default withRouter(connect(
	(state) => ({
		repos:sortBy(filter(state.repos, function(repo) { 
    		if((!repo.published && !repo.disabled) || (repo.published && repo.role==='author')){
    			return repo
    		}
 			}),
		[repo => repo.name.toLowerCase()]),
		itemToMove:state.modals.itemToMove,
		multiSelectItems:state.modals.multiSelectItemsToMove,
		navigateToRepo:state.modals.navigateToRepo
	}),
	{ moveToRepo,showNotification,fetchQuestionsNoMinTime,fetchSetsNoMinTime,fetchFolders,updateQuestionLock,updateSetLock}
)(RepoSelectionContainer))


import React from 'react'
import ReactDOM from 'react-dom';
import ChoiceLetterBlock from './buttons/ChoiceLetterBlock' 
import DeleteChoiceButton from './buttons/DeleteChoiceButton'  
import AddMediaChoiceButton from './buttons/AddMediaChoiceButton'  
import slideLayoutDefinitions from '../../../staticData/slideLayoutDefinitions.js'
import {setChoiceIsTouched} from '../../../prosemirror/utils/setChoiceIsTouched'


class ChoiceNodeView {

	constructor(node, view, getPos) {
		this.getNode=this.getNode.bind(this)
		this.node = node
		this.getPos=getPos
		const {correct,isSurvey,choiceIndex,fontSize,questionChoiceCount,template} = node.attrs
		this.dom = document.createElement("div")
		this.dom.classList.add("slide-choice")
		if(isSurvey===true){
			this.dom.classList.add("slide-choice--survey")
		}else{
			this.dom.classList.add("slide-choice--graded")
			if(correct===true){
				this.dom.classList.add("slide-choice--correct")
			}else{
				this.dom.classList.add("slide-choice--incorrect")
			}
		}
		this.dom.style.fontSize=`${fontSize||42}px`

		if(template==='bodyCenterChoicesMedia' || template==='bodyLeftMediaRightChoicesMedia'){
			const mediaChoiceWidth = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${questionChoiceCount}Choices`].width
			this.dom.style.width=`${mediaChoiceWidth}px`
		}else{
			this.dom.style.width=null
		}
		this.dom.classList.add(`slide-choice--choiceCount--${questionChoiceCount}`)
	 
		ReactDOM.render(<ChoiceLetterBlock 
			choiceIndex={choiceIndex.toString()} 
			getNode={this.getNode} 
			getPos={getPos}  />, this.dom)


		const choiceContentElement=document.createElement("div");
		choiceContentElement.className='slide-choice-content'
		this.dom.appendChild(choiceContentElement)
		this.contentDOM = choiceContentElement
		this.buttonDOM = document.createElement("span")
		this.dom.appendChild(this.buttonDOM)
		this.addMediaButtonDOM = document.createElement("span")
		this.dom.appendChild(this.addMediaButtonDOM)
		const imageButtonDOM = document.createElement("span")
		this.dom.appendChild(imageButtonDOM)
		const removeImageButtonDOM = document.createElement("span")
		this.dom.appendChild(removeImageButtonDOM)
		if(questionChoiceCount<3){
			ReactDOM.render(null, this.buttonDOM)
		}else{
			ReactDOM.render(<DeleteChoiceButton  
				getNode={this.getNode} 
				getPos={this.getPos}/>, this.buttonDOM)
		}

		if(template==='bodyCenterChoicesMedia' || template==='bodyLeftMediaRightChoicesMedia'){
			ReactDOM.render(null, this.addMediaButtonDOM)
		}else{
			ReactDOM.render(<AddMediaChoiceButton
				template={template}
			/>, this.addMediaButtonDOM)
		}
	} 

	getNode(){
		return this.node
	}

	update(node) {
		if (node.type !== this.node.type) return false
		
		const {choiceIndex,correct,isSurvey,fontSize,questionChoiceCount,template} = node.attrs
		let isFocused=false
		if(this.dom.classList.contains('slide-choice--focused')){
			isFocused=true
		}
		this.dom.classList=''
		if(isFocused){
			this.dom.classList.add('slide-choice--focused')
		}
		this.dom.classList.add("slide-choice")
		if(isSurvey===true){
			this.dom.classList.add("slide-choice--survey")
		}else{
			this.dom.classList.add("slide-choice--graded")
			if(correct===true){
				this.dom.classList.add("slide-choice--correct")
			}else{
				this.dom.classList.add("slide-choice--incorrect")
			}
		}
		this.dom.classList.add(`slide-choice--choiceCount--${questionChoiceCount}`)

		if(template==='bodyCenterChoicesMedia' || template==='bodyLeftMediaRightChoicesMedia'){
			const mediaChoiceWidth = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${questionChoiceCount}Choices`].width
			this.dom.style.width=`${mediaChoiceWidth}px`
		}else{
			this.dom.style.width=null
		}

		ReactDOM.render(<ChoiceLetterBlock 
			isCorrect={correct.toString()} 
			choiceIndex={choiceIndex.toString()} 
			getNode={this.getNode} 
			getPos={this.getPos}  />, this.dom)

		if(questionChoiceCount<3){
			ReactDOM.render(null, this.buttonDOM)
		}else{
			ReactDOM.render(<DeleteChoiceButton  
			getNode={this.getNode} 
			getPos={this.getPos}/>, this.buttonDOM)
		}

		if(template==='bodyCenterChoicesMedia' || template==='bodyLeftMediaRightChoicesMedia'){
			ReactDOM.render(null, this.addMediaButtonDOM)
		}else{
			ReactDOM.render(<AddMediaChoiceButton
			template={template}
		/>, this.addMediaButtonDOM)
		}

		this.dom.style.fontSize=`${fontSize||42}px`
		this.node = node

		if(node.attrs.isTouched===false){ 
			if (node.textContent.length > 0 || (node.content && node.content.content && node.content.content[1] && node.content.content[1].type.name !=='choiceMediaEmptyState' ) ){
				setTimeout(function() { //Start the timer
					setChoiceIsTouched(node,this.getPos())
				}.bind(this),  50)
			}
		}
		return true
	}

	destroy() {
		//if (this.innerView) this.close()
	}

	stopEvent(event) {
		//return this.innerView && this.innerView.dom.contains(event.target)
	}

}

export default ChoiceNodeView
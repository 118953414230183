//Used for "do not show me this again" checkbox in confirm modals

export function getConfirmModalSettings() {	

	//Defaults
	let confirmModalSettings={
		showMoveFromSharedRepoModal:true,
		showMoveFromPublishedRepoModal:true,
		showSendToTrashModal:true,
		showArchiveModal:true,
		showLeaveRepoModal:true,
		showArchiveRepoItemModal:true
	} 

	const confirmModalSettingsFromLocalStorage = JSON.parse(localStorage.getItem('confirmModalSettings'))
	if(confirmModalSettingsFromLocalStorage ){
		if(confirmModalSettingsFromLocalStorage.showMoveFromSharedRepoModal===true || confirmModalSettingsFromLocalStorage.showMoveFromSharedRepoModal===false ){
			confirmModalSettings.showMoveFromSharedRepoModal=confirmModalSettingsFromLocalStorage.showMoveFromSharedRepoModal
		}
		if(confirmModalSettingsFromLocalStorage.showSendToTrashModal===true ||confirmModalSettingsFromLocalStorage.showSendToTrashModal===false ){
			confirmModalSettings.showSendToTrashModal=confirmModalSettingsFromLocalStorage.showSendToTrashModal
		}
		if(confirmModalSettingsFromLocalStorage.showArchiveModal===true || confirmModalSettingsFromLocalStorage.showArchiveModal===false ){
			confirmModalSettings.showArchiveModal=confirmModalSettingsFromLocalStorage.showArchiveModal
		}
		if(confirmModalSettingsFromLocalStorage.showLeaveRepoModal===true || confirmModalSettingsFromLocalStorage.showLeaveRepoModal===false ){
			confirmModalSettings.showLeaveRepoModal=confirmModalSettingsFromLocalStorage.showLeaveRepoModal
		}
		if(confirmModalSettingsFromLocalStorage.showArchiveRepoItemModal===true || confirmModalSettingsFromLocalStorage.showArchiveRepoItemModal===false ){
			confirmModalSettings.showArchiveRepoItemModal=confirmModalSettingsFromLocalStorage.showArchiveRepoItemModal
		}
		
		if(confirmModalSettingsFromLocalStorage.showMoveFromPublishedRepoModal===true || confirmModalSettingsFromLocalStorage.showMoveFromPublishedRepoModal===false ){
			confirmModalSettings.showMoveFromPublishedRepoModal=confirmModalSettingsFromLocalStorage.showMoveFromPublishedRepoModal
		}
	}

	return confirmModalSettings

}


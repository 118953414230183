import {inputRules} from './customInputRules'
import schema from '../schema/editorSchema'
import fractionInputRule from './inputRules/fractionInputRule'


//use custom input rules so can trigger with tab key as well as space

const buildInputRules = (schema) => {
  let result = []
  result.push(fractionInputRule())
  return result
}


const fractionPlugin=inputRules({ rules: buildInputRules(schema) })

export default fractionPlugin



import React, { Component } from 'react'
import { connect } from 'react-redux'
import find from 'lodash/find'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import ScoreSheet from '../../components/scoreSheet/ScoreSheet'
import { calculateDataForCsvExport } from '../../utils/scoresheet/calculateDataForCsvExport'
import { calculateStudentTotals } from '../../utils/scoresheet/calculateStudentTotals'
import { calculateGuestTotals } from '../../utils/scoresheet/calculateGuestTotals'

// This component takes date range (passed as props from ScoreSheetContainer.js) and filters data, handles excluding polls and does csv calcs
// Clicking on a poll header in scoresheet excludes it from calculations e.g. student score for timeperiod
// excluded polls are grayed out in the table

// calculateStudentTotals returns response counts by student for polls in date selection (not including excluded polls)
// studentsWithTotals[studentId]=
// {
//  totalResponses:number of responses,
//  totalCorrect:number of correct responses,
//  totalSurveys:number of survey responses
// }
// as well as aggregated counts for all students

// calculateGuestTotals does the same for guests (TODO could combine these functions as very similar logic)

class ScoreSheetDataContainer extends Component {
  constructor(props) {
    super(props)
    this.calculateCsvData = this.calculateCsvData.bind(this)
    this.getCsvFilename = this.getCsvFilename.bind(this)
    this.excludePoll = this.excludePoll.bind(this)
    this.clearExcludedPolls = this.clearExcludedPolls.bind(this)
    let excludedPolls = null
    if (props.sections.length !== 0) {
      excludedPolls = this.createInitialExcludedPollsObject(props.sections)
    }
    this.state = {
      excludedPolls, // polls to be excluded from calcs
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.sections.length === 0 && nextProps.sections.length !== 0) {
      const excludedPolls = this.createInitialExcludedPollsObject(nextProps.sections)
      this.setState({ excludedPolls })
    }
  }

  createInitialExcludedPollsObject(sections) {
    const excludedPolls = {}
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i]
      excludedPolls[section.id] = {
        questionPolls: {},
        setPolls: {},
        setPollPolls: {},
      }
    }
    return excludedPolls
  }

  clearExcludedPolls(sectionId) {
    let { excludedPolls } = this.state
    if (sectionId) {
      excludedPolls[sectionId] = {
        questionPolls: {},
        setPolls: {},
        setPollPolls: {},
      }
    } else {
      excludedPolls = this.createInitialExcludedPollsObject(this.props.sections)
    }
    this.setState({ excludedPolls })
  }

  // can exclude a poll, setPoll or a setPollPoll.  Toggling excluding setPoll toggles all child setPollPolls
  excludePoll(pollId, pollType, parentPoll) { // pollType is questionPoll,setPoll or setPollPoll
    const sectionId = this.props.currentSection.id
    this.setState(function (prevState) {
      const { excludedPolls } = prevState
      // TODO check setPolls vs setPoll typo
      if (pollType === 'setPolls') { // if toggle a setPoll clear all setPollPolls from excludedPolls
        if (excludedPolls[sectionId][pollType][pollId]) {
          // if deselecting setPoll, deselect all setPollPolls
          Object.keys(excludedPolls[sectionId].setPollPolls).map((key) => {
            if (key.includes(pollId)) {
              delete excludedPolls[sectionId].setPollPolls[key]
            }
            return null
          })
        } else {
          const setPoll = find(this.props.historyPolls, { id: pollId })
          // if excluding setPoll, exclude all setPollPolls
          Object.keys(setPoll.polls).map((key) => {
            excludedPolls[sectionId].setPollPolls[`${pollId}-${key}`] = true
            return null
          })
        }
      } else if (pollType === 'setPollPolls') { // if setPollPoll clear setPoll (if it is excluded)
        if (excludedPolls[sectionId].setPolls[parentPoll]) {
          delete excludedPolls[sectionId].setPolls[parentPoll]
        } else {
          // if excluding setPollPoll check if all setPollPolls are now excluded
          // eslint-disable-next-line no-lonely-if
          if (!excludedPolls[sectionId][pollType][pollId]) {
            const setPoll = find(this.props.historyPolls, { id: parentPoll })
            let excludedSetPollPollCount = 0
            Object.keys(setPoll.polls).map((key) => {
              if (excludedPolls[sectionId].setPollPolls[`${parentPoll}-${key}`]) {
                excludedSetPollPollCount += 1
              }
              return null
            })
            if (excludedSetPollPollCount === Object.keys(setPoll.polls).length - 1) {
              excludedPolls[sectionId].setPolls[parentPoll] = true
            }
          }
        }
      }
      if (excludedPolls[sectionId][pollType][pollId]) {
        delete excludedPolls[sectionId][pollType][pollId] // toggle
      } else excludedPolls[sectionId][pollType][pollId] = true

      return { excludedPolls }
    }, () => {
    })
  }

  calculateCsvData(sortedBy, sortOrder) {
    const { historyPolls, currentSection, dateRange } = this.props
    const { excludedPolls } = this.state
    const polls = historyPolls.slice(0)
    const studentsWithTotals = calculateStudentTotals(polls, currentSection, excludedPolls, dateRange)
    const guestsWithTotals = calculateGuestTotals(polls, currentSection, excludedPolls)
    const csvData = calculateDataForCsvExport(polls, currentSection, studentsWithTotals, guestsWithTotals, dateRange, excludedPolls, sortedBy, sortOrder)
    return csvData
  }

  getCsvFilename() {
    let { endDate } = this.props.dateRange
    if (moment().diff(endDate, 'days') < 0) { // if end date is in the future (part way through granularity), use today
      endDate = moment()
    }
    const csvFilename = `${this.props.currentSection.name} ${moment(this.props.dateRange.startDate).format('DD/MM/YYYY')}-${moment(endDate).format('DD/MM/YYYY')} .csv`
    return csvFilename
  }

  render() {
    const { excludedPolls } = this.state
    const { historyPolls, currentSection } = this.props
    const studentsWithTotals = calculateStudentTotals(historyPolls, currentSection, excludedPolls)
    const guestsWithTotals = calculateGuestTotals(historyPolls, currentSection, excludedPolls)
    return (
      <React.Fragment>
        <div className='scoreSheetContainer'>
          <ScoreSheet
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props} // pass all props along
            studentsWithTotals={studentsWithTotals}
            guestsWithTotals={guestsWithTotals}
            excludePoll={this.excludePoll}
            excludedPolls={this.state.excludedPolls}
            calculateCsvData={this.calculateCsvData}
            getCsvFilename={this.getCsvFilename}
            disable90Day={this.props.scoreSheetDisable90Day}
            disableCustom={this.props.scoreSheetDisableCustom}
            clearExcludedPolls={this.clearExcludedPolls}
          />
        </div>

      </React.Fragment>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const currentSection = find(state.sections, { id: ownProps.activeSectionId })
  const historySetPolls = filter(filter(state.historySetPolls, { section: ownProps.activeSectionId }), { archived: false })
  const historyQuestionPolls = filter(filter(state.historyQuestionPolls, { section: ownProps.activeSectionId }), { archived: false })
  const allHistoryPolls = historySetPolls.concat(historyQuestionPolls)
  let historyPolls = []
  if (ownProps.dateRange) {
    historyPolls = filter(allHistoryPolls, (poll) => (moment(poll.minCaptured).isAfter(ownProps.dateRange.startDate) &&
         moment(poll.minCaptured).isBefore(ownProps.dateRange.endDate)))
  }
  historyPolls = sortBy(historyPolls, 'minCaptured')
  let columnCount = 0 // for IE
  let independentQuestionCount = 0
  let setCount = 0
  for (let i = 0; i < historyPolls.length; i++) {
    const poll = historyPolls[i]
    if (poll.polls) {
      setCount += 1
      columnCount += Object.keys(poll.polls).length
      poll.pollsOrderedByMinCaptured = sortBy(poll.polls, 'minCaptured')
    } else {
      columnCount += 1
      independentQuestionCount += 1
    }
  }

  let product = null
  if (state.service) {
    product = state.service.product
  }
  return {
    setCount,
    independentQuestionCount,
    columnCount,
    sections: state.sections,
    currentSection,
    allHistoryPolls,
    historyPolls,
    scoreSheetDisable90Day: state.planRestrictions.scoreSheetDisable90Day,
    scoreSheetDisableCustom: state.planRestrictions.scoreSheetDisableCustom,
    product,
  }
}

export default connect(mapStateToProps,
  { })(ScoreSheetDataContainer)

import {Plugin,TextSelection} from "prosemirror-state"


//https://github.com/ueberdosis/tiptap/issues/629#issuecomment-690972208

const QUESTION_BODY_MAX_LENGTH = 400
const CHOICE_BODY_MAX_LENGTH = 200


function getNodeAtPos(newResPos) {
	let nodeAtPos
	for (let i = newResPos.depth; i > 0; i--) {
		let node = newResPos.node(i)
		if (node.type.name === "questionBody" || node.type.name === "choiceBody") {
			nodeAtPos = node
			break
		}
	}
	return nodeAtPos
}

export function characterLimitPlugin(){
	return new Plugin({
		appendTransaction: (transactions, oldState, newState) => {
			let focused=false
			if(window.view){
				focused=window.view.hasFocus()
			}
			if (!focused) {
				return false
			}
			const oldDocSize = oldState.doc.content.size
			const newDocSize = newState.doc.content.size
			if(newDocSize > oldDocSize){
				const newResPos = newState.selection.$head
				const node = getNodeAtPos(newResPos)
				if(node){
					const nodeSize=node.content.size        
					let max = QUESTION_BODY_MAX_LENGTH
					if(node.type.name==='choiceBody'){
						max = CHOICE_BODY_MAX_LENGTH
					}
					max = max +2
					if (nodeSize > max) {
						const overPaste = nodeSize - max;
						const newTextSelection = TextSelection.create(
							newState.doc,
							(newResPos.pos - overPaste) > 0
							? newResPos.pos - overPaste
							: 0,
							newResPos.pos);
						let newTr = newState.tr;
						newTr.setSelection(newTextSelection)
						newTr.deleteSelection()
						return newTr;
					}
				}
			}
		}
	})
}
import {updateQuestionMeta} from '../actions/questions'
import {updateSetMeta} from '../actions/sets'
import {updateFolder} from '../actions/folders'
import store from '../store'


export function moveToLibrary(itemToMove) {
	const nowDate = new Date()
	if(itemToMove.questions){
		const updatedSet={
			...itemToMove,
			clientModified:nowDate,
			folder:null,
			repo:null
		}
		return store.dispatch(updateSetMeta(updatedSet,itemToMove.id))	
	}
	else if(itemToMove.ancestors){
		const updatedFolder={
			...itemToMove,	
			clientModified:nowDate,
			parent:null,
			repo:null
		}
		return store.dispatch(updateFolder(updatedFolder,itemToMove.id))
	}

	else{
		const updatedQuestion={
			...itemToMove,	
			clientModified:nowDate,
			folder:null,
			repo:null
		}
		return store.dispatch(updateQuestionMeta(updatedQuestion,itemToMove.id))
	}
}


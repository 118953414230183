import React from 'react'
import ReactDOM from 'react-dom'
/* eslint-disable import/no-cycle */
import EditorInsertVideoModal from '../editorUI/modals/EditorInsertVideoModal'
/* eslint-enable import/no-cycle */
import { stopAllMediaPlayback } from '../../../prosemirror/utils/editorActions/stopAllMediaPlayback'

const modalId = 'editor-insertYouTubeMiniModalContainer'

function closeInsertVideoModal() {
  ReactDOM.render(null, document.getElementById(modalId))
}

export function showInsertVideoModal(searchState, insertPos, isQuestion, questionPos, questionNode) {
  stopAllMediaPlayback()
  ReactDOM.render(
    <EditorInsertVideoModal
      searchState={searchState}
      insertPos={insertPos}
      isQuestion={isQuestion}
      questionPos={questionPos}
      questionNode={questionNode}
      closeInsertVideoModal={() => { closeInsertVideoModal() }}
    />, document.getElementById(modalId),
  )
}

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { sendDeleteAccountLink } from '../../../actions/user'
import ReauthenticationModal from '../../../components/reauthenticationModal/ReauthenticationModal'
import Button from '../../../components/uiKit/Button'
import { hideModal } from '../../../actions/modals'
import { showNotification } from '../../../actions/notifications'
import handleAccountRequest from '../../account/utils/handleAccountRequest'

function DeleteAccountModalContainer(props) {
  const {
    user,
    signinMethods,
    sendDeleteAccountLink,
    showNotification,
    hideModal,
  } = props

  const [showReauthenticationModal, setShowReauthenticationModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [submitLoading, setSubmitLoading] = useState(false)

  function onAuthenticate(authentication) {
    const successMessage = `We've sent a link to ${user.email}. Please check your email.`
    handleAccountRequest({
      setSubmitLoading,
      setErrorMessage,
      makeRequest: () => sendDeleteAccountLink(user.id, { ...authentication }),
      hideModal,
      showSuccessNotification: () => showNotification(successMessage, '', 'default'),
      requestName: 'sendDeleteAccountLink',
      dataParamForErrorMessage: { authType: authentication.type },
    })
  }

  return (
    <React.Fragment>
      <div className='delete-account-modal'>
        <div className='delete-account-modal-step'>Step 1 of 3</div>
        <div className='delete-account-modal-description'>
          <div>To delete your account, please authenticate using the button below.</div>
          <div>
            You will then receive an email at
            {' '}
            <span>{user.email}</span>
          </div>
          <div>containing a link to confirm your deletion request.</div>
        </div>
        <div className='delete-account-modal-warning'>
          <div>
            <span>Important:</span>
            {' '}
            deleting your account is permanent and cannot be undone.
          </div>
        </div>

        <Button
          size='large'
          submitLoading={submitLoading}
          label='Authenticate'
          color='red'
          onClickFunction={() => setShowReauthenticationModal(true)}
        />

        {errorMessage && (
          <div className='accountForm-error'>
            {errorMessage}
          </div>
        )}
      </div>
      {showReauthenticationModal && (
        <ReauthenticationModal
          title='Delete Account'
          signinMethodsToDisplay={{ ...signinMethods }}
          onAuthenticate={(authentication) => onAuthenticate(authentication)}
          hideModal={() => setShowReauthenticationModal(false)}
          actionButtonColor='red'
        />
      )}
    </React.Fragment>
  )
}

export default connect(
  (state) => ({
    user: state.user,
    signinMethods: state.signinMethods,
  }),
  {
    sendDeleteAccountLink, hideModal, showNotification,
  },
)(DeleteAccountModalContainer)

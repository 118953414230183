import PropTypes from 'prop-types'
import React, { Component } from 'react'
import GalleryImage from './GalleryImage.js'


//Adapted from react-grid-gallery https://github.com/benhowell/react-grid-gallery

class Gallery extends Component {

	constructor () {
		super()
		this.state = {
			images: [],
			thumbnails: [],
			containerWidth: 0
		}
		this.onResize = this.onResize.bind(this)
	}

	componentDidMount () {
		this.onResize()
		window.addEventListener('resize', this.onResize)
		if(this.props.images){
			this.setState({
				images:this.props.images,
				thumbnails: this.renderThumbs(this._gallery.clientWidth,
																this.props.images)
			})
		}
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		this.setState({
			images: nextProps.images,
			thumbnails: this.renderThumbs(this._gallery.clientWidth,
													nextProps.images)
		})
	}

	componentDidUpdate () {
		if (!this._gallery) return
		if (this._gallery.clientWidth
			!== this.state.containerWidth){
			this.onResize()
		}
	}

	onResize () {
		if (!this._gallery) return
		this.setState({
			containerWidth: Math.floor(this._gallery.clientWidth),
			thumbnails: this.renderThumbs(this._gallery.clientWidth)
		})
	}


	getOnClickThumbnailFn () {
		if(this.props.onClickThumbnail)
			return this.props.onClickThumbnail
		return null
	}

	calculateCutOff (len, delta, items) {
		var cutoff = []
		var cutsum = 0
		for(var i in items) {
			var item = items[i]
			var fractOfLen = item.scaletwidth / len
			cutoff[i] = Math.floor(fractOfLen * delta)
			cutsum += cutoff[i]
		}

		var stillToCutOff = delta - cutsum
		while(stillToCutOff > 0) {
			for(i in cutoff) {
				cutoff[i]++
				stillToCutOff--
				if (stillToCutOff < 0) break
			}
		}
		return cutoff
	}

	buildImageRow (items, containerWidth) {
		var row = []
		var len = 0
		var imgMargin = 2 * this.props.margin
		while(items.length > 0 && len < containerWidth) {
			var item = items.shift()
			row.push(item)
			len += (item.scaletwidth + imgMargin)
		}
		var delta = len - containerWidth
		if(row.length > 0 && delta > 0) {
			var cutoff = this.calculateCutOff(len, delta, row)
			for(var i in row) {
				var pixelsToRemove = cutoff[i]
				item = row[i]
				item.marginLeft = -Math.abs(Math.floor(pixelsToRemove / 2))
				item.vwidth = item.scaletwidth - pixelsToRemove
			}
		}
		else {
			for(var j in row) {
				item = row[j]
				item.marginLeft = 0
				item.vwidth = item.scaletwidth
			}
		}
		return row
	}

	setThumbScale (item) {
		item.scaletwidth =
			Math.floor(this.props.rowHeight * (item.thumbnailWidth / item.thumbnailHeight))
	}

		renderThumbs (containerWidth, images = this.state.images) {
				if (!images) return []
				if (containerWidth === 0) return []

				var items = images.slice()
				for (var t in items) {
						this.setThumbScale(items[t])
				}

				var thumbs = []
				var rows = []
				while(items.length > 0) {
						rows.push(this.buildImageRow(items, containerWidth))
				}

				for(var r in rows) {
						for(var i in rows[r]) {
								var item = rows[r][i]
								if(this.props.maxRows) {
										if(r < this.props.maxRows) {
												thumbs.push(item)
										}
								}
								else {
										thumbs.push(item)
								}
						}
				}
				return thumbs
		}

	render () {
		var images = this.state.thumbnails.map((item, idx) => {
		return <GalleryImage
			key={'Image-'+idx+'-'+item.src}
			item={item}
			index={idx}
			margin={this.props.margin}
			height={this.props.rowHeight}
			isSelectable={this.props.enableImageSelection}
			onClick={this.getOnClickThumbnailFn()}
			onDoubleClick={this.props.onDoubleClick}
			onSelectImage={this.onSelectImage}
			tagStyle={this.props.tagStyle}
			isGif={this.props.isGif}
		/>})


	
				return (
								<div id='Gallery' ref={(c) => this._gallery = c}>
								{images}

								</div>
				)
		}
}

export default Gallery

Gallery.displayName = 'Gallery'

Gallery.propTypes = {
		images: PropTypes.arrayOf(
				PropTypes.shape({
						src: PropTypes.string.isRequired,
						thumbnail: PropTypes.string,
						srcset: PropTypes.array,
						caption: PropTypes.string,
						tags: PropTypes.arrayOf(
								PropTypes.shape({
										value: PropTypes.string.isRequired,
										title: PropTypes.string.isRequired
								})
						),
						thumbnailWidth: PropTypes.number.isRequired,
						thumbnailHeight: PropTypes.number.isRequired,
						isSelected: PropTypes.bool
				})
		).isRequired,
		rowHeight: PropTypes.number,
		maxRows: PropTypes.number,
		margin: PropTypes.number,
		onClickThumbnail: PropTypes.func,
}

Gallery.defaultProps = {
		enableImageSelection: true,
		rowHeight: 180,
		margin: 2,
		backdropClosesModal: false,
		currentImage: 0,
		preloadNextImage: true,
		enableKeyboardInput: true,
		imageCountSeparator: ' of ',
		isOpen: false,
		showCloseButton: true,
		showImageCount: true,
		lightboxWidth: 1024,
		showLightboxThumbnails: false
}

// module.exports = Gallery
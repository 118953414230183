import React from 'react'
import Icon from '../../misc/Icons'
 
class PublishingFlowSelectControl extends React.Component{

 
	render(){	
		const {onChangeInput,inputValue,radio, checkbox, optionalOtherInputBox, description,checked} = this.props


		let showOptionalInputBox = false
		if(optionalOtherInputBox && checked){
			showOptionalInputBox = true
		}


		return(
			<div onClick={this.props.onSelect} className={'publishingFlowSelectControl ' + (checked ? 'publishingFlowSelectControl--checked' : 'publishingFlowSelectControl--unchecked')}>

				{checkbox &&
					<div className='publishingFlowSelectControl-checkbox'>
						{checked &&
							<Icon name='checkbox-check' />
						}
					</div>
				}

				{radio &&
					<div className='publishingFlowSelectControl-radio'>
						{checked &&
							<div className='publishingFlowSelectControl-radio-inner'/>
						}
					</div>
				}

				
					<div className='publishingFlowSelectControl-label'>
						{this.props.label}					
					</div>
				

				{description && this.props.showDescription && !showOptionalInputBox &&
					<div className='publishingFlowSelectControl-description'>
						{this.props.description}
					</div>
				}

				{showOptionalInputBox &&
					<input 
						type='text'
						value={inputValue}
						placeholder={this.props.optionalOtherInputBoxPlaceholder}
						className='publishingFlowSelectControl-optionalOtherInputBox'
						onChange={onChangeInput}
						maxLength={300} // maybe want to put this somewhere
						onClick={(e)=>{e.stopPropagation()}}
					/>
				}

				

				
							

			</div>
			
		)	
	}
				
}





export default PublishingFlowSelectControl



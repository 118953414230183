import React from 'react'
import Button from '../../uiKit/Button'


class SectionHomeEmptyStudents extends React.Component{

	render(){

		return(
			<div className="sectionHome-emptyStudents sectionHome--block">
				<div className="sectionHome--block-divider" />
				<div className="sectionHome--block-header">
					<div className="sectionHome--block-header-title">
						No Students Added
					</div>
				</div>
				<div className='sectionHome-emptyStudents-label'>
					Finish class setup by adding your students now
				</div>
				<Button size='large' label='Add Students' onClickFunction={this.props.showBulkAddStudentsModal} />
					
												

			</div>
		)
	}
}


export default SectionHomeEmptyStudents



import React from 'react'
import loadable from '@loadable/component'
const ComplexIconSVG = loadable(() => import(/*webpackChunkName: 'upgradeicons'*/'./ComplexIconsSVG'))

class ComplexIcon extends React.Component{
  render(){
    return(
      <ComplexIconSVG name={this.props.name} />        
    )
  }
}

export default ComplexIcon

import React from 'react'
import { withRouter } from 'react-router-dom'
import EditorIcon from '../../misc/EditorIcons'
import Icon from '../../misc/Icons'
import Dropdown from '../../uiKit/Dropdown'
import DropdownItem from '../../uiKit/DropdownItem'
import DropdownSelectItem from '../../uiKit/DropdownSelectItem'
import DropdownDivider from '../../uiKit/DropdownDivider'
import DropdownSelect from '../../uiKit/DropdownSelect'
import { newSet } from '../../../utils/newItem'
import EditorTopBarSetTitleInput from './EditorTopBarSetTitleInput'
import EditorTopBarUndoRedoButtons from './EditorTopBarUndoRedoButtons'
import { addQuestionToSet } from '../../../prosemirror/utils/editorActions/addQuestionToSet'
import AddToQueueDropdown from '../../uiKit/AddToQueueDropdown'
import PollsForItemList from '../../uiKit/PollsForItemList'

class EditorTopBarMenuButton extends React.Component {
  render() {
    const { onClickFunction } = this.props
    return (
      <button onClick={onClickFunction} className='editor-topBar-btn editor-topBar-btn--menu'>
        <EditorIcon name='topBarMenu' />
      </button>
    )
  }
}

class AddToClassDropdownButton extends React.Component {
  render() {
    const { onClickFunction, isOpen, created } = this.props
    return (
      <button disabled={!created} className={`btn btn--mid btn--white btn--dropdown addToClassDropdownButton ${isOpen ? ' btn--dropdown--open' : ''}`} onClick={onClickFunction}>
        Add to Queue
      </button>
    )
  }
}

class EditorTopBar extends React.Component {
  constructor() {
    super()
    this.handleMakeACopy = this.handleMakeACopy.bind(this)
    let imageFill = true
    const savedDefaultChoiceImageFill = JSON.parse(localStorage.getItem('defaultChoiceImageFill'))
    if (savedDefaultChoiceImageFill || savedDefaultChoiceImageFill === false) {
      imageFill = savedDefaultChoiceImageFill
    }
    this.state = {
      defaultChoiceImageFill: imageFill,
    }
  }

  setDefaultChoiceImageFillType(imageFill) {
    localStorage.setItem('defaultChoiceImageFill', JSON.stringify(imageFill))
    this.setState({ defaultChoiceImageFill: imageFill })
  }

  newSet() {
    let parentFolder = null// We use the parentFolder for creating sets within folders
    let repo = null
    if (typeof window.parentFolder === 'string') { // Prevent invalid folder value
      parentFolder = window.parentFolder
    }
    if (typeof window.repo === 'string') { // Prevent invalid folder value
      repo = window.repo
    }
    newSet(parentFolder, repo)
  }

  handleMakeACopy() {
    if (this.props.isSetEditor) {
      return this.props.makeACopySet(this.props.set).then((response) => {
        window.open(`/seteditor/${response.id}`, '_blank')
      })
    }
    return this.props.makeACopyQuestion(this.props.question).then((response) => {
      window.open(`/editor/${response.id}`, '_blank')
    })
  }

  render() {
    const {
      set, question, isSetEditor, isQuestionEditor, disableFastFractionInputRule, maxNumOfQuestionsReached, isViewOnly, itemIsLockedByAnotherUser, inSharedRepo, isPublicConsumer, isPublicAuthor,
    } = this.props
    let isCreated = false
    if ((set && set.id) || (question && question.id)) {
      isCreated = true
    }

    return (
      <div className={`editor-topBar ${isQuestionEditor ? 'editor-topBar--questionEditor' : ''}`}>
        <div className='editor-topBar-left'>
          <Dropdown dropdownButton={<EditorTopBarMenuButton />}>
            <DropdownItem label='Your Library' onSelect={() => { this.props.history.push('/') }} />
            <DropdownDivider />
            {isSetEditor && (
            <React.Fragment>
              {!isViewOnly &&
              <DropdownItem label='New Set' onSelect={this.newSet} />}
              {!isViewOnly &&
              <DropdownItem label='Make a Copy' disabled={!isCreated} onSelect={this.handleMakeACopy} />}
              {isViewOnly &&
              <DropdownItem label='Make a Copy and Edit' onSelect={this.props.showDuplicateAndEditModal} />}
              {!isViewOnly &&
              <DropdownDivider />}
              {!isViewOnly &&
              <DropdownItem label='Import Questions' disabled={itemIsLockedByAnotherUser} onSelect={this.props.showImportQuestionsModal} />}
              {!isViewOnly &&
              <DropdownItem label='Move to Pack...' disabled={!isCreated || itemIsLockedByAnotherUser} onSelect={() => { this.props.showRepoSelectionModal(set, null, this.props.reloadEditor) }} />}
              <DropdownItem label='Print Set Handout' disabled={itemIsLockedByAnotherUser} onSelect={this.props.showPrintModal} />
              {!isViewOnly &&
              <DropdownItem label='Send to Trash' disabled={!isCreated || itemIsLockedByAnotherUser} warning onSelect={this.props.showArchiveSetModal} />}
            </React.Fragment>
            )}
            {isQuestionEditor && (
            <React.Fragment>
              {!isViewOnly &&
              <DropdownItem label='Create New' disabled={!isCreated} onSelect={this.props.newQuestionEditorTab} />}
              {!isViewOnly &&
              <DropdownItem label='Duplicate Question' disabled={!isCreated} onSelect={this.handleMakeACopy} />}
              <DropdownItem label='Copy to Clipboard' disabled={!isCreated} onSelect={() => { this.props.copyQuestionToClipboard(question) }} />
              {!isViewOnly && // to cancel out one below
              <DropdownDivider />}
              {!isViewOnly &&
              <DropdownItem label='Move to Pack...' onSelect={() => { this.props.showRepoSelectionModal(question, null, this.props.reloadEditor) }} />}
              {!isViewOnly &&
              <DropdownItem label='Send to Trash' warning onSelect={this.props.showArchiveQuestionModal} />}
            </React.Fragment>
            )}

            {!isViewOnly && !itemIsLockedByAnotherUser && (
            <React.Fragment>
              <DropdownDivider />
              <DropdownSelect>
                <DropdownSelectItem isActive={this.state.defaultChoiceImageFill} label='Default Fill Image Choice' onSelect={() => { this.setDefaultChoiceImageFillType(true) }} />
                <DropdownSelectItem isActive={!this.state.defaultChoiceImageFill} label='Default Fit Image Choice' onSelect={() => { this.setDefaultChoiceImageFillType(false) }} />
              </DropdownSelect>
            </React.Fragment>
            )}

            {!isViewOnly && !itemIsLockedByAnotherUser && (
            <React.Fragment>
              <DropdownDivider />
              {disableFastFractionInputRule &&
              <DropdownItem label='Turn on Auto Fractions' onSelect={this.props.toggleDisableFastFractionInputRule} />}
              {!disableFastFractionInputRule &&
              <DropdownItem label='Turn off Auto Fractions' onSelect={this.props.toggleDisableFastFractionInputRule} />}
            </React.Fragment>
            )}

            <DropdownDivider />

            <DropdownItem label="What's Possible" onSelect={() => { window.location.hash = 'whats-possible' }} />

            <DropdownItem label='Editor Help' onSelect={() => { window.open('https://help.plickers.com/hc/en-us/articles/360008948754', '_blank') }} />

            <DropdownItem label='Plickers Help' onSelect={() => { window.open('https://help.plickers.com', '_blank') }} />

          </Dropdown>

          {isSetEditor && !isViewOnly && (
          <button
            disabled={maxNumOfQuestionsReached || itemIsLockedByAnotherUser}
            onClick={() => { addQuestionToSet(this.props.onAddQuestionToSet) }}
            className='editor-topBar-btn editor-topBar-btn--newSlide'
            id='addQuestionButton'
            tabIndex={0}
          >
            <EditorIcon name='topBarNewSlide' />
            <div className='editor-topBar-tooltip'>
              New Question
            </div>
          </button>
          )}

          {!isViewOnly && !itemIsLockedByAnotherUser && (
          <EditorTopBarUndoRedoButtons
            editorView={window.view}
          />
          )}

          {isSetEditor && isViewOnly && (
          <button
            className='editor-topBar-btn editor-topBar-btn--textBtn'
            onClick={this.props.showDuplicateAndEditModal}
          >
            Make a Copy And Edit
          </button>
          )}

          {isQuestionEditor && !isViewOnly && (
          <button disabled={!isCreated} className='editor-topBar-btn editor-topBar-btn--textBtn' onClick={this.props.convertQuestionIntoSet}>
            Convert to Set
          </button>
          )}

          {isQuestionEditor && (
          <button disabled={!isCreated} className='editor-topBar-btn editor-topBar-btn--textBtn' onClick={() => { this.props.copyQuestionToClipboard(question) }}>
            Copy to Clipboard
          </button>
          )}

        </div>

        <div className='editor-topBar-center'>
          {isSetEditor && (
          <EditorTopBarSetTitleInput
            name={set.name}
            updateSetTitle={this.props.updateSetTitle}
            isPublicConsumer={isPublicConsumer}
            isPublicAuthor={isPublicAuthor}
            isViewOnly={isViewOnly}
            inSharedRepo={inSharedRepo}
            itemIsLockedByAnotherUser={itemIsLockedByAnotherUser}
          />
          )}
          {isQuestionEditor && isCreated && (
          <div className='editor-topBar-center-questionEditorTitle'>
            {inSharedRepo &&
            <Icon name='shared' />}
            {isPublicConsumer &&
            <Icon name='globe-shared' />}
            {isPublicAuthor &&
            <Icon name='globe-shared-author' />}
            Question Editor
          </div>
          )}

        </div>

        <div className='editor-topBar-right'>

          {isCreated === true &&
          <PollsForItemList item={set || question} />}

          <AddToQueueDropdown dropdownButton={<AddToClassDropdownButton disabled={!isCreated} created={isCreated} />} item={set || question} />

          {isSetEditor && !isViewOnly && (
          <button disabled={itemIsLockedByAnotherUser} className='editor-topBar-btn editor-topBar-btn--importQuestions' onClick={this.props.showImportQuestionsModal}>
            <Icon name='import-questions' />
            <div className='editor-topBar-tooltip'>
              Import Questions
            </div>
          </button>
          )}

          {isSetEditor && (
          <button className={`editor-topBar-btn editor-topBar-btn--print ${isViewOnly ? 'editor-topBar-btn editor-topBar-btn--print--printOnly' : 'editor-topBar-btn editor-topBar-btn--print--printAndImport'}`} onClick={this.props.showPrintModal}>
            <EditorIcon name='topBarPrint' />
            <div className='editor-topBar-tooltip'>
              Print Set Handout
            </div>
          </button>
          )}

          {isQuestionEditor && !isViewOnly && (
          <button disabled={!isCreated} className='editor-topBar-btn editor-topBar-btn--textBtn' onClick={this.props.newQuestionEditorTab}>
            Create New
          </button>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(EditorTopBar)

//
// HARDCODED STATES FOR TESTING

/// /
// NORMAL USER LOCAL (PACK OR NOT PACK)
//
// const isViewOnly= false
// const itemIsLockedByAnotherUser= false
// const inSharedRepo = false
// const isPublicConsumer = false
// const isPublicAuthor = false

// ////
// // PUBLIC CONSUMER
// //
// const isViewOnly= true
// const itemIsLockedByAnotherUser = false
// const inSharedRepo = false
// const isPublicConsumer = true
// const isPublicAuthor = false

// ////
// // PUBLIC AUTHOR
// //
// const isViewOnly= false
// const itemIsLockedByAnotherUser = false
// const inSharedRepo = false
// const isPublicConsumer = false
// const isPublicAuthor = true

// ////
// // PRIVATE SHARED: VIEWER
// //
// const isViewOnly = true
// const itemIsLockedByAnotherUser = null
// const inSharedRepo = true
// const isPublicConsumer = false
// const isPublicAuthor = false

// ////
// // PRIVATE SHARED: EDITOR NOT LOCKED
// //
// const isViewOnly = false
// const itemIsLockedByAnotherUser = false
// const inSharedRepo = true
// const isPublicConsumer = false
// const isPublicAuthor = false

// ////
// // PRIVATE SHARED: EDITOR LOCKED BY ANOTHER USER
// //
// const isViewOnly = false
// const itemIsLockedByAnotherUser = true
// const inSharedRepo = true
// const isPublicConsumer = false
// const isPublicAuthor = false

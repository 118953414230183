import React from 'react'
import FeatureModalSelectControl from '../../../featureModals/FeatureModalSelectControl'
import FeatureModalSelectControlGroup from '../../../featureModals/FeatureModalSelectControlGroup'
 

class ReportPackModalConfigControls extends React.Component{

	constructor() {
		super()		

		this.state = {
			selectedOptionTab: 'packSetup',
			personalizedContentControl: false
		}
	} 

	render(){ 
		const {browser,printSetSettings,setPrintSetSetting,setPaperSize,paperSize}=this.props
		const {selectedOptionTab}=this.state
		const {
			columnCount,
			showImages,
			showTeachersName,
			showDate,
			highlightCorrect,
			includeStudentWorkingSpace,
			labelSurveys

		}=printSetSettings


	
		let showPaperSizeControl=true
		if(browser==='firefox'){
			showPaperSizeControl=false
		}


		return(			
			<div className='featureModal-configControls'>
				<div className='featureModal-configControls-optionTabs'>
					<div 
						onClick={()=>{this.setState({selectedOptionTab: 'packSetup'})}}
						className={'featureModal-configControls-optionTabs-optionTab ' + (selectedOptionTab === 'packSetup' ? 'featureModal-configControls-optionTabs-optionTab--active' : 'featureModal-configControls-optionTabs-optionTab--inactive' )}>
						Setup
					</div>
					<div 
						onClick={()=>{this.setState({selectedOptionTab: 'options'})}}
						className={'featureModal-configControls-optionTabs-optionTab ' + (selectedOptionTab === 'options' ? 'featureModal-configControls-optionTabs-optionTab--active' : 'featureModal-configControls-optionTabs-optionTab--inactive' )}>
						Options
					</div>				
				</div>

				{selectedOptionTab === 'packSetup' &&
					<div className='featureModal-configControls-controlSet featureModal-configControls-controlSet--packSetup'>
						
						<FeatureModalSelectControlGroup>
							<FeatureModalSelectControl checked={highlightCorrect} onClick={()=>{setPrintSetSetting('highlightCorrect',!highlightCorrect)}} checkbox label='Indicate correct answers' subLabel='Show correct answer on every question.'/>
						</FeatureModalSelectControlGroup>

						<FeatureModalSelectControlGroup>
							<FeatureModalSelectControl checked={!showImages} onClick={()=>{setPrintSetSetting('showImages',!showImages)}} checkbox label='Hide all images and GIFs' />
						</FeatureModalSelectControlGroup>

						<FeatureModalSelectControlGroup>
							<FeatureModalSelectControl checked={includeStudentWorkingSpace} onClick={()=>{setPrintSetSetting('includeStudentWorkingSpace',!includeStudentWorkingSpace)}} checkbox label='Include space for student work' />
						</FeatureModalSelectControlGroup>
					 	
					 	<FeatureModalSelectControlGroup>
							<FeatureModalSelectControl textInput textInputValue={this.props.instructionalText} textInputOnChange={this.props.handleInstructionalTextAreaOnChange} label='Include instructions at top' subLabel='Write a custom message to appear at the top.' />
						</FeatureModalSelectControlGroup>

					</div>
				}

				{selectedOptionTab === 'options' &&
					<div className='featureModal-configControls-controlSet featureModal-configControls-controlSet--options'>					 

											
						<FeatureModalSelectControlGroup title='Layout'>
							<FeatureModalSelectControl checked={columnCount===1} onClick={()=>{setPrintSetSetting('columnCount',1)}} radio label='One-column layout' />
							<FeatureModalSelectControl checked={columnCount===2} onClick={()=>{setPrintSetSetting('columnCount',2)}} radio label='Two-column layout' />
						</FeatureModalSelectControlGroup>

						{showPaperSizeControl &&
						<FeatureModalSelectControlGroup title='Paper Size'>
							<FeatureModalSelectControl checked={paperSize==='letter'} onClick={()=>{setPaperSize('letter')}} radio label='Letter' />
							<FeatureModalSelectControl checked={paperSize==='a4'} onClick={()=>{setPaperSize('a4')}}  radio label='A4' />
						</FeatureModalSelectControlGroup>
						}

						<FeatureModalSelectControlGroup>
							<FeatureModalSelectControl checked={showTeachersName} onClick={()=>{setPrintSetSetting('showTeachersName',!showTeachersName)}}  checkbox label="Add teacher's name to header" />
							<FeatureModalSelectControl checked={showDate} onClick={()=>{setPrintSetSetting('showDate',!showDate)}}  checkbox label="Add today's date to header" />						
						</FeatureModalSelectControlGroup>

						<FeatureModalSelectControlGroup>
							<FeatureModalSelectControl checked={labelSurveys} checkbox label='Always show survey label' onClick={()=>{setPrintSetSetting('labelSurveys',!labelSurveys)}}/>
						</FeatureModalSelectControlGroup>
						
				

					</div>
				}
				


			</div>
		)
	}
}


export default ReportPackModalConfigControls

export default function getUserDataOwners(user, dataOwners) {
  const userDataOwners = []
  if (user?.dataOwners?.length > 0) {
    user.dataOwners.forEach((dataOwnerObj) => {
      const dataOwner = dataOwners[dataOwnerObj.id]
      if (dataOwner) userDataOwners.push(dataOwner)
    })
  }
  return userDataOwners
}

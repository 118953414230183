const randId='00000000'.replace(/0/g,function(){return (~~(Math.random()*16)).toString(16)})



const UNTOUCHED_CHOICE_BODY='\u2063'

export default function richQuestionTemplate(isIndependentQuestion) {

	let question={
		'body': '',
		'bodyHtml':'',
		'choices': [{
			'body':UNTOUCHED_CHOICE_BODY,
			//'bodyHtml':'',
			'correct': true
		},
		{
			'body':UNTOUCHED_CHOICE_BODY,
			//'bodyHtml':'Choice B<choicemediaemptystate></choicemediaemptystate>',
			'correct': false
		},
		{
			'body':UNTOUCHED_CHOICE_BODY,
			//'bodyHtml':'Choice C<choicemediaemptystate></choicemediaemptystate>',
			'correct': false
		},
		{
			'body':UNTOUCHED_CHOICE_BODY,
			//'bodyHtml':'Choice D<choicemediaemptystate></choicemediaemptystate>',
			'correct': false
		}],
		//'source':JSON.stringify(makePMNode(randId), null, 2),
		//'xLayout':null,
	    'measurements':{
	      'bodyFS':64,
	      'choiceFS':38,
	    },
		// 'version':2,
		'layout':'bodyLeft',
		// 'image':'',
		// 'questionId':randId
	}
	if(!isIndependentQuestion){
		question.questionId=randId
	}

	return question
}


import React from 'react'
import EditorIcon from '../../../../misc/EditorIcons'
import Icon from '../../../../misc/Icons'
import {CircularProgressbar} from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import {formatRecordingDuration} from './utils/formatRecordingDuration'
import moment from 'moment'
import { Spring } from  'react-spring/renderprops'

const SPACEBAR_RERECORD_DELAY=600
const SOUND_FINISHED_DELAY=100
 
class EditorInsertSoundRecordingReviewPage extends React.Component{  

	constructor(props) {
		super(props)
		this.playSound=this.playSound.bind(this)
		this.pauseSound=this.pauseSound.bind(this)
		this.startTimer=this.startTimer.bind(this)
		this.restartAudio=this.restartAudio.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.handleKeyUp=this.handleKeyUp.bind(this)
		this.stopTimer=this.stopTimer.bind(this)
		this.insertSound=this.insertSound.bind(this)
		this.onInputFocus=this.onInputFocus.bind(this)
		this.onInputBlur=this.onInputBlur.bind(this)
		this.seekTo=this.seekTo.bind(this)
		this.onProgressBarClick=this.onProgressBarClick.bind(this)

		this.audioElement = new Audio()
		this.audioElement.src=props.audioURL
		this.audioElement.addEventListener("ended", function(){
			this.setState({playerCurrentTime:props.recordingDuration},()=>{
			 this.endedTimeout=setTimeout(() => {
					this.setState({isPlaying:false,playerCurrentTime:0})
				}, SOUND_FINISHED_DELAY)
				this.audioElement.currentTime = 0;
				this.stopTimer()
			})}.bind(this))

		this.timer=null

		let setTitle='Untitled Set'
		const setTitleInput=document.getElementById('updateSetTitleField')
		if(setTitleInput){
			setTitle=setTitleInput.value || "Untitled Set"
		}
		const formattedDate=moment().format('DD MMM YYYY')
		let recordingTitle=`Untitled Recording (${setTitle} – ${formattedDate})`
		
		this.state = {
			isPlaying:false,
			duration:props.recordingDuration,
			playerCurrentTime:0,
			spaceBarPressed:false,
			recordingTitle:recordingTitle,
			submitLoading:false,
			titleInputFocused:false
		}  
	}

	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyDown)
		document.addEventListener('keyup', this.handleKeyUp)
		this.playSound()
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.audioURL !== this.props.audioURL){
			this.audioElement.src=nextProps.audioURL
		}
	}

	componentWillUnmount() {
		this.audioElement.pause()
		document.removeEventListener('keydown', this.handleKeyDown)
		document.removeEventListener('keyup', this.handleKeyUp)
		this.stopTimer()
		if(this.timeout){
			clearTimeout(this.timeout)
		}
		if(this.endedTimeout){
			clearTimeout(this.endedTimeout)
		}
	} 

	handleKeyDown(e){
		const inputElement= document.getElementById("soundTitleInputField")
		const isFocused = (document.activeElement === inputElement)
		if(!isFocused){
			if(e.keyCode ===32 && e.shiftKey){ //shift + spacebar
				this.seekTo(0)
			} 
			if(e.keyCode === 32){ //spacebar
				this.setState({spaceBarPressed:true})
				this.timeout=setTimeout(() => {
					if(this.state.spaceBarPressed){
						this.setState({spaceBarPressed:false})
						this.props.rerecordRecording('spacebarRecording')
					}
				}, SPACEBAR_RERECORD_DELAY)
			}
			if(e.keyCode===13){ //enter
				this.insertSound()
			}
		}else{
			 if(e.keyCode===13){//enter
				inputElement.blur()
			}
			if(e.keyCode === 27) { //escape
				inputElement.blur()
			}
		}
	}

	insertSound(){
		this.pauseSound()
		this.setState({submitLoading:true})
		const title=this.state.recordingTitle || "Untitled Recording"
		this.props.uploadToCloudinaryAndInsertSound(title,this.state.duration)
	}

	handleKeyUp(e){
		const inputElement= document.getElementById("soundTitleInputField")
		const isFocused = (document.activeElement === inputElement)
		if(e.keyCode === 32 && this.state.spaceBarPressed){
			this.setState({spaceBarPressed:false})  
			 if(this.timeout){
				clearTimeout(this.timeout)
			}
		}
		if(!isFocused){
			if(e.keyCode ===32 && !e.shiftKey){ //spacebar
				if(this.state.isPlaying){   
					this.pauseSound()
				}else{
					this.playSound()
				}
			}
		}  
	}

	updatePlayerCurrentTime(){
		const currentTime=this.audioElement.currentTime
		this.setState({playerCurrentTime:currentTime*1000})
	}

	startTimer(){
		this.timer = setInterval(() => {
			this.updatePlayerCurrentTime()
		}, 10)
	}

	stopTimer(){
	 clearInterval(this.timer)
	 this.timer=null
	}

	playSound(){ //play recording when get to review screen
		var promise = this.audioElement.play()
			if (promise !== undefined) {
				promise.then(_ => {
					// Autoplay started!
					this.setState({isPlaying:true})
					this.startTimer()
				}).catch(error => {
				// Autoplay was prevented.
				// Show a "Play" button so that user can start playback.
			});
		}
	}

	pauseSound(){
		this.setState({isPlaying:false})
		this.audioElement.pause()
		this.stopTimer()
	}

	restartAudio(){
		this.audioElement.currentTime=0
	}

	onInputFocus(){
		document.getElementById("soundTitleInputField").select()
		this.setState({titleInputFocused:true})
	}
	
	onInputBlur(){
		this.setState({titleInputFocused:false})
	}

	//user can click on circular progress bar to seek sound
	//work out click position and do some maths stuff to figure out % around the circle
	onProgressBarClick(e){ 
		var cursorX = e.pageX
		var cursorY = e.pageY
		const target=e.target
		const rect=target.getBoundingClientRect()
		const centerPointX=rect.left+0.5*rect.width
		const centerPointY=rect.top+0.5*rect.height
		const adjacentSideLength=cursorX-centerPointX
		const oppositeSideLength=cursorY-centerPointY
		let degree

		if(adjacentSideLength>0 && oppositeSideLength<0){
			degree = 90-Math.atan((-1*oppositeSideLength)/adjacentSideLength) * (180 / Math.PI)
		}else if(adjacentSideLength>0 && oppositeSideLength>0){
			degree = 90+Math.atan(oppositeSideLength/adjacentSideLength) * (180 / Math.PI)
		}else if(adjacentSideLength<0 && oppositeSideLength>0){
			degree = 270-Math.atan(oppositeSideLength/(-1*adjacentSideLength)) * (180 / Math.PI)
		}else if(adjacentSideLength<0 && oppositeSideLength<0){
			degree = 270+Math.atan((-1*oppositeSideLength)/(-1*adjacentSideLength)) * (180 / Math.PI)
		}
		const seekToTime=(degree/360)*this.state.duration/1000
		this.seekTo(seekToTime)
	}

	seekTo(time){
		this.audioElement.currentTime=time
		this.updatePlayerCurrentTime()
		if(this.endedTimeout){
			clearInterval(this.endedTimeout)
		}
		this.playSound()
	}


	render(){  
		const {recordingType}=this.props
		const {titleInputFocused, submitLoading} = this.state
		const formattedCurrentTime=formatRecordingDuration(this.state.playerCurrentTime)
		const formattedDuration=formatRecordingDuration(this.state.duration)  
		const percentageProgress=100*this.state.playerCurrentTime/this.state.duration
		const {uploadPercent} = this.props
		const uploadProgress = uploadPercent / 100
		const widthUploadProgressBar = 300
		const xTranslateOffset = widthUploadProgressBar * -1 * (1 - uploadProgress)
		return (      
			<div className={'editor-insertSoundModal editor-insertSoundModal--recordingReview ' + (submitLoading ? 'editor-insertSoundModal--recordingReview--submitLoading' : '')}>
				<div className='editor-insertSoundModal--recordingReview-header'>
					<div className='editor-insertSoundModal--recordingReview-header-leftContainer'>
						<button disabled={submitLoading} className='editor-insertSoundModal--recordingReview-header-backBtn' onClick={this.props.goBack}>
							Back
						</button>
					</div>
					<div className='editor-insertSoundModal--recordingReview-header-titleContainer'>
						<input 
							onFocus={this.onInputFocus}
							onBlur={this.onInputBlur}
							id='soundTitleInputField'                  
							className='editor-insertSoundModal--recordingReview-header-title'
							placeholder={"Untitled Recording"}
							value={this.state.recordingTitle}
							onChange={(e)=>{this.setState({recordingTitle:e.target.value})}}
							autoComplete="off"
							disabled={submitLoading}
						/>                       
						<div className='editor-insertSoundModal--recordingReview-header-title-tooltip'>
							The title of the sound clip is always hidden from students
						</div>
					</div>
					<div className='editor-insertSoundModal--recordingReview-header-rightContainer'>
						<button onClick={this.props.closeInsertSoundModal} className='editor-insertSoundModal--recordingReview-header-cancelBtn'>
							Cancel
						</button>
					</div>
				</div>
				<div className='editor-insertSoundModal--recordingReview-center'>
					<button disabled={submitLoading} onClick={()=>{this.seekTo(0)}} className='editor-insertSoundModal--recordingReview-center-playFromStartBtn'>            
						<div className='editor-insertSoundModal--recordingReview-center-playFromStartBtn-circle'/>
					</button>
					<div className='editor-insertSoundModal--recordingReview-center-leftContainer'>
						<div className='editor-insertSoundModal--recordingReview-center-timestamps'>
							<div className='editor-insertSoundModal--recordingReview-center-timestamps-current'>
								{formattedCurrentTime}
							</div>  
							<div className='editor-insertSoundModal--recordingReview-center-timestamps-total'>
								{formattedDuration}
							</div>  
						</div>
					</div>              
					<div className='editor-insertSoundModal--recordingReview-center-player'>                                            				
						{this.state.isPlaying &&
							<button disabled={submitLoading}  onClick={this.pauseSound} className='editor-insertSoundModal--recordingReview-center-player-playPauseBtn editor-insertSoundModal--recordingReview-center-player-playPauseBtn--playing'>               
								<EditorIcon name='playbackPause' />
							</button>
						}
						{!this.state.isPlaying &&
							<button disabled={submitLoading} onClick={this.playSound} className='editor-insertSoundModal--recordingReview-center-player-playPauseBtn'>                
								<EditorIcon name='playbackPlay' />
							</button>
						}                  
						<div disabled={submitLoading} onClick={this.onProgressBarClick} className='editor-insertSoundModal--recordingReview-center-player-progressBarContainer'>
							<CircularProgressbar value={percentageProgress} strokeWidth={8} />
						</div>              
					</div>               
					<div className='editor-insertSoundModal--recordingReview-center-rightContainer'>            
						{recordingType === 'spacebarRecording' && !titleInputFocused &&
							<div className='editor-insertSoundModal--recordingReview-center-reRecordTooltip'>  
								<EditorIcon name='microphone' />
								<div className='editor-insertSoundModal--recordingReview-center-reRecordTooltip-label'>  
									Hold Spacebar<br/>to re-record
								</div>
							</div>
						}
						{recordingType === 'clickRecording' &&
							<button disabled={submitLoading} onClick={()=>{this.props.rerecordRecording('clickRecording')}} className='editor-insertSoundModal--recordingReview-center-reRecordBtn'>  
								<EditorIcon name='microphone' />
								<div className='editor-insertSoundModal--recordingReview-center-recordTooltip'>  
									Re-record
								</div>
							</button>
						}
					</div>
				</div>
				<div className='editor-insertSoundModal--recordingReview-footer'>              
					<div className='editor-insertSoundModal--recordingReview-footer-leftContainer'>
						{/* MAYBE ADD LATER TBC 
						<button className='editor-insertSoundModal--recordingReview-footer-editBtn'>
							Edit Clip
						</button>
					*/}
					</div>				
					<button disabled={submitLoading} onClick={this.insertSound} className='editor-insertSoundModal--recordingReview-footer-doneBtn'>							
						{submitLoading &&
							<div style={{width: `${widthUploadProgressBar}px`}} className='editor-insertSoundModal--recordingReview-footer-doneBtn-uploadProgressBar'>                    
								<Spring to={{xTranslate: uploadProgress ? xTranslateOffset : 0 }}>
									{props => 
										<div 
											style={{transform: `translateX(${props.xTranslate}px)`}}
											className='editor-insertSoundModal--recordingReview-footer-doneBtn-uploadProgressBar-bar'
										/>
									}                         
								</Spring>
								<div className='editor-insertSoundModal--recordingReview-footer-doneBtn-uploadProgressBar-rail' />
							</div>
						}							
						{!submitLoading &&
							<React.Fragment>
								<div className='editor-insertSoundModal--recordingReview-footer-doneBtn-primary'>
									Insert Sound Clip
								</div>										
								<div className='editor-insertSoundModal--recordingReview-footer-doneBtn-sub'>
									{!titleInputFocused &&
										<div className='editor-insertSoundModal--recordingReview-footer-doneBtn-sub-key'>
											Enter
											<Icon name='enterKey' />
										</div>
									}
								</div>
							</React.Fragment>
						}                
					</button>
					<div className='editor-insertSoundModal--recordingReview-footer-rightContainer'>
						 {/* MAYBE ADD LATER TBC 
						<button className='editor-insertSoundModal--recordingReview-footer-insertAndNextChoice'>
							Insert and go to B
						</button>
						*/}
					</div>
				</div>
			</div>								
		)
	}
}

export default EditorInsertSoundRecordingReviewPage

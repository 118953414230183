import React, { Component } from 'react'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import Icon from '../misc/Icons'
import QuestionCountBadge from '../uiKit/QuestionCountBadge'
import StaticQuestionSlide from '../slides/StaticQuestionSlide'
import {getItemForPoll} from '../../utils/getItemForPoll'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'

//Bar at the bottom on live view that shows on hover

const CLOSE_NAVIGATION_BAR_DELAY=1000

class SetNavigatorItem extends React.Component{
	render(){
		const{currentQuestionIndex,question,index}=this.props
		return(
			<div 
				className={'liveView-controlBar-setNavigator-item ' + 
					(currentQuestionIndex===index ? ' liveView-controlBar-setNavigator-item--currentQuestion ' : '') +
					(currentQuestionIndex>index ? ' liveView-controlBar-setNavigator-item--hasResponses ' : '') +
					(currentQuestionIndex<index ? ' liveView-controlBar-setNavigator-item--noResponses ' : '')}
				onClick={this.props.playQuestionInSet}
			>
				<div className='liveView-controlBar-setNavigator-item-tab' />
				<div className='liveView-controlBar-setNavigator-item-preview'>
					 <StaticQuestionSlide question={question} stage='ask' mode='view' displayWidth={256} previewCard={true} hideSoundTitles />
				</div>
			</div>
		)}
}

class PopoverWrapper extends React.Component{
	render(){
		return(			
			<div className='liveView-controlBar-popoverContainer' style={{width: `${this.props.width}px`}}>
				<div className={'liveView-controlBar-popover ' + (this.props.type ? `liveView-controlBar-popover--${this.props.type}` : '')}>
				
					{this.props.children}
					
				</div>
			</div>
		)
	}
}

class QueuePopoverItem extends React.Component{

	render(){
		const {playPoll,poll}=this.props
		let title=''
		let previewQuestion
		let isSet=false
		const item=getItemForPoll(poll)
		if(item){
			if(item.questions){
				title=item.name
				previewQuestion=item.questions[0]
				isSet=true

			}else{
				title=item.body
				previewQuestion=item
			}
			return(				
				<div className='liveView-controlBar-popover--queue-queueItem--container' onClick={()=>{playPoll(poll,item)}}>
					<div className='liveView-controlBar-popover--queue-queueItem'>
						<div className='liveView-controlBar-popover--queue-queueItem-slidePreview'>

						 <StaticQuestionSlide question={previewQuestion} stage='ask' mode='view' displayWidth={94} previewCard={true} />
							{isSet &&
							<QuestionCountBadge questionCount={item.questions.length} />
							}
						</div>
						<div className='liveView-controlBar-popover--queue-queueItem--right'>
							<div className='liveView-controlBar-popover--queue-queueItem-title'>
								{title}
							</div>
							<div className='liveView-controlBar-popover--queue-queueItem-playLabel'>
								Play Now
							</div>
						</div>
					</div>
				</div>

			)
		}
		else return null
	}
}


class QueuePopover extends React.Component{

	render(){
		return(
			<PopoverWrapper type='queue' width={328}>								
								
				<PerfectScrollbar 
						option={{
									handlers:['click-rail', 'drag-thumb', 'wheel', 'touch'],
									suppressScrollX: true
						}}		    	
					>

					{this.props.queue.length >0 &&
					<div className='liveView-controlBar-popover--queue-listSubtitle'>
						Up Next
					</div>
					}					
					{this.props.queue.map((poll,index) => {	
						if(index===1){
							return[
								<div className='liveView-controlBar-popover--queue-listSubtitle' key='upcoming'>
								Upcoming
								</div>,						
								<QueuePopoverItem
									key={poll.id}
									playPoll={this.props.playQueuePoll}
									poll={poll}
								/>
							]}
						else return (
							<QueuePopoverItem
								key={poll.id}
								playPoll={this.props.playQueuePoll}
								poll={poll}
							/>
						)
					})}	
				
				</PerfectScrollbar>
				
				
			</PopoverWrapper>
		)}
}




class LiveViewScreenNavigationBar extends Component {

	constructor(){
		super()
		this.playQueuePoll=this.playQueuePoll.bind(this)
		this.mouseOverControlBarHoverArea=this.mouseOverControlBarHoverArea.bind(this)
		this.delayedCloseNavigationBar=this.delayedCloseNavigationBar.bind(this)

		this.closeNavigationBarTimeout=null 

		this.state=({
			showQueuePopover:false,
			mouseOverControlBarHoverArea:false
		})
	}


	mouseOverControlBarHoverArea(){		
		this.setState({mouseOverControlBarHoverArea:true})
		if(this.closeNavigationBarTimeout){
			clearTimeout(this.closeNavigationBarTimeout)
		}
	}

	delayedCloseNavigationBar(){
		this.closeNavigationBarTimeout = setTimeout(function(){ 
			this.setState({mouseOverControlBarHoverArea:false})
		}.bind(this), CLOSE_NAVIGATION_BAR_DELAY)
	}

	playQueuePoll(poll,item){
		this.setState({showQueuePopover:false})
		this.props.playPoll(poll,item)
	}

	render() {
		const {questionsAndPollsList,currentSetPoll,section,currentQuestionIndex,fullScreen,playQuestionInSet} = this.props
		const {showQueuePopover,mouseOverControlBarHoverArea}=this.state
		let students=[]
		let activeStudentCount=0
		if(section){
				students=sortBy(filter(section.students,{'archived':false}), [function(o) { 
							return o.displayName.toString().toLowerCase()
			}])
		}
		if(section){
			activeStudentCount = students.length
		}
		const showNavigationBar = mouseOverControlBarHoverArea && !this.props.mediaZoomed
		return (			
			 <div id="liveViewControlBarHoverArea" className='liveView-controlBarContainer' onMouseOver={this.mouseOverControlBarHoverArea}  onMouseLeave={this.delayedCloseNavigationBar}>
				 <div className={'liveView-controlBar' + 
						(questionsAndPollsList ? ' liveView-controlBar--playingSet ' : ' liveView-controlBar--playingIndependentQuestion ') + 
						(showNavigationBar ? ' liveView-controlBar--show' : ' liveView-controlBar--hide')
					}>

				 {questionsAndPollsList &&
					<div className='liveView-controlBar-setNavigator'>
						 {questionsAndPollsList.map((questionAndPoll,index) => {						
							const question=questionAndPoll.questionSnapshot
							return(
								<SetNavigatorItem 
									playQuestionInSet={()=>{playQuestionInSet(questionAndPoll)}}
									question={question} 
									index={index} 
									key={index}
									currentQuestionIndex={currentQuestionIndex}
								/>											 
							)
						})}
					</div>
					}
						
						<div className='liveView-controlBar-bar'>
							
							<div className='liveView-controlBar-bar-setTitle'>
								{currentSetPoll && 	currentSetPoll.snapshot &&			
									<React.Fragment>
										{currentSetPoll.snapshot.name}
									</React.Fragment>
								}
							</div>				
							{section && 
								<React.Fragment>								
									{activeStudentCount > 0 &&
									<div className='liveView-controlBar-bar-btn liveView-controlBar-bar-btn--cardAssignment' onClick={this.props.showCardAssignmentView}>
										<div className='liveView-controlBar-bar-btn--cardAssignment-classCount'>
											{activeStudentCount}
										</div>
										<Icon name='card-assignment' />
									</div>
									}
								</React.Fragment>
							}
							{section && 
								<div className={'liveView-controlBar-bar-btn liveView-controlBar-bar-btn--queue ' + (this.props.queue.length === 0 ? 'liveView-controlBar-bar-btn--disabled' : '')} onMouseEnter={()=>{this.setState({showQueuePopover:true})}} onMouseLeave={()=>{this.setState({showQueuePopover:false})}}>
									<Icon name='queue' />
									{showQueuePopover &&
										<QueuePopover {...this.props} playQueuePoll={this.playQueuePoll}/>
									}
								</div>
							}

							{!fullScreen &&
							<div className='liveView-controlBar-bar-btn liveView-controlBar-bar-btn--enterFS' onClick={this.props.launchFullscreen}>
								<Icon name='enterFS' />
							</div>
							}

							{fullScreen &&
							<div className='liveView-controlBar-bar-btn liveView-controlBar-bar-btn--exitFS' onClick={this.props.exitFullscreen}>
								<Icon name='exitFS' />
							</div>
							}
						</div>
					</div>			
			</div>
		)
	}
}


export default LiveViewScreenNavigationBar


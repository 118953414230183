import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {showLiveViewModal} from '../../utils/liveView/showLiveViewModal'
import {playItem} from '../../utils/playItem'
import SectionSelection from '../../components/modals/SectionSelection'

class SectionSelectionContainer extends Component {

	constructor(){
		super()
		this.playForSection=this.playForSection.bind(this)
	}

	playForSection(sectionId){
		this.props.hideModal()
		this.props.playItem(this.props.itemToPlay,sectionId)
		showLiveViewModal()
	}

	render() {
		return (    
			<SectionSelection sections={this.props.sections} item={this.props.itemToPlay} hideModal={this.props.hideModal} playForSection={this.playForSection}/>
		)
	}
}

export default withRouter(connect(
	(state) => ({
		sections:state.sections,
		itemToPlay:state.modals.itemToPlay
	}),
	{ playItem}
)(SectionSelectionContainer))


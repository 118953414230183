import {getActiveQuestion} from '../getActiveQuestion'
import {SetAttrsStep} from '../SetAttrsStep'

export function updateQuestionFontSizes(bodyFontSize,choiceFontSize){
	let tr = window.view.state.tr
	const activeQuestion=getActiveQuestion()
	const {questionPos,questionNode}=activeQuestion
	tr.step(new SetAttrsStep(questionPos, {...questionNode.attrs,bodyFontSize:bodyFontSize,choiceFontSize:choiceFontSize}))

	window.view.state.doc.nodesBetween(questionPos,questionPos+questionNode.nodeSize, (questionChildNode, questionChildPos) => {
		if(questionChildNode.type.name==='questionBody') {
			tr.step(new SetAttrsStep(questionChildPos,{...questionChildNode.attrs,fontSize:bodyFontSize}))
		}
		if(questionChildNode.type.name==='choice') {
			tr.step(new SetAttrsStep(questionChildPos,{...questionChildNode.attrs,fontSize:choiceFontSize}))
		}
	})
	tr.setMeta('addToHistory', false) //can not undo setting font size      
	window.view.dispatch(tr)
}

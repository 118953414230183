import React from 'react'
import loadable from '@loadable/component'
const IconSVG = loadable(() => import(/*webpackChunkName: 'icons'*/"./IconsSVG"))

class Icon extends React.Component{
  render(){
    return(
      <IconSVG name={this.props.name} />        
    )
  }
}

export default Icon

export function analyticsTrackSkipMediaCommand(control,command) {
	if(window.analytics){		
		const snapshot=control.snapshot
		const playbackItem=control.playback.item
		if(snapshot){
			let mediaType
			if(playbackItem==='promptMedia' && snapshot.media){
				mediaType=snapshot.media.type
			}
			else if(playbackItem==='choiceA' && snapshot.choices[0] && snapshot.choices[0].media){
				mediaType=snapshot.choices[0].media.type
			}	else if(playbackItem==='choiceB' && snapshot.choices[1] && snapshot.choices[1].media){
				mediaType=snapshot.choices[1].media.type
			}else if(playbackItem==='choiceC' && snapshot.choices[2] && snapshot.choices[2].media){
				mediaType=snapshot.choices[2].media.type
			}else if(playbackItem==='choiceD' && snapshot.choices[3] && snapshot.choices[3].media){
				mediaType=snapshot.choices[3].media.type
			}

			let skipDirection='forward'
			if(command.command==='skip back'){
				skipDirection='back'
			}
			window.analytics.track('Mobile skip NP media',{
				playbackItem:playbackItem,
				mediaType:mediaType,
				skipDirection:skipDirection
			})	
		}
	}
}








import React, { useState } from 'react'
import { connect } from 'react-redux'
import { updateExternalSignin } from '../../../actions/user'
import ReauthenticationModal from '../../../components/reauthenticationModal/ReauthenticationModal'
import { hideModal } from '../../../actions/modals'
import { showNotification } from '../../../actions/notifications'
import FeatureModalSelectControl from '../../../components/featureModals/FeatureModalSelectControl'
import Button from '../../../components/uiKit/Button'
import SelectExternalAccountComponent from '../../../components/modals/account/SelectExternalAccountComponent'
import handleAccountRequest from '../../account/utils/handleAccountRequest'

function UpdateGoogleContainer(props) {
  const {
    user, hideModal, showNotification, updateExternalSignin,
  } = props

  const [showReauthenticationModal, setShowReauthenticationModal] = useState(false)
  const [shouldLogOut, setShouldLogOut] = useState(false)
  const [newGoogleAccount, setNewGoogleAccount] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  function onAuthenticate(authentication) {
    const requestData = {
      type: 'google',
      currentIdToken: authentication.idToken,
      newIdToken: newGoogleAccount.idToken,
    }
    handleAccountRequest({
      setSubmitLoading,
      setErrorMessage,
      makeRequest: () => updateExternalSignin(user.id, requestData, shouldLogOut),
      hideModal,
      showSuccessNotification: () => showNotification('your Google account', 'Updated', 'default'),
      requestName: 'updateGoogleSignin',
    })
  }

  return (
    <div className='accountAuthentication accountAuthentication--update '>
      <SelectExternalAccountComponent
        title='Select a new Google account'
        externalAccount={newGoogleAccount}
        setExternalAccount={setNewGoogleAccount}
        externalAccountType='google'
      />

      <div className='accountAuthentication-actionRow'>
        <Button
          size='xLarge'
          color='blue'
          label='Update'
          onClickFunction={() => setShowReauthenticationModal(true)}
          disabled={!newGoogleAccount}
          submitLoading={submitLoading}
        />
      </div>

      <div className='accountAuthentication-signOutEverywhere'>
        <FeatureModalSelectControl
          checkbox
          checked={shouldLogOut}
          onClick={() => setShouldLogOut(!shouldLogOut)}
          label='Require all devices to sign in again'
        />
      </div>

      {errorMessage && (
      <div className='accountAuthentication-error'>
        {errorMessage}
      </div>
      )}

      {showReauthenticationModal && (
        <ReauthenticationModal
          title='Change Google Account'
          signinMethodsToDisplay={{ google: true }}
          onAuthenticate={(authentication) => onAuthenticate(authentication)}
          hideModal={() => setShowReauthenticationModal(false)}
        />
      )}
    </div>
  )
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  {
    updateExternalSignin,
    hideModal,
    showNotification,
  },
)(UpdateGoogleContainer)

import React from 'react'
import Button from '../uiKit/Button'
import FeatureModalSelectControl from '../featureModals/FeatureModalSelectControl'

const regexHasConsecutiveUnderscores = new RegExp('[_]{2,}')
const regexHasEndingUnderscore = new RegExp('.*_$')

const regexValidCharacters = new RegExp('^[a-zA-Z]?([a-zA-Z0-9]|(_))+[a-zA-Z0-9]?$')

const transitionDelay = 500

class CreatorProgramUsernamePage extends React.Component {
  constructor(props) {
    super(props)
    this.saveUsername = this.saveUsername.bind(this)
    this.onChangeUsername = this.onChangeUsername.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)

    this.state = {
      username: '',
      saveUsernameError: null,
      consecutiveUnderscoresError: false,
      endingUnderscoreError: false,
      specialCharactersError: false,
      submitLoading: false,
      confirmChecked: false,
      feedbackChecked: false,
    }
  }

  componentDidMount() {
    if (window.analytics) {
      window.analytics.page('Join Creator Program', {
        stage: 'username',
      })
    }
  }

  handleKeyDown(e) {
    const {
      feedbackChecked,
      confirmChecked,
      username,
      consecutiveUnderscoresError,
      endingUnderscoreError,
      specialCharactersError,
    } = this.state
    if (e.keyCode === 13) {
      if (
        feedbackChecked &&
        confirmChecked &&
        username &&
        !consecutiveUnderscoresError &&
        !endingUnderscoreError &&
        !specialCharactersError
      ) {
        this.saveUsername()
      }
    }
  }

  onChangeUsername(value) {
    let stringWithoutSpaces = value.replace(/\s/g, '')
    stringWithoutSpaces = stringWithoutSpaces.toLowerCase()

    this.setState({ username: stringWithoutSpaces, saveUsernameError: null })
    const hasConsecutiveUnderscores = regexHasConsecutiveUnderscores.test(stringWithoutSpaces)
    const hasEndingUnderscore = regexHasEndingUnderscore.test(stringWithoutSpaces)
    const hasSpecialCharacters = !(regexValidCharacters.test(stringWithoutSpaces) || value === '')
    this.setState({
      consecutiveUnderscoresError: hasConsecutiveUnderscores,
      endingUnderscoreError: hasEndingUnderscore,
      specialCharactersError: hasSpecialCharacters,
    })
  }

  saveUsername() {
    this.setState({ submitLoading: true })
    const updatedUserObject = { ...this.props.user }
    updatedUserObject.username = this.state.username
    const nowDate = new Date()
    updatedUserObject.clientModified = nowDate

    this.props.testUsername(this.state.username)
      .then(() => {
        this.setState({ saveUsernameError: 'usernameTaken' })
        this.setState({ submitLoading: false })
      })
      .catch(() => {
        this.props.updateUser(updatedUserObject, this.props.user.id)
          .then(() => {
            if (window.analytics) {
              window.analytics.track('Joined Creator Program', {
                username: this.state.username,
                feedbackChecked: this.state.feedbackChecked,
              })
            }
            setTimeout(() => { // Start the timer
              this.props.goToNextPage()
              this.setState({ submitLoading: false })
            }, transitionDelay)
          })
          .catch(() => {
            this.setState({ saveUsernameError: 'blacklistedUsername' })
            this.setState({ submitLoading: false })
          })
      })
  }

  render() {
    const { saveUsernameError, confirmChecked, feedbackChecked } = this.state

    const minUsernameLength = 4
    const maxUsernameLength = 15

    let disableSubmit = false

    if (
      !this.state.username ||
      this.state.consecutiveUnderscoresError ||
      this.state.endingUnderscoreError ||
      this.state.specialCharactersError ||
      (this.state.username && this.state.username.length < minUsernameLength) ||
      (this.state.username && this.state.username.length > maxUsernameLength)
    ) {
      disableSubmit = true
    }

    return (
      <React.Fragment>
        <div className="joinCreatorProgramModal-header">
          Become a Plickers Creator
        </div>
        <div className="joinCreatorProgramModal-subheader">
          To become a Creator you need... a Creator username!
        </div>

        <div className="joinCreatorProgramModal-input">

          <input
            type="text"
            value={this.state.username}
            className="joinCreatorProgramModal-input-input"
            onChange={(e) => { this.onChangeUsername(e.target.value) }}
            onKeyDown={this.handleKeyDown}
            maxLength={30} // let people go over a bit
            placeholder="Your username"
          />
          {this.state.username && (
            <div className={`joinCreatorProgramModal-input-characterCounter ${this.state.username.length > maxUsernameLength ? ' joinCreatorProgramModal-input-characterCounter--aboveMax' : ''}`}>
              {this.state.username.length}
              /
              {maxUsernameLength}
            </div>
          )}

          {this.state.consecutiveUnderscoresError && (
            <div className="joinCreatorProgramModal-input-instructionsLabel--error">
              Consecutive underscores are not allowed.
            </div>
          )}

          {this.state.endingUnderscoreError && (
          <div className="joinCreatorProgramModal-input-instructionsLabel--error">
            Ending in underscore is not allowed.
          </div>
          )}

          {this.state.specialCharactersError && (
            <div className="joinCreatorProgramModal-input-instructionsLabel--error">
              Only letters, numbers and underscores allowed.
            </div>
          )}

          {saveUsernameError === 'usernameTaken' && (
            <div className="joinCreatorProgramModal-error">
              Unfortunately that username is already taken.
            </div>
          )}
          {saveUsernameError === 'blacklistedUsername' && (
            <div className="joinCreatorProgramModal-error">
              Unfortunately that username is not available.
            </div>
          )}

          {
            !saveUsernameError &&
            !this.state.specialCharactersError &&
            !this.state.consecutiveUnderscoresError &&
            !this.state.endingUnderscoreError &&
            (
              <React.Fragment>
                <div className="joinCreatorProgramModal-input-instructionsLabel">
                  Letters, numbers and underscores allowed.
                </div>
                {this.state.username && (
                  <div className="joinCreatorProgramModal-input-instructionsLabel">
                    Your unique Plickers URL will be
                    {' '}
                    {process.env.REACT_APP_SIMPLE_URL}
                    /
                    {this.state.username}
                  </div>
                )}
                {
                  this.state.username &&
                  (
                    (this.state.username.length < minUsernameLength) ||
                    (this.state.username.length > maxUsernameLength)
                  ) &&
                  (
                    <div className="joinCreatorProgramModal-input-instructionsLabel">
                      Your username can be between
                      {' '}
                      {minUsernameLength}
                      {' '}
                      and
                      {maxUsernameLength}
                      {' '}
                      characters.
                    </div>
                  )
                }

              </React.Fragment>
            )
          }
        </div>

        <div className="joinCreatorProgramModal-submitArea">
          {!disableSubmit && (
          <React.Fragment>
            <div className="joinCreatorProgramModal-submitArea-primaryConfirm">
              <FeatureModalSelectControl
                checkbox
                checked={confirmChecked}
                onClick={() => (this.setState({ confirmChecked: !confirmChecked }))}
                label={(
                  <React.Fragment>
                    I accept the&nbsp;
                  </React.Fragment>
                )}
              />
              <a target="_blank" rel="noopener noreferrer" href="https://help.plickers.com/hc/en-us/articles/360009090913-Terms-of-Service">Terms of Service</a>
              ,&nbsp;
              <a target="_blank" rel="noopener noreferrer" href="https://help.plickers.com/hc/en-us/articles/360009090833-Plickers-Privacy-Policy">Privacy Policy</a>
              , and&nbsp;
              <a target="_blank" rel="noopener noreferrer" href="https://help.plickers.com/hc/en-us/articles/360038320453-Content-Policy">Content Policy</a>
              .
            </div>
            <div className="joinCreatorProgramModal-submitArea-feedbackConfirm">

              <FeatureModalSelectControl
                checkbox
                checked={feedbackChecked}
                onClick={() => (this.setState({ feedbackChecked: !feedbackChecked }))}
                label="I'm happy for Plickers to contact me for my feedback."
              />

            </div>
          </React.Fragment>
          )}

          <Button
            submitLoading={this.state.submitLoading}
            size="large"
            color={(disableSubmit || !(this.state.confirmChecked && this.state.feedbackChecked)) ? 'gray' : 'blue'}
            label="Become a Creator"
            name="submit"
            onClickFunction={this.saveUsername}
            disabled={disableSubmit || !(this.state.confirmChecked && this.state.feedbackChecked)}
          />

          <Button
            size="large"
            color="white"
            label="I don't want to join the program right now"
            name="cancel"
            onClickFunction={this.props.hideModal}
          />

        </div>

      </React.Fragment>

    )
  }
}

export default CreatorProgramUsernamePage

import sortBy from 'lodash/sortBy'


export function sortStudentList(students,studentsWithTotals,sortedBy,sortOrder) {
	let sortedStudents=[]
	if(sortedBy==='displayName'){
		sortedStudents=sortBy(students, [function(student) { 
			return student.displayName.toString().toLowerCase()
		}])

		if(sortOrder !=='ascending'){
			sortedStudents.reverse()
		}
	}
	else if(sortedBy==='score'){
		sortedStudents=sortBy(students, [function(student) { 
			const studentTotals=studentsWithTotals[student.id]
			let studentScore=0

			if(studentTotals.totalResponses!==0){
				studentScore=studentTotals.totalCorrect/studentTotals.totalResponses
			}else if(sortOrder ==='ascending'){
				studentScore=-1 //to ensure absent students are always at the bottom of sorted list
			
			}else if(sortOrder ==='descending'){
				studentScore=101		
			}
			return studentScore
		}])


		if(sortOrder ==='ascending'){
			sortedStudents.reverse()
		}
	}

	else if(sortedBy==='card'){
		sortedStudents=sortBy(students, [function(student) { 
			let cardNumber=student.card || 100
			if(cardNumber ===-1 && sortOrder==='ascending'){ //so archived students always appear after active students
				cardNumber=100
			}
			return cardNumber
		}])


		if(sortOrder !=='ascending'){
			sortedStudents.reverse()
		}
	}else if(sortedBy==='firstName'){
		sortedStudents=sortBy(students, [function(student) { 
			return student.firstName.toString().toLowerCase()
		}])

		if(sortOrder !=='ascending'){
			sortedStudents.reverse()
		}
	}
	else if(sortedBy==='lastName'){
		sortedStudents=sortBy(students, [function(student) { 
			return student.lastName.toString().toLowerCase()
		}])

		if(sortOrder !=='ascending'){
			sortedStudents.reverse()
		}
	}

	return sortedStudents
}

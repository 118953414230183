module.exports = [
 //{'name':'multiply','string':'\u{00D7}',spaceAfter:true}, //
//{'name':'multiply','string':'\u{00D7}'}, //
//{'name':'divide','string':'\u{00F7}'}, //

	// GENERAL
	{'name':'degree',								'string':'\u{00B0}', 	'escapedString':'\\u{00B0}'}, // °
	{'name':'hash',									'string':'\u{0023}', 	'escapedString':'\\u{0023}'}, // #
	{'name':'ampersand',						'string':'\u{0026}', 	'escapedString':'\\u{0026}', altNames:['and']}, // &
	{'name':'asterix',							'string':'\u{002A}', 	'escapedString':'\\u{002A}', altNames:['star']}, // *
	{'name':'arrow',								'string':'\u{2192}', 	'escapedString':'\\u{2192}', spaceAfter:true}, // →
	{'name':'long-arrow',						'string':'\u{27F6}', 	'escapedString':'\\u{27F6}', spaceAfter:true, altNames:['arrow-long']}, // →
	{'name':'long-arrow-reverse',		'string':'\u{2190}', 	'escapedString':'\\u{2190}', spaceAfter:true, altNames:['arrow-long-reverse']}, // ←
	{'name':'reversible-arrow',			'string':'\u{21CB}', 	'escapedString':'\\u{21CB}', spaceAfter:true, altNames:['arrow-reversible']}, // ⇋
	{'name':'tilde',								'string':'\u{007E}', 	'escapedString':'\\u{007E}', altNames:['approx']}, // ~
	{'name':'left-square-brackets',	'string':'\u{005B}', 	'escapedString':'\\u{007E}', altNames:['brackets-square-left','leftsquarebrackets','leftbracketssquare']}, // [
	{'name':'right-square-brackets','string':'\u{005D}', 	'escapedString':'\\u{007E}', altNames:['brackets-square-right','rightsquarebrackets','rightbracketssquare']}, // ]
	{'name':'left-curly-bracket',		'string':'\u{007B}', 	'escapedString':'\\u{007E}', altNames:['brackets-curly-left', 'leftcurlybrackets','leftbracketscurly']}, // {
	{'name':'right-curly-bracket',	'string':'\u{007D}', 	'escapedString':'\\u{007D}', altNames:['brackets-curly-right', 'rightcurlybrackets','rightbracketscurly']}, // }
	{'name':'blank',								'string':'＿＿＿', 			'escapedString':'＿＿＿', 'previewSymbol': '___'}, // ___	
	{'name':'long-blank',						'string':'＿＿＿＿＿＿', 	'escapedString':'＿＿＿＿＿＿', 'previewSymbol': '____', altNames:['blank-long']},
	{'name':'box',									'string':'\u{25A2}', 		'escapedString':'\\u{25A2}', altNames:['square','blank','empty']}, // •
	{'name':'bullet',								'string':'\u{2022}', 	'escapedString':'\\u{2022}', altNames:['dot']}, // •
	{'name':'trademark',						'string':'\u{2122}', 	'escapedString':'\\u{2122}', altNames:['tm']}, // ™
	{'name':'copyright',						'string':'\u{00A9}', 'escapedString':'\\u{00A9}'}, 	// ©
	{'name':'celsius',							'string':'\u{00B0}C','escapedString':'\\u{00B0}C'},	// °C
	{'name':'farenheit',						'string':'\u{00B0}F','escapedString':'\\u{00B0}F'},	// °F
	{'name':'sharp',								'string':'\u{266F}', 'escapedString':'\\u{266F}'},	// Flat
	{'name':'flat',									'string':'\u{266D}', 'escapedString':'\\u{266D}'},	// Sharp
	//{'name':'en-dash',							'string':'\u{2013}', 'escapedString':'\\u{2013}', altNames:['dash-en']},	// En Dash
	//{'name':'em-dash',							'string':'\u{2014}', 'escapedString':'\\u{2014}', altNames:['dash-em']},	// Em Dash

	// MATH
	{'name':'multiply',									'string':'\u{00D7}', 'escapedString':'\\u{00D7}', spaceAfter:true, altNames:['times']},
	{'name':'divide',										'string':'\u{00F7}', 'escapedString':'\\u{00F7}', spaceAfter:true},
	{'name':'add',											'string':'\u{002B}', 'escapedString':'\\u{002B}', spaceAfter:true, altNames:['plus']},
	{'name':'subtract',									'string':'\u{2212}', 'escapedString':'\\u{2212}', spaceAfter:true, altNames:['minus']},
	{'name':'equals',										'string':'\u{003D}', 'escapedString':'\\u{003D}', spaceAfter:true},
	{'name':'percent',									'string':'\u{0025}', 'escapedString':'\\u{0025}', spaceAfter:true},
	{'name':'plus-minus',								'string':'\u{00B1}', 'escapedString':'\\u{00B1}'},
	{'name':'square-root',							'string':'\u{221A}', 'escapedString':'\\u{221A}', altNames:['root']},
	{'name':'cube-root',								'string':'\u{221B}', 'escapedString':'\\u{221B}'},
	{'name':'infinity',									'string':'\u{221E}', 'escapedString':'\\u{221E}'},
	{'name':'multiply-dot',							'string':'\u{00B7}', 'escapedString':'\\u{00B7}', spaceAfter:true, altNames:['multiply', 'dot']},
	{'name':'not-equal-to',							'string':'\u{2260}', 'escapedString':'\\u{2260}', altNames:['equal-not-to']},
	{'name':'asymptotic-to',						'string':'\u{2248}', 'escapedString':'\\u{2248}', altNames:['approx']},
	{'name':'identical-to',							'string':'\u{2261}', 'escapedString':'\\u{2261}', altNames:['equal-exactly-to', 'identity']},
	{'name':'less-than',								'string':'\u{003C}', 'escapedString':'\\u{003C}'},
	{'name':'greater-than',							'string':'\u{003E}', 'escapedString':'\\u{003E}', altNames:['more-than']},
	{'name':'less-than-or-equal-to',		'string':'\u{2264}', 'escapedString':'\\u{2264}'},
	{'name':'greater-than-or-equal-to',	'string':'\u{2265}', 'escapedString':'\\u{2265}', altNames:['more-than-or-equal-to']},	
	{'name':'much-less-than',						'string':'\u{226A}', 'escapedString':'\\u{226A}', altNames:['less-than-much', 'muchlessthan']},
	{'name':'much-greater-than',				'string':'\u{226B}', 'escapedString':'\\u{226B}', altNames:['greater-than-much', 'muchgreaterthan']},
	{'name':'prime',										'string':'\u{2032}', 'escapedString':'\\u{2032}'},
	{'name':'double-prime',							'string':'\u{2033}', 'escapedString':'\\u{2033}', altNames:['prime-double']},
	{'name':'triple-prime',							'string':'\u{2034}', 'escapedString':'\\u{2034}', altNames:['prime-triple']},
	{'name':'integral',									'string':'\u{222B}', 'escapedString':'\\u{222B}'},
	{'name':'partial-differential',			'string':'\u{2202}', 'escapedString':'\\u{2202}'},
	{'name':'increment',								'string':'\u{2206}', 'escapedString':'\\u{2206}'},
	{'name':'double-struck-capital-r',	'string':'\u{211D}', 'escapedString':'\\u{211D}'},
	{'name':'double-struck-capital-z',	'string':'\u{2124}', 'escapedString':'\\u{2124}'},
	{'name':'double-struck-capital-n',	'string':'\u{2115}', 'escapedString':'\\u{2115}'},
	{'name':'element-of',								'string':'\u{2208}', 'escapedString':'\\u{2208}'},
	{'name':'vertical-line',						'string':'\u{007C}', 'escapedString':'\\u{007C}'},
	{'name':'intersection ',						'string':'\u{2229}', 'escapedString':'\\u{2229}'},
	{'name':'union',										'string':'\u{222A}', 'escapedString':'\\u{222A}'},
	//{'name':'not-less-than',					'string':'\u{226E}'}, // DOESN'T WORK IN LATO
	//{'name':'not-greater-than',				'string':'\u{226F}'}, // DOESN'T WORK IN LATO

	

	// MATH LETTERS
	// lowercase
	{'name':'a-math','string':'\u{1D44E}', 	'escapedString':'\\u{1D44E}', altNames:['math-a', 'amath', 'matha',]},
	{'name':'b-math','string':'\u{1D44F}', 	'escapedString':'\\u{1D44F}', altNames:['math-b', 'bmath', 'mathb',]},
	{'name':'c-math','string':'\u{1D450}', 	'escapedString':'\\u{1D450}', altNames:['math-c', 'cmath', 'mathc',]},
	{'name':'d-math','string':'\u{1D451}', 	'escapedString':'\\u{1D451}', altNames:['math-d', 'dmath', 'mathd',]},
	{'name':'e-math','string':'\u{1D452}', 	'escapedString':'\\u{1D452}', altNames:['math-e', 'emath', 'mathe',]},
	{'name':'f-math','string':'\u{1D453}', 	'escapedString':'\\u{1D453}', altNames:['math-f', 'fmath', 'mathf',]},
	{'name':'g-math','string':'\u{1D454}', 	'escapedString':'\\u{1D454}', altNames:['math-g', 'gmath', 'mathg',]},
	{'name':'h-math','string':'\u{210E}', 	'escapedString':'\\u{210E}', 	altNames:['math-h', 'hmath', 'mathh',]},
	{'name':'i-math','string':'\u{1D456}',	'escapedString':'\\u{1D456}', altNames:['math-i', 'imath', 'mathi',]},
	{'name':'j-math','string':'\u{1D457}', 	'escapedString':'\\u{1D457}', altNames:['math-j', 'jmath', 'mathj',]},
	{'name':'k-math','string':'\u{1D458}',	'escapedString':'\\u{1D458}', altNames:['math-k', 'kmath', 'mathk',]},
	{'name':'l-math','string':'\u{1D459}',	'escapedString':'\\u{1D459}', altNames:['math-l', 'lmath', 'mathl',]},
	{'name':'m-math','string':'\u{1D45A}',	'escapedString':'\\u{1D45A}', altNames:['math-m', 'mmath', 'mathm',]},
	{'name':'n-math','string':'\u{1D45B}',	'escapedString':'\\u{1D45B}', altNames:['math-n', 'nmath', 'mathn',]},
	{'name':'o-math','string':'\u{1D45C}',	'escapedString':'\\u{1D45C}', altNames:['math-o', 'omath', 'matho',]},
	{'name':'p-math','string':'\u{1D45D}',	'escapedString':'\\u{1D45D}', altNames:['math-p', 'pmath', 'mathp',]},
	{'name':'q-math','string':'\u{1D45E}',	'escapedString':'\\u{1D45E}', altNames:['math-q', 'qmath', 'mathq',]},
	{'name':'r-math','string':'\u{1D45F}',	'escapedString':'\\u{1D45F}', altNames:['math-r', 'rmath', 'mathr',]},
	{'name':'s-math','string':'\u{1D460}',	'escapedString':'\\u{1D460}', altNames:['math-s', 'smath', 'maths',]},
	{'name':'t-math','string':'\u{1D461}',	'escapedString':'\\u{1D461}', altNames:['math-t', 'tmath', 'matht',]},
	{'name':'u-math','string':'\u{1D462}',	'escapedString':'\\u{1D462}', altNames:['math-u', 'umath', 'mathu',]},
	{'name':'v-math','string':'\u{1D463}',	'escapedString':'\\u{1D463}', altNames:['math-v', 'vmath', 'mathv',]},
	{'name':'w-math','string':'\u{1D464}',	'escapedString':'\\u{1D464}', altNames:['math-w', 'wmath', 'mathw',]},
	{'name':'x-math','string':'\u{1D465}',	'escapedString':'\\u{1D465}', altNames:['math-x', 'xmath', 'mathx',]},
	{'name':'y-math','string':'\u{1D466}',	'escapedString':'\\u{1D466}', altNames:['math-y', 'ymath', 'mathy',]},
	{'name':'z-math','string':'\u{1D467}',	'escapedString':'\\u{1D467}', altNames:['math-z', 'zmath', 'mathz',]},

	// uppercase
	{'name':'uppercase-a-math','string':'\u{1D434}',	'escapedString':'\\u{1D434}',  altNames:['a-math-uppercase','amathuppercase','mathauppercase']},
	{'name':'uppercase-b-math','string':'\u{1D435}',	'escapedString':'\\u{1D435}',  altNames:['b-math-uppercase','bmathuppercase','mathbuppercase']},
	{'name':'uppercase-c-math','string':'\u{1D436}',	'escapedString':'\\u{1D436}',  altNames:['c-math-uppercase','cmathuppercase','mathcuppercase']},
	{'name':'uppercase-d-math','string':'\u{1D437}',	'escapedString':'\\u{1D437}',  altNames:['d-math-uppercase','dmathuppercase','mathduppercase']},
	{'name':'uppercase-e-math','string':'\u{1D438}',	'escapedString':'\\u{1D438}',  altNames:['e-math-uppercase','emathuppercase','matheuppercase']},
	{'name':'uppercase-f-math','string':'\u{1D439}',	'escapedString':'\\u{1D439}',  altNames:['f-math-uppercase','fmathuppercase','mathfuppercase']},
	{'name':'uppercase-g-math','string':'\u{1D43A}',	'escapedString':'\\u{1D43A}',  altNames:['g-math-uppercase','gmathuppercase','mathguppercase']},
	{'name':'uppercase-h-math','string':'\u{1D43B}',	'escapedString':'\\u{1D43B}',  altNames:['h-math-uppercase','hmathuppercase','mathhuppercase']},
	{'name':'uppercase-i-math','string':'\u{1D43C}',	'escapedString':'\\u{1D43C}',  altNames:['i-math-uppercase','imathuppercase','mathiuppercase']},
	{'name':'uppercase-j-math','string':'\u{1D43D}',	'escapedString':'\\u{1D43D}',  altNames:['j-math-uppercase','jmathuppercase','mathjuppercase']},
	{'name':'uppercase-k-math','string':'\u{1D43E}',	'escapedString':'\\u{1D43E}',  altNames:['k-math-uppercase','kmathuppercase','mathkuppercase']},
	{'name':'uppercase-l-math','string':'\u{1D43F}',	'escapedString':'\\u{1D43F}',  altNames:['l-math-uppercase','lmathuppercase','mathluppercase']},
	{'name':'uppercase-m-math','string':'\u{1D440}',	'escapedString':'\\u{1D440}',  altNames:['m-math-uppercase','mmathuppercase','mathmuppercase']},
	{'name':'uppercase-n-math','string':'\u{1D441}',	'escapedString':'\\u{1D441}',  altNames:['n-math-uppercase','nmathuppercase','mathnuppercase']},
	{'name':'uppercase-o-math','string':'\u{1D442}',	'escapedString':'\\u{1D442}',  altNames:['o-math-uppercase','omathuppercase','mathouppercase']},
	{'name':'uppercase-p-math','string':'\u{1D443}',	'escapedString':'\\u{1D443}',  altNames:['p-math-uppercase','pmathuppercase','mathpuppercase']},
	{'name':'uppercase-q-math','string':'\u{1D444}',	'escapedString':'\\u{1D444}',  altNames:['q-math-uppercase','qmathuppercase','mathquppercase']},
	{'name':'uppercase-r-math','string':'\u{1D445}',	'escapedString':'\\u{1D445}',  altNames:['r-math-uppercase','rmathuppercase','mathruppercase']},
	{'name':'uppercase-s-math','string':'\u{1D446}',	'escapedString':'\\u{1D446}',  altNames:['s-math-uppercase','smathuppercase','mathsuppercase']},
	{'name':'uppercase-t-math','string':'\u{1D447}',	'escapedString':'\\u{1D447}',  altNames:['t-math-uppercase','tmathuppercase','mathtuppercase']},
	{'name':'uppercase-u-math','string':'\u{1D448}',	'escapedString':'\\u{1D448}',  altNames:['u-math-uppercase','umathuppercase','mathuuppercase']},
	{'name':'uppercase-v-math','string':'\u{1D449}',	'escapedString':'\\u{1D449}',  altNames:['v-math-uppercase','vmathuppercase','mathvuppercase']},
	{'name':'uppercase-w-math','string':'\u{1D44A}',	'escapedString':'\\u{1D44A}',  altNames:['w-math-uppercase','wmathuppercase','mathwuppercase']},
	{'name':'uppercase-x-math','string':'\u{1D44B}',	'escapedString':'\\u{1D44B}',  altNames:['x-math-uppercase','xmathuppercase','mathxuppercase']},
	{'name':'uppercase-y-math','string':'\u{1D44C}',	'escapedString':'\\u{1D44C}',  altNames:['y-math-uppercase','ymathuppercase','mathyuppercase']},
	{'name':'uppercase-z-math','string':'\u{1D44D}',	'escapedString':'\\u{1D44D}',  altNames:['z-math-uppercase','zmathuppercase','mathzuppercase']},

	// GREEK LETTERS

	{'name':'alpha',				'string':'\u{03B1}', 'escapedString':'\\u{03B1}'},
	{'name':'beta',					'string':'\u{03B2}', 'escapedString':'\\u{03B2}'},
	{'name':'gamma',				'string':'\u{03B3}', 'escapedString':'\\u{03B3}'},
	{'name':'delta',				'string':'\u{03B4}', 'escapedString':'\\u{03B4}'},
	{'name':'epsilon',			'string':'\u{03B5}', 'escapedString':'\\u{03B5}'},
	{'name':'zeta',					'string':'\u{03B6}', 'escapedString':'\\u{03B6}'},
	{'name':'eta',					'string':'\u{03B7}', 'escapedString':'\\u{03B7}'},
	{'name':'theta',				'string':'\u{03B8}', 'escapedString':'\\u{03B8}'},
	{'name':'iota',					'string':'\u{03B9}', 'escapedString':'\\u{03B9}'},
	{'name':'kappa',				'string':'\u{03BA}', 'escapedString':'\\u{03BA}'},
	{'name':'lambda',				'string':'\u{03BB}', 'escapedString':'\\u{03BB}'},
	{'name':'mu',						'string':'\u{03BC}', 'escapedString':'\\u{03BC}'},
	{'name':'nu',						'string':'\u{03BD}', 'escapedString':'\\u{03BD}'},
	{'name':'xi',						'string':'\u{03BE}', 'escapedString':'\\u{03BE}'},
	{'name':'omicron',			'string':'\u{03BF}', 'escapedString':'\\u{03BF}'},
	{'name':'pi',						'string':'\u{03C0}', 'escapedString':'\\u{03C0}'},
	{'name':'rho',					'string':'\u{03C1}', 'escapedString':'\\u{03C1}'},
	{'name':'sigma',				'string':'\u{03C3}', 'escapedString':'\\u{03C3}'},
	{'name':'tau',					'string':'\u{03C4}', 'escapedString':'\\u{03C4}'},
	{'name':'upsilon',			'string':'\u{03C5}', 'escapedString':'\\u{03C5}'},
	{'name':'phi',					'string':'\u{03C6}', 'escapedString':'\\u{03C6}'},
	{'name':'chi',					'string':'\u{03C7}', 'escapedString':'\\u{03C7}'},
	{'name':'psi',					'string':'\u{03C8}', 'escapedString':'\\u{03C8}'},
	{'name':'omega',				'string':'\u{03C9}', 'escapedString':'\\u{03C9}'},

	{'name':'alpha-capital',	'string':'\u{0391}', 'escapedString':'\\u{0391}'},
	{'name':'beta-capital',		'string':'\u{0392}', 'escapedString':'\\u{0392}'},
	{'name':'gamma-capital',	'string':'\u{0393}', 'escapedString':'\\u{0393}'},
	{'name':'delta-capital',	'string':'\u{0394}', 'escapedString':'\\u{0394}'},
	{'name':'epsilon-capital','string':'\u{0395}', 'escapedString':'\\u{0395}'},
	{'name':'zeta-capital',		'string':'\u{0396}', 'escapedString':'\\u{0396}'},
	{'name':'eta-capital',		'string':'\u{0397}', 'escapedString':'\\u{0397}'},
	{'name':'theta-capital',	'string':'\u{0398}', 'escapedString':'\\u{0398}'},
	{'name':'iota-capital',		'string':'\u{0399}', 'escapedString':'\\u{0399}'},
	{'name':'kappa-capital',	'string':'\u{039A}', 'escapedString':'\\u{039A}'},
	{'name':'lambda-capital',	'string':'\u{039B}', 'escapedString':'\\u{039B}'},
	{'name':'mu-capital',			'string':'\u{039C}', 'escapedString':'\\u{039C}'},
	{'name':'nu-capital',			'string':'\u{039D}', 'escapedString':'\\u{039D}'},
	{'name':'xi-capital',			'string':'\u{039E}', 'escapedString':'\\u{039E}'},
	{'name':'omicron-capital','string':'\u{039F}', 'escapedString':'\\u{039F}'},
	{'name':'pi-capital',			'string':'\u{03A0}', 'escapedString':'\\u{03A0}'},
	{'name':'rho-capital',		'string':'\u{03A1}', 'escapedString':'\\u{03A1}'},
	{'name':'sigma-capital',	'string':'\u{03A3}', 'escapedString':'\\u{03A3}', altNames:['sum']},
	{'name':'tau-capital',		'string':'\u{03A4}', 'escapedString':'\\u{03A4}'},
	{'name':'upsilon-capital','string':'\u{03A5}', 'escapedString':'\\u{03A5}'},
	{'name':'phi-capital',		'string':'\u{03A6}', 'escapedString':'\\u{03A6}'},
	{'name':'chi-capital',		'string':'\u{03A7}', 'escapedString':'\\u{03A7}'},
	{'name':'psi-capital',		'string':'\u{03A8}', 'escapedString':'\\u{03A8}'},
	{'name':'omega-capital',	'string':'\u{03A9}', 'escapedString':'\\u{03A9}'},

	// WORLD LANGUAGES
	{'name':'guillemet-left',					'string':'\u{00AB}', 'escapedString':'\\u{00AB}', altNames:['quote']},
	{'name':'guillemet-right',				'string':'\u{00BB}', 'escapedString':'\\u{00BB}', altNames:['quote']},
	{'name':'inverted-exclamation',		'string':'\u{00A1}', 'escapedString':'\\u{00A1}', altNames:['exclamation-inverted']},
	{'name':'inverted-question',			'string':'\u{00BF}', 'escapedString':'\\u{00BF}', altNames:['question-inverted']},
	
	{'name':'a-grave',								'string':'\u{00E0}', 'escapedString':'\\u{00E0}', altNames:['grave-a']},
	{'name':'a-acute',								'string':'\u{00E1}', 'escapedString':'\\u{00E1}', altNames:['acute-a']},
	{'name':'a-circumflex',						'string':'\u{00E2}', 'escapedString':'\\u{00E2}', altNames:['circumflex-a']},
	{'name':'a-diaeresis',						'string':'\u{00E4}', 'escapedString':'\\u{00E4}', altNames:['diaeresis-a', 'umlaut-a', 'a-umlaut']},
	{'name':'a-overring',							'string':'\u{00E5}', 'escapedString':'\\u{00E5}', altNames:['overring-a']},
	{'name':'a-grave-capital',				'string':'\u{00C0}', 'escapedString':'\\u{00C0}', altNames:['grave-a-capital']},
	{'name':'a-acute-capital',				'string':'\u{00C1}', 'escapedString':'\\u{00C1}', altNames:['acute-a-capital']},
	{'name':'a-circumflex-capital',		'string':'\u{00C2}', 'escapedString':'\\u{00C2}', altNames:['circumflex-a-capital']},
	{'name':'a-tilde-capital',				'string':'\u{00C3}', 'escapedString':'\\u{00C3}', altNames:['tilde-a-capital']},
	{'name':'ae',											'string':'\u{00E6}', 'escapedString':'\\u{00E6}'},
	{'name':'ae-capital',							'string':'\u{00C6}', 'escapedString':'\\u{00C6}'},
	{'name':'c-cedilla',							'string':'\u{00E7}', 'escapedString':'\\u{00E7}', altNames:['cedilla-c']},
	{'name':'c-cedilla-capital',			'string':'\u{00C7}', 'escapedString':'\\u{00C7}', altNames:['cedilla-c-capital']},
	{'name':'e-circumflex',						'string':'\u{00EA}', 'escapedString':'\\u{00EA}', altNames:['circumflex-e']},
	{'name':'e-acute',								'string':'\u{00E9}', 'escapedString':'\\u{00E9}', altNames:['acute-e']},
	{'name':'e-diaeresis',						'string':'\u{00EB}', 'escapedString':'\\u{00EB}', altNames:['diaeresis-e', 'umlaut-e', 'e-umlaut']},
	{'name':'e-grave',								'string':'\u{00E8}', 'escapedString':'\\u{00E8}', altNames:['grave-e']},
	{'name':'e-circumflex-capital',		'string':'\u{00CA}', 'escapedString':'\\u{00CA}', altNames:['grave-e']},
	{'name':'e-diaeresis-capital',		'string':'\u{00CB}', 'escapedString':'\\u{00CB}', altNames:['grave-e']},
	{'name':'e-acute-capital',				'string':'\u{00C9}', 'escapedString':'\\u{00C9}', altNames:['grave-e']},
	{'name':'e-grave-capital',				'string':'\u{00C8}', 'escapedString':'\\u{00C8}', altNames:['grave-e']},
	{'name':'i-diaeresis',						'string':'\u{00EF}', 'escapedString':'\\u{00EF}', altNames:['diaeresis-i', 'umlaut-i', 'i-umlaut']},
	{'name':'i-acute',								'string':'\u{00ED}', 'escapedString':'\\u{00ED}', altNames:['acute-i']},
	{'name':'i-circumflex',						'string':'\u{00EE}', 'escapedString':'\\u{00EE}', altNames:['circumflex-i']},
	{'name':'i-grave',								'string':'\u{00EC}', 'escapedString':'\\u{00EC}', altNames:['grave-i']},
	{'name':'i-acute-capital',				'string':'\u{00CD}', 'escapedString':'\\u{00CD}', altNames:['acute-i-capital']},
	{'name':'i-grave-capital',				'string':'\u{00CC}', 'escapedString':'\\u{00CC}', altNames:['grave-i-capital']},
	{'name':'i-circumflex-capital',		'string':'\u{00CE}', 'escapedString':'\\u{00CE}', altNames:['circumflex-i-capital']},
	{'name':'i-diaeresis-capital',		'string':'\u{00CF}', 'escapedString':'\\u{00CF}', altNames:['diaeresis-i-capital','umlaut-i-capital', 'i-umlaut-capital']},
	{'name':'n-tilde',								'string':'\u{00F1}', 'escapedString':'\\u{00F1}', altNames:['tilde-n']},
	{'name':'n-tilde-capital',				'string':'\u{00D1}', 'escapedString':'\\u{00D1}', altNames:['tilde-n-capital']},
	{'name':'oe',											'string':'\u{0153}', 'escapedString':'\\u{0153}',},
	{'name':'oe-capital',							'string':'\u{0152}', 'escapedString':'\\u{0152}',},
	{'name':'o-circumflex',						'string':'\u{00F4}', 'escapedString':'\\u{00F4}', altNames:['circumflex-o']},
	{'name':'o-diaeresis',						'string':'\u{00F6}', 'escapedString':'\\u{00F6}', altNames:['diaeresis-o', 'umlaut-o', 'o-umlaut']},
	{'name':'o-grave',								'string':'\u{00F2}', 'escapedString':'\\u{00F2}', altNames:['grave-o']},
	{'name':'o-tilde',								'string':'\u{00F5}', 'escapedString':'\\u{00F5}', altNames:['tilde-o']},
	{'name':'o-acute',								'string':'\u{00F3}', 'escapedString':'\\u{00F3}', altNames:['acute-o']},
	{'name':'o-stroke',								'string':'\u{00F8}', 'escapedString':'\\u{00F8}', altNames:['stroke-o']},
	{'name':'o-acute-capital',				'string':'\u{00D3}', 'escapedString':'\\u{00D3}', altNames:['acute-o-capital']},
	{'name':'o-circumflex-capital',		'string':'\u{00D4}', 'escapedString':'\\u{00D4}', altNames:['circumflex-o-capital']},
	{'name':'o-tilde-capital',				'string':'\u{00D5}', 'escapedString':'\\u{00D5}', altNames:['tilde-o-capital']},
	{'name':'o-stroke-capital',				'string':'\u{00D8}', 'escapedString':'\\u{00D8}', altNames:['stroke-o-capital']},
	{'name':'o-diaeresis-capital',		'string':'\u{00D6}', 'escapedString':'\\u{00D6}', altNames:['diaeresis-o-capital', 'umlaut-o-capital', 'o-umlaut-capital']},
	{'name':'o-grave-capital',				'string':'\u{00D2}', 'escapedString':'\\u{00D2}', altNames:['grave-o-capital']},
	{'name':'s-caron',								'string':'\u{0161}', 'escapedString':'\\u{0161}'},
	{'name':'s-caron-capital',				'string':'\u{0160}', 'escapedString':'\\u{0160}'},
	{'name':'u-acute',								'string':'\u{00FA}', 'escapedString':'\\u{00FA}', altNames:['acute-u']},
	{'name':'u-diaeresis',						'string':'\u{00FC}', 'escapedString':'\\u{00FC}', altNames:['diaeresis-u', 'umlaut-u', 'u-umlaut']},
	{'name':'u-circumflex',						'string':'\u{00FB}', 'escapedString':'\\u{00FB}', altNames:['circumflex-u']},
	{'name':'u-grave',								'string':'\u{00F9}', 'escapedString':'\\u{00F9}', altNames:['grave-u']},
	{'name':'u-grave-capital',				'string':'\u{00D9}', 'escapedString':'\\u{00D9}', altNames:['grave-u-capital']},
	{'name':'u-acute-capital',				'string':'\u{00DA}', 'escapedString':'\\u{00DA}', altNames:['acute-u-capital']},
	{'name':'u-diaeresis-capital',		'string':'\u{00DC}', 'escapedString':'\\u{00DC}', altNames:['diaeresis-u-capital', 'umlaut-u-capital', 'u-umlaut-capital']},
	{'name':'u-circumflex-capital',		'string':'\u{00DB}', 'escapedString':'\\u{00DB}', altNames:['circumflex-u-capital']},
	{'name':'y-diaeresis',						'string':'\u{00FF}', 'escapedString':'\\u{00FF}', altNames:['diaeresis-y', 'umlaut-y', 'y-umlaut']},
	{'name':'y-diaeresis-capital',		'string':'\u{0178}', 'escapedString':'\\u{0178}', altNames:['diaeresis-y-capital', 'umlaut-y-capital', 'umlaut-y-capital']},
	{'name':'y-acute',								'string':'\u{00FD}', 'escapedString':'\\u{00FD}', altNames:['acute-y']},
	{'name':'y-acute-capital',				'string':'\u{00DD}', 'escapedString':'\\u{00DD}', altNames:['acute-y-capital']},
	{'name':'z-caron',								'string':'\u{017E}', 'escapedString':'\\u{017E}'},
	{'name':'z-caron-capital',				'string':'\u{017D}', 'escapedString':'\\u{017D}'},
	{'name':'ss',											'string':'\u{00DF}', 'escapedString':'\\u{00DF}', altNames:['eszett', 'scharfes-s']},

	// CURRENCY
	{'name':'dollar',							'string':'\u{0024}', 'escapedString':'\\u{0024}'},
	{'name':'euro',								'string':'\u{20AC}', 'escapedString':'\\u{20AC}'},
	{'name':'yen',								'string':'\u{00A5}', 'escapedString':'\\u{00A5}'},
	{'name':'pound-sterling',			'string':'\u{00A3}', 'escapedString':'\\u{00A3}'},
	{'name':'korean-won',					'string':'\u{20A9}', 'escapedString':'\\u{20A9}'},
	{'name':'ruble',							'string':'\u{20BD}', 'escapedString':'\\u{20BD}'},
	{'name':'rupee',							'string':'\u{20B9}', 'escapedString':'\\u{20B9}'},
	{'name':'cent',								'string':'\u{00A2}', 'escapedString':'\\u{00A2}'},
	{'name':'baht',								'string':'\u{0E3F}', 'escapedString':'\\u{0E3F}'},
	{'name':'philippine-peso',		'string':'\u{20B1}', 'escapedString':'\\u{20B1}'},

	// ROMAN NUMERALS
	{'name':'roman-numeral-one',		'string':'\u{2160}', 'escapedString':'\\u{2160}', altNames:['roman-one', 		'one-roman', 		'roman-i', 		'i-roman']},
	{'name':'roman-numeral-two',		'string':'\u{2161}', 'escapedString':'\\u{2161}', altNames:['roman-two', 		'two-roman', 		'roman-ii', 	'ii-roman']},
	{'name':'roman-numeral-three',	'string':'\u{2162}', 'escapedString':'\\u{2162}', altNames:['roman-three',	'three-roman', 	'roman-iii',	'iii-roman']},
	{'name':'roman-numeral-four',		'string':'\u{2163}', 'escapedString':'\\u{2163}', altNames:['roman-four', 	'four-roman', 	'roman-iv', 	'iv-roman']},
	{'name':'roman-numeral-five',		'string':'\u{2164}', 'escapedString':'\\u{2164}', altNames:['roman-five', 	'five-roman', 	'roman-v', 		'v-roman']},
	{'name':'roman-numeral-six',		'string':'\u{2165}', 'escapedString':'\\u{2165}', altNames:['roman-six', 		'six-roman', 		'roman-vi', 	'vi-roman']},
	{'name':'roman-numeral-seven',	'string':'\u{2166}', 'escapedString':'\\u{2166}', altNames:['roman-seven', 	'seven-roman', 	'roman-vii', 	'vii-roman']},
	{'name':'roman-numeral-eight',	'string':'\u{2167}', 'escapedString':'\\u{2167}', altNames:['roman-eight', 	'eigth-roman', 	'roman-viii', 'vii-roman']},
	{'name':'roman-numeral-nine',		'string':'\u{2168}', 'escapedString':'\\u{2168}', altNames:['roman-nine', 	'nine-roman', 	'roman-ix', 	'ix-roman']},
	{'name':'roman-numeral-ten',		'string':'\u{2169}', 'escapedString':'\\u{2169}', altNames:['roman-ten', 		'ten-roman', 		'roman-x', 		'x-roman']},
	{'name':'roman-numeral-eleven',	'string':'\u{216A}', 'escapedString':'\\u{216A}', altNames:['roman-eleven', 'eleven-roman', 'roman-xi', 	'xi-roman']},
	{'name':'roman-numeral-twelve',	'string':'\u{216B}', 'escapedString':'\\u{216B}', altNames:['roman-twelve', 'twelve-roman', 'roman-xii', 	'xii-roman']},
	{'name':'roman-numeral-fifty',	'string':'\u{216C}', 'escapedString':'\\u{216C}', altNames:['roman-fifty', 	'fifty-roman', 	'roman-l', 		'l-roman', 'roman-numeral-fifty', 'roman-fifty', 'fifty-roman']},
	{'name':'roman-numeral-100',		'string':'\u{216D}', 'escapedString':'\\u{216D}', altNames:['roman-100', 		'roman-c', 		'c-roman', 'roman-numeral-one-hundred', 'roman-hundred', 'hundred-roman']},
	{'name':'roman-numeral-500',		'string':'\u{216E}', 'escapedString':'\\u{216E}', altNames:['roman-500', 		'roman-d', 		'd-roman', 'roman-numeral-five-hundred', 'roman-five-hundred', 'five-hundred-roman']},
	{'name':'roman-numeral-1000',		'string':'\u{216F}', 'escapedString':'\\u{216F}', altNames:['roman-1000', 	'roman-m', 		'm-roman', 'roman-numeral-one-thousand', 'roman-thousand', 'thousand-roman']},

]


// FRENCH
	// {'name':'a-grave',							'string':'\u{00E0}'},
	// {'name':'a-circumflex',					'string':'\u{00E2}'},
	// {'name':'c-cedilla',						'string':'\u{00E7}'},
	// {'name':'e-acute',							'string':'\u{00E9}'},
	// {'name':'e-grave',							'string':'\u{00E8}'},
	// {'name':'e-circumflex',					'string':'\u{00EA}'},
	// {'name':'e-diaeresis',					'string':'\u{00EB}'},
	// {'name':'i-circumflex',					'string':'\u{00EE}'},
	// {'name':'i-diaeresis',					'string':'\u{00EF}'},
	// {'name':'o-circumflex',					'string':'\u{00F4}'},
	// {'name':'oe',										'string':'\u{0153}'},
	// {'name':'u-grave',							'string':'\u{00F9}'},
	// {'name':'u-circumflex',					'string':'\u{00FB}'},
	// {'name':'u-diaeresis',					'string':'\u{00FC}'},
	// {'name':'a-grave-capital',			'string':'\u{00C0}'},
	// {'name':'a-circumflex-capital',	'string':'\u{00C2}'},
	// {'name':'c-cedilla-capital',		'string':'\u{00C7}'},
	// {'name':'e-acute-capital',			'string':'\u{00C9}'},
	// {'name':'e-grave-capital',			'string':'\u{00C8}'},
	// {'name':'e-circumflex-capital',	'string':'\u{00CA}'},
	// {'name':'e-diaeresis-capital',	'string':'\u{00CB}'},
	// {'name':'i-circumflex-capital',	'string':'\u{00CE}'},
	// {'name':'i-trema-capital',			'string':'\u{00CF}'},
	// {'name':'o-diaeresis-capital',	'string':'\u{00D4}'},
	// {'name':'oe-capital',						'string':'\u{0152}'},
	// {'name':'u-grave-capital',			'string':'\u{00D9}'},
	// {'name':'u-circumflex-capital',	'string':'\u{00DB}'},
	// {'name':'u-diaeresis-capital',	'string':'\u{00DC}'},

	// SPANISH
	// {'name':'inverted-exclamation',	'string':'\u{00A1}', altNames:['exclamation-inverted']},
	// {'name':'inverted-question',		'string':'\u{00BF}', altNames:['question-inverted']},
	// {'name':'degree',											'string':'\u{00B0}'},
	// {'name':'a-acute',										'string':'\u{00E1}'},
	// {'name':'e-acute',										'string':'\u{00E9}'},
	// {'name':'i-acute',										'string':'\u{00ED}'},
	// {'name':'n-tilde',										'string':'\u{00F1}'},
	// {'name':'o-acute',										'string':'\u{00F3}'},
	// {'name':'u-acute',										'string':'\u{00FA}'},
	// {'name':'u-diaeresis',								'string':'\u{00FC}'},
	// {'name':'a-acute-capital',						'string':'\u{00C1}'},
	// {'name':'e-acute-capital',						'string':'\u{00C9}'},
	// {'name':'i-acute-capital',						'string':'\u{00CD}'},
	// {'name':'n-tilde-capital',						'string':'\u{00D1}'},
	// {'name':'o-acute-capital',						'string':'\u{00D3}'},
	// {'name':'u-acute-capital',						'string':'\u{00DA}'},
	// {'name':'u-diaeresis-capital',				'string':'\u{00DC}'},
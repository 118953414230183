import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import DayPickerRangeController from 'react-dates/lib/components/DayPickerRangeController'
import { updateCustomDateRange } from '../../actions/scoreSheet'
import Button from '../../components/uiKit/Button'
import Icon from '../../components/misc/Icons'
import isOutsideRange from '../../components/scoreSheet/utils/isOutsideRange'

// Modal for selecting custom date range in scoresheet (pro only feature)
// when select dates update scoresheetCustomDateRange in state
// There is some slight funkiness around date selection and the react-dates day picker, may want to improve in the future e.g. if custom date selection was opened up to all users (not just pro)

class CalNavButton extends React.Component {
  render() {
    return (
      <div className='scoreSheet-calendarNavCustom'>
        {this.props.previous &&
        <Icon name='chevron-left' />}
        {this.props.next &&
        <Icon name='chevron-right' />}
      </div>
    )
  }
}

class ScoreSheetCustomRangeModalContainer extends Component {
  constructor(props) {
    super(props)
    this.onDatesChange = this.onDatesChange.bind(this)
    this.onFocusChange = this.onFocusChange.bind(this)
    this.applyChanges = this.applyChanges.bind(this)
    this.isDayHighlighted = this.isDayHighlighted.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.selectAll = this.selectAll.bind(this)

    let startDate = null
    let endDate = null
    if (props.scoreSheetCustomDateRange.startDate) {
      startDate = props.scoreSheetCustomDateRange.startDate
      endDate = props.scoreSheetCustomDateRange.endDate
    }
    this.state = {
      focusedInput: 'startDate',
      startDate,
      endDate,
      submitLoading: false,
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(e) {
    if (e.keyCode === 13) {
      this.applyChanges()
    }
  }

  onDatesChange({ startDate, endDate }) {
    const { focusedInput } = this.state
    const adjustedEndDate = ((focusedInput === 'endDate') && endDate) ?
      moment(endDate).endOf('d') :
      null
    this.setState({ startDate, endDate: adjustedEndDate })
  }

  onFocusChange(focusedInput) {
    this.setState({
      // defaults to 'startDate'
      focusedInput: focusedInput || 'startDate',
    })
  }

  selectAll() {
    const daysWithData = Object.keys(this.props.dayGroupedPollsForSection)
    const moments = daysWithData.map((d) => moment(d))
    const startDate = moment.min(moments).startOf('d')
    const endDate = moment.max(moments).endOf('d')
    this.setState({ startDate, endDate })
  }

  applyChanges() {
    this.setState({ submitLoading: true }, () => {
      setTimeout(() => {
        if (this.state.endDate.isSame(this.state.startDate, 'd')) { // temp for handling same day start and end
          this.props.updateCustomDateRange({
            startDate: moment(this.state.startDate).startOf('d'),
            endDate: moment(this.state.startDate).endOf('d'),
          })
        } else {
          this.props.updateCustomDateRange({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          })
        }
        this.props.hideModal()
      }, 1)
    })
  }

  isDayHighlighted(day) {
    if (this.props.dayGroupedPollsForSection[day.startOf('day')]) {
      return true
    } return false
  }

  render() {
    const { startDate, endDate, focusedInput } = this.state
    const highlightedInput = (startDate && endDate) ? '' : focusedInput
    return (
      <div className='scoreSheet-customRangeModal'>
        <div className='scoreSheet-customRangeModal-header'>

          <Button label='Select All' onClickFunction={this.selectAll} />

          <div className='scoreSheet-customRangeModal-header-label'>
            {(startDate && endDate) ? 'Custom Date Range' : 'Select Date Range'}
          </div>

          <div className='scoreSheet-customRangeModal-header-range'>
            <div className={highlightedInput === 'startDate' ? 'scoreSheet-customRangeModal-header-range-date--highlighted' : ''}>
              {startDate ? startDate.format('ddd DD MMM YYYY') : 'Start Date'}
            </div>
            <div>
              -
            </div>
            <div className={highlightedInput === 'endDate' ? 'scoreSheet-customRangeModal-header-range-date--highlighted' : ''}>
              {endDate ? endDate.format('ddd DD MMM YYYY') : 'End Date'}
            </div>
          </div>

          <Button label='Cancel' onClickFunction={this.props.hideModal} />
        </div>

        <div className='scoreSheet-customRangeModal-calendarContainer'>
          <DayPickerRangeController
            initialVisibleMonth={() => moment().subtract(3, 'months')}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onDatesChange={this.onDatesChange}
            focusedInput={this.state.focusedInput}
            onFocusChange={this.onFocusChange}
            numberOfMonths={4}
            isOutsideRange={(timestamp) => isOutsideRange(timestamp, this.props.firstDataDate)}
            isDayHighlighted={this.isDayHighlighted}
            hideKeyboardShortcutsPanel
            navPrev={<CalNavButton previous />}
            navNext={<CalNavButton next />}
            daySize={32}
            transitionDuration={0}
            firstDayOfWeek={1}
            minimumNights={0}
          />
        </div>

        <div className='scoreSheet-customRangeModal-footer'>
          <Button
            disabled={!endDate || this.state.submitLoading}
            submitLoading={this.state.submitLoading}
            color={!endDate ? 'gray' : 'blue'}
            size='xLarge'
            label='Apply'
            onClickFunction={this.applyChanges}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { currentSection } = state.modals
  const pollsForSection = _.filter(_.filter(state.historySetPolls, { archived: false }), { section: currentSection }).concat(_.filter(_.filter(state.historyQuestionPolls, { archived: false }), { section: currentSection }))
  const dayGroupedPollsForSection = _.groupBy(pollsForSection, (poll) => moment(poll.minCaptured).startOf('day'))
  const firstPoll = _.sortBy(pollsForSection, [function (poll) {
    return poll.minCaptured
  }])[0]

  let firstDataDate
  if (firstPoll) {
    firstDataDate = firstPoll.minCaptured
  }
  return {
    scoreSheetCustomDateRange: state.scoreSheetCustomDateRange,
    dayGroupedPollsForSection,
    firstDataDate,
  }
}

export default connect(mapStateToProps,
  { updateCustomDateRange })(ScoreSheetCustomRangeModalContainer)

import React from 'react'


class PageHead extends React.Component{

	render(){ 

		const {studentName, currentPage, totalPages} = this.props

		return(			
					
			<div className='printPackPageHead'>		
				{studentName && 	
					<div className='printPackPageHead-name'>
						{studentName}
					</div>
				}
				<div className='printPackPageHead-pageNumbers'>
					{currentPage && 
						<div className='printPackPageHead-pageNumbers-current'>
							{currentPage}
						</div>
					}
					{totalPages && 
						<div className='printPackPageHead-pageNumbers-total'>
							 &nbsp;/ {totalPages}
						</div>
					}
				</div>

			</div>
			
		)
	}
}
export default PageHead
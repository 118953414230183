import React from 'react'
import { ContextMenu, MenuItem } from 'react-contextmenu'

const SUBMENU_HOVER_DELAY=0
 
class FolderContextMenu extends React.Component{
	render(){
		const {
			onShowContextMenu,
			onHideContextMenu,
			handleFolderContextMenuClick,
			contextMenuObject,
			isViewOnlyRepo}=this.props

			 let isViewOnly=false
			 if(contextMenuObject){
				isViewOnly=contextMenuObject.readOnly ||isViewOnlyRepo
			 }
		return(
			<ContextMenu id='FOLDER' className='contextMenu' onShow={onShowContextMenu} onHide={onHideContextMenu}>
				
				<MenuItem onClick={handleFolderContextMenuClick} data={{action: 'Open'}}>			    
					Open
				</MenuItem>
				
				{!isViewOnly && 
					<React.Fragment>

						<MenuItem divider/>

						<MenuItem onClick={handleFolderContextMenuClick} data={{action: 'NewSetInFolder'}}>			    
							New Set in Folder	    
						</MenuItem>

						<MenuItem divider/>

						<MenuItem disabled={isViewOnly} attributes={{className:`${isViewOnly ?'is--disabled':''}`}} hoverDelay={SUBMENU_HOVER_DELAY} preventClose={true} data={{ action: 'MoveToRepo'}} onClick={handleFolderContextMenuClick} >
							Move to Pack...
						</MenuItem>		

						<MenuItem divider/>

						<MenuItem disabled={isViewOnly} onClick={handleFolderContextMenuClick} data={{action: 'Rename'}}>			    
							Rename		    
						</MenuItem>			
						
						<MenuItem disabled={isViewOnly} hoverDelay={SUBMENU_HOVER_DELAY} data={{ action: 'Move'}} onClick={handleFolderContextMenuClick} >
							Move to Folder...
						</MenuItem>		
						
						<MenuItem divider/>
					
						<MenuItem  disabled={isViewOnly} attributes={{className:'is--warning'}} onClick={handleFolderContextMenuClick} data={{action: 'Archive'}}>			    
							Send to Trash
						</MenuItem>

					</React.Fragment>
				}
				
			
			</ContextMenu>
		)
	}
}

export default FolderContextMenu
import store from '../store'
import find from 'lodash/find'
 


export function getItemForPoll(poll) {	
	const state = store.getState()
	const item= find(state.questions, {'id':poll.question}) || find(state.sets, {'id':poll.setObject})
	return item
}


import React from 'react'
import Button from '../uiKit/Button'

class CreatorProgramGuidelinesBlock extends React.Component{
	render(){	
		return(		
			<div className='joinCreatorProgramModal--guidelines-grid-block'>					
				<div className='joinCreatorProgramModal--guidelines-grid-block-title'>
					{this.props.title}
				</div>
				<div className='joinCreatorProgramModal--guidelines-grid-block-label'>
					{this.props.label}
				</div>
				{this.props.label2 &&
					<div className='joinCreatorProgramModal--guidelines-grid-block-label joinCreatorProgramModal--guidelines-grid-block-label--two'>
						{this.props.label2}
					</div>
				}
			</div>
		)	
	}
				
}

class CreatorProgramGuidelinesPage extends React.Component{

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Join Creator Program',{
				stage:'guidelines',
			})
		}
	}

	render(){	
		return(
			<React.Fragment>
				<div className='joinCreatorProgramModal-header'>
					What we ask from Creators
				</div>

				<div className='joinCreatorProgramModal--guidelines-grid'>
					
					<CreatorProgramGuidelinesBlock
						title='Bring your magic'
						label='Every pack published has the potential to impact millions of classrooms around the world. We can’t wait to see what you share.'
					/>
					<CreatorProgramGuidelinesBlock
						title='Follow the Content Policy'
						label='All published packs are public and are accessible from your profile, so make sure that material is appropriate and copyright is respected. Over time, we may select public packs and profiles to promote.'
					/>
					<CreatorProgramGuidelinesBlock
						title='Bring your unique voice'
						label='Different teachers have different teaching styles and systems. We encourage every teacher to publish packs with their own unique style and approach.'
					/>
					
					<CreatorProgramGuidelinesBlock
						title='Give us your feedback'
						label='We look forward to hearing from you about what’s working and what could be better so that we can improve Plickers and the Creator program for everyone.'
					/>

				</div>
		
				<div className='joinCreatorProgramModal-submitArea'>
					
					<Button
						size='large'
						color='blue'
						label="Choose your username"
						name='submit'
						onClickFunction={this.props.goToNextPage}						
					/>
					<Button
						size='large'
						color='white'
						label="I'm not ready to join right now"
						name='cancel'
						onClickFunction={this.props.hideModal}						
					/>

				</div>
			</React.Fragment>	
		)	
	}
				
}


export default CreatorProgramGuidelinesPage



import Pusher from 'pusher-js'
import pako from 'pako'
import store from '../../store'
import { controlReceived } from '../../actions/control'
import { pusherPollReceived } from '../../actions/polls'
import { onboardingStatusUpdateRecieved } from '../../actions/meta'
import { sectionControlReceived } from '../../actions/sectionControl'
import { fetchSearchKey } from '../../actions/searchKey'
import { repoAddedPusherReceived, repoRemovedPusherReceived, repoUnpublishedPusherReceived } from '../../actions/repos'
import { permissionUpdatedPusherReceived } from '../../actions/permissions'
import { onControlMessage, onResponseMessage } from '../network/pusherMessageMetrics'

// http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#user-channel
export function appPusherSetup(userId, onSetupComplete) {
  // Pusher.logToConsole = true
  const pusher = new Pusher(process.env.REACT_APP_PLICKERS_PUSHER_TOKEN, {
    encrypted: true,
  })

  const channel = pusher.subscribe(userId)
  // TODO add sentry error logging?
  channel.bind('pusher:subscription_error', (status) => {
    if (window.analytics) {
      window.analytics.track('user_pusher:subscription_error', {
        status,
        userId,
      })
    }
  })

  pusher.connection.bind('error', (error) => {
    if (window.analytics) {
      window.analytics.track('user_pusher:connection_error', {
        error,
        userId,
      })
    }
  })

  channel.bind('control-update', handleControlUpdatePusherReceived)
  channel.bind('control-update-comp', handleCompressedControlUpdatePusherReceived)
  channel.bind('response-update', handleResponseUpdatePusherReceived)
  channel.bind('response-update-comp', handleCompressedResponseUpdatePusherReceived)
  channel.bind('onboarding-status-update', handleOnboardingStatusUpdatePusherReceived)
  channel.bind('section-control', handleSectionControlUpdatePusherReceived)
  channel.bind('repo added', handleRepoAddedPusherReceived)
  channel.bind('repo removed', handleRepoRemovedPusherReceived)
  channel.bind('repo followed', handleRepoFollowedPusherReceived)
  channel.bind('repo unfollowed', handleRepoUnfollowedPusherReceived)
  channel.bind('permission updated', handlePermissionUpdatedPusherReceived)
  channel.bind('repo unpublished', handleRepoUnpublishedPusherReceived)

  onSetupComplete()
}

function handleControlUpdatePusherReceived(data) {
  onControlMessage(data.message)
  store.dispatch(controlReceived(data.message))
}

function handleCompressedControlUpdatePusherReceived(data) {
  const byteArray = new Uint8Array(data.data)
  const controlString = pako.inflate(byteArray, { to: 'string' })
  const controlJSON = JSON.parse(controlString)
  onControlMessage(controlJSON.message)
  store.dispatch(controlReceived(controlJSON.message))
}

function handleResponseUpdatePusherReceived(data) {
  onResponseMessage(data)
  store.dispatch(pusherPollReceived(data))
}

function handleCompressedResponseUpdatePusherReceived(data) {
  const byteArray = new Uint8Array(data.data)
  const controlString = pako.inflate(byteArray, { to: 'string' })
  const controlJSON = JSON.parse(controlString)
  const responseData = controlJSON.message || controlJSON
  onResponseMessage(responseData)
  store.dispatch(pusherPollReceived(responseData))
}

function handleOnboardingStatusUpdatePusherReceived(data) {
  store.dispatch(onboardingStatusUpdateRecieved(data.status))
}

function handleSectionControlUpdatePusherReceived(data) {
  store.dispatch(sectionControlReceived(data))
}

function handleRepoAddedPusherReceived(data) {
  store.dispatch(fetchSearchKey())
  store.dispatch(repoAddedPusherReceived(data.repo))
}
function handleRepoFollowedPusherReceived() {
  store.dispatch(fetchSearchKey())
}
function handleRepoUnfollowedPusherReceived() {
  store.dispatch(fetchSearchKey())
}

function handlePermissionUpdatedPusherReceived(data) {
  store.dispatch(fetchSearchKey())
  store.dispatch(permissionUpdatedPusherReceived(data.permission))
}

function handleRepoRemovedPusherReceived(data) {
  store.dispatch(fetchSearchKey())
  store.dispatch(repoRemovedPusherReceived(data.repoId))
}

function handleRepoUnpublishedPusherReceived(data) {
  store.dispatch(fetchSearchKey())
  store.dispatch(repoUnpublishedPusherReceived(data.repoId))
}

function filterSuggestions(filterTerm,suggestions,recentSymbols,isDropdown){
	let filteredSuggestions=[]
	const filterTermLowerCase=filterTerm.toLowerCase()
	
	//loop over recentSymbols name and then alt names
	recentSymbols.forEach((recentSymbol)=>{
		if((recentSymbol.name.toLowerCase()).startsWith(filterTermLowerCase)){
			filteredSuggestions.push(recentSymbol)
		}
	})
	
	recentSymbols.forEach((recentSymbol)=>{
		if(recentSymbol.altNames){
				recentSymbol.altNames.forEach((altName)=>{
						if((altName.toLowerCase()).startsWith(filterTermLowerCase)){
						filteredSuggestions.push(recentSymbol)
					 }
				})
			}
	})
	//then loop over all over symbols
	suggestions.forEach((suggestion)=>{
		if((suggestion.name.toLowerCase()).startsWith(filterTermLowerCase)){
			filteredSuggestions.push(suggestion)
		}
	})

 suggestions.forEach((suggestion)=>{
	if(suggestion.altNames){
				suggestion.altNames.forEach((altName)=>{
					if((altName.toLowerCase()).startsWith(filterTermLowerCase)){
						filteredSuggestions.push(suggestion)
					}
				})
			}
	})
 	//remove duplicates
	let dedupedSuggestions = [...new Set(filteredSuggestions)]
	if(dedupedSuggestions.length===0){
		if(!filterTerm){
			dedupedSuggestions=suggestions
		}
	}
	let suggestionsArray=dedupedSuggestions
	if(!isDropdown){
		suggestionsArray= dedupedSuggestions.slice(0,8)
	}
	return suggestionsArray
}

export function getSuggestions(text,suggestions,recentSymbols,isDropdown,done){
	const filteredSuggestions=filterSuggestions(text,suggestions,recentSymbols,isDropdown)
	done(filteredSuggestions)
}
import React from 'react'
import EditorIcon from '../../../misc/EditorIcons'
import slideLayoutDefinitions from '../../../../staticData/slideLayoutDefinitions.js'

class ChoiceAnimatedGIFReactComponent extends React.Component{  

	constructor() {
		super()    
		this.state = {
			cloudinaryGIFHasLoaded: false
		}
	}

	render(){ 
		const {placeholder,host,webpUrl,gifUrl,giphyUser,fileId,questionChoiceCount}=this.props
		const {cloudinaryGifHasLoaded}=this.state
		const gifUploadCompleted = fileId?true:false // switch to file ID
		const slideWidth = this.props.slideWidth
		const slideScale = slideWidth / 1280
		const inverseScale = 1 / slideScale
		
		// prevent and other elements icons getting too big
		const ceilingInverseScale = 1.75 
		const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)
		const mediaWidth = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${questionChoiceCount}Choices`].width
		const bufferRemoveButton = 40 // prevent badge overflowing cross
		const topLeftBtnContainerWidth = (mediaWidth - (bufferRemoveButton * UIInverseScale)) / UIInverseScale

		let giphyUserAvatarURL
		let giphyUserProfileURL
		let giphyUserUsername
		if(giphyUser){
			giphyUserAvatarURL = giphyUser.avatarUrl
			giphyUserProfileURL = giphyUser.profileUrl
			giphyUserUsername = giphyUser.username
		}
		return (
			<React.Fragment>
												 
				{host==='giphy' &&                 
					<picture onClick={this.props.showReplaceGIFModal} className='slide-choice-media--gif-gif'> 
						<source draggable="false" type="image/webp" srcSet={webpUrl} />
						<img draggable="false" src={gifUrl} alt=''/>            
					</picture> 
				}

				{host==='cloudinary' &&                    
					<picture onLoad={()=>{this.setState({cloudinaryGifHasLoaded:true})}} onClick={this.props.showReplaceGIFModal} className='slide-choice-media--gif-gif'> 
						<source draggable="false" type="image/webp" srcSet={webpUrl} />            
						<img draggable="false" src={gifUrl} alt=''/>            
					</picture>                  
				}                 

					{/* GIPHY BADGE */}
				 {host==='giphy' && giphyUserUsername && giphyUserProfileURL && giphyUserAvatarURL &&         
						<div style={{transform: `scale(${UIInverseScale})`, width: `${topLeftBtnContainerWidth}px`}} className='slide-choice-media-topLeftBtnContainer slide-choice-media-topLeftBtnContainer--giphyBadge'>
							<a href={giphyUserProfileURL} target='_blank'rel="noopener noreferrer" className='slide-mediaContainer--gif-giphyUserLink'>
								<div className='slide-mediaContainer--gif-giphyUserLink-profilePicContainer'>
									<img src={giphyUserAvatarURL} className='slide-mediaContainer--gif-giphyUserLink-profilePic-img' alt=''/>
								</div>
								<div className='slide-mediaContainer--gif-giphyUserLink-userName'>
									<div className='slide-mediaContainer--gif-giphyUserLink-userName-userName'>
										@{giphyUserUsername}
									</div>
									<div className='slide-mediaContainer--gif-giphyUserLink-userName-viewOnGiphy'>
										View on Giphy
									</div>
								</div>                
							</a>
						</div>            
					}                      

				{placeholder && !gifUploadCompleted && 
					<div style={{transform: `scale(${UIInverseScale})`}} className='slide-choice-media-topLeftBtnContainer'>
						<div className='slide-choice-media--gif-loadingSpinnerContainer'>
							<div className='slide-choice-media--gif-loadingSpinner' /> 
						</div>
					</div>
				}

				{placeholder && !cloudinaryGifHasLoaded && 
					<picture onClick={this.props.showReplaceGIFModal} className='slide-choice-media--gif-gif slide-choice-media--gif-gif--placeholderEXample'>
						<img draggable="false" src={placeholder} alt=''/>
					</picture>            
				}

				{/* REMOVE GIF */}
					<div style={{transform: `scale(${UIInverseScale})`}} className='slide-choice-media-topRightBtnContainer'>
						<button onClick={this.props.deleteGIF} className='slide-choice-media--gif-removeGif'>
							<EditorIcon name='close' /> 
						</button>
					</div>          
				
				 
				{/*{placeholder &&           
					<div className='slide-choice-mediaContainer--gif--fallback' />
				}  */}      

			</React.Fragment>
		)
	}
}

export default ChoiceAnimatedGIFReactComponent

		 
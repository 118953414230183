import forEach from 'lodash/forEach'
import findIndex from 'lodash/findIndex'

export default function (state=[], action) {
	switch (action.type) {
      		 
	case 'FETCH_SETS_SUCCESS':
	case 'FETCH_REPO_SETS_SUCCESS':
		let newState = [...state] || []
		action.response.forEach(set => {
			const setId = set.id
			let indexOfSet = newState.findIndex(set => {
				return set.id === setId
			})
	
			if(indexOfSet === -1){
				indexOfSet=newState.length
			}
			newState= [
				...newState.slice(0,indexOfSet),
				set,
				...newState.slice(indexOfSet + 1)
			]	
		})
		return newState

	case 'REFRESH_SETS_SUCCESS':
		let sets=state.slice()
		forEach(action.response, function(modifiedSet) { //modified set is either new or has been edited since last data refresh
			
			let indexOfSet = sets.findIndex(set => {
				return set.id === modifiedSet.id
			})
			if(indexOfSet !== -1){ //if question if already in question array (is not new since last data refresh) remove old object
				sets =[
					...sets.slice(0,indexOfSet),
					...sets.slice(indexOfSet + 1)
				]
				}
			sets=[modifiedSet,...sets]
			
		})

		return sets	

	case 'FETCH_A_SET_SUCCESS':
		const setId = action.response.id
		if(state){
			var indexOfSetFetched = state.findIndex(set => {
				return set.id === setId
			})
			if(indexOfSetFetched === -1){
				indexOfSetFetched=state.length
			}
		
			return [
				...state.slice(0,indexOfSetFetched),
				action.response,
				...state.slice(indexOfSetFetched + 1)
			]
		}
		
		else return [action.response]

	
	case 'CREATE_SET_SUCCESS':
	case 'SET_ADDED_PUSHER':
		if(state){
			const addedSetId = action.set.id
			var indexOfSet = findIndex(state,set => {
				return set.id === addedSetId
			})
			if(indexOfSet === -1){
				indexOfSet=state.length
			}
	
			return [
				...state.slice(0,indexOfSet),
				action.set,
				...state.slice(indexOfSet + 1)
			]

		}
		else{
			const newSet=action.set
			return [newSet]
		}

	case 'UPDATE_SET_SUCCESS':
	case 'UPDATE_SET_FROM_PUSHER':
		const updatedSetId = action.response.id
		var indexOfSetToUpdate = findIndex(state,(set => {
			return set.id === updatedSetId
		}))
		if(indexOfSetToUpdate !== -1 ){
			if(action.response.archived===false){
				return [
					...state.slice(0,indexOfSetToUpdate),
					action.response,
					...state.slice(indexOfSetToUpdate + 1)
				]
			}
			else {
				return [
					...state.slice(0,indexOfSetToUpdate),
					...state.slice(indexOfSetToUpdate + 1)
				]
			}}
		else {
			if(action.response.archived===false){
				return [...state,action.response]
			}else return state
		}


	case 'DELETE_SET_SUCCESS':
		const deletedSetId = action.setId
		const indexOfSetToDelete = state.findIndex(set => {
			return set.id === deletedSetId
		})
		if(indexOfSetToDelete !==-1){	
			return [
				...state.slice(0,indexOfSetToDelete),
				...state.slice(indexOfSetToDelete + 1)
			]
		}else return state


	case 'SET_REMOVED_PUSHER':
		const removedSetId = action.setId
		const removedFromRepoId=action.repoId
		const indexOfSetToRemove = state.findIndex(set => {
			return (set.id === removedSetId && set.repo===removedFromRepoId)
		})
		if(indexOfSetToRemove !==-1){	
			return [
				...state.slice(0,indexOfSetToRemove),
				...state.slice(indexOfSetToRemove + 1)
			]
		}else return state

	
	case 'LOGOUT':
		return null

	default:
	    return state
	}
}


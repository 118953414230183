import {showNotification} from '../../actions/notifications'
import {updateUserConfig} from '../../actions/userConfig'
import store from '../../store'
import filter from 'lodash/filter'
import moment from 'moment'

//When user inserts fraction using button in toolbar we show a notification telling them how to insert using a shortcut

const MAX_NOTIFICATION_COUNT=2 //per type
const NOTIFICATION_TIME_PERIOD=60*24*30//resets every 30 days

function disableFastFractionInputRule(){
	const reduxState = store.getState()
	const user=reduxState.user
	if(user){
		const userId=user.id
		const requestData={disableFastFractionInputRule:true}
		store.dispatch(updateUserConfig(requestData,userId))
		store.dispatch(showNotification('You can re-enable Auto Fractions at any time from the top-left menu.','Auto Fractions disabled: ', 'default'))     		
	} 
	if(window.analytics){
		window.analytics.track('Disable Fast Fraction Input Rule from Notification')
	}  
}
 

export function showAutoFractionNotification(){
	const autoFractionNotificationHistory = JSON.parse(localStorage.getItem('autoFractionNotificationHistory')) || []
	const now=moment()
	let dateFilteredNotifications=filter(autoFractionNotificationHistory,function (n) { 	
		const timeAgo=now.diff(n.timestamp,'minutes')
		return timeAgo < NOTIFICATION_TIME_PERIOD 
	})
	const notificationCount=dateFilteredNotifications.length
	if(notificationCount<MAX_NOTIFICATION_COUNT){
		const fractionNoticficationButton={
			buttonLabel:"Disable Auto Fractions",
			buttonAction:disableFastFractionInputRule
		}
		store.dispatch(showNotification('You can insert more complex fractions from the toolbar or with the // shortcut.','We converted your text into a Fast Fraction.','default',fractionNoticficationButton))
		dateFilteredNotifications.push({timestamp:moment()})
		localStorage.setItem('autoFractionNotificationHistory', JSON.stringify(dateFilteredNotifications))
	}
}
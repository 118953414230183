import {createNewSet} from '../actions/sets'
import {createNewQuestion} from '../actions/questions'
import {getQuestionBodyLabel} from './getQuestionBodyLabel'
import {copyItemFromRepoEvent} from './analytics/copyItemFromRepoEvent'



const SET_TITLE_MAX_CHARACTER_LENGTH=60

export function addCopyToLibrary(item,instigatedBy,appendWithCopy) {
	const nowDate = new Date()

	if(item.choices){
		const requestBody = {
			...item,
			'body':`${getQuestionBodyLabel(item)}`,
			'userCreated': nowDate,
			'clientModified':nowDate,
			'folder':null,
			repo:null
		}
		copyItemFromRepoEvent(item,instigatedBy)
		return createNewQuestion(requestBody)
	}
	else if(item.questions){
		//const newSetName=`${item.name} Copy`
		let newSetName=`${item.name}`
		if(appendWithCopy !== false){
			 newSetName+=` Copy`
		}
		
		const requestBody = {
			...item,
			'name':newSetName.substring(0, SET_TITLE_MAX_CHARACTER_LENGTH),
			'userCreated': nowDate,
			'folder':null,
			'clientModified':nowDate,
			repo:null
		}
		copyItemFromRepoEvent(item,instigatedBy)
		return createNewSet(requestBody)

	}

}



    
import React from 'react'
import { Spring } from 'react-spring/renderprops'
import Icon from '../misc/Icons'
import Button from '../uiKit/Button'
import { newSet } from '../../utils/newItem'
import { getAmazonLink } from '../../utils/getAmazonLink'

const displayAmazonCardLink = process.env.REACT_APP_DISPLAY_AMAZON_CARD_LINK_ON_ONBOARDING_WIZARD === 'true'

class OnboardingWizardTaskButton extends React.Component {
  render() {
    const { onClickFunction, label, secondaryBtn } = this.props
    return (
      <button onClick={onClickFunction} className={`onboardingWizardTask-button${secondaryBtn ? ' onboardingWizardTask-button--secondary' : ' onboardingWizardTask-button--primary'}`}>
        {label}
        {' '}
        {!secondaryBtn && <Icon name='chevron-right' /> }
      </button>

    )
  }
}

class OnboardingWizardTaskWrapper extends React.Component {
  render() {
    const {
      title, description, children, completed,
    } = this.props
    return (
      <Spring to={{ taskHeight: completed ? 35 : 95 }}>
        { (props) => (
          <div style={{ height: `${props.taskHeight}px` }} className={`onboardingWizardTask${completed ? ' onboardingWizardTask--completed' : ' onboardingWizardTask--incomplete'}`}>
            <div className='onboardingWizardTask-statusMark'>
              {completed &&
              <Icon name='check' />}
              <div className='onboardingWizardTask-statusMark-hoverMark' />

            </div>

            <Spring to={{ opacity: completed ? 0.5 : 1 }}>
              {(props) => (
                <div style={{ opacity: `${props.opacity}` }} className='onboardingWizardTask-h1'>
                  {title}
                </div>
              )}
            </Spring>

            <Spring config={{ tension: 350, friction: 20 }} to={{ opacity: completed ? 0 : 1 }}>
              {(props) => (
                <React.Fragment>
                  <div style={{ opacity: `${props.opacity}` }} className='onboardingWizardTask-buttonArray'>
                    {children}
                  </div>
                  <div style={{ opacity: `${props.opacity}` }} className='onboardingWizardTask-body'>
                    {description}
                  </div>
                </React.Fragment>
              )}
            </Spring>
          </div>
        )}
      </Spring>

    )
  }
}

class OnboardingWizard extends React.Component {
  constructor() {
    super()
    this.emailAppLinksButtonClick = this.emailAppLinksButtonClick.bind(this)
    this.state = {
      hasClickedEmailLinkButton: false,
      hasClickedGotAppButton: false,
    }
  }

  emailAppLinksButtonClick() {
    this.props.emailAppLinks()
    this.setState({ hasClickedEmailLinkButton: true })
  }

  calculateTasksProgress(onboardingStatus) {
    const totalStepsCount = 4
    let completedStepsCount = 0
    if (onboardingStatus.mobileSignedIn) {
      completedStepsCount += 1
    }
    if (onboardingStatus.contentCreated) {
      completedStepsCount += 1
    }
    if (onboardingStatus.sectionCreated && onboardingStatus.studentCreated) {
      completedStepsCount += 1
    }
    if (onboardingStatus.cardsPrepared) {
      completedStepsCount += 1
    }

    const percentComplete = (completedStepsCount / totalStepsCount) * 100

    return { percentComplete, completedTaskCount: completedStepsCount, totalTaskCount: totalStepsCount }
  }

  render() {
    const { onboardingStatus } = this.props
    const { hasClickedEmailLinkButton, hasClickedGotAppButton } = this.state

    const tasksProgress = this.calculateTasksProgress(onboardingStatus)
    const { percentComplete } = tasksProgress

    let onboardingHeader = ''

    if (percentComplete === 0) {
      onboardingHeader = 'Let’s get you up and running'
    } else if (percentComplete === 25) {
      onboardingHeader = 'Great start!'
    } else if (percentComplete === 50) {
      onboardingHeader = 'Almost classroom ready!'
    } else if (percentComplete === 75) {
      onboardingHeader = 'One more step...'
    } else if (percentComplete === 100) {
      onboardingHeader = "You're all ready to go!"
    }

    return (
      <React.Fragment>
        <div className='onboardingWizard-header'>
          <div className='onboardingWizard-header-left'>
            <div className='onboardingWizard-header-left-h1'>
              {onboardingHeader}
            </div>
            {percentComplete === 0 && (
            <div className='onboardingWizard-header-left-h2'>
              Complete these steps to get your Plickers classroom ready!
            </div>
            )}

            {percentComplete !== 0 && (
            <Spring
              to={{ barWidth: percentComplete }}
              delay={350}
            >
              {(props) => (
                <React.Fragment>
                  <div className='onboardingWizard-header-left-h2'>
                    Setup
                    {' '}
                    {Math.round(props.barWidth)}
                    % complete
                  </div>
                  <div className='onboardingWizard-header-left-progressBar'>

                    <div className='onboardingWizard-header-left-progressBar-bar' style={{ width: `${props.barWidth}%` }} />

                    <div className='onboardingWizard-header-left-progressBar-track' />
                  </div>
                </React.Fragment>
              )}
            </Spring>
            )}
          </div>
          <div className='onboardingWizard-header-right'>
            <div className='onboardingWizard-header-right-mark'>
              <Icon name='spaceship' />
            </div>
          </div>

        </div>

        {percentComplete !== 100 && (
        <React.Fragment>
          <OnboardingWizardTaskWrapper
            title='Make a class and add some students'
            description='Grab a spreadsheet or list of your students’ names and this’ll take 30 seconds tops.'
            completed={onboardingStatus.sectionCreated && onboardingStatus.studentCreated}
            halfCompleted={onboardingStatus.sectionCreated}
          >
            {!onboardingStatus.sectionCreated &&
            <OnboardingWizardTaskButton label='Create a Class' onClickFunction={this.props.showNewSectionModal} />}
            {onboardingStatus.sectionCreated &&
            <OnboardingWizardTaskButton label='Add some students to finish' onClickFunction={this.props.navigateToSection} />}

          </OnboardingWizardTaskWrapper>

          <OnboardingWizardTaskWrapper
            title='Add some questions to ask'
            description='Write some quick questions to deliver in your Plickers lesson.'
            completed={onboardingStatus.contentCreated}
          >
            <OnboardingWizardTaskButton label='Create your first Set' onClickFunction={() => { newSet() }} />
          </OnboardingWizardTaskWrapper>

          <OnboardingWizardTaskWrapper
            title='Get the cards'
            description={displayAmazonCardLink ?
              'You can download and print yourself, or grab a nice pre-made set from Amazon.' :
              'Each student holds a unique card to answer your questions. Download and print them out.'}
            completed={onboardingStatus.cardsPrepared}
          >
            <a target='_blank' rel='noopener noreferrer' href='https://assets.plickers.com/plickers-cards/PlickersCards_2up.pdf'>
              <OnboardingWizardTaskButton label='Download' />
            </a>
            {displayAmazonCardLink && (
            <a target='_blank' rel='noopener noreferrer' href={getAmazonLink()}>
              <OnboardingWizardTaskButton label='Buy on Amazon' />
            </a>
            )}
            <OnboardingWizardTaskButton secondaryBtn label="I've got cards" onClickFunction={this.props.updateCardsPreparedStatus} />

          </OnboardingWizardTaskWrapper>

          <OnboardingWizardTaskWrapper
            title='Get the mobile app'
            description='The app scans student responses. Download and sign-in on iPhone, iPad, or Android now.'
            completed={onboardingStatus.mobileSignedIn}
          >

            {!hasClickedGotAppButton && (
            <React.Fragment>

              {!hasClickedEmailLinkButton &&
              <OnboardingWizardTaskButton label='Email me a link' onClickFunction={this.emailAppLinksButtonClick} />}
              {hasClickedEmailLinkButton && (
              <button className='onboardingWizardTask-button onboardingWizardTask-button--labelOnly onboardingWizardTask-button--green'>
                Download link sent!
              </button>
              )}

              <OnboardingWizardTaskButton onClickFunction={() => { this.setState({ hasClickedGotAppButton: true }) }} secondaryBtn label="I've got the app" />

            </React.Fragment>
            )}
            {hasClickedGotAppButton && (
            <button className='onboardingWizardTask-button onboardingWizardTask-button--labelOnly onboardingWizardTask-button--primary'>
              Almost done! Sign in to the app to finish
            </button>
            )}

          </OnboardingWizardTaskWrapper>

          {/*
           {onboardingStatus.cardsPrepared &&
            <button className='onboardingWizardTask-tempbutton' onClick={this.props.resetOnboardingStatus} />
             }
          */}
        </React.Fragment>
        )}

        {percentComplete === 100 && (
        <React.Fragment>

          <div className='onboardingWizard-success-h1'>
            How to play Plickers in your next lesson
          </div>
          <div className='onboardingWizard-success-item'>
            <div className='onboardingWizard-success-item-number'>1</div>
            <div className='onboardingWizard-success-item-label'>
              Hand out the Plickers cards to students.
              <a target='_blank' rel='noopener noreferrer' href='https://help.plickers.com/hc/en-us/articles/360009089113-Cards-Overview'>
                <OnboardingWizardTaskButton label='Learn more about Plickers Cards' />
              </a>
            </div>
          </div>
          <div className='onboardingWizard-success-item'>
            <div className='onboardingWizard-success-item-number'>2</div>
            <div className='onboardingWizard-success-item-label'>
              Open Plickers.com and project it onto your whiteboard.
              {' '}
              <br />
              <a target='_blank' rel='noopener noreferrer' href='https://help.plickers.com/hc/en-us/articles/360009089673-How-to-play-content-to-your-students'>
                <OnboardingWizardTaskButton label='Info on Playing in the Classroom' />
              </a>
            </div>
          </div>
          <div className='onboardingWizard-success-item'>
            <div className='onboardingWizard-success-item-number'>3</div>
            <div className='onboardingWizard-success-item-label'>Use the mobile app to play questions and scan responses during the lesson.</div>
          </div>
          <div className='onboardingWizard-success-item'>
            <div className='onboardingWizard-success-item-number'>4</div>
            <div className='onboardingWizard-success-item-label'>Gather instant results on your students' understanding. They'll think it's a game!</div>
          </div>
          <Button name='success-done' label='Done' size='large' color='blue' onClickFunction={this.props.dismissOnboardingWizard} />
        </React.Fragment>
        )}

      </React.Fragment>

    )
  }
}

export default OnboardingWizard

// {onboardingStatus.cardsPrepared &&
//   <button className='onboardingWizardTask-tempbutton' onClick={this.props.resetOnboardingStatus} />
// }

import {inputRules} from 'prosemirror-inputrules'
import editorSchema from '../../schema/editorSchema'
import formulaInputRules from './formulaInputRules'
import {superscriptInputRule} from './superscriptInputRule'
import {subscriptInputRule} from './subscriptInputRule'
import {arrowInputRule} from './arrowInputRule'
import {insertFractionShortcut} from './insertFractionShortcut'
import {openEquationEditorInputRule,openAdvancedEquationEditorInputRule} from './openEquationEditorInputRule'
import {minusSignInputRule} from './minusSignInputRule'

const buildInputRules = (schema) => {
  let result = []
  formulaInputRules.forEach((rule)=>{
    result.push(rule)
  })
  result.push(superscriptInputRule())
  result.push(subscriptInputRule())
  result.push(arrowInputRule())
  result.push(insertFractionShortcut())
  result.push(openEquationEditorInputRule())
  result.push(openAdvancedEquationEditorInputRule())
  result.push(minusSignInputRule())
  
  return result
}

const editorInputRules=inputRules({ rules: buildInputRules(editorSchema) })
export default editorInputRules




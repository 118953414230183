import store from '../../store'
import {
  generateClockSyncReport,
  reportToSegment,
  buildClockSyncReport,
} from './clockSyncServiceUtils'
import { CrossTabService, IntervalSequenceCrossTabServiceLeader } from './crossTabService'

const CLOCK_SYNC_VERSION = 4
const ENABLE_LOGGING = false
const ENABLE_LOG_OFFSET_VALUE = false

const defaultOptions = {
  clockOffsetStorageName: 'clock-sync-offset',
  serviceStateStorageName: 'clock-sync-service',
  repeatCount: 5,
  responseTimeoutMs: 10 * 1000,
  waitBeforeFirstMs: 100,
  delayBetweenRequestsMs: 1 * 1000,
  retryIntervalMs: 10 * 1000,
  percentilesToCompute: [50, 75, 90, 95, 99],
  backoffIntervalsMs: [
    2 * 1000,
    5 * 1000,
    15 * 1000,
    30 * 1000,
    60 * 1000,
    5 * 60 * 1000,
    15 * 60 * 1000,
    30 * 60 * 1000, // last timeout is used forever
  ],
}

function dispatchClockSyncAction(clockOffset) {
  store.dispatch({
    type: 'CLOCK_SYNC_SUCCESS',
    clockOffset,
    syncAction: true,
  })
}

class ClockSyncService {
  constructor(options) {
    this.options = options
    this.name = this.options.serviceStateStorageName
    this.crossTabService = new CrossTabService(
      this.options.serviceStateStorageName,
      (state, saveState) => new IntervalSequenceCrossTabServiceLeader(
        state,
        saveState,
        (message) => this.log(message),
        (actionContext) => this.performClockSync(actionContext),
        {
          backoffIntervals: this.options.backoffIntervalsMs,
          retryInterval: this.options.retryIntervalMs,
        },
      ),
    )
    if (ENABLE_LOG_OFFSET_VALUE) {
      // we don't otherwise log changes via tab sync
      setInterval(() => {
        this.log(`current clock offset: ${store.getState().clockSyncOffset}ms`)
      }, 5 * 1000)
    }
  }

  log(message) {
    if (ENABLE_LOGGING) {
      console.log(`${this.name} ${message}`)
    }
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ClockSyncService(defaultOptions)
    }
    return this.instance
  }

  saveClockOffset(clockOffset) {
    localStorage.setItem(this.options.clockOffsetStorageName, clockOffset)
    this.log(`saved clock offset: ${clockOffset}ms`)
    dispatchClockSyncAction(clockOffset)
  }

  getClockOffset() {
    const clockOffset = parseFloat(localStorage.getItem(this.options.clockOffsetStorageName), 10)
    this.log(`read clock offset: ${clockOffset}ms`)
    return (Number.isNaN(clockOffset) ? null : clockOffset)
  }

  loadClockOffset() {
    const clockOffset = this.getClockOffset()
    if (clockOffset !== null) {
      dispatchClockSyncAction(clockOffset)
    }
  }

  async performClockSync(actionContext) {
    const clockSyncId = new Date().getTime()
    const clockSyncReport = await generateClockSyncReport(this.options)

    clockSyncReport.clockSyncId = clockSyncId
    clockSyncReport.existingOffsetMs = store.getState().clockSyncOffset
    clockSyncReport.version = CLOCK_SYNC_VERSION
    clockSyncReport.extraParams.serviceRunId = actionContext.serviceRunId
    clockSyncReport.extraParams.runnerSequenceId = actionContext.runnerSequenceId
    reportToSegment(buildClockSyncReport(clockSyncReport))

    if (clockSyncReport.succeeded) {
      this.saveClockOffset(clockSyncReport.measuredOffsetMs)
    }
    return clockSyncReport.succeeded
  }
}

const clockSyncService = ClockSyncService.getInstance()

export default clockSyncService

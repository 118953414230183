import React from 'react'
import { ContextMenu, MenuItem ,SubMenu} from 'react-contextmenu'
import SectionMarker from '../../uiKit/SectionMarker'
import Icon from '../../misc/Icons'
import {getRepoforId} from '../../../utils/getRepoforId'

const SUBMENU_HOVER_DELAY=0

 

class RecentContextMenu extends React.Component{
	render(){ 
		const {
			sections,
			onShowContextMenu,
			onHideContextMenu,
			handleContextMenuClick,
			contextMenuObject
			 }=this.props

	 

		let isSet=false
		let isViewOnly=false
		let isInRepo = false
		let isViewOnlyRepo =false


		if(contextMenuObject){
			isInRepo = contextMenuObject.repo?true:false
			if(isInRepo){
				const repo=getRepoforId(contextMenuObject.repo)
				if(repo && (repo.role ==='viewer' || repo.role ==='consumer')){
					isViewOnlyRepo=true
				}
			}

			isViewOnly=contextMenuObject.readOnly || isViewOnlyRepo
			if(contextMenuObject.questions){
				isSet=true
			}
		}

			


		return(
			 <ContextMenu id='ITEMS' className='contextMenu' onShow={onShowContextMenu} onHide={onHideContextMenu}>
	      
				{!isViewOnly &&
					<React.Fragment>
			      <MenuItem preventClose={true}  onClick={handleContextMenuClick} data={{action: 'Open'}}>        	
			      	<div className='contextMenu-item'>        	
			      		Edit        	
			      	</div>
			      </MenuItem>
	     	
			      {isSet &&
							<MenuItem disabled={isViewOnly} onClick={handleContextMenuClick} data={{action: 'Rename'}}>			    
									Rename...
							</MenuItem>
						}
				
			  		<MenuItem divider />

			  	</React.Fragment>
			  }
 
	  		{sections.length < 8 &&
					<SubMenu title='Play Now' hoverDelay={SUBMENU_HOVER_DELAY}>
						{sections.map((section,index) =>
							<MenuItem  key={`play_${index}`} onClick={handleContextMenuClick} data={{ action: 'Play', section:section }}>
								<SectionMarker section={section} />
								<Icon name='play' />
								<SectionMarker section={section} type={'colorOnly'} />
							</MenuItem>
	  				)}	  			
					</SubMenu>
				}
		   
				{sections.length >= 8 &&
					<MenuItem hoverDelay={SUBMENU_HOVER_DELAY} onClick={handleContextMenuClick} data={{ action: 'Play' }}>
						Play Now
					</MenuItem>
				}


				{sections.length < 8 &&
					<SubMenu title='Add to Queue' hoverDelay={SUBMENU_HOVER_DELAY}>
						{sections.map((section,index) =>      				
							<MenuItem  key={`queue_${index}`} onClick={handleContextMenuClick} data={{ action: 'Queue', section:section }}>
       				<SectionMarker section={section} />					
							<Icon name='plus' />
							<SectionMarker section={section} type={'colorOnly'} />
       			</MenuItem>
						)}
					</SubMenu>
				}

				{!isViewOnly &&
					<React.Fragment>
						<MenuItem divider/>

						<MenuItem disabled={isViewOnly} attributes={{className:`${isViewOnly ?'is--disabled':''}`}} hoverDelay={SUBMENU_HOVER_DELAY} preventClose={true} data={{ action: 'MoveToRepo'}} onClick={handleContextMenuClick} >
							Move to Pack...
						</MenuItem>		
					</React.Fragment>
				}

				<MenuItem divider />

				{isViewOnly &&
					<MenuItem onClick={handleContextMenuClick} data={{action: 'CopyToLibrary'}}>			    
						Add Copy to Library
					</MenuItem>
				}

				<MenuItem onClick={handleContextMenuClick} data={{action: 'ItemDetail'}}>			    
					Detail View		    
				</MenuItem>

				{(!isSet || (!isViewOnly && !isInRepo)) &&
					<MenuItem divider />
				}
 	

				{!isSet &&
					<React.Fragment>
						<MenuItem  preventClose={true} attributes={{className:'is--warning'}} onClick={handleContextMenuClick} data={{action: 'CopyToClipboard'}}>        						
							Copy To Clipboard				
						</MenuItem>

						{!isViewOnly && !isInRepo &&
							<MenuItem divider />
						}

					</React.Fragment>
				}
				

				{!isViewOnly && !isInRepo &&
					<MenuItem  preventClose={true} attributes={{className:'is--warning'}} onClick={handleContextMenuClick} data={{action: 'Archive'}}>        						
						Send To Trash					
					</MenuItem>
				}


			  </ContextMenu>
		)
	}
}


export default RecentContextMenu
import {getRepoforId} from '../../utils/getRepoforId'
import {showConfirmModal} from '../../actions/modals'
import store from '../../store'
//https://github.com/react-dnd/react-dnd

function onDrop(props,droppedItem,multiselectItems) {	
	let promises=[]
	if(multiselectItems){	
		Object.keys(multiselectItems).map((key)=>{
			const item=multiselectItems[key]
			promises.push(props.moveToRepo(item,props.repo.id))
			return null
		})
	}else{
		promises.push(props.moveToRepo(droppedItem,props.repo.id))
	}

	Promise.all(promises).then(function(response) {
		let itemCount=1
		if(multiselectItems){
			itemCount=Object.keys(multiselectItems).length
		}
		let notificationMessage=''
		if(itemCount>1){
			 notificationMessage=`${itemCount} items to ${props.repo.name}`
		}else{
			notificationMessage=`${droppedItem.name || droppedItem.body} to ${props.repo.name}`
		}

		props.showNotification(notificationMessage,'Moved','default')
		if(props.hideModal){
			props.hideModal()	
		}
	})
}


const RepoTarget = {
	canDrop(props, monitor) {
		const repo = props.repo
		const draggedItem=monitor.getItem().cellItem
		const containingRepo = getRepoforId(draggedItem.repo)
		if(containingRepo && (containingRepo.role ==='viewer' || containingRepo.role ==='consumer')){//can't drop if viewer or consumer
			return false
		}else if(draggedItem.repo === repo.id){ 
			if(!draggedItem.folder && !draggedItem.parent){//if dropping onto item's parent repo can only drop if has a folder/parent (will move item to route of repo)
				return false
			}else{
				return true
			}			
		}else if(repo.disabled){//can't drop on disabled repo
			return false
		}else if(repo.joined && (repo.role === 'editor' || repo.role === 'admin' || repo.role === 'author')){ //can't drop if haven't joined repo
			return true
		}else{
			return false
		}
	},
	drop(props, monitor) {
		const droppedItem=monitor.getItem().cellItem
		const multiselectItems=monitor.getItem().multiselectItems
		const inSharedRepo=monitor.getItem().inSharedRepo
		const inPublishedRepo=monitor.getItem().inPublishedRepo
		if(inSharedRepo){
			store.dispatch(showConfirmModal(multiselectItems,onDrop.bind(this,props,droppedItem,multiselectItems),'moveFromSharedRepo'))
		}else if(inPublishedRepo && droppedItem.repo !==props.repo.id){
			store.dispatch(showConfirmModal(multiselectItems,onDrop.bind(this,props,droppedItem,multiselectItems),'moveFromPublishedRepo'))
		}else{
			onDrop(props,droppedItem,multiselectItems)
		}
	}
}

export default RepoTarget


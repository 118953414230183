import React from 'react'
import moment from 'moment'
import {formatDate} from '../../../../utils/formatDate'
import QuestionCountBadge from '../../../uiKit/QuestionCountBadge'
import {getQuestionBodyLabel} from '../../../../utils/getQuestionBodyLabel'
import {getRepoforId} from '../../../../utils/getRepoforId'
import Icon from '../../../misc/Icons'

class SectionHomeAddToClassCell extends React.PureComponent{
	
	render(){
		const {item,addToQueue}=this.props
		let formattedActivity = formatDate(moment(item.modified))
		let formattedTitle
		let questionCount
		if(item.name){
			formattedTitle = item.name
			questionCount = item.questions.length
		}else{
			formattedTitle = getQuestionBodyLabel(item)  
			questionCount = null
		}
 

		let inSharedRepo = false 
		if(item.repo){
			const containingRepo=getRepoforId(item.repo)
			if(containingRepo){
				inSharedRepo=containingRepo.shared
			}
		}

		return(
			<div key={item.id} className={'sectionHome-addToClassCell ' + (questionCount ? 'sectionHome-addToClassCell--set' : 'sectionHome-addToClassCell--question')} onClick={() =>{addToQueue(item)}}>
				<div className='sectionHome-addToClassCell-addBtn'>
				Add
				</div>

				<div className='sectionHome-addToClassCell-contents'>
					{questionCount && 
						<QuestionCountBadge color="blueLight" questionCount={questionCount} />
					}
					<div className="sectionHome-addToClassCell-title"> 
						{formattedTitle} {inSharedRepo && <Icon name='shared'/>}
					</div>

					<div className="sectionHome-addToClassCell-timestamp">
						Edited {formattedActivity}
					</div>
		  			
				</div>
			</div>
		)

	}
}


export default SectionHomeAddToClassCell



const FriendlyFractionSpec = {
	group: 'inline',
	inline: true,
	content: "friendlyFractionNumerator friendlyFractionDenominator",
	isolating: true,
	toDOM(node) {
		return ['span',{class:'friendlyFraction'}, 0]},
	parseDOM: [{tag: 'span.friendlyFraction'}],
}
export default FriendlyFractionSpec

import React from 'react'
import ScorePill from '../../uiKit/ScorePill'
import SlideGrid from '../../SlideGrid'
import sortBy from 'lodash/sortBy' 
import orderBy from 'lodash/orderBy' 

class SyncLiveViewSetReportStudentCell extends React.Component{
	render(){		
		const {totalResponses,isOnline}=this.props
		return(								
			<div className={'syncLiveView-setReport-studentList-studentCell ' + (totalResponses===0 ? ' syncLiveView-setReport-studentList-studentCell--noGradedResponses':'') + (isOnline ? ' syncLiveView-setReport-studentList-studentCell--online':' syncLiveView-setReport-studentList-studentCell--offline')}>
				<div className='syncLiveView-setReport-studentList-studentCell-name'>
					{this.props.displayName}
				</div>
				<div className='syncLiveView-setReport-studentList-studentCell-score'>
					{totalResponses!==0 &&		
						<ScorePill score={this.props.score} size='small' />
					}						
					{totalResponses===0 && 
						<div className='syncLiveView-setReport-studentList-studentCell-score-noGradedResponsesDash'>
							-
						</div>
					}						
				</div>
			</div>		
		)
	}
}

class SyncLiveViewSetReport extends React.Component{

	constructor(){
		super()
		this.state={			
			studentSortOrder: 'name',
		}
	}

	render(){		
		const {students,setPoll,onlineStudents}=this.props
		const {studentSortOrder}=this.state
		let sortedStudents=students 
		if(studentSortOrder==='name'){
				sortedStudents=sortBy(sortedStudents,[function(o) { 
			return o.displayName.toString().toLowerCase()
		}])
		}else{
			sortedStudents = orderBy(sortedStudents, [function(student) {
				let studentPercentageCorrect=0
				if(setPoll && setPoll.aggregatedResponsesByStudent &&setPoll.aggregatedResponsesByStudent[student.id]){
					const total=setPoll.aggregatedResponsesByStudent[student.id].totalResponses
					const correct=setPoll.aggregatedResponsesByStudent[student.id].totalCorrect
					if(total!==0){
						studentPercentageCorrect=100*(correct/total)
					}
				}	
				return studentPercentageCorrect		
				},function(student) {
			return student.displayName.toString().toLowerCase()}],['desc', 'asc'])		
		}
			return(				
				<div className='syncLiveView-setReport'>
					{!this.props.classSafeMode &&
						<div className='syncLiveView-setReport-studentSummary'>						
							<div className='syncLiveView-setReport-sectionHeader'>
								Set Summary
								<div className='syncLiveView-setReport-studentSortToggle'>
									<div className={'syncLiveView-setReport-studentSortToggle-toggle' + (this.state.studentSortOrder === 'name' ? ' setReport-studentSortToggle-toggle--active' : '')} 
										onClick={()=>{this.setState({studentSortOrder: 'name'})}}>
										A-Z
									</div>
									<div className={'syncLiveView-setReport-studentSortToggle-toggle' + (this.state.studentSortOrder === 'score' ? ' setReport-studentSortToggle-toggle--active' : '')} 
										onClick={()=>{this.setState({studentSortOrder: 'score'})}}>
										High-Low
									</div>
								</div>
							</div>

							<div className='syncLiveView-setReport-studentListContainer'>							
								<div className='syncLiveView-setReport-studentList-blockLine'/>
								<div className='syncLiveView-setReport-studentList'>

									{sortedStudents.map((student)=>{
										let studentPercentageCorrect=0
										let total=0 //responses excluding surveys
										let totalSurveyResponses=0
										if(setPoll && setPoll.aggregatedResponsesByStudent &&setPoll.aggregatedResponsesByStudent[student.id]){
											total=setPoll.aggregatedResponsesByStudent[student.id].totalResponses
											totalSurveyResponses=setPoll.aggregatedResponsesByStudent[student.id].totalSurveyResponses
											const correct=setPoll.aggregatedResponsesByStudent[student.id].totalCorrect
											if(total!==0){
												studentPercentageCorrect=100*(correct/total)
											}
											
										}
										const isOnline=(onlineStudents && onlineStudents[student.id])?true:false

										return(
											<SyncLiveViewSetReportStudentCell isOnline={isOnline} totalResponses={total} totalSurveyResponses={totalSurveyResponses} key={student.id} displayName={student.displayName} score={studentPercentageCorrect} />
											)

									})}

								</div>				
							</div>
						</div>
					}
					<div className='syncLiveView-setReport-questionSummary'>						
						<div className='syncLiveView-setReport-sectionHeader'>
							Set Review
						</div>
						{this.props.setPoll &&
							<SlideGrid  
								showAnsweredOnly={false}
								showExpandedQuestionSlideModal={()=>{}}
								section={this.props.currentSection}
								pollId={this.props.setPoll.id}
								questionsAndPollsList={this.props.questionsAndPollsList}
								currentQuestionIndex={this.props.currentQuestionIndex}
								gridColumns={3}
								availableWidth={860}
							/>
						}
					</div>
				</div>
				
			)

	}
}


export default SyncLiveViewSetReport


import React, { Component} from 'react'
import ModalWrapper from './ModalWrapper'
import Button from '../uiKit/Button'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'
import {getConfirmModalSettings} from '../../utils/getConfirmModalSettings'
import FeatureModalSelectControl from '../featureModals/FeatureModalSelectControl'
import FeatureModalSelectControlGroup from '../featureModals/FeatureModalSelectControlGroup'
import {saveSettingToLocalStorage} from '../../utils/saveSettingToLocalStorage'

//Modal shown to get user confirm they want to do an action 
//e.g. are you sure you want to delete this repo?
//They have a confirmItem (e.g. a repo object), accept function(what to do if the user says yes e.g. delete repo) and actionType used for the presentational logic
//Some confirm modals have a "Do not show me this again" check box e.g. moving content out of published repos
//do not show again works by saving a setting in local storage
//This component has grown over the years, could split out into smaller components 

class ConfirmModal extends Component {
	
	constructor() {
		super()
		this.handleAccept=this.handleAccept.bind(this)
		this.setConfirmModalSetting=this.setConfirmModalSetting.bind(this)
		const confirmModalSettings=getConfirmModalSettings()
		this.state={
			buttonDisabled:false,
			confirmModalSettings:confirmModalSettings,
			confirmModalCheckboxChecked:false
		}
	}

	setConfirmModalSetting(setting,value){
		saveSettingToLocalStorage('confirmModalSettings',setting,!value)
		this.setState({confirmModalCheckboxChecked:value})
	}

	UNSAFE_componentWillMount() {
		//if user has checked "do not show me again" then do not show the modal, move straight to executing accept
		if(this.props.actionType==='moveFromSharedRepo' && !this.state.confirmModalSettings.showMoveFromSharedRepoModal){
			this.props.accept()
		}else if(this.props.actionType==='moveFromPublishedRepo' && !this.state.confirmModalSettings.showMoveFromPublishedRepoModal){
			this.props.accept()
		}else if(this.props.actionType==='trash' && !this.state.confirmModalSettings.showSendToTrashModal){
			this.props.accept()
		}else if(this.props.actionType==='archive' && !this.state.confirmModalSettings.showArchiveModal){
			this.props.accept()
		}else if(this.props.actionType==='leave' && !this.state.confirmModalSettings.showLeaveRepoModal){
			this.props.accept()
		}else if(this.props.actionType==='archiveRepoItem' && !this.state.confirmModalSettings.showArchiveRepoItemModal){
			this.props.accept(this.props.confirmItem)
		}
	}

	handleAccept(items){
		this.setState({buttonDisabled:true}) //can't accept twice
		this.props.accept(items)
	}

	render() {
		const {buttonDisabled	}=this.state
		const {confirmItem,actionType}=this.props
		//each actionType has a slightly different UI
		if(actionType==='trash' && this.state.confirmModalSettings.showSendToTrashModal){
			return (
				<div className='confirmModal-modal'>
					<ModalWrapper
						{...this.props}
						title='Send to Trash'						
						showModalHeader={false}
					>
						<div className='confirmModal'>
							<div className='confirmModal-h1'>
								Are you sure you want to <br/><b>send this to trash?</b>
							</div>
							<div className='confirmModal-h2'>
								You can restore trashed items at any point.
							</div>
							<div className='confirmModal-h2'>
								You will be directed back Home if you send this trash.
							</div>							
							<FeatureModalSelectControlGroup>
								<FeatureModalSelectControl onClick={()=>{this.setConfirmModalSetting('showSendToTrashModal',!this.state.confirmModalCheckboxChecked)}}  checked={this.state.confirmModalCheckboxChecked}checkbox label='Do not show this warning again' />
							</FeatureModalSelectControlGroup>						 						
							<div className='confirmModal-buttonSet'>
								<Button name='confirmModalConfirm' disabled={buttonDisabled} label='Yes, send to Trash' onClickFunction={this.handleAccept}/>
								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
							</div>
						</div>	
					</ModalWrapper>
				</div>
			)
		}else if(actionType==='delinkSection'){ //delink from Google Classroom
			return (
				<div className='confirmModal-modal has--dynamicHeight confirmModal-modal--delinkSection'>
					<ModalWrapper
						{...this.props}
						title='Delink GC'
						showModalHeader={false}
					>		
						<div className='confirmModal'>	
							<div className='confirmModal-h1'>
								Are you sure you want to <b>delink this class ({confirmItem.name}) from Google Classroom?</b>
							</div>
							<div className='confirmModal-h2'>
								If you delink this class, you will not be able to sync the class roster with Google Classroom.
							</div>
							<div className='confirmModal-h2'>
								However, after delinking, you will be able to add and remove students manually to the class.
							</div>
							<div className='confirmModal-h2'>
								This cannot be undone. Your other classes will not be affected.
							</div>							
							<br/>																	
							<div className='confirmModal-buttonSet'>
								<Button name='confirmModalConfirm' disabled={buttonDisabled} label='Yes, delink from Google Classroom'  onClickFunction={this.handleAccept}/>
								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
							</div>
						</div>
					</ModalWrapper>
				</div>
			)
		}else if(actionType==='batchArchive'){
			return (
				<div className='confirmModal-modal has--dynamicHeight'>
					<ModalWrapper
						{...this.props}
						title='Send to Trash'
						showModalHeader={false}
					>
						<div className='confirmModal'>							
							<div className='confirmModal-h1'>
								Are you sure you want to <b>send the following items to trash?</b>
							</div>
							<div className='confirmModal-dynamicItemList'>
								{Object.keys(this.props.confirmItem).map((key,index) =>{
									const item=this.props.confirmItem[key]
									return(
									 <div className='confirmModal-item' key={index}>{item.name || getQuestionBodyLabel(item)}</div>              
								)})}						
							</div>																				
							<div className='confirmModal-buttonSet'>
								<Button name='confirmModalConfirm' disabled={buttonDisabled} label={`Yes, send ${Object.keys(this.props.confirmItem).length} items to trash`}  onClickFunction={this.handleAccept}/>
								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
							</div>
						</div>
					</ModalWrapper>
				</div>
			)
		}else if(actionType==='archive' && this.state.confirmModalSettings.showArchiveModal){
			let sectionName			
			if(this.props.confirmItem && this.props.confirmItem.name.length < 25){
				sectionName = this.props.confirmItem.name				
			}else if(this.props.confirmItem && this.props.confirmItem.name.length >= 25){	
				sectionName = this.props.confirmItem.name.substring(25,0) + '...'					
			}
			return (
				<div className='confirmModal-modal'>
					<ModalWrapper
						{...this.props}
						title={`Archive ${this.props.confirmItem.name}`}						
						showModalHeader={false}
					>
						<div className='confirmModal'>					
							<div className='confirmModal-h1'>
								Are you sure you want to archive <b>{sectionName}?</b>
							</div>							
							<div className='confirmModal-h2'>
								All student data and class history will be stored safely.
							</div>
							<div className='confirmModal-h2'>
								You can unarchive any classes, or permanently delete them, by clicking on 'Edit' next to Your Classes on the left-hand navigation.
							</div>
							<FeatureModalSelectControlGroup>
								<FeatureModalSelectControl onClick={()=>{this.setConfirmModalSetting('showArchiveModal',!this.state.confirmModalCheckboxChecked)}}  checked={this.state.confirmModalCheckboxChecked} checkbox label='Do not show this warning again' />
							</FeatureModalSelectControlGroup>						 							
							<div className='confirmModal-buttonSet'>
								<Button name='confirmModalConfirm' disabled={buttonDisabled} label= 'Yes, archive Class' onClickFunction={this.handleAccept}/>
								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
							</div>							
						</div>
					</ModalWrapper>
				</div>
			)
		}else if(actionType==='emptyTrash'){
		 return (
				<div className='confirmModal-modal'>
					<ModalWrapper
						{...this.props}							
						showModalHeader={false}
					>
						<div className='confirmModal'>           
							<div className='confirmModal-h1'>
								Are you sure you want to <b>empty your Trash?</b>
							</div>
							<div className='confirmModal-h2'>
								Everything in your trash will be permanently deleted.
							</div>
							<div className='confirmModal-h2'>
								This cannot be undone.
							</div>
							<div className='confirmModal-buttonSet'>
								<Button name='confirmModalConfirm' disabled={buttonDisabled} label= 'Yes, I want to empty my Trash' onClickFunction={this.handleAccept}/>
								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
							</div>
						</div>
					</ModalWrapper>
				</div>
			)
		}else if(actionType==='leave' && this.state.confirmModalSettings.showLeaveRepoModal){ //Leave a shared repo
			let repoName
			if(this.props.confirmItem && this.props.confirmItem.name.length < 40){
				repoName = this.props.confirmItem.name				
			}else if(this.props.confirmItem && this.props.confirmItem.name.length >= 40){	
				repoName = this.props.confirmItem.name.substring(37,0) + '...'				
			}
		 return (
				<div className='confirmModal-modal'>
					<ModalWrapper
						{...this.props}				
						showModalHeader={false}
					>
						<div className='confirmModal'>           
							<div className='confirmModal-h1'>
								Are you sure you want to <b>leave {repoName}?</b>
							</div>							
							<div className='confirmModal-h2'>
								To regain access to this pack, you will need to be re-invited by a member.
							</div>							
							<FeatureModalSelectControlGroup>
								<FeatureModalSelectControl onClick={()=>{this.setConfirmModalSetting('showLeaveRepoModal',!this.state.confirmModalCheckboxChecked)}}  checked={this.state.confirmModalCheckboxChecked} checkbox label='Do not show this warning again' />
							</FeatureModalSelectControlGroup>						 
							<div className='confirmModal-buttonSet'>
								<Button name='confirmModalConfirm' disabled={buttonDisabled} label= 'Yes, I want to leave' onClickFunction={this.handleAccept}/>
								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
							</div>
						</div>
					</ModalWrapper>
				</div>
			)
		}else if(actionType==='archiveRepoItem' && this.state.confirmModalSettings.showArchiveRepoItemModal){
			let itemName // original
			let itemLabel // cutdown if too long
			if(this.props.confirmItem && this.props.confirmItem.name){
				itemName = this.props.confirmItem.name
			}else if(this.props.confirmItem && this.props.confirmItem.body){
				itemName = this.props.confirmItem.body	
			}
			if(itemName && itemName.length < 45){
				itemLabel = itemName
			}else if(itemName && itemName.length >= 45){	
				itemLabel = itemName.substring(45,0) + '...'					
			}
		 return (
				<div className='confirmModal-modal'>
					<ModalWrapper
						{...this.props}												
						showModalHeader={false}
					>
						<div className='confirmModal'>           
							<div className='confirmModal-h1'>
								Are you sure you want to <b>send {itemLabel} to trash?</b>
							</div>
							<div className='confirmModal-h2'>
								If you send this item to trash, it will be removed from every members queue.
							</div>
							    
							<FeatureModalSelectControlGroup>
								<FeatureModalSelectControl  onClick={()=>{this.setConfirmModalSetting('showArchiveRepoItemModal',!this.state.confirmModalCheckboxChecked)}}  checked={this.state.confirmModalCheckboxChecked} checkbox label='Do not show this warning again' />
							</FeatureModalSelectControlGroup>						 

							<div className='confirmModal-buttonSet'>
								<Button name='confirmModalConfirm' disabled={buttonDisabled} label= 'Yes, I want to send it to trash' onClickFunction={()=>{this.handleAccept(this.props.confirmItem)}} />
								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
							</div>
						</div>
					</ModalWrapper>
				</div>
			)
		}else if(actionType==='unPublishRepo'){
			let itemName // original
			let itemLabel // cutdown if too long
			if(this.props.confirmItem && this.props.confirmItem.name){
				itemName = confirmItem.name
			}		
			if(itemName && itemName.length < 45){
				itemLabel = confirmItem.name
			}else if(itemName && itemName.length >= 45){	
				itemLabel = confirmItem.name.substring(45,0) + '...'					
			}
		 return (
				<div className='confirmModal-modal has--dynamicHeight'>
					<ModalWrapper
						{...this.props}						
						showModalHeader={false}
					>
						<div className='confirmModal'>           
							<React.Fragment>
								<div className='confirmModal-h1'>
									Are you sure you want to unpublish <b>{itemLabel}</b>?
								</div>								
								<div className='confirmModal-h2'>
									If you unpublish this pack, it will no longer be publicly accessible via the share URL.
								</div>
								<div className='confirmModal-h2'>
									Any followers of the pack will immediately lose access.
								</div>
								<div className='confirmModal-h2'>
									All of the pack's contents will also be immediately removed from other people's class queues.
								</div>
								<br/>
								<br/>
							</React.Fragment>									
							<div className='confirmModal-buttonSet'>
								<Button name='confirmModalConfirm' disabled={buttonDisabled} label='Yes, I want to unpublish it' onClickFunction={()=>{this.handleAccept()}} />
								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
							</div>												
						</div>
					</ModalWrapper>
				</div>
			)
		}else if(actionType==='moveFromPublishedRepo' && this.state.confirmModalSettings.showMoveFromPublishedRepoModal){		
			let multipleItems = false
			const numberOfItems=Object.keys(confirmItem).length
			if(numberOfItems > 1){
				multipleItems = true
			}
			const firstItem=confirmItem[Object.keys(confirmItem)[0]]
			let itemName
			let itemLabel
			if(!multipleItems && !firstItem.body){
				itemName = firstItem.name
			}
			if(!multipleItems && firstItem.body){
				itemName = firstItem.body
			}
			if(!multipleItems && itemName && itemName.length < 45){
				itemLabel = itemName
			}else if(!multipleItems && itemName && itemName.length >= 45){	
				itemLabel = itemName.substring(45,0) + '...'					
			}
			let buttonLabel
			if(multipleItems){
				buttonLabel = 'Yes, I want to move them'
			}
			if(!multipleItems){
				buttonLabel = 'Yes, I want to move it'
			}
		 return (
				<div className='confirmModal-modal'>
					<ModalWrapper
						{...this.props}						
						showModalHeader={false}
					>
						<div className='confirmModal'>           
							{!multipleItems &&
								<React.Fragment>
									<div className='confirmModal-h1'>
										Are you sure you want to move <b>{itemLabel}</b> out of this published pack?
									</div>								
									<div className='confirmModal-h2'>
										If you move this item, it will no longer be publicly accessible and any followers will lose access to it.
									</div>
									<div className='confirmModal-h2'>
										If anybody has added this item to their class queues, it will be removed from those queues.
									</div>
								</React.Fragment>						
							}							
							{multipleItems &&
								<React.Fragment>
									<div className='confirmModal-h1'>
										Are you sure you want to move these items out of a published pack?
									</div>						
									<div className='confirmModal-h2'>
										If you move these items, they will no longer be publicly accessible and any followers will lose access to them.
									</div>
									<div className='confirmModal-h2'>
										If anybody has added any of these items to their class queues, they will be removed from those queues.
									</div>
								</React.Fragment>
							}
							<FeatureModalSelectControlGroup>
								<FeatureModalSelectControl  onClick={()=>{this.setConfirmModalSetting('showMoveFromPublishedRepoModal',!this.state.confirmModalCheckboxChecked)}}  checked={this.state.confirmModalCheckboxChecked} checkbox label='Do not show this warning again' />
							</FeatureModalSelectControlGroup>	
							<div className='confirmModal-buttonSet'>
								<Button name='confirmModalConfirm' disabled={buttonDisabled} label={buttonLabel} onClickFunction={()=>{this.handleAccept()}} />
								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
							</div>												
						</div>
					</ModalWrapper>
				</div>
			)
		}else if(actionType==='moveFromSharedRepo' && this.state.confirmModalSettings.showMoveFromSharedRepoModal){		
			let multipleItems = false
			const numberOfItems=Object.keys(confirmItem).length
			if(numberOfItems > 1){
				multipleItems = true
			}
			const firstItem=confirmItem[Object.keys(confirmItem)[0]]

			let itemName
			let itemLabel
			if(!multipleItems && !firstItem.body){
				itemName = firstItem.name
			}
			if(!multipleItems && firstItem.body){
				itemName = firstItem.body
			}
			if(!multipleItems && itemName && itemName.length < 45){
				itemLabel = itemName
			}else if(!multipleItems && itemName && itemName.length >= 45){	
				itemLabel = itemName.substring(45,0) + '...'					
			}

			let buttonLabel
			if(multipleItems){
				buttonLabel = 'Yes, I want to move them'
			}else{
				buttonLabel = 'Yes, I want to move it'
			}
		 return (
				<div className='confirmModal-modal'>
					<ModalWrapper
						{...this.props}						
						showModalHeader={false}
					>
						<div className='confirmModal'>           
							{!multipleItems &&
								<React.Fragment>
									<div className='confirmModal-h1'>
										Are you sure you want to move <b>{itemLabel}</b> out of this shared pack?
									</div>								
									<div className='confirmModal-h2'>
										If you move this item, members of the shared pack will lose access to it.
									</div>
								</React.Fragment>						
							}							
							{multipleItems &&
								<React.Fragment>
									<div className='confirmModal-h1'>
										Are you sure you want to move these items out of a shared pack?
									</div>						
									<div className='confirmModal-h2'>
										If you move these items, members of the current pack will lose access to them.
									</div>
								</React.Fragment>
							}
							<FeatureModalSelectControlGroup>
								<FeatureModalSelectControl  onClick={()=>{this.setConfirmModalSetting('showMoveFromSharedRepoModal',!this.state.confirmModalCheckboxChecked)}}  checked={this.state.confirmModalCheckboxChecked} checkbox label='Do not show this warning again' />
							</FeatureModalSelectControlGroup>	
							<div className='confirmModal-buttonSet'>
								<Button name='confirmModalConfirm' disabled={buttonDisabled} label={buttonLabel} onClickFunction={()=>{this.handleAccept()}} />
								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
							</div>												
						</div>
					</ModalWrapper>
				</div>
			)
		}else if(actionType==='delete' && confirmItem.joined){
			let itemName // original
			let itemLabel // cutdown if too long
			if(this.props.confirmItem && this.props.confirmItem.name){
				itemName = confirmItem.name
			}			
			if(itemName && itemName.length < 45){
				itemLabel = confirmItem.name
			}else if(itemName && itemName.length >= 45){	
				itemLabel = confirmItem.name.substring(45,0) + '...'					
			}
		 return (
				<div className='confirmModal-modal'>
					<ModalWrapper
						{...this.props}						
						showModalHeader={false}
					>
						<div className='confirmModal'>           
							<div className='confirmModal-h1'>
								Are you sure you want to <b>delete {itemLabel}?</b>
							</div>
							<div className='confirmModal-h2 confirmModal-h2--strong'>
								The pack and its contents will be permanently deleted.
							</div>
							<div className='confirmModal-h2'>
								This cannot be undone.
							</div>
							<FeatureModalSelectControlGroup>
								<FeatureModalSelectControl onClick={()=>{this.setState({confirmModalCheckboxChecked:!this.state.confirmModalCheckboxChecked})}}   checked={this.state.confirmModalCheckboxChecked} checkbox label='Yes, I am sure I want to delete this pack.' />
							</FeatureModalSelectControlGroup>						 						    							
							<div className='confirmModal-buttonSet'>
								<Button name='confirmModalConfirm' disabled={!this.state.confirmModalCheckboxChecked} label= 'Yes, I want to delete it' onClickFunction={()=>{this.handleAccept(this.props.confirmItem)}} />
								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
							</div>
						</div>
					</ModalWrapper>
				</div>
			)
		}else if(actionType==='delete' && !confirmItem.joined){
			let itemType=''
			let itemTitle // original
			if(confirmItem.ancestors){
				itemType = 'Folder'
				itemTitle=confirmItem.name
			}else if(confirmItem.name){
				itemType = 'Set'
				itemTitle=confirmItem.name
			}else if(confirmItem.choices){
				itemType = 'Question'
				itemTitle=getQuestionBodyLabel(confirmItem)
			}else if(confirmItem.firstName){
				itemType = 'Student'
				itemTitle= confirmItem.displayName
			}else if(confirmItem.snapshot){    
				itemType = 'Report'  
				itemTitle = confirmItem.snapshot.name || getQuestionBodyLabel(confirmItem.snapshot)
			}
			let itemLabel = itemTitle	
			if(itemTitle && itemTitle.length >= 60){	
				itemLabel = itemTitle.substring(60,0) + '...'					
			}
			return (
				<div className='confirmModal-modal'>
					<ModalWrapper
						{...this.props}						
						showModalHeader={false}
					>
						<div className='confirmModal'>           
							<div className='confirmModal-h1'>
								Are you sure you want to <b>delete {itemLabel}?</b>
							</div>							
							<div className='confirmModal-h2'>
								This cannot be undone.
							</div>
							    							
							<div className='confirmModal-buttonSet'>
								<Button name='confirmModalConfirm' disabled={buttonDisabled} label={`Permanently Delete ${itemType}`} size='xLarge' color='red' onClickFunction={()=>{this.handleAccept(this.props.confirmItem)}}/>
								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
							</div>
						</div>

					</ModalWrapper>
				</div>
			)
		}
		else{
			return null
		}
	}
}

export default ConfirmModal

//These are pack review confirm modals
//take them out for now
// else if(actionType==='logEmailAndKeepInReview'){
// 			return (
// 				<div className='confirmModal-modal'>
// 					<ModalWrapper
// 						{...this.props}						
// 						showModalHeader={false}
// 					>
// 						<div className='confirmModal'>           
// 							<div className='confirmModal-h1'>
// 								Mark as sent and keep in review: {confirmItem.repo.name}
// 							</div>							
// 							<div className='confirmModal-h2'>
// 								Make sure the email has been sent to the creator and you want to keep the pack in review. 
// 							</div>														    							
// 							<div className='confirmModal-buttonSet'>
// 								<Button name='confirmModalConfirm' disabled={buttonDisabled} label={`Email sent and keep In Review`} size='xLarge' color='blue' onClickFunction={()=>{this.handleAccept(this.props.confirmItem)}}/>
// 								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
// 							</div>
// 						</div>
// 					</ModalWrapper>
// 				</div>
// 			)
// 		}else if(actionType==='logEmailAndCancel'){
// 			return (
// 				<div className='confirmModal-modal'>
// 					<ModalWrapper
// 						{...this.props}						
// 						showModalHeader={false}
// 					>
// 						<div className='confirmModal'>           
// 							<div className='confirmModal-h1'>
// 								Mark as sent and cancel submission: {confirmItem.repo.name}
// 							</div>							
// 							<div className='confirmModal-h2'>
// 								Once the submission is cancelled the creator will have to resubmit it. This cannot be undone.
// 							</div>													    							
// 							<div className='confirmModal-buttonSet'>
// 								<Button name='confirmModalConfirm' disabled={buttonDisabled} label={`Email sent and cancel`} size='xLarge' color='red' onClickFunction={()=>{this.handleAccept(this.props.confirmItem)}}/>
// 								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
// 							</div>
// 						</div>
// 					</ModalWrapper>
// 				</div>
// 			)
// 		}else if(actionType==='logEmailAndApprove'){
// 			return (
// 				<div className='confirmModal-modal'>
// 					<ModalWrapper
// 						{...this.props}						
// 						showModalHeader={false}
// 					>
// 						<div className='confirmModal'>           
// 							<div className='confirmModal-h1'>
// 								Mark as sent and approve submission: {confirmItem.repo.name}						
// 							</div>							    							
// 							<div className='confirmModal-buttonSet'>
// 								<Button name='confirmModalConfirm' disabled={buttonDisabled} label={`Update Submission`} size='xLarge' color='red' onClickFunction={()=>{this.handleAccept(this.props.confirmItem)}}/>
// 								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
// 							</div>
// 						</div>
// 					</ModalWrapper>
// 				</div>
// 			)
// 		}else if(actionType==='cancelSubmission'){ //
			
// 			return (
// 				<div className='confirmModal-modal'>
// 					<ModalWrapper
// 						{...this.props}						
// 						showModalHeader={false}
// 					>
// 						<div className='confirmModal'>           
// 							<div className='confirmModal-h1'>
// 								Are you sure you want to Cancel submission for {confirmItem.repo.name}
// 							</div>							
							
							    							
// 							<div className='confirmModal-buttonSet'>
// 								<Button name='confirmModalConfirm' disabled={buttonDisabled} label={`Update Submission`} size='xLarge' color='red' onClickFunction={()=>{this.handleAccept(this.props.confirmItem)}}/>
// 								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
// 							</div>
// 						</div>

// 					</ModalWrapper>
// 				</div>
// 			)
// 		}else if(actionType==='approveSubmission'){
// 			return (
// 				<div className='confirmModal-modal'>
// 					<ModalWrapper
// 						{...this.props}						
// 						showModalHeader={false}
// 					>
// 						<div className='confirmModal'>           
// 							<div className='confirmModal-h1'>
// 								Are you sure you want to APPROVE submission for {confirmItem.repo.name}
// 							</div>							
							
							    							
// 							<div className='confirmModal-buttonSet'>
// 								<Button name='confirmModalConfirm' disabled={buttonDisabled} label={`Update Submission`} size='xLarge' color='red' onClickFunction={()=>{this.handleAccept(this.props.confirmItem)}}/>
// 								<Button name='confirmModalCancel' label='Cancel'  onClickFunction={() =>{this.props.hideModal()}}/>
// 							</div>
// 						</div>

// 					</ModalWrapper>
// 				</div>
// 			)
// 		}
export function formatVideoDurationWithMilliseconds(duration){ //duration in ms
	let centiseconds = ("0" + (Math.floor(duration / 10) % 100)).slice(-2)
	let seconds = ("0" + (Math.floor(duration / 1000) % 60)).slice(-2)
	let minutes = ("0" + (Math.floor(duration / 60000) % 60)).slice(-2)
	let hours = Math.floor(duration / 3600000)
	if(hours>0){
		return `${hours}:${minutes}:${seconds}.${centiseconds.substring(0,1)}0`
	}else{
		return `${minutes}:${seconds}.${centiseconds.substring(0,1)}0`
	}
  
}

import {createNewSet} from '../../actions/sets'
 
const SET_TITLE_MAX_CHARACTER_LENGTH=60

export function convertQuestionIntoSet(question) {

	return(dispatch) => {
		const nowDate = new Date()
		const questionBody=question.body
		const newSetData = {
			'folder': question.folder,
			'name': questionBody.substring(0, SET_TITLE_MAX_CHARACTER_LENGTH),
			'description': '',
			'questions': [question],
			'clientModified': nowDate,
			'archived': false,
			'userCreated': nowDate,
			'repo':null,
			'lastEditedAt':nowDate
		}

		return dispatch(createNewSet(newSetData)).then((response) => {
			return response
		})
	}
}






import {updateControl} from '../actions/control'
import store from '../store'
import {showLiveViewModal} from './liveView/showLiveViewModal'
import {updateRecentActivities} from '../actions/recentActivities'
import {getDefaultControlObject} from './getDefaultControlObject'



export function resumePlayingQuestionPoll(poll) {
	updateControlObject(poll)
	store.dispatch(updateRecentActivities(poll.question,'question','play'))
	showLiveViewModal()
}





 
function updateControlObject(questionPoll) {

	const nowDate=new Date()
	const defaultControlObject=getDefaultControlObject()

	const controlObject = {
		...defaultControlObject,
		'section': questionPoll.section,
		'setPoll': null,
		'setPollPoll':null,
		'currentPoll': questionPoll.id,
		'snapshot':questionPoll.snapshot,
		'mobileAccepted': false,
		'scanning':false,
		'revealAnswer': false,
		'showGraph': false,
		'controlled': nowDate,
		'modified': nowDate,
		'paused':false,
		'showCardAssignment':false,



	}
	store.dispatch(updateControl(controlObject))
 

}






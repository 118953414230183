import React, { Component } from 'react'

class RepoReleaseModalVisualSideNavItem extends Component {

	render() {

		return ( 
			<div style={{width: `${this.props.width}px`}} className='repoReleaseModal-visual-sideNav-item' />
		)
	}
}

class RepoReleaseModalVisualSideNav extends Component {

	render() {


		return ( 
			<div className='repoReleaseModal-visual-sideNav' >
				<div style={{top: '185px'}} className='repoReleaseModal-visual-sideNav-activeItem' />
				<RepoReleaseModalVisualSideNavItem width='61' />
				<RepoReleaseModalVisualSideNavItem width='70' />
				<RepoReleaseModalVisualSideNavItem width='62' />				
				<RepoReleaseModalVisualSideNavItem width='64' />				
				<div className='repoReleaseModal-visual-sideNav-break' />
				<RepoReleaseModalVisualSideNavItem width='62' />
				<RepoReleaseModalVisualSideNavItem width='60' />
				<RepoReleaseModalVisualSideNavItem width='70' />
				<div className='repoReleaseModal-visual-sideNav-break' />
				<RepoReleaseModalVisualSideNavItem width='65' />
				<RepoReleaseModalVisualSideNavItem width='73' />
				<RepoReleaseModalVisualSideNavItem width='68' />
				<RepoReleaseModalVisualSideNavItem width='66' />
				<RepoReleaseModalVisualSideNavItem width='62' />
				<RepoReleaseModalVisualSideNavItem width='72' />
				<RepoReleaseModalVisualSideNavItem width='68' />

			</div>	
		)
	}
}


export default RepoReleaseModalVisualSideNav




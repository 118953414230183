import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DocumentTitle from 'react-document-title'

import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'
import intersection from 'lodash/intersection'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import { showAdminEditRepoNotesModal } from '../../actions/modals'
import { adminFetchRepos, adminUpdateRepoMetadata } from '../../actions/admin'
import gradeLevels from '../../staticData/gradeLevels'
import subjects from '../../staticData/subjects'
import ExplorerCrossFilterPage from '../../components/explorer/ExplorerCrossfilterPage'
import Button from '../../components/uiKit/Button'
import { getPublicUrlForRepo } from '../../utils/getPublicUrlForRepo'
import { formatRepoGradesToString } from '../../utils/formatRepoGradesToString'

class ExplorerContainer extends Component {
  constructor(props) {
    super(props)
    this.selectSubject = this.selectSubject.bind(this)
    this.selectGrade = this.selectGrade.bind(this)
    this.selectAllSubjects = this.selectAllSubjects.bind(this)
    this.selectAllGrades = this.selectAllGrades.bind(this)
    this.clearSelectedSubjects = this.clearSelectedSubjects.bind(this)
    this.clearSelectedGrades = this.clearSelectedGrades.bind(this)
    this.filterRepos = this.filterRepos.bind(this)
    this.toggleEnglishLanguageFilter = this.toggleEnglishLanguageFilter.bind(this)
    this.updateRepoRating = this.updateRepoRating.bind(this)
    this.selectInternalRating = this.selectInternalRating.bind(this)
    this.selectAllInternalRatings = this.selectAllInternalRatings.bind(this)
    this.clearSelectedInternalRatings = this.clearSelectedInternalRatings.bind(this)
    this.changeSortOrder = this.changeSortOrder.bind(this)
    this.selectMonth = this.selectMonth.bind(this)
    this.onClickCsv = this.onClickCsv.bind(this)
    this.calculateCsvData = this.calculateCsvData.bind(this)

    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    let monthString = `${month}`
    if (month < 10) {
      monthString = `0${month}`
    }

    this.state = {
      selectedSubjects: {},
      selectedGrades: {},
      selectedMonths: {},
      englishLanguageFilter: false,
      filteredRepos: props.repos || null,
      selectedInternalRatings: {},
      sortedBy: 'publishedAt',
      sortOrder: 'descending',
      year,
      month: monthString,
      csvData: [],
    }
  }

  UNSAFE_componentWillMount() {
    this.props.adminFetchRepos()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.repos.length === 0 && nextProps.repos.length !== 0) {
      this.setState({ filteredRepos: nextProps.repos })
    }
  }

  calculateCsvData() {
    const csvData = []
    const headerRow = [
      'Repo ID',
      'Repo Name',
      'Published',
      'Repo URL',
      'Rating',
      'External Rating',
      'ReviewCount',
      'Language',
      'Subject',
      'Subject other',
      'Grade',
      'Author',
      'email',
      'Author ID',
      'Feb Responses',
    ]
    csvData.push(headerRow)

    const englishRepos = filter(this.props.repos, (repo) => {
      if (repo.language === 'en' && repo.subject.name === 'Other') {
        return repo
      }
    })

    englishRepos.forEach((repo) => {
      const url = getPublicUrlForRepo(repo)
      let rating = '-'
      if (repo.explorerMetadata && repo.explorerMetadata.rating) {
        rating = repo.explorerMetadata.rating
      }
      const grade = formatRepoGradesToString(repo.grade)

      let febCount = 0
      if (repo.explorerMetadata && repo.explorerMetadata.metrics && repo.explorerMetadata.metrics['2020'] && repo.explorerMetadata.metrics['2020']['02']) {
        febCount = repo.explorerMetadata.metrics['2020']['02'].responseCount
      }
      const row = [
        repo.id,
        repo.name,
        repo.publishedAt,
        url,
        rating,
        repo.rating,
        repo.reviewCount,
        repo.language,
        repo.subject.name,
        repo.subjectOther,
        grade,
        repo.author.username,
        repo.author.email,
        repo.author.id,
        febCount,
      ]
      csvData.push(row)
    })
    return csvData
  }

  onClickCsv() {
    const csvData = this.calculateCsvData()
    const btn = this.refs.csv
    this.setState({ csvData }, () => { btn.link.click() })
  }

  filterRepos() {
    const unfilteredRepos = this.props.repos
    let filteredRepos = unfilteredRepos
    const { selectedSubjects } = this.state
    const { selectedMonths } = this.state
    // const selectedGrades=this.state.selectedGrades
    const { selectedInternalRatings } = this.state

    const subjects = Object.keys(this.state.selectedSubjects)
    const grades = Object.keys(this.state.selectedGrades)
    const ratings = Object.keys(this.state.selectedInternalRatings)
    const months = Object.keys(this.state.selectedMonths)

    if (subjects.length !== 0) {
      filteredRepos = filter(filteredRepos, (repo) => {
        if (repo.subject && selectedSubjects[repo.subject.id]) {
          return repo
        }
      })
    }
    if (grades.length !== 0) {
      filteredRepos = filter(filteredRepos, (repo) => {
        if (intersection(repo.grade, grades).length > 0) {
          return repo
        }
      })
    }
    if (ratings.length !== 0) {
      filteredRepos = filter(filteredRepos, (repo) => {
        if (
          selectedInternalRatings.noRating &&
          (
            !repo.explorerMetadata ||
            (!repo.explorerMetadata.rating && repo.explorerMetadata.rating !== 0) ||
            repo.explorerMetadata.rating === -1
          )
        ) {
          return repo
        }

        if (
          repo.explorerMetadata &&
          (repo.explorerMetadata.rating || repo.explorerMetadata.rating === 0) &&
          selectedInternalRatings[repo.explorerMetadata.rating]
        ) {
          return repo
        }
      })
    }
    if (months.length !== 0) {
      filteredRepos = filter(filteredRepos, (repo) => {
        const { publishedAt } = repo
        const publishedMonth = moment(publishedAt).format('MMM YYYY')
        if (selectedMonths[publishedMonth]) {
          return repo
        }
      })
    }

    if (this.state.englishLanguageFilter) {
      filteredRepos = filter(filteredRepos, (repo) => repo.language === 'en')
    }

    // then sort
    this.setState((prevState) => {
      const sortedRepos = this.sortRepos(filteredRepos, prevState.sortedBy, prevState.sortOrder)
      return { filteredRepos: sortedRepos }
    })
  }

  selectSubject(subjectId) {
    const { selectedSubjects } = this.state
    if (selectedSubjects[subjectId]) {
      delete selectedSubjects[subjectId]
    } else {
      selectedSubjects[subjectId] = true
    }
    this.setState({ selectedSubjects },
      () => this.filterRepos())
  }

  selectMonth(month) {
    const { selectedMonths } = this.state
    if (selectedMonths[month]) {
      delete selectedMonths[month]
    } else {
      selectedMonths[month] = true
    }
    this.setState({ selectedMonths },
      () => this.filterRepos())
  }

  selectInternalRating(rating) {
    const { selectedInternalRatings } = this.state
    if (selectedInternalRatings[rating]) {
      delete selectedInternalRatings[rating]
    } else {
      selectedInternalRatings[rating] = true
    }
    this.setState({ selectedInternalRatings },
      () => this.filterRepos())
  }

  selectAllInternalRatings() {
    const selectedRatings = {
      noRating: true,
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
    }
    this.setState({ selectedInternalRatings: selectedRatings },
      () => this.filterRepos())
  }

  clearSelectedInternalRatings() {
    this.setState({ selectedInternalRatings: {} },
      () => this.filterRepos())
  }

  selectAllSubjects() {
    const selectedSubjects = {}
    Object.keys(subjects).forEach((subjectName) => {
      const { subjectId } = subjects[subjectName]
      selectedSubjects[subjectId] = true
    })
    this.setState({ selectedSubjects },
      () => this.filterRepos())
  }

  clearSelectedSubjects() {
    this.setState({ selectedSubjects: {} },
      () => this.filterRepos())
  }

  selectGrade(grade) {
    const { selectedGrades } = this.state
    if (selectedGrades[grade]) {
      delete selectedGrades[grade]
    } else {
      selectedGrades[grade] = true
    }
    this.setState({ selectedGrades },
      () => this.filterRepos())
  }

  selectAllGrades() {
    const selectedGrades = {}
    gradeLevels.forEach((grade) => {
      selectedGrades[grade.grade] = true
    })
    selectedGrades.higher = true
    selectedGrades.other = true
    selectedGrades.staff = true
    this.setState({ selectedGrades },
      () => this.filterRepos())
  }

  clearSelectedGrades() {
    this.setState({ selectedGrades: {} },
      () => this.filterRepos())
  }

  toggleEnglishLanguageFilter() {
    this.setState(
      (prevState) => ({ englishLanguageFilter: !prevState.englishLanguageFilter }),
      () => this.filterRepos(),
    )
  }

  updateRepoRating(repoId, rating) {
    this.props.adminUpdateRepoMetadata(repoId, { rating })
  }

  sortRepos(repos, sortType, sortOrder) {
    let sortedRepos = repos
    if (sortType === 'publishedAt') {
      if (sortOrder === 'ascending') {
        sortedRepos = sortBy(sortedRepos, 'publishedAt')
      } else {
        sortedRepos = sortBy(sortedRepos, 'publishedAt').reverse()
      }
    } else if (sortType === 'repoName') {
      if (sortOrder === 'ascending') {
        sortedRepos = sortBy(sortedRepos, [function (repo) {
          return repo.name.toString().toLowerCase()
        }])
      } else {
        sortedRepos = sortBy(sortedRepos, [function (repo) {
          return repo.name.toString().toLowerCase()
        }]).reverse()
      }
    } else if (sortType === 'externalRating') {
      sortedRepos = orderBy(sortedRepos, [function (repo) {
        return repo.rating || 0
      }, function (repo) {
        return repo.name.toString().toLowerCase()
      }], [sortOrder === 'ascending' ? 'asc' : 'desc', 'asc'])
    } else if (sortType === 'rating') {
      sortedRepos = orderBy(sortedRepos, [function (repo) {
        if (
          repo.explorerMetadata &&
          (repo.explorerMetadata.rating || repo.explorerMetadata.rating === 0)
        ) {
          return repo.explorerMetadata.rating
        }
        return -1
      }, function (repo) {
        return repo.name.toString().toLowerCase()
      }], [sortOrder === 'ascending' ? 'asc' : 'desc', 'asc'])
    } else if (sortType === 'author') {
      if (sortOrder === 'ascending') {
        sortedRepos = orderBy(sortedRepos, [function (repo) {
          return repo.author.username.toString().toLowerCase()
        }, function (repo) {
          return repo.name.toString().toLowerCase()
        }], ['asc', 'asc'])
      } else {
        sortedRepos = orderBy(sortedRepos, [function (repo) {
          return repo.author.username.toString().toLowerCase()
        }, function (repo) {
          return repo.name.toString().toLowerCase()
        }], ['desc', 'asc'])
      }
    } else if (sortType === 'totalResponses') {
      sortedRepos = orderBy(sortedRepos, [function (repo) {
        if (repo.explorerMetadata && repo.explorerMetadata.metrics) {
          return repo.explorerMetadata.metrics.responseCount
        }
        return -1
      }, function (repo) {
        return repo.name.toString().toLowerCase()
      }], ['desc', 'asc'])
    } else if (sortType === 'monthResponses') {
      const { month, year } = this.state
      sortedRepos = orderBy(sortedRepos, [function (repo) {
        if (
          repo.explorerMetadata &&
          repo.explorerMetadata.metrics &&
          repo.explorerMetadata.metrics[year] &&
          repo.explorerMetadata.metrics[year][month]
        ) {
          return repo.explorerMetadata.metrics[year][month].responseCount
        }
        return -1
      }, function (repo) {
        return repo.name.toString().toLowerCase()
      }], ['desc', 'asc'])
    }

    return sortedRepos
  }

  changeSortOrder(sortType, sortOrder) {
    if (this.props.repos.length !== 0) {
      const repos = this.state.filteredRepos
      const sortedRepos = this.sortRepos(repos, sortType, sortOrder)
      this.setState({ filteredRepos: sortedRepos, sortedBy: sortType, sortOrder })
    }
  }

  render() {
    // const page = this.props.match.params.page

    return (
      <DocumentTitle title="Plickers - Explorer">

        <React.Fragment>
          {this.props.repos.length > 0 && (
          <React.Fragment>
            <div className="plickers-explorer-exportCSVButtonContainer">
              <Button tooltipLabel="Export Feb CSV" tooltipLabelTypeLabel name="exportcsv" iconName="export-csv" onClickFunction={this.onClickCsv} />
            </div>
            <CSVLink ref="csv" filename="Explorer Repo CSV Export" target="_self" data={this.state.csvData} style={{ display: 'none' }} />
          </React.Fragment>
          )}

          {this.props.repos.length > 0 && (
          <ExplorerCrossFilterPage
            reposToBeRated={this.props.reposToBeRated}
            reposRated3={this.props.reposRated3}
            reposRated4={this.props.reposRated4}
            reposRated5={this.props.reposRated5}
            repos={this.state.filteredRepos}
            selectSubject={this.selectSubject}
            selectedSubjects={this.state.selectedSubjects}
            selectAllSubjects={this.selectAllSubjects}
            clearSelectedSubjects={this.clearSelectedSubjects}
            selectGrade={this.selectGrade}
            selectedGrades={this.state.selectedGrades}
            selectAllGrades={this.selectAllGrades}
            clearSelectedGrades={this.clearSelectedGrades}
            englishLanguageFilter={this.state.englishLanguageFilter}
            toggleEnglishLanguageFilter={this.toggleEnglishLanguageFilter}
            updateRepoRating={this.updateRepoRating}
            showAdminEditRepoNotesModal={this.props.showAdminEditRepoNotesModal}
            selectInternalRating={this.selectInternalRating}
            selectedInternalRatings={this.state.selectedInternalRatings}
            selectAllInternalRatings={this.selectAllInternalRatings}
            clearSelectedInternalRatings={this.clearSelectedInternalRatings}
            sortedBy={this.state.sortedBy}
            sortOrder={this.state.sortOrder}
            changeSortOrder={this.changeSortOrder}
            loadingRepos={this.props.repos.length === 0}
            month={this.state.month}
            year={this.state.year}
            selectMonth={this.selectMonth}
            selectedMonths={this.state.selectedMonths}
          />
          )}

          {this.props.repos.length === 0 && (
          <div className="explorerCrossFilter-table-header explorerCrossFilter-table-header--loading">
            Loading explorer...
          </div>
          )}
        </React.Fragment>
      </DocumentTitle>
    )
    // )
    // }else return (
    //   <DocumentTitle title={'Explorer'}>
    //     <ExplorerDashboardPage/>
    //   </DocumentTitle>
    // )
  }
}

function mapStateToProps(state) {
  let repos = state.adminRepos
  if (process.env.REACT_APP_ENV === 'development') {
    // filter out old dodgy data!
    const startDate = '2019-11-01'

    repos = filter(repos, (repo) => {
      if (repo.userCreated > startDate) {
        return repo
      }
    })
  }

  repos = sortBy(repos, 'publishedAt').reverse()
  let reposToBeRated = repos
  reposToBeRated = filter(reposToBeRated, (repo) => {
    if (repo.language === 'en') {
      if (
        !repo.explorerMetadata ||
        (!repo.explorerMetadata.rating && repo.explorerMetadata.rating !== 0) ||
        repo.explorerMetadata.rating === -1
      ) {
        return repo
      }
    }
  })

  let reposRated3 = repos

  reposRated3 = filter(reposRated3, (repo) => {
    if (repo.language === 'en') {
      if (repo.explorerMetadata && repo.explorerMetadata.rating === 3) {
        return repo
      }
    }
  })

  let reposRated4 = repos

  reposRated4 = filter(reposRated4, (repo) => {
    if (repo.language === 'en') {
      if (repo.explorerMetadata && repo.explorerMetadata.rating === 4) {
        return repo
      }
    }
  })

  let reposRated5 = repos

  reposRated5 = filter(reposRated5, (repo) => {
    if (repo.language === 'en') {
      if (repo.explorerMetadata && repo.explorerMetadata.rating === 5) {
        return repo
      }
    }
  })

  return {
    repos,
    reposToBeRated,
    reposRated4,
    reposRated5,
    reposRated3,
  }
}

export default withRouter(connect(
  mapStateToProps,
  {
    adminFetchRepos,
    adminUpdateRepoMetadata,
    showAdminEditRepoNotesModal,
  },
)(ExplorerContainer))

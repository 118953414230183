import React, { Component } from 'react'
import { connect } from 'react-redux'
import {imageSearch,gifSearch} from '../../actions/imageSearch'
import ImageSearch from '../../components/ImageSearch'
import {saveImageSearchModalImage,hideModal} from '../../actions/modals'
import {showNotification} from '../../actions/notifications'
const MAX_FILE_SIZE=20000000


//used for add repo image modal (used to be used in set editor pre rich text)
//TODO clean out old GIF search stuff (this modal used to be used in old set editor and we experimented with GIF search)
class ImageSearchContainer extends Component {

	constructor(props){
		super()
		this.handleNewImageSearch=this.handleNewImageSearch.bind(this)
		this.handleSelectImage=this.handleSelectImage.bind(this)
		this.handleFileUpload=this.handleFileUpload.bind(this)
		this.handleLoadMore=this.handleLoadMore.bind(this)
		this.handleGifSearch=this.handleGifSearch.bind(this)
		this.handleLoadMoreGifs=this.handleLoadMoreGifs.bind(this)

		let images=[]
		if(props.imageSearchResults){
			images=props.imageSearchResults
		}
		let searchTerm=''
		if(props.imageSearchTerm){
			searchTerm=props.imageSearchTerm
		}

		this.state=({
			images:images,
			searchTerm:searchTerm,
			isSearching:false,
			noResults:false,
			searchResultCount:null, 
			count:50,
			gifLimit:10,
			offset:0,
			errorMessage:null

		})
	}


	handleGifSearch(searchTerm){
		this.setState({isSearching:true, noResults:false})
		this.props.gifSearch(searchTerm,this.state.gifLimit,0).then((response) => {
			this.setState({isSearching:false})
			if(response.data.length===0){
				this.setState({noResults:true})
			}
			let images=[]
			response.data.forEach(value => {
				images.push(this.parseGifObject(value.images))
			})
			this.setState({images:images,searchTerm:searchTerm, offset:images.length})
		})
	}

	handleLoadMoreGifs(){
		const {searchTerm,gifLimit,offset}=this.state
		this.setState({isSearching:true})
		this.props.gifSearch(searchTerm,gifLimit, offset).then((response) => {
			let images=this.state.images
			response.data.forEach(value => {
				images.push(this.parseGifObject(value.images))
			})
			this.setState({images:images,searchTerm:searchTerm, offset:images.length})
			
		})
	}




	parseGifObject(value){
		return {
			src: value.original.url,
			thumbnail: value.original.url,
			thumbnailWidth: parseInt(value.original.width,10),
			thumbnailHeight: parseInt(value.original.height,10), 
			caption:'select gif',
			isSelected: false
		}
	}


	handleNewImageSearch(searchTerm){
		this.setState({isSearching:true, noResults:false,images:[]})
		this.props.imageSearch(searchTerm,this.state.count,0).then((response) => {
  		this.setState({isSearching:false})
  		if(response.value.length===0){
    		this.setState({noResults:true})
			}
			let images=[]
			response.value.forEach(value => {
				images.push(this.parseImageObject(value))
			})
			this.setState({images:images,searchTerm:searchTerm, offset:response.nextOffset,searchResultCount:response.totalEstimatedMatches})
		})
			.catch((error) => {
				this.setState({errorMessage:'Sorry we are having issues handling the high demand for our new image search feature! We are working to fix this as soon as possible.'})
		 	
		 })
	}



	handleLoadMore(){
		const {searchTerm,count,offset}=this.state
		this.setState({isSearching:true})
		this.props.imageSearch(searchTerm,count, offset).then((response) => {
			let images=this.state.images
			response.value.forEach(value => {
				images.push(this.parseImageObject(value))
			})
			this.setState({isSearching:false,images:images,offset:response.nextOffset, searchResultCount:response.totalEstimatedMatches})
		})
	}


	parseImageObject(value){
		return {
			src: value.contentUrl,
			thumbnail: value.thumbnailUrl,
			thumbnailWidth: value.thumbnail.width,
			thumbnailHeight: value.thumbnail.height, 
			caption:'',
			accentColor:value.accentColor
		}
	}



	handleSelectImage(index, image){
		const img=this.state.images[index]
		this.props.saveImageSearchModalImage(img.src,img.thumbnail)
	}


	handleFileUpload(e){
		const fileList = e.target.files
		const file = fileList[0]
		if(file.size > MAX_FILE_SIZE){
			this.props.hideModal()
			this.props.showNotification('Max image upload is 20mb','Cannot upload image','destroy')

		}else{
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = (event) => {
			this.props.saveImageSearchModalImage(event.target.result,event.target.result)
		}
	}
	}


 
	render() {
		let imageSearch=this.handleNewImageSearch
		if(this.props.isGif){
			imageSearch=this.handleGifSearch
		}
 
		return (
			<div className='repoImageSearch-container'>        
				<ImageSearch 
					isSearching={this.state.isSearching}
					imageSearch={imageSearch}
					isGif={this.props.isGif}
					images={this.state.images}
					searchTerm={this.state.searchTerm}
					noResults={this.state.noResults}
					onImageSelected={this.handleSelectImage}
					handleFileUpload={this.handleFileUpload}
					loadMore={this.handleLoadMore}
					searchResultCount={this.state.searchResultCount}
					loadMoreGifs={this.handleLoadMoreGifs}
					errorMessage={this.state.errorMessage}
					disableGifs={this.props.disableGifs}
				/>          
			</div>      
		)
	}
}


export default connect(
  (state) => ({
	// disableGifs:state.modals.disableGifs,

}),
  { hideModal,showNotification,imageSearch,gifSearch,saveImageSearchModalImage}
)(ImageSearchContainer)



class QuestionBodyNodeView {

	constructor(node) {
		this.node = node
		const {fontSize} = node.attrs
		this.dom = this.contentDOM = document.createElement("div")
		this.dom.classList.add("slide-body")
		this.dom.style.fontSize=`${fontSize||50}px`
	}

	update(node) {
		if (node.type !== this.node.type) return false
		const {fontSize} = node.attrs
		this.dom.style.fontSize=`${fontSize||50}px`
		return true
	}

	destroy() {
	//if (this.innerView) this.close()
	}

	stopEvent(event) {
	//return this.innerView && this.innerView.dom.contains(event.target)
	}

}

export default QuestionBodyNodeView
import {updateControl} from '../actions/control'
import {updateSetPoll} from '../actions/polls'
import store from '../store'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import {buildQuestionsAndPollsList} from './buildQuestionsAndPollsList'
import {showLiveViewModal} from './liveView/showLiveViewModal'
import {updateRecentActivities} from '../actions/recentActivities'
import {getDefaultControlObject} from './getDefaultControlObject'


export function getNextQuestionAndPoll(resumableSetPoll) { //returns question/poll pair that will be up next if playing is resumed

	if(resumableSetPoll){
		let nextQuestionAndPoll={}
		//1: refresh snapshot
		const updatedPoll=refreshSnapshot(resumableSetPoll)
		//2.  buildQuestionsAndPoll Lists
		const questionsAndPollsList=buildQuestionsAndPollsList(updatedPoll) || []
		const questionsWithoutResponses=filter(questionsAndPollsList,{poll:null})
		const questionsWithResponses=filter(questionsAndPollsList,function(questionAndPoll) { 
		    		return questionAndPoll.poll
		})
		let indexOfNext

		//3. If ALL questions have responses-->the last of the ordered polls
		if(questionsWithoutResponses.length===0){

			nextQuestionAndPoll=questionsAndPollsList[questionsAndPollsList.length-1]
			indexOfNext=questionsAndPollsList.length-1
		}
		//4. If NO questions have responses-->first one
		else if(questionsWithoutResponses.length===questionsAndPollsList.length){

			nextQuestionAndPoll=questionsAndPollsList[0]
			indexOfNext=0
		}
		//5.  If some have responses and some not
		//find the index of the 'last' question with response.  
		//if this is the last question/poll in the list play the FIRST question without reponses
		//otherwise play the first question without responses After the last question with responses
		else{

			const lastQuestionWithResponse=questionsWithResponses[questionsWithResponses.length-1]
			const indexOfLastQuestionWithResponses = questionsAndPollsList.findIndex(questionAndPoll => {
			
				return (questionAndPoll.poll && questionAndPoll.poll.id === lastQuestionWithResponse.poll.id)
			})
			if(indexOfLastQuestionWithResponses===(questionsAndPollsList.length-1)){
				nextQuestionAndPoll=questionsWithoutResponses[0]
				//indexOfNext=0
				indexOfNext=findIndex(questionsAndPollsList, function (questionAndPoll) {		
    				return questionAndPoll.questionSnapshot.questionId === nextQuestionAndPoll.questionSnapshot.questionId
    			})

			
			}else{
			
				nextQuestionAndPoll=questionsAndPollsList[indexOfLastQuestionWithResponses+1]
				indexOfNext=indexOfLastQuestionWithResponses+1
				
			}
		}
		return {...nextQuestionAndPoll,indexOfNext:indexOfNext}
	}else return {}
}


//Very rough initial implementation

export function resumePlayingSetPoll(poll,upNextQuestionAndPoll) {

	//1: refresh snapshot

	const updatedPoll=refreshSnapshot(poll)

	if(updatedPoll.snapshotRefreshed !==poll.snapshotRefreshed){
		store.dispatch(updateSetPoll(poll.id,updatedPoll))
	}




	if(upNextQuestionAndPoll){
		let snapshotForControl
		let pollPollId=null
		if(upNextQuestionAndPoll.poll){
			snapshotForControl=upNextQuestionAndPoll.poll.snapshot
			pollPollId=upNextQuestionAndPoll.poll.id
		}else{
			snapshotForControl=upNextQuestionAndPoll.questionSnapshot
		}
		updateControlObject(poll,pollPollId,snapshotForControl)
	}
	else{
	//2.  buildQuestionsAndPoll Lists
		const questionsAndPollsList=buildQuestionsAndPollsList(updatedPoll)
		const questionsWithoutResponses=filter(questionsAndPollsList,{poll:null})
		const questionsWithResponses=filter(questionsAndPollsList,function(questionAndPoll) { 
	    		return questionAndPoll.poll
		})



		//3. If ALL questions have responses update control with the last of the ordered polls
		if(questionsWithoutResponses.length===0){

			const pollPollToPlay=questionsAndPollsList[questionsAndPollsList.length-1].poll
			updateControlObject(poll,pollPollToPlay.id,pollPollToPlay.snapshot)
		}
		//4. If NO questions have responses, play the first one
		else if(questionsWithoutResponses.length===questionsAndPollsList.length){

			const questionToPlay=questionsAndPollsList[0].questionSnapshot
			updateControlObject(poll,null,questionToPlay)
		}
		//5.  If some have responses and some not
		//find the index of the 'last' question with response.  
		//if this is the last question/poll in the list play the FIRST question without reponses
		//otherwise play the first question without responses After the last question with responses
		else{
			const lastQuestionWithResponse=questionsWithResponses[questionsWithResponses.length-1]
			const indexOfLastQuestionWithResponses = questionsAndPollsList.findIndex(questionAndPoll => {
			
				return (questionAndPoll.poll && questionAndPoll.poll.id === lastQuestionWithResponse.poll.id)
			})
			if(indexOfLastQuestionWithResponses===(questionsAndPollsList.length-1)){
				const questionToPlay=questionsWithoutResponses[0].questionSnapshot
				updateControlObject(poll,null,questionToPlay)
			}else{
				const questionToPlay=questionsAndPollsList[indexOfLastQuestionWithResponses+1].questionSnapshot
				updateControlObject(poll,null,questionToPlay)
			}

		}
		store.dispatch(updateRecentActivities(poll.setObject,'set','play'))
		showLiveViewModal()
	}
	
}




//reorder and also add snapshot to setPoll
//TODO if underlying set has been deleted
//TODO update recent activity

function refreshSnapshot(poll){
	const state = store.getState()	
	const set=find(state.sets,{id:poll.setObject})
	const nowDate=new Date()
	if(set){
		let requestBody={
			...poll,
			snapshot:set,
			snapshotRefreshed:nowDate
		}
		return requestBody
	}else return poll //if underlying set has been deleted dont refresh snapshot
}
 

function updateControlObject(setPoll,setPollPollId,snapshot) {

	const defaultControlObject=getDefaultControlObject()
	const controlObject = {
		...defaultControlObject,
		section: setPoll.section,
		setPoll: setPoll.id,
		setPollPoll:setPollPollId||snapshot.questionId,
		snapshot:snapshot,
	}
	store.dispatch(updateControl(controlObject))
}






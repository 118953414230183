import {TextSelection} from "prosemirror-state"

//When user does command + A we select the text in the active node (not all the text)

export function selectAllTextInActiveNode() {
  let state=window.view.state
  let doc=state.doc
  let sel = window.view.state.selection
  const $from=state.doc.resolve(sel.anchor)
  let depth
  for (let i = $from.depth; i > 0; i--) {
    let node = $from.node(i)
    if (node.type.name === "questionBody" || node.type.name === "choiceBody") {
      depth = i
      break
    }
  }
  const startOfNode=$from.before(depth)
  const endOfNode=$from.after(depth)
  let tr=state.tr
  tr.setSelection(TextSelection.create(doc,startOfNode+1,endOfNode-1))
  window.view.dispatch(tr)
}
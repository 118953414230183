import React from 'react'

class ExplorerCrossFilterSortPanelControl extends React.Component{ 
 


	render(){
		const {onSelect}=this.props
 
		return( 
			<div className={'explorerCrossFilterSortPanelControl ' + (this.props.isActive ? 'explorerCrossFilterSortPanelControl--isActive' : '')} onClick={onSelect} >				
				{this.props.label}							
			</div>
		)
	}
}


export default ExplorerCrossFilterSortPanelControl



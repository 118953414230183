import React, { Component } from 'react'
import { connect } from 'react-redux'
import {showNotification } from '../../actions/notifications'
import EditAuthorDescriptionModal from '../../components/authorPage/EditAuthorDescriptionModal' 
import {updateUser } from '../../actions/user'
  
//modal to edit author description
class EditAuthorDescriptionContainer extends Component {  
	
	constructor(props){
		super(props)
		this.saveChanges=this.saveChanges.bind(this)
		let authorDescription=''
		if(props.user && props.user.description){
			authorDescription=props.user.description
		}
		this.state={
			authorDescription:authorDescription,
			submitLoading:false
		}        
	}        
 
 	componentDidMount(){
		if(window.analytics){
			if(this.props.user && this.props.user.description){
				window.analytics.page('Edit Author Bio Modal')
			}else{
				window.analytics.page('Add Author Bio Modal')
			}	
		}
	}

	saveChanges(){
		this.setState({submitLoading:true})
		const nowDate = new Date()
		let requestData={
			...this.props.user,
			description:this.state.authorDescription,
			clientModified:nowDate
		}
		if(window.analytics){
			if(this.props.user.description){
				window.analytics.track('Edit Author Bio',{
					bio:this.state.authorDescription
				})	
			}else{
				window.analytics.track('Add Author Bio',{
				bio:this.state.authorDescription
				})	
			}				
		}
		this.props.updateUser(requestData,this.props.user.id).then(()=>{
			this.props.hideModal()
			this.props.showNotification('author bio','Updated','default')
		})
		 .catch((error) => {})	
	}

	render() {

		return(
			<React.Fragment>
				<div className='editAuthorDescriptionModalContainer'>	
					<EditAuthorDescriptionModal 
						authorDescription={this.state.authorDescription}
						descriptionOnChange={(e)=>{this.setState({authorDescription:e.target.value})}}
						submitLoading={this.state.submitLoading}
						saveChanges={this.saveChanges}
					{...this.props}/>
					<div className='editAuthorDescriptionModalBackground' onClick={this.props.hideModal} />
				</div>
			</React.Fragment>

		)		
	}
}


export default connect(
	(state) => ({user:state.user}),
	{showNotification,updateUser}
)(EditAuthorDescriptionContainer)
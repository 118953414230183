import React from 'react'
import { formatRepoGradesToString } from '../../utils/formatRepoGradesToString'
import { getLinkForRepo } from '../../utils/getLinkForRepo'
import Dropdown from '../uiKit/Dropdown'
import DropdownItem from '../uiKit/DropdownItem'
import DropdownDivider from '../uiKit/DropdownDivider'
import { formatDateLong } from '../../utils/formatDateLong'
import { formatExplorerPublishedDate } from '../../utils/formatExplorerPublishedDate'
import DiscoverRepoRatingStars from '../discover/DiscoverRepoRatingStars'

class ExplorerCrossFilterRepoRatingButton extends React.Component {
  render() {
    const { onClickFunction, isOpen, currentInternalRating } = this.props

    let internalRatingDescription = ''

    if ((!currentInternalRating || currentInternalRating === -1) && currentInternalRating !== 0) {
      internalRatingDescription = 'Please rate'
    }
    if (currentInternalRating === 0) {
      internalRatingDescription = 'Internal/Default Blank'
    } else if (currentInternalRating === 1) {
      internalRatingDescription = 'Ignore'
    } else if (currentInternalRating === 2) {
      internalRatingDescription = 'Low likelihood to meet'
    } else if (currentInternalRating === 3) {
      internalRatingDescription = 'Good start, potential to meet'
    } else if (currentInternalRating === 4) {
      internalRatingDescription = 'Close to meeting guidelines'
    } else if (currentInternalRating === 5) {
      internalRatingDescription = 'Meets all guidelines'
    }

    return (
      <button className={`btn btn--mid btn--white btn--dropdown explorerCrossFilterRepo-rating-btn ${(this.props.currentInternalRating || this.props.currentInternalRating === 0) ? ` explorerCrossFilterRepo-rating-btn--${this.props.currentInternalRating} ` : ' explorerCrossFilterRepo-rating-btn--noRating '}${isOpen ? ' btn--dropdown--open' : ''}`} onClick={onClickFunction}>
        <div className="explorerCrossFilterRepo-rating-btn-value">
          {this.props.currentInternalRating && this.props.currentInternalRating !== -1 &&
          <span>{this.props.currentInternalRating}</span>}
          {
            (!currentInternalRating || currentInternalRating === -1) &&
            currentInternalRating !== 0 &&
            <span>No Rating</span>
          }
        </div>
        <div className="explorerCrossFilterRepo-rating-btn-description">
          {internalRatingDescription}
        </div>
      </button>
    )
  }
}

class ExplorerCrossFilterRepoRating extends React.Component {
  render() {
    const { updateRepoRating, repoId } = this.props
    return (
      <div className="explorerCrossFilterRepo-rating">
        <Dropdown
          dropdownButton={(
            <ExplorerCrossFilterRepoRatingButton
              currentInternalRating={this.props.currentInternalRating}
            />
          )}
        >
          <DropdownDivider label="Edit Internal Rating" atDropdownTop />
          <DropdownItem label="Rate 0 - Internal/Blank" onSelect={() => { updateRepoRating(repoId, 0) }} />
          <DropdownItem label="Rate 1 - Ignore: v. unlikely to meet guidelines" onSelect={() => { updateRepoRating(repoId, 1) }} />
          <DropdownItem label="Rate 2 - Not good: low likelihood meet guidelines" onSelect={() => { updateRepoRating(repoId, 2) }} />
          <DropdownItem label="Rate 3 - Good start: potential meet guidelines" onSelect={() => { updateRepoRating(repoId, 3) }} />
          <DropdownItem label="Rate 4 - Close to meeting guidelines" onSelect={() => { updateRepoRating(repoId, 4) }} />
          <DropdownItem label="Rate 5 - Meets guidelines" onSelect={() => { updateRepoRating(repoId, 5) }} />
          <DropdownItem warning label="Remove Rating" onSelect={() => { updateRepoRating(repoId, -1) }} />
        </Dropdown>
      </div>
    )
  }
}

class ExplorerCrossFilterRepo extends React.Component {
  constructor() {
    super()
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this)
    this.openNotesModal = this.openNotesModal.bind(this)
    this.openAuthorPage = this.openAuthorPage.bind(this)
  }

  handleBackgroundClick() {
    const repoLink = getLinkForRepo(this.props.repo)
    window.open(repoLink, '_blank')
  }

  openNotesModal(e) {
    e.stopPropagation()
    this.props.showAdminEditRepoNotesModal(this.props.repo)
  }

  openAuthorPage(e) {
    e.stopPropagation()
    const { username } = this.props.repo.author
    window.open(`/${username}`, '_blank')
  }

  render() {
    const { repo } = this.props
    const repoPublishedDate = repo.publishedAt
    const { explorerMetadata } = repo

    // Internal rating
    let currentInternalRating = null
    if (explorerMetadata && (explorerMetadata.rating || explorerMetadata.rating === 0)) {
      currentInternalRating = explorerMetadata.rating
    }

    // Relative published date
    const relativePublishedDate = formatExplorerPublishedDate(repoPublishedDate)

    // Notes
    let notesBody = ''
    if (explorerMetadata && explorerMetadata.notes) {
      notesBody = explorerMetadata.notes
    }
    /* eslint-disable max-len */
    // const notesBody = 'a bunch of random stuff that can go  on and aaskdj aksjd qwekj qwkejqwkje qkjwe kjqwekj qwkejqkwje kqwekj qwkje qkwe kjqwe kqwkej qjkwe kjqwekj qwkjeqkwej kqwe kqjwe kqjwe kjqw ekjqwkje qkwej jkqwekjqwkej qkwjekqjw ekjqw ekjqwkje qkjwe kjqwekj qwkje kqjwekjqwkje qjkwe kjqwe kjqw kqwkej a bunch of random stuff that can go  on and aaskdj aksjd qwekj qwkejqwkje qkjwe kjqwekj qwkejqkwje kqwekj qwkje qkwe kjqwe kqwkej qjkwe kjqwekj qwkjeqkwej kqwe kqjwe kqjwe kjqw ekjqwkje qkwej jkqwekjqwkej qkwjekqjw ekjqw ekjqwkje qkjwe kjqwekj qwkje kqjwekjqwkje qjkwe kjqwe kjqw kqwkej a bunch of random stuff that can go  on and aaskdj aksjd qwekj qwkejqwkje qkjwe kjqwekj qwkejqkwje kqwekj qwkje qkwe kjqwe kqwkej qjkwe kjqwekj qwkjeqkwej kqwe kqjwe kqjwe kjqw ekjqwkje qkwej jkqwekjqwkej qkwjekqjw ekjqw ekjqwkje qkjwe kjqwekj qwkje kqjwekjqwkje qjkwe kjqwe kjqw kqwkej a bunch of random stuff that can go  on and aaskdj aksjd qwekj qwkejqwkje qkjwe kjqwekj qwkejqkwje kqwekj qwkje qkwe kjqwe kqwkej qjkwe kjqwekj qwkjeqkwej kqwe kqjwe kqjwe kjqw ekjqwkje qkwej jkqwekjqwkej qkwjekqjw ekjqw ekjqwkje qkjwe kjqwekj qwkje kqjwekjqwkje qjkwe kjqwe kjqw kqwkej'
    /* eslint-enable max-len */

    let totalConsumerResponseCount = 0
    if (explorerMetadata && explorerMetadata.metrics && explorerMetadata.metrics.responseCount) {
      totalConsumerResponseCount = explorerMetadata.metrics.responseCount
    }
    let thisMonthConsumerResponseCount = 0
    if (
      explorerMetadata &&
      explorerMetadata.metrics &&
      explorerMetadata.metrics[this.props.year] &&
      explorerMetadata.metrics[this.props.year][this.props.month]
    ) {
      thisMonthConsumerResponseCount =
        explorerMetadata.metrics[this.props.year][this.props.month].responseCount
    }

    return (
      <div onClick={this.handleBackgroundClick} className="explorerCrossFilterRepo">
        <div className={`explorerCrossFilterRepo-internalRatingColorBar ${currentInternalRating ? `explorerCrossFilterRepo-internalRatingColorBar--rating${currentInternalRating}` : ''}`} />
        <div className="explorerCrossFilterRepo--top">
          <div className="explorerCrossFilterRepo--top--left">
            <div className="explorerCrossFilterRepo-title">
              {this.props.repoName}
            </div>
            <div className="explorerCrossFilterRepo-author" onClick={this.openAuthorPage}>
              {repo.author && repo.author.username}
            </div>
          </div>
          <div className="explorerCrossFilterRepo--top--center">
            <div className="explorerCrossFilterRepo--top-sevenDayConsumerResponses">
              <div className="explorerCrossFilterRepo--top-sevenDayConsumerResponses-count">
                {
                  thisMonthConsumerResponseCount
                    .toLocaleString(navigator.language, { minimumFractionDigits: 0 })
                }
              </div>
              <div className="explorerCrossFilterRepo--top-sevenDayConsumerResponses-label">
                Month
              </div>
            </div>
            <div className="explorerCrossFilterRepo--top-totalConsumerResponses">
              <div className="explorerCrossFilterRepo--top-totalConsumerResponses-count">
                {
                  totalConsumerResponseCount
                    .toLocaleString(navigator.language, { minimumFractionDigits: 0 })
                }
              </div>
              <div className="explorerCrossFilterRepo--top-totalConsumerResponses-label">
                Total
              </div>
            </div>
          </div>
          <div className="explorerCrossFilterRepo--top--right" onClick={(e) => { e.stopPropagation() }}>
            <ExplorerCrossFilterRepoRating
              updateRepoRating={this.props.updateRepoRating}
              repoId={repo.id}
              currentInternalRating={currentInternalRating}
            />
          </div>
        </div>
        <div className="explorerCrossFilterRepo--bottom">
          <div className="explorerCrossFilterRepo-metadata">
            {repo.rating && (
              <div className="explorerCrossFilterRepo-metadata-ratingContainer">
                <DiscoverRepoRatingStars rating={repo.rating} showRatingLabel />
              </div>
            )}
            <div
              className={`explorerCrossFilterRepo-metadata-reviews${repo.reviewCount > 0 ? '--hasReviews' : ''}`}
            >
              {`${repo.reviewCount} review${repo.reviewCount === 1 ? '' : 's'}`}
            </div>
            <div className="explorerCrossFilterRepo-metadata-subject">
              {repo.subject && repo.subject.name}
            </div>
            <div className="explorerCrossFilterRepo-metadata-grade">
              {formatRepoGradesToString(repo.grade)}
            </div>
            <div className="explorerCrossFilterRepo-metadata-language">
              {repo.language}
            </div>

          </div>

          <div onClick={this.openNotesModal} className="explorerCrossFilterRepo-notes">

            {notesBody && (
            <React.Fragment>
              <div className="explorerCrossFilterRepo-notes-header">
                Notes
              </div>
              <div className="explorerCrossFilterRepo-notes-body">
                {notesBody}
              </div>
            </React.Fragment>
            )}
            {!notesBody && (
            <div className="explorerCrossFilterRepo-notes-header explorerCrossFilterRepo-notes-header--empty">
              Add a Note
            </div>
            )}
          </div>

          <div className="explorerCrossFilterRepo-date">
            <div className="explorerCrossFilterRepo-date-relative">
              Published
              {' '}
              {relativePublishedDate}
            </div>
            <div className="explorerCrossFilterRepo-date-full">
              {formatDateLong(repoPublishedDate)}
            </div>
          </div>

        </div>

      </div>
    )
  }
}

export default ExplorerCrossFilterRepo

import React from 'react'

 
class PublishingFlowSuccessPage extends React.Component{

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Publishing Flow',{
				stage:'success',
			})
		}
	}



	render(){			

 
		return(
			<div className='publishingFlowModalPage publishingFlowModalPage--success'>
				<div className='publishingFlowModalPage-header'>
					Nice, your Pack is now live
				</div>
				<div className='publishingFlowModalPage--success-URL'>
					plickers.com/abcdef
				</div>				

				<div className='publishingFlowModalPage--success-URL'>
					plickers.com/abcdef
				</div>				
				
			</div>
				

			
		)	
	}
				
}




export default PublishingFlowSuccessPage



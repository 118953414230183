import {updateControl} from '../actions/control'
import {updateQuestionPoll,updateSetPoll} from '../actions/polls'
import store from '../store'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find' 
import moment from 'moment'
import {updateRecentActivities} from '../actions/recentActivities'
import {showLiveViewModal} from './liveView/showLiveViewModal'
import {getDefaultControlObject} from './getDefaultControlObject'
 

export function playPoll(poll) {
	const state = store.getState()	
	let item
	let isSet=false
	if(poll.setObject){
		isSet=true
		item=find(state.sets,{'id':poll.setObject})
	}else{
		item=find(state.questions,{'id':poll.question})
	}

	const controlObject = createControlObject(poll,item,isSet)
	reorderQueueOnPlay(poll)
	store.dispatch(updateControl(controlObject))
	const itemType=isSet? 'set':'question'
	if(item){
		store.dispatch(updateRecentActivities(item.id,itemType,'play'))
	}
	showLiveViewModal()
}



//reorder and also add snapshot to setPoll
function reorderQueueOnPlay(poll){
	const sectionId=poll.section
	const state = store.getState()	
	const sectionQueueQuestionPolls=filter(state.queueQuestionPolls,{'section':sectionId})
	const sectionQueueSetPolls=filter(state.queueSetPolls,{'section':sectionId})
	const combinedData=sectionQueueQuestionPolls.concat(sectionQueueSetPolls)
	const sortedPolls= sortBy(combinedData, 'planned')

	//find the planned time of item currently at top of queue and subtract a 1 day buffer
	const newPlanned=moment(sortedPolls[0].planned).subtract(1,'d')
	let requestBody={
		...poll,
		planned:newPlanned.toISOString()
	}

	if(poll.setObject){
		//add a snapshot
		const set=find(state.sets,{id:poll.setObject})
		requestBody.snapshot=set
		requestBody.snapshotRefreshed=new Date()
		store.dispatch(updateSetPoll(poll.id,requestBody))
	}
	else if(poll.question){
		store.dispatch(updateQuestionPoll(poll.id,requestBody))
	}
}



function createControlObject(poll,item,isSet) {
	let setPoll = null
	let setQuestion = null
	let currentPoll = null
	let snapshot=null
	

	if(isSet) {
		setPoll = poll.id
		//set setQuestion to the first question in the set
		///setQuestion = poll.polls[0].question
		//set question ids change when update set so take the question id from set object not poll
		setQuestion = item.questions[0]
		snapshot=setQuestion
	} 
	else {
		currentPoll = poll.id
		snapshot=item
	}
	const defaultControlObject=getDefaultControlObject()
	const controlObject = {
		...defaultControlObject,
		'section': poll.section,
		'setPoll': setPoll,
		'setPollPoll':setQuestion ? setQuestion.questionId:null,
		'currentPoll': currentPoll,
		'snapshot':snapshot
	}
	return controlObject
}

export function getPrintSetSettings() {	
	
	let printSetSettings={
		columnCount:2,
		showImages:true,
		includeStudentWorkingSpace:false,	
		showTeachersName:false,
		showDate:false,
		highlightCorrect:false,	
		labelSurveys:false

	} 

	const settingsFromLocalStorage = JSON.parse(localStorage.getItem('printSetSettings'))

	if(settingsFromLocalStorage ){	
		if(settingsFromLocalStorage.showImages || !settingsFromLocalStorage.showImages){
			printSetSettings.showImages=settingsFromLocalStorage.showImages
		}
		if(settingsFromLocalStorage.labelSurveys || !settingsFromLocalStorage.labelSurveys){
			printSetSettings.labelSurveys=settingsFromLocalStorage.labelSurveys
		}
		if(settingsFromLocalStorage.highlightCorrect || !settingsFromLocalStorage.highlightCorrect){
			printSetSettings.highlightCorrect=settingsFromLocalStorage.highlightCorrect
		}
		if(settingsFromLocalStorage.includeStudentWorkingSpace || !settingsFromLocalStorage.includeStudentWorkingSpace){
			printSetSettings.includeStudentWorkingSpace=settingsFromLocalStorage.includeStudentWorkingSpace
		}
		if(settingsFromLocalStorage.showTeachersName || !settingsFromLocalStorage.showTeachersName){
			printSetSettings.showTeachersName=settingsFromLocalStorage.showTeachersName
		}
		if(settingsFromLocalStorage.showDate || !settingsFromLocalStorage.showDate){
			printSetSettings.showDate=settingsFromLocalStorage.showDate
		}
		if(settingsFromLocalStorage.columnCount ){
			printSetSettings.columnCount=settingsFromLocalStorage.columnCount
		}
		if(settingsFromLocalStorage.textSize ){
			printSetSettings.textSize=settingsFromLocalStorage.textSize
		}		
	}

	return printSetSettings

}


import React from 'react'
import EditorIcon from '../misc/EditorIcons'

class StaticLetterBlock extends React.Component{

	render(){	
		const {index, isReviewScreen, showCorrect, isSurvey, isCorrect, reportView, alwaysShowLetterblock} = this.props
		
		let showLetterBlock = true

	    if(!alwaysShowLetterblock && isReviewScreen && !reportView && !isSurvey && showCorrect){
	       showLetterBlock = false
	    }

    	const showIcon=!showLetterBlock

		return(
			<div className={'slide-choice-letterblock slide-choice-letterblock--static' + (showLetterBlock ? ' slide-choice-letterblock--isLetterblock ' : ' slide-choice-letterblock--isIcon ')}>   		
    		 	{showLetterBlock && index === 0 &&
	     			<EditorIcon name='choiceLetterBlock-A' />
	     		}
	     		{showLetterBlock && index === 1 &&
	     			<EditorIcon name='choiceLetterBlock-B' />
	     		}
	     		{showLetterBlock && index === 2 &&
	     			<EditorIcon name='choiceLetterBlock-C' />
	     		}
	     		{showLetterBlock && index === 3 &&
	     			<EditorIcon name='choiceLetterBlock-D' />
	     		}	     		

    		 {showIcon && isCorrect &&
			 		<svg width='44' height='44' viewBox='0 0 44 44'>
						<path fill='#FFF' fillRule='evenodd' d='M9.32165655,25.0422759 L17.5460826,32.4475824 C17.9423758,32.8044064 18.5692792,32.7724108 18.9351504,32.3660696 L33.7422071,15.9211672 C34.1109037,15.5116882 34.079366,14.8925113 33.6780762,14.5311884 L32.2518545,13.2470126 C31.8555613,12.8901885 31.2286579,12.9221842 30.8627867,13.3285253 L18.0065002,27.606878 C17.9155762,27.7078593 17.7560376,27.7124391 17.6534987,27.6201127 L11.7702627,22.3228232 C11.3730009,21.9651271 10.7480453,21.9932159 10.3856462,22.3957008 L9.24940308,23.6576267 C8.89346874,24.0529318 8.91935302,24.6800402 9.32165655,25.0422759 Z'/>
					</svg>
    		 }

    		 {showIcon && !isCorrect && 
    	 		<svg width='44' height='44' viewBox='0 0 44 44'>
    				<path fill='#9292A8' fillRule='evenodd' d='M18.7016645,21.9965014 L11.3535236,14.6483605 C10.8844712,14.1793081 10.8833748,13.4329366 11.3430465,12.9732648 L12.9767634,11.3395479 C13.4307117,10.8855997 14.186401,10.8845669 14.6518591,11.350025 L22,18.6981659 L29.3481409,11.350025 C29.813599,10.8845669 30.5692883,10.8855997 31.0232366,11.3395479 L32.6569535,12.9732648 C33.1166252,13.4329366 33.1155288,14.1793081 32.6464764,14.6483605 L25.2983355,21.9965014 L32.6464764,29.3446423 C33.1119345,29.8101004 33.1109018,30.5657897 32.6569535,31.019738 L31.0232366,32.6534549 C30.5635648,33.1131266 29.8171933,33.1120302 29.3481409,32.6429778 L22,25.2948369 L14.6518591,32.6429778 C14.1828067,33.1120302 13.4364352,33.1131266 12.9767634,32.6534549 L11.3430465,31.019738 C10.8890982,30.5657897 10.8880655,29.8101004 11.3535236,29.3446423 L18.7016645,21.9965014 Z'/>
    			</svg>
    		 }

			</div>
		)		
	}	
}


export default StaticLetterBlock

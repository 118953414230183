import api from '../api'

//old creator fund stuff

export function submitRepoForReview(repoId) {
  	return (dispatch) => api.post('/submissions/',{repo:repoId})
	    .then((response) => {
		      dispatch({ type: 'CREATE_SUBMISSION_SUCCESS', response})
		      return response
	    })
	    .catch(err => {})
}

export function fetchSubmissions() {
	return (dispatch) => api.fetch('/submissions')
	    .then((response) => {
		      dispatch({ type: 'FETCH_SUBMISSIONS_SUCCESS', response})
	    })
	 .catch(err => {})
}

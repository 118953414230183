import React from 'react'
import ReactGA from 'react-ga4'
import { Route, Redirect } from 'react-router-dom'
// if user is not authenticated they will be redirected to login page
const MatchAuthenticated = ({
  path,
  location,
  isAuthenticated,
  willAuthenticate,
  component: Component,
}) => {
  if (isAuthenticated && !willAuthenticate) {
    return (
      <Route
        path={path}
        render={(props) => {
          if (typeof window.gtag === 'function') {
            ReactGA.set({ page: location.pathname + location.search })
            ReactGA.send({ hitType: 'pageview' })
          }
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          return <Component {...props} />
        }}
      />

    )
  }
  if (!isAuthenticated && !willAuthenticate) {
    return <Redirect to={{ pathname: '/login' }} />
  }
  return null
}

export default MatchAuthenticated

export function getPaymentErrorMessage(stripeError) {	
	let errorMessage='Please try again. If the problem persists, contact support.'
	if(stripeError==='Your card was declined.'){
		errorMessage='Unfortunately your payment was declined.  Please try a different card to complete your payment.'
	}else if(stripeError==="Your card's security code is incorrect."){
		errorMessage="Your card's security code is incorrect. Please check your details and try again."
	}else if(stripeError==="Your card has insufficient funds."){
		errorMessage="Your card has insufficient funds. Please try a different card to complete your payment."
	}
	else if(stripeError==="Your card has expired."){
		errorMessage="Your card has expired.  Please try a different card to complete your payment."
	}
	else if(stripeError==="An error occurred while processing your card. Try again in a little bit."){
		errorMessage="An error occurred while processing your card. Please try again in a little bit."
	}
	else if(stripeError){
		errorMessage=`${stripeError}`
	}
	return errorMessage
}


import React, { Component } from 'react'
import Icon from '../misc/Icons'
import {FacebookShareButton,TwitterShareButton,PinterestShareButton} from 'react-share';


class RepoSocialMediaShareRow extends Component {   	

	render() {
		

		const {repo,publicShareURL} = this.props

		const repoImageURL = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload/${repo.image}/repo/${repo.id}.jpg`

		return (
			<div className='repoSocialMediaShareRow'>				

				<FacebookShareButton
					url={publicShareURL}
					hashtag={"#plickers"}
				>
					<div className='repoSocialMediaShareRow-item repoSocialMediaShareRow-item--facebook'>
						<Icon name='social-media-facebook-share' />	
						Share
					</div>

				</FacebookShareButton>

				<TwitterShareButton
					url={publicShareURL}				
					title={`${repo.name} by ${repo.author.username}`}
					hashtags={["plickers"]}
				>
					<div className='repoSocialMediaShareRow-item repoSocialMediaShareRow-item--twitter'>
						<Icon name='social-media-twitter-tweet' />
						Tweet
					</div>

				</TwitterShareButton>
				

				<PinterestShareButton
					url={String(publicShareURL)}
					media={String(repoImageURL)}
					description={repo.description ? repo.description : null}
				>
					<div className='repoSocialMediaShareRow-item repoSocialMediaShareRow-item--pinterest'>
						<Icon name='social-media-pinterest-save' />
						Save
					</div>
				</PinterestShareButton>		
				
			</div>
		)
	}
}


export default RepoSocialMediaShareRow
import React from 'react'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import sortBy from 'lodash/sortBy'
import SyncLiveViewPlayerStudentCell from './SyncLiveViewPlayerStudentCell'

const lettersArray=['A','B','C','D']

class SyncLiveViewPlayerStudents extends React.Component{

	render(){		
		const {onlineStudents,currentSection,poll,questionStage,isSurvey,hoveredChoice,choicesGroupHovered}=this.props
		const filteredStudents=filter(currentSection.students,{archived:false})
		const students=sortBy(filteredStudents,[function(o) { 
			return o.displayName.toString().toLowerCase()
		}])

		const totalStudentCount = students.length
		let correctLetters=[]
		if(poll&&poll.snapshot){
			poll.snapshot.choices.forEach((choice,index) => {
				if(choice.correct===true){
					const correctLetter=lettersArray[index]
					correctLetters.push(correctLetter)
				}
			})	
		}			

		let onlineStudentCount = 0
		if(onlineStudents){
			onlineStudentCount=Object.keys(onlineStudents).length
		}

		let onlineStudentsAnsweredCount = 0
		Object.keys(onlineStudents).forEach((studentId)=>{
			if(poll && poll.responsesByStudent && poll.responsesByStudent[studentId]){
				onlineStudentsAnsweredCount+=1
			}
		})

		let allStudentsAnsweredCount=0
		if(poll && poll.responsesByStudent){
			allStudentsAnsweredCount = Object.keys(poll.responsesByStudent).length	
		}
					
		return(				
			<div className={'syncLiveView-player-students ' + (totalStudentCount > 32 ? ' syncLiveView-player-students--over32Students ' : '' ) + (totalStudentCount > 47 ? ' syncLiveView-player-students--over47Students ' : '' )}>		
				<div className='syncLiveView-player-students-top'>
					{questionStage !== 'scan' && onlineStudentCount > 0 &&
						<div className='syncLiveView-player-students-top-label syncLiveView-player-students-top-label--online'>
							<div className='syncLiveView-player-students-top-label-count'>
								{onlineStudentCount}
							</div>
							<div className='syncLiveView-player-students-top-label-label'>
								{onlineStudentCount === 1 ? 'Student Online' : 'Students Online'}
							</div>
						</div>
					}
					{questionStage !== 'scan' && onlineStudentCount === 0 && 
						<div className='syncLiveView-player-students-top-label syncLiveView-player-students-top-label--allOffline'>								
							<div className='syncLiveView-player-students-top-label-label'>
								All students are offline
							</div>
						</div>
					}
					{questionStage === 'scan' && onlineStudentCount > 0 &&
						<div className='syncLiveView-player-students-top-label syncLiveView-player-students-top-label--scan'>
							<div className='syncLiveView-player-students-top-label-count'>
								{onlineStudentsAnsweredCount}/{onlineStudentCount}
							</div>
							<div className='syncLiveView-player-students-top-label-label'>
								Students Answered
							</div>
						</div>
					}

					{questionStage === 'scan' && onlineStudentCount === 0 &&
						<div className='syncLiveView-player-students-top-label syncLiveView-player-students-top-label--scan'>
							<div className='syncLiveView-player-students-top-label-count'>
								{allStudentsAnsweredCount}
							</div>
							<div className='syncLiveView-player-students-top-label-label'>
								Students Answered
							</div>
						</div>
					}


				</div>
									
				<div className='syncLiveView-player-students-list'>
					{students.map((student,index)=>{
						let hasResponse=false
						 let isCorrect=false
						 let answer=''
						if(poll && poll.responsesByCard){
							const studentResponse=poll.responsesByCard[student.card]
							if(studentResponse){
								hasResponse=true
								answer=studentResponse.answer
								isCorrect=includes(correctLetters,answer)
								}							 	 		
							 }
						const isOnline=(onlineStudents && onlineStudents[student.id])?true:false

						return(

							<SyncLiveViewPlayerStudentCell 
								key={`${student.id}`}
								index={index}
								isCorrect={isCorrect}
								hasResponse={hasResponse}									
								isAsk={questionStage==='ask'}
								isScan={questionStage==='scan'}
								isReview={questionStage==='review'}
								isSurvey={isSurvey}
								responseLetter={answer}
								isIncorrect={hasResponse && !isCorrect}									
								student={student}
								hoveredChoice={choicesGroupHovered?hoveredChoice:null}
								isOnline={isOnline}
							/>
							)

					})}

				</div>
			</div>		
		)

	}
}


export default SyncLiveViewPlayerStudents
//symbols organized into catagories for symbol dropdown in set editor
module.exports = {
	// "frequentlyUsed": [
 //  //   "x-math",
	// 	// "y-math",		
	// 	// "pi",	
	// 	// "multiply",	
	// 	"degree",	
	// 	"degree",	
	// 	"a-acute",	
	// 	"e-grave",	
	// 	"e-circumflex",	
	// 	'a-circumflex',
	// 	'n-tilde',
	// 	'i-circumflex',
	// 	'n-tilde-capital',
	// 	"a-acute",	
	// 	"e-grave",	
	// 	"e-circumflex",	
	// 	'a-circumflex',
	// 	'n-tilde',
	// 	'i-circumflex',
	// 	'n-tilde-capital',
	// 	"degree",	
	// 	"a-acute",	
	// 	"e-grave",	
	// 	"e-circumflex",	
	// 	'a-circumflex',
	// 	"degree",	
	// 	"a-acute",	
	// 	"e-grave",	
	// 	"e-circumflex",	
	// 	'a-circumflex',
 //  ],
  
  // GENERAL

  "general": [
    "degree",
		// "hash",
		"ampersand",
		"asterix",
		"arrow",
		"long-arrow",
		//"long-arrow-reverse",
		// "reversible-arrow",
		"tilde",
		// "left-square-brackets",
		// "right-square-brackets",
		// "left-curly-bracket",
		// "right-bracket-close",
		"blank", // ADD LATER		
		"box",
		"sharp",
		"flat",
		"bullet",
		// "trademark",
		//"copyright",
		"celsius",
		"farenheit",
  ],

  // MATH

  "math-and-science": [
  	'multiply',
		'divide',
		'add',
		'subtract',
		'equals',
		'percent',
		'plus-minus',
		'square-root',
		'cube-root',
		'infinity',
		'multiply-dot',
		'not-equal-to',
		'asymptotic-to',
		'identical-to',
		'less-than',
		'greater-than',
		'less-than-or-equal-to',
		'greater-than-or-equal-to',
		//'not-less-than',
		//'not-greater-than',
		'much-less-than',
		'much-greater-than',
		'pi',
		// 'theta',
		// 'summation',
		// 'lamda',
		// 'omega',
		// 'mu',
		// 'sigma',
		// 'alpha',
		// 'xi',
		'integral',
		'partial-differential',
		'increment',
		'x-math',
		'y-math',
		// 'a-math',
		// 'b-math',
		// 'm-math',
		// 'n-math',
		// 't-math',
		// 'v-math',		
		'double-struck-capital-r',
		'double-struck-capital-z',
		'double-struck-capital-n',
		'element-of',
		'vertical-line',
		'intersection ',
		'union',
		'prime',
		'double-prime',
		'triple-prime',
		'roman-numeral-one',	
		'roman-numeral-two',	
		'roman-numeral-three',
  ],

  // LANGUAGES 

  "world-languages":[
  	'a-grave',
		'a-acute',
		'a-circumflex',
		'a-diaeresis',
		'a-overring',
		'a-grave-capital',
		'a-acute-capital',
		'a-circumflex-capital',
		'a-tilde-capital',
		'ae',
		'ae-capital',
		'c-cedilla',
		'c-cedilla-capital',
		'e-circumflex',
		'e-acute',
		'e-diaeresis',
		'e-grave',
		'e-circumflex-capital',
		'e-diaeresis-capital',
		'e-acute-capital',
		'e-grave-capital',
		'i-diaeresis',
		'i-acute',
		'i-circumflex',
		'i-grave',
		'i-acute-capital',
		'i-grave-capital',
		'i-circumflex-capital',
		'i-diaeresis-capital',
		'n-tilde',
		'n-tilde-capital',
		'oe',
		'oe-capital',
		'o-circumflex',
		'o-diaeresis',
		'o-grave',
		'o-tilde',
		'o-acute',
		'o-stroke',
		'o-acute-capital',
		'o-diaeresis-capital',
		'o-tilde-capital',
		'o-stroke-capital',
		'o-diaeresis-capital',
		'o-grave-capital',
		's-caron',
		's-caron-capital',
		'u-acute',
		'u-diaeresis',
		'u-circumflex',
		'u-grave',
		'u-grave-capital',
		'u-acute-capital',
		'u-diaeresis-capital',
		'u-circumflex-capital',
		'y-diaeresis',
		'y-diaeresis-capital',
		'y-acute',
		'y-acute-capital',
		'z-caron',
		'z-caron-capital',
		'ss',
		'degree',
		'guillemet-left',
		'guillemet-right',
		'inverted-exclamation',
		'inverted-question',
  ],

  // "french": [
  // 	'a-grave',
		// 'a-circumflex',
		// 'c-cedilla',
		// 'e-acute',
		// 'e-grave',
		// 'e-circumflex',
		// 'e-diaeresis',
		// 'i-circumflex',
		// 'i-diaeresis',
		// 'o-circumflex',
		// 'oe',
		// 'u-grave',
		// 'u-circumflex',
		// 'u-diaeresis',
		// 'a-grave-capital',
		// 'a-circumflex-capital',
		// 'c-cedilla-capital',
		// 'e-acute-capital',
		// 'e-grave-capital',
		// 'e-circumflex-capital',
		// 'e-diaeresis-capital',
		// 'i-circumflex-capital',
		// 'i-trema-capital',
		// 'o-diaeresis-capital',
		// 'oe-capital',
		// 'u-grave-capital',
		// 'u-circumflex-capital',
		// 'u-diaeresis-capital',
		// 'guillemet-left',
		// 'guillemet-right',
  // ],
  // "spanish": [
  // 	'inverted-exclamation-point',
		// 'inverted-question-mark',
		// 'degree',
		// 'a-acute',
		// 'e-acute',
		// 'i-acute',
		// 'n-tilde',
		// 'o-acute',
		// 'u-acute',
		// 'u-diaeresis',
		// 'a-acute-capital',
		// 'e-acute-capital',
		// 'i-acute-capital',
		// 'n-tilde-capital',
		// 'o-acute-capital',
		// 'u-acute-capital',
		// 'u-diaeresis-capital',
  // ],

  "greek-alphabet":[
  	'alpha',
		'beta',
		'gamma',
		'delta',
		'epsilon',
		'zeta',
		'eta',
		'theta',
		'iota',
		'kappa',
		'lambda',
		'mu',
		'nu',
		'xi',
		'omicron',
		'pi',
		'rho',
		'sigma',
		'tau',
		'upsilon',
		'phi',
		'chi',
		'psi',
		'omega',
		'alpha-capital',
		'beta-capital',
		'gamma-capital',
		'delta-capital',
		'epsilon-capital',
		'zeta-capital',
		'eta-capital',
		'theta-capital',
		'iota-capital',
		'kappa-capital',
		'lambda-capital',
		'mu-capital',
		'nu-capital',
		'xi-capital',
		'omicron-capital',
		'pi-capital',
		'rho-capital',
		'sigma-capital',
		'tau-capital',
		'upsilon-capital',
		'phi-capital',
		'chi-capital',
		'psi-capital',
		'omega-capital',
  ],

  // MATH LETTERS
  "math-letters": [
  	'a-math',
		'b-math',
		'c-math',
		'd-math',
		'e-math',
		'f-math',
		'g-math',
		'h-math',
		'i-math',
		'j-math',
		'k-math',
		'l-math',
		'm-math',
		'n-math',
		'o-math',
		'p-math',
		'q-math',
		'r-math',
		's-math',
		't-math',
		'u-math',
		'v-math',
		'w-math',
		'x-math',
		'y-math',
		'z-math',
		'uppercase-a-math',
		'uppercase-b-math',
		'uppercase-c-math',
		'uppercase-d-math',
		'uppercase-e-math',
		'uppercase-f-math',
		'uppercase-g-math',
		'uppercase-h-math',
		'uppercase-i-math',
		'uppercase-j-math',
		'uppercase-k-math',
		'uppercase-l-math',
		'uppercase-m-math',
		'uppercase-n-math',
		'uppercase-o-math',
		'uppercase-p-math',
		'uppercase-q-math',
		'uppercase-r-math',
		'uppercase-s-math',
		'uppercase-t-math',
		'uppercase-u-math',
		'uppercase-v-math',
		'uppercase-w-math',
		'uppercase-x-math',
		'uppercase-y-math',
		'uppercase-z-math',
  ],

  "roman-numerals": [
  	'roman-numeral-one',	
		'roman-numeral-two',	
		'roman-numeral-three',
		'roman-numeral-four',		
		'roman-numeral-five',		
		'roman-numeral-six',		
		'roman-numeral-seven',	
		'roman-numeral-eight',	
		'roman-numeral-nine',		
		'roman-numeral-ten',		
		'roman-numeral-eleven',	
		'roman-numeral-twelve',	
		'roman-numeral-fifty',	
		'roman-numeral-100',		
		'roman-numeral-500',		
		'roman-numeral-1000',		
  ],

  "currency": [
  	'dollar',
		'euro',
		'yen',
		'pound-sterling',
		'korean-won',
		'ruble',
		'rupee',
		'cent',
		'baht',
		'philippine-peso',
  ],
	
      
}
export function getReportPackSettings() {	
	let reportPackSettings={
		columnCount:2,
		showImages:true,
		includeArchivedStudents:false,
		includeGuests:true,
		showQuestionNumbers:true,
		showResponseCapturedDate:false,
		highlightCorrect:true,
		showMissing:true,
		showSurveys:true
		
	}

	const settingsFromLocalStorage = JSON.parse(localStorage.getItem('reportPackSettings'))

	if(settingsFromLocalStorage ){	
		if(settingsFromLocalStorage.showImages || !settingsFromLocalStorage.showImages){
			reportPackSettings.showImages=settingsFromLocalStorage.showImages
		}
		if(settingsFromLocalStorage.showQuestionNumbers || !settingsFromLocalStorage.showQuestionNumbers){
			reportPackSettings.showQuestionNumbers=settingsFromLocalStorage.showQuestionNumbers
		}
		if(settingsFromLocalStorage.includeArchivedStudents || !settingsFromLocalStorage.includeArchivedStudents){
			reportPackSettings.includeArchivedStudents=settingsFromLocalStorage.includeArchivedStudents
		}
		if(settingsFromLocalStorage.includeGuests || !settingsFromLocalStorage.includeGuests){
			reportPackSettings.includeGuests=settingsFromLocalStorage.includeGuests
		}
		if(settingsFromLocalStorage.showResponseCapturedDate || !settingsFromLocalStorage.showResponseCapturedDate){
			reportPackSettings.showResponseCapturedDate=settingsFromLocalStorage.showResponseCapturedDate
		}
		if(settingsFromLocalStorage.columnCount ){
			reportPackSettings.columnCount=settingsFromLocalStorage.columnCount
		}
		if(settingsFromLocalStorage.highlightCorrect || !settingsFromLocalStorage.highlightCorrect){
			reportPackSettings.highlightCorrect=settingsFromLocalStorage.highlightCorrect
		}
		if(settingsFromLocalStorage.showMissing || !settingsFromLocalStorage.showMissing){
			reportPackSettings.showMissing=settingsFromLocalStorage.showMissing
		}
		if(settingsFromLocalStorage.showSurveys || !settingsFromLocalStorage.showSurveys){
			reportPackSettings.showSurveys=settingsFromLocalStorage.showSurveys
		}
		
	}

	return reportPackSettings

}


import React, { Component} from 'react'
import { withRouter} from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux'
import DiscoverHomePage from '../components/discover/DiscoverHomePage'
import DiscoverSubjectPage from '../components/discover/DiscoverSubjectPage'
import {fetchAPublishedRepoByShortId,fetchContentForRepo} from '../actions/repos'
import {updateMeta} from '../actions/meta'
import {showNotification} from '../actions/notifications'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import discoverRepoIds from '../staticData/discoverRepoIds.js'
import DiscoverSeeAllPage from '../components/discover/DiscoverSeeAllPage'

function onFetchLatestRepos(subject,repos){
	return { type: 'FETCH_DISCOVER_LATEST_REPOS_SUCCESS',subject,repos}
}

//Current Discover stuff is pretty harcoded and subject pages are different components
//If more work is done on discover recommend looking at cleaning up subject pages (perhaps combine into one component)
//thinking about improving page load times and e.g. use collections instead of hardcoded repo ids


// ALL SCIENCE G6-9 
//const scienceRepoShortIds=discoverRepoIds.scienceRepoShortIds
// ALL HS BIOLOGY inc. pipeline/pre-approval
const biologyRepoShortIds=discoverRepoIds.biologyRepoShortIds
// ALL Early Math
const mathsRepoShortIds=discoverRepoIds.mathsRepoShortIds
const keyTextsRepoShortIds=discoverRepoIds.keyTextsRepoShortIds
	

class DiscoverContainer extends Component {

	constructor(props){
		super(props)
		this.loadRepoContent=this.loadRepoContent.bind(this)
		this.subscribeToExcludedMarket=this.subscribeToExcludedMarket.bind(this)
		this.unsubscribeToExcludedMarket=this.unsubscribeToExcludedMarket.bind(this)
		let subscribedMarkets=[]
		if(props.meta){ //http://plickers-api-documentation-pgjd3e8jb7.s3-website-us-east-1.amazonaws.com/#meta
			subscribedMarkets=props.meta.subscribedMarkets
		}

		this.state = { 
			selectedSubSubject:null,
			sortType:'alphabetical', //default sort type
			subscribedMarkets:subscribedMarkets //users can subscribe to be updated when a market is added to Discover.  Subscribed markets are saved in meta
		}
	}


	UNSAFE_componentWillReceiveProps(nextProps){
		if(this.props.match.params.subject!==nextProps.match.params.subject || this.props.match.params.subSubject!==nextProps.match.params.subSubject || this.props.match.params.page!==nextProps.match.params.page){
			const discoverPage={
				subject:nextProps.match.params.subject,
				subSubject:nextProps.match.params.subSubject,
				page:nextProps.match.params.page
			}
			//We save lastViewedDiscover in local storage so we can open on this page next time user visits Discover
			localStorage.setItem('lastViewedDiscover',JSON.stringify(discoverPage))
		}
		if(!this.props.meta && nextProps.meta){
			const subscribedMarkets=nextProps.meta.subscribedMarkets
			this.setState({subscribedMarkets:subscribedMarkets})
		}
	}

	subscribeToExcludedMarket(marketString){
		const {subscribedMarkets}=this.state
		let newSubscribedMarkets=subscribedMarkets.slice()
		const nowDate=new Date()
		newSubscribedMarkets.push({name:marketString,addedAt:nowDate})
		this.setState({subscribedMarkets:newSubscribedMarkets})	
		this.props.updateMeta({subscribedMarkets:newSubscribedMarkets})
		if(window.analytics){
			window.analytics.track('Subscribe to discover market',{
				market:marketString
		})}
	}

	unsubscribeToExcludedMarket(marketString){
		const {subscribedMarkets}=this.state
		let newSubscribedMarkets=[]
		subscribedMarkets.forEach((market)=>{
			if(market.name!==marketString){
				newSubscribedMarkets.push(market)
			}
		})		
		this.setState({subscribedMarkets:newSubscribedMarkets})	
		this.props.updateMeta({subscribedMarkets:newSubscribedMarkets})
		if(window.analytics){
			window.analytics.track('unsubscribe from discover market',{
				market:marketString
		})}
	}

	loadRepoContent(repoId){ //load sets/questions/fodlers for a repo
		this.props.fetchContentForRepo(repoId)
	}

	selectSubject(subject){
		this.setState({selectedSubSubject:subject})
	}

	render() {
		const subject=this.props.match.params.subject
		const subSubject=this.props.match.params.subSubject
		const page=this.props.match.params.page
		if(subject && !page){
			return (
				<DocumentTitle title="Discover - Plickers">
					<DiscoverSubjectPage 
						fetchAPublishedRepoByShortId={this.props.fetchAPublishedRepoByShortId}
						showNotification={this.props.showNotification}
						discoverLatestRepos={this.props.discoverLatestRepos}
						onFetchLatestRepos={this.props.onFetchLatestRepos}
						loadRepoContent={this.loadRepoContent}
						searchKey={this.props.searchKey}
						repos={this.props.repos} 
						subject={subject}
						selectedSubSubject={subSubject} 
						selectSubSubject={(subSubject)=>{this.props.history.push(`/discover/${subject}/${subSubject?subSubject:''}`)}} />
				</DocumentTitle>				
			)
		}else if(subject && page){
			return (
				<DocumentTitle title="Discover - Latest">
					<DiscoverSeeAllPage 
						subject={subject}
						page={page}
						searchKey={this.props.searchKey}
						loadRepoContent={this.loadRepoContent}
					/>
				</DocumentTitle>				
			)
		}else{
			return (
				<DocumentTitle title="Discover - Plickers">			
					<DiscoverHomePage 
					showNotification={this.props.showNotification}
					meta={this.props.meta}
					biologyRepos={this.props.biologyRepos}
					mathRepos={this.props.mathRepos}
					keyTextRepos={this.props.keyTextRepos}
					fetchAPublishedRepoByShortId={this.props.fetchAPublishedRepoByShortId}
					subscribedMarkets={this.state.subscribedMarkets}
					subscribeToExcludedMarket={this.subscribeToExcludedMarket}
					unsubscribeToExcludedMarket={this.unsubscribeToExcludedMarket}
					/>
				</DocumentTitle>
			)}
		}
}

function mapStateToProps(state,ownProps) {
	let repos=[]
	const subject=ownProps.match.params.subject
	let biologyRepos=[]
	let mathRepos=[]
	let keyTextRepos=[]

	biologyRepoShortIds.forEach((shortId)=>{
		const repo=find(state.repos, {'shortId':parseInt(shortId,10)})
		if(repo){
			biologyRepos.push(repo)
		}
	})

	mathsRepoShortIds.forEach((shortId)=>{
		const repo=find(state.repos, {'shortId':parseInt(shortId,10)})
		if(repo){
			mathRepos.push(repo)
		}
	})

	keyTextsRepoShortIds.forEach((shortId)=>{
		const repo=find(state.repos, {'shortId':parseInt(shortId,10)})
		if(repo){
			keyTextRepos.push(repo)
		}
	})

	if(subject==='biology'){
		repos=biologyRepos
	}else if(subject==='earlyMath'){
		repos=mathRepos
	}else if(subject==='keyTexts'){
		repos=keyTextRepos
	}

	const sortedRepos = sortBy(repos, [function(repo) {
		return repo.name.toString().toLowerCase()				
	}])			

	const searchKey=state.searchKey
	let publicRepoKey
	if(searchKey){
		publicRepoKey=searchKey.publicRepo
	}

	return {
		biologyRepos:biologyRepos,
		mathRepos:mathRepos,
		keyTextRepos:keyTextRepos,
		repos:sortedRepos,
		meta:state.meta,
		searchKey:publicRepoKey,
		discoverLatestRepos:state.discoverLatestRepos
	}
}
 
export default withRouter(connect(
	mapStateToProps,
	{fetchAPublishedRepoByShortId,fetchContentForRepo,onFetchLatestRepos,showNotification,updateMeta }
)(DiscoverContainer))
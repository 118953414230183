import React from 'react'
import PublishingFlowSelectControl from './PublishingFlowSelectControl'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import languages from '../../../staticData/languages.js'
 

class PublishingFlowGradeLevelCheckbox extends React.Component{

	render(){	

		const {checked} = this.props
		
		return(
			<div onClick={this.props.onSelect} 
				data-tooltip={`${this.props.ageLabel}`}
				className={'publishingFlowModalPage--gradeLevel-gradeLevelCheckbox tooltip tooltip--label ' + (checked ? 'publishingFlowModalPage--gradeLevel-gradeLevelCheckbox--active' : 'publishingFlowModalPage--gradeLevel-gradeLevelCheckbox--inactive')}
			>
				<div className='publishingFlowModalPage--gradeLevel-gradeLevelCheckbox-gradeLabel'>
					{this.props.gradeLabel}
				</div>
				<div className='publishingFlowModalPage--gradeLevel-gradeLevelCheckbox-ageLabel'>
					{this.props.secondRowLabel}
				</div>				
			</div>
		
		)	
	}
				
}



class PublishingFlowGradeLevelPage extends React.Component{

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Publishing Flow',{
				stage:'gradeLevel',
			})
		}
	}


	render(){	
		const {selectedGradeLevels,gradeLevels,language}=this.props

		return(
			<div className='publishingFlowModalPage publishingFlowModalPage--gradeLevel'>
				<div className='publishingFlowModalPage-header'>
					Add Grade Level
				</div>
				<div className='publishingFlowModalPage-subheader'>
					Select the target student age and ability range.
				</div>

				<div className='publishingFlowModalPage--gradeLevel-gradeLevelCheckboxContainer'>

					{gradeLevels.map((grade)=>{
						const checked= selectedGradeLevels[grade.grade]
						return (
							<PublishingFlowGradeLevelCheckbox 
								key={grade.grade} 
								checked={checked} 
								onSelect={()=>{this.props.selectGradeLevel(grade.grade)}} 
								grade={grade.grade} 
								gradeLabel={grade.label} 
								ageLabel={grade.ageLabel} 
								secondRowLabel={grade.secondRowLabel}
							/>
						)
					})}
									
				</div>

				<div className='publishingFlowModalPage--gradeLevel-gradeLevel-extraContainer'>
					<div className='publishingFlowModalPage--gradeLevel-gradeLevel-extraOptions'>
						<PublishingFlowSelectControl 
							onSelect={this.props.selectHigherEducation} 
							checked={this.props.selectedHigherEducation}  
							checkbox 
							label='Higher Education' 
							description="College-level, Technical College, Vocational" 
							showDescription 
						/>
						
						<PublishingFlowSelectControl 
							onSelect={this.props.selectStaff} 
							checked={this.props.selectedStaff} 
							checkbox label='Staff' 
							description="Teacher training and Professional Development" 
							showDescription 
						/>
					</div>
					<div className='publishingFlowModalPage--gradeLevel-gradeLevel-otherContainer'>
						<PublishingFlowSelectControl  
							onSelect={this.props.selectOtherGradeLevel} 
							checked={this.props.selectedOtherGradeLevel} 
							checkbox 
							label='Other' 
							inputValue={this.props.gradeOtherString} 
							onChangeInput={(e)=>{this.props.setGradeOtherString(e.target.value)}}
							optionalOtherInputBox 
							optionalOtherInputBoxPlaceholder="Who is the intended audience for your pack?"
						/>
					</div>
				</div>

				

				{/* GRADE CONTIGUITY VALIDATOR 
				<button onClick={this.validateGradeLevels}>validate</button>
				{((this.state.isValidGradeRange===true ) || (this.state.isValidGradeRange===false)) &&
					<div> valid grade range:{this.state.isValidGradeRange?'yes':'no'}</div>
				}
			*/}

				
				<div className='publishingFlowModalPage-header'>
					Language
				</div>

				<div className='publishingFlowModalPage--gradeLevel-languageSelectContainer'>
					<Select											
						value={language}
						onChange={(option) => this.props.selectLanguage(option?option.value:null)}
						//onBlur={() => this.props.input.onBlur(this.props.input.value)}
						options={languages}
						searchable={true}
						clearable={false}
						placeholder="Select Language"
					/>
				</div>
				

			</div>

			
		)	
	}
				
}




export default PublishingFlowGradeLevelPage
import React from 'react'
import FeatureModalSelectControl from '../../featureModals/FeatureModalSelectControl'
import {formatRepoGradesToString} from '../../../utils/formatRepoGradesToString'
import {formatSubjectToString} from '../../../utils/formatSubjectToString'
import {Image} from 'cloudinary-react'
import languages from '../../../staticData/languages.js'
import find from 'lodash/find'
 
class PublishingFlowReviewPage extends React.Component{

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Publishing Flow',{
				stage:'review',
			})
		}
	}





	render(){			
		const {repo,repoName,repoDescription,confirmCheckboxChecked,toggleConfirmCheckbox}=this.props
		const grade=formatRepoGradesToString(repo.grade)
		const subject=formatSubjectToString(repo.subject)
		const maxDescriptionLength = 300
		const maxNameLength = 80
 
		const language=this.props.language
		const languageObject=find(languages, {value:language})
		let languageLabel
		if(languageObject){
			languageLabel=languageObject.label
		}


		return(
			<div className='publishingFlowModalPage publishingFlowModalPage--review'>
				<div className='publishingFlowModalPage-header'>
					Make sure everything looks good				
				</div>				

				<div className='publishingFlowModalPage--review-contentContainer'>
					<div className='publishingFlowModalPage--review-packImageContainer'>
						<button 
							onClick={()=>window.location.hash='publish-image'} 
							className='publishingFlowModalPage--review-packImageContainer-editBtn'
						>
							Edit
						</button>
							<Image
								cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
								publicId={`${repo.image}/repo/${repo.id}`} 
								className='repoDetailsModal-imageContainer-image'
								width='450'
								height='450'
								crop='fill'
								fetchFormat='auto'
			    			quality='auto'
							/>

						{/* REPO IMAGE GOES HERE */}

					</div>

					<div className='publishingFlowModalPage--review-packInfo'>
						<div className='publishingFlowModalPage--review-packInfo-textAreaContainer publishingFlowModalPage--review-packInfo-textAreaContainer--name'>													

							<textarea 								
								value={repoName}
								maxLength={maxNameLength}
								placeholder="Your Pack Name"					
								onChange={(e)=>{this.props.onChangeNameInput(e.target.value)}}
							/>

							<div className='publishingFlowModalPage--review-packInfo-textAreaGhost'>
								{repoName}

								{repoName &&
									<div className={'publishingFlowModalPage--review-packInfo-textAreaCounter ' + (repoName.length > 65 ? 'publishingFlowModalPage--review-packInfo-textAreaCounter--show' : '') + (repoName.length === maxNameLength ? ' publishingFlowModalPage--review-packInfo-textAreaCounter--max' : '')}>								
										{repoName.length} / {maxNameLength}
									</div>
								}
							</div>


							

						</div>

						

						<button onClick={()=>window.location.hash='publish-subject'} className='publishingFlowModalPage--review-packInfo-field'>
							<div className='publishingFlowModalPage--review-packInfo-field-title'>
								Subject <span className='publishingFlowModalPage--review-packInfo-field-title-edit'>Edit</span>
							</div>
							<div className='publishingFlowModalPage--review-packInfo-field-value'>
								{subject}							
							</div>
						</button>
						<button onClick={()=>window.location.hash='publish-gradeLevel'} className='publishingFlowModalPage--review-packInfo-field'>
							<div className='publishingFlowModalPage--review-packInfo-field-title'>
								Grade Level <span className='publishingFlowModalPage--review-packInfo-field-title-edit'>Edit</span>
							</div>
							<div className='publishingFlowModalPage--review-packInfo-field-value'>
								{grade}
							</div>
						</button>
						

						<div className='publishingFlowModalPage--review-packInfo-field-title publishingFlowModalPage--review-packInfo-field-title--description'>
							Description
						</div>
						<div className='publishingFlowModalPage--review-packInfo-textAreaContainer publishingFlowModalPage--review-packInfo-textAreaContainer--description'>
							
							<textarea 										
								value={repoDescription}
								maxLength={maxDescriptionLength}
								placeholder="Enter a snazzy description"					
								onChange={(e)=>{this.props.onChangeRepoDescription(e.target.value)}}
							/>


							<div className='publishingFlowModalPage--review-packInfo-textAreaGhost'>
								{repoDescription}
								{repoDescription &&
									<div className={'publishingFlowModalPage--review-packInfo-textAreaCounter ' + (repoDescription.length > 200 ? 'publishingFlowModalPage--review-packInfo-textAreaCounter--show' : '') + (repoDescription.length === maxDescriptionLength ? ' publishingFlowModalPage--review-packInfo-textAreaCounter--max' : '')}>								
										{repoDescription.length} / {maxDescriptionLength}
									</div>
								}
							</div>
							

						</div>													
						
						<button onClick={()=>window.location.hash='publish-gradeLevel'} className='publishingFlowModalPage--review-packInfo-field'>
							<div className='publishingFlowModalPage--review-packInfo-field-title'>
								Language <span className='publishingFlowModalPage--review-packInfo-field-title-edit'>Edit</span>
							</div>
							<div className='publishingFlowModalPage--review-packInfo-field-value publishingFlowModalPage--review-packInfo-field-value--small'>
								{languageLabel}
							</div>
						</button>
						
							
					</div>

				</div>

				<div className='publishingFlowModalPage--review-checkboxContainer'>
					<div onClick={toggleConfirmCheckbox} className='publishingFlowModalPage--review-checkbox'>
						<FeatureModalSelectControl
							checkbox
							checked={confirmCheckboxChecked}
							//onClick={toggleConfirmCheckbox}
							label="" // because of links here, need to take out label
						/>
						<div className='publishingFlowModalPage--review-checkbox-label'>
							 I confirm this pack follows the{'\u00A0'}
							<a target='_blank' rel='noopener noreferrer' href='https://help.plickers.com/hc/en-us/articles/360038320453-Content-Policy' onClick={(e)=>{e.stopPropagation()}}>Content Policy</a>
							{'\u00A0'}and agree to license the contents under{'\u00A0'}
							<a target='_blank' rel='noopener noreferrer' href='https://creativecommons.org/licenses/by/4.0/' onClick={(e)=>{e.stopPropagation()}}>CC BY</a>.
						</div>
					</div>
				</div>

			</div>
				

			
		)	
	}
				
}




export default PublishingFlowReviewPage



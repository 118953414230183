import getErrorMessageForRequest from '../../../utils/getErrorMessageForRequest'

function handleAccountRequest({
  setSubmitLoading,
  setErrorMessage,
  makeRequest,
  hideModal,
  showSuccessNotification,
  requestName,
  dataParamForErrorMessage, // optional
}) {
  setSubmitLoading(true)
  setErrorMessage('')
  makeRequest()
    .then(() => {
      hideModal()
      showSuccessNotification()
    })
    .catch((error) => {
      setSubmitLoading(false)
      const errorMessageToSet = getErrorMessageForRequest({
        requestName,
        response: error.response,
        data: dataParamForErrorMessage,
      })
      setErrorMessage(errorMessageToSet)
    })
}

export default handleAccountRequest

function shuffle(array) {
	var currentIndex = array.length, temporaryValue, randomIndex
	while (0 !== currentIndex) {	// While there remain elements to shuffle...
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex)
		currentIndex -= 1
		// And swap it with the current element.
		temporaryValue = array[currentIndex]
		array[currentIndex] = array[randomIndex]
		array[randomIndex] = temporaryValue
	}
	return array
}

export function shuffleChoices(schema,state, dispatch) {
	var tr = window.view.state.tr
	let choices=[]
	window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if(node.attrs.activeslide && node.attrs.activeslide===true){
			state.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
				if (questionNode.type.attrs.correct){ 
					choices.push(questionNode)
				}
			})
		}
	})
	const newChoices=shuffle(choices)
	let newChoiceNodes=[]
	newChoices.forEach((choice,index)=>{
		const type = schema.nodes.choice
		const node=type.create({...choice.attrs,choiceIndex:index},choice.content)
		newChoiceNodes.push(node)
	})
	
	window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if(node.attrs.activeslide && node.attrs.activeslide===true){
			state.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
				if(questionNode.type.name==='choiceGroup'){
					let choiceGroupType = schema.nodes.choiceGroup
					const newChoiceGroupNode=choiceGroupType.create({...questionNode.attrs},newChoiceNodes)
					tr.replaceWith(questionPos, questionPos+questionNode.nodeSize,newChoiceGroupNode)
				}	 
			})
		}
	})
	tr.setMeta("triggerParseDoc", true) 
	window.view.dispatch(tr)
}
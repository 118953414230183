import React from 'react'
import EditorIcon from '../../../misc/EditorIcons'
import {deleteQuestionGIF} from '../../../../prosemirror/utils/editorActions/deleteQuestionGIF'
import slideLayoutDefinitions from '../../../../staticData/slideLayoutDefinitions.js'

class QuestionAnimatedGIFReactComponent extends React.Component{  

	constructor(props) {
		super(props)
		this.setImageFillType=this.setImageFillType.bind(this)
		this.onPlaceholderImageLoad=this.onPlaceholderImageLoad.bind(this)
		let imageFillType='fit'
		if(props.imageFill){
			imageFillType='fill'
		}
		this.state = { 
			imageFillType:imageFillType,   
			cloudinaryGifHasLoaded:false, // I think we need to do something about state 4 or something
			placeholderWidth: 0,
			placeholderHeight: 0,
		}
	}

	setImageFillType(type){
		this.props.setImageFillType(type)
		this.setState({imageFillType:type})
	}

	onPlaceholderImageLoad(){
		const gif = document.getElementById(`placeholder-gif-${this.props.nodeId}`)
		if(gif){
			this.setState({placeholderWidth:gif.naturalWidth,placeholderHeight:gif.naturalHeight})
		}
	}

	render(){ 
		const {dimensions,placeholder,template,questionMediaDynamicHeight,slideWidth}=this.props 
		const {cloudinaryGifHasLoaded,imageFillType,placeholderWidth,placeholderHeight}=this.state
		let displayImageWidth=dimensions.w
		let displayImageHeight=dimensions.h
    
		///    
		const slideScale = slideWidth / 1280
		const inverseScale = 1 / slideScale
		// prevent and other elements icons getting too big
		const ceilingInverseScale = 1.75 
		const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)

		let imageContainerWidth = slideLayoutDefinitions.bodyMediaContainerDimensions[`${template}`].width
		let imageContainerHeight = slideLayoutDefinitions.bodyMediaContainerDimensions[`${template}`].height

		if(template === 'bodyCenterMediaCenter'){
			imageContainerHeight = questionMediaDynamicHeight // pass in
		}
		const intrinsicImageAspect = displayImageWidth / displayImageHeight
		const containerAspect = imageContainerWidth / imageContainerHeight
		const placeholderAspect = placeholderWidth / placeholderHeight
		let fitImageWidth
		let fitImageHeight 
		let fitPlaceholderImageWidth
		let fitPlaceholderImageHeight 

		if(intrinsicImageAspect > containerAspect){//landscape
			fitImageWidth = imageContainerWidth
			fitImageHeight = imageContainerWidth / intrinsicImageAspect
		}
		if(placeholderAspect > containerAspect){//landscape
			fitPlaceholderImageWidth = imageContainerWidth
			fitPlaceholderImageHeight = imageContainerWidth / placeholderAspect
		}
		if(intrinsicImageAspect < containerAspect){//portrait
			fitImageHeight = imageContainerHeight
			fitImageWidth = imageContainerHeight * intrinsicImageAspect
		}
		if(placeholderAspect < containerAspect){//portrait
			fitPlaceholderImageHeight = imageContainerHeight
			fitPlaceholderImageWidth = imageContainerHeight * placeholderAspect
		}
		let fillImageWidth
		let fillImageHeight
		let isPortrait
		if(intrinsicImageAspect > containerAspect){//landscape
			fillImageHeight = imageContainerHeight
			fillImageWidth = imageContainerHeight * intrinsicImageAspect
		}
		if(intrinsicImageAspect < containerAspect){ //portrait
			fillImageWidth = imageContainerWidth
			fillImageHeight = imageContainerWidth / intrinsicImageAspect
			isPortrait = true
		}
		if(placeholderAspect < containerAspect){ //portrait placeholder
			isPortrait = true
		}

		let imageWidth
		let imageHeight
		//const placeholderImageWidth = fitPlaceholderImageWidth
		//const placeholderImageHeight = fitPlaceholderImageHeight
    
		if(imageFillType === 'fit'){
			imageWidth = fitImageWidth
			imageHeight = fitImageHeight
		}

		if(imageFillType === 'fill'){
			imageWidth = fillImageWidth
			imageHeight = fillImageHeight
		}

		//
		// POSITIONING FOR BUTTONS ONTO IMAGE
		//

		let editImageBottomY // used for putting at bottom of image
		let editImageXOffset = 0 // used for centering in narrow images
		//let imageFillTypeToggleXOffset = 0
		let removeImageBtnRightXOffset = 0
		let topLeftBtnLeftXOffset = 0

		if(imageFillType === 'fit'){
			editImageBottomY = imageContainerHeight - fitImageHeight
		}
		if(imageFillType === 'fit' && isPortrait && (template === 'bodyLeftMediaRight' || template === 'bodyLeftMediaRightChoicesMedia')){            
			editImageXOffset = imageContainerWidth - fitImageWidth   
			topLeftBtnLeftXOffset = imageContainerWidth - fitImageWidth   
		}
		if(imageFillType === 'fit' && isPortrait && (template === 'bodyLeftMediaRight' || template === 'bodyLeftMediaRightChoicesMedia') && placeholderWidth > 0){
			topLeftBtnLeftXOffset = imageContainerWidth - fitPlaceholderImageWidth
		}
		if(imageFillType === 'fit' && isPortrait && template === 'bodyRightMediaLeft'){      
			editImageXOffset = -1 * (imageContainerWidth - fitImageWidth)                  
		}
		if(imageFillType === 'fit' && isPortrait && template === 'bodyRightMediaLeft' && placeholderWidth === 0){            
			removeImageBtnRightXOffset = imageContainerWidth - fitImageWidth            
		}
		if(imageFillType === 'fit' && isPortrait && template === 'bodyRightMediaLeft' && placeholderWidth > 0){
			removeImageBtnRightXOffset = imageContainerWidth - fitPlaceholderImageWidth     
		}      
		if(imageFillType === 'fit' && isPortrait && template === 'bodyCenterMediaCenter' && placeholderWidth === 0){        
			removeImageBtnRightXOffset = 0.5 * (imageContainerWidth - fitImageWidth)  
			topLeftBtnLeftXOffset = 0.5 * (imageContainerWidth - fitImageWidth)
		}
		if(imageFillType === 'fit' && isPortrait && template === 'bodyCenterMediaCenter' && placeholderWidth > 0){        
			removeImageBtnRightXOffset = 0.5 * (imageContainerWidth - fitPlaceholderImageWidth)
			topLeftBtnLeftXOffset = 0.5 * (imageContainerWidth - fitPlaceholderImageWidth)
		}
		if(imageFillType === 'fill'){
			editImageBottomY = 0      
		}

		let coverWidth
		let coverHeight   
		const placeholderCoverWidth = fitPlaceholderImageWidth
		const placeholderCoverHeight = fitPlaceholderImageHeight
		let topLeftBtnContainerWidth // prevent badge overflowing remove button
		const bufferRemoveButton = 44

		// before: needs refactoring for portrait
		// latest: seems to work okay!
		if(imageFillType === 'fit'){
			coverWidth = fitImageWidth
			coverHeight = fitImageHeight
			topLeftBtnContainerWidth = (fitImageWidth - (bufferRemoveButton * UIInverseScale)) / UIInverseScale
		}
		else if(imageFillType === 'fill'){
			coverWidth = imageContainerWidth
			coverHeight = imageContainerHeight
			topLeftBtnContainerWidth = (imageContainerWidth - bufferRemoveButton) / UIInverseScale
		}
		// POSITIONING WITHIN CONTAINER
		let imageLayoutStyle
		//let placeholderPositionStyle
		if(template === 'bodyLeftMediaRight' || template === 'bodyLeftMediaRightChoicesMedia'){
			imageLayoutStyle = {top: '0px', right: '0px'}
			//placeholderPositionStyle = {objectPosition: 'top right'}
		}else if(template === 'bodyRightMediaLeft'){
			imageLayoutStyle = {top: '0px', left: '0px'}
			//placeholderPositionStyle = {objectPosition: 'top left'}
		}else if(template === 'bodyCenterMediaCenter'){
			imageLayoutStyle = {top: '0px', margin: 'auto', left: '0px', right:'0px'}
			//placeholderPositionStyle = {objectPosition: 'center center'}
		}

		//const loadingSpinnerLeftXOffset = 0
		const {fileId,webpUrl,host,gifUrl,giphyUser} = this.props
		const gifUploadComplete = fileId?true:false

		let giphyUserAvatarURL
		let giphyUserProfileURL
		let giphyUserUsername
		if(giphyUser){
			giphyUserAvatarURL = giphyUser.avatarUrl
			giphyUserProfileURL = giphyUser.profileUrl
			giphyUserUsername = giphyUser.username
		}
		return (
			<React.Fragment>

				{placeholder && !cloudinaryGifHasLoaded &&
					<div onClick={this.props.showReplaceGIFModal} style={{width: `${placeholderCoverWidth}px`, height: `${placeholderCoverHeight}px`, ...imageLayoutStyle}}  className='slide-media--gifCover slide-media--gifCover--fit slide-media--gifCover--placeholder'>
						<div style={{width: `${fitPlaceholderImageWidth}px`, height: `${fitPlaceholderImageHeight}px`}} className='slide-media--gifContainer'>             
							<picture className='slide-media--gif'> 
								<img alt='' onLoad={this.onPlaceholderImageLoad} draggable="false" id={`placeholder-gif-${this.props.nodeId}`} src={placeholder} />
							</picture>                
						</div>            
					</div>
				}

				{host==='giphy' &&      
					<div onClick={this.props.showReplaceGIFModal} style={{width: `${coverWidth}px`, height: `${coverHeight}px`, ...imageLayoutStyle}}  className={'slide-media--gifCover ' + (imageFillType ? `slide-media--gifCover--${imageFillType}` :'')}>            
						<div style={{width: `${imageWidth}px`, height: `${imageHeight}px`}} className='slide-media--gifContainer '>                                                                   
							<picture className='slide-media--gif'> 
								<source draggable="false" type="image/webp" srcSet={webpUrl} />
								<img alt='' draggable={false} src={gifUrl} />                
							</picture>              
						</div>            
					</div>      
				}

				{host==='cloudinary' &&  
					<div onClick={this.props.showReplaceGIFModal} style={{width: `${coverWidth}px`, height: `${coverHeight}px`, ...imageLayoutStyle}}  className={'slide-media--gifCover ' + (imageFillType ? `slide-media--gifCover--${imageFillType}` :'')}>            
						<div style={{width: `${imageWidth}px`, height: `${imageHeight}px`}} className='slide-media--gifContainer '>                                         
							<picture onLoad={()=>{this.setState({cloudinaryGifHasLoaded:true})}} className='slide-media--gif'> 
								<source draggable="false" type="image/webp" srcSet={webpUrl} />
								<img alt='' draggable="false" src={gifUrl} />                
							</picture>                  
						</div>            
					</div>       
				}           

				{/* LOADING SPINNER  */}          
				{placeholder && !gifUploadComplete &&
					<div style={{transform: `scale(${UIInverseScale})`, left: `${topLeftBtnLeftXOffset}px`}} className='slide-mediaContainer--gif-topLeftContainer'>                                
						<div className='slide-mediaContainer--gif-topLeftContainer-spinnerContainer'>
							<div className='slide-mediaContainer--gif-topLeftContainer-spinner'/>              
						</div>
					</div>           
				}
				{/* GIPHY BADGE */}
				{host==='giphy' && giphyUserUsername && giphyUserProfileURL && giphyUserAvatarURL &&         
					<div style={{transform: `scale(${UIInverseScale})`, left: `${topLeftBtnLeftXOffset}px`, width: `${topLeftBtnContainerWidth}px`}} className='slide-mediaContainer--gif-topLeftContainer'>
						<a href={giphyUserProfileURL} target='_blank' className='slide-mediaContainer--gif-giphyUserLink' rel="noopener noreferrer" >
						<div className='slide-mediaContainer--gif-giphyUserLink-profilePicContainer'>
							<img alt='' src={giphyUserAvatarURL} className='slide-mediaContainer--gif-giphyUserLink-profilePic-img' />
						</div>
						<div className='slide-mediaContainer--gif-giphyUserLink-userName'>
							<div className='slide-mediaContainer--gif-giphyUserLink-userName-userName'>
								@{giphyUserUsername}
							</div>
							<div className='slide-mediaContainer--gif-giphyUserLink-userName-viewOnGiphy'>
								View on Giphy
							</div>
						</div>                
						</a>
					</div>            
				}

				{/* REMOVE IMAGE */}                  
				<div style={{transform: `scale(${UIInverseScale})`, right: `${removeImageBtnRightXOffset}px`}} className='slide-mediaContainer--gif-topRightBtnContainer'>
					<button onClick={deleteQuestionGIF} className='slide-mediaContainer--gif-topRightBtn slide-mediaContainer--gif-topRightBtn--removeImage'>
						<EditorIcon name='close' /> 
					</button>
				</div>          

				{/* FILL IMAGE */}        
				{(cloudinaryGifHasLoaded || host === "giphy") &&
					<div style={{transform: `scale(${UIInverseScale})`, bottom: `${editImageBottomY}px`, left: `${editImageXOffset}px`}} className='slide-mediaContainer--gif-editGifBtnContainer'>
						<button onClick={()=>{this.setImageFillType(this.state.imageFillType === 'fit' ? 'fill' : 'fit')}} className={'slide-mediaContainer--gif-fillTypeBtn slide-mediaContainer--gif-fillTypeBtn--fit ' + (this.state.imageFillType === 'fill' ? 'slide-mediaContainer--gif-fillTypeBtn--active' : 'slide-mediaContainer--gif-fillTypeBtn--inactive')}>
							<EditorIcon name='imageFill' />
							Fill GIF
						</button>           
					</div>
				}
			</React.Fragment>
		)
	}
}

export default QuestionAnimatedGIFReactComponent

import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import {fetchArchivedFolders,deleteFolder,updateFolder } from '../../actions/folders'
import {fetchArchivedQuestions,deleteQuestion,updateQuestionMeta} from '../../actions/questions'
import {fetchArchivedSets,deleteSet,updateSetMeta} from '../../actions/sets'
import {showNotification,hideNotification} from '../../actions/notifications'
import {showConfirmModal,hideModal } from '../../actions/modals'
import Trash from '../../components/Trash'
import TrashPreviewPanel from '../../components/previewPanel/PreviewPanel'
import FilterBar from '../../components/uiKit/FilterBar'
import {getQuestionBodyLabel} from '../../utils/getQuestionBodyLabel'
import {getLinkForRepo} from '../../utils/getLinkForRepo'
import TopPageHeader from '../../components/pageHeaders/TopPageHeader'
import StickyPageHeader from '../../components/pageHeaders/StickyPageHeader'
import PageIcon from '../../components/misc/PageIcons'
import Icon from '../../components/misc/Icons'




class RepoTrashContainer extends Component {

	constructor(){
		super()
		this.handleDeleteQuestion=this.handleDeleteQuestion.bind(this)
		this.confirmDeleteQuestion=this.confirmDeleteQuestion.bind(this)
		this.confirmDeleteSet=this.confirmDeleteSet.bind(this)
		this.handleDeleteSet=this.handleDeleteSet.bind(this)
		this.handleDeleteFolder=this.handleDeleteFolder.bind(this)
		this.restoreItem=this.restoreItem.bind(this)
		this.restoreFolder=this.restoreFolder.bind(this)
		this.keyPressNavigation=this.keyPressNavigation.bind(this)
		this.confirmDeleteFolder=this.confirmDeleteFolder.bind(this)
		this.handleSelectRow=this.handleSelectRow.bind(this)
		this.handleFilterChange=this.handleFilterChange.bind(this)


		this.state={
			activePanelItem: {},
			filterTerm:''
		}
	}

 
	UNSAFE_componentWillMount() {
		this.props.fetchArchivedFolders()
		this.props.fetchArchivedQuestions()
		this.props.fetchArchivedSets()
	}


 
	handleFilterChange(e){
		this.setState({filterTerm:e.target.value})
	}

	handleDeleteQuestion(question){
		this.props.deleteQuestion(question.id).then(() => {
			this.props.hideModal()
			const notificationMessage=`${getQuestionBodyLabel(question)}`
			this.props.showNotification(notificationMessage,'Permanently Deleted','destroy')		
		})
	}

	confirmDeleteQuestion(question){
		this.props.showConfirmModal(question,this.handleDeleteQuestion,'delete')
	}

	handleDeleteSet(set){
		this.props.deleteSet(set.id).then(() => {
			this.props.hideModal()
			const notificationMessage=`${set.name}`
			this.props.showNotification(notificationMessage,'Permanently Deleted','destroy')
			this.setState({activePanelItem:{}})
		})
	}

	confirmDeleteSet(set){
		this.props.showConfirmModal(set,this.handleDeleteSet,'delete')
	}

	handleDeleteFolder(folder){
		this.props.deleteFolder(folder.id).then(() => {
			this.props.hideModal()
			const notificationMessage=`${folder.name}`
			this.props.showNotification(notificationMessage,'Permanently Deleted','destroy')
			this.setState({activePanelItem:{}})
		})
	}

	confirmDeleteFolder(folder){
		this.props.showConfirmModal(folder,this.handleDeleteFolder,'delete')
	}


	


	restoreItem(item) {
		const nowDate = new Date()
		let notificationMessage
		const requestData = {
			...item,
			clientModified: nowDate,
			archived: false
		}
		if (item.questions) {
			this.props.updateSetMeta(requestData, item.id).then(() => {
				notificationMessage=`${item.name} `
				this.props.showNotification(notificationMessage,'Restored','create')
			})
		} else {
			this.props.updateQuestionMeta(requestData, item.id).then(() => {
				notificationMessage=`${item.body}`
				this.props.showNotification(notificationMessage,'Restored','create')
			})
		}
		this.setState({activePanelItem:{}})
	}

	restoreFolder(folder) {
		const nowDate = new Date()
		let notificationMessage
		const requestData = {
			...folder,
			clientModified: nowDate,
			archived: false
		}
		
		this.props.updateFolder(requestData, folder.id).then(() => {
			notificationMessage=`${folder.name}`
			this.props.showNotification(notificationMessage,'Restored','create')
		})
		this.setState({activePanelItem:{}})
	}
	


	handleSelectRow(selectedItem){
		this.setState({activePanelItem:selectedItem})
	}


	keyPressNavigation(e){
		const {activePanelItem}=this.state
		const {archivedItems}=this.props
	
		if(activePanelItem.id){
			if (e.keyCode === 40 || e.keyCode === 38 ){
				const activeIndex= findIndex(archivedItems, function (item) {
					return item.id === activePanelItem.id
				})
				e.preventDefault()// prevent scrolling container
				let newSelectedIndex
				//check if keypress was an up or down press
				if (e.keyCode === 40) { 
					newSelectedIndex=Math.min(activeIndex+1,archivedItems.length-1)				
				}
				else if (e.keyCode === 38) { 
			    newSelectedIndex=Math.max(activeIndex-1,0)
				 }
				const newSelected=archivedItems[newSelectedIndex]	   
			  this.setState({activePanelItem:newSelected})
		  }
	  }
	}






	render() {
		let panelActiveItem={}
		if(this.state.activePanelItem){
			panelActiveItem=find(this.props.archivedItems,{'id':this.state.activePanelItem.id})||find(this.props.archivedFolders,{'id':this.state.activePanelItem.id})||{}
			
		}

		 const documentTitle=`${this.props.repo.name || ''} (Trash) – Plickers`


		return (
   	 <DocumentTitle title={documentTitle}>
    	 <div className='page page--sidePanelLayout' >
					<div className='page-leftPanel'/>
					<div className='page-centerPanel library'>
    						
						<TopPageHeader 
							location='trash'
							height='175'
							leftBadge={
									<React.Fragment>
										<Icon name='chevron-left' />	
										<PageIcon name='trashLarge' />									
									</React.Fragment>
								}
							leftBadgeClickFunction={()=>{this.props.history.push(`${getLinkForRepo(this.props.repo)}`)}}
							header='Trash'
							actionRow={
								<React.Fragment>
									<FilterBar
										value={this.state.filterTerm}
										onChange={this.handleFilterChange} 
										id='filterInput'
										clearBtnOnClick={() =>{this.clearFilter()}}
									/>
								</React.Fragment>}
						/>

						<StickyPageHeader 
							location='trash' 
							leftBadge={
									<React.Fragment>
										<Icon name='chevron-left' />
										<PageIcon name='trashLarge' />
									</React.Fragment>
								}
							leftBadgeClickFunction={()=>{this.props.history.push(`/packs/${this.props.repoId}`)}}
							header='Trash'						
						/>


						<Trash
							selectRow={this.handleSelectRow}						
							activeItem={this.state.activePanelItem}   
							keyPressNavigation={this.keyPressNavigation}
							deleteFolder={this.confirmDeleteFolder}
							filterTerm={this.state.filterTerm}   
							restoreItem={this.restoreItem}
							archivedItems={this.props.archivedItems}    
							archivedFolders={this.props.archivedFolders}
							restoreFolder={this.restoreFolder}
						/>
						
					</div>
					<div className='page-rightPanel'>
						<div className='sidePanelContainer'>
							<div className='sidePanel'> 
								<TrashPreviewPanel     
									restoreItem={this.restoreItem}
									deleteQuestion={this.confirmDeleteQuestion}
									deleteSet={this.confirmDeleteSet}   
									isLibrary={true}
									activeItem={panelActiveItem} 
									sections={this.props.sections}
									isTrash={true}
									restoreFolder={this.restoreFolder}
									deleteFolder={this.confirmDeleteFolder}
									isRepoTrash={true}
								/>
							</div>
						</div>
					</div>
					
				</div>
			</DocumentTitle>
		)
	}
}



function mapStateToProps(state,ownProps) {

	const repoId=ownProps.repoId

	const archivedQuestions=filter(state.archivedQuestions,{'repo':repoId})
	const archivedSets=filter(state.archivedSets,{'repo':repoId})
	const archivedItems=archivedSets.concat(archivedQuestions)

	return {
		repo:find(state.repos, {'id':repoId})||{},
		repoId:repoId,
		archivedFolders:sortBy(filter(filter(state.archivedFolders, {'parent':null}),{'repo':repoId}),[function(o) { 
	            return o.name.toString().toLowerCase()
	            }]),
	    archivedQuestions:archivedQuestions,
	    archivedSets:archivedSets,
	    archivedItems:archivedItems
	  }
}



export default withRouter(connect(
	mapStateToProps,
	{ 
		fetchArchivedFolders,
		showNotification,
		hideNotification,
		deleteFolder,
		fetchArchivedQuestions,
		deleteQuestion,
		fetchArchivedSets,    
		deleteSet,
		showConfirmModal,   
		hideModal,
		updateSetMeta,
		updateQuestionMeta,
		updateFolder
	}
)(RepoTrashContainer))

import React from 'react'
import FeatureModalSelectControl from '../../featureModals/FeatureModalSelectControl'
import {formatRepoGradesToString} from '../../../utils/formatRepoGradesToString'
import {getPublicUrlForRepo} from '../../../utils/getPublicUrlForRepo'
import filter from 'lodash/filter'
import PackReviewDetailPanelEmailPreview from './PackReviewDetailPanelEmailPreview'


class PackReviewDetailPanelHeader extends React.Component{ 

	render(){
		const {submission} = this.props
		const {repo}=submission	
		const frontConversationID = null
		let frontConversationURL = null
		let frontConversationURLLabel = null
		if(frontConversationID){
			frontConversationURL = `https://app.frontapp.com/open/${frontConversationID}`
			frontConversationURLLabel = `app.frontapp.com/open/${frontConversationID}`
		}
		let plickersAdminConsoleURL=''
		if(repo.author){
			plickersAdminConsoleURL = `https://plickers-web.herokuapp.com/admin/users/${repo.author.id}`
		}
		const submissionAssignee = submission.assignee	

		return( 			
				<div className='packReview-detailPanel-header'>
					<div className='packReview-detailPanel-header--left'>
						<div className='packReview-detailPanel-header-repoName'>
							{repo.name}
						</div>
						<div className='packReview-detailPanel-header-submissionID'>
							{submission.shortId}
						</div>

						{repo.author &&
						
						<div className='packReview-detailPanel-header-author'>
							<a className='packReview-detailPanel-header-author-name' href={`/${repo.author.username}`} target='_blank' rel='noopener noreferrer'>							
								{repo.author.firstName} {repo.author.lastName} ({repo.author.username})								
							</a>													
							<a className='packReview-detailPanel-header-author-email' href={plickersAdminConsoleURL} target='_blank' rel='noopener noreferrer'>																																
								{repo.author.email}
							</a>							
						</div>
					}
						<div className='packReview-detailPanel-header-metadata'>
							{repo.subject.name} · {formatRepoGradesToString(repo.grade)} {/* Subject and Grade Level */}
						</div>						
						<a className='packReview-detailPanel-header-url packReview-detailPanel-header-url--plickersURL' href={`https://${this.props.plickersRepoURL}`} target='_blank' rel='noopener noreferrer'>
							<div className='packReview-detailPanel-header-url-icon'>
								<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">    
									<path d="M11.718,25.3854749 L10.098,26.9954749 C8.696,28.3854749 6.412,28.3854749 5.008,26.9954749 C4.336,26.3274749 3.966,25.4374749 3.966,24.4914749 C3.966,23.5454749 4.338,22.6594749 5.008,21.9874749 L10.968,16.0774749 C12.202,14.8514749 14.526,13.0474749 16.22,14.7274749 C16.998,15.4994749 18.252,15.4954749 19.026,14.7174749 C19.796,13.9394749 19.792,12.6834749 19.014,11.9134749 C16.138,9.05747492 11.882,9.58547492 8.176,13.2634749 L2.216,19.1754749 C0.786,20.5934749 0,22.4834749 0,24.4914749 C0,26.5034749 0.788,28.3894749 2.216,29.8074749 C3.688,31.2674749 5.62,31.9994749 7.554,31.9994749 C9.488,31.9994749 11.422,31.2694749 12.892,29.8074749 L14.514,28.1974749 C15.292,27.4274749 15.296,26.1734749 14.524,25.3974749 C13.75,24.6214749 12.496,24.6174749 11.718,25.3854749 Z M29.782,2.41747492 C26.688,-0.65052508 22.364,-0.81652508 19.504,2.02347492 L17.486,4.02747492 C16.708,4.79947492 16.702,6.05347492 17.474,6.82947492 C18.246,7.60747492 19.5,7.61147492 20.278,6.83947492 L22.298,4.83747492 C23.778,3.36547492 25.72,3.97547492 26.99,5.23147492 C27.662,5.90147492 28.034,6.78947492 28.034,7.73547492 C28.034,8.68147492 27.662,9.56947492 26.99,10.2374749 L20.63,16.5454749 C17.722,19.4274749 16.358,18.0774749 15.776,17.4994749 C14.998,16.7274749 13.744,16.7334749 12.974,17.5094749 C12.202,18.2874749 12.206,19.5434749 12.984,20.3114749 C14.32,21.6354749 15.844,22.2914749 17.44,22.2914749 C19.394,22.2914749 21.46,21.3074749 23.426,19.3574749 L29.786,13.0514749 C31.21,11.6314749 32,9.74347492 32,7.73547492 C32,5.72547492 31.21,3.83747492 29.782,2.41747492 Z"></path>
								</svg>
							</div>							
							{this.props.plickersRepoURL}							
						</a>												
						{frontConversationURL &&
							<a className='packReview-detailPanel-header-url packReview-detailPanel-header-url--frontID' href={frontConversationURL} target='_blank' rel='noopener noreferrer'>
								<div className='packReview-detailPanel-header-url-icon'>
									<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">    
										<path d="M1.02044722,7.62122191 C1.88800266,8.08699963 13.9057788,14.5438876 14.3537788,14.7838876 C14.8017787,15.0238876 15.3813342,15.1376653 15.9644452,15.1376653 C16.5475562,15.1376653 17.1271117,15.0238876 17.5751117,14.7838876 C18.0231116,14.5438876 30.0408878,8.08699963 30.9084432,7.62122191 C31.7777764,7.15366642 32.5991096,5.335 31.0044432,5.335 L0.926225013,5.335 C-0.668441438,5.335 0.152891784,7.15366642 1.02044722,7.62122191 Z M31.3119987,11.5376658 C30.3253322,12.0514435 18.179556,18.3803316 17.5751117,18.696776 C16.9706673,19.0132204 16.5475562,19.0505537 15.9644452,19.0505537 C15.3813342,19.0505537 14.9582231,19.0132204 14.3537788,18.696776 C13.7493344,18.3803316 1.67289158,12.0496658 0.686225046,11.5358881 C-0.00710819419,11.1732214 1.45753773e-06,11.5981103 1.45753773e-06,11.9252213 C1.45753773e-06,12.2523324 1.45753773e-06,24.8905529 1.45753773e-06,24.8905529 C1.45753773e-06,25.6372195 1.006225,26.6683305 1.77778045,26.6683305 L30.2222211,26.6683305 C30.9937765,26.6683305 32.0000015,25.6372195 32.0000015,24.8905529 C32.0000015,24.8905529 32.0000015,12.2541102 32.0000015,11.9269991 C32.0000015,11.599888 32.0071097,11.1749992 31.3119987,11.5376658 Z"></path>    
									</svg>
								</div>
								{frontConversationURLLabel}
							</a>
						}
						{!frontConversationURL &&
							<div className='packReview-detailPanel-header-url--noLink'>							
								No Front email conversation
							</div>
						}
						{/*
						<a className='packReview-detailPanel-header-url packReview-detailPanel-header-url--authorPage' href={`/${repo.author.username}`} target='_blank' rel='noopener noreferrer'>							
							{plickersAuthorPageURL}							
						</a>	
						*/}
						
						
					</div>
					<div className='packReview-detailPanel-header--right'>
						{submission.status !== 'submitted' &&
							<div className={'packReview-detailPanel-header-status ' + (submission.status ? `packReview-detailPanel-header-status--${submission.status}` : '')}>
								{submission.status}
							</div>
						}										
					</div>

					{submissionAssignee &&
						<div className='packReview-detailPanel-header-assignee'>
							Assigned to {submissionAssignee}
						</div>
					}
				</div>			
		)
	}
}

class PackReviewDetailPanelActionAreaHeader extends React.Component{ 

	render(){

		const {submissionDetailedStatus} = this.props

		let nextStepLabel = ''
		let nextStepDescription = ''



		if(submissionDetailedStatus === 'submitted'){
			nextStepLabel = 'Move to in review'
			nextStepDescription = 'To move the pack to in review, select the assignee:'			
		}
		if(submissionDetailedStatus === 'in-review-pre-contact'){
			nextStepLabel = 'Send initial email'
			nextStepDescription = 'Generate Front email draft'
			//nextStepDescription = 'Create email draft then copy-paste into a draft in Front'
		}
		if(submissionDetailedStatus === 'in-review-post-contact'){
			nextStepLabel = 'Continue on Front'
			nextStepDescription = 'Continue emailing the user in Front until it either gets cancelled or approved.'
		}
		if(submissionDetailedStatus === 'cancelled'){
			nextStepLabel = 'Submission Cancelled'
			nextStepDescription = 'As the pack submission was cancelled, there are no further steps required.'
		}
		if(submissionDetailedStatus === 'withdrawn'){
			nextStepLabel = 'Submission Withdrawn'
			nextStepDescription = 'As the pack submission was withdrawn, there are no further steps required.'
		}
		if(submissionDetailedStatus === 'approved'){
			nextStepLabel = 'Approved - Yay'
			nextStepDescription = 'I guess work out how to pay them their cash award.'
		}

		return( 			
				<div className='packReview-detailPanel-actionArea-header'>					
					<div className='packReview-detailPanel-actionArea-header-icon'>
						<svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">        
							<path d="M28.8598461,19.2519032 L6.7572325,31.531133 C4.96106878,32.5290017 2.69605795,31.8818558 1.69818921,30.0856921 C1.39113374,29.5329922 1.23,28.9111568 1.23,28.2788909 L1.23,3.72043132 C1.23,1.66569384 2.89569384,0 4.95043132,0 C5.58269723,0 6.20453265,0.161133744 6.7572325,0.468189212 L28.8598461,12.747419 C30.6560098,13.7452877 31.3031558,16.0102986 30.3052871,17.8064623 C29.9678851,18.4137857 29.4671696,18.9145013 28.8598461,19.2519032 Z"></path>
						</svg>
					</div>					
					<div className='packReview-detailPanel-actionArea-header-nextSteps'>
						Next Step
					</div>					
					{nextStepLabel &&
						<div className='packReview-detailPanel-actionArea-header-label'>
							{nextStepLabel}
						</div>					
					}
					<div className='packReview-detailPanel-actionArea-header-description'>
						{nextStepDescription}
					</div>											
				</div>			
		)
	}
}




class PackReviewDetailPanel extends React.Component{ 



	constructor() {
		super()	
		this.resetState=this.resetState.bind(this)
		this.state = { 
			nolanChecked:false,
			jasperChecked:false,
			selectedTemplate:null,
			guideline1Checked:false,
			guideline2Checked:false,
			guideline3Checked:false,
			guideline4Checked:false,
			guideline5Checked:false,
			guideline6Checked:false,

	    }
  	}

  	UNSAFE_componentWillReceiveProps(nextProps) {
 		if(nextProps.submission.id !==this.props.submission.id){
 			this.resetState()
 		}
 	}

  	resetState(){
  		this.setState({
			nolanChecked:false,
			jasperChecked:false,
			selectedTemplate:null,
			guideline1Checked:false,
			guideline2Checked:false,
			guideline3Checked:false,
			guideline4Checked:false,
			guideline5Checked:false,
			guideline6Checked:false,

	    })
  	}

	render(){

		const {submission, allSubmissions} = this.props
		const {selectedTemplate}=this.state
		const {repo}=submission


		const emailEvents=filter(submission.events,{event:'email-sent'})
		const hasMadeEmailContact = emailEvents.length>0

		const initialSubmitStatus = submission.status

		//let submissionDetailedStatus = submission.status
		let submissionDetailedStatus = initialSubmitStatus
	

		if(initialSubmitStatus === 'in-review' && !hasMadeEmailContact){
			submissionDetailedStatus = 'in-review-pre-contact'
		}
		if(initialSubmitStatus === 'in-review' && hasMadeEmailContact){
			submissionDetailedStatus = 'in-review-post-contact'
		}

		const previousSubmissionsByAuthor=filter(allSubmissions,function(o) {
    	if ((o.user === submission.user) && o.id!==submission.id) return o
		})
		
		const plickersRepoURL = getPublicUrlForRepo(repo)		



		return( 
			<div className='packReview-detailPanel'>
				
				<PackReviewDetailPanelHeader
					submission={submission}
					plickersRepoURL={plickersRepoURL}
				/>
				
				<div className={'packReview-detailPanel-actionArea ' + (submissionDetailedStatus ? `packReview-detailPanel-actionArea--${submissionDetailedStatus}` : '') }>
					<PackReviewDetailPanelActionAreaHeader 
						submissionDetailedStatus = {submissionDetailedStatus}						
					/>

					{submissionDetailedStatus === 'submitted' &&
						<React.Fragment>
							<FeatureModalSelectControl
								checkbox
								checked={this.state.jasperChecked}
								onClick={()=>{this.setState({nolanChecked:false,jasperChecked:!this.state.jasperChecked})}}
								label="Assign to Joe" 
							/>
							<FeatureModalSelectControl
								checkbox
								checked={this.state.nolanChecked}
								onClick={()=>{this.setState({nolanChecked:!this.state.nolanChecked,jasperChecked:false})}}
								label="Assign to Nolan" 
							/>

							{previousSubmissionsByAuthor.length>0 &&
								<React.Fragment>
							Other submissions from author
							<br/>
							{previousSubmissionsByAuthor.map((submission)=>{
								return(<div>assignee:{submission.assignee?submission.assignee:'none'}</div>)
							})}	
								</React.Fragment>
							}


							<button className='packReview-detailPanel-actionArea-btn' disabled={!this.state.jasperChecked && !this.state.nolanChecked} onClick={()=>{this.props.assignAndMoveToReview(this.state.nolanChecked,this.state.jasperChecked)}}>
								Assign and&nbsp;<b>move to in review</b>
							</button>
						
						</React.Fragment>
					}

					{submissionDetailedStatus === 'in-review-pre-contact' &&
						<React.Fragment>
							<div className='packReview-detailPanel-actionArea-emailTemplater'>
								<div className='packReview-detailPanel-actionArea-emailTemplater-controls'>
									<div className={'packReview-detailPanel-actionArea-emailTemplater-controls-template ' + (this.state.selectedTemplate ? 'packReview-detailPanel-actionArea-emailTemplater-controls-template--hasTemplate' : 'packReview-detailPanel-actionArea-emailTemplater-controls-template--noTemplate')}>
										<div className='packReview-detailPanel-actionArea-emailTemplater-controls-template-title'>
											Template
										</div>
										
										<FeatureModalSelectControl
											radio
											checked={selectedTemplate==='minorChanges'}
											onClick={()=>{this.setState({selectedTemplate:'minorChanges'})}}
											label="Minor Changes" 
										/>
										<FeatureModalSelectControl
											radio
											checked={selectedTemplate==='majorChanges'}
											onClick={()=>{this.setState({selectedTemplate:'majorChanges'})}}
											label="Major Changes" 
										/>
										<FeatureModalSelectControl
											radio
											checked={selectedTemplate==='cancelWithCause'}
											onClick={()=>{this.setState({selectedTemplate:'cancelWithCause'})}}
											label="Cancel with cause" 
										/>
										<FeatureModalSelectControl
											radio
											checked={selectedTemplate==='cancelWithoutCause'}
											onClick={()=>{this.setState({selectedTemplate:'cancelWithoutCause'})}}
											label="Cancel without cause" 
										/>
									</div>
									{(selectedTemplate === 'majorChanges' || selectedTemplate === 'cancelWithCause') &&
										<div className='packReview-detailPanel-actionArea-emailTemplater-controls-guideline'>
											<div className='packReview-detailPanel-actionArea-emailTemplater-controls-guideline-title'>
												Select Guidelines {selectedTemplate === 'cancelWithCause' && <React.Fragment>(Optional)</React.Fragment>}
											</div>
											
											<FeatureModalSelectControl
												checkbox
												checked={this.state.guideline1Checked}
												//checked={this.state.nolanChecked}
												onClick={()=>{this.setState({guideline1Checked:!this.state.guideline1Checked})}}
												label="1. Focused to topic" 
											/>
											<FeatureModalSelectControl
												checkbox
												checked={this.state.guideline2Checked}
												onClick={()=>{this.setState({guideline2Checked:!this.state.guideline2Checked})}}
												label="2. Substantial" 
											/>
											<FeatureModalSelectControl
												checkbox
												checked={this.state.guideline3Checked}
												onClick={()=>{this.setState({guideline3Checked:!this.state.guideline3Checked})}}
												label="3. Useful to teachers everywhere" 
											/>
											<FeatureModalSelectControl
												checkbox
												checked={this.state.guideline4Checked}
												onClick={()=>{this.setState({guideline4Checked:!this.state.guideline4Checked})}}
												label="4. Well-organized"
											/>
											<FeatureModalSelectControl
												checkbox
												checked={this.state.guideline5Checked}
												onClick={()=>{this.setState({guideline5Checked:!this.state.guideline5Checked})}}
												label="5. High-quality questions"
											/>
											<FeatureModalSelectControl
												checkbox
												checked={this.state.guideline6Checked}
												onClick={()=>{this.setState({guideline6Checked:!this.state.guideline6Checked})}}
												label="6. Original/ContentPolicy"
											/>
										</div>
									}
									{/*
									<button 
										className={'packReview-detailPanel-actionArea-btn packReview-detailPanel-actionArea-btn--frontButton ' + (this.state.selectedTemplate ? '':'packReview-detailPanel-actionArea-btn--disabled')}
										disabled={!this.state.selectedTemplate}
									>
										
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M15,15H2V6h2.595c0,0,0.689-0.896,2.17-2H1C0.447,4,0,4.449,0,5v11c0,0.553,0.447,1,1,1h15c0.553,0,1-0.447,1-1v-3.746  l-2,1.645V15z M13.361,8.05v3.551L20,6.4l-6.639-4.999v3.131C5.3,4.532,5.3,12.5,5.3,12.5C7.582,8.752,8.986,8.05,13.361,8.05z"></path></svg>
										
										<CopyToClipboard text={curl}><div><b>Generate Draft{'\u00A0'}</b>in Front	</div></CopyToClipboard>									
										
									</button>
								*/}
								</div>

								<div className='packReview-detailPanel-actionArea-emailTemplater-previewContainer'>
									{this.state.selectedTemplate &&
										<PackReviewDetailPanelEmailPreview  
											selectedEmailTemplate={this.state.selectedTemplate}
											guideline1Checked={this.state.guideline1Checked}
											guideline2Checked={this.state.guideline2Checked}
											guideline3Checked={this.state.guideline3Checked}
											guideline4Checked={this.state.guideline4Checked}
											guideline5Checked={this.state.guideline5Checked}
											guideline6Checked={this.state.guideline6Checked}
											submission={submission}
											activePackReviewAccount={submission.assignee}
											plickersRepoURL={plickersRepoURL}
										/>
									}
								</div>
								
							</div>
							
							<div className='packReview-detailPanel-actionArea-emailSentControls'>																
									<button className='packReview-detailPanel-actionArea-btn' onClick={()=>{this.props.adminLogEmailEvent(submission.id)}}>
										Mark Sent and keep&nbsp;<b>In Review</b>
									</button>
									<button className='packReview-detailPanel-actionArea-btn' onClick={this.props.emailAndMarkCanceled}>
										Mark Sent and&nbsp;<b>Cancel</b>
									</button>
									{/*}
									<button className='packReview-detailPanel-actionArea-btn' onClick={this.props.emailAndMarkApproved}>
										Sent and Mark&nbsp;<b>Approved</b>
									</button>								
									*/}								
							</div>
							
							
						</React.Fragment>
					}

					{submissionDetailedStatus === 'in-review-post-contact' &&
						<React.Fragment>														
							<div className='packReview-detailPanel-actionArea-buttonRow'>								
								<button className='packReview-detailPanel-actionArea-btn' onClick={this.props.showMarkCanceledConfirmModal}>
									Mark as cancelled
								</button>
								<button className='packReview-detailPanel-actionArea-btn' onClick={this.props.showApproveSubmissionConfirmModal}>
									Mark as approved
								</button>
								 {/* Add final approval checklist */}
							</div>
							
						</React.Fragment>
					}

				</div>
								

			</div>				
		)
	}
}


export default PackReviewDetailPanel



import React from 'react'
import ReactDOM from 'react-dom'
import QuestionVideoReactComponent from './QuestionVideoReactComponent' 
import {playMediaNode} from '../../../../prosemirror/utils/editorActions/playMediaNode'

function randId(){
  return '00000000'.replace(/0/g,function(){return (~~(Math.random()*16)).toString(16)})
}


function handleMouseDown(e){
  e.preventDefault()
  if (e.target === e.currentTarget){
    e.stopPropagation()
  } 
}
 

class QuestionVideoNodeView {

  constructor(node, view, getPos) {
    this.getNode=this.getNode.bind(this)
    this.playVideo=this.playVideo.bind(this)
    this.node = node
    this.getPos=getPos

    const {youtubeId,fullDuration,isClipped,template,questionMediaDynamicHeight,isPlaying,start,end,slideWidth} = node.attrs
    this.dom = document.createElement("div")
    this.dom.className="slide-mediaContainer slide-mediaContainer--video"
    this.dom.addEventListener('mousedown',handleMouseDown)
    
    this.id=randId()

    ReactDOM.render(
      <QuestionVideoReactComponent 
        videoDuration={fullDuration} 
        isClipped={isClipped}
        nodeId={this.id} 
        key={`${youtubeId}_${start}_${end}`} 
        youtubeId={youtubeId}
        template={template}
        start={start}
        end={end}
        questionMediaDynamicHeight={questionMediaDynamicHeight} 
        getNode={this.getNode} 
        getPos={getPos}
        isPlaying={isPlaying}
        playVideo={this.playVideo}
        slideWidth={slideWidth}
        />, this.dom)

   
  } 


  playVideo(){
    const position=this.getPos()
    playMediaNode(position)
  }

  getNode(){
    return this.node
  }

 update(node) {
  if (node.type !== this.node.type) return false

    const {youtubeId,fullDuration,isClipped,template,questionMediaDynamicHeight,isPlaying,start,end,slideWidth} = node.attrs
  
    const oldTemplate=this.node.attrs.template
    const oldStart=this.node.attrs.start
    const oldEnd=this.node.attrs.end
    const oldQuestionMediaDynamicHeight=this.node.attrs.questionMediaDynamicHeight
    const oldIsPlaying=this.node.attrs.isPlaying 
    const oldSlideWidth=this.node.attrs.slideWidth
     if(
      template !==oldTemplate || 
      start !==oldStart || 
      end !==oldEnd || 
      questionMediaDynamicHeight !==oldQuestionMediaDynamicHeight || 
      isPlaying !== oldIsPlaying ||
      slideWidth !== oldSlideWidth
      ){

    ReactDOM.render(
       <QuestionVideoReactComponent 
          fullDuration={fullDuration} 
          isClipped={isClipped}
          nodeId={this.id} 
          key={`${youtubeId}_${start}_${end}`} 
          youtubeId={youtubeId}
          template={template}
          questionMediaDynamicHeight={questionMediaDynamicHeight} 
          getNode={this.getNode} 
          getPos={this.getPos}
          isPlaying={isPlaying}
          playVideo={this.playVideo}
          start={start}
           end={end}
           slideWidth={slideWidth}
        />, this.dom)
      
    }
    this.node=node
    return true


    }
        
        
       

  destroy() {
    this.dom.removeEventListener('mousedown',handleMouseDown)
    ReactDOM.unmountComponentAtNode(this.dom)
  }

}

export default QuestionVideoNodeView
export function formatVideoDuration(duration){ //duration in seconds
	let seconds = ("0" + (Math.floor(duration) % 60)).slice(-2)
	let minutes = ("0" + (Math.floor(duration / 60) % 60)).slice(-2)
	let hours = Math.floor(duration / 3600)
	if(hours>0){
		return `${hours}:${minutes}:${seconds}`
	}else{
		return `${minutes}:${seconds}`
	}
}

import React from 'react'
import sortBy from 'lodash/sortBy'
import {formatDate} from '../../utils/formatDate'
import {calculateStudentTotals} from '../../utils/calculateStudentTotal'
import ScorePill from '../uiKit/ScorePill'
import Button from '../uiKit/Button'
 
//Scoresheet review modal for setPolls 
class ScoreSheetSetReviewModal extends React.Component{

	constructor(props) {
		super(props)
		this.goToReport = this.goToReport.bind(this)
	}

	renderStudentScorePill(student) {
		let score
		if(student.totalAnswers !==0){
			 score = (student.totalCorrect/student.totalAnswers)*100
		}else if(student.totalSurveys!==0){
			score ='N/A'
		}else {
			score ='-'
		}
		return( 
			<div className='scoreSheet-setReviewModal-studentSummary-studentCard' key={student.id} > 	      
				<div className='scoreSheet-setReviewModal-studentSummary-studentCard-name'>{student.displayName}</div>	      	
				<ScorePill score={score} size='small' />	      
			</div>
		)
	}

	goToReport(e){
		e.stopPropagation()
		const reportPath=`/setreport/${this.props.setPoll.id}`
		window.open(reportPath,'_blank')
	}

	render(){
		const {setPoll,section}=this.props
		let studentsWithTotals
			if(section && setPoll){
				studentsWithTotals=calculateStudentTotals(section.students,setPoll)
			}
		const sortedStudents=sortBy(studentsWithTotals, [function(o) { 
					return o.displayName.toString().toLowerCase()
		}]) 

		if(setPoll.snapshot){
			return(
				<div className='scoreSheet-setReviewModal'>
					

					<div className='scoreSheet-setReviewModal-header'>

						<div className='scoreSheet-setReviewModal-header--left'>
							<div className='scoreSheet-setReviewModal-header-setTitle'>
								{setPoll.snapshot.name}
							</div>

							<div className='scoreSheet-setReviewModal-header-timestamp'>
								Played {formatDate(setPoll.minCaptured)}
							</div>
						</div>

						<div className='scoreSheet-setReviewModal-header--right'>
							<div className='scoreSheet-setReviewModal-header-scorePillContainer'>
								{setPoll.correctPercent > -1 &&
									<ScorePill score={setPoll.correctPercent} typeDot />			
								}								
							</div>

							{setPoll.correctPercent > -1 &&
									<div className='scoreSheet-setReviewModal-header-classAverageLabel'>
								Class Average
							</div>
								}								
							
						</div>

					</div>

					{setPoll.correctPercent > -1 &&
						<div className='scoreSheet-setReviewModal-studentSummaryContainer'>
							<div className='scoreSheet-setReviewModal-studentSummary-blockLine'/>
							<div className='scoreSheet-setReviewModal-studentSummary'>			
								{sortedStudents.map(this.renderStudentScorePill)}						
							</div>
						</div>
					}

					{setPoll.correctPercent === -1 &&
						<div className='scoreSheet-setReviewModal-noSummary'>
							No student summary available.
						</div>
					}
					<div className='scoreSheet-setReviewModal-reportLinkContainer'>
						<Button label='Go to Report' onClickFunction={this.goToReport}/>
					</div>	
			</div>
		)
		}else return null
	}
}

export default ScoreSheetSetReviewModal

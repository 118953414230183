import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import {updateMeta} from '../actions/meta'
import Icon from '../components/misc/Icons'
import find from 'lodash/find'
 
//const timeBetweenShowModal=1000*60*60*24*7 //1 week in ms

class SyncLearnMoreModalFAQItem extends Component {
	render() {
		return (
			<div className='syncLearnMoreModal-block-faqItem'>
				<div className='syncLearnMoreModal-block-faqItem-title'>
					{this.props.title}
				</div>
				<div className='syncLearnMoreModal-block-faqItem-description'>
					{this.props.description}
				</div>					
				{this.props.description2 &&
				<div className='syncLearnMoreModal-block-faqItem-description'>
					{this.props.description2}
				</div>					
				}
				{this.props.description3 &&
				<div className='syncLearnMoreModal-block-faqItem-description'>
					{this.props.description3}
				</div>					
				}
				{this.props.children &&
				<div className='syncLearnMoreModal-block-faqItem-description'>
					{this.props.children}
				</div>					
				}
							
			</div>								
		)
	}
}


class SyncLearnMoreModalHelpVideo extends Component {
	render() {
		return (
			<div className='syncLearnMoreModal-block syncLearnMoreModal-block--helpVideo'>
				<div className='syncLearnMoreModal-block-helpVideoContainer'>																									
						<iframe className="syncLearnMoreModal-block-helpVideo" 
							src='https://www.youtube-nocookie.com/embed/tOS6wDctlDs'
							cc_load_policy="1"
							cc_lang_pref="en" // required to show captions by default
							frameBorder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							rel="0" // only show related videos from same channel
							allowFullScreen
							title='helpVideoModal'
						>
						</iframe>								
				</div>																	
			</div>	
		)
	}
}


class SyncLearnMoreModal extends Component {

	constructor(){
		super()				
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)
	}

	componentDidMount() {
		if(window.analytics){
			const {isActivationMode}=this.props
			 window.analytics.page('Sync Learn More Modal',{
				isActivationMode:isActivationMode,

			 })
		}
	}

	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.props.dismissModal() //close modal is click outside modal
	}
		
	render() {
		const {isActivationMode,section}=this.props
		const helpMode = !isActivationMode
		const activateMode = isActivationMode
			return (
				<div className='syncLearnMoreModalContainer' onClick={this.handleBackgroundClick}>				

					<div className={'syncLearnMoreModal ' + (helpMode ? 'syncLearnMoreModal--helpMode' : 'syncLearnMoreModal--activateMode')}>


						<div onClick={this.props.dismissModal} className='syncLearnMoreModal-closeBtn'>
							<Icon name='circle-with-close' />
						</div>


						{activateMode &&
							<div className='syncLearnMoreModal-header'>

								<div className='syncLearnMoreModal-header-headline'>
									Try Plickers E-Learning in your next class.
								</div>
								
								<div className='syncLearnMoreModal-header-subline'>
									<div className='syncLearnMoreModal-header-subline-p'>
										For live-remote and hybrid teaching environments. 
									</div>
									<div className='syncLearnMoreModal-header-subline-p'>
										Students answer live via laptop, Chromebook, or iPad. 
									</div>
									<div className='syncLearnMoreModal-header-subline-p'>
										No ads, no tracking, 100% COPPA compliance. <a href="https://help.plickers.com/hc/en-us/articles/360049891033-COPPA" target='_blank' rel='noopener noreferrer'>Learn More.</a>
									</div>
								</div>

								<div className='syncLearnMoreModal-header-primaryButtonContainer'>
									<div className='syncLearnMoreModal-header-primaryButton-leftLine'>
										
											<svg xmlns="http://www.w3.org/2000/svg" width="62" height="54" viewBox="0 0 62 54">
												<path id="line" fill="none" stroke="#000" strokeDasharray="4 4" d="M1,4.89396102 C5.35565771,-2.00716938 11.4112198,0.264673558 19.1666863,11.7094898 C30.7998861,28.8767143 24.2555335,46.8293015 32.8769159,51.9350314 C38.6245041,55.3388513 48.6655322,50.583283 63,37.6683266"/>
											</svg>
										
									</div>
									<button className='syncLearnMoreModal-header-primaryButton' onClick={this.props.enableELearning}>
										<div className='syncLearnMoreModal-header-primaryButton-text'>
											Enable E-Learning for {section.name}
										</div>
										<div className='syncLearnMoreModal-header-primaryButton-icon'>
											<Icon name='chevron-right' />
											
										</div>
									</button>
								</div>
							
							</div>
						}

						
						{activateMode && 
							<React.Fragment>

								


								<div className='syncLearnMoreModal-block syncLearnMoreModal-block--students'>

									<div className='syncLearnMoreModal-block-text'>
										<div className='syncLearnMoreModal-block-text-header'>
											Clear and simple for students
										</div>
										<div className='syncLearnMoreModal-block-text-description'>
											<div className='syncLearnMoreModal-block-text-description-p'>
												No accounts, logins, or daily game codes.
											</div>
											<div className='syncLearnMoreModal-block-text-description-p'>
												Designed for student privacy with  100% COPPA and GDPR compliance.
											</div>
											<div className='syncLearnMoreModal-block-text-description-p'>
												Instructor-led pacing puts you in the driver's seat.
											</div>	
										</div>							
									</div>

									<div className='syncLearnMoreModal-block-visual'>
										<div className='syncLearnMoreModal-block-visual-screenContainer'>
											<div className='syncLearnMoreModal-block-visual-screen'>																				
																								
												<video loop autoPlay muted preload="auto" poster="https://res.cloudinary.com/plickers-static/image/upload/w_962,f_auto,q_100/v1599909639/elearningvideos/ELearningLearnMoreStudentBGVideoPoster.B.png" class="syncBetaModal-videoTest-video">
														<source src="https://res.cloudinary.com/plickers-static/video/upload/w_962/q_auto:best/v1599909667/elearningvideos/ELearningLearnMoreStudentBGVideo.B.mp4" type="video/mp4" />
												</video>										    
									
											</div>
										</div>
										<div className='syncLearnMoreModal-block-visual-bg'>
											<svg xmlns="http://www.w3.org/2000/svg" width="526" height="420" viewBox="0 0 526 420">
												<path d="M22.8676029,21.9516733 C50.6717799,-8.96374469 204.79464,1.82173492 355.989633,1.82173492 C566.026289,1.82173492 533.748327,2.64066112 554.6875,12.95 C568.149198,19.5778265 526.916273,73.3989229 526,135.13776 C524.320335,248.314273 586.649665,418.954242 526,420 C398.59904,422.196725 133.20638,429.25 59.7605239,429.25 C-35.6514664,429.25 21.5552984,182.293988 8.30936221,135.13776 C-4.93657395,87.9815327 -4.93657395,52.8670913 22.8676029,21.9516733 Z"/>
											</svg>
										</div>
									</div>							

								</div>



								<div className='syncLearnMoreModal-block syncLearnMoreModal-block--teachers'>

									<div className='syncLearnMoreModal-block-visual'>
										<div className='syncLearnMoreModal-block-visual-screenContainer'>
											<div className='syncLearnMoreModal-block-visual-screen'>																														
												
												<video loop autoPlay muted preload="auto" poster="https://res.cloudinary.com/plickers-static/image/upload/w_962,f_auto,q_100/v1599920900/elearningvideos/ELearningLearnMoreTeacherBGVideoPoster.A.png" class="syncBetaModal-videoTest-video">
														<source src="https://res.cloudinary.com/plickers-static/video/upload/w_962/q_auto:best/v1599920913/elearningvideos/ELearningLearnMoreTeacherBGVideo.A.mp4" type="video/mp4" />
												</video>


											</div>
										</div>
										<div className='syncLearnMoreModal-block-visual-bg'>									
											<svg xmlns="http://www.w3.org/2000/svg" width="526" height="420" viewBox="0 0 526 420">
												<path d="M4.41531256e-11,-0.00258747824 C200.923536,-0.00258747824 500.478147,-0.431243611 520.508807,9.84089567 C533.386425,16.4448067 521.385324,66.9347619 520.508807,128.450825 C518.902019,241.218958 526.913062,419.648964 526,420 C495.669459,431.66089 267.80721,418.958015 209.789026,420 C87.9157697,422.188798 70.2591701,420 3.85256271e-11,420 C-91.2722324,420 12.6712184,221.337424 4.54605242e-11,174.351352 C-12.6712184,127.36528 -144.634909,-0.00258747824 4.41531256e-11,-0.00258747824 Z"/>
											</svg>									
										</div>
									</div>							


									<div className='syncLearnMoreModal-block-text'>
										<div className='syncLearnMoreModal-block-text-header'>
											Real-time formative results
										</div>
										<div className='syncLearnMoreModal-block-text-description'>
											<div className='syncLearnMoreModal-block-text-description-p'>
												See all your students’ answers live as they come in.
											</div>
											<div className='syncLearnMoreModal-block-text-description-p'>
												In Hybrid mode, students at home use <br/>E-Learning while students in your classroom use cards.
											</div>
											{/*
											<div className='syncLearnMoreModal-block-text-description-p'>
												Switch between Plickers cards, E-Learning, and Hybrid modes instantly.
											</div>
										*/}
										</div>							
									</div>
						
								</div>


								
							</React.Fragment>
						}

						{helpMode &&
							<div className='syncLearnMoreModal-header'>

								<div className='syncLearnMoreModal-header-headline'>
									Getting started with Plickers E-Learning
								</div>
								
								<div className='syncLearnMoreModal-header-subline'>
									<div className='syncLearnMoreModal-header-subline-p'>
										Students need to register their device by clicking their unique registration link.
									</div>
									<div className='syncLearnMoreModal-header-subline-p'>
										After registering, students visit my.plickers.com for the rest of the school year to join.
									</div>
									<div className='syncLearnMoreModal-header-subline-p'>
										You can switch between Cards, E-Learning, and Hybrid modes as your school scenario changes.
									</div>
								</div>								
							
							</div>
						}



					
						{helpMode &&
							<SyncLearnMoreModalHelpVideo />
						}
					

						

						<div className='syncLearnMoreModal-block syncLearnMoreModal-block--faq'>
						
							<div className='syncLearnMoreModal-block-text'>
								<div className='syncLearnMoreModal-block-text-header'>
									FAQ
								</div>								
							</div>

							<div className='syncLearnMoreModal-block-faqContainer'>
									
								
								<SyncLearnMoreModalFAQItem
									title='Can I have some students on E-Learning and some students using cards at the same time?'
									description="Yes! When you're playing an item, select Hybrid mode in the top-right corner."
									description2="Hybrid mode is a classic Plickers view you can project in your classroom, and displays responses from cards and E-Learning as they arrive."
								/>

								

								<SyncLearnMoreModalFAQItem
									title="Do I need to use the mobile app?"
									description="If all your students are on E-Learning, you don’t need to use the mobile app – as all the controls are available on your computer– but you might find the app useful as a remote control."
									description2="If you're using Plickers in Hybrid mode, you'll continue to use the app as normal for scanning cards. Plickers will accept E-Learning answers while you're 'scanning' in the app."
								/>

								<SyncLearnMoreModalFAQItem
									title="When I play a set in class, what do my students need to do?"
									description="Once they do the one-time registration on their device, all your students need to do is go to my.plickers.com"
									description2="As you play sets, they'll automatically open on students' screens."
								/>

								<SyncLearnMoreModalFAQItem
									title="Do I need to share my screen?"
									description="No. By default, when you activate E-Learning on a class, you see the Teacher-only view (E-Learning mode), which displays all the results, including students' individual answers."
									description2="If you want to share your screen – for example, to deep-dive into a question with students – use Hybrid mode."
								/>

								<SyncLearnMoreModalFAQItem
									title='Do students need to create accounts?'
									description='No. There’s only one step for students: to click the registration link you’ve sent them.'
									description2='After that, they can simply visit my.plickers.com (or their link) to participate.'
								/>


								<SyncLearnMoreModalFAQItem
									title="Can students use this on a shared computer?"
									description="Yes – students can log out of any class on their device by clicking 'Edit Classes'. They will then need to follow their link to re-register."
								/>


								<SyncLearnMoreModalFAQItem
									title='Is this private and secure?'
									description="We've designed e-Learning for privacy and security from the ground up."
									description2='No student accounts or logins are required, and we do not collect names, addresses, phone numbers, photos, or locations from students. We do not allow students to make any information publicly available. All student responses are fully-encrypted in transit.'
								>
									For more information on how we handle data from students, please see <a href="https://help.plickers.com/hc/en-us/articles/360009090833-Privacy-Policy#Information-Collected-About-Students" target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}}>Section 11 of our Privacy Policy</a>
								</SyncLearnMoreModalFAQItem>

								<SyncLearnMoreModalFAQItem
									title='When I play a set in class, what do my students need to do?'
									description='If they’ve done the one-time registration to their device, all your students need to do is go to my.plickers.com'
									description2='Sets will automatically open on their screen as you play them.'
								/>

								<SyncLearnMoreModalFAQItem
									title='What student devices are supported?'
									description='Laptops, Chromebooks, and iPads are all supported.'
								/>

								<SyncLearnMoreModalFAQItem
									title='How are student results saved?'
									description="All of your students' responses will be saved like normal and available in reports and Scoresheet."
								/>							

								<SyncLearnMoreModalFAQItem
									title='Can I try out the student app?'
									description='The easiest way is to create a test class with a test student, and click on the student’s link yourself.'
									description2='Then you can be the teacher in one browser tab, and the student in the other.'
								/>
													
								<SyncLearnMoreModalFAQItem
									title='Can my students use this with their other teachers?'
									description='Yep, when a student visits a link for another class, we’ll add that class to their home screen alongside your class.'
								/>

								<SyncLearnMoreModalFAQItem
									title='Can students use multiple devices?'
									description='Yes, students can register to multiple devices.'
									description2='However, multiple students in the same class cannot be registered to a single device.'
								/>

							</div>
							
						</div>

						
						{activateMode &&
							<SyncLearnMoreModalHelpVideo />
						}
						

						{activateMode &&
							<div className='syncLearnMoreModal-block syncLearnMoreModal-block--doneBtn'>
								<button className='syncLearnMoreModal-header-primaryButton' onClick={this.props.enableELearning}>								
									<div className='syncLearnMoreModal-header-primaryButton-text'>
										Enable E-Learning for {section.name}													
									</div>	
								</button>
								<button className='syncLearnMoreModal-notNowBtn' onClick={this.props.dismissModal}>								
									Not now
								</button>
							</div>
						}
												
						<div className='syncLearnMoreModal-block syncLearnMoreModal-block--helpBtn'>
							{!activateMode &&
								<br/>
							}
							<a className='syncLearnMoreModal-helpLink' target='_blank' rel='noopener noreferrer' href='https://help.plickers.com/hc/en-us/categories/1260801512989-Remote-Learning'>
								Learn more in the E-Learning Help Guide
							</a>
						</div>

					</div>
				</div>								
			)
	}
}



class SyncLearnMoreModalContainer extends Component {

	constructor(){
		super()	
		this.shouldShowModal=this.shouldShowModal.bind(this)
		this.dismissModal=this.dismissModal.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)    
	}


	componentDidMount(){
		window.addEventListener('keydown', this.handleKeyDown)
	}
	
	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	handleKeyDown(e){
		if(e.keyCode === 27){//escape key
			this.dismissModal()
		}
	}

	shouldShowModal(){
		const {location}=this.props.history
		if(location.hash==='#e-learning-help' || location.hash==='#e-learning-activation'){
			return true
		}
		else return false		
	}

	dismissModal(){
		window.location.hash=''
	}

	render() {
		const {location}=this.props.history
		let isActivationMode=true

		if(location.hash==='#e-learning-help'){
			isActivationMode=false
		}

		if(this.shouldShowModal() ){
			return(
				<SyncLearnMoreModal 
					dismissModal={this.dismissModal}
					meta={this.props.meta}
					isActivationMode={isActivationMode}
					section={this.props.currentSection}
					enableELearning={()=>{window.location.hash='consent'}}
		
			/>)
		
		}
		else return null
	}
}





function mapStateToProps(state,ownProps) {
	var expression = window.location.pathname.match(/.*\/(.*)\/(.*)$/)
	let currentSection
	if(expression){
		const sectionId=expression[2]
		if(sectionId){
			currentSection=find(state.sections, {'id':sectionId})||{}	
		}
	}
	return {
		currentSection: currentSection
	}
}

export default withRouter(connect(mapStateToProps,
	{ updateMeta}
)(SyncLearnMoreModalContainer))
import store from '../../store'
import find from 'lodash/find'


export function sendRepoPageEvent(repoId) {
	const state = store.getState()
	const repo= find(state.repos, {'id':repoId})

	if(window.analytics && repo){
		let sharedType = 'private'
		if(repo.published){
			sharedType='public'
		}
		else if(repo.shared){
			sharedType='shared'
		}
		let permissionRole = 'pending'
		if(repo.disabled){
			permissionRole = 'disabled'
		}else if(repo.joined){
			permissionRole = repo.role
		}else if(repo.published){
			permissionRole = repo.role
		}
		let hasImage = false
		if(repo.image !==''){
			hasImage = true
		}
		const repoName=repo.name
		const repoDescription=repo.description

		window.analytics.track('Repo',{
			repoId:repoId,
			sharedType:sharedType,
			permissionRole:permissionRole,
			hasImage:hasImage,
			name:repoName,
			description:repoDescription,
			isFollowing:repo.following

		})
	

	}
	
}






import findIndex from 'lodash/findIndex'



export default function (state=[], action) {

	switch (action.type) {


	case 'FETCH_ARCHIVED_QUESTIONS_SUCCESS':
		let newState = [...state] || []
		action.response.forEach(question => {
			const questionId = question.id
			let indexOfquestion = newState.findIndex(question => {
				return question.id === questionId
			})
	
			if(indexOfquestion === -1){
				indexOfquestion=newState.length
			}
			newState= [
				...newState.slice(0,indexOfquestion),
				question,
				...newState.slice(indexOfquestion + 1)
			]	
		})
		return newState

 



	case 'UPDATE_QUESTION_SUCCESS':
	case 'UPDATE_QUESTION_FROM_PUSHER':

		const updatedQuestionId = action.response.id
		var indexOfQuestionToUpdate = findIndex(state,(question => {
			return question.id === updatedQuestionId
		}))
		if(indexOfQuestionToUpdate !== -1 ){
			if(action.response.archived===true){
				return [
					...state.slice(0,indexOfQuestionToUpdate),
					action.response,
					...state.slice(indexOfQuestionToUpdate + 1)
				]
			}else {
				return [
					...state.slice(0,indexOfQuestionToUpdate),
					...state.slice(indexOfQuestionToUpdate + 1)
				]
			}}
		else {
			if(action.response.archived===true){
				return [...state,action.response]
			}else return state
		}



	case 'DELETE_QUESTION_SUCCESS':
	case 'QUESTION_REMOVED_PUSHER':
		const deletedQuestionId = action.questionId
		const indexOfQuestionToDelete = state.findIndex(question => {
			return question.id === deletedQuestionId
		})
		if(indexOfQuestionToDelete !==-1){	
			return [
				...state.slice(0,indexOfQuestionToDelete),
				...state.slice(indexOfQuestionToDelete + 1)
			]
		}else return state







	case 'LOGOUT':
		return []

	default:
		return state

	}
}
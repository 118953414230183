import store from '../../store'



export function createRepoEvent(newRepo) {
	const state = store.getState()
	const repos= state.repos

	if(window.analytics){
		
		let hasImage = false
		if(newRepo.image !==''){
			hasImage = true
		}
		const repoName=newRepo.name
		const repoDescription=newRepo.description
		const repoCount=repos.length
		const repoId=newRepo.id

		window.analytics.track('Create New Repo',{
			hasImage:hasImage,
			name:repoName,
			description:repoDescription,
			newRepoCount:repoCount,
			repoId:repoId


		})
	

	}
	
}






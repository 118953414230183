import React from 'react'
import EditorIcon from '../misc/EditorIcons'


class LiveViewUniversalMediaUnzoomBtn extends React.PureComponent{	
	render(){
		const slideScale = 1280 / this.props.slideWidth
		const inverseScale = 1 / slideScale
		return(
			<button onClick={this.props.unzoomMedia} style={{transform: `scale(${inverseScale})`}} className={'liveView-universalMediaUnzoomBtn ' + (this.props.mouseMove ? ' liveView-universalMediaUnzoomBtn--mouseMove ' : ' liveView-universalMediaUnzoomBtn--mouseNotMoved ') + (this.props.mediaZoomed ? ' liveView-universalMediaUnzoomBtn--mediaZoomed ' : ' liveView-universalMediaUnzoomBtn--mediaNotZoomed ')}>				
				<div className={'liveView-universalMediaUnzoomBtn-inner' + (this.props.mediaZoomed ? ' liveView-universalMediaUnzoomBtn-inner--mediaZoomed ' : ' liveView-universalMediaUnzoomBtn-inner--mediaNotZoomed ')}>
					<EditorIcon name='unZoomMedia' />
				</div>
			</button>				
		)
	}
}

export default LiveViewUniversalMediaUnzoomBtn
import React from 'react'
import slideLayoutDefinitions from '../../../../staticData/slideLayoutDefinitions.js'

class StaticSlideZoomableVideoPlayer extends React.Component{


  render(){
    const {videoWidth, videoHeight, slideTemplate, questionMediaDynamicHeight} = this.props

    
    const videoAspect = videoWidth / videoHeight

    const videoContainerWidth = slideLayoutDefinitions.bodyMediaContainerDimensions[`${slideTemplate}`].width
    let videoContainerHeight = slideLayoutDefinitions.bodyMediaContainerDimensions[`${slideTemplate}`].height

    if(slideTemplate === 'bodyCenterMediaCenter'){
      videoContainerHeight = questionMediaDynamicHeight
    }

    const videoContainerAspect = videoContainerWidth / videoContainerHeight    
    
    let fitVideoWidth
    let fitVideoHeight     
    
    if(videoAspect > videoContainerAspect){ // image is more landscape than media choice container
      fitVideoWidth = videoContainerWidth
      fitVideoHeight = fitVideoWidth / videoAspect
    }

    if(videoAspect < videoContainerAspect){ // image is more portrait than media choice container
      fitVideoHeight = videoContainerHeight
      fitVideoWidth = fitVideoHeight * videoAspect
    }

    // POSITIONING WITHIN CONTAINER

    let videoLayoutStyle

    if(slideTemplate === 'bodyLeftMediaRight' || slideTemplate === "bodyLeftMediaRightChoicesMedia"){
      videoLayoutStyle = {top: '0px', right: '0px'}
    }
    if(slideTemplate === 'bodyRightMediaLeft'){
      videoLayoutStyle = {top: '0px', left: '0px'}
    }
    if(slideTemplate === 'bodyCenterMediaCenter'){
      videoLayoutStyle = {top: '0px', margin: 'auto', left: '0px', right:'0px'}
    }    

        
    return(             
      <div id="videoPlayerZoomable" style={{width: `${fitVideoWidth}px`, height: `${fitVideoHeight}px`, ...videoLayoutStyle}}  className='slide-media--videoCover'>
        {/*
          image.deliveryDimensions.w: {image.deliveryDimensions.w}<br/>
          image.deliveryDimensions.h: {image.deliveryDimensions.h}<br/>
          Image Container Width: {videoContainerWidth}<br/>
          Image Container Height: {videoContainerHeight}<br/>
          Fit Image Width: {fitVideoWidth}<br/>
          Fit Image Width: {fitVideoHeight}<br/>
        */}
      </div>
    )   
  } 
}


export default StaticSlideZoomableVideoPlayer

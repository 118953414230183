import React from 'react'


// class ExplorerSavedMarketDropdownButton extends React.Component{
// 	render(){
// 		const {onClickFunction, isOpen}=this.props
// 		return(
// 			<button className={'btn btn--mid btn--white btn--dropdown explorer-topBar-marketDropdownBtn ' + (isOpen ? ' btn--dropdown--open' : '')} onClick={onClickFunction}>
// 				Switch Markets
// 			</button>
// 		)
// 	}
// }

// class ExplorerSaveMarketOptionsDropdownButton extends React.Component{
// 	render(){
// 		const {onClickFunction, isOpen}=this.props
// 		return(
// 			<button className={'btn btn--mid btn--white btn--dropdown explorer-topBar-marketDropdownBtn ' + (isOpen ? ' btn--dropdown--open' : '')} onClick={onClickFunction}>
// 				Save
// 			</button>
// 		)
// 	}
// }



class ExplorerTopBar extends React.Component{ 
 


	render(){

 
		return( 
			<div className='explorer-topBar' >
				<div className='explorer-topBar-left' >
					<div className='explorer-topBar-left-title' >
						<b>Explorer</b>
					</div>
				</div>
				<div className='explorer-topBar-center' >
					{/*
					<div className='explorer-topBar-tagArray' >
						<ExplorerTopBarTag title='Biology' tagType='subject' />
						<ExplorerTopBarTag title='Grade 9' tagType='grade' />
						<ExplorerTopBarTag title='Grade 10' tagType='grade' />
						<ExplorerTopBarTag title='Grade 11' tagType='grade' />
						<ExplorerTopBarTag title='English' tagType='language' />
					</div>
					*/}
				</div>
				<div className='explorer-topBar-right' >					
					{/* }
					<Button label='New Blank Market' />
					<Dropdown dropdownButton={<ExplorerSavedMarketDropdownButton />}>
						<DropdownItem label='Biology Grades 5-10 EN' />
						<DropdownItem label='Chemistry Grades 4-7 EN' />
						<DropdownItem label='Maths Grades 7-9 EN' />											
					</Dropdown>
					<Dropdown dropdownButton={<ExplorerSaveMarketOptionsDropdownButton />}>
						<DropdownItem label='Save over existing' />
						<DropdownItem label='Save as new' />						
					</Dropdown>
				*/}
				</div>
			</div>
		)
	}
}


export default ExplorerTopBar



// (Under 60 min) 45 min ago
// (Under 24 hours) 1 hr ago → 5 hr ago
// (over 24 hours) 1 day ago → 4 days ago
// (over 7 days) 1 week ago → 5 weeks ago


import moment from 'moment'


export function formatDiscoverLatestTimestamp(timestamp) {

	const nowDate =moment()
	let formatedTimestamp
	const seconds = Math.floor((nowDate - moment(timestamp)) / 1000)
	const minutes = Math.floor(seconds / 60)
	if(minutes<60){
		if(minutes===1){
			formatedTimestamp = `${minutes} min ago`
		}else{
			formatedTimestamp = `${minutes} mins ago`
		}
		
	}else{
		const hours = Math.floor(minutes / 60)
		if(hours<24){
			if(hours===1){
				formatedTimestamp = `${hours} hour ago`
			}
			else{
				formatedTimestamp = `${hours} hours ago`
			}
		}else{
			const days = Math.floor(hours / 24)
			if(days<7){
				if(days===1){
					formatedTimestamp = `${days} day ago`
				}else{
					formatedTimestamp = `${days} days ago`
				}
				
			}else{
				const weeks = Math.max(Math.floor(days / 7),1)
				if(weeks===1){
					formatedTimestamp = `${weeks} week ago`
				}else{
					formatedTimestamp = `${weeks} weeks ago`
				}
				

			}
		}

	}

	return formatedTimestamp

}
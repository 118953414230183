import {updateRecentActivities} from '../actions/recentActivities'
import store from '../store'
 

// export function openItemInEditor(itemId,isSet) {
// 	window.open(`/${isSet? 'set':''}editor/${itemId}`,'_blank')
// 	if(isSet){
// 		return store.dispatch(updateRecentActivities(itemId,'set','open'))
// 	}
// 	else{
// 		return store.dispatch(updateRecentActivities(itemId,'question','open'))
// 	}
// }

	

export function openItemInEditor(itemId,isSet) {
	window.open(`/${isSet? 'seteditor':'editor'}/${itemId}`,'_blank')
	if(isSet){
		return store.dispatch(updateRecentActivities(itemId,'set','open'))
	}
	else{
		return store.dispatch(updateRecentActivities(itemId,'question','open'))
	}
}

	
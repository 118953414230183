export default function (state = {}, action) {
	switch (action.type) {

	case 'FETCH_USER_PROFILE_SUCCESS':				
		const response=action.response
			 return {...state,
			 [`${response.username}`]:response
			 }
	
	case 'LOGOUT':
		return {}
	
	default:
		return state
	
	}
}

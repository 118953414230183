export default function (state = {}, action) {
	switch (action.type) {

	case 'PROPOSE_CONTROL':
		return action.controlObject
	
	case 'LOGOUT':
		return {}
	
	default:
		return state
	}
}

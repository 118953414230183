import React from 'react' 
import TopPageHeader from '../../components/pageHeaders/TopPageHeader'
import Icon from '../../components/misc/Icons'
import StickyDividerWrapper from '../uiKit/StickyDividerWrapper'
import PageNotFoundLabel from '../../components/misc/PageNotFoundLabel'
 

class RepoLoadingPage extends React.Component{

	render(){ 

		let RepoLoadingPageHeaderInfoRow =
			<React.Fragment>
				<div className='pageHeaderTop--pageHeaderTop-headerInfoRow-contents'>
					
					<div className='pageHeaderTop--repo-headerInfoRow-contents-authorContainer'>
						<div className='pageHeaderTop--repo-headerInfoRow-contents-author'>
							<div className='pageHeaderTop--repo-headerInfoRow-contents-author-profilePicContainer'>
								<div className='pageHeaderTop--repo-headerInfoRow-contents-author-profilePicPageLoader' />
							</div>
							<div className='pageHeaderTop--repo-headerInfoRow-contents-author-username'>
								<div className='pageHeaderTop--repo-headerInfoRow-contents-author-username-pageLoaderPlaceholder'/>
							</div>
						</div>
					</div>
						
					<div className='pageHeaderTop--repo-headerInfoRow-contents-metadata'>
						<div className='pageHeaderTop--repo-headerInfoRow-contents-metadata-pageLoaderPlaceholder'/>						
					</div>
					

					<div className='pageHeaderTop--repo-headerInfoRow-contents-description'>
						<div className='pageHeaderTop--repo-headerInfoRow-contents-description-pageLoaderPlaceholder'/>
					</div>

				</div>
			</React.Fragment>

		
		return(
			

			<div className='page page--sidePanelLayout' >
				<div className='page-leftPanel'/>
				<div className={'page-centerPanel repo repoPageLoader ' + (this.props.isPublicConsumer ? 'repo--publicConsumer':'')}>
					{this.props.isPublicConsumer && !this.props.pageNotFound && 
						<TopPageHeader
						location='repo'
						leftBadge={
							<React.Fragment>																
									<div className='pageHeaderTop-leftBadge-repoImageContainer'>
										<div className='pageHeaderTop-leftBadge-repoImageContainer-pageLoader' />
									</div>									
							</React.Fragment>
						}
						header={
							<React.Fragment>
								<div className='pageHeaderTop--repo-headerRow-header-loadingPlaceholder' />
							</React.Fragment>
						}
						headerInfoRow={RepoLoadingPageHeaderInfoRow}
						//actionRow={RepoPageHeaderActions}						
						repoWithImage						
						rightBadge={<Icon name='globe-shared' />}							
						/>
					}						

					{this.props.isPublicConsumer && !this.props.pageNotFound && 
						<div className='repo-content repo-content--repoBase'>
							<div className='repo-table--container'>				
								<StickyDividerWrapper className='repo'>	
									<div className='sortableColumnHeaders is--sortedByName is--sortedAscending'>
										<div className='sortableColumnHeaders-name'>
											Name								
										</div>
										<div className='sortableColumnHeaders-modified'>
											Modified								
										</div>
									</div>
								</StickyDividerWrapper>			
							</div>
						</div>
					}

					{this.props.pageNotFound && 
						<PageNotFoundLabel /> 
					}

				</div>
				<div className="page-rightPanel" />		
			</div>
		)
	}

}

       

export default RepoLoadingPage

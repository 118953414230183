import React from 'react'
import { withRouter} from 'react-router-dom'
import PackReviewRepoSubmission from './PackReviewRepoSubmission'
import PackReviewDetailPanel from './PackReviewDetailPanel'

//This hardcoded for Joe/Nolan to do pack review as part of old creator fund project
//may want to take this out at some point 

class PackReviewSideNavFilter extends React.Component{ 
	render(){
		const {isActive, label, count, name} = this.props
		return( 
			<button 
				onClick={this.props.onClickFunction}
				className={'packReview-sideNav-filter ' 
					+ (isActive ? 'packReview-sideNav-filter--active' : 'packReview-sideNav-filter--inactive')
					+ (label ? ` packReview-sideNav-filter--${name}` : '')
				}
			>				
				<div className='packReview-sideNav-filter-label'>
					{label}
				</div>
				<div className='packReview-sideNav-filter-count'>					
					{count}
				</div>
			</button>
		)
	}
}


class PackReviewPage extends React.Component{ 

	render(){
		const {allSubmissions,activeSubmissions,filterTerm,inbox,inReviewSubmissions,approvedSubmissions,cancelledSubmissions,currentUser} = this.props
		let submissions=allSubmissions
		if(filterTerm==='active'){
			submissions=activeSubmissions
		}else if(filterTerm==='inbox'){
			submissions=inbox
		}else if(filterTerm==='in-review'){
			submissions=inReviewSubmissions
		}
		else if(filterTerm==='approved'){
			submissions=approvedSubmissions
		}
		else if(filterTerm==='cancelled'){
			submissions=cancelledSubmissions
		}


		let activePackReviewAccount = 'Joe'
		if(currentUser.email==='nolan@plickers.com'){
			activePackReviewAccount='Nolan'
		} 

		// const activePackReviewAccount = 'Nolan'		

		return( 
			<div className='page packReview' >
				<div className='packReview-sideNav'>	

					<div className='packReview-sideNav-activeAccount'>
						{activePackReviewAccount}
					</div>

					<PackReviewSideNavFilter
						label='All Active'
						count={activeSubmissions.length}
						isActive={filterTerm==='active'}
						onClickFunction={()=>this.props.setFilter('active')}
						name='allactive'
					/>
					<div className='packReview-sideNav-spacer'/>
					<PackReviewSideNavFilter
						label='Your Inbox'
						count={inbox.length}
						isActive={filterTerm==='inbox'}
						onClickFunction={()=>this.props.setFilter('inbox')}
						name='inbox'
					/>
					<PackReviewSideNavFilter
						label='In Review'
						count={inReviewSubmissions.length}
						isActive={filterTerm==='in-review'}
						onClickFunction={()=>this.props.setFilter('in-review')}
						name='inreview'
					/>
					<PackReviewSideNavFilter
						label='Approved'
						count={approvedSubmissions.length}
						isActive={filterTerm==='approved'}
						onClickFunction={()=>this.props.setFilter('approved')}
						name='approved'
					/>					
					<div className='packReview-sideNav-spacer'/>
					<div className='packReview-sideNav-spacer'/>
					<PackReviewSideNavFilter
						label='Everything'
						count={allSubmissions.length}
						isActive={filterTerm==='all'}
						onClickFunction={()=>this.props.setFilter('all')}
						name='all'
					/>															
					<PackReviewSideNavFilter
						label='Cancelled'
						count={cancelledSubmissions.length}
						isActive={filterTerm==='cancelled'}
						onClickFunction={()=>this.props.setFilter('cancelled')}
						name='cancelled'
					/>					
					<PackReviewSideNavFilter
						label='Withdrawn'
						count='0'
						isActive={filterTerm==='withdrawn'}
						onClickFunction={()=>this.props.setFilter('withdrawn')}
						withdrawn
					
					/>					
				</div>
				
				<div className='packReview-centerPanel' onKeyDown={this.props.keyPressNavigation} tabIndex='-1' id={'focusablePackReviewTable'}>
					
					
					{submissions.map((submission)=>{
						return(

							<PackReviewRepoSubmission 
							setSelectedSubission={this.props.setSelectedSubission}
							selectedSubmission={this.props.selectedSubmission}
							key={submission.id} 
							submission={submission} 
							showAdminEditSubmissionStatusModal={this.props.showAdminEditSubmissionStatusModal}
							/>
							)


					})}					
				</div>

				<div className='packReview-detailPanel'>
					{this.props.selectedSubmission && 
					<PackReviewDetailPanel
						submission={this.props.selectedSubmission}
						assignAndMoveToReview={this.props.assignAndMoveToReview}
						adminLogEmailEvent={this.props.adminLogEmailEvent}
						emailAndMarkCanceled={this.props.emailAndMarkCanceled}
						emailAndMarkApproved={this.props.emailAndMarkApproved}
						showMarkCanceledConfirmModal={this.props.showMarkCanceledConfirmModal}
						showApproveSubmissionConfirmModal={this.props.showApproveSubmissionConfirmModal}
						activePackReviewAccount={activePackReviewAccount}
						allSubmissions={this.props.allSubmissions}
					/>
				}

				</div>

		</div>
				

		)
	}
}


export default withRouter(PackReviewPage)



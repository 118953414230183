import React from 'react'
import ArchivedCell from './cells/ArchivedCell'
import ArchivedFolderCell from './cells/ArchivedFolderCell'
import includes from 'lodash/includes'




class Trash extends React.Component{

	constructor(){
		super()		
		this.renderArchivedItem=this.renderArchivedItem.bind(this)
		this.renderArchivedFolder=this.renderArchivedFolder.bind(this)
	}



	renderArchivedFolder(key){
		const item=this.props.archivedFolders[key]
		const filterTerm=this.props.filterTerm
		let isActive=false
		if(this.props.activeItem){
			if (item.id === this.props.activeItem.id){
				isActive=true
			}
		}
		if (includes(item.name.toLowerCase(),filterTerm.toLowerCase())) {
			return (
				<ArchivedFolderCell 
					key={item.id}
					folder={item}
					isActive={isActive}				
					handleSingleClick={this.props.selectRow}
					navigateToFolder={this.props.navigateToFolder}
					restoreFolder={this.props.restoreFolder}

				/>
			)	
		} else return null
	}

	
	renderArchivedItem(key){
		const item=this.props.archivedItems[key]
		const filterTerm=this.props.filterTerm
		let isActive=false
		if(this.props.activeItem){
			if (item.id === this.props.activeItem.id){
				isActive=true
			}
		}




		if(item.choices){
			if (includes(item.body.toLowerCase(),filterTerm.toLowerCase())) {
				return  (
					<ArchivedCell
						key={`question_${item.id}`}
						isSet={false}
						item={item}
						isActive={isActive}
						deleteItem={this.props.deleteQuestion}
						handleSingleClick={this.props.selectRow}
						restoreItem={this.props.restoreItem}
					/> 
				)
			}
		}
		else if(item.name){
			if(includes(item.name.toLowerCase(),filterTerm.toLowerCase())){
				return (
					<ArchivedCell
						key={item.id}
						isSet={true}
						item={item}
						isActive={isActive}
						deleteItem={this.props.deleteSet}
						handleSingleClick={this.props.selectRow}
						restoreItem={this.props.restoreItem}
					/>
				)
			}
		}
	}


	render(){
		const {archivedItems,archivedFolders}=this.props
		return(
			<div className='library-content' >		
				<div className='library-table--container' onKeyPress={this.handleUp}>
					<div className='library-table' tabIndex='-1' onKeyDown={this.props.keyPressNavigation} >
						{Object.keys(archivedFolders).map(this.renderArchivedFolder)}										
						{Object.keys(archivedItems).map(this.renderArchivedItem)}	
					</div>
				</div>
			</div>
		)
	}
}



export default Trash



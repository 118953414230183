import React from 'react'
import Button from '../../../uiKit/Button'
import SectionHomeAddToClassCell from './SectionHomeAddToClassCell'


class SectionHomeAddToClassInline extends React.Component{

	constructor(){
		super()
		this.handleAddToQueue=this.handleAddToQueue.bind(this)
		this.state={
     	  showAddToClassCards:false
		}
	}



	handleAddToQueue(item){
		if(this.props.queueIsEmpty){
			this.setState({showAddToClassCards:true})
		}
		this.props.addToQueue(item)
	}

	render(){ 
	
		const {showAddToClassCards} = this.state
		const {recentLibrary}=this.props

		return(
			<div className={'sectionHome-addToClassInline ' + ((showAddToClassCards || this.props.queueIsEmpty) ? 'sectionHome-addToClassInline--showContents' : 'sectionHome-addToClassInline--hideContents') + (this.props.queueIsEmpty ? ' sectionHome-addToClassInline--emptyQueue' : '')}>		
				{!this.props.queueIsEmpty && 
					<Button size='large' label='Add to Queue' color={showAddToClassCards ? 'blue' : 'white'} onClickFunction={()=>{this.setState(showAddToClassCards ? {showAddToClassCards: false} : {showAddToClassCards: true})}} />								
				}								
					
				<div className='sectionHome-addToClassInline-table'>
					{recentLibrary.map((item, index) => {
						return(
							<SectionHomeAddToClassCell item={item} addToQueue={this.handleAddToQueue} key={index}/>
						)
					})}

					<div className='sectionHome-addToClassInline-searchLibrary' onClick={this.props.showAddToQueueFromLibraryModal}>
	 					Search...
	 				</div>

				</div>
				 		
 				
	 			

			</div>
		)
	}
}


export default SectionHomeAddToClassInline
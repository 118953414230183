import React from 'react'
import Button from '../../uiKit/Button'

class ArchivedStudentCell extends React.Component {
  render() {
    const {
      student, activeStudent, archiveStudent, deleteStudent, disableAddNewStudent,
    } = this.props
    let isActive = false
    if (student && activeStudent) {
      if (student.id === activeStudent) {
        isActive = true
      }
    }

    return (
      <div className={`studentCell is--archived${isActive ? ' is--active' : ''}`} onClick={() => { this.props.setActiveStudent(student) }}>
        <div className="studentCell-content">
          <div className="studentCell-left" />
          <div className="studentCell-firstName">
            {student.firstName}
          </div>
          <div className="studentCell-lastName">
            {student.lastName}
          </div>

          <Button disabled={disableAddNewStudent || this.props.sectionIsIntegrationRostered} size="small" label="Unarchive" onClickFunction={() => { archiveStudent(student) }} />
          <Button size="small" label="Delete" onClickFunction={() => { deleteStudent(student) }} />

        </div>
      </div>
    )
  }
}

export default ArchivedStudentCell

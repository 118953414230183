import React, { Component } from 'react'
import find from 'lodash/find'
import { NavLink } from 'react-router-dom'
import TopPageHeader from '../../components/pageHeaders/TopPageHeader'
import StickyPageHeader from '../../components/pageHeaders/StickyPageHeader'
import Dropdown from '../../components/uiKit/Dropdown'
import Button from '../../components/uiKit/Button'
import DropdownItem from '../../components/uiKit/DropdownItem'
import DropdownDivider from '../../components/uiKit/DropdownDivider'
import Icon from '../../components/misc/Icons'
import {Image} from 'cloudinary-react'
import ProfilePic from '../../components/ProfilePic'
import {newSet,newQuestion} from '../../utils/newItem'
import PageIcon from '../../components/misc/PageIcons'
import {getUserNameForId} from '../../utils/getUserNameForId'
import {formatRepoGradesToString} from '../../utils/formatRepoGradesToString'
import {formatSubjectToString} from '../../utils/formatSubjectToString'
import DiscoverRepoRatingStars from '../../components/discover/DiscoverRepoRatingStars'

const googleFormUrl='https://forms.gle/bb9mCqCx9fK6LeiG6'

class FolderNameInput extends Component {
	constructor(props){
		super(props)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.state=({ 
 			folderName:props.folder.name
 		})
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
 		if(nextProps.folder){
 			if(nextProps.folder.id !==this.props.folder.id){
 				this.setState({folderName:nextProps.folder.name})
 			}
 		}else{
 			this.setState({folderName:null})
 		}
 	}

 	handleKeyDown(e){
 		if (e.keyCode === 13) { 
 			e.preventDefault()
			this.props.editFolderName(this.props.folder,this.state.folderName)
			document.getElementById('pageHeaderFolderNameInput').blur()
		}
 	}


	render(){	
		const {readOnlyFolder,folder}=this.props
		const {folderName}=this.state

		return(		
			<input 
				disabled={readOnlyFolder || this.props.readOnlyPrivileges}
				maxLength={100}
				className='pageHeader-folderTitle'
				id='pageHeaderFolderNameInput'
				placeholder='Enter Folder Name'
				value={this.state.folderName || ''}
				onChange={(e)=>{this.setState({folderName:e.target.value})}}
				onBlur={()=>{this.props.editFolderName(folder,folderName)}} 
				onKeyDown={this.handleKeyDown}
			/>

		)}
}







class RepoPageHeaders extends Component {
 
	constructor(){
		super()
		this.renderPathItem=this.renderPathItem.bind(this)
		this.reportPack=this.reportPack.bind(this)
	}

	renderPathItem(pathItem,index){
		const folder=find(this.props.allFolders,{'id':pathItem})	
		if(folder){
			return(
				<div className='pageHeaderTop-folderNavRow-folderPathItem is--folder' onClick={() =>{this.props.navigateToFolder(pathItem)}} key={index}>
					<Icon name='chevron-right'/>
					<Icon name='folder'/>					       
					{folder.name}
				</div>
			)
		}else{
			return null
		}
	}

	reportPack(){

		if(window.analytics){
			window.analytics.track('Click Report For Violation',{
				repo:this.props.repo
			})
		}
		window.open(googleFormUrl,'_blank')

	}



	render() { 

		const {repo,parentFolder,readOnlyPrivileges,isPublicConsumer,isPublicAuthor,isViewer,isPending,repoDisabled,uploadingImage,parentFolderId,permissionsJoined}=this.props

		let countJoinedPermissions
		if(permissionsJoined){
			countJoinedPermissions = permissionsJoined.length
		}


		let subject=''
		if(repo.subject){
			subject=formatSubjectToString(repo.subject)
		}
		let gradeString=''
		if(repo.grade){
			gradeString=formatRepoGradesToString(repo.grade)
		}
		
		let repoMetadata = null

		if(repo.subject && gradeString){
			repoMetadata = `${subject} · ${gradeString}`
		}
		if(repo.subject && !gradeString){
			repoMetadata = `${subject}`
		}
		if(!repo.subject && gradeString){
			repoMetadata = `${gradeString}`
		}

		const ratingCount = repo.reviewCount
		let roundedRating
		if(repo.rating){
			roundedRating = Math.round(repo.rating * 10) / 10
		}
	
		let ratingCountLabel
		if(ratingCount === 1){
			ratingCountLabel = '1 review'
		}
		if(ratingCount > 1){
			ratingCountLabel = `${ratingCount} reviews`
		}


		let readOnly=readOnlyPrivileges			

		let folderPath=[]
		let folderNavRow

		if(parentFolder){
			readOnly=parentFolder.readOnly
			folderPath=parentFolder.ancestors.slice().reverse()

			folderNavRow=
				<React.Fragment>															
					<div className='pageHeaderTop-folderNavRow-folderPathItem is--library' onClick={()=>{this.props.navigateToFolder()}}>
						{repo.shared && <Icon name='shared'/>} {repo.published && repo.role === 'author' && <Icon name='globe-shared-author'/>} {repo.published && repo.role === 'consumer' && <Icon name='globe-shared'/>} {repo.name}
					</div>
					
					{folderPath.map((pathItem,index) => ( this.renderPathItem(pathItem,index)))}						

					<div className='pageHeaderTop-folderNavRow-folderPathItem is--folder is--currentFolder'>
			        <Icon name='chevron-right'/>
			        <Icon name={!readOnly ? 'folder' : 'folder-isbe' }/>					        
			          {parentFolder.name}				        
					  </div>
				</React.Fragment>
		}

		let RepoPageHeaderActions =
			<React.Fragment>
				{/* Default Action Row */}
				{!readOnlyPrivileges && 
					<React.Fragment>
						<Button iconName='compose' label={parentFolder ? 'New Set in Folder' : 'New Set'} onClickFunction={()=>{newSet(parentFolderId,repo.id)}} />
						{/*{!repo.shared && !repo.published && <button onClick={this.props.publishRepo}>Publish</button>} */}
						<div className='pageHeaderTop-actionRow-spacer' />
						<Button iconName='folder-outline' tooltipLabel='New Folder' tooltipPosition='label'  onClickFunction={this.props.handleNewFolder}/>
						<Dropdown>						
							<DropdownItem label={parentFolder ? 'New Question in Folder' : 'New Question'} onSelect={()=>{newQuestion(parentFolderId,repo.id)}} />
							<DropdownDivider />			
							<DropdownItem label='Edit Pack Details' onSelect={()=>{this.props.showEditRepoModal(repo)}}/>
							<DropdownItem label='View Trash' onSelect={this.props.navigateToTrash} />							
							{(repo.shared || (repo.published && isPublicAuthor)) &&
								<DropdownDivider />	
							}
							{repo.shared &&
								<DropdownItem warning label='Leave Pack' onSelect={this.props.confirmLeaveRepo} />
							}
							{/*TEMP*/}
							{repo.published && isPublicAuthor &&
								<DropdownItem warning label='Unpublish Pack' disabled={this.props.unpublishDisabled} onSelect={this.props.unpublishRepo} />
							}
							{!repo.shared && !repo.published && !parentFolder &&
								<React.Fragment>
									<DropdownDivider />	
									<DropdownItem warning label='Delete Pack' onSelect={this.props.confirmDeleteRepo} />
								</React.Fragment>
							}
							
						</Dropdown>	
					</React.Fragment>
				}

					{/* If not joined, then show inline member list */}
					{!parentFolder && permissionsJoined && isPending &&									
						<div className={'pageHeaderTop-actionRow--repoMemberList ' + (countJoinedPermissions > 4 ? 'pageHeaderTop-actionRow--repoMemberList--shortForm' : 'pageHeaderTop-actionRow--repoMemberList--longForm')}>
							{permissionsJoined.map((permission,i)=>{
			 					const userDisplayName=getUserNameForId(permission.user.id,repo.id)
				 								 				
			 					let repoMemberListShortForm = true // show short-form style with name tag if small number of members
			 					if(countJoinedPermissions < 2){
			 						repoMemberListShortForm = false
			 					}
			 					return(
			 						<React.Fragment key={i}>
				 						<div data-tooltip={userDisplayName} className={'pageHeaderTop-actionRow--repoMemberList-repoMember tooltip tooltip--label ' + (repoMemberListShortForm ? 'pageHeaderTop-actionRow--repoMemberList-repoMember--shortForm':'pageHeaderTop-actionRow--repoMemberList-repoMember--longForm')} >				 							
			 								<ProfilePic size='30' borderRadius='4' key={i} user={permission.user} />
			 								<div className='pageHeaderTop-actionRow--repoMemberList-repoMember-name'>{userDisplayName}</div>
				 						</div>				 					
			 						</React.Fragment>
			 						)
			 				})}
			 				
							{countJoinedPermissions > 10 && 
							<div className='pageHeaderTop-actionRow--repoMemberList-overflow'>							
			 					and {countJoinedPermissions - 10} more
			 				</div>
			 				}
						</div>
					}
						
				{/* If viewer, show dropdown to leave and read-only access message */}

				{isViewer && !isPending && !repoDisabled && !parentFolder &&
					<React.Fragment>						
						<div className='pageHeaderTop-actionRow-viewOnlyBanner'>
							View only 
						</div>
						<div className='pageHeaderTop-actionRow-spacer' />
						{repo.shared &&
							<Dropdown>														
								<DropdownItem warning label='Leave Pack' onSelect={this.props.confirmLeaveRepo} />
							</Dropdown>	
						}													
					</React.Fragment>
				}


				{isPublicConsumer && !parentFolder &&
					<React.Fragment>						
						<div className='pageHeaderTop-actionRow-spacer' />						
							<Dropdown>								
								<DropdownItem warning label='Report Pack' onSelect={this.reportPack} />
								{this.props.isFollowing &&
									<DropdownItem warning label='Unfollow' onSelect={this.props.unfollowRepo} />
								}
							</Dropdown>							
					</React.Fragment>
				}

		</React.Fragment>


		let RepoPageHeaderInfoRow =
			<React.Fragment>
				<div className='pageHeaderTop--pageHeaderTop-headerInfoRow-contents'>

					{repo.published && repo.author &&
						<NavLink
							onClick={(e)=>{e.stopPropagation()}}
							to={`/${repo.author.username}`}
							className='pageHeaderTop--repo-headerInfoRow-contents-authorContainer'>
							<div className='pageHeaderTop--repo-headerInfoRow-contents-author'>
								<div className='pageHeaderTop--repo-headerInfoRow-contents-author-profilePicContainer'>
									<ProfilePic size={26} borderRadius={13} user={repo.author} />
								</div>
								<div className='pageHeaderTop--repo-headerInfoRow-contents-author-username'>
									{/*{this.props.currentUser.username}*/}
									{isPublicConsumer &&
										<span>
											{/*{repo.author.firstName} {repo.author.lastName}*/}
											{repo.author.username}											
										</span>
									}
									{isPublicAuthor &&
										<span>
											{/* You ({repo.author.firstName} {repo.author.lastName}) */}
											You ({repo.author.username})
										</span>
									}
								</div>
							</div>

						</NavLink>
					}				
										

					{ratingCount > 0 && 
						<div className='pageHeaderTop--repo-headerInfoRow-contents-rating'>													
									<DiscoverRepoRatingStars rating={roundedRating} />
									{roundedRating} · {ratingCountLabel}
						</div>
					}

					{repoMetadata && 
						<div className={'pageHeaderTop--repo-headerInfoRow-contents-metadata ' + (ratingCount > 0 ? 'pageHeaderTop--repo-headerInfoRow-contents-metadata--hasRating' : 'pageHeaderTop--repo-headerInfoRow-contents-metadata--noRating')}>						
							{repoMetadata} 						
						</div>
					}

					

					

					<div className='pageHeaderTop--repo-headerInfoRow-contents-description'>
						{repo.description}
					</div>

				</div>
			</React.Fragment>




 	if(!parentFolder){
			return (
    			<React.Fragment>
					<TopPageHeader
						location='repo'
						leftBadge={
							<React.Fragment>								
								{repo.image &&
									<div className='pageHeaderTop-leftBadge-repoImageContainer' onClick={()=>{if(!readOnlyPrivileges){this.props.showEditRepoModal(repo)}}}>
										<Image
											key={repo.id}						
											cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
											publicId={`${repo.image}/repo/${repo.id}`} 
											className='pageHeaderTop-leftBadge-repoImage'											
											width='450'
											height='450'
											crop='fill'
											fetchFormat='auto'
			    						quality='auto'
										/>
									</div>
								}								
							</React.Fragment>
						}
						header={repo.name}
						headerRowClickFunction={()=>{
							if(!readOnlyPrivileges){
								this.props.showEditRepoModal(repo)
							}
						}}
						leftBadgeClickFunction={()=>{
							if(!readOnlyPrivileges){
								this.props.showEditRepoModal(repo)
							}
						}}						
						headerInfoRowClickFunction={()=>{
							if(!readOnlyPrivileges){
								this.props.showEditRepoModal(repo)
							}
						}}
						headerInfoRow={RepoPageHeaderInfoRow}
						actionRow={RepoPageHeaderActions}						
						repoWithImage={repo.image || uploadingImage}
						viewOnlyRepoPrivileges={readOnlyPrivileges}
						repoTextOnly={!repo.image && !uploadingImage}
						multiLine
						rightBadge={
							<React.Fragment>
								{repo.shared &&
									<Icon name='shared' />
								}
								{repo.published && repo.role === 'consumer' &&
									<Icon name='globe-shared' />
								}								
								{repo.published && repo.role === 'author' &&
									<Icon name='globe-shared-author' />
								}								

							</React.Fragment>
						}
						backButton
					/>

					<StickyPageHeader 
						location='repo' 
						leftBadge={
							<React.Fragment>							
								{repo.image &&						
									<div className='pageHeaderSticky-leftBadge-repoImageContainer'>												
										<Image	
											key={repo.id}					
											cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
											publicId={`${repo.image}/repo/${repo.id}`} 
											className='pageHeaderSticky-leftBadge-repoImage'
											width='450'
											height='450'
											crop='fill'
											fetchFormat='auto'
			    							quality='auto'
										/>											
									</div>
								}
							</React.Fragment>
						}
						header={repo.name}
						actionGroup={RepoPageHeaderActions}							
					/>	
				</React.Fragment>					
			)}
		else return(
			<React.Fragment>
				<TopPageHeader
					location='repoFolder' 								
					leftBadge={
						<React.Fragment>
							<Icon name='chevron-left' />
							<PageIcon name='libraryLarge' />
						</React.Fragment>}
					leftBadgeClickFunction={()=>{
						if(folderPath.length===0){
							this.props.navigateToFolder()	
						}else{
							this.props.navigateToFolder(folderPath[folderPath.length-1])
						} }}
					header={ 
						<FolderNameInput
							path={folderPath}
							readOnlyFolder={readOnly}
							allFolders={this.props.allFolders}
							navigateToFolder={this.props.navigateToFolder}
							editFolderName={this.props.editFolderName}
							folder={this.props.parentFolder}
							readOnlyPrivileges={readOnlyPrivileges}
						/>}						
					folderNavRow={folderNavRow}
					actionRow={RepoPageHeaderActions}
					height={readOnlyPrivileges ? '140': '175'}
				/>
					
					<StickyPageHeader 
						location='repoFolder' 
						leftBadge={
								<React.Fragment>
									<Icon name='chevron-left' />
									<PageIcon name='libraryLarge' />
								</React.Fragment>
							}
						leftBadgeClickFunction={()=>{
							if(folderPath.length===0){
								this.props.navigateToFolder()	
							}else{
								this.props.navigateToFolder(folderPath[folderPath.length-1])
								}  }}
						header={this.props.parentFolder.name}
						actionGroup={RepoPageHeaderActions}
					/>

							</React.Fragment>
						)}
}


 


export default RepoPageHeaders
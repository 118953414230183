import React from 'react'
import { Field, reduxForm } from 'redux-form'
import FormFieldText from '../../uiKit/FormFieldText'
import { getNewPasswordErrorMessage } from '../../../utils/passwordValidator'
/// ////// Validations//////////////

const passwordsMatch = (value, allValues) => (value !== allValues.password ? 'Passwords don\'t match' : undefined)
const requiredConfirmPassword = (value) => (value ? undefined : 'Please confirm your new password')

class ResetPasswordForm extends React.Component {
  render() {
    const { handleSubmit, errorMessage, submitLoading } = this.props

    return (
      <form
        className={`reset-password-form signup-form ${
          this.props.anyTouched ? ' signup-form--anyTouched ' : ''
        }${this.props.valid ? ' signup-form--valid ' : ' signup-form--invalid'}`}
        onSubmit={handleSubmit}
      >

        <Field
          name='password'
          type='password'
          component={FormFieldText}
          placeholder='New Password'
          validate={[getNewPasswordErrorMessage]}
          autoFocus
        />

        <Field
          name='passwordMatch'
          type='password'
          component={FormFieldText}
          placeholder='Confirm Password'
          validate={[requiredConfirmPassword, passwordsMatch]}
        />

        <div
          className='reset-password-form-note'
        >
          To protect your account, all devices will be signed out.
        </div>

        <button className='signup-form-submitBtn' type='submit'>

          {!submitLoading && (
          <React.Fragment>
            Confirm New Password
          </React.Fragment>
          )}

          {submitLoading && (
          <div className='signup-form-submitBtn-loadingSpinnerContainer'>
            <div className='signup-form-submitBtn-loadingSpinner' />
          </div>
          )}

        </button>

        {errorMessage && (
        <div className='signup-form-error'>
          {errorMessage}
        </div>
        )}

      </form>
    )
  }
}

/* eslint-disable no-class-assign */
ResetPasswordForm = reduxForm({
  form: 'resetPasswordForm',
})(ResetPasswordForm)
/* eslint-enable no-class-assign */

export default ResetPasswordForm

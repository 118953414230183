import React, { Component } from 'react'
import { connect } from 'react-redux'
import {updateFolder } from '../../actions/folders'
import {showNotification } from '../../actions/notifications'
import {createFolder} from '../../utils/createFolder'
import InputField from '../../components/uiKit/InputField'
import Button from '../../components/uiKit/Button'

//modal to create a new folder or rename existing folder
 
class EditFolderContainer extends Component {
	
	constructor(props){
		super(props)
		this.createFolder=this.createFolder.bind(this)
		this.updateFolder=this.updateFolder.bind(this)
		this.handleNameChange=this.handleNameChange.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		let folderName=''
		if(props.folder){
			folderName=props.folder.name
		}
		this.state={
			folderName:folderName,
			buttonDisabled:false,
			submitLoading:false
		}        
	}

	createFolder(){
		if(this.state.folderName!==''){
			this.setState({buttonDisabled:true,submitLoading:true})
			this.props.createFolder(this.props.parentFolder,this.state.folderName,this.props.repoId).then((response) => {
				if(this.props.newFolderSucess){
					this.props.newFolderSucess(response)
				}
				this.props.hideModal()
			})
		}
	}

	updateFolder(){
		const nowDate = new Date()
		this.setState({buttonDisabled:true,submitLoading:true})
		const oldName=this.props.folder.name
		const newName=this.state.folderName
		if(newName !== ''){
			if(oldName !== newName){
				this.setState({buttonDisabled:true})
				const requestData = {...this.props.folder,
					'name': newName,
					'clientModified': nowDate,
				}
				this.props.updateFolder(requestData, this.props.folder.id).then(() => {
					this.props.hideModal()
					const notificationMessage=`${oldName} to ${newName}`
					this.props.showNotification(notificationMessage,'Renamed','default')
				})
			}else{
				this.props.hideModal()
			}
		}
	}

	handleNameChange(e){
		this.setState({
			folderName:e.target.value,
		})
	}

	handleKeyDown(e){
		if (e.keyCode === 13) { 
			if(this.props.folder){
				this.updateFolder() 
			 }else{
				this.createFolder()
			 }
		}
	}

	render() {
		const {folder}=this.props
		let buttonDisabled=false
		if(this.state.buttonDisabled || !this.state.folderName ){
			buttonDisabled=true
		}
		if(this.props.folder&& this.state.folderName === this.props.folder.name ){
			buttonDisabled=true
		}
		const {submitLoading}=this.state
		return (
			<div className='library-editFolderModal'>          
				<InputField
					autoFocus={true} 
					onFocus={function(e) {
							var val = e.target.value
							e.target.value = ''
							e.target.value = val
					}}		      
					value={this.state.folderName}
					placeholder='Enter Folder Name'
					onChangeFunction={this.handleNameChange}
					onKeyDown={this.handleKeyDown}
				/>

				<Button 
					submitLoading={submitLoading}
					label={folder ? 'Save' : 'Create Folder'}
					size='large'
					inputType='submit'
					color={(buttonDisabled && !submitLoading) ? 'gray' : 'blue'}
					onClickFunction={folder ? this.updateFolder : this.createFolder}
				/>
			</div>
		)
	}
}

export default connect(
	(state) => ({}),
	{createFolder,updateFolder,showNotification }
)(EditFolderContainer)



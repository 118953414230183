import React from 'react'
import PropTypes from 'prop-types'

class ScorePill extends React.Component {
  render() {
    const {
      score, size, disabled, onClick, isSet, colorOnly, typeDot,
    } = this.props
    let { scorePillBG } = 'rgb(0,0,0)'
    let { scorePillSizeClass } = ''

    if (disabled) {
      scorePillBG = 'rgb(220,220,230)'
    } else if (score >= 85) {
      scorePillBG = 'rgb(0,207,132)'
    } else if (score >= 70) {
      scorePillBG = 'rgb(108,213,133)'
    } else if (score >= 60) {
      scorePillBG = 'rgb(242,142,92)'
    } else if (score >= 0) {
      scorePillBG = 'rgb(243,107,100)'
    }

    let isSurvey = false

    if (score === -1) {
      isSurvey = true
    }

    let isAbsent = false
    let isNA = false

    if (score === '-') {
      isAbsent = true
    } else if (score === 'N/A') {
      isNA = true
    }

    if (size === 'small') {
      scorePillSizeClass = 'scorePill--small'
    }
    if (size === 'medium') {
      scorePillSizeClass = 'scorePill--mid'
    }
    if (size === 'large') {
      scorePillSizeClass = 'scorePill--large'
    }

    return (
      <div
        className={`scorePill ${scorePillSizeClass}${score === 100 ? ' is--oneHundredPercent' : ''}${isAbsent ? ' is--absent ' : ''}${isNA ? ' is--na ' : ''}${isSurvey ? ' is--survey ' : ''}${typeDot ? ' type--dot ' : ''}${colorOnly ? ' type--colorOnly ' : ''}${disabled ? ' scorePill--isDisabled ' : ''}`}
        style={!typeDot ? { background: scorePillBG } : {}}
        onClick={onClick}
      >
        {typeDot &&
        <div style={isSurvey ? {} : { background: scorePillBG }} className="scorePill-dot" />}

        {!isSurvey && !isAbsent && !colorOnly && !isNA && (
        <span>
          {Math.round(score)}
          <span className="scorePill-percentSign">%</span>
        </span>
        )}
        {isSurvey && !isSet &&
        <span>Survey</span>}
        {isSurvey && isSet &&
        <span>Surveys</span>}
        {isAbsent &&
        <span>Abs.</span>}
        {isNA &&
        <span>N/A</span>}

      </div>
    )
  }
}

ScorePill.defaultProps = {
  size: 'medium',
}

ScorePill.propTypes = {
  size: PropTypes.string,
}

export default ScorePill

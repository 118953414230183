import React from 'react'
import EditorIcon from '../../../misc/EditorIcons'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import {formatVideoDuration} from '../../../../utils/timestampFormatters/formatVideoDuration'

class CanvasVideoControlBar extends React.Component{  

	render(){
		const {duration,isPlaying,playerCurrentTime,clipStartTime}=this.props
		const startTime=clipStartTime||0
		let currentTimestamp
		if(this.props.clipStartTime){	
			currentTimestamp = formatVideoDuration(Math.max(playerCurrentTime-clipStartTime,0))
		}else{
			currentTimestamp = formatVideoDuration(playerCurrentTime)
		}	
		const fullTimestamp=formatVideoDuration(duration)
	  return (
	  	<div className='slide-media--video-controlBar'>	  		
  			<div className='slide-media--video-controlBar-seekBar'>
  				<Slider 
  					onBeforeChange={this.props.onBeforeChange}
  					onAfterChange={this.props.onAfterChange}
  					onChange={(value)=>{this.props.seekTo(value+clipStartTime)}}
  					value={playerCurrentTime-startTime} 
  					min={0} 
  					step={0.01}
  					max={duration} 
  					defaultValue={0}   					 
  				/>  				
  			</div>
	  		
	  		<div className='slide-media--video-controlBar-buttonContainer'>
	  			{isPlaying &&
		  			<button onClick={this.props.pauseVideo} className='slide-media--video-controlBar-btn slide-media--video-controlBar-btn--playPause'>
		  				<EditorIcon name='playbackPause' /> 
		  			</button>
		  		}
	  			{!isPlaying &&
		  			<button onClick={this.props.playVideo} className='slide-media--video-controlBar-btn slide-media--video-controlBar-btn--playPause'>
		  				<EditorIcon name='playbackPlay' /> 
		  			</button>
	  			}
	  			<div className='slide-media--video-controlBar-timestamp'>
	  				<div className='slide-media--video-controlBar-timestamp-current'>
	  					{currentTimestamp}
	  				</div>
	  				<div className='slide-media--video-controlBar-timestamp-total'>
	  					{fullTimestamp}
	  				</div>
	  			</div>

	  			{/* GOES BACK TO 0 AND PLAYS */}
	  			<button onClick={this.props.restartVideo} className={'slide-media--video-controlBar-btn slide-media--video-controlBar-btn--restart'}>	  			 			
	  				<EditorIcon name='playbackRestart' />
	  			</button>

	  		{/* OPENS EDIT VIDEO MODAL */}
	  			<button onClick={this.props.showEditVideoModal} className='slide-media--video-controlBar-btn slide-media--video-controlBar-btn--editVideo'>
	  				<EditorIcon name='editMediaTrim' />
	  				Edit Video
	  			</button>

	  		</div>
	  	</div>
	  )
	}
}

export default CanvasVideoControlBar

import {Fragment} from "prosemirror-model"
import ReactDOM from 'react-dom'
import React from 'react'
import editorSchema from '../../schema/editorSchema'
import EquationEditorModal from '../../../components/richEditor/editorUI/modals/equationEditor/EquationEditorModal'
import {TextSelection} from "prosemirror-state"

function closeModal() {
	ReactDOM.render(null, document.getElementById('equationEditorModal'))
}
 
export function openEquationEditorModal(openedWithShortcut,isAdvancedEditor,openedFromToolbar){
	ReactDOM.render(
		<EquationEditorModal 
			isNewNode 
			mode={isAdvancedEditor?'advanced':'mathquill'}
			cancel={closeModal}
			openedWithShortcut={openedWithShortcut||false}
			insertMathNode={insertMathNodeWithLatex} 
			initialLatex={''}
			openedFromToolbar={openedFromToolbar}
			/>, 
		document.getElementById('equationEditorModal'))
}


function insertMathNodeWithLatex(latex,openedWithShortcut,isAdvancedInput){
	const state= window.view.state
	const dispatch=window.view.dispatch
	let {empty, $from, $to} = state.selection, content = Fragment.empty
	if (!empty && $from.sameParent($to) && $from.parent.inlineContent)
		content = $from.parent.content.cut($from.parentOffset, $to.parentOffset)
		if(dispatch){
			closeModal()
			let type = editorSchema.nodes.math
			const node=type.create({latex:latex,isAdvancedInput:isAdvancedInput})
			let tr = state.tr
			tr.replaceSelectionWith(node)
			const selection = new TextSelection(tr.doc.resolve($from.pos+node.nodeSize))
			tr.setSelection(selection) //position cursor after inserted stuff
			dispatch(tr)
			window.view.focus()
			if(window.analytics){
		    window.analytics.track('Insert Equation', {
		      latex:latex,
		      isAdvancedInput:isAdvancedInput
		   })
		  }
		}
	}

// export function insertEquation(openedWithShortcut){
// 	openModal(insertMathNodeWithLatex,openedWithShortcut)
// }

//Today				Today 	
//This Week			Tuesday 	
//July				Mon 10 July		
//May	 			Wed 15 May		
//December 2016	   	Thur 16 Dec 2016		

 

import moment from 'moment'


export function formatDateConsumerView(timestamp) {

	const nowDate =moment()
	const today = moment(nowDate).startOf('day')
	const week = moment(nowDate).startOf('isoWeek')
	const year = moment(nowDate).startOf('isoYear')
 
	let formatedTimestamp

	if (moment(timestamp).isSame(today, 'd')) {
		formatedTimestamp = 'Today'

	}


	else
	if (moment(timestamp).isSame(week, 'w')) {
		formatedTimestamp = moment(timestamp).format('dddd')
	}
	else
	if (moment(timestamp).isSame(year, 'y')) {
		formatedTimestamp=moment(timestamp).format('ddd DD MMM')
	}

	else {
		formatedTimestamp=moment(timestamp).format('DD MMM YYYY')
	}

	return formatedTimestamp

}
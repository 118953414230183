import React from 'react' 
import Button from '../uiKit/Button'
import ProfilePic from '../ProfilePic' 
import {getMemberSummary} from '../../utils/getMemberSummary'

 
class RepoInvitationBanner extends React.Component{
 
	constructor(){
		super()
		this.joinRepo=this.joinRepo.bind(this)
		this.rejectInvitation=this.rejectInvitation.bind(this)
		this.state={
			submitLoading:false
		}
	}

	joinRepo(){
		if(window.analytics){
			window.analytics.track('Invitation Banner Action', {
				  	action:'accept',
			})
		}
		this.setState({submitLoading:true})
		return this.props.joinRepo().then(()=>{
		})
		 .catch((error) => {
		 	this.setState({submitLoading:false})
		})
 
	}


	rejectInvitation(){
		if(window.analytics){
			window.analytics.track('Invitation Banner Action', {
				  	action:'reject',
			})
		}
		this.props.rejectInvitation()
	}


	showUpgradeModal(){
		if(window.analytics){
			window.analytics.track('Invitation Banner Action', {
				  	action:'click upgrade',
			})
		}
		window.location.hash='upgrade'

	}

 
 
	render(){
		const {submitLoading}=this.state
		const {repo,permissions,service,meta,repoDisabled}=this.props
		const {invitedBy}=repo
		let product //free or plus
		if(service){
			product=service.product		
		}

		let trialUsed=true
		if(meta){
			trialUsed=meta.trialUsed
		}
		
		//const repoDisabled = true // hardcoded, to be wired up
		let inviteeFirstName = ''
		let inviteeLastName = ''
		if(invitedBy){
			inviteeFirstName = invitedBy.firstName
			inviteeLastName = invitedBy.lastName
		}
		
		const repoName = repo.name
		//const memberSummary = 'Nicole, Sarah and 3 others'
		const memberSummary=getMemberSummary(permissions,repo)
	
		return(
			<div className='repo-invitationBannerContainer'>
				<div className='repo-invitationBanner'>
					{!repoDisabled &&
						<div className='repo-invitationBanner-inviteeProfilePicContainer'>
							<ProfilePic size='36' user={invitedBy} /> 
						</div>
					}

					<div className='repo-invitationBanner-center'>
						
						{!repoDisabled && 
							<div className='repo-invitationBanner-center-headline'>
								<b>{inviteeFirstName} {inviteeLastName}</b> invited you to <b>{repoName}</b>
							</div>
						}

						{repoDisabled && 
							<div className='repo-invitationBanner-center-headline' onClick={this.showUpgradeModal}>
								<b>Upgrade now</b> to restore access to <b>{repoName}</b>
							</div>
						}

						{product === 'plus' && !repoDisabled &&
							<div className='repo-invitationBanner-center-subline'>
								<div className='repo-invitationBanner-center-subline-label'>
									Join {memberSummary}
								</div>
								<div className='repo-invitationBanner-center-subline-rejectBtn' onClick={this.rejectInvitation}>
									Reject Invitation
								</div>
							</div>
						}


						{product === 'free' && !repoDisabled && !trialUsed &&
							<div className='repo-invitationBanner-center-subline'>
								<div className='repo-invitationBanner-center-subline-label'>
									Try Plickers Pro and join {memberSummary}
								</div>
								<div className='repo-invitationBanner-center-subline-rejectBtn' onClick={this.rejectInvitation}>
									Reject Invitation
								</div>
							</div>
						}

						{product === 'free' && !repoDisabled && trialUsed &&
						    <div className='repo-invitationBanner-center-subline'>
						    	<div className='repo-invitationBanner-center-subline-label'>
							    	Upgrade to Plickers Pro and join {memberSummary}
							   	</div>
					        <div className='repo-invitationBanner-center-subline-rejectBtn' onClick={this.rejectInvitation}>
						        Reject Invitation
					        </div>
						    </div>
						}  

						{repoDisabled &&
							<div className='repo-invitationBanner-center-subline'>
								<div className='repo-invitationBanner-center-subline-label'>
									Shared Packs are only available with Plickers Pro.
								</div>
								<div className='repo-invitationBanner-center-subline-rejectBtn' onClick={this.rejectInvitation}>
									Leave Pack
								</div>
							</div>
						}    
						
					</div>

					<div className='repo-invitationBanner-right'>
						{product === 'plus' && !repoDisabled &&
							<Button submitLoading={submitLoading} color='blue' size='large' label='Join Pack' onClickFunction={this.joinRepo} />
						}
						{product === 'free' && !trialUsed && !repoDisabled &&
							<Button color='blue' size='large' label='Start 14-day free trial' onClickFunction={this.showUpgradeModal}/>
						}
						{product === 'free' && trialUsed && !repoDisabled &&
							<Button color='blue' size='large' label='Upgrade to Pro' onClickFunction={this.showUpgradeModal}/>
						}
						{repoDisabled &&
							<Button color='blue' size='large' label='Restore Access' onClickFunction={this.showUpgradeModal}/>
						}						
					</div>

				</div>
			</div>

			
		)
	}

}

	 

export default RepoInvitationBanner

export default function (state=false, action) {
	switch (action.type) {
		
	case 'SHOW_LIVE_VIEW_NOTIFICATION':
		return true
	case 'HIDE_LIVE_VIEW_NOTIFICATION':
		return false
	default:
		return state
	}
}


import {Plugin} from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

//input like placeholder behavior
//e.g.empty questionBody has "Click here to edit question" placeholder when not selected
//this is done using decorations
//https://discuss.prosemirror.net/t/how-to-input-like-placeholder-behavior/705/8

const QUESTION_BODY_PLACEHOLDER="Click here to edit question"
const CHOICE_BODY_PLACEHOLDER="Click here to edit"

export function placeholderPlugin(){
	return new Plugin({
		props: {
			decorations: ({ doc, plugins, selection }) => {
				const decorations = []
				const { anchor } = selection
				let focused=false
				if(window.view){
					focused=window.view.hasFocus()
				}
				doc.descendants((node, pos) => {
					const hasAnchor = anchor >= pos && anchor <= (pos + node.nodeSize)
					if (node.type.name==='questionBody' && !node.textContent && (!hasAnchor||!focused)) {
						let hasMathNode=false
						node.descendants((node, pos, parent)=>{
							if(node.type.name==='math'){
								hasMathNode=true
							}
						})
					if(!hasMathNode){//properly empty=has no text and no math node 
						const placeholderDiv=document.createElement("div")
						placeholderDiv.classList.add("slide-body-placeholder")
						placeholderDiv.textContent=QUESTION_BODY_PLACEHOLDER
						decorations.push(Decoration.widget(pos+1, placeholderDiv))
					}
				}     
				else if (node.type.name==='choiceBody' && !node.textContent && (!hasAnchor||!focused)) {
					let hasMathNode=false
					node.descendants((node, pos, parent)=>{
					if(node.type.name==='math'){
						hasMathNode=true
					}
				})
				if(!hasMathNode){
					const placeholderDiv=document.createElement("span")
					placeholderDiv.classList.add("slide-choice-text-placeholder")
					placeholderDiv.textContent=CHOICE_BODY_PLACEHOLDER
					decorations.push(Decoration.widget(pos+1, placeholderDiv))
				}
			}
		})
		return DecorationSet.create(doc, decorations)
	}
	}
	})
}
import React from 'react'
import Button from '../../uiKit/Button'

class SectionHomeCompleteSetupPrompt extends React.Component {
  constructor(props) {
    super(props)
    this.getDisplayStrings = this.getDisplayStrings.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount() {
    if (window.analytics) {
      const { currentSection } = this.props
      window.analytics.page(
        'Class',
        {
          subpage: 'New Class Setup',
          sectionId: currentSection.id,
          title: currentSection.name,
        },
      )
    }
  }

  getDisplayStrings() {
    const { step } = this.props
    if (step === 'dataOwner') {
      return {
        titleBeforeSectionName: 'Does',
        titleAfterSectionName: 'belong to an organization?',
        actionTitle: 'Yes, assign to organization',
      }
    } if (step === 'students') {
      return {
        titleBeforeSectionName: '',
        titleAfterSectionName: 'is almost ready',
        subTitle: 'Finish setup by adding your students',
        actionTitle: 'Add Students',
      }
    }
    return {}
  }

  onClick() {
    const {
      step,
      showAssignSectionToDataOwnerModal,
      showBulkAddStudentsModal,
    } = this.props
    if (step === 'dataOwner') {
      showAssignSectionToDataOwnerModal()
    } else if (step === 'students') {
      showBulkAddStudentsModal()
    }
  }

  render() {
    const {
      currentSection,
      skipThisStep,
      step,
    } = this.props
    const displayStrings = this.getDisplayStrings()
    return (
      <div className={`sectionHome-completeSetupPrompt sectionHome-completeSetupPrompt--${step}`}>

        <div className='sectionHome-completeSetupPrompt-header'>
          {displayStrings.titleBeforeSectionName && (
            <span className='sectionHome-completeSetupPrompt-header-sectionName'>
              {displayStrings.titleBeforeSectionName}
              &nbsp;
            </span>
          )}
          <span className={`sectionHome-completeSetupPrompt-header-sectionName${currentSection.color ? ` sectionHome-completeSetupPrompt-header-sectionName--color-${currentSection.color}` : ''}`}>
            {currentSection.name}
            &nbsp;
          </span>
          <span className='sectionHome-completeSetupPrompt-header-sectionName'>
            {displayStrings.titleAfterSectionName}
          </span>
        </div>

        {displayStrings.subTitle && (
          <div className='sectionHome-completeSetupPrompt-subtitle'>
            {displayStrings.subTitle}
          </div>
        )}

        <Button
          size='large'
          label={displayStrings.actionTitle}
          onClickFunction={this.onClick}
        />

        <div
          className='sectionHome-completeSetupPrompt-skip'
          onClick={skipThisStep}
        >
          Skip this step
        </div>
      </div>
    )
  }
}

export default SectionHomeCompleteSetupPrompt

import React, { Component } from 'react'
import Button from '../../components/uiKit/Button'

class ExplorerEditRepoNotesModal extends Component {
  	
	render() {
		const {repoNotes,repo}=this.props
		return ( 
			<div className='explorerEditRepoNotesModal '>
				<Button 
					label='Cancel'					
					name='explorerreponotescancel'					
					onClickFunction={this.props.hideModal}
				/>				
				<div className='explorerEditRepoNotesModal-title'>
					Notes for {repo.name}
				</div>
				<div className='explorerEditRepoNotesModal-instructions'>
					Add a comment with <b>Shift+CMD+X</b>
				</div>
	
				<div className='explorerEditRepoNotesModal-input'>
					<textarea
						id='repoNotesTextArea'	      
						value={repoNotes}
						className={" is--empty"}			      
						placeholder='Add the first comment with Shift+CMD+X'
						onChange={this.props.handleNoteChange}
						autoFocus
					/>
				</div>
		
			
				<Button 
					label='Done'
					size='xLarge'
					name='explorerreponotessubmit'
					inputType='submit'
					disabled={this.props.submitLoading}
					submitLoading={this.props.submitLoading} 					
					color={'blue'}
					onClickFunction={this.props.saveNotes}
				/>

				<div className='explorerEditRepoNotesModal-fakeBtnSecondLine'>
					SHIFT + ENTER
				</div>
				

			</div>
		)
	}
}


export default ExplorerEditRepoNotesModal
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {updateUser} from '../../../actions/user'
import {showNotification} from '../../../actions/notifications'
import {updateBilling} from '../../../actions/billing'
import {Elements} from 'react-stripe-elements'
import UpdatePaymentInfoForm from './UpdatePaymentInfoForm'


class UpdatePaymentInfoContainer extends Component {
  
	constructor(props){
		super(props)
		this.updateBillingInformation=this.updateBillingInformation.bind(this)
		this.beginSubmit=this.beginSubmit.bind(this)

		this.state = {
			submitLoading:false,
			errorMessage:null
		
		}
		
	} 

	beginSubmit(){
		this.setState({submitLoading:true,errorMessage:null})
	}


	updateBillingInformation(source) {
		this.setState({submitLoading:true})
		this.props.updateBilling(source).then((response)=>{
			
			if(!response.message){		
			 	this.props.showNotification('','Updated Payment Method','default')
				this.props.hideModal()
		 	}
		})
			.catch(error => {

				let errorMessage='Please try again. If the problem persists, contact support.'
				if(error.response.status===402){
					errorMessage='Payment failed,  please check your details and try again'
				}
				this.setState({errorMessage:errorMessage,submitLoading:false,})
			})



	} 
 
	render() {
		const {user,billingInfo}=this.props
		let email=''
		let fullName=''
		if(this.props.user){
			email=user.email
			fullName=`${user.firstName} ${user.lastName}`
		}
		const {errorMessage,submitLoading}=this.state
		
		const currentCardBrand = billingInfo? billingInfo.brand:null
		const currentCardLast4 =billingInfo? billingInfo.last4:null



		return (
			<div className='updatePaymentInfoModal'>

				<div className='updatePaymentInfoModal-header'>
					Update Payment Method
				</div>

				<div className='updatePaymentInfoModal-subHeader updatePaymentInfoModal-subHeader--current'>
					Current Card
				</div>

				<div className='updatePaymentInfoModal-currentCard'>					
					{currentCardBrand} •••• •••• •••• {currentCardLast4}
				</div>

				<div className='updatePaymentInfoModal-subHeader updatePaymentInfoModal-subHeader--replace'>
					Replace with New Card
				</div>

				<Elements
			    fonts={[
			        {'cssSrc': 'StripeFontDefinitions.css'}
			    ]}>

   				<UpdatePaymentInfoForm 
   					email={email}
   					fullName={fullName}
   					beginSubmit={this.beginSubmit}
   					updateBillingInformation={this.updateBillingInformation}
   					hideModal={this.props.hideModal}
   					submitLoading={submitLoading}
   				/>

     		</Elements>

     		{errorMessage &&		
					<div className='accountForm-error'>
						{errorMessage}					
					</div>
				}

			</div>
		)
	}
}


export default connect(
	(state) => ({
		user:state.user,
		billingInfo:state.billing,

	}),
	{ updateUser,showNotification,updateBilling}
)(UpdatePaymentInfoContainer)



//Change active question to a survey question (no correct choice)
export function setToSurvey() {
 var tr = window.view.state.tr
 window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
 	if (node.attrs.activeslide && node.attrs.activeslide===true){
	  window.view.state.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
	  	if (questionNode.type.attrs.correct) {
	    	tr.setNodeMarkup(questionPos, null, {...questionNode.attrs,correct:false,isSurvey:true})
	    }
	   })
   } 
	})
  tr.setMeta("triggerParseDoc", true) 
	return  window.view.dispatch(tr)
}

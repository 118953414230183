export function getSuggestionsHTML(items) {
	return (
		'<div class="suggestion-item-list notranslate">'+
  			items.map(i => 
  				'<div class="'+`suggestion-item suggestion-item--${i.name}`+'">'+
  					'<div class="suggestion-item-preview">'+
  						(i.previewSymbol || i.string) +
  					'</div>' + 
  					'<div class="suggestion-item-label">'+
  						i.name+
  					'</div>'+
  					'<div class="suggestion-item-keyHint">Enter Key</div>'+
  				'</div>').join('')+
		'</div>')
}

import schema from '../../schema/editorSchema'  
import {calculateImageDeliveryValues} from '../image/calculateImageDeliveryValues'
import {getNewQuestionTemplateAddMedia} from '../getNewQuestionTemplate'
import {generateRandQuestionId} from '../../../utils/generateRandQuestionId'
import {uploadNewImageToCloudinary} from '../../../actions/cloudinary'
import {showNotification} from '../../../actions/notifications'
import {getActiveQuestionChoiceCount} from '../getActiveQuestionChoiceCount'
import {getActiveQuestionSlideWidth} from '../getActiveQuestionSlideWidth'
import {SetAttrsStep} from '../SetAttrsStep'
import store from '../../../store'

//TODO cleanup and maybe combine prompt/choice image uploads

const defaultRotate={
	angle:0,
	flipH:false,
	flipV:false
}
const defaultColorAdjust={
	brightness:0,
	saturation:0,
	contrast:0
}

function checkIfFileIsAnimated(cloudinaryResponse){ //animated GIF check
	let isAnimated=false
	if(cloudinaryResponse.pages && cloudinaryResponse.pages>1){
		isAnimated=true
	}
	return isAnimated
}

function sendInsertImageAnalytics(node,imageAttrs,isImageSearch,isCopyPaste,isSearchBarPaste){
	if(window.analytics){
		let isPromptMedia=false
		if(node.type.name === "questionImage"){
			isPromptMedia=true
		}
		let copyPasteType
		if(isCopyPaste){
			if(isSearchBarPaste){
				copyPasteType='searchBar'
			}else{
				copyPasteType='questionBody'
			}
		}
		window.analytics.track('Insert Image', {
			isPromptMedia:isPromptMedia,
			deliveryUrl:imageAttrs.deliveryUrl,
			isImageSearch:isImageSearch,
			isCopyPaste:isCopyPaste,
			copyPasteType:copyPasteType
	 })
	}
}

export function updateImageNodeWithSavedCloudinaryValues(nodeId){ //used for undo/redo stuff to avoid getting stuck in placeholder state
	const state = store.getState()
	if(state.imageUploads[nodeId]){  //check if have a saved cloudinary response
	 updateImageNodeWithCloudinaryValues(state.imageUploads[nodeId],nodeId)
	}
}

function updateImageNodeWithCloudinaryValues(cloudinaryResponse,nodeId,isImageSearch,isCopyPaste,isSearchBarPaste){  
	const fileId=cloudinaryResponse.public_id
	const intrinsicHeight=cloudinaryResponse.height
	const intrinsicWidth=cloudinaryResponse.width
	const isAnimated=checkIfFileIsAnimated(cloudinaryResponse)

	if(isAnimated){
		const cloudinaryBucketName=process.env.REACT_APP_CLOUDINARY_CLOUDNAME
		const fullSizeStaticImageUrl=`https://res.cloudinary.com/${cloudinaryBucketName}/image/upload/pg_1/${fileId}.png`
		return store.dispatch(beginImageUpload(fullSizeStaticImageUrl)).then((response)=>{
				//Show notification that has been converted to static image
				store.dispatch(showNotification("If you'd like to insert it as an animated GIF instead, go to Insert GIF.","GIF inserted as an image.",'default'))
				updateImageNodeWithCloudinaryValues(response,nodeId)
		}) 
	}
	else{
		store.dispatch({ type: 'CLOUDINARY_UPLOAD_SUCCESS', nodeId,cloudinaryResponse })
		const originalDimensions={
			w:intrinsicWidth,
			h:intrinsicHeight
		}

		const imageDeliveryValues=calculateImageDeliveryValues(fileId,originalDimensions,null,defaultRotate,defaultColorAdjust,[],[])
		const deliveryUrl=imageDeliveryValues.deliveryUrl
		 
		let tr = window.view.state.tr
		let imageNode
		let imageNodePos
		window.view.state.doc.nodesBetween(0,window.view.state.doc.content.size, (node, pos) => {
				if(node.attrs.nodeId && node.attrs.nodeId===nodeId){
				 imageNodePos=pos
				 imageNode=node
				}
		})
		if(imageNode){
			let imageAttrs={...imageNode.attrs}
			imageAttrs.originalDimensions=originalDimensions
			imageAttrs.deliveryDimensions={
				w:imageDeliveryValues.w,
				h:imageDeliveryValues.h
			}
			imageAttrs.fileId=fileId
			imageAttrs.deliveryUrl=deliveryUrl
			sendInsertImageAnalytics(imageNode,imageAttrs,isImageSearch,isCopyPaste,isSearchBarPaste)
			tr.step(new SetAttrsStep(imageNodePos,imageAttrs))
			tr.setMeta('addToHistory', false)  
			tr.setMeta("triggerParseDoc", true) 
			window.view.dispatch(tr)
		}
	}
}

function beginImageUpload(src,thumbnail){
	return (dispatch) =>{
		return dispatch(uploadNewImageToCloudinary(src,thumbnail)).then((response)=>{
			return response
		})
		 .catch((error) => {
				if(error.response && error.response.status===400){
					store.dispatch(showNotification(" we're unable to insert the image. Please try a different image.","Image upload failed:",'destroy')) 
				}
				throw(error)
			})
	}
}

function addQuestionImage(src,thumbnail,title,attribution,insertPos,questionPos,questionNode,file,isImageSearch,isCopyPaste,isSearchBarPaste) {
	return (dispatch) =>{
		let tr = window.view.state.tr
		const newTemplate=getNewQuestionTemplateAddMedia(questionNode.attrs.template)
		tr.setNodeMarkup(questionPos, null, {...questionNode.attrs,template:newTemplate})		
		window.view.state.doc.nodesBetween(questionPos,questionPos+questionNode.nodeSize, (node, pos) => {
			if (node.type.name === "choiceImage"||node.type.name === "choiceVideo"||node.type.name === "choiceSound"||node.type.name === "choice"){
				tr.setNodeMarkup(pos, null, {...node.attrs,template:newTemplate})          
			}
		})
		const nodeId=generateRandQuestionId()
		let placeholderImage=''
		let placeholderDimensions={
			w:'1',
			h:'1'
		}
		if(thumbnail && thumbnail.src){
			placeholderImage=thumbnail.src
			placeholderDimensions={
				w:thumbnail.w,
				h:thumbnail.h
			}
		}

		const type = schema.nodes.questionImage  
		const nowDate = new Date()

		let imageAttrs={
			nodeId:nodeId,
			addedAt:nowDate,
			placeholderImage:placeholderImage,
			fileId:'',
			title:title,
			attribution:attribution,
			deliveryUrl:'',
			template:newTemplate,
			originalDimensions:{
				w:'',
				h:''
			},
			deliveryDimensions:{
				w:'',
				h:''
			},
			placeholderDimensions:placeholderDimensions
		}

		imageAttrs.questionMediaDynamicHeight=questionNode.attrs.questionMediaDynamicHeight
		imageAttrs.slideWidth=getActiveQuestionSlideWidth()

		if(file){
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = (event) => {
				imageAttrs.placeholderImage=event.target.result
				const img = new Image()
				img.src = event.target.result
				img.onload = function() {
					const placeholderDimensions={
						w:img.naturalWidth,
						h:img.naturalHeight
					}
				imageAttrs.placeholderDimensions=placeholderDimensions
				const imageNode=type.create(imageAttrs)
				tr.replaceWith(insertPos.start,insertPos.end,imageNode)
				tr.setMeta("triggerParseDoc", true) 
				window.view.dispatch(tr)
				return dispatch(beginImageUpload(event.target.result)).then((response)=>{
					updateImageNodeWithCloudinaryValues(response,nodeId,isImageSearch,isCopyPaste,isSearchBarPaste)
				})
				.catch((error)=>{})
			}
		}
	}else{
		const imageNode=type.create(imageAttrs)
		tr.replaceWith(insertPos.start,insertPos.end,imageNode)
		tr.setMeta("triggerParseDoc", true) 
		window.view.dispatch(tr)
		return dispatch(beginImageUpload(src,thumbnail)).then((response)=>{
			updateImageNodeWithCloudinaryValues(response,nodeId,isImageSearch,isCopyPaste,isSearchBarPaste)
		})
		.catch((error)=>{})
	}
	}
}

function addChoiceImage(src,thumbnail,title,attribution,insertPos,file,isImageSearch,isCopyPaste,isSearchBarPaste){
 return (dispatch) =>{
	let tr = window.view.state.tr
	const type = schema.nodes.choiceImage 
	const nodeId=generateRandQuestionId()
	let placeholderImage=''
	let  placeholderDimensions={
		w:'1',
		h:'1'
	}
	if(thumbnail && thumbnail.src){
		placeholderImage=thumbnail.src
		placeholderDimensions={
			w:thumbnail.w,
			h:thumbnail.h
		}
	}
	const nowDate = new Date()

	let imageFill=true
	const savedDefaultChoiceImageFill = JSON.parse(localStorage.getItem('defaultChoiceImageFill'))
	if(savedDefaultChoiceImageFill || savedDefaultChoiceImageFill===false){
		imageFill=savedDefaultChoiceImageFill
	}

	let imageAttrs={
		nodeId:nodeId,
		addedAt:nowDate,
		placeholderImage:placeholderImage,
		questionChoiceCount:getActiveQuestionChoiceCount(),
		slideWidth:getActiveQuestionSlideWidth(),
		fileId:'',
		title:title,
		attribution:attribution,
		deliveryUrl:'',
		placeholderDimensions:placeholderDimensions,
		imageFill:imageFill
	}

	if(file){
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = (event) => {
			imageAttrs.placeholderImage=event.target.result
			const img = new Image()
			img.src = event.target.result
			img.onload = function() {
				const imageNode=type.create(imageAttrs)
				tr.replaceWith(insertPos.start,insertPos.end,imageNode)
				tr.setMeta("triggerParseDoc", true) 
				window.view.dispatch(tr)
				return dispatch(beginImageUpload(event.target.result)).then((response)=>{
				//const isUpload=true
				updateImageNodeWithCloudinaryValues(response,nodeId,isImageSearch,isCopyPaste,isSearchBarPaste)
				})
				.catch((error)=>{})
			}
		}
	}
	else{
		const imageNode=type.create(imageAttrs)
		tr.replaceWith(insertPos.start,insertPos.end,imageNode)
		tr.setMeta("triggerParseDoc", true) 
		window.view.dispatch(tr)
		return dispatch(beginImageUpload(src,thumbnail)).then((response)=>{
			updateImageNodeWithCloudinaryValues(response,nodeId,isImageSearch,isCopyPaste,isSearchBarPaste)
		})
		.catch((error)=>{})
	}
	}
}

export {addQuestionImage,addChoiceImage}
 

import React from 'react'
import EditorIcon from '../../../misc/EditorIcons'
import Slider, { SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import slideLayoutDefinitions from '../../../../staticData/slideLayoutDefinitions.js'
import {formatMillisecondDuration} from '../../../../utils/formatMillisecondDuration'

const { Handle } = Slider

const handle = props => {
	const { value, dragging, index, ...restProps } = props;
	return (
		<SliderTooltip
			prefixCls="slide-media--sound-player-seekBar-tooltip"
			overlay={`${value}`}
			visible={false}
			placement="top"
			key={index}
		>
			<Handle value={value} {...restProps} />
		</SliderTooltip>
	)
}

class ChoiceSoundReactComponent extends React.Component{  

	constructor(props) {
		super(props)
		this.playSound=this.playSound.bind(this)
		this.pauseSound=this.pauseSound.bind(this)
		this.startTimer=this.startTimer.bind(this)
		this.seekTo=this.seekTo.bind(this)
		this.stopTimer=this.stopTimer.bind(this)   
		this.onAudioEnd=this.onAudioEnd.bind(this)
		this.audioElement = new Audio(`${props.deliveryUrl}.mp3`)
		this.audioElement.addEventListener('ended',this.onAudioEnd)
		this.timer=null
		let duration=props.end-props.start
		this.state = {
			isPlaying:false,
			duration:duration,
			playerCurrentTime:0,
		}  
	}
  
	UNSAFE_componentWillReceiveProps(nextProps) {
		if(this.props.isPlaying && !nextProps.isPlaying){
			this.pauseSound()
		}
	}

	componentWillUnmount() {
		this.pauseSound()
		this.stopTimer()
		this.audioElement.removeEventListener('ended',this.onAudioEnd)
	}

	onAudioEnd(){
		this.audioElement.currentTime = 0
		this.setState({isPlaying:false,playerCurrentTime:0})
		this.stopTimer()
	}

	updatePlayerCurrentTime(){
		const currentTime=this.audioElement.currentTime
		this.setState({playerCurrentTime:currentTime})
	}

	startTimer(){
		this.timer = setInterval(() => {
			this.updatePlayerCurrentTime()
		}, 10)
	}
   
	stopTimer(){
		if(this.timer){
			clearInterval(this.timer)
			this.timer=null
		}
	}

	playSound(){
		this.props.playSound()
		this.setState({isPlaying:true})
		this.startTimer()
		this.audioElement.play()
	}

	pauseSound(){
		this.setState({isPlaying:false})
		this.audioElement.pause()
		this.stopTimer()
	}

	seekTo(seconds){
		this.audioElement.currentTime=seconds
		if(!this.timer){
			this.setState({playerCurrentTime:seconds})
		}
	}

	render(){ 
		const {slideWidth}=this.props
		const {isPlaying} = this.state
		const slideScale = slideWidth / 1280
		const inverseScale = 1 / slideScale
		const numberOfChoices = this.props.questionChoiceCount
		const mediaWidth = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${numberOfChoices}Choices`].width
		// const mediaHeight = slideLayoutDefinitions.choiceMediaChoiceDimensions[`${numberOfChoices}Choices`].height
		const mediaInternalSidePadding = 5
		const soundPlayerWidth = mediaWidth - mediaInternalSidePadding
		const progress = this.state.playerCurrentTime
		const lengthSeconds = this.state.duration
		const ceilingInverseScale = 1.55 // prevent and other elements icons getting too big
		const UIInverseScale = Math.min(inverseScale, ceilingInverseScale)
		const soundClipTitle = this.props.title
		const scaledSoundPlayerWidth = soundPlayerWidth / UIInverseScale
		const showSlider = isPlaying || progress > 0
		let displayMillisecondGranularity = true
		let displayHourGranularity=false
		if((this.state.duration) >1){ // if over 1 second 
			displayMillisecondGranularity = false // don't show millisecond granularity
			if(this.state.duration>60*60){
				displayHourGranularity=true
			}
		}
		// matching to video choices
		let heightBottomTimestampContainer = 83
		if(numberOfChoices === 2){
			heightBottomTimestampContainer = 81 
		}
	  return (
		<div className={'slide-choice-media slide-choice-media--sound slide-choice-media--sound--editor ' + (progress > 0 ? ' slide-choice-media--sound--started' : ' slide-choice-media--sound--initial')}>

			{/* OPEN IN EDITOR MODAL BTN */}
			<div style={{transform:`scale(${UIInverseScale})`}} className='slide-choice-media--sound-topLeft'>            
				<button onClick={()=>{this.props.showEditSoundModal(false)}} className='slide-choice-media-smallBtn slide-choice-media--sound-edit'>
					Edit
				</button>                        
			</div>

			{/* CLOSE BTN */}
			<div style={{transform:`scale(${UIInverseScale})`}} className='slide-choice-media--sound-topRight'>            
			{/* I'm not sure adds weight...
			<button className='slide-choice-media-smallBtn slide-choice-media--sound-replace'>
			<EditorIcon name='replaceMedia' />
			</button>
			*/}
				<button onClick={this.props.deleteSound} className='slide-choice-media-smallBtn slide-choice-media--sound-close'>
					<EditorIcon name='close' />
				</button>
			</div>

			{/* PLAY/PAUSE BTN */}
			<div style={{transform:`scale(${UIInverseScale})`}} className='slide-choice-media--sound-center'>            
				{isPlaying &&
					<button onClick={this.pauseSound} className='slide-choice-media--sound-playPauseBtn slide-choice-media--sound-playPauseBtn--playing'>
						<EditorIcon name='playbackPause' /> 
					</button>
				}
				{!isPlaying &&
					<button onClick={this.playSound} className='slide-choice-media--sound-playPauseBtn slide-choice-media--sound-playPauseBtn--notPlaying'>
						<EditorIcon name='playbackPlay' />
						<EditorIcon name='insertSound' /> 
					</button>
				}            
			</div>

			<div style={{width: `${Math.floor(scaledSoundPlayerWidth)}px`, height:`${heightBottomTimestampContainer / UIInverseScale}px`, transform:`scale(${UIInverseScale})`}} className='slide-choice-media--sound-bottom'>
				<div className='slide-choice-media--sound-bottom-timestampContainer'>
					{!showSlider &&
						<div style={{width: `${scaledSoundPlayerWidth - 12}px`}} className='slide-choice-media--sound-initialTitleAndTimestamp'>
							<div onClick={()=>{this.props.showEditSoundModal(true)}} className='slide-choice-media--sound-initialTitleAndTimestamp-title'>
								{soundClipTitle}
							</div>
						<div className='slide-choice-media--sound-initialTitleAndTimestamp-timestamp'>                    
							{formatMillisecondDuration(this.state.duration*1000,displayMillisecondGranularity,displayHourGranularity)}          
						</div>
					</div>
				}
			</div>

			{showSlider &&
			<div className={'slide-choice-media--sound-bottom-seekBarContainer ' + (numberOfChoices ? `slide-choice-media--sound-bottom-seekBarContainer--choiceCount--${numberOfChoices}`:'')}>
				<Slider 
					onChange={(value)=>{this.seekTo(value)}}
					value={this.state.playerCurrentTime} 
					step={0.01}
					min={0} 
					max={lengthSeconds} 
					defaultValue={0} 
					handle={handle}
				/>              
			</div>
			}
          
		</div>
	</div>
	)
	}
}

export default ChoiceSoundReactComponent
 
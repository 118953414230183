import React, { Component } from 'react'
import moment from 'moment'
 
// Notifications have 
// actionWord e.g. Queued,
// message e.g. WW2 set for 9th Grade History -->(we display actionWord+message = Queued WW2 set for 9th Grade History )
// actionType e.g. default, delete used for styling
// optional button- currently only used in set editor notifications to allow users to disable input rules from notification

const NOTIFICATION_TIMEOUT= 5500 //ms
const NOTIFICATION_WITH_BUTTON_TIMEOUT= 10000 //ms longer timeout if there is a button in notification
//const NOTIFICATION_TIMEOUT= 10000 //ms TEMP increase notification time for user testing

class Notification extends Component {
	constructor(){
    super()
    this.setTimer=this.setTimer.bind(this)
  }
 
	componentDidMount() {
		this.setTimer()
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (moment(nextProps.timestamp) !== moment(this.props.timestamp)) {//reset the timer
			this.setTimer()
		}
	}

	setTimer() {
		if(this._timer !== null){// clear any existing timer
			clearTimeout(this._timer)
		}
		let delay=NOTIFICATION_TIMEOUT
		if(this.props.button){
			delay=NOTIFICATION_WITH_BUTTON_TIMEOUT
		}	
		this._timer = setTimeout(function(){// hide after `delay` milliseconds
			this.props.hideNotification()
			this._timer = null
		}.bind(this), delay)
	}


	render() {
		const {message,actionWord,actionType,button}=this.props
		return (
			<div className={'notification' + (actionType ? ` notification--actionType--${actionType}` : ' notification--actionType--default') + (button ? ' notification--hasButton' : '')}>
				<div className='notification-message'>
					{actionWord && <span className='notification-actionWord'>{actionWord} </span> }				
					{message}					
				</div>
				{button &&
					<div className='notification-buttonContainer'>
						<button className='notification-button' onClick={button.buttonAction}>
							{button.buttonLabel}
						</button>
					</div>
					}
			</div>
		)
	}
}

export default Notification

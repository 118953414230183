const initialState = {
  isAuthenticated: false,
  willAuthenticate: true,
  sessionInfo: [],

};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        sessionInfo: action.response,

      };
    case 'AUTHENTICATION_REQUEST':
      return {
        ...state,
        willAuthenticate: true,

      };
    case 'AUTHENTICATION_SUCCESS':
      return {
        ...state,
        willAuthenticate: false,
        isAuthenticated: true,
        sessionInfo: action.response,
      };
    case 'AUTHENTICATION_FAILURE':
      return {
        ...state,
        willAuthenticate: false,

      };
    case 'LOGOUT':
      return initialState

    default:
      return state;
  }
}

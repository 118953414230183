import _ from 'lodash'

//TODO combine guests/guests sorting 
export function sortGuestsList(guests,guestsWithTotals,sortedBy,sortOrder) {
	let sortedGuests=[]
	if(sortedBy==='displayName' || sortedBy==='firstName' || sortedBy==='lastName'){
		sortedGuests=_.sortBy(guests, function (guest) {
    		return parseInt(guest.slice(6), 10)
		})

		if(sortOrder !=='ascending'){
			sortedGuests.reverse()
		}
	}
	else if(sortedBy==='score'){
		sortedGuests=_.sortBy(guests, [function(guest) { 
			const guestTotals=guestsWithTotals[guest]
			let guestScore=0

			if(guestTotals.totalResponses!==0){
				guestScore=guestTotals.totalCorrect/guestTotals.totalResponses
			}else if(sortOrder ==='ascending'){
				guestScore=-1 //to ensure absent guests are always at the bottom of sorted list
			
			}else if(sortOrder ==='descending'){
				guestScore=101
			
			}
			return guestScore
		}])


		if(sortOrder ==='ascending'){
			sortedGuests.reverse()
		}
	}

	else if(sortedBy==='card'){
		sortedGuests=_.sortBy(guests, [function(guest) { 
			let cardNumber=parseInt(guest.slice(6), 10)
			if(cardNumber ===-1 && sortOrder==='ascending'){ //so archived guests always appear after active guests
				cardNumber=100
			}
			return cardNumber
		}])


		if(sortOrder !=='ascending'){
			sortedGuests.reverse()
		}
	}


	return sortedGuests
}

const initialState = {
	user: null,
}

export default function (state=initialState, action) {
	switch (action.type) {	
		case 'FETCH_USER_SUCCESS':
			return action.response

		case 'UPDATE_USER_SUCCESS':
			return action.response

		case 'LOGOUT':
			return initialState
			
		default:
			return state
	}
}



import React from 'react'
import {Motion} from 'react-motion'
import sortBy from 'lodash/sortBy'
import includes from 'lodash/includes'


class LiveViewMainStudentList extends React.Component{

	handleSortStudents(sortType,students){
		let sortedStudents
		if(sortType !=='card'){
			sortedStudents=sortBy(students,[student => student.displayName.toLowerCase()])
		}else{
			sortedStudents=sortBy(students,sortType)
		}
		return sortedStudents
	}

	render(){		
		const {onlineStudents,nowPlayingMode,layout,students,studentSortOrder,showCorrect,showStudentCardNumbers,colorScheme,isSurvey,showIndividualStudentResponses,manualShowIndividualStudentResponses,correctLetters,poll,studentLetterBlockSideDimension} = this.props 
		let showIndividualStudentResponsesInStudentList = false
		if(showIndividualStudentResponses === 'always'){
			showIndividualStudentResponsesInStudentList = true
		}else if (showIndividualStudentResponses === 'manual' && manualShowIndividualStudentResponses){
			showIndividualStudentResponsesInStudentList = true
		}

		// const reviewControlsPadding = 24 //resolution independent
		let guests=[]
		if(poll && poll.responsesByCard){
			Object.keys(poll.responsesByCard).map((key)=>{
				const response=poll.responsesByCard[key]
				if(!response.student){
					const guest={...response,card:key}
					guests.push(guest)
				}
				return null
			})
		}
		const sortedStudents=this.handleSortStudents(studentSortOrder,students)
		const showHybridStudentOnlineStatus = nowPlayingMode==='liveViewHybrid'
		return(			
			<Motion style={this.props.slideScanStudentListStyle}>
				{({xTranslate, opacity}) =>
					<div className={'liveView-studentList ' + (showCorrect ? ' liveView-studentList--isShowCorrect ' : 'liveView-studentList--isNotShowCorrect ') + (showIndividualStudentResponsesInStudentList ? ' liveView-studentList--isShowIndividualStudentResponsesInStudentList ' : ' liveView-studentList--isNotShowIndividualStudentResponsesInStudentList ')}
						style={{
							width: `${layout.studentListWidth}px`, 
							bottom: `${layout.internalSafePadding - layout.studentListItemPillMargin}px`,
							top: `${layout.yPosSlide}px`,
							transform: `translateX(${xTranslate}px)`,
							opacity: `${opacity}`,
							right: `${layout.internalSafePadding - layout.studentListItemPillMargin}px`,
							paddingLeft: `${layout.studentListLeftMargin}px`,
							paddingTop: `${layout.reviewControlHeight + layout.studentListTopMargin}px`}}
					>
				
					{sortedStudents.map((student,index) => {
						let hasResponse=false
						let isCorrect=false
						let answer=''								
						if(poll && poll.responsesByCard){
							const studentResponse=poll.responsesByCard[student.card]
							if(studentResponse){
								hasResponse=true
								answer=studentResponse.answer
								isCorrect=includes(correctLetters,answer)
							}							 	 		
						}
						const studentIsOnline = (onlineStudents && onlineStudents[student.id])?true:false
						return(	
							<div className='liveView-studentList-studentItem notranslate' key={`${index}_${hasResponse}`} 
							style={{			        					
								fontSize: `${layout.studentListFontSize}px`,
								width:`${layout.studentListItemWidth}px`,
								height:`${layout.studentListItemHeight}px`,
							}}
							>	        					
								<div className={'liveView-studentList-studentItem-pill' +
									(hasResponse ? ' liveView-studentList-studentItem-pill--hasResponse' : ' liveView-studentList-studentItem-pill--noResponse') + 
									(isCorrect && hasResponse && !isSurvey ? ' liveView-studentList-studentItem-pill--isCorrect' : '') +
									(!isCorrect && hasResponse && !isSurvey ? ' liveView-studentList-studentItem-pill--isIncorrect' : '') +
									(isSurvey && hasResponse ? ' liveView-studentList-studentItem-pill--isSurvey' : ' ')
									}
									style={{
										top: `${layout.studentListItemPillMargin}px`,
										right: `${layout.studentListItemPillMargin}px`,
										left: `${layout.studentListItemPillMargin}px`,
										bottom: `${layout.studentListItemPillMargin}px`,
										borderRadius: `${layout.studentListPillBorderRadius}px`
									}}
								>
												
									<span className={student.displayName.length > 13 ? ' liveView-studentList-studentItem-pill-name--long' : ''}>	        					  			        					  	
										{student.displayName}
									</span>

									{showHybridStudentOnlineStatus && studentIsOnline &&
										<div className='liveView-studentList-studentItem-pill-hybridOnlineStatus'>
											<div className='liveView-studentList-studentItem-pill-hybridOnlineStatus-marker'/>
											<div className='liveView-studentList-studentItem-pill-hybridOnlineStatus-marker'/>
											<div className='liveView-studentList-studentItem-pill-hybridOnlineStatus-marker'/>
										</div>
									}

									{showIndividualStudentResponsesInStudentList && hasResponse &&
										<div className={'liveView-studentList-studentItem-pill-name-letterBlock' + (colorScheme ? ` is--${colorScheme} `: '')}
											style={{
												height:`${studentLetterBlockSideDimension}px`,
												width:`${studentLetterBlockSideDimension}px`,	        					  				
											}}
										>
											{answer}
										</div>
									}

									{showStudentCardNumbers && (!showIndividualStudentResponsesInStudentList || (showIndividualStudentResponsesInStudentList && !hasResponse)) &&
										<div className='liveView-studentList-studentItem-pill-name-cardNumber'
											style={{
												height:`${studentLetterBlockSideDimension}px`,
												width:`${studentLetterBlockSideDimension}px`,	        					  					        					  				
											}}
										>
											{student.card}
										</div>
									}
								</div>
							</div>
						)
					})}				
					{guests.map((guest,index) => {
						let isCorrect=false
						let answer=''
						if(poll && poll.responsesByCard){
							answer=guest.answer
							isCorrect=includes(correctLetters,answer)
						}
						return(	
							<div className='liveView-studentList-studentItem' key={index}
								style={{
									fontSize: `${layout.studentListFontSize}px`,
									width:`${layout.studentListItemWidth}px`,
									height:`${layout.studentListItemHeight}px`,	        						
								}}
							>	        					
								<div className={'liveView-studentList-studentItem-pill liveView-studentList-studentItem-pill--hasResponse' +		        						  
									 (isCorrect && !isSurvey ? ' liveView-studentList-studentItem-pill--isCorrect' : '') +
									 (!isCorrect && !isSurvey ? ' liveView-studentList-studentItem-pill--isIncorrect' : '') +
									 (isSurvey ? ' liveView-studentList-studentItem-pill--isSurvey' : ' ')
									}
									style={{
										top: `${layout.studentListItemPillMargin}px`,
										right: `${layout.studentListItemPillMargin}px`,
										left: `${layout.studentListItemPillMargin}px`,
										bottom: `${layout.studentListItemPillMargin}px`,
										borderRadius: `${layout.studentListPillBorderRadius}px`
									}} 
								>										 
									Card {guest.card}

									{showIndividualStudentResponsesInStudentList &&
										<div className={'liveView-studentList-studentItem-pill-name-letterBlock' + (colorScheme ? ` is--${colorScheme} `: '')}
											style={{
												height:`${studentLetterBlockSideDimension}px`,
												width:`${studentLetterBlockSideDimension}px`,	        					  				
											}}
										>
											{answer}
										</div>
									}
								</div>
							</div>
						)
					})}							
				</div>
			}
		</Motion>					
		)
	}
}

export default LiveViewMainStudentList
import React from 'react'
import Button from '../../components/uiKit/Button'
import Icon from '../../components/misc/Icons'
import {getPublicUrlForRepo} from '../../utils/getPublicUrlForRepo'
 
class RepoInlineRatingPanelStar extends React.Component {	
	render(){			
		const {onMouseOver,onMouseLeave,btnRating,rating,mouseOverRating} = this.props
		const isActive=((rating && rating>(btnRating-1)) || (!rating && mouseOverRating && mouseOverRating>(btnRating-1)))
		return(		
			<button
				className={'repo-table-inlineRatingPanel-starBtn ' + (isActive ? 'repo-table-inlineRatingPanel-starBtn--active' :  'repo-table-inlineRatingPanel-starBtn--inactive')}							
				onClick={()=>{this.props.setRating(btnRating)}}
				onMouseOver={()=>{onMouseOver(btnRating)}}
				onMouseLeave={onMouseLeave}
			>				
				<Icon name='star' />
			</button>					
		)
	}
}

class RepoInlineRatingPanel extends React.Component {
  
  constructor(props) {
		super(props)
		this.createReview=this.createReview.bind(this)
		this.state = {
			rating: null,
			mouseOverRating:null,
			feedbackText:'',
			submitLoading:false
		}
	}

	createReview(){
		this.setState({submitLoading:true})
		const {user}=this.props
		let userHasUsername = false
		if(user.username){
			userHasUsername=true
		}
		const requestData={
			repo:this.props.repo.id,
			rating:this.state.rating,
			comment: this.state.feedbackText,
			anonymous: !userHasUsername,
		}
		if(window.analytics){
			const {rating,feedbackText}=this.state
			window.analytics.track('Public Pack Review', {
				  rating:rating,
				  feedbackText:feedbackText,
				  repoId:this.props.repo.id,
				  repoUrl:getPublicUrlForRepo(this.props.repo),
				  anonymous:!userHasUsername,
				  repoName:this.props.repo.name,
			})
		}

		this.props.createReview(requestData).then(()=>{			
			this.props.showNotification('Thanks for taking the time to leave your feedback','Rating Submitted!','default')

		})
	}


	render() {			
		const {rating,mouseOverRating} = this.state
		const {user}=this.props
		let userHasUsername = false
		if(user.username){
			userHasUsername=true
		}
		let reviewTextAreaPlaceholder = 'Please leave any additional comments about this pack, including anything that other educators would find helpful. Your comment will be anonymous.'
		if (userHasUsername){
			reviewTextAreaPlaceholder = 'Please leave any additional comments about this pack, including anything that other educators would find helpful.'
		}
		const buttonRatings=[1,2,3,4,5]
		return (
			<div className={'repo-table-inlineRatingPanel ' + (rating ? 'repo-table-inlineRatingPanel--rating' : 'repo-table-inlineRatingPanel--noRating')} >
				<div className='repo-table-inlineRatingPanel-topRow'>
					<div className='repo-table-inlineRatingPanel-topRow-icon'>
						<Icon name='thumbsup' /> 
					</div>

					<div className='repo-table-inlineRatingPanel-topRow-title'>						
						Rate and Review {rating && <span>({rating} out of 5 stars)</span>}						
					</div>

					<div className='repo-table-inlineRatingPanel-topRow-stars'>					
						{buttonRatings.map((btnRating)=>{
							return(
								<RepoInlineRatingPanelStar
									key={btnRating}
									btnRating={btnRating}
									rating={rating}
									mouseOverRating={mouseOverRating}
									setRating={(btnRating)=> this.setState({rating: btnRating})}
									onMouseOver={(btnRating)=> this.setState({mouseOverRating: btnRating})}
									onMouseLeave={()=> this.setState({mouseOverRating: null})}
								/>)
	    				})}
						</div>

				</div>
				{rating && 
				<div className='repo-table-inlineRatingPanel-bottomRow'>
						<textarea 
							className='repo-table-inlineRatingPanel-messageArea' 
							placeholder={reviewTextAreaPlaceholder}					 
							maxLength={300}
							value={this.state.feedbackText}
							onChange={(e)=>{this.setState({feedbackText:e.target.value})}}
						/>

						<Button 
							name='submitfeedback'
							size='xLarge'				
							label='Submit Rating'
							iconName='paper-airplane'
							color='blue'
							onClickFunction={this.createReview}
							disabled={!this.state.rating}
							submitLoading={this.state.submitLoading}
						/>

				</div>
				}
			</div>
		)
	}
}
 
export default RepoInlineRatingPanel  
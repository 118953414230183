import React, { Component } from 'react'
import { connect } from 'react-redux'
import {showSectionSelectionModal,showExpandedQuestionSlideModal,showRepoSelectionModal} from '../../actions/modals'
import SetDetail from '../../components/itemDetail/SetDetail'
import find from 'lodash/find'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import {createPoll} from '../../utils/createPoll'
import {addCopyToLibrary} from '../../utils/addCopyToLibrary'
import {removeFromQueue} from '../../utils/removeFromQueue'
import DocumentTitle from 'react-document-title' 
import {showNotification} from '../../actions/notifications'

class SetDetailContainer extends Component {

	constructor() {
		super()
		this.addToQueue=this.addToQueue.bind(this)
		this.playSet=this.playSet.bind(this)
		this.addCopyToLibrary=this.addCopyToLibrary.bind(this)
	}

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Item Detail',{itemType:'Set'})
		}
	}

	addCopyToLibrary(){
		return this.props.addCopyToLibrary(this.props.set,'Item Detail').then(()=>{
			this.props.showNotification('to Your Library','Added a Copy','create')
		})
	}

	addToQueue(sectionId){
		this.props.createPoll(this.props.set,sectionId)
	}

	playSet(){
		this.props.showSectionSelectionModal(this.props.set)
	}


	render() {
		const {set,sets}=this.props   
		let documentTitle='Set Detail'
		if(set){
			documentTitle=`${set.name} - Plickers`
		}
		return(  
			<DocumentTitle title={documentTitle}>
				<div className='page page--wideLayout'>					
					{set &&
						<SetDetail
							showRepoSelectionModal={this.props.showRepoSelectionModal}
							parentRepo={this.props.parentRepo}
							playSet={this.playSet}
							set={set}
							parentFolder={this.props.parentFolder}
							historyPolls={this.props.historyPolls}
							sections={this.props.sections}
							showExpandedQuestionSlideModal={this.props.showExpandedQuestionSlideModal}
							addCopyToLibrary={this.addCopyToLibrary}
						/>
					}
					{sets && !set &&
						<div className='page-centerPanel'>
							<h3>Something went wrong. Please try again, if the problem persists contact support.</h3>
						</div>
					}								
				</div> 
			</DocumentTitle>
		)
	}
}

function mapStateToProps(state,ownProps) {
	const currentSet= find(state.sets, {'id':ownProps.match.params.id})
	const historyPolls=sortBy(filter(filter(state.historySetPolls,{'archived':false}),{'setObject':ownProps.match.params.id}), 'maxCaptured').reverse()
	let parentFolder
	let parentRepo
	if(currentSet){
		parentFolder= find(state.folders, {'id':currentSet.folder})	
		parentRepo= find(state.repos, {'id':currentSet.repo})	
	}
	return {
		sets:state.sets,
		set:currentSet,
		parentFolder:parentFolder || {},
		parentRepo:parentRepo,
		sections:filter(state.sections,{'archived':false}),
		historyPolls:historyPolls,
	}
}

export default connect(
	mapStateToProps,
	{ createPoll,
		removeFromQueue,
		showSectionSelectionModal,
		showExpandedQuestionSlideModal,
		showRepoSelectionModal,
		addCopyToLibrary,
		showNotification
	}
)(SetDetailContainer)
import api from '../api'

export function fetchBilling() {
	return (dispatch) => api.fetch('/billing')
		.then((response) => {
			dispatch({ type: 'FETCH_BILLING_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}

export function fetchInvoices() {
	return (dispatch) => api.fetch('/billing/invoices')
		.then((response) => {
			dispatch({ type: 'FETCH_INVOICES_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}

export function fetchUpcomingInvoice() {
	return (dispatch) => api.fetch('/billing/upcoming-invoice')
		.then((response) => {
			dispatch({ type: 'FETCH_UPCOMING_INVOICE_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}

export function updateBilling(source) {
	return (dispatch) => api.put('/billing/update',{source:source})
		.then((response) => {
			dispatch({ type: 'UPDATE_BILLING_SUCCESS', response })
			return response
		})
}

import React, {Component} from 'react'
import Button from '../../components/uiKit/Button'
import Icon from '../../components/misc/Icons'

//Modal that sends import questions feedback to segment

 
class ImportQuestionsFeedbackModalRatingButton extends Component{
	render(){	
	const {btnRating, activeRating} = this.props
		return(					  
			<button 
				className={'importQuestionsFeedbackModal-ratingBtn ' + ((activeRating === btnRating) ? 'importQuestionsFeedbackModal-ratingBtn--active' :  'importQuestionsFeedbackModal-ratingBtn--inactive')}							
				onClick={this.props.setRating}
			>
				<Icon name={`feedback-smile-${btnRating}`} />
				<div className='importQuestionsFeedbackModal-ratingBtn-label'>
					{this.props.label}
				</div>
			</button>					
		)
	}
}

class ImportQuestionsFeedbackModal extends Component{

	constructor(props) {
		super(props)
		this.sendFeedback=this.sendFeedback.bind(this)
		this.handleBackgroundClick=this.handleBackgroundClick.bind(this)

		
		this.state = {
			rating: null,
			feedbackText:'',
			submitLoading:false
		}
	}

	componentDidMount() {
		if(window.analytics){
			window.analytics.page('Import Questions Feedback Modal')
		}
	}

	UNSAFE_componentWillMount() {
		document.body.style.overflowY='hidden' //prevent scrolling while modal is open
	}
 
	componentWillUnmount() {
		document.body.style.overflowY='' // overlay is not supported by firefox, so default to blank (auto style comes from css)
		document.body.style.overflowY='overlay' // overlay is to prevent windows scrollbars interacting with pagewidth
	}

	handleBackgroundClick(e){
		if (e.target === e.currentTarget) this.props.closeModal() //close modal is click outside modal
	}
 
	sendFeedback(){
		this.setState({submitLoading:true})
		setTimeout(function() { //Start the timer
			if(window.analytics){
				const {rating,feedbackText}=this.state
				const {service}=this.props
				let product='free'
				if(service.product==='plus'){
					product='pro'
				}
				window.analytics.track('Import Questions Feedback', {
				  	rating:rating,
				  	feedbackText:feedbackText,
				  	product:product
				})
			}
			this.props.closeModal()
			this.props.showNotification('','Sent Feedback, thank you!','default')
		}.bind(this),	400)
	}


	render(){			
		const {rating} = this.state
		return(					
     	 <div className='importQuestionsFeedbackModalContainer' onClick={this.handleBackgroundClick}>				
				<div className={'importQuestionsFeedbackModal' + (rating ? ' importQuestionsFeedbackModal--hasRating' : ' importQuestionsFeedbackModal--noRating')}>

					<div className='importQuestionsFeedbackModal-closeBtn' onClick={this.props.closeModal} >
						<Icon name='circle-with-close'  />
					</div>

				<div className='importQuestionsFeedbackModal-title'>
					How are you finding Import Questions?
				</div>
				<div className='importQuestionsFeedbackModal-ratingBtnRowContainer'>
					<div className='importQuestionsFeedbackModal-ratingBtnRow'>
						<ImportQuestionsFeedbackModalRatingButton
							btnRating={1}
							activeRating={rating}
							label='Awful'							
							setRating={()=> this.setState({rating: 1})}
						/>
						<ImportQuestionsFeedbackModalRatingButton
							btnRating={2}
							activeRating={rating}
							label='Bad'							
							setRating={()=> this.setState({rating: 2})}
						/>
						<ImportQuestionsFeedbackModalRatingButton
							btnRating={3}
							activeRating={rating}
							label='Okay'							
							setRating={()=> this.setState({rating: 3})}
						/>
						<ImportQuestionsFeedbackModalRatingButton
							btnRating={4}
							activeRating={rating}
							label='Good'							
							setRating={()=> this.setState({rating: 4})}
						/>
						<ImportQuestionsFeedbackModalRatingButton
							btnRating={5}
							activeRating={rating}
							label='Awesome'							
							setRating={()=> this.setState({rating: 5})}
						/>


					</div>
				</div>
				
				<textarea 
					className='importQuestionsFeedbackModal-message' 
					placeholder='Let us know what’s working well for you, and any problems you’re facing. If you have a source of questions that you’re struggling with please include a link.'
					value={this.state.feedbackText}
					onChange={(e)=>{this.setState({feedbackText:e.target.value})}}
				/>

					<Button 
						name='sendfeedback'
						size='xLarge'				
						label='Send Feedback'
						color='blue'
						onClickFunction={this.sendFeedback}
						disabled={!this.state.feedbackText && !this.state.rating}
						submitLoading={this.state.submitLoading}
					/>


				</div>
			</div>				
		)
	}
}

export default ImportQuestionsFeedbackModal

import React from 'react'
import StudentReportPackStudent from './StudentReportPackStudent'

class StudentReportPack extends React.Component{

	render(){ 
		const {
			students,
			guests,
			showStudentScores,
			studentsWithTotals,
			totalPollCount,
			showResponses,
			reportPollsByStudent,
			reportPackSettings,
			paperSize,
			browser
		}=this.props		

		const columnCount = reportPackSettings.columnCount



		return(			
			<div className={'printPack printPack--studentReport printPack--rendered ' + (browser === 'safari' ? ' printPack--safari ': ' ') + (paperSize === 'letter' ? ' printPack--letter ': ' printPack--a4 ') + (columnCount === 2 ? 'printPack--twoColumn ' : '') + (columnCount === 1 ? 'printPack--singleColumn ' : '')}>

				{students && students.map((student,index) => {
					
					const studentTotals=studentsWithTotals[student.id]

					return(						 	 	
						<StudentReportPackStudent 
							ghostHeights={this.props.ghostHeights}
							reportPackSettings={this.props.reportPackSettings}
							highlightCorrect={this.props.highlightCorrect}
							showQuestionGrades={this.props.showQuestionGrades} 
							personalizedContentLabel={this.props.personalizedContentLabel}
							totalPollCount={totalPollCount} 
							studentTotals={studentTotals} 
							showStudentScores={showStudentScores} 
							student={student} 
							dateRangeLabel={this.props.dateRangeLabel}
							studentPolls={reportPollsByStudent[student.id]}
							key={student.id}
							showResponses={showResponses}
							paperSize={paperSize}

						/>			
	    			)
				})} 
				{guests &&guests.map((guest,index) => {
					
					const studentTotals=studentsWithTotals[guest]
					return(						 	 	
						<StudentReportPackStudent
							ghostHeights={this.props.ghostHeights}
							reportPackSettings={this.props.reportPackSettings}
							highlightCorrect={this.props.highlightCorrect} 
							showQuestionGrades={this.props.showQuestionGrades} 
							personalizedContentLabel={this.props.personalizedContentLabel}
							totalPollCount={totalPollCount} 
							studentTotals={studentTotals} 
							showStudentScores={showStudentScores} 
							guest={guest}
							isGuest={true	}
							dateRangeLabel={this.props.dateRangeLabel}
							studentPolls={reportPollsByStudent[guest]}
							key={guest}
							showResponses={showResponses}
							paperSize={paperSize}
						/>			
        			)
				})} 

			</div>			
		

		)
	}
}


export default StudentReportPack

import React from 'react'
import {formatDate} from '../../../../utils/formatDate'
import {formatDateConsumerView} from '../../../../utils/formatDateConsumerView'
import Button from '../../../uiKit/Button'
import Icon from '../../../misc/Icons'
import { Link } from 'react-router-dom'
import QuestionSlideCarousel from '../../../uiKit/QuestionSlideCarousel'
import {ContextMenuTrigger } from 'react-contextmenu'
import {getQuestionBodyLabel} from '../../../../utils/getQuestionBodyLabel'
import {openItemInEditor} from '../../../../utils/openItemInEditor'
import {getRepoforId} from '../../../../utils/getRepoforId'
import {getUserForId} from '../../../../utils/getUserForId'
import {getDisplayNameForUser} from '../../../../utils/getDisplayNameForUser'
import {getSetForId} from '../../../../utils/getSetForId'
import {getQuestionForId} from '../../../../utils/getQuestionForId'
import {getLinkForRepo} from '../../../../utils/getLinkForRepo'

class SectionHomeUpNext extends React.Component{

	collect(props) {
		return { upNextPoll: props.upNextPoll,isSet:props.isSet,item:props.item}
	}

 
 
	render(){

		const {upNextPoll} = this.props
		
		let isSet
		let item		
		let clientModified
		let title=''
		let itemId
		let containingRepoID

		
		if(upNextPoll){
			if(upNextPoll.setObject){
				isSet=true
				item= getSetForId(upNextPoll.setObject)
				if(item){
					clientModified=item.clientModified
					title = item.name	
					itemId=item.id
					containingRepoID=item.repo
				}
			}
			else{
				isSet=false
				item= getQuestionForId(upNextPoll.question)
				if(item){
					clientModified=item.clientModified
					title=getQuestionBodyLabel(item)
					itemId=item.id
					containingRepoID=item.repo
				}
			}

			let containingRepo = null
			let containingRepoName = null
			let isViewOnlyRepo = false
			let inSharedRepo = false 
			let isPublicConsumer = false

			let lastEditedBy
			if(containingRepoID){
				containingRepo = getRepoforId(item.repo)
				if(containingRepo){
					inSharedRepo=containingRepo.shared
					if(inSharedRepo){
						const lastEditedUser = getUserForId(item.lastEditedBy,item.repo)
						lastEditedBy=getDisplayNameForUser(lastEditedUser)
					}
					if(containingRepo.role ==='consumer'){
						isPublicConsumer = true
					}
					containingRepoName=containingRepo.name
					if(containingRepo.role ==='viewer' || containingRepo.role ==='consumer'){
						isViewOnlyRepo = true
					}
				}
			}

		 
		

			return(
				<ContextMenuTrigger 
          		id='UP_NEXT'
          		upNextPoll={upNextPoll}
          		isSet={isSet}
          		item={item}
          		holdToDisplay={1000}
          		collect={this.collect}
          		isViewOnlyRepo={isViewOnlyRepo}
        	>

					<div className={'sectionHome-upNext' + 
						(this.props.sectionHasLivePoll ? ' sectionHome-upNext--livePoll' : '' ) +
						(this.props.upNextContextMenuOpen ? ' sectionHome-upNext--contextOpen' : '' )
					}>
		

						<div className='sectionHome-upNext--left'>

							<div className='sectionHome-upNext-label is--upnext'>
								Up Next
							</div>

							<div className='sectionHome-upNext-title'>
								{title} {inSharedRepo && <Icon name='shared'/>}
							</div>

							<div className='sectionHome-upNext-subline'>
								{containingRepoName &&
									<Link to={getLinkForRepo(containingRepo)} className='sectionHome-upNext-subline-containingRepo'>
										{isPublicConsumer && <Icon name='globe-shared' />} {containingRepoName}
									</Link>
								}
								{!isPublicConsumer &&
									<div className='sectionHome-upNext-subline-timestamp'>
										{lastEditedBy?`${lastEditedBy} edited` :'Edited'} {formatDate(clientModified)}
									</div>
								}
								{isPublicConsumer &&
									<div className='sectionHome-upNext-subline-timestamp'>
										Edited {formatDateConsumerView(clientModified)}
									</div>
								}
							</div>

							<div className='sectionHome-upNext-actionList'>
								<Button label='Play Now' disabled={upNextPoll.id?false:true} onClickFunction={()=>{this.props.playPoll(upNextPoll,item)}}/>											
								<Button label={(!isViewOnlyRepo ? 'Edit ' : 'View ')  + (isSet ? 'Set' : 'Question')} onClickFunction={()=>{openItemInEditor(itemId,isSet)}} />						
							</div>

						</div>

			
						<div className='sectionHome-upNext--right'>
							{isSet && item &&
								<QuestionSlideCarousel  key={item.id} play={()=>{this.props.playPoll(upNextPoll,item)}} set={item} slideWidth={328} showSetNavigator />
							}

							{!isSet && item &&
								<QuestionSlideCarousel key={item.id} play={()=>{this.props.playPoll(upNextPoll,item)}}  question={item} slideWidth={328} />
							}
						</div>								

						<div className="sectionHome-upNext-removeBtn" onClick={()=>{this.props.removeFromQueue(upNextPoll)}} >
							<Icon name="close-small"/>
						</div>				
						<div className='sectionHome-upNext-background' />
					</div>
				</ContextMenuTrigger>
			)
		}
	}
}

export default SectionHomeUpNext

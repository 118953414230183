import {updateControl} from '../actions/control'
import api from '../api'
import {updateQuestionPoll,updateSetPoll} from '../actions/polls'
import store from '../store'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import moment from 'moment'
import {isSet} from '../utils/isSet'
import {updateRecentActivities} from '../actions/recentActivities'
import * as Sentry from '@sentry/browser'
  

export function playPoll(poll,item) {	
	return(dispatch) => {

		const controlObject = createControlObject(poll,item)
		dispatch(updateControl(controlObject))
		if(isSet(item)){
			dispatch(updateRecentActivities(item.id,'set','play'))
		}
		else{
			dispatch(updateRecentActivities(item.id,'question','play'))
		}
		
		return reorderQueueOnPlay(poll,dispatch)
	}
}

	
export function playItem(item, sectionId) {
	const state = store.getState()	
	const sectionQueueQuestionPolls=filter(state.queueQuestionPolls,{'section':sectionId})
	const sectionQueueSetPolls=filter(state.queueSetPolls,{'section':sectionId})
	const combinedData=sectionQueueQuestionPolls.concat(sectionQueueSetPolls)
	const sortedPolls= sortBy(combinedData, 'planned')

	//find the planned time of item currently at top of queue and subtract a 1 day buffer
	let plannedTime=new Date()
	if(sortedPolls[0]){
		plannedTime=moment(sortedPolls[0].planned).subtract(1,'d')	
	}
	
	
	return(dispatch) => createPoll(item, sectionId,plannedTime, dispatch)

		.then((response) => {
			const controlObject = createControlObject(response,item)
			dispatch(updateControl(controlObject))
			//reorderQueueOnPlay(response,dispatch)
			if(isSet(item)){
				dispatch(updateRecentActivities(item.id,'set','play'))
			}
			else{
				dispatch(updateRecentActivities(item.id,'question','play'))
			}
		})
		.catch(err => {
			return err
		})


}





//reorder and also add snapshot to setPoll
function reorderQueueOnPlay(poll,dispatch){
	const sectionId=poll.section
	const state = store.getState()	
	const sectionQueueQuestionPolls=filter(state.queueQuestionPolls,{'section':sectionId})
	const sectionQueueSetPolls=filter(state.queueSetPolls,{'section':sectionId})
	const combinedData=sectionQueueQuestionPolls.concat(sectionQueueSetPolls)
	const sortedPolls= sortBy(combinedData, 'planned')

	//find the planned time of item currently at top of queue and subtract a 1 day buffer
	const newPlanned=moment(sortedPolls[0].planned).subtract(1,'d')
	let requestBody={
		...poll,
		planned:newPlanned.toISOString()
	}

	if(poll.setObject){
		//add a snapshot
		const set=find(state.sets,{id:poll.setObject})
		requestBody.snapshot=set
		requestBody.snapshotRefreshed=new Date()
		dispatch(updateSetPoll(poll.id,requestBody))
	}
	else if(poll.question){
		const question=find(state.questions,{id:poll.question})
		if(question){
			requestBody.snapshot=question	
		}
		dispatch(updateQuestionPoll(poll.id,requestBody))
	}
}






	



function createControlObject(poll,item) {
	let setPoll = null
	let setQuestion = null
	let currentPoll = null
	let snapshot=null
	if(isSet(poll)) {
		setPoll = poll.id
		//set setQuestion to the first question in the set
		///setQuestion = poll.polls[0].question
		//set question ids change when update set so take the question id from set object not poll
		setQuestion = item.questions[0]
		snapshot=setQuestion
	} 
	else {
		currentPoll = poll.id
		snapshot=item
	}
	const nowDate = new Date()

	const playback={
		item:null,
		isPlaying:false
	}

	const controlObject = {
		'controlled': nowDate,
		'section': poll.section,
		'scanning':false,
		'showGraph':false,
		'revealAnswer':false,
		'setPoll': setPoll,
		'setPollPoll':setQuestion ? setQuestion.questionId:null,
		'currentPoll': currentPoll,
		'snapshot':snapshot,		
		'mobileAccepted': false,
		'paused':false,
		'showCardAssignment':false,
		'playback':playback,
		'zoomedItem':null
	}

	return controlObject
}



function newSetPoll(data,dispatch) {   
	return api.post('/setpolls/',data)
		.then((response) => {
			dispatch({ type: 'CREATE_SET_POLL_SUCCESS', response,syncAction:true  })

			return response
		})  
		.catch(err => {
			Sentry.withScope(scope => {
				scope.setExtra('request', data)
				scope.setExtra('isPlay', true)
				Sentry.captureException(new Error(err))
			})})
}




function newQuestionPoll(data,dispatch) {  
	return api.post('/polls/',data)
		.then((response) => {
			dispatch({ type: 'CREATE_QUESTION_POLL_SUCCESS', response,syncAction:true  })
			return response
		})  
		.catch(err => {
			Sentry.withScope(scope => {
				scope.setExtra('request', data)
				scope.setExtra('isPlay', true)
				Sentry.captureException(new Error(err))
			})})
}

function createPoll(item, sectionId,plannedTime, dispatch) {
	const nowDate = new Date()
	if(isSet(item)) {
		const requestBodySet = {
			'section': sectionId,
			'setObject': item.id,
			'snapshot': item,
			'snapshotRefreshed':nowDate,
			'cleared': null,
			'polls': {},
			'planned': plannedTime,
			'canceled': false,
			'archived': false,
			'clientModified': nowDate,
			'userCreated': nowDate
		}
		return newSetPoll(requestBodySet, dispatch)
	} 
	else {
		const requestBodyQuestion = {
			'section': sectionId,
			'question': item.id,
			'snapshot': item,
			'cleared': null,
			'planned': plannedTime,
			'canceled': false,
			'archived': false,
			'clientModified': nowDate,
			'userCreated': nowDate
		}
		return newQuestionPoll(requestBodyQuestion, dispatch)
	}
}
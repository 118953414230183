import {updateQuestionMeta} from '../actions/questions'
import {updateSetMeta} from '../actions/sets'
import {updateFolder} from '../actions/folders'

 

export function moveToRepo(itemToMove,newRepo,folder) {
	const nowDate = new Date()
	if(itemToMove.questions){
		const updatedSet={
			...itemToMove,
			clientModified:nowDate,
			folder:folder || null,
			repo:newRepo
		}
		return updateSetMeta(updatedSet,itemToMove.id)	
	}
	else if(itemToMove.ancestors){
		const updatedFolder={
			...itemToMove,	
			clientModified:nowDate,
			parent:folder || null,
			repo:newRepo
		}
		return updateFolder(updatedFolder,itemToMove.id,true)	
	}

	else{
		const updatedQuestion={
			...itemToMove,	
			clientModified:nowDate,
			folder:folder || null,
			repo:newRepo
		}
		return updateQuestionMeta(updatedQuestion,itemToMove.id)	
	}
}


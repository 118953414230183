// this method checks if the element is wider than allowed (only happens for equations)
// note (Safari bug handling):
// Safari sometimes has scrollWidth off by 1px.
// Therefore we have 1px buffer here to prevent unintentionally returning true
// which results in setting isValid false --> selecting small font
function isTooWide(element) {
  const { scrollWidth, clientWidth } = element
  return (scrollWidth - clientWidth) > 1
}

export default isTooWide

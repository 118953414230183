import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchMobileInfo } from '../actions/mobileInfo'
import MobileUpdateNotification from '../components/MobileUpdateNotification'

// Return 1 if a > b
// Return -1 if a < b
// Return 0 if a == b
function compareVersionNumbers(a, b) { // e.g. 4.1.1 vs 4.0
  if (a === b) {
    return 0
  }
  const componentsOfA = a.split('.')
  const componentsOfB = b.split('.')
  const len = Math.min(componentsOfA.length, componentsOfB.length)

  // loop while the components are equal
  for (let i = 0; i < len; i++) {
    if (parseInt(componentsOfA[i], 10) > parseInt(componentsOfB[i], 10)) { // A bigger than B
      return 1
    }
    if (parseInt(componentsOfA[i], 10) < parseInt(componentsOfB[i], 10)) { // B bigger than A
      return -1
    }
  }
  // If one's a prefix of the other, the longer one is greater.
  if (componentsOfA.length > componentsOfB.length) {
    return 1
  }
  if (componentsOfA.length < componentsOfB.length) {
    return -1
  }
  // Otherwise they are the same.
  return 0
}

const notificationIsVisible = (location) => { // don't show notification on some pages
  if (
    !(location.pathname.indexOf('/seteditor/') > -1) &&
    !(location.pathname.indexOf('/editor/') > -1) &&
    !(location.pathname.indexOf('/liveview') > -1) &&
    !(location.pathname.indexOf('/scoresheet') > -1) &&
    !(location.pathname.indexOf('/reset-password') > -1) &&
    !(location.pathname.indexOf('/delete-account') > -1)
  ) return true
  return false
}

class MobileUpdateNotificationContainer extends Component {
  constructor() {
    super()
    this.getNotificationToDisplay = this.getNotificationToDisplay.bind(this)
    this.dismissNotification = this.dismissNotification.bind(this)
    this.state = {
      notificationDismissed: false,
    }
  }

  getNotificationToDisplay() {
    let notificationToDisplay = null
    if (this.props.mobileInfo && this.props.configuration) {
      const { platform } = this.props.mobileInfo
      const { appVersion } = this.props.mobileInfo
      const mobileAppUpgradeNotifications = this.props.configuration.mobileAppUpgradeNotification
      if (mobileAppUpgradeNotifications) {
        mobileAppUpgradeNotifications.forEach((notification) => {
          // compare user platform and appVersion to the notification
          if (!notificationToDisplay && platform === notification.platform) {
            if (compareVersionNumbers(appVersion, notification.beforeAppVersion) === -1) {
              notificationToDisplay = notification
            }
          }
        })
      }
    }
    return notificationToDisplay
  }

  dismissNotification() {
    this.setState({ notificationDismissed: true })
    const oldMobileInfo = this.props.mobileInfo
    this.props.fetchMobileInfo().then((response) => {
      // check if they have actually updated the app version
      if (window.analytics) {
        const oldAppVersion = oldMobileInfo ? oldMobileInfo.appVersion : ''
        const newAppVersion = response ? response.appVersion : ''
        const versionChanged = oldAppVersion !== newAppVersion
        window.analytics.track('Mobile update notification action', {
          type: 'dismiss',
          platform: response.platform,
          preDismissAppVersion: oldAppVersion,
          postDismissAppVersion: newAppVersion,
          versionChanged,
        })
      }
    })
  }

  render() {
    const notification = this.getNotificationToDisplay()
    const showNotification =
      notificationIsVisible(this.props.history.location) &&
      notification &&
      !this.state.notificationDismissed

    return (
      <MobileUpdateNotification
        mobileInfo={this.props.mobileInfo}
        notificationDismissed={this.state.notificationDismissed}
        dismissNotification={this.dismissNotification}
        showNotification={showNotification}
        notification={notification}
      />
    )
  }
}

export default withRouter(connect(
  (state) => ({
    configuration: state.configuration,
    mobileInfo: state.mobileInfo,

  }),
  { fetchMobileInfo },
)(MobileUpdateNotificationContainer))

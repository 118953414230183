//formulas that trigger formula input rule
//exclude formulas that don't have any numbers (more likely to interfere with normal text)
module.exports = [
  "O2",//
  "H2O",
  "CO2",
  "H2SO4",
  "H2O2",
  //"O2", // probably too short
  "AL2O3",
  "Al(OH)3",
  "Mg(OH)2",
  "(NH4)2",

  //
  // IMPORT FROM https://byjus.com/chemical-compound-formulas/

  "CH3COOH",
	// "HCN", // no numbers
	"H2SO4",
	"CH3COO", // originally CH3COO–
	"NH3",
	"HNO3",
	"H3PO4",
	"Na3PO4",
	"CaCO3",
	"(NH4)2SO4",
	"H2CO3",
	"NaHCO3",
	// "NaOH", // no numbers
	"Ca(OH)2",
	"C2H5OH",
	// "HBr", // no numbers
	"H2SO4",
	"HNO2",
	// "KOH", // no numbers
	"AgNO3",
	"Na2CO3",
	// "NaCl", // no numbers
	"(C6H10O5)n",
	"Mg(OH)2",
	"CH4",
	"NO2",
	// "KOH", // no numbers
	"NaNO3",
	"H2SO3",
	"Al2(SO4)3",
	"Al2O3",
	"NH3",
	"NH4NO3",
	"(NH4)3PO4",
	"Ba(OH)2",
	"CCl4",
	"C6H8O7",
	// "HCN", // no numbers
	"C7H6O3",
	// "HI", // no numbers
	// "HClO", // no numbers
	"Fe2O3",
	"Mg3(PO4)2",
	"C2H3NaO2",
	"Na2SO4",
	"C12H22O11",
	"KNO3",
	"NH4HCO3",
	"NH4Cl",
	"NH4OH",
	"Ca(NO3)2",
	// "CaO", // no numbers
	// "CO", // no numbers
	"Cl2",
	"C6H6O",
	"H2O2",
	// "OH–", // no numbers
	"MgCl2",
	"Mg(OH)2",
	// "KCl", // no numbers
	// "KI", // no numbers
	"SO2",
	"C3H8O3",
	"(NH4)2SO4",
	"Ba(NO3)2",
	"C4H6O4Ca",
	"H2SO3",
	"Fe2O3",
	"K2CO3",
	// "AgCl", // no numbers
	// "NaI", // no numbers
	"Na2O",
	"Na2S",
	"C12H22O11",
	"Zn(NO3)2",
	"Al2(SO4)3",
	"C20H14O4",
	"Mg(NO3)2",
	"SiO2",
	"C3H6O",
	"C6H6O2",
	"C5H5N",
	"C2H3O2NH4",
	"C8H10",
	"BaSO4",
	"C6H6",
	"CHO3–",
	"CrO42-",
	"C4H8O",
	"CN−",
	"C2HCl3O2",
	"MgSO4",
	"CH3OH",
	"NO2",
	// "O", // no numbers
	"C16H18ClN3S",
	"Na2SO3",
	"SO3",
	"AlPO4",
	"C18H36O2",
	"N2O",
	// "CO", // no numbers
	"H2CO3",
	"Na2CO3",
	"TiO2",
	"C2H3N",
	"H2C2O4",
	"K2Cr2O7",
	"C7H6O3",
	// "NaBr", // no numbers
	// "NaClO", // no numbers
	"Zn(CH3COO)2(H2O)2",
	"ZnCl2",
	"Zn(OH)2",
	"MgCO3",
	"KClO3",
	"N2H4",
	"C6H8O6",
	"C7H6O2",
	"C6H6O2",
	"Cl2",
	"NH4NO3",
	"(NH4)3PO4",
	"CCl4",
	"HNO2",
	"C4H4O4",
	"Na2S2O5",
	// "NaClO", // no numbers
	"C2H5NO",
	"MgCO3",
	"(Na2O)x·SiO2",
	"NO2", // originally NO2-
	// "OH−", // no numbers
	"PO4", // originally PO43- 
	"CH2Cl2",
	"CS2",
	"CrK2O4",
	"ZnSO4",
	// "I", // no numbers
	"C76H52O46",
	// "Al", // no numbers
	"HClO4",
	// "ClO–",  // no numbers
	"C6H8O7",
	// "KBr", // no numbers
	"H2CrO4",
	"OH2",
	"C3H6O2",
	"C2H6OS",
	"C6H14",
	"H3PO4",
	"C10H12O2",
	"MnO2",
	"C2H6OS",
	"C3H6O3",
	"C4H4O6KNa·4H2O",
	"C6H12N4",
	// "LiOH",  // no numbers
	"C2H6OS",
	"PCl5",
	"K2O",
	"KH2PO4",
	"AgC2H3O2",
	"Na3C6H5O7",
	// "NaF", // no numbers
	"NaNO2",
	"SO42−",
	"BaCO3",
	"CaI2",
	"HSO4–",
	"Li2O",
	"C4H8N2O2",
	"KMnO4",
	"Ag3PO4",
	"Na2CO3",
	"NH4Br",
	"Ca3(PO4)2",
	"K2Cr2O7",
	"Al2S3",
	"(NH4)2CO3",
	"BaCl2",
	"Ca(NO3)2",
	// "HI", // no numbers
	// "NO", // no numbers
	"K2CO3",
	// "KI", // no numbers
	"KNO3",
	"Na2O",
	"C6H12O6",
	"MgI2",
	"MgS",
	"O3",
	// "KCN", // no numbers
	"Ag2O",
	"Na2CrO4",
	"Na2O2",
	"C7H8",
	"ZnCO3",
	"Zn3(PO4)2",
	// "ZnS", // no numbers
	"C6H4Cl2",
	"H3BO3",
	"Mg3(PO4)2",
	"C2O42−",
	"KHCO3",
	// "KClO", // no numbers
	"KNO2",
	"C27H28Br2O5S",
	"NH4I",
	"(NH4)(NO3)",
	"NH4NO2",
	"(NH4)2O",
	// "Ar", // no numbers
	"BaBr2",
	"BaI2",
	"BrO3–",
	"N2O3",
	"C2H6O2",
	// "HClO", // no numbers
	"NiSO4",
	// "He",  // no numbers
	// "I", // no numbers
	"Pb(C2H3O2)2",
	// "LiCl", // no numbers
	"PO43-",
	// "KF", // no numbers
	"K2SO3",
	"Ag2CO3",
	// "NaCN", // no numbers
	"Na3N",
	"SrCl2",
	"Sr(NO3)2",
	"CH4N2O",
	"NaClO",
	"H2CO3",
	// "LiBr", // no numbers
	"AlF3",
	"BaF2",
	"C4H8O2",
	"CaH2",
	"CuCO3",
	"CH3CH2OH",
	// "F", // no numbers
	"Li3PO4",
	"C3H8O3",
	// "HBrO", // no numbers
	// "HIO", // no numbers
	"PbI2",
	// "LiI", // no numbers
	"MgO",
	"C3H7NO2",
	"Ni(NO3)2",
	// "KCl", // no numbers
	"KNO3",
	"Na2Cr2O7",
	"NaNO3",
	"C4H6O6",
	"ZnI2",
	// "Br", // no numbers
	"AlBr3",
	"C2H6Na4O12",
	"C4H8O4Ni",
	"Na2S2O3",
	"C2H4O",
	"CuSO4",
	"C6H14O6",
	"CaCl2",
	// "HCN", // no numbers
	"C5H8NO4Na",
	"(C8H8)n",
	"CaC2",
	"C2Cl4",
	"NaClO3",
	"KIO3",
	"C2H6Na4O12",
	"Pb(C2H3O2)2",
	// "KSCN", // no numbers
	"C4H10",
	"C12H22O11",
	"C27H36N2O10",
	"CH2O",
	// "HCOOH", // no numbers
	"SF6",
	"PCl3",
	"C2H6",
	"N2O5",
	"H3PO3",
	"K4Fe(CN)6",
	"XeF2",
	"Br2",
	"C6H5",
	"PI3",
	"H2S2O8",
	"KH2PO4",
	"K2HPO4",
	"Al(OH)3",
	"(NH4)2S2O8",
	"Na2[B4O5(OH)4]·8H2O",
	"C2H3O2Cl",
	"CH3CO2K",
	// "BaO", // no numbers
	"Cu2O",
	// "LiBr", // no numbers
	"Cu(OH)2",
	"SnO2",
	"ClF3",
	"C2H4",
	"C2H2",
	"Cr2O3",
	"NaHSO4",
	"CuCl2",
	"HgCl2",
	"SnCl2",
	"C3H8",
	"PbO2",

	// ADDITIONAL IMPORTS FROM https://resources.finalsite.net/images/v1559907316/hamden/wddv6fqt6jt9knyia5o5/APChemistrySummerWork-NamingWritingFormulas.pdf
	// Didn't cross check all for duplication above. Know these are mostly examples.
	"N2O4",
	"SeCl2",
	"P2S",
	"Si3I8",
	"S2O",
	"PBr5",
	"N2Se3",
	"As2O4",
	"PH3",
	"OCl2",
	"Si2O3",
	"CCl4",
	"S3O",
	"PS3",
	"BBr3",
	"N2O5",
	"PF6",
	"BaCl2",
	"(NH4)2SO4",
	"Cu2S",
	"Mn(NO3)4",
	"Na2S",
	"Be3N2",
	"CaF2",
	"Cs3P",
	"BaBr2",
	"Li2O",
	"Al2Se3",
	"CO3",
	"CIO3",
	"NO3",
	"SO4",
	"PO4",
	"SO4",
	"HSO4",
	"PO4",
	"HPO4",
	"H2PO4",
	"C2H2O2",
	"C2O4",
	"S2O3",
	"NH4",
	"MnO4",
	"CrO4",
	"Cr2O7",
	"C2H3O2",
	"C2O4",
	"S2O3",
	"Na2SO3",
	"Sr(NO3)2",
	"Be3(PO4)2",
	"NH4F",
	"CsC2H3O2",
	"Ba(ClO4)2",
	"NH4CN",
	"Al(OH)3",

	// MISC IMPORTS (mostly past papers)
	// again haven't checked for imports
	"Fe2(SO4)3",
	"MgSO4",
	"Na2O",
	"Cl2", //maybe too short
	"(NH4)2S",
	"SbI3",
	"MgCl2",
	"HNO3",
	"NH3",
	"NO3",
	"NH4",
	"HNO3",
	"NH3",
	"NO3",
	"HN4",
	"TiCl4",
	"TiO2",
	"CL2",
	"CH2O",
	"H2S",
	"Na2O",
	"Na2O3",
	"NaO2",
	"NH4",
	"CO3",
	"NO3",
	"PO4",
	"SO4",
	"CaCO3",
	"CH3COOH",
	"CH4",
	"C2H6",
	"C4H10",
	"PCl5",
	"PCl3",
	"PCl5",
	"C9H20",
	"C5H9F3",
	"N2O5",
]

import React from 'react'
import { connect } from 'react-redux'
import {getUserDisplayName} from '../../../../utils/userDisplayName'
import moment from 'moment'

class SetHandoutHeader extends React.Component{

	render(){ 

		const {setName,instructionalText,showDate,showTeachersName}=this.props
		
		//const date = 'Monday 10th November'	
		const date = moment().format('dddd Do MMMM')
		//const teachersName = 'Miss Atack'
		const teachersName = getUserDisplayName(this.props.user)

 
		return(							
			<div className='printPack--setHandout-setHandoutHeader'>				
				<div className='printPack--setHandout-setHandoutHeader-setName'>
					{setName}
				</div>
				<div className='printPack--setHandout-setHandoutHeader-instructionalText'>
					{instructionalText}
				</div>

				<div className='printPack--setHandout-setHandoutHeader-additionalLabels'>
					
					{showTeachersName &&
						<React.Fragment>
							{teachersName}
						</React.Fragment>
					}
					{showDate && showTeachersName &&
						<React.Fragment>
							&nbsp;·&nbsp;
						</React.Fragment>
					}
					{showDate &&
						<React.Fragment>
							{date}
						</React.Fragment>
					}
					
					
				</div>										
			</div>
		)
	}
}
// export default 

export default connect(
	(state) => ({
		user:state.user
	}),
	{
	   }
)(SetHandoutHeader)

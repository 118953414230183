import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { GoogleLogin } from '@react-oauth/google'
import SignInWithAppleButton from '../misc/SignInWithAppleButton'
import ModalWrapper from '../modals/ModalWrapper'
import InputField from '../uiKit/InputField'
import Button from '../uiKit/Button'
import { isPasswordValid } from '../../utils/passwordValidator'

function getInstructionMessage(title) {
  let dynamicMessage = 're-authenticate'
  if (title === 'Change Google Account') {
    dynamicMessage = 'authenticate with your existing Google account'
  }

  return `Please ${dynamicMessage} to continue.`
}

function ReauthenticationModal(props = {}) {
  const {
    title,
    signinMethodsToDisplay = {},
    onAuthenticate,
    hideModal,
    actionButtonColor,
  } = props

  const [password, setPassword] = useState('')

  const displayExternalAndPassword =
    (signinMethodsToDisplay.google || signinMethodsToDisplay.apple) &&
    signinMethodsToDisplay.password
  const buttonColor = actionButtonColor || 'blue'

  function setAuthenticationWithApple(data) {
    if (data.authorization) {
      authenticate('apple', data.authorization.id_token)
    }
  }

  function authenticate(type, value) {
    hideModal()
    const authentication = { type }
    if (type === 'password') {
      authentication.password = value
    } else {
      authentication.idToken = value
    }
    onAuthenticate(authentication)
  }

  function handleInputChange(e) {
    setPassword(e.target.value)
  }

  function handleInputKeyPress(e) {
    if (e.keyCode === 13 && isPasswordValid(password)) {
      authenticate('password', password)
    }
  }

  return createPortal(
    <div className='reauthenticationModal-modal'>
      <ModalWrapper
        title='Reauthentication'
        showModalHeader={false}
        hideModal={hideModal}
      >
        <div className='reauthenticationModal'>
          <div className='reauthenticationModal-column'>
            <div className='reauthenticationModal-title'>
              {title}
            </div>
            <div className='reauthenticationModal-instruction'>
              {getInstructionMessage(title)}
            </div>
            <div className='reauthenticationModal-signinMethodContainer'>
              {signinMethodsToDisplay.google && (
              <div className='reauthenticationModal-signinMethodContainer-google'>
                <GoogleLogin
                  logo_alignment='center'
                  width='250px'
                  text='continue_with'
                  onSuccess={(credentialResponse) => authenticate('google', credentialResponse.credential)}
                />
              </div>
              )}
              {signinMethodsToDisplay.apple && (
              <div className='reauthenticationModal-signinMethodContainer-apple'>
                <SignInWithAppleButton
                  callback={(data) => setAuthenticationWithApple(data)}
                  redirectPath='/account'
                  text='continue_with'
                />
              </div>
              )}
              {displayExternalAndPassword && (
              <div className='reauthenticationModal-signinMethodContainer-divider'>
                <div className='reauthenticationModal-signinMethodContainer-divider-line' />
                <div className='reauthenticationModal-signinMethodContainer-divider-label'>
                  or
                </div>
                <div className='reauthenticationModal-signinMethodContainer-divider-line' />
              </div>

              )}
              {signinMethodsToDisplay.password && (
              <div className='reauthenticationModal-signinMethodContainer-password'>
                <InputField
                  type='password'
                  placeholder='Password'
                  value={password}
                  onChangeFunction={(e) => handleInputChange(e)}
                  onKeyDown={(e) => handleInputKeyPress(e)}
                />
                <div className='reauthenticationModal-signinMethodContainer-password-actionRow'>
                  <Button
                    size='large'
                    label='Confirm'
                    color={isPasswordValid(password) ? buttonColor : 'gray'}
                    type='submit'
                    onClickFunction={() => authenticate('password', password)}
                  />
                </div>
              </div>
              )}
            </div>
          </div>
        </div>
      </ModalWrapper>
    </div>,
    document.getElementById('reauthenticationModal'),
  )
}

export default ReauthenticationModal

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { addPassword } from '../../../actions/user'
import { hideModal } from '../../../actions/modals'
import AddPasswordForm from '../../../components/forms/account/AddPasswordForm'
import { showNotification } from '../../../actions/notifications'
import ReauthenticationModal from '../../../components/reauthenticationModal/ReauthenticationModal'
import handleAccountRequest from '../../account/utils/handleAccountRequest'

function AddPasswordContainer(props) {
  const {
    form, user, hideModal, showNotification, addPassword, signinMethods,
  } = props
  const [showReauthenticationModal, setShowReauthenticationModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const externalAccountType = (signinMethods && signinMethods.google) ?
    'google' :
    'apple'

  function onAddPassword(event) {
    event.preventDefault()
    setShowReauthenticationModal(true)
  }

  function onAuthenticate(authentication) {
    const { newPassword } = form.values
    const requestData = {
      idToken: authentication.idToken,
      password: newPassword,
    }
    handleAccountRequest({
      setSubmitLoading,
      setErrorMessage,
      makeRequest: () => addPassword(user.id, requestData),
      hideModal,
      showSuccessNotification: () => showNotification('email and password login to your account', 'Added', 'default'),
      requestName: 'addPassword',
    })
  }

  return (
    <div className='addPassword'>
      <AddPasswordForm
        email={user.email}
        addPassword={(event) => onAddPassword(event)}
        submitLoading={submitLoading}
      />
      {errorMessage && (
      <div className='accountForm-error'>
        {errorMessage}
      </div>
      )}
      {showReauthenticationModal && (
        <ReauthenticationModal
          title='Add Password'
          signinMethodsToDisplay={{ [externalAccountType]: true }}
          onAuthenticate={(authentication) => onAuthenticate(authentication)}
          hideModal={() => setShowReauthenticationModal(false)}
        />
      )}
    </div>
  )
}

export default connect(
  (state) => ({
    user: state.user,
    idToken: state.modals.idToken,
    form: state.form.addPasswordForm,
    signinMethods: state.signinMethods,
  }),
  {
    addPassword, showNotification, hideModal,
  },
)(AddPasswordContainer)

import React from 'react'
import ReactGA from 'react-ga4'
import { Route, Redirect } from 'react-router-dom'

// if user is authenticated they get redirected
const RedirectAuthenticated = ({
  path,
  location,
  isAuthenticated,
  willAuthenticate,
  component: Component,
}) => {
  if (isAuthenticated) {
    const hash = window.location.hash.replace(/#/g, '')
    if (hash) {
      return (
        <Redirect to={{ pathname: `/${hash}` }} />
      )
    }
    return (
      <Redirect to={{ pathname: '/library' }} />
    )
  }
  if (!isAuthenticated && !willAuthenticate) {
    return (
      <Route
        exact
        path={path}
        render={(props) => {
          if (typeof window.gtag === 'function') {
            ReactGA.set({ page: location.pathname + location.search })
            ReactGA.send({ hitType: 'pageview' })
          }

          /* eslint-disable-next-line react/jsx-props-no-spreading */
          return <Component {...props} />
        }}
      />

    )
  }
  return null
}

export default RedirectAuthenticated

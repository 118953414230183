import React from 'react'
import Icon from './../misc/Icons'



class ScoreSheetStudentHeader extends React.Component{
	render(){
		const {showStudentLastName, sortedBy, sortOrder, showStudentCardNumber} = this.props
		return(									
			<div className="scoreSheet-students-header">			
					{showStudentCardNumber &&
						<div className="scoreSheet-students-header-col is--cardNumber" onClick={()=>{this.props.changeSorting('card')}}>								
							{sortedBy === 'card' &&
								<ScoreSheetStudentHeaderSortIcon sortOrder={sortOrder} />
							}
							#			
						</div>
					}

					{!showStudentLastName &&
						<div className="scoreSheet-students-header-col is--name" onClick={()=>{this.props.changeSorting('displayName')}}>
							Name								
							{sortedBy === 'displayName' &&
								<ScoreSheetStudentHeaderSortIcon sortOrder={sortOrder} />
							}
						</div>
					}

					{showStudentLastName &&
						<div className="scoreSheet-students-header-col is--firstName" onClick={()=>{this.props.changeSorting('firstName')}}>							
							First Name
							{sortedBy === 'firstName' &&
								<ScoreSheetStudentHeaderSortIcon sortOrder={sortOrder} />
							}								
						</div>
					}

					{showStudentLastName &&
						<div className="scoreSheet-students-header-col is--lastName" onClick={()=>{this.props.changeSorting('lastName')}}>							
							Last Name
							{sortedBy === 'lastName' &&
								<ScoreSheetStudentHeaderSortIcon sortOrder={sortOrder} />
							}								
						</div>
					}


					<div className="scoreSheet-students-header-col is--score" onClick={()=>{this.props.changeSorting('score')}}>						
						{sortedBy === 'score' &&
							<ScoreSheetStudentHeaderSortIcon sortOrder={sortOrder} />
						}
						Total						
					</div>
			</div>	
		)
	}
}

export default ScoreSheetStudentHeader



class ScoreSheetStudentHeaderSortIcon extends React.Component{
	render(){
		const {sortOrder} = this.props		
		return(									
				<div className={'scoreSheet-students-header-sortIcon' + 					
					(sortOrder ? ` scoreSheet-students-header-sortIcon--${sortOrder}` : '')}		
				>										
					<Icon name='chevron-small-up'/>
					<Icon name='chevron-small-down'/>
				</div>	
			)
	}
}

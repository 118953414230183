const env= process.env.REACT_APP_ENV
const isDev=env==='development'
if(isDev){

module.exports = {
	  allSubjects:{
	  	label:'All Subject Areas', 
			subjectId:'5db0c8ff6f74320011379afc',
			parentId:null	
   	 },
		computerScience:{	
			label:'Computer Science', 
			subjectId:'5db0c8876f74320011379af6',
			parentId:null,
			imageSearchPlaceholder:'computer code, silicon chip, data structures',
		},
		computerSkills:{	
			label:'Computer Skills', 
			subjectId:'5db0c88fae38d80013090b8c',
			parentId:null,
			imageSearchPlaceholder:'laptop, Gmail, Excel',
		},
		creativeArts:{	
			label:'Creative Arts', 
			subjectId:'5db0c89b6f74320011379af7',
			parentId:null,
			description:'including Art, Drama, and Music',
			imageSearchPlaceholder:'theatre, Mona Lisa, orchestra',
		},
		art:{	
			label:'Art', 
			subjectId:'5db0c8a8ae38d80013090b8d',
			parentId:'5db0c89b6f74320011379af7',
			imageSearchPlaceholder:'Munch Scream, Basquiat, Pointillism',
		},
		drama:{	
			label:'Drama', 
			subjectId:'5db0c8ae6f74320011379af8',
			parentId:'5db0c89b6f74320011379af7',
			imageSearchPlaceholder:'theatre, Ibsen, Stanislavski',
		},
		music:{	
			label:'Music', 
			subjectId:'5db0c8b4ae38d80013090b8e',
			parentId:'5db0c89b6f74320011379af7',
			imageSearchPlaceholder:'guitar, orchestra, conductor',
		},
		creativeArtsOther:{	
			label:'Other', 
			subjectId:'5db34494f172040011e3a2d5',
			parentId:'5db0c89b6f74320011379af7',
			imageSearchPlaceholder:'theatre, Mona Lisa, orchestra',
		},
		foreignLanguages:{	
			label:'Foreign Languages', 
			subjectId:'5db0c8316f74320011379af1',
			parentId:null,			
		},
		arabic:{	
			label:'Arabic', 
			subjectId:'5db0c84b6f74320011379af2',
			parentId:'5db0c8316f74320011379af1',			
		},
		chinese:{	
			label:'Chinese', 
			subjectId:'5db0c851ae38d80013090b87',
			parentId:'5db0c8316f74320011379af1',		
		},
		english:{	
			label:'English', 
			subjectId:'5db0c858ae38d80013090b88',
			parentId:'5db0c8316f74320011379af1',			
		},
		french:{	
			label:'French', 
			subjectId:'5db0c85d6f74320011379af3',
			parentId:'5db0c8316f74320011379af1',
		},
		german:{	
			label:'German', 
			subjectId:'5db0c863ae38d80013090b89',
			parentId:'5db0c8316f74320011379af1',			
		},
		italian:{	
			label:'Italian', 
			subjectId:'5db0c8696f74320011379af4',
			parentId:'5db0c8316f74320011379af1',			
		},
		japanese:{	
			label:'Japanese', 
			subjectId:'5db0c86eae38d80013090b8a',
			parentId:'5db0c8316f74320011379af1',			
		},
		latin:{	
			label:'Latin', 
			subjectId:'5db0c8746f74320011379af5',
			parentId:'5db0c8316f74320011379af1',		
		},
		signLanguage:{	
			label:'Sign Language', 
			subjectId:'5db0c843ae38d80013090b86',	
			parentId:'5db0c8316f74320011379af1',		
		},
		spanish:{	
			label:'Spanish', 
			subjectId:'5db0c87aae38d80013090b8b',
			parentId:'5db0c8316f74320011379af1',			
		},
		foreignLanguagesOther:{	
			label:'Other', 
			subjectId:'5db344a8eea85d001695404b',
			parentId:'5db0c8316f74320011379af1',			
		},
		languageArts:{	
			label:'Literature/Language Arts', 
			subjectId:'5db0c9116f74320011379afd',
			parentId:null,
			imageSearchPlaceholder:'Shakespeare, books, Harper Lee',
		},
		other:{	
			label:'Other', 
			subjectId:'5db0c906ae38d80013090b93',
			parentId:null,
			description:'Subject not listed',
		},
		math:{	
			label:'Math', 
			subjectId:'5db0c7ecae38d80013090b83',
			parentId:null,
			imageSearchPlaceholder:'cosine, Pythagoras, currency',
		},
		physicalEducation:{	
			label:'Physical Education', 
			subjectId:'5db0c8f5ae38d80013090b92',
			parentId:null,
			imageSearchPlaceholder:'Lebron, trampoline, Simone Biles',
		},
		science:{	
			label:'Science', 
			subjectId:'5db0c8016f74320011379aef',
			parentId:null,
			imageSearchPlaceholder:'DNA, space-time, Marie Curie',
		},
		earthScience:{	
			label:'Earth Science', 
			subjectId:'5ece3aa358745000111cd35f',
			parentId:'5db0c8016f74320011379aef',
			imageSearchPlaceholder:'mantle, earthquake, wave cut platform',
		},
		biology:{	
			label:'Biology', 
			subjectId:'5db0c80eae38d80013090b84',
			parentId:'5db0c8016f74320011379aef',
			imageSearchPlaceholder:'photosynthesis, cell structure, pod of dolphins',
		},
		chemistry:{	
			label:'Chemistry', 
			subjectId:'5db0c81a6f74320011379af0',
			parentId:'5db0c8016f74320011379aef',
			imageSearchPlaceholder:'chemical factory, DNA, compound model',
		},
		physics:{	
			label:'Physics', 
			subjectId:'5db0c820ae38d80013090b85',
			parentId:'5db0c8016f74320011379aef',
			imageSearchPlaceholder:'apple, fighter jet, space-time',
		},
		scienceOther:{	
			label:'Other', 
			subjectId:'5db344bceea85d001695404c',
			parentId:'5db0c8016f74320011379aef',
			imageSearchPlaceholder:'DNA, space-time, Marie Curie',
		},
		socialSciences:{	
			label:'Social Sciences', 
			subjectId:'5db0c8c06f74320011379af9',
			parentId:null,
			description:'including Economics, Geography, History, Government/Politics, and Psychology',
			imageSearchPlaceholder:'manufacturing, UN General Assembly, Acropolis',
		},
		economics:{	
			label:'Economics', 
			subjectId:'5db0c8d66f74320011379afa',
			parentId:'5db0c8c06f74320011379af9',
			imageSearchPlaceholder:'manufacturing, Federal Reserve, trading floor',
		},
		geography:{	
			label:'Geography', 
			subjectId:'5db0c8d1ae38d80013090b90',
			parentId:'5db0c8c06f74320011379af9',
			imageSearchPlaceholder:'global warming, Amazon, migration',
		},
		politics:{	
			label:'Government/Politics', 
			subjectId:'5db0c8dfae38d80013090b91',
			parentId:'5db0c8c06f74320011379af9',
			imageSearchPlaceholder:'Constitution, UN General Assembly, Angela Merkel',
		},
		history:{	
			label:'History', 
			subjectId:'5db0c8ccae38d80013090b8f',
			parentId:'5db0c8c06f74320011379af9',
			imageSearchPlaceholder:'Renaissance, Acropolis, Equiano',
		},
		psychology:{	
			label:'Psychology', 
			subjectId:'5db0c8e66f74320011379afb',
			parentId:'5db0c8c06f74320011379af9',
			imageSearchPlaceholder:'neurons, cupcake, Freud',
		},
		socialSciencesOther:{	
			label:'Other', 
			subjectId:'5db344cbf172040011e3a2d7',
			parentId:'5db0c8c06f74320011379af9',
			imageSearchPlaceholder:'manufacturing, Federal Reserve, trading floor',
		}
}
}
if(!isDev){
	module.exports = {
	 	 allSubjects:{
	  	label:'All Subject Areas', 
			subjectId:'5dbcc60c2f2e12001100ce0e',
			parentId:null	
   	 },
		computerScience:{	
			label:'Computer Science', 
			subjectId:'5dbcc591eea50700165ac958',
			parentId:null,
			imageSearchPlaceholder:'computer code, silicon chip, data structures',
		},
		computerSkills:{	
			label:'Computer Skills', 
			subjectId:'5dbcc59b2f2e12001100cc7b',
			parentId:null,
			imageSearchPlaceholder:'laptop, Gmail, Excel',
		},
		creativeArts:{	
			label:'Creative Arts', 
			subjectId:'5dbcc5a420bed70016832e3e',
			parentId:null,
			description:'including Art, Drama, and Music',
			imageSearchPlaceholder:'theatre, Mona Lisa, orchestra',
		},
		art:{	
			label:'Art', 
			subjectId:'5dbcc5b019140e0012104ed4',
			parentId:'5dbcc5a420bed70016832e3e',
			imageSearchPlaceholder:'Munch Scream, Basquiat, Pointillism',
		},
		drama:{	
			label:'Drama', 
			subjectId:'5dbcc5b6eea50700165ac9e1',
			parentId:'5dbcc5a420bed70016832e3e',
			imageSearchPlaceholder:'theatre, Ibsen, Stanislavski',
		},
		music:{	
			label:'Music', 
			subjectId:'5dbcc5ba2f2e12001100ccb4',
			parentId:'5dbcc5a420bed70016832e3e',
			imageSearchPlaceholder:'guitar, orchestra, conductor',
		},
		creativeArtsOther:{	
			label:'Other', 
			subjectId:'5dbcc5c019140e0012104f14',
			parentId:'5dbcc5a420bed70016832e3e',
			imageSearchPlaceholder:'theatre, Mona Lisa, orchestra',
		},
		foreignLanguages:{	
			label:'Foreign Languages', 
			subjectId:'5dbcc54219140e0012104d96',
			parentId:null,			
		},
		arabic:{	
			label:'Arabic', 
			subjectId:'5dbcc55620bed70016832ce0',
			parentId:'5dbcc54219140e0012104d96',			
		},
		chinese:{	
			label:'Chinese', 
			subjectId:'5dbcc55ba5f33b0011f9e92a',
			parentId:'5dbcc54219140e0012104d96',		
		},
		english:{	
			label:'English', 
			subjectId:'5dbcc56120bed70016832d1a',
			parentId:'5dbcc54219140e0012104d96',			
		},
		french:{	
			label:'French', 
			subjectId:'5dbcc56620bed70016832d21',
			parentId:'5dbcc54219140e0012104d96',
		},
		german:{	
			label:'German', 
			subjectId:'5dbcc56bf2cbe10011b2bb2d',
			parentId:'5dbcc54219140e0012104d96',			
		},
		italian:{	
			label:'Italian', 
			subjectId:'5dbcc5702f2e12001100cc2e',
			parentId:'5dbcc54219140e0012104d96',			
		},
		japanese:{	
			label:'Japanese', 
			subjectId:'5dbcc5752f2e12001100cc2f',
			parentId:'5dbcc54219140e0012104d96',			
		},
		latin:{	
			label:'Latin', 
			subjectId:'5dbcc57aeea50700165ac92a',
			parentId:'5dbcc54219140e0012104d96',		
		},
		signLanguage:{	
			label:'Sign Language', 
			subjectId:'5dbcc54eeea50700165ac89f',
			parentId:'5dbcc54219140e0012104d96',			
		},
		spanish:{	
			label:'Spanish', 
			subjectId:'5dbcc57feea50700165ac943',
			parentId:'5dbcc54219140e0012104d96',			
		},
		foreignLanguagesOther:{	
			label:'Other', 
			subjectId:'5dbcc584eea50700165ac946',
			parentId:'5dbcc54219140e0012104d96',			
		},
		languageArts:{	
			label:'Literature/Language Arts', 
			subjectId:'5dbcc50e20bed70016832b93',
			parentId:null,
			imageSearchPlaceholder:'Shakespeare, books, Harper Lee',
		},
		other:{	
			label:'Other', 
			subjectId:'5dbcc613f2cbe10011b2bda7',
			parentId:null,
			description:'Subject not listed',
		},
		math:{	
			label:'Math', 
			subjectId:'5dbcc502f2cbe10011b2b999',
			parentId:null,
			imageSearchPlaceholder:'cosine, Pythagoras, currency',
		},
		physicalEducation:{	
			label:'Physical Education', 
			subjectId:'5dbcc6022f2e12001100cdfe',
			parentId:null,
			imageSearchPlaceholder:'Lebron, trampoline, Simone Biles',
		},
		science:{	
			label:'Science', 
			subjectId:'5dbcc5182f2e12001100caee',
			parentId:null,
			imageSearchPlaceholder:'DNA, space-time, Marie Curie',
		},
		earthScience:{	
			label:'Earth Science', 
			subjectId:'5ece3e8bc4f19400168248f6',
			parentId:'5dbcc5182f2e12001100caee',
			imageSearchPlaceholder:'mantle, earthquake, wave cut platform',
		},
		biology:{	
			label:'Biology', 
			subjectId:'5dbcc52520bed70016832c2e',
			parentId:'5dbcc5182f2e12001100caee',
			imageSearchPlaceholder:'photosynthesis, cell structure, school of fish',
		},
		chemistry:{	
			label:'Chemistry', 
			subjectId:'5dbcc52a20bed70016832c36',
			parentId:'5dbcc5182f2e12001100caee',
			imageSearchPlaceholder:'chemical factory, DNA, compound model',
		},
		physics:{	
			label:'Physics', 
			subjectId:'5dbcc52eeea50700165ac83c',
			parentId:'5dbcc5182f2e12001100caee',
			imageSearchPlaceholder:'apple, fighter jet, space-time',
		},
		scienceOther:{	
			label:'Other', 
			subjectId:'5dbcc534eea50700165ac842',
			parentId:'5dbcc5182f2e12001100caee',
			imageSearchPlaceholder:'DNA, space-time, Marie Curie',
		},
		socialSciences:{	
			label:'Social Sciences', 
			subjectId:'5dbcc5caf2cbe10011b2bc40',
			parentId:null,
			description:'including Economics, Geography, History, Government/Politics, and Psychology',
			imageSearchPlaceholder:'manufacturing, UN General Assembly, Acropolis',
		},
		economics:{	
			label:'Economics', 
			subjectId:'5dbcc5e0a5f33b0011f9ead6',
			parentId:'5dbcc5caf2cbe10011b2bc40',
			imageSearchPlaceholder:'manufacturing, Federal Reserve, trading floor',
		},
		geography:{	
			label:'Geography', 
			subjectId:'5dbcc5daeea50700165aca96',
			parentId:'5dbcc5caf2cbe10011b2bc40',
			imageSearchPlaceholder:'global warming, Amazon, migration',
		},
		politics:{	
			label:'Government/Politics', 
			subjectId:'5dbcc5ea19140e0012104fd9',
			parentId:'5dbcc5caf2cbe10011b2bc40',
			imageSearchPlaceholder:'Constitution, UN General Assembly, Angela Merkel',
		},
		history:{	
			label:'History', 
			subjectId:'5dbcc5d5eea50700165aca89',
			parentId:'5dbcc5caf2cbe10011b2bc40',
			imageSearchPlaceholder:'Renaissance, Acropolis, Equiano',
		},
		psychology:{	
			label:'Psychology', 
			subjectId:'5dbcc5f219140e0012104ffd',
			parentId:'5dbcc5caf2cbe10011b2bc40',
			imageSearchPlaceholder:'neurons, cupcake, Freud',
		},
		socialSciencesOther:{	
			label:'Other', 
			subjectId:'5dbcc5f8a5f33b0011f9eb1d',
			parentId:'5dbcc5caf2cbe10011b2bc40',
			imageSearchPlaceholder:'manufacturing, Federal Reserve, trading floor',
		}
}
}

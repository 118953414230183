import { nanoid } from 'nanoid'
import {
  BroadcastChannel,
  createLeaderElection,
} from 'broadcast-channel'
import * as Sentry from '@sentry/browser'

export class CrossTabService {
  constructor(
    name,
    onLeadership,
  ) {
    this.name = name
    this.channel = new BroadcastChannel(name)
    this.election = createLeaderElection(this.channel)
    this.election.onduplicate = () => {
      Sentry.withScope(() => {
        Sentry.captureException(new Error(`Have duplicate leaders on cross-tab service ${this.name}`))
      })
    }
    this.election.awaitLeadership().then(() => {
      const state = this.getState()
      onLeadership(state, (state) => {
        if (this.election.isLeader) {
          localStorage.setItem(this.name, JSON.stringify(state))
        }
      })
    })
  }

  getState() {
    return JSON.parse(localStorage.getItem(this.name))
  }
}

export class IntervalSequenceCrossTabServiceLeader {
  constructor(
    state,
    saveState,
    logger,
    action,
    options, // { backoffIntervals: [Number], retryInterval: Number }
  ) {
    this.saveState = () => {
      saveState(this.state)
    }
    this.logger = logger
    this.action = action
    this.options = options

    this.initializeState(state)

    this.scheduleNextAction()
  }

  log(message) {
    const state = this.state || {}
    const runSummary = `run ${state.serviceRunId}:${String(state.runnerSequenceId).padStart(3, '0')}`
    this.logger(`${runSummary} ${message}`)
  }

  initializeState(state) {
    const timeNow = new Date().getTime()
    if (state && state.nextTargetTime > timeNow) {
      // next action is scheduled in the future
      // resume the existing run
      this.state = {
        ...state,
        runnerSequenceId: state.runnerSequenceId + 1,
      }
      this.pushInterval(this.state.nextTargetTime - timeNow)
    } else {
      // next action was scheduled in the past (the application was closed probably)
      // start a new run
      this.state = {
        serviceRunId: nanoid(10),
        runnerSequenceId: 1,
      }
      this.resetIntervals()
    }
  }

  async runAction() {
    this.log('running scheduled action...')
    const actionContext = {
      serviceRunId: this.state.serviceRunId,
      runnerSequenceId: this.state.runnerSequenceId,
    }
    const succeeded = await this.action(actionContext)
    if (!succeeded) {
      this.log(`scheduled action failed, retry in ${this.options.retryInterval}ms`)
      this.pushInterval(this.options.retryInterval)
    }
    this.log('...complete')
  }

  scheduleNextAction() {
    const interval = this.popInterval()
    setTimeout(async () => {
      await this.runAction()
      this.scheduleNextAction()
    }, interval)
    this.state.nextTargetTime = new Date().getTime() + interval
    // persist state
    this.saveState()
    this.log(`scheduled next action in ${interval}ms`)
  }

  popInterval() {
    if (this.state.upcomingIntervals.length === 1) {
      return this.state.upcomingIntervals[0]
    }
    return this.state.upcomingIntervals.shift()
  }

  pushInterval(interval) {
    this.state.upcomingIntervals.unshift(interval)
  }

  resetIntervals() {
    this.state.upcomingIntervals = this.options.backoffIntervals
  }
}

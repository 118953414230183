import editorSchema from '../../schema/editorSchema'
import {generateRandQuestionId} from '../../../utils/generateRandQuestionId'
import {getNewQuestionTemplateDeleteMedia} from '../getNewQuestionTemplate'
import {updateQuestionOrderTransaction} from '../updateQuestionOrderTransaction'
import {setQuestionBodySelectionTransaction} from '../setQuestionBodySelectionTransaction'
import {convertStandaloneQuestionToPMNode} from '../convertSetToPMNode'
import {stopAllMediaPlayback} from './stopAllMediaPlayback'


export function addQuestionToSet(onAddQuestionToSet,copiedQuestion){
	stopAllMediaPlayback()
	const editorView=window.view
	let activeSlidePosEnd
	let activeQuestionTemplate //add new question with template of current active question
	let slideWidth
	let questionIndex
	let activeQuestionId
	let oldOrderArray

	//find position of active question node and insert new question after active question
	editorView.state.doc.nodesBetween(0,editorView.state.doc.content.size, (node, pos) => {
		if(node.type.name === "question"){
			if(!questionIndex && questionIndex !==0){ //if no questionIndex yet then set it to 0
				questionIndex=0
			}else{
				questionIndex+=1
			}
			if(!oldOrderArray){
				oldOrderArray=node.attrs.questionOrderArray
			}
		}
		if(node.attrs.activeslide && node.attrs.activeslide===true){
			activeSlidePosEnd=pos+node.nodeSize
			activeQuestionTemplate=node.attrs.template
			activeQuestionId=node.attrs.questionid
			slideWidth=node.attrs.slideWidth
		}
	})

	//find template "closest" to active question template
	let newQuestionTemplate=getNewQuestionTemplateDeleteMedia(activeQuestionTemplate)
	const newQuestionId=generateRandQuestionId()

	//make new array of question ids in order
	let newArray=[]
	oldOrderArray.forEach((questionId)=>{
		newArray.push(questionId)
		if(questionId===activeQuestionId){
			newArray.push(newQuestionId)
		}
	})

	let newQuestionNode
	if(!copiedQuestion){ //if there is not a copied question then use blank question
		let type = editorSchema.nodes.question
		newQuestionNode=type.createAndFill({
			slideWidth:slideWidth,
			template:newQuestionTemplate,
			questionid:newQuestionId,
			questionOrderArray:newArray},editorSchema.nodes.choiceGroup.create(null,[
			editorSchema.nodes.choice.createAndFill({
										"correct": true,
										"isSurvey": false,
										"choiceIndex": 0,
										"choiceImage": null,
										"fontSize": 42,
										"template":newQuestionTemplate
										},
					 editorSchema.nodes.choiceMediaEmptyState.createAndFill({
										"slideWidth": slideWidth,
										})
										),
			 editorSchema.nodes.choice.createAndFill({
										"correct": false,
										"isSurvey": false,
										"choiceIndex": 1,
										"choiceImage": null,
										"fontSize": 42,
										"template":newQuestionTemplate
									}, editorSchema.nodes.choiceMediaEmptyState.createAndFill({
										"slideWidth": slideWidth,
										})),
				editorSchema.nodes.choice.createAndFill({
										"correct": false,
										"isSurvey": false,
										"choiceIndex": 2,
										"choiceImage": null,
										"fontSize": 42,
										"template":newQuestionTemplate
									}, editorSchema.nodes.choiceMediaEmptyState.createAndFill({
										"slideWidth": slideWidth,
										})),
				 editorSchema.nodes.choice.createAndFill({
										"correct": false,
										"isSurvey": false,
										"choiceIndex": 3,
										"choiceImage": null,
										"fontSize": 42,
										"template":newQuestionTemplate
									}, editorSchema.nodes.choiceMediaEmptyState.createAndFill({
										"slideWidth": slideWidth,
										})),

			]))
	}else{
		newQuestionNode=convertStandaloneQuestionToPMNode(copiedQuestion,newQuestionId,slideWidth)
	}

	let tr=editorView.state.tr
	tr.setMeta("triggerParseDoc", true) 
	tr.setMeta("type","addQuestion")
	tr.insert(activeSlidePosEnd,newQuestionNode)
	tr=updateQuestionOrderTransaction(tr,newArray) //update with new question order array
	let questionNode
	let questionPos
	
	tr.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if(node.type.name === "question"){
			if(node.attrs.questionid===newQuestionId){
				questionNode=node
				questionPos=pos
				tr.setNodeMarkup(pos, null, {...node.attrs,activeslide:true})
			}else{
				tr.setNodeMarkup(pos, null, {...node.attrs,activeslide:false})
			}
		}})     

	tr=setQuestionBodySelectionTransaction(tr,questionNode,questionPos)
	window.view.dispatch(tr)
	onAddQuestionToSet(newQuestionId)//callback on added question to e.g. lock set
}


const ChoiceImageNodeSpec = {
  attrs: {
    fileId:{default:''},
    addedAt:{default:''},
    title:{default:''},
    attribution:{default:''},
    deliveryUrl:{default:''},
    deliveryDimensions:{default:null},
    originalDimensions:{default:null},
    imageFill:{default:true},
    crop:{default:null}, 
    rotate:{default:{
      angle:0, 
      flipH:false,
      flipV:false
    }},
    markup:{default:{covers:[],markers:[]}},
    colorAdjust:{default:{
      brightness:0,
      saturation:0,
      contrast:0
    }},
    nodeId:{default:''},
    placeholderImage:{default:''},
    placeholderDimensions:{default:{h:'1',w:'1'}},
    questionChoiceCount: {default:4},
    slideWidth:{default:''}
  },
  selectable:false,
  draggable:false,
  isolating: true,
  group: 'block',
  parseDOM: [{tag: 'choiceImage'}],
  toDOM(node) {
    return ['choiceImage']},
}
export default ChoiceImageNodeSpec
  
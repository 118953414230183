import React from 'react'

class ReportPackModalHeader extends React.Component{
	render(){	
		const {set}=this.props

		
		return(			
			<div className='featureModal-header'> 
				<div className='featureModal-header-h1'> 				
					Print Set Handout
				</div>
				<div className='featureModal-header-h2'> 				
					{set? set.name:''}
				</div>
				<div className='featureModal-header-h3'> 				

				</div>				
			</div>
		)
	}
}
export default ReportPackModalHeader



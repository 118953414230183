import React from 'react'
import { Spring } from  'react-spring/renderprops'

class EditorSoundUploadingPage extends React.Component{  

  render(){  
  	const {uploadingProgress} = this.props
  	const widthProgressBar = 520
  	const xTranslateOffset = widthProgressBar * -1 * (1 - uploadingProgress)
	  return (	  	
			<div className='editor-insertSoundModal editor-insertSoundModal--uploading'>
				<div className='editor-insertSoundModal--uploading-progressArea'>
	        <button className='editor-insertSoundModal--uploading-progressArea-cancelBtn' onClick={this.props.closeInsertSoundModal}>
	          Cancel
	        </button>
	        <div className='editor-insertSoundModal--uploading-progressArea-progressBar'>
	        	<Spring              		
							to={{			          
			          xTranslate: uploadingProgress ? xTranslateOffset : 0,
			        }}					        
			      >
			        {props => 
		        		<div 
		        			style={{transform: `translateX(${props.xTranslate}px)`}}
									className='editor-insertSoundModal--uploading-progressArea-progressBar-bar'>
										<div className='editor-insertSoundModal--uploading-progressArea-progressBar-bar-shimmer' />
								</div>				
		      		}													
						</Spring>
	          {/*<div style={{width: `${uploadingProgress * 100}%`}} className='editor-insertSoundModal--uploading-progressArea-progressBar-bar' /> */}
	          <div className='editor-insertSoundModal--uploading-progressArea-progressBar-rail' />
	        </div>
				</div>
				<div className='editor-insertSoundModal--uploading-footer'>
					<div className='editor-insertSoundModal--uploading-fileName'>
						<div className='editor-insertSoundModal--uploading-fileName-name'>
							{this.props.fileName}							
						</div>								
						<div className='editor-insertSoundModal--uploading-fileName-label'>
							Uploading
						</div>
					</div>
				</div>
			</div>    			     	
	  )
	}
}

export default EditorSoundUploadingPage

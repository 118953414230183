import React from 'react'
import Dropdown from '../uiKit/Dropdown'
import DropdownItem from '../uiKit/DropdownItem'
import DropdownDivider from '../uiKit/DropdownDivider'
import DropdownSelectItem from '../uiKit/DropdownSelectItem'
import Icon from '../misc/Icons'
import ProfilePic from '../ProfilePic'
import {getDisplayNameForUser} from '../../utils/getDisplayNameForUser'
import permissionRoleDescriptions from '../../staticData/permissionRoleDescriptions.js'    

class PermissionRowEditButton extends React.Component{

	render(){
		const {onClickFunction, permissionRole}=this.props
		
		return(
			<div className='repoInvitationModal-permissionsList-permissionRow-editBtn' onClick={onClickFunction}>
				{permissionRole === 'viewer' ? 'can view' : ''}
				{permissionRole === 'editor' ? 'can edit' : ''}
				{permissionRole === 'admin' ? 'admin' : ''}
				<Icon name='chevron-small-down' />			
			</div>

		)
	}
} 
 

class PermissionRow extends React.Component{

	constructor(props) {
		super(props)
		this.changePermissionRole=this.changePermissionRole.bind(this)


		this.state = {
			permissionRole: props.permission.role,	
			YPosDropdownButton: null	
		}  
	}

	componentDidMount(){
		const el =this.rowDiv
		if(el){
			const rect = el.getBoundingClientRect()
			this.setState({YPosDropdownButton:rect.top})
		}	
	}


	changePermissionRole(role){
		this.setState({permissionRole:role})
		this.props.updatePermission(this.props.permission,role)
	}


	render(){	
		const {permission,isCurrentUser,currentUserPermissionRole}=this.props
		const {user}=permission
		const isJoined = permission.joined && !isCurrentUser
		const isContact=user
		const isNotJoinedEmailOnly = !isJoined && !isContact && !isCurrentUser
		const isNotJoinedContact = !isJoined && isContact && !isCurrentUser
		const permissionRole=this.state.permissionRole
		
		let fullName=''
		if(user){
			fullName= getDisplayNameForUser(user,true)
		}

		// DETERMINE IF DROPDOWN NEEDS TO BE INVERTED		
		
		const {YPosDropdownButton} = this.state // measured from top of window
		const windowHeight = window.innerHeight // height of browser window
		const maxWindowHeightModal = 940 // repo invitation modal heightmaxes at a certain height

		// Hardcoded layout values
		let dropdownHeight

		if(isJoined){
			dropdownHeight = 248 // four button dropdown
		}
		else{
			dropdownHeight = 212 // three button dropdown, unfortunately hardcoded, accounts for buffers
		}
		

		
		let dropdownInverted = true

		if(YPosDropdownButton && ((Math.min(windowHeight, maxWindowHeightModal) - (YPosDropdownButton + 64)) > dropdownHeight)){
			dropdownInverted = false
		}

		// Y Position of Dropdown
		let dropdownYPosTop
		let dropdownYPosBottom

		if(YPosDropdownButton && dropdownInverted){			
			dropdownYPosBottom = windowHeight - YPosDropdownButton - 15
			dropdownYPosTop = null
		}

		if(YPosDropdownButton && !dropdownInverted){
			dropdownYPosTop = YPosDropdownButton + 50
			dropdownYPosBottom = null
		}
			

		return(
			<div ref={c => {this.rowDiv = c}}  className={'repoInvitationModal-permissionsList-permissionRow ' + (isJoined ? ' repoInvitationModal-permissionsList-permissionRow--isJoined ' : ' repoInvitationModal-permissionsList-permissionRow--notJoined ')}>
				<div className='repoInvitationModal-permissionsList-permissionRow--left'>				
					<ProfilePic size='36' borderRadius='4' user={user} />					
				</div>

				<div className='repoInvitationModal-permissionsList-permissionRow--center'>

					<div className={'repoInvitationModal-permissionsList-permissionRow-headLine' + (isNotJoinedEmailOnly ? ' repoInvitationModal-permissionsList-permissionRow-headLine--noSubLine' : '')}>											
						{(isCurrentUser || isJoined || isNotJoinedContact) && <span>{fullName}</span> }						
						{isNotJoinedEmailOnly && <span>{permission.originalEmail}</span> }						
					</div>
					<div className='repoInvitationModal-permissionsList-permissionRow-subLine'>
						{(isCurrentUser || isJoined || isNotJoinedContact) && <span>{user.email}</span> }						
					</div>					

				</div>				
					
				<div className='repoInvitationModal-permissionsList-permissionRow--right'>
					
					{!isJoined && !isCurrentUser &&
						<div>
						<Dropdown inverted={dropdownInverted} dropdownYPosTop={dropdownYPosTop} dropdownYPosBottom={dropdownYPosBottom} dropdownButton={<PermissionRowEditButton permissionRole={permissionRole} />} >							
							<DropdownSelectItem label='Can edit' sublabel={permissionRoleDescriptions.editor} multiline multilineHeight='3' isActive={permissionRole==='editor'} onSelect={()=>{this.changePermissionRole('editor')}} />
							<DropdownSelectItem label='Can view' sublabel={permissionRoleDescriptions.viewer} multiline multilineHeight='2' isActive={permissionRole==='viewer'} onSelect={()=>{this.changePermissionRole('viewer')}} />
							<DropdownDivider />
							<DropdownItem warning label='Cancel invite' onSelect={()=>{this.props.deletePermission(permission)}} />
						</Dropdown>	
						</div>		
					}


					{isJoined && currentUserPermissionRole ==='admin' && !isCurrentUser &&
						<div>
						<Dropdown inverted={dropdownInverted} dropdownYPosTop={dropdownYPosTop} dropdownYPosBottom={dropdownYPosBottom} dropdownButton={<PermissionRowEditButton permissionRole={permissionRole} />} >							
							<DropdownSelectItem label='Admin' sublabel={permissionRoleDescriptions.admin} multiline multilineHeight='2' isActive={permissionRole==='admin'}  onSelect={()=>{this.changePermissionRole('admin')}}  />							 
							<DropdownSelectItem label='Can edit' sublabel={permissionRoleDescriptions.editor} multiline multilineHeight='3' isActive={permissionRole==='editor'} onSelect={()=>{this.changePermissionRole('editor')}} />
							<DropdownSelectItem label='Can view' sublabel={permissionRoleDescriptions.viewer} multiline multilineHeight='2' isActive={permissionRole==='viewer'} onSelect={()=>{this.changePermissionRole('viewer')}} />
							<DropdownDivider />
							<DropdownItem warning label='Remove' onSelect={()=>{this.props.deletePermission(permission)}} />
						</Dropdown>	
						</div>		
					}

					{(isCurrentUser || (isJoined && !isCurrentUser && currentUserPermissionRole ==='editor')) &&
						<div className='repoInvitationModal-permissionsList-permissionRow-editBtn repoInvitationModal-permissionsList-permissionRow-editBtn--isYou'>
							{permissionRole==='editor' ? 'can edit' : ''}	
							{permissionRole==='viewer' ? 'can view' : ''}								
							{permissionRole==='admin' ? 'admin' : ''}
						</div>
					}					

				</div>

			</div>

		)
	}
}




export default PermissionRow

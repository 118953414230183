const pageHeaderHeight=85

//for print set handout print preview
export function paginateQuestionsForPreview(set,ghostHeights,columnsPerPage) {
	const availablePageHeight = ghostHeights.spacer
	const availablePageHeightFirstPage=availablePageHeight-pageHeaderHeight
	let questionsByPage={}
	let pageIndex=0
	let currentPage={}
	let currentColumnData=[]
	let currentColumn=1
	let currentColumnHeight=0
	let currentMaxHeight=availablePageHeightFirstPage

	if(set){
		set.questions.map((question,index) => {		
			const ghostHeight=ghostHeights[question.questionId]
			let newColumnHeight=currentColumnHeight+ghostHeight
			if(newColumnHeight<currentMaxHeight){
				currentColumnData.push(question)
				currentColumnHeight+=ghostHeight					
			}else{
				currentPage[`column-${currentColumn}`]=currentColumnData
				if(currentColumn<columnsPerPage){
					currentColumn +=1
					currentColumnData=[]
					currentColumnData.push(question)
					currentColumnHeight=ghostHeight
				}else{
					currentColumn=1
					currentColumnData=[]
					currentColumnData.push(question)
					currentColumnHeight=ghostHeight
					questionsByPage[pageIndex]=currentPage
					currentPage={}
					pageIndex+=1
					currentMaxHeight=availablePageHeight
				}	
			}
			if(index===set.questions.length-1){
				currentPage[`column-${currentColumn}`]=currentColumnData
				questionsByPage[pageIndex]=currentPage
			}
			return null
		})
	}
	return questionsByPage
}



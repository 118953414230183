import React from 'react'
/* eslint-disable */
import MathQuill from 'node-mathquill/build/mathquill.js' // do not remove import
/* eslint-enable */
import katex from 'katex'
import MathIcon from '../../../../misc/MathIcons'
import { stopAllMediaPlayback } from '../../../../../prosemirror/utils/editorActions/stopAllMediaPlayback'
import { cleanPastedEquationCharacter } from './cleanPastedEquationCharacter'

const EquationEditorButton = ({
  name, label, iconName, command, btnSize, tooltipLabel, tooltipKeyHint, wideTooltip, extraWideTooltip, extraExtraWideTooltip, clickFunction,
}) => (
  <button className={`equationEditor-controls-btn${name ? ` equationEditor-controls-btn--${name}` : ''}${btnSize ? ` equationEditor-controls-btn--${btnSize}` : ''}`} onClick={() => { clickFunction(command) }}>
    {label && (
      <span>
        {' '}
        {label}
        {' '}
      </span>
    )}
    {iconName &&
    <MathIcon name={iconName} />}
    {tooltipLabel && (
      <div className={`equationEditor-controls-btn-tooltip${wideTooltip ? ' equationEditor-controls-btn-tooltip--wide' : ''}${extraWideTooltip ? ' equationEditor-controls-btn-tooltip--extraWide' : ''}${extraExtraWideTooltip ? ' equationEditor-controls-btn-tooltip--extraExtraWide' : ''}`}>
        <div className='equationEditor-controls-btn-tooltip-label'>
          {tooltipLabel ? <React.Fragment>{tooltipLabel}</React.Fragment> : <React.Fragment>{label}</React.Fragment>}
        </div>

        {tooltipKeyHint && (
        <div className='equationEditor-controls-btn-tooltip-latex'>
          {tooltipKeyHint}
        </div>
        )}

        {!tooltipKeyHint && command && (
        <div className='equationEditor-controls-btn-tooltip-latex'>
          {command.latexString || command.latexCommand}
        </div>
        )}
      </div>
    )}
  </button>
)

class MathQuillEquationEditor extends React.Component {
  constructor(props) {
    super(props)
    this.writeLatex = this.writeLatex.bind(this)
    this.onButtonClick = this.onButtonClick.bind(this)
    this.executeLatexCommand = this.executeLatexCommand.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.handlePaste = this.handlePaste.bind(this)
    this.checkIsKatexValid = this.checkIsKatexValid.bind(this)
    this.handleInsertEquationButtonClick = this.handleInsertEquationButtonClick.bind(this)
    this.handleUpdateLatexButtonClick = this.handleUpdateLatexButtonClick.bind(this)
    this.state = {
      katexError: false,
    }
  }

  componentDidMount() {
    stopAllMediaPlayback()
    const mathFieldSpan = document.getElementById('math-field')
    const latexSpan = document.getElementById('latex')
    const MQ = window.MathQuill.getInterface(2) // for backcompat
    const mathField = MQ.MathField(mathFieldSpan, {
      autoCommands: 'frac subset supset cdot divide times sqrt nthroot leq geq neq nless ngtr approx rightarrow leftarrow leftrightarrow degree angle sum int infinity pi alpha beta gamma delta epsilon varepsilon eta theta iota kappa lambda mu xi rho sigma omega Gamma Delta Theta Lambda Pi Psi Sigma Omega',
      autoOperatorNames: 'tan cos sin log ln arcsin arccos arctan cot sec csc max min sinh cosh tanh coth lim mod lg exp distance',
      handlers: {
        edit: function () { // useful event handlers
          latexSpan.textContent = mathField.latex()
          this.props.onChangeLatex(mathField.latex())
          this.checkIsKatexValid(mathField.latex())
        }.bind(this),
      },
    })
    this.mathField = mathField
    if (this.props.latex) {
      mathField.latex(this.props.latex)
    }
    this.mathField.focus()
    if (window.analytics) {
      window.analytics.page('Equation editor modal', {
        isInsert: !!this.props.isNewNode,
        openedWithShortcut: !!this.props.openedWithShortcut,
      })
    }
  }

  checkIsKatexValid(newLatex) { // check is valid KaTex and show a warning if its not
    try {
      katex.renderToString(newLatex)
      this.setState({ katexError: false })
    } catch (e) {
      this.setState({ katexError: true })
    }
  }

  handlePaste(event) {
    event.preventDefault()// prevent pasting
    const text = (event.originalEvent || event).clipboardData.getData('text/plain')
    let newText = ''
    Array.from(text).forEach((ch) => {
      newText += cleanPastedEquationCharacter(ch)
    })
    this.mathField.write(newText)
    this.mathField.focus()
  }

  onButtonClick(command) {
    if (command.latexString) {
      this.writeLatex(command.latexString)
    } else if (command.latexCommand) {
      this.executeLatexCommand(command.latexCommand)
    }
    if (command.keyAfter) {
      command.keyAfter.forEach((key) => {
        this.mathField.keystroke(key)
      })
      this.mathField.focus()
    }
  }

  executeLatexCommand(command) {
    this.mathField.cmd(command)
    this.mathField.focus()
  }

  writeLatex(str) {
    this.mathField.write(str)
    this.mathField.focus()
  }

  handleOnClick() { // keep mathfield focused
    this.mathField.focus()
  }

  handleInsertEquationButtonClick(e) {
    e.preventDefault()
    e.stopPropagation()
    this.props.insertMathNode(this.props.latex, this.props.openedWithShortcut)
  }

  handleUpdateLatexButtonClick(e) {
    if (window.analytics) {
      window.analytics.track('Edit Equation', {
        oldLatex: this.props.initialLatex,
        newLatex: this.props.latex,
        isAdvancedInput: false,
      })
    }
    e.preventDefault()
    e.stopPropagation()
    this.props.updateLatexValue(this.props.latex)
  }

  render() {
    const { isNewNode, openedWithShortcut } = this.props
    const isEmpty = !this.props.latex
    const { katexError } = this.state
    const openedFromToolbar = isNewNode && !openedWithShortcut
    let isUpdateChanges = false
    if (!isNewNode && this.props.latex !== this.props.initialLatex) {
      isUpdateChanges = true
    }

    return (
      <div className='equationModalContainer' onClick={this.handleOnClick}>
        {isNewNode && openedFromToolbar && (
        <div className='equationModal-topBtnRow-fastTip'>
          Tip: Insert equations faster by typing
          {' '}
          <span>$$</span>
          {' '}
          in the editor
        </div>
        )}
        <div className='equationModal notranslate'>
          <div className='equationModal-topBtnRow'>
            {isNewNode && (
            <button onClick={this.props.showAdvancedEditor} className='equationModal-topBtnRow-btn'>
              Advanced Equation
            </button>
            )}
            <a className='equationModal-topBtnRow-link' target='_blank' rel='noopener noreferrer' href='https://katex.org/docs/supported.html'>
              Full Command List
            </a>
            <a className='equationModal-topBtnRow-link' target='_blank' rel='noopener noreferrer' href='https://help.plickers.com/hc/en-us/articles/4405865875099'>
              Help
            </a>
            <button onClick={this.props.cancel} className='equationModal-topBtnRow-btn equationModal-topBtnRow-btn--cancel'>
              Cancel
            </button>
          </div>
          <div className='equationModal-inputContainer' id='math-field' onPaste={this.handlePaste} />
          {' '}
          {/* INPUT AREA */}
          <div className='equationModal-latexOutput'>
            LaTeX of what you typed:
            {' '}
            <span id='latex' />
          </div>
          {' '}
          {/* DEV */}
          <div className='equationModal-inputHintContainer'>
            <div className={`equationModal-inputHint${(isNewNode && isEmpty) ? ' equationModal-inputHint--show' : ' equationModal-inputHint--hide'}`}>
              Try typing math or use the buttons below
            </div>
          </div>
          <div className='equationEditor-controls-top'>
            <div className='equationEditor-controls-top-btnGroup'>
              <EquationEditorButton
                name='fraction'
                iconName='fraction'
                command={{
                  latexCommand: '/',
                  latexString: null,
                  keyAfter: null,
                }}
                tooltipLabel='Fraction'
                tooltipKeyHint='/'
                btnSize='large'
                clickFunction={this.onButtonClick}
              />
            </div>
            <div className='equationEditor-controls-top-btnGroup'>
              <EquationEditorButton
                name='brackets'
                iconName='brackets'
                command={{
                  latexCommand: '(',
                }}
                tooltipLabel='Brackets'
                tooltipKeyHint='('
                btnSize='large'
                clickFunction={this.onButtonClick}
              />
            </div>
            <div className='equationEditor-controls-top-btnGroup'>
              <EquationEditorButton
                name='sup2'
                iconName='sup2'
                command={{
                  latexString: '^{2}',
                }}
                btnSize='large'
                tooltipLabel='Squared'
                tooltipKeyHint='^2'
                clickFunction={this.onButtonClick}
              />

              <EquationEditorButton
                iconName='sup3'
                command={{
                  latexString: '^{3}',
                }}
                tooltipLabel='Cubed'
                tooltipKeyHint='^3'
                btnSize='large'
                clickFunction={this.onButtonClick}
              />

              <EquationEditorButton
                name='supn'
                iconName='supn'
                command={{
                  latexCommand: null,
                  latexString: '^{}',
                  keyAfter: ['Left'],
                }}
                tooltipLabel='Exponent'
                tooltipKeyHint='^'
                btnSize='large'
                clickFunction={this.onButtonClick}
              />
            </div>
            <div className='equationEditor-controls-top-btnGroup'>
              <EquationEditorButton
                name='equals'
                label='='
                command={{
                  latexString: '=',
                }}
                tooltipLabel='Equals'
                tooltipKeyHint='='
                btnSize='large'
                clickFunction={this.onButtonClick}
              />
            </div>
            <div className='equationEditor-controls-top-btnGroup'>
              <EquationEditorButton
                name='large-pi'
                label='π'
                command={{
                  latexString: '\\pi',
                }}
                tooltipLabel='pi'
                tooltipKeyHint='pi'
                btnSize='large'
                clickFunction={this.onButtonClick}
              />

              <EquationEditorButton
                iconName='infinity'
                command={{
                  latexString: '\\infinity',
                }}
                btnSize='large'
                tooltipLabel='Infinity'
                tooltipKeyHint='infinity'
                clickFunction={this.onButtonClick}
              />

            </div>

            <div className='equationEditor-controls-top-btnGroup'>
              <EquationEditorButton
                iconName='textMode'
                command={{
                  latexCommand: null,
                  latexString: '\\mathrm{ }',
                  keyAfter: ['Left'],
                }}
                btnSize='large'
                tooltipLabel='Text Mode'
                tooltipKeyHint='\mathrm'
                clickFunction={this.onButtonClick}
              />

            </div>

            <div className='equationEditor-controls-top-btnGroup'>

              <EquationEditorButton
                name='sum'
                iconName='sum'
                command={{
                  latexCommand: null,
                  latexString: '\\sum',
                  keyAfter: ['Up'],
                }}
                btnSize='large'
                tooltipLabel='Sum'
                tooltipKeyHint='sum'
                clickFunction={this.onButtonClick}
              />

              {/* MAYBE IMPROVE */}
              <EquationEditorButton
                name='integral'
                iconName='integral'
                command={{
                  latexCommand: null,
                  latexString: '\\int_{}^{}',
                  keyAfter: ['Up'],
                  // alternative
                  // quizziz has class name mq-limit, which seems to fix vertical position
                }}
                btnSize='large'
                tooltipLabel='Integral'
                tooltipKeyHint='int'
                clickFunction={this.onButtonClick}
              />

              <EquationEditorButton
                name='dydx'
                iconName='dydx'
                command={{
                  latexString: '\\frac{dy}{dx}',
                }}
                btnSize='large'
                tooltipLabel='dy/dx'
                tooltipKeyHint='\frac{dy}{dx}'
                wideTooltip
                clickFunction={this.onButtonClick}
              />

              <EquationEditorButton
                name='fx'
                iconName='fx'
                command={{
                  latexString: '\\mathrm{f}(x)',
                }}
                btnSize='large'
                tooltipLabel='f(x)'
                tooltipKeyHint='\mathrm{f}(x)'
                wideTooltip
                clickFunction={this.onButtonClick}
              />

              <EquationEditorButton
                name='lim'
                iconName='lim'
                command={{
                  latexString: '\\lim_{}', keyAfter: ['Left'],
                }}
                btnSize='large'
                tooltipLabel='Limit'
                tooltipKeyHint='\lim_{}'
                clickFunction={this.onButtonClick}
              />

            </div>
          </div>
          <div className='equationEditor-controls-mid'>

            {/* BASIC SYMBOLS - MULTIPLY ETC */}
            <div className='equationEditor-controls-mid-btnGroup'>
              <div className='equationEditor-controls-mid-btnGroup-row'>
                <EquationEditorButton name='plus' label='+' command={{ latexString: '+' }} tooltipLabel='Add' tooltipKeyHint='+' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='minus' label='–' command={{ latexString: '-' }} tooltipLabel='Subtract' tooltipKeyHint='-' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='times' label='×' command={{ latexString: '\\times' }} tooltipLabel='Multiply' tooltipKeyHint='times' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='divide' label='÷' command={{ latexString: '\\divide' }} tooltipLabel='Divide' tooltipKeyHint='divide' btnSize='small' clickFunction={this.onButtonClick} />
              </div>
              <div className='equationEditor-controls-mid-btnGroup-row'>
                <EquationEditorButton name='centerdot' label='·' command={{ latexString: '\\cdot' }} tooltipLabel='Center Dot' tooltipKeyHint='cdot' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='percent' label='/' command={{ latexString: '\\slash' }} tooltipLabel='Slash' tooltipKeyHint='\slash' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='plusminus' label='±' command={{ latexString: '\\plusminus' }} tooltipLabel='Plus Minus' tooltipKeyHint='\plusminus' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='tilde' label='~' command={{ latexString: '\\sim' }} tooltipLabel='Tilde' tooltipKeyHint='\sim' btnSize='small' clickFunction={this.onButtonClick} />
              </div>
            </div>

            {/* ROOTS AND SUBSCRIPT */}
            <div className='equationEditor-controls-mid-btnGroup'>
              <div className='equationEditor-controls-mid-btnGroup-row'>
                <EquationEditorButton name='sqrt' iconName='sqrt' command={{ latexString: '\\sqrt{}', keyAfter: ['Left'] }} tooltipLabel='Square Root' tooltipKeyHint='sqrt' wideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='rt3' iconName='rt3' command={{ latexString: '\\sqrt[3]{}', keyAfter: ['Left'] }} tooltipLabel='Cube Root' tooltipKeyHint='\nthroot3' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='rtn' iconName='rtn' command={{ latexString: '\\sqrt[]{}', keyAfter: ['Left', 'Left'] }} tooltipLabel='Nth Root' tooltipKeyHint='nthroot' btnSize='small' clickFunction={this.onButtonClick} />
              </div>
              <div className='equationEditor-controls-mid-btnGroup-row'>
                <EquationEditorButton name='sub1' iconName='sub1' command={{ latexString: '\\_1' }} tooltipLabel='Sub 1' tooltipKeyHint='_1' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='sub2' iconName='sub2' command={{ latexString: '\\_2' }} tooltipLabel='Sub 2' tooltipKeyHint='_2' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='subn' iconName='subn' command={{ latexString: '\\_{}', keyAfter: ['Left'] }} tooltipLabel='Sub' tooltipKeyHint='_' btnSize='small' clickFunction={this.onButtonClick} />
              </div>
            </div>

            {/* GREATER THAN, LESS THAN, ETC */}
            <div className='equationEditor-controls-mid-btnGroup equationEditor-controls-bottom-btnGroup--equivalents'>
              <div className='equationEditor-controls-mid-btnGroup-row'>
                <EquationEditorButton name='less' label='<' command={{ latexString: '<' }} tooltipLabel='Less than' tooltipKeyHint='>' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='greater' label='>' command={{ latexString: '>' }} tooltipLabel='Greater than' tooltipKeyHint='<' wideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='leq' label='≤' command={{ latexString: '\\leq' }} tooltipLabel='Less than or equal to' tooltipKeyHint='leq' extraExtraWideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='geq' label='≥' command={{ latexString: '\\geq' }} tooltipLabel='Greater than or equal to' tooltipKeyHint='geq' extraExtraWideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='neq' label='≠' command={{ latexString: '\\neq' }} tooltipLabel='Not equal to' tooltipKeyHint='neq' wideTooltip btnSize='small' clickFunction={this.onButtonClick} />
              </div>
              <div className='equationEditor-controls-mid-btnGroup-row'>
                <EquationEditorButton name='muchless' label='≪' command={{ latexString: '\\ll' }} tooltipLabel='Much less than' tooltipKeyHint='\ll' extraWideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='muchgreater' label='≫' command={{ latexString: '\\gg' }} tooltipLabel='Much greater than' tooltipKeyHint='\gg' extraWideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                {/* for below: doesn't have good mathquill support. katex support is better */}
                <EquationEditorButton name='notleq' iconName='notEq' label='<' command={{ latexString: '\\nless' }} tooltipLabel='Not less than' tooltipKeyHint='nless' extraWideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='notgeq' iconName='notEq' label='>' command={{ latexString: '\\ngtr' }} tooltipLabel='Not greater than' tooltipKeyHint='ngtr' extraWideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                {/* */}
                <EquationEditorButton name='approx' label='≈' command={{ latexString: '\\approx' }} tooltipLabel='Approx' tooltipKeyHint='approx' btnSize='small' clickFunction={this.onButtonClick} />
              </div>
            </div>

            {/* ARROWS */}
            <div className='equationEditor-controls-mid-btnGroup'>
              <div className='equationEditor-controls-mid-btnGroup-row'>
                <EquationEditorButton name='rightArrow' iconName='rightArrow' command={{ latexString: '\\rightarrow' }} tooltipLabel='Right arrow' tooltipKeyHint='rightarrow' wideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='leftArrow' iconName='leftArrow' command={{ latexString: '\\leftarrow' }} tooltipLabel='Left arrow' tooltipKeyHint='leftarrow' wideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='leftRightArrow' iconName='leftRightArrow' command={{ latexString: '\\leftrightarrow' }} tooltipLabel='Left-right arrow' tooltipKeyHint='leftrightarrow' extraWideTooltip btnSize='small' clickFunction={this.onButtonClick} />
              </div>
              <div className='equationEditor-controls-mid-btnGroup-row'>
                <EquationEditorButton name='overLine' iconName='overLine' command={{ latexString: '\\overline{}', keyAfter: ['Left'] }} tooltipLabel='Overline' tooltipKeyHint='\overline_{}' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='overArrow' iconName='overRightArrow' command={{ latexString: '\\overrightarrow{}', keyAfter: ['Left'] }} tooltipLabel='Over arrow' tooltipKeyHint='\overrightarrow{}' extraWideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                {/* for below: left right harpoon not currently supported by mathquill so using unicode symbol */}
                <EquationEditorButton name='reversibleArrow' iconName='reversibleArrow' command={{ latexString: '⇌' }} tooltipLabel='Reversible arrow' tooltipKeyHint='⇌' extraWideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                {/* */}
              </div>
            </div>

            {/* TRIG */}
            <div className='equationEditor-controls-mid-btnGroup'>
              <div className='equationEditor-controls-mid-btnGroup-row'>
                <EquationEditorButton name='sin' label='sin' command={{ latexString: '\\sin{}' }} tooltipLabel='sin' tooltipKeyHint='sin' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='cos' label='cos' command={{ latexString: '\\cos{}' }} tooltipLabel='cos' tooltipKeyHint='cos' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='tan' label='tan' command={{ latexString: '\\tan{}' }} tooltipLabel='tan' tooltipKeyHint='tan' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='degree' label='°' command={{ latexString: '\\degree' }} tooltipLabel='Degree' tooltipKeyHint='degree' btnSize='small' clickFunction={this.onButtonClick} />
              </div>
              <div className='equationEditor-controls-mid-btnGroup-row'>
                <EquationEditorButton name='log' label='log' command={{ latexString: '\\log' }} tooltipLabel='log' tooltipKeyHint='log' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='logn' label='log&nbsp;&nbsp;' iconName='logn' command={{ latexString: '\\log_{}', keyAfter: ['Left'] }} tooltipLabel='log base' tooltipKeyHint='\log_{}' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='ln' label='ln' command={{ latexString: '\\ln' }} tooltipLabel='Natural log' tooltipKeyHint='ln' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='angle' label='∠' command={{ latexString: '\\angle' }} tooltipLabel='Angle' tooltipKeyHint='angle' btnSize='small' clickFunction={this.onButtonClick} />
              </div>
            </div>

          </div>
          <div className='equationEditor-controls-bottom'>

            <div className='equationEditor-controls-bottom-btnGroup equationEditor-controls-bottom-btnGroup--greek'>
              <div className='equationEditor-controls-bottom-btnGroup-row'>
                <EquationEditorButton name='alpha' label='α' command={{ latexString: '\\alpha' }} tooltipLabel='alpha' tooltipKeyHint='alpha' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='beta' label='β' command={{ latexString: '\\beta' }} tooltipLabel='beta' tooltipKeyHint='beta' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='gamma' label='γ' command={{ latexString: '\\gamma' }} tooltipLabel='gamma' tooltipKeyHint='gamma' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='delta' label='δ' command={{ latexString: '\\delta' }} tooltipLabel='delta' tooltipKeyHint='delta' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='epsilon' label='ε' command={{ latexString: '\\varepsilon' }} tooltipLabel='epsilon' tooltipKeyHint='varepsilon' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='eta' label='η' command={{ latexString: '\\eta' }} tooltipLabel='eta' tooltipKeyHint='eta' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='theta' label='θ' command={{ latexString: '\\theta' }} tooltipLabel='theta' tooltipKeyHint='theta' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='iota' label='ι' command={{ latexString: '\\iota' }} tooltipLabel='iota' tooltipKeyHint='iota' btnSize='small' clickFunction={this.onButtonClick} />
              </div>
              <div className='equationEditor-controls-bottom-btnGroup-row'>
                <EquationEditorButton name='kappa' label='κ' command={{ latexString: '\\kappa' }} tooltipLabel='kappa' tooltipKeyHint='kappa' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='lambda' label='λ' command={{ latexString: '\\lambda' }} tooltipLabel='lambda' tooltipKeyHint='lambda' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='mu' label='μ' command={{ latexString: '\\mu' }} tooltipLabel='mu' tooltipKeyHint='mu' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='xi' label='ξ' command={{ latexString: '\\xi' }} tooltipLabel='xi' tooltipKeyHint='xi' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='pi' label='π' command={{ latexString: '\\pi' }} tooltipLabel='pi' tooltipKeyHint='pi' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='rho' label='ρ' command={{ latexString: '\\rho' }} tooltipLabel='rho' tooltipKeyHint='rho' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='sigma' label='σ' command={{ latexString: '\\sigma' }} tooltipLabel='sigma' tooltipKeyHint='sigma' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='omega' label='ω' command={{ latexString: '\\omega' }} tooltipLabel='omega' tooltipKeyHint='omega' btnSize='small' clickFunction={this.onButtonClick} />
              </div>
              <div className='equationEditor-controls-bottom-btnGroup-row'>
                <EquationEditorButton name='Gamma' label='Γ' command={{ latexString: '\\Gamma' }} tooltipLabel='Gamma' tooltipKeyHint='Gamma' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='Delta' label='Δ' command={{ latexString: '\\Delta' }} tooltipLabel='Delta' tooltipKeyHint='Delta' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='Theta' label='Θ' command={{ latexString: '\\Theta' }} tooltipLabel='Theta' tooltipKeyHint='Theta' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='Lambda' label='Λ' command={{ latexString: '\\Lambda' }} tooltipLabel='Lambda' tooltipKeyHint='Lambda' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='Pi' label='Π' command={{ latexString: '\\Pi' }} tooltipLabel='Pi' tooltipKeyHint='Pi' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='Psi' label='Ψ' command={{ latexString: '\\Psi' }} tooltipLabel='Psi' tooltipKeyHint='Psi' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='Sigma' label='Σ' command={{ latexString: '\\Sigma' }} tooltipLabel='Sigma' tooltipKeyHint='Sigma' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='Omega' label='Ω' command={{ latexString: '\\Omega' }} tooltipLabel='Omega' tooltipKeyHint='Omega' btnSize='small' clickFunction={this.onButtonClick} />
              </div>
            </div>

            <div className='equationEditor-controls-bottom-btnGroup'>
              <div className='equationEditor-controls-bottom-btnGroup-row'>
                <EquationEditorButton name='subset' label='⊂' command={{ latexString: '\\subset' }} tooltipLabel='Subset' tooltipKeyHint='subset' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='supset' label='⊃' command={{ latexString: '\\supset' }} tooltipLabel='Superset' tooltipKeyHint='supset' btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='cap' label='∩' command={{ latexString: '\\cap' }} tooltipLabel='Intersection' tooltipKeyHint='\cap' wideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                {/* Lato character for cup is not quite right, so use cap symbol but flip */}
                <EquationEditorButton name='cup' label='∩' command={{ latexString: '\\cup' }} tooltipLabel='Union' tooltipKeyHint='\cup' btnSize='small' clickFunction={this.onButtonClick} />
              </div>
              <div className='equationEditor-controls-bottom-btnGroup-row'>
                <EquationEditorButton name='emptyset' label='∅' command={{ latexString: '\\emptyset' }} tooltipLabel='Empty set' tooltipKeyHint='\emptyset' wideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='in' label='∈' command={{ latexString: '\\in' }} tooltipLabel='Element in' tooltipKeyHint='\in' wideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='therefore' label='∴' command={{ latexString: '\\therefore' }} tooltipLabel='Therefore' tooltipKeyHint='\therefore' wideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='forall' label='∀' command={{ latexString: '\\forall' }} tooltipLabel='For All' tooltipKeyHint='\forall' btnSize='small' clickFunction={this.onButtonClick} />
              </div>
              <div className='equationEditor-controls-bottom-btnGroup-row'>
                {/* No out of box support for doublestruck in mathquill */}
                <EquationEditorButton name='doublestruckN' label='ℕ' command={{ latexString: 'ℕ' }} tooltipLabel='Doublestruck N' tooltipKeyHint='\ℕ' extraWideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='doublestruckR' label='ℝ' command={{ latexString: 'ℝ' }} tooltipLabel='Doublestruck R' tooltipKeyHint='\ℝ' extraWideTooltip btnSize='small' clickFunction={this.onButtonClick} />
                <EquationEditorButton name='doublestruckZ' label='ℤ' command={{ latexString: 'ℤ' }} tooltipLabel='Doublestruck Z' tooltipKeyHint='\ℤ' extraWideTooltip btnSize='small' clickFunction={this.onButtonClick} />
              </div>
            </div>

            {isNewNode && (
            <button disabled={isEmpty} className='equationEditor-controls-primaryBtn' onClick={this.handleInsertEquationButtonClick}>
              <div className='equationEditor-controls-primaryBtn-title'>
                Insert Equation
              </div>
              <div className='equationEditor-controls-primaryBtn-keyboardHint'>
                Enter
              </div>
              <div className={`equationEditor-controls-primaryBtn-error ${katexError ? 'equationEditor-controls-primaryBtn-error--hasError' : 'equationEditor-controls-primaryBtn-error--noError'}`}>
                Your equation contains some invalid LaTeX
              </div>
            </button>
            )}

            {!isNewNode && (
            <button className='equationEditor-controls-primaryBtn' onClick={this.handleUpdateLatexButtonClick}>
              <div className='equationEditor-controls-primaryBtn-title'>
                {isUpdateChanges ? 'Save Changes' : 'Done'}
              </div>
              <div className='equationEditor-controls-primaryBtn-keyboardHint'>
                Enter
              </div>
              <div className={`equationEditor-controls-primaryBtn-error ${katexError ? 'equationEditor-controls-primaryBtn-error--hasError' : 'equationEditor-controls-primaryBtn-error--noError'}`}>
                Your equation contains some invalid LaTeX.
              </div>
            </button>
            )}
          </div>
        </div>
        {((isNewNode && isEmpty) || (!isNewNode && !isUpdateChanges)) &&
        <div onClick={this.props.cancel} className='equationModalContainerBG' />}
      </div>
    )
  }
}

export default MathQuillEquationEditor

export default function (state = {visible:false}, action) {
	switch (action.type) {
	case 'SHOW_AUTOPLAY_FAILED_ALERT':
		return {visible:true}
	case 'HIDE_AUTOPLAY_FAILED_ALERT':
		return {visible:false}
	
	case 'LOGOUT':
		return {visible:false}
	
	default:
		return state
	}
}

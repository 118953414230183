import React from 'react'
import ModalWrapper from '../ModalWrapper'
import UpdatePaymentInfoContainer from '../../../containers/modals/account/UpdatePaymentInfoContainer'

const UpdatePaymentInfoModal = props => {

	return (

  <ModalWrapper
    {...props}
    title={'Update Payment Info'}
    iconName=''
    closeBtn={false}
    showModalHeader={false}
    modalStyle='dark'
  >
    <UpdatePaymentInfoContainer {...props}/>

  </ModalWrapper>



  )
}

export default UpdatePaymentInfoModal
import React from 'react'
import { stopAllMediaPlayback } from '../../../../../prosemirror/utils/editorActions/stopAllMediaPlayback'
import MathQuillEquationEditor from './MathQuillEquationEditor'
import AdvancedEquationEditor from './AdvancedEquationEditor'

// Returns either Mathquill or Advanced equation editor component
class EquationEditorModal extends React.Component {
  constructor(props) {
    super(props)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    let advancedLatexString = ''
    let standardLatexString = ''
    if (props.initialLatex) {
      if (props.mode === 'advanced') {
        advancedLatexString = props.initialLatex
      } else {
        standardLatexString = props.initialLatex
      }
    }
    this.state = {
      mode: props.mode || 'mathquill', // mathquill or advanced
      advancedLatexString,
      standardLatexString,
      hasKatexError: false,
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
    stopAllMediaPlayback()
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(e) {
    e.stopPropagation()
    if (e.keyCode === 13 && this.state.mode === 'mathquill') { // enter key for standard modal
      e.preventDefault()
      const latex = this.state.standardLatexString
      const isAdvancedInput = false
      if (this.props.isNewNode) {
        if (latex) {
          this.props.insertMathNode(latex, this.props.openedWithShortcut, isAdvancedInput)
        } else {
          this.props.cancel()
        }
      } else {
        this.props.updateLatexValue(latex)
      }
    }
    if (e.keyCode === 13 && e.shiftKey && this.state.mode === 'advanced') { // shift enter for advanced modal
      e.preventDefault()
      // prevent saving when there's katex error
      if (this.state.hasKatexError) return

      const latex = this.state.advancedLatexString
      const isAdvancedInput = true
      if (this.props.isNewNode) {
        if (latex) {
          this.props.insertMathNode(latex, this.props.openedWithShortcut, isAdvancedInput)
        } else {
          this.props.cancel()
        }
      } else {
        this.props.updateLatexValue(latex)
      }
    }
    if (e.keyCode === 27) { // escape
      this.props.cancel()
    }
  }

  render() {
    const { mode } = this.state
    return (
      /* eslint-disable react/jsx-props-no-spreading */
      <React.Fragment>
        {mode === 'mathquill' && (
        <MathQuillEquationEditor
          latex={this.state.standardLatexString}
          onChangeLatex={(latex) => { this.setState({ standardLatexString: latex }) }}
          showAdvancedEditor={() => { this.setState({ mode: 'advanced' }) }}
          {...this.props}
        />
        )}
        {mode === 'advanced' && (
        <AdvancedEquationEditor
          latex={this.state.advancedLatexString}
          hasKatexError={this.state.hasKatexError}
          setHasKatexError={(boolean) => this.setState({ hasKatexError: boolean })}
          onChangeLatex={(latex) => { this.setState({ advancedLatexString: latex }) }}
          showMathQuillEditor={() => { this.setState({ mode: 'mathquill' }) }}
          {...this.props}
        />
        )}
      </React.Fragment>
      /* eslint-enable react/jsx-props-no-spreading */
    )
  }
}

export default EquationEditorModal

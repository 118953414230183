import _ from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { addExternalSignin } from '../../actions/user'
import { hideModal } from '../../actions/modals'
import { showNotification } from '../../actions/notifications'
import SelectExternalAccountComponent from '../../components/modals/account/SelectExternalAccountComponent'
import Button from '../../components/uiKit/Button'
import handleRequest from './utils/handleAccountRequest'

function AddExternalSigninContainer(props) {
  const {
    hideModal,
    showNotification,
    addExternalSignin,
    user,
    externalAccountType,
  } = props
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [externalAccount, setExternalAccount] = useState(null)
  const externalTypeString = _.startCase(externalAccountType)

  function onConfirm() {
    const requestData = {
      type: externalAccountType,
      idToken: externalAccount.idToken,
    }
    handleRequest({
      setSubmitLoading,
      setErrorMessage,
      makeRequest: () => addExternalSignin(user.id, requestData, externalAccountType),
      hideModal,
      showSuccessNotification: () => showNotification(`Sign in with ${externalTypeString}`, 'Added', 'default'),
      requestName: externalAccountType === 'google' ? 'addGoogleSignin' : 'addAppleSignin',
    })
  }

  return (
    <div className='accountAuthentication is--add'>
      <SelectExternalAccountComponent
        title={`Sign in with your ${externalTypeString} account`}
        externalAccount={externalAccount}
        setExternalAccount={setExternalAccount}
        externalAccountType={externalAccountType}
      />

      <div className='accountAuthentication-actionRow'>
        <Button
          size='large'
          label='Confirm'
          color='blue'
          type='submit'
          onClickFunction={() => onConfirm()}
          disabled={!externalAccount}
          submitLoading={submitLoading}
        />
      </div>

      {errorMessage && (
      <div className='accountAuthentication-error'>
        {errorMessage}
      </div>
      )}
    </div>
  )
}

export default connect(
  (state) => ({
    user: state.user,
    setBanner: state.modals.setBanner,
  }),
  {
    addExternalSignin,
    hideModal,
    showNotification,
  },
)(AddExternalSigninContainer)

export function getTotalPollCount(polls) {
  let totalPollCount = 0
  polls.forEach((poll) => {
    if (poll.setObject) { // is a setPoll
      totalPollCount += poll.filteredAndOrderedPolls.length
    } else {
      totalPollCount += 1
    }
  })
  return totalPollCount
}

import {TextSelection} from "prosemirror-state"

//sets the selection to the end of question body
export function setQuestionBodySelectionTransaction(tr,questionNode,questionPos) {
  tr.doc.nodesBetween(questionPos,questionPos+questionNode.nodeSize, (node, pos) => {
  if(node.type.name === "questionBody"){
    const selection = new TextSelection(tr.doc.resolve(pos+node.nodeSize-2))
    tr.setSelection(selection) //position cursor at end of question body
    }    
  })
  return  tr
}


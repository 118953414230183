//default empty control object

export function getDefaultControlObject() {	
	const nowDate = new Date()
	
	const defaultPlayback={
		item:null,
		isPlaying:false
	}
	
	const controlObject={
		controlled:nowDate,
		section:null,
		scanning:false,
		showGraph:false,
		revealAnswer:false,
		setPoll:null,
		setPollPoll:null,
		currentPoll:null,
		snapshot:null,
		mobileAccepted:false,
		paused:false,
		showCardAssignment:false,
		playback:defaultPlayback,
		zoomedItem:null
	}

 return controlObject

}
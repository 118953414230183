import QuestionNodeSpec from './QuestionNodeSpec'
import QuestionBodyNodeSpec from './QuestionBodyNodeSpec'
import QuestionImageNodeSpec from './QuestionImageNodeSpec'
import QuestionVideoNodeSpec from './QuestionVideoNodeSpec'
import QuestionSoundNodeSpec from './QuestionSoundNodeSpec'
import QuestionAnimatedGIFNodeSpec from './QuestionAnimatedGIFNodeSpec'
import ChoiceGroupNodeSpec from './ChoiceGroupNodeSpec'
import ChoiceNodeSpec from './ChoiceNodeSpec'
import ChoiceBodyNodeSpec from './ChoiceBodyNodeSpec'
import ChoiceImageNodeSpec from './ChoiceImageNodeSpec'
import ChoiceVideoNodeSpec from './ChoiceVideoNodeSpec'
import ChoiceSoundNodeSpec from './ChoiceSoundNodeSpec'
import ChoiceAnimatedGIFNodeSpec from './ChoiceAnimatedGIFNodeSpec'
import FriendlyFractionNodeSpec from './FriendlyFractionNodeSpec'
import FriendlyFractionNumeratorNodeSpec from './FriendlyFractionNumeratorNodeSpec'
import FriendlyFractionDenominatorNodeSpec from './FriendlyFractionDenominatorNodeSpec'
import MathNodeSpec from './MathNodeSpec'

const choiceMediaEmptyStateSpec={
	selectable:false,
	draggable:false,
	isolating: true,
	attrs: {
		slideWidth:{default:''}
	},
	
	toDOM(node) {
		return ['choiceMediaEmptyState']},
	parseDOM: [{tag: 'choiceMediaEmptyState'}],
}

const choiceMediaSpec = {
	content: "choiceMediaEmptyState | choiceImage | choiceVideo | choiceSound",
	parseDOM: [{tag: 'choiceMedia'}],
	toDOM: () => ["choiceMedia",0],
}

const pDOM = ["p", 0]

const baseNodes = {
	// :: NodeSpec A plain paragraph textblock. Represented in the DOM
	// as a `<p>` element.
	paragraph: {
		content: "inline*",
		group: "block",
		parseDOM: [{tag: "p"}],
		toDOM() { return pDOM }
	},
	// :: NodeSpec The text node.
	text: {
		group: "inline"
	},
}

const EditorNodes = {...baseNodes,
	doc:{content: "question+"},
	question:QuestionNodeSpec,
	questionBody:QuestionBodyNodeSpec,
	choiceGroup: ChoiceGroupNodeSpec,
	choice: ChoiceNodeSpec,
	choiceBody:ChoiceBodyNodeSpec,
	choiceImage:ChoiceImageNodeSpec,
	choiceVideo:ChoiceVideoNodeSpec,
	choiceSound:ChoiceSoundNodeSpec,
	questionImage:QuestionImageNodeSpec,
	questionVideo:QuestionVideoNodeSpec,
	questionAnimatedGIF:QuestionAnimatedGIFNodeSpec,
	choiceAnimatedGIF:ChoiceAnimatedGIFNodeSpec,
	friendlyFraction:FriendlyFractionNodeSpec,
	friendlyFractionNumerator:FriendlyFractionNumeratorNodeSpec,
	friendlyFractionDenominator:FriendlyFractionDenominatorNodeSpec,
	questionSound:QuestionSoundNodeSpec,
	math:MathNodeSpec, 
	choiceMedia:choiceMediaSpec,
	choiceMediaEmptyState:choiceMediaEmptyStateSpec,
}

export default EditorNodes
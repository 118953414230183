import React from 'react'
import AutoplaySetupContent from './AutoplaySetupContent'

class AutoplaySetupModal extends React.Component{
	render(){	
		return(		
			<AutoplaySetupContent isModal={true} closeModal={this.props.closeModal}/>
		)
	}
}

export default AutoplaySetupModal
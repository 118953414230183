const ChoiceSoundNodeSpec ={
  inline: false,
  attrs: {
    isPlaying:{default:false},
    fileId:{default:''},
    deliveryUrl:{default:''},
    questionChoiceCount: {default:4},
    template: {default: null},
    title:{default:''},
    fullDuration:{default:null},
    isClipped:{default:false},
    start:{default:0},
    end:{default:''},
    source:{default:false},
    attribution:{default:''},
    addedAt:{default:''},
    slideWidth:{default:''}
  },
  selectable:false,
  draggable:false,
  isolating: true,
  group: 'block',
  parseDOM: [{tag: 'choiceSound'}],
    toDOM(node) {
    return ['choiceSound']},
};

export default ChoiceSoundNodeSpec
 
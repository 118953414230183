import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import Measure from 'react-measure'
import ScorePill from '../uiKit/ScorePill'
import {formatDateLong} from '../../utils/formatDateLong'
import {formatDate} from '../../utils/formatDate'
import Button from '../uiKit/Button'
import SectionMarker from '../uiKit/SectionMarker'
import TopPageHeader from '../pageHeaders/TopPageHeader'
import StickyPageHeader from '../pageHeaders/StickyPageHeader'
import SlideGrid from '../SlideGrid'
import GridColumnToggle from '../uiKit/GridColumnToggle'
import {resumePlayingSetPoll} from '../../utils/resumePlayingSetPoll'

  

class SetPollDetail extends React.Component{
	
	constructor() {
		super()
		this.handleToggleTableView=this.handleToggleTableView.bind(this)
		this.navigateToSetPollPoll=this.navigateToSetPollPoll.bind(this)
		this.renderStudentScorePill=this.renderStudentScorePill.bind(this)
		this.state={
			isTableView:true,
			centerPanelInnerWidth:0,			
			gridColumns:3,
			studentSortOrder:'name',
			showAnsweredOnly:false,
			studentsWithScores:null
		}
	}


	handleToggleTableView(isTable){
		this.setState({isTableView:isTable})
	}


	renderStudentScorePill(student) {
		
		// const studentResults=calculateStudentTotal(student.id,this.props.poll)
		let score

		if(student.totalAnswers !==0){
			 score = (student.totalCorrect/student.totalAnswers)*100
		}
		else if(student.totalSurveys!==0){
			score ='N/A'
		}
		else {
			score ='-'
		}
		return( 
			<div className='setReport-studentCard' key={student.id} > 	      
				<div className='setReport-studentCard-name notranslate'>{student.displayName}</div>	      	
				<ScorePill score={score} size='small' />	      
			</div>
		)
	}


	navigateToSetPollPoll(pollPoll){
		if(this.props.parentSectionId){
			this.props.history.push(`/classes/${this.props.parentSectionId}/setreport/${this.props.poll.id}/${pollPoll.question}`)
		}else{
			this.props.history.push(`/setreport/${this.props.poll.id}/${pollPoll.question}`)
		}
	}


  
	render(){
		const {poll,section,students,libraryItem}=this.props
		const setScore=poll.correctPercent
		const setDetailPath=`/set/${poll.snapshot.id}`	
		let sortedStudents
		if(this.state.studentSortOrder==='name'){
		 	sortedStudents=sortBy(students, [function(o) { 
	    		return o.displayName.toString().toLowerCase()
			}]) 
		}else{		 
			sortedStudents= sortBy(students, [function(o) { 
    		if(o.totalAnswers !==0){ 
    			return -1*(o.totalCorrect/o.totalAnswers)
    		}else return 100 //of absent give student a negative score for sort order

    	},function(o) { 
    		return o.displayName.toString().toLowerCase()
			}])
		}

		const isAllSurvey = poll.correctPercent===-1
		const onlyOnePoll = Object.keys(poll.polls).length===1

		let showStudentList

		if(isAllSurvey || onlyOnePoll){
			showStudentList = false
		}
		else{
			showStudentList = true
		}

		let revealInLibraryDisabled=true
		if(libraryItem){
			revealInLibraryDisabled=false
		}

		return[
			<div className='page-leftPanel' key='leftPanel'/>,
				
			<div className='page-centerPanel' key='centerPanel'>
				<Measure onMeasure={(dimensions) => {this.setState({centerPanelInnerWidth:dimensions.width})}}>
					<div className='page-centerPanel-innerMeasureSpacer'/>
				</Measure>
								
				<TopPageHeader
					location='setReport'
					height={205}		
					rightBadge={isAllSurvey ? null : <ScorePill typeDot score={setScore} />}
					header={poll.snapshot.name}					
					headerInfoRow={						
							<Link to={`/classes/${section.id}`}>
								<SectionMarker section={section} />
							</Link>
						}
						
					headerInfoRowClickFunction={() => {this.props.history.push(`/classes/${section.id}`)}}

					actionRow={
						<React.Fragment>							
							<span>{`Played ${formatDateLong(poll.minCaptured)}`}</span>
							<div className='pageHeaderTop-actionRow-spacer' />
				    
				     </React.Fragment>
					}				
					borderBottom
					backButton
				/>
				  
				<StickyPageHeader 
					location='setReport' 						
					header={poll.snapshot.name}					
					actionGroup={isAllSurvey ? null : <ScorePill typeDot score={setScore} />}
				/>											

				{showStudentList &&
					<React.Fragment>
						<div className='setReport-sectionHeader'>
							Student Overview
							<div className='setReport-studentSortToggle'>
								<div className={'setReport-studentSortToggle-toggle' + (this.state.studentSortOrder === 'name' ? ' setReport-studentSortToggle-toggle--active' : '')} 
									onClick={()=>{this.setState({studentSortOrder: 'name'})}}>
									A-Z
								</div>
								<div className={'setReport-studentSortToggle-toggle' + (this.state.studentSortOrder === 'score' ? ' setReport-studentSortToggle-toggle--active' : '')} 
									onClick={()=>{this.setState({studentSortOrder: 'score'})}}>
									High-Low
								</div>
							</div>
						</div>

						<div className='setReport-studentListContainer'>
							<div className='setReport-studentList-blockLine'/>
							<div className='setReport-studentList'>

								{sortedStudents.map(this.renderStudentScorePill)}
							</div>				
						</div>
					</React.Fragment>
				}		

				<div className='setReport-sectionHeader'>
					Questions					
					<div className='setReport-showAnsweredToggle'>
						<div className={'setReport-showAnsweredToggle-toggle' + (!this.state.showAnsweredOnly ? ' setReport-showAnsweredToggle-toggle--active' : '')} 
							onClick={()=>{this.setState({showAnsweredOnly: false})}}>
							All
						</div>
						<div className={'setReport-showAnsweredToggle-toggle' + (this.state.showAnsweredOnly ? ' setReport-showAnsweredToggle-toggle--active' : '')} 
							onClick={()=>{this.setState({showAnsweredOnly: true})}}>
							Answered
						</div>
					</div>
					<GridColumnToggle				      	
      			activeGridColumn={this.state.gridColumns}
      			setNumberColumns={(numberOfColumns) => {this.setState({gridColumns: numberOfColumns})}}
		      />
				</div>

		 

		
					<SlideGrid 
						showAnsweredOnly={this.state.showAnsweredOnly}
						showExpandedQuestionSlideModal={this.props.showExpandedQuestionSlideModal}
						section={section}
						pollId={poll.id}
						questionsAndPollsList={this.props.questionsAndPollsList}
						//polls={this.props.orderedPollPolls}
						gridColumns={this.state.gridColumns}
						availableWidth={this.state.centerPanelInnerWidth}
					/>
				


			</div>,
				
			<div className='page-rightPanel' key='rightPanel'>
				<div className='sidePanelContainer'>
					<div className='sidePanel'>
						
						{!poll.archived &&
						<Button 
							label='Continue Playing'
							color='blue'
							// disabled={true}			
							onClickFunction={() =>{resumePlayingSetPoll(poll)}}
						/>
						}
						{!poll.archived && libraryItem && libraryItem.clientModified > poll.snapshotRefreshed &&
						<div className='setReport-continuePlayingTimestamp'>
							Updated {formatDate(libraryItem.clientModified)}
						</div>
						}
					
						{!poll.archived &&
						<Button 
							disabled={revealInLibraryDisabled}
							name='reveal-library'
							label='Go to Set Detail'
							onClickFunction={() =>{this.props.history.push(`${setDetailPath}`)}}
						/>
						}						

						<Button 
							name='archive-report'
							label={poll.archived ? 'Unarchive Report' : 'Archive Report'}
							onClickFunction={()=>{this.props.archivePoll(poll)}} 
						/>

						<Button
							name='delete-report'
							label='Delete Report'
							onClickFunction={()=>{this.props.deletePoll(poll)}} 
						/>
						
					</div>
				</div>
				
			</div>,
		]			
	}
}


export default withRouter(SetPollDetail)



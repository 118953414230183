import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { ensureRecaptcha } from './recaptchaUtil'

let captchaId

// VALIDATION

const requiredInvisible = (value) => (value ? undefined : 'Required')

class LoginFormInputField extends React.Component {
  constructor() {
    super()
    this.state = {
      showPassword: false,
    }
  }

  render() {
    const { input, styleName } = this.props

    return (
      <div
        className={`signin-form-field${
          styleName ? ` signin-form-field--${styleName} ` : ''
        }${input.value !== '' ? ' signin-form-field--nonEmpty ' : ' signin-form-field--empty'}`}
      >
        {styleName === 'email' && (
        /* eslint-disable react/jsx-props-no-spreading */
        <input
          {...input}
          className='signin-form-field-input'
          name='email'
          placeholder='Email Address'
          type='text'
          autoComplete='email'
        />
        /* eslint-enable react/jsx-props-no-spreading */
        )}

        {styleName === 'password' && (
        /* eslint-disable react/jsx-props-no-spreading */
        <React.Fragment>
          <input
            {...input}
            className='signin-form-field-input'
            name='password'
            placeholder='Password'
            autoComplete='current-password'
            type={this.state.showPassword ? 'text' : 'password'}
          />

          <div className='signin-form-field--password-showPassword' onClick={() => { this.setState((prevState) => ({ showPassword: !prevState.showPassword })) }}>
            {this.state.showPassword &&
            <span>Hide</span>}
            {!this.state.showPassword &&
            <span>Show</span>}
          </div>
        </React.Fragment>
        /* eslint-enable react/jsx-props-no-spreading */
        )}
      </div>
    )
  }
}

class LoginForm extends React.Component {
  constructor() {
    super()
    this.actuallySubmit = this.actuallySubmit.bind(this)
    this.preSubmit = this.preSubmit.bind(this)
    this.reloadCaptcha = this.reloadCaptcha.bind(this)
  }

  componentDidMount() {
    this.reloadCaptcha()
  }

  actuallySubmit(formProps) {
    const logInData = {
      ...formProps,
      recaptchaResponse: window.grecaptcha.getResponse(captchaId),
    }
    this.props.onSubmit(logInData)
    window.grecaptcha.reset(captchaId)
  }

  reloadCaptcha(callback) {
    const { handleSubmit, onRecaptchaError } = this.props
    ensureRecaptcha(
      () => {
        captchaId = window.grecaptcha.render(this.recaptchaDiv, {
          sitekey: process.env.REACT_APP_PLICKERS_RECAPTCHA_SITE_KEY,
          tabIndex: 100,
          size: 'invisible',
          callback: handleSubmit(this.actuallySubmit),
        })
        if (callback) callback()
      },
      onRecaptchaError,
    )
  }

  preSubmit() {
    this.props.onPreSubmit()
    if (!window.grecaptcha || captchaId === undefined) {
      this.reloadCaptcha(this.preSubmit)
    } else window.grecaptcha.execute(captchaId)
  }

  render() {
    const {
      handleSubmit,
      submitLoading,
      goToForgotPassword,
      handleForgotPasswordKeyDown,
    } = this.props

    return (

      <form
        className={`signin-form ${
          this.props.anyTouched ? ' signin-form--anyTouched ' : ''
        }${this.props.valid ? ' signin-form--valid ' : ' signin-form--invalid'}`}
        onSubmit={handleSubmit(this.preSubmit)}
      >

        <Field
          name='email'
          type='text'
          styleName='email'
          validate={requiredInvisible}
          component={LoginFormInputField}
        />

        <Field
          name='password'
          type='password'
          styleName='password'
          validate={requiredInvisible}
          component={LoginFormInputField}
        />

        <div className='signin-form-forgotPasswordContainer'>
          <div
            className='signin-form-forgotPasswordLink'
            role='button'
            tabIndex={0}
            onClick={goToForgotPassword}
            onKeyDown={handleForgotPasswordKeyDown}
          >
            Forgot Password?
          </div>
        </div>

        <button className='signin-form-submitBtn' type='submit'>

          {!submitLoading && (
          <React.Fragment>
            Sign in
          </React.Fragment>
          )}

          {submitLoading && (
          <div className='signin-form-submitBtn-loadingSpinnerContainer'>
            <div className='signin-form-submitBtn-loadingSpinner' />
          </div>
          )}
        </button>

        {/* eslint-disable no-return-assign */}
        <div ref={(div) => this.recaptchaDiv = div} />
        {/* eslint-enable no-return-assign */}
      </form>
    )
  }
}

export default reduxForm({
  form: 'loginForm',
})(LoginForm)

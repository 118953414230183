import React from 'react'
import { withRouter} from 'react-router-dom'
import includes from 'lodash/includes'
import Icon from './../misc/Icons'
import DnDFolderCell from '../cells/DnDFolderCell'
import DnDCell from '../cells/DnDCell'
import StickyDividerWrapper from '../uiKit/StickyDividerWrapper'
import SetAndQuestionContextMenu from '../contextMenus/SetAndQuestionContextMenu'
import FolderContextMenu from '../contextMenus/FolderContextMenu'
import MultiSelectContextMenu from '../contextMenus/MultiSelectContextMenu'
import { DropTarget } from 'react-dnd'
import { findDOMNode } from 'react-dom'
import ItemTypes from '../DnDKit/ItemTypes'
import {detectBrowser} from '../../utils/detectBrowser'
import LibraryRepoGrid from './LibraryRepoGrid'

//DND stuff
//Libary page is a drop target so we can scroll it when dragging
const browser = detectBrowser()

function collect(connect, monitor,DropTargetMonitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop()
  	}
}

const libraryTableTarget = {
	hover(props, monitor,component) {
		if(browser !== 'safari'){
		const cancelAnimationFrame = window.cancelAnimationFrame || (timeout => clearTimeout(timeout))
		const requestAnimationFrame = window.requestAnimationFrame || (func => setTimeout(func, 1000 / 60))
		// If already scrolling, stop the previous scroll loop
		if (this.lastScroll) {
		  cancelAnimationFrame(this.lastScroll)
		  this.lastScroll = null
		  clearTimeout(this.removeTimeout)
		}

		const bufferHeight = 75
		const bufferHeightTop= 155 + bufferHeight
		const dragYOffset = monitor.getClientOffset().y
		const { top: containerTop, bottom: containerBottom } = findDOMNode(component).getBoundingClientRect()
		const windowHeight = window.innerHeight
		let scrollDirection = 0
		let scrollMagnitude = 0
		const fromTop = dragYOffset - bufferHeightTop - Math.max(containerTop, 0)
		if (fromTop <= 0) {
		  // Move up
		  scrollDirection = -1
		  scrollMagnitude = Math.ceil(Math.sqrt(-1 * fromTop))
		} else {
		  const fromBottom = dragYOffset + bufferHeight - Math.min(containerBottom, windowHeight)
		  if (fromBottom >= 0) {
			// Move down
				scrollDirection = 1
				scrollMagnitude = Math.ceil(Math.sqrt(fromBottom))
		  } else {
			// If neither near the top nor bottom, skip calling the scrolling function
				return
		  }
		}
		// Indefinitely scrolls the window down at a constant rate
		const doScroll = () => {
		  window.scrollBy(0, scrollDirection * scrollMagnitude)
	  	this.lastScroll = requestAnimationFrame(doScroll)
		}
		// Stop the scroll loop after a period of inactivity
		this.removeTimeout = setTimeout(() => {
		  cancelAnimationFrame(this.lastScroll)
		  this.lastScroll = null
		}, 100)

		// Start the scroll loop
		this.lastScroll = requestAnimationFrame(doScroll)
  	}
  	},

 	 drop() {
		if (this.lastScroll) {
	  	const cancelAnimationFrame = window.cancelAnimationFrame || (timeout => clearTimeout(timeout))
	 	 	cancelAnimationFrame(this.lastScroll)
	  	this.lastScroll = null
	  	clearTimeout(this.removeTimeout)
		}
	}
}


class LibraryPage extends React.Component{

	constructor(){
		super()
		this.renderLibraryItem=this.renderLibraryItem.bind(this)
		this.isItemActive=this.isItemActive.bind(this)
		this.isItemMultiSelect=this.isItemMultiSelect.bind(this)
		this.renderFolder=this.renderFolder.bind(this)
	}


	componentDidUpdate(prevProps) {
	 // only scroll into view if the active item changed last render
	 let activeItemId=null
	 if(this.props.activeItem){
		activeItemId=this.props.activeItem.id
	 }
	 let prevActiveItemId=null
	 if(prevProps.activeItem){
		prevActiveItemId=prevProps.activeItem.id
	 }
	 if (this.props.activeItem && (activeItemId !== prevActiveItemId) ) {
	  this.ensureActiveItemVisible(this.props.activeItem.id)
		}
	}

	ensureActiveItemVisible(itemId) {
		const cellNode = document.getElementById(itemId)
		if(cellNode ){
			const cellRect = cellNode.getBoundingClientRect()
			const offset=130 //for header
			//check if need to scroll it 
			if(!(cellRect.top >= offset && cellRect.bottom <= window.innerHeight)){
				cellNode.scrollIntoView({block: 'end', behavior: 'instant'})
			}
		}
	}

	renderLibraryItem(key){
		const item=this.props.libraryData[key]
		const filterTerm=this.props.filterTerm
		const contextMenuIsOpen=(item.id===this.props.contextMenuActiveItem)
		if(item.choices){
			 if (includes(item.body.toLowerCase(),filterTerm.toLowerCase())) {
				return  (
					<DnDCell
						key={`question_${item.id}`}
						isSet={false}
						item={item}
						isActive={this.isItemActive(item.id)}
						handleSingleClick={this.props.selectRow}
						handleOpenItem={this.props.handleOpenItem}
						contextMenuIsOpen={contextMenuIsOpen}
						playItem={this.props.playItem}
						isMultiSelectItem={this.isItemMultiSelect(item.id)}
						navigateToItemDetail={this.props.navigateToItemDetail}
						multiselectItems={this.props.multiSelectItems}
					/> 
				)
			}
		}else if(item.questions){
			 if (includes(item.name.toLowerCase(),filterTerm.toLowerCase())) {
				return (
					<DnDCell
						key={item.id}
						isSet={true}
						item={item}
						isActive={this.isItemActive(item.id)}
						handleSingleClick={this.props.selectRow}
						handleOpenItem={this.props.handleOpenItem}
						contextMenuIsOpen={contextMenuIsOpen}
						playItem={this.props.playItem}
						isMultiSelectItem={this.isItemMultiSelect(item.id)}
						navigateToItemDetail={this.props.navigateToItemDetail}
						multiselectItems={this.props.multiSelectItems}
					/>
				)
			}
		}
	}

	renderFolder(key){
		const item=this.props.folders[key]
		const filterTerm=this.props.filterTerm
		const contextMenuIsOpen=(item.id===this.props.contextMenuActiveItem)
		if (includes(item.name.toLowerCase(),filterTerm.toLowerCase())) {
			return (
				<DnDFolderCell
					resetState={this.props.resetLibraryState}
					moveToFolder={this.props.moveToFolder} 
					key={item.id}
					folder={item}
					isActive={this.isItemActive(item.id)}				
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					handleSingleClick={this.props.selectRow}
					navigateToFolder={this.props.navigateToFolder}
					contextMenuIsOpen={contextMenuIsOpen}
					isMultiSelectItem={this.isItemMultiSelect(item.id)}
					showNotification={this.props.showNotification}
					multiselectItems={this.props.multiSelectItems}
				/>
			)	
		} else return null
	}

	isItemActive(itemId){
		let isActive=false
		const {activeItem}=this.props
		if(activeItem){		
			isActive=(itemId === activeItem.id)
		}
		return isActive
	}

	isItemMultiSelect(itemId){
		let isMultiSelect=false
		const {multiSelectEnabled,multiSelectItems}=this.props
		if(multiSelectEnabled ===true && Object.keys(multiSelectItems).length>1){
			isMultiSelect=includes(Object.keys(multiSelectItems), itemId)			
		}
		return isMultiSelect
	}

	render(){
		const {libraryData,sortBy,sortOrder,folders,connectDropTarget,repos,filterTerm}=this.props 
		return (

			<div className='library-content' onClick={(e)=>{e.stopPropagation()}} >			

				{repos && !this.props.parentFolder && 
					<LibraryRepoGrid filterTerm={filterTerm} repos={repos}  {...this.props}/>
				}
			


				<div className='library-table--container' onKeyPress={this.handleUp}>

					<StickyDividerWrapper className='library'>	
						<div className={'sortableColumnHeaders' + (sortBy === 'name' ? ' is--sortedByName' : ' is--sortedByDate') + (sortOrder === 'ascending' ? ' is--sortedAscending' : ' is--sortedDescending')}>
							<div className='sortableColumnHeaders-name' onClick={() =>{this.props.sortLibrary('byName')}}>
								Name
								<Icon name='chevron-small-up'/>
								<Icon name='chevron-small-down'/>
							</div>
							<div className='sortableColumnHeaders-modified' onClick={() =>{this.props.sortLibrary('byDate')}}>
								Modified
								<Icon name='chevron-small-up'/>
								<Icon name='chevron-small-down'/>
							</div>
						</div>

					</StickyDividerWrapper>
					{connectDropTarget(
					<div className='library-table' tabIndex='-1' onKeyDown={this.props.keyPressNavigation} id='focusableLibraryTable'>	
						{Object.keys(folders).map(this.renderFolder)}		
						{Object.keys(libraryData).map(this.renderLibraryItem)}	

						{libraryData.length === 0 && folders.length === 0 && this.props.allSets && this.props.allQuestions &&						
							<div className='library-table-emptyState'>
								Empty
							</div>								
						}

					</div>
					)} 
				</div>
				
				{!this.props.activeItem.ancestors && Object.keys(this.props.multiSelectItems).length < 2 &&
					<SetAndQuestionContextMenu key={this.props.activeItem.id} {...this.props}/>
				}
				
				{this.props.activeItem.ancestors && Object.keys(this.props.multiSelectItems).length < 2 &&
					<FolderContextMenu  key={this.props.activeItem.id} {...this.props} />
				}

				{Object.keys(this.props.multiSelectItems).length >1 &&
					<MultiSelectContextMenu 
						key={`${this.props.activeItem.id}_${Object.keys(this.props.multiSelectItems).length}`} 
						{...this.props} />
				}

			</div>
		)
	}
}


export default withRouter(DropTarget([ItemTypes.ITEMCELL,ItemTypes.FOLDERCELL], libraryTableTarget, collect)(LibraryPage))



import React from 'react'
import { Spring, animated } from 'react-spring/renderprops' 

function calculateItemLayout(nowPlayingLayout,choiceIndex){
	// VIEWPORT DIMENSIONS
	const viewportWidth = nowPlayingLayout.viewportWidth
	const viewportHeight = nowPlayingLayout.viewportHeight    
	// applied to bottom left and right
	const windowZoomItemInternalPadding = 20 // PASS IN FROM SLIDE DEFINITIONS
	// height of choice letterblock at 1280 (120px), plus a bit of padding as we're only applying internal padding to bottom
	const unscaledHeightChoiceLettersPanel = 120
	const windowZoomChoiceLettersTopPadding = (nowPlayingLayout.slideDimensions.width / 1280 * unscaledHeightChoiceLettersPanel) 

	//
	// START ZOOM ITEM DIMENSIONS

	// Find position of original on-slide video container
	const questionVideoZoomElement = document.getElementById(`choice${choiceIndex}FitImageZoomable`)

	let rect
	if(questionVideoZoomElement){
		rect = questionVideoZoomElement.getBoundingClientRect()
	}
 
	let itemStartZoomWidth = 0
	let itemStartZoomHeight = 0
	let itemStartZoomX = 0
	let itemStartZoomY = 0

	if(rect){
		itemStartZoomWidth = rect.width
		itemStartZoomHeight = rect.height
		itemStartZoomX = rect.x
		itemStartZoomY = rect.y
	}

	const itemAspect = itemStartZoomWidth / itemStartZoomHeight
 
	//
	// END ZOOM ITEM DIMENSIONS    

		// find maximum available width and height
		const itemEndZoomMaxWidth = viewportWidth - windowZoomItemInternalPadding - windowZoomItemInternalPadding
		const itemEndZoomMaxHeight = viewportHeight - windowZoomItemInternalPadding - windowZoomChoiceLettersTopPadding

		const itemEndZoomMaxAvailableAspect = itemEndZoomMaxWidth / itemEndZoomMaxHeight

		// find actual fitting item width and height
		let itemEndZoomWidth 
		let itemEndZoomHeight

		if(itemEndZoomMaxAvailableAspect >= itemAspect){ // screen wider than item
			itemEndZoomHeight = itemEndZoomMaxHeight
			itemEndZoomWidth = itemEndZoomHeight * itemAspect
		}
		if(itemEndZoomMaxAvailableAspect < itemAspect){ // screen narrower than item
			itemEndZoomWidth = itemEndZoomMaxWidth
			itemEndZoomHeight = itemEndZoomWidth / itemAspect
		}

		// find end-state X and Y, this is to viewport
		// basically center, for Y we also need to offset with the top padding
		const itemEndZoomX = (viewportWidth - itemEndZoomWidth) / 2
		const itemEndZoomY = Math.max(((viewportHeight - itemEndZoomHeight) / 2), windowZoomChoiceLettersTopPadding)


	//
	// ZOOM START --> ZOOM END
	
	// SCALE FACTOR
	const itemEndZoomScale = itemEndZoomWidth / itemStartZoomWidth

	// TRANSFORM ORIGIN (this effectively translates position of slide)
	const transformOriginX = (itemStartZoomX - itemEndZoomX) / (itemEndZoomWidth - itemStartZoomWidth)
	const transformOriginY = (itemStartZoomY - itemEndZoomY) / (itemEndZoomHeight - itemStartZoomHeight)
	
	
	//
	// STYLING IS FIXED
	// zooming also repositions slide through transform origin location

	const itemStyle = {
		width: `${itemStartZoomWidth}px`, 
		height: `${itemStartZoomHeight}px`,
		left: `${itemStartZoomX}px`,
		top: `${itemStartZoomY}px`,             
		transformOrigin: `${transformOriginX * 100}% ${transformOriginY * 100}%`,
	}

	return{
		itemStyle:itemStyle,
		itemEndZoomScale:itemEndZoomScale
	}
}


class LiveViewChoiceFitImage extends React.Component{

	constructor(props){
		super()
		this.zoomAnimationFinished=this.zoomAnimationFinished.bind(this)  
		this.calculateLayout=this.calculateLayout.bind(this)  
		this.state={
			zoomActivePhase:false,
			carouselIsOpen:false,
			itemStyle:{},
			itemEndZoomScale:0
		}		
	}

	componentDidMount(){
		this.calculateLayout()
	}

	componentDidUpdate(prevProps) {
		if ((this.props.layoutKey !== prevProps.layoutKey) || (this.props.showStudentList!==prevProps.showStudentList)) {
			this.calculateLayout()
		 }    
	}

	calculateLayout(){    
		const itemLayout=calculateItemLayout(this.props.nowPlayingLayout,this.props.choiceIndex,this.props.choiceCount,this.props.videoWidth, this.props.videoHeight)
		this.setState({
			itemStyle:itemLayout.itemStyle,
			itemEndZoomScale:itemLayout.itemEndZoomScale,
		})
	}


	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.choiceMediaZoomed !== this.props.choiceMediaZoomed){
			if(nextProps.choiceMediaZoomed){
				this.setState({zoomActivePhase:true})
			}	   
		}
	}

	zoomAnimationFinished(){
		if(!this.props.choiceMediaZoomed){
			this.setState({zoomActivePhase:false})
			this.setState({carouselIsOpen:false})
		}
		if(this.props.choiceMediaZoomed){
			this.setState({carouselIsOpen:true})
		}
	} 

	render(){		
		const {nowPlayingLayout, animationDisabled,choiceIndex,choiceMediaZoomed,activeZoomedChoiceIndex,isLastZoomedItem} = this.props 
		const {carouselIsOpen,itemStyle,itemEndZoomScale} = this.state
		// VIEWPORT DIMENSIONS
		const viewportWidth = nowPlayingLayout.viewportWidth
 
		//
		// CAROUSEL OFFSET

		const pageWidth = viewportWidth
		const indexDifferent = choiceIndex - activeZoomedChoiceIndex
		const isActiveZoomedChoice = choiceIndex === activeZoomedChoiceIndex

		const carouselOffset = pageWidth * indexDifferent  

		let zoomMediaScaledTranslateXCarousel = carouselOffset / itemEndZoomScale
		
		let setDurationTo0 = false

		if(!carouselIsOpen && !isActiveZoomedChoice){
			setDurationTo0 = true
		}

		if(carouselIsOpen && !choiceMediaZoomed && !isActiveZoomedChoice){
			setDurationTo0 = true
		}
		
		const setDelay = !carouselIsOpen && !isActiveZoomedChoice
		
		// Z-INDEX MANAGEMENT  
		const moveItemBelowDarkBG = (!choiceMediaZoomed && !isLastZoomedItem) || (!carouselIsOpen && !isLastZoomedItem)

		
		return(			
			
			<React.Fragment>
				<Spring
					onRest={this.zoomAnimationFinished}
					native           
					config={{
						duration: setDurationTo0 ? 0 : undefined,
						delay: setDelay ? 350 : undefined                    
					}}
					from={{ transform: 'scale(1) translateX(0px)' }}
					to={{ transform: (choiceMediaZoomed && itemEndZoomScale) ? `scale(${itemEndZoomScale}) translateX(${zoomMediaScaledTranslateXCarousel})` : 'scale(1) translateX(0px)'}}          
					immediate={animationDisabled}
				>
					{props => 
						<animated.div onClick={this.props.toggleZoomMedia} key={choiceIndex} style={{...itemStyle, ...props}}  className={'liveView-mediaItem liveView-mediaItem--choiceFitImage ' + (isLastZoomedItem ? ' liveView-mediaItem--isLastZoomedItem ' : '') + (moveItemBelowDarkBG ? ' liveView-mediaItem--moveItemBelowDarkBG ' : '')}>
							
							<img         	
								src={this.props.imageDeliveryURL}
								className='liveView-mediaItem--choiceFitImage-image'
								alt=''
							/>
							{/*}    

									viewportHeight: {viewportHeight} <br/>
							
							itemEndZoomHeight: {itemEndZoomHeight} <br/>

							itemEndZoomY: {itemEndZoomY} <br/>              

							InternalPadding: {windowZoomItemInternalPadding}

							choiceLettersTopPadding: {windowZoomChoiceLettersTopPadding}

												
							{choiceIndex}<br/>
							indexDifferent: {indexDifferent}px <br/>
							carouselOffset: {carouselOffset}px  <br/>
							zoomMediaScaledTranslateXCarousel: {zoomMediaScaledTranslateXCarousel}px <br/>
							<br/>

							{/*
							{choiceMediaZoomed ? 'Choices Zoomed' : 'Choices Not Zoomed'}<br/>
							{isActiveZoomedChoice ? 'This Choice Zoomed' : 'This Choice Not Zoomed'}<br/>
							{itemStartZoomWidth ? `${itemStartZoomWidth}` : 'No itemStartZoomWidth'}<br/>
							{itemEndZoomWidth ? `${itemEndZoomWidth}` : 'No itemEndZoomWidth'}<br/>
							{itemEndZoomScale ? `${itemEndZoomScale}` : 'No Item End Zoom Scale'}<br/>
							{zoomMediaScaledTranslateXCarousel ? `${zoomMediaScaledTranslateXCarousel}` : 'No zoomMediaScaledTranslateXCarousel'}<br/>
							*/}
							

							{/*
							viewportWidth: {viewportWidth} <br/>
							viewportHeight: {viewportHeight} <br/>
							itemEndZoomMaxWidth: {itemEndZoomMaxWidth} <br/>
							itemEndZoomMaxHeight: {itemEndZoomMaxHeight} <br/>
							itemEndZoomWidth: {itemEndZoomWidth} <br/>
							itemEndZoomHeight: {itemEndZoomHeight} <br/>
							itemEndZoomX: {itemEndZoomX} <br/>
							itemEndZoomY: {itemEndZoomY} <br/>              
							*/}

							{/*<img              	
								src={this.props.imageDeliveryURL}
								className='NPZoom-slide-fitImage-image'
							/>
							*/}
																 
						</animated.div>
					}
				</Spring>		
			</React.Fragment>

		)
	}
}


export default LiveViewChoiceFitImage


import React from 'react'

// Message shown in right panel slide list once free user reaches max questions in set
class SetEditorMaxFreeLimitMessage extends React.Component {
  showUpgrade() {
    window.location.hash = 'upgrade'
  }

  openNewSetEditor() {
    window.open('/seteditor/newSet', '_blank')
  }

  render() {
    const { maxNumOfQuestionsReached, questionsInSetLimit } = this.props
    let setLimitLabel
    if (questionsInSetLimit === 5) { // set limit can be 5 or 12 (Set question limit A/B test done 2021)
      setLimitLabel = 'five'
    } else if (questionsInSetLimit === 12) {
      setLimitLabel = '12'
    }
    return (
      <React.Fragment>
        {(questionsInSetLimit === 5 || (questionsInSetLimit === 12 && maxNumOfQuestionsReached)) && (
        <div className={`editor-slideListContainer-maxFreeLimitMessage ${maxNumOfQuestionsReached ? 'editor-slideListContainer-maxFreeLimitMessage--tall' : 'editor-slideListContainer-maxFreeLimitMessage--short'}`}>
          <div
            className={`editor-slideListContainer-maxFreeLimitMessage-head ${
              maxNumOfQuestionsReached ? ' editor-slideListContainer-maxFreeLimitMessage-head--visible' : 'editor-slideListContainer-maxFreeLimitMessage-head--hidden'}`}
          >
            Free accounts are limited to
            <br />
            {setLimitLabel}
            {' '}
            questions per Set
          </div>
          <div
            className='editor-slideListContainer-maxFreeLimitMessage-proLink'
            onClick={this.showUpgrade}
          >
            Learn about Plickers Pro
          </div>
          <div
            className={maxNumOfQuestionsReached ?
              'editor-slideListContainer-maxFreeLimitMessage-maxReachedSection--visible' :
              'editor-slideListContainer-maxFreeLimitMessage-maxReachedSection--hidden'}
          >
            <div className='editor-slideListContainer-maxFreeLimitMessage-or'>
              or
            </div>
            <div
              className='editor-slideListContainer-maxFreeLimitMessage-setLink'
              onClick={this.openNewSetEditor}
            >
              Continue with new Set
            </div>
          </div>
        </div>
        )}
      </React.Fragment>
    )
  }
}

export default SetEditorMaxFreeLimitMessage

import {Plugin} from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"
import React from 'react'
import ReactDOM from 'react-dom'
import ChoiceShortcutHints from '../../components/richEditor/nodeViews/ChoiceShortcutHints'

//plugin to add border styling to focused input 

export function focusedInputPlugin() {
  return new Plugin({
    props: {
      decorations: ({ doc, plugins, selection }) => {
        let focused=false
        if(window.view){
          focused=window.view.hasFocus()
        }
        if(!focused) {
          return false
        }
        const {anchor}=selection
        let decorations=[]
        let slideWidth
        let questionOrderArray=[]
        doc.descendants((node, pos) => {
          if(node.type.name==='question'){
            slideWidth=node.attrs.slideWidth
            questionOrderArray=node.attrs.questionOrderArray
          }
          const hasAnchor = anchor >= pos && anchor <= (pos + node.nodeSize)
          if (hasAnchor && node.type.name==='questionBody') {
            const decoration = Decoration.node(pos, pos + node.nodeSize, {
              class: 'slide-body--focused'
            })
            decorations.push(decoration)
          }
          else if (hasAnchor && node.type.name==='choice') {
            const decoration = Decoration.node(pos, pos + node.nodeSize, {
              class: 'slide-choice--focused'
            })
            decorations.push(decoration)

            const tooltipsDiv=document.createElement("span")            
            ReactDOM.render(<ChoiceShortcutHints
              slideWidth={slideWidth}
              questionCount={questionOrderArray.length}
              />, tooltipsDiv)
            decorations.push(Decoration.widget(pos+1, tooltipsDiv))
          }
        })
        return DecorationSet.create(doc, decorations)
      }
    }
  })
}
import planRestrictions from '../staticData/planRestrictions.js'
import store from '../store'

//We save plan in local storage and use it to set initial state if available
//primarily so we can guess the set limit when open a set in new tab (before have fetched service from API)
//otherwise have to load with a set limit and then remove or the other way around which can cause a UI flash

function getInitialState(){
	const product = JSON.parse(localStorage.getItem('service.product'))
	const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
	if(product){
		if(product==='plus'){
			return planRestrictions.plus
		}else if(setLimitTestGroup){
			if(setLimitTestGroup==='a'){
				return planRestrictions['free_test_a']
			}else{
				return planRestrictions['free_test_b']
			}
		}else return planRestrictions.free
	
	}else return planRestrictions.free
}

export default function (state = getInitialState(), action) {
	switch (action.type) {

	case 'FETCH_SERVICE_SUCCESS':
		if(action.response.product==='plus'){
			return planRestrictions.plus
		}else{
			const setLimitTestGroup = JSON.parse(localStorage.getItem('meta.setGroup'))
			if(setLimitTestGroup){
				if(setLimitTestGroup==='a'){
					return planRestrictions['free_test_a']
				}else{
					return planRestrictions['free_test_b']
				}
			}else return planRestrictions.free
		}
	
	case 'FETCH_META_SUCCESS':
		const fullAppState = store.getState()
		const meta=action.response
		let newPlanRestrictions=state
		if(meta.testGroup && meta.testGroup.setLimit){
			const service=fullAppState.service
			if(service.product !=='plus'){
				if(meta.testGroup.setLimit==='a'){
					newPlanRestrictions=planRestrictions['free_test_a']
				}
				else if(meta.testGroup.setLimit==='b'){
					newPlanRestrictions=planRestrictions['free_test_b']
				}	
			}
			
		}
		return newPlanRestrictions

	case 'UPDATE_SERVICE_SUCCESS':
		if(action.response.product==='plus'){
			return planRestrictions.plus
		}else return planRestrictions.free


	case 'LOGOUT':
		return getInitialState()
	
	default:
		return state
	}
}

import React from 'react'
import ModalWrapper from './ModalWrapper'
import ManageSectionsContainer from '../../containers/sections/ManageSectionsContainer'

const ManageSectionsModal = props => {


	return (
		<div className='manageSections-modal'>
			<ModalWrapper
				{...props}
				title="Manage Classes"       	
				modalStyle="dark"
			>
				<ManageSectionsContainer {...props}/>

			</ModalWrapper>

		</div>

	)
}

export default ManageSectionsModal
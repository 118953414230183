import React from 'react'
import InputField from '../uiKit/InputField'
import Button from '../uiKit/Button'
import { isPasswordValid } from '../../utils/passwordValidator'

class VerifyPasswordToSigninForm extends React.Component {
  constructor() {
    super()
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleForgotPasswordKeyDown = this.handleForgotPasswordKeyDown.bind(this)

    this.state = { password: '' }
  }

  handleInputChange(e) {
    this.setState({ password: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { password } = this.state
    const { onSubmit } = this.props
    onSubmit(password)
  }

  handleForgotPasswordKeyDown(e) {
    const { goToForgotPassword } = this.props
    if (e.keyCode === 13) goToForgotPassword()
  }

  render() {
    const {
      email,
      signinMethodString,
      submitLoading,
      goToForgotPassword,
    } = this.props
    const { password } = this.state

    return (
      <form
        className='verifyPasswordToSignin-form'
        onSubmit={this.handleSubmit}
      >
        {/* hidden email input as recommended goo.gl/9p2vKq */}
        <input
          hidden
          type='text'
          autoComplete='email'
          readOnly
          value={email}
        />
        <InputField
          className='verifyPasswordToSignin-form-passwordInput'
          type='password'
          placeholder='Password'
          autoComplete='current-password'
          autoFocus
          value={password}
          onChangeFunction={(e) => this.handleInputChange(e)}
        />
        <div className='verifyPasswordToSignin-form-note'>
          {`We'll add ${signinMethodString} to your account so you can skip this step in the future.`}
        </div>
        <Button
          size='large'
          label='Sign in'
          type='submit'
          submitLoading={submitLoading}
          disabled={!isPasswordValid(password)}
        />
        <div className='verifyPasswordToSignin-form-forgotPasswordContainer'>
          <div
            className='verifyPasswordToSignin-form-forgotPasswordLink'
            role='button'
            tabIndex={0}
            onClick={goToForgotPassword}
            onKeyDown={this.handleForgotPasswordKeyDown}
          >
            Forgot Password?
          </div>
        </div>
      </form>
    )
  }
}

export default VerifyPasswordToSigninForm

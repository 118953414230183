export function isPasswordValid(password) {
  return password && password.length >= 6
}

export function getPasswordErrorMessage(value) {
  if (!value) return 'Sorry but we are going to need you to tell us that'
  return isPasswordValid(value) ?
    undefined :
    'Password too short'
}

export function getNewPasswordErrorMessage(value) {
  if (!value) return 'Please enter a new password'
  if (value.length < 8) return 'Password too short'
  if (value.length > 72) return 'Password must be no more than 72 characters'
  return undefined
}

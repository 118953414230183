import React, { Component } from 'react'
import { connect } from 'react-redux'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import { showNotification } from '../../actions/notifications'
import { moveToRepo } from '../../utils/moveToRepo'
import { moveToFolderInLibrary } from '../../utils/moveToFolder'
import Button from '../../components/uiKit/Button'
import Icon from '../../components/misc/Icons'
import { getQuestionBodyLabel } from '../../utils/getQuestionBodyLabel'
import { getRepoforId } from '../../utils/getRepoforId'
import { fetchQuestionsNoMinTime } from '../../actions/questions'
import { fetchSetsNoMinTime } from '../../actions/sets'
import { fetchFolders } from '../../actions/folders'

// Finder style folder explorer
// shown when select move to folder for item in Library

class MoveToFolderExporerColumn extends React.Component {
  constructor() {
    super()
    this.ensureColumnVisible = this.ensureColumnVisible.bind(this)
  }

  componentDidMount() {
    // if it is the last column on right we want to scroll to make sure it is on screen
    if (this.props.ensureVisible === true) {
      this.ensureColumnVisible()
    }
  }

  ensureColumnVisible() {
    const columnNode = this.column
    if (columnNode) {
      columnNode.scrollIntoView({ block: 'end', behavior: 'instant' })
    }
  }

  render() {
    return (
      <div className="moveToFolder-explorer-column" ref={(c) => { this.column = c }}>
        {this.props.children}
      </div>
    )
  }
}

class MoveToFolderExporerRow extends React.Component {
  constructor() {
    super()
    this.selectRow = this.selectRow.bind(this)
  }

  selectRow(isSelectable, treeNode) {
    if (isSelectable) {
      this.props.onSelectNode(treeNode)
    }
  }

  render() {
    const {
      treeNode, title, isSelected, isLibrary, isRepo, selectedFolder, itemsToMove,
    } = this.props
    let hasChildren = true
    let name = title
    let isOpen = false
    let isSelectable = true
    if (treeNode) {
      if (treeNode.readOnly) {
        isSelectable = false
      }
      if (itemsToMove) {
        if (itemsToMove[treeNode.id]) {
          isSelectable = false
        }
      }
      name = treeNode.name
      if (selectedFolder) {
        isOpen = includes(selectedFolder.ancestors, treeNode.id)
      }
      if (treeNode.children) {
        if (treeNode.children.length === 0) {
          hasChildren = false
        }
      }
    }

    const isFolder = !isLibrary && !isRepo
    return (
      <div className={`moveToFolder-explorer-row${isLibrary ? ' is--library' : ''}${isRepo ? ' is--repo' : ''}${isOpen ? ' is--open' : ''}${isSelected ? ' is--selected' : ''}${isSelectable ? '' : ' is--notSelectable'}`} onClick={() => { this.selectRow(isSelectable, treeNode) }}>
        {isFolder &&
        <Icon name="folder" />}
        <div className="moveToFolder-explorer-row-label">
          {name}
        </div>
        {hasChildren &&
        <Icon name="chevron-right" />}
      </div>
    )
  }
}

class MoveToFolderContainer extends Component {
  constructor(props) {
    super(props)
    this.renderFolder = this.renderFolder.bind(this)
    this.moveToFolder = this.moveToFolder.bind(this)
    this.calculateFolderChildren = this.calculateFolderChildren.bind(this)
    this.getFolderChildren = this.getFolderChildren.bind(this)
    this.renderAncestorColumns = this.renderAncestorColumns.bind(this)
    this.selectNode = this.selectNode.bind(this)
    this.selectLibrary = this.selectLibrary.bind(this)
    this.moveToLibrary = this.moveToLibrary.bind(this)
    this.selectRepo = this.selectRepo.bind(this)
    this.moveToRepo = this.moveToRepo.bind(this)
    this.refreshData = this.refreshData.bind(this)

    const { itemsToMove } = props
    let selectedRepoId = null
    let librarySelected = false
    let selectedFolder = null
    let selectedRepo = null

    if (itemsToMove) {
      const keys = Object.keys(itemsToMove)
      selectedRepoId = itemsToMove[keys[0]].repo
      if (!selectedRepoId) {
        librarySelected = true
      } else {
        selectedRepo = find(props.repos, { id: selectedRepoId }) || null
      }
      const selectedFolderId = itemsToMove[keys[0]].folder
      selectedFolder = find(props.folders, { id: selectedFolderId }) || null
    }

    this.state = {
      selectedFolder,
      selectedRepo,
      selectedRepoId,
      librarySelected,
      folderTree: [],
      buttonDisabled: false,
      hasSelectedNode: false,
    }
  }

  componentDidMount() {
    this.calculateFolderChildren()
  }

  refreshData() {
    this.props.fetchFolders()
    this.props.fetchQuestionsNoMinTime()
    this.props.fetchSetsNoMinTime()
  }

  // Calculate a folder tree where each top level folder has an array of child folders
  // which in turn have an array of their children etc
  calculateFolderChildren() {
    const { folders } = this.props
    for (let i = 0; i < folders.length; i++) {
      const children = this.getFolderChildren(folders[i].id)
      folders[i].children = children
    }
    this.setState({
      folderTree: folders,
    })
  }

  getFolderChildren(folderId) {
    const folderChildren = filter(this.props.folders, {
      parent: folderId,
    })
    for (let i = 0; i < folderChildren.length; i++) {
      const children = this.getFolderChildren(folderChildren[i].id)
      if (children.length !== 0) {
        folderChildren[i].children = children
      }
    }
    return folderChildren
  }

  renderFolder(folder) {
    return (
      <div
        key={folder.id}
        onClick={() => { this.setState({ selectedFolder: folder.id }) }}
      >
        {folder.name}
      </div>
    )
  }

  // TODO could combine move to folder/library/repo
  moveToFolder() {
    this.setState({ buttonDisabled: true })
    const { itemsToMove } = this.props
    const folder = this.state.selectedFolder
    const promises = []
    const keys = Object.keys(itemsToMove)

    keys.map((key) => {
      const item = itemsToMove[key]
      promises.push(moveToFolderInLibrary(item, folder.id))
      return null
    })

    Promise.all(promises).then(() => {
      this.props.hideModal()
      this.props.clearSelected()
      let notificationMessage
      if (keys.length === 1) {
        const item = itemsToMove[keys[0]]
        const name = item.name || getQuestionBodyLabel(item)
        notificationMessage = `${name} to folder ${folder.name}`
      } else {
        notificationMessage = `${keys.length} items to folder ${folder.name}`
      }
      this.refreshData()
      this.props.showNotification(notificationMessage, 'Moved', 'default')
    })
  }

  moveToLibrary() {
    this.setState({ buttonDisabled: true })
    const { itemsToMove } = this.props
    const promises = []
    const keys = Object.keys(itemsToMove)

    keys.map((key) => {
      const item = itemsToMove[key]
      promises.push(moveToFolderInLibrary(item, null))
      return null
    })

    Promise.all(promises).then(() => {
      this.props.hideModal()
      this.props.clearSelected()
      let notificationMessage
      if (keys.length === 1) {
        const item = itemsToMove[keys[0]]
        const name = item.name || getQuestionBodyLabel(item)
        notificationMessage = `${name} to Your Library`
      } else {
        notificationMessage = `${keys.length} items to Your Library`
      }
      this.refreshData()
      this.props.showNotification(notificationMessage, 'Moved', 'default')
    })
  }

  moveToRepo() {
    this.setState({ buttonDisabled: true })
    const { itemsToMove } = this.props
    const { selectedRepo, selectedFolder } = this.state
    let folderId = null
    if (selectedFolder) {
      folderId = selectedFolder.id
    }
    const promises = []
    const keys = Object.keys(itemsToMove)

    keys.map((key) => {
      const item = itemsToMove[key]
      const { selectedRepo } = this.state
      promises.push(this.props.moveToRepo(item, selectedRepo.id, folderId))
      return null
    })

    Promise.all(promises).then(() => {
      this.props.hideModal()
      this.props.clearSelected()
      let notificationMessage
      if (keys.length === 1) {
        const item = itemsToMove[keys[0]]
        const name = item.name || getQuestionBodyLabel(item)
        notificationMessage = `${name} to ${selectedRepo.name}`
      } else {
        notificationMessage = `${keys.length} items to ${selectedRepo.name}`
      }
      this.refreshData()
      this.props.showNotification(notificationMessage, 'Moved', 'default')
    })
  }

  selectNode(treeNode) {
    this.setState({ selectedFolder: treeNode, hasSelectedNode: true })
  }

  selectLibrary() {
    this.setState({
      hasSelectedNode: true,
      selectedFolder: null,
      librarySelected: true,
      selectedRepo: null,
      selectedRepoId: null,
    })
  }

  selectRepo(repo) {
    this.setState({
      hasSelectedNode: true,
      selectedFolder: null,
      librarySelected: false,
      selectedRepo: repo,
      selectedRepoId: repo.id,
    })
  }

  renderAncestorColumns() {
    const { selectedFolder, folderTree } = this.state
    if (selectedFolder) {
      return selectedFolder.ancestors.slice(0).reverse().map((parent, index) => (
        <MoveToFolderExporerColumn key={`${index}_${parent}`}>
          {filter(folderTree, { parent })
            .map((treeNode, index) => (
              <MoveToFolderExporerRow
                itemsToMove={this.props.itemsToMove}
                treeNode={treeNode}
                key={index}
                onSelectNode={this.selectNode}
                isSelected={selectedFolder ? selectedFolder.id === treeNode.id : false}
                selectedFolder={selectedFolder}
              />
            ))}
        </MoveToFolderExporerColumn>
      ))
    } return null
  }

  render() {
    const { itemsToMove, repos } = this.props
    const {
      folderTree, selectedFolder, librarySelected, selectedRepo, selectedRepoId, hasSelectedNode,
    } = this.state
    let itemName = ''
    let multiSelectItems = false
    let currentRepoId
    if (itemsToMove) {
      const keys = Object.keys(itemsToMove)
      currentRepoId = itemsToMove[keys[0]].repo
      if (keys.length === 1) {
        itemName = itemsToMove[keys[0]].name || getQuestionBodyLabel(itemsToMove[keys[0]])
      } else {
        itemName = `${keys.length} items`
        multiSelectItems = true
      }
    }
    let buttonDisabled = false
    if (this.state.buttonDisabled || (!selectedFolder && !librarySelected && !selectedRepo)) {
      buttonDisabled = true
    }

    let sharedItem = false
    let publishedItem = false
    const repo = getRepoforId(currentRepoId)
    if (repo) {
      sharedItem = repo.shared
      publishedItem = repo.published
    }
    return (
      <div className="moveToFolder">
        <div className="moveToFolder-itemName">
          {itemName}
        </div>
        {sharedItem && !multiSelectItems && (
        /* eslint-disable max-len */
        <div className="moveToFolder-sharedItemWarning">
          If you move this item out of the current pack, members of the current pack may lose access to it.
        </div>
        )}
        {sharedItem && multiSelectItems && (
        <div className="moveToFolder-sharedItemWarning moveToFolder-sharedItemWarning--multi">
          If you move these items out of the current pack, members of the current pack may lose access to them.
        </div>
        )}
        {publishedItem && !multiSelectItems && (
        <div className="moveToFolder-sharedItemWarning">
          If you move this item out of the current pack, it will no longer be publicly accessible.
        </div>
        )}
        {publishedItem && multiSelectItems && (
        <div className="moveToFolder-sharedItemWarning moveToFolder-sharedItemWarning--multi">
          If you move these items out of the current pack, they will no longer be publicly accessible.
        </div>
        /* eslint-enable max-len */
        )}

        <div className="moveToFolder-explorer">

          <MoveToFolderExporerColumn key="library">
            <MoveToFolderExporerRow title="Your Library" isSelected={librarySelected} isLibrary onSelectNode={this.selectLibrary} />

            {repos.map((repo) => (
              <MoveToFolderExporerRow
                key={repo.id}
                title={repo.name}
                isRepo
                isSelected={selectedRepo && repo.id === selectedRepo.id}
                onSelectNode={() => { this.selectRepo(repo) }}
              />
            ))}

          </MoveToFolderExporerColumn>
          {(librarySelected || selectedFolder || selectedRepo) && (
          <MoveToFolderExporerColumn key="topLevel">
            {filter(filter(folderTree, { parent: null }), { repo: selectedRepoId })
              .map((treeNode, index) => (
                <MoveToFolderExporerRow
                  itemsToMove={itemsToMove}
                  treeNode={treeNode}
                  key={index}
                  onSelectNode={this.selectNode}
                  isSelected={selectedFolder ? selectedFolder.id === treeNode.id : false}
                  selectedFolder={selectedFolder}
                />
              ))}
          </MoveToFolderExporerColumn>
          )}

          {this.renderAncestorColumns()}

          {selectedFolder && (
          <MoveToFolderExporerColumn key={`${selectedFolder.id}`} ensureVisible>
            {filter(selectedFolder.children)
              .map((treeNode, index) => (
                <MoveToFolderExporerRow
                  itemsToMove={itemsToMove}
                  treeNode={treeNode}
                  key={index}
                  onSelectNode={this.selectNode}
                  isSelected={selectedFolder ? selectedFolder.id === treeNode.id : false}
                  selectedFolder={selectedFolder}
                />
              ))}
          </MoveToFolderExporerColumn>
          )}

        </div>
        {selectedFolder && !selectedRepo && hasSelectedNode &&
        <Button size="xLarge" color={buttonDisabled ? 'gray' : 'blue'} label={`Move to ${selectedFolder.name}`} onClickFunction={this.moveToFolder} />}
        {selectedFolder && selectedRepo && hasSelectedNode &&
        <Button size="xLarge" color={buttonDisabled ? 'gray' : 'blue'} label={`Move to ${selectedFolder.name}`} onClickFunction={this.moveToRepo} />}
        {selectedRepo && !selectedFolder && hasSelectedNode &&
        <Button size="xLarge" color={buttonDisabled ? 'gray' : 'blue'} label={`Move to ${selectedRepo.name}`} onClickFunction={this.moveToRepo} />}
        {librarySelected && !selectedFolder && hasSelectedNode &&
        <Button size="xLarge" color={buttonDisabled ? 'gray' : 'blue'} label="Move to Library" onClickFunction={this.moveToLibrary} />}
        {!hasSelectedNode &&
        <Button size="xLarge" color="gray" label="Select a Location" />}

      </div>
    )
  }
}

export default connect(
  (state) => ({
    folders: sortBy(filter(state.folders, { archived: false }), [function (o) {
      return o.name.toString().toLowerCase()
    }]),
    repos: sortBy(filter(filter(state.repos, (repo) => (repo.role !== 'viewer' && repo.role !== 'consumer' && repo.disabled === false)), { joined: true }), (o) => o.name.toString().toLowerCase()),
    itemsToMove: state.modals.itemsToMove,
    clearSelected: state.modals.clearSelected,

  }),
  {
    moveToRepo, showNotification, fetchQuestionsNoMinTime, fetchSetsNoMinTime, fetchFolders,
  },
)(MoveToFolderContainer)

import React from 'react'
import StaticQuestionSlide from '../slides/StaticQuestionSlide'
import {processQuestionResponses} from '../../utils/processQuestionResponses'
import {spring} from 'react-motion'
import LiveViewMainStudentList from './LiveViewMainStudentList'
import filter from 'lodash/filter'
import LiveViewPromptVideoPlayer from './media/LiveViewPromptVideoPlayer'
import LiveViewPromptSoundPlayer from './media/LiveViewPromptSoundPlayer'
import LiveViewPromptFitImage from './media/LiveViewPromptFitImage'
import LiveViewPromptFillImage from './media/LiveViewPromptFillImage'
import LiveViewPromptFitAnimatedGIF from './media/LiveViewPromptFitAnimatedGIF'
import LiveViewPromptFillAnimatedGIF from './media/LiveViewPromptFillAnimatedGIF'
import LiveViewChoiceVideoPlayer from './media/LiveViewChoiceVideoPlayer'
import LiveViewChoiceFitImage from './media/LiveViewChoiceFitImage'
import LiveViewChoiceFillImage from './media/LiveViewChoiceFillImage'
import LiveViewChoiceAnimatedGIF from './media/LiveViewChoiceAnimatedGIF'
import LiveViewChoiceSoundPlayer from './media/LiveViewChoiceSoundPlayer'	  				
import LiveViewDarkZoomBackground from './LiveViewDarkZoomBackground'
import LiveViewChoiceZoomLetterBlockPanel from './LiveViewChoiceZoomLetterBlockPanel'
 
const springConfig = {stiffness: 170, damping: 26}
const LETTERS_ARRAY=['A','B','C','D']


class LiveViewMain extends React.PureComponent{	
	render(){ 
		const {promptMediaZoomed,currentPollId,currentSetPollId,currentSetPollPollId,layout,section,isScan, showGraph, showCorrect,isReviewScreen,poll,isSurvey,liveViewSettings,snapshot} = this.props
		const students=filter(section.students,{archived:false})
		let showStudentList
		if(liveViewSettings.studentListVisible === 'always'){
			showStudentList = true			
		}else if(liveViewSettings.studentListVisible === 'never'){
			showStudentList = false
		}else if(liveViewSettings.studentListVisible === 'scan'){
			showStudentList = isScan
		}

		const slideScanTransitionStyle = showStudentList
	    ? { //when displayed
	       	transform: `translateY(${layout.yPosScanTranslate}px) translateX(${layout.xPosScanTranslate}px) scale(${layout.scanSlideScale})`	       	
	      }
	    : { //when not displayed
	  			transform: 'translateY(0px) translateX(0px) scale(1)'
	      }
		const slideScanStudentListStyle = showStudentList
	    ? { //when displayed
	       	xTranslate: spring(0, springConfig),
	       	opacity: spring(1, springConfig),
	      }
	    : { //when not displayed
    			xTranslate: spring(layout.studentListxPosScanTranslate, springConfig),
    			opacity: spring(0, springConfig),
	      }
		let studentLetterBlockSideDimension
		if(layout){		  	
			studentLetterBlockSideDimension = layout.studentListItemHeight - layout.studentListItemPillMargin - layout.studentListItemPillMargin
		}	
 		
 		let processedResponses
	 	if(poll && this.props.section && poll.hasResponses ){
	 		processedResponses=processQuestionResponses(poll,this.props.section)			
	 	}
	 
		let studentSortOrder='displayName'
 		if(liveViewSettings.studentSortOrder ==='card'){
 			studentSortOrder='card'
 		}
 		const {showStudentCardNumbers,alwaysShowLetterblock,colorScheme,showResponseCounts}=liveViewSettings
				
		const isScanScreen = isScan
		const isReviewOrScan = isReviewScreen || isScanScreen
		const isAskScreen = !isReviewScreen && !isScanScreen

		const lettersArray=['A','B','C','D']
		let correctLetters=[]
		
		if(poll&&poll.snapshot){
			poll.snapshot.choices.map((choice,index) => {
				if(choice.correct===true){
					const correctLetter=lettersArray[index]
					correctLetters.push(correctLetter)
				}
				return null
			})	
		}

		let key=''
		if(currentPollId){
			key=currentPollId
		}else if(currentSetPollId && currentSetPollPollId){
			key=`${currentSetPollId}_${currentSetPollPollId}`
		}

		const animationDisabled = liveViewSettings.disableZoomAnimations
		let choices=[]
		let choiceCount
		if(this.props.snapshot && this.props.snapshot.choices){
			choices=this.props.snapshot.choices
			choiceCount=choices.length
		}
		return(
			<React.Fragment> 
			
				{/* PROMPT BODY PLAYERS */}
				{this.props.snapshot && this.props.snapshot.media && this.props.snapshot.media.type === 'image' && this.props.snapshot.media.imageFill &&
					<LiveViewPromptFillImage						
						imageDeliveryURL={this.props.snapshot.media.deliveryUrl}				
						deliveryImageWidth={this.props.snapshot.media.deliveryDimensions.w}				
						deliveryImageHeight={this.props.snapshot.media.deliveryDimensions.h}				
						key={`fitImage_${key}`}
						layoutKey={this.props.layoutKey}
						nowPlayingLayout={layout}
						showStudentList={showStudentList}												
						promptMediaZoomed={promptMediaZoomed}
						toggleZoomMedia={()=>{this.props.toggleZoomMedia('promptMedia')}}					
						animationDisabled={animationDisabled}						
						isLastZoomedItem={this.props.lastZoomedItem === 'promptMedia'}
					/>
				}

				{this.props.snapshot && this.props.snapshot.media && this.props.snapshot.media.type === 'animatedGif' && this.props.snapshot.media.imageFill &&
					<LiveViewPromptFillAnimatedGIF
						GIF={this.props.snapshot.media}				
						deliveryImageWidth={this.props.snapshot.media.dimensions.w}				
						deliveryImageHeight={this.props.snapshot.media.dimensions.h}				
						key={`fitImage_${key}`}
						layoutKey={this.props.layoutKey}
						nowPlayingLayout={layout}
						showStudentList={showStudentList}												
						promptMediaZoomed={promptMediaZoomed}
						toggleZoomMedia={()=>{this.props.toggleZoomMedia('promptMedia')}}					
						animationDisabled={animationDisabled}						
						isLastZoomedItem={this.props.lastZoomedItem === 'promptMedia'}
					/>
				}
		
				{this.props.snapshot && this.props.snapshot.media && this.props.snapshot.media.type === 'image' && !this.props.snapshot.media.imageFill &&
					<LiveViewPromptFitImage
						imageDeliveryURL={this.props.snapshot.media.deliveryUrl}
						key={`fitImage_${key}`}
						layoutKey={this.props.layoutKey}
						nowPlayingLayout={layout}
						showStudentList={showStudentList}												
						promptMediaZoomed={promptMediaZoomed}
						toggleZoomMedia={()=>{this.props.toggleZoomMedia('promptMedia')}}					
						animationDisabled={animationDisabled}						
						isLastZoomedItem={this.props.lastZoomedItem === 'promptMedia'}
					/>
				}

				{this.props.snapshot && this.props.snapshot.media && this.props.snapshot.media.type === 'animatedGif' && !this.props.snapshot.media.imageFill &&
					<LiveViewPromptFitAnimatedGIF
						GIF={this.props.snapshot.media}
						key={`fitImage_${key}`}
						layoutKey={this.props.layoutKey}
						nowPlayingLayout={layout}
						showStudentList={showStudentList}												
						promptMediaZoomed={promptMediaZoomed}
						toggleZoomMedia={()=>{this.props.toggleZoomMedia('promptMedia')}}					
						animationDisabled={animationDisabled}						
						isLastZoomedItem={this.props.lastZoomedItem === 'promptMedia'}
					/>
				}		
				{snapshot && snapshot.media && snapshot.media.type === 'video' &&
					<LiveViewPromptVideoPlayer 
						key={`prompt_video_${key}`}
						showStudentList={showStudentList}	
						layoutKey={this.props.layoutKey}
						animationDisabled={animationDisabled}
						nowPlayingLayout={layout}
						video={snapshot.media}
						start={snapshot.media.start}	
						end={snapshot.media.end}		
						promptMediaZoomed={promptMediaZoomed}
						playbackItem={this.props.playbackItem}
						isPlaying={this.props.isPlaying}
						zoomMedia={()=>{this.props.zoomMedia('promptMedia')}}	
						clientPlaybackCommand={this.props.clientPlaybackCommand}
						clearPlaybackCommand={this.props.clearPlaybackCommand}					
						updateControlPlayback={this.props.updateControlPlayback}
						pageVisibilityState={this.props.pageVisibilityState}
						nowPlayingModalVisible={this.props.visible}
						showMouseMoveControls={this.props.showMouseMoveControls}
						unzoomMedia={this.props.unzoomMedia}
						isLastZoomedItem={this.props.lastZoomedItem === 'promptMedia'}
						showAutoplayFailedAlert={this.props.showAutoplayFailedAlert}
						hideAutoplayFailedAlert={this.props.hideAutoplayFailedAlert}
					/>
				}				

				{snapshot && snapshot.media && snapshot.media.type === 'sound' &&
					<LiveViewPromptSoundPlayer 		
						key={`prompt_sound_${key}`}		
						showStudentList={showStudentList}			
						pageVisibilityState={this.props.pageVisibilityState}
						nowPlayingModalVisible={this.props.visible}
						updateControlPlayback={this.props.updateControlPlayback}
						clientPlaybackCommand={this.props.clientPlaybackCommand}
						clearPlaybackCommand={this.props.clearPlaybackCommand}					
						isPlaying={this.props.isPlaying}
						playbackItem={this.props.playbackItem}
						zoomMedia={()=>{this.props.zoomMedia('promptMedia')}}						
						promptMediaZoomed={promptMediaZoomed}
						nowPlayingLayout={layout}
						layoutKey={this.props.layoutKey}
						animationDisabled={animationDisabled}
						template={this.props.snapshot.layout}
						sound={this.props.snapshot.media}
						showWaveform={true}
						widePlayer={this.props.snapshot.layout === "bodyCenterMediaCenter"}
						isLastZoomedItem={this.props.lastZoomedItem === 'promptMedia'}
						showAutoplayFailedAlert={this.props.showAutoplayFailedAlert}
						hideAutoplayFailedAlert={this.props.hideAutoplayFailedAlert}
					/>
				}

				
				{/* CHOICE ZOOM VIDEO */}
				
				{choices.map((choice, index)=>{
					let choiceMediaType
					const isChoiceMedia = true
					if(isChoiceMedia){
						if(choice.media && choice.media.type === 'video'){
							choiceMediaType = 'video'
						}else if(choice.media && choice.media.type === 'image' && !choice.media.imageFill){
							choiceMediaType = 'imageFit'
						}else if(choice.media && choice.media.type === 'image' && choice.media.imageFill){
							choiceMediaType = 'imageFill'
						}else if(choice.media && choice.media.type === 'animatedGif'){
							choiceMediaType = 'animatedGif'
						}else if(choice.media && choice.media.type === 'sound'){
							choiceMediaType = 'sound'
						}
					}

					const choiceLetter=LETTERS_ARRAY[index]
					const itemName=`choice${choiceLetter}`

					return (
						<React.Fragment key={index}>

							{choiceMediaType === 'imageFit' &&
								<LiveViewChoiceFitImage
									key={`choice_${index}_fit_image_${key}`}
									layoutKey={this.props.layoutKey}
									imageDeliveryURL={choice.media.deliveryUrl}
									choiceIndex={index}
									choiceMediaZoomed={this.props.choiceMediaZoomed}
									activeZoomedChoiceIndex={this.props.activeZoomedChoiceIndex}										
									nowPlayingLayout={layout}
									animationDisabled={animationDisabled}																						
									toggleZoomMedia={()=>{this.props.toggleZoomMedia(itemName)}}
									isLastZoomedItem={this.props.lastZoomedItem === itemName}
									showStudentList={showStudentList}
								/>
							}
							{choiceMediaType === 'imageFill' &&
								<LiveViewChoiceFillImage
									key={`choice_${index}_fill_image_${key}`}
									imageDeliveryURL={choice.media.deliveryUrl}
									deliveryImageWidth={choice.media.deliveryDimensions.w}
									deliveryImageHeight={choice.media.deliveryDimensions.h}						
									choiceIndex={index}
									layoutKey={this.props.layoutKey}		  							
									choiceMediaZoomed={this.props.choiceMediaZoomed}
									activeZoomedChoiceIndex={this.props.activeZoomedChoiceIndex}										
									nowPlayingLayout={layout}
									animationDisabled={animationDisabled}																						
									toggleZoomMedia={()=>{this.props.toggleZoomMedia(itemName)}}
									isLastZoomedItem={this.props.lastZoomedItem === itemName}
									showStudentList={showStudentList}
								/>
							}		  					
							{choiceMediaType === 'animatedGif' &&
								<LiveViewChoiceAnimatedGIF
									key={`choice_${index}_animatedGif_${key}`}
									GIF={choice.media}
									deliveryImageWidth={choice.media.dimensions.w}
									deliveryImageHeight={choice.media.dimensions.h}						
									choiceIndex={index}
									layoutKey={this.props.layoutKey}		  							
									choiceMediaZoomed={this.props.choiceMediaZoomed}
									activeZoomedChoiceIndex={this.props.activeZoomedChoiceIndex}										
									nowPlayingLayout={layout}
									animationDisabled={animationDisabled}																						
									toggleZoomMedia={()=>{this.props.toggleZoomMedia(itemName)}}
									isLastZoomedItem={this.props.lastZoomedItem === itemName}
									showStudentList={showStudentList}
								/>
							}
							{choiceMediaType === 'video' &&
								<LiveViewChoiceVideoPlayer 
									itemName={itemName}
									key={`choice_${index}_video_${key}`}
									layoutKey={this.props.layoutKey}
									video={choice.media}
									start={choice.media.start}
									end={choice.media.end}
									videoWidth={640}
									videoHeight={360}									
									choiceMediaZoomed={this.props.choiceMediaZoomed}
									activeZoomedChoiceIndex={this.props.activeZoomedChoiceIndex}										
									animationDisabled={animationDisabled}
									choiceIndex={index}
									nowPlayingLayout={layout}
									toggleZoomMedia={()=>{this.props.toggleZoomMedia(itemName)}}
									choiceCount={choiceCount}
									playbackItem={this.props.playbackItem}
									isPlaying={this.props.isPlaying}
									clientPlaybackCommand={this.props.clientPlaybackCommand}
									clearPlaybackCommand={this.props.clearPlaybackCommand}					
									updateControlPlayback={this.props.updateControlPlayback}
									pageVisibilityState={this.props.pageVisibilityState}
									nowPlayingModalVisible={this.props.visible}			
									unzoomMedia={this.props.unzoomMedia}
									zoomMedia={this.props.zoomMedia}						
									isLastZoomedItem={this.props.lastZoomedItem === itemName}
									choicesPlaying={this.props.playbackItem !== 'promptMedia' && this.props.isPlaying}
									isPlayingChoice={this.props.playbackItem === itemName && this.props.isPlaying}
									showStudentList={showStudentList}
									showAutoplayFailedAlert={this.props.showAutoplayFailedAlert}
									hideAutoplayFailedAlert={this.props.hideAutoplayFailedAlert}
								/>
							}
							{choiceMediaType === 'sound' &&
								<LiveViewChoiceSoundPlayer
									itemName={itemName}
									key={`choice_${index}_sound_${key}`}
									layoutKey={this.props.layoutKey}
									sound={choice.media}
									updateControlPlayback={this.props.updateControlPlayback}
									clientPlaybackCommand={this.props.clientPlaybackCommand}
									clearPlaybackCommand={this.props.clearPlaybackCommand}					
									isPlaying={this.props.isPlaying}
									playbackItem={this.props.playbackItem}
									choiceIndex={index}
									choiceMediaZoomed={this.props.choiceMediaZoomed}
									activeZoomedChoiceIndex={this.props.activeZoomedChoiceIndex}
									nowPlayingLayout={layout}
									animationDisabled={animationDisabled}				
									choiceCount={choiceCount}
									pageVisibilityState={this.props.pageVisibilityState}
									nowPlayingModalVisible={this.props.visible}
									isLastZoomedItem={this.props.lastZoomedItem === itemName}										
									choicesPlaying={this.props.playbackItem !== 'promptMedia' && this.props.isPlaying}
									isPlayingChoice={this.props.playbackItem === itemName && this.props.isPlaying}
									showStudentList={showStudentList}
									showAutoplayFailedAlert={this.props.showAutoplayFailedAlert}
									hideAutoplayFailedAlert={this.props.hideAutoplayFailedAlert}
								/>
							}
						</React.Fragment>
					)
				})}
				
				{/* CHOICE LETTER BLOCK PANEL UNDERLAY */}
				<LiveViewChoiceZoomLetterBlockPanel
					choiceMediaZoomed={this.props.choiceMediaZoomed}
					layout={layout}
					activeZoomedChoiceIndex={this.props.activeZoomedChoiceIndex}
					animationDisabled={animationDisabled}
					choices={choices}
					zoomMedia={this.props.zoomMedia}
					slideWidth={layout.slideDimensions.width}
					unzoomMedia={this.props.unzoomMedia}
				/>

				{/* DARK UNDERLAY */}
				<LiveViewDarkZoomBackground
					mediaZoomed={promptMediaZoomed || this.props.choiceMediaZoomed}
					animationDisabled={animationDisabled}
					unzoomMedia={this.props.unzoomMedia}
				/>				
				<div 
					//onDoubleClick={this.props.handleDoubleClick} turn off double click to enter-exit full screen
					className='liveView-slide' 
					style={{								
					width: `${layout.slideDimensions.width}px`, 
					height: `${layout.slideDimensions.height}px`,
					top: `${layout.yPosSlide}px`,
					left: `${layout.xPosSlide}px`,								
					transformOrigin: 'top left',
					//zIndex: (this.props.mediaZoomed || this.props.choiceMediaZoomed) ? 9000 : 1000
					zIndex: 2000,
					...slideScanTransitionStyle,
				}}>					
					{snapshot &&
						<StaticQuestionSlide 
							showResponseCounts={showResponseCounts}
							alwaysShowLetterblock={alwaysShowLetterblock}
							colorScheme={colorScheme}
							key={key}							 
							question={this.props.snapshot}
							displayWidth={layout.slideDimensions.width}									
							isLiveView={true}
							isAskScreen={isAskScreen}
							isScanScreen={isScanScreen}
							isReviewScreen={isReviewScreen}
							reportView={false}
							isSurvey={isSurvey}									
							showGraph={showGraph}
							showCorrect={showCorrect} 
							processedResponses={isReviewOrScan ? processedResponses : null}
							totalResponses={(isReviewOrScan && poll) ? (poll.assignedResponseCount + poll.unassignedResponseCount) : 0	}
							expectedResponses={(isReviewOrScan && poll) ? (students.length + poll.unassignedResponseCount) : 0	}
							mediaIsZoomable
							mediaIsPlayable={this.props.visible}
							pageVisibilityState={this.props.pageVisibilityState}
							xPosSlide={layout.xPosSlide}
							yPosSlide={layout.yPosSlide}
							toggleZoomMedia={this.props.zoomMedia}
							zoomMedia={this.props.zoomMedia}
							unzoomMedia={this.props.unzoomMedia}									
							mediaZoomed={this.props.mediaZoomed}									
							choiceMediaZoomed={this.props.choiceMediaZoomed}
							activeZoomedChoiceIndex={this.props.activeZoomedChoiceIndex}
							viewportWidth={layout.viewportWidth}
							viewportHeight={layout.viewportHeight}
							playbackItem={this.props.playbackItem}
							isPlaying={this.props.isPlaying}
							updateControlPlayback={this.props.updateControlPlayback}
							clientPlaybackCommand={this.props.clientPlaybackCommand}
							clearPlaybackCommand={this.props.clearPlaybackCommand}								
						/>	
					}
				</div>

				<LiveViewMainStudentList 
					key={key}
					showStudentCardNumbers={showStudentCardNumbers}
					slideScanStudentListStyle={slideScanStudentListStyle}
					colorScheme={colorScheme}
					layout={layout}
					students={students}
					manualShowIndividualStudentResponses={this.props.manualShowIndividualStudentResponses}
					showIndividualStudentResponses={liveViewSettings.showIndividualResponses}
					isAskScreen={isAskScreen}
					showCorrect={showCorrect}
					poll={poll}
					isSurvey={isSurvey}
					correctLetters={correctLetters}
					isScanScreen={isScanScreen}
					studentSortOrder={studentSortOrder}
					studentLetterBlockSideDimension={studentLetterBlockSideDimension}
					nowPlayingMode={this.props.nowPlayingMode}
					onlineStudents={this.props.onlineStudents}	
				/>
			</React.Fragment>
		)
	}
}

export default LiveViewMain

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import qs from 'qs'
import { getAccountData, deleteAccount } from '../actions/user'
import Button from '../components/uiKit/Button'
import Icon from '../components/misc/Icons'
import FeatureModalSelectControl from '../components/featureModals/FeatureModalSelectControl'
import getErrorMessageForRequest from '../utils/getErrorMessageForRequest'

function parseAttributes(location) {
  return qs.parse(location.search.slice(1))
}

function getUserDataArray(accountDataResponse) {
  const { user } = accountDataResponse
  const userDataArray = []
  userDataArray.push({
    header: 'first name',
    content: user.firstName,
  })
  userDataArray.push({
    header: 'last name',
    content: user.lastName,
  })
  userDataArray.push({
    header: 'email',
    content: user.email,
  })
  if (user.username) {
    userDataArray.push({
      header: 'username',
      content: user.username,
    })
  }
  return userDataArray
}

function getContentDataArray(accountDataResponse) {
  const { accountData } = accountDataResponse
  const {
    question, set, poll, setPoll, repo, section,
  } = accountData
  const contentDataArray = []
  if (repo) {
    contentDataArray.push({ name: 'Pack', count: repo })
  }
  if (question) {
    contentDataArray.push({ name: 'Question', count: question })
  }
  if (set) {
    contentDataArray.push({ name: 'Set', count: set })
  }
  if (section) {
    contentDataArray.push({ name: 'Class', namePlural: 'Classes', count: section })
  }
  if (poll || setPoll) {
    contentDataArray.push({ name: 'Report', count: (poll || 0) + (setPoll || 0) })
  }
  return contentDataArray
}

function getKey(str, type) {
  return str && str.replace(/\s+/g, '-') + type
}

class DeleteAccountContainer extends Component {
  constructor(props) {
    super(props)
    this.sendDeleteAccountRequest = this.sendDeleteAccountRequest.bind(this)
    this.state = {
      attributes: parseAttributes(props.location),
      loaded: false,
      showErrorMessage: false,
      errorMessage: '',
      userDataArray: [],
      contentDataArray: [],
      checked: false,
      submitLoading: false,
    }
  }

  UNSAFE_componentWillMount() {
    const { attributes } = this.state
    const { getAccountData } = this.props
    const { userId, token } = attributes
    if (!userId || !token) {
      this.setState({
        showErrorMessage: true,
        errorMessage: 'This link is broken',
      })
      return
    }
    getAccountData(attributes.userId, attributes.token)
      .then((response) => {
        this.setState({
          userDataArray: getUserDataArray(response),
          contentDataArray: getContentDataArray(response),
          loaded: true,
        })
      })
      .catch((error) => {
        const hasAuthFailed = error.response && error.response.status === 422
        const errorMessage = hasAuthFailed ?
          'This link is broken or expired' :
          getErrorMessageForRequest({
            requestName: 'getAccountData',
            response: error.response,
          })
        this.setState({
          showErrorMessage: true,
          errorMessage,
        })
      })
  }

  sendDeleteAccountRequest() {
    const { attributes } = this.state
    const { deleteAccount } = this.props

    this.setState({ submitLoading: true, showErrorMessage: false })

    deleteAccount(attributes.userId, attributes.token)
      .then(() => {
        this.setState({
          submitLoading: false,
          successMessage: 'account permanently deleted.',
        })
      })
      .catch((error) => {
        const errorMessage = getErrorMessageForRequest({
          requestName: 'deleteAccount',
          response: error.response,
        })
        this.setState({
          submitLoading: false,
          showErrorMessage: true,
          errorMessage,
        })
      })
  }

  render() {
    const {
      loaded,
      showErrorMessage,
      errorMessage,
      userDataArray,
      contentDataArray,
      checked,
      submitLoading,
      successMessage,
    } = this.state
    return (
      <DocumentTitle title='Plickers - Delete Account'>
        <div className='deleteAccount'>
          <div className='deleteAccount-centerBlock'>
            <div className='deleteAccount-centerBlock--top'>
              <div className='deleteAccount-centerBlock-logo'>
                <Icon name='logo' />
              </div>
            </div>
            <div className='deleteAccount-centerBlock--middle'>
              <div className='deleteAccount-centerBlock-title'>
                Delete Account
              </div>
              {loaded && (
                <React.Fragment>
                  <div className='deleteAccount-centerBlock-step'>
                    Step 3 of 3
                  </div>
                  <div className='deleteAccount-centerBlock-accountContent'>
                    {
                      userDataArray.map((data) => (
                        <div
                          key={getKey(data.header, 'element')}
                        >
                          <div
                            key={getKey(data.header, 'header')}
                            className='deleteAccount-centerBlock-accountContent-header'
                          >
                            {data.header}
                          </div>
                          <div
                            key={getKey(data.header, 'content')}
                            className='deleteAccount-centerBlock-accountContent-content'
                          >
                            {data.content}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  <div
                    className='deleteAccount-centerBlock-checkbox'
                  >
                    <FeatureModalSelectControl
                      checkbox
                      checked={checked}
                      onClick={() => { this.setState((prevState) => ({ checked: !prevState.checked })) }}
                      label={`I understand that deleting my account cannot be undone and that it will not be possible to recover the data in the account${contentDataArray.length ? ', including:' : '.'}`}
                    />
                  </div>
                  {!!contentDataArray.length && (
                    <ul className='deleteAccount-centerBlock-accountContent-content-list'>
                      {contentDataArray.map((content) => (
                        <li key={content.name}>
                          {`${content.count} ${content.count === 1 ? content.name : (content.namePlural || `${content.name}s`)}`}
                        </li>
                      ))}
                    </ul>
                  )}
                  <div className='deleteAccount-centerBlock-button'>
                    <Button
                      size='large'
                      color='red'
                      label='Delete my account'
                      name='submit'
                      disabled={!checked || successMessage}
                      submitLoading={submitLoading}
                      onClickFunction={this.sendDeleteAccountRequest}
                    />
                  </div>
                </React.Fragment>
              )}
              {!loaded && !showErrorMessage && (
                <div className='deleteAccount-authorizing'>
                  Authorizing...
                </div>
              )}
              {successMessage && (
                <div className='deleteAccount-successMessage'>
                  {successMessage}
                </div>
              )}
              {showErrorMessage && (
                <div className='deleteAccount-errorMessage'>
                  { errorMessage || 'something went wrong' }
                </div>
              )}
            </div>
          </div>
        </div>
      </DocumentTitle>
    )
  }
}

export default withRouter(connect(
  () => ({}),
  { getAccountData, deleteAccount },
)(DeleteAccountContainer))

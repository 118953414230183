import api from '../api'
import { loadInitialData } from './initialData'
import { clearUserAndSendToLogin } from './logoutInternal'
import { generateClientIdentityToken, trustClientIdentity } from './clientIdentity'

function setCurrentUser(dispatch, response) {
  // save session in local storage
  localStorage.setItem('ls.session', JSON.stringify(response))
  // call identify to set userId for segment tracking
  window.analytics.identify(response.user, {})
  dispatch({ type: 'AUTHENTICATION_SUCCESS', response })
  return null
}

export function login(data) {
  return commonLogin.bind(null, '/sessions', data)
}

export function externalLogin(data, doNotCreateUser) {
  let relativePath = '/sessions/external-signin'
  if (doNotCreateUser) { relativePath += '?doNotCreateUser=true' }
  return commonLogin.bind(null, relativePath, data)
}

async function commonLogin(path, data, dispatch, getState) {
  ensureClientIdentityToken(dispatch, getState)
  const response = await api.post(path, data)
  return handleLoginSuccess(dispatch, response)
}

function ensureClientIdentityToken(dispatch, getState) {
  const { clientIdentity } = getState()
  if (!clientIdentity) {
    generateClientIdentityToken(dispatch)
  }
}

function handleLoginSuccess(dispatch, response) {
  dispatch({ type: 'LOGIN_SUCCESS', response })
  setCurrentUser(dispatch, response)
  dispatch(loadInitialData(response.user))
  dispatch(trustClientIdentity(response))
  return response
}

export function signup(data) {
  return () => api.post('/users', data)
    .then((response) => response)
}

export function logout() {
  return (dispatch) => api.delete('/sessions')
    .then(() => clearUserAndSendToLogin(dispatch))
    .catch(() => {})
}

export function authenticate() {
  const url = '/sessions/self'
  localStorage.removeItem('plickers-tab-sync')
  return (dispatch) => {
    dispatch({ type: 'AUTHENTICATION_REQUEST' })
    return api.fetch(url)
      .then((response) => {
        setCurrentUser(dispatch, response)
        return response
      })
      .catch(() => clearUserAndSendToLogin(dispatch))
  }
}

export const unauthenticate = () => ({ type: 'AUTHENTICATION_FAILURE' })

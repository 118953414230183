import React from 'react'
import ModalWrapper from './ModalWrapper'
import LiveViewAlertContainer from '../../containers/modals/LiveViewAlertContainer'

const MoveToFolderModal = props => {


	return (
		<div className="liveViewAlert-modal">
			<ModalWrapper
				{...props}       	       
				modalStyle="empty"
			>
				<LiveViewAlertContainer {...props}/>
			</ModalWrapper>
		</div>
	)
}

export default MoveToFolderModal
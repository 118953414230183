import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_ADMIN_AWARDS_SUCCESS':	
		return action.response

	case 'UPDATE_AWARD_SUCCESS':	
		var indexOfAwardToUpdate = findIndex(state,(award => {
			return award.id === action.response.id
		}))
		if(indexOfAwardToUpdate !== -1 ){
			let award=state[indexOfAwardToUpdate]
			const repo=award.repo
			let newAward={...action.response,repo:repo}

			return [
					...state.slice(0,indexOfAwardToUpdate),
					newAward,
					...state.slice(indexOfAwardToUpdate + 1)
				]	
		}
		else return state


	case 'LOGOUT':
		return null
	
	default:
		return state
	}
}

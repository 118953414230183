import React, { Component } from 'react'
import { connect } from 'react-redux'
import {showExpandedReviewSlideModal ,hideModal} from '../../actions/modals'
import {processQuestionResponses} from '../../utils/processQuestionResponses'
import QuestionPollDetail from '../../components/pollDetail/QuestionPollDetail'
import DocumentTitle from 'react-document-title' 
import _ from 'lodash'
 
class SetPollPollDetailContainer extends Component { 

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Report',{pollType:'Set Poll Poll'})
		}
	}

	render() {
		const {section,currentPollPoll,poll}=this.props   
		let processedQuestionResponses
		let questionDetailPath
		const parentSectionId=this.props.match.params.sectionId
		if(currentPollPoll && poll && section){ 
			processedQuestionResponses = processQuestionResponses(currentPollPoll,section)
			if(parentSectionId){
				questionDetailPath=`/classes/${parentSectionId}/setreport/${poll.id}`
			}else{
				questionDetailPath=`/setreport/${poll.id}`
			}
		}

		let documentTitle='Report'
		if(currentPollPoll && currentPollPoll.snapshot){
			documentTitle=`${currentPollPoll.snapshot.body} Report - Plickers`
		}
		return( 
			<DocumentTitle title={documentTitle}>
				<div className='page questionReport page--defaultLayout'>
					{currentPollPoll && poll && section &&
						<QuestionPollDetail
							parentSectionId={parentSectionId}
							setPollId={poll.id}
							poll={currentPollPoll}
							section={section}
							processedQuestionResponses={processedQuestionResponses}
							questionDetailPath={questionDetailPath}
							isInSet={true}
							showExpandedReviewSlideModal={this.props.showExpandedReviewSlideModal}
						/>
					}
				</div> 
			</DocumentTitle>
		)		
	}
}
 
function mapStateToProps(state,ownProps) {
	const currentPoll= _.find(state.historySetPolls, {'id':ownProps.match.params.pollId})
	if(currentPoll){
		var currentSection=_.find(state.sections, {'id':currentPoll.section})
		var currentPollPoll=_.find(currentPoll.polls,{'question':ownProps.match.params.pollPollId})
	}
	return {
		poll:currentPoll,
		section:currentSection,
		currentPollPoll:currentPollPoll, 
	}
}

export default connect(
	mapStateToProps,
	{showExpandedReviewSlideModal ,hideModal}
)(SetPollPollDetailContainer)
import findIndex from 'lodash/findIndex'


export default function (state=[], action) {

	switch(action.type) {
		
	case 'FETCH_ARCHIVED_FOLDERS_SUCCESS':
		let newState = [...state] || []
		action.response.forEach(folder => {
			const folderId = folder.id
			let indexOfFolder = newState.findIndex(folder => {
				return folder.id === folderId
			})
	
			if(indexOfFolder === -1){
				indexOfFolder=newState.length
			}
			newState= [
				...newState.slice(0,indexOfFolder),
				folder,
				...newState.slice(indexOfFolder + 1)
			]	
		})
		return newState

	
	case 'UPDATE_FOLDER_SUCCESS':
	case 'UPDATE_FOLDER_FROM_PUSHER':
		const updatedFolderId = action.response.id
		var indexOfFolderToUpdate = findIndex(state,(folder => {
			return folder.id === updatedFolderId
		}))
		if(indexOfFolderToUpdate !== -1 ){
			if(action.response.archived===true){
				return [
					...state.slice(0,indexOfFolderToUpdate),
					action.response,
					...state.slice(indexOfFolderToUpdate + 1)
				]
			}else {
				return [
					...state.slice(0,indexOfFolderToUpdate),
					...state.slice(indexOfFolderToUpdate + 1)
				]
			}}
		else {
			if(action.response.archived===true){
				return [...state,action.response]
			}else return state
		}





	case 'DELETE_FOLDER_SUCCESS':
	case 'FOLDER_REMOVED_PUSHER':
		const deletedFolderId = action.folderId
		const indexOfFolderToDelete = state.findIndex(folder => {
			return folder.id === deletedFolderId
		})
		if(indexOfFolderToDelete !==-1){	
			return [
				...state.slice(0,indexOfFolderToDelete),
				...state.slice(indexOfFolderToDelete + 1)
			]
		}else return state



	case 'LOGOUT':
		return []

	default:
		return state

	}
}


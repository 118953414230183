import React from 'react'
import Button from '../../uiKit/Button'
import Icon from '../../misc/Icons'
 

class PublishingFlowHeaderProgressTitle extends React.Component{


	render(){	
		

		return(
			<div className={'publishingFlowModal-header-progress-title ' + (this.props.isActive ? 'publishingFlowModal-header-progress-title--active' : 'publishingFlowModal-header-progress-title--inactive')}>
				{/*unfortunate seemed best solution to prevent resizing when active */}
				<div className='publishingFlowModal-header-progress-title-inactiveLabel'>
					{this.props.label}				
				</div>				
				<div className='publishingFlowModal-header-progress-title-activeLabel'>
					{this.props.label}
				</div>
			</div>
			
		)	
	}
				
}


class PublishingFlowHeader extends React.Component{

	constructor(){
		super()
		this.clickCancelButton=this.clickCancelButton.bind(this)

	}


	clickCancelButton(){
		if(window.analytics){
			window.analytics.track('Cancel Publishing Flow',{
					stage:this.props.publishingFlowStage,
				})
		}
		this.props.closePublishRepoModal()
	}


	render(){	

		const {publishingFlowStage} = this.props
		

		return(
			<div className='publishingFlowModal-header'>
				
				<div className='publishingFlowModal-header-progress'>					
					<PublishingFlowHeaderProgressTitle
						label='Prepare for Release'
						isActive={publishingFlowStage==='subject' || publishingFlowStage==='gradeLevel' || publishingFlowStage==='description' || publishingFlowStage==='image'}
					/>
					<div className='publishingFlowModal-header-progress-iconContainer'>
						<Icon name='chevron-right' />
					</div>
					<PublishingFlowHeaderProgressTitle
						label='Review and Publish'
						isActive={publishingFlowStage==='review'}
					/>
				</div>

				<Button
					label='Cancel'
					name='cancel'					
					color='white'
					onClickFunction={this.clickCancelButton}
				/>

			</div>
			
		)	
	}
				
}




export default PublishingFlowHeader



//UISource
//inputRule (1/2)
//shortcut "//"
//topBarButton

export function analyticsInsertFriendlyFraction(UISource,numerator,denominator,inputRuleText) {
	if(window.analytics){				
			window.analytics.track('Insert Fast Fraction',{
				UISource:UISource,
				numerator:numerator,
				denominator:denominator,
				inputRuleText:inputRuleText
			})
		}
}








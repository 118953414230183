import React from 'react'
import Button from '../uiKit/Button'
import SectionMarker from '../uiKit/SectionMarker'
import StaticQuestionSlide from '../slides/StaticQuestionSlide'

//notification in middle of screen with slide showing question in NP and a countdown

class LiveViewNotification extends React.Component{
	render(){	
		const {control,section,activeCountdownSeconds,showLiveViewModal,hideLiveViewNotification}=this.props
		return(
			<div className='liveViewNotificationContainer'>
				<div className='liveViewNotification'>
					
					{section && 
						<React.Fragment>
							<div className='liveViewNotification-live'>
								Live
								<SectionMarker type='colorOnly' label='Live' section={section} />
							</div>
							<div className='liveViewNotification-sectionName'>
								{section.name}
							</div>
						</React.Fragment>
					}

					<div className='liveViewNotification-slidePreview' onClick={showLiveViewModal}>
							
						<StaticQuestionSlide 
							question={control.snapshot} 
							displayWidth={350} 
						/>

						{activeCountdownSeconds > 0 &&
							<div className='liveViewNotification-slidePreview-countDown'>								
								{activeCountdownSeconds}
								<div className='liveViewNotification-slidePreview-container-spinnerContainer'>
									<div className='liveViewNotification-slidePreview-container-spinner' />
								</div>
							</div>
						}

					</div> 

					<div className='liveViewNotification-actionRow'>
						<Button label='Open Now' onClickFunction={showLiveViewModal} color='blue' labelColor='light' />
						<Button label='Ignore' onClickFunction={hideLiveViewNotification} />
					</div>

				</div>
								
			</div>
		)
	}		
}

export default LiveViewNotification
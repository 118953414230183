import filter from 'lodash/filter'


export function getMemberSummary(permissions,repo) {
	if(permissions && repo && repo.invitedBy){

		let joinedPermissions=filter(permissions,{joined:true})
		const invitedBy=repo.invitedBy

		const indexOfInviter = joinedPermissions.findIndex(permission => {
	
			return permission.user.id === invitedBy.id
		})


		const permissionsWithoutInviter=[
			...joinedPermissions.slice(0, indexOfInviter),
			...joinedPermissions.slice(indexOfInviter + 1)
		]

		if(permissionsWithoutInviter.length ===0){
			return `${invitedBy.firstName} now!`
		}else if(permissionsWithoutInviter.length ===1){
			return `${invitedBy.firstName} and ${permissionsWithoutInviter[0].user.firstName}.`
		}
		else if(permissionsWithoutInviter.length ===2){
			return `${invitedBy.firstName}, ${permissionsWithoutInviter[0].user.firstName} and ${permissionsWithoutInviter[1].user.firstName}.`
		}
		else if(permissionsWithoutInviter.length ===3){
			return `${invitedBy.firstName}, ${permissionsWithoutInviter[0].user.firstName} and 2 others.`
		}
		else if(permissionsWithoutInviter.length > 3){
			return `${invitedBy.firstName}, ${permissionsWithoutInviter[0].user.firstName}, ${permissionsWithoutInviter[1].user.firstName} and ${permissionsWithoutInviter.length -2} others.`
		}
		else return ''
	}else return ''
}


import api from '../api'
import {fetchSetsPreview,fetchSetsForRepo} from './sets'
import {fetchFoldersPreview,fetchFoldersForRepo} from './folders'
import {fetchQuestionsPreview,fetchQuestionsForRepo} from './questions'
import {fetchPermissions} from './permissions'
import {createRepoEvent} from '../utils/analytics/createRepoEvent'
import store from '../store'

export function newRepo(data) {
	const nowDate=new Date()
	const requestData={
		...data, 
		userCreated: nowDate,
		clientModified: nowDate
	}
	return (dispatch) => api.post('/repos',requestData)
		.then((repo) => {
			dispatch({ type: 'CREATE_REPO_SUCCESS', repo})
			createRepoEvent(repo)
			return repo
		})
		.catch(err => {})
}

export function repoAddedPusherReceived(repo) {
	return (dispatch) => {
		dispatch({ type: 'REPO_ADDED', repo})
		dispatch(fetchSetsPreview())
		dispatch(fetchFoldersPreview())
		dispatch(fetchQuestionsPreview())			
	}
}

export function repoRemovedPusherReceived(repoId) {
	return (dispatch) => {
		dispatch({ type: 'REPO_REMOVED', repoId})	
	}
}

export function repoUnpublishedPusherReceived(repoId) { 
	return (dispatch) => {
		dispatch({ type: 'REPO_REMOVED', repoId})	
	}
}

export function fetchRepos() {
	return (dispatch) => api.fetch('/repos')
		.then((response) => {
			dispatch({ type: 'FETCH_REPOS_SUCCESS',response})
			response.forEach(repo => {
				dispatch(fetchPermissions(repo.id))
				if(repo.role ==='author'){
					dispatch(fetchRepoFollowerCount(repo.id))
				}	
			})
		})
		.catch(err => {})
}

export function fetchRepoFollowerCount(repoId) {
	return (dispatch) => api.fetch(`/repos/${repoId}/follower-count`)
		.then((response) => {
			const followerCount=response.followerCount
			dispatch({ type: 'FETCH_REPO_FOLLOWER_COUNT_SUCCESS',followerCount,repoId})
		})
		.catch(err => {})
}

export function fetchReposForAuthor(authorId) {
	return (dispatch) => {
		api.fetch(`/repos?author=${authorId}`)
			.then((response) => {
				dispatch({ type: 'FETCH_AUTHOR_REPOS_SUCCESS',authorId,response})
			})
			.catch(err => {})
	}
}

export function fetchARepo(repoId) {
	const state = store.getState()
	if(state.requestedRepos[repoId]){
		return({ type: 'WAIT_TO_REFRESH'})
	}else{
		return (dispatch) => {
			dispatch({ type: 'FETCH_REPO_REQUEST',repoId})
			api.fetch(`/repos/${repoId}`)
				.then((response) => {
					dispatch({ type: 'FETCH_A_REPO_SUCCESS',response})
				})
				.catch(err => {})
		}
	}
}
 
export function fetchARepoByShortId(shortId){
	const state = store.getState()
	if(state.requestedRepos[shortId]){
		return({ type: 'WAIT_TO_REFRESH'})
	}else{
		return (dispatch) => {
			dispatch({ type: 'FETCH_REPO_REQUEST',shortId})  		
			api.fetch(`/repos?shortId=${shortId}`)  		
				.then((response) => {
					dispatch({ type: 'FETCH_A_REPO_SUCCESS',response})
				})
				.catch(err => {})
		}
	}
}

export function fetchContentForRepo(repoId){
	const state = store.getState()
	if(state.requestedRepoContent[repoId]){
		return({ type: 'WAIT_TO_REFRESH'})
	}else{
		return (dispatch) => {
			dispatch({ type: 'FETCH_REPO_CONTENT_REQUEST',repoId})
			dispatch(fetchSetsForRepo(repoId))
			dispatch(fetchQuestionsForRepo(repoId))
			dispatch(fetchFoldersForRepo(repoId))
			.then((response) => {
			// dispatch({ type: 'FETCH_A_REPO_SUCCESS',response})
			})
			.catch(err => {})
		}
	}
}

export function fetchAPublishedRepo(repoId) {
	return (dispatch) => {
		api.fetch(`/repos/${repoId}`)
			.then((response) => {
				dispatch({ type: 'FETCH_A_REPO_SUCCESS',response})
			})
			.catch(err => {})
			dispatch(fetchSetsForRepo(repoId))
			dispatch(fetchQuestionsForRepo(repoId))
			dispatch(fetchFoldersForRepo(repoId))
	}
}

export function fetchAPublishedRepoByShortId(shortId) {
	return (dispatch) => api.fetch(`/repos?shortId=${shortId}`)
		.then((responseArray) => {
			if(responseArray.length>0){
				const response=responseArray[0]
				dispatch({ type: 'FETCH_A_REPO_SUCCESS',response})
				dispatch(fetchSetsForRepo(response.id))
				dispatch(fetchQuestionsForRepo(response.id))
				dispatch(fetchFoldersForRepo(response.id))
				return response
			}else{
				const error = new Error()
				throw error
			}
	})	   
}

export function fetchARepoAndPermissions(repoId) {
	return (dispatch) => api.fetch(`/repos/${repoId}`)
		.then((response) => {
			dispatch({ type: 'FETCH_A_REPO_SUCCESS',response})
			dispatch(fetchPermissions(repoId))
		})
		.catch(err => {})
}

export function updateRepo(repo) {
	if(typeof repo.subject !== 'string'){ //Prevent invalid subject value
		if(repo.subject){
			repo.subject=repo.subject.id
		}
	}
	return (dispatch) => api.put(`/repos/${repo.id}`,repo)
		.then((response) => {
			dispatch({ type: 'UPDATE_REPO_SUCCESS', response})
			return response
		})
		.catch(err => {
			if(window.analytics){
				window.analytics.track('Update repo failed', {
					repo:repo,
					error:err
				})
		}
	})
}

export function publishRepo(repoId) {
	return (dispatch) => api.put(`/repos/${repoId}/publish`)
		.then((response) => {
		dispatch({ type: 'UPDATE_REPO_SUCCESS', response})
		dispatch(fetchRepoFollowerCount(repoId))
		return response
	})
}

export function unpublishRepo(repoId) {
	return (dispatch) => api.put(`/repos/${repoId}/unpublish`)
		.then((response) => {
		dispatch({ type: 'UPDATE_REPO_SUCCESS', response})
		return response
	})
}

export function followRepo(repo) {
	return (dispatch) => api.put(`/repos/${repo.id}/follow`)
		.then(() => {
			let response=repo
			response.following=true
			dispatch({ type: 'UPDATE_REPO_SUCCESS', response})
			return response
	})	   
}

export function unfollowRepo(repo) {
	return (dispatch) => api.put(`/repos/${repo.id}/unfollow`)
		.then(() => {
			let response=repo
			response.following=false
			dispatch({ type: 'UPDATE_REPO_SUCCESS', response})
		return response
	})
}

export function deleteRepo(repoId) {
	return (dispatch) => api.delete(`/repos/${repoId}`)
		.then((response) => {
			dispatch({ type: 'DELETE_REPO_SUCCESS', repoId})
		})
	.catch(err => {})
}

export default function (state=document.visibilityState, action) {

	switch (action.type) {
	case 'PAGE_VISIBILITY_STATE_CHANGED':
		return action.visibilityState
	case 'LOGOUT':
		return null
		
	default:
		return state
	
	}

}



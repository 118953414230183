import React from 'react'
import SetHandout from '../setHandout/SetHandout'

class PrintPreview extends React.Component{

	constructor(props) {
		super(props)
		this.changePreviewStudent=this.changePreviewStudent.bind(this)

		this.state = {
			showPreview:false,
			selectedPreviewStudent:null,
			showAllStudents:false,
		}
	} 


	componentDidMount(){
		setTimeout(function() { 
			this.setState({showPreview:true})
		}.bind(this),	1)
	}


	

	changePreviewStudent(student){
		this.setState({selectedPreviewStudent:student})
	}



	render(){ 
		const {
			printSetSettings,
			ghostHeights,
			set,
			paperSize,
			instructionalText,
			includeInstructionalText
		}=this.props		

		const columnCount = printSetSettings.columnCount
		const {isSafari}=this.state

		return(			
			<div className='featureModal-previewContainer'>
				<div className='featureModal-preview'>	

					{this.state.showPreview &&
						<div className={'printPack printPack--rendered ' + (paperSize === 'letter' ? ' printPack--letter ': ' printPack--a4 ') + (columnCount === 2 ? 'printPack--twoColumn ' : '') + (columnCount === 1 ? 'printPack--singleColumn ' : '') + (isSafari ? ' printPack--isSafari ': '')}>					 	 	
							<SetHandout
								set={set}
								includeInstructionalText={includeInstructionalText}
								ghostHeights={ghostHeights}
								printSetSettings={printSetSettings}
								paperSize={paperSize}
								instructionalText={instructionalText}
							/>			
						</div>			
					}
				</div>
			</div>

		)
	}
}


export default PrintPreview

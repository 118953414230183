import React from 'react'
import EditorEditSoundModal from './EditorEditSoundModal'
import EditorInsertSoundInitialPage from './EditorInsertSoundInitialPage'
import EditorSoundRecordingPage from './EditorSoundRecordingPage'
import EditorSoundUploadingPage from './EditorSoundUploadingPage'
import EditorInsertSoundRecordingReviewPage from './EditorInsertSoundRecordingReviewPage'
import EditorSoundLibraryPage from './EditorSoundLibraryPage'
import { insertSound } from '../../../../../prosemirror/utils/editorActions/insertSound'
import { uploadSoundToCloudinaryWithProgress } from '../../../../../actions/cloudinary'
import store from '../../../../../store'
import { showNotification } from '../../../../../actions/notifications'
// stages
// initial
// recording
// recordingReview
// editSound

// recording types
// spacebarRecording (user presses and holds spacebar)
// clickRecording (user clicks to record and clicks again to stop)

const MAX_FILE_SIZE = 50000000

class EditorInsertSoundModal extends React.Component {
  constructor(props) {
    super(props)
    this.startRecording = this.startRecording.bind(this)
    this.onUploadSoundFile = this.onUploadSoundFile.bind(this)
    this.onFinishRecording = this.onFinishRecording.bind(this)
    this.insertSound = this.insertSound.bind(this)
    this.goBackToInitial = this.goBackToInitial.bind(this)
    this.rerecordRecording = this.rerecordRecording.bind(this)
    this.uploadToCloudinaryAndInsertSound = this.uploadToCloudinaryAndInsertSound.bind(this)
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this)
    this.handleSelectFile = this.handleSelectFile.bind(this)
    this.insertSoundFromSoundLibrary = this.insertSoundFromSoundLibrary.bind(this)
    this.editSoundFromSoundLibrary = this.editSoundFromSoundLibrary.bind(this)
    this.onFileUploadProgress = this.onFileUploadProgress.bind(this)

    let stage = 'initial'
    if (props.openOnSoundLibrary) {
      stage = 'soundLibrary'
    }

    this.state = {
      stage,
      uploadPercent: 0,
      uploadingFileName: '',
      recordingType: null,
      audioURL: null,
      recordingDuration: null,
      soundTitle: null,
      soundDuration: null,
      blob: null,
      soundIsFromSoundLibrary: false,
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
    this.mounted = true
    if (window.analytics) {
      window.analytics.page('Insert Sound Modal', {
        openOnSoundLibrary: this.props.openOnSoundLibrary,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
    this.mounted = false
    if (window && window.stream) {
      window.stream.getTracks() // get all tracks from the MediaStream
        .forEach((track) => track.stop()) // stop each of them
    }
  }

  handleKeyDown(e) {
    e.stopPropagation()
  }

  goBackToInitial() {
    this.setState({
      stage: 'initial',
      recordingType: null,
      audioURL: null,
      recordingDuration: null,
      soundTitle: null,
    })
  }

  rerecordRecording(recordingType) {
    this.setState({
      stage: 'recording',
      recordingType,
      audioURL: null,
      recordingDuration: null,
      soundTitle: null,
      soundDuration: null,
      blob: null,
    })
    if (window.analytics) {
      window.analytics.track('Rerecord sound', {
        recordingType,
      })
    }
  }

  handleBackgroundClick() {
    const { stage } = this.state
    if (stage === 'initial') {
      this.props.closeInsertSoundModal()
    }
  }

  uploadToCloudinaryAndInsertSound(recordingTitle /* , duration */) {
    this.setState({ uploadPercent: 1 })
    return store.dispatch(uploadSoundToCloudinaryWithProgress(
      this.state.blob,
      this.onFileUploadProgress,
    ))
      .then((response) => {
        this.setState({ uploadPercent: 100 })
        setTimeout(() => {
          const cloudinaryID = response.public_id
          const cloudinaryDuration = response.duration
          const isUpload = false
          this.insertSound(isUpload, cloudinaryID, recordingTitle, cloudinaryDuration)
        }, 100)
      })
  }

  insertSound(
    isUpload,
    fileId,
    title,
    fullDuration,
    start,
    end,
    fromSoundLibrary,
    browseSoundLibraryCategory,
    searchSoundLibraryCategory,
  ) {
    insertSound(
      isUpload,
      fileId,
      title,
      fullDuration,
      start,
      end,
      this.props.insertPos,
      this.props.isQuestion,
      this.props.questionPos,
      this.props.questionNode,
      fromSoundLibrary,
      browseSoundLibraryCategory,
      searchSoundLibraryCategory,
    )
    this.props.closeInsertSoundModal()
  }

  insertSoundFromSoundLibrary(sound, browseSoundLibraryCategory, searchSoundLibraryCategory) {
    const {
      fileId, title, fullDuration, start, end,
    } = sound
    const isUpload = sound.source === 'upload'
    this.insertSound(
      isUpload,
      fileId,
      title,
      fullDuration,
      start,
      end,
      true,
      browseSoundLibraryCategory,
      searchSoundLibraryCategory,
    )
  }

  editSoundFromSoundLibrary(sound) {
    this.setState({
      stage: 'editSound',
      librarySound: sound,
      soundTitle: sound.title,
      soundDuration: sound.fullDuration,
      soundIsFromSoundLibrary: true,
    })
  }

  onUploadSoundFile(file, fileName, fileDuration) {
    this.setState({
      stage: 'editSound',
      soundTitle: fileName,
      soundDuration: fileDuration,
    })
  }

  startRecording(recordingType) {
    this.setState({ stage: 'recording', recordingType })
    if (window.analytics) {
      window.analytics.track('Record sound', {
        recordingType,
      })
    }
  }

  onFinishRecording(audioURL, recordingDuration, blob) {
    this.setState({
      audioURL, recordingDuration, stage: 'recordingReview', blob,
    })
  }

  onFileUploadProgress(progress) {
    if (progress.percent) {
      this.setState({ uploadPercent: progress.percent })
    }
  }

  handleSelectFile(e) {
    const fileList = e.target.files
    const file = fileList[0]
    if (file.size > MAX_FILE_SIZE) {
      store.dispatch(showNotification('the maximum file size for audio files is 50mb.', 'The selected audio file is too large: ', 'default'))
    } else {
      // Remove extenstion from file name
      let fileName = file.name
      const extensions = ['.mp3', '.wav', '.aac', '.aiff', '.amr', '.flac', '.m4a', '.ogg', '.opus', '.mp4']
      extensions.forEach((extension) => {
        fileName = fileName.replace(extension, '')
      })
      this.setState({ stage: 'uploading', uploadingFileName: fileName, uploadPercent: 1 })
      return store.dispatch(uploadSoundToCloudinaryWithProgress(file, this.onFileUploadProgress))
        .then((response) => {
          // to prevent adding sound if user cancelled upload
          // (think of a better way of doing this)
          if (this.mounted) {
            const cloudinaryID = response.public_id
            const { duration } = response
            const isUpload = true
            this.insertSound(isUpload, cloudinaryID, fileName, duration)
          }
        })
        .catch(() => {})
    }
  }

  render() {
    const { stage } = this.state
    return (
      <div className="editor-insertSoundModalContainer">
        {stage === 'initial' && (
        <EditorInsertSoundInitialPage
          startRecording={this.startRecording}
          onUploadSoundFile={this.onUploadSoundFile}
          closeInsertSoundModal={this.props.closeInsertSoundModal}
          handleSelectFile={this.handleSelectFile}
          showSoundLibrary={() => { this.setState({ stage: 'soundLibrary' }) }}
        />
        )}
        {stage === 'recording' && (
        <EditorSoundRecordingPage
          closeModal={this.props.closeInsertSoundModal}
          recordingType={this.state.recordingType}
          onFinishRecording={this.onFinishRecording}
        />
        )}
        {stage === 'uploading' && (
        <EditorSoundUploadingPage
          fileName={this.state.uploadingFileName}
          uploadingProgress={this.state.uploadPercent / 100}
          closeInsertSoundModal={this.props.closeInsertSoundModal}
        />
        )}
        {stage === 'recordingReview' && (
        <EditorInsertSoundRecordingReviewPage
          uploadPercent={this.state.uploadPercent}
          audioURL={this.state.audioURL}
          recordingType={this.state.recordingType}
          recordingDuration={this.state.recordingDuration}
          goBack={this.goBackToInitial}
          closeInsertSoundModal={this.props.closeInsertSoundModal}
          rerecordRecording={this.rerecordRecording}
          uploadToCloudinaryAndInsertSound={this.uploadToCloudinaryAndInsertSound}
        />
        )}
        {stage === 'editSound' && (
        <EditorEditSoundModal
          closeModal={this.props.closeInsertSoundModal}
          insertSound={(isUpload, fileId, title, fullDuration, start, end) => {
            this.insertSound(isUpload, fileId, title, fullDuration, start, end, true)
          }}
          isInsertFlow
          closeInsertSoundModal={this.props.closeInsertSoundModal}
          fileId={this.state.librarySound.fileId}
          soundTitle={this.state.soundTitle}
          fullDuration={this.state.soundDuration}
          goBack={() => { this.setState({ stage: 'initial' }) }}
          isChoiceMedia={this.props.isChoiceMedia}
          start={this.state.librarySound.start}
          end={this.state.librarySound.end}
          soundIsFromSoundLibrary={this.state.soundIsFromSoundLibrary}
          goBackToSoundLibrary={() => { this.setState({ stage: 'soundLibrary' }) }}
          isUpload={this.state.librarySound.source === 'upload'}
        />
        )}
        {stage === 'soundLibrary' && (
        <EditorSoundLibraryPage
          closeModal={this.props.closeInsertSoundModal}
          closeInsertSoundModal={this.props.closeInsertSoundModal}
          goBack={() => { this.setState({ stage: 'initial' }) }}
          isChoiceMedia={this.props.isChoiceMedia}
          insertSoundFromSoundLibrary={this.insertSoundFromSoundLibrary}
          editSoundFromSoundLibrary={this.editSoundFromSoundLibrary}
        />
        )}
        <div onClick={this.handleBackgroundClick} className="editor-insertSoundModalContainerBG" />
      </div>
    )
  }
}
export default EditorInsertSoundModal

import React from 'react'
import { Link } from 'react-router-dom'
import PageIcon from '../misc/PageIcons'

class SectionHomeHeaderLinkTray extends React.Component {
  render() {
    const { section, disableHistory } = this.props

    const eLearningEnabled = section && section.studentAccess && section.studentAccess.enabled

    return (
      <div className={`pageHeader--sectionHome-linkTray${section.color ? ` has--classcolor-${section.color}` : ' no--color'}`}>

        {eLearningEnabled && (
        <div onClick={() => { window.scrollTo({ top: document.body.scrollHeight }) }} className='pageHeader--sectionHome-linkTray-eLearningBadge'>
          <div className='pageHeader--sectionHome-linkTray-eLearningBadge-badge'>
            E-LEARNING
          </div>
        </div>
        )}

        {section.color && !disableHistory && (
        <Link to={`/classes/${section.id}/reports`}>
          <div data-tooltip='Go to Reports' className='pageHeader--sectionHome-linkTray-link is--history tooltip tooltip--label'>
            <PageIcon name='historySolidBGLarge' />
            <div className='pageHeader--sectionHome-linkTray-link-bg' />
          </div>
        </Link>
        )}

        {section.color && (
        <Link to={`/classes/${section.id}/students`}>
          <div data-tooltip='Go to Students' className='pageHeader--sectionHome-linkTray-link is--students tooltip tooltip--label'>
            <PageIcon name='studentLarge' />
            <div className='pageHeader--sectionHome-linkTray-link-bg' />
          </div>
        </Link>
        )}

        {section.color && (
        <Link to={`/classes/${section.id}/settings`}>
          <div data-tooltip='Go to Settings' className='pageHeader--sectionHome-linkTray-link is--settings tooltip tooltip--label'>
            <PageIcon name='settingsLarge' />
            <div className='pageHeader--sectionHome-linkTray-link-bg' />
          </div>
        </Link>
        )}

      </div>

    )
  }
}

export default SectionHomeHeaderLinkTray

import {isCurrentUser} from './isCurrentUser'
 
// if displayCurrentUserName=true return You (Nicole Atack) else just You


function capitaliseUserName(user){
	let nameString=''
	if(user.firstName){
		const firstName=user.firstName
		const capitalisedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)	
		nameString += capitalisedFirstName
	}
	if(user.lastName){
		if(user.firstName){
			nameString += ' '
		}
		const lastName=user.lastName
		const capitalisedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1)
		nameString += capitalisedLastName
	}
	return nameString
}



export function getDisplayNameForUser(user,displayCurrentUserName) {	
	let displayName = ''
	
	if(isCurrentUser(user.id)){
		if(displayCurrentUserName){
			displayName=`You (${capitaliseUserName(user)})`
		}
		else{
			displayName = 'You'
		}
	}	
	else{
		displayName=capitaliseUserName(user)
	}
	return displayName
}






      
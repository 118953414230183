//checks if something is a set and returns true/false
export function isSet(item) {
	if(item){
		if(item.questions){
			return true
		}
		else if (item.setObject){
			return true
		}
		else if(item.choices){
			return false
		}
		else return false
	}
	else return false
}
import React from 'react'
import ModalWrapper from '../ModalWrapper'
import UpdateGoogleContainer from '../../../containers/modals/account/UpdateGoogleContainer'

const UpdateGoogleModal = (props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <div className='accountAuthentication-modal'>
    <ModalWrapper
      {...props}
      title='Change Google Account'
      iconName='googleLogo'
      modalStyle='dark'
      closeBtn={false}
    >
      <UpdateGoogleContainer {...props} />
    </ModalWrapper>
  </div>
  /* eslint-enable react/jsx-props-no-spreading */
)

export default UpdateGoogleModal

import moment from 'moment'
import schema from '../../schema/editorSchema'
import {getNewQuestionTemplateAddMedia} from '../getNewQuestionTemplate'
import {getActiveQuestionChoiceCount} from '../getActiveQuestionChoiceCount'
import {getActiveQuestionSlideWidth} from '../getActiveQuestionSlideWidth'
import {extractIdFromYouTubeUrl} from '../../../utils/extractIdFromYouTubeUrl'
 
function sendInsertVideoAnalytics(videoAttrs,isQuestion){
  if(window.analytics){
    window.analytics.track('Insert Video', {
      isPromptMedia:isQuestion,
      youtubeId:videoAttrs.youtubeId,
      title:videoAttrs.title,
      channelTitle:videoAttrs.channelTitle,
      thumbnail:videoAttrs.thumbnail.src,
      fullDuration:videoAttrs.fullDuration,
      start:videoAttrs.start,
      end:videoAttrs.end,
      isClipped:videoAttrs.isClipped,
      clipDuration:videoAttrs.end-videoAttrs.start
   })
  }
}

function fetchYTVideo(videoId){
  const selfURL = `https://www.googleapis.com/youtube/v3/videos?part=snippet&part=player&part=contentDetails&id=${videoId}&key=${process.env.REACT_APP_YT_API_KEY}`
  return fetch(selfURL)
    .then((response) => response.json ())
    .then((responseJson) => {
      const video=responseJson.items[0]
      return video
      })
    .catch((error) => {
    })
}

function durationToSeconds(duration){
  var x = moment.duration(duration, moment.ISO_8601)
  return x.asSeconds()
}

export function fetchVideoDetails(url) {
  const id=extractIdFromYouTubeUrl(url)
  let videoObject={
    youtubeId:id,
  }
  return fetchYTVideo(id).then((video)=>{
    if(video && video.snippet.liveBroadcastContent==='none'){ //handle no video returned or live/upcoming events
      const snippet=video.snippet
      const contentDetails=video.contentDetails
      const durationInSeconds=durationToSeconds(contentDetails.duration)
      videoObject.fullDuration=durationInSeconds
      videoObject.title=snippet.title
      videoObject.description=snippet.description
      videoObject.channelTitle=snippet.channelTitle
      videoObject.start=0
      videoObject.end=durationInSeconds

      if(snippet.thumbnails.maxres){
        videoObject.thumbnail={
          h:snippet.thumbnails.maxres.height,
          w:snippet.thumbnails.maxres.width,
          src:snippet.thumbnails.maxres.url
        }
      }

      else if(snippet.thumbnails.standard) {
        videoObject.thumbnail={
          h:snippet.thumbnails.standard.height,
          w:snippet.thumbnails.standard.width,
          src:snippet.thumbnails.standard.url
        } 
      }   
        else if(snippet.thumbnails.high) {
          videoObject.thumbnail={
            h:snippet.thumbnails.high.height,
            w:snippet.thumbnails.high.width,
            src:snippet.thumbnails.high.url
          } 
        }    
        else {
          videoObject.thumbnail={
            h:snippet.thumbnails.default.height,
            w:snippet.thumbnails.default.width,
            src:snippet.thumbnails.default.url
          } 
        }
      }
      else{
        videoObject=null
      }
    return videoObject 

    })
}

export function addVideo(video,clipStartTime,clipEndTime,insertPos,url,isQuestion,questionPos,questionNode) {
    let type 
    var tr = window.view.state.tr
    let newTemplate
    if(isQuestion){ //change template
       newTemplate=getNewQuestionTemplateAddMedia(questionNode.attrs.template)
      tr.setNodeMarkup(questionPos, null, {...questionNode.attrs,template:newTemplate})
      type = schema.nodes.questionVideo  
    }else{
      type = schema.nodes.choiceVideo  
    }

    let isClipped=false
    if(clipStartTime!==0 || ((clipEndTime-clipStartTime)!==video.fullDuration)){
      isClipped=true
    }
    const nowDate = new Date()

    let videoAttrs={
      youtubeId:video.youtubeId,
      addedAt:nowDate,
      template:newTemplate,
      title:video.title,
      channelTitle:video.channelTitle,
      thumbnail:video.thumbnail,
      fullDuration:video.fullDuration,
      start:clipStartTime,
      end:clipEndTime,
      isClipped:isClipped
    }
    if(isQuestion){
      videoAttrs.questionMediaDynamicHeight=questionNode.attrs.questionMediaDynamicHeight
      videoAttrs.slideWidth=getActiveQuestionSlideWidth()
  
      //update template on choices
      window.view.state.doc.nodesBetween(questionPos,questionPos+questionNode.nodeSize, (node, pos) => {
        if (node.type.name === "choiceImage"||node.type.name === "choiceVideo"||node.type.name === "choiceSound"||node.type.name === "choice"){
          tr.setNodeMarkup(pos, null, {...node.attrs,template:newTemplate})          
        }
      })

    }
    else{
        videoAttrs.questionChoiceCount=getActiveQuestionChoiceCount()
        videoAttrs.slideWidth=getActiveQuestionSlideWidth()
    }

    const videoNode=type.create(videoAttrs)
    sendInsertVideoAnalytics(videoAttrs,isQuestion)
    

    tr.replaceWith(insertPos.start,insertPos.end,videoNode)
    tr.setMeta("triggerParseDoc", true) 

    window.view.dispatch(tr)

  }
    



import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import find from 'lodash/find'
import { updateControl } from '../../actions/control'
import { showLiveViewModal } from '../../utils/liveView/showLiveViewModal'
import { resetControl } from '../../utils/liveView/resetControl'
import FloatingNPControl from '../../components/liveView/FloatingNPControl'

// This is the minimised live view shown in bottom right of screen
// shows a static slide you can click to go to live view and an 'x' which ends NP
// we expermiented with a view you could drag around the screen but decided on a fixed position

const isPageWithFloatingNPControl = (location) => { // don't show for the folowing routes
  if (
    !(location.pathname.indexOf('/liveview') > -1) &&
    location.hash !== '#now-playing' &&
    !(location.pathname.indexOf('/classRoster') > -1) &&
    !(location.pathname.indexOf('/creatorslist') > -1) &&
    !(location.pathname.indexOf('/explorer') > -1) &&
    !(location.pathname.indexOf('/packreview') > -1) &&
    !(location.pathname.indexOf('/rich') > -1) &&
    !(location.pathname.indexOf('/reset-password') > -1) &&
    !(location.pathname.indexOf('/delete-account') > -1) &&
    !(location.pathname.indexOf('/scan-helper') > -1)
  ) return true
  return false
}

class FloatingNPControlContainer extends Component {
  constructor() {
    super()
    this.stopLiveView = this.stopLiveView.bind(this)
  }

  stopLiveView() {
    this.props.resetControl()
  }

  render() {
    const { control, section } = this.props
    const hasItemInControl = (control && (control.setPoll || control.currentPoll))
    const { location } = this.props.history

    if (
      hasItemInControl &&
      isPageWithFloatingNPControl(location) &&
      !this.props.hasLiveViewNotification
    ) {
      return (
        <FloatingNPControl
          section={section}
          showLiveViewModal={showLiveViewModal}
          stopLiveView={this.stopLiveView}
          control={control}
        />
      )
    } return null
  }
}

function mapStateToProps(state) {
  const { control } = state
  let section = null
  if (state.control) {
    section = find(state.sections, { id: state.control.section })
  }
  return {
    control,
    section,
    hasLiveViewNotification: state.hasLiveViewNotification,
  }
}

export default withRouter(connect(
  mapStateToProps,
  { updateControl, resetControl },
)(FloatingNPControlContainer))

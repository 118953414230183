import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import findIndex from 'lodash/findIndex'
import moment from 'moment'
import ScoreSheetDataContainer from './ScoreSheetDataContainer'
import {getScoreSheetDateRangeLabel} from '../../utils/scoresheet/getScoreSheetDateRangeLabel'
import {showScoreSheetCustomRangeModal} from '../../actions/modals'
 
//Handles e.g. date selection, section selection
//Then passes as props to ScoreSheetDataContainer which filters data, handles excluding polls and does csv calcs
//(note custom date range is only available to pro users)
//Custom date range is set in modal that updates scoreSheetCustomDateRange in redux (ScoresheetCustomRangeModalContainer.js)
//(in this component we listen for changes in scoreSheetCustomDateRange and update component state accordingly)


const DEFAULT_GRANULARITY='week'//available granularities day, week, month, 90d or custom range

class ScoreSheetContainer extends Component {

	constructor(props){
		super(props)
		this.changeActiveSection=this.changeActiveSection.bind(this)
		this.calculateDateRange=this.calculateDateRange.bind(this)
		this.changeDateGranularity=this.changeDateGranularity.bind(this)
		this.changeSelectedDate=this.changeSelectedDate.bind(this)
		this.handleKeyDown = this.handleKeyDown.bind(this)
		this.toggleShowReviewModal = this.toggleShowReviewModal.bind(this)
		
		const selectedDate=new Date()
		const granularity=DEFAULT_GRANULARITY
		const dateRange=this.calculateDateRange(granularity,selectedDate)

		this.state={
			activeSectionId: null, //no section selected on load
			granularity:granularity,
			selectedDate:selectedDate, //today
			dateRange:dateRange,
			customDateRange:false,
			dateRangeLabel:getScoreSheetDateRangeLabel(granularity,dateRange),
			dateRangeCanGoForward:false, //dont allow user to move the date range forward if it will bring startDate into future
			showReviewModal:false //modal showing full question slide and response graphs.  Opened by clicking on question in scoresheet header
		}
	}
	
	componentDidMount(){
		document.addEventListener('keydown', this.handleKeyDown)
		if(window.analytics){
			window.analytics.page('Scoresheet')
		}
	}

	componentWillUnmount(){
		document.removeEventListener('keydown', this.handleKeyDown)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
 		if(nextProps.scoreSheetCustomDateRange.startDate && (nextProps.scoreSheetCustomDateRange.startDate !== this.props.scoreSheetCustomDateRange.startDate ||nextProps.scoreSheetCustomDateRange.endDate !== this.props.scoreSheetCustomDateRange.endDate)){
 			this.setState({dateRange:nextProps.scoreSheetCustomDateRange, granularity:null,dateRangeLabel:getScoreSheetDateRangeLabel('custom',nextProps.scoreSheetCustomDateRange),customDateRange:true})
 		}
 	}

	toggleShowReviewModal(){
		this.setState({showReviewModal:!this.state.showReviewModal})
	}

	handleKeyDown(e){
		if(!this.state.showReviewModal){
			if(e.shiftKey && e.keyCode===38){ //shift + up arrow to change active section
				const activeSectionIndex= findIndex(this.props.sections, function (section) {
					return section.id === this.state.activeSectionId
				}.bind(this))
				const newActiveSectionIndex=Math.max(0,activeSectionIndex-1)
				this.changeActiveSection(this.props.sections[newActiveSectionIndex].id)
			}
			if(e.shiftKey && e.keyCode===40){ //shift + down arrow to change active section
				const activeSectionIndex= findIndex(this.props.sections, function (section) {
					return section.id === this.state.activeSectionId
				}.bind(this))
				const newActiveSectionIndex=Math.min(this.props.sections.length-1,activeSectionIndex+1)
				this.changeActiveSection(this.props.sections[newActiveSectionIndex].id)
			}
			if(e.shiftKey && e.keyCode===37){ //left arrow
				this.changeSelectedDate('back')
			}
			if(e.shiftKey && e.keyCode===39){ //right arrow
				if(this.state.dateRangeCanGoForward){
					this.changeSelectedDate('forward')
				}
			}
		}
	}
 
 	changeActiveSection(sectionId){
 		this.setState({activeSectionId:sectionId})
 	}

 	changeDateGranularity(newGranularity){
 		const newDateRange=this.calculateDateRange(newGranularity,this.state.selectedDate)
 		const dateRangeLabel=getScoreSheetDateRangeLabel(newGranularity,newDateRange)
 		const dateRangeCanGoForward=this.dateRangeCanGoForward(newGranularity,this.state.selectedDate)
 		this.setState({granularity:newGranularity,dateRange:newDateRange,dateRangeLabel:dateRangeLabel,dateRangeCanGoForward:dateRangeCanGoForward,customDateRange:false,})
 	}

 	changeSelectedDate(direction,date){
 		const {granularity,selectedDate,customDateRange}=this.state
 		let newSelectedDate
 		let newGranularity=granularity	
 		if(!date){
 			if(direction==='back'){ //go back in time
 				if(granularity=== 'day'){
 					newSelectedDate = moment(selectedDate).subtract(1,'day')
 				}else if(granularity=== 'week'){
 					newSelectedDate = moment(selectedDate).subtract(1,'week')
		 		}else if(granularity=== 'month'){
 					newSelectedDate = moment(selectedDate).subtract(1,'month')
 				}else if(granularity=== '90d'){
 					newSelectedDate = moment(selectedDate).subtract(90,'days')
 				}
 			}else if(direction==='forward'){
 				if(granularity=== 'day'){
 					newSelectedDate = moment(selectedDate).add(1,'day') 				
 				}else if(granularity=== 'week'){
 					newSelectedDate = moment(selectedDate).add(1,'week')
 				}else if(granularity=== 'month'){
 					newSelectedDate = moment(selectedDate).add(1,'month')
 				}else if(granularity=== '90d'){
 					newSelectedDate = moment(selectedDate).add(90,'days')
 				}
	 		}else if(!direction){ //if no direction specified reset to today
 				newSelectedDate=moment()
 				if(customDateRange){ //if custom granularity reset to default
 					newGranularity=DEFAULT_GRANULARITY
 					this.setState({customDateRange:false})
 				}
	 		}
 		}else{
 			newSelectedDate=date
 		}
 		const dateRange=this.calculateDateRange(newGranularity,newSelectedDate)
 		const dateRangeLabel=getScoreSheetDateRangeLabel(newGranularity,dateRange)
 		const dateRangeCanGoForward=this.dateRangeCanGoForward(newGranularity,newSelectedDate)
 		this.setState({granularity:newGranularity,selectedDate:newSelectedDate,dateRange:dateRange,dateRangeLabel:dateRangeLabel,dateRangeCanGoForward:dateRangeCanGoForward})
 	}

 	dateRangeCanGoForward(granularity,selectedDate){
 		let dateRangeCanGoForward=true
		if(granularity=== 'day'){
			if(moment(selectedDate).add(1,'day').isAfter(moment())){
				dateRangeCanGoForward=false
			}
		}else if(granularity=== 'week'){
 			if(moment(selectedDate).add(1,'week').isAfter(moment())){
 				dateRangeCanGoForward=false
 			}
 		}else if(granularity=== 'month'){
 			if(moment(selectedDate).add(1,'month').isAfter(moment())){
 				dateRangeCanGoForward=false
 			}
 		}else if(granularity=== '90d'){
 			if(moment(selectedDate).add(90,'days').isAfter(moment())){
 				dateRangeCanGoForward=false
 			}
 		}
 		return dateRangeCanGoForward
 	}

 	calculateDateRange(granularity,selectedDate){
 		let startDate
 		let endDate
 		if(granularity=== 'day'){
 			startDate = moment(selectedDate).startOf('day')
 			endDate=moment(selectedDate).endOf('day')
 		}else if(granularity=== 'week'){
 			startDate = moment(selectedDate).startOf('isoWeek')
 			endDate=moment(selectedDate).endOf('isoWeek')
 		}else if(granularity=== 'month'){
 			startDate = moment(selectedDate).startOf('month')
 			endDate=moment(selectedDate).endOf('month')
 		}else if(granularity=== '90d'){
 			startDate = moment(selectedDate).subtract(89,'days')
 			endDate=moment(selectedDate).endOf('day')
 		}
 		const dateRange={
 			startDate:startDate,
 			endDate:endDate
 		}
 		return dateRange
 	}

	render() {
		return( 
			<DocumentTitle title='Scoresheet - Plickers' >
				<ScoreSheetDataContainer
					toggleShowReviewModal={this.toggleShowReviewModal}
					changeActiveSection={this.changeActiveSection}
					activeSectionId={this.state.activeSectionId}
					dateRange={this.state.dateRange}
					changeDateGranularity={this.changeDateGranularity}
					granularity={this.state.granularity}
					changeSelectedDate={this.changeSelectedDate}
					dateRangeLabel={this.state.dateRangeLabel}
					showScoreSheetCustomRangeModal={()=>{this.props.showScoreSheetCustomRangeModal(this.state.activeSectionId)}}
					dateRangeCanGoForward={this.state.dateRangeCanGoForward}
					customDateRange={this.state.customDateRange}
				/>
			</DocumentTitle>
		)
	}
}

export default connect((state,ownProps) => ({
	sections:state.sections,
	scoreSheetCustomDateRange:state.scoreSheetCustomDateRange
}),
{showScoreSheetCustomRangeModal }
)(ScoreSheetContainer)
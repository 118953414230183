export function getUserDisplayName(user) {
	let displayName=''
	if(user.title){
		displayName=`${user.title} ${user.lastName}`
	}
	else if(user.firstName){
		displayName=`${user.firstName.slice(0,1)} ${user.lastName}`
	}else{
		displayName=user.lastName
	}
	return displayName
}

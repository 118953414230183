import {updateQuestionOrderTransaction} from './updateQuestionOrderTransaction'


function reorder(list, startIndex, endIndex){
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)
	return result
}


export function reorderSetQuestions(fromIndex,toIndex){
	let tr=window.view.state.tr
	let oldOrderArray
	tr.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if (node.type.name==='question') {
			if(!oldOrderArray){
				oldOrderArray=node.attrs.questionOrderArray
			}
		}
	})
	const newArray=reorder(oldOrderArray,fromIndex,toIndex)
	tr=updateQuestionOrderTransaction(tr,newArray)
	tr.setMeta("triggerParseDoc", true) 
	window.view.dispatch(tr)
}

import React, {Component} from 'react'
import Icon from '../../components/misc/Icons'




const QUESTION_BODY_MAX_LENGTH=250
const QUESTION_CHOICE_MAX_LENGTH=200

class ImportQuestionsInputAreaRightGutterQuestion extends Component{


	render(){	
		const {choicesPerQuestion,questionPreview}=this.props

		let bodyOverMaxCharacters=false
		let choiceAOverMaxCharacters=false
		let choiceBOverMaxCharacters=false
		let choiceCOverMaxCharacters=false
		let choiceDOverMaxCharacters=false
		
		if(questionPreview){
			bodyOverMaxCharacters=questionPreview.bodyOverMaxCharacters
			choiceAOverMaxCharacters=questionPreview.choices[0].choiceOverMaxCharacters
			choiceBOverMaxCharacters=questionPreview.choices[1].choiceOverMaxCharacters

			if(questionPreview.choices[2] && questionPreview.choices[2].choiceOverMaxCharacters){
				choiceCOverMaxCharacters=true
			}
			if(questionPreview.choices[3] && questionPreview.choices[3].choiceOverMaxCharacters){
				choiceDOverMaxCharacters=true
			}


		}

		return(		
			<React.Fragment>
				
				<ImportQuestionsInputAreaRightGutterRow severeAlertCount={bodyOverMaxCharacters?questionPreview.bodyCharacterCount-QUESTION_BODY_MAX_LENGTH:null} />
				<ImportQuestionsInputAreaRightGutterRow severeAlertCount={choiceAOverMaxCharacters?questionPreview.choices[0].characterCount-QUESTION_CHOICE_MAX_LENGTH:null}/>
				<ImportQuestionsInputAreaRightGutterRow severeAlertCount={choiceBOverMaxCharacters?questionPreview.choices[1].characterCount-QUESTION_CHOICE_MAX_LENGTH:null}/>
		
				{choicesPerQuestion>2 &&
					<ImportQuestionsInputAreaRightGutterRow severeAlertCount={choiceCOverMaxCharacters?questionPreview.choices[2].characterCount-QUESTION_CHOICE_MAX_LENGTH:null}/>
				}
				{choicesPerQuestion>3 &&
					<ImportQuestionsInputAreaRightGutterRow severeAlertCount={choiceDOverMaxCharacters?questionPreview.choices[3].characterCount-QUESTION_CHOICE_MAX_LENGTH:null}/>
				}

			</React.Fragment>
			
		)
	}
}





class ImportQuestionsInputAreaRightGutterRow extends Component{

 
	render(){			

		if(this.props.severeAlert){
			return(		
				<div data-tooltip='Over character length limit' className='importQuestions-inputArea-rightGutter-row importQuestions-inputArea-rightGutter-row--severeAlert tooltip tooltip--label'>										
					<Icon name='alert' />					
				</div>
			)
		}
		if(this.props.severeAlertCount){
			return(		
				<div data-tooltip='Over character limit' className='importQuestions-inputArea-rightGutter-row importQuestions-inputArea-rightGutter-row--severeAlertCount tooltip tooltip--label'>
										
					<div className='importQuestions-inputArea-rightGutter-row--severeAlertCount-count'>
						{this.props.severeAlertCount}
					</div>

				</div>
			)
		}
		else {
			return(		
				<div className='importQuestions-inputArea-rightGutter-row' />						
			)
		}
	}
}


class ImportQuestionsInputAreaRightGutter extends Component{


	render(){	
		
		// severe alert count is number of characters over limit

		const {questionNumberArray,choicesPerQuestion,questionsPreview}=this.props

		return(		
			<div className='importQuestions-inputArea-rightGutter'>		
				
				{questionNumberArray.map((questionIndex)=>{
					return(
						<ImportQuestionsInputAreaRightGutterQuestion 
							questionPreview={questionsPreview? questionsPreview[questionIndex]:null}
							choicesPerQuestion={choicesPerQuestion} 
							setCorrectAnswer={this.props.setCorrectAnswer}  
							questionIndex={questionIndex} 
							key={questionIndex}/>
					)
				})}										
			
				
				
						
			</div>		
		)
	}
}

					

export default ImportQuestionsInputAreaRightGutter


import React from 'react'
import {detectBrowser} from '../../utils/detectBrowser'
import canAutoPlay from 'can-autoplay'
import Icon from '../misc/Icons'
import AutoPlaySetupBrowserInstructions from './AutoPlaySetupBrowserInstructions'

class AutoplaySetupContent extends React.Component{

	constructor() {
    super()
    this.playSound=this.playSound.bind(this)
    this.selectBrowser=this.selectBrowser.bind(this)

    const browser=detectBrowser()
    const isMac = navigator.platform.toUpperCase().indexOf('MAC')>=0
    const selectedOS = isMac ? "mac" : "windows"


    this.state = {
      browser:browser,
      isMac:isMac,
      selectedBrowser:null,
      autoplayFailed:null,
      selectedOS: selectedOS,
    }
  }
  	componentDidMount(){
  		let selectedBrowser
	  	canAutoPlay.audio().then(({result}) => {
			if (result === true) {
				selectedBrowser=this.state.browser
				this.setState({selectedBrowser:this.state.browser})
				this.setState({autoPlayFailed:false})
			} else {
			if(this.state.browser!=='chrome'){
				selectedBrowser=this.state.browser
				this.setState({selectedBrowser:this.state.browser})
			}
				this.setState({autoPlayFailed:true})
			}
			if(window.analytics){
	  			let chromeClickRequired=true
		  		if(selectedBrowser){
		  			chromeClickRequired=false
		  		}
	  		let browserOS
	  		if(this.state.isMac){
	  			browserOS=`mac ${this.state.browser}`
	  		}else{
	  			browserOS=`win ${this.state.browser}`
	  		}

	  		window.analytics.page('AutoPlay setup',{
	  			type:this.props.isModal?'modal':'page',
	  			chromeClickRequired:chromeClickRequired,
	  			browserOS:browserOS

	  		})
	  	}
	  	

	  	})
	  	if(this.autoplayAudio){
	  		this.autoplayAudio.volume=0.01
	  	}
	  	


	}

  	playSound(){
  		if(this.audio){
  			this.audio.volume=0.01
  			this.audio.play()
  		}
  	}
  	selectBrowser(broswer){
  		this.setState({selectedBrowser:broswer})
  		if(broswer==='chrome'){
  			this.playSound()
  		}
  	}



	render(){	

		const {selectedBrowser, selectedOS, autoplayFailed}=this.state
		const {isModal} = this.props

		const iconImageConstructor = "https://res.cloudinary.com/plickers-static/image/upload/w_512,f_auto,q_100"

		const chromeIconCloudinary = "/v1632560018/setupautoplay/chrome.png"
		const firefoxIconCloudinary = "/v1632560018/setupautoplay/firefox.png"
		const edgeIconCloudinary = "/v1632560018/setupautoplay/edge.png"
		const safariIconCloudinary = "/v1632560018/setupautoplay/safari.png"		

		const videoConstructor = "https://res.cloudinary.com/plickers-static/video/upload/w_1280/q_auto:best"
		const macChromeVideoCloudinary = "/v1632566835/setupautoplay/macchrome.mp4"		
		const macFirefoxVideoCloudinary = "/v1632580976/setupautoplay/macfirefox.mp4"			
		const macSafariVideoCloudinary = "/v1632581443/setupautoplay/macsafari.mp4"

		const winChromeVideoCloudinary = "/v1632583960/setupautoplay/winchrome.mp4"		
		const winFirefoxVideoCloudinary = "/v1632584370/setupautoplay/winfirefox.mp4"		
		const winEdgeVideoCloudinary = "/v1632584743/setupautoplay/winedge.mp4"			


		return(		
			<div className='autoPlaySetup'>

				<div className='autoPlaySetup-topRightContainer'>
					<div className='autoPlaySetup-osToggle'>
						<button onClick={()=>(this.setState({selectedOS: 'windows',selectedBrowser:'chrome'}))} className={'autoPlaySetup-osToggle-toggle ' + (selectedOS === 'windows' ? 'autoPlaySetup-osToggle-toggle--active' : 'autoPlaySetup-osToggle-toggle--inactive')}>
							Windows
						</button>
						<button onClick={()=>(this.setState({selectedOS: 'mac',selectedBrowser:'chrome'}))} className={'autoPlaySetup-osToggle-toggle ' + (selectedOS === 'mac' ? 'autoPlaySetup-osToggle-toggle--active' : 'autoPlaySetup-osToggle-toggle--inactive')}>
							Mac OS
						</button>
					</div>									

					{isModal &&
						<button className='autoPlaySetup-closeBtn' onClick={this.props.closeModal}>
							<Icon name='close' />
						</button>
					}					




				</div>
				<div className='autoPlaySetup-h1'>
					How to enable your Plickers iOS/Android app<br/> to play media on plickers.com
				</div>

				{/* UNSELECTED STATE, FOR CHROME */}
				{!selectedBrowser &&

					<div className='autoPlaySetup-initialBrowser'>
						<div className='autoPlaySetup-initialBrowser-h2'>
							Select your browser
						</div>
						<div className='autoPlaySetup-initialBrowser-btnArray'>

							<button onClick={()=>{this.selectBrowser('chrome'); this.playSound()}} className='autoPlaySetup-initialBrowser-btnArray-button'>
								<div className='autoPlaySetup-initialBrowser-btnArray-button-iconContainer'>
									<img alt='' src={iconImageConstructor + chromeIconCloudinary} />
								</div>
								<div className='autoPlaySetup-initialBrowser-btnArray-button-label'>
									Chrome
								</div>
							</button>
							{selectedOS === 'mac' &&
								<button onClick={()=>{this.selectBrowser('safari')}} className='autoPlaySetup-initialBrowser-btnArray-button'>
									<div className='autoPlaySetup-initialBrowser-btnArray-button-iconContainer'>
										<img alt='' src={iconImageConstructor + safariIconCloudinary} />
									</div>
									<div className='autoPlaySetup-initialBrowser-btnArray-button-label'>
										Safari
									</div>
								</button>
							}
							<button onClick={()=>{this.selectBrowser('firefox')}} className='autoPlaySetup-initialBrowser-btnArray-button'>
								<div className='autoPlaySetup-initialBrowser-btnArray-button-iconContainer'>
									<img alt='' src={iconImageConstructor + firefoxIconCloudinary} />
								</div>
								<div className='autoPlaySetup-initialBrowser-btnArray-button-label'>
									Firefox
								</div>
							</button>

							{selectedOS === 'windows' &&
								<button onClick={()=>{this.selectBrowser('edge')}} className='autoPlaySetup-initialBrowser-btnArray-button'>
									<div className='autoPlaySetup-initialBrowser-btnArray-button-iconContainer'>
										<img alt='' src={iconImageConstructor + edgeIconCloudinary} />
									</div>
									<div className='autoPlaySetup-initialBrowser-btnArray-button-label'>
										Edge
									</div>
								</button>
							}
							
						</div>

					</div>



				}

				{/* NORMAL STATE WITH BROWSER IDENTIFIED */}
				{selectedBrowser &&
					<div className='autoPlaySetup-selectedBrowserContainer'>

						<div className='autoPlaySetup-selectedBrowser-btnArray'>

							<button onClick={()=>{this.selectBrowser('chrome'); this.playSound()}} className={'autoPlaySetup-selectedBrowser-btnArray-button ' + (selectedBrowser === 'chrome' ? 'autoPlaySetup-selectedBrowser-btnArray-button--active' : 'autoPlaySetup-selectedBrowser-btnArray-button--inactive')}>
								<div className='autoPlaySetup-selectedBrowser-btnArray-button-iconContainer'>
									<img alt='' src={iconImageConstructor + chromeIconCloudinary} />
								</div>
								<div className='autoPlaySetup-selectedBrowser-btnArray-button-label'>
									Chrome
								</div>
							</button>
							{selectedOS === 'mac' &&
								<button onClick={()=>{this.selectBrowser('safari')}} className={'autoPlaySetup-selectedBrowser-btnArray-button ' + (selectedBrowser === 'safari' ? 'autoPlaySetup-selectedBrowser-btnArray-button--active' : 'autoPlaySetup-selectedBrowser-btnArray-button--inactive')}>
									<div className='autoPlaySetup-selectedBrowser-btnArray-button-iconContainer'>
										<img alt='' src={iconImageConstructor + safariIconCloudinary} />
									</div>
									<div className='autoPlaySetup-selectedBrowser-btnArray-button-label'>
										Safari
									</div>
								</button>
							}
							<button onClick={()=>{this.selectBrowser('firefox')}} className={'autoPlaySetup-selectedBrowser-btnArray-button ' + (selectedBrowser === 'firefox' ? 'autoPlaySetup-selectedBrowser-btnArray-button--active' : 'autoPlaySetup-selectedBrowser-btnArray-button--inactive')}>
								<div className='autoPlaySetup-selectedBrowser-btnArray-button-iconContainer'>
									<img alt='' src={iconImageConstructor + firefoxIconCloudinary} />
								</div>
								<div className='autoPlaySetup-selectedBrowser-btnArray-button-label'>
									Firefox
								</div>
							</button>

							{selectedOS === 'windows' &&
								<button onClick={()=>{this.selectBrowser('edge')}} className={'autoPlaySetup-selectedBrowser-btnArray-button ' + (selectedBrowser === 'edge' ? 'autoPlaySetup-selectedBrowser-btnArray-button--active' : 'autoPlaySetup-selectedBrowser-btnArray-button--inactive')}>
									<div className='autoPlaySetup-selectedBrowser-btnArray-button-iconContainer'>
										<img alt=''src={iconImageConstructor + edgeIconCloudinary} />
									</div>
									<div className='autoPlaySetup-selectedBrowser-btnArray-button-label'>
										Edge
									</div>
								</button>
							}
							
						</div>


						<div className='autoPlaySetup-selected-h2'>
							Instructions
						</div>

						<AutoPlaySetupBrowserInstructions 
							browser={selectedBrowser}
							os={selectedOS}
							autoplayFailed={autoplayFailed}
						/>

						<div className={'autoPlaySetup-selected-videoContainer ' + ((selectedBrowser === "edge" && selectedOS === "windows") ? 'autoPlaySetup-selected-videoContainer--winEdge' :'')}>
							{selectedBrowser === "chrome" && selectedOS === "mac" &&
								<video autoPlay loop muted preload="auto" className="autoPlaySetup-selected-video autoPlaySetup-selected-video--macChrome">									
									<source src={videoConstructor + macChromeVideoCloudinary}  type="video/mp4" />
								</video>
							}

							{selectedBrowser === "firefox" && selectedOS === "mac" &&
								<video autoPlay loop muted preload="auto" className="autoPlaySetup-selected-video autoPlaySetup-selected-video--macFirefox">
									<source src={videoConstructor + macFirefoxVideoCloudinary}  type="video/mp4" />
								</video>
							}

							{selectedBrowser === "safari" && selectedOS === "mac" &&
								<video autoPlay loop muted preload="auto" className="autoPlaySetup-selected-video autoPlaySetup-selected-video--macSafari">
									<source src={videoConstructor + macSafariVideoCloudinary}  type="video/mp4" />
								</video>
							}

							{selectedBrowser === "chrome" && selectedOS === "windows" &&
								<video autoPlay loop muted preload="auto" className="autoPlaySetup-selected-video autoPlaySetup-selected-video--winChrome">
									<source src={videoConstructor + winChromeVideoCloudinary}  type="video/mp4" />
								</video>
							}

							{selectedBrowser === "firefox" && selectedOS === "windows" &&
								<video autoPlay loop muted preload="auto" className="autoPlaySetup-selected-video autoPlaySetup-selected-video--winFirefox">
									<source src={videoConstructor + winFirefoxVideoCloudinary}  type="video/mp4" />
								</video>
							}

							{selectedBrowser === "edge" && selectedOS === "windows" &&
								<video autoPlay loop muted preload="auto" className="autoPlaySetup-selected-video autoPlaySetup-selected-video--winEdge">
									<source src={videoConstructor + winEdgeVideoCloudinary}  type="video/mp4" />
								</video>
							}

						</div>

					</div>
				}

				{selectedBrowser && isModal &&
					<button className='autoPlaySetup-doneBtn' onClick={this.props.closeModal}>
						Done
					</button>
				}


				



				{/*}
			Autoplay Setup Content
			isModal:{`${this.props.isModal}`}
			<br/>
			browser:{this.state.browser}
			<br/>
			isMac:{`${this.state.isMac}`}
			<br/>
			selectedBrowser:{selectedBrowser}
			<br/>
			autoplayFailed:{`${this.state.autoPlayFailed}`}
			<br/>
			<br/>
			<button onClick={()=>{this.selectBrowser('chrome')}}>chrome</button><br/>
			<button onClick={()=>{this.selectBrowser('safari')}}>safari</button><br/>
			<button onClick={()=>{this.selectBrowser('edge')}}>edge</button><br/>

			<button >play</button>
			
					*/}

				<audio ref = {(ref) => { this.audio = ref }} src={'https://media.plickers.com/video/upload/sound/5dc7674e20197fb3ff8838f4bd0e9a67.mp3'}/>
				<audio ref = {(ref) => { this.autoplayAudio = ref }} autoPlay src={'https://media.plickers.com/video/upload/sound/5dc7674e20197fb3ff8838f4bd0e9a67.mp3'}/>
			 </div>
			
		)
	}
}




export default AutoplaySetupContent

//<audio autoPlay loop src={'https://media.plickers.com/video/upload/sound/0f4292694deca3848b36ba78d147c202.mp3'}/>
	// <video autoPlay loop muted={false} src={URL.createObjectURL(Media.VIDEO)}/> 
	// 		<audio autoPlay loop muted={false} src={URL.createObjectURL(Media.AUDIO)}/>
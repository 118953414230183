import React from 'react'

class StaticSlideChoiceZoomableFillImage extends React.Component{	
	render(){ 
		const {choiceIndex, mediaChoiceWidth} = this.props    
		const imageContainerWidth = mediaChoiceWidth - 6 // bring in from definitions with some 
		const imageContainerHeight = 284 // refactor template definitions      
		return(             
			<div id={`choice${choiceIndex}FillImageZoomable`} style={{width: `${imageContainerWidth}px`, height: `${imageContainerHeight}px`}}  className='slide-choice-media--image-fillImageZoomable'>          
			</div>
		)   
	} 
}
export default StaticSlideChoiceZoomableFillImage

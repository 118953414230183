import {InputRule} from 'prosemirror-inputrules'
import schema from '../../schema/editorSchema'
import store from '../../../store'
import {showAutoFractionNotification} from '../../utils/showAutoFractionNotification'
import {analyticsInsertFriendlyFraction} from '../../../utils/analytics/analyticsInsertFriendlyFraction'


function getAnchorNode($from) {
	let anchorNode
	for (let i = $from.depth; i > 0; i--) {
		let node = $from.node(i)
		if (node.type.name === "friendlyFraction") {
			anchorNode = node
		break
		}
	}
	return anchorNode
}

//replaces 2/3 with a fast fraction
export default function fractionInputRule(){
	return new InputRule(/(\s|^)([\d]+)[/]([\d]+)\s$/g, (state, match, start, end) => {
		const reduxState = store.getState()
		if(reduxState.userConfig && reduxState.userConfig.disableFastFractionInputRule){ //if input rule is disabled do nothing!
			return false
		}else{
			const nodePos=state.tr.doc.resolve(start)
			const anchorNode=getAnchorNode(nodePos)//check you are not already inside a friendly fraction
			if(!anchorNode){
				const numeratorSlice=state.doc.slice(start+match[1].length,start+match[1].length+match[2].length)
				const denominatorSlice=state.doc.slice(start+match[1].length+match[2].length+1,start+match[1].length+1+match[2].length + match[3].length)
				const type = schema.nodes.friendlyFraction  
				const numerator=schema.nodes.friendlyFractionNumerator.create(null,numeratorSlice.content)
				const denominator=schema.nodes.friendlyFractionDenominator.create(null,denominatorSlice.content)
				const node=type.create(null,[numerator,denominator])
				
				let tr = state.tr
				tr.replaceWith(end-1,end,schema.text('\u00a0')) //add blank space after fraction.
				tr.replaceWith(start+match[1].length,end-1,node)
					
				const startPositionParentOffset=tr.doc.resolve(start).parentOffset
				if(startPositionParentOffset===0){//only add a space if is the begining of text node
					tr.insertText('\u00a0',start+match[1].length)
				}
				showAutoFractionNotification()
				const UISource="inputRule"
				const numeratorText=match[2]
				const denominatorText=match[3]
				const inputRuleText=match[0]
				analyticsInsertFriendlyFraction(UISource,numeratorText,denominatorText,inputRuleText)
				return tr
			}
		}
	})
}


//Old rule with autoalgebra stuff 
// export function fractionInputRule(){
// 	return new InputRule(/([\d]+)[\/]([\d]+)\s$/g, (state, match, start, end) => {
// 		const nodePos=state.tr.doc.resolve(start)
// 		const anchorNode=getAnchorNode(nodePos)//check you are not already inside a friendly fraction
// 		if(anchorNode){// console.log('ALREADY IN FRACTION')
// 			return false
// 		}
// 		else{   
// 			const reduxState = store.getState()
// 			const isAlgebraMode=reduxState.editorSettings.autoAlgebra
// 			const numeratorSlice=state.doc.slice(start,start+match[1].length)
// 			const denominatorSlice=state.doc.slice(start+match[1].length+1,start+match[1].length+1+match[2].length)
// 			const type = schema.nodes.friendlyFraction  
// 			const numerator=schema.nodes.friendlyFractionNumerator.create(null,numeratorSlice.content)
// 			const denominator=schema.nodes.friendlyFractionDenominator.create(null,denominatorSlice.content)
// 			const node=type.create(null,[numerator,denominator])
// 			let tr = state.tr
// 			if(!isAlgebraMode){
// 				tr.replaceWith(end-1,end,schema.text('\u00a0')) //add blank space after fraction.  Don't add if in algebra mode
// 				tr.replaceWith(start,end-1,node)
// 				tr.insertText('\u00a0',start)
// 			}else{
// 				tr.replaceWith(start,end,node)
// 			// tr.insertText('\u200b',start)
// 			}
// 		return tr
// 		}
// 	})
// }

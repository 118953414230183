import store from '../store'

export function getUserForId(userId,repoId) {	
	const state = store.getState()
	const permissions=state.permissions[repoId]
	if(permissions){
		const userPermission = permissions.find(permission => {
			if(permission.user){
				return permission.user.id === userId
			} 
			else return null
		})
		if(userPermission){
			return userPermission.user
		}
		else return {}
	} else return {}
}


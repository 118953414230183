import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import DayPickerRangeController from 'react-dates/lib/components/DayPickerRangeController'
import { updateCustomDateRange } from '../../actions/scoreSheet'
import Icon from '../misc/Icons'
import isOutsideRange from './utils/isOutsideRange'

class ScoreSheetSidePanelCalendar extends Component {
  constructor(props) {
    super(props)
    this.onDatesChange = this.onDatesChange.bind(this)
    this.startDateOffset = this.startDateOffset.bind(this)
    this.endDateOffset = this.endDateOffset.bind(this)
    this.isDayHighlighted = this.isDayHighlighted.bind(this)
    let startDate = null
    let endDate = null
    if (props.dateRange) {
      if (props.dateRange.startDate) {
        startDate = props.dateRange.startDate
        endDate = props.dateRange.endDate
      }
    }
    this.state = {
      startDate,
      endDate,
      initialVisibleMonth: endDate,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.dateRange.startDate && nextProps.dateRange.startDate !== this.props.dateRange.startDate) {
      this.setState({ startDate: nextProps.dateRange.startDate, endDate: nextProps.dateRange.endDate, initialVisibleMonth: nextProps.dateRange.endDate })
    }
  }

  onDatesChange({ startDate }) {
    const { granularity } = this.props
    let realStartDate = startDate
    if (granularity === '14d') { // because of offset thing for highlighting
      realStartDate = startDate.add(14, 'days')
    } else if (granularity === '90d') {
      realStartDate = startDate.add(90, 'days')
    }
    this.props.changeSelectedDate(null, realStartDate)
  }

  isDayHighlighted(day) {
    if (this.props.dayGroupedPollsForSection[day.startOf('day')]) {
      return true
    } return false
  }

  startDateOffset(day) {
    const { granularity } = this.props
    if (granularity === 'day') {
      return day.startOf('isoDay')
    } if (granularity === 'week') {
      return day.startOf('isoWeek')
    } if (granularity === 'month') {
      return day.startOf('month')
    }
    if (granularity === '14d') {
      return day.subtract(14, 'days')
    }
    if (granularity === '90d') {
      return day.subtract(90, 'days')
    }
  }

  endDateOffset(day) {
    const { granularity } = this.props
    if (granularity === 'day') {
      return day.endOf('isoDay')
    } if (granularity === 'week') {
      return day.endOf('isoWeek')
    } if (granularity === 'month') {
      return day.endOf('month')
    } if (granularity === '14d') {
      return day
    } if (granularity === '90d') {
      return day
    }
  }

  render() {
    return (
      <div className='scoreSheetSidePanel-calendar'>
        <div className='scoreSheetSidePanel-calendarInnerContainer'>
          <DayPickerRangeController
            initialVisibleMonth={() => this.state.initialVisibleMonth}
            key={this.props.currentSectionId}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            firstDayOfWeek={1}
            onDatesChange={this.onDatesChange}
            focusedInput='startDate'
            numberOfMonths={1}
            isOutsideRange={isOutsideRange}
            isDayHighlighted={this.isDayHighlighted}
            hideKeyboardShortcutsPanel
            navPrev={<CalNavButton previous />}
            navNext={<CalNavButton next />}
            daySize={28}
            transitionDuration={0}
            weekDayFormat='dd'
            enableOutsideDays
            startDateOffset={this.startDateOffset}
            endDateOffset={this.endDateOffset}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const currentSection = ownProps.currentSectionId
  const pollsForSection = filter(filter(state.historySetPolls, { archived: false }), { section: currentSection }).concat(filter(filter(state.historyQuestionPolls, { archived: false }), { section: currentSection }))
  const dayGroupedPollsForSection = groupBy(pollsForSection, (poll) => moment(poll.minCaptured).startOf('day'))
  const firstPoll = sortBy(pollsForSection, [function (poll) {
    return poll.minCaptured
  }])[0]
  let firstDataDate

  if (firstPoll) {
    firstDataDate = firstPoll.minCaptured
  }
  return {
    dayGroupedPollsForSection,
    firstDataDate,
  }
}

export default connect(mapStateToProps,
  { updateCustomDateRange })(ScoreSheetSidePanelCalendar)

class CalNavButton extends React.Component {
  render() {
    return (
      <div className='scoreSheet-calendarNavCustom'>
        {this.props.previous &&
        <Icon name='chevron-left' />}
        {this.props.next &&
        <Icon name='chevron-right' />}
      </div>
    )
  }
}

import api from '../api'

export function checkForExpiredLocks() {
	return (dispatch) =>  dispatch({ type: 'CHECK_FOR_EXPIRED_LOCKS'})
}

export function fetchLocksForRepo(repoId) {
	return (dispatch) => api.fetch(`/locks?repo=${repoId}`)
		.then((response) => {
			dispatch({ type: 'FETCH_LOCKS_SUCCESS', response,repoId })
		})
		.catch((error) => {})
}
 
export function lockSetPusherReceived(lock) {
	return (dispatch) =>  dispatch({ type: 'UPDATE_LOCKS_FROM_PUSHER',lock})
}

export function lockRemovedPusherReceived(lock) {
	return (dispatch) =>  dispatch({ type: 'REMOVE_LOCK_PUSHER',lock})
}

export function fetchLockForSet(setId) {
	return (dispatch) => api.fetch(`/locks/set/${setId}`)
		.then((response) => {})
		.catch((error) => {})
}


export function deleteLockForSet(setId) {
	return (dispatch) => api.delete(`/locks/set/${setId}`)
		.then((response) => {})
		.catch((error) => {})
}


export function deleteLockForQuestion(questionId) {
	return (dispatch) => api.delete(`/locks/question/${questionId}`)
		.then((response) => {})
		.catch((error) => {})
}


export function updateSetLock(setId) {
	return (dispatch) => api.put(`/locks/set/${setId}`)
		.then((response) => {})
		.catch((error) => {
			return error
		})
}

export function updateQuestionLock(questionId) {
	return (dispatch) => api.put(`/locks/question/${questionId}`)
		.then((response) => {
		})
		.catch((error) => {
			return error
		})
}


//Synchronous requests for deleting lock on close editor tab
export function synchronousDeleteLockForSet(setId) {
	return (dispatch) => api.synchronousDelete(`/locks/set/${setId}`)
}

export function synchronousDeleteLockForQuestion(questionId) {
	return (dispatch) => api.synchronousDelete(`/locks/question/${questionId}`)
}







import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import {CSVLink} from 'react-csv'
import Button from '../../components/uiKit/Button'
import {adminFetchCreators} from '../../actions/admin'
import moment from 'moment'

class ExplorerCreatorsContainer extends Component {

	constructor(){
		super()
		this.onClickCsv=this.onClickCsv.bind(this)
		this.calculateCsvData=this.calculateCsvData.bind(this)
		this.state={
			csvData:[],
			csvFilename:''	
		}	
	}

	UNSAFE_componentWillMount() {
		this.props.adminFetchCreators()
	}

	calculateCsvData(){
		let csvData=[]
		let headerRow=['First name','Last Name','email','userId'] 
		csvData.push(headerRow)
		this.props.creators.forEach((creator)=>{
			let row=[creator.firstName,creator.lastName,creator.email,creator.id]
			csvData.push(row)
		})	
		return csvData
	}

	onClickCsv(){
		const csvData=this.calculateCsvData()
		let btn = this.refs.csv
		this.setState({csvData:csvData,csvFilename:`creators_${moment().format('DD/MM/YYYY')}`},	 function() {btn.link.click() })
	}

	render() {
		return(
			<DocumentTitle title={'Plickers - Creators'}>
				<div className='page explorer' >
				<div className='explorerCrossFilter--center'>
				{this.props.creators.length===0 &&
					<div>loading...</div>
				}
				{this.props.creators.length!==0 &&
					<div>
					{this.props.creators.length} creators
					</div>
				}
				<br/>
				<br/>
				<Button disabled={this.props.creators.length===0} tooltipLabel='Export to CSV' tooltipLabelTypeLabel name='exportcsv' iconName='export-csv' onClickFunction={this.onClickCsv} />
				<CSVLink ref="csv" filename={this.state.csvFilename} target='_self' data={this.state.csvData} style={{display:'none'}}/>
				</div>
				</div>
				
			</DocumentTitle>
		)
	}
}

function mapStateToProps(state,ownProps) {
	return {
		creators:state.adminCreators,	
	}
}

export default withRouter(connect(
mapStateToProps,
	{adminFetchCreators
	   }
)(ExplorerCreatorsContainer))

import React, {Component} from 'react'
import { Spring } from  'react-spring/renderprops'

class ImportQuestionsFreeLimitMessage extends Component{

	showUpgrade(){
		window.location.hash='upgrade'
	}

	render(){	
		
		const {showFreeLimitMessage,questionsInSetLimit} = this.props

		let setLimitLabel
		if(questionsInSetLimit === 5){
			setLimitLabel = '5'
		}
		if(questionsInSetLimit === 12){
			setLimitLabel = '12'
		}
		if(questionsInSetLimit===5 || questionsInSetLimit===12){ //only show for free users
			return(					
				<Spring								
					to={{
						opacity: showFreeLimitMessage ? 1 : 0,
						yTranslate: showFreeLimitMessage ? 0 : 40,
					}}        
				>
					{props => 
						<div 
							style={{ transform: `translateY(${props.yTranslate}px)`, opacity:`${props.opacity}` }}
							className={'importQuestions-freeLimitMessage ' + (showFreeLimitMessage ? 'importQuestions-freeLimitMessage--show' : 'importQuestions-freeLimitMessage--hide')}
							onClick={this.showUpgrade}
						>						
							<div className='importQuestions-freeLimitMessage-title'>
								Free users are limited to {setLimitLabel} questions per set
							</div>
							<div className='importQuestions-freeLimitMessage-sub'>
								Learn about Plickers Pro
							</div>					
						</div>
					}					
				</Spring>			
			)	
		}else return null
	}
}


 
					



export default ImportQuestionsFreeLimitMessage

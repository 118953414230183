import React, { Component } from 'react'
import { connect } from 'react-redux'
import DuplicateAndEditLocationSelection from '../../components/modals/DuplicateAndEditLocationSelection'
import {addCopyToRepo} from '../../utils/addCopyToRepo'
import {copyItemFromRepoEvent} from '../../utils/analytics/copyItemFromRepoEvent'
import {showNotification} from '../../actions/notifications'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import find from 'lodash/find'
import DuplicateAndEditFlowReview from '../../components/modals/DuplicateAndEditFlowReview'
import {createReview} from '../../actions/reviews'

//Modal that shows when user wants to add a copy of item to a repo or their library
//(if user is view only in editor we show a button to create a copy and edit)

class DuplicateAndEditModalContainer extends Component {
	constructor(props){
		super(props)
		this.selectLocation=this.selectLocation.bind(this)
		let stage='review'
		if(props.userReviewForRepo || (props.parentRepo && props.parentRepo.shared)){
			stage='selectLocation'
		}
		this.state={
			submitLoading: false,
			selectedRepoId:null,
			librarySelected:false,
			stage:stage
		}
	}

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Duplicate and edit modal')
		}
	}

	selectLocation(repoId,librarySelected){
		this.setState({submitLoading:true,selectedRepoId:repoId,librarySelected:librarySelected})
		const {itemToDuplicate}=this.props
		return this.props.addCopyToRepo(itemToDuplicate,repoId).then((response)=>{
			window.open(`/seteditor/${response.id}`,'_blank')
			if(window.analytics){
				window.analytics.track('Duplicate and edit success')

				copyItemFromRepoEvent(itemToDuplicate,'Set Editor')

			}
			this.props.hideModal()
		})
	} 

	render() {
		const {stage}=this.state
		if(stage==='selectLocation'){
			return (    
				<DuplicateAndEditLocationSelection 
					submitLoading={this.state.submitLoading}
					selectedRepoId={this.state.selectedRepoId}
					repos={this.props.repos} 
					item={this.props.itemToDuplicate} 
					hideModal={this.props.hideModal} 
					selectLocation={this.selectLocation}
					librarySelected={this.state.librarySelected}
				/>
			)
		}else{
			return(
				<DuplicateAndEditFlowReview
					showNotification={this.props.showNotification}
					repo={this.props.parentRepo}
					user={this.props.user}
					createReview={this.props.createReview}
					onCreateReview={()=>{this.setState({stage:'selectLocation'})}}
					skipReviewStage={()=>{this.setState({stage:'selectLocation'})}}
				/>
			)
		} 	
	} 
}

export default connect(
	(state) => ({
		repos:sortBy(filter(state.repos, function(repo) { 
				if((!repo.published && !repo.disabled  && repo.role!=='viewer' && repo.joined) || (repo.published && repo.role==='author')){
					return repo
				}
			}),
		[repo => repo.name.toLowerCase()]),
		itemToDuplicate:state.modals.itemToDuplicate,
		user:state.user,
		parentRepo:find(state.repos, {'id':state.modals.itemToDuplicate.repo}),
		userReviewForRepo:find(state.reviews,{'repo':state.modals.itemToDuplicate.repo})

	}),
	{ addCopyToRepo,showNotification,createReview}
)(DuplicateAndEditModalContainer)


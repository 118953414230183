
import React from 'react'
import find from 'lodash/find'

function getCategoryDisplayName(name){
	let categoryName
	if(name === 'frequentlyUsed'){
		categoryName ='Frequently Used'
	}else if(name === 'general'){
		categoryName ='General'
	}else if(name === 'math-and-science'){
		categoryName ='Math and Science'
	}else if(name === 'world-languages'){
		categoryName = 'World Languages'
	}else if(name === 'math-letters'){
		categoryName = 'Mathematical Latin Alphabet'
	}else if(name === 'greek-alphabet'){
		categoryName = 'Greek Alphabet'
	}else if(name === 'currency'){
		categoryName = 'Currency'
	}else if(name === 'roman-numerals'){
		categoryName = 'Roman Numerals'
	}else if(name === 'search-results'){
		categoryName ='Search Results'
	}else if(name === 'recent'){
		categoryName ='Recent Symbols'
	}
	return categoryName
}

class SymbolPickerListItems extends React.Component{  
	render(){  	
		const {symbol,onMouseEnter,onMouseLeave,onSelectSymbol}=this.props
	  	return (
	  		<button className={'editor-emojiSymbolPicker-list-group-item ' + (symbol.name ? `editor-emojiSymbolPicker-list-group-item--${symbol.name}` :'') }  onClick={()=>{onSelectSymbol(symbol.string,symbol.name,symbol)}} onMouseEnter={()=>{onMouseEnter(symbol)}} onMouseLeave={()=>{onMouseLeave(symbol)}}   >
	  			<div className='editor-emojiSymbolPicker-list-group-item-inner'>
	  				{symbol.previewSymbol || symbol.string}
	  			</div>
	  			<div className='editor-emojiSymbolPicker-list-group-item-tooltip'>	  				
	  				/{symbol.name}	  				
	  			</div>
	  		</button>
	  	)
		}
	}


class SymbolPickerListGroup extends React.Component{  
	render(){  	
		const {name} = this.props
		const categoryDisplayName=getCategoryDisplayName(name)
	  return (
  		<div className={'editor-emojiSymbolPicker-list-group ' + (this.props.name ? `editor-emojiSymbolPicker-list-group--${this.props.name}` : '')} id={`header_${this.props.name}`}>
  			<div className='editor-emojiSymbolPicker-list-group-title' >
  				{categoryDisplayName}
  			</div>
  			<div className='editor-emojiSymbolPicker-list-group-items'>
  				{this.props.children}
  			</div>
  		</div>
	  )
	}
}


class SymbolPickerList extends React.Component{  
	render(){  	
		const {recentSymbols,searchTerm,searchResults,onMouseEnter,onMouseLeave,categories,categoriesOrdered,data}=this.props   	
	  return (
	  		<div className='editor-emojiSymbolPicker-listContainer'>
	  			<div className='editor-emojiSymbolPicker-list'>
	  				{!searchTerm &&	  					
			  			<SymbolPickerListGroup name={'recent'} key={'recent'}>
			  				{recentSymbols.map((symbol,i)=>{
			  					return(
			  						<SymbolPickerListItems
			  							key={`recent_${i}`} 
			  							onSelectSymbol={(unicodeCharacter,name,symbol)=>{this.props.onSelectSymbol(unicodeCharacter,name,symbol,'recent',recentSymbols.length)}} 
			  							symbol={symbol} 
			  							onMouseEnter={onMouseEnter} 
			  							onMouseLeave={onMouseLeave}/>
			  						)
			  				})}
			  			</SymbolPickerListGroup>
	  				}
	  				{!searchTerm && categories.map((category)=>{	  					
	  					return(
			  				<SymbolPickerListGroup name={category} key={category}>
			  				{categoriesOrdered[category].map((name,i)=>{
			  					const symbol=find(data,{'name':name})
			  					return(
			  						<SymbolPickerListItems
			  							key={`${category}_${name}_${i}`} 
			  							onSelectSymbol={(unicodeCharacter,name,symbol)=>{this.props.onSelectSymbol(unicodeCharacter,name,symbol,category,recentSymbols.length)}} 
			  							symbol={symbol} 
			  							onMouseEnter={onMouseEnter} 
			  							onMouseLeave={onMouseLeave}/>
			  						)
			  				})}
			  				</SymbolPickerListGroup>
	  						)	  					
	  				})}
	  				{searchTerm &&
			  			<SymbolPickerListGroup name={'search-results'} key={'searchResults'}>
			  				{searchResults.map((symbol,i)=>{
			  					return(
			  						<SymbolPickerListItems
			  							key={`search_results_${symbol.name}_${i}`} 
			  							onSelectSymbol={(unicodeCharacter,name,symbol)=>{this.props.onSelectSymbol(unicodeCharacter,name,symbol,'search',recentSymbols.length,searchTerm)}} 
			  							symbol={symbol} 
			  							onMouseEnter={onMouseEnter} 
			  							onMouseLeave={onMouseLeave}/>
			  						)			  				
			  					})}
			  			</SymbolPickerListGroup>	  						  					
	  				}
	  			</div>
	  		</div>
	  )
	}
}

export default SymbolPickerList


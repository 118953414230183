const env= process.env.REACT_APP_ENV
const isDev=env==='development'
if(isDev){
	module.exports = {
	 biologyRepoShortIds:[
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'11'
		],
	mathsRepoShortIds:[
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'11'
		],
	keyTextsRepoShortIds:[
			'1', 
			'2', 
		]	
	}
}else{
	module.exports = {
	scienceRepoShortIds:[
			'2450', // Photosynthesis - Venus
			'2350', // DNA Structure and Function - Meghan
			'2175', // Meisos - Meghan
			'2794', // Natural Selection and Evolution - Meghan
			'979', 	// Unbalanced Forces - Abby
			'978', 	// Balanced Forces - Abby
			'814',  // Earth's Beginnings - Abby		
			'3008', // Respiration - Venus
			'3021', // Cells - Venus
			'20', 	// Rocks and Mierals - Abby
			'805',	// Atmosphere and Meterology - Abby
			'3308', // Tectonics - Morgan
			'735',  // Particles and Matter - Nicole
			'3311', // Climate and Weather - Morgan
			'3263', // Coasts and Rivers - Morgan
			'983',  // Electricity and Magnetism - Abby		
			'980',	// Momentum, Impulse, Collisoins - Abby
			'1797',	// Natural Hazards - Joe Wilkinson
			'2989',	// Chemical Change - Nicole
			'1780',	// Photosynthesis - India
			'1781',	// Cell Division and Mitosis - India
			'3041',	// Natural Science Revision - Luke
			'1823',	// Physics Review - Shreve
			'19', 	// Plate Boundaries and Earthquakes - Abby		
			'2987', // Molecular Structure and Properties - Nicole
			'3390', // Structure of a Cell - India
			'3588', //The Carbon Cycle -Madeline Barrow
			'3462', //Transport across the cell membrane  -Madeline Barrow
		],

	// ALL HS BIOLOGY inc. pipeline/pre-approval
	 biologyRepoShortIds:[
			'2350', // DNA Structure and Function - Meghan
			'2175', // Meisos - Meghan
			'2794', // Natural Selection and Evolution - Meghan			
			'3008', // Respiration - Venus			
			'3588', // The Carbon Cycle -Madeline Barrow
			'3731', // Respiration - Madeline Barrow			
			'3869', // Variation and Distribution of Traits - Madeline Barrow
			'3885', // Carbon Based Molecules - Amy Anderson
			'3822', // Feedback Mechanisms and Homeostasis - Brianna Cowling
			'3808', // Biodiversity - Madeline Barrow
			'3783', // Carrying Capacity - Amy Anderson
			'3916', // Cycles of Matter and Energy Transfer - Amy Anderson
			'4310', // Cell Division and Mitosis - Brianna Cowling
			'4204', // Photosynthesis - Amy Anderson
			'3923', // Evidence for Evolution - Kyle McDaniel
			'4412', // Hierarchical Organization of Organisms - Amy Anderson
			
		],

	// ALL Early Math
	mathsRepoShortIds:[
			'153', 	// Number Concepts - Alisoun
			'2756', // Perimeter, Area Volume - Gouett
			'2465', // Measurement/Time - Gouett
			'3219', // Counting - Wilson
			'3297', // Place Value - Wilson  
			'1764', // Fractions - Jessica
			'3438', // Recognising Fractions - Mark Wilson
			'3615', // Properties of 2D shape  - Mark Wilson
			'3795', // Multiplication and Divison  - Ashley Page
			'3771', // Place Value - Borislav Ben
			'3478', // Patterns and Algebra  - Mark Wilson
			'3764', // Numbers and Operations - Fractions - Ashley Page 
			'3793', // Time - Mark Wilson
			'3817', // Decimal Operations - Borislav Ben
			'3831', // Counting, Addition, and Subtraction - Ashley Page
			'3918', // Numerical Expressions - Ashley Page
			'3790', // Multiplication - Kori Shultz
			'3860', // Division - Kori Shultz			

		],

	//  ELA Key Texts
	keyTextsRepoShortIds:[
			'3076', // Julius Caesar - Lori
			'3740', // Raisin in the sun - Donnald
			'4119', // Romeo and Juliet - Donnald
			'3887', // Romeo and Juliet - Hanley
			'3414', // To Kill a Mockingbird - Hanley
			'841', 	// The Crucible - Lori Hoffman
			'3076', // Tragedy of Julius Caesar - Lori Hoffman
			'3713', // Hamlet - Hanley
			'3779', // Macbeth - Hanley
			'6158', // Lord of the Flies - Shoupe
			'4610', // Chains - Shoupe
		]
		
	}
}
import React from 'react'
import EditorInsertSoundModal from '../editorUI/modals/sound/EditorInsertSoundModal'
import ReactDOM from 'react-dom'
import {stopAllMediaPlayback} from '../../../prosemirror/utils/editorActions/stopAllMediaPlayback'
import store from '../../../store'

const modalId='editor-insertSoundModalContainer'

function closeInsertSoundModal(onCloseModal){
	ReactDOM.render(null, document.getElementById(modalId))
}

export function showInsertSoundModal(insertPos,isQuestion,questionPos,questionNode,openOnSoundLibrary) {
	stopAllMediaPlayback()
	store.dispatch({ type: 'HIDE_NOTIFICATION'})
	ReactDOM.render(
		<EditorInsertSoundModal 
			insertPos={insertPos}
			isQuestion={isQuestion}
			questionPos={questionPos}
			questionNode={questionNode}
			closeInsertSoundModal={closeInsertSoundModal}
			openOnSoundLibrary={openOnSoundLibrary}
	/>, document.getElementById(modalId))
}


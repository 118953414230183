export function setToGraded() {
 var tr = window.view.state.tr
 let choiceIndex=0

 window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
 	if(node.attrs.activeslide && node.attrs.activeslide===true){
		window.view.state.doc.nodesBetween(pos,pos+node.nodeSize, (questionNode, questionPos) => {
		  if (questionNode.type.attrs.correct){ 
		  	if(choiceIndex===0){
		      tr.setNodeMarkup(questionPos, null, {...questionNode.attrs,correct:true,isSurvey:false})
		  	}else{
		  		tr.setNodeMarkup(questionPos, null, {...questionNode.attrs,correct:false,isSurvey:false})
		  	}
		  	choiceIndex+=1
		  	}
			})
  	}
	})
  tr.setMeta("triggerParseDoc", true) 
	return  window.view.dispatch(tr)
}

import React from 'react'
import ReactDOM from 'react-dom'
import ReportPackModalHeader from './ReportPackModalHeader'
import PrintPreview from './PrintPreview'
import ReportPackModalConfigControls from './ReportPackModalConfigControls'
import GhostReportPack from '../ghostPack/GhostReportPack'
import Icon from '../../../misc/Icons'
import {getReportPackSettings} from '../../../../utils/scoresheet/getReportPackSettings'
import {getPrintSettings} from '../../../../utils/printing/getPrintSettings'
import {saveSettingToLocalStorage} from '../../../../utils/saveSettingToLocalStorage'
import {getTotalPollCount} from '../../../../utils/scoresheet/getTotalPollCount'
import {getReportPackDateRangeLabel} from '../../../../utils/scoresheet/getReportPackDateRangeLabel'
import {buildReportPollsByStudent} from '../../../../utils/scoresheet/buildReportPollsByStudent'
import StudentReportPack from '../studentReportPack/StudentReportPack'
import {getPersonalizedContentLabel} from '../../../../utils/scoresheet/getPersonalizedContentLabel'
import filter from 'lodash/filter'
import {detectBrowser} from '../../../../utils/detectBrowser'
import {calculateWholeClassPageCount} from '../../../../utils/scoresheet/calculateWholeClassPageCount'
import {calculateIndividualStudentPageCount} from '../../../../utils/scoresheet/calculateIndividualStudentPageCount'



class PrintPortal extends React.Component {
	constructor() {
		super()
		this.onAfterPrint=this.onAfterPrint.bind(this)
	}

	componentDidMount(){
		this.props.handlePrintPortalMounted()
		window.print()
		window.onafterprint = this.onAfterPrint()
	
	} 
	onAfterPrint(){
		this.props.closeReportPackModal()
		if(window.analytics){
			window.analytics.track('Print Student Report', {})}
	}
	render() {
		return (
			<React.Fragment>
				{ReactDOM.createPortal(
	      			this.props.children,
	      			document.body
	    		)}
			</React.Fragment>
		)
	}
}


class ReportPackModal extends React.Component{

	constructor() {
		super()
		this.setReportPackSetting=this.setReportPackSetting.bind(this)
		this.toggleSetting=this.toggleSetting.bind(this)
		this.setPaperSize=this.setPaperSize.bind(this)
		this.handlePrintReport=this.handlePrintReport.bind(this)
		this.saveGhostHeights=this.saveGhostHeights.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.handlePrintPortalMounted=this.handlePrintPortalMounted.bind(this)

		const reportPackSettings=getReportPackSettings()
		const printSettings=getPrintSettings()
		const browser=detectBrowser()
 
		this.state = {
			previewLoading:true,
			showPreview:false,
			reportPackSettings:reportPackSettings,
			paperSize:printSettings.paperSize,
			showStudentScores:true,
			showCorrect:true,
			showIncorrect:true,
			showResponses:true,
			showQuestionGrades:true,
			renderPrintPortal:false,
			ghostHeights:null,
			submitLoading:false,
			browser:browser,
			printPageCount:null
		}
	} 

	componentDidMount(){
		if(window.analytics){
			window.analytics.page('Student Reports Modal')
		}
		setTimeout(function() { 
			this.setState({showPreview:true})
		}.bind(this),	1)
		
		window.addEventListener('keydown', this.handleKeyDown)

	}
	
	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)

	}

	handlePrintPortalMounted(){
		this.setState({submitLoading:false})
	}

	handleKeyDown(e){
		if(e.keyCode === 27){//escape key
			this.props.closeReportPackModal()
		}else if(e.keyCode === 13){//escape key
			this.handlePrintReport()
		}
	}



	saveGhostHeights(heights){
		this.setState({ghostHeights:heights,showPreview:true})
	}


	handlePrintReport(){
		this.setState({submitLoading:true})	

		setTimeout(function() { 
			this.setState({renderPrintPortal:true})
		}.bind(this),	1)
	
	}

	setReportPackSetting(setting,value){		
		let reportPackSettings=this.state.reportPackSettings
		reportPackSettings[setting]=value
		this.setState({reportPackSettings:reportPackSettings})
		saveSettingToLocalStorage('reportPackSettings',setting,value)
	}

	setPaperSize(value){		
		this.setState({paperSize:value})
		saveSettingToLocalStorage('printSettings','paperSize',value)
	}

	toggleSetting(setting){
		this.setState(function(prevState){
		      return {[setting]:!prevState[setting]
		   }}, () => {})
	}
	


	render(){
		const {students,individualReportPackStudent,section,guests,historyPolls,dateRange,closeReportPackModal}=this.props



		
		const totalPollCount = getTotalPollCount(historyPolls)
		const {paperSize,showResponses,reportPackSettings,showStudentScores,showCorrect,showIncorrect,showPreview,renderPrintPortal,ghostHeights,showQuestionGrades}=this.state
		
		const {includeArchivedStudents,includeGuests,highlightCorrect,showMissing,showSurveys,columnCount}=reportPackSettings
		let reportStudents=[]
		let reportGuests=[]
		if(individualReportPackStudent){
			reportStudents.push(individualReportPackStudent)			
		}else{
			if(includeArchivedStudents){
				reportStudents=students
			}else{
				reportStudents=filter(students.slice(0),{'archived':false})
			}
			
			if(includeGuests){
				reportGuests=guests
			}
		}


		const dateRangeLabel=getReportPackDateRangeLabel(dateRange)

		const reportPollsByStudent=buildReportPollsByStudent(historyPolls,students,guests,showCorrect,showIncorrect,showMissing,showSurveys)

		const personalizedContentLabel=getPersonalizedContentLabel(showCorrect,showIncorrect,showMissing,showSurveys)
		
		const {submitLoading} = this.state

		let printPageCount=0
		if(!individualReportPackStudent && reportPollsByStudent && ghostHeights){
			printPageCount=calculateWholeClassPageCount(reportPollsByStudent,ghostHeights,columnCount,includeArchivedStudents,includeGuests)
		}else{
			if(individualReportPackStudent && reportPollsByStudent && ghostHeights){
				printPageCount=calculateIndividualStudentPageCount(reportPollsByStudent[individualReportPackStudent.id],ghostHeights,columnCount)
			}
		}
		
 		

		return(
			<div className='featureModalContainer'>
				<div className={'featureModal featureModal--reportPack ' + (submitLoading ? ' featureModal--reportPack--hide' : '')}> 
					<div className='featureModal-closeBtn' onClick={closeReportPackModal}>
						<Icon name='circle-with-close' />
					</div>

					<div className='featureModal-left'>
						<ReportPackModalHeader 
							dateRangeLabel={dateRangeLabel}
							section={section}
							studentCount={reportStudents.length}
							individualReportPackStudent={individualReportPackStudent}
							totalPollCount={totalPollCount}
						/>

						<ReportPackModalConfigControls 
							setReportPackSetting={this.setReportPackSetting} 
							setPaperSize={this.setPaperSize} 
							reportPackSettings={reportPackSettings}
							individualReportPackStudent={individualReportPackStudent}
							showStudentScores={showStudentScores}
							showCorrect={showCorrect}
							showIncorrect={showIncorrect}
							toggleSetting={this.toggleSetting}
							showResponses={showResponses}
							showQuestionGrades={showQuestionGrades}
							paperSize={paperSize}
							browser={this.state.browser}
						/>		
					</div>
					

					<GhostReportPack 
						reportPackSettings={reportPackSettings}
						polls={historyPolls} //use all polls (not reportPollsByStudent)
						saveGhostHeights={this.saveGhostHeights}
						showQuestionGrades={showQuestionGrades}
						showStudentScores={showStudentScores}
						columnCount={reportPackSettings.columnCount}
						showImages={reportPackSettings.showImages}
						paperSize={paperSize}
					/>	


 

					{showPreview===true &&
						<PrintPreview 
							{...this.props} 
							individualReportPackStudent={individualReportPackStudent}
							paperSize={paperSize}
							showQuestionGrades={showQuestionGrades}
							ghostHeights={ghostHeights}
							reportPackSettings={reportPackSettings}
							showResponses={showResponses}
							totalPollCount={totalPollCount} 
							showStudentScores={showStudentScores}
							highlightCorrect={highlightCorrect}
							students={reportStudents}
							guests={reportGuests}							
							reportPollsByStudent={reportPollsByStudent}
							dateRangeLabel={dateRangeLabel}
							personalizedContentLabel={personalizedContentLabel}
							saveGhostHeights={(heights)=>{this.setState({ghostHeights:heights})}}
						/>			
					}
					
					{renderPrintPortal===true &&
						<PrintPortal closeReportPackModal={closeReportPackModal} handlePrintPortalMounted={this.handlePrintPortalMounted}>
						
							<StudentReportPack 
								{...this.props} 
								paperSize={paperSize}
								browser={this.state.browser}
								showQuestionGrades={showQuestionGrades}
								ghostHeights={ghostHeights}
								reportPackSettings={reportPackSettings}
								showResponses={showResponses}
								totalPollCount={totalPollCount} 
								showStudentScores={showStudentScores}
								highlightCorrect={highlightCorrect}
								students={reportStudents}
								guests={reportGuests}							
								reportPollsByStudent={reportPollsByStudent}
								dateRangeLabel={dateRangeLabel}
								personalizedContentLabel={personalizedContentLabel}
							/>
						</PrintPortal>
					}
				

					<button className='featureModal-primaryButton' onClick={this.handlePrintReport}>

						{!submitLoading ?`Print ${printPageCount} ${printPageCount>1?'pages':'page'}`:''}


						{submitLoading &&	
							<div className='featureModal-primaryButton-loadingSpinnerContainer'>
								<div className='featureModal-primaryButton-loadingSpinner' />
							</div>
						}

					</button>
				</div>
				<div className='featureModalOverlay' onClick={closeReportPackModal} />
			</div>
		)
	}
}
 

export default ReportPackModal

	

import React from 'react'
import ModalWrapper from './ModalWrapper'
import SectionHomeAddToClassSearchModalContainer from '../../containers/modals/SectionHomeAddToClassSearchModalContainer'

const SectionHomeAddToClassSearchModal= props => {
	return (
		<div className='addToClassSearch-modal'>
			<ModalWrapper
				{...props}
			 title="Add to Queue"
			 modalStyle="dark"
			 >
				<SectionHomeAddToClassSearchModalContainer {...props} />
			</ModalWrapper>
		</div>
	)
}

export default SectionHomeAddToClassSearchModal
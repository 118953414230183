import React from 'react'
import AutoplaySetupContent from './AutoplaySetupContent'

class AutoplaySetupPage extends React.Component{

	render(){	



		return(		
			<div className='autoPlaySetupContainer autoPlaySetupContainer--page'>
				<AutoplaySetupContent isModal={false}/>
			</div>
			
		)
	}
}




export default AutoplaySetupPage
